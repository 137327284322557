import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { getNest } from '../../Utils';
import Login from './Login';
import Funds from './Funds';
import Compare from './Compare';
import Combine from './Combine';
import ActConfirm from './Act';
import { isThisISOWeek } from 'date-fns';
import { addEventToAnalytics } from 'Utils';

var classNames = require('classnames');

export default class Tabs extends React.Component {
  stages = {
    funds:   { tab: 'funds' },
    compare: { tab: 'compare' },
    combine: { tab: 'combine' },
    act:     { tab: 'act' },
    confirm: { tab: 'act' },
  }

  constructor(props) {
    super(props);
    this.tabs = ['funds', 'compare', 'combine', 'act'];
    this.state = {
      activeTab: this.getTab(this.props),
      FunDetailTab:'information'
    };
  }

  componentDidMount() {
    addEventToAnalytics('Active Tab','Active Tab','ACTIVE_TAB',{ activeTab: 'funds'},true);
  }

  componentWillReceiveProps(newProps) {
    let newTab = this.getTab(newProps);

    if (this.state.activeTab !== newTab) {
      this.setState({ activeTab: newTab });
    }
    if(!newProps.open && this.state.activeTab !== "funds"){

      this.props.actionHandler('funds')
    }
  }
  toggle = (tab) => {
    let tabName = this.props.loggedIn ? this.tabs[tab] : 'login';
    if (this.state.activeTab !== tabName) {
      this.setState({
        activeTab: tabName
      }, () => { this.props.actionHandler(tabName) });
    }
  }

  getTab = (props) => {
    return getNest([props.stage, 'tab'], this.stages) || (props.loggedIn ? 'funds' : 'login');
  }

  OnFunDetailTabChange = (FunDetailTab) => {
    this.setState({
      FunDetailTab
    })
  }
  toggleFunDetails = (card) => {
    this.setState({
      FunDetailTab:'information'
    }, () => {
      this.props.toggleFunDetails(card)
    })
  }

  render() {
    const { activeTab,FunDetailTab } = this.state;
    const { showFunDetails } = this.props;

    const subHeaderStyle = { fontSize: '0.7rem', marginTop: '-4px', textTransform: 'capitalize'};
    return (
      <div className="content-wrap">
        <Nav tabs justified pills>
          <NavItem className={`${showFunDetails ? "d-none":"" }`}>
            <NavLink className={classNames({ active: activeTab === this.tabs[0] })} onClick={() => {
            this.props.actionHandler('funds');
            addEventToAnalytics('Active Tab','Active Tab','ACTIVE_TAB',{ activeTab: 'funds'},true);
            }}>Funds<div className="media-none" style={subHeaderStyle}>View Details</div></NavLink>
          </NavItem>
          <NavItem className={`${showFunDetails ? "d-none":"" }`}>
            <NavLink className={classNames({ active: activeTab === this.tabs[1] })} onClick={() => {
                this.props.actionHandler('compare')
                addEventToAnalytics('Active Tab','Active Tab','ACTIVE_TAB',{ activeTab: 'compare'},true);
                }}>Compare<div className="media-none" style={subHeaderStyle}>Identify best</div></NavLink>
          </NavItem>
          <NavItem className={`${showFunDetails ? "d-none":"" }`}>
            <NavLink className={classNames({ active: activeTab === this.tabs[2] })} onClick={() => {
                this.props.actionHandler('combine')
                addEventToAnalytics('Active Tab','Active Tab','ACTIVE_TAB',{ activeTab: 'combine'},true);
                }}>
                  {process.env.REACT_APP_SUB_DOMAIN === 'retail'
                    ? <>Impact</>
                    : <>Combine</>
                  }
                  <div className="media-none" style={subHeaderStyle}>Size position</div>
                </NavLink>
          </NavItem>
          <NavItem className={`${showFunDetails ? "d-none":"" }`} style={{ border: 0 }}>
            <NavLink className={classNames({ active: activeTab === this.tabs[3] })} onClick={() => {
                this.props.buyHandler('all')
                addEventToAnalytics('Active Tab','Active Tab','ACTIVE_TAB',{ activeTab: 'act'},true);
                }}>Act<div className="media-none" style={subHeaderStyle}>Propose or buy</div></NavLink>
          </NavItem>
          {/* RESPONSIVE TABS */}
          <NavItem className={`${showFunDetails ? "d-block":"d-none" }`}>
            <NavLink className={classNames({ active: FunDetailTab === 'information' })}
                     onClick={() => this.OnFunDetailTabChange('information') }
            >
              ADDITIONAL DETAILS
            </NavLink>
          </NavItem>
          {/*
            <NavItem className={`${showFunDetails ? "d-block":"d-none" }`}>
              <NavLink className={classNames({ active: FunDetailTab === 'sponsored' })}
                       onClick={() => this.OnFunDetailTabChange('sponsored') }
              >
                SPONSORED
              </NavLink>
            </NavItem>
          */}
          {/*
            <NavItem className={`${showFunDetails ? "d-block":"d-none" }`}>
              <NavLink className={classNames({ active: FunDetailTab === 'revelance' })}
                       onClick={() => this.OnFunDetailTabChange('revelance') }
              >
                Relevance
              </NavLink>
            </NavItem>
          */}
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId={this.tabs[0]}>
            <Funds {...this.props} resetTab = {() => this.OnFunDetailTabChange('information') } toggleFunDetails = { this.toggleFunDetails } FunDetailTab = { FunDetailTab }/>
          </TabPane>
          <TabPane tabId={this.tabs[1]}>
            { activeTab === this.tabs[1] && <Compare {...this.props} /> }
          </TabPane>
          <TabPane tabId={this.tabs[2]}>
            { activeTab === this.tabs[2] && <Combine {...this.props} /> }
          </TabPane>
          <TabPane tabId={this.tabs[3]}>
            { activeTab === this.tabs[3] && <ActConfirm {...this.props} /> }
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

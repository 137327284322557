import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { isEmpty } from 'lodash/lang'

import { cn } from 'DataSet';
import { addEventToAnalytics } from 'Utils';
import { numberCheck } from 'layouts/utils';

import ItemCard from './ItemCard';
import AddMoneyPopup from './AddMoney';
// import SearchCard from './SearchCard';
import { Loader } from '../Loader';
import { FetchBroker } from './helper';

class ItemList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amt: 'Units',
      avaBalance: null,
      showAddMoney: false,
      showBalance: false,
      showPendingAccount: false,
    }
  }

  componentDidMount() {
    this.refreshApexAccountDetails();
    const elems = document.querySelectorAll('.custom-scroll');
    if (elems.length) {
      [].forEach.call(elems, function (el) {
        if (el.scrollHeight > el.clientHeight) {
          el.style.paddingRight = '.25rem';
          el.style.paddingLeft = '.40rem';
        } else el.style.paddingRight = '0rem';
      });
    }
  }

  refreshApexAccountDetails = () => {
    const { getApexAccountDetailsRequest, loggedIn } = this.props;
    if (getApexAccountDetailsRequest && loggedIn) {
      getApexAccountDetailsRequest('', (accountBalanceResponse) => {
        if (accountBalanceResponse && accountBalanceResponse.status === 200 && accountBalanceResponse.data) {
          if (accountBalanceResponse.data && typeof accountBalanceResponse.data.balancePower !== 'undefined') {
            let { balancePower } = accountBalanceResponse.data;
            this.setState({ avaBalance: numberCheck(balancePower, 5) })
          }
        }
      });
    }
  }

  amtHandler = (amt) => {
    this.setState({ amt });
  }

  addBroker = () => {
    console.log('=== addBroker ===');
    addEventToAnalytics('Act Add Broker', 'Act Add Broker', 'ACT_ADD_BROKER', { category: 'advisory_PTC' }, true);
  }

  selectFundToExecute = (checkedStatus, cardTicker) => {
    // const { setSFMState, items } = this.props;
    // items.forEach(item => {
    //   if(item.ticker === cardTicker) {
    //     item._checkedToExecute = checkedStatus;
    //   }
    // })
  }

  removeFund = (fund) => {
    if (fund) {
      const { removeFund } = this.props;
      if (removeFund) {
        removeFund(fund);
      }
    }
  }

  buyHandler = () => {
    const { buyHandler } = this.props;
    if (buyHandler) {
      buyHandler();
    }
  }

  clearHandler = () => {
    const { clearHandler } = this.props;
    if (clearHandler) {
      clearHandler();
    }
  }

  toggleAddMoneyPopup = () => {
    if (addEventToAnalytics) {
      addEventToAnalytics(
        'Quick Trade Add Money',
        'Quick Trade Add Money',
        'QUICK_TRADE_ADD_MONEY',
        {},
        true
      );
    }
    this.setState({
      showAddMoneyPopup: !this.state.showAddMoneyPopup,
    })
  }

  render() {
    const {
      // magnifiTickers,
      // fetchingAllTickersList,
      // profile,
      funds: items,
      profileLoading,
      loggedIn,
      verificationPending,
      noBankAccount,
      getBrokerDetailsRequest,
      loadBrokerDetails,
      isBrokerRegistered,
    } = this.props;
    const { showAddMoneyPopup } = this.state;
    // console.log(items);
    if (items.length === 0) return null;

    // const profile_self_role =  window.localStorage.getItem('profile_self_role') ? window.localStorage.getItem('profile_self_role') : 'loggedOut';
    const sel = (b) => b._all || b._any;

    // let _magnifiTickers = magnifiTickers.filter((kl) => !items.find((l) => l.ticker === kl.ticker));
    // console.log('=== showAddMoneyPopup ===> ', showAddMoneyPopup);
    return (
      <div style={{ height: '100%' }}>
        <div className="lh-125 display-flex-row align-items-center">
          <div className="col-40 add-money" >
          </div>
          <div className="flex-row-child col-20 padding-5-right-1">
            <div className="h-100">
              <Button color="secondary" block className="broker h-100 non-clickable">
                Orders
              </Button>
            </div>
            <div style={{ height: 10 }} />
          </div>
          <div className="flex-row-child col-20 padding-5-right-1">
            <div className="h-100">
              <Button color="secondary" block className="broker h-100 non-clickable">
                Information
              </Button>
            </div>
            <div style={{ height: 10 }} />
          </div>
          <div className="flex-row-child col-20 padding-5-right-1">
            <div className="h-100">
              <Button color="secondary" block className="broker h-100 non-clickable">
                Actions
              </Button>
            </div>
            <div style={{ height: 10 }} />
          </div>
          <div className="flex-row-child col-20 padding-5-right-1">
            <div className="h-100">
              <Button color="secondary" block className="broker h-100 non-clickable">
                Your Broker
              </Button>
            </div>
            <div style={{ height: 10 }} />
          </div>
        </div>
        <div className="item-card-area" style={{ flexDirection: isBrokerRegistered ? 'column' : 'row' }}>
          <div className="h-335 custom-scroll" style={{ width: '100%' }}>
            <div style={{ display: 'flex', width: '100%' }}>
              <div className={`display-flex-col ${isBrokerRegistered ? 'w-100' : 'col-85'}`}>
                {items.map((e, i) => {
                  return (
                    <div key={cn(e, 'Ticker')}>
                      <ItemCard
                        {...this.props}
                        card={e}
                        idx={i}
                        noBankAccount={noBankAccount}
                        verificationPending={verificationPending}
                        checkedToExecute
                        isBrokerRegistered={isBrokerRegistered}
                        amt={this.state.amt}
                        avaBalance={this.state.avaBalance}
                        isMF={e.vehicle}
                        clearHandler={this.clearHandler}
                        buyHandler={this.buyHandler}
                        removeFund={this.removeFund}
                        selectFundToExecute={this.selectFundToExecute}
                        toggleAddMoneyPopup={this.toggleAddMoneyPopup}
                      />
                    </div>
                  )
                })}
              </div>
              <div className={`col-15 mb-2 border-0 add-broker-wrapper ${(profileLoading) && 'broker-loading-area'}`}>
                {(profileLoading) ? (
                  <Loader loading={false} valid={true} />
                ) : ((noBankAccount || verificationPending) && loggedIn ? (noBankAccount ? <div className=" h-100 d-flex align-items-center justify-content-center" style={{ minHeight: '75px' }}><div className="magnifi-broker"><Fragment>
                  <Link style={{ fontWeight: 600, fontSize: 12 }} to={{ pathname: "/settings/tradingAccount", linkaccount: true }}>Link your Bank Account</Link>
                </Fragment></div></div> : <div className=" h-100 d-flex align-items-center justify-content-center" style={{ minHeight: '75px' }}><div className="magnifi-broker"><Fragment>
                  <a href={'settings/tradingAccount'} style={{ fontWeight: 600, fontSize: 12 }}>Verification Pending</a>
                </Fragment></div></div>)
                  : (!isBrokerRegistered ? <>
                    <FetchBroker
                      loggedIn={loggedIn}
                      loadBrokerDetails={loadBrokerDetails}
                      getBrokerDetailsRequest={getBrokerDetailsRequest}
                    />
                    <div className=" h-100 d-flex align-items-center justify-content-center" style={{ minHeight: '75px' }}>
                      <div className="magnifi-broker">
                        <Fragment>
                          <p className={'heading'}>Start Investing Today!</p>
                          <p className={'subHeading'}>Create an Account.</p>
                          <Link to="/register">
                            <Button className="add-broker-button" onClick={this.addBroker}>
                              Magnifi
                            </Button>
                          </Link>
                          {!loggedIn && (
                            <Link to="/login" onClick={() => addEventToAnalytics('Quick Trade Sign In', 'Quick Trade Sign In', 'QUICK_TRADE_SIGN_IN', {}, true)}>
                              <u className="sign-in">Sign In</u>
                            </Link>
                          )}
                        </Fragment>
                      </div>
                    </div>
                  </> : null)
                )}
              </div>
            </div>
            {/*false && (
              <SearchCard
                {...this.props}
                loading={fetchingAllTickersList}
                magnifiTickers={_magnifiTickers}
              />
            )*/}
            <AddMoneyPopup
              showAddMoneyPopup={showAddMoneyPopup}
              toggleAddMoneyPopup={this.toggleAddMoneyPopup}
              refreshApexAccountDetails={() => this.refreshApexAccountDetails()}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ItemList;

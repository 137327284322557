/* eslint-disable max-classes-per-file */
import React, { PureComponent } from 'react';
import TreeMap from "react-d3-treemap";

export const data = {
  name: "Documents",
  children: [
    {
      name: "AgglomerativeCluster",
      value: 3938,
      link: "https://blog.josequinto.com",
    },
    { name: "CommunityStructure", value: 3812 },
    { name: "Child 2", value: 6714 },
    {
      name: "Child 1",
      children: [
        { name: "Child 1.1", value: 12 },
        { name: "Child 1.2", value: 200 },
        { name: "Child 1.3", value: 300 },
        { name: "Child 1.4", value: 743 },
      ],
    },
  ],
};

const COLORS = ['#8889DD', '#9597E4', '#8DC77B', '#A5D297', '#E2CF45', '#F8C12D'];

export class TreeMapBurstChart extends PureComponent {
  onZoom = (zoomLevel, zoomId, breadcrumbItems) => {
    console.log(zoomLevel, zoomId, breadcrumbItems);
  }
  render() {
    return (
      <TreeMap
        id="myTreeMap"
        width={400}
        height={200}
        data={data}
        valueUnit={"MB"}
        hideNumberOfChildren={true}
        onZoom={(zoomLevel, zoomId, breadcrumbItems) => this.onZoom(zoomLevel, zoomId, breadcrumbItems)}
      />
    );
  }
}

export default {
  TreeMapBurstChart,
}

import React from 'react'
import { connect } from 'react-redux'
import { RiskBarChart } from './ChartElements/BarChart'

import moment from 'moment'
import { getPortfolioBenchmarkOfSelectedTicker, monthToDate, nameToDateRange } from 'Utils'
import {
  geopMeanCalc,
  getAnnualizedReturnsByYears,
  getAnnualizedVolatilityByYears,
  getMonthFromYearDiff
} from '../../../../AccountsPage/utils'
import { Select } from 'antd'

const { Option } = Select;
class Risk extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedUpDownBenchmarkTicker:'SPY'
    };
  }
  handleUpDownDropDownChange = (value) => {
    this.setState({
      selectedUpDownBenchmarkTicker:value
    })
  }

  render() {
    const mapData = [
      {
        name: 'Annualized Volatility',
        node: [{
          n: 'anVol1yr',
          v: '1 year',
          c: '#1d6091'
        }, {
          n: 'anVol3yr',
          v: '3 years',
          c: '#1d6091'
        }, {
          n: 'anVol5yr',
          v: '5 years',
          c: '#1d6091'
        }],
        years: 'pfRet3y'
      },
      {
        name: 'Maximum Drawdown',
        node: [{
          n: 'dMax1',
          v: '1 year',
          c: '#1d6091'
        },
          {
            n: 'dMax',
            v: '3 years',
            c: '#1d6091'
          }, {
            n: 'dMax5',
            v: '5 years',
            c: '#1d6091'
          }
        ],
        color: '#1d6091',
        years: 'pfRet3y'
      },
      {
        name: 'Up Capture',
        node: [
          {
            n: 'up_1yr',
            v: '1 year',
            c: '#1d6091'
          }, {
            n: 'up_3yr',
            v: '3 years',
            c: '#1d6091'
          }, {
            n: 'up_5yr',
            v: '5 years',
            c: '#1d6091'
          }],
        color: '#1d6091',
        years: 'pfRet3y',
        hide: false,
        negative: false
      },
      {
        name: 'Down Capture',
        node: [
          {
            n: 'down_1yr',
            v: '1 year',
            c: '#1d6091'
          }, {
            n: 'down_3yr',
            v: '3 years',
            c: '#1d6091'
          }, {
            n: 'down_5yr',
            v: '5 years',
            c: '#1d6091'
          }],
        color: '#1d6091',
        years: 'pfRet3y',
        hide: false,
        negative: true
      }
    ]
    const { upDownDataSet,} = this.props
    const { selectedUpDownBenchmarkTicker} = this.state
    let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(upDownDataSet, selectedUpDownBenchmarkTicker)
    const riskData = riskDataCalc(this.props.selectedClientHoldings.holdings,portfolioBenchmark)
    console.log("riskData",riskData)

    return (
      <div className="risk-chart-wrapper">
        <div className="risk-single-chart">
          <h4>Annualized Volatility</h4>
          <div style={{width: '100%', height: '180px'}}>
            <RiskBarChart
              yAxisLabelClassName="y-axis-label"
              xAxisLabelClassName="x-axis-label"
              data={getNodeData(mapData[0].node, riskData)}
            />
          </div>
        </div>
        <div className="risk-single-chart">
          <h4>Maximum Drawdown</h4>
          <div style={{width: '100%', height: '180px'}}>
            <RiskBarChart
              yAxisLabelClassName="y-axis-label"
              xAxisLabelClassName="x-axis-label"
              data={getNodeData(mapData[1].node, riskData)}
            />
          </div>
        </div>
        <div className="up-down-capture-chart">
          <div className={'title'}>
            <h4>Up Capture</h4>
            <Select
              onChange={this.handleUpDownDropDownChange}
              value={selectedUpDownBenchmarkTicker}
              className='up-down-capture-dropdown'
              bordered={false}
              trigger={['click']}
              dropdownClassName='up-down-capture-menu'>
              {upDownDataSet.map(item => <Option key={item.ticker} value={item.ticker}>{item.ticker}</Option>)}
            </Select>
          </div>
          <div style={{width: '100%', height: '180px'}}>
            <RiskBarChart
              yAxisLabelClassName="y-axis-label"
              xAxisLabelClassName="x-axis-label"
              data={getNodeData(mapData[2].node, riskData)}
            />
          </div>
        </div>
        <div className="up-down-capture-chart">
          <div className={'title'}>
            <h4>Down Capture</h4>
            <Select
              onChange={this.handleUpDownDropDownChange}
              value={selectedUpDownBenchmarkTicker}
              className='up-down-capture-dropdown'
              bordered={false}
              trigger={['click']}
              dropdownClassName='up-down-capture-menu'>
              {upDownDataSet.map(item => <Option key={item.ticker} value={item.ticker}>{item.ticker}</Option>)}
            </Select>
          </div>
          <div style={{width: '100%', height: '180px'}}>
            <RiskBarChart
              yAxisLabelClassName="y-axis-label"
              xAxisLabelClassName="x-axis-label"
              data={getNodeData(mapData[3].node, riskData)}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ clientportfolio: { selectedClientHoldings,upDownDataSet },}) => ({
  selectedClientHoldings,
  upDownDataSet,
})

export default connect(
  mapStateToProps
)(Risk);

const getNodeData = (node, data) => {
  return node.map((v) => {
    return {
      name: v.v, value: parseInt(data[v.n]), color: v.c,
    }
  });
}

export const riskDataCalc = (currentP,portfolioBenchmark={}) => {
  //currentP is the array of portfolio object [{ticker, nav, returns}, {ticker, nav, returns}]
  console.log("check portfolioBenchmark", portfolioBenchmark)
  console.log("check currentP", currentP)
  const currentPf = JSON.parse(JSON.stringify(currentP));
  let portfolioSumValue = 0.0, weightSum = 0.0;

  const  currentYear = moment(new Date()).year();
  currentPf.forEach((e,index) => {
    e.nav = e.nav || e.current_price;
    if(e.nav && e.shares)
      portfolioSumValue += e.nav * e.shares;
    const returnsArr = e.returns;
    const start = moment(new Date(`01 ${returnsArr[returnsArr.length - 1].d}`));
    const end = moment(new Date(`01 ${returnsArr[0].d}`));
    const DateRangeMoment = { start, end };
    const calculatePoints = (ret, year, startFrom) => {
      let returns = JSON.parse(JSON.stringify(ret))
      const range = {
        start: nameToDateRange(year, DateRangeMoment, startFrom).start._d,
        end: nameToDateRange(year, DateRangeMoment, startFrom).end._d,
      }
      return returns.filter((e) => {
        let dt = monthToDate(e.d);
        return dt.isAfter(moment(range.start).subtract(1, 'seconds')) && dt.isBefore(moment(range.end).add(1, 'seconds'));
      });
    }
    currentPf[index].points1y = calculatePoints(e.returns, 'l1y');
    currentPf[index].points3y = calculatePoints(e.returns, 'l3y');
    currentPf[index].points5y = calculatePoints(e.returns, 'l5y');

    currentPf[index].pfWeight = e.nav * e.shares;
    weightSum += currentPf[index].pfWeight;
    currentPf[index].retWeighted1y = [];
    currentPf[index].retWeighted3y = [];
    currentPf[index].retWeighted5y = [];
    e.points1y.forEach((el, index1) => {
      el.v = el.v * currentPf[index].pfWeight; //multiplied by 100 to negate the divide by 100 for charts
      currentPf[index].retWeighted1y.push(el);
    });
    e.points3y.forEach((el, index1) => {
      el.v = el.v * currentPf[index].pfWeight;
      currentPf[index].retWeighted3y.push(el);
    });
    e.points5y.forEach((el, index1) => {
      el.v = el.v * currentPf[index].pfWeight;
      currentPf[index].retWeighted5y.push(el);
    });
  });
  // end for each portfolio
  //
  // start pf ret calc
  let pfRet1y = getMonthFromYearDiff(1);
  let pfRet3y = getMonthFromYearDiff(3);
  let pfRet5y = getMonthFromYearDiff(5);

  let outd1 = {n: 0, dMax: 0.0}, cuml1 = 0.0;
  pfRet1y.forEach((el, index1) => {
    currentPf.forEach((e, index2) => {
      e.points1y.forEach((el1, index11) => {
        if(pfRet1y[index1].d == el1.d) {
          pfRet1y[index1].v += el1.v
        }
      });
    });
    pfRet1y[index1].v = pfRet1y[index1].v/weightSum;
    //calculate drawdown
    let r = pfRet1y[index1].v/100;
    cuml1 = (1 + cuml1)*(1 + r) - 1;
    outd1.n += 1;
    if (outd1.n === 1) {
      outd1.cumlMax = cuml1;
    }
    else {
      (cuml1 > outd1.cumlMax) && (outd1.cumlMax = cuml1);
      let dmax = (outd1.cumlMax-cuml1)/(1+outd1.cumlMax);
      (dmax > outd1.dMax) && (outd1.dMax = dmax);
    }
  });
  let outd = {n: 0, dMax: 0.0}, cuml = 0.0;
  pfRet3y.forEach((el, index1) => {
    currentPf.forEach((e, index2) => {
      e.points3y.forEach((el1, index11) => {
        if(pfRet3y[index1].d == el1.d) {
          pfRet3y[index1].v += el1.v
        }
      });
    });
    pfRet3y[index1].v = pfRet3y[index1].v/weightSum;
    //calculate drawdown
    let r = pfRet3y[index1].v/100;
    cuml = (1 + cuml)*(1 + r) - 1;
    outd.n += 1;
    if (outd.n === 1) {
      outd.cumlMax = cuml;
    }
    else {
      (cuml > outd.cumlMax) && (outd.cumlMax = cuml);
      let dmax = (outd.cumlMax-cuml)/(1+outd.cumlMax);
      (dmax > outd.dMax) && (outd.dMax = dmax);
    }
  });
  let outd5 = {n: 0, dMax: 0.0}, cuml5 = 0.0;
  pfRet5y.forEach((el, index1) => {
    currentPf.forEach((e, index2) => {
      e.points5y.forEach((el1, index11) => {
        if(pfRet5y[index1].d == el1.d) {
          pfRet5y[index1].v += el1.v
        }
      });
    });
    pfRet5y[index1].v = pfRet5y[index1].v/weightSum;
    //calculate drawdown
    let r = pfRet5y[index1].v/100;
    cuml5 = (1 + cuml5)*(1 + r) - 1;
    outd5.n += 1;
    if (outd5.n === 1) {
      outd5.cumlMax = cuml5;
    }
    else {
      (cuml5 > outd5.cumlMax) && (outd5.cumlMax = cuml5);
      let dmax = (outd5.cumlMax-cuml5)/(1+outd5.cumlMax);
      (dmax > outd5.dMax) && (outd5.dMax = dmax);
    }
  });
  let annualizedRet1y = getAnnualizedReturnsByYears(pfRet1y);
  let annualizedRet3y = getAnnualizedReturnsByYears(pfRet3y);
  let annualizedRet5y = getAnnualizedReturnsByYears(pfRet5y);
  let annualizedVolt1y = getAnnualizedVolatilityByYears(pfRet1y);
  let annualizedVolt3y = getAnnualizedVolatilityByYears(pfRet3y);
  let annualizedVolt5y = getAnnualizedVolatilityByYears(pfRet5y);
  let up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr
  // let portfolioBenchmark1 = JSON.parse(JSON.stringify(portfolioBenchmark)).reverse();
  const __ret = geopMeanCalc(portfolioBenchmark, pfRet1y, pfRet3y, pfRet5y)
  if (__ret) {
    up_1yr = __ret.up_1yr
    down_1yr = __ret.down_1yr
    up_3yr = __ret.up_3yr
    down_3yr = __ret.down_3yr
    up_5yr = __ret.up_5yr
    down_5yr = __ret.down_5yr
  }
  let out ={
    pfRet1y,
    pfRet3y,
    pfRet5y,
    anRet1yr: getAnnualizedReturnsByYears(pfRet1y).toFixed(2),
    anVol1yr: getAnnualizedVolatilityByYears(pfRet1y).toFixed(2),
    anVol3yr: annualizedVolt3y.toFixed(2),
    anRet3yr: annualizedRet3y.toFixed(2),
    anVol5yr: getAnnualizedVolatilityByYears(pfRet5y).toFixed(2),
    anRet5yr: getAnnualizedReturnsByYears(pfRet5y).toFixed(2),
    dMax1: (outd1.dMax*100).toFixed(2),
    dMax: (outd.dMax*100).toFixed(2),
    dMax5: (outd5.dMax*100).toFixed(2),
    up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr
  }
  return out;
}

const xAxisTicks = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

const BarCustomTooltip = (props) => {
  const { active, payload, label, vkey } = props;
  if (active && payload) {
    return (
      <div className="recharts-default-tooltip ssf-tooltip-custom">
        <div className="label">{label}</div>
        { payload.map((e, index) => (
          <div key={e.dataKey} className="d-flex" style={{color: e.payload[`${e.dataKey+'Color'}`]}}>
            <span className="mr-2">{e.dataKey}</span>
            <span className="ml-auto">{e.payload[e.dataKey]}</span>
            <span className="">{e.unit}</span>
          </div>
        )) }
      </div>
    );
  }

  return null;
}

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button ,Form, ButtonGroup } from 'reactstrap';
import { CloseButton, PrimaryButton, SecondaryButton } from 'shared-components';
import ConfigureWeights from './ConfigureWeights';
import ReferralContainer from './../../pages/ReferralCodeContainer';
// import TermsCondition from '../../layouts/WebDashboard/SearchResults/Elements/Registrations/TermsNConditions';
import InviteFriends from 'layouts/WebDashboard/SearchResults/Elements/InviteFriends';
import PremiumPlanDetails from './premiumPlanDetails';
import { addEventToAnalytics } from 'Utils';

const WeightChangeModal = ({ scoreAttrs, scoreAttrsWeightHandler, premiumUser, disabled = false, selectedCalculator, ...props }) => {
  const [isInviteFriendsModal, setInviteFriendsModalOpen] = useState(false);
  const [isWeightsModalOpen, setWeightsModalOpen] = useState(false);
  const [isFeatureModalOpen, setFeaturesModalOpen] = useState(false);
  const [notifyModal, setNotifyModalOpen] = useState(false);
  //const [isTermModalOpen, isTermModalOpen] = useState(false);

  useEffect(() => {
    if(selectedCalculator === 'create custom selector'){
      setWeightsModalOpen(true);
    }
  }, [selectedCalculator]);

  const onFeaturesModalOkClick = (e) => {
    e.stopPropagation();
    const { funds } = props
    setFeaturesModalOpen(false);
    if (premiumUser) {
      setWeightsModalOpen(true);
      if (funds) {
        addEventToAnalytics('Custom Weight Calculator','Custom Weight Calculator','CUSTOM_WEIGHT_CALCULATOR',{ query: funds.query, logId: funds.logId },true);
      }
    }
  }

  const onClickHandle = (e) => {
    //e.stopPropagation();
    const { funds } = props
    setFeaturesModalOpen(true);
  }

  const referNow = () => {
    setFeaturesModalOpen(false);
    if (!premiumUser) setInviteFriendsModalOpen(true);
  }

  const inviteHandleCallBack = (response) => {
    if (response && !premiumUser) {
      setInviteFriendsModalOpen(false);
    }
  }

  if (props.inviteSuccess && isInviteFriendsModal) {
    if (props.setProfileState) {
      props.setProfileState({
        inviteSuccess: undefined,
      });
      setInviteFriendsModalOpen(false);
      setNotifyModalOpen(true);
    }
  }
  let renderButton='';
  if (window.location.pathname === '/investments') {
    renderButton = (
      <span style={{position:'absolute',cursor:'pointer'}}>
        {/* <button className="border-0 p-0 bg-sfmodal-grey float-right" onClick={premiumUser ? (() => onFeaturesModalOkClick()) : (() => setFeaturesModalOpen(true)), onClickHandle()}> */}
        {/* <button className="border-0 p-0 bg-sfmodal-grey float-right" onClick={() => <PremiumFeature}> */}

        {disabled ? (
          <button className="border-0 p-0 bg-sfmodal-grey float-right modal-trigger-btn icon-disabled">
            <i className="fal fa-cog"></i>
          </button>
        ): (
          <button className="border-0 p-0 bg-sfmodal-grey float-right modal-trigger-btn" style={{ cursor:'pointer' }} onClick={premiumUser ? (onFeaturesModalOkClick) : (() => onClickHandle())}>
            <i className="fal fa-cog" style={{color:'#1e5f91'}}></i>
          </button>
        )}
      </span>
    )
  }
  else {
    renderButton = (
      <span>
        {/* <button className="border-0 p-0 bg-sfmodal-grey float-right" onClick={premiumUser ? (() => onFeaturesModalOkClick()) : (() => setFeaturesModalOpen(true)), onClickHandle()}> */}
        {/* <button className="border-0 p-0 bg-sfmodal-grey float-right" onClick={() => <PremiumFeature}> */}
        <button className="border-0 p-0 bg-sfmodal-grey float-right modal-trigger-btn" onClick={premiumUser ? (onFeaturesModalOkClick) : (() => onClickHandle())}>
          <i className="fas fa-cog"></i>
        </button>
      </span>
    )
  }

  return (
    [renderButton,
    <Modal
      isOpen={isFeatureModalOpen}
      toggle={() => setFeaturesModalOpen(false)}
      centered
      keyboard={true}
      backdrop
      className="premium-msg-modal-sm-light"
      backdropClassName="premium-feature-process-modal-backdrop"
      >
      <ModalBody className="ssf-modal__body">
        <div className="premium-feature-process-modal-container">
          <div className="header-wrapper">
            {/* CHECKTHIS -> Need to check if the title has to be changed based on any condition */}
            {/* <h1 className="text-align-left">You have discovered a premium feature.</h1> */}
            <h1 className="text-align-left premium-feature-main-heading">Subscribe to unlock features</h1>
          </div>
          <i className="fal fa-times-circle premium-feature-completed-close close-light" onClick={() => setFeaturesModalOpen(false)}></i>
          <span>
            {/* CHECKTHIS -> Need to check if the older message has to be retained */}
            {/* {(!premiumUser) && (
              <span>To receive access, please refer a friend to Magnifi. Your access to the premium features will be activated once they login.</span>
            )} */}
            {!premiumUser && <PremiumPlanDetails referNow={referNow} showPremium userEmail={props.useremail} />}
          </span>
          {(premiumUser) && (
            <ButtonGroup className="cta-wrapper justify-content-left">
              <PrimaryButton className="btn secondary-btn" type="button" onClick={onFeaturesModalOkClick} style={{ maxWidth: 150 }}>
                OK, GOT IT
              </PrimaryButton>
            </ButtonGroup>
          )}
          {/* CHECKTHIS -> Need to check if the refer button is required anywhere */}
          {/* {(!premiumUser) && (
            <ButtonGroup className="cta-wrapper justify-content-left">
              <PrimaryButton className="btn secondary-btn" type="button" onClick={referNow} style={{ maxWidth: 150, margin: 0 }}>
                REFER NOW
              </PrimaryButton>
            </ButtonGroup>
          )} */}
        </div>
      </ModalBody>
    </Modal>,
    <Modal
      isOpen={isWeightsModalOpen}
      toggle={() => setWeightsModalOpen(false)}
      centered
      keyboard={true}
      className="premium-msg-modal configure-weight-modal"
      backdrop
      backdropClassName="backdropClassName"
      >
      <ModalBody className="ssf-modal__body premium-feature-process-modal-container">
        <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => setWeightsModalOpen(false)}></i>
        <ConfigureWeights
          scoreAttrs={scoreAttrs}
          scoreAttrsWeightHandler={values => {
            setWeightsModalOpen(false);
            scoreAttrsWeightHandler(values);
          }}
          modalToggle={setWeightsModalOpen}
        />
      </ModalBody>
    </Modal>,
    <Modal
      isOpen={isInviteFriendsModal}
      toggle={() => setInviteFriendsModalOpen(false)}
      centered
      keyboard={true}
      backdrop
      className="premium-feature-process-modal"
      backdropClassName="premium-feature-process-modal-backdrop"
      >
      <ModalBody className="ssf-modal__body">
        <div className="premium-feature-process-modal-container">
          <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => onFeaturesModalOkClick}></i>
          <InviteFriends />
        </div>
      </ModalBody>
    </Modal>,
    <Modal
      isOpen={notifyModal}
      toggle={() => setNotifyModalOpen(false)}
      centered
      keyboard={true}
      backdrop
      className="premium-msg-modal"
      backdropClassName="premium-feature-process-modal-backdrop"
      >
      <ModalBody className="ssf-modal__body">
        <div className="premium-feature-process-modal-container">
          <div className="header-wrapper">
            <h1 className="text-align-left">Confirmation</h1>
          </div>
          <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => setNotifyModalOpen(false)}></i>
          <p className="content-wrapper">
            <span>
              Your invitation has been sent to
              {` ${props.inviteSentTo ? (props.inviteSentTo.length === 1 ? props.inviteSentTo[0] : [ props.inviteSentTo.slice(0, props.inviteSentTo.length - 1).join(", "), props.inviteSentTo[props.inviteSentTo.length - 1] ].join(" and ")) : ''}`}.
            </span>
            <br/>
          </p>
          <ButtonGroup className="cta-wrapper justify-content-left">
            <PrimaryButton className="btn secondary-btn" type="button" onClick={() => setNotifyModalOpen(false)} style={{ maxWidth: 150 }}>
              OK, GOT IT
            </PrimaryButton>
          </ButtonGroup>
        </div>
      </ModalBody>
    </Modal>,
  ])
}

WeightChangeModal.defaultProps = {
  scoreAttrs: []
}


const mapStateToProps = ({ funds, weights }) => ({
  selectedCalculator: weights.selectedCalculator,
})

export default connect(
  mapStateToProps,
)(WeightChangeModal);

import React from 'react';
import Flipster from 'components/charts/Flipster'
import NotApplicable from 'components/NotApplicable';

export const ThemeScoreChart = ({ card, name, width, imgSize }) => {
  if (!card.themeScore) return <NotApplicable />;

  const mult = name === 'Theme Score' ? 100 : 1;
  const display = 3;
  const data = card.themeScore.filter(e => e.value > 1e-5).slice(0, display).map((e, i) => {
    return {
      name: e.name,
      value: Math.ceil(+e.value*mult),
    };
  });
  if (data.length <= 0) return <NotApplicable />;

  return (
    <div className="p-1">
      <Flipster width={width} imgSize={imgSize} data={data} display={display} />
    </div>
  );
}

export default ThemeScoreChart;

import React, { Component } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import { CustomFormFeedback, PrimaryButton, PasswordRequirement } from 'shared-components';

export default class ChangePasswordPage extends Component {
    render() {
        const { formProps, verifyPasswordErrMsg, error } = this.props;
        const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = formProps
        return (
                <Form className="sign-in magnifi-auth-design-container" noValidate={true} onSubmit={handleSubmit}>
                    <div className="header-area">
                        <div className="header-label">Change Password</div>
                        {/* <div className="sub-header-label">We'll send you an email with instructions</div> */}
                    </div>

                    <div className="body-wrapper">
                    <FormGroup className="element-box pos-rel">
                        <Label htmlFor="password" className="label">
                            New Password
                        </Label>
                        <Input
                        type="password"
                        name="password"
                        id="password"
                        //placeholder="Password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                            errors.password && touched.password
                            ? 'sign-in__input-field sign-in__input-password is-invalid input-area'
                            : 'sign-in__input-field sign-in__input-password input-area'
                         }
                        />
                        <CustomFormFeedback formProps={formProps} fieldName="password" />
                        {error && error.error && (
                        <Alert color="danger">{error.error}</Alert>
                        )}

                     </FormGroup>
                     <FormGroup className="element-box pos-rel">
                        <Label htmlFor="confirmPassword" className="label">
                            Confirm Password
                        </Label>
                        <Input
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        //placeholder="Password"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                            errors.confirmPassword && touched.confirmPassword
                            ? 'sign-in__input-field sign-in__input-password is-invalid input-area'
                            : 'sign-in__input-field sign-in__input-password input-area'
                         }
                        />
                        <CustomFormFeedback formProps={formProps} fieldName="confirmPassword" />
                        {(verifyPasswordErrMsg && verifyPasswordErrMsg.length > 0) ? (
                        <Alert color="danger">{verifyPasswordErrMsg}</Alert>
                        ) : ( error && error.error ) ? (
                            <Alert color="danger">{error.error}</Alert>
                            ):''}


                     </FormGroup>
                     <Row>
                       <Col>
                         <div className="body-wrapper">
                           <FormGroup className="element-box pos-rel">
                             <PasswordRequirement
                               label={'Password Requirements:'}
                               formProps={formProps}
                               fieldName={'password'}
                             />
                           </FormGroup>
                         </div>
                       </Col>
                     </Row>
                    <FormGroup className="cta-wrapper">
                        <PrimaryButton
                        type="submit"
                        className="btn primary-btn"
                        block
                        disabled={isSubmitting}
                        >
                        Update Password
                        </PrimaryButton>
                    </FormGroup>
                    </div>
                </Form>
            )
        }
}

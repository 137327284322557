import React, { Component } from 'react'
import { connect } from 'react-redux';
import _ from 'lodash';
import { Row, Col, Card, Tabs, Input, Dropdown, Menu } from 'antd'
// import { CardBody, Tooltip } from 'reactstrap';
import { CapitalizeEachWord, addEventToAnalytics, deflatLastNode } from 'Utils'
// import { CN  } from 'DataSet';
import style from './CardRow.module.scss'
import { DownOutlined, SwapOutlined } from '@ant-design/icons'
import {
  getMPDetailsCustomReplaceRequest,
  setModelEnhancementData,
  removeCustomMPSelection,
  loadCPMListRequest,
  resetTotumScore,
  getCurrentPortfolioScoreRequest,
  getEnhancedPortfolioScoreRequest,
} from 'actions/enhancer'
// import { getPortfolioStats, getInvestmentWatchlistStats, getInvestmentPositionStats } from 'selectors/investment';

import ItemList from './ItemList';
import ItemCard from './ItemCard';
// import { Loader } from '../../../Elements/Loader'
// import UploadSection from '../../OnBoardScreen/UploadSection'

// import { Select } from 'antd';

// const { Option:OptionSelect } = Select;
const { TabPane } = Tabs;
// const { Option } = AutoComplete;
const { SubMenu } = Menu;
const { Search } = Input;

class CardRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resetEnhanceFundCheckStatus: false,
      resetCustomAddedFund: false,
      activeRegion: '',
      keyIndex:1,
      modelEnhancement: {
        current: {},
        enhanced: {},
        otherEnhancements: [],
      },
      IMG_OBJECT: {
        'Sponsor Models': '/assets/images/SponsorProvidedModel_black.svg',
        'My Models': '/assets/images/MyPortfolios-black.svg',
      },
      _viewIndex: "1",
    };
  }

  componentWillMount() {
    const { loadCPMListRequest } = this.props;
    if (loadCPMListRequest && this.props.marketPlaceList.length === 0) {
      loadCPMListRequest();
    }
  }

  componentDidMount() {
    const { setModelEnhancementData } = this.props;
    if (setModelEnhancementData) {
      setModelEnhancementData(this.state.modelEnhancement);
    }
  }

  componentDidUpdate(prevProps) {
    const { setModelEnhancementData, enhanceReplace } = this.props;
    if(setModelEnhancementData && prevProps.replacePortfolioWith.ticker !== this.props.replacePortfolioWith.ticker) {
      setModelEnhancementData(this.state.modelEnhancement)
      // Hack to load refresh Risk Score for enhanced and current portfolio
      let tickerData = (enhanceReplace && enhanceReplace.length > 0 && enhanceReplace[0].selectedPortfolioFunds) ? enhanceReplace[0].selectedPortfolioFunds : {};
      if (tickerData && Object.keys(tickerData).length === 0) {
        const { resetTotumScore } = this.props;
        if (resetTotumScore) {
          resetTotumScore();
        }
      }
      setTimeout(() => {
        this.getTotumScore(tickerData);
      }, 500);
    }
  }

  onSelectTab = (value) => {
    if(value === '2') {
      // console.log(' -- Specify Custom Ticker Models -- ', this.props.query_uuid);
      addEventToAnalytics(
        'Specify Custom Ticker Models',
        'Specify Custom Ticker Models',
        'SPECIFY_CUSTOM_TICKER_MODELS', {
          query_uuid: this.props.query_uuid
        },
        false,
      );
    }
    this.setState({
      keyIndex:value
    })
  }

  setModelEnhancement = (modelEnhancement) => {
    this.setState({ modelEnhancement });
  }

  resetCheckBoxFor = (mode) => {
    switch (mode) {
      case 'enhanceFund':
        this.setState({
          resetEnhanceFundCheckStatus: false,
        });
      break;
      case 'customAddedFund':
        this.setState({
          resetEnhanceFundCheckStatus: true,
        });
      break;
      default:
      break;
    }
  }

  setReplaceTicker = (e, replaceTickerData, replaceTicker, query) => {
    const val = document.getElementById(e.target.id).checked;

    let ticker = replaceTicker, tickerData = replaceTickerData;

    if (val) {
      addEventToAnalytics('Enhancer MP Select','Enhancer MP Select','ENHANCER_MP_SELECT',{},false);
      ticker = replaceTicker;
      tickerData = replaceTickerData;
      if (this.state.enhanceReplace === ticker) {
        ticker = '';
        tickerData = {};
      }
    } else {
      //for unchecking checkbox
      ticker = '';
      tickerData = {};
    }

    this.setState({
      enhanceReplace: ticker,
    }, () => {
      const { query, modelPortfolios } = this.props;
      const { myCurrentPortfolio } = modelPortfolios;

      const { replaceCurrentModelPortfolioWithRequest, setEnhancerState } = this.props;
      if (replaceCurrentModelPortfolioWithRequest && setEnhancerState) {
        setEnhancerState({
          enhanceReplace: [{
            query,
            replace: ticker,
            selectedPortfolioFunds: tickerData,
            ticker: myCurrentPortfolio.portfolioDetails.name,
          }],
        });
        replaceCurrentModelPortfolioWithRequest({
          replacePortfolioWith: tickerData,
        });

        if (tickerData && Object.keys(tickerData).length === 0) {
          const { resetTotumScore } = this.props;
          if (resetTotumScore) {
            resetTotumScore();
          }
          setTimeout(() => {
            this.getTotumScore(tickerData);
          }, 500);
        }

      }
    });
  }

  getTotumScore = (selectedPortfolioFunds = {}) => {
    const { getCurrentPortfolioScoreRequest, getEnhancedPortfolioScoreRequest, portfolioFunds, totumScore } = this.props;
    const { scoreMeter } = totumScore;
    let orderedScoreMeter = _.orderBy(scoreMeter.filter((item) => item.value !== null), ['value'],['asc']);
    let scores = orderedScoreMeter.map((item) => item.value);
    // console.log('scores--->', scores);

    if (getCurrentPortfolioScoreRequest && getEnhancedPortfolioScoreRequest && portfolioFunds) {
      // NOTE: check for 'current' score
      if (scores.length === 0) {
        // call request for 'current' score
        const requestData = portfolioFunds.map((item) => {
          return {
            ticker: item.ticker,
            weight: item.weight,
          };
        });
        if (requestData && requestData.length > 0) {
          getCurrentPortfolioScoreRequest({
            requestData,
            For: 'current',
          });
        }
      }
      // NOTE: get for 'enhanced' score
      let _holdingsData = [];
      if (selectedPortfolioFunds &&
        typeof selectedPortfolioFunds.holdings !== 'undefined' &&
        selectedPortfolioFunds.holdings.length > 0
      ) {
        selectedPortfolioFunds.holdings.forEach((kl) => {
          _holdingsData = [
            ..._holdingsData, {
              ticker: kl.h_ticker,
              weight: kl.weight,
            }
          ];
        });
      }
      if (_holdingsData && _holdingsData.length > 0) {
        _holdingsData = _.uniqBy(_holdingsData, 'ticker');
        if (_holdingsData && _holdingsData.length > 0) {
          // console.log('--- CardRow _holdingsData ->> rickscore for enhanced ->>', _holdingsData);
          getEnhancedPortfolioScoreRequest({
            requestData: _holdingsData,
            For: 'enhanced',
          });
        }
      }
    }
  }

  searchFundsActiveRegion = (activeRegion) => {
    this.setState({
      activeRegion
    });
  }

  removeCustomMP = () => {
    // console.log('remove')
    this.props.removeCustomMPSelection()
  }

  openChange = () => {
    const element = document.querySelector('.market-popup-wrapper');
    const dropdownEl = document.querySelectorAll('.ant-dropdown-menu-submenu-title');
    if (element != null) {
      element.style.height = element.clientHeight > 0 && `${element.clientHeight}px`
    }
    if(dropdownEl) {
      dropdownEl.forEach((el, i) => {
        dropdownEl[i].title = '';
      })
    }
  }

  setMarketPlace = data => {
    const { getMPDetailsCustomReplaceRequest, portfolioId, modelPortfolios } = this.props;
    // const { selectedPortfolioType } = onboardData;
    const { replacePortfolioOptions } = modelPortfolios;
    let obj = {
      portfolio_id: data.ticker,
      portfolio_name: data.name,
      isSponsored: true,
      target_pf_id: portfolioId,
    };
    if (replacePortfolioOptions && replacePortfolioOptions._NEW_QUERY_VAR_OBJECT && replacePortfolioOptions._NEW_QUERY_VAR_OBJECT._originalQueryVar) {
      let { _NEW_QUERY_VAR_OBJECT } = replacePortfolioOptions;
      obj.query_var = _NEW_QUERY_VAR_OBJECT._originalQueryVar;
    }
    getMPDetailsCustomReplaceRequest(obj)
  }

  selectedPortfolio = portfolioData => {
    const { getMPDetailsCustomReplaceRequest, portfolioId, modelPortfolios } = this.props;
    // const { selectedPortfolioType } = onboardData;
    const { replacePortfolioOptions } = modelPortfolios;
    const obj = {
      portfolio_id: portfolioData.id,
      portfolio_name: portfolioData.portfolio_name,
      isSponsored: false,
      target_pf_id: portfolioId,
    };
    if (replacePortfolioOptions && replacePortfolioOptions._NEW_QUERY_VAR_OBJECT && replacePortfolioOptions._NEW_QUERY_VAR_OBJECT._originalQueryVar) {
      let { _NEW_QUERY_VAR_OBJECT } = replacePortfolioOptions;
      obj.query_var = _NEW_QUERY_VAR_OBJECT._originalQueryVar;
    }
    getMPDetailsCustomReplaceRequest(obj)
  }

  tabOnChange = (selectedKey) => {
    this.setState({ _viewIndex: selectedKey });
  }

  render() {
    const {
      query,
      customReplaceMPOptions,
      modelPortfolios,
      readableQuery,
      portfolioQuery,
      query_english,
      // replacePortfolioOptions,
      modelUniverse,
      portfolioId,
    } = this.props;
    const { myCurrentPortfolio } = modelPortfolios;
    const { resetEnhanceFundCheckStatus,IMG_OBJECT, activeRegion, modelEnhancement, _viewIndex } = this.state;
    // let { _NEW_QUERY_VAR_OBJECT } = modelPortfolios.replacePortfolioOptions;
    let _NEW_QUERY_VAR_OBJECT = myCurrentPortfolio._NEW_QUERY_VAR_OBJECT;

    if (modelPortfolios.replacePortfolioOptions && modelPortfolios.replacePortfolioOptions._NEW_QUERY_VAR_OBJECT) {
      _NEW_QUERY_VAR_OBJECT = modelPortfolios.replacePortfolioOptions._NEW_QUERY_VAR_OBJECT;
    }

    let currentPortfolioNameInitial = '';
    if (myCurrentPortfolio && typeof myCurrentPortfolio.portfolioDetails !== 'undefined') {
      const { portfolioDetails } = myCurrentPortfolio;
      if (portfolioDetails.profile && (portfolioDetails.profile.firstName || portfolioDetails.profile.lastName)) {
        if (portfolioDetails.profile.firstName) {
          currentPortfolioNameInitial = portfolioDetails.profile.firstName[0];
        }
        if (portfolioDetails.profile.lastName) {
          currentPortfolioNameInitial = currentPortfolioNameInitial+portfolioDetails.profile.lastName[0];
        }
      } else {
        currentPortfolioNameInitial = 'N/A';
      }
    }

    let queryHeadTitle = readableQuery && CapitalizeEachWord(readableQuery);

    return (
      <React.Fragment>
        <Row className="enhance-result-page-container">
					<Col className="static-card-wrapper">
						<div className="static-card-label" id="short_name_tooltip">Selected Portfolio</div>
            <Card className="static-card-body">
              <ItemCard
                i={1}
                universe={''}
                staticCard
                activeViewTab={_viewIndex}
                className="model-portfolio-item-card"
                data={myCurrentPortfolio}
                portfolioQuery={portfolioQuery}
                universe={modelUniverse || ''}
                currentPortfolioNameInitial={currentPortfolioNameInitial}
                query={query}
                ticker={null}
                activeRegion={activeRegion}
                targetPortfolioId={portfolioId}
                modelEnhancement={modelEnhancement}
                highlights={modelPortfolios && modelPortfolios.replacePortfolioOptions && modelPortfolios.replacePortfolioOptions.Highlights}
                defaultFRR={_NEW_QUERY_VAR_OBJECT._defaultFRR}
                relevanceChartTabs={_NEW_QUERY_VAR_OBJECT._relevanceChartTabs}
                originalQueryVar={_NEW_QUERY_VAR_OBJECT._originalQueryVar}
                searchFundsActiveRegion={this.searchFundsActiveRegion}
                setModelEnhancement={this.setModelEnhancement}
                tabOnChange={this.tabOnChange}
              />
            </Card>
					</Col>
          <Col className="swap-icon-col">
            <SwapOutlined className="swap-icon-wrapper" />
          </Col>
					<Col className="dynamic-card-wrapper">
						<Tabs className="card-tab-container" defaultActiveKey="1" tabBarGutter={32} onChange={this.onSelectTab}>
              {query && (
                <TabPane className="tabPaneClass" tab={queryHeadTitle} key="1">
                  <Card style={{position:`relative`}} className="dynamic-card-body">
                    <ItemList
                      staticCard={false}
                      activeViewTab={_viewIndex}
                      activeRegion={activeRegion}
                      currentPortfolioNameInitial={currentPortfolioNameInitial}
                      data={modelPortfolios && modelPortfolios.replacePortfolioOptions}
                      universe={modelUniverse || ''}
                      query={query}
                      query_english={query_english}
                      ticker={null}
                      portfolioQuery={portfolioQuery}
                      targetPortfolioId={portfolioId}
                      resetCheck={resetEnhanceFundCheckStatus}
                      enhanceModelPortfolio={modelPortfolios}
                      myCurrentPortfolio={modelPortfolios.myCurrentPortfolio}
                      replacePortfolioWith={modelPortfolios.replacePortfolioWith}
                      portfolioQuery={query}
                      resetCheckBoxFor={this.resetCheckBoxFor}
                      searchFundsActiveRegion={this.searchFundsActiveRegion}
                      modelEnhancement={modelEnhancement}
                      setModelEnhancement={this.setModelEnhancement}
                      replaceCurrentModelPortfolioWith={this.replaceCurrentModelPortfolioWith}
                      setReplaceTicker={this.setReplaceTicker}
                      tabOnChange={this.tabOnChange}
                    />
                  </Card>
                </TabPane>
              )}
              <TabPane tab="Specify Custom Replacement" className={style.customMPCardContainer} key="2">
                {/*(replacePortfolioOptions && Object.keys(replacePortfolioOptions).length > 0) && (
                  <div className={style.customMPCard}>
                    {(customReplaceMPOptions && Object.keys(customReplaceMPOptions).length !== 0) && (
                      <div className={style.selectionContainer}>
                        <ItemList
                          staticCard={false}
                          activeViewTab={_viewIndex}
                          activeRegion={activeRegion}
                          currentPortfolioNameInitial={currentPortfolioNameInitial}
                          data={customReplaceMPOptions}
                          universe={modelUniverse || ''}
                          query={query}
                          query_english={query_english}
                          ticker={null}
                          portfolioQuery={portfolioQuery}
                          resetCheck={resetEnhanceFundCheckStatus}
                          enhanceModelPortfolio={modelPortfolios}
                          myCurrentPortfolio={modelPortfolios.myCurrentPortfolio}
                          replacePortfolioWith={modelPortfolios.replacePortfolioWith}
                          resetCheckBoxFor={this.resetCheckBoxFor}
                          searchFundsActiveRegion={this.searchFundsActiveRegion}
                          modelEnhancement={modelEnhancement}
                          setModelEnhancement={this.setModelEnhancement}
                          replaceCurrentModelPortfolioWith={this.replaceCurrentModelPortfolioWith}
                          setReplaceTicker={this.setReplaceTicker}
                          tabOnChange={this.tabOnChange}
                        />
                        <div style={{position:'absolute',right:'0',top:'24px',cursor:'pointer'}} onClick={this.removeCustomMP} ><img src="/assets/images/card-close.png" /></div>
                      </div>
                    )}
                    {(customReplaceMPOptions && (Object.keys(customReplaceMPOptions|| {})).length === 0) && (
                      <div className={style.noSelectionContainer}>
                        <img className={style.plus} src="/assets/images/plus.png"/>
                        <div className={style.ddContainer}>
                          <Dropdown
                            onVisibleChange={e => {
                              const dropdownEl = document.querySelectorAll('.ant-dropdown-menu-submenu-title')
                              if (dropdownEl) {
                                dropdownEl.forEach((el, i) => {
                                  dropdownEl[i].title = ''
                                })
                              }
                            }}
                            trigger={'hover'}
                            className={style.sponsored}
                            overlay={
                              <CustomSponsoredDropDown
                                openChange={this.openChange}
                                setMarketPlace={this.setMarketPlace}
                                marketPlaceList={this.props.marketPlaceList}
                              />
                            }
                            overlayClassName={style.portfolioDDContainer}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img src={IMG_OBJECT['Sponsor Models']} height="20"/>
                              <div className='row d-flex align-items-center justify-content-between' style={{ marginLeft: '10px', width: '100%', marginRight: '0px' }}>
                                <div>Sponsor Models </div>
                                <DownOutlined/>
                              </div>
                            </div>
                          </Dropdown>
                          <Dropdown
                            trigger={'hover'}
                            className={style.myPortfolio}
                            overlayClassName={style.portfolioDDContainer}
                            overlay={<PortfolioDDSearch
                              portfolioList={this.props.portfolioList}
                              selectedPortfolio={this.selectedPortfolio}
                            />}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img src={IMG_OBJECT['My Models']} height="20"/>
                              <div className='row d-flex align-items-center justify-content-between' style={{ marginLeft: '10px', width: '100%', marginRight: '0px' }}>
                                <div >My Models </div>
                                <DownOutlined/>
                              </div>
                            </div>
                          </Dropdown>
                        </div>
                      </div>
                    )}
                  </div>
                )*/}
                <div className={style.customMPCard}>
                  {(customReplaceMPOptions && Object.keys(customReplaceMPOptions).length !== 0) && (
                    <div className={style.selectionContainer}>
                      <ItemList
                        staticCard={false}
                        activeViewTab={_viewIndex}
                        activeRegion={activeRegion}
                        currentPortfolioNameInitial={currentPortfolioNameInitial}
                        data={customReplaceMPOptions}
                        universe={modelUniverse || ''}
                        query={query}
                        query_english={query_english}
                        ticker={null}
                        targetPortfolioId={portfolioId}
                        portfolioQuery={portfolioQuery}
                        resetCheck={resetEnhanceFundCheckStatus}
                        enhanceModelPortfolio={modelPortfolios}
                        myCurrentPortfolio={modelPortfolios.myCurrentPortfolio}
                        replacePortfolioWith={modelPortfolios.replacePortfolioWith}
                        resetCheckBoxFor={this.resetCheckBoxFor}
                        searchFundsActiveRegion={this.searchFundsActiveRegion}
                        modelEnhancement={modelEnhancement}
                        setModelEnhancement={this.setModelEnhancement}
                        replaceCurrentModelPortfolioWith={this.replaceCurrentModelPortfolioWith}
                        setReplaceTicker={this.setReplaceTicker}
                        tabOnChange={this.tabOnChange}
                      />
                      <div style={{position:'absolute',right:'0',top:'24px',cursor:'pointer'}} onClick={this.removeCustomMP} ><img src="/assets/images/card-close.png" /></div>
                    </div>
                  )}
                  {(customReplaceMPOptions && (Object.keys(customReplaceMPOptions|| {})).length === 0) && (
                    <div className={style.noSelectionContainer}>
                      <img className={style.plus} src="/assets/images/plus.png"/>
                      <div className={style.ddContainer}>
                        {modelUniverse === 'mp' && (
                          <Dropdown
                            onVisibleChange={e => {
                              const dropdownEl = document.querySelectorAll('.ant-dropdown-menu-submenu-title')
                              if (dropdownEl) {
                                dropdownEl.forEach((el, i) => {
                                  dropdownEl[i].title = ''
                                })
                              }
                            }}
                            trigger={'hover'}
                            className={style.sponsored}
                            overlay={
                              <CustomSponsoredDropDown
                                openChange={this.openChange}
                                setMarketPlace={this.setMarketPlace}
                                marketPlaceList={this.props.marketPlaceList}
                              />
                            }
                            overlayClassName={style.portfolioDDContainer}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img src={IMG_OBJECT['Sponsor Models']} height="20"/>
                              <div className='row d-flex align-items-center justify-content-between' style={{ marginLeft: '10px', width: '100%', marginRight: '0px' }}>
                                <div>Sponsor Models </div>
                                <DownOutlined/>
                              </div>
                            </div>
                          </Dropdown>
                        )}
                        {(modelUniverse === 'up') && (
                          <Dropdown
                            trigger={'hover'}
                            className={style.myPortfolio}
                            overlayClassName={style.portfolioDDContainer}
                            overlay={<PortfolioDDSearch
                              portfolioList={this.props.portfolioList}
                              selectedPortfolio={this.selectedPortfolio}
                            />}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img src={IMG_OBJECT['My Models']} height="20"/>
                              <div className='row d-flex align-items-center justify-content-between' style={{ marginLeft: '10px', width: '100%', marginRight: '0px' }}>
                                <div >My Models </div>
                                <DownOutlined/>
                              </div>
                            </div>
                          </Dropdown>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </TabPane>
						</Tabs>
					</Col>
        </Row>
      </React.Fragment>
    )
  }
}

// export default CardRow;

const mapStateToProps = ({ enhancer }) => ({
  portfolioQuery: enhancer.query,
  query_uuid: enhancer.query_uuid,
  query_english: enhancer.query_english,
  marketPlaceList: enhancer.marketPlaceList,
  portfolioList: enhancer.portfolioList,
  portfolioId: enhancer.portfolioId,
  targetModelPortfolioId: enhancer.portfolioName,
  onboardData: enhancer.onboardData,
  isGettingMPData: enhancer.isGettingMPData,
  totumScore: enhancer.totumScore,
  modelUniverse: enhancer.modelUniverse,
  customReplaceMPOptions: enhancer.modelPortfolios.customReplaceMPOptions,
  replacePortfolioOptions:enhancer.modelPortfolios.replacePortfolioOptions,
})
//
const mapDispatchToProps = {
  // deletePortfolioEnhanceReplace,
  setModelEnhancementData,
  getMPDetailsCustomReplaceRequest,
  removeCustomMPSelection,
  loadCPMListRequest,
  resetTotumScore,
  getCurrentPortfolioScoreRequest,
  getEnhancedPortfolioScoreRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardRow)


export class CustomSponsoredDropDown extends React.Component{
  constructor(props) {
    super(props)
  }
  state={
    input:''
  }

  componentWillMount() {
    let { obj:newMarketPlaceList,allItems:allList } = deflatLastNode(this.props.marketPlaceList,true)
    this.setState({
      newMarketPlaceList,
      allList
    })
  }
  handleInputChange =(e)=>{
    this.setState({
      input:e
    })
  }
 render(){
    const {openChange,setMarketPlace} = this.props
    const {input,newMarketPlaceList,allList} = this.state
    let filterdList = input.length!==0 && allList.filter(item=>item.name.toLowerCase().includes(input.toLowerCase()))
    return (
      <Menu onOpenChange={openChange} className={style.portfolioMenuWrapper}>
        <Menu.Item className={style.inputContainer}>
          <Search onChange={(e)=>{e.preventDefault();this.handleInputChange(e.target.value)}} className={style.input} placeholder="Search for better results" allowClear onSearch={this.handleInputChange} />
        </Menu.Item>
        {input.length!==0 && filterdList.map(item=> <Menu.Item className={style.onSearchMenuContainer}>
            <div title={item.name} className={style.onSearchMenu} onClick={() => setMarketPlace(item)} >
              <i className={`far fa-fw fa-search ${style.resultSearchIcon}`}/>
              <div className={style.resultDetail}>
                <p title={item.name}>{item.name}</p>
                <p title={item.sponsor}>{item.sponsor}</p>
              </div>
            </div>
          </Menu.Item>
        )}
        {input.length!==0 && filterdList.length===0 && 	<Menu.Item className={`${style.noHighlight} ${style.noResult}`} key="0">
          <div >No Result Found</div>
        </Menu.Item>}
        {input.length===0 && newMarketPlaceList &&
          typeof newMarketPlaceList == 'object' &&
          Object.keys(newMarketPlaceList).length > 0 &&
          Object.keys(newMarketPlaceList).map((item, index) => {
          return (
            <SubMenu
              key={item}
              title={item}
              className={style.subMenu}
              popupClassName={style.popupClass}
            >
              {Object.keys(newMarketPlaceList[item]).length > 0 &&
              Object.keys(newMarketPlaceList[item]).map((subItem, findex) => {
                if(subItem == 'uncatagorized'){
                  return (newMarketPlaceList[item] && newMarketPlaceList[item][subItem] && newMarketPlaceList[item][subItem].length > 0) && newMarketPlaceList[item][subItem].map(
                    (subItem1, index) => {
                      return (
                        <div
                          title={subItem1.name}
                          key={`market_name-label_${index}`}
                          className={style.itemLable}
                          onClick={() =>
                            setMarketPlace(subItem1)
                          }
                        >
                          {subItem1.name}
                        </div>
                      )
                    }
                  )
                }
                else{
                  return (
                    <SubMenu
                      key={subItem}
                      title={subItem}
                      className={style.subMenu}
                      popupClassName={style.popupClass}
                    >
                      {newMarketPlaceList[item] && newMarketPlaceList[item][subItem] && newMarketPlaceList[item][subItem].length > 0 &&
                        newMarketPlaceList[item][subItem].map(
                          (subItem1, index) => {
                            return (
                              <div
                                title={subItem1.name}
                                key={`market_name-label_${index}`}
                                className={style.itemLable}
                                onClick={() =>
                                  setMarketPlace(subItem1)
                                }
                              >
                                {subItem1.name}
                              </div>
                            )
                          }
                        )}
                    </SubMenu>
                  )
                }
              })}
            </SubMenu>
          )
        })
        }
        {newMarketPlaceList &&
        newMarketPlaceList.message &&
        newMarketPlaceList.message == 'No tickers Present.' && (
          <Menu.Item key="0">
            <div className="portfolio_name-label dropdown-style">No data</div>
          </Menu.Item>
        )}
      </Menu>
    );
  }
}


export class PortfolioDDSearch extends React.Component  {
  constructor(props) {
    super(props);
  }
  state={
    input:''
  }
  handleInputChange =(e)=>{
    // console.log(e)
    this.setState({
      input:e
    })
  }

  render(){
    const { portfolioList,selectedPortfolio } =this.props;
    const {input} =this.state
    let filterList = portfolioList && portfolioList.length>0 ? portfolioList.filter(item=>item.portfolio_name.toLowerCase().includes(input.toLowerCase())):[]
    return (
      <Menu className={style.portfolioMenuWrapper}>
        <Menu.Item className={style.inputContainer}>
          <Search onChange={(e)=>{e.preventDefault();this.handleInputChange(e.target.value)}} className={style.input} placeholder="Search for better results" allowClear onSearch={this.handleInputChange} />
        </Menu.Item>
        {input.length!==0 && filterList.length===0 && 	<Menu.Item className={`${style.noHighlight} ${style.noResult}`} key="0">
          <div >No Result Found</div>
        </Menu.Item>}
        {input.length===0 && filterList.length===0 && 	<Menu.Item className={`${style.noHighlight} ${style.noResult}`} key="0">
          <div >No User Models</div>
        </Menu.Item>}
        {filterList &&
        filterList.length &&
        filterList.map((item, index) => {
          return (
            <Menu.Item className={style.onSearchMenuContainerUP} key={item.portfolio_name + index}>
              <div title={item.portfolio_name} className={style.onSearchMenu}  onClick={() => {selectedPortfolio(item)}} >
                {input.length>0 && <i className={`far fa-fw fa-search ${style.resultSearchIcon}`}/>}
                <div className={style.resultDetail}>
                  <p title={item.portfolio_name}>{item.portfolio_name}</p>
                </div>
              </div>
            </Menu.Item>
          )
        })
        }
      </Menu>

  );
}

}

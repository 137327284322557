import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { setFundsState, refreshFundsState, updateFundsData , searchQueryRequest } from 'actions/discovery';
import { clearFundsApiLoadPercent } from 'actions/global';
import CardListToolbar from '../Elements/CardListToolbar';
import { DateRange, cn, SortAttrs } from 'DataSet';
import { sortItems } from '../utils';
import { addEventToAnalytics } from 'Utils';

class Header extends Component {
  constructor(props) {
    super(props);
    const { discovery } = props;
    const { data: _fundsData } = discovery;
    this.FUNDS_DATA = _fundsData;
    this.state = {
      dateRange: { start: moment(DateRange.start), end: props.dateRange.end || moment(DateRange.end) },
      dateRangeSelected: {},
      FRR_ATTRS: [
        { id:"fees", key: "Fees", sortEnabled: false, asc: false, desc: false },
        { id:"return", key: "Return", sortEnabled: false, asc: false, desc: false },
        { id:"risk", key: "Risk", sortEnabled: true, asc: false, desc: false },
      ],
    }
    this.dateRangeHandler = this.dateRangeHandler.bind(this);
    this.viewHandler = this.viewHandler.bind(this);
    this.setDateRange = this.setDateRange.bind(this);
  }

  componentDidMount() {
    this.updateDateSlider();
    const { requestPayloadSort } = this.props;
    if (requestPayloadSort.name !== '' && requestPayloadSort.value !== '') {
      const dd = this.state.FRR_ATTRS.map((item) => {
        if (item && item.key === this.capitalize(requestPayloadSort.name)) {
          item[requestPayloadSort.value] = true;
        }
        return item;
      });
      this.setState({
        FRR_ATTRS: dd,
      });
    }
  }

  capitalize = (str) => {
    if (str.length) {
      return str[0].toUpperCase() + str.slice(1);
    }
    return str;
  };

  updateDateSlider = () => {
    const { dateRangeSelected, dateRange } = this.props;
    if (dateRangeSelected && typeof dateRangeSelected !== 'undefined' && dateRange && typeof dateRange !== 'undefined') {
      this.setState({
        dateRange: { start: dateRange.start, end: dateRange.end },
        dateRangeSelected: { start: dateRangeSelected.start, end: dateRangeSelected.end },
      }, () => {
        this.setDateRange(dateRange);
      });
    }
  }

  dateRangeHandler(range) {
    setTimeout(() => {
      const { setFundsState, dateRangeSelected, discovery } = this.props;
      if (dateRangeSelected.start !== moment(range.start).format('MMM YYYY') || dateRangeSelected.end !== moment(range.end).format('MMM YYYY')) {
        setFundsState({
          dateRangeSelected: {...range},
        });
        this.setState({ dateRangeSelected: {...range} }, this.filterApply);
        addEventToAnalytics('Timeline change','Timeline change','TIMELINE_CHANGE',{ query: discovery.query, logId: discovery.logId, start: moment(range.start).format('MMM YYYY'), end: moment(range.end).format('MMM YYYY') },true);
      }
    }, 0)
  }

  filterApply = (filrAttrs) => {
    // console.log(filrAttrs);
    // const { setFundsState, discovery, fundsData } = this.props;
    // const { sortAttr, dateRangeSelected } = discovery;
    // const filterAttrs = filrAttrs || discovery.filterAttrs;
    // fundsData.forEach(e => {
    //   e._hidden = false;
    //   if (e.returns.length > 0) {
    //     const d1 = moment("01 "+e._start, "DD MMM YYYY"), d2 = moment("01 "+e._end, "DD MMM YYYY");
    //     // for. e.g: dateRangeSelected: 1st May 2019
    //     // whereas fund's start date is 1st Jul 2019 && end date is 1st July 2019
    //     // then check d1.isAfter(moment(dateRangeSelected.end).startOf('month')) and set true
    //     if (d1.isAfter(moment(dateRangeSelected.end).startOf('month'))) {
    //       e._hidden = true;
    //     }
    //     if (d2.isBefore(moment(dateRangeSelected.start).startOf('month'))) {
    //       e._hidden = true;
    //     } else {
    //       for (var f in filterAttrs) {
    //         if (cn(e, f) === undefined || (filterAttrs[f].length > 0 && !filterAttrs[f].includes(cn(e, f)))) { // re-check 1st condition
    //           e._hidden = true;
    //         }
    //       }
    //     }
    //   }
    // });
    // this.setState({
    //   data: fundsData,
    // }, () => {
    //   const { updateFundsData } = this.props;
    //   if (this.state.sortAttr !== undefined && this.state.sortAttr !== '') {
    //     updateFundsData({
    //       data: sortItems(fundsData, sortAttr),
    //     });
    //   }
    //   else {
    //     updateFundsData({
    //       data: fundsData,
    //     });
    //   }
    // });
  }

  setDateRange = (range) => {
    if (!this.sliderElement) return;
    if (range) {
      this.sliderElement.dateRangeHandler({
        start: moment("01 "+range.start, "DD MMM YYYY"),
        end: moment("01 "+range.end, "DD MMM YYYY")
      });
    }
    else {
      this.sliderElement.clickHandler(this.defaultYears);
    }
  }

  viewHandler(view, secondaryView, mappingVariable) {
    const { setFundsState } = this.props;
    setFundsState({
      itemCardView: {view, secondaryView, mappingVariable}
    });
  }

  sortFunds = (orderBy) => {
    const arr = this.state.FRR_ATTRS;
    const index = arr.findIndex((item) => item.key === orderBy.key);
    if (orderBy.sortEnabled) {
      arr.forEach((k) => {
        if (k.key !== orderBy.key) {
          k.asc = false;
          k.desc = false;
          k.sortEnabled = false;
        }
      });
    }
    if (index !== -1) {
      arr[index] = orderBy;
    }
    this.setState({ FRR_ATTRS: arr }, () => {
      const attr = SortAttrs.find(k => k.name === orderBy.key);
      if (attr && attr.col) {
        const orderByKey = orderBy.asc ? 'asc' : (orderBy.desc ? 'desc' : '');
        const { setFundsState, discovery } = this.props;
        const { searchQueryRequest, clearFundsApiLoadPercent } = this.props;
        clearFundsApiLoadPercent();
        let query = discovery.query;
        let search_type = "search_bar";
        let sort_value = orderByKey;
        let sort_name = orderByKey ? orderBy.id : "";
        setFundsState({
          actions: true,
          requestPayloadSort: {
            name: sort_name,
            value: sort_value,
          },
        });
        searchQueryRequest({
          query,
          search_type,
        });
        let sortOrderText = (!orderBy.sortEnabled) ? 'Default' : ((orderBy.asc) ? 'Low To High' : 'High To Low');
        let sortOrder_Key = (!orderBy.sortEnabled) ? 'DEFAULT' : ((orderBy.asc) ? 'LOW_TO_HIGH' : 'HIGH_TO_LOW');
        addEventToAnalytics(`Sort ${orderBy.key} ${sortOrderText}`,`Sort ${orderBy.key} ${sortOrderText}`,`SORT_${orderBy.key.toUpperCase()}_${sortOrder_Key}`, {}, false);
      }
    });
  }

  render() {
    const { discovery, query, chartData, highlightText } = this.props;
    const { loading, data, view, dateRange, dateRangeSelected, itemCardView } = discovery;
    return (
      <React.Fragment>
        <div id="itemListHeaderWrapper" className="itemListHeaderWrapper">
          <CardListToolbar
            discovery={discovery}
            sortATTRS={this.state.FRR_ATTRS}
            dateRangeHandler={this.dateRangeHandler}
            dateRange={this.state.dateRange}
            dateRangeSelected={this.state.dateRangeSelected}
            inputRef={this.props.inputRef}
            viewHandler={this.viewHandler}
            view={view}
            itemCardView={itemCardView}
            query={query}
            chartData={chartData}
            highlightText={highlightText}
            loading={loading || (data && data.length <= 0)}
            sortCallBack={(sortData,arr) => this.sortFunds(sortData,arr)}
          />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ discovery }) => ({
  discovery,
  dateRangeSelected: discovery.dateRangeSelected,
  dateRange: discovery.dateRange,
  itemCardView: discovery.itemCardView,
  fundsData: discovery.data,
  query: discovery.query,
  loading: discovery.actions,
  requestPayloadSort: discovery.requestPayloadSort,
})

const mapDispatchToProps = {
  setFundsState,
  refreshFundsState,
  updateFundsData,
  clearFundsApiLoadPercent,
  searchQueryRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)

import React from 'react';
import { Tabs } from 'antd';

// import { getPremiumFeatureDetails } from 'apis/test-drive';

import CustomAlert from 'components/CustomAlert';
import Spinner from 'layouts/WebDashboard/SearchResults/Elements/Spinner';

import FundsTab from './FundsTab';
import CompareTab from './CompareTab';
import CombineTab from './CombineTab';
import ActTab from './ActTab';
import { Loader } from './Loader';

import { addEventToAnalytics } from 'Utils'
import { Modal, ModalBody } from 'reactstrap'
import PrintPreviewModal, { getAllPages } from './PrintPreviewModal/PrintPreviewModal'
import moment from 'moment'
// import * as actions from 'actions/sfm'
import { COMPLETED, ERROR, UNASSIGNED } from './utils'
import { reportUploadPUT } from 'apis/sfm'

const { TabPane } = Tabs;
const FileSaver = require('file-saver');
export default class SFMTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opts: {},
      activeTab: 'Funds',//this.getTab(this.props),
      stage: 'funds', // stages for tabs ['funds', 'compare', 'combine', 'act', 'comfirm'],
      disabledTabs: [], // ['Funds', 'Compare', 'Combine', 'Act'],
      premiumModalOpen: false,
      premiumFeature: 'false',
      showPrintPreviewModal: false,
      printSelection: [], //selected pages for printing
      printData: {},
      // notify: false,
    };
  }

  componentDidMount() {
    const { enableOnlyActTab } = this.props;
    if (enableOnlyActTab && typeof enableOnlyActTab !== 'undefined') {
      this.setState({
        activeTab: 'Act',
      });
      addEventToAnalytics('Active Tab', 'Active Tab', 'ACTIVE_TAB', { activeTab: 'act' }, true);
    } else {
      this.setState({
        activeTab: 'Compare',
      });
      addEventToAnalytics('Active Tab', 'Active Tab', 'ACTIVE_TAB', { activeTab: 'compare' }, true);
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { setSFMState } = this.props;
    // only when Modal is minimize set ActiveTab to Funds Tab, because Compare/Combine didnt update when we add new fund in SFM
    // NOTE: Because Compare/Combine didnt update when we add new fund in SFM (Need to fix).
    if (typeof nextProps.sfm !== 'undefined' && typeof nextProps.sfm.modal !== 'undefined') {
      const { sfm, enableOnlyActTab } = nextProps
      if (!sfm.modal && !sfm.modalToggleState && this.state.activeTab !== 'Compare' && !enableOnlyActTab && typeof enableOnlyActTab === 'undefined') {
        this.setState({
          activeTab: 'Compare',
        });
      }
    }
    const currDate = moment().format('MMMM YYYY');
    const firstName = nextProps.firstName || '';
    const lastName = nextProps.lastName || '';
    const fullName = firstName + ' ' + lastName;
    let { profile: { report_preferences } } = this.props
    report_preferences = (report_preferences && report_preferences.length) ? report_preferences.filter(pref => pref.report_name === 'selector')[0].request_payload : getAllPages()
    if ((nextProps.sfm.bgDownloadStatus === COMPLETED) && nextProps.sfm.pdfReportBlob !== '' && !this.state.showPrintPreviewModal && this.state.printSelection.length === report_preferences.length) {
      const isCompareOnly = nextProps.sfm.isCompareOnlyBG || false;
      const newBlob = nextProps.sfm.pdfReportBlob || '';
      //need to fire put api.
      const id = nextProps.sfm.x_report_id
      reportUploadPUT({
        report_id: id,
        isBgDownloadSaved: true
      })
      if (isCompareOnly) {
        FileSaver.saveAs(newBlob, 'Selector Proposal – ' + currDate + '.pdf');
        setSFMState({
          pdfReportBlob: '',
          reportLoaderCompareOnly: false,
          bgDownloadStatus: UNASSIGNED,
          reportPDFDownloadStatusCompareOnly: {
            message: 'PDF Downloaded successfully',
            downloadStatus: 'success',
          },
        })
      }
      else {
        FileSaver.saveAs(newBlob, 'Selector Proposal Detailed – ' + currDate + '.pdf');
        setSFMState({
          pdfReportBlob: '',
          bgDownloadStatus: UNASSIGNED,
          reportLoader: false,
          reportPDFDownloadStatus: {
            message: 'PDF Downloaded successfully',
            downloadStatus: 'success',
          },
        })
      }
    }
    else if (nextProps.sfm.bgDownloadStatus === ERROR && !this.state.showPrintPreviewModal) {
      const isCompareOnly = nextProps.sfm.isCompareOnlyBG || false;
      if (isCompareOnly) {
        setSFMState({
          pdfReportBlob: '',
          bgDownloadStatus: UNASSIGNED,
          reportLoaderCompareOnly: false,
          reportPDFDownloadStatusCompareOnly: {
            message: 'Failed to download Report PDF file. Please email us at: help@magnifi.com',
            downloadStatus: 'danger',
          },
        })
      }
      else {
        setSFMState({
          pdfReportBlob: '',
          bgDownloadStatus: UNASSIGNED,
          reportLoader: false,
          reportPDFDownloadStatus: {
            message: 'Failed to download Report PDF file. Please email us at: help@magnifi.com',
            downloadStatus: 'danger',
          },
        })
      }
    }
    else {
    }

    // NOTE: default open ACT tab when buying a funds from iBAR widget || 'buy' param present in url
    if (typeof nextProps.buyFromURL !== 'undefined' && nextProps.buyFromURL) {
      this.setState({
        activeTab: 'Act',
      });
      addEventToAnalytics('Active Tab', 'Active Tab', 'ACTIVE_TAB', { activeTab: 'act' }, true);
      setSFMState({
        buyFromURL: undefined,
      });
    }
  }

  buyHandler = item => {
    let order = {
      stage: 'buy',
      otype: 'Market',
      price: '',
      qty: '',
      tvalue: '',
      validity: undefined
    }
    if (item === 'all') {
      this.props.items.forEach(e => {
        if (e._order) e._order.stage = 'buy'
        else e._order = { ...order }
      })
    } else {
      if (item._order) item._order.stage = 'buy'
      else item._order = { ...order }
    }
    this.setState({
      activeTab: 'Act',
      disabledTabs: this.state.disabledTabs.filter((item) => item !== 'Act'),
    });
    // this.stageHandler('act')
  }

  combineHandler = (item) => {
    this.props.actionHandler('combine', item); // DO NOT REMOVE THIS
    this.setState({
      activeTab: 'Combine',
      disabledTabs: this.state.disabledTabs.filter((item) => item !== 'Combine'),
    });
  }

  compareHandler = (item) => {
    this.setState({
      activeTab: 'Compare',
      disabledTabs: this.state.disabledTabs.filter((item) => item !== 'Compare'),
    });
  }

  fundsHandler = (item) => {
    this.setState({
      activeTab: 'Funds',
      disabledTabs: this.state.disabledTabs.filter((item) => item !== 'Funds'),
    });
  }

  actionHandler = (type, item = 'all') => {
    switch (type) {
      case 'Act':
        this.buyHandler(item);
        break;

      case 'Combine':
        this.combineHandler(item);
        break;

      case 'Compare':
        this.compareHandler(item);
        break;

      case 'Funds':
        this.fundsHandler(item);
        break;

      default:
    }
  }

  clearHandler = (item, unselect = true) => {
    if (item === 'all') {
      this.props.items.forEach(e => e._order && (e._order.stage = 'compare'))
      unselect && this.props.selectHandler()
    } else {
      item._order && (item._order.stage = 'compare')
      unselect && this.props.selectHandler(item)
    }
  }

  compareReportHandle = (optsStateProps = {}) => {
    const { premiumUser } = this.props;
    this.setState({ opts: optsStateProps });
    const data = {
      isCompareOnly: true,
      ...this.state,
      ...this.props,
    };
    this.saveDataAndShowSelection(data)
    // this.confirmPrintReport(data);
    // addEventToAnalytics('Compare print report', 'Compare print report', 'COMPARE_PRINT_REPORT', {}, true);
    // if (premiumUser) {
    //   const data = {
    //     isCompareOnly: true,
    //     ...this.state,
    //     ...this.props,
    //   };
    //   this.confirmPrintReport(data);
    //   addEventToAnalytics('Compare print report', 'Compare print report', 'COMPARE_PRINT_REPORT', {}, true);
    // } else {
    //   this.checkForUserAccess('print_report_combine'); // 'print_report_combine' WAS USED IN OLD SFM CODE TOO.
    // }
  }

  combineReportHandle = (optsStateProps = {}) => {
    // const { premiumUser } = this.props;
    this.setState({ opts: optsStateProps });
    const data = {
      ...this.state,
      ...this.props,
    };
    this.saveDataAndShowSelection(data)
    // this.confirmPrintReport(data);
    // addEventToAnalytics('Combine print report', 'Combine print report', 'COMBINE_PRINT_REPORT', {}, true);
    // if (premiumUser) {
    //   const data = {
    //     ...this.state,
    //     ...this.props,
    //   };
    //   this.confirmPrintReport(data);
    //   addEventToAnalytics('Combine print report', 'Combine print report', 'COMBINE_PRINT_REPORT', {}, true);
    // } else {
    //   this.checkForUserAccess('print_report_combine');
    // }
  }

  // checkForUserAccess = (print_report_type, callback) => {
  //   const { premiumUser, premiumFeatureAccessRequest } = this.props;
  //   if (!premiumUser && premiumFeatureAccessRequest) {
  //     premiumFeatureAccessRequest(print_report_type, (res) => {
  //       console.log('-- premiumFeatureAccessRequest --');
  //       console.log(res);
  //       console.log('---------------------------------');
  //       if (res && res.access !== 'Denied') {
  //         this.setState({ premiumFeature: 'true' });
  //       }
  //     });
  //   }
  // }

  closePremiumModal = () => {
    console.log('-- closePremiumModal --');
  }

  premiumModalToggle = () => {
    console.log('-- premiumModalToggle --');
  }

  confirmPrintReport = (isBackground) => {
    if (isBackground) {
      //download with all pages
      let { profile: { report_preferences } } = this.props
      report_preferences = (report_preferences && report_preferences.length) ? report_preferences.filter(pref => pref.report_name === 'selector')[0].request_payload : getAllPages()
      let data = { ...this.state.printData, printSelection: report_preferences, isBackgroundDownload: isBackground }
      const { printReport } = this.props;
      printReport(data);
    } else {
      //download with selected pages
      let data = { ...this.state.printData, printSelection: this.state.printSelection, isBackgroundDownload: isBackground }
      const { printReport } = this.props;
      printReport(data);
    }
  }

  saveDataAndShowSelection = (printData) => {
    this.setState({
      showPrintPreviewModal: !this.state.showPrintPreviewModal,
      printData,
    }, () => {
      this.confirmPrintReport(true)
    })
  }

  togglePrintPreviewModal = (isForceClose = false) => {
    this.setState({
      showPrintPreviewModal: !this.state.showPrintPreviewModal
    })
    if (isForceClose) {
      //remove the blob
      const { setSFMState } = this.props;
      setSFMState({
        pdfReportBlob: '',
        bgDownloadStatus: UNASSIGNED,
        reportLoader: false,
        reportLoaderCompareOnly: false,
      })
    }
  }

  savePrintSelection = (arr) => {
    // console.log(arr)
    let { profile: { report_preferences } } = this.props
    report_preferences = (report_preferences && report_preferences.length) ? report_preferences.filter(pref => pref.report_name === 'selector')[0].request_payload : getAllPages()
    this.setState({
      printSelection: arr,
      showPrintPreviewModal: !this.state.showPrintPreviewModal
    }, () => {
      if (arr.length === report_preferences.length && arr.sort() === report_preferences.sort()) {
        // no changes in the report pref
        // save the blob which may or may not have downloaded
        this.props.saveBgDownload()
      } else {
        //changes in report pref
        this.confirmPrintReport(false)
      }
    })
  }

  // openNotify = () => {
  //   this.setState({
  //     notify: true,
  //   });
  // }
  //
  // notifyToggle = () => {
  //   this.setState({
  //     notify: !this.state.notify,
  //   });
  // }

  testDrivePrintReport = () => {
    const { activeTab } = this.state;
    switch (activeTab) {
      case 'Combine':
        const _data = {
          ...this.state,
          ...this.props,
        };
        this.saveDataAndShowSelection(_data)
        // this.confirmPrintReport(_data);
        addEventToAnalytics('Combine print report', 'Combine print report', 'COMBINE_PRINT_REPORT', { category: 'advisory_PTC' }, true);
        break;

      case 'Compare':
        const _Data = {
          isCompareOnly: true,
          ...this.state,
          ...this.props,
        };
        this.saveDataAndShowSelection(_Data)
        // this.confirmPrintReport(_Data);
        addEventToAnalytics('Compare print report', 'Compare print report', 'COMPARE_PRINT_REPORT', { category: 'advisory_PTC' }, true);
        break;

      default:
    }
  }

  onAlertDismiss = () => {
    const { onAlertDismiss } = this.props;
    if (onAlertDismiss) {
      onAlertDismiss();
    }
  }

  render() {
    const { premiumModalOpen, notify, activeTab, FunDetailTab, disabledTabs, showPrintPreviewModal } = this.state;
    const {
      sfm: { reportLoaderCompareOnly, reportPDFDownloadStatusCompareOnly, myportfolio },
      reportLoader,
      reportPDFDownloadStatus,
      // premiumUser,
      chartReportData,
      enableOnlyActTab,
      // importedAccounts,
      fetchFundsLoading,
      executeOrderLoading,
      profileLoading,
    } = this.props;
    let { profile: { report_preferences } } = this.props
    report_preferences = (report_preferences && report_preferences.length) ? report_preferences.filter(pref => pref.report_name === 'selector')[0].request_payload : []
    // console.log('enableOnlyActTab ==>', enableOnlyActTab);
    // console.log('disabledTabs ==>', disabledTabs);
    // console.log("importedAccounts ==>", importedAccounts);
    let tabPaneArray = [{
      key: 'Compare',
      tabHead: () => (
        <div className="SFM-v2-tab-head-wrap">
          <div className="SFM-v2-tab-head-title">Compare</div>
          <div className="SFM-v2-tab-sub-head">Identify Best</div>
        </div>
      ),
      tabContent: () => (
        <CompareTab
          {...this.props}
          actionHandler={this.actionHandler}
          buyHandler={this.buyHandler}
          clearHandler={this.clearHandler}
          printReport={this.compareReportHandle}
        />
      ),
      disabled: disabledTabs.includes('Compare'),
    }, {
      key: 'Combine',
      tabHead: () => (
        <div className="SFM-v2-tab-head-wrap">
          <div className="SFM-v2-tab-head-title">
            {process.env.REACT_APP_SUB_DOMAIN === 'retail'
              ? <>Impact</>
              : <>Combine</>
            }
          </div>
          <div className="SFM-v2-tab-sub-head">Size Position</div>
        </div>
      ),
      tabContent: () => (
        <CombineTab
          {...this.props}
          actionHandler={this.actionHandler}
          buyHandler={this.buyHandler}
          clearHandler={this.clearHandler}
          printReport={this.combineReportHandle}
        />
      ),
      disabled: disabledTabs.includes('Combine'),
    }, {
      key: 'Act',
      tabHead: () => (
        <div className="SFM-v2-tab-head-wrap">
          <div className="SFM-v2-tab-head-title">Act</div>
          <div className="SFM-v2-tab-sub-head">Buy or Sell</div>
        </div>
      ),
      tabContent: () => (
        <ActTab
          {...this.props}
          activeTab={this.state.activeTab}
          actionHandler={this.actionHandler}
          buyHandler={this.buyHandler}
          clearHandler={this.clearHandler}
        />
      ),
      disabled: disabledTabs.includes('Act'),
    }, {
      key: 'Funds',
      tabHead: () => (
        <div className="SFM-v2-tab-head-wrap">
          <div className="SFM-v2-tab-head-title">Funds</div>
          <div className="SFM-v2-tab-sub-head">View Details</div>
        </div>
      ),
      tabContent: () => (
        <FundsTab
          {...this.props}
          actionHandler={this.actionHandler}
          buyHandler={this.buyHandler}
          clearHandler={this.clearHandler}
        />
      ),
      disabled: disabledTabs.includes('Funds'),
    }];
    if (enableOnlyActTab && typeof enableOnlyActTab !== 'undefined') {
      tabPaneArray = tabPaneArray.filter((kl) => kl.key === 'Act')
    }
    // if (!myportfolio) {
    //   tabPaneArray = tabPaneArray.filter((kl) => kl.key !== 'Combine')
    // }
    const renderTabs = () => {
      return (
        <Tabs
          className={`SFM-v2-tabs ${(tabPaneArray.length === 1) ? 'enableOnlyActTab' : ''}`}
          type="card"
          size="default"
          activeKey={activeTab}
          onChange={this.actionHandler}
        >
          {tabPaneArray && tabPaneArray.map((kl) => {
            return (
              <TabPane key={kl.key} disabled={kl.disabled} tab={kl.tabHead()}>
                {(fetchFundsLoading || executeOrderLoading || profileLoading) && <Loader loading />}
                {!fetchFundsLoading && !profileLoading && kl.tabContent()}
              </TabPane>
            )
          })}
        </Tabs>
      )
    }
    const _allFunds = (chartReportData && chartReportData.allFunds && chartReportData.allFunds.funds) ? chartReportData.allFunds.funds : [];
    const _selFun = (chartReportData && chartReportData.selectedFunds) ? chartReportData.selectedFunds : [];
    // console.log('Tabs')
    return (
      <div className="content-wrap">
        {(reportLoaderCompareOnly || this.props.selectorTimeFrameLoader || reportLoader)
          && (
            <div className="report-spinner-wrapper">
              <Spinner />
            </div>
          )
        }
        {(reportPDFDownloadStatusCompareOnly && reportPDFDownloadStatusCompareOnly.message !== '' && reportPDFDownloadStatusCompareOnly.downloadStatus !== '')
          && (
            <CustomAlert className="alert-windown-wrapper" color={reportPDFDownloadStatusCompareOnly.downloadStatus} onDismissCallback={this.onAlertDismiss}>
              {reportPDFDownloadStatusCompareOnly.message}
            </CustomAlert>
          )
        }
        {(reportPDFDownloadStatus && reportPDFDownloadStatus.message !== '' && reportPDFDownloadStatus.downloadStatus !== '')
          && (
            <CustomAlert className="alert-windown-wrapper" color={reportPDFDownloadStatus.downloadStatus} onDismissCallback={this.onAlertDismiss}>
              {reportPDFDownloadStatus.message}
            </CustomAlert>
          )
        }
        <Modal
          isOpen={showPrintPreviewModal}
          toggle={() => { this.togglePrintPreviewModal(true) }}
          centered
          keyboard={true}
          backdrop
          className="print-selection-preview-modal"
          backdropClassName="premium-feature-process-modal-backdrop"
        >
          <ModalBody className="modal-body-2">
            <PrintPreviewModal
              activeTab={activeTab}
              allFunds={_allFunds}
              selectedFunds={_selFun}
              report_preference={report_preferences}
              toggle={this.togglePrintPreviewModal}
              savePrintSelection={this.savePrintSelection}
            />
          </ModalBody>
        </Modal>
        {renderTabs()}
      </div>
    );
  }
}

import React from 'react';
import { connect } from 'react-redux';

import { Row, Button } from 'reactstrap';

import { toggleModal, setSFMState } from 'actions/sfm'
import { dropFund, setInvestmentState, investmentFetch, downloadPortfolioReport } from 'actions/investment'
import { getInvestmentWatchlistStats, getInvestmentPositionStats, getPortfolioStats } from 'selectors/investment'
import { Wrapper, WrapperNP, KeyStats1, KeyStatsPort1 } from './Common'
import { Transactions } from './Summary'
import { PortfolioGains, Holdings, SecurityReturns, Allocations, ProfitLoss } from './Positions'
import { uniqValues } from 'Utils'
import CustomAlert from 'components/CustomAlert'

class UploadPortfolioContent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.buyHandler = this.buyHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
  }

  componentDidMount() {
    this.props.setInvestmentState({
      portfolioValue : this.props.redirectFrom.portfolioValue ? this.props.redirectFrom.portfolioValue : this.props.fundStats.portfolioValue
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.dropFundResponse !== '' && newProps.droppedFund) {
      const { investmentFetch, setInvestmentState } = this.props;
      investmentFetch();
      setInvestmentState({
        dropFundResponse: '',
        droppedFund: false,
      });
    }
  }

  buyHandler = (ticker) => {
    // console.log(ticker);
    // setSFMState({
    //   items,
    // });
  }

  deleteHandler = (item) => {
    const { dropFund } = this.props;
    dropFund({
      ticker: item.symbol,
    });
  }

  selectHandler = (item, index) => {
    const { selectHandler } = this.props;
    selectHandler(item, index);
  }

  onAlertDismiss = () => {
    // console.log('trrrr');
    const { setInvestmentState } = this.props
    setInvestmentState({
      reportPDFDownloadStatus: {
        message: '',
        downloadStatus: ''
      }
    });
  }

  render() {
    const { fundStats, positionStats, acView, reportPDFDownloadStatus, checkPurchaseValues } = this.props;
    const hdExpand = acView === 'holdings',
          txExpand = acView === 'transactions';

    return (
      <div className="">
        {(reportPDFDownloadStatus && reportPDFDownloadStatus.message !== '' && reportPDFDownloadStatus.downloadStatus !== '')
          && (
            <CustomAlert className="alert-windown-wrapper" color={reportPDFDownloadStatus.downloadStatus} onDismissCallback={this.onAlertDismiss}>
              {reportPDFDownloadStatus.message}
            </CustomAlert>
          )
        }
        {
          !checkPurchaseValues &&
          <Row className="compact">
            <WrapperNP className="col-lg-6"><PortfolioValue stats={fundStats} /></WrapperNP>
            <WrapperNP className="col-lg-6"><PortfolioGains stats={positionStats} view={this.props.view} /></WrapperNP>
          </Row>
        }
        {
          checkPurchaseValues &&
          <Row className="compact">
            <WrapperNP className="col-lg-6"><PortfolioValue1 stats={fundStats} /></WrapperNP>
            <Wrapper className="col-6"><Allocations stats={fundStats} /></Wrapper>
            {/* <WrapperNP className="col-lg-6"><PortfolioGains stats={positionStats} view={this.props.view} /></WrapperNP> */}
          </Row>
        }

        <Row className="compact">
          <div className="col-lg-6">
            <Row className="compact">
              <Wrapper className="col-lg-12">
                <Holdings
                  title="Fund Holdings"
                  items={fundStats.positions}
                  deleteHandler={this.deleteHandler}
                  buyHandler={this.buyHandler}
                  actions={FundActions}
                  expand={hdExpand}
                  selectHandler={this.selectHandler}
                />
              </Wrapper>
              { !hdExpand && !checkPurchaseValues &&
                <React.Fragment>
                  <Wrapper className="col-6"><Allocations stats={fundStats} /></Wrapper>
                  <Wrapper className="col-6"><ProfitLoss stats={fundStats} view={this.props.view} /></Wrapper>
                </React.Fragment> }
            </Row>
          </div>
          <div className="col-lg-6">
            <Row className="compact">
              { !txExpand && <Wrapper className="col-lg-12"><SecurityReturns items={fundStats.funds} /></Wrapper> }
              {
                !checkPurchaseValues &&
                <Wrapper className="col-lg-12">
                  <Transactions title="Recent Fund Transactions" items={fundStats.funds} expand={txExpand} />
                </Wrapper>
              }
            </Row>
          </div>
        </Row>
      </div>
    )
  }
}

export default connect(
  state => ({
    fundStats: getPortfolioStats(state),
    positionStats: getPortfolioStats(state),
    acView: state.investment.acView,
    redirectFrom: state.investment.redirectFrom,
    droppedFund: state.investment.droppedFund,
    dropFundResponse: state.investment.dropFundResponse,
    sfm: state.sfm,
    view: state.investment.view,
    reportPDFDownloadStatus: state.investment.reportPDFDownloadStatus,
    checkPurchaseValues: state.investment.checkPurchaseValues,
  }),
  {
    toggleModal,
    setSFMState,
    dropFund,
    setInvestmentState,
    investmentFetch,
    downloadPortfolioReport,
  }
)(UploadPortfolioContent);


const PortfolioValue = (props) => {
  const stats = [
    { name: 'Value Of Fund Holdings', value: props.stats.portfolioValue },
  ]
  return (
    <KeyStats1 stats={stats} />
  )
}

const PortfolioValue1 = (props) => {
  const stats = [
    { name: 'Value Of fund Holdings', value: props.stats.portfolioValue },
  ]
  return (
    <KeyStatsPort1 stats={stats} />
  )
}

const FundActions = () => {
  return (
    <React.Fragment>
      <Button color={"select"} outline
        className="h6 mb-0 py-1 badge-pill">Buy</Button>
      <Button color={"select"} outline
        className="h6 mb-0 mt-2 py-1 badge-pill">Drop</Button>
    </React.Fragment>
  )
}

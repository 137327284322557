import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
// import { uniqBy, union } from 'lodash';
import { searchQueryRequest, setFundsState, refreshFundsState, updateFundsData, searchAnalyticsAPI, updateSelFundsInCrd } from 'actions/discovery';
import {
  setSFMState,
  setSFMItems,
  clearFundsTracking,
  scoreAttrsHandler,
  addSFMItemTickersRequest,
  removeSFMItemTickersRequest,
  deleteAllSFMItemTickersRequest,
  addTickersToCartRequest,
} from 'actions/sfm';
import { refreshWeightState } from 'actions/weights';
import { setBuildPortfolioState } from 'actions/buildportfolio';
// import { ScoreAttrs } from 'DataSet';
import { addEventToAnalytics } from 'Utils';
// import { DateRange, cn } from 'DataSet';
import { CHECK_QUICK_TRADE } from 'layouts/utils';

import Header from './Header';
import List from './List';

// let selectorCount = 0;

class ItemList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: props.fundsData,
      selectedCards: props.selectedCards,
      portfolioBuilderCount: 0
    }
    this.viewHandler = this.viewHandler.bind(this);
    this.selectHandler = this.selectHandler.bind(this);
  }

  componentDidMount() {
    const { history: { location }, fundsData, buildportfolio, setBuildPortfolioState } = this.props;
    const { resultPageCart } = buildportfolio;
    if (resultPageCart && resultPageCart.length > 0) {
      fundsData.map((item) => {
        if (item.ticker) {
          const found = resultPageCart.find((d) => d.ticker === item.ticker);
          if (found && typeof found !== 'undefined') {
            item._builder = true;
          }
        }
      });
      this.setState({ data: fundsData });
    }
    if (this.props.buildportfolio.clearBuilderFunds) {
      setBuildPortfolioState({
        resultPageCart: [],
        clearBuilderFunds: false
      })
    }
    // console.log('===== CHECK_QUICK_TRADE =====> ', CHECK_QUICK_TRADE());
    if (!CHECK_QUICK_TRADE()) {
      setTimeout(() => {
        if (location) {
          let params = new URLSearchParams(location.search);
          if (params.get('buy')) {
            let _buyParams = params.get('buy');
            // console.log(_buyParams);
            this.addToCart({ tickers: _buyParams }, 'buy');
          } else if (params.get('compare')) {
            let _compareParams = params.get('compare');
            // console.log(_compareParams);
            this.addToCart({ tickers: _compareParams }, 'compare');
          }
        }
      }, 3000);
    }
  }

  addToCart = (data, activeTab = 'compare') => {
    // console.log('--  addToCart --', data);
    if (data && data.tickers && data.tickers.length > 0) {
      let _tickers = data && data.tickers;
      if (_tickers) {
        const {
          funds,
          fundsData,
          // setSFMItems,
          // sfmSelectedItemTickers,
          addTickersToCartRequest,
          updateSelFundsInCrd,
        } = this.props;
        // console.log('-- select logic --');
        if (addTickersToCartRequest) {
          const payload = {
            selectedItemTickers: _tickers,
          };
          if (activeTab === 'buy') {
            payload.buyFromURL = true;
          }
          addTickersToCartRequest(payload);
          if (funds.query) {
            addEventToAnalytics('ADD TO CART', 'ADD TO CART', 'ADD_TO_CART', { logId: funds.logId, ticker: _tickers, category: 'advisory_PTC' }, true);
          }
          const foundInFundsData = fundsData.find((item) => (Object.prototype.toString.call(data.tickers) === '[object Array]' ? data.tickers.includes(item.ticker) : (data.tickers === item.ticker)));
          // console.log('-- foundInFundsData --', foundInFundsData);
          if (foundInFundsData) {
            updateSelFundsInCrd(foundInFundsData)
          }
        }
      }
    }
  }

  getSecondaryName = (originalName, item, index) => {
    const { discovery } = this.props;
    const { chartData } = discovery;
    if (typeof originalName !== 'undefined') {
      return chartData.filter(e => e.view_name === originalName[index])[0].Secondary_view_name;
    }
    const newArr = chartData.filter(e => e.view_name === item);
    return newArr.length ? newArr[0].Secondary_view_name : null;
  }

  viewHandler(view, secondaryView) {
    const { setFundsState } = this.props;
    setFundsState({
      itemCardView: { view, secondaryView }
    });
  }

  searchAnalytics = async (item) => {
    const { searchAnalyticsAPI, discovery } = this.props;
    searchAnalyticsAPI({ search_query_id: discovery.logId, ticker: discovery.ticker })
  }

  selectBuilderHandler = (item, clear) => {
    const { buildportfolio, setBuildPortfolioState, discovery } = this.props;
    if (clear) {
      item._builder = false;
      let filteredBuilderArray = buildportfolio.resultPageCart.filter((el) => el.id != item.id);
      let fundArr = [];
      discovery.data.forEach(item => {
        if (item._selector) {
          item._selected = true;
          fundArr.push(item);
        }
      })
      let obj = { resultPageCart: filteredBuilderArray };
      updateFundsData({
        data: this.props.fundsData,
      });
      this.searchAnalytics(item);
      setBuildPortfolioState(obj);
    } else {
      // item._selected = true;
      item._builder = true;
      let itemArr = [...buildportfolio.resultPageCart];
      itemArr.push(item);
      let obj = { resultPageCart: itemArr };
      this.searchAnalytics(item);
      addEventToAnalytics('Add to Builder', 'Add to Builder', 'ADD_TO_BUILDER', { selected_ticker: item.ticker, category: 'advisory_PTC' }, true);
      setBuildPortfolioState(obj);
    }
  }

  selectHandler(item, index) {
    //V-2 logic
    const { sfmSelectedItemTickers, addSFMItemTickersRequest, removeSFMItemTickersRequest, deleteAllSFMItemTickersRequest, funds } = this.props;
    if (item === undefined || typeof item === 'undefined') {
      // DELETE ALL SFM FUNDS/ITEMS
      console.log('=====> DELETE ALL SFM FUNDS/ITEMS <=====');
      if (deleteAllSFMItemTickersRequest) {
        deleteAllSFMItemTickersRequest()
      }
    } else {
      // NOTE: Step 1: Add item-> Ticker in SFM ->`selectedItemTickers`
      // Step 2: using ticker name SFM look into FUNDS reducer >
      // if ticker already present in funds reducer state then use that data else Call API to fetch ticker data in SFM
      const alreadyPresent = sfmSelectedItemTickers.find((l) => l === item.ticker)
      // let updateArr = [...sfmSelectedItemTickers];
      if (alreadyPresent) {
        // un_select logic
        console.log('-- un_select logic --');
        if (removeSFMItemTickersRequest) {
          removeSFMItemTickersRequest({
            removeTicker: item.ticker
          })
        }
      } else {
        // select logic
        console.log('-- select logic --');
        if (addSFMItemTickersRequest) {
          addSFMItemTickersRequest({
            selectedItemTickers: item.ticker
          })
          addEventToAnalytics('Funds Select', 'Funds Select', 'FUNDS_SELECT', { query: funds.query, logId: funds.logId, ticker: item.ticker, category: 'advisory_PTC' }, true);
        }
      }
    }
    this.props.updateSelFundsInCrd(item);
    // window.addKrakenEvent({name: 'Funds Select', body: {query: funds.query, logId: funds.logId, ticker: item.ticker}});
  }

  searchFundsActiveRegion = (activeRegion) => {
    const { setFundsState } = this.props;
    setFundsState({
      activeRegion
    });
  }

  checkPopOpen = (bool) => {
    const { setFundsState } = this.props;
    setFundsState({
      firstPop: bool
    });
  }

  render() {
    const { discovery, modalToggleState, buildportfolio, sfm, profileLoading } = this.props;
    const {
      highlightText,
      data,
      chartData,
      catData,
      itemCardView,
      activeRegion,
      outPerfReturnsData,
      queryCategoryValue,
      firstPop,
      dateRangeSelected,
    } = discovery;
    let highlightText_local = {
      feesHighLightEnable: false,
      returnHighLightEnable: false,
      riskHighLightEnable: false,
    };
    const cartItems = sfm.items.filter((el) => el._selector === true);
    if (typeof chartData !== 'undefined') {
      highlightText_local = highlightText;
    }
    return (
      <React.Fragment>
        <Header
          catData={catData}
          chartData={chartData}
          highlightText={highlightText_local}
          selectHandler={this.selectHandler}
          inputRef={el => this.sliderElement = el}
          viewHandler={this.viewHandler}
        />
        {!profileLoading && (
          <List
            {...this.props}
            items={data}
            itemslist={cartItems}
            selectedCards={this.state.selectedCards}
            modalToggle={this.props.modalToggle}
            SpoofModalToggle={this.props.SpoofModalToggle}
            modalToggleState={modalToggleState}
            // sfmDateRange={this.state.dateRange}
            catData={catData}
            range={{
              start: moment(dateRangeSelected.start),
              end: moment(dateRangeSelected.end)
            }}
            view={itemCardView}
            chartData={chartData}
            highlightText={highlightText_local}
            selectHandler={this.selectHandler}
            selectBuilderHandler={this.selectBuilderHandler}
            activeRegion={activeRegion}
            searchFundsActiveRegion={this.searchFundsActiveRegion}
            outPerfReturnsData={outPerfReturnsData}
            outPerfTicker={queryCategoryValue}
            checkPopOpen={this.checkPopOpen}
            firstPop={firstPop}
            builderSelected={buildportfolio.resultPageCart.length}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ discovery, sfm, buildportfolio }) => ({
  discovery,
  sfm,
  buildportfolio,
  query: discovery.query,
  fundsData: discovery.data,
  loading: discovery.actions,
  messages: discovery.messages,
  correctedQuery: discovery.correctedQuery,
  selectedCards: sfm.items,
  modalToggleState: sfm.modalToggleState,
  clearAllFunds: sfm.clearAllFunds,
  sfmTrackFundsState: sfm.trackFundsCompareOrder,
  sfmSelectedItemTickers: sfm.selectedItemTickers,
})

const mapDispatchToProps = {
  searchQueryRequest,
  setFundsState,
  updateFundsData,
  refreshFundsState,
  setSFMState,
  setSFMItems,
  clearFundsTracking,
  scoreAttrsHandler,
  refreshWeightState,
  searchAnalyticsAPI,
  setBuildPortfolioState,
  // V-2
  updateSelFundsInCrd,
  addSFMItemTickersRequest,
  removeSFMItemTickersRequest,
  deleteAllSFMItemTickersRequest,
  addSFMItemTickersRequest,
  addTickersToCartRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemList)

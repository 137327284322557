import React, { Component, Fragment, useState } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Spinner as StrapSpin,
  Table,
  Modal,
  ModalBody
} from 'reactstrap';
import style from './TradingAccount.module.scss'
import { Radio, Select } from 'antd'
import { CustomFormFeedback, Spinner, FieldValue } from 'shared-components'
import CountrySelect from '../Profile/CountrySelect'
import StateSelect from '../Profile/StateSelect'
import plusImg from '../../../../assets/images/plus.svg'
import minusImg from '../../../../assets/images/minus.svg'
import moment from 'moment'
import {
  employmentTypeTradingAccount,
  financialGoalsTradingAccount,
  liquidityTimeTradingAccount,
  tradingExperience,
  uploadTypeTradAcc,
  investMentObjectives,
  annualIncomeRange,
  formattedRange,
  liquidNetWorth,
  totalNetWorth,
  riskTolerance,
  taxBracket,
  dividentReinvestment,
  SUPPORTED_FORMATS
} from '../../Constant/FormData'
import { downloadSnap, uploadReviewImage } from 'apis/funds'
import TransferAccModal from './TransferAccModal'
import DatePicker from 'react-datepicker'
import ConfirmModal from '../../Elements/Confirm'
import { EditableJointDetails, JointEditableDisclosure } from './JointEditableTradingAccount'
import CustomAlert from 'components/CustomAlert'
import { capitalizeAll } from '../../../utils';
import { AddMoneyStatusMapping } from '../../../../DataSet'
import { PlaidLink } from 'react-plaid-link'
import PhoneNumberInput from '../../../../components/PhoneNumberInput';
import { isEqual, keys, orderBy } from 'lodash';
import config from '../../../../env';

const imagesPath = {
  minus: minusImg,
  plus: plusImg
}
const FileSaver = require('file-saver')

class EditableTradingAccountForm extends Component {
  constructor(props) {
    super(props)
    // console.log(props)
  }

  state = {
    tview: 'order_queue',
    showMartialSelection: false,
    showSocialSecurity: false,
    showPhoneEdit: false,
    showAddressEdit: false,
    showMailingAddress: false,
    showBussinessAddress: false,
    showEmployeerName: false,
    showJointEmployeerName: false,
    showOccupation: false,
    showUpdateFinra: false,
    showUpdateFinraJoint: false,
    showTransferAccountModal: false,
    // showUpdateExposed        :false,
    isCollapseYourDetails: true,
    isCollapseDisclousure: true,
    isCollapseEmployeeDetails: true,
    isCollapseACHRelationship: true,
    isCollapseTransaction: true,
    isCollapseDownload: this.props.history.location.search.includes('downld_doc') ? false : true,
    isCollapseDownloadReport: true,
    showAffliatedWith: true,
    identity_proof: this.props.formProps.values.identity_proof,
    address_proof: this.props.formProps.values.address_proof,
    citizenship_proof: this.props.formProps.values.citizenship_proof,
    primary_firstname: this.props.formProps.values.primary_firstname,
    primary_lastname: this.props.formProps.values.primary_lastname,
    secondary_firstname: this.props.formProps.values.secondary_firstname,
    secondary_lastname: this.props.formProps.values.secondary_lastname,
    citizenship: this.props.formProps.values.citizenship,
    ssn: this.props.formProps.values.ssn,
    reg_bi: this.props.formProps.values.reg_bi,
    upload_type: this.props.formProps.values.upload_type,
    upload_file: this.props.formProps.values.upload_file,
    upload_file_name: '',
    joint_upload_file_name: '',
    showConfirmation: false,
    userInput: false,
    modalHeading: '',
    modalBtn1: '',
    modalBtn2: '',
    classType: 'rejected',
    placeholder: 'Please enter a reason',
    userInputArea: '',
    confirmModalAction: '',
    downLoadState: {
      loading: false,
      id: ''
    },
    editMode: false,
    email: '',
    invalidInput: '',
    errorMsg: '',
    error: false,
    showACHPopup: false,
    isContactUsCollapsed: true
  }

  toggleMartial = () => {
    this.setState({
      showMartialSelection: !this.state.showMartialSelection
    })
  }

  handleUserDetailsChange = (fieldName, value, toggleStateName = null, tab = "details") => {
    const { setFieldValue } = this.props.formProps
    setFieldValue(fieldName, value);
    if (tab == "details") {
      this.props.toggleUserDetailsUpdate(true);
    }
    if (tab == "discloser") {
      this.props.toggleUpdate(true);
    }
    if (toggleStateName) {
      this.setState({
        [toggleStateName]: !this.state[toggleStateName]
      });
    }
  }

  // selectMartial = (e) => {
  //   this.props.formProps.setFieldValue('marital_status', e.target.value)
  //   this.setState({
  //     showMartialSelection: !this.state.showMartialSelection
  //   });
  //   this.props.toggleUserDetailsUpdate(true);
  // }

  handleCitizen = (value, item) => {
    this.props.formProps.setFieldValue('citizenship', value);
    this.props.toggleUserDetailsUpdate(true);
  }

  handleCitizenTransferAccount = (value, item) => {
    this.props.formProps.setFieldValue('transfer_account_citizenship', value);
    this.props.toggleUserDetailsUpdate(true);
  }

  handleRelationTransferAccount = (value, item) => {
    this.props.formProps.setFieldValue('transfer_account_relation', value);
    this.props.toggleUserDetailsUpdate(true);
  }

  handleExperence = (value) => {
    this.props.formProps.setFieldValue('experience', value);
    this.props.toggleUserDetailsUpdate(true);
  }

  handleTimeHorizon = (value) => {
    const { time_horizon } = this.props.formProps.values
    if (value !== time_horizon) {
      this.props.formProps.setFieldValue('time_horizon', value)
      this.props.toggleUpdate(true)
    }
  }

  handleLiquidityNeed = (value) => {
    const { liquidity_needs } = this.props.formProps.values
    if (value !== liquidity_needs) {
      this.props.formProps.setFieldValue('liquidity_needs', value)
      this.props.toggleUpdate(true)
    }
  }

  toggleAddress = () => {
    !this.state.showAddressEdit && this.setState({
      showAddressEdit: !this.state.showAddressEdit
    })
  }

  toggleSocialSecurity = () => {
    this.setState({
      showSocialSecurity: !this.state.showSocialSecurity
    })
  }

  toggleMailingAddress = () => {
    this.setState({
      showMailingAddress: !this.state.showMailingAddress
    })
  }

  toggleBussinessAddress = () => {
    this.setState({
      showBussinessAddress: !this.state.showBussinessAddress
    })
  }

  handleBrokerage = (value) => {
    console.log(value)
    this.props.formProps.setFieldValue('brokerage', !value)
  }

  handlePoliticalExp = (value) => {
    const { values, setFieldValue } = this.props.formProps
    if (value == 'NO') {
      setFieldValue('isPoliticallyExposed', 'NO')
      this.props.toggleUpdate(true)
    } else {
      setFieldValue('isPoliticallyExposed', 'YES')
      setFieldValue('political_organization', !(values.political_organization && values.political_organization.length) ? [{
        political_organization: '',
        relation: ''
      }] : values.political_organization)
      this.props.toggleUpdate(true)
    }
    // this.props.formProps.setFieldValue('politicalexp', !value)
  }

  toggleEmployeerName = () => {
    this.setState({
      showEmployeerName: !this.state.showEmployeerName
    })
  }

  toggleJointEmployeerName = () => {
    this.setState({
      showJointEmployeerName: !this.state.showJointEmployeerName
    })
  }

  toggleOccupation = () => {
    this.setState({
      showOccupation: !this.state.showOccupation
    })
    this.props.toggleUpdate(true)
  }

  handleAffiliatedWith = () => {
    if (this.props.formProps.values.isAffiliatedExchangeOrFINRA == 'YES') {
      this.props.formProps.setFieldValue('isAffiliatedExchangeOrFINRA', 'NO')
      this.props.toggleUpdate(true)
      this.setState({ showUpdateFinra: true })
    } else {
      this.props.formProps.setFieldValue('isAffiliatedExchangeOrFINRA', 'YES')
      this.props.toggleUpdate(true)
      this.setState({ showUpdateFinra: true })
    }
  }

  handleSeniorExecutive = (value) => {
    this.props.formProps.setFieldValue('is_control_person', value == 'YES' ? 'NO' : 'YES')
    this.props.toggleUpdate(true)
  }
  handleUpload = (e, elem) => {
    e.preventDefault()
    document.getElementById(`${elem}`).click()
  }

  fileUpload = async (e, elem) => {
    if (e.target.files && e.target.files[0]) {
      const files = e.target.files;
      let formData = new FormData()
      const { account_id } = this.props.formProps.values
      let fileSize = files[0].size / 1024 / 1024;
      if (fileSize > 15) {
        return this.setState({ error: true, errorMsg: `The file ${files[0].name} is too big. (files must be under 15MB in size).`, invalidInput: 'is-invalid' }, () => setTimeout(() => {
          this.setState({ error: false, errorMsg: '' })
        }, 3000));
      } else if (!SUPPORTED_FORMATS.includes(files[0].type)) {
        return this.setState({ error: true, errorMsg: "Only .png,.jpg,.jpeg,.gif, image format are allowed", invalidInput: 'is-invalid' }, () => setTimeout(() => {
          this.setState({ error: false, errorMsg: '' })
        }, 3000));
      }
      formData.append('file', files[0])
      formData.append('tag', 'AFFILIATED_APPROVAL')
      formData.append('account', account_id)
      let res = await uploadReviewImage(formData)
      //debugger
      this.props.formProps.setFieldValue(elem, res.record_id)
      if (["upload_file", "joint_upload_file"].includes(elem)) {
        if (elem.includes("joint")) {
          this.setState({ joint_upload_file_name: files[0].name })
          this.setState({ showUpdateFinraJoint: false })
        } else {
          this.setState({ upload_file_name: files[0].name })
          this.setState({ showUpdateFinra: false })
        }
        this.props.toggleUpdate(true)
      }
    }
    // this.setState({ identity_proof: e.target.files[0] });

  }

  handleDelete = (e, elem) => {
    e.preventDefault()
    this.props.formProps.setFieldValue(elem, null)
    // this.setState({ identity_proof: null });
  }

  setTView = (view) => {
    this.setState({
      tview: view
    })
  }

  handleCountrySelection = (value, item) => {
    this.props.formProps.setFieldValue('country', value)
  }

  handleAchCancel = (id) => {
    this.toggleACHPopup()
    this.setState({
      achCancelId: id
    })
    // this.props.handleArcCancel(id)
  }

  toggleACHPopup = () => {
    this.setState({
      showACHPopup: !this.state.showACHPopup
    })
  }

  confirmAchCancel = () => {
    this.toggleACHPopup()
    this.props.handleArcCancel(this.state.achCancelId)
  }

  handleYourDClick = () => {
    return false
  }


  cancelTradingAccount = (e) => {
    e.preventDefault()
    const { account_id } = this.props.formProps.values

    this.setState({
      showConfirmation: true,
      userInput: false,
      modalHeading: 'Are you sure?',
      modalBtn1: 'Yes',
      modalBtn2: 'No',
      classType: 'approved',
      idToSend: account_id,
      confirmModalAction: 'closeAccount'
    })
  }
  transferTradingAccount = (e) => {
    e.preventDefault()
    this.setState({ showTransferAccountModal: true })
  }
  toggleTransferAccPopup = () => {
    this.setState({
      showTransferAccountModal: !this.state.showTransferAccountModal
    })
  }

  // handleStockNameChange = (e) => {
  //   this.props.formProps.setFieldValue('stock_name', e.target.value)
  //   this.props.toggleUpdate(true)
  // }

  handleEmpNameChange = (e) => {
    this.props.formProps.setFieldValue('employer_name', e.target.value)
    this.props.toggleUpdate(true)
  }

  handleOccupationChange = (e) => {
    const { occupation } = this.props.formProps.values
    this.props.formProps.setFieldValue('occupation', e.target.value)
    this.props.toggleUpdate(true)
  }

  handleEmpTypeChange = (value) => {
    const { employement_type } = this.props.formProps.values
    if (value !== employement_type) {
      this.props.formProps.setFieldValue('employement_type', value)
      this.props.toggleUpdate(true)
    }
  }

  getRequestElements = (request) => {
    console.log(this.props)
    if (Array.isArray(request)) {
      let disabled = false
      if (this.props.profile && this.props.profile.apex && this.props.profile.apex.account && this.props.profile.apex.account.length && this.props.profile.apex.account[0].status === 'SUSPENDED') {
        disabled = true
      }
      const sortedRequests = orderBy(request, ['id'], ['asc']);
      console.log(sortedRequests)
      let allCloseReq = sortedRequests.filter((r) => r.request_type === 'CLOSE_ACCOUNT');
      let allTransferReq = sortedRequests.filter((r) => r.request_type === 'TRANSFER_ACCOUNT')
      let pendingTransferReq = allTransferReq.filter((r) => r.status === 'REQUESTED')
      let finalRetrun = []
      if (allCloseReq.length) {
        finalRetrun.push(<Fragment>
          <FormGroup className={style.formGroup} key='close-acc'>
            <Label className={style.key} for='self_role'>Close Account</Label>
            <div className={`${style.cursorPointer} ${style.value}`}>
              {allCloseReq[allCloseReq.length - 1].status}
            </div>
          </FormGroup>
          {allCloseReq.length === allCloseReq.filter(v => v.status === 'REJECTED').length && <FormGroup className={`a${style.formGroup} text-right`}>
            <button disabled={disabled} className={`${style.update}`}
              onClick={(e) => this.cancelTradingAccount(e)}>
              Request Again
            </button>
          </FormGroup>}
        </Fragment>)
      } else {
        finalRetrun.push(<FormGroup className={style.formGroup} key='close-acc-send'>
          <Label className={style.key} for='self_role'>Close Account</Label>
          <button disabled={disabled} className={style.update} onClick={(e) => this.cancelTradingAccount(e)}>
            Send request
          </button>
        </FormGroup>)
      }
      if (allTransferReq.length) {
        finalRetrun.push(<Fragment>
          <FormGroup className={`${style.formGroup} ${!config.transferRequest && style.commingSoon}`} style={{ position: 'relative' }} key='transfer-acc'>
            <Label className={style.key} for='self_role'>Transfer From Another Account {!config.transferRequest && <i>(Coming Soon)</i>}</Label>
            <div className={`${style.cursorPointer} ${style.value}`}>
              {pendingTransferReq.length ?
                <Fragment>{pendingTransferReq[pendingTransferReq.length - 1].status}</Fragment> :
                <Fragment>{allTransferReq[allTransferReq.length - 1].status}</Fragment>}
            </div>
          </FormGroup>
          {allTransferReq.length === allTransferReq.filter(v => v.status === 'REJECTED').length && <FormGroup className={`a${`${style.formGroup} ${!config.transferRequest && style.commingSoon}`} text-right`}>
            <button disabled={disabled} className={`${style.update}`}
              onClick={(e) => this.transferTradingAccount(e)}>
              Request Again
            </button>
          </FormGroup>}
        </Fragment>)
      } else {
        finalRetrun.push(<FormGroup className={`${style.formGroup} ${!config.transferRequest && style.commingSoon}`} key='transfer-acc-send'>
          <Label className={style.key} for='self_role'>Transfer From Another Account {!config.transferRequest && <i>(Coming Soon)</i>}</Label>
          <button disabled={disabled} className={style.update} onClick={(e) => this.transferTradingAccount(e)}>
            Transfer Now
          </button>
        </FormGroup>)
      }

      finalRetrun.push(<FormGroup className={style.formGroup} key='update-email'>
        <Label className={style.key} for='self_role_email'>Update Apex Email</Label>
        {!this.state.editMode && <div
          className={`${style.cursorPointer} ${style.value}`}>{this.props.profile && this.props.profile.apex && this.props.profile.apex.account && this.props.profile.apex.account[0].apex_email}<i
            className={`${style.pl_10} fas fa-pencil`}
            onClick={() => this.toggleEdit(this.props.profile.apex.account[0].apex_email)}></i></div>}
        {
          this.state.editMode && <div className={`${style.cursorPointer} ${style.value}`}><Input
            className={`${this.state.invalidInput} ${style.editInput}`}
            type='text'
            placeholder={'Email'}
            name='email'
            id='email'
            value={this.state.email}
            onChange={this.handleEmailchnage}
          /><i className={`${style.pl_10} fas fa-check`} onClick={() => this.saveEmail()}></i></div>
        }
      </FormGroup>)
      return finalRetrun
    } else {
      return null
    }
  }


  toggleEdit = (apex_email) => {
    this.setState({ editMode: true, email: apex_email })
  }

  saveEmail = () => {
    const email = this.state.email
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (email) {
      if (re.test(String(email).toLowerCase())) {
        let formData = {
          apex_email: this.state.email
        }
        this.props.updateEmail(this.state.email)

        this.setState({ editMode: false, email: '', invalidInput: '', error: false, errorMsg: '' })
      } else {
        this.setState({
          error: true,
          errorMsg: 'Email is invalid',
          invalidInput: 'is-invalid'
        }, () => setTimeout(() => {
          this.setState({ error: false, errorMsg: '' })
        }, 2000))
      }
    } else {
      this.setState({ error: true, errorMsg: 'Email is required', invalidInput: 'is-invalid' }, () => setTimeout(() => {
        this.setState({ error: false, errorMsg: '' })
      }, 2000))
    }
  }

  handleEmailchnage = (event) => {
    const target = event.target
    const value = target.value
    this.setState({ [event.target.name]: value })
  }


  handleUploadChange = (value) => {
    this.props.formProps.setFieldValue('upload_type', value);
    this.props.toggleUserDetailsUpdate(true);
  }

  hideModal = () => {
    this.setState({
      showConfirmation: false,
      userInput: false,
      modalHeading: '',
      modalBtn1: '',
      modalBtn2: '',
      classType: 'rejected',
      userInputArea: '',
      idToSend: '',
      confirmModalAction: ''
    })
  }

  handleModalAction = () => {

    if (this.state.idToSend && this.state.userInputArea && this.state.confirmModalAction === 'cancelTransaction') {

      let formData = {
        id: this.state.idToSend,
        reason: this.state.userInputArea
      }
      this.props.cancelDeposits(formData)
    } else if (this.state.confirmModalAction === 'closeAccount') {
      const data = {
        apex_account_id: this.state.idToSend
      }
      this.props.cancelTradingAccount(data)
    }


    this.setState({
      showConfirmation: false,
      userInput: false,
      modalHeading: '',
      modalBtn1: '',
      modalBtn2: '',
      userInputArea: '',
      idToSend: '',
      confirmModalAction: ''
    })


  }

  handleTransactionCancel = (obj) => {

    this.setState({
      showConfirmation: true,
      userInput: true,
      modalHeading: 'Are you sure?',
      modalBtn1: 'Yes',
      modalBtn2: 'No',
      classType: 'rejected',
      idToSend: obj.id,
      userInputArea: '',
      confirmModalAction: 'cancelTransaction'
    })
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    this.setState({ [event.target.name]: value })
  }

  onDownloadSnap = async (id, name) => {
    const { downLoadState } = this.state
    try {
      downLoadState.loading = true
      downLoadState.id = name
      this.setState({
        downLoadState
      })
      let download = await downloadSnap({ 'snap': id }, true)
      downLoadState.loading = false
      downLoadState.id = name
      this.setState({
        downLoadState
      })
      const newBlob = new Blob([download.data], { type: download.headers['content-type'] })
      const extension = download.headers['content-type'].split('/')[1];
      FileSaver.saveAs(newBlob, `${name}.${extension === "octet-stream" ? "gif" : extension}`)
    } catch (e) {
      downLoadState.loading = false
      downLoadState.id = name
      this.setState({
        downLoadState
      })
      console.log(e)
    }
  }

  handleChangePoliticalOrganization = (e, name, index) => {
    const { values, setFieldValue } = this.props.formProps
    let pol = values.political_organization
    pol[index][name] = e.target.value
    setFieldValue('political_organization', pol)
    this.props.toggleUpdate(true)
  }

  addMorePoliticalRelation = () => {
    const { values, setFieldValue } = this.props.formProps
    let pol = values.political_organization
    if (pol) {
      pol.push({ political_organization: '', relation: '' })
    }
    setFieldValue('political_organization', pol)
    this.props.toggleUpdate(true)
  }

  removePoliticalRelation = (index) => {
    const { values, setFieldValue } = this.props.formProps
    let pol = values.political_organization.filter((val, i) => i !== index)
    setFieldValue('political_organization', pol)
    this.props.toggleUpdate(true)
  }

  toggleUpdateFinraJoint = (status = true) => {
    this.setState({
      showUpdateFinraJoint: status
    });
  }

  redirectTo = (accountNo, orders) => {
    // console.log(accountNo, orders);
    if (orders && orders.length > 0 && accountNo && accountNo !== '') {
      const { history } = this.props
      // console.log(history);
      if (history) {
        const jObj = {
          portfolioType: 'My Portfolio',
          portfolioId: `MAGNIFI_${accountNo}`
        }
        let urlParam = []
        for (var i in jObj) {
          urlParam.push(encodeURI(i) + '=' + encodeURI(jObj[i]))
        }
        const newPath = `/accounts/dashboard/summary?${urlParam.join('&')}`
        history.push(newPath)
      }
    }
  }

  handleFirmChange = (e) => {
    const { firm_name } = this.props.formProps.values
    this.props.formProps.setFieldValue('firm_name', e.target.value)
    this.props.toggleUpdate(true)
  }

  handleTrustedSelect = (e) => {
    const { setFieldValue, values } = this.props.formProps;
    let trusted = [...values.trusted_select];

    if (!e.target.checked && values.trusted_select.includes(e.target.name)) {
      if (trusted.length > 1)
        trusted = values.trusted_select.filter(val => val !== e.target.name);
    } else {
      trusted.push(e.target.name);
    }
    setFieldValue('trusted_select', trusted);
    this.props.toggleUserDetailsUpdate(true);
  }

  componentDidUpdate = (prev) => {
    if (!isEqual(prev.formProps.errors, this.props.formProps.errors)) {
      let filtered = keys(this.props.formProps.errors).filter(key => ['trusted_contact_email',
        'trusted_contact_city',
        'trusted_contact_country',
        'trusted_contact_email',
        'trusted_contact_firstname',
        'trusted_contact_lastname',
        'trusted_contact_mobile',
        'trusted_contact_state',
        'trusted_contact_street',
        'trusted_contact_zipcode'].includes(key));
      if (filtered.length) {
        this.props.toggleTrustedContact(true);
      }
    }
  }

  toggleContactUs = () => {
    this.setState((oldState) => {
      return {
        ...oldState,
        isContactUsCollapsed: !oldState.isContactUsCollapsed
      }
    });
  }

  getSplittedValue = (value) => {
    let formattedIndex = formattedRange.findIndex(el => Object.keys(el)[0] === value)
    if (formattedIndex >= 0) {
      return formattedRange[formattedIndex][value]
    }
    return value
  }

  render() {
    // console.log(this.state);
    const { formProps } = this.props;
    const {
      values,
      initialValues,
      touched,
      errors,
      handleChange,
      handleBlur,
      setFieldValue
    } = this.props.formProps
    let tradeReq = this.props.profile.apex.trade_request
    let deposits = this.props.profile.apex.deposits
    let account_no = this.props.profile.apex.account && this.props.profile.apex.account[0].account_no
    let orders = this.props.profile.apex.orders
    let request = this.props.profile.apex.request
    if (deposits && Array.isArray(deposits)) {
      // deposits.length = 10
    }
    let newAchData = this.props.newAchData
    const { Option } = Select
    // console.log("formik props",this.props)
    // console.log("political_organization state", values.political_organization)
    console.log('==== annualIncomeRange ====');
    console.log(annualIncomeRange);
    const {
      showMartialSelection,
      showAddressEdit,
      showSocialSecurity,
      showBussinessAddress,
      showMailingAddress,
      showEmployeerName,
      showJointEmployeerName,
      showOccupation,
      isCollapseEmployeeDetails,
      isCollapseDisclousure,
      isCollapseYourDetails,
      isCollapseTransaction,
      isCollapseACHRelationship,
      isCollapseDownloadReport,
      isCollapseDownload,
      showUpdateFinra,
      showTransferAccountModal,
      upload_file_name,
      joint_upload_file_name,
      downLoadState,
      showUpdateFinraJoint,
      isContactUsCollapsed
    } = this.state;
    const { showUpdate, showUserDetailsUpdate, toggleUserDetailsUpdate, showTrustedContactSelection, toggleTrustedContact, showPoliticallyExposed,
      showPoliticallyExposedJoint, togglePoliticallyExposed, togglePoliticallyExposedJoint } = this.props;
    const { handleUserDetailsChange, handleTrustedSelect, handlePoliticalExp } = this;
    return (
      <div className={`newProfile ${style.editForm}`}>
        {this.state.error &&
          <div>
            <CustomAlert className='alert-windown-wrapper' color='danger'>
              {this.state.errorMsg}
            </CustomAlert>
          </div>
        }

        {this.state.showConfirmation &&
          <ConfirmModal
            userInput={this.state.userInput}
            heading={this.state.modalHeading}
            type={this.state.classType}
            btn1={this.state.modalBtn1}
            btn2={this.state.modalBtn2}
            closeModal={this.hideModal}
            handleModalAction={this.handleModalAction}
            placeholder={this.state.placeholder}
            userInputArea={this.state.userInputArea}
            onChange={this.handleInputChange}
          />
        }

        <div className={style.sectionHeader}
          onClick={() => this.setState({ isCollapseYourDetails: !this.state.isCollapseYourDetails })}
        >
          <span className={style.subHeader}>Your Details</span>
          {/* <i class={!isCollapseYourDetails?"fas fa-plus-circle":"fas fa-minus-circle"} onClick={()=> this.setState({isCollapseYourDetails:!this.state.isCollapseYourDetails})} ></i> */}
          <img style={{ width: 20 }} src={!isCollapseYourDetails ? imagesPath.minus : imagesPath.plus} />
        </div>
        {values.customerType === "JOINT" && !isCollapseYourDetails ?
          <EditableJointDetails
            formProps={this.props.formProps}
            handleCitizen={this.handleCitizen}
            handleYourDClick={this.handleYourDClick}
            handleExperence={this.handleExperence}
            tradingExperience={this.tradingExperience}
            handleUserDetailsChange={handleUserDetailsChange}
            showMartialSelection={showMartialSelection}
            showUserDetailsUpdate={showUserDetailsUpdate}
            toggleMartial={this.toggleMartial}
            toggleSocialSecurity={this.toggleSocialSecurity}
            toggleTrustedContact={toggleTrustedContact}
            showTrustedContactSelection={showTrustedContactSelection}
            toggleUserDetailsUpdate={toggleUserDetailsUpdate}
            togglePoliticallyExposed={togglePoliticallyExposed}
            togglePoliticallyExposedJoint={togglePoliticallyExposedJoint}
          /> :
          <Fragment>
            {!isCollapseYourDetails &&
              <>
                {/* APPLICANT */}
                <FormGroup className={`${style.addressGroup} ${style.formGroup} `}>
                  <Label className={style.key} for='address'>Applicant</Label>
                  {/* {!showAddressEdit && <div onClick={this.toggleAddress}
                  className={`${style.cursorPointer} ${style.value}`}>Select</div>} */}
                  <div
                    className={style.value}
                  >
                    {values.customerType}
                  </div>
                </FormGroup>
                {/*Marital Status*/}
                <FormGroup className={style.formGroup}>
                  <Label className={style.key} for='marital'>Marital Status</Label>
                  {!showMartialSelection &&
                    <div
                      onClick={this.toggleMartial}
                      className={`${style.cursorPointer}
                  ${style.value}`}
                    >
                      {values.marital_status === 'married' ? 'Married' : values.marital_status === 'single' ? 'Single' : 'Not Specified'}
                    </div>}
                  {showMartialSelection &&
                    <div className={style.genderSelection}>
                      <Radio.Group id={'martial'} onChange={(e) => handleUserDetailsChange('marital_status', e.target.value, 'showMartialSelection')} value={values.marital_status}>
                        <Radio value={'married'}>Married</Radio>
                        <Radio value='single'>Single</Radio>
                        <Radio value={''}>Not Specified</Radio>
                      </Radio.Group>
                    </div>
                  }
                </FormGroup>
                {/* CITIZENSHIP */}
                <FormGroup className={style.formGroup}>
                  <Label className={style.key} for='self_role'>Citizenship</Label>
                  <div
                    onClick={this.handleYourDClick}
                    className={`${style.cursorNotAllowed} ${style.value}`}
                  >
                    <FieldValue className={style.value} dashIfEmpty>
                      {values.citizenship}
                    </FieldValue>
                  </div>
                </FormGroup>
                {/* SOCIAL SECURITY NUMBER */}
                <FormGroup className={style.formGroup}>
                  <Label className={style.key} for='ssn'>Social Security Number</Label>
                  {!showSocialSecurity &&
                    <div
                      onClick={this.toggleSocialSecurity && this.handleYourDClick}
                      className={`${style.cursorNotAllowed} ${style.value}`}>
                      {values.ssn ? values.ssn : 'Select'}
                    </div>
                  }
                  {showSocialSecurity && <div className={style.errorWithFeedback}>
                    <Input
                      type='text'
                      name='ssn'
                      id='ssn'
                      placeholder={'Social Security number'}
                      value={values.ssn}
                      onChange={(e) => handleUserDetailsChange('ssn', e.target.value)}
                      onBlur={handleBlur}
                      disabled
                      className={errors.phone && touched.phone ? `is-invalid ${style.inputInvalid}` : style.input2}
                    />
                    <CustomFormFeedback formProps={this.props.formProps} fieldName='ssn' />
                  </div>}
                </FormGroup>
                {/* INVESTING EXPERENCE */}
                <FormGroup className={style.formGroup}>
                  <Label className={style.key} for='experience'>Investment Experience</Label>
                  <Select
                    type='select'
                    name='experience'
                    id='experience'
                    value={values.experience}
                    onChange={(val) => handleUserDetailsChange('experience', val)}
                    bordered={false}
                    suffixIcon={null}
                    dropdownMatchSelectWidth={false}
                    dropdownClassName={style.dropdownANT}
                    onBlur={handleBlur}
                    className={
                      errors.self_role && touched.self_role ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                    }
                  >
                    {
                      tradingExperience.map((item) => {
                        return <Option key={`experience ${item.value}`} value={item.value}>{item.value}</Option>
                      })
                    }
                  </Select>
                  <CustomFormFeedback
                    formProps={this.props.formProps}
                    fieldName='experience'
                  />
                </FormGroup>

                {/* TRUSTED CONTACT */}
                <FormGroup className={style.formGroup} style={{ height: 'auto' }}>
                  <div className={style.formBoxPanel}>
                    <div className={style.trustedPerson}>
                      <Label className={style.key} style={{ marginBottom: 'auto' }} for='trustedContacts'>Trusted Contact Person</Label>
                      {!showTrustedContactSelection &&
                        <div
                          className={`${style.cursorPointer} ${style.value}`}
                          style={{ height: 'auto' }}
                          onClick={(e) => toggleTrustedContact(true)}
                        >
                          <div className={style.trustedData}>
                            <div>{values.trusted_contact ? "YES" : "NO"}</div>
                            {values.trusted_contact &&
                              <Fragment>
                                <div>{`${values.trusted_contact_firstname} ${values.trusted_contact_lastname}`}</div>
                                {values.trusted_select.includes("trusted_email") &&
                                  <Fragment>
                                    <div>{values.trusted_contact_email}</div>
                                  </Fragment>
                                }
                                {values.trusted_select.includes("trusted_phone") &&
                                  <Fragment>
                                    <div>{values.trusted_contact_country_code} {values.trusted_contact_mobile}</div>
                                  </Fragment>
                                }
                                {values.trusted_select.includes("trusted_address") &&
                                  <Fragment>
                                    <div>{values.trusted_contact_street} {values.trusted_contact_city} {values.trusted_contact_state} {values.trusted_contact_country} {values.trusted_contact_zipcode}</div>
                                  </Fragment>
                                }
                              </Fragment>
                            }
                          </div>
                        </div>}
                      {showTrustedContactSelection &&
                        <div className={style.genderSelection}>
                          <Radio.Group id={'trusted_contact'} onChange={(e) => handleUserDetailsChange('trusted_contact', e.target.value)} value={values.trusted_contact}>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </div>

                      }
                    </div>
                    {values.trusted_contact && showTrustedContactSelection ?
                      <div className={style.trustedContactContainer}>
                        <FormGroup className="element-box pos-rel">

                          <div className={style.formBox_view}>
                            <div className={style.leftPart}>Name</div>
                            <div className={style.rightPart}>
                              <div className={style.errorWithFeedback}>
                                <Input
                                  type='text'
                                  name='trusted_contact_firstname'
                                  id='trusted_contact_firstname'
                                  placeholder="First Name"
                                  value={values.trusted_contact_firstname}
                                  onChange={(e) => handleUserDetailsChange('trusted_contact_firstname', e.target.value)}
                                  onBlur={handleBlur}
                                  className={errors.trusted_contact_firstname && touched.trusted_contact_firstname ? `is-invalid ${style.inputInvalid}` : style.input}
                                />
                                <CustomFormFeedback
                                  formProps={formProps}
                                  fieldName='trusted_contact_firstname'
                                />
                              </div>
                              <div className={style.errorWithFeedback}>
                                <Input
                                  type='text'
                                  name='trusted_contact_lastname'
                                  id='trusted_contact_lastname'
                                  placeholder="Last Name"
                                  value={values.trusted_contact_lastname}
                                  onChange={(e) => handleUserDetailsChange('trusted_contact_lastname', e.target.value)}
                                  onBlur={handleBlur}
                                  className={errors.trusted_contact_lastname && touched.trusted_contact_lastname ? `is-invalid ${style.inputInvalid}` : style.input}
                                />
                                <CustomFormFeedback
                                  formProps={formProps}
                                  fieldName='trusted_contact_lastname'
                                />
                              </div>
                            </div>
                          </div>

                        </FormGroup>
                        <FormGroup className="element-box pos-rel">
                          <div className={style.formBox_view}>
                            <div className={style.leftPart}>
                              <Label className={style.key} style={{ marginBottom: 'auto' }} for='trustedContacts'>Email</Label>
                              <Input
                                type="checkbox"
                                name="trusted_email"
                                id="trusted_email"
                                checked={values.trusted_select.includes("trusted_email")}
                                onChange={handleTrustedSelect}
                                className={errors.trusted_email && touched.trusted_email ? style.inputInvalid
                                  : style.checkboxInput}
                              />
                            </div>

                            <div className={style.rightPart}>
                              {values.trusted_select.includes("trusted_email") &&
                                <div className={style.secInfo}>
                                  <Input
                                    type='email'
                                    name='trusted_contact_email'
                                    id='trusted_contact_email'
                                    placeholder="Email"
                                    value={values.trusted_contact_email}
                                    onChange={(e) => handleUserDetailsChange('trusted_contact_email', e.target.value)}
                                    onBlur={handleBlur}
                                    className={errors.trusted_contact_email && touched.trusted_contact_email ? `is-invalid ${style.inputInvalid}` : style.input}
                                  />
                                  <CustomFormFeedback
                                    formProps={formProps}
                                    fieldName='trusted_contact_email'
                                  />
                                </div>
                              }
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup className="element-box pos-rel">
                          <div className={style.formBox_view}>
                            <div className={style.leftPart}>
                              <Label className={style.key} style={{ marginBottom: 'auto' }} for='trustedContacts'>Phone</Label>
                              <Input
                                type="checkbox"
                                name="trusted_phone"
                                id="trusted_phone"
                                checked={values.trusted_select.includes("trusted_phone")}
                                onChange={handleTrustedSelect}
                                className={errors.trusted_phone && touched.trusted_phone ? style.inputInvalid
                                  : style.checkboxInput}
                              />
                            </div>
                            <div className={style.rightPart}>
                              {values.trusted_select.includes("trusted_phone") &&
                                <div className={`${style.errorWithFeedback} phoneContainer`}>
                                  <PhoneNumberInput
                                    hideCode={true}
                                    onChangePhoneCode={(val) => handleUserDetailsChange('trusted_contact_country_code', val)}
                                    errors={errors}
                                    touched={touched}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    phoneCodeName="trusted_contact_country_code"
                                    phoneName="trusted_contact_mobile"
                                  />
                                  {errors.trusted_contact_mobile && touched.trusted_contact_mobile && <div className={style.formInvalidField}>{errors['trusted_contact_mobile']}</div>}
                                </div>
                              }
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup className="element-box pos-rel">
                          <div className={style.formBox_view}>
                            <div className={style.leftPart}>
                              <Label className={style.key} style={{ marginBottom: 'auto' }} for='trustedContacts'>Address</Label>
                              <Input
                                type="checkbox"
                                name="trusted_address"
                                id="trusted_address"
                                checked={values.trusted_select.includes("trusted_address")}
                                onChange={handleTrustedSelect}
                                className={errors.trusted_address && touched.trusted_address ? style.inputInvalid
                                  : style.checkboxInput}
                              />
                            </div>
                            <div className={style.rightPart}>
                              {values.trusted_select.includes("trusted_address") &&
                                <Fragment>
                                  <div className="row spacing-5 ml-5">
                                    <div className="col-12 col-sm-12 col-md-4">
                                      <FormGroup className="element-box pos-rel pl-2">
                                        <CountrySelect
                                          trusted
                                          dropdownClassName={style.dropdownANT}
                                          className={`${style.input} ${style.antSelect}`}
                                          value={values.trusted_contact_country}
                                          onChange={(val) => handleUserDetailsChange('trusted_contact_country', val)}
                                          bordered={false}
                                          suffixIcon={null}
                                          dropdownMatchSelectWidth={false}
                                          onBlur={handleBlur}
                                        />
                                        {errors.trusted_contact_country && touched.trusted_contact_country && <div className={style.formInvalidField}>{errors['trusted_contact_country']}</div>}
                                      </FormGroup>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4">
                                      <FormGroup className="element-box pos-rel pl-2">
                                        <StateSelect
                                          dropdownClassName={style.dropdownANT}
                                          className={`${style.input} ${style.antSelect}`}
                                          value={values.trusted_contact_state}
                                          onChange={(val) => handleUserDetailsChange('trusted_contact_state', val)}
                                          bordered={false}
                                          suffixIcon={null}
                                          dropdownMatchSelectWidth={false}
                                          onBlur={handleBlur}
                                        />
                                        {errors.trusted_contact_state && touched.trusted_contact_state && <div className={style.formInvalidField}>{errors['trusted_contact_state']}</div>}
                                      </FormGroup>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4">
                                      <FormGroup className="element-box pos-rel pl-2">
                                        <Input
                                          type='text'
                                          placeholder={'Street Address'}
                                          name='trusted_contact_street'
                                          id='trusted_contact_street'
                                          value={values.trusted_contact_street}
                                          onChange={(e) => handleUserDetailsChange('trusted_contact_street', e.target.value)}
                                          onBlur={handleBlur}
                                          className={errors.trusted_contact_street && touched.trusted_contact_street ? style.inputInvalid : style.input} />
                                        {errors.trusted_contact_street && touched.trusted_contact_street && <div className={style.formInvalidField}>{errors['trusted_contact_street']}</div>}
                                      </FormGroup>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4">
                                      <FormGroup className="element-box pos-rel pl-2">
                                        <Input
                                          type='text'
                                          placeholder={'City'}
                                          name='trusted_contact_city'
                                          id='trusted_contact_city'
                                          value={values.trusted_contact_city}
                                          onChange={(e) => handleUserDetailsChange('trusted_contact_city', e.target.value)}
                                          onBlur={handleBlur}
                                          className={errors.trusted_contact_city && touched.trusted_contact_city ? style.inputInvalid : style.input} />
                                        {errors.trusted_contact_city && touched.trusted_contact_city && <div className={style.formInvalidField}>{errors['trusted_contact_city']}</div>}
                                      </FormGroup>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4">
                                      <FormGroup className="element-box pos-rel pl-2">
                                        <Input
                                          type='text'
                                          name='trusted_contact_zipcode'
                                          id='trusted_contact_zipcode'
                                          placeholder={'Zipcode'}
                                          value={values.trusted_contact_zipcode}
                                          onChange={(e) => handleUserDetailsChange('trusted_contact_zipcode', e.target.value)}
                                          onBlur={handleBlur}
                                          className={errors.trusted_contact_zipcode && touched.trusted_contact_zipcode ? `is-invalid ${style.inputInvalid}` : style.input}
                                        />
                                        <CustomFormFeedback
                                          formProps={formProps}
                                          fieldName='trusted_contact_zipcode'
                                        />
                                      </FormGroup>
                                    </div>

                                  </div>



                                </Fragment>
                              }
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                      : null}
                  </div>

                </FormGroup>
                {/* BUSINESS ADDRESS */}
                <FormGroup className={style.formGroup}>
                  <Label className={style.key} for='bussinessAddress'>Business Address</Label>
                  {!showBussinessAddress && <div onClick={this.toggleBussinessAddress && this.handleYourDClick}
                    className={`${style.cursorNotAllowed} ${style.value}`}>
                    {values.addressB} {values.cityB} {values.stateB} {values.countryB} {values.zipcodeB}
                  </div>}
                  {showBussinessAddress && <div className={style.addressInfo}>
                    <div className={style.firstInfo}>
                      <CountrySelect
                        dropdownClassName={style.dropdownANT}
                        className={`${style.input} ${style.antSelect}`}
                        value={values.countryB}
                        onChange={(e) => handleUserDetailsChange('countryB', e)}
                        bordered={false}
                        suffixIcon={null}
                        dropdownMatchSelectWidth={false}
                        onBlur={handleBlur}
                      />
                      <StateSelect
                        dropdownClassName={style.dropdownANT}
                        className={`${style.input} ${style.antSelect}`}
                        name="stateB"
                        value={values.stateB}
                        onChange={(e) => handleUserDetailsChange('stateB', e)}
                        bordered={false}
                        suffixIcon={null}
                        dropdownMatchSelectWidth={false}
                        onBlur={handleBlur}
                      />
                      <Input
                        type='text'
                        placeholder={'Street Address'}
                        name='addressB'
                        id='addressB'
                        value={(e) => handleUserDetailsChange('addressB', e.target.value)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.address && touched.address ? style.inputInvalid : style.input} />

                    </div>
                    <div className={style.secInfo}>
                      <Input
                        type='text'
                        placeholder={'City'}
                        name='cityB'
                        id='cityB'
                        value={values.cityB}
                        onChange={(e) => handleUserDetailsChange('cityB', e.target.value)}
                        onBlur={handleBlur}
                        className={errors.cityB && touched.cityB ? style.inputInvalid : style.input} />
                      <div className={style.errorWithFeedback}>
                        <Input
                          type='text'
                          name='zipcodeB'
                          id='zipcodeB'
                          placeholder={'Zipcode'}
                          value={values.zipcodeB}
                          onChange={(e) => handleUserDetailsChange('zipcodeB', e.target.value)}
                          onBlur={handleBlur}
                          className={errors.zipcodeB && touched.zipcodeB ? `is-invalid ${style.inputInvalid}` : style.input}
                        />
                        <CustomFormFeedback
                          formProps={this.props.formProps}
                          fieldName='zipcodeB'
                        />
                      </div>
                    </div>
                  </div>}
                </FormGroup>



                <FormGroup className={`${style.addressGroup} ${style.formGroup} `}>
                  <Label className={style.key} for='address'>Investment Objective</Label>
                  <div className={style.errorWithFeedback}>
                    <Select
                      type='select'
                      name='investmentObjective'
                      id='investmentObjective'
                      value={values.investmentObjective}
                      onChange={(val) => handleUserDetailsChange('investmentObjective', val)}
                      bordered={false}
                      suffixIcon={null}
                      dropdownMatchSelectWidth={false}
                      dropdownClassName={style.dropdownANT}
                      onBlur={handleBlur}
                      className={
                        errors.self_role && touched.self_role ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                      }
                    >
                      <Option value='' disabled>Select</Option>
                      {investMentObjectives.map((item, i) => {
                        return <Option key={`investmentObjective${i}`} value={item} >{capitalizeAll(item.split('_').map(i => i.toLowerCase()).join(' '))}</Option>
                      })}
                    </Select>
                    {errors.investmentObjective && touched.investmentObjective && <div className={style.formInvalidField}>{errors['investmentObjective']}</div>}
                  </div>
                </FormGroup>

                <FormGroup className={`${style.addressGroup} ${style.formGroup} `}>
                  <Label className={style.key} for='address'>Annual Income</Label>
                  <div className={style.errorWithFeedback}>
                    <Select
                      type='select'
                      name='annualIncomeUSD'
                      id='annualIncomeUSD'
                      value={values.annualIncomeUSD}
                      onChange={(val) => handleUserDetailsChange('annualIncomeUSD', val)}
                      bordered={false}
                      suffixIcon={null}
                      dropdownMatchSelectWidth={false}
                      dropdownClassName={style.dropdownANT}
                      onBlur={handleBlur}
                      className={
                        errors.self_role && touched.self_role ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                      }
                    >
                      <Option value='' disabled>Select</Option>
                      {annualIncomeRange.map((item, i) => {
                        return <Option key={`annualIncomeRange${i}`} value={item} >{this.getSplittedValue(capitalizeAll(item.split('_').map(i => i.toLowerCase()).join(' ')))}</Option>
                      })}
                    </Select>
                    {errors.annualIncomeUSD && touched.annualIncomeUSD && <div className={style.formInvalidField}>{errors['annualIncomeUSD']}</div>}
                  </div>
                </FormGroup>
                <FormGroup className={`${style.addressGroup} ${style.formGroup} `}>
                  <Label className={style.key} for='address'>Total Net Worth</Label>
                  <div className={style.errorWithFeedback}>
                    <Select
                      type='select'
                      name='totalNetWorthUSD'
                      id='totalNetWorthUSD'
                      value={values.totalNetWorthUSD}
                      onChange={(val) => handleUserDetailsChange('totalNetWorthUSD', val)}
                      bordered={false}
                      suffixIcon={null}
                      dropdownMatchSelectWidth={false}
                      dropdownClassName={style.dropdownANT}
                      onBlur={handleBlur}
                      className={
                        errors.self_role && touched.self_role ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                      }
                    >
                      <Option value='' disabled>Select</Option>
                      {totalNetWorth.map((item, i) => {
                        return <Option key={`totalNetWorth${i}`} value={item} >{this.getSplittedValue(capitalizeAll(item.split('_').map(i => i.toLowerCase()).join(' ')))}</Option>
                      })}
                    </Select>
                    {errors.totalNetWorthUSD && touched.totalNetWorthUSD && <div className={style.formInvalidField}>{errors['totalNetWorthUSD']}</div>}
                  </div>
                </FormGroup>
                {/* <FormGroup className={`${style.addressGroup} ${style.formGroup} `}>
                <Label className={style.key} for='address'>Risk Tolerance</Label>
                <div className={style.errorWithFeedback}>
                  <Select
                    type='select'
                    name='riskTolerance'
                    id='riskTolerance'
                    value={values.riskTolerance}
                    onChange={(val) => handleUserDetailsChange('riskTolerance', val)}
                    bordered={false}
                    suffixIcon={null}
                    dropdownMatchSelectWidth={false}
                    dropdownClassName={style.dropdownANT}
                    onBlur={handleBlur}
                    className={
                      errors.self_role && touched.self_role ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                    }
                  >
                    <Option value='' disabled>Select</Option>
                    {riskTolerance.map((item,i) => {
                            return <Option key={`riskTolerance${i}`} value={item} >{capitalizeAll(item.split('_').map(i=>i.toLowerCase()).join(' '))}</Option>
                          })}
                  </Select>
                  {errors.riskTolerance && touched.riskTolerance && <div className={style.formInvalidField}>{errors['riskTolerance']}</div>}
                </div>
              </FormGroup> */}
                {/* <FormGroup className={`${style.addressGroup} ${style.formGroup} `}>
                <Label className={style.key} for='address'>Tax Bracket</Label>
                <div className={style.errorWithFeedback}>
                  <Input
                    type='text'
                    placeholder={''}
                    name='federalTaxBracketPercent'
                    id='federalTaxBracketPercent'
                    value={values.federalTaxBracketPercent}
                    onChange={(e) => handleUserDetailsChange('federalTaxBracketPercent', e.target.value)}
                    onBlur={handleBlur}
                    className={errors.federalTaxBracketPercent && touched.federalTaxBracketPercent ? style.inputInvalid : `${style.input} ${style.styledInput}`} />
                    {errors.federalTaxBracketPercent && touched.federalTaxBracketPercent && <div className={style.formInvalidField}>{errors['federalTaxBracketPercent']}</div>}
                </div>
              </FormGroup> */}
                <FormGroup className={`${style.addressGroup} ${style.formGroup} `}>
                  <Label className={style.key} for='address'>Dividend Reinvestment</Label>
                  <div className={style.errorWithFeedback}>
                    <Select
                      type='select'
                      name='dividendReinvestment'
                      id='dividendReinvestment'
                      value={values.dividendReinvestment}
                      onChange={(val) => handleUserDetailsChange('dividendReinvestment', val)}
                      bordered={false}
                      suffixIcon={null}
                      dropdownMatchSelectWidth={false}
                      dropdownClassName={style.dropdownANT}
                      onBlur={handleBlur}
                      className={
                        errors.self_role && touched.self_role ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                      }
                    >
                      <Option value='' disabled>Select</Option>
                      {dividentReinvestment.map((item, i) => {
                        return <Option key={`dividendReinvestment${i}`} value={item} >{capitalizeAll(item.split('_').map(i => i.toLowerCase()).join(' '))}</Option>
                      })}
                    </Select>
                    {errors.dividendReinvestment && touched.dividendReinvestment && <div className={style.formInvalidField}>{errors['dividendReinvestment']}</div>}
                  </div>
                </FormGroup>
                {showUserDetailsUpdate && <div className={`${style.formGroup} ${style.btnFlexEnd}`}>
                  <button type='submit' className={style.update}>
                    Update
                  </button>
                </div>}
              </>
            }
          </Fragment>
        }

        <div className={style.divider} />

        <div className={style.sectionHeader}
          onClick={() => this.setState({ isCollapseDisclousure: !this.state.isCollapseDisclousure })}
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span className={style.subHeader}>Disclosure</span>
          {/* <i class={!isCollapseDisclousure?"fas fa-plus-circle":"fas fa-minus-circle"} onClick={()=> this.setState({isCollapseDisclousure:!this.state.isCollapseDisclousure})} ></i> */}
          <img style={{ width: 20 }} src={!isCollapseDisclousure ? imagesPath.minus : imagesPath.plus} />
        </div>
        {!isCollapseDisclousure &&
          <Fragment>
            {values.customerType === 'JOINT' ?

              <JointEditableDisclosure
                formProps={this.props.formProps}
                showUpdateFinraJoint={showUpdateFinraJoint}
                showUpdateFinra={showUpdateFinra}
                toggleUpdateFinraJoint={this.toggleUpdateFinraJoint}
                showUpdate={showUpdate}
                showEmployeerName={showEmployeerName}
                showJointEmployeerName={showJointEmployeerName}
                toggleJointEmployeerName={this.toggleJointEmployeerName}
                upload_file_name={upload_file_name}
                joint_upload_file_name={joint_upload_file_name}
                downLoadState={downLoadState}
                fileUpload={this.fileUpload}
                toggleUpdate={this.props.toggleUpdate}
                handleSeniorExecutive={this.handleSeniorExecutive}
                handleAffiliatedWith={this.handleAffiliatedWith}
                handleUploadChange={this.handleUploadChange}
                handleUpload={this.handleUpload}
                onDownloadSnap={this.onDownloadSnap}
                handleDelete={this.handleDelete}
                handlePoliticalExp={this.handlePoliticalExp}
                handleChangePoliticalOrganization={this.handleChangePoliticalOrganization}
                removePoliticalRelation={this.removePoliticalRelation}
                addMorePoliticalRelation={this.addMorePoliticalRelation}
                handleEmpTypeChange={this.handleEmpTypeChange}
                toggleEmployeerName={this.toggleEmployeerName}
                handleOccupationChange={this.handleOccupationChange}
                handleLiquidityNeed={this.handleLiquidityNeed}
                handleTimeHorizon={this.handleTimeHorizon}
                handleEmpNameChange={this.handleEmpNameChange}
                upload_file_name={upload_file_name}
                handleFirmChange={this.handleFirmChange}
                handleUserDetailsChange={handleUserDetailsChange}
                togglePoliticallyExposed={togglePoliticallyExposed}
                togglePoliticallyExposedJoint={togglePoliticallyExposedJoint}
                showPoliticallyExposed={showPoliticallyExposed}
                showPoliticallyExposedJoint={showPoliticallyExposedJoint}
              /> :

              <Fragment>
                <div className={`${style.addressGroup} ${style.formGroup} `}>
                  <div className={style.key}>You/Family members is a senior executive or 10% shareholder at a public
                    trading company
                  </div>
                  <div style={{ minWidth: `40%` }} className={style.addressInfo}>
                    <div style={{ flexDirection: 'column', alignItems: 'flex-end' }} className={style.firstInfo}>
                      <div id='is_control_person'
                        className={`${style.AccIve} ${style.value}`}
                        onClick={e => {
                          e.preventDefault()
                          this.handleSeniorExecutive(values.is_control_person)
                        }}
                      >
                        {values.is_control_person == 'YES' ? 'Yes' : 'No'}
                      </div>
                      {values.is_control_person == 'YES' ?
                        <div style={{ display: 'flex', width: `100%`, justifyContent: `flex-end` }}>
                          <div className={`${style.AccIve} ${style.value}`}>Stock Name</div>
                          <Input
                            style={{ width: `120px` }}
                            type='text'
                            required
                            placeholder={'Company Name'}
                            name='stock_name'
                            id='stock_name'
                            value={values.stock_name}
                            onChange={(e) => handleUserDetailsChange('stock_name', e.target.value, null, 'discloser')}
                            onBlur={handleBlur}
                            className={errors.stock_name && touched.stock_name ? style.inputInvalid : `${style.input} ${style.styledInput}`}
                          // disabled={true}
                          />
                        </div>
                        : null
                      }
                    </div>
                  </div>
                </div>

                <div className={`${style.addressGroup} ${style.formGroup} `}>
                  <div className={style.key}>You/Family, or anyone authorized to trade in your account, affiliated with,
                    work with or work for a member firm of a stock exchange or FINRA?
                  </div>
                  <div id='isAffiliatedExchangeOrFINRA'
                    onClick={e => {
                      e.preventDefault()
                      this.handleAffiliatedWith()
                    }}
                    className={`${style.AccIve} ${style.value}`}>{values.isAffiliatedExchangeOrFINRA == 'YES' ? 'Yes' : 'No'}
                  </div>
                </div>
                {values.isAffiliatedExchangeOrFINRA == 'YES' &&
                  <>
                    <FormGroup className={style.formGroup}>
                      <Label className={style.key} for='firm_name'>Firm Name</Label>

                      <div className={style.errorWithFeedback}>
                        <Input
                          type='text'
                          name='firm_name'
                          id='firm_name'
                          placeholder={'Firm Name'}
                          value={values.firm_name}
                          onChange={(e) => this.handleFirmChange(e)}
                          onBlur={handleBlur}
                          className={errors.firm_name && touched.firm_name ? `is-invalid ${style.inputInvalid}` : `${style.input2} ${style.styledInput}`}
                        />
                        <CustomFormFeedback formProps={this.props.formProps} fieldName='firm_name' />
                      </div>
                    </FormGroup>
                    {/* <div className={style.addressInfo}>
                      <div className={style.firstInfo}> */}

                    <FormGroup className={style.formGroup}>
                      <Label className={style.key} for='employement_type'>Upload Type</Label>
                      <div className={style.errorWithFeedback}>
                        <Select
                          type='select'
                          name='upload_type'
                          id='upload_type'
                          value={values.upload_type}
                          onChange={this.handleUploadChange}
                          bordered={false}
                          suffixIcon={null}
                          dropdownMatchSelectWidth={false}
                          dropdownClassName={style.dropdownANT}
                          onBlur={handleBlur}
                          className={
                            errors.upload_type && touched.upload_type ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                          }
                        >
                          {
                            uploadTypeTradAcc.map((item) => {
                              return <Option disabled={item.disabled} value={item.value}>{item.name}</Option>
                            })
                          }
                        </Select>
                        {errors.upload_type && touched.upload_type &&
                          <div className={style.formInvalidField}>{errors.upload_type}</div>}
                      </div>
                    </FormGroup>

                    {
                      values.upload_type !== '' &&
                      <FormGroup className={style.formGroup}>
                        <Label className={style.key} for='upload_file'>
                          {values.upload_file && this.state.upload_file_name}
                        </Label>
                        <div className={style.addressInfo}>
                          <div className={style.firstInfo}>
                            <input id='upload_file' accept="image/*" type='file' style={{ visibility: 'hidden', width: 0 }}
                              onChange={(e) => this.fileUpload(e, 'upload_file')} />
                            <button onClick={(e) => this.handleUpload(e, 'upload_file')} className={style.update}>
                              Upload
                            </button>
                          </div>
                        </div>
                      </FormGroup>
                    }
                    {values.upload_3210 !== '' && !showUpdateFinra &&
                      <div className={`${style.addressGroup} ${style.formGroup} `}>
                        <div className={style.key}>3210 Letter</div>
                        <div className={style.addressInfo}>
                          <div className={style.firstInfo}>
                            <input id='identity_proof' accept="image/*" type='file' style={{ visibility: 'hidden', width: 0 }}
                              onChange={(e) => this.fileUpload(e, 'identity_proof')} />
                            {values.identity_proof && <div>{values.identity_proof}</div>}
                            {this.state.downLoadState.id === 'upload_3210' && this.state.downLoadState.loading &&
                              <StrapSpin />}
                            <button className={`${style.key} ${style.dwnContainer}`}
                              disabled={downLoadState.loading}
                              onClick={() => this.onDownloadSnap(values.upload_3210, 'upload_3210')}>
                              <i class='fa fa-download' aria-hidden='true' style={{ color: '#56a9e7', marginLeft: 5 }}></i>
                              {values.identity_proof &&
                                <i className='far fa-fw fa-trash-alt' style={{ color: '#9f9f9f', marginLeft: 5 }}
                                  onClick={(e) => this.handleDelete(e, 'identity_proof')}></i>}
                            </button>
                          </div>
                        </div>
                      </div>}
                    {values.upload_407 !== '' && !showUpdateFinra &&
                      <div className={`${style.addressGroup} ${style.formGroup} `}>
                        <div className={style.key}>407 Letter</div>
                        <div className={style.addressInfo}>
                          <div className={style.firstInfo}>
                            <input id='address_proof' accept="image/*" type='file' style={{ visibility: 'hidden', width: 0, height: 0 }}
                              onChange={(e) => this.fileUpload(e, 'address_proof')} />
                            {values.address_proof && <div>{values.address_proof}</div>}
                            {this.state.downLoadState.id === 'upload_407' && this.state.downLoadState.loading &&
                              <StrapSpin />}
                            <button className={`${style.key} ${style.dwnContainer}`}
                              disabled={downLoadState.loading}
                              onClick={() => this.onDownloadSnap(values.upload_407, 'upload_407')}>
                              <i class='fa fa-download' aria-hidden='true' ></i>
                              {values.address_proof &&
                                <i className='far fa-fw fa-trash-alt' style={{ color: '#9f9f9f', marginLeft: 5 }}
                                  onClick={(e) => this.handleDelete(e, 'address_proof')}></i>}
                            </button>
                          </div>
                        </div>
                      </div>}
                    {errors.upload_file && touched.upload_file &&
                      <div style={{ textAlign: 'right' }} className={style.formInvalidField}>{errors.upload_file}</div>}
                  </>
                }
                <div style={{ position: 'relative', alignItems: 'start' }} className={`${style.formGroup} ${style.addressGroup} ${style.politicallyExposedContainer}`}>
                  <div className={style.key}>You/Family members are politically exposed</div>
                  {!showPoliticallyExposed && <div
                    className={`${style.key} ${style.cursorPointer} ${style.value}`}
                    onClick={togglePoliticallyExposed}
                  >
                    {values.isPoliticallyExposed == "YES" ? "Yes" : "No"}
                  </div>}
                  {showPoliticallyExposed &&
                    <div className={style.genderSelection}>
                      <Radio.Group id={'politicaly'} onChange={(e) => handlePoliticalExp(e.target.value)} value={values.isPoliticallyExposed}>
                        <Radio value="YES">Yes</Radio>
                        <Radio value="NO">No</Radio>
                      </Radio.Group>
                    </div>
                  }
                </div>
                {!showPoliticallyExposed && values.isPoliticallyExposed == "YES" && <FormGroup className={style.formGroup}
                  onClick={togglePoliticallyExposed} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <table style={{ width: '50%', textAlign: 'right' }}>
                    <thead>
                      <th style={{ color: "#56a9e8" }}>Name</th>
                      <th style={{ color: "#56a9e8" }}>Relation</th>
                    </thead>
                    <tbody>
                      {values.political_organization.map((o, i) =>
                        <tr>
                          <th style={{ color: "#56a9e8" }}>{o.political_organization}</th>
                          <th style={{ color: "#56a9e8" }}>{o.relation}</th>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </FormGroup>}
                {showPoliticallyExposed && values.isPoliticallyExposed == "YES" && values.political_organization.length > 0 && <div>
                  <div className="row">
                    <div className={`${style.key} ${style.cursorPointer} ${style.value} col`}>Name</div>
                    <div className={`${style.key} ${style.cursorPointer} ${style.value} col`}>Relation</div>
                  </div>

                  {values.political_organization.map((o, i) => (
                    <div className={`${style.secInfo} pos-rel`} key={`political_organization${i}`}>
                      <div className={`${style.errorWithFeedback} col p-0 ml-0`} title={o.political_organization}>
                        <Input
                          type='text'
                          placeholder={'political_organization'}
                          name='name'
                          id='political_organization'
                          value={o.political_organization}
                          onChange={(e) => this.handleChangePoliticalOrganization(e, 'political_organization', i)}
                          onBlur={handleBlur}
                          className={errors.political_organization && touched.political_organization ? `${style.inputInvalid} ${style.multipleInputs}` : `${style.input} ${style.multipleInputs}`} />
                        {errors.political_organization && touched.political_organization && <div
                          className={style.formInvalidField}>{errors.political_organization[i] && errors.political_organization[i].political_organization}</div>}
                      </div>
                      <div className={`${style.errorWithFeedback} col p-0`} title={o.relation}>
                        <Input
                          type='text'
                          placeholder={'relation'}
                          name='relation'
                          id='relation'
                          value={o.relation}
                          onChange={(e) => this.handleChangePoliticalOrganization(e, 'relation', i)}
                          onBlur={handleBlur}
                          className={errors.political_organization && touched.political_organization ? `${style.inputInvalid} ${style.multipleInputs}` : `${style.input} ${style.multipleInputs}`} />
                        {errors.political_organization && touched.political_organization && <div
                          className={style.formInvalidField}>{errors.political_organization[i] && errors.political_organization[i].relation}</div>}
                      </div>
                      {i > 0 && <i className={`fas fa-trash ach-trash ${style.removePoliticalRelation}`}
                        onClick={() => this.removePoliticalRelation(i)}></i>}
                    </div>))}
                  <div className={style.secInfo} key={`political_organization-btn`}>
                    <button type='button' className={style.addPoliticalRelation}
                      onClick={this.addMorePoliticalRelation}>Add
                    </button>
                  </div>
                </div>}
                <FormGroup className={style.formGroup}>
                  <Label className={style.key} for='employement_type'>Employment Type</Label>
                  <div className={style.errorWithFeedback}>
                    <Select
                      type='select'
                      name='employement_type'
                      id='employement_type'
                      value={values.employement_type}
                      onChange={this.handleEmpTypeChange}
                      bordered={false}
                      suffixIcon={null}
                      dropdownMatchSelectWidth={false}
                      dropdownClassName={style.dropdownANT}
                      onBlur={handleBlur}
                      className={
                        errors.occupation && touched.occupation ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                      }
                    >
                      {
                        employmentTypeTradingAccount.map((item) => {
                          return <Option disabled={item.disabled} key={item.value} value={item.value}>{item.name}</Option>
                        })
                      }
                    </Select>
                    <CustomFormFeedback formProps={this.props.formProps} fieldName='occupation' />
                  </div>
                </FormGroup>

                {values.employement_type == 'Employed' &&
                  <>
                    <FormGroup className={style.formGroup}>
                      <Label className={style.key} for='employer_name'>Name of Employer</Label>
                      {!showEmployeerName && <div onClick={this.toggleEmployeerName}
                        className={`${style.cursorPointer} ${style.value}`}>{values.employer_name ? values.employer_name : 'NA'}</div>}
                      {showEmployeerName && <div className={style.errorWithFeedback}>
                        <Input
                          type='text'
                          name='employer_name'
                          id='employer_name'
                          placeholder={'Employeer Name'}
                          value={values.employer_name}
                          onChange={(e) => this.handleEmpNameChange(e)}
                          onBlur={handleBlur}
                          className={errors.phone && touched.phone ? `is-invalid ${style.inputInvalid}` : style.input2}
                        />
                        <CustomFormFeedback formProps={this.props.formProps} fieldName='employer_name' />
                      </div>}
                    </FormGroup>
                    <FormGroup className={style.formGroup}>
                      <Label className={style.key} for='occupation'>Occupation</Label>
                      {/* { !showOccupation && <div onClick={this.toggleOccupation} className={`${style.cursorPointer} ${style.value}`}>{values.occupation?values.occupation:'NA'}</div>} */}
                      <div className={style.errorWithFeedback}>
                        <Input
                          type='text'
                          name='occupation'
                          id='occupation'
                          placeholder={'Occupation'}
                          value={values.occupation}
                          onChange={(e) => this.handleOccupationChange(e)}
                          onBlur={handleBlur}
                          className={errors.phone && touched.phone ? `is-invalid ${style.inputInvalid}` : `${style.input2} ${style.styledInput}`}
                        />
                        <CustomFormFeedback formProps={this.props.formProps} fieldName='occupation' />
                      </div>
                    </FormGroup>
                  </>
                }
                {/* <FormGroup className={style.formGroup}>
                <Label className={style.key} for='time_horizon'>Number of Years planned to invest to achieve particular
                  financial goal</Label>
                <Select
                  type='select'
                  name='time_horizon'
                  id='time_horizon'
                  value={values.time_horizon}
                  onChange={this.handleTimeHorizon}
                  bordered={false}
                  suffixIcon={null}
                  dropdownMatchSelectWidth={false}
                  dropdownClassName={style.dropdownANT}
                  onBlur={handleBlur}
                  className={
                    errors.time_horizon && touched.time_horizon ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                  }
                >
                  {
                    financialGoalsTradingAccount.map((item) => {
                      return <Option disabled={item.disabled} value={item.value}>{item.name}</Option>
                    })
                  }

                </Select>
                <CustomFormFeedback
                  formProps={this.props.formProps}
                  fieldName='time_horizon'
                />
              </FormGroup> */}
                {/* <FormGroup className={style.formGroup}>
                <Label className={style.key} for='liquidity_needs'>Ability to quickly and easily convert all or a
                  portion of the account assets into cash without experiencing significant loss</Label>
                <Select
                  type='select'
                  name='liquidity_needs'
                  id='liquidity_needs'
                  value={values.liquidity_needs}
                  onChange={this.handleLiquidityNeed}
                  bordered={false}
                  suffixIcon={null}
                  dropdownMatchSelectWidth={false}
                  dropdownClassName={style.dropdownANT}
                  onBlur={handleBlur}
                  className={
                    errors.liquidity_needs && touched.liquidity_needs ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                  }
                >
                  <Option value="" disabled>Select</Option>
                  {
                    liquidityTimeTradingAccount.map((item) => {
                      return <Option disabled={item.disabled} value={item.value}>{item.name}</Option>
                    })
                  }
                </Select>
                <CustomFormFeedback
                  formProps={this.props.formProps}
                  fieldName='liquidity_needs'
                />
              </FormGroup> */}
                {showUpdate && <div className={`${style.formGroup} ${style.btnFlexEnd}`}>
                  <button type='submit' className={style.update}>
                    Update
                  </button>
                </div>}
              </Fragment>
            }
          </Fragment>
        }

        <div className={style.divider} />

        <div onClick={() => this.setState({ isCollapseACHRelationship: !this.state.isCollapseACHRelationship })}
          className={style.sectionHeader}
        >
          <span className={style.subHeader}>ACH Relationship</span>
          {/* <i class={!isCollapseACHRelationship?"fas fa-plus-circle":"fas fa-minus-circle"} onClick={()=> this.setState({isCollapseACHRelationship:!this.state.isCollapseACHRelationship})} ></i> */}
          <img style={{ width: 20 }}
            src={!isCollapseACHRelationship ? imagesPath.minus : imagesPath.plus} />
        </div>
        {!isCollapseACHRelationship && newAchData.length > 0 &&
          <div className={style.whiteBnkInfo}>
            <div className={style.header}>Bank Mandates</div>
            <div>
              {newAchData && newAchData.map((item, i) => <ACHRow isLast={i === newAchData.length - 1} data={item}
                cancelAch={(id) => this.handleAchCancel(id)} />)}
            </div>
          </div>}

        {!isCollapseACHRelationship && newAchData.length === 0 &&
          <div>
            <span>No ACH Relationship found</span> <br />
            {this.props.token &&
              <PlaidLink
                product={['auth']}
                onSuccess={this.props.onSuccess}
                className={`${style.update} ${style.updatePlaid}`}
                token={this.props.token}>
                Link New Bank Account
              </PlaidLink>
            }
            <br />
            <button onClick={(e) => { e.preventDefault(); this.props.rebuildAch() }} className={style.update}>Create New ACH using existing bank account</button>
          </div>
        }

        <ACHCancelPopup confirmACHPopup={this.confirmAchCancel} isACHPopupOpen={this.state.showACHPopup} toggleACHPopup={this.toggleACHPopup} />

        <div className={style.divider} />

        <div
          onClick={() => this.setState({ isCollapseTransaction: !this.state.isCollapseTransaction })}
          className={style.sectionHeader}
        >
          <span className={style.subHeader}>Transaction</span>
          <img style={{ width: 20 }} src={!isCollapseTransaction ? imagesPath.minus : imagesPath.plus}
          />
        </div>
        {!isCollapseTransaction && <div>
          <TransactionView setTView={this.setTView} value={this.state.tview}
            redirectTo={() => this.redirectTo(account_no, orders)} />
          {this.state.tview === 'order_queue' && tradeReq && tradeReq.length > 0 &&
            <div className={style.recentBankTran}>
              <Table borderless>
                <thead>
                  <tr>
                    <th>Ticker</th>
                    <th>Quantity</th>
                    <th>Action</th>
                    <th>Price</th>
                    <th>Status</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {tradeReq && tradeReq.map((item, i) => <OrderQueueTRow isLast={i === deposits.length - 1} data={item} />)}
                </tbody>
              </Table>
            </div>}

          {this.state.tview === 'bank' && deposits && deposits.length > 0 && <div className={style.recentBankTran}>
            <div className={style.recentBankatop}>
              <div className={style.recentBankahead}>
                Recent Bank Transactions
              </div>
              <div className={style.status}>Status</div>
              <div className={style.more}>

              </div>
            </div>
            {deposits && deposits.map((item, i) => <BankTRow isLast={i === deposits.length - 1} data={item}
              confirmCancel={(item) => this.handleTransactionCancel(item)} />)}
          </div>}
          {this.state.tview === 'trading' && orders && orders.length > 0 && <div className={style.tradingTable}>
            <Table borderless>
              <thead>
                <tr>
                  <th>Ticker</th>
                  <th>Quantity</th>
                  <th>Action</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {orders && orders.map((order, i) => <TradingTRow isLast={i === orders.length - 1} data={order} />)}
              </tbody>
            </Table>
          </div>}
        </div>
        }

        <div className={style.divider} />

        <div onClick={() => this.setState({ isCollapseDownload: !this.state.isCollapseDownload })}
          className={style.sectionHeader}>
          <span className={style.subHeader}>Download Documents</span>
          <img style={{ width: 20 }} src={!isCollapseDownload ? imagesPath.minus : imagesPath.plus} />
        </div>
        {!isCollapseDownload && <div>
          <DownloadSection account_no={account_no} />
        </div>
        }

        <div className={style.divider} />

        <div
          className={style.sectionHeader}
          onClick={() => this.setState({ isCollapseDownloadReport: !this.state.isCollapseDownloadReport })}>
          <span className={style.subHeader}>Request</span>
          <img style={{ width: 20 }} src={!isCollapseDownloadReport ? imagesPath.minus : imagesPath.plus} />
        </div>
        {!isCollapseDownloadReport &&
          <>
            {this.getRequestElements(request)}
            {showTransferAccountModal && <TransferAccModal toggleTransferAccPopup={this.toggleTransferAccPopup}
              showTransferAccountModal={showTransferAccountModal}
              acc_id={values.account_id} />}

          </>}

        <div className={style.divider} />
        <div
          onClick={this.toggleContactUs}
          className={style.sectionHeader}
        >
          <span className={style.subHeader}>Contact Us</span>
          <img style={{ width: 20 }} src={!isContactUsCollapsed ? imagesPath.minus : imagesPath.plus} />
        </div>
        {!isContactUsCollapsed && <div className={style.contactUsContainer}>
          <span>
            Get In Touch With Us @
            <a id="mail-to-magnifi" href={`mailto:tradingaccountsupport@magnifi.com`}>
              {" tradingaccountsupport@magnifi.com"}
            </a>
          </span>
        </div>}

      </div>
    )
  }

}

export default EditableTradingAccountForm

export const TransactionView = ({ value, setTView, redirectTo }) => {
  let onClick = (view) => {
    setTView(view)
  }
  return (
    <div className={style.viewTrBtn}>
      <button className={` ${value === 'order_queue' && style.active}`} onClick={(e) => {
        e.preventDefault()
        onClick('order_queue')
      }}>Order Queue
      </button>
      <button className={` ${value === 'trading' && style.active}`} onClick={(e) => {
        e.preventDefault()
        onClick('trading')
      }}>Trading
      </button>
      <button className={` ${value === 'bank' && style.active}`} onClick={(e) => {
        e.preventDefault()
        onClick('bank')
      }}>Withdrawal/Deposit
      </button>
      <button
        className={` ${value === 'position_summary' && style.active}`}
        onClick={(e) => {
          e.preventDefault()
          redirectTo()
        }}
        style={{ float: 'right', marginRight: 0 }}
      >Position Summary
      </button>
    </div>
  )
}

//deposit etc
export const BankTRow = ({ isLast, data, confirmCancel }) => {
  let amount = parseFloat(data.amount)
  let isDeposited = amount > 0
  return (
    <div className={`${!isLast && style.borderBottom} ${style.recentBankaList}`}>
      <div className={style.left} style={{ width: '55%' }}>
        {isDeposited ? (
          <div className={style.green}>
            <i className='far fa fa-long-arrow-up' />
            <span>${Math.abs(amount)}</span>
          </div>
        ) : <div className={style.red}>
          <i className='far fa fa-long-arrow-down' />
          <span>${Math.abs(amount)}</span>
        </div>}
      </div>
      <div className={style.right} style={{ width: '25%' }}>
        <span>{AddMoneyStatusMapping(data.status) ? AddMoneyStatusMapping(data.status).status : data.status}</span>
        {(data.status === 'FundsPosted' || data.status === 'Postponed') && <span className={style.closeDeposits} onClick={() => confirmCancel(data)}>
          <i className='fa fa-times' aria-hidden='true' />
          Cancel
        </span>}
      </div>
      <div className={style.right}>
        <span>{isDeposited ? 'Deposited' : 'Withdrawal'}</span>
        <span>{moment(data.created_at).format('MM/DD/YYYY')}</span>
      </div>
    </div>
  )


}

//trading
export const TradingTRow = ({ isLast, data }) => {
  return (
    <tr className={`${!isLast && style.borderBottom}`}>
      <td>{data.ticker}</td>
      <td>{data.quantity}</td>
      <td>{data.side}</td>
      <td>${data.analysis_price}</td>
      <td>{data.status}</td>
      <td>{moment(data.created_at).format('DD/MM/YY hh:mm:ss')}</td>
    </tr>
  )
}

//order queue
export const OrderQueueTRow = ({ isLast, data }) => {
  return (
    <tr className={`${!isLast && style.borderBottom}`}>
      <td>{data.ticker}</td>
      <td>{data.quantity}</td>
      <td>{data.side}</td>
      <td>${data.analysis_price}</td>
      <td>{data.status}</td>
      <td>{moment(data.created_at).format('DD/MM/YY hh:mm:ss')}</td>
    </tr>
  )
}

export const ACHRow = ({ isLast, data, cancelAch }) => {


  let isAmountPositive = parseFloat(data.amount) > 0
  return (
    <div className={`${!isLast && style.borderBottom} ${style.bankinfo}`}>
      <div className={style.leftBox}>
        <div className={style.bankHeding}>
          {data.nickname} : {data.bank_account_no}
        </div>
        {/* <div className={style.fromTo}>
          <div className={style.from}>
            <span>Method</span>
            <br></br>
            <span>ACH ID</span>
          </div>
          <div className={style.from}>
            <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
          </div>
          <div className={style.from}>
            <span>{data.approval_method}</span>
            <br></br>
            <span>{data.ach_id}</span>
          </div>
        </div> */}
      </div>
      <div className={style.rightBox}>
        <div className={style.Status}>
          <div className={style.btnActive}>{data.status}</div>
          <div className={style.close} onClick={() => cancelAch(data.id)}>
            <i className='fa fa-times' aria-hidden='true'></i> Cancel
          </div>
        </div>
        <div className={style.created}>
          Created : {moment(data.creation_time).format('MM/DD/YYYY')}
        </div>
      </div>
    </div>
  )
}

export const DownloadSection = ({ account_no }) => {

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [ddValue, setDDValue] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [list, setList] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const toggle = () => setDropdownOpen(prevState => !prevState)


  const onChangeDD = (doc) => {
    setDDValue(doc)
  }

  const onDownloadClicks = async () => {
    setLoading(true)
    setError('')
    try {
      setLoading(true)
      let payload = {
        source: 'soap',
        type: ddValue.key === 'TRADE_CONFIRMATION' ? undefined : ddValue.key,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        account_no,
        end_date: moment(endDate).format('YYYY-MM-DD')
      }
      let download = await downloadSnap(payload)
      setList(download.data.map(item => ({ ...item, doc_date: `${ddValue.desc ? ddValue.desc.toLowerCase().replaceAll(" ", "_") + '_' : ''}${item.doc_type ? item.doc_type + '_' : ''}${item.doc_date.replaceAll("/", "_")}` })))
      setLoading(false)

    } catch (e) {
      setList([])
      setError('No data')
      setLoading(false)
      console.log(e)
    }
  }

  const download = async (url) => {
    setError('')
    console.log(url)
    try {
      setLoading(true)
      let payload = {
        source: 'soap',
        doc_url: url
      }
      console.log(payload)
      let download = await downloadSnap(payload, true)
      console.log(download)
      setLoading(false)
      //console.log(btoa(download.data));
      //var imgsrc = 'data:image/png;base64,' + btoa(unescape(encodeURIComponent(download.data)));
      //console.log(imgsrc);

      const newBlob = new Blob([download.data], { type: download.headers['content-type'] })
      const extension = download.headers['content-type'].split('/')[1]

      // var reader = new FileReader();

      // reader.readAsDataURL(newBlob);
      // reader.onloadend = function() {
      //   var base64data = reader.result;
      //   setTestImg(base64data)
      // }
      FileSaver.saveAs(newBlob, `${ddValue.desc}.${extension}`)

    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const dateClick = (d, c) => {
    if (d === 'start') {
      setStartDate(c)
    } else {
      setEndDate(c)
    }
  }

  return (
    <div className={style.downloadSection}>
      <Spinner isFetching={loading} />
      <div className={'d-flex'}>
        <div>
          <p>Start Date</p>
          <DatePicker
            selected={startDate}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            placeholderText={'DD/MM/YYYY'}
            maxDate={new Date()}
            autoComplete='of'
            onChange={(dateInstance, dateString) => {
              console.log(dateInstance)
              dateClick('start', dateInstance || null)
            }}
            className={`${style.input} ${style.DOB}`}
          />
        </div>
        <div>
          <p>End Date</p>
          <DatePicker
            selected={endDate}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            placeholderText={'DD/MM/YYYY'}
            maxDate={new Date()}
            autoComplete='of'
            onChange={(dateInstance, dateString) => {
              console.log(dateInstance)
              dateClick('end', dateInstance || null)
            }}
            className={`${style.input} ${style.DOB}`}
          />
        </div>
      </div>
      <div style={{ margin: `10px 0` }} className='d-flex justify-content-between'>
        <Dropdown
          className={'dropdown-wrapper'}
          isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle className={style.toggle} caret>
            {ddValue.desc || 'Select'}
          </DropdownToggle>
          <DropdownMenu>
            {OBJ.map((doc, idx) => <DropdownItem key={idx} value={doc.key} onClick={(e) => {
              onChangeDD(doc)
            }}>{doc.desc}</DropdownItem>)}
          </DropdownMenu>
        </Dropdown>
        <div>
          <button disabled={!ddValue.key} onClick={(e) => {
            e.preventDefault()
            onDownloadClicks()
          }} className={style.update}>Get Documents
          </button>
        </div>
      </div>
      <div>
        {error}
        {list.map(item => <div className={style.listDownload}>
          <p>{item.doc_date}</p>
          <a href={item.doc_url} download target='_blank'>Download</a>
        </div>)}
      </div>

    </div>
  )
}

export const ACHCancelPopup = ({ isACHPopupOpen, toggleACHPopup, confirmACHPopup }) => {

  const onCloseIcon = () => {
    toggleACHPopup()
  }
  const onOkClick = () => {
    confirmACHPopup()
  }

  return (
    <Fragment>
      <Modal
        keyboard
        centered
        backdrop
        unmountOnClose={true}
        isOpen={isACHPopupOpen}
        className='add-money-popup-sfm'
        backdropClassName='premium-feature-process-modal-backdrop'
        toggle={onCloseIcon}
      >
        <ModalBody className='ssf-modal__body'>
          <div className={style.achPopup}>
            <i onClick={toggleACHPopup} className={`fal fa-times ${style.close}`} />
            <p className={style.header}>Do you want to Cancel ACH Relationship?</p>
            <div className={'d-flex'}>
              <button className={`${style.confirm} ${style.cancel}`}
                onClick={onCloseIcon}> Cancel
              </button>
              <button className={style.confirm}
                onClick={onOkClick}> confirm
              </button>
            </div>

          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  )
}

const OBJ = [
  { key: 'TRADE_CONFIRMATION', desc: 'Trade Confirmation' },
  { key: '2', desc: 'Account Statement' },
  { key: '100', desc: '1099 & 1099 Corrections' },
  { key: '102', desc: '1099R' },
  { key: '104', desc: '1042-S' },
  { key: '105', desc: '5498 (used as the FMV)' },
  { key: '106', desc: '5498 ESA' },
  { key: '107', desc: 'FMV' },
  { key: '108', desc: '1099Q' },
  { key: '109', desc: 'SDIRA' }
]

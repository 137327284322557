import React from 'react'
import { Row, Col } from 'reactstrap'
// import { haveHTMLData } from 'Utils';
import { Disclosures } from './Disclosures'

import {
  Coverage_Logo,
  Coverage_poweredByLogo,
  ProposalCoverImage,
  CoverFooterIcon
} from '../../../../../common/constant'

const Logo = Coverage_Logo
const poweredByLogo = Coverage_poweredByLogo

const IndexPage = props => {
  const { createdDate, disclosures, portfolioName } = props
  return (
    <React.Fragment>
      <div className="page">
        <div className="content cover">
          <div className="pr-v2-cover-page">
            <div>
              <img
                className={'cover-logo'}
                src={ProposalCoverImage}
                alt=""
                width={120}
                height={120}
              />
              <div>
                <p className="coverTitle">Enhancer Report</p>
                {createdDate && (
                  <p className="date">Data as of {createdDate}</p>
                )}
                <h2 className="paragraph" style={{ fontWeight: '600' }}>
                  {portfolioName}
                </h2>
              </div>
              <Row>
                <Col>
                  <p className="paragraph">
                    TIFIN Wealth is changing the way we shop for investments.
                    The world's first semantic search engine for finance helps
                    discover and compare investment products such as ETFs,
                    Mutual funds, etc.
                  </p>
                  <p className="paragraph">
                    This proposal is designed to select and compare available
                    thematic investment options to determine which options meet
                    your criteria and may help to enhance your portfolio.
                  </p>
                  <Disclosures
                    createdDate={createdDate}
                    disclosure={disclosures}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="cover-footer">
          <div className="glossary-footer text-justify">
            <div style={{ width: '100%' }}>
              {props.logo && (
                <img
                  className={'cover-logo'}
                  style={{ height: `75px` }}
                  src={props.logo}
                  alt=""
                />
              )}
            </div>

            <hr style={{ border: '1px solid #E4E7EC', margin: '20px  0' }} />

            <p className="pr-v2-medium-paragraph">
              There is no guarantee that any investment strategy illustrated
              will be successful or achieve any particular level of results.
              Please review the disclosures at the end of this document and
              consult your financial advisor for more information.
            </p>
            <br />
            {/* <span className="pr-v2-medium-paragraph">Magnifi LLC is an SEC registered investment adviser relying on rule 203A-2(e) for registration. Reference to registration with the Securities and Exchange Commission (“SEC”) does not imply that the SEC has endorsed or approved the qualifications of the firm to provide any advisory services described or that the firm has attained a level of skill or training.</span> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default IndexPage

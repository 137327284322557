import React from 'react';
import RenderRegionsChart from '../shared/RegionsChart'
import RenderBurst from '../shared/RenderBurst'
import RenderCarousel from '../shared/RenderCarousel'
import RenderOdometer from '../shared/RenderOdometer'
import RenderPieChart from '../shared/RenderPieChart'
import DynamicGaugeChart from '../shared/DynamicGaugeChart'
import NotApplicable from '../../NotApplicable';
import AssetChart from '../shared/AssetChart'

export const ViewChart = ({ fund, i, queryVar, data, chartType, size }) => {
  switch (chartType) {
    case 'regionsChart':
      return <RenderRegionsChart data={data} i={i} />;
    case 'burst_chart':
      return <RenderBurst fund={fund} data={data} i={i} queryVar={queryVar} />;
    case 'carousel':
      return <RenderCarousel data={data} queryVar={queryVar} />;
    case 'odometer':
      return <RenderOdometer tickerData={data} queryVar={queryVar} />;
    case 'assets':
      return <AssetChart card={fund} />;
    case 'pie_chart':
      return <RenderPieChart fund={fund} item={data} queryVar={queryVar} />;
    case 'dynamicGaugeChart':
      return <DynamicGaugeChart tickerData={data} queryVar={queryVar} />;
    default:
      return <NotApplicable />;
  }
}

import React from 'react';
import $ from 'jquery';
//window.jQuery = $;  // OR include in below js file: import jQuery from 'jquery';
const flipster =  require('../../third-party/jquery-flipster/jquery.flipster');

export default class Flipster extends React.Component {
  constructor (props) {
    super(props);
    this.elRef = React.createRef();
  }

  componentDidMount() {
    $(this.elRef.current).flipster({
      style: 'carousel',
      spacing: -0.25,
      scrollwheel: false,
      fadeIn: 0,
    });
  }

  imgUrl = n => `/assets/images/themeIcons/${n}.png`

  render() {
    let displayArr;
    if(this.props.display == 3) displayArr = [1, 0, 2];
    else if(this.props.display == 5) displayArr = [3, 1, 0, 2, 4];

    const items = displayArr.map(i => this.props.data[i]).filter(e => !!e); // re-order

    return (
      <div ref={this.elRef} className="" style={{ width: this.props.width || '140px' }}>
        <ul className="flip-items">
          { items.map(e =>
              <li key={e.name} data-flip-title={e.name}>
                <img src={this.imgUrl(e.name)} alt={e.name} width={this.props.imgSize || "50px"} height={this.props.imgSize || "50px"} title={`${e.name}: ${e.value}`} />
                <h6 className="font-weight-normal text-center" style={{margin: '8px -40px 0'}}>{e.name}</h6>
              </li>
          ) }
        </ul>
      </div>
    )
  }
}

import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Row, Col, Button, Spin, Tooltip } from 'antd';

import { RightOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd'
import {
	setEnhancerState,
	resetSummaryDataRequest,
	savePortfolioRequest,
	resetFinalizeDataRequest,
	checkTotumAdvisorRequest,
	submitTotumAdvisorRequest,
	loadCPMListRequest,
	resetTotumScore,
	resendTotumInviteRequest,
	getCurrentPortfolioScoreRequest,
	getTotumQuestionnaireStatusRequest,
} from 'actions/enhancer';
import { fetchProfile, profileDataRequest } from 'actions/profile';
import { createLoadingSelector } from 'selectors/loading-selector';

// import CustomAlert from 'components/CustomAlert';

import { addEventToAnalytics } from 'Utils';
import { getClientDetailsForTotum } from 'layouts/utils';

import TotumSection from '../../Elements/TotumSection'

class ContentHead extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editName: false,
			nameInvalid: false
		}
	}

	// componentDidMount() {
	// 	const { advisorId, clientList, totumScore, portfolioName } = this.props;
	// 	let { showScoreMeter } = getClientDetailsForTotum(advisorId, clientList, totumScore, portfolioName);
	// 	console.log('=--======== componentDidMount showScoreMeter ============-=');
	// 	if (showScoreMeter) {
	// 		this.totumRenderButtonOnClick();
	// 	}
	// }

	componentWillUnmount() {
		const { resetTotumScore } = this.props;
		if (resetTotumScore) {
			resetTotumScore()
		}
	}

	routeToOnBoardScreen = () => {
		addEventToAnalytics('Enhancer Edit Home', 'Enhancer Edit Home', 'ENHANCER_EDIT_HOME', {}, false);
		const { history, resetSummaryDataRequest } = this.props;
		if (resetSummaryDataRequest && history) {
			resetSummaryDataRequest('', () => {
				const newPath = `/enhancer/onboard-screen`;
				history.push(newPath);
			})
		}
	}

	drawerToggle = () => {
		const { summaryDrawer, openDrawer } = this.props;
		if (openDrawer) {
			openDrawer(!summaryDrawer);
		}
	}

	handleNameSubmit = (e) => {
		if (!e.target.value) {
			return
		} else if (e.target.value === this.props.portfolioName) {
			return this.setState({ editName: false })
		} else if (e.target.value.length > 50) {
			return this.setState({ nameInvalid: true })
		}

		const { history, portfolioType, savePortfolioRequest, portfolioId, enhancerData, setEnhancerState } = this.props;
		const enhancedPortfolioId = portfolioId;
		this.setState({ editName: false })
		savePortfolioRequest({
			portfolio_id: enhancedPortfolioId,
			portfolio_name: e.target.value,
			portfolioType,
			errorNotification: true,
			is_client: window.location.href.indexOf("portfolioType=Client") > -1
		}, (response) => {
			if (response.notify) {
				notification.open({
					description: 'Portfolio already exist.',
					className: 'saveProfile_toastFail',
					duration: 4,
					message: null,
					top: 50,
				})
				return
			}
			if (response && history) {
				addEventToAnalytics('Edit Portfolio Name', 'Edit Portfolio Name', 'EDIT_PORTFOLIO_NAME', {}, false);
				let urlParam = [];
				for (var i in response) {
					urlParam.push(encodeURIComponent(i) + "=" + encodeURIComponent(response[i]));
				}
				const newPath = `/enhancer/dashboard/summary?${urlParam.join("&")}`;
				history.push(newPath);
				// dont know why Rohan added below logic? like assigning Old Enhancer State as a Current Enhancer State (when portfolio renamed)
				let __enhanceData = enhancerData;
				__enhanceData['portfolioName'] = response.portfolioId;
				// console.log(__enhanceData);

				let { enhancerData: { clientList: __clientList } } = this.props;
				if (__clientList) {
					__enhanceData = {
						...__enhanceData,
						clientList: __clientList,
					};
				}
				setEnhancerState(__enhanceData);
			}
		});

	}

	totumRenderButtonOnClick = () => {
		const { getCurrentPortfolioScoreRequest, portfolioFunds, tickerNotSupported } = this.props;
		if (getCurrentPortfolioScoreRequest && portfolioFunds) {
			let allData = [...portfolioFunds, ...tickerNotSupported]
			const requestData = allData.map((item) => {
				return {
					ticker: item.ticker,
					weight: item.weight,
				};
			});
			if (requestData && requestData.length > 0) {
				getCurrentPortfolioScoreRequest({
					requestData,
				});
			}
		}
	}

	sendEmailOnClick = (data, callback) => {
		const { submitTotumAdvisorRequest } = this.props;
		if (submitTotumAdvisorRequest && data) {
			submitTotumAdvisorRequest(data);
		}
		setTimeout(() => {
			const { loadCPMListRequest } = this.props
			if (loadCPMListRequest) {
				loadCPMListRequest()
			}
			if (callback) {
				callback();
			}
		}, 2000)
	}

	activateOnClick = (callback) => {
		const { advisorId, checkTotumAdvisorRequest, profileDataRequest } = this.props;
		if ((advisorId === null || !advisorId || advisorId === '') && checkTotumAdvisorRequest) {
			checkTotumAdvisorRequest();
		}
		setTimeout(() => {
			if (profileDataRequest) {
				profileDataRequest()
			}
			if (callback) {
				callback();
			}
		}, 2000)
	}

	loadScoreData = () => {
		const { advisorId, clientList, totumScore, portfolioName } = this.props;
		let { showScoreMeter } = getClientDetailsForTotum(advisorId, clientList, totumScore, portfolioName);
		if (showScoreMeter) {
			this.totumRenderButtonOnClick();
		}
	}

	getQuestionnaireStatus = (callBack) => {
		const { advisorId, clientList, totumScore, portfolioName, getTotumQuestionnaireStatusRequest } = this.props;
		if (getTotumQuestionnaireStatusRequest) {
			let {
				clientId,
				advisorName,
				advisorEmail,
			} = getClientDetailsForTotum(advisorId, clientList, totumScore, portfolioName);
			if (clientId) {
				getTotumQuestionnaireStatusRequest({
					clientId,
					portfolioName,
				}, (res) => {
					// console.log('-- res --', res);
					if (callBack) {
						callBack(res);
					}
				});
			} else {
				console.log('-- clientId is missing --');
			}
		}
	}

	resendTotumMail = (formData = {}) => {
		// console.log('== resendTotumMail formData ==', formData);
		const { advisorId, clientList, totumScore, portfolioName, resendTotumInviteRequest } = this.props;
		if (resendTotumInviteRequest && formData && formData.client_id) {
			// if (resendTotumInviteRequest && advisorId && clientList && totumScore && portfolioName) {
			// let {
			// 	clientId,
			// 	advisorName,
			// 	advisorEmail,
			// } = getClientDetailsForTotum(advisorId, clientList, totumScore, portfolioName);
			// let firstName = advisorName.split(' ').length > 0 ? advisorName.split(' ')[0] : advisorName;
			// let lastName = advisorName.split(' ').length > 1 ? advisorName.split(' ')[1] : advisorName;
			resendTotumInviteRequest({
				...formData,
			}, (res) => {
				if (res) {
					// NOTE: trigger notification
					// console.log(res);
					if (res && res.result && res.result.message) {
						notification.open({
							description: 'Email sent successfully.',
							className: 'saveProfile_toastSuccess',
							duration: 2,
							message: null,
							top: 50,
						})
					} else {
						notification.open({
							description: 'Failed to process.',
							className: 'saveProfile_toastFail',
							duration: 2,
							message: null,
							top: 50,
						})
					}
				}
			})
		}
	}

	generateProposal = () => {
		const { generateProposalClick } = this.props;
		if (generateProposalClick) {
			generateProposalClick();
		}
	}

	render() {
		const {
			portfolioName,
			portfolioType,
			sponsoredPortfolioName,
			portfolioId,
			advisorId,
			clientList,
			totumScore,
			totumRiskLoading,
			emailSubmitLoading,
			CPMLoading,
			profileData,
			totumAccessEmail,
			resendingTotumEmail,
			performanceImpactLoading
		} = this.props;

		let {
			min,
			max,
			clientId,
			advisorName,
			resendEmail,
			advisorEmail,
			showScoreMeter,
			allScoreMeter,
			orderedScoreMeter,
			renderScoreValues,
			totumClientDetails,
			renderAllScoreValues,
		} = getClientDetailsForTotum(advisorId, clientList, totumScore, portfolioName);

		const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

		return (
			<React.Fragment>
				<div id="funds-board-head" className="funds-board-head">
					<Row id="funds-board-content-header" className="pf-content-header m-0">
						<Col span={24} className="d-flex col-wrap">
							<Col span={20} className="enhancer-portfolio-name-container" style={{ display: 'flex', zIndex: 1 }}>
								<span style={{ color: '#494949' }}>Enhancement Ideas For </span>
								{portfolioType && (portfolioType === 'My Portfolio' || portfolioType === 'Upload Portfolio' || portfolioType === 'Client Portfolio') && (portfolioId && typeof portfolioId === 'number') ? (this.state.editName ? <div style={{ display: 'grid' }}><input class="ant-input portfolio_name_input" style={{ marginLeft: 10 }} type="text" onChange={(e) => {
									if (e.target.value.length > 50) {
										this.setState({ nameInvalid: true })
									} else {
										this.setState({ nameInvalid: false })
									}
								}} onBlur={(e) => this.handleNameSubmit(e)} defaultValue={(portfolioType && portfolioType === 'MarketPlace' && sponsoredPortfolioName && sponsoredPortfolioName !== '') ? sponsoredPortfolioName : portfolioName} disabled="" />{this.state.nameInvalid ? <span style={{ fontSize: 10, color: 'red', marginLeft: 10 }}>Maximum 50 characters allowed.</span> : null}
								</div> : <><span style={{ marginLeft: 10 }}>
									{(portfolioType && portfolioType === 'MarketPlace' && sponsoredPortfolioName && sponsoredPortfolioName !== '') ? sponsoredPortfolioName : portfolioName}
								</span><EditOutlined onClick={() => this.setState({ editName: true })} style={{ marginLeft: 5 }} /></>) : <span style={{ marginLeft: 10 }}>
									{(portfolioType && portfolioType === 'MarketPlace' && sponsoredPortfolioName && sponsoredPortfolioName !== '') ? sponsoredPortfolioName : portfolioName}
								</span>}
							</Col>
							<Col span={4} className="d-flex align-items-center" style={{ justifyContent: 'flex-end' }}>
								<Button
									shape="round"
									className="primary-button return-home-button"
									type="primary"
									style={{ padding: 0 }}
									onClick={this.routeToOnBoardScreen}
								>
									<img width="28px" src="/assets/images/reload.svg" />
								</Button>
							</Col>
						</Col>
					</Row>
					<Row className={`enhancer-cta-container m-0 ${(!CPMLoading && showScoreMeter) ? 'pt-3 pb-3' : ''}`}>
						<Col span={14} className="d-flex align-items-center justify-content-start">
							{(portfolioType === 'Client Portfolio') && (
								<>
									{(!CPMLoading) ? (
										<TotumSection
											min={min}
											max={max}
											clientId={clientId}
											advisorId={advisorId}
											profileData={profileData}
											advisorName={advisorName}
											resendEmail={resendEmail}
											advisorEmail={advisorEmail}
											portfolioType={portfolioType}
											allScoreMeter={allScoreMeter}
											scoreMeter={orderedScoreMeter}
											showScoreMeter={showScoreMeter}
											totumAccessEmail={totumAccessEmail}
											totumRiskLoading={totumRiskLoading}
											renderScoreValues={renderScoreValues}
											emailSubmitLoading={emailSubmitLoading}
											totumClientDetails={totumClientDetails}
											resendingTotumEmail={resendingTotumEmail}
											renderAllScoreValues={renderAllScoreValues}
											loadScoreData={this.loadScoreData}
											resendLink={(formData) => this.resendTotumMail(formData)}
											getQuestionnaireStatus={(callBack) => this.getQuestionnaireStatus(callBack)}
											activateOnClick={(callback) => this.activateOnClick(callback)}
											sendEmailOnClick={(data, callback) => this.sendEmailOnClick(data, callback)}
											totumRenderButtonOnClick={this.totumRenderButtonOnClick}
										/>
									) : (
										<div className="totum-risk-bar-container">
											<div className="totum-risk-bar-loader">
												<Spin indicator={antIcon} />
											</div>
										</div>
									)}
								</>
							)}
						</Col>
						<Col span={10} className="d-flex align-items-center" style={{ justifyContent: 'flex-end' }}>
							<Button
								type="link"
								className="custom-link-button"
								//className="primary-cta-button"
								onClick={this.drawerToggle}
								style={{ textDecoration: 'underline' }}>
								Performance Summary
								{/* <RightOutlined /> */}
							</Button>
							<Tooltip placement="bottomRight" title="Generate Current Portfolio Report">
								<Button
									shape="round"
									className="primary-button return-home-button mr-lf-10"
									type="primary"
									style={{ padding: 0 }}
									onClick={this.generateProposal}
									disabled={performanceImpactLoading}
								>
									<img width="28px" src="/assets/images/PrintIcon.svg" />
								</Button>
							</Tooltip>
						</Col>
					</Row>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ router, loading, enhancer, profile: { profile: _profile } }) => ({
	location: router.location,
	enhancerData: enhancer,
	profileData: _profile,
	clientList: enhancer.clientList,
	portfolioName: enhancer.portfolioName,
	portfolioType: enhancer.portfolioType,
	portfolioId: enhancer.portfolioId,
	sponsoredPortfolioName: enhancer.sponsoredPortfolioName,
	portfolioFunds: enhancer.summary.portfolioFunds,
	advisorId: _profile.totum_advisor_id || null,
	totumScore: enhancer.totumScore,
	totumAccessEmail: _profile.email || null,
	tickerNotSupported: enhancer.summary.tickerNotSupported || [],
	CPMLoading: createLoadingSelector(['LOAD_CPM_LIST', 'SAVE_PORTFOLIO'])({ loading }),
	emailSubmitLoading: createLoadingSelector(['SUBMIT_TOTUM_ADVISOR'])({ loading }),
	resendingTotumEmail: createLoadingSelector(['RESEND_TOTUM_INVITE_EMAIL', 'TOTUM_QUESTIONNAIRE_STATUS'])({ loading }),
	totumRiskLoading: createLoadingSelector(['GET_CURRENT_PORTFOLIO_SCORE', 'PROFILE_DATA'])({ loading }),
});

const mapDispatchToProps = {
	fetchProfile,
	profileDataRequest,
	setEnhancerState,
	resetSummaryDataRequest,
	savePortfolioRequest,
	resetFinalizeDataRequest,
	checkTotumAdvisorRequest,
	submitTotumAdvisorRequest,
	loadCPMListRequest,
	resetTotumScore,
	resendTotumInviteRequest,
	getCurrentPortfolioScoreRequest,
	getTotumQuestionnaireStatusRequest,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ContentHead);

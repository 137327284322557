import ActionTypes from '../action-types'

export function clearFundsApiLoadPercent() {
  return {
    type: ActionTypes.CLEAR_FUNDS_API_LOAD_PERCENT,
  }
}

export function updateFundsApiProgress(payload) {
  return {
    type: ActionTypes.UPDATE_LOAD_PERCENT,
    payload,
  }
}

export function fundApiCallDuration(payload) {
  return {
    type: ActionTypes.FUNDS_API_CALL_DURATION,
    payload,
  }
}

export function fundsApiCallTime(payload) {
  return {
    type: ActionTypes.FUNDS_API_CALL_TIME,
    payload,
  }
}


export function setGlobalState(payload) {
  return {
    type: ActionTypes.SET_GLOBAL_STATE,
    payload,
  }
}

export function refreshGlobalState(payload) {
  return {
    type: ActionTypes.REFRESH_GLOBAL_STATE,
    payload,
  }
}

export function toggleJoinModal(payload) {
  return {
    type: ActionTypes.SHOW_JOIN_MODAL,
    payload,
  }
}


/* --- GET ALL TICKER LIST  --- */
export function getTickerListRequest(payload) {
  return {
    type: ActionTypes.GET_ALL_TICKER_LIST_REQUEST,
    payload,
  }
}
export function getTickerListFetch(payload) {
  return {
    type: ActionTypes.GET_ALL_TICKER_LIST_FETCH,
    payload,
  }
}
export function getTickerListSuccess(payload) {
  return {
    type: ActionTypes.GET_ALL_TICKER_LIST_SUCCESS,
    payload,
  }
}

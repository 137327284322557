import React from 'react';

import {
  ComposedChart,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ReferenceLine,
  CartesianGrid,
  Tooltip,
  Legend,
  Surface,
  Symbols,
  foreignObject,
  Dot,
  Label,
  Line,
} from 'recharts';

import { D3Colors20 } from 'DataSet';
import { SectorColors } from '../../../data/Colors';
import { fixedValue, splitDataForTicks } from './Utils';
import _ from 'lodash';

let ticksCount = {
  x: 5,
  y: 3
};

let maxTicksCount = {
  x: 8,
  y: 4
}

const _FactorExposureChart = (props) => {
  let data = props.chartData;
  let { tickConfig } = props;
  tickConfig = tickConfig || {};
  const domain = getDomain(data, tickConfig);
  const { xDomain, yDomain } = domain;
  const ticks = getTicks(domain, ticksCount.x, ticksCount.y);
  const xCenter = (xDomain[1] - xDomain[0])/2;
  const { yTicks, xTicks } = ticks;
  const axisLabels = {
    x: props.axisLabels ? props.axisLabels[0] : '',
    y: props.axisLabels ? props.axisLabels[1] : '',
  };
  const axisUnits = {
    x: props.axisUnits ? props.axisUnits[0] : '',
    y: props.axisUnits ? props.axisUnits[1] : '',
  };
  const { maxMinLabel } = props;
  const tickFormatter = (value, axis) => {
    let tickValue = value;
    if(maxMinLabel){
      if(axis === 'x'){
        if(value === xDomain[0]){
          tickValue = maxMinLabel.xMin === 'NA' ? '' : maxMinLabel.xMin;
        }
        if(value === xDomain[1]){
          tickValue = maxMinLabel.xMax === 'NA' ? '' : maxMinLabel.xMax;
        }
      }else{
        if(value === yDomain[0]){
          tickValue = maxMinLabel.yMin === 'NA' ? '' : maxMinLabel.yMin;
        }
        if(value === yDomain[1]){
          tickValue = maxMinLabel.yMax === 'NA' ? '' : maxMinLabel.yMax;
        }
      }
    }
    tickValue = (!tickValue || isNaN(tickValue)) ? tickValue : Number(Number(tickValue).toFixed(2));
    return tickValue;
  }
  if(!data.length) return '';
  return(
    <div className="pa-chart-wrapper">
      <h5 className="chart-header">
        {props.chartTitle}
      </h5>
      <div className="pa-scatter-chart-wrapper">
        <ComposedChart
          width={950}
          height={250}
          data={data}
          margin={{
            top: 20,
            right: 25,
            bottom: 25,
            left: 8,
          }}
        >
          <XAxis
            type="number"
            dataKey="x"
            name={axisLabels.x}
            unit={axisUnits.x}
            tickSize={-8}
            tickMargin={25}
            stroke={'#c3c3c3'}
            shapeRendering="crispEdges"
            domain={domain.xDomain}
            ticks={xTicks}
            tickFormatter={value => tickFormatter(value, 'x')}
          >
            <Label
              value={axisLabels.x}
              position="bottom"
            />
          </XAxis>
          <YAxis
            type="number"
            dataKey="y"
            name={axisLabels.y}
            unit={axisUnits.y}
            tickSize={-8}
            tickMargin={15}
            stroke={'#c3c3c3'}
            shapeRendering="crispEdges"
            domain={domain.yDomain}
            ticks={yTicks}
            tickFormatter={value => tickFormatter(value, 'y')}
          >
            <Label
              value={axisLabels.y}
              position="insideLeft"
              angle={-90}
              style={{ textAnchor: 'middle' }}
            />
          </YAxis>
          <Tooltip cursor={{ strokeDasharray: '3 3' }} />
          <ReferenceLine
            y={domain.yDomain[1]}
            stroke={'#c3c3c3'}
            shapeRendering="crispEdges"
          />
          <ReferenceLine
            y={yTicks[Math.round((yTicks.length - 1) / 2)]}
            stroke={'#c3c3c3'}
            shapeRendering="crispEdges"
          />
          <ReferenceLine
            x={xTicks[Math.round((xTicks.length - 1) / 2)]}
            stroke={'#c3c3c3'}
            shapeRendering="crispEdges"
          />
          <ReferenceLine
            x={domain.xDomain[1]}
            stroke={'#c3c3c3'}
            shapeRendering="crispEdges"
          />
          {data.map((item, index) => {
            const shape = item.name === 'Benchmark' ? 'diamond' : 'circle';
            if(!item.data ||
              (!item.data[0].x && item.data[0].x !== 0) ||
              (!item.data[0].y && item.data[0].y !== 0) ||
              item.percentileValue) return;
            return(
              <Scatter
                key={index}
                name={item.name}
                data={item.data}
                fill={item.color}
                shape={shape}
              />
            );
          })}
        </ComposedChart>
      </div>
    </div>
  )
}

export default React.memo(_FactorExposureChart, areEqual);

const getDomain = (data, tickConfig) => {
  let xData = [], yData =[], xMin, xMax, yMin, yMax;
  data.forEach(item => {
    item.data.forEach(value => {
      xData.push(Number(value.x));
      yData.push(Number(value.y));
    })
  })
  xMin = Math.min(...xData);
  xMax = Math.max(...xData);
  yMin = Math.min(...yData);
  yMax = Math.max(...yData);
  return {
    xDomain: getMaxMinValue(xMin, xMax, 'x', tickConfig),
    yDomain: getMaxMinValue(yMin, yMax, 'y', tickConfig),
  }
}

const getMaxMinValue = (lowerBound = 0, upperBound = 0, type, tickConfig) => {
  let range = getRange(lowerBound, upperBound, type, tickConfig);
  let {lowerLimit, upperLimit} = range;
  lowerLimit = lowerLimit || 0;
  upperLimit = upperLimit || 0;
  return [lowerLimit, upperLimit];
}

const getRange = (lowerBound, upperBound, type, tickConfig) => {
  let maxTicks = type === 'x' ? ticksCount.x : ticksCount.y, range, rangeOffset, tickOffset, tickSpacing, lowerLimit, upperLimit;
  const { allowDecimals = true } = tickConfig;
  let lowerOffset = allowDecimals ? lowerBound : Math.floor(lowerBound);
  let upperOffset = allowDecimals ? upperBound : Math.ceil(upperBound);
  if(lowerOffset === upperOffset){
    if(lowerOffset === 0 && upperOffset === 0){
      lowerOffset = -1;
      upperOffset = 1;
    }else{
      lowerOffset = allowDecimals ? lowerOffset - 0.25 : lowerOffset - 1;
      upperOffset = allowDecimals ? upperOffset + 0.25 : upperOffset + 1;
    }
  }
  range = Number(upperOffset) - Number(lowerOffset);
  rangeOffset = 0.25 *  Math.abs(range);
  lowerLimit = Number(lowerBound) - rangeOffset;
  upperLimit = Number(upperBound) + rangeOffset;
  if(!allowDecimals){
    lowerLimit = Math.floor(lowerLimit);
    upperLimit = Math.ceil(upperLimit);
    let tempRange = Number(upperLimit) - Number(lowerLimit);
    let foundTickCount = false;
    for(let i = maxTicksCount[type]; i >= 4 ; i--){
      const offsetTicks = i - 1;
      let tickSpacing = tempRange / offsetTicks;
      if(tickSpacing % 1 === 0) {
        ticksCount[type] = i;
        foundTickCount = true;
        break;
      }
    }
    if(!foundTickCount){
      const rangeBasedTicks = getRangeBasedTicks(lowerOffset, upperOffset, ticksCount[type]);
      lowerLimit = rangeBasedTicks.lowerLimit;
      upperLimit = rangeBasedTicks.upperLimit;
    }
  }
  return { 
    lowerLimit: Number(lowerLimit),
    upperLimit: Number(upperLimit),
  };
}

const getRangeBasedTicks = (lowerOffset = 0, upperOffset = 0, maxTicks = 1) => {
  let tempRange = Number(upperOffset) - Number(lowerOffset);
  let tempRangeOffset = 0.25 * Math.abs(tempRange);
  let lowerLimit = Math.floor(lowerOffset - tempRangeOffset);
  let upperLimit = Math.ceil(upperOffset + tempRangeOffset);
  let range = Number(upperLimit) - Number(lowerLimit);
  let tickOffset = maxTicks - 1;
  let tickSpacing = Math.ceil(range / tickOffset) * tickOffset;
  upperLimit = lowerLimit + tickSpacing;
  return { lowerLimit, upperLimit };
}

const getTicks = (domain, xTicksCount, yTicksCount) => {
  return {
    xTicks: splitDataForTicks(...domain.xDomain, xTicksCount, -1),
    yTicks: splitDataForTicks(...domain.yDomain, yTicksCount, -1),
  }
}

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  return prevProps.chartTitle === nextProps.chartTitle ? true : false;
}

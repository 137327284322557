import zipObject from 'lodash/zipObject'

const actions = [
  'SET_ENHANCER_STATE',
  'GET_ENHANCER_FUNDS',
  'RESET_ENHANCER_STATE',

  'GET_TICKER_DATA_REQUEST',
  'GET_TICKER_DATA_FETCH',
  'GET_TICKER_DATA_SUCCESS',

  'DELETE_TICKER_DATA',

  'PORTFOLIO_DATA_REQUEST',
  'PORTFOLIO_DATA_FETCH',
  'PORTFOLIO_DATA_SUCCESS',

  'LOAD_DEMO_CSV_REQUEST',
  'LOAD_DEMO_CSV_FETCH',
  'LOAD_DEMO_CSV_SUCCESS',

  'CHECK_SINGLE_TICKER_EXIST_REQUEST',
  'CHECK_SINGLE_TICKER_EXIST_FETCH',
  'CHECK_SINGLE_TICKER_EXIST_SUCCESS',

  'UPLOAD_CSV_FILE_REQUEST',
  'UPLOAD_CSV_FILE_FETCH',
  'UPLOAD_CSV_FILE_SUCCESS',

  'UPLOAD_CSV_REQUEST',
  'UPLOAD_CSV_FETCH',
  'UPLOAD_CSV_SUCCESS',

  'GET_PORTFOLIO_BY_ID_REQUEST',
  'GET_PORTFOLIO_BY_ID_FETCH',
  'GET_PORTFOLIO_BY_ID_SUCCESS',

  'LOAD_CPM_LIST_REQUEST',
  'LOAD_CPM_LIST_FETCH',
  'LOAD_CPM_LIST_SUCCESS',

  'GET_CLIENT_LIST_REQUEST',
  'GET_CLIENT_LIST_FETCH',
  'GET_CLIENT_LIST_SUCCESS',

  'DELETE_CLIENT_LIST_REQUEST',
  'DELETE_CLIENT_LIST_FETCH',
  'DELETE_CLIENT_LIST_SUCCESS',

  'GET_MODELS_LIST_REQUEST',
  'GET_MODELS_LIST_FETCH',
  'GET_MODELS_LIST_SUCCESS',

  'GET_PORTFOLIO_LIST_REQUEST',
  'GET_PORTFOLIO_LIST_FETCH',
  'GET_PORTFOLIO_LIST_SUCCESS',

  'VERIFY_CSV_TICKER_AND_PRICE_REQUEST',
  'VERIFY_CSV_TICKER_AND_PRICE_FETCH',
  'VERIFY_CSV_TICKER_AND_PRICE_SUCCESS',

  'VALIDATE_TICKER_REQUEST',
  'VALIDATE_TICKER_FETCH',
  'VALIDATE_TICKER_SUCCESS',

  'RESET_SUMMARY_STATS_RETURN_TO_ONBOARDSCREEN_REQUEST',
  'RESET_SUMMARY_STATS_RETURN_TO_ONBOARDSCREEN_FETCH',
  'RESET_SUMMARY_STATS_RETURN_TO_ONBOARDSCREEN_SUCCESS',

  'GET_FUNDS_PORTFOLIO_DATA_BY_ID_REQUEST',
  'GET_FUNDS_PORTFOLIO_DATA_BY_ID_FETCH',
  'GET_FUNDS_PORTFOLIO_DATA_BY_ID_SUCCESS',

  'RESET_FUNDS_PORTFOLIOS_RTO_REQUEST',
  'RESET_FUNDS_PORTFOLIOS_RTO_FETCH',
  'RESET_FUNDS_PORTFOLIOS_RTO_SUCCESS',

  'ADD_FUND_FOR_ENHANCE_REQUEST',
  'ADD_FUND_FOR_ENHANCE_FETCH',
  'ADD_FUND_FOR_ENHANCE_SUCCESS',

  'DELETE_FUND_FROM_ENHANCE_REQUEST',
  'DELETE_FUND_FROM_ENHANCE_FETCH',
  'DELETE_FUND_FROM_ENHANCE_SUCCESS',

  'FINALIZE_PORTFOLIO_REQUEST',
  'FINALIZE_PORTFOLIO_FETCH',
  'FINALIZE_PORTFOLIO_SUCCESS',

  'FINALIZE_MODEL_PORTFOLIO_REQUEST',
  'FINALIZE_MODEL_PORTFOLIO_FETCH',
  'FINALIZE_MODEL_PORTFOLIO_SUCCESS',

  'SAVE_PORTFOLIO_REQUEST',
  'SAVE_PORTFOLIO_FETCH',
  'SAVE_PORTFOLIO_SUCCESS',

  'CLEAR_FINALIZE_DATA_REQUEST',
  'CLEAR_FINALIZE_DATA_FETCH',
  'CLEAR_FINALIZE_DATA_SUCCESS',

  'CLEAR_FUND_PORTFOLIO_PAGE_DATA',

  'GET_ALL_TICKER',
  'SAVE_ALL_TICKER',

  'SET_REPLACEMENT_TICKER',
  'SAVE_REPLACEMENT_TICKER',

  'DOWNLOAD_ENHANCER_REPORT',
  'DOWNLOAD_RECOMMENDATION_ENHANCER_REPORT',

  'SET_ENHANCED_TRANSACTIONS_TRADE',
  'SAVE_ENHANCED_TRANSACTIONS_TRADE',

  'GET_MODEL_PORTFOLIOS_BY_ID_REQUEST',
  'GET_MODEL_PORTFOLIOS_BY_ID_FETCH',
  'GET_MODEL_PORTFOLIOS_BY_ID_SUCCESS',

  'GET_CURRENT_PORTFOLIO_REQUEST',
  'GET_CURRENT_PORTFOLIO_FETCH',
  'GET_MY_CURRENT_PORTFOLIO_SUCCESS',

  'REPLACE_CURRENT_MODEL_PORTFOLIO_REQUEST',
  'REPLACE_CURRENT_MODEL_PORTFOLIO_FETCH',
  'REPLACE_CURRENT_MODEL_PORTFOLIO_SUCCESS',

  'DELETE_SPECIFY_REPLACEMENT',

  'SET_MODEL_PORTFOLIO_ENHANCEMENT_DATA',
  'DELETE_PORTFOLIO_BY_ID_REQUEST',
  'DELETE_PORTFOLIO_BY_ID_FETCH',
  'DELETE_PORTFOLIO_BY_ID_SUCCESS',

  'RESET_FINALIZEDATA_STATE_REQUEST',
  'RESET_FINALIZEDATA_STATE_FETCH',
  'RESET_FINALIZEDATA_STATE_SUCCESS',

  'UPDATE_EVALUATE_FILTER_REQUEST',
  'UPDATE_EVALUATE_FILTER_FETCH',
  'UPDATE_EVALUATE_FILTER_SUCCESS',

  'GET_MP_DETAILS_CUSTOM_REPLACE_REQUEST',
  'GET_MP_DETAILS_CUSTOM_REPLACE_FETCH',
  'GET_MP_DETAILS_CUSTOM_REPLACE_SUCCESS',

  'SAVE_MP_DETAILS_CUSTOM_REPLACE',

  'REMOVE_CUSTOM_MP_SELECTION',

  'DESELECT_ALL_ENHANCER_FUNDS',

  'USE_SUGGESTED_FUNDS_REQUEST',
  'USE_SUGGESTED_FUNDS_FETCH',
  'USE_SUGGESTED_FUNDS_SUCCESS',

  'UPLOAD_PORTFOLIO_DATA_BY_ID_REQUEST',
  'UPLOAD_PORTFOLIO_DATA_BY_ID_FETCH',
  'UPLOAD_PORTFOLIO_DATA_BY_ID_SUCCESS',

  'SET_ENHANCER_UNIVERSE',
  'RESET_ENHANCER_UNIVERSE',

  'SET_ENHANCER_FUNDS_UNIVERSE',
  'RESET_ENHANCER_FUNDS_UNIVERSE',

  'SET_ENHANCER_MODELS_UNIVERSE',
  'RESET_ENHANCER_MODELS_UNIVERSE',

  'CHECK_TOTUM_ADVISOR_REQUEST',
  'CHECK_TOTUM_ADVISOR_FETCH',
  'CHECK_TOTUM_ADVISOR_SUCCESS',

  'SUBMIT_TOTUM_ADVISOR_REQUEST',
  'SUBMIT_TOTUM_ADVISOR_FETCH',
  'SUBMIT_TOTUM_ADVISOR_SUCCESS',

  'TOTUM_SCORE_RESET',

  'GET_CURRENT_PORTFOLIO_STATS_REQUEST',
  'GET_CURRENT_PORTFOLIO_STATS_FETCH',
  'GET_CURRENT_PORTFOLIO_STATS_SUCCESS',

  'GET_ENHANCED_STATS_FOR_SELECTED_TICKER_REQUEST',
  'GET_ENHANCED_STATS_FOR_SELECTED_TICKER_FETCH',
  'GET_ENHANCED_STATS_FOR_SELECTED_TICKER_SUCCESS',

  'RESEND_TOTUM_INVITE_EMAIL_REQUEST',
  'RESEND_TOTUM_INVITE_EMAIL_FETCH',
  'RESEND_TOTUM_INVITE_EMAIL_SUCCESS',

  'GET_CURRENT_PORTFOLIO_SCORE_REQUEST',
  'GET_CURRENT_PORTFOLIO_SCORE_FETCH',
  'GET_CURRENT_PORTFOLIO_SCORE_SUCCESS',

  'GET_ENHANCED_PORTFOLIO_SCORE_REQUEST',
  'GET_ENHANCED_PORTFOLIO_SCORE_FETCH',
  'GET_ENHANCED_PORTFOLIO_SCORE_SUCCESS',

  'GET_TICKER_REAL_TIME_PRICE_REQUEST',
  'GET_TICKER_REAL_TIME_PRICE_FETCH',
  'GET_TICKER_REAL_TIME_PRICE_SUCCESS',

  'TOTUM_QUESTIONNAIRE_STATUS_REQUEST',
  'TOTUM_QUESTIONNAIRE_STATUS_FETCH',
  'TOTUM_QUESTIONNAIRE_STATUS_SUCCESS',

  'DOWNLOAD_CURRENT_PORTFOLIO_REPORT',

  'UPLOAD_PORTFOLIO_CSV_REQUEST',
  'UPLOAD_PORTFOLIO_CSV_FETCH',
  'UPLOAD_PORTFOLIO_CSV_SUCCESS',

  'UPDATE_CLIENT_ACCOUNT_NAME_REQUEST',
  'UPDATE_CLIENT_ACCOUNT_NAME_FETCH',
  'UPDATE_CLIENT_ACCOUNT_NAME_SUCCESS',
]

export default zipObject(actions, actions)

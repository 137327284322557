import React, { Fragment } from 'react';
import moment from 'moment';
import { Row, Col, Button } from 'antd';
import { FormatCurrency, TruncatedNameSponsor } from './Common';
import { numFmt, convertToFloatvalues } from 'Utils'

import {
	getImageOrFallback,
} from 'Utils';
import {
	cn,
	sponsorLogo,
} from 'DataSet';

import Emitter from 'realTimePrice/emitter';
import { renderMappedVehicle } from 'Utils';

class HoldingCard extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			imageValidPath: false,
			realPriceObj: {},
		}
	}

	componentDidMount() {
		const { item } = this.props;
		this.checkSponsorLogo(item);
	}

	checkSponsorLogo = (card) => {
		if (cn(card, 'Sponsor')) {
			getImageOrFallback(
				sponsorLogo(cn(card, 'Sponsor'))
			).then(result => {
				if (result) {
					this.setState({ imageValidPath: true })
				} else {
					this.setState({ imageValidPath: false })
				}
			});
		}
	}

	buyHandler = (thisItem, selectedType) => {
		try {
			const {
				summary,
				sfmSelectedItemTickers,
				addSFMItemTickersRequest,
				removeSFMItemTickersRequest,
			} = this.props;
			if (summary && summary.portfolioFunds) {
				const { portfolioFunds } = summary;
				const ticker = thisItem.ticker || thisItem.script || thisItem.symbol;
				// console.log('== fetching apex profile details ==');
				const { refreshApexStateRequest } = this.props;
				if (refreshApexStateRequest) {
					refreshApexStateRequest();
				}
				// NOTE: Add fund to SFM
				//V-2 logic
				// console.log(`========>>>> ADDING TICKER ${ticker} IN SFM <<<<========`);
				const alreadyPresent = sfmSelectedItemTickers.find((l) => l === ticker)
				if (alreadyPresent) {
					// un_select logic
					if (removeSFMItemTickersRequest) {
						removeSFMItemTickersRequest({
							removeTicker: ticker,
							selectedType,
						})
					}
				} else {
					// select logic
					if (addSFMItemTickersRequest) {
						addSFMItemTickersRequest({
							selectedItemTickers: ticker,
							selectedType,
							autoOpenModal: true,
						})
					}
				}
			} else {
				throw '-- retData is missing --'
			}
		} catch (e) {
			console.log(e);
		}
	}

	// deleteHandler = (item) => {
	// 	const { deleteHandler } = this.props;
	// 	if (deleteHandler) {
	// 		deleteHandler(item);
	// 	}
	// }

	calculateReturn = (item, quantityValue) => {
		try {
			const { sliderType } = this.props;
			let finalPrice = null;
			let finalPrice_per = null;
			let type = `${sliderType}_nav`;
			let { purchase_date } = item;
			if (typeof purchase_date !== 'undefined' && moment(purchase_date).isValid()) {
				const purchaseDate = moment(purchase_date).format('YYYY-MM-DD');
				const currentDate = moment(new Date()).format('YYYY-MM-DD');
				if (typeof item[type] !== 'undefined' && moment(purchaseDate).isSameOrBefore(currentDate)) {
					//daily_nav, monthly_nav, yearly_nav  --- retToday, retMonthly, retYearly
					if (type == 'daily_nav') {
						// finalPrice = (this.state.realPriceObj && typeof this.state.realPriceObj.diff !== 'undefined') ? (this.state.realPriceObj.diff * quantityValue) : parseFloat(item.retTodayAmount)
						// finalPrice_per = (this.state.realPriceObj && typeof this.state.realPriceObj.change !== 'undefined') ? (this.state.realPriceObj.change) : parseFloat(item.retToday)
						if(moment().diff(moment(purchase_date),'day') < 1) {
							// finalPrice_per = parseFloat(item.retToday)
							finalPrice_per = (item._realPriceObj) ? parseFloat(item._realPriceObj.price / item.daily_nav-1) : parseFloat(item.retToday)
							// finalPrice = parseFloat(item.retTodayAmount)
							finalPrice = (item._realPriceObj) ? parseFloat(item.daily_nav *  (item._realPriceObj.price / item.daily_nav-1) * item.shares) : parseFloat(item.retTodayAmount)
						}
						else{
							finalPrice = (item._realPriceObj && typeof item._realPriceObj.diff !== 'undefined') ? (item._realPriceObj.diff * quantityValue) : parseFloat(item.retTodayAmount)
							finalPrice_per = (item._realPriceObj && typeof item._realPriceObj.change !== 'undefined') ? (item._realPriceObj.change) : parseFloat(item.retToday)
						}
					} else if(type == 'monthly_nav') {
						finalPrice = parseFloat(item.retMonthlyAmount)
						finalPrice_per = parseFloat(item.retMonthly)
					} else if(type == 'yearly_nav'){
						finalPrice = parseFloat(item.retYearlyAmount)
						finalPrice_per = parseFloat(item.retYearly)
					}
					let percentValue = finalPrice_per!== null && !isNaN(finalPrice_per) ? finalPrice_per === 0 ? 0 : numFmt((finalPrice_per)) : '--'; // finalPrice_per ? numFmt((finalPrice_per) * 100) : '--';
					return {
						finalPrice,
						percentValue,
					};
				} else {
					throw `-- ${type} is missing --`
				}
			} else {
				throw `-- ${purchase_date} is not valid --`
			}
		} catch (e) {
			console.log(e);
			return null;
		}
	}

	render() {
		const { item, last } = this.props;
		const {
			sfm,
			items: portfolioItems,
			addFundToSFM,
			refreshSFMState,
			setEnhancerState,
			summary,
			view,
			sliderType
		} = this.props;
		const { items: sfmItems } = sfm;
		const { imageValidPath } = this.state;

		const cost = item.market_value || item.price;
		// console.log(item.weight);
		const weightToBe = item.original_weight ? parseFloat(item.original_weight.toFixed(2)) : null;

		let _activeFundTicker = this.props.activeFundTicker;
		const quantityValue = convertToFloatvalues(item.shares);

		// let tickerWillBe = item.ticker; // <-- for testing purpose use "ANKIT" as a ticker
		let renderReturnStats = this.calculateReturn(item, quantityValue);
		let finalPrice = renderReturnStats && typeof renderReturnStats.finalPrice !== "undefined" ? renderReturnStats.finalPrice : null;
		let percentValue = renderReturnStats && typeof renderReturnStats.percentValue !== "undefined" ? renderReturnStats.percentValue : null;
		// console.log(percentValue)
		let FUND_REAL_TIME_PRICE = item.current_price;

		let averageCostValue = (!isNaN(item.purchase_price) && item.purchase_price !== 'NaN' && item.purchase_price !== '') ? convertToFloatvalues(item.purchase_price) * quantityValue : '--';
		averageCostValue = FormatCurrency(averageCostValue, 0);

		let marketValue = (!isNaN(FUND_REAL_TIME_PRICE) && FUND_REAL_TIME_PRICE !== 'NaN' && FUND_REAL_TIME_PRICE !== '') ? convertToFloatvalues(FUND_REAL_TIME_PRICE) * quantityValue : '--';
		// marketValue = FormatCurrency(marketValue, 0);
		if (item.type === 'BASKET') {
			marketValue = (item.market_value !== '' && item.market_value !== '--' && !isNaN(item.market_value) && item.market_value !== 'NaN' && item.market_value !== null && !isNaN(convertToFloatvalues(item.market_value))) ? FormatCurrency(convertToFloatvalues(item.market_value), 0) : '--';
		} else {
			marketValue = (marketValue !== '--' && !isNaN(marketValue) && marketValue !== 'NaN') ? FormatCurrency(marketValue, 0) : '--';
		}

		const self_role = window.localStorage.getItem('profile_self_role');
		const prefixPositivePrice = finalPrice > 0 ? "+" : "";
		return (
			<Row className={`accounts-summary-holdings-card ${((item.ticker_short || item.ticker) === _activeFundTicker) ? 'accounts-summary-holdings-card-selected' : ''}`} onClick={this.props.itemClickHandler(item.ticker)}>
				<Col span={4} className="ticker-block">
					<h5 className="mb-0 primary-color">{item.ticker_short || item.ticker}</h5>
					<h3 className={`mb-0 holdings-weight-font-size ${finalPrice !== 0 ? finalPrice > 0 ? 'portfolio-profit': 'portfolio-loss' : ''}`}>{(marketValue && marketValue !== '--') ? marketValue : '--'}</h3>
					{item.type === "BASKET"
						? <h3 className="mb-0 holdings-weight-vehicle">Managed<br />Portfolio</h3>
						: <h3 className="mb-0 holdings-weight-vehicle">{renderMappedVehicle(item.type)}</h3>
					}
				</Col>
				<Col span={5} className="name-block">
					{/*
						<TruncatedNameSponsor
							imageValidPath={imageValidPath}
							weight={weightToBe}
							name={item.short_name}
							sponsor={item.sponsor}
							lines={1}
							style={{ width: '100%' }}
						/>
					*/}
					<div style={{ width: '100%' }}>
						<div className="pb-1">
							{imageValidPath ? (
								<img
									src={item.sponsor ? sponsorLogo(item.sponsor) : ''}
									className="img-fluid"
									alt={item.sponsor}
									style={{ height: '20px', objectFit: 'contain' }}
								/>
							) : (
								<div className="sponsor-logo-placeholder" title={item.sponsor} style={{ width: '100%', height: 22, lineHeight: '22px' }}>
									{item.sponsor || ''}
								</div>
							)}
						</div>
						<h5 className="font-weight-bold text-ssf-blue-d2 mb-0 truncate lh-125" title={item.short_name}>{item.short_name}</h5>
					</div>
				</Col>
				<Col span={4} className="FRR-block">
					<h5 className="font-weight-normal m-0" title={(item.type === "BASKET") ? '--' : numFmt(quantityValue, 5)}>
						{(item.type === "BASKET") ? '--' : numFmt(quantityValue, 2)}
					</h5>
				</Col>
				<Col span={4} className="FRR-block">
					<h5 className="font-weight-normal m-0">{(averageCostValue && averageCostValue !== '--') ? averageCostValue : '--'}</h5>
				</Col>
				<Col span={4} className="FRR-block" title={"$" + (numFmt(finalPrice, 5) === '' ? 0 : numFmt(finalPrice, 5)) }>
					{((finalPrice) !== 0) ? (
						<>
							<h5 className={`${(finalPrice) > 0 ? 'portfolio-profit' : 'portfolio-loss'}`}>{(finalPrice !== null && !isNaN(finalPrice) && !isNaN(numFmt(finalPrice))) ? prefixPositivePrice + FormatCurrency(numFmt(finalPrice), 0) : '--'}</h5>
							<h3 className={`${(finalPrice) > 0 ? 'portfolio-profit' : 'portfolio-loss'}`}>{(percentValue !== null && !isNaN(percentValue)) ? percentValue+'%': '--'}</h3>
						</>
					) : (
						<>
							<h5>{(finalPrice !== null && !isNaN(finalPrice) && !isNaN(numFmt(finalPrice))) ? FormatCurrency(numFmt(finalPrice), 0) : '--'}</h5>
							<h3>{(percentValue !== null && !isNaN(percentValue)) ? percentValue+'%': '--'}</h3>
						</>
					)}
				</Col>
				<Col span={3} className="cta-block">
					<Button
						outline={!item._selected && (item._selectedType !== 'sell')}
						disabled={(item._selected && item._selectedType !== 'buy')}
						color={item._selected ? "unselect" : "select"}
						className={`mb-0 py-1 selectBtn buy-btn ${(item._selected && (item._selectedType === 'buy')) ? 'selected' : ''}`}
						onClick={() => { this.buyHandler(item, 'buy') }}
						style={{ position: 'relative', maxWidth: 50 }}
						>
						{((item._selected && item._selectedType === 'buy') ? 'CLEAR' : 'BUY')}
					</Button>
					<Button
						color="select"
						outline={!item._selected && (item._selectedType !== 'sell')}
						disabled={(item._selected && item._selectedType !== 'sell')}
						className={`mb-0 mt-1 py-1 selectBtn buy-btn ${(item._selected && (item._selectedType === 'sell')) ? 'selected' : ''}`}
						onClick={() => { this.buyHandler(item, 'sell') }}
						style={{ position: 'relative', maxWidth: 50 }}
						>{(item._selected && item._selectedType === 'sell') ? 'CLEAR' : 'SELL'}</Button>
				</Col>
			</Row>
		)
	}
}

export default HoldingCard;

import React, { Component } from 'react';
import { addEventToAnalytics } from 'Utils';
import  SearchSuggestions  from 'shared-components/SearchSuggestions';
import { getSaveSearchList,getSearchRecentList } from 'shared-components/SearchSuggestions/GetSuggestions';
import _ from 'lodash';

const classNames = require('classnames');

class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '', active: false, searchValue: '', enableSaveIcon: false, saveListDataResponse:[], saveRecentSearchResponse:[], search_type:'',starAddIcon:false, searchInputBlank:false};

    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

async componentDidMount() {
  const { loggedIn } = this.props;
  if (loggedIn) {
    this.fetchSaveSearchList();
    this.fetchRecentSuggestions();
  }
}

async componentWillReceiveProps(newProps) {
    if (typeof newProps.query !== 'undefined' && (newProps.query || newProps.query === '') && newProps.loggedIn && !this.state.searchValue) {
      //this.fetchRecentSuggestions();
      //this.fetchSaveSearchList();
      (!this.state.searchInputBlank && (this.state.value === '' || newProps.query === this.state.value)) && this.setState({ value: newProps.query, active: this.validQuery(newProps.query) });
      (this.props.query !== newProps.query) && this.setState({ value: newProps.query, active: this.validQuery(newProps.query)});
      this.setState({enableSaveIcon: true})
      if(this.state.saveRecentSearchResponse && this.state.saveRecentSearchResponse.length){
        let _filterItem=this.state.saveRecentSearchResponse[0].suggestions.find(item=>item.query_string===newProps.query)
        if(_filterItem && typeof _filterItem === 'object'){
          this.setState({
            //enableSaveIcon: _filterItem.query_string === newProps.query,
            //enableSaveIcon: true,
            starAddIcon : this.state.saveListDataResponse.some(item=>item.query===newProps.query),
            search_type : _filterItem.search_type
          })
        }else{
          this.setState({
            //enableSaveIcon: true,
            starAddIcon : this.state.saveListDataResponse.some(item=>item.query===newProps.query),
            search_type : 'SearchBar'
          })
        }
      }
    }
    if(!newProps.loggedIn){
      (this.state.value === '' || newProps.query === this.state.value) && this.setState({ value: newProps.query, active: this.validQuery(newProps.query) });
      (this.props.query !== newProps.query) && this.setState({ value: newProps.query, active: this.validQuery(newProps.query)});
    }
  }

  handleChange(val) {
    let updateVal = typeof val==='object'? val.query_string : val;
    if(!updateVal){
      this.setState({searchInputBlank:true})
    }
    this.setState({ value: updateVal, searchValue: updateVal, active: this.validQuery(updateVal) ,enableSaveIcon: false});
  }

  /**GET SAVE SEARCH LIST */
  fetchSaveSearchList = async () =>{
    const response = await getSaveSearchList();
    if(response && response.saveListResponse){
      this.setState({
        saveListDataResponse:response.saveListResponse,
        starAddIcon: response.saveListResponse.some(item=>item.query===this.state.value)
      })
    }
  }

  enableSaveIcon = () => {
    console.log('calling ')
    this.setState({
      enableSaveIcon:true
    })
  }

   /**GET RECENT SUGGESTION SEARCH LIST */
   fetchRecentSuggestions = async () => {
    const response = await getSearchRecentList()
    if(response && response.length){
      this.setState({
        saveRecentSearchResponse:response
      })
    }
  }

  handleSearch(val, extra, searchType) {
    // console.log('handleSearch extra ->', extra)
    const { loggedIn, queryHandler } = this.props;
    this.setState({searchValue: ''})
    let updateVal = typeof val==='object'? val.query_string : val;
    window.sessionStorage.removeItem('filterAttrs')
    window.sessionStorage.removeItem('sortAttr')
    window.sessionStorage.removeItem("rankingAttr")
    window.sessionStorage.removeItem("cardSortAttr")

    if (this.validQuery(updateVal)) {
      if(!this.props.fromSecurities){
        this.props.history.push('/securities');
      }
      let QueryVal = updateVal.trim();
      if (queryHandler) {
        queryHandler(QueryVal, searchType, extra);
      }
      addEventToAnalytics('Search Bar', 'Search Bar', 'SEARCH_BAR', { query: QueryVal, loggedIn });
    }
  }

  clearSearch = () => {
    this.setState({
      value: '',
    });
    document.getElementById("fund-search-box").focus();
  }

  validQuery = (query) => {
    if(typeof query !== "undefined"){
      if(typeof query === 'object'){
        return query.query_string.trim().length > 0
      }else{
        return query.trim().length > 0
      }
    }else{
      return false
    }
  }

  render() {
    const { universe, loggedIn, surveyFormConfirmation, surveyModelOpen } = this.props;
    return (
      <form style={{ position: 'relative' }}>
        <div className={classNames("input-group h2 mb-0 search-box")}>
          <SearchSuggestions
            loggedIn={loggedIn}
            universe={universe}
            surveyFormConfirmation={surveyFormConfirmation}
            searchText={this.state.value}
            setEnableSearchIcon={()=>this.enableSaveIcon()}
            surveyModelOpen={surveyModelOpen}
            search_type={this.state.search_type}
            fetchSaveSearchList={()=>this.fetchSaveSearchList()}
            setSearchText={(val)=>{this.handleChange(val)}}
            saveListDataResponse={this.state.saveListDataResponse}
            enableSaveIcon={this.state.enableSaveIcon}
            starAddIcon={this.state.starAddIcon}
            onPressEnter={async (val, extra, searchType) => {
              if(!this.props.fromSecurities){
                _.debounce(async (val) => { await this.handleSubmit(val)}, 0)
              } else {
                this.setState({enableSaveIcon: true},
                  ()=> this.handleSearch(val, extra, searchType)
                )
              }
            }}
            isClearIconActive={this.state.active}
          />
          </div>
      </form>
    );
  }
}

export default SearchBox;

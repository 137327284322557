import React from 'react'
import { connect } from 'react-redux'
import { ButtonGroup } from 'reactstrap'
import { PrimaryButton } from 'shared-components'
import { createSubscriptionRequest } from 'actions/profile'
import { Checkbox, Divider } from 'antd';
import config from 'env';
import {addEventToAnalytics} from 'Utils';
import TermsConditions from 'layouts/WebDashboard/SearchResults/Elements/Registrations/AgreeTermsNConditions';
// import { RETAIL_USERS_ROLES } from '../../layouts/WebDashboard/Constant/FormData';
import style from './PremiumPlanDetails.module.scss'

const PREMIUM ='Premium Plan'
const LITE ='Premium Lite'

class PremiumPlanDetails extends React.Component {
  // state = {
  //   isTermsOpen: false,
  //   enableGetStarted: false,
  //   tab:(selfRole === 'Other/Curious' || selfRole === 'Individual Investor') ? LITE : PREMIUM,
  // }

  constructor(props) {
    super(props)
    // this.props.createSubscriptionRequest();
    const selfRole = this.props.profile && this.props.profile.profile.self_role;

    this.state = {
      isTermsOpen: false,
      enableGetStarted: false,
      tab:(selfRole === 'Other/Curious' || selfRole === 'Individual Investor') ? LITE : PREMIUM,
      selfRole: selfRole
    }
    this.generateSessionId = this.generateSessionId.bind(this);
    this.subscribeNow = this.subscribeNow.bind(this);
  }

  generateSessionId() {
    this.props.createSubscriptionRequest({ plan_name: this.state.tab },this.subscribeNow)
  }

  subscribeNow(sessionId) {
    if(this.props.featureName){
      addEventToAnalytics('Premium Upgrade','Premium Upgrade','PREMIUM_UPGRADE',{feature_name: this.props.featureName},false);
    }
    // const sessionId = this.props.stripeSessionID;
    if(sessionId){
      this.stripe = window.Stripe(config.stripePublishableKey);
      this.stripe.redirectToCheckout({
        sessionId
      }).then((result) => {
        // console.log("results status",result)
      });
    }
   }

  onTermsAndConditionClick() {
    this.setState({
      isTermsOpen: !this.state.isTermsOpen
    })
  }

  onTermCheckBoxClixk() {
    this.setState({
      enableGetStarted: !this.state.enableGetStarted
    })
  }
  changePlanSelection =(plan)=>{
    this.setState({tab:plan})
  }
  render() {
    const { isTermsOpen, enableGetStarted,tab } = this.state
    // const profile_self_role =  window.localStorage.getItem('profile_self_role') ? window.localStorage.getItem('profile_self_role') : 'loggedOut';
    // let seletorName =  RETAIL_USERS_ROLES.includes(profile_self_role) ? "Cart" : "Selector";
    return (
      <React.Fragment>
        <div className={style.planHeader}>
          {(this.state.selfRole !== 'Other/Curious' && this.state.selfRole !== 'Individual Investor') && <span onClick={()=>this.changePlanSelection(PREMIUM)} className={`${tab===PREMIUM && style.active}  ${style.subHeader}`}>Magnifi Pro Plus</span>}
          {(this.state.selfRole !== 'Other/Curious' && this.state.selfRole !== 'Individual Investor') && <div className={style.separator}/>}
          <span onClick={()=>this.changePlanSelection(LITE)} className={`${tab===LITE && style.active} ${style.subHeader}`}>Magnifi Pro</span>
        </div>
        <div className={style.subtext}>
          <p>{tab===PREMIUM?'In addition to all the features of Magnifi Pro, unlock the full power of Magnifi to manage models, portfolios, and client accounts all in one place.':'Modernize investment discovery, proposal generation, and client engagement with Magnifi\'s natural language and investment intelligence capabilities.'}</p>
        </div>
        <div className="premium-msg-plan-details">
          <ul>
            {(tab === LITE && process.env.REACT_APP_SUB_DOMAIN !== "retail") && (
              <>
                <li>
                  <p>Enhancer: Produce instant value by
                        showing clients and prospects
                        enhancements to their portfolios.
                        Generate actionable proposals with clear
                        adjustments to meet individual
                        investment objectives.
                  </p>
                </li>
                <div style={{listStyleType: 'none', textAlign: 'center'}}><h4><Divider>Or</Divider></h4></div>
              </>
            )}
            <li>{process.env.REACT_APP_SUB_DOMAIN === "retail" ? "Cart" : "Selector"}: Compare funds, SMAs, and models based on customizable portfolio attributes using Magnifi's patented fund Selector. See the impact of adding new holdings into your portfolio, or download the data to excel. </li>
            <li>Client Proposals: White-label ready proposals document and communicate investment strategies to clients. Use the proposals to store your due diligence for Reg BI, Suitability, and other firm requirements.</li>
            {tab===PREMIUM && <li>Builder: Magnifi's Builder capability helps construct better portfolios via existing models or from scratch and test changes, add thematic tilts, and more. </li>}
            {tab===PREMIUM &&<li>Enhancer: Produce instant value by showing clients and prospects enhancements to their portfolios. Generate actionable proposals with clear adjustments to meet individual investment objectives.</li>}
            {tab===PREMIUM &&<li>Client Portal: Look across all your client accounts in seconds. Use plain-English search to find specific holdings and allocations within accounts, to adjust exposures quickly. </li>}
          </ul>
          <div className="d-flex justify-content-center terms-condition-select">
            <Checkbox class="custom-checkbox" onClick={()=> this.onTermCheckBoxClixk()}>&nbsp;I agree to the&nbsp;<span className="terms-text" onClick={() => this.onTermsAndConditionClick()}>terms and conditions</span></Checkbox>
          </div>
        </div>
        <ButtonGroup className="cta-wrapper justify-content-center button-blue">
          <PrimaryButton disabled={!this.props.stripeSessionID} className="btn secondary-btn fs-20" type="button" onClick={()=>{this.generateSessionId()}} disabled={!enableGetStarted} style={{ maxWidth: 435, margin: 0 }}>
            {(tab === PREMIUM) ? 'GET STARTED WITH MAGNIFI PRO PLUS' : 'GET STARTED WITH MAGNIFI PRO'}
          </PrimaryButton>
        </ButtonGroup>
        {isTermsOpen && <TermsConditions onTermsClick={() => this.onTermsAndConditionClick()}/>}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ profile }) => ({
  stripeSessionID: profile.stripeSessionID,
  profile: profile
})

const mapDispatchToProps = {
  createSubscriptionRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PremiumPlanDetails);

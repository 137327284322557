import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Form, FormGroup, Input, Modal, ModalBody } from 'reactstrap'
import { CustomFormFeedback } from 'shared-components'
import { addEventToAnalytics } from 'Utils';

const ClientNameEditModal = ({ children, For, data, visible, enableDelete, closeModalToggle, updateClientAccountNameRequest, getUpdatedClientList, ...props }) => {
	// console.log(data)
	const clientNameSchema = Yup.object().shape({
		client_name: Yup.string().required('Client name is a required field'),
	})
	const handleSubmit = (values, { setSubmitting }) => {
		try {
			let payloadRequest = {
				id: values.client_id,
				name: values.client_name,
			}
			addEventToAnalytics(
				'Client Name Update Request Submitted',
				'Client Name Update Request Submitted',
				'CLIENT_NAME_UPDATE_REQUEST_SUBMITTED', {
					client_id: values.client_id,
					name: values.client_name,
				},
				true,
			);
			// console.log(payloadRequest);
			if (updateClientAccountNameRequest) {
				updateClientAccountNameRequest(For, payloadRequest, (response) => {
					if (response && response.message === 'Client data saved successfully.') {
						closeModalToggle();
						if (getUpdatedClientList) {
							getUpdatedClientList(response);
						}
					}
				});
			}
			setSubmitting && setSubmitting(false); // To stop submit cycle---Formik
		} catch(e) {
			console.log("<----- Error in submitting Form Data ------->");
		}
	}

	return (
		<Fragment>
			<Modal
				centered
				backdrop
				keyboard={true}
				isOpen={visible}
				className="delete-confirm-modal premium-msg-modal account-modal client-name-edit-modal"
				backdropClassName="premium-feature-process-modal-backdrop top-60px"
				toggle={closeModalToggle}
				>
				<ModalBody className="ssf-modal__body">
					<div className="premium-feature-process-modal-container">
						<i
							className="fal fa-times-circle premium-feature-completed-close"
							onClick={closeModalToggle}
							></i>
						<p className="content-wrapper">
							<div className="col-12 question">
								Update Client Account Name
							</div>
							<Formik
								initialValues={{ client_name: data.client_name, client_id: data.client_id }}
								onSubmit={handleSubmit}
								validationSchema={clientNameSchema}
								render={props => (
									<Form
										noValidate={true}
										className={`col-12 client-name-edit-form-wrapper`}
										onSubmit={props.handleSubmit}
										>
										<FormGroup className="element-box">
											<Input
												name="client_name"
												id="client_name"
												value={props.values.client_name}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												className={`sign-in__input-field input-area client-name-edit-input ${props.errors.client_name && props.touched.client_name ? ' is-invalid' : ''}`}
												/>
											<CustomFormFeedback formProps={props} fieldName="client_name" />
										</FormGroup>
										<FormGroup className="cta-wrapper col-12 margin-left-51 p-0 m-0">
											<button
												disabled={props.isSubmitting}
												type="submit"
												className="ssf-btn-primary btn btn-primary"
												>
												<span>UPDATE</span>
											</button>
											<button
												type="button"
												className="ssf-btn-primary btn btn-secondary"
												onClick={closeModalToggle}
												>
												<span>CANCEL</span>
											</button>
										</FormGroup>
									</Form>
								)}
							/>
						</p>
					</div>
				</ModalBody>
			</Modal>
		</Fragment>
	)
}

export default ClientNameEditModal;

ClientNameEditModal.propTypes = {
	visible: PropTypes.bool.isRequired,
	data: PropTypes.object,
	For: PropTypes.string,
	enableDelete: PropTypes.bool,
	getUpdatedClientList: PropTypes.func,
	closeModalToggle: PropTypes.func.isRequired,
	updateClientAccountNameRequest: PropTypes.func.isRequired,
}

ClientNameEditModal.defaultProps = {
	visible: false,
	enableDelete: true,
	For: '',
	data: { client_id: '', client_name: '' }
}

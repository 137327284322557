import React, { Component } from 'react';
import './TrendingSearches.scss';
import { Button, Spinner } from 'reactstrap';
import { Divider } from 'antd';
import { connect } from 'react-redux';

const hardcoded_queries = [
  { frequency: 10, query: "Save for Retirement" },
  { frequency: 9, query: "How to Invest in Nanotechnology" },
  { frequency: 8, query: "How to Invest in Climate Change" },
  { frequency: 7, query: "Best Active Fintech ETFs" },
  { frequency: 6, query: "How to Search for Sustainability Funds" },
  { frequency: 5, query: "Funds with Exposure to Transformation" },
  { frequency: 4, query: "Mid Cap Funds with Low Volatility" },
  { frequency: 3, query: "Capital Appreciation" },
  { frequency: 2, query: "Best Quantitative Investment Strategies" },
  { frequency: 1, query: "Hot Robotic Funds" },
];
class TrendingSearches extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { trendingSearch, queryHandler } = this.props;
    let trendingSearches = trendingSearch ? JSON.parse(JSON.stringify(trendingSearch)) : {};
    if (hardcoded_queries && hardcoded_queries.length > 0) {
      trendingSearches.isLoading = false;
      trendingSearches.searches = hardcoded_queries;
    }
    let sr = 0
    return (
      <div className="trending-searches">
        <div className="inner-container">
        {this.props.searchCard ? <div className="w-100 text-center mb-3"><span style={{ color: 'black', fontSize: 12, fontWeight: 100 }}>DON'T KNOW WHERE TO START?</span></div> : null}
          {this.props.searchCard ? <h1><img style={{ height: 24, width: 34, marginRight: 10 }} src="/assets/images/Trending-up.svg" /> Trending Searches</h1> : <h1>Popular Searches</h1>}
          {trendingSearches.isLoading
            ? (
              <Spinner />
            ) : this.props.searchCard ? (<div className="w-100 text-center">
              {!trendingSearches.isLoading && trendingSearches.searches.map((val, i) =>
                <a
                  href="javascript:void(0)"
                  className="search_card_trending"
                  onClick={() => queryHandler(val.query,'trending_searches')}
                  key={`odd${val.query}`}
                >
                  {val.query}
                </a>
              )}
            </div>) : (
              <div className="row">
                <div className="col-lg-6">
                  <ul>
                    {!trendingSearches.isLoading && trendingSearches.searches.map((val, i) =>
                      <>
                        {(i + 1) % 2 !== 0 ? <li
                          onClick={() => queryHandler(val.query)}
                          key={`odd${val.query}`}
                        >
                          {val.query}
                        </li> : null
                        }
                      </>
                    )}
                  </ul>
                </div>
                {window.innerWidth > 968 && <Divider className="seprator" type={"vertical"} />}
                <div className="col-lg-6">
                  <ul>
                    {!trendingSearches.isLoading && trendingSearches.searches.map((val, i) =>
                      <>
                        {(i + 1) % 2 === 0 ? <li
                          onClick={() => queryHandler(val.query)}
                          key={`odd${val.query}`}
                        >
                          {val.query}
                        </li> : null
                        }
                      </>
                    )}
                  </ul>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ trendingSearches }) => ({
  trendingSearches
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrendingSearches)

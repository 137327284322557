import React, { createElement } from 'react';
import classNames from 'classnames';
import { Button } from 'antd';
import Uc from './Uc';
import config from './config';
import styles from './index.less';

export default ({ className, linkElement = 'a', type, code, title, desc, img, actions, ...rest }) => {
  const pageType = type in config ? type : '404';
  const clsString = classNames(styles.errorStateContainer, className);
  let renderDesign = '';
  if (type !== 'uc') {
    renderDesign = (
      <div className={clsString} {...rest}>
        <div className={styles.codeWrapper}>
          <h3>{code || config[pageType].code}</h3>
        </div>
        <div className={styles.contentWrapper}>
          <h5>{title || config[pageType].title}</h5>
          <div className={styles.description}>{desc || config[pageType].desc}</div>
          <div className={styles.actionsWrapper}>
            {
              actions
                || createElement(linkElement, {
                  to: '/',
                  href: '/',
                }, <Button type="primary">Return home</Button>)
            }
          </div>
        </div>
      </div>
    );
  } else {
    renderDesign = (
      <Uc
        className={className}
        linkElement={linkElement}
        type={type}
        code={code}
        title={title}
        desc={desc}
        img={img}
        actions={actions}
        {...rest}
      />
    );
  }
  return renderDesign;
};

import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ResponsiveContainer, ReferenceLine } from 'recharts';

export const CustomBarChart = ({ data }) => {
  console.log(data)
  if (typeof data === 'undefined' || typeof data.length === 'undefined' || data.length === 0) return null;

  const domain = [ dataMin => Math.min(dataMin, 0), dataMax => Math.max(dataMax, 0) ];

  return (
    <ResponsiveContainer width="100%" height="100%" >
      <BarChart data={data} margin={{top: 30, right: 0, left: 0, bottom: 30}}>
        <CartesianGrid horizontal={true} vertical={false} stroke="#dbdbdb" shapeRendering="crispEdges" />
        <XAxis
          dataKey="name"
          tickLine={false}
          y={10}
          axisLine={false}
          tick={
           <CustomXAxisTick
             labelFormatter={labelPc}
           />
          }
        />
        <YAxis 
          hide={false}
          // domain={[-25,10]}
          // ticks={[-25, -20, -15, -10, -5, 0, 5, 10]}
          width={40}
          interval={0}
          tickSize={0}
          tickMargin={10}
          shapeRendering="crispEdges"
          stroke="#dbdbdb"
        />
        <Tooltip content={<BarCustomTooltip />} cursor={false} isAnimationActive={false} />
        <ReferenceLine y={0} stroke="#dbdbdb" shapeRendering="crispEdges"/>
        <Bar dataKey="portfolio" unit="%"  maxBarSize={60} isAnimationActive={false} >
          <LabelList dataKey="portfolio" position="top" className="text" formatter={labelPc} content={renderCustomizedLabel} />
          { data.map((v, i) => <Cell key={`cell-${i}`} fill="#56a9e8"/>) }
        </Bar>

        {(data && data.length && typeof data[0].benchmark !== 'undefined')
          && (
            <Bar dataKey="benchmark" unit="%" maxBarSize={60} isAnimationActive={false} >
              <LabelList dataKey="benchmark" position="top" className="text" formatter={labelPc} content={renderCustomizedLabel} />
              { data.map((v, i) => <Cell key={`cell-${i}`} fill="#1e5f91"/>) }
            </Bar>
          )
        }
        
      </BarChart>
    </ResponsiveContainer>
  );
}

const labelPc = label => label.toFixed(2)+'%';

const CustomXAxisTick = (props) => {
  const {
    x, y, width, height, fill, payload,
  } = props;

  if (payload) {
    const { coordinate, value, offset, tickCoord, isShow } = payload;
    return (
      <text x={x} y={y+20} fill={fill} textAnchor="middle" dominantBaseline="middle">
        {value}
      </text>
    );
  }
}

const renderCustomizedLabel = (props) => {
  const {
    x, y, width, height, value, fill,
  } = props;

  let yForText = y - 10;

  if (value !== undefined && typeof value !== 'undefined') {
    if (value < 0) {
      yForText = y + 10;
    }
    return (
      <g>
        <text x={x + width / 2} y={yForText} fill={fill} textAnchor="middle" dominantBaseline="middle">
          {value!==null?value+'%':'NA'}
        </text>
      </g>
    );
  }
};

const BarCustomTooltip = (props) => {
  const { active, payload, label, vkey } = props;
  if (active && payload) {
    return (
      <div className="recharts-default-tooltip ssf-tooltip-custom">
        <div className="label">{label}</div>
        { payload.map((e, index) => (
          <div key={e.dataKey} className="d-flex" style={{color: e.payload[`${e.dataKey+'Color'}`]}}>
            <span className="mr-2">{e.dataKey}</span>
            <span className="ml-auto">{e.payload[e.dataKey]}</span>
            <span className="">{e.unit}</span>
          </div>
        )) }
      </div>
    );
  }

  return null;
}

export default {
  CustomBarChart,
}

import React, { Component, Fragment } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Icon, Radio } from 'antd';
import Slider from "react-slick";
import {setBuildPortfolioState} from 'actions/buildportfolio';

import { createLoadingSelector } from 'selectors/loading-selector';

import InputSearch from '../../../Elements/InputSearch';

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 10,
  slidesToScroll: 10,
  initialSlide: 0,
  nextArrow: null,
  prevArrow: null,
  responsive: [
    {
      breakpoint: 1900,
      settings: {
          slidesToShow: 10,
        slidesToScroll: 10
      }
    },
    {
      breakpoint: 1770,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 8
      }
    },
    {
      breakpoint: 1439,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 7
      }
    },
    {
      breakpoint: 1139,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6
      }
    }
  ]
};

class RadioButtonList extends Component {
  state = {
    value: '',
    dataList: [],
    topThemeList: ['Cloud Computing', 'Virtual Reality', 'Agriculture', 'Wearable Tech', 'Organic', 'Climate Change', 'Cancer Treatment', 'Vegan', 'Cybersecurity', 'Bitcoin'],
    selectedThemes: '',
  }

  componentDidMount() {
    const { dataList, selectedThemes } = this.props;
    this.setState({
      dataList,
      selectedThemes: selectedThemes.length > 0 ? selectedThemes[0] : '',
    });
  }

  componentWillReceiveProps(newProps) {
    const { selectedThemes } = newProps;
    if (this.state.selectedThemes !== selectedThemes && selectedThemes.length > 0) {
      this.setState({ selectedThemes: selectedThemes[0] });
    }
  }

  radioChange = (event) => {
    const selectedThemes = event.target.value;
    this.setState({
      selectedThemes,
    }, () => {
      const { getSelectedThemes, setBuildPortfolioState, buildportfolio } = this.props;
      if (getSelectedThemes) {
        setBuildPortfolioState({
          thematicTab: {
            ...buildportfolio.thematicTab,
            newQuery: selectedThemes,
          }
        });
        getSelectedThemes(_.uniqBy([this.state.selectedThemes]));
      }
    });
  }

  render() {
    const { dataList, selectedThemes, topThemeList } = this.state;

    return (
      <React.Fragment>
        <div className="TRFD-button-list-container">
          <div className="label">Themes to Consider</div>
          <Radio.Group
            style={{ width: '100%', display: 'inherit' }}
            onChange={this.radioChange}
            value={selectedThemes}
          >
            {(dataList.length === 0) ? (
              <div className="no-result-found-wapper">
                <p className="no-result-found-message" style={{ padding: '0 15px' }}>
                  This theme doesn’t seem to be in our portfolio right now.
                  <br />
                  Have a look at other closely relevant theme that might interest you.
                </p>
                <Slider {...settings}>
                  {
                    topThemeList.map((u, ui) => {
                      let img = ''
                      let notfoundList = [];
                      try {
                        const images = require.context('../../../../../../../public/themes', true);
                        if (images('./' + u + '.png')) {
                          img = images('./' + u + '.png');
                        } else {
                          throw u;
                        };
                      } catch (err) {
                        return
                      }
                      return (
                        <Radio className="theme-item-radio" key={u} value={u}>
                          <div className="themes-item-block">
                            <div className="img-holder">
                              <img src={img} />
                            </div>
                            <label>{u}</label>
                          </div>
                        </Radio>
                      )}
                    )
                  }
                </Slider>
              </div>
            ): (
              <Slider {...settings}>
                {dataList.map((u, ui) => {
                  let img = ''
                  try {
                    const images = require.context('../../../../../../../public/themes', true);
                    if (images('./' + u + '.png')) {
                      img = images('./' + u + '.png');
                    } else throw '';
                  } catch (err) {
                    return
                  }
                  return (
                    <Radio className="theme-item-radio" key={u} value={u}>
                      <div className="themes-item-block">
                        <div className="img-holder">
                          <img src={img} />
                        </div>
                        <label>{u}</label>
                      </div>
                    </Radio>
                  )})
                }
              </Slider>
            )}
          </Radio.Group>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ buildportfolio, loading }) => ({
  buildportfolio
  // equityTabLoading: createLoadingSelector(['PORTFOLIO_BATCH'])({ loading }),
})

const mapDispatchToProps = {
  // thematicBatch,
  setBuildPortfolioState
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RadioButtonList);

import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import qs from 'query-string'
import { Spinner } from 'shared-components/'
import UserPlanType from './UserPlanType'
import ProfilePricingPlans from './ProfilePricingPlans'
import { createLoadingSelector } from 'selectors/loading-selector'
import { fetchProfile } from 'actions/profile'

class Subscription extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPlanDetails: true
    }
  }

  componentDidMount() {
    !this.props.profile.user && this.props.fetchProfile()
  }

  render() {
    const query = qs.parse(this.props.location.search)
    const {loading} = this.props
    if(query.paymentStatus){window.sessionStorage.setItem('paymentStatus', query.paymentStatus)}
    return (
      <React.Fragment>
        {(query.paymentStatus) && (
          <Redirect to='/settings/subscriptionPlan' />
        )}
        { loading && <Spinner isFetching={loading}  /> }
        { !loading && this.props.profile.user && <div>
          <UserPlanType user={this.props.profile.user} changePlan={this.changePlan} userDetails={this.props.userDetails}/>
          <ProfilePricingPlans />
        </div>}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ auth,loading,profile }) => ({
  userDetails: auth.user,
  profile,
  loading: createLoadingSelector([
    'PROFILE'
  ])({ loading }),
})

const mapDispatchToProps = {
  fetchProfile
}

export default connect(
  mapStateToProps,mapDispatchToProps
)(Subscription);

// absolute imports
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col, ButtonGroup } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { Paginate as NativePaginate } from "react-data-table-component";
import { mockData } from './mockData';
import { ShowSpinner } from '../Shared/ShowSpinner';
import { NoProposalsComponent } from '../Shared/NoProposalsComponent'
import style from './_index.scss'
import ColumnSearchFilter from '../Shared/ColumnSearchFilter';
import { Table, Tag, Space } from 'antd';
import * as ProfileApi from 'apis/profile';
import { Loader } from '../Loader';
import * as moment from 'moment'

const FileSaver = require('file-saver')

const { Column, ColumnGroup } = Table;


class ArchivedTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterText: '',
      selectedColumn: '',
      selectedClientDet: '',
      clientTableWidth: '',
      tableHeight:'',
      showLoader: false
    };

  }

  componentDidUpdate(prevProps, prevState) {

    if(prevProps.portfolioListLoading != this.props.portfolioListLoading){
      this.newScreenSize();
    }

  }

  componentDidMount () {
    window.addEventListener("resize", this.newScreenSize.bind(this));
    this.newScreenSize();
  }

  newScreenSize = () => {
    const element1 = document.querySelector('.pt-wrapper');
    const element2 = document.querySelector('.pt-wrapper .ant-table-header')
    const element3 = document.querySelector('.pt-wrapper .ant-table-body');

    const { proposalsList } = this.props;
    // console.log(element1, element2);
    // console.log(element1, element2, element1.clientHeight, element2 ? element2.clientHeight : 'element2 null');

    if(element1 && element1 !== null){
      const tableHeight = (element1 && element2 && element2 !== null && (proposalsList && proposalsList.length > 0) ) ? (element1.clientHeight - element2.clientHeight) : element1.clientHeight;

      const hasScrollBar = (element3 && tableHeight) && tableHeight < element3.scrollHeight
      // const hasScrollBar = (element3) && element3.scrollHeight > element3.clientHeight;
      if (hasScrollBar){
        if(element2) element2.style.marginRight = '15px';
      }else{
        if(element2) element2.style.marginRight = '0px';
      }
      this.setState({
        clientTableWidth: window.innerWidth,
        tableHeight
      })
    }

  }

  downloadProposals = async(row) => {
    this.setState({showLoader: true});

    try {
      let download    = await ProfileApi.downloadPortfolioPDF({ 'id': row.id })
      const newBlob   = new Blob([download.data], { type: download.headers['content-type'] })
      const extension = download.headers['content-type'].split('/')[1];
      let fileNameArr = row.file.split('/');
      let filename    = fileNameArr[fileNameArr.length -1]
      let name        = filename.split('.')[0]

      var reader = new FileReader();
      FileSaver.saveAs(newBlob, `${name}.${extension}`)
      this.setState({showLoader: false});
    } catch (e) {
      this.setState({showLoader: false});
      console.log(e)
    }
  }


  render() {

    const { proposalsList, portfolioListLoading, searchedQuery, proposalsfilteredColumn } = this.props;
    const {  clientTableWidth, tableHeight, disableTradeButton } = this.state;

    let query_var             = [];
    const searchField         = query_var && query_var.length ? query_var[0].variable : null;

    let finalData             = [];
    const singleSectionWidth  = clientTableWidth / 2;
    const allColumnWidth      = 1160;
    const tableWidth          = clientTableWidth > allColumnWidth ? '100%' : allColumnWidth;

    let noDataMessage;

    if ((searchedQuery && searchedQuery !== '') || proposalsfilteredColumn !== '') {
      noDataMessage = `No proposals found. Please try another query.`;
    } else {
      noDataMessage = `No proposals found.`;
    }

    if (searchedQuery && searchedQuery !== '') {
      let tempData = [];

      (this.props.proposalsList && this.props.proposalsList.length > 0) && this.props.proposalsList.filter(eachData => {
        let x = Object.keys(eachData.attributes).filter(function(key) {
          if (eachData.attributes[key].toLowerCase().includes(searchedQuery.toLowerCase())) {
            return true
          } else {
            return false
          }
        })
       return (
         eachData.report_name.toLowerCase().includes(searchedQuery.toLowerCase()) || eachData.section.toLowerCase().includes(searchedQuery.toLowerCase()) ||
         (eachData.entity && eachData.entity.toLowerCase().includes(searchedQuery.toLowerCase())) ||
         eachData.report_type.toLowerCase().includes(searchedQuery.toLowerCase()) ||
         (
           eachData.first_name.toLowerCase().includes(searchedQuery.toLowerCase()) || eachData.last_name.toLowerCase().includes(searchedQuery.toLowerCase())
         ) ||
         x.length
       )}).map((obj,idx) => {
          tempData.push(obj);
      })

      finalData = tempData
    } else {
      finalData = proposalsList ? proposalsList : []
    }

    console.log(finalData)
    return(
      <div className="portfolio-tbl-wrapper" style={{marginTop: '5px'}}>
        {this.state.showLoader && <Loader loading />}


        <div className={`ant-custom-tbl-content pt-wrapper `}>

        {portfolioListLoading ? <EmptyTable finalData={[]} tableWidth={tableWidth} tableHeight={'calc(100vh - 31vh)'} portfolioListLoading={portfolioListLoading} /> :
        <Table className={process.env.REACT_APP_SUB_DOMAIN !== 'retail' ? "ArchiveTable advisor" : "ArchiveTable"}
          dataSource={finalData}
          scroll={{ x: finalData.length > 0 ? tableWidth : '100%', y: 'calc(100vh - 31vh)' }}
          pagination={false}
          rowKey={"id"}
          locale={{
            emptyText: !portfolioListLoading ? <NoProposalsComponent message={noDataMessage} /> : 'Loading...'
          }}
          loading={{
            spinning: portfolioListLoading,
            indicator: <ShowSpinner />
          }}
        >
          <ColumnGroup>
            <Column
              title={<ColumnSearchFilter
                title="Name"
                filterName="Name"
                selector="report_name"
              />}
              dataIndex="report_name"
              className="acc-details-header no-right-border"
              key="report_name"
              width="190px"
            />

            <Column
              title={<ColumnSearchFilter
                title="Entity"
                filterName="Entity"
                selector="entity"
              />}
              dataIndex="entity"
              key="entity"
              className="acc-details-header no-right-border"
              width="100px"
            />

            <Column
              title={<ColumnSearchFilter
                title="Section"
                filterName="Section"
                selector="section"
              />}
              dataIndex="section"
              key="section"
              className="acc-details-header no-right-border"
              width="80px"
            />

            <Column
              title={<ColumnSearchFilter
                title="Proposal Type"
                filterName="Proposal Type"
                selector="report_type"
              />}
              dataIndex="report_type"
              key="report_type"
              className="acc-details-header no-right-border"
              width="130px"
            />

            <Column
              title={<ColumnSearchFilter
                title="Date & Time"
                filterName="Date & Time"
                selector="created_at"
              />}
              render={row => {
                return(
                  <>{moment(row.created_at).format('DD MMM, YYYY | hh:mm:ss A')}</>
                )
              }}
              className="acc-details-header no-right-border"
              width="160px"
            />

            {process.env.REACT_APP_SUB_DOMAIN !== 'retail' && <ColumnGroup
              rowSpan="2"
              title={<ColumnSearchFilter
                title="Advisor"
                filterName="Advisor"
                selector="advisor"
              />}
              className="acc-details-header th-grouped no-right-border"
              >
              <Column
                key="first_name"
                width="130px"
                render={row => {
                  return(<Name data={row}/>)
                }}
              />

            </ColumnGroup>}

            <Column
              title="Attributes"
              dataIndex="attributes"
              key="attributes"
              className="acc-details-header no-right-border"
              width="190px"
              render={row => {
                return(<Attributes data={row}/>)
              }}
            />

            <Column
              title="Download"
              key="download"
              align="center"
              className="acc-details-header no-right-border"
              width="100px"
              render={row => {
                return(
                  <img onClick={() => this.downloadProposals(row)} className="downloadPDF" src="/assets/images/DownloadPDF.svg"/>
                )
              }}
            />

            </ColumnGroup>
        </Table>
         }
        </div>
      </div>
  )

  }
}

const mapStateToProps = ({ profile: {proposalsList, portfolioListLoading, proposalsfilteredColumn} }) => ({
  proposalsList,
  portfolioListLoading,
  proposalsfilteredColumn
})

const mapDispatchToProps = {

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArchivedTable);


const FilterItems = (props) => {
  return(
    <div className="tbl-hdr-search">
      <p className={props.align == 'left' ? 'jus-start' : 'jus-center'}>{props.name}</p>
      <hr />
      <SearchCol {...props} />
    </div>
  )
}

class SearchCol extends React.Component {
  constructor(props) {
    super(props);
    this[this.props.selector] = React.createRef();
  }
  componentDidMount(){
    this[this.props.selectedColumn] &&  this[this.props.selectedColumn].current.focus();
  }
  render() {
    const {selectedColumn, selector, filterText, onFilter} = this.props;
    const searchText = selectedColumn == selector ? filterText : '';
    return (
      <div className="sch-wrapper">
        <i className="far fa-fw fa-filter" />
        <input className="search" name={selector} type="text" placeHolder="Search" value={searchText} onChange={onFilter} ref={this[selector]}/>
        <i className="far fa-fw fa-search" />
      </div>
    );
  }
}

// Temporary solution, delete it after fixing the table header issue
const EmptyTable = ({finalData, tableWidth, tableHeight, portfolioListLoading}) => {
  return(<Table
          dataSource={finalData}
          scroll={{ x: tableWidth, y: tableHeight }}

          pagination={false}
          rowKey={"id"}
          locale={{
            emptyText: 'Loading...'
          }}
          loading={{
            spinning: portfolioListLoading,
            indicator: <ShowSpinner />
          }}
          className={process.env.REACT_APP_SUB_DOMAIN !== 'retail' ? "ArchiveTable advisor" : "ArchiveTable"}
        >

          <ColumnGroup>

            <Column
              title={<ColumnSearchFilter
                title="Name"
                filterName="Name"
                selector="report_name"
              />}

              className="acc-details-header no-right-border"

              width="200px"
            />

            <Column
              title={<ColumnSearchFilter
                title="Entity"
                filterName="Entity"
                selector="entity"
              />}


              className="acc-details-header no-right-border"
              width="100px"
            />

            <Column
              title={<ColumnSearchFilter
                title="Section"
                filterName="Section"
                selector="section"
              />}

              className="acc-details-header no-right-border"
              width="80px"
            />

            <Column
              title={<ColumnSearchFilter
                title="Proposal Type"
                filterName="Proposal Type"
                selector="report_type"
              />}

              className="acc-details-header no-right-border"
              width="120px"
            />

            <Column
              title={<ColumnSearchFilter
                title="Date & Time"
                filterName="Date & Time"
                selector="created_at"
              />}

              className="acc-details-header no-right-border"
              width="160px"
            />

            {process.env.REACT_APP_SUB_DOMAIN !== 'retail' && <ColumnGroup
            rowSpan="2"
              title={<ColumnSearchFilter
                title="Advisor"
                filterName="Advisor"
                selector="advisor"
              />}
              className="acc-details-header th-grouped no-right-border"
              >
              <Column
                width="130px"
              />
            </ColumnGroup>}

            <Column
              title="Attributes"

              className="acc-details-header no-right-border"
              width="190px"

            />

            <Column
              title="Download"

              align="center"
              className="acc-details-header no-right-border"
              width="70px"

            />
          </ColumnGroup>
        </Table>)
}

const Attributes = ({data})=>{
  if(!data) return null

  return <div className={'attributes'}>
    { Object.entries(data).map(([key, value]) =>
      <p title={`${key} : ${value}`} className={'key'}>
        <b>{key}</b> : {value}
      </p>
    )}
  </div>
}


const Name = ({data})=>{
  const {first_name,last_name} = data
  return <div className={'attributes'}>
    <p title={`${first_name} ${last_name}`} className={'key'}>
      {`${first_name} ${last_name}`}
    </p>
  </div>
}
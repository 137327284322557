import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Space, Popconfirm, Form } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { FormatCurrency } from 'Utils';

class unsupportedTickerTable extends React.Component {
	constructor(props) {
		super(props)
	}

	componentDidMount(){
		const { dataSource } = this.props;
		this[dataSource[0].key] &&  this[dataSource[0].key].current.focus();
	}

	render() {
		const { showAllInvalidTickers, showAllInvalidTickersToggle, dataSource, updateTickerStatus, validTickers } = this.props;
		const unsupportedTickerData = showAllInvalidTickers || !validTickers.length ? dataSource : dataSource.slice(0,2);

		const invalidTickerColumn = [
			{
				title: 'Ticker',
				dataIndex: 'ticker',
				render: (text, record) => <div>{text}</div>,
				width: '80px'
			},
			{
				title: 'Weight(%)',
				dataIndex: 'weight(%)',
				render: (text, record) => <div>{text !== 'NA' ? parseFloat(Number(text).toFixed(2))+'%' : 'NA'}</div>,
				width: '117px'
			},
			{
				title: 'Values',
				dataIndex: 'values',
				render: (text, record) => <div>{FormatCurrency(text, 0, '$', 'unf')}</div>,
			},
			{
				title: 'Current Price',
				dataIndex: 'price',
				render: (text, record) => <div>{'$'+parseFloat(Number(text).toFixed(2))}</div>,
			},
			{
				title: 'Delete*',
				render: (text, record) => {
					return(
						<i className="far fa-trash-alt" onClick={() => this.props.deleteInvalidTickers(record)}></i>
					)
				},
				width: '15%'
			}
		]

		return (
			<React.Fragment>
				<div id="csv-preview-upload-container" className="csv-preview-upload-container">
					<div className="head-note">
						<p>Unsupported {(dataSource && dataSource.length > 1) ? 'securities' : 'security'}</p>
					</div>
					<Table
						size="small"
						columns={invalidTickerColumn}
						dataSource={unsupportedTickerData}
						pagination={false}
						className={showAllInvalidTickers && 'show-all-invalid-tickers'}
					/>
					<div className="foot-note">
						<p>Above positions are excluded from the analysis.</p>
						{!showAllInvalidTickers && this.props.dataSource.length > 2 && validTickers.length > 0 && <span onClick={() => showAllInvalidTickersToggle(true)}>More</span>}
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default unsupportedTickerTable;

class EditableCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    	value: this.props.text
    }
    this[this.props.record.key] = React.createRef();
  }
  componentDidMount(){
  	const { record, firstKey } = this.props;
  	if(this[record.key] && record.key === firstKey){
			this[record.key].current.focus()
  	}
  }
  allowAlphabetsOnly = (val) => {
  	var letters = /^[A-Za-z]+$/;
		return val.match(letters);
  }
  handleChange = (e) => {
  	if(!e.target.value.length || this.allowAlphabetsOnly(e.target.value)){
	  	this.setState({
	  		value: e.target.value.toUpperCase()
	  	})
	  }
  }
  render() {
    const {text, record, updateTickerStatus} = this.props;
    return (
      <div className="edit-ticker">
				<input
					type="text"
					value={this.state.value}
					onChange={this.handleChange}
					//disabled={record.isDisabled}
					spellCheck={false}
					onBlur={() =>
						updateTickerStatus({
							updatedTicker: this.state.value,
							selectedRecord: record
						})
					}
					ref={this[record.key]}
				/>
			</div>
    );
  }
}

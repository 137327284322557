import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Space, Popconfirm, Form, Row, Col, Tooltip, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';

import { Allocation, colWidthObj, FormatCurrency } from './helper';
import SearchDropdown from './SearchDropdown';

import { numberCheck } from 'layouts/utils';

const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

let PAGE_COUNT = 1;

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  style,
  record,
  disabled,
  editable,
  children,
  dataIndex,
  searchBox,
  isEditing,
  handleSave,
	errorHandle,
  checkTicker,
  magnifiTickers,
  getRealTimePrice,
  allocationTypeValue,
  realTimePriceLoading,
  searchDropdownHeight,
  getTickerListRequest,
  searchedCustomTicker,
  fetchingAllTickersList,
  searchDropdownTargetElement,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);

  if (editable) {
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  }

  const toggleEdit = () => {
    setEditing(!editing);
    isEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const searchedTicker = async (e, selectedData) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      if (e && e.ticker && e.name) {
        // console.log({ ...record, ticker: e.ticker, name: e.name });
        console.log(selectedData);
        if (handleSave) {
          handleSave({
            ...record,
            ticker: e.ticker,
            name: e.name,
            oldTicker: record.ticker,
            type: e && e.type ? e.type : '',
            price: selectedData && selectedData.price && !isNaN(Number(selectedData.price)) ? Number(selectedData.price) : record.price,
          }, selectedData);
        }
        if (errorHandle) {
          errorHandle({ fields: [] });
        }
      }
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
			errorHandle({ fields: errInfo.errorFields });
    }
  }

  const save = async e => {
    try {
      console.log(e);
      const values = await form.validateFields();
      toggleEdit();

      if (values[dataIndex] && !isNaN(Number(values[dataIndex]))) {
        values[dataIndex] = Number(values[dataIndex]);
      }

      if (dataIndex.includes('weight') &&
        !isNaN(Number(values[dataIndex]))
      ) {
        values[`weight(%)`] = Number(values[dataIndex]);
        values[`weight`] = Number(values[dataIndex]);
        values[`original_weight`] = Number(values[dataIndex]);
        // values.weight = parseFloat(values[dataIndex]);
        // values.original_weight = parseFloat(values[dataIndex]);
      }

      // if (allocationTypeValue === 'weight') {
      //   values[`values`] = 0; // numberCheck(props.values);
      // }
      //
      // if (allocationTypeValue === 'value') {
      //   values[`weight(%)`] = 0;
      //   values[`weight`] = 0;
      //   values[`original_weight`] = 0;
      // }
      //
      // values[`shares`] = 0; // always reset share when editing 'Weight' or 'Values'

      // [dataIndex]: record[dataIndex],
      if (handleSave) {
        handleSave({ ...record, ...values });
      }
      if (errorHandle) {
        errorHandle({ fields: [] });
      }
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
			errorHandle({ fields: errInfo.errorFields });
    }
  };

  let childNode = children, editingClassNameForTD = '';

  if (searchBox && editable) {
    editingClassNameForTD = (editing || editable) ? 'editable-enable-overflow' : '';
    childNode = (
      <Form.Item
        className="editable-box-container custom-ticker-search-box-wrapper"
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
        validateStatus={(record.hasError && dataIndex === 'ticker') ? "error" : ""}
        help={(record.errorMsg && dataIndex === 'ticker') ? record.errorMsg : ""}
      >
        <SearchDropdown
          placeHolder="Find Security/Ticker"
          showSearchIcon={false}
          magnifiTickers={magnifiTickers}
          loading={fetchingAllTickersList}
          getRealTimePrice={getRealTimePrice}
          searchedCustomTicker={searchedTicker}
          realTimePriceLoading={realTimePriceLoading}
          getTickerListRequest={getTickerListRequest}
          searchDropdownHeight={searchDropdownHeight}
          searchDropdownTargetElement={searchDropdownTargetElement}
        />
      </Form.Item>
    )
  } else {
    // console.log('editing || editable ==>', editing, editable, dataIndex);
    if (editable || (record && typeof record !== 'undefined' && typeof record.hasError !== 'undefined' && record.hasError)) {
      childNode = (editing || editable) ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
          validateStatus={(record.hasError && dataIndex === 'ticker') ? "error" : ""}
          help={(record.errorMsg && dataIndex === 'ticker') ? record.errorMsg : ""}
        >
          <Input ref={inputRef} disabled={disabled} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className={`editable-cell-value-wrap ${(record && typeof record !== 'undefined' && typeof record.hasError !== 'undefined' && record.hasError) ? 'col-has-error' : ''}`}
          // onClick={toggleEdit}
        >
          {children}
          {(record && typeof record !== 'undefined' && typeof record.hasError !== 'undefined' && record.hasError && dataIndex === 'ticker') && (
            <span className="error-message">{record.errorMsg}</span>
          )}
        </div>
      );
    } else {
      // for not-editable childNode
      if (dataIndex && dataIndex.includes('weight') && dataIndex === 'weight(%)') {
        childNode = (<div className={`${disabled && 'disabled'}`}>{numberCheck(record['weight(%)'], 2)+'%'}</div>)
      }
      if (dataIndex === 'values' && typeof record['values'] !== 'undefined') {
        childNode = (<div className={`${disabled && 'disabled'}`}>{numberCheck(record['values']) ? FormatCurrency(record['values'], 0, '$', 'unf') : '--'}</div>)
      }
    }
  }

  const dataIndexClassName = typeof dataIndex !== 'undefined' ? (dataIndex.includes('weight') ? 'weight' : dataIndex) : '';

  return (
    <td {...restProps} className={`${restProps.className} ${dataIndexClassName} ${editingClassNameForTD}`}>
      {childNode}
    </td>
  );
};

class EmptyDataList extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			searchText: '',
			searchedColumn: '',
			sortedInfo: {
				order: 'descend',
				columnKey: 'price',
		 	},
			pagination: typeof props.pagination !== 'undefined' ? props.pagination : {
        position: ['topRight'],
				current: 1,
				pageSize: this.props.invalidTickers.length > 0 ? 7 : 10,
			},
			dataSource: props.data || [],
			count: 2,
      tickerNotExist: props.tickerNotExist || [],
      editingKey: '',
      isEditing: false,
      addTickerEnabled: true,
      newTicker: null,
      columns: [],
      searchDropdownValue: { value: '', label: [], key: '' },
      editingCurrentRecord: null,
		};
    this.columns = [];
    this.addNewTickerForm = React.createRef();
	}

	componentDidMount() {
		const { rowsArr, tickerNotExist, showAllInvalidTickers, validTickers, tableFor } = this.props;
    let { data } = this.props;
		if (data) {
			data.map((item, key) => {
				item.key = key.toString();
			});
			this.setState({
				dataSource: data,
			});
		}
    if (rowsArr && rowsArr.length) {
      let arr = [];
      rowsArr.map((item) => {
        arr.push({
          key: item,
          ellipsis: true,
          dataIndex: item,
          title: this.capitalize(item),
          editable: true,
          disabled: (item === 'values'),
          searchBox: (item === 'ticker'),
        });
      });
      arr = [
        {
          title: <div style={{ position: 'relative' }}></div>,
          dataIndex: 'edit',
        },
        ...arr,
        {
          title: <div style={{ position: 'relative' }}>Delete <i className="fas fa-asterisk" style={{ position: 'absolute', right: 4, fontSize: 8 }}></i></div>,
          dataIndex: 'operation',
        },
      ];
      this.columns = arr.map((kl) => {
        kl.width = this.getWidth(kl.dataIndex) || 'auto';
        return kl;
      });
    }
	}

	componentWillReceiveProps(newProps) {
		if (newProps.data && newProps.data !== this.state.dataSource) {
			newProps.data.map((item, key) => {
				item.key = key.toString();
			});
			this.setState({
				dataSource: newProps.data,
			});
      const { pagination } = this.state;
      const { current } = pagination;
      let totalRow = newProps.data.length;
      // console.log('showPaginationTotal ---> ', totalRow);
      if (pagination && typeof pagination.pageSize !== 'undefined' && (totalRow > pagination.pageSize)) {
        // step 1: get all page count
        PAGE_COUNT = (totalRow / pagination.pageSize);
        if (PAGE_COUNT !== pagination.current) {
          PAGE_COUNT = parseInt(PAGE_COUNT);
          PAGE_COUNT++;
        }
      }
      // console.log('---- componentWillReceiveProps ----', PAGE_COUNT, current, current !== PAGE_COUNT);
      if (current !== PAGE_COUNT) {
        this.setState({
          pagination : {
            ...this.state.pagination,
            current: PAGE_COUNT,
          }
        })
      }
		}
    if(!newProps.invalidTickers.length && newProps.invalidTickers.length !== this.props.invalidTickers.length){
    	const { current } = this.state.pagination;
    	this.setState({
    		pagination : {
    			current,
    			pageSize: 10
    		}
    	})
    }
	}

  capitalize = (str) => {
    if (str.length) {
      return str[0].toUpperCase() + str.slice(1);
    }
    return str;
  };

  getWidth = (col) => {
    return colWidthObj[col];
  }

	handleDelete = (record) => {
    const { showAllInvalidTickers, validTickers, tableFor } = this.props;
		let dataSource = [...this.state.dataSource];
    dataSource = dataSource.filter(item => item.key !== record.key);
		this.setState({
			dataSource,
		}, () => {
      const { getUpdatedData, deleteInvalidTickers, tableFor, allocationTypeValue } = this.props;
      if (tableFor === 'Invalid') {
        if (deleteInvalidTickers) {
          deleteInvalidTickers(record);
        }
      } else {
        if (getUpdatedData) {
          // 'OVER_RIDE_DATA = true' will re-calculate weight
          let OVER_RIDE_DATA = false;
          if (allocationTypeValue === 'weight') {
            OVER_RIDE_DATA = false;
          } else if (allocationTypeValue === 'value') {
            OVER_RIDE_DATA = true;
          }
          getUpdatedData(this.state.dataSource, OVER_RIDE_DATA, allocationTypeValue);
        }
      }
		});
 	};

  searchedTicker = (e, selectedData) => {
    // console.log(e);
    // console.log(selectedData);
    try {
      if (e && e.ticker && e.name) {
        let initialRowObject = {};
        if (this.state.dataSource && this.state.dataSource.length > 0) {
          if (Object.keys(this.state.dataSource[0])) {
            Object.keys(this.state.dataSource[0]).map((l) => {
              initialRowObject[`${l}`] = typeof this.state.dataSource[0][l] === 'string' ? '' : 0;
            });
          }
        }
        this.setState({
          searchDropdownValue: selectedData || { value: '', label: [], key: '', price: 0 },
          newTicker: {
            ...initialRowObject,
            name: e.name,
            ticker: e.ticker,
            type: e.type || '',
            price: Number(selectedData.price), // numberCheck(selectedData.price, 2),
            realTimePrice: Number(selectedData.price), // numberCheck(selectedData.price, 2),
          }
        });
      }
    } catch (errInfo) {
      console.log('Failed to add new ticker', errInfo);
    }
  }

	addNewTicker = (props) => {
    console.log('--- addNewTicker ---->', props);
    let _dataSource = this.state.dataSource;
    const { allocationTypeValue } = this.props;
    let requiredObj = {
      amount: 0,
      format_value: 0,
      invalidType: 'supported',
      name: '',
      original_weight: 0,
      price: 0,
      realTimePrice: 0,
      shares: 0,
      validTicker: true,
      values: 0,
      weight: 0,
      price: 0,
      'weight(%)': 0,
    };

    if (typeof props[`weight_calculated_format`] !== 'undefined') { delete props[`weight_calculated_format`]; };
    if (typeof props[`message`] !== 'undefined') { delete props[`message`]; };
    if (typeof props[`showInMissingTicker`] !== 'undefined') { delete props[`showInMissingTicker`]; };

    let OVER_RIDE_DATA = false;
    if (typeof props.weight !== 'undefined' || typeof props.values !== 'undefined') {
      requiredObj[`weight(%)`] = Number(props.weight); // numberCheck(props.weight);
      requiredObj[`weight`] = Number(props.weight); // numberCheck(props.weight);
      requiredObj[`original_weight`] = Number(props.weight); // numberCheck(props.weight);

      // if (this.state.newTicker && this.state.newTicker[`type`] === 'SHARE') {
      //   requiredObj[`invalid`] = true;
      //   requiredObj[`validTicker`] = false;
      //   requiredObj[`invalidType`] = "unsupported";
      // }

      _dataSource.forEach((item, i) => {
        // if (typeof item[`weight_calculated_format`] !== 'undefined') { delete item[`weight_calculated_format`]; };
        // if (allocationTypeValue === 'weight') {
        //   item[`weight_calculated_format`] = 'weight';
        // } else if (allocationTypeValue === 'value') {
        //   item[`weight_calculated_format`] = 'amount';
        // }
        // item[`shares`] = 0;
        if (typeof item[`weight_calculated_format`] !== 'undefined') { delete item[`weight_calculated_format`]; };
        item[`shares`] = 0;
        if (typeof item[`original_weight`] !== 'undefined' && item[`original_weight`] !== item[`weight`]) {
          item[`weight`] = item[`original_weight`];
        }
      });

      if (allocationTypeValue === 'weight') {
        OVER_RIDE_DATA = false;
        requiredObj[`values`] = 0; // numberCheck(props.values);
        requiredObj[`shares`] = 0;
        requiredObj[`amount`] = 0;

        requiredObj[`weight_calculated_format`] = 'weight';
        requiredObj[`format_value`] = Number(props.weight) || 0;
      } else if (allocationTypeValue === 'value') {
        OVER_RIDE_DATA = true;
        requiredObj[`shares`] = 0;
        requiredObj[`weight(%)`] = 0;
        requiredObj[`weight`] = 0;
        requiredObj[`original_weight`] = 0;

        requiredObj[`weight_calculated_format`] = 'amount';
        requiredObj[`values`] = Number(props.values); // numberCheck(props.values);
        requiredObj[`amount`] = Number(props.values) || 0;
        requiredObj[`format_value`] = Number(props.values) || 0;
      }

      if (this.state.newTicker && this.state.newTicker !== null && Object.keys(this.state.newTicker).length > 0) {
        requiredObj = {
          ...this.state.newTicker,
          ...requiredObj,
          key: `${this.state.dataSource && this.state.dataSource.length + 1}`,
          name: (this.state.newTicker && this.state.newTicker.name) ? this.state.newTicker.name : requiredObj.name,
          price: (this.state.newTicker && typeof this.state.newTicker.price !== 'undefined') ? Number(this.state.newTicker.price) : requiredObj.price,
          realTimePrice: (this.state.newTicker && typeof this.state.newTicker.realTimePrice !== 'undefined') ? Number(this.state.newTicker.realTimePrice) : requiredObj.realTimePrice,
        };
        // console.log(requiredObj);
        const newData = [...this.state.dataSource, requiredObj];
        this.setState({
          dataSource: newData,
        }, () => {
          // console.log('---- dataSource ---->', this.state.dataSource);
          const { getUpdatedData, tableFor, allocationTypeValue } = this.props;
          if (getUpdatedData) {
            // 'OVER_RIDE_DATA = true' will re-calculate weight
            let OVER_RIDE_DATA = false;
            if (allocationTypeValue === 'weight') {
              OVER_RIDE_DATA = false;
            } else if (allocationTypeValue === 'value') {
              OVER_RIDE_DATA = true;
            }
            getUpdatedData(this.state.dataSource, tableFor, OVER_RIDE_DATA, allocationTypeValue);
          }
          this.removeThisTicker();
        });
      }
    }

	};

	handleSave = (row, searchedTicker = false) => {
    // console.log('handleSave --> ', row);
    let _dataSource = this.state.dataSource;
    const { allocationTypeValue } = this.props;

    if (searchedTicker && searchedTicker.selectedData && searchedTicker.selectedData.label && typeof searchedTicker.selectedData.label !== 'undefined') {
      console.log('---- searchedTicker.selectedData ----> ', searchedTicker.selectedData);
      this.setState({
        searchDropdownValue: searchedTicker.selectedData || { value: '', label: [], key: '', price: 0 },
      });
    }

    if (typeof row[`weight_calculated_format`] !== 'undefined') { delete row[`weight_calculated_format`]; };
    if (typeof row[`message`] !== 'undefined') { delete row[`message`]; };
    if (typeof row[`showInMissingTicker`] !== 'undefined') { delete row[`showInMissingTicker`]; };

    _dataSource.forEach((item, i) => {
      // if (typeof item[`weight_calculated_format`] !== 'undefined') { delete item[`weight_calculated_format`]; };
      item[`shares`] = 0;
      if ((typeof item[`original_weight`] !== 'undefined' && item[`original_weight`] !== item[`weight`]) || typeof item[`weight`] === 'undefined') {
        item[`weight`] = item[`original_weight`];
      }
    });

    // 'OVER_RIDE_DATA = true' will re-calculate weight
    let OVER_RIDE_DATA = false;
    if (allocationTypeValue === 'weight') {
      OVER_RIDE_DATA = false;
      row[`weight_calculated_format`] = 'weight';
      row[`format_value`] = Number(row.weight) || 0;

      row[`values`] = 0;
      row[`shares`] = 0;
      row[`amount`] = 0;
    } else if (allocationTypeValue === 'value') {
      OVER_RIDE_DATA = true;
      row[`weight_calculated_format`] = 'amount';
      row[`amount`] = row.values || 0;
      row[`format_value`] = row.values || 0;

      row[`weight(%)`] = 0;
      row[`weight`] = 0;
      row[`original_weight`] = 0;
      row[`shares`] = 0;
    }

    // for invalid ticker case
    if (row[`original_weight`] === 'NA') {
      row[`original_weight`] = 0;
      if (row[`weight`] === 'NA') {
        row[`weight`] = 0;
        if (row[`weight(%)`] === 'NA') {
          row[`weight(%)`] = 0;
        }
      }
    }

    if (typeof row[`amount`] === 'undefined' || row[`amount`] === 'NA' || row[`amount`] === '' || row[`amount`] === null || row[`amount`] !== row.values) {
      row[`amount`] = row.values || 0;
    }

    // uncommenting this line will, trigger moving ticker between diff while searching new ticker based on invalidType nature
    // console.log('row --> ', row);
    // if (row && row.ticker && _magnifiTickers.find(r => r.ticker === row.ticker)) {
    //   let _foundInList = _magnifiTickers.find(r => r.ticker === row.ticker);
    //   console.log('_foundInList --> ', _foundInList);
    //   // for now, 'SHARE' type ticker are in-valid
    //   if (row && _foundInList[`type`] === 'SHARE') {
    //     row[`invalid`] = true;
    //     row[`validTicker`] = false;
    //     row[`showInMissingTicker`] = true;
    //     row[`invalidType`] = "unsupported";
    //     // row[`showInMissingTicker`] = true;
    //   }
    // }

    this.setState({ editingCurrentRecord: row }, () => {
      console.log(' editingCurrentRecord--> ', this.state.editingCurrentRecord);
    });

		let newData = [..._dataSource];
		const index = newData.findIndex(item => row.key === item.key);
		const item = newData[index];
		newData.splice(index, 1, { ...item, ...row });

		this.setState({
			dataSource: newData,
		}, () => {
      // console.log('this.state.dataSource ==> ', this.state.dataSource);
      // const { getUpdatedData, allocationTypeValue } = this.props;
      // if (getUpdatedData) {
      //   getUpdatedData(this.state.dataSource, OVER_RIDE_DATA);
      // }
		});
    const { getUpdatedData, tableFor } = this.props;
    console.log('--- on-editing, OVER_RIDE_DATA -> ', OVER_RIDE_DATA);
    if (getUpdatedData) {
      getUpdatedData(newData, tableFor, OVER_RIDE_DATA, allocationTypeValue);
    }
	};

  saveCurrentRecordChange = (rowProps) => {
    console.log('rowProps-->', rowProps);
    const row = rowProps; // this.state.editingCurrentRecord;
    if (row) {
      console.log('-- before saveCurrentRecordChange --> ', row);
      if (row && Object.keys(row).length > 0) {
        let _dataSource = this.state.dataSource;
        const { allocationTypeValue } = this.props;

        if (row && row.ticker) {
          // consider ticker as valid
          row[`invalid`] = false;
          row[`validTicker`] = true;
          row[`invalidType`] = "supported";
          row[`showInMissingTicker`] = false;
        }

        // commenting below SHARE specify logic, since in backend SHARE is in supported category
        // // for now, 'SHARE' type ticker are in-valid
        // if (row && row[`type`] === 'SHARE') {
        //   row[`invalid`] = true;
        //   row[`validTicker`] = false;
        //   row[`showInMissingTicker`] = true;
        //   row[`invalidType`] = "unsupported";
        //   // row[`showInMissingTicker`] = true;
        // } else
        if (row[`type`] && typeof row[`type`] !== 'undefined' && row[`type`] !== '' && row[`type`] !== 'NA' && row[`type`] !== null) {
          row[`invalid`] = false;
          row[`validTicker`] = true;
          row[`showInMissingTicker`] = false;
          row[`invalidType`] = "supported";
          if (typeof row[`message`] !== 'undefined') { delete row[`message`]; };
        }

        if (typeof row[`amount`] === 'undefined' || row[`amount`] === 'NA' || row[`amount`] === '' || row[`amount`] === null || row[`amount`] !== row.values) {
          row[`amount`] = row.values || 0;
        }

        // 'OVER_RIDE_DATA = true' will re-calculate weight
        let OVER_RIDE_DATA = false;
        if (allocationTypeValue === 'weight') {
          OVER_RIDE_DATA = false;
        } else if (allocationTypeValue === 'value') {
          OVER_RIDE_DATA = true;
        }

        let newData = [..._dataSource];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });

        this.setState({
          dataSource: newData,
        }, () => {
          const { getUpdatedData, tableFor } = this.props;
          if (getUpdatedData) {
            // console.log('--- save button clicked, OVER_RIDE_DATA -> ', OVER_RIDE_DATA);
            getUpdatedData(this.state.dataSource, tableFor, OVER_RIDE_DATA, allocationTypeValue);
          }
          this.setState({ editingCurrentRecord: null })
        });
      }
    }
  }

  removeThisTicker = () => {
    this.setState({
      newTicker: null,
      searchDropdownValue: { value: '', label: [], key: '', price: 0 },
    })
    this.addTickerToggle();
    if (this.addNewTickerForm && this.addNewTickerForm.current && this.addNewTickerForm.current.resetFields) {
      this.addNewTickerForm.current.resetFields();
    }
    setTimeout(() => {
      this.addTickerToggle();
      const searchBox = document.getElementById('custom-ticker-search-select-dropdown');
      if (searchBox) {
        searchBox.focus();
      }
    }, 400)
  }

  checkFieldHasError = row => {
    return false;
  }

  getErrorMsg = row => {
    if (true) {
      return '';
    } else {
      return 'some error msg';
    }
  }

	handleChange = (pagination, filters, sorter) => {
		this.setState({
			filteredInfo: filters,
			sortedInfo: sorter,
			pagination: {
        ...this.state.pagination,
        ...pagination,
      },
		});
	};

	errorHandle = ({ fields }) => {
		this.setState({ errorFields: fields }, () => {
			const { errorHandle } = this.props;
			if (errorHandle) {
				errorHandle(fields);
			}
		});
	}

  edit = record => {
    // console.log(record);
    if (this.state.editingKey !== record.key) {
      this.setState({ editingKey: record.key })
    } else if (this.state.editingKey === record.key) {
      this.setState({ editingKey: '' })
      let __editCurrentRecord = JSON.parse(JSON.stringify(this.state.editingCurrentRecord))
      console.log('-- this.state.editingCurrentRecord > ', __editCurrentRecord);
      this.saveCurrentRecordChange(__editCurrentRecord);
    }
    // console.log('editingKey=>>', this.state.editingKey);
  }

  isEditable = (record, col, checkWith = 'ticker') => {
    return record.key === this.state.editingKey;
  }

  isEditing = () => {
    this.setState({ isEditing: !this.state.isEditing });
  }

  searchedCustomTicker = data => {
    console.log('====== searchedCustomTicker ======');
    console.log(data);
    console.log('======');
  }

  addTickerToggle = () => {
    this.setState({ addTickerEnabled: !this.state.addTickerEnabled }, () => {
      if (this.state.addTickerEnabled) {
        this.setState({
          // dont allow user to edit at same time
          editingKey: '',
          isEditing: false,
        })
      }
    });
  }

  allocationChange = (allocationType) => {
    let allocationTypeValue = allocationType.target ? allocationType.target.value : allocationType;
    if (allocationTypeValue) {
      const { allocationChange } = this.props;
      if (allocationChange) {
        allocationChange(allocationTypeValue)
      }
    }
  }

	render() {
    const {
      data,
      title,
      tableFor,
      checkTicker,
      validTickers,
      renderHeader,
      portfolioTotal,
      getRealTimePrice,
      allocationTypeValue,
      realTimePriceLoading,
      getTickerListRequest,
      showAllInvalidTickers,
      fetchingAllTickersList,
      showAllInvalidTickersToggle,
      magnifiTickers: _magnifiTickers,
    } = this.props;

		let { sortedInfo, pagination, dataSource, tickerNotExist, addTickerEnabled, searchDropdownValue } = this.state;

    let filterMagnifiTickers = _magnifiTickers; // _magnifiTickers.filter((item) => { })

		sortedInfo = sortedInfo || {};

		const components = {
			body: {
				row: EditableRow,
				cell: EditableCell,
			},
		};

    let columns = this.columns.map((col, colIndex) => {
      if (col.dataIndex === 'name') {
        return {
          ...col,
          render: (text, record) => (typeof col.dataIndex !== 'undefined' && col.dataIndex === 'name' && record.key === this.state.editingKey && col && col.dataIndex === 'name' ) ? (
            <div className={`td-disable-box`}>
              {text}
            </div>
          ) : (
            <div className="ant-table-cell-ellipsis" title={text}>{text}</div>
          ),
        }
      }

      if (col.dataIndex === 'price') {
        return {
          ...col,
          render: (text, record) => <div>{'$'+numberCheck(text, 2)}</div>,
        }
      }

      if (col.dataIndex === 'operation') {
        return {
          ...col,
          render: (text, record) =>
            this.state.dataSource.length >= 1 ? (
              <i className="far fa-trash-alt" onClick={() => this.handleDelete(record)}></i>
            ) : null,
        }
      }

      if (col.dataIndex === 'edit') {
        return {
          ...col,
          render: (text, record) =>
            this.state.dataSource.length >= 1 ? (
              <i
                className={`fas ${record.key !== this.state.editingKey ? 'fa-pencil-alt csv-preview-uplaod-pencil-icon' : 'fa-check csv-preview-uplaod-circle-check-icon'}`}
                onClick={() => this.edit(record)}
              />
            ) : null,
        }
      }

      if (col.dataIndex.includes('weight')) {
        col.disabled = (allocationTypeValue === 'value');
        col.title = (
          <Allocation preText="Allocation" onChange={this.allocationChange} value={allocationTypeValue} />
        );
      }

      if (col.dataIndex === 'values') {
        col.title = '';
        col.disabled = (allocationTypeValue === 'weight');
      }

      return {
        ...col,
        className: `${col.className} ${col.dataIndex.includes('weight') ? 'allocation-header-container' : ''}`,
        sortOrder: sortedInfo.columnKey === col.dataIndex && sortedInfo.order,
        onCell: record => ({
          record,
          checkTicker,
          getRealTimePrice,
          allocationTypeValue,
          realTimePriceLoading,
          getTickerListRequest,
          fetchingAllTickersList,
          dataIndex: col.dataIndex,
          searchBox: col.searchBox,
          searchDropdownHeight: 160,
          disabled: col.disabled || false,
          title: col.title, // (col.dataIndex === 'price') ? '$'+col.title : ((col.dataIndex.includes('weight') && col.title) ? col.title.toFixed(2)+'%' : col.title),
          isEditing: this.isEditing,
          errorMsg: this.getErrorMsg,
          handleSave: this.handleSave,
          errorHandle: this.errorHandle,
          fieldError: this.checkFieldHasError,
          magnifiTickers: filterMagnifiTickers,
          editable: this.isEditable(record, col),
          searchDropdownTargetElement: `${tableFor}-csv-preview-upload-container`,
          // searchedCustomTicker: this.searchedCustomTicker,
        }),
      };
    });

    const portfolioWeight = portfolioTotal && typeof portfolioTotal.weight !== 'undefined' ? numberCheck(portfolioTotal.weight, 2) : 0;
    const portfolioValue = portfolioTotal && typeof portfolioTotal.values !== 'undefined' ? numberCheck(portfolioTotal.values, 2) : 0;
    const errorTextForWeight = 'Total sum of weights(%) must be 100%';

    const renderTotalDesign = ({ style }) => {
      return (
        <Row className="add-new-ticker-container" style={style}>
          <Col className="add-new-ticker-col" style={{ width: colWidthObj['edit'] }}></Col>
          <Col className="add-new-ticker-col total-text" style={{ width: colWidthObj['searchInput'] }}>
            Total
          </Col>
          <Col id="csv-preview-total-weight" className="add-new-ticker-col" style={{ width: colWidthObj['weight(%)'], textAlign: 'center' }}>
            {(portfolioWeight !== 100) ? (
              <Tooltip placement="bottom" title={errorTextForWeight} getPopupContainer={() => document.getElementById(`csv-preview-total-weight`)}>
                <div className={`input-box error ${(allocationTypeValue === 'value') && 'disabled'}`}>{portfolioWeight+'%'}</div>
              </Tooltip>
            ) : (
              <div className={`input-box ${(allocationTypeValue === 'value') && 'disabled'}`}>{portfolioWeight+'%'}</div>
            )}
          </Col>
          <Col className="add-new-ticker-col" style={{ width: colWidthObj['values'], textAlign: 'center' }}>
            <div className={`input-box ${(allocationTypeValue === 'weight') && 'disabled'}`}>
              {numberCheck(portfolioValue) ? FormatCurrency(portfolioValue, 0, '$', 'unf') : '--'}
            </div>
          </Col>
          <Col className="add-new-ticker-col" style={{ width: colWidthObj['operation'], textAlign: 'center' }}></Col>
        </Row>
      );
    };

    // let totalRow = this.props.data.length;
    // // console.log('showPaginationTotal ---> ', totalRow);
    // if (pagination && typeof pagination.pageSize !== 'undefined' && (totalRow > pagination.pageSize)) {
    //   // step 1: get all page count
    //   PAGE_COUNT = (totalRow / pagination.pageSize);
    //   if (PAGE_COUNT !== pagination.current) {
    //     PAGE_COUNT = parseInt(PAGE_COUNT);
    //     PAGE_COUNT++;
    //   }
    // }

    // console.log('PAGE_COUNT---> ', PAGE_COUNT, 'pagination.current --> ', pagination.current, 'pagination.current === PAGE_COUNT --> ', pagination.current === PAGE_COUNT);

		return (
			<React.Fragment>
				<div id={`${tableFor}-csv-preview-upload-container`} className="csv-preview-upload-container">
					{renderHeader}
          <Table
            size="small"
            components={components}
            dataSource={dataSource}
            pagination={pagination}
            columns={columns}
            onChange={this.handleChange}
            className={(showAllInvalidTickers && tableFor === 'Invalid') && 'show-all-invalid-tickers'}
            rowClassName={(record) => `ant-table-row ant-table-row-level-0 editable-row ${ tickerNotExist.find((g) => g === record.ticker) ? 'row-has-error' : ''}`}
          />
          {(pagination.current === PAGE_COUNT) && (
            <div>
              {(addTickerEnabled) ? (
                <>
                  <Row className="add-new-ticker-container" style={{ marginBottom: 0 }}>
                    <Form
                      ref={this.addNewTickerForm}
                      name="new_ticker_form"
                      layout="inline"
                      onFinish={this.addNewTicker}
                    >
                      <Col className="add-new-ticker-col" style={{ width: colWidthObj['edit'] }}>
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            style={{
                              margin: 0,
                            }}
                          >
                            <i className={`fas fa-plus`} />
                          </Button>
                        </Form.Item>
                      </Col>
                      <Col className="add-new-ticker-col custom-ticker-search-box-wrapper" style={{ width: colWidthObj['searchInput'], padding: 0 }}>
                        <SearchDropdown
                          placeHolder="Add Security/Ticker"
                          showSearchIcon={false}
                          searchDropdownHeight={160}
                          value={searchDropdownValue}
                          loading={fetchingAllTickersList}
                          magnifiTickers={filterMagnifiTickers}
                          getRealTimePrice={getRealTimePrice}
                          realTimePriceLoading={realTimePriceLoading}
                          getTickerListRequest={getTickerListRequest}
                          searchDropdownTargetElement={`${tableFor}-csv-preview-upload-container`}
                          searchedCustomTicker={this.searchedTicker}
                          // getRef={this.searchSelectDropdownRef}
                        />
                      </Col>
                      <Col className="add-new-ticker-col" style={{ width: colWidthObj['weight(%)'], textAlign: 'center' }}>
                        <Form.Item
                          style={{
                            margin: 0,
                          }}
                          name={'weight'}
                          rules={[
                            {
                              required: (allocationTypeValue === 'weight'),
                              message: (<div></div>),
                            },
                          ]}
                        >
                          <Input
                            className={`input-box ${(allocationTypeValue === 'value') && 'disabled'}`}
                            disabled={(allocationTypeValue === 'value')}
                          />
                        </Form.Item>
                      </Col>
                      <Col className="add-new-ticker-col" style={{ width: colWidthObj['values'], textAlign: 'center' }}>
                        <Form.Item
                          style={{
                            margin: 0,
                          }}
                          name={'values'}
                          rules={[
                            {
                              required: (allocationTypeValue === 'value'),
                              message: (<div></div>),
                            },
                          ]}
                        >
                          <Input
                            className={`input-box ${(allocationTypeValue === 'weight') && 'disabled'}`}
                            disabled={(allocationTypeValue === 'weight')}
                          />
                        </Form.Item>
                      </Col>
                      <Col className="add-new-ticker-col" style={{ width: colWidthObj['operation'], textAlign: 'center' }}></Col>
                    </Form>
                  </Row>
                  {renderTotalDesign({ style: {} })}
                </>
              ) : (
                <>
                  <Row className="add-new-ticker-container" style={{ marginBottom: 0 }}>
                    <Col className="add-new-ticker-col custom-ticker-search-box-wrapper" style={{ width: '100%', padding: 0 }}>
                      <div className="d-flex flex-column align-items-center justify-content-center pos-rel" style={{ height: 30 }}>
                        <div className="custom-ticker-search-box-loader">
                          <Spin indicator={spinIcon} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {renderTotalDesign({ style: {} })}
                </>
              )}
            </div>
          )}
				</div>
			</React.Fragment>
		)
	}
}

export default EmptyDataList;


// Add a ticker design
// (
//   <>
//     {renderTotalDesign({ style: { marginBottom: 0 } })}
//     <div className="add-new-ticker-label">
//       <span onClick={this.addTickerToggle}>Add Security</span>
//     </div>
//   </>
// )

import React, { Component, Fragment } from 'react';

import NotApplicable from '../../NotApplicable';
import GaugeChart from './GaugeChart'

const formatNumberValue = (value, fraction) => {
  const leftPart = (value + '').split('.')[0];

  if (leftPart.length >= 4 && leftPart.length < 7) {
    return (value / 1000).toFixed(fraction) + 'K';
  } else
    if (leftPart.length >= 7 && leftPart.length < 10) {
      return (value / 1000000).toFixed(fraction) + 'M';
    } else
      if (leftPart.length >= 10) {
        return (value / 1000000000).toFixed(fraction) + 'B';
      } else {
        return value.toFixed(fraction)
      }
};

const RenderOdometer = (props) => {
    const { tickerData, queryVar } = props;
    const { view_name, Secondary_view_name, decimal, suffix } = queryVar;

    if (!tickerData) return <NotApplicable />;

    const currentMultiplier = tickerData.multiplier || 1;
    const currentSuffix = view_name !== "Volume" ? (tickerData.suffix || suffix || '') : '';
    const currentDecimal = tickerData.decimal || decimal || 1;
    let name = view_name + (Secondary_view_name ? ` (${Secondary_view_name})` : '');
    name = name.replace('ticker', queryVar.data);

    let min, max, value;
    if (view_name === 'Return Quality') {
      // implementing like seach after discussing with business
      const subField = tickerData.find(type => Secondary_view_name.includes(type.name));
      if(!subField) return <NotApplicable />
      min = (subField.min * 100).toFixed(2);
      max = (subField.max * 100).toFixed(2);
      value = (subField.value * 100).toFixed(2);
    } else {
      min = tickerData.min;
      max = tickerData.max;
      value = tickerData.value;
    }

    let minWithMultiplier = (min * currentMultiplier);
    let maxWithMultiplier = (max * currentMultiplier);
    let valueWithMultiplier = (value * currentMultiplier);
    let minRender, maxRender, valueRender;

    if (queryVar.chart_type === 'odometer') {
      if (view_name === 'Assets') {
        minRender = formatNumberValue((min / 1000), currentDecimal);
        maxRender = formatNumberValue((max / 1000), currentDecimal);
        valueRender = formatNumberValue((value / 1000), currentDecimal);
      } else
        if (view_name === 'Return Quality') {
          // implementing like seach after discussing with business
          const subField = tickerData.find(type => Secondary_view_name.includes(type.name));
          minRender = (subField.min * currentMultiplier).toFixed(currentDecimal) + (currentSuffix || '');
          maxRender = (subField.max * currentMultiplier).toFixed(currentDecimal) + (currentSuffix || '');
          valueRender = (subField.value * currentMultiplier).toFixed(currentDecimal) + (currentSuffix || '');
        } else {
          minRender = formatNumberValue(minWithMultiplier, currentDecimal) + currentSuffix;
          maxRender = formatNumberValue(maxWithMultiplier, currentDecimal) + currentSuffix;
          valueRender = formatNumberValue(valueWithMultiplier, currentDecimal) + currentSuffix;
        }
    } else {
      minRender = minWithMultiplier.toFixed(currentDecimal) + currentSuffix;
      maxRender = maxWithMultiplier.toFixed(currentDecimal) + currentSuffix;
      valueRender = valueWithMultiplier.toFixed(currentDecimal) + currentSuffix;
    }

    return (
      <div className="siw-meter print-wrapper">
        <span className="siw-meter-title" title={name}>{name}</span>
        <GaugeChart
          value={value}
          max={max}
          min={min}
        />
        <div className="d-flex" style={{ width: '100px', justifyContent :"space-between" }}>
          <span className="siw-meter-min">
            {minRender}
          </span>
          <span className="siw-meter-value">
            {valueRender}
          </span>
          <span className="siw-meter-max">
            {maxRender}
          </span>
        </div>
      </div>
    );
};

export default RenderOdometer;

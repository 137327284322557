import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Redirect, NavLink } from 'react-router-dom'

import { Container, Row, Nav, Col } from 'reactstrap'
import ProfileFormContainer from './ProfileFormContainer'
import Navbar from '../components/Navbar'

import { logout } from 'actions/auth'
import { PrivateRoute } from 'shared-components/'
import BrokerContainer from './BrokerContainer'
import WeightsContainer from './WeightsContainer'
import ReferralCodeContainer from './ReferralCodeContainer'
import SubscriptionPlanDetails from '../components/profile/SubscriptionPlanDetails'
import { subscriptionPlan, setProfileState } from 'actions/profile'
import { changeLogo } from '../Utils'
import { Layout } from 'antd';

const { Footer } = Layout;
class SettingsContainer extends Component {
  componentDidMount() {
    if(this.props.loggedIn){
      changeLogo().then(res => this.props.setProfileState({logo: res}));
    }else{
      this.props.setProfileState({logo: '/assets/images/MagnifiLogo/Magnifi_byTIFIN-darkgrey.svg'});
    }

    this.props.subscriptionPlan();
    document.body.classList.remove('main')
    document.body.classList.remove('ht0')
    this.forceUpdate();
  }
  componentDidUpdate() {
    document.body.style.paddingTop = "";
    document.body.classList.remove('main');
    document.body.classList.remove('ht0');
  }
  render() {
    const {buildportfolio, logo, selectorCount} = this.props;
    const mailTo = process.env.REACT_APP_SUB_DOMAIN === 'retail' ? 'tradingaccountsupport@magnifi.com' : 'support@magnifi.com';
    // <section ref={this.secRef} className="fixed-top bg-ssf-gray-l3 sm">
    //   <Navbar logo={logo} height="40" selectorCount={selectorCount} count={buildportfolio.clearBuilderFunds ? 0:buildportfolio.resultPageCart.length} onLogout={this.props.logout} isLoggedIn={this.props.loggedIn} {...this.props}/>
    // </section>
    return (
      <div className="settings-container">
        {(window.innerWidth <= 1023) &&
          <section ref={this.secRef} className="fixed-top bg-ssf-gray-l3 sm">
            <Navbar logo={logo} height="40" selectorCount={selectorCount} count={buildportfolio.clearBuilderFunds ? 0:buildportfolio.resultPageCart.length} onLogout={this.props.logout} isLoggedIn={this.props.loggedIn} {...this.props}/>
          </section>
        }
        <div className="settings__header">
          <Container>
            <Row>
              <Col xs={12} className="setting-wrapper">
                <h2 className="settings__title">Settings</h2>
                <div className="settings__menu-content-area">
                  <Nav className="settings__menu">
                    <NavLink
                      to="/settings/profile"
                      activeClassName="active"
                      className="settings__menu-item"
                    >
                      Profile
                    </NavLink>
                    <NavLink
                      to="/settings/broker"
                      activeClassName="active"
                      className="settings__menu-item"
                    >
                      Brokers
                    </NavLink>
                    {/* <NavLink
                      to="/settings/weights"
                      activeClassName="active"
                      className="settings__menu-item"
                    >
                      Configure
                    </NavLink> */}
                    {/*<NavLink
                      to="/settings/referral"
                      activeClassName="active"
                      className="settings__menu-item"
                    >
                      Referrals
                    </NavLink>*/}
                    <NavLink
                      to="/settings/subscriptionPlan"
                      activeClassName="active"
                      className="settings__menu-item"
                    >
                      {(window.screen.width < 760) ? 'Subscription' : 'Subscription Plan'}
                    </NavLink>
                  </Nav>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="settings__body">
          <Container>
            <Row>
              <Col xs={12}>
                <Switch>
                  <PrivateRoute
                    path="/settings/profile"
                    component={ProfileFormContainer}
                  />
                  <PrivateRoute
                    path="/settings/broker"
                    component={BrokerContainer}
                  />
                  {/* <PrivateRoute
                    path="/settings/weights"
                    component={WeightsContainer}
                  /> */}
                  <PrivateRoute
                    path="/settings/referral"
                    component={ReferralCodeContainer}
                  />
                  <Redirect from="/settings" exact to="/settings/profile" />
                  <PrivateRoute
                    path="/settings/subscriptionPlan"
                    component={SubscriptionPlanDetails}
                  />
                </Switch>
              </Col>
            </Row>
          </Container>
        </div>
        {/* <Footer className="footerContainer">
            <img src="/assets/images/SupportIcon.svg" />
            <span>
              Get In Touch With Us @ 
              <a id="mail-to-magnifi" href={`mailto:${mailTo}`}>
                {mailTo}
              </a>
            </span>
        </Footer> */}
      </div>
    )
  }
}

const mapStateToProps = ({ auth, buildportfolio, profile, sfm }) => ({
  buildportfolio,
  logoutSuccess: auth.logoutSuccess,
  loggedIn: auth.loggedIn,
  premiumUser:auth.user.premiumUser,
  logo: profile.logo,
  selectorCount: sfm.selectorCount,
})

const mapDispatchToProps = {
  logout,
  subscriptionPlan,
  setProfileState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsContainer)

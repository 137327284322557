import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import qs from 'query-string'

import { Formik } from 'formik'
import * as Yup from 'yup'
import { get } from 'lodash'
import moment from 'moment';
import { Row, Col } from 'reactstrap'

import {encryptPassword, isRetailUser} from "layouts/utils"

import {
  auth,
  failedGoogleAuth,
  successfulAuth,
  successfulGoogleAuth
} from 'actions/auth'
import {
  register,
  registerGoogle,
  setRegisterState,
  successfulRegister,
  failedGoogleRegister
} from 'actions/register'
import { storeAuthToken } from 'common/auth-utils'
import { registerProfileData, failedRegister } from 'actions/register'

import {
  updateProfile,
  successfulUpdateProfile,
  failedUpdateProfile,
  fetchProfile
} from 'actions/profile'
import { createLoadingSelector } from 'selectors/loading-selector'
import { Spinner } from 'shared-components/'
import { isTermsAgreed } from 'selectors/auth-selector'
import { REDIRECT_DOMAIN_PATH } from 'DataSet'
import { addEventToAnalytics } from 'Utils'
import RegisterFormOpt2 from './RegisterFormOpt2'
import * as RegisterApi from '../../../apis/register'
import * as AuthUtils from '../../../common/auth-utils'
import * as ProfileApi from '../../../apis/profile'
import { getAgeMapping } from '../Constant/FormData'
import { createSubscriptionRequest } from 'actions/profile'
import config from '../../../env'
import { updateMetaInfo } from '../utils'
import {
  getAdditionalDetailsForRegister,
  clearUtmSession
} from '../../../Utils'
import { Regex } from 'common/regex'

const magnifiLogo =
  '/assets/images/MagnifiLogo/Magnifi_Tagline_White_RGB@2x.png'

class Register extends Component {
  state = {
    modal: true,
    showSuccess: false,
    step: 1,
    isStripeSubscription: false,
    plan_selected: '',
    isFetching: false,
    isPlanChange: false,
    disableRegisterButton: false,
    planType: '',
    openTradingRegisterPage: null,
    registerForTrade: null,
  }

  initialValues = {
    termsCondition: false,
    planName: 'Premium Lite' // default $250 plan
    //accredited_investor:false,
  }

  componentDidMount() {
    updateMetaInfo({ title: 'Register With Magnifi | Innovation-Based Investing & Portfolio Management', description: 'Create your free Magnifi account and get access to customized investment portfolios for you or your client. Discover a whole new world of investing opportunities today!' });
    document.getElementsByTagName('body')[0].style.paddingTop = 0
    let plan = this.props.history.location.state
      ? this.props.history.location.state.plan
        ? this.props.history.location.state.plan
        : 'basic'
      : 'basic'
    const planType = get(this.props.history, 'location.state.planType', null)
    //basic, pro, pro-plus
    console.log(plan)
    if (plan === null) {
      this.props.history.push(`/join?`)
    } else {
      this.setState({
        plan_selected: plan,
        planType
      })
    }
    addEventToAnalytics('Register Page', 'Register Page', 'REGISTER_PAGE', {
      clickPage: 'Register Page'
    })
  }

  componentWillUnmount(){
    updateMetaInfo();
  }

  componentDidUpdate(prevProps) {
    const { profileUpdateSuccess } = this.props
    const { isStripeSubscription } = this.state
    if (
      profileUpdateSuccess !== prevProps.profileUpdateSuccess &&
      profileUpdateSuccess &&
      isStripeSubscription
    ) {
      this.generateSessionId()
    }
  }

  generateSessionId() {
    this.setState({ isFetching: true })
    this.props.createSubscriptionRequest(
      { plan_name: this.state.planName, planType: this.state.planType },
      this.subscribeNow
    )
  }

  subscribePlan = plan => {
    switch (plan) {
      case '$250':
        // $250 plan
        this.setState({
          planName: 'Premium Lite'
        })
        break
      case '$500':
        // $500 plan
        this.setState({
          planName: 'Premium Plan'
        })
        break
      default:
        // $250 plan
        this.setState({
          planName: 'Premium Lite'
        })
    }
    this.setState({ isStripeSubscription: true })
  }

  subscribeNow = sessionId => {
    this.setState({ isFetching: false })
    // const sessionId = this.props.stripeSessionID;
    if (sessionId) {
      const stripe = window.Stripe(config.stripePublishableKey)
      stripe
        .redirectToCheckout({
          sessionId
        })
        .then(result => {
          console.log(result)
          // console.log("results status",result)
        })
    }
  }

  nextClick = async (values, { setSubmitting }, showStep2 = false) => {

    const returnedToEdit= this.props.history && this.props.history.location && this.props.history.location.returnToEdit ? true : false;
    values.password = encryptPassword(values.password);
    values.rePassword= values.password; //encryptPassword(values.rePassword);
    if(returnedToEdit){
      let postData = JSON.parse(JSON.stringify(this.props.profileData.profile))
      postData['investable_assets'] = values.investable_assets;
      postData['date_of_birth'] = values.date_of_birth
      ? typeof values.date_of_birth.getMonth === 'function'
        ? moment(values.date_of_birth).format('YYYY-MM-DD')
        : moment(values.date_of_birth).format('YYYY-MM-DD')
      : values.date_of_birth;
      this.updateUsersProfile(postData, ()=>{
        this.props.history.push({ pathname: '/register/trading', fromRegister: true, fromPathRouter: this.props.location.pathname })
      })

      return
    }

    let data = {
      ...values,
      register_step_status: '1'
    };

    this.setState({
      disableRegisterButton: true
    })
    let isPlanChange = localStorage.getItem('isPlanChange')
      ? JSON.parse(localStorage.getItem('isPlanChange'))
      : false
    if (!isPlanChange) {
      let { plan_selected } = this.state
      if (plan_selected === 'pro') {
        this.subscribePlan('$250')
      } else if (plan_selected === 'pro-plus') {
        this.subscribePlan('$500')
      }
    } else {
      this.setState({
        isPlanChange: isPlanChange
      })
    }

    try {
      let _responseData = await RegisterApi.register(
        qs.stringify({
          ...values,
          // verify_email: true,
          e: true,
          ...getAdditionalDetailsForRegister()
        })
      )
      // console.log(_responseData)

      // NOTE: commenting clearUtmSession, we need to UTM values for INVESTMENT ACCOUNT CREATED EVENT
      // clearUtmSession()

      // creating First Name from email address
      // const EMAIL_FIRST_NAME = values.email && values.email.split('@').length ? values.email.split('@')[0] : '';
      // _responseData.data.first_name = _responseData.data.first_name || EMAIL_FIRST_NAME;

      addEventToAnalytics('Create Free Magnifi Trading Account Click', 'Create Free Magnifi Trading Account Click', 'CREATE_FREE_MAGNIFI_TRADING_ACCOUNT_CLICK', {
        clickPage: 'Register Page'
      })

      AuthUtils.storeAuthToken({
        token: _responseData.data.token,
        firstName: _responseData.data.first_name,
        lastName: _responseData.data.last_name.trim(),
        email: values.email,
        isGoogleLogin: false,
        newUser: _responseData.data.new_user,
        termsAgreed: _responseData.data.terms_agreed ? 1 : 0,
        profile_self_role: _responseData.data.self_role
      })
      _responseData.data.firstName = _responseData.data.first_name
      _responseData.data.lastName = _responseData.data.last_name.trim()

      this.props.successfulRegister(_responseData.data)
      this.props.successfulAuth({
        ..._responseData.data,
        termsAgreed: _responseData.data.terms_agreed ? 1 : 0,
        profile: {
          ..._responseData.data,
          newUser: _responseData.data.new_user,
          termsAgreed: _responseData.data.terms_agreed ? 1 : 0
        }
      })
      let data2 = {
        zipcode: values.zipcode,
        country: values.country,
        termsCondition: false
      }
      addEventToAnalytics('Register', 'Register', 'REGISTER', {
        email: values.email
      })

      // NOTE: Google Analytics event --> Advisor Event
      // 'Magnifi Advisor Account Created', {properties: {category: 'Advisor'}, category: 'Advisor'}
      // ------------------------------------------------------------------------------------------------------------------------------
      addEventToAnalytics(
        'Magnifi Advisor Account Created',
        'Magnifi Advisor Account Created',
        'MAGNIFI_ADVISOR_ACCOUNT_CREATED', {
          properties: {
            category: 'Advisor'
          },
          category: 'Advisor'
        });
      // ------------------------------------------------------------------------------------------------------------------------------

      //unused "profile" api call
      // this.props.updateProfile(data2)

      // if (
      //   this.state.plan_selected == 'basic' &&
      //   isRetailUser(values.self_role) &&
      //   !isPlanChange
      // ) {
      //   // redirect the user to securities so that they can continue where they left off
      //   // otherwise if they were not in /securities they will be automatically
      //   // redirected to landing page
      //   // debugger
      //   this.props.history.push('/securities')
      // } else {
      //   if (!isRetailUser(values.self_role)) {
      //     // debugger
      //     if (this.state.registerForTrade === 'OPEN_TRADING_REGISTER_PAGE') {
      //       this.setState({ openTradingRegisterPage: this.state.registerForTrade });
      //     }
      //
      //     showStep2 && this.setStep(2)
      //   }else {
      //     // debugger
      //     this.props.history.push('/securities')
      //   }
      // }

      console.log(' === this.state.registerForTrade ===>', this.state.registerForTrade);
      // debugger
      if (this.state.registerForTrade === 'OPEN_TRADING_REGISTER_PAGE') {
        this.setState({ openTradingRegisterPage: this.state.registerForTrade });
        // showStep2 && this.setStep(2)
        this.props.history.push({ pathname: '/register/trading', fromRegister: true, fromPathRouter: this.props.location.pathname })
      } else {
        this.props.history.push('/securities')
      }

      this.setState({
        disableRegisterButton: false
      })
      addEventToAnalytics(
        'Register Save Profile',
        'Register Save Profile',
        'REGISTER_SAVE_PROFILE',
        { email: data.email }
      )
    } catch (error) {
      console.log(error)
      this.setState({
        disableRegisterButton: false
      })
      if (error && typeof error.response !== 'undefined') {
        console.log(error.response);
        if (
          typeof error.response.data !== 'undefined' &&
          typeof error.response.data.email !== 'undefined' &&
          error.response.data.email[0] === 'E-mail ID already registered'
        ) {
          values.first_name = "";
          this.props.failedRegister(error.response, error.response.data)
          setSubmitting(false)
        }
      }
    }
  }

  setStep = step => {
    console.log('setStep', step)
    this.setState({ step })
  }

  backHandler = () => {
    const { step } = this.state
    if (step === 1) this.routeToPage()
    else if (step === 2) {
      // this.setState({ step: 1 });
    }
  }

  handleGoogleSignInSuccess = response => {
    console.log({ response })

    const profile = response.profileObj
    const token = response.tokenObj
    let { plan_selected } = this.state
    // window.localStorage.setItem('socialmail', profile.email)
    this.props.registerGoogle({
      email: profile.email,
      first_name: profile.givenName,
      last_name: profile.familyName,
      g: 1,
      token: response.tokenId,
      plan_selected
    }, (authToken)=>{
      const dataToSend = {
        data: {
          register_step_status: "1",
          self_role: "Individual Investor"
        },
        token: authToken
      }
      this.props.registerProfileData(dataToSend, ()=>{
        addEventToAnalytics(
          'Register with Google login',
          'Register with Google login',
          'REGISTER_WITH_GOOGLE_LOGIN',
          { email: profile.email }
        )
        addEventToAnalytics(
          'Register Google Button Clicked',
          'Register Google Button Clicked',
          'REGISTER_BUTTON_CLICKED',
          { email: profile.email }
        )
        this.props.history.push('/register/trading');
      });
    })
  }

  handleGoogleSignInError = error => {
    console.log({ error })

    //TODO: why Google sso triggered error first time
    if (error !== 'SDK not loaded') {
      this.props.failedGoogleAuth(error)
    }
  }

  registerSchema = Yup.object().shape({
    first_name: Yup.string()
      .required('First name is a required field')
      .matches(Regex.fullName, 'First name is not valid'),
    last_name: Yup.string()
      .required('Last name is a required field')
      .matches(Regex.fullName, 'Last name is not valid'),
    email: Yup.string()
      .email()
      .required('Email is a required field'),
    password: Yup.string()
      .min(8, 'Password has to be longer than 8 characters!')
      .required('Password is a required field'),
    rePassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Re-enter password is a required field'),
    termsCondition: Yup.boolean().oneOf(
      [true],
      'Please accept terms and conditions'
    ),
    country: Yup.string().required('Please select a country'),
    zipcode: Yup.string().required('Zip is a required field'),
    age_group: Yup.string().required('Please select a age group'),
    self_role: Yup.string().required('Please select your profile'),

    areYouAnOther: Yup.string().when('self_role', {
      is: self_role => self_role === 'Other',
      then: Yup.string().required('Enter a value'),
      otherwise: Yup.string()
    }),

    investable_assets: Yup.string().when('self_role', {
      is: self_role =>
        self_role === 'Individual Investor' || self_role === 'Other/Curious',
      then: Yup.string().required('Please Select a Investable Assets')
    }),

    estimated_net_worth: Yup.string().when('self_role', {
      is: self_role =>
        self_role === 'Financial Advisor' ||
        self_role === 'Family Office' ||
        self_role === 'Portfolio Manager' ||
        self_role === 'Hedge Fund Manager' ||
        self_role === 'Corporate Investor',
      then: Yup.string().required(
        'Please Select a Assets Under Management/ Advice.'
      )
    })
  })

  routeToPage = () => {
    const currentPath = get(this.props, 'location.pathname', '')
    const pathname = this.props.fromPath || '/'
    if (pathname !== currentPath) {
      if (this.props.fromPath) {
        this.props.history.push(pathname)
      } else {
        this.props.history.goBack()
      }
    }
  }

  updateUsersProfile = async (postData, callback) => {
    try {
      this.setState({ isFetching: true })
      let _responseData = await ProfileApi.updateProfile(
        qs.stringify({ ...postData })
      )
      callback({ responseData: _responseData })
      this.setState({ isFetching: false })
    } catch (error) {
      console.log(error)
      this.setState({ isFetching: false })
      if (error && typeof error.response !== 'undefined') {
        if (typeof error.response.data !== 'undefined') {
          //TO DO
        }
      }
    }
  }

  updatePlanChange = value => {
    this.setState({
      isPlanChange: value
    })
  }

  registerForTrade = (state) => {
    // debugger
    console.log('registerForTrade ==> ', state);
    this.setState({ registerForTrade: state });
  }

  render() {
    const {
      loggedIn,
      registerSuccess,
      registerStepStatus,
      registerGoogleAuthToken,
      registerAppleAuthToken,
      profileUpdateSuccess,
      googleRegisterSuccess,
      createSubscriptionRequest
    } = this.props
    const {
      isStripeSubscription,
      plan_selected,
      isPlanChange,
      disableRegisterButton,
      planType,
      openTradingRegisterPage,
    } = this.state
    if (registerGoogleAuthToken && registerAppleAuthToken && registerStepStatus === '0') {
      // return <Redirect to={`/register-profile/${registerGoogleAuthToken}`} />
    } else {
      if (loggedIn && isPlanChange) {
        // console.log('plan change redirect to join')
        localStorage.removeItem('isPlanChange')
        this.setState({
          isPlanChange: false
        })
        return (
          <Redirect
            to={{
              pathname: '/join',
              state: { isPlanChange: true, planType }
            }}
          />
        )
      }
      if (
        !isStripeSubscription &&
        loggedIn &&
        window.location.search.length === 0 &&
        (registerStepStatus == 2) &&
        !isPlanChange
      ) {
        // console.log('redirect to securities')
        // if query is not present and loggedIn then redirect to magnifi.com/discover
        const pathname = '/securities'
        return (
          <Redirect
            to={{
              pathname,
              search: window.location.search
            }}
          />
        )
      }
    }

    return (
      <RegisterFormVariants
        isFetching={(this.props.isFetching || this.props.isProfileCreate) || this.state.isFetching}
        initialValues={this.initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={this.registerSchema}
        onToggle={this.handleToggle}
        nextClick={this.nextClick}
        error={this.props.error}
        onGoogleSignInSuccess={this.handleGoogleSignInSuccess}
        onGoogleSignInError={this.handleGoogleSignInError}
        step={this.state.step}
        setStep={this.setStep}
        backHandler={this.backHandler}
        history={this.props.history}
        stripeSessionID={this.props.stripeSessionID}
        createSubscriptionRequest={createSubscriptionRequest}
        subscribeNow={plan => this.subscribePlan(plan)}
        updateUsersProfile={this.updateUsersProfile}
        plan_selected={plan_selected}
        updatePlanChange={this.updatePlanChange}
        loggedIn={this.props.loggedIn}
        disableRegisterButton={disableRegisterButton}
        openTradingRegisterPage={openTradingRegisterPage}
        registerForTrade={this.registerForTrade}
        profileData={this.props.profileData}
      />
    )
  }
}

const mapStateToProps = ({ auth, register, loading, profile }) => ({
  registerGoogleAuthToken: register.registerGoogleAuthToken,
  registerAppleAuthToken: register.registerAppleAuthToken,
  registerStepStatus: register.registerStepStatus,
  registerFailed: register.registerFailed,
  registerSuccess: register.registerSuccess,
  error: register.error,
  isFetching: createLoadingSelector(['REGISTER'])({ loading }),
  isProfileCreate: createLoadingSelector(['REGISTER_PROFILE'])({ loading }),
  isTermsAgreed: isTermsAgreed(auth),
  loggedIn: auth.loggedIn,
  user: auth.user,
  profileUpdateSuccess: profile.profileUpdateSuccess,
  googleRegisterSuccess: register.googleRegisterSuccess,
  stripeSessionID: profile.stripeSessionID,
  profileData: profile
})

const mapDispatchToProps = {
  register,
  failedGoogleAuth,
  registerGoogle,
  setRegisterState,
  registerProfileData,
  failedRegister,
  successfulRegister,
  successfulAuth,
  updateProfile,
  successfulUpdateProfile,
  failedUpdateProfile,
  successfulGoogleAuth,
  fetchProfile,
  failedGoogleRegister,
  createSubscriptionRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)

const RegisterFormVariants = props => {
  const variant = '1'

  if (variant === '1') {
    const {
      history: { location }
    } = props
    const state = location.state ? location.state : {}

    return (
      <RegisterFormOpt2
        {...props}
        from={state.from}
        createSubscriptionRequest={props.createSubscriptionRequest}
        subscribeNow={props.subscribeNow}
      />
    )
  }
}

// absolute imports
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { FormatCurrency } from 'Utils';
import TotalAccountValueChart from './TotalAccountValue';
import { Tag } from 'antd';
import 'antd/dist/antd.css';
const { CheckableTag } = Tag;

let yearsData = [{
  label: '1 Year',
  shortLabel: '1Y',
  value: 'l1y',
  number: 1,
  disabled: false,
}, {
  label: '3 Years',
  shortLabel: '3Y',
  value: 'l3y',
  number: 3,
  disabled: false,
}, {
  label: '5 Years',
  shortLabel: '5Y',
  value: 'l5y',
  number: 5,
  disabled: false,
}].reverse();

class AccountPerformance extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: {
        label: '1 Year',
        shortLabel: '1Y',
        value: 'l1y',
        number: 1,
        disabled: false,
      },
    };
  }

  handleChange(year) {
    //for multiple year selection
    //const { selectedTags } = this.state;
   // const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
   // console.log('You are interested in: ', nextSelectedTags);
   console.log(year);
    if (year) {
      this.setState({ selectedYear: year });
    }
  }

  render() {
    const { selectedYear } = this.state;
    const {clientChartDetails}=this.props;
    let yearsList = yearsData.map((item) => item.shortLabel);
    console.log('yearsList -->', yearsList);
    console.log('yearsData -->', yearsData);
    console.log('selectedYear -> ', selectedYear);
    return (
     <>{ clientChartDetails && Object.keys(clientChartDetails).length > 1 ? 
      <div className='account-performance'>
        <div className='total-label'>
        <span>Total Account Value</span>
        <span style={{ float:'right' }}>
          {yearsData.map(year => (
            <CheckableTag
              key={year.shortLabel}
              checked={(selectedYear.shortLabel === year.shortLabel)}
              onChange={() => this.handleChange(year)}
            >
              {year.shortLabel}
            </CheckableTag>
          ))}
        </span>
        </div>
        <TotalAccountValueChart selectedYear={selectedYear} yearsData={yearsData} />
      </div>:''
     }
     </>
     );
  }
}

const mapStateToProps = ({ clientportfolioV2: { selectedClientDetails ,clientChartDetails},
  auth: { user: { firstName, lastName } } }) => ({
  selectedClientDetails,
  firstName,
  lastName,
  clientChartDetails
})

export default connect(
  mapStateToProps
)(AccountPerformance);

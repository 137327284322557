import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Tabs, Row, Col, Card, Select, Checkbox } from 'antd';
import Slider from "react-slick";
import { addEventToAnalytics } from 'Utils';

import { createLoadingSelector } from 'selectors/loading-selector';

import CardLoader from '../../../Elements/CardLoader';
import NoFunds from '../../../Elements/NoFunds';
import FundCard from './FundCard';
import CustomCard from './CustomCard';
import FundCardV2 from './FundCardV2'

const { TabPane } = Tabs;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  initialSlide: 0,
  nextArrow: null,
  prevArrow: null,
  responsive: [
    {
      breakpoint: 1900,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5
      }
    },
    {
      breakpoint: 1770,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    },
    {
      breakpoint: 1439,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 1139,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
  ]
};

class ListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      value: undefined,
      selectedFunds: props.selectedFunds || {},
      selectedTickers: props.checkedListArray || [],
      sliderValue: props.sliderValue || 0,
    };
  }

  componentDidMount() {
    const { checkedListArray, selectedFunds } = this.props;
    if (checkedListArray !== this.state.selectedTickers) {
      this.setState({ selectedTickers: checkedListArray }, () => {
        this.addSliderValue(this.state.selectedTickers, this.props);
      });
    }
  }

  componentWillReceiveProps(newProps) {
    const { checkedListArray, selectedFunds, sliderValue } = newProps;
    if (this.state.selectedFunds !== selectedFunds) {
      this.setState({ selectedFunds: newProps.selectedFunds });
    }
    if (checkedListArray !== this.state.selectedTickers) {
      this.setState({ selectedTickers: checkedListArray }, () => {
        this.addSliderValue(this.state.selectedTickers, newProps);
      });
    }
  }

  searchTickerData = (searchedTicker) => {
    const { customSearchTickerCallback, query, ticker } = this.props;
    if (customSearchTickerCallback) {
      customSearchTickerCallback({
        searchedTicker,
        forTicker: ticker,
        forQuery: query,
      });
    }
  }

  addSliderValue = (selectedTickers, props) => {
    const { sliderValue, weightingMethodology, data, query, buildportfolio } = props;
    const { selectedFunds } = this.state;
    const checkedList = [];
    const updatedArray = _.unionBy(data.funds, selectedFunds[query], 'ticker');
    // combine all funds based on QUERY and allocate weights.
    updatedArray.forEach((f) => {
      if (f.ticker) {
        // f._sliderValue = 0;
        selectedTickers.map((t) => {
          if (t === f.ticker) {
            if(weightingMethodology == "Equal"){
              // f._sliderValue = sliderValue/selectedTickers.length;
                if(f.color === undefined){
                if(buildportfolio.activeTab === 'equityTab') f.color = '#DEF1FF';
                else if(buildportfolio.activeTab === 'bondTab') f.color = '#E0E0E0';
                else if(buildportfolio.activeTab === 'thematicTab') f.color = '#C0E1FA';
              }
            }
            checkedList.push(f);
          }
        })
      }
    });
    // hide those funds which are not part of the QUERY RESULT.
    const resultSet = _.intersectionBy(updatedArray, data.funds, 'ticker');
    data.funds = resultSet;
    this.setState({
      data,
    });
    return checkedList;
  }

  onChange = (selectedTickers) => {
    const { getSelectedFunds, query, sliderValue, weightingMethodology, data, buildportfolio } = this.props;
    const oldEquityTab = JSON.parse(JSON.stringify(buildportfolio.equityTab));
    const oldBondTab = JSON.parse(JSON.stringify(buildportfolio.bondTab));
    const oldThematicTab = JSON.parse(JSON.stringify(buildportfolio.thematicTab));

    addEventToAnalytics('Builder Fund Checkbox','Builder Fund Checkbox','BUILDER_FUND_CHECKBOX',{},false);

    let { selectedFunds } = this.state;
    this.setState({
      oldSelectedfunds: JSON.parse(JSON.stringify(selectedFunds)),
    });
    console.log(this.state.oldSelectedfunds);
    const checkedList = this.addSliderValue(selectedTickers, this.props);
    if (selectedFunds[query]) {
      const oldFunds = selectedFunds[query];
      if (data && typeof data.funds !== 'undefined') {
        // funds which are present in result set ?? ---- >
        // The `_.property` iteratee shorthand.
        // _.intersectionBy([{ 'x': 1 }], [{ 'x': 2 }, { 'x': 1 }], 'x');
        // => [{ 'x': 1 }]
        const resultSetCheckedItems = _.intersectionBy(data.funds, oldFunds, 'ticker');

        // funds which are not present in result set ?? ---- >
        // The `_.property` iteratee shorthand.
        // _.differenceBy([{ 'x': 2 }, { 'x': 1 }], [{ 'x': 1 }], 'x');
        // => [{ 'x': 2 }]
        const preSelectedFunds = _.differenceBy(oldFunds, data.funds, 'ticker');
        let arr = [];
        if (checkedList.length) {
          arr = [
            ..._.uniqBy(preSelectedFunds, 'ticker'),
            ..._.uniqBy(checkedList, 'ticker'),
          ];
        } else {
          arr = [
            ..._.uniqBy(preSelectedFunds, 'ticker'),
          ];
        }

        selectedFunds[query] = _.uniqBy(arr, 'ticker');
      }
    } else {
      selectedFunds = {
        ...selectedFunds,
        [query]: checkedList,
      };
    }

    const ticks = selectedFunds[query].map((h) => h.ticker);
    const updatedArray = this.addSliderValue(ticks, this.props);
    selectedFunds[query] = updatedArray;
    console.log(this.state.oldSelectedfunds);
    this.setState({
      selectedTickers,
      selectedFunds,
    }, () => {
      getSelectedFunds({
        forQuery: query,
        selectedFunds: this.state.selectedFunds[query],
        oldSelectedfunds: this.state.oldSelectedfunds,
        // oldEquityTab,
        // oldBondTab,
        // oldThematicTab
      });
    });

  }

  render() {
    const { query, ticker, staticCard, resetCheck, customEdit, tickerList, tickerLoading, customSearchTickerData, deleteCard, checkedListArray, newQueryLoading, sliderValue, weightingMethodology, newQuery, noResultFound, newQuerySearchLoader } = this.props;
    const { data, selectedTickers, selectedFunds } = this.state;
    const updatedArray = _.unionBy( selectedFunds[query], data.funds,'ticker');
    // combine all funds based on QUERY and allocate weights.
    // updatedArray.forEach((f) => {
    //   if (f.ticker) {
    //     selectedTickers.map((t) => {
    //       if (t !== f.ticker) {
    //         // f._sliderValue = 0;
    //       }
    //     })
    //   }
    // });
    // hide those funds which are not part of the QUERY RESULT.
    const resultSet = _.intersectionBy(updatedArray, data.funds, 'ticker');

    return (
      <React.Fragment>
        {(newQueryLoading || newQuerySearchLoader) ? (
          <CardLoader className="skeleton-bg-transparent" minHeight={208} />
        ): (
          <div style={{ display: 'inherit', width: '100%' }}>
            {(typeof noResultFound !== 'undefined' && noResultFound) ? (
              <NoFunds minHeight={208}>
                <div className="no-results-message">
                  <p>Thematic information for <span>{newQuery}</span> is currently unavailable.</p>
                  <p>Please try another thematic query or pick from the images below.</p>
                </div>
              </NoFunds>
            ) : (
              <CheckboxGroup
                style={{ display: 'inherit', width: '100%' }}
                value={selectedTickers}
                onChange={this.onChange}
                >
                <div className="funds-list-view-container">
                  <div className={customEdit ? 'show' : 'hide'}>
                    <CustomCard
                      query={query}
                      ticker={ticker}
                      highlights={data.Highlights}
                      customSearchTickerData={customSearchTickerData}
                      tickerLoading={tickerLoading}
                      tickerList={tickerList}
                      selectedTickers={selectedTickers}
                      onChange={this.searchTickerData}
                      deleteCard={deleteCard}
                      CustomCheckbox={Checkbox}
                      />
                  </div>
                  <div className={customEdit ? 'hide' : 'show'}>
                    {resultSet.length > 0 ? (
                      <Slider {...settings}>
                        { resultSet.map((e, i) => {
                          return(
                          <FundCardV2
                            key={`i-${i}`}
                            data={e}
                            query={query}
                            i={i}
                            ticker={ticker}
                            highlights={data.Highlights}
                            Checkbox={<Checkbox  className='p-top-right custom-ant-checkbox' key={e.ticker} value={e.ticker} />}
                            />
                        )})}
                      </Slider>
                    ) : (
                      <NoFunds minHeight={208} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className="no-results-message">
                          <p>No funds were found for <span>{newQuery}</span></p>
                        </div>
                      </NoFunds>
                    )}
                  </div>
                </div>
              </CheckboxGroup>
            )}
          </div>

        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ buildportfolio, loading }) => ({
  buildportfolio,
  newQueryLoading: createLoadingSelector(['SEARCH_NEW_QUERY'])({ loading }),
})

const mapDispatchToProps = {

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListView);

import React, { Component } from 'react'
import IndexPage from './Pages/IndexPage'
import ContentTable from './Pages/ContentTable.js'
import ExecutiveSummary from './Pages/ExeSummery'
import Performance from './Pages/Performance'
import Appendix from './Pages/Appendix'
import ErrorBoundary from 'components/shared/ErrorBoundary'
// import { createAPIInstance } from '../../../apis/api'
import { GlossaryPage1 } from '../PortfolioReportV2/sf/Glossary'
import AssetAllocation from './Pages/AssetAllocation'
import { PortfolioContextProvider } from './PortfolioContext'

import { getUploadedPortfolioStats } from '../AccountsPage/utils'

// import { haveHTMLData } from 'Utils';
import moment from 'moment';

import { fetchReportData, fetchRatio } from '../PortfolioOverviewReport/dataService'

class PdfPage extends Component {
	state = {
		glideChart: null,
		appendix: null,
		disclosures: '',
		resultSection: null,
		portfolioValue: 0,
		ratio: null,
		bondValue1: 0,
		equityValue: 0,
		thematicValue: 0,
		advisory_fees: 0,
	}

	async componentDidMount() {
		const query = new URLSearchParams(this.props.location.search)
		const rid = query.get('id')
		//debugger
		if (!rid) return
		// let {
		// 	glideChart,
		// 	appendix,
		// 	disclosures,
		// 	resultSection,
		// 	selectedBenchmark,
		// 	portfolioValue,
		// 	ratio,
		// 	logo,
		// 	bondValue1,
		// 	equityValue,
		// 	thematicValue,
		// 	advisory_fees
		// } = await fetchReportData(rid).then(reportData => reportData.message !== 'No Data Present' && JSON.parse(reportData[0].json))
		// let asset_alloc_current = glideChart.asset_alloc_current
		// let { chart3yr, asset_alloc } = await fetchRatio(ratio).then(data => {
		// 	// console.log(asset_alloc)
		// 	this.setState({
		// 		glideChart,
		// 		appendix,
		// 		disclosures,
		// 		resultSection,
		// 		selectedBenchmark,
		// 		portfolioValue,
		// 		ratio: chart3yr,
		// 		ratioString: ratio,
		// 		asset_alloc_current,
		// 		asset_alloc,
		// 		logo,
		// 		bondValue1,
		// 		equityValue,
		// 		thematicValue,
		// 		advisory_fees,
		// 	}, () => {
		// 		// console.log(this.state)
		// 		document.body.classList.add('builder-report')
		// 	})
		// })
		fetchReportData(rid).then(reportData => {
			if (reportData && reportData.length && reportData[0].json && reportData.message !== 'No Data Present') {
				let parseData = JSON.parse(reportData[0].json)
				console.log('parseData ->', parseData);
				let {
					ratio,
					glideChart,
					resultSection,
					portfolioValue,
					selectedBenchmark,
					portfolioBenchmark,
				} = parseData;
				// let benchMark = ["SPY", "ACWI"].includes(parseData.portfolio.selectedBenchmark) ? "100/0" : (parseData.portfolio.selectedBenchmark === "AGG") ?  "0/100" :  parseData.portfolio.selectedBenchmark;
				fetchRatio(ratio).then(data => {
					console.log('data --> ', data);
					const benchMarkReturns = data && data['returns'] && data['returns'].length ? data['returns'] : null;
					console.log('benchMarkReturns -->', benchMarkReturns)
					const portfolioStats = getUploadedPortfolioStats([data], '', portfolioValue, benchMarkReturns);
					console.log('Comparison PortfolioStats -> ', portfolioStats)
					this.setState({
						...parseData,
						benchMarkReturns,
						portfolioBenchmark,
						// ratio: chart3yr,
						ratioString: ratio,
						rationString: selectedBenchmark,
						// asset_alloc_current: glideChart.asset_alloc_current,
						comparePortfolio: { ...data, ...portfolioStats },
						basePortfolio: resultSection && resultSection.analyticsData ? resultSection.analyticsData : glideChart,
					},
					() => {
						document.body.classList.add('portfolio-reports-v2');
					});
				});
			}
		});
	}

	componentWillUnmount() {
		document.body.classList.remove('builder-report')
	}

	render() {
		let { glideChart, appendix, disclosures, selectedBenchmark, portfolioValue, ratioString, asset_alloc, logo, bondValue1, equityValue, thematicValue, advisory_fees } = this.state
		let buillderFeedDate = appendix && appendix.find(k => k.feed_date)
		let createdDate = (buillderFeedDate && buillderFeedDate.feed_date) ? moment(buillderFeedDate.feed_date).format('MM/DD/YYYY') : moment(new Date().toLocaleString("en-US", { timeZone: "America/New_York" })).format('MM/DD/YYYY')
		return (
			<ErrorBoundary>
				<PortfolioContextProvider value={this.state}>
					{this.state.glideChart ? <div className="container h-100">
						<IndexPage logo={logo} disclosures={disclosures} createdDate={createdDate} />
						<ContentTable logo={logo} />
						<ExecutiveSummary
							{...glideChart}
							logo={logo}
							pv={portfolioValue}
							createdDate={createdDate}
							advisoryFees={advisory_fees}
						/>
						<Performance
							logo={logo}
							// ratio36={ratio}
							defaultYears={'pfRet3y'}
							ratioString={ratioString}
							createdDate={createdDate}
							// data={resultSection.analyticsData}
						/>
						<AssetAllocation
							logo={logo}
							ratioString={ratioString}
							// data={resultSection.analyticsData}
							selectedBenchmark={selectedBenchmark}
							// current={asset_alloc_current}
							benchmark={asset_alloc}
							bondValue1={bondValue1}
							equityValue={equityValue}
							thematicValue={thematicValue}
						/>
						<Appendix
							logo={logo}
							fundList={appendix} />
						<GlossaryPage1 />
					</div> : null}
				</PortfolioContextProvider>
			</ErrorBoundary>
		)
	}
}

export default PdfPage

import React from 'react';
import { connect } from 'react-redux';
import { Input } from 'antd';
import _ from 'lodash';
import { SearchOutlined } from '@ant-design/icons';
import { ScoreAttrs, AdditionalScoreAttrs, ActiveCalcScoreAttrs } from 'DataSet';
import { setCustomCalcScoreAttrs } from 'actions/weights';

class SelectorName extends React.Component {
	constructor(props) {
    super(props)
    this.state = {
      customCalculators: this.getCustomCalculators(),
      selectorName: '',
    	error: '',
    }
  }

  componentDidMount() {
    const { selectedCalculator } = this.props;
    if(selectedCalculator === 'create custom selector'){
      const selectorName = this.props.selectorName || '';
      if(!selectorName){
        this.props.setGenSelectorError('');
      }
      this.props.setSelectorName(selectorName);
      this.setState({ selectorName });
    }else{
      this.setState({
        selectorName: selectedCalculator,
      })
    }
  }

  getCustomCalculators = () => {
  	let out = Object.keys(this.props.customCalculators);
  	out = out.map(item => item.toLowerCase());
  	return out;
  }

  changeSelectorName = (e) => {
  	const { customCalculators } = this.state;
  	let inputText = this.getFormattedText(e.target.value), selectorName = e.target.value, error = '';
    const defaultCalculators = ['magnifi fund selector', 'passive fund selector', 'active fund selector', 'active fund screener'];
  	const namesToBeAvoided = [...customCalculators, ...defaultCalculators];
    if(inputText === 'create custom selector'){
  		error = '*You cannot use this as a selector name. Please pick a new name.';
  	}else if(namesToBeAvoided.includes(inputText)) {
  		error = '*You already have another selector with the same name. Please pick a new name.';
  	}else if(inputText.length > 30){
			error = '*Selector name should not exceed 30 characters.';
		}else{
  		selectorName = e.target.value;	
    }
    this.props.setGenSelectorError(error);
  	this.props.setSelectorName(selectorName);
  	this.setState({
      selectorName: e.target.value,
      error,
    });
  }

  getFormattedText = (text) => {
    let formattedText = text;
    if(text){
      formattedText = formattedText.toLowerCase().trim();
    }
    return formattedText;
  }

  getCustomCalculators = () => {
  	let out = Object.keys(this.props.customCalculators);
  	out = out.map(item => item.toLowerCase());
  	return out;
  }

  render() {
  	const { selectorName, error } = this.state;
    return (
      <React.Fragment>
      	<div className="selector-name-wrapper">
		      <div className="selector-name">
            {this.props.editSelector ? (
              <span>
                {selectorName}
              </span> ) : (
              <>
    		      	<Input
    		      		placeholder="Name your selector"
    		      		bordered={false}
    		      		onChange={this.changeSelectorName}
                  value={selectorName}
    		      	/>
    		      	<i className="fa fa-pencil"/>
              </>
            )}
		      </div>
		      {this.props.showError && (
            <div className="selector-error">
              {error}
            </div>
          )}
	      </div>
	    </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    selectedCalculator: state.weights.selectedCalculator,
		customCalculators: state.weights.customCalculators,
		customCalculatorAttrs: state.weights.customCalculatorAttrs,
  }
}

export default connect(
  mapStateToProps,
)(SelectorName);
import React, { Component, Fragment } from 'react'

const NotApplicable = () => {
  return (
    <div className="siw-1-notapp-status text-center">
      <div>
        <img
          src="https://discover.magnifi.com/assets/images/notapplicable_icon.svg"
          onError="https://discover.magnifi.com/siw-assets/images/notapplicable_icon.svg"
        />
      </div>
      <div className="siw-1-notapp-status__text">N.A.</div>
    </div>
  )
}

export default NotApplicable

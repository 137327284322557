import React from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  Card,
  CardText,
  CardBody
} from 'reactstrap'
import { sendFeedback } from '../../apis/feedback'
import qs from 'query-string'
import $ from 'jquery'
//import { , , Label, Input, FormText } from 'reactstrap'

export const FeedbackButtonStickToBottom = props => {
  return <FeedbackButton />
}

class FeedbackButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: true,
      isActive: false,
      isOnFinal: false,
      feedbackMessage: ''
    }

    this.toggle = this.toggle.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleMessageChange = this.handleMessageChange.bind(this)

    this.inRef = React.createRef()
    this.bw = document.body.clientWidth
  }

  handleMessageChange(event) {
    this.setState({ feedbackMessage: event.target.value })
  }

  handleFormSubmit(event) {
    event.preventDefault()

    if (this.state.feedbackMessage.length > 0) {
      console.log(
        sendFeedback(qs.stringify({ feedback: this.state.feedbackMessage }))
      )

      this.setState({ isOnFinal: true }, () => {
        setTimeout(() => {
          this.toggle(false)
          setTimeout(() => {
            this.setState({ isOnFinal: false })
          })
        }, 5000)
      })
    }
  }

  componentDidMount() {
    document.body.classList['remove'](
      'modal-open'
    )

    document.body.classList[this.state.isActive ? 'add' : 'remove'](
      'modal-open'
    )
    document.body.classList[this.state.isActive ? 'add' : 'remove'](
      'modal-open-fb'
    )
    $('.feedback-backdrop').click(() => this.toggle(!this.state.isActive)) // hack as bockdrop click not working
  }

  componentWillUnmount() {
    $('.feedback-backdrop').unbind('click', this.toggle)
  }

  toggle(isActive) {
    this.setState({ isActive: isActive }, () => {
      document.body.classList[isActive ? 'add' : 'remove']('modal-open')
      document.body.classList[isActive ? 'add' : 'remove']('modal-open-fb')
      console.log(document.body.clientWidth, this.bw)
      console.log(this.inRef.current)
      this.inRef.current.style.paddingLeft = `${
        this.state.isActive ? document.body.clientWidth - this.bw : 0
      }px`
    })
  }

  render() {
    // override modal dialog close button, just hide it.
    const closeBtn = <span />

    const size = this.state.isActive ? 'full' : 'min'

    return (
      <React.Fragment>
        <Modal
          id="feedback-modal"
          isOpen={this.state.isOpen}
          toggle={() => this.toggle(!this.state.isActive)}
          backdrop={true}
          className={'modal-lg'}
          modalClassName={'feedback ' + size}
          contentClassName="bg-ssf-blue-d2"
          backdropClassName="feedback-backdrop"
          innerRef={this.inRef}
          zIndex={1060}
        >
          <ModalHeader
            className="bg-ssf-blue-d4 text-white lh-100 border-0 d-flex justify-content-center font-one"
            onClick={() => this.toggle(!this.state.isActive)}
            toggle={() => this.toggle(!this.state.isActive)}
            close={closeBtn}
            tag="h4"
          >
            <i className="fas fa-comment-alt mr-3" />
            <span className="font-weight-bold">Feedback</span>
          </ModalHeader>
          <ModalBody className="bg-ssf-gray-l7 font-one">
            {this.state.isOnFinal ? (
              <FeedbackThanks />
            ) : (
              <FeedbackForm
                onSubmit={this.handleFormSubmit}
                onFeedbackMessageChange={this.handleMessageChange}
                value={this.state.feedbackMessage}
              />
            )}
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

const FeedbackForm = props => (
  <Form onSubmit={props.onSubmit}>
    <FormGroup>
      <Input
        type="textarea"
        name="feedbackMessage"
        value={props.feedbackMessage}
        onChange={props.onFeedbackMessageChange}
        id="feedbackMessage"
        placeholder="Please share your thoughts, views and suggestions to make Magnifi work better for you."
        style={{ resize: 'none' }}
      />
    </FormGroup>
    <Button
      type="submit"
      onClick={props.onSubmit}
      color="select"
      className="btn h3 text-uppercase font-weight-bold p-3 mb-0 ml-3"
    >
      Submit
    </Button>
  </Form>
)

const FeedbackThanks = props => (
  <Card>
    <CardBody>
      <CardText>
        Thank you for your feedback.
      </CardText>
    </CardBody>
  </Card>
)

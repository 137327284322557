import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { isEmpty, uniqBy } from 'lodash'
// import { Row, Col } from 'reactstrap';
import { Row, Col } from 'antd'

import { createLoadingSelector } from 'selectors/loading-selector'
import { logout } from 'actions/auth'
import { fetchProfile } from 'actions/profile'
import { setGlobalState } from 'actions/global'
import { setProfileState, subscriptionPlan } from 'actions/profile'
import {
  setFundsState,
  refreshFundsState,
  updateFundsData,
  searchQueryRequest,
  updateSelFundsInCrd,
  getSimilarSearchListRequest
} from 'actions/discovery'
import {
  setSFMState,
  setSFMCatData,
  setSFMItems,
  scoreAttrsHandler,
  trackFundsCompareOrder,
  updateReportForFundTracking,
  updateSFMTrackYear,
  deleteAllSFMItemTickersRequest,
  removeSFMItemTickersRequest,
  addSFMItemTickersRequest,
  clearFundsTracking,
  saveBgDownload,
  sfmRealTimePriceSocket,
} from 'actions/sfm'
import { setBuildPortfolioState } from 'actions/buildportfolio'
import { refreshWeightState } from 'actions/weights'
import { getApexAccountDetailsRequest } from 'actions/accounts'
import SearchCard from './SearchCard'
import {
  ReservedRoutes,
  DateRange,
  optWeightList
} from 'DataSet'
import {
  addEventToAnalytics,
  nameToDateRange,
  monthToDate,
  fixedValue,
  changeLogo
} from 'Utils'
import { calcCombineStatsWeighted } from 'Stats'

import Overlay from 'components/Overlay'
import CustomAlert from 'components/CustomAlert'
import TifinWealthMessage from 'components/TifinWealthMessage'
// import { PrimaryButton } from 'shared-components'
import { CHECK_QUICK_TRADE } from 'layouts/utils'

import SideBar from './SideBar'
import Content from './Content'
import NoResult from './Elements/NoResult'
import Spinner from './Elements/Spinner'
import AccountConfirmed from './Elements/Registrations/AccountConfirmed'
// import TermsNConditions from './Elements/Registrations/TermsNConditions'
import PaymentStatusModal from './Elements/PaymentStatus/PaymentStatusModal'
import Survey from '../Survey'
import SpoofSfMain from './Elements/SpoofSfModal'
import { Loader } from './Elements/Loader'
import AddDob from './Elements/AddDob';
import SFM from '../SFM'
import QuickTradeModal from '../QuickTrade'
import Confirmation from '../SFM/ConfirmTab/Confirmation'
import { getQueryItems } from 'layouts/utils'
import { Spinner as NormalSpinner } from '../SearchResultsV2/Elements/Loader'

import JoinModal from '../LandingPage/JoinPop/JoinPop'

const classNames = require('classnames')
let shareCombineState, shareCompareState = null;

class SearchResults extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLogin: false,
      setCookie: false,
      premiumModal: false,
      alertMessage: false,
      range: { return: 'active', risk: 'active' },
      confirm: false,
      justTrackFundsState: props.sfmTrackFundsState || [],
      combineStats: [],
      dateRange: { start: moment(DateRange.start), end: moment(DateRange.end) },
      dateRangeSelected: {},
      data: props.fundsData,
      selectedCards: props.selectedCards,
      portfolioBuilderCount: 0,
      showJoinModal: true,
      dobModal: false
    }
    this.modalToggle = this.modalToggle.bind(this)
    this.SpoofModalToggle = this.SpoofModalToggle.bind(this)
    this.viewHandler = this.viewHandler.bind(this)
    this.selectHandler = this.selectHandler.bind(this)
    this.toggleSfmModalFromSelector = this.toggleSfmModalFromSelector.bind(this)
    this.toggleSfmModalFromSelector = this.toggleSfmModalFromSelector.bind(this)
    this.onAlertDismiss = this.onAlertDismiss.bind(this)
  }

  componentDidMount() {
    document.body.style.paddingTop = '0'
    document.body.classList.remove('ht0')
    document.body.classList.add('main', 'ht5', 'no-scroll')
    if (this.props.loggedIn) {
      changeLogo().then(res => this.props.setProfileState({ logo: res }))
      this.props.fetchProfile()
    } else {
      this.props.setProfileState({
        logo: '/assets/images/MagnifiLogo/Magnifi_Logo_Charcoal_RGB.png'
      })
    }

    if (this.props.useremail && this.props.firstName && this.props.lastName) {
      if (window.heap) {
        window.heap.identify(this.props.useremail)
        window.heap.addUserProperties({
          'First Name': this.props.firstName,
          'Last Name': this.props.lastName
        })
      }
      window.addkrakenUser &&
        typeof window.addkrakenUser === 'function' &&
        window.addkrakenUser({
          email: this.props.useremail,
          firstName: this.props.firstName,
          lastName: this.props.lastName
        })

      // NOTE: Segment Script
      // ------------------------------------------------------------------------------------------
      if (window.analytics && window.analytics.identify && typeof window.analytics.identify === 'function') {
        // console.log('---- SearchResults Segment ----');
        window.analytics.identify(this.props.useremail, {
          name: this.props.firstName + ' ' + this.props.lastName,
          firstName: this.props.firstName,
          from: 'Search',
          lastName: this.props.lastName,
          email: this.props.useremail,
          user_type: 'Advisor',
        });
      }
      // ------------------------------------------------------------------------------------------
    }
    this.executeGlobalSurvey()

    // auth-type means user is logged in
    this.setState({
      showJoinModal:
        localStorage.getItem('auth-type') === null &&
        localStorage.getItem('first-time-user') === null &&
        process.env.REACT_APP_SUB_DOMAIN === 'retail'
    })
  }

  componentDidUpdate(prevProps) {
    if (!this.props.loggedIn) {
      this.executeGlobalSurvey()
    }
    if (this.props.discovery.query !== prevProps.discovery.query) {
      const { setFundsState } = this.props
      setFundsState({
        filterAttrs: {}
      })
    }
    if (
      this.props.loadPercent == 100 &&
      this.props.loadPercent != prevProps.loadPercent &&
      this.props.fundsData.length > 0
    ) {
      addEventToAnalytics(
        'SEARCH_RESULT_DISPLAYED',
        'SEARCH_RESULT_DISPLAYED',
        'SEARCH_RESULT_DISPLAYED',
        { query: this.props.discovery.query },
        true
      )
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('main', 'ht5', 'no-scroll')
    // const { refreshFundsState } = this.props;
    // refreshFundsState();
  }

  viewHandler(view, secondaryView) {
    const { setFundsState } = this.props
    setFundsState({
      itemCardView: { view, secondaryView }
    })
  }

  selectHandler(item) {
    //V-2 logic
    // console.log(item);
    const {
      sfmSelectedItemTickers,
      addSFMItemTickersRequest,
      removeSFMItemTickersRequest,
      deleteAllSFMItemTickersRequest,
      funds,
      updateSelFundsInCrd,
    } = this.props
    if (item === undefined || typeof item === 'undefined') {
      // DELETE ALL SFM FUNDS/ITEMS
      console.log('=====> DELETE ALL SFM FUNDS/ITEMS <=====')
      if (deleteAllSFMItemTickersRequest) {
        deleteAllSFMItemTickersRequest()
      }
    } else {
      // NOTE: Step 1: Add item-> Ticker in SFM ->`selectedItemTickers`
      // Step 2: using ticker name SFM look into FUNDS reducer >
      // if ticker already present in funds reducer state then use that data else Call API to fetch ticker data in SFM
      const alreadyPresent = sfmSelectedItemTickers.find(l => l === item.ticker)
      // let updateArr = [...sfmSelectedItemTickers];
      if (alreadyPresent) {
        // un_select logic
        // console.log('-- un_select logic --');
        if (removeSFMItemTickersRequest) {
          removeSFMItemTickersRequest({
            removeTicker: item.ticker
          })
        }
      } else {
        // select logic
        console.log('-- select logic --')
        if (addSFMItemTickersRequest) {
          addSFMItemTickersRequest({
            selectedItemTickers: item.ticker
          })
          addEventToAnalytics(
            'Funds Select',
            'Funds Select',
            'FUNDS_SELECT',
            { query: funds.query, logId: funds.logId, ticker: item.ticker, category: 'advisory_PTC' },
            true
          )
        }
      }
    }
    if (updateSelFundsInCrd) {
      updateSelFundsInCrd(item)
    }
    // this.props.updateSelFundsInCrd(item)
    // window.addKrakenEvent({name: 'Funds Select', body: {query: funds.query, logId: funds.logId, ticker: item.ticker}});
  }

  selectBuilderHandler = (item, clear) => {
    const {
      buildportfolio,
      setBuildPortfolioState,
      // setSFMItems,
      fundsData,
      // sfm
    } = this.props
    if (clear) {
      // item._selected = item._selector ? true : false;
      // item._selector = false;
      item._builder = false
      let filteredBuilderArray = buildportfolio.resultPageCart.filter(
        el => el.id != item.id
      )
      // let filteredSfmArray = sfm.items.filter((el) => el.id != item.id);
      let fundArr = []
      fundsData.forEach(item => {
        if (item._selector) {
          item._selected = true
          fundArr.push(item)
        }
      })
      let obj = { resultPageCart: filteredBuilderArray }
      updateFundsData({
        // updateFundsData how this is still working on Prod, it is not calling from props!
        data: this.props.fundsData
      })
      // setSFMItems({
      //   items: fundArr,
      // });
      this.searchAnalytics(item)
      setBuildPortfolioState(obj)
    } else {
      // item._selected = true;
      item._builder = true
      let itemArr = [...buildportfolio.resultPageCart]
      // let sfmItemArr = sfm.items;
      // if(!item._selector) sfmItemArr.push(item);
      itemArr.push(item)
      let obj = { resultPageCart: itemArr }
      // setSFMItems({
      //   items: sfmItemArr,
      // });
      this.searchAnalytics(item)
      addEventToAnalytics(
        'Add to Builder',
        'Add to Builder',
        'ADD_TO_BUILDER',
        { selected_ticker: item.ticker, category: 'advisory_PTC' },
        true
      )
      setBuildPortfolioState(obj)
    }
  }

  executeGlobalSurvey = () => {
    const { setGlobalState, loggedIn } = this.props
    const plusCounter = localStorage.getItem('plusCounter')
    const counter = localStorage.getItem('counter')
    if (plusCounter !== 'NaN' && plusCounter && (counter === plusCounter) && !loggedIn) { // only set surveyModal: true when user is not loggedIn
      setGlobalState({
        surveyModal: true
      })
    } else if (this.props.replacePremiumWithSurvey) {
      setGlobalState({
        surveyModal: true
      })
    } else {
      setGlobalState({
        surveyModal: false,
        replacePremiumWithSurvey: false
      })
    }
  }

  onSinginButtonClick = () => {
    this.setState({
      showLogin: true
    })
  }

  handleLoginClose = () => {
    this.setState({
      showLogin: false
    })
  }

  setProfileCookie = () => {
    this.setState({ setCookie: true })
  }

  modalToggle = modalToggleState => {
    const { setSFMState } = this.props
    if (modalToggleState === 'add') {
      setSFMState({
        modalToggleState: true,
        modal: true
      })
    } else if (modalToggleState === 'remove') {
      setSFMState({
        modalToggleState: false,
        modal: false,
        autoOpenModal: undefined
      })
    }
  }

  SpoofModalToggle = () => {
    localStorage.setItem('counter', 2)
    localStorage.setItem('plusCounter', 2)
    const {
      setSFMState,
      setGlobalState,
      autoOpenModal,
      surveyModal,
    } = this.props
    if (!surveyModal) {
      setGlobalState({
        surveyModal: true
      })
    }
    if (!autoOpenModal) {
      setSFMState({
        autoOpenModal: true,
        modalToggleState: true,
      })
    }
  }

  toggleSfmModalFromSelector = () => {
    const { loggedIn, selectorCount, setSFMState } = this.props
    if (!loggedIn) {
      this.SpoofModalToggle()
    } else if (selectorCount > 0) {
      this.modalToggle('add')
      setSFMState({
        autoOpenModal: true
      })
    }
  }

  closePremiumModal = () => {
    this.setState({
      premiumModal: !this.state.premiumModal
    })
  }

  openPremiumModal = () => {
    this.setState({
      premiumModal: true
    })
  }

  onAlertDismiss = () => {
    const { setSFMState } = this.props
    setSFMState({ showAlert: false })
  }

  surveyModelOpen = () => {
    const { setGlobalState, loggedIn } = this.props;
    if (!loggedIn) {
      setGlobalState({
        replacePremiumWithSurvey: true
      })
    }
  }

  // SFM RELATED FUNC

  compareStats = (items, scoreAttrs) => {
    const dFun = this.props.sfmTrackFundsState
    dFun.map((j, index) => {
      const f = j.funds.map(jItem => {
        const getFundFromItemlistByTicker = items.filter(
          r => r.ticker === jItem.ticker
        )[0]
        if (
          typeof jItem.score === 'undefined' &&
          getFundFromItemlistByTicker &&
          getFundFromItemlistByTicker._sfStats
        ) {
          const sf = this.calFundsStats(
            getFundFromItemlistByTicker._sfStats,
            getFundFromItemlistByTicker
          )
          return sf // calculate SFSTATS manually
        } else {
          return jItem
        }
      })
      dFun[index].funds = f
    })
  }

  combineStats = (items, scoreAttrs) => {
    const { sfmTrackFundsState } = this.props
    const dFun = JSON.parse(JSON.stringify(sfmTrackFundsState))
    const lastestArr = dFun[dFun.length - 1]
    if (lastestArr && lastestArr.funds) {
      const result = lastestArr.funds.map(jItem => {
        const getFundFromItemlistByTicker = items.filter(
          r => r.ticker === jItem.ticker
        )[0]
        if (
          getFundFromItemlistByTicker &&
          getFundFromItemlistByTicker._sfWtStats
        ) {
          const sf = this.calFundsStats(
            getFundFromItemlistByTicker._sfWtStats,
            getFundFromItemlistByTicker
          )
          return sf
        }
      })
      const COMBINE_FUNDS = result.filter(some => typeof some !== 'undefined') // result;
      lastestArr.funds = COMBINE_FUNDS
      lastestArr.trackCompareOrder = scoreAttrs
      this.setState({
        combineStats: lastestArr
      })
    }
  }

  sortFn = (a, b) => {
    let [_a, _b] = [a._sfStats, b._sfStats]
    if (_a && _b) {
      // descending order
      if (_a.score < _b.score) return 1
      else if (_a.score > _b.score) return -1
    }
    return 0
  }

  combineSortFn = (a, b) => {
    let [_a, _b] = [a._sfWtStats, b._sfWtStats]
    if (_a && _b) {
      // descending order
      if (_a.score < _b.score) return 1
      else if (_a.score > _b.score) return -1
    }
    return 0
  }

  nextsortFn = (_a, _b) => {
    if (_a && _b) {
      // descending order
      if (_a.score < _b.score) return 1
      else if (_a.score > _b.score) return -1
    }
    return 0
  }

  executeData = (
    dateRange,
    isCompareOnly = false,
    printSelection,
    isBackgroundDownload
  ) => {
    const {
      query,
      correctedQuery,
      chartReportData,
      selectedCalculator,
      fundsTicker,
      profile,
      scoreAttrs,
      sfm,
      commonActiveSFMReturnsRange,
    } = this.props
    const itemslist = sfm.items.filter(
      el => el._selector === true && el.vehicle !== 'SHARE'
    )
    chartReportData.allFunds['1 Year Yield'] = chartReportData.allFunds['Dividend Yield']

    if (chartReportData.allFunds && chartReportData.allFunds.funds) {
      chartReportData.allFunds.funds = chartReportData.allFunds.funds.filter(
        el => el.type !== 'SHARE'
      )
    }
    if (
      chartReportData.initialAllFunds &&
      chartReportData.initialAllFunds.funds
    ) {
      chartReportData.initialAllFunds.funds = chartReportData.initialAllFunds.funds.filter(
        el => el.type !== 'SHARE'
      )
    }
    if (chartReportData.selectedFunds) {
      chartReportData.selectedFunds = chartReportData.selectedFunds.filter(
        el => el.type !== 'SHARE'
      )
    }

    const logo = profile.custom_report_logo ? profile.custom_report_logo : ''
    const benchmark = profile.mpt_benchmark ? profile.mpt_benchmark : 'CATEGORY' // got deleted WHY ?
    const attrsWithWeight = scoreAttrs.filter(item => item.weight > 0)
    const formattedSelectedFunds = this.getFormattedFundsForCompare(itemslist)
    const compareFund = formattedSelectedFunds.filter(g => g.ticker).sort(this.nextsortFn)
    const sortDescFunds = JSON.parse(JSON.stringify(compareFund))
    const defaultAttrs = this.props.customCalculatorAttrs.filter(
      item => item.default
    )
    const allAttrs = scoreAttrs.concat(defaultAttrs)
    const compare = [
      {
        attrs: attrsWithWeight.map(item => {
          return item.name.toLowerCase()
        }),
        funds: uniqBy(sortDescFunds, 'ticker'),
        // date,
        attrsWithScore: attrsWithWeight.map(item => {
          return { name: item.name, weight: item.weight }
        }),
        selectedCalculator
      }
    ]
    let combine = {}
    if (!isCompareOnly && shareCombineState) {
      const currentPFSelection = shareCombineState.opts
      // const selectedPortfolio = shareCombineState.portfolios.filter((item) => item.code === currentPFSelection.optPf);
      const selectedPortfolio = shareCombineState.portfolios.filter(
        item => item.code === currentPFSelection.optPf
      ).length
        ? shareCombineState.portfolios.filter(
          item => item.code === currentPFSelection.optPf
        )
        : shareCombineState.portfolios2.filter(
          item => item.portfolio_name === currentPFSelection.optPf
        )
      const selectedDate = shareCombineState.returnsChartDateRange || ''
      const selectedFunds = JSON.parse(
        JSON.stringify(
          shareCombineState.items.filter(el => el.vehicle !== 'SHARE')
        )
      )
      const sortedCombineFunds = selectedFunds.sort(this.combineSortFn)
      const bestFundsFactors = this.getBestFundsFactors(
        sortedCombineFunds,
        'combine'
      )
      const sortedBestFundsFactors = bestFundsFactors.sort(this.nextsortFn)
      const highestScore = Math.max.apply(
        Math,
        sortedCombineFunds.map(o => o._sfWtStats.score)
      )
      const bestFund = sortedCombineFunds.find(
        item => item._sfWtStats.score === highestScore
      )
      const activeDateRange = this.nameToDateRange(
        { return: 'active', risk: 'active' },
        sortedCombineFunds,
        true
      )
      // const pfDetails = shareCombineState.data.portfolios.find(e => e.code === currentPFSelection.optPf);
      const pfDetails = shareCombineState.data.portfolios.find(
        e => e.code === currentPFSelection.optPf
      )
        ? shareCombineState.data.portfolios.find(
          e => e.code === currentPFSelection.optPf
        )
        : shareCombineState.portfolios2.find(
          e => e.portfolio_name === currentPFSelection.optPf
        )

      combine = {
        all: {
          factor: currentPFSelection.optWt,
          attrs: ['return', 'risk', 'fee'],
          funds: sortedBestFundsFactors,
          dateRange: selectedDate
        },
        best: {
          ticker: bestFund.ticker,
          attrs: ['return', 'risk', 'fee'],
          factors: this.getBestFundFactor(bestFund, pfDetails, activeDateRange)
        },
        portfolio: `${selectedPortfolio[0].name} ${selectedPortfolio[0].desc}`
      }
    }

    // if (commonActiveSFMReturnsRange) {
    //   console.log(commonActiveSFMReturnsRange);
    //   console.log(moment(commonActiveSFMReturnsRange.start).format('DD MMM YYYY'));
    // }

    console.log('dateRange --> ', dateRange)
    const convertDateRange = {};
    if (dateRange) {
      Object.keys(dateRange).forEach(e => {
        console.log('e --> ', e, dateRange[e])
        if (dateRange[e] && dateRange[e].start) {
          convertDateRange[`${e}`] = {
            start: moment(dateRange[e].start).format('MM/DD/YYYY'),
            end: moment(dateRange[e].end).format('MM/DD/YYYY'),
          }
        }
      });
    }

    console.log('convertDateRange --> ', convertDateRange)

    const reports = {
      search: {
        query,
        correctedQuery,
        funds: fundsTicker.split(','), // e.g: ['IEMG', 'VWO', ...]
        date: new moment().format('MMM DD, YYYY') // e.g: '02-Sep-2019'
      },
      allAttrs,
      dateRange: convertDateRange, // (shareCompareState && shareCompareState.displayYear) ? shareCompareState.displayYear : null,
      // or selectedFunds: ['IEMG', 'VWO', 'EELV']
      selectedFundDetails: chartReportData.selectedFunds,
      allFunds: chartReportData.allFunds,
      disclosures: profile.disclosures || '',//Added disclosures data
      selectedFunds: chartReportData.selectedFunds.map(item => item.ticker), // [{ticker: '',name: '',price: 0.0, sponsor: "", volume: 0, volumnMin: 0, volumnMax: 0, volumnPc: 0 }]
      compare, // NOTE: ORDER MATTER ! {attrs: ['return', 'risk', 'fee'], funds: [{ ticker: 'IEMG', return: 5.18, risk: 17.98, fee: 0.13, score: 100 }, ...]}
      combine,
      isCompareOnly,
      logo,
      benchmark,
      printSelection,
      isBackgroundDownload,
    }
    console.log(reports)
    this.sendReportData(reports)
  }

  getBestFundFactor = (bestFund, pfDetails, dateRange) => {
    const _FactorsArr = optWeightList.map(wt => {
      if (bestFund && pfDetails && wt.value && dateRange) {
        const factorVar = calcCombineStatsWeighted(
          bestFund,
          pfDetails,
          wt.value,
          dateRange
        )
        return {
          factor: factorVar.weight,
          return: parseFloat(factorVar.yRet.toFixed(2)),
          risk: parseFloat(factorVar.risk.toFixed(2)),
          fee: parseFloat(factorVar.fees.toFixed(2))
        }
      }
    })
    return _FactorsArr
  }

  nameToDateRange = (range, items, forReport) => {
    let d = moment.max(items.map(e => monthToDate(e._start)))
    let sDefault = moment(DateRange.start),
      sActive = moment.max(sDefault, d)
    let maxRange = r => ({
      start: r !== 'active' ? sDefault : sActive,
      end: r == 'active' && forReport ? moment() : moment(DateRange.end)
    })

    return {
      return: nameToDateRange(range.return, maxRange(range.return)),
      risk: nameToDateRange(range.risk, maxRange(range.risk))
    }
  }

  sendReportData = reports => {
    // console.log(reports);
    if (reports) {
      const { updateReportForFundTracking } = this.props
      updateReportForFundTracking({
        reports
      })
    }
  }

  SFMOnAlertDismiss = () => {
    const { setSFMState } = this.props
    setSFMState({
      reportPDFDownloadStatus: {
        message: '',
        downloadStatus: ''
      },
      reportPDFDownloadStatusCompareOnly: {
        message: '',
        downloadStatus: ''
      }
    })
  }

  handleClick = e => {
    addEventToAnalytics(
      'Search Result Pagination',
      'Search Result Pagination',
      'SEARCH_RESULT_PAGINATION',
      { pageNumber: e },
      true
    )
    this.props.setFundsState({ currentPage: e, pageLoader: true })
    this.props.searchQueryRequest({ query: this.props.query, page: e })
  }

  prepareDataForReport = data => {
    console.log('prepareDataForReport --> ', data)
    const {
      dateRange,
      isCompareOnly,
      printSelection,
      isBackgroundDownload
    } = data
    this.executeData(dateRange, isCompareOnly, printSelection, isBackgroundDownload)
  }

  getBestFundsFactors = (funds, section) => {
    const ff = JSON.parse(JSON.stringify(funds))
    const formattedFunds = ff.map(item => {
      const _sfFundsArr = shareCombineState.items.filter(
        ff => ff.ticker === item.ticker
      )
      const SFStats = item._sfStats ? item._sfStats : _sfFundsArr[0]._sfStats
      const SFWTStats = item._sfWtStats
        ? item._sfWtStats
        : _sfFundsArr[0]._sfWtStats
      const scoreValue =
        section === 'compare'
          ? parseFloat(SFStats.score !== null ? SFStats.score.toFixed(2) : 0)
          : parseFloat(
            SFWTStats.score !== null ? SFWTStats.score.toFixed(2) : 0
          )
      const scoreRoundFigure = fixedValue(scoreValue)
      return {
        ticker: item.ticker,
        return:
          section === 'compare'
            ? parseFloat(SFStats.yRet !== null ? SFStats.yRet.toFixed(2) : 0)
            : SFWTStats.yRetOff
              ? 'Inactive'
              : parseFloat(SFWTStats.yRet.toFixed(2)),
        risk:
          section === 'compare'
            ? parseFloat(SFStats.risk !== null ? SFStats.risk.toFixed(2) : 0)
            : SFWTStats.riskOff
              ? 'Inactive'
              : parseFloat(SFWTStats.risk.toFixed(2)),
        fee:
          section === 'compare'
            ? parseFloat(SFStats.fees !== null ? SFStats.fees.toFixed(2) : 0)
            : SFWTStats.feesOff
              ? 'Inactive'
              : parseFloat(SFWTStats.fees.toFixed(2)),
        score: scoreRoundFigure
      }
    })
    return formattedFunds
  }

  shareCombineState = data => {
    shareCombineState = data
  }

  shareCompareState = (state) => {
    // For report
    shareCompareState = state
  }

  calFundsStats = (SFStats, item) => {
    // console.log(SFStats, item);
    if (typeof SFStats !== 'undefined') {
      const scoreValue = parseFloat(
        SFStats.score !== null ? SFStats.score.toFixed(2) : 0
      )
      const scoreRoundFigure = Math.round(scoreValue)
      return {
        ticker: item.ticker,
        return: parseFloat(SFStats.yRet !== null ? SFStats.yRet.toFixed(2) : 0),
        risk: parseFloat(SFStats.risk !== null ? SFStats.risk.toFixed(2) : 0),
        fee: parseFloat(SFStats.fees !== null ? SFStats.fees.toFixed(2) : 0),
        score: scoreRoundFigure
      }
    } else {
      return {
        ticker: item.ticker,
        return: 0,
        risk: 0,
        fee: 0,
        score: 0
      }
    }
  }

  getFormattedFundsForCompare = funds => {
    const formattedFunds = funds.map(item => {
      const SFStats = item._sfStats
      // console.log(SFStats);
      if (typeof SFStats !== 'undefined') {
        const scoreValue = parseFloat(
          SFStats.score !== null ? SFStats.score.toFixed(2) : 0
        )
        const scoreRoundFigure = fixedValue(scoreValue)
        return {
          ticker: item.ticker,
          return: SFStats.yRet ? parseFloat(SFStats.yRet.toFixed(2)) : null,
          risk: SFStats.risk ? parseFloat(SFStats.risk.toFixed(2)) : null,
          fee: SFStats.fees ? parseFloat(SFStats.fees.toFixed(2)) : 0,
          'premium to nav': SFStats.weight_premium_to_nav
            ? parseFloat(SFStats.weight_premium_to_nav)
            : null,
          'fund flows': SFStats.weight_fund_flows
            ? parseFloat(SFStats.weight_fund_flows)
            : null,
          assets: SFStats.aum ? parseFloat(SFStats.aum) : null,
          volume: SFStats.vol ? parseFloat(SFStats.vol) : null,
          'tracking error': SFStats.trkerror
            ? parseFloat(SFStats.trkerror)
            : null,
          yield: SFStats.weight_yield ? parseFloat(SFStats.weight_yield) : null,
          'bid-ask spread': SFStats.weight_bid_ask
            ? parseFloat(SFStats.weight_bid_ask)
            : null,
          'track record': SFStats.weight_track_record
            ? parseFloat(SFStats.weight_track_record)
            : null,
          trend: SFStats.weight_trend ? parseFloat(SFStats.weight_trend) : null,
          score: scoreRoundFigure < 0 ? 0 : scoreRoundFigure,
          _sfStats: SFStats
        }
      } else {
        return {
          ticker: item.ticker,
          return: 0,
          risk: 0,
          fee: 0,
          'premium to nav': 0,
          'fund flows': 0,
          assets: 0,
          volume: 0,
          'tracking error': 0,
          yield: 0,
          'bid-ask spread': 0,
          'track record': 0,
          trend: 0,
          score: 0,
          _sfStats: SFStats
        }
      }
    })
    return formattedFunds
  }

  getScoreAttrs = scoreAttrs => {
    const { scoreAttrsHandler } = this.props
    scoreAttrsHandler({
      scoreAttrs
    })
  }

  confirmHandler = (confirm, payload) => {
    this.setState({ confirm, confirmPayload: payload })
  }

  searchedCustomTicker = data => {
    // console.log(data)
    let _tickers = data && data.value
    if (_tickers) {
      const {
        sfmSelectedItemTickers,
        addSFMItemTickersRequest,
        removeSFMItemTickersRequest,
        // deleteAllSFMItemTickersRequest,
        funds,
        selectedCards,
        setSFMItems
      } = this.props

      // console.log(JSON.parse(JSON.stringify(selectedCards)));
      if (setSFMItems && selectedCards) {
        setSFMItems({ items: JSON.parse(JSON.stringify(selectedCards)) })
      }

      // NOTE: Step 1: Add item-> Ticker in SFM ->`selectedItemTickers`
      // Step 2: using ticker name SFM look into FUNDS reducer >
      // if ticker already present in funds reducer state then use that data else Call API to fetch ticker data in SFM
      const alreadyPresent = sfmSelectedItemTickers.find(l => l === _tickers)
      // let updateArr = [...sfmSelectedItemTickers];
      if (alreadyPresent) {
        // un_select logic
        // console.log('-- un_select logic --');
        if (removeSFMItemTickersRequest) {
          removeSFMItemTickersRequest({
            removeTicker: _tickers
          })
        }
      } else {
        // select logic
        // console.log('-- select logic --')
        if (addSFMItemTickersRequest) {
          addSFMItemTickersRequest({
            selectedItemTickers: _tickers
          })
          if (funds.query) {
            addEventToAnalytics(
              'SFM CUSTOM SEARCHED TICKER',
              'SFM CUSTOM SEARCHED TICKER',
              'SFM_CUSTOM_SEARCHED_TICKER',
              { logId: funds.logId, ticker: _tickers },
              true
            )
          }
        }
      }
    }
  }

  handleToggleJoinModal = () => {
    // set this when the user first visit the website
    localStorage.setItem('first-time-user', 'false')

    this.setState({
      showJoinModal: !this.state.showJoinModal
    })
  }

  redirectToJoinPage = () => {
    // set this when the user first visit the website
    localStorage.setItem('first-time-user', 'false')
    addEventToAnalytics(
      'Nag Popup Retail',
      'Nag Popup Retail',
      'NAG_POPUP_RETAIL',
      {}
    )
    this.setState({
      showJoinModal: !this.state.showJoinModal
    })
  }

  redirectToAdvisorJoin = () => {
    // set this when the user first visit the website
    localStorage.setItem('first-time-user', 'false')
    addEventToAnalytics(
      'Nag Popup Advisor',
      'Nag Popup Advisor',
      'NAG_POPUP_ADVISOR',
      {}
    )
    const query = sessionStorage.getItem('query')
    window.location.href = `https://app.magnifi.com/securities?query=${query}`
  }

  quickTradeModal = (quickTradeModal) => {
    console.log('==== quickTradeModal ====');
  }

  profileUpdateCallBack = (data) => {
    this.setState({ dobModal: false })
    this.props.fetchProfile('', () => {
      this.props.getSimilarSearchListRequest(this.props.query)
    });
  }

  render() {
    const {
      sfm,
      loggedIn,
      loading,
      fundsData,
      stocksData,
      sponsoredData,
      loadPercent,
      location,
      isTermsAgreed,
      accConfirmedModal,
      surveyModal,
      buildportfolio,
      modalToggleState,
      reportLoader,
      reportLoaderCompareOnly,
      reportPDFDownloadStatus,
      reportPDFDownloadStatusCompareOnly,
      autoOpenModal,
      premiumUser,
      fetchingPremiumDetails,
      magnifiExecuteOrderLoader,
      buyFromURL,
      profileLoading,
      quickTradeFunds,
      quickTradeModalToggle,
    } = this.props;
    const { pathname } = location;
    const cartItems = sfm.items.filter(el => el._selector === true);
    const enabledReservedRoutes = ReservedRoutes.includes(pathname);
    const blurClass = ((modalToggleState || enabledReservedRoutes || quickTradeModalToggle) && !surveyModal) ? 'blur-effect' : '';
    // const SEARCH_QUERY_MISSING = (
    //   isEmpty(getQueryItems(this.props)) ||
    //   ((getQueryItems(this.props) === null) && (!this.props.match.params.id || this.props.match.params.id == ''))
    // );
    // console.log(this.props)
    // console.log(getQueryItems(this.props));
    const SEARCH_QUERY_MISSING = (isEmpty(getQueryItems(this.props)));

    const renderNoQueryDesign = (
      <Overlay
        className="survey-overlay-container"
        wrapperClassName={`overlay-wrap-margin h-100`}
        isBlocked={!loggedIn && surveyModal}
        overlayInnerComponent={
          surveyModal ? <Survey setProfileCookie={this.setProfileCookie} {...this.props} /> : ''
        }
      >
        <SearchCard history={this.props.history} match={this.props.match} />
      </Overlay>
    );

    const surveyFormConfirmation = <Survey setProfileCookie={this.setProfileCookie} {...this.props} />

    let renderModal = ''
    if (loggedIn && isTermsAgreed === 0) {
      // renderModal = <TermsNConditions />
    } else if (loggedIn && accConfirmedModal) {
      renderModal = <AccountConfirmed />
    } else if (window.sessionStorage.getItem('paymentStatus')) {
      renderModal = (
        <PaymentStatusModal
          planName={this.props.planName}
          userEmail={this.props.useremail}
          paymentStatus={window.sessionStorage.getItem('paymentStatus')}
        />
      )
    }

    const contentContainerStyle = SEARCH_QUERY_MISSING ? {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      overflow: 'hidden',
    } : {};

    return (
      <Fragment>
        <TifinWealthMessage />
        {this.state.showJoinModal && process.env.REACT_APP_SUB_DOMAIN === 'retail' && (
          <JoinModal
            securitiesPage
            open={this.state.showJoinModal}
            cancel={this.handleToggleJoinModal}
            handleFirstAction={this.redirectToJoinPage}
            handleSecondAction={this.redirectToAdvisorJoin}
          />
        )}
        {fetchingPremiumDetails && <Loader loading />}
        {sfm.showAlert && (
          <CustomAlert
            className="alert-windown-wrapper"
            color="success"
            onDismissCallback={this.onAlertDismiss}
          >
            {typeof sfm.showAlert === 'string'
              ? sfm.showAlert
              : 'Added to builder successfully'}
          </CustomAlert>
        )}
        {magnifiExecuteOrderLoader && <NormalSpinner />}
        <Row id="contentWrapper" className={`contentWrapper main ${blurClass}`}>
          <Col span={6} id="sideBarContainer" className="sideBarContainer">
            <SideBar {...this.props} addToCart={this.addToCart} surveyFormConfirmation={surveyFormConfirmation} surveyModelOpen={this.surveyModelOpen} />
          </Col>
          <Col
            span={18}
            className="contentContainer"
            style={contentContainerStyle}
          >
            {(SEARCH_QUERY_MISSING) ? (
              renderNoQueryDesign
            ) : (
              <Fragment>
                {(!loading && loadPercent === 100 && !this.props.loader && !profileLoading) ? (
                  <Overlay
                    className="survey-overlay-container"
                    wrapperClassName={`overlay-wrap-margin h-100`}
                    modalToggleState={((modalToggleState || enabledReservedRoutes || quickTradeModalToggle) && !surveyModal)}
                    isBlocked={(!loggedIn && !loading && surveyModal) || (this.state.dobModal && loggedIn && !loading)}
                    overlayInnerComponent={
                      this.state.dobModal ? (
                        <AddDob
                          profile={this.props.profile}
                          profileUpdateCallBack={this.profileUpdateCallBack}
                          dobModalToggle={() => this.setState({ dobModal: !this.state.dobModal })}
                        />
                      ) : (
                        <Survey {...this.props} setProfileCookie={this.setProfileCookie} />
                      )
                    }
                  >
                    {(fundsData.length || stocksData.length || sponsoredData.length) ? (
                      <Content
                        {...this.props}
                        modalToggle={this.modalToggle}
                        SpoofModalToggle={this.SpoofModalToggle}
                        dobModalToggle={() => this.setState({ dobModal: !this.state.dobModal })}
                      />
                    ) : (
                      <NoResult />
                    )}
                  </Overlay>
                ) : (
                  <Spinner
                    loading
                    message
                    loadPercent={loadPercent}
                  />
                )}
              </Fragment>
            )}
          </Col>
        </Row>
        {renderModal}
        <section
          className={classNames(
            'fixed-top bg-ssf-gray-l3 sm topSectionWrapper',
            modalToggleState ? 'blur-effect' : ''
          )}
        >
          {(cartItems.length > 0 && loggedIn) && (
            <SFM
              {...this.props}
              enablePrintReports
              items={cartItems}
              buyFromURL={buyFromURL}
              premiumUser={premiumUser}
              autoOpenModal={autoOpenModal}
              reportLoader={reportLoader}
              reportLoaderCompareOnly={reportLoaderCompareOnly}
              reportPDFDownloadStatus={reportPDFDownloadStatus}
              reportPDFDownloadStatusCompareOnly={reportPDFDownloadStatusCompareOnly}
              builderSelected={buildportfolio.resultPageCart.length}
              // dateRange={this.props.funds.dateRange}
              getScoreAttrs={this.getScoreAttrs}
              modalToggle={this.modalToggle}
              confirmHandler={this.confirmHandler}
              printReport={this.prepareDataForReport}
              shareCombineState={this.shareCombineState}
              shareCompareState={this.shareCompareState}
              onAlertDismiss={this.SFMOnAlertDismiss}
              compareStats={this.compareStats}
              combineStats={this.combineStats}
              selectHandler={this.selectHandler}
              selectBuilderHandler={this.selectBuilderHandler}
              searchedCustomTicker={this.searchedCustomTicker}
            />
          )}
          {(cartItems.length > 0 && !loggedIn) && (
            <SpoofSfMain
              {...this.props}
              items={cartItems}
              modalToggle={this.SpoofModalToggle}
              confirmHandler={this.confirmHandler}
            />
          )}
          {(cartItems.length === 0 && !surveyModal && quickTradeFunds.length > 0 && CHECK_QUICK_TRADE()) && (
            <QuickTradeModal {...this.props} modalToggle={this.quickTradeModal} />
          )}
          {this.state.confirm && (
            <Confirmation
              {...this.props}
              confirmHandler={this.confirmHandler}
              confirmPayload={this.state.confirmPayload}
            />
          )}
        </section>
      </Fragment>
    )
  }
}

const mapStateToProps = ({
  loading,
  discovery,
  auth,
  sfm,
  global,
  router,
  register,
  buildportfolio,
  profile,
  weights,
  accounts,
  quicktrade,
}) => ({
  discovery,
  funds: discovery,
  sfm,
  buildportfolio,
  location: router.location,

  fundsTicker: discovery.ticks,
  fundsData: discovery.data,
  stocksData: discovery.stocks_data,
  sponsoredData: discovery.sponsored_data,
  query: discovery.query,
  correctedQuery: discovery.correctedQuery,
  messages: discovery.messages,
  currentPage: discovery.currentPage,
  chartReportData: discovery.chartReportData,
  commonActiveReturnsRange: discovery.commonActiveReturnsRange,

  loadPercent: global.fundsApiCallPercent,
  surveyModal: global.surveyModal,
  replacePremiumWithSurvey: global.replacePremiumWithSurvey,

  isTermsAgreed: auth.termsAgreed,
  isNewUser: auth.newUser,
  loggedIn: auth.loggedIn,
  loggingIn: auth.loggingIn,
  planName: auth.user.planName,
  accConfirmedModal: auth.accConfirmedModal,
  useremail: auth.user.email,
  firstName: auth.user.firstName,
  lastName: auth.user.lastName,
  logo: profile.logo,
  registerSuccess: register.registerSuccess,

  // isRedirectFromHeaderButtons: sfm.isRedirectFromHeaderButtons,

  scoreAttrs: weights.scoreAttrs,

  sfmToggle: sfm.modalToggleState,
  modalToggleState: sfm.modalToggleState,
  selectedCards: sfm.items,
  clearAllFunds: sfm.clearAllFunds,
  sfmTrackFundsState: sfm.trackFundsCompareOrder,
  sfmSelectedItemTickers: sfm.selectedItemTickers,
  resetTrackFundsState: sfm.resetTrackFundsState,
  reportLoader: sfm.reportLoader,
  reportLoaderCompareOnly: sfm.reportLoaderCompareOnly,
  reportPDFDownloadStatus: sfm.reportPDFDownloadStatus,
  reportPDFDownloadStatusCompareOnly: sfm.reportPDFDownloadStatusCompareOnly,
  sfmTrackYear: sfm.sfmTrackYear,
  autoOpenModal: sfm.autoOpenModal,
  buyFromURL: sfm.buyFromURL,
  selectorCount:
    sfm.items.filter(k => k._selector || k._selected).length ||
    sfm.selectorCount,
  commonActiveSFMReturnsRange: sfm.commonActiveSFMReturnsRange,

  profile: profile.profile,
  selectedCalculator: weights.selectedCalculator,
  customCalculatorAttrs: weights.customCalculatorAttrs,

  magnifiExecuteOrderLoader: discovery.magnifiExecuteOrderLoader,

  apexBalanceData: accounts.apexBalanceData,

  quickTradeModalToggle: quicktrade.modalToggle,
  quickTradeFunds: quicktrade.funds,

  fetchingApexAmountBalance: createLoadingSelector(['GET_APEX_AMOUNT_DETAILS'])({ loading }) || false,
  fetchingPremiumDetails: createLoadingSelector(['PREMIUM_FEATURE_ACCESS'])({ loading }) || false,
  loading: createLoadingSelector(['SEARCH_QUERY'])({ loading }) || discovery.actions,
  activateButtonLoading: createLoadingSelector(['ACTIVATE_SIMILAR_SEARCH'])({ loading }),
  profileLoading: createLoadingSelector(['PROFILE'])({ loading }),
})

const mapDispatchToProps = {
  logoutHandler: logout,
  setGlobalState,
  setProfileState,
  fetchProfile,
  subscriptionPlan,
  searchQueryRequest,

  setSFMState,
  setSFMCatData,
  setSFMItems,
  scoreAttrsHandler,
  trackFundsCompareOrder,
  updateReportForFundTracking,
  saveBgDownload,
  updateSFMTrackYear,
  deleteAllSFMItemTickersRequest,
  removeSFMItemTickersRequest,
  addSFMItemTickersRequest,
  clearFundsTracking,

  updateSelFundsInCrd,
  // getChartDataForAllFunds,
  updateFundsData,
  setFundsState,
  refreshFundsState,

  refreshWeightState,

  setBuildPortfolioState,
  getApexAccountDetailsRequest,
  sfmRealTimePriceSocket,

  getSimilarSearchListRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults)

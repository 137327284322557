import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button } from 'antd';
import { checkTickerRequest } from 'actions/portfolio';
import { checkBuilderTickerRequest } from 'actions/buildportfolio';
import { PrimaryButton } from 'shared-components'
import DataList from './DataList'
import InvalidTickerTable from './InvalidTickerTable'
import UnsupportedTickerTable from './UnsupportedTickerTable'

class CSVPreviewUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: props.visible || true,
      data: props.data || [],
      errorFields: [],
      tableHeaderArr: [],
      tickerNotExist: props.tickerNotExist || [],
      showAllInvalidTickers: false,
      submitDisabled: false
    }
  }

  componentDidMount() {
		const { data, tickerNotExist } = this.props;
    // if (tickerNotExist) {
    //   this.setState({
    //     tickerNotExist,
    //   });
    // }

		if (data) {
      // if (this.state.tickerNotExist.length) {
      //   data.map((item, key) => {
      //     const found = this.state.tickerNotExist.find((g) => g === item.ticker);
      //     if (item.ticker === found) {
      //       item.hasError = true;
      //       item.errorMsg = 'Sorry ticker is not present in db';
      //     }
      //   });
      //   this.setState({
      //     errorFields: ['Sorry ticker is not present in db'],
      //   });
      // } else {
      //   this.setState({
      //     errorFields: [],
      //   });
      // }
			data.map((item, key) => {
				item.key = key.toString();
			});
			this.setState({
				data: data,
			}, () => {
        if (data.length > 0) {
          const obj = data[0];
          const tableHeaderArr = this.getSortedTableHeader(obj);
          this.setState({
            tableHeaderArr
          })
        }
      });
		}
    if(!this.state.data.length) {
      const checkInvalidTickers = this.props.invalidTickers.filter(item => item.validTicker);
      this.setState({
        submitDisabled: checkInvalidTickers.length == 0
      })
    }
	}

	componentWillReceiveProps(newProps) {
		if (newProps.data && newProps.data !== this.state.data) {
			newProps.data.map((item, key) => {
				item.key = key.toString();
			});
			this.setState({
				data: newProps.data,
			}, () => {
        if (this.state.data.length > 0) {
          const obj = this.state.data[0];
          const tableHeaderArr = this.getSortedTableHeader(obj);
          this.setState({
            tableHeaderArr
          })
        }
      });
		}

    // if (newProps.tickerNotExist && newProps.tickerNotExist.length !== this.state.tickerNotExist.length) {
    //   if (newProps.tickerNotExist.length === 0) {
    //     newProps.data.map((item, key) => {
    //       if (item.hasError) {
    //         delete item.hasError;
    //         delete item.errorMsg;
    //       }
    //     });
    //     // console.log(newProps.data);
    //     this.setState({
    //       data: newProps.data,
    //       tickerNotExist: newProps.tickerNotExist,
    //       errorFields: [],
    //     }, () => {
    //       // console.log(this.state.data);
    //     });
    //   } else {
    //     newProps.data.map((item, key) => {
    //       if (item.ticker === newProps.tickerNotExist.find((g) => g === item.ticker)) {
    //         item.hasError = true;
    //         item.errorMsg = 'Sorry ticker is not present in db';
    //       }
    //     });
    //     this.setState({
    //       data: newProps.data,
    //       tickerNotExist: newProps.tickerNotExist,
    //       errorFields: ['Sorry ticker is not present in db'],
    //     });
    //   }
    // }
    if(!this.state.data.length){
      const checkInvalidTickers = newProps.invalidTickers.filter(item => item.validTicker);
      this.setState({
        submitDisabled: checkInvalidTickers.length == 0
      })
    }
	}

  getSortedTableHeader = (obj) => {
    const headers = ['ticker','weight(%)','values','realTimePrice'];
    const objKeys = Object.keys(obj);
    let tableHeaderArr1 = [];
    let tableHeaderArr2 = [];
    objKeys.forEach(item => {
      if(headers.includes(item)){
        tableHeaderArr1.push(item);
      }else{
        if(item !== 'key' && item !== 'date' && item !== 'price' && item !== 'amount' && item !== 'weight' && item !== 'validTicker' && item !== 'shares' && item !== 'original_weight' && item !== 'invalid' && item !== 'weight_calculated_format' && item !== 'format_value' && item !== 'invalidType') {
          tableHeaderArr2.push(item);
        }
      }
    })
    const sortedHeaderArr = tableHeaderArr1.sort(function (a,b){
      return headers.indexOf(a) - headers.indexOf(b)
    });
    let tableHeaderArr = [...sortedHeaderArr, ...tableHeaderArr2];
    return tableHeaderArr;
  }

	toggleModalChange = () => {
		this.setState({ visible: false});
	}

	onModalClose = () => {
		this.setState({ visible: false, data: [] }, () => {
			const { onModalClose, clearData } = this.props;
			if (clearData) {
				clearData();
			}
			if (onModalClose) {
				onModalClose(this.state.visible);
			}
		});
	}

	onClickOk = () => {
		const { onClickOk } = this.props;
		if (onClickOk) {
			onClickOk(this.state.data);
		}
	}

  getUpdatedData = (data) => {
    // console.log(data);
    if (data) {
      this.setState({
        data,
      }, () => {
        const { getDataList } = this.props;
        if (getDataList) {
          getDataList(this.state.data);
        }
      });
    }
  }

  errorHandle = (fields) => {
    this.setState({
      errorFields: fields,
    });
  }

  checkTicker = (ticker, callback) => {
    const { checkTicker } = this.props;
    if (checkTicker && ticker && callback) {
      return checkTicker(ticker, () => {
        callback(this.state.tickerNotExist)
      });
    }
  }

  showAllInvalidTickersToggle = (val) => {
    this.setState({
      showAllInvalidTickers: val
    })
  }

  render() {
    const { loading, disclaimerMessage, modalTitle, wrapClassName, width, style, maskStyle, centered, footer, invalidTickers } = this.props;
    const { errorFields, tableHeaderArr, tickerNotExist, showAllInvalidTickers, submitDisabled } = this.state;
    // console.log(invalidTickers);
    const unsupportedTickers = invalidTickers.filter((item) => item.invalidType === 'unsupported');
    const _invalidTickersData = invalidTickers.filter((item) => item.invalidType === 'unrecognized');
    // console.log(unsupportedTickers);
    // console.log(_invalidTickersData);
    // const submitDisabled = errorFields.length;
    return (
      <React.Fragment>
        <Modal
          centered={centered}
          footer={footer}
          width={width}
          title={modalTitle}
          style={{ ...style, display: loading ? 'none' : '' }}
          maskStyle={maskStyle}
          bodyStyle={{ minHeight: 212, display: loading ? 'none' : '' }}
          wrapClassName={`csv-preview-upload ${wrapClassName}`}
          visible={this.state.visible}
          onOk={() => this.onClickOk()}
          onCancel={() => this.onModalClose()}
          closeIcon={<i className="fal fa-times premium-feature-completed-close cp-modal-close" onClick={() => this.onModalClose()}></i>}
        >
          <div className="pf-list-preview-wrapper h-100 fx-clm">
            {_invalidTickersData.length > 0 && (
              <div className={`invalid-ticker-table fx-clm-space-between h-100 ${showAllInvalidTickers ? 'pb-0' : ''}`}
                style={{ display: loading ? 'none' : '' }}
              >
                <InvalidTickerTable
                  dataSource={_invalidTickersData}
                  deleteInvalidTickers={this.props.deleteInvalidTickers}
                  showAllInvalidTickers={showAllInvalidTickers}
                  showAllInvalidTickersToggle={this.showAllInvalidTickersToggle}
                  updateTickerStatus={this.props.updateTickerStatus}
                  validTickers={this.state.data}
                />
              </div>
            )}

            {tableHeaderArr.length > 0 && !showAllInvalidTickers && this.state.data.length > 0 && (
              <div className="valid-ticker-table fx-clm-space-between h-100" style={{ display: loading ? 'none' : '' }}>
                <DataList
                  {...this.props}
                  rowsArr={tableHeaderArr}
                  data={this.state.data}
                  invalidTickers={invalidTickers}
                  errorHandle={this.errorHandle}
                  getUpdatedData={this.getUpdatedData}
                  checkTicker={this.checkTicker}
                />
                {/*<div>
                  <div className="d-flex w-100 justify-content-center">
                    <Button
                      className="btn secondary-btn fill p-1 mr-4"
                      type="button"
                      disabled={submitDisabled || errorFields.length}
                      onClick={() => this.onClickOk()}
                      style={{ minWidth: 120 }}
                    >
                      SUBMIT
                    </Button>
                    <Button
                      className="btn secondary-btn p-1 ml-2"
                      type="button"
                      onClick={() => this.onModalClose()}
                      style={{ minWidth: 120 }}
                    >
                      CANCEL
                    </Button>
                  </div>
                  {disclaimerMessage}
                </div>*/}
              </div>
            )}
            {unsupportedTickers.length > 0 && (
              <div className={`invalid-ticker-table fx-clm-space-between h-100 ${showAllInvalidTickers ? 'pb-0' : ''}`}
                style={{ display: loading ? 'none' : '' }}
              >
                <UnsupportedTickerTable
                  dataSource={unsupportedTickers}
                  deleteInvalidTickers={this.props.deleteInvalidTickers}
                  showAllInvalidTickers={showAllInvalidTickers}
                  showAllInvalidTickersToggle={this.showAllInvalidTickersToggle}
                  updateTickerStatus={this.props.updateTickerStatus}
                  validTickers={this.state.data}
                />
              </div>
            )}
            <div>
              {showAllInvalidTickers && this.state.data.length > 0 ?
                <div className="d-flex w-100 justify-content-center" style={{ paddingTop: 18 }}>
                  <Button
                    className="btn secondary-btn fill p-1 mr-4"
                    type="button"
                    onClick={() => this.showAllInvalidTickersToggle(false)}
                    style={{ minWidth: 120 }}
                  >
                    BACK
                  </Button>
                </div>
                :
                <div className="d-flex w-100 justify-content-center" style={{ paddingTop: 18 }}>
                  <Button
                    className="btn secondary-btn fill p-1 mr-4"
                    type="button"
                    disabled={submitDisabled /*|| errorFields.length*/}
                    onClick={() => this.onClickOk()}
                    style={{ minWidth: 120 }}
                  >
                    SUBMIT
                  </Button>
                  <Button
                    className="btn secondary-btn p-1 ml-2"
                    type="button"
                    onClick={() => this.onModalClose()}
                    style={{ minWidth: 120 }}
                  >
                    CANCEL
                  </Button>
                </div>
              }
              <>{disclaimerMessage}</>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = {
  checkTickerRequest,
  checkBuilderTickerRequest,
}

export default connect(
  null,
  mapDispatchToProps
)(CSVPreviewUpload);

const Spinner = () => {
  return (
    <div className="spinner-wrapper">
      <div className="text-secondary text-center">
        <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5"></i>
      </div>
    </div>
  )
}

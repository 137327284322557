import React from 'react';
import { customColors } from './Utils';

const ScatterCustomLegend = ({data}) => {
  return (
    <div className="scatter-custom-legend-wrapper">
      {data.map((item, index) => {
      	return (
      		<div className="single-legend" key={index}>
      			<span
      				className="legend-dot"
      				style={{      					
      					background: customColors[index%customColors.length],
      				}}
      			>
      			</span>
      			<span
      				className="legend-text"
      			>
      				{item.ticker_short}
      			</span>
      		</div>
      	)
      })}
    </div>
  );
}

export default ScatterCustomLegend;
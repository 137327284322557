import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import DataTable, { createTheme } from 'react-data-table-component';
import { mockData } from '../../PortfolioTable/mockData';
import { clientDetailsRequest } from 'actions/clientportfolioV2';
import { FormatCurrency } from 'Utils';
import { scrollBarCheck } from '../../../Utils';
import orderBy from 'lodash/orderBy';
import { ShowSpinner } from '../../Shared/ShowSpinner';
import { NoDataComponent } from '../../Shared/NoDataComponent';
import { getColViewName } from '../../../Utils';
// import { Button } from 'antd';
// import { LeftOutlined , RightOutlined } from '@ant-design/icons'
class ClientHoldings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      skipDataCheck: false,
      // searchAccTickerIndex:0,
      // qv:{}
    };
  }

  componentDidMount() {
    scrollBarCheck('.cl-holdings-wrapper');
    // this.setState({
    //   searchAccTickerIndex:this.props.searchAccTickerIndex,
    //   qv:this.props.qv
    // })
  }

  // componentDidUpdate(prevProps){
  //   const {searchAccTickerIndex,qv,client_id}=this.props;
  //   if(searchAccTickerIndex!==prevProps['searchAccTickerIndex']){
  //     this.setState({
  //       searchAccTickerIndex:searchAccTickerIndex
  //     })
  //   }
  //   if(qv!==prevProps['qv']){
  //     //this.props.clientDetailsRequest({client_id: client_id,query_var:qv});
  //     this.setState({
  //       qv:qv
  //     })
  //   }
  // }

  getMoreHoldingData = () => {
    this.setState({
      skipDataCheck: true
    })
  }

  getValue = (price, shares) => {
    return (price && shares) ? FormatCurrency(Math.round(price.toFixed(1) * shares), 0, '$', 'unf') : 'NA'
  }

  customSort = (rows, field, direction = 'desc') => {
    return orderBy(rows, field, direction);
  };

  _getWeightPercent = (_row, _total) => {
    if (_row.shares && _row.current_price) {
      let _tickerWeight = (parseFloat(parseFloat(_row.shares).toFixed(2)) * parseFloat(parseFloat(_row.current_price).toFixed(2)));
      let _totalWeight = (parseFloat(parseFloat(_total).toFixed(2)));
      return _totalWeight ? ((_tickerWeight / _totalWeight) * 100).toFixed(2) + '%' : 'NA'
    } else {
      return 'NA'
    }
  }

  _getTotalWeight = (holdings) => {
    const _reducer = (previousValue, currentValue) => {
      if (currentValue.shares && currentValue.current_price) {
        return previousValue + (parseFloat(parseFloat(currentValue.shares).toFixed(2)) * parseFloat(parseFloat(currentValue.current_price).toFixed(2)));
      } else {
        return previousValue + 0
      }
    }
    return holdings.reduce(_reducer, 0)
  }

  render() {
    const { selectedClientDetails: { holdings }, searchResultsData, searchAccTickerIndex, query_var: qv } = this.props;
    const _totalWeight = holdings && holdings.length && this._getTotalWeight(holdings);
    let HoldingsColumns = [
      {
        name: 'Name',
        // selector: 'name',
        cell: row => row.name ? <EllipsisTooltip className='cus-pointer' title={row.name}>{row.name}</EllipsisTooltip> : '-',
        sortable: false,
        grow: 3,
        width: '140px'
      },
      {
        name: 'Ticker/CUSIP',
        cell: row => <EllipsisTooltip className='cus-pointer' title={row.ticker || row.script}>{row.ticker || row.script}</EllipsisTooltip>,
        sortable: false,
        textAlign: 'center',
        width: '100px'
      },
      {
        name: 'Quantity',
        //selector: 'shares',
        cell: row => row.shares ? <EllipsisTooltip className='cus-pointer' title={row.shares}>{parseFloat(row.shares).toFixed(2)}</EllipsisTooltip> : '-',
        sortable: false,
        width: '100px'
      },
      {
        name: 'Price',
        cell: row => row.current_price ? <EllipsisTooltip className='cus-pointer' title={row.current_price}>{FormatCurrency(parseFloat(row.current_price).toFixed(2), 0, '$', 'unf')}</EllipsisTooltip> : 'NA',
        sortable: false,
        width: '80px'
      },
      {
        name: 'Weight',
        cell: row => <EllipsisTooltip className='cus-pointer' title={this._getWeightPercent(row, _totalWeight)}>{this._getWeightPercent(row, _totalWeight)}</EllipsisTooltip>,
        sortable: false,
        width: '80px'
      }

    ];
    const customStyles = {
      headRow: {
        style: {
          minHeight: '35px',
          borderBottom: 'none'
        },
      },
      headCells: {
        style: {
          padding: '4px'
        },
      },
      cells: {
        style: {
          padding: '4px'
        },
      },
      rows: {
        style: {
          minHeight: '35px', // override the row height
          '&:last-of-type': {
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: 'rgba(0,0,0,.12)',
          },
        }
      }
    };
    let query_var = (qv && qv.length) && qv.filter(item => {
      if (item.active || ((item['view_name'] && item['Name of the chart on UI']))) {
        if (item['view_name'] == 'Highlight') {
          if (item['Name of the chart on UI'] == 'Fees') {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    });
    let filteredQueryVarForViewName = query_var;
    if (query_var && query_var.length > 0 && query_var[searchAccTickerIndex]) {
      filteredQueryVarForViewName = [{ ...query_var[searchAccTickerIndex] }]; // to solve this -> ['Top Holdings', 'Top Holdings'] -> ['holdings_TSLA', 'holdings_IBM'] scenario
    }
    const searchAccTicker = searchResultsData && query_var ? (query_var.length && query_var[searchAccTickerIndex].view_name) ? getColViewName(query_var[searchAccTickerIndex].view_name, filteredQueryVarForViewName) : '' : '';
    const searchField = query_var && query_var.length ? query_var[searchAccTickerIndex].variable : null;
    const sortingOrder = query_var && query_var.length ? query_var[searchAccTickerIndex].preference : null;
    let HoldingsData = [];
    HoldingsData = sortingOrder && sortingOrder == '>' ? this.customSort(holdings, searchField, 'desc') : this.customSort(holdings, searchField, 'asc');

    let columnsData = HoldingsColumns
    if (columnsData) {
      searchAccTicker != "" && columnsData.splice(1, 0, {
        name: <div className="highlight highlight-ticker" id='searchField' title={searchAccTicker}><span className='content-highlight'>{searchAccTicker}</span></div>,
        cell: row => <EllipsisTooltip className='cus-pointer'>{<SearchColContent row={row} searchField={searchField} query_var={query_var} searchAccTicker={searchAccTicker} />}</EllipsisTooltip>,
        sortable: false,
        width: '100px'
      })
    }

    HoldingsColumns = searchAccTicker ? columnsData : HoldingsColumns;

    //HoldingsColumns = searchAccTicker ? Array : HoldingsColumns;
    let showMore = false;
    if (HoldingsData) {
      if (HoldingsData.length > 5 && !this.state.skipDataCheck) {
        const top5Holdings = HoldingsData.slice(0, 5);
        HoldingsData = top5Holdings;
        showMore = true;
      }
    }


    return (
      <React.Fragment>
        <div className="tbl-content gn-tbl-wpr cl-holdings-wrapper">
          <DataTable
            noHeader
            columns={HoldingsColumns}
            data={HoldingsData}
            customStyles={customStyles}
            pagination={false}
            fixedHeader={true}
            fixedHeaderScrollHeight={'100vh'}
            sortFunction={this.customSort}
            defaultSortField="Value"
            defaultSortAsc={false}
          />
        </div>
        <div className='show-more-section'>
          {showMore && <span className='show-more' onClick={() => this.getMoreHoldingData()}>Show More</span>}
          {/* {query_var && query_var.length > 1 && <span className='dynamic-column-pagination'><span className='left-arrow'><Button type="primary" onClick={() => this.columnTrack('-',query_var.length)} icon={<LeftOutlined />}/></span><span className='right-arrow'><Button type="primary" onClick={() => this.columnTrack('+',query_var.length)} icon={<RightOutlined />}/></span></span>} */}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ clientportfolioV2: { selectedClientDetails, searchResultsData, searchAccTickerIndex } }) => ({
  selectedClientDetails,
  searchResultsData,
  searchAccTickerIndex
})

const mapDispatchToProps = {
  clientDetailsRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientHoldings);

const SearchColContent = ({ row, searchField, query_var, searchAccTicker }) => {
  // const value = (searchAccTicker == 'Risk' || searchAccTicker == 'Returns') ? parseFloat(row[searchField]).toFixed(2)*100 : parseFloat(row[searchField]).toFixed(2);
  const multiplier = query_var[0].multiplier ? query_var[0].multiplier : 1;
  const value = (row[searchField] !== "" && row[searchField] != null) ? (parseFloat(row[searchField]) * multiplier).toFixed(2) || '--' : '--';
  const suffix = (query_var[0].view_name == 'Sectors' || query_var[0].view_name == 'Regions' || searchAccTicker == 'Risk' || searchAccTicker == 'Returns') ? '%' : query_var[0].suffix;
  if (value == "--") {
    return value;
  } else {
    return value + suffix;
  }
}

class EllipsisTooltip extends React.Component {
  render() {
    const { className, onClick } = this.props;
    return (
      <div
        ref={node => this.container = node}
        className={className} onClick={onClick}
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        }}
        title={this.props.title}
      >
        {this.props.children}
      </div>
    )
  }
}

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
// import moment from 'moment';
import { Row, Col } from 'antd';
import { addEventToAnalytics, getPortfolioBenchmarkOfSelectedTicker } from 'Utils'

import { createLoadingSelector } from 'selectors/loading-selector';
import { setAnalyticsChartData, setBuildPortfolioState } from 'actions/buildportfolio'
import { setPortfolioState } from 'actions/portfolio';
import { customScroll } from 'layouts/utils';

import { RiskCard, ReturnCard } from './AnalyticsHelper';
import { getCalculatedStats } from '../utils';
import { geoMeanCalc } from '../utils'

// const DEFAULT_YEARS = 'pfRet3y';

// const { TabPane } = Tabs;

class AnalyticsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'return',
	    stats:{}
    };
  }

  componentDidMount() {
    customScroll();
	  const { fundList, upDownDataSet, selectedBenchmark, portfolioValue } = this.props
	  let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(upDownDataSet, selectedBenchmark)
	  let stats = getCalculatedStats(fundList, false, portfolioBenchmark)
    this.setState({
		  stats
	  })
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { fundList, upDownDataSet, selectedBenchmark, portfolioValue } = this.props
    let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(upDownDataSet, selectedBenchmark)
    let stats = getCalculatedStats(fundList, false, portfolioBenchmark)
    this.setState({
      stats
    })
  }

  callback = (tab) => {
    addEventToAnalytics('Builder '+ tab + ' Tab','Builder '+ tab + ' Tab','BUILDER_'+tab.toUpperCase()+'_TAB',{},false);
    this.setState({ activeTab: tab });
  }

  yearChange = (selected) => {
    console.log(selected);
  }

	handleUpDownDropDownChange = (value) => {
		const { setPortfolioState ,upDownDataSet} = this.props
		let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(upDownDataSet, value)
		const { pfRet1y, pfRet3y, pfRet5y} = this.state.stats
 		let { up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr } = geoMeanCalc(portfolioBenchmark, pfRet1y, pfRet3y, pfRet5y)
		let updatedCurrentStats = { ...this.state.stats, up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr }
		this.setState({
			stats: updatedCurrentStats
		})
		setPortfolioState({
			selectedBenchmark: value,
		});
	}

  render() {
    const { fundList, upDownDataSet, selectedBenchmark } = this.props;
    const { activeTab,stats } = this.state;
    const DEFAULT_YEARS = 'pfRet3y';
    const YEAR_SHORT_NAME = '3yr';
	  return (
      <React.Fragment>
        {(fundList && fundList.length > 0) && (
          <div className="analytics-tab-container">
            <Row className="analytics-tab-menu-wrapper">
            <Col
                className={`tab ${activeTab === 'return' ? 'tab-active' : ''}`}
                span={12}
                onClick={() => this.callback('return')}
              >
                Return
              </Col>
              <Col
                className={`tab ${activeTab === 'risk' ? 'tab-active' : ''}`}
                span={12}
                onClick={() => this.callback('risk')}
              >
                Risk
              </Col>
            </Row>
            <Row className="analytics-tab-content-wrapper custom-scroll">
            {(activeTab === 'return') &&(
                <ReturnCard
                  data={stats}
                  defaultYears={DEFAULT_YEARS}
                  yearShortName={YEAR_SHORT_NAME}
                  yearChange={this.yearChange}
                />
              )}
              {(activeTab === 'risk') &&(
                <RiskCard
                  data={stats}
                  defaultYears={DEFAULT_YEARS}
                  yearShortName={YEAR_SHORT_NAME}
                  portfolioBenchmarkData={upDownDataSet}
                  selectedPortfolioBenchmarkTicker={selectedBenchmark}
                  handleUpDownDropDownChange={this.handleUpDownDropDownChange}/>
              )}
            </Row>
          </div>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ portfolio,buildportfolio, loading, }) => ({
  equityTab: buildportfolio.equityTab,
  portfolioValue: buildportfolio.portfolioValue,
  analyticsData: buildportfolio.resultSection.analyticsData,
  equityTabLoading: createLoadingSelector(['EQUITY_BATCH'])({ loading }),
	upDownDataSet:portfolio.upDownDataSet,
	selectedBenchmark:portfolio.selectedBenchmark,
})

const mapDispatchToProps = {
  setAnalyticsChartData,
	setPortfolioState,
	setBuildPortfolioState
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyticsTab);

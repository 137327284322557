import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Row, Col, PopoverBody } from 'reactstrap';
import { withPopover } from 'components/Popover';
import { quintileSize, quintileClass } from 'Utils';
import { cn } from 'DataSet';
import { getLineHeight, getSTKData } from '../utils';
import { Quintile, Pct } from '../common';

class AnalyticsPopover extends Component {

  render() {
    const { card, qi, icon, highlight, checkPopOpen, firstPop, funds } = this.props;
    let val = qi.name === 'Volume' ? Math.round(card[qi.col] / 1000) : card[qi.col];

    if(val === null) val = '--';

    const valFmt = val !== '--' ? <span className="text-gray-8 font-weight-bold">{this.props.prefix}{val.toLocaleString('en', { minimumFractionDigits: 0 })}{ val !== '--' ? <Pct /> : ''}</span> : '--';
    const catVal = qi.name === 'Fees' ? card._cstats[qi.col].toFixed(2) : Math.round(card._cstats[qi.col]);
    const catValFmt = catVal !==  null ? <span>{this.props.prefix}{catVal.toLocaleString('en', { minimumFractionDigits: 0 })}</span> : '--';
    const hikeLabel =  (val !== '--') ? (quintileSize(card[qi.qcol], qi.reverse) === 2 ? 'Average' : (quintileSize(card[qi.qcol], qi.reverse) === 3 ? 'High' : 'Low')) : '';

    const e1 = () => (
      <div className="fees-flag-color">
        <i
          className={classNames("far fa-fw fassf", icon + quintileSize(card[qi.qcol], qi.reverse))}
        />
        <p className="__val" style={{ margin: 0, fontSize: 11, lineHeight: 1, fontWeight: 'bold' }}>
          {this.props.prefix}{val !== '--' ? val.toFixed(2).toLocaleString('en', { minimumFractionDigits: 0 })+'%' : '--'}
        </p>
        <p className="__state" style={{ margin: 0, fontSize: 10, lineHeight: 1 }}>
          {hikeLabel}
        </p>
      </div>
    );

    const e2 = () => (
      <React.Fragment>
        <PopoverBody className="p-0">
          <Row className="no-gutters">
            {/* <Col className="col-12 p-2 pt-3 d-flex justify-content-between align-items-center">
              <h4 className="fs-17 text-ssf-blue-d2 mb-1">{qi.name}</h4>
            </Col> */}
            <Col className="col-12 p-2 text-gray-9 bg-light br-2">
              <div className="h5 d-flex justify-content-between pt-1">
                <div className="popover-drop">
                  <div className="fees-pop-tog popover-drop__toggle" onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave} >
                    <span className="pop-toggle-name">Fees</span>
                  </div>
                </div>
                <span>{valFmt}</span>
              </div>
              <Quintile cardCategory={cn(card, 'Category')} date={null} item={qi} value={card[qi.qcol]} catValue={catValFmt} bg={quintileClass(card[qi.qcol], qi.reverse)} />
            </Col>
          </Row>
        </PopoverBody>
      </React.Fragment>
    );

    const highlightClassName = highlight ? 'search-fees-return-stats-padding search-risk-stats-highlighting' : (card._flags.feesMin ? 'search-fees-return-stats-padding search-risk-stats-highlighting' : 'search-fees-return-stats-padding');
    const WithPopover = withPopover(e1, e2, {
      id: "AnalyticsPopover-" + qi.name + '-' + cn(card, 'Id'), placement: 'right', className: highlightClassName,
      checkPopOpen, firstPop, funds, card, popUp: 'Fees Popup'
    })
    return (
      <WithPopover />
    );
  }
}

const mapStateToProps = ({ funds }) => ({
  funds
})

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyticsPopover)

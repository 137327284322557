import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import Slider from "react-slick";
import PerfectScrollbar from 'perfect-scrollbar';
import { Card } from 'antd';

import ItemCard from './ItemCard';
import { arrayToHash } from 'Utils';
import { CN  } from 'DataSet';

import { RightOutlined, LeftOutlined } from '@ant-design/icons';

import { replaceCurrentModelPortfolioWith, setEnhancerState } from 'actions/portfolio';

class ItemList extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      enhanceReplace: (props.replacePortfolioWith && typeof props.replacePortfolioWith.ticker !== 'undefined') ? props.replacePortfolioWith.ticker : '',
    }
  }

  componentDidMount() {
    // const { replacePortfolioWith } = this.props;
    // // console.log('------------------!!!!___________!!!-------------------');
    // // console.log(this.state.enhanceReplace);
    // if (replacePortfolioWith && typeof replacePortfolioWith.ticker !== 'ticker') {
    //   this.setState({
    //     enhanceReplace: replacePortfolioWith.ticker,
    //   });
    // }
    // console.log(this.state.enhanceReplace);
  }

  setReplaceTicker = (e, replaceTickerData, replaceTicker, query) => {
    const { setReplaceTicker } = this.props;
    if (setReplaceTicker) {
      setReplaceTicker(e, replaceTickerData, replaceTicker, query);
    }
  }

  tabOnChange = (selectedKey, data, ticker, query, index) => {
    const { tabOnChange } = this.props;
    if (tabOnChange) {
     tabOnChange(selectedKey, data, ticker, query, index)
    }
  }

  render () {
    const {
      data,
      query,
      ticker,
      staticCard,
      resetCheck,
      currentPortfolioNameInitial,
      enhanceModelPortfolio,
      portfolioQuery,
      universe,
      replacePortfolioWith,
      query_english,
      activeViewTab,
    } = this.props;

    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: null, //<RightOutlined />,
      prevArrow: null, //<LeftOutlined />,
      responsive: [
        {
          breakpoint: 1900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1530,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1180,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const messgageText = (q) => {
      if (enhanceModelPortfolio.portfolioName) {
        if (q.includes('yield')) {
          return <span>There are <span className="text-bold">no portfolios</span> similar to <br/><span className="text-bold">{enhanceModelPortfolio.portfolioName}</span> with <span className="text-bold">higher yield</span>.</span>;
        } else if (q.includes('risk') || q.includes('safe_alternative')) {
          return <span>There are <span className="text-bold">no portfolios</span> similar to <br/><span className="text-bold">{enhanceModelPortfolio.portfolioName}</span> with <span className="text-bold">lower risk</span>.</span>;
        } else if (q.includes('low_cost') || q.includes('similar')) {
          return <span>There are <span className="text-bold">no portfolios</span> similar to <br/><span className="text-bold">{enhanceModelPortfolio.portfolioName}</span> with <span className="text-bold">lower cost</span>.</span>;
        } else if (q.includes('high_sharpe_alternative')) {
          return <span>There are <span className="text-bold">no portfolios</span> similar to <br/><span className="text-bold">{enhanceModelPortfolio.portfolioName}</span> with the <span className="text-bold">higher risk adjusted return</span>.</span>;
        } else {
          return <span>There are <span className="text-bold">no portfolios</span> similar to <br/><span className="text-bold">{enhanceModelPortfolio.portfolioName}</span> for <span className="text-bold">'{query_english}'</span>.</span>;
        }
      }
    }

    const renderDesign = (data && typeof data.funds !== 'undefined' && data.funds.length > 0) ? (
      <Slider {...settings}>
        { data && typeof data.funds !== 'undefined' && data.funds.length > 0 && data.funds.map((e, i) => (
          <ItemCard
            {...this.props}
            className="model-portfolio-item-card"
            key={i}
            i={i}
            data={e}
            universe={universe || ''}
            query={query}
            portfolioQuery={portfolioQuery}
            currentPortfolioNameInitial={currentPortfolioNameInitial}
            staticCard={staticCard}
            resetCheck={resetCheck}
            activeViewTab={activeViewTab}
            defaultFRR={e._defaultFRR}
            relevanceChartTabs={e._relevanceChartTabs}
            originalQueryVar={e._originalQueryVar}
            ticker={e.ticker}
            highlights={data.Highlights}
            enhanceReplace={replacePortfolioWith.ticker || ''}
            setReplaceTicker={this.setReplaceTicker}
            tabOnChange={this.tabOnChange}
          />
        )) }
      </Slider>
    ) : (
      <Card className="card-background-grey empty-message-wrapper model-portfolio-item-card" style={{ margin: '0 8px' }}>
        <div className="text-box">
          {messgageText(query)}
        </div>
      </Card>
    );

    return (
      <div className="itemlist-carousel-wrap" style={{ display: 'inherit', width: '100%' }}>
        {renderDesign}
      </div>
    )
  }
}

export default ItemList;

// const mapStateToProps = ({ portfolio }) => ({
//   // enhanceModelPortfolio: portfolio.enhancePortfolio,
//   // myCurrentPortfolio: portfolio.enhancePortfolio.myCurrentPortfolio,
//   // replacePortfolioWith: portfolio.enhancePortfolio.replacePortfolioWith,
//   // portfolioQuery: portfolio.query,
// });

// const mapDispatchToProps = {
//   replaceCurrentModelPortfolioWith,
//   setEnhancerState,
// }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(ItemList)

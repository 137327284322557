// absolute imports
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { createLoadingSelector } from 'selectors/loading-selector';
// import Header from './Header';
import { Loader } from './Elements/Loader';
import Search from './Elements/Search/index';
import Stats from './Elements/Stats/index';
import TrendingSearch from './Elements/TrendingSearch/index';
import PortfolioTable from './Elements/PortfolioTable/index';
import PortfolioUpload from './Elements/PortfolioUpload/index';
import PfListPreview from './Elements/PfListPreview/index';
import ModelSelector from './Elements/ModelSelector/index';
// import { csvToJSON } from './Utils';
// import { ButtonGroup } from 'reactstrap';
// import { PrimaryButton } from 'shared-components';
import { clientListRequest, uploadClientListV2, portfolioOptionsRequest, finalizeAllocationRequest, searchAccFundsReset, accountPageClientListRequest, resetPaginationAndDownloadedList } from 'actions/clientportfolioV2';
// import { demoClients } from './demoClients';
// import PremiumNoticeModal from 'components/PremiumNoticeModal';
import { PremiumModal } from 'components/Premium';
// import {getPremiumFeatureDetails} from 'apis/test-drive';
import { fetchProfile, subscriptionPlan, premiumFeatureAccessRequest } from 'actions/profile';

class ClientPortfolioV2 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      headerHeight: '',
      pfList: '',
      selectedClient: '',
      searchedQuery: '',
      showClientUploadModal: false,
      premiumModal: false,
      premiumFeature: '',
      showDynamicContent: false,
      dynamicData: {},
      trendingSearch: false,
      trendingSearchQuery: '',
      clearSearch: false,
    }
    this.isSearchClear = false
  }
  componentDidMount() {
    document.body.classList.add('cp-comp');
    const { accountPageClientListRequest, finalizeAllocationRequest, portfolioOptionsRequest } = this.props;
    if (this.props.client_exist) {
      const { redirectFrom } = this.props;
      if (redirectFrom && typeof redirectFrom.payload !== 'undefined') {
        const { payload } = redirectFrom;
        if (payload && payload.clientDetails && finalizeAllocationRequest) {
          finalizeAllocationRequest([payload.clientDetails]);
        }
      } else {
        if (accountPageClientListRequest) {
          accountPageClientListRequest({
            page_number: 1,
            count: 20,
          });
        }
      }
    }
    console.log(' ---------------- portfolioOptionsRequest ----------------- ');
    if (portfolioOptionsRequest) {
      portfolioOptionsRequest();
    }
    // this.props.portfolioOption;sRequest();
    console.log(' ---------------- ----------------- ');
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.fetchingPremiumDetails) {
      const { loggedIn, premiumFeatureAccessRequest, premiumUser, premiumNoticeModalOpen, access, fetchingPremiumDetails, premiumFeatures } = nextProps;
      if (!premiumNoticeModalOpen && (access === 'Denied' || access === '')) {
        // console.log('-- premiumFeatureAccessRequest --');
        premiumFeatureAccessRequest('client_portfolio', (res) => {
          this.setState({ premiumModal: res.premiumNoticeModalOpen });
          // this.setState({ premiumFeature: res.premiumNoticeModalOpen });
        });
      }
    }
    if (!this.state.searchedQuery) {
      this.setState({
        showDynamicContent: false
      })
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('cp-comp');
    // Clear search results if there is any
    this.props.searchAccFundsReset();
    /*NOTE- TO CLEAR DOWNLOAD LIST OF CLIENT PORTFOLIO  V2 PAGE */
    this.props.resetPaginationAndDownloadedList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.state && this.props.location.state['id'] !== prevProps.location.state['id'] || this.state.clearSearch) {
      this.setState({
        headerHeight: '',
        pfList: '',
        selectedClient: '',
        searchedQuery: '',
        showClientUploadModal: false,
        premiumModal: false,
        premiumFeature: '',
        showDynamicContent: false,
        dynamicData: {},
        trendingSearch: false,
        trendingSearchQuery: '',
        clearSearch: false
      })
      document.body.classList.remove('cp-comp');
      this.props.searchAccFundsReset();
      this.props.resetPaginationAndDownloadedList();

      document.body.classList.add('cp-comp');
      const { accountPageClientListRequest, finalizeAllocationRequest, portfolioOptionsRequest } = this.props;
      if (this.props.client_exist) {
        const { redirectFrom } = this.props;
        if (redirectFrom && typeof redirectFrom.payload !== 'undefined') {
          const { payload } = redirectFrom;
          if (payload && payload.clientDetails && finalizeAllocationRequest) {
            finalizeAllocationRequest([payload.clientDetails]);
          }
        } else {
          if (accountPageClientListRequest) {
            accountPageClientListRequest({
              page_number: 1,
              count: 20,
            });
          }
        }
      }
      if (portfolioOptionsRequest) {
        portfolioOptionsRequest();
      }
    }
  }

  setSelectedClient = (val) => {
    this.setState({ selectedClient: val })
  }

  getTypedQuery = (query) => {
    if (typeof query !== 'undefined') {
      this.setState({
        searchedQuery: query,
        showDynamicContent: false,
        trendingSearch: false
      });
    } else {
      this.setState({ clearSearch: true });
    }
  }

  searchQuery = (query) => {
    this.setState({
      trendingSearchQuery: query,
      trendingSearch: true
    });
  }

  clientUploadModalToggle = (val) => {
    this.setState({
      showClientUploadModal: val
    })
  }

  dynamicColumnQuery = (data, value) => {
    this.setState({
      showDynamicContent: value,
      dynamicData: data
    })
  }

  premiumModalToggle = () => {
    this.setState({
      premiumModal: !this.state.premiumModal,
    });
  }


  render() {
    const { selectedClient, searchedQuery, showClientUploadModal, showDynamicContent, dynamicData, trendingSearch, trendingSearchQuery } = this.state;
    const { uploadedClientList, client_exist, user_advisor_menu, clientList, showUpdateColumnInPreview, loadingPortfolio, premiumUser, planName, fetchingPremiumDetails } = this.props;
    // console.log('this.props',this.props);
    if (!user_advisor_menu) {
      return <Redirect to='/securities' />
    }
    return (
      <div className="cp-wrap">
        {(fetchingPremiumDetails || loadingPortfolio)
          && <Loader loading />
        }
        {!fetchingPremiumDetails && (
          <div className="client-portfolio-screen-wrapper">
            <div className="content-body">
              <div className="general-wrapper spacing">
                <Search selectedClient={selectedClient} getTypedQuery={this.getTypedQuery} trendingSearch={trendingSearch} trendingSearchQuery={trendingSearchQuery} />
                <TrendingSearch searchQuery={this.searchQuery} />
                <Stats dynamicContent={dynamicData} isDynamicQuery={showDynamicContent} />
              </div>
              <div className="general-wrapper gen-divider"></div>
              {(this.state.premiumModal && (this.props.access !== 'Granted' || this.props.access === '')) ? (
                <PremiumModal
                  access={this.props.access}
                  premiumUser={premiumUser}
                  planName={planName}
                  activeFeatureName={this.props.activeFeatureName}
                  premiumNoticeModalOpen={this.props.premiumNoticeModalOpen}
                  onClickOK={this.premiumModalToggle}
                  onClickCancel={this.premiumModalToggle}
                  onToggle={this.premiumModalToggle}
                  testDriveAction={this.premiumModalToggle}
                />
              ) : (
                <>
                  {/*Display client list till a user selects a portfolio*/}
                  {!selectedClient && <PortfolioTable setSelectedClient={this.setSelectedClient} clientUploadModalToggle={this.clientUploadModalToggle} clientUploadModalToggle={this.clientUploadModalToggle} dynamicColumnQuery={this.dynamicColumnQuery} />}
                  {/*Display model selector once a user selects a portfolio*/}
                  {selectedClient && <ModelSelector searchedQuery={searchedQuery} selectedClient={this.state.selectedClient} setSelectedClient={this.setSelectedClient} />}
                  {/* Display upload modal if the portfolio list is empty */}
                  {((!client_exist || showClientUploadModal) && !uploadedClientList.length) &&
                    <PortfolioUpload
                      showClientUploadModal={showClientUploadModal}
                      clientUploadModalToggle={this.clientUploadModalToggle}
                      client_exist={client_exist}
                      clientList={clientList}
                      redirectTo={'securities'}
                    />
                  }
                  {/* Display preview modal once the upload portfolio is successful */}
                  {(uploadedClientList.length > 0 && (!client_exist || !showClientUploadModal)) &&
                    <PfListPreview
                      clientList={clientList}
                      pfList={uploadedClientList}
                      clientUploadModalToggle={(val) => this.clientUploadModalToggle(val, false)}
                      showUpdateColumnInPreview={showUpdateColumnInPreview}
                      uploadedFrom="clientPortfolioV2"
                    />
                  }
                </>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({
  clientportfolioV2: { clientList, uploadedClientList, showUpdateColumnInPreview },
  auth: { client_exist, user_advisor_menu, user: { premiumUser, planName }, loggedIn }, investment: { copyOfRedirectFrom }, enhancer: { redirectFrom }, profile, loading
}) => ({
  clientList,
  uploadedClientList,
  showUpdateColumnInPreview,
  client_exist,
  user_advisor_menu,
  copyOfRedirectFrom,
  premiumUser,
  loggedIn,
  access: profile.access,
  premiumFeatures: profile.premiumFeatures,
  activeFeatureName: profile.activeFeatureName,
  premiumNoticeModalOpen: profile.premiumNoticeModalOpen,
  redirectFrom,
  loadingPortfolio: createLoadingSelector(['GET_PORTFOLIO_BY_ID'])({ loading }),
  fetchingPremiumDetails: createLoadingSelector(['GET_SUBSCRIPTION_PLAN', 'PROFILE', 'PREMIUM_FEATURE_ACCESS'])({ loading }),
})

const mapDispatchToProps = {
  clientListRequest,
  uploadClientListV2,
  portfolioOptionsRequest,
  finalizeAllocationRequest,
  searchAccFundsReset,
  fetchProfile,
  subscriptionPlan,
  premiumFeatureAccessRequest,
  accountPageClientListRequest,
  resetPaginationAndDownloadedList,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientPortfolioV2);

import React from 'react';
import moment from 'moment'
const DateRangeSubHeading = (props) => {
  const { style } = props;
  let start = moment().subtract(35, 'months').format('MMMM YYYY');
  let end = moment().format('MMMM YYYY');
  return(
    <p className={'date-range-subheading'} style={style}>{`${start} - ${end}`}</p>
  )
};

export default DateRangeSubHeading;
import config from './env'
const parseDomain = require("parse-domain");

const analyticsInit = () => {
  console.log(' ===== analyticsInit ===== ');
  window.dataLayer = window.dataLayer || []
  var url_string = window.location.href
  var url = new URL(url_string)

  if (url.searchParams.get('ks')) {
    window.sessionStorage.setItem(
      'kraken_sessionID',
      url.searchParams.get('ks')
    )
  }
  var utm = new Object();

  if (url.searchParams.get('utm_source')) {
    utm.source = url.searchParams.get('utm_source')
    let domain = parseDomain(utm.source);
    if(domain && domain.domain) {
      utm.source = domain.domain;
    }
    console.log(domain);
    window.sessionStorage.setItem('utm_source',utm.source)
  }

  if (url.searchParams.get('utm_medium')) {
    utm.medium = url.searchParams.get('utm_medium')
    window.sessionStorage.setItem('utm_medium',utm.medium)
  }

  if (url.searchParams.get('buy')) {
    utm.buy = url.searchParams.get('buy')
    window.sessionStorage.setItem('buy',utm.buy)
  }

  if (url.searchParams.get('compare')) {
    utm.compare = url.searchParams.get('compare')
    window.sessionStorage.setItem('compare',utm.compare)
  }

  if (url.searchParams.get('utm_usage_log_id')) {
    utm.utm_usage_log_id = url.searchParams.get('utm_usage_log_id')
    window.sessionStorage.setItem('utm_usage_log_id',utm.utm_usage_log_id)
  }

  if (url.searchParams.get('utm_token')) {
    utm.utm_token = url.searchParams.get('utm_token')
    window.sessionStorage.setItem('utm_token',utm.utm_token)
  }

  if (url.searchParams.get('utm_campaign')) {
    utm.campaign = url.searchParams.get('utm_campaign')
    let domain = parseDomain(utm.campaign);
    if(domain && domain.domain) utm.campaign = domain.domain;
    window.sessionStorage.setItem('utm_campaign',utm.campaign)
  }

  if (url.searchParams.get('utm_term')) {
    utm.medium = url.searchParams.get('utm_term')
    window.sessionStorage.setItem('utm_term',utm.term)
  }

  if (url.searchParams.get('utm_content')) {
    utm.medium = url.searchParams.get('utm_content')
    window.sessionStorage.setItem('utm_content',utm.content)
  }

  if (url.searchParams.get('fundPrice')) {
    utm.fundPrice = url.searchParams.get('fundPrice')
    window.sessionStorage.setItem('fundPrice',utm.fundPrice)
  }

  if (url.searchParams.get('fundIsSponsored')) {
    utm.fundIsSponsored = url.searchParams.get('fundIsSponsored')
    window.sessionStorage.setItem('fundIsSponsored',utm.fundIsSponsored)
  }

  if (url.searchParams.get('fundSponsorLogo')) {
    utm.fundSponsorLogo = url.searchParams.get('fundSponsorLogo')
    window.sessionStorage.setItem('fundSponsorLogo',utm.fundSponsorLogo)
  }

  if (url.searchParams.get('fundName')) {
    utm.fundName = url.searchParams.get('fundName')
    window.sessionStorage.setItem('fundName',utm.fundName)
  }

  if (url.searchParams.get('fundCategory')) {
    utm.fundCategory = url.searchParams.get('fundCategory')
    window.sessionStorage.setItem('fundCategory',utm.fundCategory)
  }

  if (url.searchParams.get('fundVehicle')) {
    utm.fundVehicle = url.searchParams.get('fundVehicle')
    window.sessionStorage.setItem('fundVehicle', utm.fundVehicle)
  }

  var eventInfo=new Object();

  if (url.searchParams.get('event_name')) {
    eventInfo.event_name = url.searchParams.get('event_name')
  }

  if (url.searchParams.get('event_param')) {
    try {
      eventInfo.event_param = JSON.parse(url.searchParams.get('event_param'))
    }
    catch {
      console.log('error : enable to parse event_param')
    }
  }

  if (url.searchParams.get('event_source')) {
    eventInfo.event_source = url.searchParams.get('event_source')
  }

  window.gtag = function() {
    window.dataLayer.push(arguments)
  }

  window.gtag('js', new Date())
  window.gtag('config', config.googleTaManagerCode, config.googleGTAGUAConfig)

  if (Object.keys(utm).length != 0 && window.onKrakenInit) {
    utm.hostname = window.location.hostname;
    window.onKrakenInit(config.krakenId,utm);
  }
  else if(window.onKrakenInit) {
    window.onKrakenInit(config.krakenId);
  }
  else {
    console.log('no kraken found')
    setTimeout(()=>{
      if (Object.keys(utm).length != 0 && window.onKrakenInit) {
        utm.hostname = window.location.hostname;
        console.log('yes1')
        window.onKrakenInit(config.krakenId,utm);
      }
      else if(window.onKrakenInit) {
        console.log('yes')
        window.onKrakenInit(config.krakenId);
      }
    },1000);
  }

  const utm_timestamp = new Date();
  setTimeout(()=>{
    if(Object.keys(utm).length != 0 && window.addKrakenEvent && typeof window.addKrakenEvent==="function"){
      utm.timestamp = utm_timestamp;
      window.addKrakenEvent({ name: 'UTM_PARAMS_RECORDED', body: utm});
    }
  },2000);

  if(Object.keys(eventInfo).length != 0 && window.addKrakenEvent && typeof window.addKrakenEvent==="function") {
    if (eventInfo.event_param != undefined) {
     window.addKrakenEvent({ name: eventInfo.event_name, body: eventInfo.event_param, event_source: eventInfo.event_source});
    }
    else {
      window.addKrakenEvent({ name: eventInfo.event_name, event_source: eventInfo.event_source});
    }
  }

  if (window.heap && window.heap.load) {
    window.heap.load(config.heapAnalyticsCode)
  }
}

export default analyticsInit

import React, { Component, Fragment } from 'react';
import { Row, Col, Dropdown } from 'antd';

import SearchDropdown from '../Shared/ColumnSearchFilter/SearchDropdown';

class InfiniteListHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showDropdown: false
		}
	}

	render () {
		const { headerColData } = this.props;

		const headerCol = headerColData || [{
			label: 'Name',
			key: 'name',
			showFilter: true,
			colSpan: { span: 6 },
		}, {
			label: 'Account Number',
			key: 'acc_no',
			showFilter: true,
			colSpan: { span: 9 },
		}, {
			label: 'Custodian',
			key: 'custodian',
			showFilter: true,
			colSpan: { span: 9 },
		}];

		const RenderCol = () => {
			return headerCol.map((item) => {
				return (
					<Col {...item.colSpan} className={`${item.className || ''}`} key={item.key}>
						{(item.showFilter) ? (
							<>
								<span style={{ paddingRight: 5 }}>{item.label}</span>
								<SearchDropdown
									title={item.label}
									selector={item.key}
									filterName={item.label}
								/>
							</>
						) : item.label}
					</Col>
				)
			})
		}

		return (
			<Fragment>
				<Row className="infinite-list-header">
					<RenderCol />
				</Row>
			</Fragment>
		)
	}
}

export default InfiniteListHeader;

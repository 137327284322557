import React, { Component } from 'react';
import './_index.scss';
import Header from "./Header";
import Search from "./Search";
import Violator from "./Violator";
import TrendingSearches from "./TrendingSearches";
// import LandingForm from "./LandingForm";
import TrendingTheme from "./TrendingTheme";
import Info from "./Info";
import Footer from "./Footer";
import StartFreeTrade from './StartFreeTrade/index';
// import VidIntro from "./VidIntro";
import { connect } from "react-redux";
import { addEventToAnalytics, searchUrlV3, clearWindowUtmSession } from 'Utils';
import { getViolatorSearches } from 'actions/trendingsearches';
import { createLoadingSelector } from 'selectors/loading-selector';
// import { apexRedirectionWhereTo } from 'layouts/utils';
import { CloseCircleOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
// import { getApexAccountStatus } from 'apis/register'
// import { isEmpty } from 'lodash';
import { searchQueryRequest, setFundsState,getTrendingSearchThemeRequest } from 'actions/discovery';
import { clearFundsApiLoadPercent } from 'actions/global';

class LandingPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      is_visible: false,
      showAccountBar: true,
      apexStatus: null,
      uploadDocs: false,
      uploadBar: true
    }
  }

  // componentWillMount() {
  //   this.handleRejectedStatusData(this.props);
  // }

  componentDidMount = () => {
    const {getTrendingSearchThemeRequest}=this.props;
    document.body.style.paddingTop = "0";
    document.body.classList.remove('ht0');
    var scrollComponent = this;
    this.props.getViolatorSearches()
    if(getTrendingSearchThemeRequest){
      getTrendingSearchThemeRequest({count:18,duration:'DAY'})
    }

    document.addEventListener("scroll", function (e) {
      scrollComponent.toggleVisibility();
    });
  }

  // componentWillReceiveProps(nextProps) {
  //   this.handleRejectedStatusData(nextProps);
  // }

  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }

  toCaptilize = (myString) => {
    return myString.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
  }

  // queryHandler = (query) => {
  //   if (query) {
  //     const { history } = this.props;
  //     window.sessionStorage.removeItem('search_type');
  //     // let queryString = "query=" + encodeURIComponent(this.toCaptilize(query.toLowerCase()));
  //     // this.props.history.push(`/securities?${queryString}`);
  //     if (history) {
  //       history.push(searchUrlV3(query));
  //     }
  //   }
  // }

  checkRoutes = (query, clearUTMSession) => {
    // const { isTermsAgreed, match, history } = this.props;
    // // const query = window.sessionStorage.getItem('query');
    // // if (loggedIn && isTermsAgreed === 0 && query) return;
    // console.log('checkRoutes -->', query, 'isTermsAgreed ->', isTermsAgreed)
    // if (!match.params.id || match.params.id == '') {
		// 	if (clearUTMSession) {
		// 		clearWindowUtmSession();
		// 	}
    //   if (history) {
    //     history.push(searchUrlV3(query));
    //   }
		// }
    const { history } = this.props;
    if (query && history) {
      if (clearUTMSession) {
        clearWindowUtmSession();
      }
      history.push(searchUrlV3(query));
    }
  }

  queryHandlerCustom = (query, clearUTMSession = false, search_type) => {
    if (query) {
      const { searchQueryRequest, setFundsState, clearFundsApiLoadPercent } = this.props;
      clearFundsApiLoadPercent();
      setFundsState({
        actions: true,
        currentPage: 1,
      });
      this.checkRoutes(query, clearUTMSession);
      searchQueryRequest({
        query,
        search_type,
      });
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  redirectToThemes = () => {
    if (process.env.REACT_APP_SUB_DOMAIN === 'advisor') {
      window.location.href = 'https://advisor.magnifi.com/trending-themes';
    } else {
      window.location.href = '/pages/trending-themes';
    }
  }

  navigateTo = (path) => {
    window.location.href = path;
  }

  handleTrendingSearch = (query) => {
    addEventToAnalytics('Trending Search Button Click', 'Trending Search Button Click', 'TRENDING_SEARCH_BUTTON_CLICK', {});
    this.queryHandlerCustom(query, true, 'trending_searches');
  }

  handleTrendingSearchCustom = (query) => {
    addEventToAnalytics('Trending Search Button Click', 'Trending Search Button Click', 'TRENDING_SEARCH_BUTTON_CLICK', {});
    this.queryHandlerCustom(query, true, 'trending_searches');
  }

  // handleThemeClick = (link) => {
  //   addEventToAnalytics('Trending Theme Click', 'Trending Theme Click', 'TRENDING_THEME_CLICK', {});
  //   window.location.href = link;
  //   // this.queryHandler(query);
  // }

  handleThemeClickQuery = (query) => {
    addEventToAnalytics('Trending Theme Click', 'Trending Theme Click', 'TRENDING_THEME_CLICK', {});
    this.queryHandlerCustom(query, true, 'popular_themes');
  }

  handleLandingBtnClick = () => {
    window.location.href = 'https://share.hsforms.com/1Ew4U5OnWTB-oMw7i94qG1w47xec';
  }

  violatorQueryHandler = (link) => {
    const { loggedIn } = this.props;
    try {
      if (loggedIn) {
        throw '-- Redirecting it always --'
        // if (profile && profile.apex) {
        //   const apex_obj = profile.apex;
        //   const isApexEmpty = apex_obj && Object.keys(apex_obj).length === 0 && apex_obj.constructor === Object;
        //   if (!isApexEmpty) {
        //     const _apexRedirectionWhereTo = apexRedirectionWhereTo({ profile });
        //     if (_apexRedirectionWhereTo !== null && _apexRedirectionWhereTo !== 'Not to apex route') {
        //       if (history) {
        //         history.push(_apexRedirectionWhereTo)
        //       }
        //     } else {
        //       throw '-- _apexRedirectionWhereTo fn returns null --'
        //       // console.log('-- _apexRedirectionWhereTo fn returns null --');
        //       // if (history) {
        //       //   history.push('/register/trading');
        //       // }
        //     }
        //   } else {
        //     // console.log('-- Apex object is empty --');
        //     throw '-- Apex object is empty --'
        //     // if (history) {
        //     //   history.push('/register/trading');
        //     // }
        //   }
        // } else {
        //   throw '-- Profile || Profile.apex is missing --'
        // }
      } else {
        throw '-- Not loggedIn --'
      }
    } catch (e) {
      console.log(e);
      window.open(
        link,
        '_blank'
      );
    }
  }

  render() {
    const { profile, universe ,trendingSearchTheme,loadingTrendingTheme} = this.props;
    const { is_visible, uploadDocs, uploadBar } = this.state;
    let profileDetails = profile;
    let showLinkAccountBar = profileDetails && profileDetails.apex && Object.keys(profileDetails.apex).length && Object.keys(profileDetails.apex).length !== 0 && profileDetails.apex.account && profileDetails.apex.account[0] && profileDetails.apex.account[0].status === 'COMPLETE' && profileDetails.apex.account[0].is_active && (!profileDetails.apex.bank || (profileDetails.apex.bank && profileDetails.apex.bank.length && profileDetails.apex.bank.length === 0)) && this.state.showAccountBar;

    if (this.props.searchCard) {
      return (
        <div id="landing-page">
          {/* <div className={`r32`}>
            <div className="banner-items pt-4 pb-0">
              <h1 style={{ color: 'black' }}>Discover a world of investing.</h1>
              <h4 style={{ color: 'gray' }}>Find, compare and invest using the world’s first semantic search powered marketplace for finance.</h4>
              <Search profile={profile} universe={universe} queryHandler={this.queryHandler}/>
            </div>
          </div> */}
          <TrendingSearches searchCard={this.props.searchCard} queryHandler={this.props.queryHandlerMobile || this.handleTrendingSearchCustom} />
          <TrendingTheme trendingSearchTheme={trendingSearchTheme} loadingTrendingTheme={loadingTrendingTheme}  searchCard={this.props.searchCard} redirectToThemes={this.redirectToThemes} queryHandler={this.props.queryHandlerMobile || this.handleThemeClickQuery} />
        </div>
      )
    }

    return (
      <div id="landing-page" style={showLinkAccountBar || (uploadDocs && uploadBar) ? { marginTop: '30px' } : {}}>
        {showLinkAccountBar ? <div className="no_account_bar" style={{ position: 'fixed', top: 0, zIndex: 9999 }}>
          <Link onClick={() => {
            let email = localStorage.getItem('email') || window.sessionStorage.getItem('email') || "";
            addEventToAnalytics('Link Your Bank Account', 'Link Your Bank Account', 'LINK_YOUR_BANK_ACCOUNT', { email: email }, true);
          }} to={{ pathname: "/settings/tradingAccount", linkaccount: true }}>Link Your Bank Account<span className="span1"></span></Link>
          <CloseCircleOutlined onClick={() => {
            // localStorage.setItem('noaccbar', localStorage.getItem('counter'))
            let email = localStorage.getItem('email') || window.sessionStorage.getItem('email') || "";
            addEventToAnalytics('Link Your Bank Account Bar Closed', 'Link Your Bank Account Bar Closed', 'LINK_YOUR_BANK_ACCOUNT_BAR_CLOSED', { email: email }, true);
            this.setState({ showAccountBar: false })
          }} style={{ position: 'fixed', right: 10, fontSize: 20 }} />
        </div> : null}

        {uploadDocs && uploadBar ? <div className="no_account_bar" style={{ position: 'fixed', top: 0, zIndex: 9999 }}>
          <Link onClick={() => {
            let email = localStorage.getItem('email') || window.sessionStorage.getItem('email') || "";
            addEventToAnalytics('Upload Additional Documents', 'Upload Additional Documents', 'UPLOAD_DOCUMENTS', { email: email }, true);
          }} to={{ pathname: "/accounts", linkaccount: true }}><CloudUploadOutlined className="upload_icon" /> Upload Additional Documents To Open Your Investment Account</Link>
          <CloseCircleOutlined onClick={() => {
            // localStorage.setItem('noaccbar', localStorage.getItem('counter'))
            let email = localStorage.getItem('email') || window.sessionStorage.getItem('email') || "";
            addEventToAnalytics('Upload Additional Documents Bar Closed', 'Upload Additional Documents Bar Closed', 'UPLOAD_DOCUMENTS_BAR_CLOSED', { email: email }, true);
            this.setState({ uploadBar: false })
          }} style={{ position: 'fixed', right: 10, fontSize: 20 }} />
        </div> : null}

        {this.props.trendingSearches && this.props.trendingSearches.violatorSearches && this.props.trendingSearches.violatorSearches.data && Object.keys(this.props.trendingSearches.violatorSearches.data).length > 0 && (
          <Violator
            profile={profile}
            trendingSearches={this.props.trendingSearches}
            loadingProfileData={this.props.loadingProfileData}
            violatorQueryHandler={this.violatorQueryHandler}
          />
        )}
        <div className={`banner ${this.props.loggedIn ? 'logged-in-banner' : ''} r32`}>
          <Header {...this.props} />
          <div className="banner-items">
            <h1>Discover a world of investing.</h1>
            <h4>Find, compare and invest using the world’s first semantic search powered marketplace for finance.</h4>
            <Search profile={profile} universe={universe} queryHandler={this.queryHandler} />
          </div>
        </div>
        <StartFreeTrade {...this.props} />
        <TrendingSearches queryHandler={this.handleTrendingSearch} />
        {/* <PopularSearch queryHandler={this.handlePopularSearch}/> */}
        {/* <LandingForm handleLandingBtnClick={this.handleLandingBtnClick}/>
        <VidIntro/> */}
        <TrendingTheme trendingSearchTheme={trendingSearchTheme} loadingTrendingTheme={loadingTrendingTheme} redirectToThemes={this.redirectToThemes} queryHandler={this.handleThemeClickQuery} />
        <Info navigateTo={this.navigateTo} />
        <StartFreeTrade {...this.props} parentClass={'full_w_p'} customclass={'full_w'} />
        <Footer />
        {process.env.REACT_APP_SUB_DOMAIN === 'retail' && <div style={{ height: "35px" }} />}
        {is_visible && (
          <div className="style-light footer-scroll-top" onClick={() => this.scrollToTop()} style={process.env.REACT_APP_SUB_DOMAIN === 'retail' ? { bottom: "50px" } : { bottom: "20px" }}>
            <a className="scroll-top"><i className="fa fa-angle-up fa-stack fa-rounded btn-default btn-hover-nobg"></i></a>
          </div>
        )}
      </div>
    );
  }
}


const mapStateToProps = ({ auth: { user_advisor_menu, client_exist, loggedIn, termsAgreed }, profile, trendingSearches, loading, discovery }) => ({
  user_advisor_menu,
  client_exist,
  premiumUser: profile.user && profile.user.premiumUser,
  profile: profile.profile,
  loggedIn,
  trendingSearches,
  apex: profile.profile.apex,
  universe: discovery.universe,
  discovery: discovery,
  trendingSearchTheme : discovery.trendingSearchTheme,
  loadingTrendingTheme : discovery.loadingTrendingTheme,
  loadingProfileData: createLoadingSelector(['PROFILE'])({ loading }) || false,
  isTermsAgreed: termsAgreed
})

const mapDispatchToProps = {
  getViolatorSearches,
  searchQueryRequest,
  getTrendingSearchThemeRequest,
  setFundsState,
  clearFundsApiLoadPercent
};

const LandingPageComponent = connect(mapStateToProps, mapDispatchToProps)(LandingPage);

export default LandingPageComponent;

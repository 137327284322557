import React from 'react';
import Exception from '../Exception/index';
import CheckPermissions from './CheckPermissions';
/**
 * default is "NULL"
 */
const Exception403 = () => (
  <Exception type="403" style={{ minHeight: 500, height: '80%' }} />
);

// Determine whether the incoming component has been instantiated
// AuthorizedRoute is already instantiated
// Authorized  render is already instantiated, children is no instantiated
// Secured is not instantiated
const checkIsInstantiation = (target) => {
  if (!React.isValidElement(target)) {
    return target;
  }
  return () => target;
};

/**
 * authority string ,funtion:()=>boolean|Promise
 * e.g. 'user' user
 * e.g. 'user,admin' user admin
 * e.g. ()=>boolean true, false
 * e.g. Promise  then  catch
 * e.g. authority support incoming string, funtion: () => boolean | Promise
 * e.g. 'user' only user user can access
 * e.g. 'user, admin' user and admin can access
 * e.g. () => boolean true to be able to visit, return false can not be accessed
 * e.g. Promise then can not access the visit to catch
 * @param {string | function | Promise} authority
 * @param {ReactNode} error
 */
const authorize = (authority, error) => {
  /**
   * conversion into a class
   * staticContext
   * String parameters can cause staticContext not found error
   */
  let classError = false;
  if (error) {
    classError = () => error;
  }
  if (!authority) {
    throw new Error('authority is required');
  }
  return function decideAuthority(targer) {
    const component = CheckPermissions(authority, targer, classError || Exception403);
    return checkIsInstantiation(component);
  };
};

export default authorize;

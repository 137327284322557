import React, { useContext } from 'react'
import { connect } from 'react-redux'
import ActReportContext from '../ActReportContext'
// import { indexPageMapping } from './Utils';
import { getSelectorReportIndexData } from 'actions/reports'
import {
  Header_Footer_Logo,
  Header_Footer_poweredByLogo,
  PoweredByIcon
} from '../../../common/constant'

// const Logo = Header_Footer_Logo;
const poweredByLogo = Header_Footer_poweredByLogo

export const withHeaderFooter = WrappedComponent => {
  class HeaderFooter extends React.Component {
    static contextType = ActReportContext
    state = {}
    componentDidMount() {
      const {
        showHeaderFooter,
        pageName,
        // selectorReportIndexData,
        getSelectorReportIndexData
      } = this.props
      let page = showHeaderFooter !== false && pageNum.next().value
      if (page && pageName) {
        const newPage = { name: pageName, pageNumber: page }
        getSelectorReportIndexData(newPage)
      }
      this.setState({ page })
    }
    render() {
      const { totalPageCount, showHeaderFooter } = this.props
      const logo = this.context.logo || this.props.logo
      const { page } = this.state

      return (
        <React.Fragment>
          <div className="page">
            {showHeaderFooter !== false ? (
              <>
                <PageHeader logo={logo} page={page} total={totalPageCount} />
                <WrappedComponent {...this.props} />
                <PageFooter page={page} />
              </>
            ) : (
              <WrappedComponent {...this.props} />
            )}
          </div>
        </React.Fragment>
      )
    }
  }
  const mapStateToProps = ({ reports }) => ({
    selectorReportIndexData: reports.selectorReportIndexData
  })
  const mapDispatchToProps = {
    getSelectorReportIndexData
  }
  return connect(mapStateToProps, mapDispatchToProps)(HeaderFooter)
}

const PageHeader = ({ date, page, total, logo }) => (
  <div className="page-header d-print-block">
    <div className="d-flex justify-content-between align-item-center">
      {logo ? <img className={'logo'} src={logo} alt="" /> : <div></div>}
      <div className="d-grid">
        <img style={{ height: `20px` }} src={PoweredByIcon} alt="" />
        {/*<div style={{color:"#979797",fontWeight:600,paddingRight:2,marginTop:5 }} className="d-flex  justify-content-end align-item-center">For Institutional Use Only</div>*/}
      </div>
    </div>
  </div>
)

const PageFooter = ({ date, page, total, extraContent = false }) => (
  <div className="page-footer d-print-block">
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between">
        <span>
          Please review the Disclosures contained at the end of this document
          for more information.
          {extraContent
            ? (page === 3 || page === 4) && (
                <>
                  <br />
                  *Please refer to the glossary of terms for more information.
                </>
              )
            : (page === 2 ||
                page === 3 ||
                page === 5 ||
                page === 6 ||
                page === 7 ||
                page === 8) && (
                <>
                  <br />
                  *Please refer to the glossary of terms for more information.
                </>
              )}
        </span>
        <br />
        {page > 0 && (
          <span style={{ width: `100px`, textAlign: `end` }}>
            Page{' '}
            {total && total !== '-'
              ? `${page} | ${total < 10 ? '0' + total : total}`
              : `${page}`}
          </span>
        )}
      </div>
    </div>
  </div>
)

const pageNum = (function*() {
  let n = 0
  while (true) yield n++
})()

import React, { createElement } from 'react';
import * as pathToRegexp from 'path-to-regexp';
import { getMenuData } from './routermenu';
import BuildPortfolio from 'layouts/WebDashboard/BuildPortfolio';
import OnBoardScreen from 'layouts/WebDashboard/BuildPortfolio/Pages/OnBoardScreen/OnBoardScreen';
import AssetAllocation from 'layouts/WebDashboard/BuildPortfolio/Pages/AssetAllocation/index';
import MarketPlace from 'layouts/WebDashboard/BuildPortfolio/Pages/MarketPlace/index';
import BuildFromScratch from 'layouts/WebDashboard/BuildPortfolio/Pages/BuildFromScratch';
import CustomUploadPortfolio from 'layouts/WebDashboard/BuildPortfolio/Pages/CustomUploadPortfolio';
import Exception403 from 'layouts/WebDashboard/BuildPortfolio/Pages/Exception/403';
import Exception404 from 'layouts/WebDashboard/BuildPortfolio/Pages/Exception/404';
import Exception500 from 'layouts/WebDashboard/BuildPortfolio/Pages/Exception/500';

// {"nav": [
// {
//   "label": "HOME",
//   "route": {
//     "url": "/",
//     "exact": true
//   },
//   "component": {
//     "path": "home",
//     "name": "home"
//   }
// }}
// createRoutes() {
//     return this.props.data.nav.map((item: any, index: number) => {
//         let DynamicComponent = require('../../' + item.component.path + '/' + item.component.name).default;
//         return <Route key={index} path={item.route.url}
//                       exact={item.route.exact}
//                       render={() => (<DynamicComponent key={item.component.name}/>)}/>
//     });
// }

function getFlatMenuData(menus) {
  let keys = {};
  menus.forEach((item) => {
    if (item.children) {
      keys[item.path] = { ...item };
      keys = { ...keys, ...getFlatMenuData(item.children) };
    } else {
      keys[item.path] = { ...item };
    }
  });
  return keys;
}

export const getRouterData = () => {
  const self_role = (window.localStorage.getItem('profile_self_role')) ? window.localStorage.getItem('profile_self_role') : "loggedOut";

  let routerConfig = {
    '/build-portfolio': {
      component: BuildPortfolio,
      authority: 'user',
      protected: false,
    },
    '/build-portfolio/onboard-screen': {
      component: OnBoardScreen,
      authority: 'user',
      protected: false,
    },
    '/build-portfolio/market-place/:name': {
      component: MarketPlace,
      authority: 'user',
      protected: true,
    },
    '/build-portfolio/custom/:name': {
      component: CustomUploadPortfolio,
      authority: 'user',
      protected: true,
    },
    '/build-portfolio/asset-allocation': {
      component: AssetAllocation,
      authority: 'user',
      protected: true,
    },
    '/build-portfolio/scratch/': {
      component: BuildFromScratch,
      authority: 'user',
      protected: true,
    },
    '/build-portfolio/exception/403': {
      component: Exception403,
    },
    '/build-portfolio/exception/404': {
      component: Exception404,
    },
    '/build-portfolio/exception/500': {
      component: Exception500,
    },
  };

  if (["","Other/Curious", "Individual Investor"].includes(self_role) === true) {
    delete routerConfig['/build-portfolio/onboard-screen']

  }
  // Get name from ./menu.js or just set it in the router data.
  const menuData = getFlatMenuData(getMenuData());
  // Route configuration data
  // eg. {name,authority ...routerConfig }
  const routerData = {};
  // The route matches the menu
  Object.keys(routerConfig).forEach((path) => {
    // Regular match item name
    // eg.  router /user/:id === /user/chen
    const pathRegexp = pathToRegexp(path);
    const menuKey = Object.keys(menuData).find(key => pathRegexp.test(`${key}`));
    let menuItem = {};
    // If menuKey is not empty
    if (menuKey) {
      menuItem = menuData[menuKey];
    }
    let router = routerConfig[path];
    // If you need to configure complex parameter routing,
    // https://github.com/ant-design/ant-design-pro-site/blob/master/docs/router-and-nav.md#%E5%B8%A6%E5%8F%82%E6%95%B0%E7%9A%84%E8%B7%AF%E7%94%B1%E8%8F%9C%E5%8D%95
    // eg . /list/:type/user/info/:id
    router = {
      ...router,
      name: router.name || menuItem.name,
      authority: router.authority || menuItem.authority,
      protected: router.protected || menuItem.protected,
    };
    routerData[path] = router;
  });
  // console.log(routerData);
  return routerData;
};

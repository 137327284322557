import React, { Component } from 'react';
import { addEventToAnalytics } from 'Utils';

const classNames = require('classnames');

class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '', active: false };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.query) {
      (this.state.value === '' || newProps.query === this.state.value) && this.setState({ value: newProps.query });
      (this.props.query !== newProps.query) && this.setState({ value: newProps.query });
    }
  }

  handleChange(event) {
    this.setState({ value: event.target.value, active: this.validQuery(event.target.value) });
    // action
  }

  handleSubmit(event) {
    const { loggedIn } = this.props;
    event.preventDefault();
    if (this.validQuery(this.state.value)) {
      // this.props.history.push('/securities');
      this.props.queryHandler(this.state.value.trim(), 'search_bar');
    }
    // addEventToAnalytics('Search Bar','Search Bar','SEARCH_BAR',{ query: this.state.value.trim(), loggedIn });
  }

  clearSearch = () => {
    this.setState({
      value: '',
    });
    document.getElementById("fund-search-box").focus();
  }

  validQuery = (query) => query.trim().length > 0

  render() {
    const { placeholder } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className={classNames("input-group h2 mb-0 search-box")}>
          <input
            id="fund-search-box"
            value={this.state.value}
            onChange={this.handleChange}
            className="form-control form-control-lg rounded bg-white"
            type="text"
            placeholder={placeholder}
            autoComplete="off"
            aria-label="Search"
            style={{ paddingRight: (this.state.value && this.state.value.length > 0) ? '60px' : '40px' }}
          />
          {(this.state.value && this.state.value.length > 0) && (
            <i className={`far fa-times fa-lg text-secondary clear-icon ${this.state.clearSearch ? '' : ''} ${this.state.active ? 'active' : ''}`}
              onClick={this.clearSearch} />
          )}
          <i className={classNames("far fa-fw fa-search fa-lg text-secondary search-icon", {'active': this.state.active})}
            onClick={this.handleSubmit} />
        </div>
      </form>
    );
  }
}

export default SearchBox;

export const SimilarityMeasures =  [
	{
		label: 'Fund Category',
		key: 'fund_category',
		tooltip: 'Find replacements for funds held from the same category they belong to'
	},	
	{
		label: 'Holdings Overlap',
		key: 'overlap',
		tooltip: 'Find replacements for funds held that have similar holdings when available, else funds with similar returns'
	},
	{
		label: 'Return Correlation',
		key: 'correlation',
		tooltip: 'Find replacements for funds held that have similar returns when available, else funds with similar holdings'
	}
];

export const ModelsPortfolios =  [
  {
    label: 'Sponsor Models',
    key: 'mp',
  },
  {
    label: 'My Models',
    key: 'up',
  }
];

export const FundUniverse =  [
  {
    label: 'ETFs and MFs',
    key: 'etf,etn,mf,cef',
  }, {
    label: 'ETFs',
    key: 'etf,etn',
  }, {
    label: 'Mutual Funds',
    key: 'mf,cef',
  }
];

export const EnhancePositionsUniverse =  [
  {
    label: 'ETF',
    key: 'etf,etn',
		premiumFeature: false,
		featureName: '',
		tooltip: 'Exchanged Traded Fund',
		tooltipTargetKey: 'EnhancePositionsUniverse_etf',
  }, {
    label: 'MF',
    key: 'mf',
		premiumFeature: false,
		featureName: '',
		tooltip: 'Mutual Funds',
		tooltipTargetKey: 'EnhancePositionsUniverse_mf',
  }, {
		label: 'CEF',
		key: 'cef',
		premiumFeature: false,
		featureName: '',
		tooltip: 'Closed End Funds',
		tooltipTargetKey: 'EnhancePositionsUniverse_cef',
	}, {
		label: 'SMA',
		key: 'sma',
		premiumFeature: false,
		featureName: 'sma_switch',
		tooltip: 'Separately Managed Accounts are a portfolio of securities managed by a professional investment firm',
		tooltipTargetKey: 'EnhancePositionsUniverse_sma',
	}
];

export const EnhancePortfolioUniverse =  [
  {
    label: 'Sponsored Models',
    key: 'mp',
		premiumFeature: false,
		featureName: '',
		tooltip: 'Model portfolios are a crafted bundle of diversified funds, grouped together to provide an expected return with a corresponding amount of risk',
		tooltipTargetKey: 'EnhancePortfolioUniverse_mp',
  }, {
		label: 'My Models',
    key: 'up',
		premiumFeature: false,
		featureName: '',
		tooltip: 'Custom user created models',
		tooltipTargetKey: 'EnhancePortfolioUniverse_up',
  }
];

export const topQueryList = {
	'similar'                       : 'Lower Cost Alternatives',
	'safe_alternative'              : 'Lower Risk Alternatives',
	'low_cost_low_risk_replacement' : 'Lower Cost And Risk Alternatives',
	'lower_drawdown_alternatives' 	: 'Lower Drawdown Alternatives',
	'high_yield_alternative'        : 'Highest Yield Alternatives',
	'long_track_record_alternatives': 'Long Track Record Alternatives',
	'lowest_tracking_error_option'  : 'Lower Tracking Error Alternatives',
	'high_sharpe_alternative'       : 'Highest Risk Adjusted Return Alternatives',
	'outperform'                    : 'Best Performing Options In Category',
	// 'user_criteria'                 : 'Best Alternatives Using Passive Fund Selector',
};

export const modelQuery = {
	'similar' 											: 'low_cost_alternative_models',
	'outperform' 										: 'outperform_models',
	'safe_alternative' 							: 'low_risk_alternative_models',
	'low_cost_low_risk_replacement'	:	'low_cost_low_risk_replacement_models',
	'lower_drawdown_alternatives'		:	'lower_drawdown_alternatives_models',
	'high_yield_alternative' 				: 'high_yield_alternative_models',
	'long_track_record_alternatives': 'long_track_record_alternatives_models',
	'lowest_tracking_error_option'	: 'lowest_tracking_error_option_models',
	'high_sharpe_alternative' 			: 'high_sharpe_alternative_models',
	'user_criteria' 								: 'user_criteria_models',
};

export const modelReverseQuery = {
	'low_cost_alternative_models'   				: 'similar',
	'outperform_models'											: 'outperform',
	'low_risk_alternative_models'   				: 'safe_alternative',
	'low_cost_low_risk_replacement_models'	:	'low_cost_low_risk_replacement',
	'lower_drawdown_alternatives_models'		:	'lower_drawdown_alternatives',
	'high_yield_alternative_models' 				: 'high_yield_alternative',
	'long_track_record_alternatives_models' : 'long_track_record_alternatives',
	'lowest_tracking_error_option_models'		:	'lowest_tracking_error_option',
	'high_sharpe_alternative_models'				: 'high_sharpe_alternative',
	'user_criteria_models'          				: 'user_criteria',
};

export default {
	modelQuery,
	modelReverseQuery,
	topQueryList,
	FundUniverse,
	ModelsPortfolios,
	SimilarityMeasures,
	EnhancePositionsUniverse,
	EnhancePortfolioUniverse,
};

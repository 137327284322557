import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import {
	setEnhancerState,
	resetSummaryDataRequest,
} from 'actions/enhancer';
import { addEventToAnalytics } from 'Utils'

class ContentHead extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

	// routeToOnBoardScreen = () => {
	// 	addEventToAnalytics('Enhancer Edit Home','Enhancer Edit Home','ENHANCER_EDIT_HOME',{},false);
	// 	const { history, resetSummaryDataRequest } = this.props;
	// 	if (resetSummaryDataRequest && history) {
	// 		resetSummaryDataRequest('', () => {
	// 			const newPath = `/enhancer/onboard-screen`;
	// 			history.push(newPath);
	// 		})
	// 	}
	// }

	drawerToggle = () => {
		const { summaryDrawer, openDrawer } = this.props;
		if (openDrawer) {
			openDrawer(!summaryDrawer);
		}
	}

  render() {
		const { portfolioName, portfolioType, sponsoredPortfolioName, profileData } = this.props;
		const { first_name, last_name } = profileData;
		let Name = ((first_name !== '' && first_name && typeof first_name !== 'undefined') || (last_name !== '' && last_name && typeof last_name !== 'undefined')) ? first_name+' '+last_name : null;
		return (
      <React.Fragment>
				<div id="funds-board-head" className="funds-board-head">
					<Row id="funds-board-content-header" className="accounts-page-label">
						{Name && (
							<span style={{ color: '#494949', paddingRight: 5 }}>{Name}: </span>
						)}
						<span>
							{(portfolioType && portfolioType === 'MarketPlace' && sponsoredPortfolioName && sponsoredPortfolioName !== '') ? sponsoredPortfolioName : portfolioName}
						</span>
					</Row>
				</div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ router, loading, accounts, profile }) => ({
  location: router.location,
	portfolioName: accounts.portfolioName,
	portfolioType: accounts.portfolioType,
	sponsoredPortfolioName: accounts.sponsoredPortfolioName,
	profileData: profile.profile,
});

const mapDispatchToProps = {
	setEnhancerState,
	resetSummaryDataRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentHead);

import moment from 'moment';
import _ from 'lodash';
import { categoryCol, cn } from 'DataSet';
import { nameToDateRange, getQuintile, getNest, arrayToHash, monthToDate, dateToMonth, getNestDefault } from 'Utils';
import { calcStats } from 'Stats';

export const dateSort = (a, b) => {
  let _a = monthToDate(a.d), _b = monthToDate(b.d);
  return _a.isBefore(_b) ? -1 : (_a.isAfter(_b) ? 1 : 0) ;
}

export const getLineChartMatrix = (range, dataArr, name) => {
  const arr = JSON.parse(JSON.stringify(dataArr));
  const dd = moment(range.start);
  if (!dd.isValid()) {
    range = {
      start: moment("01 "+moment(range.start, 'MMM YYYY')._i, "DD MMM YYYY"),
      end: moment("01 "+moment(range.end, 'MMM YYYY')._i, "DD MMM YYYY"),
    }
  }

  const rangeFilter = e => {
    let dt = monthToDate(e.d);
    return dt.isAfter(moment(range.start).subtract(1, 'seconds')) && dt.isBefore(moment(range.end).add(1, 'seconds'));
  }

  const dateRangeMonthList = range => {
    let out = [];  // List of months
    let rangeEnd = moment(range.end);
    for (let m = moment(range.start); m.isSameOrBefore(rangeEnd); m.add(1, 'months')) {
      out.push(dateToMonth(m));
    }
    return out;
  }

  const valTraslate = v => v < 0 ? -1*Math.log10((100-v)/100) : Math.log10((100+v)/100);

  let rMin = 1e6, rMax = -1e6;

  let x = dateRangeMonthList(range);

  if (arr && arr.length) {
    let points = arr.filter(rangeFilter);
    if (points.length <= 0) {
      return;
    }

    points.forEach((item, i) => {
      item.v = item.v/100
    });

    let stats = calcStats(points);

    let series = x.map(d => {
      let val = getNest([d, 'cuml'], stats.series);
      let orgVal = getNest([d, 'ret'], stats.series);

      let ival = val !== undefined ? valTraslate(val) : val,
        ival2 = ival !== undefined ? getNest([d, 'ret'], stats.series) : 'Inactive';

      if (ival < rMin) rMin = ival;
      if (ival > rMax) rMax = ival;

      return { x: d, y1: ival, y2: orgVal, y3: ival2, y4: val };
    });

    const obj = {
      _stats: { ...stats, series },
      _returnRange: { min: -rMax, max: rMax },
      label: `$${Math.round(stats.cuml+100)}`,
      lastValue: Math.round(stats.cuml+100),
      dateRange: range,
    }
    return obj;
  }
}

const calculatePoints = (ret, year) => {
  let returns = JSON.parse(JSON.stringify(ret));
  const strt = (returns[returns.length - 1].d).split(' ');
  const nd = (returns[0].d).split(' ');
  if (strt[1] > nd[1]) {
    // strt date is '2020' and 'nd' date is '2008'
    returns.reverse();
  }
  const start = moment(new Date(`01 ${returns[returns.length - 1].d}`));
  const end = moment(new Date(`01 ${returns[0].d}`));
  const DateRangeMoment = { start, end };
  const range = {
    start: nameToDateRange(year, DateRangeMoment).start,
    end: nameToDateRange(year, DateRangeMoment).end,
  };
  return returns.filter((e) => {
    let dt = monthToDate(e.d);
    return dt.isAfter(moment(range.start).subtract(1, 'seconds')) && dt.isBefore(moment(range.end).add(1, 'seconds'));
  });
}

export const getGeoMean = (benchmarkArray, retArray) => {
  //Geometric mean = (x1 * x2 * x3 * . . . * xn)^1/n
  let benchmarkReturnsArrayByYears = JSON.parse(JSON.stringify(benchmarkArray));
  let returnsArrayByYears = JSON.parse(JSON.stringify(retArray));
  // console.log('sid sid 0', benchmarkReturnsArrayByYears, returnsArrayByYears)
  if (!benchmarkReturnsArrayByYears || typeof benchmarkReturnsArrayByYears === 'undefined' || benchmarkReturnsArrayByYears.length === 0 ) return null;
  if (!returnsArrayByYears || typeof returnsArrayByYears === 'undefined' || returnsArrayByYears.length === 0 ) return null;
  let benchUpsum = 1.0, benchDownsum = 1.0;
  let upsum = 1.0, downsum = 1.0;

  //create array of objects by date
  const benchUserIndex = _.keyBy(benchmarkReturnsArrayByYears, 'd');
  const userIndex = _.keyBy(returnsArrayByYears, 'd');
  // console.log('sid sid 1', benchUserIndex, userIndex)
  //filter the positives from benchmark array
  // const benchFilterARRAY = _.map(benchUserIndex, 'v');
  // const filterARRAY = _.map(userIndex, 'v');

  const upDates = _.filter(_.map(benchUserIndex, function(e) {if(e.v >= 0) {return e.d;}}),function(o) {if (o) return o});
  const downDates = _.filter(_.map(benchUserIndex, function(e) {if(e.v < 0) {return e.d;}}),function(o) {if (o) return o});
  // console.log('sid sid 2', upDates, downDates)
  let benchUpSeries = [], benchDownSeries = [], upSeries = [], downSeries = [];

  benchUpSeries = _.map(benchmarkReturnsArrayByYears.filter(e => {if(upDates.indexOf(e.d) > -1) return e;}),'v');
  benchDownSeries = _.map(benchmarkReturnsArrayByYears.filter(e => {if(downDates.indexOf(e.d) > -1) return e;}),'v');
  // console.log('sid sid 3', benchUpSeries, benchDownSeries)
  // upSeries = filterARRAY.filter(e => e>=0);
  // downSeries = filterARRAY.filter(e => e<0);
  upSeries = _.map(returnsArrayByYears.filter(e => {if(upDates.indexOf(e.d) > -1) return e;}),'v');
  downSeries = _.map(returnsArrayByYears.filter(e => {if(downDates.indexOf(e.d) > -1) return e;}),'v');


  benchUpSeries.forEach(e => {benchUpsum *= (e+1);});
  benchDownSeries.forEach(e => {benchDownsum *= (e+1);});
  upSeries.forEach(e => {upsum *= (e+1);});
  downSeries.forEach(e => {downsum *= (e+1);});

  const benchGeoMean = {
    up : Math.pow(benchUpsum,(1/benchUpSeries.length)),
    down : Math.pow(benchDownsum,(1/benchDownSeries.length))
  };
  const GeoMean = {
    up : Math.pow(upsum,(1/upSeries.length)),
    down : Math.pow(downsum,(1/downSeries.length))
  }
  return { ret: GeoMean, bench: benchGeoMean};
}

// export const geoMeanCalc = (portfolioBenchmark, pfRet1y, pfRet3y, pfRet5y)=> {
//   if (portfolioBenchmark){
//     let pfb = JSON.parse(JSON.stringify(portfolioBenchmark))
//     let pf1 = JSON.parse(JSON.stringify(pfRet1y))
//     let pf3 = JSON.parse(JSON.stringify(pfRet3y))
//     let pf5 = JSON.parse(JSON.stringify(pfRet5y))
//     pfb.forEach((item,index) => {
//       pfb[index].v = item.v/100;
//     });
//     pf1.forEach((item,index) => {
//       pf1[index].v = item.v/100;
//     });
//     pf3.forEach((item,index) => {
//       pf3[index].v = item.v/100;
//     });
//     pf5.forEach((item,index) => {
//       pf5[index].v = item.v/100;
//     });
// 		let up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr
//
// 		const start = moment(new Date(`01 ${portfolioBenchmark[portfolioBenchmark.length - 1].d}`))
// 		const end = moment(new Date(`01 ${portfolioBenchmark[0].d}`))
// 		const DateRangeMoment = { start, end }
//
//     const calculatePoints = (ret, year, startFrom) => {
//       let returns = JSON.parse(JSON.stringify(ret))
//       const range = {
//         start: nameToDateRange(year, DateRangeMoment, startFrom).start._d,
//         end: nameToDateRange(year, DateRangeMoment, startFrom).end._d,
//       }
//       // console.log(range);
//       return returns.filter((e) => {
//         let dt = monthToDate(e.d)
//         return dt.isAfter(moment(range.start).subtract(1, 'seconds')) &&
//           dt.isBefore(moment(range.end).add(1, 'seconds'))
//       })
//     }
//
// 		let benchPoints1y = calculatePoints(pfb, 'l1y')
// 		let benchPoints3y = calculatePoints(pfb, 'l3y')
// 		let benchPoints5y = calculatePoints(pfb, 'l5y')
//     const res1 = getGeoMean(benchPoints1y,pf1)
// 		const res3 = getGeoMean(benchPoints3y,pf3)
//     const res5 = getGeoMean(benchPoints5y,pf5)
//
//     const geomean_bench_1y = res1.bench
// 		const geomean_bench_3y = res3.bench
// 		const geomean_bench_5y = res5.bench
//
// 		const geomean_port_1y = res1.ret
// 		const geomean_port_3y = res3.ret
//     const geomean_port_5y = res5.ret
//
// 		up_1yr = ((geomean_port_1y.up - 1) / (geomean_bench_1y.up - 1)) * 100
// 		down_1yr = ((geomean_port_1y.down - 1) / (geomean_bench_1y.down - 1)) * 100
// 		up_3yr = ((geomean_port_3y.up - 1) / (geomean_bench_3y.up - 1)) * 100
// 		down_3yr = ((geomean_port_3y.down - 1) / (geomean_bench_3y.down - 1)) * 100
// 		up_5yr = ((geomean_port_5y.up - 1) / (geomean_bench_5y.up - 1)) * 100
// 		down_5yr = ((geomean_port_5y.down - 1) / (geomean_bench_5y.down - 1)) * 100
//
// 		up_1yr = up_1yr ? parseFloat(up_1yr.toFixed(2)) : null
// 		down_1yr = down_1yr ? (parseFloat(down_1yr.toFixed(2))*-1) : null
// 		up_3yr = up_3yr ? parseFloat(up_3yr.toFixed(2)) : null
// 		down_3yr = down_3yr ? (parseFloat(down_3yr.toFixed(2))*-1) : null
// 		up_5yr = up_5yr ? parseFloat(up_5yr.toFixed(2)) : null
// 		down_5yr = down_5yr ? (parseFloat(down_5yr.toFixed(2))*-1) : null
//
// 		// console.log(up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr)
// 		return { up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr }
// 	}
//   else {
// 		console.log('error')
// 		return null
// 	}
// }

export const geoMeanCalc = (portfolioBenchmark, pfRet1y, pfRet3y, pfRet5y, pfRet10y) => {
  if (portfolioBenchmark) {
    // console.log(pfRet1y)
    let pfb = portfolioBenchmark ? JSON.parse(JSON.stringify(portfolioBenchmark)) : [];
    let pf1 = pfRet1y ? JSON.parse(JSON.stringify(pfRet1y)) : [];
    let pf3 = pfRet3y ? JSON.parse(JSON.stringify(pfRet3y)) : [];
    let pf5 = pfRet5y ? JSON.parse(JSON.stringify(pfRet5y)) : [];
    let pf10 = pfRet10y ? JSON.parse(JSON.stringify(pfRet10y)) : [];
    pfb.forEach((item,index) => {
      pfb[index].v = item.v/100;
    });
    pf1.forEach((item,index) => {
      pf1[index].v = item.v/100;
    });
    pf3.forEach((item,index) => {
      pf3[index].v = item.v/100;
    });
    pf5.forEach((item,index) => {
      pf5[index].v = item.v/100;
    });
    pf10.forEach((item,index) => {
      pf10[index].v = item.v/100;
    });
    // console.log('geopMeanCalc')
		let up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr, up_10yr, down_10yr;

		const start = moment(new Date(`01 ${portfolioBenchmark[portfolioBenchmark.length - 1].d}`))
		const end = moment(new Date(`01 ${portfolioBenchmark[0].d}`))
		const DateRangeMoment = { start, end }

		const calculatePoints = (ret, year, startFrom) => {
			let returns = JSON.parse(JSON.stringify(ret))
			const range = {
				start: nameToDateRange(year, DateRangeMoment, startFrom).start._d,
				end: nameToDateRange(year, DateRangeMoment, startFrom).end._d,
			}
			// console.log(range);
			return returns.filter((e) => {
				let dt = monthToDate(e.d)
				return dt.isAfter(moment(range.start).subtract(1, 'seconds')) &&
					dt.isBefore(moment(range.end).add(1, 'seconds'))
			})
		}

		let benchPoints1y = calculatePoints(pfb, 'l1y');
		let benchPoints3y = calculatePoints(pfb, 'l3y');
		let benchPoints5y = calculatePoints(pfb, 'l5y');
    let benchPoints10y = calculatePoints(pfb, 'l10y');
    const res1 = getGeoMean(benchPoints1y,pf1);
		const res3 = getGeoMean(benchPoints3y,pf3);
		const res5 = getGeoMean(benchPoints5y,pf5);
    const res10 = benchPoints10y && pf10 ? getGeoMean(benchPoints10y,pf10) : res5;

    const geomean_bench_1y = res1.bench
		const geomean_bench_3y = res3.bench
		const geomean_bench_5y = res5.bench
    const geomean_bench_10y = res10 && res10.bench ? res10.bench : res5.bench

		const geomean_port_1y = res1.ret
		const geomean_port_3y = res3.ret
    const geomean_port_5y = res5.ret
    const geomean_port_10y = res10 && res10.ret ? res10.ret : res5.ret

		up_1yr = ((geomean_port_1y.up - 1) / (geomean_bench_1y.up - 1)) * 100
		down_1yr = ((geomean_port_1y.down - 1) / (geomean_bench_1y.down - 1)) * 100
		up_3yr = ((geomean_port_3y.up - 1) / (geomean_bench_3y.up - 1)) * 100
		down_3yr = ((geomean_port_3y.down - 1) / (geomean_bench_3y.down - 1)) * 100
		up_5yr = ((geomean_port_5y.up - 1) / (geomean_bench_5y.up - 1)) * 100
		down_5yr = ((geomean_port_5y.down - 1) / (geomean_bench_5y.down - 1)) * 100
    up_10yr = ((geomean_port_10y.up - 1) / (geomean_bench_10y.up - 1)) * 100
    down_10yr = ((geomean_port_10y.down - 1) / (geomean_bench_10y.down - 1)) * 100

		up_1yr = up_1yr ? Math.round(up_1yr) : null
		down_1yr = down_1yr ? Math.round((down_1yr)*-1) : null
		up_3yr = up_3yr ?Math.round( up_3yr) : null
		down_3yr = down_3yr ? Math.round((down_3yr)*-1) : null
		up_5yr = up_5yr ?Math.round( up_5yr) : null
		down_5yr = down_5yr ? Math.round((down_5yr)*-1) : null
    up_10yr = up_10yr ?Math.round( up_10yr) : null
    down_10yr = down_10yr ? Math.round((down_10yr)*-1) : null
    // console.log(up_1yr)
    return { up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr, up_10yr, down_10yr }
	}else {
		console.log('error')
		return null
	}
}

export const getCalculatedStats = (currentP,isDownload, portfolioBenchmark={}) => {
	// console.log('getCalculatedStats')
	const currentPf = JSON.parse(JSON.stringify(currentP));
  let portfolioSumValue = 0.0, portfolioSumExpenseRatioValue = 0.0, portfolioExpenseRatio = 0.0, weightSum = 0.0;
  let yieldSum = 0.0, outperformSum = 0.0, trackRecord = 0.0;
  const  currentYear = moment(new Date()).year();
  currentPf.forEach((e,index) => {
    // if (typeof e._pF === 'undefined') return;

    e._realTimePrice = e.nav; // comment it later

    const returnsArr = e.return ? (e.return.returns) : e.returns;

    currentPf[index].points1y = calculatePoints(returnsArr, 'l1y');
    currentPf[index].points3y = calculatePoints(returnsArr, 'l3y');
    currentPf[index].points5y = calculatePoints(returnsArr, 'l5y');
    currentPf[index].pointsHistorical = {};
    if(isDownload){
      currentPf[index].points10y = calculatePoints(returnsArr, 'l10y');
      currentPf[index].pointsHistorical.points1y  = calculatePoints(returnsArr, 'l10y');
      currentPf[index].pointsHistorical.points2y  = calculatePoints(returnsArr, 'l10y', currentYear - 1);
      currentPf[index].pointsHistorical.points3y  = calculatePoints(returnsArr, 'l10y', currentYear - 2);
      currentPf[index].pointsHistorical.points4y  = calculatePoints(returnsArr, 'l10y', currentYear - 3);
      currentPf[index].pointsHistorical.points5y  = calculatePoints(returnsArr, 'l10y', currentYear - 4);
      currentPf[index].pointsHistorical.points6y  = calculatePoints(returnsArr, 'l10y', currentYear - 5);
      currentPf[index].pointsHistorical.points7y  = calculatePoints(returnsArr, 'l10y', currentYear - 6);
      currentPf[index].pointsHistorical.points8y  = calculatePoints(returnsArr, 'l10y', currentYear - 7);
      currentPf[index].pointsHistorical.points9y  = calculatePoints(returnsArr, 'l10y', currentYear - 8);
      currentPf[index].pointsHistorical.points10y = calculatePoints(returnsArr, 'l10y', currentYear - 9);
    }

    currentPf[index].pfWeight = e._sliderValue;
    weightSum += currentPf[index].pfWeight;
    currentPf[index].retWeighted1y = [];
    currentPf[index].retWeighted3y = [];
    currentPf[index].retWeighted5y = [];
    if(isDownload){
      currentPf[index].retWeighted10y = [];
      currentPf[index].retWeightedHistorical = {
        retWeighted1y : [],
        retWeighted2y : [],
        retWeighted3y : [],
        retWeighted4y : [],
        retWeighted5y : [],
        retWeighted6y : [],
        retWeighted7y : [],
        retWeighted8y : [],
        retWeighted9y : [],
        retWeighted10y : []
      };
    }


    e.points1y.forEach((el, index1) => {
      el.v = el.v * currentPf[index].pfWeight; //multiplied by 100 to negate the divide by 100 for charts -- removed for calcultion sid
      currentPf[index].retWeighted1y.push(el);
    });

    e.points3y.forEach((el, index1) => {
      el.v = el.v * currentPf[index].pfWeight;
      currentPf[index].retWeighted3y.push(el);
    });

    e.points5y.forEach((el, index1) => {
      el.v = el.v * currentPf[index].pfWeight;
      currentPf[index].retWeighted5y.push(el);
    });
    if(isDownload){
      e.points10y.forEach((el, index1) => {
        el.v = el.v * currentPf[index].pfWeight*100;
        currentPf[index].retWeighted10y.push(el);
      });

      e.pointsHistorical.points1y.forEach((el, index1) => {
        el.v = el.v * currentPf[index].pfWeight*100;
        currentPf[index].retWeightedHistorical.retWeighted1y.push(el);
      });

      e.pointsHistorical.points2y.forEach((el, index1) => {
        el.v = el.v * currentPf[index].pfWeight*100;
        currentPf[index].retWeightedHistorical.retWeighted2y.push(el);
      });

      e.pointsHistorical.points3y.forEach((el, index1) => {
        el.v = el.v * currentPf[index].pfWeight*100;
        currentPf[index].retWeightedHistorical.retWeighted3y.push(el);
      });

      e.pointsHistorical.points4y.forEach((el, index1) => {
        el.v = el.v * currentPf[index].pfWeight*100;
        currentPf[index].retWeightedHistorical.retWeighted4y.push(el);
      });

      e.pointsHistorical.points5y.forEach((el, index1) => {
        el.v = el.v * currentPf[index].pfWeight*100;
        currentPf[index].retWeightedHistorical.retWeighted5y.push(el);
      });

      e.pointsHistorical.points6y.forEach((el, index1) => {
        el.v = el.v * currentPf[index].pfWeight*100;
        currentPf[index].retWeightedHistorical.retWeighted6y.push(el);
      });

      e.pointsHistorical.points7y.forEach((el, index1) => {
        el.v = el.v * currentPf[index].pfWeight*100;
        currentPf[index].retWeightedHistorical.retWeighted7y.push(el);
      });

      e.pointsHistorical.points8y.forEach((el, index1) => {
        el.v = el.v * currentPf[index].pfWeight*100;
        currentPf[index].retWeightedHistorical.retWeighted8y.push(el);
      });

      e.pointsHistorical.points9y.forEach((el, index1) => {
        el.v = el.v * currentPf[index].pfWeight*100;
        currentPf[index].retWeightedHistorical.retWeighted9y.push(el);
      });

      e.pointsHistorical.points10y.forEach((el, index1) => {
        el.v = el.v * currentPf[index].pfWeight*100;
        currentPf[index].retWeightedHistorical.retWeighted10y.push(el);
      });

    }

  });
  // end for each portfolio
  //
  // start pf ret calc
  let pfRet1y = getMonthFromYearDiff(1);
  let pfRet3y = getMonthFromYearDiff(3);
  let pfRet5y = getMonthFromYearDiff(5);
  let pfRet10y = getMonthFromYearDiff(10);
  let historical ={};

  if(isDownload){
    historical.pfRet1y  = getMonthFromYearDiff(1);
    historical.pfRet2y  = getMonthFromYearDiff(1, currentYear - 1);
    historical.pfRet3y  = getMonthFromYearDiff(1, currentYear - 2);
    historical.pfRet4y  = getMonthFromYearDiff(1, currentYear - 3);
    historical.pfRet5y  = getMonthFromYearDiff(1, currentYear - 4);
    historical.pfRet6y  = getMonthFromYearDiff(1, currentYear - 5);
    historical.pfRet7y  = getMonthFromYearDiff(1, currentYear - 6);
    historical.pfRet8y  = getMonthFromYearDiff(1, currentYear - 7);
    historical.pfRet9y  = getMonthFromYearDiff(1, currentYear - 8);
    historical.pfRet10y = getMonthFromYearDiff(1, currentYear - 9);
  }

  let outd1 = {n: 0, dMax: 0.0}, cuml1 = 0.0;
  pfRet1y.forEach((el, index1) => {
    currentPf.forEach((e, index2) => {
      e.points1y.forEach((el1, index11) => {
        if(el.d == el1.d) {
          pfRet1y[index1].v += el1.v
        }
      });
    });
    // pfRet1y[index1].v = pfRet1y[index1].v/weightSum;
    //calculate drawdown
    let r = pfRet1y[index1].v/100;
     cuml1 = (1 + cuml1)*(1 + r) - 1;
     outd1.n += 1;
     if (outd1.n === 1) {
       outd1.cumlMax = cuml1;
     }
     else {
       (cuml1 > outd1.cumlMax) && (outd1.cumlMax = cuml1);
       let dmax = (outd1.cumlMax-cuml1)/(1+outd1.cumlMax);
       (dmax > outd1.dMax) && (outd1.dMax = dmax);
     }
  });

  let outd = {n: 0, dMax: 0.0}, cuml = 0.0;

  pfRet3y.forEach((el, index1) => {
    currentPf.forEach((e, index2) => {

      e.points3y.forEach((el1, index11) => {
        if(pfRet3y[index1].d == el1.d) {
          pfRet3y[index1].v += el1.v
        }
      });
    });
    // pfRet3y[index1].v = pfRet3y[index1].v/weightSum;
    //calculate drawdown
    let r = pfRet3y[index1].v/100;
     cuml = (1 + cuml)*(1 + r) - 1;
     outd.n += 1;
     if (outd.n === 1) {
       outd.cumlMax = cuml;
     }
     else {
       (cuml > outd.cumlMax) && (outd.cumlMax = cuml);
       let dmax = (outd.cumlMax-cuml)/(1+outd.cumlMax);
       (dmax > outd.dMax) && (outd.dMax = dmax);
     }
  });

  let outd5 = {n: 0, dMax: 0.0}, cuml5 = 0.0;
  pfRet5y.forEach((el, index1) => {
    currentPf.forEach((e, index2) => {

      e.points5y.forEach((el1, index11) => {
        if(pfRet5y[index1].d == el1.d) {
          pfRet5y[index1].v += el1.v
        }
      });
    });
    // pfRet5y[index1].v = pfRet5y[index1].v/weightSum;
    //calculate drawdown
    let r = pfRet5y[index1].v/100;
     cuml5 = (1 + cuml5)*(1 + r) - 1;
     outd5.n += 1;
     if (outd5.n === 1) {
       outd5.cumlMax = cuml5;
     }
     else {
       (cuml5 > outd5.cumlMax) && (outd5.cumlMax = cuml5);
       let dmax = (outd5.cumlMax-cuml5)/(1+outd5.cumlMax);
       (dmax > outd5.dMax) && (outd5.dMax = dmax);
     }
  });

  let asset_alloc = {}
  if(isDownload){
    pfRet10y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
        if (e.points10y && typeof e.points10y !== 'undefined') {
          e.points10y.forEach((el1, index11) => {
            if(pfRet10y[index1].d == el1.d) {
              pfRet10y[index1].v += el1.v
            }
          });
        }
      });
      if (typeof pfRet10y[index1].v !== 'undefined') {
        pfRet10y[index1].v = pfRet10y[index1].v/weightSum;
      }
    });

    historical.pfRet1y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
        if (e.pointsHistorical && typeof e.pointsHistorical !== 'undefined') {
          e.pointsHistorical.points1y.forEach((el1, index11) => {
            if(historical.pfRet1y[index1].d == el1.d) {
              historical.pfRet1y[index1].v += el1.v
            }
          });
        }
      });
      if (typeof historical.pfRet1y[index1].v !== 'undefined') {
        historical.pfRet1y[index1].v = historical.pfRet1y[index1].v/weightSum;
      }
    });

    historical.pfRet2y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
        if (e.pointsHistorical && typeof e.pointsHistorical !== 'undefined') {
          e.pointsHistorical.points2y.forEach((el1, index11) => {
            if(historical.pfRet2y[index1].d == el1.d) {
              historical.pfRet2y[index1].v += el1.v
            }
          });
        }
      });
      if (typeof historical.pfRet2y[index1].v !== 'undefined') {
        historical.pfRet2y[index1].v = historical.pfRet2y[index1].v/weightSum;
      }
    });

    historical.pfRet3y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
        if (e.pointsHistorical && typeof e.pointsHistorical !== 'undefined') {
          e.pointsHistorical.points3y.forEach((el1, index11) => {
            if(historical.pfRet3y[index1].d == el1.d) {
              historical.pfRet3y[index1].v += el1.v
            }
          });
        }
      });
      if (typeof historical.pfRet3y[index1].v !== 'undefined') {
        historical.pfRet3y[index1].v = historical.pfRet3y[index1].v/weightSum;
      }
    });

    historical.pfRet4y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
        if (e.pointsHistorical && typeof e.pointsHistorical !== 'undefined') {
          e.pointsHistorical.points4y.forEach((el1, index11) => {
            if(historical.pfRet4y[index1].d == el1.d) {
              historical.pfRet4y[index1].v += el1.v
            }
          });
        }
      });
      if (typeof historical.pfRet4y[index1].v !== 'undefined') {
        historical.pfRet4y[index1].v = historical.pfRet4y[index1].v/weightSum;
      }
    });

    historical.pfRet5y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
        if (e.pointsHistorical && typeof e.pointsHistorical !== 'undefined') {
          e.pointsHistorical.points5y.forEach((el1, index11) => {
            if(historical.pfRet5y[index1].d == el1.d) {
              historical.pfRet5y[index1].v += el1.v
            }
          });
        }
      });
      if (typeof historical.pfRet5y[index1].v !== 'undefined') {
        historical.pfRet5y[index1].v = historical.pfRet5y[index1].v/weightSum;
      }
    });

    historical.pfRet6y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
        if (e.pointsHistorical && typeof e.pointsHistorical !== 'undefined') {
          e.pointsHistorical.points6y.forEach((el1, index11) => {
            if(historical.pfRet6y[index1].d == el1.d) {
              historical.pfRet6y[index1].v += el1.v
            }
          });
        }
      });
      if (typeof historical.pfRet6y[index1].v !== 'undefined') {
        historical.pfRet6y[index1].v = historical.pfRet6y[index1].v/weightSum;
      }
    });

    historical.pfRet7y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
        if (e.pointsHistorical && typeof e.pointsHistorical !== 'undefined') {
          e.pointsHistorical.points7y.forEach((el1, index11) => {
            if(historical.pfRet7y[index1].d == el1.d) {
              historical.pfRet7y[index1].v += el1.v
            }
          });
        }
      });
      if (typeof historical.pfRet7y[index1].v !== 'undefined') {
        historical.pfRet7y[index1].v = historical.pfRet7y[index1].v/weightSum;
      }
    });

    historical.pfRet8y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
        if (e.pointsHistorical && typeof e.pointsHistorical !== 'undefined') {
          e.pointsHistorical.points8y.forEach((el1, index11) => {
            if(historical.pfRet8y[index1].d == el1.d) {
              historical.pfRet8y[index1].v += el1.v
            }
          });
        }
      });
      if (typeof historical.pfRet8y[index1].v !== 'undefined') {
        historical.pfRet8y[index1].v = historical.pfRet8y[index1].v/weightSum;
      }
    });

    historical.pfRet9y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
        if (e.pointsHistorical && typeof e.pointsHistorical !== 'undefined') {
          e.pointsHistorical.points9y.forEach((el1, index11) => {
            if(historical.pfRet9y[index1].d == el1.d) {
              historical.pfRet9y[index1].v += el1.v
            }
          });
        }
      });
      if (typeof historical.pfRet9y[index1].v !== 'undefined') {
        historical.pfRet9y[index1].v = historical.pfRet9y[index1].v/weightSum;
      }
    });

    historical.pfRet10y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
        if (e.pointsHistorical && typeof e.pointsHistorical !== 'undefined') {
          e.pointsHistorical.points10y.forEach((el1, index11) => {
            if(historical.pfRet10y[index1].d == el1.d) {
              historical.pfRet10y[index1].v += el1.v
            }
          });
        }
      });
      if (typeof historical.pfRet10y[index1].v !== 'undefined') {
        historical.pfRet10y[index1].v = historical.pfRet10y[index1].v/weightSum;
      }
    });
    //PF assetAllocation
    let assetPFFunds = 0, equitiesSum = 0.0, bondsSum = 0.0, commoditiesSum = 0.0, currenciesSum = 0.0, derivativesSum = 0.0, cashSum = 0.0, otherSum = 0.0;
    currentPf.forEach(e => {
      if(e.asset_alloc){
        assetPFFunds += 1;
        if(e.asset_alloc.equities) equitiesSum += e.asset_alloc.equities
        if(e.asset_alloc.bonds) bondsSum += e.asset_alloc.bonds;
        if(e.asset_alloc.commodities) commoditiesSum += e.asset_alloc.commodities;
        if(e.asset_alloc.currencies) currenciesSum += e.asset_alloc.currencies;
        if(e.asset_alloc.derivatives) derivativesSum += e.asset_alloc.derivatives;
        if(e.asset_alloc.cash) cashSum += e.asset_alloc.cash;
        if(e.asset_alloc.other) otherSum += e.asset_alloc.other;
      }
    });
     asset_alloc = {
      equities : assetPFFunds ? equitiesSum / assetPFFunds : 0,
      bonds : assetPFFunds ? bondsSum / assetPFFunds : 0,
      commodities : assetPFFunds ? commoditiesSum / assetPFFunds : 0,
      currencies : assetPFFunds ? currenciesSum / assetPFFunds : 0,
      derivatives : assetPFFunds ? derivativesSum / assetPFFunds : 0,
      cash : assetPFFunds ? cashSum / assetPFFunds : 0,
      other : assetPFFunds ? otherSum / assetPFFunds : 0,
    }

  }

  let up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr, up_10yr, down_10yr
  // if(!isDownload){
    //calculate up capture and down capture
    //getGeoMean of portfolioBenchmark and potfolio
    //
    const __ret = geoMeanCalc(portfolioBenchmark, pfRet1y, pfRet3y, pfRet5y, pfRet10y)
    if (__ret) {
      up_1yr = __ret.up_1yr
      down_1yr = __ret.down_1yr
      up_3yr = __ret.up_3yr
      down_3yr = __ret.down_3yr
      up_5yr = __ret.up_5yr
      down_5yr = __ret.down_5yr
      up_10yr = __ret.up_10yr
      down_10yr = __ret.down_10yr
    }
  // }

  let annualizedRet1y = getAnnualizedReturnsByYears(pfRet1y);
  let annualizedRet3y = getAnnualizedReturnsByYears(pfRet3y);
  let annualizedRet5y = getAnnualizedReturnsByYears(pfRet5y);
  let annualizedRet10y = getAnnualizedReturnsByYears(pfRet10y);
  let annualizedVolt1y = getAnnualizedVolatilityByYears(pfRet1y);
  let annualizedVolt3y = getAnnualizedVolatilityByYears(pfRet3y);
  let annualizedVolt5y = getAnnualizedVolatilityByYears(pfRet5y);
  let annualizedVolt10y = getAnnualizedVolatilityByYears(pfRet10y);
  let out= {}
  if(isDownload){
    out ={
      pfRet1y,
      pfRet3y,
      pfRet5y,
      pfRet10y,
      up_1yr,
      down_1yr,
      up_3yr,
      down_3yr,
      up_5yr,
      down_5yr,
      up_10yr,
      down_10yr,
      asset_alloc_current:asset_alloc,
      anRet1yr: parseFloat(getAnnualizedReturnsByYears(pfRet1y).toFixed(2)),
      anVol1yr: parseFloat(getAnnualizedVolatilityByYears(pfRet1y).toFixed(2)),
      anVol3yr: parseFloat(annualizedVolt3y.toFixed(2)),
      anRet3yr: parseFloat(annualizedRet3y.toFixed(2)),
      anVol5yr: parseFloat(getAnnualizedVolatilityByYears(pfRet5y).toFixed(2)),
      anRet5yr: parseFloat(getAnnualizedReturnsByYears(pfRet5y).toFixed(2)),
      anVol10yr: parseFloat(annualizedVolt10y.toFixed(2)),
      anRet10yr: parseFloat(annualizedRet10y.toFixed(2)),
      minus_1_sd_10yr: parseFloat(annualizedRet10y+(-1*annualizedVolt10y)),
      plus_1_sd_10yr: parseFloat(annualizedRet10y+(annualizedVolt10y*1)),
      dMax: parseFloat((outd.dMax*100).toFixed(2)),
      dMax1: parseFloat((outd1.dMax*100).toFixed(2)),
      dMax5: parseFloat((outd5.dMax*100).toFixed(2)),
      sharpe_ratio_3y: (annualizedRet3y/annualizedVolt3y).toFixed(2) == NaN ? 0.00 : (annualizedRet3y/annualizedVolt3y).toFixed(2),
      sharpe_pfRet1y: (annualizedRet1y/annualizedVolt1y).toFixed(2) == NaN ? 0.00 : (annualizedRet1y/annualizedVolt1y).toFixed(2),
      sharpe_pfRet3y: (annualizedRet3y/annualizedVolt3y).toFixed(2) == NaN ? 0.00 : (annualizedRet3y/annualizedVolt3y).toFixed(2),
      sharpe_pfRet5y: (annualizedRet5y/annualizedVolt5y).toFixed(2) == NaN ? 0.00 : (annualizedRet5y/annualizedVolt5y).toFixed(2),
    }
  }else{
    out ={
      pfRet1y,
      pfRet3y,
      pfRet5y,
      pfRet10y,
      anRet1yr: getAnnualizedReturnsByYears(pfRet1y).toFixed(2),
      anVol1yr: getAnnualizedVolatilityByYears(pfRet1y).toFixed(2),
      anVol3yr: annualizedVolt3y.toFixed(2),
      anRet3yr: annualizedRet3y.toFixed(2),
      anVol5yr: getAnnualizedVolatilityByYears(pfRet5y).toFixed(2),
      anRet5yr: getAnnualizedReturnsByYears(pfRet5y).toFixed(2),
      dMax: (outd.dMax*100).toFixed(2),
      dMax1: (outd1.dMax*100).toFixed(2),
      dMax5: (outd5.dMax*100).toFixed(2),
      sharpe_ratio_3y: (annualizedRet3y/annualizedVolt3y).toFixed(2) == NaN ? 0.00 : (annualizedRet3y/annualizedVolt3y).toFixed(2),
      sharpe_pfRet1y: (annualizedRet1y/annualizedVolt1y).toFixed(2) == NaN ? 0.00 : (annualizedRet1y/annualizedVolt1y).toFixed(2),
      sharpe_pfRet3y: (annualizedRet3y/annualizedVolt3y).toFixed(2) == NaN ? 0.00 : (annualizedRet3y/annualizedVolt3y).toFixed(2),
      sharpe_pfRet5y: (annualizedRet5y/annualizedVolt5y).toFixed(2) == NaN ? 0.00 : (annualizedRet5y/annualizedVolt5y).toFixed(2),
	    up_1yr,
	    down_1yr,
	    up_3yr,
	    down_3yr,
	    up_5yr,
	    down_5yr,
      up_10yr,
      down_10yr
    }
  }

  console.timeEnd('a')
  // if (isDownload) console.log('sid sid', out)
  return out;
}

export const getAnnualizedReturnsByYears = (retArray) => {
  let returnsArrayByYears = JSON.parse(JSON.stringify(retArray));
  if (!returnsArrayByYears || typeof returnsArrayByYears === 'undefined' || returnsArrayByYears.length === 0 ) return null;
  const userIndex = _.keyBy(returnsArrayByYears, 'd');
  const sum = _.sum(_.map(userIndex, 'v'));
  const mean = _.mean(_.map(userIndex, 'v'));
  const avg = (sum / returnsArrayByYears.length) || 0;
  const AnnualizedReturns = returnsArrayByYears.length >= 12 ? mean*12 : mean*returnsArrayByYears.length;

  return AnnualizedReturns;
}

export const getAnnualizedVolatilityByYears = (retArray) => {
  let returnsArrayByYears = JSON.parse(JSON.stringify(retArray));
  if (!returnsArrayByYears || typeof returnsArrayByYears === 'undefined' || returnsArrayByYears.length === 0 ) return null;
  const userIndex = _.keyBy(returnsArrayByYears, 'd');
  const filterARRAY = _.map(userIndex, 'v');
  const AnnualizedVolumes = std(filterARRAY)*Math.sqrt(12);

  return AnnualizedVolumes;
}


export const std = (arr) => {
  let array = JSON.parse(JSON.stringify(arr));
  var avg = _.sum(array) / array.length;
  return Math.sqrt(_.sum(_.map(array, (i) => Math.pow((i - avg), 2))) / array.length);
};

export const getMonthFromYearDiff = (yearDiff) => {
  const currentYear = moment(new Date()).year();
  const currentMonth = moment(new Date()).month();
  const startYear = moment(new Date()).month(currentMonth + 1).year(currentYear - yearDiff);
  const endYear = moment(new Date());
  const monthList = [];

  while (endYear > startYear || startYear.format('M') === endYear.format('M')) {
    monthList.push({d: startYear.format('MMM YYYY'), v: 0});
    startYear.add(1,'month');
  }

  return monthList;
}

export default {
  dateSort,
  getCalculatedStats,
  getLineChartMatrix,
};

import React from 'react';
// import axios from 'axios';

import { createAPIInstance } from 'apis/api';
// import { fetchCharts } from 'apis/funds'
import { uniqValues, getNestDefault, isViewPresentInChartTypes, arrayToHash } from 'Utils';
import { concatQuery } from '../shared/Utils';
import { cn, SectorsAttrs, CN } from 'DataSet';

/* Do not need complexity of redux store for data as page is loaded/rendered just once.
   Page wont be interative and data won't be modified.
   So using context as global store */
export const DataContext = React.createContext({});

// const ReportOptions = require('../reports-options.json');

const isDynamicChart = (view, query_var) => {
  // staticChart === false && chartType === "odometer"
  // let chartData =  card.chartData.length ? card.chartData.filter((item) => {
  //   return item.display_view_name === view
  // }) : [];
  // if(chartData.length){
  if (!query_var.Static && query_var.chart_type === 'odometer') {
    return true;
  }
  // }
  return false;
}

function fetchReportOptions(payload) {
  return createAPIInstance()
    .get('/user/get-compare-reports', { params: payload })
    .then(response => response.data)
}

function fetchSearchResult(payload) {
  return createAPIInstance()
    .get('computed-search', { params: payload })
    .then(response => response.data)
}

function fetchRealTimePrices(payload) {
  return createAPIInstance()
    .get('/realtime-prices', { params: payload })
    .then(response => response.data)
}

function fetchCorrelationData(payload) {
  return createAPIInstance()
    .get('/user/selector-correlation', { params: payload })
    .then(response => response.data)
}

function fetchYearlyReturns(payload) {
  return createAPIInstance()
    .get('/user/year-return-report', { params: payload })
    .then(response => response.data)
}

const getFundsUpdatedPrice = (res, funds) => {
  // console.log(res["funds"]);
  const priceData = arrayToHash(res["funds"], CN['Ticker']);
  funds.forEach(e => {
    if(e[CN['Vehicle']] == 'ETF' || e[CN['Vehicle']] == 'ETN')
      e[CN['Nav Price']] = getNestDefault([cn(e, 'Ticker'), 'price'], priceData, cn(e, 'Nav Price'));
  });
  return funds;
}

const addAnd = (array)=> array.reduce((acc,curr,index)=>{
  if(array.length===1) return curr.ticker
  if(index===array.length-1){
    return`${acc} and ${curr.ticker}`
  }else{
    if(index===0){
      return `${curr.ticker}`
    }
    return `${acc}, ${curr.ticker}`
  }
},'')


export const fetchData = async (id) => {
    const data = await fetchReportOptions({ id })
    let options = JSON.parse(data[0].json);
    // console.log('options ==> ', options);
    const correlationData = await fetchCorrelationData({ tickers: options.selectedFunds.join() });
    const yearlyReturnsData = await fetchYearlyReturns({ tickers: options.selectedFunds.join() });
    options.correlationData = correlationData;
    options.yearlyReturnsData = yearlyReturnsData;

    //there could be other best ticker depending on score. for combine they are calculated from ui and for compare as below.
    if (Object.keys(options.combine).length > 0) {
      options.bestTicker = options.combine.best.ticker
    } else {
      //compare report
      options.bestTicker = addAnd(options.compare[0].funds.filter(ticker=>ticker.score===options.compare[0].funds[0].score))
    }

    let funds = options.allFunds;

    // Filter queries that has selected funds and show relevant funds for valid queries
    const validQueries = [...new Set(options.selectedFundDetails.map(item => item.query))];
    const queryFunds = options.allFunds.queryFunds.filter(item => validQueries.includes(item.query));
    let validFunds = [];
    queryFunds.forEach(item => { validFunds = [...validFunds, ...item.funds] });
    validFunds = [...new Set([...validFunds, ...options.selectedFunds])];
    funds.funds = funds.funds.filter(item => validFunds.includes(item.ticker))

    options = options.isCompareOnly ?
      validateOptionsCompareOnly(options, uniqValues(funds.funds, 'ticker'), validQueries) :
      validateOptions(options, uniqValues(funds.funds, 'ticker'), validQueries);

    let allFunds = funds.funds, selectedFunds = [], otherFunds = [];

    allFunds.forEach(e => {
      let queryVar = e.query_var.filter(qv => {
        if (qv.active && qv.view_name && qv.view_name != "") {
          if(qv.view_name != 'Highlight'){
            return qv;
          }
        }
      })[0];
      e.queryVar = queryVar;

      if (e.asset_alloc && Object.keys(e.asset_alloc) && typeof e.asset_alloc.etf_mf_security_id !== 'undefined') {
        delete e.asset_alloc.etf_mf_security_id;
      }

      // console.log(e.ticker, queryVar);
      if(!queryVar || queryVar.length === 0)  {
        e._viewChartType = "assets"
        e._viewData = funds['Regions'][e.ticker];
      } else {
        // const queryvar = queryVar[0];
        const staticVal = queryVar.Static;
        // const viewName = queryVar.view_name;
        let viewName = queryVar.view_name;
        if (viewName && viewName.includes('ticker')) {
          if (Object.prototype.toString.call(queryVar.data) === '[object Array]') {
            viewName = viewName.replace("ticker", queryVar.data[0]);
          }
          else if (typeof queryVar.data === 'string') {
            viewName = viewName.replace("ticker", queryVar.data);
          }
        }

        e._viewName = viewName;
        // e._queryVar = queryVar;
        if (!staticVal) {
          if (!isViewPresentInChartTypes(viewName) && isDynamicChart(viewName, queryVar) && funds[viewName]) {
            e._viewChartType = "dynamicGaugeChart";
            e._viewData = funds[viewName][e.ticker];
          } else if(viewName === "Themes" && funds[viewName]) {
            e._viewChartType = "carousel";
            e._viewData = funds[viewName][e.ticker];
          } else if (funds[viewName]) {
            e._viewChartType = queryVar.chart_type;
            e._viewData = funds[viewName][e.ticker];
          }
        } else {
          if (viewName) {
            if(viewName === "Regions" && funds[viewName]) {
              e._viewChartType = "burst_chart";
              e._viewData = funds[viewName][e.ticker];
            } else if ((viewName === "Top Holdings" || viewName === "Asset Allocation") && funds[viewName]) {
              e._viewChartType = "pie_chart";
              e._viewData = viewName === "Top Holdings" ? funds[viewName]['funds'].find(t => t.ticker === e.ticker) : funds[viewName].find(t => t.ticker === e.ticker);
            } else if (viewName === "Diversification" && funds[viewName]) {
              e._viewChartType = queryVar.chart_type;
              e._viewData = funds[viewName].find(t => t.ticker === e.ticker);
            } else if (viewName === "Fiduciary Risk") {
              e._viewChartType = "dynamicGaugeChart";
              e._viewData = { max:100, min:0, value: e.fiduciary_risk ? e.fiduciary_risk : 0 }
            } else if (funds[viewName]) {
              e._viewChartType = queryVar.chart_type;
              e._viewData = funds[viewName][e.ticker];
            }
          }
        }
      }

      if (e._viewData && Object.keys(e._viewData) && typeof e._viewData.etf_mf_security_id !== 'undefined') {
        delete e._viewData.etf_mf_security_id;
      }

      let performance = [], noPerfVal = false;
      const perfFees = e.Fee_Risk_Return || [];
      Object.keys(perfFees).map((data, i) => {
        const year = data.match(/\d+/) ? data.match(/\d+/)[0] : '';
        if (year !== '') {
          // if (!perfFees[data] || (perfFees[data] && (perfFees[data] === '' || perfFees[data] === null || perfFees[data] === 'NaN'))) noPerfVal = true;
          if (data.includes("Category Annualized")) {
            const index = performance.findIndex(x => x.year === year);
            if (index === 0) {
              performance[index].Category = perfFees[data];
            } else if (!index || index < 0) {
              performance.push({
                year,
                name: `${year} year`,
                Category: perfFees[data],
              })
            } else {
              performance[index].Category = perfFees[data];
            }
          } else if (data.includes("Returns")) {
            const index = performance.findIndex(x => x.year === year);
            if (index === 0) {
              performance[index].Fund = perfFees[data];
            } else if (!index || index < 0) {
              performance.push({
                year,
                name: `${year} year`,
                Fund: perfFees[data],
              })
            } else {
              performance[index].Fund = perfFees[data];
            }
          } else return null;
        } else return null;
      })

      // console.log(e.ticker, e.returns)

      performance.push({
        name: (e.returns && e.returns.length > 0 && e.returns[0].d) ? `Since Inception,${e.returns[0].d}` : '',
        Fund: perfFees['Annualized Average'],
        Category: perfFees['Category Annualized Average']
      })

      const performanceArr = JSON.parse(JSON.stringify(performance));
      // if category value is not present for this fund
      // then delete this record from performance array

      const updatedPerformanceArr = performanceArr.filter((item) => (item.Category !== null))
                                                  .filter((item) => (item.Fund !== null))
                                                  .filter((item) => (item.Fund !== 'NaN'))
                                                  .filter((item) => (item.Category !== 'NaN'));

      if (options.selectedFunds.includes(e.ticker)) {
        // let fundCharts = charts.find(c => c.ticker === e.ticker);
        e._charts = {
          returns: e.returns,
          holdings: funds['Top Holdings'].funds.find(item => item.ticker == e.ticker).holdings,
          regions: e.region,
          sectors: getSectorData(e),
          // performance: getNestDefault(['result', 'performance', 0, 'performance'], fundCharts, []),
          performance: noPerfVal ? [] : updatedPerformanceArr,
        }
        e.cumlExcessReturn = cumlExcessReturn(e);
        e.annExcessReturn = annExcessReturn(e);
        e.tracking_error = e.tracking_error*100;
        let Found = options.selectedFundDetails.find(k => k.ticker === e.ticker);
        if (Found) {
          selectedFunds.push({
            ...e,
            ...Found,
          });
        } else {
          selectedFunds.push(e);
        }
      }
      else
        otherFunds.push(e);
    });

    options.flags = flags(options, { otherFunds: otherFunds.length > 0 });

    if (options.flags.compare) {
      const scores = options.compare[0].funds.reduce((acc, e) => ({ ...acc, [e.ticker]: e.score}), {});
      selectedFunds.sort((a, b) => (scores[b.ticker] || 0) - (scores[a.ticker] || 0))
    }

    return { ...options, queryFunds, allFunds, selectedFunds, otherFunds };
}

// exclude all funds which are not in search result
const validateOptions = (options, ticks, validQueries) => ({
  ...options,
  selectedFunds: options.selectedFunds.filter(e => ticks.includes(e)),
  compare: options.compare
             .map(c => ({...c, funds: c.funds.filter(e => ticks.includes(e.ticker))}))
             .filter(c => c.funds.length > 0),
  combine: {
    ...options.combine,
    all: {
      ...options.combine.all,
      funds: options.combine.all.funds.filter(e => ticks.includes(e.ticker))
    }
  },
  search:{
    ...options.search,
    query: concatQuery(options.allFunds.queryFunds.map(item => item.query)
      .filter(item => validQueries.includes(item)))  //need to be verified
  }
})

const validateOptionsCompareOnly = (options, ticks, validQueries) => ({
  ...options,
  selectedFunds: options.selectedFunds.filter(e => ticks.includes(e)),
  compare: options.compare
             .map(c => ({...c, funds: c.funds.filter(e => ticks.includes(e.ticker))}))
             .filter(c => c.funds.length > 0),
  combine: {},
  search:{
    ...options.search,
    query: concatQuery(options.allFunds.queryFunds.map(item => item.query)
      .filter(item => validQueries.includes(item)))  //need to be verified
  }
})

const flags = (options, newFlags) => {
  return {
    selectFunds: options.selectedFunds.length > 0,
    otherFunds: true,
    compare: options.compare.length > 0,
    otherCompare: options.compare.length > 1,
    combine: getNestDefault(['combine', 'all', 'funds', 'length'], options, 0) > 0,
    best: !!options.combine.all,
    ...newFlags,
  }
}

const getSectorData = item => {
  let out = [];
  SectorsAttrs.forEach(e => {
    let v = cn(item, e);
    if (v !== undefined && v > 0.0)
      out.push({ n: e, v });
  })
  return out;
}

const annExcessReturn = (item) => {
  // const { _stats: { yRet: fund }, _cstats: { yRet: category } } = item;
  if (item._categorySeries && item._categorySeries.yRet) {
    const { _return_v: fund, _categorySeries: { yRet: category } } = item;
    return Number(fund) - Number(category);
  } else {
    return null;
  }
}

const cumlExcessReturn = (item) => {
  // const { _stats: { cuml: fund }, _cstats: { cuml: category } } = item;
  if (item._categorySeries && item._categorySeries.yRet) {
    const { _return_cuml_v: fund, _categorySeries: { yRet: category } } = item;
    return Number(fund) - Number(category);
  } else {
    return null;
  }
}

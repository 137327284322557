import React from 'react'
import { connect } from 'react-redux'
import $ from 'jquery'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { isEqual, find, isEmpty } from 'lodash'
import { calcAttrStats, calcScores, calcScreenerScores } from '../../Stats'
import Tabs from './Tabs'
// import FundDetailsTab from './FundDetails';
import { DateRange, cn, ScoreAttrs, AdditionalScoreAttrs, calculatorOptions } from '../../DataSet'
import moment from 'moment'
import { createLoadingSelector } from 'selectors/loading-selector';
import { fetchImportedAccounts, fetchBrokerList } from 'apis/broker'
import { setupBroker, completeTradeItSetup } from 'actions/broker'
import { selectorWithTimeFrameRequest, selectorWithTimeFrameSuccess } from 'actions/funds'
import { refreshWeightState, fetchWeightsData, saveScoreAttrs, changeCalculator } from '../../actions/weights'
import { addEventToAnalytics, nameToDateRange, monthToDate, getNest } from 'Utils';
import { dateRangeActiveObj } from './utils';

var classNames = require('classnames')

class Main extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      range: dateRangeActiveObj, // { return: 'active', risk: 'active' }
      timeFrameData: [],
    }
  }

  componentDidMount() {
    this.state.dateRange = this.nameToDateRange(this.props.items, this.state.range);
    this.calcStats(this.props.items, this.state.range, this.props.scoreAttrs);
    this.fetchAccounts();
    this.fetchBrokers();
  }

  componentWillReceiveProps(newProps) {
    if (!this.state.modal && newProps.items.length > this.props.items.length) {
      this.stageHandler('funds')
      //calcScores(newProps.items.map(e => e._sfStats), ScoreAttrs);
    }

    if (newProps.importAccountsSuccess === true) {
      this.fetchAccounts()
    }
  }

  shouldComponentUpdate(newProps, newState) {
    if (newProps.items.length !== this.props.items.length || newState.range !== this.state.range || !isEqual(newProps.scoreAttrs, this.props.scoreAttrs)) {
      //this.calcStats(newProps.items, newState.range, newState.scoreAttrs);
      let rr = {
        return: 'active',
        risk: 'active',
      };
      newProps.scoreAttrs.filter((jk) => jk.yearDropdown).map((hb) => {
        if (hb.code) {
          rr[`${hb.code}`] = 'active';
        }
      });

      let dateRange = this.nameToDateRange(newProps.items, rr);
      let range = newState.range;
      // Reset the range to active when a ticker/fund is added or deleted if a custom time range is selected
      if (newProps.items.length !== this.props.items.length && newState.range.fromDatePicker) {
        range = {
          return: 'active',
          risk: 'active',
        };
        newProps.scoreAttrs.filter((jk) => jk.yearDropdown).map((hb) => {
          if (hb.code) {
            range[`${hb.code}`] = 'active';
          }
        });
      }
      // console.log(range);
      // console.log(dateRange);
      this.setState({ loading: true, dateRange, range });
      setTimeout(() => {
        this.calcStats(newProps.items, newState.range, newProps.scoreAttrs)
        this.setState({ loading: false })
      }, 1000)
    }
    return true
  }

  componentDidUpdate(props) {
    const attrs = [];
    // console.log(this.state.range);
    this.calcStats(
      props.items,
      this.state.range,
      attrs.length ? attrs : this.props.scoreAttrs
    )
  }

  fetchAccounts = async () => {
    let importedAccounts = await fetchImportedAccounts().then(result => {
      this.setState({ importedAccounts: result.data })
    })
  }

  async fetchBrokers() {
    let importedAccounts = await fetchBrokerList().then(result => {
      this.setState({ brokers: result.data.brokers })
    })
  }

  calcStats = (items, range, scoreAttrs) => {
    // console.log(items);
    // console.log(range);
    // console.log(scoreAttrs);
    // items.map(e => {
    //   console.log(e._sfStats ? e._sfStats.score : e._sfStats, e.ticker);
    // })
    let dateRange = {};

    if (range.fromDatePicker) {
      dateRange = {
        ...range,
      };
      scoreAttrs.filter((jk) => jk.yearDropdown).map((nl) => {
        if (nl.code && range[nl.code]) {
          dateRange[`${nl.code}`] = range[nl.code]
        }
      });
      // console.log(dateRange);
    } else {
      let _range = range;
      scoreAttrs.filter((jk) => jk.yearDropdown).map((nl) => {
        if (nl.code) {
          let firstKey = Object.keys(range)[0];
          if (firstKey && range[firstKey]) {
            _range[`${nl.code}`] = range[firstKey] // range[`${nl.code}`]
          }
          // _range[`${nl.code}`] = range[firstKey] // range[`${nl.code}`]
        }
      });
      dateRange = this.nameToDateRange(items, _range);
    }

    const rangeFilter = (e, rnge) => {
      const dt = moment('01 ' + e.d, 'DD MMM YYYY')
      return (
        dt.isAfter(moment(rnge.start).subtract(1, 'seconds')) &&
        dt.isBefore(moment(rnge.end).add(1, 'seconds'))
      )
    }

    const active = item => !getNest(['_sfStats', 'feesOff'], item)

    const inactive = item => {
      if (typeof range.return !== 'undefined' && typeof range.risk !== 'undefined') {
        let mStart = monthToDate(item._start)
        let yRetOff = mStart.isAfter(moment(dateRange.return.start)),
          riskOff = mStart.isAfter(moment(dateRange.risk.start)),
          feesOff = yRetOff && riskOff
        return { yRetOff, riskOff, feesOff }
      }
    }

    items.forEach(e => {
      if (typeof range.return !== 'undefined' && typeof range.risk !== 'undefined') {
        if (range.return === range.risk) {
          e._sfStats = calcAttrStats(
            e.returns.filter(e => rangeFilter(e, dateRange.return))
          )
        } else {
          let stats1 = calcAttrStats(
            e.returns.filter(e => rangeFilter(e, dateRange.return)),
            'return'
          )
          let stats2 = calcAttrStats(
            e.returns.filter(e => rangeFilter(e, dateRange.risk)),
            'risk'
          )
          e._sfStats = { ...stats1, ...stats2 }
        }
      }

      let WPN = cn(e, 'Premium to NAV'), WT = cn(e, 'Trend'), WFF = cn(e, 'Fund Flows');

      if (this.state.timeFrameData && this.state.timeFrameData.length > 0) {
        const found = this.state.timeFrameData.find((hj) => hj.ticker === e.ticker);
        if (found) {
          WPN = found.premium;
          WT = found.trend;
          WFF = found.fund_flow;
        }
      } else if (range) {
        WPN = (range.weight_premium_to_nav === 'l5y') ? cn(e, 'Premium to NAV 5yr') : ((range.weight_premium_to_nav === 'l3y') ? cn(e, 'Premium to NAV 3yr') : (range.weight_premium_to_nav === 'l1y') ? cn(e, 'Premium to NAV 1yr') : cn(e, 'Premium to NAV'));
        WT = (range.weight_trend === 'l5y') ? cn(e, 'Trend 5yr') : ((range.weight_trend === 'l3y') ? cn(e, 'Trend 3yr') : (range.weight_trend === 'l1y') ? cn(e, 'Trend 1yr') : cn(e, 'Trend'));
        WFF = (range.weight_fund_flows === 'l5y') ? cn(e, 'Fund Flows 5yr') : ((range.weight_fund_flows === 'l3y') ? cn(e, 'Fund Flows 3yr') : (range.weight_fund_flows === 'l1y') ? cn(e, 'Fund Flows 1yr') : cn(e, 'Fund Flows'));
      }

      e._sfStats = {
        ...e._sfStats,
        ...inactive(e),
        fees: cn(e, 'Fees'),
        aum: cn(e, 'Assets'),
        vol: cn(e, 'Volume'),
        weight_yield: cn(e, 'Yield'),
        weight_bid_ask: cn(e, 'Bid Ask spread'),
        weight_track_record: cn(e, 'Track record'),
        weight_premium_to_nav: WPN, // || cn(e, 'Premium to NAV'),
        weight_trend: WT, // || cn(e, 'Trend'),
        weight_fund_flows: WFF, // || cn(e, 'Fund Flows'),
        // weight_risk_adjusted_returns: cn(e, 'Price to Book') || 0,
        // weight_price_to_book: cn(e, 'Risk Adjusted Returns') || 0,
        trkerror: find(e.riskAdjReturn, {
          name: 'Tracking Error'
        })
          ? find(e.riskAdjReturn, { name: 'Tracking Error' }).value
          : 0,
        score: -1,
        manager_tenure: cn(e, 'Manager Tenure'),
        expense_ratio: cn(e, 'Expense Ratio'),
        median_expense_ratio: cn(e, 'Median Expense Ratio'),
        aumAC: cn(e, 'aumAC'),
        upside_capture_5y: cn(e, 'Up Capture 5yr'),
        upside_capture_10y: cn(e, 'Up Capture 10yr'),
        downside_capture_5y: cn(e, 'Down Capture 5yr'),
        downside_capture_10y: cn(e, 'Down Capture 10yr'),
        outperformance_3y: cn(e, 'Outperformance 3yr'),
        outperformance_5y: cn(e, 'Outperformance 5yr'),
        outperformance_10y: cn(e, 'Outperformance 10yr'),
        alpha_5y: cn(e, 'Alpha 5yr'),
        alpha_10y: cn(e, 'Alpha 10yr'),
        min_expense_ratio: cn(e, 'Min Expense Ratio'),
        expense_ratio_25pct: cn(e, 'Expense Ratio 25pct'),
        expense_ratio_75pct: cn(e, 'Expense Ratio 75pct'),
        max_expense_ratio: cn(e, 'Max Expense Ratio'),
      }
    })

    // console.log('scoreeee cal!!!');
    // console.log(scoreAttrs, this.props.scoreAttrs);
    const { selectedCalculator } = this.props;
    if (selectedCalculator !== calculatorOptions.magnifiactivescreener) {
      calcScores(items.filter(active).map(e => e._sfStats), scoreAttrs);
    } else {
      calcScreenerScores(items.filter(active).map(e => e._sfStats), scoreAttrs);
    }

    // items.map(e => {
    //   console.log(e._sfStats.score, e.ticker);
    // })
  }

  stageHandler = (stage, item = 'all') => {
    if (stage === 'combine') {
      this.combineHandler(item)
    }

    this.setState({
      stage: stage || this.state.prevStage,
      prevStage:
        stage === 'confirm' || this.state.stage === 'confirm'
          ? this.state.prevStage
          : this.state.stage
    })
  }

  buyHandler = item => {
    //debugger
    let order = {
      stage: 'buy',
      otype: 'Market',
      price: '',
      qty: '',
      tvalue: '',
      validity: undefined
    }

    if (item === 'all') {
      this.props.items.forEach(e => {
        if (e._order) e._order.stage = 'buy'
        else e._order = { ...order }
      })
    } else {
      if (item._order) item._order.stage = 'buy'
      else item._order = { ...order }
    }
    this.stageHandler('act')
  }

  combineHandler = item => {
    let order = {
      stage: 'compare',
      otype: 'Market',
      price: '',
      qty: '',
      tvalue: '',
      validity: undefined
    }

    if (item === 'all') order.stage = 'combine'

    this.props.items.forEach(e => {
      if (e._order) e._order.stage = order.stage
      else e._order = { ...order }
    })

    if (item !== 'all') item._order.stage = 'combine'
  }

  clearHandler = (item, unselect = true) => {
    if (item === 'all') {
      this.props.items.forEach(e => e._order && (e._order.stage = 'compare'))
      //this.stageHandler('compare');
      unselect && this.props.selectHandler()
    } else {
      item._order && (item._order.stage = 'compare')
      unselect && this.props.selectHandler(item)
      /* if (this.props.items.some(e => getNest(['_order', 'stage'], e) === 'buy'))
        this.setState({ stage: this.state.stage })
      else
        this.stageHandler('compare'); */
    }
  }

  rangeHandler = (attr, selRange) => {
    // console.log(attr);
    // console.log(selRange);

    const { funds, items: sfmFundsList } = this.props;
    let range = {};

    if (selRange.fromDatePicker) {
      const { selectorWithTimeFrameRequest, selectorWithTimeFrameSuccess, dropdownScoreAttrs } = this.props;
      // call API only for ['weight_trend', 'weight_fund_flows', 'weight_premium_to_nav']
      if (dropdownScoreAttrs && dropdownScoreAttrs.length > 0) {
        // console.log(dropdownScoreAttrs);
        dropdownScoreAttrs.filter((jk) => jk.yearDropdown).map((nl) => {
          // console.log(nl.code);
          // console.log(selRange[nl.code]);
          if (nl.code && selRange[nl.code]) {
            range[`${nl.code}`] = selRange[nl.code]
          }
        })
      }
      range.fromDatePicker = true;
      // console.log(range);

      if (selectorWithTimeFrameRequest && dropdownScoreAttrs.filter((jk) => !jk.default && jk.yearDropdown && jk.code === attr).length > 0) {
        // check for custom date range
        if (selRange[attr] && selRange[attr].start) {
          let startDate = moment(selRange[attr].start).format("MMM YYYY");
          let endDate = moment(selRange[attr].end).format("MMM YYYY");
          const tickersWillBe = sfmFundsList.filter(v => v._selector === true).map((g) => g.ticker);
          if (tickersWillBe && tickersWillBe.length > 0) {
            selectorWithTimeFrameRequest({
              start_date: startDate,
              end_date: endDate,
              tickers: tickersWillBe,
            }, (apiResponse) => {
              if (apiResponse) {
                this.setState({ timeFrameData: apiResponse }, () => {
                  if (selectorWithTimeFrameSuccess) {
                    selectorWithTimeFrameSuccess();
                  }
                });
              }
            });
          }
        }
      }
    } else {
      // default way
      const { dropdownScoreAttrs } = this.props;
      if (dropdownScoreAttrs && dropdownScoreAttrs.length > 0) {
        dropdownScoreAttrs.filter((jk) => jk.yearDropdown).map((nl) => {
          if (nl.code) {
            range[`${nl.code}`] = selRange
          }
        })
      }
      // console.log(range);
    }
    // console.log(range);
    this.setState({ range })
    if (funds) {
      addEventToAnalytics('Compare time period change', 'Compare time period change', 'COMPARE_TIME_PERIOD_CHANGE', { query: funds.query, logId: funds.logId }, true);
    }
  }

  scoreAttrsHandler = (name, move) => {
    const swap = (arr, i1, i2) => {
      let t = arr[i1]
      const i1Weigth = arr[i1].weight
      const i2Weigth = arr[i2].weight
      arr[i1] = arr[i2]
      arr[i1].weight = i1Weigth
      arr[i2] = t
      arr[i2].weight = i2Weigth
    }

    var scoreAttrs = [...this.props.scoreAttrs]
    var i = scoreAttrs.findIndex(e => e.name === name)

    if (move === 'up' && i > 0) swap(scoreAttrs, i, i - 1)
    else if (move === 'down' && i < scoreAttrs.length - 1)
      swap(scoreAttrs, i, i + 1)
    else return

    this.props.saveScoreAttrs(scoreAttrs);
    if (this.props.enablePrintReports) {
      const { getScoreAttrs } = this.props;
      getScoreAttrs(scoreAttrs);
    }
  }

  scoreAttrsHandler2 = (scoreAttrs) => {
    this.props.saveScoreAttrs(scoreAttrs);
    if (this.props.enablePrintReports) {
      const { getScoreAttrs, items } = this.props;
      getScoreAttrs(scoreAttrs, items);
    }
  }

  scoreAttrsWeightHandler = values => {
    // const finalAttrs = [...this.props.scoreAttrs]
    // const attrs = []
    // values.map(({ name, weight }) => {
    //   const rest = find(finalAttrs, x => x.code === name)
    //   attrs.push({
    //     ...rest,
    //     weight: weight
    //   })
    // })
    // this.props.saveScoreAttrs(attrs);
  }

  nameToDateRange = (items, range) => {
    let d = moment.max(items.map(e => monthToDate(e._start)))
    let sDefault = moment(DateRange.start),
      sActive = moment.max(sDefault, d);

    let maxRange = r => ({ start: r !== 'active' ? sDefault : sActive, end: moment(this.props.dateRange.end) });

    let rr = {
      // return: nameToDateRange(range.return, maxRange(range.return)),
      // risk: nameToDateRange(range.risk, maxRange(range.risk)),
    };

    Object.keys(range).map((de) => {
      if (de && range[de]) {
        // console.log(maxRange(range[de]).start.format('MMM YYYY'));
        rr[`${de}`] = nameToDateRange(range[de], maxRange(range[de]));
      }
    });

    return rr;

    // return {
    //   return: nameToDateRange(range.return, maxRange(range.return)),
    //   risk: nameToDateRange(range.risk, maxRange(range.risk)),
    //   weight_trend: nameToDateRange(range.weight_trend, maxRange(range.weight_trend)),
    //   weight_fund_flows: nameToDateRange(range.weight_fund_flows, maxRange(range.weight_fund_flows)), // nameToDateRange(range.risk, maxRange(range.risk)),
    //   weight_premium_to_nav: nameToDateRange(range.weight_premium_to_nav, maxRange(range.weight_premium_to_nav)),
    // }
  }

  modalToggle = modalToggle => {
    this.props.modalToggle(modalToggle)
  }

  sortFn = (a, b) => {
    let [_a, _b] = [a._sfStats, b._sfStats]
    if (_a && _b) {      // descending order
      if (_a.score < _b.score) return 1;
      else if (_a.score > _b.score) return -1;
    }
    return 0;
  }

  render() {
    let selectedItems = this.props.items.filter(v => v._selector === true);
    return (
      <SfModal
        {...this.props}
        autoOpenModal={this.props.autoOpenModal}
        backHandler={this.backHandler}
        buyHandler={this.buyHandler}
        actionHandler={this.stageHandler}
        clearHandler={this.clearHandler}
        modalToggle={this.modalToggle}
        selectHandler={this.props.selectHandler}
        range={this.state.range}
        dateRange={this.state.dateRange}
        rangeHandler={this.rangeHandler}
        scoreAttrsHandler={this.scoreAttrsHandler}
        scoreAttrsHandler2={this.scoreAttrsHandler2}
        scoreAttrsWeightHandler={this.scoreAttrsWeightHandler}
        scoreAttrs={this.props.scoreAttrs}
        loading={this.state.loading || this.props.isWeightsLoading || this.props.selectorTimeFrameLoader}
        stage={this.state.stage}
        importedAccounts={this.state.importedAccounts}
        brokers={this.state.brokers}
        fetchAccounts={this.fetchAccounts}
        fetchWeightsData={this.props.fetchWeightsData}
        selectedItems={selectedItems}
      />
    )
  }
}

const mapStateToProps = state => {
  const weights = state.weights;
  return {
    dateRange: state.funds.dateRange,
    selectedBroker: state.broker.selectedBroker,
    receivedEndPoint: state.broker.receivedEndPoint,
    error: state.broker.error,
    // isFetching: createLoadingSelector(['SETUP_BROKER'])(state),
    // isDemoUser: isDemoUser(),
    importAccountsSuccess: state.importBrokerAccount.importAccountsSuccess,
    displayVerification: state.tradeItSecurityHandling.displayVerification,
    weights: weights.weightScore,
    mobileDevice: state.global.mobileDevice,
    scoreAttrs: weights.scoreAttrs,
    selectedCalculator: weights.selectedCalculator,
    isWeightsLoading: weights.loading,
    sfm: state.sfm,
    customCalculators: weights.customCalculators,
    dropdownScoreAttrs: weights.scoreAttrs.filter(x => x.weight !== 0 && x.yearDropdown) || [],
    selectorTimeFrameLoader: createLoadingSelector(['SELECTOR_WITH_TIME_FRAME'])({ loading: state.loading }),
  }
}

const mapDispatchToProps = {
  setupBroker,
  completeTradeItSetup,
  refreshWeightState,
  fetchWeightsData,
  saveScoreAttrs,
  changeCalculator,
  selectorWithTimeFrameRequest,
  selectorWithTimeFrameSuccess,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main)

class SfModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      open: true,
      alert: true,
      showFunDetails: false,
      selectedFunDetails: undefined
    }
    this.inRef = React.createRef()
    this.bw = document.body.clientWidth
  }

  componentWillMount() {
    const { loggedIn, scoreAttrs } = this.props;
    if (loggedIn && isEmpty(scoreAttrs)) {
      this.props.fetchWeightsData();
    }
  }

  componentDidMount() {
    document.body.classList['remove']('modal-open')
    document.body.classList[this.state.modal ? 'add' : 'remove'](
      'modal-open-sfm'
    )
    setTimeout(() => {
      this.setState({ alert: false })
    }, 2000)
    $('.sfp-backdrop').click(this.toggle) // hack as bockdrop click not working
    this.props.confirmHandler(false)
  }

  componentWillReceiveProps(newProps) {
    if (this.props.autoOpenModal !== newProps.autoOpenModal && newProps.autoOpenModal) {
      this.autoOpenModal();
    }

    if (!this.state.modal && newProps.items.length !== this.props.items.length) {
      this.setState({ alert: true });
      setTimeout(() => { this.setState({ alert: false }) }, 2000);
    }
  }

  componentWillUnmount() {
    $('.sfp-backdrop').unbind('click', this.toggle)
    document.body.classList['remove']('modal-open-sfm')
  }

  autoOpenModal = () => {
    this.setState({ modal: true }, () => {
      document.body.classList['add']('modal-open-sfm');
      this.props.modalToggle('add');
    });
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal }, () => {
      document.body.classList[this.state.modal ? 'add' : 'remove'](
        'modal-open-sfm'
      )
      this.props.modalToggle(this.state.modal ? 'add' : 'remove')
      // this.inRef.current.style.paddingRight = `${this.state.modal ? document.body.clientWidth-this.bw : 0}px`;
      if (this.state.modal) {
        addEventToAnalytics('SFM Maximize', 'SFM Maximize', 'SFM_MAXIMIZE', { clickBtn: 'SFM Maximize' }, true);

      }
      else {
        addEventToAnalytics('SFM Minimize', 'SFM Minimize', 'SFM_MINIMIZE', { clickBtn: 'SFM Minimize' }, true);
      }
    })
  }

  clearAllHandler = () => {
    this.setState({ open: false }, () => {
      setTimeout(() => {
        this.props.clearHandler('all', true)
      }, 300)
    })
  }

  open = () => {
    this.setState({ open: !this.state.open }, () => { })
  }

  toggleFunDetails = selectedFunDetails => {
    if (window.innerWidth < 760)
      this.setState(prevState => ({
        showFunDetails: !prevState.showFunDetails,
        selectedFunDetails
      }))
  }

  render() {
    const { showFunDetails, selectedFunDetails, open, modal } = this.state
    const { items, mobileDevice, selectedItems } = this.props;
    const size = this.state.modal ? 'full' : this.state.alert ? 'anim' : 'min'
    const classes = mobileDevice ? ' modal-full-screen' : ''
    const closeBtn = (
      <button
        className={`close ${open ? 'media-close' : ''} ${showFunDetails ? 'd-none' : ''
          }`}
        onClick={this.toggle}
      >
        {/* <i className={`fas text-white icon-cancel d-md-none ${this.state.modal && !showFunDetails ? "d-sm-block":"d-none"} `}></i> */}
        <div
          className={`hamburger ${size === 'full' && !showFunDetails ? ' active' : ''
            } `}
        >
          <div className="hamburger-box">
            <div className="hamburger-inner"></div>
          </div>
        </div>
        <i
          className={`far text-white ${this.state.modal ? 'fa-minus' : 'fa-plus'
            }`}
        ></i>
      </button>
    )

    const renderBtn = showFunDetails ? (
      this.state.modal ? (
        <div
          className="media-arrow d-md-none d-sm-block modal-back-arrow"
          onClick={() => this.toggleFunDetails(selectedFunDetails)}
        >
          <i className="fas fa-arrow-left" style={{ color: '#fff' }} />
        </div>
      ) : (
        <div
          className="media-arrow d-md-none d-sm-block modal-back-arrow"
          onClick={this.toggle}
        >
          <i className="fas fa-plus" style={{ color: '#fff' }} />
        </div>
      )
    ) : (
      closeBtn
    )
    let selectedFunds = selectedItems && selectedItems.length > 0 ? selectedItems : items;
    return (
      <Modal className="fund-container" isOpen={this.state.open} toggle={this.toggle} backdrop={true} modalClassName={'sfp ' + size + classes}
        innerRef={this.inRef} className="modal-lg" contentClassName="bg-ssf-blue-d2" backdropClassName="sfp-backdrop">
        <ModalHeader
          className="media-details-tab bg-sfmodal-header text-white modal-header-title-body"
          toggle={this.toggle}
          close={renderBtn}
          tag="div"
        >
          <div className="modal-header-title-container" onClick={this.toggle}>
            <img className={`fas fa-briefcase mr-3 ${showFunDetails ? 'd-none' : ''
              }`} src='/assets/images/icons/shopping-cart-White-circle.svg' alt='' />
            {/* <i
              className={`fas fa-briefcase mr-3 ${
                showFunDetails ? 'd-none' : ''
              }`}
            ></i> */}
            <span onClick={this.toggle} className="font-weight-bold">
              {`${selectedFunds && selectedFunds.length && !showFunDetails ? (selectedFunds.length > 1 ? selectedFunds.length + " Funds Selected" : selectedFunds.length + " Fund Selected") : "Fund Information"}`}
            </span>
          </div>
        </ModalHeader>
        <ModalBody className="p-0 bg-sfmodal-grey font-one">
          {open && open && (
            <Tabs
              {...this.props}
              card={this.props.card || {}}
              toggleFunDetails={this.toggleFunDetails}
              clearAllHandler={this.clearAllHandler}
              showFunDetails={showFunDetails}
              selectedFunDetails={selectedFunDetails}
              open={size === 'full' && !showFunDetails}
            />
          )}
        </ModalBody>
      </Modal>
    )
  }
}

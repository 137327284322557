import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip,
  FormFeedback,
  Button,
} from 'reactstrap'

import get from 'lodash/get'

import { CloseButton, CustomFormFeedback } from 'shared-components'
import { PrimaryButton } from 'shared-components/';

export default class RegisterForm extends Component {
  state = {
    noEmailEntered: false,
    emailErrorMessage: 'Please enter atleast one email address',
    initialValues: [{
      firstName: '',
      LastName: '',
      Email: '',
      status: {
        Email: {
          errorMessage: '',
          error: false,
        }
      },
    }, {
      firstName: '',
      LastName: '',
      Email: '',
      status: {
        Email: {
          errorMessage: '',
          error: false,
        }
      },
    }, {
      firstName: '',
      LastName: '',
      Email: '',
      status: {
        Email: {
          errorMessage: '',
          error: false,
        }
      },
    }],
  };

  componentDidUpdate() {
    const elems = document.querySelectorAll('.custom-scroll');
    if (elems.length) {
      [].forEach.call(elems, function(el) {
        if (el.scrollHeight > el.clientHeight) {
          el.style.paddingRight = '.55rem';
          el.style.marginRight = '-.55rem';
        } else el.style.paddingRight = '0rem';
      });
      const div = document.getElementById('custom-scroll');
      this.scrollToBottom(div);
    }
  }

  scrollToBottom = (div) => {
    div.scrollTop = div.scrollHeight - div.clientHeight;
  }

  handleChange = (pos, key, event) => {
    const { initialValues } = this.state;
    if (initialValues && initialValues[pos]) {
      initialValues[pos][key] = event.target.value;
      this.setState({
        initialValues,
      });
      if (this.state.initialValues[pos]['Email'] !== '') {
        this.state.initialValues[pos].status['Email'].error = false;
        this.state.initialValues[pos].status['Email'].errorMessage = '';
      } else {
        this.state.initialValues[pos].status['Email'].error = true;
      }
    }
  }

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  handleBlur = (pos, key, event) => {
    const { initialValues } = this.state;
    if (event.target.value === '') {
      if (initialValues && initialValues[pos]) {
        // initialValues[pos].status[key].error = true;
        this.setState({
          initialValues,
        });
      }
    } else {
      if (initialValues && initialValues[pos]) {
        // initialValues[pos].status[key].error = false;
        this.setState({
          initialValues,
        });

        if (key === 'Email') {
          if (this.validateEmail(this.state.initialValues[pos][key])) {
            initialValues[pos].status[key].error = false;
            initialValues[pos].status[key].errorMessage = '';
          } else {
            initialValues[pos].status[key].error = true;
            initialValues[pos].status[key].errorMessage = 'Invalid email format';

          }
        }
      }
    }
  }

  renderErrorMessage = (message) => {
    return (<div className="error-message">{message}</div>);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { initialValues } = this.state;
    const emailPresentArray = initialValues.filter((item) => item.Email !== '' && item.status && !item.status.Email.error);
    if (emailPresentArray.length === 0) {
      this.setState({
        noEmailEntered: true,
      });
    } else {
      this.setState({
        noEmailEntered: false,
      }, () => {
        const filterData = this.state.initialValues.filter((df) => df.Email !== '' && df.status && !df.status.Email.error);
        const data = filterData.map((d) => {
          return {
            firstName: d.firstName,
            LastName: d.LastName,
            Email: d.Email,
          };
        });
        const { handleSubmit } = this.props;
        if (data.length && handleSubmit) {
          handleSubmit(data);
        }
      });
    }
  }

  addMoreRow = () => {
    const { initialValues } = this.state;
    const disableAddMore = initialValues.filter((f) => f.Email === '');
    if (disableAddMore.length === 0) {
      const arr = JSON.parse(JSON.stringify(initialValues));
      arr.push({
        firstName: '',
        LastName: '',
        Email: '',
        status: {
          Email: {
            errorMessage: '',
            error: false,
          }
        },
      });
      this.setState({
        initialValues: arr,
      }, () => {
        const div = document.getElementById('custom-scroll');
        this.scrollToBottom(div);
      });
    }
  }

  render() {
    const { initialValues, noEmailEntered } = this.state;
    const { mobileDevice } = this.props;

    const disableAddMore = (initialValues.filter((f) => f.Email === '')).length !== 0;

    const addMoreButton = (
      <Button
        className="add-more-btn"
        onClick={this.addMoreRow}
        disabled={disableAddMore}
      >
        Add More
      </Button>
    );

    const disableSubmitButton = ((initialValues.filter((f) => f.Email !== '' && !f.status.Email.error)).length === 0);

    const renderDesign = initialValues.map((item, index) => {
      return (
        <Row key={`${item}_${index}`}>
          <Col lg="1" md="1" sm="12" className="index-number-box">
            <div className="index-number">{index+1}</div>
          </Col>
          <Col lg="5" md="5" sm="12">
            <FormGroup className="element-box pos-rel">
              {mobileDevice && <Label className="label">Email Address*</Label>}
              <Input
                type="text"
                name={`Email_${index}`}
                id={`Email_${index}`}
                value={initialValues[index].Email}
                onChange={(event) => this.handleChange(index, 'Email', event)}
                onBlur={(event) => this.handleBlur(index, 'Email', event)}
                className={initialValues[index].status['Email'].error ? 'is-invalid input-area' : 'input-area'}
              />
              {initialValues[index].status['Email'].error && this.renderErrorMessage(initialValues[index].status['Email'].errorMessage)}
            </FormGroup>
          </Col>
          <Col lg="3" md="3" sm="12">
            <FormGroup className="element-box pos-rel">
              {mobileDevice && <Label className="label" >First Name</Label>}
              <Input
                type="text"
                name={`firstName_${index}`}
                id={`firstName_${index}`}
                value={initialValues[index].firstName}
                onChange={(event) => this.handleChange(index, 'firstName', event)}
                onBlur={(event) => this.handleBlur(index, 'firstName', event)}
                className={'input-area'}
              />
          </FormGroup>
          </Col>
          <Col lg="3" md="3" sm="12">
            <FormGroup className="element-box pos-rel">
              {mobileDevice && <Label className="label">Last Name</Label>}
              <Input
                type="text"
                name={`LastName_${index}`}
                id={`LastName_${index}`}
                value={initialValues[index].LastName}
                onChange={(event) => this.handleChange(index, 'LastName', event)}
                onBlur={(event) => this.handleBlur(index, 'LastName', event)}
                className={'input-area'}
              />
            </FormGroup>
          </Col>
        </Row>
      )
    });

    return (
      <Form
        className="invite-friends-wrapper"
        onSubmit={this.handleSubmit}
      >
        <div className="header-area">
          <Row>
            <Col lg="1" md="1" sm="12">
            </Col>
            <Col lg="11" md="11" sm="12">
              <div className="header-label">Share the gift of Magnifi</div>
            </Col>
          </Row>
        </div>
        <div className="body-wrapper pos-rel">
          {!mobileDevice
            && (
              <Row>
                <Col lg="1" md="1" sm="12">
                </Col>
                <Col lg="5" md="5" sm="12">
                  <Label className="label">Email Address*</Label>
                </Col>
                <Col lg="3" md="3" sm="12">
                  <Label className="label" >First Name</Label>
                </Col>
                <Col lg="3" md="3" sm="12">
                  <Label className="label">Last Name</Label>
                </Col>
              </Row>
            )
          }
          <div id="custom-scroll" className="h-335 custom-scroll ">
            {renderDesign}
          </div>
          {addMoreButton}
        </div>
        <FormGroup className="cta-wrapper justify-content-left margin-left-51">
          <PrimaryButton
            type="submit"
            disabled={disableSubmitButton}
            className="btn secondary-btn"
          >
            SEND
          </PrimaryButton>
          <div className="note-text">*Please input at least one email address.</div>
        </FormGroup>
      </Form>
    )
  }
}

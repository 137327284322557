import React  from 'react';
import { FormatCurrency } from '../PortfolioReportV2/shared/Utils';

export const getExecutiveSummaryContent = (avgReturn, expRisk, avgGrowth) => {
  return (
    <React.Fragment>
      <div>
        <p>
          The portfolio has the potential for an average return of {avgReturn}% per annum with potential risk of {expRisk}%. This could
          translate to an expected average growth of {FormatCurrency(avgGrowth,0,'$','unf')} over 10 years. The chart below reflects the
          impact of portfolio risk on this potential growth.
        </p>
      </div>
    </React.Fragment>
  )
}

export const getConclusionContent = (portPerfPercent, avgReturn, expRisk, avgGrowth, noOfYrs, returnsCalcBasedOn, volatilityCalcBasedOn) => {
  let view = (
    <div>
      <p>Replacing your current holdings with suggested "high returns" alternatives` can enhance your portfolio performance by {portPerfPercent}% of your starting portfolio.</p>
      <p>The enhanced portfolio has a 3 year annualized return of {avgReturn}% with a 3 year annualized volatility of {expRisk}%. This translates to an expected average growth of {avgGrowth} over {noOfYrs} years. The chart below reflects the impact of portfolio risk on this expected growth.</p>
    </div>
  )

  return (
    <React.Fragment>
      {view}
    </React.Fragment>
  )
}

import React from 'react';
import { Redirect } from 'react-router-dom';
import { get } from 'lodash'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button, Row, Col, Card, CardBody } from 'reactstrap';
import { UncontrolledTooltip } from 'reactstrap';
import { D3Colors09, D3AppColors09, cn, sponsorLogo, Brokers } from '../../DataSet';
import { numFmt, getNest, getNestDefault, getImageOrFallback } from '../../Utils';
import { FooterActions, TruncatedNameSponsor, TruncatedText } from './Common';
import { placeTDFundOrder,placeTradeItFundOrderPreview, placeTradeItFundOrder } from 'apis/funds';
import { Spinner } from 'shared-components/';
import { addEventToAnalytics } from 'Utils';

var classNames = require('classnames');
const isError = (str = '') => str.toLowerCase() === 'error';
const comingSoon = ['MF', 'SMA', 'MP'];
export default class Confirm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      warnings: {warnings: []},
      disabled: false
    };
    this.handleCheckbox = this.handleCheckbox.bind(this)
  }

  componentDidMount = () => {
    this.previewOrder()
      .then( result => {
        this.setState({
          previewOrderResult: result,
          loading: false
        });
      })
  }

  handleCheckbox(){
    var flag = false;
    let elements = document.getElementsByClassName('warning-tradeit');
    if(elements){
      // [].forEach.call(elements, el => {
      for(let i = 0; i<elements.length; i++) {
        if(elements[i].checked == true){
          flag = true;
        }
        else{
          flag = false;
          break;
        }
      };
      if(flag == true && this.state.disabled != false){
        this.setState({disabled: false});
        return true;
      }
      else if(flag == false && this.state.disabled != true){
        this.setState({disabled: true});
        return false;
      }
    }
  }

  confirmHandler = () => {
    this.setState({ disabled: true, loading: true });
    addEventToAnalytics('Confirm SFM Footer Button','Confirm SFM Footer Button','CONFIRM_SFM_FOOTER_BUTTON',{ clickBtn: 'confirm' },true);
    this.placeFundsOrder()
      .then(result => {
        let payload = [];
        result.funds.forEach((e, i) => {
          let broker = Brokers.find(el => el.name === e.broker.name);
          let errorMsg, error = isError(getNest(['results', i, 'status'], result));
          if (error)
            errorMsg = getNest(['results', i, 'response', 'message'], result) || getNest(['results', i, 'shortMessage'], result) || getNest(['results', i, 'message', 'default'], result)

          let card = {
            ticker: cn(e, 'Ticker'),
            name: cn(e, 'Name2'),
            sponsor: cn(e, 'Sponsor'),
            color: D3Colors09[i % D3Colors09.length],
            broker: e.broker.name,
            brokerColor: broker.color,
            error,
            errorMsg,
            orderNumber: getNest(['results', i, 'data', 'orderNumber'], result),
            timestamp: getNest(['results', i, 'data', 'timestamp'], result),
            confirmationMessage: getNest(['results', i, 'data', 'confirmationMessage'], result)
          }
          payload.push(card);
        })

        this.setState({ disabled: false, loading: false });

        this.props.clearAllHandler();
        this.props.confirmHandler(true, payload);
      });
  }

  previewOrder = async () => {
    // const { items } = this.props;
    let funds = [];
    let promiseArrayTD = [];
    let promiseArray = [];
    let items = this.props.items.filter(e => !comingSoon.includes(getNest(['_order', 'state', 'type'], e)));
    items.forEach((e,index) => {
        let amtType = getNest(['_order', 'state', 'amtType'], e);
        let qty = amtType === 'Value'
          ? +getNest(['_order', 'qty'], e)
          : +getNest(['_order', 'state', 'qty'], e);
        if (qty <= 0 || qty === null || isNaN(qty)) return;
        let broker = Brokers.find(el => el.name === e.broker.name);
        const accbroker = this.props.brokers.find(b => b.name === broker.name); //setting account_number here
        if (typeof this.props.items[index]._order !== 'undefined') {
          const selectedFlag = e._brokerFlags.find(e => e.id === accbroker.id)
          this.props.items[index]._order.account_number = selectedFlag.account_number;
        }
        let ac = getNest(['_order', 'account_number'], e);
        let out = {
          "accountNo": ac,
          "orderType": "MARKET",
          "session": "NORMAL",
          "duration": "DAY",
          "orderStrategyType": "SINGLE",
          "orderLegCollection": [
            {
              "orderLegType": "EQUITY",
              "legId": 0,
              "instrument": {
                "assetType": "EQUITY",
                "symbol": cn(e, 'Ticker')
              },
              "instruction": "BUY",
              "quantity": qty,
              "quantityType": "ALL_SHARES"
            }
          ]
        }

        let flag = e._brokerFlags && e._brokerFlags.find(f => f.sel);
        let price = getNest(['_order', 'price'], e);

        // if (flag) out.broker = flag.id;
        // if (price) out.price = +price;
        funds.push(e);
        if(broker.name == 'TD Ameritrade') {
          let postData = out;
          promiseArrayTD.push(postData)
        }
        else {
          const previewOrder = (postData) => {
            return placeTradeItFundOrderPreview(postData)
            .then(result => {
              return result;
            })
            .catch(error => ({ ...getNestDefault(['response', 'data'], error, {}), status: 'Error' }))
          }
          let postData = {
            "accountNumber": ac,
            "orderAction": "buy",
            "orderQuantity": qty,
            "orderSymbol": cn(e, 'Ticker'),
            "orderPriceType": "market",
            "orderExpiration": "day",
            "orderQuantityType": amtType === 'Value' ? "TOTAL_PRICE" : "SHARES",
            "broker": broker.name
          }
          promiseArray[index] = previewOrder(postData);
        }
    }) //end for loop

    if (funds.length <= 0)
      return;

    // let postData = { funds };
    // let resp = await placeTDFundOrder(postData)
    // return resp;
    return Promise.all(promiseArray)
      .then(results => {
        items.forEach((e,index) => {
          let amtType = getNest(['_order', 'state', 'amtType'], e);
          let qty = amtType === 'Value'
            ? +getNest(['_order', 'qty'], e)
            : +getNest(['_order', 'state', 'qty'], e);
          // if (qty <= 0) return;
          let b = Brokers.find(eb => eb.name === e.broker.name)
          if(b.parent == 'TradeIT' && qty > 0){
            if(results[index].status == "REVIEW_ORDER" ){
              if(e.ticker == results[index].data.orderDetails.orderSymbol){
                this.props.items[index]._order.previewResult = results[index];
              }
            }
            else if(results[index].status == "Error" ){
              this.props.items[index]._order.previewResult = results[index];
            }
            else if(results[index].message){
              this.props.items[index]._order.previewResult = results[index];
            }
          }
        });
        return { funds, promiseArrayTradeIT: results, promiseArrayTD };
      })

  }

  placeFundsOrder = async () => {
    const { items } = this.props;
    let funds = [];
    let promiseArray = [];

    items.forEach(e => {
      let amtType = getNest(['_order', 'state', 'amtType'], e);
      let qty = amtType === 'Value'
        ? +getNest(['_order', 'qty'], e)
        : +getNest(['_order', 'state', 'qty'], e);

      if (qty <= 0 || qty === null || isNaN(qty)) return;
      let broker = Brokers.find(el => el.name === e.broker.name);
      let ac = getNest(['_order', 'account_number'], e);

      let out = {
        // ticker: cn(e, 'Ticker'),
        // price: cn(e, 'Nav Price'),
        // quantity: qty,
        // accountNo: '494102028',
        // transaction_type: 'BUY',
        "accountNo": ac,
        "orderType": "MARKET",
        "session": "NORMAL",
        "duration": "DAY",
        "orderStrategyType": "SINGLE",
        "orderLegCollection": [
          {
            "orderLegType": "EQUITY",
            "legId": 0,
            "instrument": {
              "assetType": "EQUITY",
              "symbol": cn(e, 'Ticker')
            },
            "instruction": "BUY",
            "quantity": qty,
            "quantityType": "ALL_SHARES"
          }
        ]
      }

      let flag = e._brokerFlags && e._brokerFlags.find(f => f.sel);
      let price = getNest(['_order', 'price'], e);
      if(e.type != 'MF'){
        funds.push(e);
      }
      if(broker.name == 'TD Ameritrade') {
        let postData = out;
        promiseArray.push(placeTDFundOrder(postData))
      }
      else if(broker.parent && broker.parent == 'TradeIT') {
        if (typeof e._order !== 'undefined' && e._order.state.type != 'MF') {
          const placeOrder = (el) => {
            if(el.type != 'MF') {
            return placeTradeItFundOrder({ "orderId": getNest(['orderId'], el._order.previewResult.data), broker: broker.name })
            .then(result => {
              return result;
            })
            .catch(error => ({ ...getNestDefault(['response', 'data'], error, {}), status: 'Error' }))
          }
        }
          promiseArray.push(placeOrder(e))
        }
      }
    })
    if (funds.length <= 0)
      return;

    // let postData = { funds };
    // let resp = await placeTDFundOrder(postData)
    // return resp;
    return Promise.all(promiseArray)
      .then(results => {
        return { funds, results };
      })
  }

  render() {
    return (
      <div>
        <div className="modal-body-ssf p-2">
          <Spinner isFetching={this.state.loading}/>

            {!this.state.loading &&  <ItemList {...this.props} previewResult={this.state.previewOrderResult} handleCheckbox={this.handleCheckbox}/>}
        </div>
        <ModalFooter className="py-2 sfm-modal-footer">
          <Footer {...this.props} confirmHandler={this.confirmHandler} handleCheckbox={this.handleCheckbox} disabled={this.state.disabled}/>
        </ModalFooter>
      </div>
    )
  }
}

class ItemList extends React.Component {

  componentDidMount() {
    const elems = document.querySelectorAll('.custom-scroll');
    if (elems.length) {
      [].forEach.call(elems, function(el) {
        if (el.scrollHeight > el.clientHeight) {
          el.style.paddingRight = '.25rem';
          el.style.paddingLeft = '.40rem';
        } else el.style.paddingRight = '0rem';
      });
    }
  }


  render() {
    const items = this.props.items.filter(e => getNest(['_order', 'stage'], e) === 'buy' && getNest(['_order', 'state', 'valid'], e) && !comingSoon.includes(e.vehicle));
    if (items.length === 0) return null;

    return (
      <div className="h-425 custom-scroll">
        {items.map((e, i) => {
          return (
            <div key={cn(e, 'Id')}>
              <ItemCard card={e} idx={i} clearHandler={this.props.clearHandler} brokers={this.props.brokers} handleCheckbox={this.props.handleCheckbox} />
            </div>
          )
        })}
      </div>
    );
  }
}

class ItemCard extends React.Component {
  state = {
    imageValidPath: false,
    card: {},
    idx: ''
  }

  componentDidMount() {
    const { card, idx } = this.props;
    getImageOrFallback(
      sponsorLogo(cn(card, 'Sponsor'))
    ).then(result => {
      if (result) {
        this.setState({ card, idx, imageValidPath: true });
      } else {
        this.setState({ card, idx, imageValidPath: false });
      }
    });
  }

  componentDidUpdate() {
    const { card, idx } = this.props;
    getImageOrFallback(
      sponsorLogo(cn(card, 'Sponsor'))
    ).then(result => {
      if (result) {
        this.setState({ card, idx, imageValidPath: true });
      } else {
        this.setState({ card, idx, imageValidPath: false });
      }
    });
  }

  componentWillMount() {
    const random11 = () => `${Math.floor(10000 + Math.random() * 90000)}${Math.floor(100000 + Math.random() * 900000)}`;
    this.props.card._order.oid = random11();
    // const broker = this.props.brokers.find(e => e.name === this.props.card.broker.name);
    // this.props.card._order.account_number = broker.account_number;
  }

  unselectCard = (item) => {
    this.props.clearHandler(item);
  }
  render() {
    const { imageValidPath, card, idx } = this.state;
    // const { card, idx } = this.props;
    if (!card.broker) return null;
    const broker = Brokers.find(e => e.name === card.broker.name);
    const price = (card._order.otype === 'Market' ? cn(card, 'Nav Price').toFixed(2) : card._order.price) || 0.0;
    const qty = card._order.state && card._order.state.amtType === 'Units' ? card._order.state.qty : card._order.qty;
    const tvalue = qty > 0 && price > 0 ? (qty * price + card.broker.commission) : 0.0;
    if(broker.parent && broker.parent == 'TradeIT' && card._order.previewResult && card._order.previewResult.status == "REVIEW_ORDER")
      return (
        <div>
        <Card className={classNames("shadow-sm mb-2 border-0")}>
          <CardBody className="p-0">
            <Row className="no-gutters">
              <Col className="col-3 p-1">
                <div className="selected-card bg-ssf-gray-l5 px-2 py-1 h-100 d-flex align-items-center" style={{ borderLeftColor: D3AppColors09[idx % D3AppColors09.length] }}>
                  {/* <div className="pr-1 text-center">
                    <h5 className="mb-0 text-blue">{cn(card, 'Ticker')}</h5>
                    <h3 className="mt-1 mb-0 text-info" style={{ minWidth: '4rem' }}>
                      <span className="ticker-price text-gray-5">$</span>
                      <span className="ticker-price font-weight-bold">{cn(card, 'Nav Price').toFixed(1)}</span>
                    </h3>
                  </div> */}
                  <TruncatedNameSponsor style={{ flex: 1 }} imageValidPath={imageValidPath} ticker={cn(card, 'Ticker')} name={cn(card, 'Name2')} sponsor={cn(card, 'Sponsor')} />
                  <div className="d-flex align-items-start h-100 close-wrapper">
                    <div className="close-icon-box pl-0">
                      <i className="fal fa-times-circle" onClick={() => this.unselectCard(card)}></i>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="col-4 py-1">
                <Row className="no-gutters h-100">
                  <Col className="col-5 pr-1 h-100">
                    <div className="p-1 py-2 h-100 d-flex align-items-center bg-ssf-gray-l5">
                      <Button color="link" block
                        className="broker px-1"
                        style={{ backgroundColor: broker.color, height: '80px', maxHeight: '80px' }} >
                        <h5 className="mb-1">{broker.name}</h5>
                        <h5 className="mb-0 font-weight-bold">{'$' + card._order.previewResult.data.orderDetails.estimatedOrderCommission}</h5>
                        {/* <h5 className="mb-1">{'Account'}</h5> */}
                        <h6 className="mt-2 mb-0 font-weight-bold confirm-info-account-number">({card._order.account_number})</h6>
                      </Button>
                    </div>
                  </Col>
                  <Col className="col-7 h-100">
                    <div className="p-2 h-100 d-flex align-items-center bg-ssf-gray-l5 text-gray-8">
                      <Button
                        color="link"
                        block
                        className="broker px-1"
                        style={{ backgroundColor: broker.color, textAlign: 'inherit' }}
                      >
                        <div className="row no-gutters w-100 mb-0">
                          <div className="col-auto text-gray-8 confirm-info-container" >
                            <h6 className="mb-1"><i className="fal fa-fw fa-money-bill-wave-alt mr-1 align-self-center" ></i>Trade Value</h6>
                            <h6 className="mb-1"><i className="fal fa-fw fa-coins mr-1 align-self-center"></i>Quantity</h6>
                            <h6 className="mb-1"><i className="fal fa-fw fa-clipboard-check mr-1 align-self-center"></i>Order Type</h6>
                            <h6 className="mb-1"><i className="fal fa-fw fa-at mr-1 align-self-center"></i>Price</h6>
                            {card._order.otype !== 'Market' &&
                              <h6 className="mb-1"><i className="fal fa-fw fa-hourglass-start mr-2 align-self-center"></i>Good Until</h6>}
                          </div>
                          <div className="col-6 ml-auto mr-auto confirm-info-container-inner-right">
                            <h6 className="mb-1 font-weight-bold" style={{ whiteSpace: 'nowrap' }}>${numFmt(card._order.previewResult.data.orderDetails.estimatedTotalValue)}</h6>
                            <h6 className="mb-1 font-weight-bold">{numFmt(parseInt(card._order.previewResult.data.orderDetails.orderQuantity || 0, 10))}</h6>
                            <h6 className="mb-1">{card._order.otype}</h6>
                            <h6 className="mb-1">${numFmt(card._order.previewResult.data.orderDetails.estimatedOrderValue)}</h6>
                            {card._order.otype !== 'Market' &&
                              <h6 className="mb-1">{card._order.validity}</h6>}
                          </div>
                        </div>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="col p-1">
                <div className="p-1 h-100 d-flex flex-column align-items-center justify-content-center bg-ssf-gray-l5">
                  {card._order.previewResult.data.orderDetails.warnings.length > 0 &&
                  <div>
                    <Row className="no-gutters"><h5 className="p-1 font-weight-bold text-warning confirm-info-error"><i className="fas fa-exclamation-triangle"></i> Warnings</h5></Row>
                    {card._order.previewResult.data.orderDetails.warnings.map((warn, index) => {
                      this.props.handleCheckbox()
                      return(
                        <Row key={warn.message} className="no-gutters mb-1">
                          <Col className="col-1 text-center">{warn.requiresAcknowledgement && <h5 className="mb-0 text-gray-7 confirm-info-error"><input className='warning-tradeit' id={"warning-" + card.ticker + "-" + index} type="checkbox" onChange={this.props.handleCheckbox}></input></h5>}</Col>
                          <Col className="col-11"><h5 className="mb-0 text-gray-7 confirm-info-error">{warn.message}</h5></Col>
                        </Row>
                      );
                    })}
                  </div>}
                  {card._order.previewResult.data.orderDetails.warnings.length == 0 &&
                  <div>
                    {/* <Row className="no-gutters">
                      <h5 className="p-1 font-weight-bold text-warning confirm-info-error">
                        <span className="tick"><i className="fas fa-check-circle"></i></span>
                      </h5>
                    </Row> */}
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <i className="fal fa-fw fa-check-circle fa-2x text-success"></i>
                      <h5 className="text-gray-8 fs-11" style={{ marginTop: '0.5rem' }}>Order has been created successfully.<br />Please click on Confirm to proceed.</h5>
                    </div>
                  </div>}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        </div>
      );
    else if(broker.parent && broker.parent == 'TradeIT' && card._order.previewResult && card._order.previewResult.status == "Error" && 
    get(card, '_order.previewResult.message.default', '') == 'Session Expired' && card.type != 'MF'){
      return (
        //<div></div>
        <Card className={classNames("shadow-sm mb-2 border-0")}>
          {/* <CardBody className="p-0">
            <Row className="no-gutters">
              <Col className="col p-1">
                <div className="selected-card bg-ssf-gray-l5 px-2 py-1 h-100 d-flex align-items-center" style={{ borderLeftColor: D3Colors09[idx % D3Colors09.length] }}>
                  <div className="pr-1 text-center">
                    <h5 className="mb-0 text-blue">{cn(card, 'Ticker')}</h5>
                    <h3 className="mt-1 mb-0 text-info" style={{ minWidth: '4rem' }}>
                      <span className="ticker-price text-gray-5">$</span>
                      <span className="ticker-price font-weight-bold">{cn(card, 'Nav Price').toFixed(1)}</span>
                    </h3>
                  </div>
                  <TruncatedNameSponsor name={cn(card, 'Name2')} sponsor={cn(card, 'Sponsor')} />
                  <div className="h-100 d-flex align-items-end ml-auto">
                    <div className="btn btn-link text-gray-4 p-0 border-0 lh-100">
                      <i className="far fa-times-circle" onClick={() => this.unselectCard(card)}></i>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="col-4 py-1">
                <Row className="no-gutters h-100">
                  <Col className="col-4 pr-1 h-100">
                    <div className="p-1 py-2 h-100 d-flex align-items-center bg-ssf-gray-l5">
                      <Button color="link" block
                        className="broker px-1"
                        style={{ backgroundColor: broker.color }} >
                        <h5 className="mb-1">{broker.name}</h5>
                        <h5 className="mb-0 font-weight-bold">{'$' + card.broker.commission}</h5>
                      </Button>
                    </div>
                  </Col>
                  <Col className="col-8 h-100">
                    <div className="p-2 h-100 d-flex align-items-center justify-content-center bg-ssf-gray-l5 text-gray-8">
                      <div className="row no-gutters w-100 mb-0">
                        <div className="col-auto text-gray-7 ml-auto mr-2">
                          <h6 className="mb-1"><i className="fal fa-fw fa-money-bill-wave-alt mr-1 align-self-center" ></i>Trade Value</h6>
                          <h6 className="mb-1"><i className="fal fa-fw fa-coins mr-1 align-self-center"></i>Quantity</h6>
                          <h6 className="mb-1"><i className="fal fa-fw fa-clipboard-check mr-1 align-self-center"></i>Order Type</h6>
                          <h6 className="mb-0"><i className="fal fa-fw fa-at mr-1 align-self-center"></i>Price</h6>
                          {card._order.otype !== 'Market' &&
                            <h6 className="mb-0 mt-1"><i className="fal fa-fw fa-hourglass-start mr-2 align-self-center"></i>Good Until</h6>}
                        </div>
                        <div className="col-5">
                          <h6 className="mb-1 font-weight-bold" style={{ whiteSpace: 'nowrap' }}>${numFmt(tvalue)}</h6>
                          <h6 className="mb-1 font-weight-bold">{numFmt(parseInt(qty || 0, 10))}</h6>
                          <h6 className="mb-1">{card._order.otype}</h6>
                          <h6 className="mb-0">${numFmt(price)}</h6>
                          {card._order.otype !== 'Market' &&
                            <h6 className="mb-0 mt-1">{card._order.validity}</h6>}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="col-4 p-1">
                <div className="p-1 h-100 d-flex flex-column align-items-center justify-content-center bg-ssf-gray-l5">
                  <h6 className="text-gray-6">Account Number:</h6>
                  <h5 className="mb-0 text-ssf-blue-d2">{card._order.account_number}</h5>
                </div>
              </Col>
            </Row>
          </CardBody> */}
          <CardBody className="p-0">
            <Row className="no-gutters">
              <Col className="col-3 p-1">
                <div className="selected-card bg-ssf-gray-l5 px-2 py-1 h-100 d-flex align-items-center" style={{ border: 0 }}>
                  {/* <div className="pr-1 text-center">
                    <h5 className="mb-0 text-blue">{cn(card, 'Ticker')}</h5>
                    <h3 className="mt-1 mb-0 text-info" style={{ minWidth: '4rem' }}>
                      <span className="ticker-price text-gray-5">$</span>
                      <span className="ticker-price font-weight-bold">{cn(card, 'Nav Price').toFixed(1)}</span>
                    </h3>
                  </div> */}
                  <TruncatedNameSponsor style={{ flex: 1 }} imageValidPath={imageValidPath} ticker={cn(card, 'Ticker')} name={cn(card, 'Name2')} sponsor={cn(card, 'Sponsor')} />
                  <div className="d-flex align-items-start h-100 close-wrapper">
                    <div className="close-icon-box pl-0">
                      <i className="fal fa-times-circle" onClick={() => this.unselectCard(card)}></i>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="col-4 py-1">
                <Row className="no-gutters h-100">
                  <Col className="col-5 pr-1 h-100">
                    <div className="p-1 py-2 h-100 d-flex align-item-flex-start bg-ssf-gray-l5">
                      <Button color="link" block
                        className="broker px-1"
                        style={{ backgroundColor: broker.color, height: '80px', maxHeight: '80px' }} >
                        <h5 className="mb-1">{broker.name}</h5>
                        <h5 className="mb-0 font-weight-bold">{'$' + card.broker.commission}</h5>
                        {/* <h5 className="mb-1">{'Account'}</h5> */}
                        <h6 className="mt-2 mb-0 font-weight-bold confirm-info-account-number">({card._order.account_number})</h6>
                      </Button>
                    </div>
                  </Col>
                  <Col className="col-7 h-100">
                    <div className="p-2 h-100 d-flex align-item-flex-start bg-ssf-gray-l5 text-gray-8">
                      <Button
                        color="link"
                        block
                        className="broker px-1"
                        style={{ backgroundColor: broker.color, textAlign: 'inherit', height: '100%' }}
                      >
                        <div className="row no-gutters w-100 mb-0" style={{ height: '100%' }}>
                          <div className="col-auto text-gray-8 confirm-info-container" >
                            <h6 className="mb-0"><i className="fal fa-fw fa-money-bill-wave-alt mr-1 align-self-center" ></i>Trade Value</h6>
                            <h6 className="mb-0"><i className="fal fa-fw fa-coins mr-1 align-self-center"></i>Quantity</h6>
                            <h6 className="mb-0"><i className="fal fa-fw fa-clipboard-check mr-1 align-self-center"></i>Order Type</h6>
                            <h6 className="mb-0"><i className="fal fa-fw fa-at mr-1 align-self-center"></i>Price</h6>
                            {card._order.otype !== 'Market' &&
                              <h6 className="mb-1"><i className="fal fa-fw fa-hourglass-start mr-2 align-self-center"></i>Good Until</h6>}
                          </div>
                          <div className="col-6 ml-auto mr-auto confirm-info-container-inner-right">
                            <h6 className="mb-1 font-weight-bold" style={{ whiteSpace: 'nowrap' }}>${numFmt(tvalue)}</h6>
                            <h6 className="mb-1 font-weight-bold">{numFmt(parseInt(qty || 0, 10))}</h6>
                            <h6 className="mb-1">{card._order.otype}</h6>
                            <h6 className="mb-0">${numFmt(price)}</h6>
                            {card._order.otype !== 'Market' &&
                              <h6 className="mb-0 mt-1">{card._order.validity}</h6>}
                          </div>
                        </div>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="col p-1">
                <div className="p-1 h-100 d-flex flex-column align-items-center justify-content-center bg-ssf-gray-l5">
                {card._order.previewResult.status == "Error" && (
                  <div style={{ width: '100%' }}>
                    <Row className="no-gutters">
                      <h5 className="p-1 font-weight-bold text-ssf-red-d1 confirm-info-error"><i className="fas fa-exclamation-triangle"></i> Errors</h5>
                    </Row>
                    {typeof card._order.previewResult.message === 'string' && (
                      <Row className="no-gutters mb-1">
                        <Col className="col-1 text-center"></Col>
                        <Col className="col-11"><h5 className="mb-0 text-gray-7 confirm-info-error">{get(card, '_order.previewResult.message.default', '')}</h5></Col>
                      </Row>
                    )}
                    <Row className="no-gutters mb-1">
                      <Col className="col-1 text-center"></Col>
                      <Col className="col-11"><h5 className="mb-0 text-ssf-red-d1 confirm-info-error">This order would't be executed if you click confirm.</h5></Col>
                    </Row>
                    {typeof card._order.previewResult.message === 'object' && card._order.previewResult.message.constructor === Object && card._order.previewResult.message.longMessage.map((warn, index) => {
                      return(
                        <Row key={index+'error-'+warn} className="no-gutters mb-1">
                          <Col className="col-1 text-center"></Col>
                          <Col className="col-11"><h5 className="mb-0 text-gray-7 confirm-info-error">{warn}</h5></Col>
                        </Row>
                      );
                    })}
                    {typeof card._order.previewResult.message === 'string' && (
                      <Row key={'error-'+card._order.previewResult.message} className="no-gutters mb-1">
                        <Col className="col-1 text-center"></Col>
                        <Col className="col-11"><h5 className="mb-0 text-gray-7 confirm-info-error">{card._order.previewResult.message}</h5></Col>
                      </Row>
                    )}
                  </div>
                )}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      );
    }
    else{
      return (
        // <div></div>
        <Card className={classNames("shadow-sm mb-2 border-0")}>
          <CardBody className="p-0">
            <Row className="no-gutters">
              <Col className="col-3 p-1">
                <div className="selected-card bg-ssf-gray-l5 px-2 py-1 h-100 d-flex align-items-center" style={{ borderLeftColor: D3AppColors09[idx % D3AppColors09.length] }}>
                  {/* <div className="pr-1 text-center">
                    <h5 className="mb-0 text-blue">{cn(card, 'Ticker')}</h5>
                    <h3 className="mt-1 mb-0 text-info" style={{ minWidth: '4rem' }}>
                      <span className="ticker-price text-gray-5">$</span>
                      <span className="ticker-price font-weight-bold">{cn(card, 'Nav Price').toFixed(1)}</span>
                    </h3>
                  </div> */}
                  <TruncatedNameSponsor style={{ flex: 1 }} imageValidPath={imageValidPath} ticker={cn(card, 'Ticker')} name={cn(card, 'Name2')} sponsor={cn(card, 'Sponsor')} />
                  <div className="h-100 d-flex align-items-end ml-auto">
                    <div className="btn btn-link text-gray-4 p-0 border-0 lh-100">
                      <i className="far fa-times-circle" onClick={() => this.unselectCard(card)}></i>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="col-4 py-1">
                <Row className="no-gutters h-100">
                  <Col className="col-5 pr-1 h-100">
                    <div className="p-1 py-2 h-100 d-flex align-items-center bg-ssf-gray-l5">
                      <Button color="link" block
                        className="broker px-1"
                        style={{ backgroundColor: broker.color, height: '80px', maxHeight: '80px' }} >
                        <h5 className="mb-1">{broker.name}</h5>
                        <h5 className="mb-0 font-weight-bold">{'$' + card.broker.commission}</h5>
                        {/* <h5 className="mb-1">{'Account'}</h5> */}
                        <h6 className="mt-2 mb-0 font-weight-bold confirm-info-account-number">({card._order.account_number})</h6>
                      </Button>
                    </div>
                  </Col>
                  <Col className="col-7 h-100">
                    <div className="p-2 h-100 d-flex align-items-center bg-ssf-gray-l5 text-gray-8">
                    <Button color="link" block
                        className="broker px-1"
                        style={{ backgroundColor: broker.color, textAlign: 'inherit' }} >
                      <div className="row no-gutters w-100 mb-0">

                        <div className="col-auto text-gray-8 confirm-info-container" >
                          <h6 className="mb-1"><i className="fal fa-fw fa-money-bill-wave-alt mr-1 align-self-center" ></i>Trade Value</h6>
                          <h6 className="mb-1"><i className="fal fa-fw fa-coins mr-1 align-self-center"></i>Quantity</h6>
                          <h6 className="mb-1"><i className="fal fa-fw fa-clipboard-check mr-1 align-self-center"></i>Order Type</h6>
                          <h6 className="mb-1"><i className="fal fa-fw fa-at mr-1 align-self-center"></i>Price</h6>
                          {card._order.otype !== 'Market' &&
                            <h6 className="mb-1"><i className="fal fa-fw fa-hourglass-start mr-2 align-self-center"></i>Good Until</h6>}
                        </div>
                        <div className="col-6 ml-auto mr-auto confirm-info-container-inner-right">
                          <h6 className="mb-1 font-weight-bold" style={{ whiteSpace: 'nowrap' }}>${numFmt(tvalue)}</h6>
                          <h6 className="mb-1 font-weight-bold">{numFmt(parseInt(qty || 0, 10))}</h6>
                          <h6 className="mb-1">{card._order.otype}</h6>
                          <h6 className="mb-1">${numFmt(price)}</h6>
                          {card._order.otype !== 'Market' &&
                            <h6 className="mb-1">{card._order.validity}</h6>}
                        </div>
                      </div>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="col p-1">
                <div className="p-1 h-100 d-flex flex-column align-items-center justify-content-center bg-ssf-gray-l5">
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <i className="fal fa-fw fa-check-circle fa-2x text-success"></i>
                    <h5 className="text-gray-8 fs-11" style={{ marginTop: '0.5rem' }}>Order has been created successfully.<br />Please click on Confirm to proceed.</h5>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      );
    }
  }
}

export class Footer extends React.Component {
  render() {
    const items = this.props.items.filter(e => getNest(['_order', 'stage'], e) === 'buy');
    let disabled = this.props.disabled || items.length === 0;

    return (
      <React.Fragment>
        <FooterActions backHandler={() => this.props.actionHandler('act')} clearHandler={this.props.clearAllHandler} />

        <Button id="select-tt-confirm" color="select" disabled={disabled}
          className="btn h3 text-uppercase font-weight-bold p-3 mb-0 ml-auto secondary-btn"
          onClick={this.props.confirmHandler}>Confirm</Button>
          { this.props.disabled && <SelectButtonDisabledTooltip target={'select-tt-confirm'} placement={'top'} /> }

      </React.Fragment>
    )
  }
}

export class Confirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
    };
  }

  componentDidMount() {
    const elems = document.querySelectorAll('.custom-scroll');
    if (elems.length) {
      [].forEach.call(elems, function(el) {
        if (el.scrollHeight > el.clientHeight) {
          el.style.paddingRight = '.25rem';
          el.style.paddingLeft = '.40rem';
        } else el.style.paddingRight = '0rem';
      });
    }
  }

  toggle = (accountPage = false) => {
    this.setState({ modal: !this.state.modal, accountPage });
    accountPage || this.props.confirmHandler(false);
  }

  triggerInvestment = () => {
    const { triggerInvestment } = this.props;
    if (triggerInvestment) {
      triggerInvestment();
    }
    this.toggle(true);
  }

  render() {
    const { match } = this.props;
    let renderButton = (
      <div className="row no-gutters w-100">
        <div className="col">
          <Button color="link" className="text-white" block onClick={() => this.toggle(true)}>View Account</Button>{' '}
        </div>
        <div className="col">
          <Button color="link" className="text-white continue" block onClick={this.toggle}>Continue Search</Button>
        </div>
      </div>
    )
    if (match && match.path === '/investments' || window.location.pathname === '/investments') {
      renderButton = (
        <div className="row no-gutters w-100">
          <div className="col">
            <Button color="link" className="text-white" block onClick={this.triggerInvestment}>View Account</Button>{' '}
          </div>
        </div>
      )
    }
    if (this.state.accountPage === true) {
      return <Redirect to='/investments' />
    }
    const { confirmPayload } = this.props;

    return (
      <Modal isOpen={this.state.modal} toggle={() => {}} size="md" centered modalClassName="confirm" contentClassName="bg-ssf-blue-modal-bg br-15px p-4">
        <ModalHeader className="text-white lh-100 border-0 d-flex justify-content-center pt-2" tag="h4">
          <span className="text-white fs-20">Confirmation</span>
          <i className="fal fa-times-circle text-white close-icon" onClick={() => this.setState({ modal: false })}></i>
        </ModalHeader>
        <ModalBody className="p-0 font-one no-scroll">
          { confirmPayload ? <ConfirmationPayload data={confirmPayload} /> :
          <div className="d-flex flex-column align-items-center justify-content-center">
            <h5 className="text-center text-white" style={{ marginTop: '0.5rem' }}>Order has been added to watchlist successfully.<br />Please click on View Account to proceed.</h5>            
            <br />
            <i className="fal fa-fw fa-check-circle fa-7x text-white"></i>
            <br />
          </div> }
        </ModalBody>
        <ModalFooter className="pb-2">
          {renderButton}
        </ModalFooter>
      </Modal>
    )
  }
}

const ConfirmationPayload = ({ data }) => {
    if (data.length === 0) return null;
    const style = { overflowY: 'auto' }

    return (

      <div className="px-1 pt-2" style={style}>
        <div className="custom-scroll">
          {data.map((e, i) => {
            return (
              <div key={e.ticker}>
                <ResultCard data={e} />
              </div>
            )
          })}
        </div>
      </div>
    );
}

export class ResultCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      data: this.props.data,
      imageValidPath: false,
    };
  }

  componentDidMount() {
    if (this.state.data.sponsor) {
      getImageOrFallback(
        sponsorLogo(this.state.data.sponsor)
      ).then(result => {
        if (result) {
          this.setState({ imageValidPath: true });
        } else {
          this.setState({ imageValidPath: false });
        }
      });
    }
  }

  toggleOpen = () =>{
    this.setState({isOpen : !this.state.isOpen});
  }

  render () {
    const { imageValidPath } = this.state;
    return (
      <Card className={classNames("shadow-sm mb-2 border-0")}>
        <CardBody className="p-0">
          <Row className="no-gutters">
            <Col className="col-5 p-1">
              <div className="selected-card bg-ssf-gray-l5 px-2 py-1 h-100 d-flex align-items-center position-relative" style={{ borderLeftColor: this.state.data.color }}>
                <div className="pr-1 position-absolute" style={{ top: '4px', right: '4px' }}>
                  <h5 className="mb-0 text-gray-5">{this.state.data.ticker}</h5>
                </div>
                <TruncatedNameSponsor imageValidPath={imageValidPath} name={this.state.data.name} sponsor={this.state.data.sponsor} />
              </div>
            </Col>
            <Col className="col-3 py-1">
              <div className="p-1 py-2 h-100 d-flex align-items-center bg-ssf-gray-l5">
                <Button color="link" block
                  className="broker px-1"
                  style={{ backgroundColor: this.state.data.brokerColor }} >
                  <h5 className="mb-1 text-white">{this.state.data.broker}</h5>
                </Button> 
              </div>
            </Col>
            <Col className="col-4 p-1">
              <div className="p-1 h-100 d-flex flex-column align-items-center justify-content-center bg-ssf-gray-l5">
                { this.state.data.error
                ? <div className="text-danger text-center">
                    <h4 className="mb-0">Order Failed</h4>
                    { this.state.data.errorMsg && <h6 className="mb-0 mt-1"><TruncatedText text={this.state.data.errorMsg} lines={2} /></h6> }
                  </div>
                : <div className="text-center"><h4 className="text-success">Order Placed</h4>{this.state.data.borker != "TD Ameritrade" ? <h6 className="mb-0 mt-1"><a className="details-link" onClick={this.toggleOpen}>Details</a></h6>: <h6 className="mb-0 mt-1"></h6>}</div> }
              </div>
            </Col>
          </Row>
        </CardBody>
        {this.state.isOpen && <div>
          <Row className="no-gutters">
            <Col className="col-2 p-1">
              <h6 className="mb-1">Order No:</h6>
            </Col>
            <Col className="col-10 p-1">
              <h6 className="confirmation-detail-text mb-1">{this.state.data.orderNumber}</h6>
            </Col>
          </Row>
          <Row className="no-gutters">
            <Col className="col-2 p-1">
              <h6 className="mb-1">Time:</h6>
            </Col>
            <Col className="col-10 p-1">
              <h6 className="confirmation-detail-text mb-1">{this.state.data.timestamp}</h6>
            </Col>
          </Row>
          <Row className="no-gutters">
            <Col className="col-2 p-1">
              <h6 className="mb-1">Message:</h6>
            </Col>
            <Col className="col-10 p-1">
              <h6 className="confirmation-detail-text mb-1">{this.state.data.confirmationMessage}</h6>
            </Col>
          </Row>

        </div>}
      </Card>
    );
  }
}

const SelectButtonDisabledTooltip = ({ target, placement = 'bottom' }) => {
  return (
    <UncontrolledTooltip className="w-sm" placement={placement} target={target}>
      Please acknowledge the warnings displayed on the screen.
    </UncontrolledTooltip>
)}

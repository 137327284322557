import React from 'react'
// import { Spinner as SpinnerLoader} from 'reactstrap'

export default function Spinner({ isFetching, moreProps }) {
  if (!isFetching) {
    return null
  }

  return (
    <div className="spinner-wrapper" {...moreProps}>
      <div className="text-secondary text-center mt-5">
        <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5"></i>
      </div>
    </div>
  )
}

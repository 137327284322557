import axiosMainInstance from 'axios'
import config from 'env'
import { createAPIInstance } from '../../apis/api';
// import { message } from 'antd';

const axios = axiosMainInstance.create({
  baseURL: config.apiBaseUrl
})

export const getSuggestions = async ({ query, universe }) => {
  let _universe = Array.isArray(universe) ? universe.toString() : universe;
  const response = await axios.get(`/search-queries-by-frequency`, {
    params: {
      q: query,
      universe: _universe, // 'etf,etn,mf,cef,share,basket'
    }
  })
  const { query_suggestions, investment_suggestions } = response.data
  const result = []
  if (query_suggestions && query_suggestions.length > 0)
    result.push({
      title: 'Searches',
      suggestions: query_suggestions
    })
  if (investment_suggestions && investment_suggestions.length > 0)
    result.push({
      title: 'Investments',
      suggestions: investment_suggestions
    })
  return result
}

/**GET RECENT SEARCH  LIST  */
export const getSearchRecentList = async () =>{
  try {
    const queryResult = [];
    const response = await createAPIInstance().get(`/user/get-user-search`,{
      headers: { 'Content-Type': 'application/json' },
      params: { 'count': 10 }
      }).then( response => response)
    if(response && response.data && typeof response.data.data!=='string' && response.data.data.length > 0){
      let result = response.data.data;
      queryResult.push({
        title: 'Recent Searches',
        suggestions: result.map(query=> query)
      })
    }
    return queryResult;
  }catch(e){
    console.log('Something went wrong in get recent search list API');
    return [];
  }
}

/**GET SAVE SEARCH LIST */
export const getSaveSearchList = async () => {
  try {
    const response = await createAPIInstance().get(`/user/get-saved-search`).
                   then( response => response)
    const _result = {
        queryResult:[],
        saveListResponse:[]
      };
    if(response && response.data && typeof response.data.result !=='string' && response.data.result.length > 0){
      let result = response.data.result;
      // _result.queryResult.push({
      //   title: 'Saved Search',
      //   suggestions: result.map(query=>query && query.query)
      // })
      _result.saveListResponse = result;
    }
    return _result;
  }catch (e){
    console.log('Something went wrong in get save search list API');
    return null;
  }
}

/**ADD ITEM INTO SAVE SEARCH LIST */
export const saveSearchItemToList = async (query,search_type ) =>{
  const form = new FormData();
  form.append('query',query);
  form.append('search_type',search_type);
  try {
    const response = await createAPIInstance().post('/user/save-my-search',
                   form,
                   {headers: { 'Content-Type': 'multipart/form-data' }}).then( response => response)
    return response;
  }catch(e){
    console.log('Something went wrong in save my item API');
    return null;
  }
}

/** DELETE SAVED ITEM FROM LIST */
export const deleteSearchItemOfList = async (id ) =>{
  try{
    const response = await createAPIInstance().delete('/user/delete-saved-search',{
      headers: { 'Content-Type': 'application/json' },
      params: { 'id': id }
    }).then( response => response)
    return response;
  }catch(e){
    console.log('Something went wrong in deleting save item API');
    return null;
  }
}

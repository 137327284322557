import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Dropdown, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CustomInput, Collapse } from 'reactstrap';
import RangeSlider from './RangeSlider'
import { SortAttrs } from '../DataSet';

var classNames = require('classnames');

export default class Toolbar extends Component {
  constructor (props) {
    super(props);
    this.state = {
      sortAttr: props.sortAttr,
      filterAttrs: {},
      filterItems: this.filterAttrsSelection(this.props.filterAttrData),
    };

    this.onSortBtnClick = this.onSortBtnClick.bind(this);
    this.filterChange = this.filterChange.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
  }

  // componentWillMount() {
  //   this.setState({
  //     filterItems: this.filterAttrsSelection(this.props.filterAttrData),
  //   });
  // }

  componentDidMount(){
    this.setActiveFilters()
  }

  setActiveFilters = () => {
    if (Object.keys(this.props.filterAttrs).length > 0) {
      const { filterAttrs } = this.props;
      let selectedFilter = {};
      Object.keys(filterAttrs).map((c) => {
        selectedFilter[c] = filterAttrs[c];
      });
      this.setState({
        filterItems: selectedFilter,
      })
    }
  }

  // componentWillReceiveProps(newProps) {
  //   if (newProps.query !== this.props.query) this.setState({ filterItems: {}, filterAttrs: {} }, () => this.setState({ filterItems: this.filterAttrsSelection(newProps.filterAttrData) }))
  //   if (!Object.keys(newProps.filterAttrs).length) this.setState({ filterAttrs: {}, filterItems: this.filterAttrsSelection(newProps.filterAttrData) });
  // }

  selected = (c, v) => {
    if (this.state && this.state.filterItems && this.state.filterItems.length) {
      let _v = this.state.filterItems[c] && this.state.filterItems[c].find(e => e.name === v);
      return _v && _v.selected;
    }
  }

  filterAttrsSelection = (obj) => {
    return Object.keys(obj)
             .reduce((p, c) => ({...p, [c]: obj[c].map(i => ({name: i, selected: this.selected(c, i)}))}), {});
  }

  onSortBtnClick(sortAttr) {
    this.setState({ sortAttr });
    this.props.sortHandler(sortAttr);
  }

  // filterHandler(filterSelection) {
  //   let filterItems = Object.keys(filterSelection)
  //                      .reduce((p, c) => ({...p, [c]: filterSelection[c].filter(i => i.selected).map(i => i.name)}), {});
  //
  //   this.props.filterHandler(filterItems);
  //
  //   let filterAttrs = Object.keys(filterItems)
  //                       .filter(i => filterItems[i].length > 0)
  //                       .reduce((acc, v) => ({...acc, [v]:filterItems[v]}), {})
  //   this.setState({ filterAttrs });
  // }

  filterChange(attr, value) {
    let filterItems = JSON.parse(JSON.stringify(this.state.filterItems));
    filterItems[attr][value].selected = !filterItems[attr][value].selected;
    this.setState({ filterItems: filterItems }, () => {
      this.props.filterHandler(this.state.filterItems);
    });
  }

  resetFilters() {
    this.props.filterHandler({});
    this.setState({ filterItems: Toolbar.filterAttrsSelection(this.props.filterAttrData), filterAttrs: {} });
  }

  render() {
    return this.props.loading ? null : (
      <React.Fragment>
        <div className="toolbarWrapper" style={{ background: '#ddd', paddingTop: 10, paddingTop: 0 }}>
          <div className="container dynamic e2" style={{ height: 'auto' }}>
            <ToolbarSort
              sortAttr={this.props.sortAttr}
              sortDesc={this.props.sortDesc}
              sortAttrSelect={this.onSortBtnClick}
              filterItems={this.state.filterItems}
              filtersActive={this.state.filterAttrs}
              filterChange={this.filterChange}
              dateRangeHandler={this.props.dateRangeHandler}
              dateRangeSelected={this.props.dateRangeSelected}
              ref={this.props.inputRef}
              query={this.props.query}
              range={this.props.range} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

class ToolbarSort extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortOpen: false,
      filterOpen: false,
      selectRangeOpen: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggleRangeSlider() {
    const topBar = document.getElementById('top-bar');
    this.setState({ selectRangeOpen: !this.state.selectRangeOpen }, () => {
      if (this.state.selectRangeOpen) {
        document.body.style.paddingTop = `${topBar.clientHeight + 78}px`;
      } else {
        document.body.style.paddingTop = `${topBar.clientHeight - 78}px`;
      }
    })
  }

  closeFilter(value) {
    if (value) {
      this.setState({ filterOpen: false });
    }
  }

  toggle(attr) {
    this.setState({ [attr]: !this.state[attr] });
  }

  onMouseOver(attr) {
    this.setState({ [attr]: true });
  }

  onMouseLeave(attr) {
    this.setState({ [attr]: false });
  }

  render() {
    const { filterItems, filtersActive } = this.props;

    var iconClass = (attr) => {
      return "text-light fas fa-fw" + (
                this.props.sortAttr === attr
                ? (this.props.sortDesc ? " fa-arrow-alt-circle-up" : " fa-arrow-alt-circle-down")
                : "" )
    }

    const screenWidth = window.screen.width;

    return (
      <Fragment>
      <div className="row" style={{ paddingRight: 5 }}>
        <div className="col-auto nav-scroller" style={{ paddingRight: 5, marginBottom: '10px' }}>
          <div className="sortButtonWrapper">
            <Dropdown
              className="sortbutton"
              isOpen={this.state.sortOpen}
              onMouseOver={() => this.onMouseOver('sortOpen')}
              onMouseLeave={() => this.onMouseLeave('sortOpen')}
              toggle={() => {}}
              style={{ lineHeight: 1.5 }}
            >
              <DropdownToggle className="sortbutton" caret={true} style={{ lineHeight: 1, fontSize: 'inherit', padding: '2px 7px' }}>
                <i className={`far fa-fw fa-sort-amount-${this.props.sortOpen ? "up" : "down" }`}></i> Sort
              </DropdownToggle>
              <DropdownMenu
                className={classNames("nav", {"ghost-wrapper": this.state.sortOpen, "d-none": !this.state.sortOpen})}
              >
                <div className="dropdown-wrapper">
                  { SortAttrs.map((v, i) => <DropdownItem className={classNames("nav-link", {'text-tag-blue': this.props.sortAttr === v.name})} key={i} color="link"
                      onClick={() => {this.props.sortAttrSelect(v.name); this.onMouseLeave('sortOpen');}}>{v.name}</DropdownItem>) }
                </div>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <div className="col-auto nav-scroller" style={{ padding: '0 5px', marginBottom: '10px' }}>
          <div className={ Object.keys(filtersActive).length > 0 ? "filterButtonWrapper select-col"  : "filterButtonWrapper unselect-col"}>
            <Dropdown
              className="filterbutton"
              isOpen={this.state.filterOpen}
              onMouseOver={() => this.onMouseOver('filterOpen')}
              onMouseLeave={() => this.onMouseLeave('filterOpen')}
              toggle={() => {}}
              style={{ lineHeight: 1.5 }}
            >
              <DropdownToggle className="filterbutton" caret={true} style={{ lineHeight: 1, fontSize: 'inherit', padding: '2px 7px' }}>
                <i className="far fa-fw fa-filter"></i> Filter
              </DropdownToggle>
              <DropdownMenu
                className={classNames("nav", {"ghost-wrapper": this.state.filterOpen, "d-none": !this.state.filterOpen})}
              >
                <div className="dropdown-wrapper">
                  { Object.keys(this.props.filterItems).map((v, i) => {
                    return (
                      <ToolbarFilter key={i} filtersActive={filtersActive} filterAttrs={v} filterValues={this.props.filterItems[v]} filterChange={this.props.filterChange} closeFilter={(value) => this.closeFilter(value)} />
                    )}
                  )}
                </div>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        {
          Object.keys(filtersActive).length > 0 && screenWidth > 767 &&
          // <div className="col-auto nav-scroller" style={{ display: 'flex'}}>
            // {
              Object.keys(filtersActive).map((item) => {
                return filtersActive[item].map((data) => {
                  const value = item;
                  const index = filterItems[item].findIndex((x) => x.name === data);
                  return (
                    <div className="filter-items">
                      {data}  <a className="filter-items__btn" onClick={this.props.filterChange.bind(this,value, index)}><i className="far fa-fw fa-times"></i></a>
                    </div>
                  )
                })
              })
            // }
          // </div>
        }

        {
          screenWidth <= 767 &&
          <Fragment>
            <div className="col-auto nav-scroller" style={{ padding: '0 5px', marginBottom: '10px', position: 'absolute', right: '15px', height: '23px' }}>
              <div className="selRangeButtonWrapper">
                  <button className={`selrangebutton ${this.state.selectRangeOpen ? 'br-btm' : ''}`} onClick={() => this.toggleRangeSlider()} style={{ lineHeight: 1, fontSize: 'inherit' }}>
                    <i className={`far fa-calendar-alt`}></i> {this.props.range ? `${moment(this.props.range.start).format("MMM YYYY")} - ${moment(this.props.range.end).format("MMM YYYY")}` : ''} {this.state.selectRangeOpen ? <i className='far fa-chevron-up'></i> : <i className='far fa-chevron-down'></i>}
                  </button>
              </div>
            </div>
            <Collapse isOpen={this.state.selectRangeOpen} className="selRangeCollapseWrapper">
              {
                Object.keys(this.props.dateRangeSelected).length > 0 &&
                <div className="row selRangeDateWrapper">
                  <RangeSlider
                    dateRangeHandler={this.props.dateRangeHandler}
                    dateRangeSelected={this.props.dateRangeSelected}
                    ref={this.props.inputRef}
                    query={this.props.query}
                  />
                </div>
              }
              </Collapse>
          </Fragment>
        }
      </div>



      </Fragment>
    )
  }
}


class ToolbarFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false
    };

    this.toggle = this.toggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  onMouseEnter() {
    this.setState({ dropdownOpen: true });
  }

  onMouseLeave() {
    this.setState({ dropdownOpen: false });
  }

  render() {
    const { filtersActive, filterAttrs } = this.props;
    const filterActiveLen = Object.keys(filtersActive);
    const filterValue = (filterActiveLen.length &&  filterActiveLen.includes(filterAttrs)) ? true : false;
    return (
      <Dropdown
        direction="right"
        isOpen={this.state.dropdownOpen}
        onMouseOver={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        toggle={() => {}}
        className={filterValue ? "select-col" : ""}
      >
        <DropdownToggle
          caret={false}
          className="nav-link dropdown-button"
          color="link"
        >{this.props.filterAttrs}</DropdownToggle>
        <ToolbarFilterOptions {...this.props} />
      </Dropdown>
    )
  }
}

const ToolbarFilterOptions = (props) => {
  return (
    <DropdownMenu className="rounded-0 second-level-dropdown">
      <div className="dropdown-wrapper">
        { props.filterValues.map((v, i) =>
            <DropdownItem
              key={i}
              onClick={() => {
                props.filterChange(props.filterAttrs, i);
                props.closeFilter(true);
              }}>
              <CustomInput id={props.filterAttrs+i} type="checkbox" label={v.name} defaultChecked={v.selected} />
            </DropdownItem>) }
      </div>
    </DropdownMenu>
  )
  //{ props.filterValues.map((v, i) => <DropdownItem active={v.selected} onClick={() => props.filterChange(props.filterAttrs, i)}>{v.name}</DropdownItem>) }
}

const ToolbarFilterSelected = (props) => {
  var attrs = Object.keys(props.filterAttrs)
                .map(i => props.filterAttrs[i])
                .reduce((acc, v) => { acc.push(...v); return acc; }, [])
  if (attrs.length === 0)
    return null;

  return (
    <div className="h5 text-muted">
      <i className="far fa-fw fa-filter"></i>&nbsp;
      { attrs.map((v, i) => <span key={i} className="badge badge-primary mr-1">{v}</span>) }
      &nbsp;<i className="far fa-fw fa-sync" title="Reset filters" onClick={props.resetFilters}></i>
    </div>
  )
}

import React from 'react';
import { useState } from 'react';
import { Row, Col, Typography, Button } from 'antd';
import moment from 'moment';

import { numFmt, getImageOrFallback, convertWordToCapitalizeCase, convertToFloatvalues } from 'Utils'
import { AddMoneyStatusMapping, cn, sponsorLogo } from 'DataSet'
import { MoreButton, KeyStats3, TruncatedNameSponsor, transactionsWithScrollBar, NoData } from './Common'
import {  get } from 'lodash';

import ConfirmModal from 'layouts/WebDashboard/Elements/Confirm'
import { Loading } from './Common';

const classNames = require('classnames');
const { Text } = Typography;

export const Transactions = React.memo(({ title, transactionBtnTitle, transactionType, changeTransactionType, loadMoreAction, items, profile, expand = false, cancelRequestForDeposits, loading }) => {
  const [showConfirmationPopup, setConfirmationPopupState] = useState(false);
  const [userMessageText, setUserMessageText] = useState('');
  const [cancelRequestProps, setCancelRequestProps] = useState({
    userInput: true,
    modalHeading: 'Are you sure?',
    modalBtn1: 'Yes',
    modalBtn2: 'No',
    classType: 'rejected',
    userInputArea: '',
    idToSend: '',
    confirmModalAction: 'cancelTransaction',
  });

  const more = items.length > 3;
  const _items = items; // items.slice(0, expand ? 30 : 3);
  const orders = (profile.profile) ? get(profile.profile, 'apex.orders') : [];
  const deposits = (profile.profile) ? get(profile.profile, 'apex.deposits') : [];
  const tradeRequest = (profile.profile) ? get(profile.profile, 'apex.trade_request') : [];

  const cancelRequestAction = (props) => {
    if (props) {
      // console.log('==== Transactions ====');
      // console.log(props);
      if (typeof props.showConfirmation !== 'undefined') {
        setCancelRequestProps({ ...cancelRequestProps, idToSend: props.idToSend, ...props });
        setConfirmationPopupState(true);
      }
      // console.log('======================');
    }
  }

  const handleModalAction = (props) => {
    // console.log('==== handleModalAction ====');
    // console.log(cancelRequestProps);
    // console.log("cancelRequestProps.idToSend ==>", cancelRequestProps.idToSend);
    if (cancelRequestForDeposits) {
      let reqData = {
        id: cancelRequestProps.idToSend,
        reason: cancelRequestProps.userInputArea || '',
      };
      // console.log(reqData);
      // console.log('======================');
      setConfirmationPopupState(false);
      cancelRequestForDeposits(reqData, cancelRequestProps.confirmModalAction);
    }
  }

  const userInputMessageTextChange = (props) => {
    const value = props.target.value;
    setUserMessageText(value)
  }

  return (
    <React.Fragment>
      {showConfirmationPopup && (
        <ConfirmModal
          userInput={cancelRequestProps.userInput}
          heading={cancelRequestProps.modalHeading}
          type={cancelRequestProps.classType}
          btn1={cancelRequestProps.modalBtn1}
          btn2={cancelRequestProps.modalBtn2}
          placeholder={cancelRequestProps.placeholder}
          userInputArea={userMessageText}
          onChange={userInputMessageTextChange}
          closeModal={() => setConfirmationPopupState(false)}
          handleModalAction={handleModalAction}
        />
      )}
      <div className="d-flex align-items-center justify-content-between card-title mb-1">
        <h5 className="mb-0">{title}</h5>
        <button type="button" className="p-0 btn btn-link" style={{ lineHeight: 1 }} onClick={changeTransactionType}>{transactionBtnTitle}</button>
      </div>
      <Row className="px-3 pb-0 accounts-summary-holdings-card border-0" style={{ margin: 0 }}>
        {
          (transactionType === 'bank') &&
            <>
            <Col span={4} className="holdings-list-head">
              Amount
            </Col>
            <Col span={6} className="holdings-list-head text-center" style={{ padding: '0 4px' }}>
              Status
            </Col>
            <Col span={5} className="holdings-list-head text-center" style={{ padding: '0 4px' }}>
              Transaction
            </Col>
            <Col span={5} className="holdings-list-head text-center" style={{ padding: '0 4px' }}>
              Date
            </Col>
            <Col span={4} className="holdings-list-head" style={{ padding: '0 4px' }}></Col>
          </>
        }
        {(transactionType !== 'bank') && (
          <>
            <Col span={4} className="holdings-list-head">
              Ticker
            </Col>
            <Col span={5} className="holdings-list-head" style={{ padding: '0 4px' }}>
              Security Name
            </Col>
            <Col span={6} className="holdings-list-head text-center" style={{ padding: '0 4px' }}>
              Transaction/Status
            </Col>
            {/* <Col span={5} className="holdings-list-head text-center" style={{ padding: '0 4px' }}>
              Transaction
            </Col> */}
            <Col span={5} className="holdings-list-head" style={{ padding: '0 4px', textAlign: 'right' }}>
              Details
            </Col>
          </>
        )}
      </Row>
      {loading
        ? (<Loading height='100%' />)
        : (
          <TransactionsItemListWithScrollBar
            items={_items}
            orders={orders}
            deposits={deposits}
            tradeRequest={tradeRequest}
            transactionType={transactionType}
            cancelRequestAction={cancelRequestAction}
          /> 
        )
      }
    </React.Fragment>
  )
})

const TransactionsItemList = ({ items, orders, deposits, tradeRequest, transactionType, cancelRequestAction }) => {
  // console.log("TransactionsItemList count =>", count);
  // let tradeAndOrders = [];
  // if(transactionType === 'security'){
  //   if(tradeRequest && tradeRequest.length > 0){
  //     tradeRequest = tradeRequest.map(el=> { el['transactionType'] = 'Trade'; return el })
  //     tradeAndOrders = tradeRequest;
  //   } 
  //   if(orders && orders.length > 0){
  //     orders = orders.map(el=> { el['transactionType'] = 'Order'; return el })
  //     tradeAndOrders = tradeAndOrders.concat(orders);
  //   }
  //   tradeAndOrders.sort((a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf());
  // }
  const count = (transactionType === 'security') ? (orders && orders.length) : (deposits && deposits.length)

  if (count <= 0 || !count) return <NoData height='225px' />;

  return (
    <Row style={ transactionType !== 'bank' ? { marginBottom: 75 } : {}}>
      <Col span={24}>
        {(orders.length > 0 && transactionType !== 'bank') && (
            <>
              {orders && orders.map((e, i) => <TransactionsItem transactionType={e.transactionType} orderStatus={e.status} key={e.id+'-'+i} item={e} last={i === count-1} cancelRequestAction={cancelRequestAction} />)}
            </>
          )}

        {/* {(transactionType === 'security' && tradeRequest && tradeRequest.length > 0) && (
          <>
            {tradeRequest && tradeRequest.map((e, i) => <TransactionsItem transactionType="Trade" orderStatus={e.status} key={e.id+'-'+i} item={e} last={i === count-1} cancelRequestAction={cancelRequestAction} />)}
          </>
        )} */}

        {/* {(transactionType === 'security' && orders && orders.length > 0) && (
          <>
            {orders && orders.map((e, i) => <TransactionsItem transactionType="Order" orderStatus={e.status} key={e.id+'-'+i} item={e} last={i === count-1} cancelRequestAction={cancelRequestAction} />)}
          </>
        )} */}

        {(transactionType === 'bank' && deposits.length > 0) && (
          <>
            {deposits && deposits.map((e, i) => <TransactionsBankItem transactionType="Bank" key={e.id+'-'+i} item={e} last={i === count-1} cancelRequestAction={cancelRequestAction} />)}
          </>
        )}
      </Col>
    </Row>
  )
}
const TransactionsItemListWithScrollBar = transactionsWithScrollBar(TransactionsItemList)

const TransactionsItem = ({ item, last, orderStatus, transactionType, cancelRequestAction }) => {
  const [imageValidPath, sponsorLogoIsValid] = useState(false);

  const checkSponsorLogo = (card) => {
    // console.log(card);
    if (cn(card, 'Sponsor')) {
      getImageOrFallback(
        sponsorLogo(cn(card, 'Sponsor'))
      ).then(result => {
        if (result) {
          sponsorLogoIsValid(true)
        } else {
          sponsorLogoIsValid(false)
        }
      });
    }
  }

  const cancelRequest = (item) => {
      // console.log(item);
      // if ((item.status === 'FundsPosted' || item.status === 'Postponed') && cancelRequestAction) {
      if (cancelRequestAction) {
        const reqObj = {
          showConfirmation: true,
          userInput: false,
          modalHeading: 'Are you sure?',
          modalBtn1: 'Yes',
          modalBtn2: 'No',
          classType: 'rejected',
          idToSend: item.id,
          userInputArea: '',
          confirmModalAction: 'cancelSecurityTransaction',
        };
        cancelRequestAction(reqObj);
      } else {
        console.log('-- Sorry cannot process --', item.status);
      }
  }



  checkSponsorLogo(item);
  // useEffect(() => { checkSponsorLogo(item); }, [item]);

  //  const date = moment(item.transaction_date).format('MM/DD/YYYY');
  const customNumberFormat = (number) => {
    return number === 0 ? 0 : numFmt(number)
  } 
  const isBasketTrade = (item.vehicle === "BASKET");
  const date = moment(item.created_at).format('MM/DD/YYYY');
  const quantity = !isNaN(item.quantity) ? parseFloat(item.quantity) : item.quantity;
  const _status = AddMoneyStatusMapping(orderStatus) ? AddMoneyStatusMapping(orderStatus).status : orderStatus;
  const isPriceValue = item.option === "Value" && _status !== "Complete" ? true : false;
  const postFix = (item.quantity > 1 ? 'Units' : 'Unit');
  const unit = `${isPriceValue ? "$" : ""}${customNumberFormat(quantity, 2)} ${isPriceValue ?  "" : postFix}`;
  const unitTitle =  isBasketTrade ? `$${customNumberFormat(quantity, 2)}` : `${isPriceValue ? "$" : ""}${convertToFloatvalues(quantity)} ${isPriceValue ?  "" : postFix}`;
  
  const weightToBe = Number(item.quantity) * Number(item.analysis_price)

  // console.log('imageValidPath ==>', imageValidPath, item.ticker, item.sponsor, orderStatus);

  if (date !== 'Invalid date') {
    let detailsPrice = (!isNaN(item.transaction_price) && item.transaction_price !== 'NaN' && item.transaction_price !== '') ? customNumberFormat(Number(item.transaction_price)) : ((!isNaN(item.analysis_price) && item.analysis_price !== 'NaN' && item.analysis_price !== '') ? customNumberFormat(Number(item.analysis_price)) : '');
    const unitsOrPrice = detailsPrice !== '' ? isPriceValue ? '@ '+ detailsPrice + " " + postFix :  '@ $'+ detailsPrice : ""
    let itemQuantityText = isBasketTrade ? `$${customNumberFormat(quantity, 2)}` :  (transactionType === 'Order' ? `${unit} ${unitsOrPrice}` : `${unit} ${unitsOrPrice}`)
    // let _status = AddMoneyStatusMapping(orderStatus) ? AddMoneyStatusMapping(orderStatus).status : orderStatus;
    return (
      <Row className="accounts-summary-holdings-card">
        <Col span={4} className="ticker-block pl-0">
          <h5 className="mb-0 primary-color">{item.ticker_short || item.ticker}</h5>
          {/*weightToBe && <h3 className="mb-0 holdings-weight-font-size">${ numFmt(weightToBe)}</h3>*/}
        </Col>
        <Col span={5} className="name-block">
          <TruncatedNameSponsor imageValidPath={imageValidPath} name={item.short_name} sponsor={item.sponsor} lines={1} style={{ width: '100%' }} />
        </Col>
        <Col span={6} className="ticker-block">
          <TextField
            fieldProps={
              {
                title: convertWordToCapitalizeCase(_status),
                ellipsis: {
                  tooltip: convertWordToCapitalizeCase(_status),
                }
              }
            }
            style={{ width: '100%', textAlign: 'center' }}
          >
          <TextField
            fieldProps={
              {
                title: convertWordToCapitalizeCase(item.side),
                ellipsis: {
                  tooltip: convertWordToCapitalizeCase(item.side)
                }
              }
            }
            style={{ width: '100%', textAlign: 'center' }}
          >
            <h5 className="font-weight-normal m-0 ant-typography-ellipsis-single-line text-center" style={{ lineHeight: 'normal' }} title={convertWordToCapitalizeCase(item.side)}>{convertWordToCapitalizeCase(item.side) || '--'}</h5>
          </TextField>
            <h5 className="font-weight-normal m-0 ant-typography-ellipsis-single-line text-center" title={convertWordToCapitalizeCase(_status)}>{convertWordToCapitalizeCase(_status)}</h5>
          </TextField>
        </Col>
        {/* <Col span={5} className="ticker-block">
          <TextField
            fieldProps={
              {
                title: convertWordToCapitalizeCase(item.side),
                ellipsis: {
                  tooltip: convertWordToCapitalizeCase(item.side)
                }
              }
            }
            style={{ width: '100%', textAlign: 'center' }}
          >
            <h5 className="font-weight-normal m-0 ant-typography-ellipsis-single-line text-center" style={{ lineHeight: 'normal' }} title={convertWordToCapitalizeCase(item.side)}>{convertWordToCapitalizeCase(item.side) || '--'}</h5>
          </TextField>
        </Col> */}
        <Col span={5} className="d-flex flex-column justify-content-end align-items-end time-Unit">
          <div title={unitTitle}>{itemQuantityText}</div>
          <div>{date}</div>
        </Col>
        <Col span={4} className="cta-block">
          {convertWordToCapitalizeCase(_status)=== 'Queued' && !isBasketTrade ? (
            <Button
              outline
              className="mb-0 py-1"
              onClick={() => cancelRequest(item)}
              style={{ height: 'auto' }}
            >
              Cancel Request
            </Button>
          ) : (
            <Button
              disabled
              className='mb-0 py-1 inactive'
              style={{ height: 'auto' }}
            >
              Cancel Request
            </Button>
          )}
        </Col>
      </Row>
    )
  } else return '';
}

const TransactionsBankItem = ({ item, last, cancelRequestAction }) => {
  //  const date = moment(item.transaction_date).format('MM/DD/YYYY');
  const date        = moment(item.created_at).format('MM/DD/YYYY');
  const weightToBe  = Number(item.amount)
  let isDeposited   = weightToBe > 0

  const cancelRequest = (item) => {
    // console.log(item);
    // if ((item.status === 'FundsPosted' || item.status === 'Postponed') && cancelRequestAction) {
    if (cancelRequestAction) {
      const reqObj = {
        showConfirmation: true,
        userInput: true,
        modalHeading: 'Are you sure?',
        modalBtn1: 'Yes',
        modalBtn2: 'No',
        classType: 'rejected',
        idToSend: item.id,
        userInputArea: '',
        confirmModalAction: 'cancelTransaction',
      };
      cancelRequestAction(reqObj);
    } else {
      console.log('-- Sorry cannot process --', item.status);
    }
  }

  if (date !== 'Invalid date') {
    return (
      <Row className="accounts-summary-holdings-card">
        <Col span={4} className="ticker-block pl-0">
          {isDeposited ? <h3 className="mb-0 holdings-weight-font-size positive-amount">{'+'}${ numFmt(weightToBe)}</h3> : <h3 className="mb-0 holdings-weight-font-size negative-amount">{'-' }${numFmt(Math.abs((weightToBe)))}</h3>}
        </Col>
        <Col span={6} className="ticker-block">
          <TextField
            fieldProps={
              {
                title: AddMoneyStatusMapping(item.status) ? AddMoneyStatusMapping(item.status).status : item.status,
                ellipsis: {
                  tooltip: AddMoneyStatusMapping(item.status) ? AddMoneyStatusMapping(item.status).status : item.status,
                }
              }
            }
            style={{ width: '100%', textAlign: 'center' }}
          >
            <h5 className="font-weight-normal m-0 ant-typography-ellipsis-single-line text-center" title={AddMoneyStatusMapping(item.status) ? AddMoneyStatusMapping(item.status).status : item.status}>{AddMoneyStatusMapping(item.status) ? AddMoneyStatusMapping(item.status).status : item.status}</h5>
          </TextField>
        </Col>
        <Col span={5} className="ticker-block">
          <TextField
            fieldProps={
              {
                title: (isDeposited) ? 'Deposit' : 'Withdrawal',
                ellipsis: {
                  tooltip: (isDeposited) ? 'Deposit' : 'Withdrawal',
                }
              }
            }
            style={{ width: '100%', textAlign: 'center' }}
          >
            <h5 className="font-weight-normal m-0 ant-typography-ellipsis-single-line text-center" title={(isDeposited) ? 'Deposit' : 'Withdrawal'}>{(isDeposited) ? 'Deposit' : 'Withdrawal'}</h5>
          </TextField>
        </Col>
        <Col span={5} className="ticker-block">
          <TextField
            fieldProps={
              {
                title: date,
                ellipsis: {
                  tooltip: date,
                }
              }
            }
            style={{ width: '100%', textAlign: 'center' }}
          >
            <h5 className="font-weight-normal m-0 ant-typography-ellipsis-single-line text-center" title={date}>{date}</h5>
          </TextField>
        </Col>
        <Col span={4} className="cta-block">
          {(item.status === 'FundsPosted' || item.status === 'Postponed') ? (
            <Button
              outline
              className="mb-0 py-1"
              onClick={() => cancelRequest(item)}
              style={{ height: 'auto' }}
            >
              Cancel Request
            </Button>
          ) : (
            <Button
              disabled
              className='mb-0 py-1 inactive'
              style={{ height: 'auto' }}
            >
              Cancel Request
            </Button>
          )}
        </Col>
      </Row>
    )
  } else return '';
}

// <Button
//   outline={!isDeposited}
//   disabled={isDeposited}
//   className={`mb-0 py-1 ${(isDeposited) ? 'inactive' : ''}`}
//   onClick={() => cancelRequest(item)}
//   style={{ height: 'auto' }}
// >
//   Cancel Request
// </Button>

const TextField = ({children, fieldProps = {}, ...props}) => {
  return <Text {...fieldProps} {...props} className="inner-attributes">{children}</Text>
}

import React from 'react';
import { connect } from 'react-redux';
// import { getPremiumFeatureDetails } from 'apis/test-drive';
import { createLoadingSelector } from 'selectors/loading-selector';
// import CustomAlert from 'components/CustomAlert';
// import PremiumNoticeModal from 'components/PremiumNoticeModal';
// import Spinner from 'layouts/WebDashboard/SearchResults/Elements/Spinner';
import { ModalFooter } from 'reactstrap';
import Footer from './Footer';
import ItemList from './ItemList';

// var classNames = require('classnames');

class CombineTab extends React.Component {
  state = {
    opts: {},
    premiumModal: false,
    reportLoader: false,
    buttonDisabled: true,
    premiumFeature: 'false',
    notify: false,
    tradingAccountExist: false,
    uploadPortfolio: this.props.myportfolio ? this.props.myportfolio : []
  }

  componentDidMount() {
    this.props.actionHandler('Combine', 'all');
    // console.log(this.props.profile);
    let accountNumber = null;
    if (this.props.profile.profile.apex) {
      if (this.props.profile.profile.apex.account) {
        // console.log('== this.props.profile.profile.apex.account ==> ', this.props.profile.profile.apex.account);
        if (this.props.profile.profile.apex.account[0].account_no) {
          accountNumber = this.props.profile.profile.apex.account[0].account_no;
          // console.log('== this.props.profile.profile.apex.account ==> ', this.props.profile.profile.apex.account[0].account_no);
          this.setState({
            tradingAccountExist: true
          })
        }
      }
    }
  }

  optSelHandler = (opts) => {
    this.setState({ opts });
  }

  shareCombineState = (state) => {
    // console.log(state);
    const { shareCombineState } = this.props;
    if (shareCombineState) {
      shareCombineState(state);
    }
  }

  loadingStatus = (status) => {
    this.setState({
      buttonDisabled: status,
    });
  }

  printReport = () => {
    const { printReport } = this.props;
    if (printReport) {
      printReport(this.state.opts);
    }
  }

  render() {
    const { buttonDisabled } = this.state;
    const { reportLoader } = this.props;
    // console.log(' === impactLoading ===> ', impactLoading);
    console.log('CombineTab')
    return (
      <div className="sfm-combine-tab-wrapper position-relative">
        <div className="modal-body-ssf">
          <ItemList {...this.props} loadingStatus={this.loadingStatus} shareCombineState={this.shareCombineState} optSelHandler={this.optSelHandler} tradingAccountExist={this.state.tradingAccountExist} uploadPortfolio={this.state.uploadPortfolio} />
        </div>
        { !this.props.mobileDevice &&
        <ModalFooter className="py-2 sfm-modal-footer">
          <Footer
            {...this.props}
            accountsPage={typeof this.props.accountsPage !== 'undefined' && this.props.accountsPage}
            disabled={reportLoader || buttonDisabled}
            printReport={this.printReport}
            opts={this.state.opts}
          />
        </ModalFooter>
        }
      </div>
    )
  }
}

const mapStateToProps = ({ sfm, profile, loading }) => ({
  trackFundsCompareOrder: sfm.trackFundsCompareOrder,
  profile: profile,
  myportfolio: sfm.myportfolio,
  profileLoading: createLoadingSelector(['PROFILE'])({ loading }),
  impactLoading: createLoadingSelector(['GET_PORTFOLIO_DETAILS_FOR_SFM', 'SEARCH_QUERY'])({ loading }),
})

export default connect(
  mapStateToProps
)(CombineTab)

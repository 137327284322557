import React from 'react';
import { connect } from 'react-redux';
import { newQuery, refreshFundsState, dymLogs, searchAnalyticsAPI } from '../actions/funds';
import {
  addSFMItemTickersRequest,
  deleteAllSFMItemTickersRequest,
  removeSFMItemTickersRequest,
  setSFMState
} from '../actions/sfm'
import { clearFundsApiLoadPercent, setGlobalState } from 'actions/global'
// import { toggleModal, getSelectedFunds } from '../sagas/sfm';
//import update from 'immutability-helper';

import moment from 'moment';
import _, { isEmpty, uniqBy, reverse } from 'lodash'
import Survey from 'layouts/WebDashboard/Survey';

import { arrayToHash, uniqValues, getNest, getNestDefault, findAndUpdateArray, searchUrlV3, clearWindowUtmSession } from '../Utils'
import { FilterAttrData, SortAttrs, DefaultSortAttr, DateRange, cn, CN, MaxResult, Universe, BrokersComms, SeoLinks, REDIRECT_DOMAIN_PATH } from '../DataSet';
import TopSection from './TopSection';
import TifinWealthMessage from 'components/TifinWealthMessage'
import Spinner from 'layouts/WebDashboard/SearchResults/Elements/Spinner';
import AccountConfirmed from 'layouts/WebDashboard/SearchResults/Elements/Registrations/AccountConfirmed';
// import TermsNConditions from 'layouts/WebDashboard/SearchResults/Elements/Registrations/TermsNConditions';
import Toolbar from './Toolbar';
import ItemList from './ItemList';
import CardListToolbar from './CardListToolbar';
import Overlay from './Overlay'
import NoResult from '../layouts/WebDashboard/SearchResults/Elements/NoResult'
import SearchCard from '../layouts/WebDashboard/SearchResultsV3/SearchCard'
import { fetchFunds, fetchFundsDetails, fetchCategories, fetchRealTimePrices, fetchReturnsDataV1, fetchChartsV1 } from 'apis/funds'
import { SectorColors } from '../data/Colors';
import { addEventToAnalytics, searchUrl, formatTickerForView } from 'Utils';
import { RegionChartMapping, SectorChartMapping } from 'ChartData';
import { searchQueryRequest, setFundsState, setUniverse } from 'actions/discovery'
import { getQueryItems } from '../layouts/utils'
import { createLoadingSelector } from 'selectors/loading-selector'
let iconClickedLoginPrompt = false;
class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateRange: { start: moment(DateRange.start), end: moment(DateRange.end) },
      dateRangeSelected: {},
      // data: props.fundsData,
      selectedCards: props.items,
      // fundsApiCallPercent: 0,
      setCookie: false,
      callFundsAPI: false,
    }
    this.defaultYears = 3;
    this.sortHandler = this.sortHandler.bind(this);
    this.filterHandler = this.filterHandler.bind(this);
    this.dateRangeHandler = this.dateRangeHandler.bind(this);
    this.viewHandler = this.viewHandler.bind(this);
    this.selectHandler = this.selectHandler.bind(this);
    // this.fetchFunds = this.fetchFunds.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
  }

  async componentDidMount() {
    // Download data
    //window.sessionStorage.removeItem('search_type');
    console.log('Main Did mount', moment().format('h:mm:ss:SS a'));

    const id = this.props.match.params.id;
    const { history: { location } } = this.props;
    let params = new URLSearchParams(location.search);
    if (params.get('search_type')) {
      window.sessionStorage.setItem('search_type', params.get('search_type'));
    }
    //TODO getTrendingFeeds
    const { getTrendingFeeds } = this.props;
    let requestPayload = {}, _q = '';
    if (id && id != '') {
      const index = SeoLinks.findIndex(x => x.name.toLowerCase() == id.toLowerCase());
      if (index >= 0) {
        _q = SeoLinks[index].query;
        // this.setState({ fundsApiCallPercent: 25 });
        requestPayload = {
          query: _q || "",
        };
      } else {
        window.location.href = REDIRECT_DOMAIN_PATH;
        return;
      }
    } else {
      _q = this.getQueryItems();
      // this.setState({ fundsApiCallPercent: 25 });
      // requestPayload = {
      //   query: _q || "",
      // };
      if (window.sessionStorage.getItem('search_type')) {
        this.queryHandler(_q, window.sessionStorage.getItem('search_type'))
      } else {
        this.queryHandler(_q, 'search_bar');
      }
    }


    // const data = await this.fetchData(requestPayload);
    // set reducer's state

    // const { setFundsState } = this.props;
    // setFundsState({
    //   fundsResponse: data,
    // });
    // if (!data.funds.length) {
    //   // console.log('NO FUNDS FOUND!');
    //   setFundsState({
    //     highlightText: {
    //       feesHighLightEnable: false,
    //       returnHighLightEnable: false,
    //       riskHighLightEnable: false,
    //     },
    //   });
    // }

    //TODO date range from props tp state
    // this.setState({
    //   dateRangeSelected: { start: data.range.start, end: data.range.end },
    // }, () => {
    //   this.sliderElement = null;
    //   this.setDateRange(data.range);
    // });
    //
    // setFundsState({
    //   data: data.funds,
    //   logId: data.log_id,
    //   didYouMean: data.didYouMean,
    //   activeRegion: '',
    //   catData: data.categories,
    //   dateRangeSelected: { start: data.range.start, end: data.range.end },
    //   filterAttrData: data.filterAttrData,
    //   related: data.related,
    //   correctedQuery: data.corrected_query,
    //   messages: data.messages,
    //   query: _q,
    //   view: data.relevantViewNames,
    //   chartData: data.chartData,
    //   loading: false,
    //   outPerfReturnsData: data.outPerfReturnsData,
    // });
    // this.setState({ fundsApiCallPercent: 100 });

    if (this.props.useremail && this.props.firstName && this.props.lastName) {
      if (window.heap) {
        window.heap.identify(this.props.useremail);
        window.heap.addUserProperties({ 'First Name': this.props.firstName, 'Last Name': this.props.lastName });
      }
      window.addkrakenUser && typeof window.addkrakenUser === "function" && window.addkrakenUser({ email: this.props.useremail, firstName: this.props.firstName, lastName: this.props.lastName });
    }

    //this.executeGlobalSurvey();
  }

  getQueryItems() {
    let params, qury;
    if (this.props.location.search) {
      params = new URLSearchParams(this.props.location.search);
      qury = params.get('query');
    }
    return this.props.query || qury || window.sessionStorage.getItem('query')
  }

  componentWillUnmount() {
    if (this.priceTimer) {
      clearInterval(this.priceTimer);
      this.priceTimer = null;
    }
    document.body.style.paddingTop = "";
    document.body.classList.remove('ht0');
    const { refreshFundsState } = this.props;
    refreshFundsState();
  }

  componentDidUpdate(prevProps) {
    if (document.getElementById("top-bar") && document.getElementById("overlay-wrapper")) {
      const child = document.getElementById("overlay-wrapper");
      const topSectionWrapper = document.getElementById("top-bar");
      child.style.marginTop = topSectionWrapper.offsetHeight + "px";
    }
    if (
      this.props.loadPercent == 100 &&
      this.props.loadPercent != prevProps.loadPercent &&
      this.props.fundsData.length > 0
    ) {
      addEventToAnalytics(
        'SEARCH_RESULT_DISPLAYED',
        'SEARCH_RESULT_DISPLAYED',
        'SEARCH_RESULT_DISPLAYED',
        { query: this.props.query },
        true
      )
    }
    if (!this.props.loggedIn && !iconClickedLoginPrompt) {
      this.executeGlobalSurvey();
    } else {
      iconClickedLoginPrompt = false
    }
  }

  componentWillReceiveProps(newProps) {
    // this.checkProps(newProps);
    if (newProps.fundsData) {
      this.setState({
        data: newProps.fundsData,
      });
    }
    if (typeof newProps.selectedCards !== undefined) {
      this.setState({
        selectedCards: newProps.selectedCards,
      });
    }
  }

  executeGlobalSurvey = () => {
    const { setGlobalState } = this.props;
    const plusCounter = localStorage.getItem('plusCounter');
    iconClickedLoginPrompt = false
    if (plusCounter !== 'NaN' && plusCounter && localStorage.getItem('counter') === plusCounter) {
      setGlobalState({
        surveyModal: true,
      });
    } else {
      setGlobalState({
        surveyModal: false,
      });
    }
  }

  surveyModalOpen = () => {
    const { setGlobalState } = this.props;
    iconClickedLoginPrompt = true;
    if (setGlobalState) {
      setGlobalState({
        surveyModal: true,
      });
    }
  }

  // checkProps = (props) => {
  //   if (props.selectedCards && props.selectedCards.length && props.fundsData && props.fundsData.length) {
  //     const { selectedCards, fundsData } = props;
  //     // console.log(updatedArray);
  //   }
  // }

  queryHandler = (query, search_type) => {
    const { query: _query, setFundsState } = this.props;
    if (_query !== query) {
      setFundsState({
        requestPayloadFilters: {
          filterKeys: [],
          filterValues: [],
        },
        requestPayloadSort: {
          name: '',
          value: '',
        },
      });
    }
    if (query) {
      if (search_type === 'fund_category') {
        query = `Funds Categorized as ${query}`;
      }
      if (!search_type && window.sessionStorage.getItem('search_type')) {
        search_type = window.sessionStorage.getItem('search_type');
        window.sessionStorage.removeItem('search_type');
      }
      if (search_type === 'trending_searches')
        addEventToAnalytics('Trending Searches', 'Trending Searches', 'TRENDING_SEARCHES', { clicked_query: query }, true);

      const { searchQueryRequest, setFundsState, clearFundsApiLoadPercent } = this.props;
      clearFundsApiLoadPercent();
      window.sessionStorage.setItem('query', query);

      setFundsState({
        actions: true,
        currentPage: 1,
      });
      this.checkRoutes(query);
      searchQueryRequest({
        query,
        search_type,
      });
    }
  }

  checkRoutes = (query, clearUTMSession = false) => {
    // const { location, isTermsAgreed, loggedIn, match } = this.props;
    // const { pathname } = location;
    // const query = window.sessionStorage.getItem('query');
    // if (loggedIn && isTermsAgreed === 0 && query) return;
    // if (!match.params.id || match.params.id == '') this.props.history.push(searchUrlV3(query));
    const { history } = this.props;
    if (query && history) {
      if (clearUTMSession) {
        clearWindowUtmSession();
      }
      history.push(searchUrlV3(query));
    }
  }

  // queryHandler = (query) => {
  //   const { loggedIn } = this.props;
  //   if (query) {
  //     let requestPayload = {
  //       query,
  //     };
  //     this.fetchFunds(requestPayload);
  //   }
  //   addEventToAnalytics('Search Bar','Search Bar','SEARCH_BAR',{ query, loggedIn });
  // }

  didYouMeanSearchQuery = (query) => {
    if (query) {
      const old_query = window.sessionStorage.getItem('query');
      if (old_query) {
        window.sessionStorage.setItem('query', query);
        let requestPayload = {
          query,
          search_type: 'did_you_mean',
          // didYouMean: old_query,
          // universe:  Universe,
        };
        const { dymLogs, logId, queryUUID } = this.props;
        console.log(queryUUID);
        dymLogs({
          originalQuery: old_query,
          dymQueryClicked: query,
          // logId: logId,
          queryUUID: queryUUID,
        })
        // this.fetchFunds(requestPayload);
        this.queryHandler(query, 'did_you_mean')
      }
    }
  }

  // async fetchFunds(requestP) {
  //   console.log('fetching')
  //   // this.setState({ loading: true, query });
  //   if (requestP.query) {
  //     const { query } = requestP;
  //     // this.props.history.push('/securities?query=' + query)
  //     this.setState({ fundsApiCallPercent: 25 });
  //     const { setFundsState, newQuery, selectedCards } = this.props;
  //     setFundsState({
  //       loading: true,
  //       query,
  //     });
  //     newQuery(query);
  //
  //     let data = await this.fetchData(requestP);
  //     console.log(data)
  //     debugger
  //
  //     data.funds.forEach(e => {
  //       let i = selectedCards.findIndex(s => cn(s, 'Ticker') === cn(e, 'Ticker'));
  //       if (i >= 0) {
  //         e._selected = true;
  //         this.state.selectedCards[i] = e;
  //       }
  //     });
  //
  //     this.setState({
  //       dateRangeSelected: { start: data.range.start, end: data.range.end },
  //     }, () => { this.sliderElement = null; this.setDateRange(data.range); });
  //
  //     // set funds reducer's state
  //     console.log(data)
  //     setFundsState({
  //       data: data.funds,
  //       logId: data.log_id,
  //       queryUUID: data.query_uuid,
  //       didYouMean: data.didYouMean,
  //       catData: data.categories,  //
  //       related: data.related,
  //       dateRangeSelected: { start: data.range.start, end: data.range.end },
  //       correctedQuery: data.corrected_query,
  //       messages: data.messages,
  //       sortAttr: DefaultSortAttr,
  //       filterAttrData: data.filterAttrData,  //
  //       sortDesc: false,
  //       filterAttrs: {},
  //       query,
  //       view: data.relevantViewNames,   //
  //       chartData: data.chartData,    //
  //       outPerfReturnsData: data.outPerfReturnsData, //
  //       firstPop: false,
  //       loading: false,
  //       selectedCards: this.state.selectedCards,
  //     });
  //     this.setState({ fundsApiCallPercent: 100 });
  //   }
  // }

  // async fetchData(requestP) {
  //   console.log('fetching')
  //   const { query } = requestP;
  //   // console.log('fetching data started', moment().format('h:mm:ss:SS a'));
  //   query && window.sessionStorage.setItem('query', query);
  //   if (Object.keys(this.props.match.params).length == 0) {
  //     // this.props.history.push('/securities?query=' + query)
  //     this.props.history.push(searchUrl(query))
  //   }
  //
  //   let requestPayload = {
  //     q: query,
  //     universe: Universe,
  //   };
  //
  //   if (requestP.didYouMean) {
  //     requestPayload = {
  //       q: query,
  //       did_you_mean: requestP.didYouMean,
  //       universe: Universe,
  //     };
  //   }
  //
  //   let _fundTickers = await fetchFunds(requestPayload);
  //   // console.log('found funds tickers', moment().format('h:mm:ss:SS a'));
  //   if (uniqValues(_fundTickers.data, 'ticker').length <= 0)
  //     return { funds: [], categories: [], range: { start: moment(DateRange.start), end: moment(DateRange.end) }, filterAttrData: {} };
  //
  //   let ticks = uniqValues(_fundTickers.data, 'ticker').join(',');
  //
  //   // console.log('3 apis calls initiated', moment().format('h:mm:ss:SS a'));
  //   let [_funds, returns, categories, charts] = await Promise.all([
  //     fetchFundsDetails(_fundTickers),
  //     fetchReturnsDataV1({ tickers: ticks }),
  //     fetchCategories({ tickers: ticks }),
  //     fetchChartsV1({ tickers: ticks }),
  //   ]);
  //
  //   // funds api call add event
  //
  //   this.setState({ fundsApiCallPercent: 50 });
  //
  //   this.setState({ callFundsAPI: true })
  //   const { log_id, query_uuid } = _fundTickers;
  //   const { related, corrected_query, messages } = _funds;
  //   let itemCardView = {};
  //   const { setFundsState } = this.props;
  //   let funds = [];
  //   if (_funds["funds"] && _funds["funds"].length) {
  //     funds = _funds["funds"].slice(0, MaxResult);
  //   }
  //
  //   if(_fundTickers.query_uuid) {
  //     window.sessionStorage.setItem('query_uuid', _fundTickers.query_uuid);
  //   }
  //   addEventToAnalytics('Search Api','Search Api','SEARCH_API',{ query: query,logId:false },true);
  //
  //   if (funds.length <= 0)
  //     return { funds: [], categories: [], range: _funds["date_range"], filterAttrData: {} };
  //
  //   const queryArr = _funds["query_var"];
  //   var highlightArr = [];
  //   let queryCategoryValue, chartData = [], highlightText = {}, outPerfReturnsData;
  //   // showCarousel = false, queryVarValue, dynamicQueryVar, queryVar;
  //
  //   // let relevantCharts = uniqBy((queryArr.filter(e => {
  //   //   if (e.active && e.view_name && e.view_name != "") {
  //   //     if (e.view_name != 'Highlight') {
  //   //       return e;
  //   //     }
  //   //     else
  //   //       highlightArr.push(e)
  //   //   }
  //   // }) || []).slice(0, 2), 'view_name');
  //   //
  //   // let relevantViewNames = [], jointNameStr = '', jointNameArray = [];
  //   // if (relevantCharts && relevantCharts.length > 0) {
  //   //   relevantCharts.forEach((r, i) => {
  //   //     let view = r.view_name;
  //   //     jointNameArray.push(view);
  //   //     if (view && view.includes('ticker')) {
  //   //       if (Object.prototype.toString.call(r.data) === '[object Array]') {
  //   //         view = view.replace("ticker", r.data[0]);
  //   //       }
  //   //       else if (typeof r.data === 'string') {
  //   //         view = view.replace("ticker", r.data);
  //   //       }
  //   //     }
  //   //     //push the individual view name
  //   //     relevantViewNames.push({ name: view, secondaryView: r.Secondary_view_name, originalName: [r.view_name] });
  //   //     relevantCharts[i].display_view_name = view;
  //   //     if (view == 'Return Quality') view = view + ' (' + r.Secondary_view_name + ')';
  //   //     i == 0 ? jointNameStr += view : jointNameStr += ' & ' + view;
  //   //     // secondaryViewArr.push(r.Secondary_view_name);
  //   //   });
  //   //   if (relevantViewNames.length > 1) relevantViewNames.push({ name: jointNameStr, secondaryView: '', originalName: jointNameArray });
  //   // }
  //   // else {
  //   //   relevantViewNames.push({ name: 'Assets', secondaryView: '', originalName: ['Assets'] });
  //   //   relevantCharts.push({ active: true, Static: true, view_name: 'Assets', Secondary_view_name: '', display_view_name: 'Assets' });
  //   // }
  //   // // this.setState({itemCardView: {view: relevantCharts[0].display_view_name, secondaryView: relevantCharts[0].Secondary_view_name}})
  //
  //   let relevantCharts = (queryArr.filter(e => {
  //     if (e.active && e.view_name && e.view_name != "") {
  //       if (e.view_name != 'Highlight') {
  //         return e;
  //       }
  //       else
  //       highlightArr.push(e)
  //     }
  //   }) || []);
  //
  //   // let relevantCharts = uniqBy(relevantCharts, 'view_name'); // }) || []).slice(0, 2), 'view_name');
  //
  //   let relevantViewNames = [], jointNameStr = '', jointNameArray = [];
  //   if (relevantCharts && relevantCharts.length > 0) {
  //     let secondaryViewArr = [];
  //     relevantCharts.forEach((r, i) => {
  //       let view = r.view_name;
  //       jointNameArray.push(view);
  //
  //       // NOTE: pick only dynamic chart
  //       if (view === 'Regions') {
  //         let displayText = RegionChartMapping.filter(
  //           e => e.chart_name === r.node && r.Static === true
  //         )
  //         if (displayText.length) {
  //           let toBeDisplayedView = '%\u00a0' + displayText[0].region_name;
  //           view = toBeDisplayedView;
  //         }
  //       } else if (view == 'Sectors') {
  //         let find = SectorChartMapping.filter(
  //           e => e.chart_name === r.node && r.Static === true
  //         )
  //         if (find.length > 0) {
  //           let toBeDisplayedView = '%\u00a0' + find[0].sector_name
  //           view = toBeDisplayedView
  //         }
  //       }
  //       else if (
  //         view == 'Top Holdings' &&
  //         r.data &&
  //         (r.variable.includes('holdings_') ||
  //         r.variable.include('neg_holdings_'))
  //       ) {
  //         let ticker = ''
  //         if (Object.prototype.toString.call(r.data) === '[object Array]') {
  //           ticker = r.data[0]
  //         } else if (typeof r.data === 'string') {
  //           ticker = r.data
  //         }
  //         if (ticker.length > 4) ticker = formatTickerForView(ticker)
  //         let toBeDisplayedView = '%\u00a0' + ticker
  //         view = toBeDisplayedView
  //         // return name;
  //       }
  //
  //       // NOTE: for Holdings mapping, pick 'view' from query_var.data
  //       if (view && view.includes('ticker')) {
  //         if (Object.prototype.toString.call(r.data) === '[object Array]') {
  //           view = view.replace("ticker", r.data[0]);
  //         }
  //         else if (typeof r.data === 'string') {
  //           view = view.replace("ticker", r.data);
  //         }
  //       }
  //
  //       //push the individual view name
  //       relevantViewNames.push({ name: view, secondaryView: r.Secondary_view_name, originalName: [r.view_name], variable: r.variable });
  //       relevantCharts[i].display_view_name = view;
  //       // console.log(r.variable);
  //       relevantCharts[i].variable = r.variable;
  //       if (view == 'Return Quality') view = view + ' (' + r.Secondary_view_name + ')';
  //       i == 0 ? jointNameStr += view : jointNameStr += ' && ' + view;
  //       // secondaryViewArr.push(r.Secondary_view_name);
  //     });
  //     if (relevantViewNames.length > 1) relevantViewNames.push({ name: jointNameStr, secondaryView: '', originalName: jointNameArray });
  //   }
  //   else {
  //     relevantViewNames.push({ name: 'Assets', secondaryView: '', originalName: ['Assets'] });
  //     relevantCharts.push({ active: true, Static: true, view_name: 'Assets', Secondary_view_name: '', display_view_name: 'Assets' });
  //   }
  //
  //   if (relevantViewNames && relevantViewNames.length > 0 && relevantViewNames[relevantViewNames.length - 1] && relevantViewNames[relevantViewNames.length - 1].name.includes('&')) {
  //     itemCardView = {
  //       view: relevantViewNames[0].name,
  //       secondaryView: relevantViewNames[0].secondaryView,
  //       mappingVariable: relevantViewNames[0].variable || '',
  //     };
  //   } else {
  //     itemCardView = {
  //       view: relevantCharts[0].display_view_name,
  //       secondaryView: relevantCharts[0].Secondary_view_name,
  //       mappingVariable: relevantCharts[0].variable || '',
  //     };
  //   }
  //
  //   setFundsState({
  //     itemCardView,
  //   });
  //
  //   if (relevantCharts.length > 0)
  //     chartData = relevantCharts;
  //   highlightText = {
  //     feesHighLightEnable: false,
  //     returnHighLightEnable: false,
  //     riskHighLightEnable: false,
  //   };
  //
  //   if (highlightArr.length) {
  //     let highlightPopup = [];
  //     const highlightText_local = highlightArr.map((item) => {
  //       if (typeof item.chart_type !== 'undefined' && item.chart_type.length && item.active) {
  //         if (item.chart_type == 'highlight_popup')
  //           highlightPopup.push(item);
  //         return item.chart_type.split('highlight_')[1];
  //       }
  //     });
  //
  //     if (highlightText_local.length) {
  //       highlightText_local.map((item) => {
  //         switch (item) {
  //           case 'fees':
  //             highlightText.feesHighLightEnable = true;
  //             break;
  //           case 'returns':
  //             highlightText.returnHighLightEnable = true;
  //             break;
  //           case 'risk':
  //             highlightText.riskHighLightEnable = true;
  //             break;
  //           case 'popup':
  //             highlightText.popupHighLightEnable = highlightPopup;
  //             break;
  //           default:
  //             highlightText = {
  //               feesHighLightEnable: false,
  //               returnHighLightEnable: false,
  //               riskHighLightEnable: false,
  //             };
  //         }
  //       });
  //       setFundsState({
  //         highlightText,
  //       });
  //     }
  //     else {
  //       setFundsState({
  //         highlightText: {
  //           feesHighLightEnable: false,
  //           returnHighLightEnable: false,
  //           riskHighLightEnable: false,
  //         },
  //       });
  //     }
  //   }
  //   else {
  //     setFundsState({
  //       highlightText: {
  //         feesHighLightEnable: false,
  //         returnHighLightEnable: false,
  //         riskHighLightEnable: false,
  //       },
  //     });
  //   }
  //
  //   // let commData = arrayToHash(comms["funds"], CN['Ticker']);
  //   let returnsData = arrayToHash(returns, CN['Ticker']);
  //   let chartsData = arrayToHash(charts, CN['Ticker']);
  //   // let assetData = arrayToHash(assetTypes, CN['Ticker']);
  //   // let esgScoreData = arrayToHash(esgScore["esg_score"], CN['Ticker']);
  //   // let diverseDataH = arrayToHash(diverseData, CN['Ticker']);
  //
  //   funds.forEach((e, index) => {
  //     funds[index].region = chartsData[cn(e, 'Ticker')].result.regions ? chartsData[cn(e, 'Ticker')].result.regions.length ? chartsData[cn(e, 'Ticker')].result.regions[0].region : [] : [];
  //     funds[index].returns = returnsData[e.ticker].returns;
  //     funds[index].returns = reverse(funds[index].returns);
  //     let r = getNest(['returns', 0], e);
  //     if (r) {
  //       e._start = r['d'];
  //       e._end = e.returns[e.returns.length - 1]['d'];
  //     }
  //     e.returns.forEach((item, index) => {
  //       e.returns[index].v = item.v / 100;
  //     });
  //     // e.brokers = getNest([cn(e, 'Ticker'), 'brokers'], commData);
  //     e.brokers = BrokersComms;
  //     e.broker = getNest([0], e.brokers);
  //
  //     //regions data manipulation -- adding others
  //     if (e.region.length > 0) {
  //       e.region.forEach((reg, i) => {
  //         let sum = 0;
  //         if (reg.n == 'Others') {
  //           reg.sub.forEach(child => {
  //             sum += child.v;
  //           })
  //           e.region[i].sub = [{ n: 'Others ', v: sum }];
  //         }
  //       });
  //     }
  //
  //     //calculate assetType chart data
  //     e.assetTypeData = chartsData[cn(e, 'Ticker')].result.asset_type[0] || []
  //
  //     //calculate theme score chart data
  //     e.themeScore = chartsData[cn(e, 'Ticker')].result.themes_new[cn(e, 'Ticker')];
  //
  //     //calculate risk adjusted return chart data
  //     e.riskAdjReturn = chartsData[cn(e, 'Ticker')].result.risk_adjusted[cn(e, 'Ticker')];
  //
  //     e.diverseData = chartsData[cn(e, 'Ticker')].result.diversification[0];
  //
  //     e.diverseData = chartsData[cn(e, 'Ticker')].result.diversification[0];
  //     // console.log(e.diverseData)
  //     //calculate holding chart data
  //     let _sum = 0.0;
  //     let holdingData = chartsData[cn(e, 'Ticker')].result.topten.funds.length ? chartsData[cn(e, 'Ticker')].result.topten.funds[0].holdings : []
  //     e.holdingData = holdingData
  //       .map((el, i) => {
  //         _sum += el.weight;
  //         return {
  //           name: el.name,
  //           value: el.weight,
  //           color: SectorColors[i % SectorColors.length]
  //         }
  //       });
  //     if (e.holdingData.length > 0 && _sum < 100) {
  //       let rem = 100 - _sum.toFixed(1);
  //       rem = parseFloat(rem.toFixed(1));
  //       e.holdingData.push({ name: 'Others', value: rem, color: '#ddd' });
  //     }
  //
  //     //add queryCategoryValue to fund card
  //     if (queryCategoryValue) e.queryCategoryValue = queryCategoryValue;
  //   });
  //
  //   categories["categories"].forEach(e => {
  //     let r = getNest(['returns', 0], e);
  //     if (r) {
  //       e._start = r['d'];
  //       e._end = e.returns[e.returns.length - 1]['d'];
  //     }
  //     e.returns.forEach((item, index) => {
  //       e.returns[index].v = item.v / 100;
  //     });
  //   });
  //
  //   let filterAttrData = FilterAttrData.reduce((acc, v) => ({ ...acc, [v.name]: uniqValues(funds, v.col) }), {});
  //   filterAttrData = Object.keys(filterAttrData).reduce((acc, item) => {
  //     acc[item] = filterAttrData[item].filter(x => (x != 0 || x != ''))
  //     return acc;
  //   }, {});
  //
  //   setTimeout(() => this.fetchPrice(ticks), 500);
  //   if (this.priceTimer) clearInterval(this.priceTimer);
  //   this.priceTimer = setInterval(() => this.fetchPrice(ticks), 61000);
  //
  //   // console.log('fundsDataMapping ended at ->', moment().format('h:mm:ss:SS a'));
  //   this.setState({ fundsApiCallPercent: 50 });
  //   // return { funds, categories: categories["categories"], range: _funds["date_range"], filterAttrData, queryCategory: queryCategory, dynamicQueryCategory: dynamicQueryCategory, queryCategoryValue:queryCategoryValue, related, corrected_query};
  //   // return { funds, categories: categories["categories"], range: _funds["date_range"], filterAttrData, queryCategory: queryCategory, dynamicQueryCategory: dynamicQueryCategory, queryCategoryValue:queryCategoryValue, secondaryViewName, chartData, related, corrected_query, showCarousel, dynamicQueryVar, queryVarValue, queryVar };
  //   return { funds, didYouMean: _fundTickers.didumean, categories: categories["categories"], range: _funds["date_range"], filterAttrData, relevantViewNames, chartData, related, corrected_query, outPerfReturnsData, messages, log_id, query_uuid };
  // }

  fetchPrice = (ticks) => {
    const { setFundsState, funds } = this.props;
    const { data } = funds;
    // const funds = this.state.data;
    // console.log('fetch price api call', moment().format('h:mm:ss:SS a'));
    fetchRealTimePrices({ tickers: ticks })
      .then(d => {
        // console.log('fetch price api response at', moment().format('h:mm:ss:SS a'));
        let priceData = arrayToHash(d["funds"], CN['Ticker']);
        data.forEach(e => {
          if (e[CN['Vehicle']] == 'ETF' || e[CN['Vehicle']] == 'ETN')
            e[CN['Nav Price']] = getNestDefault([cn(e, 'Ticker'), 'price'], priceData, cn(e, 'Nav Price'));
        });
        this.setState({ priceTs: Date.now() }); // hack to update value till redux is implemented
        if (data && data.length) {
          setFundsState({
            priceTs: Date.now(),
            data,
          });
          // console.log('fetch price state updated at ->>', moment().format('h:mm:ss:SS a'));
        }
      })
  }

  sortItems(data, attr, reverse) {
    const sortFn = (a, b) => {
      var _a = a[attr] === '-' ? 0 : a[attr],
        _b = b[attr] === '-' ? 0 : b[attr];
      if (typeof _a === 'string' && typeof _b === 'string') {
        _a = _a.toLowerCase();
        _b = _b.toLowerCase();
      }
      if (_a < _b)
        return -1;
      else if (_a > _b)
        return 1;
      return 0;
    }

    let out = data.slice().sort(sortFn);
    return reverse ? out.reverse() : out;
  }

  sortHandler = (sortAttr) => {
    const { setFundsState, funds: discovery, requestPayloadSort } = this.props;
    let search_type = "search_bar"; // need to change for sorting
    let query = discovery.query;
    if (requestPayloadSort) {
      if (requestPayloadSort.name === '' && requestPayloadSort.value === '') {
        setFundsState({
          requestPayloadSort: {
            name: sortAttr,
            value: 'desc',
          },
        });
      } else if (requestPayloadSort.name === sortAttr && requestPayloadSort.value === 'desc') {
        setFundsState({
          requestPayloadSort: {
            name: sortAttr,
            value: 'asc',
          },
        });
      } else if (requestPayloadSort.name === sortAttr && requestPayloadSort.value === 'asc') {
        setFundsState({
          requestPayloadSort: {
            name: '',
            value: '',
          },
        });
      } else if (sortAttr) {
        setFundsState({
          requestPayloadSort: {
            name: sortAttr,
            value: 'desc',
          },
        });
      }
    }
    this.queryHandler(query, search_type);
    addEventToAnalytics('Sort', 'Sort', 'SORT', { query: discovery.query, logId: discovery.logId, selectedOption: sortAttr }, true);
  }

  filterApply = (filterAttrs) => {
    const { setFundsState, funds: discovery } = this.props;
    let search_type = "search_bar"; // need to change for filter
    let query = discovery.query;
    let attrs = {}, selectedFilters = {}, filterKeys = [], filterValues = [];
    Object.keys(filterAttrs).map((c) => attrs[c] = _.filter(filterAttrs[c], ['selected', true]));
    Object.keys(attrs).map((p) => {
      if (attrs[p] && attrs[p].length) {
        selectedFilters[p] = attrs[p];
        selectedFilters[p].map((h) => {
          let kk = FilterAttrData.filter((fI) => fI.name === p);
          if (kk && kk.length > 0) {
            filterKeys.push(kk[0].col); // api gives 'asset_class' not 'Asset Class', so convert back 'Asset Class' to 'asset_class' for api filter request
          }
          // filterKeys.push(p);
          filterValues.push(h.name);
        });
      }
    });
    setFundsState({
      requestPayloadFilters: {
        filterKeys,
        filterValues,
      },
      filterAttrs,
    });
    this.queryHandler(query, search_type);
    addEventToAnalytics('Filter', 'Filter', 'FILTER', { query: discovery.query, logId: discovery.logId, selectedFilter: selectedFilters }, true);
  }

  filterHandler(filterAttrs) {
    // this.setState({ filterAttrs }, this.filterApply);
    // const { setFundsState, funds } = this.props;
    // setFundsState({
    //   filterAttrs,
    // });
    this.filterApply(filterAttrs);

    // addEventToAnalytics('Filter','Filter','FILTER',{ query: funds.query, logId: funds.logId, selectedFilter: filterAttrs },true);
  }

  setDateRange = (range) => {
    if (!this.sliderElement) return;

    if (range)
      this.sliderElement.dateRangeHandler({
        start: moment("01 " + range.start, "DD MMM YYYY"),
        end: moment("01 " + range.end, "DD MMM YYYY")
      });
    else
      this.sliderElement.clickHandler(this.defaultYears);
  }

  dateRangeHandler(range) {
    const { funds, dateRangeSelected, setFundsState } = this.props;
    this.setState({ dateRange: { ...range } }, () => {
      setFundsState({
        dateRange: this.state.dateRange,
      });
      // this.filterApply();
    });
    if (this.state.callFundsAPI) {
      this.setState({ callFundsAPI: false })
    }
    else {
      addEventToAnalytics('Timeline change', 'Timeline change', 'TIMELINE_CHANGE', { query: funds.query, logId: funds.logId, start: moment(range.start).format('MMM YYYY'), end: moment(range.end).format('MMM YYYY') }, true);
    }
  }

  getSecondaryName = (originalName, item, index) => {
    const { funds } = this.props;
    const { chartData } = funds;

    if (typeof originalName !== 'undefined') {
      return chartData.filter(e => e.view_name === originalName[index])[0].Secondary_view_name;
    }
    const newArr = chartData.filter(e => e.view_name === item);
    return newArr.length ? newArr[0].Secondary_view_name : null;
  }

  viewHandler(view, secondaryView, mappingVariable) {
    const { setFundsState } = this.props;
    setFundsState({
      itemCardView: { view, secondaryView, mappingVariable }
    });
  }

  searchAnalytics = async (item) => {
    const { searchAnalyticsAPI, funds } = this.props;
    searchAnalyticsAPI({ search_query_id: funds.logId, ticker: item.ticker })
  }

  selectHandler(item, index) {
    // this.state.data: ->  mark the item in main list if exist
    // this.state.items: -> mark the item in sfm list if exist

    const { setFundsState, setSFMState, funds, sfmSelectedItemTickers, addSFMItemTickersRequest, removeSFMItemTickersRequest, deleteAllSFMItemTickersRequest } = this.props;
    const { data } = this.state;
    if (item === undefined) {
      this.state.selectedCards.forEach(d => { d._selected = false; d._order = undefined });
      data.forEach(d => { d._selected = false; d._order = undefined });
      this.modalToggle('remove');
      setSFMState({
        items: [],
        modal: false,
      });
      setFundsState({
        data,
      });
      console.log('=====> DELETE ALL SFM FUNDS/ITEMS <=====');
      if (deleteAllSFMItemTickersRequest) {
        deleteAllSFMItemTickersRequest()
      }
      return;
    }

    item._selected = !item._selected;

    // mark the item in main list if exist
    if (this.state.data && this.state.data.length) {
      let _item = this.state.data.find(e => cn(e, 'Ticker') === cn(item, 'Ticker'));
      if (_item) _item._selected = item._selected;
      setFundsState({
        data: this.state.data,
      });
    }

    // let _item = data.find(e => cn(e, 'Ticker') === cn(item, 'Ticker'));
    // if (_item) _item._selected = item._selected;

    if (item._selected) {
      let selectedCards = this.state.selectedCards.slice();
      console.log(selectedCards)
      index !== undefined ? selectedCards.splice(index, 0, item) : selectedCards.push(item);
      setSFMState({
        items: selectedCards,
      });
      if (addSFMItemTickersRequest) {
        addSFMItemTickersRequest({
          selectedItemTickers: item.ticker
        })
        addEventToAnalytics('Funds Select', 'Funds Select', 'FUNDS_SELECT', { query: funds.query, logId: funds.logId, ticker: item.ticker, category: 'advisory_PTC' }, true);
      }
      this.searchAnalytics(item);
    } else {
      setSFMState({
        items: this.state.selectedCards.filter((e) => cn(e, 'Id') !== cn(item, 'Id')),
      });
      if (removeSFMItemTickersRequest) {
        removeSFMItemTickersRequest({
          removeTicker: item.ticker
        })
      }
      this.setState({ selectedCards: this.state.selectedCards.filter((e) => cn(e, 'Id') !== cn(item, 'Id')) }, () => {
        if (this.state.selectedCards.length === 0) {
          this.modalToggle('remove');
        }
      });
      item._order = undefined;
    }
    addEventToAnalytics('Funds Select', 'Funds Select', 'FUNDS_SELECT', { query: funds.query, logId: funds.logId, ticker: item.ticker, category: 'advisory_PTC' }, true);
  }

  loginHandler = () => {
    const { setFundsState } = this.props;
    setFundsState({
      loggedIn: true,
    });
  }

  searchFundsActiveRegion = (activeRegion) => {
    const { setFundsState } = this.props;
    setFundsState({
      activeRegion
    });
  }

  checkPopOpen = (bool) => {
    // console.log(bool, "checkPopOpen")
    const { setFundsState } = this.props;
    setFundsState({
      firstPop: bool
    });
  }

  modalToggle = (modalToggleState) => {
    const { setSFMState } = this.props;
    if (modalToggleState === 'add') {
      setSFMState({
        modalToggleState: true,
      });
    } else if (modalToggleState === 'remove') {
      setSFMState({
        modalToggleState: false,
        autoOpenModal: undefined,
      });
    }
  }

  setProfileCookie = () => {
    this.setState({ setCookie: true })
  }

  goBackfromMoreStocks = () => {
    const { clearFundsApiLoadPercent, previousSearch: { universe }, setUniverse, query, searchQueryRequest } = this.props;
    const defaultPageNo = 1;

    addEventToAnalytics('Go Back From More Stocks Page', 'Go Back From More Stocks Page', 'GO_BACK_FROM_MORE_STOCKS_PAGE', {}, true);
    if (setUniverse) {
      setUniverse({
        universe,
        page: defaultPageNo,
        showGoBackSection: false,
        showGoBackSectionClicked: true,
      });
    }

    clearWindowUtmSession();
    if (clearFundsApiLoadPercent) {
      clearFundsApiLoadPercent();
    }

    if (searchQueryRequest) {
      searchQueryRequest({
        query,
        page: defaultPageNo,
        search_type: 'search_bar',
      });
    }
  }

  moreStocksOnClick = () => {
    const { searchQueryRequest, clearFundsApiLoadPercent, setUniverse, fundsUniverse, query } = this.props;
    const defaultPageNo = 1;

    addEventToAnalytics('More Stocks Like Share', 'More Stocks Like Share', 'MORE_STOCKS_LIKE_SHARE', { 'SEARCH': 'Share' }, true);
    if (setUniverse) {
      setUniverse({
        page: defaultPageNo,
        universe: ['share'],
        previousSearch: {
          universe: fundsUniverse,
        },
        showGoBackSection: true,
        isMoreStocksClicked: true,
      });
    }

    clearWindowUtmSession();
    if (clearFundsApiLoadPercent) {
      clearFundsApiLoadPercent();
    }

    if (searchQueryRequest) {
      searchQueryRequest({
        query,
        page: defaultPageNo,
        search_type: 'search_bar',
      });
    }
  }

  render() {
    const { funds, sortAttr, loggedIn, modalToggleState, surveyModal, discovery, loading, sponsors_tickers } = this.props;
    const {
      query,
      highlightText,
      data,
      // stocks_data,
      // sponsored_data,
      correctedQuery,
      messages,
      related,
      view,
      chartData,
      filterAttrData,
      filterAttrs,
      cardsCount,
      sortDesc,
      catData,
      itemCardView,
      activeRegion,
      outPerfReturnsData,
      queryCategoryValue,
      firstPop,
      didYouMean,
      requestPayloadSort,
      showGoBackSection,
    } = funds;
    // const data = data; // [...sponsored_data,...stocks_data,...data];
    const _renderGoBack = <div className='go-back-section' style={{ paddingLeft: '16px' }} onClick={() => this.goBackfromMoreStocks()}>
      <img src='/assets/images/left_arrow_svg.svg' /><span>Go Back To All Results</span>
    </div>
    // const renderMoreStocksSection=(<div className='render-more-stocks-section'>
    //     <div className='render-more-text' onClick={()=>this.moreStocksOnClick()} >More Stocks <i class="fas fa-chevron-right"></i></div>
    //   </div>)
    //  const getCompleteList=()=>{
    //   let sponsoredData=[];
    //   let stocksData=[];
    //   let moreStocks=[];
    //   let filteredData=[];
    //   let _SpD = data.filter((kl) => kl.is_sponsored);
    //   if(_SpD.length > 0){
    //     sponsoredData=renderItemList(_SpD);
    //   }
    //   let _SD = data.filter((kl) => kl.type === 'SHARE');
    //   if(_SD.length > 0 && !showGoBackSection){
    //     stocksData=renderItemList([_SD[0]]);
    //     moreStocks=[renderMoreStocksSection];
    //   }
    //   if(data.length > 0){
    //     if (!showGoBackSection) {
    //       filteredData=renderItemList(data.filter((kl) => !kl.is_sponsored && kl.type !== 'SHARE'));
    //     } else {
    //       filteredData=renderItemList(data.filter((kl) => !kl.is_sponsored));
    //     }
    //   }
    //   return [sponsoredData,stocksData,moreStocks,filteredData];
    // }

    let highlightText_local = {
      feesHighLightEnable: false,
      returnHighLightEnable: false,
      riskHighLightEnable: false,
    };

    if (typeof chartData !== 'undefined') {
      highlightText_local = highlightText;
    }

    const OverlayInnerComponent = (<Survey {...this.props} setProfileCookie={this.setProfileCookie} />)

    // console.log('Main Render', moment().format('h:mm:ss:SS a'));
    let renderModal = '';
    const { isTermsAgreed, accConfirmedModal } = this.props;
    if (loggedIn && isTermsAgreed === 0) {
      // renderModal = <TermsNConditions />
    } else if (loggedIn && accConfirmedModal) {
      renderModal = <AccountConfirmed />
    }

    // redirect for anonymous user with no query
    if (isEmpty(this.getQueryItems()) && (!this.props.match.params.id || this.props.match.params.id == '')) {
      return (
        <main role="main" className="mb-5 pb-2 pageContainer">
          <TopSection
            didYouMean={didYouMean}
            fundsApiCallPercent={this.props.fundsApiCallPercent}
            sfmDateRange={this.state.dateRange}
            dateRangeSelected={this.props.dateRangeSelected}
            sfmCatData={catData}
            items={this.props.selectedCards}
            correctedQuery={correctedQuery}
            messages={messages}
            data={data}
            surveyModelOpen={() => this.surveyModalOpen()}
            modalToggle={this.modalToggle}
            modalToggleState={modalToggleState}
            range={this.state.dateRange}
            loading={loading || data.length <= 0}
            relatedQueries={related}
            selectHandler={this.selectHandler}
            queryHandler={this.queryHandler}
            didYouMeanSearchQuery={this.didYouMeanSearchQuery}
            query={query}
            logoutHandler={this.props.logoutHandler}
            loggedIn={loggedIn}
            loginHandler={this.loginHandler}
            showSinginIcon={loggedIn}
            view={view}
            cardListToolbar={<CardListToolbar dateRangeHandler={this.dateRangeHandler}
              dateRangeSelected={this.props.dateRangeSelected}
              inputRef={el => this.sliderElement = el}
              viewHandler={this.viewHandler}
              view={view}
              funds={funds}
              query={query}
              itemCardView={itemCardView}
              chartData={chartData}
              highlightText={highlightText_local}
              loading={loading || data.length <= 0}
            />}
            moreStocks={showGoBackSection ? _renderGoBack : null}
            toolbar={<Toolbar
              sortHandler={this.sortHandler}
              filterHandler={this.filterHandler}
              query={query}
              range={this.state.dateRange}
              filterAttrData={filterAttrData}
              filterAttrs={filterAttrs}
              recordCount={cardsCount}
              sortAttr={_.upperFirst(requestPayloadSort.name)}
              sortDesc={sortDesc}
              inputRef={el => this.sliderElement = el}
              dateRangeHandler={this.dateRangeHandler}
              dateRangeSelected={this.props.dateRangeSelected}
              loading={loading || data.length <= 0} />}
            history={this.props.history}
          />
          <Overlay
            className={`overlay-items`}
            modalToggleState={modalToggleState}
            isBlocked={(!loggedIn && !loading && surveyModal)}
            overlayInnerComponent={OverlayInnerComponent}
          >
            <SearchCard queryHandlerMobile={this.queryHandler} history={this.props.history} />
          </Overlay>
        </main>
      );
    }

    return (
      <main role="main" className="mb-5 pb-2 pageContainer" style={loading ? { overflow: 'hidden' } : {}}>
        <TifinWealthMessage />
        <TopSection
          didYouMean={didYouMean}
          fundsApiCallPercent={this.props.fundsApiCallPercent}
          sfmDateRange={this.state.dateRange}
          dateRangeSelected={this.props.dateRangeSelected}
          sfmCatData={catData}
          surveyModelOpen={() => this.surveyModalOpen()}
          items={this.props.selectedCards}
          correctedQuery={correctedQuery}
          messages={messages}
          data={data}
          modalToggle={this.modalToggle}
          modalToggleState={modalToggleState}
          range={this.state.dateRange}
          loading={loading || data.length <= 0}
          relatedQueries={related}
          selectHandler={this.selectHandler}
          queryHandler={this.queryHandler}
          didYouMeanSearchQuery={this.didYouMeanSearchQuery}
          query={query}
          logoutHandler={this.props.logoutHandler}
          loggedIn={loggedIn}
          loginHandler={this.loginHandler}
          showSinginIcon={loggedIn}
          view={view}
          //date range
          cardListToolbar={<CardListToolbar dateRangeHandler={this.dateRangeHandler}
            dateRangeSelected={this.props.dateRangeSelected}
            inputRef={el => this.sliderElement = el}
            viewHandler={this.viewHandler}
            view={view}
            funds={funds}
            query={query}
            itemCardView={itemCardView}
            chartData={chartData}
            highlightText={highlightText_local}
            loading={loading || data.length <= 0}
          />
          }
          moreStocks={this.props.showGoBackSection ? _renderGoBack : ''}
          //sort and filter
          toolbar={<Toolbar
            sortHandler={this.sortHandler}
            filterHandler={this.filterHandler}
            query={query}
            range={this.state.dateRange}
            filterAttrData={filterAttrData}
            filterAttrs={filterAttrs}
            recordCount={cardsCount}
            sortAttr={_.upperFirst(requestPayloadSort.name)}
            sortDesc={sortDesc}
            inputRef={el => this.sliderElement = el}
            dateRangeHandler={this.dateRangeHandler}
            dateRangeSelected={this.props.dateRangeSelected}
            loading={loading || data.length <= 0} />}
          history={this.props.history}
        />
        <Overlay
          className={`overlay-items `}
          modalToggleState={modalToggleState}
          isBlocked={(!loggedIn && !loading && surveyModal && data.length > 0)}
          overlayInnerComponent={OverlayInnerComponent}
        >
          <div className="mt-2 mb-5 container pageWrapper">
            {data.length > 0 && !loading ? (
              <ItemList
                reponsiveDetailsModal={{ view: view }}
                {...this.props}
                items={data}
                catData={catData}
                range={this.state.dateRange}
                view={itemCardView}
                chartData={chartData}
                highlightText={highlightText_local}
                selectHandler={this.selectHandler}
                activeRegion={activeRegion}
                searchFundsActiveRegion={this.searchFundsActiveRegion}
                outPerfReturnsData={outPerfReturnsData}
                outPerfTicker={queryCategoryValue}
                checkPopOpen={this.checkPopOpen}
                firstPop={firstPop}
                queryHandler={this.queryHandler}
                moreStocksOnClick={this.moreStocksOnClick}
                sponsors_tickers={sponsors_tickers}
              />
            ) : (data.length <= 0 && !loading ? (
              <NoResult />
            ) : (
              <div style={{ minHeight: '50vh' }} className="d-flex align-items-center justify-content-center">
                <Spinner
                  message
                  loading={loading}
                  loadPercent={this.props.fundsApiCallPercent}
                />
              </div>
            ))}
          </div>
        </Overlay>
        {renderModal}
      </main>
    );
  }
}

const mapStateToProps = ({ loading, funds, discovery, auth, sfm, register, global }) => ({
  funds: discovery,
  surveyModal: global.surveyModal,
  sponsors_tickers: discovery.sponsorsTickers,
  logId: discovery.logId,
  queryUUID: discovery.queryUUID,
  fundsUniverse: discovery.universe,
  fundsData: discovery.data,
  loadPercent: global.fundsApiCallPercent,
  // stocksData: discovery.stocks_data,
  previousSearch: discovery.previousSearch,
  // sponsoredData: discovery.sponsored_data,
  showGoBackSection: discovery.showGoBackSection,
  SHOWING_ALL_STOCKS: discovery.SHOWING_ALL_STOCKS,
  selectedCards: sfm.items,
  modalToggleState: sfm.modalToggleState,
  // SFMCat: sfm.sfmCatData,
  SFMModal: sfm.modal,
  query: discovery.query,
  isTermsAgreed: auth.termsAgreed,
  isNewUser: auth.newUser,
  loggedIn: auth.loggedIn,
  loggingIn: auth.loggingIn,
  accConfirmedModal: auth.accConfirmedModal,
  registerSuccess: register.registerSuccess,
  useremail: auth.user.email,
  firstName: auth.user.firstName,
  lastName: auth.user.lastName,
  sortAttr: discovery.sortAttr,
  requestPayloadSort: discovery.requestPayloadSort,
  dateRangeSelected: discovery.dateRangeSelected,
  fundsApiCallPercent: global.fundsApiCallPercent,
  sfmSelectedItemTickers: sfm.selectedItemTickers,
  loading: createLoadingSelector(['SEARCH_QUERY'])({ loading }) || discovery.actions,
})

const mapDispatchToProps = {
  newQuery,
  searchQueryRequest,
  setFundsState,
  setUniverse,
  refreshFundsState,
  dymLogs,
  setSFMState,
  setGlobalState,
  searchAnalyticsAPI,

  removeSFMItemTickersRequest,
  addSFMItemTickersRequest,
  deleteAllSFMItemTickersRequest,
  clearFundsApiLoadPercent,

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main)

export const Waiting = () => {
  return (
    <div className="w-100 loading-wrapper d-flex align-items-center justify-content-center">
      <div className="text-secondary text-center">
        <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5"></i>
        <h3 className="mt-4">Searching the universe of 2000+ Funds!</h3>
      </div>
    </div>
  )
}

// export const NoResult = (props) => {
//   const errorMessage = 'Magnifi can’t handle this query at this point in time, but will soon learn.'
//   return (
//     <div className="w-100">
//       <div className="text-secondary text-center">
//         <h2 className="mt-4">{errorMessage}</h2>
//         {!props.isQueryEmpty && <h2 className="mt-4" style={{ lineHeight: '0.6' }}>Thanks for the challenge! Please try another search.</h2>}
//       </div>
//     </div>
//   )
// }

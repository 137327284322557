import React, { Component } from 'react';
import {Dropdown, Menu, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import Slider from "react-slick";
import { DownOutlined } from '@ant-design/icons';

import { getPremiumFeatureDetails } from 'apis/test-drive';
import { weightSelectorsOptions } from 'DataSet';

const SETTINGS = {
	dots: true,
	infinite: true,
	slidesToShow: 3,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 2000,
	rtl: true,
	vertical: true,
	verticalSwiping: true,
	pauseOnHover: true,
};

let arr = [], arrToMap = [], index = 0, limit = 4, intervalIsPaused = false;

class TopFeeds extends Component {
  constructor(props){
    super(props);
		this.state = {
			// selectedWeightOptions: props.selectedWeightOptions || 'passive fund selector',
		}
  }

  executeCall = (query) => {
    const { executeCall } = this.props;
    executeCall(query, 'related_searches');
  }

  queryHandler = (event) => {
		const elem = event.target;
		if (elem) {
			const data = elem.getAttribute('aria-data');
			const { queryHandler } = this.props;
			if (queryHandler && data) {
				queryHandler(data, 'trending_searches');
			}
		}
  }

	// weightHandleClick = (e) => {
	// 	if (e && e.key) {
	// 		this.setState({ selectedWeightOptions: e.key }, () => {
	// 			const seletedObj = weightSelectorsOptions.find((kl) => kl.value === e.key);
	// 			const { setWeightModal } = this.props;
	// 			if (setWeightModal && seletedObj) {
	// 				setWeightModal(seletedObj);
	// 			}
	// 		});
	// 	}
	// }

  render() {
    const { queryHandler, queryList, weightSetting, isModelsToggleOn } = this.props;
		const { selectedWeightOptions } = this.state;
		// const menu = (
		//   <Menu onClick={this.weightHandleClick} selectedKeys={[selectedWeightOptions]}>
		// 		{weightSelectorsOptions && weightSelectorsOptions.map((weightItem) => {
		// 			return (
		// 				<Menu.Item key={weightItem.value}>
		// 					{weightItem.name}
		// 				</Menu.Item>
		// 			)
		// 		})}
		//   </Menu>
		// );

		// const renderDropdownSelectedText = weightSelectorsOptions.find((kl) => kl.value === selectedWeightOptions) ? weightSelectorsOptions.find((kl) => kl.value === selectedWeightOptions).name : selectedWeightOptions;

    return (
      <React.Fragment>
				<div id="enahncer-top-feeds-wrapper" className={`enahncer-top-feeds-wrapper`}>
					<div className="marquee-area">
						<div id="enhancer-query-feeds-block">
							{queryList && Object.keys(queryList).length > 0 && (
								Object.keys(queryList).map((item, index) => {
									return (
										<div key={`feeds-type-writer-${index+1}`} id={`feeds-type-writer-${index+1}`} className={`marquee-child feeds-type-writer ${index+1}`}>
											{((item === 'outperform') ? (
													isModelsToggleOn ? (
														<Tooltip placement="bottomLeft" title="Applicable only for fund replacements">
															<span style={{ paddingRight: 5 }} aria-data={item} onClick={(event) => this.queryHandler(event)}>
																{queryList[item]}
															</span>
														</Tooltip>
													) : (
														<span style={{ paddingRight: 5 }} aria-data={item} onClick={(event) => this.queryHandler(event)}>
															{queryList[item]}
														</span>
													)
												) : (
													<span aria-data={item} onClick={(event) => this.queryHandler(event)}>
														{queryList[item]}
													</span>
												)
											)}
										</div>
									)
								})
							)}
						</div>
					</div>
				</div>
      </React.Fragment>
    )
  }
}

export default TopFeeds;

// old logic Weight Modal in query

// {queryList && Object.keys(queryList).length > 0 && (
// 	Object.keys(queryList).map((item, index) => {
// 		return (
// 			<div key={`feeds-type-writer-${index+1}`} id={`feeds-type-writer-${index+1}`} className={`marquee-child feeds-type-writer ${index+1}`}>
// 				{weightSetting && (item === 'user_criteria') ? (
// 					isModelsToggleOn ? (
// 						<Tooltip placement="bottomLeft" title="Applicable only for fund replacements">
// 							<span style={{ paddingRight: 5 }} aria-data={item} onClick={(event) => this.queryHandler(event)}>
// 								Best Alternatives Using
// 							</span>
// 							<span style={{ textTransform: 'capitalize' }}>
// 								<Dropdown overlay={menu} disabled={isModelsToggleOn}>
// 									<a className="ant-dropdown-link" style={{ color: 'inherit' }} onClick={e => e.preventDefault()}>
// 										{renderDropdownSelectedText} <DownOutlined />
// 									</a>
// 								</Dropdown>
// 							</span>
// 							<span style={{ minWidth: 20, minHeight: 15 }}>{weightSetting}</span>
// 						</Tooltip>
// 					) : (
// 						<>
// 							<span style={{ paddingRight: 5 }} aria-data={item} onClick={(event) => this.queryHandler(event)}>
// 								Best Alternatives Using
// 							</span>
// 							<span style={{ textTransform: 'capitalize' }}>
// 								<Dropdown overlay={menu} disabled={isModelsToggleOn}>
// 									<a className="ant-dropdown-link" style={{ color: 'inherit' }} onClick={e => e.preventDefault()}>
// 										{renderDropdownSelectedText} <DownOutlined />
// 									</a>
// 								</Dropdown>
// 							</span>
// 							<span style={{ minWidth: 20, minHeight: 15 }}>{weightSetting}</span>
// 						</>
// 					)
// 				) : ((item === 'outperform') ? (
// 						isModelsToggleOn ? (
// 							<Tooltip placement="bottomLeft" title="Applicable only for fund replacements">
// 								<span style={{ paddingRight: 5 }} aria-data={item} onClick={(event) => this.queryHandler(event)}>
// 									{queryList[item]}
// 								</span>
// 							</Tooltip>
// 						) : (
// 							<span style={{ paddingRight: 5 }} aria-data={item} onClick={(event) => this.queryHandler(event)}>
// 								{queryList[item]}
// 							</span>
// 						)
// 					) : (
// 						<span aria-data={item} onClick={(event) => this.queryHandler(event)}>
// 							{queryList[item]}
// 						</span>
// 					)
// 				)}
// 			</div>
// 		)
// 	})
// )}

import { isViewPresentInChartTypes, getNest } from 'Utils'
import { BrokersComms } from 'DataSet'


/*
  Input : result = { funds: [ ... ], query_var: [ ... ], Regions: { ... }, [view_name]: {... }, ... }
  Output : Adds below fields to each fund item. Updates input object and returns its reference.
  {
    returns, // not added, just reverses the items in list
    _start,
    _end,
    brokers,
    broker,
    _queryVar,
    _viewChartType,
    _viewData,
    _viewName,
  }
*/

export const fixFundsData = (result) => {
  let queryVar = result.query_var.filter(e => {
    if (e.active && e.view_name && e.view_name != "") {
      if(e.view_name != 'Highlight'){
        return e;
      }
    }
  })[0];

  result.funds.forEach(e => {
    fixReturnsData(e, true);
    e.brokers = BrokersComms;
    e.broker = getNest([0], e.brokers);

    e._queryVar = queryVar;
    if(!queryVar || queryVar.length === 0)  {
      e._viewChartType = "regionsChart"
      if(e.ticker == 'FSIGX') console.log(result)
      // e._viewData = result['Regions'][e.ticker]; Removed this becasue API is not sending Regions key and I think this get overridden to default overlap chart anyways.
    } else {
      const staticVal = queryVar.Static;
      const viewName = queryVar.view_name;
      e._viewName = viewName;
      if (!staticVal) {
        if (!isViewPresentInChartTypes(viewName) && isDynamicChart(viewName, queryVar)) {
          e._viewChartType = "dynamicGaugeChart";
          e._viewData = result[viewName][e.ticker];
        } else if(viewName === "Themes") {
          e._viewChartType = "carousel";
          e._viewData = result[viewName][e.ticker];
        } else {
          e._viewChartType = queryVar.chart_type;
          e._viewData = result[viewName][e.ticker];
        }

      } else {
        if(viewName === "Regions") {
          e._viewChartType = "burst_chart";
          e._viewData = result[viewName][e.ticker];
        } else if (viewName === "Top Holdings" || viewName === "Asset Allocation") {
          e._viewChartType = "pie_chart";
          e._viewData = viewName === "Top Holdings" ? result[viewName]['funds'].find(t => t.ticker === e.ticker) : result[viewName].find(t => t.ticker === e.ticker);
        } else {
          e._viewChartType = queryVar.chart_type;
          e._viewData = result[viewName][e.ticker];
        }
      }
    }
  })
  return result;
}

const isDynamicChart = (view, query_var) => {
  if (!query_var.Static && query_var.chart_type === 'odometer') {
    return true;
  }
  return false;
}


/*
  Input : data = { returns: [ ... ], ... }, reverse = false
  Output : Adds below fields to input data object. Updates input object in-place.
  {
    returns, // not added, just reverses the items in list if reverse = true
    _start,
    _end,
  }
*/

export const fixReturnsData = (data, reverse = false) => {
  data.returns.reverse();
  let r = getNest(['returns', 0], data);
  if (r){
    data._start = r['d'];
    data._end = data.returns[data.returns.length-1]['d'];
  }
}

// absolute imports
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
// import { isEmpty } from 'lodash';
import classNames from 'classnames';
import DocumentTitle from 'react-document-title';
import { ContainerQuery } from 'react-container-query';
import { Layout, Result } from 'antd';
import { Route, Switch, Redirect } from 'react-router-dom';

import { logout } from 'actions/auth';
import { setGlobalState } from 'actions/global';
import { loadCPMListRequest } from 'actions/enhancer';
import { subscriptionPlan, setProfileState, fetchProfile, setPremiumState, premiumFeatureAccessRequest } from 'actions/profile';
import { getHomeOfficeModelsRequest } from 'actions/investment';
// import {getPremiumFeatureDetails} from 'apis/test-drive';
import { createLoadingSelector } from 'selectors/loading-selector';
import { ReservedRoutes, REDIRECT_DOMAIN_PATH, GET_USER_TYPE } from 'DataSet';
import Authorized from 'common/Authorized';
import { getWindowScreen } from 'Utils';
// import PremiumNoticeModal from 'components/PremiumNoticeModal';
import { PremiumModal } from 'components/Premium';

// relative imports
import { Loader } from './Elements/Loader';
// import { getQueryItems } from '../../utils';
import AccountConfirmed from './Elements/Registrations/AccountConfirmed';
import TermsNConditions from './Elements/Registrations/TermsNConditions';
// import RegistrationCompleted from './Elements/Registrations/RegistrationCompleted';

import { getRoutes } from './utils';
// import { changeLogo } from '../../../Utils';
import { getMenuData } from './routermenu';
import { getRouterData } from './BuildPortfolioRouter';
import { setPortfolioState } from 'actions/portfolio'
// import Header from './Header';


const { Content } = Layout;
const { AuthorizedRoute, check } = Authorized;

const redirectData = [];
const getRedirect = (item) => {
  if (item && item.children) {
    if (item.children[0] && item.children[0].path) {
      redirectData.push({
        from: `${item.path}`,
        to: `${item.children[0].path}`,
      });
      item.children.forEach((children) => {
        getRedirect(children);
      });
    }
  }
};
getMenuData().forEach(getRedirect);

const query = {
  'screen-xs': {
    maxWidth: 575,
  },
  'screen-sm': {
    minWidth: 576,
    maxWidth: 767,
  },
  'screen-md': {
    minWidth: 768,
    maxWidth: 991,
  },
  'screen-lg': {
    minWidth: 992,
    maxWidth: 1199,
  },
  'screen-xl': {
    minWidth: 1200,
  },
};

class BuildPortfolio extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      headerHeight: '',
      notify: false,
      premiumModal: false,
      premiumFeature: 'false'
    };
  }

  componentDidMount() {
    document.body.style.paddingTop = 0;
    const { setGlobalState, loadCPMListRequest } = this.props;
    const windowScreen = getWindowScreen();
    if (setGlobalState && windowScreen) {
      setGlobalState({
        ...windowScreen,
      });
    }

    if (loadCPMListRequest) {
      loadCPMListRequest();
    }

    setTimeout(() => {
      console.log('-- premiumFeatureAccessRequest block --');
      const { premiumFeatureAccessRequest, premiumNoticeModalOpen, access, fetchingPremiumDetails } = this.props;
      if(!premiumNoticeModalOpen && (access === 'Denied' || access === '') && !fetchingPremiumDetails) {
        console.log('-- premiumFeatureAccessRequest --');
        premiumFeatureAccessRequest('build_portfolio', (res) => {
          console.log('-- premiumFeatureAccessRequest --');
          console.log(res);
          this.setState({ premiumModal: res.premiumNoticeModalOpen });
          console.log('---------------------------------');
          // this.setState({ premiumFeature: res.premiumNoticeModalOpen });
        });
      }
    }, 1000)
  }

  componentWillUnmount() {
    console.log('unmounting')
    this.props.setPortfolioState({ selectedBenchmark: 'SPY' })
  }

  getPageTitle() {
    const { routerData, location } = this.props;
    const { pathname } = location;
    let title = 'Magnifi';
    if (routerData[pathname] && routerData[pathname].name) {
      title = `${routerData[pathname].name} - Magnifi`;
    }
    return title;
  }

  getBashRedirect = () => {
    // According to the url parameter to redirect
    const urlParams = new URL(window.location.href); //eslint-disable-line

    const redirect = urlParams.searchParams.get('redirect');
    // Remove the parameters in the url
    if (redirect) {
      urlParams.searchParams.delete('redirect');
      window.history.replaceState(null, 'redirect', urlParams.href);
    } else {
      const { routerData } = this.props;
      // get the first authorized route path in routerData
      const authorizedPath = Object.keys(routerData).find(item => check(routerData[item].authority, item) && item !== '/');
      return authorizedPath;
    }
    return redirect;
  }

  setHeaderHeight = () => {
    const headerElement = document.getElementsByTagName("header");
    const bodyPadding = window.getComputedStyle(document.body).getPropertyValue('padding-top');
    if (headerElement && headerElement.length) {
      if (headerElement[0]) {
        this.setState({
          headerHeight: `${headerElement[0].offsetHeight}px`,
        })
      }
    }
  }

  closePremiumModal = () => {
    this.props.history.push('/securities');
  }

  premiumModalToggle = () => {
    console.log('-- premiumModalToggle --');
    this.setState({
      premiumModal: !this.state.premiumModal,
    });
  }

  // notifyToggle = () => {
  //   this.setState({
  //     notify: !this.state.notify,
  //   });
  // }
  //
  // openNotify = () => {
  //   this.setState({
  //     notify: true,
  //   });
  // }

  render() {
    const {
      routerData,
      match,
      view,
      modalToggleState,
      loggedIn,
      loading,
      location,
      isTermsAgreed,
      accConfirmedModal,
      loggingIn,
      surveyModal,
      premiumUser,
      fetchingPremiumDetails,
      logo,
    } = this.props;
    const { headerHeight } = this.state;
    const { pathname } = location;

    // if ((isEmpty(getQueryItems(this.props)) || getQueryItems(this.props) === null) && (!this.props.match.params.id || this.props.match.params.id == '') ) {
    //   window.location.href = REDIRECT_DOMAIN_PATH;
    //   return;
    // }

    const enabledReservedRoutes = ReservedRoutes.includes(pathname)
    const blurClass = (this.props.sfmToggle || enabledReservedRoutes) ? 'blur-effect' : '';
    let renderModal = '';

    if (loggedIn && isTermsAgreed === 0) {
      renderModal = <TermsNConditions />
    } else if (loggedIn && accConfirmedModal) {
      renderModal = <AccountConfirmed />
    }

    const bashRedirect = this.getBashRedirect();
    // <Header setHeaderHeight={this.setHeaderHeight} logo={logo} {...this.props} />
    // <PremiumNoticeModal
    //   {...this.props}
    //   open={!this.state.premiumModal}
    //   premiumUser={premiumUser}
    //   onClickCancel={this.closePremiumModal}
    //   onToggle={this.premiumModalToggle}
    //   notify={this.state.notify}
    //   openNotify={this.openNotify}
    //   notifyToggle={this.notifyToggle}
    //   premiumFeature={this.state.premiumFeature}
    //   modalActionType={'build-portfolio'}
    //   featureName='build-portfolio'
    // />

    const layout = (
      <Layout
        style={{
          minHeight: 'calc(100vh - 60px)',
        }}
      >
        <Content id="build-portfolio-content-wrapper" className="build-portfolio-content-wrapper" style={{ marginTop: headerHeight }}>
          {fetchingPremiumDetails
            && <Loader loading />
          }
          {!fetchingPremiumDetails && (
            <Fragment>
              <Switch>
                {
                  redirectData.map(item => <Redirect key={item.from} exact from={item.from} to={item.to} />)
                }
                {
                  getRoutes(match.path, routerData).map(item => (
                    <AuthorizedRoute
                      exact
                      key={item.key}
                      path={item.path}
                      component={item.component}
                      authority={item.authority || 'user'}
                      protected={item.protected || false}
                      redirectPath="/"
                    />
                  ))
                }
                <Redirect exact from="/" to={bashRedirect} />
                <Route render={NotFound} />
              </Switch>
              {(this.state.premiumModal && (this.props.access !== 'Granted' || this.props.access === '')) && (
                <PremiumModal
                  access={this.props.access}
                  premiumUser={premiumUser}
                  planName={this.props.planName}
                  activeFeatureName={this.props.activeFeatureName}
                  premiumNoticeModalOpen={this.props.premiumNoticeModalOpen}
                  onClickOK={this.premiumModalToggle}
                  onClickCancel={this.premiumModalToggle}
                  onToggle={this.premiumModalToggle}
                  testDriveAction={this.premiumModalToggle}
                />
              )}
            </Fragment>
          )}
        </Content>
      </Layout>
    );

    return (
      <DocumentTitle title={this.getPageTitle()}>
        <ContainerQuery query={query}>
          {params => <div className={classNames(params)}>{layout}</div>}
        </ContainerQuery>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = ({ funds, global, sfm, auth, register, router, loading, investment, profile }) => ({
  useremail: auth.user.email,
  firstName: auth.user.firstName,
  lastName: auth.user.lastName,
  premiumUser: auth.user.premiumUser,
  planName: auth.user.planName,
  isTermsAgreed: auth.termsAgreed,
  isNewUser: auth.newUser,
  loggedIn: auth.loggedIn,
  loggingIn: auth.loggingIn,
  accConfirmedModal: auth.accConfirmedModal,
  premiumFeatures: profile.premiumFeatures,
  activeFeatureName: profile.activeFeatureName,
  access: profile.access, // 'Access' or 'Denied' or 'Test Drive'
  premiumNoticeModalOpen: profile.premiumNoticeModalOpen,
  location: router.location,
  registerSuccess: register.registerSuccess,
  loadPercent: global.fundsApiCallPercent,
  surveyModal: global.surveyModal,
  query: funds.query,
  sfmToggle: sfm.modalToggleState,
  modalToggleState: sfm.modalToggleState,
  routerData: getRouterData(),
  fetchingPremiumDetails: createLoadingSelector(['GET_SUBSCRIPTION_PLAN', 'PROFILE', 'PREMIUM_FEATURE_ACCESS'])({ loading }),
  logo: profile.logo,
  profileDetails: profile.profile,
});

const mapDispatchToProps = {
  logoutHandler: logout,
  setGlobalState,
  subscriptionPlan,
  setProfileState,
  fetchProfile,
  getHomeOfficeModelsRequest,
  setPortfolioState,
  setPremiumState,
  premiumFeatureAccessRequest,
  loadCPMListRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BuildPortfolio);

const NotFound = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
    />
  )
}

import { takeEvery, fork, call, put, all, select } from 'redux-saga/effects';
import qs from 'query-string';
import get from 'lodash/get';
import { push } from 'connected-react-router';

import ActionTypes from 'action-types';
import * as actions from 'actions/auth';
import * as profileActions from 'actions/profile';
import * as globalActions from 'actions/global';
// import {
//   setPortfolioState,
// } from 'actions/portfolio';
import { getGlobalState } from 'selectors/global';

import config from 'env';
import * as AuthApi from 'apis/auth';
import * as ProfileApi from 'apis/profile';
import { oktaAuth, SCOPES, CLIENT_ID, REDIRECT_URI, OKTA_AUTHENTICATOR } from 'oktaAuth';
// import { REDIRECT_DOMAIN_PATH } from 'DataSet';
import { addEventToAnalytics, searchUrlV3, getAdditionalDetailsForRegister, clearUtmSession } from 'Utils';
import { domainRedirectForRetailAdvisor, encryptPassword, openNotificationWithIcon } from 'layouts/utils';

import * as AuthUtils from '../common/auth-utils';

function* auth(action) {
  const { payload, callback } = action;
  let oktaResponse = null;
  try {
    if (payload) {
      const email = payload.email;
      let old_password = payload.password;
      // payload.password = encryptPassword(payload.password);
      payload.e = true;

      yield put(actions.authFetch());

      if ('query' in payload) {
        delete payload.query;
      }

      let requestPayload = {
        username: email,
        password: old_password,
      }

      if (payload.executOktaLoginFor === 'register') {
        requestPayload = JSON.parse(JSON.stringify(payload));
        if ('e' in payload) {
          delete payload.e;
        }
        if ('executOktaLoginFor' in requestPayload) {
          delete requestPayload.executOktaLoginFor;
        }
      }

      console.log('payload --> ', payload)
      console.log('requestPayload --> ', requestPayload)

      oktaResponse = yield call(AuthApi.executOktaLogin, requestPayload);

      console.log('oktaResponse --> ', oktaResponse);
      if(oktaResponse &&
        oktaResponse.status === 'SUCCESS' &&
        AuthUtils.redirectOktaNonGroupMagnifiUsers(oktaResponse))
        {
          console.log('Navigating outside of Advisor')
        }
        else if (oktaResponse &&
        oktaResponse.status === "PENDING" &&
        oktaResponse.nextStep &&
        oktaResponse.nextStep.name === "reenroll-authenticator"
      ) {
        yield put(actions.setAuthState({
          oktaChangePasswordModal: true,
          oktaChangePasswordToken: oktaResponse.stateToken,
        }));
      } else {

        if (oktaResponse.status === 'PENDING' &&
          oktaResponse.messages &&
          Array.isArray(oktaResponse.messages) &&
          oktaResponse.messages.length &&
          oktaResponse.messages[0].i18n &&
          oktaResponse.messages[0].i18n.key &&
          oktaResponse.messages[0].i18n.key === 'errors.E0000119'
        ) {

          throw 'Your account is locked.'

        } else {
          if (oktaResponse.status === 'SUCCESS' &&
            oktaResponse.tokens &&
            oktaResponse.tokens.accessToken &&
            oktaResponse.tokens.accessToken.accessToken
          ) {
            yield put(actions.oktaAuthLoginRequest({
              token: oktaResponse.tokens.accessToken.accessToken,
            }))

            // NOTE: to stop all loader
            // ------------------------------------------
            yield put(actions.successfulAuth())
            // ------------------------------------------

          } else {

            // NOTE: OLD FLOW, BEFORE "IDX"
            // ----------------------------------------------------------------------------------------------------------
            if (oktaResponse &&
              oktaResponse.sessionToken &&
              oktaResponse.status === 'SUCCESS'
            ) {

              console.log('oktaResponse.sessionToken -> ', oktaResponse.sessionToken)
              // NOTE: for change-password case
              // -----------------------------------------------------
              yield put(actions.setOldPassword(old_password));
              // -----------------------------------------------------

              // NOTE: after OKTA login, redirect to '/'
              // -----------------------------------------------------
              const sessionToken = oktaResponse.sessionToken;
              if (sessionToken) {
                let __payload = {
                  sessionToken,
                  state: encodeURIComponent(
                    JSON.stringify({
                      sourceURI: window.location.pathname === '/register' ? `/register?plan=basic` : `${window.location.pathname}`, // redirect user back to source path if something went wrong
                    }),
                  ),
                };
                if (payload.executOktaLoginFor === 'register') {
                  __payload.state = encodeURIComponent(
                    JSON.stringify({
                      isNew: true,
                      validateOktaUserFor: 'register',
                      sourceURI: `/register?plan=basic`, // `${window.location.pathname}`, // redirect user back to source path if something went wrong
                    }),
                  );
                }

                if (payload.executOktaLoginFor === 'google') {
                  __payload = {
                    scopes: SCOPES,
                    clientId: CLIENT_ID,
                    responseType: 'code',
                    idp: '0oa5o7sccuy5YgrIz5d7',
                    redirectUri: REDIRECT_URI,
                    useInteractionCodeFlow: true,
                    state: encodeURIComponent(
                      JSON.stringify({
                        validateOktaUserFor: 'google',
                        sourceURI: window.location.pathname === '/register' ? `/register?plan=basic` : `${window.location.pathname}`, // redirect user back to source path if something went wrong
                      }),
                    ),
                  }
                }

                console.log('__payload --> ', __payload);
                yield put(oktaAuth.signInWithRedirect(__payload));

                if (callback && typeof callback === 'function') {
                  callback(oktaResponse);
                }
                // let signInRedirectResponse = yield call(oktaAuth.signInWithRedirect, { sessionToken });
                // console.log(signInRedirectResponse)
                // if (signInRedirectResponse) {
                //   if (callback && typeof callback === 'function') {
                //     callback(oktaResponse);
                //   }
                // }
              };
              // -----------------------------------------------------
            } else {
              throw 'Authentication failed'
            }

            // ----------------------------------------------------------------------------------------------------------

          }
        }
      }
    } else {
      throw 'Email/Password missing'
    }
  } catch (error) {
    console.log(error)
    let errorDetails = get(error, 'response.data', error.stack)
    console.log(errorDetails);
    console.log(oktaResponse);
    if (!errorDetails && (error && error.includes('locked'))) {
      errorDetails = {
        error,
      }
    }
    if (!errorDetails || (!errorDetails.error && errorDetails && errorDetails.includes('Authentication failed'))) {
      errorDetails = {
        error: 'Authentication failed'
      }
    }
    let _error = errorDetails && errorDetails.error ? errorDetails : { error: errorDetails }
    AuthUtils.deleteAuthToken()
    yield put(actions.failedAuth(_error))
    if (callback && typeof callback === 'function') {
      callback(_error);
    }
    // openNotificationWithIcon({
    //   duration: 5,
    //   type: 'error',
    //   message: 'Error',
    //   className: 'api-response-notification-class',
    //   description: errorDetails && errorDetails.error ? errorDetails.error : errorDetails,
    // });
  }
}

function* oktaChangePassword(action) {
  const { payload, callback } = action;
  let oktaResponse = null;
  try {
    if (payload && payload.password) {
      yield put(actions.oktaChangePasswordFetch());
      let requestPayload = {
        authenticator: OKTA_AUTHENTICATOR,
        newPassword: payload.password,
      }
      console.log('payload --> ', payload)
      console.log('requestPayload --> ', requestPayload)

      oktaResponse = yield call(AuthApi.executOktaIdxChangePassword, requestPayload);

      console.log('oktaResponse --> ', oktaResponse);

      if (oktaResponse.status === 'PENDING' &&
        oktaResponse.messages &&
        Array.isArray(oktaResponse.messages) &&
        oktaResponse.messages.length &&
        oktaResponse.messages[0].message
      ) {

        yield put(actions.oktaChangePasswordSuccess({
          oktaChangePasswordError: oktaResponse.messages[0].message
        }));
      } else {
        if (oktaResponse.status === 'SUCCESS' &&
          oktaResponse.tokens &&
          oktaResponse.tokens.accessToken &&
          oktaResponse.tokens.accessToken.accessToken
        ) {
          yield put(actions.oktaAuthLoginRequest({
            token: oktaResponse.tokens.accessToken.accessToken,
          }))

          yield put(actions.oktaChangePasswordSuccess({
            oktaChangePasswordModal: false
          }))
        }
      }

      // NOTE: to stop all loader
      // ------------------------------------------
      yield put(actions.successfulAuth())
      // ------------------------------------------

    } else {
      throw 'Password is missing'
    }
  } catch (error) {
    let errorDetails = get(error, 'response.data', error.stack)
    console.log(errorDetails);
    console.log(oktaResponse);
    if (!errorDetails || (!errorDetails.error && errorDetails && errorDetails.includes('Authentication failed'))) {
      errorDetails = {
        error: 'Authentication failed'
      }
    }
    let _error = errorDetails && errorDetails.error ? errorDetails : { error: errorDetails }
    // NOTE: to stop all loader
    // ------------------------------------------
    AuthUtils.deleteAuthToken()
    yield put(actions.successfulAuth({
      loggedIn: false,
      loggingIn: false,
      authFailed: true,
      error: _error,
      newUser: 0,
      termsAgreed: 0
    }))
    yield put(actions.oktaChangePasswordSuccess())
    // ------------------------------------------
    if (callback && typeof callback === 'function') {
      callback(_error);
    }
  }
}

function* oktaAuthLogin(action) {
  let newUser = null,
    userData = null,
    authAccessToken = null,
    loginDataResponse = null,
    openCalendarInviteModal = null;
  const { payload, callback } = action;
  try {
    if (payload && payload.token) {
      yield put(actions.oktaAuthLoginFetch())

      // NOTE: Get query from discovery reducer's state
      // --------------------------------------------------------------------------------------------
      const discoveryQuery = yield select(state => state.discovery.query) || '';
      const query = payload.query || discoveryQuery || window.sessionStorage.getItem('query');
      // --------------------------------------------------------------------------------------------

      if ('query' in payload) {
        delete payload.query;
      }

      // NOTE: Delete old Auth "TOKEN"
      // --------------------------------------------
      AuthUtils.deleteAuthToken()
      // --------------------------------------------

      console.log('payload --> ', payload)

      if (payload.validateOktaUserFor === 'register' || payload.validateOktaUserFor === 'google') {
        // payload ->> {"access_token":"actual value","uid":"00u5lwc23vd7ShiGm5d7","termsCondition":true,"freeTrial":true}
        console.log('AuthApi.validateOktaUser access_token -> ', payload.token)
        loginDataResponse = yield call(AuthApi.validateOktaUser, {
          ...getAdditionalDetailsForRegister(),
          termsCondition: true,
          access_token: payload.token,
        });
        console.log('loginDataResponse --> ', loginDataResponse)
        // debugger
        if (loginDataResponse) {
          if ((typeof loginDataResponse.register_step_status !== 'undefined' &&
            loginDataResponse.register_step_status === "") || (
              !loginDataResponse.original_self_role
            ) // which means profile data is incomplete
          ) {
            if (callback && typeof callback === 'function') {
              callback(loginDataResponse);
              yield put(actions.oktaAuthLoginSuccess(loginDataResponse));
              return;
              // NOTE: ---- EVERYTHING ENDS HERE ----
            }
          } else {
            if (loginDataResponse.email &&
              Array.isArray(loginDataResponse.email) &&
              loginDataResponse.email[0] === 'E-mail ID already registered'
            ) {
              // AUTO LOGIN with token
              console.log('token for oktaAuthLoginRequest -> ', payload.token)
              // NOTE: New action starts from here
              // --------------------------------------------
              yield put(actions.oktaAuthLoginRequest({
                token: payload.token,
              }));
              // --------------------------------------------
            } else {
              // NOTE: status code 200 or 201 area
              // --------------------------------------------

              authAccessToken = payload.token;

              if (typeof loginDataResponse.profile === 'undefined') {
                // User registered! response (status code -> 201)
                console.log('========== New user created response (status code -> 201) ==========')

                AuthUtils.storeAuthToken({
                  token: authAccessToken,
                  email: loginDataResponse.email,
                  lastName: loginDataResponse.lastName || loginDataResponse.last_name,
                  firstName: loginDataResponse.firstName || loginDataResponse.first_name,
                  self_role: loginDataResponse.original_self_role || loginDataResponse.self_role,
                  profile_self_role: loginDataResponse.original_self_role || loginDataResponse.self_role,
                });

                openCalendarInviteModal = true;
                newUser = loginDataResponse.new_user || false;

                let profileResponse = yield call(ProfileApi.fetchProfile)
                if (profileResponse && profileResponse.data) {
                  userData = profileResponse.data;
                } else {
                  throw 'Failed to fetch PROFILE api data'
                }

              } else {
                console.log('token -> ', payload.token)
                // User already exist response (status code -> 200)
                console.log('========== User already exist response (status code -> 200) ==========')
                userData = loginDataResponse;
              }

              console.log('userData from 200 or 201 area --> ', userData)
              // --------------------------------------------
            }
          }
        } else {
          throw 'Failed to fetch login data'
        }
      } else { // NOTE: "FOR LOGIN WORKFLOW"
        // NOTE: Snehal changed the end-point, now validate-okta
        // ---------------------------------------------------------------------------

        // before it was /login api
        // loginDataResponse = yield call(AuthApi.getOktaLoginData, {
        //   token: payload.token,
        // });

        // now it's
        userData = yield call(AuthApi.validateOktaUser, {
          ...getAdditionalDetailsForRegister(),
          termsCondition: true,
          access_token: payload.token,
        });
        authAccessToken = payload.token;
        console.log('userData from login flow --> ', userData)

        // ---------------------------------------------------------------------------
      }

      // NOTE: GOOD TO GO
      // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
      if (userData && typeof userData === 'object') {

        if (typeof userData.profile === 'undefined') {
          userData.profile = userData;
        }

        const email = userData.profile.email;
        const token = userData.token || null;
        const self_role = userData.original_self_role || null;
        const remind_for_password_change = userData.remind_for_password_change;

        userData.token = authAccessToken
        userData.email = userData.profile.email
        userData.firstName = userData.profile.first_name
        userData.lastName = userData.profile.last_name

        /** NOTE- To Handle remind_for_password_change   */
        if (remind_for_password_change) {
          // yield put(actions.successfulAuth(result))
          yield put(actions.oktaAuthLoginSuccess(userData));
          // yield put(actions.setOldPassword(old_password)); <-- calling inside "auth*" function
          yield put(push('/changepwd'))
        } else {

          if (config.advisorRetailRedirect && token) {
            const redirectTo = domainRedirectForRetailAdvisor({
              token,
              email,
              selfRole: self_role,
            });
            if (redirectTo) {
              window.location.href = redirectTo;
              return;
            }
            // yield put(oktaAuth.signInWithRedirect({
            //   redirectUri: config.advisor_redirect + '/auth/callback',
            //    scopes: SCOPES,
            //    sessionToken,
            // }));
          }

          // console.log('==== GOOD TO GO ====');
          AuthUtils.storeAuthToken({
            token: authAccessToken,
            email: userData.email,
            lastName: userData.lastName,
            firstName: userData.firstName,
            self_role: userData.self_role,
            client_exist: userData.client_exist,
            termsAgreed: userData.profile.terms_agreed,
            user_advisor_menu: userData.user_advisor_menu,
            profile_self_role: userData.profile.self_role,
            restricted_universe: userData.profile.restricted_universe || false,
          })

          clearUtmSession();

          if (window.heap) {
            window.heap.identify(userData.email);
            window.heap.addUserProperties({
              'First Name': userData.firstName,
              'Last Name': userData.lastName
            });
            window.heap.track('Login', { email: userData.email });
          }

          window.addkrakenUser && typeof window.addkrakenUser === "function" && window.addkrakenUser({
            email: userData.email,
            firstName: userData.firstName,
            lastName: userData.lastName,
          });

          // NOTE: Segment Script
          // ------------------------------------------------------------------------------------------
          if (window.analytics && window.analytics.identify && typeof window.analytics.identify === 'function') {
            // console.log('---- Auth Segment ----');
            window.analytics.identify(userData.email, {
              from: 'Auth',
              user_type: 'Advisor',
              email: userData.email,
              lastName: userData.lastName,
              firstName: userData.firstName,
              name: userData.firstName + ' ' + userData.lastName,
            });
          }
          // ------------------------------------------------------------------------------------------

          addEventToAnalytics('Login', 'Login', 'LOGIN', { email: userData.email });
          // NOTE: Google in login page triggers 'handleGoogleSignInError'
          // Which set 'loggedIn: false', to resolve this -> `loggedInByToken` used in reducers/auth.js -> GOOGLE_AUTH_FAILURE
          // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
          yield put(actions.oktaAuthLoginSuccess({
            user: {
              ...userData,
              premiumUser: false,
              loggedInByToken: (payload.token && typeof payload.token !== 'undefined')
            },
            loggedIn: true,
            loggingIn: false,
            authSuccess: true,
            token: authAccessToken,
            openCalendarInviteModal,
            newUser: newUser || false,
            client_exist: userData.client_exist,
            termsAgreed: userData.profile.terms_agreed,
            user_advisor_menu: userData.user_advisor_menu,
            remind_for_password_change: userData.remind_for_password_change,
            loggedInByToken: (payload.token && typeof payload.token !== 'undefined')
          }))
          // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------

          yield put(profileActions.loadProfile(userData.profile))
          yield put(profileActions.subscriptionPlan())

          const { surveyModal } = yield select(getGlobalState);
          if (surveyModal) {
            yield put(globalActions.setGlobalState({ surveyModal: false }));
          }

          const loggedIn = (yield select(state => state.auth)).loggedIn;
          //const remind_for_password_change = (yield select(state => state.auth)).remind_for_password_change;
          if (loggedIn || window.localStorage.getItem('token')) {
            const securitiesPath = searchUrlV3(query); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
            // debugger
            yield put(push(securitiesPath));
          }
        }

      } else {
        throw 'Failed to fetch USER_DATA'
      }

      // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------

      // if (callback && typeof callback === 'function') {
      //   callback(loginDataResponse);
      // }
    } else {
      throw '-- Payload Missing --'
    }
  } catch (error) {
    let errorDetails = get(error, 'response.data', error.stack)
    console.log(errorDetails);
    console.log(userData);
    if (
      !errorDetails ||
      (errorDetails && (
        !errorDetails.error || errorDetails.includes('Authentication failed')
      ))
    ) {
      errorDetails = {
        error: 'Authentication failed'
      }
    }
    openNotificationWithIcon({
      duration: 5,
      type: 'error',
      message: 'Error',
      className: 'api-response-notification-class',
      description: errorDetails && errorDetails.error ? errorDetails.error : errorDetails,
    });
    AuthUtils.deleteAuthToken()
    yield put(actions.oktaAuthLoginSuccess(errorDetails))
    if (callback && typeof callback === 'function') {
      callback(errorDetails);
    }
  }
}

// function* auth(action) {
//   const { payload } = action
//   try {
//     let old_password = payload.password;
//     // const ps = encryptPassword(payload.password)
//     payload.password = encryptPassword(payload.password);
//     payload.e = true;
//
//     // NOTE: Get query from discovery reducer's state
//     // --------------------------------------------------------------------------------------------
//     const discoveryQuery = yield select(state => state.discovery.query) || '';
//     // --------------------------------------------------------------------------------------------
//
//     const email = payload.email;
//     const query = payload.query || discoveryQuery || window.sessionStorage.getItem('query');
//
//     if ('query' in payload) {
//       delete payload.query;
//     }
//
//     const response = yield call(AuthApi.authenticate, qs.stringify(payload))
//
//     const result = response.data;
//     const token = result.token || null ;
//     const self_role = result.original_self_role || null;
//     const remind_for_password_change = result.remind_for_password_change;
//
//     /** NOTE- To Handle remind_for_password_change   */
//     if (remind_for_password_change) {
//       yield put(actions.successfulAuth(result))
//       yield put(actions.setOldPassword(old_password));
//       yield put(push('/changepwd'))
//     } else {
//       if (config.advisorRetailRedirect && !payload.token) {
//         const redirectTo = domainRedirectForRetailAdvisor({
//           selfRole: self_role,
//           token,
//           email,
//         });
//         if (redirectTo) {
//           window.location.href = redirectTo;
//           return;
//         }
//       }
//
//       // console.log('==== GOOD TO GO ====');
//       AuthUtils.storeAuthToken({
//         token: result.token,
//         email: payload.email,
//         firstName: result.profile.first_name,
//         lastName: result.profile.last_name,
//         termsAgreed: result.profile.terms_agreed,
//         self_role: result.self_role,
//         client_exist: result.client_exist,
//         user_advisor_menu: result.user_advisor_menu,
//         profile_self_role: result.profile.self_role,
//         restricted_universe: result.profile.restricted_universe || false,
//       })
//
//       result.email = payload.email
//       result.firstName = result.profile.first_name
//       result.lastName = result.profile.last_name
//
//       if (window.heap) {
//         window.heap.identify(payload.email);
//         window.heap.addUserProperties({'First Name': result.profile.first_name,'Last Name': result.profile.last_name});
//         window.heap.track('Login',{email:payload.email});
//       }
//
//       window.addkrakenUser && typeof window.addkrakenUser === "function" && window.addkrakenUser({
//         email: payload.email,
//         firstName: result.profile.first_name,
//         lastName:result.profile.last_name,
//       });
//
//       // NOTE: Segment Script
//       // ------------------------------------------------------------------------------------------
//       if (window.analytics && window.analytics.identify && typeof window.analytics.identify === 'function') {
//         // console.log('---- Auth Segment ----');
//         window.analytics.identify(payload.email, {
//           name: result.profile.first_name+' '+result.profile.last_name,
//           firstName: result.profile.first_name,
//           from: 'Auth',
//           lastName: result.profile.last_name,
//           email: payload.email,
//           user_type: 'Advisor',
//         });
//       }
//       // ------------------------------------------------------------------------------------------
//
//       addEventToAnalytics('Login','Login','LOGIN',{ email: payload.email });
//       // NOTE: Google in login page triggers 'handleGoogleSignInError'
//       // Which set 'loggedIn: false', to resolve this -> `loggedInByToken` used in reducers/auth.js -> GOOGLE_AUTH_FAILURE
//       // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//       yield put(actions.successfulAuth({ ...result, loggedInByToken: (payload.token && typeof payload.token !== 'undefined') }))
//       // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//       // yield put(sfmActions.setSFMState({
//       //   isRedirectFromHeaderButtons: true,
//       //   autoOpenModal: false,
//       // })) // removed because it was blocking auto-open modal after sign in
//       yield put(profileActions.loadProfile(result.profile))
//       yield put(profileActions.subscriptionPlan())
//
//       if (result && result.profile) {
//         const { share_class } = result.profile;
//         if (share_class && typeof share_class !== 'undefined') {
//           yield put(setPortfolioState({ shareClass: share_class }));
//         } else {
//           console.log('======= SHARE CLASS NOT PRESENT =======');
//         }
//       }
//
//       const { surveyModal } = yield select(getGlobalState);
//       if (surveyModal) {
//         yield put(globalActions.setGlobalState({ surveyModal: false }));
//       }
//
//       const loggedIn = (yield select(state => state.auth)).loggedIn;
//       //const remind_for_password_change = (yield select(state => state.auth)).remind_for_password_change;
//       if (loggedIn || window.localStorage.getItem('token')) {
//         const securitiesPath = searchUrlV3(query); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
//         console.log('login flow google-login -> ', securitiesPath)
//         yield put(push(securitiesPath));
//       }
//
//     }
//
//   } catch (error) {
//     const errorDetails = get(error, 'response.data', error.stack)
//     yield put(actions.failedAuth(errorDetails))
//   }
// }

function* termsAgreement() {
  const payload = {
    terms: 'y'
  }
  try {
    const response = yield call(AuthApi.acceptsTerms, qs.stringify(payload))
    if (response) {
      localStorage.setItem('termsAgreed', 1);
      yield put(
        actions.successfulTermsAgreement({
          profile: {
            terms_agreed: 1,
            accConfirmedModal: true,
          }
        })
      )
      // yield put(push('/account-confirm'))
    }
  } catch (error) {
    const errorDetails = get(error, 'response.data', error.stack)
    yield put(actions.failedTermsAgreement(errorDetails))
  }
}

function* logout(action) {
  try {
    let oktaResponse = yield call(AuthApi.executOktaLogout);
    console.log('oktaResponse --> ', oktaResponse);
    AuthUtils.deleteAuthToken()
    yield put(actions.successfulLogout())
    // const response = yield call(
    //   AuthApi.logout,
    //   qs.stringify({ email: AuthUtils.getAuthToken().email })
    // )
    // AuthUtils.deleteAuthToken()
    // yield put(actions.successfulLogout(response))
    //
    // window.location.href = REDIRECT_DOMAIN_PATH;
  } catch (error) {
    AuthUtils.deleteAuthToken()
    const errorDetails = get(error, 'response.data', error.stack)
    yield put(actions.failedLogout(errorDetails))
  }

  // window.location.reload(); //TODO: instead reset Redux store
}

function* sendChangePwdEmail(action) {
  const { payload, callback } = action
  let response = null;
  try {
    if (payload && payload.email) {
      response = yield call(AuthApi.executeOktaResetPassword, {
        username: payload.email,
        factorType: 'EMAIL',
      })
      console.log('response --> ', response)
      // factorResult: "WAITING"
      // factorType: "EMAIL"
      // recoveryType: "PASSWORD"
      // status: "RECOVERY_CHALLENGE"
      if (response && response.status === 'RECOVERY_CHALLENGE') {
        callback && typeof callback === 'function' && callback('success');
        yield put(push('/forgotpwdthankyou'))
      }
    } else {
      throw '-- Email is missing in payload --'
    }
  } catch (error) {
    let errorDetails = error && error.toString() ? error.toString() : get(error, 'response.data', error.stack)
    console.log(errorDetails);
    console.log(response);
    if (
      !errorDetails ||
      (errorDetails && (
        !errorDetails.error || errorDetails.includes('AuthApiError:')
      ))
    ) {
      if (errorDetails.includes('AuthApiError:')) {
        let errorText = errorDetails.split('AuthApiError: ').filter(e => e.length)[0];
        errorDetails = {
          error: errorText
        }
      } else {
        errorDetails = {
          error: errorDetails
        }
      }
    }
    openNotificationWithIcon({
      duration: 5,
      type: 'error',
      message: 'Error',
      className: 'api-response-notification-class',
      description: errorDetails && errorDetails.error ? errorDetails.error : errorDetails,
    });
    yield put(actions.oktaAuthLoginSuccess(errorDetails))
    if (callback && typeof callback === 'function') {
      callback(errorDetails);
    }
  }
}

function* resendPasswordResetEmail(action) {
  const { payload, callback } = action;
  let oktaResponse = null;
  try {
    if (payload && payload.email) {
      yield put(actions.resendPasswordResetEmailFetch());

      oktaResponse = yield call(AuthApi.executeOktaResetPassword, {
        username: payload.email,
        factorType: 'EMAIL',
      })

      console.log('response --> ', oktaResponse)
      // factorResult: "WAITING"
      // factorType: "EMAIL"
      // recoveryType: "PASSWORD"
      // status: "RECOVERY_CHALLENGE"

      if (oktaResponse && oktaResponse.status === 'RECOVERY_CHALLENGE') {
        callback && typeof callback === 'function' && callback({
          oktaPasswordResetEmailSent: true,
        });

      }
      yield put(actions.resendPasswordResetEmailSuccess());

    } else {
      throw 'Email is missing'
    }
  } catch (error) {
    let errorDetails = error && error.toString() ? error.toString() : get(error, 'response.data', error.stack)
    console.log(errorDetails);
    console.log(oktaResponse);
    if (
      !errorDetails ||
      (errorDetails && (
        !errorDetails.error || errorDetails.includes('AuthApiError:')
      ))
    ) {
      if (errorDetails.includes('AuthApiError:')) {
        let errorText = errorDetails.split('AuthApiError: ').filter(e => e.length)[0];
        errorDetails = {
          error: errorText
        }
      } else {
        errorDetails = {
          error: errorDetails
        }
      }
    }
    openNotificationWithIcon({
      duration: 5,
      type: 'error',
      message: 'Error',
      className: 'api-response-notification-class',
      description: errorDetails && errorDetails.error ? errorDetails.error : errorDetails,
    });
    yield put(actions.resendPasswordResetEmailSuccess(errorDetails))
    if (callback && typeof callback === 'function') {
      callback();
    }
  }
}

function* verifyChangePwdToken(action) {
  const { payload, callback } = action
  try {
    const response = yield call(AuthApi.verifyChangePwdToken, payload)

    if (response.status === 200) {
      const { name, token, message } = response.data;
      yield put(
        actions.saveverifyChangePwdToken({
          name: name || '',
          token: token || '',
        })
      )
      if (message) {
        yield put(push('/invalidtoken'))
      }
      if (callback) {
        callback();
      }
    }
  } catch (error) {
    const errorDetails = get(error, 'response.data', error.stack) || { message: 'Token invalid' };
    yield put(push('/invalidtoken'))
  }
}

function* changePassword(action) {
  let { payload } = action
  try {
    // let key = CryptoJS.enc.Utf8.parse('[fGJq$4ZGC~jjZtF');
    // let iv = CryptoJS.lib.WordArray.random(16);
    // let ps = CryptoJS.AES.encrypt(payload.values.password, key, {
    //   iv: iv,
    // });
    // ps = iv.concat(ps.ciphertext).toString(CryptoJS.enc.Base64)
    //
    // payload.values.password = ps;

    // NOTE: Encrypt password
    // --------------------------------------------------------------------
    // payload.values.password = encryptPassword(payload.values.password)
    // --------------------------------------------------------------------
    const old_password = encryptPassword(payload.old_password);
    const current_password = encryptPassword(payload.values.password);
    const response = yield call(AuthApi.changePassword,
      { email: payload.email, old_password: old_password, password: current_password, verifypwdtoken: payload.token }
    )
    if (response.status === 200) {
      yield put(actions.successfulSetPassword(response))
      const _email = (yield select(state => state.auth)).user.profile.email;
      /**NOTE- To Call Login API Again After Change Password  */
      if (_email) {
        yield put(actions.auth({ email: _email, password: payload.values.password }))
      }
    }
  } catch (error) {
    if (error && error.response.status === 400) {
      openNotificationWithIcon({
        duration: null,
        type: 'error',
        message: 'Failed',
        className: 'api-response-notification-class',
        description: error.response.data.error,
      });
    }
  }
}

function* autoLoginByToken(action) {
  let { payload, callback } = action;
  let userData = null;
  try {
    if (payload && payload.token) {
      yield put(actions.autoLoginByTokenFetch())

      // NOTE: Get query from discovery reducer's state
      // --------------------------------------------------------------------------------------------
      const discoveryQuery = yield select(state => state.discovery.query) || '';
      const query = payload.query || discoveryQuery || window.sessionStorage.getItem('query');
      // --------------------------------------------------------------------------------------------

      // NOTE: Update new auth "TOKEN"
      // --------------------------------------------
      AuthUtils.updateToken(payload.token)
      // --------------------------------------------

      let profileResponse = yield call(ProfileApi.fetchProfile)
      console.log('profileResponse --> ', profileResponse)
      if (profileResponse && profileResponse.data) {

        userData = profileResponse.data;

        if (typeof userData.profile === 'undefined') {
          userData.profile = userData;
        }

        AuthUtils.storeAuthToken({
          token: payload.token,
          email: userData.email,
          lastName: userData.last_name,
          self_role: userData.self_role,
          firstName: userData.first_name,
          termsAgreed: userData.terms_agreed,
          client_exist: userData.client_exist,
          profile_self_role: userData.self_role,
          user_advisor_menu: userData.user_advisor_menu,
          restricted_universe: userData.restricted_universe,
        });

        // Which set 'loggedIn: false', to resolve this -> `loggedInByToken` used in reducers/auth.js -> GOOGLE_AUTH_FAILURE
        // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        yield put(actions.autoLoginByTokenSuccess({
          user: {
            ...userData,
            premiumUser: false,
            loggedInByToken: (payload.token && typeof payload.token !== 'undefined')
          },
          loggedIn: true,
          loggingIn: false,
          authSuccess: true,
          token: payload.token,
          client_exist: userData.client_exist,
          termsAgreed: userData.terms_agreed,
          user_advisor_menu: userData.user_advisor_menu,
          remind_for_password_change: userData.remind_for_password_change,
          loggedInByToken: (payload.token && typeof payload.token !== 'undefined')
        }))
        // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------

        yield put(profileActions.loadProfile(userData.profile))
        yield put(profileActions.subscriptionPlan())

        const { surveyModal } = yield select(getGlobalState);
        if (surveyModal) {
          yield put(globalActions.setGlobalState({ surveyModal: false }));
        }

        const loggedIn = (yield select(state => state.auth)).loggedIn;
        //const remind_for_password_change = (yield select(state => state.auth)).remind_for_password_change;
        if (loggedIn || window.localStorage.getItem('token')) {
          const securitiesPath = searchUrlV3(query); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
          //debugger
          yield put(push(securitiesPath));
        }

      } else {
        throw 'Failed to fetch PROFILE api data'
      }
    } else {
      throw '-- Missing Payload --'
    }
  } catch (error) {
    let errorDetails = get(error, 'response.data', error.stack)
    console.log(errorDetails);
    console.log(userData);
    if (!errorDetails || (!errorDetails.error && errorDetails && errorDetails.includes('Authentication failed'))) {
      errorDetails = {
        error: 'Authentication failed'
      }
    }
    yield put(actions.failedAuth(errorDetails))
    if (callback && typeof callback === 'function') {
      callback(errorDetails);
    }
    openNotificationWithIcon({
      duration: 5,
      type: 'error',
      message: 'Error',
      className: 'api-response-notification-class',
      description: errorDetails && errorDetails.error ? errorDetails.error : errorDetails,
    });
    AuthUtils.deleteAuthToken()
    yield put(actions.autoLoginByTokenSuccess())
  }
}

function* loginSaga() {
  yield all([
    // fork(takeEvery, ActionTypes.GOOGLE_AUTH_SUCCESS, googleAuth),
    fork(takeEvery, ActionTypes.AUTH_REQUEST, auth),
    fork(takeEvery, ActionTypes.TERMS_AGREEMENT_REQUEST, termsAgreement),
    fork(takeEvery, ActionTypes.LOGOUT_REQUEST, logout),
    fork(takeEvery, ActionTypes.SEND_CHANGE_PWD_EMAIL, sendChangePwdEmail),
    fork(takeEvery, ActionTypes.VERIFY_CHANGE_PWD_TOKEN, verifyChangePwdToken),
    fork(takeEvery, ActionTypes.FORGOT_CHANGE_PASSWORD, changePassword),
    fork(takeEvery, ActionTypes.OKTA_AUTH_LOGIN_REQUEST, oktaAuthLogin),
    fork(takeEvery, ActionTypes.AUTO_LOGIN_BY_TOKEN_REQUEST, autoLoginByToken),
    fork(takeEvery, ActionTypes.OKTA_CHANGE_PASSWORD_REQUEST, oktaChangePassword),
    fork(takeEvery, ActionTypes.RESEND_PASSWORD_RESET_EMAIL_REQUEST, resendPasswordResetEmail),
  ])
}

export default loginSaga

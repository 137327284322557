import { takeEvery, fork, call, put, all, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment';
import ActionTypes from '../action-types';
import {
  buildPortfolioBatchSearch,
  thematicBatchSearch,
  getAllTickers,
  getFundData,
  getFundDataCommaSeparated,
  getAllThemes,
  savePortfolio,
  searchNewBatchQuery,
  fetchPortfolioData,
  editPortfolio,
  uploadBuilderPortfolio,
  downloadBuilderReport
} from 'apis/buildportfolio';

import {checkTickerExist} from 'apis/portfolio';

import {  fetchRealTimePrices, } from 'apis/funds'
import { uniqValues, convertToCapitalCase } from 'Utils'
import { getFundsUpdatedPrice } from 'layouts/utils'
import { fixFundsData, fixReturnsData } from 'layouts/WebDashboard/PfUpload/shared/ViewCharts'

import {
  equityBatchFetch,
  equityBatchSuccess,
  bondsBatchFetch,
  bondsBatchSuccess,
  thematicBatchFetch,
  thematicBatchSuccess,
  customTickerFetch,
  customTickerSuccess,
  tickerDataFetch,
  tickerDataSuccess,
  getThemesFetch,
  getThemesSuccess,
  finalizeSavePortfolioFetch,
  finalizeSavePortfolioSuccess,
  searchForNewQueryFetch,
  searchForNewQuerySuccess,
  fetchPortfolioFetch,
  fetchPortfolioSuccess,
  preSelectedThematicTickersFetch,
  preSelectedThematicTickersSuccess,
  setBuildPortfolioState,
  editPortfolioRequest,
  editPortfolioSuccess,
  checkBuilderTickerFetch,
  checkBuilderTickerSuccess,
} from '../actions/buildportfolio';
import { getEquityTabStates, getAllBuilPortfolioStates, getThematicTabStates } from '../selectors/buildportfolio';
import { setInvestmentState,getPortfolioListBuilderRequest } from 'actions/investment'
import { deletePortfolioAPI, downloadPortfolioReportPDF, fetchPortfolioList } from 'apis/account'
import get from 'lodash/get'
import {
  deletePortfolioByIdFetch,
  deletePortfolioByIdSuccess,
  getPortfolioListFetch,
  getPortfolioListRequest, getPortfolioListSuccess
} from 'actions/enhancer'
import {
  deletePortfolioBuilderByIdFetch,
  deletePortfolioBuilderByIdSuccess,
} from 'actions/buildportfolio'
const FileSaver = require('file-saver');

const dataMap = {
  'US Market Bonds': 'USA',
  'US Markets Bonds': 'USA',
  'Developed Markets Bonds': 'Developed Markets',
  'Emerging Markets Bonds': 'Emerging Markets',
  'US Market Funds': 'USA',
  'US Markets Funds': 'USA',
  'Developed markets Funds': 'Developed Markets',
  'Emerging markets Funds': 'Emerging Markets',
  'Global Market Funds': 'Global Markets',
  'Global Market Bonds': 'Global Markets',
};

const tabMap = {
  'equityTab'   : 'equity',
  'bondTab'     : 'bonds',
  'thematicTab' : 'themes',
};

function* fetchEquityBatch(action) {
  try {
    if (action.payload) {
      const { query, tab } = action.payload;
      let { preEquityTickers, allStates } = action.payload;
      if(!preEquityTickers) {
        preEquityTickers = [];
      }

      let preTicks = [];
      let GLOBAL_FUNDS_PRESENT = false;

      // let allStates = yield select(getAllBuilPortfolioStates);
      let equityTab = JSON.parse(JSON.stringify(allStates[`${tab}`]));
      delete equityTab.data;

      // let preEquityTickers = allStates.preSelectedTickers.filter(pS => (pS.category === 'equity' || (pS.category === 'equity' && pS.sub_category === 'Global')));
      const totalPreTickersCount = allStates.preSelectedTickers.length;

      let topSliderValues = {};

      preEquityTickers.forEach((l, i) => {
        if (l.category === 'equity' && l.sub_category === 'Global Markets') {
          topSliderValues[l.sub_category] = 40; // initialize SLiderValue 40 for GLOBAL
          GLOBAL_FUNDS_PRESENT = true;
        }
      });

      preTicks = preTicks.concat(uniqValues(preEquityTickers, 'ticker'));
      preTicks = [...new Set(preTicks)].join(',');

      const requestPayload = GLOBAL_FUNDS_PRESENT ? { query, global: GLOBAL_FUNDS_PRESENT } : { query };

      let _profileState = yield select(state => state.profile.profile);
      let restricted_universe = (window.localStorage.getItem('restricted_universe') === 'true') || (_profileState && _profileState.restricted_universe) || false;
      if (restricted_universe) {
        requestPayload.skip_cache = true;
      }

      yield put(equityBatchFetch());
      let [ _result, _preFundResult ] = yield all([
        call(buildPortfolioBatchSearch, requestPayload),
        call(getFundDataCommaSeparated, preTicks)
      ]);

      _result = _result.filter(x => x.result.funds && x.result.funds.length > 0)

      let ticks = [];
      _result.forEach(e => {
        e.result = fixFundsData(e.result);
        ticks = ticks.concat(uniqValues(e.result.funds, 'ticker'));
      })
      ticks = [...new Set(ticks)].join(',');
      if(preTicks && preTicks != '')
        ticks += ',' + preTicks;

      const [ prices, ] = yield all([
        call(fetchRealTimePrices, { tickers: ticks }),
      ]);
      if(_preFundResult){
        _preFundResult = getFundsUpdatedPrice(prices, _preFundResult);
      }

      const tickersPresentInResult = preEquityTickers.filter(el => _preFundResult.funds.find((x) => x.ticker === el.ticker));
      const { tabHeaderMenu } = allStates;
      const equityHeader = tabHeaderMenu.find((x) => x.key === 'equityTab');
      let equityTabValue = equityHeader.sliderValue;
      _result.forEach((r,i) => {
        let { funds } = getFundsUpdatedPrice(prices, r.result.funds);
        let queryWillBe = r.query;
        if (dataMap[`${r.query}`]) {
          queryWillBe = dataMap[`${r.query}`];
        }
        let sssValue = 0;
        if(tickersPresentInResult && tickersPresentInResult.length > 0 && tickersPresentInResult[0].weight) {
          sssValue = _.sumBy(_.filter(tickersPresentInResult, e=> e.sub_category == queryWillBe), 'weight')
        }
        else if(tickersPresentInResult && tickersPresentInResult.length > 0 && _.countBy(tickersPresentInResult, 'sub_category')[queryWillBe]){
          sssValue = equityTabValue / Object.keys(_.countBy(tickersPresentInResult, 'sub_category')).length;
        }

        funds.map((q) => {
          q._sliderValue = 0;
        });

        r.query = queryWillBe;
        r.result.funds = funds;
        r.newQuery = r.query;
        r.forQuery = r.query;
        r.ticker = r.ticker || r.query;
        r.label = queryWillBe;
        r.locked = false;
        r.showInput = false;
        r.inputNumberDisabled = true;
        r.sliderEnabled = false;
        // r.sliderValue = equityHeader.sliderValue/_result.length;
        r.sliderValue = sssValue;
        r.position = i+1;
        r.sliderTooltipEnabled = false;
        r.customEditScreen = false;

        r.sliderValue = parseFloat(r.sliderValue);
        topSliderValues[r.query] = parseFloat(r.sliderValue);
      });

      let selectedFunds = {};
      let count = _.countBy(tickersPresentInResult, 'sub_category');
      // console.log('sid counnt', count)
      if(count['US']) count['USA'] = count['US'];

      tickersPresentInResult.forEach((e,i) => {
        if(_preFundResult.funds[i]){
          let originalWeight = _.find(tickersPresentInResult, el => el.ticker == _preFundResult.funds[i].ticker)
          if(e.sub_category == 'US') e.sub_category = 'USA';

          const eeeYY = originalWeight.weight || (topSliderValues[e.sub_category])/count[e.sub_category];

          _preFundResult.funds[i]._sliderValue = eeeYY || 0;

          if(selectedFunds[e.sub_category]){
            //push
            selectedFunds[e.sub_category].push(_preFundResult.funds[i]);
          }
          else{
            //set
            selectedFunds[e.sub_category] = [_preFundResult.funds[i]];
          }
        }
      });
      //set selectedFunds
      // console.log(selectedFunds);
      if (Object.keys(selectedFunds).length > 0) {
        _result.map((f) => {
          // console.log(f.query);
          if (selectedFunds[f.query]) {
            f.result.funds.map((g) => {
              if (g.ticker) {
                if (selectedFunds[f.query].find((c) => c.ticker === g.ticker)) {
                  g._sliderValue = selectedFunds[f.query].find((c) => c.ticker === g.ticker)._sliderValue;
                }
              }
            })
          }
        })
      }
      // console.log(_result);

      let newObject = {
        [`${tab}`]: equityTab,
      };
      newObject[`${tab}`].selectedFunds = selectedFunds;
      newObject[`${tab}`].data = _result;
      newObject.data = _result;

      // console.log('sid ticker 4', newObject)
      yield put(equityBatchSuccess({
        ...newObject,
      }));
    }
  } catch (error) {
    console.log('error ', error)
    // yield put(setInvestmentState({ message }))
  }
}

function* fetchBondsBatch(action) {
  try {
    if (action.payload) {
      const { query, tab } = action.payload;
      let { preBondTickers, allStates } = action.payload;
      if(!preBondTickers) {
        preBondTickers = [];
      }

      let preTicks = [];
      let GLOBAL_FUNDS_PRESENT = false;

      // const allStates = yield select(getAllBuilPortfolioStates);
      let bondTab = JSON.parse(JSON.stringify(allStates[`${tab}`]));
      delete bondTab.data;

      // let preBondTickers = allStates.preSelectedTickers.filter(pS => (pS.category === 'bonds' || (pS.category === 'bonds' && pS.sub_category === 'Global')));
      const totalPreTickersCount = allStates.preSelectedTickers.length;

      let topSliderValues = {};

      preBondTickers.forEach((l, i) => {
        if (l.category === 'bonds' && l.sub_category === 'Global Markets') {
          topSliderValues[l.sub_category] = 40; // initialize SLiderValue 40 for GLOBAL
          GLOBAL_FUNDS_PRESENT = true;
        }
      });

      preTicks = preTicks.concat(uniqValues(preBondTickers, 'ticker'));
      preTicks = [...new Set(preTicks)].join(',');

      const requestPayload = GLOBAL_FUNDS_PRESENT ? { query, global: GLOBAL_FUNDS_PRESENT } : { query };
      let _profileState = yield select(state => state.profile.profile);
      let restricted_universe = (window.localStorage.getItem('restricted_universe') === 'true') || (_profileState && _profileState.restricted_universe) || false;
      if (restricted_universe) {
        requestPayload.skip_cache = true;
      }

      yield put(bondsBatchFetch());
      let [ _result, _preFundResult ] = yield all([
        call(buildPortfolioBatchSearch, requestPayload),
        call(getFundDataCommaSeparated, preTicks)
      ]);

      _result = _result.filter(x => x.result.funds && x.result.funds.length > 0)

      let ticks = [];
      _result.forEach(e => {
        e.result = fixFundsData(e.result);
        ticks = ticks.concat(uniqValues(e.result.funds, 'ticker'));
      })
      ticks = [...new Set(ticks)].join(',');
      if(preTicks && preTicks != '')
        ticks += ',' + preTicks;

      const [ prices ] = yield all([
        call(fetchRealTimePrices, { tickers: ticks }),
      ]);

      if(_preFundResult){
        _preFundResult = getFundsUpdatedPrice(prices, _preFundResult);
      }

      const tickersPresentInResult = preBondTickers.filter(el => _preFundResult.funds.find((x) => x.ticker === el.ticker));
      const { tabHeaderMenu } = allStates;
      const bondHeader = tabHeaderMenu.find((x) => x.key === 'bondTab');
      let bondTabValue = bondHeader.sliderValue;
      _result.forEach((r,i) => {
        let { funds } = getFundsUpdatedPrice(prices, r.result.funds);
        r.query = r.query === 'Bonds' ? 'US Market Bonds' : r.query;
        let queryWillBe = r.query;
        if (dataMap[`${r.query}`]) {
          queryWillBe = dataMap[`${r.query}`];
        }

        let sssValue = 0;
        if(tickersPresentInResult && tickersPresentInResult.length > 0 && tickersPresentInResult[0].weight) {
          sssValue = _.sumBy(_.filter(tickersPresentInResult, e=> e.sub_category == queryWillBe), 'weight')
        }
        else if(tickersPresentInResult && tickersPresentInResult.length > 0 && _.countBy(tickersPresentInResult, 'sub_category')[queryWillBe]){
          sssValue = bondTabValue / Object.keys(_.countBy(tickersPresentInResult, 'sub_category')).length;
        }

        funds.map((q) => q._sliderValue = 0);

        r.query = queryWillBe;
        r.result.funds = funds;
        r.newQuery = r.query;
        r.forQuery = r.query;
        r.ticker = r.ticker || r.query;
        r.label = queryWillBe;
        r.locked = false;
        r.showInput = false;
        r.inputNumberDisabled = true;
        r.sliderEnabled = false;
        // r.sliderValue = bondHeader.sliderValue/_result.length;
        r.sliderValue = sssValue;
        r.position = i+1;
        r.sliderTooltipEnabled = false;
        r.customEditScreen = false;

        r.sliderValue = parseFloat(r.sliderValue);
        topSliderValues[r.query] = parseFloat(r.sliderValue);
      });

      let selectedFunds = {};
      let count = _.countBy(tickersPresentInResult, 'sub_category');
      if(count['US']) count['USA'] = count['US'];

      // console.log(tickersPresentInResult);

      tickersPresentInResult.forEach((e,i) => {
        if(_preFundResult.funds[i]){
          let originalWeight = _.find(tickersPresentInResult, el => el.ticker == _preFundResult.funds[i].ticker)
          if(e.sub_category == 'US') e.sub_category = 'USA';
          // console.log('sid ticker 3', originalWeight)
          // _preFundResult.funds[i]._sliderValue = originalWeight ? originalWeight.weight : (topSliderValues[e.sub_category])/count[e.sub_category];

          const eeeYY = originalWeight.weight || (topSliderValues[e.sub_category])/count[e.sub_category];

          _preFundResult.funds[i]._sliderValue = eeeYY || 0;

          if(selectedFunds[e.sub_category]){
            //push
            selectedFunds[e.sub_category].push(_preFundResult.funds[i]);
          }
          else{
            //set
            selectedFunds[e.sub_category] = [_preFundResult.funds[i]];
          }
        }
      });
      //set selectedFunds
      // console.log(selectedFunds);
      if (Object.keys(selectedFunds).length > 0) {
        _result.map((f) => {
          // console.log(f.query);
          if (selectedFunds[f.query]) {
            f.result.funds.map((g) => {
              if (g.ticker) {
                if (selectedFunds[f.query].find((c) => c.ticker === g.ticker)) {
                  g._sliderValue = selectedFunds[f.query].find((c) => c.ticker === g.ticker)._sliderValue;
                }
              }
            })
          }
        })
      }
      // console.log(_result);

      const newObject = {
        [`${tab}`]: bondTab,
      };
      newObject[`${tab}`].selectedFunds = selectedFunds;
      newObject[`${tab}`].data = _result;
      newObject.data = _result;

      // let tabHeader = allStates.tabHeaderMenu;
      // tabHeader.forEach((element,i) => {
      //   if(element.key == tab){
      //     tabHeader[i].sliderValue = bondTabValue
      //   }
      // });

      yield put(bondsBatchSuccess({
        ...newObject,
        // tabHeaderMenu: tabHeader
      }));
    }
  } catch (error) {
    console.log('error ', error)
    // yield put(setInvestmentState({ message }))
  }
}

function* searchNewQuery(action) {
  try {
    if (action.payload) {
      const { query, forTab, forQuery, q, mode } = action.payload;
      if (query && q) {

        if (forTab === 'thematicTab') {
          const thematicStates = yield select(getThematicTabStates);
          const currentThematicDataArr = JSON.parse(JSON.stringify(thematicStates.data));
          // console.log(currentThematicDataArr);
          const oldThematicSelectedFunds = JSON.parse(JSON.stringify(thematicStates.selectedFunds));
          // console.log(oldThematicSelectedFunds);

          currentThematicDataArr.forEach((r, i) => {
            // console.log(r.query,r.sliderValue);
            if (oldThematicSelectedFunds && Object.keys(oldThematicSelectedFunds) && Object.keys(oldThematicSelectedFunds).length > 0) {
              if (oldThematicSelectedFunds[r.query] && typeof oldThematicSelectedFunds[r.query] !== 'undefined' && (r.query === oldThematicSelectedFunds[r.query]) && oldThematicSelectedFunds[r.query].length > 0) {
                r.selected = true;
                r.result.funds.map((e) => {
                  oldThematicSelectedFunds[r.query].map((f) => {
                    if (e.ticker === f.ticker) {
                      f.sliderValue = e.sliderValue;
                    }
                  });
                });
              }
            }
          });
          // console.log(currentThematicDataArr.length);
          // console.log(oldThematicSelectedFunds);

          const newThematicStates = {
            ...thematicStates,
            selectedFunds: oldThematicSelectedFunds,
            data: currentThematicDataArr,
            selectedThemes: action.payload.themes || thematicStates.selectedThemes,
            noResultFound: false,
            loading: true,
          };

          yield put(searchForNewQueryFetch({
            thematicTab: newThematicStates,
          }));
        } else {
          const builderState = yield select(getAllBuilPortfolioStates);
          let _currentTabData = builderState[`${forTab}`].data;
          _currentTabData.forEach((v, index) => {
            if (v.query === forQuery) {
              v.loader = true;
            }
          });

          builderState[`${forTab}`] = {
            ...builderState[`${forTab}`],
            data: _currentTabData,
          };

          yield put(searchForNewQueryFetch({
            [`${forTab}`]: builderState[`${forTab}`],
          }));
        }

        let letQWillBe = q + ' within ' + forQuery + ' ' + tabMap[`${forTab}`];
        if (forTab === 'bondTab' && forQuery === 'USA') {
          // letQWillBe = q + ' within '+ tabMap[`${forTab}`];
        }
        if (forTab === 'thematicTab') {
          letQWillBe = q; //+' '+tabMap[`${forTab}`];
        }

        let requestPayload = {
          query,
          q: letQWillBe,
        };
        let _profileState = yield select(state => state.profile.profile);
        let restricted_universe = (window.localStorage.getItem('restricted_universe') === 'true') || (_profileState && _profileState.restricted_universe) || false;
        if (restricted_universe) {
          requestPayload.skip_cache = true;
        }

        let response = yield call(searchNewBatchQuery, requestPayload);
        if (response) {
          response = response.filter(x => x.result.funds && x.result.funds.length > 0);
          let ticks = [];
          response.forEach(e => {
            e.result = fixFundsData(e.result);
            ticks = ticks.concat(uniqValues(e.result.funds, 'ticker'));
          })
          ticks = [...new Set(ticks)].join(',');
          const [ prices ] = yield all([
            call(fetchRealTimePrices, { tickers: ticks }),
          ]);

          const allStates = yield select(getAllBuilPortfolioStates);
          const { tabHeaderMenu, thematicTab } = allStates;

          let currentData = allStates[`${forTab}`].data;
          let NOT_FOUD_ARR = [];
          let THEME_NEW_QUERY_WILL_BE = '';

          if (forTab === 'thematicTab') {
            const themesPresent = response.find(x => x.theme && x.theme !== '');
            if (!themesPresent || typeof themesPresent === 'undefined') {
              // when theme is empty
              console.log(`-------- ${q} NOT a Theme -------'`);
              thematicTab.noResultFound = true;
              THEME_NEW_QUERY_WILL_BE = q;
            } else {
              thematicTab.noResultFound = false;
              response.forEach((r,i) => {
                let { funds } = getFundsUpdatedPrice(prices, r.result.funds);
                let queryWillBe = r.theme || r.query;
                THEME_NEW_QUERY_WILL_BE = queryWillBe;
                funds.map((q) => q._sliderValue = 0);
                // mode === 'initial' then climate change query will be shown on funds list
                // and allocation chart doesnt show 'climate change' data.
                r.mode = mode;
                r.query = queryWillBe;
                r.result.funds = funds;
                r.newQuery = queryWillBe;
                r.forQuery = queryWillBe;
                r.ticker = r.ticker || queryWillBe;
                r.label = queryWillBe;
                r.sliderValue = 0;
                // r.selected = false;
              });

              currentData.forEach((f, i) => {
                if (f.query !== 'Climate Change' && f.mode !== 'initial') {
                  f.mode = mode;
                }
              });
            }

            const thematicHeader = tabHeaderMenu.find((x) => x.key === 'thematicTab');
            let selectedThemesFundsCounter = 0;

            Object.keys(thematicTab.selectedFunds).map((f) => {
              selectedThemesFundsCounter += 1;
            });

            let selectedThemesFunds = allStates['thematicTab'].selectedFunds;
            let updatedArray = [];

            if (Object.keys(selectedThemesFunds).length > 0 && (themesPresent || typeof themesPresent !== 'undefined')) {
              //Object.keys(selectedThemesFunds) looks like => ['Tech', 'Agricultural']
              currentData = Object.keys(selectedThemesFunds).map((v) => {
                // 'v' could be 'Tech' from => ['Tech', 'Agricultural']
                if (selectedThemesFunds[v] && selectedThemesFunds[v].length) {
                  // selectedThemesFunds[v] => 'Tech': [{...},]
                  // if 'thematicTab.selectedFunds' has data then
                  if (currentData.length > 0) {
                    const found = currentData.find((g) => g.query === v);
                    if (found && typeof found !== 'undefined') {
                      found.selected = true;
                      //after finding return only those data as a new 'CurrentData'
                      return found;
                    }
                  }
                } else if (typeof selectedThemesFunds[v] !== 'undefined' && selectedThemesFunds[v].length === 0) {
                  if (currentData.length > 0) {
                    if (currentData.filter((g) => g.query !== v) && currentData.filter((g) => g.query !== v).length > 0) {
                      currentData.forEach((gV, i) => {
                        if (gV.query !== v) {
                          gV.selected = false;
                          delete gV.selected;
                        }
                      });
                    }
                  }
                }
              }).filter((k) => typeof k !== 'undefined');

              // console.log(currentData);

              updatedArray = [...currentData, ...response];

              // console.log('updatedArray----->>', updatedArray);

              if ((themesPresent || typeof themesPresent !== 'undefined')) {
                updatedArray = [...currentData, ...response];
              } else {
                // do NOT include 'empty theme' in thematicTab.data.
                updatedArray = [...currentData];
              }

              updatedArray = _.uniqBy(updatedArray, 'query');
              // console.log(updatedArray);

              // updatedArray.forEach((r) => {
              //   // thematic tab sliderValue divided among its selected themes.
              //   // for.e.g: sliderValue is 20 and themes are ['Tech', 'Agricultural']
              //   r.sliderValue = thematicHeader.sliderValue/updatedArray.length;
              //   r.sliderValue = parseFloat(r.sliderValue.toFixed(1));
              // });

              // if (updatedArray.filter((n) => (typeof n.selected !== 'undefined' && n.selected)).length > 0) {
              //   // updatedArray.filter((n) => (typeof n.selected !== 'undefined' && n.selected)).forEach((p) => {
              //   //   if (p) {
              //   //     p.sliderValue = thematicHeader.sliderValue/updatedArray.filter((d) => (typeof d.selected !== 'undefined' && d.selected)).length;
              //   //   }
              //   // });
              //
              //   // console.log(updatedArray);
              // }

              console.log(updatedArray);
            } else {
              // since no fund were selected for thematic.. Do NOT calculate sliderValue for climate change query;
              // console.log(currentData);
              let climateChangeObj = [];
              if (currentData.length > 0 && q !== 'Climate Change') {
                climateChangeObj = currentData.filter((d) => d.query === 'Climate Change');
                currentData = currentData.filter((d) => d.query !== 'Climate Change');
                // console.log('currentData----->> without climate change', currentData);
              }

              if ((themesPresent || typeof themesPresent !== 'undefined')) {
                // console.log(response);
                updatedArray = [...currentData, ...response];
              } else {
                // do NOT include 'empty theme' in thematicTab.data.
                updatedArray = [...currentData];
              }

              updatedArray = _.uniqBy(updatedArray, 'query');
              // console.log(updatedArray);

              // updatedArray.forEach((r) => {
              //   // thematic tab sliderValue divided among its selected themes.
              //   // for.e.g: sliderValue is 20 and themes are ['Tech', 'Agricultural']
              //   r.sliderValue = thematicHeader.sliderValue/updatedArray.length;
              //   r.sliderValue = parseFloat(r.sliderValue.toFixed(1));
              // });

              // if (updatedArray.filter((n) => (typeof n.selected !== 'undefined' && n.selected)).length > 0) {
              //   // updatedArray.filter((n) => (typeof n.selected !== 'undefined' && n.selected)).forEach((p) => {
              //   //   if (p) {
              //   //     p.sliderValue = thematicHeader.sliderValue/updatedArray.filter((d) => (typeof d.selected !== 'undefined' && d.selected)).length;
              //   //   }
              //   // });
              //
              //   console.log(updatedArray);
              //
              //   // console.log(updatedArray);
              //   // updatedArray = _.uniqBy([...updatedArray, ...newS], 'query'); // do not change this!
              // }

              console.log(updatedArray);
              updatedArray = [...updatedArray, ...climateChangeObj];
            }

            // console.log(updatedArray);
            updatedArray = _.uniqBy(updatedArray, 'query');

            // console.log(updatedArray);

            updatedArray.forEach((r, i) => {
              r.locked = false;
              r.showInput = false;
              r.inputNumberDisabled = true;
              r.sliderEnabled = false;
              r.sliderTooltipEnabled = false;
              r.customEditScreen = false;
              r.position = i+1;
              r.loading = false;
            });

            const newObject = {
              ...(yield select(getThematicTabStates)),
              data: updatedArray,
              noResultFound: thematicTab.noResultFound,
              newQuery: THEME_NEW_QUERY_WILL_BE,
            }

            yield put(searchForNewQuerySuccess({
              thematicTab: newObject,
            }));

          } else {
            currentData.forEach((v, index) => {
              if (v.query === forQuery) {
                // if new searched query result found
                if (response && response.length > 0 && response[0].result) {
                  const res = response[0].result;
                  v.result = res;
                } else {
                  // if new searched query result NOT found
                  v.result = [];
                }
                v.forQuery = forQuery;
                v.newQuery = q;//response[0].query;
                v.loading = false;
              }
            });

            currentData = _.uniqBy(currentData, 'query');

            allStates[`${forTab}`] = {
              ...allStates[`${forTab}`],
              data: currentData,
              query: q,
              forQuery,
            };

            const newObject = {
              [`${forTab}`]: allStates[`${forTab}`],
            };

            yield put(searchForNewQuerySuccess({
              ...newObject,
            }));
          }
        } else {
          throw ' -- no response -- '
        }
      }
    }
  } catch (error) {
    console.log('error ', error)
    // yield put(setInvestmentState({ message }))
  }
}

function* fetchThematicBatch(action) {
  const { payload } = action;
  try {
    if (payload) {
      const { query, themes, mode } = payload;
      console.log('---------------------- MOde ----------------------', mode);
      console.log(themes);
      console.log('--------------------------------------------------');
      if (query && themes) {

        const thematicStates = yield select(getThematicTabStates);

        const newThematicStates = {
          ...thematicStates,
          selectedThemes: themes,
          noResultFound: false,
        };

        yield put(thematicBatchFetch({
          thematicTab: newThematicStates,
        }));

        const q = themes.join() || "Climate Change";

        let requestPayload = {
          q,
          query,
        };
        let _profileState = yield select(state => state.profile.profile);
        let restricted_universe = (window.localStorage.getItem('restricted_universe') === 'true') || (_profileState && _profileState.restricted_universe) || false;
        if (restricted_universe) {
          requestPayload.skip_cache = true;
        }

        let [ _result ] = yield all([
          call(buildPortfolioBatchSearch, requestPayload)
        ]);

        if (_result) {
          _result = _result.filter(x => x.result.funds && x.result.funds.length > 0)

          let ticks = [];

          _result.forEach(e => {
            e.result = fixFundsData(e.result);
            ticks = ticks.concat(uniqValues(e.result.funds, 'ticker'));
          })
          ticks = [...new Set(ticks)].join(',');

          const [ prices ] = yield all([
            call(fetchRealTimePrices, { tickers: ticks }),
          ]);

          const allStates = yield select(getAllBuilPortfolioStates);
          const { tabHeaderMenu, thematicTab } = allStates;
          const thematicHeader = tabHeaderMenu.find((x) => x.key === 'thematicTab');
          let selectedThemesFundsCounter = 0;

          Object.keys(thematicTab.selectedFunds).map((f) => {
            selectedThemesFundsCounter += 1;
          });

          _result.forEach((r,i) => {
            let { funds } = getFundsUpdatedPrice(prices, r.result.funds);
            let queryWillBe = r.query;

            if (dataMap[`${r.query}`]) {
              queryWillBe = dataMap[`${r.query}`];
            }

            // console.log('-------- queryWillBe --------', queryWillBe);

            // mode === 'initial' then climate change query will be shown on funds list
            // and allocation chart doesnt show 'climate change' data.
            r.mode = (mode === 'initial' && queryWillBe === 'Climate Change') ? 'initial' : 'new';

            r.query = queryWillBe;
            r.result.funds = funds;
            r.newQuery = r.query;
            r.forQuery = r.query;
            r.ticker = r.ticker || r.query;
            r.label = queryWillBe;
            // r.selected = false;
          });

          let currentData = allStates['thematicTab'].data;
          let selectedThemesFunds = allStates['thematicTab'].selectedFunds;
          let updatedArray = [];

          console.log(selectedThemesFunds);
          // console.log(currentData);

          if (Object.keys(selectedThemesFunds).length > 0) {
            //Object.keys(selectedThemesFunds) looks like => ['Tech', 'Agricultural']
            currentData = Object.keys(selectedThemesFunds).map((v) => {
              // 'v' could be 'Tech' from => ['Tech', 'Agricultural']
              if (selectedThemesFunds[v] && selectedThemesFunds[v].length) {
                // selectedThemesFunds[v] => 'Tech': [{...},]
                // console.log(selectedThemesFunds[v]);
                // if 'thematicTab.selectedFunds' has data then
                if (currentData.length > 0) {
                  // find selectedFuds data from 'thematicTab.data' [] array.
                  const found = currentData.find((g) => g.query === v);
                  if (found && typeof found !== 'undefined') {
                    //after finding return only those data as a new 'CurrentData'
                    found.selected = true;
                    return found;
                  }
                } else if (_result.length > 0) {
                  // find selectedFuds data from '_result' [] array.
                  const found = _result.find((g) => g.query === v);
                  if (found && typeof found !== 'undefined') {
                    //after finding return only those data as a new 'CurrentData'
                    let sumOfWeight = 0;
                    selectedThemesFunds[v].forEach((sW, i) => {
                      console.log(sW.ticker, sW._sliderValue);
                      sumOfWeight += sW._sliderValue;
                    });
                    found.sliderValue = sumOfWeight;
                    found.selected = true;
                    return found;
                  }
                }
              } else if (typeof selectedThemesFunds[v] !== 'undefined' && selectedThemesFunds[v].length === 0) {
                if (currentData.length > 0) {
                  if (currentData.filter((g) => g.query !== v) && currentData.filter((g) => g.query !== v).length > 0) {
                    currentData.forEach((gV, i) => {
                      if (gV.query !== v) {
                        gV.selected = false;
                        delete gV.selected;
                      }
                    });
                  }
                }
              }
            }).filter((k) => typeof k !== 'undefined');

            // console.log('currentData----->>', currentData);

            // updatedArray = [...currentData, ..._result];
            updatedArray = [...currentData];
            console.log(updatedArray);
            // updatedArray.forEach((r) => {
            //   // thematic tab sliderValue divided among its selected themes.
            //   // for.e.g: sliderValue is 20 and themes are ['Tech', 'Agricultural']
            //   r.sliderValue = thematicHeader.sliderValue/updatedArray.length;
            //   r.sliderValue = parseFloat(r.sliderValue.toFixed(1));
            // });
            if (updatedArray.filter((n) => (typeof n.selected !== 'undefined' && n.selected)).length > 0) {
              // updatedArray.filter((n) => (typeof n.selected !== 'undefined' && n.selected)).forEach((p) => {
              //   if (p) {
              //     p.sliderValue = thematicHeader.sliderValue/updatedArray.filter((d) => (typeof d.selected !== 'undefined' && d.selected)).length;
              //   }
              // });
              // console.log(updatedArray);
              // updatedArray = _.uniqBy([...updatedArray, ...newS], 'query'); // do not change this!
            }
          } else {
            // since no fund were selected for thematic.. Do NOT calculate sliderValue for climate change query;
            let climateChangeObj = [];
            if (currentData.length > 0) {
              climateChangeObj = currentData.filter((d) => d.query === 'Climate Change');
              // console.log('currentData----->> without climate change', currentData);
              currentData = currentData.filter((d) => d.query !== 'Climate Change');
            }
            // console.log(currentData);
            updatedArray = [...currentData, ..._result];
            updatedArray.forEach((r) => {
              // thematic tab sliderValue divided among its selected themes.
              // for.e.g: sliderValue is 20 and themes are ['Tech', 'Agricultural']
              r.sliderValue = thematicHeader.sliderValue/updatedArray.length;
              r.sliderValue = parseFloat(r.sliderValue.toFixed(2));
            });
            if (updatedArray.filter((n) => (typeof n.selected !== 'undefined' && n.selected)).length > 0) {
              // updatedArray.filter((n) => (typeof n.selected !== 'undefined' && n.selected)).forEach((p) => {
              //   if (p) {
              //     p.sliderValue = thematicHeader.sliderValue/updatedArray.filter((d) => (typeof d.selected !== 'undefined' && d.selected)).length;
              //   }
              // });
              console.log(updatedArray);
              // updatedArray = _.uniqBy([...updatedArray, ...newS], 'query'); // do not change this!
            }
            updatedArray = [...updatedArray, ...climateChangeObj];
          }

          // console.log(updatedArray);
          updatedArray = _.uniqBy(updatedArray, 'query');

          updatedArray.forEach((r, i) => {
            r.locked = false;
            r.showInput = false;
            r.inputNumberDisabled = true;
            r.sliderEnabled = false;
            r.sliderTooltipEnabled = false;
            r.customEditScreen = false;
            r.position = i+1;
          });

          const newObject = {
            ...(yield select(getThematicTabStates)),
            data: updatedArray,
            newQuery: themes[0],
          }

          yield put(thematicBatchSuccess({
            thematicTab: newObject,
          }));
        }
      }
    }
  } catch (error) {
    console.log('error ', error)
    // yield put(setInvestmentState({ message }))
  }
}

function* getPreSelectedThematicTickersData(action) {
  const { payload } = action;
  try {
    if (payload) {
      const { query, preThematicTickers, allStates } = payload;
      if (query && preThematicTickers) {
        let selectedFunds = {}, topSliderValues = {};

        const { tabHeaderMenu, thematicTab } = allStates;
        const { allocationChartData } = thematicTab;
        const count = _.countBy(preThematicTickers, 'sub_category');
        let selectedThemes = _.uniqBy(uniqValues(preThematicTickers, 'sub_category'));
        yield put(preSelectedThematicTickersFetch({
          thematicTab: {
            ...thematicTab,
            selectedThemes,
          }
        }));

        let preTicks = [];

        // let preEquityTickers = allStates.preSelectedTickers.filter(pS => (pS.category === 'equity' || (pS.category === 'equity' && pS.sub_category === 'Global')));

        preTicks = preTicks.concat(uniqValues(preThematicTickers, 'ticker'));
        preTicks = [...new Set(preTicks)].join(',');

        let [ _preFundResult ] = yield all([
          call(getFundDataCommaSeparated, preTicks)
        ]);

        if (_preFundResult) {
          const [ prices ] = yield all([
            call(fetchRealTimePrices, { tickers: preTicks }),
          ]);

          if(_preFundResult){
            _preFundResult = getFundsUpdatedPrice(prices, _preFundResult);
          }

          const tickersPresentInResult = preThematicTickers.filter(el => _preFundResult.funds.find((x) => x.ticker === el.ticker));
          const thematicHeader = tabHeaderMenu.find((x) => x.key === 'thematicTab');
          // thematic tab sliderValue divided among its selected themes.
          // for.e.g: sliderValue is 20 and themes are ['Tech', 'Agricultural']
          let countSelectedThemes = 0;
          selectedThemes.forEach((p, i) => {
            countSelectedThemes +=1;
            topSliderValues[p] = thematicHeader.sliderValue/selectedThemes.length;
          });

          let foundAllocationChartData = [];

          preThematicTickers.forEach((e,i) => {
            if(_preFundResult.funds[i]){
              let originalWeight = _.find(tickersPresentInResult, el => el.ticker == _preFundResult.funds[i].ticker)

              _preFundResult.funds[i]._sliderValue = originalWeight.weight || (topSliderValues[e.sub_category])/count[e.sub_category] || 0;
               console.log('_preFundResult.funds[i]._sliderValue ------>', _preFundResult.funds[i]._sliderValue);

              if(selectedFunds[e.sub_category]){
                //push
                selectedFunds[e.sub_category].push(_preFundResult.funds[i]);
              }
              else{
                //set
                selectedFunds[e.sub_category] = [_preFundResult.funds[i]];
              }

            }
          });

          selectedThemes = selectedThemes.length > 0 ? [...selectedThemes] : [...selectedThemes, 'Climate Change'];

          console.log(selectedThemes);

          // console.log(foundAllocationChartData);
          // console.log(allocationChartData);
          //set selectedFunds and selectedThemes
          allStates[`thematicTab`] = {
            ...allStates[`thematicTab`],
            selectedFunds,
            selectedThemes, //['Climate Change'], // default load
            allocationChartData,
            chooseThemeButtonToggle: !(Object.keys(selectedFunds).length > 0),
          };

          console.log(allStates[`thematicTab`]);

          const newObject = {
            [`thematicTab`]: allStates[`thematicTab`],
          };

          yield put(preSelectedThematicTickersSuccess({
            ...newObject,
          }));
        }
      }
    }
  } catch (error) {
    console.log('error ', error)
    // yield put(setInvestmentState({ message }))
  }
}

function* fetchCustomTicker(action) {
  try{
    yield put(customTickerFetch());
    const result = yield call(getAllTickers);
    if (result) {
      const tickerList = result.map((b) => {
        return b.ticker;
      });
      yield put(customTickerSuccess({
        tickerList,
      }));
    }
  }
  catch (error) {
    console.log(error)
  }
}

function* getCustomTickerData(action) {
  const { payload } = action;
  try{
    if (action) {
      const { searchedTicker, forTicker, forQuery, tab } = payload;
      if (searchedTicker) {
        yield put(tickerDataFetch());
        const response = yield call(getFundData, { ticker: searchedTicker });
        if (response && typeof response.result !== 'undefined' && response.result.funds) {

          let fundsArray = response.result.funds;
          let ticks = [];

          ticks = ticks.concat(uniqValues(fundsArray, 'ticker'));
          ticks = [...new Set(ticks)].join(',');

          const [ prices ] = yield all([
            call(fetchRealTimePrices, { tickers: ticks }),
          ]);

          const { funds } = getFundsUpdatedPrice(prices, fundsArray);
          const allStates = yield select(getAllBuilPortfolioStates);
          const data = allStates[`${tab}`].data;

          data.forEach((item) => {
            if (item.ticker === forTicker && item.query === forQuery) {
              item.customSearchTicker = searchedTicker;
              item.forQuery = forQuery;
              item.forTicker = forTicker;
              item.customSearchTickerData = {
                response: {
                  ...response,
                },
                dataMappedArray: funds,
              };
            }
          });

          const newData = {
            ...allStates[`${tab}`],
            data,
          };

          const newObject = {
            [`${tab}`]: newData,
          };

          yield put(tickerDataSuccess({
            ...newObject,
          }));

        }
      } else {
        throw 'Ticker is missing';
      }
    }
  }
  catch (error) {
    console.log(error)
  }
}

function* getThemes(action) {
  try {
    yield put(getThemesFetch());
    let themes = yield call(getAllThemes);
    if (themes) {
      const thematicStates = yield select(getThematicTabStates);
      themes = _.uniqBy(themes, (e) => e);

      const filteredThemes = themes.map((u, ui) => {
        let img = ''
        try {
          const images = require.context('../../public/themes', true);
          if (images('./' + u + '.png')) {
            return u;
          } else {
            throw u;
          };
        } catch (err) {
          return
        }
      }).filter(f => (f && typeof f !== 'undefined'));

      const newObject = {
        ...thematicStates,
        themes: filteredThemes.filter(f => (f && typeof f !== 'undefined')),
      }

      yield put(getThemesSuccess({
        thematicTab: newObject,
      }));
    }

  } catch (error) {
    console.log('error ', error)
    // yield put(setInvestmentState({ message }))
  }
}

function* saveUserPortfolio(action) {
  try{
    yield put(finalizeSavePortfolioFetch());
    const result = yield call(savePortfolio, action.payload);
    if (result) {
      yield put(customTickerSuccess({
        savedPortfolio: result,
        portfolioName: result.portfolio_name,
        portfolio_name: result.portfolio_name,
      }));
    }
  }
  catch (error) {
    console.log(error.response)
    yield put(customTickerSuccess({
      savedPortfolio: error.response.data
    }));
  }
}

function* fetchPortfolio(action){
  try{
    let result = null;
    if(action.tickerArr){
      result = action.tickerArr;
    }else{
      yield put(fetchPortfolioFetch());
      result = yield call(fetchPortfolioData, action.payload.data);
    }
    if(result){
      let fundsArray = [], noWeight = false;
      result.forEach(data => {
        if((data.category && data.category !== '') && (data.sub_category && data.sub_category !== '')){
          if(!data.weight) noWeight = true;
          console.log('sid ankit', data.ticker, data.category, data.sub_category)
          fundsArray.push(data);
        }
      });
      fundsArray.forEach(fund => {
        // if(fund.category === 'equity' && fund.sub_category === 'USA') {fund.color = '#074D83'}
        // else if(fund.category === 'equity' && fund.sub_category === 'Developed Markets') {fund.color = '#0E78C9'}
        // else if(fund.category === 'equity' && fund.sub_category === 'Emerging Markets') {fund.color = '#37A5FA'}
        // else if(fund.category === 'bonds' && fund.sub_category === 'USA') {fund.color = '#67B9F0'}
        // else if(fund.category === 'bonds' && fund.sub_category === 'Developed Markets') {fund.color = '#7BCAFF'}
        // else if(fund.category === 'bonds' && fund.sub_category === 'Emerging Markets') {fund.color = '#AAEBFE'}
        // else if(fund.category === 'themes') {fund.color = '#2F94E6'}
        if(fund.category === 'equity') {fund.color = '#DEF1FF'}
        else if(fund.category === 'bonds') {fund.color = '#E0E0E0'}
        else if(fund.category === 'themes') {fund.color = '#C0E1FA'}
      })

      const allStates = yield select(getAllBuilPortfolioStates);
      const totalPreTickersCount = fundsArray.length;
      const preThematicTickers = fundsArray.filter(e => e.category === 'themes');
      const preBondTickers = fundsArray.filter(e => e.category === 'bonds');
      const preEquityTickers = fundsArray.filter(e => e.category === 'equity');

      let equityTabValue = 0, themeTabValue = 0, bondTabValue = 0;

      if(noWeight){
        if(preEquityTickers.length > 0 && preBondTickers.length > 0 && preThematicTickers.length > 0) {
          // 54:36:10
          equityTabValue = 54;
          bondTabValue = 36;
          themeTabValue = 10;
        }
        else if(preEquityTickers.length > 0 && preBondTickers.length > 0 && preThematicTickers.length <= 0) {
          // 60 40
          equityTabValue = 60;
          bondTabValue = 40;
          themeTabValue = 0;
        }
        else if(preEquityTickers.length > 0 && preBondTickers.length <= 0 && preThematicTickers.length > 0) {
          // 90: 10
          equityTabValue = 90;
          bondTabValue = 0;
          themeTabValue = 10;
        }
        else if(preEquityTickers.length <= 0 && preBondTickers.length > 0 && preThematicTickers.length > 0) {
          // 90: 10
          equityTabValue = 0;
          bondTabValue = 90;
          themeTabValue = 10;
        }
        else if(preEquityTickers.length > 0 && preBondTickers.length <= 0 && preThematicTickers.length <= 0) {
          // 100
          equityTabValue = 100;
          bondTabValue = 0;
          themeTabValue = 0;
        }
        else if(preEquityTickers.length <= 0 && preBondTickers.length > 0 && preThematicTickers.length <= 0) {
          // 100
          equityTabValue = 0;
          bondTabValue = 100;
          themeTabValue = 0;
        }
        else if(preEquityTickers.length <= 0 && preBondTickers.length <= 0 && preThematicTickers.length > 0) {
          // 100
          equityTabValue = 0;
          bondTabValue = 0;
          themeTabValue = 100;
        }
      }

      //equity
      if(preEquityTickers && preEquityTickers.length > 0 && !preEquityTickers[0].weight) {
        // equityTabValue = (preEquityTickers.length / totalPreTickersCount) * 100;
        equityTabValue = equityTabValue;
      }
      else if(preEquityTickers && preEquityTickers.length > 0 && preEquityTickers[0].weight) {
        preEquityTickers.forEach(item => {
          equityTabValue += item.weight;
        });
      }
      //bond
      if(preBondTickers && preBondTickers.length > 0 && !preBondTickers[0].weight) {
        // bondTabValue = (preBondTickers.length / totalPreTickersCount) * 100;
        bondTabValue = bondTabValue;
      }
      else if(preBondTickers && preBondTickers.length > 0 && preBondTickers[0].weight) {
        preBondTickers.forEach(item => {
          bondTabValue += item.weight;
        });
      }
      //theme
      if(preThematicTickers && preThematicTickers.length > 0 && !preThematicTickers[0].weight) {
        // themeTabValue = (preThematicTickers.length / totalPreTickersCount) * 100;
        themeTabValue = themeTabValue;
      }
      else if(preThematicTickers && preThematicTickers.length > 0 && preThematicTickers[0].weight) {
        preThematicTickers.forEach(item => {
          themeTabValue += item.weight;
        });
      }

      let tabHeader = allStates.tabHeaderMenu;
      tabHeader.forEach((element,i) => {
        if(element.key == 'equityTab'){
          tabHeader[i].sliderValue = equityTabValue;
        }
        if(element.key == 'bondTab'){
          tabHeader[i].sliderValue = bondTabValue;
        }
        if(element.key == 'thematicTab'){
          tabHeader[i].sliderValue = themeTabValue;
        }
      });
      // console.log(tabHeader);
      yield put(fetchPortfolioSuccess({
        preSelectedTickers: fundsArray,
        onboardData: {
          onboardFrom: action.payload.path,
          selectedPortfolioFor: action.payload.selectedPortfolioFor,
        },
        tabHeaderMenu: tabHeader,
        noResult:result.length === 0
      }));

      // yield put(setBuildPortfolioState({onboardData: {onboardFrom: '', selectedPortfolioFor: action.payload.selectedPortfolioFor}}))
    }
  }
  catch(error){
    console.log(error);
    yield put(fetchPortfolioSuccess({
      tickerFetchError:'The Selected Model does not have any holdings.'
    }));
  }
}

function* editPortfolioName(action) {
  try{
    yield call(editPortfolio, action.payload);
  }catch(error){
    console.log(error);
    yield put(setBuildPortfolioState({
      savedPortfolio: {
        ...action.payload,
        message: error.response.data.message
      }
    }));
  }
}

function* uploadBuilderPortfolioCall(data){
  const {payload, callback} = data;
  try{
    console.log('here saga', payload);
    const result = yield call(uploadBuilderPortfolio, payload);
    yield call(fetchPortfolio, {tickerArr: result, payload:{selectedPortfolioFor: '', path: '/scratch'}});
  }catch(error){
    console.log(error);
    // yield put(setBuildPortfolioState({

    // }))
  }
}

function* getAllTickersValidate(action) {
  try{
    if (action.payload) {
      // console.log(action.payload);
      const { tickers } = action.payload;
      if (tickers) {
        const portState = yield select(getAllBuilPortfolioStates);
        yield put(checkBuilderTickerFetch());
        const response = yield call(checkTickerExist, { tickers });
        // console.log(response);
        if (response) {
          if (response.tickers_not_exist) {
            console.log('here saga', response)
            yield put(checkBuilderTickerSuccess({
              tickerNotExist: response.tickers_not_exist,
            }));
          } else {
            yield put(checkBuilderTickerSuccess({
              error: '`tickers_not_exist` not present in response',
            }));
            throw '------ `tickers_not_exist` not present in response -------'
          }

          if (action.callback && typeof action.callback == 'function') {
            action.callback();
          }
        } else {
          yield put(checkBuilderTickerSuccess({
            error: 'failed to response',
          }));
          throw '------ failed to response -------'
        }
      } else {
        console.log('---- NO PAYLOAD -----');
        yield put(checkBuilderTickerSuccess({
          error: '---- NO PAYLOAD -----',
        }));
      }
    } else {
      console.log('---- NO PAYLOAD -----');
    }
  }
  catch (error) {
    console.log(error)
  }
}

function* downloadBuilderPDF(action) {
  console.log('yo')
  console.log(action)
  const { payload } = action;
  try {
    if (payload) {
      yield put(
        setInvestmentState({
          reportLoader: true,
        })
      );

      const currDate = moment().format('MMMM YYYY');
      const reportName = 'Builder Proposal – '+currDate
      const entity = payload.portfolioName
      const section = 'Builder'
      const report_type = 'Builder Proposal'

      const response = yield call(downloadBuilderReport, {
        reports: JSON.stringify(payload),
        reportName,
        entity,
        section,
        report_type,
      });
      if (response) {
        // console.log('!!!!! REPORT API RESPONSE !!!!!!');
        // console.log(response);
        const newBlob = new Blob([response.data], { type: response.headers['content-type'] });
        // const firstName = yield select(state => state.auth.user.firstName) || '';
        // const lastName = yield select(state => state.auth.user.lastName) || '';
        // const fullName = firstName + ' ' + lastName;
        FileSaver.saveAs(newBlob, 'Builder Proposal – '+currDate+'.pdf');

        yield put(
          setInvestmentState({
            reportPDFDownloadStatus: {
              message: 'PDF Downloaded successfully',
              downloadStatus: 'success',
            },
          })
        );
        // yield put(setPortfolioState({ enhanceReplace: [], enhanceTickerFilter: [], resultSet: [], filterResultSet: [] }))
      }
      yield put(
        setInvestmentState({
          reportLoader: false,
        })
      );
    }
  } catch (error) {
    const errorDetails = get(error, 'response.data', error.stack)
    console.log(errorDetails)
    yield put(
      setInvestmentState({
        reportLoader: false,
        reportPDFDownloadStatus: {
          message: 'Failed to download Report PDF file. Please email us at: help@magnifi.com',
          downloadStatus: 'danger',
        },
      })
    );
    // yield put(actions.failedToPerform(errorDetails))
  }
}

function* deletePortfolio(data) {
  const { payload, callback } = data;
  try {
    if (payload) {
      yield put(deletePortfolioBuilderByIdFetch());
      const response = yield call(deletePortfolioAPI, payload);
      if (response.message === "File deleted successfully") {
        yield put(getPortfolioListBuilderRequest());
        yield put(deletePortfolioBuilderByIdSuccess());
      }
      if (callback && typeof callback === 'function') {
        callback();
      }
    } else {
      throw '-- payload is missing --'
    }
  } catch (error) {
    if (error.stack) {
      const errorDetails = get(error, 'response.data', error.stack)
      console.log(errorDetails)
      yield put(deletePortfolioBuilderByIdSuccess({
        pageCrash: errorDetails
      }));
      // yield put(actions.failedToPerform(errorDetails))
    } else {
      console.log(error)
      yield put(deletePortfolioBuilderByIdSuccess({
        pageCrash: error
      }));
    }
  }
}

function* getPortfolioListData(action) {
  try {
    yield put(getPortfolioListFetch());
    const response = yield call(fetchPortfolioList);
    if (response) {
      yield put(getPortfolioListSuccess({
        portfolioList: response,
      }));
    } else {
      yield put(getPortfolioListSuccess({
        portfolioList: [],
      }));
    }
  }
  catch (error) {
    console.log(error)
    yield put(getPortfolioListSuccess({
      portfolioList: [],
      pageCrash: 'Failed to load query.',
    }));
  }
}

function* customWeightInput(data){
  let {payload} = data

  const allStates = yield select(getAllBuilPortfolioStates);
  console.log(payload,allStates)
  let {thematicTab,equityTab,bondTab} = allStates
  let thematicTabJ = JSON.parse(JSON.stringify(thematicTab))
  let equityTabJ = JSON.parse(JSON.stringify(equityTab))
  let bondTabJ = JSON.parse(JSON.stringify(bondTab))
  //
  // let allTickersSum = 100 - payload.ticker._sliderValue
  if(Object.keys(equityTabJ.selectedFunds).length){
    //equityTab
    equityTabJ._isCustomInput = true
    //loop over categories (usa,dm,em)
    for (let i = 0; i < Object.keys(equityTabJ.selectedFunds).length; i++) {
      //tickerArray => selected funds of usa,em,dm
      if (equityTabJ.selectedFunds[Object.keys(equityTabJ.selectedFunds)[i]].length > 1) {
        let diff = payload.value - payload.ticker._sliderValue

        let isCurrentCatRight = false

        let tickerArray = equityTabJ.selectedFunds[ Object.keys(equityTabJ.selectedFunds)[i]]

        tickerArray.forEach(item=>{
          if (item.ticker === payload.ticker.ticker) {
            isCurrentCatRight = true
          }
        })
        console.log(diff)

        let allSum = 0
        let maxValue = 100
        //calculate all sum
        if (isCurrentCatRight) {
          tickerArray.forEach(item=>{
            allSum=allSum+item._sliderValue
            if (item.ticker === payload.ticker.ticker) {
              allSum=allSum-item._sliderValue
              // item._isCustomInput = true
            }
          })
          equityTabJ.data.forEach(item=>{
            if(item.query==Object.keys(equityTabJ.selectedFunds)[i]){
              maxValue = item.sliderValue
            }
          })
        }
        console.log(allSum,maxValue)
        if(allSum!==0 && payload.value <= maxValue && isCurrentCatRight){
          //calculate ratio
            tickerArray.forEach(item=>{
              if (item.ticker !== payload.ticker.ticker) {
                item.w_ratio = item._sliderValue/allSum
                console.log(item.w_ratio)
              }
            })

          //assign new weights
            tickerArray.forEach(item=>{
              if (item.ticker !== payload.ticker.ticker) {
                item._sliderValue = item._sliderValue-(diff*item.w_ratio)
                // item._isCustomInput = true
              }
              if (item.ticker === payload.ticker.ticker) {
                item._sliderValue = payload.value
                // item._isCustomInput = true
              }
            })
        }

      }
    }
  }

  if(Object.keys(thematicTabJ.selectedFunds).length){
    //thematicTab
    thematicTabJ._isCustomInput = true
    for (let i = 0; i < Object.keys(thematicTabJ.selectedFunds).length; i++) {
      //tickerArray => selected funds of usa,em,dm
      if (thematicTabJ.selectedFunds[Object.keys(thematicTabJ.selectedFunds)[i]].length > 1){
        let diff = payload.value - payload.ticker._sliderValue

        let isCurrentCatRight = false

        let tickerArray = thematicTabJ.selectedFunds[ Object.keys(thematicTabJ.selectedFunds)[i]]

        tickerArray.forEach(item=>{
          if (item.ticker === payload.ticker.ticker) {
            isCurrentCatRight = true
          }
        })

        let allSum = 0
        let maxValue = 100
        //calculate all sum
        if (isCurrentCatRight) {
          tickerArray.forEach(item=>{
            allSum=allSum+item._sliderValue
            if (item.ticker === payload.ticker.ticker) {
              allSum=allSum-item._sliderValue
              // item._isCustomInput = true
            }
          })
          thematicTabJ.data.forEach(item=>{
            if(item.query==Object.keys(thematicTabJ.selectedFunds)[i]){
              maxValue = item.sliderValue
            }
          })
        }
        if(allSum!==0 && payload.value <= maxValue && isCurrentCatRight) {
          //calculate ratio
            tickerArray.forEach(item => {
              if (item.ticker !== payload.ticker.ticker) {
                item.w_ratio = item._sliderValue / allSum
                console.log(item.w_ratio)
              }
            })

          //assign new weights
            tickerArray.forEach(item => {
              if (item.ticker !== payload.ticker.ticker) {
                item._sliderValue = item._sliderValue - (diff * item.w_ratio)
                // item._isCustomInput = true
                console.log(item._sliderValue)
              }
              if (item.ticker === payload.ticker.ticker) {
                item._sliderValue = payload.value
                // item._isCustomInput = true
              }
            })
        }
      }
    }
  }

  if(Object.keys(bondTabJ.selectedFunds).length){
    //bondTab
    bondTabJ._isCustomInput = true
    //loop over categories (usa,dm,em)
    for (let i = 0; i < Object.keys(bondTabJ.selectedFunds).length; i++) {
      //tickerArray => selected funds of usa,em,dm
      if (bondTabJ.selectedFunds[Object.keys(bondTabJ.selectedFunds)[i]].length > 1){
        let diff = payload.value - payload.ticker._sliderValue

        let isCurrentCatRight = false

        let tickerArray = bondTabJ.selectedFunds[ Object.keys(bondTabJ.selectedFunds)[i]]

        tickerArray.forEach(item=>{
          if (item.ticker === payload.ticker.ticker) {
            isCurrentCatRight = true
          }
        })

        let allSum = 0
        let maxValue = 100
        //calculate all sum
        if (isCurrentCatRight) {
          tickerArray.forEach(item=>{
            allSum=allSum+item._sliderValue
            if (item.ticker === payload.ticker.ticker) {
              allSum=allSum-item._sliderValue
              console.log(item._sliderValue)
              // item._isCustomInput = true
            }
          })
          bondTabJ.data.forEach(item=>{
            if(item.query==Object.keys(bondTabJ.selectedFunds)[i]){
              maxValue = item.sliderValue
            }
          })
        }

        console.log(allSum)
        if(allSum!==0 && payload.value <= maxValue && isCurrentCatRight) {
          //calculate ratio
            tickerArray.forEach(item => {
              if (item.ticker !== payload.ticker.ticker) {
                item.w_ratio = item._sliderValue / allSum

              }
            })

          //assign new weights
            tickerArray.forEach(item => {
              if (item.ticker !== payload.ticker.ticker) {
                item._sliderValue = item._sliderValue - (diff * item.w_ratio)
                // item._isCustomInput = true
              }
              if (item.ticker === payload.ticker.ticker) {
                item._sliderValue = payload.value
                // item._isCustomInput = true
              }
            })
        }
      }
    }
  }

  console.log('setting new input values')
  yield put(setBuildPortfolioState({
    equityTab: equityTabJ,
    thematicTab: thematicTabJ,
    bondTab: bondTabJ
  }));

}

function* removeZeroFunds(){
  const allStates = yield select(getAllBuilPortfolioStates);
  let {thematicTab,equityTab,bondTab} = allStates
  let thematicTabJ = JSON.parse(JSON.stringify(thematicTab))
  let equityTabJ = JSON.parse(JSON.stringify(equityTab))
  let bondTabJ = JSON.parse(JSON.stringify(bondTab))

  if(Object.keys(equityTabJ.selectedFunds).length){
    for (let i = 0; i < Object.keys(equityTabJ.selectedFunds).length; i++) {
      let tickerArray = equityTabJ.selectedFunds[ Object.keys(equityTabJ.selectedFunds)[i]]
      equityTabJ.selectedFunds[ Object.keys(equityTabJ.selectedFunds)[i]] = tickerArray.filter(item=>item._sliderValue!==0)
    }
  }
  if(Object.keys(thematicTabJ.selectedFunds).length){
    for (let i = 0; i < Object.keys(thematicTabJ.selectedFunds).length; i++) {
      let tickerArray = thematicTabJ.selectedFunds[ Object.keys(thematicTabJ.selectedFunds)[i]]
      thematicTabJ.selectedFunds[ Object.keys(thematicTabJ.selectedFunds)[i]] = tickerArray.filter(item=>item._sliderValue!==0)
    }
  }
  if(Object.keys(bondTabJ.selectedFunds).length){
    for (let i = 0; i < Object.keys(bondTabJ.selectedFunds).length; i++) {
      let tickerArray = bondTabJ.selectedFunds[ Object.keys(bondTabJ.selectedFunds)[i]]
      bondTabJ.selectedFunds[ Object.keys(bondTabJ.selectedFunds)[i]] = tickerArray.filter(item=>item._sliderValue!==0)
    }
  }

  yield put(setBuildPortfolioState({
    equityTab: equityTabJ,
    thematicTab: thematicTabJ,
    bondTab: bondTabJ
  }));
}

function* portfolioSaga() {
  yield all([
    fork(takeEvery, ActionTypes.EQUITY_BATCH_REQUEST, fetchEquityBatch),
    fork(takeEvery, ActionTypes.BONDS_BATCH_REQUEST, fetchBondsBatch),
    fork(takeEvery, ActionTypes.THEMATIC_BATCH_REQUEST, fetchThematicBatch),
    fork(takeEvery, ActionTypes.CUSTOM_TICKER_REQUEST, fetchCustomTicker),
    fork(takeEvery, ActionTypes.TICKER_DATA_REQUEST, getCustomTickerData),
    fork(takeEvery, ActionTypes.THEMES_REQUEST, getThemes),
    fork(takeEvery, ActionTypes.FINALIZE_SAVE_PORTFOLIO_REQUEST, saveUserPortfolio),
    fork(takeEvery, ActionTypes.SEARCH_NEW_QUERY_REQUEST, searchNewQuery),
    fork(takeEvery, ActionTypes.PORTFOLIO_DATA_REQUEST, fetchPortfolio),
    fork(takeEvery, ActionTypes.EDIT_PORTFOLIO_REQUEST, editPortfolioName),
    fork(takeEvery, ActionTypes.PRE_SELECTED_THEMATIC_TICKERS_REQUEST, getPreSelectedThematicTickersData),
    fork(takeEvery, ActionTypes.UPLOAD_BUILDER_PORTFOLIO_REQUEST, uploadBuilderPortfolioCall),
    fork(takeEvery, ActionTypes.CHECK_BUILDER_TICKER_EXIST_REQUEST, getAllTickersValidate),
    fork(takeEvery, ActionTypes.DOWNLOAD_BUILDER_REPORT, downloadBuilderPDF),
    fork(takeEvery, ActionTypes.DELETE_PORTFOLIO_BUILDER_BY_ID_REQUEST, deletePortfolio),
    fork(takeEvery, ActionTypes.CUSTOM_WEIGHT_INPUT, customWeightInput),
    fork(takeEvery, ActionTypes.REMOVE_ZERO_WEIGHT_FUNDS, removeZeroFunds),
  ])
}

export default portfolioSaga

import ActionTypes from '../action-types';

export function updateSFMTrackYear(payload) {
  return {
    type: ActionTypes.UPDATE_SFM_TRACK_YEAR,
    payload,
  }
}

export function updateReportForFundTracking(payload) {
  return {
    type: ActionTypes.UPDATE_FUNDS_TRACKING_REPORT,
    payload
  }
}

export function saveBgDownload() {
  return {
    type: ActionTypes.SAVE_BG_DOWNLOAD,
  }
}

export function clearFundsTracking(payload) {
  return {
    type: ActionTypes.CLEAR_TRACK_FUNDS,
    payload
  }
}

export function trackFundsCompareOrder(payload) {
  return {
    type: ActionTypes.TRACK_COMAPRE_ORDER,
    payload
  }
}

export function generateFUNDSReport(payload) {
  return {
    type: ActionTypes.GENERATE_FUNDS_REPORT,
    payload
  }
}

export function scoreAttrsHandler(payload) {
  return {
    type: ActionTypes.SCORE_ATTRS_HANDLER,
    payload
  }
}

export function setSFMItems(payload) {
  return {
    type: ActionTypes.SET_SFM_ITEMS,
    payload,
  }
}

export function setSFMCatData(payload) {
  return {
    type: ActionTypes.SET_SFM_CATDATA,
    payload,
  }
}

export function addFundToSFM(payload) {
  return {
    type: ActionTypes.ADD_FUND_TO_SFM,
    payload,
  }
}

export function refreshSFMState(payload) {
  return {
    type: ActionTypes.REFRESH_STATE,
    payload,
  }
}

export function setSFMState(payload) {
  return {
    type: ActionTypes.SET_SFM_STATE,
    payload,
  }
}

export function toggleModal(payload) {
  return {
    type: ActionTypes.TOGGLE_MODAL,
    payload,
  }
}

export function getSelectedFunds(payload) {
  return {
    type: ActionTypes.GET_SELECTED_FUNDS,
    payload,
  }
}

export function getFunds(payload) {
  return {
    type: ActionTypes.GET_FUNDS,
    payload
  }
}

export function failedToPerform(payload) {
  return {
    type: ActionTypes.FAILED_TO_PERFORM,
    payload
  }
}

export function backHandler(payload) {
  return {
    type: ActionTypes.BACK_HANDLER,
    payload
  }
}

export function buyHandler(payload) {
  return {
    type: ActionTypes.BUY_HANDLER,
    payload
  }
}

export function actionHandler(payload) {
  return {
    type: ActionTypes.ACTION_HANDLER,
    payload
  }
}

export function clearHandler(payload) {
  return {
    type: ActionTypes.CLEAR_HANDLER,
    payload
  }
}

export function selectHandler(payload) {
  return {
    type: ActionTypes.SELECT_HANDLER,
    payload
  }
}

export function rangeHandler(payload) {
  return {
    type: ActionTypes.RANGE_HANDLER,
    payload
  }
}

export function sfmFundAdd(fund, index) {
  return {
    type: ActionTypes.SFM_FUND_ADD,
    fund,
    index,
  }
}

export function sfmFundDelete(fund) {
  return {
    type: ActionTypes.SFM_FUND_DELETE,
    fund,
  }
}

// SFM V-2 actions

/* ADD SFM ITEM TICKERS */
export function addSFMItemTickersRequest(payload, callback) {
  return {
    type: ActionTypes.ADD_SFM_ITEM_TICKERS_REQUEST,
    payload,
    callback,
  }
}

export function addSFMItemTickersFetch(payload) {
  return {
    type: ActionTypes.ADD_SFM_ITEM_TICKERS_FETCH,
    payload,
  }
}

export function addSFMItemTickersSuccess(payload) {
  return {
    type: ActionTypes.ADD_SFM_ITEM_TICKERS_SUCCESS,
    payload,
  }
}

/* REMOVE SFM ITEM TICKERS */
export function removeSFMItemTickersRequest(payload, callback) {
  return {
    type: ActionTypes.REMOVE_SFM_ITEM_TICKERS_REQUEST,
    payload,
    callback,
  }
}

export function removeSFMItemTickersFetch(payload) {
  return {
    type: ActionTypes.REMOVE_SFM_ITEM_TICKERS_FETCH,
    payload,
  }
}

export function removeSFMItemTickersSuccess(payload) {
  return {
    type: ActionTypes.REMOVE_SFM_ITEM_TICKERS_SUCCESS,
    payload,
  }
}

/* GET SFM ITEM DETAILS */
export function getSFMItemDetailsRequest(payload, callback) {
  return {
    type: ActionTypes.GET_SFM_ITEM_DETAILS_REQUEST,
    payload,
    callback,
  }
}

export function getSFMItemDetailsFetch(payload) {
  return {
    type: ActionTypes.GET_SFM_ITEM_DETAILS_FETCH,
    payload,
  }
}

export function getSFMItemDetailsSuccess(payload) {
  return {
    type: ActionTypes.GET_SFM_ITEM_DETAILS_SUCCESS,
    payload,
  }
}

/* UPDATE HOLDING, REGIONS & SECTOR (HRS) IN SFM FUND */

export function updateHRSDataRequest(payload, callback) {
  return {
    type: ActionTypes.UPDATE_HRS_DATA_REQUEST,
    payload,
    callback,
  }
}

export function updateHRSDataFetch(payload) {
  return {
    type: ActionTypes.UPDATE_HRS_DATA_FETCH,
    payload,
  }
}

export function updateHRSDataSuccess(payload) {
  return {
    type: ActionTypes.UPDATE_HRS_DATA_SUCCESS,
    payload,
  }
}

/* DELETE ALL SFM ITEM TICKERS */

export function deleteAllSFMItemTickersRequest(payload, callback) {
  return {
    type: ActionTypes.DELETE_ALL_SFM_ITEM_TICKERS_REQUEST,
    payload,
    callback,
  }
}

export function deleteAllSFMItemTickersFetch(payload) {
  return {
    type: ActionTypes.DELETE_ALL_SFM_ITEM_TICKERS_FETCH,
    payload,
  }
}

export function deleteAllSFMItemTickersSuccess(payload) {
  return {
    type: ActionTypes.DELETE_ALL_SFM_ITEM_TICKERS_SUCCESS,
    payload,
  }
}

/* setFundSelectionType 'buy' or 'sell' */
export function setFundSelectionTypeRequest(payload) {
  return {
    type: ActionTypes.SET_SFM_FUND_SELECTION_TYPE_REQUEST,
    payload,
  }
}

export function setFundSelectionTypeFetch(payload) {
  return {
    type: ActionTypes.SET_SFM_FUND_SELECTION_TYPE_FETCH,
    payload,
  }
}

export function setFundSelectionTypeSuccess(payload) {
  return {
    type: ActionTypes.SET_SFM_FUND_SELECTION_TYPE_SUCCESS,
    payload,
  }
}

/* EXECUTE SFM TRADE ORDER */
export function executeMagnifiOrderRequest(payload, callback) {
  return {
    type: ActionTypes.EXECUTE_MAGNIFI_BROKER_ORDERS_REQUEST,
    payload,
    callback,
  }
}

export function executeMagnifiOrderFetch(payload) {
  return {
    type: ActionTypes.EXECUTE_MAGNIFI_BROKER_ORDERS_FETCH,
    payload,
  }
}

export function executeMagnifiOrderSuccess(payload) {
  return {
    type: ActionTypes.EXECUTE_MAGNIFI_BROKER_ORDERS_SUCCESS,
    payload,
  }
}

/* ADD TICKERS TO CART */
export function addTickersToCartRequest(payload, callback) {
  return {
    type: ActionTypes.ADD_TICKERS_TO_CART_REQUEST,
    payload,
    callback,
  }
}

export function addTickersToCartFetch(payload) {
  return {
    type: ActionTypes.ADD_TICKERS_TO_CART_FETCH,
    payload,
  }
}

export function addTickersToCartSuccess(payload) {
  return {
    type: ActionTypes.ADD_TICKERS_TO_CART_SUCCESS,
    payload,
  }
}

/* SFM REAL-TIME-PRICE SOCKET */
export function sfmRealTimePriceSocket(payload) {
  return {
    type: ActionTypes.SFM_REAL_TIME_PRICE_SOCKET,
    payload,
  }
}

export function getUserPortfolio(payload) {
  return {
    type: ActionTypes.GET_USER_PORTFOLIO,
    payload,
  }
}

export function userPortfolioSuccess(payload) {
  return {
    type: ActionTypes.GET_USER_PORTFOLIO_SUCCESS,
    payload,
  }
}

export function userPortfolioError(payload) {
  return {
    type: ActionTypes.GET_USER_PORTFOLIO_ERROR,
    payload,
  }
}

/* GET UPLOADED PORTFOLIO list in sfm myPortfolio */
export function getPortfolioDetailsForSFMRequest(payload, callback) {
  return {
    type: ActionTypes.GET_PORTFOLIO_DETAILS_FOR_SFM_REQUEST,
    payload,
    callback,
  }
}

export function getPortfolioDetailsForSFMFetch(payload) {
  return {
    type: ActionTypes.GET_PORTFOLIO_DETAILS_FOR_SFM_FETCH,
    payload,
  }
}

export function getPortfolioDetailsForSFMSuccess(payload) {
  return {
    type: ActionTypes.GET_PORTFOLIO_DETAILS_FOR_SFM_SUCCESS,
    payload,
  }
}

export function getCombineScoreRequest(payload, callback) {
  return {
    type: ActionTypes.GET_COMBINE_SCORE_REQUEST,
    payload,
    callback,
  }
}

export function getCombineScoreFetch(payload) {
  return {
    type: ActionTypes.GET_COMBINE_SCORE_FETCH,
    payload,
  }
}

export function getCombineScoreSuccess(payload) {
  return {
    type: ActionTypes.GET_COMBINE_SCORE_SUCCESS,
    payload,
  }
}
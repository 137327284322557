// absolute imports
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'reactstrap';
import { CSVLink } from 'react-csv';
import { Table, Tooltip as AntToolTip } from 'antd';
import {
  generateTradelistRequest,
  clientDeleteRequest,
  clientDetailsRequest,
  downloadMoreDataRequest,
  getSelectedClientDetailsRequest,
  deleteClientAccountRequest,
  setSearchAccountTickerIndex,
} from 'actions/clientportfolioV2';
import { updateClientAccountNameRequest } from 'actions/enhancer';
import { createLoadingSelector } from 'selectors/loading-selector';
import { getColViewName } from '../../Utils';
import { ShowSpinner } from '../Shared/ShowSpinner';
import { NoClientsComponent } from '../Shared/NoClientsComponent'
import ClientDetailsModal from '../ClientDetailsModal/index';
import DelConfirmModal from './DelConfirmModal';
import RestrictedTickersModal from './RestrictedTickersModal';
import TaxTransitionModal from './TaxTransitionModal';
import OptimizeModal from './OptimizeModal';
import ColumnSearchFilter from '../Shared/ColumnSearchFilter';
import { addEventToAnalytics } from 'Utils';
import InfiniteList from './InfiniteList';

const { Column, ColumnGroup } = Table;

class PortfolioTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
      selectAll: false,
      globalSelect: false,
      tradelist: '',
      filterText: '',
      selectedColumn: '',
      selectedClientDet: '',
      showDetailsModal: false,
      delType: '',
      showDelConfirmModal: false,
      delClientDetails: '',
      clearSelectedRows: false,
      modalSelectedClients: '',
      showRestrictedTickersModal: false,
      showTaxTransitionModal: false,
      showOptimizeModal: false,
      clientTableWidth: '',
      tableHeight:'',
      tooltipOpen:false,
      tooltipOpen1:false,
      disableTradeButton: true
    };
    this.scrollBar = React.createRef();
    this.genTradelist = React.createRef()
    // this.name = React.createRef()
  }

  _clientDeleteCallBack = (row)=>{
    this.deleteClientConfirmation(row);
  }

  _restrictedTickerCallBack = (row)=>{
    this.setState({
      modalSelectedClients: row ? [row] : '',
      showRestrictedTickersModal: true
    })
  }


  generateTradelist = () => {
    addEventToAnalytics('Client Trade', 'Client Trade', 'CLIENT_TRADE', {}, false)
    let selectedRowIDs = this.state.selectedRows.map(a => a.id)
    this.props.generateTradelistRequest({client_ids: selectedRowIDs, dT: this.downloadTradelist});
  }

  downloadTradelist = (tradelist) => {
    let tradelistError = tradelist.filter(item => item.trade_result[0].hasOwnProperty('message'))
    tradelist = tradelist.filter(item => !item.trade_result[0].hasOwnProperty('message'))
    tradelist = tradelist.reduce((acc, item) => [...acc, ...item.trade_result], [])
    this.setState({tradelist}, () => {
      // click the CSVLink component to trigger the CSV download
      this.genTradelist.current.link.click()
    });
  }

  handleClear = () => {

  }

  getClientDetails = ({ row, queryVar }) => {
    // console.log('selectedClientDetailsRow --> ', row);
    // console.log('queryVar --> ', queryVar);
    this.setState({
      selectedClientDet: row,
    })
    const { getSelectedClientDetailsRequest } = this.props;
    if (getSelectedClientDetailsRequest) {
      getSelectedClientDetailsRequest({
        selectedRow: row,
        query_var: queryVar,
      });
    }
  }

  // To updated column on grid
  columnTrack = (check,totalColumn)=>{
    const {setSearchAccountTickerIndex, searchAccTickerIndex}=this.props;
    if(setSearchAccountTickerIndex){
      switch (check){
        case '+':
          if( searchAccTickerIndex < totalColumn-1){
            setSearchAccountTickerIndex({
              searchAccTickerIndex: searchAccTickerIndex + 1
            })
          }
          break;
        case '-':
          if( searchAccTickerIndex > 0){
            setSearchAccountTickerIndex({
              searchAccTickerIndex: searchAccTickerIndex - 1
            })
          }
          break;
        default:
          break;
        }
      }
  }

  closeDetailsModal = (val) => {
    this.setState({
      selectedClientDet: '',
      showDetailsModal: false
    })
  }

  deleteClientConfirmation = (val) => {
    // console.log(val);
    // delType 0 = single
    // delType 1 = multiple
    // delType 2 = all

    // Check if some rows are selected or all rows are selected and setType based on that
    //let delType = val || this.state.selectedRows.length == 1 ? 0 : this.state.selectedRows.length > 0 && this.state.selectedRows.length != this.props.clientList.length ? 1 : 2;
    const delType=0;
    let delClientDetails = val;
    this.setState({
      showDelConfirmModal: true,
      delClientDetails,
      delType
    })
  }

  deleteClient = (val) => {
    // this.props.clientDeleteRequest({client_ids: [val.id]})
    if(this.state.delType == 0){
      addEventToAnalytics('Client Delete Micro', 'Client Delete Micro', 'CLIENT_DELETE_MICRO', { client_id: val.id }, false)
    }else if(this.state.delType == 1){
      addEventToAnalytics('Client Multiple Delete', 'Client Multiple Delete', 'CLIENT_MULTIPLE_DELETE', {}, false)
    }else{
      addEventToAnalytics('Client Delete Macro', 'Client Delete Macro', 'CLIENT_DELETE_MACRO', {}, false)
    }

    console.log('deleting clients val ->', val);
    let client_ids;
    if(val){
      client_ids = [val.id]
    }else if(this.state.selectedRows.length > 0) {
      client_ids = this.state.selectedRows.map(a => a.id)
    }else{
      client_ids = this.props.clientList && this.props.clientList.map(item => item.id)
    }

    const { deleteClientAccountRequest } = this.props;
    if (deleteClientAccountRequest) {
      deleteClientAccountRequest({
        client_ids,
      })
    }

    // this.props.clientDeleteRequest({client_ids: client_ids})

    this.closeDelConfirmationModal();
    this.setState({
      clearSelectedRows: !this.state.clearSelectedRows,
      selectedRows: []
    })
  }

  closeDelConfirmationModal = () => {
    this.setState({
      showDelConfirmModal: false
    })
  }

  closeRestrictedTickersModal = () => {
    this.setState({
      showRestrictedTickersModal: false
    })
  }

  taxTransition = (row) => {
    this.setState({
      modalSelectedClients: row ? [row] : '',
      showTaxTransitionModal: true
    })
  }

  closeTaxTransitionModal = () => {
    this.setState({
      showTaxTransitionModal: false
    })
  }

  optimize = (row) => {
    // console.log("row", row)
    this.setState({
      modalSelectedClients: row,
      showOptimizeModal: true
    })
  }

  closeOptimizeModal = () => {
    this.setState({
      showOptimizeModal: false
    })
  }

  // updateSelectedRows = (row, isChecked, finalData) => {
  //   if(isChecked){
  //     const selectedRows = this.state.selectedRows.concat(row)
  //     this.setState({
  //       selectedRows,
  //       globalSelect: selectedRows.length == finalData.length
  //     })
  //   }else{
  //     const selectedRows = this.state.selectedRows.filter(item => item.id !== row.id)
  //     this.setState({
  //       selectedRows,
  //       globalSelect: false
  //     })
  //   }
  // }

  // globalUpdate = (row, isChecked) => {
  //   if(isChecked){
  //     this.setState({
  //       selectedRows: row,
  //       globalSelect: true,
  //       selectAll: true
  //     })
  //   }else{
  //     this.setState({
  //       selectedRows: [],
  //       globalSelect: false,
  //       selectAll: false
  //     })
  //   }
  // }

  // row selection for client table
  highlightClientRow = (id) => {
    let prevSelectedRow=[];
    let nextSelectedRow=[];
    /* to maintain height*/
    let header=document.getElementsByClassName('infinite-list-header');
    if(header.length){
      let clientDetailDiv=document.getElementsByClassName('client-detail-section')
      // let hightlightContainer=document.getElementsByClassName('arrow-padd')
      let tableBodyheight=document.getElementsByClassName('infinite-list-wrapper')
      // let carouselR=document.getElementsByClassName('right-arrow')
      // let carouselL=document.getElementsByClassName('left-arrow')
      //let addAccountMargin= document.getElementsByClassName('imp-exp-opt')
      if(clientDetailDiv.length){
        clientDetailDiv[0].style.paddingTop=header[0].clientHeight-1+'px';
      }
      if(tableBodyheight && tableBodyheight.length){
        tableBodyheight[0].style.height = 'calc(98vh - '+(300 + header[0].clientHeight + 55 )+'px )';
      }
      // if(hightlightContainer.length){
      //   tableBodyheight[0].style.maxHeight= 'calc(100vh - '+(300 + header[0].clientHeight + 60 )+'px )';
      //   //addAccountMargin[0].style.marginTop=0+'px';
      // }
      // if(carouselR.length && hightlightContainer.length){
      //   carouselR[0].style.paddingTop=(hightlightContainer[0].clientHeight-24) +'px';
      // }
      // if(carouselL.length && hightlightContainer.length){
      //   carouselL[0].style.paddingTop=(hightlightContainer[0].clientHeight-24) +'px';
      // }

    }
    /* to maintain height*/
    if(id){
       prevSelectedRow=document.getElementsByClassName('selectedClientRow');
       if(prevSelectedRow.length){
         prevSelectedRow[0].classList.remove('selectedClientRow');
        }
        nextSelectedRow =document.querySelector(`[data-row-key="${id}"]`);
       if(nextSelectedRow){
         nextSelectedRow.classList.add('selectedClientRow');
        }
      }
  }

  _uploadAccount = () =>{
    const hover =(ele)=>{
      if(ele){
        ele.currentTarget.children.image1.setAttribute('src', '/assets/images/PlusIconWhite.svg');
      }
    }

    const unhover =(ele)=>{
      if(ele){
        ele.currentTarget.children.image1.setAttribute('src', '/assets/images/PlusIcon.svg');
      }
    }

    return (<>
      <div className="imp-exp-opt" id='acUpload'
        onClick={() => this._clientUploadModalToggle(true)} onMouseOver={(e)=>hover(e)} onMouseOut={(e)=>unhover(e)}>
          <img id='image1' className='image1' src="/assets/images/PlusIcon.svg" />
          <span className='text'>ADD MORE ACCOUNTS</span>
      </div>
      {/* <Tooltip className="col-grey-top" placement="top" isOpen={this.state.tooltipOpen} target="acUpload" toggle={() => this.setState({ tooltipOpen: !this.state.tooltipOpen })}>
          Add more accounts
      </Tooltip> */}
      </>
    )
  }

  _clientUploadModalToggle = (value) => {
    if(this.props.clientUploadModalToggle){
      this.props.clientUploadModalToggle(value);
    }

  }

  tooltipVisibility=(value)=>{
    this.setState({ tooltipOpen1: value })
  }

  tooltipVisibility1=(value)=>{
    this.setState({ tooltipOpen: value })
  }

  downloadMoreList = (requestData) => {
    const { downloadMoreDataRequest, downloadedList } = this.props;
    if (downloadMoreDataRequest) {
      if (downloadedList.isSearchQueryBased) {
        requestData = {
          ...requestData,
          q: downloadedList.query,
          isSearchQueryBased: downloadedList.isSearchQueryBased,
        };
      }
      downloadMoreDataRequest(requestData);
    }
  }

  render() {
    const { clientList, searchResultsData, searchResultsData: { query_var: qv }, accSearchQuery, clientSearchStatus, clientUploadModalToggle, clientListLoading, clientsfilteredText, totalClientList, downloadedList, downloadingList, selectedClientDetails, loadingClientDetails, dynamicColumnQuery, searchAccTickerIndex, updateClientAccountNameRequest, updatingClientName } = this.props;
    const { selectedClientDet, showDetailsModal, showDelConfirmModal, delClientDetails, modalSelectedClients, delType, clearSelectedRows, showRestrictedTickersModal, showTaxTransitionModal, showOptimizeModal, selectAll, selectedRows, clientTableWidth, tableHeight, disableTradeButton } = this.state;
    // const searchAccTicker = query_var ? query_var[0].data[0] : null;
    // Remove query_var values from array that doesn't contain view_name
    let query_var = (qv && qv.length) && qv.filter(item =>{
      if(item.active || ((item['view_name'] && item['Name of the chart on UI']))){
        if(item['view_name']=='Highlight'){
          if(item['Name of the chart on UI']=='Fees'){
            return true;
          }else{
            return false;
          }
        }else{
          return true;
        }
      }});
    //const suffix = (query_var[0].view_name == 'Sectors' || query_var[0].view_name == 'Regions' || searchAccTicker == 'Risk' || searchAccTicker == 'Returns') ? '%' : query_var[0].suffix;
    // console.log('searchAccTickerIndex-->', this.state.searchAccTickerIndex);

    let filteredQueryVarForViewName = query_var;
    if (query_var && query_var.length > 0 && query_var[searchAccTickerIndex]) {
      filteredQueryVarForViewName = [{...query_var[searchAccTickerIndex] }]; // to solve this -> ['Top Holdings', 'Top Holdings'] -> ['holdings_TSLA', 'holdings_IBM'] scenario
    }
    // console.log('filteredQueryVarForViewName -->', filteredQueryVarForViewName);

    const searchAccTicker = searchResultsData ? (query_var.length && query_var[searchAccTickerIndex].view_name) ?getColViewName(query_var[searchAccTickerIndex].view_name, filteredQueryVarForViewName) : '' : '';
    const searchAccTickerTooltip = searchResultsData ? (query_var.length && query_var[searchAccTickerIndex].view_name) ? query_var[searchAccTickerIndex].tooltip:'':'';
    const searchField = query_var && query_var.length ? query_var[searchAccTickerIndex].variable : null;
    const multiplier = query_var && query_var.length &&query_var[searchAccTickerIndex].multiplier ? query_var[searchAccTickerIndex].multiplier : 1;
    let averageValueOfSearchAccTicker=0;
    let suffix='';
    if(searchResultsData && searchField){
      // calculating average value of search field
       searchResultsData['client_list'].forEach((ele)=>{
         if(ele[searchField] !== "" && ele[searchField] != null){
           averageValueOfSearchAccTicker+=parseFloat((ele[searchField]*multiplier).toFixed(2));
         }
       });
       const clientList=searchResultsData['client_list'].filter((ele1)=>{
         if(ele1[searchField] !== "" && ele1[searchField] != null){
           return true;
         }
       })
       averageValueOfSearchAccTicker=(averageValueOfSearchAccTicker/clientList.length).toFixed(2);
       suffix = query_var && (query_var[searchAccTickerIndex].view_name == 'Sectors' || query_var[searchAccTickerIndex].view_name == 'Regions' || searchAccTicker == 'Risk' || searchAccTicker == 'Returns') ? '%' : query_var[searchAccTickerIndex].suffix;
       dynamicColumnQuery({averageJSX:averageCalculation(searchAccTicker,averageValueOfSearchAccTicker,suffix), columnJSX: columnJSX(searchAccTicker),columnTrackIcon : this.columnTrack,query_var_length:query_var.length,index:searchAccTickerIndex},true);
    }else{
       dynamicColumnQuery({},false);
    }
    const finalData = clientList.length ? clientList : [];
    // if(selectedClientDet){
    //   clientDetailSection=selectedClientDet;
    //   if(clientDetailSection){
    //     this.highlightClientRow(clientDetailSection.id)
    //   }
    //   this.setState({
    //     showDetailsModal:true
    //   })
    // }
    // else if(finalData){
    //   clientDetailSection=finalData[0];
    //   if(clientDetailSection){
    //     this.highlightClientRow(clientDetailSection.id)
    //   }
    //   this.setState({
    //     showDetailsModal:true
    //   })
    // }

    // const singleSectionWidth = clientTableWidth / 2;
    // const allColumnWidth = searchAccTicker ? 1520 : 1160;
    // const tableWidth = clientTableWidth > allColumnWidth ? '100%' : allColumnWidth;
    // const tableWidth = '100%';

    let orionHeaders = [
      { label: 'account', key: 'account' },
      { label: 'action', key: 'side' },
      { label: 'shares', key: 'shares' },
      { label: 'ticker', key: 'ticker' }
    ]

    // let filterProps = {
    //   onFilter: e => this.setState({ filterText: e.target.value, selectedColumn: e.target.name }),
    //   onClear: this.handleClear,
    //   filterText: this.state.filterText,
    //   selectedColumn: this.state.selectedColumn
    // }

    let noDataMessage;

    if(!clientSearchStatus && accSearchQuery && finalData.length === 0 ){
      //noDataMessage = `No results found for "${accSearchQuery}". Please try another query.`;
      noDataMessage= `There are 0 accounts relevant to your search.`
    }else if(clientsfilteredText && finalData.length === 0) {
      //noDataMessage = `We did not find any accounts for "${clientsfilteredText}"`;
      noDataMessage= `There are 0 accounts relevant to your search.`
    }

    let colSpan = {
      name: { span: downloadedList.isSearchQueryBased ? 5 : 6 },
      acc_no: { span: downloadedList.isSearchQueryBased ? 7 : 9 },
      custodian: { span: downloadedList.isSearchQueryBased ? 6 : 9 },
      query_var: { span: 6 },
    };

    let headerColData = [{
      label: 'Name',
      key: 'name',
      showFilter: true,
      colSpan: colSpan['name'],
    }, {
      label: 'Account No.',
      key: 'acc_no',
      showFilter: true,
      colSpan: colSpan['acc_no'],
    }, {
      label: 'Custodian',
      key: 'custodian',
      showFilter: true,
      colSpan: colSpan['custodian'],
    }];

    const TooltipCol = ({ searchAccTicker, tooltipContent }) => {
      if (!tooltipContent) return (<div className="highlight highlight-ticker" id='searchField'>{searchAccTicker}</div>)
      return (
        <AntToolTip overlayClassName="infinite-list-tooltip" placement="top" title={tooltipContent}>
          <div className="highlight highlight-ticker" id='searchField'>
            {searchAccTicker}
            <span className="check-label" id="smaIcon">
              <i class="fal fa-info-circle"></i>
            </span>
          </div>
        </AntToolTip>
      )
    }

    // const QueryVarColDesign = () => {
    //   if ((!query_var || !query_var.length) && !downloadedList.isSearchQueryBased) return null;
    //   return (
    //     <Fragment>
    //       {(query_var.length > 1) ? (
    //         <div className="multiple-query-var-header-col">
    //           <span className='side-arrow left-arrow' onClick={() => this.columnTrack('-',query_var.length)}>
    //             {'<'}
    //           </span>
    //           <TooltipCol searchAccTicker={searchAccTicker} tooltipContent={searchAccTickerTooltip} />
    //           <span className='side-arrow right-arrow' onClick={() => this.columnTrack('+',query_var.length)}>
    //             {'>'}
    //           </span>
    //         </div>
    //       ) : (
    //         <TooltipCol searchAccTicker={searchAccTicker} tooltipContent={searchAccTickerTooltip} />
    //       )}
    //     </Fragment>
    //   )
    // }

    const QueryVarColDesignInfiniteList=() => {
      if(!downloadedList.isSearchQueryBased) return null;
      return (
        <Fragment>
          <TooltipCol searchAccTicker={searchAccTicker} tooltipContent={searchAccTickerTooltip} />
        </Fragment>
      )
    }


    if (downloadedList.isSearchQueryBased) {
      headerColData = [...headerColData, {
        key: 'query_var',
  			className: 'query-var-header-col',
        colSpan: colSpan['query_var'],
        showFilter: false,
        label: (<QueryVarColDesignInfiniteList />),
      }];
    }

    return(
      <div className="general-wrapper portfolio-tbl-wrapper-advisor">
        {showDelConfirmModal &&
          <DelConfirmModal
            showDelConfirmModal={showDelConfirmModal}
            closeDelConfirmationModal={this.closeDelConfirmationModal}
            delType={delType}
            delClientDetails={delClientDetails}
            deleteClient={this.deleteClient}
          />
        }
        {showRestrictedTickersModal &&
          <RestrictedTickersModal
            showRestrictedTickersModal={showRestrictedTickersModal}
            closeRestrictedTickersModal={this.closeRestrictedTickersModal}
            clientDetails={modalSelectedClients}
          />
        }
        {showTaxTransitionModal &&
          <TaxTransitionModal
            showTaxTransitionModal={showTaxTransitionModal}
            closeTaxTransitionModal={this.closeTaxTransitionModal}
            clientDetails={modalSelectedClients}
          />
        }
        {showOptimizeModal &&
          <OptimizeModal
            showOptimizeModal={showOptimizeModal}
            closeOptimizeModal={this.closeOptimizeModal}
            clientDetails={modalSelectedClients}
          />
        }
        <CSVLink
          data={this.state.tradelist}
          headers={orionHeaders}
          filename={'MyTradeList.csv'}
          ref={this.genTradelist}
        />
        {clientListLoading ? (
          <div className='ant-custom-tbl-content pt-wrapper'><ShowSpinner/></div>
        ) : (
          <div className={`ant-custom-tbl-content pt-wrapper d-flex ${!finalData.length ? 'no-table-content' : ''} ${ showDetailsModal ?'show-client-det':''}`}>
            {(noDataMessage && noDataMessage.length > 0) ? (
              <NoClientsComponent message={noDataMessage} child={this._uploadAccount()} />
            ) : (
              <>
                <div className='container-left-width'>
                  <InfiniteList
                    colSpan={colSpan}
                    queryVar={query_var}
                    variable={searchField}
                    viewName={searchAccTicker}
                    loading={downloadingList}
                    customHeader={headerColData}
                    downloadedList={downloadedList}
                    dataSourceLength={totalClientList}
                    selectedRow={selectedClientDetails}
                    selectedQueryVarIndex={searchAccTickerIndex}
                    updateClientAccountNameRequest={updateClientAccountNameRequest}
                    fetchData={this.downloadMoreList}
                    clientUploadModalToggle={this._clientUploadModalToggle}
                    onRowClick={({ row, queryVar }) => this.getClientDetails({ row, queryVar })}
                  />
                </div>
                <div className='client-detail-section container-right-width' style={{ paddingTop: downloadedList.isSearchQueryBased ? 81 : 56 }}>
                  <ClientDetailsModal
                    query_var={query_var}
                    searchAccTicker={searchAccTicker}
                    selectedClientDet={selectedClientDetails}
                    clientDeleteCallBack={this._clientDeleteCallBack}
                    restrictedTickerCallBack={this._restrictedTickerCallBack}
                  />
                </div>
              </>
            )}
          </div>
        )}

        <div className="cta-footer">
          <footer style={{ width: "100%", borderTop: "1px solid #d5cbcb", textAlign: "center", fontFamily: "Open Sans", fontSize: "12px", padding: ".4em", background: "inherit" }}>
            <a href="https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/terms_of_use.pdf" target="_blank">Terms &#38; Conditions</a>&nbsp;
              | <a href="https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/privacy_policy.pdf" target="_blank">Privacy Policy</a>&nbsp;
              | <a href="https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/cookie_policy.pdf" target="_blank">Cookie Policy</a>&nbsp;
              | <a href="https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/crs_309397.pdf" target="_blank">Form CRS</a>&nbsp;
              | <a href="https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/Magnifi%20-%20Part%202A%20Brochure%207-26-2021.docx.pdf" target="_blank">Part2 Brochure</a>&nbsp;
              | <span>&#169; 2022 Magnifi All rights reserved. Patent Pending</span>
          </footer>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ loading, clientportfolioV2: { clientList, searchAccTickerIndex,searchResultsData, accSearchQuery, clientSearchStatus, clientListLoading, clientsfilteredText, downloadedList, selectedClientDetails } }) => ({
  clientList: downloadedList.data || clientList,
  searchResultsData,
  searchAccTickerIndex,
  accSearchQuery,
  downloadedList,
  clientSearchStatus,
  clientsfilteredText,
  selectedClientDetails,
  updatingClientName: createLoadingSelector(['UPDATE_CLIENT_ACCOUNT_NAME'])({ loading }),
  loadingClientDetails: createLoadingSelector(['GET_SELECTED_CLIENT_DETAILS'])({ loading }),
  downloadingList: createLoadingSelector(['DOWNLOAD_MORE_DATA'])({ loading }),
  clientListLoading: createLoadingSelector(['ACCOUNTPAGE_CLIENT_LIST', 'GET_CLIENT_LIST_BY_QUERY', 'FINALIZE_ALLOCATION', 'UPDATE_RESTRICTED_TICKERS', 'DELETE_CLIENT_ACCOUNT'])({ loading }) || clientListLoading,
})

const mapDispatchToProps = {
  generateTradelistRequest,
  clientDeleteRequest,
  clientDetailsRequest,
  downloadMoreDataRequest,
  getSelectedClientDetailsRequest,
  deleteClientAccountRequest,
  setSearchAccountTickerIndex,
  updateClientAccountNameRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PortfolioTable);

// const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => (
//   <div className="checkbox-align">
//     <div className="custom-control custom-checkbox">
//       <input
//         type="checkbox"
//         className="custom-control-input"
//         ref={ref}
//         {...rest}
//       />
//       <label className="custom-control-label" onClick={onClick} />
//     </div>
//     {/* <hr /> */}
//   </div>
// ));

const columnJSX = (searchAccTicker)=>{
  return (
    <div className="highlight highlight-ticker" id='searchField'>{searchAccTicker}</div>
  )
}

const averageCalculation =(searchAccTicker,averageValueOfSearchAccTicker,suffix)=>{
  return (
    <div className="tbl-actions avg-cash-allocation-margin">
      <div className="avg-cash-allocation">
        <span>{`Avg ${searchAccTicker}`} </span>
        <span className="avg-cash-allocation-value">{`${averageValueOfSearchAccTicker}${suffix}`}</span>
      </div>
    </div>
    )
}

//const averageCalculation=(searchAccTicker,averageValueOfSearchAccTicker,suffix,tooltipStatus1,tooltipToggle1)=>{
//  return(
//   <div className="tbl-actions avg-cash-allocation-margin">
//     <div className="avg-cash-allocation" id='acAlloc'>
//       <span>{`Avg ${searchAccTicker}`} </span>
//       <span className="avg-cash-allocation-value">{`${averageValueOfSearchAccTicker}${suffix}`}</span>
//       <Tooltip className="col-grey-top" placement="top" isOpen={tooltipStatus1} target="acAlloc" toggle={() =>tooltipToggle1(!tooltipStatus1) }>
//         Average across all relevant accounts
//       </Tooltip>
//   </div>
// </div>
//)
//}

// const searchAccTickerColumn=(tooltip,searchAccTicker,tooltipContent,tooltipStatus,tooltipToggle)=>{
//   if(tooltip && tooltipContent){
//     return(
//       <div className=''><div className="highlight highlight-ticker-c" id='searchField'><span className='content-highlight'>{searchAccTicker}</span><span className="check-label">
//       <i class="fal fa-info-circle"></i>
//     </span></div><Tooltip className="col-grey" placement="right" isOpen={tooltipStatus} target="smaIcon" toggle={() =>tooltipToggle(!tooltipStatus)}>
//                 {tooltipContent}
//               </Tooltip></div>)
//   }else{
//     return(
//     <div className=''><div className="highlight highlight-ticker-c" id='searchField'><span className='content-highlight'>{searchAccTicker}</span></div></div>)
//   }
// }

// const searchAccTickerColumnStats=(tooltip,searchAccTicker,tooltipContent,tooltipStatus,tooltipToggle)=>{
//   if(tooltip && tooltipContent){
//     return(
//       <div className='arrow-padd'><div className="highlight highlight-ticker" id='searchField'><span className='content-highlight'>{searchAccTicker}</span><span className="check-label" id="smaIcon">
//       <i class="fal fa-info-circle"></i>
//     </span></div><Tooltip className="col-grey" placement="right" isOpen={tooltipStatus} target="smaIcon" toggle={() =>tooltipToggle(!tooltipStatus)}>
//                 {tooltipContent}
//               </Tooltip></div>)
//   }else{
//     return(
//     <div className='arrow-padd'><div className="highlight highlight-ticker" id='searchField' title={searchAccTicker}><span className='content-highlight'>{searchAccTicker}</span></div></div>)
//   }
// }


// const ModelSelectorEdit = (props) => {
//   return (
//     <img className="cus-pointer ml-2"
//       onClick={() => {
//         if(props.isGlobal){
//           addEventToAnalytics('Client Edit Select Model Macro', 'Client Edit Select Model Macro', 'CLIENT_EDIT_SELECT_MODEL_MACRO', {}, false)
//         }else{
//           addEventToAnalytics('Client Edit Select Model Micro', 'Client Edit Select Model Micro', 'CLIENT_EDIT_SELECT_MODEL_MICRO', { client_id: props.refId }, false)
//         }
//         props.setSelectedClient(props.refId)
//       }}
//       src="/assets/images/pen-icon.svg"
//     />
//   )
// }
//
// const FilterItems = (props) => {
//   return(
//     <div className="tbl-hdr-search">
//       <p className={props.align == 'left' ? 'jus-start' : 'jus-center'}>{props.name}</p>
//       <hr />
//       <SearchCol {...props} />
//     </div>
//   )
// }

// const MdlSelectorSearch = (props) => {
//   let { clientList, setSelectedClient } = props;
//   return(
//     <div className="tbl-hdr-search">
//       <p className="d-flex justify-content-center align-items-center">
//         Selected Model
//         <ModelSelectorEdit
//           refId={clientList.length ? clientList[0].id : ''}
//           setSelectedClient={setSelectedClient}
//           isGlobal
//         />
//       </p>
//       {/*
//         <hr />
//         <SearchCol {...props} />
//       */}
//     </div>
//   )
// }

class SearchCol extends React.Component {
  constructor(props) {
    super(props);
    this[this.props.selector] = React.createRef();
  }
  componentDidMount(){
    this[this.props.selectedColumn] &&  this[this.props.selectedColumn].current.focus();
  }
  render() {
    const {selectedColumn, selector, filterText, onFilter} = this.props;
    const searchText = selectedColumn == selector ? filterText : '';
    return (
      <div className="sch-wrapper">
        <i className="far fa-fw fa-filter" />
        <input className="search" name={selector} type="text" placeHolder="Search" value={searchText} onChange={onFilter} ref={this[selector]}/>
        <i className="far fa-fw fa-search" />
      </div>
    );
  }
}

// const SearchColContent = ({row, searchField, query_var, searchAccTicker,index}) => {
//   // const value = (searchAccTicker == 'Risk' || searchAccTicker == 'Returns') ? parseFloat(row[searchField]).toFixed(2)*100 : parseFloat(row[searchField]).toFixed(2);
//   const multiplier = query_var[index].multiplier ? query_var[index].multiplier : 1;
//   const value = (row[searchField] !== "" && row[searchField] != null) ? (parseFloat(row[searchField])*multiplier).toFixed(2) || '-': '-';
//   const suffix = (query_var[index].view_name == 'Sectors' || query_var[index].view_name == 'Regions' || searchAccTicker == 'Risk' || searchAccTicker == 'Returns') ? '%' : query_var[index].suffix;
//   // console.log('value + suffix; -->', value + suffix);
//   if(value=='-'){
//     return value;
//   }else{
//     return value + suffix;
//   }
// }

// Commenting delete all functionality, enable if required in the future
{/*<div className="delete-all" onClick={props.clearData}>Delete All</div>*/}

// const DeleteAll = (props) => {
//   return(
//     <span className="cus-pointer" onClick={() => props.deleteClientConfirmation()} style={{width: '100%', display: 'block'}}>
//       <p className="mb-0 justify-content-center">
//         <i className="far fa-fw fa-trash-alt"></i>
//       </p>
//       {/* <hr /> */}
//     </span>
//   )
// }

// const DeleteEntry = (props) => {
//   return(
//     <div className="text-center delete-row cus-pointer" onClick={() => props.deleteClientConfirmation(props.row)}><i className="far fa-fw fa-trash-alt"></i></div>
//   )
// }

// // Temporary solution, delete it after fixing the table header issue
// const EmptyTable = ({finalData, searchAccTicker, tableWidth, tableHeight, clientListLoading}) => {
//   return(<Table
//           dataSource={finalData}
//           scroll={{ x: tableWidth, y: tableHeight }}
//           pagination={false}
//           rowKey={"id"}
//           locale={{
//             emptyText: 'Loading...'
//           }}
//           loading={{
//             spinning: clientListLoading,
//             indicator: <ShowSpinner/>
//           }}
//         >
//
//           <ColumnGroup>
//
//             <Column
//               title={<ColumnSearchFilter
//                 title="Name"
//                 filterName="Name"
//                 selector="name"
//               />}
//               className="acc-details-content"
//               render={row => {
//                 return(
//                   <EllipsisTooltip
//                     className="cus-pointer"
//                     onClick={() => {}}
//                     title={row.name}>
//                     {row.name}
//                   </EllipsisTooltip>
//                 )
//               }}
//               width="180px"
//             />
//
//             <Column
//               title={<ColumnSearchFilter
//                 title="Account No"
//                 filterName="Account No"
//                 selector="acc_no"
//                 center
//               />}
//               dataIndex="acc_no"
//               key="acc_no"
//               align="center"
//               className="acc-details-content"
//               width="175px"
//             />
//             <Column
//               title={<ColumnSearchFilter
//                 title="Custodian"
//                 filterName="Custodian"
//                 selector="custodian"
//                 center
//               />}
//               dataIndex="custodian"
//               key="custodian"
//               align="center"
//               className="acc-details-content light-right-border"
//               width="180px"
//             />
//
// {searchAccTicker &&
//               <Column title={<div className="highlight">{searchAccTicker}</div>}
//                 className="search-highlight acc-details-content"
//                 render={row => {
//                   return(
//                     <SearchColContent
//                       row={row}
//                       query_var={''}
//                       searchField={''}
//                       searchAccTicker={searchAccTicker}
//                     />
//                   )
//                 }}
//                 align="center"
//                 width="180px"
//               />
//             }
//
//
//           </ColumnGroup>
//
//
//         </Table>)
// }

// class EllipsisTooltip extends React.Component {
//   render() {
//     const { className, onClick } = this.props;
//     return (
//         <div
//           ref={node => this.container = node}
//           className={className} onClick={onClick}
//           style={{
//             textOverflow: 'ellipsis',
//             overflow: 'hidden',
//             whiteSpace: 'nowrap'
//           }}
//           title={this.props.title}
//           >
//             {this.props.children}
//         </div>
//     )
//   }
// }

import React, { Fragment } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

// import { Security, LoginCallback } from '@okta/okta-react'
// import { toRelativeUrl } from '@okta/okta-auth-js'
// import { oktaAuth } from 'oktaAuth'

import SettingsContainer from './pages/SettingsContainer'
import FundsContainer from './pages/FundsContainer'
import ReportsContainerV2 from './pages/ReportsContainerV2'

import WidgetLogin from 'layouts/WebDashboard/WidgetLogin'
import JoinPage from 'layouts/WebDashboard/LandingPage/JoinPage'
import SearchResultsV3 from 'layouts/WebDashboard/SearchResultsV3/Page'
import AccPage from 'layouts/WebDashboard/AccountsPage/Page'
import Login from 'layouts/WebDashboard/Login'
import RedirectLogin from 'layouts/WebDashboard/RedirectLogin'
import RegisterProfile from 'layouts/WebDashboard/RegisterProfile'
import RegisterProfileApple from 'layouts/WebDashboard/RegisterProfileApple'
import ForgotPassword from 'layouts/WebDashboard/ForgotPassword'
import ChangePassword from 'layouts/WebDashboard/ChangePassword'
import ForgotPasswordThankYou from 'layouts/WebDashboard/ForgotPassword/ThankYouMsg'
import PasswordReset from 'layouts/WebDashboard/PasswordReset'
import DeleteAccount from 'layouts/WebDashboard/DeleteAccount'
import InvalidTokenPage from 'layouts/WebDashboard/PasswordReset/InvalidToken'
import PortfolioReportV2 from 'layouts/WebDashboard/PortfolioReportV2'
import PortfolioOverviewReport from 'layouts/WebDashboard/PortfolioOverviewReport'
import SignOut from 'layouts/WebDashboard/Login/SignOut'
import BuildPortfolio from 'layouts/WebDashboard/BuildPortfolio'
import Enhancer from 'layouts/WebDashboard/Enhancer'
import ClientPortfolioV2 from 'layouts/WebDashboard/ClientPortfolioV2'
import PdfPage from './layouts/WebDashboard/BuilderReport/PDFPage'
import EnhancerInterimReport from './layouts/WebDashboard/EnhancerInterimReport'
import ArchivedTable from 'layouts/WebDashboard/Settings/Branding/ArchiveTable'
import Trading from 'layouts/WebDashboard/Trading'
import Validate from 'layouts/WebDashboard/Validate';
import CalendarInviteModal from 'layouts/WebDashboard/Elements/CalendarInviteModal';

import VerifyEmail from 'pages/verifyEmail'
import CallbackTDAmeritrade from 'pages/CallbackTDAmeritrade'
import FourZeroFourContainer from 'pages/404Container'
import Support from 'pages/Support'
// import Maintenance from 'components/Maintenance';
// import TifinWealthMessage from 'components/TifinWealthMessage';

import { PrivateRoute } from 'shared-components'
// import { Loader } from 'components/Loader'

import config from './env'
import { routerPages } from './routermenu'
import RenderRegisterPage from './RenderRegisterPage';

class ChokidarRouter extends React.Component {
  // componentWillMount() {
  //   const { setOktaAuthLoader } = this.props
  //   if (oktaAuth &&
  //     oktaAuth.token &&
  //     setOktaAuthLoader &&
  //     oktaAuth.token.isLoginRedirect()
  //   ) {
  //     setOktaAuthLoader(true);
  //     console.log('oktaAuth.token --> ', oktaAuth.token)
  //     const { oktaAuthLoginRequest } = this.props;
  //     oktaAuth.token.parseFromUrl().then(tokenResponse => {
  //       console.log('tokenResponse --> ', tokenResponse)
  //       try {
  //         if (tokenResponse && tokenResponse.tokens) {
  //           oktaAuth.tokenManager.setTokens(tokenResponse.tokens);
  //           const { accessToken } = tokenResponse.tokens
  //           if (accessToken && accessToken.accessToken) {
  //             oktaAuthLoginRequest({
  //               token: accessToken.accessToken,
  //             }, () => {
  //               setOktaAuthLoader(false);
  //             });
  //           } else {
  //             throw '--- Missing Access Token ---'
  //           }
  //         } else {
  //           throw '--- OKTA access token api failed ---'
  //         }
  //       } catch (error) {
  //         console.log(error)
  //         setOktaAuthLoader(false);
  //       }
  //     });
  //   }
  // }

  // restoreOriginalUri = async (_oktaAuth, originalUri) => {
  //   console.log('_oktaAuth --> ', _oktaAuth)
  //   console.log('originalUri --> ', originalUri)
  //   // this.props.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  // };

  render() {
    const { Layout, location, oktaAuthLoader } = this.props

    // MAINTENANCE PAGE ONLY
    // let loadBody = <Route path="/" component={Maintenance} />
    // let defaultLoad = (
    //   <Switch>
    //     <Redirect exact path="/" to="/maintenance" />
    //     <Route exact path="/maintenance" component={Maintenance} />
    //     <Redirect to="/maintenance" />
    //   </Switch>
    // )

    // UN_COMMENT ALL ROUTE WHEN YOU REMOVE MAINTENANCE PAGE
    let loadBody = <PrivateRoute path="/" bypass component={Layout} />
    let defaultLoad = (
      <Switch>
        {/*process.env.REACT_APP_SUB_DOMAIN === 'advisor' ? <Redirect exact path="/" to="/securities" /> : null*/}
        <Redirect exact path="/" to="/securities" />
        <Route exact path="/securities" component={SearchResultsV3} />
        <PrivateRoute path="/investments" component={AccPage} />
        <PrivateRoute path="/build-portfolio" component={BuildPortfolio} />
        <PrivateRoute path="/enhancer" component={Enhancer} />
        <PrivateRoute path="/advisor/accountV2" component={ClientPortfolioV2} />
        <PrivateRoute path="/settings" component={SettingsContainer} />
        <PrivateRoute path="/archived/proposals" component={ArchivedTable} />
        <PrivateRoute path={config.tdCallbackPath} component={CallbackTDAmeritrade} />
        <Route path="/support" component={Support} />
        <Route exact path="/login" component={Login} />
        {process.env.REACT_APP_ENV === 'prod' ? (
          <Route path='/join' component={() => { // quick hotfix
            window.location.href = 'https://advisor.magnifi.com/pricing/';
            return null;
          }}/>
        ) : (
          <Route path="/join" exact component={JoinPage} />
        )}
        <Route path="/verify/email" component={VerifyEmail} />
        <Route exact path="/register/widget" component={WidgetLogin} />
        <Route exact path="/redirect-login" component={RedirectLogin} />
        <Route exact path="/signout" component={SignOut} />
        <Route exact path="/register-profile/:id" component={RegisterProfile} />
        <Route exact path="/register-profile/a/:id" component={RegisterProfileApple} />
        <Route exact path="/register" component={RenderRegisterPage} />
        <Route exact path="/register/trading" component={Trading} />
        <Route exact path="/trading/review" component={Trading} />
        <Route exact path="/trading/bank/add" component={Trading} />
        <Route exact path="/forgotpwd" component={ForgotPassword} />
        <Route exact path="/changepwd" component={ChangePassword} />
        <Route exact path="/forgotpwdthankyou" component={ForgotPasswordThankYou} />
        <Route exact path="/invalidtoken" component={InvalidTokenPage} />
        <Route exact path="/password-reset" component={PasswordReset} />
        <Route exact path="/delete-account" component={DeleteAccount} />
        <Route path="/search-results" component={FundsContainer} />
        <Route path="/reports-v2" component={ReportsContainerV2} />
        <Route path="/portfolio-reports-v2" component={PortfolioReportV2} />
        <Route path="/portfolio-overview-report" component={PortfolioOverviewReport} />
        <Route path="/builder-report" component={PdfPage} />
        <Route path="/rec-enhancer" component={EnhancerInterimReport} />
        {/* <Route path='/login/callback' component={LoginCallback} /> */}
        <Route path="/auth/callback" component={Validate} />
        <Route path="/404" component={FourZeroFourContainer} />
        <Redirect to="/404" />
      </Switch>
    )


    // console.log(location.pathname);
    // console.log(Object.keys(routerPages), location.pathname.split('/'));
    // console.log(Object.keys(routerPages).includes(location.pathname));

    let primaryPath = location.pathname.split('/')
    primaryPath = primaryPath[0] === '' ? primaryPath[1] : primaryPath[0]
    // console.log(primaryPath);
    // console.log(Object.keys(routerPages));
    // console.log(Object.keys(routerPages).find(e => e === location.pathname));

    const foundRoute = Object.keys(routerPages).find(
      e => e === location.pathname
    )
    // console.log(foundRoute);
    if (foundRoute === undefined || typeof foundRoute === 'undefined') {
      loadBody = defaultLoad
    }

    // if (location.pathname && typeof routerPages[location.pathname] === 'undefined') {
    //   loadBody = defaultLoad;
    // }
    const imgTagsArr = document.body.getElementsByTagName('img')
    if (imgTagsArr && imgTagsArr.length > 0) {
      for (var i = 0; i < imgTagsArr.length; i++) {
        if (imgTagsArr[i] && imgTagsArr[i].getAttribute) {
          let zemantaSRC = imgTagsArr[i].getAttribute('src')
          if (zemantaSRC && zemantaSRC.includes('zemanta')) {
            console.log('===== FOUND ZEMANTA =====')
            console.log(zemantaSRC, imgTagsArr[i])
            console.log('===== REMOVING ZEMANTA =====')
            imgTagsArr[i].remove()
            console.log('=========================')
          }
        }
      }
    }

    // return (
    //   <Security oktaAuth={oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
    //     {oktaAuthLoader && <Loader loading />}
    //     {window.innerWidth <= 1023 ? defaultLoad : loadBody}
    //   </Security>
    // )
    return (
      <>
        {window.innerWidth <= 1023 ? defaultLoad : loadBody}
        <Fragment>
          <CalendarInviteModal />
        </Fragment>
      </>
    )
  }
}

export default ChokidarRouter

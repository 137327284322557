import React, { Component } from 'react'
import { Redirect, Link, withRouter } from 'react-router-dom'
import {
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import { connect } from 'react-redux'
import { setAuthState } from 'actions/auth'
import { PrimaryButton, SecondaryButton, TickIcon } from 'shared-components'
import { getQuery } from 'common/auth-utils';

class AccountConfirmed extends Component {
  handleCompleteRegistration = () => {
    const { setAuthState } = this.props;
    setAuthState({
      accConfirmedModal: false,
    });
    this.props.history.replace('/settings/profile')
  }

  handleStartSearch = () => {
    //Normal/Google SSO user after registration complete, start searching should remember user's selected/typed search criteria
    const q = getQuery()
    const { setAuthState } = this.props;
    setAuthState({
      accConfirmedModal: false,
    });
    if (q) {
      this.props.history.replace('/securities?query='+q)
    }
  }

  render() {
    return (
      <Modal
        isOpen={true}
        centered
        className="registrations-process-modal"
        backdropClassName="registrations-process-modal-backdrop"
      >
        <Form
          className="register-form"
          noValidate={true}
          onSubmit={this.handleSubmit}
        >
          <ModalBody className="ssf-modal__body  pos-rel">
            <div className="registrations-process-modal-container">
              <div className="text-align-center mt-3">
                <i className="fal fa-check-circle registrations-completed-check" />
              </div>
              <p className="content-wrapper text-align-center mt-3 fs-1">Account confirmed</p>
              <div className="cta-wrapper">
                <Button
                  type="button"
                  className="primary-btn"
                  onClick={this.handleCompleteRegistration}
                >
                  Complete Registration
                </Button>
                <Button
                  type="button"
                  className="secondary-btn"
                  onClick={this.handleStartSearch}
                >
                  Start Searching
                </Button>
              </div>
            </div>
          </ModalBody>
        </Form>
      </Modal>
    )
  }
}

const mapStateToProps = ({ register, router }) => ({
  registerFailed: register.registerFailed,
  registerSuccess: register.registerSuccess,
  router,
})

const mapDispatchToProps = {
  setAuthState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountConfirmed))

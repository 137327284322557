import React from 'react';
import {
  Card,
  CardBody,
  CardText,
} from 'reactstrap';

export const SuggestionThanks = props => (
  <Card>
    <CardBody>
      <CardText>Thank you for your suggestion.</CardText>
    </CardBody>
  </Card>
);

export default {
  SuggestionThanks,
};

import React from 'react';
import { Modal, ModalBody, ButtonGroup, Button, Spinner } from 'reactstrap';
import { CloseButton } from 'shared-components';
import { doNotLeaveButtons } from '../../DataSet';

import './doNotLeave.scss';

const DoNotLeave = (props) => {
    const { open, cancel, trendingSearches, history, openIndidualPop, openFinacPop } = props;
    const redirectTo = (link) => {
        window.location.href = `/securities?query=${encodeURIComponent(link)}&search_type=${encodeURIComponent('exit_popup_search')}`;
    }
    const onClose = () => {
        cancel();
    }
    let newTrendingSearches = { ...trendingSearches };
    // newTrendingSearches.searches.length = 5;
    return (<Modal
        isOpen={open}
        toggle={onClose}
        centered
        keyboard={true}
        backdrop
        className="premium-msg-modal modalFeature"
        backdropClassName="premium-feature-process-modal-backdrop"
    >
        <ModalBody className="ssf-modal__body">
            <div className="premium-feature-process-modal-container">
                <div className="header-wrapper">
                    <h1 className="text-align-center pb-2 modal_title_box">Before you go, please select the option that best describes you:</h1>
                    {/* <p className="text-align-center pb-2 h3_Title">Try these popular searches</p> */}
                    <p className="text-align-center pb-2 h3_Title"></p>
                </div>
                <i className="fal fa-times-circle premium-feature-completed-close" onClick={onClose}></i>
                <div className="botBtn">
                    {/* {newTrendingSearches.isLoading && <Spinner />}
                        {!newTrendingSearches.isLoading && newTrendingSearches.searches.map((val, i) =>
                            <>
                            {(i<5) ? <Button key={val.query} type="button" className="btns-do-not-leave" onClick={() => redirectTo(val.query)} >
                                {val.query}
                            </Button> : null}
                            </>
                        )} */}
                    <Button type="button" className="btns-do-not-leave" onClick={() => {
                        cancel();
                        openIndidualPop();
                    }}>
                        An Individual Investor
                    </Button>
                    <Button type="button" className="btns-do-not-leave" onClick={() => {
                        cancel();
                        openFinacPop();
                    }}>
                        A Financial Professional
                    </Button>
                </div>
            </div>
        </ModalBody>
    </Modal>
    )
}

export default DoNotLeave;
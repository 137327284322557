import React, { useContext } from 'react';
// import { Row, Col, Table } from 'reactstrap';
import { withHeaderFooter } from '../shared/HeaderFooter';
import ActReportContext from '../ActReportContext';
import { customColors } from '../shared/Utils';
import { AttrChartsRelFund } from './Common';
// import { ViewChart } from './ViewChart';
import { calculatorOptions } from 'DataSet';
import { concatAttrs } from '../shared/Utils';
import BestTickerBadge from '../shared/BestTickerBadge';
import { getNest, numDc } from '../../../Utils'
import { BenchmarkDisclaimer } from 'components/reportsV2/sf/Common'

const RelevantFunds = withHeaderFooter((props) => {
  const reportData = useContext(ActReportContext)
  const { allFunds, allAttrs, compare: [{ attrs, funds, date, attrsWithScore, selectedCalculator }], flags, search: { query, correctedQuery }, printSelection, selectedFundDetails, benchmark, dateRange } = reportData;
  //instead of 8, took 6 because of addition of vs S&P 500 and benchmark row
  const top8Attrs = printSelection.includes('rel-funds') ? attrs.slice(0, 6) : attrs.slice(0, 8)
  const selectedCalcOption = Object.keys(calculatorOptions).find(key => calculatorOptions[key] === selectedCalculator);
  // const getMultiplier = (col) => {
  //   const multiplierList = ['bid-ask spread', 'tracking error', 'premium to nav',
  //     'trend', 'outperformance 3yr', 'outperformance 5yr', 'outperformance 10yr',
  //     'alpha 5yr', 'alpha 10yr'];
  //   return multiplierList.includes(col) ? 100 : 1;
  // }
  // console.log('reportData --> ', reportData);
  const { bestTicker } = reportData;
  const chartData = attrs.map(a => funds.map((f, i) => {
    const sf = allFunds.find((k) => k.ticker == f.ticker)
    let labelNA = sf ? ((a === 'bid-ask spread' || a === 'premium to nav' || a === 'volume') && sf.type == 'MF' && f[a] == 0) ? 'NA' : '' : ''
    const selectedAttr = allAttrs.find(item => item.name.toLowerCase() == a.toLowerCase());
    const multiplier = selectedAttr ? selectedAttr.multiplier : 1;
    const attrValue = selectedAttr ? f._sfStats[selectedAttr.col] : f[a];
    if (attrValue === null) { labelNA = 'NA' }
    const decimalPrec = selectedAttr.col === 'weight_bid_ask' ? 3 : 2;
    let value = ''
    if (getNest(['_sfStats', selectedAttr.colOff], f)) {
      value = 0;
      labelNA = 'Inactive'
    }
    else {
      value = numDc(getNest(['_sfStats', selectedAttr.col], f) * multiplier, decimalPrec)
    }
    // const value = fixedValue((attrValue && labelNA !== 'NA' ? attrValue : 0) * multiplier);
    return ({
      name: sf ? sf.ticker_short : f.ticker,
      value,
      color: customColors[i % customColors.length],
      labelNA,
      attr: a,
      relFunds: true
    })
  }));
  // console.log(chartData, 'chartData')
  let followedByAttrs = [...attrs];
  followedByAttrs.shift();
  const joinAttrs = followedByAttrs ? concatAttrs(followedByAttrs) : '';
  let showSPYDisclaimer = false
  attrsWithScore.forEach(item => {
    if (!showSPYDisclaimer) {
      if (item.name.toLowerCase().includes('capture') || item.name.toLowerCase().includes('alpha') || item.name.toLowerCase().includes('outperformance')) {
        showSPYDisclaimer = true
      }
    }
  })
  // console.log('dateRange --> ', dateRange)
  return (
    <React.Fragment>
      <div className="content">
        {printSelection.includes('rel-funds') && (
          <div className="pr-v2-header">
            <h3>Relevant Funds</h3>
            <p>
              We scanned through the selected universe of ETFs, Mutual Funds, Model Portfolios and Managed Accounts to
              identify the relevant investment options for the queries entered by you. The Universe of relevant investment
              options were the ones the highest exposure to the queries entered.
            </p>
          </div>
        )}
        {printSelection.includes('sel-criteria') &&
          <div className="pr-v2-relevant-funds">
            <div>
              <div className="pr-v2-sec-title">
                <h3>Selection Criteria</h3>
                <p>Among the universe of investment options, we used the selection criteria shown below. The selection criteria is driven by {attrs[0] == 'return' ? 'historical returns' : attrs[0]}{joinAttrs ? `, ${joinAttrs}` : ''}.</p>
              </div>
              <p className="pr-v2-sm-title">What Matters to You</p>
              {/*ticker row*/}
              <div style={{ display: `flex` }}>
                <div style={{ minWidth: `180px` }}>
                  {/*  blank space */}
                </div>
                <div className={'d-flex justify-content-around'} style={{ width: `100%` }}>
                  {chartData[0].map(v => {
                    return <div style={{ width: `${100 / chartData[0].length}%`, maxWidth: `${100 / chartData[0].length}%` }} className={'d-flex justify-content-center align-items-center'} >
                      <p style={{ fontSize: `18px`, position: 'relative' }}>
                        {(v.name && v.name === bestTicker) && (
                          <BestTickerBadge
                            style={{
                              position: 'absolute',
                              left: '-26px',
                            }}
                          />
                        )}
                        {v.name}
                      </p>
                    </div>
                  })}
                </div>
              </div>
              <div xs="12" className="rel-results">
                <AttrChartsRelFund
                  relFunds
                  period={date}
                  attrs={top8Attrs}
                  allAttrs={allAttrs}
                  dateRange={dateRange}
                  chartData={chartData}
                  attrsWithScore={attrsWithScore}
                  selectedCalcOption={selectedCalcOption}
                />
              </div>
              {/*benchmark row*/}
              {/* <BenchmarkRow benchmark={benchmark} selectedFundDetails={selectedFundDetails} chartData={chartData[0]} /> */}
            </div>
          </div>}
        {printSelection.includes('sel-criteria') && showSPYDisclaimer && <BenchmarkDisclaimer style={{ paddingTop: `20px` }} />}
      </div>
    </React.Fragment>
  )
});

export default RelevantFunds;

export const BenchmarkRow = ({ selectedFundDetails, chartData, benchmark }) => {
  return (<div className={'benchmark-bottom'} >
    <div className={'blank-space'}>
      Benchmark
    </div>
    <div className={'data-space d-flex justify-content-around '} >
      {chartData.map(v => {
        let fund = selectedFundDetails.filter(fund => fund.ticker_short === v.name)
        let index = fund.length ? (benchmark === 'MPT' ? fund[0].benchmark_primary_index : fund[0].category_proxy_name) : ''
        return <div style={{ width: `${100 / selectedFundDetails.length}%`, maxWidth: `${100 / selectedFundDetails.length}%` }} className={'d-flex data-column justify-content-center align-items-center'} >
          <p >
            {index}
          </p>
        </div>
      })}
    </div>
  </div>)
}

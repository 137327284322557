// import React from 'react';
// import { Row, Col } from 'reactstrap';
//
// export const GlossaryPage = () => {
//   return (
//     <React.Fragment>
//       <div className="content">
//         <div className="pr-v2-glossary">
//           <Row>
//             <Col>
//               <h4>Glossary</h4>
//               <p>Fee: Means expense ratio</p>
//               <br />
//               <p>Risk: Means standard deviation</p>
//               <br />
//               <h4>Disclosures</h4>
//               <p>TIFIN Wealth Tech LLC and its parent, affiliates, subsidiaries, agents, sub-agents and vendors including those providing Information as defined below or providing technology platforms, workflow, software or services (“Tifin Wealth”) disclaim any and all liability for the data, information, projections, forecasts, estimates, overlays, diagnosis, proposals, returns, reporting, recommendations and analysis (“Information”) herein including without limitation, any express or implied representations or warranties for the Information or errors contained in, or omissions from, the Information. Tifin Wealth shall not be liable for any loss or liability suffered by you resulting from the provision to you of the Information or your use or reliance in any way on the Information. Information cannot be guaranteed to be timely, secure or error free. Information provided is applicable only as of its date. Tifin Wealth has not undertaken, nor will Tifin Wealth undertake, any duty to update Information or otherwise advise you of changes. The validity of recommendations and analyses contained herein is dependent upon the accuracy and thoroughness of the data and information provided by you. The use of different data and information will result in different results.
//                 This information in this software is for general information purposes only. It is not intended as personal financial or investment advice and should not be construed or relied on as such. No information contained within this software should be construed or relied upon as providing recommendations in relation to any financial product or investment strategy. The information contained herein should in no way be construed or interpreted as a solicitation to sell or offer to sell advisory services to any residents of any State where notice-filed or otherwise legally permitted. It is important to understand that it is your responsibility to determine if, and how, the suggestions should be implemented. Analyses, suggested asset allocations or recommendations including replacement securities are based on information you or your advisor provided, and investment assumptions pertaining to current and proposed portfolios and individual asset classes. You should carefully consider all relevant factors in making these decisions and you are encouraged to consult with your professional advisors. For example, any Information presented about tax considerations affecting client financial transactions or arrangements is not intended as tax advice and should not be relied upon for the purpose of avoiding any tax penalties. Tifin Wealth and advisors do not provide tax, accounting or legal advice. Please review any planned financial transactions or arrangements that may have tax, accounting or legal implications with your own tax, legal and accounting advisors.</p>
//               <br />
//               <p>You are not required to transact business with your advisor or to implement any of the recommendations in this proposal.</p>
//               <br />
//               <p>This information was generated by an investment analysis tool that produces simulations and statistical analyses that present the likelihood of various investment outcomes if certain investments are made or certain investment strategies or styles are undertaken. Past performance does not guarantee future results. Tifin Wealth does not guarantee any minimum level of investment performance or the success of any portfolio or investment strategy. Results may vary with each use and over time. There is a potential for loss in any investment, including the loss of capital. All investments involve risk, and different types of investments involve varying degrees of risk. Investment recommendations will not always be profitable. No representation is being made that any client account will or is likely to achieve profit or losses similar to those shown in the chart above. Hypothetical performance information shown in text, charts, tables and graphs is provided for informational purposes only and should not be considered investment advice or a recommendation to buy or sell any particular security.</p>
//               <p>
//                 There are certain limitations inherent in hypothetical results like those portrayed, particularly that such hypothetical results do not reflect trading in actual client accounts and do not reflect the impact that material economic and market factors may have had on the investments chosen had the adviser actually been managing client accounts. Unlike an actual performance record, hypothetical performance results do not represent actual trading. These types of simulations, in general, benefit compared to actual performance results because such simulations are designed with the benefit of hindsight. In addition, simulated trading does not involve or take into account financial risk and does not take into account that material and market factors may have on investments chosen, all of which can adversely affect actual trading results and performance.
//               </p>
//               <br />
//               <p>Portfolio information powered by Tifin Wealth based on inputs from your Advisor. Underlying data provided by Morningstar, and other publicly available data sources. The data used to calculate the portfolio performance was obtained from sources deemed reliable and then organized and presented by Tifin Wealth.</p>
//               <br /><br />
//               <p>
//                 Performance and standard deviation figures presented herein are calculated by taking historical daily market values over the past 3, 5, and 10 years of the selected underlying securities selected by your Advisor and using inputs provided by your Advisor. The hypothetical portfolio returns to create average return and portfolio standard deviation. Return information is calculated using a time-weighted calculation. Such methods are deemed hypothetical or backtested performance. The returns and standard deviation assumes a buy and hold strategy based on current portfolio holdings and periodic rebalancing. Backtested performance is hypothetical (it does not reflect trading in actual accounts) and is provided for informational purposes only. Performance information is provided to indicate historical performance had you invested in the portfolios over the relevant period and is NOT an indicator of future actual results.
//               </p>
//               <br />
//               <br />
//               <p>Actual performance for your account may be materially lower than that of the performance should you have actually invested in such assets and held them for an identical period.</p>
//               <br />
//               <p>Performance results are net of your Advisors fee.</p>
//               <br />
//               <p>Backtested performance results have certain inherent limitations. Backtested performance presented herein includes a management fee as entered by your Advisor, but will not include any trading fees.</p>
//               <br />
//               <p>Calculations also assume that there are no additional deposits or withdrawals during the period indicated.</p>
//               <br />
//               <p>Backtested performance returns results reflect performance of a strategy not historically offered to investors and do NOT represent returns that any investor actually attained.</p>
//               <br />
//               <p>
//                 It is important to understand that the assumption of rebalancing has an impact on the returns reported. The return is calculated with the assumption that the portfolio is perfectly in balance throughout the calculation period, which is not an accurate assumption when compared to actual returns. Since trades have not actually been executed, results may have under-or over-compensated for the impact, if any, of certain market factors, such as lack of liquidity, and may not reflect the impact that certain economic or market factors may have had on the decision-making process. In addition, backtested performance assumes that all the assets used were available during the period presented and also assumes that the markets were sufficiently liquid to permit the types of trading used to calculate the performance.
//               </p>
//               <br />
//               <p>Performance does not reflect the deduction of other fees or expenses, including but not limited to custodial fees, maintenance fees, or any other charges from you broker, advisor, or custodian.</p>
//               <br />
//               <p>Performance results shown assume the reinvestment of dividends. The data used to calculate the portfolio performance was obtained from sources deemed reliable and then organized and presented by Tifin Wealth.</p>
//               <br />
//               <p>The performance calculations have not been audited by any third party.</p>
//               <br />
//               <p>Actual performance of client portfolios may differ materially due to the timing related to additional client deposits or withdrawals and the actual deployment and investment of a client portfolio, the length of time various positions are held, the client’s objectives and restrictions, and fees and expenses incurred by any specific individual portfolio.</p>
//             </Col>
//             {/* <Col>
//                 <h4>Glossary and Disclosures</h4>
//                 <p>THIS INFORMATION IS FOR THE IMMEDIATE USE OF THE RECIPIENT, ONLY FOR DISCUSSION PURPOSES AND IS SUBJECT TO COMPLETION OR AMENDMENT. PLEASE READ THIS DISCLOSURE IN ITS ENTIRETY.</p>
//
//                 <h5>Section I. Introduction</h5>
//                 <p>Tifin Wealth and its affiliates, subsidiaries, agents, sub-agents and vendors including those providing Information as defined below or providing technology platforms, workflow, Software or Services (“Provider ” or “Tifin Wealth”) disclaim any and all liability for the data, information, projections, forecasts, estimates, overlays, diagnosis, proposals, returns, reporting, recommendations and analysis (“Information”) herein including without limitation, any express or implied representations or warranties for the Information or errors contained in, or omissions from, the Information. The Provider shall not be liable for any loss or liability suffered by you resulting from the provision to you of the Information or your use or reliance in any way on the Information. Information cannot be guaranteed to be timely, secure or error free. Information provided is applicable only as of its date. Provider has not undertaken, nor will Provider undertake, any duty to update Information or otherwise advise you of changes. The validity of recommendations and analyses contained herein is dependent upon the accuracy and thoroughness of the data and information provided by you. Theuse of different data and information will result in different results.</p>
//
//                 <p>This information in this software is for general information purposes only. It is not intended as personal financial or investment advice and should not be construed or relied on as such. No information contained within this softwareshould be construed or relied upon as providing recommendations in relationto any financial product or investment strategy. Information is not intended to be a specific offer to sell or provide, or a specific invitation to apply for, any financial product, instrument or service that may be mentioned. It is important to understand that it is your responsibility to determine if, and how, the suggestions should be implemented. Analyses, suggested asset allocations and recommendations including replacement securities are based on Information you provided, appropriate financial concepts and investment assumptions pertaining to current and proposed portfolios and individual asset classes. You should carefully consider all relevant factors in making these decisions and you are encouraged to consult with your professional advisors. For example, any Information presented about tax considerations affecting client financial transactions or arrangements is not intended as tax advice and should not be relied upon for the purpose of avoiding any tax penalties. Providers and advisors do not provide tax, accounting or legal advice. Please review any planned financial transactions or arrangements that may have tax, accounting or legal implications with your own tax, legal and accounting advisors. You are not required to transact business with your advisor or to implement any of the recommendations.</p>
//
//                 <h5>Section II. Explanation of Simulations, Returns, Value and Performance</h5>
//               <p>Information related to projections, returns, estimated additional returns, increases in portfolio values and performance and risk forecasts are estimates only and are not a guarantee. Estimated returns are a combinationof current returns, historical or simulated returns and projections on future return trends. Investments entail the acceptance of risk of a loss of capital invested. Such losses are not reflected in simulated performance or returns within the software. Estimated returns are derived from information you provide including without limitation fees, security weight, portfolio holdings and risk tolerance. Please carefully consider and review such inputs. Any performance portrayed should be discounted in the event of material negative market or economic conditions.</p>
//
//               <p>Estimated returns are derived from simulated models and scenario analysis (“Model”) when sufficient historical data is unavailable. These hypothetical returns do not reflect actual trading and therefore do not account for actual financial risks, economic conditions, taxes, fees or expenses. Projected performance generally includes projected reinvestment of dividends and other earnings. Results from a Model may vary with each useand over time. Material changes in conditions, objectives or investment strategies of any Model portfolio during the period portrayed will affect, potentially decreasing,the returns portrayed. Model returns may be estimated using the projected exposure of an investment to fees, tax management, risks and other estimated factor shifts of the scenario or back test, and/or estimated and/or actual returns of a proxy index or instrument. Actual market risks cannot be considered.</p>
//
//               <p>Estimated returns should not be a primary basis for any investment analysis.These results are based on simulated or hypothetical performance results that have certain inherent limitations. Unlike the results shown in an actual performance record, these estimates do not represent actual trading. Also, because these trades have not actually been executed, these results may have under-or over-compensated for the impact, if any, of certain market factors, such as lack of liquidity. Simulated or hypothetical trading programs in general are also subject to the fact that they are designed with the benefitof hindsight. Securities may not exhibit the same exposure to factors as theydid in the past, the individual factors may not correlate the same way they did historically, and the factors may not exhibit the same returns or volatility as they did before. Any charts including portfolio estimated or projected returns including estimated increases in portfolio value, returns or simulations or tax alpha are just components (such as your investment inputs and goals) a Model Provider uses to determine custom recommendations.</p>
//
//               <p>By the nature of a simulation, portfolio estimated or projected returns including estimated increase in portfolio value and projections represent probabilities and possible outcomes, not promises of future performance. Securities prices fluctuate in value unpredictably, and returns cannot be predicted with certainty. Any predictions generated regarding the likelihood of various investment outcomes are hypothetical in nature, do not reflect actual investment results, and are not a guarantee of future results.</p>
//
//               <p>Benchmarks in charts are selected by advisors, but may be suggested by Tifin Wealth’s model based on the allocation that most closely resembles the user’s risk profile and investment objectives. Comparison of the Personalized portfolio’s performance to any particular benchmark’s performance is not without complications. Among other things, the portfolio may be actively managed, potentially more or less diversified than the benchmark, incur expenses, and may have additional limitations or restrictions. Accordingly, any such comparisons are for informational purposes only and should not be construed as having a direct association.</p>
//            </Col> */}
//           </Row>
//         </div>
//       </div>
//     </React.Fragment>
//   )
// };

import React from 'react';
import { Row, Col } from 'reactstrap';
// import { withHeaderFooter } from '../shared/HeaderFooter';

export const GlossaryPage = () => {
  return (
    <React.Fragment>
      <div className="content">
        <div className="pr-v2-glossary">
          <Row>
            <Col>
              <h4>Glossary and Disclosures</h4>
              <p style={{ margin: 0 }}>THIS INFORMATION IS FOR THE IMMEDIATE USE OF THE RECIPIENT, ONLY FOR DISCUSSION PURPOSES AND IS SUBJECT TO COMPLETION OR AMENDMENT. PLEASE READ THIS DISCLOSURE IN ITS ENTIRETY.</p>
              <br />
              <br />

              <h4>Glossary</h4>

              <p style={{ margin: 0 }}>Annualized total return: The amount of money earned by an investment each year over a given period of time assuming the annual return was compounded</p>
              <br />

              <p style={{ margin: 0 }}>Comparison ETF: Performance, standard deviation, and other statistical measures shown are compared to the performance of an Exchange Traded Fund (“ETF”). Equity securities are
                compared against SPY and fixed income security are compared to AGG. ETFs are used for comparison purposes and may not be an appropriate comparison or analogous to an appropriate benchmark for the client</p>
              <br />

              <p style={{ margin: 0 }}>Diversification: An investment strategy by which investors spread their portfolio across different types of securities and asset classes to reduce the risk of market volatility. Diversification may not protect against market risk or loss of principal</p>
              <br />

              <p style={{ margin: 0 }}>Down Market Capture Ratio: A statistical measure of an investment manager's overall performance in down-markets. It is used to evaluate how well an investment manager performed relative to an index during periods when that index has dropped</p>
              <br />

              <p style={{ margin: 0 }}>Drawdown: The expected peak-to-trough decline of a portfolio. E.g. If a trading account has $10,000 in it, and the funds drop to $7,000 before moving back above $10,000, then the drawdown in this portfolio is 30%</p>
              <br />

              <p style={{ margin: 0 }}>Other: A catchall asset class that includes assets like cash, forward contracts, futures contracts, options, commodities, structured products, and other alternative investments</p>
              <br />

              <p style={{ margin: 0 }}>Risk: Means standard deviation</p>
              <br />

              <p style={{ margin: 0 }}>Up Market Capture Ratio: A statistical measure of an investment manager's overall performance in up-markets. It is used to evaluate how well an investment manager performed and weakness. relative to an index during periods when that index has risen</p>
              <br />

              <p style={{ margin: 0 }}>Volatility: Means standard deviation and is one indicator of portfolio risk. Too high means the portfolio is at risk of large losses. Too low, the portfolio’s potential may be restricted.</p>
              <br />
              <br />

              <h4>Disclosures</h4>

              <p style={{ margin: 0 }}>TIFIN Wealth Tech LLC and its parent, affiliates, subsidiaries, agents, sub-agents and vendors including those providing Information as defined below or providing technology platforms, workflow, software or services (“Tifin Wealth”) disclaim any and all liability for the data, information, projections, forecasts, estimates, overlays, diagnosis, proposals, returns, reporting, recommendations and analysis (“Information”) herein including without limitation, any express or implied representations or warranties for the Information or errors contained in, or omissions from, the Information. Tifin Wealth shall not be liable for any loss or liability suffered by you resulting from the provision to you of the Information or your use or reliance in any way on the Information. Information cannot be guaranteed to be timely, secure or error free. Information provided is applicable only as of its date. Tifin Wealth has not undertaken, nor will Tifin Wealth undertake, any duty to update Information or otherwise advise you of changes. The validity of recommendations and analyses contained herein is dependent upon the accuracy and thoroughness of the data and information provided by you. The use of different data and information will result in different results.</p>
              <br />

              <p style={{ margin: 0 }}>This information in this software is for general information purposes only. It is not intended as personal financial or investment advice and should not be construed or relied on as such. No information contained within this software should be construed or relied upon as providing recommendations in relation to any financial product or investment strategy. The information contained herein should in no way be construed or interpreted as a solicitation to sell or offer to sell advisory services to any residents of any State where notice-filed or otherwise legally permitted. It is important to understand that it is your responsibility to determine if, and how, the suggestions should be implemented. Analyses, suggested asset allocations or recommendations including replacement securities are based on information you or your advisor provided, and investment assumptions pertaining to current and proposed portfolios and individual asset classes. You should carefully consider all relevant factors in making these decisions and you are encouraged to consult with your professional advisors. For example, any Information presented about tax considerations affecting client financial transactions or arrangements is not intended as tax advice and should not be relied upon for the purpose of avoiding any tax penalties. Tifin Wealth and advisors do not provide tax, accounting or legal advice. Please review any planned financial transactions or arrangements that may have tax, accounting or legal implications with your own tax, legal and accounting advisors.</p>
              <br />

              <p style={{ margin: 0 }}>You are not required to transact business with your advisor or to implement any of the recommendations in this proposal.</p>
              <br />

              <p style={{ margin: 0 }}>
                This information was generated by an investment analysis tool that produces simulations and statistical analyses that present the likelihood of various investment outcomes if certain investments are made or certain investment strategies or styles are undertaken. Past performance does not guarantee future results. Tifin Wealth does not guarantee any minimum level of investment performance or the success of any portfolio or investment strategy. Results may vary with each use and over time. There is a potential for loss in any investment, including the loss of capital. All investments involve risk, and different types of investments involve varying degrees of risk. Investment recommendations will not always be profitable. No representation is being made that any client account will or is likely to achieve profit or losses similar to those shown in the chart above.
                <br />
                Hypothetical performance information shown in text, charts, tables and graphs is provided for informational purposes only and should not be considered investment advice or a recommendation to buy or sell any particular security.
              </p>


              {/* <h4>Glossary and Disclosures</h4>
              <p>THIS INFORMATION IS FOR THE IMMEDIATE USE OF THE RECIPIENT, ONLY FOR DISCUSSION PURPOSES AND IS SUBJECT TO COMPLETION OR AMENDMENT. PLEASE READ THIS DISCLOSURE IN ITS ENTIRETY.</p>

              <h5>Section I. Introduction</h5>
              <p>Tifin Wealth and its affiliates, subsidiaries, agents, sub-agents and vendors including those providing Information as defined below or providing technology platforms, workflow, Software or Services (“Provider ” or “Tifin Wealth”) disclaim any and all liability for the data, information, projections, forecasts, estimates, overlays, diagnosis, proposals, returns, reporting, recommendations and analysis (“Information”) herein including without limitation, any express or implied representations or warranties for the Information or errors contained in, or omissions from, the Information. The Provider shall not be liable for any loss or liability suffered by you resulting from the provision to you of the Information or your use or reliance in any way on the Information. Information cannot be guaranteed to be timely, secure or error free. Information provided is applicable only as of its date. Provider has not undertaken, nor will Provider undertake, any duty to update Information or otherwise advise you of changes. The validity of recommendations and analyses contained herein is dependent upon the accuracy and thoroughness of the data and information provided by you. Theuse of different data and information will result in different results.</p>

              <p>This information in this software is for general information purposes only. It is not intended as personal financial or investment advice and should not be construed or relied on as such. No information contained within this softwareshould be construed or relied upon as providing recommendations in relationto any financial product or investment strategy. Information is not intended to be a specific offer to sell or provide, or a specific invitation to apply for, any financial product, instrument or service that may be mentioned. It is important to understand that it is your responsibility to determine if, and how, the suggestions should be implemented. Analyses, suggested asset allocations and recommendations including replacement securities are based on Information you provided, appropriate financial concepts and investment assumptions pertaining to current and proposed portfolios and individual asset classes. You should carefully consider all relevant factors in making these decisions and you are encouraged to consult with your professional advisors. For example, any Information presented about tax considerations affecting client financial transactions or arrangements is not intended as tax advice and should not be relied upon for the purpose of avoiding any tax penalties. Providers and advisors do not provide tax, accounting or legal advice. Please review any planned financial transactions or arrangements that may have tax, accounting or legal implications with your own tax, legal and accounting advisors. You are not required to transact business with your advisor or to implement any of the recommendations.</p>

              <h5>Section II. Explanation of Simulations, Returns, Value and Performance</h5>
              <p>Information related to projections, returns, estimated additional returns, increases in portfolio values and performance and risk forecasts are estimates only and are not a guarantee. Estimated returns are a combinationof current returns, historical or simulated returns and projections on future return trends. Investments entail the acceptance of risk of a loss of capital invested. Such losses are not reflected in simulated performance or returns within the software. Estimated returns are derived from information you provide including without limitation fees, security weight, portfolio holdings and risk tolerance. Please carefully consider and review such inputs. Any performance portrayed should be discounted in the event of material negative market or economic conditions.</p>

              <p>Estimated returns are derived from simulated models and scenario analysis (“Model”) when sufficient historical data is unavailable. These hypothetical returns do not reflect actual trading and therefore do not account for actual financial risks, economic conditions, taxes, fees or expenses. Projected performance generally includes projected reinvestment of dividends and other earnings. Results from a Model may vary with each useand over time. Material changes in conditions, objectives or investment strategies of any Model portfolio during the period portrayed will affect, potentially decreasing,the returns portrayed. Model returns may be estimated using the projected exposure of an investment to fees, tax management, risks and other estimated factor shifts of the scenario or back test, and/or estimated and/or actual returns of a proxy index or instrument. Actual market risks cannot be considered.</p>

              <p>Estimated returns should not be a primary basis for any investment analysis.These results are based on simulated or hypothetical performance results that have certain inherent limitations. Unlike the results shown in an actual performance record, these estimates do not represent actual trading. Also, because these trades have not actually been executed, these results may have under-or over-compensated for the impact, if any, of certain market factors, such as lack of liquidity. Simulated or hypothetical trading programs in general are also subject to the fact that they are designed with the benefitof hindsight. Securities may not exhibit the same exposure to factors as theydid in the past, the individual factors may not correlate the same way they did historically, and the factors may not exhibit the same returns or volatility as they did before. Any charts including portfolio estimated or projected returns including estimated increases in portfolio value, returns or simulations or tax alpha are just components (such as your investment inputs and goals) a Model Provider uses to determine custom recommendations.</p>

              <p>By the nature of a simulation, portfolio estimated or projected returns including estimated increase in portfolio value and projections represent probabilities and possible outcomes, not promises of future performance. Securities prices fluctuate in value unpredictably, and returns cannot be predicted with certainty. Any predictions generated regarding the likelihood of various investment outcomes are hypothetical in nature, do not reflect actual investment results, and are not a guarantee of future results.</p>

              <p>Benchmarks in charts are selected by advisors, but may be suggested by Tifin Wealth’s model based on the allocation that most closely resembles the user’s risk profile and investment objectives. Comparison of the Personalized portfolio’s performance to any particular benchmark’s performance is not without complications. Among other things, the portfolio may be actively managed, potentially more or less diversified than the benchmark, incur expenses, and may have additional limitations or restrictions. Accordingly, any such comparisons are for informational purposes only and should not be construed as having a direct association.</p> */}
              {/* <b>© 2020 Tifin Wealth All Rights Reserved.</b>
               <p>* Performance quoted represents past performance, which is no guarantee or indication of future results. Performance is reported net of investment management fees, where such fee information is available. Performance is reported gross of all other expenses such as custodial, trading and other fees. Performance for some periods may include data from external sources supplied for inclusion in the performance history, whose accuracy has not been verified.</p> */}
              {/* <p>Hypothetical performance shown is back tested and also is not a guarantee of past or future results. The performance quoted is not intended to represent the performance of any particular security. Actual performance of any investment may have differed substantially from the back-tested performance presented, as the performance was calculated with the benefit of hindsight and cannot account for all financial risk that may affect the actual performance. The performance includes the reinvestment of capital gains as well as income related to the back-tested securities, if any. The performance is also shown on a gross-of-fee and net-of-fee basis, as indicated.</p> */}
              {/* <p>Please carefully review the full Disclosures to understand assumed average rate of returns, definitions and other details of the methodology used. The charts depict the historical investment performance of a portfolio or index including capital appreciation / depreciation, dividends and other investment income. Estimated returns are derived from such simulated models or historical back tests and scenario analysis when sufficient historical data is unavailable. These hypothetical returns do not reflect actual trading and therefore do not account for actual market risks, economic conditions, taxes or expenses.</p>
               <p>The Proposal recommendations reflect the Current Portfolio inputs, the results of the Diagnosis and back test methodology described above. Before considering implementation of any Proposal, please consider whether the Current Portfolio and Diagnosis were appropriately tailored to the desired analysis and stated financial goals and objectives. Proposals do not consider all transaction costs and risks such as market risks or service provider fees and expenses such as liquidation charges.</p>
               <p>** Refer to Annual Fees in the glossary of terms for determination of fee level.</p>

               <p style={{width: '100%'}}><b>Assumptions</b></p>

               <b>For Fee Analysis</b>
               <p>Estimated portfolio value computed assuming that the portfolio will increase at the rate of the average annual return, net of costs and fees, derived from the back test of the proposal strategy. The impact of any annual fee savings is computed by liquidating the fee saving every year from the proposed portfolio.</p>

               <b>For Risk Analysis</b>
               <p>Estimated increase in portfolio value computed using average rate of return annualized (net of assumed fees and costs) that is derived from the back test of the current portfolio (without risk overlay) since January’07. The upper and lower bounds use annualized volatility from the back test and cover 90% of the likely values.</p>

               <b>Exchange Traded Funds ("ETFs")</b>
               <p>ETF shares are not redeemable with the issuing fund other than in large creation units. Instead, investors must buy or sell ETF shares in the secondary market with the assistance of a qualified intermediary. In doing so, the investor may incur brokerage commissions and may pay more than net asset value (“NAV”) when buying and receive less than net asset value when selling. The NAV of the ETF shares is calculated each day the national securities exchanges are open for trading as of the close of regular trading on the New York Stock Exchange (“NYSE”), normally 4:00 p.m. Eastern time (the “NAV Calculation Time”). Shares are bought and sold at market price (closing price) not NAV. Market price returns are based on the midpoint of the bid/ask spread at 4:00 pm Eastern Time (when NAV is normally determined).</p>

               <b>Assumptions</b>

               <p>THIS INFORMATION IS FOR THE IMMEDIATE USE OF THE RECIPIENT, ONLY FOR DISCUSSION PURPOSES AND IS SUBJECT TO COMPLETION OR AMENDMENT. PLEASE READ THIS DISCLOSURE IN ITS ENTIRETY AS SECTIONS CROSS REFERENCE AND RELATE TO EACH OTHER.</p>

               <b>Section I. Introduction</b>

               <p>Tifin Wealth and its affiliates, subsidiaries, agents, sub-agents and vendors including those providing Information as defined below or providing technology platforms, workflow, Software or Services (“Provider ” or “Tifin Wealth”) disclaim any and all liability for the data, information, projections, forecasts, estimates, overlays, diagnosis, proposals, returns, reporting, recommendations and analysis (“Information”) herein including without limitation, any express or implied representations or warranties for the Information or errors contained in, or omissions from, the Information. The Provider shall not be liable for any loss or liability suffered by you resulting from the provision to you of the Information or your use or reliance in any way on the Information.</p>
               <p>Information cannot be guaranteed to be timely, secure or error free. Information provided is applicable only as of its date. Provider has not undertaken, nor will Provider undertake, any duty to update Information or otherwise advise you of changes. The validity of recommendations and analyses contained herein is dependent upon the accuracy and thoroughness of the data and information provided by you. The use of different data and information will result in different results.</p>
               <p>This information in this software is for general information purposes only. It is not intended as personal financial or investment advice and should not be construed or relied on as such. No information contained within this software should be construed or relied upon as providing recommendations in relation to any financial product or investment strategy.</p>
               <p>Any subsequent communication with a prospective client will be conducted by a representative that is either registered or qualifies for an exemption or exclusion from registration in the state where the prospective client resides.</p> */}
              {/* <p>Information is not intended to be a specific offer to sell or provide, or a specific invitation to apply for, any financial product, instrument or service that may be mentioned. It is important to understand that it is your responsibility to determine if, and how, the suggestions should be implemented. Analyses suggested asset allocations and recommendations including replacement securities are based on Information you provided, appropriate financial concepts and investment assumptions pertaining to current and proposed portfolios and individual asset classes. You should carefully consider all relevant factors in making these decisions and you are encouraged to consult with your professional advisors. For example, any Information presented about tax considerations affecting client financial transactions or arrangements is not intended as tax advice and should not be relied upon for the purpose of avoiding any tax penalties. Providers and advisors do not provide tax, accounting or legal advice. Please review any planned financial transactions or arrangements that may have tax, accounting or legal implications with your own tax, legal and accounting advisors. You are not required to transact business with your advisor or to implement any of the recommendations.</p> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <p style={{ margin: 0 }}>
                There are certain limitations inherent in hypothetical results like those portrayed, particularly that such hypothetical results do not reflect trading in actual client accounts and do not reflect the impact that material economic and market factors may have had on the investments chosen had the adviser actually been managing client accounts. Unlike an actual performance record, hypothetical performance results do not represent actual trading. These types of simulations, in general, benefit compared to actual performance results because such simulations are designed with the benefit of hindsight. In addition, simulated trading does not involve or take into account financial risk and does not take into account that material and market factors may have on investments chosen, all of which can adversely affect actual trading results and performance.
              </p>
              <br />

              <p style={{ margin: 0 }}>Portfolio information powered by Tifin Wealth based on inputs from your Advisor. Underlying data provided by Morningstar, and other publicly available data sources. The data used to calculate the portfolio performance was obtained from sources deemed reliable and then organized and presented by Tifin Wealth.</p>
              <br />

              <p style={{ margin: 0 }}>Performance and standard deviation figures presented herein are calculated by taking historical daily market values over the past 3, 5, and 10 years of the selected underlying securities selected by your Advisor and using inputs provided by your Advisor. The hypothetical portfolio returns to create average return and portfolio standard deviation. Return information is calculated using a time-weighted calculation. Such methods are deemed hypothetical or backtested performance. The returns and standard deviation assumes a buy and hold strategy based on current portfolio holdings and periodic rebalancing. Backtested performance is hypothetical (it does not reflect trading in actual accounts) and is provided for informational purposes only. Performance information is provided to indicate historical performance had you invested in the portfolios over the relevant period and is NOT an indicator of future actual results.</p>
              <br />

              <p style={{ margin: 0 }}>Actual performance for your account may be materially lower than that of the performance should you have actually invested in such assets and held them for an identical period.</p>
              <br />

              <p style={{ margin: 0 }}>Performance results are net of your Advisors fee.</p>
              <br />

              <p style={{ margin: 0 }}>Backtested performance results have certain inherent limitations. Backtested performance presented herein not include any trading fees. Calculations also assume that there are no additional deposits or withdrawals during the period indicated.</p>
              <br />

              <p style={{ margin: 0 }}>Backtested performance returns results reflect performance of a strategy not historically offered to investors and do NOT represent returns that any investor actually attained.</p>
              <br />

              <p style={{ margin: 0 }}>
                It is important to understand that the assumption of rebalancing has an impact on the returns reported. The return is calculated with the assumption that the portfolio is perfectly in balance throughout the calculation period, which is not an accurate assumption when compared to actual returns. Since trades have not actually been executed, results may have under-or over- compensated for the impact, if any, of certain market factors, such as lack of liquidity, and may not reflect the impact that certain economic or market factors may have had on the decision- making process. In addition, backtested performance assumes that all the assets used were available during the period presented and also assumes that the markets were sufficiently liquid to permit the types of trading used to calculate the performance.
              </p>
              <br />

              <p style={{ margin: 0 }}>
                Performance does not reflect the deduction of other fees or expenses, including but not limited to custodial fees, maintenance fees, or any other charges from you broker, advisor, or custodian.
              </p>
              <br />

              <p style={{ margin: 0 }}>Performance results shown assume the reinvestment of dividends. The data used to calculate the portfolio performance was obtained from sources deemed reliable and then organized and presented by Tifin Wealth.</p>
              <br />

              <p style={{ margin: 0 }}>
                The performance calculations have not been audited by any third party.
                Actual performance of client portfolios may differ materially due to the timing related to additional client deposits or withdrawals and the actual deployment and investment of a client portfolio, the length of time various positions are held, the client’s objectives and restrictions, and fees and expenses incurred by any specific individual portfolio.
              </p>
              <br />

            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
};

export const GlossaryPage2 = () => {
  return (
    <React.Fragment>
      <div className="content">
        <div className="pr-v2-glossary">
          <Row>
            <Col>
              <h4>Glossary and Disclosures</h4>
              <p style={{ margin: 0 }}>THIS INFORMATION IS FOR THE IMMEDIATE USE OF THE RECIPIENT, ONLY FOR DISCUSSION PURPOSES AND IS SUBJECT TO COMPLETION OR AMENDMENT. PLEASE READ THIS DISCLOSURE IN ITS ENTIRETY.</p>
              <br />
              <br />

              <h4>Glossary</h4>

              <p style={{ margin: 0 }}>Correlation: The statistical measure of the degree to which two securities move in relation to each other.</p>
              <br />

              <p style={{ margin: 0 }}>Fee: Means expense ratio</p>
              <br />

              <p style={{ margin: 0 }}>Risk: Means standard deviation</p>
              <br />
              <br />

              <h4>Disclosures</h4>

              <p style={{ margin: 0 }}>TIFIN Wealth Tech LLC and its parent, affiliates, subsidiaries, agents, sub-agents and vendors including those providing Information as defined below or providing technology platforms, workflow, software or services
              (“Tifin Wealth”) disclaim any and all liability for the data, information, projections, forecasts, estimates, overlays, diagnosis, proposals, returns, reporting, recommendations and analysis (“Information”) herein
              including without limitation, any express or implied representations or warranties for the Information or errors contained in, or omissions from, the Information. Tifin Wealth shall not be liable for any loss or liability
              suffered by you resulting from the provision to you of the Information or your use or reliance in any way on the Information. Information cannot be guaranteed to be timely, secure or error free. Information
              provided is applicable only as of its date. Tifin Wealth has not undertaken, nor will Tifin Wealth undertake, any duty to update Information or otherwise advise you of changes. The validity of recommendations and
              analyses contained herein is dependent upon the accuracy and thoroughness of the data and information provided by you. The use of different data and information will result in different results.
              This information in this software is for general information purposes only. It is not intended as personal financial or investment advice and should not be construed or relied on as such. No information contained
              within this software should be construed or relied upon as providing recommendations in relation to any financial product or investment strategy. The information contained herein should in no way be
              construed or interpreted as a solicitation to sell or offer to sell advisory services to any residents of any State where notice-filed or otherwise legally permitted. It is important to understand that it is your
              responsibility to determine if, and how, the suggestions should be implemented. Analyses, suggested asset allocations or recommendations including replacement securities are based on information you or your
              advisor provided, and investment assumptions pertaining to current and proposed portfolios and individual asset classes. You should carefully consider all relevant factors in making these decisions and you are
              encouraged to consult with your professional advisors. For example, any Information presented about tax considerations affecting client financial transactions or arrangements is not intended as tax advice and
              should not be relied upon for the purpose of avoiding any tax penalties. Tifin Wealth and advisors do not provide tax, accounting or legal advice. Please review any planned financial transactions or arrangements that
              may have tax, accounting or legal implications with your own tax, legal and accounting advisors.</p>
              <br />

              <p style={{ margin: 0 }}>You are not required to transact business with your advisor or to implement any of the recommendations in this proposal.</p>
              <br />

              <p style={{ margin: 0 }}>This information was generated by an investment analysis tool that produces simulations and statistical analyses that present the likelihood of various investment outcomes if certain investments are made or
              certain investment strategies or styles are undertaken. Past performance does not guarantee future results. Tifin Wealth does not guarantee any minimum level of investment performance or the success of any
              portfolio or investment strategy. Results may vary with each use and over time. There is a potential for loss in any investment, including the loss of capital. All investments involve risk, and different types of
              investments involve varying degrees of risk. Investment recommendations will not always be profitable. No representation is being made that any client account will or is likely to achieve profit or losses similar to
              those shown in the chart above. Hypothetical performance information shown in text, charts, tables and graphs is provided for informational purposes only and should not be considered investment advice or a
              recommendation to buy or sell any particular security</p>
              <br />

              <p style={{ margin: 0 }}>There are certain limitations inherent in hypothetical results like those portrayed, particularly that such hypothetical results do not reflect trading in actual client accounts and do not reflect the impact that
              material economic and market factors may have had on the investments chosen had the adviser actually been managing client accounts. Unlike an actual performance record, hypothetical performance results do
              not represent actual trading. These types of simulations, in general, benefit compared to actual performance results because such simulations are designed with the benefit of hindsight. In addition, simulated
              trading does not involve or take into account financial risk and does not take into account that material and market factors may have on investments chosen, all of which can adversely affect actual trading results
              and performance.</p>
              <br />

              <p style={{ margin: 0 }}>Portfolio information powered by Tifin Wealth based on inputs from your Advisor. Underlying data provided by Morningstar, and other publicly available data sources. The data used to calculate the portfolio
                performance was obtained from sources deemed reliable and then organized and presented by Tifin Wealth.</p>
              <br />

              <p style={{ margin: 0 }}>
                Performance and standard deviation figures presented herein are calculated by taking historical daily market values over the past 3, 5, and 10 years of the selected underlying securities selected by your Advisor
                and using inputs provided by your Advisor. The hypothetical portfolio returns to create average return and portfolio standard deviation. Return information is calculated using a time-weighted calculation. Such
                methods are deemed hypothetical or backtested performance. The returns and standard deviation assumes a buy and hold strategy based on current portfolio holdings and periodic rebalancing. Backtested
                performance is hypothetical (it does not reflect trading in actual accounts) and is provided for informational purposes only. Performance information is provided to indicate historical performance had you
                invested in the portfolios over the relevant period and is NOT an indicator of future actual results
              </p>
              <br />

              <p style={{ margin: 0 }}>
                Actual performance for your account may be materially lower than that of the performance should you have actually invested in such assets and held them for an identical period.
              </p>
              <br />

            </Col>
          </Row>
          <Row>
            <Col>
              {/*
                <p style={{ margin: 0 }}>Performance results are net of your Advisors fee. </p>
                <br />
              */}

              <p style={{ margin: 0 }}>Backtested performance results have certain inherent limitations. Backtested performance presented herein not include any trading fees</p>
              <br />

              <p style={{ margin: 0 }}>Calculations also assume that there are no additional deposits or withdrawals during the period indicated. </p>
              <br />

              <p style={{ margin: 0 }}>Backtested performance returns results reflect performance of a strategy not historically offered to investors and do NOT represent returns that any investor actually attained.</p>
              <br />

              <p style={{ margin: 0 }}>
                It is important to understand that the assumption of rebalancing has an impact on the returns reported. The return is calculated with the assumption that the portfolio is perfectly in balance throughout the
                calculation period, which is not an accurate assumption when compared to actual returns. Since trades have not actually been executed, results may have under-or over-compensated for the impact, if any, of
                certain market factors, such as lack of liquidity, and may not reflect the impact that certain economic or market factors may have had on the decision-making process. In addition, backtested performance assumes
                that all the assets used were available during the period presented and also assumes that the markets were sufficiently liquid to permit the types of trading used to calculate the performance.
              </p>
              <br />

              <p style={{ margin: 0 }}>
                Performance does not reflect the deduction of other fees or expenses, including but not limited to custodial fees, maintenance fees, or any other charges from you broker, advisor, or custodian.
              </p>
              <br />

              <p style={{ margin: 0 }}>
                Performance results shown assume the reinvestment of dividends. The data used to calculate the portfolio performance was obtained from sources deemed reliable and then organized and presented by Tifin Wealth.
              </p>
              <br />

              <p style={{ margin: 0 }}>
                The performance calculations have not been audited by any third party.
              </p>
              <br />

              <p style={{ margin: 0 }}>
                Actual performance of client portfolios may differ materially due to the timing related to additional client deposits or withdrawals and the actual deployment and investment of a client portfolio, the length of time
                various positions are held, the client’s objectives and restrictions, and fees and expenses incurred by any specific individual portfolio.
              </p>
              <br />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
};

export const GlossaryPage3 = () => {
  return (
    <React.Fragment>
      {/* <div className="content">
       <div className="pr-v2-glossary">
         <Row>
           <Col>
               <p>Projected performance for diagnosis charts (the input portfolio) reflects the deduction of fund fees and any advisory, management and/or miscellaneous fees entered by the advisor. Projected performance for overlay and proposal portfolios reflect the deduction of brokerage fees, if any, (see Glossary of Terms), fund fees, Tifin Wealth’s management fee, and any advisory and/or management fees entered by the advisor. Custodial fees paid to a bank or other organization for safekeeping client funds and securities are not deducted unless the advisor inputting data is responsible for determining the custodial fee and inputs such data. In this case, advisors should consult with the Provider. The projected performance includes the reinvestment of capital gains as well as income related to the back-tested securities. Results generated by an advisor’s custom portfolio module may differ from standard strategy output if changes are made to the Provider’s Model.</p>
               <p>As previously mentioned, projected performance shown is back tested (hypothetical) and is not a guarantee of past or future results. With any investment, there is a potential for loss as well as profit. The performance displayed is not intended to represent the performance of any particular security. Actual performance of any investment may differ substantially from the back-tested performance presented, as the performance was calculated with the benefit of hindsight and cannot account for all financial risk that may affect the actual performance.</p>
               <p>Past performance, whether actual or hypothetical, is not a guarantee performance of any investment product. When actual events and indicators vary from assumptions used, the hypothetical projections may be materially impacted.</p>

               <b>B. Client Portfolio Overlays and Analyses (Hypothetical Back Tested Projections)</b>

               <p>In order to project client portfolio overlays, the following methodology and assumptions are used to back-test hypothetical results: 1) The positions in the client portfolio were purchased on the last business day of 2006 (market closed January 1st) and held until date of report (reporting date) (the “Back Test Period”); 2) There was no inflow or outflow of cash in the portfolio since the 1st of Jan 2007, except through dividend reinvestment, 3) As total returns are used to calculate return series, it is assumed dividends are reinvested in the portfolio, 4) If a position was not active in a historical month, its allocation is assumed to be distributed to the other positions pro-rata; 5) Portfolio size is assumed to be $1mn, when portfolio size is not available. 6) Returns are reported net of some fees and costs such as management fees, if available from the advisor. Please ensure such inputs are accurate; 7) Returns are reported net of Tifin Wealth fees for the overlays. See the Section on tax loss harvesting below for assumptions for the Tax overlay.</p>
               <p>Actual market risks cannot be considered. Portfolio weights are constructed off index weights. No further capital market assumptions are considered. Our weights are based off of Index weights, capital markets weights. See Glossary for benchmarks.</p>
               <p>Past performance, whether actual or hypothetical, is not a guarantee performance of any investment product. When actual events and indicators vary from assumptions used, the hypothetical projections may be materially impacted.</p>

               <b>C. Public Product Investment Returns (Hypothetical Back-Tested Projections)</b>

               <p>In order to project performance of certain product investments using hypothetical historical performance, the following assumptions are used: 1) Portfolio returns of public asset product investments such as ETFs and mutual funds are back-tested from Jan 2007 to the most recent month-end with monthly rebalancing as of the date of the report (the “Back Test Period”). Securities that do not trade on a market will be not included in any back test; 2) There was no inflow or outflow of cash in the portfolio since the 1st of Jan 2007, except through dividend reinvestment; 3) As total returns are used to calculate return series, dividends are assumed to be reinvested in the portfolio invested in the selected product(s); 4) If a product is not active in a historical month, then the return on the underlying index, minus any publicly known fund fee, published as of the date of the report, is considered as the return for it. In general, if no return is found on a security in a historical month (even after the index-based adjustment for ETFs mentioned above), then the return on that security for the month is considered as zero; 5) Portfolio size is assumed to be $1mn, when portfolio size is not available. Please discuss alternative portfolio size assumptions with Provider if this does not fit your financial circumstances; 6) Returns are reported net of known advisory fees and fees paid to the Provider.</p>
               <p>Actual market risks cannot be considered. No further capital market assumptions are considered.</p>
               <p>Past performance, whether actual or hypothetical, is not a guarantee performance of any investment product. When actual events and indicators vary from assumptions used, the hypothetical projections may be materially impacted.</p>

               <b>D. Wealth Creation (Hypothetical Back-Tested Projections Growth Rate for Forward Looking Charts)</b>

               <p>While calculating wealth creation estimates and projections, it is assumed a portfolio will grow cumulatively at the average annual rate of return (not actual rate of return ) of the portfolio of securities input during the holdings period from Jan 2007 until approximately the date of the report or chart displayed. (The “Back Test Period”).</p>
               <p>While calculating benefits of the reduced fee and tax, it is assumed that, going forward, over the ten (10) years after the date of the report (report date), client average annual portfolio returns increase by the fee savings and tax savings from the Tifin Wealth projected low-fee and tax overlays illustrated in the report.</p>
               <p>Actual market risks cannot be considered. No further capital market assumptions are considered. See glossary of terms for benchmark selection methodology.</p>
               <p>Past performance, whether actual or hypothetical, is not a guarantee performance of any investment product. When actual events and indicators vary from assumptions used, the hypothetical projections may be materially impacted.</p>

               <b>E. Machine Learning Assumptions for Tax Features – Enhanced Diagnosis Section; Proposals for Calculating Tax Alpha</b>

               <p>The Provider’s “tax technology” utilizes the following tax heuristics assumptions; for instance, in the Diagnosis and Proposal charts as well Proposal Reports and any tax alpha calculations.</p>
           </Col>
         </Row>
       </div>
     </div> */}
    </React.Fragment>
  )
};

export const GlossaryPage4 = () => {
  return (
    <React.Fragment>
      {/* <div className="content">
       <div className="pr-v2-glossary">
         <Row>
           <Col>
               <p>Machine learning (the “algorithm”) is used to implement machine learning as an alternative to back testing in the Diagnosis and Proposal features. In general, tax savings are estimated as per the recommended fit of the desired portfolio to a large database with typically a minimum of 1000+ back-tested portfolios. The database is updated so this number reflects the estimated portfolios. Impact of changes in configuration have been estimated as per the fit of the impact on tax loss harvesting parameters for 1000+ back-tested portfolios. Variables used to build the model include the following: i) the losses that the portfolio incurs; ii) the drawdowns that the portfolio incurs for each position; iii) dispersion in contribution to portfolio returns; and iv) dispersion in returns.</p>
               <p>The algorithm is used for predictions of Annual Tax Savings, Tracking Error and Estimated Annual Losses Harvested. For this feature, the back-test period is split into 4 equal periods for each inception date, and calculate them over period 1 to 4, with period 1 being the first 1⁄4 of the period. Outliers at the 5th percentile, in terms of potential tax savings for each inception date, are dropped. The following model statistics are used as defined: R2 is defined as goodness of fit of the predicted values v/s the actual value of the validation dataset; RMSE refers to the root mean squared error; and MAE refers to the mean absolute error. More information on Provider’s methodology is available upon any commercially reasonable request.</p>
               <p>Past performance, whether actual or hypothetical, is not a guarantee performance of any investment product. When actual events and indicators vary from assumptions used, the hypothetical projections may be materially impacted.</p>

               <b>F. Current Portfolio Charts</b>

               <p>The Current Portfolio charts reflect the data and information, including fees, you have provided. Please ensure your inputs are accurate and comprehensive to help ensure the Information, estimates, projections, Diagnosis and Proposals (“Projections”) within are as helpful as possible. The accuracy of such Projections depends on the data and information you provide. For projected returns, please refer to back testing disclosures above and Section II.</p>

               <b>G. Hypothetical Diagnosis and Compare Charts</b>

               <p>The Diagnosis or Compare projections and estimates show the impact of the Current Portfolio performance based on the Current Portfolio inputs and attributes, and as described above, back-testing hypothetical results. ADiagnosis and its assumptions are used to prepare a Proposal to improve portfolio performance as desired. Before considering any Proposals, please affirm your agreement with the projected Diagnosis.</p>
               <p>Fees . ADiagnosis to reduce annual fees (See the Glossary of Terms for definitions and back-testing disclosures above) is calculated using the following methodology: the user inputs the assumed current fees for the current portfolio. Those fees are categorized in accordance with the definition of low, medium or high fees (see the Glossary of Terms for more information). Thereafter, a simulator is used to estimate the impact of a fee reduction to hypothetically show the impact of increased funds invested similarly in the market at the assumed rate of return instead of those funds being allocated to fees as expenses. The “estimated fee savings translated” into the amount of funds is derived from the total amount of hypothetical fee savings invested within a similar asset allocation and as noted above in the Wealth Creation sub-section, it is assumed a portfolio will grow cumulatively at the average annual rate of return (not actual rate of return). See also assumptions used in Proposals below.</p>
               <p>Risks . Risk for a portfolio or a strategy is seen with a combination of measures including portfolio Volatility, its historical drawdowns (peak to trough returns) and worst monthly returns. When proposing a strategy or overlay, the reduction in these values relative to the strategy’s benchmark and/or the client’s current portfolio over the back-test period is referred to as reduction in risk.</p>
               <p>Adiagnosis attempts to reduce downside market and economic risks [driving volatility] of the current portfolio through diversification and asset allocation strategies using a back tested hypothetical model.</p>
               <p>Hypothetical annualized volatility of the current portfolio is estimated from a back test of the current portfolio. Risk mitigation is calculated as the estimated reduction in extreme losses. Estimated wealth change is a hypothetical estimate simulating the benefits of the back tested risk mitigation performance over time as described in Wealth Creation above. Risk Forecasting is another method in which the software helps simulate potential options for forecasting, managing and mitigating market and economic risks within the displayed actual and hypothetical portfolio. See Glossary of Terms for definitions, assumptions used in Proposals below and other sections herein.  </p>
           </Col>
         </Row>
       </div>
     </div> */}
    </React.Fragment>
  )
};

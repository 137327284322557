import React from 'react';

import { Row, Col } from 'reactstrap';
import { extent as d3Extent } from 'd3-array';
import { Cell, PieChart, Pie, ResponsiveContainer, Label } from 'recharts';
import { LineChart, Line, XAxis, YAxis, ReferenceLine, LabelList } from 'recharts';

import { GaugeColors } from 'data/Colors';
import { FormatCurrency } from './Utils';
import { numberCheck } from 'layouts/utils';

export const GaugeChartWrap = ({ data, size = "md" }) => {
  const heights = { sm: '50px', md: '60px', lg: '70px' };
  const styles = { height: heights[size] };
  const { value, min, max } = data;
  const pc = (value - min)/(max - min);

  return (
    <div className="w-100 p-1">
      <div className="d-flex flex-column align-items-center">
        <h6 className="text-center p-0 mb-1">Volume vs Category</h6>
        <div className="w-100" style={styles}>
          <GaugeChart pc={pc} />
        </div>

        <Row className="no-gutters h6 mb-0 w-100">
          <Col className="col text-right h7">{FormatCurrency(min, 1)}</Col>
          <Col className="col-6 text-center h7">{FormatCurrency(value, 1)}</Col>
          <Col className="col text-left h7">{FormatCurrency(max, 1)}</Col>
        </Row>
      </div>
    </div>
  );
}

export const PercentChartWrap = ({ value, color, size = "md" }) => {
  const heights = { sm: '80px', md: '100px', lg: '120px' };
  const styles = { height: heights[size] };
  const fs = 15;
  const data = [{name: 'name', value: 41, color: '#61bf93'}, {name: 'other', value: 79, color: '#ededed'}];
  return (
    <div className="w-100" style={styles}>
      <PercentChart data={data} value={value} color={color} fs={fs} />
    </div>
  );
}


const GaugeChart = ({ value, min, max, pc }) => {
  const sumValues = 300;
  const colorData = GaugeColors.slice(0, 3).map(e => ({ value: 100, color: e }));
  const chartValue = sumValues*(pc !== undefined ? pc : (value-min)/(max - min));

  const arrowData = [
      { value: chartValue },
      { value: 0 },
      { value: sumValues - chartValue }
  ];

  const pieProps = {
      startAngle: 180, endAngle: 0,
      cy: '100%',
  };

  const pieRadius = {
    innerRadius: '150%',
    outerRadius: '180%'
  };

  const Arrow = ({ cx, cy, midAngle, outerRadius }) => {
      const RADIAN = Math.PI / 180;
      const sin = Math.sin(-RADIAN * midAngle),
            cos = Math.cos(-RADIAN * midAngle);
      const mx = cx + (outerRadius - 3) * cos,
            my = cy + (outerRadius - 3) * sin;
      return (
          <g>
              <circle cx={cx} cy={cy} r={3} fill="#999" stroke="none"/>
              <path d={`M${cx},${cy}L${mx},${my}`} strokeWidth="2" stroke="#999" fill="none" strokeLinecap="round"/>
          </g>
      );
  };

  return (
    <ResponsiveContainer>
      <PieChart margin={{top: 0, right: 0, left: 0, bottom: 5}}>
          <Pie data={colorData} dataKey="value" fill="#8884d8" isAnimationActive={false}
              { ...pieRadius } { ...pieProps } >
              { colorData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colorData[index].color} />
                  )) }
          </Pie>
          <Pie
              stroke="none" fill="none"
              activeIndex={1} activeShape={ Arrow }
              data={ arrowData } dataKey="value"
              outerRadius={ pieRadius.innerRadius }
              { ...pieProps }
          />
      </PieChart>
    </ResponsiveContainer>
  );
};


const PercentChart = ({ value, ...props }) => {
  const data = [
    {name: 'name', value: (value === null ? 0 : value), color: props.color },
    {name: 'other', value: 100-value, color: '#ededed'}
  ];

  return (
    <ResponsiveContainer>
      <PieChart >
        <Pie data={data} isAnimationActive={false} dataKey="value"
          innerRadius="60%" outerRadius="95%" startAngle={270} endAngle={-90}
          fill="#ededed">
          { data.map((e, i) => <Cell key={i} fill={e.color} stroke="none" />) }
          <Label value={data[0].value+'%'} offset={0} position="insideBottom" content={pieLabel} fs={props.fs} />
        </Pie>

      </PieChart>
    </ResponsiveContainer>
  )
}

const pieLabel = (props) => {
  const { cx, cy } = props.viewBox;
  return <text x={cx} y={cy} dy={6} dx={2} textAnchor="middle" fill={GaugeColors[0]}
           fontSize={props.fs} fontWeight="normal">{props.value}</text>
}


/*
  series: [ { name: 'S1', data: [{ d: 'Mar 2017', cuml: 1.23 }, ... ], color: '#bbb' }, ... ],
  xkey: 'd',
  ykey: 'cuml',
  ycentered: true,
*/
export const LineChartS1 = (props) => {
  // console.log(props);
  const { series, xkey, ykey, ycentered = true } = props;
  let ydomain = ['auto', 'auto'];
  if (ycentered) {
    const ranges = series.reduce((acc, s) => acc.concat(d3Extent(s.data, e => e[ykey])), []);
    const [min, max] = d3Extent(ranges);                  // ydomain across all series
    let max2 = Math.max(Math.abs(min), Math.abs(max));
    ydomain = [-max2, max2];
  }

  // let dt = new Date();
  // let yr = dt.getFullYear();
  // let mn = new Date(dt.setMonth(dt.getMonth()+1)).getMonth()
  // let allmn = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']

  // const tickFormatter = label => {
  //   let lastYear = label.substring(label.length,4)
  //   if(label.substring(0,3) == allmn[mn]){
  //     return (lastYear == yr) ? 'Today' : label
  //   }else if(label.substring(0,3) == allmn[mn-1] && lastYear == yr){
  //     return 'Today'
  //   }
  //   return ''
  // }

  const YAxisLabel = (value) => {
    // console.log('YAxisLabel -> ', value)
    return `$${numberCheck(value+100, 0)}`
  }

	return (
    <ResponsiveContainer width="100%" height="100%" >
      <LineChart margin={{ top: 8, right: 30, left: -15, bottom: 8 }}>
        {/*
          <ReferenceLine
            y={0}
            hide={true}
            stroke="#bbb"
            shapeRendering="crispEdges"
            label={null}
          />
        */}
        <ReferenceLine
          y={0}
          stroke="#bbb"
          shapeRendering="crispEdges"
          label={<ReferenceLineCustomLabel value={`$${100}`} stroke="#666" />}
        />

        <XAxis
          interval={'preserveStartEnd'}
          tickSize={0}
          tickCount={0}
          dataKey={xkey}
          tickMargin={13}
          stroke="#dbdbdb"
          allowDataOverflow={true}
          shapeRendering="crispEdges"
          // tickFormatter={value => tickFormatter(value)}
        />
        <YAxis
          interval={'preserveEnd'}
          tickSize={0}
          tickCount={3}
          dataKey={ykey}
          tickMargin={13}
          stroke="#dbdbdb"
          domain={ydomain}
          allowDataOverflow={true}
          shapeRendering="crispEdges"
          tickFormatter={value => YAxisLabel(value)}
        />
        {series.map(s => (
          <Line
            dataKey={ykey}
            data={s.data}
            name={s.name}
            key={s.name}
            type="monotone"
            unit="%"
            dot={false}
            stroke={s.color}
            strokeWidth={1}
            isAnimationActive={false}
            label={<LineChartCustomLabel size={s.data.length} label={s.label} stroke={s.color} />}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}

const ReferenceLineCustomLabel = (props) => {
  const { viewBox, stroke, value } = props;
  return <text x={0} y={viewBox.y} dx={4} dy={4} fill={stroke} fontSize={10} textAnchor="start">{value}</text>
};


const LineChartCustomLabel = ({ label, x, y, stroke, index, size }) => (
  index === size-1
  ? <text x={x} y={y} dx={2} dominantBaseline="middle" fill={stroke} fontSize={10} textAnchor="start">{label}</text>
  : null
);

import React, { Component, Fragment } from 'react'
import { EditOutlined } from '@ant-design/icons';
// import JsxParser from 'react-jsx-parser' // https://github.com/TroyAlford/react-jsx-parser/issues/108#issuecomment-747245521
import { Button, Modal } from 'antd';
import style from './Branding.module.scss'
import { Spinner } from 'shared-components/'
import { createLoadingSelector } from 'selectors/loading-selector'
import { fetchProfile, updateProfile, getTotalProposals, saveDisclosuresOrdersRequest } from 'actions/profile'
import { connect } from 'react-redux'
import { uploadCustomLogo } from 'apis/profile'
import { ToolbarFormatterTextArea } from '../ToolbarFormatterTextArea/ToolbarFormatterTextArea';
import { getPositionOfAnElement } from 'layouts/utils';
import { addEventToAnalytics } from 'Utils';
// import { Highlight } from './ParserHtml';

import 'antd/dist/antd.css'
import './BrandingNew.scss'

// const {TextArea} = Input
// const { Paragraph } = Typography;

const haveHTMLData = (htmlString) => {
  const aux = document.createElement('div');
  aux.innerHTML = htmlString; //parses the html
  const trimmedContent = aux.innerText.trim();
  return trimmedContent !== '';
}

class Branding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo_url: '',
      isLoading: false,
      errorText: '',
      disclosures: '',
      isPreviewModalVisible: false,
      confirmModalVisible: false,
    }
  }

  componentDidMount() {
    this.props.fetchProfile('', (res) => {
      //console.log(res);
      if (res) {
        // console.log('res disclosures -->', res.disclosures);
        this.setState({ disclosures: res.disclosures })
      }
    })
    this.props.getTotalProposals()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      logo_url:nextProps.profile.custom_report_logo?nextProps.profile.custom_report_logo:'/assets/images/MagnifiLogo/Magnifi_byTIFIN-darkgrey.svg'
    })
  }

  onChangeHandler = async event => {
    this.setState({
      isLoading: true,
      errorText:''
    })
    let data = new FormData()
    data.append('file', event.target.files[0])
    try {
      let upload = await uploadCustomLogo(data)
      if (upload.status === 200) {
        console.log(upload.data)
        this.setState({
          isLoading: false,
          logo_url:upload.data.image_url
        })
      } else {
        this.setState({
          isLoading: false,
          errorText:'Only .png,.jpg image format are allowed'
        })
      }
    }
    catch (e) {
      this.setState({
        isLoading: false,
        errorText:'Only .png,.jpg image format are allowed'
      })
    }
  }

  viewArchives = () => {
    this.props.history.push('/archived/proposals')
  }

  disclosureOnChange = ({ target: { value } }) => {
    this.setState({ disclosures: value })
  }

  saveDisclosures = () => {
    const { saveDisclosuresOrdersRequest } = this.props;
    if (saveDisclosuresOrdersRequest) {
      addEventToAnalytics('Disclosure Section Content Save Action', 'Disclosure Section Content Save Action', 'DISCLOSURE_SECTION_CONTENT_SAVE_ACTION', {});
      if(haveHTMLData(this.state.disclosures)) {
        saveDisclosuresOrdersRequest({
          disclosures: this.state.disclosures,
        });
      } else {
        saveDisclosuresOrdersRequest({
          disclosures: null,
        });
      }
    }
  }

  previewDisclosures = () => {
    this.setState({
      isPreviewModalVisible: true
    })
  }

  textAreaHtml = (html) => {
    this.setState({ disclosures: html });
  }

  handleOk = () => {
    this.setState({
      isPreviewModalVisible: false
    })
    this.saveDisclosures();
  }

  handleCancel = () => {
    this.setState({
      isPreviewModalVisible: false,
    })
  }

  handleCancelConfirmModal = () => {
    // NOTE: discard disclosures changes, and close Modal
    const { profile } = this.props;
    this.setState({
      confirmModalVisible: false,
      disclosures: profile.disclosures,
    }, () => {
      this.setState({
        isPreviewModalVisible: false,
      })
    });
  }

  handleOkConfirmModal = () => {
    // NOTE: DO NOT discard disclosures changes
    this.setState({
      confirmModalVisible: false,
    });
  }

  openConfirmModal = () => {
    const { profile } = this.props;
    // console.log(profile.disclosures);
    // console.log(this.state.disclosures);
    // console.log('haveHTMLData(this.state.disclosures) -->', haveHTMLData(this.state.disclosures));
    if (profile && profile.disclosures === '' && !haveHTMLData(this.state.disclosures)) {
      this.setState({
        isPreviewModalVisible: false,
      });
    } else {
      if (profile && profile.disclosures !== this.state.disclosures) {
        this.setState({
          confirmModalVisible: true,
        });
      } else {
        this.setState({
          isPreviewModalVisible: false,
        })
      }
    }
  }

  render() {
    const { isFetching, totalProposals, savingDisclosures, profile } = this.props
    const { isLoading, logo_url, errorText, disclosures, isPreviewModalVisible, confirmModalVisible } = this.state
    const disclosureModalStyles = {};
    let saveButtonDisabled = (profile && (profile.disclosures === disclosures));
    let noDisclosuresContent = ((disclosures && disclosures.length === 0) || !disclosures || !haveHTMLData(disclosures));
    const textAreaElement = document.getElementById('disclosures-text-area');
    if (textAreaElement) {
      const { x, y } = getPositionOfAnElement(textAreaElement);
      if (typeof x !== 'undefined' && typeof y !== 'undefined') {
        disclosureModalStyles.width = textAreaElement.offsetWidth+'px';
        disclosureModalStyles.top = y+'px';
        disclosureModalStyles.left = x+'px';
      }
    }
    return (
      <>
        <Spinner isFetching={isLoading || isFetching || savingDisclosures}/>
        {process.env.REACT_APP_SUB_DOMAIN !== 'retail' && <Fragment>
        <span className={style.subHeader}> Customize Proposals</span>
        <div className={style.container}>
          <p>Select an image to use as your firm logo for reports and client communications</p>
          <div className={style.uploadContainer}>
            <p>Current Logo</p>
            <div className={style.currentLogo}>
              <img src={logo_url} alt="Logo"/>
              <label htmlFor="imagePicker" className={style.update}>
                Upload New Logo
              </label>
              <input
                id={'imagePicker'}
                type="file"
                name="file"
                style={{ visibility: 'hidden', width: 0, position: `absolute` }}
                accept="image/*"
                onChange={this.onChangeHandler}
              />
            </div>
            {errorText&&<p className={style.error}>{errorText}</p>}
          </div>

          <div className={style.disclosuresContainer}>
            <p className={`${style.editIconContainer}`}>
              Disclosures
              <Button
                type="link"
                shape="circle"
                className={style.editButton}
                icon={<EditOutlined className={style.anticon} />}
                onClick={() => this.previewDisclosures()}
              />
            </p>
            <div id={'disclosures-text-area'} className={style.disclosuresWrapper}>
              <div className={style.antTypography}>
                <div
                  className={style.renderDisclosure}
                  style={{ opacity: noDisclosuresContent ? '0.6': '1' }}
                  dangerouslySetInnerHTML={{ __html: noDisclosuresContent ? 'Add your firm specific disclosures here.' : disclosures }}
                />
              </div>
              {/*
                <div className={style.btnWrapper}>
                  <Button type="link" disabled={(disclosures && disclosures.length==0) || disclosures==="<p><br></p>"} className={style.btnMargin} onClick={()=>this.previewDisclosures()}>
                    Preview
                  </Button>
                  <Button type="primary" onClick={() => this.saveDisclosures()}>
                    Save
                  </Button>
                </div>
              */}
              <Modal
                mask
                destroyOnClose
                closable={false}
                maskClosable={false}
                okText={'SAVE'}
                cancelText={'Cancel'}
                wrapClassName={'disclosures-modal-container'}
                style={disclosureModalStyles}
                visible={isPreviewModalVisible}
                bodyStyle={{ height: '360px', maxHeight: '360px', overflow:'auto' }}
                maskStyle={{ background: '#636363', opacity: '0.3' }}
                okButtonProps={{ type: "primary", className: 'modelOkBtn', disabled: saveButtonDisabled }}
                cancelButtonProps={{ type: "link", className: 'modelCancelBtn' }}
                onOk={this.handleOk}
                onCancel={this.openConfirmModal}
              >
                <div className={style.parserHtmlWrapper}>
                  <ToolbarFormatterTextArea
                    value={disclosures}
                    style={{ height: 360, maxHeight: 360 }}
                    className={'modal-preview-text-area'}
                    placeholder={'Add your firm specific disclosures here.'}
                    textAreaHtml={this.textAreaHtml}
                  />
                </div>
                {confirmModalVisible && (
                  <Modal
                    mask
                    destroyOnClose
                    closable={false}
                    maskClosable={false}
                    okText={'No'}
                    cancelText={'Yes'}
                    wrapClassName={'confirm-discard-changes-modal'}
                    style={disclosureModalStyles}
                    visible={confirmModalVisible}
                    bodyStyle={{ height: 'auto', overflow:'auto' }}
                    maskStyle={{ background: '#636363', opacity: '0.3' }}
                    okButtonProps={{ type: "primary", className: 'modelOkBtn' }}
                    cancelButtonProps={{ type: "link", className: 'modelCancelBtn' }}
                    onOk={this.handleOkConfirmModal}
                    onCancel={this.handleCancelConfirmModal}
                  >
                    Are you sure you want to discard these changes?
                  </Modal>
                )}
              </Modal>
            </div>
          </div>

        </div>

        {/*divider*/}
        <div className={style.divider}/>
        </Fragment>}
        <span className={style.subHeader}>Proposals Archive</span>
        <div className={style.container}>
          <p>Browse through all the archived proposals</p>
          <div className={style.uploadContainer}>
            <p>Total Proposals</p>
              <div className={style.currentLogo}>
                <label className={style.totalProposals}>{totalProposals}</label>
                <label htmlFor="viewArchive" onClick={this.viewArchives} className={style.update}>
                  View Archive
                </label>
              </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  totalProposals: state.profile.proposalsCount,
  profile: state.profile.profile,
  isFetching: createLoadingSelector([
    'PROFILE',
    'PROFILE_UPDATE'
  ])({ loading: state.loading }),
  savingDisclosures: createLoadingSelector(['SAVE_DISCLOSURES'])({ loading: state.loading }),
})

const mapDispatchToProps = {
  fetchProfile,
  updateProfile,
  getTotalProposals,
  saveDisclosuresOrdersRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(Branding)

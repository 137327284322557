import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom';
import style from './PopUps.module.scss'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, ButtonGroup, Form,Spinner } from 'reactstrap';
import { addApexMoney, fetchApexBalance } from 'apis/funds'
// import { CloseButton, PrimaryButton } from 'shared-components';
//
// import { setPremiumState } from 'actions/profile';
//
// import InviteFriends from 'layouts/WebDashboard/SearchResults/Elements/InviteFriends';
// import { getPremiumFeatureDetails, allowTestDrive } from 'apis/test-drive';
// import PremiumPlanDetails from './PremiumPlanDetails';
// import LoginWall from './LoginWall';
// import { addEventToAnalytics } from 'Utils';
import { load } from 'actions/videoplayer'
import moment from 'moment'


const OBJ = {
  img_pending:'assets/images/icons/apex_process.svg',
  img_error:'assets/images/icons/apex_close.svg',
  text1_pending:'Your documents are under review',
  text2_pending:"We'll notify you once approved",
  text1_error:'There was some error while opening account',
  text2_error:'We will get back to you shortly',
  btn_error:'Retry',
  btn_pending:'Continue'
}

let getResource = (obj,atr)=>{
  return obj[atr]
}

class AccountAfterCreationPopup extends Component {
  constructor(props) {
    super(props)
  }


  state = {
  };

  onConfirm = ()=>{
    this.props.toggleAccountPopup()
    if (this.props.state === 'error') {
      this.props.setStep(0)
    }else{
      // this.props.history.push('/securities')
      this.props.setlinkAccountPopup();
    }
  }


  render() {
    const {
      open,
      state,
      fromMobileApp,
      formError
    } = this.props;


    return (
      <Fragment>
        <Modal
          keyboard
          centered
          backdrop
          unmountOnClose={false}
          isOpen={open}
          className="add-money-popup-sfm"
          style={fromMobileApp === 'true' && state !== 'error' ? { margin: 0 } : {}}
          backdropClassName="premium-feature-process-modal-backdrop"
          contentClassName={fromMobileApp === 'true' && state !== 'error' ? style.accountCreatePopupForApp : null}
        >
          <ModalBody className="ssf-modal__body" style={fromMobileApp === 'true' && state !== 'error' ? { display: 'flex' }: {}}>
            <div className={style.accountCreatePopup}>
             {/*<i onClick={this.onCloseIcon} className={`fal fa-times ${style.close}`}/>*/}
              <div className={style.accountCreateError} >
                <img alt={''} src={state==='error'?'/assets/images/icons/apex_close.svg':'/assets/images/icons/apex_process.svg'} width="50" />
                <p className={style.statusText}>
                  {formError ? "Error" : getResource(OBJ,`text1_${state}`)}
                  <br/>
                  {formError ? formError : getResource(OBJ,`text2_${state}`)}
                </p>
                {fromMobileApp === 'true' && state !== 'error' ? <p className={style.statusText}>
                  Please click the close button at the top to continue on the app
                </p>:null}
                <button className={style.confirm} onClick={this.onConfirm}>{getResource(OBJ,`btn_${state}`)}</button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ auth, profile }) => ({
  // auth,
  // loggedIn: auth.loggedIn,
  // premiumFeatures: profile.premiumFeatures,
  // activeFeatureName: profile.activeFeatureName,
  // premiumNoticeModalOpen: profile.premiumNoticeModalOpen,
  // access: profile.access, // 'Access' or 'Denied' or 'Test Drive'
})

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountAfterCreationPopup))

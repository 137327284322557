import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { get } from 'lodash'

import LoginForm from 'components/LoginForm'
import { CloseButton } from 'shared-components'
import { auth, failedGoogleAuth } from 'actions/auth'
import { registerGoogle } from 'actions/register'
import { storeAuthToken } from '../common/auth-utils'
import { createLoadingSelector } from 'selectors/loading-selector'
import { Spinner } from 'shared-components/'
import { isTermsAgreed } from 'selectors/auth-selector'

class LoginContainer extends Component {
  state = { modal: true }

  handleSubmit = (values, { setSubmitting }) => {
    this.props.auth(values)
    setSubmitting(false)
  }

  handleGoogleSignInSuccess = response => {
    console.log({ response })
    const profile = response.profileObj
    const token = response.tokenObj

    storeAuthToken({
      token: response.tokenId,
      email: profile.email,
      firstName: profile.givenName,
      lastName: profile.familyName,
      isGoogleLogin: true
    })

    this.props.registerGoogle({
      email: profile.email,
      first_name: profile.givenName,
      last_name: profile.familyName,
      g: 1,
      token: response.tokenId
    })
    // this.props.successfulGoogleAuth(response)
  }

  handleGoogleSignInError = error => {
    console.log({ error })
    //TODO: why Google sso triggered error first time
    if (error !== 'SDK not loaded') {
      this.props.failedGoogleAuth(error)
    }

    // storeAuthToken({
    //   token: 'qwerty123',
    //   email: 'raja@gmail.com',
    //   firstName: 'Raja',
    //   lastName: 'Jagan',
    //   isGoogleLogin: true
    // })

    // this.props.registerGoogle({
    //   email: 'raja@gmail.com',
    //   first_name: 'Raja',
    //   last_name: 'Jagan',
    //   g: 1,
    //   token: 'qwerty123'
    // })
  }

  authSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email')
      .required('Email is a required field'),
    password: Yup.string().required('Password is a required field')
  })

  handleToggle = () => {
    const currentPath = get(this.props,'location.pathname','')
    const pathname = this.props.fromPath || '/'
    if(pathname!==currentPath) {
      this.props.history.push(pathname)
    }
    if(this.props.handleClose) {
      this.props.handleClose()
    }
  }

  render() {
    const { isFetching, isTermsAgreed, loggedIn } = this.props

    if (loggedIn && !isTermsAgreed) {
      return <Redirect to="/agree-terms" />
    }

    if (loggedIn || window.localStorage.getItem('token')) {
      const pathname = this.props.fromPath || '/'
      return (
        <Redirect
          to={{
            pathname,
            search: window.location.search
          }}
        />
      )
    }

    return (
      <div className="login-container">
        <Spinner isFetching={isFetching} />
        <Modal
          isOpen={this.state.modal}
          toggle={this.handleToggle}
          centered
          keyboard={true}
          wrapClassName={this.props.wrapClassName}
          modalClassName={this.props.modalClassName}
          backdropClassName={this.props.backdropClassName}
          contentClassName={this.props.contentClassName}
          className={`ssf-modal sign-in-modal ${this.props.className}`}
        >
          <ModalHeader className="ssf-modal__header">
            Sign In
            <CloseButton onClick={this.handleToggle} />
          </ModalHeader>
          <ModalBody className="ssf-modal__body">
            {/* {authFailed && <Alert color="danger">Unable to login please contact admin</Alert>} */}
            <Formik
              initialValues={{ email: '', password: '' }}
              onSubmit={this.handleSubmit}
              validationSchema={this.authSchema}
              render={props => (
                <LoginForm
                  formProps={props}
                  error={this.props.error}
                  onGoogleSignInSuccess={this.handleGoogleSignInSuccess}
                  onGoogleSignInError={this.handleGoogleSignInError}
                />
              )}
            />
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, loading }) => ({
  loggedIn: auth.loggedIn,
  user: auth.user,
  error: auth.error,
  authFailed: auth.authFailed,
  authSuccess: auth.authSuccess,
  isFetching: createLoadingSelector(['AUTH'])({ loading }),
  isTermsAgreed: isTermsAgreed(auth)
})

const mapDispatchToProps = {
  auth,
  failedGoogleAuth,
  registerGoogle
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginContainer))

import React, { Component } from 'react'
import {
  Button,
  Card,
  CardBody,
  Row,
  ButtonGroup,
  Dropdown,
  FormGroup,
  ModalBody,
  Input,
  ModalFooter,
  Modal,
  Form,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { PrimaryButton } from 'shared-components'
import { getNest } from '../../../../../Utils'
import { CSVLink } from 'react-csv'

class GenerateTradeListModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tradeListValue: '',
      brokerDropdownValue: '',
      tradeListDropdown: false,
      accountDropdown: false,
      accounNoDropdown: false,
      selectAccountNumber: '',
      accountNumber: [],
      tradeList: '',
      portfolioValue: props.portfolioValue
    }
    this.csvLink = React.createRef()
    this.fidelityCsvLink = React.createRef()
    this.schwabCsvLink = React.createRef();
  }

  onCloseDownloadTradeList = () => {
    this.setState({ tradeListValue: '' })
    this.setState({ brokerDropdownValue: '' })
    this.setState({ selectAccountNumber: '' })

    this.props.toggleModal()
  }

  tradeListDropDownToggle = () => {
    this.setState({ tradeListDropdown: !this.state.tradeListDropdown })
  }

  accountDropdownToggle = () => {
    this.setState({ accountDropdown: !this.state.accountDropdown })
  }

  accountNoDropdownToggle = () => {
    this.setState({ accounNoDropdown: !this.state.accounNoDropdown })
  }

  selectTradeList = item => {
    this.props.selectedTradeList(item)
    this.setState({ tradeListValue: item })
    this.setState({ brokerDropdownValue: '' })
    this.setState({ selectAccountNumber: '' })
  }

  getAccountName = item => {
    const accountArray = []

    const { importedAccounts } = this.props //LEFT TO SEE THIS PROP

    var valuesData = Object.entries(importedAccounts).map(([k, v]) => ({
      [k]: v
    }))

    valuesData.map((i, key) => {
      if (item === Object.keys(i).toString()) {
        accountArray.push(i)
      }
    })

    const ids = []
    JSON.stringify(accountArray, (key, value) => {
      if (key === 'account_number') ids.push(value)
      return value
    })

    this.setState({
      accountNumber: ids
    })

    this.setState({
      brokerDropdownValue: item
    })

    this.setState({ selectAccountNumber: '' })
  }

  getAccountNumber = item => {
    this.setState({
      selectAccountNumber: item
    })
  }

  setAccountNumber = e => {
    const name = document.getElementById('account-number-box').value;
    this.setState({ selectAccountNumber: name });
  }

  setPortfolioValue = e => {
    const pfvalue = document.getElementById('portfolio-value-box').value;
    this.setState({ portfolioValue: pfvalue });
  }

  updatePortfolioValue = e => {
    const {createPF} = this.props;
    console.log(this.state.portfolioValue);
    createPF(this.state.portfolioValue);
  }

  downloadOrionTradeListExcel = () => {
    const { items } = this.props
    var CSVDataValue = items.map(i => {
      let info = {
        account_number: this.state.selectAccountNumber,
        transaction: 'B',
        quantity: i._computedPFShares,
        ticker: i.ticker,
        price: i.nav
      };
      return info;
    });

    var finalValue = CSVDataValue.filter(i => i)

    this.setState({
      downloadCSV: true
    });

    this.setState({ tradeList: finalValue }, () => {
      // click the CSVLink component to trigger the CSV download
      this.csvLink.current.link.click();
    })

    this.onCloseDownloadTradeList();
  }

  downloadFidelityTradeListExcel = () => {
    const { items } = this.props;
    const { brokerDropdownValue } = this.state;
    var CSVDataValue = items.map(i => {
        var info = {
          account_number: this.state.selectAccountNumber,
          // transaction: getNest(['_order', 'stage'], i) == 'buy' ? 'B' : 'T',
          transaction: 'B',
          price: i.nav,
          quantity: i._computedPFShares,
          ticker: i.ticker,
          instrx: 'MOC',
          timeLimit: 'D',
          timing: 'M',
          security_type: ["ETF", "ETN"].includes(i.type) ? "EQ" : "M",
        }
        return info;
    });

    var finalValue = CSVDataValue.filter(i => i);

    this.setState({
      downloadCSV: true
    });

    this.setState({ tradeList: finalValue }, () => {
      // click the CSVLink component to trigger the CSV download
      if(brokerDropdownValue === "Schwab"){
        this.schwabCsvLink.current.link.click();
      }else{
        this.fidelityCsvLink.current.link.click();
      }
    });

    this.onCloseDownloadTradeList();
  }

  render() {
    const { toggleModal, isOpen } = this.props
    const { accountNumber } = this.state
    var tradeList = ['Orion', 'Not Applicable']
    var accountList = [
      'Fidelity',
      'Schwab',
      'TD Ameritrade',
      'Robinhood',
      'Vanguard'
    ]

    var orionHeaders = [
      { label: 'account', key: 'account_number' },
      { label: 'action', key: 'transaction' },
      { label: 'shares', key: 'quantity' },
      { label: 'ticker', key: 'ticker' },
      { label: 'price', key: 'price'}
    ]

    var fidelityHeaders = [
      { label: 'Acct', key: 'account_number' },
      { label: 'Action', key: 'transaction' },
      { label: 'Quantity', key: 'quantity' },
      { label: 'Symbol', key: 'ticker' },
      { label: 'Instrx', key: 'instrx' },
      { label: 'Price', key: 'price' },
      { label: 'Time Limit', key: 'timeLimit' }
    ]

    var schwabHeaders = [
      { label: "Account Number", key: "account_number"},
      { label: "Action", key: "transaction" },
      { label: "Quantity", key: "quantity" },
      { label: "Security Symbol", key: "ticker" },
      { label: "Timing", key: "timing" },
      { label: "Security Type", key: "security_type" }
    ];

    let disabledCSV =
      (this.state.selectAccountNumber != '' &&
        this.state.brokerDropdownValue == '') ||
      this.state.selectAccountNumber == ''
    let disabledBroker = this.state.tradeListValue == ''
    let disabledAccount =
      (this.state.tradeListValue != '' &&
        this.state.brokerDropdownValue == '') ||
      (this.state.tradeListValue == '' && this.state.brokerDropdownValue == '')

    return (
      <React.Fragment>
        <CSVLink
          data={this.state.tradeList}
          headers={orionHeaders}
          filename={'MyTradeList.csv'}
          ref={this.csvLink}
        />
        <CSVLink
          data={this.state.tradeList}
          headers={fidelityHeaders}
          filename={'MyTradeList.csv'}
          ref={this.fidelityCsvLink}
        />
         <CSVLink
            data={this.state.tradeList}
            headers={schwabHeaders}
            filename={"MyTradeList.csv"}
            ref={this.schwabCsvLink}
          />
        <Modal
          isOpen={isOpen}
          toggle={() => toggleModal()}
          centered
          keyboard={true}
          backdrop
          className="account-broker-modal premium-msg-modal account-modal"
          backdropClassName="premium-feature-process-modal-backdrop"
        >
          <ModalBody className="ssf-modal__body">
            <div className="premium-feature-process-modal-container">
              <div className="header-wrapper">
                <h1 className="text-align-left">Download Tradelist</h1>
              </div>
              <i
                className="fal fa-times-circle premium-feature-completed-close"
                onClick={() => this.onCloseDownloadTradeList()}
              ></i>
              <div
                className="account-dropdown-element"
                style={{ paddingBottom: '18px' }}
              >
                <span style={{ color: 'white' }}>
                  To Execute Selected Enhancements In Desired Format
                </span>
                <br />
              </div>
              <div className="account-dropdown-element">
                <span style={{ color: 'white' }}>Enter Portfolio Value</span>
                <input
                  id="portfolio-value-box"
                  className="dd-input-area"
                  type="text"
                  value={this.state.portfolioValue}
                  onChange={this.setPortfolioValue}
                  onBlur={this.updatePortfolioValue}
                  autoComplete="off"
                />
              </div>
              <div className="account-dropdown-element">
                <span style={{ color: 'white' }}>Execution Platform</span>

                <Dropdown
                  isOpen={this.state.tradeListDropdown}
                  toggle={this.tradeListDropDownToggle}
                  className="account-dropdown-wrapper"
                >
                  <DropdownToggle caret className="dd-input-area">
                    {this.state.tradeListValue}
                  </DropdownToggle>
                  <DropdownMenu center>
                    {tradeList.map(item => {
                      return (
                        <DropdownItem
                          value={item}
                          onClick={() => this.selectTradeList(item)}
                        >
                          {item}
                        </DropdownItem>
                      )
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div className="account-dropdown-element">
                <span style={{ color: 'white' }}>Broker/Custodian</span>

                <Dropdown
                  isOpen={this.state.accountDropdown}
                  toggle={this.accountDropdownToggle}
                  className="account-dropdown-wrapper"
                >
                  <DropdownToggle
                    caret
                    className="dd-input-area"
                    disabled={disabledBroker}
                  >
                    {this.state.brokerDropdownValue}
                  </DropdownToggle>
                  <DropdownMenu center>
                    {this.state.tradeListValue === 'Orion'
                      ? accountList.map(item => {
                          return (
                            <DropdownItem
                              value={item}
                              onClick={() => this.getAccountName(item)}
                            >
                              {item}
                            </DropdownItem>
                          )
                        })
                      : accountList.map(item => {
                          if (['Fidelity', 'Schwab'].includes(item)) {
                            return (
                              <DropdownItem
                                value={item}
                                onClick={() => this.getAccountName(item)}
                              >
                                {item}
                              </DropdownItem>
                            )
                          }
                        })}
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div>
                {accountNumber.length > 0 ? (
                  <div className="account-dropdown-element">
                    <span style={{ color: 'white' }}>Account</span>
                    <Dropdown
                      isOpen={this.state.accounNoDropdown}
                      toggle={this.accountNoDropdownToggle}
                      className="account-dropdown-wrapper"
                    >
                      <DropdownToggle
                        caret
                        className="dd-input-area"
                        disabled={disabledAccount}
                      >
                        {this.state.selectAccountNumber}
                      </DropdownToggle>
                      <DropdownMenu center>
                        {accountNumber.map(item => {
                          return (
                            <DropdownItem
                              value={item}
                              onClick={() => this.getAccountNumber(item)}
                            >
                              {item}
                            </DropdownItem>
                          )
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                ) : (
                  <div className="account-dropdown-element">
                    <span style={{ color: 'white' }}>Enter Account Number</span>
                    <input
                      id="account-number-box"
                      className="dd-input-area"
                      type="text"
                      value={this.state.selectAccountNumber}
                      onChange={this.setAccountNumber}
                      autoComplete="off"
                      disabled={disabledAccount}
                    />
                  </div>
                )}

                {this.state.tradeListValue === 'Orion' ? (
                  <div className="download-csv">
                    <ButtonGroup className="cta-wrapper justify-content-left">
                      <PrimaryButton
                        className="btn secondary-btn"
                        disabled={disabledCSV}
                        type="button"
                        onClick={this.downloadOrionTradeListExcel}
                        style={{ maxWidth: '203px', cursor: 'pointer' }}
                      >
                        Download Tradelist
                      </PrimaryButton>
                    </ButtonGroup>
                  </div>
                ) : (
                  <div className="download-csv">
                    <ButtonGroup className="cta-wrapper justify-content-left">
                      <PrimaryButton
                        className="btn secondary-btn"
                        disabled={disabledCSV}
                        type="button"
                        onClick={this.downloadFidelityTradeListExcel}
                        style={{ maxWidth: '203px', cursor: 'pointer' }}
                      >
                        Download Tradelist
                      </PrimaryButton>
                    </ButtonGroup>
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default GenerateTradeListModal

import React from 'react';

import PortfolioSection from './PortfolioSection';
import HoldingsSection from './HoldingsSection';
import Divider from '../Shared/Divider';

const PfCardWrapper = (props) => {
  return (
    <div className="pf-card-wrapper">
      <PortfolioSection
      	minWidth={'575px'}
        query={props.query}
        data={props.data}
        type={props.type}
        title={props.pfTitle}
      />
      <Divider
      	minWidth={props.divider || 0}
      />
      <HoldingsSection
        holdings={props.data.holdings}
        cutOff={props.holdingsCutOff}
        title={props.holdingsTitle}
        type={props.type}
      />
    </div>
  )
};
export default PfCardWrapper;

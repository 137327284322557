import React, { Component } from 'react';
import { DropdownMenu, DropdownItem, CustomInput } from 'reactstrap';

export const FilterOptions = (props) => {
  return (
    <DropdownMenu className="rounded-0 second-level-dropdown">
      <div className="dropdown-wrapper">
        { props.filterValues.map((v, i) =>
            <DropdownItem
              key={i}
              onClick={() => {
                props.filterChange(props.filterAttrs, i);
                props.closeFilter(true);
              }}>
              <CustomInput id={props.filterAttrs+i} type="checkbox" label={v.name} defaultChecked={v.selected} />
            </DropdownItem>) }
      </div>
    </DropdownMenu>
  )
  //{ props.filterValues.map((v, i) => <DropdownItem active={v.selected} onClick={() => props.filterChange(props.filterAttrs, i)}>{v.name}</DropdownItem>) }
}

export const FilterSelected = (props) => {
  var attrs = Object.keys(props.filterAttrs)
                .map(i => props.filterAttrs[i])
                .reduce((acc, v) => { acc.push(...v); return acc; }, [])
  if (attrs.length === 0)
    return null;

  return (
    <div className="h5 text-muted">
      <i className="far fa-fw fa-filter"></i>&nbsp;
      { attrs.map((v, i) => <span key={i} className="badge badge-primary mr-1">{v}</span>) }
      &nbsp;<i className="far fa-fw fa-sync" title="Reset filters" onClick={props.resetFilters}></i>
    </div>
  )
}

export default {
  FilterOptions,
  FilterSelected,
}

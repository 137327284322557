import React from 'react';
import { D3Sunburst } from 'components/charts/D3Charts';
import NotApplicable from 'components/NotApplicable';
import { RegionChartMapping } from 'ChartData';
import { SectorColors } from 'data/Colors';
import { cn, AssetAllocationBurstChartMapping, CN } from 'DataSet';

const AllocationChartB_ = (props) => {
  console.log({ props })
  let { card, view, name, centerDisplay } = props;

  if (typeof card === 'undefined' || typeof card.Region === 'undefined' || !card.Region || typeof card.chartData === 'undefined') return <NotApplicable />;

  const currentChartData = card.chartData.filter(e => e.display_view_name === view)[0];

  const formatTree = data => {
    if (!data) return data;
    return data.map(e => {
      let name = e.n;
      if (name && AssetAllocationBurstChartMapping[`${name}`] !== 'undefined') {
        name = AssetAllocationBurstChartMapping[`${name}`];
      }
      if (typeof name === 'undefined') {
        name = e.n;
      }
      let out = { name, size: e.v }
      if (e.sub){
        out.children = formatTree(e.sub);
        out.value = out.children.reduce((prev,curr)=>prev+parseFloat(curr.size||0),0)
      }
      return out;
    })
  }
  const data = {
    name: props.name,
    children: formatTree(card.Region)
  };

  if (data && data.children && data.children.length === 0) {
    return <NotApplicable />;
  }

  const sum = (arr, key) => {
    return arr.reduce((a, b) => a + (b[key] || 0), 0);
  }

  let newArr = JSON.parse(JSON.stringify(data));

  newArr && newArr.children && newArr.children.map((item) => {
    item.size = sum(item.children, 'size')
    return item;
  });

  let colorsArr = props.colors;
  if (data.children && data.children.length > 0) {
    data.children.map((h, i) => {
      h.children.map((f, k) => {
        if (f.name && props.colors[f.name]) {
          colorsArr = props.colors;
        } else {
          colorsArr = {
            ...colorsArr,
            [f.name] : SectorColors[k % SectorColors.length],
          };
        }
      });
    });
  }


  if (typeof currentChartData !== 'undefined') {
    if (currentChartData.data) {
      let displayText = RegionChartMapping.filter(e => e.chart_name === currentChartData.node);
      if (displayText.length) {
        if (displayText[0].region_name) {
          if (card[currentChartData.data] >= 0) {
            centerDisplay = `${card[currentChartData.data].toFixed(1)}%`;
          }
        }
      }
    }
  }

  if (typeof card.overlap_data !== 'undefined' && card.overlap_data && card.overlap_data.length > 0) {
    if (props.activeRegion !== 'Allocations' &&  props.activeRegion !== '') {
      const activeSegment = CN[props.activeRegion];
      if (activeSegment) {
        const overlapD = card.overlap_data[0];
        const overlapValue = overlapD[activeSegment];
        if (typeof overlapValue !== 'undefined') {
          centerDisplay = Math.round(overlapValue)+'%';
        }
      }
    }
  }

  const allowedTooltip = ['Equities', 'Bonds', 'Cash'];
  const { type, overlap } = props;
  return (
    <div className="simplePieChartWrapper d3ChartWrapper model-chart">
      <div className="chart-section">
        <D3Sunburst
          data={data}
          width={props.width || 84}
          height={props.height || 84}
          colors={colorsArr}
          clickHandler={() => {}}
        />
      </div>
      <div className="legend-section">
        <span className="legend-with-header">
          <div className="d-flex legend-label">
            <span>Asset Allocation</span>
            {type !== 'current' && <span className="overlap-label">Overlap:
              <span style={{marginLeft: '10px'}}>{overlap}</span>
            </span>}
          </div>
          <div style={{width:`360px`,alignItems:'center',justifyContent:'space-between'}} className="d-flex">
            {data && data.children && data.children.map((item, index) => {
              if(!allowedTooltip.includes(item.name)) return '';
              return(
                <span className="single-legend" key={index}>
                  <span className="legend-color" style={{background: colorsArr[item.name]}}></span>
                  <span className="legend-content">
                    <span>{item.name}: <span className="legend-value">{item.value.toFixed(1)}%</span></span>
                  </span>
                </span>
              )}
            )}
          </div>
        </span>
      </div>
    </div>
  )
}

export const ModelRegionsChart = React.memo(AllocationChartB_);

export default ModelRegionsChart;

import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux'
import PerfectScrollbar from 'perfect-scrollbar';

import Item from './Item';
import { Selectable } from './shared/SfmSelectable';
import { arrayToHash } from 'Utils';
import { CN  } from 'DataSet';

import { setPortfolioReplace, setEnhancementData, addPortfolioEnhanceReplace, deletePortfolioEnhanceReplace  } from 'actions/portfolio';
import { getInvestmentWatchlistStats, getInvestmentPositionStats } from 'selectors/investment';

// const ItemList_ = ({ data, query, ticker, setReplaceTicker }) => {
//   console.log('ItemList')
//   return (
//     <div className="d-flex flex-row">
//       { data.funds.map((e, i) => (
//           <div key={i} className="pr-3">
//             <Item data={e} query={query} i={i} ticker={ticker} setReplaceTicker={setReplaceTicker} />
//           </div>
//       )) }
//     </div>
//   )
// }

class ItemList extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      enhanceReplace : '',
    }
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const { data, ticker, query, replacePortfolioToggle, addPortfolioEnhanceReplace,  view, resultSet, enhanceTickerFilter, portfolioFunds, marketFunds, watchlistFundstats, accountFundstats } = this.props;

    if (replacePortfolioToggle) {
      this.setState({ enhanceReplace: data.funds[0].ticker }, () => {
        // const filterResultSet = resultSet.filter((item) => enhanceTickerFilter.includes(item.ticker));
        const tickerWiseRet = arrayToHash(resultSet, CN['Ticker']);
        let selectedPortfolioFunds = {}
        if (tickerWiseRet[ticker] && tickerWiseRet[ticker].result && tickerWiseRet[ticker].result.funds) {
          const rA = tickerWiseRet[ticker].result.funds;
          const _queryVar = tickerWiseRet[ticker].result.query_var ? tickerWiseRet[ticker].result.query_var : []; // for showing correlation chart
          const fA = rA.filter(i => i.ticker === data.funds[0].ticker)[0];

          // const pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
          let pF = {}
          if (view == 'account') {
            pF = accountFundstats.funds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
          } else if (view == 'watchlist') {
            pF = watchlistFundstats.positions.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
          } else if (view == 'mrktPl') {
            pF = marketFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
          } else {
            pF = portfolioFunds.filter((dd) => ((dd.script || dd.symbol) === ticker) && dd.type !== 'SHARE')[0];
          }

          selectedPortfolioFunds = { ...fA, query_var: _queryVar, _pF: pF }
        }
        addPortfolioEnhanceReplace({ replaceTicker: data.funds[0].ticker, ticker, query, selectedPortfolioFunds })
      })
    }
    this.ps = new PerfectScrollbar(this.myRef.current, { suppressScrollY: true });
  }

  componentWillUnmount() {
    this.ps.destroy();
    this.ps = null;
  }

  componentWillReceiveProps(nextProps) {
    const { data, ticker, query, replacePortfolioToggle, replaceAllPortfolioToggle, view, resultSet, enhanceTickerFilter, portfolioFunds, marketFunds, watchlistFundstats, accountFundstats } = nextProps;
    if (this.props.replacePortfolioToggle != replacePortfolioToggle) {
        if (replacePortfolioToggle) {
          this.setState({ enhanceReplace: data.funds[0].ticker }, () => {
            const filterResultSet = enhanceTickerFilter.length > 0 ? resultSet.filter((item) => enhanceTickerFilter.includes(item.ticker)) : resultSet;
            const tickerWiseRet = arrayToHash(filterResultSet, CN['Ticker']);
            let selectedPortfolioFunds = {}
            if (tickerWiseRet[ticker] && tickerWiseRet[ticker].result && tickerWiseRet[ticker].result.funds) {
              const rA = tickerWiseRet[ticker].result.funds;
              const _queryVar = tickerWiseRet[ticker].result.query_var ? tickerWiseRet[ticker].result.query_var : []; // for showing correlation chart
              const fA = rA.filter(i => i.ticker === data.funds[0].ticker)[0];

              // const pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
              let pF = {}
              if (view == 'account') {
                pF = accountFundstats.funds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
              } else if (view == 'watchlist') {
                pF = watchlistFundstats.positions.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
              } else if (view == 'mrktPl') {
                pF = marketFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
              } else {
                pF = portfolioFunds.filter((dd) => ((dd.script || dd.symbol) === ticker) && dd.type !== 'SHARE')[0];
              }

              selectedPortfolioFunds = { ...fA, query_var: _queryVar, _pF: pF }
            }
            this.props.addPortfolioEnhanceReplace({ replaceTicker: data.funds[0].ticker, ticker, query, selectedPortfolioFunds })
          })
      } else if (!replacePortfolioToggle && replaceAllPortfolioToggle) {
        this.setState({ enhanceReplace: '' })
      }
    }
  }

  setReplaceTicker = (e, replaceTicker, ticker, query) => {
    const val = document.getElementById(e.target.id).checked;
    const { addPortfolioEnhanceReplace } = this.props;


    // const index = enhanceReplace.findIndex(x => x.ticker === ticker);

    // const tickerWiseRet = arrayToHash(filterResultSet, CN['Ticker']);
    // if (index >= 0) {
      if (val) {
        // enhanceReplace[index]['replace'] = replaceTicker;
        // enhanceReplace[index]['query'] = query;
        // if (tickerWiseRet[ticker] && tickerWiseRet[ticker].result && tickerWiseRet[ticker].result.funds) {
        //   const rA = tickerWiseRet[ticker].result.funds;
        //   const fA = rA.filter(i => i.ticker === replaceTicker)[0];

        //   // const pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
        //   let pF = {}
        //   if (view == 'account') {
        //     pF = accountFundstats.funds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
        //   } else if (view == 'watchlist') {
        //     pF = watchlistFundstats.positions.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
        //   } else if (view == 'mrktPl') {
        //     pF = marketFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
        //   } else {
        //     pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
        //   }
        //   enhanceReplace[index]['selectedPortfolioFunds'] = {
        //     ...fA,
        //     _pF: pF,
        //   };
        // }
        // setPortfolioReplace(enhanceReplace);
        // this.setState({ enhanceReplace, query, replaceTicker});



        const { enhanceReplace } = this.state;
        this.setState({ enhanceReplace: replaceTicker }, () => {
          addPortfolioEnhanceReplace({ replaceTicker, ticker, query })
        })
      } else {
        // enhanceReplace.splice(index, 1)

        // if (tickerWiseRet[ticker] && tickerWiseRet[ticker].result && tickerWiseRet[ticker].result.funds) {
        //   const rA = tickerWiseRet[ticker].result.funds;
        //   const fA = rA.filter(i => i.ticker === replaceTicker)[0];
        //   const pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
        //   enhanceReplace[index]['selectedPortfolioFunds'] = {
        //     ...fA,
        //     _pF: pF,
        //   };
        // }
        // setPortfolioReplace(enhanceReplace);
        // this.setState({ enhanceReplace, query, replaceTicker});
      }
    // } else {

      // if (tickerWiseRet[ticker] && tickerWiseRet[ticker].result && tickerWiseRet[ticker].result.funds) {
      //   const rA = tickerWiseRet[ticker].result.funds;
      //   const fA = rA.filter(i => i.ticker === replaceTicker)[0];

      //   // const pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
      //   let pF = {}
      //     if (view == 'account') {
      //       pF = accountFundstats.funds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
      //     } else if (view == 'watchlist') {
      //       pF = watchlistFundstats.positions.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
      //     } else if (view == 'mrktPl') {
      //       pF = marketFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
      //     } else {
      //       pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
      //     }
      //   enhanceReplace.push({ ticker, replace: replaceTicker, query, selectedPortfolioFunds: {
      //     ...fA,
      //     _pF: pF,
      //   }});
      // }
      // this.setState({ enhanceReplace, query, replaceTicker});
    //   setPortfolioReplace(enhanceReplace);
    // }

    // if (ticker) {
    //   if (tickerWiseRet[ticker] && tickerWiseRet[ticker].result && tickerWiseRet[ticker].result.funds) {
    //     const rA = tickerWiseRet[ticker].result.funds;
    //     const fA = rA.filter(i => i.ticker === replaceTicker);
    //     if (fA.length) {
    //       setEnhancementData({
    //         enhancementData: fA,
    //       });
    //     }
    //   }
    // }
  }


  setReplaceTicker1 = (e, replaceTicker, ticker, query) => {
    const val = document.getElementById(e.target.id).checked;
    const { view, resultSet, enhanceTickerFilter, addPortfolioEnhanceReplace, deletePortfolioEnhanceReplace, portfolioFunds, marketFunds, watchlistFundstats, accountFundstats } = this.props;

    if (val) {
      this.setState({ enhanceReplace: replaceTicker }, () => {
        const filterResultSet = enhanceTickerFilter.length > 0 ? resultSet.filter((item) => enhanceTickerFilter.includes(item.ticker)) : resultSet;
        const tickerWiseRet = arrayToHash(filterResultSet, CN['Ticker']);
        let selectedPortfolioFunds = {}
        if (tickerWiseRet[ticker] && tickerWiseRet[ticker].result && tickerWiseRet[ticker].result.funds) {
          const rA = tickerWiseRet[ticker].result.funds;
          const _queryVar = tickerWiseRet[ticker].result.query_var ? tickerWiseRet[ticker].result.query_var : []; // for showing correlation chart
          const fA = rA.filter(i => i.ticker === replaceTicker)[0];

          // const pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
          let pF = {}
          if (view == 'account') {
            pF = accountFundstats.funds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
          } else if (view == 'watchlist') {
            pF = watchlistFundstats.positions.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
          } else if (view == 'mrktPl') {
            pF = marketFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
          } else {
            pF = portfolioFunds.filter((dd) => ((dd.script || dd.symbol) === ticker) && dd.type !== 'SHARE')[0];
          }

          selectedPortfolioFunds = { ...fA, query_var: _queryVar, _pF: pF }
        }
        addPortfolioEnhanceReplace({ replaceTicker, ticker, query, selectedPortfolioFunds })
      })
    }  else {
      this.setState({ enhanceReplace: '' }, () => {
        deletePortfolioEnhanceReplace({ replaceTicker, ticker, query })
      })
    }
  }

  render () {
    const { data, query, ticker } = this.props;
    return (
      <div ref={this.myRef} className="ps-wrap">
        <div className="d-flex flex-row">
          { data.funds.map((e, i) => (
              <div key={i} className="pr-3">
                <Item data={e} query={query} i={i} ticker={ticker} setReplaceTicker={this.setReplaceTicker1} enhanceReplace={this.state.enhanceReplace} />
              </div>
          )) }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  view: state.investment.view,
  replacePortfolioToggle: state.portfolio.replacePortfolioToggle,
  replaceAllPortfolioToggle: state.portfolio.replaceAllPortfolioToggle,
  resultSet: state.portfolio.resultSet,
  enhanceTickerFilter: state.portfolio.enhanceTickerFilter,
  portfolioFunds: state.investment.portfolioFunds,
  marketFunds: state.investment.marketFunds,
  watchlistFundstats: getInvestmentWatchlistStats(state),
  accountFundstats: getInvestmentPositionStats(state),
})

const mapDispatchToProps = {
  addPortfolioEnhanceReplace,
  deletePortfolioEnhanceReplace
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemList)

// const withScrollBar = (WrappedComponent) => {
//   return class extends React.Component {
//     constructor(props) {
//       super(props);
//       this.myRef = React.createRef();
//     }

//     componentDidMount() {
//       this.ps = new PerfectScrollbar(this.myRef.current, { suppressScrollY: true });
//     }

//     componentWillUnmount() {
//       this.ps.destroy();
//       this.ps = null;
//     }

//     render() {
//       return (
//         <div ref={this.myRef} className="ps-wrap">
//           <WrappedComponent {...this.props} />
//         </div>
//       )
//     }
//   }
// }

// const ItemList = withScrollBar(ItemList_);
// export default ItemList

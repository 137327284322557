import React from 'react'
import { connect } from 'react-redux'
import { createSubscriptionRequest, fetchProfile } from 'actions/profile'
import {
  Alert,
  Col,
  Collapse,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Button,
  Input
} from 'reactstrap'
import { PrimaryButton } from 'shared-components'
import PaymentStatusModal from '../../layouts/WebDashboard/SearchResults/Elements/PaymentStatus/PaymentStatusModal';

class UserPlanType extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openUnsubscribeModal: false
    }
    this.props.fetchProfile();
  }

  convertDateFormat(date, outputType) {
    if (date && outputType) {
      const d = new Date(date)
      const dtf = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long', day: '2-digit' })
      const [{ value: mo }, , { value: da }, , { value: ye }] = dtf.formatToParts(d)
      return (outputType == 'm-y') ? `${mo} ${ye}` : (outputType == 'm-d-y') ? `${mo} ${da}, ${ye}` : ''
    }
  }

  render() {
    let renderModal = '';
    if (window.sessionStorage.getItem('paymentStatus')) {
      renderModal = <PaymentStatusModal userEmail={this.props.useremail} paymentStatus={window.sessionStorage.getItem('paymentStatus')} />
    }
    let renews_on = this.props.userDetails.renews_on ? this.convertDateFormat(new Date(this.props.userDetails.renews_on), 'm-d-y') : '';
    let subscriber_since = this.props.userDetails.subscriber_since ? this.convertDateFormat(new Date(this.props.userDetails.subscriber_since), 'm-y') : '';
    let cancels_on = this.props.userDetails.cancels_on ? this.convertDateFormat(new Date(this.props.userDetails.cancels_on), 'm-d-y') : '';
    return (
      <React.Fragment>
        {renderModal}
        <div className="profile__section profile__personal-info top">
          <div className="profile__section-header">
            <Container>
              <Row>
                <Col xs={12}>
                  <h2 className="profile__section-title">
                    Subscribe to premium membership
                  </h2>
                </Col>
              </Row>
            </Container>
          </div>
          <Container>
            <Row>
              <Col xs={12} className="">
                <div className="">
                  <Row className="profile-element-wrapper">
                    <Col xs={6} className="profile-description-heading">
                      <h5>Plan Type</h5>
                    </Col>
                    <Col xs={6} className="profile-change-plan text-right">
                      <h5>{(this.props.userDetails.premiumUser) ? 'Premium' : 'Basic'}</h5>
                      {subscriber_since && <p className="profile-mute-text">Member since {subscriber_since}</p>}
                      {cancels_on ? <p className="profile-mute-text float-right">Expires on {cancels_on}</p> : renews_on ? <p className="profile-mute-text float-right">Renews on {renews_on}</p> : ''}
                      {/* <div className="profile__subscription-button float-right">
                          <Container>
                            <Row>
                              <Col offset-xs={12}>
                                <FormGroup>
                                <Button color="tools"
                                    className="btn h3 text-uppercase float-right font-weight-bold p-3 mb-0 ml-auto secondary-btn"
                                    onClick={this.props.changePlan}
                                  >
                                    CHANGE PLAN
                                  </Button>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Container>
                        </div> */}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = {
  fetchProfile
}

export default connect(
  null,
  mapDispatchToProps
)(UserPlanType);

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Row,
  ButtonGroup,
  Dropdown,
  FormGroup,
  ModalBody,
  Input,
  ModalFooter,
  Modal,
  Form,
} from 'reactstrap';
import { Carousel } from 'react-responsive-carousel';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { UncontrolledTooltip } from 'reactstrap';
import { trackFunds } from 'apis/funds'
import { arrayToHash } from '../../Utils';
import { cn, Brokers, BrokerNameMap, BrokersList, Api, ApiHeaders, D3Colors09, D3AppColors09, isDemoUser, sponsorLogo } from '../../DataSet';
import Confirm from './Confirm';
import { numFmt, getNest, getImageOrFallback, closeTraditWindow } from '../../Utils';
import { FooterActions, TruncatedNameSponsor } from './Common';
import ItemCarousel from '../ItemCarousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { isNull } from 'util';
import { get } from 'lodash';
import { CSVLink } from "react-csv";
import { PrimaryButton } from 'shared-components'
import CustomAlert from 'components/CustomAlert';
import { addEventToAnalytics } from 'Utils';

var classNames = require('classnames');

export default class ActConfirm extends React.Component {
  render() {
    return (
      this.props.stage === 'act'
        ? <Act  {...this.props} />
        : this.props.stage === 'confirm'
          ? <Confirm {...this.props} />
          : null
    )
  }
}

const comingSoonValues = ['SMA', 'MP'];

class Act extends React.Component {
  state = { activate: false, activate2: false, doRegister: false }

  buyHandler = () => {
    this.props.buyHandler('all');
  }

  activateHandler = (flag) => {
    if (flag !== this.state.activate)
      this.setState({ activate: flag });
  }

  activateHandler2 = (flag) => {
    if (flag !== this.state.activate2)
      this.setState({ activate2: flag });
  }

  doRegisterHandler = (flag) => {
    if (flag !== this.state.doRegister)
      this.setState({ doRegister: flag });
  }

  trackHandler = () => {
    this.trackFundData();
    this.props.clearAllHandler();
    this.props.confirmHandler(true);
  }

  trackFundData = async () => {
    const { items } = this.props;
    let funds = [];
    items.forEach(e => {
      let qty = getNest(['_order', 'state', 'amtType'], e) === 'Value'
        ? +getNest(['_order', 'qty'], e)
        : +getNest(['_order', 'state', 'qty'], e);
      if (qty <= 0 || isNaN(qty) || isNull(qty)) return;

      let out = {
        ticker: cn(e, 'Ticker'),
        price: parseFloat(cn(e, 'Nav Price').toFixed(2)),
        quantity: qty,
        account_number: 'Dummy',
        transaction_type: 'TRACK',
      }

      let flag = e._brokerFlags && e._brokerFlags.find(f => f.sel);
      let price = getNest(['_order', 'price'], e);


      if (flag) out.broker = flag.id;
      if (price) out.price = +price;
      funds.push(out);
    })
    if (funds.length <= 0)
      return;

    let postData = { funds };
    let resp = await trackFunds(postData)
    return resp;
  }

  render() {
    return (
      <div>
        <div className="modal-body-ssf p-2 media-none">
          <ItemList {...this.props}
            activateHandler={this.activateHandler}
            activateHandler2={this.activateHandler2}
            doRegisterHandler={this.doRegisterHandler} />
        </div>
        <div className="d-md-none modal-body-ssf d-flex align-items-center flex-column justify-content-center">
          <p className="text-center px-2 mb-2">Please visit magnifi.com on a desktop to access features for further analysis and trading</p>
        </div>
        <ModalFooter className="py-2 media-none sfm-modal-footer">
          <Footer {...this.props}
            buyHandler={this.buyHandler}
            trackHandler={this.trackHandler}
            activate={this.state.activate}
            activate2={this.state.activate2}
            doRegister={this.state.doRegister}
          />
        </ModalFooter>
      </div>
    )
  }
}

class ItemList extends React.Component {
  state = {
    amt: 'Units',
  }
  selectable = broker => get(broker, 'status') !== 'Register'

  componentDidMount() {
    const elems = document.querySelectorAll('.custom-scroll');
    if (elems.length) {
      [].forEach.call(elems, function (el) {
        if (el.scrollHeight > el.clientHeight) {
          el.style.paddingRight = '.25rem';
          el.style.paddingLeft = '.40rem';
        } else el.style.paddingRight = '0rem';
      });
    }
  }


  componentWillMount() {
    let brokers = get(this.props, 'brokers', []).map(e => {
      let b = Brokers.find(b => b.name === e.name);
      if (!b) b = Brokers.find(b => b.name === 'dummy');
      return { ...b, ...e }
    });
    let brokerNames = brokers.map(e => e.name);
    var flags = {}
    var comms = this.props.items.map(e => {
      return ({ t: cn(e, 'Ticker'), b: e.brokers })
    });
    comms.forEach(c => {
      const _c = brokerNames.map(b => c.b.find(e => e.name === b));
      const min = Math.min(..._c.map(e => e.commission));
      flags[c.t] = _c.map((e, i) => ({
        min: e.commission === min,
        sel: false,
        disabled: !this.selectable(brokers[i]),
        id: brokers[i].id
      }));
    })

    this.props.doRegisterHandler(!brokers.some(this.selectable));
    this.setState({ brokers, brokerNames, flags }, () => this.selectBroker(brokers[0]));
  }

  componentWillReceiveProps(newProps) {
    var comms = newProps.items.map(e => ({ t: cn(e, 'Ticker'), b: e.brokers }));

    var flags = {}, newComms = [];
    comms.forEach(c => {
      if (!this.state.flags[c.t]) {
        const _c = this.state.brokerNames.map(b => c.b.find(e => e.name === b));
        const min = Math.min(..._c.map(e => e.commission));
        flags[c.t] = _c.map(e => ({ min: e.commission === min, sel: false }));
        newComms.push(c);
      }
      else {
        flags[c.t] = this.state.flags[c.t];
      }
    })
    this.setState({ flags }, () => {
      newComms.forEach(e => this.selectBroker(this.state.brokers[0], e.t));
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true; //this.props.items.length !== nextProps.items.length;
  }

  selectBroker = (broker, ticker, accNumber) => {
    if (!this.selectable(broker))
      return;

    var bi = this.state.brokerNames.indexOf(get(broker, 'name'));
    var brokers = this.state.brokers.slice(0);
    brokers.forEach(e => e._all = false);

    if (ticker) {
      this.state.flags[ticker].forEach((e, i) => e.sel = i === bi);
      // this.state.flags[ticker].forEach((e, i) => e.account_number = i === bi ? account_number : null );
      brokers.forEach((b, _bi) => {
        b._any = !!Object.keys(this.state.flags).map(t => this.state.flags[t][_bi]).find(e => e.sel);
      })
    }
    else {
      brokers.forEach(e => e._any = false);
      // fails when no internet connection
      if (broker) {
        broker._all = true;
      }
      Object.keys(this.state.flags).forEach(t => {
        this.state.flags[t].forEach((e, i) => e.sel = i === bi);
      })
    }

    this.setState({ brokers });
  }

  selectOptimum = () => {
    if (!this.state.brokers.some(this.selectable))
      return;

    var comms = this.props.items.map(e => ({ t: cn(e, 'Ticker'), b: e.brokers }));
    comms.forEach(c => {
      var flag = this.state.flags[c.t];
      var min = -1;  // index of first min
      if (flag) {
        flag.forEach((e, i) => {
          if (!e.disabled && e.min && min === -1) {
            min = i;
          }
        })
        min >= 0 && this.selectBroker(this.state.brokers[min], c.t);
      }
    })
  }

  amtHandler = (amt) => {
    this.setState({ amt });
  }

  buyHandler = () => {

    let valid = this.props.items.some(e => getNest(['_order', 'state', 'valid'], e));

    let mfCount = this.props.items.filter(e => {
      return !comingSoonValues.includes(getNest(['_order', 'state', 'type'], e)) && getNest(['_order', 'state', 'qty'], e) != ''
    });

    if (mfCount.length === 0) {
      this.props.activateHandler(false);
    }
    else {
      this.props.activateHandler(valid);
    }

    let valid2 = this.props.items.some(e => getNest(['_order', 'state', 'valid2'], e));
    //this.props.activateHandler(valid);
    this.props.activateHandler2(valid2);
  }

  accountHandler = (broker, account_number, ticker) => {
    var bi = this.state.brokerNames.indexOf(broker.name);
    var brokers = this.state.brokers.slice(0);
    brokers.forEach(e => e._all = false);

    if (ticker && this.state.flags[ticker][bi].sel) {
      this.state.flags[ticker].forEach((e, i) => e.account_number = i === bi ? account_number : null);
    }
    else {
      Object.keys(this.state.flags).forEach(t => {
        this.state.flags[t].forEach((e, i) => {
          if (i === bi) {
            return e.account_number = account_number;
          }
        });
      })
    }
  }

  addBroker = () => {
    addEventToAnalytics('Act Add Broker', 'Act Add Broker', 'ACT_ADD_BROKER', { category: 'advisory_PTC' }, true);
  }

  render() {
    const items = this.props.items.filter(e => getNest(['_order', 'stage'], e) === 'buy');
    if (items.length === 0) return null;
    const sel = (b) => b._all || b._any;
    const isBrokerRegister = false;
    const { brokers } = this.state;
    const registeredBrokerArr = brokers.filter((item) => item.status !== 'Register');
    const isBrokerRegistered = registeredBrokerArr.length ? true : false;
    let DataList = items;

    if (items.find((y) => y._showSponsoredOnSFM)) {
      const fundsThatisSponsored = items.filter((s) => s._showSponsoredOnSFM);
      const fundsThatisNotSponsored = items.filter((s) => !s._showSponsoredOnSFM);
      DataList = [...fundsThatisNotSponsored];
      if (fundsThatisSponsored.length) {
        fundsThatisSponsored.forEach((f, i) => {
          if (f.ticker && DataList.find((s) => s.ticker !== f.ticker)) {
            DataList.unshift(f);
          }
        });
      }
      // items.find((y) => y._showSponsoredOnSFM) ? (
      //   items.sort(function(a,b){return a._showSponsoredOnSFM-b._showSponsoredOnSFM}).reverse()
      // ) :
    }

    // console.log(DataList);

    const addBrokerDesign = (
      <div style={{ display: 'flex', width: '100%' }}>
        <div className="display-flex-col col-80">
          {DataList.map((e, i) => {
            return (
              <div key={cn(e, 'Id')}>
                <ItemCard
                  {...this.props}
                  card={e}
                  flag={this.state.flags[cn(e, 'Ticker')]}
                  idx={i}
                  brokers={registeredBrokerArr}
                  selectBroker={this.selectBroker}
                  clearHandler={this.props.clearHandler}
                  buyHandler={this.buyHandler}
                  accountHandler={this.accountHandler}
                  isBrokerRegistered={isBrokerRegistered}
                  amt={this.state.amt}
                  isMF={e.vehicle}
                />
              </div>
            )
          })}
        </div>
        <div className="col-20 card shadow-sm mb-2 border-0 add-broker-wrapper">
          <div className="bg-ssf-gray-l9 px-2 py-1 h-100 d-flex align-items-center justify-content-center" style={{ minHeight: '75px' }}>
            {isDemoUser()
              ? <Link to="/settings/broker/select">
                <Button color="select" className="add-broker-button" onClick={() => this.addBroker()}>
                  <i className="fas fa-plus-circle" />
                  Broker
                </Button>
              </Link>
              : <div className="add-broker-button" onClick={() => this.addBroker()}>
                <i className="fas fa-plus-circle" />
                Broker
              </div>}
          </div>
        </div>
      </div>
    );
    const showBrokerDesign = items.map((e, i) => {
      return (
        <div key={cn(e, 'Id')}>
          <ItemCard
            {...this.props}
            card={e}
            flag={this.state.flags[cn(e, 'Ticker')]}
            idx={i}
            brokers={registeredBrokerArr}
            selectBroker={this.selectBroker}
            clearHandler={this.props.clearHandler}
            buyHandler={this.buyHandler}
            isBrokerRegistered={isBrokerRegistered}
            accountHandler={this.accountHandler}
            amt={this.state.amt}
            isMF={e.vehicle}
          />
        </div>
      )
    })
    const renderDesign = isBrokerRegistered ? showBrokerDesign : addBrokerDesign;
    const brokerLabel = registeredBrokerArr.length > 1 ? 'Your Brokers' : 'Your Broker';
    return (
      <div style={{ height: '100%' }}>
        <div className="lh-125 display-flex-row align-items-center">
          <div className="col-40" />
          <div className="flex-row-child col-20 padding-5-right-1">
            <div className="h-100">
              <Button color="secondary" block className="broker h-100 non-clickable">
                Amount
              </Button>
            </div>
            <UnitsValue funds={this.props.funds} amtHandler={this.amtHandler} />
          </div>
          <div className="flex-row-child col-20 padding-5-right-1">
            <div className="h-100">
              <Button color="secondary" block className="broker h-100 non-clickable">
                Market Price
              </Button>
            </div>
            <div style={{ height: 30 }} />
          </div>
          <div className="flex-row-child col-20 padding-5-right-1">
            <div className="h-100">
              <Button color="secondary" block className="broker h-100 non-clickable">
                {brokerLabel}
              </Button>
            </div>
            <div className="text-center">
              {isDemoUser()
                ? <Link to="/settings/broker/select" className="h6 text-gray-8 p-0 my-2 lh-100 btn btn-link broker-link" style={isBrokerRegistered ? { visibility: 'visible' } : { visibility: 'hidden' }}>
                  + New Broker
                </Link>
                : <div className="h6 text-gray-8 p-0 my-2 lh-100 btn btn-link broker-link" style={isBrokerRegistered ? { visibility: 'visible' } : { visibility: 'hidden' }}>
                  + New Broker
                </div>}
            </div>
          </div>
        </div>
        <div className="item-card-area" style={{ flexDirection: isBrokerRegistered ? 'column' : 'row' }}>
          <div className="h-335 custom-scroll" style={{ width: '100%' }}>
            {renderDesign}
          </div>
        </div>
      </div>
    );
  }
}

ItemList.defaultProps = {
  brokers: []
}

class ItemCard extends React.Component {
  state = {
    price: '',
    qty: '',
    tvalue: '',
    amtType: 'Units',
    valid: false,
    activeKey: 0,
    imageValidPath: false,
    type: '',
  }

  constructor(props) {
    super(props);
    this.initState();
  }

  unselectCard = (item) => {
    const { funds } = this.props;
    this.props.clearHandler(item);
    if (funds) {
      addEventToAnalytics('ACT fund delete', 'ACT fund delete', 'ACT_FUND_DELETE', { query: funds.query, logId: funds.logId, ticker: item.ticker }, true);
    }
  }

  componentDidMount() {
    const { card } = this.props;
    if (cn(card, 'Sponsor')) {
      getImageOrFallback(
        sponsorLogo(cn(card, 'Sponsor'))
      ).then(result => {
        if (result) {
          this.setState({ imageValidPath: true });
        } else {
          this.setState({ imageValidPath: false });
        }
      });
    }

    if (this.props.card._order === undefined)
      this.props.card._order = {
        otype: 'Market',
        price: '',
        qty: '',
        validity: undefined,
        state: { ...this.state },
      };
  }

  shouldComponentUpdate(props) {
    const commH = arrayToHash(props.card.brokers, 'name');
    props.brokers.forEach((e, i) => props.flag[i].sel && (props.card.broker = commH[e.name])); // not the right place?
    props.card._brokerFlags = props.flag;  // need rework
    props.card._order.state = { ...this.state };
    this.tradeValue();
    props.buyHandler();
    return true;
  }

  selectComm = (broker) => {
    this.props.selectBroker(broker, cn(this.props.card, 'Ticker'));
  }

  ot = () => ['Market', 'Limit'][this.state.otype];

  otypeHandler = (event) => {
    const otype = event.target.value;
    this.props.card._order.otype = otype;
    this.setState({ otype });
  }

  qtyHandler = (event) => {
    this.state.qty = event.target.value;
    this.state.amtType = 'Units';
    this.setState({ amtType: 'Units' });
  }

  componentWillReceiveProps(props) {
    this.setState({ type: this.props.isMF });
  }

  priceHandler = (event) => {
    this.props.card._order.price = event.target.value;
    this.setState({ price: event.target.value });
  }

  tvalueHandler = (event) => {
    this.state.tvalue = event.target.value;
    this.state.amtType = 'Value';
    this.setState({ amtType: 'Value' });
  }

  validityHandler = (event) => {
    const validity = event.target.value;
    this.state.validity = validity;
    this.setState({ validity }, () => {
      this.props.card._order.validity = this.state.validity;
    });
  }

  tradeValue = () => {
    const { card, flag } = this.props;
    let _comm = flag.some(e => e.sel) ? card.broker.commission : 0.0;
    let price = (card._order.otype === 'Market' ? cn(card, 'Nav Price') : card._order.price) || 0.0;
    if (this.state.amtType === 'Value') {
      let tvalue = this.state.tvalue - _comm;
      card._order.qty = tvalue >= 0 && price > 0.0 ? Math.floor(tvalue / price) : ''
      this.state.qty = '';
      card._order.tvalue = '';
    }
    else if (this.state.amtType === 'Units') {
      card._order.tvalue = this.state.qty > 0 && price > 0 ? (this.state.qty * price + _comm) : 0.0;
      this.state.tvalue = '';
      card._order.qty = '';
    }
    this.state.valid = this.validate();
    this.state.valid2 = this.validate2();
    card._order.state = { ...this.state };
  }

  initState = () => {
    const { card } = this.props;
    if (card._order.state)
      this.state = { ...card._order.state }
    this.state.otype = card._order.otype;
    this.state.price = card._order.price;
    this.state.validity = card._order.validity;
  }

  validate = () => {
    const { card, flag } = this.props;
    const isNum = n => +n > 0
    return (isNum(card._order.qty) || isNum(card._order.tvalue)) &&
      (card._order.otype === 'Market' || (isNum(card._order.price) &&
        ['End Of Day', 'Cancelled'].indexOf(this.state.validity) >= 0)) &&
      flag.some(e => e.sel)
  }

  validate2 = () => {
    const { card, flag } = this.props;
    const isNum = n => +n > 0
    return (isNum(card._order.qty) || isNum(card._order.tvalue)) &&
      (card._order.otype === 'Market' || isNum(card._order.price))
  }

  accountHandler = (broker, account_number, card) => {
    const { accountHandler } = this.props;
    accountHandler(broker, account_number, card);
  }

  handleActiveIndexKey = (index) => {
    this.setState({
      activeKey: index,
    });
  }

  selectedBrokerInfo = (broker) => {
    this.selectComm(broker);
  }

  render() {
    const { activeKey, imageValidPath } = this.state;
    const { card, brokers, idx, flag, amt, isBrokerRegistered, importedAccounts, isMF } = this.props;
    if (!card.broker || !card._order) return null;

    const commH = arrayToHash(card.brokers, 'name');
    const disabledAll = flag.every(e => e.disabled);

    return (
      <Card className={classNames("shadow-sm mb-2 border-0")}>
        <CardBody className="p-0 ">
          <div className="no-gutters display-flex-row">
            <div className={` ${isBrokerRegistered ? 'col-40' : 'col-50'} padding-5-right-1`}>
              <div className="bg-ssf-gray-l5 p-1 h-100 d-flex align-items-center pos-rel">
                {/*card._showSponsoredOnSFM && <div className="sponsored-logo-tag" style={{ top: 5, left: 6 }}>SPONSORED</div>*/}
                <div className="pr-1 px-2 text-center">
                  <h5 className="mb-0 text-blue text-gray-5 ticker-size ticker-price">{cn(card, 'Ticker Short')}</h5>
                  <h3 className={`mb-0 text-info`} style={{ minWidth: '5rem' }}>
                    <span className="ticker-price text-gray-5">$</span>
                    <span className="ticker-price">{cn(card, 'Nav Price').toFixed(1)}</span>
                  </h3>
                </div>
                <TruncatedNameSponsor
                  style={{ flex: 1 }}
                  showSponsorLogo={card._showSponsoredOnSFM}
                  imageValidPath={imageValidPath}
                  name={cn(card, 'Name2')}
                  sponsor={cn(card, 'Sponsor')}
                  lines={1}
                />
                <div className="d-flex align-items-start h-100 close-wrapper">
                  <div className="close-icon-box pl-0">
                    <i className="fal fa-times-circle" onClick={() => this.unselectCard(card)}></i>
                  </div>
                </div>
              </div>
            </div>
            <div className={` ${isBrokerRegistered ? 'col-20' : 'col-25'} padding-5-right-1`}>
              <div className="bg-ssf-gray-l9 px-2 py-1 h-100 d-flex align-items-center justify-content-center">
                <div className="flex-row" style={{ marginTop: '17px' }}>
                  {amt === 'Units' &&
                    <React.Fragment>
                      <FormGroup className="m-auto" style={{ maxWidth: 100 }}>
                        <Input type="number" name="qty" id="orderQty" bsSize="sm" placeholder="Units" min="0"
                          className="text-center"
                          value={this.state.qty}
                          onChange={this.qtyHandler} />
                      </FormGroup>
                      <div className="text-center h5 lh-125 my-0">
                        <small><span className="text-gray-5">Value: </span>${numFmt(card._order.tvalue, 0)}</small>
                      </div>
                    </React.Fragment>}
                  {amt === 'Value' &&
                    <React.Fragment>
                      <FormGroup className="m-auto" style={{ maxWidth: 100 }}>
                        <Input type="number" name="tvalue" id="orderTvalue" bsSize="sm" placeholder="Value" min="0"
                          className="text-center"
                          value={this.state.tvalue}
                          onChange={this.tvalueHandler} />
                      </FormGroup>
                      <div className="text-center h5 lh-125 my-0">
                        <small><span className="text-gray-5">Units: </span>{numFmt(card._order.qty || 0, 0)}</small>
                      </div>
                    </React.Fragment>}
                </div>
              </div>
            </div>
            <div className={` ${isBrokerRegistered ? 'col-20 padding-5-right-1' : 'col-25 padding-5'}`}>
              <div className="bg-ssf-gray-l9 px-2 py-1 h-100 d-flex align-items-center justify-content-center" style={{ minHeight: '75px' }}>
                <div className="form-row w-100">
                  <FormGroup className="col-12 mb-0 d-none" >
                    <Input type="select" name="otype" id="orderType" bsSize="sm" required
                      value={this.state.otype}
                      onChange={this.otypeHandler}>
                      <option value="Market">Market</option>
                      {/* <option value="Limit">Select Limit</option> */}
                    </Input>
                  </FormGroup>
                  <div className="pr-1 text-center mx-auto">
                    <h5 className="mb-0 text-info " style={{ minWidth: '4rem', lineHeight: 1.5 }}>
                      <div className="mb-1" style={{ display: 'flex' }}>
                        <div className="font-weight-bold" style={{
                          background: 'rgb(53,53,53,0.16)',
                          color: '#353a3d',
                          width: '45px',
                          borderRadius: '3px',
                          marginRight: '5px'
                        }}
                        >
                          Bid
                        </div>
                        <div className="" style={{ color: '#6c757d' }}>${(cn(card, 'Nav Price') - (cn(card, 'Nav Price') * 0.5 / 100)).toFixed(2)}</div>
                      </div>
                      <div className="m-0" style={{ display: 'flex' }}>
                        <div className="font-weight-bold" style={{
                          background: 'rgb(53,53,53,0.16)',
                          color: '#353a3d',
                          width: '45px',
                          borderRadius: '3px',
                          marginRight: '5px'
                        }}
                        >
                          Ask
                        </div>
                        <div className="" style={{ color: '#6c757d' }}>${(cn(card, 'Nav Price') + (cn(card, 'Nav Price') * 0.5 / 100)).toFixed(2)}</div>
                      </div>
                    </h5>
                  </div>
                  {this.state.otype !== 'Market' &&
                    <React.Fragment>
                      <FormGroup className="col-5 mb-0 mt-1">
                        <Input type="number" name="price" id="orderPrice" bsSize="sm" placeholder="Price"
                          min="0" step="0.05" className="px-1" readOnly={this.state.otype === 'Market'}
                          value={this.state.price}
                          onChange={this.priceHandler} />
                      </FormGroup>
                      <FormGroup className="col-7 mb-0 mt-1 pl-0">
                        <Input type="select" name="validity" id="orderValid" bsSize="sm" required
                          style={{ padding: '.25rem 2px' }}
                          value={this.state.validity}
                          defaultValue=""
                          onChange={this.validityHandler}>
                          <option disabled value="">Good Till</option>
                          <option>End Of Day</option>
                          <option>Cancelled</option>
                        </Input>
                      </FormGroup>
                    </React.Fragment>}
                </div>
              </div>
            </div>
            {isBrokerRegistered
              && (
                <div className="col-20 padding-5">
                  <div className="bg-ssf-gray-l9 px-2 py-1 h-100 d-flex align-items-center justify-content-center" style={{ minHeight: '75px' }}>
                    <div className="broker-carousel-wrapper">
                      <ItemCarousel
                        {...this.props}
                        isDemoUser={isDemoUser}
                        isMutualFund={card.vehicle}
                        itemArray={brokers}
                        card={cn(card, 'Ticker')}
                        accountHandler={this.accountHandler}
                        AccountDropDownValue={AccountDropDownValue}
                        activeKey={activeKey}
                        handleActiveIndexKey={this.handleActiveIndexKey}
                        selectedBrokerInfo={this.selectedBrokerInfo}
                      />
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </CardBody>
      </Card>
    );
  }
}

export class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadTradeList: false,
      tradeListDropdown: false,
      tradeListValue: '',
      accountDropdown: false,
      brokerDropdownValue: '',
      accountNumber: [],
      accounNoDropdown: false,
      selectAccountNumber: '',
      tradeList: '',
      downloadCSV: false,
    }
    this.csvLink = React.createRef();
    this.fidelityCsvLink = React.createRef();
  }

  onDownloadTradeList = () => {
    this.setState({
      downloadTradeList: true,
      downloadCSV: false,
    });
  }

  onCloseDownloadTradeList = () => {
    this.setState({ downloadTradeList: false });
    this.setState({ tradeListValue: '' });
    this.setState({ brokerDropdownValue: '' });
    this.setState({ selectAccountNumber: '' });
  }

  tradeListDropDownToggle = () => {
    this.setState({ tradeListDropdown: !this.state.tradeListDropdown });
  }

  accountDropdownToggle = () => {
    this.setState({ accountDropdown: !this.state.accountDropdown });
  }

  accountNoDropdownToggle = () => {
    this.setState({ accounNoDropdown: !this.state.accounNoDropdown });
  }

  getAccountName = (item) => {
    const accountArray = [];

    const { importedAccounts } = this.props;

    var valuesData = Object.entries(importedAccounts).map(([k, v]) => ({ [k]: v }))

    valuesData.map((i, key) => {
      if (item === Object.keys(i).toString()) {
        accountArray.push(i);
      }
    });

    const ids = [];
    JSON.stringify(accountArray, (key, value) => {
      if (key === 'account_number') ids.push(value);
      return value;
    });

    this.setState({
      accountNumber: ids,
    });

    this.setState({
      brokerDropdownValue: item,
    });

    this.setState({ selectAccountNumber: '' });
  }

  getAccountNumber = (item) => {
    this.setState({
      selectAccountNumber: item,
    })
  }

  downloadOrionTradeListExcel = () => {

    const { items } = this.props;

    var CSVDataValue = items.map((i) => {
      if (getNest(['_order', 'state', 'valid2'], i) == true && getNest(['_order', 'state', 'amtType'], i) == 'Units') {
        var info = {
          "account_number": this.state.selectAccountNumber,
          "transaction": getNest(['_order', 'stage'], i) == 'buy' ? 'B' : 'T',
          "quantity": getNest(['_order', 'state', 'qty'], i),
          "ticker": i.ticker,
          "price": parseInt(getNest(['_order', 'tvalue'], i)),
        }
        return info;
      }
      if (getNest(['_order', 'state', 'valid2'], i) == true && getNest(['_order', 'state', 'amtType'], i) == 'Value') {
        var info = {
          "account_number": this.state.selectAccountNumber,
          "transaction": getNest(['_order', 'stage'], i) == 'buy' ? 'B' : 'T',
          "quantity": getNest(['_order', 'qty'], i),
          "ticker": i.ticker,
          "price": parseInt(getNest(['_order', 'qty'], i) * (i.nav)),
        }
        return info;
      }
    });

    var finalValue = CSVDataValue.filter(i => i);

    this.setState({
      downloadCSV: true,
    });

    this.setState({ tradeList: finalValue }, () => {
      // click the CSVLink component to trigger the CSV download
      this.csvLink.current.link.click()
    })

    this.onCloseDownloadTradeList();
  }

  downloadFidelityTradeListExcel = () => {

    const { items } = this.props;

    var CSVDataValue = items.map((i) => {
      if (getNest(['_order', 'state', 'valid2'], i) == true && getNest(['_order', 'state', 'amtType'], i) == 'Units') {
        var info = {
          "account_number": this.state.selectAccountNumber,
          "transaction": getNest(['_order', 'stage'], i) == 'buy' ? 'B' : 'T',
          "price": parseInt(getNest(['_order', 'tvalue'], i)),
          "quantity": getNest(['_order', 'state', 'qty'], i),
          "ticker": i.ticker,
          "instrx": "MOC",
          "timeLimit": "D",
        }
        return info;
      }
      if (getNest(['_order', 'state', 'valid2'], i) == true && getNest(['_order', 'state', 'amtType'], i) == 'Value') {
        var info = {
          "account_number": this.state.selectAccountNumber,
          "transaction": getNest(['_order', 'stage'], i) == 'buy' ? 'B' : 'T',
          "price": getNest(['_order', 'qty'], i) * (i.nav),
          "quantity": getNest(['_order', 'qty'], i),
          "ticker": i.ticker,
          "instrx": "MOC",
          "timeLimit": "D",
        }
        return info;
      }
    });

    var finalValue = CSVDataValue.filter(i => i);

    this.setState({
      downloadCSV: true,
    });

    this.setState({ tradeList: finalValue }, () => {
      // click the CSVLink component to trigger the CSV download
      this.fidelityCsvLink.current.link.click()
    })

    this.onCloseDownloadTradeList();
  }

  setAccountNumber = (e) => {
    const name = document.getElementById('account-number-box').value;
    this.setState({ selectAccountNumber: name })
  }

  selectTradeList = (item) => {
    this.setState({ tradeListValue: item });
    this.setState({ brokerDropdownValue: '' });
    this.setState({ selectAccountNumber: '' });
  }

  addToBuilder = () => {
    addEventToAnalytics('Act Builder', 'Act Builder', 'ACT_BUILDER', { category: 'advisory_PTC' }, true);
    const { setSFMItems, setBuildPortfolioState, setSFMState, buildportfolio, sfm } = this.props;
    let itemArr = [...buildportfolio.resultPageCart];
    let sfmItemArr = sfm.items;
    sfmItemArr.forEach(item => {
      if (item._builder !== true) {
        item._builder = true;
        itemArr.push(item);
      }
    });
    setSFMItems({ items: sfmItemArr });
    setBuildPortfolioState({ resultPageCart: itemArr });
    setSFMState({ showAlert: true });
  }

  render() {

    const { accountNumber } = this.state;
    const { funds, importedAccounts, items } = this.props;

    let tickerNames;
    if (items.length > 0) {
      tickerNames = items.map(x => x.ticker);
    }

    let itemsValue = this.props.items.length;
    let MFValue = this.props.items.filter(e => comingSoonValues.includes(e.type));
    const item = this.props.items.filter(e => getNest(['_order', 'stage'], e) === 'buy');

    let disabled = !this.props.activate || item.length === 0 || MFValue.length == itemsValue;
    const backHandler = () => {
      this.props.clearHandler('all', false);
      this.props.actionHandler();
    }

    var accountListNew = Object.keys(importedAccounts);

    var tradeList = ["Orion", "Not Applicable"];
    var accountList = ['Fidelity', 'Schwab', 'TD Ameritrade', 'Robinhood', 'Vanguard']

    var orionHeaders = [
      { label: "account", key: "account_number" },
      { label: "action", key: "transaction" },
      { label: "shares", key: "quantity" },
      { label: "ticker", key: "ticker" },
    ];

    var fidelityHeaders = [
      { label: "Acct", key: "account_number" },
      { label: "Action", key: "transaction" },
      { label: "Quantity", key: "quantity" },
      { label: "Symbol", key: "ticker" },
      { label: "Instrx", key: "instrx" },
      { label: "Price", key: "price" },
      { label: "Time Limit", key: "timeLimit" },
    ];

    let disabledCSV = (this.state.selectAccountNumber != '' && this.state.brokerDropdownValue == '') || this.state.selectAccountNumber == '';
    let disabledBroker = this.state.tradeListValue == '';
    let disabledAccount = (this.state.tradeListValue != '' && this.state.brokerDropdownValue == '') || (this.state.tradeListValue == '' && this.state.brokerDropdownValue == '')

    /* What is the use of extra span tag with same id="buy-tt-id" Anish ?? */

    return (
      <React.Fragment>
        <FooterActions hideBack={true} backHandler={backHandler} clearHandler={this.props.clearAllHandler} />
        <span id="buy-tt-id" style={{ margin: '0 0 0 16px' }}>
          <Button style={{ width: '135px' }} color="tools" disabled={!this.props.activate2}
            className="btn h3 text-uppercase font-weight-bold p-3 mb-0 secondary-btn"
            onClick={() => {
              this.props.trackHandler()
              if (funds) {
                addEventToAnalytics('ACT Footer Button', 'ACT Footer Button', 'ACT_FOOTER_BUTTON', { query: funds.query, logId: funds.logId, tickers: tickerNames, clickBtn: 'Track', category: 'advisory_PTC' }, true);
              }
            }}>Track</Button>
        </span>
        <span style={{ margin: '0 0 0 16px' }}>
          <Button style={{ width: '135px' }} color="tools" disabled={false}
            className="btn h3 text-uppercase font-weight-bold p-3 mb-0 secondary-btn"
            onClick={() => this.addToBuilder()}
          >+ BUILDER</Button>
        </span>
        <span id="buy-tt-id" style={{ margin: '0 0 0 16px' }}>
          <Button style={{ width: '135px' }} color="tools" disabled={!this.props.activate2}
            className="btn h3 text-uppercase font-weight-bold p-3 mb-0 ml-auto secondary-btn"
            onClick={() => {
              this.onDownloadTradeList();
              if (funds) {
                addEventToAnalytics('ACT Footer Generate Tradelist', 'ACT Footer Generate Tradelist', 'ACT_FOOTER_GENERATE_TRADELIST', { query: funds.query, logId: funds.logId, clickBtn: 'Generate Tradelist', category: 'advisory_PTC' }, true);
              }
            }}><img src='/assets/images/icons/Download-Orange.svg' alt='' /> Tradelist</Button>
        </span>
        <span id="buy-tt-id" style={{ margin: '0 4px 0 16px' }}>
          <Button color="select" disabled={disabled}
            className="btn h3 text-uppercase font-weight-bold p-3 mb-0 secondary-btn"
            onClick={() => {
              if (funds) {
                this.props.actionHandler('confirm')
                addEventToAnalytics('ACT Footer Button', 'ACT Footer Button', 'ACT_FOOTER_BUTTON', { query: funds.query, logId: funds.logId, tickers: tickerNames, clickBtn: 'Buy', category: 'advisory_PTC' }, true);
              }
            }}
            style={this.props.doRegister ? { pointerEvents: 'none', width: '135px' } : { width: '135px' }}>Buy</Button>
          {disabled && this.props.doRegister && <RegisterTooltip target={'buy-tt-id'} placement={'top'} />}
        </span>
        <span style={{ display: 'none' }}>
          <CSVLink
            data={this.state.tradeList}
            headers={orionHeaders}
            filename={"MyTradeList.csv"}
            ref={this.csvLink}
          />
          <CSVLink
            data={this.state.tradeList}
            headers={fidelityHeaders}
            filename={"MyTradeList.csv"}
            ref={this.fidelityCsvLink}
          />
        </span>

        {
          this.state.downloadTradeList &&
          <Modal
            isOpen={this.state.downloadTradeList}
            toggle={() => this.setState({ downloadTradeList: !this.state.downloadTradeList })}
            centered
            keyboard={true}
            backdrop
            className="account-broker-modal premium-msg-modal account-modal"
            backdropClassName="premium-feature-process-modal-backdrop"
          >
            <ModalBody className="ssf-modal__body" >
              <div className="premium-feature-process-modal-container">
                <div className="header-wrapper">
                  <h1 className="text-align-left">Download Tradelist</h1>
                </div>
                <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => this.onCloseDownloadTradeList()}></i>
                <div className="account-dropdown-element" style={{ paddingBottom: '18px' }}>
                  <span style={{ color: 'white' }}>To Execute Selected Enhancements In Desired Format</span><br />
                </div>
                <div className="account-dropdown-element">
                  <span style={{ color: 'white' }}>Execution Platform</span>

                  <Dropdown
                    isOpen={this.state.tradeListDropdown}
                    toggle={this.tradeListDropDownToggle}
                    className="account-dropdown-wrapper"
                  >
                    <DropdownToggle caret
                      className="dd-input-area"
                    >
                      {this.state.tradeListValue}
                    </DropdownToggle>
                    <DropdownMenu center>
                      {
                        tradeList.map((item) => {
                          return <DropdownItem value={item} onClick={() => this.selectTradeList(item)}>{item}</DropdownItem>
                        })
                      }
                    </DropdownMenu>
                  </Dropdown>
                </div>

                {/* this.state.tradeListValue && */}
                <div className="account-dropdown-element">
                  <span style={{ color: 'white' }}>Broker/Custodian</span>

                  <Dropdown
                    isOpen={this.state.accountDropdown}
                    toggle={this.accountDropdownToggle}
                    className="account-dropdown-wrapper"
                  >
                    <DropdownToggle caret
                      className="dd-input-area" disabled={disabledBroker}
                    >
                      {this.state.brokerDropdownValue}
                    </DropdownToggle>
                    <DropdownMenu center >
                      {
                        this.state.tradeListValue === 'Orion' ?
                          accountList.map((item) => {
                            return <DropdownItem value={item} onClick={() => this.getAccountName(item)}>{item}</DropdownItem>
                          })
                          :
                          accountList.map((item) => {
                            if (item === 'Fidelity') {
                              return <DropdownItem value={item} onClick={() => this.getAccountName(item)}>{item}</DropdownItem>
                            }
                          })
                      }
                    </DropdownMenu>
                  </Dropdown>
                </div>
                {/* {
                  (this.state.brokerDropdownValue != 'Select'&& this.state.tradeListValue != '') && */}
                <div>

                  {
                    accountNumber.length > 0 ?
                      <div className="account-dropdown-element">
                        <span style={{ color: 'white' }}>Account</span>
                        <Dropdown
                          isOpen={this.state.accounNoDropdown}
                          toggle={this.accountNoDropdownToggle}
                          className="account-dropdown-wrapper"
                        >
                          <DropdownToggle caret
                            className="dd-input-area" disabled={disabledAccount}
                          >
                            {this.state.selectAccountNumber}
                          </DropdownToggle>
                          <DropdownMenu center>
                            {
                              accountNumber.map((item) => {
                                return <DropdownItem value={item} onClick={() => this.getAccountNumber(item)}>{item}</DropdownItem>
                              })
                            }
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                      :
                      <div className="account-dropdown-element">
                        <span style={{ color: 'white' }}>Enter Account Number</span>
                        <input
                          id="account-number-box"
                          className="dd-input-area"
                          type="text"
                          value={this.state.selectAccountNumber}
                          onChange={this.setAccountNumber}
                          autoComplete="off"
                          disabled={disabledAccount}
                        />
                      </div>
                  }

                  {this.state.tradeListValue === 'Orion' ?
                    <div className="download-csv">

                      <ButtonGroup className="cta-wrapper justify-content-left">
                        <PrimaryButton className="btn secondary-btn" disabled={disabledCSV} type="button" onClick={this.downloadOrionTradeListExcel} style={{ maxWidth: '203px', cursor: 'pointer' }}>
                          Download Tradelist
                        </PrimaryButton>
                      </ButtonGroup>

                    </div>
                    :
                    <div className="download-csv">

                      <ButtonGroup className="cta-wrapper justify-content-left">
                        <PrimaryButton className="btn secondary-btn" disabled={disabledCSV} type="button" onClick={this.downloadFidelityTradeListExcel} style={{ maxWidth: '203px', cursor: 'pointer' }}>
                          Download Tradelist
                        </PrimaryButton>
                      </ButtonGroup>

                    </div>
                  }
                </div>
              </div>
            </ModalBody>
          </Modal>
        }

        {this.state.downloadCSV &&
          <div>
            <CustomAlert className="alert-windown-wrapper" color='info'>
              Tradelist Download Successfully.
            </CustomAlert>
          </div>
        }
      </React.Fragment>
    )
  }
}

class UnitsValue extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      view: 'Units'
    };
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  changeView(view) {
    const { funds } = this.props
    this.setState({ view });
    this.props.amtHandler(view);
    if (funds) {
      addEventToAnalytics('ACT Unit Dropdown', 'ACT Unit Dropdown', 'ACT_UNIT_DROPDOWN', { query: funds.query, logId: funds.logId, view }, true);
    }
  }

  render() {
    return (
      <div className="text-center">
        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle caret color="link" className="h6 text-gray-8 p-0 my-2 lh-100 sfmodal-unit">{this.state.view}</DropdownToggle>
          <DropdownMenu className="rounded-0 h6 py-1" style={{ minWidth: '5rem' }}>
            {['Units', 'Value'].map(v => (<DropdownItem key={v} className="py-1" onClick={() => this.changeView(v)}>{v}</DropdownItem>))}
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    )
  }
}

class AccountDropDownValue extends React.Component {
  constructor(props) {
    super(props);
    let defaultIndex;
    let accounts = this.props.accounts;
    if (accounts.length > 0) {
      accounts.forEach(function (element, index) {
        if (element.default_account == true) {
          defaultIndex = index;
        }
      });
      if (defaultIndex > 0) {
        let e = accounts[defaultIndex];
        accounts.splice(defaultIndex, 1);
        accounts.unshift(e);
      }
    }

    this.state = {
      dropdownOpen: false,
      accounts: accounts,
      view: (accounts.length > 0) ? accounts[0].account_number : '',
      isLogInRequired: (accounts.length > 0) ? (accounts[0].is_login_required === 0 ? false : true) : false,
    };
    this.props.accountHandler(this.props.broker, this.state.view);
  }

  componentWillReceiveProps(newProps) {
    const accounts = newProps.accounts;
    if (accounts.length > 0 && accounts[0].is_login_required !== this.props.accounts[0].is_login_required) {
      this.setState({ isLogInRequired: (accounts.length > 0) ? (accounts[0].is_login_required === 0 ? false : true) : false })
    }
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  changeAccount(account_number) {
    const { card, funds } = this.props;
    this.setState({ view: account_number }, () => {
      this.props.accountHandler(this.props.broker, account_number, card);
    });
    if (funds) {
      addEventToAnalytics('ACT Account Change', 'ACT Account Change', 'ACT_ACCOUNT_CHANGE', { query: funds.query, logId: funds.logId }, true);
    }
  }

  handleBrokerSelect = (broker, received_code) => {
    if (broker.name == "tdameritrade" && !received_code) {
      closeTraditWindow();
      window.location.href = 'https://auth.tdameritrade.com/oauth?client_id=LOCALTD5@AMER.OAUTHAP&response_type=code&redirect_uri=https://test.opensesafi.com/callbacks/tdameritrade/td-auth-code';
    } else if (broker.name !== 'tdameritrade') {
      this.props.setupBroker(broker, { broker: broker.name })
      const messageHandler = (message) => {
        if (['https://www.trade.it', "https://ems.tradingticket.com"].includes(message.origin)) {
          const parsedData = JSON.parse(message.data)
          const token = get(parsedData, 'oAuthVerifier', '');
          this.props.completeTradeItSetup({
            token,
            brokerName: broker.name
          })
          // this.props.fetchAccounts();
        }
      }
      window.onmessage = messageHandler
    } else {
      this.props.setupBroker(broker, received_code)
    }
  }

  render() {
    const { broker } = this.props;
    const brokerDetail = BrokersList.filter(b => b.name === BrokerNameMap(broker.name));

    return (
      <div className="text-center">
        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          {
            !this.state.isLogInRequired
            &&
            <React.Fragment>
              <DropdownToggle caret color="link" className="h6 text-gray-8 p-0 my-2 lh-100">{this.state.view}</DropdownToggle>
              <DropdownMenu className="rounded-0 h6 py-1" style={{ minWidth: '5rem' }}>
                {(this.state.accounts.length > 0) ? this.state.accounts.map(v => <DropdownItem key={v.account_number} className="py-1" onClick={() => this.changeAccount(v.account_number)}>{v.account_number}</DropdownItem>) : ''}
              </DropdownMenu>
            </React.Fragment>
          }
        </ButtonDropdown>

        {
          this.state.isLogInRequired
          &&
          <React.Fragment>
            <a className="relink-broker text-gray-8 btn-link broker-link" href="#" onClick={() => this.handleBrokerSelect(brokerDetail[0])}>Reactivate broker</a>
          </React.Fragment>
        }
      </div>
    )
  }
}

const RegisterTooltip = ({ target, placement = 'bottom' }) => {
  return (
    <UncontrolledTooltip className="w-sm" placement={placement} target={target}>
      Please register or link broker account to your Magnifi account.
    </UncontrolledTooltip>
  )
}

const RegisterTooltip2 = ({ target, placement = 'bottom' }) => {
  return (
    <UncontrolledTooltip className="w-sm" placement={placement} target={target}>
      Coming soon
    </UncontrolledTooltip>
  )
}

import React, { Component } from 'react';

import _ from 'lodash';
import { connect } from 'react-redux'
import {
  searchQueryRequest,
  setFundsState,
  refreshFundsState,
  updateFundsData,
  getChartDataForAllFunds,
  getSimilarSearchListRequest,
  dymLogs,
} from 'actions/discovery';
import { fundsApiCallTime, clearFundsApiLoadPercent } from 'actions/global';
import { activateSimilarSearchRequest } from 'actions/profile';
import { createLoadingSelector } from 'selectors/loading-selector'

import { FilterAttrData } from 'DataSet';
import TopSection from './Elements/TopSection';
import ItemList from './ItemList';

import SortFilter from './Elements/SortFilter';
import { addEventToAnalytics } from 'Utils';

class Content extends Component {
  constructor(props){
    super(props)
    this.state = {
      sortAttr: props.sortAttr,
      att: null,
    }
  }

  componentDidMount(){
    const { chartReportData } = this.props.discovery;
    if(!chartReportData || chartReportData && chartReportData.allFunds.correctedQuery !== this.props.discovery.correctedQuery){
      this.props.getChartDataForAllFunds()
    }
    let sort = window.sessionStorage.getItem('sortAttr')
    this.setState({att:sort})
  }

  sortHandler = (sortAttr) => {
    const { setFundsState, discovery, requestPayloadSort } = this.props;
    let search_type = "search_bar"; // need to change for sorting
    let query = discovery.query;
    if (requestPayloadSort) {
      if (requestPayloadSort.name === '' && requestPayloadSort.value === '') {
        setFundsState({
          requestPayloadSort: {
            name: sortAttr,
            value: 'desc',
          },
        });
      } else if (requestPayloadSort.name === sortAttr && requestPayloadSort.value === 'desc') {
        setFundsState({
          requestPayloadSort: {
            name: sortAttr,
            value: 'asc',
          },
        });
      } else if (requestPayloadSort.name === sortAttr && requestPayloadSort.value === 'asc') {
        setFundsState({
          requestPayloadSort: {
            name: '',
            value: '',
          },
        });
      } else if (sortAttr) {
        setFundsState({
          requestPayloadSort: {
            name: sortAttr,
            value: 'desc',
          },
        });
      }
    }
    this.queryHandler(query, search_type, true);
    addEventToAnalytics('Sort','Sort','SORT',{ query: discovery.query, logId: discovery.logId, selectedOption: sortAttr },true);
  }

  filterApply = (filterAttrs) => {
    const { setFundsState, discovery } = this.props;
    let search_type = "search_bar"; // need to change for filter
    let query = discovery.query;
    let attrs = {}, selectedFilters = {}, filterKeys = [], filterValues = [];
    Object.keys(filterAttrs).map((c) => attrs[c] = _.filter(filterAttrs[c], ['selected', true]));
    Object.keys(attrs).map((p) => {
      if (attrs[p] && attrs[p].length) {
        selectedFilters[p] = attrs[p];
        selectedFilters[p].map((h) => {
          let kk = FilterAttrData.filter((fI) => fI.name === p);
          if (kk && kk.length > 0) {
            filterKeys.push(kk[0].col); // api gives 'asset_class' not 'Asset Class', so convert back 'Asset Class' to 'asset_class' for api filter request
          }
          // filterKeys.push(p);
          filterValues.push(h.name);
        });
      }
    });
    setFundsState({
      requestPayloadFilters: {
        filterKeys,
        filterValues,
      },
      filterAttrs,
    });
    this.queryHandler(query, search_type, true);
    addEventToAnalytics('Filter','Filter','FILTER',{ query: discovery.query, logId: discovery.logId, selectedFilter: selectedFilters },true);
  }

  filterHandler = (filterAttrs) => {
    this.filterApply(filterAttrs);
  }

  queryHandler = (query, search_type, clearUTMSession = false) => {
    if (query) {
      const { searchQueryRequest, setFundsState, clearFundsApiLoadPercent } = this.props;
      clearFundsApiLoadPercent();
      const old_query = window.sessionStorage.getItem('query');
      if (old_query) {
        // window.sessionStorage.setItem('query', query);
        setFundsState({
          actions: true,
        });
        searchQueryRequest({
          query,
          search_type,
        });
      }
    }
  }

  render() {
    const { discovery, loading, query, correctedQuery, messages } = this.props;
    const { filterAttrData, filterAttrs, cardsCount, requestPayloadSort, data, stocks_data, sponsored_data, sortDesc, queryUUID } = discovery;
    return (
      <React.Fragment>
        {(data.length || stocks_data.length || sponsored_data.length) && (
          <div style={{ padding: '0 0 15px 15px' }}>
            <div id="topSectionContainer" className="topSectionContainer">
              <TopSection
                {...this.props}
                searchResultCount={cardsCount}
                loading={loading}
                correctedQuery={correctedQuery}
                query={query}
                messages={messages}
                queryUUID={queryUUID}
                SortFilter={<SortFilter
                  sortHandler={this.sortHandler}
                  filterHandler={this.filterHandler}
                  query={query}
                  filterAttrData={filterAttrData}
                  filterAttrs={filterAttrs}
                  recordCount={cardsCount}
                  sortAttr={_.upperFirst(requestPayloadSort.name)}
                  sortDesc={sortDesc}
                  loading={loading || data.length <= 0}
                  queryHandler={this.queryHandler}
                />}
              />
            </div>
            <div className="itemListContainer">
              <ItemList {...this.props} />
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ discovery, sfm, profile, auth, loading }) => ({
  discovery,
  loggedIn: auth.loggedIn,
  query: discovery.query,
  sortAttr: discovery.sortAttr,
  logId: discovery.logId,
  viewArray: discovery.view,
  chartData: discovery.chartData,
  messages: discovery.messages,
  fundsUniverse: discovery.universe,
  correctedQuery: discovery.correctedQuery,
  similarSearchList: discovery.similarSearches,
  showGoBackSection: discovery.showGoBackSection,
  requestPayloadSort: discovery.requestPayloadSort,
  SHOWING_ALL_STOCKS: discovery.SHOWING_ALL_STOCKS,
  profile: profile.profile,
  showUserSimilarSearches: (profile && profile.profile) ? profile.profile.show_similar_user_queries : false,
  profileLoading: createLoadingSelector(['PROFILE'])({ loading }),
})

const mapDispatchToProps = {
  dymLogs,
  searchQueryRequest,
  setFundsState,
  updateFundsData,
  refreshFundsState,
  getChartDataForAllFunds,
  fundsApiCallTime,
  clearFundsApiLoadPercent,
  activateSimilarSearchRequest,
  getSimilarSearchListRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Content)

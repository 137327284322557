import React, { Component } from 'react'
// import moment from 'moment'
import { connect } from 'react-redux'
// import * as Yup from 'yup'
// import { Formik } from 'formik'
import { withRouter } from 'react-router'
import { Row, Menu, Dropdown, Tooltip } from 'antd'
import { Form, FormGroup, Label, Input, Modal, ModalBody } from 'reactstrap'
import { DownOutlined } from '@ant-design/icons'
import { addEventToAnalytics } from 'Utils'
import VideoPlayer from 'components/VideoPlayer'
// import { CustomFormFeedback } from 'shared-components'
import ClientNameEditModal from 'components/ClientNameEditModal';

import {
  setEnhancerState,
  resetEnhancerState,
  loadCPMListRequest,
  deletePortfolioByIdRequest,
  updateClientAccountNameRequest,
} from 'actions/enhancer'
import { clientDeleteRequest } from 'actions/enhancer'
import { premiumFeatureAccessRequest } from 'actions/profile'
import { createLoadingSelector } from 'selectors/loading-selector'

import {
  // sponsoredMenus,
  // portfolioMenus,
  CustomSponsoredDropDown,
  ClientDDSearch,
  PortfolioDDSearch
} from './Helper'
import ClientUpload from './ClientUpload'
import UploadSection from './UploadSection'

import { Loader } from '../../Elements/Loader'
import PortfolioUpload from '../../../ClientPortfolio/Elements/PortfolioUpload/index'
import PfListPreview from '../../../ClientPortfolio/Elements/PfListPreview/index'
import style from './OnBoardScreen.module.scss'

class OnBoardScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      portfolioType: '',
      IMG_OBJECT: {
        'Selected Funds': '/assets/images/UsingSelectedFund-White.svg',
        'Sponsor Models': '/assets/images/SponsorProvidedModel-White.svg',
        'My Models': '/assets/images/MyPortfolios-White.svg',
        'Client Accounts': '/assets/images/client-icon.svg',
        Scratch: '/assets/images/FromScratch-White.svg',
        'Demo Portfolio': '/assets/images/DemoPortfolio-White.svg'
      },
      videoData: {
        title: 'Magnifi',
        link:
          'https://magnifi-videos.s3.us-east-2.amazonaws.com/Enhancer_01.mp4'
      },
      showClientUploadModal: false,
      uploadDemo: false,
      clientPortfolioLoader: false,
      deleteConfirm: false,
      deletePortfolioName: '',
      deleteClientAccountId: '',
      toggleDeleteClient: false,
      deleteClientAccountName: '',
      clientDDVisible: false,
      myPfDDVisible: false,
      portfolioUploadDD: 'upload',
      toggleEditClientName: false,
      // editClientAccountId: '',
      // editClientAccountName: '',
      editClientDetails: {},
    }
  }

  componentDidMount() {
    const mainContent = document.getElementById('enhancer-content-wrapper')
    if (mainContent) {
      document.body.classList.add('body-bg-white')
      mainContent.style.background = '#fff'
      mainContent.style.display = 'flex'
    }
    const { loadCPMListRequest } = this.props
    if (loadCPMListRequest && (this.props.access === 'Granted' || this.props.access === 'Test Drive')) {
      loadCPMListRequest()
    }
  }

  componentWillUnmount() {
    // debugger
    const mainContent = document.getElementById('enhancer-content-wrapper')
    if (mainContent) {
      document.body.classList.remove('body-bg-white')
      mainContent.style.background = 'transparent'
      mainContent.style.display = 'unset'
      mainContent.style.alignItems = 'unset'
    }
  }

  selectedPortfolio = portfolioData => {
    addEventToAnalytics('Enhancer UP', 'Enhancer UP', 'ENHANCER_UP', {}, false)
    // const { location, history, setEnhancerState, enhancer } = this.props
    const obj = {
      portfolio_name: portfolioData.portfolio_name,
      portfolio_id: portfolioData.id,
      status: portfolioData.status
    }
    this.routeTo('My Portfolio', 'dashboard', { data: portfolioData.id }, obj)
  }

  setMarketPlace = sponsoredData => {
    // console.log(sponsoredData);
    try {
      addEventToAnalytics('Enhancer MP', 'Enhancer MP', 'ENHANCER_MP', {}, false)
      if (sponsoredData) {
        if (sponsoredData.ticker && sponsoredData.name) {
          let obj = {
            portfolio_name: sponsoredData.ticker,
            portfolio_id: sponsoredData.ticker,
            sponsoredPortfolioName: sponsoredData.name
          }
          // console.log(obj);
          this.routeTo('MarketPlace', 'dashboard', { data: sponsoredData.name }, obj)
        } else {
          throw `-- sponsored ticker ${sponsoredData.ticker} or sponsored name ${sponsoredData.name} is missing`
        }
      } else {
        // Manually upload sponsored portfolio through CSV preview modal by editing tickers!
        const { enhancer } = this.props
        if (
          enhancer.portfolioName &&
          enhancer.portfolioName !== '' &&
          enhancer.sponsoredPortfolioName &&
          enhancer.sponsoredPortfolioName !== ''
        ) {
          let obj = {
            portfolio_name: enhancer.portfolioName,
            portfolio_id: enhancer.portfolioId,
            sponsoredPortfolioName: enhancer.sponsoredPortfolioName
          }
          // since the CSV PREVIEW modal logic came, changing route 'MarketPlace' to 'Upload Portfolio'
          this.routeTo('Upload Portfolio', 'dashboard', { data: enhancer.sponsoredPortfolioName }, obj)
        } else {
          throw `-- portfolio id ${enhancer.portfolioName} or sponsore name ${enhancer.sponsoredPortfolioName} is missing --`
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  clientSelectedPortfolio = portfolioData => {
    // console.log("=== clientSelectedPortfolio ===> ", portfolioData);
    try {
      addEventToAnalytics('Enhancer Client Portfolio', 'Enhancer Client Portfolio', 'ENHANCER_CLIENT_PORTFOLIO', {}, false)
      if (portfolioData) {
        if (portfolioData.original_portfolio_id && portfolioData.original_portfolio) {
          // Didn't made any change with selected sponsored portfolio from dropdown!
          const obj = {
            portfolio_name: portfolioData.original_portfolio,
            portfolio_id: portfolioData.original_portfolio_id
          };
          const { clientList } = this.props;
          if (clientList) {
            const clientDetails = clientList.find((item) => item.original_portfolio === portfolioData.original_portfolio);
            if (!clientDetails) {
              // NOTE: execute
              const { loadCPMListRequest } = this.props
              if (loadCPMListRequest) {
                loadCPMListRequest();
              }
            }
          }
          this.routeTo('Client Portfolio', 'dashboard', { data: portfolioData.original_portfolio_id }, obj);
        } else {
          throw `-- portfolioData original_portfolio_id ${portfolioData.original_portfolio_id} or sponsored name ${portfolioData.original_portfolio} is missing`
        }
      } else {
        // Manually upload client portfolio through CSV preview modal by editing tickers!
        const { enhancer } = this.props
        if (enhancer.portfolioName && enhancer.portfolioName !== '') {
          let obj = {
            portfolio_name: enhancer.portfolioName,
            portfolio_id: enhancer.portfolioId
            // sponsoredPortfolioName: enhancer.sponsoredPortfolioName,
          }
          // since the CSV PREVIEW modal logic came, changing route 'Client Portfolio' to 'Upload Portfolio'
          this.routeTo('Upload Portfolio', 'dashboard', {}, obj)
        } else {
          throw `-- portfolio id ${enhancer.portfolioId} or portfolio name ${enhancer.portfolioName} is missing --`
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  uploadPortfolio = () => {
    // console.log("=== uploadPortfolio ===> ", data);
    const { enhancer } = this.props
    if (enhancer.portfolioName && enhancer.portfolioName !== '') {
      let obj = {
        portfolio_name: enhancer.portfolioName,
        portfolio_id: enhancer.portfolioId
      }
      this.routeTo('Upload Portfolio', 'dashboard', { data: '' }, obj)
    }
  }

  watchlistPortfolio = () => {
    addEventToAnalytics('Enhancer Watchlist', 'Enhancer Watchlist', 'ENHANCER_WATCHLIST', {}, false)
    let obj = { portfolio_name: 'Watchlist' }
    this.routeTo('Your Watchlist', 'dashboard', { data: '' }, obj)
  }

  demoPortfolio = () => {
    const { enhancer } = this.props
    if (enhancer.portfolioName && enhancer.portfolioName !== '') {
      let obj = {
        portfolio_name: enhancer.portfolioName,
        portfolio_id: enhancer.portfolioId
      }
      this.routeTo('Demo Models', 'dashboard', { data: '' }, obj)
    }
  }

  routeTo = (selectedPortfolioType, path, payloadRequest, portfolioData) => {
    // console.log(selectedPortfolioType);
    // console.log(payloadRequest);
    // console.log(portfolioData);
    const { history, setEnhancerState } = this.props
    if (setEnhancerState && selectedPortfolioType && path) {
      const jObj = {
        portfolioType: selectedPortfolioType,
        portfolioId: portfolioData.portfolio_name
      }
      if (portfolioData && portfolioData.sponsoredPortfolioName) {
        setEnhancerState({
          portfolioName: portfolioData.portfolio_name,
          portfolioId: portfolioData.portfolio_id,
          sponsoredPortfolioName: portfolioData.sponsoredPortfolioName,
          onboardData: {
            selectedPortfolioType,
            selectedPortfolioPath: `${path}`
          }
        })
        jObj.sponsoredPortfolioName = portfolioData.sponsoredPortfolioName
      } else {
        setEnhancerState({
          portfolioName: portfolioData.portfolio_name,
          portfolioId: portfolioData.portfolio_id,
          onboardData: {
            selectedPortfolioType,
            selectedPortfolioPath: `${path}`
          }
        })
      }
      if (history) {
        let urlParam = []
        for (var i in jObj) {
          // console.log(jObj[i], encodeURI(jObj[i]), encodeURIComponent(jObj[i]));
          urlParam.push(
            encodeURIComponent(i) + '=' + encodeURIComponent(jObj[i])
          )
        }
        const newPath = `/enhancer/${path}/summary?${urlParam.join('&')}`
        // console.log(newPath);
        history.push(newPath)
      }
    }
  }

  clientUploadModalToggle = (val, uploadDemo) => {
    this.setState({
      showClientUploadModal: val,
      uploadDemo: uploadDemo && uploadDemo
    })
  }

  goToSelectedPortfolio = selectedClient => {
    console.log('=== selectedClient ===> ', selectedClient);
    this.setState({
      clientPortfolioLoader: false,
    }, () => this.clientSelectedPortfolio(selectedClient))
  }

  showLoader = () => {
    this.setState({
      clientPortfolioLoader: true
    })
  }

  videoSetState = _state => {
    addEventToAnalytics('Enhancer Video', 'Enhancer Video', 'ENHANCER_VIDEO', {}, false)
  }

  openChange = () => {
    const element = document.querySelector('.market-popup-wrapper')
    const dropdownEl = document.querySelectorAll('.ant-dropdown-menu-submenu-title')
    if (element !== null) {
      element.style.height = element.clientHeight > 0 && `${element.clientHeight}px`
    }
    if (dropdownEl) {
      dropdownEl.forEach((el, i) => {
        dropdownEl[i].title = ''
      })
      // console.log('here el', dropdownEl);
    }
  }

  toggleDeleteClientModal = (id, name) => {
    this.setState({
      toggleDeleteClient: true,
      deleteClientAccountName: name,
      deleteClientAccountId: id
    })
  }

  toggleEditClientNameModal = (id, name) => {
    addEventToAnalytics(
      'Enhancer Client Name Edit Button Clicked',
      'Enhancer Client Name Edit Button Clicked',
      'ENHANCER_CLIENT_NAME_EDIT_BUTTON_CLICKED', {
        client_id: id,
        name: name,
      },
      true,
    );
    this.setState({
      toggleEditClientName: true,
      // editClientAccountId: id,
      // editClientAccountName: name,
      editClientDetails: {
        client_id: id,
        client_name: name,
      },
    })
  }

  confirmDeleteClient = id => {
    addEventToAnalytics('Client Delete Enhancer', 'Client Delete Enhancer', 'CLIENT_DELETE_ENHANCER', {}, false)

    this.setState({
      toggleDeleteClient: !this.state.toggleDeleteClient
    })

    this.props.clientDeleteRequest({ client_ids: [id] }, res => {
      if (res && res.data.message === 'Client Deleted Successfully.') {
        // const { loadCPMListRequest } = this.props
        // if (loadCPMListRequest) {
        //   loadCPMListRequest()
        // }
      }
    })
  }

  deletePortfolio = portfolioName => {
    this.setState({ myPfDDVisible: false })
    this.setState({ deletePortfolioName: portfolioName, deleteConfirm: true })
  }

  confirmDeletePortfolio = () => {
    addEventToAnalytics('Enhancer Delete', 'Enhancer Delete', 'ENHANCER_DELETE', {}, false)
    const { deletePortfolioByIdRequest } = this.props
    if (
      deletePortfolioByIdRequest &&
      this.state.deletePortfolioName &&
      this.state.deletePortfolioName !== ''
    ) {
      deletePortfolioByIdRequest(this.state.deletePortfolioName, () =>
        this.setState({ deleteConfirm: false })
      )
    } else {
      console.log('-- PORTFOLIO ID MISSING --')
    }
  }

  myPfDDHandle = flag => {
    // if(flag){
    //   console.log('focusing')
    //   console.log(document.getElementById('pfSearch'))
    //   document.getElementById('pfSearch').focus()
    // }
    this.setState({ myPfDDVisible: flag })
  }

  clientDDHandle = flag => {
    // if(flag){
    //   console.log('focusing')
    //   document.getElementById('clientSearch').focus()
    // }
    this.setState({ clientDDVisible: flag })
  }

  handleClientDDMenuClick = item => {
    // console.log(item);
    this.setState({ clientDDVisible: false })
    if (item && item.name === 'Demo Account') {
      this.clientUploadModalToggle(true, true)
    } else {
      this.clientSelectedPortfolio(item)
    }
  }

  portfolioUploadDD = (key) => {
    if (key.domEvent) {
      key.domEvent.preventDefault();
      key.domEvent.stopPropagation();
    }
  }

  // clientNameSchema = Yup.object().shape({
  //   client_name: Yup.string().required('Client name is a required field'),
  // })

  // handleSubmit = (values, { setSubmitting }) => {
  //   const { updateClientAccountNameRequest } = this.props;
  //   try {
  //     let payloadRequest = {
  //       id: values.client_id,
  //       name: values.client_name,
  //     }
  //     // console.log(payloadRequest);
  //     if (updateClientAccountNameRequest) {
  //       updateClientAccountNameRequest('', payloadRequest, () => {
  //         this.closeEditClientNameModal();
  //       })
  //     }
  //     setSubmitting && setSubmitting(false); // To stop submit cycle---Formik
  //   } catch(e) {
  //     console.log("<----- Error in submitting Form Data ------->");
  //   }
  // }

  closeEditClientNameModal = () => {
    this.setState({
      toggleEditClientName: false,
      // editClientAccountId: '',
      // editClientAccountName: '',
      editClientDetails: {
        client_id: '',
        client_name: '',
      },
    })
  }

  render() {
    const {
      loading,
      // portfolioList,
      // marketPlaceList,
      // setMarketPlace,
      clientList,
      client_exist,
      uploadedClientList,
      showUpdateColumnInPreview,
      demoPortfolioLoader,
      updatingClientName,
      updateClientAccountNameRequest,
      // premiumUser
    } = this.props

    const {
      IMG_OBJECT,
      videoData,
      showClientUploadModal,
      uploadDemo,
      clientPortfolioLoader,
      deleteConfirm,
      deletePortfolioName,
      // portfolioUploadDD,
    } = this.state;

    const emptyDataListMenu = (
      <Menu className={style.portfolioMenuWrapper}>
        <Menu.Item key="upload">
          <UploadSection
            className=""
            mode="upload"
            fileFor="Custom_portfolio"
            onFileSuccess={item => this.uploadPortfolio(item)}
          >
            Upload Portfolio
          </UploadSection>
        </Menu.Item>
        <Menu.Item key="create">
          <UploadSection
            className=""
            mode="create"
            fileFor="Custom_portfolio"
            onFileSuccess={item => this.uploadPortfolio(item)}
          >
            Create From Scratch
          </UploadSection>
        </Menu.Item>
      </Menu>
    );

    return (
      <div
        className={`enhancer-onboard-screen-wrapper row onBoardScreenWrapper ${
          loading ? ' enhancer-onboard-screen-wrapper-loader-screen-overlay ' : ''
        }`}
      >
        {(loading || demoPortfolioLoader || clientPortfolioLoader || updatingClientName) && (
          <Loader loading />
        )}
        {!loading && (
          <>
            {showClientUploadModal && (
              <PortfolioUpload
                clientList={clientList}
                uploadDemo={uploadDemo}
                client_exist={client_exist}
                showClientUploadModal={showClientUploadModal}
                clientUploadModalToggle={this.clientUploadModalToggle}
              />
            )}
            {uploadedClientList.length > 0 && (
              <PfListPreview
                allowSelection
                clientList={clientList}
                pfList={uploadedClientList}
                showUpdateColumnInPreview={showUpdateColumnInPreview}
                showLoader={this.showLoader}
                callback={this.goToSelectedPortfolio}
                clientUploadModalToggle={this.clientUploadModalToggle}
              />
            )}
            <Row>
              <div className="col-6" style={{ paddingLeft: '0px' }}>
                <div className="page-head-wrapper">
                  <h1 className="head-label">Enhancer</h1>
                  <div className="page-description">
                    Read in existing or prospect portfolio for easy enhancements
                    using funds, home office models or sponsor models.
                  </div>
                </div>
                <div className="content_title">
                  Analyze Portfolio For Possible Enhancements
                  <div className="select-portfolio-text">Start with -</div>
                </div>
              </div>
              <div className="col-6 video-block">
                <VideoPlayer
                  key={videoData.link}
                  link={videoData.link}
                  title={videoData.title}
                  onPlayPauseState={this.videoSetState}
                  onPauseState={this.videoSetState}
                  onEndedState={this.videoSetState}
                />
              </div>
            </Row>
            <div className="onBoardScreen_contentWrapper">
              <div
                className={`content-body buttons-block`}
                style={{ marginBottom: 0 }}
              >
                <div className={`buttons-items-wrapper `}>
                  <Dropdown
                    trigger={'click'}
                    onVisibleChange={e => {
                      const dropdownEl = document.querySelectorAll('.ant-dropdown-menu-submenu-title')
                      if (dropdownEl) {
                        dropdownEl.forEach((el, i) => {
                          dropdownEl[i].title = ''
                        })
                      }
                    }}
                    className={style.ddButtons}
                    overlay={
                      <CustomSponsoredDropDown
                        openChange={this.openChange}
                        setMarketPlace={this.setMarketPlace}
                        marketPlaceList={this.props.marketPlaceList}
                      />
                    }
                    overlayClassName={style.portfolioDDContainer}
                  >
                    <div
                      id="sponsored-portfolio-dropdown"
                      className="d-flex align-items-center justify-content-between"
                    >
                      <img src={IMG_OBJECT['Sponsor Models']} height="28" />
                      <div
                        className="row d-flex align-items-center justify-content-between"
                        style={{
                          marginLeft: '10px',
                          width: '100%',
                          marginRight: '0px'
                        }}
                      >
                        <div>Sponsor Models </div>
                        <DownOutlined />
                      </div>
                    </div>
                  </Dropdown>
                  <Dropdown
                    trigger={'click'}
                    onVisibleChange={this.myPfDDHandle}
                    visible={this.state.myPfDDVisible}
                    className={style.ddButtons}
                    overlayClassName={style.portfolioDDContainer}
                    overlay={
                      <PortfolioDDSearch
                        uploadPortfolio={this.uploadPortfolio}
                        watchlistPortfolio={this.watchlistPortfolio}
                        deletePortfolio={this.deletePortfolio}
                        handleDDMenuClick={this.myPfDDHandle}
                        demoPortfolio={this.demoPortfolio}
                        portfolioList={this.props.portfolioList}
                        selectedPortfolio={this.selectedPortfolio}
                      />
                    }
                  >
                    <div
                      id={'pfSearch'}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <img src={IMG_OBJECT['My Models']} height="28" />
                      <div
                        className="row d-flex align-items-center justify-content-between"
                        style={{
                          marginLeft: '10px',
                          width: '100%',
                          marginRight: '0px'
                        }}
                      >
                        <div>My Models </div>
                        <div
                          className="d-flex align-items-center justify-content-between"
                          style={{ width: `50px` }}
                        >
                          <div onClick={e => e.stopPropagation()}>
                            <Dropdown
                              overlay={emptyDataListMenu}
                              overlayClassName={style.portfolioDDContainer}
                              trigger={['click']}
                            >
                              <Tooltip title="Upload Portfolio">
                                <i className={`far fa-fw fa-plus`} />
                              </Tooltip>
                            </Dropdown>
                          </div>
                          <DownOutlined />
                        </div>
                      </div>
                    </div>
                  </Dropdown>
                  <Dropdown
                    trigger={'click'}
                    onVisibleChange={this.clientDDHandle}
                    className={style.ddButtons}
                    visible={this.state.clientDDVisible}
                    overlayClassName={style.portfolioDDContainer}
                    overlay={
                      <ClientDDSearch
                        clientList={clientList}
                        handleDDMenuClick={this.handleClientDDMenuClick}
                        clientUploadModalToggle={this.clientUploadModalToggle}
                        clientSelectedPortfolio={this.clientSelectedPortfolio}
                        deleteClient={(id, name) => this.toggleDeleteClientModal(id, name)}
                        editClientAccountName={(id, name) => this.toggleEditClientNameModal(id, name)}
                      />
                    }
                  >
                    <div
                      id={'clientSearch'}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <img
                        width={22}
                        src={IMG_OBJECT['Client Accounts']}
                        height="28"
                      />
                      <div
                        className="row d-flex align-items-center justify-content-between"
                        style={{
                          marginLeft: '10px',
                          width: '100%',
                          marginRight: '0px'
                        }}
                      >
                        <div>Client Accounts </div>
                        <div
                          className="d-flex align-items-center justify-content-between"
                          style={{ width: `50px` }}
                        >

                          <ClientUpload
                            {...this.props}
                            fileFor="Custom_portfolio"
                            onFileUploadSuccess={this.clientSelectedPortfolio}
                            clientUploadModalToggle={this.clientUploadModalToggle}
                          >
                            <Tooltip title="Add Account ">
                              <i className={`far fa-fw fa-plus`} />
                            </Tooltip>
                          </ClientUpload>

                          <DownOutlined />
                        </div>
                      </div>
                    </div>
                  </Dropdown>
                </div>
              </div>
            </div>
          </>
        )}
        <Modal
          isOpen={deleteConfirm}
          toggle={() => this.setState({ deleteConfirm: !deleteConfirm })}
          centered
          keyboard={true}
          backdrop
          className="delete-confirm-modal premium-msg-modal account-modal"
          backdropClassName="premium-feature-process-modal-backdrop top-60px"
          // modalClassName="top-60px"
        >
          <ModalBody className="ssf-modal__body">
            <div className="premium-feature-process-modal-container">
              <i
                className="fal fa-times-circle premium-feature-completed-close"
                onClick={() => this.setState({ deleteConfirm: false })}
              ></i>
              <p className="content-wrapper">
                <div className="col-12 question">
                  Are you sure you want to delete {deletePortfolioName}{' '}
                  portfolio?
                </div>
                <div className="col-12 cta-wrapper margin-left-51">
                  <button
                    type="button"
                    className="ssf-btn-primary btn secondary-btn btn btn-secondary"
                    onClick={() => this.confirmDeletePortfolio()}
                  >
                    <span>YES</span>
                  </button>
                  <button
                    type="button"
                    className="ssf-btn-primary btn secondary-btn btn btn-secondary"
                    onClick={() => this.setState({ deleteConfirm: false })}
                  >
                    <span>NO</span>
                  </button>
                </div>
              </p>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.toggleDeleteClient}
          toggle={() =>
            this.setState({
              toggleDeleteClient: !this.state.toggleDeleteClient
            })
          }
          centered
          keyboard={true}
          backdrop
          className="delete-confirm-modal premium-msg-modal account-modal"
          backdropClassName="premium-feature-process-modal-backdrop top-60px"
          // modalClassName="top-60px"
        >
          <ModalBody className="ssf-modal__body">
            <div className="premium-feature-process-modal-container">
              <i
                className="fal fa-times-circle premium-feature-completed-close"
                onClick={() => this.setState({ toggleDeleteClient: false })}
              ></i>
              <p className="content-wrapper">
                <div className="col-12 question">
                  Are you sure you want to delete{' '}
                  {`${this.state.deleteClientAccountName}'s account?`}
                </div>
                <div className="col-12 cta-wrapper margin-left-51">
                  <button
                    type="button"
                    className="ssf-btn-primary btn secondary-btn btn btn-secondary"
                    onClick={() =>
                      this.confirmDeleteClient(this.state.deleteClientAccountId)
                    }
                  >
                    <span>YES</span>
                  </button>
                  <button
                    type="button"
                    className="ssf-btn-primary btn secondary-btn btn btn-secondary"
                    onClick={() => this.setState({ toggleDeleteClient: false })}
                  >
                    <span>NO</span>
                  </button>
                </div>
              </p>
            </div>
          </ModalBody>
        </Modal>

        <ClientNameEditModal
          data={this.state.editClientDetails}
          visible={this.state.toggleEditClientName}
          closeModalToggle={this.closeEditClientNameModal}
          updateClientAccountNameRequest={updateClientAccountNameRequest}
        />

        {/*
          <Modal
            isOpen={this.state.toggleEditClientName}
            toggle={() => this.closeEditClientNameModal()}
            centered
            keyboard={true}
            backdrop
            className="delete-confirm-modal premium-msg-modal account-modal client-name-edit-modal"
            backdropClassName="premium-feature-process-modal-backdrop top-60px"
          >
            <ModalBody className="ssf-modal__body">
              <div className="premium-feature-process-modal-container">
                <i
                  className="fal fa-times-circle premium-feature-completed-close"
                  onClick={() => this.closeEditClientNameModal()}
                ></i>
                <p className="content-wrapper">
                  <div className="col-12 question">
                    Update Client Account Name
                  </div>
                  <Formik
                    initialValues={{ client_name: this.state.editClientAccountName, client_id: this.state.editClientAccountId }}
                    onSubmit={this.handleSubmit}
                    validationSchema={this.clientNameSchema}
                    render={props => (
                      <Form
                        noValidate={true}
                        className={`col-12 client-name-edit-form-wrapper`}
                        onSubmit={props.handleSubmit}
                      >
                        <FormGroup className="element-box">
                          <Input
                            name="client_name"
                            id="client_name"
                            value={props.values.client_name}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            className={`sign-in__input-field input-area client-name-edit-input ${props.errors.client_name && props.touched.client_name ? ' is-invalid' : ''}`}
                          />
                          <CustomFormFeedback formProps={props} fieldName="client_name" />
                        </FormGroup>
                         <FormGroup className="cta-wrapper col-12 margin-left-51 p-0 m-0">
                           <button
                             disabled={props.isSubmitting || updatingClientName}
                             type="submit"
                             className="ssf-btn-primary btn btn-primary"
                           >
                             <span>UPDATE</span>
                           </button>
                           <button
                             type="button"
                             className="ssf-btn-primary btn btn-secondary"
                             onClick={() => this.closeEditClientNameModal()}
                           >
                             <span>CANCEL</span>
                           </button>
                         </FormGroup>
                      </Form>
                    )}
                  />
                </p>
              </div>
            </ModalBody>
          </Modal>
        */}
      </div>
    )
  }
}

const mapStateToProps = ({
  enhancer,
  auth,
  loading,
  sfm,
  clientportfolioV2,
  profile
}) => ({
  enhancer,
  sfmItems: sfm.items,
  marketPlaceList: enhancer.marketPlaceList,
  portfolioList: enhancer.portfolioList,
  clientList: enhancer.clientList,
  uploadedClientList: clientportfolioV2.uploadedClientList,
  showUpdateColumnInPreview: clientportfolioV2.showUpdateColumnInPreview,
  client_exist: auth.client_exist,
  premiumUser: auth.user.premiumUser,
  premiumFeatures: profile.premiumFeatures,
  activeFeatureName: profile.activeFeatureName,
  premiumNoticeModalOpen: profile.premiumNoticeModalOpen,
  access: profile.access, // 'Access' or 'Denied' or 'Test Drive'
  updatingClientName: createLoadingSelector(['UPDATE_CLIENT_ACCOUNT_NAME', 'GET_CLIENT_LIST'])({ loading }),
  loading: createLoadingSelector(['GET_SUBSCRIPTION_PLAN', 'LOAD_CPM_LIST'])({ loading }),
  demoPortfolioLoader: createLoadingSelector([
    'UPLOAD_CSV',
    'UPLOAD_CSV_FILE',
    'VERIFY_CSV_TICKER_AND_PRICE',
    'CHECK_SINGLE_TICKER_EXIST',
    'DELETE_PORTFOLIO_BY_ID',
    'UPLOAD_PORTFOLIO_DATA_BY_ID',
    'DELETE_CLIENT_LIST'
  ])({ loading })
})

const mapDispatchToProps = {
  setEnhancerState,
  resetEnhancerState,
  loadCPMListRequest,
  deletePortfolioByIdRequest,
  clientDeleteRequest,
  premiumFeatureAccessRequest,
  updateClientAccountNameRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OnBoardScreen))

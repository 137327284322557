import React from 'react';
import { connect } from 'react-redux';
import { Input } from 'antd';
import _ from 'lodash';
import { SearchOutlined } from '@ant-design/icons';
import { ScoreAttrs, AdditionalScoreAttrs, ActiveCalcScoreAttrs } from '../../DataSet';
import { setCustomCalcScoreAttrs } from 'actions/weights';

class CreateSelector extends React.Component {
	constructor(props) {
    super(props)
    this.state = {
    	allAttributes: [...ScoreAttrs, ...AdditionalScoreAttrs, ...ActiveCalcScoreAttrs],
      selectorName: '',
    	searchText: '',
    	searchResults: [],
    	selectedAttrs: [],
    	showResults: false,
    	customCalculators: this.getCustomCalculators(),
    	error: '',
      editSelector: false,
    }
    this.addAttributeWrapper = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    const { selectedCalculator, currentScoreAttrs } = this.props;
    const { allAttributes } = this.state;
    if(selectedCalculator === 'create custom selector'){
      this.props.setSelectorName('');
      this.props.setCustomCalcScoreAttrs([]);
    }else{
      const selectedAttrs = allAttributes.filter(item => item.col in currentScoreAttrs);
      this.setState({
        selectorName: selectedCalculator,
        selectedAttrs: this.getCurrentWeight(selectedAttrs),
        editSelector: true,
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  getCustomCalculators = () => {
  	let out = Object.keys(this.props.customCalculators);
  	out = out.map(item => item.toLowerCase());
  	return out;
  }

  changeSelectorName = (e) => {
  	const { customCalculators } = this.state;
  	let inputText = this.getFormattedText(e.target.value), selectorName = '', error = '';
    const defaultCalculators = ['magnifi fund selector', 'passive fund selector', 'active fund selector', 'active fund screener'];
  	const namesToBeAvoided = [...customCalculators, ...defaultCalculators];
    if(inputText === 'create custom selector'){
  		error = '*You cannot use this as a selector name. Please pick a new name.';
  	}else if(namesToBeAvoided.includes(inputText)) {
  		error = '*You already have another selector with the same name. Please pick a new name.';
  	}else{
  		selectorName = e.target.value;	
  	}
  	this.props.setSelectorName(selectorName);
  	this.setState({
      selectorName: e.target.value,
      error,
    })
  }

  getFormattedText = (text) => {
    let formattedText = text;
    if(text){
      formattedText = formattedText.toLowerCase().trim();
    }
    return formattedText;
  }

  handleClickOutside(event) {
    if (this.addAttributeWrapper && !this.addAttributeWrapper.current.contains(event.target)) {
      this.closeResults();
    }
  }

  closeResults = () => {
  	this.setState({showResults: false});
  }

  searchAttributes = (e) => {
  	this.setState({searchText: e.target.value, showResults: true});
  	if(!e.target.value){
  		this.setState({searchResults: [], showResults: false})
  	}else{
	  	const searchResults = this.runSearch(e.target.value);
	  	this.setState({searchResults})
  	}
  }

  runSearch = (attr) => {
  	const { allAttributes, selectedAttrs } = this.state;
  	const selectedArray = selectedAttrs.map(item => item.name);
  	const results = allAttributes
	  	.filter(item => {
	  		const attrName = item.name.toLowerCase();
	  		const searchText = attr.toLowerCase();
	  		return attrName.includes(searchText);
	  	})
	  	.filter(item => !selectedArray.includes(item.name));
  	return results;
  }

  selectAttribute = (attr) => {
  	const { allAttributes, selectedAttrs, searchResults } = this.state;
  	let updatedSelection = [...selectedAttrs, attr];
    if(updatedSelection.length > 12){
      this.props.setGenSelectorError('*Maximum 12 metrics allowed.');
      return;
    }
  	updatedSelection = this.addWeights(updatedSelection);
  	const updatedSearchResults = searchResults.filter(item => item.name !== attr.name);
  	this.setState({
  		searchResults: updatedSearchResults,
  		selectedAttrs: updatedSelection,
  	});
  	this.props.setScoreAttrs(updatedSelection);
  }

  deselectAttribute = (attr) => {
  	const { searchResults, selectedAttrs, searchText } = this.state;
  	let updatedSelection = selectedAttrs.filter(item => item.name !== attr.name);
    if(updatedSelection.length < 13){
      this.props.setGenSelectorError('');
    }
  	updatedSelection = this.deleteWeights(updatedSelection);
  	this.setState({
  		selectedAttrs: updatedSelection,
  	}, () => {
  		let updatedSearchResults = this.runSearch(searchText);
	  	this.setState({
	  		searchResults: updatedSearchResults,	  		
	  	})
  	});
  	this.props.setScoreAttrs(updatedSelection);	
  }

  addWeights = (selectedAttrs) => {
    let updatedSelectedAttrs = this.getCurrentWeight(selectedAttrs);
    const totalAttrs = updatedSelectedAttrs.length;
    if(totalAttrs > 1){
      updatedSelectedAttrs[totalAttrs - 1].weight = 0;
    }else{
      updatedSelectedAttrs[0].weight = 100;
    }
  	return updatedSelectedAttrs;
  }

  deleteWeights = (selectedAttrs) => {
    let updatedSelectedAttrs = this.getCurrentWeight(selectedAttrs);
    const totalAttrs = updatedSelectedAttrs.length;
    let totalAttrsWeight = updatedSelectedAttrs.reduce((prev, cur) => {
      return prev + cur.weight;
    }, 0);
    let sumOfWeights = 0, out = [];
    if(totalAttrsWeight){
      out = updatedSelectedAttrs.map((attr, index) => {
        attr.weight = Math.floor((attr.weight/totalAttrsWeight) * 100);      
        if(index === totalAttrs-1){
          attr.weight = 100 - sumOfWeights;
        }
        sumOfWeights += attr.weight;
        return attr;
      })
    }else{
      out = updatedSelectedAttrs.map((attr, index) => {
        attr.weight = Math.floor(100/totalAttrs);      
        if(index === totalAttrs-1){
          attr.weight = 100 - sumOfWeights;
        }
        sumOfWeights += attr.weight;
        return attr;
      })
    }
    return out;
  }

  getCurrentWeight = (selectedAttrs) => {
    const { currentScoreAttrs } = this.props;
    const attrs = _.clone(selectedAttrs);
    const updatedSelectedAttrs = attrs.map(item => {
      if(item.col in currentScoreAttrs){
        item.weight = currentScoreAttrs[item.col];        
      }
      return item;
    });
    return updatedSelectedAttrs;
  }

  render() {
  	const { allAttributes, searchResults, selectedAttrs, showResults, selectorName, error, editSelector } = this.state;
    return (
      <div className="create-custom-selector">
      	<div className="selector-name-wrapper">
		      <div className="selector-name">
            {editSelector ? (
              <span>
                {selectorName}
              </span> ) : (
              <>
    		      	<Input
    		      		placeholder="Name your selector"
    		      		bordered={false}
    		      		onChange={this.changeSelectorName}
                  value={selectorName}
    		      	/>
    		      	<i className="fa fa-pencil"/>
              </>
            )}
		      </div>
		      <div className="selector-error">
		      	{error}
		      </div>
	      </div>
	      <div className="add-attribute" ref={this.addAttributeWrapper}>
	      	<div className="search-input">
	      		<Input
	      			placeholder="Search for Metrics to Add"
	      			prefix={<SearchOutlined />}
	      			allowClear={true}
	      			onChange={this.searchAttributes}
	      		/>
	      	</div>
	      	{showResults && 
	      		<div className="search-results">
	      			<div className="results-content">
		      			{searchResults.length > 0 ? 
		      				searchResults.map(item => {
			      				return(
			      					<div
			      						className="result-item"
			      						onClick={() => this.selectAttribute(item)}
			      					>
			      						<span className="attr">
			      							{item.name}
			      						</span>
			      						<span>
			      							<i className="fa fa-plus"/>
			      						</span>
			      					</div>
			      				)
		      				}) :
		      				<div className="result-item">
	      						No results found
			      			</div>
		      			}
	      			</div>
	      			<hr />
	      			<div className="selected-header">
      					<span className="label">Selected</span>
      					{/*<span
      						className="allot-weight"
      						onClick={() => this.closeResults()}
      					>Allot Weight</span>*/}
      				</div>
	      			<div className="selected-attrs">
								{selectedAttrs.map(item => {
									return(
										<div
		      						className="result-item"
		      						onClick={() => this.deselectAttribute(item)}
		      					>
		      						<span className="attr">
		      							{item.name}
		      						</span>
		      						<span>
		      							<i className="fa fa-minus"/>
		      						</span>
		      					</div>
									)
								})}
	      			</div>
	      		</div>
      		}
	      </div>
	    </div>
    )
  }
}

const mapStateToProps = state => {
  return {
  	customCalculators: state.weights.customCalculators,
  }
}

const mapDispatchToProps = {
	setCustomCalcScoreAttrs,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSelector)
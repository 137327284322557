import React, { Component, Fragment } from 'react';

const NotApplicable = ({style}) => {
  return (
    <div style={style} className="notapp-status">
      <div style={{textAlign:'center'}}><img src="/assets/images/notapplicable_icon.svg" /></div>
      <div className="notapp-status__text">N.A.</div>
    </div>
  );
}

export default NotApplicable;

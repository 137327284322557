import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { logout } from 'actions/auth';
import Header from 'layouts/WebDashboard/SearchResults/Header';
import { Loader } from './shared/Loader';
import SideBar from '../AccountsPage/SideBar';
import Content from './Content';

import ErrorBoundary from 'components/shared/ErrorBoundary'

import './_index.scss'
import { investment } from 'reducers/investment';

const classNames = require('classnames');

class PfUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLogin: false,
      setCookie: false,
    }
  }

  componentDidMount() {
    // document.body.style.paddingTop = "0";
    document.body.style.paddingTop = "50"; /// to removed afterwards
    document.body.classList.remove('ht0');
    document.body.classList.add('main', 'no-scroll', 'pf-upload');
    // const headerElement = document.getElementById("headerWrapper");
    // if (headerElement) {
    //   document.body.style.paddingTop = `${headerElement.offsetHeight+'px'}`;
    // }
  }

  componentWillReceiveProps(newProps) {
    // if (newProps.loading) document.getElementById('headerWrapper').style.zIndex = 0;
    // else document.getElementById('headerWrapper').style.zIndex = 9;
  }

  componentWillUnmount() {
    document.body.classList.remove('main', 'ht5', 'no-scroll', 'pf-upload');
  }

  render() {
    const { loggingIn, loading, modalToggleState, valid } = this.props;
    // console.log('pf upload rendering.......');
    return (
      <React.Fragment>
        <Content />
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ auth, router, sfm, portfolio, investment }) => ({
  location: router.location,
  loggedIn: auth.loggedIn,
  loggingIn: auth.loggingIn,
  loading: portfolio.loading,
  valid: portfolio.valid,
  modalToggleState: sfm.modalToggleState,
})

const mapDispatchToProps = {
  logoutHandler: logout,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PfUpload)

const Waiting = () => {
  return (
    <div className="w-100">
      <div className="text-secondary text-center mt-5">
        <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5"></i>
      </div>
    </div>
  )
}

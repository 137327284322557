import React, { Component } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import { REDIRECT_DOMAIN_PATH } from 'DataSet';
import { PrimaryButton, SecondaryButton } from 'shared-components';
import { deleteAccountRequest, tokenExpiryCheck } from 'actions/profile';
import { connect } from 'react-redux';
import { createLoadingSelector } from 'selectors/loading-selector';
import { Spinner } from 'shared-components';
import { notification } from 'antd'
import { addEventToAnalytics } from 'Utils'

import './index.scss';

const magnifiLogo = "/assets/images/magnifi_by_tifin.svg";
const investResponsibly = "/assets/images/register-magnifi-invest-resp-logo.svg";

class DeleteAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: '',
        }
    }

    componentWillMount() {
        let params, q;
        if (this.props.location.search) {
            params = new URLSearchParams(this.props.location.search);
            q = params.get('token');
            if (q && q !== '') {
                this.setState({ token: q }, () => {
                    this.checkTokenExpiry();
                });
            } else { this.props.history.push('/') }
        } else { this.props.history.push('/') }
    }

    checkTokenExpiry = () => {
        this.props.tokenExpiryCheck({ token: this.state.token }, (responseData) => {
            if(responseData.success){
                if(responseData.data.message === 'Invalid Token'){
                    notification.open({
                        description: 'Link Expired.',
                        className: 'unregister_toastFail',
                        duration: 4,
                        message: null,
                        top: 84,
                    })
                    return setTimeout(() => {
                        this.props.history.push('/');
                    }, 1000);
                }
            } else {
                notification.open({
                    description: 'Link Not Valid.',
                    className: 'unregister_toastFail',
                    duration: 4,
                    message: null,
                    top: 84,
                })
                return setTimeout(() => {
                    this.props.history.push('/');
                }, 1000);
            }
        })
    }

    deleteAccount = () => {
        addEventToAnalytics(
            'Delete Account Accept',
            'Delete Account Accept',
            'DELETE_ACCOUNT_ACCEPT',
            { token: this.state.token },
            true
        )
        this.props.deleteAccountRequest({
            token: this.state.token,
        }, (responseData) => {
            if (responseData.success) {
                notification.open({
                    description: 'Account unregistered successfully.',
                    className: 'unregister_toastSuccess',
                    duration: 4,
                    message: null,
                    top: 84,
                })
            } else {
                notification.open({
                    description: 'Link not valid.',
                    className: 'unregister_toastFail',
                    duration: 4,
                    message: null,
                    top: 84,
                })
            }
            setTimeout(() => {
                this.props.history.push('/');
            }, 1000);
        });
    }

    render() {
        let { history, deleteAccountLoading, tokenExpiryCheckLoading } = this.props;
        return (
            <React.Fragment>
                <Spinner isFetching={deleteAccountLoading || tokenExpiryCheckLoading} />
                <Row className="magnifi-auth-page-wrapper delete_page_warpper">
                    <Col md="6" lg="6" className="left-side-panel-container">
                        <a className="logo-wrapper" href={REDIRECT_DOMAIN_PATH}>
                            {process.env.REACT_APP_SUB_DOMAIN === 'retail' ? <img src={investResponsibly} height={70} alt="magnifi" /> : <img src={magnifiLogo} height={70} alt="magnifi" />}
                        </a>
                    </Col>
                    <Col md="6" lg="6" className="right-side-panel-container">
                        <div className="sign-in magnifi-auth-design-container delete_auth_cotainer">
                            <div className="header_area_delete">
                                <div className="header_label">Unregister from Magnifi</div>
                            </div>
                            <div className="body-wrapper">
                                <div className="inner_desc_content">
                                    <span className="warning_content">
                                        Are you sure you don't want to reconsider?
                                    </span>
                                    <p className="warning_content">
                                        There will be no way to restore your account.
                                    </p>
                                </div>
                                <div className="cta-wrapper">
                                    <PrimaryButton
                                        type="button"
                                        className="btn primary-btn delet_btn"
                                        block
                                        onClick={() => history.push('/')}
                                    >
                                        KEEP MY ACCOUNT
                                    </PrimaryButton>
                                </div>
                                <div className="cta-wrapper">
                                    <span className="sparate"></span>
                                </div>
                                <div className="cta-wrapper">
                                    <SecondaryButton
                                        type="button"
                                        className="btn secondary-btn delet_btn"
                                        block
                                        onClick={() => this.deleteAccount()}
                                    >
                                        UNREGISTER
                                    </SecondaryButton>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ loading }) => ({
    deleteAccountLoading: createLoadingSelector(['DELETE_ACCOUNT'])({ loading }),
    tokenExpiryCheckLoading: createLoadingSelector(['TOKEN_EXPIRY_CHECK'])({ loading }),
})

const mapDispatchToProps = {
    deleteAccountRequest,
    tokenExpiryCheck
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteAccount)
import React from 'react';
import { connect } from 'react-redux'
import Spoof from './Spoof';

var classNames = require('classnames');

class SpoofSfModal extends React.Component {
  modalToggle = (modalToggle) => {
    this.props.modalToggle(modalToggle);
  }

  render() {
    return (
      <Spoof
        {...this.props}
        backHandler={this.backHandler}
        buyHandler={this.buyHandler}
        actionHandler={this.stageHandler}
        clearHandler={this.clearHandler}
        modalToggle={this.modalToggle}
        selectHandler={this.props.selectHandler}
        rangeHandler={this.rangeHandler}
        scoreAttrsHandler={this.scoreAttrsHandler}
        scoreAttrsHandler2={this.scoreAttrsHandler2}
        scoreAttrsWeightHandler={this.scoreAttrsWeightHandler}
        fetchAccounts={this.fetchAccounts}
      />
    );
  }
}

export default SpoofSfModal;

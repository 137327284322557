import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Tooltip } from 'reactstrap';
import { Row, Col, Tooltip as AntToolTip } from 'antd';

import { setUniverse, refreshFundsUniverseState } from 'actions/discovery';
import {getPremiumFeatureDetails} from 'apis/test-drive';

import { addEventToAnalytics } from 'Utils';
import Toggle from 'components/Toggle';
import PButton from 'components/PButton';

// import PremiumNoticeModal from 'components/PremiumNoticeModal';
// import PremiumFeatureDownloadModal from 'layouts/WebDashboard/SearchResults/PremiumFeatureDownloadModal';

const classNames = require('classnames');

class UniverseSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      universe: props.fundsUniverse,
      _isMoreStocksClicked : props.isMoreStocksClicked,
      _showGoBackSection : props.showGoBackSection,
      // sma: props.fundsUniverse && props.fundsUniverse.includes('sma') || false,
      // mp: props.fundsUniverse && props.fundsUniverse.includes('mp') || false,
      // cef: props.fundsUniverse && props.fundsUniverse.includes('cef') || true,
      // udc: props.fundsUniverse && props.fundsUniverse.includes('udc') || false,
      // share: props.fundsUniverse && props.fundsUniverse.includes('share') || false,
      premiumModal: false,
      notify: false,
      premiumFeature: false,
      // data: null,
      checkMsg: false,
      tooltipOpen: false,
      tooltipOpenMF: false,
      tooltipOpenMP: false,
      tooltipOpenSMA: false,
      tooltipOpenCEF: false,
      tooltipOpenShare:false,
      modalActionType: ''
    };
  }

  componentWillUnmount() {
    const { refreshFundsUniverseState } = this.props;
    if (refreshFundsUniverseState) {
      refreshFundsUniverseState();
    }
  }
  
  componentDidUpdate(prevProps){
    const {fundsUniverse,isMoreStocksClicked,showGoBackSection,showGoBackSectionClicked}=this.props;
    if(prevProps['fundsUniverse']!==fundsUniverse){
      this.setState({
        universe:fundsUniverse,
        tooltipOpenShare:false,
        _showGoBackSection:false
      })
    }
    if(prevProps['isMoreStocksClicked']!==isMoreStocksClicked){
      this.setState({
        _isMoreStocksClicked:isMoreStocksClicked,
        _showGoBackSection:showGoBackSection,
        tooltipOpenShare:false
      })
    }
    if(prevProps['showGoBackSectionClicked']!==showGoBackSectionClicked){
      this.setState({
        universe:fundsUniverse,
      })
    }
  }

  // switchHandler = (val) => {
  //   switch(val){
  //     case 'sma':
  //       this.setState({sma: !this.state.sma});
  //       break;
  //     case 'mp':
  //       this.setState({mp: !this.state.mp});
  //       break;
  //     case 'cef':
  //       this.setState({cef: !this.state.cef});
  //       break;
  //     case 'share':
  //       this.setState({share: !this.state.share});
  //       break;
  //     default:
  //       break;
  //   }
  // }

  // checkPremium = (toggleState, val) => {
  //   if(this.props.loggedIn){
  //     if(this.props.premiumUser || !toggleState){ // previous logic !document.getElementById(val).checked
  //       this.universeHandler(toggleState, val);
  //     }else{
  //       this.setState({modalActionType: `${val}_switch`})
  //       getPremiumFeatureDetails(`${val}_switch`).then(res => {
  //         this.setState({premiumFeature: res.test_drive, premiumModal: true, data: {val, checked: !val}});
  //       });
  //     }
  //   }else{
  //     this.premiumModalToggle();
  //   }
  // }

  checkPremium = (toggleState, val) => {
    this.universeHandler(toggleState, val);
    // this.setState({ data: { val, checked: !val } });
  }

  // modalAction(data){
  //   // 'data' will be ==>  { val: 'eft', checked: false }
  //   this.universeHandler(true, data.val)
  // }

  universeHandler = (toggleState, value) => {
    let { universe } = this.state;
    const val = value;
    // const controlledSwitches = ['sma', 'mp', 'cef'];
    if (toggleState) {
      // NOTE: Toggle 'ON', add 'val' in 'universe'

      // if(controlledSwitches.includes(val)){
      //   this.switchHandler(val);
      // }
      universe.push(val);
      this.setState({ universe: _.union(universe, val), checkMsg: false }, () => this.queryHandler(val))
    } else {
      // NOTE: Toggle 'OFF', remove 'val' from 'universe'

      // const oldUniverse = [...universe];
      // Previously it was ->
      // universe = universe.filter(val => !["share", "udc", "basket"].includes(val)); // to meet aleast one selected condtion
      universe = universe.filter(val => !["udc", "basket"].includes(val)); // to meet aleast one selected condtion

      if (universe.length > 1) {
        // if(controlledSwitches.includes(val)){
        //   this.switchHandler(val);
        // }
        _.remove(universe, (n) => n === val);
        this.setState({ universe, checkMsg: false }, () => this.queryHandler(val));
      } else {
        // NOTE: 'universe' is empty and 'atleast one universe need to be selected' so pushing back 'val' into 'universe'
        // and showing 'checkMsg' text

        universe.push(val);
        this.setState({
          universe: _.union(universe, val),
        });
        this.setState({ checkMsg: true })
      }
    }
    // this.setState({data: null})
    if (val === 'udc') {
      addEventToAnalytics('Using Default Criteria','Using Default Criteria','USING_DEFAULT_CRITERIA',{ [val]: toggleState });
    } else {
      addEventToAnalytics('Universe Toggle','Universe Toggle','UNIVERSE_TOGGLE',{ [val]: toggleState });
    }
  }

  queryHandler = (val,search_type) => {
    const { setUniverse, query, history, queryHandler } = this.props;
    if (setUniverse) {
      setUniverse({
        universe: this.state.universe,
        isMoreStocksClicked:false,
        showGoBackSection:false,
        showGoBackSectionClicked:false,
      });
    }
    if (history) {
      history.push('/securities');
    }
    if (queryHandler) {
      queryHandler(query, 'search_bar');
    }
  }

  openNotify = () => {
    this.setState({
      notify: true,
    });
  }

  notifyToggle = () => {
    this.setState({
      notify: !this.state.notify,
    });
  }

  closePremiumModal = () => {
    this.setState({
      premiumModal: false,
    });
  }

  premiumModalToggle = () => {
    this.setState({
      premiumModal: !this.state.premiumModal,
    });
  }

  validQuery = (query) => query.trim().length > 0

  render() {
    const { loggedIn, premiumUser } = this.props;
    const StocksTooltip= this.state._isMoreStocksClicked && (this.state.universe && this.state.universe.length===1 && this.state.universe.includes('share'));
    // {loggedIn && <PremiumNoticeModal
    //   {...this.props}
    //   open={this.state.premiumModal}
    //   premiumUser={premiumUser}
    //   onClickOK={this.confirmPrintReport}
    //   onClickCancel={this.closePremiumModal}
    //   onToggle={this.premiumModalToggle}
    //   notify={this.state.notify}
    //   openNotify={this.openNotify}
    //   notifyToggle={this.notifyToggle}
    //   premiumFeature={this.state.premiumFeature}
    //   modalAction={() => this.modalAction(this.state.data)}
    //   modalActionType={this.state.modalActionType}
    //   featureName={this.state.modalActionType}
    // />}
    // {!loggedIn && this.state.premiumModal && <PremiumFeatureDownloadModal
    //   onToggle={this.premiumModalToggle}
    //   history={this.props.history}
    // />}
    return (
      <Fragment>
        <Row className="universe-section">
          <Col xs={8} sm={12} md={12} lg={12} xl={6} className="universe-section__item">
            <div className="d-flex align-items-center">
              <Toggle
                size="small"
                style={{ marginRight: 5 }}
                checked={this.state.universe.includes('etf,etn')}
                onChange={(value) => this.universeHandler(value, 'etf,etn')}
              />
              <span className="check-label" style={{ paddingRight: 5 }} onClick={(e) => e.preventDefault()}>
                ETF
              </span>
              <AntToolTip
                title={'Exchanged Traded Fund'}
                overlayClassName="magnifi-tooltip-wrapper"
                arrowPointAtCenter={false} 
                placement={'right'}>
                <span className="check-label" style={{ paddingLeft: 5 }} id="etf">
                  <i class="fal fa-info-circle"></i>
                </span>
              </AntToolTip>
              {/* <Tooltip className="col-grey" placement="right" isOpen={this.state.tooltipOpen} target="etf" toggle={() => this.setState({ tooltipOpen: !this.state.tooltipOpen })}>
                Exchanged Traded Fund
              </Tooltip> */}
            </div>
          </Col>

          {process.env.REACT_APP_SUB_DOMAIN === 'advisor' && (
            <React.Fragment>
              <Col xs={8} sm={12} md={12} lg={12} xl={6} className="universe-section__item">
                <div className="d-flex align-items-center">
                  <PButton
                    size="small"
                    type="toggle"
                    featureName="sma_switch"
                    premiumAction={true}
                    style={{ marginRight: 5 }}
                    checked={this.state.universe.includes('sma')}
                    onChange={(value) => this.checkPremium(value, 'sma')}
                  />
                  <span className="check-label" style={{ paddingRight: 5 }} onClick={(e) => e.preventDefault()}>
                    SMA
                  </span>
                  <AntToolTip
                    title={'Separately Managed Accounts are a portfolio of securities managed by a professional investment firm'}
                    overlayClassName="magnifi-tooltip-wrapper"
                    arrowPointAtCenter={false} 
                    placement={'right'}>
                  <span className="check-label" style={{ paddingLeft: 5 }} id="smaIcon">
                    <i class="fal fa-info-circle"></i>
                  </span>
                  </AntToolTip>
                  {/* <Tooltip className="col-grey"  placement="right" isOpen={this.state.tooltipOpenSMA} target="smaIcon" toggle={() => this.setState({ tooltipOpenSMA: !this.state.tooltipOpenSMA })}>
                    Separately Managed Accounts are a portfolio of securities managed by a professional investment firm
                  </Tooltip> */}
                </div>
              </Col>

              <Col xs={8} sm={12} md={12} lg={12} xl={6} className="universe-section__item">
                <div className="d-flex align-items-center">
                  <PButton
                    size="small"
                    type="toggle"
                    featureName="mp_switch"
                    premiumAction={true}
                    style={{ marginRight: 5 }}
                    checked={this.state.universe.includes('mp')}
                    onChange={(value) => this.checkPremium(value, 'mp')}
                  />
                  <span className="check-label" style={{ paddingRight: 5 }} onClick={(e) => e.preventDefault()}>
                    Models
                  </span>
                  <AntToolTip
                    title={'Model portfolios are a crafted bundle of diversified funds, grouped together to provide an expected return with a corresponding amount of risk'}
                    overlayClassName="magnifi-tooltip-wrapper"
                    arrowPointAtCenter={false} 
                    placement={'right'}>
                  <span  className="check-label" style={{ paddingLeft: 5 }} id="mpIcon">
                    <i class="fal fa-info-circle"></i>
                  </span>
                  </AntToolTip>
                  {/* <Tooltip className="col-grey"  placement="right" isOpen={this.state.tooltipOpenMP} target="mpIcon" toggle={() => this.setState({ tooltipOpenMP: !this.state.tooltipOpenMP })}>
                    Model portfolios are a crafted bundle of diversified funds, grouped together to provide an expected return with a corresponding amount of risk
                  </Tooltip> */}
                </div>
              </Col>
            </React.Fragment>
          )}

          <Col xs={8} sm={12} md={12} lg={12} xl={6} className="universe-section__item">
            <div className="d-flex align-items-center">
              <Toggle
                size="small"
                style={{ marginRight: 5 }}
                checked={this.state.universe.includes('mf')}
                onChange={(value) => this.universeHandler(value, 'mf')}
              />
              <span className="check-label" style={{ paddingRight: 5 }} onClick={(e) => e.preventDefault()}>
                MF
              </span>
              <AntToolTip
                title={'Mutual Fund'}
                overlayClassName="magnifi-tooltip-wrapper"
                arrowPointAtCenter={false} 
                placement={'right'}>
              <span className="check-label" style={{ paddingLeft: 5 }} id="mfu">
                <i class="fal fa-info-circle"></i>
              </span>
              </AntToolTip>
              {/* <Tooltip className="col-grey"  placement="right" isOpen={this.state.tooltipOpenMF} target="mfu" toggle={() => this.setState({ tooltipOpenMF: !this.state.tooltipOpenMF })}>
                Mutual Fund
              </Tooltip> */}
            </div>
          </Col>

          <Col xs={8} sm={12} md={12} lg={12} xl={6} className="universe-section__item">
            <div className="d-flex align-items-center">
              <Toggle
                size="small"
                style={{ marginRight: 5 }}
                checked={this.state.universe.includes('cef')}
                onChange={(value) => this.universeHandler(value, 'cef')}
              />
              <span className="check-label" style={{ paddingRight: 5 }} onClick={(e) => e.preventDefault()}>
                CEF
              </span>
              <AntToolTip
                title={'Closed End Funds'}
                overlayClassName="magnifi-tooltip-wrapper"
                arrowPointAtCenter={false} 
                placement={'right'}>
              <span className="check-label" style={{ paddingLeft: 5 }} id="cefIcon">
                <i class="fal fa-info-circle"></i>
              </span>
              </AntToolTip>
              {/* <Tooltip className="col-grey"  placement="right" isOpen={this.state.tooltipOpenCEF} target="cefIcon" toggle={() => this.setState({ tooltipOpenCEF: !this.state.tooltipOpenCEF })}>
                Closed End Funds
              </Tooltip> */}
            </div>
          </Col>

          <Col xs={8} sm={12} md={12} lg={12} xl={6} className="universe-section__item">
             <div className="d-flex align-items-center">
                <Toggle
                  size="small"
                  style={{ marginRight: 5 }}
                  checked={this.state.universe.includes('share')}
                  onChange={(value) => this.universeHandler(value, 'share')}
                />
                <span className="check-label" style={{ paddingRight: 5 }} onClick={(e) => e.preventDefault()}>
                  Stocks
                </span>
                <span className='stock-new-label' id='share'>
                  NEW
                </span>
                {/* { StocksTooltip ?
                <Tooltip className="col-grey" placement="right" isOpen={!this.state.tooltipOpenShare} target="share" toggle={() => this.setState({ tooltipOpenShare: this.state.tooltipOpenShare })}>
                  Showing stock results only <i className='fa fa-times fa-md' style={{paddingLeft:'4px',cursor:'pointer'}} onClick={()=> this.setState({ tooltipOpenShare: !this.state.tooltipOpenShare })}/>
                </Tooltip>:''
                }  */}
              </div>
          </Col>
        </Row>
        {
          this.state.checkMsg && <div className="check-error">Note: At least one option should be selected.</div>
        }
      </Fragment>
    );
  }
}

const mapStateToProps = ({ auth, investment, profile, discovery }) => ({
  // data: funds.data,
  loggedIn: auth.loggedIn,
  loading: investment.loading,
  fundsUniverse: discovery.universe,
  premiumUser: profile.user && profile.user.premiumUser,
  isMoreStocksClicked:discovery.isMoreStocksClicked,
  showGoBackSection:discovery.showGoBackSection,
  showGoBackSectionClicked:discovery.showGoBackSectionClicked,
})

const mapDispatchToProps = {
  setUniverse,
  refreshFundsUniverseState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UniverseSection)

// <Row className="universe-section">
//   <Col xs={12} sm={12} md={12} lg={12} xl={6} className="universe-section__item m-0">
//     <label className="switch" ><span className="check-label" onClick={(e) => e.preventDefault()}>ETF <span id="etf"><i class="fal fa-info-circle"></i></span></span>
//       <input type="checkbox" id="etf,etn" value="etf,etn" onClick={this.universeHandler} defaultChecked={this.state.universe.includes('etf,etn')} />
//       <div class="slider round"></div>
//     </label>
//     <Tooltip className="col-grey" placement="right" isOpen={this.state.tooltipOpen} target="etf" toggle={() => this.setState({ tooltipOpen: !this.state.tooltipOpen })}>
//       Exchanged Traded Fund
//     </Tooltip>
//   </Col>
//   <Col xs={12} sm={12} md={12} lg={12} xl={6} className="universe-section__item m-0">
//     <label className="switch"><span className="check-label" onClick={(e) => e.preventDefault()}>MF <span id="mfu"><i class="fal fa-info-circle"></i></span></span>
//       <input type="checkbox" id="mf" value="mf" onClick={this.universeHandler} defaultChecked={this.state.universe.includes('mf')} />
//       <div class="slider round"></div>
//     </label>
//     <Tooltip className="col-grey"  placement="right" isOpen={this.state.tooltipOpenMF} target="mfu" toggle={() => this.setState({ tooltipOpenMF: !this.state.tooltipOpenMF })}>
//       Mutual Fund
//     </Tooltip>
//   </Col>
//
//   <Col xs={12} sm={12} md={12} lg={12} xl={6} className="universe-section__item m-0">
//     <label className="switch"><span className="check-label custom-check-label" onClick={(e) => e.preventDefault()}>SMA  <span id="smaIcon" style={{ fontSize: 10 }}><i class="fal fa-info-circle"></i></span></span>
//       <input type="checkbox" id="sma" value="sma" onClick={this.checkPremium} checked={this.state.sma} />
//       <div class="slider round"></div>
//     </label>
//     <Tooltip className="col-grey"  placement="right" isOpen={this.state.tooltipOpenSMA} target="smaIcon" toggle={() => this.setState({ tooltipOpenSMA: !this.state.tooltipOpenSMA })}>
//       Separately Managed Accounts are a portfolio of securities managed by a professional investment firm
//     </Tooltip>
//   </Col>
//   <Col xs={12} sm={12} md={12} lg={12} xl={6} className="universe-section__item m-0">
//     <label className="switch"><span className="check-label custom-check-label" onClick={(e) => e.preventDefault()}>Models  <span id="mpIcon" style={{ fontSize: 10 }}><i class="fal fa-info-circle"></i></span></span>
//       <input type="checkbox" id="mp" value="mp" onClick={this.checkPremium} checked={this.state.mp} />
//       <div class="slider round"></div>
//     </label>
//     <Tooltip className="col-grey"  placement="right" isOpen={this.state.tooltipOpenMP} target="mpIcon" toggle={() => this.setState({ tooltipOpenMP: !this.state.tooltipOpenMP })}>
//       Model portfolios are a crafted bundle of diversified funds, grouped together to provide an expected return with a corresponding amount of risk
//     </Tooltip>
//   </Col>
// </Row>

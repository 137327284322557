import React, { Component } from 'react'
import Slider from "react-slick";
import {
  Dropdown,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip,
  UncontrolledTooltip,
} from 'reactstrap';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import qs from 'query-string';
import { uniq, sortBy, difference } from 'lodash';
import { sendFeedback } from 'apis/feedback';
import SuggestForm from './SuggestForm';
import { RegionChartMapping, SectorChartMapping } from 'ChartData';
import { ViewNames } from 'DataSet';
import { formatTickerForView, addEventToAnalytics, convertToCapitalCase } from 'Utils';

const settings = {
  dots: true,
  infinite: false,
  speed: 100,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  nextArrow: <RightOutlined />,
  prevArrow: <LeftOutlined />,
  appendDots: (dots) => (
    <div>
      <ul> {dots.length > 5 ? dots.splice(0, 5) : dots} </ul>
    </div>
  ),
};

class SelectView extends React.Component {
  constructor(props) {
    super(props)
    // console.log(props);
    const defaultViewObject = props.view.length > 1 ? props.view[props.view.length - 1] : props.view[0];
    const defaultView = defaultViewObject.name;
    let selectedView = props.chartData[0].display_view_name;
    //view/default view is for view and dropdown
    // selected view will be passed to itemcard
    // console.log(defaultViewObject);
    // console.log(defaultView);
    let secondaryQ = '';
    if (defaultViewObject.secondaryView != '') {
      secondaryQ = defaultViewObject.secondaryView
      selectedView = props.chartData[0].display_view_name;
      const _found = props.chartData.filter((item) => item.display_view_name === selectedView);
      let mappingVariable = (_found && _found.length > 0 &&  _found[0].variable) ? _found[0].variable : '';
      if (mappingVariable && mappingVariable !== '') {
        props.viewHandler(selectedView, secondaryQ, mappingVariable);
      } else {
        props.viewHandler(selectedView, secondaryQ)
      }
    } else if (defaultView == 'Diversification') {
      secondaryQ = 'Holdings'
      props.viewHandler(defaultView, 'Holdings')
    } else if (defaultView == 'Return Quality') {
      secondaryQ = 'Sharpe Ratio'
      props.viewHandler(defaultView, 'Sharpe Ratio')
    } else if (defaultView.includes(' && ')) {
      selectedView = props.chartData[0].display_view_name;
      secondaryQ = props.chartData[0].Secondary_view_name;
      const _found = props.chartData.filter((item) => item.display_view_name === selectedView);
      let mappingVariable = (_found && _found.length > 0 &&  _found[0].variable) ? _found[0].variable : '';
      if (secondaryQ && secondaryQ !== '') {
        props.viewHandler(selectedView, secondaryQ, mappingVariable);
      }
    }

    this.state = {
      dropdownOpen: false,
      diversificationDropdownOpen: false,
      riskAdjustedReturnsDropdownOpen: false,
      view: defaultView,
      selectedView: selectedView,
      secondaryView: secondaryQ,
      suggestMoreIsActive: false,
      suggestMessage: '',
      isOnFinal: false,
      viewDropDownListTop: 22,
      tooltipOpen: false,
      slideNum: 0,
    }

    this.toggle = this.toggle.bind(this)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.onMouseEnterToggleDiversification = this.onMouseEnterToggleDiversification.bind(this)
    this.onMouseLeaveToggleDiversification = this.onMouseLeaveToggleDiversification.bind(this)
    this.toggleDiversificationDropdown = this.toggleDiversificationDropdown.bind(this)
    this.onMouseEnterToggleRisk = this.onMouseEnterToggleRisk.bind(this)
    this.onMouseLeaveToggleRisk = this.onMouseLeaveToggleRisk.bind(this)
    this.toggleRiskAdjustedReturnsDropdown = this.toggleRiskAdjustedReturnsDropdown.bind(this)
    this.onSuggestModalClose = this.onSuggestModalClose.bind(this)
    this.suggestClick = this.suggestClick.bind(this)
    this.onSuggestMessageChange = this.onSuggestMessageChange.bind(this)
    this.suggestSubmit = this.suggestSubmit.bind(this)
  }

  toggle() {
    const cls = document.getElementById('view-text-container')
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      viewDropDownListTop: cls.offsetHeight || 22
    })
  }

  onMouseEnter() {
    const cls = document.getElementById('view-text-container')
    this.setState({
      dropdownOpen: true,
      viewDropDownListTop: cls.offsetHeight || 22
    })
  }

  onMouseLeave() {
    const cls = document.getElementById('view-text-container')
    // console.log('leave');
    this.setState({
      dropdownOpen: false,
      viewDropDownListTop: cls.offsetHeight || 22
    })
  }

  toggleDiversificationDropdown() {
    this.setState({
      diversificationDropdownOpen: !this.state.diversificationDropdownOpen
    })
  }

  onMouseEnterToggleDiversification() {
    this.setState({
      diversificationDropdownOpen: true
    })
  }

  onMouseLeaveToggleDiversification() {
    this.setState({
      diversificationDropdownOpen: false
    })
  }

  toggleRiskAdjustedReturnsDropdown() {
    this.setState({
      riskAdjustedReturnsDropdownOpen: !this.state
      .riskAdjustedReturnsDropdownOpen
    })
  }

  onMouseEnterToggleRisk() {
    this.setState({
      riskAdjustedReturnsDropdownOpen: true
    })
  }

  onMouseLeaveToggleRisk() {
    this.setState({
      riskAdjustedReturnsDropdownOpen: false
    })
  }

  changeView(view, secondaryView, selectedView) {
    // console.log(view, secondaryView, selectedView);
    const { discovery, view: _originalPropView } = this.props;
    if (!selectedView) {
      if (view.includes(' && ')) {
        selectedView = this.props.chartData[0].display_view_name
        secondaryView = secondaryView ? secondaryView : this.props.chartData[0].Secondary_view_name
      } else selectedView = view
    }
    let mappingVariable = '';
    const _found = this.props.chartData.filter((item) => item.display_view_name === selectedView);
    mappingVariable = (_found && _found.length > 0 &&  _found[0].variable) ? _found[0].variable : '';
    // if (view.includes(' && ')) {
    //   const _found = this.props.chartData.filter((item) => item.display_view_name === selectedView);
    //   mappingVariable = (_found && _found.length > 0 &&  _found[0].variable) ? _found[0].variable : '';
    // }
    // console.log(view, secondaryView, selectedView);
    this.setState({ view, secondaryView, selectedView })
    this.props.viewHandler(selectedView, secondaryView, mappingVariable)

    // console.log(_found);

    if (_found && _found.length > 0 && _found[0].display_view_name) {
      // console.log(_found[0].display_view_name);
      // console.log(_originalPropView);
      const SliderElem = _originalPropView.find((k) => k.name.includes(' && '));
      // console.log(SliderElem);
      if (SliderElem) {
        const listForSliderElem = SliderElem.name.split(' && ');
        // console.log(listForSliderElem);
        const slideNum = (listForSliderElem.findIndex(x => x === _found[0].display_view_name));
        // console.log('slideNum =>', slideNum);
        this.setState({ slideNum });
        if (slideNum > -1 && this.slider) {
          setTimeout(() => {
            this.slider.slickGoTo(slideNum)
          }, 200)
        }
      }
    }

    addEventToAnalytics('View Chart','View Chart','View Chart',{ query: discovery.query, logId: discovery.logId, viewName: view },true);
  }

  suggestClick() {
    this.setState({
      suggestMoreIsActive: true
    })
  }
  onSuggestModalClose() {
    this.setState({
      suggestMoreIsActive: false
    })
  }

  onSuggestMessageChange(newMessage) {
    this.setState({ suggestMessage: newMessage.target.value })
  }

  suggestSubmit(event) {
    event.preventDefault()
    if (this.state.suggestMessage.length === 0) {
      return
    }

    // console.log(
    //   sendFeedback(
    //     qs.stringify({ feedback: 'dataItem: ' + this.state.suggestMessage })
    //   )
    // )

    this.setState({ isOnFinal: true }, () => {
      setTimeout(() => {
        this.toggle(false)
        setTimeout(() => {
          this.setState({ isOnFinal: false, suggestMoreIsActive: false })
        })
      }, 2000)
    })

    //this.setState({ suggestMoreIsActive: false, suggestMessage: '' })
  }

  getDropDownViewName = name => {
    // const { isSectorDataFoundInFunds } = this.props;
    let split_name = name.split(' && ')
    split_name.forEach((item, index) => {
      const currentChartData = this.props.chartData.filter(
        e => e.view_name == item
      )[0]
      let toBeDisplayedView
      if (currentChartData) {
        // if (item == 'Regions' && currentChartData.data) {
        //   let displayText = RegionChartMapping.filter(
        //     e => e.chart_name === currentChartData.node
        //   )
        //   if (displayText.length) {
        //     toBeDisplayedView = '%\u00a0' + displayText[0].region_name
        //     split_name[index] = toBeDisplayedView
        //     // return name;
        //   } else split_name[index] = item
        // } else if (item == 'Sectors' && currentChartData.data) {
        //   let find = SectorChartMapping.filter(
        //     e => e.chart_name === currentChartData.node
        //   )
        //   if (find.length > 0) {
        //     toBeDisplayedView = '%\u00a0' + find[0].sector_name
        //     split_name[index] = toBeDisplayedView
        //   } else split_name[index] = item
        // } else if (
        //   item == 'Top Holdings' &&
        //   currentChartData.data &&
        //   (currentChartData.variable.includes('holdings_') ||
        //   currentChartData.variable.include('neg_holdings_'))
        // ) {
        //   let ticker = ''
        //   if (
        //     Object.prototype.toString.call(currentChartData.data) ===
        //     '[object Array]'
        //   ) {
        //     ticker = currentChartData.data[0]
        //   } else if (typeof currentChartData.data === 'string') {
        //     ticker = currentChartData.data
        //   }
        //   if (ticker.length > 4) ticker = formatTickerForView(ticker)
        //   toBeDisplayedView = '%\u00a0' + ticker
        //   split_name[index] = toBeDisplayedView
        //   // return name;
        // }
        // // else if (
        // //   item == 'Asset Allocation' &&
        // //    currentChartData.data &&
        // //    typeof currentChartData.category !== 'undefined' &&
        // //    currentChartData.category === 'asset_type'
        // //  ) {
        // //
        // //   if (currentChartData.node) {
        // //     const chartDataNode = currentChartData.node === 'equity' ? 'equities' : currentChartData.node;
        // //     split_name[index] = `% ${convertToCapitalCase(chartDataNode)}`;
        // //   } else split_name[index] = item
        // //
        // // } else split_name[index] = item
        // else
        // {
          split_name[index] = item // remove this line if you uncomment above lines
        // }
      } else {
        split_name[index] = item
      }
    })
    if (split_name.length > 1) {
      // return split_name[0] + ' && ' + split_name[1]
      return 'Relevant Charts'
    } else {
      return split_name[0]
    }
  }

  callToChangeView = (item, secondaryView, viewName, selectedView) => {
    // this.setState({selectedView: selectedView, secondaryView: secondaryView})
    this.changeView(viewName, secondaryView || '', selectedView)
  }

  sliderChange = (index) => {
    const { chartData } = this.props;
    const item = chartData[index];
    if (item) {
      this.callToChangeView(
        item,
        item.Secondary_view_name,
        this.state.view,
        item.display_view_name
      )
    }
  }

  renderViewTextDesign = view => {
    let { chartData, view: relevantView } = this.props;
    // console.log(this.state.view);
    // console.log(chartData);
    let found = chartData.find((k) => k.view_name === this.state.view && k.tooltip && k.tooltip !== '');
    let renderTooltip;
    if (found) {
      const toolTipId = (found.table_name && found.table_name !== '' && typeof found.table_name !== 'undefined') ? found.table_name : found.node;
      renderTooltip = (
        <span style={{ paddingLeft: 2 }}>
          <i id={toolTipId} class="fal fa-info-circle"></i>
          {(document.getElementById(toolTipId)) && (
            <UncontrolledTooltip
              className="share-component__tooltip view-name-tooltip"
              arrowClassName="view-name-tooltip-arrow"
              placement="bottom"
              target={toolTipId}
              isOpen={this.state.tooltipOpen}
              toggle={() => this.setState({ tooltipOpen: !this.state.tooltipOpen })}
            >
              {found.tooltip}
            </UncontrolledTooltip>
          )}
        </span>
      );
    }

    let renderViewTextDesign = (
      <div className="view-text-label text-blue selected">
        {this.getDropDownViewName(this.state.view)} {renderTooltip}
      </div>
    )

    settings.initialSlide = this.state.slideNum;

    // console.log(this.state.selectedView);
    // console.log(this.state.view);
    // console.log(this.getDropDownViewName(this.state.view));
    // console.log(relevantView, relevantView.find((k) => k.name === this.getDropDownViewName(this.state.view)));

    if (this.state.view.includes(' && ') || relevantView.find((k) => k.name === this.getDropDownViewName(this.state.view))) {
      renderViewTextDesign = (
        <Slider ref={slider => (this.slider = slider)} {...settings} afterChange={(e) => this.sliderChange(e)}>
          {
            chartData.map((item, index) => {
              const toolTipId = (item.table_name && item.table_name !== '' && typeof item.table_name !== 'undefined') ? item.table_name : item.node;
              return (
                <div
                  key={`chart-type-${item.view_name}`}
                  className={`view-text-label text-blue ${
                    item.display_view_name === this.state.selectedView
                    ? 'selected'
                    : ''
                  }`}
                  style={{ width: 130 }}
                  onClick={() =>
                    this.callToChangeView(
                      item,
                      item.Secondary_view_name,
                      this.state.view,
                      item.display_view_name
                    )
                  }
                >
                  {item.display_view_name
                    ? this.getDropDownViewName(item.display_view_name)
                    : this.getDropDownViewName(item.view_name)
                  }
                  {(item.tooltip && item.tooltip !== '' && toolTipId)
                    && (
                      <span style={{ paddingLeft: 2 }}>
                        <i id={"info-icon"+toolTipId} class="fal fa-info-circle"></i>
                        <UncontrolledTooltip className="share-component__tooltip view-name-tooltip" placement="bottom" target={"info-icon"+toolTipId} >
                          {item.tooltip}
                        </UncontrolledTooltip>
                      </span>
                    )
                  }
                </div>
              )
            })
          }
        </Slider>
      )
    }

    return (
      <div id="view-text-container" className="view-text-container">
        {renderViewTextDesign}
      </div>
    )
  }

  renderSubTitle = viewName => {
    const selectedView = this.state.selectedView
    let renderSubTitle = null;
    if (selectedView.includes('Performance vs ')) {
      renderSubTitle = (
        <h6 className="h6 mb-0 text-gray-6">
          <span>(3 year cumulative return)</span>
        </h6>
      )
    } else if (['Risk', 'Trend'].includes(selectedView)) {
      renderSubTitle = (
        <h6 className="h6 mb-0 text-gray-6">(Under Development)</h6>
      )
    } else {
      switch (selectedView) {
        case 'Assets':
        renderSubTitle = (
          <h6 className="h6 mb-0 text-gray-6">
            <span>Assets Managed vs Category</span>
          </h6>
        )
        break
        case 'Volume':
        renderSubTitle = (
          <h6 className="h6 mb-0 text-gray-6">
            <span>Volume vs Category</span>
          </h6>
        )
        break
        case 'Market Cap':
        renderSubTitle = (
          <h6 className="h6 mb-0 text-gray-6">
            <span>Market Cap vs Category</span>
          </h6>
        )
        break
        case 'Fiduciary Risk':
        renderSubTitle = (
          <h6 className="h6 mb-0 text-gray-6">
            <span>Powered by Fi360</span>
          </h6>
        )
        break
        case 'Fiduciary Score':
        renderSubTitle = (
          <h6 className="h6 mb-0 text-gray-6">
            <span>100-Fi360 Fiduciary Risk Score</span>
          </h6>
        )
        break
        case 'Diversification':
        if (this.state.secondaryView) {
          renderSubTitle = (
            <h6 className="h6 mb-0 wid-100">
              <Dropdown
                isOpen={this.state.diversificationDropdownOpen}
                toggle={this.toggleDiversificationDropdown}
                onMouseOver={this.onMouseEnterToggleDiversification}
                onMouseLeave={this.onMouseLeaveToggleDiversification}
                >
                <DropdownToggle
                  caret
                  color="link"
                  className="h6 text-blue mb-0 p-0 wid-100"
                  >
                  {this.state.secondaryView}
                </DropdownToggle>
                <DropdownMenu
                  className="rounded-0 h6 wid-100 text-center"
                  style={{ margin: 0 }}
                  >
                  <DropdownItem
                    key={'Holdings'}
                    className="py-1"
                    onClick={() =>
                      this.changeView(viewName, 'Holdings', selectedView)
                    }
                    >
                    {'Holdings'}
                  </DropdownItem>
                  <DropdownItem
                    key={'Region'}
                    className="py-1"
                    onClick={() =>
                      this.changeView(viewName, 'Region', selectedView)
                    }
                    >
                    {'Region'}
                  </DropdownItem>
                  <DropdownItem
                    key={'Sector'}
                    className="py-1"
                    onClick={() =>
                      this.changeView(viewName, 'Sector', selectedView)
                    }
                    >
                    {'Sector'}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </h6>
          )
        } else {
          renderSubTitle = null
        }
        break
        case 'Return Quality':
        if (this.state.secondaryView) {
          renderSubTitle = (
            <h6 className="h6 mb-0 wid-100">
              <Dropdown
                isOpen={this.state.riskAdjustedReturnsDropdownOpen}
                toggle={this.toggleRiskAdjustedReturnsDropdown}
                onMouseOver={this.onMouseEnterToggleRisk}
                onMouseLeave={this.onMouseLeaveToggleRisk}
                >
                <DropdownToggle
                  caret
                  color="link"
                  className="h6 text-blue mb-0 p-0 wid-100"
                  >
                  {this.state.secondaryView}
                </DropdownToggle>
                <DropdownMenu
                  className="rounded-0 h6 wid-100 text-center"
                  style={{ margin: 0 }}
                  >
                  <DropdownItem
                    key={'Sharpe Ratio'}
                    className="py-1"
                    onClick={() =>
                      this.changeView(viewName, 'Sharpe Ratio', selectedView)
                    }
                    >
                    {'Sharpe Ratio'}
                  </DropdownItem>
                  <DropdownItem
                    key={'Sortino Ratio'}
                    className="py-1"
                    onClick={() =>
                      this.changeView(viewName, 'Sortino Ratio', selectedView)
                    }
                    >
                    {'Sortino Ratio'}
                  </DropdownItem>
                  <DropdownItem
                    key={'Tracking Error'}
                    className="py-1"
                    onClick={() =>
                      this.changeView(
                        viewName,
                        'Tracking Error',
                        selectedView
                      )
                    }
                    >
                    {'Tracking Error'}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </h6>
          )
        } else {
          renderSubTitle = null
        }
        break
        default:
        renderSubTitle = null
      }
    }

    // const viewTextCarouselElem = document.getElementById("view-text-container");
    // if (viewTextCarouselElem) {
    //   const foundDotsElem = viewTextCarouselElem.getElementsByClassName('slick-dots');
    //   // console.log(foundDotsElem);
    //   if (foundDotsElem && foundDotsElem.length > 0) {
    //     let bottomPx = (viewTextCarouselElem.offsetHeight+5) +"px";
    //     // console.log(renderSubTitle);
    //     if (renderSubTitle === null) {
    //       foundDotsElem[0].style.top = `${bottomPx}`;
    //       foundDotsElem[0].style.bottom = ''
    //     } else {
    //       foundDotsElem[0].style.top = ``;
    //       foundDotsElem[0].style.bottom = '-33px';
    //     }
    //   }
    // }

    return renderSubTitle
  }

  render() {
    const { view: relevantView, chartData } = this.props;
    const { view, selectedView, viewDropDownListTop } = this.state;

    let staticViewName = ViewNames.map(item => this.getDropDownViewName(item.name));
    // console.log('staticViewName', staticViewName);

    let dynamicViewName = sortBy(relevantView.filter((item) => !staticViewName.includes(item.name)).map(item => item.name));
    // console.log(relevantView.map((item) => item.name));
    // console.log('dynamicViewName', dynamicViewName);

    let _viewNameCollections = uniq(
      ViewNames.map(item => item.name).concat(
        relevantView.map(item => item.name)
      )
    );

    // console.log(relevantView);
    // console.log(_viewNameCollections);

    let dropDownViewName = uniq(_viewNameCollections.map((k) => {
      if (!k.includes(' && ')) {
        return this.getDropDownViewName(k)
      } else {
        return k
      }
    }));
    // console.log(dropDownViewName);
    const _staticViewNamesWillbe = dropDownViewName.filter((item) => ViewNames.find((k) => k.name === item));
    // console.log(_staticViewNamesWillbe);
    const _dynamicViewNameWillbe = difference(dropDownViewName.filter((k) => !k.includes(' && ')), _staticViewNamesWillbe); // dropDownViewName.filter((item) => _staticViewNamesWillbe.find((k) => k !== item))
    // console.log(_dynamicViewNameWillbe);

    // const _finalViewNameList = _dynamicViewNameWillbe.concat(ViewNames.map((k) => k.name));
    // console.log(_finalViewNameList);

    const _finalViewNameList = ViewNames.map((k) => k.name);
    // console.log(_finalViewNameList);

    const styleObj = {
      top: viewDropDownListTop,
      alignItems: 'center'
    }

    // console.log(view);
    let renderViewTextDesign = this.renderViewTextDesign(view)
    // console.log(renderViewTextDesign);
    let renderSubTitle = this.renderSubTitle(view)

    // {dropDownViewName.map(v => (
    //   <DropdownItem
    //     key={v}
    //     className="py-1"
    //     onClick={() => {
    //       if (v == 'Diversification') {
    //         this.changeView(v, 'Holdings')
    //       } else if (v == 'Return Quality') {
    //         this.changeView(v, 'Sharpe Ratio')
    //       } else this.changeView(v, '')
    //     }}
    //     >
    //     {this.getDropDownViewName(v)}
    //   </DropdownItem>
    // ))}

    return (
      <div id="viewDropdownContainer" className="viewDropdownContainer">
        <Dropdown
          className="viewDropdownWrapper"
          isOpen={this.state.dropdownOpen}
          toggle={this.toggle}
          // onMouseOver={this.onMouseEnter}
          // onMouseLeave={this.onMouseLeave}
          >
          <DropdownToggle
            caret
            color="link"
            className="h6 text-blue my-1 viewBtn"
            >
            View
          </DropdownToggle>
          <DropdownMenu
            className="rounded-0 h6 viewDropDownList"
            style={{ marginTop: `${viewDropDownListTop}px` }}
            >
            {/*
              <div
                className="ghost-child"
                style={{
                  height: `${viewDropDownListTop + 8}px`,
                  top: `-${viewDropDownListTop + 3}px`
                }}
              />
            */}
            <div className="viewDropDownList-Child">
              {(_dynamicViewNameWillbe && _dynamicViewNameWillbe.length > 0) && (
                <div className="dynamic-chart-wrap">
                  {_dynamicViewNameWillbe.map(v => (
                    <DropdownItem
                      key={v}
                      className="py-1"
                      onClick={() => {
                        if (v == 'Diversification') {
                          this.changeView(v, 'Holdings')
                        } else if (v == 'Return Quality') {
                          this.changeView(v, 'Sharpe Ratio')
                        } else this.changeView(v, '')
                      }}
                      >
                      {this.getDropDownViewName(v)}
                    </DropdownItem>
                  ))}
                </div>
              )}
              <div className="static-chart-list-wrap">
                {_finalViewNameList.map(v => (
                  <DropdownItem
                    key={v}
                    className="py-1"
                    onClick={() => {
                      if (v == 'Diversification') {
                        this.changeView(v, 'Holdings')
                      } else if (v == 'Return Quality') {
                        this.changeView(v, 'Sharpe Ratio')
                      } else this.changeView(v, '')
                    }}
                    >
                    {v}
                  </DropdownItem>
                ))}
              </div>
              <DropdownItem
                key="Suggest more"
                className="py-1"
                onClick={() => this.suggestClick()}
                >
                Suggest more?
              </DropdownItem>
            </div>
          </DropdownMenu>
        </Dropdown>
        {renderViewTextDesign}
        <div id="view-sub-title-container" className="view-sub-title-container">{renderSubTitle}</div>
        {this.state.suggestMoreIsActive && (
          <SuggestForm
            isOnFinal={this.state.isOnFinal}
            isOpen={this.state.suggestMoreIsActive}
            onClose={this.onSuggestModalClose}
            onMessageChange={this.onSuggestMessageChange}
            message={this.suggestMessage}
            onSubmit={this.suggestSubmit}
            />
        )}
      </div>
    )
  }
}

  export default SelectView

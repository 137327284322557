import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { sendFailureQuery } from 'apis/funds';
import { searchQueryRequest, setFundsState  } from 'actions/discovery';
import { fundsApiCallTime, clearFundsApiLoadPercent } from 'actions/global';
import { FailureQuery, REDIRECT_DOMAIN_PATH } from '../../../../DataSet';
import OtherTextSubmitModal from './OtherTextSubmitModal'
import { searchUrlV3 } from 'Utils';

class NoResult extends Component {
  constructor(props){
    super(props);
    this.state = {
      errorMsg: false,
      isOtherTextModalOpen: false
    }
  }

  // checkRoutes = () => {
  //   const { isTermsAgreed, loggedIn } = this.props;
  //   const query = window.sessionStorage.getItem('query');
  //   if (loggedIn && isTermsAgreed === 0 && query) return;
  //   this.props.history.push('/securities?query='+query);
  // }

  checkRoutes = (query) => {
    const { history } = this.props;
    if (history) {
      history.push(searchUrlV3(query));
    }
  }

  searchQueryRequest = (querySearched) => {
    const { query, searchQueryRequest, setFundsState, clearFundsApiLoadPercent } = this.props;
    sendFailureQuery({ query, selection_text: querySearched });
    // If condition for tab and mobile version
    if(this.props.fetchFunds) {
      this.props.fetchFunds({ query: FailureQuery[querySearched] })
    } else {
      clearFundsApiLoadPercent();
      // window.sessionStorage.setItem('query', FailureQuery[querySearched]);
      setFundsState({
        actions: true,
        loading: true,
      });
      this.checkRoutes(FailureQuery[querySearched]);
      searchQueryRequest({
        query: FailureQuery[querySearched],
      });
    }
  }

  otherQuerySearch = (e) => {
    e.preventDefault();
    const { query } = this.props;
    const val = document.getElementById('other').value;
    if (val !== '' && val.replace(/\s/g, '').length != 0) {
      this.setState({ errorMsg: false }, () => {
        window.sessionStorage.setItem('query', val);
        // sendFailureQuery({ query, selection_text: val }, this.querySuccess);
        sendFailureQuery({ query, selection_text: val }, () => {
          this.setState({
            isOtherTextModalOpen: true
          });
        });
        // // If condition for tab and mobile version
        // if(this.props.fetchFunds) {
        //   this.props.fetchFunds({ query: val })
        // } else {
        //   // clearFundsApiLoadPercent();
        //   // window.sessionStorage.setItem('query', val);
        //   // setFundsState({
        //   //   actions: true,
        //   // });
        //   // this.checkRoutes();
        //   // searchQueryRequest({
        //   //   query: val,
        //   // });
        // }
      })
    } else {
      this.setState({ errorMsg: true })
      return false;
    }
  }

  querySuccess = () => {
    window.location.href = REDIRECT_DOMAIN_PATH;
  }

  onModalToggle = () => {
    this.setState({
      isOtherTextModalOpen: false
    });
    this.querySuccess();
  }

  render () {
    const { other, errorMsg, isOtherTextModalOpen } = this.state;

    return (
      <div className="w-100 no-result-wrapper">
        <div className="text-secondary text-center">
          <div className="mg-btm-no-result"><img src="assets/images/no-result.png" /></div>
          <p className="font-weight-bold h3 col-grey ">Hmmm, that query is new to me.</p>
          <p className="col-grey sub-head">Can you help identify what you are looking for? A specific...</p>
          <div className="specific-query col-grey">
            <ul>
              <li><span className="dot-no-result">&#x25cf;</span> <div className="query-name" onClick={this.searchQueryRequest.bind(this, 'company')}>Company</div>
                <span className="eg-query">e.g. IBM</span>
              </li>
              <li><span className="dot-no-result">&#x25cf;</span> <div className="query-name" onClick={this.searchQueryRequest.bind(this, 'region')}>Region</div>
                <span className="eg-query">e.g. Emerging Markets</span>
              </li>
              <li><span className="dot-no-result">&#x25cf;</span> <div className="query-name" onClick={this.searchQueryRequest.bind(this, 'sector')}>Sector</div>
                <span className="eg-query">e.g. Utilities</span>
              </li>
              <li><span className="dot-no-result">&#x25cf;</span> <div className="query-name" onClick={this.searchQueryRequest.bind(this, 'theme')}>Theme</div>
                <span className="eg-query">e.g. Fintech</span>
              </li>
            </ul>
            <ul>
              <li><span className="dot-no-result">&#x25cf;</span> <div className="query-name" onClick={this.searchQueryRequest.bind(this, 'objective')}>Objective</div>
                <span className="eg-query">e.g. Retirement</span>
              </li>
              <li><span className="dot-no-result">&#x25cf;</span> <div className="query-name" onClick={this.searchQueryRequest.bind(this, 'fund')}>Fund</div>
                <span className="eg-query">e.g. S&P 500 ETF</span>
              </li>
              <li><span className="dot-no-result">&#x25cf;</span> <div className="query-name" onClick={this.searchQueryRequest.bind(this, 'assetType')}>Asset Type</div>
                <span className="eg-query">e.g. Equities</span>
              </li>
              <li><span className="dot-no-result">&#x25cf;</span> <div className="query-name" onClick={this.otherQuerySearch.bind(this)}>Other</div>
                <div className="eg-query other">
                    <input type="text" id="other"  placeholder="Enter here"  />
                    {errorMsg && <span className="other-error">Enter a query</span>}
                    <button type="button" className="no-query-btn" onClick={this.otherQuerySearch.bind(this)}><i class="far fa-chevron-right"></i></button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {isOtherTextModalOpen && <OtherTextSubmitModal onModalToggle={() => this.onModalToggle()}/>}
      </div>
    )
  }
}

const mapStateToProps = ({ discovery, router }) => ({
  query: discovery.query,
  loading: discovery.actions,
  fundsLoader: discovery.loading,
  location: router.location,
})

const mapDispatchToProps = {
  searchQueryRequest,
  setFundsState,
  fundsApiCallTime,
  clearFundsApiLoadPercent,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NoResult))

import ActionTypes from '../action-types';

const initialState = {
  relatedInsights: [],
}

export function plugins(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case ActionTypes.FETCH_RELATED_INSIGHTS_DATA_FETCH:
      return {
        ...state,
        ...payload,
      }
    case ActionTypes.FETCH_RELATED_INSIGHTS_DATA_SUCCESS:
      return {
        ...state,
        ...payload,
      }
    case ActionTypes.CLEAR_PLUGINS:
      return {
        relatedInsights: [],
      }

    default:
      return state
  }
}

import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Row, Col, Space } from 'antd';
// import moment from 'moment';
import { addEventToAnalytics } from 'Utils';
import { PLAN_NAME_MAPPING } from 'DataSet';

const SubscriptionCancelModal = (props) => {
  const [selectedFeatures, selectFeature] = useState([]);
  const iconStyles = {
    position: 'absolute',
    right: '15px',
    top: '10px',
    fontSize: '20px',
    cursor: 'pointer',
  };

  const onChange = (checkedValues) => {
    selectFeature(checkedValues)
  }
  const activatedFeatures = props.activatedFeatures || [];
  const notIncludingDiscover = activatedFeatures.filter(function (e) { return e !== 'discovery' })
  //const subscriptionEndDate = props.subscription.renews_on == '' ? props.profile.user.plan_end_date : props.subscription.renews_on
  const confirmCancellation = () => {
    const userDetails = (props.profile && props.profile.user && props.profile.user.email) ? {
      email: props.profile.user.email,
      selectedFeatures,
    } : {};
    addEventToAnalytics(
      'Confirm Cancel Subscription',
      'Confirm Cancel Subscription',
      'CONFIRM_CANCEL_SUBSCRIPTION', userDetails, false);
    props.confirmCancellation({ selectedFeatures });
  }

  const declineCancellation = () => {
    // console.log('User declines Cancellation');
    const userDetails = (props.profile && props.profile.user && props.profile.user.email) ? {
      email: props.profile.user.email,
      selectedFeatures,
    } : {};
    addEventToAnalytics(
      'Decline Cancel Subscription',
      'Decline Cancel Subscription',
      'DECLINE_CANCEL_SUBSCRIPTION', userDetails, false);
    props.declineCancellation(false);
  }
  useEffect(() => {
    if (notIncludingDiscover.length == 1) {
      selectFeature([PLAN_NAME_MAPPING[notIncludingDiscover]])
    }
  }, [])

  return (
    <React.Fragment>
      <div className="header-wrapper">
        <i className="far fa-times modal-close-icon" style={iconStyles} onClick={declineCancellation} />
        <h1 className="subscribe-main-heading">Are you sure you want to cancel subscription?</h1>
        {notIncludingDiscover > 1 && <div className="subscribe-sub-heading">Please select a module you want to cancel.</div>}
      </div>
      {(notIncludingDiscover && notIncludingDiscover.length > 1) && (
        <Checkbox.Group
          className="subscription-plan-checkbox-wrapper"
          style={{ width: '100%' }}
          onChange={onChange}
        >
          <Space direction="vertical">
            {notIncludingDiscover.map(item => (
              <Row key={item}>
                <Col>
                  <Checkbox value={PLAN_NAME_MAPPING[item]}>
                    {PLAN_NAME_MAPPING[item]}
                  </Checkbox>
                </Col>
              </Row>
            ))}

          </Space>
        </Checkbox.Group>
      )}
      <div className='subscribe-footer-content'>
        Your subscription will be cancelled at the end of your billing period.
        {/* <br /> on <strong>{moment(subscriptionEndDate).format('DD MMMM, YYYY')}</strong> */}
      </div>
      <div className="unsubscribe-button-wrapper">
        <Button
          color="#6AA7E1"
          className="btn secondary-btn"
          onClick={declineCancellation}
        >
          No
        </Button>
        <Button
          disabled={selectedFeatures.length === 0}
          color="#6AA7E1"
          className="btn primary-btn"
          onClick={confirmCancellation}
        >
          Confirm
        </Button>
      </div>
    </React.Fragment>
  )
}

export default SubscriptionCancelModal

import React from 'react';
// import moment from 'moment';
// import { connect } from 'react-redux';
import {
  ModalFooter,
} from 'reactstrap';

import ItemList from './ItemList';
import { Footer, Loader } from './helper';

class FundsTab extends React.Component {
  buyHandler = (item) => {
    const { buyHandler } = this.props;
    if (buyHandler) {
      if (item) {
        buyHandler(item);
      } else {
        buyHandler('all');
      }
    }
  }

  actionHandler = (type, item) => {
    const { actionHandler } = this.props;
    if (actionHandler && type) { // type: ['combine', 'funds'] which tab to open
      actionHandler(type, item);
    }
  }

  clearAllFunds = (item) => {
    const { clearHandler } = this.props;
    if (clearHandler) {
      clearHandler('all');
    }
  }

  render() {
    const { fetchFundsLoading } = this.props;
    return (
      <div>
        {fetchFundsLoading && <Loader />}
        <div className="modal-body-ssf">
          <ItemList
            {...this.props}
          />
        </div>
        <ModalFooter className="py-2 media-none sfm-modal-footer">
          <Footer
            {...this.props}
            buyHandler={this.buyHandler}
            actionHandler={this.actionHandler}
            clearAllFunds={this.clearAllFunds}
          />
        </ModalFooter>
      </div>
    )
  }
}

export default FundsTab;

import React from 'react';
import { Button, Row, Col, Card, CardBody } from 'reactstrap';
import { sponsorLogo } from 'DataSet';
import { getImageOrFallback } from 'Utils';
import { TruncatedNameSponsor, TruncatedText } from '../Common';

const classNames = require('classnames');

class ResultCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      data: this.props.data,
      imageValidPath: false,
    };
  }

  componentDidMount() {
    if (this.state.data.sponsor) {
      getImageOrFallback(
        sponsorLogo(this.state.data.sponsor)
      ).then(result => {
        if (result) {
          this.setState({ imageValidPath: true });
        } else {
          this.setState({ imageValidPath: false });
        }
      });
    }
  }

  toggleOpen = () =>{
    this.setState({isOpen : !this.state.isOpen});
  }

  render () {
    const { imageValidPath } = this.state;
    return (
      <Card className={classNames("shadow-sm mb-2 border-0")}>
        <CardBody className="p-0">
          <Row className="no-gutters">
            <Col className="col-5 p-1">
              <div className="selected-card bg-ssf-gray-l5 px-2 py-1 h-100 d-flex align-items-center position-relative" style={{ borderLeftColor: this.state.data.color }}>
                <div className="pr-1 position-absolute" style={{ top: '4px', right: '4px' }}>
                  <h5 className="mb-0 text-gray-5">{this.state.data.ticker}</h5>
                </div>
                <TruncatedNameSponsor imageValidPath={imageValidPath} name={this.state.data.name} sponsor={this.state.data.sponsor} />
              </div>
            </Col>
            <Col className="col-3 py-1">
              <div className="p-1 py-2 h-100 d-flex align-items-center bg-ssf-gray-l5">
                <Button color="link" block
                  className="broker px-1"
                  style={{ backgroundColor: this.state.data.brokerColor }} >
                  <h5 className="mb-1 text-white">{this.state.data.broker}</h5>
                </Button>
              </div>
            </Col>
            <Col className="col-4 p-1">
              <div className="p-1 h-100 d-flex flex-column align-items-center justify-content-center bg-ssf-gray-l5">
                { this.state.data.error
                ? <div className="text-danger text-center">
                    <h4 className="mb-0">Order Failed</h4>
                    { this.state.data.errorMsg && <h6 className="mb-0 mt-1"><TruncatedText text={this.state.data.errorMsg} lines={2} /></h6> }
                  </div>
                : <div className="text-center"><h4 className="text-success">Order Placed</h4>{this.state.data.borker != "TD Ameritrade" ? <h6 className="mb-0 mt-1"><a className="details-link" onClick={this.toggleOpen}>Details</a></h6>: <h6 className="mb-0 mt-1"></h6>}</div> }
              </div>
            </Col>
          </Row>
        </CardBody>
        {this.state.isOpen && <div>
          <Row className="no-gutters">
            <Col className="col-2 p-1">
              <h6 className="mb-1">Order No:</h6>
            </Col>
            <Col className="col-10 p-1">
              <h6 className="confirmation-detail-text mb-1">{this.state.data.orderNumber}</h6>
            </Col>
          </Row>
          <Row className="no-gutters">
            <Col className="col-2 p-1">
              <h6 className="mb-1">Time:</h6>
            </Col>
            <Col className="col-10 p-1">
              <h6 className="confirmation-detail-text mb-1">{this.state.data.timestamp}</h6>
            </Col>
          </Row>
          <Row className="no-gutters">
            <Col className="col-2 p-1">
              <h6 className="mb-1">Message:</h6>
            </Col>
            <Col className="col-10 p-1">
              <h6 className="confirmation-detail-text mb-1">{this.state.data.confirmationMessage}</h6>
            </Col>
          </Row>
        </div>}
      </Card>
    );
  }
}

export default ResultCard;

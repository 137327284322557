import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from 'reactstrap';

import { Formik } from 'formik'
import * as Yup from 'yup'
import { get } from 'lodash'

import { PrimaryButton, SecondaryButton, TickIcon } from 'shared-components'

import { setPremiumState, inviteFriends } from 'actions/profile';
import { setGlobalState } from 'actions/global';
import { auth } from 'actions/auth';
import { addEventToAnalytics } from 'Utils';
import InviteForm from './InviteForm';


class PremiumFeature extends Component {

  handleSubmit = (values) => {
    this.props.inviteFriends(values);
    var emails=values.map((i) => {
      return i.Email;
    })
    addEventToAnalytics('Send Invite','Send Invite','SEND_INVITE',{ email:emails.toString() },true);
  }

  render() {
    return (
      <React.Fragment>
        <InviteForm
          {...this.props}
          handleSubmit={this.handleSubmit}
          onToggle={this.handleToggle}
        />
      </React.Fragment>
    )
  }
}


const mapStateToProps = ({ auth, loading, router, profile, global }) => ({
  loggedIn: auth.loggedIn,
  loggedIn: auth.loggedIn,
  inviteFailed: profile.inviteFailed,
  inviteSuccess: profile.inviteSent,
  inviteError: profile.error,
  inviteMessage: profile.inviteMessage,
  premiumUser: auth.user.premiumUser,
  surveyModal: global.surveyModal,
  mobileDevice: (window.innerWidth <= 1023),
})

const mapDispatchToProps = {
  inviteFriends,
  setPremiumState,
  setGlobalState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PremiumFeature))

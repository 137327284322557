// absolute imports
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

class HighlightStat extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { firstName, lastName } = this.props;
    return (
      <div className="sw-highlight-card">
        <div className="sw-gen-style">
          <div className="sw-card-icon">
            <img src="/assets/images/advisor-icon.svg" />
          </div>
          <div className="sw-card-det">
            <span className="main-title">{firstName} {lastName}</span>
            <span className="sub-title">Advisor</span>
          </div>
        </div>
      </div>
    )
  }
}

const mapStatetoProps = ({ auth: { user: { firstName, lastName } } }) => ({
  firstName,
  lastName
})

export default connect(
  mapStatetoProps
)(HighlightStat);

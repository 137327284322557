// absolute imports
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
// import { isEmpty } from 'lodash';
// import classNames from 'classnames';

import { Layout, Result, Row, Col } from 'antd';
// import { BrowserRouter as Router, Route, Switch, Redirect, Link } from 'react-router-dom';

import { resetSummaryDataRequest } from 'actions/enhancer'
import { changeCalculator } from 'actions/weights'

import SideBar from './SideBar';

class Dashboard extends React.PureComponent {
  render() {
    const {
      // routerData,
      // match,
      // loading,
      // location,
      component: Component,
    } = this.props;
    // const { pathname } = location;

    return (
      <Layout
        style={{
          // minHeight: 'calc(100vh - 60px)',
        }}
      >
        <Row className="dashboard-container">
          <Col span={6} className="sidebar-container" style={{ paddingRight: 15 }}>
            <SideBar
              {...this.props}
              setContent={this.setContent}
              setPfEnhancement={this.setPfEnhancement}
              enableEnhancePortfolioQueries={'enableEnhancePortfolioQueries'}
              toggleFirstTimeEnhanceQuerySelction= {this.toggleFirstTimeEnhanceQuerySelction}
              enableFirstEnhancePortfolioQueries=""
            />
          </Col>
          <Col span={18} className="dashboard-content-container" style={{ padding: '15px 0 15px 15px' }}>
            <Fragment>
              <Component {...this.props} />
            </Fragment>
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapStateToProps = ({ router }) => ({
  location: router.location,
});

const mapDispatchToProps = {
  changeCalculator,
  resetSummaryDataRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);

// const NotFound = () => {
//   return (
//     <Result
//       status="404"
//       title="404"
//       subTitle="Sorry, the page you visited does not exist."
//     />
//   )
// }

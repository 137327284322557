import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';

import { Modal, ModalBody, ButtonGroup } from 'reactstrap';
import { PrimaryButton } from 'shared-components';

import { setPremiumState } from 'actions/profile';

// import InviteFriends from 'layouts/WebDashboard/SearchResults/Elements/InviteFriends';
import { allowTestDrive } from 'apis/test-drive';
// import PremiumPlanDetails from './PremiumPlanDetails';
// import LoginWall from './LoginWall';
import { addEventToAnalytics, jsonInURL } from 'Utils';
import { ADVISOR_DEMO_LINK, PLAN_NAME_MAPPING } from 'DataSet';

const modalHeader = {
	testDrive: 'You have discovered a premium feature!',
	referal: 'You have discovered a premium feature!'
}

class PremiumModal extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

	setFeaturesModalOpen = (state) => {
		const { setPremiumState } = this.props;
		if (setPremiumState) {
			setPremiumState({
				premiumNoticeModalOpen: state || false,
			})
		}
	}

	onFeaturesModalOkClick = () => {
		this.cancel();
		if (this.props.premiumUser) this.props.onClickOK();
	}

	testDrive = () => {
		if (this.props.activeFeatureName) {
			addEventToAnalytics('Test Drive', 'Test Drive', 'TEST_DRIVE', { feature_name: this.props.activeFeatureName }, false);
		}
		this.props.onClickCancel();
		this.setFeaturesModalOpen(false);
		if (this.props.activeFeatureName !== 'my_calculator') {
			allowTestDrive(this.props.activeFeatureName);
		}
		if (this.props.testDriveAction) this.props.testDriveAction();
	}

	cancel = () => {
		const { activeFeatureName, match } = this.props;
		if (activeFeatureName) {
			addEventToAnalytics('Test Drive Cancel', 'Test Drive Cancel', 'TEST_DRIVE_CANCEL', { feature_name: activeFeatureName }, false);
		}
		if (match && match.path === '/investments' ||
			window.location.pathname === '/investments' ||
			window.location.pathname.includes('/build-portfolio/') ||
			window.location.pathname.includes('/enhancer') ||
			window.location.pathname === '/advisor/account' ||
			window.location.pathname === '/advisor/accountV2'
		) {
			this.props.history.push('/securities');
		}
		this.props.onClickCancel();
		this.setFeaturesModalOpen(false);
	}

	premiumPlanDemoClick = () => {
		const { activeFeatureName } = this.props;
		// console.log('premiumPlanDemoClick', activeFeatureName)
		addEventToAnalytics(
			'Premium Plan Schedule Demo',
			'Premium Plan Schedule Demo',
			'PREMIUM_PLAN_SCHEDULE_DEMO',
			{ feature_name: activeFeatureName },
			false
		);
		// console.log(PLAN_NAME_MAPPING[ADVISOR_DEMO_LINK], PLAN_NAME_MAPPING[activeFeatureName], activeFeatureName)
		const featureName = PLAN_NAME_MAPPING[activeFeatureName] || activeFeatureName;
		const link = jsonInURL({ utm_content: featureName }, ADVISOR_DEMO_LINK);
		// console.log(link)
		window.location.href = link;
	}

	premiumPlanLearnMoreClick = () => {
		const { activeFeatureName } = this.props;
		// console.log('premiumPlanLearnMore', activeFeatureName)
		addEventToAnalytics(
			'Premium Plan Learn More',
			'Premium Plan Learn More',
			'PREMIUM_PLAN_LEARN_MORE',
			{ feature_name: activeFeatureName },
			false
		);
		// window.location.href = ADVISOR_PRICING_LINK;
		this.props.onClickCancel();
		this.setFeaturesModalOpen(false);
		this.props.history.push('/settings/subscriptionPlan');
	}

  render() {
		const {
			// loggedIn,
			access,
			premiumUser,
			premiumNoticeModalOpen,
			activeFeatureName,
			planName,
		} = this.props;

		// <PremiumPlanDetails activeFeatureName={activeFeatureName} showPremium />

		// {access === 'Denied' && (
		// 	<p className="text-align-left pb-2" style={{ marginTop: '-5px' }}>
		// 		{process.env.REACT_APP_SUB_DOMAIN === "retail" ?
		// 			"Access this and all other premium features for $250 per month." :
		// 			"Access this and all other premium features for $500 per month or try Magnifi Pro for $250 per month."
		// 		}
		// 	</p>
		// )}

		// console.log('activeFeatureName --> ', activeFeatureName);

		return (
			<Fragment>
				<Modal
					keyboard
					centered
					backdrop
					isOpen={premiumNoticeModalOpen}
					className="premium-msg-modal"
					backdropClassName="premium-feature-process-modal-backdrop"
					toggle={this.cancel}
				>
					<ModalBody className="ssf-modal__body">
						<div className="premium-feature-process-modal-container">
							<div className="header-wrapper">
								<h1 className={"text-align-left " + ((access === 'Test Drive') ? 'pb-4' : '')}>
									{access === 'Test Drive' ? modalHeader.testDrive : modalHeader.referal}
								</h1>
								{access === 'Denied' && (
									<div className="premium-feature-title-text">
										<span className="learn-more-text" onClick={this.premiumPlanDemoClick}>Schedule a demo</span>
										<span> to see it in action or </span>
										<span className="learn-more-text" onClick={this.premiumPlanLearnMoreClick}>learn more</span>
									</div>
								)}
							</div>
							<i className="fal fa-times-circle premium-feature-completed-close" onClick={this.cancel}></i>
							{(premiumUser && planName === 'Premium Plan' && access === 'Test Drive') && (
								<ButtonGroup className="cta-wrapper justify-content-left">
									<PrimaryButton className="btn secondary-btn" type="button" onClick={this.onFeaturesModalOkClick} style={{ maxWidth: 150 }}>
										OK, GOT IT
									</PrimaryButton>
								</ButtonGroup>
							)}
							{((!premiumUser || planName !== 'Premium Plan') && access === 'Test Drive') && (
								<ButtonGroup className="cta-wrapper justify-content-center">
									<PrimaryButton className="btn secondary-btn fs-20" type="button" onClick={this.testDrive} style={{ maxWidth: 218, margin: 0 }}>
										TRY IT FOR FREE
									</PrimaryButton>
								</ButtonGroup>
							)}
						</div>
					</ModalBody>
				</Modal>
			</Fragment>
		)
  }
}

const mapStateToProps = ({ auth, profile }) => ({
  // auth,
	// loggedIn: auth.loggedIn,
	// premiumFeatures: profile.premiumFeatures,
	// activeFeatureName: profile.activeFeatureName,
	// premiumNoticeModalOpen: profile.premiumNoticeModalOpen,
	// access: profile.access, // 'Access' or 'Denied' or 'Test Drive'
})

const mapDispatchToProps = {
	setPremiumState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PremiumModal))

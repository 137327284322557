import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Row, Col, Button } from 'reactstrap';
import { Row as AntRow, Col as AntCol, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import PerfectScrollbar from 'perfect-scrollbar';

import { getImageOrFallback } from 'Utils'
import { sponsorLogo } from 'DataSet';
import { investmentAccountView } from 'actions/investment';
import { Spinner } from 'shared-components';

const { Option } = Select;

export const Wrapper = (props) => {
  return (
    <div className={props.className+" my-1"}>
      <div className="p-3 bg-white rounded border border-gray-2 h-100">
        {props.children}
      </div>
    </div>
  )
}

export const WrapperNP = (props) => {
  return (
    <div className={props.className+" my-1"}>
      <div className="bg-white rounded border border-gray-2 h-100">
        {props.children}
      </div>
    </div>
  )
}

export class SelectorDD extends React.PureComponent {
  state = { dropdownOpen: false, opt: this.props.opts[0] }

  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  selectOpt = opt => event => this.setState({ opt }, () => this.props.optHandler(opt))

  render() {
    const { dropdownOpen, opt } = this.state;
    return (
      <Dropdown isOpen={dropdownOpen} toggle={this.toggle} className="header float-right lh-100">
        <DropdownToggle caret color="link" className="p-0 lh-100">By {opt}</DropdownToggle>
        <DropdownMenu style={{minWidth:'6rem'}}>
          {this.props.opts.map(e => (
            <DropdownItem key={e} onClick={this.selectOpt(e)} className="py-1 fs-13">By {e}</DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    )
  }
}

class MoreButton_ extends React.PureComponent {
  clickHandler = () => {
    const { layout, expand, loadMoreAction } = this.props;
    this.props.investmentAccountView(expand ? 'default' : layout);
    if (loadMoreAction) {
      loadMoreAction(expand ? 'default' : layout);
    }
  }

  content = () => {
    const { expand } = this.props;
    return expand
    ? <i className="fal fa-times"></i>
    : <React.Fragment>More<i className="fas fa-caret-right fs-11 ml-1"></i></React.Fragment>
  }

  render() {
    const { expand, more, style } = this.props;
    // console.log(this.props);

    if (!expand && !more) return null;

    return (
      <Button style={style} color={"link"} size="sm" className="lh-100 p-0 float-right" onClick={this.clickHandler}>
        {this.content()}
      </Button>
    )
  }
}
export const MoreButton = connect(
  null,
  {
    investmentAccountView,
  }
)(MoreButton_);

export const KeyStats2 = (props) => {
  const { stats, prec = 0 } = props;
  return (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{minHeight: '80px'}}>
      <Row className="w-100">
        <Col xs="6" className="text-center">
          <h5 className="text-gray-4">{stats[0].name}</h5>
          <h1 className="text-secondary h1p5">{FormatCurrency(stats[0].value, prec)}</h1>
        </Col>
        <Col xs="6" className="text-center">
          <h5 className="text-gray-4">{stats[1].name}</h5>
          <h1 className="text-secondary h1p5">{FormatCurrency(stats[1].value, prec)}</h1>
        </Col>
      </Row>
    </div>
  )
}

export class KeyStats3 extends React.Component { 
  state = { view: 1, direction_one: '', transitionTimedOutOne: false, direction_two: '', transitionTimedOutTwo: false }

  viewHandler = view => ev => this.setState({ view });

  componentDidUpdate(prevProps, prevState) {
    let direction_one, direction_two
    if (this.props.stats[0].value != prevProps.stats[0].value) {
      
      if (this.props.stats[0].value > prevProps.stats[0].value) {
        direction_one = 'priceUp'
      } else {
        direction_one = 'priceDown'
      }
      this.setState({direction_one: direction_one, transitionTimedOutOne: false})
    }

    if (this.props.stats[2].value != prevProps.stats[2].value) {
      
      if (this.props.stats[2].value > prevProps.stats[2].value) {
        direction_two = 'priceUp'
      } else {
        direction_two = 'priceDown'
      }
      this.setState({direction_two: direction_two, transitionTimedOutTwo: false})
    }
    
  }

  updateClassOne = () => {
    this.setState({transitionTimedOutOne: true})
  }

  updateClassTwo = () => {
    this.setState({transitionTimedOutTwo: true})
  }

  render() {
    const { stats, prec = 0, selectedOption } = this.props;
    const { view } = this.state;

    return (
      <Row className="no-gutters rounded accounts-card-stats-wrapper" style={{ height: '100%' }}>
        <Col xs="6" className="col-block apex-bg-2 m-0" style={{ padding: '15px 0' }}>
          <div className="pt-0 pl-3 pr-3">
            <div className="fs-s1 tx-c3 mb-1">{stats[1].name}</div>
            {/* <h1 className={(this.state.transitionTimedOutOne) ? `fs-l1 fw-b tx-c2` : `fs-l1 fw-b tx-c2 ${this.state.direction_one}`} onTransitionEnd={() => this.updateClassOne()}>{!Number.isNaN(stats[0].value) ? stats[0].value === 0 ? "$0" : FormatCurrency(stats[0].value, prec) : '--'}</h1> */}
            <h1 className={(selectedOption && selectedOption.value < 0) ? `fs-l1 fw-b tx-c2 priceDown` : (selectedOption && selectedOption.value > 0) ? `fs-l1 fw-b tx-c2 priceUp`: 'fs-l1 fw-b tx-c2'} onTransitionEnd={() => this.updateClassOne()}>{!Number.isNaN(stats[0].value) ? stats[0].value === 0 ? "$0" : FormatCurrency(stats[0].value, prec) : '--'}</h1>
          </div>
        </Col>
        <Col xs="6" className="col-block border-gray-2 apex-bg-1 m-0" style={{ padding: '15px 0' }}>
          <div className="pt-0 pl-3 pr-3 border-left" style={{ borderRight: 0 }}>
            <div className="fs-s1 tx-c3 mb-1">{stats[2].name}</div>
            {/* <h1 className={(this.state.transitionTimedOutTwo) ? `fs-l1 fw-b tx-c2` : `fs-l1 fw-b tx-c2 ${this.state.direction_two}`} onTransitionEnd={() => this.updateClassTwo()}>{!Number.isNaN(stats[2].value) ? (stats[2].value===0?'$0':FormatCurrency(stats[2].value, prec)) : '--'}</h1> */}
            <h1 className={(selectedOption && selectedOption.value < 0) ? `fs-l1 fw-b tx-c2 priceDown` : (selectedOption && selectedOption.value > 0) ? `fs-l1 fw-b tx-c2 priceUp`: 'fs-l1 fw-b tx-c2'} onTransitionEnd={() => this.updateClassTwo()}>{!Number.isNaN(stats[2].value) ? (stats[2].value===0?'$0':FormatCurrency(stats[2].value, prec)) : '--'}</h1>
          </div>
        </Col>
      </Row>
    );
  }
}

export class PortfolioGains extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // selected: props.fundStats && props.fundStats.length > 0 ? props.fundStats[0] : null,
      direction: '', transitionTimedOut: false
    }
  }

  componentDidMount() {
    const { viewHandler, fundStats } = this.props;
    if (viewHandler && fundStats && fundStats.length > 0) {
      viewHandler(fundStats[0]);
    }
  }

  viewHandler = props => {
    const { viewHandler } = this.props;
    if (viewHandler) {
      viewHandler(props);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    let direction

    if (this.props.selectedOption && prevProps.selectedOption && this.props.selectedOption.value != prevProps.selectedOption.value) {
      
      if (this.props.selectedOption.value > prevProps.selectedOption.value) {
        direction = 'priceUp'
      } else {
        direction = 'priceDown'
      }
      this.setState({direction: direction, transitionTimedOut: false})
    }
  }

  updateClass = () => {
    this.setState({transitionTimedOut: true})
  }


  render() {
    const { fundStats: stats, selectedOption: selected, prec = 0 } = this.props;
    // const { selected } = this.state;

    if (typeof stats === 'undefined' || (stats && stats.length === 0) || !stats || selected === null) return null;

    let renderArray = stats.filter((kl) => kl.key !== selected.key);
    const _selectedKey = stats.find((j) => j.key === selected.key) || selected;
    const renderName = (<div className="fs-s1 tx-c3 mb-1">{_selectedKey.name}</div>);
    const renderPrice = (<h1 className="fs-l1 fw-b tx-c2">{_selectedKey.value == 0 ? '$0' : FormatCurrency(_selectedKey.value, prec)}</h1>);
    const renderView = (
      <div className="rounded accounts-card-stats-wrapper pos-rel">
        <AntRow className="pt-0 pl-3 pr-3 col-block">
          <AntCol span={14}>
            {renderName}
          </AntCol>
          {/* <AntCol span={10} id="custom-stats-switch-dropdown" className="custom-stats-switch-btn-wrapper">
            {renderArray.map((jk, index) => {
              if (index !== (renderArray.length - 1)) {
                return (
                  <Button color="link" className="p-0 cta mb-1" onClick={() => this.viewHandler(jk, index)}>
                    {jk.key}
                    <i className="fal fa-minus fa-rotate-90 mx-0p5"></i>
                  </Button>
                )
              } else {
                return (
                  <Button color="link" className="p-0 cta mb-1" onClick={() => this.viewHandler(jk, index)}>
                    {jk.key}
                  </Button>
                )
              }
            })}
          </AntCol> */}
          <AntCol span={24}>
            {/* <h1 className={(this.state.transitionTimedOut) ? `fs-l1 fw-b tx-c2` : `fs-l1 fw-b tx-c2 ${this.state.direction}`} onTransitionEnd={() => this.updateClass()}>{selected.value == 0 ? '$0' : FormatCurrency(selected.value, prec)}</h1> */}
            <h1 className={(selected.value < 0) ? `fs-l1 fw-b tx-c2 priceDown` : (selected.value === 0) ?`fs-l1 fw-b tx-c2`: 'fs-l1 fw-b tx-c2 priceUp'} onTransitionEnd={() => this.updateClass()}>{selected.value == 0 ? '$0' : FormatCurrency(selected.value, prec)}</h1>
          </AntCol>
        </AntRow>
      </div>
    )

    return renderView;
  }
}

export class KeyStats5 extends React.Component {
  state = { view: 1, direction: '', transitionTimedOut: false }

  viewHandler = view => this.setState({ view: this.state.view === 0 ? 1 : 0 });

  callBack = () => {
    this.props.history.push('/settings/tradingAccount?show=linked_accounts');
    const { callBack } = this.props;
    if (callBack) {
      callBack('Add Balance');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let direction;
    let view = this.state.view

    if (this.props.stats && prevProps.stats && this.props.stats[view].value != prevProps.stats[prevState.view].value) {
      
      if (this.props.stats[view].value > prevProps.stats[prevState.view].value) {
        direction = 'priceUp'
      } else {
        direction = 'priceDown'
      }
      this.setState({direction: direction, transitionTimedOut: false})
    }
  }

  updateClass = () => {
    this.setState({transitionTimedOut: true})
  }

  render() {
    const { stats, prec = 0 } = this.props;
    const { view } = this.state;

    return (
      <Row className="no-gutters apex-bg-1 rounded accounts-card-stats-wrapper" style={{ height: '100%' }}>
        <Col xs="12" className="col-block">
          <div className="pt-0 pl-3 pr-3">
            <Button color="link" className="float-right p-0 cta" onClick={this.viewHandler}>
              {/* Add Balance */}
              {view === 0 ? stats[1].name : stats[0].name}
            </Button>
            <div className="fs-s1 tx-c3 mb-1">{stats[view].name}</div>
            <Button color="link" className="float-right p-0 cta" onClick={this.callBack}>
              <ul className="m-0 p-0 pt-2 tx-c3" style={{fontSize: 10}}>Add Balance</ul>
              {/* <img src="/assets/images/icons/AddFunds.svg" /> */}
            </Button>
            {/* <h1 className={(this.state.transitionTimedOut) ? `fs-l1 fw-b tx-c2` : `fs-l1 fw-b tx-c2 ${this.state.direction}`} onTransitionEnd={() => this.updateClass()} >{stats[view].value == 0 ? '$0' : FormatCurrency(stats[view].value, prec)}</h1> */}
            <h1 className={`fs-l1 fw-b tx-c2`} style={{ color: '#4a4a4a' }} onTransitionEnd={() => this.updateClass()} >{stats[view].value == 0 ? '$0' : FormatCurrency(stats[view].value, prec)}</h1>
            
          </div>
        </Col>
      </Row>
    );
  }
}

export class KeyStats1 extends React.Component {
  render() {
    const { stats, prec = 0 } = this.props;
    return (
      <Row className="no-gutters apex-bg-1" style={{ minHeight: '120px', height: '100%' }}>
        <Col xs="12">
          <div className="pt-3 pl-3 pr-3">
            <div className="fs-s1 tx-c3 mb-1">{stats[0].name}</div>
            <h1 className="fs-l1 fw-b tx-c2">{stats[0].value !== 0 ? FormatCurrency(stats[0].value, prec) : '--'}</h1>
          </div>
        </Col>
      </Row>
    )
  }
}

export class KeyStatsPort1 extends React.Component {
  render() {
    const { stats, prec = 0 } = this.props;
    return (
      <Row className="no-gutters apex-bg-1" style={{ height: '100%' }}>
        <Col xs="12" className="port-custom-container">
          <div className="pt-3 pl-3 pr-3">
            <div className="fs-s1 tx-c3 mb-1">{stats[0].name}</div>
            <h1 className="fs-l1 fw-b tx-c2">{stats[0].value !== 0 ? FormatCurrency(stats[0].value, prec) : '--'}</h1>
          </div>
        </Col>
      </Row>
    )
  }
}

export const numFmt = (num, prec = 2) =>
  typeof num === "number"
    ? num.toLocaleString('en', {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: prec,
        minimumFractionDigits: 0
      })
    : ''

export const FormatCurrency = (value, prec = 0, curr = '$', showPositiveSign = false) => {
  if(value === 0){
    return `${curr}${value}`;
  }
  let _prefix = (value < 0 ? '-' : value > 0 && showPositiveSign ? "+" : '');
  let [_value, _suffix, _prec] = [Math.abs(value), '', prec];

  if (_value >= 1e9)
    [_value, _suffix, _prec] = [_value/1e9, 'B', 2];
  else if (_value >= 1e6)
    [_value, _suffix, _prec] = [_value/1e6, 'M', 2];

  if (_value >= 100 && _prec >= 0) _prec = 0;
  else if (_value >= 10 && _prec >= 0) _prec = 1;

  return `${_prefix}${numFmt(_value)}${_suffix}`
}

export const TruncatedNameSponsor = ({ imageValidPath, ticker, name, sponsor, style, lines = 1}) => {
  return (
    <div style={style}>
      {ticker && <h5 className="mb-0 text-blue">{ticker}</h5>}
      <div className="pb-1">
        {imageValidPath ? (
          <img src={sponsor ? sponsorLogo(sponsor) : ''} className="img-fluid" alt={sponsor} style={{ height: '20px', objectFit: 'contain' }} />
        ) : (
          <div className="sponsor-logo-placeholder" title={sponsor} style={{ width: '100%', height: 22, lineHeight: '22px' }}>{sponsor || ''}</div>
        )}
      </div>
      <h5 className="font-weight-bold text-ssf-blue-d2 mb-0 truncate lh-125" title={name}>{name}</h5>
    </div>
  )
}

export const withScrollBar = (WrappedComponent) => {
  return class extends React.Component {
    componentDidMount() {
      this.ps = new PerfectScrollbar('.holdingsWithScrollbar', { suppressScrollX: true });
    }

    componentWillUnmount() {
      this.ps.destroy();
      this.ps = null;
    }

    render() {
      return (
        <div className="holdingsWithScrollbar ps-wrap">
          <WrappedComponent {...this.props} />
        </div>
      )
    }
  }
}

export const transactionsWithScrollBar = (WrappedComponent) => {
  return class extends React.Component {
    componentDidMount() {
      this.ps = new PerfectScrollbar('.transactionsWithScrollBar', { suppressScrollX: true });
    }

    componentWillUnmount() {
      this.ps.destroy();
      this.ps = null;
    }

    render() {
      return (
        <div className="transactionsWithScrollBar ps-wrap">
          <WrappedComponent {...this.props} />
        </div>
      )
    }
  }
}

export const invalidTickerWithScrollBar = (WrappedComponent) => {
  return class extends React.Component {
    componentDidMount() {
      this.ps = new PerfectScrollbar('.ps-wrap', { suppressScrollX: true });
    }

    componentWillUnmount() {
      this.ps.destroy();
      this.ps = null;
    }

    render() {
      return (
        <div className="ps-wrap" style={{ height: '538px' }}>
          <WrappedComponent {...this.props} />
        </div>
      )
    }
  }
}

export const Placeholder = ({ title, height }) => {
  return (
    <React.Fragment>
      <h5 className="mb-1 text-secondary">{title}</h5>
      <div className="d-flex justify-content-center align-items-center" style={{minHeight: height || '225px'}}>
        <h3 className="pb-3 text-gray-2"><i className="fas fa-puzzle-piece fa-3x" title="Placeholder"></i></h3>
      </div>
    </React.Fragment>
  )
}

export const NoData = ({ height }) => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{height: height}}>
      <h3 className="text-gray-4 mb-0">- no data -</h3>
    </div>
  )
}

export const Loading = ({ height }) => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{height: height}}>
      <Spinner isFetching={true} />
    </div>
  )  
}
import React, { Component } from 'react';
import { connect } from 'react-redux'
// import classNames from 'classnames';
import { Row, Col } from 'reactstrap';
// import { setGlobalState, clearFundsApiLoadPercent } from 'actions/global';
// import { searchQueryRequest, setFundsState } from 'actions/discovery';
import { sortFn, isViewPresentInChartTypes } from 'Utils';
import { SectorsAttrs, QuinItems, cn, AssetType, D3Colors09 } from 'DataSet';
import { RegionColors, SectorColors } from 'data/Colors';
import NotApplicable from 'components/NotApplicable';
import CardName from './CardHelper/CardName';
import FeeReturnRisk from './CardHelper/FeeReturnRisk';
import {
  VolumeChart,
  TopHoldingChart,
  ThemeScoreChart,
  ReturnsChart,
  SharpeGaugeChartWrap,
  TrackingErrorGaugeChartWrap,
  SortinoGaugeChartWrap,
  DynamicGuageChart,
  DividendYieldChart,
  RegionDiversificationChart,
  SectorDiversificationChart,
  HoldingsDiversificationChart,
  AssetChart,
  AssetAllocationChart,
  RegionChart,
  SectorChart,
  FudiciaryChart,
  MarketCapChart,
  IPODate
} from './ChartHelper';

// const rightArrowIcon = `/assets/images/arrow.svg`;

class ItemCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: null,
      iconDescPopover: false
    };
  }

  componentDidMount() {
    if (this.container !== null && typeof this.container.offsetWidth !== 'undefined') {
      const { smallScreen, bigScreen } = this.props;
      const chartWidth = this.container ? this.container.offsetWidth : (smallScreen ? 270 : (bigScreen ? 410 : 360));
      const lineChartWidth = chartWidth - 30;
      const lineChartHeight = smallScreen ? 80 : (bigScreen ? 91 : 90);
      this.setState({
        dimensions: {
          width: lineChartWidth,
          height: lineChartHeight,
        },
      });
    }
  }

  getChartDataByViewName = (viewObject, card, chartData) => {
    const { view, mappingVariable } = viewObject;
    return chartData.length ? chartData.filter((item) => {
      if (mappingVariable && mappingVariable !== '' && mappingVariable.length) {
        return item.display_view_name === view && item.variable === mappingVariable;
      } else {
        return item.display_view_name === view;
      }
    }) : [];
  }

  isDynamicChart(view, _chartData) {
    // staticChart === false && chartType === "odometer"
    let chartData = _chartData.length ? _chartData.filter((item) => item.view_name === view) : [];
    if (chartData.length && !chartData[0].Static && chartData[0].chart_type === 'odometer') {
      return true;
    }
    return false;
  }

  renderLineChartDesign = () => {
    const { card, smallScreen, bigScreen, itemsKey, showGoBackSection, SHOWING_ALL_STOCKS } = this.props;
    const { dimensions } = this.state;
    const lineChartWidth = dimensions.width;
    const lineChartHeight = dimensions.height;
    // let moreButtonStyle = {};

    // if (typeof card._cstats === 'undefined') return <NotApplicable />;
    if (typeof card._returnsChart === 'undefined' || typeof card._returnsChart.series === 'undefined' || card._returnsChart.series.length === 0) return <NotApplicable />;

    const lineData = card._returnsChart.series;
    const tickerStartDate = card._tickerStartDate;
    // const getLabelValue = (props) => {
    //   const stockButtonElem = document.getElementById('magnifi-more-stocks-button');
    //   if (props &&
    //     stockButtonElem &&
    //     !isNaN(props.baseValue) &&
    //     !isNaN(props.labelValue) &&
    //     typeof props.baseValue !== 'undefined' &&
    //     typeof props.labelValue !== 'undefined'
    //   ) {
    //     // stockButtonElem.style.position = 'absolute';
    //     // stockButtonElem.style.right = 0;
    //     const { baseValue, labelValue } = props;
    //     console.log('labelValue --> ', labelValue, 'baseValue --> ', baseValue, Math.abs(labelValue - baseValue));
    //     if (labelValue < baseValue) {
    //       // check if diff is more than 50
    //       if (Math.abs(labelValue - baseValue) > 50) {
    //         stockButtonElem.style.top = 0;
    //       } else {
    //         stockButtonElem.style.bottom = 0;
    //       }
    //     } else if ((labelValue > baseValue) && (Math.abs(labelValue - baseValue) > 50)) {
    //       // check if diff is more than 50
    //       if (Math.abs(labelValue - baseValue) > 50) {
    //         stockButtonElem.style.bottom = 0;
    //       } else {
    //         stockButtonElem.style.top = 0;
    //       }
    //     }
    //   }
    // };
    const SHOW_MORE_STOCKS_BUTTON = (!showGoBackSection && card && card.type === 'SHARE' && !SHOWING_ALL_STOCKS);
    return (
      <React.Fragment>
        <ReturnsChart
          itemsKey={itemsKey}
          lineData={lineData}
          bigScreen={bigScreen}
          line3={card._opticker}
          width={lineChartWidth}
          height={lineChartHeight}
          fid={cn(card, 'Ticker')}
          smallScreen={smallScreen}
          ydomain={card._returnRange}
          tickerStartDate={tickerStartDate}
        />
        {(SHOW_MORE_STOCKS_BUTTON) && (
          <div id="magnifi-more-stocks-button" className="render-more-stocks-text">
            <span onClick={() => this.moreStocksOnClick()}>More Stocks <i class="fas fa-chevron-right"></i></span>
          </div>
        )}
      </React.Fragment>
    )
  }

  openFundDetailDrawer = () => {
    const { card, openFundDetailDrawer } = this.props;
    if (card && (card.type === 'BASKET' || card.vehicle === 'BASKET') && openFundDetailDrawer) {
      openFundDetailDrawer(card)
    }
  }

  moreStocksOnClick = () => {
    const { moreStocksOnClick } = this.props;
    if (moreStocksOnClick) {
      moreStocksOnClick();
    }
  }

  render() {
    const {
      k,
      card,
      view: viewList,
      // showCarousel,
      // secondaryView,
      chartData,
      activeRegion,
      searchFundsActiveRegion,
      highlightText,
      checkPopOpen,
      firstPop,
      smallScreen,
      bigScreen,
      // selectedCard,
      // stockFunds,
      // showGoBackSection,
    } = this.props;
    // const lineChartColumn = 'col-4';
    // const viewChartsColumn = 'col-2';
    let othersHolding = card.holdingData.find(e => e.name === 'Others');
    const dataSortedHolding = card.holdingData.filter(e => e.name !== 'Others').sort(sortFn('value'));
    const GuageChartWidth = smallScreen ? 90 : (bigScreen ? 130 : null);
    const PieChartSize = smallScreen ? 74 : (bigScreen ? 100 : null);
    const getView = (_View, secondaryView, staticChart, chartType, mappingVariable, _card, _chrtD) => {
      let view = (_chrtD.length > 1 && mappingVariable === '' && _chrtD.find((g) => g.display_view_name === _View)) ? _chrtD.find((g) => g.display_view_name === _View).view_name : _View;
      let _chartData = (_chrtD.length > 1 && mappingVariable === '') ? _chrtD.filter((g) => g.display_view_name === _View) : _chrtD;

      return [
        view === 'Regions' && <RegionChart width={PieChartSize} height={PieChartSize} key={k} card={_card} chartData={_chartData} name={'Allocations'} colors={RegionColors} activeRegion={activeRegion} searchFundsActiveRegion={searchFundsActiveRegion} view={view} />,
        view === 'Sectors' && <SectorChart width={PieChartSize} height={PieChartSize} key={k} card={_card} chartData={_chartData} attrs={SectorsAttrs} name={'Sectors'} colors={SectorColors} view={view} />,
        view === 'Assets' && <AssetChart width={GuageChartWidth} key={k} card={_card} chartData={_chartData} qi={QuinItems[2]} />,
        view === 'Volume' && <VolumeChart width={GuageChartWidth} key={k} card={_card} chartData={_chartData} qi={QuinItems[1]} />,
        view === 'Top Holdings' && <TopHoldingChart width={PieChartSize} height={PieChartSize} key={k} card={_card} chartData={_chartData} data={othersHolding ? [...dataSortedHolding, othersHolding] : dataSortedHolding} name={'Top Holdings'} colors={SectorColors} view={view} />,
        view === 'Asset Allocation' && <AssetAllocationChart width={PieChartSize} height={PieChartSize} key={k} card={_card} chartData={_chartData} attrs={AssetType} name={'Asset Types'} colors={SectorColors} />,
        view === 'Themes' && <ThemeScoreChart width={GuageChartWidth} imgSize={smallScreen ? 40 : 50} key={k} card={_card} chartData={_chartData} name={'Theme Score'} />,
        view === 'Market Cap' && <MarketCapChart width={GuageChartWidth} key={k} card={_card} chartData={_chartData} />,
        (view === 'Diversification' && secondaryView === 'Holdings') && <HoldingsDiversificationChart width={PieChartSize} height={PieChartSize} key={k} card={_card} chartData={_chartData} colors={D3Colors09} name={'Holdings Diversification'} />,
        (view === 'Diversification' && secondaryView === 'Region') && <RegionDiversificationChart width={PieChartSize} height={PieChartSize} key={k} card={_card} chartData={_chartData} colors={D3Colors09} name={'Region Diversification'} />,
        (view === 'Diversification' && secondaryView === 'Sector') && <SectorDiversificationChart width={PieChartSize} height={PieChartSize} key={k} card={_card} chartData={_chartData} colors={D3Colors09} name={'Sector Diversification'} />,
        (view === 'Return Quality' && secondaryView === 'Sharpe Ratio') && <SharpeGaugeChartWrap width={GuageChartWidth} key={k} card={_card} chartData={_chartData} name={'Sharpe'} />,
        (view === 'Return Quality' && secondaryView === 'Sortino Ratio') && <SortinoGaugeChartWrap width={GuageChartWidth} key={k} card={_card} chartData={_chartData} name={'Sortino'} />,
        (view === 'Return Quality' && secondaryView === 'Tracking Error') && <TrackingErrorGaugeChartWrap width={GuageChartWidth} key={k} card={_card} chartData={_chartData} name={'Tracking Error'} multiplier={100} />,
        view === '30-Day Yield' && <DividendYieldChart width={GuageChartWidth} key={k} card={_card} chartData={_chartData} name={'Dividend Yield'} />,
        view === 'Fiduciary Risk' && <FudiciaryChart width={GuageChartWidth} key={k} card={_card} chartData={_chartData} name={'Fiduciary Risk'} />,
        view === 'IPO Date' && <IPODate key={k} card={_card} />,
        (!isViewPresentInChartTypes(view) && this.isDynamicChart(view, _chartData)) && DynamicGuageChart(view, _card, GuageChartWidth, _chartData),
      ];
    };
    const renderSingleChart = () => {
      let forSingleChart = {
        staticChart: '',
        chartType: '',
      };
      const forSingleChartArr = this.getChartDataByViewName(viewList, card, chartData);
      if (forSingleChartArr.length && typeof forSingleChartArr[0].Static !== 'undefined') {
        forSingleChart.staticChart = forSingleChartArr[0].Static;
        forSingleChart.chartType = forSingleChartArr[0].chart_type;
      }
      if (viewList.mappingVariable && viewList.mappingVariable !== '' && viewList.mappingVariable.length) {
        const foundQueryVarData = chartData.filter((item) => item.display_view_name === viewList.view && item.variable === viewList.mappingVariable); // card.chartData.filter((item) => item.display_view_name === viewList.view && item.variable === viewList.mappingVariable);
        const _view = foundQueryVarData && foundQueryVarData[0].view_name;
        const _chartType = foundQueryVarData && foundQueryVarData[0].chartType;
        const _staticChart = foundQueryVarData && foundQueryVarData[0].staticChart;
        const getViewDesign = getView(_view, viewList.secondaryView, _staticChart, _chartType, viewList.mappingVariable, card, foundQueryVarData);
        return getViewDesign;
      } else {
        // card._chartData = chartData; // card.chartData;
        const getViewDesign = getView(viewList.view, viewList.secondaryView, forSingleChart.staticChart, forSingleChart.chartType, viewList.mappingVariable, card, chartData);
        return getViewDesign;
      }
    }
    const { dimensions } = this.state;
    // const styleForNA = (typeof card._stats === 'undefined') ? {
    //   minHeight: '100%',
    //   justifyContent: 'center',
    //   alignItems: 'center',
    //   display: 'flex',
    // } : {};
    const topSectionParent = document.getElementById("topSectionContainer");
    const itemListHeaderWrapperElem = document.getElementById("itemListHeaderWrapper");
    if (topSectionParent && itemListHeaderWrapperElem) {
      const parentTopSectionWrapper = document.getElementById("itemListArea");
      if (parentTopSectionWrapper) {
        let heightForList = itemListHeaderWrapperElem.offsetHeight + topSectionParent.offsetHeight + 44 + "px"; // 20 is padddingTop for itemListHeaderWrapper
        parentTopSectionWrapper.style.height = `calc(95vh - ${heightForList})`;
      }
    }
    // const isBasketFund = (card.vehicle === "BASKET" || card.type === "BASKET");
    // console.log('card._selected==>', card._selected, selectedCard, card.ticker);
    // {false && (
    //   <>
    //     {isBasketFund ?
    //       <div className="no-gutters align-items-center h-100 media-flex-nowrap row basket-fund-container">
    //         {/* <div className="col-lg-3 col-md-auto d-lg-block d-sm-none select-btn-box col">
    //           <div className="options-btn-body d-flex align-center row">
    //             <img src={`/assets/images/basket_icons/${card.basket_desciption_icon ? (card.basket_desciption_icon).includes('.svg') ? card.basket_desciption_icon : card.basket_desciption_icon + '.svg' : 'basket_icon.svg'}`} className="img-fluid" alt={cn(card, 'Sponsor')} />
    //           </div>
    //         </div> */}
    //         <div className="media-mycard-desc col-md-12 fund-name-box col">
    //           <div onMouseLeave={() => this.state.iconDescPopover && this.setState({ iconDescPopover: false })} onMouseEnter={() => !this.state.iconDescPopover && this.setState({ iconDescPopover: true })}>
    //             <span id={'basket_desc' + card.id} className="dark-blue-text">{card.basket_description ? card.basket_description : ''}</span>
    //             <Popover placement="left" trigger={'hover'} isOpen={this.state.iconDescPopover} target={'basket_desc' + card.id} toggle={() => this.setState({ iconDescPopover: !this.state.iconDescPopover })}>
    //               <PopoverBody><span className="fs-13">{card.basket_description}</span></PopoverBody>
    //             </Popover>
    //           </div>
    //           {/* <div>
    //             <a href={card.basket_link} target="_blank" rel="noopener noreferrer"><span className="external-link-label">CLICK HERE FOR MORE DETAILS <img src="/assets/images/icons/external-link.svg" alt="click-icon" /></span></a>
    //           </div> */}
    //         </div>
    //       </div> : dimensions && this.renderLineChartDesign()}
    //   </>
    // )}

    return (
      <div className={`p-0 itemCardWrapper ${!card._selected ? 'border-bg-filter-bg-color' : 'border-ssf-orange-d1'}`}>
        <Row className="no-gutters itemCard">
          <Col className="col-md-4 col-lg-4 col-sm-12 py-1 pl-1 paddingTpBtLt">
            <CardName {...this.props} highlightText={highlightText} />
          </Col>
          <Col className="col-lg-2 col-md-3 p-1 paddingTpBt media-none">
            <FeeReturnRisk
              card={card}
              chartData={chartData}
              highlightText={highlightText}
              checkPopOpen={checkPopOpen}
              firstPop={firstPop}
              discovery={this.props.discovery}
              userReturnPrefer={this.props.user_return_prefer}
              userRiskPrefer={this.props.user_risk_prefer}
              setReturnPopover={this.props.setReturnPopover}
              setRiskPopover={this.props.setRiskPopover}
            />
          </Col>
          <Col className="col-2 py-1 paddingTpBt media-none">
            <div className="w-100 h-100 card-background d-flex align-items-center chart-block" style={{ justifyContent: viewList.length >= 2 ? 'space-evenly' : 'center' }}>
              {renderSingleChart()}
            </div>
          </Col>
          <Col className="col-4 p-1 paddingTpBtRt media-none col-lg-4 col-md-3 w-100">
            <div
              className="position-relative h-100 card-background d-flex align-items-center justify-content-center w-100"
              ref={el => (this.container = el)}
              style={{ flexDirection: 'column' }}
            >
              {dimensions && this.renderLineChartDesign()}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  // discovery,
  // query: discovery.query,
  smallScreen: global.smallScreen,
  bigScreen: global.bigScreen,
});

const mapDispatchToProps = {
  // setGlobalState,
  // searchQueryRequest,
  // setFundsState,
  // clearFundsApiLoadPercent
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemCard);

import React from 'react';
import { connect } from 'react-redux';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { Select } from 'antd';
import { addEventToAnalytics } from 'Utils';
import {clientBenchMarkData,removeClientBenchMarkData} from 'actions/clientportfolioV2';

const { Option } = Select;
const ALLOCATION_PENDING='Selection Pending';
class AssetAllocation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedUpDownBenchmarkTicker : this.props.selectedClientDetails.benchmark || ALLOCATION_PENDING ,
    };
  }  

  handleUpDownDropDownChange=(value, disabled)=>{
    if(value!== ALLOCATION_PENDING){
      try{
        this.props.clientBenchMarkData({client_id:this.props.selectedClientDetails.id,benchmark:value});
        this.setState({
          selectedUpDownBenchmarkTicker:value
        })
      }catch(e){
        console.log("Getting Error In Saving Client BenchMark Data");
      }
    }else{
      if(!disabled){
        try{
          addEventToAnalytics('Deleting Selected Client Benchmark','Deleting Selected Client Benchmark','DELETE_SELECTED_CLIENT_BENCHMARK',true);
          this.props.removeClientBenchMarkData({client_id:[this.props.selectedClientDetails.id]});
          this.setState({
            selectedUpDownBenchmarkTicker:value
          })
        }catch(e){
          console.log("Getting Error In Deleting Client BenchMark Data");
      }
     }
    }

  }

  render() {

    const { selectedClientDetails: { holdings, asset_alloc} ,upDownDataSet} = this.props;
    const { selectedUpDownBenchmarkTicker} = this.state;

    const data = [
      {name: 'Bonds', model: 50},
      {name: 'Equity', model: 40},
      {name: 'Commodities', model: 35},
      {name: 'Currency', model: 25},
    ];    

    const assetAllocData = assetAllocCalc(asset_alloc);
    // const assetAllocData = data;

    return (
      // <div style={{width: '100%', height: '285px'}}>
      //   <ResponsiveContainer width="100%" height="100%" >
      //     <BarChart data={assetAllocData}
      //           margin={{top: 30, right: 10, left: 0, bottom: 30}} layout="vertical">
      //        <CartesianGrid horizontal={false} vertical={true} stroke="#e2e2e2" shapeRendering="crispEdges" />
      //        <XAxis type="number" ticks={xAxisTicks} tickFormatter={formatXAxis} stroke="#707070" shapeRendering="crispEdges" />
      //        <YAxis dataKey="name" type="category" stroke="#707070" shapeRendering="crispEdges" tick={false} width={10} />
      //        <Tooltip content={<BarCustomTooltip />} cursor={false} isAnimationActive={false} />
      //        <Bar dataKey="model" fill="#1e5f91" isAnimationActive={false} maxBarSize={25}>
      //          <LabelList dataKey="name" position="right" fill="#4a4a4a" shapeRendering="crispEdges" content={renderCustomizedLabel} />
      //        </Bar>
      //        {/*<Bar dataKey="current" fill="#56a9e8" isAnimationActive={false} />*/}
      //     </BarChart>
      //   </ResponsiveContainer>
      // </div>
      <div className='account-benchmark'>
        <div className="account-benchmark-tab" style={{marginTop: '4%'}}>
          <Select
              onChange={this.handleUpDownDropDownChange}
              value={selectedUpDownBenchmarkTicker}
              className='up-down-capture-dropdown'
              bordered={false}
              trigger={['click']}
              dropdownClassName='up-down-capture-menu'>
              {upDownDataSet && upDownDataSet.map(item => <Option key={item.ticker} value={item.ticker}>{item.ticker}</Option>)}
          </Select>
        </div>
        <div className={selectedUpDownBenchmarkTicker=== ALLOCATION_PENDING ? 'img-pointer-not-allowed' : 'img-pointer' } style={{marginTop:'4%',marginRight:'1%'}} onClick={()=>this.handleUpDownDropDownChange( ALLOCATION_PENDING ,selectedUpDownBenchmarkTicker=== ALLOCATION_PENDING )}><img src={'/assets/images/TrashIcon.svg'}/></div>
      </div>
    )
  }
}

const mapStateToProps = ({ clientportfolioV2: { selectedClientDetails,upDownDataSet } }) => ({
  selectedClientDetails,
  upDownDataSet
})

const mapDispatchToProps={
  clientBenchMarkData,
  removeClientBenchMarkData
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssetAllocation);

const assetAllocCalc = (values) => {
  let data = [
    {name: 'Bonds', model: 0},
    {name: 'Equity', model: 0},
    {name: 'Commodities', model: 0},
    {name: 'Currency', model: 0},
    {name: 'Cash', model: 0},
    {name: 'Derivatives', model: 0},
    {name: 'Others', model: 0},
  ]; 

  if(values){
    data = [
      {name: 'Bonds', model: Math.abs(values.bonds), orgVal: parseFloat(values.bonds).toFixed(2)},
      {name: 'Equity', model: Math.abs(values.equities), orgVal: parseFloat(values.equities).toFixed(2)},
      {name: 'Commodities', model: Math.abs(values.commodities), orgVal: parseFloat(values.commodities).toFixed(2)},
      {name: 'Currency', model: Math.abs(values.currencies), orgVal: parseFloat(values.currencies).toFixed(2)},
      {name: 'Cash', model: Math.abs(values.cash), orgVal: parseFloat(values.cash).toFixed(2)},
      {name: 'Derivatives', model: Math.abs(values.derivatives), orgVal: parseFloat(values.derivatives).toFixed(2)},
      {name: 'Others', model: Math.abs(values.other), orgVal: parseFloat(values.other).toFixed(2)},
    ];
  } 

//  let dataKey = ['bonds', 'equities', 'commodities', 'currencies', 'cash', 'derivatives', 'other'];

  // values.forEach(element => {
  //   let {asset_alloc} = element;    
  //   if(Object.keys(asset_alloc).length > 0){ 
  //     dataKey.forEach((item, i) => {
  //       data[i].orgVal = parseFloat(data[i].model+asset_alloc[item]/values.length).toFixed(2);
  //       data[i].model = Math.abs(data[i].orgVal);
  //     })
  //   }
  // });
  // debugger
  return data;
}

const xAxisTicks = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

const formatXAxis = (tick) => {
  return tick + '%';
}

const BarCustomTooltip = (props) => {
  const { active, payload, label, vkey } = props;
  if (active && payload) {
    return (
      <div className="recharts-default-tooltip ssf-tooltip-custom">
        <div className="label">{label}</div>
        { payload.map((e, index) => (
          <div key={e.dataKey} className="d-flex" style={{color: e.payload[`${e.dataKey+'Color'}`]}}>
            <span className="mr-2" style={{textTransform: 'capitalize'}}>{e.dataKey}</span>
            <span className="ml-auto">{e.payload.orgVal || ''}</span>
            <span className="">%</span>
          </div>
        )) }
      </div>
    );
  }

  return null;
}

const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value, fill, color } = props;
  let textProps = { color, width, height, fill, x: x+width + 5, y: y+(height/2), textAnchor: 'start'}

  if (Math.abs(width) > 450) {
    textProps.textAnchor = 'end'
    textProps.fill = '#fff'
    textProps.x = textProps.x - 10 
  }

  return (
    <g>
      <text {...textProps} className="recharts-text recharts-label">
        <tspan x={textProps.x} dy="0.355em">{value}</tspan>
      </text>
    </g>
  );
};
import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Row, Col, Button, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { DualColorLineChart } from 'components/charts/LineCharts';
import { getNestDefault, getImageOrFallback, convertToFloatvalues } from 'Utils';
import {
  cn,
  sponsorLogo
} from 'DataSet';
import { MoreButton, FormatCurrency, TruncatedNameSponsor, withScrollBar, NoData, invalidTickerWithScrollBar } from './Common';
import { TreeMapChart } from '../../../Elements/Charts/TreeMapChart';
import { TreeMapBurstChart } from '../../../Elements/Charts/TreeMapBurstChart';
import { returnYears } from './utils';

const { Option } = Select;

export class BasketHoldings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
      imageValidPath: false,
      TREE_MAP_DATA: [],
      FISRT_NODE_DATA: [],
      ALL_NODE_DATA: [],
      OTHERS_NODE_DATA: [],
      renderView: 'myHoldings',
      enableBackButton: false,
    }
  }

  componentDidMount() {
    const { card: fund } = this.props;
    if (fund) {
      this.prepareData(fund)
      this.checkSponsorLogo(fund);
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.card.name !== prevProps.card.name) {
      this.checkSponsorLogo(this.props.card);
    }
  }

  componentWillReceiveProps(newProps) {
    const { card: fund } = newProps;
    // console.log(fund);
    // console.log(fund.ticker, this.props.card && this.props.card.ticker);
    if (fund &&
      fund.ticker &&
      this.props.card &&
      this.props.card.ticker &&
      fund.ticker !== this.props.card.ticker) {
      this.prepareData(fund)
    }
  }

  checkSponsorLogo = (card) => {
    if (cn(card, 'Sponsor')) {
      getImageOrFallback(
        sponsorLogo(cn(card, 'Sponsor'))
      ).then(result => {
        let imageValidPath = false;
        if (result) {
          imageValidPath = true;
        }
        this.setState({
          imageValidPath
        });
      });
    }
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    })
  }

  selectReturnYear = (selectedYear) => {
    const { selectReturnYear, items } = this.props;
    if (selectReturnYear) {
      selectReturnYear(selectedYear, items)
    }
  };

  prepareData = (fund) => {
    if (fund && fund.basket_trades && fund.basket_trades.length) {
      let last = fund.basket_trades[fund.basket_trades.length - 1];
      let otherNode = {}, firstNodeData = [], othersNodeData = [];
      let firstNodeValue = 0, firstNodePrice = 0, othersNodeValue = 0, othersNodePrice = 0;

      let treeMapData = fund.basket_trades.map((d, i) => {
        return {
          name: d.ticker,
          full_name: d.name,
          size: convertToFloatvalues(d.weight)*100, // Math.floor(Math.random()*(999-100+1)+100), // d.original_weight || i || 0,
          price: convertToFloatvalues(d.current_price), // Math.floor(Math.random()*(999-100+1)+100),//d.market_value || d.current_price || i || 0,
          shares: d.shares,
          market_value: d.market_value
        }
      });
      treeMapData =  _.orderBy(treeMapData, ['size'], ['desc']);
      let TREE_MAP_DATA = [{ name: 'Initial', children: treeMapData }];
      let OTHERS_NODE_DATA = [{ name: 'Others', children: [] }]
      if (treeMapData.length > 13) {
        let sliceTreeMapData = treeMapData.slice(0, 12);
        if (sliceTreeMapData && sliceTreeMapData.length > 1) {
          firstNodeData = treeMapData.slice(0, 12);
          othersNodeData = treeMapData.slice(12, treeMapData.length);
          // so 'first' will be sum of all first sliced holding'sweight
          // for e.g: 'first': 2333 [sum of first 13 basket_trades weight]
          firstNodeData.map((j) => {
            firstNodeValue = firstNodeValue + j.size; // weight
            firstNodePrice = firstNodePrice + j.price;
            // j.size = j.size*100;
          });
          // so 'others' will be sum of all remaining holding's weight
          // for e.g: 'others': 233 [sum of 10 basket_trades weight]
          othersNodeData.map((j) => {
            othersNodeValue = othersNodeValue + j.size;
            othersNodePrice = othersNodePrice + j.price;
          });
          TREE_MAP_DATA = [{
            name: 'Initial',
            children: [
              ...firstNodeData, {
              name: 'Others',
              size: 12, // Math.floor(Math.random()*(999-100+1)+100), // firstNodeValue,
              price: 12, // Math.floor(Math.random()*(999-100+1)+100), // firstNodeValue,
            }], // All holdings
          }];
          OTHERS_NODE_DATA = [{
            name: 'Others',
            children: othersNodeData,
          }];
        }
      }
      console.log(TREE_MAP_DATA);
      this.setState({
        TREE_MAP_DATA,
        FISRT_NODE_DATA: TREE_MAP_DATA,
        OTHERS_NODE_DATA: OTHERS_NODE_DATA,
        ALL_NODE_DATA: [{ name: 'Initial', children: treeMapData }],
      });
    }
  }

  onOthersClick = () => {
    console.log('=== onOthersClick ===');
    const { ALL_NODE_DATA, OTHERS_NODE_DATA } = this.state;
    // let TREE_MAP_DATA = [{
    //   children: ALL_NODE_DATA, // All holdings
    // }];
    this.setState({ TREE_MAP_DATA: OTHERS_NODE_DATA, enableBackButton: true });
  }

  onBackClick = () => {
    // console.log('==== onBackClick ====');
    const { FISRT_NODE_DATA } = this.state;
    this.setState({ TREE_MAP_DATA: FISRT_NODE_DATA, enableBackButton: false });
  }

  viewHandler = (view) => {
    // console.log('--- renderView ---', view);
    this.setState({ renderView: view });
  }

  render() {
    const { items, height, card: fund } = this.props;
    const { imageValidPath, TREE_MAP_DATA, renderView, enableBackButton } = this.state;
    // console.log(items);
    if (renderView === 'securityReturns' && !items._dropdown) return null;

    // if (!items._series || typeof items._series === 'undefined' || items._series.length <= 1) return null;

    const { _dropdown: ReturnDropdown } = items;
    const ticker = getNestDefault(['ticker'], items, '');
    const marketValue = fund.market_value;
    const quantityValue = convertToFloatvalues(fund.shares);
    const returnSeriesNotPresent = (!items._series || typeof items._series === 'undefined' || items._series.length <= 1);
    // console.log(fund);
    // console.log('==== marketValue ====', marketValue);
    let _marketValue = (!isNaN(marketValue) && marketValue !== 'NaN' && marketValue !== '' && marketValue !== null && !isNaN(convertToFloatvalues(marketValue))) ? convertToFloatvalues(marketValue) : '--';
    // console.log('=== _marketValue ===', _marketValue);
    _marketValue = (_marketValue !== '--' && !isNaN(_marketValue) && _marketValue !== 'NaN' && marketValue !== null) ? FormatCurrency(_marketValue, 0) : '--';

    const weightToBe = fund.original_weight ? parseFloat(fund.original_weight.toFixed(2)) : null;
    let renderSelectedYearRange = null; // <span className="mb-0 time-Unit">{`(${startDate} - ${endDate})`}</span>
    const renderOptions = ReturnDropdown && Object.keys(ReturnDropdown).map((u) => {
      if (u && ReturnDropdown[u] &&
        typeof ReturnDropdown[u].value !== 'undefined' &&
        typeof ReturnDropdown[u].disabled !== 'undefined' &&
        typeof ReturnDropdown[u].label !== 'undefined') {
          if (typeof ReturnDropdown[items._selectedYear].year !== 'undefined') {
            renderSelectedYearRange = <span className="mb-0">{ReturnDropdown[items._selectedYear].year}</span>
          }
        return (<Option value={ReturnDropdown[u].value} disabled={ReturnDropdown[u].disabled}>{ReturnDropdown[u].label}</Option>)
      } else {
        return null
      }
    });

    return (
      <React.Fragment>
        {fund && (
          <Row className="accounts-summary-holdings-card p-0 border-0" style={{ marginBottom: 12 }}>
            <Col span={16} className="name-block basket-trade-name-block p-0">
              <Row className="ticker-block">
                <h5 className="mb-0 primary-color">{fund.ticker_short || fund.ticker}</h5>
                <h3 className="mb-0 tx-c2 holdings-weight-font-size">{_marketValue}</h3>
                <div className="pb-1">
                  {imageValidPath ? (
                    <img src={fund.sponsor ? sponsorLogo(fund.sponsor) : ''} className="img-fluid" alt={fund.sponsor} style={{ height: 17 }} />
                  ) : (
                    <div className="sponsor-logo-placeholder" title={fund.sponsor} style={{ width: '100%', height: 20, lineHeight: '20px' }}>{fund.sponsor || ''}</div>
                  )}
                </div>
              </Row>
              <Row className="basket-trade-button-wrapper">
                <span className={`time-Unit p-0 ${renderView === 'myHoldings' && 'selected'}`} onClick={() => this.viewHandler('myHoldings')}>My Holdings</span>
                {(!returnSeriesNotPresent) && (
                  <>
                    <i className="fal fa-minus fa-rotate-90"></i>
                    <span
                      className={`time-Unit p-0 ${renderView === 'securityReturns' && 'selected'} ${returnSeriesNotPresent ? 'disabled' : ''}`}
                      title={`${returnSeriesNotPresent ? 'Returns not available' : ''}`}
                      onClick={() => !returnSeriesNotPresent ? this.viewHandler('securityReturns') : null }
                    >
                      Returns
                    </span>
                  </>
                )}
              </Row>
            </Col>
            <Col id="security-range-filter" span={8} className="d-flex flex-column justify-content-end align-items-end time-Unit">
              {(renderView === 'securityReturns') ? (
                (ReturnDropdown && Object.keys(ReturnDropdown).length > 0) && (
                  <>
                    <Select
                      className="text-blue"
                      style={{ width: '100%' }}
                      bordered={false}
                      value={items._selectedYear}
                      getPopupContainer={() => document.getElementById('security-range-filter')}
                      onChange={this.selectReturnYear}
                      suffixIcon={<CaretDownOutlined />}
                    >
                      {renderOptions}
                    </Select>
                    {renderSelectedYearRange}
                  </>
                )
              ) : (
                <div>
                  <span style={{ height: 20 }}></span>
                  {enableBackButton && (<span className="mb-0 selected" onClick={this.onBackClick} style={{ cursor: 'pointer' }}>back</span>)}
                </div>
              )}
            </Col>
          </Row>
        )}
        {(renderView === 'securityReturns') ? (
          <div className="d-flex justify-content-center align-items-center" style={{ width: '100%', height: '82%' }}>
            {(!items || items.returns.length <= 0) ? <NoData height={height+'px'} /> : <SecurityReturnsChart height={height || 460} fund={items} />}
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center" style={{ width: '100%', height: '82%' }}>
            {(!TREE_MAP_DATA || !TREE_MAP_DATA.length) ? (
              <NoData height={height+'px'} />
            ) : (
              <div className="w-100" style={{ minHeight: height || 460, height: height || 460 }}>
                <TreeMapChart
                  treeMapData={TREE_MAP_DATA}
                  onOthersClick={this.onOthersClick}
                />
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default {
  BasketHoldings,
};

const SecurityReturnsChart = ({ fund, height }) => {
  let last = fund._series[fund._series.length-1];
  let series = [ {
    name: fund.ticker,
    data: fund._series,
    label: `$${Math.round(last.cuml+100)}`
  } ];

  return (
      <div className="w-100" style={{ minHeight: height, height }}>
        <DualColorLineChart series={series} ycentered={true} xkey="d" ykey="cumlLog" ykeyLabel="v" vkey="v" />
        {/* v for change from last position
        cuml for change from start */}
      </div>
  )
}

import React from 'react';
import { connect } from 'react-redux';
import { getNestDefault } from 'Utils'
import { NoData } from './Common'
import { DualColorLineChart } from 'components/charts/LineCharts'

class SecurityReturns_ extends React.Component {
  render() {
    const { items, height } = this.props;
    const startDate = items && items._series[1].d;
    const endDate = items && items._series[items._series.length - 1].d;
    const ticker = getNestDefault(['ticker'], items, '');
    return (
      <React.Fragment>
        <h5 className="mb-1 text-heading font-one">Security Returns <span className="ml-1 tx-c1">{`${ticker} (${startDate} - ${endDate})`}</span></h5>
        { !items || items.returns.length <= 0
          ? <NoData height={height+'px'} />
        : <SecurityReturnsChart height={height || 225} fund={items} />
        }
      </React.Fragment>
    )
  }
}

export const SecurityReturns = connect(
  state => ({
    // activeFund: getInvestmentActiveFundStats(state),
  }),
  {
  }
)(SecurityReturns_);

const SecurityReturnsChart = ({ fund, height }) => {
  let last = fund._series[fund._series.length-1];
  let series = [ {
    name: fund.ticker,
    data: fund._series,
    label: `$${Math.round(last.cuml+100)}`
  } ];

  return (
      <div className="" style={{ minHeight: height, height }}>
        <DualColorLineChart series={series} ycentered={true} xkey="d" ykey="cumlLog" ykeyLabel="v" vkey="v" />
        {/* v for change from last position
        cuml for change from start */}
      </div>
  )
}

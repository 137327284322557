import React from 'react';
import { Cell, PieChart, Pie } from 'recharts';

const PercentageChart = ({ value, min, max, pc, minWidth }) => {
  const width = minWidth || 100;
  const colors = ['#1e5f91', '#dddddd'];
  const values = value && value < 101 ? [value, 100 - value] : [100, 0];
  const colorData = colors.map((e,i) => ({ value: values[i], color: e }));

  const pieProps = {
      startAngle: 180, endAngle: 0,
      cx: width / 2, cy: width / 2
  };

  const pieRadius = {
    innerRadius: (width / 2) * 0.77,
    outerRadius: (width / 2) * 0.95
  };

  return (
    <PieChart width={minWidth || width} height={(width / 2) + 5} margin={{top: 0, right: 0, left: 0, bottom: 0}} aspect={1.5}>
        <Pie data={colorData} dataKey="value" fill="#8884d8" isAnimationActive={false}
            { ...pieRadius } { ...pieProps } >
            { colorData.map((entry, index) => (
                    <Cell key={`cell-${index}`} stroke={colorData[index].color} fill={colorData[index].color} />
                )) }
        </Pie>
    </PieChart>
  );
};

export default PercentageChart;

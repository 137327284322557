import { takeEvery, fork, call, put, all, select, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import qs from 'query-string';
import get from 'lodash/get';
import { reverse, uniqBy } from 'lodash';
import { push } from 'connected-react-router';
import moment from 'moment';

import ActionTypes from 'action-types';
import * as actions from 'actions/sfm';
// import * as fundActions from 'actions/funds';
import * as fundActions from 'actions/discovery';
import * as accountsActions from 'actions/accounts';
import * as sfmAPI from 'apis/sfm';
import * as discoveryAPI from 'apis/discovery';
import * as accountsAPI from 'apis/account';
import * as profileAPI from 'apis/profile';
import * as enhacerAPI from 'apis/enhancerAPIs';
import * as profileActions from 'actions/profile';
// import { getFundsData } from 'selectors/funds';
import { getFundsData } from 'selectors/discovery';

import { prepareHRSDataForFund, prepareSFMItemFundObject } from 'layouts/utils';
import {
  cn,
  CN,
  Universe,
  FilterAttrData,
  DefaultSortAttr,
  DateRange,
  ViewNames,
  MaxResult,
} from 'DataSet';
import {
  getNest,
  uniqValues,
  arrayToHash,
  getNestDefault,
  getImageOrFallback,
  addEventToAnalytics
} from 'Utils';
import Emitter from 'realTimePrice/emitter';

import { ALL_LENGTH } from '../layouts/WebDashboard/SFM/PrintPreviewModal/PrintPreviewModal'
import { UNASSIGNED,COMPLETED,ERROR,PENDING } from '../layouts/WebDashboard/SFM/utils'
import { getDiscoverFundsList } from 'layouts/utils';

const FileSaver = require('file-saver');

export function* updateReportForFundTracking(data) {
  const { payload } = data;
  const currDate = moment().format('MMMM YYYY');
  let isCompareOnly = payload.reports.isCompareOnly
  let isBackgroundDownload = payload.reports.isBackgroundDownload
  const reportName = isCompareOnly?`Selector Proposal – ${currDate}`:`Selector Proposal Detailed – ${currDate}`
  const entity = '-'
  const section = 'Selector'
  const report_type = isCompareOnly ? 'Compare Proposal' : 'Combine Proposal'
  // console.log({ isBackgroundDownload },payload.reports.printSelection.length)
  // console.log('bgDownloadStatus',yield select(state => state.sfm.bgDownloadStatus))
  try {
    if (payload.reports) {
      if(isBackgroundDownload){
        yield put(
          actions.setSFMState({
            bgDownloadStatus:PENDING,
            isCompareOnlyBG:isCompareOnly,
          })
        );
      }
      else{
        if(isCompareOnly){
          yield put(
            actions.setSFMState({
              bgDownloadStatus:UNASSIGNED,
              pdfReportBlob: '',
              reportLoaderCompareOnly: true,
            })
          );
        }
        else{
          yield put(
            actions.setSFMState({
              bgDownloadStatus:UNASSIGNED,
              pdfReportBlob: '',
              reportLoader: true,
            })
          );
        }
      }

      if(!isBackgroundDownload){
        //save print selection
        //no need to wait for response
        let reportPreferencePayload = {
          report_name:'selector',
          preferences:payload.reports.printSelection
        }
        try{
          yield call(profileAPI.saveSFMReportPrintSelection, reportPreferencePayload);
          yield put(profileActions.fetchProfile());
        }catch (e) {
          console.log('preference save failed, continuing the download')
          console.log(e)
        }
      }

      //  isBgDownloadSaved is always false for bg download report.
      //  set it true when bg download report is saved using PUT api with report id
      //  isBgDownloadSaved is always true for not bg download report
      const response = yield call(sfmAPI.reportUpload, {
        reports: JSON.stringify(payload.reports),
        reportName,
        entity,
        section,
        report_type,
        isBgDownloadSaved:!isBackgroundDownload,
      });
      if (response) {
        console.log('!!!!! REPORT API RESPONSE !!!!!!');
        // console.log(response);
        console.log(payload.reports.isBackgroundDownload,payload.reports.printSelection.length)
        const newBlob = new Blob([response.data], { type: response.headers['content-type'] });
        if(isBackgroundDownload){
          const latest_bgDownloadStatus = yield select(state => state.sfm.bgDownloadStatus);
          if (latest_bgDownloadStatus !== UNASSIGNED) {
            //store it in redux, and save once user click download
            yield put(
              actions.setSFMState({
                pdfReportBlob: newBlob,
                bgDownloadStatus: COMPLETED,
                x_report_id:response.headers['x-report-id']
              })
            );
          }
        }
        else{

          console.log('else')
          const firstName = yield select(state => state.auth.user.firstName) || '';
          const lastName = yield select(state => state.auth.user.lastName) || '';
          const fullName = firstName + ' ' + lastName;
          if(isCompareOnly){
            FileSaver.saveAs(newBlob, 'Selector Proposal – '+currDate+'.pdf');
            yield put(
              actions.setSFMState({
                pdfReportBlob:'',
                bgDownloadStatus:UNASSIGNED,
                reportPDFDownloadStatusCompareOnly: {
                  message: 'PDF Downloaded successfully',
                  downloadStatus: 'success',
                },
              })
            );
          }
          else{
            FileSaver.saveAs(newBlob, 'Selector Proposal Detailed – '+currDate+'.pdf');
            yield put(
              actions.setSFMState({
                pdfReportBlob:'',
                bgDownloadStatus:UNASSIGNED,
                reportPDFDownloadStatus: {
                  message: 'PDF Downloaded successfully',
                  downloadStatus: 'success',
                },
              })
            );
          }
        }
      }
      else {
        throw response
      }
      if(!isBackgroundDownload){
        if(isCompareOnly){
          yield put(
            actions.setSFMState({
              reportLoaderCompareOnly: false,
            })
          );
        }
        else{
          yield put(
            actions.setSFMState({
              reportLoader: false,
            })
          );
        }
      }
    }
  }
  catch (error) {
    console.log(error);
    const errorDetails = get(error, 'response.data', error.stack)
    console.log(errorDetails)
    if(!isBackgroundDownload){
      if(isCompareOnly){
        yield put(
          actions.setSFMState({
            pdfReportBlob:'',
            reportLoaderCompareOnly: false,
            reportPDFDownloadStatusCompareOnly: {
              message: 'Failed to download Report PDF file. Please email us at: help@magnifi.com',
              downloadStatus: 'danger',
            },
          })
        );
      }
      else {
        yield put(
          actions.setSFMState({
            pdfReportBlob:'',
            reportLoader: false,
            reportPDFDownloadStatus: {
              message: 'Failed to download Report PDF file. Please email us at: help@magnifi.com',
              downloadStatus: 'danger',
            },
          })
        );
      }
    }
    else{
      yield put(
        actions.setSFMState({
          isCompareOnlyBG:isCompareOnly,
          bgDownloadStatus: ERROR,
        })
      );
    }
    // yield put(actions.failedToPerform(errorDetails))
  }
}

export function* saveBgDownloadSFM(){
  console.log()
  console.log('saveBgDownloadSFM saga')

  const currDate = moment().format('MMMM YYYY');
  const firstName = yield select(state => state.auth.user.firstName) || '';
  const lastName = yield select(state => state.auth.user.lastName) || '';
  const fullName = firstName + ' ' + lastName;

  const isCompareOnly = yield select(state => state.sfm.isCompareOnlyBG)||false;
  const bgDownloadStatus = yield select(state => state.sfm.bgDownloadStatus);
  console.log({ bgDownloadStatus })
  if(bgDownloadStatus===COMPLETED){
    const id = yield select(state => state.sfm.x_report_id);

    console.log('saving by saga')
    //downloaded
    //update false flag to true
    console.log('dd')
    yield call(sfmAPI.reportUploadPUT, {
      report_id:id,
      isBgDownloadSaved:true,
    })
    const newBlob = yield select(state => state.sfm.pdfReportBlob)||'';
    if(isCompareOnly){
      FileSaver.saveAs(newBlob, 'Selector Proposal – '+currDate+'.pdf');
      yield put(
        actions.setSFMState({
          pdfReportBlob:'',
          bgDownloadStatus:UNASSIGNED,
          reportPDFDownloadStatusCompareOnly: {
            message: 'PDF Downloaded successfully',
            downloadStatus: 'success',
          },
        })
      );
    }
    else{
      FileSaver.saveAs(newBlob, 'Selector Proposal Detailed – '+currDate+'.pdf');
      yield put(
        actions.setSFMState({
          pdfReportBlob:'',
          bgDownloadStatus:UNASSIGNED,
          reportPDFDownloadStatus: {
            message: 'PDF Downloaded successfully',
            downloadStatus: 'success',
          },
        })
      );
    }
  }
  else if(bgDownloadStatus===PENDING){
    //currently downloading
    console.log('show loading and handing over to ui save')
    if(isCompareOnly){
      yield put(
        actions.setSFMState({
          reportLoaderCompareOnly: true,
        })
      );
    }
    else{
      yield put(
        actions.setSFMState({
          reportLoader: true,
        })
      );
    }
  }
  // else if(bgDownloadStatus==='error'){
  //   //download error
  //   if(isCompareOnly){
  //     yield put(
  //       actions.setSFMState({
  //         pdfReportBlob:'',
  //         bgDownloadStatus:'',
  //         reportLoaderCompareOnly: false,
  //         reportPDFDownloadStatusCompareOnly: {
  //           message: 'Failed to download Report PDF file. Please email us at: help@magnifi.com',
  //           downloadStatus: 'danger',
  //         },
  //       })
  //     );
  //   }
  //   else {
  //     yield put(
  //       actions.setSFMState({
  //         pdfReportBlob:'',
  //         bgDownloadStatus:'',
  //         reportLoader: false,
  //         reportPDFDownloadStatus: {
  //           message: 'Failed to download Report PDF file. Please email us at: help@magnifi.com',
  //           downloadStatus: 'danger',
  //         },
  //       })
  //     );
  //   }
  // }
  // else{
    //fail-safe
    //not found anything or cancelled
    //don't do anything
  // }
}

export function* toggleModal(data) {
  const { payload } = data
  try {
    yield put(
      actions.toggleModal({
        payload,
      })
    );
  } catch (error) {
    const errorDetails = get(error, 'response.data', error.stack)
    console.log(errorDetails)
    yield put(actions.failedToPerform(errorDetails))
  }
}

export function* getSelectedFunds(data) {
  const { payload } = data
  try {
    yield put(
      actions.toggleModal({
        payload,
      })
    );
  } catch (error) {
    const errorDetails = get(error, 'response.data', error.stack)
    yield put(actions.failedToPerform(errorDetails))
  }
}

function* getFunds(action) {
  try {
    const { payload } = action
    // const response = yield call(AuthApi.authenticate, qs.stringify(payload))
    yield put(actions.getFunds(payload))
  } catch (error) {
    const errorDetails = get(error, 'response.data', error.stack)
    yield put(actions.failedToPerform(errorDetails))
  }
}

export function* fetchUserPortFolio(action) {
  const account_no = action.payload;
  const myPortfolio = yield call(accountsAPI.getPortfolioResultAPI, account_no);
  yield put(actions.userPortfolioSuccess(Array.isArray(myPortfolio) ? myPortfolio : null));
}

// V-2
function* addSFMItemTicker(action) {
  try {
    const { payload } = action;
    if (payload && payload.selectedItemTickers) {
      let selectedType = payload.selectedType || 'buy';
      /**
       * My portfolio fecth addded to access portfolio
       */
      const profileState = yield select(state => state.profile.profile);
      const myportfolio = yield select(state => state.sfm.myportfolio);
      // console.log('== profileState ==> ', profileState);
      if(!myportfolio && profileState.apex && profileState.apex.account && profileState.apex.account.length && profileState.apex.account[0].account_no){
        const myPortfolio = yield call(accountsAPI.getPortfolioResultAPI, `MAGNIFI_${profileState.apex.account[0].account_no}`);
        yield put(actions.userPortfolioSuccess(myPortfolio));
      }
      yield put(actions.addSFMItemTickersFetch())
      // NOTE: Step 1: Add item -> Ticker in SFM ->`selectedItemTickers`
      // Step 2: using ticker name SFM look into FUNDS reducer >
      // if ticker already present in funds reducer state then use that data else Call API to fetch ticker data in SFM
      // NOTE: if sfm > Items already has data do not call api.
      const reducerFundsData = yield select(getFundsData);
      const foundInFundsData = reducerFundsData.filter((item) => payload.selectedItemTickers.includes(item.ticker));
      let realTimePriceSocket = [];
      let selectedItemTickers = [];

      // NOTE: ======= creating socket connection =======
      // console.log(payload.selectedItemTickers, typeof payload.selectedItemTickers);
      if (typeof payload.selectedItemTickers === 'string') {
        selectedItemTickers.push(payload.selectedItemTickers);
      } else if (typeof payload.selectedItemTickers === 'array') {
        selectedItemTickers = [...payload.selectedItemTickers]
      }
      // removed below "ANKIT" if not testing
      // selectedItemTickers.push("ANKIT");
      // console.log('=== selectedItemTickers ===', selectedItemTickers, typeof selectedItemTickers);
      Emitter.emit('SUBSCRIBE_TO_STREAM', selectedItemTickers);
      // console.log('Subscribed');
      // NOTE: ======= created connection =======

      if (foundInFundsData && foundInFundsData.length > 0) {
        // Found ticker in Search Results Data then run mapping function to prepare SFM related data
        console.log('======>>>>> Found ticker in Search Results Data <<<<<======');
        yield put(actions.addSFMItemTickersSuccess({
          selectedItemTickers: payload.selectedItemTickers,
          selectedFund: foundInFundsData,
          selectedType,
          realTimePriceSocket,
          autoOpenModal: payload.autoOpenModal,
        }));

        const _selectedItemTickers = yield select(state => state.sfm.selectedItemTickers);

        yield put(fundActions.updatedFundsSelectorState({
          selectedFunds: _selectedItemTickers,
          selectedType,
          selectedItemTickers: payload.selectedItemTickers,
          realTimePriceSocket,
        }));
       
        yield put(accountsActions.addFundFromAccountHoldingsToSFM({
          selectedFunds: _selectedItemTickers,
          selectedType,
          realTimePriceSocket,
        }));

      } else {
        console.log('======>>>>> NO SEARCH RESULTS DATA FOUND <<<<<======');
        console.log(`-- call an api to fetch '${payload.selectedItemTickers}' ticker data for SFM --`);

        // NOTE: API calls for TICKER to load SFM data.
        // asyn calls: [ fetchFundByTicker] for selected items.
        yield put(actions.getSFMItemDetailsRequest());
        yield put(actions.getSFMItemDetailsFetch());

        // using 'getEnhancedCardAPI' from enahcner's => specify-custom-replacement
        let [ customSearchTickerData ] = yield all([
          call(enhacerAPI.getEnhancedCardAPI, { ticker: payload.selectedItemTickers, overlap: payload.selectedItemTickers }),
        ]);
        // console.log(customSearchTickerData);

        try {
          let { result } = customSearchTickerData;
          let funds = [], _responseDataRange = {
            start: moment(DateRange.start),
            end: moment(DateRange.end),
          };

          if (typeof result !== 'undefined' && result) {
            let { funds: _funds, date_range } = result;
            if (typeof _funds !== 'undefined' && _funds && typeof date_range !== 'undefined' && date_range) {
              funds = _funds.slice(0, MaxResult); // _funds["funds"].slice(0, MaxResult);
              _responseDataRange = {
                start: (date_range && date_range.start) ? moment(new Date(`01 ${date_range.start}`)) : moment(DateRange.start),
                end: moment(new Date(`01 ${date_range.end}`)),
              }
            }
          }

          if (funds.length <= 0)
            return { funds: [], categories: [], range: _responseDataRange, filterAttrData: {} };

          let ticks = uniqValues(funds, 'ticker').join(',');

          // update Accounts > Summary > PortfolioFunds > _selector key state
          yield put(accountsActions.addFundFromAccountHoldingsToSFM({
            selectedFunds: payload.selectedItemTickers,
            selectedType,
            realTimePriceSocket,
          }));

           // commented below dispatch, because SCORE was not getting calculated for Compare tab
          // yield put(actions.addSFMItemTickersSuccess({
          //   selectedItemTickers: payload.selectedItemTickers,
          //   selectedFund: funds,
          //   selectedType,
          // }));
          // yield call(delay, 600);

          // NOTE: API calls for TICKER to load SFM data.
          // asyn calls: [ category, charts-api, return-charts ] for selected items.
          // added getSelectorAttrsData api to solve compare score value issue
          let [ returns, categories, charts, selectorAttrsData ] = yield all([
            call(discoveryAPI.fetchReturnsDataV1, { tickers: payload.selectedItemTickers }),
            call(discoveryAPI.fetchCategories, { tickers: payload.selectedItemTickers }),
            call(discoveryAPI.fetchChartsV1, { tickers: payload.selectedItemTickers }),
            call(discoveryAPI.getSelectorAttrsData, { tickers: payload.selectedItemTickers })
          ]);

          const mappedSFMItem = prepareSFMItemFundObject({ returns, categories, charts, funds, selectorAttrsData });

          if (mappedSFMItem) {
            let { funds, categories, filteredAttrData } = mappedSFMItem;

            funds.forEach((kl, i) => {
              if (kl.ticker === payload.selectedItemTickers) {
                kl._selectedType = selectedType;
              }
            });

            funds = uniqBy(funds, 'ticker');
            // console.log(funds);
            yield put(actions.getSFMItemDetailsSuccess({
              funds,
              categories,
              selectedItemTickers: payload.selectedItemTickers,
              realTimePriceSocket,
            }));

            yield put(actions.addSFMItemTickersSuccess({
              selectedItemTickers: payload.selectedItemTickers,
              selectedFund: funds,
              selectedType,
              realTimePriceSocket,
              autoOpenModal: payload.autoOpenModal,
            }));

          } else {
            throw '-- FAILED to map ticker data for sfm, check api response --';
          }
        } catch (error) {
          console.log(error);
          const errorDetails = get(error, 'response.data', error.stack)
          console.log(errorDetails)
          if(errorDetails){
            yield put(actions.addSFMItemTickersSuccess({
              pageCrash: errorDetails,
            }));
            yield put(actions.getSFMItemDetailsSuccess({
              pageCrash: errorDetails,
            }));
          } else {
            yield put(actions.addSFMItemTickersSuccess({
              pageCrash: '-- Failed to process --',
            }));
            yield put(actions.getSFMItemDetailsSuccess({
              pageCrash: '-- Failed to process --',
            }));
          }
        }
      }

      yield put(actions.updateHRSDataRequest());

      // after delay execute HRS mapping
      yield call(delay, 600);
      // asyn calls: fetchTopTenHoldings for selected items.
      // yield put(actions.updateHRSDataRequest());
      let funds = [], holdings = [];
      const ticker = payload.selectedItemTickers;
      yield put(actions.updateHRSDataFetch());
      [ holdings ] = yield all([
        call(discoveryAPI.fetchTopTenHoldings, { tickers: ticker }),
      ]);
      if (holdings && holdings.funds) {
        holdings = holdings.funds.find(e => cn(e, 'Ticker') === ticker)
        const sfmItems = yield select(state => state.sfm.items);
        const card = sfmItems.find((l) => l.ticker === ticker);
        if (card) {
          let mappedData = prepareHRSDataForFund({ holdings, card });
          yield put(actions.updateHRSDataSuccess({
            HRSdata: mappedData,
            forTicker: ticker,
          }));
        } else {
          console.log('-- For mapping holdings data ticker not found in sfm items --');
          yield put(actions.updateHRSDataSuccess({
            HRSdata: {
              holdingData: [],
              sectorData: [],
              regionData: [],
            },
            forTicker: ticker,
          }));
        }
      } else {
        yield put(actions.updateHRSDataSuccess({
          HRSdata: {
            holdingData: [],
            sectorData: [],
            regionData: [],
          },
          forTicker: ticker,
        }));
      }
    } else {
      throw '-- Payload missing --'
    }
  } catch (error) {
    const errorDetails = get(error, 'response.data', error.stack)
    console.log(errorDetails)
    if(errorDetails){
      yield put(actions.addSFMItemTickersSuccess({
        pageCrash: errorDetails,
      }));
    } else {
      yield put(actions.addSFMItemTickersSuccess({
        pageCrash: '-- Failed to process --',
      }));
    }
  }
}

function* removeSFMItemTicker(action) {
  try {
    const { payload } = action;
    if (payload && payload.removeTicker) {
      let selectedType = payload.selectedType || 'buy';
      yield put(actions.removeSFMItemTickersFetch());

      // NOTE: UNSUBSCRIBE_STREAM or disconnect socket for deselected tickers
      console.log('=== payload.removeTicker ===', [payload.removeTicker]);
      if (payload.removeTicker) {
        Emitter.emit('UNSUBSCRIBE_STREAM', [payload.removeTicker]);
      }

      yield put(actions.removeSFMItemTickersSuccess({
        removeTicker: payload.removeTicker,
        selectedType,
      }))
      // update Funds > Data > _selector key state
      yield put(fundActions.removeFundsSelectorState({
        unSelectTicker: payload.removeTicker,
        selectedType,
      }))
      // update Accounts > Summary > PortfolioFunds > _selector key state
      yield put(accountsActions.removeFundsSelectorState({
        unSelectTicker: payload.removeTicker,
        selectedType,
      }))
    } else {
      throw '-- Payload missing --'
    }
  } catch (error) {
    console.log(error);
    yield put(actions.removeSFMItemTickersSuccess({
      pageCrash: '-- Failed to process --',
    }));
  }
}

function* deleteAllSFMItems(action) {
  try {
    const { payload, callback } = action;
    yield delay(700)

    // NOTE: UNSUBSCRIBE_STREAM or disconnect socket for all tickers
    const ___SELECTED_ITEM_TICKERS = yield select(state => state.sfm.selectedItemTickers);
    const unsubscribeTickers = ___SELECTED_ITEM_TICKERS.map((h) => h.ticker)
    console.log('===== unsubscribeTickers =====', unsubscribeTickers);
    if (unsubscribeTickers) {
      Emitter.emit('UNSUBSCRIBE_STREAM', unsubscribeTickers);
    }

    yield put(actions.deleteAllSFMItemTickersFetch());
    yield put(fundActions.removeAllFundsSelectorState());
    yield put(accountsActions.removeAllFundsSelectorState());
    yield put(actions.deleteAllSFMItemTickersSuccess(payload));
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(actions.deleteAllSFMItemTickersSuccess({
      pageCrash: '-- Failed to process --',
    }));
  }
}

function* setSFMFundSelectionType(action) {
  try {
    const { payload, callback } = action;
    yield put(actions.setFundSelectionTypeFetch());

    yield put(fundActions.setFundSelectionType(payload));
    yield put(accountsActions.setFundSelectionType(payload));

    yield put(actions.setFundSelectionTypeSuccess(payload));
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(actions.deleteAllSFMItemTickersSuccess({
      pageCrash: '-- Failed to process --',
    }));
  }
}

function* executeMagnifiBrokerOrders(action) {
  const { payload, callback } = action;
  console.log(payload)
  try{
    if (payload && payload.data) {
      yield put(actions.executeMagnifiOrderFetch())
      window.sessionStorage.removeItem('buy');
      const response = yield call(discoveryAPI.executeTradeOrder, payload)
      console.log(response);
      if(response) {
        yield put(actions.executeMagnifiOrderSuccess({
          sfmTradeOrderResponse: {
            response,
            payload,
            error: false,
          },
        }));
        yield put(actions.deleteAllSFMItemTickersRequest({
          sfmTradeOrderResponse: {
            response,
            payload,
            error: false,
          },
        }));
        window.sessionStorage.removeItem('buy');
      } else {
        yield put(actions.executeMagnifiOrderSuccess({
          sfmTradeOrderResponse: {
            response,
            payload,
            error: true
          }
        }));
      }
      if (callback && typeof callback === 'function') {
        callback();
      }
    } else {
      throw '-- Payload missing --'
    }
  } catch (error) {
    const errorDetails = get(error, 'response.data', error.stack)
    if(errorDetails) {
      console.log(errorDetails)
      yield put(actions.executeMagnifiOrderSuccess({
        sfmTradeOrderResponse: {
          response: errorDetails,
          payload: payload.data,
          error: true,
        },
        pageCrash: errorDetails,
      }));
    } else {
      console.log(error)
      yield put(actions.executeMagnifiOrderSuccess({
        sfmTradeOrderResponse: {
          response: error,
          payload: payload.data,
          error: true,
        },
        pageCrash: '-- Failed to process --',
      }));
    }
    if (callback && typeof callback === 'function') {
      callback();
    }
  }
}

function* addTickerToSFMCart(action) {
  try {
    const { payload } = action;
    if (payload && payload.selectedItemTickers) {
      let selectedType = payload.selectedType || 'buy';

      try {
        /**
         * My portfolio fecth addded to access portfolio
         */
        const profileState = yield select(state => state.profile.profile);
        const myportfolio = yield select(state => state.sfm.myportfolio);
        // console.log('== profileState ==> ', profileState);
        if(!myportfolio && profileState.apex && profileState.apex.account && profileState.apex.account.length && profileState.apex.account[0].account_no) {
          const myPortfolio = yield call(accountsAPI.getPortfolioResultAPI, `MAGNIFI_${profileState.apex.account[0].account_no}`);
          yield put(actions.userPortfolioSuccess(myPortfolio));
        }
      } catch (e) {
        console.log(e);
      }

      yield put(actions.addTickersToCartFetch())
      // NOTE: Step 1: Add item -> Ticker in SFM ->`selectedItemTickers`
      // Step 2: using ticker name SFM look into FUNDS reducer >
      // if ticker already present in funds reducer state then use that data else Call API to fetch ticker data in SFM
      // NOTE: if sfm > Items already has data do not call api.
      const reducerFundsData = yield select(getFundsData);
      const getOnlyTickerArray = reducerFundsData.map((kl) => kl.ticker) || [];
      // console.log(' --- getOnlyTickerArray ---', getOnlyTickerArray);

      const foundInFundsData = reducerFundsData.filter((kl) => payload.selectedItemTickers.split(',').includes(kl.ticker));

      console.log(foundInFundsData);
      if (foundInFundsData && foundInFundsData.length > 0) {
        // Found ticker in Search Results Data then run mapping function to prepare SFM related data
        console.log('======>>>>> Found ticker in Search Results Data <<<<<======');

        // yield put(actions.addTickersToCartSuccess({
        //   selectedItemTickers: foundInFundsData.map((jk) => jk.ticker), // payload.selectedItemTickers,
        //   selectedFund: foundInFundsData,
        //   buyFromURL: payload.buyFromURL,
        //   selectedType,
        // }));

        let _selectedItemTickers = yield select(state => state.sfm.selectedItemTickers);
        // update Funds > Data > _selector key state
        if (Object.prototype.toString.call(payload.selectedItemTickers) === '[object Array]') {
          console.log('selectedItemTickers is in array');
          _selectedItemTickers = [...payload.selectedItemTickers, ..._selectedItemTickers];
        } else if (typeof payload.selectedItemTickers === 'string') {
          let _tickers = payload.selectedItemTickers.split(',');
          _selectedItemTickers = [..._tickers, ..._selectedItemTickers];
        } else {
          _selectedItemTickers = payload.selectedItemTickers;
        }
        
        Emitter.emit('SUBSCRIBE_TO_STREAM', _selectedItemTickers);

        console.log(_selectedItemTickers, typeof _selectedItemTickers);
        yield put(fundActions.updatedFundsSelectorState({
          selectedFunds: _selectedItemTickers,
          selectedType,
        }));
       // update Accounts > Summary > PortfolioFunds > _selector key state
        yield put(accountsActions.addFundFromAccountHoldingsToSFM({
          selectedFunds: _selectedItemTickers,
          selectedType,
        }));

        yield put(actions.addTickersToCartSuccess({
          selectedItemTickers: foundInFundsData.map((jk) => jk.ticker), // payload.selectedItemTickers,
          selectedFund: foundInFundsData,
          buyFromURL: payload.buyFromURL,
          selectedType,
        }));

        // asyn calls: fetchTopTenHoldings for selected items.
        yield put(actions.updateHRSDataRequest());
        // after delay execute HRS mapping
        yield call(delay, 600);

        let funds = [], holdings = [];
        // const ticker = payload.selectedItemTickers;
        const sfmItems = yield select(state => state.sfm.items);
        const _sfmItemsWithoutHRS = sfmItems.filter((l) => (typeof l._holdingData === 'undefined' ||
                                                          typeof l._regionData === 'undefined' ||
                                                          typeof l._sectorData === 'undefined') || (
                                                            l._holdingData && l._holdingData.length === 0 ||
                                                            l._regionData && l._regionData.length === 0 ||
                                                            l._sectorData && l._sectorData.length === 0
                                                          )) || [];
        console.log('--- _sfmItemsWithoutHRS ---', _sfmItemsWithoutHRS);
        let _sfmItemsWithoutHRSTicker = _sfmItemsWithoutHRS.map((y) => y.ticker);
        _sfmItemsWithoutHRSTicker = _sfmItemsWithoutHRSTicker.toString();
        yield put(actions.updateHRSDataFetch());
        [ holdings ] = yield all([
          call(discoveryAPI.fetchTopTenHoldings, { tickers: _sfmItemsWithoutHRSTicker }),
        ]);
        if (holdings && holdings.funds && typeof holdings.funds !== 'undefined' && _sfmItemsWithoutHRS && _sfmItemsWithoutHRS.length) {
          for (var i = 0; i < _sfmItemsWithoutHRS.length; i++) {
            if (_sfmItemsWithoutHRS[i] && holdings && typeof holdings.funds !== 'undefined') {
              let card = _sfmItemsWithoutHRS[i];
              let ticker = _sfmItemsWithoutHRS[i].ticker;
              holdings = holdings.funds.find(e => cn(e, 'Ticker') === ticker);
              if (holdings) {
                if (card) {
                  let mappedData = prepareHRSDataForFund({ holdings, card });
                  yield put(actions.updateHRSDataSuccess({
                    HRSdata: mappedData,
                    forTicker: ticker,
                  }));
                } else {
                  console.log('-- For mapping holdings data ticker not found in sfm items --', ticker);
                  let __discoveryFundsState = yield select(state => state.discovery.data);
                  let __foundInDiscoveryFunds = __discoveryFundsState.find((l) => l.ticker === ticker);
                  if (__foundInDiscoveryFunds) {
                    let _mappedData = prepareHRSDataForFund({ holdings, card: __foundInDiscoveryFunds });
                    yield put(actions.updateHRSDataSuccess({
                      HRSdata: _mappedData,
                      forTicker: ticker,
                    }));
                  } else {
                    console.log('-- For mapping holdings data ticker not found in Discovery Funds as well --', ticker);
                    yield put(actions.updateHRSDataSuccess({
                      HRSdata: {
                        holdingData: [],
                        sectorData: [],
                        regionData: [],
                      },
                      forTicker: ticker,
                    }));
                  }
                }
              } else {
                console.log('-- holdings data not found in api response --', holdings);
                yield put(actions.updateHRSDataSuccess({
                  HRSdata: {
                    holdingData: [],
                    sectorData: [],
                    regionData: [],
                  },
                  forTicker: ticker,
                }));
              }
            } else {
              yield put(actions.updateHRSDataSuccess({
                HRSdata: {
                  holdingData: [],
                  sectorData: [],
                  regionData: [],
                },
                forTicker: _sfmItemsWithoutHRSTicker,
              }));
            }
          }
        } else {
          yield put(actions.updateHRSDataSuccess({
            HRSdata: {
              holdingData: [],
              sectorData: [],
              regionData: [],
            },
            forTicker: _sfmItemsWithoutHRSTicker,
          }));
        }
      } else {
        console.log('======>>>>> NO SEARCH RESULTS DATA FOUND <<<<<======');
        console.log(`-- call an api to fetch '${payload.selectedItemTickers}' ticker data for SFM --`);

        // // NOTE: API calls for TICKER to load SFM data.
        // // asyn calls: [ fetchFundByTicker] for selected items.
        // yield put(actions.getSFMItemDetailsRequest());
        // yield put(actions.getSFMItemDetailsFetch());
        //
        // // using 'getEnhancedCardAPI' from enahcner's => specify-custom-replacement
        // let [ customSearchTickerData ] = yield all([
        //   call(enhacerAPI.getEnhancedCardAPI, { ticker: payload.selectedItemTickers, overlap: payload.selectedItemTickers }),
        // ]);
        // // console.log(customSearchTickerData);
        //
        // try {
        //   let { result } = customSearchTickerData;
        //   let funds = [], _responseDataRange = {
        //     start: moment(DateRange.start),
        //     end: moment(DateRange.end),
        //   };
        //
        //   if (typeof result !== 'undefined' && result) {
        //     let { funds: _funds, date_range } = result;
        //     if (typeof _funds !== 'undefined' && _funds && typeof date_range !== 'undefined' && date_range) {
        //       funds = _funds.slice(0, MaxResult); // _funds["funds"].slice(0, MaxResult);
        //       _responseDataRange = {
        //         start: (date_range && date_range.start) ? moment(new Date(`01 ${date_range.start}`)) : moment(DateRange.start),
        //         end: moment(new Date(`01 ${date_range.end}`)),
        //       }
        //     }
        //   }
        //
        //   if (funds.length <= 0)
        //     return { funds: [], categories: [], range: _responseDataRange, filterAttrData: {} };
        //
        //   let ticks = uniqValues(funds, 'ticker').join(',');
        //
        //   // update Accounts > Summary > PortfolioFunds > _selector key state
        //   yield put(accountsActions.addFundFromAccountHoldingsToSFM({
        //     selectedFunds: payload.selectedItemTickers,
        //     selectedType,
        //   }));
        //
        //    // commented below dispatch, because SCORE was not getting calculated for Compare tab
        //   // yield put(actions.addTickersToCartSuccess({
        //   //   selectedItemTickers: payload.selectedItemTickers,
        //   //   selectedFund: funds,
        //   //   selectedType,
        //   // }));
        //   // yield call(delay, 600);
        //
        //   // NOTE: API calls for TICKER to load SFM data.
        //   // asyn calls: [ category, charts-api, return-charts ] for selected items.
        //   // added getSelectorAttrsData api to solve compare score value issue
        //   let [ returns, categories, charts, selectorAttrsData ] = yield all([
        //     call(discoveryAPI.fetchReturnsDataV1, { tickers: payload.selectedItemTickers }),
        //     call(discoveryAPI.fetchCategories, { tickers: payload.selectedItemTickers }),
        //     call(discoveryAPI.fetchChartsV1, { tickers: payload.selectedItemTickers }),
        //     call(discoveryAPI.getSelectorAttrsData, { tickers: payload.selectedItemTickers })
        //   ]);
        //
        //   const mappedSFMItem = prepareSFMItemFundObject({ returns, categories, charts, funds, selectorAttrsData });
        //
        //   if (mappedSFMItem) {
        //     let { funds, categories, filteredAttrData } = mappedSFMItem;
        //     funds.forEach((kl, i) => {
        //       if (kl.ticker === payload.selectedItemTickers) {
        //         kl._selectedType = selectedType;
        //       }
        //     });
        //     funds = uniqBy(funds, 'ticker');
        //     // console.log(funds);
        //     yield put(actions.getSFMItemDetailsSuccess({
        //       funds,
        //       categories,
        //       selectedItemTickers: payload.selectedItemTickers,
        //     }));
        //
        //     yield put(actions.addTickersToCartSuccess({
        //       selectedItemTickers: payload.selectedItemTickers,
        //       selectedFund: funds,
        //       selectedType,
        //     }));
        //   } else {
        //     throw '-- FAILED to map ticker data for sfm, check api response --';
        //   }
        // } catch (e) {
        //   console.log(e);
        // }
      }
    } else {
      throw '-- Payload missing --'
    }
  } catch (error) {
    const errorDetails = get(error, 'response.data', error.stack)
    console.log(errorDetails)
    if(errorDetails){
      yield put(actions.addTickersToCartSuccess({
        pageCrash: errorDetails,
      }));
    } else {
      yield put(actions.addTickersToCartSuccess({
        pageCrash: '-- Failed to process --',
      }));
    }
  }
}

function* getPortfolioDetailsForSFM(action) {
  try {
    const { payload, callback } = action;
    if (payload && payload.accountNumber) {
      yield put(actions.getPortfolioDetailsForSFMFetch());
      const response = yield call(accountsAPI.getPortfolioResultAPI, `MAGNIFI_${payload.accountNumber}`);
      // console.log(' === myPortfolio response ===> ', response);
      yield put(actions.getPortfolioDetailsForSFMSuccess({
        myportfolio: response,
      }));
    } else {
      yield put(actions.getPortfolioDetailsForSFMSuccess());
    }
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(actions.getPortfolioDetailsForSFMSuccess({
      pageCrash: '-- Failed to process --',
    }));
  }
}

function* getCombineScoreRequest(action) {
  try {
    const { payload, callback } = action;
    if ( payload ) {
      yield put(actions.getCombineScoreFetch());
      const response = yield call(sfmAPI.getEnhancementScore, payload);
      yield put(actions.getCombineScoreSuccess({ enhacementScore: response.data }));
      if(callback && typeof callback === 'function'){
        callback();
      }
    }
  } catch (error) {
    console.log(error)
  } finally {

  }
}

function* sfmSaga() {
  yield all([
    fork(takeEvery, ActionTypes.TOGGLE_MODAL, toggleModal),
    fork(takeEvery, ActionTypes.GET_FUNDS, getFunds),
    fork(takeEvery, ActionTypes.GET_SELECTED_FUNDS, getSelectedFunds),
    fork(takeEvery, ActionTypes.UPDATE_FUNDS_TRACKING_REPORT, updateReportForFundTracking),
    fork(takeEvery, ActionTypes.SAVE_BG_DOWNLOAD, saveBgDownloadSFM),
    // V-2
    fork(takeEvery, ActionTypes.ADD_SFM_ITEM_TICKERS_REQUEST, addSFMItemTicker),
    fork(takeEvery, ActionTypes.REMOVE_SFM_ITEM_TICKERS_REQUEST, removeSFMItemTicker),
    fork(takeEvery, ActionTypes.DELETE_ALL_SFM_ITEM_TICKERS_REQUEST, deleteAllSFMItems),
    fork(takeEvery, ActionTypes.SET_SFM_FUND_SELECTION_TYPE_REQUEST, setSFMFundSelectionType),
    fork(takeEvery, ActionTypes.EXECUTE_MAGNIFI_BROKER_ORDERS_REQUEST, executeMagnifiBrokerOrders),
    fork(takeEvery, ActionTypes.ADD_TICKERS_TO_CART_REQUEST, addTickerToSFMCart),
    fork(takeLatest, ActionTypes.GET_USER_PORTFOLIO, fetchUserPortFolio),
    fork(takeEvery, ActionTypes.GET_PORTFOLIO_DETAILS_FOR_SFM_REQUEST, getPortfolioDetailsForSFM),

    fork(takeEvery, ActionTypes.GET_COMBINE_SCORE_REQUEST, getCombineScoreRequest),
  ])
}

export default sfmSaga;

import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Card } from 'antd';
import _ from 'lodash';

import { createLoadingSelector } from 'selectors/loading-selector';

import { customScroll } from '../../utils'
import SearchResult from './SearchResult';
import ResultPage from './ResultPage';
import EnhancePortfolio from './EnhancePortfolio';
import Sfm from './shared/Sfm';

import { getInvestmentWatchlistStats, getInvestmentPositionStats, getMarketFunds, getInvestmentMarketTickers, getPortfolioAccountStats } from 'selectors/investment'
import { getPortfolioResultSet, getPortfolioEnhanceTickerFilter } from 'selectors/portfolio'
import { portfolioQuery, setPortfolioReplace, setPortfolioState } from 'actions/portfolio';
import { investment } from 'reducers/investment';
import { uniqValues } from 'Utils';

class Content extends Component {
  constructor(props){
    super(props);

    this.state = {
      enhanceReplace: [],
      query: '',
      replacedTicker: '',
      enhanceTickerFilter: [],
      portfolioFunds: props.portfolioFunds,
      disableFor: ["low_cost_alternative_models", "low_risk_alternative_models", "high_yield_alternative_models", "high_sharpe_alternative_models"]
    }

  }

  componentDidMount() {
    const { view, query, portfolioTickers, watchlistFundstats, accountFundstats, marketTickers, portfolioCaching, investmentCaching, fundUniverse, modelPortfolioApiLoading, shareClass, similarityMeasures } = this.props;

    let ACCOUNT_FUND_STATS_TICKERS = accountFundstats.ticker;
    let WATCHLIST_FUND_STATS_TICKERS = watchlistFundstats.tickers;
    let MARKET_TICKERS = marketTickers;
    let PORTFOLIO_TICKERS = portfolioTickers;
    let QUERY = query;

    if (investmentCaching && typeof investmentCaching.portfolioTickers !== 'undefined') {
      PORTFOLIO_TICKERS = investmentCaching.portfolioTickers;
    }

    if (investmentCaching && typeof investmentCaching.marketTickers !== 'undefined') {
      MARKET_TICKERS = investmentCaching.marketTickers;
    }

    if (accountFundstats && typeof accountFundstats.tickers !== 'undefined') {
      ACCOUNT_FUND_STATS_TICKERS = accountFundstats.tickers;
    }

    if (watchlistFundstats && investmentCaching && typeof investmentCaching.view !== 'undefined' && typeof watchlistFundstats.tickers.tickers !== 'undefined') {
      console.log(watchlistFundstats.tickers, watchlistFundstats.tickers);
      WATCHLIST_FUND_STATS_TICKERS = watchlistFundstats.tickers;
    }

    // if (portfolioCaching && typeof portfolioCaching.enhanceReplace !== 'undefined') {
    //   QUERY = typeof portfolioCaching.query !== 'undefined' ? portfolioCaching.query : query;
    // }

    if (!modelPortfolioApiLoading) {
      if ((investmentCaching && typeof investmentCaching.view !== 'undefined' && investmentCaching.view === 'account') || (view === 'account')) {
        console.log(ACCOUNT_FUND_STATS_TICKERS);
        if (fundUniverse) {
          this.props.portfolioQuery({ tickers: uniqValues(ACCOUNT_FUND_STATS_TICKERS, 'ticker').join(','), prtQuery: QUERY, fundUniverse, shareClass, similarityMeasures })
        } else {
          this.props.portfolioQuery({ tickers: uniqValues(ACCOUNT_FUND_STATS_TICKERS, 'ticker').join(','), prtQuery: QUERY, shareClass, similarityMeasures })
        }
      } else if ((investmentCaching && typeof investmentCaching.view !== 'undefined' && investmentCaching.view === 'watchlist') || (view === 'watchlist')) {
        // console.log(WATCHLIST_FUND_STATS_TICKERS, watchlistFundstats.tickers, watchlistFundstats);
        if (fundUniverse) {
          this.props.portfolioQuery({ tickers: uniqValues(WATCHLIST_FUND_STATS_TICKERS, 'ticker').join(','), prtQuery: QUERY, fundUniverse, shareClass, similarityMeasures })
        } else {
          this.props.portfolioQuery({ tickers: uniqValues(WATCHLIST_FUND_STATS_TICKERS, 'ticker').join(','), prtQuery: QUERY, shareClass, similarityMeasures })
        }
      } else if ((investmentCaching && typeof investmentCaching.view !== 'undefined' && investmentCaching.view === 'mrktPl' ) || (view === 'mrktPl')) {
        // console.log(MARKET_TICKERS);
        if (fundUniverse) {
          this.props.portfolioQuery({ tickers: MARKET_TICKERS, prtQuery: QUERY, fundUniverse, shareClass, similarityMeasures })
        } else {
          this.props.portfolioQuery({ tickers: MARKET_TICKERS, prtQuery: QUERY, shareClass, similarityMeasures })
        }
      } else {
        if (fundUniverse) {
          this.props.portfolioQuery({ tickers: PORTFOLIO_TICKERS, prtQuery: QUERY, fundUniverse, shareClass, similarityMeasures })
        } else {
          this.props.portfolioQuery({ tickers: PORTFOLIO_TICKERS, prtQuery: QUERY, shareClass, similarityMeasures })
        }
      }
    }

  }

  componentWillReceiveProps(nextProps) {
    const { view, query, portfolioTickers, watchlistFundstats, accountFundstats, marketTickers, portfolioCaching, investmentCaching, enhancePortfolioVisibleState, fundUniverse, shareClass, similarityMeasures, searchTypeMode, selectedWeightOptions } = nextProps;

    let ACCOUNT_FUND_STATS_TICKERS = accountFundstats.ticker;
    let WATCHLIST_FUND_STATS_TICKERS = watchlistFundstats.tickers;
    let MARKET_TICKERS = marketTickers;
    let PORTFOLIO_TICKERS = portfolioTickers;
    let QUERY = query;
    let CODE = selectedWeightOptions.code;

    if (investmentCaching && typeof investmentCaching.portfolioTickers !== 'undefined') {
      PORTFOLIO_TICKERS = investmentCaching.portfolioTickers;
    }

    if (investmentCaching && typeof investmentCaching.marketTickers !== 'undefined') {
      MARKET_TICKERS = investmentCaching.marketTickers;
    }

    if (accountFundstats && typeof accountFundstats.tickers !== 'undefined') {
      ACCOUNT_FUND_STATS_TICKERS = accountFundstats.tickers;
    }

    if (watchlistFundstats && investmentCaching && typeof investmentCaching.view !== 'undefined' && typeof watchlistFundstats.tickers !== 'undefined') {
      WATCHLIST_FUND_STATS_TICKERS = watchlistFundstats.tickers;
    }
    // if (portfolioCaching && typeof portfolioCaching.enhanceReplace !== 'undefined') {
    //   QUERY = typeof portfolioCaching.query !== 'undefined' ? portfolioCaching.query : query;
    // }

    if (((this.props.selectedWeightOptions && this.props.selectedWeightOptions.code && this.props.selectedWeightOptions.code !== CODE) || this.props.searchTypeMode !== searchTypeMode || this.props.query !== QUERY || this.props.fundUniverse !== fundUniverse || this.props.shareClass !== shareClass || this.props.similarityMeasures !== similarityMeasures) && searchTypeMode === 'FUNDS' && QUERY !== '' && !enhancePortfolioVisibleState) {
      if ((investmentCaching && typeof investmentCaching.view !== 'undefined' && investmentCaching.view === 'account') || (view === 'account')) {
        // console.log(ACCOUNT_FUND_STATS_TICKERS);
        if (fundUniverse) {
          this.props.portfolioQuery({ tickers: uniqValues(ACCOUNT_FUND_STATS_TICKERS, 'ticker').join(','), prtQuery: QUERY, fundUniverse, shareClass, similarityMeasures })
        } else {
          this.props.portfolioQuery({ tickers: uniqValues(ACCOUNT_FUND_STATS_TICKERS, 'ticker').join(','), prtQuery: QUERY, shareClass, similarityMeasures })
        }
      } else if ((investmentCaching && typeof investmentCaching.view !== 'undefined' && investmentCaching.view === 'watchlist') || (view === 'watchlist')) {
        // console.log(WATCHLIST_FUND_STATS_TICKERS, watchlistFundstats.tickers);
        if (fundUniverse) {
          this.props.portfolioQuery({ tickers: uniqValues(WATCHLIST_FUND_STATS_TICKERS, 'ticker').join(','), prtQuery: QUERY, fundUniverse, shareClass, similarityMeasures })
        } else {
          this.props.portfolioQuery({ tickers: uniqValues(WATCHLIST_FUND_STATS_TICKERS, 'ticker').join(','), prtQuery: QUERY, shareClass, similarityMeasures })
        }

      } else if ((investmentCaching && typeof investmentCaching.view !== 'undefined' && investmentCaching.view === 'mrktPl') || (view === 'mrktPl')) {
        // console.log(MARKET_TICKERS);
        if (fundUniverse) {
          this.props.portfolioQuery({ tickers: MARKET_TICKERS, prtQuery: QUERY, fundUniverse, shareClass, similarityMeasures })
        } else {
          this.props.portfolioQuery({ tickers: MARKET_TICKERS, prtQuery: QUERY, shareClass, similarityMeasures })
        }
      } else {
        if (fundUniverse) {
          this.props.portfolioQuery({ tickers: PORTFOLIO_TICKERS, prtQuery: QUERY, fundUniverse, shareClass, similarityMeasures })
        } else {
          this.props.portfolioQuery({ tickers: PORTFOLIO_TICKERS, prtQuery: QUERY, shareClass, similarityMeasures })
        }
      }
    }

    // if((this.props.enhanceReplace != nextProps.enhanceReplace && nextProps.enhanceReplace.length <= 0) ||
    //    (nextProps.query && !this.state.disableFor.includes(nextProps.query) && nextProps.filterResultSet && nextProps.filterResultSet[0] && nextProps.filterResultSet[0].target_card && _.isEmpty(nextProps.filterResultSet[0].target_card) && nextProps.summaryDrawer)
    //   ){
    //     this.props.setPortfolioState({
    //       summaryDrawer: false
    //     });
    // }
    // this.setState({ enhanceReplace: nextProps.enhanceReplace, enhanceTickerFilter: nextProps.enhanceTickerFilter })
  }

  componentDidUpdate() {
    customScroll();
  }

  portfolioIdWillBe = (portfolioName) => {
    // const { portfolioList, portfolioId } = this.props;
    // console.log(portfolioName, portfolioId, 'Demo portfolio ???', portfolioName.includes('Demo'));
    // if (portfolioName.includes('Demo') && portfolioId) {
    //   return portfolioId;
    // } else if (portfolioList.length > 0) {
    //   if (portfolioList.find((item) => item.portfolio_name === portfolioName)) {
    //     return portfolioList.find((item) => item.portfolio_name === portfolioName).id;
    //   } else if (portfolioName.includes('Demo')) {
    //     return portfolioId;
    //   }
    // }
    return portfolioName
  }

  render() {
    const {
      data,
      valid,
      query,
      queryEnglish,
      enhanceTickerFilter,
      filterResultSet,
      portfolioFunds,
      marketFunds,
      oldViewBeforeUpload,
      view,
      enhancePortfolioVisibleState,
      enhanceReplace,
      resultSet,
      searchTypeMode,
      selectedWeightOptions,
    } = this.props;

    if (!valid) return null;

    data.forEach((yu, i) => {
      let jjk = [];
      // console.log('view', view);
      switch (view) {
        case "mrktPl":
          // console.log(_.sortBy(marketFunds, 'market_value').reverse().map((ii) => ii.script));
          jjk = marketFunds.filter((oo) => oo.script === yu.ticker)
          break;
        // case "watchlist":
        //   console.log(_.sortBy(marketFunds, 'market_value').reverse().map((ii) => ii.script));
        //   jjk = marketFunds.filter((oo) => oo.script === yu.ticker)
        //   break;
        default:
          // console.log(_.sortBy(portfolioFunds, 'market_value').reverse().map((ii) => ii.script));
          jjk = portfolioFunds.filter((oo) => (oo.script === yu.ticker) && oo.type !== 'SHARE')
          break;
      }
      if (jjk.length) {
        yu.market_value = jjk[0].market_value;
      }
    });

    const thisFund = (data && data.length) ? (typeof data[0].market_value !== 'undefined' ? _.sortBy(data, 'market_value').reverse() : data) : data;
    const renderTextQuery = (query === 'user_criteria') ? (`Best Alternatives Using ${selectedWeightOptions && selectedWeightOptions.name}`) : (queryEnglish || query);

    return (
      <React.Fragment>
        <div className="custom-scroll pb-5">
          {!enhancePortfolioVisibleState && (
            (resultSet.length > 0 && thisFund.length > 0) ? (
               thisFund.map((e, i) => {
                if (enhanceTickerFilter.includes(e.ticker) || enhanceTickerFilter.length === 0) {
                  return (
                    <ResultPage key={i} data={e} query={this.state.query} />
                  )
                }
              })
            ) : (
              query && !this.state.disableFor.includes(query) && (
              <Card className="card-background-grey empty-message-wrapper no-funds-wrapper">
                <img src='/assets/images/icons/NoFundsFound.svg' alt='' />
                <div className="text-box">
                  {(searchTypeMode === 'MODELS' && query === 'user_criteria') ? (
                    <span>The query may be applicable only for fund enhancements.</span>
                  ) : (
                    <><span>No funds</span> were found for <span>{renderTextQuery}</span></>
                  )}
                </div>
              </Card>)
            )
          )}
          {enhancePortfolioVisibleState && <EnhancePortfolio />}
          <Sfm {...this.props} />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  funds: state.funds,
  view: state.investment.view,
  valid: state.portfolio.valid,
  data: getPortfolioResultSet(state), //state.portfolio.resultSet,
  query: state.portfolio.query,
  queryEnglish: state.portfolio.query_english,
  enhanceTickerFilter: getPortfolioEnhanceTickerFilter(state), // state.portfolio.enhanceTickerFilter,
  filterResultSet: state.portfolio.filterResultSet,
  portfolioTickers: state.investment.portfolioTickers,
  watchlistFundstats: getInvestmentWatchlistStats(state),
  accountFundstats: getInvestmentPositionStats(state),
  marketTickers: getInvestmentMarketTickers(state),
  portfolioFunds: getPortfolioAccountStats(state.investment), // state.investment.portfolioFunds,
  marketFunds: getMarketFunds(state), //state.investment.marketFunds,
  oldViewBeforeUpload: state.investment.oldViewBeforeUpload,
  view: state.investment.view,
  portfolioCaching: state.portfolio.caching,
  investmentCaching: state.investment.caching,
  enhancePortfolioVisibleState: state.portfolio.enhancePortfolioVisible,
  fundUniverse: state.portfolio.fundUniverse,
  modelPortfolioApiLoading: createLoadingSelector(['GET_MY_CURRENT_PORTFOLIO', 'GET_REPLACE_PORTFOLIO_OPTIONS', 'FINALIZE_REPLACE_MODEL_PORTFOLIO'])({ loading: state.loading }),
  shareClass: state.portfolio.shareClass,
  similarityMeasures: state.portfolio.similarityMeasures,
  enhanceReplace: state.portfolio.enhanceReplace,
  summaryDrawer: state.portfolio.summaryDrawer,
  resultSet: state.portfolio.resultSet,
  searchTypeMode: state.portfolio.searchTypeMode,
  selectedWeightOptions: state.portfolio.selectedWeightOptions,
})

const mapDispatchToProps = {
  portfolioQuery,
  setPortfolioReplace,
  setPortfolioState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Content)

import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { SortAttrs } from 'DataSet';
import Filter from './Filter';

const classNames = require('classnames');

class Sort extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortOpen: false,
      filterOpen: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  closeFilter(value) {
    if (value) {
      this.setState({ filterOpen: false });
    }
  }

  toggle(attr) {
    this.setState({ [attr]: !this.state[attr] });
  }

  onMouseOver(attr) {
    this.setState({ [attr]: true });
  }

  onMouseLeave(attr) {
    this.setState({ [attr]: false });
  }

  componentDidUpdate() {
    const searchParent = document.getElementById("search-related-wrapper");
    const child = document.getElementById("sort-filter-wrapper");

    if (document.getElementById("investment-ranked-box")) {
      if (child) {
        child.style.position = 'unset';
      }
    } else if (searchParent) {
      if (child) {
        const searchLabelElem = document.getElementById("search-related-search-label");
        if (searchLabelElem) {
          searchLabelElem.style.width = '86%';
        }
        child.style.top = (searchParent.offsetHeight - 4)+"px"; // searchParent.offsetHeight / 2 + 1 +"%";
      }
    }

    // if (document.getElementById("investment-ranked-wrapper")) {
    //   const investmentRankedElem = document.getElementById("investment-ranked-wrapper");
    //   console.log("investmentRankedElem.offsetHeight ===> ", investmentRankedElem.offsetHeight);
    //   console.log("investmentRankedElem.offsetWidth ===> ", investmentRankedElem.offsetWidth);
    //   console.log(investmentRankedElem);
    //   if (investmentRankedElem.offsetHeight) {
    //     // if searchParent.offsetHeight present then  =>  then SORT/FILTER top = investmentRankedElem.offsetHeight + searchParent.offsetHeight
    //     // console.log("search-related-wrapper offsetHeight ===> ", searchParent.offsetHeight);
    //     if (searchParent.offsetHeight) {
    //       child.style.top = (investmentRankedElem.offsetHeight + searchParent.offsetHeight - 5)+"px"; // searchParent.offsetHeight / 2 + 1 +"%";
    //       child.style.left = investmentRankedElem.offsetWidth + 5 + "px";
    //       child.style.justifyContent = 'flex-end';
    //       child.style.width = '155px';
    //     } else {
    //       // if searchParent.offsetHeight not present then  => SORT/FILTER top = investmentRankedElem.offsetHeight
    //       child.style.top = (investmentRankedElem.offsetHeight - 5)+"px"; // investmentRankedElem.offsetHeight / 2 + 1 +"%";
    //       child.style.left = investmentRankedElem.offsetWidth + 5 + "px";
    //       child.style.justifyContent = 'flex-end';
    //       child.style.width = '155px';
    //     }
    //   } else {
    //     // look for 'search-related-wrapper'
    //     // console.log("search-related-wrapper offsetHeight ===> ", searchParent.offsetHeight);
    //     if (searchParent.offsetHeight) {
    //       child.style.top = (searchParent.offsetHeight - 5)+"px"; // searchParent.offsetHeight / 2 + 1 +"%";
    //       child.style.justifyContent = 'flex-end';
    //       child.style.width = '155px';
    //     } else {
    //       // both elem not found
    //       child.style.position = 'unset';
    //       child.style.paddingTop = '15px';
    //     }
    //   }
    // }

    // if(document.getElementById("investment-ranked-box")) {
    //   const parentTopSectionWrapper = document.getElementById("investment-ranked-box");
    //   console.log(parentTopSectionWrapper);
    //   console.log('parentTopSectionWrapper.offsetHeight ==>', parentTopSectionWrapper.offsetHeight);
    //   console.log('parentTopSectionWrapper.offsetWidth ==>', parentTopSectionWrapper.offsetWidth);
    //
    //   child.style.top = parentTopSectionWrapper.offsetHeight + 5 + "px";
    //   child.style.left = parentTopSectionWrapper.offsetWidth + 5 + "px";
    // }

    // if(!document.getElementById("did-you-mean") || document.getElementById("did-you-mean") === null) {
    //   const parentTopSectionWrapper = document.getElementById("topSectionContainer");
    //   child.style.top = parentTopSectionWrapper.offsetHeight / 2 + 1 +"%";
    // } else {
    //   const parent = document.getElementById("did-you-mean");
    //   child.style.top = searchParent.offsetHeight+parent.offsetHeight+"px";
    // }
  }

  render() {
    const { filterItems, filtersActive } = this.props;
    const iconClass = (attr) => {
      return "text-light fas fa-fw" + (
                this.props.sortAttr === attr
                ? (this.props.sortDesc ? " fa-arrow-alt-circle-up" : " fa-arrow-alt-circle-down")
                : "" );
    }

    return (
      <div>
        <div id="sort-filter-wrapper" className="row sort-filter-wrapper">
          <div className="col-auto nav-scroller" style={{ paddingRight: 5, marginBottom: '10px' }}>
            <div className="sortButtonWrapper">
              <Dropdown
                className="sortbutton"
                isOpen={this.state.sortOpen}
                onMouseOver={() => this.onMouseOver('sortOpen')}
                onMouseLeave={() => this.onMouseLeave('sortOpen')}
                toggle={() => {}}
                style={{ lineHeight: 1.5 }}
              >
                <DropdownToggle className="sortbutton" caret={true} style={{ lineHeight: 1, fontSize: 'inherit', padding: '2px 7px' }}>
                  <i className={`far fa-fw fa-sort-amount-${this.props.sortOpen ? "up" : "down" }`}></i> Sort
                </DropdownToggle>
                <DropdownMenu
                  className={classNames("nav", {"ghost-wrapper": this.state.sortOpen, "d-none": !this.state.sortOpen})}
                >
                  <div className="dropdown-wrapper">
                    { SortAttrs.map((v, i) => <DropdownItem className={classNames("nav-link", {'text-tag-blue': this.props.sortAttr === v.name})} key={i} color="link"
                        onClick={() => this.props.sortAttrSelect(v.name)}>{v.name}</DropdownItem>) }
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div className="col-auto nav-scroller" style={{ padding: '0 5px', marginBottom: '10px' }}>
            <div className={ Object.keys(filtersActive).length > 0 ? "filterButtonWrapper select-col"  : "filterButtonWrapper unselect-col"}>
              <Dropdown
                direction="left"
                className="filterbutton"
                isOpen={this.state.filterOpen}
                onMouseOver={() => this.onMouseOver('filterOpen')}
                onMouseLeave={() => this.onMouseLeave('filterOpen')}
                toggle={() => {}}
                style={{ lineHeight: 1.5 }}
              >
                <DropdownToggle className="filterbutton" caret={true} style={{ lineHeight: 1, fontSize: 'inherit', padding: '2px 7px' }}>
                  <i className="far fa-fw fa-filter"></i> Filter
                </DropdownToggle>
                <DropdownMenu
                  className={classNames("nav", {"ghost-wrapper": this.state.filterOpen, "d-none": !this.state.filterOpen})}
                >
                  <div className="dropdown-wrapper">
                    { Object.keys(this.props.filterItems).map((v, i) => {
                      return (
                        <Filter
                          key={i}
                          filtersActive={filtersActive}
                          filterAttrs={v}
                          filterValues={this.props.filterItems[v]}
                          filterChange={this.props.filterChange}
                          closeFilter={(value) => this.closeFilter(value)}
                        />
                      )}
                    )}
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
        {
          Object.keys(filtersActive).length > 0 &&
          <div className="row filter-items-wrapper" style={{ display: 'flex'}}>
            {
              Object.keys(filtersActive).map((item) => {
                return filtersActive[item].map((data) => {
                  const value = item;
                  const index = filterItems[item].findIndex((x) => x.name === data);
                  return (
                    <div className="filter-items">
                      {data}  <a className="filter-items__btn close-btn" onClick={this.props.filterChange.bind(this,value, index)}><i className="far fa-fw fa-times"></i></a>
                    </div>
                  )
                })
              })
            }
          </div>
        }
      </div>
    )
  }
}

export default Sort;

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { createLoadingSelector } from 'selectors/loading-selector';
import { getThemesRequest, preSelectedThematicTickersRequest } from 'actions/buildportfolio';

// import ThematicContent from './ThematicContent';
import ThematicRadioContent from './ThematicRadioContent';
import CardLoader from '../../../Elements/CardLoader';

class ThematicTab extends Component {
  componentDidMount() {
    const { getThemesRequest } = this.props;
    if (getThemesRequest) {
      getThemesRequest();
    }
  }

  componentWillReceiveProps(newProps) {
    if (!newProps.fetchingThemes && newProps.fetchingThemes !== this.props.fetchingThemes) {
      const { preSelectedTickers, preSelectedThematicTickersRequest, fetchingThemes, thematicTab, buildportfolio } = newProps;
      if(preSelectedTickers && preSelectedTickers.length > 0 && !fetchingThemes && (thematicTab.themes !== this.props.thematicTab.themes)) {
        const preThematicTickers = preSelectedTickers.filter(e => e.category === 'themes');
        if (preSelectedThematicTickersRequest && preThematicTickers.length) {
          preSelectedThematicTickersRequest({
            query: 'pf_builder_themes',
            preThematicTickers,
            allStates: buildportfolio
          });
        }
      }
    }
  }

  render() {
    const { thematicTabLoading, weightingMethodology, thematicTab, activeTab, tabHeaderMenu } = this.props;
    return (
      <React.Fragment>
        {thematicTabLoading ? (
          <CardLoader />
        ) : (
          <ThematicRadioContent topTabHeaderMenu={tabHeaderMenu} activeTab={activeTab} weightingMethodology={weightingMethodology} thematicTab={thematicTab}/>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ buildportfolio, loading }) => ({
  buildportfolio,
  thematicTab: buildportfolio.thematicTab,
  fetchingThemes: createLoadingSelector(['THEMES'])({ loading }),
  thematicTabLoading: createLoadingSelector(['THEMES', 'PRE_SELECTED_THEMATIC_TICKERS'])({ loading }),
  preSelectedTickers: buildportfolio.preSelectedTickers,
})

const mapDispatchToProps = {
  getThemesRequest,
  preSelectedThematicTickersRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThematicTab);

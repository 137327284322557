import React from 'react';
import { D3Sunburst } from 'components/charts/D3Charts';
import NotApplicable from 'components/NotApplicable';
import { RegionChartMapping } from 'ChartData';
import { cn } from 'DataSet';

const AllocationChartB_ = (props) => {
  const { card, view, name, chartData } = props;
  // if (typeof card.chartData === 'undefined') return null;
  // console.log(view);
  const chrtD = chartData || card.chartData;
  const currentChartData = chrtD.filter(e => e.view_name === view)[0];
  // console.log(currentChartData);
  const formatTree = data => {
    if (!data) return data;
    return data.map(e => {
      let out = { name: e.n, size: e.v }
      if (e.sub) out.children = formatTree(e.sub);
      return out;
    })
  }

  if (!props.card || !cn(props.card, 'Region')) {
    return <NotApplicable />;
  }

  const data = {
    name: props.name,
    children: formatTree(cn(props.card, 'Region'))
  };

  if (data && data.children && data.children.length === 0) {
    return <NotApplicable />;
  }

  const sum = (arr, key) => {
    return arr.reduce((a, b) => a + (b[key] || 0), 0);
  }

  // let newArr = JSON.parse(JSON.stringify(data));
  //
  // newArr.children.map((item) => {
  //   item.size = sum(item.children, 'size')
  //   return item;
  // });

  let primaryTextStyle = {}, secondaryTextStyle = {};
  const chartTypePresent = typeof name !== 'undefined' ? name : '';
  let centerText, centerDisplay = "";

  if (typeof currentChartData !== 'undefined') {
    if (currentChartData.data) {
      let displayText = RegionChartMapping.filter(e => e.chart_name === currentChartData.node);
      if (displayText.length) {
        if (displayText[0].region_name) {
          if (card[currentChartData.data] >= 0 && card[currentChartData.data] !== '' && card[currentChartData.data] !== null && typeof card[currentChartData.data] !== 'undefined') {
            centerDisplay = `${card[currentChartData.data].toFixed(1)}%`;
            centerText = displayText[0].region_name;
            // centerText = centerText.substring(0, 11);
            primaryTextStyle = chartTypePresent === 'Allocations' ?  {
              // top: '39%',
              fontSize: (centerDisplay >= 100) ? 9 : 11,
              fontWeight: 'bold',
            }: {};
            secondaryTextStyle = chartTypePresent === 'Allocations' ? {
              top: centerText.length > 8 ? '54%' : '52%',
              fontSize: centerText.length > 8 ? 7 : 9,
            } : {};
          }
        }
      }
    }
  }

  let d3value = '';

  const showTooltip = (evt, text) => {
    let tooltip = document.getElementById(`${card.ticker}_d3tooltip`);
    d3value = text;
    tooltip.innerHTML = text;
    tooltip.style.display = "block";
    tooltip.style.left = evt.pageX + 10 + 'px';
    tooltip.style.top = evt.pageY + 10 + 'px';
  }

  const hideTooltip = () => {
    var tooltip = document.getElementById(`${card.ticker}_d3tooltip`);
    tooltip.style.display = "none";
  }

  return (
    <div className="d3ChartWrapper">
      {(name === 'Allocations')
        && (
          <div className="primaryTextStyle" style={primaryTextStyle}>
            { centerDisplay }
            <div className="highlightTooltip">{centerText}: {centerDisplay}</div>
          </div>
        )
      }
      <div id={`${card.ticker}_d3tooltip`} className="d3Tooltip">{d3value}</div>
      <D3Sunburst
        data={data}
        width={props.width || 84}
        height={props.height || 84}
        colors={props.colors}
        clickHandler={props.searchFundsActiveRegion}
        activeSegment={props.activeRegion}
        showTooltip={showTooltip}
        hideTooltip={hideTooltip}
      />
    </div>
  )
}

export const RegionChart = React.memo(AllocationChartB_);

export default RegionChart;

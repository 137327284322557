import ActionTypes from '../action-types'

import { getAuthToken, isPremiumUser } from '../common/auth-utils'

const tokenInfo = getAuthToken()

const cleanState = {
  loggedIn: false,
  loggingIn: false,
  user: {
    email: ''
  },
  token: '',
  newUser: 0,
  termsAgreed: 1,// parseInt(window.localStorage.getItem('termsAgreed')) || 0,
  authFailed: false,
  authSuccess: false,
  isGoogleLogin: false,
  isAppleLogin: false,
  logoutSuccess: false,
  logoutFailed: false,
  accConfirmedModal: false,
  client_exist: false,
  user_advisor_menu: false,
  loggedInByToken: null,
}

const initialState = {
  ...cleanState,
  loggedIn: Boolean(tokenInfo.token),
  user: {
    email: tokenInfo.email || '',
    firstName: tokenInfo.firstName || '',
    lastName: tokenInfo.lastName || '',
    premiumUser: false, //isPremiumUser(tokenInfo),
    planName: '',
    plan_start_date: '',
    plan_end_date: '',
    subscriber_since: '',
    subscription_id: '',
    renews_on: '',
    cancellation_request_status: false,
    cancels_on: ''
  },
  token: tokenInfo.token || '',
  isGoogleLogin: tokenInfo.isGoogleLogin,
  isAppleLogin: tokenInfo.isAppleLogin,
  verifypwdtoken:'',
  old_password:'',
  setPasswordSuccess:false,
  remind_for_password_change:false,
  client_exist: tokenInfo.client_exist || false,
  oktaAuthLoader: false,
  openCalendarInviteModal: null,
  user_advisor_menu: tokenInfo.user_advisor_menu || false,
  oktaChangePasswordModal: null,
  oktaChangePasswordError: null,
}

export function auth(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case ActionTypes.UPDATE_PREMIUM_STATE:
      return {
        ...state,
        user: action.payload.user,
      }
    case ActionTypes.SET_AUTH_STATE:
      return {
        ...cleanState,
        ...state,
        ...action.payload,
      }
    case ActionTypes.AUTH_REQUEST:
      return {
        ...cleanState,
        ...state,
        loggingIn: true,
        authSuccess: false,
        authFailed: false,
        logoutSuccess: false,
        newUser: 0,
        termsAgreed: 0,
        user: {},
        token: '',
        error: {}
      }
    case ActionTypes.SET_OLD_PASSWORD:
      return {
        ...cleanState,
        ...state,
        old_password: action.payload,
      }
    // case ActionTypes.AUTH_SUCCESS:
    //   const { loggedInByToken: _loggedInByToken } = action.response
    //   return {
    //     ...state,
    //     loggedIn: true,
    //     loggingIn: false,
    //     authSuccess: true,
    //     user: {
    //       ...action.response,
    //       premiumUser: false, //isPremiumUser(action.response),
    //     },
    //     token: action.response.token,
    //     loggedInByToken: _loggedInByToken || state.loggedInByToken,
    //     newUser: action.response.profile.new_user,
    //     termsAgreed: action.response.profile.terms_agreed,
    //     client_exist: action.response.client_exist,
    //     remind_for_password_change: action.response.remind_for_password_change,
    //     user_advisor_menu: action.response.user_advisor_menu
    //   }

    case ActionTypes.AUTH_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }

    case ActionTypes.AUTH_FAILURE:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        authFailed: true,
        error: action.error,
        newUser: 0,
        termsAgreed: 0
      }

    case ActionTypes.GOOGLE_AUTH_REQUEST:
      return {
        ...state,
        loggingIn: true,
        authSuccess: false,
        authFailed: false,
        user: {},
        token: '',
        error: {},
        newUser: 0,
        termsAgreed: 0
      }
    case ActionTypes.GOOGLE_AUTH_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        authSuccess: true,
        isGoogleLogin: true,
        user: {},
        token: action.response.token,
        newUser: action.response.new_user,
        termsAgreed: action.response.terms_agreed
      }
    case ActionTypes.GOOGLE_AUTH_FAILURE:
      let failureState = {
        loggedIn: false,
        loggingIn: false,
        authSuccess: false,
        authFailed: true,
        user: {},
        token: '',
        newUser: 0,
        termsAgreed: 0
      };
      const { loggedInByToken } = state;
      if (loggedInByToken) {
        failureState = {};
      }
      return {
        ...state,
        ...failureState,
      }

      case ActionTypes.APPLE_AUTH_REQUEST:
        return {
          ...state,
          loggingIn: true,
          authSuccess: false,
          authFailed: false,
          user: {},
          token: '',
          error: {},
          newUser: 0,
          termsAgreed: 0
        }
      case ActionTypes.APPLE_AUTH_SUCCESS:
        return {
          ...state,
          loggedIn: true,
          authSuccess: true,
          isAppleLogin: true,
          user: {},
          token: action.response.token,
          newUser: action.response.new_user,
          termsAgreed: action.response.terms_agreed
        }
      case ActionTypes.APPLE_AUTH_FAILURE:
        return {
          ...state,
          loggedIn: false,
          loggingIn: false,
          authSuccess: false,
          authFailed: true,
          user: {},
          token: '',
          newUser: 0,
          termsAgreed: 0
        }

    case ActionTypes.TERMS_AGREEMENT_REQUEST:
      return {
        ...state,
        termsAgreed: 0
      }
    case ActionTypes.TERMS_AGREEMENT_SUCCESS:
      return {
        ...state,
        termsAgreed: action.response.profile.terms_agreed,
        accConfirmedModal: action.response.profile.accConfirmedModal,
      }
    case ActionTypes.TERMS_AGREEMENT_FAILURE:
      return {
        ...state,
        termsAgreed: 0
      }

    case ActionTypes.LOGOUT_SUCCESS:
      window.localStorage.removeItem("popupCounter");
      window.localStorage.removeItem("isPremium");
      return {
        ...cleanState,
        logoutSuccess: true
      }
    case ActionTypes.LOGOUT_FAILURE:
      return {
        ...cleanState,
        logoutSuccess: true // TODO: change later
      }
    case ActionTypes.SAVE_VERIFY_CHANGE_PWD_TOKEN:
        const { payload } = action;
        return{
          ...cleanState,
          ...state,
          verifypwdtoken: payload.token,
        }

    case ActionTypes.SAVE_SET_PASSWORD:
        return{
          ...state,
          setPasswordSuccess:true,
        }

    case ActionTypes.SET_ADVISOR_DATA:
        return{
          ...state,
          client_exist: action.response.client_exist,
          user_advisor_menu: action.response.user_advisor_menu,
          restricted_universe: action.response.restricted_universe || false,
        }

    case ActionTypes.SELECT_DOMAIN_TO_REDIRECT:
      return{
        ...state,
        ...action.payload,
      }

    case ActionTypes.OKTA_AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }

    case ActionTypes.SET_OKTA_AUTH_LOADER:
      return {
        ...state,
        oktaAuthLoader: action.payload,
      }

    case ActionTypes.SET_CALENDAR_INVITE_MODAL_STATE:
      return {
        ...state,
        openCalendarInviteModal: action.payload,
      }

    case ActionTypes.AUTO_LOGIN_BY_TOKEN_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }

    case ActionTypes.REFRESH_AUTH_STATE:
      return {
        ...initialState,
      }

    case ActionTypes.OKTA_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }

    case ActionTypes.RESEND_PASSWORD_RESET_EMAIL_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}

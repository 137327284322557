import React from 'react';
import classNames from 'classnames';
import config from './config';
// import ucImage from '../../assets/images/ucImage.png';
import styles from './index.less';

export default ({ className, type, code, title, desc, img, actions, ...rest }) => {
  const pageType = type in config ? type : '404';
  const clsString = classNames(styles.ucStateContainer, className);
  return (
    <div className={clsString} {...rest}>
      <div className={styles.codeWrapper}>
        <h3>{code || config[pageType].code}</h3>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.description}>{desc || config[pageType].desc}</div>
        <div className={styles.imageWrapper}>
          <img src="" alt="uc images" />
        </div>
      </div>
    </div>
  );
};

import React from "react";
import PropTypes from "prop-types";
import { PrimaryButton } from "shared-components/";

export default function BrokerList(props) {
  const items = props.brokerList.map((broker, idx) => {
    if(broker.disabled)
      return null
    else
      return (
      <li key={idx} className="brokers__item">
        <PrimaryButton
          className="brokers__buttonWrap"
          onClick={() => props.onBrokerSelect(broker)}
          disabled={broker.disabled}
        >
          <p className="brokers__displayName">{broker.displayName}</p>
        </PrimaryButton>
      </li>
    );
  });

  return (
    <div className="brokers__wrapper">
      <ul className="brokers">{items}</ul>
    </div>
  );
}

BrokerList.propType = {
  onBrokerSelect: PropTypes.func.isRequired
};

import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, ButtonGroup, Form, FormGroup, Label, Input } from 'reactstrap'
import { PrimaryButton } from 'shared-components'
import InputChips from '../Shared/InputChips'
import PercentageChart from '../Shared/PercentageChart';
import { FormatCurrency } from 'Utils';
import { taxBillsRequest, updateSelectedtaxBill } from 'actions/clientportfolioV2';
import { ShowSpinner } from '../Shared/ShowSpinner';
import { addEventToAnalytics } from 'Utils';

class OptimizeModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTaxBillKey: '',
      selectedTaxBillValue: '',
    }
  }

  componentWillMount() {
    this.props.taxBillsRequest({ client_id: this.props.clientDetails.id })
  }

  selectTaxBill = (key, value) => {
    this.setState({
      selectedTaxBillKey: key,
      selectedTaxBillValue: value
    })
  }

  handleSubmit = () => {
    const { selectedTaxBillValue } = this.state;
    const { clientDetails } = this.props;
    let updatedTaxBill = [];
    if(selectedTaxBillValue){
      addEventToAnalytics('Client Optimize', 'Client Optimize', 'CLIENT_OPTIMIZE', { client_id: clientDetails.id }, false)
      const optimizeObj = {
        client_id: clientDetails.id,
        estimated_tax: selectedTaxBillValue.tax_budget    
      }
      updatedTaxBill.push(optimizeObj);
      this.props.updateSelectedtaxBill(updatedTaxBill);      
    }
    this.props.closeOptimizeModal();
  }

  render() {
    const { showOptimizeModal, clientDetails, closeOptimizeModal, optmizeTaxBills } = this.props;
    const { tt_results } = optmizeTaxBills;
    const { selectedTaxBillKey: selectedKey } = this.state;
    let noContentMessage;
    console.log("check clientDetails", clientDetails);
    if(clientDetails.has_tax_lot){
      if(!clientDetails.selected_portfolio){
        noContentMessage = 'Please select a portfolio'
      }        
    }else{
      noContentMessage = 'Please upload tax lot information to optimize tax transition'
    }
    return (
      <React.Fragment>
        <Modal
          isOpen={showOptimizeModal}
          toggle={() =>
            closeOptimizeModal()
          }
          centered
          keyboard={false}
          backdrop="static"
          className={`optimize-sm-light ${ noContentMessage ? 'opt-min-wid' : '' }`}
          backdropClassName="pf-up-sm-light-modal-backdrop"
        >
          <ModalBody className="ssf-modal__body sml-modal-wrapper opt-mdl-wrap">
            <i className="fal fa-times cp-modal-close" onClick={() => closeOptimizeModal()}></i>
            {!noContentMessage ? <> { tt_results ? <>
            <h3 className="text-center" style={{marginBottom: '30px'}}>Select Maximum Tax Bill for {clientDetails.name}</h3>
            <div className="optimize-tbl-wrapper">
              <table id="table" className="optimize-tbl" style={{ position: 'relative', width: '100%' }}>
                <tbody>

                  <tr>
                    <th><h5>%</h5></th>                    
                    {Object.entries(tt_results).map(([key, value]) => {
                      return(
                        <td className="cus-pointer" onClick={() => this.selectTaxBill(key, value)}>
                          <div className="shadown_divs"></div>
                          <div className="tt_budget_heading">{key}%</div>
                          <div className="container-skill">
                              <div className="skill-group-bar">
                                  <div className={`skill-bar ${ key >= 20 ? 'active' : 'disable' }`}></div>
                                  <div className={`skill-bar ${ key >= 40 ? 'active' : 'disable' }`}></div>
                                  <div className={`skill-bar ${ key >= 60 ? 'active' : 'disable' }`}></div>
                                  <div className={`skill-bar ${ key >= 80 ? 'active' : 'disable' }`}></div>
                                  <div className={`skill-bar ${ key >= 100 ? 'active' : 'disable' }`}></div>
                              </div>
                          </div>
                        </td>
                      )
                    })}
                  </tr>

                  <tr>
                    <th scope="row" data-label="Account" style={{ borderTop: 'none' }} ><h5>Maximum Tax Bill</h5></th>
                    {Object.entries(tt_results).map(([key, value]) => {
                      return(
                        value.tracking_error != 'XXX' && <td className={`cus-pointer ${key == selectedKey ? 'highlight' : ''}`} onClick={() => this.selectTaxBill(key, value)}>
                            {value.tracking_error != 'XXX' && <div className="graph-box-r">
                                <div className="graph-box-r-content">
                                    <h5 data-label="Tax Budget ($) % of taxes incurred by complete transition" class="pv heading_bg">{value.tax_budget != 0 ? FormatCurrency(parseFloat(value.tax_budget).toFixed(1), 0, '$', 'unf') : '$0'}</h5>
                                </div>
                            </div>}
                            {value.tracking_error == 'XXX' && <div><h5 style={{ color: '#4e4e4e', fontSize: '16px', lineHeight: '1.5' }}>No Optimal<br/>Solution Found</h5></div>}
                        </td>
                      )
                    })}
                  </tr>
                  
                  <tr>
                      <th><h5>Transition Amount</h5> <span className="tt-mdl-sub-text">% of portfolio value</span></th>
                      {Object.entries(tt_results).map(([key, value]) => {
                        let [_min, _max, _val] = [0, 100, parseFloat(value.amt_transitioned_pct)]
                        return(
                          value.tracking_error != 'XXX' && <td className={`cus-pointer ${key == selectedKey ? 'highlight' : ''}`} style={{ padding: '20px 0px' }} onClick={() => this.selectTaxBill(key, value)}>
                              <div className="graph-box-r">
                                  <div class="graph-box-r-content">
                                      <h5 className="pv heading_bg">{FormatCurrency(parseFloat(value.amt_transitioned_dollar), 0, '$', 'unf')}</h5>    
                                      <div className="w-100 p-1 guage-chart-wrapper">
                                        <div className="d-flex flex-column align-items-center perc-chart">
                                          <PercentageChart minWidth={110} value={_val} />
                                          <Row className="no-gutters h6 mb-0 w-100 guage-chart-details">
                                            <Col className="col-4 pr-2 text-gray-6i text-right h7 fz" style={{ marginTop: '-2px' }}>{/*{_min.toFixed(2)}%*/}</Col>
                                            <Col className="col-4 text-center font-weight-bold mb-0 lh-100 fz" style={{ marginTop: '0px' }}>{_val == 0 ? _val : _val.toFixed(2)}%</Col>
                                            <Col className="col-4 pl-2 text-gray-6i text-left h7 fz" style={{ marginTop: '-2px' }}>{/*{_max.toFixed(2)}%*/}</Col>
                                          </Row>
                                        </div>
                                      </div>                               
                                  </div>
                              </div>
                          </td>
                        )
                      })}
                  </tr>

                  <tr>
                    <th scope="row" data-label="Account"><h5>Tracking Error</h5></th>
                    {Object.entries(tt_results).map(([key, value]) => {
                      return(
                         value.tracking_error != 'XXX' && <td className={`cus-pointer ${key == selectedKey ? 'highlight' : ''}`} onClick={() => this.selectTaxBill(key, value)}>
                            <h5 className="pv"> +/- {value.tracking_error}%</h5>
                        </td>
                      )
                    })}
                  </tr>

                </tbody>
              </table>
            </div>
            <div className="col-12 cta-wrapper">
              <button type="button" className="ssf-btn-primary btn secondary-btn btn btn-secondary"
                onClick={() => this.handleSubmit()}><span>SUBMIT</span></button>              
            </div>
            </> : <ShowSpinner /> }
            </> : <>
              <div className="confirm-text text-center">
                <span>{noContentMessage}</span>
              </div>
              <div className="col-12 cta-wrapper">
                <button type="button" className="ssf-btn-primary btn secondary-btn btn btn-secondary"
                  onClick={() => closeOptimizeModal()}><span>OK</span></button>
              </div>
            </> }
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ clientportfolio: { optmizeTaxBills } }) => ({
  optmizeTaxBills
})

const mapDispatchToProps = {
  taxBillsRequest,
  updateSelectedtaxBill,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OptimizeModal);

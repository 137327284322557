// This component was build based on the UI mockup, later it was requested to be removed
// This file is not being used currently in the application
import React from 'react'
import {
  Alert,
  Col,
  Collapse,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Button,
  Input
} from 'reactstrap'
import { PrimaryButton } from 'shared-components'

class BasicUserDetails extends React.Component {
  constructor(props) {
    super(props)
    // Add your stripe key here
    this.stripe = window.Stripe('');
    this.subscribeNow = this.subscribeNow.bind(this)
  }

  subscribeNow() {
    this.stripe.redirectToCheckout({
      items: [{
        // Replace with the ID of your plan
        plan: 'plan_GnYesjTDXYz1yJ',
        quantity: 1,
      }],
      customerEmail: 'ten@tester.com',
      successUrl: window.location.href+'&paymentStatus=1',
      cancelUrl: window.location.href+'&paymentStatus=0'
    }).then((result) => {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
      console.log("results status",result)
    });
  }

  render() {
    return (
      <React.Fragment>
        <Row className="profile-element-wrapper">
          <Col xs={12} className="profile-description-heading">
            <h5>Plan Type</h5>
            <a href="#"><b>Upgrade to Premium Plan</b></a>
          </Col>
          <Col xs={12} className="profile-plan-details">
            <h6>Basic Plan</h6>
            <p>The current cost is $00/month</p>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default BasicUserDetails

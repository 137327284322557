import React from 'react';
import { Header_Footer_Logo, Header_Footer_poweredByLogo } from '../../../../common/constant';
const Logo = Header_Footer_Logo;
const poweredByLogo = Header_Footer_poweredByLogo;
export const withHeaderFooter = (WrappedComponent) => {
  return class extends React.Component {
    render() {
      const { totalPageCount, logo } = this.props;
      let page = pageNum.next().value;
      return (
        <div className="page">
          <PageHeader logo={logo} page={page} total={totalPageCount} />
          <WrappedComponent {...this.props} />
          <PageFooter page={page} />
        </div>
      )
    }
  }
}

const PageHeader = ({ date, page, total, logo }) => (
  <div className="page-header d-print-block">
    <div className="d-flex justify-content-between align-item-center">
      {logo && (
        <img className={'logo'} src={logo} alt="magnifi" />
      )}
      <div className="d-grid">
        <img style={{ height: `20px` }} src={poweredByLogo} alt="" />
        {/* <div style={{ color: "#979797", fontWeight: 600, paddingRight: 2, marginTop: 5 }} className="d-flex  justify-content-end align-item-center">For Institutional Use Only</div> */}
      </div>
    </div>
  </div>
)

// const PageHeader = () => (
//   <div className="page-header d-print-block">
//     <img src={Logo} width="120" alt="magnifi" />
//   </div>
// )

const PageFooter = ({ date, page, total, extraContent }) => (
  <div className="page-footer d-print-block">
    <div className="d-flex">
      <span style={{ width: '100%' }}>
        <div>
          Please review the Disclosures contained at the end of this document for more information.
          {(extraContent) ? (
            (page === 3 || page === 4) && (
              <>
                <br />
                *Please refer to the glossary of terms for more information.
              </>
            )
          ) : (
            (page === 2 || page === 3) && (
              <>
                <br />
                *Please refer to the glossary of terms for more information.
              </>
            )
          )}
        </div>
      </span>
      {(page > 0) &&
        <span style={{ width: `100px`, textAlign: `end` }}>Page {(total && total !== '-') ? `${page} | ${(total < 10) ? '0' + (total) : total}` : `${page}`}</span>
      }
    </div>
  </div>
)

// const PageFooter = ({ date, page, total }) => (
//   <div className="page-footer d-print-block">
//     <div className="d-flex">
//       <span>Search Analytics Report</span>
//       <i className="far fa-minus fa-rotate-90 mx-2"></i>
//       <span className="ml-1">{date}</span>
//       <span className="ml-auto">Page {(total && total !== '-') ? `${page}/${total}` : `${page}`}</span>
//     </div>
//   </div>
// )

const pageNum = (function* () {
  let n = 0;
  while (true) yield n++;
})();

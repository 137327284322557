import React from 'react';
import { SimplePieChart } from 'components/Charts';
import NotApplicable from 'components/NotApplicable';
import { cn } from 'DataSet';
// import { convertToCapitalCase } from 'Utils';

export const AssetAllocationChart = (props) => {
  if(typeof props.card === 'undefined' || typeof props.card.assetTypeData === 'undefined') return <NotApplicable />;

  const { assetTypeData } = props.card;

  if(!assetTypeData || assetTypeData === null || assetTypeData.length <= 0) return <NotApplicable />;
  // if(!assetTypeData || assetTypeData === null) return <NotApplicable />;

  // var mult = props.name === 'Asset Types' ? 100 : 1;
  let sum = 0.0;
  let centerDisplayContent = '', centerText = '';
  props.attrs.forEach(v => {
    sum += Math.abs(cn(assetTypeData, v));
  });
  let data = props.attrs.map(v => {
    return { name: v, value: Math.round((Math.abs(cn(assetTypeData, v))*100/sum)), originalValue: Math.round((cn(assetTypeData, v)*100/sum)) }
  });

  if (props.useOriginalData) {
    data = props.attrs.map(v => {
      return { name: v, value: Math.round(cn(assetTypeData, v)), originalValue: cn(assetTypeData, v) }
    });
  }

  const filterData = data.filter(x => x.value > 0);

  if (filterData.length <= 0) return <NotApplicable />;

  // if (props.chartData && typeof props.chartData !== 'undefined' && props.chartData.length > 0) {
  //   const node = props.chartData[0].node === 'equity' ? 'equities' : props.chartData[0].node;
  //   if (node && assetTypeData[node]) {
  //     centerDisplayContent = parseFloat(assetTypeData[node].toFixed(1)) + '%';
  //     centerText = `% ${convertToCapitalCase(node)}`;
  //   }
  // }

  return (
    <SimplePieChart
      {...props}
      data={data}
      centerText={centerText}
      centerDisplay={centerDisplayContent}
      colors={props.colors}
      chartName={'asset_type'}
    />
  )
}

export default AssetAllocationChart;

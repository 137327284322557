import React from 'react';
import { connect } from 'react-redux';

import { Row, Col, Button } from 'reactstrap';

import { uniqBy, reverse } from 'lodash'
import { getNest, getNestDefault, arrayToHash, queryStr, uniqValues, convertToCapitalCase } from 'Utils'
import { FilterAttrData, SortAttrs, DefaultSortAttr, DateRange, ViewNames, Api, ApiHeaders, cn, CN, MaxResult, Universe, BrokersComms } from 'DataSet'
import { calcStats } from 'Stats';
import getFilteredListByRange from './utils';
import { investmentActiveFund, setInvestmentState } from 'actions/investment'
import { addFundToSFM, setSFMCatData, refreshSFMState } from 'actions/sfm'
import { getInvestmentActiveFundStats, getInvestmentActiveFund } from 'selectors/investment'
import { DonutChartS1 } from 'components/charts/Recharts'
import { BarChartPL } from 'components/charts/BarCharts'
import { DualColorLineChart } from 'components/charts/LineCharts'
import { RegionColors, SectorColors } from 'data/Colors'
import { SelectorDD, MoreButton, FormatCurrency, KeyStats3, KeyStats4, TruncatedNameSponsor, withScrollBar, NoData } from './Common'
import { fetchFunds, fetchFundsDetails, fetchCategories, fetchFundCommissions, fetchRealTimePrices, fetchCharts, fetchReturnsData, fetchRegionsChart, fetchReturnsDataV1, fetchChartsV1 } from 'apis/funds'
import { fetchFundByTicker } from 'apis/account'
import { getFundsUpdatedPrice } from 'layouts/utils';

const classNames = require('classnames');

export const Gains = (props) => {
  const stats = [
    { name: "Gains For Today", value: props.stats.gainToday },
    { name: 'Gains For The Month', value: props.stats.gainTotal },
    { name: 'Gains For The Year', value: props.stats.gainToday },
  ]
  return (
    <KeyStats3 {...props} stats={stats} prec={2} />
  )
}

export const PortfolioGains = (props) => {
  const stats = [
    { name: "Gains For Today", value: props.stats.gainToday },
    { name: 'Gains For The Month', value: props.stats.gainMonthly },
    { name: 'Gains For The Year', value: props.stats.gainYearly },
  ]
  return (
    <KeyStats4 {...props} stats={stats} prec={2} />
  )
}

export class Allocations extends React.Component {
  opts = [ 'Sectors', 'Regions' ]
  state = { opt: this.opts[0] }

  optHandler = opt => this.setState({ opt });
  formatter = val => `${val.toFixed(1)}%`;

  chartData = () => {
    const { priceRegions: regions, priceSectors: sectors } = this.props.stats;
    return this.state.opt === this.opts[0]
            // ? Object.keys(sectors).map((e, i) => ({ name: e, value: sectors[e], color: SectorColors[i%SectorColors.length] }))
            ? this.props.stats.allocationPriceSectors.length > 0 ? this.props.stats.allocationPriceSectors : []
            // : Object.keys(regions).map(e => ({ name: e, value: regions[e], color: RegionColors[e] }));
            : this.props.stats.priceRegions.length > 0 ? this.props.stats.priceRegions : [];
  }

  render() {
    const data = this.chartData();

    return (
      <React.Fragment>
        <SelectorDD opts={this.opts} optHandler={this.optHandler} />
        <h5 className="mb-1 text-heading font-one">Allocations</h5>
        { data.length <= 0
          ? <NoData height='225px' />
          :  <div className="d-flex flex-column justify-content-center align-items-center" style={{minHeight: '225px'}}>
              <div style={{ width: "90%", height: "140px" }}>
                <DonutChartS1 data={data} formatter={this.formatter} />
              </div>
            </div> }
      </React.Fragment>
    )
  }
}

export class ProfitLoss extends React.PureComponent {
  opts = [ 'Sectors', 'Regions' ]
  state = { opt: this.opts[0] }

  optHandler = opt => this.setState({ opt });
  formatter = val => `$${val.toFixed(1)}`;

  chartData = () => {
    return this.state.opt === this.opts[0]
           ? this.props.stats.priceSectors.length > 0 ? this.props.stats.priceSectors : []
           : this.props.stats.priceRegions.length > 0 ? this.props.stats.priceRegions : [];
  }

  render() {
    const data = this.chartData();

    return (
      <React.Fragment>
        <SelectorDD opts={this.opts} optHandler={this.optHandler} />
        <h5 className="mb-1 text-heading font-one">Profit / Loss</h5>
        {/* { data.length <= 0 || this.props.view === 'watchlist' */}
        { data.length <= 0
          ? <NoData height='225px' />
          : <div className="d-flex flex-column justify-content-center align-items-center" style={{minHeight: '225px'}}>
              <div style={{ width: "90%", height: "180px" }}>
                <BarChartPL data={data} formatter={this.formatter} />
              </div>
            </div> }
      </React.Fragment>
    )
  }
}


class Holdings_ extends React.Component {
  constructor(props) {
    super(props);
    this.structuredFund = this.structuredFund.bind(this);
    this.getFundsData = this.getFundsData.bind(this);
  }

  componentDidMount() {
    if (!this.props.activeFund){
      const fundId = getNest(['items', 0, 'symbol'], this.props);
      this.props.investmentActiveFund(fundId); // will also clear if no funds
    }
  }

  componentDidUpdate() {
    const { activeFund, items, expand = false } = this.props;
    if (!items.slice(0, expand ? 30 : 3).map(e => e.symbol).includes(activeFund)){
      const fundId = getNest(['items', 0, 'symbol'], this.props);
      this.props.investmentActiveFund(fundId); // will also clear if no funds
    }
  }

  itemClickHandler = fundId => ev => {
    if (fundId !== this.props.activeFund)
      this.props.investmentActiveFund(fundId);
  }

  async structuredFund(_funds) {
    let funds = [];
    if (_funds["funds"] && _funds["funds"].length) {
      funds = _funds["funds"].slice(0, MaxResult);
    }
    if (funds.length <= 0)
      return { funds: [], categories: [], range: _funds["date_range"], filterAttrData: {} };

    let ticks = uniqValues(funds, 'ticker').join(',');

    let [returns, categories, charts] = await Promise.all([
      fetchReturnsDataV1({tickers:ticks}),
      fetchCategories({tickers:ticks}),
      fetchChartsV1({ tickers: ticks }),
    ]);


    let holdings = charts.topten,
        assetTypes = charts.asset_type,
        themeScore = charts.themes_new,
        diverseData = charts.diversification,
        riskAdjReturn = charts.risk_adjusted;

    let returnsData = arrayToHash(returns, CN['Ticker']);
    let chartsData = arrayToHash(charts, CN['Ticker']);

    funds.forEach((e, index) => {
      funds[index].region = chartsData[cn(e, 'Ticker')].result.regions ? chartsData[cn(e, 'Ticker')].result.regions.length ? chartsData[cn(e, 'Ticker')].result.regions[0].region : [] : [];
      funds[index].returns = returnsData[e.ticker].returns;
      funds[index].returns = reverse(funds[index].returns);
      let r = getNest(['returns', 0], e);
      if (r){
        e._start = r['d'];
        e._end = e.returns[e.returns.length-1]['d'];
      }
      e.returns.forEach((item,index) => {
        e.returns[index].v = item.v/100;
      });
      // e.brokers = getNest([cn(e, 'Ticker'), 'brokers'], commData);
      e.brokers = BrokersComms;
      e.broker = getNest([0], e.brokers);

      //regions data manipulation -- adding others
      if(e.region.length> 0){
        e.region.forEach((reg, i) => {
          let sum = 0;
          if(reg.n == 'Others'){
            reg.sub.forEach(child => {
              sum += child.v;
            })
            e.region[i].sub = [{ n: 'Others ', v: sum}];
          }
        });
      }

      //calculate assetType chart data
      e.assetTypeData = chartsData[cn(e, 'Ticker')].result.asset_type[0] || []

      //calculate theme score chart data
      e.themeScore = chartsData[cn(e, 'Ticker')].result.themes_new[cn(e, 'Ticker')];

      //calculate risk adjusted return chart data
      e.riskAdjReturn = chartsData[cn(e, 'Ticker')].result.risk_adjusted[cn(e, 'Ticker')];

      e.diverseData = chartsData[cn(e, 'Ticker')].result.diversification[0];

      e.diverseData = chartsData[cn(e, 'Ticker')].result.diversification[0];
      // console.log(e.diverseData)
      //calculate holding chart data
      let _sum = 0.0;
      let holdingData = chartsData[cn(e, 'Ticker')].result.topten.funds.length ? chartsData[cn(e, 'Ticker')].result.topten.funds[0].holdings : []
      e.holdingData = holdingData
        .map((el, i) => {
          _sum += el.weight;
          return {
            name: el.name,
            value: el.weight,
            color: SectorColors[i % SectorColors.length]
          }
        });
      if (e.holdingData.length > 0 && _sum < 100){
        let rem = 100 - _sum.toFixed(1);
        rem = parseFloat(rem.toFixed(1));
        e.holdingData.push({ name: 'Others', value: rem, color: '#ddd' });
      }

      //add queryCategoryValue to fund card
      // if(queryCategoryValue) e.queryCategoryValue = queryCategoryValue;
    });

    categories["categories"].forEach(e => {
      let r = getNest(['returns', 0], e);
      if (r){
        e._start = r['d'];
        e._end = e.returns[e.returns.length-1]['d'];
      }
      e.returns.forEach((item,index) => {
        e.returns[index].v = item.v/100;
      });
    });

    let filterAttrData = FilterAttrData.reduce((acc, v) => ({...acc, [v.name]: uniqValues(funds, v.col)}), {});
    filterAttrData = Object.keys(filterAttrData).reduce((acc,item) => {
      acc[item] = filterAttrData[item].filter(x => (x != 0 || x != ''))
      return acc;
    }, {});

    return { funds, categories: categories["categories"], filterAttrData };
  }

  async getFundsData(card, data) {
    // const { funds } = data;
    const _funds = await this.structuredFund(data);
    const { categories, filteredAttrData, range } = _funds;
    let { funds } = _funds;
    // const { sfm, sfmDateRange } = this.props;
    const { sfm, realTimePrice } = this.props;
    const { items } = sfm;
    if (funds && funds.length && funds.length > 0) {

      if (realTimePrice && realTimePrice.funds) {
        const updatedData = getFundsUpdatedPrice(realTimePrice, funds);
        if (updatedData) {
          funds = updatedData.funds;
        }
      }

      funds.forEach((element) => {
        if (!element.series) {
          const valTraslate = v => Math.log10((v+100)/100);
          let stats = calcStats(element.returns);
          let dd = element.returns.map(e => e.d);
          element.series = dd.map(d => {
            let ival = getNest([d, 'cuml'], stats.series)
            return { x: d, y1: ival, y3: ival }
          });
        }
        if (!element._stats) {
          // pass date-range param to get filter item list, it will update this.props.item
          const sfmDateRange = { // or else check this.props.sfmDateRange and use ?
            start: element._start,
            end: element._end,
          };
          if (funds.length && sfmDateRange && categories) {
            getFilteredListByRange({ items: funds, range: sfmDateRange, catData: categories });
          }
        }
        element._selected = true;
        card._selected = true;
      });


      const arr = funds.concat(items);
      const dataArr = uniqBy(arr, 'ticker');
      const { addFundToSFM } = this.props;

      const NEWSFMCATDATA = this.props.sfmCatData || [];

      if (typeof categories !== undefined) {
        const { setSFMCatData } = this.props;
        const newArr = uniqBy(NEWSFMCATDATA.concat(categories),'name');
        setSFMCatData(newArr);
      }
      addFundToSFM({
        items: dataArr,
        modal: true,
        open: true,
      });

      const { setInvestmentState, retData } = this.props;
      const cardNewArray = [];
      const foundT = retData.find(e => cn(e, 'Ticker') === (this.props.view === 'account' ? card.symbol : card.script));
      // console.log(foundT, card);

      if (foundT && typeof foundT._selected !== undefined) {
        foundT._selected = !foundT._selected;
        cardNewArray.push(foundT);
        // console.log(cardNewArray);
        const retDataUpdatedArray = uniqBy(retData.concat(cardNewArray),'ticker');
        // console.log(retDataUpdatedArray);
        setInvestmentState({
          retData: retDataUpdatedArray,
        });
      }

    }
    // const { setSFMCatData, sfmCatData } = this.props;
    // if (typeof categories !== undefined) {
    //   console.log(categories);
    //   setSFMCatData(categories);
    // }
  }

  getPortfolioFundData = (card) => {
    // console.log('getPortfolioFundData', card);
    // const { card: selCard } = this.props;
    // const index = this.props.items.findIndex(e => e === selCard);
    // this.props.selectHandler(card, index + 1);
    // this.props.selCardHandler(card);
  }

  addPortfolioFundToSFM = (card) => {

  }

  deleteHandler = (d) => {
    const { deleteHandler } = this.props;
    deleteHandler(d);
  }
  //
  // buyHandler = (d) => {
  //   const { buyHandler } = this.props;
  //   buyHandler(d);
  // }

  updateActiveFund = (fundId) => {
    if (fundId) {
      const { investmentActiveFund } = this.props;
      investmentActiveFund(fundId);
    }
  }

  render() {
    const { title, items, expand = false } = this.props;
    const more = items.length > 3;
    const _items = items.slice(0, expand ? 30 : 3);
    return (
      <React.Fragment>
        <MoreButton layout="holdings" more={more} expand={expand} />
        {/* {title === 'Portfolio'
          && (
            <Button className="lh-100 p-0 float-right btn-link download-pdf-btn" onClick={this.props.downloadPDF}>
              Recommended Changes
              {more && (<i className="fal fa-minus fa-rotate-90 mx-0p5"></i>)}
            </Button>
          )
        } */}
        <h5 className="mb-1 text-heading font-one">{title}</h5>
        { expand
          ? <HoldingsItemListWithScrollBar
              {...this.props}
              deleteHandler={this.deleteHandler}
              items={_items}
              actions={this.props.actions}
              itemClickHandler={this.itemClickHandler}
              getFundsData={this.getFundsData}
              updateActiveFund={this.updateActiveFund}
            />
          : <HoldingsItemList
              {...this.props}
              deleteHandler={this.deleteHandler}
              items={_items}
              buyHandler={this.buyHandler}
              itemClickHandler={this.itemClickHandler}
              getFundsData={this.getFundsData}
              updateActiveFund={this.updateActiveFund}
            />
        }
      </React.Fragment>
    )
  }
}

export const Holdings = connect(
  state => ({
    activeFund: getInvestmentActiveFund(state),
    sfm: state.sfm,
    sfmCatData: state.sfm.sfmCatData,
    sfmDateRange: state.funds.dateRange,
    view: state.investment.view,
    retData: state.investment.retData,
    realTimePrice: state.investment.realTimePrice,
  }),
  {
    investmentActiveFund,
    addFundToSFM,
    setSFMCatData,
    setInvestmentState,
    refreshSFMState,
  }
)(Holdings_);

const getActiveFunds = (items, key) => {
  if (!items && !items.length && key) return;
  const index = items.findIndex((x) => x.symbol === key);
  let symbol = key;
  if (index < 0) {
    symbol = items[0].symbol;
  }
  return symbol;
}

const HoldingsItemList = (props) => {
  const count = props.items.length;
  if (count <= 0) return <NoData height='225px' />;
  // const buyHandler = (item) => {
  //   props.buyHandler(item);
  // }
  const itemClickHandler = (item) => {
    props.itemClickHandler(item);
  }

  const activeF = getActiveFunds(props.items, props.activeFund);

  if (activeF) {
    props.updateActiveFund(activeF);
  }

  return (
    <div style={{minHeight: '225px'}}>
      { props.items.map((e, i) => <HoldingsItem
                                    {...props}
                                    key={e.symbol+'-'+i}
                                    actions={props.actions}
                                    itemClickHandler={props.itemClickHandler}
                                    item={e}
                                    last={i === count-1}
                                    getFundsData={props.getFundsData}
                                    deleteHandler={props.deleteHandler}
                                  /> ) }
    </div>
  )
}

const HoldingsItemListWithScrollBar = withScrollBar(HoldingsItemList)

const HoldingsItem = React.memo(({ item, last, ...props }) => {
  const { sfm, items: portfolioItems, addFundToSFM, refreshSFMState, setInvestmentState, retData, view } = props;
  const { items: sfmItems } = sfm;

  let foundTicker = retData.find(e => cn(e, 'Ticker') === (view === 'account' ? item.symbol : item.script ));
  // console.log(sfmItems, foundTicker);

  const buyHandler = (thisItem) => {
    const ticker = (view === 'account' ? thisItem.symbol : thisItem.script )
    // props.buyHandler(item);
    // console.log(sfmItems, foundTicker, thisItem);
    const foundT = retData.find(e => cn(e, 'Ticker') === ticker);

    if (foundT && foundT._selected) {
      // Clear fund button
      foundT._selected = false;
      const _item = sfmItems.find(e => cn(e, 'Ticker') === ticker);
      const index = sfmItems.indexOf(_item);
      if (index > -1) {
        sfmItems.splice(index, 1);
      }

      // console.log(sfmItems);

      if (sfmItems.length === 0) {
        refreshSFMState();
      }

      // console.log(sfmItems);

      addFundToSFM({
        items: sfmItems,
      })

      foundT._selected = false;
      const cardNewArray = [];
      cardNewArray.push(foundT);
      const retDataUpdatedArray = uniqBy(retData.concat(cardNewArray),'ticker');

      setInvestmentState({
        retData: retDataUpdatedArray,
      });

    } else {
      // Add fund button
      fetchFundByTicker(ticker).then((response) => {
        const { getFundsData } = props;
        getFundsData(item, response);
      })
    }

  }
  const deleteHandler = (item) => {
    props.deleteHandler(item);
  }

  if (sfmItems.length === 0) {
    retData.map(e => e._selected = false);
    item._selected = false;
  }

  return (
    <div className={classNames({"py-2p5 border-bottom": !last, "pt-2p5": last})}
      onClick={props.itemClickHandler((view === 'account' ? item.symbol : item.script ))}>
      <Row>
        <Col xs="3" className="d-flex flex-column justify-content-center align-items-center">
          <h5 className="mb-1 fs-m1 fw-b tx-c1">{(view === 'account' ? item.symbol : item.script )}</h5>
          <h3 className="mb-0 fs-m1 fw-b tx-c2">{FormatCurrency(item._price, 2)}</h3>
        </Col>
        <Col xs="6" sm="7" className="d-flex align-items-center">
          <TruncatedNameSponsor name={item.short_name} sponsor={item.sponsor} lines={1} />
        </Col>
        <Col xs="3" sm="2" className="d-flex flex-column justify-content-center">
          <Button
            outline={!foundTicker._selected}
            color={ foundTicker._selected ? "unselect" : "select" }
            className="h6 mb-0 py-1 badge-pill selectBtn"
            id="buy-fund"
            onClick={() => { buyHandler(item) }}
            style={{ position: 'relative' }}
          >{props.view == 'watchlist' ? (foundTicker._selected ? 'Clear' : 'Add') : (foundTicker._selected ? 'Clear' : 'Buy')}</Button>
          <Button
            color={"select"}
            outline
            disabled={props.view == 'watchlist' ? false : true}
            className="h6 mb-0 mt-2 py-1 badge-pill"
            onClick={() => { deleteHandler(item) }}
          >{props.view == 'watchlist' ? 'Drop' : 'Sell'}</Button>
        </Col>
      </Row>
    </div>
  )
})

export const FundActions = () => {
  return (
    <React.Fragment>
      <Button color={"select"} outline
        className="h6 mb-0 py-1 badge-pill">Buy</Button>
      <Button color={"select"} outline
        className="h6 mb-0 mt-2 py-1 badge-pill">Sell</Button>
    </React.Fragment>
  )
}


class SecurityReturns_ extends React.Component {
  render() {
    const { activeFund: { fund }, items } = this.props;
    const startDate = fund && fund._series[1].d;
    const endDate = fund && fund._series[fund._series.length - 1].d;
    const ticker = getNestDefault(['ticker'], fund, '');
    if (!items.length) {
      return (
        <React.Fragment>
          <h5 className="mb-1 text-heading font-one">Security Returns <span className="ml-1 tx-c1">{(!items.length) ? '' : ticker}</span></h5>
          <NoData height='225px' />
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <h5 className="mb-1 text-heading font-one">Security Returns <span className="ml-1 tx-c1">{`${ticker} (${startDate} - ${endDate})`}</span></h5>
        { !fund || fund.returns.length <= 0
          ? <NoData height='225px' />
        : <SecurityReturnsChart fund={fund} />
        }
      </React.Fragment>
    )
  }
}

export const SecurityReturns = connect(
  state => ({
    activeFund: getInvestmentActiveFundStats(state),
  }),
  {
  }
)(SecurityReturns_);

const SecurityReturnsChart = ({ fund }) => {
  let last = fund._series[fund._series.length-1];
  let series = [ {
    name: fund.ticker,
    data: fund._series,
    label: `$${Math.round(last.cuml+100)}`
  } ];

  return (
      <div className="" style={{minHeight: '225px', height: '225px'}}>
        <DualColorLineChart series={series} ycentered={true} xkey="d" ykey="cumlLog" ykeyLabel="v" vkey="v" />
        {/* v for change from last position
        cuml for change from start */}
      </div>
  )
}

import React from 'react'
import {
  Alert,
  Col,
  Collapse,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Button,
  Input
} from 'reactstrap'
import { PrimaryButton } from 'shared-components'
import BasicUserDetails from './BasicUserDetails'
import PremiumUserDetails from './PremiumUserDetails'

class ProfilePlanDetails extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <React.Fragment>
        <div className="profile__section profile__personal-info">
          <div className="profile__section-header">
            <Container>
              <Row>
                <Col xs={12}>
                  <h2 className="profile__section-title">
                    MY SUBSCRIPTION
                  </h2>
                </Col>
              </Row>
            </Container>
          </div>
          <Container>
            <Row>
              <Col xs={12} className="profile-description-heading">
                <h4>Plan Description</h4>
                <p>Renews on March 18, 2020</p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="profile-details-wrapper">
                <div className="profile-boxed-details">
                  {(this.props.premiumUser) ? <PremiumUserDetails /> : <BasicUserDetails />}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default ProfilePlanDetails

import React from 'react';

import {
  Tooltip,
  PieChart,
  Pie,
  Cell,
  Label,
} from 'recharts';

import NotApplicable from 'components/NotApplicable';
import { getNestDefault, convertToCapitalCase } from 'Utils';
import { cn } from 'DataSet';

export const AssetAllocationChart = (props) => {
  if(props.card.length <= 0) return <NotApplicable />;
  var mult = props.name === 'Asset Types' ? 100 : 1;
  let sum = 0.0;
  const absoluteData = props.attrs.forEach(v => {
    sum += Math.abs(cn(props.card, v));
  });
  const data = props.attrs.map(v => {
    return { name: v, value: Math.round((Math.abs(cn(props.card, v))*100/sum)), originalValue: Math.round((cn(props.card, v)*100/sum)) }
  });

  const filterData = data.filter(x => x.value > 0);

  if (filterData.length <= 0) return <NotApplicable />;

  return (
    <SimplePieChart {...props} centerText={props.centerTooltipPrefixText} data={data} centerDisplay={props.centerDisplayContent} colors={props.colors} chartName={'asset_type'}/>
  )
}

export default AssetAllocationChart;

const SimplePieChart = props => {
  const itemStyle = { fontSize: '0.75rem', padding: '0' }
  const formatter = (value, data, index) =>
    props.chartName == 'asset_type'
      ? index.payload.originalValue.toFixed(1) + '%'
      : value + '%'
  const { data, colors, centerDisplay, chartName, title, width } = props

  let centerText = props.centerText || ''
  // if (chartName === 'Holdings Diversification') {
  //   centerDisplay > 1 ? (centerText = 'Holdings') : (centerText = 'Holding')
  // } else if (chartName === 'Region Diversification') {
  //   centerDisplay > 1 ? (centerText = 'Regions') : (centerText = 'Region')
  // } else if (chartName === 'Sector Diversification') {
  //   centerDisplay > 1 ? (centerText = 'Sectors') : (centerText = 'Sector')
  // }
  // console.log(chartName, centerDisplay, centerText);

  const chartTypePresent = typeof chartName !== 'undefined' ? chartName : ''

  const primaryTextStyle =
    chartTypePresent === 'Top Holdings' ||
    chartName === 'Holdings Diversification' ||
    chartName === 'Region Diversification' ||
    chartName === 'Sector Diversification'
      ? {
          // top: '39%',
          fontSize: 11,
          fontWeight: 'bold',
          zIndex: '5'
        }
      : {}
  // const secondaryTextStyle =
  //   chartTypePresent === 'Top Holdings'
  //     ? {
  //         top: '52%',
  //         fontSize: 10
  //       }
  //     : {}
  return (
    <div className="simplePieChartWrapper" style={{ minWidth: props.width || 90 }}>
      {((centerDisplay !== '' && centerText !== '')) && (
        <div className="primaryTextStyle" style={primaryTextStyle}>
          {centerDisplay}
          <div className="highlightTooltip">
            {centerText}: {centerDisplay}
          </div>
        </div>
      )}
      <PieChart width={props.width || 90} height={props.height || 90}>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={props.width ? (props.width === 100 ? 49 : 37) : 42}
          innerRadius={props.width ? (props.width === 100 ? 22 : 17) : 22}
          isAnimationActive={false}
          fill="#8884d8"
        >
          {props.data.map((v, i) => (
            <Cell
              key={i}
              fill={v.color ? v.color : colors[i % colors.length]}
            />
          ))}{' '}
        </Pie>
        <Tooltip content={<CustomTooltip dataKey="value" />} />
      </PieChart>
    </div>
  )
}

// <Tooltip
//   wrapperClassName="ssf-tooltip"
//   formatter={formatter}
//   itemStyle={itemStyle}
// />

const CustomTooltip = props => {
  const { active, payload, dataKey } = props
  // console.log(payload, active, dataKey);
  if (active && payload) {
    const _payload = (payload.length > 0) ? payload.find(e => (e.value !== 'undefined')) : payload;
    // console.log(_payload);
    if (_payload && typeof _payload.name !== 'undefined' && typeof _payload.value !== 'undefined') {
      // console.log(_payload);
      const val = _payload.value;
      const textColor = (_payload.payload && _payload.payload.fill) ? _payload.payload.fill : '';
      return (
        <div className="recharts-default-tooltip ssf-tooltip-custom">
          <div style={{ color: textColor }}>
            <span className="label" style={{ color: textColor }}>{_payload.name+':'}</span>
            <span className="value" style={{ color: textColor }}>
              {val}
              <span className="small" style={{ color: textColor }}>%</span>
            </span>
          </div>
        </div>
      )
    } else {
      return (
        <div className="recharts-default-tooltip ssf-tooltip-custom">
          <div className="label">{'Inactive'}</div>
        </div>
      )
    }
  }

  return null
}

import React, { useContext } from 'react'
// import { Row, Col, Table } from 'reactstrap'

import { withHeaderFooter } from '../shared/HeaderFooter'
import ActReportContext from '../ActReportContext'
// import { SectorColors } from '../../../data/Colors'
import _ from 'lodash'
import { ScoreAttrs } from 'DataSet'
import { ScoreAttrName, AttrColors, joinNames, getColor, customColors } from '../shared/Utils'

import RelFundsPerf from './RelFundsPerf'
import PortfolioAddition from './PortfolioAddition'
import EnhancementScores from './EnhancementScores'
// import ImpactWeights from './ImpactWeights'
import Conclusion from './Conclusion'
import ConclusionCompareOnly from './ConclusionCompareOnly'

const RFPerformance = props => {
  const reportData = useContext(ActReportContext)
  const { allFunds, flags, isCompareOnly, logo } = reportData
  // compare section calculations
  const { bestTicker, printSelection } = reportData;
  const [{ attrs: cpAttrs, funds: cpFunds, date: cpDate }] = reportData.compare
  const cpChartData = cpAttrs.map(a =>
    cpFunds.map((f, i) => {
      const sf = allFunds.find(k => k.ticker === f.ticker)
      return {
        name: sf ? sf.ticker_short : f.ticker,
        value: f[a],
        color: customColors[i % customColors.length]
      }
    })
  )
  const cpItems = cpFunds.map(f => allFunds.find(s => s.ticker === f.ticker))

  let propsL1 = { cpItems, cpFunds, cpChartData, isCompareOnly, logo, printSelection }
  let propsL2 = { printSelection }
  // combine section calculcations
  if (!isCompareOnly) {
    const {
      all: { factor: cbFactor, funds: cbFunds, attrs: cbAttrs }
    } = reportData.combine
    const cbChartData = cbAttrs.map(a =>
      cbFunds.map((f, i) => {
        const sf = allFunds.find(k => k.ticker === f.ticker)
        return {
          name: sf ? sf.ticker_short : f.ticker,
          value: f[a],
          color: customColors[i % customColors.length],
          attr: a,
        }
      })
    )
    const cbItems = cbFunds.map(f => allFunds.find(s => s.ticker === f.ticker))

    // best securities calculations
    let {
      best: { factors: bstFactors, ticker: bstTicker, attrs: bstAttrs },
      portfolio: bstPortfolio
    } = reportData.combine
    const bstFilterScoreattrs = bstAttrs.filter(x =>
      _.find(ScoreAttrs, { name: _.startCase(_.toLower(x)) })
    )

    const findTicker = allFunds.find(k => k.ticker === bstTicker)
    bstTicker = findTicker ? findTicker.ticker_short : bstTicker

    // Custom factor - % impact for chart
    const checkFactorAlreadyExists = bstFactors.find(
      item => item.factor === cbFactor
    )
    if (!checkFactorAlreadyExists) {
      const customDetails = cbFunds.find(item => item.ticker == bstTicker)
      let customFactor = {}
      Object.keys(bstFactors[0]).forEach(function (key, index) {
        if (key === 'factor') {
          customFactor[key] = cbFactor
        } else {
          customFactor[key] = customDetails[key]
        }
      })
      bstFactors.push(customFactor)
      bstFactors = _.orderBy(bstFactors, ['factor'], ['asc'])
    }

    let bstChartData = bstFilterScoreattrs.map((a, i) => ({
      name: ScoreAttrName(a),
      data: bstFactors.map(f => ({ name: f.factor * 100 + '%', value: f[a] })),
      color: getColor(AttrColors, i)
    }))
    const bstWeights = joinNames(bstFactors.map(f => f.factor * 100 + '%'))
    propsL1 = { ...propsL1, cbFunds, bstChartData, bstTicker, bstWeights, cbFactor, cbItems, cbAttrs, cbChartData, logo, bestTicker }
    propsL2 = {
      ...propsL2,
      cbItems,
      cbFunds,
      cbAttrs,
      cbChartData,
      bstChartData,
      bstTicker,
      bstWeights,
      cbFactor,
      isCompareOnly,
      logo,
      bestTicker
    }
  }

  return (
    <React.Fragment>
      <Layout1 {...propsL1} />
      {!isCompareOnly && <Layout2 {...propsL2} />}
    </React.Fragment>
  )
}

export default RFPerformance

const Layout1 = props => {
  const {
    cpItems,
    // cpFunds,
    // cpChartData,
    // cbAttrs,
    // cbChartData,
    isCompareOnly,
    // bestTicker,
    printSelection
  } = props
  // Checking the number of rows to be displayed and adding page breaks accordingly
  // Currently the number of funds(rows) is fixed to 10 from the backend
  return (
    <React.Fragment>
      {cpItems.length < 6 ? (
        <>
          <Layout1Opt1 {...props} pageName={`${printSelection.includes('high-rel-funds') ? 'high-rel-funds' : ''}${(printSelection.includes('conclusion') && isCompareOnly) ? ',conclusion' : ''}`} />
        </>
      ) : (
        <React.Fragment>
          <Layout1Opt2 {...props} />
          {!isCompareOnly && <Layout1Opt3 {...props} />}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const Layout1Opt1 = withHeaderFooter(props => {
  const {
    cpItems,
    cpFunds,
    cpChartData,
    cbChartData,
    isCompareOnly,
    logo,
    bestTicker,
    printSelection,
    cbItems,
    cbFunds,
    cbFactor,
  } = props
  return (
    <React.Fragment>
      <div className="content">
        <div className="secWrapper">
          <RelFundsPerf
            cpItems={cpItems}
            cpFunds={cpFunds}
            cpChartData={cpChartData}
            isCompareOnly={isCompareOnly}
            logo={logo}
            showHeaderFooter={false}
            bestTicker={bestTicker}
          />
        </div>
        <div className="secWrapper" style={{ marginTop: '50px' }}>
          {!isCompareOnly && printSelection.includes('high-rel-funds') && <div className="secWrapper" style={{ marginTop: '50px' }}>
            <EnhancementScores
              cbItems={cbItems}
              cbFunds={cbFunds}
              cbChartData={cbChartData}
              cbFactor={cbFactor}
              showHeaderFooter={false}
            />
          </div>}
        </div>
        {isCompareOnly && printSelection.includes('conclusion') && (
          <div className="secWrapper" style={{ marginTop: '50px' }}>
            <ConclusionCompareOnly />
          </div>
        )}
      </div>
    </React.Fragment>
  )
})

const Layout1Opt2 = props => {
  const {
    cpItems,
    cpFunds,
    cpChartData,
    // cbAttrs,
    // cbChartData,
    isCompareOnly,
    logo,
    bestTicker,
    // printSelection
  } = props
  return <RelFundsPerf
    cpItems={cpItems}
    cpFunds={cpFunds}
    cpChartData={cpChartData}
    isCompareOnly={isCompareOnly}
    logo={logo}
    bestTicker={bestTicker}
  />
}

const Layout1Opt3 = withHeaderFooter(props => {
  const { cbItems, cbFunds, cbFactor, cbChartData } = props
  return (
    <React.Fragment>
      <div className="content">
        <div className="secWrapper" style={{ marginTop: '50px' }}>
          <EnhancementScores
            cbItems={cbItems}
            cbFunds={cbFunds}
            cbChartData={cbChartData}
            cbFactor={cbFactor}
            showHeaderFooter={false}
          />
        </div>
      </div>
    </React.Fragment>
  )
})

const Layout2 = props => {
  const {
    // cbItems,
    // cbFunds,
    // cbChartData,
    // bstChartData,
    // bstTicker,
    // cbAttrs,
    // bstWeights,
    printSelection
  } = props
  // Checking the number of rows to be displayed and adding page breaks accordingly
  // Currenlty the number of funds(rows) is fixed to 10 from the backend
  return (
    <React.Fragment>
      <Layout2Opt2 pageName={`${printSelection.includes('conclusion') ? 'conclusion' : ''}`} {...props} />
      {/*{cbItems.length < 6 ? (*/}
      {/*  <Layout2Opt1 {...props} pageName={printSelection.includes('conclusion')?'conclusion':''} />*/}
      {/*) : (*/}
      {/*  <React.Fragment>*/}
      {/*    /!*port add and conclusion *!/*/}
      {/*    <Layout2Opt2 pageName={`${printSelection.includes('conclusion')?'conclusion':''}`} {...props} />*/}
      {/*    /!*impact and conclusion*!/*/}
      {/*    /!*{(printSelection.includes('conclusion')||printSelection.includes('high-rel-funds')) && <Layout2Opt3 {...props} pageName={`${printSelection.includes('conclusion')?'conclusion':''}`} />}*!/*/}
      {/*  </React.Fragment>*/}
      {/*)}*/}
    </React.Fragment>
  )
}

const Layout2Opt1 = withHeaderFooter(props => {
  const {
    // cbItems,
    // cbFunds,
    cbChartData,
    // bstChartData,
    cbAttrs,
    // bstTicker,
    // bstWeights,
    // cbFactor,
    printSelection
  } = props
  return (
    <React.Fragment>
      <div className="content">
        {printSelection.includes('high-rel-funds') && <div className="secWrapper" style={{ marginTop: '50px' }}>
          <PortfolioAddition cbAttrs={cbAttrs} cbChartData={cbChartData} />
        </div>}
        {/*{printSelection.includes('high-rel-funds') &&<div className="secWrapper" style={{ marginTop: '50px' }}>*/}
        {/*  <ImpactWeights*/}
        {/*  bstChartData={bstChartData}*/}
        {/*  bstTicker={bstTicker}*/}
        {/*  bstWeights={bstWeights}*/}
        {/*  />*/}
        {/*</div>}*/}
        {printSelection.includes('conclusion') && <div className="secWrapper" style={{ marginTop: '50px' }}>
          <Conclusion />
        </div>}
      </div>
    </React.Fragment>
  )
})

const Layout2Opt2 = withHeaderFooter(props => {
  const {
    // cbItems,
    // cbFunds,
    cbChartData,
    // bstChartData,
    // bstTicker,
    // bstWeights,
    // cbFactor,
    printSelection,
    cbAttrs
  } = props
  return (
    <div className="content">
      {printSelection.includes('high-rel-funds') && <PortfolioAddition cbAttrs={cbAttrs} cbChartData={cbChartData} />}
      {printSelection.includes('conclusion') && <div className="secWrapper" style={{ marginTop: '50px' }}>
        <Conclusion
          pageName={'conclusion'} />
      </div>}
    </div>
  )
})

// const Layout2Opt3 = props => {
//   const {
//     cbItems,
//     cbFunds,
//     cbChartData,
//     bstChartData,
//     bstTicker,
//     bstWeights,
//     printSelection
//   } = props
//   return (
//     <React.Fragment>
//         {/*<div className="secWrapper" style={{ marginTop: '50px' }}>*/}
//         {/*  <ImpactWeights*/}
//         {/*    bstChartData={bstChartData}*/}
//         {/*    bstTicker={bstTicker}*/}
//         {/*    bstWeights={bstWeights}*/}
//         {/*  />*/}
//         {/*</div>*/}
//
//     </React.Fragment>
//   )
// })

import React from 'react'
import { StackedAreaChart } from '../../PortfolioReportV2/shared/Charts'
import { Col, Row } from 'reactstrap'
import { FormatCurrency } from '../../PortfolioReportV2/shared/Utils'

function GlideChart(props) {
  const {
    chartData,
    chartOptions,
    minMaxValues,
    avgGrowthChart,
  } = props;

  return (
    <React.Fragment>
        <div className="content">
          <div className="pr-v2-conclusion">
            <Row>
              <Col xs="12">
                <div style={{width: '970px', height: '360px', marginTop: '20px', marginBottom: '10px', position: 'relative'}}>
                  <StackedAreaChart
                    chartData={chartData}
                    chartOptions={chartOptions}
                    minMaxValues={minMaxValues}
                    showLabels
                  />
                  <div className="pr-v2-conc-desc">
                    <p className="mb-0">Year 10</p>
                    <p className="mb-0">50% Chance of having:<span>{FormatCurrency(avgGrowthChart,0,'$','unf')}</span></p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
      </div>
    </React.Fragment>
  )
}

export default GlideChart

import React, { Component } from 'react';
import './_index.scss';
import { Col, Row ,Button} from 'reactstrap';
import { addEventToAnalytics } from 'Utils';
class Info extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleClick1=()=>{
    addEventToAnalytics('Read More Shop For Investments','Read More Shop For Investments','READ_MORE_SHOP_FOR_INVESTMENTS',{});
    this.props.navigateTo('/pages/about');
  }

  handleClick2=()=>{
    addEventToAnalytics('Read More Share Knowledge','Read More Share Knowledge','READ_MORE_SHARE_KNOWLEDGE',{});
    this.props.navigateTo('/pages/widget');
  }


  render() {
    return (
        <div className="info-section r32">
            <Row className="mb-4 section-row">
                    <Col lg={6} md={12}  >
                    <div className="info-section-info">
                        <h1><span>Revolutionizing how</span> <span>you shop for investments.</span></h1>
                        <h2>Magnifi is changing the way we shop for investments. The world's first semantic search engine for finance helps discover, compare and buy investment products such as ETFs, mutual funds and stocks.</h2>
                        <Button className="info-section-button" outline color="secondary" onClick={()=>this.handleClick1()}>READ MORE</Button>
                    </div>
                    </Col>
                    <Col lg={6} md={12}>
                    <img className="info-image" src="/assets/images/homepage/Laptop_Magnifi-Details_04-uai-2064x1387-1-uai-720x484.png"/>
                    </Col>
            </Row>

            {/* <hr className="info-section-divider"></hr> */}

            {/* <Row className="mt-4 section-row" >
                    <Col lg={6} md={12}>
                        <img className="info-section-image" src="/assets/images/homepage/Widget_01-1-uai-720x323.jpg"/>
                    </Col>
                    <Col lg={6} md={12}>
                        <div className="info-section-info pl-5">
                        <h1 className="mt-0">Share the knowledge.</h1>
                        <h2>Utilize the power of our search engine on your own website. <br />Simply add a few lines of code to your existing site and you're all set.</h2>
                        <Button className="info-section-button" outline color="secondary" onClick={()=>this.handleClick2()}>READ MORE</Button>
                        </div>
                    </Col>
            </Row> */}
        </div>
    );
  }
}


export default Info

import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Tabs } from 'antd';
import {
  Nav,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalBody,
  ButtonGroup,
} from 'reactstrap';
import PremiumNoticeModal from 'components/PremiumNoticeModal';
import CSVPreviewUpload from 'components/CSVPreviewUpload';
import CustomAlert from 'components/CustomAlert';
import { Notification } from 'shared-components';
import { createLoadingSelector } from 'selectors/loading-selector';
import { getPortfolioStats } from 'selectors/investment';
import {
  investmentOptions,
  fetchPortfolioData,
  deletePortfolio,
  uploadPortfolio,
  getRealTimePriceForTickersRequest,
} from 'actions/investment';
import {
  subscriptionPlan,
  setProfileState,
} from 'actions/profile';
import {
  setEnhancerState,
  checkTickerRequest,
  checkSingleTickerValidityRequest,
  verifyCSVTickerAndPriceRequest,
  uploadCSVFileRequest,
  uploadCSVFileFetch,
  uploadCSVFileSuccess,
  uploadPortfolioDataByIdRequest,
  uploadPortfolioCSVRequest,
} from 'actions/enhancer';
import { uploadSingleClientRequest } from 'actions/clientportfolioV2';
import { fetchProfile } from 'actions/profile';
import {
  uniqValues,
  mapCsvToJSON,
  addEventToAnalytics,
  findDelimiter,
  convertToUpperCase,
  convertToLowerCase,
  updateTickerData,
  calculatePortfolioDataFormat,
} from 'Utils';
import ClientUploadModal from './ClientUploadModal';
import ClientUpload from './ClientUpload';
import { demoPortfolioJSON } from '../utils';
import { Loader } from '../../../Elements/Loader';

const { TabPane } = Tabs;

class UploadSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      portfolioDropdownOpen: false,
      uplPrtModal: false,
      notify: false,
      isNotifyOpen: false,
      csvVisible: false,
      csvDataList: [],
      validTickers: [],
      invalidTickers: [],
      csvFormatErrorMessage: '',
      messageModal: false,
      uploadFileFor: '',
      activeTab: 'single',
      weightCalculatedFromCSVBy: null,
    };
  }

  componentWillReceiveProps(newProps) {
    if (((newProps.tickerNotExist && newProps.tickerNotExist.length > 0) || (newProps.tickerNotSupported && newProps.tickerNotSupported.length > 0)) && !this.state.messageModal) {
      this.setState({ messageModal: false, csvVisible: true });
    }
  }

  uploadFileFor = () => {
    const { fileFor, portfolioID } = this.props;
    this.setState({ uploadFileFor: fileFor });
    switch (fileFor) {
      case 'Demo_portfolio':
        this.uploadFileForDemo();
      break;
      case 'MarketPlace_Portfolio':
        if (document.getElementById('sponsored-portfolio-dropdown')) {
          document.getElementById('sponsored-portfolio-dropdown').click();
        }
        this.uploadFileForMarketPlace(portfolioID);
      break;
      case 'Client_Portfolio':
        if (document.getElementById('clientSearch')) {
          document.getElementById('clientSearch').click();
        }
        this.uploadFileForClientPortfolio(portfolioID);
      break;
      case 'Custom_portfolio':
        this.openUplPrt();
      break;
      default:
    }
  }

  uploadFileForClientPortfolio = (selectedClientPortfolio) => {
    console.log('--------- uploadFileForClientPortfolio ----------');
    console.log('selectedClientPortfolio =>', selectedClientPortfolio);
    const { uploadPortfolioDataByIdRequest, uploadCSVFileSuccess, verifyCSVTickerAndPriceRequest } = this.props;
    // NOTE: Step: 1, fetch Holdings data for selected MarketPlace ID
    try {
      if (selectedClientPortfolio && selectedClientPortfolio.original_portfolio && selectedClientPortfolio.name) {
        const { name: _clientPortfolioName, original_portfolio_id: _selectedClientPortfolioId, original_portfolio: _selectedClientPortfolioTicker } = selectedClientPortfolio;
        if (uploadPortfolioDataByIdRequest) {
          const requestObj = {
            portfolioType: 'Client Portfolio',
            filename: _selectedClientPortfolioTicker,
            selectedPortfolioName: _clientPortfolioName,
            selectedPortfolioTicker: _selectedClientPortfolioTicker,
            selectedPortfolioId: _selectedClientPortfolioId,
          };
          uploadPortfolioDataByIdRequest(requestObj, (res) => {
            if (res && res.csvFormatPortfolioData && res.csvFormatPortfolioData.length > 0 && res.tickers && typeof res.realTimePrice !== 'undefined') {
              console.log(res);
              const { tickers, realTimePrice, csvFormatPortfolioData } = res;
              this.setState({
                JSONData: csvFormatPortfolioData,
              }, () => {
                // NOTE: Step: 2, Map 'verifyCSVTickerAndPriceRequest' data with 'JSONData';
                if(tickers && tickers.length > 0) {
                  this.mapRealTimeToJSONData(realTimePrice);
                } else {
                  throw `-- To verifyCSVTickerAndPriceRequest, tickers ${tickers} is not valid --`;
                }
              });
            } else {
              throw `-- To fetch data, sponsored ticker ${res.tickers} or realTimePrice ${res.realTimePrice} or portfolio funds ${res.csvFormatPortfolioData} is missing --`;
            }
          });
        } else {
          throw `-- Redux actions uploadPortfolioDataByIdRequest missing --`;
        }
      } else if (selectedClientPortfolio && selectedClientPortfolio.name && selectedClientPortfolio.name === 'Demo Account') {
        console.log('=== Upload demo portfolio for client accounts ===');
        this.onFileSuccess(selectedClientPortfolio);
      } else {
        throw `-- To fetch data, sponsored ticker ${selectedClientPortfolio} is missing --`;
      }
    } catch (e) {
      console.log(e);
    }
  }

  uploadFileForMarketPlace = (selectedMarketPlaceData) => {
    console.log('--------- uploadFileForMarketPlace ----------');
    console.log('selectedMarketPlaceData =>', selectedMarketPlaceData);
    const { uploadPortfolioDataByIdRequest, uploadCSVFileSuccess, verifyCSVTickerAndPriceRequest } = this.props;
    // NOTE: Step: 1, fetch Holdings data for selected MarketPlace ID
    try {
      if (selectedMarketPlaceData && selectedMarketPlaceData.ticker && selectedMarketPlaceData.name) {
        const { name: _sponsoredPortfolioName, ticker: _selectedMarketPlaceId } = selectedMarketPlaceData;
        if (uploadPortfolioDataByIdRequest) {
          const requestObj = {
            portfolioType: 'MarketPlace',
            filename: _selectedMarketPlaceId,
            selectedPortfolioName: _sponsoredPortfolioName,
          };
          uploadPortfolioDataByIdRequest(requestObj, (res) => {
            if (res && res.csvFormatPortfolioData && res.csvFormatPortfolioData.length > 0 && res.tickers && typeof res.realTimePrice !== 'undefined') {
              const { tickers, realTimePrice, csvFormatPortfolioData } = res;
              this.setState({
                JSONData: csvFormatPortfolioData,
              }, () => {
                // NOTE: Step: 2, Map 'verifyCSVTickerAndPriceRequest' data with 'JSONData';
                if(tickers && tickers.length > 0) {
                  this.mapRealTimeToJSONData(realTimePrice);
                } else {
                  throw `-- To verifyCSVTickerAndPriceRequest, tickers ${tickers} is not valid --`;
                }
              });
            } else {
              throw `-- To fetch data, sponsored ticker ${res.tickers} or realTimePrice ${res.realTimePrice} or portfolio funds ${res.csvFormatPortfolioData} is missing --`;
            }
          });
        } else {
          throw `-- Redux actions uploadPortfolioDataByIdRequest missing --`;
        }
      } else {
        throw `-- To fetch data, sponsored ticker ${selectedMarketPlaceData} is missing --`;
      }
    } catch (e) {
      console.log(e);
    }
  }

  uploadFileForDemo = () => {
    addEventToAnalytics('Enhancer Demo','Enhancer Demo','ENHANCER_DEMO',{},false);
    console.log('--------- uploadFileForDemo ----------');
    if (demoPortfolioJSON) {
      // NOTE: Step: 1, call 'verifyCSVTickerAndPriceRequest' api for tickers
      this.setState({
        JSONData: demoPortfolioJSON,
      }, () => {
        this.processDemoPortfolioData(this.state.JSONData);
      });
    }
  }

  processDemoPortfolioData = (JSONData) => {
    const { uploadCSVFileSuccess } = this.props;
    let tickers = demoPortfolioJSON.map((r) => r.ticker);
    // NOTE: Step: 2, Map 'verifyCSVTickerAndPriceRequest' data with 'JSONData';
    if(tickers && tickers.length > 0){
      tickers = tickers.join();
      const { verifyCSVTickerAndPriceRequest } = this.props;
      if (verifyCSVTickerAndPriceRequest) {
        verifyCSVTickerAndPriceRequest({ tickers }, this.mapRealTimeToJSONData);
      }
    }
  }

  mapRealTimeToJSONData = (realTimeTickerPrice) => {
    // NOTE:
    /*
    given input of each row:
    {
    "ticker": "ACWI",
    "price": 74.53924915,
    "shares": 1172,
    "date": "25-07-2019"
    }

    After mapping expected output of each row sample:
    {
    date: "25-07-2019"
    price: 74.53924915
    realTimePrice: 80.8562
    shares: 1172
    ticker: "ACWI"
    values: 94763.4664
    weight(%): 11.87388352446596
    }
    */
    if (realTimeTickerPrice) {
      const { funds } = realTimeTickerPrice;
      let sumOfPriceAndShares = 0;
      const { portfolioValue } = this.props;
      const mappedArray = calculatePortfolioDataFormat(this.state.JSONData, realTimeTickerPrice, portfolioValue);
      // console.log(mappedArray);
      if (mappedArray && mappedArray.length > 0) {
        this.setState({ csvDataList: mappedArray, csvFormatErrorMessage: '', uplPrtModal: false, csvVisible: true }, () => {
          this.showOnlyTickersAvailable();
        });
      } else {
        console.log('-- Failed to map and classify valid/invallid CSV data --');
      }
    }
  }

  openUplPrt = () => {
    addEventToAnalytics('Enhancer Upload','Enhancer Upload','ENHANCER_UPLOAD',{},false);
    this.setState({ uplPrtModal: true })
  }

  openNotify = () => {
    this.setState({
      notify: true,
    });
  }

  notifyToggle = () => {
    this.setState({
      notify: !this.state.notify,
    });
  }

  getCSVData = (data, fileList) => {
    const { uploadPortfolioCSVRequest } = this.props;
    if (data && data.name) {
      var reader = new FileReader();
      reader.onload = () => {
        if (uploadPortfolioCSVRequest) {
          let formData = new FormData();
          formData.append("file", data);
          formData.append("name", data.name);
          uploadPortfolioCSVRequest(formData, (response) => {
            // console.log('uploadPortfolioCSVRequest response', response);
            let validTickers = [], invalidTickers = [];
            if (response) {
              this.processCsvPreviewDataFromAPI(response)
            } else {
              console.log('-- response issue --');
            }
          });
        }
        // NOTE: to enable UI end CSV conversion and preview logic, uncomment below line
        // this.processUploadData(reader.result)
      };
      // start reading the file. When it is done, calls the onload event defined above.
      reader.readAsBinaryString(data);
    }
  }

  processCsvPreviewDataFromAPI = (clientList, tickerNamesForCSVPreview = [], noData = false) => {
    // const filters = { headersArray: ['ticker','price','shares','date','lot date','weight','amount'], maxHeadersCount: 6, addHeader: ['weight(%)'] };
    const { portfolioValue, mode } = this.props;
    // let clientList = JSON.parse(mapCsvToJSON(this.state.csvData, filters, realTimeTickerPrice, noData, portfolioValue));
    // console.log('clientList-- >', clientList);
    if (typeof clientList.error !== 'undefined') {
      this.setState({
        csvFormatErrorMessage: clientList.error || '',
      });
    } else {
      let weightCalculatedFromCSVBy = this.state.weightCalculatedFromCSVBy;
      let headerRow = clientList.filter((item) => (item.ticker && item.ticker !== '' && item.ticker === 'ticker'));
      let filteredClientList = clientList.filter((item) => (item.ticker && item.ticker !== '' && item.ticker !== 'ticker'));

      if (filteredClientList && filteredClientList.length > 0) {
        filteredClientList = filteredClientList.map((kl) => {
          // NOTE:  Deleting 'name' for client upload only
          if (kl && kl.name) {
            delete kl.name;
          }
          weightCalculatedFromCSVBy = kl.weight_calculated_format;
          return kl;
        }).filter((item) => item)
      }

      if (mode === 'create') {
        this.setState({ emptyCSVModal: true });
      } else {
        this.setState({ csvVisible: true });
      }

      let sumOfAllWeights = filteredClientList.filter((item) => item.original_weight !== 'NA' && item.original_weight !== '' && typeof item.original_weight !== 'undefined').map((item) => item.original_weight).reduce((a, b) => a + b, 0);
      let sumOfAllValues = filteredClientList.filter((item) => item.values !== 'NA' && item.values !== '' && typeof item.values !== 'undefined').map((item) => item.values).reduce((a, b) => a + b, 0);
      let _portfolioValueWillBe = this.props.portfolioValue;
      // console.log(headerRow);

      if (headerRow &&
        headerRow.length > 0 &&
        headerRow[0] &&
        Object.keys(headerRow[0]).length > 0 &&
        filteredClientList &&
        filteredClientList.length > 0 &&
        filteredClientList[0] &&
        typeof filteredClientList[0].weight_calculated_format !== 'undefined'
      ) {
        if (Object.keys(headerRow[0]).length > 0) {
          let rows = Object.keys(headerRow[0]);
          let portfolioValueBySMV = rows.find((item) => item === 'shares' || item === 'amount' || item === 'values')
          if (portfolioValueBySMV) {
            // console.log(' --- portfolioValueBySMV ---> ', portfolioValueBySMV);
            // set portfolioValue by sum of all amounts (bcoz shares||amount||values present in csv)
            _portfolioValueWillBe = sumOfAllValues
          } else {
            _portfolioValueWillBe = this.props.portfolioValue
          }
        }
        // _portfolioValueWillBe = // allData[0].weight_calculated_format === 'weight' ? this.props.portfolioValue : sumOfAllValues;
      }

      // console.log('portfolioValueWillBe --> ', _portfolioValueWillBe);
      // if (!weightCalculatedFromCSVBy && filteredClientList && filteredClientList[0] && filteredClientList[0].weight_calculated_format) {
      //   weightCalculatedFromCSVBy = filteredClientList[0].weight_calculated_format;
      // }

      this.setState({
        weightCalculatedFromCSVBy,
        csvDataList: filteredClientList,
        csvFormatErrorMessage: '',
        uplPrtModal: false,
        portfolioValueWillBe: _portfolioValueWillBe,
        allocationTypeValue: (weightCalculatedFromCSVBy === 'values' || weightCalculatedFromCSVBy === 'value' || weightCalculatedFromCSVBy === 'amount') ? 'value' : 'weight', // 'value' for toggle "&" "%" in csv preview modal
      }, () => {
        // console.log('allocationTypeValue --> ', this.state.allocationTypeValue, weightCalculatedFromCSVBy);
        this.categorizeTickers();
      });
    }
  }

  categorizeTickers = () => {
    // const { tickerNotExist, tickerNotSupported } = this.props;
    const { csvDataList } = this.state;
    let validTickers = [];
    let invalidTickers = [];
    csvDataList.forEach((u, i) => {
      if (!u.invalid && u.invalidType === 'supported') {
        u.key = i;
        u.validTicker = true;
        u.invalidType = 'supported';
        validTickers.push(u);
      } else {
        if (u.invalidType === 'unrecognized') {
          u.invalidType = 'unrecognized';
          u.message = 'Ticker not identified';
        } else if (u.invalidType === 'unsupported') {
          u.invalidType = 'unsupported';
          u.message = 'Ticker not supported';
        }
        u.key = i;
        u.validTicker = false;
        u.showInMissingTicker = true;
        invalidTickers.push(u);
      }
    });

    this.setState({
      validTickers,
      invalidTickers,
    })
  }

  processUploadData = (csvData) => {
    const { uploadCSVFileSuccess, getRealTimePriceForTickersRequest, setEnhancerState } = this.props;
    this.setState({
      csvDataList: [],
      messageModal: false,
      csvVisible: false,
    }, () => {
      setEnhancerState({ tickerNotExist: [], tickerNotSupported: [] });
    });

    let lines = csvData.split("\n");
    const identified_delimiter = findDelimiter(csvData);
    let tickers = '';
    let headers = lines[0].split(identified_delimiter);
    headers = headers.map(item => convertToLowerCase(item));
    let tickerIndex = headers.indexOf('ticker');

    /* eslint-disable */
    for (let i=1; i<lines.length; i++) {
      let currentline = lines[i].split(identified_delimiter);
      if(currentline[tickerIndex]){
        tickers = i == 1 ? tickers+convertToUpperCase(currentline[tickerIndex]) : tickers+','+convertToUpperCase(currentline[tickerIndex])
      }
    }

    this.setState({
      csvData
    }, () => {
      const { uploadCSVFileSuccess } = this.props;
      if (uploadCSVFileSuccess) {
        uploadCSVFileSuccess();
      }
      if(tickers){
        const { verifyCSVTickerAndPriceRequest } = this.props;
        if (verifyCSVTickerAndPriceRequest) {
          verifyCSVTickerAndPriceRequest({ tickers }, this.buildClientList);
        }
      }else{
        this.buildClientList([], true)
      }
    });
  }

  buildClientList = (realTimeTickerPrice, noData=false) => {
    const filters = { headersArray: ['ticker','price','shares','date','lot date','weight','amount'], maxHeadersCount: 6, addHeader: ['weight(%)'] };
    const { portfolioValue } = this.props;
    const clientList = JSON.parse(mapCsvToJSON(this.state.csvData, filters, realTimeTickerPrice, noData, portfolioValue));
    if (typeof clientList.error !== 'undefined') {
      this.setState({
        csvFormatErrorMessage: clientList.error || '',
      });
    } else {
      const filteredClientList = clientList.filter((item) => (item.ticker && item.ticker !== '' && item.ticker !== 'ticker'));
      this.setState({ csvDataList: filteredClientList, csvFormatErrorMessage: '', uplPrtModal: false, csvVisible: true }, () => {
        const { checkTickerRequest } = this.props;
        const tickersList = this.state.csvDataList.map((u) => {
          if (u.ticker && u.ticker !== '' && u.ticker !== 'ticker') {
            return u.ticker;
          }
        });
        this.showOnlyTickersAvailable();
      });
    }
  }

  showOnlyTickersAvailable = () => {
    const { tickerNotExist, tickerNotSupported } = this.props;
    const { csvDataList } = this.state;
    let validTickers = [];
    let invalidTickers = [];
    csvDataList.forEach((u, i) => {
      if (typeof tickerNotExist.find((k) => k === u.ticker) === 'undefined' && typeof tickerNotSupported.find((k) => k === u.ticker) === 'undefined') {
        u.key = i;
        u.validTicker = true;
        u.invalidType = 'supported';
        validTickers.push(u);
      } else {
        if (typeof tickerNotExist.find((k) => k === u.ticker) !== 'undefined') {
          u.invalidType = 'unrecognized';
          u.message = 'Ticker not identified';
        } else if (typeof tickerNotSupported.find((k) => k === u.ticker) !== 'undefined') {
          u.invalidType = 'unsupported';
          u.message = 'Ticker not supported';
        }
        u.key = i;
        u.validTicker = false;
        u.showInMissingTicker = true;
        invalidTickers.push(u);
      }
    });
    this.setState({
      validTickers,
      invalidTickers
    })
  }

  checkTicker = (ticker, callback) => {
    const { checkTickerRequest } = this.props;
    if (checkTickerRequest && ticker && callback) {
      checkTickerRequest({ tickers: [ticker] }, callback);
    }
  }

  csvOnClose = () => {
    this.props.setEnhancerState({ tickerNotExist: [], tickerNotSupported: [] });
    this.setState({
      csvVisible: false,
      csvDataList: [],
      validTickers: [],
      invalidTickers: []
    });
  }

  handleUploadSubmit = () => {
    const updatedTickers = [...this.state.validTickers, ...this.state.invalidTickers];

    let validTickers = [];
    let invalidTickers = [];
    updatedTickers.forEach((u) => {
      if(u.showInMissingTicker){
        invalidTickers.push(u)
      }else{
        validTickers.push(u)
      }
    })
    this.setState({
      validTickers,
      invalidTickers
    }, () => this.csvOnClickOk(updatedTickers));
  }

  csvOnClickOk = (dataJson) => {
    if (dataJson) {
      const {
        onboardScreenData,
        originalCSVFormatPortfolioData,
        originalCSVFormatPortfolioTickers,
        sponsoredPortfolioName,
        uploadedPortfolioTicker,
        selectedPortfolioName,
        selectedPortfolioID,
      } = this.props;
      if (onboardScreenData && onboardScreenData.selectedPortfolioType === 'MarketPlace') {
        // upload for Sponsored Portfolio
        console.log(' ==== MarketPlace CSV data ==== ');
        const reqJSONTickers = dataJson.map((jk) => jk.ticker);
        const notMatchTickers = originalCSVFormatPortfolioTickers.filter((tick) => !reqJSONTickers.includes(tick));
        //step: if TICKER has changed (or edit operation performed)
        if (notMatchTickers.length > 0) {
          // and not matched with the ones uploaded from dropdown then call 'upload-portfolio' api
          this.props.setEnhancerState({ tickerNotExist: [], tickerNotSupported: [] });
          this.setState({ messageModal: false });
          if (this.props.fileName !== '') {
            this.props.uploadSingleClientRequest({ data: dataJson, "file_name": this.props.fileName }, this.onFileSuccess);
          } else {
            this.props.uploadSingleClientRequest({ data: dataJson }, this.onFileSuccess);
          }
        } else {
          // or else "NO" change/update/edit operation performed, then call 'model-portfolio' api
          //by passing SPONSORED_PORTFOLIO_TICKER which was selected from dropdown
          if (uploadedPortfolioTicker && sponsoredPortfolioName) {
            const sponsoredObj = {
              ticker: uploadedPortfolioTicker,
              name: sponsoredPortfolioName,
            };
            // console.log(sponsoredObj);
            this.onFileSuccess(sponsoredObj);
          } else {
            console.log(`--- uploadedPortfolioTicker ${uploadedPortfolioTicker} or sponsoredPortfolioName ${sponsoredPortfolioName} is missing ---`);
          }
        }
      } else if (onboardScreenData && onboardScreenData.selectedPortfolioType === 'Client Portfolio') {
        // upload for Client Portfolio
        console.log(' ==== Client Portfolio CSV data ==== ');
        const reqJSONTickers = dataJson.map((jk) => jk.ticker);
        const notMatchTickers = originalCSVFormatPortfolioTickers.filter((tick) => !reqJSONTickers.includes(tick));
        //step: if TICKER has changed (or edit operation performed)
        if (notMatchTickers.length > 0) {
          // and not matched with the ones uploaded from dropdown then call 'upload-portfolio' api
          this.props.setEnhancerState({ tickerNotExist: [], tickerNotSupported: [] });
          this.setState({ messageModal: false });
          if (this.props.fileName !== '') {
            this.props.uploadSingleClientRequest({ data: dataJson, "file_name": this.props.fileName }, this.onFileSuccess);
          } else {
            this.props.uploadSingleClientRequest({ data: dataJson }, this.onFileSuccess);
          }
        } else {
          // or else "NO" change/update/edit operation performed, then call 'model-portfolio' api
          //by passing CLIENT_PORTFOLIO_TICKER which was selected from dropdown
          if (uploadedPortfolioTicker &&
            selectedPortfolioName &&
            selectedPortfolioName !== '' &&
            typeof selectedPortfolioName !== 'undefined' &&
            selectedPortfolioID &&
            typeof selectedPortfolioID !== 'undefined' &&
            selectedPortfolioID !== ''
          ) {
            const clientRequestObj = {
              original_portfolio: uploadedPortfolioTicker,
              original_portfolio_id: selectedPortfolioID,
            };
            // console.log(clientRequestObj);
            this.onFileSuccess(clientRequestObj);
          } else {
            console.log(`--- uploadedPortfolioTicker ${uploadedPortfolioTicker} or selectedPortfolioName ${selectedPortfolioName} is missing ---`);
          }
        }
      } else {
        // upload for My Portfolio
        this.props.setEnhancerState({ tickerNotExist: [], tickerNotSupported: [] });
        this.setState({ messageModal: false });
        if (this.props.fileName !== '') {
          this.props.uploadSingleClientRequest({ data: dataJson, "file_name": this.props.fileName }, this.onFileSuccess);
        } else {
          this.props.uploadSingleClientRequest({ data: dataJson }, this.onFileSuccess);
        }
      }
    }
  }

  onFileSuccess = (responseOBJ) => {
    console.log('=== onFileSuccess ===> ', responseOBJ);
    this.setState({ uplPrtModal: false, isNotifyOpen: true }, () => {
      this.csvOnClose();
      const { onFileUploadSuccess, fetchProfile } = this.props;
      if (
        responseOBJ &&
        onFileUploadSuccess &&
        responseOBJ.uploaded &&
        Array.isArray(responseOBJ.uploaded) &&
        responseOBJ.uploaded.length > 0 &&
        responseOBJ.uploaded[0].name &&
        responseOBJ.uploaded[0].portfolio_id
      ) {
        if (fetchProfile) {
          fetchProfile();
        }
        onFileUploadSuccess({
          original_portfolio_id: responseOBJ.uploaded[0].portfolio_id,
          original_portfolio: responseOBJ.uploaded[0].portfolio_name,
        });
      }
      setTimeout(() => {
        this.setState({ isNotifyOpen: false })
      }, 3000);
    });
  }

  // delete operation triggers getUpdatedData
  getUpdatedData = (data) => {
    const dataJson = JSON.parse(JSON.stringify(data));
    this.setState({ validTickers: dataJson }, () => {
      this.getUpdatedPortfolioDataState();
    });
  }

  closeMessageModal = (doesClientExist) => {
    this.setState({ messageModal: false, csvVisible: doesClientExist });
  }

  getTickerList = (items) => {
    return items.reduce((acc, curr, i) =>
    i !== 0 ? acc + `${items.length - 1 === i ? ` and  ` : ', '}` + curr : curr, '');
  }

  uploadModalToggle = () => {
    this.setState({
      csvFormatErrorMessage: '', // dont change this
      uplPrtModal: false,
    });
  }

  updateTickerStatus = (invalidData) => {
    const { checkSingleTickerValidityRequest } = this.props;
    const { updatedTicker, selectedRecord } = invalidData;
    if (updatedTicker && updatedTicker !== selectedRecord.ticker) {
      const invalidTickers = this.state.invalidTickers.map(item => {
        if(item.key == selectedRecord.key){
          item.loading = true;
        }
        return item;
      })
      this.setState({
        invalidTickers
      }, () => checkSingleTickerValidityRequest(invalidData, this.updateCSVData));
    }
  }

  // edit operation triggers updateCSVData
  updateCSVData = (oldTicker, selectedRecord, updateStatus) => {
    let invalidTickers = this.state.invalidTickers.map(obj => {
      let item = Object.assign({}, obj);
      if(item.key == selectedRecord.key) {
        item.ticker = selectedRecord.ticker;
        item.invalid = (item.invalid === true) ? !updateStatus : item.invalid;
        item.validTicker = updateStatus;
        item.message = updateStatus ? 'Valid Ticker' : 'Ticker not identified';
        item.loading = false;
        item.showInMissingTicker = true;
        item.realTimePrice = selectedRecord.realTimePrice || 'NA';
      }
      return item;
    })
    // console.log(invalidTickers);
    this.setState({
      invalidTickers
    }, () => {
      this.getUpdatedPortfolioDataState();
    })
  }

  getUpdatedPortfolioDataState = () => {
    const updatedTickers = [...this.state.validTickers, ...this.state.invalidTickers];
    const { portfolioValue } = this.props;
    updatedTickers.forEach((item, i) => {
      if (item.weight && typeof item.weight_calculated_format !== 'undefined' && item.weight_calculated_format === 'weight') { delete item.values; delete item.shares; delete item.amount; };
      if (item.shares && typeof item.weight_calculated_format !== 'undefined' && item.weight_calculated_format === 'shares') { delete item.values; delete item.weight; delete item.amount; };
      if (item.amount && typeof item.weight_calculated_format !== 'undefined' && item.weight_calculated_format === 'amount') { delete item.values; delete item.shares; delete item.weight; };
    });
    const allTickers = updateTickerData(updatedTickers, portfolioValue, true); // OVER_RIDE_DATA = true;
    let validTickers = [];
    let invalidTickers = [];
    allTickers.forEach((u) => {
      if(u.showInMissingTicker){
        invalidTickers.push(u)
      }else{
        validTickers.push(u)
      }
    })
    this.setState({
      validTickers,
      invalidTickers
    });
  }

  deleteInvalidTickers = (selectedRecord) => {
    let invalidTickers = this.state.invalidTickers.filter(item => item.key !== selectedRecord.key);
    this.setState({
      invalidTickers
    }, () => {
      this.getUpdatedPortfolioDataState();
    })
  }

  tabOnChange = (activeTab) => {
    this.setState({ activeTab });
  }

  clientUploadModalToggle = (toggle) => {
    this.setState({ uplPrtModal: false }, () => {
      const { clientUploadModalToggle } = this.props;
      if (clientUploadModalToggle) {
        clientUploadModalToggle(toggle);
      }
    });
  }

  render() {
    const { portfolioDropdownOpen, uplPrtModal, premiumModal, notify, isNotifyOpen, csvVisible, csvFormatErrorMessage, activeTab } = this.state;
    const {
      premiumUser,
      uploadFilePartial,
      uploadFilePartialMsg,
      setEnhancerState,
      children,
      className,
      childrenWrapClassName,
      tickerNotExist,
      csvValidating,
      fileFor,
      portfolioLoading,
      prefixTag,
      renderClientUpload,
    } = this.props;

    let { csvDataList, validTickers, invalidTickers } = this.state;
    let CSVPreviewUploadTOP = 60;
    const headerElement = document.getElementById("magnifi-header-wrapper");
    if (headerElement) {
      CSVPreviewUploadTOP = `${headerElement.offsetHeight}px`;
    }

    return (
      <div className={className}>
        {prefixTag}
        <div
          className={childrenWrapClassName}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.uploadFileFor()
          }}
          >
          {children}
        </div>
        {(this.state.uploadFileFor === fileFor) && (
          <div>
            <Modal
              centered
              keyboard
              backdrop
              isOpen={uplPrtModal}
              className="premium-msg-modal account-modal client-portfolio-upload-modal"
              backdropClassName="premium-feature-process-modal-backdrop top-60px"
              modalClassName="top-60px"
              toggle={() => this.uploadModalToggle()}
            >
              <ModalBody className="ssf-modal__body" style={{ minHeight: 212 }}>
                <div className="premium-feature-process-modal-container">
                  <div className="header-wrapper">
                    <h1 className="text-align-left">Upload And View Analytics On Client Account</h1>
                  </div>
                  <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => this.uploadModalToggle()}></i>
                  <Tabs
                    centered
                    size="small"
                    defaultActiveKey="single"
                  >
                    <TabPane tab={<b>Add Single Account</b>} key="single">
                      <p className="content-wrapper">
                        <ClientUpload
                          getCSVData={this.getCSVData}
                          csvFormatErrorMessage={csvFormatErrorMessage}
                        />
                      </p>
                    </TabPane>
                    <TabPane tab={<b>Add Multiple Accounts</b>} key="multiple">
                      <div className="client-upload-modal-container">
                        <ClientUploadModal
                          {...this.props}
                          clientUploadModalToggle={this.clientUploadModalToggle}
                        />
                        <a
                          className="dld-sample"
                          href="/assets/data/mock_client_accounts.csv"
                          style={{ textAlign: 'right' }}
                          onClick={() => {
                            addEventToAnalytics('Client Download Sample', 'Client Download Sample', 'CLIENT_DOWNLOAD_SAMPLE', {}, false)
                          }}
                        >
                          Download Sample
                        </a>
                      </div>
                      {(renderClientUpload) && renderClientUpload}
                    </TabPane>
                  </Tabs>
                </div>
              </ModalBody>
            </Modal>
            {csvVisible && !this.state.messageModal && ( validTickers.length > 0 || invalidTickers.length > 0 ) && (
              <CSVPreviewUpload
                modalClassName="csv-preview-upload"
                modalTitle={<div class="title-head">Uploaded Portfolio</div>}
                width={600}
                centered={true}
                footer={null}
                maskStyle={{ top: CSVPreviewUploadTOP }}
                visble={csvVisible}
                data={validTickers}
                invalidTickers={invalidTickers}
                loading={csvValidating}
                deleteInvalidTickers={this.deleteInvalidTickers}
                onModalClose={this.csvOnClose}
                getDataList={this.getUpdatedData}
                onClickOk={this.handleUploadSubmit}
                checkTicker={this.checkTicker}
                updateTickerStatus={this.updateTickerStatus}
                disclaimerMessage={<div className="disclaimer-text mb-0"><i class="fas fa-asterisk"></i>Deleting a position will remove the security entirely from the analysis. The security's allocation will be pro-rated to the other securities in the portfolio.</div>}
              />
            )}
            {(this.props.uploadFileFailure || this.props.uploadfileDuplicate) && (
              <CustomAlert className="alert-windown-wrapper" color='danger'>
                {this.props.uploadfileDuplicate ? 'Label already exists' : this.props.uploadFileFailureMsg}
              </CustomAlert>
            )
          }
          {isNotifyOpen
            && (
              <Notification
                isNotifyOpen={isNotifyOpen}
                notifyClassName="notify-card"
                notifyBody="File uploaded successfully"
              />
            )
          }
        </div>
      )}
    </div>
  );
}
}


export default connect(
  state => ({
    premiumUser: state.auth.user.premiumUser,
    uploadFilePartial: state.enhancer.uploadFilePartial,
    uploadFilePartialMsg: state.enhancer.uploadFilePartialMsg,
    uploadFileFailure: state.enhancer.uploadFileFailure,
    uploadFileFailureMsg: state.enhancer.uploadFileFailureMsg,
    uploadfileDuplicate: state.enhancer.uploadfileDuplicate,
    tickerNotExist: state.enhancer.tickerNotExist,
    tickerNotSupported: state.enhancer.tickerNotSupported,
    fileName: state.enhancer.fileName,
    portfolioValue: state.profile.portfolioValue,
    onboardScreenData: state.enhancer.onboardData,
    originalCSVFormatPortfolioData: state.enhancer.originalCSVFormatPortfolioData,
    originalCSVFormatPortfolioTickers: state.enhancer.originalCSVFormatPortfolioTickers,
    uploadedPortfolioTicker: state.enhancer.uploadedPortfolioTicker,
    sponsoredPortfolioName: state.enhancer.sponsoredPortfolioName,
    selectedPortfolioName: state.enhancer.portfolioName,
    selectedPortfolioID: state.enhancer.portfolioID,
    csvValidating: createLoadingSelector(['VERIFY_CSV_TICKER_AND_PRICE'])({ loading: state.loading }),
  }),
  {
    investmentOptions,
    fetchPortfolioData,
    setEnhancerState,
    checkTickerRequest,
    uploadSingleClientRequest,
    getRealTimePriceForTickersRequest,
    checkSingleTickerValidityRequest,
    uploadCSVFileRequest,
    uploadCSVFileFetch,
    uploadCSVFileSuccess,
    verifyCSVTickerAndPriceRequest,
    uploadPortfolioDataByIdRequest,
    fetchProfile,
    uploadPortfolioCSVRequest,
  }
)(UploadSection);

const Spinner = () => {
  return (
    <div className="spinner-wrapper">
      <div className="text-secondary text-center">
        <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5"></i>
      </div>
    </div>
  )
}

import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Table, Form, Alert, ButtonGroup, UncontrolledTooltip } from 'reactstrap'
import { PrimaryButton, SecondaryButton } from 'shared-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { findIndex, maxBy, minBy, values as ldValues, keys, sum } from 'lodash'
import { ScoreAttrs, AdditionalScoreAttrs, calculatorOptions } from '../../DataSet'
import { addEventToAnalytics } from 'Utils'

import { postWeightsFormData, setWeightsData, setCustomSelectorName, setCustomCalcScoreAttrs } from '../../actions/weights'
import WeightItem from './WeightItem'
import WeightsPieChart from './WeightsPieChart'
import ConfigureScreener from './ConfigureScreener'
import CreateSelector from './CreateSelector'

//import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';

const configureWeightsSchema = Yup.object().shape({
  yRet: Yup.number(),
  risk: Yup.number(),
  fees: Yup.number(),
  aum: Yup.number(),
  vol: Yup.number(),
  trkerror: Yup.number(),
  weight_yield: Yup.number(),
  weight_bid_ask: Yup.number(),
  weight_track_record: Yup.number()
})

const deriveFinalValues = (values, col, lockedVariables) => {
  let totalLockedValues = 0
  let totalUnlockedValue = 0

  // gather the sum of total locked & unlocked variables
  Object.keys(values).map(key => {
    const intValue = parseInt(values[key], 10)
    if (lockedVariables.includes(key)) {
      totalLockedValues += intValue
    } else if (key !== col) {
      totalUnlockedValue += intValue
    }
  })

  // derive max variable balance for the
  const maxColBalance = 100 - totalLockedValues

  if (values[col] > maxColBalance) {
    values[col] = maxColBalance
  }

  // distribute the weightage based on the values increased
  const availableBalance = maxColBalance - totalUnlockedValue - values[col]
  const totalLockedVariables = [...lockedVariables, col]
  let finalUnlockedValues = 0
  const finalUnlockedVariables = []
  Object.keys(values).map(key => {
    if (!totalLockedVariables.includes(key)) {
      let columnBalance =
        Math.round(
          (values[key] / totalUnlockedValue) * availableBalance + values[key]
        ) || 0
      values[key] = columnBalance
      finalUnlockedValues += columnBalance
      if (values[key] !== 0) {
        finalUnlockedVariables.push({
          [key]: values[key]
        })
      }
    }
  })

  // adjust the lien values on max or min variable for small value changes
  const finalBalanceValues =
    values[col] + totalLockedValues + finalUnlockedValues

  if (finalBalanceValues < 100) {
    const minValueKey = minBy(finalUnlockedVariables, item => ldValues(item)[0])
    const minKey = keys(minValueKey)[0]
    if (minKey) {
      values[minKey] = values[minKey] + (100 - finalBalanceValues)
    }
  } else if (finalBalanceValues > 100) {
    const maxValueKey = maxBy(finalUnlockedVariables, item => ldValues(item)[0])
    const maxKey = keys(maxValueKey)[0]
    if (maxKey) {
      values[maxKey] = values[maxKey] + (100 - finalBalanceValues)
    }
  }

  // derive minimum column balance
  let sumOfUnlockedVariables = 0
  Object.keys(values).map(key => {
    if (!totalLockedVariables.includes(key)) {
      sumOfUnlockedVariables += values[key]
    }
  })

  const minColBalance = 100 - totalLockedValues - sumOfUnlockedVariables

  if (values[col] < minColBalance) {
    values[col] = minColBalance
  }

  return values
}

const ConfigureWeights = ({
  scoreAttrs,
  setScoreAttrs,
  handleSubmit,
  values,
  errors,
  handleChange,
  resetForm,
  loading,
  weightError,
  weightSuccess,
  setValues,
  selectedCalculator,
  selectorName,
  setSelectorName,
  customCalculators
}) => {
  const [lockedVariables, setLockedVariables] = useState([])
  const [genSelectorError, setGenSelectorError] = useState('')
  const onSliderValueChange = value => {
    setValues({
      ...values,
      ...value
    })
  }

  const afterSliderChange = value => {
    const col = Object.keys(value)[0]
    const normalisedValues = deriveFinalValues(
      {
        ...values,
        ...value
      },
      col,
      lockedVariables
    )
    setValues(normalisedValues)
  }

  const onLockButtonClick = col => {
    const newLockedVariables = [...lockedVariables]
    const colIndex = newLockedVariables.indexOf(col)
    if (colIndex !== -1) {
      newLockedVariables.splice(colIndex, 1)
    } else {
      newLockedVariables.push(col)
    }
    setLockedVariables(newLockedVariables)
  }

  const onResetClick = () => {
    setLockedVariables([])
    resetForm()
  }

  const showSelectorOption = (selectedCalculator === 'create custom selector')
    || (selectedCalculator in customCalculators);

  let saveSelectorError = '';

  if(!selectorName){
    saveSelectorError = 'Please name your selector';
  }else if(!scoreAttrs.length > 0){
    saveSelectorError = 'Please add a metric to save your selector';
  }

  return (
    <Form onSubmit={handleSubmit} noValidate={true}>
      {/* {errors && errors.formAlert && (
        <Alert color="danger" className="m-0">
          {errors.formAlert}
        </Alert>
      )}
      {weightSuccess && (
        <Alert color="success" className="m-0">
          Successfully Updated
        </Alert>
      )}
      {weightError && weightError !== '' && (
        <Alert color="danger" className="m-0">
          {weightError}
        </Alert>
      )} */}

      <div
        className={
          loading == true
            ? 'overlay weight-active weight-overlay'
            : 'overlay weight-deactive weight-overlay'
        }
      >
        <div className="text rounded">
          <i className="fal fa-circle-notch fa-3x fa-spin text-gray-5"></i>
          <h5 className="mt-2 mb-0">Updating weights data</h5>
        </div>
      </div>

      <Table className="m-0 configure-weights-table">
        <tbody>
          <tr>
            <td className="weight-cell-column">
              <div className="cell-header-section">
                <div className="modal-addtional-text">
                  <div className="header-wrapper">
                    <h1
                      clasName="text-align-left"
                      style={{ fontWeight: '600' }}
                    >
                      Personalize Your Fund Selector
                    </h1>
                  </div>
                  <span>
                    The weights assigned will be used to generate a score to find
                    the best security from the selected securities -
                    ((variable1*weight1)+(variable2*weight2)…….)/ (sum of weights)
                  </span>
                  {showSelectorOption && (
                    <CreateSelector
                      selectorName={selectorName}
                      setSelectorName={setSelectorName}
                      currentScoreAttrs={values}
                      selectedCalculator={selectedCalculator}
                      setGenSelectorError={setGenSelectorError}
                      setScoreAttrs={setScoreAttrs}
                    />
                  )}
                </div>
                <div className="weights-pie-chart-wrapper">
                  <WeightsPieChart dataObject={values} />
                </div>
              </div>
              <div className="weight-cell-container">
                <div className="right-line"></div>
                <div className="bottom-line"></div>
                <div className="weight-cell-wrapper d-flex">
                  {scoreAttrs.map(attr => {
                    const inputValue =
                      typeof values[attr.col] === 'number'
                        ? values[attr.col]
                        : 0
                    return (
                      <WeightItem
                        key={attr.col}
                        attr={attr}
                        onSliderValueChange={onSliderValueChange}
                        afterSliderChange={afterSliderChange}
                        inputValue={inputValue}
                        setValues={setValues}
                        allValues={values}
                        onLockButtonClick={onLockButtonClick}
                        lockedVariables={lockedVariables}
                      />
                    )
                  })}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className="weight-cell-column footer">
              {selectedCalculator === 'create custom selector' ? (
                <ButtonGroup className="justify-content-center w-100">
                  <SecondaryButton
                    type="button"
                    className="btn-link btn-reset"
                    onClick={onResetClick}
                    style={{
                      maxWidth: '203px',
                      cursor: 'pointer',
                      color: '#1e5f91',
                    }}
                  >
                    Reset
                  </SecondaryButton>
                  <span id={'tooltip-error'}>
                    <PrimaryButton
                      type="submit"
                      className="btn-link btn-update"
                      style={{
                        width: '203px',
                        maxWidth: '203px',
                        cursor: 'pointer',
                      }}
                      disabled={!selectorName || (!scoreAttrs.length > 0)}
                    >
                      Save
                    </PrimaryButton>
                  </span>
                  {saveSelectorError &&
                    <UncontrolledTooltip                    
                      className="save-selector-error" 
                      placement="bottom"
                      target={'tooltip-error'}
                    >
                      {saveSelectorError}
                    </UncontrolledTooltip>
                  }              
                </ButtonGroup>
              ) : (
                <ButtonGroup className="justify-content-center w-100">
                  <SecondaryButton
                    type="button"
                    className="btn-link btn-reset"
                    onClick={onResetClick}
                    style={{
                      maxWidth: '203px',
                      cursor: 'pointer',
                      color: '#1e5f91'
                    }}
                  >
                    Reset
                  </SecondaryButton>
                  <PrimaryButton
                    type="submit"
                    className="btn-link btn-update"
                    style={{ maxWidth: '203px', cursor: 'pointer' }}
                  >
                    Update
                  </PrimaryButton>
                </ButtonGroup>
              )}
              <div className="gen-selector-errors">
                {showSelectorOption && (
                  <span>{genSelectorError}</span>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </Form>
  )
}

const ConfigureWeightsWithForm = ({
  scoreAttrs: orginalAttrs,
  weights,
  scoreAttrsWeightHandler,
  loading,
  weightSuccess,
  weightError,
  funds,
  selectedCalculator,
  setCustomSelectorName,
  customCalculators,
  ...props
}) => {
  const [scoreAttrs, setScoreAttrs] = useState(orginalAttrs);
  const validator = values => {
    const errors = {}
    let hasNegativeValue = false
    let isNotValidNumber = false

    const total = Object.keys(values).reduce((total, current) => {
      if (values[current] < 0) {
        hasNegativeValue = true
      }
      if (isNaN(values[current])) {
        isNotValidNumber = true
      }
      return total + parseFloat(values[current] || 0)
    }, 0)

    if (hasNegativeValue || isNotValidNumber) {
      errors.formAlert = 'Please enter a positive number.'
      return errors
    }

    if (total <= 0) {
      errors.formAlert = `Please enter atleast one value.`
    }
    return errors
  }

  const [selectorName, setSelectorName] = useState('');

  const [showMore, setShowMore] = useState(
    false ||
      ScoreAttrs.length + AdditionalScoreAttrs.length === scoreAttrs.length
  )

  const remainingAttrs = [...ScoreAttrs, ...AdditionalScoreAttrs]
  const shouldDisplayShowmore = scoreAttrs.length < remainingAttrs.length

  scoreAttrs.forEach(attr => {
    const filteredIndex = findIndex(remainingAttrs, { col: attr.col })
    remainingAttrs.splice(filteredIndex, 1)
  })
  // console.log('===scoreAttrs', scoreAttrs);
  // const finalAttrs = !showMore ? scoreAttrs : [...scoreAttrs, ...remainingAttrs]
  const finalAttrs = scoreAttrs;
  // console.log('===finalAttrs', finalAttrs);
  let defaultValues = {}
  const initialValues = {}
  ;(finalAttrs || []).forEach(attr => {
    defaultValues[attr.col] = attr.weight || 0
  })

  const onFormSubmit = values => {
    if(selectedCalculator === 'create custom selector'){
      setCustomSelectorName(selectorName);
    }
    props.setCustomCalcScoreAttrs(scoreAttrs);
    const arrayValues = [];
    (finalAttrs || []).forEach(attr => {
      arrayValues.push({
        name: attr.code,
        weight: values[attr.col] || 0,
        screener: attr.screener || null,
      })
    })
    props.postWeightsFormData(arrayValues)
    scoreAttrsWeightHandler(arrayValues)

    // const weightScore = []
    // ;(finalAttrs || []).forEach(attr => {
    //   const index = arrayValues.findIndex(x => x.name === attr.code)
    //   if (index >= 0)
    //     weightScore.push({
    //       weight_name: attr.code,
    //       weight_value: arrayValues[index].weight
    //     })
    // })
    // props.setWeightsData({ weightScore: weightScore })
    if (funds) {
      addEventToAnalytics(
        'Custom Weight Calculator -- Save',
        'Custom Weight Calculator -- Save',
        'CUSTOM_WEIGHT_CALCULATOR -- SAVE',
        { query: funds.query, logId: funds.logId },
        true
      )
    }
  }

  return (
    <>
    {(selectedCalculator === calculatorOptions.magnifiactivescreener) ? 
      <ConfigureScreener
        scoreAttrs={finalAttrs}
        values={defaultValues}
        modalToggle={props.modalToggle}
      /> : 
      <Formik
        enableReinitialize
        initialValues={{
          ...defaultValues,
          ...initialValues
        }}
        validationSchema={configureWeightsSchema}
        onSubmit={onFormSubmit}
        validate={validator}
        render={props => (
          <ConfigureWeights
            {...props}
            loading={loading}
            weightSuccess={weightSuccess}
            weightError={weightError}
            scoreAttrs={finalAttrs}
            setScoreAttrs={setScoreAttrs}
            onShowMoreClick={() => setShowMore(!showMore)}
            showMore={showMore}
            shouldDisplayShowmore={shouldDisplayShowmore}
            selectedCalculator={selectedCalculator}
            selectorName={selectorName}
            setSelectorName={setSelectorName}
            customCalculators={customCalculators}
          />
        )}
      />
    }
    </>
  )
}

const mapStateToProps = ({ funds, weights }) => ({
  funds,
  weights: weights.weightScore,
  loading: weights.loading,
  weightSuccess: weights.weightSuccess,
  weightError: weights.error,
  selectedCalculator: weights.selectedCalculator,
  customCalculators: weights.customCalculators,
})

const mapDispatchToProps = {
  setCustomSelectorName,
  postWeightsFormData,
  setWeightsData,
  setCustomCalcScoreAttrs,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigureWeightsWithForm)

ConfigureWeightsWithForm.defaultProps = {
  scoreAttrs: []
}

import React, { Component, Fragment } from 'react';

import NotApplicable from '../../NotApplicable';
import GaugeChart from './GaugeChart'

const formatNumberValue = (value, fraction) => {
  const leftPart = (value + '').split('.')[0];

  if (leftPart.length >= 4 && leftPart.length < 7) {
    return (value / 1000).toFixed(fraction) + 'K';
  } else
    if (leftPart.length >= 7 && leftPart.length < 10) {
      return (value / 1000000).toFixed(fraction) + 'M';
    } else
      if (leftPart.length >= 10) {
        return (value / 1000000000).toFixed(fraction) + 'B';
      } else {
        return value.toFixed(fraction)
      }
};

const DynamicGaugeChart = (props) => {
    const { tickerData, queryVar } = props;
    let chart_color = queryVar.chart_color
    const { view_name, Secondary_view_name, decimal, suffix, multiplier, Prefix } = queryVar;
    if (!tickerData) return <div className="not-applicable-icon-wrapper">
        <NotApplicable />
      </div>;

    const mult = multiplier !== '' ? multiplier : 1;
    let name = view_name + (Secondary_view_name ? ` (${Secondary_view_name})` : '');

    name = name.replace('ticker', queryVar.data[0]);

    let _min = tickerData.min * mult,
      _max = tickerData.max * mult,
      _value = tickerData.value * mult;
    const suff = ['', 'K', 'M', 'B'];
    const kmb = (val, i = 0) => (val < 100 || i > 2) ? `${Prefix}${val.toFixed(decimal)}${suff[i]}` : kmb(val / 1000, i + 1);
    return (
      <div className="siw-meter print-wrapper">
        <span className="siw-meter-title" title={name}>{name}</span>
        <GaugeChart
          value={_value}
          max={_max}
          min={_min}
          chart_color={chart_color}
        />
        <div className="d-flex" style={{ width: '100px', justifyContent :"space-between" }}>
          <span className="siw-meter-min">
            {kmb(_min)}{suffix}
            {/* {_min.toFixed(decimal)}{suffix} */}
          </span>
          <span className="siw-meter-value">
            {kmb(_value)}{suffix}
            {/* {_value.toFixed(decimal)}{suffix} */}
          </span>
          <span className="siw-meter-max">
            {kmb(_max)}{suffix}
            {/* {_max.toFixed(decimal)}{suffix} */}
          </span>
        </div>
      </div>
    );
};

export default DynamicGaugeChart;

import React, { useState } from 'react';
import { indexOf } from 'lodash';

const RankedTags = ({
  id,
  className,
  style,
  tagName,
  variable,
  preference,
  onClick,
  ...props
}) => {
  const { requestPayloadSort } = props;
  const { name: sort_by, value: sort_value } = requestPayloadSort;
  const sortSelected = (typeof sort_by !== 'undefined' && typeof sort_value !== 'undefined') ? ((sort_by === (variable+'_rank')) ? sort_value : '') : '';
  const sortOptions = (preference === '<') ? ['asc', 'desc', ''] : ['desc', 'asc', ''];
  const [sort, setSort] = useState(sortSelected);

  const sortHandle = (tagName, sortSelected) => {
    let ggg = indexOf(sortOptions, sortSelected)
    if (typeof sortOptions[ggg] === 'undefined' || ggg >= (sortOptions.length - 1)) {
      ggg = 0;
      setSort(sortOptions[ggg]);
    } else {
      ggg++;
      setSort(sortOptions[ggg]);
    }
    onClick({ name: tagName, sort: sortOptions[ggg] });
  }

  return (
    <div id={id} className={`black-tag pos-rel ${className}`} style={style} onClick={() => sortHandle(tagName, sort)}>
      {props.children}
      {sort !== '' && (
        <i
          id={`${id}_up`}
          className={`sort-state-icon ${(sort === 'asc') ? 'active fas fa-caret-down' : ''} ${(sort === 'desc') ? 'active fas fa-caret-up' : ''}`}
          style={{ marginLeft: 5 }}
        />
      )}
    </div>
  )
}

export default RankedTags;

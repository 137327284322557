import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { findDOMNode } from 'react-dom';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import screenfull from 'screenfull';
import ReactPlayer from 'react-player';
import { Slider } from 'antd';
import {
  load,
  playPause,
  pip,
  stop,
  toggleLoop,
  setVolume,
  toggleMuted,
  setPlaybackRate,
  onPlay,
  onEnablePIP,
  onDisablePIP,
  onPause,
  onSeekMouseDown,
  onSeekChange,
  onSeekMouseUp,
  onProgress,
  onEnded,
  onDuration,
  refreshVideoState,
} from 'actions/videoplayer';
import { PauseCircleOutlined, PlayCircleOutlined, FullscreenOutlined, RadiusBottomrightOutlined } from '@ant-design/icons';
// import Duration from './Duration';
import { createLoadingSelector } from 'selectors/loading-selector';

// import styles from './index.scss';

class VideoPlayer extends Component {
  state = {
    playing: true,
    muted: true,
    played: 0,
    loaded: 0,
    playbackRate: 1.0,
    loop: false,
    volume: 0.3,
    showVolumeSlider: false,
    showControls: false,
    videoEnded: false,
  }

  load = (url) => {
    this.setState({
      played: 0,
      loaded: 0,
    });
    // const { load } = this.props;
    // if (load) {
    //   load({
    //     url,
    //     played: 0,
    //     loaded: 0,
    //     pip: false,
    //   });
    // }
    // dispatch({
    //   type: 'videoplayer/load',
    //   payload: {
    //     url,
    //     played: 0,
    //     loaded: 0,
    //     pip: false,
    //   },
    // });
  }

  playPause = () => {
    const { videoplayer, onPlayPauseState, link, playPause } = this.props;
    const { playing } = this.state;
    this.setState({ playing: !playing });
    if (this.state.videoEnded) {
      this.setState({ videoEnded: false });
    }
    // if (playPause) {
    //   playPause({
    //     url: link,
    //     playing: !videoplayer.playing,
    //   });
    // }
    onPlayPauseState(videoplayer);
  }

  pip = () => {
    const { videoplayer, pip } = this.props;
    // if (pip) {
    //   pip({
    //     pip: !videoplayer.pip,
    //   })
    // }
  }

  stop = () => {
    this.setState({ playing: false });
    const { stop } = this.props;
    // if (stop) {
    //   stop({
    //     url: null,
    //     playing: false,
    //   });
    // }
  }

  toggleLoop = () => {
    const { videoplayer, toggleLoop } = this.props;
    // if (toggleLoop) {
    //   toggleLoop({
    //     loop: !videoplayer.loop,
    //   });
    // }
  }

  setVolume = (value) => {
    if (isNaN(value)) {
      return;
    }

    const { setVolume, toggleMuted } = this.props;

    if (value === 0) {
      this.setState({ muted: true });
    } else {
      this.setState({ muted: false });
    }

    this.setState({
      volume: parseFloat(value),
    });

    // if (setVolume) {
    //   setVolume({
    //     volume: parseFloat(value),
    //   });
    // }
  }

  toggleMuted = () => {
    this.setState({ muted: !this.state.muted }, () => {
      // const { toggleMuted } = this.props;
      // if (toggleMuted) {
      //   toggleMuted({
      //     muted: this.state.muted,
      //   });
      // }
    });
  }

  setPlaybackRate = (e) => {
    this.setState({ playbackRate: parseFloat(e.target.value) });
    // const { setPlaybackRate } = this.props;
    // if (setPlaybackRate) {
    //   setPlaybackRate({
    //     playbackRate: parseFloat(e.target.value),
    //   });
    // }
  }

  onPlay = () => {
    this.setState({ playing: true });
    // const { link, onPlay } = this.props;
    // if (onPlay) {
    //   onPlay({
    //     url: link,
    //     playing: true,
    //   });
    // }
  }

  onEnablePIP = () => {
    this.setState({ pip: true });
    // const { onEnablePIP } = this.props;
    // if (onEnablePIP) {
    //   onEnablePIP({
    //     pip: true,
    //   });
    // }
  }

  onDisablePIP = () => {
    this.setState({ pip: false });
    // const { onDisablePIP } = this.props;
    // if (onDisablePIP) {
    //   onDisablePIP({
    //     pip: false,
    //   });
    // }
  }

  onPause = () => {
    this.setState({ playing: false });
    const { onPauseState, videoplayer, link, onPause } = this.props;
    // if (onPause) {
    //   onPause({
    //     url: link,
    //     playing: false,
    //   })
    // }
    // onPauseState(videoplayer);
  }

  onSeekMouseDown = () => {
    this.setState({ seeking: true });
    // const { onSeekMouseDown } = this.props;
    // if (onSeekMouseDown) {
    //   onSeekMouseDown({
    //     seeking: true,
    //   });
    // }
  }

  onSeekChange = (e) => {
    this.setState({ played: parseFloat(e.target.value) });
    // const { onSeekChange } = this.props;
    // if (onSeekChange) {
    //   onSeekChange({
    //     played: parseFloat(e.target.value),
    //   });
    // }
  }

  onSeekMouseUp = (e) => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value));
    // const { onSeekMouseUp } = this.props;
    // if (onSeekMouseUp) {
    //   onSeekMouseUp({
    //     seeking: false,
    //   });
    // }
  }

  onProgress = (state) => {
    // console.log('onProgress', state);
    const { seeking } = this.state;
    if (!seeking) {
      this.setState(state);
    }
    // const { dispatch, videoplayer, onProgress } = this.props;
    // // We only want to update time slider if we are not currently seeking
    // if (!videoplayer.seeking && onProgress) {
    //   onProgress({
    //     state
    //   });
    // }
  }

  onEnded = () => {
    const { dispatch, onEndedState, videoplayer, onEnded } = this.props;
    const { loop } = this.state;
    this.setState({ playing: loop, videoEnded: true });
    // if (onEnded) {
    //   onEnded({
    //     playing: videoplayer.loop,
    //   });
    // }
    // onEndedState(videoplayer);

  }

  onDuration = (duration) => {
    // console.log('onDuration', duration);
    // const { dispatch, onDuration } = this.props;
    // // this.setState({ duration });
    // if (onDuration) {
    //   onDuration({
    //     duration
    //   });
    // }
  }

  onClickFullscreen = () => {
    screenfull.request(findDOMNode(this.player)); //eslint-disable-line
  }

  // renderLoadButton = (url, label) => {
  //   return (
  //     <Button onClick={() => this.load(url)}>
  //       {label}
  //     </Button>
  //   );
  // }

  ref = (player) => {
    this.player = player;
  }

  getFileName = (link) => {
    const filename = link.substring(link.lastIndexOf('/') + 1);
    return (filename);
  }

  showVolumeSlider = (_state) => {
    this.setState({
      showVolumeSlider: _state,
    });
  }

  showVideoControls = (_state) => {
    this.setState({
      showControls: _state,
    });
  }

  render() {
    const { link, title } = this.props;
    const { playing, volume, muted, loop, played, loaded, playbackRate, pip, showVolumeSlider, showControls, videoEnded } = this.state;
    // const toltaltime = (
    //   <Duration seconds={duration} />
    // );
    // const elapsed = (
    //   <Duration seconds={duration * played} />
    // );
    // const remaining = (
    //   <Duration seconds={duration * (1 - played)} />
    // );

    // {ReactPlayer.canEnablePIP(link)
    //   && (
    //     <RadiusBottomrightOutlined onClick={this.pip} />
    //   )
    // }

    // <FullscreenOutlined className="fullscreen" onClick={this.onClickFullscreen} />


    return (
      <section className="videoplayerContainer">
        {link
          && (
            <div className="videoplayerWrapper">
              <div
                className={classNames('playerWrapper', 'player-wrapper')}
                onMouseOver={() => this.showVideoControls(true)}
                onMouseOut={() => this.showVideoControls(false)}
              >
                <ReactPlayer
                  ref={this.ref}
                  className={classNames('reactPlayer', 'react-player')}
                  width="100%"
                  height="100%"
                  url={link}
                  pip={pip}
                  playing={playing}
                  loop={loop}
                  playbackRate={playbackRate}
                  volume={volume}
                  muted={muted}
                  //onReady={() => console.log('onReady')}
                  //onStart={() => console.log('onStart')}
                  onPlay={this.onPlay}
                  onEnablePIP={this.onEnablePIP}
                  onDisablePIP={this.onDisablePIP}
                  onPause={this.onPause}
                  //onBuffer={() => console.log('onBuffer')}
                  //onSeek={e => console.log('onSeek', e)}
                  onEnded={this.onEnded}
                  onError={e => console.log('onError', e)}
                  onProgress={this.onProgress}
                  onDuration={this.onDuration}
                />
                <div className="videoControls" style={{ display: (showControls || videoEnded) ? 'block' : 'none', background: videoEnded ? '#1212138c' : '' }}>
                  {playing ? (
                    <PauseCircleOutlined className="playPause" onClick={this.playPause} />
                  ) : (
                    <PlayCircleOutlined className="playPause" onClick={this.playPause} />
                  )}

                  <div
                    className="volume-controls"
                    style={{ display: (videoEnded) ? 'none' : 'block' }}
                    onMouseOver={() => this.showVolumeSlider(true)}
                    onMouseOut={() => this.showVolumeSlider(false)}
                  >
                    {(muted) ? (
                      <i className="fas fa-volume-mute volume-icon" onClick={this.toggleMuted} />
                    ) : (
                      <i className="fas fa-volume-up volume-icon"
                        onClick={this.toggleMuted}
                      />
                    )}
                    <Slider
                      vertical
                      tooltipVisible={false}
                      min={0}
                      max={1}
                      step={0.1}
                      defaultValue={volume}
                      value={volume}
                      id="volume"
                      className="volume-slider"
                      style={{ display: showVolumeSlider ? 'block' : 'none' }}
                      onChange={this.setVolume}
                      onMouseOver={() => this.showVolumeSlider(true)}
                    />
                  </div>
                </div>
                <div className="trackBarContainer" style={{ display: showControls ? 'block' : 'none' }}>
                  <input
                    className="inputSeekbar"
                    type="range"
                    min={0}
                    max={1}
                    step="any"
                    value={played}
                    onMouseDown={this.onSeekMouseDown}
                    onChange={this.onSeekChange}
                    onMouseUp={this.onSeekMouseUp}
                  />
                  <div className="playedbar">
                    <progress max={1} value={played} />
                  </div>
                  <div className="loadedbar">
                    <progress max={1} value={loaded} />
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </section>
    );
  }
}

VideoPlayer.propTypes = {
  link: PropTypes.string.isRequired,
  onPlayPauseState: PropTypes.func.isRequired,
  onPauseState: PropTypes.func.isRequired,
  onEndedState: PropTypes.func.isRequired,
};

const mapStateToProps = ({ videoplayer, loading }) => ({
  videoplayer,
  clientListLoading: createLoadingSelector(['GET_CLIENT_LIST'])({ loading }),
});

const mapDispatchToProps = {
  load,
  playPause,
  pip,
  stop,
  toggleLoop,
  setVolume,
  toggleMuted,
  setPlaybackRate,
  onPlay,
  onEnablePIP,
  onDisablePIP,
  onPause,
  onSeekMouseDown,
  onSeekChange,
  onSeekMouseUp,
  onProgress,
  onEnded,
  onDuration,
  refreshVideoState,
};

export default hot(module)(connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VideoPlayer)));

import React, { useContext } from 'react';
import { Row, Col, Table } from 'reactstrap';

const Divider = (props) => {
  return (
      <div
      	style={{
      	  display: 'block',
      	  minWidth: props.minWidth ? props.minWidth : 0,
      	}}
      >
      </div>
  )
};
export default Divider;

import React, { Component } from 'react';
import { connect } from 'react-redux'
import _ from 'lodash';
import { Row, Col, Card, Tabs } from 'antd';

import { CapitalizeEachWord, addEventToAnalytics } from 'Utils'
// import { CN  } from 'DataSet';

import { SwapOutlined } from '@ant-design/icons';

import { createLoadingSelector } from 'selectors/loading-selector';

import AutoCompleteJJ from './AutComplete';

import ItemList from './ItemList';
import ItemCard from './ItemCard';

import {
  getAllTickers,
  resetTotumScore,
  addFundForEnhanceRequest,
  deleteFundForEnhanceRequest,
  setEnhancerState,
  deleteSpecifyReplacement,
  getCurrentPortfolioScoreRequest,
  getEnhancedPortfolioScoreRequest,
  getEnhanceStatsForSelectedTickerRequest,
} from 'actions/enhancer';
import { getPortfolioBenchmarkOfSelectedTicker } from 'Utils';
import { getCalculatedEnhanceStats } from 'layouts/WebDashboard/AccountsPage/utils'

import { Loader } from '../../../Elements/Loader';
// import { prepareFundObject } from '../../../../../utils'
const { TabPane } = Tabs;
// const { Option } = AutoComplete;

class RenderRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataSource: [],
      enhanceReplace: '',
      customReplaceActiveTab: {},
      resetEnhanceFundCheckStatus: false,
      resetCustomAddedFund: false,
      tabKey: '1',
      _viewIndex: "1",
    };
  }

  onSelectTab = (value) => {
    if (value === '2') {
      addEventToAnalytics('Specify Custom Ticker Funds', 'Specify Custom Ticker Funds', 'SPECIFY_CUSTOM_TICKER_FUNDS', { query_uuid: this.props.query_uuid }, false);//
      this.setState({
        tabKey: value
      })
      if (this.props.allTickers.length === 0) {
        this.props.getAllTickers();
      }
    }
  }

  deleteCard(replaceTicker, ticker, query) {
    // console.log('deleteCard');
    // console.log(replaceTicker, ticker, query);
    const { deleteSpecifyReplacement } = this.props;
    if (deleteSpecifyReplacement) {
      deleteSpecifyReplacement({
        replaceTicker,
        ticker,
        query,
      });

      console.log('--- After deleteSpecifyReplacement ---');
      const {
        // summary,
        portfolioQuery,
        enhanceReplace,
        performanceImpact,
        // enhanceTickerFilter,
        getEnhanceStatsForSelectedTickerRequest,
      } = this.props;

      if (performanceImpact && enhanceReplace) {
        let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(performanceImpact.upDownDataSet, performanceImpact.selectedBenchmark);
        let updatedArray = enhanceReplace.map((item) => {
          return item.selectedPortfolioFunds
        });

        if (portfolioBenchmark && updatedArray) {
          const STATS = getCalculatedEnhanceStats(updatedArray, portfolioQuery, portfolioBenchmark);
          console.log('========== PLOTTING PERFORMANCE IMPACT DATA ==========');
          let enhancedRequestData = updatedArray.map((item) => {
            return {
              ticker: item.ticker,
              weight: item.weight,
            };
          });

          // let __portfolioFunds = (summary && summary.portfolioFunds) ? summary.portfolioFunds : [];
          // console.log('__portfolioFunds --> ', __portfolioFunds);
          // if (enhanceTickerFilter && enhancedRequestData.length !== __portfolioFunds.length) {
          //   __portfolioFunds.forEach((item, i) => {
          //     if (!enhanceTickerFilter.includes(item.ticker)) {
          //       console.log(' --  found missing ticker --> ', item.ticker, item.weight);
          //       enhancedRequestData = [
          //         ...enhancedRequestData, {
          //           ticker: item.ticker,
          //           weight: item.weight,
          //         }
          //       ];
          //     }
          //   });
          // }
          // console.log('enhancedRequestData --> ', enhancedRequestData);

          // console.log(enhancedRequestData);
          if (enhancedRequestData &&
            enhancedRequestData.length > 0 &&
            portfolioBenchmark &&
            STATS &&
            getEnhanceStatsForSelectedTickerRequest
          ) {
            getEnhanceStatsForSelectedTickerRequest({
              portfolioBenchmark,
              enhancedRequestData,
              ui_enhancedStats: STATS,
            }, (res) => {
              // console.log('------- getEnhanceStatsForSelectedTickerRequest res -------');
              // console.log(res);
              // console.log('-----------------------------------------------------------');
            });

            const { resetTotumScore } = this.props;
            if (resetTotumScore) {
              resetTotumScore();
            }

            setTimeout(() => {
              this.getTotumScore();
            }, 500);

          }
        } else {
          console.log('-- Failed to calculate portfolio benchmark data after deleting specify custom funds --');
        }
      } else {
        console.log('-- Failed to calculate after deleting specify custom funds, missing performanceImpact --');
      }
    }
  }

  setCustomActiveTag = (ticker, status) => {
    console.log('setCustomActiveTag');
  }

  resetCheckBoxFor = (mode) => {
    switch (mode) {
      case 'enhanceFund':
        this.setState({
          resetEnhanceFundCheckStatus: false,
          resetCustomAddedFund: true,
        });
        break;
      case 'customAddedFund':
        this.setState({
          resetEnhanceFundCheckStatus: true,
          resetCustomAddedFund: false,
        });
        break;
      default:
        break;
    }
  }

  resetReplaceTicker = (replaceTicker, ticker, query, calledFrom) => {

  }

  setReplaceTicker = (e, selectedFund, replaceTicker, ticker, query) => {
    // console.log(selectedFund, replaceTicker, ticker, query);
    const checkedCard = e.target.checked;
    const {
      // data,
      // setEnhancerState,
      // enhanceReplace,
      addFundForEnhanceRequest,
      deleteFundForEnhanceRequest,
    } = this.props;

    try {
      if (checkedCard) {
        addEventToAnalytics('Enhancer Fund Select', 'Enhancer Fund Select', 'ENHANCER_FUND_SELECT', { ticker, replaceTicker, query_uuid: this.props.query_uuid, category: 'advisory_PTC' }, false);
        // add card in enhanceReplace
        // console.log('add card in enhanceReplace');
        if (selectedFund && addFundForEnhanceRequest) {
          addFundForEnhanceRequest({
            replaceTicker,
            ticker,
            query,
            selectedPortfolioFunds: selectedFund,
          }, (res) => {
            // console.log(res);
          })
        } else {
          console.log(' -- data is missing --');
        }
      } else {
        addEventToAnalytics('Enhancer Fund Deselect', 'Enhancer Fund Deselect', 'ENHANCER_FUND_DESELECT', { ticker, replaceTickermquery_uuid: this.props.query_uuid }, false);
        // remove card from enhanceReplace
        // console.log('remove card from enhanceReplace');
        if (deleteFundForEnhanceRequest && selectedFund) {
          deleteFundForEnhanceRequest({
            replaceTicker,
            ticker,
            query,
            selectedPortfolioFunds: selectedFund,
          }, (res) => {
            console.log(res);
          })
        } else {
          throw '-- selected fund missing --'
        }
      }

      const { resetTotumScore } = this.props;
      if (resetTotumScore) {
        resetTotumScore();
      }

      setTimeout(() => {
        this.getTotumScore();
      }, 500);

    } catch (e) {
      console.log(e);
    }
  }

  searchTickerCallback = (props) => {
    console.log('-- Custom ticker search callback --', props);
    const { resetTotumScore } = this.props;
    if (resetTotumScore) {
      resetTotumScore();
    }

    setTimeout(() => {
      this.getTotumScore();
    }, 500);
  }

  tabOnChange = (selectedKey, data, ticker, query, index) => {
    this.setState({ _viewIndex: selectedKey });
  }

  getTotumScore = () => {
    const { getCurrentPortfolioScoreRequest, getEnhancedPortfolioScoreRequest, portfolioFunds, enhanceReplace, totumScore, tickerNotSupported } = this.props;
    const { scoreMeter } = totumScore;
    let orderedScoreMeter = _.orderBy(scoreMeter.filter((item) => item.value !== null), ['value'], ['asc']);
    let scores = orderedScoreMeter.map((item) => item.value);
    if (getCurrentPortfolioScoreRequest && getEnhancedPortfolioScoreRequest && portfolioFunds && enhanceReplace) {
      // NOTE: check for 'current' score
      if (scores.length === 0) {
        // call request for 'current' score
        let allData = [...portfolioFunds, ...tickerNotSupported];
        const requestData = allData.map((item) => {
          return {
            ticker: item.ticker,
            weight: item.weight,
          };
        });
        if (requestData && requestData.length > 0) {
          getCurrentPortfolioScoreRequest({
            requestData,
            For: 'current',
          });
        }
      }
      // NOTE: get for 'enhanced' score
      let enhancedSelectedFunds = enhanceReplace.map((item) => item.selectedPortfolioFunds) || [];
      if (enhancedSelectedFunds && enhancedSelectedFunds.length > 0) {
        let enhanceAllData = [...enhancedSelectedFunds, ...tickerNotSupported];
        let requestData = enhanceAllData.map((k) => {
          return {
            ticker: k.ticker,
            weight: k.weight,
          }
        }) || [];
        if (requestData && requestData.length > 0) {
          getEnhancedPortfolioScoreRequest({
            requestData,
            For: 'enhanced',
          });
        }
      } else {
        console.log('--- NO ENHANCED REPLACEMENT TICKER FOUND FOR SCORE ---');
      }
    }
  }

  render() {
    const {
      data: { query, result, short_name, ticker, pseudo_query },
      enhanceTickerFilter,
      isGettingAllTickers,
      allTickers,
      customReplacementTickersData,
      portfolioQuery,
      fundPortfolios,
      enhanceReplace,
      tickerDataLoading,
      portfolioReadableQuery,
      summary: { portfolioFunds: _summaryPortfolioFunds, invalidTickerFunds: _invalidTickerFunds },
    } = this.props;
    const { _defaultFRR, _relevanceChartTabs } = result;
    const { resetEnhanceFundCheckStatus, resetCustomAddedFund, _viewIndex, tabKey } = this.state;

    let enhanceTicker;
    let staticCardValue;

    // re-write below logic again
    if (fundPortfolios.length > 0) {
      var getFilterTicker = fundPortfolios.map(x => x.ticker)
      var index = getFilterTicker.indexOf(ticker);
      var targetCardData = fundPortfolios[index].target_card;
      if (typeof targetCardData.funds !== 'undefined' && targetCardData.funds.length) {
        staticCardValue = targetCardData.funds[0];
      } else {
        return null;
      }
    }
    let renderReplace = null
    let dataSource = []
    const customReplacementCard = customReplacementTickersData.length > 0 && customReplacementTickersData.find(kl => kl.ticker === ticker)
    // console.log('customReplacementCard', customReplacementCard);

    if (tabKey === '2') {
      dataSource = allTickers.map(x => x.ticker);
      if (customReplacementCard && customReplacementCard.fundsData && customReplacementCard.fundsData.ticker && customReplacementCard.replace) {
        let { fundsData } = customReplacementCard;
        enhanceTicker = customReplacementCard.replace;
        renderReplace = (
          <div className="d-flex align-items-center" style={{ padding: '0px 10px', height: '100%' }}>
            <div style={{ position: 'absolute', left: '275px', top: '24px', cursor: 'pointer' }} onClick={this.deleteCard.bind(this, enhanceTicker, ticker, query)}><img src="/assets/images/card-close.png" /></div>
            <div className="pr-3" id="select-card">
              <ItemCard
                className="enhancer-fund-card"
                i={1}
                staticCard={false}
                activeViewTab={_viewIndex}
                relevanceChartTabs={_relevanceChartTabs}
                defaultFRR={_defaultFRR}
                portfolioQuery={portfolioQuery}
                data={fundsData}
                history={this.props.history}
                query={query}
                invalidTickerFunds={_invalidTickerFunds}
                summaryPortfolioFunds={_summaryPortfolioFunds}
                checked={enhanceReplace.find((lp) => lp.replaceTicker === customReplacementCard.replace && lp.ticker === ticker) ? true : false}
                originalQueryVar={result.query_var}
                highlights={result.Highlights}
                ticker={staticCardValue.ticker}
                tabOnChange={this.tabOnChange}
                setReplaceTicker={this.setReplaceTicker}
              />
            </div>
          </div>
        )
      } else {
        renderReplace = (
          <div className="d-flex align-items-center" style={{ padding: '0 10px', height: '100%' }}>
            <Card loading={tickerDataLoading} style={{ minWidth: '250px', width: '250px', height: '312px' }}>
              <div style={{ textAlign: 'center', paddingTop: '66px' }} >
                <img src="/assets/images/plus.png" />
                <AutoCompleteJJ suggestions={dataSource} overlapTicker={ticker} originalQueryVar={result.query_var} onClick={this.searchTickerCallback} />
              </div>
            </Card>
          </div>
        )
      }
    }

    let originalWeight = null;
    if (_summaryPortfolioFunds && _invalidTickerFunds && staticCardValue && staticCardValue.ticker) {
      const findTickerInSummaryPortfolioFunds = _summaryPortfolioFunds.find((kl) => kl.ticker === staticCardValue.ticker) || _invalidTickerFunds.find((jk) => jk.ticker === staticCardValue.ticker);
      if (findTickerInSummaryPortfolioFunds && findTickerInSummaryPortfolioFunds.original_weight && findTickerInSummaryPortfolioFunds.original_weight !== '') {
        originalWeight = Number(findTickerInSummaryPortfolioFunds.original_weight);
        originalWeight = parseFloat(findTickerInSummaryPortfolioFunds.original_weight.toFixed(2));
      }
    }

    return (
      <React.Fragment>
        <Row className="enhance-result-page-container">
          {(enhanceTickerFilter.includes(ticker) || enhanceTickerFilter.length === 0) && (
            <Col className="static-card-wrapper">
              <div className="static-card-label" id="short_name_tooltip">{ticker} {(originalWeight || originalWeight === 0) && `(Weight: ${originalWeight}%)`}</div>
              {staticCardValue
                && (
                  <Card className="static-card-body">
                    <ItemCard
                      className="enhancer-fund-card"
                      i={1}
                      staticCard
                      activeViewTab={_viewIndex}
                      relevanceChartTabs={_relevanceChartTabs}
                      defaultFRR={_defaultFRR}
                      portfolioQuery={portfolioQuery}
                      data={staticCardValue}
                      history={this.props.history}
                      query={query}
                      invalidTickerFunds={_invalidTickerFunds}
                      summaryPortfolioFunds={_summaryPortfolioFunds}
                      originalQueryVar={result.query_var}
                      highlights={result.Highlights}
                      ticker={staticCardValue.ticker}
                      tabOnChange={this.tabOnChange}
                    />
                  </Card>
                )
              }
              <SwapOutlined className="swap-icon-wrapper" />
            </Col>
          )}
          {(enhanceTickerFilter.includes(ticker) || enhanceTickerFilter.length === 0) && (
            <Col className="dynamic-card-wrapper">
              <Tabs className="card-tab-container" defaultActiveKey="1" tabBarGutter={32} onChange={this.onSelectTab}>
                <TabPane className="tabPaneClass" tab={CapitalizeEachWord(pseudo_query)} key="1">
                  <Card className="dynamic-card-body">
                    <ItemList
                      staticCard={false}
                      activeViewTab={_viewIndex}
                      relevanceChartTabs={_relevanceChartTabs}
                      defaultFRR={_defaultFRR}
                      data={result}
                      query={query}
                      ticker={ticker}
                      history={this.props.history}
                      portfolioReadableQuery={portfolioReadableQuery}
                      portfolioQuery={portfolioQuery}
                      resetCheck={resetEnhanceFundCheckStatus}
                      invalidTickerFunds={_invalidTickerFunds}
                      summaryPortfolioFunds={_summaryPortfolioFunds}
                      originalQueryVar={result.query_var}
                      customReplaceActiveTab={this.state.customReplaceActiveTab}
                      setCustomActiveTag={this.setCustomActiveTag}
                      resetCheckBoxFor={this.resetCheckBoxFor}
                      tabOnChange={this.tabOnChange}
                    />
                  </Card>
                </TabPane>
                <TabPane tab="Specify Custom Replacement" className="specify-custom-replacement-tab" key="2" onTabClick={this.onSelectTab}>
                  <Card className="dynamic-card-body custom-dynamic-card-body">
                    {isGettingAllTickers && <Loader loading={true} />}
                    {(!isGettingAllTickers) && renderReplace}
                  </Card>
                </TabPane>
              </Tabs>
            </Col>
          )}
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  portfolioQuery: state.enhancer.query,
  portfolioReadableQuery: state.enhancer.query_english,
  enhanceReplace: state.enhancer.enhanceReplace,
  performanceImpact: state.enhancer.performanceImpact,
  isGettingAllTickers: state.enhancer.isGettingAllTickers,
  allTickers: state.enhancer.allTickers,
  customReplacementTickersData: state.enhancer.customReplacementTickersData || [],
  summary: state.enhancer.summary,
  portfolioFunds: state.enhancer.summary.portfolioFunds,
  totumScore: state.enhancer.totumScore,
  query_uuid: state.enhancer.query_uuid,
  tickerNotSupported: state.enhancer.summary.tickerNotSupported || [],
  tickerDataLoading: createLoadingSelector(['GET_TICKER_DATA'])({ loading: state.loading }),
})

const mapDispatchToProps = {
  getAllTickers,
  resetTotumScore,
  setEnhancerState,
  addFundForEnhanceRequest,
  deleteFundForEnhanceRequest,
  deleteSpecifyReplacement,
  getCurrentPortfolioScoreRequest,
  getEnhancedPortfolioScoreRequest,
  getEnhanceStatsForSelectedTickerRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RenderRow)

import React, { useContext } from 'react'
import { Row, Col, Table } from 'reactstrap'

import { withHeaderFooter } from './HeaderFooter'

const ContentTable = withHeaderFooter((props) => {

  return (
    <React.Fragment>
        <div className="content">
          <div className="pr-v2-header">
            <h3>Table of Contents</h3>
          </div>
          <Table striped className="pr-v2-toc">
            <tbody>
            <tr>
              <td scope="row">Executive Summary</td>
              <td>01</td>
            </tr>
            <tr>
              <td scope="row">Performance Analytics</td>
              <td>02</td>
            </tr>
            <tr>
              <td scope="row">Appendix - Holdings</td>
              <td>03</td>
            </tr>
            <tr style={{ height: '57px' }}>
              <td scope="row"></td>
              <td></td>
            </tr>

            </tbody>
          </Table>
        </div>
    </React.Fragment>
  )
})
export default ContentTable

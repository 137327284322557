import React, { Component } from 'react'
import { Route, NavLink, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { Input, Label, FormGroup, UncontrolledCollapse } from 'reactstrap'
import { get } from 'lodash'

import SelectBrokerContainer from './SelectBrokerContainer'
import BrokerSignInContainer from './BrokerSignInContainer'
import BrokerAccountListContainer from './BrokerAccountListContainer'
import BrokerAmeritradeSetupContainer from './BrokerAmeritradeSetupContainer'
import BrokersWithAccountList from 'components/broker/BrokersWithAccountList'
import BrokerTradeItSecurityQuestionsContainer from './BrokerTradeItSecurityQuestionsContainer'

import { PrivateRoute, ComingSoon, Spinner } from 'shared-components'

import { isDemoUser, BrokersList, BrokerNameMap } from '../DataSet'
import { closeTraditWindow } from '../Utils'
import { fetchImportedAccounts, deleteImportedAccounts,  setupBroker, completeTradeItSetup } from 'actions/broker'
import { setDefaultAccount } from 'apis/broker'
import { each, keyBy, orderBy } from 'lodash'
import { createLoadingSelector } from 'selectors/loading-selector'
import { aggregateNetCashBalanceSelector } from 'reducers/broker'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  PrimaryButton
} from 'shared-components/'
import config from '../env'

class BrokerContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      defaultAccounts: {
        etrade: {},
        tdameritrade: {}
      },
      expandedBrokerName: '',
      expandedHamburger: '',
      showShowDeleteDialog: false
      // accountList: [] // imported account list
    }

    this.handleAddBroker = this.handleAddBroker.bind(this)
    this.handleExpandItem = this.handleExpandItem.bind(this)
    this.toCurrency = this.toCurrency.bind(this)
    this.handleSetDefault = this.handleSetDefault.bind(this)
  }

  handleAddBroker = e => {
    if (!isDemoUser()) {
      e.preventDefault()
    }
  }

  getBrokerMap = () => {}

  componentDidMount() {
    document.body.style.paddingTop = "245px";
    this.props.fetchImportedAccounts()
  }

  componentDidUpdate(prevProps) {
    document.body.style.paddingTop = "";
    document.body.classList.remove('ht0');
    if (this.props.location.pathname != prevProps.location.pathname) {
      this.props.fetchImportedAccounts()
      this.setState({ expandedHamburger: '' })
    }

    if (
      this.props.importedAccountsSuccess != prevProps.importedAccountsSuccess
    ) {
      if (this.props.importedAccountsSuccess) {
        const brokerMap = keyBy(this.props.accountList, 'broker')
        const defaultAccounts = Object.keys(brokerMap).reduce(
          (defaultAccountsMap, key) => {
            var defaultExist = brokerMap[key].accounts.find(
              el => el.default_account
            )
            if (defaultExist) {
              defaultAccountsMap[key] = defaultExist
            }
            return defaultAccountsMap
          },
          {}
        )
        this.setState({
          defaultAccounts
        })
      }
    }
  }

  handleExpandItem(brokerName) {
    brokerName = this.state.expandedBrokerName == brokerName ? '' : brokerName
    this.setState({
      expandedBrokerName: brokerName
    })
  }

  handleSetDefault = async (e, broker, account) => {
    let toggleAllowed = true
    const accounts = broker.accounts || []

    if (account.default_account === true) {
      toggleAllowed = false
    }

    const defaultAccounts = {
      ...this.state.defaultAccounts,
      [broker.broker]: account
    }

    this.setState({
      defaultAccounts: defaultAccounts
    })

    if (toggleAllowed) {
      await setDefaultAccount(
        { account_number: account.account_number },
        broker.broker
      )
    }
  }

  toCurrency(numberString) {
    let number = parseFloat(numberString)
    return number.toLocaleString('USD')
  }

  handleToggleAccountList = ({ broker }) => {
    this.setState({
      expandedBrokerName: this.state.expandedBrokerName == broker ? '' : broker
    })
  }

  handleHamburgerClick = ({ broker }) => {
    this.setState({
      expandedHamburger: this.state.expandedHamburger === broker ? '' : broker
    })
  }

  handleHamburgerMenuClick = ({ broker }) => {
    this.setState({
      expandedHamburger: ''
    })
  }

  handleDeleteAccountList = (broker, account) => {
   /*  const { defaultAccounts } = this.state
    const brokerName = broker.broker
    console.log({ defaultAccounts })
    const defaultedAccountNumber =
      defaultAccounts[brokerName] && defaultAccounts[brokerName].account_number

    const showShowDeleteDialog =
      account.account_number === defaultedAccountNumber &&
      broker.accounts.length > 1

    this.setState({
      showShowDeleteDialog
    })

    if (!showShowDeleteDialog) {
      this.props.deleteImportedAccounts({
        account_number: account.account_number,
        brokerName: broker.broker
      })
    } */

	  this.props.deleteImportedAccounts({
  		account_number: account.account_number,
  		brokerName: broker.broker
	  })
  }

  toggle = () => {
    this.setState(prevState => ({
      showShowDeleteDialog: !prevState.showShowDeleteDialog
    }))
  }

  handleBrokerSelect = (broker, received_code) => {
    if(broker.name == "tdameritrade" && !received_code){
      closeTraditWindow();
      window.location.href = config.tdAmeritradeCallbackUrl;
    } else if(broker.name !== 'tdameritrade') {
      this.props.setupBroker(broker, { broker: broker.name })
      const messageHandler = (message) => {
        if([config.tradeItOrigin, config.tradingTicketorigin].includes(message.origin)) {
          const parsedData = JSON.parse(message.data)
          const token = get(parsedData, 'oAuthVerifier','');
          this.props.completeTradeItSetup({
              token,
              brokerName: broker.name
            }, this.fetchImportedAccounts)
        }
      }
      window.onmessage = messageHandler
    } else{
      this.props.setupBroker(broker, received_code)
    }
  }

  fetchImportedAccounts = () => {
    this.props.fetchImportedAccounts()
  }

  render() {
    const isDemoUsers = isDemoUser()
    const accountList = (this.props.accountList || []).filter(
      acc => acc.accounts && acc.accounts.length > 0
    )
    return (
      <div className="broker-container settings__content-area">
        <Spinner isFetching={this.props.isDeleting} />
        <Spinner isFetching={this.props.isFetching} />

        <div className="broker__link-container">
          <NavLink
            to="/settings/broker/select"
            activeClassName="active"
            className="broker__link"
            onClick={this.handleAddBroker}
          >
            <i className="far fa-plus-circle broker__add-icon" />
            Add Broker
            {!isDemoUsers && <ComingSoon height={20} />}
          </NavLink>
        </div>
        <Switch>
          <PrivateRoute
            path="/settings/broker/select"
            component={SelectBrokerContainer}
          />

          <PrivateRoute
            path="/settings/broker/:brokerName/account"
            component={BrokerAccountListContainer}
          />
          {/* Currently only used for ETrade setup process */}
          <PrivateRoute
            path="/settings/broker/:brokerName/register"
            component={BrokerSignInContainer}
          />
          {/* Component to trigger HTTP calls for TDAmeritrade setup process  after success redirect to /settings/broker/:brokerName/account*/}
          <PrivateRoute
            path="/settings/broker/tdameritrade/setup"
            component={BrokerAmeritradeSetupContainer}
          />
          <PrivateRoute
            path="/settings/broker/tradeit/security-questions"
            component={BrokerTradeItSecurityQuestionsContainer}
          />
        </Switch>

        <BrokersWithAccountList
          expandedBrokerName={this.state.expandedBrokerName}
          expandedHamburger={this.state.expandedHamburger}
          accounts={accountList}
          defaultAccounts={this.state.defaultAccounts}
          onHamburgerClick={this.handleHamburgerClick}
          onHamburgerMenuClick={this.handleHamburgerMenuClick}
          onToggleChange={this.handleSetDefault}
          onToggleAccountList={this.handleToggleAccountList}
          onDeleteAccountList={this.handleDeleteAccountList}
          handleBrokerSelect={this.handleBrokerSelect}
        />

        {/* <pre>{this.state.showShowDeleteDialog ? 'you can' : "you can't"}</pre> */}

        {this.state.showShowDeleteDialog ? (
          <Modal
            isOpen={true}
            toggle={this.toggle}
            centered
            size="sm"
            className="delete-account-confirm"
          >
            <ModalHeader>Delete Information</ModalHeader>
            <ModalBody>
              Please make another account default before deleting this account
            </ModalBody>
            <ModalFooter>
              <PrimaryButton onClick={this.toggle}>OK</PrimaryButton>
            </ModalFooter>
          </Modal>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = ({
  broker,
  importedAccounts,
  deleteImportAccounts,
  loading,
  importBrokerAccount,
  tradeItSecurityHandling
}) => ({
  deleteImportedAccountFailed: deleteImportAccounts.failed,
  deleteImportedAccountSuccess: deleteImportAccounts.success,
  deleteImportedAccountError: deleteImportAccounts.error,
  accountList: aggregateNetCashBalanceSelector(importedAccounts.accounts),
  importedAccountsSuccess: importedAccounts.success,
  importedAccountsFailed: importedAccounts.failed,
  isFetching: createLoadingSelector(['FETCH_IMPORTED_ACCOUNT'])({ loading }),
  isDeleting: createLoadingSelector(['DELETE_IMPORTED_ACCOUNT'])({ loading }),
  selectedBroker: broker.selectedBroker,
  receivedEndPoint: broker.receivedEndPoint,
  error: broker.error,
  importAccountsSuccess: importBrokerAccount.importAccountsSuccess,
  displayVerification: tradeItSecurityHandling.displayVerification,
})

const mapDispatchToProps = {
  fetchImportedAccounts,
  deleteImportedAccounts,
  setupBroker,
  completeTradeItSetup
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrokerContainer)

import React from 'react'
import { Modal, ModalBody, Button } from 'reactstrap'

import './joinPop.scss'

const JoinPop = ({
  securitiesPage,
  open,
  cancel,
  handleFirstAction,
  handleSecondAction
}) => {
  const onClose = () => {
    cancel()
  }
  return (
    <Modal
      isOpen={open}
      toggle={onClose}
      centered
      keyboard={true}
      backdrop
      className="premium-msg-modal modalFeature"
      backdropClassName="premium-feature-process-modal-backdrop"
    >
      <ModalBody className="ssf-modal__body">
        <div className="premium-feature-process-modal-container">
          <div className="header-wrapper">
            {securitiesPage ? (
              <>
                <h1 className="text-align-center pb-2 modal_title_box">
                Please select the option that best <br />
                  describes you:
                </h1>
                <p className="text-align-center pb-2 h3_Title"></p>
              </>
            ) : (
              <h1 className="text-align-center pb-2 modal_title_box">
                Join As
              </h1>
            )}
          </div>
          <i
            className="fal fa-times-circle premium-feature-completed-close"
            onClick={onClose}
          ></i>
          {securitiesPage ? (
            <div className="botBtnSecurity">
              <Button
                type="button"
                className="btns-do-not-leave"
                onClick={handleFirstAction}
              >
                <span>Individual Investor</span>
              </Button>
              <Button
                type="button"
                className="btns-do-not-leave"
                onClick={handleSecondAction}
              >
                <span>Financial Professional</span>
              </Button>
            </div>
          ) : (
            <div className="botBtn">
              <Button
                type="button"
                className="btns-join-pop"
                onClick={handleFirstAction}
              >
                <span>I am an individual investor - register me for free</span>
              </Button>
              <Button
                type="button"
                className="btns-join-pop"
                onClick={handleSecondAction}
              >
                <span>I am a financial professional</span>
              </Button>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default JoinPop

import React, { Component, useMemo } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import config from './config';
import { setFundsState } from 'actions/discovery'
import noAccount from '../../../../../assets/images/NonExistingTradingAccount.svg'
import { isEmpty } from 'lodash'
import { getApexAccountStatus } from 'apis/register'
import { Spinner, PrimaryButton } from 'shared-components/';
import classnames from 'classnames';
import Dropzone from 'react-dropzone';
import { CloudUploadOutlined, CloseOutlined } from '@ant-design/icons';
import { saveForReview, uploadReviewImage } from 'apis/funds';
import {
  SUPPORTED_FORMATS,
} from '../../../Constant/FormData'
import { fetchProfile } from 'actions/profile';
import { InnerPageFooter } from 'shared-components/'

const toCaptilize = (myString) => {
  return myString.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
}

const FORMLABLES = {
  DOB: 'Date of Birth',
  TAX_ID: 'Social Security Number',
  NAME: 'Name',
  ADDRESS: 'Address'
}

class ApexAccountErrors extends Component {
  constructor(props) {
    super(props)
    this.state = {
      apexStatus: null,
      accountStatusLoading: true,
      activeUser: 1,
      activeTab: {
        0: 1,
        1: 1
      },
      uploadedFiles: [],
      underVerification: false,
      selectedDocs: {
        0: {},
        1: {}
      },
      docSectionLoading: false
    }
  }

  componentWillMount() {
    this.handleRejectedStatusData(this.props);
  }

  queryHandler = (query) => {
    window.sessionStorage.removeItem('search_type');
    if (query) {
      let queryString = "query=" + encodeURIComponent(toCaptilize(query.toLowerCase()));
      window.sessionStorage.setItem('query', query);
      this.props.setFundsState({
        query: query.toLowerCase(),
      })
      this.props.history.push(`/securities?${queryString}`);
    }
  }

  handleRedirect = (to) => {
    const { history } = this.props;
    history.push(to)
  }


  componentWillReceiveProps(nextProps, nextContext) {
    this.handleRejectedStatusData(nextProps);
  }

  handleRejectedStatusData = async (nextProps) => {
    let apex = nextProps.apex
    let apexStatus
    let showSuspendedAccount = false;
    let isDisabled = true;

    if (!isEmpty(apex)) {
      this.setState({
        accountStatus: apex.account[0].status
      })
      if (apex.account[0].status === 'SUSPENDED' || apex.account[0].status === 'ACTION_REQUIRED') {
        try {
          apexStatus = await getApexAccountStatus(apex.account[0].id)
          apexStatus = apexStatus.result
          !isEmpty(apexStatus.investigations) && (apexStatus.status === 'SUSPENDED' || apexStatus.status === 'ACTION_REQUIRED') && apexStatus.investigations.forEach(investigation => {
            if (!showSuspendedAccount) {
              !isEmpty(investigation.sketchId) && investigation.sketchId.cipCategories.length && investigation.sketchId.cipCategories.forEach(cat => {
                if (cat.categoryState === 'REJECTED' && cat.requestedDocuments.length > 0) {
                  showSuspendedAccount = true
                  apex.snaps && apex.snaps.length &&
                    apex.snaps.findIndex(snap => snap.sketch_id == investigation.sketchId.id) > -1
                    ? apex.snaps.forEach(snap => {
                      if (snap.sketch_id === investigation.sketchId.id && snap.doc_type === cat.name) {
                        if (isDisabled) {
                          isDisabled = snap.status !== 'rejected'
                        }
                      }
                    })
                    : (isDisabled = false)
                }
              })
            }
          })
          if (!showSuspendedAccount || isDisabled) {
            this.setState({ underVerification: true })
          } else {
            this.setState({
              apexStatus,
            });
          }
        } catch (e) {
          console.log(e)
        }
      }
      this.setState({ accountStatusLoading: false })
    }
  }

  onDrop = (files, data, dataIndex, parentIndex, investivation, tabToSwitch) => {
    this.onChangeHandler(files, data, dataIndex, parentIndex, investivation, tabToSwitch);
  };

  onChangeHandler = async (files, data, dataIndex, parentIndex, investivation, tabToSwitch) => {
    if (files && files[0]) {
      let fileSize = files[0].size / 1024 / 1024
      if (fileSize > 15) {
        // setError(
        //   `The file ${files[0].name} is too big. (files must be under 15MB in size).`
        // )
        return
      } else if (!SUPPORTED_FORMATS.includes(files[0].type)) {
        // setError('Only .png,.jpg,.jpeg,.gif, image format are allowed')
        return
      }
      let formData = new FormData()
      formData.append('file', files[0])
      formData.append('tag', 'ID_DOCUMENT')
      formData.append('account', this.props.apex.account[0].id)
      this.setState({ docSectionLoading: true })
      try {
        let upload = await uploadReviewImage(formData)
        this.setState({ docSectionLoading: false })
        let snapRecord = {
          id: upload.record_id,
          type: data.name,
          proof: this.state.selectedDocs[parentIndex][dataIndex].name,
          description: this.state.selectedDocs[parentIndex][dataIndex].description,
          primaryOrSecondry: parentIndex,
          dataPosition: dataIndex,
          pendingState: FORMLABLES[data.name] ? FORMLABLES[data.name] : data.name.replace('_', ' '),
          fileSize: files[0].size / 1024,
          fileName: files[0].name,
          status: 'uploaded',
          sketchId: investivation.sketchId.id
        }
        let uploadedFiles = this.state.uploadedFiles;
        uploadedFiles.push(snapRecord);
        if (tabToSwitch) {
          let activeTab = this.state.activeTab
          activeTab[parentIndex] = activeTab[parentIndex] + 1;
          this.setState({ activeTab })
        }
        this.setState({ uploadedFiles });
      } catch (e) {
        this.setState({ docSectionLoading: false })
      }
    }
  }


  onClickReview = async () => {
    const { apexStatus } = this.state;
    let payload = {
      apex_account_id: this.props.apex.account[0].id,
      data: apexStatus.investigations.filter((investigation, index) => !isEmpty(investigation.sketchId)).map(investigation => {
        return {
          sketchId: investigation.sketchId.id,
          snapIDs: this.state.uploadedFiles.filter(el => el.sketchId === investigation.sketchId.id).map(el => {
            return {
              id: el.id,
              type: el.type,
              proof: el.proof,
              status: el.status
            }
          })
        }
      })
    }
    try {
      this.setState({
        docSectionLoading: true
      })
      let uploadRes = await saveForReview(payload);
      if (uploadRes && uploadRes.data && uploadRes.data.message) {
        this.setState({
          docSectionLoading: false
        })
        this.props.fetchProfile()
      } else {
        this.setState({
          docSectionLoading: false
        })
      }
    } catch (e) {
      this.setState({
        docSectionLoading: false
      })
    }
  }


  fileAlreadyExists = (parentindex, dataindex) => {
    let uploadedFiles = this.state.uploadedFiles;
    let filtered = uploadedFiles.filter(el => el.dataPosition === dataindex && el.primaryOrSecondry === parentindex);
    if (filtered.length > 0)
      return true
    return false
  }

  submitDisabled = () => {
    let disabled = true, allkeys = 0;
    const { apexStatus } = this.state;
    if (!isEmpty(apexStatus)) {
      apexStatus.investigations.forEach((investigation, index) => {
        if (!isEmpty(investigation.sketchId)) {
          let cipies = investigation.sketchId.cipCategories.filter(el => el.categoryState === 'REJECTED' && el.requestedDocuments.length > 0);
          cipies.forEach((el, childIndex) => {
            allkeys += 1;
          })
        }
      })
      if (allkeys === this.state.uploadedFiles.length) {
        disabled = false;
      }
    }

    return disabled;
  }

  render() {
    const { type, profile, apex, history } = this.props
    const pageType = type in config ? type : '404';
    let profileDetails = profile;
    let { apexStatus, accountStatusLoading } = this.state
    let showLinkAccountBar = profileDetails && profileDetails.apex && Object.keys(profileDetails.apex).length && Object.keys(profileDetails.apex).length !== 0 && profileDetails.apex.account[0] && profileDetails.apex.account[0].status === 'COMPLETE' && profileDetails.apex.account[0].is_active && (!profileDetails.apex.bank || (profileDetails.apex.bank && profileDetails.apex.bank.length && profileDetails.apex.bank.length === 0));

    if (!profileDetails.email) {
      return null
    }

    if (profileDetails.email && !isEmpty(apex) && accountStatusLoading) {
      return <Spinner isFetching={accountStatusLoading} />
    }
    if (!this.state.underVerification && apexStatus && apexStatus.status === 'SUSPENDED' && apexStatus.investigations && apexStatus.investigations[0].sketchId.result && apexStatus.investigations[0].sketchId.result.equifaxResult) {
      let activeSketches = !isEmpty(apexStatus) && apexStatus.investigations.filter((invi) => !isEmpty(invi.sketchId))
      return (
        <div className="w-100 no-result-wrapper accountspage custom_height custom-scroll" style={{ display: "flex", flexDirection: "column", justifyContent: 'flex-start' }}>
          <div className="text-secondary text-center upload_doc_container w-100" style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <Spinner isFetching={this.state.docSectionLoading} />
            <h2 className="mb-3 mt-3">Magnifi Takes Security Seriously. Please Verify Your Identity Below.</h2>
            <Row className="mb-3">
              <Col sm={2} xs={12}>
                {!isEmpty(apexStatus) && activeSketches.length > 1 ?
                  <div className="w-100 primary_secondry_tabs">
                    <span className="applicant_span">Applicant</span>
                    <ul>
                      {apexStatus.investigations.map((investigation, index) => {
                        if (!isEmpty(investigation.sketchId)) {
                          let heading = ''
                          if (index === 0) {
                            heading = this.props.profile.first_name + ' ' + this.props.profile.last_name
                          } else if (index === 1) {
                            heading = this.props.profile.apex.joint_user.joint_first_name + ' ' + this.props.profile.apex.joint_user.joint_last_name
                          }
                          return (
                            <li>
                              <a
                                className={classnames({ active: this.state.activeUser === index + 1 })}
                                onClick={() => {
                                  this.setState({ activeUser: index + 1 })
                                }}
                                href={'javascript:void(0)'}
                              >
                                {heading}
                              </a>
                            </li>
                          )
                        }
                        return null
                      })}
                    </ul>
                  </div> : null}
              </Col>
              <Col sm={8} xs={12}>
                {!isEmpty(apexStatus) ?
                  <TabContent activeTab={this.state.activeUser}>
                    {apexStatus.investigations.map((investigation, index) => {
                      if (!isEmpty(investigation.sketchId)) {
                        let heading = ''
                        if (index === 0) {
                          heading = 'Primary User Documents'
                        } else if (index === 1) {
                          heading = 'Secondary User Documents'
                        }
                        let cipies = investigation.sketchId.cipCategories.filter(el => el.categoryState === 'REJECTED' && el.requestedDocuments.length > 0)
                        if (cipies.length === 0) {
                          return (
                            <TabPane tabId={index + 1}>
                              All Documents Verified for this user.
                            </TabPane>
                          )
                        }
                        return (
                          <TabPane tabId={index + 1}>
                            <Nav tabs>
                              {cipies.map((el, childIndex) => {
                                return (
                                  <NavItem style={{ width: `${parseInt(100/cipies.length)}%` }}>
                                    <NavLink
                                      className={classnames({ active: this.state.activeTab[index] === childIndex + 1 })}
                                      onClick={() => {
                                        let activeTab = this.state.activeTab
                                        activeTab[index] = childIndex + 1
                                        this.setState({ activeTab })
                                      }}
                                    >
                                      {FORMLABLES[el.name]
                                        ? FORMLABLES[el.name]
                                        : el.name.replace('_', ' ')}
                                    </NavLink>
                                  </NavItem>
                                )
                              })}
                            </Nav>
                            <TabContent activeTab={this.state.activeTab[index]} style={{ margin: 'auto', width: '70%' }}>
                              {cipies.map((el, childIndex) => {
                                let sortedRequestedDoc = el.requestedDocuments.sort(function (a, b) { return a.description.length - b.description.length });
                                return (
                                  <TabPane tabId={childIndex + 1}>
                                    <Row>
                                      <Col md={12}>
                                        <h3>Upload any of the following documents.</h3>
                                      </Col>
                                      {sortedRequestedDoc.map((doc, i) => {
                                        return (
                                          <Col xs={12} md={12} lg={12} xl={doc.description.length > 30 ? 12 : 6}>
                                            <FormGroup onClick={() => {
                                              let selectedDocs = JSON.parse(JSON.stringify(this.state.selectedDocs));
                                              selectedDocs[index][childIndex] = doc;
                                              this.setState({ selectedDocs })
                                            }}>
                                              <Label>
                                                <Input type="radio" onChange={() => { }} checked={this.state.selectedDocs[index] && this.state.selectedDocs[index][childIndex] && this.state.selectedDocs[index][childIndex].name === doc.name} name={doc.name} />{' '}
                                                {doc.description}
                                              </Label>
                                            </FormGroup>
                                          </Col>
                                        )
                                      })}
                                      <Col md={12}>
                                        <Dropzone activeStyle={{ border: '2px solid red' }} accept='image/jpeg, image/png, image/jpg, image/gif' onDrop={(e) => this.onDrop(e, el, childIndex, index, investigation, childIndex < cipies.length - 1 ? true : false)} noClick={this.state.selectedDocs[index] && this.state.selectedDocs[index][childIndex] && !this.fileAlreadyExists(index, childIndex) ? false : true} noKeyboard={this.state.selectedDocs[index] && this.state.selectedDocs[index][childIndex] && !this.fileAlreadyExists(index, childIndex) ? false : true} noDrag={this.state.selectedDocs[index] && this.state.selectedDocs[index][childIndex] && !this.fileAlreadyExists(index, childIndex) ? false : true}>
                                          {({ getRootProps, getInputProps }) => (
                                            <section className="dropzone-container container">
                                              <div {...getRootProps({ className: 'dropzone' })}>
                                                <input {...getInputProps()} />
                                                <div>
                                                  <CloudUploadOutlined className={this.state.selectedDocs[index] && this.state.selectedDocs[index][childIndex] && !this.fileAlreadyExists(index, childIndex) ? "upload_icon upload_icon_active" : "upload_icon"} />
                                                  <p>Drag & Drop Or <a href="javascript:void(0)" className={this.state.selectedDocs[index] && this.state.selectedDocs[index][childIndex] && !this.fileAlreadyExists(index, childIndex) ? "active_link" : "disabled_link"}>Click Here</a> To Upload</p>
                                                </div>
                                              </div>
                                            </section>
                                          )}
                                        </Dropzone>
                                        <div className="w-100 text-center">
                                          <span style={{ fontSize: 11, color: 'black' }}>
                                            Accepted file types: .png, .jpeg, .jpg and .gif only.
                                          </span>
                                        </div>
                                      </Col>
                                    </Row>
                                  </TabPane>
                                )
                              })}
                            </TabContent>
                            {this.state.uploadedFiles.map((snaps, snapindex) => {
                              if (snaps.primaryOrSecondry === index) {
                                return (
                                  <Row className="file_list_item mt-2 p-0 ml-0 mr-0">
                                    <Col sm={6} md={3} className="child_col" style={{ borderRight: '1px solid #d1d1d1' }}>
                                      <span>Pending Verification</span>
                                      <p>{snaps.pendingState}</p>
                                    </Col>
                                    <Col sm={6} md={3} className="child_col">
                                      <span>Document Type</span>
                                      <p>{snaps.description}</p>
                                    </Col>
                                    <Col sm={6} md={5} className="child_col">
                                      <span>Uploaded File</span>
                                      <p style={{ color: '#56a9e8' }}>{snaps.fileName} <span style={{ fontSize: 10, color: '#a1a1a1' }}>({parseFloat(snaps.fileSize).toFixed(2)} kb)</span></p>
                                    </Col>
                                    <Col sm={2} md={1} className="child_col" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                      <CloseOutlined onClick={() => {
                                        let uploadedFiles = JSON.parse(JSON.stringify(this.state.uploadedFiles));
                                        uploadedFiles.splice(snapindex, 1);
                                        this.setState({ uploadedFiles })
                                      }} className="remove_file_button" />
                                    </Col>
                                  </Row>
                                )
                              }
                              return null
                            })}
                            <FormGroup className="cta-wrapper mt-4" style={{ display: 'inline-block', width: '100%' }}>
                              {/* <PrimaryButton
                                  type="button"
                                  onClick={() => { }}
                                  className="btn secondary-btn"
                                  style={{ width: '48%', float: 'left', fontSize: 20, height: 'unset' }}
                                >
                                  SAVE PROGRESS
                                </PrimaryButton> */}

                              <PrimaryButton
                                type="button"
                                disabled={this.submitDisabled()}
                                className="btn primary-btn"
                                style={{ width: '100%', fontSize: 20, height: 'unset' }}
                                onClick={() => this.onClickReview()}
                              >
                                SUBMIT FOR REVIEW
                              </PrimaryButton>
                            </FormGroup>
                          </TabPane>
                        )
                      }
                      return null
                    })}
                  </TabContent>
                  : null}
              </Col>
            </Row>
          </div>
          <InnerPageFooter style={{ width: "100%", borderTop: "1px solid #d5cbcb", textAlign: "center", fontFamily: "Open Sans", fontSize: "12px", padding: ".4em", background: "#f7f7f7", marginTop: "50px", marginBottom: 15 }} />
        </div>
      )
    }

    return (
      <div className="w-100 no-result-wrapper accountspage" style={{ display: "flex", flexDirection: "column" }}>
        <div className="text-secondary text-center" style={{ marginTop: '-20px', flex: 1, display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <div className="mg-btm-no-result">
            {pageType === 'create' || showLinkAccountBar ? <img src={noAccount} /> : <img src="/assets/images/no-result.png" />}
          </div>
          {!showLinkAccountBar ? <p className="font-weight-bold h3 col-grey title ">{config[pageType].title}</p> : <p className="font-weight-bold h3 col-grey title ">Link your Bank Account to Start Investing</p>}
          {!showLinkAccountBar ? <p className="sub-head sub-title">{config[pageType].desc}</p> : null}
          <div className="specific-query col-grey" id="landing-page">
            <div className="popular-search r32">
              {pageType === 'create' || showLinkAccountBar
                ? (showLinkAccountBar ? <Button
                  className="popular-search-button" outline color="secondary"
                  onClick={() => { this.props.history.push({ pathname: "/settings/tradingAccount", linkaccount: true }) }}
                >
                  LINK ACCOUNT
                </Button> : <Button
                  className="popular-search-button" outline color="secondary"
                  onClick={() => { this.handleRedirect('/register/trading') }}
                >
                  Create A Magnifi Investment Account
                </Button>)
                : <div className="row ">
                  <div className="col-lg-2">
                    <Button onClick={() => this.queryHandler('HEALTHCARE')} className="popular-search-button" outline color="secondary">HEALTHCARE</Button>
                  </div>
                  <div className="col-lg-3">
                    <Button onClick={() => this.queryHandler('BEST TECH EXPOSURE')} className="popular-search-button" outline color="secondary">BEST TECH EXPOSURE</Button>
                  </div>
                  <div className="col-lg-2">
                    <Button onClick={() => this.queryHandler('INVEST IN GOLD')} className="popular-search-button " outline color="secondary">INVEST IN GOLD</Button>
                  </div>
                  <div className="col-lg-5">
                    <Button onClick={() => this.queryHandler('THEMATIC FUNDS WITH ASSETS MORE THAN $1BILLION')} className="popular-search-button " outline color="secondary">THEMATIC FUNDS WITH ASSETS MORE THAN $1BILLION</Button>
                  </div>
                  <div className="col-lg-4">
                    <Button onClick={() => this.queryHandler('FUNDS THAT DO WELL WHEN MARKETS FALL')} className="popular-search-button " outline color="secondary">FUNDS THAT DO WELL WHEN MARKETS FALL</Button>
                  </div>
                  <div className="col-lg-4">
                    <Button onClick={() => this.queryHandler('US FUNDS THAT OUTPERFORM THE MARKET')} className="popular-search-button " outline color="secondary">US FUNDS THAT OUTPERFORM THE MARKET</Button>
                  </div>
                  <div className="col-lg-2">
                    <Button onClick={() => this.queryHandler('DIVERSITY')} className="popular-search-button " outline color="secondary">DIVERSITY</Button>
                  </div>
                  <div className="col-lg-2">
                    <Button onClick={() => this.queryHandler('HIGH YIELD FUNDS')} className="popular-search-button " outline color="secondary">HIGH YIELD FUNDS</Button>
                  </div>
                </div>}
            </div>
          </div>
        </div>
        <InnerPageFooter style={{ width: "100%", borderTop: "1px solid #d5cbcb", textAlign: "center", fontFamily: "Open Sans", fontSize: "12px", padding: ".4em", background: "#f7f7f7", marginTop: "50px" }} />
      </div>
    )
  }

}

const mapStateToProps = ({ profile }) => ({
  profile: profile.profile,
  apex: profile.profile.apex
})

const mapDispatchToProps = {
  setFundsState,
  fetchProfile
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ApexAccountErrors))


import React from 'react';
import { Button, Card, CardBody, Row, Col, ModalFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import { UncontrolledTooltip } from 'reactstrap';
import FlipMove from 'react-flip-move';
import { BarChartS1 } from '../charts/Recharts';
import { cn, sponsorLogo, D3AppColors09, calculatorOptions, calcTestDriveKey } from '../../DataSet';
import { getNest, numDc, dateToMonth, getImageOrFallback } from '../../Utils';
import { variablesWithoutDateRange } from './utils'
import { AttrCharts, DateRangeSelector, FooterActions, TruncatedNameSponsor } from './Common';
import WeightChangeModal from './WeightChangeModal';
import { addEventToAnalytics } from 'Utils';
import CompareSm from './SmallScreen/Compare';
import { connect } from 'react-redux';
import PremiumNoticeModal from 'components/PremiumNoticeModal';
import { getPremiumFeatureDetails } from '../../apis/test-drive';
import Spinner from '../../layouts/WebDashboard/SearchResults/Elements/Spinner';
import DeleteSelectorModal from './DeleteSelectorModal';
import CustomAlert from 'components/CustomAlert'
//var classNames = require('classnames');

export default class Compare extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props)
  }
  state = {
    opts: {},
    premiumModal: false,
    reportLoader: false,
    buttonDisabled: true,
    premiumFeature: 'false',
    notify: false,
  }
  buyHandler = () => {
    this.props.buyHandler('all');
  }
  onAlertDismiss = () => {
    const { onAlertDismiss } = this.props;
    if (onAlertDismiss) {
      onAlertDismiss();
    }
  }
  openPremiumModal = () => {
    // console.log('das')
    const { premiumUser } = this.props;
    if (premiumUser) {
      this.confirmPrintReport();
    } else if (this.state.premiumFeature === 'true') {
      getPremiumFeatureDetails('print_report_combine').then(res => {
        // console.log('openPremiumModal function', res.test_drive);
        this.setState({ premiumFeature: res.test_drive });
      });
      this.setState({
        premiumModal: true,
      });
    } else {
      this.setState({
        premiumModal: true,
      });
    }
  }

  closePremiumModal = () => {
    this.setState({
      premiumModal: false,
    });
  }

  premiumModalToggle = () => {
    this.setState({
      premiumModal: !this.state.premiumModal,
    });
  }

  confirmPrintReport = () => {
    this.closePremiumModal();
    // this.setState({
    //   reportLoader: true,
    // })
    const { printReport, premiumUser } = this.props;
    const data = {
      isCompareOnly: true,
      ...this.state,
      ...this.props,
    };
    addEventToAnalytics('Compare print report', 'Compare print report', 'COMPARE_PRINT_REPORT', { category: 'advisory_PTC' }, true);
    if (printReport && premiumUser) {
      printReport(data);
    } else if (printReport && this.state.premiumFeature === 'true') {
      printReport(data);
    }
  }

  componentDidMount() {
    // console.log(this.props.accountsPage);
    if (this.props.subscriptionPlan) {
      this.props.subscriptionPlan();
    }
    if (typeof this.props.accountsPage === 'undefined') {
      // console.log('------------------------- this.props.accountsPage --------------------', this.props.accountsPage);
      this.props.compareStats(this.props.items, this.props.scoreAttrs.filter(x => x.weight !== 0));
    }
  }

  render() {
    const { premiumModal, notify } = this.state;
    const { sfm: { reportLoaderCompareOnly, reportPDFDownloadStatusCompareOnly }, premiumUser } = this.props;
    return (
      <div>
        <PremiumNoticeModal
          {...this.props}
          open={premiumModal}
          premiumUser={premiumUser}
          onClickOK={this.confirmPrintReport}
          onClickCancel={this.closePremiumModal}
          onToggle={this.premiumModalToggle}
          notify={notify}
          openNotify={this.openNotify}
          notifyToggle={this.notifyToggle}
          premiumFeature={this.state.premiumFeature}
          modalActionType={'print_report_combine'}
          modalAction={this.testDrivePrintReport}
          featureName='print_report_combine'
        />
        {(reportLoaderCompareOnly || this.props.selectorTimeFrameLoader)
          && (
            <div className="report-spinner-wrapper">
              <Spinner />
            </div>
          )
        }
        {(reportPDFDownloadStatusCompareOnly && reportPDFDownloadStatusCompareOnly.message !== '' && reportPDFDownloadStatusCompareOnly.downloadStatus !== '')
          && (
            <CustomAlert className="alert-windown-wrapper" color={reportPDFDownloadStatusCompareOnly.downloadStatus} onDismissCallback={this.onAlertDismiss}>
              {reportPDFDownloadStatusCompareOnly.message}
            </CustomAlert>
          )
        }
        <div className="modal-body-ssf">
          <ItemList {...this.props} />
        </div>
        {!this.props.mobileDevice &&
          <ModalFooter className="py-2 sfm-modal-footer">
            <Footer printReports={this.openPremiumModal}  {...this.props} buyHandler={this.buyHandler} />
          </ModalFooter>
        }
      </div>
    )
  }
}

class ItemList extends React.Component {
  state = {
    items: this.props.items,
    displayYear: '',
    premiumModal: false,
    notify: false,
    premiumFeature: 'false',
    calculatorName: null,
    featureName: 'my_calculator',
    testDriveStatus: {},
    selectorName: '',
    deleteSelectorToggle: false,
  }

  componentDidMount() {
    const elems = document.querySelectorAll('.custom-scroll');
    if (elems.length) {
      [].forEach.call(elems, function (el) {
        if (el.scrollHeight > el.clientHeight) el.style.paddingRight = '.25rem';
        else el.style.paddingRight = '.25rem';
      });
    }
    if (this.props.subscriptionPlan) {
      this.props.subscriptionPlan();
    }
    let testDrive = null;
    getPremiumFeatureDetails('my_calculator').then(res => {
      testDrive = res.test_drive;
      let testDriveStatus = {
        'my_calculator': res.test_drive
      }
      this.setState({ premiumFeature: testDrive, testDriveStatus })
    });
    // Temporary change, need to rework this logic later
    // Add a condition to execute this only when a new fund is selected
    if (typeof this.props.accountsPage === 'undefined') {
      if (this.props.shareCompareState) {
        // Adding this to fix proposal button not working when sfm is cleared
        // and new fund is selected when the calculator doesn't have the daterange
        let selectedYear = this.state.displayYear;
        if (!selectedYear) {
          const firstKey = Object.keys(this.props.dateRange).length > 0 ? Object.keys(this.props.dateRange)[0] : null
          if (firstKey && this.props.dateRange[firstKey] && this.props.dateRange[firstKey].start) {
            selectedYear = 'Since ' + dateToMonth(this.props.dateRange[firstKey].start);
          }
          // selectedYear = 'Since '+dateToMonth(this.props.dateRange['return'].start);
        }
        console.log('selectedYear===>', selectedYear);
        const items = this.props.items.slice().sort(this.sortFn);
        const filterScoreattrs = this.props.scoreAttrs.filter(x => x.weight !== 0)
        this.props.updateSFMyear(items, filterScoreattrs, selectedYear)
      }
    }

  }

  sortFn = (a, b) => {
    let [_a, _b] = [a._sfStats, b._sfStats]
    if (_a && _b) {      // descending order
      if (_a.score < _b.score) return 1;
      else if (_a.score > _b.score) return -1;
    }
    return 0;
  }

  displayYear = (data) => {
    // console.log(data);
    if (data !== this.state.displayYear) {
      this.setState({
        displayYear: data,
      });
    }
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  onCalculatorChange(name) {
    const featureName = calcTestDriveKey[name] || name || 'my_calculator';
    this.setState({
      calculatorName: name,
      featureName,
    }, () => this.checkTestDrive(name))
  }

  checkTestDrive(name) {
    const { featureName, testDriveStatus } = this.state;
    if (typeof testDriveStatus[featureName] === 'undefined') {
      getPremiumFeatureDetails(featureName).then(res => {
        let updatedStatus = {
          ...testDriveStatus,
          [featureName]: res.test_drive
        }
        this.setState({
          premiumFeature: res.test_drive,
          testDriveStatus: updatedStatus
        }, () => this.switchCalculator(name));
      });
    } else {
      this.switchCalculator(name);
    }
  }

  switchCalculator(name) {
    const { premiumUser, funds, changeCalculator } = this.props;
    const { featureName, testDriveStatus } = this.state;
    addEventToAnalytics('Compare select comparison criteria', 'Compare select comparison criteria', 'COMPARE_SELECT_COMPARISON_CRITERIA', { query: funds.query, logId: funds.logId, value: name }, true);
    if (premiumUser === false &&
      (calculatorOptions.magnificalculator !== name)) {
      this.setState({
        premiumModal: true,
        premiumFeature: testDriveStatus[featureName] || true,
      });
    } else {
      this.props.changeCalculator(name);
    }
  }

  changeCalculatorTestDrive = () => {
    if (this.state.premiumFeature) {
      const { featureName, testDriveStatus } = this.state;
      let updatedStatus = {
        ...testDriveStatus,
        [featureName]: 'false'
      }
      this.setState({
        testDriveStatus: updatedStatus,
      })
      this.props.changeCalculator(this.state.calculatorName);
    }
  }

  deleteCalculator = (calc) => {
    this.setState({
      selectorName: calc,
    },
      () => this.toggleDeleteModal(true));
  }

  toggleDeleteModal = (val) => {
    this.setState({
      deleteSelectorToggle: val,
    })
  }

  openNotify = () => {
    this.setState({
      notify: true,
    });
  }

  notifyToggle = () => {
    this.setState({
      notify: !this.state.notify,
    });
  }

  closePremiumModal = () => {
    this.setState({
      premiumModal: false,
    });
  }

  premiumModalToggle = () => {
    this.setState({
      premiumModal: !this.state.premiumModal,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.scoreAttrs) != JSON.stringify(this.props.scoreAttrs)) {
      const items = this.props.items.slice().sort(this.sortFn);
      const filterScoreattrs = this.props.scoreAttrs.filter(x => x.weight !== 0)
      this.props.updateSFMyear(items, filterScoreattrs, this.state.displayYear)
    }
  }

  render() {
    const { selectedCalculator, scoreAttrs, premiumUser, customCalculators } = this.props;
    const { premiumModal, notify, featureName, selectorName, deleteSelectorToggle } = this.state;
    const items = this.props.items.slice().sort(this.sortFn);
    const filterScoreattrs = (selectedCalculator !== calculatorOptions.magnifiactivescreener)
      ? scoreAttrs.filter(x => x.weight !== 0) : scoreAttrs;
    const { dropdownOpen } = this.state;
    const isMagnifiactivescreener = selectedCalculator === calculatorOptions.magnifiactivescreener;

    // console.log('------------------------- this.props.accountsPage --------------------', this.props.accountsPage);
    if (typeof this.props.accountsPage === 'undefined') {
      if (this.props.shareCompareState) {
        // Adding this to fix proposal button not working when sfm is cleared
        // and new fund is selected when the calculator doesn't have the daterange
        let selectedYear = this.state.displayYear;
        if (!selectedYear) {
          const firstKey = Object.keys(this.props.dateRange).length > 0 ? Object.keys(this.props.dateRange)[0] : null
          if (firstKey && this.props.dateRange[firstKey] && this.props.dateRange[firstKey].start) {
            selectedYear = 'Since ' + dateToMonth(this.props.dateRange[firstKey].start);
          }
          // selectedYear = 'Since '+dateToMonth(this.props.dateRange['return'].start);
        }
        console.log('selectedYear===>', selectedYear);
        this.props.shareCompareState(items, filterScoreattrs, selectedYear);
      }
    }

    if (this.props.mobileDevice) {
      return (
        <>
          <CompareSm {...this.props}
            items={items}
            scoreAttrs={scoreAttrs}
            displayYear={this.displayYear}
            onCalculatorChange={name => this.onCalculatorChange(name)}
            selectedCalculator={selectedCalculator}
            filterScoreattrs={filterScoreattrs}
            premiumUser={premiumUser}
            premiumFeature={this.state.premiumFeature}
            modalActionType={featureName}
            modalAction={this.changeCalculatorTestDrive}
            premiumModal={premiumModal}
            closePremiumModal={this.closePremiumModal}
            featureName={featureName}
            customCalculators={customCalculators}
            deleteCalculator={this.deleteCalculator}
          />
          <DeleteSelectorModal
            modalToggle={deleteSelectorToggle}
            selectorName={selectorName}
            toggleDeleteModal={this.toggleDeleteModal}
          />
        </>
      )
    }

    return (
      <div>
        <PremiumNoticeModal
          {...this.props}
          open={premiumModal}
          premiumUser={premiumUser}
          onClickCancel={this.closePremiumModal}
          onToggle={this.premiumModalToggle}
          notify={notify}
          openNotify={this.openNotify}
          notifyToggle={this.notifyToggle}
          premiumFeature={this.state.premiumFeature}
          modalActionType={featureName}
          modalAction={this.changeCalculatorTestDrive}
          featureName={featureName}
        />
        <DeleteSelectorModal
          modalToggle={deleteSelectorToggle}
          selectorName={selectorName}
          toggleDeleteModal={this.toggleDeleteModal}
        />
        <Row className="no-gutters">
          <Col className="col-12 text-center" style={{ padding: '1rem 1rem 10px 1rem' }}>
            <div className="d-flex align-items-center justify-content-center multiple-calculator">
              <h4 className="mb-0" style={{ lineHeight: '26px' }}><strong>Identify</strong> the best selection using </h4>
              <Dropdown isOpen={dropdownOpen} toggle={this.toggle}>
                <DropdownToggle
                  caret
                  color="link"
                  className="h6 my-1 view-btn"
                  style={{ paddingTop: '1px' }}
                >
                  {selectedCalculator || 'selected comparison criteria'}
                </DropdownToggle>
                <DropdownMenu className="rounded-0 h6">
                  <div>
                    {Object.keys(calculatorOptions).map(name => (
                      <DropdownItem
                        className="py-1"
                        onClick={() => this.onCalculatorChange(calculatorOptions[name])}
                        disabled={calculatorOptions[name] === calculatorOptions.sponsorcalculator}
                        style={{ cursor: 'pointer' }}
                      >
                        <div className="selector-options">
                          <span>{calculatorOptions[name]}</span>
                        </div>
                      </DropdownItem>
                    ))}
                    {Object.keys(customCalculators).map(item => {
                      return (
                        <DropdownItem
                          className="py-1"
                          style={{ cursor: 'pointer' }}
                        >
                          <div className="selector-options">
                            <span
                              onClick={() => this.onCalculatorChange(item)}
                              className="selector-name"
                            >
                              {item}
                            </span>
                            <span
                              className="delete-selector"
                              onClick={() => this.deleteCalculator(item)}
                            >
                              <i class="fas fa-trash-alt"></i>
                            </span>
                          </div>
                        </DropdownItem>
                      )
                    })}
                    <DropdownItem divider />
                    <DropdownItem
                      className="py-1"
                      onClick={() => this.onCalculatorChange('create custom selector')}
                      style={{ cursor: 'pointer' }}
                    >
                      <div className="selector-options">
                        <span>create custom selector</span>
                      </div>
                    </DropdownItem>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>
          </Col>
          <Col className="col-6 pl-2 pr-1 h-100">
            <h5 className="mb-0 text-center">
              <div className="w-95 mx-auto pt-2 pb-1 rounded-top">Reorder to reflect what's important to you
                {
                  (selectedCalculator !== calculatorOptions.magnificalculator) &&
                  <WeightChangeModal
                    {...this.props}
                    scoreAttrs={scoreAttrs}
                    premiumUser={this.state.premiumFeature === 'true' ? true : this.props.premiumUser}
                    scoreAttrsWeightHandler={this.props.scoreAttrsWeightHandler}
                  />
                }
              </div>
            </h5>
            <div className="custom-scroll" style={{ height: 360 }}>
              <div className="bg-secondary rounded" style={{ paddingTop: '2px' }}>
                <AttrCharts
                  tab="Compare"
                  funds={this.props.funds}
                  scoreAttrs={scoreAttrs}
                  dataItems={items}
                  range={this.props.range}
                  dateRange={this.props.dateRange}
                  rangeHandler={this.props.rangeHandler}
                  displayYear={this.displayYear}
                  dropdownScoreAttrs={this.props.dropdownScoreAttrs}
                  chart={Chart}
                  scoreAttrsHandler={this.props.scoreAttrsHandler}
                  scoreAttrsHandler2={this.props.scoreAttrsHandler2}
                  filterScoreattrs={filterScoreattrs}
                  isMagnifiactivescreener={isMagnifiactivescreener}
                />
              </div>
            </div>
          </Col>
          <Col className="col-6 pl-1 pr-1 h-100">
            <h5 className="mb-0 text-center">
              <div className="w-75 mx-auto pt-2 pb-1 rounded-top">Best selection to reflect your preferences</div>
            </h5>
            <div className="custom-scroll" style={{ height: 360 }}>
              <div className="bg-secondary rounded" style={{ paddingTop: '2px' }}>
                <FlipMove>
                  {items.map((e, i) => {
                    return (
                      <div key={cn(e, 'Id')}>
                        <ItemCard card={e} funds={this.props.funds} idx={i} clearHandler={this.props.clearHandler} buyHandler={this.props.buyHandler} actionHandler={this.props.actionHandler} selectedCalculator={selectedCalculator} />
                      </div>
                    )
                  })}
                </FlipMove>
                {this.props.loading &&
                  <div className="overlay active">
                    <div className="text rounded">
                      <i className="fal fa-circle-notch fa-3x fa-spin text-gray-5"></i>
                      <h5 className="mt-2 mb-0">Comparing your selections</h5>
                    </div>
                  </div>}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export class ItemCard extends React.Component {
  state = {
    imageValidPath: false,
  }

  componentDidMount() {
    const { card } = this.props;
    if (cn(card, 'Sponsor')) {
      getImageOrFallback(
        sponsorLogo(cn(card, 'Sponsor'))
      ).then(result => {
        if (result) {
          this.setState({ imageValidPath: true });
        } else {
          this.setState({ imageValidPath: false });
        }
      });
    }
  }

  buyHandler = () => {
    const { funds, card } = this.props;
    this.props.buyHandler(card);
    if (funds) {
      addEventToAnalytics('Compare fund buy', 'Compare fund buy', 'COMPARE_FUND_BUY', { query: funds.query, logId: funds.logId, ticker: card.ticker, category: 'advisory_PTC' }, true);
    }
  }

  addHandler = () => {
    this.props.actionHandler('combine', this.props.card);
  }

  unselectCard = (item) => {
    const { funds } = this.props;
    this.props.clearHandler(item);
    if (funds) {
      addEventToAnalytics('Compare fund delete', 'Compare fund delete', 'COMPARE_FUND_DELETE', { query: funds.query, logId: funds.logId, ticker: item.ticker }, true);
    }
  }

  callEvent = () => {
    const { funds, card } = this.props;
    if (funds) {
      addEventToAnalytics('Compare score hover', 'Compare score hover', 'COMPARE_SCORE_HOVER', { query: funds.query, logId: funds.logId, ticker: card.ticker }, true);
    }
  }

  getFailedCriteriaMessage = (value) => {
    const totalFailedCriteria = value.length;
    const failedText = value.map(item => {
      const value = item.value + item.suffix;
      return `${item.name} ${item.condition} ${value}`;
    })
    const failedTextObj = {
      items: failedText,
      text: this.concatCriteria(failedText)
    }
    return failedTextObj;
  }

  concatCriteria = (items) => {
    if (!items) {
      return '';
    }
    if (!Array.isArray(items)) {
      return items;
    }
    return items.reduce((acc, curr, i) => {
      return i !== 0 ? acc + ' | ' + curr : curr
    }, '');
  }

  render() {
    const { imageValidPath } = this.state;
    const { card, idx, funds, selectedCalculator } = this.props;
    let score = Math.round(getNest(['_sfStats', 'score'], card));
    score = (isNaN(score) || score < 0) ? 0 : score;
    const failedCriteria = card._sfStats.failedConditions;
    const failedCriteriaMessage = failedCriteria ? this.getFailedCriteriaMessage(failedCriteria) : '';
    const isMagnifiactivescreener = selectedCalculator === calculatorOptions.magnifiactivescreener;
    return (
      <div style={{ padding: '0 2px 2px' }}>
        <Card className="shadow-sm border-0 card-item">
          <CardBody className="p-0 card">
            <Row className="no-gutters">
              <Col className="col-12 px-1 pt-1 progress-wrapper">
                <div className="progress-box position-relative">
                  <div className="progress score flex-grow-1">
                    {score !== -1 &&
                      <div className="progress-bar" role="progressbar"
                        style={{
                          minWidth: '30px',
                          width: `${score}%`,
                          backgroundColor: score === 0 ? 'transparent' : D3AppColors09[idx % D3AppColors09.length],
                          color: score === 0 ? '#4f5357' : '#fff',
                        }}
                        aria-valuenow={score} aria-valuemin="0" aria-valuemax="100" onMouseEnter={this.callEvent.bind(this)}>
                        {!isMagnifiactivescreener &&
                          <>
                            <span id={'tooltip-' + idx} className="ml-2">{score}%</span>
                            <UncontrolledTooltip className="w-sm" placement="top" target={'tooltip-' + idx}>
                              The score reflects the confidence that this security is indeed the best among the selected securities.
                            </UncontrolledTooltip>
                          </>
                        }
                      </div>
                    }
                    {(isMagnifiactivescreener && score === 0) &&
                      <span className="failed-criteria">
                        <div className="ban-icon-box" title="Failed Crieteria">
                          <i className="fal fa-ban"></i>
                        </div>
                        <span
                          className="failed-criteria-text"
                          id={'tooltip-failedtext-' + idx}
                        >
                          {failedCriteriaMessage.text}
                        </span>
                        {failedCriteriaMessage.items &&
                          failedCriteriaMessage.items.length > 2 ? (
                          <UncontrolledTooltip
                            placement="top"
                            target={'tooltip-failedtext-' + idx}
                          >
                            <FailedItems
                              items={failedCriteriaMessage.items}
                            />
                          </UncontrolledTooltip>
                        ) : ''}
                      </span>
                    }
                  </div>
                </div>
                <div className="close-icon-box" title="Remove Fund">
                  <i className="fal fa-times-circle" onClick={() => this.unselectCard(card)}></i>
                </div>
              </Col>
              <Col className="col-12">
                <div className="selected-card bg-ssf-gray-l5 p-1 h-100 d-flex align-items-center"
                  style={{ borderLeftColor: D3AppColors09[idx % D3AppColors09.length], border: 0, minHeight: 80 }}>
                  {/*card._showSponsoredOnSFM && <div className="sponsored-logo-tag" style={{ top: 5, left: 6 }}>SPONSORED</div>*/}
                  <div className="pr-1 text-center">
                    <h5 className="mb-0 text-blue text-gray-5 ticker-size ticker-price">{cn(card, 'Ticker Short')}</h5>
                    <h3 className={`mb-0 text-info`} style={{ minWidth: '5rem' }}>
                      <span className="ticker-price text-gray-5">$</span>
                      <span className="ticker-price">{cn(card, 'Nav Price') ? cn(card, 'Nav Price').toFixed(1) : ''}</span>
                    </h3>
                  </div>
                  <TruncatedNameSponsor showSponsorLogo={card._showSponsoredOnSFM} imageValidPath={imageValidPath} name={cn(card, 'Name2')} sponsor={cn(card, 'Sponsor')} lines={1} />
                  <div className="ml-auto pl-2 h-100">
                    <div className="d-flex align-items-center h-100 btn-wrap m-0">
                      <Button color={"select"} outline
                        style={{ width: '135px' }}
                        className="btn h4 mb-0 mt-0 text-uppercase font-weight-bold px-3 py-1 modal-btn-br"
                        style={{ marginTop: '2px' }}
                        onClick={() => {
                          this.buyHandler()
                          if (funds) {
                            addEventToAnalytics('Compare Buy Button', 'Compare Buy Button', 'COMPARE_BUY_BUTTON', { query: funds.query, logId: funds.logId, tickers: card.ticker, clickBtn: 'Buy' }, true);
                          }
                        }}>
                        Buy</Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const FailedItems = ({ items }) => {
  return (
    <React.Fragment>
      {items.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {item} <br />
          </React.Fragment>
        )
      })}
    </React.Fragment>
  );
}

const Footer = (props) => {
  const { funds, items, funds: { chartReportData: { selectedFunds } }, selectedCalculator } = props;
  const disableProposal = selectedFunds.length === 0 || selectedCalculator === calculatorOptions.magnifiactivescreener;
  let tickerNames;
  if (items.length > 0) {
    tickerNames = items.map(x => x.ticker);
  }
  return (
    <React.Fragment>
      <FooterActions hideBack={true} backHandler={() => props.actionHandler('funds')} clearHandler={props.clearAllHandler} />

      <div className=" text-white h6 font-weight-bold pt-2">
        Patent Pending
      </div>

      <Button color="tools"
        style={{ width: '135px' }}
        className="btn h3 text-uppercase font-weight-bold p-3 mb-0 ml-auto mt-0 secondary-btn"
        title={(!props.disabled && !disableProposal) &&
          "Download search analytics report for the last query"}
        onClick={() => {
          props.printReports()
        }}
        disabled={props.disabled || disableProposal}
      >
        PROPOSAL
      </Button>

      <Button color="tools"
        style={{ width: '135px' }}
        className="btn h3 text-uppercase font-weight-bold p-3 mb-0 mt-0 ml-3 secondary-btn"
        title="Evaluate addition to portfolios"
        onClick={() => {
          props.actionHandler('combine')
          if (funds) {
            addEventToAnalytics('Compare SFM Footer Button', 'Compare SFM Footer Button', 'COMPARE_SFM_FOOTER_BUTTON', { query: funds.query, logId: funds.logId, tickers: tickerNames, clickBtn: 'Add All', category: 'advisory_PTC' }, true);
          }
        }}>Add All</Button>

      <Button color="select"
        style={{ width: '135px' }}
        className="btn h3 text-uppercase font-weight-bold p-3 mb-0 mt-0 ml-3 secondary-btn"
        onClick={() => {
          props.buyHandler()
          if (funds) {
            addEventToAnalytics('Compare SFM Footer Button', 'Compare SFM Footer Button', 'COMPARE_SFM_FOOTER_BUTTON', { query: funds.query, logId: funds.logId, tickers: tickerNames, clickBtn: 'Buy All', category: 'advisory_PTC' }, true);
          }
        }}>Buy All</Button>
    </React.Fragment>
  )
}

export const Chart = (props) => {
  const { idx, attr, items, filterScoreattrs, dropdownScoreAttrs } = props;
  const { name, col, code, colOff, suffix, parser, multiplier = 1 } = attr;

  const getMultiplier = (col) => {
    const multiplierList = ['weight_bid_ask', 'trkerror', 'weight_premium_to_nav',
      'weight_trend', 'outperformance_3y', 'outperformance_5y', 'outperformance_10y',
      'alpha_5y', 'alpha_10y'];
    return multiplierList.includes(col) ? 100 : 1;
  }
  const data = items.map((e, i) => {
    let out = {
      name: cn(e, 'Ticker Short'),
      color: D3AppColors09[i % D3AppColors09.length],
    }
    const decimalPrec = col === 'weight_bid_ask' ? 3 : 2;
    const multiplier = getMultiplier(col);
    let labelNA = (((col === 'weight_bid_ask' || col === 'weight_premium_to_nav' || col === 'vol') && e.type === 'MF') || (col === 'manager_tenure' && (e.type === 'ETF' || e.type === 'ETN'))) ? 'NA' : '';
    if (getNest(['_sfStats', col], e) === null) { labelNA = 'NA' }
    return getNest(['_sfStats', colOff], e)
      ? { ...out, value: 0.0, label: 'Inactive', decimalPrec, labelNA }
      : { ...out, value: labelNA === 'NA' ? 0.0 : numDc(getNest(['_sfStats', col], e) * multiplier, decimalPrec), decimalPrec, labelNA }
    // : { ...out, value: numDc(getNest(['_sfStats', col], e) * multiplier, decimalPrec), decimalPrec }
  });
  const maxScoreAttrs = (filterScoreattrs || []).length

  // console.log(dropdownScoreAttrs && dropdownScoreAttrs.find(c => c.code === code && c.yearDropdown));
  // console.log(code);
  // console.log(props.dateRange);
  // console.log(props.range);
  // console.log(props.range[code]);
  // console.log(props.dateRange[code]);
  console.log(props);

  return (
    <div style={{ padding: '0 2px 2px' }}>
      <div className="p-1 bg-white rounded">
        <div className="bg-ssf-gray-l1" style={{ height: '109px' }}>
          <div className="d-flex align-items-center px-2" style={{ height: '20px' }}>
            <div className="h6 mb-0 font-weight-bold">{name}</div>
            {
              idx !== 1 && <Button color={"select"} outline
                style={{ padding: '2px' }}
                className="h6 mb-0 lh-100 rounded-0 ml-2 sfmodal-btn-arw"
                title="Increase importance"
                onClick={() => {
                  props.scoreAttrsHandler(name, 'up')
                  addEventToAnalytics('Compare weight reorder', 'Compare weight reorder', 'COMPARE_WEIGHT_REORDER', { name: items.query, logId: items.logId, name: 'UP' }, true);
                }}>
                <i className="fas fa-fw fa-arrow-alt-up"></i></Button>
            }
            {
              idx !== maxScoreAttrs && <Button color={"select"} outline
                style={{ padding: '2px' }}
                className="h6 mb-0 lh-100 rounded-0 ml-2 sfmodal-btn-arw"
                title="Decrease importance"
                onClick={() => {
                  props.scoreAttrsHandler(name, 'down')
                  addEventToAnalytics('Compare weight reorder', 'Compare weight reorder', 'COMPARE_WEIGHT_REORDER', { query: items.query, logId: items.logId, name: 'DOWN' }, true);
                }}>
                <i className="fas fa-fw fa-arrow-alt-down"></i></Button>
            }
            {!variablesWithoutDateRange.includes(name) &&
              ((props.dateRange[code]) && props.range[code] && typeof props.displayYear === 'undefined' && typeof (props.dateRange[code]).start !== 'undefined') &&
              (
                <div className="ml-auto">
                  <DateRangeSelector
                    dropdownScoreAttrs={dropdownScoreAttrs}
                    rangeHandler={props.rangeHandler}
                    col={code}
                    range={props.range[code]}
                    activeLabel={'Since ' + dateToMonth(props.dateRange[code].start)}
                    dateRange={props.dateRange[code]}
                    fromDatePicker={props.range.fromDatePicker}
                  />
                </div>
              )
            }
            {!variablesWithoutDateRange.includes(name) && dropdownScoreAttrs && dropdownScoreAttrs.find(c => c.code === code && c.yearDropdown) &&
              ((props.dateRange[code]) && props.range[code] && typeof props.displayYear !== 'undefined' && typeof (props.dateRange[code]).start !== 'undefined') &&
              (
                <div className="ml-auto">
                  <DateRangeSelector
                    dropdownScoreAttrs={dropdownScoreAttrs}
                    rangeHandler={props.rangeHandler}
                    col={code}
                    range={props.range[code]}
                    activeLabel={'Since ' + dateToMonth(props.dateRange[code].start)}
                    displayYear={props.displayYear}
                    dateRange={props.dateRange[code]}
                    fromDatePicker={props.range.fromDatePicker}
                  />
                </div>
              )
            }
          </div>
          <div className="px-1 text-gray-3 chart-wrap" style={{ height: 89 }}>
            <BarChartS1 data={data} suffix={suffix} parser={parser} showTickerOnAxis />
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react';
import { connect } from 'react-redux';

import { Row, Col, Button } from 'reactstrap';
import { addEventToAnalytics } from 'Utils';

import { BarChartPL } from 'components/charts/BarCharts'

import { SelectorDD, KeyStats3, KeyStats5, NoData } from './Common'

export const PortfolioValue = (props) => {
  const stats = [
    { name: 'Value Of Fund Holdings', value: props.stats.portfolioValue + parseFloat(props.cashEquity) },
    // { name: 'Value of My Investments', value: props.stats.fundBalance },
    // { name: 'Cost of My Investments', value: props.stats.costOfMyInvestment },
    { name: 'Total Account Value', value: props.stats.fundBalance },
    { name: 'Value Of My Securities', value: props.stats.portfolioValue },
  ]
  return (
    <KeyStats3 stats={stats} prec={2} selectedOption={props.selectedOption}/>
  )
}

// export const PortfolioGains = (props) => {
//   return (
//     <KeyStats4 {...props} prec={2} />
//   )
// }

export const BuyingPower = (props) => {
  const stats = [
    { name: 'Buying Power', value: props.avaBalance },
    { name: 'Cash Balance', value: props.cashEquity },
  ]
  return (
    <KeyStats5 {...props} stats={stats} prec={2} />
  )
}

export class ProfitLoss extends React.PureComponent {
  // opts = [ 'Sectors', 'Regions' ]
  // state = { opt: this.opts[0] }

  // optHandler = opt => {
  //   if(opt == 'Sectors'){
  //     addEventToAnalytics('Enhancer PL Sector','Enhancer PL Sector','ENHANCER_PL_SECTOR',{},false);
  //   }
  //   else{
  //     addEventToAnalytics('Enhancer PL Region','Enhancer PL Region','ENHANCER_PL_REGION',{},false);
  //   }
  //   this.setState({ opt });
  // }

  formatter = val => `$${val.toFixed(1)}`;

  // chartData = () => {
  //   return this.state.opt === this.opts[0]
  //          ? this.props.stats.priceSectors.length > 0 ? this.props.stats.priceSectors : []
  //          : this.props.stats.priceRegions.length > 0 ? this.props.stats.priceRegions : [];
  // }

  render() {
    if (typeof this.props.stats === 'undefined' || typeof this.props.stats.priceSectors === 'undefined' || typeof this.props.stats.priceRegions === 'undefined') return (<NoData height='225px' />);

    // const data = this.chartData();

    const assetData = [];
    const sectorData = this.props.stats.priceSectors.length > 0 ? this.props.stats.priceSectors : []; // this.chartData();
    const regionData = this.props.stats.priceRegions.length > 0 ? this.props.stats.priceRegions : [];
    // <SelectorDD opts={this.opts} optHandler={this.optHandler} />
    return (
      <React.Fragment>
        <h5 className="mb-1 card-title p-0">Profit / Loss</h5>
        <div className="accounts-allocations-chart-container">
          {(assetData > 0) && (
            <div className="accounts-allocations-chart-wrapper">
              {(assetData <= 0) ? (
                <NoData height='95px' />
              ) : (
                <BarChartPL data={assetData} formatter={this.formatter} />
              )}
              <div className="accounts-allocations-chart-label">By Asset class</div>
            </div>
          )}
          <div className="accounts-allocations-chart-wrapper">
            {(sectorData <= 0) ? (
              <NoData height='95px' />
            ) : (
              <BarChartPL data={sectorData} formatter={this.formatter} />
            )}
            <div className="accounts-allocations-chart-label">By Sectors</div>
          </div>
          <div className="accounts-allocations-chart-wrapper">
            {(regionData <= 0) ? (
              <NoData height='95px' />
            ) : (
              <BarChartPL data={regionData} formatter={this.formatter} />
            )}
            <div className="accounts-allocations-chart-label">By Regions</div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

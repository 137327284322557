import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { setProfileState } from 'actions/profile';
import { setGlobalState } from 'actions/global';
import { fetchReferral } from 'actions/profile';
import { connect } from 'react-redux';
import { Notification } from 'shared-components';
import PremiumNoticeModal from 'components/PremiumNoticeModal';

class ReferralCodeContainer extends Component {
  state = {
    isNotifyOpen: false,
    premiumModal: false,
    notify: false,
  }

  componentDidMount() {
    this.props.fetchReferral()
    let params, qury;
    if (this.props.location.search) {
      params = new URLSearchParams(this.props.location.search);
      qury = params.get('invite');
    }
    if (qury && qury != '') {
      this.premiumModalToggle();
    } 
  }

  openPremiumModal = () => {
    this.setState({
      premiumModal: true,
    });
  }

  openNotify = () => {
    this.setState({
      notify: true,
    });
  }

  notifyToggle = () => {
    this.setState({
      notify: !this.state.notify,
    });
    this.props.fetchReferral()
  }

  closePremiumModal = () => {
    this.setState({
      premiumModal: false,
    });
  }

  premiumModalToggle = () => {
    this.setState({
      premiumModal: !this.state.premiumModal,
    });
  }

  confirmPrintReport = () => {
    this.closePremiumModal();
  }

  copyToShare = () => {
    let copyText = document.getElementById('referral-code');
    copyText.select();
    document.execCommand("copy");
    this.setState({ isNotifyOpen: true });
    setTimeout(() => { this.setState({ isNotifyOpen: false }) }, 2000);
  }

  render() {
    const { isNotifyOpen, premiumModal, notify } = this.state;
    const { profile, premiumUser } = this.props;
    const { referralCode , usersList, referralStatusFailedMessage } = profile;

    if (referralStatusFailedMessage) {
      return (
        <div className="weights-container settings__content-area">
          <div className="failed-referral-message-block sub-referal-text">
            <i className="fal fa-info-circle"></i>
            {referralStatusFailedMessage}
          </div>
        </div>
      )
    }

    const renderDesign = usersList.map(attr => {
      return attr === null ? (
        <tr className="">
          <td className="refer-name">
            No Users Added
          </td>
        </tr>
      ) :(
        <tr key={attr.col} className="">
          <td className="">
            {attr.email}{`${(attr.first_name !== ' ' && attr.first_name !== '') ? ' - '+attr.first_name : '' }`}
          </td>
          <td className="">
            <i id={attr.col} className={`far ${(attr.status === 'Pending') ? 'fa-times status-icon' : 'fa-check sub-referal-text'}`}></i>
          </td>
        </tr>
      )
    })

    const clickTOCopy = (
      <div className="content-wrapper">
        <p colSpan="2" className="sub-referal-text text-center">
          Your Personal referral code
        </p>
        <input id="referral-code" className="referral-code-input sub-email-text text-center" value={referralCode} />
        <Button
          color="tools"
          type="submit"
          className="btn secondary-btn"
          onClick={this.copyToShare}
          >
          Copy to share
        </Button>
      </div>
    );

    const contentDesign = (
      <div>
        <PremiumNoticeModal
          {...this.props}
          referralPage={true}
          open={premiumModal}
          premiumUser={premiumUser}
          onClickOK={this.confirmPrintReport}
          onClickCancel={this.closePremiumModal}
          onToggle={this.premiumModalToggle}
          notify={notify}
          openNotify={this.openNotify}
          notifyToggle={this.notifyToggle}
          featureName='refer'
        />
        <div className="referral-code-container">
          <div className="card mb-3">
            <p>Spread the word, invite your friends.</p>
            <Button className="btn secondary-btn" onClick={this.premiumModalToggle}>SEND INVITE</Button>
          </div>
          <div className="card mb-3">
            <p>{`Want to directly invite your friends? Go ahead. You'll still receive the referral benefits. Just ask them to use your personal referral code: `}<span className="sub-referal-text">{referralCode}</span>.</p>
          </div>
          {(usersList.length !== 0) && (
            <div className="card mb-3">
              <table className="invitation-table-wrapper" style={{width: '100%' }}>
                <thead>
                  <tr>
                    <td>Inivitations</td>
                    <td>Status</td>
                  </tr>
                </thead>
                <tbody>
                  {(usersList.length !== 0) && renderDesign}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    )

    return (
      <div className="weights-container settings__content-area">
        {referralCode !== '' && contentDesign}
        {(isNotifyOpen && referralCode !== '')
          && (
            <Notification
              isNotifyOpen={isNotifyOpen}
              notifyClassName="notify-card"
              notifyBody="Referral code copied to clipboard"
            />
          )
        }
      </div>
    )
  }
}


const mapStateToProps = ({ profile, auth, global }) => ({
  profile,
  inviteSentTo: profile.inviteSentTo,
  inviteReferralCode: profile.inviteReferralCode,
  inviteFailed: profile.inviteFailed,
  inviteSuccess: profile.inviteSuccess,
  inviteError: profile.error,
  inviteMessage: profile.inviteMessage,
  surveyModal: global.surveyModal,
  premiumUser: auth.user.premiumUser,
})

const mapDispatchToProps = {
  fetchReferral,
  setGlobalState,
  setProfileState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferralCodeContainer)

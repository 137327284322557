import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import { uniqBy, reverse } from 'lodash';
import { MoreButton, FormatCurrency, TruncatedNameSponsor, withScrollBar, NoData } from './Common';

import { setEnhancerState } from 'actions/enhancer';
import { addFundToSFM, setSFMCatData, refreshSFMState, addSFMItemTickersRequest, removeSFMItemTickersRequest } from 'actions/sfm';

import { fetchFundByTicker } from 'apis/account';
import {
  fetchFunds,
  fetchFundsDetails,
  fetchCategories,
  fetchFundCommissions,
  fetchRealTimePrices,
  fetchCharts,
  fetchReturnsData,
  fetchRegionsChart,
  fetchReturnsDataV1,
  fetchChartsV1,
} from 'apis/funds';

import { calcStats } from 'Stats';
import {
  getNest,
  getNestDefault,
  arrayToHash,
  queryStr,
  uniqValues,
  convertToCapitalCase,
} from 'Utils';
import {
  FilterAttrData,
  SortAttrs,
  DefaultSortAttr,
  DateRange,
  ViewNames,
  Api,
  ApiHeaders,
  cn,
  CN,
  MaxResult,
  Universe,
  BrokersComms,
} from 'DataSet';
import { SectorColors } from 'data/Colors'
import { getFundsUpdatedPrice } from 'layouts/utils';

import getFilteredListByRange from './utils';

const classNames = require('classnames');

class Holdings_ extends React.Component {
  constructor(props) {
    super(props);
    this.structuredFund = this.structuredFund.bind(this);
    this.getFundsData = this.getFundsData.bind(this);
  }

  componentDidMount() {

  }

  componentDidUpdate() {

  }

  itemClickHandler = fundId => ev => {
    console.log(fundId);
		if (this.props.itemClickHandler) {
			this.props.itemClickHandler(fundId);
		}
  }

  deleteHandler = (d) => {
    const { deleteHandler } = this.props;
    if (deleteHandler) {
      deleteHandler(d);
    }
  }

  updateActiveFund = (fundId) => {
    console.log(fundId);
    if (this.props.itemClickHandler) {
      this.props.itemClickHandler(fundId);
    }
  }

  async structuredFund(_funds) {
    try {
      let funds = [];
      if (_funds["funds"] && _funds["funds"].length) {
        funds = _funds["funds"].slice(0, MaxResult);
      }
      if (funds.length <= 0)
        return { funds: [], categories: [], range: _funds["date_range"], filterAttrData: {} };

      let ticks = uniqValues(funds, 'ticker').join(',');

      let [returns, categories, charts] = await Promise.all([
        fetchReturnsDataV1({tickers:ticks}),
        fetchCategories({tickers:ticks}),
        fetchChartsV1({ tickers: ticks }),
      ]);

      let holdings = charts.topten,
          assetTypes = charts.asset_type,
          themeScore = charts.themes_new,
          diverseData = charts.diversification,
          riskAdjReturn = charts.risk_adjusted;

      let returnsData = arrayToHash(returns, CN['Ticker']);
      let chartsData = arrayToHash(charts, CN['Ticker']);

      funds.forEach((e, index) => {
        funds[index].region = chartsData[cn(e, 'Ticker')].result.regions ? chartsData[cn(e, 'Ticker')].result.regions.length ? chartsData[cn(e, 'Ticker')].result.regions[0].region : [] : [];
        funds[index].returns = returnsData[e.ticker].returns;
        funds[index].returns = reverse(funds[index].returns);
        let r = getNest(['returns', 0], e);
        if (r){
          e._start = r['d'];
          e._end = e.returns[e.returns.length-1]['d'];
        }
        e.returns.forEach((item,index) => {
          e.returns[index].v = item.v/100;
        });
        // e.brokers = getNest([cn(e, 'Ticker'), 'brokers'], commData);
        e.brokers = BrokersComms;
        e.broker = getNest([0], e.brokers);

        //regions data manipulation -- adding others
        if(e.region.length> 0){
          e.region.forEach((reg, i) => {
            let sum = 0;
            if(reg.n == 'Others'){
              reg.sub.forEach(child => {
                sum += child.v;
              })
              e.region[i].sub = [{ n: 'Others ', v: sum}];
            }
          });
        }

        //calculate assetType chart data
        e.assetTypeData = chartsData[cn(e, 'Ticker')].result.asset_type[0] || []

        //calculate theme score chart data
        e.themeScore = chartsData[cn(e, 'Ticker')].result.themes_new[cn(e, 'Ticker')];

        //calculate risk adjusted return chart data
        e.riskAdjReturn = chartsData[cn(e, 'Ticker')].result.risk_adjusted[cn(e, 'Ticker')];

        e.diverseData = chartsData[cn(e, 'Ticker')].result.diversification[0];

        e.diverseData = chartsData[cn(e, 'Ticker')].result.diversification[0];
        // console.log(e.diverseData)
        //calculate holding chart data
        let _sum = 0.0;
        let holdingData = chartsData[cn(e, 'Ticker')].result.topten.funds.length ? chartsData[cn(e, 'Ticker')].result.topten.funds[0].holdings : []
        e.holdingData = holdingData
          .map((el, i) => {
            _sum += el.weight;
            return {
              name: el.name,
              value: el.weight,
              color: SectorColors[i % SectorColors.length]
            }
          });
        if (e.holdingData.length > 0 && _sum < 100){
          let rem = 100 - _sum.toFixed(1);
          rem = parseFloat(rem.toFixed(1));
          e.holdingData.push({ name: 'Others', value: rem, color: '#ddd' });
        }

      });

      categories["categories"].forEach(e => {
        let r = getNest(['returns', 0], e);
        if (r){
          e._start = r['d'];
          e._end = e.returns[e.returns.length-1]['d'];
        }
        e.returns.forEach((item,index) => {
          e.returns[index].v = item.v/100;
        });
      });

      let filterAttrData = FilterAttrData.reduce((acc, v) => ({...acc, [v.name]: uniqValues(funds, v.col)}), {});
      filterAttrData = Object.keys(filterAttrData).reduce((acc,item) => {
        acc[item] = filterAttrData[item].filter(x => (x != 0 || x != ''))
        return acc;
      }, {});

      return { funds, categories: categories["categories"], filterAttrData };

    } catch (e) {
      console.log(e);
    }
  }

  async getFundsData(card, data) {
    try {
      // const { funds } = data;
      console.log(card);
      const _funds = await this.structuredFund(data);
      const { categories, filteredAttrData, range } = _funds;
      let { funds } = _funds;
      // const { sfm, sfmDateRange } = this.props;
      const { sfm, realTimePrice } = this.props;
      const { addFundToSFM, setSFMCatData, setEnhancerState } = this.props;

      const { items } = sfm;
      if (funds && funds.length && funds.length > 0) {
        if (realTimePrice && realTimePrice.funds) {
          const updatedData = getFundsUpdatedPrice(realTimePrice, funds);
          if (updatedData) {
            funds = updatedData.funds;
          }
        } else {
          console.log('-- failed to load realTimePrice for Funds');
        }
        funds.forEach((element) => {
          if (!element.series) {
            const valTraslate = v => Math.log10((v+100)/100);
            let stats = calcStats(element.returns);
            let dd = element.returns.map(e => e.d);
            element.series = dd.map(d => {
              let ival = getNest([d, 'cuml'], stats.series)
              return { x: d, y1: ival, y3: ival }
            });
          }
          if (!element._stats) {
            // pass date-range param to get filter item list, it will update this.props.item
            const sfmDateRange = { // or else check this.props.sfmDateRange and use ?
              start: element._start,
              end: element._end,
            };
            if (funds.length && sfmDateRange && categories) {
              getFilteredListByRange({ items: funds, range: sfmDateRange, catData: categories });
            }
          }
          element.ticker_short = element.ticker;
          element._selected = true;
          card._selected = true;
          element._selector = true;
          card._selector = true;
        });

        const arr = funds.concat(items);
        const dataArr = uniqBy(arr, 'ticker');
        const NEWSFMCATDATA = this.props.sfmCatData || [];
        if (typeof categories !== undefined && setSFMCatData) {
          const newArr = uniqBy(NEWSFMCATDATA.concat(categories),'name');
          setSFMCatData(newArr);
        } else {
          throw 'categories data is undefined or setSFMCatData is missing'
        }

        if (addFundToSFM) {
          addFundToSFM({
            items: dataArr,
            modal: true,
            open: true,
          });
        } else {
          throw '-- addFundToSFM is missing --'
        }

        const { summary } = this.props;
        if (summary && summary.portfolioFunds) {
          const { portfolioFunds } = summary;
          const cardNewArray = [];
          const foundT = portfolioFunds.find(e => cn(e, 'Ticker') === card.ticker);

          // console.log(foundT, card);

          if (foundT) {
            foundT._selected = true;
            foundT._selector = true;
            cardNewArray.push(foundT);
            // console.log(cardNewArray);
            const portfolioFundsUpdatedArray = uniqBy(portfolioFunds.concat(cardNewArray),'ticker');
            // console.log(portfolioFundsUpdatedArray);
            console.log('update summary portfolioFunds here');

            if (setEnhancerState) {
              setEnhancerState({
                summary: {
                  ...summary,
                  portfolioFunds: portfolioFundsUpdatedArray,
                }
              });
            } else {
              throw '-- setEnhancerState is missing --'
            }
          }
        } else {
          throw '-- summary portfolioFunds missing for SFM operation'
        }

      } else {
        throw '-- NO FUNDS found for SFM operation'
      }
      // const { setSFMCatData, sfmCatData } = this.props;
      // if (typeof categories !== undefined) {
      //   console.log(categories);
      //   setSFMCatData(categories);
      // }
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { title, items, loadMoreAction, expand = false, summary } = this.props;

    const more = items.length > 3;
    const _items = items.slice(0, expand ? 30 : 3);

    return (
      <React.Fragment>
        {false && (
          <MoreButton loadMoreAction={loadMoreAction} layout="holdings" more={more} expand={expand} />
        )}
        <h5 className="mb-1 font-one p-3 OpenSummaryPage-card-title">{title}</h5>
        { expand ? (
          <HoldingsItemListWithScrollBar
            {...this.props}
            deleteHandler={this.deleteHandler}
            items={_items}
            actions={this.props.actions}
            itemClickHandler={this.itemClickHandler}
            getFundsData={this.getFundsData}
            updateActiveFund={this.updateActiveFund}
          />
        ) : (
          <HoldingsItemList
            {...this.props}
            deleteHandler={this.deleteHandler}
            items={_items}
            buyHandler={this.buyHandler}
            itemClickHandler={this.itemClickHandler}
            getFundsData={this.getFundsData}
            updateActiveFund={this.updateActiveFund}
          />
        )}
      </React.Fragment>
    )
  }
}

export const Holdings = connect(
  state => ({
    sfm: state.sfm,
    sfmCatData: state.sfm.sfmCatData,
    sfmSelectedItemTickers: state.sfm.selectedItemTickers,
    sfmDateRange: state.funds.dateRange,
    summary: state.enhancer.summary || {},
    realTimePrice: state.enhancer.summary.realTimePrice || {},
  }),
  {
    addFundToSFM,
    setSFMCatData,
    refreshSFMState,
    setEnhancerState,
    // SFM V-2
    addSFMItemTickersRequest,
    removeSFMItemTickersRequest,
  }
)(Holdings_);

const HoldingsItemList = (props) => {
  const count = props.items.length;
  if (count <= 0) return <NoData height='225px' />;
  // const buyHandler = (item) => {
  //   props.buyHandler(item);
  // }
  const itemClickHandler = (item) => {
    props.itemClickHandler(item);
  }

  const activeF = getActiveFunds(props.items, props.activeFund);

  if (activeF) {
    console.log(activeF);
    props.updateActiveFund(activeF);
  }

  return (
    <div style={{minHeight: '225px'}}>
      { props.items.map((e, i) => <HoldingsItem
                                    {...props}
                                    key={e.symbol+'-'+i}
                                    actions={props.actions}
                                    itemClickHandler={props.itemClickHandler}
                                    item={e}
                                    last={i === count-1}
                                    getFundsData={props.getFundsData}
                                    deleteHandler={props.deleteHandler}
                                  /> ) }
    </div>
  )
}

const HoldingsItemListWithScrollBar = withScrollBar(HoldingsItemList)

const getActiveFunds = (items, key) => {
  if (!items && !items.length && key) return;
  const index = items.findIndex((x) => x.symbol === key);
  let symbol = key;
  if (index < 0) {
    symbol = items[0].symbol;
  }
  return symbol;
}

const HoldingsItem = React.memo(({ item, last, ...props }) => {
  const {
    sfm,
    items: portfolioItems,
    addFundToSFM,
    refreshSFMState,
    setEnhancerState,
    summary,
    view,
    sfmSelectedItemTickers,
    addSFMItemTickersRequest,
    removeSFMItemTickersRequest,
  } = props;
  const { items: sfmItems } = sfm;

  // SFM V-2 Logic
  const buyHandler = (thisItem) => {
    try {
      if (summary && summary.portfolioFunds) {
        const { portfolioFunds } = summary;
        const ticker = thisItem.ticker || thisItem.script || thisItem.symbol;
        // props.buyHandler(item);

        //NOTE: update 'fund._selected' in HOLDING LIST
        portfolioFunds.map((e) => {
          if (cn(e, 'Ticker') === ticker) {
            if (e._selected) {
              e._selected = false;
              e._selector = false;
            } else {
              e._selected = true;
              e._selector = true;
            }
          }
        });
        if (setEnhancerState) {
          setEnhancerState({
            summary: {
              ...summary,
              portfolioFunds: portfolioFunds,
            },
          });
        }

        // NOTE: Add fund to SFM
        //V-2 logic
        console.log(`========>>>> ADDING TICKER ${ticker} IN SFM <<<<========`);
        const alreadyPresent = sfmSelectedItemTickers.find((l) => l === ticker)
        if (alreadyPresent) {
          // un_select logic
          if (removeSFMItemTickersRequest) {
            removeSFMItemTickersRequest({
              removeTicker: ticker,
            })
          }
        } else {
          // select logic
          if (addSFMItemTickersRequest) {
            addSFMItemTickersRequest({
              selectedItemTickers: ticker,
            })
          }
        }

      } else {
        throw '-- retData is missing --'
      }
    } catch (e) {
      console.log(e);
    }
  }

  const deleteHandler = (item) => {
    props.deleteHandler(item);
  }

	const cost = item.market_value || item.price;

  return (
    <div
			className={classNames({"py-2p5 border-bottom OpenSummaryPage-holdings-item": !last, "pt-2p5": last})}
      onClick={props.itemClickHandler(item.ticker)}
		>
			<Row style={{ margin: 0 }}>
				<Col xs="2" className="d-flex flex-column justify-content-center align-items-center">
					<h5 className="mb-1 fs-m1 fw-b tx-c1 OpenSummaryPage-card-sub-title">{item.ticker}</h5>
					<h3 className="mb-0 fs-m1 fw-b tx-c2 OpenSummaryPage-card-sub-title">{FormatCurrency(cost, 2)}</h3>
				</Col>
				<Col xs="8" sm="8" className="d-flex align-items-center">
					<TruncatedNameSponsor name={item.short_name} sponsor={item.sponsor} lines={1} />
				</Col>
				<Col xs="2" sm="2" className="d-flex flex-column justify-content-center">
          <div className="delete-icon" style={{ textAlign: 'right' }} onClick={() => deleteHandler(item)}>
            <i class="far fa-fw fa-trash-alt"></i>
          </div>
				</Col>
			</Row>
    </div>
  )
})

export const FundActions = () => {
  return (
    <React.Fragment>
      <Button color={"select"} outline
        className="h6 mb-0 py-1 badge-pill buy-btn">Buy</Button>
      <Button color={"select"} outline
        className="h6 mb-0 mt-2 py-1 badge-pill sell-btn">Sell</Button>
    </React.Fragment>
  )
}

import React, { Component } from 'react';
import { Button, Row, Col, Tooltip, UncontrolledTooltip } from 'reactstrap';
import NamePopover from './NamePopover';
import ResponsiveCardPopover from './ResponsiveCardPopover';
import { cn } from 'DataSet';
import { configConsumerProps } from 'antd/lib/config-provider';
import config from 'env'
import {Dropdown, Menu, Icon} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

class CardName extends Component {
  constructor (props) {
    super(props);
    this.state = {
      tooltipOpen: false,
      selector: false,
      builder: false,
      selectorImg: '/assets/images/icons/shopping-cart-Orange.svg',
      builderImg: '/assets/images/icons/BuilderIcon-Orange.svg',
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    if(e === 'builder'){
      this.props.selectBuilderHandler(this.props.card, false);
    }else{
      this.props.card._showSponsoredOnSFM = false;
      // let sponsorFund = this.props.funds.data.filter(e => this.props.card.ticker != e.ticker && e.is_sponsored == true && typeof(this.props.sfm.items.find(el=> el.ticker === e.ticker)) === "undefined" );
      let selectorState = true;
      if(this.props.sfm.items.length > 0){
        this.props.sfm.items.forEach(item => {
          if(item._selector){
            selectorState = false;
          }
        })
      }
      // let sponsorFund = typeof(this.props.sfm.items.find(el=> el.ticker === this.props.card.ticker)) === "undefined" || selectorState ? (
      //   this.props.funds.data.filter(e => this.props.card.ticker != e.ticker && e.is_sponsored == true && typeof(this.props.sfm.items.find(el=> el.ticker === e.ticker)) === "undefined")
      // ) : [];
      // console.log('here data', sponsorFund);
      // if(sponsorFund.length){
      //   sponsorFund.forEach((e,i) => {sponsorFund[i]._showSponsoredOnSFM = true; console.log(sponsorFund[i]); this.props.selectHandler(sponsorFund[i])});
      // }

      this.props.selectHandler(this.props.card);

      if(this.props.funds && this.props.funds.data && this.props.funds.data.length > 0 && this.props.card){
        let sponsorFund = this.props.funds.data.filter(e => e.is_sponsored === true);
        sponsorFund.forEach(fund => {
          if(!fund._selector && this.props.card._selector) {
            this.props.selectHandler(fund);
          }
        });
      }
    }
    this.forceUpdate();
  }

  onBuilderClearClick = (e) =>{
    this.props.selectBuilderHandler(this.props.card, true);
  }

  handleMouseOver = (e, type) => {
    if(type === 'selector'){
      this.setState({
        selector: true,
        selectorImg: '/assets/images/icons/shopping-cart-White.svg'
      });
    }else{
      this.setState({
        builder: true,
        builderImg: '/assets/images/icons/BuilderIcon-White.svg',
      });
    }
  }

  handleMouseOut = (e, type) => {
    if(type === 'selector'){
      this.setState({
        selector: false,
        selectorImg: '/assets/images/icons/shopping-cart-Orange.svg'
      });
    }else{
      this.setState({
        builder: false,
        builderImg: '/assets/images/icons/BuilderIcon-Orange.svg'
      });
    }
  }

  render() {
    const { card, index, checkPopOpen, firstPop, firstPopIndex, chartData, highlightText, builderSelected } = this.props;
    // console.log('here card', card);
    // const menu = (
    //   <Menu onClick={this.onClick} className='select-option'>
    //     <Menu.Item key="cart">
    //       + Selector
    //     </Menu.Item>
    //     <Menu.Item key="builder">
    //       + Builder
    //     </Menu.Item>
    //   </Menu>
    // );
    return (
      <div className="h-100 card-background px-2 py-1 position-relative app-card-left">
        {(card.vehicle === 'MF' && typeof card.class_type !== 'undefined' && card.class_type !== '')
          && (
            <div id={`fundclass-${card.ticker}`}>
              <img className="card-Ad-Icon" src={`/assets/images/${card.class_type}.png`} width='10' />
              {card._cstats &&
                <Tooltip className="col-grey" placement="right" isOpen={this.state.tooltipOpen} target={`fundclass-${card.ticker}`} toggle={() => this.setState({ tooltipOpen: !this.state.tooltipOpen })}>
                  {card.class_type === 'retail' ? 'Retail Class' : 'Institutional Class'}
                </Tooltip>
              }
            </div>
          )
        }
        {card.is_sponsored && config.sponsoredTicker && <div className="ad-logo">SPONSORED</div>}
        <Row className="no-gutters align-items-center h-100 media-flex-nowrap">
          <Col className="col-lg-3 col-md-auto d-lg-block d-sm-none select-btn-box">
            <Row className='options-btn-body d-flex'>
              <div className='selector-btn'>
                <Button
                  disabled={(typeof card._cstats === 'undefined')}
                  onClick={() => {this.onClick('cart'); this.setState({selector: false});}}
                  onMouseOver={e => this.handleMouseOver(e, 'selector')}
                  onMouseOut={e => this.handleMouseOut(e, 'selector')}
                  id={`selector-btn-${card.ticker}`} outline={!card._selector} color={card._selector?'unselect':'select'}
                  className={`unselected ${card._selector ? 'selected' : null}`}
                >
                  {!card._selector ?
                    <img
                      src={this.state.selectorImg}
                      alt=''
                    />:
                    <img
                      src='/assets/images/icons/shopping-cart-White.svg'
                      alt='dis'
                    />
                  }
                </Button>
                {this.state.selector && <div className={`btn-info-txt ${card._selector && 'clear'}`}>{card._selector ? 'CLEAR':'+ SELECTOR'}</div>}
              </div>
              <div className='builder-btn'>
                <Button
                  onClick={(e) => {card._builder ? this.onBuilderClearClick(e):this.onClick('builder'); this.setState({builder: false});}}
                  onMouseOver={e => this.handleMouseOver(e, 'builder')}
                  onMouseOut={e => this.handleMouseOut(e, 'builder')}
                  id={`builder-btn-${card.ticker}`} color={card._builder?'unselect':'select'}
                  className={`unselected ${card._builder ? 'selected' : null}`}
                >
                  {!card._builder ?
                    <img
                      src={this.state.builderImg}
                      alt=''
                    />:
                    <img
                      src='/assets/images/icons/BuilderIcon-White.svg'
                      alt=''
                    />
                  }
                </Button>
                {this.state.builder && <div className={`btn-info-txt builder ${card._builder && 'clear builder-clear'}`}>{card._builder?'CLEAR':'+ BUILDER'}</div>}
              </div>
            </Row>
          </Col>
          <Col className="col-1 p-0 d-md-none media-show">
            <label className="orange-checkbox ml-2 mr-5">
              <input type="checkbox"
                onChange = { this.onClick }
                checked = { card._selected }
                />
              <span className="checkmark"></span>
            </label>
            {card.vehicle && card.vehicle != 'MP'
              && (
                <div className="vehicle-label media-vehicle-label">{card.vehicle}</div>
              )
            }
            {card.vehicle && card.vehicle == 'MP'
              && (
                <div className="vehicle-label" style={{ maxWidth: 60 }}>Model Portfolio</div>
              )
            }
          </Col>
          <Col className="col-auto col-md-3 text-center fund-ticker-box">
            <h5 className="mb-0 text-blue text-gray-5 ticker-size ticker-price">
              {cn(card, 'Ticker Short')}
            </h5>
            <h3 className="mt-1 mb-0 text-info" style={{minWidth: '5rem'}}>
              <span className="ticker-price text-gray-5">$</span>
              <span className="ticker-price">{cn(card, 'Nav Price')?cn(card, 'Nav Price').toFixed(1):''}</span>
            </h3>
            {card.vehicle && card.vehicle != 'MP'
              && (
                <div className="vehicle-label mt-1 mb-0 text-gray-6 media-vehicle-label">{card.vehicle}</div>
              )
            }
            {card.vehicle && card.vehicle == 'MP'
              && (
                <div className="vehicle-label mt-1 mb-0 text-gray-6" style={{ maxWidth: 60 }}>Model Portfolio</div>
              )
            }
          </Col>
          <Col className="media-mycard-desc col-md-6 fund-name-box">
            <NamePopover {...this.props} card={card} toggleDetailsModal = {this.props.toggleDetailsModal} index={index} checkPopOpen={checkPopOpen} firstPop={firstPop} firstPopIndex={firstPopIndex} highlightText={highlightText}/>
          </Col>
          {/*
            <Col md="12" className="d-md-none d-sm-block">
              <div className="d-flex align-items-center mt-2 ml-5">
                <i className="mr-3 h2 mb-0">
                  <span className="icon-Slader-Home-Active">
                    <span className="path1"></span><span className="path2"></span>
                  </span>
                </i>
                {card._cstats &&
                  <ResponsiveCardPopover card={card} chartData={chartData} highlightText={highlightText} checkPopOpen={checkPopOpen} firstPop={firstPop} />
                }
              </div>
            </Col>
          */}
        </Row>
      </div>
    )
  }
}

export default CardName;

import React from 'react'
import { Row, Col, Menu, Input } from 'antd'
// import { DownOutlined } from '@ant-design/icons'
import UploadSection from './UploadSection'
import { deflatLastNode } from 'Utils'
import style from './OnBoardScreen.module.scss'
const { SubMenu } = Menu
const { Search } = Input
export const portfolioMenus = ({
  props,
  selectedPortfolio,
  demoPortfolio,
  uploadPortfolio,
  watchlistPortfolio,
  deletePortfolio
}) => {
  const { portfolioList } = props
  return (
    <Menu className="portfolio-menu-wrapper">
      <Menu.Item key="Watchlist">
        <div
          className="upload_btn active-label"
          onClick={() => watchlistPortfolio()}
        >
          Your Watchlist
        </div>
      </Menu.Item>
      <Menu.Divider className={style.divider} />
      <Menu.Item key="Demo Model">
        <UploadSection
          mode="upload"
          className="upload_btn active-label"
          fileFor="Demo_portfolio"
          onFileSuccess={() => demoPortfolio()}
        >
          Use Demo Portfolio
        </UploadSection>
      </Menu.Item>
      <Menu.Item key="Upload Portfolio">
        <UploadSection
          mode="upload"
          className="upload_btn active-label"
          fileFor="Custom_portfolio"
          onFileSuccess={item => uploadPortfolio(item)}
        >
          Upload Portfolio
        </UploadSection>
      </Menu.Item>
      <Menu.Divider className={style.divider} />
      {portfolioList &&
        portfolioList.length &&
        portfolioList.map((item, index) => {
          return (
            <Menu.Item key={item.portfolio_name + index}>
              <div
                className="portfolio_name-label dropdown-style"
                onClick={() => {
                  selectedPortfolio(item)
                }}
              >
                {item.portfolio_name}
              </div>
              <div
                className="delete-icon"
                onClick={() => deletePortfolio(item.portfolio_name)}
              >
                <i class="far fa-fw fa-trash-alt"></i>
              </div>
            </Menu.Item>
          )
        })}
    </Menu>
  )
}

export const sponsoredMenus = ({ props, openChange, setMarketPlace }) => {
  let { obj: newMarketPlaceList } = deflatLastNode(props.marketPlaceList)
  // const { portfolioList } = props

  return (
    <Menu onOpenChange={openChange}>
      {newMarketPlaceList && typeof newMarketPlaceList == 'string' && <div />}
      {newMarketPlaceList &&
        typeof newMarketPlaceList == 'object' &&
        Object.keys(newMarketPlaceList).length > 0 &&
        Object.keys(newMarketPlaceList).map((item, index) => {
          return (
            <SubMenu
              key={item}
              title={item}
              className="dropdown-style"
              popupClassName="market-popup-head build-portfolio-onboard-screen-dropdown dropdown-style"
            >
              {Object.keys(newMarketPlaceList[item]).length > 0 &&
                Object.keys(newMarketPlaceList[item]).map((subItem, findex) => {
                  if (subItem == 'uncatagorized') {
                    return (
                      <div
                        key={`market-popup-wrapper_${findex}`}
                        className="market-popup-wrapper"
                      >
                        {newMarketPlaceList[item] &&
                          newMarketPlaceList[item][subItem] &&
                          newMarketPlaceList[item][subItem].length > 0 &&
                          newMarketPlaceList[item][subItem].map(
                            (subItem1, index) => {
                              return (
                                <div
                                  key={`market_name-label_${index}`}
                                  className="market_name-label dropdown-style"
                                  onClick={() => setMarketPlace(subItem1)}
                                >
                                  {subItem1.name}
                                </div>
                              )
                            }
                          )}
                      </div>
                    )
                  } else {
                    return (
                      <SubMenu
                        key={subItem}
                        title={subItem}
                        className="dropdown-style"
                        popupClassName="market-popup dropdown-style"
                      >
                        <div className="market-popup-wrapper">
                          {newMarketPlaceList[item] &&
                            newMarketPlaceList[item][subItem] &&
                            newMarketPlaceList[item][subItem].length > 0 &&
                            newMarketPlaceList[item][subItem].map(
                              (subItem1, index) => {
                                return (
                                  <div
                                    key={`market_name-label_${index}`}
                                    className="market_name-label dropdown-style"
                                    onClick={() => setMarketPlace(subItem1)}
                                  >
                                    {subItem1.name}
                                  </div>
                                )
                              }
                            )}
                        </div>
                      </SubMenu>
                    )
                  }
                })}
            </SubMenu>
          )
        })}
      {newMarketPlaceList &&
        newMarketPlaceList.message &&
        newMarketPlaceList.message == 'No tickers Present.' && (
          <Menu.Item key="0">
            <div className="portfolio_name-label dropdown-style">No data</div>
          </Menu.Item>
        )}
    </Menu>
  )
}

export class CustomSponsoredDropDown extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    input: ''
  }

  componentWillMount() {
    let { obj: newMarketPlaceList, allItems: allList } = deflatLastNode(
      this.props.marketPlaceList,
      true
    )
    this.setState({
      newMarketPlaceList,
      allList
    })
  }

  handleInputChange = e => {
    this.setState({
      input: e
    })
  }

  setMarketPlace = prop => {
    if (this.props.setMarketPlace) {
      this.props.setMarketPlace(prop)
    }
  }

  render() {
    const { openChange } = this.props
    const { input, newMarketPlaceList, allList } = this.state
    let filterdList =
      input.length !== 0 &&
      allList.filter(item =>
        item.name.toLowerCase().includes(input.toLowerCase())
      )
    // <Menu.Item className={style.onSearchMenuContainer}>
    // 	<div className={style.onSearchMenu} onClick={() => this.setMarketPlace(item)} >
    // 		<i className={`far fa-fw fa-search ${style.resultSearchIcon}`}/>
    // 		<div className={style.resultDetail}>
    // 			<p title={item.name}>{item.name}</p>
    // 			<p title={item.sponsor}>{item.sponsor}</p>
    // 		</div>
    // 	</div>
    // </Menu.Item>

    // <UploadSection
    // 	fileFor="MarketPlace_Portfolio"
    // 	key={`market_name-label_${index}`}
    // 	className={style.onSearchMenuContainer}
    // 	portfolioID={item}
    // 	onFileSuccess={(res) => this.setMarketPlace(res)}
    // >
    // 	<i className={`far fa-fw fa-search ${style.resultSearchIcon}`}/>
    // 	<div className={style.resultDetail}>
    // 		<p title={item.name}>{item.name}</p>
    // 		<p title={item.sponsor}>{item.sponsor}</p>
    // 	</div>
    // </UploadSection>

    return (
      <Menu onOpenChange={openChange} className={style.portfolioMenuWrapper}>
        <Menu.Item key={'search'} className={style.inputContainer}>
          <Search
            id={'sponsorSearch'}
            autoFocus
            onChange={e => {
              e.preventDefault()
              this.handleInputChange(e.target.value)
            }}
            className={style.input}
            placeholder="Search for better results"
            allowClear
            onSearch={this.handleInputChange}
          />
        </Menu.Item>
        {input.length !== 0 &&
          filterdList.map((item, index) => (
            <Menu.Item className={style.onSearchMenuContainer}>
              <UploadSection
                mode="upload"
                fileFor="MarketPlace_Portfolio"
                key={`market_name-label_${index}`}
                className={style.onSearchMenu}
                childrenWrapClassName={style.resultDetail}
                portfolioID={item}
                prefixTag={
                  <i
                    className={`far fa-fw fa-search ${style.resultSearchIcon}`}
                  />
                }
                onFileSuccess={res => this.setMarketPlace(res)}
              >
                <p title={item.name}>{item.name}</p>
                <p title={item.sponsor}>{item.sponsor}</p>
              </UploadSection>
            </Menu.Item>
          ))}
        {input.length === 0 &&
          newMarketPlaceList &&
          typeof newMarketPlaceList == 'object' &&
          Object.keys(newMarketPlaceList).length > 0 &&
          Object.keys(newMarketPlaceList).map((item, index) => {
            return (
              <SubMenu
                key={item}
                title={item}
                className={style.subMenu}
                popupClassName={style.popupClass}
              >
                {Object.keys(newMarketPlaceList[item]).length > 0 &&
                  Object.keys(newMarketPlaceList[item]).map(
                    (subItem, findex) => {
                      if (subItem == 'uncatagorized') {
                        return (
                          newMarketPlaceList[item][subItem].length > 0 &&
                          newMarketPlaceList[item][subItem].map(
                            (subItem1, index) => {
                              return (
                                // <div
                                // 	key={`market_name-label_${index}`}
                                // 	className={style.itemLable}
                                // 	onClick={() =>
                                // 		setMarketPlace(subItem1)
                                // 	}
                                // >
                                // 	{subItem1.name}
                                // </div>
                                <UploadSection
                                  mode="upload"
                                  fileFor="MarketPlace_Portfolio"
                                  key={`market_name-label_${index}`}
                                  className={style.itemLable}
                                  portfolioID={subItem1}
                                  onFileSuccess={item =>
                                    this.setMarketPlace(item)
                                  }
                                >
                                  {subItem1.name}
                                </UploadSection>
                              )
                            }
                          )
                        )
                      } else {
                        return (
                          <SubMenu
                            key={subItem}
                            title={subItem}
                            className={style.subMenu}
                            popupClassName={style.popupClass}
                          >
                            {newMarketPlaceList[item][subItem].length > 0 &&
                              newMarketPlaceList[item][subItem].map(
                                (subItem1, index) => {
                                  return (
                                    // <div
                                    // 	key={`market_name-label_${index}`}
                                    // 	className={style.itemLable}
                                    // 	onClick={() =>
                                    // 		setMarketPlace(subItem1)
                                    // 	}
                                    // >
                                    // 	{subItem1.name}
                                    // </div>
                                    <UploadSection
                                      mode="upload"
                                      fileFor="MarketPlace_Portfolio"
                                      key={`market_name-label_${index}`}
                                      className={style.itemLable}
                                      portfolioID={subItem1}
                                      onFileSuccess={item =>
                                        this.setMarketPlace(item)
                                      }
                                    >
                                      {subItem1.name}
                                    </UploadSection>
                                  )
                                }
                              )}
                          </SubMenu>
                        )
                      }
                    }
                  )}
              </SubMenu>
            )
          })}
        {input.length !== 0 && filterdList.length === 0 && (
          <Menu.Item
            className={`${style.noHighlight} ${style.noResult}`}
            key="0"
          >
            <div>No Result Found</div>
          </Menu.Item>
        )}
        {newMarketPlaceList &&
          newMarketPlaceList.message &&
          newMarketPlaceList.message == 'No tickers Present.' && (
            <Menu.Item key="0">
              <div className="portfolio_name-label dropdown-style">No data</div>
            </Menu.Item>
          )}
      </Menu>
    )
  }
}

export class PortfolioDDSearch extends React.Component {
  constructor(props) {
    super(props)
  }
  state = {
    input: '',
    portfolioList: []
  }

  componentDidMount() {
    this.listGenerator()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let { portfolioList } = nextProps
    //regenerate in case of deletion
    if (
      Math.abs(this.state.portfolioList.length - portfolioList.length) > 2 ||
      portfolioList.message
    ) {
      this.listGenerator()
    }
  }

  listGenerator = () => {
    let { portfolioList } = this.props
    portfolioList =
      portfolioList.length > 0
        ? [
            ...portfolioList,
            { portfolio_name: 'Your Watchlist' },
            { portfolio_name: 'Demo Portfolio' }
          ].sort((a, b) => {
            let nameA = a.portfolio_name.toLowerCase()
            let nameB = b.portfolio_name.toLowerCase()
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0
          })
        : [
            { portfolio_name: 'Demo Portfolio' },
            { portfolio_name: 'Your Watchlist' }
          ]
    this.setState({
      portfolioList
    })
  }

  handleInputChange = e => {
    // console.log(e)
    this.setState({
      input: e
    })
  }

  render() {
    const {
      selectedPortfolio,
      demoPortfolio,
      handleDDMenuClick,
      watchlistPortfolio,
      deletePortfolio,
      uploadPortfolio
    } = this.props
    const { input, portfolioList } = this.state
    let filterList =
      portfolioList &&
      portfolioList.length > 0 &&
      portfolioList.filter(item =>
        item.portfolio_name.toLowerCase().includes(input.toLowerCase())
      )
    return (
      <Menu className={style.portfolioMenuWrapper}>
        <Menu.Item key={'search'} className={style.inputContainer}>
          <Search
            id={'pfSearch'}
            autoFocus
            onChange={e => {
              e.preventDefault()
              this.handleInputChange(e.target.value)
            }}
            className={style.input}
            placeholder="Search for better results"
            allowClear
            onSearch={this.handleInputChange}
          />
        </Menu.Item>
        {filterList &&
          filterList.length &&
          filterList.map((item, index) => {
            return item.portfolio_name === 'Demo Portfolio' ? (
              <Menu.Item
                className={style.onSearchMenuContainerUP}
                key="Demo Model"
              >
                <UploadSection
                  mode="upload"
                  fileFor="Demo_portfolio"
                  onFileSuccess={() => demoPortfolio()}
                >
                  <div
                    onClick={() => {
                      handleDDMenuClick(false)
                    }}
                    className={`d-flex align-items-center justify-content-left ${style.onSearchMenu}`}
                  >
                    {input.length > 0 && (
                      <i
                        style={{ opacity: `0.4` }}
                        className={`far fa-fw fa-search ${style.resultSearchIcon}`}
                      />
                    )}
                    <p style={{ margin: '0' }}>Demo Portfolio</p>
                  </div>
                </UploadSection>
              </Menu.Item>
            ) : item.portfolio_name === 'Your Watchlist' ? (
              <Menu.Item
                className={style.onSearchMenuContainerUP}
                key="Watchlist"
              >
                <div
                  className={`d-flex align-items-center justify-content-left ${style.onSearchMenu}`}
                  onClick={() => watchlistPortfolio()}
                >
                  {input.length > 0 && (
                    <i
                      style={{ opacity: `0.4` }}
                      className={`far fa-fw fa-search ${style.resultSearchIcon}`}
                    />
                  )}
                  <p style={{ margin: '0' }}>Your Watchlist</p>
                </div>
              </Menu.Item>
            ) : (
              <Menu.Item
                className={style.myPfItems}
                key={item.portfolio_name + index}
              >
                <div
                  title={item.portfolio_name}
                  className={style.myPfItemsDetail}
                  onClick={() => {
                    selectedPortfolio(item)
                  }}
                >
                  {input.length > 0 && (
                    <i
                      className={`far fa-fw fa-search ${style.resultSearchIcon}`}
                    />
                  )}
                  <p>{item.portfolio_name}</p>
                </div>
                <i
                  onClick={() => deletePortfolio(item.portfolio_name)}
                  className={`far fa-fw fa-trash-alt ${style.deleteIcon}`}
                />
              </Menu.Item>
            )
          })}
        {input.length !== 0 && filterList.length === 0 && (
          <Menu.Item
            className={`${style.noHighlight} ${style.noResult}`}
            key="0"
          >
            <div>No Result Found</div>
          </Menu.Item>
        )}
      </Menu>
    )
  }
}

export class ClientDDSearch extends React.Component {
  state = {
    input: '',
    clientList: []
  }

  // componentDidMount() {
  //   let { clientList } = this.props
  //   // clientList = [...clientList, { name: 'Demo Account' }].sort((a, b) => {
  //   clientList = [...clientList].sort((a, b) => {
  //     let nameA = a.name.toLowerCase()
  //     let nameB = b.name.toLowerCase()
  //     if (nameA < nameB) {
  //       return -1
  //     }
  //     if (nameA > nameB) {
  //       return 1
  //     }
  //     return 0
  //   })
  //   this.setState({
  //     clientList
  //   })
  //   // window.scrollTo(0,document.getElementById('clientSearch').getBoundingClientRect().top)
  // }

  // componentWillReceiveProps(newProps) {
  //   if (this.props.clientList.length !== newProps.clientList.length) {
  //     let { clientList } = newProps
  //     // clientList = [...clientList, { name: 'Demo Account' }].sort((a, b) => {
  //     clientList = [...clientList].sort((a, b) => {
  //       let nameA = a.name.toLowerCase()
  //       let nameB = b.name.toLowerCase()
  //       if (nameA < nameB) {
  //         return -1
  //       }
  //       if (nameA > nameB) {
  //         return 1
  //       }
  //       return 0
  //     })
  //     this.setState({
  //       clientList
  //     })
  //   }
  // }

  handleInputChange = e => {
    // console.log(e)
    this.setState({
      input: e
    })
  }

  handleClick = item => {
    if (this.props.handleDDMenuClick) {
      this.props.handleDDMenuClick(item)
    }
  }

  deleteClientAccount = (id, name) => {
    this.props.deleteClient(id, name);
  }

  editClientAccountName = (id, name) => {
    this.props.editClientAccountName(id, name);
  }

  clientSelectedPortfolio = (client) => {
    const { clientSelectedPortfolio } = this.props;
    if (clientSelectedPortfolio) {
      clientSelectedPortfolio(client);
    }
  }

  render() {
    let { clientList } = this.props;
    const { input } = this.state;

    clientList = clientList.sort((a, b) => {
      let nameA = a.name.toLowerCase()
      let nameB = b.name.toLowerCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    });

    let filterList =
      clientList &&
      clientList.length > 0 &&
      clientList.filter(item =>
        item.name.toLowerCase().includes(input.toLowerCase())
      )
    return (
      <Menu className={style.portfolioMenuWrapper}>
        <Menu.Item
          key={'search'}
          className={`${style.noHighlight} ${style.inputContainer}`}
        >
          <Search
            id={'clientSearch'}
            autoFocus
            onChange={e => {
              e.preventDefault()
              this.handleInputChange(e.target.value)
            }}
            className={style.input}
            placeholder="Search for better results"
            allowClear
            onSearch={this.handleInputChange}
          />
        </Menu.Item>
        {input.length !== 0 && filterList.length === 0 && (
          <Menu.Item
            className={`${style.noHighlight} ${style.noResult}`}
            key="0"
          >
            <div>No Result Found</div>
          </Menu.Item>
        )}
        {filterList &&
          filterList.length &&
          filterList.map((item, index) => {
            return (
              <Menu.Item
                className={style.dropdownMenuItem}
                key={item.name + index}
              >
                <Row className={style.dropdownOptionRow}>
                  <Col
                    span={18}
                    title={item.name}
                    className={style.clientName}
                    onClick={() => {
                      this.clientSelectedPortfolio(item)
                    }}
                  >
                    {input.length > 0 && (
                      <i className={`far fa-fw fa-search ${style.resultSearchIcon}`} style={{ marginRight: 5 }} />
                    )}
                    <p title={item.name}>{item.name}</p>
                  </Col>
                  <Col span={6} className={style.iconWrapper}>
                    <i
                      onClick={() => this.editClientAccountName(item.id, item.name)}
                      className={`far fa-pencil ${style.editIcon}`}
                      title="Edit Name"
                    />
                    <i
                      onClick={() => this.deleteClientAccount(item.id, item.name)}
                      className={`far fa-trash-alt ${style.deleteIcon}`}
                      title="Delete"
                    />
                  </Col>
                </Row>
              </Menu.Item>
            )
          })}
      </Menu>
    )
  }
}

export default {
  sponsoredMenus,
  portfolioMenus,
  CustomSponsoredDropDown,
  PortfolioDDSearch,
  ClientDDSearch
}

import React, { Component } from 'react';
import {
    Form,
    FormGroup,
    Label,
    Input} from 'reactstrap';
import { CustomFormFeedback, PrimaryButton } from 'shared-components';

export default class ForgotPasswordPage extends Component {

    render() {
        const { formProps, verifyEmailErrMsg } = this.props;

        const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
        } = formProps

        return (

            <Form className="sign-in magnifi-auth-design-container" noValidate={true} onSubmit={handleSubmit}>
                <div className="header-area">
                    <div className="header-label">Forgot Password</div>
                    <div className="sub-header-label">We'll send you an email with instructions</div>
                </div>

                <div className="body-wrapper">
                    <FormGroup className="element-box pos-rel">
                        <Label htmlFor="email" className="label">
                            Email Address
                        </Label>
                        <Input
                            type="email"
                            name="email"
                            id="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                                errors.email && touched.email
                                    ? 'sign-in__input-field sign-in__input-email is-invalid input-area'
                                    : 'sign-in__input-field sign-in__input-email input-area'
                            }
                            />
                        <CustomFormFeedback formProps={formProps} fieldName="email" />
                        {verifyEmailErrMsg !== '' && (!errors.email || !touched.email) && <span style={{color:'#a5685f',position:'absolute',top:'55px'}} className="password-errormsg">Invalid Email</span>}
                    </FormGroup>

                    <FormGroup className="cta-wrapper">
                        <PrimaryButton
                            type="submit"
                            className="btn primary-btn"
                            block
                            disabled={isSubmitting}
                        >
                            Reset Password
                        </PrimaryButton>
                    </FormGroup>
                </div>
            </Form>
        )
    }
} 
import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Button } from 'antd';
import DataTable, { createTheme } from 'react-data-table-component';
import { mockData } from '../../PortfolioTable/mockData';
import { clientHoldingsRequest } from 'actions/clientportfolioV2';
import { FormatCurrency, quintileSize } from 'Utils';
import { scrollBarCheck, getColViewName } from '../../../Utils';
import GaugeChart from 'components/charts/GaugeChart';
import ResultCharts from './ResultCharts/index';
import { ShowSpinner } from '../../Shared/ShowSpinner';
import { NoDataComponent } from '../../Shared/NoDataComponent';
import { addEventToAnalytics } from 'Utils';

createTheme('blue-sm', {
  text: {
    primary: '#268bd2',
    secondary: '#2aa198',
  },
  background: {
    default: '#e1effa',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#FFFFFF',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
});

class SearchResults extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      chartColTitle: ''
    };
  }

  componentDidMount() {
    scrollBarCheck('.sr-wrapper');
    const { modelSearchResults: { query_var } } = this.props;
    this.setState({
      chartColTitle: query_var && query_var.length ? getColViewName(query_var[0].view_name, query_var) : 'Assets'
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modelSearchResults.corrected_query != this.props.modelSearchResults.corrected_query) {
      scrollBarCheck('.sr-wrapper');
      const { modelSearchResults: { query_var } } = this.props;
      this.setState({
        chartColTitle: query_var && query_var.length ? getColViewName(query_var[0].view_name, query_var) : 'Assets'
      })
    }
  }

  allocatePortolio = (row) => {
    // this.props.sendSelectedPortfolio(row.name, row.ticker, this.props.selectedClient)
  }

  selectThisData = (selectedData) => {
    // console.log(selectedData);
    const { modelSearchResults } = this.props;
    addEventToAnalytics('Client Allocate Search Select', 'Client Allocate Search Select', 'CLIENT_ALLOCATE_SEARCH_SELECT', { query_uuid: modelSearchResults.query_uuid, ticker: selectedData.ticker, category: 'advisory_PTC' }, false)
    this.props.sendSelectedPortfolio(selectedData.name, selectedData.ticker, this.props.selectedClient)
  }

  render() {

    const { modelSearchResults, sendSelectedPortfolio, noSearchResults, modelSearchQuery } = this.props;
    const SearchResultsData = modelSearchResults.funds || [];

    const SearchResultsColumn = [
      {
        name: 'Ticker',
        cell: row => <Ticker row={row} />,
        sortable: false,
        grow: 1,
        omit: true,
      },
      {
        name: '',
        cell: row => <Button type="primary" className="select-btn" onClick={() => this.selectThisData(row)}>SELECT</Button>,
        sortable: false,
        grow: 2,
      },
      {
        name: 'Name',
        cell: row => <Sponsor row={row} />,
        sortable: false,
        grow: 3
      },
      {
        name: <FeesHead />,
        cell: row => <FeeReturnRisk row={row} type={'fees'} />,
        sortable: false,
        allowOverflow: true,
        center: true
      },
      {
        name: <ReturnsHead />,
        cell: row => <FeeReturnRisk row={row} type={'returns'} />,
        sortable: false,
        allowOverflow: true,
        center: true
      },
      {
        name: <RiskHead />,
        cell: row => <FeeReturnRisk row={row} type={'risk'} />,
        sortable: false,
        allowOverflow: true,
        center: true
      },
      {
        name: this.state.chartColTitle,
        cell: row => <ResultCharts item={row} allResults={modelSearchResults} />,
        sortable: false,
        allowOverflow: true,
        center: true,
        grow: 2
      }

    ];

    const customStyles = {
      rows: {
        style: {
          minHeight: '35px', // override the row height
          '&:hover': {
            cursor: 'pointer'
          }
        }
      },
      headCells: {
        style: {
          minWidth: '70px !important',
        },
      },
      cells: {
        style: {
          minWidth: '70px !important',
        },
      }
    };

    return (
      <React.Fragment>
        <div className="tbl-content sr-wrapper">
          <DataTable
            noHeader
            columns={SearchResultsColumn}
            data={SearchResultsData}
            customStyles={customStyles}
            pagination={false}
            theme="blue-sm"
            fixedHeader={true}
            fixedHeaderScrollHeight={'100vh'}
            onRowClicked={(row) => this.allocatePortolio(row)}
            noDataComponent={<NoDataComponent message={<NoDataMessage query={modelSearchQuery} />} />}
            progressPending={!noSearchResults && SearchResultsData.length == 0}
            progressComponent={<ShowSpinner />}
            persistTableHead
          />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ clientportfolio: { modelSearchResults, noSearchResults, modelSearchQuery } }) => ({
  modelSearchResults,
  noSearchResults,
  modelSearchQuery
})

const mapDispatchToProps = {
  clientHoldingsRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResults);

// Temporary fix to enable click event inside cells,
// Hoping this won't be required in the future as
// the user might accidently click on a cell and
// the portfolio gets allocated, will go for a
// long term solution if this feature becomes permanent
const enableEvent = {
  'role': 'cell',
  'data-tag': '___react-data-table-allow-propagation___'
}

const Ticker = ({ row }) => {
  // const sponsor = row.sponsor == 'BlackRock Advisors LLC' ? 'BLKMP' : row.sponsor == 'WisdomTree'? 'WSDMP': row.sponsor == 'Commonwealth' ? 'CWMD' : 'INVMP';
  const sponsor = row.ticker || row.sponsor;  //not sure of logic
  return (
    <div className="ticker-wrapper">
      <span {...enableEvent} title={sponsor}>{sponsor}</span>
      <span {...enableEvent}>{FormatCurrency(row.nav, 0, '$', 'unf')}</span>
    </div>
  )
}

const Sponsor = ({ row }) => {
  return (
    <div className="sponsor-wrapper">
      <img {...enableEvent} src={`/assets/images/sponsors/${row.sponsor}.png`} height="20" className="img-fluid" alt={row.sponsor ? row.sponsor : 'sponsor'} style={{ height: '20px' }} />
      <span {...enableEvent} title={row.short_name}>{row.short_name}</span>
    </div>
  )
}

const FeesHead = () => {
  return (
    <div className="frr-head-wrapper">
      <i className="far fa-fw fassf fa-tags-1" />
      <span>Fees</span>
    </div>
  )
}

const ReturnsHead = () => {
  return (
    <div className="frr-head-wrapper">
      <i className="far fa-fw fassf fa-chart-line-1" />
      <span>Returns</span>
    </div>
  )
}

const RiskHead = () => {
  return (
    <div className="frr-head-wrapper">
      <img src="/assets/images/icons/grey-heart-rate-1.svg" width="15" alt="Risk" style={{ marginRight: '5px' }} />
      <span>Risk</span>
    </div>
  )
}

const FeeReturnRisk = ({ row, type }) => {
  let val;
  let reverse = true;
  let col;
  let label;

  if (type == 'fees') {
    val = row.Fee_Risk_Return && row.Fee_Risk_Return.fees ? row.Fee_Risk_Return.fees.toFixed(2) + '%' : '--';
    reverse = true;
    col = row.feesq
    label = row.Fee_Risk_Return.fees ? (quintileSize(col, reverse) === 2 ? 'Average' : (quintileSize(col, reverse) === 3 ? 'High' : 'Low')) : ''
  } else if (type == 'returns') {
    val = row.Fee_Risk_Return && row.Fee_Risk_Return['Annualized Average'] ? row.Fee_Risk_Return['Annualized Average'].toFixed(2) + '%' : '--';
    reverse = false;
    col = row.Fee_Risk_Return.returnq
    label = quintileSize(col, reverse) === 2
      ? 'Average'
      : quintileSize(col, reverse) === 3
        ? 'High'
        : 'Low'
  } else if (type == 'risk') {
    val = row.Fee_Risk_Return && row.Fee_Risk_Return['Annualized Volatility'] ? row.Fee_Risk_Return['Annualized Volatility'].toFixed(2) + '%' : '--';
    reverse = true;
    col = row.Fee_Risk_Return.riskq
    label = (quintileSize(col, reverse) === 2 ? 'Average' : (quintileSize(col, reverse) === 3 ? 'High' : 'Low'))
  }

  return (
    <div className="frr-wrapper">
      <span {...enableEvent}>{val ? val : 'NA'}</span>
      <span {...enableEvent}>{label}</span>
    </div>
  )
}

const GaugeChartWrap = ({ row }) => {
  let [_min, _max, _val] = [0, 100, 97.5]
  return (
    <div className="w-100 p-1 guage-chart-wrapper">
      <div className="d-flex flex-column align-items-center" >
        <GaugeChart minWidth={70} value={_val} min={_min} max={_max} />
        <Row className="no-gutters h6 mb-0 w-100 guage-chart-details">
          <Col className="col-4 pr-2 text-gray-6i text-right h7 fz" style={{ marginTop: '-2px' }}>{_min.toFixed(2)}%</Col>
          <Col className="col-4 text-center font-weight-bold mb-0 lh-100 fz" style={{ marginTop: '2px' }}>{_val == 0 ? _val : _val.toFixed(2)}%</Col>
          <Col className="col-4 pl-2 text-gray-6i text-left h7 fz" style={{ marginTop: '-2px' }}>{_max.toFixed(2)}%</Col>
        </Row>
      </div>
    </div>
  )
}

const NoDataMessage = ({ query }) => {
  return (
    <div>
      <h3>Please Try Another Search</h3>
    </div>
  )
}

import React from 'react'
import { connect } from 'react-redux'
import { createSubscriptionRequest } from 'actions/profile'
import {
  Alert,
  Col,
  Collapse,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Button,
  Input,
  CustomInput
} from 'reactstrap'
import { PrimaryButton } from 'shared-components'
import { Checkbox, Menu, Dropdown, Icon } from 'antd';
import config from '../../env';

class PremiumUserDetails extends React.Component {
  constructor(props) {
    super(props)
    this.props.createSubscriptionRequest();
    this.subscribeNow = this.subscribeNow.bind(this);
  }

  subscribeNow() {
    const sessionId = this.props.stripeSessionID;
    if(sessionId){
      this.stripe = window.Stripe(config.stripePublishableKey);
      this.stripe.redirectToCheckout({
        sessionId
      }).then((result) => {
        // console.log("results status",result)
      });
    }   
  }

  render() {
    return (
      <React.Fragment>
        <Row className="profile-element-wrapper">
          <Col xs={12} className="profile-description-heading">
            <h5>Plan Type</h5>
            {/*<a href="#"><b>Change Plan</b></a>*/}
            <a href="#" onClick={this.subscribeNow}><b>Unsubscribe</b></a>
          </Col>
          <Col xs={12} className="profile-plan-details">
            <h6>Premium Plan</h6>
            <Row>
              <Col xs={12}>
                <p>The current cost is $75/month for the premium service</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="profile-element-wrapper">
          <Col xs={12} className="profile-description-heading">
            <h5>Payment Method</h5>
            <a href="#"><b>Update Card Details</b></a>
          </Col>
          <Col xs={12} className="profile-card-details">
            <h6>Visa XXXX XXXX 8603</h6>
            <p>Powered by stripe</p>
          </Col>
        </Row>
        <Row className="profile-element-wrapper">
          <Col xs={12} className="profile-description-heading">
            <h5>Billing Settings</h5>
          </Col>
          <Col xs={12} className="profile-billing-address">
            <h5>Address</h5>
            <p>This address appears in your monthly invoice and should be the legal address of your home or business.</p>
          </Col>
          <Col xs={12} className="profile-billing-check">
            <h5>Same as stated in personal information</h5>
            <Checkbox class="custom-checkbox" checked={true}></Checkbox>
            {/* CHECKTHIS -> Use this checkbox if required or remove later*/}
            {/*<CustomInput type="checkbox" checked={true}/>*/}
          </Col>
          <Col xs={12} className="mt-10">
            <Row>
              <Col xs={8} className="profile-billing-check">
                <p>#10 House, Street no.22,<br/>Washington lane, City, State, Country<br/>Zip Code: 229867</p>
              </Col>
              <Col xs={4} className="profile-billing-check">
                <a href="#" className="float-right"><b>Change Address</b></a>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="profile-element-wrapper">
          <Col xs={12} className="profile-description-heading">
            <h5>Billing Alerts</h5>
          </Col>
          <Col xs={12} className="profile-billing-check mt-10">
            <p>Update me one day before via an email before deduction</p>
            <Checkbox class="custom-checkbox" checked={true}></Checkbox>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ profile }) => ({
  stripeSessionID: profile.stripeSessionID
})

const mapDispatchToProps = {
  createSubscriptionRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PremiumUserDetails);

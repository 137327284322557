import React, { Component } from 'react';
import { SimpleLineChart } from 'components/Charts';

class ReturnsChart extends Component {
  // constructor(props) {
  //   super(props);
  //   // this.itemsKey = props.itemsKey;
  // }

  render() {
    const {
      width,
      height,
      bigScreen,
      smallScreen,
      lineData,
      fid,
      line3,
      ydomain,
      itemsKey,
      getLabelValue,
      tickerStartDate,
    } = this.props;

    return (
      <SimpleLineChart
        fid={fid}
        line3={line3}
        width={width}
        height={height}
        ydomain={ydomain}
        lineData={lineData}
        itemsKey={itemsKey}
        bigScreen={bigScreen}
        smallScreen={smallScreen}
        getLabelValue={getLabelValue}
        tickerStartDate={tickerStartDate}
      />
    )
  }
}

export default ReturnsChart;

import React, { Component, Fragment } from 'react'
import './_index.scss'
import { logout } from 'actions/auth'
import { connect } from 'react-redux'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap'
import { fetchProfile } from 'actions/profile'
import { toggleJoinModal } from 'actions/global'
import * as AuthUtils from 'common/auth-utils'
import { Link, withRouter } from 'react-router-dom'
import { addEventToAnalytics } from 'Utils'
import { UserMenu } from 'components/user/UserMenu'
import JoinModal from './JoinPop/JoinPop'
class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      isDrpdownOpen: false,
      width: this.getWidth(),
      mobileUrl: false
    }
  }
  componentDidMount() {
    const { loggedIn, fetchProfile } = this.props
    if (loggedIn && fetchProfile) {
      fetchProfile()
    }
  }
  getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  handleToggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }
  onMouseEnter = () => {
    this.setState({ isDrpdownOpen: true })
  }

  onMouseLeave = () => {
    this.setState({ isDrpdownOpen: false })
  }

  changeRoute = () => { }

  onLogout = () => {
    this.props.logout()
  }

  handleToggleJoinModal = () => {
    if (process.env.REACT_APP_SUB_DOMAIN === 'advisor') {
      addEventToAnalytics(
        'Menu Join Advisor',
        'Menu Join Advisor',
        'MENU_JOIN_ADVISOR',
        {}
      )
      this.props.history.push('/join')
    } else {
      this.props.toggleJoinModal({ showJoinModal: !this.props.showJoinModal });
    }
  }

  redirectToJoinPage = () => {
    this.handleToggleJoinModal()
    addEventToAnalytics(
      'Menu Join Retail',
      'Menu Join Retail',
      'MENU_JOIN_RETAIL',
      {}
    )
    this.props.toggleJoinModal({ showJoinModal: false });
    this.props.history.push('/register', {
      plan: 'basic',
      planType: 'selector'
    })
  }

  redirectToAdvisorJoin = () => {
    addEventToAnalytics(
      'Menu Join Retail To Advisor',
      'Menu Join Retail To Advisor',
      'MENU_JOIN_RETAIL_TO_ADVISOR',
      {}
    )
    window.location.href = 'https://app.magnifi.com/join'
  }

  render() {
    const {
      changeRoute,
      onLogout,
      handleToggleJoinModal,
      redirectToJoinPage,
      redirectToAdvisorJoin
    } = this
    const { firstName = '', lastName = '' } = AuthUtils.getAuthToken()
    const userName = `${firstName} ${lastName}`
    const shortName = `${firstName && firstName[0]}${firstName && lastName[0]}`
    const portalLink =
      'https://portal.magnifi.us?at=' + window.localStorage.getItem('token')
    const { width } = this.state
    const { loggedIn, isLandingPage, loggingIn, location } = this.props
    return (
      <Fragment>
        <JoinModal
          open={this.props.showJoinModal}
          cancel={handleToggleJoinModal}
          handleFirstAction={redirectToJoinPage}
          handleSecondAction={redirectToAdvisorJoin}
        />
        <div
          className={`container-navbar ${isLandingPage && !this.state.isOpen
            ? 'landing-header-container'
            : ''
            } r32`}
        >
          {/* <div className='img-logo'>
                <img className='main-logo' src='pages/wp-content/uploads/2019/07/Magnifi_White.svg'/>
            </div> */}
          <div className="header-menu">
            <Navbar
              className={
                this.state.isOpen ? 'header-background' : 'header noToggle'
              }
              expand="lg"
            >
              <NavbarBrand href={process.env.REACT_APP_SUB_DOMAIN === 'advisor' ? "https://advisor.magnifi.com/" : "/"} className="header-brand">
                <div className="img-logo">
                  <img
                    className="main-logo"
                    src={
                      isLandingPage && !this.state.isOpen
                        ? '/assets/images/homepage/Magnifi_byTIFIN-darkgrey.svg'
                        : '/assets/images/homepage/Magnifi_byTIFIN-white.svg'
                    }
                  />
                </div>{' '}
              </NavbarBrand>
              <NavbarToggler onClick={this.handleToggle}>
                {/* Close mark */}
                <div
                  id="close-icon"
                  className={this.state.isOpen ? 'open' : ''}
                >
                  <span></span>
                </div>
                {/* close mark ends */}
              </NavbarToggler>
              {/* <NavbarToggler className ="header-toggle" onClick={this.handleToggle} /> */}
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto" navbar>
                  {/* <NavItem>
              <NavLink
                onClick={() => {
                  addEventToAnalytics('Menu Adviser','Menu Adviser','MENU_ADVISORS',{})
                }}
                className="header-link"
                href="pages/advisors/"
              >Advisors</NavLink>
            </NavItem> */}
                  {/* <NavItem>
                    <NavLink
                      onClick={() => {
                        addEventToAnalytics(
                          'Menu Asset Manager',
                          'Menu Asset Manager',
                          'MENU_ASSET_MANAGERS',
                          {}
                        )
                      }}
                      className={location && location.pathname && location.pathname === "/" ? "header-link active" : "header-link"}
                      href="/"
                    >
                      Discover
                    </NavLink>
                  </NavItem> */}
                  {/* <NavItem>
              <NavLink
                onClick={() => {
                  addEventToAnalytics('Menu Asset Manager','Menu Asset Manager','MENU_ASSET_MANAGERS',{})
                }}
                className="header-link"
                href="pages/asset-managers/"
              >Asset Managers</NavLink>
            </NavItem> */}
                  <NavItem style={{ position: 'relative' }} className="nav_with_drop">
                    <NavLink
                      onClick={() => {
                        addEventToAnalytics(
                          'Solutions',
                          'Solutions',
                          'SOLUTIONS',
                          {}
                        )
                      }}
                      className="header-link forDekstop"
                      href={'javascript:void(0)'}
                    >
                      Solutions<i class="fa fa-angle-down fa-dropdown"></i>
                    </NavLink>
                    <NavLink
                      onClick={() => {
                        this.setState({ mobileUrl: !this.state.mobileUrl }, () => {
                          let droplist = document.getElementById('drop_cont')
                          if (this.state.mobileUrl) {
                            droplist.style.display = 'block'
                          } else {
                            droplist.style.display = 'none'
                          }
                        })
                        addEventToAnalytics(
                          'Solutions',
                          'Solutions',
                          'SOLUTIONS',
                          {}
                        )
                      }}
                      className="header-link forMobile"
                      href={'javascript:void(0)'}
                    >
                      Solutions<i class="fa fa-angle-down fa-dropdown"></i>
                    </NavLink>
                    <div className="drop_cont" id="drop_cont">
                      <div className="managed_port_dropdown">
                        <ul>
                          <li><a onClick={() => {
                            addEventToAnalytics(
                              'For Advisors',
                              'For Advisors',
                              'FOR_ADVISORS',
                              {}
                            )
                          }} href="https://advisor.magnifi.com/">For Advisors</a></li>
                          <li><a onClick={() => {
                            addEventToAnalytics(
                              'For Asset Managers',
                              'For Asset Managers',
                              'FOR_ASSET_MANAGERS',
                              {}
                            )
                          }} href="https://advisor.magnifi.com/asset-managers/">For Asset Managers</a></li>
                          <li><a onClick={() => {
                            addEventToAnalytics(
                              'For Investors',
                              'For Investors',
                              'FOR_INVESTORS',
                              {}
                            )
                          }} href="https://magnifi.com/">For Investors</a></li>
                        </ul>
                      </div>
                    </div>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        addEventToAnalytics(
                          'Menu Themes',
                          'Menu Themes',
                          'MENU_THEMES',
                          {}
                        )
                      }}
                      className="header-link"
                      href={process.env.REACT_APP_SUB_DOMAIN === 'advisor' ? "https://advisor.magnifi.com/investment-themes/" : "pages/investment-themes/"}
                    >
                      Insights & Themes
                    </NavLink>
                  </NavItem>
                  {/* <UncontrolledDropdown nav inNavbar onMouseOver={this.onMouseEnter} onMouseLeave={this.onMouseLeave} isOpen={this.state.isDrpdownOpen}>
              <DropdownToggle
                onClick={() => addEventToAnalytics('Menu Insights','Menu Insights','MENU_INSIGHTS',{})}
                className="header-link" nav >
                Insights
                <i className="fa fa-angle-down fa-dropdown"></i>
              </DropdownToggle>
              <DropdownMenu className="main-dropdown" right>
                <DropdownItem
                  onClick={() => addEventToAnalytics('Menu Popular Themes','Menu Popular Themes','MENU_INSIGHTS_POPULAR_THEMES',{})} href="pages/themes/">
                  Popular Themes
                </DropdownItem>
                <DropdownItem
                  onClick={() => addEventToAnalytics('Menu Popular Stocks','Menu Popular Stocks','MENU_INSIGHTS_POPULAR_STOCKS',{})}
                  href="pages/stocks/">
                 Popular Stocks
                </DropdownItem>
                <DropdownItem
                  onClick={() => addEventToAnalytics('Menu Popular ETFs','Menu Popular ETFs','MENU_INSIGHTS_POPULAR_ETFS',{})}
                  href="pages/etfs/">
                 Popular ETFs
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
                  {/* <NavItem>
                    <NavLink
                      onClick={() => {
                        addEventToAnalytics(
                          'Menu Adviser',
                          'Menu Adviser',
                          'MENU_ADVISORS',
                          {}
                        )
                      }}
                      className="header-link"
                      href="pages/advisors/"
                    >
                      For Advisors
                    </NavLink>
                  </NavItem> */}
                  {/* <NavItem>
                <NavLink
                  onClick={() => addEventToAnalytics('Menu Integrations','Menu Integrations','MENU_INTEGRATIONS',{})}
                  className="header-link"
                  href="pages/widget/">Integrations</NavLink>
              </NavItem> */}
                  <NavItem>
                    <NavLink
                      onClick={() =>
                        addEventToAnalytics(
                          'Menu About',
                          'Menu About',
                          'MENU_ABOUT',
                          {}
                        )
                      }
                      className="header-link"
                      href={process.env.REACT_APP_SUB_DOMAIN === 'advisor' ? "https://advisor.magnifi.com/about/" : "pages/about/"}
                    >
                      About
                    </NavLink>
                  </NavItem>
                  {loggedIn ? (
                    <NavItem>
                      <UserMenu
                        className="user-menu-popover"
                        onLogout={this.props.logoutHandler}
                        isLoggedIn={loggedIn || loggingIn}
                        showSinginIcon={loggedIn}
                      />
                    </NavItem>
                  ) : (
                    <React.Fragment>
                      {/* <NavItem>
                <NavLink
                  onClick={() => {
                    addEventToAnalytics('Menu Join', 'Menu Join', 'MENU_JOIN', {})
                    this.props.history.push('/join')
                  }}
                  className="header-link">Join</NavLink>
              </NavItem> */}
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            addEventToAnalytics(
                              'Menu Sign In',
                              'Menu Sign In',
                              'MENU_SIGN_IN',
                              {}
                            )
                            this.props.history.push('/login')
                          }}
                          className="header-link"
                        >
                          Sign In
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            handleToggleJoinModal()
                            if (process.env.REACT_APP_SUB_DOMAIN === 'retail') {
                              addEventToAnalytics(
                                'Menu Join',
                                'Menu Join',
                                'MENU_JOIN',
                                {}
                              )
                            }
                            // this.props.history.push('/join')
                          }}
                          className="header-link custom-button-header"
                        >
                          <div className="custom-button">
                            Join a World of Investing
                          </div>
                        </NavLink>
                      </NavItem>
                    </React.Fragment>
                  )}
                </Nav>
              </Collapse>
            </Navbar>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = ({
  auth: { user_advisor_menu, client_exist, loggedIn, loggingIn },
  profile,
  global: { showJoinModal }
}) => ({
  user_advisor_menu,
  client_exist,
  premiumUser: profile.user && profile.user.premiumUser,
  loggedIn,
  loggingIn,
  showJoinModal
})

const mapDispatchToProps = {
  logoutHandler: logout,
  fetchProfile,
  toggleJoinModal
}

export const HeaderComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Header))

export default HeaderComponent

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom';
import style from './PopUps.module.scss'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, ButtonGroup, Form } from 'reactstrap';
import { addApexMoney, fetchApexBalance } from 'apis/funds'
import { setSFMState } from 'actions/sfm'
import { cn, sponsorLogo } from 'DataSet';
import { getImageOrFallback } from '../../../Utils'
import { fetchProfile } from 'actions/profile'
import { UncontrolledTooltip } from 'reactstrap';
import { Spinner } from 'shared-components';
class TradingExecutePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageValidPath: false,
      isLoading: false
    };
  }

  checkPath = (card) => {
    console.log('----------checkPath----------',card)
    if (cn(card, 'Sponsor')) {
      getImageOrFallback(
        sponsorLogo(cn(card, 'Sponsor'))
      ).then(result => {
        if (result) {
          return true
        } else {
          return false
        }
      });
    }
  }

  onConfirm = ()=>{
    // console.log('close')
    this.props.toggle(true)
  }
  continue = ()=>{
    this.props.toggle(true)
  }

  viewDetails = ()=>{
    this.setState({
      isLoading: true
    });
    if (this.props.location.pathname.startsWith('/accounts') === true) {
      this.props.toggle(true)
      // this.props.history.push('/accounts');
      // this.setState({
      //   isLoading: false
      // });
    } else {
      this.props.fetchProfile('',()=>{
        this.props.toggle(true)
        this.setState({
          isLoading: false
        });
        this.props.history.push('/accounts');
      })
    }
    this.props.setSFMState({
      modalToggleState: false,
      modal: false,
      autoOpenModal: undefined,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(nextState.isLoading !== this.state.isLoading){
      return true;
    }
    if (this.props && this.props.sfm && this.props.sfm.items && this.props.sfm.items.length == 0) {
      return false
    } else {
      return false
    }
  }

  render() {
    const {
      open,data,toggle, sfm,
      quicktrade,
    } = this.props;

    const arrayOfObj = data.error ? [] : (data.response) ? data.response.trades_status : [];
    const { isLoading } = this.state;

    const quickTradeFundArr = (quicktrade && quicktrade.funds && quicktrade.funds.length > 0 && quicktrade.funds[0].ticker) ? quicktrade.funds : null;

    return (
      <Fragment>
          <Modal
            keyboard
            centered
            backdrop
            unmountOnClose={false}
            isOpen={open}
            className="checkout-model-popup-main"
            backdropClassName="premium-feature-process-modal-backdrop"
          >
            <ModalBody className="checkout-model-outer">
              <div>
                <Spinner moreProps={{style: {position: 'absolute'}}} isFetching={isLoading} />
                <a className="checkout-model-cross" onClick={this.continue}><img src="/assets/images/cross.png"/></a>
                <div className="checkout-model-title">{data.error ? 'Error' : 'Confirmation'}</div>
                <div className="checkout-model-row-outer-container-section">
                {
                    data.error ? <p className={`checkout-model-error-text`}>{data.response.error && data.response.error}</p> :

                  arrayOfObj && arrayOfObj.map((obj,idx) => {

                    //let card = sfm && sfm.items ? sfm.items[idx] : {};
                    let filteredCard  = sfm && sfm.items && sfm && sfm.items.filter(e => e.ticker == obj.ticker);
                    filteredCard      = (sfm && sfm.items && sfm.items.length === 0 && quickTradeFundArr !== null) ? quickTradeFundArr : filteredCard;
                    let card          = filteredCard && filteredCard.length > 0 ? filteredCard[0] : {};

                    if (sfm && sfm.items && sfm.items.length === 0 && quickTradeFundArr !== null) {
                      card.short_name = quickTradeFundArr[0].name;
                    }

                    let imageExist = false;
                    var image = new Image();
                    var url_image = sponsorLogo(cn(card, 'Sponsor'))
                    image.src = url_image;
                    if (image.width == 0) {
                      imageExist = false;
                    } else {
                      imageExist = true
                    }

                    let checkoutClassName = (obj.status && obj.status.includes("REJECTED")) ? 'checkout-model-order order-failed' : 'checkout-model-order ordered';


                    return (

                        <div key={idx} className="checkout-model-row">
                          <div className="checkout-model-col checkout-model-left-col">
                            <span className="checkout-model-companyType">{obj.ticker}</span>

                            {card && imageExist && cn(card, 'Sponsor') ? (
                              <img src={sponsorLogo(cn(card, 'Sponsor'))} className="img-fluid" alt={cn(card, 'Sponsor')} style={{ height: '20px' }} />
                            ) : (
                                <div className="sponsor-logo-placeholder" title={cn(card, 'Sponsor') || ''}>{cn(card, 'Sponsor') || ''}</div>
                            )}


                            <p className="checkout-model-dosName text-overflow-ellipse-line-1" title={card.short_name}>{card.short_name}</p>
                          </div>
                          <div className="checkout-model-col checkout-model-middle-col">
                            <div className="checkout-model-magnify">Magnifi</div>
                          </div>
                          <div className="checkout-model-col checkout-model-right-col">
                            <div className={checkoutClassName}>
                              <span>{obj.status === 'SHARES_BOOKED' || obj.status === 'SHARES_TO_BOOK' ? 'COMPLETE' : obj.status}</span>
                              {/*obj.reason && <span title={obj.reason} className="checkout-model-reason text-overflow-ellipse-line-2">{obj.reason}</span>*/}
                              {obj.reason && <Fragment>
                                <a id={`checkoutDetails-${idx}`}>Details</a>

                                <UncontrolledTooltip placement="left" target={`checkoutDetails-${idx}`}  className="checkout-model-tooltip">
                                {obj.reason}
                                </UncontrolledTooltip>
                                </Fragment>
                              }
                            </div>
                          </div>
                        </div>

                    )
                  })
                }
                </div>
                <div className="checkout-model-btns">
                  <div className="checkout-model-btn-section">
                    <button className={style.confirm} onClick={this.viewDetails}>View Details</button>
                  </div>
                  <div className="checkout-model-btn-section">
                    <button className={style.confirm} onClick={this.continue}>Continue</button>
                  </div>
                </div>
              </div>

            </ModalBody>
          </Modal>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ auth, profile }) => ({
})

const mapDispatchToProps = {
  setSFMState,
  fetchProfile,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TradingExecutePopup))

import ActionTypes from '../action-types'


export function setUpdate (payload) {
  return {
    type:ActionTypes.SETUPDATE,
    payload
  }
}
export function toggleEdit (payload) {
  return {
    type:ActionTypes.TOGGLEEDIT,
    payload
  }
}
export function changeFname (payload) {
  return {
    type:ActionTypes.CHANGE_FNAME,
    payload
  }
}
export function changeLname (payload) {
  return {
    type:ActionTypes.CHANGE_LNAME,
    payload
  }
}

export function updateTradAccDisclosure (payload) {
  return {
    type:ActionTypes.UPDATE_TRADING_ACCOUNT_DISCLOSURE,
    payload
  }
}
export function updateTradAccDisclosureLoading(payload) {
  return {
    type:ActionTypes.UPDATE_TRADING_ACCOUNT_DISCLOSURE_LOADING,
    payload
  }
}
export function updateTradAccDisclosureSuccess(payload) {
  return {
    type:ActionTypes.UPDATE_TRADING_ACCOUNT_DISCLOSURE_SUCCESS,
    payload
  }
}

export function updateTradAccDisclosureError(payload) {
  return {
    type:ActionTypes.UPDATE_TRADING_ACCOUNT_DISCLOSURE_ERROR,
    payload
  }
}

export function cancelAchRelation(payload) {
  return {
    type:ActionTypes.CANCEL_ACH_TRANSACTION,
    payload
  }
}
export function cancelAchRelationLoading() {
  return {
    type:ActionTypes.CANCEL_ACH_LOADING
  }
}


export function cancelAchRelationSuccess(payload) {
  return {
    type: ActionTypes.CANCEL_ACH_TRANSACTION_SUCCESS,
    payload
  }
}

export function subscriptionPlan(payload) {
  return {
    type: ActionTypes.GET_SUBSCRIPTION_PLAN_REQUEST,
    payload
  }
}

export function subscriptionPlanFetch(payload) {
  return {
    type: ActionTypes.GET_SUBSCRIPTION_PLAN_FETCH,
    payload
  }
}

export function subscriptionPlanSuccess(payload) {
  return {
    type: ActionTypes.GET_SUBSCRIPTION_PLAN_SUCCESS,
    payload
  }
}

export function setPremiumState(payload) {
  return {
    type: ActionTypes.SET_PREMIUM_STATE,
    payload
  }
}

export function inviteFriends(payload) {
  return {
    type: ActionTypes.INVITE_FRIENDS,
    payload
  }
}

export function failedToInviteFriends(payload) {
  return {
    type: ActionTypes.FAILED_TO_INVITE_FRIENDS,
    payload
  }
}

export function successfullySentInvite(payload) {
  return {
    type: ActionTypes.SUCCESS_TO_INVITE_FRIENDS,
    payload
  }
}

export function setProfileState(payload) {
  return {
    type: ActionTypes.SET_PROFILE_STATE,
    payload
  }
}

export function setReferralCode(payload) {
  return {
    type: ActionTypes.SET_REFERRAL_CODE,
    payload
  }
}

export function fetchReferral(payload) {
  return {
    type: ActionTypes.REFERRAL_REQUEST,
    payload
  }
}

export function fetchProfile(payload, callback) {
  return {
    type: ActionTypes.PROFILE_REQUEST,
    payload,
    callback,
  }
}

export function successfulFetchProfile(profile) {
  return {
    type: ActionTypes.PROFILE_SUCCESS,
    profile
  }
}

export function failedFetchProfile(error) {
  return {
    type: ActionTypes.PROFILE_FAILURE,
    error
  }
}

export function loadProfile(profile) {
  return {
    type: ActionTypes.PROFILE_LOAD,
    profile
  }
}

export function profileChangePasswordRequest(payload) {
  return {
    type: ActionTypes.CHANGE_PASSWORD_REQUEST,
    payload
  }
}

export function profileChangePasswordFetch(payload) {
  return {
    type: ActionTypes.CHANGE_PASSWORD_FETCH,
    payload
  }
}

export function profileChangePasswordSuccess(response) {
  return {
    type: ActionTypes.CHANGE_PASSWORD_SUCCESS,
    response
  }
}

export function failedChangePassword(error) {
  return {
    type: ActionTypes.CHANGE_PASSWORD_FAILURE,
    error
  }
}


export function updateProfile(payload) {
  return {
    type: ActionTypes.PROFILE_UPDATE_REQUEST,
    payload
  }
}

export function successfulUpdateProfile(response) {
  return {
    type: ActionTypes.PROFILE_UPDATE_SUCCESS,
    response
  }
}

export function failedUpdateProfile(error) {
  return {
    type: ActionTypes.PROFILE_UPDATE_FAILURE,
    error
  }
}

export function createSubscriptionRequest(payload, callback) {
  return {
    type: ActionTypes.CREATE_SUBSCRIPTION_REQUEST,
    payload,
    callback,
  }
}

export function successfulSubscriptionRequest(payload) {
  return {
    type: ActionTypes.CREATE_SUBSCRIPTION_SUCCESS,
    payload
  }
}

export function failedSubscriptionRequest(error) {
  return {
    type: ActionTypes.CREATE_SUBSCRIPTION_FAILURE,
    error
  }
}

export function unsubscribeUserRequest(payload) {
  return {
    type: ActionTypes.UNSUBSCRIBE_USER_REQUEST,
    payload
  }
}

export function successfulUnsubscribeUser(payload) {
  return {
    type: ActionTypes.UNSUBSCRIBE_USER_SUCCESS,
    payload
  }
}

export function failedUnsubscribeUser(error) {
  return {
    type: ActionTypes.UNSUBSCRIBE_USER_FAILURE,
    error
  }
}

export function reactivateSubcriptionRequest(payload) {
  return {
    type: ActionTypes.REACTIVATE_SUBSCRIPTION_REQUEST,
    payload
  }
}

export function successfulReactivateSubcription(payload) {
  return {
    type: ActionTypes.REACTIVATE_SUBSCRIPTION_SUCCESS,
    payload
  }
}

export function failedReactivateSubcription(error) {
  return {
    type: ActionTypes.REACTIVATE_SUBSCRIPTION_FAILURE,
    error
  }
}


/* CHECK PREMIUM STATE: LITE, PLUSS USER ? */
export function checkPremiumStateRequest(payload, callback) {
  return {
    type: ActionTypes.CHECK_PREMIUM_STATE_REQUEST,
    payload,
    callback,
  }
}

export function checkPremiumStateFetch(payload) {
  return {
    type: ActionTypes.CHECK_PREMIUM_STATE_FETCH,
    payload,
  }
}

export function checkPremiumStateSuccess(payload) {
  return {
    type: ActionTypes.CHECK_PREMIUM_STATE_SUCCES,
    payload,
  }
}

/* CHECK PREMIUM FEATURE ACCESS DETAILS */
export function premiumFeatureAccessRequest(payload, callback) {
  return {
    type: ActionTypes.PREMIUM_FEATURE_ACCESS_REQUEST,
    payload,
    callback,
  }
}

export function premiumFeatureAccessFetch(payload) {
  return {
    type: ActionTypes.PREMIUM_FEATURE_ACCESS_FETCH,
    payload,
  }
}

export function premiumFeatureAccessSuccess(payload) {
  return {
    type: ActionTypes.PREMIUM_FEATURE_ACCESS_SUCCESS,
    payload,
  }
}


export function setPopOverOptionState(payload) {
  return {
    type: ActionTypes.SET_POPOVER_OPTION_STATE,
    payload
  }
}

/* ---- GET DOWNLOADED PROPOSALS LIST  ---- */
export function proposalsListLoader() {
  return {
    type: ActionTypes.PROPOSALS_LIST_LOADER
  }
}

export function getProposalsListRequest(payload) {
  return {
    type: ActionTypes.GET_DWNLD_PORTFOLIO_LIST_REQUEST,
    payload,
  }
}

export function getProposalsListFetch(payload) {
  return {
    type: ActionTypes.GET_DWNLD_PORTFOLIO_LIST_FETCH,
    payload,
  }
}

export function getProposalsListSuccess(payload) {
  return {
    type: ActionTypes.GET_DWNLD_PORTFOLIO_LIST_SUCCESS,
    payload,
  }
}

export function filterProposals(payload) {
  return {
    type: ActionTypes.FILTER_PROPOSALS_LIST,
    payload
  }
}

export function getTotalProposals() {
  return {
    type: ActionTypes.GET_PROPOSALS_COUNT
  }
}

export function getTotalProposalsSuccess(payload) {
  return {
    type: ActionTypes.GET_PROPOSALS_COUNT_SUCCESS,
    payload,
  }
}

/* Activate Similar Search Options */
export function activateSimilarSearchRequest(payload, callback) {
  return {
    type: ActionTypes.ACTIVATE_SIMILAR_SEARCH_REQUEST,
    payload,
    callback,
  }
}

export function activateSimilarSearchFetch(payload) {
  return {
    type:ActionTypes.ACTIVATE_SIMILAR_SEARCH_FETCH,
    payload,
  }
}

export function  activateSimilarSearchSuccess(payload) {
  return {
    type:ActionTypes.ACTIVATE_SIMILAR_SEARCH_SUCCESS,
    payload,
  }
}


export function deleteAccountEmailRequest(payload, callback) {
  return {
    type: ActionTypes.EMAIL_DELETE_ACCOUNT_REQUEST,
    payload,
    callback,
  }
}

export function deleteAccountEmailFetch(payload) {
  return {
    type:ActionTypes.EMAIL_DELETE_ACCOUNT_FETCH,
    payload,
  }
}

export function  deleteAccountEmailSuccess(payload) {
  return {
    type:ActionTypes.EMAIL_DELETE_ACCOUNT_SUCCESS,
    payload,
  }
}


export function deleteAccountRequest(payload, callback) {
  return {
    type: ActionTypes.DELETE_ACCOUNT_REQUEST,
    payload,
    callback,
  }
}

export function deleteAccountFetch(payload) {
  return {
    type:ActionTypes.DELETE_ACCOUNT_FETCH,
    payload,
  }
}

export function  deleteAccountSuccess(payload) {
  return {
    type:ActionTypes.DELETE_ACCOUNT_SUCCESS,
    payload,
  }
}


export function tokenExpiryCheck(payload, callback) {
  return {
    type: ActionTypes.TOKEN_EXPIRY_CHECK_REQUEST,
    payload,
    callback,
  }
}

export function tokenExpiryFetch(payload) {
  return {
    type:ActionTypes.TOKEN_EXPIRY_CHECK_FETCH,
    payload,
  }
}

export function  tokenExpirySuccess(payload) {
  return {
    type:ActionTypes.TOKEN_EXPIRY_CHECK_SUCCESS,
    payload,
  }
}

// PROFILE_DATA_REQUEST

export function profileDataRequest(payload, callback) {
  return {
    type: ActionTypes.PROFILE_DATA_REQUEST,
    payload,
    callback,
  }
}

export function profileDataFetch(payload) {
  return {
    type:ActionTypes.PROFILE_DATA_FETCH,
    payload,
  }
}

export function  profileDataSuccess(payload) {
  return {
    type:ActionTypes.PROFILE_DATA_SUCCESS,
    payload,
  }
}

// GET_PROFILE_ORDERS_REQUEST

export function getProfileOrders(payload) {
  return {
    type: ActionTypes.GET_PROFILE_ORDERS_REQUEST,
    payload
  }
}

export function getProfileOrdersFetch(payload) {
  return {
    type: ActionTypes.GET_PROFILE_ORDERS_FETCH,
    payload
  }
}

export function getProfileOrdersSuccess(payload) {
  return {
    type: ActionTypes.GET_PROFILE_ORDERS_SUCCESS,
    payload
  }
}

// SAVE DISCLOSURES
export function saveDisclosuresOrdersRequest(payload, callback) {
  return {
    type: ActionTypes.SAVE_DISCLOSURES_REQUEST,
    payload,
    callback,
  }
}

export function saveDisclosuresOrdersFetch(payload) {
  return {
    type: ActionTypes.SAVE_DISCLOSURES_FETCH,
    payload
  }
}

export function saveDisclosuresOrdersSuccess(payload) {
  return {
    type: ActionTypes.SAVE_DISCLOSURES_SUCCESS,
    payload
  }
}

// REQUEST FOR SUBSCRIPTIONS CANCELLATIONS
export function activeSubscriptionsCancellationRequest(payload, callback) {
  return {
    type: ActionTypes.ACTIVE_SUBSCRIPTIONS_CANCELLATION_REQUEST,
    payload,
    callback,
  }
}

export function activeSubscriptionsCancellationFetch(payload) {
  return {
    type: ActionTypes.ACTIVE_SUBSCRIPTIONS_CANCELLATION_FETCH,
    payload
  }
}

export function activeSubscriptionsCancellationSuccess(payload) {
  return {
    type: ActionTypes.ACTIVE_SUBSCRIPTIONS_CANCELLATION_SUCCESS,
    payload
  }
}

import React, { Component } from 'react';
import './_index.scss';
import {Row ,Button} from 'reactstrap';
import { Skeleton } from 'antd';
import { data, advisordata } from './themeData';

class TrendingTheme extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleClick = () =>{
     this.props.redirectToThemes();
  }

  handleError = (e) => {
    if(e){
        e.target.src = '/assets/images/Fallback.png';
    }
  }

  renderImageSkeleton = () => (
    <div className="trending-theme r32">
        <h1>Trending Themes</h1>
        <div className="trending-theme-container">
            <Row className="trending-theme-row">
            <div className="skeleton-container">
               <div className="skeleton-wrap">
                    <Skeleton.Avatar className="skeleton-Avatar" active size={'large'} shape={'large'} />
                    <Skeleton paragraph={{ rows: 3 }} />
                </div>
                <div className="skeleton-wrap">
                    <Skeleton.Avatar className="skeleton-Avatar" active size={'large'} shape={'large'} />
                    <Skeleton paragraph={{ rows: 3 }} />
                </div>
                <div className="skeleton-wrap">
                    <Skeleton.Avatar className="skeleton-Avatar" active size={'large'} shape={'large'} />
                    <Skeleton paragraph={{ rows: 3 }} />
                </div>
                <div className="skeleton-wrap">
                    <Skeleton.Avatar className="skeleton-Avatar" active size={'large'} shape={'large'} />
                    <Skeleton paragraph={{ rows: 3 }} />
                </div>
            </div>
            </Row>
        </div>
  </div>
  )

  renderImage = (arraytoProceed) => (
    <div className="trending-theme r32">
    <h1>Trending Themes</h1>
    <div className="trending-theme-container">
        <Row className="trending-theme-row">
            {arraytoProceed.map((o)=><div class="trending-theme-card" onClick={()=>this.props.queryHandler(`${o.query}`,'trending_themes')}>
                <div class="t-entry-visual-overlay">
                    <div class="t-entry-visual-overlay-in">
                </div>
            </div>
            <div class="t-overlay-wrap">
                <div class="t-overlay-inner">
                    <div class="t-overlay-content">
                        <div class="t-overlay-text half-block-padding">
                            <div class="t-entry">
                                <h3 class="t-entry-title h5 font-weight-500">{o.theme}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img className="trending-theme-card-image" 
                 src={o.logo_url_advisor}
                 onError={(e)=>this.handleError(e)}/>
            {/* <div className="bottom-right">{o.name}</div> */}
        </div>)}
        </Row>
        {/* <div class="trending-theme-card">
            <img className="trending-theme-card-image" src="https://magnifi.com/wp-content/uploads/2019/12/Big-Data-uai-258x258.jpg"/>
            <div className="bottom-right">Big Data</div>
        </div> */}
    </div>
    {/*<div className="trending-theme-button">
        <Button outline color="secondary" onClick={()=>this.handleClick()}>VIEW MORE</Button>
    </div>*/}
    </div>
  )

  render() {
    const {trendingSearchTheme,loadingTrendingTheme }=this.props;
    let arraytoProceed = process.env.REACT_APP_SUB_DOMAIN === 'advisor' ?  trendingSearchTheme : data;
    return (
        loadingTrendingTheme ? this.renderImageSkeleton() : this.renderImage(arraytoProceed)
    );
  }
}


export default TrendingTheme

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
// import { Alert } from 'antd';
import {
  Form,
  FormGroup,
  Label,
  Input,
  // Breadcrumb,
  // BreadcrumbItem,
} from 'reactstrap'
// import GoogleLogin from 'react-google-login'

import { CustomFormFeedback, PrimaryButton, AppleLogin } from 'shared-components'

import LoginErrorMessage from '../Elements/LoginErrorMessage'

import { addEventToAnalytics } from 'Utils'

// import { ReactComponent as AppleLogoImg } from './apple_logo_SVG.svg';
// import { AppleFilled } from '@ant-design/icons';

// import config from  'env';

const googleLogo = '/assets/images/google@3x.png'

class LoginForm extends Component {
  static propTypes = {
    formProps: PropTypes.object.isRequired,
    onGoogleSignInSuccess: PropTypes.func.isRequired,
    onGoogleSignInError: PropTypes.func.isRequired
  }

  render() {
    const { formProps, loginError, resendEmailAction } = this.props
    // const linkButton = (
    //   <FormGroup>
    //     <Breadcrumb className="sign-in__breadcrumb">
    //       <BreadcrumbItem className="sign-in__breadcrumb-item">
    //         <Link to="/register" className="sign-in__breadcrumb-link">
    //           Register
    //         </Link>
    //       </BreadcrumbItem>
    //       <BreadcrumbItem className="sign-in__breadcrumb-item">
    //         <Link to="/forgot-password" className="sign-in__breadcrumb-link">
    //           Forgot Password?
    //         </Link>
    //       </BreadcrumbItem>
    //     </Breadcrumb>
    //   </FormGroup>
    // )
    //
    // const forgetPassword = (
    //   <Link className="hyper-link" to="/forgot-password">
    //     Forgot Password?
    //   </Link>
    // )

    const onJoinClicked = () => {
      if (process.env.REACT_APP_SUB_DOMAIN === 'retail') {
        this.props.history.push('/register', {
          plan: 'basic',
          planType: 'selector'
        })
      } else {
        this.props.history.push('/join')
      }
    }

    const {
      values,
      errors,
      touched,
      handleBlur,
      isSubmitting,
      handleChange,
      handleSubmit,
    } = formProps

    return (
      <Form
        className="sign-in magnifi-auth-design-container"
        noValidate={true}
        onSubmit={handleSubmit}
      >
        <div className="header-area">
          <div className="header-label">Welcome to Magnifi</div>
        </div>

        <div className="body-wrapper">
          <FormGroup className="element-box">
            <Label htmlFor="email" className="label">
              Email Address
            </Label>
            <Input
              type="email"
              name="email"
              id="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.email && touched.email
                  ? 'sign-in__input-field sign-in__input-email is-invalid input-area'
                  : 'sign-in__input-field sign-in__input-email input-area'
              }
            />
            <CustomFormFeedback formProps={formProps} fieldName="email" />
          </FormGroup>

          <FormGroup className="element-box pos-rel">
            <Label htmlFor="password" className="label">
              Password
            </Label>
            <Input
              type="password"
              name="password"
              id="password"
              //placeholder="Password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.password && touched.password
                  ? 'sign-in__input-field sign-in__input-password is-invalid input-area'
                  : 'sign-in__input-field sign-in__input-password input-area'
              }
            />
            <CustomFormFeedback formProps={formProps} fieldName="password" />
            <div
              onClick={() =>
                addEventToAnalytics(
                  'Forget Password',
                  'Forget Password',
                  'FORGET_PASSWORD',
                  {},
                  false
                )
              }
            >
              <Link
                className="hyper-link"
                style={{ fontSize: '0.8rem' }}
                to="/forgotpwd"
              >
                Forgot Password?
              </Link>
            </div>
          </FormGroup>

          {loginError && loginError.error && (
            <LoginErrorMessage
              email={values.email}
              error={loginError.error}
              disabledAction={errors.email && touched.email}
              resendEmailAction={resendEmailAction}
            />
          )}

          <FormGroup className="cta-wrapper">
            <PrimaryButton
              type="submit"
              className="btn primary-btn"
              block
              disabled={isSubmitting}
            >
              Sign in
            </PrimaryButton>
            <div
              className="acc-access-option-wrapper"
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              Don't have an account? &nbsp;
              <div className="hyper-link" onClick={onJoinClicked}>
                <span>Join Now</span>
              </div>
            </div>
            <div className="or-partition">OR</div>

            {/*
              <GoogleLogin
                clientId={config.googleClientId}
                render={renderProps => (
                  <button
                    className="btn secondary-btn sign-in__googleBtn"
                    onClick={renderProps.onClick}
                  >
                    <img src={googleLogo} width={20} alt="google-logo" />
                    <span style={{ paddingLeft: 10 }}>Sign in with Google</span>
                  </button>
                )}
                onSuccess={this.props.onGoogleSignInSuccess}
                onFailure={(error) => this.props.onGoogleSignInError(error)}
              />
            */}

            <button
              className="btn secondary-btn sign-in__googleBtn"
              onClick={(event) => this.props.onGoogleSignInSuccess(event)}
            >
              <img src={googleLogo} width={20} alt="google-logo" />
              <span style={{ paddingLeft: 10 }}>Sign in with Google</span>
            </button>

            {/*process.env.REACT_APP_SUB_DOMAIN === 'retail' && (
              <AppleLogin>
                <button
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    background: "white",
                    color: "#56a9e8",
                    border: "1px solid #56a9e8",
                    borderRadius: "100px",
                    padding: ".8em",
                    paddingRight: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "13px",
                    fontWeight: 700,
                    textTransform: "uppercase"
                  }}
                >
                  <AppleLogoImg />
                  {<AppleFilled style={{ color: "black", fontSize: "1.5em" }} />}
                  <span style={{ paddingLeft: ".8em" }}>Sign in with Apple</span>
                </button>
              </AppleLogin>
            )*/}

          </FormGroup>
        </div>

      </Form>
    )
  }
}

export default LoginForm;

import React, { Component } from 'react'
import moment from 'moment'
import { MdlEnhancerContextProvider } from './Context/MdlEnhancerContext'
import ErrorBoundary from 'components/shared/ErrorBoundary'
import CoverPage from './Pages/CoverPage'
import IndexPage from './Pages/IndexPage'
import SelectedReplacementPage from './Pages/SelectedReplacementPage'
import OtherReplacementsPage from './Pages/OtherReplacementsPage'
import { GlossaryPage2 } from '../../PortfolioReportV2/sf/Glossary'

class ModelEnhancementReport extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    document.body.classList.add('portfolio-reports-v2')
  }

  render() {
    let data = this.props.data
    if (data && typeof data.modelEnhancement === 'undefined' && data.portfolio) {
      data = {
        ...data,
        ...data.portfolio,
      }
    }
    const { disclosures, logo } = data
    let {
      modelEnhancement: { enhanced, otherEnhancements }
    } = data

    data.showOtherEnhancements =
      otherEnhancements.filter(
        item => item.portfolioName !== enhanced.portfolioName
      ).length > 0

    let createdDate = moment(
      new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })
    ).format('MM/DD/YYYY')
    if (enhanced && enhanced.data) {
      const { data: _enhancedFundData } = enhanced
      if (_enhancedFundData && _enhancedFundData.feed_date) {
        createdDate = moment(_enhancedFundData.feed_date).format('MM/DD/YYYY')
      }
    }

    return (
      <ErrorBoundary>
        <MdlEnhancerContextProvider value={data}>
          <CoverPage
            disclosures={disclosures}
            createdDate={createdDate}
            logo={logo}
          />
          <div className="container h-100 mdl-enh-wrapper">
            <IndexPage createdDate={createdDate} logo={logo} />
            <SelectedReplacementPage createdDate={createdDate} logo={logo} />
            <OtherReplacementsPage createdDate={createdDate} logo={logo} />
            <GlossaryPage2 createdDate={createdDate} logo={logo} />
          </div>
        </MdlEnhancerContextProvider>
      </ErrorBoundary>
    )
  }
}

export default ModelEnhancementReport

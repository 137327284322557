import React, { Component } from 'react';
import { Row, Col, PopoverBody } from 'reactstrap';
import { withPopover } from 'components/Popover';
import { quintileSize, quintileClass } from 'Utils';
import { cn, QuinItems } from 'DataSet';
import { Quintile, Pct } from '../../common';

var classNames = require('classnames');

export default class ResponsiveRiskPopover extends Component {
  onMouseOver = () => {
		try {
			const element = document.querySelector('.popover-drop__item');
			element.style.display = 'block';
		} catch (e) {
			console.log(e);
		}
  }

  onMouseOverItem = () => {
		try {
			const element = document.querySelector('.popover-drop__item');
			element.style.display = 'block';
		} catch (e) {
			console.log(e);
		}
  }

  onMouseLeave = () => {
		try {
			const element = document.querySelector('.popover-drop__item');
			element.style.display = 'none';
		} catch (e) {
			console.log(e);
		}
  }

  setItem = (e) => {
		try {
			const val = e.target.id;

			const element = document.querySelector('.pop-toggle-name');
			element.innerHTML = val;
			document.querySelector('.popover-drop__item').style.display = 'none';

			if (val === 'Annualized Volatility')  {
				const element1 = document.querySelector('.popover-drop__item__wrapper');
				element1.innerHTML = `<li class="popover-drop__item__wrapper__list" id="Drawdown">Drawdown</li>`
				const elems1 = document.querySelectorAll(".drawdown");
				[].forEach.call(elems1, function(el) {
					el.style.display = 'none';
				});
				const elems2 = document.querySelectorAll(".annualized-volatility");
				[].forEach.call(elems2, function(el) {
					el.style.display = 'block';
				});
			} else {
				const element1 = document.querySelector('.popover-drop__item__wrapper');
				element1.innerHTML = `<li class="popover-drop__item__wrapper__list" id="Annualized Volatility">Annualized Volatility</li>`
				const elems1 = document.querySelectorAll(".annualized-volatility");
				[].forEach.call(elems1, function(el) {
					el.style.display = 'none';
				});
				const elems2 = document.querySelectorAll(".drawdown");
				[].forEach.call(elems2, function(el) {
					el.style.display = 'block';
				});
			}
			this.addEvent();
		} catch (e) {
			console.log(e);
		}
  }

  addEvent = () => {
		try {
			const abc = document.querySelector('.popover-drop__item__wrapper__list');
			if (abc !== null) {
				abc.addEventListener('click', this.setItem)
			}
		} catch (e) {
			console.log(e);
		}
  }

  render() {
    const { card, highlight, checkPopOpen, firstPop } = this.props;
    const i = 4;
    const blueUrl = `/assets/images/icons/blue-heart-rate-${quintileSize(card._stats.voltQ, QuinItems[i].reverse)}.svg`;
    const iconUrl = `/assets/images/icons/heart-rate-${quintileSize(card._stats.voltQ, QuinItems[i].reverse)}.svg`;

    const hikeLabel = quintileSize(card._stats.voltQ, QuinItems[i].reverse) === 2 ? 'Average' : (quintileSize(card._stats.voltQ, QuinItems[i].reverse) === 3 ? 'High' : 'Low');
    // const statusColor = quintileClass(card._stats.voltQ, QuinItems[i].reverse) === 'danger' ? '#ff4d4d' : (quintileClass(card._stats.voltQ, QuinItems[i].reverse) === 'success' ? '#416D60' : '#1b8be0');
    const statusText = card._stats.volt.toFixed(2)+"%";

    const e1 = () => (
      <span className=" icon-Slader-Risk h2 hover-orange"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
    );

    const e2 = () => (
      <React.Fragment>
        <PopoverBody className="p-0">
          <Row className="no-gutters">
            {/* <Col className="col-12 p-2 pt-3 d-flex justify-content-between align-items-center">
              <h4 className="fs-17 text-ssf-blue-d2 mb-1">{'Risk'}<span className="h6 font-three text-gray-7 ml-2">{card._stats.start + ' - ' + card._stats.end}</span></h4>
            </Col> */}
            <Col className="col-12 p-2 text-gray-9 bg-light br-2">
              <div className={classNames("h5 d-flex justify-content-between pt-1")}>

                <div className="popover-drop">
                  <div className="popover-drop__toggle" onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave} >
                    <span className="pop-toggle-name">Annualized Volatility</span> <i className="fas fa-caret-down"></i>
                  </div>
                  <div className="popover-drop__item" onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}>
                    <ul className="popover-drop__item__wrapper">
                      {/* <li className="popover-drop__item__wrapper__list" onClick={() => this.setItem('Annualized Volatility')}>Annualized Volatility</li> */}
                      <li className="popover-drop__item__wrapper__list" id="Drawdown">Drawdown</li>
                    </ul>
                  </div>
                </div>
                <span className="font-weight-bold annualized-volatility">{card._stats.volt.toFixed(2)}<Pct /></span>
                <span className="font-weight-bold drawdown">{card._stats.dMax.toFixed(2)}<Pct /></span>
              </div>

              <div className="annualized-volatility">
                <Quintile date={`${card._stats.start} - ${card._stats.end}`} item={QuinItems[i]} value={card._stats.voltQ}
                  catValue={<span>{card._cstats.volt.toFixed(2)}<Pct /></span>}
                  bg={quintileClass(card._stats.voltQ, QuinItems[i].reverse)} />
              </div>
              <div className="drawdown">
                <Quintile date={`${card._stats.start} - ${card._stats.end}`} item={QuinItems[i]} value={card._stats.dMaxQ}
                  catValue={<span>{card._cstats.dMax.toFixed(2)}<Pct /></span>}
                  bg={quintileClass(card._stats.dMaxQ, QuinItems[i].reverse)} />
              </div>
            </Col>
          </Row>
        </PopoverBody>
        {this.addEvent()}
      </React.Fragment>
    );

    const highlightClassName = highlight ? 'search-risk-stats-padding search-risk-stats-highlighting' : (card._flags.risksMin ? 'search-risk-stats-padding search-risk-stats-highlighting' : 'search-risk-stats-padding');
    const WithPopover = withPopover(e1, e2, { id: "ResponsiveRiskPopover-" + cn(card, 'Id'), placement: 'bottom', className: '',
      checkPopOpen, firstPop
    });
    return (
      <WithPopover />
    );
  }
}

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { ScoreAttrs, AdditionalScoreAttrs, calculatorOptions } from 'DataSet'
import { postWeightsFormData, setWeightsData, setCustomSelectorName, setCustomCalcScoreAttrs } from 'actions/weights'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { findIndex } from 'lodash'
import { addEventToAnalytics } from 'Utils'
import ConfigureWeightsTable from './ConfigureWeightsTable'
import ConfigureScreener from './ConfigureScreener'

//import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';

const configureWeightsSchema = Yup.object().shape({
  yRet: Yup.number(),
  risk: Yup.number(),
  fees: Yup.number(),
  aum: Yup.number(),
  vol: Yup.number(),
  trkerror: Yup.number(),
  weight_yield: Yup.number(),
  weight_bid_ask: Yup.number(),
  weight_track_record: Yup.number()
})

const ConfigureWeightsWithForm = ({
  scoreAttrs: originalAttrs,
  weights,
  scoreAttrsWeightHandler,
  loading,
  weightSuccess,
  weightError,
  funds,
  selectedCalculator,
  setCustomSelectorName,
  customCalculators,
  ...props
}) => {
  const [scoreAttrs, setScoreAttrs] = useState(originalAttrs);
  const [lockedVariables, setLockedVariables] = useState([])
  const validator = values => {
    const errors = {}
    let hasNegativeValue = false
    let isNotValidNumber = false

    const total = Object.keys(values).reduce((total, current) => {
      if (values[current] < 0) {
        hasNegativeValue = true
      }
      if (isNaN(values[current])) {
        isNotValidNumber = true
      }
      return total + parseFloat(values[current] || 0)
    }, 0)

    if (hasNegativeValue || isNotValidNumber) {
      errors.formAlert = 'Please enter a positive number.'
      return errors
    }

    if (total <= 0) {
      errors.formAlert = `Please enter atleast one value.`
    }
    return errors
  }

  const [selectorName, setSelectorName] = useState('');

  const [showMore, setShowMore] = useState(
    false ||
      ScoreAttrs.length + AdditionalScoreAttrs.length === scoreAttrs.length
  )

  const remainingAttrs = [...ScoreAttrs, ...AdditionalScoreAttrs]
  const shouldDisplayShowmore = scoreAttrs.length < remainingAttrs.length
  let initialLockedVariables = [];
  scoreAttrs.forEach(attr => {
    const filteredIndex = findIndex(remainingAttrs, { col: attr.col })
    remainingAttrs.splice(filteredIndex, 1)
    if(attr.lock){
      initialLockedVariables.push(attr.col);
    }
  })
  useEffect(() => {
    setLockedVariables(initialLockedVariables)
    if(selectedCalculator === 'create custom selector'){
      setCustomCalcScoreAttrs([]);
    }
  }, []);
  // console.log('===scoreAttrs', scoreAttrs);
  // const finalAttrs = !showMore ? scoreAttrs : [...scoreAttrs, ...remainingAttrs]
  const finalAttrs = scoreAttrs;
  // console.log('===finalAttrs', finalAttrs);
  let defaultValues = {}
  const initialValues = {}
  ;(finalAttrs || []).forEach(attr => {
    defaultValues[attr.col] = attr.weight || 0
  })

  const onFormSubmit = values => {
    if(selectedCalculator === 'create custom selector'){
      setCustomSelectorName(selectorName);
    }
    props.setCustomCalcScoreAttrs(scoreAttrs);
    const arrayValues = [];
    (finalAttrs || []).forEach(attr => {
      arrayValues.push({
        name: attr.code,
        weight: values[attr.col] || 0,
        screener: attr.screener || null,
        lock: lockedVariables.includes(attr.col),
      })
    })
    props.postWeightsFormData(arrayValues)
    scoreAttrsWeightHandler(arrayValues)

    // const weightScore = []
    // ;(finalAttrs || []).forEach(attr => {
    //   const index = arrayValues.findIndex(x => x.name === attr.code)
    //   if (index >= 0)
    //     weightScore.push({
    //       weight_name: attr.code,
    //       weight_value: arrayValues[index].weight
    //     })
    // })
    // props.setWeightsData({ weightScore: weightScore })
    if (funds) {
      addEventToAnalytics(
        'Custom Weight Calculator -- Save',
        'Custom Weight Calculator -- Save',
        'CUSTOM_WEIGHT_CALCULATOR -- SAVE',
        { query: funds.query, logId: funds.logId },
        true
      )
    }
  }

  return (
    <>
    {(selectedCalculator === calculatorOptions.magnifiactivescreener) ?
      <ConfigureScreener
        scoreAttrs={finalAttrs}
        values={defaultValues}
        modalToggle={props.modalToggle}
      /> :
      <Formik
        enableReinitialize
        initialValues={{
          ...defaultValues,
          ...initialValues
        }}
        validationSchema={configureWeightsSchema}
        onSubmit={onFormSubmit}
        validate={validator}
        render={props => (
          <ConfigureWeightsTable
            {...props}
            loading={loading}
            weightSuccess={weightSuccess}
            weightError={weightError}
            scoreAttrs={finalAttrs}
            setScoreAttrs={setScoreAttrs}
            onShowMoreClick={() => setShowMore(!showMore)}
            showMore={showMore}
            shouldDisplayShowmore={shouldDisplayShowmore}
            selectedCalculator={selectedCalculator}
            selectorName={selectorName}
            setSelectorName={setSelectorName}
            customCalculators={customCalculators}
            lockedVariables={lockedVariables}
            setLockedVariables={setLockedVariables}
          />
        )}
      />
    }
    </>
  )
}

const mapStateToProps = ({ discovery, weights }) => ({
  funds: discovery,
  weights: weights.weightScore,
  loading: weights.loading,
  weightSuccess: weights.weightSuccess,
  weightError: weights.error,
  selectedCalculator: weights.selectedCalculator,
  customCalculators: weights.customCalculators,
})

const mapDispatchToProps = {
  setCustomSelectorName,
  postWeightsFormData,
  setWeightsData,
  setCustomCalcScoreAttrs,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigureWeightsWithForm)

ConfigureWeightsWithForm.defaultProps = {
  scoreAttrs: []
}

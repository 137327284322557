import React, { Component } from 'react';
import AdvisorRegisterPage from 'layouts/WebDashboard/Register';
import RetailRegisterPage from 'layouts/WebDashboard/RegisterV2';

export default class RenderRegisterPage extends Component {
  render() {
		if (process.env.REACT_APP_SUB_DOMAIN === 'retail') return (<RetailRegisterPage {...this.props} />);
    return (
			<AdvisorRegisterPage {...this.props} />
    )
  }
}

import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { Button } from 'reactstrap';

import { fetchApexBalance } from 'apis/funds';
import { fetchImportedAccounts } from 'apis/broker';

import { cn, Brokers, isDemoUser, MIN_INVESTMENT_FUNDS } from 'DataSet';
import { getNest, addEventToAnalytics } from 'Utils';

import ItemCard from './ItemCard';
import { UnitsValue } from './helper';
import AddMoneyPopup from './AddMoney';
import SearchCard from './SearchCard';
import { isEmpty } from 'lodash/lang'
import { RETAIL_USERS_ROLES } from '../../Constant/FormData';
import Emitter from 'realTimePrice/emitter'

const comingSoonValues = ['SMA', 'MP'];
let realTimePrices = []

let payload = []
class ItemList extends React.Component {
  constructor(props) {
    // console.log(props)
    super(props);
    this.state = {
      amt: 'Units',
      avaBalance: null,
      showAddMoney: false,
      showBalance: false,
      noBankAccount: false,
      showPendingAccount: false,
      realTimePrices: []
    }
  }

  selectable = broker => get(broker, 'status') !== 'Register'

  componentDidMount() {
    this.refreshApexAccountDetails();
    const elems = document.querySelectorAll('.custom-scroll');
    if (elems.length) {
      [].forEach.call(elems, function (el) {
        if (el.scrollHeight > el.clientHeight) {
          el.style.paddingRight = '.25rem';
          el.style.paddingLeft = '.40rem';
        } else el.style.paddingRight = '0rem';
      });
    }
    this.showPendingAccount()
  }

  componentDidUpdate(prevProps, prevState) {

  }

  refreshApexAccountDetails = () => {
    const { getApexAccountDetailsRequest } = this.props;
    if (getApexAccountDetailsRequest) {
      getApexAccountDetailsRequest('', (accountBalanceResponse) => {
        // console.log(accountBalanceResponse);
        if (accountBalanceResponse && accountBalanceResponse.status === 200 && accountBalanceResponse.data) {
          if (accountBalanceResponse.data && typeof accountBalanceResponse.data.balancePower !== 'undefined') {
            const { balancePower } = accountBalanceResponse.data;
            this.setState({ avaBalance: balancePower })
          }
        }
      });
    }
  }

  componentWillMount() {
    // console.log('==== Item list SFM componentWillMount ====');
    let brokers = get(this.props, 'brokers', []).map(e => {
      if (e.name == 'Magnifi' && e.status == 'Registered') {
        this.setState({ 'showBalance': true });
      }
      let b = Brokers.find(b => b.name === e.name);
      if (!b) b = Brokers.find(b => b.name === 'dummy');
      return { ...b, ...e }
    });
    let brokerNames = brokers.map(e => e.name);
    var flags = {}
    var comms = this.props.items.map(e => ({ t: cn(e, 'Ticker'), b: e.brokers }));
    // console.log(brokerNames);
    // console.log(comms);
    comms.forEach(c => {
      const _c = brokerNames.map(b => c.b.find(e => e.name === b));
      const min = Math.min(..._c.map(e => e.commission));
      flags[c.t] = _c.map((e, i) => ({
        min: e.commission === min,
        sel: false,
        disabled: !this.selectable(brokers[i]),
        id: brokers[i].id
      }));
    })

    this.props.doRegisterHandler(!brokers.some(this.selectable));
    this.setState({ brokers, brokerNames, flags }, () => this.selectBroker(brokers[0]));
    // console.log(this.state);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.brokers !== this.props.brokers) {
      this.showAddMoneyText()
      let brokers = get(newProps, 'brokers', []).map(e => {
        if (e.name == 'Magnifi' && e.status == 'Registered') {
          this.setState({ 'showBalance': true });
        }
        let b = Brokers.find(b => b.name === e.name);
        if (!b) b = Brokers.find(b => b.name === 'dummy');
        return { ...b, ...e }
      });
      // console.log(brokers);
      // console.log(this.state.brokers);
      let brokerNames = brokers.map(e => e.name);
      var comms = newProps.items.map(e => ({ t: cn(e, 'Ticker'), b: e.brokers }));
      var flags = {}, newComms = [];
      comms.forEach(c => {
        const _c = brokerNames.map(b => c.b.find(e => e.name === b));
        const min = Math.min(..._c.map(e => e.commission));
        flags[c.t] = _c.map((e, i) => ({
          min: e.commission === min,
          sel: false,
          disabled: !this.selectable(brokers[i]),
          id: brokers[i].id,
        }));
        // if (!this.state.flags[c.t]) {
        //   const _c = brokerNames.map( b => c.b.find(e => e.name === b));
        //   const min = Math.min(..._c.map(e => e.commission));
        //   flags[c.t] = _c.map((e, i) => ({
        //     min: e.commission === min,
        //     sel: false,
        //   }));
        //   newComms.push(c);
        // }
        // else {
        //   flags[c.t] = this.state.flags[c.t];
        // }
      })
      this.props.doRegisterHandler(!brokers.some(this.selectable));
      // console.log(brokers, brokerNames);
      // console.log(flags);
      this.setState({ brokers, brokerNames, flags }, () => {
        // newComms.forEach(e => this.selectBroker(this.state.brokers[0], e.t));
        // newComms.forEach(e => this.selectBroker(brokers[0], e.t));
        this.selectBroker(brokers[0])
      });
      this.showPendingAccount()
    }
    // console.log(newComms);
  }

  showPendingAccount = () => {
    let { profile } = this.props
    if (!isEmpty(profile.apex)) {
      let apex_Status = profile.apex.account[0].status
      let apex_active = profile.apex.account[0].is_active
      if (apex_Status !== 'COMPLETE' || !apex_active) {
        this.setState({
          showBalance: false,
          noBankAccount: false,
          showPendingAccount: true
        })
      } else if (apex_Status === 'COMPLETE' && apex_active) {
        if (!profile.apex.bank || (profile.apex.bank && profile.apex.bank.length === 0)) {
          this.setState({
            showBalance: false,
            showPendingAccount: false,
            noBankAccount: true
          })
        }
      }
    }
  }

  selectBroker = (broker, ticker, accNumber) => {
    if (!this.selectable(broker))
      return;

    var bi = this.state.brokerNames.indexOf(get(broker, 'name'));
    var brokers = this.state.brokers.slice(0);
    brokers.forEach(e => e._all = false);

    if (ticker) {
      this.state.flags[ticker].forEach((e, i) => e.sel = i === bi);
      // this.state.flags[ticker].forEach((e, i) => e.account_number = i === bi ? account_number : null );
      brokers.forEach((b, _bi) => {
        b._any = !!Object.keys(this.state.flags).map(t => this.state.flags[t][_bi]).find(e => e.sel);
      })
    }
    else {
      brokers.forEach(e => e._any = false);
      // fails when no internet connection
      if (broker) {
        broker._all = true;
      }
      Object.keys(this.state.flags).forEach(t => {
        this.state.flags[t].forEach((e, i) => e.sel = i === bi);
      })
    }

    this.setState({ brokers });
  }

  selectOptimum = () => {
    if (!this.state.brokers.some(this.selectable))
      return;

    var comms = this.props.items.map(e => ({ t: cn(e, 'Ticker'), b: e.brokers }));
    comms.forEach(c => {
      var flag = this.state.flags[c.t];
      var min = -1;  // index of first min
      if (flag) {
        flag.forEach((e, i) => {
          if (!e.disabled && e.min && min === -1) {
            min = i;
          }
        })
        min >= 0 && this.selectBroker(this.state.brokers[min], c.t);
      }
    })
  }

  amtHandler = (amt) => {
    this.setState({ amt });
  }

  checkIfErrorExist = (funds) => {
    return !funds.every(v => {
      let realPrice = v.realPrice || cn(v, 'Nav Price');
      if (getNest(['_selectedType'], v) === "sell") {
        const getMyHoldingIFExists = this.props.sfm.myportfolio ? this.props.sfm.myportfolio.filter(val => val.script === v.ticker) : [];
        const myHoldingValue = getNest(['vehicle'], v) === "BASKET" ? parseFloat(get(getMyHoldingIFExists, '[0].market_value')).toFixed(5) : parseFloat(get(getMyHoldingIFExists, '[0].shares')).toFixed(5);

        if (myHoldingValue && getNest(['vehicle'], v) === "BASKET" && parseFloat(get(v, '_order.tvalue')) > myHoldingValue) {
          // debugger
          return false;
        } else if (myHoldingValue && parseFloat(get(v, '_order.tvalue')) > myHoldingValue * realPrice) {
          // debugger
          return false;
        }
      }
      if (getNest(['_selectedType'], v) === "buy") {
        // console.log(getNest(['_order', 'tvalue'], v), get(v, 'minimum_investment'));
        if (MIN_INVESTMENT_FUNDS.includes(getNest(['vehicle'], v)) && getNest(['_order', 'tvalue'], v) < get(v, 'minimum_investment')) {
          return false;
        } else if (getNest(['_order', 'tvalue'], v) < get(v, 'minimum_investment')) {
          return false;
        }
      }
      return true;
    })
  }

  buyHandler = () => {
    // console.log(' buyHandler triggers from ItemList ');
    // console.log(this.props.items);
    const checkedFundsForExecuteButton = this.props.items.filter(item => getNest(['_checkedToExecute'], item));
    const qtyOrValue = checkedFundsForExecuteButton.filter(item => getNest(['_order', 'state', 'valid'], item))
    let isExecuteButtonDisabled = (checkedFundsForExecuteButton.length > 0 && checkedFundsForExecuteButton.length === qtyOrValue.length)

    // console.log("ani", this.props.items, qtyOrValue)

    //let valid = this.props.items.some(e => getNest(['_order', 'state', 'valid'], e) && getNest(['_checkedToExecute'], e));

    let mfCount = this.props.items.filter(e => {
      return !comingSoonValues.includes(getNest(['_order', 'state', 'type'], e)) && getNest(['_checkedToExecute'], e) && getNest(['_order', 'state', 'valid'], e)
    });



    //  console.log('isExecuteButtonDisabled ==>', mfCount);
    if (mfCount.length === 0) {
      this.props.activateHandler(false);
    } else {
      this.props.activateHandler(isExecuteButtonDisabled);
      // let notValidFundQuantity = false;
      // const sellFunds = mfCount.filter(v => getNest(['_selectedType'], v) === "sell");
      // const buyFunds = mfCount.filter(v => getNest(['_selectedType'], v) === "buy");
      const notValidFundQuantity = this.checkIfErrorExist(mfCount);
      // console.log('=== notValidFundQuantity ===', notValidFundQuantity);
      // if(mfCount.every(v => get(v, '_order.action') === 'sell_all')){
      //   notValidFundQuantity = false;
      // }

      // if(buyFunds.length){
      //   const buyValidate = buyFunds.every(x=> {
      //     if(MIN_INVESTMENT_FUNDS.includes(getNest(['vehicle'], x))){
      //       return getNest(['_order', 'tvalue'], x) >= get(x, 'minimum_investment');
      //     }
      //     return (getNest(['_order', 'tvalue'], x) >= 5)
      //   });
      //   let sellValidate = true;
      //   if(sellFunds.length){
      //     sellValidate = sellFunds.every(x=> {
      //       if(get(x, '_order.action') === 'sell_all'){
      //         return true;
      //       }
      //       return getNest(['_order', 'tvalue'], x) !== "";
      //     });
      //   }
      //   if(!buyValidate || !sellValidate){
      //     notValidFundQuantity = true;
      //   }
      // }
      this.props.handleGreaterValue(notValidFundQuantity);

    }
    // if(!buyFunds.length && condition){
    //   notValidFundQuantity = false;
    // }else if(buyFunds.length){
    //   const isValuegreaterThanFive = !mfCount.filter(v => getNest(['_selectedType'], v) === "buy").every(x=>getNest(['_order', 'tvalue'], x) >= 5);
    //   const isValuegreaterThanBasketTradeMinValue = !mfCount.filter(v => getNest(['_selectedType'], v) === "buy" && getNest(['type'], v) === "BASKET" || getNest(['vehicle'], v) === "BASKET").every(x=>getNest(['_order', 'tvalue'], x) >= get(x, 'minimum_investment'));
    //   notValidFundQuantity = (isValuegreaterThanFive || isValuegreaterThanBasketTradeMinValue);
    // }

    // const isValuegreaterThanFive = !mfCount.filter(v => getNest(['_selectedType'], v) === "buy").every(x=>getNest(['_order', 'tvalue'], x) >= 5);
    // const isValuegreaterThanBasketTradeMinValue = !mfCount.filter(v => getNest(['_selectedType'], v) === "buy" && getNest(['type'], v) === "BASKET" || getNest(['vehicle'], v) === "BASKET").every(x=>getNest(['_order', 'tvalue'], x) >= get(x, 'minimum_investment'));

    // const checkEveryFundSell = !mfCount.filter(v => getNest(['_selectedType'], v) === "sell").every(x=> getNest(['_order', 'tvalue'], x) >= get(x, 'minimum_investment'));


    // let valid2 = this.props.items.some(e => getNest(['_order', 'state', 'valid2'], e) && getNest(['_checkedToExecute'], e));
    // const checkedFundsForTT = this.props.items.filter(e => getNest(['_order', 'state', 'valid2'], e) && getNest(['_checkedToExecute'], e));
    // let isTTButtonDisabled = checkedFundsForTT.length > 0
    // //this.props.activateHandler(valid);
    // // console.log(this.props.items);
    // console.log('isTTButtonDisabled ==>', isTTButtonDisabled);
    this.props.activateHandler2(isExecuteButtonDisabled);
  }

  accountHandler = (broker, account_number, ticker) => {
    var bi = this.state.brokerNames.indexOf(broker.name);
    var brokers = this.state.brokers.slice(0);
    brokers.forEach(e => e._all = false);

    if (ticker && this.state.flags[ticker][bi].sel) {
      this.state.flags[ticker].forEach((e, i) => e.account_number = i === bi ? account_number : null);
    }
    else {
      Object.keys(this.state.flags).forEach(t => {
        this.state.flags[t].forEach((e, i) => {
          if (i === bi) {
            return e.account_number = account_number;
          }
        });
      })
    }
  }

  addBroker = () => {
    addEventToAnalytics('Act Add Broker', 'Act Add Broker', 'ACT_ADD_BROKER', { category: 'advisory_PTC' }, true);
  }

  toggleAddMoneyPopup = () => {
    this.setState({
      showAddMoneyPopup: !this.state.showAddMoneyPopup
    })
  }

  showAddMoneyText = () => {
    if (!this.state.showAddMoney) {
      this.props.items.forEach(item => {
        if (item._brokerFlags && item._brokerFlags.length) {
          item._brokerFlags.forEach(broker => {
            if (broker.id === 14 && broker.sel === true) {
              this.setState({
                showAddMoney: true
              })
            }
          })
        }
      })
    }
  }

  selectAllFundsToExecute = () => {
    const { setSFMState, items } = this.props;
    items.forEach(item => {
      item._checkedToExecute = true;
    })
    if (setSFMState) {
      setSFMState({
        items,
      });
    }
    this.buyHandler();
  }

  selectFundToExecute = (checkedStatus, cardTicker) => {
    const { setSFMState, items } = this.props;
    items.forEach(item => {
      if (item.ticker === cardTicker) {
        item._checkedToExecute = checkedStatus;
      }
    })
    if (setSFMState) {
      setSFMState({
        items,
      });
    }
    this.buyHandler();
  }

  data = list => {
    if (process.env.REACT_APP_SUB_DOMAIN === 'retail') {
      return list.filter(e => e.not_tradable !== "1.0")
    }
    return list
  }

  render() {
    const items = this.props.items.filter(e => getNest(['_order', 'stage'], e) === 'buy');
    const profile_self_role = window.localStorage.getItem('profile_self_role') ? window.localStorage.getItem('profile_self_role') : 'loggedOut';
    if (items.length === 0) return null;
    const sel = (b) => b._all || b._any;
    const { magnifiTickers, fetchingAllTickersList, profile } = this.props;
    const isBrokerRegister = false;
    const { brokers, showAddMoneyPopup } = this.state;

    const registeredBrokerArr = brokers.filter((item) => item.status !== 'Register');
    let _magnifiTickers = magnifiTickers.filter((kl) => !this.props.items.find((l) => l.ticker === kl.ticker));

    const isBrokerRegistered = registeredBrokerArr.length ? true : false;

    // const showConnect = () => {
    //   if (process.env.REACT_APP_SUB_DOMAIN === 'retail') {
    //     return !items.some(fund => fund.not_tradable === "1.0")
    //   }
    //   return true
    // }

    // if (!isBrokerRegistered) {
    //   console.log('===== Broker is not registered ! ======', isBrokerRegistered);
    //   console.log(brokers);
    // }

    let DataList = items;

    if (items.find((y) => y._showSponsoredOnSFM)) {
      const fundsThatisSponsored = items.filter((s) => s._showSponsoredOnSFM);
      const fundsThatisNotSponsored = items.filter((s) => !s._showSponsoredOnSFM);
      DataList = [...fundsThatisNotSponsored];
      if (fundsThatisSponsored.length) {
        fundsThatisSponsored.forEach((f, i) => {
          if (f.ticker && DataList.find((s) => s.ticker !== f.ticker)) {
            DataList.unshift(f);
          }
        });
      }
    }

    const addBrokerDesign = (
      <div style={{ display: 'flex', width: '100%' }}>
        <div className="display-flex-col col-85">
          {this.data(DataList).map((e, i) => {
            return (
              <div key={cn(e, 'Id')}>
                <ItemCard
                  {...this.props}
                  card={e}
                  numberOfItems={DataList.length}
                  flag={this.state.flags[cn(e, 'Ticker')]}
                  idx={i}
                  checkedToExecute={e._checkedToExecute}
                  brokers={registeredBrokerArr}
                  selectBroker={this.selectBroker}
                  clearHandler={this.props.clearHandler}
                  buyHandler={this.buyHandler}
                  accountHandler={this.accountHandler}
                  isBrokerRegistered={isBrokerRegistered}
                  amt={this.state.amt}
                  isMF={e.vehicle}
                  selectFundToExecute={this.selectFundToExecute}
                  realTimePrices={this.state.realTimePrices}
                />
              </div>
            )
          })}
        </div>
        <div className="col-15 mb-2 border-0 add-broker-wrapper">
          <div className=" h-100 d-flex align-items-center justify-content-center" style={{ minHeight: '75px' }}>
            {isDemoUser()
              ? <div className="magnifi-broker">
                {RETAIL_USERS_ROLES.includes(profile_self_role) && (!isEmpty(profile.apex) && ((!profile.apex.bank || (profile.apex.bank && profile.apex.bank.length === 0)) && profile.apex.account && profile.apex.account[0] && profile.apex.account[0].status === 'COMPLETE' && profile.apex.account[0].is_active) ? <Fragment>
                  {/* <p className={'heading'}>Start Investing Today!</p> */}
                  <Link style={{ fontWeight: 600, fontSize: 12 }} to={{ pathname: "/settings/tradingAccount", linkaccount: true }}>Link your Bank Account</Link>
                </Fragment> : (!isEmpty(profile.apex) && ((!profile.apex.bank || (profile.apex.bank && profile.apex.bank.length === 0)) && profile.apex.account && profile.apex.account[0] && (profile.apex.account[0].status === 'PENDING' || profile.apex.account[0].status === 'ACTION_REQUIRED' || profile.apex.account[0].status === 'BACK_OFFICE' || profile.apex.account[0].status === 'REJECTED' || profile.apex.account[0].status === 'SUSPENDED' || (profile.apex.account[0].status === 'COMPLETE' && !profile.apex.account[0].is_active))) ? <Fragment>
                  <a href={'settings/tradingAccount'} style={{ fontWeight: 600, fontSize: 12 }}>Verification Pending</a>
                </Fragment> : <Fragment>
                  <p className={'heading'}>Start Investing Today!</p>
                  <p className={'subHeading'}>Create an Account.</p>
                  <Link to="/register/trading">
                    <Button className="add-broker-button" onClick={() => this.addBroker()}>
                      Magnifi
                    </Button>
                  </Link>
                </Fragment>))}
                {(!RETAIL_USERS_ROLES.includes(profile_self_role)) && (
                  <Link to="/settings/broker/select">
                    <Button className="add-broker-button SFM-connect-broker-btn" onClick={() => this.addBroker()}>
                      Connect Existing Broker
                    </Button>
                  </Link>
                )}
              </div>
              : <div className="add-broker-button" onClick={() => this.addBroker()}>
                <i className="fas fa-plus-circle" />
                Broker
              </div>}
          </div>
        </div>
      </div>
    );
    const showBrokerDesign = this.data(items).map((e, i) => {
      return (
        <div key={cn(e, 'Id')}>
          <ItemCard
            {...this.props}
            card={e}
            flag={this.state.flags[cn(e, 'Ticker')]}
            idx={i}
            numberOfItems={items.length}
            showBalance={this.state.showBalance}
            noBankAccount={this.state.noBankAccount}
            showPendingAccount={this.state.showPendingAccount}
            avaBalance={this.state.avaBalance}
            checkedToExecute={e._checkedToExecute}
            brokers={registeredBrokerArr}
            selectBroker={this.selectBroker}
            clearHandler={this.props.clearHandler}
            buyHandler={this.buyHandler}
            isBrokerRegistered={isBrokerRegistered}
            accountHandler={this.accountHandler}
            amt={this.state.amt}
            isMF={e.vehicle}
            selectFundToExecute={this.selectFundToExecute}
            toggleAddMoneyPopup={this.toggleAddMoneyPopup}
            realTimePrices={this.state.realTimePrices}
          />
        </div>
      )
    })
    // ->> /settings/broker/select?showBroker=Magnifi
    const renderDesign = isBrokerRegistered ? showBrokerDesign : addBrokerDesign;
    const brokerLabel = registeredBrokerArr.length > 1 ? 'Your Brokers' : 'Your Broker';
    return (
      <div style={{ height: '100%' }}>
        <div className="lh-125 display-flex-row align-items-center">
          <div className="col-40 add-money" >
            {/*
              {this.state.showBalance && <div>
                <div>
                  <span>Balance Available: </span>
                  <span> ${this.state.avaBalance}</span>
                </div>
                <span onClick={this.toggleAddMoneyPopup} className='add-money-btn'>ADD BALANCE</span>
              </div>}
              <div style={{ textAlign: 'left' }}>
                <span onClick={this.selectAllFundsToExecute} className='h6 text-gray-8 p-0 my-2 lh-100 btn btn-link add-money-btn'>SELECT ALL</span>
              </div>
            */}
          </div>
          <div className="flex-row-child col-20 padding-5-right-1">
            <div className="h-100">
              <Button color="secondary" block className="broker h-100 non-clickable">
                Orders
              </Button>
            </div>
            <div style={{ height: 30 }} />
            {/* <UnitsValue funds={this.props.funds} amtHandler={this.amtHandler} /> */}
          </div>
          <div className="flex-row-child col-20 padding-5-right-1">
            <div className="h-100">
              <Button color="secondary" block className="broker h-100 non-clickable">
                Information
              </Button>
            </div>
            <div style={{ height: 30 }} />
          </div>
          <div className="flex-row-child col-20 padding-5-right-1">
            <div className="h-100">
              <Button color="secondary" block className="broker h-100 non-clickable">
                Actions
              </Button>
            </div>
            <div style={{ height: 30 }} />
          </div>
          <div className="flex-row-child col-20 padding-5-right-1">
            <div className="h-100">
              <Button color="secondary" block className="broker h-100 non-clickable">
                {brokerLabel}
              </Button>
            </div>
            {!RETAIL_USERS_ROLES.includes(profile_self_role) ? <div className="text-center">
              {isDemoUser()
                ? <Link to="/settings/broker/select" className="h6 text-gray-8 p-0 my-2 lh-100 btn btn-link broker-link" style={isBrokerRegistered ? { visibility: 'visible' } : { visibility: 'hidden' }}>
                  + New Broker
                </Link>
                : <div className="h6 text-gray-8 p-0 my-2 lh-100 btn btn-link broker-link" style={isBrokerRegistered ? { visibility: 'visible' } : { visibility: 'hidden' }}>
                  + New Broker
                </div>}
            </div> : <div style={{ height: 30 }} />}
          </div>
        </div>
        <div className="item-card-area" style={{ flexDirection: isBrokerRegistered ? 'column' : 'row' }}>
          <div className="h-335 custom-scroll" style={{ width: '100%' }}>
            {renderDesign}
            <SearchCard
              {...this.props}
              loading={fetchingAllTickersList}
              magnifiTickers={_magnifiTickers}
            />
          </div>
        </div>
        {<AddMoneyPopup toggleAddMoneyPopup={this.toggleAddMoneyPopup} showAddMoneyPopup={showAddMoneyPopup} refreshApexAccountDetails={() => this.refreshApexAccountDetails()} />}
      </div>
    );
  }
}

ItemList.defaultProps = {
  brokers: []
}

export default ItemList;


export const fetchBalance = async () => {
  let balance
  try {
    let resp = await fetchApexBalance().then(result => result)
    // console.log(resp.data)
    balance = resp.data.balancePower; // resp.data.startDayCashAvailable
  } catch (e) {
    balance = 0
    // console.log(e)
  }
  return balance
}

export const fetchBalanceData = async () => {
  let balance = {}
  try {
    let resp = await fetchApexBalance().then(result => result)
    balance = resp.data;
  } catch (e) {
    console.log(e)
  }
  return balance
}

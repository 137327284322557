import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { addEventToAnalytics } from 'Utils';
import { apexRedirectionWhereTo } from 'layouts/utils';
import './startfreetrade.scss';

class StartFreeTrade extends Component {
  constructor(props) {
    super(props);
  }

  navigate = () => {
    const { history } = this.props;
    addEventToAnalytics('Start Trading For Free','Start Trading For Free','START_TRADING_FOR_FREE',{});
    try {
      if (this.props.loggedIn) {
        const { profile } = this.props;
        if (profile && profile.apex) {
          const apex_obj = profile.apex;
          const isApexEmpty = apex_obj && Object.keys(apex_obj).length === 0 && apex_obj.constructor === Object;
          if (!isApexEmpty) {
            const _apexRedirectionWhereTo = apexRedirectionWhereTo({ profile });
            if (_apexRedirectionWhereTo !== null && _apexRedirectionWhereTo !== 'Not to apex route') {
              if (history) {
                history.push(_apexRedirectionWhereTo)
              }
            } else {
              console.log('-- _apexRedirectionWhereTo fn returns null --');
              if (history) {
                history.push('/register/trading');
              }
            }
          } else {
            console.log('-- Apex object is empty --');
            if (history) {
              history.push('/register/trading');
            }
          }
        } else {
          throw '-- Profile || Profile.apex is missing --'
        }
      } else {
        throw '-- Not loggedIn --'
      }
    } catch (e) {
      console.log(e);
      if (history) {
        history.push('/register');
      }
    }
  }

  render() {
    if(process.env.REACT_APP_SUB_DOMAIN === 'retail'){
      return (
        <div className={this.props.parentClass ? `${this.props.parentClass} free-trade-option` : "free-trade-option"}>
          <div className={this.props.customclass ? `${this.props.customclass} free_trade_container` : "free_trade_container"}>
            <div className="free_trade_row">
              <div className="custom_col col_text">
                <h2>Unlock a World of Investing with a Magnifi Investment Account</h2>
              </div>
              <div className="custom_col col_button">
                <a onClick={() => this.navigate()} class="header-link custom-button-header nav-link">
                  <div class="custom-button">START INVESTING TODAY</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default StartFreeTrade;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu, Tooltip } from 'antd';
import _ from 'lodash'

import {
	getMyCurrentPortfolio,
	getReplacePortfolios,
} from 'actions/portfolio';

import {
	setEnhancerState,
	clearFinalizeDataRequest,
	getEnhanceStatsForSelectedTickerRequest
} from 'actions/enhancer';

import { fetchWeightsData } from 'actions/weights';
import { createLoadingSelector } from 'selectors/loading-selector';

import { addEventToAnalytics, getPortfolioBenchmarkOfSelectedTicker, jsonInURL } from 'Utils';
import { QUERY_MAP } from 'DataSet';

import SearchBox from 'layouts/WebDashboard/AccountsPage/Elements/SearchBox';

import { customScroll } from 'layouts/utils';
import { getCalculatedEnhanceStats } from 'layouts/WebDashboard/AccountsPage/utils'

import TopFeeds from '../../Elements/TopFeeds';
import UniverseSection from './UniverseSection';

import {
	modelReverseQuery,
	topQueryList,
	// FundUniverse,
	// ModelsPortfolios,
	SimilarityMeasures,
} from './utils';

class SideBar extends Component {
	constructor(props){
		super(props);
		this.state = {
			fundUniverse: props.fundUniverse || 'etf,etn,mf,cef',
			modelUniverse: props.modelUniverse || 'mp',
			similarityMeasures: props.similarityMeasures,
			switchFundState: (props.searchTypeMode === 'FUNDS'),
			searchTypeMode: props.searchTypeMode,
		}
	}

	componentDidMount() {
		const { searchTypeMode } = this.props;
		this.setState({
			switchFundState: (searchTypeMode === 'FUNDS'),
			searchTypeMode,
		});
	}

	componentWillReceiveProps(nextProps) {
		if (typeof nextProps.searchTypeMode !== 'undefined' && nextProps.searchTypeMode !== this.props.searchTypeMode) {
			this.setState({
				searchTypeMode: nextProps.searchTypeMode,
				switchFundState: (nextProps.searchTypeMode === 'FUNDS'),
			})
		}
	}

	componentDidUpdate() {
		customScroll();
		const staticElement = document.getElementById("sidebar-static-elements");
		const headerElement = document.getElementById("magnifi-header-wrapper");
		if (staticElement && headerElement) {
			const scrollableElement = document.getElementById("sidebar-scrollable-elements");
			if (scrollableElement) {
				let heightForList = headerElement.offsetHeight + staticElement.offsetHeight + 20 +"px"; // 20 is padddingTop for itemListHeaderWrapper
				scrollableElement.style.height = `calc(98vh - ${heightForList})`;
			}
		}
	}

	getQuery = query => {
		let mappedQuery = QUERY_MAP[query] || query;
		return mappedQuery;
	}

	switchFundStateHandle = (switchFundState) => {
		this.setState({
			switchFundState: !this.state.switchFundState,
			searchTypeMode: !this.state.switchFundState ? 'FUNDS' : 'MODELS',
		}, () => {
			addEventToAnalytics('Enhancer Search Type','Enhancer Search Type','ENHANCER_SEARCH_TYPE',{type: this.state.searchTypeMode},false);
			const val = (this.props.query.includes('model') && modelReverseQuery[this.props.query]) ? modelReverseQuery[this.props.query] : this.props.query;
			this.enhanceSearchQuery(val);
		})
	}

	modelDropDownChange = (data) => {
		if (data && data.key) {
			this.setState({
				modelUniverse: data.key,
			}, () => {
				addEventToAnalytics('Models Dropdown','Models Dropdown','MODELS_DROPDOWN',{modelUniverse: this.state.modelUniverse},false);
				const { setEnhancerState } = this.props;
				if (setEnhancerState) {
					setEnhancerState({
						fundUniverse: this.state.fundUniverse,
					});
				}
				this.triggerModalQueryForChange();
			})
		}
	}

	triggerModalQueryForChange = () => {
		if (this.props.query !== '' && !this.state.switchFundState) {
			const { query, setEnhancerState, callbackPath } = this.props;
			const { searchTypeMode, fundUniverse, modelUniverse, similarityMeasures } = this.state;
			if (setEnhancerState) {
				let _payloadRequest = {
					searchTypeMode,
					fundUniverse,
					modelUniverse,
					similarityMeasures,
					query,
					query_english: this.getQuery(query) || query,
				}
				if (callbackPath && callbackPath !== '') {
					_payloadRequest.callbackPath = callbackPath === 'go' ? 'client' : 'builder';
				}
				setEnhancerState({
					..._payloadRequest,
				});
				this.execute({
					..._payloadRequest,
				});
			}
		}
	}

	universeDropDownChange = (data) => {
		if (data && data.key && this.state.fundUniverse !== data.key) {
			this.setState({
				fundUniverse: data.key,
			}, () => {
				addEventToAnalytics('Funds Dropdown','Funds Dropdown','FUNDS_DROPDOWN',{fundUniverse: this.state.fundUniverse},false);
				const { setEnhancerState } = this.props;
				if (setEnhancerState) {
					setEnhancerState({
						fundUniverse: this.state.fundUniverse,
					});
				}
				this.triggerFundsQueryForChange();
			});
		}
	}

	triggerFundsQueryForChange = () => {
		const { query, setEnhancerState, callbackPath } = this.props;
		if (query !== '') {
			const { searchTypeMode, fundUniverse, modelUniverse, similarityMeasures } = this.state;
			if (setEnhancerState) {
				let _payloadRequest = {
					searchTypeMode,
					fundUniverse,
					modelUniverse,
					similarityMeasures,
					query,
					query_english: this.getQuery(query) || query,
				}
				if (callbackPath && callbackPath !== '') {
					_payloadRequest.callbackPath = callbackPath === 'go' ? 'client' : 'builder';
				}
				setEnhancerState({
					..._payloadRequest,
				});
				this.execute({
					..._payloadRequest,
				});
			}
		}
	}

	similarityMeasuresOverlay = (data) => {
		if (data && data.key && data.key !== this.state.similarityMeasures) {
			this.setState({
				similarityMeasures: data.key,
			}, () => {
				addEventToAnalytics('Enhancer Similarity Measures','Enhancer Similarity Measures','ENHANCE_SIMILARITY_MEASURES',{measure: this.state.similarityMeasures},false);
				const { query, setEnhancerState, callbackPath } = this.props;
				if (query) {
					const { searchTypeMode, fundUniverse, modelUniverse, similarityMeasures } = this.state;
					if (setEnhancerState) {
						let _payloadRequest = {
							searchTypeMode,
							fundUniverse,
							modelUniverse,
							similarityMeasures,
							query,
							query_english: this.getQuery(query) || query,
						}
						if (callbackPath && callbackPath !== '') {
							_payloadRequest.callbackPath = callbackPath === 'go' ? 'client' : 'builder';;
						}
						setEnhancerState({
							..._payloadRequest,
						});
						this.execute({
							..._payloadRequest,
						});
					}
				}
			})
		}
	}

	scoreAttrsWeightHandler = (selectedCalculator, universe) => {
		const { setEnhancerState } = this.props;
		if (selectedCalculator !== '' && typeof selectedCalculator !== 'undefined') {
			if (setEnhancerState) {
				// console.log('-- setEnhancerState selectedCalculator --', selectedCalculator, universe);
				// hotfix patch
				if (universe && typeof universe !== 'undefined' && universe.length > 0) {
					// add weight in FUNDS url
					setEnhancerState({
						selectedWeightOptions: {
							name: selectedCalculator,
							value: selectedCalculator,
							code: selectedCalculator,
						},
					});
				} else {
					// add weight from FUNDS url
					setEnhancerState({ selectedWeightOptions: {} });
				}
			}
			this.setState({
				wightValueChange: true
			}, () => {
				setTimeout(() => {
					this.setState(() => ({ wightValueChange: false }))
				}, 3000);
				const { query } = this.props;
				if (query !== '') {
					console.log('-- calling enhanceSearchQuery --');
					this.enhanceSearchQuery(query);
				}
			});
		}
	}

	enhanceSearchQuery = (q) => {
		console.log('enhanceSearchQuery ==> ', q, this.state.searchTypeMode);
		if (q) {
			const { searchTypeMode, fundUniverse, modelUniverse, similarityMeasures } = this.state;
			const { setEnhancerState, selectedWeightOptions, callbackPath } = this.props;
			if (setEnhancerState) {
				let _payloadRequest = {
					searchTypeMode,
					fundUniverse,
					modelUniverse,
					similarityMeasures,
					query: q,
					query_english: this.getQuery(q) || q,
				}
				// console.log("callbackPath", callbackPath);
				if (callbackPath && callbackPath !== '') {
					_payloadRequest.callbackPath = callbackPath === 'go' ? 'client' : 'builder';
				}
				// console.log(_payloadRequest);
				// console.log(selectedWeightOptions);
				setEnhancerState({
					..._payloadRequest,
				});
				this.execute({
					..._payloadRequest,
					selectedWeightOptions,
				});
			}
		} else {
			// NOTE: Lets show CUSTOM REPLACEMENT options for MODELS, without firing a "Query"
			// ------------------------------------------------------------------------------------
			const { searchTypeMode, fundUniverse, modelUniverse, similarityMeasures } = this.state;
			const { setEnhancerState, selectedWeightOptions, callbackPath } = this.props;
			if (setEnhancerState) {
				let _payloadRequest = {
					searchTypeMode,
					fundUniverse,
					modelUniverse,
					similarityMeasures,
				}
				// console.log("callbackPath", callbackPath);
				if (callbackPath && callbackPath !== '') {
					_payloadRequest.callbackPath = callbackPath === 'go' ? 'client' : 'builder';
				}
				// console.log(_payloadRequest);
				// console.log(selectedWeightOptions);
				setEnhancerState({
					..._payloadRequest,
				});
				this.execute({
					..._payloadRequest,
					selectedWeightOptions,
				}, true);
			}
			// ------------------------------------------------------------------------------------
		}
	}

	handlePerformanceData = (data) => {
		const {
			query,
			// summary,
			enhanceReplace,
			performanceImpact,
			// enhanceTickerFilter,
		 } = this.props.enhancer;

		  if(data.query !== query) {
				return
			}

		  if (performanceImpact && enhanceReplace) {
			let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(performanceImpact.upDownDataSet, performanceImpact.selectedBenchmark);
			let updatedArray = enhanceReplace.map((item) => {
			  return item.selectedPortfolioFunds
			});

			if (portfolioBenchmark && updatedArray) {
			  const STATS = getCalculatedEnhanceStats(updatedArray, query, portfolioBenchmark);
			  // console.log('========== PLOTTING PERFORMANCE IMPACT DATA ==========');
			  let enhancedRequestData = updatedArray.map((item) => {
					return {
					  ticker: item.ticker,
					  weight: item.weight,
					};
			  });

			  if (enhancedRequestData &&
					enhancedRequestData.length > 0 &&
					portfolioBenchmark &&
					STATS &&
					this.props.getEnhanceStatsForSelectedTickerRequest
			  ) {
					this.props.getEnhanceStatsForSelectedTickerRequest({
					  portfolioBenchmark,
					  enhancedRequestData,
					  ui_enhancedStats: STATS
					}, (res) => {
					  // console.log('------- getEnhanceStatsForSelectedTickerRequest res -------');
					  // console.log(res);
					  // console.log('-----------------------------------------------------------');
					});
			  }
			} else {
			  console.log('-- Failed to calculate portfolio benchmark data after deleting specify custom funds --');
			}
	  } else {
			console.log('-- Failed to calculate after deleting specify custom funds, missing performanceImpact --');
	  }
	}

	execute = (data, noQuery = false) => {
		// console.log(data);
		let { history, selectedPortfolioType, portfolioName, tickers, clearFinalizeDataRequest, finalizeData, callbackPath, sponsoredPortfolioName, selectedWeightOptions, activeSelectorUniverse } = this.props;
		if (clearFinalizeDataRequest && finalizeData && typeof finalizeData.tickers !== 'undefined' && typeof finalizeData.portfolioName !== 'undefined') {
			// console.log('-- clear finalize data before firing new query --');
			clearFinalizeDataRequest({manualClear: true}, () => {
				// console.log('-- cleared finalizeData --');
				this.handlePerformanceData(data);
			});
		}

		const {
			searchTypeMode,
			fundUniverse,
			modelUniverse,
			similarityMeasures,
			query,
			// query_english,
			// selectedWeightOptions: _selectedWeightOptions,
		} = data;
		let jObj = {}, urlParam = [];
		// console.log(selectedWeightOptions);
		// console.log(_selectedWeightOptions);
		// if (query === 'user_criteria' && selectedWeightOptions && selectedWeightOptions.value) {
		// 	jObj.weight = selectedWeightOptions.value;
		// }
		if (activeSelectorUniverse && activeSelectorUniverse.length > 0 && selectedWeightOptions && selectedWeightOptions.value) {
			jObj.weight = selectedWeightOptions.value;
		} else {
			delete jObj.weight;
		}
		// console.log("callbackPath", callbackPath);
		if (callbackPath && callbackPath !== '') {
			callbackPath = callbackPath === 'go' ? 'client' : 'builder';
		}
		if (history) {
			let redirectPathWillBe;
			jObj = {
				...jObj,
				"tickers": tickers,
				"portfolioId": portfolioName,
				"portfolioType": selectedPortfolioType,
				"similarity-measures": similarityMeasures,
			};
			switch (searchTypeMode) {
				case 'FUNDS':
					if (selectedPortfolioType === 'MarketPlace' && sponsoredPortfolioName && typeof sponsoredPortfolioName !== 'undefined' && sponsoredPortfolioName !== '' && sponsoredPortfolioName !== null) {
						jObj.sponsoredPortfolioName = sponsoredPortfolioName;
					}
					// console.log("callbackPath", callbackPath);
					if (callbackPath && callbackPath !== '') {
						jObj.callbackPath = callbackPath;
					}
					if (noQuery) {
						jObj = {
							...jObj,
							"portfolioId": portfolioName,
							"portfolioType": selectedPortfolioType,
						};
						redirectPathWillBe = jsonInURL(jObj, '/enhancer/dashboard/summary');
					} else {
						jObj = {
							...jObj,
							"q": query,
							"universe": fundUniverse,
						};
						redirectPathWillBe = jsonInURL(jObj, '/enhancer/dashboard/funds');
					}
					// console.log('redirectPathWillBe -> ', redirectPathWillBe);
					history.push(redirectPathWillBe);
					// for (var i in jObj){
					// 	urlParam.push(encodeURIComponent(i) + "=" + encodeURIComponent(jObj[i]));
					// }
					// history.push(`/enhancer/dashboard/funds?${urlParam.join("&")}`);
				break;
				case 'MODELS':
					if (noQuery) {
						jObj = {
							...jObj,
							"universe": modelUniverse,
						};
					} else {
						jObj = {
							...jObj,
							"q": query,
							"universe": modelUniverse,
						};
					}
					if (selectedPortfolioType === 'MarketPlace' && sponsoredPortfolioName && typeof sponsoredPortfolioName !== 'undefined' && sponsoredPortfolioName !== '' && sponsoredPortfolioName !== null) {
						jObj.sponsoredPortfolioName = sponsoredPortfolioName;
					}
					// console.log("callbackPath", callbackPath);
					if (callbackPath && callbackPath !== '') {
						jObj.callbackPath = callbackPath;
					}
					redirectPathWillBe = jsonInURL(jObj, '/enhancer/dashboard/models');
					// console.log('redirectPathWillBe -> ', redirectPathWillBe);
					history.push(redirectPathWillBe);
					// for (var i in jObj){
					// 	urlParam.push(encodeURIComponent(i) + "=" + encodeURIComponent(jObj[i]));
					// }
					// history.push(`/enhancer/dashboard/models?${urlParam.join("&")}`);
				break;
				default:
			}
		}
	}

	toggleHandler = ({
		searchTypeMode,
		modelUniverse,
		fundUniverse,
		switchFundState,
	}) => {
		// console.log('--- Sidebar toggleHandler ---');
		// const { fundUniverse, modelUniverse, similarityMeasures, searchTypeMode } = this.props;
		// const { similarityMeasures } = this.state;
		// console.log(this.state);
		this.setState({
			fundUniverse,
			modelUniverse,
			// similarityMeasures,
			searchTypeMode,
			switchFundState,
		}, () => {
			addEventToAnalytics('Enhancer Search Type','Enhancer Search Type','ENHANCER_SEARCH_TYPE',{type: this.state.searchTypeMode},false);
			const val = (this.props.query.includes('model') && modelReverseQuery[this.props.query]) ? modelReverseQuery[this.props.query] : this.props.query;
			this.enhanceSearchQuery(val);
		});
	}

	render() {
		const {
			query,
			loggedIn,
			// onBoardScreen,
			// activePortfolio,
			// setPfEnhancementContent,
			// selectedWeightOptions,
		} = this.props;
		// const  { btnError, switchFundState } = this.state;
		// const UniverseMenu = (
		// 	<Menu onClick={this.universeDropDownChange} selectedKeys={[this.state.fundUniverse]}>
		// 		{
		// 			FundUniverse.map((j) => (
		// 				<Menu.Item key={j.key}>
		// 					{j.label}
		// 				</Menu.Item>
		// 			))
		// 		}
		// 	</Menu>
		// );
		// const ModelsPortfolioMenus = (
		// 	<Menu onClick={this.modelDropDownChange} selectedKeys={[this.state.modelUniverse]}>
		// 		{
		// 			ModelsPortfolios.map((j) => (
		// 				<Menu.Item key={j.key}>
		// 					{j.label}
		// 				</Menu.Item>
		// 			))
		// 		}
		// 	</Menu>
		// );
		// const fundsUniverseText = FundUniverse.find((item) => item.key === this.state.fundUniverse) ? FundUniverse.find((item) => item.key === this.state.fundUniverse).label : ( this.state.fundUniverse === 'mf' ? 'Mutual Funds' : this.state.fundUniverse);
		// const modelUniverseText = ModelsPortfolios.find((item) => item.key === this.state.modelUniverse) ? ModelsPortfolios.find((item) => item.key === this.state.modelUniverse).label : this.state.modelUniverse;
		const SimilarityMeasuresOverlay = (
			<Menu onClick={this.similarityMeasuresOverlay} selectedKeys={[this.state.similarityMeasures]}>
				{
					SimilarityMeasures.map((j) => (
						<Menu.Item key={j.key}>
							{j.label}
							<Tooltip placement='right' title={j.tooltip}>
								<span id={`info-icon-${j.key}`} className='info-icon'><i className="fal fa-info-circle"/></span>
							</Tooltip>
						</Menu.Item>
					))
				}
			</Menu>
		);

		const selectedSimilarityMeasuresLabel = SimilarityMeasures.find((kl) => kl.key === this.state.similarityMeasures) ? SimilarityMeasures.find((kl) => kl.key === this.state.similarityMeasures).label : this.state.similarityMeasures;

		return (
			<React.Fragment>
				<div className="sideBarWrapper">
					<div id="sidebar-static-elements">
						<div className="element">
							<div className="label">How would you like to enhance your portfolio?</div>
							<div className="searchInput">
								<SearchBox
									queryHandler={this.enhanceSearchQuery}
									query={this.getQuery(query)}
									history={this.props.history}
									loggedIn={loggedIn}
									placeholder='Specify replacement objectives'
								/>
							</div>
						</div>
					</div>
						<div id="sidebar-scrollable-elements" className="custom-scroll sidebar-scroll-content">
							<div className="universe-container element">
								<UniverseSection
									query={query}
									SimilarityMeasuresOverlay={SimilarityMeasuresOverlay}
									SelectedSimilarityMeasuresLabel={selectedSimilarityMeasuresLabel}
									toggleHandler={this.toggleHandler}
									scoreAttrsWeightHandler={(val, universe) => this.scoreAttrsWeightHandler(val, universe)}
								/>
							</div>
							<div className="element">
								<div className="label">Popular Enhancements <span className="sub-label">(click on any to see results)</span></div>
								<TopFeeds
									queryList={topQueryList}
									queryHandler={this.enhanceSearchQuery}
								/>
							</div>
						</div>
					</div>
				</React.Fragment>
			)
		}
	}

	const mapStateToProps = ({ router, loading, auth, profile, weights, enhancer }) => ({
		enhancer: enhancer,
		loggedIn: auth.loggedIn,
		premiumUser: auth.user.premiumUser,
		location: router.location,
		profile: profile,
		scoreAttrs: weights.scoreAttrs || weights.myScoreAttrs,
		myScoreAttrs: weights.myScoreAttrs,
		query: enhancer.query,
		query_uuid:enhancer.query_uuid,
		portfolioName: enhancer.portfolioName,
		selectedPortfolioType: enhancer.onboardData.selectedPortfolioType,
		similarityMeasures: enhancer.similarityMeasures,
		searchTypeMode: enhancer.searchTypeMode,
		tickers: enhancer.tickers,
		finalizeData: enhancer.finalizeData,
		fundUniverse: enhancer.fundUniverse,
		modelUniverse: enhancer.modelUniverse,
		callbackPath: (enhancer.redirectFrom && enhancer.redirectFrom.module) ? enhancer.redirectFrom.module : '',
		selectedWeightOptions: enhancer.selectedWeightOptions,
		sponsoredPortfolioName: enhancer.sponsoredPortfolioName,
		activeSelectorUniverse: enhancer.activeSelectorUniverse,
	});

	const mapDispatchToProps = {
		getReplacePortfolios,
		getMyCurrentPortfolio,
		fetchWeightsData,
		setEnhancerState,
		clearFinalizeDataRequest,
		getEnhanceStatsForSelectedTickerRequest
	}

	export default connect(
		mapStateToProps,
		mapDispatchToProps,
	)(SideBar);

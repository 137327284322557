import React, { Component } from 'react'
import { Redirect, Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Button,
  ButtonGroup,
  Form,
  Modal,
  ModalBody} from 'reactstrap'

import { PrimaryButton } from 'shared-components'
import { Spinner } from 'shared-components/'
import { addEventToAnalytics } from 'Utils';

export default class PasswordSuccessFul extends Component {

  handleAgree = () => {
    addEventToAnalytics(
      'Password Updated Successfully',
      'Password Updated Successfully',
      'PASSWORD_UPDATED_SUCCESSFULLY',
      { token: this.props.verifypwdtoken || '' }
    )
    this.props.history.push('/login')
  }

  render() {
    const {
      isFetching,
      isTermsAgreed,
      isNewUser,
      isDisagreeFetching
    } = this.props

    return (
      <Modal
        isOpen={true}
        centered
        className="password-process-modal modal-dialog-centered"
        backdropClassName="password-process-modal-backdrop"
      >
        <Spinner isFetching={isDisagreeFetching} />
        <Spinner isFetching={isFetching} />
        <Form className="password-form" noValidate={true}>
          <ModalBody className="ssf-modal__body">
            <div className="password-process-modal-container">
              <div className="header-wrapper">
                <h1 className="text-center">Password Updated Successfully</h1>
              </div>
              <br/>
              <ButtonGroup className="cta-wrapper justify-content-center">
                <PrimaryButton className="btn primary-btn" type="button" onClick={this.handleAgree} style={{ maxWidth: 150 }}>
                  OK
                </PrimaryButton>
              </ButtonGroup>
            </div>
          </ModalBody>
        </Form>
      </Modal>
    )
  }
}
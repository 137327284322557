import React from 'react'
import { Alert, Col, Collapse, Row, Container, Form, FormGroup, Label, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup } from 'reactstrap'
import { PrimaryButton } from 'shared-components'

class ContinueWithPlanModal extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.modalStatus}
          toggle={() => {this.props.changeContinueWithPlanModalStatus(false)}}
          centered
          keyboard={true}
          backdrop
          className="premium-msg-modal-sm-light"
          backdropClassName="premium-feature-process-modal-backdrop"
          >
          <ModalBody className="ssf-modal__body">
            <div className="premium-feature-process-modal-container">
              <div className="header-wrapper">
                <h1 className="text-align-left premium-feature-main-heading">You are already a {(this.props.premiumUser) ? 'Premium' : 'Basic'} subscriber!</h1>
              </div>
              <i className="fal fa-times-circle premium-feature-completed-close close-light" onClick={() => {this.props.changeContinueWithPlanModalStatus(false)}}></i>
              <div className="pcm__description">
                { this.props.premiumUser && <p>Continue to access the advanced features.</p> }
              </div>
              <div className="profile__subscription-button">
                <Container>
                  <Row>
                    <Col xs={12}>
                      <FormGroup className="pcm__button-group">
                        <Button color="tools"
                          className="btn h3 text-uppercase font-weight-bold p-3 mb-0 ml-auto primary-btn"
                          onClick={() => {this.props.changeContinueWithPlanModalStatus(false)}}
                        >
                          CONTINUE
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default ContinueWithPlanModal

import React, { useContext } from 'react'
import { Row, Col, Table } from 'reactstrap'
// import ActReportContext from '../ActReportContext';
// import moment from 'moment'
import {
  Coverage_Logo,
  Coverage_poweredByLogo,
  ProposalCoverImage
} from '../../../common/constant'
import { Disclosures } from './Disclosures'
// import { haveHTMLData } from 'Utils';
// const Logo = Coverage_Logo;
const poweredByLogo = Coverage_poweredByLogo

const CoverPage = props => {
  const { disclosures, createdDate } = props
  // const { isCompareOnly } = useContext(ActReportContext);
  // const layout1 = {
  //   title: 'Selector',
  //   description: `Magnifi is changing the way we shop for investments. The world's first semantic search engine for finance helps discover, compare and buy investment products such as ETFs, Mutual funds etc.`,
  //   subContent: isCompareOnly ?
  //     'Here is a proposal to help you choose from the funds you have selected.' :
  //     'Here is a proposal to help you choose a fund by looking at its impact on the selected baseline portfolio.',
  //   logoPosition: 'right',
  // }
  const layout2 = {
    title: 'Manager Diligence Report',
    description: '',
    subContent:
      'This proposal is designed to select and compare available thematic investment options to determine which options meet your criteria and may help to enhance your portfolio.',
    logoPosition: 'bottom'
  }
  const finalLayout = layout2
  return (
    <React.Fragment>
      <div className="page">
        <div className="content cover">
          <div className="pr-v2-cover-page">
            <div>
              <img
                className={'cover-logo'}
                src={ProposalCoverImage}
                width={120}
                height={120}
              />
              {/* <div style={{ marginBottom: `22px` }} className={`title-name-logo ${finalLayout.logoPosition === 'bottom' ? 'layout-2' : 'layout-1'}`}>
                <h1>{finalLayout.title}</h1>
                <img style={{ height: `30px` }} src={poweredByLogo} alt="" />
              </div> */}
              <div>
                <p className="coverTitle">Manager Diligence Report</p>
                {createdDate && (
                  <p className="date">Data as of {createdDate}</p>
                )}
              </div>
              <Row>
                <Col>
                  {finalLayout.description ? (
                    <p className="paragraph">{finalLayout.description}</p>
                  ) : (
                    ''
                  )}
                  {finalLayout.subContent ? (
                    <p className="paragraph">{finalLayout.subContent}</p>
                  ) : (
                    ''
                  )}
                  <Disclosures disclosure={disclosures} />
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="cover-footer">
          <div className="glossary-footer text-justify">
            <div style={{ width: '100%' }}>
              {props.logo && (
                <img
                  className={'cover-logo'}
                  style={{ height: `75px` }}
                  src={props.logo}
                  alt=""
                />
              )}
            </div>

            <hr style={{ border: '1px solid #E4E7EC', margin: '20px  0' }} />

            <p className="pr-v2-medium-paragraph">
              There is no guarantee that any investment strategy illustrated
              will be successful or achieve any particular level of results.
              Please review the disclosures at the end of this document and
              consult your financial advisor for more information.
            </p>
            <br />
            {/* <span className="pr-v2-medium-paragraph">Magnifi LLC is an SEC registered investment adviser relying on rule 203A-2(e) for registration. Reference to registration with the Securities and Exchange Commission (“SEC”) does not imply that the SEC has endorsed or approved the qualifications of the firm to provide any advisory services described or that the firm has attained a level of skill or training.</span> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default CoverPage

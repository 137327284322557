import React, { Component } from 'react'
import { createAPIInstance } from 'apis/api'
import RecommendationReport from './RecommendationReport/RecommendationReport'
import ModelEnhancementReport from './ModelEnhancementReport'
import { processAndValidateUrls, sanitizeInput } from '../utils';

export const fetchReportData = async requestQuery => {
  return createAPIInstance()
    .get('/user/get-compare-reports', { params: { id: requestQuery } })
    .then(response => response.data)
}

class EnhancerInterimReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reportData: ''
    }
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search)
    const rid = query.get('id')
    if (!rid) return
    fetchReportData(rid).then(
      reportData => {
        if (reportData && reportData.length > 0 && reportData.message !== 'No Data Present') {
          const __data = JSON.parse(reportData[0].json);
          // Get an array of URLs from the data
          // const urls = extractUrlsFromObject(reportData);
          // console.log('urls -> ', urls);
          // processAndValidateUrls(__data);
          const updatedData = sanitizeInput(__data);
          this.setState({ reportData: updatedData })
        }
      }
    )
  }

  render() {
    const { reportData } = this.state
    return (
      <React.Fragment>
        {reportData && <ShowReport data={this.state.reportData} />}
      </React.Fragment>
    )
  }
}

export default EnhancerInterimReport

const ShowReport = ({ data }) => {
  const { searchTypeMode } = data;
  return searchTypeMode === 'FUNDS' ? (
    <RecommendationReport data={data} />
  ) : (
    <ModelEnhancementReport data={data} />
  )
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, PopoverBody,Popover,PopoverHeader } from 'reactstrap';
import { withPopover } from '../../Elements/Popover';
import { quintileSize, quintileClass, addEventToAnalytics } from 'Utils';
import { cn, QuinItems } from 'DataSet';
import { Quintile, Pct } from '../common';

let classNames = require('classnames');

// const CUMULATIVE = 'Cumulative Return'
const ANNUALIZED = 'Annualized Return'

class ReturnPopover extends Component {

  state={
    popoverOpen: false,
  }

  onHover = () => {
    !this.state.popoverOpen && this.setState({
      popoverOpen: true,
    },()=>{
      if (this.props.discovery && this.props.discovery.query && this.props.discovery.queryUUID && this.props.card && this.props.card.ticker) {
        addEventToAnalytics('Return Popup','Return Popup','RETURN_POPUP',{ query: this.props.discovery.query, queryUUID: this.props.discovery.queryUUID, ticker: this.props.card.ticker }, true);
      }
    })
  }

  onHoverLeave = () => {
    this.state.popoverOpen &&this.setState({
      popoverOpen: false,
    })
  }

  toggle=()=> {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    })
  }
  onMouseOver = () => {
    const element = document.querySelector('.popover-drop__item');
    element.style.display = 'block';
  }

  onMouseOverItem = () => {
    const element = document.querySelector('.popover-drop__item');
    element.style.display = 'block';
  }

  onMouseLeave = () => {
    const element = document.querySelector('.popover-drop__item');
    element.style.display = 'none';
  }

  render() {
    const { card, highlight, userReturnPrefer, setReturnPopover, icon } = this.props;
    const i = 3;
    const annReturnsIconQ = quintileSize(card._return_quin, QuinItems[i].reverse) ? (icon + quintileSize(card._return_quin, QuinItems[i].reverse)) : (icon + 1);
    const cumReturnsIconQ = quintileSize(card._return_cuml_quin, QuinItems[i].reverse) ? (icon + quintileSize(card._return_cuml_quin, QuinItems[i].reverse)) : (icon + 1);
    const returnStats = {
      label: (userReturnPrefer === ANNUALIZED) ? 'Annualized Return' : 'Cumulative Return',
      icon_name: (userReturnPrefer === ANNUALIZED) ? (card._return_quin ? annReturnsIconQ : (icon + 1)) : (card._return_cuml_quin ? cumReturnsIconQ : (icon + 1)),
      return_v: (userReturnPrefer === ANNUALIZED) ? card._return_v : card._return_cuml_v,
      return_quin: (userReturnPrefer === ANNUALIZED) ? card._return_quin : card._return_cuml_quin,
      catVal: (userReturnPrefer === ANNUALIZED) ? ((card._categorySeries && typeof card._categorySeries.yRet !== 'undefined') ? card._categorySeries.yRet.toFixed(2) : '--') : ((card._categorySeries && typeof card._categorySeries.cuml !== 'undefined') ? card._categorySeries.cuml.toFixed(2) : '--'),
      catDateRange: (card._returnsChart && card._returnsChart.start && card._returnsChart.end) ? `${card._returnsChart.start} - ${card._returnsChart.end}` : null,
      highlight : card._flags ? card._flags.returnsMax : false,
    };
    const hikeLabel = returnStats.return_quin ? (quintileSize(returnStats.return_quin, QuinItems[i].reverse) === 2 ? 'Average' : (quintileSize(returnStats.return_quin, QuinItems[i].reverse) === 3 ? 'High' : 'Low')) : '--';
    const statusText = returnStats.return_v ? (returnStats.return_v.toFixed(2) + "%") : '--';
    const highlightClassName = highlight ? 'search-fees-return-stats-padding hover-orange search-risk-stats-highlighting' : (returnStats.highlight ? 'search-fees-return-stats-padding search-risk-stats-highlighting' : 'search-fees-return-stats-padding');

    return (
      <div
        className={highlightClassName}
        onMouseEnter={this.onHover}
        onMouseLeave={this.onHoverLeave}
      >
        <div id={"ReturnPopover-" + cn(card, 'Id')} className={'w-100'} >
         <div className="returns-flag-color">
           <i className={classNames("far fa-fw fassf", returnStats.icon_name ) } />
           <p className="__val" style={{ margin: 0, fontSize: 11, lineHeight: 1, fontWeight: 'bold' }}>{statusText}</p>
           <p className="__state" style={{ margin: 0, fontSize: 10, lineHeight: 1 }}>{hikeLabel}</p>
         </div>
        </div>
        <Popover
          toggle={this.toggle}
          isOpen={this.state.popoverOpen}
          trigger={'hover'} placement="right"
          target={"ReturnPopover-" + cn(card, 'Id')}
        >
          <div className="pop-wrapper">
            <PopoverBody className="p-0 dddd">
              <Row className="no-gutters">
                <Col className="col-12 p-2 text-gray-9 bg-light br-2">
                  <div className={classNames("d-flex justify-content-between")}>
                    <div className="popover-drop">
                      <div className="popover-drop__toggle" onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}  >
                        <span className="pop-toggle-name">{returnStats.label}</span> <i className="fas fa-caret-down"></i>
                      </div>
                      <div className="popover-drop__item" onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}>
                        <ul className="popover-drop__item__wrapper">
                          {(userReturnPrefer === ANNUALIZED) ? (
                            <li className="popover-drop__item__wrapper__list" onClick={() => setReturnPopover('Cumulative Return')} id="Cumulative Return">Cumulative Return</li>
                          ): (
                            <li onClick={() => setReturnPopover('Annualized Return')} className="popover-drop__item__wrapper__list" id="Annualized Return">Annualized Return</li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <span className="font-weight-bold">{returnStats.return_v ? returnStats.return_v.toFixed(2) : '--'}<Pct /></span>
                  </div>
                  <div className="">
                    <Quintile
                      cardCategory={cn(card, 'Category')}
                      date={returnStats.catDateRange}
                      item={QuinItems[i]}
                      value={returnStats.return_quin}
                      catValue={(<span>{returnStats.catVal}<Pct /></span>)}
                      bg={returnStats.return_quin ? quintileClass(returnStats.return_quin, QuinItems[i].reverse) : null}
                    />
                  </div>
                </Col>
              </Row>
            </PopoverBody>
          </div>
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = ({ discovery }) => ({
  discovery,
})

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturnPopover)

import React, { Fragment } from 'react';
// import moment from 'moment';
// import { sortBy } from 'lodash';
import { Row, Col, Card as AntCard, Button } from 'antd';
import ProfileSubscriptionPlan from 'components/profile/ProfileSubscriptionPlan';
import { addEventToAnalytics } from 'Utils';
import { PLAN_NAME_MAPPING } from 'DataSet';

// let defaultPlans = DEFAULT_PLANS;

class SubscriptionCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFeatureName: '',
      openSubscriptionCancelModal: false
    }
    this.scheduleDemo = this.scheduleDemo.bind(this);
    this.onChangeModal = this.onChangeModal.bind(this);
  }

  onChangeModal(status) {
    this.setState({
      openSubscriptionCancelModal: status
    })
  }

  scheduleDemo = (featureName) => {
    // console.log('scheduleDemo for --> ', PLAN_NAME_MAPPING[featureName]);
    addEventToAnalytics(
      'Subscription Plan Schedule Demo Click',
      'Subscription Plan Schedule Demo Click',
      'SUBSCRIPTION_PLAN_SCHEDULE_DEMO_CLICK',
      { plan_name: PLAN_NAME_MAPPING[featureName] },
      true
    )
    this.setState({
      selectedFeatureName: featureName,
      openSubscriptionCancelModal: true,
    });
  }

  render() {
    const { defaultPlans, activatedFeatures } = this.props;
    // defaultPlans.forEach(item => {
    //   if (premiumUser && premiumFeatures.includes(item.featureName)) {
    //     item.isActivated = true;
    //     item.subscriptionNote = subscription && subscription.subscriber_since ? `Member since ${moment(subscription.subscriber_since).format('DD MMMM, YYYY')}` : '';
    //   } else if (!item.amount) {
    //     item.isActivated = true;
    //   } else {
    //     item.isActivated = false;
    //   }
    // });
    //
    // if (premiumUser) {
    //   let discoverCard = defaultPlans.find(e => e.featureName === 'discover');
    //   let notIncludingDiscover = defaultPlans.filter((l) => l.featureName !== 'discover');
    //   notIncludingDiscover = sortBy(notIncludingDiscover, ['isActivated']).reverse();
    //
    //   defaultPlans = [
    //     discoverCard,
    //     ...notIncludingDiscover,
    //   ];
    // }

    // let activatedFeatures = defaultPlans.filter(l => l.isActivated).map(k => k.featureName)

    const renderCard = defaultPlans.map(item => {
      return (
        <Col span={24} className="subscription-card-wrapper">
          <AntCard className={`subscription-card`}>
            <div className={`subscription-card-status-tag ${item.isActivated ? 'activated-arc' : ''}`}>
              <div className="subscription-card-status-text">
                {(item.isActivated) ? 'Activated' : item.planLabel}
              </div>
            </div>
            <Row className="subscription-card-row">
              <Col span={19} className="subscription-plan-desc-wrapper">
                <div className="subscription-plan-details">
                  <label>{item.title}</label>
                  <p>{item.desc}</p>
                </div>
                <div className="subscription-plan-date">
                  {/*item.subscriptionNote*/}
                </div>
              </Col>
              <Col span={5} className="subscription-plan-pricing-wrapper">
                {(item.type === 'Free Plan') ? (
                  <div className="empty-box subscription-card-free-plan-text">Free Plan</div>
                ) : (
                  !item.isActivated && (
                    <>
                      <div className="empty-box"></div>
                      <div className="subscription-plan-amount">
                        <span>
                          <sup>$</sup>
                          <strong>{item.amount}</strong>
                        </span>/ Month
                      </div>
                      <Button
                        className="subscription-plan-btn"
                        onClick={() => this.scheduleDemo(item.featureName)}
                      >
                        Schedule Demo
                      </Button>
                    </>
                  )
                )}
              </Col>
            </Row>
          </AntCard>
        </Col>
      )
    });
    return (
      <Fragment>
        <ProfileSubscriptionPlan
          profile={this.props.profile}
          modalType='scheduleDemo'
          activatedFeatures={activatedFeatures}
          featureName={this.state.selectedFeatureName}
          modalStatus={this.state.openSubscriptionCancelModal}
          onChangeModal={this.onChangeModal}
        />
        <Row gutter={[8, 24]} className="subscription-card-container">
          {renderCard}
        </Row>
      </Fragment>
    );
  }
}
export default SubscriptionCard;

// {(!premiumUser) ? (
//   <>
//     {(item.type === 'Free Plan') ? (
//       <div className="empty-box subscription-card-free-plan-text">Free Plan</div>
//     ) : (
//       <>
//         <div className="empty-box"></div>
//         <div className="subscription-plan-amount">
//           <span>
//             <sup>$</sup>
//             <strong>{item.amount}</strong>
//           </span>/ Month
//         </div>
//         <Button className="subscription-plan-btn">
//           Schedule Demo
//         </Button>
//       </>
//     )}
//   </>
// ) : (premiumUser && !item.isActivated && (
//   <>
//     <div className="empty-box"></div>
//     <div className="subscription-plan-amount">
//       <span>
//         <sup>$</sup>
//         <strong>{item.amount}</strong>
//       </span>/ Month
//     </div>
//     <Button
//       className="subscription-plan-btn"
//       onClick={() => this.scheduleDemo(item.featureName)}
//     >
//       Schedule Demo
//     </Button>
//   </>
// ))}

// absolute imports
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { addEventToAnalytics } from 'Utils';
import ModelSelectorDropdown from '../../Shared/ModelSelectorDropdown';
import {finalizeAllocationRequest,setSelectedPortfolio, pfHoldingsRequest,clearHoldingsRequest,searchAccFundsSuccess, modelSearchSuccess, modelSearchRequest } from 'actions/clientportfolioV2';
const ALLOCATION_PENDING='Allocation Pending';
class AccountDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showAllocationPending:true,
      IMG_OBJECT: {
        'Sponsor Models': '/assets/images/SponsorProvidedModel-White.svg',
        'My Models': '/assets/images/MyPortfolios-White.svg',
      },


    };
  }

  updateClientPfObj = (pfName, ticker, clientID, clientList, isGlobalSelect,finalizeAllocationRequest) => {
    this.props.setSelectedPortfolio({
      value: pfName,
      ticker: ticker,
      clientID: clientID,
      clientList: clientList,
      isGlobalSelect: isGlobalSelect,
      finalizeAllocationRequest:finalizeAllocationRequest
    });
  }

  deleteSelectedPortfolio = (id,disabled) =>{
    if(!disabled){
      this.sendSelectedPortfolio('','',id,false);
    }
  }

  sendSelectedPortfolio= (pfName, pfTicker, clientID, isGlobalSelect)=>{
    //this.props.modelSearchSuccess([]);
    //this.props.clearHoldingsRequest();
    //if(pfTicker || pfName) {
      try{
            //this.props.pfHoldingsRequest({ticker: pfTicker, file_name: pfName});
            addEventToAnalytics('Deleting Selected Model Allocation','Deleting Selected Model Allocation','DELETE_SELECTED_MODEL_ALLOCATION',true);
            this.updateClientPfObj(pfName, pfTicker, clientID, this.props.clientList, isGlobalSelect,this.props.finalizeAllocationRequest);
        }catch(e){
            console.log("Might be we are missing any parameter like pfName, pfTicker, clientID, isGlobalSelect");
        }
    //}
  }

  render() {
    const { client,clientChartDetails: {values},selectedClientDetails: { holdings,account_type,address,phone_number }, firstName, lastName,portfolioList} = this.props;
    const {showAllocationPending , IMG_OBJECT ,selectedModel}=this.state;

    const getModelName = (obj) => {
      // console.log('---- getModelName ----');
      // console.log(obj);
      // console.log('----------------------');
      if(obj) {
        if(obj.model_portfolio && Object.keys(obj.model_portfolio).length) {
          return obj.model_portfolio.portfolio_name;
        } else if(obj.custom_portfolio && Object.keys(obj.custom_portfolio).length) {
          return obj.custom_portfolio.portfolio_name;
        } else {
          return 'Allocation Pending';
        }
      } else {
        return 'Allocation Pending';
      }
    }

    return (
      <div className="account-details-wrapper" style={{marginTop: '5px'}}>
         <span className='model-selection'>Model Selection</span>
         {
           showAllocationPending && (<span className='allocation-pending'>
           {getModelName(client)} <span className='img-pointer' onClick={()=>this.setState({showAllocationPending:false})}><img src={'/assets/images/Edit_Primar_Blue.svg'}/></span>
           <span className={getModelName(client)===ALLOCATION_PENDING?'img-pointer-not-allowed':'img-pointer'} style={{float:'right',marginRight:'1%'}} onClick={()=>this.deleteSelectedPortfolio(client.id,getModelName(client)===ALLOCATION_PENDING)}><img  src={'/assets/images/TrashIcon.svg'}/></span>
          <hr/>
          </span>
           )
           }
          {
            !showAllocationPending && (
            <div>
              <ModelSelectorDropdown refID={client.id} sendSelectedPortfolio={this.sendSelectedPortfolio} selectedPortfolio={''} selectedClient={client}>
              </ModelSelectorDropdown>
            </div>
            )
          }
      </div>
    )
  }
}

const mapStateToProps = ({ clientportfolioV2: { selectedClientDetails,clientChartDetails ,clientList,
  finalizeObject },
  auth: { user: { firstName, lastName } } }) => ({
  selectedClientDetails,
  clientChartDetails,
  firstName,
  lastName,
  clientList,
  finalizeObject
})

const mapDispatchToProps = {
  clearHoldingsRequest,
  pfHoldingsRequest,
  searchAccFundsSuccess,
  modelSearchSuccess,
  modelSearchRequest,
  setSelectedPortfolio,
  finalizeAllocationRequest
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetails);

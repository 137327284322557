import React, { Component } from 'react';
import $ from 'jquery';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';
import { SuggestionThanks } from './utils';

class SuggestForm extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    document.body.classList['add']('modal-open-suggest')
    $('.suggest-backdrop').click(() => this.props.onClose) // hack as bockdrop click not working
  }

  componentWillUnmount() {
    document.body.classList['remove']('modal-open-suggest')
    $('.suggest-backdrop').unbind('click', this.props.onClose)
  }

  render() {
    return (
      <Modal
        autoFocus={false}
        isOpen={this.props.isOpen}
        backdrop={true}
        centered
        fade
        backdropClassName="suggest-backdrop"
        modalClassName="suggest"
        contentClassName="b-2"
        toggle={this.props.onClose}
        id="suggestModal"
      >
        {this.props.isOnFinal ? (
          <SuggestionThanks />
        ) : (
          <Form onSubmit={this.props.onSubmit} inline>
            <FormGroup className="m-1 p-0" style={{ flex: '2' }}>
              <Input
                type="text"
                name="suggestMessage"
                value={this.props.message}
                onChange={this.props.onMessageChange}
                id="suggestMessage"
                placeholder="Suggest a new data item"
                style={{ resize: 'none' }}
                required
                className="w-100 p-0 px-2"
                maxLength={32}
                rows={1}
                autoFocus
                width="auto"
              />
            </FormGroup>
            <Button
              type="submit"
              onClick={this.props.onSubmit}
              color="select"
              className="btn h4 p-1 h-100 text-uppercase font-weight-bold m-0 mr-1"
            >
              Submit
            </Button>{' '}
          </Form>
        )}
      </Modal>
    )
  }
}

export default SuggestForm;

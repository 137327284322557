import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { get, uniqBy } from 'lodash';
import { Card } from 'antd';

import { fetchProfile } from 'actions/profile';
import { getAccountsPortfolioDataByIDRequest, setAccountsState, cancelDepositsRequest, refreshApexStateRequest, cancelTradesRequest } from 'actions/accounts';
import { getSummaryFundsForReactState } from 'selectors/accounts';
import { createLoadingSelector } from 'selectors/loading-selector';

import { cn, CN } from 'DataSet';
import { getNestDefault, nameToDateRange, getPortfolioBenchmarkOfSelectedTicker, addEventToAnalytics, arrayToHash } from 'Utils'
import { calcCumulativeReturns } from 'Stats';

import Board from './Board';
import ContentHead from './ContentHead';
import PortfolioModal from '../../Elements/PortfolioModal';
import { geopMeanCalc } from '../../../AccountsPage/utils';
import { dropdownAndReturnSeries, apexRedirectionWhereTo, lineChartStartDate, dailyReturnSeries } from 'layouts/utils';

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      summaryDrawer: props.summaryDrawer,
    };
  }

  componentDidMount() {
    // console.log('========>>>>>> summary page componentDidMount <<<<<<========');
    const { location, onboardData, history, match, getAccountsPortfolioDataByIDRequest, loading, sponsoredPortfolioName, fetchProfile } = this.props;
    try {
      if (match && !loading) {
        const id = match.params.id
        if (location && location.search) {
          const params = new URLSearchParams(location.search)
          if (params) {
            const portfolioId = params.get('portfolioId');
            const portfolioType = params.get('portfolioType');
            const sponsoredPortfolioName = params.get('sponsoredPortfolioName');
            this.triggerApi({
              portfolioId,
              portfolioType,
              sponsoredPortfolioName,
            });
          } else {
            this.checkOnBoardData();
          }
        } else {
          this.checkOnBoardData();
        }
      } else {
        this.checkOnBoardData();
      }
    } catch (e) {
      console.log(e);
      const { history } = this.props;
      if (history) {
        const newPath = `/accounts/exception/404`
        history.push(newPath)
      }
    }
  }

  checkOnBoardData = () => {
    console.log('--- checkOnBoardData ---');
    const { accounts, profile: _prof } = this.props;
    const { sponsoredPortfolioName, onboardData } = accounts;
    if (onboardData) {
      const { selectedPortfolioPath, selectedPortfolioType } = onboardData;
      if (selectedPortfolioPath && selectedPortfolioPath !== '' && selectedPortfolioType && selectedPortfolioType !== '') {
        const jObj = {
          portfolioType: selectedPortfolioType,
          portfolioId: selectedPortfolioPath, // `MAGNIFI_${accountNo}`,
        };
        this.routeTo('My Portfolio', 'dashboard/summary', jObj);
      } else if (_prof && _prof.profile) {
        this.executeRedirection({ profile: _prof.profile });
      } else {
        throw 'data not found in profile state'
      }
    } else {
      throw 'data not found in url from onBoard state'
    }
    // calling 'fetchProfile' explictly for SFM trading functionality
    // if (fetchProfile) {
    //   fetchProfile('', (props) => {
    //     // console.log(props);
    //   });
    // }
  }

  executeRedirection = ({ profile }) => {
    const { history } = this.props;
    const _apexRedirectionWhereTo = apexRedirectionWhereTo({ profile });
    if (history && _apexRedirectionWhereTo !== null && _apexRedirectionWhereTo !== 'Not to apex route') {
      history.push(_apexRedirectionWhereTo);
    }
  }

  routeTo = (selectedPortfolioType, path, jObj) => {
    const { history, portfolioName, fetchProfile } = this.props;
    if (selectedPortfolioType && path && jObj) {
      if (history) {
        let urlParam = [];
        for (var i in jObj){
          urlParam.push(encodeURI(i) + "=" + encodeURI(jObj[i]));
        }
        const newPath = `/accounts/${path}?${urlParam.join("&")}`;
        history.push(newPath);
      }
    }
  }

  triggerApi = ({ portfolioId, portfolioType, sponsoredPortfolioName }) => {
    const { location, onboardData, history, match, getAccountsPortfolioDataByIDRequest, loading } = this.props;
    if (portfolioId &&
      portfolioType &&
      getAccountsPortfolioDataByIDRequest &&
      portfolioId !== 'undefined' &&
      portfolioType !== 'undefined') {
      let requestPayloadWillBe = {
        filename: portfolioId,
        portfolioType: portfolioType,
      };
      if (portfolioType === 'MarketPlace') {
        if (sponsoredPortfolioName && typeof sponsoredPortfolioName !== 'undefined' && sponsoredPortfolioName !== '' && sponsoredPortfolioName !== null) {
          requestPayloadWillBe = {
            filename: portfolioId,
            portfolioType: portfolioType,
            sponsoredPortfolioName,
          };
          getAccountsPortfolioDataByIDRequest(requestPayloadWillBe, (res) => {
            // console.log('=----------------=');
            // console.log(res);
            // console.log('=---- loaded ----=');
          });
        } else {
          throw 'sponsoredPortfolioName not found in url'
        }
      } else {
        getAccountsPortfolioDataByIDRequest(requestPayloadWillBe, (res) => {
          // console.log('=----------------=');
          // console.log(res);
          // console.log('=---- loaded ----=');
        });
      }
    } else {
      throw 'data not found in triggerApi params'
    }
  }

  selectReturnYear = (selectedYear, items) => {
    const { summary, setAccountsState } = this.props;
    const { retData, portfolioFunds } = summary;
    if (items && typeof items.ticker !== 'undefined' && typeof retData !== 'undefined' && retData.length > 0) {
      const { returns, ticker } = items;
      if (selectedYear &&
        selectedYear !== '' &&
        ticker !== '' &&
        typeof returns !== 'undefined' &&
        typeof ticker !== 'undefined' &&
        typeof selectedYear !== 'undefined') {
        retData.forEach((e,index) => {
          // NOTE: IF YOU CHANGE ANYTHING IN THIS MAP FUNCTION,
          // --> MAKE SURE TO CHECK => map of "retData" => `getAccountsPortfolioDataById` function => in 'sagas/account.js' file as well
          if (e.returns.length > 0 && e.ticker === ticker) {
            e._selectedYear = selectedYear;
            let n = getNestDefault(['returns', 'length'], e, 0);
            if (n > 0) {
              let DARS = dailyReturnSeries({ returns: e.returns }) // dropdownAndReturnSeries({ returns: e.returns });
              if (DARS && DARS.dropdown && DARS.cumlSeries && DARS.cumlSeries[e._selectedYear]) {
                // NOTE: tooltip 'start_date' logic for line-chart
                // portfolioFunds.forEach((kq, i) => {
                //   if (kq && (kq.ticker || kq.script || kq.symbol) === e.ticker) {
                //     if (kq && DARS.outSeries && DARS.outSeries[e._selectedYear] && DARS.outSeries[e._selectedYear].length > 0) {
                //       // accounts page retData structure is not same as discovery fund's _returnsChart, so make it.
                //       let RETURNS_CHART_SERIES = DARS.outSeries[e._selectedYear];
                //       kq._returnsChart = {
                //         start: RETURNS_CHART_SERIES[0].d,
                //         end: RETURNS_CHART_SERIES[RETURNS_CHART_SERIES.length - 1].d,
                //         series: RETURNS_CHART_SERIES,
                //       };
                //       kq = lineChartStartDate(kq);
                //       if (typeof kq._tickerStartDate !== 'undefined' && kq._tickerStartDate) {
                //         RETURNS_CHART_SERIES = [
                //           { d: kq._tickerStartDate, v: 0, cuml: 0, cumlLog: 0 },
                //           ...RETURNS_CHART_SERIES,
                //         ];
                //       } else {
                //         // if _tickerStartDate not present then push default 'Start'
                //         RETURNS_CHART_SERIES = [
                //           { d: 'Start', v: 0, cuml: 0, cumlLog: 0 },
                //           ...RETURNS_CHART_SERIES,
                //         ];
                //       }
                //       // set return chart series
                //       e._series = RETURNS_CHART_SERIES;
                //     }
                //   }
                // });
                e._dropdown = DARS.dropdown;
                e._series = DARS.cumlSeries[selectedYear];
              }
            }
          }
        });
        let tickerWiseRet = arrayToHash(retData, CN['Ticker']);
        portfolioFunds.forEach((e,index) => {
          e.return = tickerWiseRet[e.ticker];
        });
        if (setAccountsState) {
          setAccountsState({
            summary: {
              ...summary,
              retData,
              portfolioFunds,
            },
          })
        }
      }
    }
  };

  cancelDepositsRequest = (props, reqType) => {
    const { cancelDepositsRequest, cancelTradesRequest } = this.props;
    if (props && cancelDepositsRequest) {
        if(reqType === "cancelSecurityTransaction"){
          cancelTradesRequest({ request_id : props.id});
        }else{
          cancelDepositsRequest(props);
        }
    }
  }

  render() {
    const {
      accounts,
      loading,
      fundPortfolios,
      query,
      queryEnglish,
      searchTypeMode,
      summary,
      portfolioName,
      onboardData,
      profile,
      smallScreen,
      history,
      apexBalanceData,
      refreshApexStateRequest,
    } = this.props;
    const { portfolioFunds } = summary;

    return (
      <React.Fragment>
        {!loading && (
          <div className="accounts-summary-container">
            <div className="accounts-page-container">
              <ContentHead
                {...this.props}
              />             
              <Board
                profile={profile}
                history={history}
                smallScreen={smallScreen}
                portfolioName={portfolioName}
                summary={summary}
                apexBalanceData={apexBalanceData}
                retData={summary.retData}
                activeFund={summary.activeFund}
                onboardData={onboardData}
                funds={portfolioFunds}
                refreshApexStateRequest={refreshApexStateRequest}
                fundStats={getSummaryFundsForReactState(portfolioFunds)}
                selectReturnYear={this.selectReturnYear}
                cancelDepositsRequest={this.cancelDepositsRequest}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ loading, accounts, portfolio, sfm, funds, profile, global }) => ({
  accounts,
  // sfm,
  summary: accounts.summary,
  query: accounts.query,
  apexBalanceData: accounts.apexBalanceData,
  onboardData: accounts.onboardData,
  summaryDrawer: accounts.summaryDrawer,
  portfolioName: accounts.portfolioName,
  fundPortfolios: accounts.fundPortfolios,
  queryEnglish: accounts.query_english,
  searchTypeMode: accounts.searchTypeMode,
  guidanceMessage: accounts.guidanceMessage,
  profile: profile,
  smallScreen: global.smallScreen,
  loading: createLoadingSelector(['GET_ACCOUNTS_PORTFOLIO_BY_ID'])({ loading }),
})

const mapDispatchToProps = {
  getAccountsPortfolioDataByIDRequest,
  setAccountsState,
  cancelDepositsRequest,
  cancelTradesRequest,
  fetchProfile,
  refreshApexStateRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Summary);

import { get } from 'lodash'
import defaultConfig from './defaultConfig'
import dev from './dev'
import uat from './uat'
import prod from './prod'
import demo from './demo'
import research from './research'

const configMapper = {
  dev,
  uat,
  prod,
  demo,
  research,
}

export default {
  ...defaultConfig,
  ...configMapper[get(process.env, 'REACT_APP_ENV', '').trim()]
}

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import { createLoadingSelector } from 'selectors/loading-selector';
import { bondsBatchRequest } from 'actions/buildportfolio';

import BondsContent from './BondsContent';
import CardLoader from '../../../Elements/CardLoader';

class BondsTab extends Component {
  componentDidMount() {
    const { bondsBatchRequest, preSelectedTickers, activeTab, bondTabData, bondTabLoading, buildportfolio } = this.props;
    let preBondTickers = [];
    if (bondsBatchRequest) {
      if(preSelectedTickers && preSelectedTickers.length > 0) {
        preBondTickers = preSelectedTickers.filter(e => e.category == 'bonds');
      }
      if (bondTabData.length === 0) {
        bondsBatchRequest({ query: 'pf_builder_bonds', tab: 'bondTab', preBondTickers: preBondTickers.length > 0 ? preBondTickers : null, allStates: buildportfolio });
      }
    }
  }

  componentWillReceiveProps(newProps) {
    // console.log(newProps.buildportfolio);
    const { bondTabData, tabHeaderMenu, selectedFunds, buildportfolio } = newProps;

    if (buildportfolio.oldHeaderMenuState && typeof buildportfolio.oldHeaderMenuState !== 'undefined' && buildportfolio.oldHeaderMenuState.length) {
      const qw = buildportfolio.oldHeaderMenuState.filter((l) => l.key === 'bondTab')[0]; // 100
      const lw = buildportfolio.tabHeaderMenu.filter((k) => k.key === 'bondTab')[0]; // 60

      // console.log('old bondTab value-->>', qw.sliderValue, 'new bondTab value-->>', lw.sliderValue);

      if (qw.sliderValue !== lw.sliderValue) {
        if (Object.keys(selectedFunds) && Object.keys(selectedFunds).length > 0) {
          const diff = lw.sliderValue - qw.sliderValue;
          console.log('diff', diff);
          const getActiveFunds = Object.keys(selectedFunds).filter((ty) => (selectedFunds[ty].length > 0 && bondTabData.find((er) => er.query === ty).sliderValue > 0));
          // console.log(getActiveFunds);

          if (getActiveFunds.length > 0) {
            let oldSum = 0;
            if (buildportfolio.oldBondData) {
              console.log(buildportfolio.oldBondData);
              buildportfolio.oldBondData.forEach((er) => {
                console.log(er.sliderValue)
                oldSum += er.sliderValue;
              })
            }
            getActiveFunds.map((rt) => {
              if (rt) {
                const fd = bondTabData.find((er) => er.query === rt);
                // console.log(fd.query);
                if (fd) {
                  //USA sliderValue
                  // console.log(`${fd.query} sliderValue`);
                  // bondTabData.find((er) => er.query === rt).sliderValue = newProps.tabHeaderMenu.find((item) => item.key === 'bondTab').sliderValue/getActiveFunds.length;
                  console.log(oldSum, diff);
                  if(oldSum != diff){
                    if (getActiveFunds.length === 1) {
                      bondTabData.find((er) => er.query === rt).sliderValue = newProps.tabHeaderMenu.find((item) => item.key === 'bondTab').sliderValue/getActiveFunds.length;
                    } else if (getActiveFunds.length > 1 && buildportfolio.oldBondData && buildportfolio.oldBondData.find((er) => er.query === rt) && buildportfolio.oldBondData.find((er) => er.query === rt).sliderValue > 0) {
                      // console.log(buildportfolio.oldBondData.find((er) => er.query === rt).sliderValue);
                      // console.log(oldSum);
                      // console.log(diff);
                      // console.log((buildportfolio.oldBondData.find((er) => er.query === rt).sliderValue / oldSum)*diff);
                      bondTabData.find((er) => er.query === rt).sliderValue = buildportfolio.oldBondData.find((er) => er.query === rt).sliderValue + ((buildportfolio.oldBondData.find((er) => er.query === rt).sliderValue == 0 ? 0 :buildportfolio.oldBondData.find((er) => er.query === rt).sliderValue / oldSum)*diff);
                    } else if (getActiveFunds.length > 1 && buildportfolio.oldBondData && buildportfolio.oldBondData.find((er) => er.query === rt) && buildportfolio.oldBondData.find((er) => er.query === rt).sliderValue === 0) {
                      if (bondTabData.find((er) => er.query === rt).sliderValue > 0) {
                        bondTabData.find((er) => er.query === rt).sliderValue = newProps.tabHeaderMenu.find((item) => item.key === 'bondTab').sliderValue/getActiveFunds.length;
                      }
                    }
                  }

                  // USA selectedFunds sliderValue
                  // console.log(`${fd.query} selectedFunds sliderValue`);
                  // console.log(rt, selectedFunds[rt]);
                  if (selectedFunds[rt] && selectedFunds[rt].length > 0) {
                    selectedFunds[rt].map((o) => {
                      if (o) {
                        // console.log(o._sliderValue);
                        if(selectedFunds[rt].length == 1 ){
                          o._sliderValue = bondTabData.find((er) => er.query === rt).sliderValue/selectedFunds[rt].length;
                          // console.log(o._sliderValue);
                        }
                        else if(selectedFunds[rt].length > 1 && o._sliderValue > 0 && buildportfolio.oldBondTab.selectedFunds[rt] && _.sumBy(buildportfolio.oldBondTab.selectedFunds[rt], '_sliderValue') <= 0) {
                          // console.log('pratik 12.3')
                          o._sliderValue = bondTabData.find((er) => er.query === rt).sliderValue/selectedFunds[rt].length;
                          // console.log('pratik 12.3.1', o.ticker, o._sliderValue)
                        }
                        else if(selectedFunds[rt].length > 1 && o._sliderValue <= 0) {
                          // console.log('pratik 12.3')
                          o._sliderValue = bondTabData.find((er) => er.query === rt).sliderValue/selectedFunds[rt].length;
                          // console.log('pratik 12.3.1', o.ticker, o._sliderValue)
                        }
                        else if(selectedFunds[rt].length > 1 && o._sliderValue > 0) {
                          const oldFound = buildportfolio.oldBondTab.selectedFunds[rt].find(x => x.ticker == o.ticker);
                          if(oldFound){
                            let diff = bondTabData.find((er) => er.query === rt).sliderValue - buildportfolio.oldBondData.find((er) => er.query === rt).sliderValue;
                            let ratio = oldFound._sliderValue == 0 ? 0 : oldFound._sliderValue / buildportfolio.oldBondData.find((er) => er.query === rt).sliderValue;
                            let wt = diff * ratio;
                            o._sliderValue = oldFound._sliderValue + wt;
                            // console.log('pratik 121', oldFound, diff, ratio);
                          }
                        }
                      }
                    })
                  }

                  //USA  fund list sliderValue
                  // console.log(`${fd.query} fund list sliderValue`);
                  // bondTabData.map((l) => {
                  //   if (l.query === rt) {
                  //     l.result.funds.map((w) => {
                  //       w._sliderValue = 0;
                  //       // const found = getActiveFunds.find((o) => o.ticker === w.ticker);
                  //       if (rt.ticker === w.ticker) {
                  //         // console.log(rt, w.ticker);
                  //         w._sliderValue = l.sliderValue/selectedFunds[rt].length;
                  //       }
                  //     });
                  //   }
                  // });
                  bondTabData.map((l) => {
                    l.result.funds.map((w) => {
                      w._sliderValue = 0;
                      if (selectedFunds[l.query] && typeof selectedFunds[l.query] !== 'undefined') {
                        const found = selectedFunds[l.query].find((o) => o.ticker === w.ticker);
                        const oldFound = buildportfolio.oldBondTab.selectedFunds[l.query] && buildportfolio.oldBondTab.selectedFunds[l.query].find(x => x.ticker == w.ticker);
                        if (found && typeof found !== 'undefined' && (found.ticker === w.ticker)) {
                          if(selectedFunds[l.query].length == 1){
                            w._sliderValue = l.sliderValue/selectedFunds[l.query].length;
                          }
                          else if(selectedFunds[l.query].length > 1 && oldFound && oldFound._sliderValue > 0) {
                            if(oldFound){
                              // console.log('pratik 50', w, buildportfolio, oldFound, buildportfolio.oldBondData.find((er) => er.query === l.query).sliderValue)
                              let diff = l.sliderValue - buildportfolio.oldBondData.find((er) => er.query === l.query).sliderValue;
                              // console.log('pratik 51', w.ticker)
                              // console.log('pratik 52', w.ticker, oldFound.ticker, buildportfolio.oldBondTab.selectedFunds[l.query])
                              let ratio = oldFound._sliderValue == 0 ? 0 : oldFound._sliderValue / buildportfolio.oldBondData.find((er) => er.query === l.query).sliderValue;
                              let wt = diff * ratio;
                              w._sliderValue = oldFound._sliderValue + wt;
                              // console.log('pratik 151', oldFound, diff, ratio);
                            }
                          }
                          else if(selectedFunds[l.query].length > 1 && oldFound && oldFound._sliderValue <= 0) {
                            w._sliderValue = l.sliderValue/selectedFunds[l.query].length;
                          }
                        }
                      }
                    });
                  });

                }
              }
            })
          }
          else if(qw.sliderValue == 0 && Object.keys(selectedFunds).filter((ty) => (selectedFunds[ty].length > 0)).length > 0){
            // console.log('pratik 21')
            const selFunds = Object.keys(selectedFunds).filter((ty) => (selectedFunds[ty].length > 0));
            const diff = lw.sliderValue - qw.sliderValue;
            // console.log('pratik diff---->', diff);
            selFunds.map((rt) => {
              if (rt) {
                // console.log('pratik 22')
                const fd = bondTabData.find((er) => er.query === rt);
                // console.log('pratik 23', fd)
                if (fd) {
                  bondTabData.find((er) => er.query === rt).sliderValue = lw.sliderValue / selFunds.length;
                }
              }
            })
          }
        } else {
          console.log('EQUAL');
        }
        console.log('-----------------');
      } else {
        console.log('EQUAL');
      }
    }


    console.log(buildportfolio.oldBuildportfolioState);
    if (buildportfolio.oldBuildportfolioState && typeof buildportfolio.oldBuildportfolioState !== 'undefined') {

      const { oldBuildportfolioState } = newProps.buildportfolio;
      const oldBondTabSelectedFunds = oldBuildportfolioState.bondTab.selectedFunds;

      if (oldBondTabSelectedFunds && typeof oldBondTabSelectedFunds !== 'undefined') {

        const qw = (Object.keys(oldBondTabSelectedFunds));
        const lw = (Object.keys(newProps.selectedFunds));

        if (qw.length !== lw.length) {
          if (lw && lw.length > 0) {
            // console.log(lw);
            const getActiveFunds = Object.keys(newProps.selectedFunds).filter((ty) => newProps.selectedFunds[ty].length > 0);;
            // console.log(getActiveFunds);

            if (getActiveFunds.length > 0) {
              getActiveFunds.map((rt) => {
                if (rt) {
                  const fd = bondTabData.find((er) => er.query === rt);
                  // console.log(fd);
                  if (fd && bondTabData.find((er) => er.query === rt)) {
                    // USA selectedFunds sliderValue
                    if (getActiveFunds[rt] && getActiveFunds[rt].length > 0) {
                      getActiveFunds[rt].map((o) => {
                        if (o) {
                          o._sliderValue = bondTabData.find((er) => er.query === rt).sliderValue/newProps.selectedFunds[rt].length;
                        }
                      })
                    }
                    //USA sliderValue
                    // bondTabData.find((er) => er.query === rt).sliderValue = newProps.tabHeaderMenu.find((item) => item.key === 'bondTab').sliderValue/getActiveFunds.length;
                    //USA  fund list sliderValue
                    bondTabData.map((l) => {
                      l.result.funds.map((w) => {
                        w._sliderValue = 0;
                        if (newProps.selectedFunds[l.query] && typeof newProps.selectedFunds[l.query] !== 'undefined') {
                          const found = newProps.selectedFunds[l.query].find((o) => o.ticker === w.ticker);
                          if (found && typeof found !== 'undefined' && (found.ticker === w.ticker)) {
                            w._sliderValue = bondTabData.find((er) => er.query === rt).sliderValue/newProps.selectedFunds[l.query].length;
                          }
                        }
                      });
                    });
                  }
                }
              })
            }
          }
          console.log('-----------------');
        } else {
          console.log('EQUAL');
        }

      }
    }

  }

  render() {
    const { bondTabLoading, bondTabData, weightingMethodology, bondTab, activeTab, tabHeaderMenu, selectedFunds } = this.props;

    return (
      <React.Fragment>
        {bondTabLoading ? (
          <CardLoader />
        ) : (
          <BondsContent topTabHeaderMenu={tabHeaderMenu} activeTab={activeTab} data={bondTabData} weightingMethodology={weightingMethodology} bondTab={bondTab}/>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ buildportfolio, loading }) => ({
  buildportfolio,
  // weightingMethodology: buildportfolio.bondTab.weightingMethodology,
  preSelectedTickers: buildportfolio.preSelectedTickers,
  bondTabData: buildportfolio.bondTab.data,
  bondTab: buildportfolio.bondTab,
  selectedFunds: buildportfolio.bondTab.selectedFunds,
  bondTabLoading: createLoadingSelector(['BONDS_BATCH'])({ loading }),
})

const mapDispatchToProps = {
  bondsBatchRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BondsTab);

import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { isEmpty } from 'lodash';
import Toggle from 'components/Toggle';
import { MODEL_PORTFOOLIO_QUERY_MAP } from 'DataSet';
import { addEventToAnalytics } from 'Utils';
import { getPerformanceSeriesAndYear } from 'layouts/WebDashboard/AccountsPage/utils';
import MDrawer from './MDrawer';
import { FeesCard, ReturnCard, RiskCard, getOrderOfCard } from './PortfolioModalHelper';

const DEFAULT_YEARS = 'pfRet1y';

class PortfolioModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentLoadData: {
        ...props.currentStats,
        selectedSeries: props.currentStats[DEFAULT_YEARS],
        // sharpeRatio: props.currentStats[`sharpe_${DEFAULT_YEARS}`],
        cumlValue: props.currentStats[`cuml_${DEFAULT_YEARS}`]
      },
      enhanceLoadData: {},
      selectedYear: DEFAULT_YEARS,
    }
  }

  componentDidMount() {
    const { query, portfolioEnhanceReplace, enhanceStats, currentStats } = this.props;
    if (currentStats) {
      this.setState({
        currentLoadData: {
          ...currentStats,
          selectedSeries: currentStats[DEFAULT_YEARS],
          // sharpeRatio: currentStats[`sharpe_${DEFAULT_YEARS}`],
          cumlValue: currentStats[`cuml_${DEFAULT_YEARS}`]
        },
        selectedYear: DEFAULT_YEARS,
      })
      if (portfolioEnhanceReplace.length > 0 && enhanceStats && Object.keys(enhanceStats).length > 0) {
        this.setState({
          enhanceLoadData: {
            ...enhanceStats,
            selectedSeries: enhanceStats[DEFAULT_YEARS],
            // sharpeRatio: enhanceStats[`sharpe_${DEFAULT_YEARS}`],
            cumlValue: currentStats[`cuml_${DEFAULT_YEARS}`]
          },
        })
      }
    }
  }

  componentWillReceiveProps(newProps) {
    const { portfolioEnhanceReplace, enhanceStats, currentStats } = newProps;
    if (currentStats !== this.props.currentStats) {
      if (currentStats) {
        this.setState({
          currentLoadData: {
            ...currentStats,
            selectedSeries: currentStats[DEFAULT_YEARS],
            // sharpeRatio: currentStats[`sharpe_${DEFAULT_YEARS}`],
            cumlValue: currentStats[`cuml_${DEFAULT_YEARS}`]
          },
          selectedYear: DEFAULT_YEARS,
        })
      }
    }

    if (portfolioEnhanceReplace.length > 0) {
      if (enhanceStats && Object.keys(enhanceStats).length > 0 && enhanceStats !== this.props.enhanceStats) {
        this.setState({
          currentLoadData: {
            ...currentStats,
            selectedSeries: currentStats[DEFAULT_YEARS],
            // sharpeRatio: currentStats[`sharpe_${DEFAULT_YEARS}`],
            cumlValue: currentStats[`cuml_${DEFAULT_YEARS}`]
          },
          enhanceLoadData: {
            ...enhanceStats,
            selectedSeries: enhanceStats[DEFAULT_YEARS],
            // sharpeRatio: enhanceStats[`sharpe_${DEFAULT_YEARS}`],
            cumlValue: enhanceStats[`cuml_${DEFAULT_YEARS}`]
          },
          selectedYear: DEFAULT_YEARS,
        })
      }
    } else {
      this.setState({
        currentLoadData: {
          ...currentStats,
          selectedSeries: currentStats[DEFAULT_YEARS],
          // sharpeRatio: currentStats[`sharpe_${DEFAULT_YEARS}`],
          cumlValue: currentStats[`cuml_${DEFAULT_YEARS}`]
        },
        enhanceLoadData: enhanceStats, //hack
        selectedYear: DEFAULT_YEARS,
      })
    }
  }

  title = () => {
    switch (this.props.query) {
      case 'similar':
        return 'Use All Suggested Funds'

      case 'outperform':
        return 'Use All Suggested Funds'

      case 'safe_alternative':
        return 'Use All Suggested Funds'

      case 'high_yield_alternative':
        return 'Use All Suggested Funds'

      case 'long_track_record_alternatives':
        return 'Use All Suggested Funds'

      case 'lowest_tracking_error_option':
        return 'Use All Suggested Funds'

      case 'high_sharpe_alternative':
        return 'Use All Suggested Funds'

      case 'user_criteria':
        return 'Use All Suggested Funds'

      default:
        return `Use All Suggested Funds`
    }
  }

  setPfEnhancementCategory = (e, category) => {
    const val = document.getElementById(e.target.id).checked;
    const { setPortfolioState, toggleChange } = this.props;
    addEventToAnalytics('Enhancer Replace All', 'Enhancer Replace All', 'ENHANCER_REPLACE_ALL', {}, false);
    // if (val) {
    // 	setPortfolioState({ replacePortfolioToggle: true })
    // 	if (toggleChange) {
    // 		toggleChange(true);
    // 	}
    // } else {
    // 	setPortfolioState({ replacePortfolioToggle: false, enhanceReplace: [], replaceAllPortfolioToggle: true })
    // 	if (toggleChange) {
    // 		toggleChange(false);
    // 	}
    // }
  }

  yearChange = (selected) => {
    if (selected) {
      const { portfolioEnhanceReplace, enhanceStats, currentStats } = this.props;
      if (portfolioEnhanceReplace.length > 0 && enhanceStats) {
        this.setState({
          currentLoadData: {
            ...currentStats,
            selectedSeries: currentStats[selected],
            // sharpeRatio: currentStats[`sharpe_${selected}`],
            cumlValue: currentStats[`cuml_${selected}`],
            // dMax: currentStats[`dMax_${selected}`],
          },
          enhanceLoadData: {
            ...enhanceStats,
            selectedSeries: enhanceStats[selected],
            // sharpeRatio: enhanceStats[`sharpe_${selected}`],
            cumlValue: enhanceStats[`cuml_${selected}`],
            // dMax: enhanceStats[`dMax_${selected}`],
          },
          selectedYear: selected,
        });
      } else {
        this.setState({
          currentLoadData: {
            ...currentStats,
            selectedSeries: currentStats[selected],
            // sharpeRatio: currentStats[`sharpe_${selected}`],
            cumlValue: currentStats[`cuml_${selected}`],
            // dMax: currentStats[`dMax_${selected}`],
          },
          enhanceLoadData: {},
          selectedYear: selected,
        });
      }
    }
  }

  useSuggestedFunds = (toggleState, overrideState) => {
    const { useSuggestedFundsRequest } = this.props;
    if (overrideState === 'MANUAL' && toggleState) {
      let data = this.props._portfolioEnhanceReplace.map(item => {
        return {
          ticker: item.ticker,
          before: item.replace,
          after: this.props.SuggestedFundsData.find(temp => temp.ticker === item.ticker).replace
        }
      })
      console.log('--- Use All Suggested Funds ---')
      addEventToAnalytics('Use All Suggested Funds', 'Use All Suggested Funds', 'USE_ALL_SUGGESTED_FUNDS', { funds: data, query_uuid: this.props.query_uuid, category: 'advisory_PTC' }, false);  //
    }
    if (overrideState === 'MANUAL' && !toggleState) {
      console.log('--- Deselected Use All Suggested Funds ---')
      addEventToAnalytics('Deselected Use All Suggested Funds', 'Deselected Use All Suggested Funds', 'DESELECTED_USE_ALL_SUGGESTED_FUNDS', { query_uuid: this.props.query_uuid, category: 'advisory_PTC' }, false);  //
    }
    if (useSuggestedFundsRequest) {
      useSuggestedFundsRequest({
        toggleState,
        overrideState,
      });
    }
  }

  render() {
    const {
      mask,
      query,
      visible,
      loading,
      closable,
      openDrawer,
      summaryPage,
      enhanceStats,
      currentStats,
      SuggestedFundsData,
      evaluateFundsDesign,
      enhanceTickerFilter,
      usingSuggestedFunds,
      portfolioBenchmarkData,
      portfolioEnhanceReplace,
      enhancePortfolioVisible,
      replacementToggleEnable,
      handleUpDownDropDownChange,
      selectedPortfolioBenchmarkTicker,
    } = this.props;

    const { currentLoadData, enhanceLoadData, selectedYear } = this.state;

    let loadData = {
      ...this.props,
      currentStats: currentLoadData,
      enhanceStats: enhanceLoadData,
    };

    let retDropDown = {
      pfRet1y: {
        year: '1 Year',
        disabled: false,
        col: 6,
      },
      pfRet3y: {
        year: '3 Years',
        disabled: false,
        col: 6,
      },
      pfRet5y: {
        year: '5 Years',
        disabled: false,
        col: 6,
      },
    };

    const tickerLength = portfolioEnhanceReplace.filter((item) => (item.ticker !== item.replace && enhanceTickerFilter.includes(item.ticker))).length

    const mDrawerHeader = (summaryPage) => {
      const renderDesign = (summaryPage) ? (<span>Performance Summary </span>) : (
        <span>
          Performance Impact {(enhancePortfolioVisible === true) && (
            <span className="sub-head-label">(
              {tickerLength} {tickerLength <= 1 ? 'ticker' : 'tickers'} replaced)
            </span>
          )}
          {evaluateFundsDesign}
        </span>
      )
      return (
        <>
          {renderDesign}
          {(!loading) && (
            <div className="pft-category-item">
              <Row className="content-head-row align-items-center justify-content-between" style={{ margin: '0 0 5px 0', padding: 0 }}>
                <Col className="col-header-wrap pft-category-item-wrapper" style={{ textAlign: 'left' }}>
                  {(replacementToggleEnable && !summaryPage) && (
                    <Toggle
                      size="small"
                      style={{ marginRight: 5 }}
                      checked={usingSuggestedFunds}
                      onChange={(value) => this.useSuggestedFunds(value, 'MANUAL')}
                    >
                      {this.title()}
                    </Toggle>
                  )}
                </Col>
                <Col className="col-header-wrap d-flex justify-content-between" style={(portfolioEnhanceReplace.length > 0 && enhanceStats) ? { width: '30%', maxWidth: 122 } : {}}>
                  <span className="col-header-current-stats">Current</span>
                  {(portfolioEnhanceReplace.length > 0 && enhanceStats) && (
                    <span className="col-header-enhanced-stats">Enhanced</span>
                  )}
                </Col>
              </Row>
            </div>
          )}
        </>
      )
    }

    // if (enhanceLoadData && currentLoadData && Object.keys(currentLoadData).length > 0 &&  Object.keys(enhanceLoadData).length > 0 && tickerLength > 0) {
    if (enhanceLoadData && currentLoadData && Object.keys(currentLoadData).length > 0 && Object.keys(enhanceLoadData).length > 0 && loadData && loadData.currentStats && !isEmpty(loadData.currentStats) && Object.keys(loadData.currentStats).length > 2) {
      const filteredPerformanceObject = getPerformanceSeriesAndYear({ currentSeries: loadData.currentStats, enhancedSeries: enhanceLoadData, query, retDropDown });
      // console.log(filteredPerformanceObject.currentSeries);
      // console.log(filteredPerformanceObject);
      if (filteredPerformanceObject.enhancedSeries && filteredPerformanceObject.enhancedSeries.pfRet1y) {
        loadData = {
          ...this.props,
          currentStats: {
            ...loadData.currentStats,
            ...filteredPerformanceObject.currentSeries,
            selectedSeries: filteredPerformanceObject.currentSeries[selectedYear],
          },
          enhanceStats: {
            ...loadData.enhanceStats,
            ...filteredPerformanceObject.enhancedSeries,
            selectedSeries: filteredPerformanceObject.enhancedSeries[selectedYear],
          },
        };
      }

      retDropDown = {
        pfRet1y: filteredPerformanceObject.pfRet1y,
        pfRet3y: filteredPerformanceObject.pfRet3y,
        pfRet5y: filteredPerformanceObject.pfRet5y,
      };
    }

    // console.log(loadData);

    const showAsteriskInfo = (loadData.enhanceStats && typeof loadData.enhanceStats.showAsteriskFor !== 'undefined' && loadData.enhanceStats.showAsteriskFor.length > 0);
    // console.log(loadData.enhanceStats);
    // console.log('=== showAsteriskInfo =====> ', showAsteriskInfo);

    const CARD_ORDER_OBJ = {
      Fees: () => (<FeesCard originalQueryVar={this.props.originalQueryVar} data={loadData} query={(query && query.includes('model')) ? MODEL_PORTFOOLIO_QUERY_MAP[query] : query} />),
      Returns: () => (
        <ReturnCard
          defaultYears={DEFAULT_YEARS}
          data={loadData}
          selectedYear={selectedYear}
          dropDownData={retDropDown}
          yearChange={this.yearChange}
        />
      ),
      Risk: () => (
        <RiskCard
          query={this.props.query}
          data={loadData}
          selectedYear={selectedYear}
          portfolioBenchmarkData={portfolioBenchmarkData}
          selectedPortfolioBenchmarkTicker={selectedPortfolioBenchmarkTicker}
          handleUpDownDropDownChange={handleUpDownDropDownChange}
        />
      ),
    };

    const renderPortfoliosCard = getOrderOfCard({ orderBy: query, CARD_ORDER_OBJ });

    return (
      <MDrawer
        id="summaryDrawerElement"
        className="summaryDrawerWrapper"
        onOpen={() => openDrawer(true)}
        onClose={() => openDrawer(false)}
        visible={visible}
        headerText={mDrawerHeader(summaryPage)}
        mask={mask}
        closable={closable}
        icon={<i class="fas fa-times"></i>}
        FooterDesign={null}
      >
        {/*
          <Row className="content-head-row" gutter={[16, 8]} style={{ margin: '0 0 5px 0', padding: '0 15px' }}>
            <Col className="col-header-wrap" span={12}></Col>
            <Col className="col-header-wrap" span={6}>Current</Col>
            <Col className="col-header-wrap" span={6}>{(portfolioEnhanceReplace.length > 0  && enhanceStats) ? 'Enhanced' : ''}</Col>
          </Row>
        */}

        {(loading) && (
          <div className="performance-impact-loader-wrapper">
            <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5" style={{ fontSize: '2em' }}></i>
            <div className="text-box" style={{ marginTop: 20 }}>
              <span>Data is currently being calculated</span>
            </div>
          </div>
        )}
        {(!loading) && (
          <div>
            {renderPortfoliosCard.map((kk) => {
              if (kk && typeof kk !== 'undefined' && typeof kk === 'function') {
                return kk();
              }
            })}
            {showAsteriskInfo && (
              <div className={`portfolio-impact-card-footer-wrapper`}>
                <p className={`portfolio-impact-card-footer-text`}>* Has less than 5 years of historical data.</p>
              </div>
            )}
          </div>
        )}

      </MDrawer>
    )
  }
}

export default PortfolioModal;

import React from 'react';
const d3 = require("d3");

export default class D3Sunburst extends React.Component {
  constructor (props) {
    super(props);

    this.inRef = React.createRef();
    this.clickableNodes = [];
    this.state = {}
  }

  componentDidMount() {
    this.draw(this.props);
  }

  draw = ({ data, colors={} }) => {
    const { clickHandler } = this.props;

    const partition = data => {
      const root = d3.hierarchy(data).sum(d => d.size);

      return d3.partition().size([2 * Math.PI, root.height + 1])(root);
    };

    const d3color = d3.scaleOrdinal().range(d3.quantize(d3.interpolateRainbow, 20));
    const color = name => colors[name] || d3color(name);
    const width = this.props.width, height = this.props.height;
    const radius = width / 4;
    const radiusFn = d => {
      let r0 = d.y0 * radius;
      return Math.max(r0, r0 + (d.y0 >= 2 ? 4 : radius - 1));
    };
    const tooltip = d => `${d.data.name}: ${Math.round(d.value)}%`;

    const arc = d3.arc()
      .startAngle(d => d.x0)
      .endAngle(d => d.x1)
      .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(radius * 1.5)
      .innerRadius(d => d.y0 * radius)
      .outerRadius(radiusFn);

    const root = partition(data);

    root.each(d => d.current = d);

    d3.select(this.inRef.current).selectAll("*").remove();
    const svg = d3.select(this.inRef.current).append("svg:svg")
        .style("width", width+'px')
        .style("height", height+'px')
        .style("font", "10px sans-serif");

    const g = svg.append("g")
        .attr("transform", `translate(${width / 2},${width / 2})`);

    const path = g.append("g")
      .selectAll("path")
      .data(root.descendants().slice(1))
      .enter().append("path")
        .attr("fill", d => color(d.data.name))
        .attr("fill-opacity", d => arcVisible(d.current) ? 1 : 0)
        .attr("d", d => arc(d.current));

    const parent = g.append("circle");

    this.clicked = (p) => {
      parent.select('title').remove();
      parent.datum(p.parent || root)
        .attr("fill", () => color(p.data.name))
        .attr("cursor", p.depth === 0 ? "default" : "pointer")
        .append("title")
        .text(() => tooltip(p));
      root.each(d => d.target = {
        x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
        x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
        y0: Math.max(0, d.y0 - p.depth),
        y1: Math.max(0, d.y1 - p.depth)
      });
      const t = g.transition().duration(750);

      path.transition(t)
          .tween("data", d => {
            const i = d3.interpolate(d.current, d.target);
            return t => d.current = i(t);
          })
        .filter(function(d) {
          return +this.getAttribute("fill-opacity") || arcVisible(d.target);
        })
          .attr("fill-opacity", d => arcVisible(d.target) ? 1 : 0)
          .attrTween("d", d => () => arc(d.current));
    };

    path.filter(d => {
      if (d.children) {
        // save clickable node to array
        this.clickableNodes.push(d);
        return d.children;
      }
      return false;
    })
      .style("cursor", "pointer")
      .on("click", clickHandler);

    path.append("title")
        .text(tooltip);

    parent.datum(root)
        .attr("r", radius)
        .attr("fill", d => {
          this.clickableNodes.push(d);
          return color(d.data.name);
        })
        .attr("cursor", "default")
        .attr("pointer-events", "all")
        .on("click", clickHandler)
        .append("title")
        .text(tooltip);

    function arcVisible(d) {
      return d.y1 <= 2 && d.y0 >= 1 && d.x1 > d.x0;
    }

    return svg.node();
  };

  render() {
    return (
      <div ref={this.inRef} style={{ borderRadius: '50%', overflow: 'hidden' }}/>
    )
  }
}

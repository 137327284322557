import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { Col, FormGroup, Modal, ModalBody, Row } from 'reactstrap'
import DeepDif from 'deep-diff'
import { Button } from 'antd'
import { logout } from 'actions/auth'
import { refreshFundsState, searchQuery, setFundsState } from 'actions/funds'
import {
  disableEnhanceBtnControlState,
  finalizeReplaceModelPortfolioRequest,
  resetPortfolioState,
  saveAccountEnhancedOption,
  saveEnhancedOption,
  saveEnhanceProposalPortfolio,
  setEnhancedTransaction,
  setPortfolioDefault,
  setPortfolioReplace,
  setPortfolioState
} from 'actions/portfolio'
import { clearFundsApiLoadPercent, setGlobalState } from 'actions/global'
import { REDIRECT_DOMAIN_PATH, ReservedRoutes } from 'DataSet'
// import Header from './Header';
import SideBar from './SideBar'
import Content from './Content'
import PfUpload from '../PfUpload'
import PortfolioFilter from './PortfolioFilter'
import { Loader } from './Elements/Loader'
import { addEventToAnalytics } from 'Utils'
import { customScroll, getQueryItems } from '../../utils'
import AccountConfirmed from './Elements/Registrations/AccountConfirmed'
import TermsNConditions from './Elements/Registrations/TermsNConditions'
import OnBoardScreen from './OnBoardScreen'
import PortfolioModal from './Elements/PortfolioModal'
import {
  getFileName,
  getInvestmentPositionStats,
  getInvestmentWatchlistStats,
  getMarketStats,
  getPortAccountStats
} from 'selectors/investment'
import {
  downloadPortfolioReport,
  downloadRecEnhancerReport,
  fetchMarketData,
  fetchPortfolioData,
  investmentFetch,
  investmentFetchSuccess,
  investmentOptions,
  investmentOptionsRedirect,
  resetInvestmentState,
  setInvestmentDefault,
  setInvestmentState
} from 'actions/investment'
import {
  dateSort,
  geopMeanCalc,
  getCalculatedEnhanceStats,
  getCalculatedModelPortfolioEnhanceStats,
  getCurrentPfStats
} from './utils'
import GenerateTradeList from './Elements/GenerateTradeList'
import { getPortfolioEnhanceReplace } from 'selectors/portfolio'
import CustomAlert from 'components/CustomAlert'
import { changeCalculator } from 'actions/weights'
import { fetchProfile, subscriptionPlan } from 'actions/profile'
import { setBuildPortfolioState } from 'actions/buildportfolio'
import { createLoadingSelector } from 'selectors/loading-selector'
import { getDefaultEnhanceReplaceData, statsForPerformanceImpact } from 'layouts/utils'

import { RightOutlined } from '@ant-design/icons'
import { getPortfolioBenchmarkOfSelectedTicker } from '../../../Utils'

class AccountsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLogin: false,
      setCookie: false,
      setPfEnhancement: false,
      tickers: '',
      drawerVisible: false,
      generateTradelist: false,
      alert: false,
      header2Disable: false,
      saveEnhancePortfolio: false,
      fileName: '',
      fileNameError: false,
      setPfState: true,
      moduleLoading: false,
      enableEnhancePortfolioQueries: props.enableEnhancePortfolioQueries,
      enableFirstEnhancePortfolioQueries: false,
      UploadPortfolio: { label: 'Upload Portfolio', name: 'Portfolio', code: 'uplPrt' },
      MarketPlace: { label: 'Market Place', name: 'Market', code: 'mrktPl' },
      shouldReturn: false,
      modelUniverse: '',
    }
  }

  componentDidMount() {
    const { view, watchlistFundstats, accountFundstats } = this.props;
    if (this.props.useremail && this.props.firstName && this.props.lastName) {
      if (window.heap) {
        window.heap.identify(this.props.useremail);
        window.heap.addUserProperties({ 'First Name': this.props.firstName, 'Last Name': this.props.lastName });
      }
      window.addkrakenUser && typeof window.addkrakenUser === "function" && window.addkrakenUser({ email: this.props.useremail, firstName: this.props.firstName, lastName: this.props.lastName });
      // NOTE: Segment Script
      // ------------------------------------------------------------------------------------------
      if (window.analytics && window.analytics.identify && typeof window.analytics.identify === 'function') {
        // console.log('---- AccountsPage Segment ----');
        window.analytics.identify(this.props.useremail, {
          name: this.props.firstName + ' ' + this.props.lastName,
          firstName: this.props.firstName,
          lastName: this.props.lastName,
          email: this.props.useremail,
          user_type: 'Advisor',
        });
      }
      // ------------------------------------------------------------------------------------------
    }

    if (view == "watchlist") {
      if (watchlistFundstats.tickers.length == 0) {
        this.setState({ header2Disable: true })
      } else this.setState({ header2Disable: false })
    } else if (view == "account") {
      if (accountFundstats.tickers.length == 0) {
        this.setState({ header2Disable: true })
      } else this.setState({ header2Disable: false })
    } else if (view == "") {
      this.setState({ header2Disable: true })
    }

    this.executeGlobalSurvey();
    this.props.subscriptionPlan();
    this.props.fetchProfile('')
    this.props.investmentFetch(() => {
      this.callback();
      this.calculateSummaryStats(this.props.view);
    });
    if (this.props.redirectFrom.module || this.props.redirectFrom.type) {
      this.setState({ moduleLoading: true });
    }
  }

  callback() {
    // NOTE: this case is written to stop NaN and Undefined issue on Performance impact drawer
    // if (this.props.portfolioEnhanceReplace && this.props.portfolioEnhanceReplace.length) {
    //   this.calculateEnhanceStats();
    // }

    if (this.props.redirectFrom.module && this.props.redirectFrom.portfolioName) {
      // this.props.setPortfolioReplace([]);
      // this.props.investmentFetchSuccess({ setPfEnhancementContent: false });
      // commented this because setpfenahancement counter will be false from unmount

      if (this.props.redirectFrom.module == 'builder' || this.props.redirectFrom.type == 'custom_portfolio') {
        this.props.fetchPortfolioData(this.props.redirectFrom.portfolioName);
        const opts = {
          view: this.state.UploadPortfolio.code,
          activePortAccount: this.props.redirectFrom.portfolioName,
          activeAccount: this.props.redirectFrom.portfolioName,
          activeAccAccount: 'All',
          acView: 'default',
        };
        this.props.investmentOptions(opts);
        this.props.setPortfolioState({ onBoardScreen: false })
      }
      else if (this.props.redirectFrom.type == 'model_portfolio') {
        //portfolio_name --> mp id
        //type == model_portfolio
        this.props.fetchMarketData(this.props.redirectFrom.portfolioName)
        let opts = {
          view: this.state.MarketPlace.code,
          activeAccAccount: this.props.redirectFrom.portfolioName,
          activeAccount: this.props.redirectFrom.portfolioName,
          filename: this.props.redirectFrom.portfolioName,
          acView: 'default',
          activePortAccount: '',
        }
        this.props.investmentOptions(opts);
        this.props.setPortfolioState({ onBoardScreen: false })
      }
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('main', 'ht5', 'no-scroll', 'body_paddingTp');
    // NOTE: this case is written to stop NaN and Undefined issue on Performance impact drawer
    const { refreshFundsState, setPortfolioState, setInvestmentState } = this.props;
    if (setPortfolioState) {
      setPortfolioState({
        summaryDrawer: false,
        enhanceReplace: [],
        enableEnhancePortfolioQueries: false,
        currentPerformanceImpact: {},
        enhancedPerformanceImpact: {},
      });
    }
    if (refreshFundsState) {
      refreshFundsState();
    }
    setInvestmentState({
      setPfEnhancementContent: false,
    });
    this.props.investmentOptions({ redirectFrom: {} });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.view && this.props.view !== nextProps.view) {
      this.calculateSummaryStats(nextProps.view);
    }

    const { enhancePortfolioVisible } = nextProps;
    if (enhancePortfolioVisible) {
      const { enhanceModelPortfolio } = nextProps;
      if (enhanceModelPortfolio && enhanceModelPortfolio.replacePortfolioOptions && enhanceModelPortfolio.replacePortfolioOptions.funds && enhanceModelPortfolio.replacePortfolioOptions.funds.length > 0 && nextProps.portfolioEnhanceReplace.length > 0) {
        const thisPropReplacePortfolioWith = this.props.enhanceModelPortfolio.replacePortfolioWith;
        if ((enhanceModelPortfolio.replacePortfolioWith && enhanceModelPortfolio.replacePortfolioWith.ticker !== thisPropReplacePortfolioWith.ticker) || (this.props.enhanceModelPortfolio.requestQuery !== enhanceModelPortfolio.requestQuery) || (this.state.modelUniverse !== enhanceModelPortfolio.fundUniverse)) {
          this.calculateModelPortfolioEnhanceStats();
        }
      }
    }

    if ((nextProps.investment.redirectFrom.type || nextProps.investment.redirectFrom.module) && nextProps.onBoardScreen == false && (nextProps.investment.portfolioFunds || nextProps.investment.marketFunds.length > 0) && !nextProps.portfolio.enableEnhancePortfolioQueries && this.state.enableEnhancePortfolioQueries == false) {
      this.setState({ enableEnhancePortfolioQueries: true, enableFirstEnhancePortfolioQueries: true })
      const { setPortfolioState, setInvestmentState } = this.props;
      if (nextProps.portfolio.enableEnhancePortfolioQueries == false && this.props.portfolio.enableEnhancePortfolioQueries == false) {
        setPortfolioState({
          enableEnhancePortfolioQueries: true,
        });
      }
      this.props.changeCalculator('passive fund selector');
    }

    if ((nextProps.investment.redirectFrom.type || nextProps.investment.redirectFrom.module) && (nextProps.investment.marketFunds.length > 0 || nextProps.investment.portfolioFunds.length > 0) && nextProps.investment.retData.length > 0 && this.state.setPfState) {
      const { setInvestmentState } = this.props;
      setInvestmentState({
        setPfEnhancementContent: true,
      });
      this.setState({ setPfState: false, moduleLoading: false });
    }
  }

  componentDidUpdate(prevProps) {
    const { view, watchlistFundstats, accountFundstats, enhancePortfolioVisible } = this.props;
    if (view == 'watchlist') {
      if (watchlistFundstats.positions !== prevProps.watchlistFundstats.positions) {
        this.calculateSummaryStats(this.props.view);
      }
    } else if (view == 'account') {
      if (accountFundstats.funds !== prevProps.accountFundstats.funds) {
        this.calculateSummaryStats(this.props.view);
      }
    } else if (view == 'uplPrt' && this.props.portfolioFunds !== prevProps.portfolioFunds) {
      this.calculateSummaryStats(this.props.view);
    } else if (view == 'mrktPl' && this.props.marketFunds !== prevProps.marketFunds) {
      this.calculateSummaryStats(this.props.view);
    }


    // if (this.props.portfolioEnhanceReplace.length > 0 && this.props.portfolioEnhanceReplace !== prevProps.portfolioEnhanceReplace && !this.props.enhancePortfolioVisible) {
    //   this.calculateEnhanceStats();
    // }

    // if (enhancePortfolioVisible) {
    //   const { enhanceModelPortfolio } = this.props;
    //   if (enhanceModelPortfolio && enhanceModelPortfolio.replacePortfolioOptions && enhanceModelPortfolio.replacePortfolioOptions.funds && enhanceModelPortfolio.replacePortfolioOptions.funds.length > 0) {
    //     const prevPropsReplacePortfolioWith = prevProps.enhanceModelPortfolio.replacePortfolioWith;
    //     // console.log('current Prop ReplacePortfolioWith --->>>>', prevPropsReplacePortfolioWith.ticker);
    //     // console.log('new prop ReplacePortfolioWith ---->>>>', enhanceModelPortfolio.replacePortfolioWith.ticker);
    //     // console.log('prevProps.enhanceModelPortfolio.requestQuery -> ', prevProps.enhanceModelPortfolio.requestQuery, 'enhanceModelPortfolio.requestQuery -> ',enhanceModelPortfolio.requestQuery);
    //     // console.log(enhanceModelPortfolio.replacePortfolioWith.ticker, prevPropsReplacePortfolioWith.ticker);
    //     console.log('old props Model Univers -> ', prevProps.enhanceModelPortfolio.fundUniverse, '-- new props model universe -->',this.props.enhanceModelPortfolio.fundUniverse, '-- state model universe -->', this.state.modelUniverse);
    //     if ((enhanceModelPortfolio.replacePortfolioWith && enhanceModelPortfolio.replacePortfolioWith.ticker !== prevPropsReplacePortfolioWith.ticker) || (prevProps.enhanceModelPortfolio.requestQuery !== enhanceModelPortfolio.requestQuery) || (this.state.modelUniverse !== this.props.enhanceModelPortfolio.fundUniverse)) {
    //       this.calculateModelPortfolioEnhanceStats();
    //     }
    //   }
    // }

    // const headerElement = document.getElementById("headerWrapper");
    // if (headerElement) {
    //   document.body.style.paddingTop = `${headerElement.offsetHeight}px`;
    // }
    const headerElement = document.getElementById("magnifi-header-wrapper");
    const portfolioContentHeaderElement = document.getElementById("pf-content-header");
    if (portfolioContentHeaderElement && headerElement) {
      const portfolioSummaryDrawerElement = document.getElementById("summaryDrawerElement");
      if (portfolioSummaryDrawerElement) {
        const drawerxContentElement = portfolioSummaryDrawerElement.getElementsByClassName("ant-drawer-content-wrapper")[0];
        drawerxContentElement.style.top = portfolioContentHeaderElement.offsetHeight + 9 + 'px';
      }
    }

    const element = document.querySelector('.ant-drawer-content-wrapper')
    if (element) element.classList.add("custom-scroll");
    customScroll();

    if (document.getElementById('save-file-name-box') != null) {
      document.getElementById('save-file-name-box').addEventListener('keypress', function (event) {
        if (event.keyCode == 13) {
          event.preventDefault();
          document.getElementById("save-prtf").click();
        }
      });
    }

    //set the enhance portfoilio state false
    // if(this.props.onBoardScreen == true && this.state.enableEnhancePortfolioQueries == true){
    //   this.setState({ enableEnhancePortfolioQueries: false }, () => {
    //     const { setPortfolioState } = this.props;
    //     setPortfolioState({
    //       enableEnhancePortfolioQueries: this.state.enableEnhancePortfolioQueries,
    //     });
    //   });
    // }
  }

  executeGlobalSurvey = () => {
    const { setGlobalState } = this.props;
    const plusCounter = localStorage.getItem('plusCounter');
    if (plusCounter && localStorage.getItem('counter') === plusCounter) {
      setGlobalState({
        surveyModal: true,
      });
    } else {
      setGlobalState({
        surveyModal: false,
      });
    }
  }

  calculateSummaryStats = (data) => {
    setTimeout(() => {
      console.log('calculateSummaryStats............');
      const { watchlistFundstats, accountFundstats, portfolioFunds, marketFunds, setPortfolioState, portfolioQuery, upDownDataSet, selectedBenchmark } = this.props;
      let currentStats = {}, view = data || this.props.view;
      let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(upDownDataSet, selectedBenchmark)
      if (view === 'account') {
        currentStats = getCurrentPfStats(accountFundstats.funds, portfolioQuery, portfolioBenchmark);
      } else if (view === 'watchlist') {
        currentStats = getCurrentPfStats(watchlistFundstats.positions, portfolioQuery, portfolioBenchmark);
      } else if (view === 'mrktPl') {
        currentStats = getCurrentPfStats(marketFunds, portfolioQuery, portfolioBenchmark);
      } else if (view === 'uplPrt') {
        currentStats = getCurrentPfStats(portfolioFunds, portfolioQuery, portfolioBenchmark);
      }
      this.setState({ currentStats: currentStats });
      setPortfolioState({
        currentPerformanceImpact: currentStats,
      });
    }, 0);
  }

  calculateEnhanceStats = () => {
    console.log('calculateEnhanceStats')
    const { portfolioEnhanceReplace, watchlistFundstats, accountFundstats, portfolioFunds, marketFunds, setPortfolioState, portfolioQuery, enhancePortfolioVisible, upDownDataSet, selectedBenchmark } = this.props;
    let currentStats = {}, view = this.props.view;
    let enhanceTickers = [], currentTicker = [];

    let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(upDownDataSet, selectedBenchmark)
    if (portfolioEnhanceReplace && !enhancePortfolioVisible) {
      let updatedArray = portfolioEnhanceReplace.map((item) => {
        enhanceTickers.push(item.ticker);
        const returns = item.selectedPortfolioFunds.returns.sort(dateSort);
        return {
          ...item.selectedPortfolioFunds,
          return: {
            returns: returns.reverse(), // intentionally did it!!
          },
        }
      });
      // console.log(enhanceTickers)
      if (view == 'account') {
        if (portfolioEnhanceReplace.length != accountFundstats.funds.length) {
          // console.log('uplPrt1', portfolioEnhanceReplace.length, accountFundstats.funds.length)
          // if item.ticker ! predent in enhanceTickers
          // then push it to currentTicker
          let cfS = JSON.parse(JSON.stringify(accountFundstats.funds));
          cfS.forEach((elm) => {
            if (enhanceTickers.indexOf(elm.script) === -1) {
              let ret = elm.return;
              currentTicker.push({
                _pF: JSON.parse(JSON.stringify(elm)),
                ticker: elm.script || elm.symbol,
                return: ret,
                nav: elm._realTimePrice,
                fees: elm.fees,
                asset_alloc: elm.asset_alloc,
                'attribute/fundamental/yield': elm['attribute/fundamental/yield'] || elm['yield'],
                outperform: elm['outperform'] || 0.0,
                'attribute/track_record': elm['attribute/track_record'] || elm['track_record'],
              });
            }
          })
        }
      }
      else if (view == 'watchlist') {
        if (portfolioEnhanceReplace.length != watchlistFundstats.positions.length) {
          // console.log('uplPrt1', portfolioEnhanceReplace.length, watchlistFundstats.positions.length)
          // if item.ticker ! predent in enhanceTickers
          // then push it to currentTicker
          // console.log(watchlistFundstats.positions)
          let cfS = JSON.parse(JSON.stringify(watchlistFundstats.positions));
          cfS.forEach((elm) => {
            if (enhanceTickers.indexOf(elm.script) === -1) {
              let ret = elm.return;
              currentTicker.push({
                _pF: JSON.parse(JSON.stringify(elm)),
                ticker: elm.script || elm.symbol,
                return: ret,
                nav: elm._realTimePrice,
                fees: elm.fees,
                asset_alloc: elm.asset_alloc,
                'attribute/fundamental/yield': elm['attribute/fundamental/yield'] || elm['yield'],
                outperform: elm['outperform'] || 0.0,
                'attribute/track_record': elm['attribute/track_record'] || elm['track_record'],
              });
            }
          })
        }
      }
      else if (view == 'mrktPl') {
        if (portfolioEnhanceReplace.length != marketFunds.length) {
          // console.log('uplPrt1', portfolioEnhanceReplace.length, marketFunds.length)
          // if item.ticker ! predent in enhanceTickers
          // then push it to currentTicker
          let cfS = JSON.parse(JSON.stringify(marketFunds));
          cfS.forEach((elm) => {
            if (enhanceTickers.indexOf(elm.script) === -1) {
              let ret = elm.return;
              currentTicker.push({
                _pF: JSON.parse(JSON.stringify(elm)),
                ticker: elm.script || elm.symbol,
                return: ret,
                nav: elm._realTimePrice,
                fees: elm.fees,
                asset_alloc: elm.asset_alloc,
                'attribute/fundamental/yield': elm['attribute/fundamental/yield'] || elm['yield'],
                outperform: elm['outperform'] || 0.0,
                'attribute/track_record': elm['attribute/track_record'] || elm['track_record'],
              });
            }
          })
        }
      }
      else if (view == 'uplPrt') {
        if (portfolioEnhanceReplace.length != portfolioFunds.length) {
          // console.log('uplPrt1', portfolioEnhanceReplace.length, portfolioFunds.length)
          // if item.ticker ! predent in enhanceTickers
          // then push it to currentTicker
          let cfS = JSON.parse(JSON.stringify(portfolioFunds));
          cfS.forEach((elm) => {
            if (enhanceTickers.indexOf(elm.script) === -1) {
              let ret = elm.return;
              currentTicker.push({
                _pF: JSON.parse(JSON.stringify(elm)),
                ticker: elm.script || elm.symbol,
                return: ret,
                nav: elm._realTimePrice,
                fees: elm.fees,
                asset_alloc: elm.asset_alloc,
                'attribute/fundamental/yield': elm['attribute/fundamental/yield'] || elm['yield'],
                outperform: elm['outperform'] || 0.0,
                'attribute/track_record': elm['attribute/track_record'] || elm['track_record'],
              });
            }
          })
        }
      }
      // console.log(enhanceTickers, currentTicker)
      updatedArray = [...updatedArray, ...currentTicker];

      const enhanceStats = getCalculatedEnhanceStats(updatedArray, portfolioQuery, portfolioBenchmark);
      this.setState({ enhanceStats });
      setPortfolioState({
        enhancedPerformanceImpact: enhanceStats,
      });
    }
  }

  calculateModelPortfolioEnhanceStats = () => {
    // console.log('running calculateModelPortfolioEnhanceStats......');
    const { portfolioEnhanceReplace, setPortfolioState, portfolioQuery, enhancePortfolioVisible, investment, upDownDataSet, selectedBenchmark } = this.props;
    if (enhancePortfolioVisible) {
      // console.log('---- Model Portfolio section ----');
      const { enhanceModelPortfolio } = this.props;
      const { replacePortfolioWith } = enhanceModelPortfolio;
      const enhanceModelPortfolioQuery = enhanceModelPortfolio.requestQuery;
      let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(upDownDataSet, selectedBenchmark)
      if (replacePortfolioWith && replacePortfolioWith.holdings && replacePortfolioWith.holdings.length > 0 && replacePortfolioWith._calculateEnhanceStatsFlag === false) {
        const replacePortfolioWith_Arr = [replacePortfolioWith];
        const enhanceStats = getCalculatedModelPortfolioEnhanceStats(replacePortfolioWith_Arr, enhanceModelPortfolioQuery, investment.portfolioValue, portfolioBenchmark);
        if (enhanceStats) {
          this.setState({ enhanceStats, modelUniverse: enhanceModelPortfolio.fundUniverse });
          setPortfolioState({
            enhancedPerformanceImpact: enhanceStats,
          });
        }
      }
    }
  }

  onSinginButtonClick = () => {
    this.setState({
      showLogin: true
    })
  }

  handleLoginClose = () => {
    this.setState({
      showLogin: false
    })
  }

  setProfileCookie = () => {
    this.setState({ setCookie: true })
  }

  setContent = (tickers) => {
    this.setState({ setPfEnhancement: false })
  }

  setPfEnhancement = () => {
    this.setState({ setPfEnhancement: true })
    this.props.investmentFetchSuccess({ setPfEnhancementContent: true })
  }

  toggleChange = (toggleState) => {
    const {
      resultSet,
      portfolioQuery,
      setPortfolioState,
      portfolioEnhanceReplace,
      watchlistFundstats,
      accountFundstats,
      portfolioFunds,
      marketFunds,
      enhancePortfolioVisible,
      view,
      investment,
      upDownDataSet,
      selectedBenchmark
    } = this.props;
    const { enhanceReplace, _resultSet } = getDefaultEnhanceReplaceData({ resultSet, toggleState });
    if (enhanceReplace.length > 0) {
      // checked all funds when toggel is ON
      let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(upDownDataSet, selectedBenchmark)
      statsForPerformanceImpact({
        portfolioEnhanceReplace: enhanceReplace,
        watchlistFundstats,
        accountFundstats,
        portfolioFunds,
        marketFunds,
        portfolioQuery,
        enhancePortfolioVisible,
        view,
        portfolioBenchmark,
      }).then((res) => {
        if (res.enhancedPerformanceImpact && res.portfolioEnhanceReplace && res.portfolioEnhanceReplace.length > 0) {
          if (setPortfolioState) {
            setPortfolioState({
              enhanceReplace: res.portfolioEnhanceReplace,
              enhancedPerformanceImpact: res.enhancedPerformanceImpact,
            });
          }
        }
      });
    } else {
      // unchecked all funds when toggel is OFF
      if (setPortfolioState) {
        setPortfolioState({
          resultSet: _resultSet,
        });
      }
    }
  }

  evaluateOnChange = (filteredArray) => {
    const {
      resultSet,
      portfolioQuery,
      setPortfolioState,
      portfolioEnhanceReplace,
      watchlistFundstats,
      accountFundstats,
      portfolioFunds,
      marketFunds,
      enhancePortfolioVisible,
      view,
      upDownDataSet, selectedBenchmark
    } = this.props;
    // console.log(filteredArray , filteredArray.length ,  portfolioEnhanceReplace.length);
    // console.log(filteredArray && filteredArray.length > 0 && (filteredArray.length !== portfolioEnhanceReplace.length));
    try {
      if (filteredArray && filteredArray.length > 0 && (filteredArray.length !== portfolioEnhanceReplace.length)) { // enhance ticker filter list not 0 or not all checked ?
        const ff = JSON.parse(JSON.stringify(portfolioEnhanceReplace));
        const filteredPortfolioEnhanceReplace = ff.filter(x => filteredArray.includes(x.ticker));
        const filterResultSetWillBe = resultSet.filter(rX => filteredArray.includes(rX.ticker));
        const excludedTickers = ff.filter(x => !filteredArray.includes(x.ticker));
        if (excludedTickers && excludedTickers.length > 0) {
          // console.log('excludedTickers', excludedTickers);
          let newReplaceData = [], pFF = {};
          excludedTickers.forEach((exT, iExT) => {
            const currentData = exT;
            const found = resultSet.filter((item) => item.ticker === currentData.ticker);
            if (found.length > 0 && found[0].target_card && found[0].target_card.funds && found[0].target_card.funds.length > 0) {
              const targetCardData = found[0].target_card.funds[0];
              const index = ff.findIndex(x => x.ticker === currentData.ticker);
              if (index >= 0) {
                let pF = {};
                if (view == 'account') {
                  pF = accountFundstats.funds.filter((dd) => (dd.script || dd.symbol) === targetCardData.ticker)[0];
                } else if (view == 'watchlist') {
                  pF = watchlistFundstats.positions.filter((dd) => (dd.script || dd.symbol) === targetCardData.ticker)[0];
                } else if (view == 'mrktPl') {
                  pF = marketFunds.filter((dd) => (dd.script || dd.symbol) === targetCardData.ticker)[0];
                } else {
                  pF = portfolioFunds.filter((dd) => ((dd.script || dd.symbol) === targetCardData.ticker) && dd.type !== 'SHARE')[0];
                }
                ff.splice(index, 1, {
                  query: currentData.query,
                  replace: currentData.ticker,
                  selectedPortfolioFunds: {
                    ...targetCardData,
                    query_var: found[0].result.query_var || [], // for showing correlation chart
                    [`overlap_${targetCardData.ticker}`]: targetCardData[`overlap_${targetCardData.ticker}`],
                    'Fee_Risk_Return': targetCardData.Fee_Risk_Return,
                    category: targetCardData.category,
                    ticker: targetCardData.ticker,
                    dividend_yield: targetCardData.dividend_yield,
                    fees: targetCardData.fees,
                    feesq: targetCardData.feesq,
                    name: targetCardData.name,
                    nav: targetCardData.nav,
                    returns: targetCardData.returns,
                    short_desc: targetCardData.short_desc,
                    short_name: targetCardData.short_name,
                    sponsor: targetCardData.sponsor,
                    vehicle: targetCardData.vehicle,
                    asset_alloc: targetCardData.asset_alloc,
                    weight_yield: targetCardData.weight_yield,
                    weight_track_record: targetCardData.weight_track_record,
                    'attribute/fundamental/yield': targetCardData['attribute/fundamental/yield'] || targetCardData['yield'],
                    outperform: targetCardData['outperform'] || 0.0,
                    'attribute/track_record': targetCardData['attribute/track_record'] || targetCardData['track_record'],
                    _pF: pF,
                  },
                  ticker: currentData.ticker,
                });
                // console.log('excludedTickers', ff);
                if (ff.length > 0) {
                  let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(upDownDataSet, selectedBenchmark)
                  statsForPerformanceImpact({
                    portfolioEnhanceReplace: ff,
                    watchlistFundstats,
                    accountFundstats,
                    portfolioFunds,
                    marketFunds,
                    portfolioQuery,
                    enhancePortfolioVisible,
                    view,
                    portfolioBenchmark
                  })
                    .then((res) => {
                      if (res.enhancedPerformanceImpact && res.portfolioEnhanceReplace && res.portfolioEnhanceReplace.length > 0) {
                        if (setPortfolioState) {
                          setPortfolioState({
                            enhancedPerformanceImpact: res.enhancedPerformanceImpact,
                            enhanceReplace: ff,
                            filterResultSet: filterResultSetWillBe,
                          });
                        }
                      }
                    });
                }
              }
            }
          });
        } else {
          // console.log('filteredPortfolioEnhanceReplace', filteredPortfolioEnhanceReplace);
          let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(upDownDataSet, selectedBenchmark)
          if (filteredPortfolioEnhanceReplace.length > 0) {
            statsForPerformanceImpact({
              portfolioEnhanceReplace: filteredPortfolioEnhanceReplace,
              watchlistFundstats,
              accountFundstats,
              portfolioFunds,
              marketFunds,
              portfolioQuery,
              enhancePortfolioVisible,
              view,
              portfolioBenchmark
            })
              .then((res) => {
                if (res.enhancedPerformanceImpact && res.portfolioEnhanceReplace && res.portfolioEnhanceReplace.length > 0) {
                  if (setPortfolioState) {
                    setPortfolioState({
                      enhancedPerformanceImpact: res.enhancedPerformanceImpact,
                      enhanceReplace: filteredPortfolioEnhanceReplace,
                      filterResultSet: filterResultSetWillBe,
                    });
                  }
                }
              });
          }
        }
      } else {
        // when evaluate options are unchecked then calc enhace stats for defaultChecked funds
        // console.log('defaultChecked', portfolioEnhanceReplace);
        let newReplaceData = [], pF = {};
        resultSet.map((e, eI) => {
          if (e.result.funds && e.result.funds.length > 0) {
            e.result.funds.forEach((item, itemIndex) => {
              if (itemIndex === 0) {
                item._selected = true;
                if (view == 'account') {
                  pF = accountFundstats.funds.filter((dd) => (dd.script || dd.symbol) === e.ticker)[0];
                } else if (view == 'watchlist') {
                  pF = watchlistFundstats.positions.filter((dd) => (dd.script || dd.symbol) === e.ticker)[0];
                } else if (view == 'mrktPl') {
                  pF = marketFunds.filter((dd) => (dd.script || dd.symbol) === e.ticker)[0];
                } else {
                  pF = portfolioFunds.filter((dd) => ((dd.script || dd.symbol) === e.ticker) && dd.type !== 'SHARE')[0];
                }

                newReplaceData.push({
                  query: e.query,
                  ticker: e.ticker,
                  replaceTicker: item.ticker,
                  replace: item.ticker,
                  selectedPortfolioFunds: {
                    ...item,
                    query_var: e.result.query_var || [], // for showing correlation chart
                    [`overlap_${e.ticker}`]: item[`overlap_${e.ticker}`],
                    'Fee_Risk_Return': item.Fee_Risk_Return,
                    category: item.category,
                    ticker: item.ticker,
                    dividend_yield: item.dividend_yield,
                    fees: item.fees,
                    feesq: item.feesq,
                    name: item.name,
                    nav: item.nav,
                    returns: item.returns,
                    short_desc: item.short_desc,
                    short_name: item.short_name,
                    sponsor: item.sponsor,
                    vehicle: item.vehicle,
                    asset_alloc: item.asset_alloc,
                    weight_yield: item.weight_yield,
                    weight_track_record: item.weight_track_record,
                    'attribute/fundamental/yield': item['attribute/fundamental/yield'] || item['yield'],
                    outperform: item['outperform'] || 0.0,
                    'attribute/track_record': item['attribute/track_record'] || item['track_record'],
                    'attribute/technical/returns/risk_adjusted_returns': item['attribute/technical/returns/risk_adjusted_returns'],
                  }, // NOTE:: need to remove unused keys from this object to only required ones, might be reason for speed issue
                  _pF: pF,
                });

              } else {
                item._selected = false;
              }
            });
          }
        });
        // console.log(newReplaceData);
        let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(upDownDataSet, selectedBenchmark)
        statsForPerformanceImpact({
          portfolioEnhanceReplace: newReplaceData,
          watchlistFundstats,
          accountFundstats,
          portfolioFunds,
          marketFunds,
          portfolioQuery,
          enhancePortfolioVisible,
          view,
          portfolioBenchmark
        })
          .then((res) => {
            if (res.enhancedPerformanceImpact && res.portfolioEnhanceReplace && res.portfolioEnhanceReplace.length > 0) {
              if (setPortfolioState) {
                const filterResultSetWillBe = resultSet.filter(rX => filteredArray.includes(rX.ticker));
                if (filterResultSetWillBe.length === 0) {
                  setPortfolioState({
                    enhancedPerformanceImpact: res.enhancedPerformanceImpact,
                    enhanceReplace: newReplaceData,
                    filterResultSet: resultSet,
                  });
                } else {
                  setPortfolioState({
                    enhancedPerformanceImpact: res.enhancedPerformanceImpact,
                    enhanceReplace: newReplaceData,
                    filterResultSet: filterResultSetWillBe,
                  });
                }

              }
            }
          });
      }
    } catch (e) {
      console.log(e);
    }
  }

  openDrawer = (visible) => {
    if (visible == true)
      addEventToAnalytics('Enhancer Summary', 'Enhancer Summary', 'ENHANCER_SUMMARY', {}, false);
    const { setPortfolioState, view, watchlistFundstats, accountFundstats } = this.props;
    if (view == "watchlist") {
      if (watchlistFundstats.tickers.length > 0) {
        if (setPortfolioState) {
          setPortfolioState({
            summaryDrawer: visible,
          });
        }
      }
    } else if (view == "account") {
      if (accountFundstats.tickers.length > 0) {
        if (setPortfolioState) {
          setPortfolioState({
            summaryDrawer: visible,
          });
        }
      }
    } else {
      if (setPortfolioState) {
        setPortfolioState({
          summaryDrawer: visible,
        });
      }
    }
  }

  generateTradelistCall = () => {
    addEventToAnalytics('Enhancer Tradelist', 'Enhancer Tradelist', 'ENHANCER_TRADELIST', {}, false);
    this.tradeList();
  }

  generateProposalCall = () => {
    addEventToAnalytics('Enhancer Report', 'Enhancer Report', 'ENHANCER_REPORT', {}, false);
    this.downloadPDF();
  }

  tradeList = () => {
    this.setState({
      generateTradelist: !this.state.generateTradelist,
      alert: false,
    })
    const { newFileName } = this.props;
    this.props.setEnhancedTransaction(newFileName);
  }

  downloadPDF = () => {
    const { view, downloadQuery, fundStats, investment, portfolio, downloadPortfolioReport, fundSelector, enhanceTickerFilter, profile, modelEnhancement } = this.props;
    let logo = profile.profile.custom_report_logo ? profile.profile.custom_report_logo : ''
    if (view == 'uplPrt') {
      if (fundStats && fundStats.funds) {
        // const { positions } = fundStats;
        // const ALL_FUNDS_TICKER = uniqValues(positions, 'symbol');

        let enhanceReplace = portfolio.generateProposalEnhanceReplace;
        enhanceReplace = enhanceReplace.filter(e => e.query != '').filter((k) => k.ticker !== k.replace); // selected EVALUATE & Selected FUNDS; //added for not displaying funds with no recomendations
        enhanceReplace.forEach((e, i) => {
          let itemResultSet = portfolio.resultSet.filter(el => el.ticker == e.ticker)[0];
          if (!this.props.hideGenerateProposalBtn && enhanceReplace[i].selectedPortfolioFunds && itemResultSet && itemResultSet.target_card && itemResultSet.target_card.funds[0]) {
            enhanceReplace[i].selectedPortfolioFunds.target_card = itemResultSet.target_card.funds[0];
            const selectedPortfolioTicker = enhanceReplace[i].selectedPortfolioFunds.ticker;
            enhanceReplace[i].selectedPortfolioFunds._frs = itemResultSet.result.funds.find(item => item.ticker === selectedPortfolioTicker)._frs;
          }
        });
        console.log(enhanceReplace);
        const obj = {
          investment: {
            portfolioValue: investment.portfolioValue
          },
          portfolio: {
            //mp query toggle
            isModelPortfolioQuery: this.props.hideGenerateProposalBtn,
            query: portfolio.downloadQuery,
            query_english: portfolio.query_english,
            enhanceReplace: enhanceReplace,
            fundSelector: fundSelector.myScoreAttrs,
            enhancedPerformanceImpact: portfolio.generateEnhancedPerformanceImpact,
            currentPerformanceImpact: portfolio.generateCurrentPerformanceImpact,
            selectedBenchmark: portfolio.selectedBenchmark,
            logo,
            modelEnhancement
          },
        };
        // obj.portfolio.currentPerformanceImpact.averageCostAdjRet10Yr /= 100;
        // obj.portfolio.enhancedPerformanceImpact.averageCostAdjRet10Yr /= 100;
        console.log('generate proposal', obj)
        // downloadPortfolioReport(obj);
      }
    }
  }
  onAlertDismiss = () => {
    // console.log('trrrr');
    const { setInvestmentState } = this.props
    setInvestmentState({
      reportPDFDownloadStatus: {
        message: '',
        downloadStatus: ''
      }
    });
  }
  downloadRecommedation = () => {
    let out = {
      positionsOut: {}
    }
    addEventToAnalytics('Rec-Report Download Request', 'Rec-Report Download Request', 'REC-REPORT DOWNLOAD REQUEST', { category: 'advisory_PTC' }, false);
    const { portfolio, profile, view, watchlistFundstats, accountFundstats, downloadRecEnhancerReport, portfolioFunds } = this.props
    //if replacement ticker is not selected or
    let TempResultSet = JSON.parse(JSON.stringify(portfolio.resultSet))
    let TempEnhanceReplace = JSON.parse(JSON.stringify(portfolio.enhanceReplace))

    if (TempResultSet.length !== TempEnhanceReplace.length) {
      console.log('errororororor')
    }

    let obj = TempResultSet.map((v, i) => {
      for (let j = 0; j < TempEnhanceReplace.length; j++) {
        if (v.ticker === TempEnhanceReplace[j].ticker) {
          return { ...v, selected: TempEnhanceReplace[j] ? { ...TempEnhanceReplace[j].selectedPortfolioFunds } : [] }
        }
      }
    })

    let positionsInt = []
    if (view === 'watchlist') {
      positionsInt = watchlistFundstats.positions
    } else if (view === 'account') {
      positionsInt = accountFundstats.funds
    } else if (view === 'uplPrt') {
      positionsInt = portfolioFunds
    } else if (view === 'mrktPl') {
      positionsInt = portfolioFunds
    }
    console.log(positionsInt)
    positionsInt.map(v => {
      out.positionsOut[v.symbol] = {
        price: v.price,
        weight: v.weight
      }
    })

    out.data = obj.map((v, i) => {
      let count = 0
      let temp = JSON.parse(JSON.stringify(v))
      temp.target_card = temp.target_card.funds[0]
      delete temp.target_card.region
      delete temp.target_card.return
      delete temp.target_card.returns
      delete temp.target_card._frs.region
      delete temp.target_card._frs.return
      delete temp.target_card._frs.returns
      delete temp.target_card._frs._frs


      delete temp.selected.region
      delete temp.selected.return
      delete temp.selected.returns
      delete temp.selected._pF

      temp.result.funds = temp.result.funds.map(d => {
        let funds = JSON.parse(JSON.stringify(d))
        if (funds._selected) {
          temp.selected._frs = { ...funds._frs }
        }
        if (count < 6 && !funds._selected) {
          count++
          delete funds.region
          delete funds.return
          delete funds.returns
          delete funds._frs.region
          delete funds._frs.return
          delete funds._frs.returns
          delete funds._frs._frs
          return funds
        }
      })
      return temp
    })
    out.queryEnglish = portfolio.query_english
    out.logo = profile.profile.custom_report_logo
    out.searchTypeMode = portfolio.searchTypeMode
    console.log(out)
    localStorage.setItem('rec', JSON.stringify(out))
    downloadRecEnhancerReport(out)
  }

  downloadModelReport = () => {
    addEventToAnalytics('Rec-Report Download Request', 'Rec-Report Download Request', 'REC-REPORT DOWNLOAD REQUEST', { category: 'advisory_PTC' }, false);
    let out = {};
    const { profile, portfolio, modelEnhancement, downloadRecEnhancerReport } = this.props;
    out.query = portfolio.query;
    out.query_english = portfolio.query_english;
    out.logo = profile.profile.custom_report_logo;
    out.searchTypeMode = portfolio.searchTypeMode;
    out.modelEnhancement = modelEnhancement;
    downloadRecEnhancerReport(out);
  }

  onCloseTradeList = () => {
    this.setState({
      generateTradelist: !this.state.generateTradelist,
    }, () => {
      setTimeout(() => {
        this.setState({
          alert: !this.state.alert,
        });
      }, 2000)
    })
  }

  DirectCloseTrade = () => {
    this.setState({
      generateTradelist: !this.state.generateTradelist,
    })
  }

  onDownloadTradeComplete = () => {
    const { setPortfolioState } = this.props
    this.setState({
      alert: !this.state.alert,
    }, () => {
      // setPortfolioState({ enhanceReplace: [], enhanceTickerFilter: [], resultSet: [], filterResultSet: [] })
    })
  }

  setFileName = (e) => {
    const name = document.getElementById('save-file-name-box').value;
    this.setState({ fileName: name })
  }

  savePortfolioEnhance = () => {
    addEventToAnalytics('Enhancer Save', 'Enhancer Save', 'ENHANCER_SAVE', {}, false);
    const { saveEnhanceProposalPortfolio, enhancedPortfolioName, enhancedPortfolioId, redirectFrom, setBuildPortfolioState } = this.props;
    const { fileName } = this.state;
    if (redirectFrom.module == 'builder') {
      setBuildPortfolioState({
        portfolioName: redirectFrom.portfolioName,
        savedPortfolio: redirectFrom.savedPortfolio,
      });
      this.props.history.push(redirectFrom.link);
    } else if (fileName == '') {
      this.setState({ fileNameError: true })
    } else {
      this.setState({ fileName: '', fileNameError: false },
        () => saveEnhanceProposalPortfolio({
          portfolio_id: enhancedPortfolioId,
          portfolio_name: fileName
        }, this.portfolioSaveSuccess(fileName, enhancedPortfolioId))
      )
    }
  }

  portfolioSaveSuccess = (fileName, enhancedPortfolioId) => {
    this.setState({ fileNameError: false, saveEnhancePortfolio: false, enableEnhancePortfolioQueries: false }, () => {
      const { setPortfolioState } = this.props;
      setPortfolioState({
        enableEnhancePortfolioQueries: this.state.enableEnhancePortfolioQueries,
      }, this.updateClientDetails(fileName, enhancedPortfolioId));
    });
  }

  updateClientDetails = (fileName, enhancedPortfolioId) => {
    const { redirectFrom, setBuildPortfolioState } = this.props;
    if (this.state.shouldReturn) {
      let { link } = redirectFrom
      if (redirectFrom.module == 'go') {
        let { payload, payload: { clientDetails } } = redirectFrom;
        let updatedPortfolio = {
          ...redirectFrom,
          payload: {
            ...payload,
            clientDetails: {
              ...clientDetails,
              custom_portfolio: {
                portfolio_name: fileName
              },
              model_portfolio: {},
              selected_portfolio: fileName
            }
          },
          portfolioName: fileName,
          returnPortfolioID: enhancedPortfolioId
        }
        this.props.investmentOptionsRedirect({ redirectFrom: updatedPortfolio, copyOfRedirectFrom: updatedPortfolio }, this.redirectTo);
      }
    } else {
      this.setState({ shouldReturn: false });
    }
  }

  redirectTo = () => {
    let { link } = this.props.redirectFrom;
    this.props.history.push(link)
  }

  onClickFinalize = () => {
    addEventToAnalytics('Enhancer Finalize', 'Enhancer Finalize', 'ENHANCER_FINALIZE', { category: 'advisory_PTC' }, false);
    if (this.props.portfolioEnhanceReplace.length > 0) {
      let currentPI = JSON.parse(JSON.stringify(this.props.portfolio.currentPerformanceImpact));
      let enahncedPI = JSON.parse(JSON.stringify(this.props.portfolio.enhancedPerformanceImpact));
      let enhanceRep = JSON.parse(JSON.stringify(this.props.portfolioEnhanceReplace));
      this.props.setPortfolioState({ generateProposalEnhanceReplace: enhanceRep, generateCurrentPerformanceImpact: currentPI, generateEnhancedPerformanceImpact: enahncedPI });
      this.saveEnhancedOption('analytics');
    }
  }

  onClickEnhancePortfolio = () => {
    addEventToAnalytics('Enhancer Enhance', 'Enhancer Enhance', 'ENHANCER_ENHANCE', {}, false);
    this.setState({ enableEnhancePortfolioQueries: true, enableFirstEnhancePortfolioQueries: true }, () => {
      const { setPortfolioState } = this.props;
      setPortfolioState({
        enableEnhancePortfolioQueries: this.state.enableEnhancePortfolioQueries,
      });
    });
    this.props.changeCalculator('passive fund selector');
  }

  onClickEnhanceModelPortfolio = () => {
    addEventToAnalytics('Enhancer Model', 'Enhancer Model', 'ENHANCER_MODEL', {}, false);
    this.setState({ enableEnhancePortfolioQueries: true, enableFirstEnhancePortfolioQueries: true }, () => {
      const { setPortfolioState } = this.props;
      setPortfolioState({
        enableEnhancePortfolioQueries: this.state.enableEnhancePortfolioQueries,
        triggerModelPortfolioQuery: true,
      });
    });
  }

  toggleFirstTimeEnhanceQuerySelction = () => {
    this.setState({ enableFirstEnhancePortfolioQueries: !this.state.enableFirstEnhancePortfolioQueries })
  }

  saveEnhancedOption = (action) => {
    const { view, portfolioQuery, activeAccount, filename, portfolioEnhanceReplace, saveEnhancedOption, setPortfolioState, saveAccountEnhancedOption, watchlistFundstats, accountFundstats, marketFundStats, redirectFrom, enhanceTickerFilter } = this.props;

    const _portfolioEnhanceReplace = portfolioEnhanceReplace.filter((item) => enhanceTickerFilter.includes(item.ticker)).filter((k) => k.ticker !== k.replace); // selected EVALUATE & Selected FUNDS

    setPortfolioState({ downloadQuery: portfolioQuery });

    const { setInvestmentState, oldViewBeforeUpload, enhancePortfolioVisible, finalizeReplaceModelPortfolioRequest } = this.props;

    setInvestmentState({ oldViewBeforeUpload: view });

    if (view == "uplPrt") {
      if (enhancePortfolioVisible && finalizeReplaceModelPortfolioRequest) { // model portfolio
        //dispatch model portfolio api action
        // console.log('dispatch model portfolio api action');
        if (portfolioEnhanceReplace && portfolioEnhanceReplace.length > 0) {
          const requestData = portfolioEnhanceReplace[0];
          if (requestData && requestData.selectedPortfolioFunds && requestData.ticker && requestData.replace) {
            const replaceDataArr = requestData.selectedPortfolioFunds.holdings.map((o) => {
              return {
                ticker: o.h_ticker,
                shares: o._h_quantity,
                "purchase_price": o.nav,
                "purchase_date": moment(new Date()).format('DD-MM-YYYY'),
              };
            });
            let obj = {
              "portfolio_name": requestData.ticker, // current_portfolio's name
              "replace_portfolio_type": "custom_portfolio", // custom_portfolio/model_portfolio
              "replace_portfolio_id": requestData.replace, // model_portfolio's ticker
              "replace_data": replaceDataArr,
            }
            if (redirectFrom.module == 'builder') {
              obj.portfolio_overwrite = true;
            }
            finalizeReplaceModelPortfolioRequest({
              request: obj,
              enhanceCTA: 'Finalize',
              enhancementScreenView: view,
            }, () => this.setFilename(activeAccount));
          }
        } else {
          console.log('------ portfolioEnhanceReplace is MISSING ------');
          console.log(portfolioEnhanceReplace);
          console.log('--------------------------------------------------------');
        }
      } else {
        if (_portfolioEnhanceReplace && _portfolioEnhanceReplace.length > 0) {
          // _portfolioEnhanceReplace.map((item) => { delete item.query; delete item.selectedPortfolioFunds });
          this.setState({ btnError: false, action }, () => {
            let enhanceOptionObj = { "portfolio_name": filename, "replace": _portfolioEnhanceReplace, enhanceCTA: 'Finalize' }
            if (this.props.redirectFrom.module == 'builder') {
              enhanceOptionObj.portfolio_overwrite = true;
            }
            saveEnhancedOption(enhanceOptionObj, () => this.setFilename(activeAccount));
          })
        } else this.setState({ btnError: true })
      }
    } else if (view == "mrktPl") {
      if (enhancePortfolioVisible && finalizeReplaceModelPortfolioRequest) { // model portfolio
        //dispatch model portfolio api action
        // console.log('dispatch model portfolio api action');
        if (portfolioEnhanceReplace && portfolioEnhanceReplace.length > 0) {
          const requestData = portfolioEnhanceReplace[0];
          if (requestData && requestData.selectedPortfolioFunds && requestData.ticker && requestData.replace) {
            const replaceDataArr = requestData.selectedPortfolioFunds.holdings.map((o) => {
              return {
                ticker: o.h_ticker,
                shares: o._h_quantity,
                "purchase_price": o.nav,
                "purchase_date": moment(new Date()).format('DD-MM-YYYY'),
              };
            });
            // console.log(replaceDataArr);
            finalizeReplaceModelPortfolioRequest({
              request: {
                "portfolio_name": requestData.ticker, // current_portfolio's name
                "replace_portfolio_type": "custom_portfolio", // custom_portfolio/model_portfolio
                "replace_portfolio_id": requestData.replace, // model_portfolio's ticker
                "replace_data": replaceDataArr,
                data: marketFundStats.tickers,
              },
              enhanceCTA: 'Finalize',
              enhancementScreenView: view,
            }, () => this.setFilename(activeAccount));
          }
        } else {
          console.log('------ _portfolioEnhanceReplace is MISSING ------');
          console.log(_portfolioEnhanceReplace);
          console.log('--------------------------------------------------------');
        }
      }
      else {
        if (_portfolioEnhanceReplace && _portfolioEnhanceReplace.length > 0) {
          saveAccountEnhancedOption({ "portfolio_name": filename, "replace": _portfolioEnhanceReplace, data: marketFundStats.tickers, enhanceCTA: 'Finalize' }, () => this.setFilename(activeAccount))
        } else {
          console.log('------ _portfolioEnhanceReplace is MISSING ------');
          console.log(_portfolioEnhanceReplace);
          console.log('--------------------------------------------------------');
        }
      }
    } else if (view == "watchlist") {
      if (enhancePortfolioVisible && finalizeReplaceModelPortfolioRequest) { // model portfolio
        //dispatch model portfolio api action
        // console.log('dispatch model portfolio api action');
        if (portfolioEnhanceReplace && portfolioEnhanceReplace.length > 0) {
          const requestData = portfolioEnhanceReplace[0];
          if (requestData && requestData.selectedPortfolioFunds && requestData.ticker && requestData.replace) {
            const replaceDataArr = requestData.selectedPortfolioFunds.holdings.map((o) => {
              return {
                ticker: o.h_ticker,
                shares: o._h_quantity,
                "purchase_price": o.nav,
                "purchase_date": moment(new Date()).format('DD-MM-YYYY'),
              };
            });
            // console.log(replaceDataArr);
            finalizeReplaceModelPortfolioRequest({
              request: {
                "portfolio_name": requestData.ticker, // current_portfolio's name
                "replace_portfolio_type": "custom_portfolio", // custom_portfolio/model_portfolio
                "replace_portfolio_id": requestData.replace, // model_portfolio's ticker
                "replace_data": replaceDataArr,
                data: watchlistFundstats.tickers,
              },
              enhanceCTA: 'Finalize',
              enhancementScreenView: view,
            }, () => this.setFilename(activeAccount));
          }
        } else {
          console.log('------ _portfolioEnhanceReplace is MISSING ------');
          console.log(_portfolioEnhanceReplace);
          console.log('--------------------------------------------------------');
        }
      } else {
        if (portfolioEnhanceReplace && portfolioEnhanceReplace.length > 0) {
          saveAccountEnhancedOption({ "portfolio_name": 'watchlist', "replace": _portfolioEnhanceReplace, data: watchlistFundstats.tickers, enhanceCTA: 'Finalize' }, () => this.setFilename(activeAccount))
        } else {
          console.log('------ _portfolioEnhanceReplace is MISSING ------');
          console.log(_portfolioEnhanceReplace);
          console.log('--------------------------------------------------------');
        }
      }
    } else if (view == "account") {
      if (enhancePortfolioVisible && finalizeReplaceModelPortfolioRequest) { // model portfolio
        //dispatch model portfolio api action
        // console.log('dispatch model portfolio api action');
        if (portfolioEnhanceReplace && portfolioEnhanceReplace.length > 0) {
          const requestData = portfolioEnhanceReplace[0];
          if (requestData && requestData.selectedPortfolioFunds && requestData.ticker && requestData.replace) {
            const replaceDataArr = requestData.selectedPortfolioFunds.holdings.map((o) => {
              return {
                ticker: o.h_ticker,
                shares: o._h_quantity,
                "purchase_price": o.nav,
                "purchase_date": moment(new Date()).format('DD-MM-YYYY'),
              };
            });
            finalizeReplaceModelPortfolioRequest({
              request: {
                "portfolio_name": requestData.ticker, // current_portfolio's name
                "replace_portfolio_type": "custom_portfolio", // custom_portfolio/model_portfolio
                "replace_portfolio_id": requestData.replace, // model_portfolio's ticker
                "replace_data": replaceDataArr,
                data: accountFundstats.tickers,
              },
              enhanceCTA: 'Finalize',
              enhancementScreenView: view,
            }, () => this.setFilename(activeAccount));
          }
        } else {
          console.log('------ _portfolioEnhanceReplace is MISSING ------');
          console.log(_portfolioEnhanceReplace);
          console.log('--------------------------------------------------------');
        }
      } else {
        if (portfolioEnhanceReplace && portfolioEnhanceReplace.length > 0) {
          saveAccountEnhancedOption({ "portfolio_name": filename, "replace": _portfolioEnhanceReplace, data: accountFundstats.tickers, enhanceCTA: 'Finalize' }, () => this.setFilename(activeAccount))
        } else {
          console.log('------ _portfolioEnhanceReplace is MISSING ------');
          console.log(_portfolioEnhanceReplace);
          console.log('--------------------------------------------------------');
        }
      }
    }
  }

  setFilename = (filename) => {
    const { investmentFetchSuccess } = this.props
    investmentFetchSuccess({ activeAccount: filename })
  }

  returnHome = () => {
    addEventToAnalytics('Enhancer Edit Home', 'Enhancer Edit Home', 'ENHANCER_EDIT_HOME', {}, false);
    const { setPortfolioDefault, setInvestmentDefault, investmentFetch, disableEnhanceBtnControlState } = this.props;
    disableEnhanceBtnControlState();
    investmentFetch();
    setPortfolioDefault();
    setInvestmentDefault();
  }

  handleUpDownDropDownChange = (value) => {
    const { setPortfolioState, upDownDataSet, portfolio } = this.props
    let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(upDownDataSet, value)
    console.log(portfolio.enhancedPerformanceImpact)
    if (Object.keys(portfolio.enhancedPerformanceImpact).length > 0) {
      let { pfRet1y, pfRet3y, pfRet5y } = portfolio.enhancedPerformanceImpact
      let { pfRet1y: pfRet1yC, pfRet3y: pfRet3yC, pfRet5y: pfRet5yC } = portfolio.currentPerformanceImpact
      let { up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr } = geopMeanCalc(portfolioBenchmark, pfRet1y, pfRet3y, pfRet5y)
      let { up_1yr: up_1yrC, down_1yr: down_1yrC, up_3yr: up_3yrC, down_3yr: down_3yrC, up_5yr: up_5yrC, down_5yr: down_5yrC } = geopMeanCalc(portfolioBenchmark, pfRet1yC, pfRet3yC, pfRet5yC)
      let updateEnhanceStats = { ...portfolio.enhancedPerformanceImpact, up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr }
      let updatedCurrentStats = { ...portfolio.currentPerformanceImpact, up_1yr: up_1yrC, down_1yr: down_1yrC, up_3yr: up_3yrC, down_3yr: down_3yrC, up_5yr: up_5yrC, down_5yr: down_5yrC }

      this.setState({
        enhanceStats: updateEnhanceStats,
        currentStats: updatedCurrentStats
      })
      setPortfolioState({
        selectedBenchmark: value,
        currentPerformanceImpact: updatedCurrentStats,
        enhancedPerformanceImpact: updateEnhanceStats
      });

    } else {
      let { pfRet1y, pfRet3y, pfRet5y } = portfolio.currentPerformanceImpact
      let { up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr } = geopMeanCalc(portfolioBenchmark, pfRet1y, pfRet3y, pfRet5y)
      let updatedCurrentStats = { ...portfolio.currentPerformanceImpact, up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr }

      this.setState({
        currentStats: updatedCurrentStats
      })

      setPortfolioState({
        selectedBenchmark: value,
        currentPerformanceImpact: updatedCurrentStats
      });
    }

  }
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    let props = DeepDif(nextProps, this.props)
    let state = DeepDif(nextState, this.state)
    let context = DeepDif(nextContext, this.context)
    return (!!props || !!state || !!context)
  }

  render() {
    // if ((isEmpty(getQueryItems(this.props)) || getQueryItems(this.props) === null) && (!this.props.match.params.id || this.props.match.params.id == '') ) {
    //   window.location.href = REDIRECT_DOMAIN_PATH;
    //   return;
    // }

    const {
      view,
      modalToggleState,
      loggedIn,
      loading,
      funds,
      startTime,
      loadPercent,
      location,
      isTermsAgreed,
      registerSuccess,
      accConfirmedModal,
      loggingIn,
      surveyModal,
      portfolioLoading,
      setPfEnhancementContent,
      reportLoader,
      reportPDFDownloadStatus,
      onBoardScreen,
      resultSet,
      summaryDrawer,
      enableEnhanceBtnControl,
      portfolioEnhanceReplace,
      portfolioSave,
      portfolioSaveError,
      letPortfolioLoad,
      portfolioQuery,
      portfolio,
      enableEnhancePortfolioQueries,
      buildportfolio,
      selectorCount,
      enhancePortfolioVisible,
      hideGenerateProposalBtn,
      redirectFrom,
      clientListLoading,
      enhanceTickerFilter,
      readableQuery,
      activeAccount,
      investment,
      upDownDataSet,
      selectedBenchmark,
      guidanceMessage,
    } = this.props;
    const { setPfEnhancement, tickers, currentStats, enhanceStats, header2Disable, saveEnhancePortfolio, fileNameError, moduleLoading } = this.state;
    const { pathname } = location;
    const { data } = funds;
    const enabledReservedRoutes = ReservedRoutes.includes(pathname)
    const blurClass = (this.props.sfmToggle || enabledReservedRoutes) ? 'blur-effect' : '';
    let renderModal = '';
    let disabled = (this.props.portfolioEnhanceReplace && this.props.portfolioEnhanceReplace.length === 0) ? true : false;

    if (loggedIn && isTermsAgreed === 0) {
      renderModal = <TermsNConditions />
    } else if (loggedIn && accConfirmedModal) {
      renderModal = <AccountConfirmed />
    }

    const drawerStyle = {
      overflow: 'hidden',
      position: 'relative',
    };

    const mDrawerHeader = (setPfEnhancementContent) => {
      const renderDesign = setPfEnhancementContent ? <span>Performance Summary </span> : <span>
        Performance Impact <span className="sub-head-label">(
          {this.props.portfolioEnhanceReplace.length} {this.props.portfolioEnhanceReplace.length > 0 && this.props.portfolioEnhanceReplace.length == 1 ? 'ticker' : 'tickers'} replaced )
        </span>
      </span>
      return renderDesign
    };

    // <Row id="headerWrapper" className={`headerWrapper ${blurClass}`}>
    //   <Header
    //     count={buildportfolio.clearBuilderFunds ? 0 : buildportfolio.resultPageCart.length}
    //     onLogout={this.props.logoutHandler}
    //     isLoggedIn={this.props.loggedIn || loggingIn}
    //     selectorCount={selectorCount}
    //     {...this.props}
    //   />
    // </Row>

    return (
      <React.Fragment>
        <div className="homeWrapper">
          {
            !onBoardScreen && typeof currentStats !== 'undefined' && typeof currentStats.pfRet1y !== 'undefined' && (currentStats || this.props.enhancedPerformanceImpact) &&
            <PortfolioModal
              query={portfolioQuery}
              openDrawer={this.openDrawer}
              visible={summaryDrawer}
              enhancePortfolioVisible={enhancePortfolioVisible}
              setPfEnhancementContent={setPfEnhancementContent}
              portfolioEnhanceReplace={portfolioEnhanceReplace}
              enhanceStats={this.props.enhancedPerformanceImpact}
              currentStats={currentStats}
              mask={false}
              enhanceTickerFilter={enhanceTickerFilter}
              closable
              portfolioBenchmarkData={upDownDataSet}
              selectedPortfolioBenchmarkTicker={selectedBenchmark}
              handleUpDownDropDownChange={this.handleUpDownDropDownChange}
              toggleChange={this.toggleChange}
              setPfEnhancement={this.setPfEnhancement}
              evaluateOnChange={this.evaluateOnChange}
              setPortfolioState={this.props.setPortfolioState}
              replacePortfolioToggle={this.props.replacePortfolioToggle}
            />
          }

          <Row id="contentWrapper" className={`contentWrapper main ${blurClass}`} style={drawerStyle}>
            {(!onBoardScreen)
              && (
                <Col xs="3" sm="3" lg="3" className="sideBarContainer">
                  <SideBar
                    {...this.props}
                    setContent={this.setContent}
                    setPfEnhancement={this.setPfEnhancement}
                    enableEnhancePortfolioQueries={enableEnhancePortfolioQueries}
                    toggleFirstTimeEnhanceQuerySelction={this.toggleFirstTimeEnhanceQuerySelction}
                    enableFirstEnhancePortfolioQueries={this.state.enableFirstEnhancePortfolioQueries}
                  />
                </Col>
              )
            }
            <Col xs={`${!onBoardScreen ? "9" : "12"}`} sm={`${!onBoardScreen ? "9" : "12"}`} lg={`${!onBoardScreen ? "9" : "12"}`} className="contentContainer">
              {(onBoardScreen)
                && <OnBoardScreen
                  {...this.props}
                  setContent={this.setContent}
                  setPfEnhancement={this.setPfEnhancement}
                />
              }
              {(!onBoardScreen)
                && (
                  <div>
                    <Row id="pf-content-header" className="pf-content-header">
                      <Col xs="8" sm="8" lg="8" style={{ paddingLeft: 0 }}>
                        {/*
                          {
                            (!setPfEnhancementContent && !(typeof enableEnhanceBtnControl !== 'undefined' && enableEnhanceBtnControl)) && 'Portfolio Summary'
                          }
                          {
                            setPfEnhancementContent && 'Enhancement Ideas'
                          }
                          {
                            (typeof enableEnhanceBtnControl !== 'undefined' && enableEnhanceBtnControl) && 'Enhanced Portfolio Summary'
                          }
                        */}
                        <span style={{ color: '#494949', marginRight: 5 }}>Enhancement Ideas For </span>
                        <span>
                          {activeAccount}
                        </span>
                      </Col>
                      <Col className="d-flex align-items-center" xs="4" sm="4" lg="4" style={{ padding: 0, justifyContent: 'flex-end' }}>
                        <Button
                          shape="round"
                          className="primary-button return-home-button"
                          type="primary"
                          style={{ padding: 0 }}
                          onClick={() => this.returnHome()}
                        >
                          <img width="28px" src="/assets/images/reload.svg" />
                        </Button>
                      </Col>
                      {
                        setPfEnhancementContent && resultSet && resultSet.length > 0 &&
                        <Col xs="12" sm="12" lg="12" className="d-flex align-items-center" style={{ paddingLeft: '0px', paddingRight: 0, justifyContent: (readableQuery && readableQuery !== '') ? 'space-between' : 'flex-end' }}>
                          {(readableQuery && readableQuery !== '') && <div className="pf-showing-result-label">{guidanceMessage && guidanceMessage.length > 0 ? guidanceMessage : `Showing results for “${readableQuery}“`}</div>}
                          <PortfolioFilter setPfEnhancement={this.setPfEnhancement} toggleChange={this.toggleChange} evaluateOnChange={this.evaluateOnChange} />
                        </Col>
                      }
                      {
                        enhancePortfolioVisible && setPfEnhancementContent && (
                          <Col xs="12" sm="12" lg="12" className="d-flex align-items-center" style={{ paddingLeft: '0px', paddingRight: 0, justifyContent: (readableQuery && readableQuery !== '') ? 'space-between' : 'flex-end' }}>
                            {(readableQuery && readableQuery !== '') && <div className="pf-showing-result-label">{guidanceMessage && guidanceMessage.length > 0 ? guidanceMessage : `Showing results for “${readableQuery}“`}</div>}
                          </Col>
                        )
                      }
                    </Row>
                    <Row className={`portfolio-summary-btn-wrap d-flex ${(typeof enableEnhanceBtnControl === 'undefined' || !enableEnhanceBtnControl) ? 'justify-content-end' : 'justify-content-between'} `}>
                      {(typeof enableEnhanceBtnControl !== 'undefined' && enableEnhanceBtnControl)
                        && (
                          <div>
                            <Button className="portfolio-summary-btn mg-rt-15" onClick={() => { this.generateTradelistCall() }}>
                              Generate Tradelist
                            </Button>

                            <Button className="portfolio-summary-btn mg-rt-15" onClick={() => { this.generateProposalCall() }}>
                              Generate Proposal
                            </Button>

                            {redirectFrom.module && redirectFrom.module == 'go' || redirectFrom.module == 'builder' ? <Button className="portfolio-summary-btn mg-rt-15" onClick={() => redirectFrom.module == 'builder' ? this.savePortfolioEnhance(true) : this.setState({ saveEnhancePortfolio: true, shouldReturn: true })}>
                              Save & Return
                            </Button> :
                              <Button className="portfolio-summary-btn mg-rt-15" onClick={() => { addEventToAnalytics('Enhancer Save Btn', 'Enhancer Save Btn', 'ENHANCER_SAVE_BTN', { slider: this.state.label }, false); this.setState({ saveEnhancePortfolio: true }) }}>
                                Save Portfolio
                              </Button>}
                          </div>
                        )
                      }
                      {setPfEnhancementContent && <div style={{ marginRight: 'auto' }}>
                        <Button className="portfolio-summary-btn mg-rt-15" onClick={() => { this.onClickFinalize() }} disabled={disabled}>
                          Finalize
                        </Button>
                        {portfolio.searchTypeMode === 'FUNDS' && <Button disabled={portfolio.enhanceReplace.length === 0} className="portfolio-summary-btn mg-rt-15" onClick={() => { this.downloadRecommedation() }} >
                          Generate Proposal
                        </Button>}
                        {portfolio.searchTypeMode === 'MODELS' && <Button disabled={portfolio.enhanceReplace.length === 0} className="portfolio-summary-btn mg-rt-15" onClick={() => { this.downloadModelReport() }} >
                          Generate Proposal
                        </Button>}
                      </div>}
                      {

                        /*

                        {(!(typeof enableEnhanceBtnControl !== 'undefined' && enableEnhanceBtnControl) &&  !setPfEnhancementContent) && <div style={{ marginRight: (view === 'watchlist') ? 'auto' : '',display:`flex`,alignItems:'center' }}>
                          <p className={'portfolio-summary-pre-text'}>Enhance Using :</p>
                          <Button className="portfolio-summary-btn mg-rt-15" onClick={() => {this.onClickEnhancePortfolio()}} >
                           Funds
                        </Button>
                        </div>}
                        {(!(typeof enableEnhanceBtnControl !== 'undefined' && enableEnhanceBtnControl) &&  !setPfEnhancementContent && view !== 'watchlist') && <div style={{ marginRight: 'auto' }}>
                        <Button className="portfolio-summary-btn mg-rt-15" onClick={() => {this.onClickEnhanceModelPortfolio()}} >
                           Models
                        </Button>
                        </div>}


                        */
                      }
                      <Button className="portfolio-summary-btn pd-rt-36" onClick={() => { this.openDrawer(true) }} disabled={this.props.portfolio.header2Disable}>
                        {`${!setPfEnhancementContent ? "Performance Summary" : "Performance Impact"}`} <RightOutlined />
                      </Button>
                    </Row>
                    <Row>
                      <Col xs="12" sm="12" lg="12" >
                        {setPfEnhancementContent ? <PfUpload /> : <Content {...this.props} />}
                      </Col>
                    </Row>
                    {(this.state.generateTradelist)
                      && (
                        <GenerateTradeList onCloseClick={this.onCloseTradeList} onDownload={this.onDownloadTradeComplete} OnDirectClose={this.DirectCloseTrade} />
                      )
                    }
                    {(this.state.alert)
                      && (
                        <CustomAlert className="alert-windown-wrapper" color='info'>
                          Tradelist Download Successfully
                        </CustomAlert>
                      )
                    }
                    {(reportPDFDownloadStatus && reportPDFDownloadStatus.message !== '' && reportPDFDownloadStatus.downloadStatus !== '')
                      && (
                        <CustomAlert className="alert-windown-wrapper" color={reportPDFDownloadStatus.downloadStatus} onDismissCallback={this.onAlertDismiss}>
                          {reportPDFDownloadStatus.message}
                        </CustomAlert>
                      )
                    }
                  </div>
                )
              }
            </Col>

            {(moduleLoading || reportLoader || portfolioLoading || letPortfolioLoad || clientListLoading)
              && (
                <Loader id="account-page-loader" loading={true} />
              )
            }
          </Row>
          {renderModal}
        </div>

        <Modal
          isOpen={saveEnhancePortfolio}
          toggle={() => this.setState({ saveEnhancePortfolio: !saveEnhancePortfolio, shouldReturn: false })}
          centered
          keyboard={true}
          backdrop
          className="premium-msg-modal account-modal save-portfolio-modal"
          backdropClassName="premium-feature-process-modal-backdrop"
        >
          <ModalBody className="ssf-modal__body">
            <div className="premium-feature-process-modal-container">
              <div className="header-wrapper">
                <h1 className="text-align-left">Save Your Enhanced Portfolio</h1>
              </div>
              <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => this.setState({ saveEnhancePortfolio: false, shouldReturn: false })}></i>
              <p className="content-wrapper">
                <form className="upload-form-content">
                  <FormGroup className="element-box pos-rel">
                    <label>Name</label>
                    <input
                      id="save-file-name-box"
                      className="input-area"
                      type="text"
                      // placeholder="Give your portfolio a nickname"
                      onChange={this.setFileName}
                      autoComplete="off"
                    />
                    {fileNameError && <span className="upload-error">Please enter a Name to save</span>}
                    {portfolioSave && <span className="upload-error">{portfolioSaveError != '' ? portfolioSaveError : 'Error on file save'}</span>}
                  </FormGroup>
                  <div className="col-12 cta-wrapper ">
                    <button type="button" id="save-prtf" className="ssf-btn-primary btn secondary-btn btn btn-secondary" onClick={() => this.savePortfolioEnhance('')}><span>SAVE</span></button>
                  </div>
                </form>
              </p>

            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  buildportfolio: state.buildportfolio,
  investment: state.investment,
  portfolio: state.portfolio,
  view: state.investment.view,
  investmentFunds: state.investment.funds,
  funds: state.funds,
  query: state.funds.query,
  loadPercent: state.global.fundsApiCallPercent,
  surveyModal: state.global.surveyModal,
  sfmToggle: state.sfm.modalToggleState,
  modalToggleState: state.sfm.modalToggleState,
  location: state.router.location,
  isTermsAgreed: state.auth.termsAgreed,
  isNewUser: state.auth.newUser,
  loggedIn: state.auth.loggedIn,
  loggingIn: state.auth.loggingIn,
  accConfirmedModal: state.auth.accConfirmedModal,
  registerSuccess: state.register.registerSuccess,
  useremail: state.auth.user.email,
  firstName: state.auth.user.firstName,
  lastName: state.auth.user.lastName,
  portfolioLoading: state.investment.portfolioLoading || state.investment.reportLoader,
  setPfEnhancementContent: state.investment.setPfEnhancementContent,
  reportLoader: state.investment.reportLoader || state.investment.loading,
  reportPDFDownloadStatus: state.investment.reportPDFDownloadStatus,
  summaryDrawer: state.portfolio.summaryDrawer,
  onBoardScreen: state.portfolio.onBoardScreen,
  resultSet: state.portfolio.resultSet,
  enhancedPortfolioName: state.portfolio.enhancedPortfolioName,
  enhancedPortfolioId: state.portfolio.enhancedPortfolioId,
  portfolioSave: state.portfolio.portfolioSave,
  portfolioSaveError: state.portfolio.portfolioSaveError,
  portfolioFunds: state.investment.portfolioFunds,
  marketFunds: state.investment.marketFunds,
  filename: getFileName(state), // state.investment.filename,
  newFileName: state.investment.filename,
  enableEnhanceBtnControl: state.portfolio.enableEnhanceBtnControl,
  fundStats: getPortAccountStats(state.investment),
  portfolioEnhanceReplace: getPortfolioEnhanceReplace(state), // state.portfolio.enhanceReplace,
  downloadQuery: state.portfolio.downloadQuery,
  portfolioQuery: state.portfolio.query,
  watchlistFundstats: getInvestmentWatchlistStats(state),
  accountFundstats: getInvestmentPositionStats(state),
  marketFundStats: getMarketStats(state),
  activeAccount: state.investment.activeAccount,
  letPortfolioLoad: state.portfolio.loading || createLoadingSelector(['GET_MY_CURRENT_PORTFOLIO', 'GET_REPLACE_PORTFOLIO_OPTIONS', 'FINALIZE_REPLACE_MODEL_PORTFOLIO', 'CHECK_TICKER_EXIST', 'GET_REAL_TIME_PRICE_TICKERS', 'LOAD_DEMO_CSV'])({ loading: state.loading }),
  oldViewBeforeUpload: state.investment.oldViewBeforeUpload,
  enableEnhancePortfolioQueries: state.portfolio.enableEnhancePortfolioQueries,
  fundSelector: state.weights,
  redirectFrom: state.investment.redirectFrom,
  selectorCount: state.sfm.selectorCount,
  enhancePortfolioVisible: state.portfolio.enhancePortfolioVisible,
  enhanceModelPortfolio: state.portfolio.enhancePortfolio,
  investmentFetchLoading: createLoadingSelector(['INVESTMENT_FETCH'])({ loading: state.loading }),
  hideGenerateProposalBtn: state.portfolio.hideGenerateProposalBtn,
  currentPerformanceImpact: state.portfolio.currentPerformanceImpact,
  enhancedPerformanceImpact: state.portfolio.enhancedPerformanceImpact,
  enhanceTickerFilter: state.portfolio.enhanceTickerFilter,
  replacePortfolioToggle: state.portfolio.replacePortfolioToggle,
  readableQuery: state.portfolio.query_english,
  returnsData: state.investment.portfolioRetData,
  upDownDataSet: state.portfolio.upDownDataSet,
  selectedBenchmark: state.portfolio.selectedBenchmark,
  profile: state.profile,
  modelEnhancement: state.portfolio.modelEnhancement,
  guidanceMessage: state.portfolio.guidanceMessage,
});

const mapDispatchToProps = {
  logoutHandler: logout,
  setFundsState,
  refreshFundsState,
  setGlobalState,
  investmentFetch,
  investmentFetchSuccess,
  setPortfolioState,
  setEnhancedTransaction,
  saveEnhanceProposalPortfolio,
  downloadPortfolioReport,
  downloadRecEnhancerReport,
  clearFundsApiLoadPercent,
  searchQuery,
  saveEnhancedOption,
  saveAccountEnhancedOption,
  setInvestmentState,
  changeCalculator,
  subscriptionPlan,
  investmentOptions,
  investmentOptionsRedirect,
  setPortfolioReplace,
  fetchPortfolioData,
  fetchMarketData,
  finalizeReplaceModelPortfolioRequest,
  resetInvestmentState,
  resetPortfolioState,
  setBuildPortfolioState,
  setInvestmentDefault,
  setPortfolioDefault,
  disableEnhanceBtnControlState,
  fetchProfile,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountsPage)

import React, { Component } from 'react';
import {
  UncontrolledTooltip,
  Button,
  Input,
  Card,
  CardBody,
  CardText,
  Row,
  Col,
  PopoverBody,
} from 'reactstrap';
import RangeSlider from 'components/RangeSlider'
import Sort from 'components/Sort';
import SelectView from './SelectView';
import ViewPopOver from './ViewPopOver';
import { getViewNameList } from 'Utils';
import { SortAttrs } from 'DataSet';

class CardListToolbar extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      tooltipOpen: false,
    }
  }

  toggle = (e) => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  sortOnChange = (e) => {
    this.props.sortCallBack(e);
  }

  render() {
    const { highlightText, view, query, dateRangeHandler, dateRange, dateRangeSelected, sortATTRS, requestPayloadSort } = this.props;
    const lineChartColumn = 'col-lg-4 col-md-3 col-sm-12';
    const viewChartsColumn = 'col-2';

    return this.props.loading ? null : (
      <section className="cardListToolbarWrapper">
        <div className="row no-gutters media-h-auto container">
          <div className="col-4 media-sm-none toolbar-wrapper-flex-center">
            <div className="fees-returns-risk-header-container media-h-auto text-center" style={{ width: '27%' }}>
              {process.env.REACT_APP_SUB_DOMAIN === 'advisor' ? (
                <div className="FRR-Label" style={{ width: '100%' }}>Add to</div>
              ) : (
                <div className="FRR-Label" style={{ width: '100%' }}></div>
              )}
            </div>
            <div className="fees-returns-risk-header-container media-h-auto text-center" style={{ width: '73%' }}>
              <div className="FRR-Label" style={{ width: '73%' }}>Name</div>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 text-center media-sm-none toolbar-wrapper-flex-center">
            <div className="media-sm-none fees-returns-risk-header-container media-h-auto" style={{ display: 'flex', width: '100%' }}>
              {
                sortATTRS.map((item) => {
                  return (
                    <Sort
                      id={item.id}
                      asc={item.asc}
                      desc={item.desc}
                      sortFor={item.key}
                      hightlight={highlightText}
                      onChange={this.sortOnChange}
                    />
                  )
                })
              }
            </div>
          </div>
          <div className={`${viewChartsColumn} text-center media-sm-none viewChartsColumn`}>
            <SelectView
              discovery={this.props.discovery}
              itemCardView={this.props.itemCardView}
              viewHandler={this.props.viewHandler}
              view={this.props.view}
              chartData={this.props.chartData}
              showCarousel = {this.props.showCarousel}
            />
          </div>
          <div className={`${lineChartColumn} display-grid-center-range date-range-slider`}>
          {
             Object.keys(this.props.dateRangeSelected).length > 0 && <RangeSlider
              dateRangeHandler={this.props.dateRangeHandler}
              dateRangeSelected={this.props.dateRangeSelected}
              dateRange={this.props.dateRange}
              ref={this.props.inputRef}
              query={this.props.query}
          /> }
          </div>
        </div>
      </section>
    )
  }
}

export default CardListToolbar

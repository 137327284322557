import React, { useState } from 'react';
import moment from 'moment';

import { categoryCol, cn, DateRange } from '../DataSet'
import { getQuintile, getNest, arrayToHash, monthToDate, dateToMonth } from '../Utils';
import { calcStats } from '../Stats';
import ItemCard from './ItemCard';
import { isNull } from 'util';
import DetailsModal from 'components/detailsModal'

const dateRangeMonthList = range => {
  let out = [];  // List of months
  let rangeEnd = moment(range.end);
  for (let m = moment(range.start); m.isSameOrBefore(rangeEnd); m.add(1, 'months')) {
    out.push(dateToMonth(m));
  }
  return out;
}

const dateSort = (a, b) => {
  let _a = monthToDate(a.d), _b = monthToDate(b.d);
  return _a.isBefore(_b) ? -1 : (_a.isAfter(_b) ? 1 : 0);
}

const ItemList = ({ items, catData, range, sponsors_tickers,...props }) => {
  const [showDetailsModal, toggleDetailsModal] = useState(false)
  const [detailsModalData, setDetailsModalData] = useState()
  const sliderEndDate = moment(JSON.parse(JSON.stringify(window.sessionStorage.getItem('sliderEndDate'))));
  const rangeFilter = e => {
    let dt = monthToDate(e.d);
    return dt.isSameOrAfter(moment(range.start).subtract(1, 'seconds'),'date') && dt.isSameOrBefore(moment(range.end),'date');
  }

  const valTraslate = v => v < 0 ? -1 * Math.log10((100 - v) / 100) : Math.log10((100 + v) / 100);
  // const valTraslate = v => Math.log10((v+100)/100);
  const valTraslateX = v => v;

  let out = {}, ids = [];
  let x = dateRangeMonthList(range);

  let catSum = {};
  catData.forEach(e => {
    let out = {}, ysum = 0;
    e.returns.filter(rangeFilter).forEach(d => {
      ysum = (1 + ysum) * (1 + d.v) - 1;
      out[d.d] = ysum * 100;
    })
    catSum[e.name] = out
  })

  let rMin = 1e6, rMax = -1e6;
  let catDataH = arrayToHash(catData, 'name');

  let itemsVisible = items.filter(e => !e._hidden);
  itemsVisible.forEach(e => {
    if (e.returns.length > 0) {
      let points = e.returns.filter(rangeFilter);
      if (points.length <= 0) {
        e._hidden = true;
        return;
      }
      let cat = e._categorySeries;
      if (cat && cat.returns && points.length > 0) {
        let cindex = cat.returns.findIndex(e => e.d === points[0].d);
        let categoryStats = calcStats(cat.returns.slice(cindex));
        // ['fees', 'attribute/technical/volume', 'aum', 'attribute/technical/volume_min', 'aum_min', 'attribute/technical/volume_max', 'aum_max', 'esgScore', 'esgScore_min', 'esgScore_max'].forEach(a => e._cstats[a] = cat[a] || 0.0);

        let stats = calcStats(points);
        let series = x.map(d => {
          let val = getNest([d, 'cuml'], stats.series),
            ival = val !== undefined ? valTraslate(val) : val,
            cval = ival !== undefined && getNest([d, 'cuml'], categoryStats.series) ? valTraslate(getNest([d, 'cuml'], categoryStats.series)) : 0.0,
            ival2 = ival !== undefined ? getNest([d, 'ret'], stats.series) : 'Inactive';

          if (ival < rMin) rMin = ival;
          if (cval < rMin) rMin = cval;
          if (ival > rMax) rMax = ival;
          if (cval > rMax) rMax = cval;

          return { x: d, y1: ival, y2: cval, y3: ival2, y4: val };
        });

        const stats2 = {
          start: x[0],
          end:   x[x.length-1],
        }

        e._volatility_quin  = getQuintile(stats.volt, categoryStats.volt);
        e._return_quin      = getQuintile(stats.yRet, categoryStats.yRet);
        e._return_cuml_quin = getQuintile(stats.cuml, categoryStats.cuml);
        e._drawdown_quin    = getQuintile(stats.dMax, categoryStats.dMax);

        e._volatility_v     = stats.volt;
        e._return_v         = stats.yRet;
        e._return_cuml_v    = stats.cuml;
        e._drawdown_v       = stats.dMax;

        e._categorySeries = {
          ...e._categorySeries,
          ...categoryStats,
          _volatility_quin : categoryStats.volt,
          _return_quin     : categoryStats.yRet,
          _return_cuml_quin: categoryStats.cuml,
          _drawdown_quin   : categoryStats.dMax,
          _return_cuml_max : categoryStats.cumlMax,
        };

        //add api hardcoding for 1, 3, 5, 10, max years
        // console.log('sid hardcoding')
        const rend = moment(JSON.parse(JSON.stringify(range.end))).endOf('month')
        if( sliderEndDate.month() === rend.month() &&
          moment(JSON.parse(JSON.stringify(sliderEndDate))).add(1, 'seconds').subtract(1, 'years').month() == range.start.month() &&
          moment(JSON.parse(JSON.stringify(sliderEndDate))).add(1, 'seconds').subtract(1, 'years').year() == range.start.year()
        ){
          // console.log('sid slide1 yr')
          if(e.cumulative_returns_1y_ui != null) e._return_cuml_v = e.cumulative_returns_1y_ui *100
          if(e.drawdown_1y_ui != null) e._drawdown_v= e.drawdown_1y_ui *100
          if(e.volatility_1y_ui != null) e._volatility_v = e.volatility_1y_ui *100
          if(e.returns_1y_ui != null) e._return_v = e.returns_1y_ui *100
        }
        else if(sliderEndDate.month() === rend.month() && moment(JSON.parse(JSON.stringify(sliderEndDate))).add(1, 'seconds').subtract(3, 'years').month() == range.start.month() && moment(JSON.parse(JSON.stringify(sliderEndDate))).add(1, 'seconds').subtract(3, 'years').year() == range.start.year()){
          // console.log('sid slide3 yr')
          if(e.cumulative_returns_3y_ui != null) e._return_cuml_v = e.cumulative_returns_3y_ui *100
          if(e.drawdown_3y_ui != null) e._drawdown_v= e.drawdown_3y_ui *100
          if(e.volatility_3y_ui != null) e._volatility_v = e.volatility_3y_ui *100
          if(e.returns_3y_ui != null) e._return_v = e.returns_3y_ui *100
        }
        else if(sliderEndDate.month() === rend.month() && moment(JSON.parse(JSON.stringify(sliderEndDate))).add(1, 'seconds').subtract(5, 'years').month() == range.start.month() && moment(JSON.parse(JSON.stringify(sliderEndDate))).add(1, 'seconds').subtract(5, 'years').year() == range.start.year()){
          // console.log('sid slide5 yr')
          if(e.cumulative_returns_5y_ui != null) e._return_cuml_v = e.cumulative_returns_5y_ui *100
          if(e.drawdown_5y_ui != null) e._drawdown_v= e.drawdown_5y_ui *100
          if(e.volatility_5y_ui != null) e._volatility_v = e.volatility_5y_ui *100
          if(e.returns_5y_ui != null) e._return_v = e.returns_5y_ui *100
        }
        else if(sliderEndDate.month() === rend.month() && moment(JSON.parse(JSON.stringify(sliderEndDate))).add(1, 'seconds').subtract(10, 'years').month() == range.start.month() && moment(JSON.parse(JSON.stringify(sliderEndDate))).add(1, 'seconds').subtract(10, 'years').year() == range.start.year()){
          // console.log('sid slide10 yr')
          if(e.cumulative_returns_10y_ui != null) e._return_cuml_v = e.cumulative_returns_10y_ui *100
          if(e.drawdown_10y_ui != null) e._drawdown_v= e.drawdown_10y_ui *100
          if(e.volatility_10y_ui != null) e._volatility_v = e.volatility_10y_ui *100
          if(e.returns_10y_ui != null) e._return_v = e.returns_10y_ui *100
        }
        else if(sliderEndDate.month() === rend.month() && moment(JSON.parse(JSON.stringify(DateRange)).start).format() == moment(JSON.parse(JSON.stringify(range)).start).format()){
          // console.log('sid slide max yr')
          if(e.cumulative_returns_ltd_ui != null) e._return_cuml_v = e.cumulative_returns_ltd_ui *100
          if(e.drawdown_ltd_ui != null) e._drawdown_v= e.drawdown_ltd_ui *100
          if(e.volatility_ltd_ui != null) e._volatility_v = e.volatility_ltd_ui *100
          if(e.returns_ltd_ui != null) e._return_v = e.returns_ltd_ui *100
        }

        e._returnsChart = {...stats2, series};
      }
    }
  });

  itemsVisible = items.filter(e => !e._hidden);
  let risks = itemsVisible.map(e => e._volatility_v),
    returns = itemsVisible.map(e => e._return_v),
    fees = itemsVisible.map(e => e.fees),
    dividend_yields = itemsVisible.map(e => { if(e.dividend_yield != "null") return e.dividend_yield}).filter(e => { if(e.dividend_yield != "null" || typeof e.dividend_yield != 'undefined') return true;}),
    // sharpeValues = itemsVisible.map(e => { let sharpee = e.riskAdjReturn.filter(e => e.name == 'Sharpe'); if(sharpee.length >0) return sharpee[0].value}).filter(e => { if(e) return e;}), sharpeMax, sharpeMin,
    // sortinoValues = itemsVisible.map(e => { let sortinoo = e.riskAdjReturn.filter(e => e.name == 'Sortino'); if(sortinoo.length >0) return sortinoo[0].value}).filter(e => {if(e) return e;}), sortinoMax, sortinoMin,
    // trackingErrorValues = itemsVisible.map(e => { let errorr = e.riskAdjReturn.filter(e => e.name == 'Tracking Error'); if(errorr.length >0) return errorr[0].value}).filter(e => {if(e) return e;}), trackingErrorMax, trackingErrorMin;

    sharpeValues = itemsVisible.map(e => { let sharpee; (e.riskAdjReturn && e.riskAdjReturn.length > 0) ? sharpee = e.riskAdjReturn.filter(e => e.name == 'Sharpe'): sharpee = []; if(sharpee.length >0) return sharpee[0].value}).filter(e => { if(e) return e;}), sharpeMax, sharpeMin,
    sortinoValues = itemsVisible.map(e => { let sortinoo; (e.riskAdjReturn && e.riskAdjReturn.length > 0) ? sortinoo = e.riskAdjReturn.filter(e => e.name == 'Sortino'): sortinoo = []; if(sortinoo.length >0) return sortinoo[0].value}).filter(e => {if(e) return e;}), sortinoMax, sortinoMin,
    trackingErrorValues = itemsVisible.map(e => { let errorr; (e.riskAdjReturn && e.riskAdjReturn.length > 0) ? errorr = e.riskAdjReturn.filter(e => e.name == 'Tracking Error') : errorr = []; if(errorr.length >0) return errorr[0].value}).filter(e => {if(e) return e;}), trackingErrorMax, trackingErrorMin;

  // let outperformanceValues, outperformanceMax, outperformanceMin;
  // if(props.view.includes('Performance vs ')){
  //   outperformanceValues = itemsVisible.map(e => e.outperformance);
  //   [outperformanceMax, outperformanceMin] = [Math.max(...outperformanceValues), Math.min(...outperformanceValues)];
  // }

  // let outperformanceValues, outperformanceMax, outperformanceMin;
  // if(props.view.includes('Performance vs ')){
  //   outperformanceValues = itemsVisible.map(e => e.outperformance);
  //   [outperformanceMax, outperformanceMin] = [Math.max(...outperformanceValues), Math.min(...outperformanceValues)];
  // }

  let [risksMin, returnsMax, feesMin, dividendMin, dividendMax] = [Math.min(...risks), Math.max(...returns), Math.min(...fees), Math.min(...dividend_yields), Math.max(...dividend_yields)];
  [sharpeMax, sharpeMin, sortinoMax, sortinoMin, trackingErrorMax, trackingErrorMin] = [Math.max(...sharpeValues), Math.min(...sharpeValues), Math.max(...sortinoValues), Math.min(...sortinoValues), Math.max(...trackingErrorValues), Math.min(...trackingErrorValues)]

  //calculate the dynamic chart min max
  let cardChartData;
  if (props.chartData) {
    cardChartData = JSON.parse(JSON.stringify(props.chartData));
    if (cardChartData.length) {
      cardChartData.forEach(chart => {
        if (chart.chart_type == 'odometer' && chart.Static == false) {
          let min, max;
          let values = itemsVisible.map(e => e[chart.variable]);
          // console.log('values', values);
          if (chart.min == 'data_min') {
            min = Math.min(...values);
            // console.log('min', min);
            chart.min = min;
          }
          if (chart.max == 'data_max') {
            max = Math.max(...values);
            // console.log('mx', max);
            chart.max = max;
          }
          // console.log('inside the odo', cardChartData);
        }
      });
    }
  }
  rMax = Math.max(Math.abs(rMin), rMax);
  itemsVisible.forEach(e => {
    e._flags = { risksMin: e._volatility_v === risksMin,
      returnsMax: e._return_v === returnsMax,
      feesMin: e.fees === feesMin };
    e._returnRange = { min: -rMax, max: rMax };
    e.sharpeMax = sharpeMax;
    e.sharpeMin = sharpeMin;
    e.sortinoMax = sortinoMax;
    e.sortinoMin = sortinoMin;
    e.trackingErrorMax = trackingErrorMax;
    e.trackingErrorMin = trackingErrorMin;
    e.dividendMin = dividendMin;
    e.dividendMax = dividendMax;
  });

  const toggleModal = (data) => {
    setDetailsModalData(data)
    toggleDetailsModal(!showDetailsModal)
  }

  // chart multiple render hack
  let itemsKey = items.map(e => cn(e, 'Ticker')).join('|')
  itemsKey += `|${range.start.format("MMM YYYY")}|${range.end.format("MMM YYYY")}`
  let firstPopIndex = null;
  return (
    <React.Fragment>
      { items.map((e,i) => {
          if (e._hidden) return null;
          if(isNull(firstPopIndex)) firstPopIndex = i;
          return (
            <div key={`k_${i}_${cn(e, 'Id')}`} className="itemCardWrapper">
              <ItemCard {...props} k={`k_${i}_${cn(e, 'Id')}`}
              openDetailsModalState={showDetailsModal}
              reponsiveDetailsModal={props.reponsiveDetailsModal}
              card={e}
              itemsKey={itemsKey}
              index={i}
              firstPopIndex={firstPopIndex}
              toggleDetailsModal={toggleModal}
              sponsors_tickers={sponsors_tickers}
              />
              {/* <DetailsModal key={e.ticker} item={e} data={detailsModalData} open={showDetailsModal} toggle={() => toggleDetailsModal(!showDetailsModal)}/> */}
            </div>
          )
      })}
    </React.Fragment>
  );
};
export default ItemList;

import React, { useState, useEffect } from 'react';
// import classNames from 'classnames';
import { getImageOrFallback } from 'Utils';
// import { cn } from 'DataSet';
import { FormatCurrency } from '../shared/Utils'

export const ViewFund = ({sponsor, fundName}) => {

	const [url, setUrl] = useState('')

	useEffect(() => {
		getImageOrFallback(`/assets/images/sponsors/${sponsor}.png`)
			.then(result => setUrl(result));
	}, [sponsor])

	return (
		<React.Fragment>
			{url
				? <img src={url} height="20" className="img-fluid" alt="sponsor" style={{height: '20px'}} />
				: <div className="sponsor-logo-placeholder" title={sponsor}>{sponsor}</div>
			}
			<div style={{fontSize: '15px', marginTop: '5px'}}>{fundName}</div>
	  </React.Fragment>
	)
}

export const TickerPrice = ({ticker, price}) => (
	<React.Fragment>
		<span>{ticker}</span><br />
		<span>$ {FormatCurrency(price,0,'')}</span>
	</React.Fragment>
)
export const TickerWeight = ({ticker, weight}) => (
	<React.Fragment>
		<span>{ticker}</span><br />
		<span>{weight && weight.toFixed(2)}%</span>
	</React.Fragment>
)

export const ViewFee = ({fee, qE}) => {
	let highlight = (qE !== 'Lowest Risk Alternatives' && qE !== 'Highest Yield Alternatives' && qE !== 'Long Track Record Alternatives' && qE !== 'Best Performing Options In Category')
	return (<div className={`fees-flag-color ${highlight ? 'flag-highlight' : ''} `}>
		<i className="far fa-fw fassf fa-tags-1" />
		<p className="__val" style={{ margin: 0, lineHeight: 1 }}> {fee} </p>
		<p className="__state d-none" style={{ margin: 0, lineHeight: 1 }}> Low </p>
	</div>)
}

export const ViewReturn = ({returns}) => (
	<div className="returns-flag-color">
		<i className="far fa-fw fassf fa-chart-line-1" />
		<p className="__val" style={{ margin: 0, lineHeight: 1 }}> {returns} </p>
		<p className="__state d-none" style={{ margin: 0, lineHeight: 1 }}> Low </p>
	</div>
)

export const ViewRisk = ({ ticker, risk, qE}) => {
	let highlight = (qE == 'Lowest Risk Alternatives')
	return (<div className={`risk-flag-color ${highlight ? 'flag-highlight' : ''} `}>
		<img src="/assets/images/icons/grey-heart-rate-1.svg" width="25" alt="Risk" style={{ marginBottom: '10px' }} />
		<p className="__val" style={{ margin: 0, lineHeight: 1 }}> {risk} </p>
		<p className="__state d-none" style={{ margin: 0, lineHeight: 1 }}> Average </p>
	</div>)
}

export const ViewYield = ({yld}) => (
	<div className="risk-flag-color flag-highlight">
		<img src="/assets/images/icons/grey-Yield.svg" width="25" alt="Risk" style={{ marginBottom: '10px' }} />
		<p className="__val" style={{ margin: 0, lineHeight: 1 }}> {yld} </p>
		<p className="__state d-none" style={{ margin: 0, lineHeight: 1 }}> Average </p>
	</div>
)

export const ViewTrackRecord = ({tr}) => (
	<div className="risk-flag-color flag-highlight">
	<i className="far fa-calendar-alt" style={{ marginBottom: '10px' }} />
		<p className="__val" style={{ margin: 0, lineHeight: 1 }}> {tr} </p>
		<p className="__state d-none" style={{ margin: 0, lineHeight: 1 }}> Average </p>
	</div>
)

export const ViewOutperform = ({op}) => (
	<div className="risk-flag-color flag-highlight">
		<img src="/assets/images/icons/grey-OutPerformance.svg" width="20" alt="Risk" style={{ marginBottom: '10px' }} />
		<p className="__val" style={{ margin: 0, lineHeight: 1 }}> {op} </p>
		<p className="__state d-none" style={{ margin: 0, lineHeight: 1 }}> Average </p>
	</div>
)

export const ViewSharpe = ({sharpe}) => (
	<div className="risk-flag-color flag-highlight">
		<img src="/assets/images/icons/grey-OutPerformance.svg" width="20" alt="sharpe" style={{ marginBottom: '10px' }} />
		<p className="__val" style={{ margin: 0, lineHeight: 1 }}> {sharpe} </p>
		<p className="__state d-none" style={{ margin: 0, lineHeight: 1 }}> Average </p>
	</div>
)

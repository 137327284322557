import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { withHeaderFooter } from '../shared/HeaderFooter';

const IndexPage = withHeaderFooter((props) => {
  const { selectorReportIndexData: indexData } = props;
  if(!Array.isArray(indexData) || !indexData.length) return '';
  return (
    <React.Fragment>
      <div className="content">
        <div className="pr-v2-header">
          <h3>Table of Contents</h3>
        </div>
        <Table striped className="pr-v2-toc">
          <tbody>
          {indexData.map(item => {
            return(
              <tr>
                <td scope="row">{item.name}</td>
                <td>{getFormattedPageNum(item.pageNumber)}</td>
              </tr>
            )
          })}
          {/*<tr style={{height: '57px'}}>
              <td scope="row"></td>
              <td></td>
            </tr>*/}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  )
})

const mapStateToProps = ({ reports }) => ({
  selectorReportIndexData: reports.selectorReportIndexData,
})

export default connect(mapStateToProps)(IndexPage);

const getFormattedPageNum = (number) => {
  if(!Array.isArray(number) || !number.length || !number) return '';
  if(number.length === 1 || number[0] === number[number.length - 1]) {
    return handleSingleCount(number[0]);
  }else {
    return `${handleSingleCount(number[0])} - ${handleSingleCount(number[number.length - 1])}`;
  }
}

const handleSingleCount = (count) => count < 10 ? '0' + count : count;
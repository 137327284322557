import React from 'react';

import NotApplicable from '../../NotApplicable';
import SimplePieChart from './SimplePieChart'
import { SectorsAttrs } from 'DataSet';
import { SectorChartMapping } from 'ChartData';
import { SectorColors } from 'data/Colors';

const diversificationKeys = {
  Holdings: {
    dataKey: 'top_20_prct_coverage',
    centerTextKey: 'n_hldgs',
  },
  Region: {
    dataKey: 'top_20_prct_reg_coverage',
    centerTextKey: 'n_countries',
  },
  Sector: {
    dataKey: 'top_20_prct_sec_coverage',
    centerTextKey: 'n_sectors',
  }
};

const capitalize = (str) => {
  if (str.length) {
    return str[0].toUpperCase() + str.slice(1);
  }
  return str;
};

const RenderPieChart = (props) => {
  const { fund, item, queryVar } = props;
  const { view_name, Secondary_view_name, node, variable, chart_center_abovetext = '', chart_center_belowtext = '' } = queryVar;

  let sum = 0.0;
  let data = [];
  let title = '';
  let decimal = 0, centerDisplayText, centerValueText, dataVar;
  let topTitle = view_name + (Secondary_view_name ? ' ' + Secondary_view_name : '');
  if (view_name === 'Diversification') {
    const key = Secondary_view_name && diversificationKeys[Secondary_view_name] ?
                  diversificationKeys[Secondary_view_name].dataKey : 'top_20_prct_coverage';
    // let chartData = results[view_name].find(t => t.ticker === item.ticker);
    let chartData = item;
    if (!chartData) return <NotApplicable />;
    // title = chartData[key] + ' ' + Secondary_view_name;
    let top20Value = chartData[key];
    data = [
      {
        name: 'Percentage help by top 20% positions',
        value: top20Value * 100,
      },
      {
        name: 'Percentage help by lower 80% positions',
        value: (1 - top20Value) * 100,
      },
    ]
    centerDisplayText = topTitle;
    const centerTextKey = Secondary_view_name && diversificationKeys[Secondary_view_name] ?
                  diversificationKeys[Secondary_view_name].centerTextKey : 'n_hldgs';
    centerValueText = chartData[centerTextKey] || '';
  } else
    if (view_name === 'Asset Allocation') {
      let chartData = item;
      if (!chartData) return <NotApplicable />;

      chartData && Object.keys(chartData).forEach(key => {
        if (key === 'ticker') return;
        data.push({
          name: capitalize(key),
          value: +chartData[key],
        });
      })
    } else if (view_name === 'Top Holdings') {
      let chartData = item;
      if (!chartData || (chartData.holdings && !chartData.holdings.length)) return <NotApplicable />;

      const totalSum = (chartData && chartData.holdings.reduce((total, current) => total + current.weight, 0.0)) || 0;
      decimal = 1;

      if (Object.prototype.toString.call(queryVar.data) === '[object Array]') {
        dataVar = queryVar.data[0];
      } else if (typeof queryVar.data === 'string') {
        dataVar = queryVar.data;
      }

      if (fund[variable]) {
        const centerValue = fund[variable];
        console.log('chart_center_abovetext -> ', chart_center_abovetext, centerValue)
        if (!centerValue) return <NotApplicable />;
        centerValueText = chart_center_abovetext.replace('value', centerValue.toFixed(1));
        centerDisplayText = chart_center_belowtext.replace('ticker', dataVar);
      }

      data = chartData.holdings.map(d => ({
        name: d.name,
        value: d.weight
      }));
      data.push({
        name: "Others",
        value: 100 - totalSum.toFixed(decimal),
        color: '#ddd'
      })
    } else {
      SectorsAttrs.forEach((name) => {
        // const Sectors = this.state.results.Sectors;
        const value = item[name];
        if (!value) return <NotApplicable />;
        if (value && value > 0) {
          const labelName = SectorChartMapping.filter(e => e.chart_name === name);
          data.push({
            name: labelName.length ? labelName[0].sector_name : '',
            value,
          });
        }
      });
      const displayText = SectorChartMapping.filter(e => e.chart_name === node);
      centerDisplayText = displayText.length ? displayText[0].sector_name : '';
      centerValueText = (displayText.length && displayText[0].chart_name && fund[displayText[0].chart_name]) ? `${fund[displayText[0].chart_name].toFixed(1)}%` : '';
    }

  data = data.map(v => {
    return { name: v.name, value: (Math.abs(v.value)), originalValue: (v.value) }
  });

  return (
    <div className="siw-pie-holder print-wrapper">
      <span
        className="siw-pie-title"
        title={centerDisplayText}
      >
        {centerDisplayText && centerDisplayText !== '' ? `% ${centerDisplayText}` : topTitle}
      </span>

      {title && (
        <span className="siw-pie-center-text">{title}</span>
      )}
      <SimplePieChart
        data={data}
        colors={view_name === 'Diversification' ? ['#ddd', '#1e5f91'] : SectorColors}
        decimal={decimal}
        centerDisplayText={centerDisplayText}
        centerValueText={centerValueText}
        chartName={view_name}
      />
    </div>
  );
};

export default RenderPieChart;

import React from 'react';
import { Row, Col } from 'reactstrap';
import NotApplicable from 'components/NotApplicable';
import GaugeChart from 'components/charts/GaugeChart';

export const SharpeGaugeChartWrap = ({ card, name, width }) => {
  const mult = name === 'Sharpe' ? 1 : 1;
  if (!card.riskAdjReturn) return <NotApplicable />;
  if (card.sharpeMin === card.sharpeMax) return <NotApplicable />;
  let sharpeObj = card.riskAdjReturn.filter(e => e.name === 'Sharpe');

  if (sharpeObj.length <= 0) return <NotApplicable />;
  sharpeObj = sharpeObj[0];
  let _min = card.sharpeMin * mult,
    _max = card.sharpeMax * mult,
    _val = sharpeObj.value * mult;
  _val = parseFloat(_val.toFixed(2));

  return (
    <div className="w-100 p-1 guage-chart-wrapper">
      <div className="d-flex flex-column align-items-center" >
        {/* style={{ minWidth: width || 134 }} */}
        {/* <h6 className="text-center mb-0">Percentage Overlap</h6> */}
        {(_min === _max && _max === _val) ? (
          <GaugeChart minWidth={width} pc={0.5} />
        ) : (
          <GaugeChart minWidth={width} value={_val} min={_min} max={_max} />
        )}
        <Row className="no-gutters h6 mb-0 w-100 guage-chart-details">
          <Col className="col-4 pr-2 text-gray-6i text-right h7 fz-9" style={{ marginTop: '-2px' }}>{_min.toFixed(2)}</Col>
          <Col className="col-4 text-center font-weight-bold mb-0 lh-100 fz-9" style={{ marginTop: '2px' }}>{_val.after() === 0 ? _val.toFixed(0) : _val.toFixed(2)}</Col>
          <Col className="col-4 pl-2 text-gray-6i text-left h7 fz-9" style={{ marginTop: '-2px' }}>{_max.toFixed(2)}</Col>
        </Row>
      </div>
    </div>
  );
}

export const SortinoGaugeChartWrap = ({ card, name, width }) => {
  const mult = name === 'Sortino' ? 1 : 1;
  if (!card.riskAdjReturn) return <NotApplicable />;
  if (card.sortinoMin === card.sortinoMax) return <NotApplicable />;
  let sortinoObj = card.riskAdjReturn.filter(e => e.name === 'Sortino');

  if (sortinoObj.length <= 0) return <NotApplicable />;
  sortinoObj = sortinoObj[0];
  let _min = card.sortinoMin * mult,
    _max = card.sortinoMax * mult,
    _val = sortinoObj.value * mult;
  _val = parseFloat(_val.toFixed(2));

  return (
    <div className="w-100 p-1 guage-chart-wrapper">
      <div className="d-flex flex-column align-items-center" >
        {/* style={{ minWidth: width || 134 }} */}
        {/* <h6 className="text-center mb-0">Percentage Overlap</h6> */}
        {(_min === _max && _max === _val) ? (
          <GaugeChart minWidth={width} pc={0.5} />
        ) : (
          <GaugeChart minWidth={width} value={_val} min={_min} max={_max} />
        )}
        <Row className="no-gutters h6 mb-0 w-100 guage-chart-details">
          <Col className="col-4 pr-2 text-gray-6i text-right h7 fz-9" style={{ marginTop: '-2px' }}>{_min.toFixed(2)}</Col>
          <Col className="col-4 text-center font-weight-bold mb-0 lh-100 fz-9" style={{ marginTop: '2px' }}>{_val.after() === 0 ? _val.toFixed(0) : _val.toFixed(2)}</Col>
          <Col className="col-4 pl-2 text-gray-6i text-left h7 fz-9" style={{ marginTop: '-2px' }}>{_max.toFixed(2)}</Col>
        </Row>
      </div>
    </div>
  );
}

export const TrackingErrorGaugeChartWrap = ({ card, name, width, multiplier }) => {
  const mult = name === 'Tracking Error' ? multiplier : 1;
  if (!card.riskAdjReturn) return <NotApplicable />;
  if (card.trackingErrorMin === card.trackingErrorMax) return <NotApplicable />;
  let trackingErrorObj = card.riskAdjReturn.filter(e => e.name === 'Tracking Error');

  if (trackingErrorObj.length <= 0) return <NotApplicable />;
  trackingErrorObj = trackingErrorObj[0];
  let _min = card.trackingErrorMin * mult,
    _max = card.trackingErrorMax * mult,
    _val = trackingErrorObj.value * mult;
  // _val = parseFloat(_val.toFixed(1));

  return (
    <div className="w-100 p-1 guage-chart-wrapper">
      <div className="d-flex flex-column align-items-center" >
        {/* style={{ minWidth: width || 134 }} */}
        {/* <h6 className="text-center mb-0">Percentage Overlap</h6> */}
        {(_min === _max && _max === _val) ? (
          <GaugeChart minWidth={width} pc={0.5} />
        ) : (
          <GaugeChart minWidth={width} value={_val} min={_min} max={_max} />
        )}
        <Row className="no-gutters h6 mb-0 w-100 guage-chart-details">
          <Col className="col-4 pr-2 text-gray-6i text-right h7 fz-9" style={{ marginTop: '-2px' }}>{_min.toFixed(2)}%</Col>
          <Col className="col-4 text-center font-weight-bold mb-0 lh-100 fz-9" style={{ marginTop: '2px' }}>{_val.toFixed(2)}%</Col>
          <Col className="col-4 pl-2 text-gray-6i text-left h7 fz-9" style={{ marginTop: '-2px' }}>{_max.toFixed(2)}%</Col>
        </Row>
      </div>
    </div>
  );
}

export default {
  SharpeGaugeChartWrap,
  TrackingErrorGaugeChartWrap,
  SortinoGaugeChartWrap,
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, PopoverBody,Popover,PopoverHeader } from 'reactstrap';
import { withPopover } from 'components/Popover';
import { quintileSize, quintileClass, addEventToAnalytics } from 'Utils';
import { cn, QuinItems } from 'DataSet';
import { Quintile, Pct } from '../common';

var classNames = require('classnames');


const CUMULATIVE = 'Cumulative Return'
const ANNUALIZED = 'Annualized Return'

class ReturnPopover2 extends Component {

  state={
    popoverOpen: false,
  }

  onHover = () => {
    !this.state.popoverOpen && this.setState({
      popoverOpen: true,
    },()=>{
      addEventToAnalytics('Return Popup','Return Popup','RETURN_POPUP',{ query: this.props.funds.query, logId: this.props.funds.logId, ticker: this.props.card.ticker },true);
    })
  }

  onHoverLeave = () => {
    this.state.popoverOpen &&this.setState({
      popoverOpen: false,
    })
  }

  toggle=()=> {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    })
  }
  onMouseOver = () => {
    try {
      const element = document.querySelector('.popover-drop__item');
      element.style.display = 'block';
    } catch (e) {
      console.log(e);
    }
  }

  onMouseOverItem = () => {
    try {
      const element = document.querySelector('.popover-drop__item');
      element.style.display = 'block';
    } catch (e) {
      console.log(e);
    }
  }

  onMouseLeave = () => {
    try {
      const element = document.querySelector('.popover-drop__item');
      element.style.display = 'none';
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { card, highlight, user_return_prefer, setReturnPopover } = this.props;
    const i = 3;
    const icon = 'fa-chart-line-';

    const hikeLabel = user_return_prefer===ANNUALIZED ?(quintileSize(card._stats.yRetQ, QuinItems[i].reverse) === 2 ? 'Average' : (quintileSize(card._stats.yRetQ, QuinItems[i].reverse) === 3 ? 'High' : 'Low')):(quintileSize(card._stats.cumlQ, QuinItems[i].reverse) === 2 ? 'Average' : (quintileSize(card._stats.cumlQ, QuinItems[i].reverse) === 3 ? 'High' : 'Low'));
    const statusText =  user_return_prefer===ANNUALIZED ? (card._stats.yRet.toFixed(2) + "%"):(card._stats.cuml.toFixed(2) + "%");

    const highlightClassName = highlight ? 'search-fees-return-stats-padding hover-orange search-risk-stats-highlighting' : (card._flags.returnsMax ? 'search-fees-return-stats-padding search-risk-stats-highlighting' : 'search-fees-return-stats-padding');
    return (
      <div
        className={highlightClassName}
        onMouseEnter={this.onHover}
        onMouseLeave={this.onHoverLeave}>
          <div id={"ReturnPopover-" + cn(card, 'Id')} className={'w-100'} >
           <div className="returns-flag-color">
             <i className={classNames("far fa-fw fassf", icon + quintileSize(card._stats.yRetQ, QuinItems[i].reverse))} />
             <p className="__val" style={{ margin: 0, fontSize: 11, lineHeight: 1, fontWeight: 'bold' }}>{statusText}</p>
             <p className="__state" style={{ margin: 0, fontSize: 10, lineHeight: 1 }}>{hikeLabel}</p>
           </div>
          </div>
          <Popover
            toggle={this.toggle}
            isOpen={this.state.popoverOpen}
            trigger={'hover'} placement="right"
            target={"ReturnPopover-" + cn(card, 'Id')} >
            <div className="pop-wrapper">
              <PopoverBody className="p-0 dddd">
                <Row className="no-gutters">
                  <Col className="col-12 p-2 text-gray-9 bg-light br-2">
                    <div className={classNames("d-flex justify-content-between")}>
                      <div className="popover-drop">
                        <div className="popover-drop__toggle" onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}  >
                          <span className="pop-toggle-name">{`${user_return_prefer===ANNUALIZED?'Annualized':'Cumulative'} Return`}</span> <i className="fas fa-caret-down"></i>
                        </div>
                        <div className="popover-drop__item" onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}>
                          <ul className="popover-drop__item__wrapper">
                            {user_return_prefer===ANNUALIZED?<li className="popover-drop__item__wrapper__list" onClick={() => setReturnPopover('Cumulative Return')} id="Cumulative Return">Cumulative Return</li>:
                              <li onClick={() => setReturnPopover('Annualized Return')} className="popover-drop__item__wrapper__list" id="Annualized Return">Annualized Return</li>}
                          </ul>
                        </div>
                      </div>
                      {user_return_prefer===ANNUALIZED?<span className="font-weight-bold">{card._stats.yRet.toFixed(2)}<Pct /></span>
                        : <span className="font-weight-bold">{card._stats.cuml.toFixed(2)}<Pct /></span>}
                    </div>

                    {user_return_prefer===ANNUALIZED? <div className="">
                        <Quintile cardCategory={cn(card, 'Category')} date={`${card._stats.start} - ${card._stats.end}`} item={QuinItems[i]} value={card._stats.yRetQ}
                                  catValue={<span>{card._cstats.yRet.toFixed(2)}<Pct /></span>}
                                  bg={quintileClass(card._stats.yRetQ, QuinItems[i].reverse)} />
                      </div>:
                      <div className="">
                        <Quintile cardCategory={cn(card, 'Category')} date={`${card._stats.start} - ${card._stats.end}`} item={QuinItems[i]} value={card._stats.cumlQ}
                                  catValue={<span>{card._cstats.cuml.toFixed(2)}<Pct /></span>}
                                  bg={quintileClass(card._stats.cumlQ, QuinItems[i].reverse)} />
                      </div>}
                  </Col>
                </Row>
              </PopoverBody>
            </div>

        </Popover>
      </div>
    );
  }
}

const mapStateToProps = ({ funds }) => ({
  funds,
})

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturnPopover2)

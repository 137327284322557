import React, { Component } from 'react';

import {
  Row,
  Col,
  PopoverBody,
} from 'reactstrap';
import { withPopover } from 'components/Popover';

class ViewPopOver extends Component {
  render() {
    const { view, dynamicQueryCategory } = this.props;
    if(view == dynamicQueryCategory){
      return (
        <div>&nbsp;</div>
      );
    }
    const data = {
      'Volume' : 'Liquidity measure based on recent dollar value traded',
      'Regions' : 'Exposure to different regions and countries',
      'Sectors' : 'Allocations to Sectors',
      'Assets' : 'Assets under management',
      'Top Holdings' : 'Allocation to top 10 holdings',
      'Asset Types' : 'Allocations made across different Asset Typess',
      'Impact' : 'Impact denotes an aggregated ESG (Environmental, Social and Governance ) Score of the holding companies',
      'Themes' : 'Top 4 theme exposures',
      'Holdings Diversification' : '3 measures of diversification; A - No of underlyings, B - Herfindahl-Hirschman Index & C - allocation to top 10 holdings',
      'Region Diversification' : '3 measures of diversification; A - No of underlyings, B - Herfindahl-Hirschman Index & C - allocation to top 10 holdings',
      'Sector Diversification' : '3 measures of diversification; A - No of underlyings, B - Herfindahl-Hirschman Index & C - allocation to top 10 holdings',
    };
    const e1 = () => (
      <React.Fragment>
        <i className="far fa-info-circle"></i>
      </React.Fragment>
    );

    const e2 = () => (
      <React.Fragment>
        <PopoverBody className="p-0">
          <Row className="no-gutters">
              <Col className="col-12 p-2">
                <div className="h5 d-flex justify-content-between pt-1">
                  {data[view]}
                </div>
              </Col>
          </Row>
        </PopoverBody>
      </React.Fragment>
    );

    const WithPopover = withPopover(e1, e2, { id: "NamePopover-Sid", placement: 'bottom' })
    return (
      <h6 className="h6 mb-0 text-gray-6 truncate">
        <span>{data[view]}</span>
        <br/>
        <span>
          <WithPopover />
        </span>
      </h6>

    );
  }
}

export default ViewPopOver;

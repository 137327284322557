import React from 'react';
import { Row, Col } from 'antd';
import { Cell, Tooltip, ResponsiveContainer, Pie, PieChart } from 'recharts';

export const DonutChart = (props) => {
  const itemStyle = {fontSize: '0.75rem', padding: '0'}
  const { data, labelFor, className, centerLabelClassName, noData } = props;
  const width = (props.width) ? props.width : "100%";
  const height = (props.height) ? props.height : "100%";
  const defaultColors = ["#1d6091", "#56a9e8", "#acd6f5" ];

  // console.log('noData-------->', noData);
  const noDataArr = [{
    color: "red",
    key: "noData",
    label: "No Data",
    locked: false,
    position: 1,
    sliderValue: 100,
  }];

  // console.log(noDataArr);

  return (
    <div className={`donut-chart-container ${className}`} style={{ width, height }}>
      {labelFor && (
        <div className={`donut-chart-center-label ${centerLabelClassName}`}>
          <div className="text">{labelFor}</div>
        </div>
      )}
      <ResponsiveContainer width={width} height={height}>
        {noData ? (
          <PieChart>
            <Pie
              data={noDataArr}
              nameKey="name"
              outerRadius={'100%'}
              innerRadius={'55%'}
              isAnimationActive={false}
            >
              { noDataArr.map((v, i) => <Cell key={i} fill={v.color || defaultColors[i%defaultColors.length]}/>) }
            </Pie>
          </PieChart>
        ) : (
          <PieChart>
            <Pie
              data={data}
              dataKey={props.dataKey}
              nameKey="name"
              unit="%"
              outerRadius={'100%'}
              innerRadius={'55%'}
              isAnimationActive={false}
            >
              { data.map((v, i) => <Cell key={i} fill={v.color || defaultColors[i%defaultColors.length]}/>) }
            </Pie>
            <Tooltip
              content={<CustomTooltipDonutChart unit="%" formatter={props.formatter} />}
              wrapperClassName="ssf-tooltip"
              itemStyle={itemStyle} />
          </PieChart>
        )}
      </ResponsiveContainer>
    </div>
  );
}

const CustomTooltipDonutChart = (props) => {
  const { active, payload, unit, formatter } = props;
  if (active && payload[0]) {
    const value = formatter ? formatter(payload[0].value) : payload[0].value+unit;

    const payload2 = payload[0].payload;
    return (
      <div className="recharts-default-tooltip ssf-tooltip-custom">
        <div>
          <span className="label mr-0">{payload2.label} : {value}</span>
        </div>
      </div>
    );
  }

  return null;
}

export const ChartLegend = ({ name, value, color }) => {
  return (
    <Row>
      <Col span={4} className="legend-box"
        style={{ backgroundColor: color }}
      />
    <Col span={16} className="font-style" title={name} style={{ paddingLeft: 5 }}>
        {name}
      </Col>
      <Col span={4} className="font-style">{value}%</Col>
    </Row>
  )
}

export default {
  ChartLegend,
  DonutChart,
}

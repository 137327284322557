import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { get, isEmpty } from 'lodash'
import { CSVLink } from "react-csv";
import {
  Button,
  ButtonGroup,
  Dropdown,
  ModalBody,
  ModalFooter,
  Modal,
  Tooltip,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Input,
  Label
} from 'reactstrap';

import { numFmt, getNest, addEventToAnalytics } from 'Utils';

import { PrimaryButton } from 'shared-components'
import CustomAlert from 'components/CustomAlert';

import { FooterActions } from '../Common';
import { RegisterTooltip } from './helper';
import CustomModal from '../../../../components/Modal';

const comingSoonValues = ['SMA', 'MP'];

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadTradeList: false,
      tradeListDropdown: false,
      tradeListValue: '',
      accountDropdown: false,
      brokerDropdownValue: '',
      accountNumber: [],
      accounNoDropdown: false,
      selectAccountNumber: '',
      tradeList: '',
      downloadCSV: false,
      builderToolTip: false,
      minTradeValueTooltip: false,
      isAgreementOpen: false,
      isAgreementChecked: false
    }
    this.csvLink = React.createRef();
    this.fidelityCsvLink = React.createRef();
    this.schwabCsvLink = React.createRef();
  }

  onDownloadTradeList = () => {
    this.setState({
      downloadTradeList: true,
      downloadCSV: false,
    });
  }

  onCloseDownloadTradeList = () => {
    this.setState({ downloadTradeList: false });
    this.setState({ tradeListValue: '' });
    this.setState({ brokerDropdownValue: '' });
    this.setState({ selectAccountNumber: '' });
  }

  tradeListDropDownToggle = () => {
    this.setState({ tradeListDropdown: !this.state.tradeListDropdown });
  }

  accountDropdownToggle = () => {
    this.setState({ accountDropdown: !this.state.accountDropdown });
  }

  accountNoDropdownToggle = () => {
    this.setState({ accounNoDropdown: !this.state.accounNoDropdown });
  }

  getAccountName = (item) => {
    const accountArray = [];

    const { importedAccounts } = this.props;

    var valuesData = Object.entries(importedAccounts).map(([k, v]) => ({ [k]: v }))

    valuesData.map((i, key) => {
      if (item === Object.keys(i).toString()) {
        accountArray.push(i);
      }
    });

    const ids = [];
    JSON.stringify(accountArray, (key, value) => {
      if (key === 'account_number') ids.push(value);
      return value;
    });

    this.setState({
      accountNumber: ids,
    });

    this.setState({
      brokerDropdownValue: item,
    });

    this.setState({ selectAccountNumber: '' });
  }

  getAccountNumber = (item) => {
    this.setState({
      selectAccountNumber: item,
    })
  }

  downloadOrionTradeListExcel = () => {
    const { items } = this.props;
    var CSVDataValue = items.map((i) => {
      if (getNest(['_order', 'state', 'valid'], i) == true && getNest(['_order', 'state', 'amtType'], i) == 'Units' && i._checkedToExecute) {
        var info = {
          "account_number": this.state.selectAccountNumber,
          "transaction": getNest(['_order', 'state', 'action'], i) == 'buy' ? 'B' : 'SL',
          "quantity": getNest(['_order', 'state', 'qty'], i),
          "ticker": i.ticker,
          "price": parseInt(getNest(['_order', 'tvalue'], i)),
        }
        return info;
      }
      if (getNest(['_order', 'state', 'valid'], i) == true && getNest(['_order', 'state', 'amtType'], i) == 'Value' && i._checkedToExecute) {
        var info = {
          "account_number": this.state.selectAccountNumber,
          "transaction": getNest(['_order', 'state', 'action'], i) == 'buy' ? 'B' : 'SL',
          "quantity": getNest(['_order', 'qty'], i),
          "ticker": i.ticker,
          "price": parseInt(getNest(['_order', 'qty'], i) * (i.nav)),
        }
        return info;
      }
    });

    var finalValue = CSVDataValue.filter(i => i);
    this.setState({
      downloadCSV: true,
    });

    this.setState({ tradeList: finalValue }, () => {
      // click the CSVLink component to trigger the CSV download
      this.csvLink.current.link.click()
    })

    this.onCloseDownloadTradeList();
  }

  downloadFidelityTradeListExcel = () => {
    const { items } = this.props;
    const { brokerDropdownValue } = this.state;
    var CSVDataValue = items.map((i) => {
      if (getNest(['_order', 'state', 'valid'], i) == true && getNest(['_order', 'state', 'amtType'], i) == 'Units' && i._checkedToExecute) {
        var info = {
          "account_number": this.state.selectAccountNumber,
          "transaction": getNest(['_order', 'state', 'action'], i) == 'buy' ? 'B' : 'SL',
          "price": parseInt(getNest(['_order', 'tvalue'], i)),
          "quantity": getNest(['_order', 'state', 'qty'], i),
          "ticker": i.ticker,
          "instrx": "MOC",
          "timeLimit": "D",
          "security_type": ["ETF", "ETN"].includes(i.type) ? "EQ" : "M",
          "timing": "M"
        }
        return info;
      }
      if (getNest(['_order', 'state', 'valid'], i) == true && getNest(['_order', 'state', 'amtType'], i) == 'Value' && i._checkedToExecute) {
        var info = {
          "account_number": this.state.selectAccountNumber,
          "transaction": getNest(['_order', 'state', 'action'], i) == 'buy' ? 'B' : 'SL',
          "price": getNest(['_order', 'qty'], i) * (i.nav),
          "quantity": getNest(['_order', 'qty'], i),
          "ticker": i.ticker,
          "instrx": "MOC",
          "timeLimit": "D",
          "security_type": ["ETF", "ETN"].includes(i.type) ? "EQ" : "M",
          "timing": "M"
        }
        return info;
      }
    });

    var finalValue = CSVDataValue.filter(i => i);

    this.setState({
      downloadCSV: true,
    });

    this.setState({ tradeList: finalValue }, () => {
      // click the CSVLink component to trigger the CSV download
      // console.log(brokerDropdownValue)
      if (brokerDropdownValue === "Schwab") {
        this.schwabCsvLink.current.link.click();
      } else {
        this.fidelityCsvLink.current.link.click();
      }
    })

    this.onCloseDownloadTradeList();
  }

  setAccountNumber = (e) => {
    const name = document.getElementById('account-number-box').value;
    this.setState({ selectAccountNumber: name })
  }

  selectTradeList = (item) => {
    this.setState({ tradeListValue: item });
    this.setState({ brokerDropdownValue: '' });
    this.setState({ selectAccountNumber: '' });
  }

  addToBuilder = () => {
    addEventToAnalytics('Act Builder', 'Act Builder', 'ACT_BUILDER', { category: 'advisory_PTC' }, true);
    const { setSFMItems, setBuildPortfolioState, setSFMState, buildportfolio, sfm } = this.props;
    let itemArr = [...buildportfolio.resultPageCart];
    let sfmItemArr = sfm.items;
    const excludeArr = [];
    sfmItemArr.forEach(item => {
      if (item._builder !== true) {
        if (item.vehicle === 'SHARE') {
          excludeArr.push(item.ticker)
        } else {
          item._builder = true;
          itemArr.push(item);
        }
      }
    });
    setSFMItems({ items: sfmItemArr });
    setBuildPortfolioState({ resultPageCart: itemArr });
    const prefix = excludeArr.length > 1 ? "are" : "is";
    const tickers = excludeArr.map(v => v).join(",").replace(/, ([^,]*)$/, ' and $1');
    setSFMState({ showAlert: `Added to builder successfully. ${excludeArr.length ? " " + tickers + " " + prefix + " excluded" : ""}` });
  }

  executeHandler = () => {
    // this.props.actionHandler('Confirm')
    const { executeHandler } = this.props;
    if (executeHandler) {
      executeHandler();
    }
  }

  toggleAgreementModal = (open = false) => {
    this.setState({
      isAgreementOpen: open
    });
  }

  agreementCheckHandle = (e) => {
    this.setState({
      isAgreementChecked: e.target.checked
    });
  }

  closeModalAndExecute = () => {
    this.props.updateBasketAggrementStatus();
    this.setState({
      isAgreementOpen: false,
      isAgreementChecked: false
    });
    this.executeHandler();
  }

  render() {
    const { accountNumber, isAgreementChecked, isAgreementOpen } = this.state;
    const { funds, importedAccounts, items, isGreaterThanMinValue } = this.props;
    let tickerNames;

    if (items.length > 0) {
      tickerNames = items.map(x => x.ticker);
    }

    let itemsValue = this.props.items.length;
    let MFValue = this.props.items.filter(e => comingSoonValues.includes(e.type));
    const item = this.props.items.filter(e => getNest(['_order', 'stage'], e) === 'buy');

    // console.log("=== this.props.activateExecuteButton ===> ", this.props.activateExecuteButton);
    let disabled = !this.props.activateExecuteButton || item.length === 0 || MFValue.length == itemsValue || isEmpty(this.props.profile.apex) ? true : !this.props.profile.apex.account[0].is_active;

    let profileDetails = this.props.profile;
    let showLinkAccountBar = profileDetails && profileDetails.apex && Object.keys(profileDetails.apex).length && Object.keys(profileDetails.apex).length !== 0 && profileDetails.apex.account && profileDetails.apex.account[0] && profileDetails.apex.account[0].status === 'COMPLETE' && profileDetails.apex.account[0].is_active && (!profileDetails.apex.bank || (profileDetails.apex.bank && profileDetails.apex.bank.length && profileDetails.apex.bank.length === 0));
    // let disabled = item.length === 0 || MFValue.length == itemsValue || isEmpty(this.props.profile.apex) ? true: !this.props.profile.apex.account[0].is_active;

    const backHandler = () => {
      this.props.clearHandler('all', false);
      this.props.actionHandler('Act');
    }

    var accountListNew = Object.keys(importedAccounts);

    var tradeList = ["Orion", "Not Applicable"];
    var accountList = ['Fidelity', 'Schwab', 'TD Ameritrade', 'Robinhood', 'Vanguard']

    var orionHeaders = [
      { label: "account", key: "account_number" },
      { label: "action", key: "transaction" },
      { label: "shares", key: "quantity" },
      { label: "ticker", key: "ticker" },
    ];

    var fidelityHeaders = [
      { label: "Acct", key: "account_number" },
      { label: "Action", key: "transaction" },
      { label: "Quantity", key: "quantity" },
      { label: "Symbol", key: "ticker" },
      { label: "Instrx", key: "instrx" },
      { label: "Price", key: "price" },
      { label: "Time Limit", key: "timeLimit" },
    ];

    var schwabHeaders = [
      { label: "Account Number", key: "account_number" },
      { label: "Action", key: "transaction" },
      { label: "Quantity", key: "quantity" },
      { label: "Security Symbol", key: "ticker" },
      { label: "Timing", key: "timing" },
      { label: "Security Type", key: "security_type" }
    ];

    let disabledCSV = (this.state.selectAccountNumber != '' && this.state.brokerDropdownValue == '') || this.state.selectAccountNumber == '';
    let disabledBroker = this.state.tradeListValue == '';
    let disabledAccount = (this.state.tradeListValue != '' && this.state.brokerDropdownValue == '') || (this.state.tradeListValue == '' && this.state.brokerDropdownValue == '')
    const self_role = (window.localStorage.getItem('profile_self_role')) ? window.localStorage.getItem('profile_self_role') : "loggedOut"
    const isEveryFundIsShare = items.every(item => item.vehicle === 'SHARE');
    const isBasketTradeExists = items.filter(item => item.vehicle === 'BASKET').length;
    const isBasketBuyInList = items.filter(item => item.vehicle === 'BASKET' && item._order && item._order.state && item._order.state.action && item._order.state.action === 'buy' && item._checkedToExecute).length;

    /* What is the use of extra span tag with same id="buy-tt-id" Anish ?? */

    return (
      <React.Fragment>
        <CustomModal
          open={isAgreementOpen}
          onClose={() => this.toggleAgreementModal(false)}
          iconTop={<img src="/assets/images/icons/noun_agreement.png" alt="agreement" />}
          title="Please review and accept the agreement below that allows Magnifi to be your investment advisor and manage this portfolio on your behalf."
        >
          <div className="form-container-act" style={{ marginTop: -15 }}>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" checked={isAgreementChecked} onChange={this.agreementCheckHandle} />{' '}
                <a style={{ textDecoration: 'underline' }} href="https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/Magnifi%20-%20Discretinary%20Non-Discretionary%20IMA%20%5BSent%207-22-21%5D-270408129-v7%20-%20Final.docx.pdf" target="_blank">Magnifi Investment Advisory Agreement</a>.
              </Label>
            </FormGroup>
            <FormGroup>
              <Button onClick={this.closeModalAndExecute} disabled={!isAgreementChecked} > Accept </Button>
            </FormGroup>
            <FormGroup style={{ marginBottom: '-2rem' }}>
              <a href="https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/Magnifi%20Thematic%20Risk%20Factors.pdf" target="_blank" style={{ fontSize: 12, textDecoration: 'underline' }}>Risk Factors and Disclosures for Magnifi Managed Portfolios</a>
            </FormGroup>
            {/* <FormGroup check>
              <Label check>
                <Input type="checkbox" />{' '}
                Don't ask me again.
              </Label>
            </FormGroup> */}
          </div>
        </CustomModal>
        <FooterActions hideBack={true} backHandler={backHandler} clearHandler={this.props.clearAllHandler} />
        {isBasketTradeExists ? <a href="https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/Magnifi%20Thematic%20Risk%20Factors.pdf" target="_blank" style={{ fontSize: 12, color: '#fff', textDecoration: 'underline' }}>Risk Factors and Disclosures for Magnifi Managed Portfolios</a> : null}
        {(["", "Other/Curious", "Individual Investor"].includes(self_role) === false) && (
          <span id="buy-tt-id" style={{ margin: '0 0 0 16px' }}>
            <Button style={{ width: '135px' }} color="tools" disabled={!this.props.activate2}
              className="btn h3 text-uppercase font-weight-bold p-3 mb-0 secondary-btn"
              onClick={() => {
                this.props.trackHandler()
                if (funds) {
                  addEventToAnalytics('ACT Footer Button', 'ACT Footer Button', 'ACT_FOOTER_BUTTON', { query: funds.query, logId: funds.logId, tickers: tickerNames, clickBtn: 'Track', category: 'advisory_PTC' }, true);
                }
              }}
            >Track</Button>
          </span>
        )}
        {(["", "Other/Curious", "Individual Investor"].includes(self_role) === false) &&
          <Fragment>
            <span style={{ margin: '0 0 0 16px' }}>
              <Button
                style={{ width: '135px' }}
                color="tools" disabled={false}
                className={`btn h3 text-uppercase font-weight-bold p-3 mb-0 secondary-btn ${isEveryFundIsShare && "disabled"}`}
                onClick={() => this.addToBuilder()}
                id="add-to-builder-button"
              >+ BUILDER</Button>
            </span>
            {isEveryFundIsShare && <Tooltip
              className={`col-grey-bottom`}
              placement="top"
              isOpen={this.state.builderToolTip}
              target="add-to-builder-button"
              toggle={() => this.setState({ builderToolTip: !this.state.builderToolTip })}>
              Stock could not be added to builder
            </Tooltip>}
          </Fragment>
        }
        <span id="buy-tt-id" style={{ margin: '0 0 0 16px' }}>
          <Button style={{ width: '135px' }} color="tools" disabled={!this.props.activate2}
            className="btn h3 text-uppercase font-weight-bold p-3 mb-0 ml-auto secondary-btn"
            onClick={() => {
              this.onDownloadTradeList();
              if (funds) {
                addEventToAnalytics('ACT Footer Generate Tradelist', 'ACT Footer Generate Tradelist', 'ACT_FOOTER_GENERATE_TRADELIST', { query: funds.query, logId: funds.logId, clickBtn: 'Generate Tradelist', category: 'advisory_PTC' }, true);
              }
            }}
          ><img src='/assets/images/icons/Download-Orange.svg' alt='' /> Tradelist</Button>
        </span>
        <span id="buy-tt-id" style={{ margin: '0 4px 0 16px' }}>
          <Button
            id="execute-order-btn"
            color="select"
            disabled={disabled}
            className={`btn h3 text-uppercase font-weight-bold p-3 mb-0 secondary-btn ${showLinkAccountBar ? "disabled" : isGreaterThanMinValue ? "disabled" : ""}`}
            onClick={() => {
              if (funds && !isGreaterThanMinValue) {
                if (isBasketTradeExists && !isEmpty(this.props.profile.apex) && this.props.profile.apex.basket_agreement === false && this.props.basketAggreement) {
                  if (isBasketBuyInList > 0) {
                    return this.toggleAgreementModal(true);
                  }
                }
                this.executeHandler();
                addEventToAnalytics('ACT Footer Button', 'ACT Footer Button', 'ACT_FOOTER_BUTTON', { query: funds.query, logId: funds.logId, tickers: tickerNames, clickBtn: 'Buy', category: 'advisory_PTC' }, true);
              }
            }}
            style={this.props.doRegister ? { pointerEvents: 'none', width: '135px' } : { width: '135px' }}
          >
            Execute
          </Button>
          {/*{ disabled && this.props.doRegister && <RegisterTooltip target={'buy-tt-id'} placement={'top'} /> }*/}
        </span>
        {isGreaterThanMinValue && <Tooltip
          className={`col-grey-bottom`}
          placement="top"
          isOpen={this.state.minTradeValueTooltip}
          target="execute-order-btn"
          toggle={() => this.setState({ minTradeValueTooltip: !this.state.minTradeValueTooltip })}>
          Please resolve above errors
        </Tooltip>}
        <span style={{ display: 'none' }}>
          <CSVLink
            data={this.state.tradeList}
            headers={orionHeaders}
            filename={"MyTradeList.csv"}
            ref={this.csvLink}
          />
          <CSVLink
            data={this.state.tradeList}
            headers={fidelityHeaders}
            filename={"MyTradeList.csv"}
            ref={this.fidelityCsvLink}
          />
          <CSVLink
            data={this.state.tradeList}
            headers={schwabHeaders}
            filename={"MyTradeList.csv"}
            ref={this.schwabCsvLink}
          />
        </span>
        {this.state.downloadTradeList && (
          <Modal
            isOpen={this.state.downloadTradeList}
            toggle={() => this.setState({ downloadTradeList: !this.state.downloadTradeList })}
            centered
            keyboard={true}
            backdrop
            className="account-broker-modal premium-msg-modal account-modal"
            backdropClassName="premium-feature-process-modal-backdrop"
          >
            <ModalBody className="ssf-modal__body" >
              <div className="premium-feature-process-modal-container">
                <div className="header-wrapper">
                  <h1 className="text-align-left">Download Tradelist</h1>
                </div>
                <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => this.onCloseDownloadTradeList()}></i>
                <div className="account-dropdown-element" style={{ paddingBottom: '18px' }}>
                  <span style={{ color: 'white' }}>To Execute Selected Enhancements In Desired Format</span><br />
                </div>
                <div className="account-dropdown-element">
                  <span style={{ color: 'white' }}>Execution Platform</span>
                  <Dropdown
                    isOpen={this.state.tradeListDropdown}
                    toggle={this.tradeListDropDownToggle}
                    className="account-dropdown-wrapper"
                  >
                    <DropdownToggle caret className="dd-input-area">
                      {this.state.tradeListValue}
                    </DropdownToggle>
                    <DropdownMenu center>
                      {
                        tradeList.map((item) => {
                          return <DropdownItem value={item} onClick={() => this.selectTradeList(item)}>{item}</DropdownItem>
                        })
                      }
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div className="account-dropdown-element">
                  <span style={{ color: 'white' }}>Broker/Custodian</span>
                  <Dropdown
                    isOpen={this.state.accountDropdown}
                    toggle={this.accountDropdownToggle}
                    className="account-dropdown-wrapper"
                  >
                    <DropdownToggle
                      caret
                      className="dd-input-area"
                      disabled={disabledBroker}
                    >
                      {this.state.brokerDropdownValue}
                    </DropdownToggle>
                    <DropdownMenu center >
                      {this.state.tradeListValue === 'Orion' ?
                        accountList.map((item) => {
                          return <DropdownItem value={item} onClick={() => this.getAccountName(item)}>{item}</DropdownItem>
                        }) :
                        accountList.map((item) => {
                          if (['Fidelity', 'Schwab'].includes(item)) {
                            return <DropdownItem value={item} onClick={() => this.getAccountName(item)}>{item}</DropdownItem>
                          }
                        })
                      }
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div>
                  {accountNumber.length > 0 ? (
                    <div className="account-dropdown-element">
                      <span style={{ color: 'white' }}>Account</span>
                      <Dropdown
                        isOpen={this.state.accounNoDropdown}
                        toggle={this.accountNoDropdownToggle}
                        className="account-dropdown-wrapper"
                      >
                        <DropdownToggle
                          caret
                          className="dd-input-area"
                          disabled={disabledAccount}
                        >
                          {this.state.selectAccountNumber}
                        </DropdownToggle>
                        <DropdownMenu center>
                          {
                            accountNumber.map((item) => {
                              return <DropdownItem value={item} onClick={() => this.getAccountNumber(item)}>{item}</DropdownItem>
                            })
                          }
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  ) : (
                    <div className="account-dropdown-element">
                      <span style={{ color: 'white' }}>Enter Account Number</span>
                      <input
                        id="account-number-box"
                        className="dd-input-area"
                        type="text"
                        value={this.state.selectAccountNumber}
                        onChange={this.setAccountNumber}
                        autoComplete="off"
                        disabled={disabledAccount}
                      />
                    </div>
                  )}
                  {this.state.tradeListValue === 'Orion' ? (
                    <div className="download-csv">
                      <ButtonGroup className="cta-wrapper justify-content-left">
                        <PrimaryButton className="btn secondary-btn" disabled={disabledCSV} type="button" onClick={this.downloadOrionTradeListExcel} style={{ maxWidth: '203px', cursor: 'pointer' }}>
                          Download Tradelist
                        </PrimaryButton>
                      </ButtonGroup>
                    </div>
                  ) : (
                    <div className="download-csv">
                      <ButtonGroup className="cta-wrapper justify-content-left">
                        <PrimaryButton className="btn secondary-btn" disabled={disabledCSV} type="button" onClick={this.downloadFidelityTradeListExcel} style={{ maxWidth: '203px', cursor: 'pointer' }}>
                          Download Tradelist
                        </PrimaryButton>
                      </ButtonGroup>
                    </div>
                  )}
                </div>
              </div>
            </ModalBody>
          </Modal>
        )}
        {this.state.downloadCSV && (
          <CustomAlert className="alert-windown-wrapper" color='info'>
            Tradelist Download Successfully.
          </CustomAlert>
        )}
      </React.Fragment>
    )
  }
}

export default Footer;

import React from 'react';
import { D3Sunburst } from 'components/charts/D3Charts';
import NotApplicable from 'components/NotApplicable';
import { RegionChartMapping } from 'ChartData';
import { SectorColors } from 'data/Colors';
import { cn, AssetAllocationBurstChartMapping, CN } from 'DataSet';

const AllocationChartB_ = (props) => {
  console.log({ props });
  
  let { card, view, name, centerDisplay, type } = props;

  if (typeof card === 'undefined' || typeof card.Region === 'undefined' || typeof card.chartData === 'undefined') return <NotApplicable />;

  const currentChartData = card.chartData.filter(e => e.display_view_name === view)[0];

  const formatTree = data => {
    if (!data) return data;
    return data.map(e => {
      let name = e.n;
      if (name && AssetAllocationBurstChartMapping[`${name}`] !== 'undefined') {
        name = AssetAllocationBurstChartMapping[`${name}`];
      }
      if (typeof name === 'undefined') {
        name = e.n;
      }
      let out = { name, size: e.v }
      if (e.sub){
        out.children = formatTree(e.sub);
        out.value = out.children.reduce((prev,curr)=>prev+parseFloat(curr.size||0),0)
      }
      return out;
    })
  }
  const data = {
    name: props.name,
    children: formatTree(card.Region)
  };

  if (data.children && data.children.length === 0) {
    localStorage.setItem(type,JSON.stringify(null))
    return <NotApplicable />;
  }

  const sum = (arr, key) => {
    return arr.reduce((a, b) => a + (b[key] || 0), 0);
  }

  let newArr = JSON.parse(JSON.stringify(data));

  newArr && newArr.children && newArr.children.map((item) => {
    item.size = sum(item.children, 'size')
    return item;
  });

  let colorsArr = props.colors;
  if (data.children && data.children.length > 0) {
    data.children.map((h, i) => {
      h.children.map((f, k) => {
        if (f.name && props.colors[f.name]) {
          colorsArr = props.colors;
        } else {
          colorsArr = {
            ...colorsArr,
            [f.name] : SectorColors[k % SectorColors.length],
          };
        }
      });
    });
  }

  // let primaryTextStyle = {
	// 	fontSize: 11,
	// 	fontWeight: 'bold',
	// 	zIndex: '5',
	// }, secondaryTextStyle = {};
  // const chartTypePresent = typeof name !== 'undefined' ? name : '';
	// let centerText = props.centerText || '';

  // if (typeof currentChartData !== 'undefined') {
  //   if (currentChartData.data) {
  //     let displayText = RegionChartMapping.filter(e => e.chart_name === currentChartData.node);
  //     if (displayText.length) {
  //       if (displayText[0].region_name) {
  //         if (card[currentChartData.data] >= 0) {
  //           centerDisplay = `${card[currentChartData.data].toFixed(1)}%`;
  //           centerText = displayText[0].region_name;
  //           // centerText = centerText.substring(0, 11);
  //           primaryTextStyle = chartTypePresent === 'Allocations' ?  {
  //             // top: '39%',
  //             fontSize: 11,
  //             fontWeight: 'bold',
  //           }: {};
  //           secondaryTextStyle = chartTypePresent === 'Allocations' ? {
  //             top: centerText.length > 8 ? '54%' : '52%',
  //             fontSize: centerText.length > 8 ? 7 : 9,
  //           } : {};
  //         }
  //       }
  //     }
  //   }
  // }

  if (typeof card.overlap_data !== 'undefined' && card.overlap_data && card.overlap_data.length > 0) {
    if (props.activeRegion !== 'Allocations' &&  props.activeRegion !== '') {
      // console.log(card.overlap_data);
      const activeSegment = CN[props.activeRegion];
      // console.log('activeSegment --> ', activeSegment);
      if (activeSegment) {
        const overlapD = card.overlap_data[0];
        const overlapValue = overlapD[activeSegment];
        // console.log(props.activeRegion, centerDisplay, CN[props.activeRegion], overlapValue, card.ticker);
        if (typeof overlapValue !== 'undefined') {
          centerDisplay = Math.round(overlapValue)+'%';
        }
      }
    }
  }

  const allowedTooltip = ['Equities', 'Bonds'];
  const { overlap_data, centerDisplayText } = props;

  //to be used in the asset allocation chart
  let _save = data && data.children && data.children.map(i=>{
    return {
      name: i.name,
      value: i.value
    }
  })
  localStorage.setItem(type,JSON.stringify(_save))


  return (
    <div className="simplePieChartWrapper d3ChartWrapper model-chart">
      <div className="chart-section">
        <D3Sunburst
          data={data}
          width={props.width || 84}
          height={props.height || 84}
          colors={colorsArr}
          clickHandler={() => {}}
        />
      </div>
      <div className="legend-section">
        {overlap_data && <span className="total-overlap">Total Overlap: {centerDisplayText}</span>}
        <span>
        {data && data.children && data.children.map(item => {
          if(!allowedTooltip.includes(item.name)) return '';
          const overlap = overlap_data && overlap_data.length ? overlap_data[0][item.name.toLowerCase()] : '';
          return(
            <span className="single-legend">
              <span className="legend-color" style={{background: colorsArr[item.name]}}></span>
              <span className="legend-content">
                <span>{item.name}: {item.value.toFixed(1)}%</span>
                {overlap && <span className="overlap">Overlap: {overlap.toFixed(1)}%</span>}
              </span>
            </span>
          )}
        )}
        </span>
      </div>
    </div>
  )
}

export const ModelRegionsChart = React.memo(AllocationChartB_);

export default ModelRegionsChart;

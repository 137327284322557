import React, { Fragment,Component } from 'react'
import { isEmpty, get } from 'lodash'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { Formik, Field, FieldArray } from 'formik'
import * as Yup from 'yup'
import {
  Input,
  FormGroup,
  Form,
  Label,
  Alert,
} from 'reactstrap'

import { 
  IconButton,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  PrimaryButton,
  CustomFormFeedback,
  Spinner
} from 'shared-components'
import { tradeItVerificationRequest, tradeItVerificationReset } from 'actions/broker'

class BrokerTradeItSecurityQuestionsContainer extends Component {	
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)	
	}

	componentDidUpdate(prevProps) {	
		if(this.props.importAccountsSuccess !== prevProps.importAccountsSuccess 
			&& this.props.importAccountsSuccess) { 
	          this.props.history.push('/settings/broker')
	  	}
	}

	toggle() {
		this.props.tradeItVerificationReset();
		this.props.history.push('/settings/broker')
	}

	handleSubmit(values) {
		this.props.tradeItVerificationRequest({
			securityAnswer: values.answer,
			token: this.props.traditVerificationToken,
			brokerName: this.props.brokerName
		})
	}

	getAnswerComponent(props) {
		const { securityQuestionOptions } = this.props;
		const errorClassName = props.errors.answer && props.touched.answer
	            ? 'brokers-signin-form__input-field brokers-signin-form__input-token is-invalid'
	            : 'brokers-signin-form__input-field brokers-signin-form__input-token'

		if(!isEmpty(securityQuestionOptions)) {			
			return <div>
			  {securityQuestionOptions.map((option, index) => <div key={option}>
		        <Label>
	              <Input 
	              	type="radio" 
	              	name="answer" 
	              	id={`answer${index}`}
	              	value={option} 
	              	className={`trade-it-radio ${errorClassName}`} 
            		onChange={props.handleChange}
            		onBlur={props.handleBlur} />{' '}
	              {option}
	            </Label>
	          </div>)}
	          {!isEmpty(props.errors) && !isEmpty(props.touched) && 
	          	<Alert color="danger">{props.errors['answer']}</Alert>}
	        </div>
		}

		return <Input
	        type="password"
	        name="answer"
	        id="answer"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
	        placeholder="Enter answer"
	        className={errorClassName}
	        autocomplete="off"
	      />
	}

	render() {
		const { securityQuestion, history, tradeItVerifyError, isFetching } = this.props;

		// if(isVerificationSuccess) {
		// 	return <Redirect to='/settings/broker' />
		// } 
		const validationSchema = Yup.object().shape({
			answer: Yup.string().required('Answer is a required field'),
		})

		return <Modal
	            isOpen={true}
	            centered
	            size="sm"
	            className=""
	          >
		    <ModalHeader>
			  <IconButton
	            onClick={this.toggle}
	            iconClass="fa-arrow-left"
	            className="float-left"
	          />
	          Security question
	        </ModalHeader>
		    <ModalBody>
		     <Formik
	      		initialValues={{ answer: ''  }}
	            onSubmit={this.handleSubmit}
	            validationSchema={validationSchema}
	            render={props => (
	              <Form
	                className="tradeit-security-question-form"
	                noValidate={true}
	                onSubmit={props.handleSubmit}
	              >
	              	<FormGroup className="text-left">
		              <Label htmlFor="answer" >
		                {securityQuestion}
		              </Label>
		              {this.getAnswerComponent(props)}
                  	  <CustomFormFeedback formProps={props} fieldName="answer" />
		              {tradeItVerifyError && tradeItVerifyError.message && (
	                    <Alert color="danger">{tradeItVerifyError.message}</Alert>
	                  )}
		            </FormGroup>
	              	<FormGroup className="text-right">
		      			<PrimaryButton type="submit">Submit</PrimaryButton>
		            </FormGroup>
	              </Form>
	            )}
	          />
          	  <Spinner isFetching={isFetching} />
		    </ModalBody>
		  </Modal>	
	}
}

const mapStateToProps = state => ({
  importAccountsSuccess: state.importBrokerAccount.importAccountsSuccess,
  tradeItVerifyError: state.tradeItSecurityHandling.error,
  securityQuestion: state.tradeItSecurityHandling.securityQuestion,
  securityQuestionOptions: state.tradeItSecurityHandling.securityQuestionOptions,
  isVerificationSuccess: state.tradeItSecurityHandling.isVerificationSuccess,
  traditVerificationToken: state.tradeItSecurityHandling.traditVerificationToken,
  isFetching: state.tradeItSecurityHandling.isFetching,
  brokerName: state.tradeItSecurityHandling.brokerName
})

const mapDispatchToProps = {
	tradeItVerificationRequest,
	tradeItVerificationReset
}

export default connect(mapStateToProps, mapDispatchToProps)(BrokerTradeItSecurityQuestionsContainer);
import React, { Component, Fragment } from 'react';
import '../_index.scss';
import './join.scss';
import Header from "../Header";
import Search from "../Search";
import PopularSearch from "../PopularSearch";
import LandingForm from "../LandingForm";
import TrendingTheme from "../TrendingTheme";
import Info from "../Info";
import Footer from "../Footer";
import VidIntro from "../VidIntro";
import {
  Card, Button, CardImg, CardTitle, CardText, CardDeck,
  CardSubtitle, CardBody, CardFooter
} from 'reactstrap';
import { Radio, Divider } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import config from '../../../../env'
import { createSubscriptionRequest } from 'actions/profile'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { createLoadingSelector } from 'selectors/loading-selector'
import { isTermsAgreed } from 'selectors/auth-selector'
import { RETAIL_USERS_ROLES } from '../../Constant/FormData';
import { get } from 'lodash';

class JoinPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      is_visible: false,
      isPlanChange:false,
      planType: process.env.REACT_APP_SUB_DOMAIN === "retail" ? 'selector' : 'enhancer',
    }
  }

  componentDidMount = () => {
    var scrollComponent = this;
    document.addEventListener("scroll", function(e) {
      scrollComponent.toggleVisibility();
    });
    let isPlanChange = this.props.history.location.state?this.props.history.location.state.isPlanChange?this.props.history.location.state.isPlanChange:false:false
    const planType = get(this.props.history, 'location.state.planType', process.env.REACT_APP_SUB_DOMAIN === "retail" ? 'selector' : 'enhancer');
    console.log(isPlanChange)
    this.setState({
      isPlanChange:isPlanChange,
      planType
    })
  }

  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }

  toCaptilize = (myString) => {
    return myString.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
  }

  queryHandler =(query)=> {
    if (query) {
        let queryString = "query=" + encodeURIComponent(this.toCaptilize(query.toLowerCase()));
        this.props.history.push(`/securities?${queryString}`);
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  addPixelBlock = (plan)=>{
    let image = document.createElement('img')
    image.style.borderStyle = 'none'
    image.height = 1
    image.width  = 1
    image.alt = ''
    if( plan === 'pro'){
      image.src = 'https://insight.adsrvr.org/track/pxl/?adv=ijdipdx&ct=0:819s9gz&fmt=3'
    }
    else if( plan === 'pro-plus'){
      image.src = 'https://insight.adsrvr.org/track/pxl/?adv=ijdipdx&ct=0:9jd0ijr&fmt=3'
    }
    else if( plan === 'demo') {
      image.src = 'https://insight.adsrvr.org/track/pxl/?adv=ijdipdx&ct=0:x04z4b7&fmt=3'
    }
    document.getElementById('landing-page').appendChild(image)
  }

  redirectToSignup = (plan) => {
    const {isPlanChange, planType} = this.state
    this.addPixelBlock(plan)
    if(isPlanChange){
      if(plan==='basic'){
        this.props.history.push('/');
      }else{
        let plan_name
        if(plan==='pro'){
          plan_name='Premium Lite'
        }
        if(plan==='pro-plus'){
          plan_name='Premium Plan'
        }
        this.props.createSubscriptionRequest({ plan_name, planType}, this.subscribeNow)
      }
    }else{
      this.props.history.push('/register', {plan, planType});
    }
  }
  subscribeNow = (sessionId) => {
    console.log('subscribeNow')
    // const sessionId = this.props.stripeSessionID;
    if(sessionId){
      const stripe = window.Stripe(config.stripePublishableKey);
      stripe.redirectToCheckout({
        sessionId
      }).then((result) => {
        console.log(result)
        // console.log("results status",result)
      });
    }
  }

  requestDemo  = ()=>{
    this.addPixelBlock('demo')
    window.location = 'https://share.hsforms.com/1Ew4U5OnWTB-oMw7i94qG1w47xec'
  }

  handleChangePlan = (e) => {
    this.setState({
      planType: e.target.value
    });
  }

  render() {
    const { loggedIn, registerSuccess, registerStepStatus, registerGoogleAuthToken, profileUpdateSuccess, googleRegisterSuccess, createSubscriptionRequest } = this.props;
    const { isStripeSubscription, plan_selected, isPlanChange, is_visible } = this.state
    const profile_self_role =  window.localStorage.getItem('profile_self_role') ? window.localStorage.getItem('profile_self_role') : 'loggedOut';
    const selectorText = RETAIL_USERS_ROLES.includes(profile_self_role) ? "Cart" : "Selector";
    if (!isStripeSubscription && loggedIn && window.location.search.length === 0 && (registerStepStatus == 2 || googleRegisterSuccess === true )) {
      console.log('redirect to securities')
      // if query is not present and loggedIn then redirect to magnifi.com/discover
      const pathname = '/securities';
      return (
        <Redirect
          to={{
            pathname,
            search: window.location.search
          }}
        />
      )
    }

    return (
      <div id="landing-page" className="main-join-page">
      <div className='banner landing-page'>
          <Header isLandingPage />
          <div className="landing-page-banner">
            <h1>Unlock the Power of Advanced Investment Intelligence with Magnifi</h1>
            <h4><strong>A limited time offer: </strong> Get 13 months for the price of 12 when you schedule a demo.</h4>
            <span class="btn-container"><span onClick={this.requestDemo}  class="custom-link-btn">Request Demo</span></span>
          </div>
      </div>
      <CardDeck>
        <Card className={process.env.REACT_APP_SUB_DOMAIN === "retail" ? "single-card" : ""}>
          <CardBody>
            <CardTitle tag="h5">Magnifi Basic</CardTitle>
            <CardSubtitle tag="h6" className="mb-2 text-muted">Register to start personalizing your queries with Magnifi</CardSubtitle>
            
            <hr className="separator-break"></hr>
            <div className="empty-space space7"></div>
            <div className="cardContent">
              <div className="priceAmount">FREE</div>
              <ul>
                <li><Radio checked disabled></Radio>Discover, analyze and compare investments across thousands of ETFs and Mutual Funds.</li>
              </ul>
              <ul>
                <li><Radio checked disabled></Radio>Act on investment ideas by trading and buying funds{process.env.REACT_APP_SUB_DOMAIN === "retail" ? "" : " with many top brokers"}.</li>
              </ul>
            </div>
          </CardBody>
          <CardFooter>
            <Button onClick={() => this.redirectToSignup('basic')}>{isPlanChange?'Personalize and Start Discovering':'Sign up'}</Button>
          </CardFooter>
        </Card>
        {process.env.REACT_APP_SUB_DOMAIN !== "retail" && 
        <Card>
          <CardBody>
            <CardTitle tag="h5">Magnifi Pro</CardTitle>
            <CardSubtitle tag="h6" className="mb-2 text-muted">Modernize investment discovery, proposal generation, portfolio enhancement and client engagement with Magnifi's natural language and investment intelligence capabilities.</CardSubtitle>
            <hr className="separator-break"></hr>
            <div className="empty-space space3"></div>
            <div className="cardContent">
              <div className="priceAmount">$250/mo</div>
              <Radio.Group id={'plan'} onChange={this.handleChangePlan}  value={this.state.planType}>
              <ul><li><b>Choose one of the options</b></li></ul>
             {process.env.REACT_APP_SUB_DOMAIN !== "retail" &&
             <Fragment>
              <ul>
                <li>
                  <Radio value="enhancer"></Radio><strong>Enhancer </strong>Produce instant value by
                    showing clients and prospects
                    enhancements to their portfolios.
                    Generate actionable proposals with clear
                    adjustments to meet individual
                    investment objectives.
                </li>
              </ul>
                <div className="devider">
                  <hr className="separator" />Or<hr className="separator" />
                </div>
              </Fragment>}
              <ul>
                <li><Radio value="selector" disabled={process.env.REACT_APP_SUB_DOMAIN === "retail"}></Radio><strong>Selector </strong>Compare funds, SMAs, and models based on customizable portfolio attributes using Magnifi’s patented fund Selector. See the impact of adding new holdings into your portfolio, or download the data to excel.</li>
              </ul>
              <ul>
                <li><strong>Client Proposals</strong> White-label ready proposals document and communicate investment strategies to clients. Use the proposals to store your due diligence for Reg BI, Suitability, and other firm requirements.</li>
              </ul>
              </Radio.Group>
            </div>
          </CardBody>
          <CardFooter>
            <Button onClick={() => this.redirectToSignup('pro')}>{isPlanChange?'Proceed With Payment':'Sign up'}</Button>
          </CardFooter>
        </Card>}
        {process.env.REACT_APP_SUB_DOMAIN !== "retail" && <Card>
          <CardBody>
            <CardTitle tag="h5">Magnifi Pro +</CardTitle>
            <CardSubtitle tag="h6" className="mb-2 text-muted">In addition to all the features of Magnifi Pro, unlock the full power of Magnifi to manage models, portfolios, and client accounts all in one place.</CardSubtitle>
            <hr className="separator-break"></hr>
            <div className="empty-space space2"></div>
            <div className="cardContent">
              <div className="priceAmount">$500/mo</div>
              <ul>
                <li><Radio checked disabled></Radio><strong>Builder</strong> Magnifi's Builder capability helps construct better portfolios via existing models or from scratch and test changes, add thematic tilts, and more.</li>
              </ul>
              <ul>
                <li><Radio checked disabled></Radio><strong>Enhancer</strong> Produce instant value by showing clients and prospects enhancements to their portfolios. Generate actionable proposals with clear adjustments to meet individual investment objectives.</li>
              </ul>
              <ul>
                <li><Radio checked disabled></Radio><strong>Client Portal</strong> Look across all your client accounts in seconds. Use plain-English search to find specific holdings and allocations within accounts, to adjust exposures quickly.</li>
              </ul>
            </div>
          </CardBody>
          <CardFooter>
            <Button onClick={() => this.redirectToSignup('pro-plus')}>{isPlanChange?'Proceed With Payment':'Sign up'}</Button>
          </CardFooter>
        </Card>}
        
      </CardDeck>
      <Footer noBlue/>
        {is_visible && <div className="style-light footer-scroll-top" onClick={() => this.scrollToTop()}>
          <a className="scroll-top"><i className="fa fa-angle-up fa-stack fa-rounded btn-default btn-hover-nobg"></i></a>
        </div>}
      </div>
    );
  }
}


const mapStateToProps = ({ auth, register, loading, profile }) => ({
  registerGoogleAuthToken: register.registerGoogleAuthToken,
  registerAppleAuthToken: register.registerAppleAuthToken,
  registerStepStatus: register.registerStepStatus,
  registerFailed: register.registerFailed,
  registerSuccess: register.registerSuccess,
  error: register.error,
  isFetching: createLoadingSelector(['REGISTER'])({loading}),
  isTermsAgreed: isTermsAgreed(auth),
  loggedIn: auth.loggedIn,
  user: auth.user,
  profileUpdateSuccess: profile.profileUpdateSuccess,
  googleRegisterSuccess: register.googleRegisterSuccess,
  stripeSessionID: profile.stripeSessionID
})

const mapDispatchToProps = {
  createSubscriptionRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JoinPage);



import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Slider from "react-slick";
import { UncontrolledTooltip } from 'reactstrap';
import { Card, Row, Col } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

import { updateEvaluateCheckboxRequest } from 'actions/enhancer';

import { arrayToHash, getPortfolioBenchmarkOfSelectedTicker } from 'Utils'
import { CN  } from 'DataSet';

import ItemCard from './ItemCard';
import { addEventToAnalytics } from 'Utils'

class ExcludeList extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      _viewIndex: "1",
    };
  }

  addTickerToAnalysis = (ticker) => {
    const { updateEvaluateCheckboxRequest, enhanceTickerFilter } = this.props;
    let evaluateTickers = enhanceTickerFilter;

    if (ticker && ticker !== '') {
      evaluateTickers.push(ticker);
    }

    if (updateEvaluateCheckboxRequest) {
      updateEvaluateCheckboxRequest({
        evaluateTickers: _.orderBy(evaluateTickers, ['asc']),
      });
    }
  }

  tabOnChange = (selectedKey, data, ticker, query, index) => {
    this.setState({ _viewIndex: selectedKey });
  }

  render () {
    const {
      data,
      portfolioQuery,
      data: { query, result, short_name, ticker, pseudo_query },
      summary: { portfolioFunds: _summaryPortfolioFunds, invalidTickerFunds: _invalidTickerFunds },
    } = this.props;

    const { _viewIndex } = this.state;

    let excludedListArray = data.filter((kl) => kl.target_card && kl.target_card.funds && kl.target_card.funds.length)
    .filter((io) => io.result && io.result._defaultFRR && io.result._relevanceChartTabs);

    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      initialSlide: 0,
      nextArrow: <RightOutlined />,
      prevArrow: <LeftOutlined />,
      responsive: [
        {
          breakpoint: 1900,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5
          }
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 1180,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    };

    const renderDesign = excludedListArray && excludedListArray.length && (
      <Slider {...settings}>
        { excludedListArray.map((staticCardValue, kai) => {
          if (staticCardValue && staticCardValue.result && staticCardValue.result._defaultFRR) {
            const { result: { _defaultFRR, _relevanceChartTabs, query_var, Highlights }, target_card: { funds: targetCardFunds } } = staticCardValue;
            const excludedFund = targetCardFunds && targetCardFunds.length > 0 && targetCardFunds[0];
            let originalWeight = null;
            if (_summaryPortfolioFunds && _invalidTickerFunds && staticCardValue && staticCardValue.ticker) {
              const findTickerInSummaryPortfolioFunds = _summaryPortfolioFunds.find((kl) => kl.ticker === excludedFund.ticker) || _invalidTickerFunds.find((jk) => jk.ticker === excludedFund.ticker);
              if (findTickerInSummaryPortfolioFunds && findTickerInSummaryPortfolioFunds.original_weight && findTickerInSummaryPortfolioFunds.original_weight !== '') {
                originalWeight = Number(findTickerInSummaryPortfolioFunds.original_weight);
                originalWeight = parseFloat(findTickerInSummaryPortfolioFunds.original_weight.toFixed(2));
              }
            }
            return (
              <div className="excluded-fund-card-wrapper">
                <div className="excluded-fund-card-label-wrapper pos-rel">
                  <div className="excluded-fund-card-label">{ticker} {(originalWeight || originalWeight === 0) && `(Weight: ${originalWeight}%)`}</div>
                  <i id={`add-fund-to-analysis-${kai}`} class="fal fa-plus" onClick={() => this.addTickerToAnalysis(staticCardValue.ticker)}></i>
                  <UncontrolledTooltip className="col-grey" placement="right" target={`add-fund-to-analysis-${kai}`}>
                    Add to analysis
                  </UncontrolledTooltip>
                </div>
                <Card className="static-card-body">
                  <ItemCard
                    key={kai}
                    className="enhancer-fund-card"
                    i={1}
                    staticCard
                    query={query}
                    data={excludedFund}
                    highlights={Highlights}
                    defaultFRR={_defaultFRR}
                    activeViewTab={_viewIndex}
                    originalQueryVar={query_var}
                    portfolioQuery={portfolioQuery}
                    relevanceChartTabs={_relevanceChartTabs}
                    ticker={staticCardValue.ticker}
                    tabOnChange={this.tabOnChange}
                    />
                </Card>
              </div>
            )
          }
        })}
      </Slider>
    );

    return (
      <Row className="enhance-result-page-container">
        <Col span={24} className="excluded-fund-list-wrapper">
          <div className="itemlist-carousel-wrap " style={{ display: 'inherit', width: '100%' }}>
            {renderDesign}
          </div>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state) => ({
  portfolioQuery: state.enhancer.query,
});

const mapDispatchToProps = {
  updateEvaluateCheckboxRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExcludeList);

import React from 'react';
import { connect } from 'react-redux';
import { Input, Dropdown, Menu } from 'antd';
import _ from 'lodash';
import { SearchOutlined } from '@ant-design/icons';
import { ScoreAttrs, AdditionalScoreAttrs, ActiveCalcScoreAttrs } from 'DataSet';
import { setCustomCalcScoreAttrs } from 'actions/weights';
import SelectorName from './SelectorName';

const { SubMenu } = Menu;
const { Item } = Menu;

class CreateSelector extends React.Component {
	constructor(props) {
    super(props)
    this.state = {
    	allAttributes: this.props.customCalculatorAttrs,
    	searchText: '',
    	searchResults: [],
    	selectedAttrs: [],
    	showResults: false,
			editSelector: false,
    }
    this.addAttributeWrapper = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
		const { selectedCalculator, currentScoreAttrs } = this.props;
    const { allAttributes } = this.state;
    const selectedAttrs = allAttributes.filter(item => item.col in currentScoreAttrs);
		this.setState({
			selectedAttrs: this.getCurrentWeight(selectedAttrs),
			editSelector: true,
		})
  }

  componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
		if(this.addAttributeWrapper && !this.addAttributeWrapper.current.contains(event.target)){
			if(event.target.className !== 'attr' && event.target.className !== 'ant-input'){
				this.closeResults();
			}
    }
  }

  closeResults = () => {
  	this.setState({showResults: false});
  }

  searchAttributes = (e) => {
  	this.setState({searchText: e.target.value, showResults: true});
  	if(!e.target.value){
  		this.setState({searchResults: [], showResults: false})
  	}else{
	  	const searchResults = this.runSearch(e.target.value);
	  	this.setState({searchResults})
  	}
  }

  runSearch = (attr) => {
  	const { allAttributes, selectedAttrs } = this.state;
  	const selectedArray = selectedAttrs.map(item => item.name);
  	const results = allAttributes
	  	.filter(item => {
	  		const attrName = item.name.toLowerCase();
				const parentName = item['parent_group_name'] ? item['parent_group_name'].toLowerCase() : item.parent_group_name;
	  		const searchText = attr.toLowerCase().trim();
	  		return attrName.includes(searchText) || (parentName && parentName.includes(searchText));
	  	})
			.filter(item => !selectedArray.includes(item.name));
  	return results;
  }

  selectAttribute = (attr) => {
  	const { allAttributes, selectedAttrs, searchResults } = this.state;
  	let updatedSelection = [...selectedAttrs, attr];
    if(updatedSelection.length > 12){
      this.props.setGenSelectorWarning('*Maximum 12 metrics allowed.');
      return;
    }
  	updatedSelection = this.addWeights(updatedSelection);
  	const updatedSearchResults = searchResults.filter(item => item.name !== attr.name);
  	this.setState({
  		searchResults: updatedSearchResults,
  		selectedAttrs: updatedSelection,
  	});
  	this.props.setScoreAttrs(updatedSelection);
  }

  deselectAttribute = (attr) => {
  	const { searchResults, selectedAttrs, searchText } = this.state;
  	let updatedSelection = selectedAttrs.filter(item => item.name !== attr.name);
    if(updatedSelection.length < 13){
      this.props.setGenSelectorWarning('');
    }
  	updatedSelection = this.deleteWeights(updatedSelection);
  	this.setState({
  		selectedAttrs: updatedSelection,
  	}, () => {
  		let updatedSearchResults = this.runSearch(searchText);
	  	this.setState({
	  		searchResults: updatedSearchResults,
	  	})
  	});
  	this.props.setScoreAttrs(updatedSelection);
  }

  addWeights = (selectedAttrs) => {
    let updatedSelectedAttrs = this.getCurrentWeight(selectedAttrs);
    const totalAttrs = updatedSelectedAttrs.length;
    if(totalAttrs > 1){
      updatedSelectedAttrs[totalAttrs - 1].weight = 0;
    }else{
      updatedSelectedAttrs[0].weight = 100;
    }
  	return updatedSelectedAttrs;
  }

  deleteWeights = (selectedAttrs) => {
    let updatedSelectedAttrs = this.getCurrentWeight(selectedAttrs);
    const totalAttrs = updatedSelectedAttrs.length;
    let totalAttrsWeight = updatedSelectedAttrs.reduce((prev, cur) => {
      return prev + cur.weight;
    }, 0);
    let sumOfWeights = 0, out = [];
    if(totalAttrsWeight){
      out = updatedSelectedAttrs.map((attr, index) => {
        attr.weight = Math.floor((attr.weight/totalAttrsWeight) * 100);
        if(index === totalAttrs-1){
          attr.weight = 100 - sumOfWeights;
        }
        sumOfWeights += attr.weight;
        return attr;
      })
    }else{
      out = updatedSelectedAttrs.map((attr, index) => {
        attr.weight = Math.floor(100/totalAttrs);
        if(index === totalAttrs-1){
          attr.weight = 100 - sumOfWeights;
        }
        sumOfWeights += attr.weight;
        return attr;
      })
    }
    return out;
  }

  getCurrentWeight = (selectedAttrs) => {
    const { currentScoreAttrs } = this.props;
    const attrs = _.clone(selectedAttrs);
    const updatedSelectedAttrs = attrs.map(item => {
      if(item.col in currentScoreAttrs){
        item.weight = currentScoreAttrs[item.col];
      }
      return item;
    });
    return updatedSelectedAttrs;
	}

	getParentGroup = (searchResults) => {
		// console.log(searchResults);
		let parentGroup = [];
		searchResults.forEach(item => {
			if(item.parent_group_name && !parentGroup.includes(item.parent_group_name)){
				parentGroup.push(item.parent_group_name);
			}else if(!item.parent_group_name){
				parentGroup.push(item.name);
			}
		});
		// console.log(parentGroup);
		return parentGroup;
	}

  render() {
		const { allAttributes, searchResults, selectedAttrs, showResults, editSelector } = this.state;
		const { prefixIcon , suffixIcon } = this.props;
		const parentGroup = this.getParentGroup(searchResults);
		const menu = (
      <div className="search-results">
				<div className="results-content">
					<Menu mode="vertical">
						{parentGroup.length > 0 ?
							parentGroup.map((item, index) => {
								const childGroup = searchResults.filter(attr => attr.parent_group_name === item);
								const noChild = (!childGroup || !childGroup.length) && searchResults.find(singleAttr => singleAttr.name === item);
								if(childGroup && childGroup.length > 0) {
									return (
										<SubMenu
											key={"subMenu-"+index}
											title={
												<div
													className="result-item"
												>
													<span className="attr">
														<SearchOutlined />
														<span>
															{item}
														</span>
													</span>
												</div>
											}
											popupClassName="level2-dropdown"
										>
											{childGroup.map(option => {
												return(
													<Menu.Item
														onClick={() => this.selectAttribute(option)}
													>
														<div
															className="result-item"
														>
															<span className="attr">
																{option.name}
															</span>
															<span className="attr-icon">
																<i className="fa fa-plus"/>
															</span>
														</div>
													</Menu.Item>
												)
											})}
										</SubMenu>
									)
								} else {
									return (
										<Menu.Item
											onClick={() => this.selectAttribute(noChild)}
										>
											<div
												className="result-item single-result-item"
											>
												<span className="attr">
													<SearchOutlined />
													<span>
														{noChild.name}
													</span>
												</span>
												<span className="attr-icon">
													<i className="fa fa-plus"/>
												</span>
											</div>
										</Menu.Item>
									)
								}
							}) :
							<Menu.Item>
								<div className="result-item">
									No results found
								</div>
							</Menu.Item>
						}
					</Menu>
				</div>
				{selectedAttrs.length > 0 && (
					<React.Fragment>
						<hr />
						<div className="selected-header">
							<span className="label">Selected Variables</span>
						</div>
						<div className="selected-attrs">
							{selectedAttrs.map(item => {
								return(
									<div
										className="result-item single-result-item"
										onClick={() => this.deselectAttribute(item)}
									>
										<span className="attr">
											{item.name}
										</span>
										<span>
											<i className="fa fa-minus"/>
										</span>
									</div>
								)
							})}
						</div>
					</React.Fragment>
				)}
			</div>
		);

    return (
      <div className="create-custom-selector">
				<div
					className="add-attribute"
					id="selector-area"
					ref={this.addAttributeWrapper}
				>
					<Dropdown
						overlay={menu}
						visible={showResults}
						overlayClassName="create-selector-dropdown"
						getPopupContainer={() => document.getElementById('selector-area')}
					>
						<div className="search-input">
							<Input
								placeholder="Which variables are important to you?"
								prefix={prefixIcon && <SearchOutlined />}
								suffix={suffixIcon && <SearchOutlined />}
								allowClear={true}
								onChange={this.searchAttributes}
							/>
						</div>
					</Dropdown>
	      </div>
	    </div>
    )
  }
}

const mapStateToProps = state => {
  return {
		customCalculatorAttrs: state.weights.customCalculatorAttrs,
  }
}

const mapDispatchToProps = {
	setCustomCalcScoreAttrs,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSelector)

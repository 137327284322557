import React from 'react'
import { connect } from 'react-redux'
import {
  Alert,
  Col,
  Collapse,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Button,
  Input
} from 'reactstrap'
import { PrimaryButton } from 'shared-components'
import {  failedUnsubscribeUser } from 'actions/profile'

class SubscriptionCancellationFailure extends React.Component {
  constructor(props) {
    super(props)
  }

  componentWillUnmount(){
    // Clear Unsubscription failure error for the user to try again
    this.props.failedUnsubscribeUser();
  }

  render() {
    return (
      <React.Fragment>
        <div className="premium-feature-process-modal-container">
          <div className="header-wrapper">
            <h1 className="text-align-left premium-feature-main-heading">Cancellation request failed</h1>
          </div>
          <i className="fal fa-times-circle premium-feature-completed-close close-light" onClick={() => {this.props.changeUnsubscribeModalStatus(false)}}></i>
          <div className="pcm__description">
            <p>Some error occured. Please try again.</p>
          </div>
          <div className="profile__subscription-button">
            <Container>
              <Row>
                <Col xs={12}>
                  <FormGroup className="pcm__button-group">
                    <Button color="tools"
                      className="btn h3 text-uppercase font-weight-bold p-3 mb-0 ml-auto primary-btn"
                      onClick={() => {this.props.changeUnsubscribeModalStatus(false)}}
                    >
                      OK
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </React.Fragment>
    )
  }
}



const mapDispatchToProps = {
  failedUnsubscribeUser
}

export default connect(
  null,
  mapDispatchToProps
)(SubscriptionCancellationFailure);

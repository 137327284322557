import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Row, Col, Slider, InputNumber } from 'antd';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { addEventToAnalytics } from 'Utils';

import { createLoadingSelector } from 'selectors/loading-selector';
import { setBuildPortfolioState } from 'actions/buildportfolio';

import { DonutChart, ChartLegend } from '../../../../Elements/Charts/ChartHelper';

let donuChartWidth = 140;
let donuChartHeight = 140;

class SliderChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabHeaderMenu: props.tabHeaderMenu,
      oldHeaderMenuState: [],
      label: ''
    };
  }

  componentDidMount() {
    this.calculateSliderValue();
    const { buildportfolio } = this.props;
    this.setState({
      oldHeaderMenuState: JSON.parse(JSON.stringify(buildportfolio.tabHeaderMenu))
    });
  }

  componentDidUpdate(prevProps) {
    this.chartSize();
    if(prevProps.tabHeaderMenu !== this.props.tabHeaderMenu){
      // console.log(this.props.tabHeaderMenu);
      this.setState({tabHeaderMenu: this.props.tabHeaderMenu});
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.tabHeaderMenu && newProps.tabHeaderMenu !== this.state.tabHeaderMenu) {
      this.setState({tabHeaderMenu: newProps.tabHeaderMenu});
    }
  }

  chartSize = () => {
    const chartBox = document.getElementsByClassName("responsive-donut-chart");
    const chartWidthPercent = 80;
    let list=[];
    if (chartBox && chartBox.length) {
      for (var j = 0; j < chartBox.length; j++) {
        if (chartBox[j]) {
          let donutCharts = chartBox[j].getElementsByClassName("donut-chart-container");
          if (donutCharts && donutCharts.length) {
            for (var i = 0, len = donutCharts.length; i < len; i++) {
              const parentWidth = chartBox[j].offsetWidth;
              const currentWidth = donutCharts[i].offsetWidth;
              donuChartWidth = parentWidth*chartWidthPercent/100;
              donuChartHeight = parentWidth*chartWidthPercent/100;
              // const diff = this.difference(parentWidth, chartWidth);
              // console.log(diff);

              //check minimum width
              if (donuChartWidth < 140) {
                donuChartWidth = 140;
                donuChartHeight = 140;
              }
              //check maximum width
              if (donuChartWidth > 250) {
                donuChartWidth = 250;
                donuChartHeight = 250;
              }

              if (parentWidth < 320 && donuChartWidth > 200) {
                donuChartWidth = 200;
                donuChartHeight = 200;
              }
            }
          }
        }
      }
    }
  }

  difference = (a, b) => (Math.abs(a - b))

  getTabHeaderDeign = (data, maxSliderValue, index, length, zeroLength, currentSelectedFunds, totalSelectedFunds, tabChart, label11) => {
    let oneSliderOnly = true ;
    // console.log('ankit value', maxSliderValue, currentSelectedFunds, totalSelectedFunds)
    
    if(totalSelectedFunds == 1 && currentSelectedFunds > 0){
      oneSliderOnly = true
      // if(!tabChart) console.log(label11, 'if')
    }
    else if(totalSelectedFunds > 1 && currentSelectedFunds > 0){
      if(maxSliderValue != null && length - zeroLength == 1 && data.sliderValue == maxSliderValue){
        oneSliderOnly = true;
        // if(!tabChart) console.log(label11, 'else if2')
      }
      else if(maxSliderValue != null && maxSliderValue == 0){
        oneSliderOnly = true;
        // if(!tabChart) console.log(label11, 'else if1')
      }
      else if(!maxSliderValue && data.sliderValue == 100) {
        oneSliderOnly = true;
        // if(!tabChart) console.log(label11, 'else if4', totalSelectedFunds, currentSelectedFunds,  length, zeroLength, maxSliderValue, data.sliderValue)
      }
      else{
        oneSliderOnly = false
        // if(!tabChart) console.log(label11, 'else if3', totalSelectedFunds, currentSelectedFunds,  length, zeroLength, maxSliderValue, data.sliderValue)
      }
    }
    else{
      oneSliderOnly = true
      // if(!tabChart) console.log(label11, 'else')
    }

    const {
      label,
      locked,
      showInput,
      sliderEnabled,
      sliderValue,
      sliderTooltipEnabled,
      mode = 'default',
      inputNumberDisabled = false,
    } = data;

    const inputOnChange = value => {
      if(locked)
        return null;
      sliderOnChange(value)
    }

    const formatter = (value) => {
      return `${value}%`;
    }

    const lockChange = (lockState) => {
      if (lockState !== null && this.lockChangeCallback) {
        this.lockChangeCallback({
          ...data,
          locked: !locked,
        });
      }
    }

    const onAfterChange = (value) => {
      if (this.onAfterChange) {
        // console.log(value);
        this.onAfterChange({
          ...data,
          sliderValue: value,
        });
      }
    }

    const sliderOnChange = (value) => {
      this.sliderOnChange({
        ...data,
        sliderValue: value,
      });
    }

    // console.log('chart sliderValue', label, sliderValue);

    return (
      <div key={`i-${index}`} className="calc-slider-container result-section-calc-slider-wrapper">
        <Row className={`label-wrapper`}>
          <Col span={20} className="label-holder">
            {label}
          </Col>
          <Col span={4} className="icon-holder">
            {locked ?
              (
                <LockOutlined className="lock" style={{ marginRight: 2 }} onClick={lockChange} />
              ) : (
                <UnlockOutlined className="un-lock" style={{ marginRight: 2 }} onClick={lockChange} />
              )
            }
          </Col>
        </Row>
        <Row className={`slider-wrapper`}>
          <Col span={20}>
            <Slider
              className={`slider-element`}
              min={0}
              max={maxSliderValue ? maxSliderValue : 100}
              // disabled={sliderEnabled || sliderValue == 0 || oneSliderOnly}
              disabled={sliderEnabled || oneSliderOnly}
              tipFormatter={sliderTooltipEnabled ? formatter : null}
              onChange={sliderOnChange}
              onAfterChange={() => this.onAfterChange()}
              value={sliderValue}
            />
          </Col>
          <Col span={4}>
            <InputNumber
              className={`input-number-element`}
              disabled={locked || sliderValue === 0 || true}
              min={0}
              max={maxSliderValue ? maxSliderValue : 100}
              style={{ marginLeft: 16 }}
              value={sliderValue}
              onChange={inputOnChange}
            />
          </Col>
        </Row>
      </div>
    )
  }

  sliderOnChange = (props) => {
    const { activeTab, buildportfolio, oldData } = this.props;
    const oldBuildportfolioState = JSON.parse(JSON.stringify(buildportfolio));
    const oldHeaderMenuState = JSON.parse(JSON.stringify(oldData));
    
    const oldEquityTab = JSON.parse(JSON.stringify(buildportfolio.equityTab));
    const oldBondTab = JSON.parse(JSON.stringify(buildportfolio.bondTab));
    const oldThematicTab = JSON.parse(JSON.stringify(buildportfolio.thematicTab));
    
    const oldEquityData = JSON.parse(JSON.stringify(buildportfolio.equityTab.data));
    const oldThematicData = JSON.parse(JSON.stringify(buildportfolio.thematicTab.data));
    const oldBondData = JSON.parse(JSON.stringify(buildportfolio.bondTab.data));

    if(!this.state.locked){
      this.setState({
        oldEquityData,
        oldBondData,
        oldThematicData,
        oldBuildportfolioState,
        oldHeaderMenuState,
        oldEquityTab,
        oldBondTab,
        oldThematicTab,
        locked:true,
      }, () => {
        
      });
    }

    let sumLockedHeaders = 0.0;
    const currentTab = this.state.tabHeaderMenu.find((item) => item.key === props.key);
    if (props.sliderValue != null && typeof props.sliderValue != 'undefined' && props.label && typeof props.label != 'undefined' && props.sliderValue != currentTab.sliderValue) {
      this.state.tabHeaderMenu.forEach((item) => {
        if(item.locked == true)
          sumLockedHeaders += item.sliderValue;
      });

      this.state.tabHeaderMenu.forEach((item) => {
        if (item.label === props.label) {
          item.sliderValue = props.sliderValue > (100-sumLockedHeaders) ? (100-sumLockedHeaders) : props.sliderValue;
        }
      });
      this.setState({ tabHeaderMenu : this.state.tabHeaderMenu, label: props.label }, () => {
        this.calculateSliderValue(props.label, props.sliderValue)
      });
    }
  }

  calculateSliderValue = (label, value) => {
    //filter the header reqquired for slider wt calc after the change
    //it may be less than the total in case of manual slider chnage
    //or it may be all if the components loads for the first time or weightingMethodology chnage

    let { weightingMethodology, setBuildPortfolioState, tabChart, buildportfolio, activeTab } = this.props;
    let totalSum = 0.0, filterObjsTotalSum = 0.0, oldFilterObjsTotalSum = 0.0, diff = 0.0;
    let filterObjs = [], unfilterObjs = [], oldFilterState = [];
    const topTab = buildportfolio.tabHeaderMenu.find((item) => item.key === activeTab);
    //delete this code after testing the equal and manual mode
    // if(weightingMethodology == 'Equal') {
    //   filterObjs = JSON.parse(JSON.stringify(this.state.tabHeaderMenu));
    //   filterObjs.forEach((item,index) => {filterObjs[index].sliderValue = 1});
    //   console.log('sid eqaul', filterObjs)
    // }
    // else {
      this.state.tabHeaderMenu.forEach((e,i) => {
        let item = JSON.parse(JSON.stringify(e));
        // if(weightingMethodology == 'Equal') item.sliderValue = 1
        totalSum += item.sliderValue;
        if((item.label != label && item.locked == false)) { //filter condition
          filterObjsTotalSum += item.sliderValue;
          filterObjs.push(item);
          //old for restarting the slider ratio after reaching zero
          if(tabChart){
            //usa dm em
            oldFilterObjsTotalSum += buildportfolio[activeTab].data[i].sliderValue;
            oldFilterState.push(buildportfolio[activeTab].data[i])
          }
          else{
            //top tab header -- equity bonds theme
            oldFilterObjsTotalSum += buildportfolio.tabHeaderMenu[i].sliderValue;
            oldFilterState.push(buildportfolio.tabHeaderMenu[i])
          }
        }
        else {
          unfilterObjs.push(item);
        }
      });
    // }
    
    if(filterObjs.length>0) {
      diff = tabChart? topTab.sliderValue - totalSum : 100 - totalSum;
      filterObjs.forEach((obj, index) => {
        // let ratio = obj.sliderValue == 0 ? 0 : obj.sliderValue / filterObjsTotalSum;
        let ratio = obj.sliderValue / filterObjsTotalSum;
        if(isNaN(ratio) || (oldFilterState[index] && oldFilterObjsTotalSum && oldFilterState[index].sliderValue != obj.sliderValue)){
          if(oldFilterState[index] && oldFilterObjsTotalSum){
            ratio = oldFilterState[index].sliderValue / oldFilterObjsTotalSum;
          }
          else
            ratio = 0;
        }
        let wt = diff * ratio;
        // filterObjs[index].sliderValue = Math.round(obj.sliderValue + wt);
        filterObjs[index].sliderValue = obj.sliderValue + wt;
      });

      filterObjs = _.sortBy(filterObjs.concat(unfilterObjs),['position']);

      let updateObj = {tabHeaderMenu : filterObjs}
      if(tabChart){
        let tab = JSON.parse(JSON.stringify(buildportfolio[activeTab]));
        tab.data = filterObjs;
        updateObj = {};
        updateObj[buildportfolio.activeTab] = tab;
      }
      this.setState({ tabHeaderMenu: filterObjs, updateObj }, () => {
        // console.log(updateObj);
        // if (setBuildPortfolioState) {
        //   setBuildPortfolioState(updateObj)
        // }
        // this.onAfterChange(this.state.updateObj);
      });
    }
  }

  onAfterChange = () => {
    const { setBuildPortfolioState, activeTab, buildportfolio } = this.props;
    // if (setBuildPortfolioState) {
    //   setBuildPortfolioState({
    //     oldBuildportfolioState: undefined,
    //   });
    // }
    // const oldBuildportfolioState = JSON.parse(JSON.stringify(buildportfolio));
    // const oldHeaderMenuState = JSON.parse(JSON.stringify(buildportfolio.tabHeaderMenu));
    // const oldEquityData = JSON.parse(JSON.stringify(buildportfolio.equityTab.data));
    // const oldThematicData = JSON.parse(JSON.stringify(buildportfolio.thematicTab.data));
    // const oldBondData = JSON.parse(JSON.stringify(buildportfolio.bondTab.data));
    const {
      oldEquityData,
      oldBondData,
      oldThematicData,
      oldBuildportfolioState,
      oldHeaderMenuState,
      oldEquityTab,
      oldBondTab,
      oldThematicTab,
    } = this.state;
    addEventToAnalytics('Builder Slider','Builder Slider','BUILDER_SLIDER',{ slider: this.state.label },false);
    // console.log('oldHeaderMenuState', oldHeaderMenuState);

    if (setBuildPortfolioState && this.state.updateObj) {
      // console.log(this.state.updateObj);
      const { tabHeaderMenu } = this.state.updateObj;

      if (tabHeaderMenu) {
        // equityTab, bondtab and thematicTab --> slider selected value will be applied here
        const newObject = {
          ...buildportfolio[`${activeTab}`],
        };

        // const selectedFunds = buildportfolio[`${activeTab}`].selectedFunds;
        // const activeTabData = buildportfolio[`${activeTab}`].data;

        // equityTab, bondtab and thematicTab --> slider selected value will be applied here
        // tabHeaderMenu.map((item) => {
        //   if (item.key === activeTab) {
        //     if (Object.keys(selectedFunds) && Object.keys(selectedFunds).length > 0) {
        //       const getActiveFunds = Object.keys(selectedFunds).filter((ty) => selectedFunds[ty].length > 0);
        //
        //       // console.log(getActiveFunds);
        //
        //       if (getActiveFunds.length > 0) {
        //         getActiveFunds.map((rt) => {
        //           if (rt) {
        //             const fd = activeTabData.find((er) => er.query === rt);
        //             // console.log(fd.query);
        //             if (fd) {
        //               //USA sliderValue
        //               // console.log(`${fd.query} sliderValue`);
        //               // activeTabData.find((er) => er.query === rt).sliderValue = tabHeaderMenu.find((item) => item.key === activeTab).sliderValue/getActiveFunds.length;
        //
        //               // USA selectedFunds sliderValue
        //               // console.log(`${fd.query} selectedFunds sliderValue`);
        //               // console.log(rt, selectedFunds[rt]);
        //               if (selectedFunds[rt] && selectedFunds[rt].length > 0) {
        //                 selectedFunds[rt].map((o) => {
        //                   if (o) {
        //                     o._sliderValue = activeTabData.find((er) => er.query === rt).sliderValue/selectedFunds[rt].length;
        //                     // console.log(o._sliderValue);
        //                   }
        //                 })
        //               }
        //               //USA  fund list sliderValue
        //               // console.log(`${fd.query} fund list sliderValue`);
        //               activeTabData.map((l) => {
        //                 if (l.query === rt) {
        //                   l.result.funds.map((w) => {
        //                     w._sliderValue = 0;
        //                     // const found = getActiveFunds.find((o) => o.ticker === w.ticker);
        //                     if (rt.ticker === w.ticker) {
        //                       // console.log(rt, w.ticker);
        //                       w._sliderValue = l.sliderValue/selectedFunds[rt].length;
        //                     }
        //                   });
        //                 }
        //               });
        //
        //             }
        //           }
        //         });
        //
        //       }
        //     }
        //   }
        // })

        // newObject.selectedFunds = selectedFunds;
        // newObject.data = activeTabData;

        this.setState({locked: false}, () => {
          setBuildPortfolioState({
            ...this.state.updateObj,
            oldHeaderMenuState: oldHeaderMenuState, //buildportfolio.tabHeaderMenu,
            [`${activeTab}`]: newObject,
            oldEquityData,
            oldBondData,
            oldThematicData,
            oldEquityTab,
            oldBondTab,
            oldThematicTab,
          });
        });

      } else {
        // based on activeTab ->>  [US, DM, EM] --> ratio-wise weight allocate

        // console.log(this.state.updateObj[`${activeTab}`]);
        // console.log(oldBuildportfolioState);

        const newObject = {
          ...this.state.updateObj[`${activeTab}`],
        };

        // console.log(newObject);

        const selectedFunds = this.state.updateObj[`${activeTab}`].selectedFunds;
        const activeTabData = this.state.updateObj[`${activeTab}`].data;
        const activeOldTab = activeTab == 'equityTab' ? oldEquityTab : activeTab == 'bondTab' ? oldBondTab : oldThematicTab;
        // console.log(activeTabData);

        if (Object.keys(selectedFunds) && Object.keys(selectedFunds).length > 0) {
          const getActiveFunds = Object.keys(selectedFunds).filter((ty) => (selectedFunds[ty] && selectedFunds[ty].length > 0));
          // console.log(getActiveFunds);
          if (getActiveFunds.length > 0) {
            getActiveFunds.map((rt) => {
              if (rt) {
                // USA selectedFunds sliderValue
                // console.log(`${fd.query} selectedFunds sliderValue`);
                // console.log(rt, selectedFunds[rt]);
                if (selectedFunds[rt] && selectedFunds[rt].length > 0) {
                  selectedFunds[rt].map((o) => {
                    if (o) {
                      const oldFound = activeOldTab.selectedFunds[rt].find(x => x.ticker == o.ticker);
                      if(selectedFunds[rt].length == 1 ){
                        o._sliderValue = activeTabData.find((er) => er.query === rt).sliderValue/selectedFunds[rt].length;
                        // console.log(o._sliderValue);
                      }
                      else if(selectedFunds[rt].length > 1 && oldFound && oldFound._sliderValue > 0) {
                        if(oldFound) {
                          let diff = activeTabData.find((er) => er.query === rt).sliderValue - activeOldTab.data.find((er) => er.query === rt).sliderValue;
                          let ratio = oldFound._sliderValue / activeOldTab.data.find((er) => er.query === rt).sliderValue;
                          let wt = diff * ratio;
                          o._sliderValue = oldFound._sliderValue + wt;
                          // console.log('pratik 121', oldFound, diff, ratio);
                        }
                      }
                      else if(selectedFunds[rt].length > 1 && oldFound && oldFound._sliderValue <= 0) {
                        o._sliderValue = activeTabData.find((er) => er.query === rt).sliderValue/selectedFunds[rt].length;
                        // console.log(o._sliderValue);
                      }
                    }
                  })
                }
                //USA  fund list sliderValue
                // console.log(`${fd.query} fund list sliderValue`);
                activeTabData.map((l) => {
                  if (l.query === rt) {
                    l.result.funds.map((w) => {
                      w._sliderValue = 0;
                      if (selectedFunds[l.query] && typeof selectedFunds[l.query] !== 'undefined') {
                        // const found = getActiveFunds.find((o) => o.ticker === w.ticker);
                        const oldFound = activeOldTab.selectedFunds[l.query].find(x => x.ticker == w.ticker);
                        if (rt.ticker === w.ticker) {
                          // console.log(rt, w.ticker);
                          // w._sliderValue = l.sliderValue/selectedFunds[rt].length;
                          if(selectedFunds[l.query].length == 1){
                            // console.log('pratik 15.01', w.ticker)
                            w._sliderValue = l.sliderValue/selectedFunds[l.query].length;
                          }
                          else if(selectedFunds[l.query].length > 1 && oldFound && oldFound._sliderValue > 0) {
                            // console.log('pratik 15.02', w.ticker, l.query)
                            if(oldFound){
                              // console.log('pratik 15.03', w.ticker)
                              let diff = l.sliderValue - activeOldTab.data.find((er) => er.query === l.query).sliderValue;
                              let ratio = oldFound._sliderValue / activeOldTab.data.find((er) => er.query === l.query).sliderValue;
                              let wt = diff * ratio;
                              w._sliderValue = oldFound._sliderValue + wt;
                              // console.log('pratik 151', oldFound, diff, ratio);
                            }
                          }
                          else if(selectedFunds[l.query].length > 1 && oldFound && oldFound._sliderValue <= 0 ) {
                            // console.log('pratik 15.02', w.ticker, l.query)
                            w._sliderValue = l.sliderValue/selectedFunds[l.query].length;
                          }
                        }
                      }
                    });
                  }
                });
              }
            });
          }
        }

        newObject.selectedFunds = selectedFunds;
        newObject.data = activeTabData;

        this.setState({locked: false}, () => {
          setBuildPortfolioState({
            [`${activeTab}`]: newObject,
            oldHeaderMenuState,
            oldEquityData,
            oldBondData,
            oldThematicData,
          });
        })
      }

    }
  }

  lockChangeCallback = (props) => {
    if (typeof props.locked !== 'undefined' && props.label) {
      this.state.tabHeaderMenu.forEach((item) => {
        if (item.label === props.label) {
          item.locked = props.locked;
          item.sliderEnabled = props.locked;
        }
      });
      this.setState({ tabHeaderMenu : this.state.tabHeaderMenu });
    }
  }

  render() {
    const { data, dataKey, tabHeaderMenu, bigScreen, labelFor, disableControls, className, buildportfolio, activeTab, tabChart } = this.props;
    const { calculatedTabHeader } = buildportfolio;
    const topTab = buildportfolio.tabHeaderMenu.find((item) => item.key === activeTab);
    // const { tabHeaderMenu } = this.state;
    // chartData is tabHeaderMenu comes form BuildFromScratch->index.js
    const tabHeaderMenuCopy = JSON.parse(JSON.stringify(tabHeaderMenu));

    if (disableControls) {
      tabHeaderMenuCopy.map((q) => {
        q.sliderValue = parseInt(calculatedTabHeader[q.key].toFixed(2));
      })
    }

    this.chartSize();

    // console.log(this.state.tabHeaderMenu);
    // console.log(this.props.tabHeaderMenu);

    const isAllSliderValueZero = this.state.tabHeaderMenu.filter(e => e.sliderValue === 0);
    // console.log(this.state.oldBuildportfolioState);
    let haveTopTotalTabSelectedFunds = 0, haveBottomTotalTabSelectedFunds = 0, haveTabSelectedFunds={};
    this.state.tabHeaderMenu.map(e => {
      if(tabChart){
        let funds = buildportfolio[activeTab].selectedFunds;
        if (Object.keys(funds).length > 0){
          haveBottomTotalTabSelectedFunds += 1;
          Object.keys(funds).forEach(ee => {
            if(ee == e.label && funds[ee].length > 0 ) {
              if(!haveTabSelectedFunds[e.label]) haveTabSelectedFunds[e.label] = 0;
              haveTabSelectedFunds[e.label] +=1;
            }
          })
        }
      }
      else {
        let funds = buildportfolio[e.key].selectedFunds;
        if (Object.keys(funds).length > 0){
          haveTopTotalTabSelectedFunds += 1;
          Object.keys(funds).forEach(ee => {
            // console.log('ankit test', funds[ee])
            if(funds[ee].length > 0 ) {
              if(!haveTabSelectedFunds[e.key]) haveTabSelectedFunds[e.key] = 0;
              haveTabSelectedFunds[e.key] +=1;
            }
          })
        }
      }
    })
    // if(tabChart) console.log('ankit hastab', haveTabSelectedFunds, haveTopTotalTabSelectedFunds)

    return (
      <Row className="result-chart-stats">
        <Col className={`chart-wrap ${disableControls ? 'single-chart-center-align' : ''} ${className}`} span={!disableControls ? 12 : 24}>
          <DonutChart
            labelFor={labelFor}
            dataKey={dataKey}
            noData={isAllSliderValueZero.length === this.state.tabHeaderMenu.length}
            width={donuChartWidth || 140}
            height={donuChartHeight || 140}
            data={disableControls ? tabHeaderMenuCopy : this.state.tabHeaderMenu}
          />
        </Col>
        {(!disableControls) && (
          <Col className="chart-legends-wrapper" span={12} style={buildportfolio.thematicTab.data.length < 3 ? {height: 'none'} : {height: '200px'}}>
            {this.state.tabHeaderMenu.map((e, i) => {
              if (e.label.indexOf('Global') === -1) {
                // return this.getTabHeaderDeign(e, tabChart ? topTab.sliderValue : null, i, this.state.tabHeaderMenu.length)
                // let haveTabSelectedFunds = 0;
                // if(tabChart){
                //   let funds = buildportfolio[activeTab].selectedFunds;
                //   if (Object.keys(funds).length > 0){
                //     Object.keys(funds).forEach(ee => {
                //       if(ee == e.label && funds[ee].length > 0 ) {
                //         haveTabSelectedFunds += 1;
                //       }
                //     })
                //   }
                //   if(haveTabSelectedFunds > 0 && topTab.sliderValue == 0){
                //     haveTabSelectedFunds = 0; //disable bottom because up slider is zero
                //   }
                // }
                // else {
                //   let funds = buildportfolio[e.key].selectedFunds;
                //   if (Object.keys(funds).length > 0){
                //     Object.keys(funds).forEach(ee => {
                //       if(funds[ee].length > 0 ) {
                //         haveTabSelectedFunds += 1;
                //       }
                //     })
                //   }
                // }
                
                return this.getTabHeaderDeign(e, tabChart ? topTab.sliderValue : null, i, this.state.tabHeaderMenu.length, isAllSliderValueZero.length, tabChart ? haveTabSelectedFunds[e.label] : haveTabSelectedFunds[e.key], tabChart ? haveBottomTotalTabSelectedFunds: haveTopTotalTabSelectedFunds, tabChart, tabChart?e.label:e.key)
              };
            })}
          </Col>
        )}
      </Row>
    )
  }
}

const mapStateToProps = ({ buildportfolio, loading, global }) => ({
  buildportfolio,
  bigScreen: global.bigScreen,
  loading: createLoadingSelector([''])({ loading }),
})

const mapDispatchToProps = {
  setBuildPortfolioState
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SliderChart);

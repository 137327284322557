import React, { Component } from 'react';
import { logout } from '../../../actions/auth';
import { connect } from 'react-redux'

class SignOut extends Component {

    componentWillMount(){
        this.props.logout();
    }
}


const mapStateToProps = ({ auth }) => ({
    
  })
  
  const mapDispatchToProps = {
    logout,
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignOut)

import React, { Component, Fragment } from 'react';
import { Upload, message, Button, Form } from 'antd';
import moment from 'moment';

import { connect } from 'react-redux';
import { FormGroup, UncontrolledTooltip, Tooltip } from 'reactstrap'
import { uploadPortfolio  } from 'actions/investment';
import { setEnhancerState } from 'actions/enhancer';

import {
  UploadOutlined,
} from '@ant-design/icons';
import { addEventToAnalytics } from 'Utils';

class UploadFile extends Component {
  UploadPortfolio = { name: 'Portfolio', code: 'uplPrt' }

  constructor(props){
    super(props);
    this.state = {
      nickName: '',
      filesList: [],
      fileUpload: false,
      uploadError: false,
      uploadInputError: false,
      multipleUploadError: false,
      labelAlreadyExists: false,
      nameInvalid: false
    }
    this.file = new FormData();
  }

  setNickName = (e) => {
    if(e.target.value.length > 50){
      this.setState({ nameInvalid: true })
    } else {
      this.setState({ nameInvalid: false })
    }
    const name = document.getElementById('file-name-box').value;
    this.setState({ nickName : name })
    this.checkLabelExists(name);
  }

  getFileName = (e) => {
    this.props.setEnhancerState({fileName: this.state.nickName})
  }

  checkLabelExists = (name) => {
    if(name){
      const checkFileExists = this.props.portfolioList.find(a => a.portfolio_name == name);
      this.setState({
        labelAlreadyExists: checkFileExists ? true : false
      })
    }else{
      this.setState({
        labelAlreadyExists: false
      })
    }
  }

  submitPortfolio = (file) => {
    if(this.state.nameInvalid){
      return
    }
    const { fileUpload, filesList } = this.state;
    const { uploadfileDuplicate, getCSVData } = this.props;
    const name = document.getElementById('file-name-box').value // || `Nolabel_${moment(new Date()).format("DDMMMYYYY_hh:mm:ss")}`;
    this.file.append('file', file);
    if (uploadfileDuplicate) {
      this.file.append('name', name);
      this.setState({ fileUpload: false, uploadInputError: false, uploadError: false, }, () => {
        if (getCSVData) {
          getCSVData(file, this.state.filesList);
        }
      })
    } else if (!fileUpload) {
      this.setState({ uploadError: true, uploadInputError: false  })
    } else {
      this.file.append('name', name);
      this.setState({ fileUpload: false, filesList: [file], uploadInputError: false, uploadError: false, }, () => {
        if (getCSVData) {
          getCSVData(file, this.state.filesList);
        }
      })
    }
  }

  render() {
    const { uploadInputError, uploadError, multipleUploadError, labelAlreadyExists, nameInvalid } = this.state;
    const { uploadfileDuplicate, uploadfileIncomplete, uploadFileFailure, uploadFileFailureMsg, uploadFilePartial, csvFormatErrorMessage } = this.props;
    const props = {
      name: 'file',
      accept: '.csv, .txt, .xlsx, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
      multiple: false,
      showUploadList: false,
      beforeUpload: (file) => {
        let fileExt = file.name.split('.');
        fileExt = fileExt[fileExt.length - 1];
        if (fileExt !== 'csv' && fileExt !== 'txt' && fileExt !== 'xlsx') {
          this.setState({ uploadError: true, fileUpload: false, filesList: [] })
          return false
        }
      },
      onRemove: () => {
        this.setState({ fileUpload: false, filesList: [] })
      },
      customRequest: ({ file }) => {
        const { filesList } = this.state;
        if (Object.keys(file).length > 0) {
          this.setState({ filesList: [file], uploadError: false, multipleUploadError: false, fileUpload: true }, () => this.submitPortfolio(file));
        } else if (filesList.length === 1) {
          this.setState({ multipleUploadError: true })
        } else this.setState({ uploadError: true })
      },
    };

    return (
      <React.Fragment>
        <form className="upload-form-content">
          <FormGroup className="element-box pos-rel">
            <label>Label</label>
            <input
              id="file-name-box"
              className="input-area"
              type="text"
              autoComplete="off"
              style={{ width: '100%' }}
              onBlur={this.getFileName}
              onChange={this.setNickName}
            />
            {uploadInputError && <span className="upload-error">Please enter a label to upload</span>}
            {nameInvalid && <span className="upload-error">Maximum 50 character allowed</span>}
            {labelAlreadyExists && <span className="upload-error">A portfolio with the same name already
             exists for your account. Please choose another name to upload the portfolio!</span>}
            {!uploadInputError && uploadfileDuplicate && <span className="upload-error">Label already exists</span>}
          </FormGroup>
          <div className="col-12 upload-btn element-box px-0">
            <Upload
              className="upload-container"
              {...props}
              fileList={this.state.filesList}
            >
              <Button
                disabled={labelAlreadyExists}
                onClick={() => {
                  addEventToAnalytics(
                    'Add Single Client',
                    'Add Single Client',
                    'ADD_SINGLE_CLIENT',
                    {},
                    false
                  )
                }}
              >
                Add Single Client
              </Button>
              <Fragment>
                <div className="arrow-up"></div>
                <span className="upld-cont-info">
                  Please enter 'Label' to upload portfolio
                </span>
              </Fragment>
            </Upload>
            <span id="upload-tooltip" style={{ marginLeft: 24 }}><i className="fal fa-info-circle"></i></span>
            <span className="sample-file"><a onClick={(e)=>{addEventToAnalytics('Enhancer Demo Download','Enhancer Demo Download','ENHANCER_DEMO_DOWNLOAD',{},false);}} href="/assets/data/single_client.csv" download="Portfolio.csv" target="_blank" >Download sample</a></span>
            <UncontrolledTooltip className="upl-tool-info" placement="right" target="upload-tooltip">
              Please upload a .csv file with the following columns – <br /><br /> Mandatory :<br /><br />1) Ticker – The symbol used on a trading exchange<br />2) Shares – Quantity held
              {/* <br /><br />Either one of the below : <br /><br />3) weight - the weight of the ticker in the portfolio in percentage<br />4) amount - the total amount invested in the ticker<br /><br /> Optional :<br /><br />5) price – The price at which the security was purchased<br />6) date / lot date – date on which the security was purchased */}
            </UncontrolledTooltip>
          </div>
          <div className="col-12 element-box upload-btn p-0">
            {uploadError && <span className="upload-error">Please upload a csv or txt file</span>}
            {uploadfileIncomplete && <span className="upload-error">Please fill all required fields in the file</span>}
            {multipleUploadError && <span className="upload-error">Please upload only one file</span>}
            {uploadFileFailure && <span className="upload-error">{uploadFileFailureMsg}</span>}
            {csvFormatErrorMessage && csvFormatErrorMessage !== '' && <span className="upload-error">{csvFormatErrorMessage}</span>}
          </div>
        </form>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ enhancer }) => ({
  uploadfileDuplicate: enhancer.uploadfileDuplicate,
  uploadfileIncomplete: enhancer.uploadfileIncomplete,
  uploadFileFailure: enhancer.uploadFileFailure,
  uploadFileFailureMsg: enhancer.uploadFileFailureMsg,
  uploadFilePartial: enhancer.uploadFilePartial,
  uploadFilePartialMsg: enhancer.uploadFilePartialMsg,
  portfolioList: enhancer.portfolioList
})

const mapDispatchToProps = {
  uploadPortfolio,
  setEnhancerState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadFile)

import React, { Component } from 'react';
import { addEventToAnalytics } from 'Utils';

class ItemCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 0,
    }
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.selectedBroker = this.selectedBroker.bind(this);
    this.accountHandler = this.accountHandler.bind(this);
  }

  componentDidMount() {
    this.selectedBroker(0, () => {
      if (this.state.activeKey !== 0) {
        this.setState({ activeKey: 0 });
      }
    });
  }

  next() {
    const { itemArray, handleActiveIndexKey } = this.props;
    const nextIndex = this.state.activeKey === itemArray.length - 1 ? 0 : this.state.activeKey + 1;
    this.setState({activeKey: nextIndex});
    handleActiveIndexKey(nextIndex);
    this.selectedBroker(nextIndex);
  }

  previous() {
    const { itemArray, handleActiveIndexKey } = this.props;
    const nextIndex = this.state.activeKey === 0 ? itemArray.length - 1 : this.state.activeKey - 1;
    this.setState({activeKey: nextIndex});
    handleActiveIndexKey(nextIndex);
    this.selectedBroker(nextIndex);
  }

  selectedBroker(nextIndex) {
    const { funds } = this.props;
    const { selectedBrokerInfo, itemArray } = this.props;
    const filterArrData = itemArray.filter((e, i) => i === nextIndex);
    if (filterArrData.length) {
      const broker = filterArrData[0];
      selectedBrokerInfo(broker);
      if(funds != undefined) {
        addEventToAnalytics('ACT Broker Change','ACT Broker Change','ACT_BROKER_CHANGE',{ query: funds.query, logId: funds.logId },true);
      }
    }
  }

  accountHandler(broker, account_number, card) {
    const { accountHandler } = this.props;
    accountHandler(broker, account_number, card);
  }

  render() {
    const { itemArray, onChange, isDemoUser, AccountDropDownValue, card, isMutualFund } = this.props;
    const defaultValue = this.state.activeKey || 0
    const prevDisabled = this.state.activeKey === 0 ? true : false;
    const nextDisabled = ((itemArray.length - 1) === this.state.activeKey) ? true : false;
    const filterArrData = itemArray.filter((e, i) => i === defaultValue);

    const renderBodyContent = filterArrData.map((e, index) => {
      return (
        <div key={e.id} name={e.name} status={e.status} className="broker-label-wrapper">
          <div className="broker-label-bg" style={{ backgroundColor: e.color }}>
            {e.name}
          </div>
          <div className="text-center dropDownText">
          { isDemoUser()
            ? <React.Fragment>
                {/*<AccountDropDownValue {...this.props} accounts={this.props.importedAccounts[e.name] ? this.props.importedAccounts[e.name] : []} broker={e} card={card} accountHandler={this.accountHandler} />*/}
              </React.Fragment>
            : <React.Fragment>
              </React.Fragment> }
          </div>
        </div>
      );
    });

    return (
      ['SMA', 'MP'].includes(isMutualFund) ?
          <React.Fragment>
            <div style={{fontSize:'12px',textAlign:'center'}}>
              <span>{isMutualFund == 'MF' ? 'Mutual Funds' : isMutualFund == 'MP' ? 'Model Portfolio' : isMutualFund} Trading </span>
              <br /><span style={{fontWeight:'bold'}}>Coming Soon</span>
            </div>
          </React.Fragment>
         :
      <div className="custom-carousel-container">
        <div className={`carousel-nav-icon ${prevDisabled ? 'disabled' : ''}`} onClick={this.previous}>
          <i className={`fas fa-caret-left previous ${prevDisabled ? 'disabled' : ''}`} />
        </div>
        <div className="custome-carousel-body">
          {renderBodyContent}
        </div>
        <div className={`carousel-nav-icon ${nextDisabled ? 'disabled' : ''}`} onClick={this.next}>
          <i className={`fas fa-caret-right next ${nextDisabled ? 'disabled' : ''}`} />
        </div>
      </div>
    );
  }
}


export default ItemCarousel;

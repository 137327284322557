import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import {
	setEnhancerState,
	resetSummaryDataRequest,
} from 'actions/enhancer';
import { addEventToAnalytics } from 'Utils'

class ContentHead extends Component {
	constructor(props) {
		super(props);
		this.state = {

		}
	}

	routeToOnBoardScreen = () => {
		addEventToAnalytics('Enhancer Edit Home', 'Enhancer Edit Home', 'ENHANCER_EDIT_HOME', {}, false);
		const { history, resetSummaryDataRequest } = this.props;
		if (resetSummaryDataRequest && history) {
			resetSummaryDataRequest('', () => {
				const newPath = `/enhancer/onboard-screen`;
				history.push(newPath);
			})
		}
	}

	drawerToggle = () => {
		const { summaryDrawer, openDrawer } = this.props;
		if (openDrawer) {
			openDrawer(!summaryDrawer);
		}
	}

	render() {
		const { portfolioName } = this.props;
		return (
			<React.Fragment>
				<div id="funds-board-head" className="funds-board-head">
					<Row id="funds-board-content-header" className="pf-content-header m-0">
						<Col span={24} className="d-flex col-wrap">
							<Col span={20} className="enhancer-portfolio-name-container">
								<span style={{ color: '#494949' }}>Enhancement Ideas For </span>
								<span>
									{portfolioName}
								</span>
							</Col>
							<Col span={4} className="d-flex align-items-center" style={{ justifyContent: 'flex-end' }}>
								<Button
									shape="round"
									className="primary-button return-home-button"
									type="primary"
									style={{ padding: 0 }}
									onClick={this.routeToOnBoardScreen}
								>
									<img width="28px" src="/assets/images/reload.svg" />
								</Button>
							</Col>
						</Col>
					</Row>
					<Row className="enhancer-cta-container m-0">
						<Col span={14} className="d-flex"></Col>
						<Col span={10} className="d-flex align-items-center" style={{ justifyContent: 'flex-end' }}>
							<Button className="primary-cta-button" onClick={this.drawerToggle} style={{ paddingRight: 32 }}>PERFORMANCE SUMMARY <RightOutlined /></Button>
						</Col>
					</Row>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ router, loading, enhancer }) => ({
	location: router.location,
	portfolioName: enhancer.portfolioName,
});

const mapDispatchToProps = {
	setEnhancerState,
	resetSummaryDataRequest,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ContentHead);

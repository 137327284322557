import React from 'react'
import { Alert } from 'reactstrap'

export default function ErrorAlert(props) {
  return (
    <Alert color="danger">
      Sorry, looks like something went wrong on our end. Try again later
    </Alert>
  )
}

import React, { Component, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { get } from 'lodash'
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
// import GoogleLogin from 'react-google-login'
import {
  CustomFormFeedback,
  PasswordRequirement,
  Spinner,
  // DatePicker
} from 'shared-components'
import { PrimaryButton } from 'shared-components/'
import {
  areYouAnList,
  investableAssetsList,
  assetUnderManagementList,
  RETAIL_USERS_ROLES,
  ADVISOR_REGISTER_LHS
} from '../Constant/FormData'
// import TermsConditions from './../SearchResults/Elements/Registrations/AgreeTermsNConditions'

// import { Carousel, CarouselItem, CarouselIndicators } from 'reactstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { REDIRECT_DOMAIN_PATH } from 'DataSet'
import { IconButton } from 'shared-components'
import './RegisterFormOpt2.scss'
import '../Trading/TradingForm.scss'
// import moment from 'moment'
import { Regex } from 'common/regex'
import { CheckOutlined } from '@ant-design/icons'
import {
  hasLowerCaseRegex,
  hasUpperCaseRegex,
  hasDigitRegex,
  hasSpecialCharactersRegex,
} from 'Utils';
import config from 'env';
import { Typography } from 'antd';
import ExpandCollapse from 'react-expand-collapse';

const googleLogo = '/assets/images/google@3x.png'
const magnifiLogo = '/assets/images/MagnifiLogo/Magnifi_Tagline_Charcoal_RGB.png'
const magnifiByTiffinLogo = '/assets/images/magnifi_by_tifin.svg'
// const magnifiLogoWhite = '/assets/images/MagnifiLogo/Magnifi_Tagline_White_RGB@2x.png'
const lhsmuchmore = '/assets/images/misc/2-Much-More.png'
const lhsCalculator = '/assets/images/misc/1-Calculator.png'
const lhsEnhance = '/assets/images/misc/2-Enhancement.png'
/*areYouAnOther: Yup.string().when('self_role', {
is: (self_role) => self_role === 'Other/Curious',
then: Yup.string().required('Enter a value'),
otherwise: Yup.string()
}),*/


const LHSMsg = [
  {
    src: lhsCalculator,
    altText: '',
    caption: 'Access calculators to compare funds'
  },
  {
    src: lhsEnhance,
    altText: '',
    caption: 'Build and evaluate changes to your portfolio'
  },
  {
    src: lhsmuchmore,
    altText: '',
    caption: 'And much more...'
  }
]

const form1InitialValues = {
  first_name: '',
  last_name: '',
  // freeTrial: false,
  email: '',
  password: '',
  rePassword: '',
  self_role:
    process.env.REACT_APP_SUB_DOMAIN === 'retail' ? 'Individual Investor' : '',
  investable_assets: '',
  estimated_net_worth: '',
  refree_code: '',
  areYouAnOther: '',
  termsCondition: false,
  // country: '',
  // zipcode: '',
  // state: '',
  // stateInput: '',
  // state_value: '',
  // city: '',
  // address: '',
  // date_of_birth: '',
  // company_name: '',
  // financial_advisor: false
}

const RegisterFormOpt2 = props => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

  const validationSchema1 = Yup.object().shape({
    first_name: Yup.string()
      .required('First name is a required field')
      .matches(Regex.fullName, 'First name is not valid'),
    // company_name: Yup.string()
    //   .required('Company name is a required field'),
    last_name: Yup.string()
      .required('Last name is a required field')
      .matches(Regex.fullName, 'Last name is not valid'),
    email: Yup.string()
      .email()
      .required('Email is a required field'),
    password: Yup.string()
      .required('Password is a required field')
      .min(8, 'Password has to be longer than 8 characters!')
      .matches(
        hasLowerCaseRegex,
        'Password should contain at least one lower case letter',
      )
      .matches(
        hasUpperCaseRegex,
        'Password should contain at least one upper case letter',
      )
      .matches(hasDigitRegex, 'Password should contain at least one number')
      .matches(
        hasSpecialCharactersRegex,
        'Password should contain atleast one special character',
      ),
    rePassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Re-enter password is a required field'),
    self_role: Yup.string().required('Please select your profile'),
    // financial_advisor: Yup.bool().oneOf([true], 'This is required field.'),
    // country: Yup.string().required('Please select a country'),
    // zipcode: Yup.string()
    //   .required('Please enter your zipcode')
    //   .matches(Regex.zipCode, 'zip code is not valid'),
    //age_group:Yup.string(),
    // date_of_birth: Yup.string()
    //   .required('Please select your DOB')
    //   .test(
    //     'is-valid-date',
    //     'Invalid Date',
    //     dob => moment(dob).isValid()
    //   )
    //   .test(
    //     'is-over-18',
    //     'Age must be over 18',
    //     dob => moment().diff(dob, 'years') >= 18
    //   ),
    // state: Yup.string().required('Please enter your state'),
    // city: Yup.string().required('Please enter your city'),
    // address: Yup.string()
    //   .required('This is a required field')
    //   .matches(Regex.pobox, 'Invalid street address')
    //   .max(30, 'Street must be at most 30 characters'),
    investable_assets: Yup.string(),
    estimated_net_worth: Yup.string()
  })

  const handleFormSubmit = (values, { setSubmitting }) => {
    props.nextClick(values, { setSubmitting }, props.plan_selected === 'basic')
  }

  const handleOkClick = () => {
    if (props.handleCalendarOkClick) {
      props.handleCalendarOkClick();
    }
  }

  // {(
  //   <Col
  //     md="6"
  //     lg="6"
  //     className={`right-side-panel-container register-page-height pt-3 ${process.env.REACT_APP_SUB_DOMAIN === 'retail' && 'd-flex justify-content-center'}`}
  //   >
  //     <RegisterFormRHS3 {...props} />
  //   </Col>
  // )}

  return (
    <Row className="magnifi-auth-page-wrapper register-magnifi-opt-2">
      <Col className="left-side-panel-container different_advisor">
        <Row style={{ alignItems: "center", width: '100%' }}>
          <Col sm={12} className="text-center">
            <a className="logo-wrapper mt-1" href={REDIRECT_DOMAIN_PATH} style={{ width: '100%' }}>
              <img style={{ width: '60%' }} src={magnifiByTiffinLogo} height={70} alt="magnifi" />
            </a>
          </Col>
          <Col sm={12}>
            <div className="list_content text-center">
              <span className="span_head">Become an AI-Powered Financial Advisor</span>
              <div style={{ width: '90%', margin: 'auto' }}>
                {ADVISOR_REGISTER_LHS.map((el, index) => {
                  return (
                    <Row className={index === 0 ? "text-left pt-5" : "text-left pt-4"} style={{ maxWidth: '100%' }}>
                      <Col className={'text-right p-0 pr-2'} sm={2}><CheckOutlined style={{ color: '#fff', fontWeight: 'bold', fontSize: '1.4rem' }} /></Col>
                      <Col className={'p-0 pl-3'} sm={10} style={{ display: 'flex', alignItems: 'center' }}><span style={{ color: '#fff', fontSize: '1.1rem', lineHeight: '1.5rem' }}>{el}</span></Col>
                    </Row>
                  )
                })}
              </div>
            </div>
          </Col>
        </Row>
        {/* {!isMobile && (
          <Row style={{ flex: 1 }}>
            <div className="contact-info">
              <p><a href="mailto:support@magnifi.com">support@magnifi.com</a> | Anytime</p>
            </div>
          </Row>
        )} */}
      </Col>
      <Col
        md="6"
        lg="6"
        className="right-side-panel-container register-page-height"
      >
        {(props.step === 1 && !props.loggedIn && !props.showCalendarInviteScreen) && (
          <>
            <Spinner isFetching={props.isFetching} />
            <BackButton clickHandler={props.backHandler} />
            <Formik
              initialValues={form1InitialValues}
              onSubmit={handleFormSubmit}
              validationSchema={validationSchema1}
              //auth_status={this.props.registerSuccess}
              render={formProps => (
                <>
                  <RegisterFormRHS
                    formProps={formProps}
                    //nextClick={props.nextClick}
                    onToggle={props.onToggle}
                    error={props.error}
                    plan_selected={props.plan_selected}
                    onGoogleSignInSuccess={props.onGoogleSignInSuccess}
                    onGoogleSignInError={props.onGoogleSignInError}
                    history={props.history}
                    subscribeNow={props.subscribeNow}
                    createSubscriptionRequest={props.createSubscriptionRequest}
                    disableRegisterButton={props.disableRegisterButton}
                  />
                  {isMobile && (
                    <Row style={{ flex: 1 }}>
                      <div className="contact-info">
                        {/* <p>Call or Text Magnifi Support: <a href="tel:7204455530">(720) 445-5530</a> | 9am - 6pm EST</p> */}
                        <p><a href="mailto:support@magnifi.com">support@magnifi.com</a> | Anytime</p>
                      </div>
                    </Row>
                  )}
                </>
              )}
            />
          </>
        )}
        {props.showCalendarInviteScreen && (
          <div className="magnifi-auth-design-container advisor_different_layout calendar-invite-screen-wrapper" style={{ margin: 'auto' }}>
            <div className="header-area retail_form_container text-center">
              <h1>Your account is ready!</h1>
              <div className="header-label main">Schedule a personalized demo to see Magnifi in action</div>
            </div>
            <div
              class="meetings-iframe-container"
              data-src="https://meetings.hubspot.com/rob-stella/magnifi-for-advisors-30-min-demo?embed=true"
              style={{ minHeight: 640 }}
            ></div>
            <Helmet>
              <script
                type="text/javascript"
                src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
              ></script>
            </Helmet>
            <div className="cta-wrapper registration-btn-wrapper">
              <PrimaryButton
                type="button"
                className="btn primary-btn"
                onClick={handleOkClick}
              >
                Not yet. Take me to my account
              </PrimaryButton>
            </div>
          </div>
        )}
      </Col>
    </Row>
  )
}

export default RegisterFormOpt2

class RegisterFormRHS3 extends Component {
  componentDidMount() {
    document.getElementsByTagName('body')[0].style.paddingTop = 0
  }

  createTradingAccount = () => {
    this.props.history.push('register/trading')
  }

  upgradeToPremium = () => {
    this.props.updatePlanChange(true)
  }

  render() {
    const profile_self_role = window.localStorage.getItem('profile_self_role')
      ? window.localStorage.getItem('profile_self_role')
      : 'loggedOut'
    const selectorText = RETAIL_USERS_ROLES.includes(profile_self_role)
      ? 'Cart'
      : 'Selector'
    if (
      process.env.REACT_APP_SUB_DOMAIN === 'retail' &&
      this.props.plan_selected === 'basic'
    ) {
      return (
        <Form
          name="RegisterFormRHS3"
          className="magnifi-auth-design-container register-magnifi-auth-design"
          style={{ flexheight: process.env.REACT_APP_SUB_DOMAIN === 'retail' ? 'auto' : '100%' }}
          noValidate={true}
        >
          <Tagline />
          <div className="header-area">
            <div className="header-label">
              You are now a registered user at magnifi.com that allows you to
              Discover a new world of investment shopping
            </div>
          </div>
          <div className="cta-wrapper registration-btn-wrapper">
            <PrimaryButton
              type="button"
              className="btn primary-btn"
              onClick={this.createTradingAccount}
            >
              Create Magnifi Investment Account
            </PrimaryButton>
          </div>
          {process.env.REACT_APP_SUB_DOMAIN !== 'retail' && (
            <Fragment>
              <div className="trading-benefits">
                <h1 style={{ paddingTop: `10px` }}>Upgrade to Premium</h1>
                <h2>Benefits Of premium account</h2>
                <ul className="row">
                  <li className="col-lg-6 col-12">
                    <b>{selectorText}:</b> Personalize our patented fund
                    selector to find the best fund for you among relevant funds
                    discovered.
                  </li>
                  <li className="col-lg-6 col-12">
                    <b>Builder:</b> Start with selected funds or initial
                    portfolio to build a portfolio for proposals or trades.
                  </li>
                  <li className="col-lg-6 col-12">
                    <b>Enhancer:</b> Read in client, prospect or sponsor
                    provided portfolios and evaluate enhancements with simple
                    language based queries.
                  </li>
                  <li className="col-lg-6 col-12">
                    <b>Proposals:</b> For clients to help convert and explain;
                    and for Compliance from fund selector, builder and enhancer.{' '}
                  </li>
                  <li className="col-lg-6 col-12">
                    <b>Save:</b> Results and download to spreadsheets.
                  </li>
                </ul>
              </div>
              <div className="cta-wrapper registration-btn-wrapper">
                {/*<Row className="registration-btn-wrapper">*/}
                {/*  <Col className="gutter-row">*/}
                {/*    <SecondaryButton*/}
                {/*      type="button"*/}
                {/*      className="btn secondary-btn cta-btn"*/}
                {/*      onClick={(e) => this.validateForm(e, '$250')}*/}
                {/*    >*/}
                {/*      PREMIUM LITE FOR $250 MONTH*/}
                {/*    </SecondaryButton>*/}
                {/*  </Col>*/}
                {/*  <Col className="gutter-row">*/}
                {/*    <SecondaryButton*/}
                {/*      type="button"*/}
                {/*      className="btn secondary-btn cta-btn"*/}
                {/*      onClick={(e) => this.validateForm(e, '$500')}*/}
                {/*    >*/}
                {/*      PREMIUM FOR $500 MONTH*/}
                {/*    </SecondaryButton>*/}
                {/*  </Col>*/}
                {/*</Row>*/}
                <PrimaryButton
                  type="button"
                  onClick={this.upgradeToPremium}
                  className="btn primary-btn"
                >
                  Upgrade to Premium
                </PrimaryButton>
              </div>
            </Fragment>
          )}
          <p
            className={'skip'}
            onClick={() => {
              this.props.history.push('/')
            }}
          >
            Skip and Start Discovering
          </p>
        </Form>
      )
    } else {
      return null
    }
  }
}
const { Paragraph } = Typography;

class RegisterFormRHS extends Component {
  state = {
    areYouAnOther: false,
    areYouanValue: '',
    tooltipOpen: false,
    termsCondition: false,
    countryState: false,
    freeTrial: false,
    isManualChangeFreeTrial: false,
    expand: false,
    counter: 0
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].style.paddingTop = 0
    document.addEventListener("keydown", this._handleKeyDown);
  }

  _handleKeyDown = (e) => {
    if (e.code === 'Tab') {
      document.getElementById('email').disabled = false
      document.getElementById('password').disabled = false
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.state.areYouAnOther &&
      nextProps.formProps.values.areYouAn === 'Other/Curious'
    ) {
      this.setState({ areYouAnOther: true }, () =>
        document.getElementById('areYouAnOther').focus()
      )
    } else if (
      this.state.areYouAnOther &&
      nextProps.formProps.values.self_role === 'Other/Curious'
    ) {
    } else this.setState({ areYouAnOther: false })

    let self_role = nextProps.formProps.values.self_role
      ? nextProps.formProps.values.self_role
      : ''
    if (!this.state.isManualChangeFreeTrial) {
      if (
        self_role === 'RIA' ||
        self_role === 'Hybrid Advisor' ||
        self_role === 'Broker/Dealer/Wirehouse Advisor'
      ) {
        // !nextProps.formProps.values.freeTrial &&
        //   nextProps.formProps.setFieldValue('freeTrial', true)
      } else {
        // nextProps.formProps.values.freeTrial &&
        //   nextProps.formProps.setFieldValue('freeTrial', false)
      }
    } else {
      if (
        self_role === 'Individual Investor' ||
        self_role === 'Other/Curious'
      ) {
        // nextProps.formProps.values.freeTrial &&
        //   nextProps.formProps.setFieldValue('freeTrial', false)
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.error && this.props.error !== prevProps.error) {
      const { setErrors } = this.props.formProps
      setErrors({
        email: get(this.props, 'error.data.email[0]'),
        password: get(this.props, 'error.password[0]'),
        refree_code: get(this.props, 'error.refree_code[0]')
      })
    }
  }

  readMore = () => {
    this.setState({ expand: true, counter: !this.state.expand ? this.state.counter + 0 : this.state.counter + 1 });
  };

  showLess = () => {
    this.setState({ expand: false, counter: !this.state.expand ? this.state.counter + 0 : this.state.counter + 1 });
  };

  onTermsAndConditionClick = () => {
    this.setState({
      termsCondition: !this.state.termsCondition
    })
  }

  onTermsAndConditionClose = () => {
    this.setState({
      termsCondition: !this.state.termsCondition
    })
  }

  toggleAreYouAn = () => {
    this.setState({
      self_role: !this.state.self_role
    }, () => {
      if (this.state.self_role) {
        setTimeout(() => {
          document.getElementById('self_role0').focus()
        }, 100)
      }
    })
  }

  toggleCountry = () => {
    this.setState({
      countryState: !this.state.countryState
    })
  }

  toggleFreeTrial = e => {
    const { formProps } = this.props
    this.setState(
      {
        freeTrial: !this.state.freeTrial,
        isManualChangeFreeTrial: true
      },
      () => {
        formProps.handleChange(e)
      }
    )
  }

  onCountrySelect = value => {
    const { setFieldValue } = this.props.formProps
    setFieldValue('country', value)
    setFieldValue('countryInput', value)
  }

  onCountryChange = e => {
    const { formProps } = this.props
    if (!this.state.countryState) {
      this.setState({
        countryState: true
      })
    }
    formProps.handleChange(e)
  }

  toggleinvestableAssets = () => {
    this.setState({
      investable_assets: !this.state.investable_assets
    })
  }

  toggleAssetManagement = () => {
    this.setState({
      estimated_net_worth: !this.state.estimated_net_worth
    }, () => {
      if (this.state.estimated_net_worth) {
        setTimeout(() => {
          document.getElementById('estimated_net_worth0').focus()
        }, 100)
      }
    })
  }

  handleChangeSub = () => {
    const { formProps } = this.props
    const { values, handleSubmit } = formProps
    if (
      values.first_name === '' &&
      values.last_name === '' &&
      values.email === '' &&
      values.password === '' &&
      values.rePassword === ''
    ) {
      // console.log('redirect')
      this.props.history.push('/join')
    } else {
      // console.log('redirect2')
      localStorage.setItem('isPlanChange', 'true')
      handleSubmit()
    }
  }

  onStateChange = e => {
    const { formProps } = this.props

    if (e.target.value == '') {
      formProps.setFieldValue('state', e.target.value)
    }

    if (!this.state.statesState) {
      this.setState({
        statesState: true
      })
    }
    formProps.handleChange(e)
  }

  onStateSelect = item => {
    const { setFieldValue } = this.props.formProps
    setFieldValue('state', item.abbreviation)
    setFieldValue('state_value', item.abbreviation)
    setFieldValue('stateInput', item.name)
  }

  toggleStateStatus = () => {
    this.setState({
      statesState: !this.state.statesState
    })
  }

  getSubmitButtonText = (plan_type, self_role) => {
    switch (plan_type) {
      case 'basic':
        const str =
          self_role === '' ||
            self_role === 'Individual Investor' ||
            self_role === 'Other/Curious'
            ? 'next'
            : 'Create Free Account'
        return process.env.REACT_APP_SUB_DOMAIN === 'advisor'
          ? 'Create Free Account'
          : str
      case 'pro':
        return 'Magnifi pro for $250 month'
      case 'pro-plus':
        return 'Magnifi pro+ for $500 month'
      default:
        return 'Create Free Account'
    }
  }

  render() {
    const { formProps, plan_selected } = this.props
    const {
      values,
      touched,
      errors,
      // isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue
    } = formProps

    // let renderModal = ''

    // const handleDateChange = dateInstance => {
    //   let dob = moment(dateInstance).format('YYYY-MM-DD')
    //   if (dateInstance) setFieldValue('date_of_birth', dob)
    // }

    // if (this.state.termsCondition)
    //   renderModal = (
    //     <TermsConditions onTermsClick={this.onTermsAndConditionClose} />
    //   )

    return (
      <>
        <Form
          name="RegisterFormRHS"
          className="magnifi-auth-design-container register-magnifi-auth-design advisor_different_layout"
          noValidate={true}
          onSubmit={handleSubmit}
          style={{ display: 'flow-root', padding: '13%' }}
        >
          {/* <Tagline /> */}
          <div className="header-area retail_form_container text-center">
            <h1>Create your Magnifi account</h1>
            <div className="header-label main">
              Enter your details below to get started
            </div>
            {/* <div className="sub-header-label">Let's get started with the basic details</div> */}
          </div>
          {/*main form */}
          <div className="body-wrapper retail_form_container">
            {/*f-name l-name*/}
            {/*email*/}
            <Row className="form_input_container_gray border_radius_top">
              <Col>
                <FormGroup className="element-box pos-rel" onClick={() => {
                  document.getElementById('email').disabled = false
                  document.getElementById('email').focus();
                }}>
                  <Label style={{ width: '100%' }} className={values.email ? "label new_label has_value" : "label new_label"} htmlFor="email">
                    E-mail
                  </Label>
                  <Input
                    type="text"
                    disabled={true}
                    name="email"
                    id="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.email && touched.email
                        ? 'is-invalid input-area'
                        : 'input-area'
                    }
                  // placeholder="Enter your email address"
                  />
                  <CustomFormFeedback formProps={formProps} fieldName="email" />
                </FormGroup>
              </Col>
            </Row>

            {/*password and confirm*/}
            <Row className="form_input_container_gray">
              <Col sm={values.password ? 6 : 12}>
                {/*password*/}
                <FormGroup className="element-box pos-rel" onClick={() => {
                  document.getElementById('password').disabled = false
                  document.getElementById('password').focus();
                }}>
                  <Label className={values.password ? "label new_label has_value" : "label new_label"} htmlFor="password">
                    Password
                  </Label>
                  <Input
                    type="password"
                    name="password"
                    disabled={true}
                    id="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.password && touched.password
                        ? 'is-invalid input-area'
                        : 'input-area'
                    }
                  // placeholder="Enter a password"
                  />
                  <CustomFormFeedback
                    formProps={formProps}
                    fieldName="password"
                  />
                </FormGroup>
              </Col>
              {values.password ? <Col sm={6}>
                {/*confirm password*/}
                <FormGroup className="element-box pos-rel">
                  <Label className={values.rePassword ? "label new_label has_value" : "label new_label"} htmlFor="rePassword">
                    Confirm Password
                  </Label>
                  <Input
                    type="password"
                    name="rePassword"
                    id="rePassword"
                    value={values.rePassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.rePassword && touched.rePassword
                        ? 'is-invalid input-area'
                        : 'input-area'
                    }
                  // placeholder="Re-enter password"
                  />
                  <CustomFormFeedback
                    formProps={formProps}
                    fieldName="rePassword"
                  />
                </FormGroup>
              </Col> : null}
            </Row>
            <Row className="form_input_container_gray">
              <Col>
                <FormGroup className="element-box pos-rel">
                  <Label className={values.first_name ? "label new_label has_value" : "label new_label"} htmlFor="first_name">
                    First Name
                  </Label>
                  <Input
                    type="text"
                    name="first_name"
                    id="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.first_name && touched.first_name
                        ? 'is-invalid input-area'
                        : 'input-area'
                    }
                  // placeholder="Enter your first name"
                  />
                  <CustomFormFeedback
                    formProps={formProps}
                    fieldName="first_name"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="element-box pos-rel">
                  <Label className={values.last_name ? "label new_label has_value" : "label new_label"} htmlFor="last_name">
                    Last Name
                  </Label>
                  <Input
                    type="text"
                    name="last_name"
                    id="last_name"
                    onKeyDown={(e) => {
                      if (e.key === 'Tab') {
                        e.preventDefault();
                        document.getElementById('estimated_net_worth').click();
                        document.getElementById('estimated_net_worth').focus();
                      }
                    }}
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.last_name && touched.last_name
                        ? 'is-invalid input-area'
                        : 'input-area'
                    }
                  // placeholder="Enter your last name"
                  />
                  <CustomFormFeedback
                    formProps={formProps}
                    fieldName="last_name"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className='form_input_container_gray'>
              <Col sm={6}>
                <FormGroup className="element-box dropdown-element pos-rel country-wrapper">
                  <Label className={values.estimated_net_worth ? "label new_label has_value" : "label new_label"} htmlFor="estimated_net_worth">
                    Assets Under Advice
                  </Label>
                  <Dropdown
                    className={
                      errors.estimated_net_worth && touched.estimated_net_worth
                        ? 'is-invalid dropdown-wrapper'
                        : 'dropdown-wrapper'
                    }
                    isOpen={this.state.estimated_net_worth}
                    toggle={this.toggleAssetManagement}
                  >
                    <DropdownToggle
                      value={values.estimated_net_worth}
                      onKeyDown={(e) => {
                        if (e.key === 'Tab') {
                          e.preventDefault();
                          document.getElementById('self_role').click();
                          document.getElementById('self_role').focus();
                        }
                      }}
                      style={{ backgroundColor: '#fff' }}
                      id='estimated_net_worth'
                      className={
                        errors.estimated_net_worth && touched.estimated_net_worth
                          ? 'is-invalid input-area'
                          : 'input-area'
                      }
                      caret
                    >
                      {values.estimated_net_worth || ''}
                    </DropdownToggle>
                    <DropdownMenu center>
                      {assetUnderManagementList.map((item, i) => {
                        return (
                          <DropdownItem
                            id={'estimated_net_worth' + i}
                            value={item}
                            onClick={e => {
                              setFieldValue('estimated_net_worth', e.target.value)
                            }}
                          >
                            {item}
                          </DropdownItem>
                        )
                      })}
                    </DropdownMenu>
                  </Dropdown>
                  {errors.estimated_net_worth && touched.estimated_net_worth && (
                    <div className="form-invalid-field">
                      {errors['estimated_net_worth']}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col sm={6}>
                {process.env.REACT_APP_SUB_DOMAIN !== 'retail' && (
                  <FormGroup className="element-box dropdown-element pos-rel">
                    <Label className={values.self_role ? "label new_label has_value" : "label new_label"} htmlFor="self_role">
                      Firm Type
                    </Label>
                    <Dropdown
                      className={
                        errors.self_role && touched.self_role
                          ? 'is-invalid dropdown-wrapper'
                          : 'dropdown-wrapper'
                      }
                      isOpen={this.state.self_role}
                      toggle={this.toggleAreYouAn}
                      onChange={this.handleAreYouAnChange}
                    >
                      <DropdownToggle
                        value={values.self_role}
                        style={{ backgroundColor: '#fff' }}
                        id='self_role'
                        className={
                          errors.areYouAnOther && touched.areYouAnOther
                            ? 'is-invalid input-area'
                            : 'input-area'
                        }
                        caret
                      >
                        {values.self_role || ''}
                      </DropdownToggle>
                      <DropdownMenu center>
                        {areYouAnList.map((item, i) => {
                          if (
                            process.env.REACT_APP_SUB_DOMAIN === 'advisor' &&
                            item === 'Individual Investor'
                          ) {
                            return null
                          }
                          return (
                            <DropdownItem
                              value={item}
                              id={'self_role' + i}
                              onClick={e => {
                                setFieldValue('self_role', e.target.value)
                                if (item !== 'Other/Curious')
                                  setFieldValue('areYouAnOther', '')
                              }}
                            >
                              {item}
                            </DropdownItem>
                          )
                        })}
                      </DropdownMenu>
                    </Dropdown>
                    {errors.self_role && touched.self_role && (
                      <div className="form-invalid-field">{errors['self_role']}</div>
                    )}
                  </FormGroup>
                )}
              </Col>
              <Col sm={6}>
                {(values.self_role === 'Individual Investor' ||
                  values.self_role === 'Other/Curious') && (
                    <FormGroup className="element-box dropdown-element pos-rel country-wrapper">
                      <Label className={values.investable_assets ? "label new_label has_value" : "label new_label"} htmlFor="investable_assets">
                        Investable Assets
                      </Label>
                      <Dropdown
                        className={
                          errors.investable_assets && touched.investable_assets
                            ? 'is-invalid dropdown-wrapper'
                            : 'dropdown-wrapper'
                        }
                        isOpen={this.state.investable_assets}
                        toggle={this.toggleinvestableAssets}
                      >
                        <DropdownToggle
                          value={values.investable_assets}
                          id="investable_assets"
                          style={{ backgroundColor: '#fff' }}
                          className={
                            errors.investable_assets && touched.investable_assets
                              ? 'is-invalid input-area'
                              : 'input-area'
                          }
                          caret
                        >
                          {values.investable_assets || ''}
                        </DropdownToggle>
                        <DropdownMenu center>
                          {investableAssetsList.map(item => {
                            return (
                              <DropdownItem
                                value={item}
                                onClick={e => {
                                  setFieldValue('investable_assets', e.target.value)
                                }}
                              >
                                {item}
                              </DropdownItem>
                            )
                          })}
                          {/* <DropdownItem value="India" onClick={(e) => {setFieldValue('country', e.target.value)}}>India</DropdownItem>
                <DropdownItem value="U.S.A" onClick={(e) => {setFieldValue('country', e.target.value)}}>U.S.A</DropdownItem> */}
                        </DropdownMenu>
                      </Dropdown>

                      {/* <CustomFormFeedback formProps={formProps} fieldName="country" /> */}
                      {errors.investable_assets && touched.investable_assets && (
                        <div className="form-invalid-field">
                          {errors['investable_assets']}
                        </div>
                      )}
                    </FormGroup>
                  )}
              </Col>
            </Row>
            {/* <Row className="form_input_container_gray">
            <Col>
              {plan_selected === 'basic' &&
                values.self_role &&
                !['Individual Investor', 'Other/Curious'].includes(
                  values.self_role
                ) && (
                  <div className="body-wrapper">
                    <FormGroup className="element-box dropdown-element pos-rel">
                      <Checkbox
                        value={values.freeTrial}
                        name="freeTrial"
                        id="freeTrial"
                        checked={values.freeTrial}
                        onChange={this.toggleFreeTrial}
                      >
                        Sign Up for a free 14 day trial
                      </Checkbox>
                    </FormGroup>
                  </div>
                )}
            </Col>
          </Row> */}
            <Row className="form_input_container_gray border_radius_bottom">
              <Col>
                <div className="body-wrapper">
                  <FormGroup className="element-box dropdown-element pos-rel">
                    {/* <Checkbox
                    value={values.financial_advisor}
                    name="financial_advisor"
                    id="financial_advisor"
                    checked={values.financial_advisor}
                    onChange={handleChange}
                  >
                    I certify I am a financial professional. *
                  </Checkbox>
                  {errors.financial_advisor && touched.financial_advisor && (
                    <div className="form-invalid-field">
                      {errors['financial_advisor']}
                    </div>
                  )} */}
                  </FormGroup>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="body-wrapper">
                  <FormGroup className="element-box pos-rel">
                    <PasswordRequirement
                      label={'Password Requirements:'}
                      formProps={formProps}
                      fieldName={'password'}
                    />
                  </FormGroup>
                </div>
              </Col>
            </Row>

            <Row>
              <div className="w-100">
                <FormGroup className="cta-wrapper">
                  {/* {process.env.REACT_APP_SUB_DOMAIN !== 'retail' && (
                  <div onClick={this.handleChangeSub}>
                    <p className={'change-sub'}>Change Your Subscription Plan</p>
                  </div>
                )} */}
                  <PrimaryButton
                    type="submit"
                    className="btn primary-btn"
                    style={{ marginTop: '6px', marginBottom: '6px' }}
                  >
                    {this.getSubmitButtonText(plan_selected, values.self_role)}
                    {/* {plan_selected==='basic'? (values.self_role === '' || values.self_role === 'Individual Investor' ||  values.self_role === 'Other/Curious') ? 'next' : 'Personalize & Start Discovering' : plan_selected==='pro'? 'Magnifi pro for $250 month':'Magnifi pro+ for $500 month'} */}
                  </PrimaryButton>
                  <div className="or-partition fs-20">
                    Already have an account?
                    <Link className="hyper-link fs-20" to="/login">
                      {' '}
                      Login
                    </Link>
                  </div>
                  <div className="or-partition">or</div>

                  {/*
                  <GoogleLogin
                    clientId={config.googleClientId}
                    render={renderProps => (
                      <button
                        className="btn secondary-btn sign-in__googleBtn"
                        onClick={renderProps.onClick}
                        style={{ marginTop: '6px' }}
                      >
                        <img src={googleLogo} width={20} alt="google-logo" />
                        <span style={{ paddingLeft: 10 }}>Continue with Google</span>
                      </button>
                    )}
                    onSuccess={this.props.onGoogleSignInSuccess}
                    onFailure={this.props.onGoogleSignInError}
                  />
                */}

                  <button
                    className="btn secondary-btn sign-in__googleBtn"
                    onClick={(event) => this.props.onGoogleSignInSuccess(event)}
                    style={{ marginTop: '6px' }}
                  >
                    <img src={googleLogo} width={20} alt="google-logo" />
                    <span style={{ paddingLeft: 10 }}>Continue with Google</span>
                  </button>


                  {/* <div className="or-partition mt-4">
                  * Mandatory
                </div> */}
                </FormGroup>
              </div>
            </Row>
          </div>
          {/*disclaimer */}
          <div className="grey">
            {/* <strong>Disclosures:-</strong> */}
            <div className="pt-3" style={{ fontStyle: 'italic', textAlign: 'justify', textJustify: 'inter-word', fontSize: 12 }}>
              <ExpandCollapse
                previewHeight="53px"
                ellipsisText="..."
                expandText="Read More"
                collapseText="Show Less">
                Advisory services are offered through Magnifi LLC, an SEC Registered Investment
                Advisor. Being registered as an investment adviser does not imply a certain level of skill or training.
                The information contained herein should in no way be construed or interpreted as a solicitation to sell or offer to sell advisory services to any residents of any State where notice-filed or otherwise legally permitted. All content is for information purposes only. It is not intended to provide any tax or legal advice or provide the basis for any financial decisions. Nor is it intended to be a projection of current or future performance or indication of future results. Moreover, this material has been derived from sources believed to be reliable but is not guaranteed as to accuracy and completeness and does not purport to be a complete analysis of the materials discussed. Purchases are subject to suitability. This requires a review of an investor’s objective, risk tolerance, and time horizons. Investing always involves risk and possible loss of capital.
                <br /><br />
                Magnifi LLC does not charge advisory fees or transaction fees for non-managed accounts. Clients who elect to have Magnifi LLC manage all or a portion of their account will be charged an advisory fee. Please see Magnifi’s Form ADV for additional information about fees and charges that may apply. Magnifi LLC receives compensation from product sponsors related to recommendations.  Other fees and charges may apply.
              </ExpandCollapse>
            </div>
          </div>
        </Form>
      </>
    )
  }
}

const Tagline = () => (
  <div className="mb-3" style={{ width: '90%' }}>
    <a className="logo-wrapper" href={REDIRECT_DOMAIN_PATH}>
      <img src={magnifiLogo} width="100%" alt="magnifi" />
    </a>
  </div>
)

const BackButton = ({ clickHandler }) => (
  <div className="align-self-stretch h1 mb-0 px-3 pt-3" style={{ position: 'absolute' }}>
    <IconButton
      onClick={clickHandler}
      iconClass="fa-long-arrow-left"
      style={{ fontSize: '38px' }}
    />
  </div>
)

// const RegisterFormLHS = () => (
//   <div className="text-center w-75 carousel-panel">
//     <h1 className="header-1">Why Personalize?</h1>
//     {/* <img src={lhsStep1} width="100%" alt="magnifi" /> */}
//     <MessagesCarousel />
//   </div>
// )

// const MessagesCarousel = props => {
//   const [activeIndex, setActiveIndex] = useState(0)
//   const [animating, setAnimating] = useState(false)
//   const items = LHSMsg
//
//   const next = () => {
//     if (animating) return
//     const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
//     setActiveIndex(nextIndex)
//   }
//
//   const previous = () => {
//     if (animating) return
//     const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
//     setActiveIndex(nextIndex)
//   }
//
//   const goToIndex = newIndex => {
//     if (animating) return
//     setActiveIndex(newIndex)
//   }
//
//   // const slides = items.map((item, i) => {
//   //   return (
//   //     <CarouselItem key={i} className="h4"
//   //       onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} >
//   //       {item}
//   //     </CarouselItem>
//   //   );
//   // });
//
//   const slides = items.map(item => {
//     return (
//       <CarouselItem
//         className="carouselImg"
//         onExiting={() => setAnimating(true)}
//         onExited={() => setAnimating(false)}
//         key={item.src}
//         id="caraousel-id"
//       >
//         <img src={item.src} alt={item.altText} />
//         <h4 style={{ color: '#fff', fontWeight: '400', fontSize: '20px' }}>
//           {item.caption}
//         </h4>
//         {/* <CarouselCaption captionText={item.caption} captionHeader={item.caption} /> */}
//       </CarouselItem>
//     )
//   })
//
//   return (
//     <Carousel
//       interval={2000}
//       ride="carousel"
//       activeIndex={activeIndex}
//       next={next}
//       previous={previous}
//     >
//       <CarouselIndicators
//         items={items}
//         activeIndex={activeIndex}
//         onClickHandler={goToIndex}
//         className="dots"
//         id="caraousel-dots"
//       />
//       {slides}
//     </Carousel>
//   )
// }

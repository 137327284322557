// absolute imports
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import { Layout, message,  Button, Icon, Tag, Result, Row, Col } from 'antd';
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from 'react-router-dom';

import { resetSummaryDataRequest } from 'actions/enhancer';
import { changeCalculator } from 'actions/weights';
import { createLoadingSelector } from 'selectors/loading-selector';

import SideBar from './SideBar';
import { Loader } from '../../Elements/Loader';

class Dashboard extends React.PureComponent {
  // componentDidUpdate() {
  //   const { location } = this.props;
  //   const { pathname } = location;
  //   if (pathname && pathname.includes('/accounts')) {
  //     const sideBarElement = document.getElementById("sidebar-container");
  //     const targetElem = document.getElementById("header-menus-wrapper-style");
  //     // console.log(targetElem);
  //     if (sideBarElement && typeof sideBarElement !== 'undefined' && targetElem && typeof targetElem !== 'undefined') {
  //       // console.log("header-menus-wrapper-style");
  //       targetElem.style.position = 'absolute';
  //       targetElem.style.left = sideBarElement.offsetWidth + 10 + 20 + 'px';
  //       // menuOptionStyles = {
  //       //   position: 'absolute',
  //       //   left: sideBarElement.offsetWidth + 15 + 20 + 'px', // 'calc(25% + 20px)', //sideBarElement.offsetWidth + 20 + 'px',
  //       // };
  //       // clientPortfolioBtnStyles = {
  //       //   // position: 'absolute',
  //       //   // right: 52,
  //       // };
  //     }
  //   }
  // }

  render() {
    const {
      routerData,
      match,
      loading,
      location,
      component: Component,
    } = this.props;
    const { pathname } = location;

    return (
      <Layout
        style={{
          // minHeight: 'calc(100vh - 60px)',
        }}
        >
        <Row className="magnifi-dashboard-container">
          <Col span={6} id="sidebar-container" className="sidebar-container">
            <SideBar
              {...this.props}
              setContent={this.setContent}
              setPfEnhancement={this.setPfEnhancement}
              enableEnhancePortfolioQueries={'enableEnhancePortfolioQueries'}
              toggleFirstTimeEnhanceQuerySelction= {this.toggleFirstTimeEnhanceQuerySelction}
              enableFirstEnhancePortfolioQueries=""
              />
          </Col>
          <Col span={18} className="dashboard-content-container">
            <Fragment>
              <Component {...this.props} />
            </Fragment>
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapStateToProps = ({ router, loading }) => ({
  location: router.location,
});

const mapDispatchToProps = {
  changeCalculator,
  resetSummaryDataRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);

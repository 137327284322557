import React from 'react'
import { Row, Col } from 'reactstrap'
import { haveHTMLData } from 'Utils'

export const Disclosures = ({ disclosure }) => {
  return (
    <React.Fragment>
      <div className="pr-v2-disclosure">
        <Row>
          <Col>
            <b>Disclosures</b>
          </Col>
        </Row>
        <Row>
          <Col>
            {disclosure && disclosure.length > 0 && haveHTMLData(disclosure) ? (
              <div
                className="text"
                style={{ color: '#fff' }}
                dangerouslySetInnerHTML={{ __html: disclosure }}
              />
            ) : (
              <p className={'cover-logo text'} style={{ margin: '1rem 0 0 0' }}>
                Your Disclosures Here
              </p>
            )}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

import React from 'react';
import { Radio } from 'antd';

export const Allocation = ({ onChange, value }) => {
	return (
		<div className="allocation-radio-button-wrapper">
			<div className="allocation-head-text">Allocation</div>
			<Radio.Group
				value={value}
				onChange={onChange}
			>
	      <Radio.Button value="weight">%</Radio.Button>
	      <Radio.Button value="value">$</Radio.Button>
	    </Radio.Group>
		</div>
	);
}

export const FormatCurrency = (num, prec = 0) => {
  let _prec = (Number.isInteger(num)) ? 0 : prec;
  let amount = typeof num === "number"
    ? num.toLocaleString('en', {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: _prec,
        minimumFractionDigits: 0
      })
    : '';
  if (amount.includes('.00')) {
    amount = (amount.split('.00') && amount.split('.00').length > 0) ? amount.split('.00')[0] : amount;
  }
  return amount;
}

export const colWidthObj = {
  edit: '5%',
  ticker: '80px',
  name: 'auto',
  // weight: '15%',
  values: '15%',
  operation: '15%',
  'weight(%)': '15%',
  searchInput: '326px',
};

export default {
	Allocation,
	colWidthObj,
	FormatCurrency,
}

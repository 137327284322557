import React, { useState } from 'react';
import { ModalFooter, Spinner, Modal, ModalBody, ButtonGroup } from 'reactstrap';
import { uniqBy } from 'lodash';
import { trackFunds } from 'apis/funds';
import { isEmpty } from 'lodash/lang'

import { cn } from 'DataSet';
import { getNest } from 'Utils';
import { isNull } from 'util';
import { numberCheck } from 'layouts/utils';

import ItemList from './ItemList';
import Footer from './Footer';
import { Loader } from '../Loader';

let trades = []

export default class ActTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      trades: [],
      inputFieldError: false,
      holdingValueDisable: false,
      noBankAccountPopup: false,
      popupOpened: false
    };
    this.inRef = React.createRef();
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.popupOpened) {
      let { profile, loggedIn } = nextProps
      if (!isEmpty(profile.apex) && loggedIn) {
        let apex_Status = profile.apex.account[0].status
        let apex_active = profile.apex.account[0].is_active
        // if(apex_Status!=='COMPLETE' || !apex_active){
        // }
        if (apex_Status === 'COMPLETE' && apex_active && (!profile.apex.bank || (profile.apex.bank && profile.apex.bank.length === 0))) {
          this.setState({ noBankAccountPopup: true, popupOpened: true })
        }
      }
    }
  }

  executeHandler = () => {
    const ORDER = {
      data: {
        trades: this.state.trades,
      },
    };
    const { executeHandler } = this.props;
    if (executeHandler) {
      executeHandler(ORDER);
    }
  }

  deleteAll = () => {
    const { deleteAll } = this.props;
    if (deleteAll) {
      deleteAll();
    }
  }

  removeFund = (fund) => {
    if (fund) {
      const { removeFund } = this.props;
      if (removeFund) {
        removeFund(fund);
      }
    }
  }

  buyHandler = () => {
    const { buyHandler } = this.props;
    if (buyHandler) {
      buyHandler();
    }
  }

  getOrderDetails = (order) => {
    const { myportfolio, funds } = this.props;
    const basketFund = order.vehicle === 'BASKET';

    let trades = [];
    let realPrice = numberCheck(order.analysisPrice, 2);
    let card = (funds && funds.length > 0) ? funds.find((item) => item.ticker === order.ticker) : null;
    let { holdingValue = null, sharesValue = null } = this.getMyHoldingData({ card });

    if (this.state.trades && this.state.trades.length) {
      trades = this.state.trades.map((item) => {
        if (item.ticker === order.ticker) {
          return order;
        }
      });
    } else {
      trades.push(order);
      trades = uniqBy(trades, 'ticker');
    }

    if (order.side === 'BUY') {
      if (order.value < card.minInvestment) {
        this.setState({ holdingValueDisable: true });
      } else {
        this.setState({ holdingValueDisable: false });
      }
    } else if (order.side === 'SELL') {
      // validation check for 'sell' or 'sell_all';
      if (holdingValue !== null && order.value > holdingValue) {
        this.setState({ holdingValueDisable: true });
      } else {
        this.setState({ holdingValueDisable: false });
      }
    } else if (order.side === 'SELL_ALL') {
      this.setState({ holdingValueDisable: false })
    }

    this.setState({ trades }, () => {
      const { getOrderDetails } = this.props;
      if (getOrderDetails) {
        getOrderDetails(trades);
      }
    });
  }

  getMyHoldingData = ({ card }) => {
    const { myportfolio } = this.props;
    let sharesValue = null, holdingValue = null;
    if (card && typeof card !== 'undefined') {
      const realPrice = card.realPrice;
      const basketFund = card.vehicle === 'BASKET';
      let getMyHoldingIFExists = (myportfolio && myportfolio.length > 0) ? myportfolio.find(v => v.script === card.ticker) : null;
      const _minInvestment = numberCheck(card.minInvestment, 5);
      if (typeof realPrice !== 'undefined' && getMyHoldingIFExists && typeof getMyHoldingIFExists.market_value !== 'undefined') {
        if (basketFund) {
          holdingValue = numberCheck(getMyHoldingIFExists.market_value, 5);
        } else if (typeof getMyHoldingIFExists.shares !== 'undefined' && numberCheck(getMyHoldingIFExists.shares, 5)) {
          sharesValue = numberCheck(getMyHoldingIFExists.shares, 5);
          holdingValue = sharesValue * realPrice;
        }
        return {
          ...getMyHoldingIFExists,
          sharesValue,
          holdingValue,
        }
      } else {
        return { sharesValue, holdingValue };
      }
    } else {
      return { sharesValue, holdingValue };
    }
  }

  invalidHandler = (inputFieldError) => {
    this.setState({ inputFieldError });
  }

  render() {
    const { loggedIn, broker, magnifiTickers, loading, profileLoading, isBrokerRegistered, profile } = this.props;
    const { trades, inputFieldError, holdingValueDisable } = this.state;
    const accountAllowedToTrade = (isBrokerRegistered && broker && broker.allowToTrade);

    const noBankAccount = !isEmpty(profile.apex) && ((!profile.apex.bank || (profile.apex.bank && profile.apex.bank.length === 0)) && profile.apex.account && profile.apex.account[0] && profile.apex.account[0].status === 'COMPLETE' && profile.apex.account[0].is_active) ? true : false;

    const verificationPending = !isEmpty(profile.apex) && (profile.apex.account && profile.apex.account[0] && (profile.apex.account[0].status === 'PENDING' || profile.apex.account[0].status === 'ACTION_REQUIRED' || profile.apex.account[0].status === 'BACK_OFFICE' || profile.apex.account[0].status === 'REJECTED' || profile.apex.account[0].status === 'SUSPENDED' || (profile.apex.account[0].status === 'COMPLETE' && !profile.apex.account[0].is_active))) ? true : false;

    return (
      <div className="sfm-act-tab-container">

        <Modal
          isOpen={this.state.noBankAccountPopup}
          toggle={() => this.setState({ noBankAccountPopup: !this.state.noBankAccountPopup })}
          centered
          keyboard={true}
          backdrop
          className="premium-msg-modal-sm-light"
          backdropClassName="premium-feature-process-modal-backdrop"
        >
          <ModalBody className="ssf-modal__body text_21_rem">
            <div className="premium-feature-process-modal-container delete-confirmation-modal">
              <div className="header-wrapper">
                <h1 className="text-align-center pb-2">Link your Bank Account to Start Investing</h1>
              </div>
              <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => this.setState({ noBankAccountPopup: !this.state.noBankAccountPopup })}></i>
              <div className="confirmation-btn">
                <ButtonGroup
                  className="justify-content-center w-100 mb-2"
                >
                  <button
                    onClick={() => {
                      let email = localStorage.getItem('email') || window.sessionStorage.getItem('email') || "";
                      // addEventToAnalytics('Link Bank Account Now', 'Link Bank Account Now', 'LINK_BANK_ACCOUNT_NOW', { email: email }, true);
                      this.props.history.push({ pathname: "/settings/tradingAccount", linkaccount: true })
                    }}
                    className="ssf-btn-primary btn-link btn_link_now btn btn-secondary"
                  >
                    LINK ACCOUNT
                  </button>
                </ButtonGroup>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {(loading || profileLoading) && <Loader loading />}
        <div className="modal-body-ssf p-2 media-none">
          <ItemList
            {...this.props}
            noBankAccount={noBankAccount}
            verificationPending={verificationPending}
            removeFund={this.removeFund}
            clearHandler={this.deleteAll}
            invalidHandler={this.invalidHandler}
            getOrderDetails={this.getOrderDetails}
            getMyHoldingData={this.getMyHoldingData}
          />
        </div>
        <div className="d-md-none modal-body-ssf d-flex align-items-center flex-column justify-content-center">
          <p className="text-center px-2 mb-2">Please visit magnifi.com on a desktop to access features for further analysis and trading</p>
        </div>
        <ModalFooter className="py-2 media-none sfm-modal-footer">
          <Footer
            trades={trades}
            items={this.props.funds}
            noBankAccount={noBankAccount}
            verificationPending={verificationPending}
            loggedIn={loggedIn}
            profileLoading={profileLoading}
            inputFieldError={inputFieldError}
            holdingValueDisable={holdingValueDisable}
            accountAllowedToTrade={accountAllowedToTrade}
            deleteAll={this.deleteAll}
            executeOrder={this.executeHandler}
            profile={profile}
            updateBasketAggrementStatus={this.props.updateBasketAggrementStatus}
            basketAggreement={this.props.basketAggreement}
          />
        </ModalFooter>
      </div>
    )
  }
}

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Row, Col, Card, Menu, Button, Tooltip } from 'antd';
import { addEventToAnalytics } from 'Utils';

import { UpOutlined, DownOutlined } from '@ant-design/icons';
import _ from 'lodash';

import { createLoadingSelector } from 'selectors/loading-selector';
import { setBuildPortfolioState, thematicBatchRequest, searchForNewQueryRequest } from 'actions/buildportfolio';

import { convertToCapitalCase } from 'Utils';

import ThematicRadioFunds from './ThematicRadioFunds';
import RadioButtonList from './RadioButtonList';
import CardLoader from '../../../Elements/CardLoader';

const { SubMenu } = Menu;

const buildPortfolioMenus = [{
  label: 'Equal',
  icon: null,
  disabled: true,
  className: 'disabled-menu',
  tooltip: null,
}, {
  label: 'Risk Parity',
  icon: null,
  disabled: true,
  style: { margin: 0 },
  className: 'disabled-menu',
  tooltip: children => (
    <Tooltip placement="top" title="Coming soon">
      {children}
    </Tooltip>
  ),
}];

class ThematicRadioContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chooseThemeButtonToggle: props.chooseThemeButtonToggle,
      selectedThemes: props.selectedThemes,
      showSearchedQuery: false,
      queryFromThemeList: '',
      thematicTabData: props.thematicTabData,
      updatedThematicTabData: [],
    };
  }

  componentDidMount() {
    // calling batch themes request for ['Climate Change'] as a default load.
    const { selectedThemes } = this.props;
    if (this.state.selectedThemes !== selectedThemes) {
      this.setState({ selectedThemes });
    }
    if (this.state.selectedThemes.length) {
      this.getSelectedThemes(this.state.selectedThemes, false, 'initial');
    }
  }

  componentWillReceiveProps(newProps) {
    // console.log('prstik 980')
    const { thematicTabData, topTabHeaderMenu, selectedFunds, buildportfolio, thematicTab } = newProps;

    if (buildportfolio.oldHeaderMenuState && typeof buildportfolio.oldHeaderMenuState !== 'undefined' && buildportfolio.oldHeaderMenuState.length) {
      const qw = buildportfolio.oldHeaderMenuState.filter((l) => l.key === 'thematicTab')[0]; // 100
      const lw = buildportfolio.tabHeaderMenu.filter((k) => k.key === 'thematicTab')[0]; // 60

      if (qw.sliderValue !== lw.sliderValue) {
        // console.log('prstik 981')
        if (buildportfolio.oldHeaderMenuState !== buildportfolio.tabHeaderMenu) {
          if (Object.keys(selectedFunds) && Object.keys(selectedFunds).length > 0) {
            const diff = lw.sliderValue - qw.sliderValue;
            const getActiveFunds = Object.keys(selectedFunds).filter((ty) => (selectedFunds[ty].length > 0 && thematicTabData.find((er) => er.query === ty).sliderValue > 0));

            // console.log('pratik111', lw.sliderValue, qw.sliderValue, getActiveFunds);

            if (getActiveFunds.length > 0) {
              // console.log('prstik 982')
              let oldSum = 0;
              if (buildportfolio.oldThematicData) {
                buildportfolio.oldThematicData.forEach((er) => {
                  // console.log(er.sliderValue)
                  oldSum += er.sliderValue;
                })
              }
              getActiveFunds.map((rt) => {
                if (rt) {
                  const fd = thematicTabData.find((er) => er.query === rt);
                  // console.log(fd.query);
                  if (fd) {
                    //USA sliderValue
                    // console.log(`${fd.query} sliderValue`);
                    // thematicTabData.find((er) => er.query === rt).sliderValue = newProps.topTabHeaderMenu.find((item) => item.key === 'thematicTab').sliderValue/getActiveFunds.length;
                    // console.log(oldSum, diff);
                    if(oldSum != diff){
                      if (getActiveFunds.length === 1) {
                        thematicTabData.find((er) => er.query === rt).sliderValue = newProps.topTabHeaderMenu.find((item) => item.key === 'thematicTab').sliderValue/getActiveFunds.length;
                      } else if (getActiveFunds.length > 1 && buildportfolio.oldThematicData && buildportfolio.oldThematicData.find((er) => er.query === rt) && buildportfolio.oldThematicData.find((er) => er.query === rt).sliderValue > 0) {
                        // console.log(buildportfolio.oldThematicData.find((er) => er.query === rt).sliderValue);
                        // console.log(oldSum);
                        // console.log(diff);
                        // console.log((buildportfolio.oldThematicData.find((er) => er.query === rt).sliderValue / oldSum)*diff);
                        thematicTabData.find((er) => er.query === rt).sliderValue = buildportfolio.oldThematicData.find((er) => er.query === rt).sliderValue + ((buildportfolio.oldThematicData.find((er) => er.query === rt).sliderValue == 0 ? 0 : buildportfolio.oldThematicData.find((er) => er.query === rt).sliderValue / oldSum)*diff);
                      } else if (getActiveFunds.length > 1 && buildportfolio.oldThematicData && buildportfolio.oldThematicData.find((er) => er.query === rt) && buildportfolio.oldThematicData.find((er) => er.query === rt).sliderValue === 0) {
                        if (thematicTabData.find((er) => er.query === rt).sliderValue > 0) {
                          // console.log('pratik121', thematicTabData.find((er) => er.query === rt).label, thematicTabData.find((er) => er.query === rt).sliderValue, newProps);
                          thematicTabData.find((er) => er.query === rt).sliderValue = newProps.buildportfolio.tabHeaderMenu.find((item) => item.key === 'thematicTab').sliderValue/getActiveFunds.length;
                          // console.log('pratik123', thematicTabData.find((er) => er.query === rt).label, thematicTabData.find((er) => er.query === rt).sliderValue);
                        }
                      }
                    }

                    // USA selectedFunds sliderValue
                    // console.log(`${fd.query} selectedFunds sliderValue`);
                    // console.log(rt, selectedFunds[rt]);
                    if (selectedFunds[rt] && selectedFunds[rt].length > 0) {
                      selectedFunds[rt].map((o) => {
                        if (o) {
                          if(selectedFunds[rt].length == 1 ){
                            o._sliderValue = thematicTabData.find((er) => er.query === rt).sliderValue/selectedFunds[rt].length;
                            // console.log(o._sliderValue);
                          }
                          else if(selectedFunds[rt].length > 1 && o._sliderValue > 0 && buildportfolio.oldThematicTab.selectedFunds[rt] && _.sumBy(buildportfolio.oldThematicTab.selectedFunds[rt], '_sliderValue') <= 0) {
                            // console.log('pratik 12.3')
                            o._sliderValue = thematicTabData.find((er) => er.query === rt).sliderValue/selectedFunds[rt].length;
                            // console.log('pratik 12.3.1', o.ticker, o._sliderValue)
                          }
                          else if(selectedFunds[rt].length > 1 && o._sliderValue <= 0) {
                            // console.log('pratik 12.3')
                            o._sliderValue = thematicTabData.find((er) => er.query === rt).sliderValue/selectedFunds[rt].length;
                            // console.log('pratik 12.3.1', o.ticker, o._sliderValue)
                          }
                          else if(selectedFunds[rt].length > 1 && o._sliderValue > 0) {
                            const oldFound = buildportfolio.oldThematicTab.selectedFunds[rt].find(x => x.ticker == o.ticker);
                            if(oldFound){
                              let diff = thematicTabData.find((er) => er.query === rt).sliderValue - buildportfolio.oldThematicData.find((er) => er.query === rt).sliderValue;
                              let ratio = oldFound._sliderValue == 0 ? 0 : oldFound._sliderValue / buildportfolio.oldThematicData.find((er) => er.query === rt).sliderValue;
                              let wt = diff * ratio;
                              o._sliderValue = oldFound._sliderValue + wt;
                              // console.log('pratik 121', oldFound, diff, ratio);
                            }
                          }
                        }
                      })
                    }
                    //USA fund list sliderValue
                    // console.log(`${fd.query} fund list sliderValue`);
                    // thematicTabData.map((l) => {
                    //   if (l.query === rt) {
                    //     l.result.funds.map((w) => {
                    //       w._sliderValue = 0;
                    //       // const found = getActiveFunds.find((o) => o.ticker === w.ticker);
                    //       if (rt.ticker === w.ticker) {
                    //         // console.log(rt, w.ticker);
                    //         w._sliderValue = l.sliderValue/selectedFunds[rt].length;
                    //       }
                    //     });
                    //   }
                    // });
                    thematicTabData.map((l) => {
                      l.result.funds.map((w) => {
                        w._sliderValue = 0;
                        if (selectedFunds[rt] && typeof selectedFunds[rt] !== 'undefined') {
                          const found = selectedFunds[rt].find((o) => o.ticker === w.ticker);
                          const oldFound = buildportfolio.oldThematicTab.selectedFunds[l.query] && buildportfolio.oldThematicTab.selectedFunds[l.query].find(x => x.ticker == w.ticker);
                          if (found && typeof found !== 'undefined' && (found.ticker === w.ticker)) {
                            if(selectedFunds[l.query] && selectedFunds[l.query].length == 1){
                              w._sliderValue = l.sliderValue/selectedFunds[l.query].length;
                            }
                            else if(selectedFunds[l.query] && selectedFunds[l.query].length > 1 && oldFound && oldFound._sliderValue > 0) {
                              if(oldFound){
                                let diff = l.sliderValue - buildportfolio.oldThematicData.find((er) => er.query === l.query).sliderValue;
                                let ratio = oldFound._sliderValue == 0 ? 0 : oldFound._sliderValue / buildportfolio.oldThematicData.find((er) => er.query === l.query).sliderValue;
                                let wt = diff * ratio;
                                w._sliderValue = oldFound._sliderValue + wt;
                                // console.log('pratik 151', oldFound, diff, ratio);
                              }
                            }
                            else if(selectedFunds[l.query] && selectedFunds[l.query].length > 1 && oldFound && oldFound._sliderValue <= 0) {
                              w._sliderValue = l.sliderValue/selectedFunds[l.query].length;
                            }
                          }
                        }
                      });
                    });

                  }
                }
              });
            }
            else if(qw.sliderValue == 0 && Object.keys(selectedFunds).filter((ty) => (selectedFunds[ty].length > 0)).length > 0){
              console.log('prstik 983')
              // console.log('pratik 21')
              const selFunds = Object.keys(selectedFunds).filter((ty) => (selectedFunds[ty].length > 0));
              const diff = lw.sliderValue - qw.sliderValue;
              // console.log('pratik diff---->', diff);
              selFunds.map((rt) => {
                if (rt) {
                  // console.log('pratik 22')
                  const fd = thematicTabData.find((er) => er.query === rt);
                  // console.log('pratik 23', fd)
                  if (fd) {
                    thematicTabData.find((er) => er.query === rt).sliderValue = lw.sliderValue / selFunds.length;
                  }
                }
              })
            }

          }
        } else {
          // console.log('EQUAL');
        }
        // console.log('-----------------');
      } else {
        // console.log('EQUAL');
      }
    }

    // console.log(buildportfolio.oldBuildportfolioState);
    if (buildportfolio.oldBuildportfolioState && typeof buildportfolio.oldBuildportfolioState !== 'undefined') {
      console.log('prstik 984')

      const { oldBuildportfolioState } = newProps.buildportfolio;
      const oldThematicTabSelectedFunds = oldBuildportfolioState.thematicTab.selectedFunds;

      if (oldThematicTabSelectedFunds && typeof oldThematicTabSelectedFunds !== 'undefined') {

        const qw = (Object.keys(oldThematicTabSelectedFunds));
        const lw = (Object.keys(newProps.selectedFunds));

        if (qw.length !== lw.length) {
          if (lw && lw.length > 0) {
            // console.log(lw);
            const getActiveFunds = Object.keys(newProps.selectedFunds).filter((ty) => newProps.selectedFunds[ty].length > 0);
            // console.log(getActiveFunds);

            if (getActiveFunds.length > 0) {
              console.log('prstik 985')
              getActiveFunds.map((rt) => {
                if (rt) {
                  const fd = thematicTabData.find((er) => er.query === rt);
                  // console.log(fd);
                  if (fd) {
                    // USA selectedFunds sliderValue
                    if (getActiveFunds[rt] && getActiveFunds[rt].length > 0) {
                      getActiveFunds[rt].map((o) => {
                        if (o) {
                          o._sliderValue = thematicTabData.find((er) => er.query === rt).sliderValue/newProps.selectedFunds[rt].length;
                        }
                      })
                    }
                    //USA sliderValue
                    // thematicTabData.find((er) => er.query === rt).sliderValue = newProps.tabHeaderMenu.find((item) => item.key === 'thematicTab').sliderValue/getActiveFunds.length;
                    //USA  fund list sliderValue
                    thematicTabData.map((l) => {
                      console.log('prstik 986')
                      l.result.funds.map((w) => {
                        w._sliderValue = 0;
                        if (newProps.selectedFunds[l.query] && typeof newProps.selectedFunds[l.query] !== 'undefined') {
                          const found = newProps.selectedFunds[l.query].find((o) => o.ticker === w.ticker);
                          if (found && typeof found !== 'undefined' && (found.ticker === w.ticker)) {
                            w._sliderValue = thematicTabData.find((er) => er.query === rt).sliderValue/newProps.selectedFunds[l.query].length;
                          }
                        }
                      });
                    });
                  }
                }
              })
            }
          }
          console.log('-----------------');
        } else {
          console.log('EQUAL');
        }

      }
    }

  }

  menuHandleClick = (key) => {
    if (key && typeof key.key !== 'undefined') {
      const { thematicTab } = this.props;
      const { weightingMethodology } = thematicTab;
      thematicTab.weightingMethodology = key.key;
      const { setBuildPortfolioState } = this.props;
      if (setBuildPortfolioState) {
        setBuildPortfolioState({ thematicTab });
      }
    }
  }

  getCardOptions = (menus) => {
    const { thematicTab } = this.props;
    const menuItems = menus.map((u) => {
      return (
        <Menu.Item className={u.className} key={u.label} disabled={u.disabled} style={u.style}>
          {u.tooltip ? u.tooltip(<span>{u.icon}{u.label}</span>) : (
            <span>{u.icon}{u.label}</span>
          )}
        </Menu.Item>
      );
    });
    return (
      <Menu
        className="folder-content-card-menu"
        mode="horizontal"
        defaultSelectedKeys={[]}
        onClick={this.menuHandleClick}
      >
        <Menu.Item className="default-menu" key="Weighting Methodology" disabled>
          Initial Weight
        </Menu.Item>
        {menuItems}
      </Menu>
    )
  }

  getCardTitle = (title) => {
    return (
      <div className="folder-content-card-title">
        {title}
      </div>
    )
  };

  getSelectedThemes = (themes, showSearchedQuery, mode) => {
    if (mode === 'new') {
      this.setState({ queryFromThemeList: themes });
    }
    this.setState({ selectedThemes: themes, showSearchedQuery }, () => {
      const { thematicBatchRequest, searchForNewQueryRequest } = this.props;
      if (thematicBatchRequest) {
        thematicBatchRequest({ query: 'pf_builder_themes', themes: this.state.selectedThemes, mode });
      }
    });
  }

  searchNewTheme = (themes, showSearchedQuery, mode) => {
    if (mode === 'new') {
      this.setState({ queryFromThemeList: themes });
    }
    this.setState({ selectedThemes: themes, showSearchedQuery }, () => {
      const { searchForNewQueryRequest } = this.props;
      if (this.state.updatedThematicTabData.length > 0) {
        // console.log(this.state.updatedThematicTabData);
        const { forQuery, } = this.state.updatedThematicTabData[0];
        const forQueryCapitalCase = convertToCapitalCase(forQuery); // dont change this!
        // console.log(forQueryCapitalCase, forQuery, this.state.selectedThemes[0]);

        if (searchForNewQueryRequest) {
          addEventToAnalytics('Builder Theme Click','Builder Theme Click','BUILDER_THEME_CLICK',{ theme: convertToCapitalCase(this.state.selectedThemes[0]) },false);
          searchForNewQueryRequest({
            query: 'pf_builder_query',
            q: convertToCapitalCase(this.state.selectedThemes[0]),
            forQuery: forQueryCapitalCase,
            forTab: 'thematicTab',
            mode: 'new',
            themes: this.state.selectedThemes,
          });
        }
      }
    });
  }

  getUpdatedThematicTabData = (dataList) => {
    // console.log(dataList);
    this.setState({
      updatedThematicTabData: dataList,
    });
  }

  render() {
    const { themesList, themeFundsLoading, thematicTabLoading, thematicTabData, weightingMethodology, thematicTab, activeTab, topTabHeaderMenu } = this.props;
    const { selectedThemes, showSearchedQuery, queryFromThemeList, updatedThematicTabData } = this.state;

    return (
      <React.Fragment>
        <Row className="folder-content-container" style={{ paddingRight: 5 }}>
          <div className="thematic-section custom-scroll">
            <Card
              title={this.getCardTitle('Search and Select Funds')}
              extra={this.getCardOptions(buildPortfolioMenus)}
              className="folder-content-card-wrapper thematic-tab-content-container"
            >
              <div className="thematic-radio-list-design-container">
                <div style={{ width: '100%', display: 'block'}}>
                  {
                    (themeFundsLoading ? (
                      <CardLoader style={{ padding: '0 15px' }} className="skeleton-bg-transparent" minHeight={302} />
                    ) : (
                      <ThematicRadioFunds
                        queryFromThemeList={this.state.queryFromThemeList}
                        showSearchedQuery={showSearchedQuery}
                        topTabHeaderMenu={topTabHeaderMenu}
                        activeTab={activeTab}
                        data={thematicTabData}
                        getUpdatedThematicTabData={this.getUpdatedThematicTabData}
                        weightingMethodology={weightingMethodology}
                        thematicTab={thematicTab}
                      />
                    ))
                  }
                </div>
                <div className="thematic-radio-button-design-container">
                  <RadioButtonList
                    dataList={themesList}
                    getSelectedThemes={(themes) => this.searchNewTheme(themes, true, 'new')}
                    selectedThemes={selectedThemes}
                  />
                </div>
              </div>
            </Card>
          </div>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ buildportfolio, loading }) => ({
  buildportfolio,
  themesList: buildportfolio.thematicTab.themes,
  thematicTab: buildportfolio.thematicTab,
  thematicTabData: buildportfolio.thematicTab.data,
  selectedThemes: buildportfolio.thematicTab.selectedThemes,
  selectedFunds: buildportfolio.thematicTab.selectedFunds,
  themeFundsLoading: createLoadingSelector(['THEMATIC_BATCH', 'PRE_SELECTED_THEMATIC_TICKERS'])({ loading }),
  preSelectedThemesLoading: createLoadingSelector(['PRE_SELECTED_THEMATIC_TICKERS'])({ loading }),
})

const mapDispatchToProps = {
  setBuildPortfolioState,
  thematicBatchRequest,
  searchForNewQueryRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThematicRadioContent);

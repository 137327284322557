import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { CaretDownOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { Tooltip, UncontrolledTooltip } from 'reactstrap';
import { Row, Col } from 'antd';

import { setUniverse, refreshFundsUniverseState } from 'actions/discovery';
import { fetchWeightsData } from 'actions/weights';
import { getPremiumFeatureDetails } from 'apis/test-drive';

import { addEventToAnalytics } from 'Utils';
import Toggle from 'components/Toggle';
import PButton from 'components/PButton';

import SelectorDropdown from './SelectorDropdown';
import { RETAIL_USERS_ROLES } from '../../Constant/FormData';

const classNames = require('classnames');

class UniverseSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      universe: props.fundsUniverse,
      _isMoreStocksClicked : props.isMoreStocksClicked,
      _showGoBackSection : props.showGoBackSection,
      premiumModal: false,
      notify: false,
      premiumFeature: false,
      checkMsg: false,
      tooltipOpen: false,
      tooltipOpenMF: false,
      tooltipOpenMP: false,
      tooltipOpenSMA: false,
      tooltipOpenCEF: false,
      tooltipOpenShare: false,
      modalActionType: ''
    };
  }

  componentWillMount() {
    const { loggedIn, scoreAttrs } = this.props;
    if(loggedIn && _.isEmpty(scoreAttrs))  {
      this.props.fetchWeightsData();
    }
  }

  componentWillUnmount() {
    const { refreshFundsUniverseState } = this.props;
    refreshFundsUniverseState();
  }

  componentDidUpdate(prevProps){
    const {fundsUniverse,isMoreStocksClicked,showGoBackSection,showGoBackSectionClicked}=this.props;
    if(prevProps['fundsUniverse']!==fundsUniverse){
      this.setState({
        universe:fundsUniverse,
        tooltipOpenShare:false,
        _showGoBackSection:false
      })
    }
    if(prevProps['isMoreStocksClicked']!==isMoreStocksClicked){
      this.setState({
        _isMoreStocksClicked:isMoreStocksClicked,
        _showGoBackSection:showGoBackSection,
        tooltipOpenShare:false
      })
    }
    if(prevProps['showGoBackSectionClicked']!==showGoBackSectionClicked){
      this.setState({
        universe:fundsUniverse,
      })
    }
  }

  checkPremium = (toggleState, val) => {
    this.universeHandler(toggleState, val);
  }

  universeHandler = (toggleState, value) => {
    let { universe } = this.state;
    const val = value;
    // const controlledSwitches = ['sma','cef', 'mp'];
    if (toggleState) {
      // NOTE: Toggle 'ON', add 'val' in 'universe'
      universe.push(val);
      this.setState({ universe: _.union(universe, val), checkMsg: false }, () => this.queryHandler(val))
    } else {
      // NOTE: Toggle 'OFF', remove 'val' from 'universe'

      // Previously it was ->
      // universe = universe.filter(val => !["share", "udc", "basket"].includes(val)); // to meet aleast one selected condtion
      universe = universe.filter(val => !["udc", "basket"].includes(val)); // to meet aleast one selected condtion

      // const indexOfUdc =  oldUniverse.findIndex(val=> val === "udc");
      if (universe.length > 1) {
        _.remove(universe, (n) => n === val);
        this.setState({ universe, checkMsg: false }, () => this.queryHandler(val));
      } else {
        // NOTE: 'universe' is empty and 'atleast one universe need to be selected' so pushing back 'val' into 'universe'
        // and showing 'checkMsg' text
        universe.push(val);
        this.setState({
          universe: _.union(universe, val),
        });
        this.setState({ checkMsg: true })
      }
    }

    if (val === 'udc') {
      addEventToAnalytics('Using Default Criteria','Using Default Criteria','USING_DEFAULT_CRITERIA',{ [val]: toggleState });
    } else {
      addEventToAnalytics('Universe Toggle','Universe Toggle','UNIVERSE_TOGGLE',{ [val]: toggleState });
    }
  }

  queryHandler = (val,search_type) => {
    const { setUniverse, query, history, queryHandler } = this.props;
    if (setUniverse) {
      setUniverse({
        universe: this.state.universe,
        isMoreStocksClicked:false,
        showGoBackSection:false,
        showGoBackSectionClicked:false,
      });
    }
    if (history) {
      history.push('/securities');
    }
    if (queryHandler) {
      queryHandler(query, 'search_bar');
    }
  }

  scoreAttrsWeightHandler = (values) => {
    // console.log(values);
    this.checkPremium(true, 'udc')
  }

  validQuery = (query) => query.trim().length > 0

  render() {
    const { loggedIn, premiumUser, bigScreen, desktopScreen } = this.props;
    const StocksTooltip= this.state._isMoreStocksClicked && (this.state.universe && this.state.universe.length===1 && this.state.universe.includes('share'));
    // const profile_self_role =  window.localStorage.getItem('profile_self_role') ? window.localStorage.getItem('profile_self_role') : 'loggedOut';
    return (
      <Fragment>
        <Row className="universe-section">
          <Col xs={12} sm={12} md={12} lg={desktopScreen ? 8 : 4} xl={desktopScreen ? 8 : 4} className="universe-section__item">
            <div className="d-flex align-items-center">
              <Toggle
                size="small"
                style={{ marginRight: 5 }}
                checked={this.state.universe.includes('etf,etn')}
                onChange={(value) => this.universeHandler(value, 'etf,etn')}
              />
              <span className="check-label" style={{ paddingRight: 5 }} onClick={(e) => e.preventDefault()}>
                ETF
              </span>
              <span className="check-label" id="etf">
                <i class="fal fa-info-circle"></i>
              </span>
              <Tooltip className="col-grey" placement="right" isOpen={this.state.tooltipOpen} target="etf" toggle={() => this.setState({ tooltipOpen: !this.state.tooltipOpen })}>
                Exchanged Traded Fund
              </Tooltip>
            </div>

          </Col>
          <Col xs={12} sm={12} md={12} lg={desktopScreen ? 8 : 4} xl={desktopScreen ? 8 : 4} className="universe-section__item">
            <div className="d-flex align-items-center">
              <Toggle
                size="small"
                style={{ marginRight: 5 }}
                checked={this.state.universe.includes('mf')}
                onChange={(value) => this.universeHandler(value, 'mf')}
              />
              <span className="check-label" style={{ paddingRight: 5 }} onClick={(e) => e.preventDefault()}>
                MF
              </span>
              <span className="check-label" id="mfu">
                <i class="fal fa-info-circle"></i>
              </span>
              <Tooltip className="col-grey"  placement="right" isOpen={this.state.tooltipOpenMF} target="mfu" toggle={() => this.setState({ tooltipOpenMF: !this.state.tooltipOpenMF })}>
                Mutual Fund
              </Tooltip>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={desktopScreen ? 8 : 4} xl={desktopScreen ? 8 : 4} className="universe-section__item">
            <div className="d-flex align-items-center">
              <Toggle
                size="small"
                style={{ marginRight: 5 }}
                // checked={this.state.cef}
                checked={this.state.universe.includes('cef')}
                // onChange={(value) => this.checkPremium(value, 'cef')}
                onChange={(value) => this.universeHandler(value, 'cef')}
              />
              <span className="check-label" style={{ paddingRight: 5 }} onClick={(e) => e.preventDefault()}>
                CEF
              </span>
              <span className="check-label" id="ceIcon">
                <i class="fal fa-info-circle"></i>
              </span>
              <Tooltip className="col-grey"  placement="right" isOpen={this.state.tooltipOpenCEF} target="ceIcon" toggle={() => this.setState({ tooltipOpenCEF: !this.state.tooltipOpenCEF })}>
                Closed End Funds
              </Tooltip>
            </div>
          </Col>

          <Col xs={12} sm={12} md={12} lg={desktopScreen ? 8 : 4} xl={desktopScreen ? 8 : 4} className="universe-section__item">
            {process.env.REACT_APP_SUB_DOMAIN === 'advisor' && <div className="d-flex align-items-center">
              <PButton
                size="small"
                type="toggle"
                featureName="sma_switch"
                premiumAction={true}
                style={{ marginRight: 5 }}
                // checked={this.state.sma}
                checked={this.state.universe.includes('sma')}
                onChange={(value) => this.checkPremium(value, 'sma')}
              />
              <span className="check-label" style={{ paddingRight: 5 }} onClick={(e) => e.preventDefault()}>
                SMA
              </span>
              <span className="check-label" id="smaIcon">
                <i class="fal fa-info-circle"></i>
              </span>
              <Tooltip className="col-grey"  placement="right" isOpen={this.state.tooltipOpenSMA} target="smaIcon" toggle={() => this.setState({ tooltipOpenSMA: !this.state.tooltipOpenSMA })}>
                Separately Managed Accounts are a portfolio of securities managed by a professional investment firm
              </Tooltip>
            </div>}
          </Col>

          <Col xs={12} sm={12} md={12} lg={desktopScreen ? 8 : 4} xl={desktopScreen ? 8 : 4} className="universe-section__item models">
            {process.env.REACT_APP_SUB_DOMAIN === 'advisor' && <div className="d-flex align-items-center">
              <PButton
                size="small"
                type="toggle"
                featureName="mp_switch"
                premiumAction={true}
                style={{ marginRight: 5 }}
                // checked={this.state.mp}
                checked={this.state.universe.includes('mp')}
                onChange={(value) => this.checkPremium(value, 'mp')}
              />
              <span className="check-label" style={{ paddingRight: 5 }} onClick={(e) => e.preventDefault()}>
                Models
              </span>
              <span  className="check-label" id="mpIcon">
                <i class="fal fa-info-circle"></i>
              </span>
              <Tooltip className="col-grey"  placement="right" isOpen={this.state.tooltipOpenMP} target="mpIcon" toggle={() => this.setState({ tooltipOpenMP: !this.state.tooltipOpenMP })}>
                Model portfolios are a crafted bundle of diversified funds, grouped together to provide an expected return with a corresponding amount of risk
              </Tooltip>
            </div>}
          </Col>
          <Col xs={12} sm={12} md={12} lg={desktopScreen ? 8 : 4} xl={desktopScreen ? 8 : 4} className="universe-section__item">
              <div className="d-flex align-items-center">
                <Toggle
                  size="small"
                  style={{ marginRight: 5 }}
                  checked={this.state.universe.includes('share')}
                  onChange={(value) => this.universeHandler(value, 'share')}
                />
                <span className="check-label" style={{ paddingRight: 5 }} onClick={(e) => e.preventDefault()}>
                  Stocks
                </span>
                <span className='stock-new-label' id='share'>
                  NEW
                </span>
                {/* StocksTooltip ?
                <Tooltip className="col-grey" placement="right" isOpen={!this.state.tooltipOpenShare} target="share" toggle={() => this.setState({ tooltipOpenShare: this.state.tooltipOpenShare })}>
                  Showing stock results only <i className='fa fa-times fa-md' style={{paddingLeft:'4px',cursor:'pointer'}} onClick={()=> this.setState({ tooltipOpenShare: !this.state.tooltipOpenShare })}/>
                </Tooltip>:''
                */}
              </div>
          </Col>
          {/* <Col xs={12} sm={12} md={12} lg={desktopScreen ? 8 : 4} xl={desktopScreen ? 8 : 4} className="universe-section__item"/> */}
        </Row>
        {
          this.state.checkMsg && <div className="check-error">Note: At least one option should be selected.</div>
        }
        {process.env.REACT_APP_SUB_DOMAIN === "advisor" && <Row className="universe-section user-criteria-wrap" style={{ paddingTop: 20 }}>
          <Col className="universe-section__item" style={{ margin: 0 }}>
            <div className="d-flex align-items-center">
              <PButton
                size="small"
                type="toggle"
                featureName="rank_using_default_selector_criteria"
                premiumAction={true}
                style={{ marginRight: 5 }}
                checked={this.state.universe.includes('udc')}
                onChange={(value) => this.checkPremium(value, 'udc')}
              />
              <span className="check-label label" style={{ margin: 0, lineHeight: 1.3 }} onClick={(e) => e.preventDefault()}>
                <span className="d-flex">Rank Using {(loggedIn && premiumUser) ? (
                  <SelectorDropdown
                    className="font-color-charcoal"
                    hideRadioButton={true}
                    refreshSearch={() => this.checkPremium(true, 'udc')}
                    scoreAttrsWeightHandler={(values) => this.scoreAttrsWeightHandler(values)}
                  /> ) : (
                  <PButton
                    size="small"
                    type="anchor"
                    featureName="rank_using_default_selector_criteria"
                    premiumAction={true}
                    style={{ marginRight: 5 }}
                    checked={this.state.universe.includes('udc')}
                    onChange={(value) => this.checkPremium(value, 'udc')}
                  >
                    <span className="discover-myselector-dropdown">My Selector <CaretDownOutlined className="down-arrow" /></span>
                  </PButton>)}
                </span>
              </span>
            </div>
          </Col>
        </Row>}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ auth, sfm, investment, discovery, profile, global, weights }) => ({
  loading: investment.loading,
  // data: discovery.data,
  fundsUniverse: discovery.universe,
  loggedIn: auth.loggedIn,
  premiumUser: profile.user && profile.user.premiumUser,
  bigScreen: global.bigScreen,
  desktopScreen: global.desktopScreen,
  scoreAttrs: weights.scoreAttrs,
  isMoreStocksClicked:discovery.isMoreStocksClicked,
  showGoBackSection:discovery.showGoBackSection,
  showGoBackSectionClicked:discovery.showGoBackSectionClicked,
})

const mapDispatchToProps = {
  setUniverse,
  refreshFundsUniverseState,
  fetchWeightsData,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UniverseSection)

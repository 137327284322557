import React, { useContext } from 'react'
import { Row, Col } from 'reactstrap'
// import moment from 'moment';
import PortfolioContext from '../PortfolioContext'
import { Disclosures } from './Disclosures'
import {
  Coverage_poweredByLogo,
  ProposalCoverImage
} from '../../../../common/constant'

// const Logo = Coverage_Logo;
const poweredByLogo = Coverage_poweredByLogo

const IndexPage = props => {
  let { disclosures, createdDate } = props
  const reportData = useContext(PortfolioContext)
  const portfolioName = reportData.portfolioName || null
  const logo = reportData.portfolio.logo

  return (
    <React.Fragment>
      <div className="page">
        <div className="content cover">
          <div className="pr-v2-cover-page">
            <div>
              <img
                className={'cover-logo'}
                src={ProposalCoverImage}
                alt=""
                width={120}
                height={120}
              />
              <div>
                <h1 className="title">Portfolio Report</h1>
                {createdDate && (
                  <p className="date">Data as of {createdDate}</p>
                )}
              </div>
              <Row>
                <Col>
                  {portfolioName && (
                    <p style={{ fontWeight: 'bold', color: '#fff' }}>
                      {portfolioName}
                    </p>
                  )}
                  <p className="paragraph">
                    Here is the summary report showing the performance of your
                    current portfolio.
                  </p>
                  <Disclosures
                    disclosure={disclosures}
                    createdDate={createdDate}
                  />
                </Col>
              </Row>
            </div>
            {/* <div className="glossary-footer text-justify">
              <span className="pr-v2-medium-paragraph">
                There is no guarantee that any investment strategy illustrated
                will be successful or achieve any particular level of results.
                Please review the disclosures at the end of this document and
                consult your financial advisor for more information.
              </span>
              <br />
            </div> */}
          </div>
        </div>
        <div className="cover-footer">
          <div className="glossary-footer text-justify">
            <div style={{ width: '100%' }}>
              {props.logo && (
                <img
                  className={'cover-logo'}
                  style={{ height: `75px` }}
                  src={props.logo}
                  alt=""
                />
              )}
            </div>

            <hr style={{ border: '1px solid #E4E7EC', margin: '20px  0' }} />

            <p className="pr-v2-medium-paragraph">
              There is no guarantee that any investment strategy illustrated
              will be successful or achieve any particular level of results.
              Please review the disclosures at the end of this document and
              consult your financial advisor for more information.
            </p>
            <br />
            {/* <span className="pr-v2-medium-paragraph">Magnifi LLC is an SEC registered investment adviser relying on rule 203A-2(e) for registration. Reference to registration with the Securities and Exchange Commission (“SEC”) does not imply that the SEC has endorsed or approved the qualifications of the firm to provide any advisory services described or that the firm has attained a level of skill or training.</span> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default IndexPage

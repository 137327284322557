import React, { Component, Fragment } from 'react';
import { Cell, PieChart, Pie } from 'recharts';
import NotApplicable from 'components/NotApplicable';

const formatNumberValue = (value, fraction) => {
  const leftPart = (value + '').split('.')[0];

  if (leftPart.length >= 4 && leftPart.length < 7) {
    return (value / 1000).toFixed(fraction) + 'K';
  } else
    if (leftPart.length >= 7 && leftPart.length < 10) {
      return (value / 1000000).toFixed(fraction) + 'M';
    } else
      if (leftPart.length >= 10) {
        return (value / 1000000000).toFixed(fraction) + 'B';
      } else {
        return value.toFixed(fraction)
      }
};

const RenderOdometer = (props) => {
    const { tickerData, queryVar, width, key } = props;
    const { view_name, Secondary_view_name, decimal, suffix } = queryVar;
    console.log(props);

    if (!tickerData) return <NotApplicable />;

    console.log(tickerData);
    const currentMultiplier = tickerData.multiplier || 1;
    const currentSuffix = view_name !== "Volume" ? (tickerData.suffix || suffix || '') : '';
    const currentDecimal = tickerData.decimal || decimal || 1;
    let name = view_name + (Secondary_view_name ? ` (${Secondary_view_name})` : '');
    name = name.replace('ticker', queryVar.data);

    let min, max, value;
    if (view_name === 'Return Quality') {
      // implementing like seach after discussing with business
      const subField = tickerData.find(type => Secondary_view_name.includes(type.name));
      if(!subField) return <NotApplicable />
      min = (subField.min * 100).toFixed(2);
      max = (subField.max * 100).toFixed(2);
      value = (subField.value * 100).toFixed(2);
    } else {
      min = tickerData.min;
      max = tickerData.max;
      value = tickerData.value;
    }

    let minWithMultiplier = (min * currentMultiplier);
    let maxWithMultiplier = (max * currentMultiplier);
    let valueWithMultiplier = (value * currentMultiplier);
    let minRender, maxRender, valueRender;

    if (queryVar.chart_type === 'odometer') {
      if (view_name === 'Assets') {
        minRender = formatNumberValue((min / 1000), currentDecimal);
        maxRender = formatNumberValue((max / 1000), currentDecimal);
        valueRender = formatNumberValue((value / 1000), currentDecimal);
      } else if (view_name === 'Return Quality') {
        // implementing like seach after discussing with business
        const subField = tickerData.find(type => Secondary_view_name.includes(type.name));
        minRender = (subField.min * currentMultiplier).toFixed(currentDecimal) + (currentSuffix || '');
        maxRender = (subField.max * currentMultiplier).toFixed(currentDecimal) + (currentSuffix || '');
        valueRender = (subField.value * currentMultiplier).toFixed(currentDecimal) + (currentSuffix || '');
      } else {
        minRender = min + currentSuffix;
        maxRender = max + currentSuffix;
        valueRender = formatNumberValue(valueWithMultiplier, currentDecimal) + currentSuffix;
      }
    } else {
      minRender = min + currentSuffix;
      maxRender = max + currentSuffix;
      valueRender = valueWithMultiplier.toFixed(currentDecimal) + currentSuffix;
    }

    const deTailsWidth = width || '100px'
    return (
      <div key={key} className="siw-meter print-wrapper">
        <div className="siw-meter-title" title={name}>{name}</div>
        <GaugeChart
          chartWidth={deTailsWidth}
          value={value}
          max={max}
          min={min}
        />
      <div className="d-flex meter-value" style={{ width: deTailsWidth }}>
          <span className="siw-meter-min">
            {minRender}
          </span>
          <span className="siw-meter-value">
            {valueRender}
          </span>
          <span className="siw-meter-max">
            {maxRender}
          </span>
        </div>
      </div>
    );
};

export default RenderOdometer;

const GaugeColors = [
  '#65b8f7', '#55a9e8', '#1b8be0',
];

const GaugeChart = ({ value, min, max, pc, chartWidth }) => {
    const width = chartWidth || 100;
    const sumValues = 300;
    const colorData = GaugeColors.slice(0, 3).map(e => ({ value: 100, color: e }));
    const chartValue = sumValues*(pc !== undefined ? pc : (value-min)/(max - min));

    const arrowData = [
      { value: chartValue },
      { value: 0 },
      { value: sumValues - chartValue }
  ];

  const pieProps = {
    startAngle: 180, endAngle: 0,
      cx: width / 2, cy: width / 2
  };

  const pieRadius = {
    innerRadius: (width / 2) * 0.60,
    outerRadius: (width / 2) * 0.95
  };

  const Arrow = ({ cx, cy, midAngle, outerRadius }) => {
      const RADIAN = Math.PI / 180;
      const sin = Math.sin(-RADIAN * midAngle),
            cos = Math.cos(-RADIAN * midAngle);
      const mx = cx + (outerRadius - width * 0.03) * cos,
            my = cy + (outerRadius - width * 0.03) * sin;
      return (
          <g>
              <circle cx={cx} cy={cy} r={3} fill="#999" stroke="none"/>
              <path d={`M${cx},${cy}L${mx},${my}`} strokeWidth="2" stroke="#999" fill="none" strokeLinecap="round"/>
          </g>
      );
  };

  return (
    <PieChart width={width} height={(width / 2) + 5} margin={{top: 0, right: 0, left: 0, bottom: 5}} aspect={1.5}>
        <Pie data={colorData} dataKey="value" fill="#8884d8" isAnimationActive={false}
            { ...pieRadius } { ...pieProps } >
            { colorData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colorData[index].color} />
                )) }
        </Pie>
        <Pie
            stroke="none" fill="none"
            activeIndex={1} activeShape={ Arrow }
            data={ arrowData } dataKey="value"
            outerRadius={ pieRadius.innerRadius }
            { ...pieProps }
        />
    </PieChart>
  );
};

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import style from './Alert.module.scss'
import { Spinner } from 'shared-components/'
import { Form, FormGroup, Label, Alert } from 'reactstrap';
import { getListAlerts, updateAlert } from 'actions/alerts';
import { refreshApexStateRequest } from 'actions/accounts';
import { uploadCustomLogo } from 'apis/profile'
import Toggle from '../../../../components/Toggle';
import DownOutlined from '@ant-design/icons/DownOutlined';
import UpOutlined from '@ant-design/icons/UpOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { Button, Input } from 'antd';
import moment from  'moment';
import { isEmpty, includes, get, toLower, startCase } from 'lodash';
import { failedSetDefaultAccount } from 'actions/broker';

const { Search } = Input;
class Alerts extends Component {
  state = {
    isOpenArchive: false,
    recent: [],
    archive: []
  }

  componentDidMount = () => {
    const { apex_account_id } = this.props;
    if(apex_account_id){
      this.props.getListAlerts({apex_account_id});
    }
  }

  componentWillReceiveProps = (nxtProps) => {
    if(nxtProps.alerts !== this.props.alerts){
      let filtered = nxtProps.alerts.reduce((acc, val) => {
        if(val.is_active){
          acc.recent.push(val);
        }else{
          acc.archive.push(val);
        }
        return acc;
      }, {
        recent: [],
        archive:[]
      });
      this.setState(filtered);
    }
    if(nxtProps.apex_account_id !== this.props.apex_account_id){
      this.props.getListAlerts({apex_account_id: nxtProps.apex_account_id});
    }
  }

  switchEmailAlerts = () => {

  }

  toggleArchive = () => {
    this.setState({
      isOpenArchive: !this.state.isOpenArchive
    });
  }

  onSearch = (e) => {
    let value = e.target.value;
    const { alerts } = this.props;
    if(value === ""){
      const values = alerts.filter((alert) => alert.is_active);
      this.setState({
        archive: values
      });
    }else{
      let filtered = alerts.filter((alert) => alert.is_active && includes(alert.title.toLowerCase(), value.toLowerCase()));
      this.setState({
        archive: filtered
      });
    }
  }

  handleArchiveClick = (e) => {
    const id = e.target.id;
    const { alerts, refreshApexStateRequest } = this.props;
    let filtered = alerts.filter((alert) => parseInt(id) === alert.id);
    if(filtered.length){
      this.props.updateAlert({notification_id: e.target.id }, {...filtered[0], is_active: false });
    }
  }

  render() {
    const { isLoading, error } = this.props;
    const { isOpenArchive, recent } = this.state;
    const { handleArchiveClick } = this;
    const Waiting = () => {
      return (
        <div style={{paddingTop:`30px`}} className="w-100">
          <div className="text-secondary text-center mt-5">
            <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5"></i>
          </div>
        </div>
      )
    }
    const archive = [...this.state.archive]
    return (
      <>
        {isLoading && <Waiting/>}
        <div className={style.container}>
        <div
          className={style.container}>
            <div className={style.recentAlertBox}>
              {!isLoading && !error ?<span className={style.subHeader}>Recent Alerts</span> : null}
              {!isLoading && <div className={`d-flex align-items-center relative ${style.emailAlertBtn}`}>
                <Toggle size="small" style={{ marginRight: 5 }} disabled={true} checked={false} onChange={this.switchEmailAlerts} />
                <span className={style.emailLabel}>Get Email Alerts</span>
              </div>}
              {!isLoading && error ?
                <div className="pt-5">
                  <Alert color="danger">
                    {error}
                  </Alert>
                </div>
                :  null
              }
              {!isLoading && !error ?
                <div className={style.recentAlertContainer}>
                  {recent.length ? recent.map((alert) =>
                    <FormGroup className={style.formGroupPanel} key={`recent${alert.id}`}>
                      <div className={`${style.mainTitleLable} ${style.common}`}>{startCase(alert.title)}</div>
                      <Label className={style.Lable}>{alert.description}<span id={alert.id} onClick={handleArchiveClick} style={{ cursor: 'pointer' }}>Archive</span></Label>
                      <div className={style.receivedtime} >{moment(alert.created_at).fromNow()}</div>
                  </FormGroup>)
                  :
                  <FormGroup className={style.formGroupPanel}>
                    <div className={`${style.mainTitleLable}`}>No Alerts Yet!</div>
                  </FormGroup>}
                  {/* <FormGroup className={style.formGroupPanel}>
                    <div className={`${style.mainTitleLable} ${style.pending}`}>Awating Approval</div>
                    <Label className={style.Lable}>Please Provide an address proof to activate your account.<span>Archive</span></Label>
                    <div className={style.receivedtime} >24 mins ago</div>
                  </FormGroup>
                  <FormGroup className={style.formGroupPanel}>
                    <div className={`${style.mainTitleLable} ${style.success}`}>SuccessFull Upload!</div>
                    <Label className={style.Lable}>Please Provide an address proof to activate your account.<span>Archive</span></Label>
                    <div className={style.receivedtime} >24 mins ago</div>
                  </FormGroup> */}
                </div>
                : null}

            </div>
            {!isLoading && !error ?
            <div className={`${style.recentAlertBox} view-alert-archive`}>
              <span className={style.subHeader}>Alert Archives</span>
              <div className={`d-flex align-items-center view-archive ${style.emailAlertBtn}`}>
              <button
                className={style.btnIcon}
                disabled={!archive.length} shape="round" onClick={this.toggleArchive} >VIEW {isOpenArchive ? <UpOutlined /> : <DownOutlined />} </button>
              </div>
              {isOpenArchive && <div>
                {/*<Search*/}
                {/*  style={{marginTop: 40, borderRadius: 20, fontSize: 15 }} */}
                {/*  placeholder="Search through the alert archive"*/}
                {/*  // onSearch={this.onSearch}*/}
                {/*  onChange={this.onSearch}*/}
                {/*/>*/}
                <div className={style.archiveAlertContainer}>
                {
                  archive.length ? archive.map(alert => (
                    <FormGroup className={style.formGroupPanel}  key={`archive${alert.id}`}>
                    <div className={style.mainTitleLable}>{startCase(alert.title)}</div>
                    <Label className={style.Lable}>{alert.description}</Label>
                    <div className={style.receivedtime} >{`${moment(alert.created_at).format("DD MMMM YYYY")} |  ${moment(alert.created_at).format("hh:mm:ss A")}`}</div>
                    </FormGroup>
                  )) :
                  <FormGroup className={style.formGroupPanel}>
                    <div className={`${style.mainTitleLable}`}>No Result Found!</div>
                    <Label className={style.Lable}>No order has been executed yet.</Label>
                  </FormGroup>
                }
                </div>
                {/* <FormGroup className={style.formGroupPanel}>
                  <div className={style.mainTitleLable}>Link your bank account and start investing</div>
                  <div className={style.receivedtime} >2o Feb, 2021 | 10:09:01 AM</div>
                </FormGroup>
                <FormGroup className={style.formGroupPanel}>
                  <div className={style.mainTitleLable}>Link your bank account and start investing</div>
                  <div className={style.receivedtime} >2o Feb, 2021 | 10:09:01 AM</div>
                </FormGroup> */}
              </div>}
            </div> : null}

          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  alerts: state.alerts.alertsData.data,
  error: state.alerts.alertsData.error,
  isLoading: state.alerts.alertsData.isLoading,
  apex_account_id: get(state.profile.profile, 'apex.account[0].id')
})

const mapDispatchToProps = {
  getListAlerts,
  updateAlert,
  refreshApexStateRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(Alerts)

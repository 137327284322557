import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import { Formik } from 'formik'
import * as Yup from 'yup'
import { get } from 'lodash'
import { Spinner } from 'shared-components';

import { REDIRECT_DOMAIN_PATH } from 'DataSet';
import PasswordResetForm from './PasswordResetForm';
import { changePassword, verifyChangePwdToken, saveverifyChangePwdToken, successfulSetPassword } from 'actions/auth';
import SetPasswordSuccessModal from './SetPasswordSuccessFul';

import {
  hasLowerCaseRegex,
  hasUpperCaseRegex,
  hasDigitRegex,
  hasSpecialCharactersRegex,
} from 'Utils';

const magnifiLogo = "/assets/images/magnifi_by_tifin.svg";
const magnifiInvestResponsiblyLogo = '/assets/images/register-magnifi-invest-resp-logo.svg';


class PasswordReset extends Component {
      state = {
        modal: true,
        token: '',
        email: '',
        verifyTokenError: false,
        verifyTokenErrorMsg: '',
        changePasswordErrMsg: '',
        loading: true
      }

    initialValues = {
        password: '',
        rePassword: '',
      }

      datatoken = {}

      componentWillMount() {
        let params, q;
        if(this.props.location.search){
          params = new URLSearchParams(this.props.location.search);
          q = params.get('q');
          if (q && q !== '') {
            this.setState({ token: q }, ()=>{
              this.props.verifyChangePwdToken(this.state.token, this.verifyTokenStatus)
            });
          } else { this.props.history.push('/') }
        }  else { this.props.history.push('/') }
      }

      componentDidMount() {
        document.getElementsByTagName('body')[0].style.paddingTop = 0;
      }

      verifyTokenStatus = () => {
        this.setState({ loading: false })
        // if (status === 'success') this.setState({ email: msg.email  });
        // if (status === 'failure') this.setState({ verifyTokenError: true, verifyTokenErrorMsg: msg.message });
      }

      changePasswordStatus = (status, msg) => {
        if (status === 'success') this.props.history.push('/login')
        if (status === 'failure') this.setState({ changePasswordErrMsg: msg.message });
      }

      handleSubmit = (values, { setSubmitting }) => {
        const data = {
          ...values,
          verifypwdtoken : this.props.verifypwdtoken,
        }
        this.props.changePassword({email:'',old_password:'',values:{password:data.password},token:data.verifypwdtoken});
        setSubmitting(false);
      }

      registerSchema = Yup.object().shape({
        password: Yup.string()
          .required('New Password is a required field')
          .min(8, 'Password has to be longer than 8 characters!')
          .matches(
            hasLowerCaseRegex,
            'Password should contain at least one lower case letter',
          )
          .matches(
            hasUpperCaseRegex,
            'Password should contain at least one upper case letter',
          )
          .matches(hasDigitRegex, 'Password should contain at least one number')
          .matches(
            hasSpecialCharactersRegex,
            'Password should contain atleast one special character',
          ),
        rePassword: Yup.string()
          .oneOf([Yup.ref('password')], 'Passwords must match')
          .required('Confirm password is a required field')
      })

      handleToggle = () => {
        // const currentPath = get(this.props,'location.pathname','')
        // const pathname = this.props.fromPath || '/'
        // if(pathname!==currentPath) {
        //   this.props.history.push(pathname)
        // }
        // if(this.props.handleClose) {
        //   this.props.handleClose()
        // }
      }

  render() {
    const { verifypwdtoken, setPasswordSuccess } =this.props;

    let renderModal = '';

    if(setPasswordSuccess)
        renderModal = <SetPasswordSuccessModal {...this.props}/>

    let renderDesign = (
        <Formik
        initialValues={this.initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={this.registerSchema}
        render={props => (
          <PasswordResetForm
          formProps={props}
          error={this.props.error}
          changePasswordErrMsg={this.state.changePasswordErrMsg}
          {...this.props}
          />
        )}
      />
    )

      return (
      <React.Fragment>
          <Spinner isFetching={this.state.loading} />
          <Row className="magnifi-auth-page-wrapper">
          <Col md="6" lg="6" className="left-side-panel-container">
            <a className="logo-wrapper" href={REDIRECT_DOMAIN_PATH}>
              <img src={process.env.REACT_APP_SUB_DOMAIN === 'retail' ? magnifiInvestResponsiblyLogo : magnifiLogo} height={70} alt="magnifi" />
            </a>
          </Col>
            <Col md="6" lg="6" className="right-side-panel-container">
                {renderDesign}
            </Col>
            </Row>
            {renderModal}
        </React.Fragment>
      )
  }
}

const mapStateToProps = ( auth ) => ({
  verifypwdtoken: auth.auth.verifypwdtoken,
  setPasswordSuccess: auth.auth.setPasswordSuccess,
  auth
  })

  const mapDispatchToProps = {
    verifyChangePwdToken,
    changePassword,
    saveverifyChangePwdToken,
    successfulSetPassword,
  }

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(PasswordReset))

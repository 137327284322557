import React from 'react';
import { connect } from 'react-redux';

import { Row, Button } from 'reactstrap';

import { Wrapper, WrapperNP, KeyStats1 } from './Common'
import { Transactions } from './Summary'
import { PortfolioGains, Holdings, SecurityReturns, Allocations, ProfitLoss } from './Positions'

import { toggleModal, setSFMState } from 'actions/sfm'
import { setPortfolioState } from 'actions/portfolio';
import { dropFund, setInvestmentState, investmentFetch } from 'actions/investment'
import { getMarketStats } from 'selectors/investment'


class WatchlistContent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.buyHandler = this.buyHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
  }

  componentDidMount() {
    this.props.setInvestmentState({
      portfolioValue : this.props.fundStats.portfolioValue
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.dropFundResponse !== '' && newProps.droppedFund) {
      const { investmentFetch, setInvestmentState } = this.props;
      investmentFetch();
      setInvestmentState({
        dropFundResponse: '',
        droppedFund: false,
      });
    }
  }

  buyHandler = (ticker) => {
    // console.log(ticker);
    // setSFMState({
    //   items,
    // });
  }

  deleteHandler = (item) => {
    const { dropFund, setPortfolioState } = this.props;
    dropFund({
      ticker: item.symbol,
    });
    setPortfolioState({ summaryDrawer: false });
  }

  selectHandler = (item, index) => {
    const { selectHandler } = this.props;
    selectHandler(item, index);
  }

  render() {
    const { fundStats, positionStats, acView } = this.props;
    const hdExpand = acView === 'holdings',
          txExpand = acView === 'transactions';

    return (
      <div className="mt-2">
        <Row className="compact">
          <WrapperNP className="col-lg-6"><PortfolioValue stats={fundStats} /></WrapperNP>
          <WrapperNP className="col-lg-6"><PortfolioGains stats={positionStats} view={this.props.view} /></WrapperNP>
        </Row>
        <Row className="compact">
          <div className="col-lg-6">
            <Row className="compact">
              <Wrapper className="col-lg-12">
                <Holdings
                  title="Tracked Portfolio"
                  items={fundStats.positions}
                  deleteHandler={this.deleteHandler}
                  buyHandler={this.buyHandler}
                  actions={FundActions}
                  expand={hdExpand}
                  selectHandler={this.selectHandler}
                />
              </Wrapper>
              { !hdExpand &&
                <React.Fragment>
                  <Wrapper className="col-6"><Allocations stats={fundStats} /></Wrapper>
                  <Wrapper className="col-6"><ProfitLoss stats={fundStats} view={this.props.view} /></Wrapper>
                </React.Fragment> }
            </Row>
          </div>
          <div className="col-lg-6">
            <Row className="compact">
              { !txExpand && <Wrapper className="col-lg-12"><SecurityReturns items={fundStats.positions} /></Wrapper> }
              <Wrapper className="col-lg-12">
                <Transactions title="Recently Added" items={fundStats.funds} expand={txExpand} />
              </Wrapper>
            </Row>
          </div>
        </Row>
      </div>
    )
  }
}

export default connect(
  state => ({
    fundStats: getMarketStats(state),
    positionStats: getMarketStats(state),
    acView: state.investment.acView,
    droppedFund: state.investment.droppedFund,
    dropFundResponse: state.investment.dropFundResponse,
    sfm: state.sfm,
    view: state.investment.view
  }),
  {
    toggleModal,
    setSFMState,
    dropFund,
    setInvestmentState,
    investmentFetch,
    setPortfolioState,
  }
)(WatchlistContent);


const PortfolioValue = (props) => {
  const stats = [
    { name: 'Portfolio Value', value: props.stats.portfolioValue },
  ]
  return (
    <KeyStats1 stats={stats} />
  )
}

const FundActions = () => {
  return (
    <React.Fragment>
      <Button color={"select"} outline
        className="h6 mb-0 py-1 badge-pill">Buy</Button>
      <Button color={"select"} outline
        className="h6 mb-0 mt-2 py-1 badge-pill">Drop</Button>
    </React.Fragment>
  )
}

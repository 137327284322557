import React, { useContext } from 'react'
import { Row, Col, Table } from 'reactstrap';
import { withHeaderFooter } from '../shared/HeaderFooter';
import { FormatCurrency } from '../shared/Utils';
import NameSponsor from './Common';
import { PercentChartWrap } from '../shared/Charts';
import { wholeNumberCheck } from 'Utils';
import _ from 'lodash';
import ActReportContext from 'components/reportsV2/ActReportContext';
import BestTickerBadge from '../shared/BestTickerBadge';

const EnhancementScores = ({ cbChartData, cbItems, cbFunds, cbFactor, showHeaderFooter }) => {
  const items = cbItems.slice(0, 12);
  const otherItems = cbItems.slice(12, cbItems.length);
  const otherItemsChunk = _.chunk(otherItems, 12);
  const reportData = useContext(ActReportContext);
  const { bestTicker } = reportData;
  const reqData = {
    cbChartData,
    cbItems: items,
    cbFunds,
    cbFactor
  }

  return (
    <>
      <EnhancementScoresContent bestTicker={bestTicker} {...reqData} showHeaderFooter={showHeaderFooter} />
      {otherItems.length > 0 && 
        otherItemsChunk.map(item => {
          return (
            <EnhancementScoresFunds {...reqData} cbItems={item} />
          )
        })
      }
    </>
  )
}

export default EnhancementScores;

const EnhancementScoresContent = withHeaderFooter(({ cbChartData, cbItems, cbFunds, cbFactor, bestTicker }) => {
  return (
    <div className="content">
      <div className="secWrapper">
        <div className="pr-v2-relevant-funds">
          <div>
            {/*<h4 className="pr-v2-sub-header">Enhancement Scores</h4>*/}
            <p>The Enhancement score indicates the marginal impact of substituting {wholeNumberCheck(cbFactor * 100, 2)}% of your portfolio with each of the funds. Each value represents the enhancement score for adding 1 fund at a time.</p>
          </div>
          <div>
            <EnhancementScoresFunds
              bestTicker={bestTicker}
              cbChartData={cbChartData}
              cbItems={cbItems}
              cbFunds={cbFunds}
              showHeaderFooter={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
});

const EnhancementScoresFunds = withHeaderFooter((props) => { 
  const { cbChartData, cbItems, cbFunds, bestTicker } = props;
  return(
    <div className="content">
      <div className="rel-results">
        <Col xs="12">
          { cbItems.map((item, i) => {
            const chartData = cbChartData ? cbChartData[0] : null;
            const details = chartData ? chartData.filter((it) => it.name === item.ticker_short)[0] : null;
            const fundColor = details ? (details.color ? details.color : null) : null;
            const fundColorNew = i === 0 ? '#00a700' : '#f5a20a';
            const color = fundColor || fundColorNew;
            return (
              <Row key={i} className="result-row">
                <Col xs="3" className="fund-symbol text-center">
                  <p className="mb-0">
                    {item.ticker_short}
                    {(item.ticker_short && item.ticker_short === bestTicker) && (
                      <BestTickerBadge
                        style={{
                          paddingLeft:'12px',
                          top:'2px',
                          position:'absolute',
                        }}
                      />
                    )}
                  </p>
                  {/* <p className="mb-0">{FormatCurrency(item.nav, 2)}</p> */}
                </Col>
                <Col xs="5" className="fund-name">
                  <NameSponsor item={item} name={item.name} sponsor={item.sponsor} />
                </Col>
                <Col xs="4" className="fund-gauge text-center">
                  <PercentChartWrap width={130} value={cbFunds[i].score} size={'md'} color={color} />
                </Col>
              </Row>
            )
          }) }
        </Col>
      </div>
    </div>
  )
});
import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import NotApplicable from 'components/NotApplicable';
import GaugeChart from 'components/charts/GaugeChart';

export const AssetChart = ({ card, qi, width, chartData }) => {
  if(typeof card._categorySeries == 'undefined' || !card._categorySeries) return <NotApplicable />

  const _categoryData = card._categorySeries;

  if(typeof _categoryData[qi.col + '_min'] === 'undefined' || typeof _categoryData[qi.col + '_max'] === 'undefined') return <NotApplicable />

  // console.log(card[qi.col], qi.col, _categoryData[qi.col + '_min'], _categoryData[qi.col + '_max']);
  const mult = qi.name === 'Assets' ? 1000 * 1000 : 1;

  const _min = _categoryData[qi.col + '_min'],
    _max = _categoryData[qi.col + '_max'],
    _val = qi.name === 'Assets' ? card[qi.col] : card[qi.col] * mult,
    _pc = card[qi.pcCol];
  if(typeof _val == 'undefined' || _val == null || _val === '') return <NotApplicable />
  if(typeof _min == 'undefined' || _min == null || _min === '') return <NotApplicable />
  if(typeof _max == 'undefined' || _max == null || _max === '') return <NotApplicable />

  const suffix = ['', 'K', 'M', 'B'];
  const kmb = (val, i = 0) => (val < 100 || i > 2) ? `$${val.toFixed(1)}${suffix[i]}` : kmb(val / 1000, i + 1)
  const noMFData = ((card.vehicle !== "MF" && qi.name === 'Volume') || qi.name === 'Assets') ? true : false;

  return (
    <div className="w-100 p-1 guage-chart-wrapper" >
      <div className="d-flex flex-column align-items-center">
        {
          noMFData &&
          <Fragment>
            {(_min === _max && _max === _val) ? (
              <GaugeChart minWidth={width} pc={0.5} />
            ) : (
              <GaugeChart minWidth={width} pc={_pc} />
            )}
            <Row className="no-gutters h6 mb-0 w-100 guage-chart-details">
              <Col className="col-4 pr-2 text-gray-6i text-right h7 fz-9" style={{ marginTop: '-2px' }}>{kmb(_min)}</Col>
              <Col className="col-4 text-center font-weight-bold mb-0 lh-100 fz-9" style={{ marginTop: '2px' }}>{kmb(_val)}</Col>
              <Col className="col-4 pl-2 text-gray-6i text-left h7 fz-9" style={{ marginTop: '-2px' }}>{kmb(_max)}</Col>
            </Row>
          </Fragment>
        }
        {
          !noMFData &&
          <NotApplicable />
        }
      </div>
    </div>
  );
}

export default AssetChart;

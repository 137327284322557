import { createAPIInstance, createAPIInstanceForOkta } from './api'
import qs from 'query-string'
// import get from 'lodash/get';
import axios from 'axios';
import { oktaAuth } from 'oktaAuth';
// import * as AuthUtils from 'common/auth-utils'

export function getCityUsingZip(zip){
  const instance = axios.create({
    baseURL: `https://www.zipcodeapi.com/rest/js-OF7GEWcPBzp2cVb0GhwJD5BBwQd4tFMez2BlOuUKegG6MaOz3il1uCXmXhWNT2ZF/info.json/${zip}/radians`
  });

  return instance.get();
}

export function authenticate(payload) {
  window.localStorage.removeItem('noaccbar')
  return createAPIInstance().post('user/login', payload, {
    headers: {
      Authorization: null
    }
  })
}

export function acceptsTerms(payload) {
  // return Promise.resolve({message: 'success'})
  return createAPIInstance().post('user/accept-terms', payload)
}

export function logout(payload) {
  window.localStorage.removeItem('noaccbar')
  return createAPIInstance().post('user/logout', payload)
}

export function sendChangePwdEmail(payload) {
  return createAPIInstance().get('user/send-change-pwd', { params: payload})
}

export function verifyChangePwdToken(payload) {
  return createAPIInstance().get('user/verify-sent-code', { params: { q: payload } })
}

export function changePassword(payload) {
  console.log('payload',qs.stringify({ password: payload.password }));
  return createAPIInstance()
    .post('user/changepwd',qs.stringify({ e : true ,email: payload.email,old_password: payload.old_password,password: payload.password}), {
      headers: {
        Authorization: `Token ${payload.verifypwdtoken}`
      }
    })
}

export function verifyEmail(token){
  return createAPIInstance().get(`user/verifyEmail/${token}`)
}

export function getOktaLoginData(payload) {
  return createAPIInstanceForOkta()
    .post('v1/user/login', qs.stringify(payload))
    .then(response => response.data)
}

// export const executOktaLogin = (payload) => {
//   return oktaAuth && oktaAuth.signInWithCredentials(payload)
//     .then(response => response.data)
//     .catch(error => {
//       console.log(error)
//       console.log(error.toString())
//       return error && error.toString();
//     })
// }

export const executOktaLogin = (payload) => {
  return oktaAuth && oktaAuth.idx && oktaAuth.idx.authenticate(payload)
    .catch(error => {
      console.log(error)
      console.log(error.toString())
      return error && error.toString();
    })
}

export const executeGoogleOktaAuth = (payload) => {
  return oktaAuth && oktaAuth.signInWithRedirect(payload)
    .then(response => response.data)
    .catch(error => {
      console.log(error)
      console.log(error.toString())
      return error && error.toString();
    })
}

export const executOktaLogout = (payload) => {
  return oktaAuth && oktaAuth.signOut(payload)
    .then(response => response.data)
    .catch(error => {
      console.log(error)
      console.log(error.toString())
      return error && error.toString();
    })
}

export const executOktaIdxChangePassword = (payload) => {
  return oktaAuth && oktaAuth.idx && oktaAuth.idx.proceed(payload)
    .catch(error => {
      console.log(error)
      console.log(error.toString())
      return error && error.toString();
    })
}

export function executeOktaResetPassword(payload) {
  return oktaAuth && oktaAuth.forgotPassword(payload)
    .then(response => response.data)
}

// api/v1/validate-okta-user
export function validateOktaUser(payload) {
  return createAPIInstanceForOkta()
    .post('v1/validate-okta-user', payload, {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => response.data)
    .catch(error => {
      console.log(error)
      let _error = error;
      if (error) {
        _error = error.data || error.toString()
        if (error.status === 404) {
          _error = 'Something went wrong. Status: 404'
        }
      }
      console.log(_error)
      return _error;
    })
}

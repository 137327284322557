import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Menu, Dropdown, Row, Col, Divider } from 'antd'
import { DownOutlined } from '@ant-design/icons'

const { SubMenu } = Menu

export default class Tabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      portfolioType: ''
    }
  }

  selectedPortfolio = portfolioData => {
    const { location, history, setBuildPortfolioState, buildportfolio } = this.props;
    setBuildPortfolioState({portfolioName: portfolioData.portfolio_name});
    this.routeToAssetAllocationFrom('portfolio', 'scratch', {type: 'portfolio', data: portfolioData.id});
  }

  setMarketPlace = ticker => {
    this.routeToAssetAllocationFrom('portfolio', 'scratch', {type: 'MP', data: ticker});
  }

  demoPortfolio = () => {
    this.routeToAssetAllocationFrom('Demo Model', 'scratch', {type: 'demo_portfolio2', data: ''});
  }

  openChange = () => {
    const element = document.querySelector('.market-popup-wrapper');
    if (element != null) {
      element.style.height = element.clientHeight > 0 && `${element.clientHeight}px`
    }
  }

  portfolioMenus = () => {
    const { portfolioList, marketPlaceList } = this.props
    return (
      <Menu onOpenChange={this.openChange}>
        {marketPlaceList && typeof marketPlaceList == 'object' && Object.keys(marketPlaceList).length > 0 && (
          <SubMenu key='root' title={<div className="active-label">Sponsored Model Portfolios</div>} popupClassName="market-place-submenu-initial">
            {marketPlaceList && typeof marketPlaceList == 'string' && <div />}
            {marketPlaceList &&
              typeof marketPlaceList == 'object' &&
              Object.keys(marketPlaceList).length > 0 &&
              Object.keys(marketPlaceList).map((item, index) => {
                return (
                  <SubMenu
                    key={item}
                    title={item}
                    popupClassName="market-popup-head default-label"
                  >
                    {Object.keys(marketPlaceList[item]).length > 0 &&
                      Object.keys(marketPlaceList[item]).map((subItem, index) => {
                        return (
                          <SubMenu
                            key={subItem}
                            title={subItem}
                            popupClassName="market-popup default-label"
                          >
                            <div className="market-popup-wrapper">
                              {marketPlaceList[item][subItem].length > 0 &&
                                marketPlaceList[item][subItem].map(
                                  (subItem1, index) => {
                                    return (
                                      <div
                                        className="market_name-label default-label"
                                        onClick={() =>
                                          this.setMarketPlace(subItem1.ticker)
                                        }
                                      >
                                        {subItem1.name}
                                      </div>
                                    )
                                  }
                                )}
                            </div>
                          </SubMenu>
                        )
                      })}
                  </SubMenu>
                )
              })}
            {marketPlaceList &&
              marketPlaceList.message &&
              marketPlaceList.message == 'No tickers Present.' && (
              <Menu.Item key="0">
                <div className="portfolio_name-label default-label">No data</div>
              </Menu.Item>
            )}
          </SubMenu>
        )}

        <Menu.Divider />

        <Menu.Item key="Demo Model">
          <div
            className="active-label"
            onClick={() =>
              this.demoPortfolio()
            }
          >
            Use Demo Portfolio
          </div>
        </Menu.Item>
        <Menu.Divider />
        {portfolioList &&
          portfolioList.length &&
          portfolioList.map((item, index) => {
            return (
              <Menu.Item key={item.portfolio_name + index}>
                <div
                  className="portfolio_name-label default-label"
                  onClick={() => {
                    this.selectedPortfolio(item)
                  }}
                >
                  {item.portfolio_name}
                </div>
              </Menu.Item>
            )
          })}
      </Menu>
    )
  }

  marketPlaceDropMenus = () => {
    return (
      <Menu>
        <Menu.Item key="Your-watchlist">
          <div className="upload_btn">No data found</div>
        </Menu.Item>
      </Menu>
    )
  }

  routeToAssetAllocationFrom = (selectedPortfolioFor, path, portfolioData) => {
    const { location, history, setBuildPortfolioState, buildportfolio } = this.props;
    const { onboardData, tabHeaderMenu, summary } = buildportfolio;
    if (setBuildPortfolioState && selectedPortfolioFor && path) {
      // setBuildPortfolioState({
      //   onboardData: {
      //     selectedPortfolioFor,
      //     onboardFrom: `/${path}`,
      //     type: portfolioData ? portfolioData.type : null,
      //     data: portfolioData ? portfolioData.data : null
      //   }
      // })
      let obj = {data:{type: portfolioData ? portfolioData.type : null}, selectedPortfolioFor, path: `/${path}`};

    if(selectedPortfolioFor === 'From Scratch'){
      setBuildPortfolioState({onboardData: {selectedPortfolioFor, onboardFrom: `/${path}`}});
    }
    else if(portfolioData.type === 'tickers'){
      let tickerObj = {data:{tickers: portfolioData.data}, selectedPortfolioFor, path: `/${path}`};
      this.props.fetchPortfolioRequest(tickerObj);
    }else if(portfolioData.type === 'demo_portfolio2'){
      this.props.fetchPortfolioRequest(obj);
    }else{
      obj.data.name = portfolioData.data;
      this.props.fetchPortfolioRequest(obj);
    }
    }
  }

  redirect = () => {
    const { history, setBuildPortfolioState, buildportfolio } = this.props;
    const { onboardData, tabHeaderMenu, summary } = buildportfolio;
    if (onboardData && onboardData.onboardFrom !== '' && history) {
      const urlObj = {
        onboardData,
        tabHeaderMenu,
      };
      // console.log(urlObj);
      setBuildPortfolioState({
        summary: {
          ...summary,
          timeline: 'Select Funds'
        },
        // onboardData: {onboardFrom: ''}
      })
      const newPath = `/build-portfolio${onboardData.onboardFrom}`;
      history.push(newPath);
    }
  }

  useSelectedFunds = () => {
    const { buildportfolio, fetchPortfolioSuccess, fetchPortfolioRequest, history } = this.props;
    let tickersArray = [];
    let obj = {tickers: ''};
    buildportfolio.resultPageCart.forEach(item => tickersArray.push(item.ticker));
    obj.tickers = tickersArray.join(',');
    this.routeToAssetAllocationFrom('Use selected funds', 'scratch', {type: 'tickers', data: obj.tickers});
  }

  render() {
    const {
      number,
      className,
      setContent,
      loading,
      valid,
      portfolioList,
      marketPlaceList,
      setWatchlist,
      setActiveAccount,
      setMarketPlace,
      setDemoPortfolio,
      buildportfolio,
      builderCount,
    } = this.props

    const tabs = [
      {
        label: 'Using Selected Funds',
        hidden: false,
        img: () => (
          <div className="imgBlock">
            <img src="/assets/images/CartBag.svg" />
          </div>
        ),
        head: null,
        subHead: () => (
          <div onClick={buildportfolio.resultPageCart.length > 0 ? () => this.useSelectedFunds() : null}>
            Using {builderCount} Selected Funds
          </div>
        ),
        tabClick: () => buildportfolio.resultPageCart.length > 0 ? this.useSelectedFunds() : null,
        disable: buildportfolio.resultPageCart.length === 0 ? true : false
      },
      {
        label: 'Using Sponsor Provided, Selected Or Custom Model',
        hidden: false,
        img: () => (
          <div className="imgBlock">
            <img src="/assets/images/CustomModel.svg" />
          </div>
        ),
        head: null,
        subHead: () => (
          <Dropdown
            overlayClassName="market-place-dropdown"
            overlay={this.portfolioMenus}
          >
            <div>
              <span>Using Sponsor Provided, Selected Or Custom Model</span>
              <span>
                <DownOutlined
                  style={{
                    position: 'relative',
                    bottom: '1px',
                    fontSize: '14px'
                  }}
                />
              </span>
            </div>
          </Dropdown>
        ),
        tabClick: null
      },
      {
        label: 'From Scratch',
        hidden: false,
        img: () => (
          <div className="imgBlock">
            <img src="/assets/images/setting-white.png" />
          </div>
        ),
        head: null,
        subHead: () => (
          <div
            onClick={() =>
              this.routeToAssetAllocationFrom('From Scratch', 'scratch')
            }
          >
            From Scratch
          </div>
        ),
        tabClick: () => this.routeToAssetAllocationFrom('From Scratch', 'scratch')
      }
    ]

    this.redirect();

    return (
      <React.Fragment>
        <div className="tabs-block">
          {tabs.map((item, index) => {
            if (!item.hidden) {
              return (
                <div
                  key={index}
                  className={`tabs ${item.tabClick !== null ? 'cursor' : ''} ${item.disable ? 'disabled' : ''}`}
                  onClick={item.tabClick !== null ? item.tabClick : null}
                >
                  <div className="box">
                    {item.img !== null && item.img()}
                    <Divider className="divider" />
                    <div className="detailsBlock">
                      {item.head !== null && (
                        <div className="head">{item.head()}</div>
                      )}
                      {item.subHead !== null && (
                        <div className={`sub-head ${item.disable ? 'disabled' : ''}`}>{item.subHead()}</div>
                      )}
                    </div>
                  </div>
                </div>
              )
            }
          })}
        </div>
        
      </React.Fragment>
    )
  }
}

import React, { useContext } from 'react';
import { Row, Col, Table } from 'reactstrap';
import { Coverage_Logo, Coverage_poweredByLogo } from '../../../../common/constant';
import { haveHTMLData } from 'Utils';
import { Disclosures } from '../Pages/Disclosures'

const Logo = Coverage_Logo;
const poweredByLogo = Coverage_poweredByLogo;
const IndexPage = (props) => {
  const { disclosures } = props;
  return (
    <React.Fragment>
      <div className="page">
        <div className="content">
          <div className="pr-v2-cover-page">
            <div>
              {props.logo && (
                <img style={{ height: `75px` }} src={props.logo ? props.logo : Logo} alt="magnifi" />
              )}
              <div className={'title-name-logo'}>
                <h1>Builder </h1>
                <img style={{ height: `30px` }} src={poweredByLogo} alt="" /><br />
              </div>
              <Row>
                <Col xs="9">
                  {props.createdDate && <span>Data as of {props.createdDate}.</span>}
                  <p> TIFIN Wealth is changing the way we shop for investments. The world's first semantic search engine for finance helps discover, compare and buy investment products such as ETFs, Mutual funds, etc.</p>
                  <p>This proposal is designed to select and compare available thematic investment options to determine which options meet your criteria and may help to enhance your portfolio.</p>
                  <Disclosures disclosure={disclosures} />
                </Col>
              </Row>
            </div>
            <div className="glossary-footer text-justify">
              <span>There is no guarantee that any investment strategy illustrated will be successful or achieve any particular level of results. Please review the disclosures at the end of this document and consult your financial advisor for more information.</span><br />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
};
export default IndexPage;


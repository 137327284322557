import React from 'react'
import classNames from 'classnames'

export default function IconButton(props) {
  const { wrapperClassName, className, iconClass='', ...rest } = props
  const wrapperClass = classNames({
    'ssf-icon-button': true,
    [`${className}`]: true
  });
  const iconClassName = classNames({
    'fal': true,
    [`${iconClass}`]: true
  });
  
  return (
    <span
      role="button"
      className={wrapperClass}
      {...rest}
    >
      <i className={iconClassName} />
    </span>
  )
}

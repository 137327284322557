import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import qs from 'query-string'

import { Formik } from 'formik'
import * as Yup from 'yup'
import { get, isEmpty } from 'lodash'

import { Row, Col } from 'reactstrap';

import { createLoadingSelector } from 'selectors/loading-selector';
import { Spinner } from 'shared-components/';
import { getLinkToken, linkTokenError, getUsersAccountDetails, createUserAccount } from 'actions/register';
import { fetchProfile } from 'actions/profile';
import * as RegisterApi from 'apis/register';

import { REDIRECT_DOMAIN_PATH } from 'DataSet';
import { addEventToAnalytics } from 'Utils';
import { apexRedirectionWhereTo } from 'layouts/utils';
import TradingForm from './TradingForm';
import AccountAfterCreationPopup from './AccountAfterCreationPopup';
import config from '../../../env';
import analyticsInit from '../../../analytics';
import { notification } from 'antd'
import { updateMetaInfo } from '../utils'

const magnifiLogo = "/assets/images/MagnifiLogo/Magnifi_Tagline_White_RGB@2x.png";

const TradingFormContainer = (props) => {

  const { history: { location } } = props
  const state = location.state ? location.state : {};

  return (
    <TradingForm {...props} from={state.from} />
  )

}

const brands = ['@brand.com']

class RegisterTrading extends Component {
  state = {
    showSuccess: false,
    step: window.location.pathname === '/trading/review' ? 5 : window.location.pathname === '/trading/bank/add' ? 3 : 1,
    isFetching: false,
    isFetching1: false,
    fromMobileApp: window.localStorage.getItem('fromMobileApp'),
    showAccountAfterCreationPopup: false,
    savedFormData: null,
    savedProgress: null,
    accountDetailsLoading: false,
    registeredUserData: null,
    linkAccountPopup: false,
    bankAccountSuccess: false,
    bankAccountStatus: true,
    pixelScripts: []
  }

  componentWillMount() {
    const { loggedIn, history } = this.props;
    if (loggedIn) {
      if(window.location.pathname !== '/trading/bank/add'){
        this.triggerProfileApiCall();
      }
    }
    if (!loggedIn && history) {
      if (analyticsInit) {
        analyticsInit();
      }
      setTimeout(() => {
        history.push('/register');
      }, 600);
    }
  }

  triggerProfileApiCall = () => {
    const { fetchProfile, profile } = this.props;
    if (profile && (typeof profile.apex === 'undefined' || !profile.apex || profile.apex === null || Object.keys(profile.apex).length === 0)) {
      if (fetchProfile) {
        fetchProfile('', (res) => {
          // this.apexRedirect(res);
        });
      }
    } else {
      // this.apexRedirect(profile);
    }
  }

  apexRedirect = (res) => {
    if (res && res.apex) {
      const apex_obj = res.apex;
      const isApexEmpty = apex_obj && Object.keys(apex_obj).length === 0 && apex_obj.constructor === Object;
      if (!isApexEmpty) {
        const _apexRedirectionWhereTo = apexRedirectionWhereTo({ profile: res });
        if (_apexRedirectionWhereTo !== null && _apexRedirectionWhereTo !== 'Not to apex route') {
          const { history } = this.props;
          if (history) {
            history.push(_apexRedirectionWhereTo)
          }
        }
      }
    }
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].style.paddingTop = 0;
    if(window.location.pathname === '/register/trading'){
      updateMetaInfo({ title: 'Register With Magnifi | Innovation-Based Investing & Portfolio Management', description: 'Create your free Magnifi account and get access to customized investment portfolios for you or your client. Discover a whole new world of investing opportunities today!' });
      let email = localStorage.getItem('email') || window.sessionStorage.getItem('email') || "";
      addEventToAnalytics('Register Trading Page', 'Register Trading Page', 'REGISTER_TRADING_PAGE', { clickPage: 'Register Trading Page', email: email }, true);
    }
    if(window.location.pathname !== '/trading/bank/add'){
      this.loadUserFormData();
    }
  }

  componentWillUnmount(){
    updateMetaInfo();
    if(this.state.pixelScripts.length){
      this.state.pixelScripts.forEach(script=>{
        script.parentNode.removeChild(script);
      })
    }
  }

  loadUserFormData = async () => {
    this.setState({ isFetching: true });
    try {
      let _responseData = await RegisterApi.getUserFormData();
      if (_responseData && !_responseData.message) {
        this.setState({ savedProgress: _responseData });
      }
      this.setState({ isFetching: false });
    } catch (err) {
      this.setState({ isFetching: false });
    }
  }

  handleSubmit = (values, { setSubmitting }) => {
    console.log(this.props, values);
  }

  setStep = (step) => {
    let email = localStorage.getItem('email') || window.sessionStorage.getItem('email') || "";
    addEventToAnalytics(`Step ${step} completed`, `Step ${step} completed`, `STEP_${step}_COMPLETED`, { email: email }, true);
    this.setState({ step: step + 1 });
    document.getElementById('register-trade-form').scrollTop = 0;
  }

  backHandler = () => {
    const { step } = this.state;
    if (step === 1) {
      this.props.history.goBack()
      // this.routeToPage()
    }
    else {
      this.setState({
        step: step - 1
      })
    }
    document.getElementById('register-trade-form').scrollTop = 0;
  }


  routeToPage = () => {
    const currentPath = get(this.props, 'location.pathname', '');
    const pathname = this.props.fromPath || '/';
    if (pathname !== currentPath) {
      this.props.history.push(pathname)
    }
  }

  getLink = async (callback) => {
    try {
      this.setState({ isFetching: true });
      let _responseData = await RegisterApi.getLinkToken();
      callback({ linkToken: _responseData.link_token });
      this.setState({ isFetching: false });
    }
    catch (error) {
      //console.log(error);
      this.setState({ isFetching: false });
      if (error && typeof error.response !== 'undefined') {
        if (typeof error.response.data !== 'undefined') {
          this.props.linkTokenError(error.response, error.response.data)
        }
      }
    }
  }

  toggleAccountPopup = () => {
    //console.log('redirect')
    this.setState({ showAccountAfterCreationPopup: !this.state.showAccountAfterCreationPopup })
  }

  getUsersAccounts = async (postData, callback) => {
    try {
      this.setState({ accountDetailsLoading: true });
      let _responseData = await RegisterApi.getUsersAccountDetails(postData);
      callback({ bankAccounnts: _responseData });
      this.setState({ isFetching: false, accountDetailsLoading: false });
    }
    catch (error) {
      //console.log(error);
      this.setState({ accountDetailsLoading: false });
      if (error && typeof error.response !== 'undefined') {
        if (typeof error.response.data !== 'undefined') {
          this.props.linkTokenError(error.response, error.response.data)
        }
      }
    }
  }

  createAchRelation = async (data) => {
    this.setState({ isFetching: true });
    try{
      await RegisterApi.createAchRelation(data)
      this.setState({ isFetching: false, bankAccountSuccess: true, bankAccountStatus: true });
    } catch(err){
      this.setState({ isFetching: false, bankAccountSuccess: true, bankAccountStatus: false });
    }
  }

  createAccount = async (postData, callback) => {
    this.setState({ isFetching: true, formError: null });
    try {
      let _responseData = await RegisterApi.createUserAccount(postData.data);
      //console.log(_responseData)
      if (_responseData.result.status === 'COMPLETE' || _responseData.result.status === 'APPROVED') {
        callback({ responseData: _responseData });
        this.setState({ isFetching: false, registeredUserData: _responseData, linkAccountPopup: true });
      } else {
        this.setState({ isFetching: false, showAccountAfterCreationPopup: true, accountState: 'pending', registeredUserData: _responseData });
      }
      let utm_campaign = window.sessionStorage.getItem('utm_campaign');
      let utm_medium = window.sessionStorage.getItem('utm_medium');
      let utm_source = window.sessionStorage.getItem('utm_source');
      let email = localStorage.getItem('email') || window.sessionStorage.getItem('email') || "";
  
      if ((utm_source && utm_medium && utm_campaign) && addEventToAnalytics) {
        addEventToAnalytics('Investment Account Created', 'Investment Account Created', 'INVESTMENT_ACCOUNT_CREATED', { utm_source, utm_medium, utm_campaign, email }, true);
      } else {
        addEventToAnalytics('Investment Account Created', 'Investment Account Created', 'INVESTMENT_ACCOUNT_CREATED', { email: email }, true);
      }
      this.addConversionPixel();
    }
    catch (error) {
      let formError = get(error, 'response.data.result.details[0].message', null);
      if (formError) {
        console.log(formError);
        this.setState({ formError })
      }
      this.setState({ isFetching: false, showAccountAfterCreationPopup: true, accountState: 'error' });
      if (error && typeof error.response !== 'undefined') {
        if (typeof error.response.data !== 'undefined') {
          this.props.linkTokenError(error.response, error.response.data)
        }
      }
    }
  }

  addConversionPixel = () => {
    if(config.vendorConversionPixel){
      let pixelScripts = []
      const script1 = document.createElement("script");
      script1.type = "text/javascript";
      script1.src = "/mathtag.js";
      document.body.appendChild(script1);
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "//pixel.mathtag.com/event/js?mt_pp=1&mt_adid=248870";
      document.body.appendChild(script);
      pixelScripts.push(script1)
      pixelScripts.push(script)
      this.setState({ pixelScripts });
    }
  }

  saveData = async (postData, isSubmit = false) => {
    postData = JSON.parse(JSON.stringify(postData));
    if (postData.secondary_account_political_organization) {
      postData.secondary_account_political_organization = JSON.stringify(postData.secondary_account_political_organization);
    }
    if (postData.joint_political_organization) {
      postData.joint_political_organization = JSON.stringify(postData.joint_political_organization);
    }
    if (postData.trusted_select) {
      postData.trusted_select = JSON.stringify(postData.trusted_select);
    }
    if (postData.political_organization) {
      postData.political_organization = JSON.stringify(postData.political_organization);
    }
    if (!isSubmit) {
      let email = localStorage.getItem('email') || window.sessionStorage.getItem('email') || "";
      addEventToAnalytics(`Save Progress From step ${this.state.step}`, `Save Progress From step ${this.state.step}`, `SAVE_PROGRESS_FROM_STEP_${this.state.step}`, { email: email }, true);

      this.setState({ isFetching1: true });
      try {
        await RegisterApi.saveProfileData(postData);
        this.setState({ isFetching1: false });
          notification.open({
            description: 'Details saved successfully.',
            className: 'saveProfile_toastSuccess',
            duration: 4,
            message: null,
            top: 50,
          })
      }
      catch (error) {
        this.setState({ isFetching1: false });
          notification.open({
            description: 'Error, Details not saved.',
            className: 'saveProfile_toastFail',
            duration: 4,
            message: null,
            top: 50,
          })
      }
    } else {
      try {
        RegisterApi.saveProfileData(postData);
      } catch (error) { }
    }
  }

  render() {
    const { loading, profile } = this.props;
    let userEmail = localStorage.getItem('email');
    let isBranded = true;

    brands.map((eachBrand) => {
      return isBranded = (userEmail && userEmail.indexOf(eachBrand) > -1) ? false : true
    })

    return (
      <div>
        {loading && <Spinner isFetching={loading} />}


        {this.state.accountDetailsLoading ? <div className='whole_page_just_loading'>
          <Row>
            <Col sm={12} className="text-center">
              <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5" style={{ fontSize: '4em' }}></i>
            </Col>
            <Col sm={12} className="text-center mt-2">
              <span>Fetching Account Information From Your Bank</span>
            </Col>
          </Row>
        </div> : null}

        <TradingFormContainer
          profile={profile}
          savedProgress={this.state.savedProgress}
          isBranded={isBranded}
          error={this.props.error}
          saveData={this.saveData}
          step={this.state.step}
          setStep={this.setStep}
          backHandler={this.backHandler}
          history={this.props.history}
          onSubmit={this.handleSubmit}
          getLink={this.getLink}
          isFetching={this.state.isFetching}
          isFetching1={this.state.isFetching1}
          getUsersAccounts={this.getUsersAccounts}
          createAccount={this.createAccount}
          fetchProfile={this.props.fetchProfile}
          apexRedirect={this.apexRedirect}
          linkAccountPopup={this.state.linkAccountPopup}
          registeredUserData={this.state.registeredUserData}
          createAchRelation={this.createAchRelation}
          setlinkAccountPopup={()=> this.setState({ linkAccountPopup: false })}
          history={this.props.history}
          bankAccountSuccess={this.state.bankAccountSuccess}
          bankAccountStatus={this.state.bankAccountStatus}
          // fromRegister={this.props.location && this.props.location.fromRegister ? true : false}
          fromRegister={false}
          fromPathRouter={this.props.location && this.props.location.fromPathRouter ? this.props.location.fromPathRouter : ''}
          closeBankAccountPopup={() => this.setState({ bankAccountSuccess: false, bankAccountStatus: false })}
        />
        {this.state.showAccountAfterCreationPopup && <AccountAfterCreationPopup setStep={this.setStep} history={this.props.history} state={this.state.accountState} fromMobileApp={this.state.fromMobileApp} formError={this.state.formError} toggleAccountPopup={this.toggleAccountPopup} open={this.state.showAccountAfterCreationPopup} setlinkAccountPopup={()=> this.setState({ linkAccountPopup: !this.state.linkAccountPopup })} />}
      </div>
    )
  }
}

const mapStateToProps = ({ auth, register, loading, profile }) => ({
  loggedIn: auth.loggedIn,
  isFetching: createLoadingSelector(['REGISTER'])({ loading }),
  linkToken: register.linkToken,
  linkTokenError: register.linkTokenError,
  bankAccounts: register.bankAccounts,
  profile: profile.profile,
  loading: createLoadingSelector([
    'PROFILE'
  ])({ loading }),
})

const mapDispatchToProps = {
  getLinkToken,
  linkTokenError,
  getUsersAccountDetails,
  createUserAccount,
  fetchProfile
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RegisterTrading));

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Redirect, NavLink, Link } from 'react-router-dom'

import { Container, Row, Nav, Col } from 'reactstrap'
import ProfileFormContainer from './ProfileFormContainer'
import Navbar from '../components/Navbar'

import { logout } from 'actions/auth'
import { PrivateRoute } from 'shared-components/'
import Footer from 'components/Footer';
import { REDIRECT_DOMAIN_PATH } from 'DataSet';

class Support extends Component {
  componentDidMount() {
    document.body.classList.toggle('pt-0', true);
    document.body.classList.toggle('about_container', true);
  }

  componentWillUnmount() {
    document.body.classList.remove('pt-0');
    document.body.classList.remove('about_container');
  }

  render() {
    return (
      <div className="about_container">
        <a name="aboutUsAnchor"></a>
        <div className="whatwedo_masthead text-center text-white d-flex">
          <div className="container my-auto">
            <div className="row">
              <div className="col-md-12 mx-auto text-center abt-txt">
                <div style={{ textAlign: 'center' }}>
                  <a className="navbar-brand mx-auto h1" href={REDIRECT_DOMAIN_PATH}>
                    <img src="/assets/images/MagnifiLogo/Magnifi_Logo_Grey_RGB.png" width="250" alt="OpenSeSaFi" />
                  </a>
                </div>
                <div className="mt-5">
                  <h2>Magnifi Support</h2>
                  <div className="btm-underline" style={{ width: '94.34px' }}></div>
                  <p className="text-faded">
                    <br />
                    For any support queries email us at <a href="mailto:support@magnifi.com">support@magnifi.com</a>
                </p>
                  <br />
                </div>
              </div>
            </div>
            <div className="container main-container__participate">
              <a className="btn main-container__btn-participate" href="/">Visit Homepage</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  logoutSuccess: auth.logoutSuccess
})

const mapDispatchToProps = {
  logout
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Support)

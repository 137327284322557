import React from 'react';
import LandingPageComponent from "../LandingPage";

const SearchCard = (props) => {
    return (
        <div className="advisor_search_card custom-scroll w-100 mb-4" style={{ height: '100%', overflow: 'auto' }}>
            <LandingPageComponent searchCard={true} {...props} />
        </div>
    )
}

export default SearchCard;

import React, { useContext } from 'react';
import { Row, Col, Table } from 'reactstrap';
import { withHeaderFooter } from '../shared/HeaderFooter';
import { StackedAreaChart } from '../shared/Charts';
import SimplePieChart from '../shared/SimplePieChart';
import ModelRegionsChart from '../shared/ModelRegionsChart'
import { assetAllocData } from '../shared/Utils';
import PortfolioContext from '../PortfolioContext';
import { ViewFund, TickerPrice, ViewFee, ViewReturn, ViewRisk, ViewYield, ViewTrackRecord, ViewOutperform, ViewSharpe } from  '../FeeReturnRisk';
import { TableFeeRiskReturnHead, TableFeeRiskReturnBody } from './FeeReturnRiskBlock';

const ModelReplacement = withHeaderFooter((props) => {
  const reportData = useContext(PortfolioContext);
  const {updateAssetAllocData} = props
  const { portfolio: { query, queryEnglish: qE, modelEnhancement } } = reportData;

  const { enhanced, current } =  modelEnhancement;


  if (current && current.FRS && typeof current.FRS._defaultFRR === 'undefined' && enhanced.FRS && enhanced.FRS._defaultFRR) {
    current.FRS._defaultFRR = enhanced.FRS._defaultFRR;
    current.FRS.query = qE;
  }

  if (current && current.FRS && typeof current.FRS._originalQueryVar === 'undefined' && enhanced.FRS && enhanced.FRS._originalQueryVar) {
    current.FRS._originalQueryVar = enhanced.FRS._originalQueryVar;
    current.FRS.query = qE;
  }

  // Use the code below if you want the columns to be dynamic
  // based on the query
  // let showQueryEnComp = '';
  // let showQueryCrComp = '';
  // let showQueryHeader = '';
  // let showHeaderIcon = '';
  // if(query == 'high_yield_alternative'){
  //   showQueryCrComp = <ViewYield yld={current.weight_yield.toFixed(2)+'%'} />
  //   showQueryEnComp = <ViewYield yld={enhanced.weight_yield.toFixed(2)+'%'} />
  //   showQueryHeader = 'Yield'
  //   showHeaderIcon = <img src="/assets/images/icons/Yield.svg" width="17" alt="Risk" style={{ marginBottom: '3px', marginRight: '5px' }} />
  // }else if(query == 'long_track_record_alternatives'){
  //   showQueryCrComp = <ViewTrackRecord tr={current.weight_track_record.toFixed(2)+'%'} />
  //   showQueryEnComp = <ViewTrackRecord tr={enhanced.weight_track_record.toFixed(2)+'%'} />
  //   showQueryHeader = 'Track Record'
  //   showHeaderIcon = <i className="far fa-calendar-alt" style={{marginRight: '7px'}}/>
  // }else if(query == 'outperform'){
  //   showQueryCrComp = <ViewOutperform op={(current.outperform * 100).toFixed(2)+'%'} />
  //   showQueryEnComp = <ViewOutperform op={(enhanced.outperform * 100).toFixed(2)+'%'} />
  //   showQueryHeader = 'Outperform'
  //   showHeaderIcon = <img src="/assets/images/icons/OutPerformance.svg" width="17" alt="Risk" style={{ marginBottom: '3px', marginRight: '5px' }} />
  // }else if(query == 'high_sharpe_alternative'){
  //   showQueryCrComp = <ViewSharpe sharpe={(current['attribute/technical/returns/risk_adjusted_returns']).toFixed(2)} />
  //   showQueryEnComp = <ViewSharpe sharpe={(enhanced['attribute/technical/returns/risk_adjusted_returns']).toFixed(2)} />
  //   showQueryHeader = 'Sharpe'
  //   showHeaderIcon = <img src="/assets/images/icons/OutPerformance.svg" width="17" alt="Risk" style={{ marginBottom: '3px', marginRight: '5px' }} />
  // }else{
  //   showQueryCrComp = <ViewFee fee={current.FRS.fees.toFixed(2)+'%'} qE={qE} />
  //   showQueryEnComp = <ViewFee fee={enhanced.FRS.fees.toFixed(2)+'%'} qE={qE} />
  //   showQueryHeader = 'Fee'
  //   showHeaderIcon = <i className="far fa-fw fassf fa-tags-1"/>
  // }

  const currentFeesValue = (current.FRS.fees && !isNaN(Number(current.FRS.fees))) ? Number(current.FRS.fees).toFixed(2)+'%' : '--';
  const enhancedFeesValue = (enhanced.FRS.fees && !isNaN(Number(enhanced.FRS.fees))) ? Number(enhanced.FRS.fees).toFixed(2)+'%' : '--';
  const currentReturnValue = (current.FRS.yRet && !isNaN(Number(current.FRS.yRet))) ? Number(current.FRS.yRet * 100).toFixed(2)+'%' : '--';
  const enhancedReturnValue = (enhanced.FRS.yRet && !isNaN(Number(enhanced.FRS.yRet))) ? Number(enhanced.FRS.yRet * 100).toFixed(2)+'%' : '--';
  const currentRiskValue = (current.FRS.volt && !isNaN(Number(current.FRS.volt))) ? Number(current.FRS.volt * 100).toFixed(2)+'%' : '--';
  const enhancedRiskValue = (enhanced.FRS.volt && !isNaN(Number(enhanced.FRS.volt))) ? Number(enhanced.FRS.volt * 100).toFixed(2)+'%' : '--';

  const showQueryCrComp = <ViewFee fee={currentFeesValue} qE={qE} />
  const showQueryEnComp = <ViewFee fee={enhancedFeesValue} qE={qE} />
  const showQueryHeader = 'Fee'
  const showHeaderIcon = <i className="far fa-fw fassf fa-tags-1"/>

  // Use the code below if you want the number of rows to be the same on the holdings table
  // const holdingsMin = Math.min(current.holdings.length, enhanced.holdings.length);
  // const cutOff = holdingsMin > 5 ? 5 : holdingsMin;

  // Dynamic top holdings based on array length
  const holdingsMax = Math.max(current.holdings.length, enhanced.holdings.length);
  const topHoldingsCount = holdingsMax >= 5 ? 5 : holdingsMax;

  // Temporary change to maintain the same number of elements in the holdings array
  current.holdings =  maintainArrayLength(current.holdings, topHoldingsCount);
  enhanced.holdings = maintainArrayLength(enhanced.holdings, topHoldingsCount);

  const imageSrc = '/assets/images/sponsors/';

  // console.log(current);
  // console.log('currentReturnValue ==> ', currentReturnValue);
  // console.log('enhancedReturnValue ==> ', enhancedReturnValue);

  return (
    <React.Fragment>
      <div className="content">
        <div className="pr-v2-model-replacement">
          <h4 className="pr-v2-mr-header">Suggested Model Replacement</h4>
          <div className="model-comparison">
            <Row>
              <Col xs="12">
                <div className="pr-v2-mdl-repl">
                  <Table bordered className="pr-v2-rec-enh-table tbl-first">
                    <thead>
                      <tr className="rec-enh-tbl-hdr">
                        <th></th>
                        <th className="text-left">Model <p> </p></th>
                        {/*
                          <th><span>{showHeaderIcon} {showQueryHeader}</span></th>
                          <th><span><i className="far fa-fw fassf fa-chart-line-1"/> Return</span></th>
                          <th><span><img src="/assets/images/icons/dark-blue-heart-rate-1.svg" width="20" alt="Risk" style={{ marginBottom: '3px', marginRight: '5px' }} /> Risk</span></th>
                        */}
                        <TableFeeRiskReturnHead data={current.FRS} />
                        <th><span style={{lineHeight: 1.2}}>Asset Allocation and Overlap</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="rec-enh-align">
                        <td xs="1" className="rec-tt-wrapper custom-height lb-border">
                          <span className="rec-transform-text">Current</span>
                        </td>
                        <td className="text-left" style={{
                          maxWidth: '10ch',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'}}
                        >
                          {current.portfolioName}
                        </td>
                        {/*
                          <td>
                            {showQueryCrComp}
                          </td>
                          <td>
                            <ViewReturn returns={currentReturnValue} />
                          </td>
                          <td>
                            <ViewRisk risk={currentRiskValue} qE={qE} />
                          </td>
                        */}
                        <TableFeeRiskReturnBody data={current.FRS} />
                        <td>
                          <ModelRegionsChart
                            width={90}
                            height={90}
                            name="Allocations"
                            card={current.data}
                            colors={current.colors}
                            type='_current_asset_data'
                          />
                        </td>
                      </tr>
                      <tr className="rec-enh-align">
                        <td xs="1" className="rec-tt-wrapper custom-height blue-bg">
                          <span className="rec-transform-text">Enhanced</span>
                        </td>
                        <td className="text-left">
                          <span className="model-details">
                            <span className="md-sponsor">
                              <img
                                src={`${imageSrc}${enhanced.sponsor}.png`}
                                className="img-fluid"
                                alt={enhanced.sponsor}
                                onError={(e) => handleBrokenImage(e)}
                              />
                            </span>
                            <span className="md-pfName">{enhanced.portfolioName}</span>
                            <span className="md-category">{enhanced.category}</span>
                          </span>
                        </td>
                        {/*
                          <td>
                            {showQueryEnComp}
                          </td>
                          <td>
                            <ViewReturn returns={enhancedReturnValue} />
                          </td>
                          <td>
                            <ViewRisk risk={enhancedRiskValue} qE={qE} />
                          </td>
                        */}
                        <TableFeeRiskReturnBody data={enhanced.FRS} />
                        <td>
                          <ModelRegionsChart
                            width={90}
                            height={90}
                            name="Allocations"
                            card={enhanced.data}
                            colors={enhanced.colors}
                            overlap_data={enhanced.data.overlap_data}
                            centerDisplayText={enhanced.centerDisplayText}
                            updateAssetAllocData ={updateAssetAllocData}
                            type='_enhanced_asset_data'
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </div>
          <div className="holdings-comparison">
            <h4>Holdings</h4>
            <div className="holdings-table-wrapper">
              <div className="holdings-section">
                <HoldingsTable holdings={current.holdings} type="Current" />
              </div>
              <div className="holdings-section">
                <HoldingsTable holdings={enhanced.holdings} type="Enhanced" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
});

export default ModelReplacement;

const HoldingsTable = (props) => {
  const { holdings } = props;
  const imageSrc = '/assets/images/sponsors/';
  return(
    <table className={props.type}
        border="0" cellpadding="0" cellspacing="0">
      <thead>
        <tr className={props.type}>
          <th>{props.type} Funds</th>
          <th>Tickers</th>
          <th className="text-center">Weights</th>
        </tr>
      </thead>
      <tbody>
        {holdings.map(item => {
          return(
            <tr>
              <td>
                {item && <span className="holdings-sponsor">
                  <img
                    src={`${imageSrc}${item.sponsor}.png`}
                    className="img-fluid"
                    alt={item.sponsor}
                    onError={(e) => handleBrokenImage(e)}
                  />
                  <span>{item.short_name || item.name}</span>
                </span>}
              </td>
              <td>{item ? item.h_ticker || item.ticker || item.symbol : ''}</td>
              <td className="text-center">{item && Math.round(item.weight) + '%'}</td>
            </tr>
          )}
        )}
      </tbody>
    </table>
  )
}

const handleBrokenImage = (e) => {
  e.target.src = '';
}

const maintainArrayLength = (holdings, cutOff) => {
  if(holdings.length >= cutOff) return holdings.slice(0, cutOff);
  let out = [];
  for(let i=0; i < cutOff; i++){
    out.push(holdings[i]);
  }
  return out;
}

import React from 'react';
import { Switch } from 'antd';

// export default ({ className, children, ...rest }) => {
//   return (
// 		<div className={`switch-wrapper ${className || ''}`}>
//       <Switch ref={this.switchInput} {...rest} />
//       {children && (<span>{children}</span>)}
// 		</div>
// 	);
// };

export default class Toggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      switchState: props.checked || false,
    };
    this.switchInput = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this);
  }

  componentDidMount() {
    if (typeof this.props.checked !== 'undefined' && this.props.checked !== this.state.switchState) {
      this.setState({
        switchState: this.props.checked,
      });
    }
  }

  componentWillReceiveProps(newProps) {
    if (typeof newProps.checked !== 'undefined' && newProps.checked !== this.state.switchState) {
      this.setState({
        switchState: newProps.checked,
      });
    }
  }

  focusTextInput = () => {
    this.setState({
      switchState: !this.state.switchState,
    }, () => {
      const { onChange } = this.props;
      if (onChange) {
        onChange(this.state.switchState)
      }
    });
  }

  onChangeState = (toggleState) => {
    this.setState({
      switchState: toggleState,
    }, () => {
      const { onChange } = this.props;
      if (onChange) {
        onChange(this.state.switchState)
      }
    });
  }

  render() {
    const { className, textClassName } = this.props;
    const { switchState } = this.state;
    return (
      <div className={`switch-wrapper ${className || ''}`}>
        <Switch {...this.props} ref={this.switchInput} checked={switchState} onChange={(state) => this.onChangeState(state)} />
        {this.props.children && (<span className={`toggle-label ${textClassName}`} onClick={this.focusTextInput}>{this.props.children}</span>)}
  		</div>
    );
  }
}

import React from 'react';
import { useState, useEffect } from 'react';

import { Button, Card, CardBody, CardHeader, Collapse, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import FlipMove from 'react-flip-move';

import { cn } from 'DataSet';
import { getNest } from 'Utils';
import ItemCard from '../CombineTab/ItemCard';
import { Chart } from '../CombineTab/Charts';
import { Spinner } from './Compare';
import { addEventToAnalytics } from 'Utils';

const clx = require('classnames');

const CombineSm = (props) => {
  const [active, setActive] = useState(0);
  const [optWt, setOptWt] = useState(CombineOptsWt[1]);
  const [optPf, setOptPf] = useState(props.portfolios[0]);

  const toggle = id => e => setActive(id)

  useEffect(() => { props.optSelHandler({ optWt: optWt.value }); }, [optWt]);
  useEffect(() => { props.optSelHandler({ optPf: optPf.code }); }, [optPf]);

  return (
    <div className="page">
      <div className="page-header">
        {process.env.REACT_APP_SUB_DOMAIN === 'retail'
          ? <>Evaluate addition of selected investments to</>
          : <>Evaluate addition of selected funds to</>
        }
        <OptPfSelect setOptPf={setOptPf} optsPf={props.portfolios} optPf={optPf} query={props.query}/>
        <div className="small">{optPf.desc}</div>
      </div>
      <div className="page-content">
        <div class="accordion">
          <Card className={clx("accordion-card", { 'active': active === 0 })}>
            {process.env.REACT_APP_SUB_DOMAIN === 'retail'
              ? <CardHeader onClick={toggle(0)}>Investments selected to evaluate addition</CardHeader>
              : <CardHeader onClick={toggle(0)}>Funds selected to evaluate addition</CardHeader>
            }
            <Collapse className="accordion-collapse" isOpen={active === 0}>
              <CardBody className="p-0">
                <div class="main">
                  <Funds {...props} />
                </div>
                <Footer {...props} />
              </CardBody>
            </Collapse>
          </Card>
          <Card className={clx("accordion-card", { 'active': active === 1 })}>
            <CardHeader onClick={toggle(1)}>
              <div className="position-relative pr-4">
                Impact of adding <strong>{optWt.name}</strong> to portfolio
                <OptWtSelect setOptWt={setOptWt} optWt={optWt} />
              </div>
            </CardHeader>
            <Collapse className="accordion-collapse" isOpen={active === 1}>
              <CardBody>
                <Charts {...props} />
              </CardBody>
            </Collapse>
          </Card>
        </div>
      </div>
    </div>
  );
}
export default CombineSm;


const Funds = React.memo((props) => (
  <div>
    <div className="bg-secondary rounded" style={{paddingTop: '2px'}}>
      <FlipMove>
        { props.items.map((e, i) => {
          return (
            <div key={cn(e, 'Id')}>
              <ItemCard card={e} idx={i}
                weight={getNest(['_sfWtStats', 'weight'], e)}
                itemIndex={i}
                funds={props.funds}
                enhancementScore={props.enhancementScore}
                clearHandler={props.clearHandler}
                buyHandler={props.buyHandler} />
            </div>
          )
        })}
      </FlipMove>
      { props.loading && <Spinner /> }
    </div>
  </div>
))


const Charts = React.memo((props) => (
  <div className="mb-3">
    <div className="bg-secondary rounded" style={{paddingTop: '2px'}}>
      { props.filterScoreattrs.map((e, i) =>
          <Chart attr={e} key={e.code}
            items={props.items}
            allWtStats={props.allWtStats}
            allWtStatsBase={props.allWtStatsBase}
            range={props.range}
            dateRange={props.dateRange}
            displayYear={props.displayYear}
            rangeHandler={props.rangeHandler} />
        ) }
    </div>
  </div>
))


const Footer = ({ buyHandler }) => (
  <div className="footer d-flex justify-content-center">
    <Button color="select" outline
      className="btn h4 mb-0 mx-3 text-uppercase font-weight-bold modal-btn-br"
      onClick={buyHandler}>Buy All</Button>
  </div>
)


const OptWtSelect = ({ setOptWt, optWt }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = e => {
    e.stopPropagation();
    setDropdownOpen(prevState => !prevState);
  }

  const optHandler = opt => e => {
    addEventToAnalytics('Combine Add Impact','Combine Add Impact','COMBINE_ADD_IMPACT',{}, false)
    setOptWt(opt);
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="icon-tr">
      <DropdownToggle tag="span"><i className="fas fa-cog"></i></DropdownToggle>
      <DropdownMenu right>
        { CombineOptsWt.map((e, i) => <DropdownItem key={i} className={clx("py-1", { 'active': e.name === optWt.name })} onClick={optHandler(e)}>{e.name2}</DropdownItem>) }
      </DropdownMenu>
    </Dropdown>
  );
}


const OptPfSelect = ({ optsPf, setOptPf, optPf, query }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const optHandler = opt => e => {
    addEventToAnalytics('Combine Portfolio Dropdown','Combine Portfolio Dropdown','COMBINE_PORTFOLIO_DROPDOWN',{ query: query, optPf: opt.name },true);
    return setOptPf(opt)
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret tag="span" className="mydropdown">{optPf.name}</DropdownToggle>
      <DropdownMenu>
        { optsPf.map((e, i) => <DropdownItem key={i} className={clx("py-1", { 'active': e.code === optPf.code })} onClick={optHandler(e)}>{e.name}</DropdownItem>) }
       </DropdownMenu>
    </Dropdown>
  );
}


const CombineOptsWt = [
  { name: '5%',  value: 0.05, name2: 'Add 5%'  },
  { name: '10%', value: 0.10, name2: 'Add 10%' },
  { name: '15%', value: 0.15, name2: 'Add 15%' },
  { name: '20%', value: 0.20, name2: 'Add 20%' },
  { name: '25%', value: 0.25, name2: 'Add 25%' },
]

import React from 'react';
const d3 = require("d3");

const sum = (arr, key) => {
  return arr.reduce((a, b) => a + (b[key] || 0), 0);
}

export class D3Sunburst extends React.Component {

  constructor (props) {
    super(props);

    this.inRef = React.createRef();
    this.state = {};
    this.segments = {};
  }

  componentDidMount() {
    this.draw(this.props);
  }

  componentDidUpdate(prevProps) {
    const { activeSegment } = this.props;
    if (prevProps.activeSegment !== activeSegment && this.segments[activeSegment])
      this.clickedHandler(this.segments[activeSegment]);
  }

  draw = ({ data, colors={}, ...props }) => {
    const partition = data => {
      const root = d3.hierarchy(data)
          .sum(d => Math.abs(d.size))

      return d3.partition()
          .size([2 * Math.PI, root.height + 1])
        (root);
    }
    const d3color = d3.scaleOrdinal().range(d3.quantize(d3.interpolateRainbow, 20));
    const color = name => colors[name] || d3color(name);
    const format = d3.format(",d");
    const width = this.props.width, height = this.props.height;
    const radius = width / 4;
    const radiusFn = d => {
      let r0 = d.y0 * radius;
      return Math.max(r0, r0 + (d.y0 >= 2 ? 4 : radius - 1));
    };
    const tooltip = d => `<span class="label">${d.data.name}:</span> <span class="value">${d.data.children ? sum(d.data.children, 'size').toFixed(1) : d.data.size.toFixed(1)}%</span>`;
    const _clicked = p => props.clickHandler(p.data.name);

    const arc = d3.arc()
      .startAngle(d => d.x0)
      .endAngle(d => d.x1)
      .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(radius * 9)
      .innerRadius(d => d.y0 * radius)
      .outerRadius(radiusFn);

    const root = partition(data);
    root.each(d => d.current = d);

    d3.select(this.inRef.current).selectAll("*").remove();
    const svg = d3.select(this.inRef.current).append("svg:svg")
        .style("width", width+'px')
        .style("height", height+'px')
        .style("font", "10px sans-serif");

    // let customTooltip = svg.append("div")
    //                       .attr("class", "d3Tooltip");

    const g = svg.append("g")
        .attr("transform", `translate(${width / 2},${width / 2})`);

    const path = g.append("g")
      .selectAll("path")
      .data(root.descendants().slice(1))
      .enter().append("path")
        .attr("fill", d => color(d.data.name))
        .attr("fill-opacity", d => arcVisible(d.current) ? 1 : 0)
        .attr("d", d => arc(d.current));

    path.filter(d => d.children)
        .style("cursor", "pointer")
        .on("click", _clicked)
        .each(p => this.segments[p.data.name] = p );

    path.on("mousemove", function(d) {
          if (props.showTooltip && d.data.name !== 'Allocations') {
            props.showTooltip(d3.event, tooltip(d))
          }
          // customTooltip.html(d.data.name)
          //   .attr("class", "d3Tooltip")
          //   .style('top', d3.event.pageY - 12 + 'px')
          //   .style('left', d3.event.pageX + 25 + 'px')
          //   .style("opacity", 1);
        })
        .on("mouseout", function(d) {
          if (props.hideTooltip) {
            props.hideTooltip(d3.event, tooltip(d))
          }
        });
        // .append("title")
        // .text(tooltip);

    const parent = g.append("circle")
    parent.datum(root)
        .attr("r", radius)
        .attr("fill", d => color(d.data.name))
        .on("mousemove", function(d) {
          if (props.showTooltip && d.data.name !== 'Allocations') {
            props.showTooltip(d3.event, tooltip(d))
          }
        })
        .on("mouseout", function(d) {
          if (props.hideTooltip) {
            props.hideTooltip(d3.event, tooltip(d))
          }
        })
        .attr("cursor", "default")
        .attr("pointer-events", "all")
        .on("click", _clicked)
        .each(p => this.segments[p.data.name] = p );
        // .append("title")
        // .text(tooltip);

    function clicked(p) {
      parent.select('title').remove();
      parent.datum(p.parent || root)
        .attr("fill", d => color(p.data.name))
        .attr("cursor", p.depth === 0 ? "default" : "pointer")
        .on("mousemove", function() {
          if (props.showTooltip && p.data.name !== 'Allocations') {
            props.showTooltip(d3.event, tooltip(p))
          }
        })
        .on("mouseout", function(d) {
          if (props.hideTooltip) {
            props.hideTooltip(d3.event, tooltip(d))
          }
        })
        // .append("title")
        // .text(d => tooltip(p));

/*       g.select('circle').remove()
      g.append("circle")
        .datum(p.parent || root)
        .attr("r", radius)
        .attr("fill", d => color(d.data.name))
        .attr("pointer-events", "all")
        .on("click", clicked)
        .append("title")
        .text(tooltip);
 */
      root.each(d => d.target = {
        x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
        x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
        y0: Math.max(0, d.y0 - p.depth),
        y1: Math.max(0, d.y1 - p.depth)
      });
      const t = g.transition().duration(750);

      // Transition the data on all arcs, even the ones that aren’t visible,
      // so that if this transition is interrupted, entering arcs will start
      // the next transition from the desired position.
      path.transition(t)
          .tween("data", d => {
            const i = d3.interpolate(d.current, d.target);
            return t => d.current = i(t);
          })
        .filter(function(d) {
          return +this.getAttribute("fill-opacity") || arcVisible(d.target);
        })
          .attr("fill-opacity", d => arcVisible(d.target) ? 1 : 0)
          .attrTween("d", d => () => arc(d.current));
    }

    this.clickedHandler = clicked;

    function arcVisible(d) {
      return d.y1 <= 2 && d.y0 >= 1 && d.x1 > d.x0;
    }

    return svg.node();
  }

  render() {
    //this.draw(this.props);
    return (
      <div ref={this.inRef} className="media-sector" style={{ borderRadius: '50%', overflow: 'hidden', position: 'relative' }}/>
    )
  }
}

/*
  const data = {
    name: 'l1',
    size: 100,
    children: [
      {name: 'ch1', size: 30, children: [{name: 'gch1', size: 10}, {name: 'gch2', size:  5}, {name: 'gch3', size: 15}]},
      {name: 'ch2', size: 20, children: [{name: 'gch1', size: 10}, {name: 'gch2', size:  5}, {name: 'gch3', size:  5}]},
      {name: 'ch3', size: 50, children: [{name: 'gch1', size: 20}, {name: 'gch2', size: 10}, {name: 'gch3', size: 20}]}]
  }
*/

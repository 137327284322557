import React from 'react';
// import { connect } from 'react-redux';
import _ from 'lodash';

import { Row, Button } from 'reactstrap';

import { Wrapper, WrapperNP, NoData } from './Elements/Common'

// import { getSummaryFunds } from 'selectors/enhancer'
import { InnerPageFooter } from 'shared-components/'

import { Holdings } from './Elements/Holdings'
import { SecurityReturns } from './Elements/SecurityReturns'
import { Transactions } from './Elements/Transactions'
import { Allocations } from './Elements/Allocations'
import { ProfitLoss, PortfolioGains, PortfolioValue } from './Elements/ProfitLoss'

class Board extends React.PureComponent {
  constructor(props) {
    super(props);
		this.state = {
			activeFund: '',
      acView: '',
		}
    this.buyHandler = this.buyHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
  }

  buyHandler = (ticker) => {
    // console.log(ticker);
    // setSFMState({
    //   items,
    // });
  }

  deleteHandler = (item) => {
    // console.log(item);
    // const { dropFund, setPortfolioState } = this.props;
    // dropFund({
    //   ticker: item.symbol,
    // });
    // setPortfolioState({ summaryDrawer: false });
  }

  selectHandler = (item, index) => {
    // console.log(item, index);
    // const { selectHandler } = this.props;
    // selectHandler(item, index);
  }

	holdingsItemClickHandler = (activeTicker) => {
    // console.log(activeTicker);
		if (activeTicker) {
			this.setState({ activeFund: activeTicker });
		}
	}

  loadMoreAction = (type) => {
    this.setState({ acView: type });
  }

  render() {
    const { funds, retData, fundStats, summary, afterFinalize } = this.props;
    let { currentTargetPortfolio } = this.props;
		const { activeFund, acView } = this.state;

    const hdExpand = acView === 'holdings',
          txExpand = acView === 'transactions',
          invalidTickersExpand = acView === 'invalidTickers';

    const sortedFunds = _.orderBy(fundStats.funds, ['market_value'], ['desc'])
    let _activeFund = activeFund !== '' ? activeFund : sortedFunds[0].ticker;
    let securityReturns = retData && retData.length > 0 && retData.find((jk) => jk.ticker === _activeFund);

    securityReturns = securityReturns || (funds && funds.length > 0 && funds.find((jj) => jj.ticker === _activeFund) && funds.find((jj) => jj.ticker === _activeFund).return);

    // console.log('Allocation data');
    // console.log(fundStats);
    // console.log(currentTargetPortfolio);
    if (afterFinalize) {
      // console.log(this.props.finalizeData);
      const { finalizeData: { finalizePerformanceImpact } } = this.props;
      if (finalizePerformanceImpact) {
        const { enhancedPerformanceImpact } = finalizePerformanceImpact;
        if (enhancedPerformanceImpact) {
          const { asset_alloc } = enhancedPerformanceImpact;
          currentTargetPortfolio = [{
            ...enhancedPerformanceImpact,
            assetTypeData: asset_alloc || {},
          }];
          // console.log('currentTargetPortfolio ->', currentTargetPortfolio)
        }
      }
    }

    return (
      <div className="summary-wrapper custom-scroll pb-0 pl-0" style={{ marginRight: '-9px', paddingRight: 8, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Row className="compact">
          <WrapperNP className="col-lg-6">
						<PortfolioValue stats={fundStats} />
					</WrapperNP>
          <WrapperNP className="col-lg-6">
            <PortfolioGains stats={fundStats} />
          </WrapperNP>
          <div className="col-lg-6">
            <Row className="compact">
              <Wrapper className="col-lg-12">
                <Holdings
                  title="Portfolio Holdings"
                  expand={hdExpand}
                  summary={summary}
                  items={sortedFunds}
                  actions={FundActions}
                  invalidTickersExpand={invalidTickersExpand}
                  buyHandler={this.buyHandler}
                  deleteHandler={this.deleteHandler}
                  selectHandler={this.selectHandler}
                  loadMoreAction={this.loadMoreAction}
									itemClickHandler={this.holdingsItemClickHandler}
                />
              </Wrapper>
              {(!hdExpand && !invalidTickersExpand) && (
                <React.Fragment>
                  <Wrapper className="col-6">
										<Allocations
                      stats={fundStats}
                      loading={this.props.loading}
                      currentTargetPortfolio={currentTargetPortfolio}
                    />
									</Wrapper>
                  <Wrapper className="col-6">
										<ProfitLoss stats={fundStats} />
									</Wrapper>
                </React.Fragment>
              )}
            </Row>
          </div>
          <div className="col-lg-6">
            <Row className="compact">
              {!txExpand && (
								<Wrapper className="col-lg-12">
									{securityReturns && securityReturns._series ? (
										<SecurityReturns height={(summary.invalidTickerFunds.length > 0) ? 252 : 225} items={securityReturns} />
									) : (
										<NoData height='225px' />
									)}
								</Wrapper>
							)}
              <Wrapper className="col-lg-12">
                <Transactions loadMoreAction={this.loadMoreAction} title="Recent Fund Transactions" items={funds} expand={txExpand} />
              </Wrapper>
            </Row>
          </div>
        </Row>
        {/* <Row className="compact">

        </Row> */}
        <InnerPageFooter style={{ width: "100%", borderTop: "1px solid #d5cbcb", textAlign: "center", fontFamily: "Open Sans", fontSize: "12px", padding: ".4em", background: "#f7f7f7" }} />
      </div>
    )
  }
}

export default Board;

const FundActions = () => {
  return (
    <React.Fragment>
      <Button color={"select"} outline
        className="h6 mb-0 py-1 badge-pill">Buy</Button>
      <Button color={"select"} outline
        className="h6 mb-0 mt-2 py-1 badge-pill">Drop</Button>
    </React.Fragment>
  )
}

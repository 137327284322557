import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import moment from 'moment';
// import { sortBy } from 'lodash';
import { createSubscriptionRequest } from 'actions/profile'
import { Spinner } from 'shared-components'
import ProfileUnsubscriptionModal from '../../../../components/profile/ProfileUnsubscriptionModal'
import ProfileSubscriptionPlan from '../../../../components/profile/ProfileSubscriptionPlan'
import ContinueWithPlanModal from '../../../../components/profile/ContinueWithPlanModal'
import ProfileReactivationModal from '../../../../components/profile/ProfileReactivationModal'
import config from '../../../../env'
// import { Divider, Radio } from 'antd'
import { Button } from 'antd';
import SubscriptionCard from 'components/common/subscriptionCard';
import style from './Subscription.module.scss'
import { Row, Col } from 'antd';

import { DEFAULT_PLANS, PLAN_NAME_MAPPING } from 'DataSet';

const PREMIUM = 'Premium Plan'
const LITE = 'Premium Lite'

class ProfilePricingPlans extends React.Component {
  constructor(props) {
    super(props)
    const selfRole = this.props.profile && this.props.profile.profile.self_role

    this.state = {
      openSubscriptionStatusModal: false,
      openContinueWithPlanModal: false,
      reactivatePlanModal: false,
      isFetching: false,
      currentPlan: this.props.profile.user.plan_name,
      tab:
        this.props.profile.user.plan_name ||
        (selfRole === 'Other/Curious' || selfRole === 'Individual Investor'
          ? LITE
          : PREMIUM),
      selfRole: selfRole,
      planValue: process.env.REACT_APP_SUB_DOMAIN === "retail" ? 'selector' : 'enhancer',
      openSubscriptionCancelModal: false,
    }

    // this.props.createSubscriptionRequest()
    this.generateSessionId = this.generateSessionId.bind(this)
    this.subscribeNow = this.subscribeNow.bind(this)
    this.openReactivatemodal = this.openReactivatemodal.bind(this)
    this.changeUnsubscribeModalStatus = this.changeUnsubscribeModalStatus.bind(
      this
    )
    this.changeContinueWithPlanModalStatus = this.changeContinueWithPlanModalStatus.bind(
      this
    )
    this.changeReactivatePlanModalStatus = this.changeReactivatePlanModalStatus.bind(
      this
    )
    this.onChangeModal = this.onChangeModal.bind(
      this
    )
  }

  generateSessionId(event, type) {
    console.log(type)
    this.setState({ isFetching: true })
    const callBack =
      event === 'reactivate' ? this.openReactivatemodal : this.subscribeNow
    //debugger
    this.props.createSubscriptionRequest({ plan_name: type, planType: this.state.planValue }, callBack)
  }

  subscribeNow(sessionId) {
    this.setState({ isFetching: false })
    // const sessionId = this.props.stripeSessionID
    if (sessionId) {
      this.stripe = window.Stripe(config.stripePublishableKey)
      this.stripe
        .redirectToCheckout({
          sessionId
        })
        .then(result => {
          // console.log("results status",result)
        })
    }
  }

  openReactivatemodal(sessionId) {
    this.setState({ isFetching: false })
    if (sessionId) {
      this.changeReactivatePlanModalStatus(true)
    }
  }

  changeReactivatePlanModalStatus(status) {
    this.setState({
      reactivatePlanModal: status
    })
  }

  changeUnsubscribeModalStatus(status) {
    this.setState({
      openSubscriptionStatusModal: status
    })
  }
  onChangeModal(status) {
    this.setState({
      openSubscriptionCancelModal: status
    })
  }

  changeContinueWithPlanModalStatus(status) {
    this.setState({
      openSubscriptionCancelModal: status
    })
  }

  changePlanSelection = plan => {
    this.setState({ tab: plan })
  }

  componentDidUpdate = (prev) => {
    if (this.props.premiumUser && this.props.profile.user.plan_name === LITE && this.props.userDetails.feature_allowed !== prev.userDetails.feature_allowed && !this.props.userDetails.feature_allowed.includes('enhancer') && this.state.planValue !== "selector") {
      this.setState({
        planValue: "selector"
      });
    }
  }

  render() {
    const { premiumFeatures, premiumUser, subscription } = this.props;
    const { tab } = this.state;
    let defaultPlans = DEFAULT_PLANS;
    let showButtonP = ''
    let showButtonL = ''

    defaultPlans.forEach(item => {
      if (premiumUser && premiumFeatures.includes(item.featureName)) {
        item.isActivated = true;
        item.subscriptionNote = subscription && subscription.subscriber_since ? `Member since ${moment(subscription.subscriber_since).format('DD MMMM, YYYY')}` : '';
      } else if (!item.amount) {
        item.isActivated = true;
      } else {
        item.isActivated = false;
      }
    });

    if (premiumUser) {
      let discoverCard = defaultPlans.find(e => e.featureName === 'discovery');
      let notIncludingDiscover = defaultPlans.filter((l) => l.featureName !== 'discovery');
      //notIncludingDiscover = sortBy(notIncludingDiscover, ['isActivated']).reverse();

      defaultPlans = [
        discoverCard,
        ...notIncludingDiscover,
      ];
    }

    let activatedFeatures = defaultPlans.filter(l => l.isActivated).map(k => k.featureName)

    if (
      this.props.premiumUser &&
      this.props.profile.user.plan_name === PREMIUM &&
      !this.props.cancellation_request_status
    ) {
      showButtonP = (
        <button
          color="tools"
          className={style.update}
          onClick={() => this.setState({ openSubscriptionStatusModal: true })}
        >
          UNSUBSCRIBE
        </button>
      )
      showButtonL = ''
    } else if (
      this.props.premiumUser &&
      this.props.profile.user.plan_name === PREMIUM &&
      this.props.cancellation_request_status
    ) {
      showButtonP = (
        <button
          color="tools"
          className={style.update}
          onClick={() => this.generateSessionId('reactivate', PREMIUM)}
        >
          UPGRADE
        </button>
      )
      showButtonL = ''
    }

    const isPreminumLitePlanSelected = this.props.premiumUser && this.props.profile.user.plan_name === LITE;
    if (
      isPreminumLitePlanSelected &&
      !this.props.cancellation_request_status
    ) {
      showButtonL = (
        <button
          color="tools"
          className={style.update}
          onClick={() => this.setState({ openSubscriptionStatusModal: true })}
        >
          UNSUBSCRIBE
        </button>
      )
      showButtonP = ''
    } else if (
      isPreminumLitePlanSelected &&
      this.props.cancellation_request_status
    ) {
      showButtonL = (
        <button
          color="tools"
          className={style.update}
          onClick={() => this.generateSessionId('reactivate', LITE)}
        >
          UPGRADE
        </button>
      )
      showButtonP = ''
    }

    if (!this.props.premiumUser) {
      showButtonP = (
        <button
          color="tools"
          className={style.update}
          onClick={() => {
            this.generateSessionId('', PREMIUM)
          }}
        >
          UPGRADE
        </button>
      )
      showButtonL = (
        <button
          color="tools"
          className={style.update}
          onClick={() => {
            this.generateSessionId('', LITE)
          }}
        >
          UPGRADE
        </button>
      )
    }

    return (
      <React.Fragment>
        <Spinner isFetching={this.state.isFetching} />
        <ProfileUnsubscriptionModal
          modalStatus={this.state.openSubscriptionStatusModal}
          changeUnsubscribeModalStatus={this.changeUnsubscribeModalStatus}
        />
        <ContinueWithPlanModal
          modalStatus={this.state.openContinueWithPlanModal}
          changeContinueWithPlanModalStatus={
            this.changeContinueWithPlanModalStatus
          }
          premiumUser={this.props.premiumUser}
        />
        <ProfileReactivationModal
          modalStatus={this.state.reactivatePlanModal}
          changeReactivatePlanModalStatus={this.changeReactivatePlanModalStatus}
        />
        <ProfileSubscriptionPlan
          modalType='cancelSubscriptions'
          profile={this.props.profile}
          activatedFeatures={activatedFeatures}
          modalStatus={this.state.openSubscriptionCancelModal}
          onChangeModal={this.onChangeModal}
        />
        <div className={style.pricingContainer}>
          <span className={style.title}>Pricing and plans</span>
          <SubscriptionCard
            defaultPlans={defaultPlans}
            profile={this.props.profile}
            activatedFeatures={activatedFeatures}
            subscription={this.props.subscription}
            premiumUser={this.props.premiumUser}
            premiumFeatures={this.props.premiumFeatures}
          />
          {/* {(this.props.premiumUser) && (
            <Row>
              <Col span={20}></Col>
              <Col span={4}>
                <Button
                  type="secondary"
                  color="tools"
                  onClick={() => this.setState({ openSubscriptionCancelModal: true })}
                  className='subscription-plan-btn'
                >
                  Cancel Subscription
                </Button>
              </Col>
            </Row>
          )} */}

          {/* <div className={style.formGroup}>
            <span className={style.subHeader}>Basic</span>
            <span className={style.type}>Free</span>
          </div> */}
          {/* <ul className={style.list}>
            <li>
              <p>Discover investments </p>
            </li>
            <li>
              <p>Analyze and compare investments </p>
            </li>
            <li>
              <p>Trade and buy funds</p>
            </li>
          </ul> */}

          {/* <div className={style.formGroup}>
            <div className={style.planHeader}>
              {this.state.selfRole !== 'Other/Curious' &&
                this.state.selfRole !== 'Individual Investor' && (
                  <span
                    onClick={() => this.changePlanSelection(PREMIUM)}
                    className={`${tab === PREMIUM && style.active}  ${style.subHeader
                      }`}
                  >
                    Magnifi Pro Plus
                  </span>
                )}
              {this.state.selfRole !== 'Other/Curious' &&
                this.state.selfRole !== 'Individual Investor' && (
                  <div className={style.separator} />
                )}
              <span
                onClick={() => this.changePlanSelection(LITE)}
                className={`${tab === LITE && style.active} ${style.subHeader}`}
              >
                Magnifi Pro
              </span>
            </div>
            <span className={style.type}>
              {tab === PREMIUM ? `$500` : `$250`}/month
            </span>
          </div> */}
          {/* <div className={style.subtext}>
            <p>
              {tab === PREMIUM
                ? 'In addition to all the features of Magnifi Pro, unlock the full power of Magnifi to manage models, portfolios, and client accounts all in one place.'
                : "Modernize investment discovery, proposal generation, and client engagement with Magnifi's natural language and investment intelligence capabilities."}
            </p>
          </div> */}
          {/* {tab === PREMIUM ? (
            <div className={'d-flex flex-column '}>
              <ul className={style.list}>
                <li>
                  <p>
                    Selector: Compare funds, SMAs, and models based on
                    customizable portfolio attributes using Magnifi's patented
                    fund Selector. See the impact of adding new holdings into
                    your portfolio, or download the data to excel.{' '}
                  </p>
                </li>
                <li>
                  <p>
                    Client Proposals: White-label ready proposals document and
                    communicate investment strategies to clients. Use the
                    proposals to store your due diligence for Reg BI,
                    Suitability, and other firm requirements.
                  </p>
                </li>
                <li>
                  <p>
                    Builder: Magnifi's Builder capability helps construct better
                    portfolios via existing models or from scratch and test
                    changes, add thematic tilts, and more.{' '}
                  </p>
                </li>
                <li>
                  <p>
                    Enhancer: Produce instant value by showing clients and
                    prospects enhancements to their portfolios. Generate
                    actionable proposals with clear adjustments to meet
                    individual investment objectives.
                  </p>
                </li>
                <li>
                  <p>
                    Client Portal: Look across all your client accounts in
                    seconds. Use plain-English search to find specific holdings
                    and allocations within accounts, to adjust exposures
                    quickly.{' '}
                  </p>
                </li>
              </ul>
              {showButtonP}
            </div>
          ) : (
            <div className={'d-flex flex-column '}>
              <Radio.Group
                onChange={e => this.setState({ planValue: e.target.value })}
                value={this.state.planValue}
              >
                <ul className={style.list}>
                  {process.env.REACT_APP_SUB_DOMAIN !== 'retail' && (
                    <Fragment>
                      <p>
                        <Radio
                          value="enhancer"
                          style={{ whiteSpace: 'normal' }}
                          disabled={isPreminumLitePlanSelected && !this.props.cancellation_request_status}>
                        </Radio>
                        Enhancer: Produce instant value by showing clients and
                        prospects enhancements to their portfolios. Generate
                        actionable proposals with clear adjustments to meet
                        individual investment objectives.
                      </p>
                      <h4>
                        <Divider>Or</Divider>
                      </h4>
                    </Fragment>
                  )}
                  <p>
                    {process.env.REACT_APP_SUB_DOMAIN === 'retail' ? (
                      <>
                        Cart: Compare funds, SMAs, and models based on
                        customizable portfolio attributes using Magnifi's
                        patented fund Selector. See the impact of adding new
                        holdings into your portfolio, or download the data to
                        excel.
                      </>
                    ) : (
                      <>
                        <Radio
                          value="selector"
                          style={{ whiteSpace: 'normal' }}
                          disabled={isPreminumLitePlanSelected && !this.props.cancellation_request_status}>
                        </Radio>
                        Selector: Compare funds, SMAs, and models based on
                        customizable portfolio attributes using Magnifi's
                        patented fund Selector. See the impact of adding new
                        holdings into your portfolio, or download the data to
                        excel.
                      </>
                    )}
                  </p>
                  <p>
                    Client Proposals: White-label ready proposals document and
                    communicate investment strategies to clients. Use the
                    proposals to store your due diligence for Reg BI,
                    Suitability, and other firm requirements.
                  </p>
                </ul>
              </Radio.Group>
              {showButtonL}
            </div>
          )} */}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ profile, auth }) => ({
  profile,
  useremail: auth.user.email,
  premiumUser: auth.user.premiumUser,
  premiumFeatures: profile.premiumFeatures,
  stripeSessionID: profile.stripeSessionID,
  subscription: profile.profile.subscription,
  subscription_id: auth.user.subscription_id,
  cancellation_request_status: auth.user.cancellation_request_status,
  user: profile.user,
  plan_name: profile.user.plan_name,
  userDetails: auth.user
})

const mapDispatchToProps = {
  createSubscriptionRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePricingPlans)

const CTAButton = ({ tab, user, cancellation_request_status }) => {
  return <div></div>
}

import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, ButtonGroup } from 'reactstrap';
import DataTable, { createTheme } from 'react-data-table-component';
import { PrimaryButton } from 'shared-components';
import { mockData } from '../PortfolioTable/mockData';
import ModelSelectorDropdown from '../Shared/ModelSelectorDropdown';
import { clearHoldingsRequest, pfHoldingsRequest, setSelectedPortfolio, modelSearchSuccess } from 'actions/clientportfolioV2';
import { scrollBarCheck } from '../../Utils';

createTheme('grey-ma', {
  text: {
    primary: '#268bd2',
    secondary: '#2aa198',
  },
  background: {
    default: '#f3f3f3',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#FFFFFF',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
});

class ModelAllocation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {};
  }  

  componentDidMount(){
    scrollBarCheck('.ma-wrapper');
  }

  getClientPfDetails = (cd) => {
    // cd => client Details    
    this.props.setSelectedClient(cd.id);
    // If the client's model portfolio has a ticker or custom portfolio
    // has a name fetch the corresponding pf Holdings
    // Or fetch client Holdings
    let { model_portfolio: mp, custom_portfolio: cp } = cd;
    if(mp.ticker && cd.selected_portfolio){
      this.props.setSelectedModel(mp.portfolio_name);
      this.props.updateClientPfObj(mp.portfolio_name, mp.ticker, cd.id, this.props.clientList);
      // Clear holdings request & search results for spinner
      this.props.modelSearchSuccess([])
      this.props.clearHoldingsRequest();
      this.props.pfHoldingsRequest({ticker: mp.ticker});
    }else if(cp.portfolio_name && cd.selected_portfolio){
      this.props.setSelectedModel(cp.portfolio_name);
      this.props.updateClientPfObj(cp.portfolio_name, '', cd.id, this.props.clientList);
      // Clear holdings request & search results for spinner
      this.props.modelSearchSuccess([])
      this.props.clearHoldingsRequest();
      this.props.pfHoldingsRequest({file_name: cp.portfolio_name});
    }else{
      this.props.setSelectedModel('');
      // Clear holdings request & search results for spinner
      this.props.modelSearchSuccess([])
      this.props.clearHoldingsRequest();
      // Since the client doesn't have any value other than holdings
      // We'll move the tab to Holdings and disable other tabs
      // Once the asset allocation data starts to come, remove
      // the selectedTabIndex
      this.props.setSelectedTabIndex('1');
    }    
  }

  getSelectedPfName = (obj) => {
    if(obj.model_portfolio.ticker){
      return obj.model_portfolio.portfolio_name;
    }else if(obj.custom_portfolio.portfolio_name){
      return obj.custom_portfolio.portfolio_name
    }
    return ''
  }

  render() {

    const { clientList, selectedClient } = this.props;
    const dataMA = clientList;

    const columnsMA = [
      {
        name: 'Name',
        selector: 'name',
        sortable: false,
      },
      {
        name: 'Account No',
        selector: 'acc_no',
        sortable: false,
        center: true
      },
      {
        name: <span>Selected Model <ModelSelectorDropdown refID={clientList[0].id} sendSelectedPortfolio={this.props.sendSelectedPortfolio} selectedPortfolio={''} selectedClient={selectedClient} isGlobalSelect /></span>,
        selector: 'selectedModel',
        cell: (row) => <ModelSelectorDropdown refID={row.id} sendSelectedPortfolio={this.props.sendSelectedPortfolio} selectedPortfolio={this.getSelectedPfName(row)} selectedClient={selectedClient} />,
        allowOverflow: true,
        sortable: false,
        center: true
      }
    ];

    const customStyles = {
      rows: {
        style: {
          minHeight: '35px', // override the row height
        }
      }
    };

    const conditionalRowStyles = [
      {
        // If a portfolio is selected highlight it or highlight the first row
        when: row => selectedClient ? selectedClient == row.id : dataMA[0].id == row.id,
        style: {
          backgroundColor: '#e1effa',
          color: 'white',
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
    ];

    return (
      <React.Fragment>
        <div className="model-panel">
          <div className="model-header ma"><h4>Current Allocation</h4></div>
          <div className="tbl-content ma-wrapper">
            <DataTable
              noHeader
              columns={columnsMA}
              data={dataMA}
              customStyles={customStyles}
              pagination={false}
              theme="grey-ma"
              conditionalRowStyles={conditionalRowStyles}
              onRowClicked={(row) => this.getClientPfDetails(row)}
              fixedHeader={true}
              fixedHeaderScrollHeight={'100vh'}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ clientportfolio: { clientList } }) => ({
  clientList
})

const mapDispatchToProps = {
  clearHoldingsRequest,
  pfHoldingsRequest,
  setSelectedPortfolio,
  modelSearchSuccess
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelAllocation);
import axios from 'axios'
import { isEmpty } from 'lodash'

import * as AuthUtils from 'common/auth-utils'
// import { Api } from '../DataSet'
import { oktaAuth } from 'oktaAuth';
import config from '../env'

export const API_ROOT = config.apiBaseUrl

const defaultConfig = {
  baseURL: ''
}

export function getBaseUrl(config) {
  const instanceConfig = Object.assign({}, defaultConfig, config)
  return API_ROOT + instanceConfig.baseURL
}

export function createAPIInstance(config) {
  // Set config defaults when creating the instance
  const authToken = window.localStorage.getItem('token');
  let tokenHeader = isEmpty(authToken) ? {} : {
    Authorization: `Token ${authToken}`
  }
  // const tokenType = window.localStorage.getItem('tokenType');
  // if (tokenType && authToken) {
  //   tokenHeader = isEmpty(authToken) ? {} : {
  //     Authorization: `${tokenType} ${authToken}`
  //   }
  // }
  const api = axios.create({
    baseURL: getBaseUrl(config),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-ossf-domain-source': process.env.REACT_APP_SUB_DOMAIN,
      ...tokenHeader
    }
  })

  api.interceptors.response.use(
    response => {
      return response
    },
    error => {
      // if (error &&
      //   error.response &&
      //   error.response.status === 502 &&
      //   window.location &&
      //   window.location.pathname !== '/maintenance'
      // ) {
      //   window.location = '/maintenance'
      //   return null
      // }

      if (error && error.response && error.response.status === 401) {
        AuthUtils.deleteAuthToken()
        oktaAuth.signOut()
        window.location = '/login'
        return null
      }

      return Promise.reject(error)
    }
  )
  return api
}

export function createAPIInstanceForOkta(config) {
  // Set config defaults when creating the instance
  const authToken = window.localStorage.getItem('token');
  const tokenHeader = isEmpty(authToken) ? {} : {
    Authorization: `Token ${authToken}`
  }
  const api = axios.create({
    baseURL: getBaseUrl(config),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-ossf-domain-source': process.env.REACT_APP_SUB_DOMAIN,
      ...tokenHeader
    }
  })

  api.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error && error.response && error.response.status === 401) {
        AuthUtils.deleteAuthToken()
        oktaAuth.signOut();
        window.location = '/login'
        return null
      }

      return Promise.reject(error.response)
    }
  )
  return api
}

import React, { useContext } from 'react';
import { Row, Col, Table } from 'reactstrap';

import { withHeaderFooter } from '../shared/HeaderFooter';

const IndexPage = withHeaderFooter((props) => {
  const { data, portfolioReport, indexPageArr, prtQuery, recomEnhCount, reportData } = props;
  const { portfolio: { isModelPortfolioQuery } } = reportData;

  const title = () => {
    switch (prtQuery) {
      case 'all' :
        return 'Portfolio Enhancements'

      case 'outperform' :
        return 'Category funds that outperform ...'

      case 'similar' :
        return 'Low cost alternatives to ...'

      default :
      return 'Portfolio Enhancements'
    }
  }

  return (
    <React.Fragment>
      <div className="content"> 
        <div className="pr-v2-header">
          <h3>Table of Contents</h3>
        </div>
        <Table striped className="pr-v2-toc">
          <tbody>
            <tr>
              <td scope="row">Executive Summary</td>
              <td>01</td>
            </tr>
            {isModelPortfolioQuery && <tr>
              <td scope="row">Suggested Model Replacement</td>
              <td>02</td>
            </tr>}
            <tr>
              <td scope="row">Current vs Enhanced Performance Analytics</td>
              <td>{isModelPortfolioQuery ? '03' : '02'}</td>
            </tr>
            <tr>
              <td scope="row">Conclusion</td>
              <td>{isModelPortfolioQuery ? '05' : '04'}</td>
            </tr>
            {!isModelPortfolioQuery &&
              <tr>
                <td scope="row">Appendix - Recommended Replacements</td>
                <td>05</td>
              </tr>
            }
            <tr style={{height: '57px'}}>
              <td scope="row"></td>
              <td></td>
            </tr>
            {/*<tr>
              <td scope="row">Glossary</td>
              <td>{((recomEnhCount + 4) < 10) ? '0'+(recomEnhCount + 4) : recomEnhCount + 4}</td>
            </tr>*/}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  )
});
export default IndexPage;

import React from 'react'
import { get } from 'lodash';
import { connect } from 'react-redux';
class PaymentSuccessMessage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { planName, featureAllowed } = this.props;
    return (
      <React.Fragment>
        <div className="premium-feature-process-modal-container payment-status-modal">
          <div className="payment-message-image text-align-center">
            <img src={'/assets/images/congratulations-Icon.svg'} alt="Congratulations"/>
          </div>
          <div className="payment-status-heading">
            <h1 className="text-align-center payment-success">Congratulations!</h1>
          </div>
          <i className="fal fa-times-circle premium-feature-completed-close close-light" onClick={this.props.closePaymentStatus}></i>
          <p className="text-align-center mb-0">
            {(planName === 'Premium Plan') ? (
              <>You can now experience the full power of Magnifi!</>
            ) : (
              <>You can now experience the full power of {featureAllowed && featureAllowed.includes('enhancer') ? "Discovery and Enhancer" : "Discovery and Selector"} tools</>
            )}
          </p>
        </div>
      </React.Fragment>
    )
  }
}
const mapStateToProps = ({ profile }) => ({
  featureAllowed: get(profile.user, 'feature_allowed', []),
})
// export default PaymentSuccessMessage
export default connect(
  mapStateToProps,
  {}
)(PaymentSuccessMessage)
import React from 'react';
import { Table } from 'reactstrap';
import { withHeaderFooter } from '../Shared/HeaderFooter';

const IndexPage = withHeaderFooter((props) => {
  return (
    <React.Fragment>
      <div className="content">
        <div className="pr-v2-header">
          <h3>Table of Contents</h3>
        </div>
        <div className="mdl-enh-rep-sub-header">
          <h4>Enhance using Model Portfolio</h4>
        </div>
        <Table striped className="pr-v2-toc">
          <tbody>
            <tr>
              <td scope="row">Overview of Suggested Replacement</td>
              <td>01</td>
            </tr>
            <tr>
              <td scope="row">Additional Recommendations Which Were Not Selected</td>
              <td>02</td>
            </tr>
            <tr style={{height: '57px'}}>
              <td scope="row"></td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  )
});
export default IndexPage;

import React, { useContext } from 'react';
import ActReportContext from '../ActReportContext';

const ConclusionCompareOnly = () => {
  const reportData = useContext(ActReportContext)
  // let ticker = reportData.compare[0].funds[0].ticker;
  // let correctedQuery = reportData.search.correctedQuery;
  const { bestTicker } = reportData;
  return (

    <div className="pr-v2-conclusion">
      <div className="pr-v2-sec-title">
        <p>{bestTicker} {bestTicker.includes('and') ? 'are' : 'is'} the best fit based upon thematic exposure, due diligence criteria chosen by the advisor, data provided by third party data providers, and the time frame chosen by the advisor.</p>
        {/* <h3>Conclusion</h3> */}
        {/* <p>
          The best investment option for you {bestTicker.includes('and') ? 'are' : 'is'} "{bestTicker}".
        </p> */}
      </div>
    </div>
  )
}

export default ConclusionCompareOnly;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Row, Col } from 'reactstrap';
import { logout } from 'actions/auth';
import { setFundsState, refreshFundsState } from 'actions/funds';
import { setGlobalState } from 'actions/global';
import { ReservedRoutes, REDIRECT_DOMAIN_PATH } from 'DataSet';

import Investment from './InvestmentsV2';

class AccountsHome extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    document.body.classList.remove('ht5');
  }

  render() {
    return (
      <React.Fragment>
        <Investment />
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ investment }) => ({
  investment,
})

const mapDispatchToProps = {

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountsHome)

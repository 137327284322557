import React, { Component } from 'react';
import { connect } from 'react-redux'
import classNames from 'classnames';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { setGlobalState } from 'actions/global';
import { withPopover } from 'components/Popover';
import { sortFn, isViewPresentInChartTypes } from 'Utils';
import { SectorsAttrs, QuinItems, cn, AssetType, D3Colors09 } from 'DataSet';
import { RegionColors, SectorColors } from 'data/Colors';
import NotApplicable from 'components/NotApplicable';
import CardName from 'layouts/WebDashboard/SearchResultsV2/ItemCard/CardHelper/CardName';
import FeeReturnRisk from 'layouts/WebDashboard/SearchResultsV2/ItemCard/CardHelper/FeeReturnRisk';
import {
  VolumeChart,
  TopHoldingChart,
  ThemeScoreChart,
  ReturnsChart,
  SharpeGaugeChartWrap,
  TrackingErrorGaugeChartWrap,
  SortinoGaugeChartWrap,
  DynamicGuageChart,
  DividendYieldChart,
  RegionDiversificationChart,
  SectorDiversificationChart,
  HoldingsDiversificationChart,
  AssetChart,
  AssetAllocationChart,
  RegionChart,
  SectorChart,
  FudiciaryChart,
  MarketCapChart,
} from 'layouts/WebDashboard/SearchResultsV3/ItemCard/ChartHelper';

class ItemCard extends Component {
  constructor (props) {
    super(props);
    this.state = {
      dimensions: null,
    };
  }

  componentDidMount() {
    if (this.container !== null && this.container && typeof this.container.offsetWidth !== 'undefined') {
      const { smallScreen, bigScreen } = this.props;
      const chartWidth = this.container ? this.container.offsetWidth : (smallScreen ? 270 : (bigScreen ? 410 : 360));
      const lineChartWidth = chartWidth - 30;
      const lineChartHeight = smallScreen ? 80 : (bigScreen ? 91 : 90);
      this.setState({
        dimensions: {
          width: lineChartWidth,
          height: lineChartHeight,
        },
      });
    }
  }

  getChartDataByViewName = (viewObject, card) => {
    const  { view, mappingVariable } = viewObject;
    return card.chartData.length ? card.chartData.filter((item) => {
      if (mappingVariable && mappingVariable !== '' && mappingVariable.length) {
        return item.display_view_name === view && item.variable === mappingVariable;
      } else {
        return item.display_view_name === view;
      }
    }) : [];
  }

  isDynamicChart(view, _chartData) {
    // staticChart === false && chartType === "odometer"
    let chartData =  _chartData.length ? _chartData.filter((item) => item.view_name === view) : [];
    if(chartData.length && !chartData[0].Static && chartData[0].chart_type === 'odometer') {
      return true;
    }
    return false;
  }

  renderLineChartDesign = () => {
    const { card, smallScreen, bigScreen, itemsKey } = this.props;
    const { dimensions } = this.state;
    const lineChartWidth = dimensions.width;
    const lineChartHeight = dimensions.height;
    if (typeof card._cstats === 'undefined') return <NotApplicable />;

    return (
      <ReturnsChart
        width={lineChartWidth}
        height={lineChartHeight}
        bigScreen={bigScreen}
        smallScreen={smallScreen}
        lineData={card._stats.series}
        fid={cn(card, 'Ticker')}
        line3={card._opticker}
        ydomain={card._returnRange}
        itemsKey={itemsKey}
      />
    )
  }

  render() {
    const { card, viewList, showCarousel, secondaryView, chartData, activeRegion, searchFundsActiveRegion, highlightText, checkPopOpen, firstPop, k, smallScreen, bigScreen } = this.props;
    const lineChartColumn = 'col-4';
    const viewChartsColumn = 'col-2';
    //holding chart data
    // console.log(this.props);
    console.log(activeRegion);
    let othersHolding = card.holdingData.find(e => e.name === 'Others');
    const dataSortedHolding = card.holdingData.filter(e => e.name !== 'Others').sort(sortFn('value'));
    const GuageChartWidth = smallScreen ? 90 : (bigScreen ? 130 : null);
    const PieChartSize = smallScreen ? 74 : (bigScreen ? 100 : null);
    const getView = (_View, secondaryView, staticChart, chartType, mappingVariable, _card, _chrtD) => {
      // console.log('--- mappingVariable ---', mappingVariable, view);
      // console.log(_card.ticker);
      // console.log(_chrtD);

      let view = (_chrtD.length > 1 && mappingVariable === '' && _chrtD.find((g) => g.display_view_name === _View)) ? _chrtD.find((g) => g.display_view_name === _View).view_name : _View;
      let _chartData = (_chrtD.length > 1 && mappingVariable === '') ? _chrtD.filter((g) => g.display_view_name === _View) : _chrtD;

      // console.log(_chartData);
      // console.log(view);
      // console.log('-- !isViewPresentInChartTypes(view) --', !isViewPresentInChartTypes(view), '--- this.isDynamicChart(view, _chartData) ---', this.isDynamicChart(view, _chartData));
      return [
        view === 'Regions'   && <RegionChart width={PieChartSize} height={PieChartSize} key={k} card={_card} chartData={_chartData} name={'Allocations'} colors={RegionColors} activeRegion={activeRegion} searchFundsActiveRegion={searchFundsActiveRegion} view={view} />,
        view === 'Sectors'   && <SectorChart width={PieChartSize} height={PieChartSize} key={k} card={_card} chartData={_chartData} attrs={SectorsAttrs} name={'Sectors'} colors={SectorColors} view={view} />,
        view === 'Assets'    && <AssetChart width={GuageChartWidth} key={k} card={_card} chartData={_chartData} qi={QuinItems[2]} />,
        view === 'Volume'    && <VolumeChart width={GuageChartWidth} key={k} card={_card} chartData={_chartData} qi={QuinItems[1]} />,
        view === 'Top Holdings'  && <TopHoldingChart width={PieChartSize} height={PieChartSize} key={k} card={_card} chartData={_chartData} data={othersHolding ? [...dataSortedHolding, othersHolding] : dataSortedHolding} name={'Top Holdings'} colors={SectorColors} view={view} />,
        // {/* { view === 'Theme'     && card.themeChartData && <AllocationChart data={card.themeChartData} name={'Theme'} colors={SectorColors} /> } */}
        view === 'Asset Allocation' && <AssetAllocationChart width={PieChartSize} height={PieChartSize} key={k} card={_card} chartData={_chartData} attrs={AssetType} name={'Asset Types'} colors={SectorColors} />,
        // {/* { view === 'Impact' && <ESGScoreGaugeChartWrap card={_card} qi={QuinItems[5]}/> } */}
        view === 'Themes' && <ThemeScoreChart width={GuageChartWidth} imgSize={smallScreen ? 40 : 50} key={k} card={_card} chartData={_chartData} name={'Theme Score'}/>,
        (view === 'Diversification' && secondaryView === 'Holdings')&& <HoldingsDiversificationChart width={PieChartSize} height={PieChartSize} key={k} card={_card} chartData={_chartData} colors={D3Colors09} name={'Holdings Diversification'} />,
        (view === 'Diversification' && secondaryView === 'Region') && <RegionDiversificationChart width={PieChartSize} height={PieChartSize} key={k} card={_card} chartData={_chartData} colors={D3Colors09} name={'Region Diversification'} />,
        (view === 'Diversification' && secondaryView === 'Sector') && <SectorDiversificationChart width={PieChartSize} height={PieChartSize} key={k} card={_card} chartData={_chartData} colors={D3Colors09} name={'Sector Diversification'} />,
        (view === 'Return Quality' && secondaryView === 'Sharpe Ratio') && <SharpeGaugeChartWrap width={GuageChartWidth} key={k} card={_card} chartData={_chartData} name={'Sharpe'} />,
        (view === 'Return Quality' && secondaryView === 'Sortino Ratio') && <SortinoGaugeChartWrap width={GuageChartWidth} key={k} card={_card} chartData={_chartData} name={'Sortino'} />,
        (view === 'Return Quality' && secondaryView === 'Tracking Error') && <TrackingErrorGaugeChartWrap width={GuageChartWidth} key={k} card={_card} chartData={_chartData} name={'Tracking Error'} multiplier={100} />,
        view === '1 Year Yield' && <DividendYieldChart width={GuageChartWidth} key={k} card={_card} chartData={_chartData} name={'Dividend Yield'}/>,
        view === 'Fiduciary Risk' && <FudiciaryChart width={GuageChartWidth} key={k} card={_card} chartData={_chartData} name={'Fiduciary Risk'}/> ,
        view === 'Market Cap' && <MarketCapChart width={GuageChartWidth} key={k} card={_card} chartData={_chartData} />,
        (!isViewPresentInChartTypes(view) && this.isDynamicChart(view, _chartData)) && DynamicGuageChart(view, _card, GuageChartWidth, _chartData),
      ];
    };

    const renderSingleChart = () => {
      let forSingleChart = {
        staticChart: '',
        chartType: '',
      };
      const forSingleChartArr = this.getChartDataByViewName(viewList, card);
      if (forSingleChartArr.length && typeof forSingleChartArr[0].Static !== 'undefined') {
        forSingleChart.staticChart = forSingleChartArr[0].Static;
        forSingleChart.chartType = forSingleChartArr[0].chart_type;
      }
      if (viewList.mappingVariable && viewList.mappingVariable !== '' && viewList.mappingVariable.length) {
        const foundQueryVarData = card.chartData.filter((item) => item.display_view_name === viewList.view && item.variable === viewList.mappingVariable);
        const _view = foundQueryVarData && foundQueryVarData[0].view_name;
        const _chartType = foundQueryVarData && foundQueryVarData[0].chartType;
        const _staticChart = foundQueryVarData && foundQueryVarData[0].staticChart;
        // card._chartData = foundQueryVarData;
        // console.log(foundQueryVarData);
        const getViewDesign = getView(_view, viewList.secondaryView, _staticChart, _chartType, viewList.mappingVariable, card, foundQueryVarData);
        return getViewDesign;
      } else {
        card._chartData = card.chartData;
        const getViewDesign = getView(viewList.view, viewList.secondaryView, forSingleChart.staticChart, forSingleChart.chartType, viewList.mappingVariable, card, chartData);
        return getViewDesign;
      }
    }

    const { dimensions } = this.state;

    const styleForNA = (typeof card._stats === 'undefined') ? {
      minHeight: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    } : {};

    const topSectionParent = document.getElementById("topSectionContainer");
    const itemListHeaderWrapperElem = document.getElementById("itemListHeaderWrapper");
    if (topSectionParent && itemListHeaderWrapperElem) {
      const parentTopSectionWrapper = document.getElementById("itemListArea");
      if (parentTopSectionWrapper) {
        let heightForList = itemListHeaderWrapperElem.offsetHeight + topSectionParent.offsetHeight + 60 +"px"; // 20 is padddingTop for itemListHeaderWrapper
        parentTopSectionWrapper.style.height = `calc(95vh - ${heightForList})`;
      }
    }

    return (
      <div className="w-100 media-chart-wrapper d-flex justify-content-center mycharts">
        {renderSingleChart()}
      </div>
      // <Card className={classNames(" mb-2", "app-card", {'border-bg-filter-bg-color': !card._selected, 'border-ssf-orange-d1': card._selected}, "itemCard")}>
      //   <CardBody className="p-0 h-100">
      //     <Row className="no-gutters">
      //       <Col className="col-md-4 col-lg-4 col-sm-12 py-1 pl-1 paddingTpBtLt">
      //         <CardName {...this.props} highlightText={highlightText}/>
      //       </Col>
      //       <Col className="col-lg-2 col-md-3 p-1 paddingTpBt media-none">
      //         {card._cstats &&
      //           <FeeReturnRisk
      //             card={card}
      //             chartData={chartData}
      //             highlightText={highlightText}
      //             checkPopOpen={checkPopOpen}
      //             firstPop={firstPop}
      //             user_return_prefer={this.props.user_return_prefer}
      //             user_risk_prefer={this.props.user_risk_prefer}
      //             setReturnPopover={this.props.setReturnPopover}
      //             setRiskPopover={this.props.setRiskPopover}
      //           />
      //         }
      //         {(typeof card._cstats === 'undefined') && <div className="w-100 h-100 card-background d-flex align-items-center justify-content-center"><NotApplicable /></div>}
      //       </Col>
      //       <Col className="col-2 py-1 paddingTpBt media-none">
      //         <div className="w-100 h-100 card-background d-flex align-items-center chart-block" style={{ justifyContent: viewList.length >= 2 ? 'space-evenly' : 'center' }}>
      //           {renderSingleChart()}
      //         </div>
      //       </Col>
      //       <Col className="col-4 p-1 paddingTpBtRt media-none col-lg-4 col-md-3 w-100">
      //         <div
      //           className="position-relative h-100 card-background d-flex align-items-center justify-content-center w-100"
      //           ref={el => (this.container = el)}
      //           style={{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }}
      //         >
      //           {dimensions && this.renderLineChartDesign()}
      //         </div>
      //       </Col>
      //     </Row>
      //   </CardBody>
      // </Card>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  smallScreen: global.smallScreen,
  bigScreen: global.bigScreen,
});

const mapDispatchToProps = {
  setGlobalState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemCard);

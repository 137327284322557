import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Card } from 'antd';
import _ from 'lodash';

import { createLoadingSelector } from 'selectors/loading-selector';

import { getPortfolioResultSet, getPortfolioEnhanceTickerFilter } from 'selectors/portfolio'
import { portfolioQuery, setPortfolioReplace, getReplacePortfolios, getMyCurrentPortfolio, setPortfolioState  } from 'actions/portfolio';
import { investment } from 'reducers/investment';
import { uniqValues } from 'Utils';

import { Loader } from 'layouts/WebDashboard/AccountsPage/Elements/Loader';
import { customScroll } from '../../../utils';
import CardRow from './CardRow';
import Sfm from '../shared/Sfm';

const QUERY_MAP = {
  'low_cost_alternative_models'  : 'low_cost_alternative',
  'low_risk_alternative_models'  : 'low_risk_alternative',
  'high_yield_alternative_models': 'high_yield_alternative',
  'high_sharpe_alternative_models'      : 'high_sharpe_alternative_models',
  'user_criteria_models'         : 'user_criteria',
};

class EnhancePortfolio extends Component {
  constructor(props){
    super(props);
    this.state = {
      portfolioFunds: props.portfolioFunds,
    };
  }

  componentDidMount() {
    // if (this.props.portfolioFunds && this.props.portfolioFunds.length > 0) {
    //   const portfolioFunds = JSON.parse(JSON.stringify(this.props.portfolioFunds));
    //   portfolioFunds.map((r) => {
    //     r.weight = Math.round(r.weight*100);
    //   });
    //   this.setState({
    //     portfolioFunds,
    //   }, () =>  {
    //     const { getMyCurrentPortfolio, portfolio, query, portfolioName, profile, currentPerformanceImpact, portfolioValue } = this.props;
    //     // console.log(portfolioName);
    //     if (getMyCurrentPortfolio && query && portfolioName) {
    //       // console.log(this.portfolioIdWillBe(portfolioName));
    //       getMyCurrentPortfolio({
    //         query: QUERY_MAP[query],
    //         portfolioId: this.portfolioIdWillBe(portfolioName),
    //         myCurrentPortfolio: {
    //           portfolioDetails: {
    //             name: portfolioName,
    //             profile: profile.profile, // do not change this
    //             riskFeeReturn: currentPerformanceImpact,
    //             chart: currentPerformanceImpact.asset_alloc,
    //           },
    //           holdings: _.orderBy(this.state.portfolioFunds, ['weight'], ['desc']),
    //           portfolioValue,
    //         },
    //       });
    //     }
    //   });
    // }
    if(this.props.portfolio.enhancePortfolio && this.props.portfolio.enhancePortfolio.myCurrentPortfolio &&
      this.props.portfolio.enhancePortfolio.myCurrentPortfolio.targetCardData &&
     _.isEmpty(this.props.portfolio.enhancePortfolio.myCurrentPortfolio.targetCardData) &&
      this.props.summaryDrawer
     ){
       this.props.setPortfolioState({
         summaryDrawer: false
       })
   }
  }

  componentWillReceiveProps(newProps) {
    if(newProps.portfolio.enhancePortfolio && newProps.portfolio.enhancePortfolio.myCurrentPortfolio &&
       newProps.portfolio.enhancePortfolio.myCurrentPortfolio.targetCardData &&
      _.isEmpty(newProps.portfolio.enhancePortfolio.myCurrentPortfolio.targetCardData) &&
       newProps.summaryDrawer
      ){
        this.props.setPortfolioState({
          summaryDrawer: false
        })
    }
  }

  // portfolioIdWillBe = (portfolioName) => {
  //   const { portfolioList, portfolioId } = this.props;
  //   if (portfolioList.length > 0) {
  //     if (portfolioList.find((item) => item.portfolio_name === portfolioName)) {
  //       return portfolioList.find((item) => item.portfolio_name === portfolioName).id;
  //     }
  //   }
  //   return portfolioName
  // }

  componentWillUnmount() {

  }

  componentDidUpdate() {
    customScroll();
  }

  render() {
    const { loading, portfolio, queryEnglish } = this.props;
    return (
      <React.Fragment>
        <div className="model-portfolio-container">
          {!loading &&  (
            portfolio.enhancePortfolio.myCurrentPortfolio &&
            portfolio.enhancePortfolio.myCurrentPortfolio.targetCardData &&
            !_.isEmpty(portfolio.enhancePortfolio.myCurrentPortfolio.targetCardData) ?
            <CardRow {...this.props} /> : (
            <Card className="card-background-grey empty-message-wrapper no-funds-wrapper">
              <img src='/assets/images/icons/NoFundsFound.svg' alt='' />
              <div className="text-box">
                <span>Your portfolio does not have any funds</span>
              </div>
            </Card>
          ))}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ loading, investment, portfolio, profile, funds }) => ({
  profile,
  portfolio,
  funds,
  portfolioList: investment.portfolioList,
  portfolioName: investment.filename,
  portfolioFunds: investment.portfolioFunds,
  portfolioId: investment.portfolioId,
  portfolioValue: investment.portfolioValue,
  query: portfolio.query,
  queryEnglish: portfolio.query_english,
  currentPerformanceImpact: portfolio.currentPerformanceImpact,
  loading: createLoadingSelector(['GET_MY_CURRENT_PORTFOLIO', 'GET_REPLACE_PORTFOLIO_OPTIONS'])({ loading }),
  summaryDrawer: portfolio.summaryDrawer,
});

const mapDispatchToProps = {
  getMyCurrentPortfolio,
  getReplacePortfolios,
  setPortfolioState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnhancePortfolio)

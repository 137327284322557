import React, { Component } from 'react';
import { MultiSeriesLineChart } from './MultiSeriesLineChart';

import moment from 'moment';
// import { isNull } from 'util';
// import { categoryCol, cn } from 'DataSet';
import { getNest, monthToDate, dateToMonth } from 'Utils';
import { calcStats } from 'Stats';

const getLineChartMatrix = (range, dataArr, name) => {
  const arr = JSON.parse(JSON.stringify(dataArr));
  const dd = moment(range.start);
  if (!dd.isValid()) {
    range = {
      start: moment("01 "+moment(range.start, 'MMM YYYY')._i, "DD MMM YYYY"),
      end: moment("01 "+moment(range.end, 'MMM YYYY')._i, "DD MMM YYYY"),
    }
  }

  const rangeFilter = e => {
    let dt = monthToDate(e.d);
    return dt.isAfter(moment(range.start).subtract(1, 'seconds')) && dt.isBefore(moment(range.end).add(1, 'seconds'));
  }

  const dateRangeMonthList = range => {
    let out = [];  // List of months
    let rangeEnd = moment(range.end);
    for (let m = moment(range.start); m.isSameOrBefore(rangeEnd); m.add(1, 'months')) {
      out.push(dateToMonth(m));
    }
    return out;
  }

  const valTraslate = v => v < 0 ? -1*Math.log10((100-v)/100) : Math.log10((100+v)/100);

  let rMin = 1e6, rMax = -1e6;

  let x = dateRangeMonthList(range);

  if (arr && arr.length) {
    let points = arr.filter(rangeFilter);
    if (points.length <= 0) {
      return;
    }

    points.forEach((item, i) => {
      item.v = item.v/100
    });

    let stats = calcStats(points);

    let series = x.map(d => {
      let val = getNest([d, 'cuml'], stats.series);
      let ival = val !== undefined ? valTraslate(val) : val,
          ival2 = ival !== undefined ? getNest([d, 'ret'], stats.series) : 'Inactive';

      if (ival < rMin) rMin = ival;
      if (ival > rMax) rMax = ival;

      // y1 point to current line
      if (name === 'Current') {
        return { x: d, y1: ival, y3: ival2, y4: val};
      } else if (name === 'Enhanced') {
        // y2 point to enhanced line
        // NOTE: Adding 0.04 for padding space between two lines !
        return { x: d, y2: ival, y3: ival2, y4: val};
      }
    });

    const obj = {
      _stats: { ...stats, series },
      _returnRange: { min: -rMax, max: rMax },
      label: `$${Math.round(stats.cuml+100)}`,
      lastValue: Math.round(stats.cuml+100),
      dateRange: range,
    }
    return obj;
  }
}

class PortfolioLineChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSeries: [],
      enhanceSeries: [],
      series: [],
      updatedLinesArr: [],
      rr: [],
      yDomain: ['auto', 'auto'],
    }
  }

  componentDidMount() {
    const { portfolioEnhanceReplace, enhanceStats, currentStats } = this.props;
    this.bakeCurrent(currentStats);
    this.bakeEnhance(portfolioEnhanceReplace, enhanceStats);
  }

  componentDidUpdate() {
    setTimeout(function() {
    const linechart = document.getElementById("portfolio-linechart");
    if (linechart) {
      let y1LineObj, y2LineObj;
      const allSvg = linechart.getElementsByTagName('svg');
      if (allSvg && allSvg.length) {
        const pickFirst = allSvg[0];
        if (pickFirst) {
          const rechartLine = pickFirst.getElementsByClassName("recharts-line");

          if (rechartLine && rechartLine.length) {
            for (let item of rechartLine) {
              const foundTextWrapper = item.getElementsByClassName("recharts-label-list");
              if (foundTextWrapper && foundTextWrapper.length) {
                const textTag = foundTextWrapper[0].getElementsByTagName("text");
                if (textTag && textTag.length) {
                  if (textTag[0].getAttribute("dataKey") === 'y1') {
                    y1LineObj = {
                      id: textTag[0].getAttribute("id"),
                      x: textTag[0].getAttribute("x"),
                      y: textTag[0].getAttribute("y"),
                      dx: textTag[0].getAttribute("dx"),
                      dy: textTag[0].getAttribute("dy"),
                      dataKey: "y1",
                      tag: textTag[0],
                    }
                  }
                  if (textTag[0].getAttribute("dataKey") === 'y2') {
                    y2LineObj = {
                      id: textTag[0].getAttribute("id"),
                      x: textTag[0].getAttribute("x"),
                      y: textTag[0].getAttribute("y"),
                      dx: textTag[0].getAttribute("dx"),
                      dy: textTag[0].getAttribute("dy"),
                      dataKey: "y2",
                      tag: textTag[0],
                    }
                  }
                }
              }
            }
          }
          if (y1LineObj && typeof y1LineObj.y !== 'undefined' && y2LineObj && typeof y2LineObj.y !== 'undefined') {
            const diff = Math.abs(y1LineObj.y - y2LineObj.y);
            if (diff < 10) {

              if (y2LineObj.y > y1LineObj.y) {
                if (y1LineObj.y > 80) {
                  y1LineObj.y = 80;
                }

                if (y2LineObj.y > 80) {
                  y2LineObj.y = 90;
                }
              } else {
                if (y1LineObj.y > 80) {
                  y1LineObj.y = 90;
                }

                if (y2LineObj.y > 80) {
                  y2LineObj.y = 80;
                }
              }

              if ((y1LineObj.y !== 80 || y1LineObj.y !== 90) && (y2LineObj.y !== 90 || y2LineObj.y !== 80)) {
                const parY1 = parseInt(y1LineObj.y);
                const parY2 = parseInt(y2LineObj.y);
                if (parY1 > parY2) {
                  // console.log('y1 off will be down ');
                  y1LineObj.y = parY1+15;
                  y2LineObj.y = (parY2-4 <= 4) ? 5 : parY2+10;
                } else {
                  // console.log('y2 off will be down ');
                  // console.log(parY1-diff, (parY1-diff <= 4));
                  y1LineObj.y = (parY1-4 <= 4) ? 5 : parY1+10;
                  y2LineObj.y = parY2+15;
                }
              }

              const setY1 = Math.round(y1LineObj.y);
              const setY2 = Math.round(y2LineObj.y);

              y1LineObj.tag.setAttribute("y", setY1);
              y2LineObj.tag.setAttribute("y", setY2);

            } else {
              // console.log(y1LineObj, y2LineObj);
            }
          }
        }
      }
    }
    }, 100)
  }

  componentWillReceiveProps(newProps) {
    if (newProps.portfolioEnhanceReplace && newProps.enhanceStats && newProps.enhanceStats !== this.props.enhanceStats) {
      const { portfolioEnhanceReplace, enhanceStats, currentStats } = newProps;
      this.bakeEnhance(portfolioEnhanceReplace, enhanceStats);
    }

    if (newProps.currentStats && newProps.currentStats !== this.props.currentStats) {
      this.bakeCurrent(newProps.currentStats);
    }
  }

  makeCurrentSeries = (data, label) => {
    const arr1 = [];
    const data1 = JSON.parse(JSON.stringify(data));
    if (data1 && typeof data1 !== 'undefined' && data1.length) {
      const eleminateLastBlankData = data1[data1.length-1].v === 0 ? data1.splice(0, data1.length - 1, 1): data1;
      eleminateLastBlankData.map((a) => {
        arr1.push({ d: a.d, y1: a.v, label, v: a.v});
      });
      const range = this.getRangeFromSeries(arr1);
      if (range) {
        const getCurrentComlSeries = getLineChartMatrix(range, arr1, label);
        return getCurrentComlSeries;
      }
    } else {
      return arr1; // return []
    }
  }

  makeEnhancedSeries = (data, label) => {
    const arr2 = [];
    const data2 = JSON.parse(JSON.stringify(data));
    if (data2 && typeof data2 !== 'undefined' && data2.length) {
      const eleminateLastBlankData = data2[data2.length-1].v === 0 ? data2.splice(0, data2.length - 1, 1): data2;
      eleminateLastBlankData.map((a) => {
        arr2.push({ d: a.d, y2: a.v, label, v: a.v});
      });
      const range = this.getRangeFromSeries(arr2);
      if (range) {
        const getEnhancedCumlSeries = getLineChartMatrix(range, arr2, label);
        return getEnhancedCumlSeries;
      }
    } else {
      return arr2; // return []
    }
  }

  getRangeFromSeries = (series) => {
    if (!series && !series.length) return null;
    if (series.length > 2) {
      return {
        start: series[0].d,
        end: series[series.length - 1].d,
      };
    } else {
      return {
        start: series[0].d,
        end: series[0].d,
      };
    }
  }

  bakeEnhance = (portfolioEnhanceReplace, enhanceStats) => {
    const enhanceSeries = (portfolioEnhanceReplace.length && enhanceStats && typeof enhanceStats !== 'undefined') ? enhanceStats : [];
    if (!enhanceSeries || typeof enhanceSeries === 'undefined' || enhanceSeries.length === 0) {
      this.setState({
        enhanceSeries: null,
      }, () => {
        this.plotSomething();
      });
    } else {
      //resulting arr: [{ name: '', y2: 1.0, label: 'enhanced' }, .... ]
      const eS = this.makeEnhancedSeries(enhanceSeries, 'Enhanced');

      if (eS) {
        this.setState({
          enhanceSeries: {
            ykey: 'y2',
            name: 'Enhanced',
            color: '#1e5f91',
            ...eS,
          },
        }, () => {
          this.plotSomething();
        });
      }
    }

  }

  bakeCurrent = (currentStats) => {
    const currentStatsArr = currentStats ? ((currentStats && typeof currentStats !== 'undefined') ? currentStats : []) : [];
    //resulting arr: [{ name: '', y1: 1.0, label: 'current' }, .... ]
    const cS = this.makeCurrentSeries(currentStatsArr, 'Current');
    if (cS) {
      this.setState({
        currentSeries: {
          ykey: 'y1',
          name: 'Current',
          color: '#56a9e8',
          ...cS,
        },
      }, () => {
        this.plotSomething();
      });
    }
  }

  plotSomething = () => {
    // let yDomain = ['auto', 'auto'];
    const { currentSeries, enhanceSeries } = this.state;
    let combineSeries = [];
    if (currentSeries && currentSeries._stats) {
      currentSeries._stats.series.map((sD, sI) => {
        if (enhanceSeries && enhanceSeries._stats) {
          enhanceSeries._stats.series.map((eD, eI) => {
            if (eD.x === sD.x) {
              combineSeries.push({
                x: eD.x,
                y1: sD.y1,
                y2: eD.y2,
                y1T: sD.y3,
                y2T: eD.y3,
                y1Val: sD.y4,
                y2Val: eD.y4,
              });
            }
          })
        } else {
          combineSeries.push({
            x: sD.x,
            y1: sD.y1,
            y1T: sD.y3,
            y1Val: sD.y4,
          });
        }
      });
    }

    this.setState({
      series: combineSeries,
    }, () => {
      let lines = [];
      // console.log(this.state.currentSeries, this.state.enhanceSeries);
      if (this.state.currentSeries && typeof this.state.currentSeries._stats !== 'undefined' && this.state.currentSeries._stats) {
        // console.log('current present');
        lines = [this.state.currentSeries]
      }

      if (this.state.enhanceSeries && typeof this.state.enhanceSeries._stats !== 'undefined' && this.state.enhanceSeries._stats) {
        // console.log('enhance present');
        lines = [this.state.enhanceSeries]
      }

      if ((this.state.currentSeries && typeof this.state.currentSeries._stats !== 'undefined' && this.state.currentSeries._stats) && (this.state.enhanceSeries && typeof this.state.enhanceSeries._stats !== 'undefined' && this.state.enhanceSeries._stats)) {
        // console.log('custom & enhance, both present');
        if (this.state.currentSeries._stats && this.state.enhanceSeries._stats) {
          lines = [this.state.currentSeries, this.state.enhanceSeries]
        }
      }

      if (lines.length) {
        let min = lines[lines.length - 1]._returnRange.min, max = lines[lines.length - 1]._returnRange.min;
        lines.map((item, index) => {
          if (item._returnRange.min < min) min = item._returnRange.min;
          if (item._returnRange.max > max) max = item._returnRange.max;
        })

        const seriesRange = this.state.series.map((oop) => oop.y2 || oop.y1);
        const minSeriesRange = Math.min(...seriesRange);
        const maxSeriesRange = Math.max(...seriesRange);
        const rMax = Math.max(Math.abs(minSeriesRange), maxSeriesRange);

        this.setState({
          updatedLinesArr: lines,
          // yDomain: [-rMax, rMax],
          yDomain: [0, rMax],
        });
      }
    });

  }

  difference = (a, b) => (Math.abs(a - b))

  render() {
    console.log(this.state.series);
    console.log(this.state.updatedLinesArr);
    return (
      <React.Fragment>
        {(this.state.updatedLinesArr && this.state.series)
          && (
            <MultiSeriesLineChart
              id={this.props.linechartId}
              ydomain={this.state.yDomain}
              lines={this.state.updatedLinesArr}
              series={this.state.series}
              xkey="x"
            />
          )
        }
      </React.Fragment>
    )
  }
}

export default PortfolioLineChart;

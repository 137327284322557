import React from 'react';
import { connect } from 'react-redux';

import { Row, Col, Button } from 'reactstrap';
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import PerfectScrollbar from 'perfect-scrollbar';

import { numFmt } from 'Utils'
import { sponsorLogo } from 'DataSet';
import { investmentAccountView } from 'actions/investment';


export const Wrapper = (props) => {
  return (
    <div className={props.className+" my-1"}>
      <div className="p-3 bg-white rounded border border-gray-2 h-100">
        {props.children}
      </div>
    </div>
  )
}

export const WrapperNP = (props) => {
  return (
    <div className={props.className+" my-1"}>
      <div className="bg-white rounded border border-gray-2 h-100">
        {props.children}
      </div>
    </div>
  )
}

export class SelectorDD extends React.PureComponent {
  state = { dropdownOpen: false, opt: this.props.opts[0] }

  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  selectOpt = opt => event => this.setState({ opt }, () => this.props.optHandler(opt))

  render() {
    const { dropdownOpen, opt } = this.state;
    return (
      <Dropdown isOpen={dropdownOpen} toggle={this.toggle} className="header float-right lh-100">
        <DropdownToggle caret color="link" className="p-0 lh-100">By {opt}</DropdownToggle>
        <DropdownMenu style={{minWidth:'6rem'}}>
          { this.props.opts.map(e => (
              <DropdownItem key={e} onClick={this.selectOpt(e)} className="py-1 fs-13">By {e}</DropdownItem>
          )) }
        </DropdownMenu>
      </Dropdown>
    )
  }
}


class MoreButton_ extends React.PureComponent {
  clickHandler = () => {
    const { layout, expand } = this.props;
    this.props.investmentAccountView(expand ? 'default' : layout);
  }

  content = () => {
    const { expand } = this.props;
    return expand
    ? <i className="fal fa-times"></i>
    : <React.Fragment>More<i className="fas fa-caret-right fs-11 ml-1"></i></React.Fragment>
  }

  render() {
    const { expand, more } = this.props;
    if (!expand && !more) return null;

    return (
      <Button color={"link"} size="sm" className="lh-100 p-0 float-right" onClick={this.clickHandler}>
        {this.content()}
      </Button>
    )
  }
}
export const MoreButton = connect(
  null,
  {
    investmentAccountView,
  }
)(MoreButton_);


export const KeyStats2 = (props) => {
  const { stats, prec = 0 } = props;

  return (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{minHeight: '80px'}}>
      <Row className="w-100">
        <Col xs="6" className="text-center">
          <h5 className="text-gray-4">{stats[0].name}</h5>
          <h1 className="text-secondary h1p5">{FormatCurrency(stats[0].value, prec)}</h1>
        </Col>
        <Col xs="6" className="text-center">
          <h5 className="text-gray-4">{stats[1].name}</h5>
          <h1 className="text-secondary h1p5">{FormatCurrency(stats[1].value, prec)}</h1>
        </Col>
      </Row>
    </div>
  )
}


export class KeyStats3 extends React.Component {
  state = { view: 1 }

  viewHandler = view => ev => this.setState({ view });

  render() {
    const { stats, prec = 0 } = this.props;
    const { view } = this.state;

    const view1 = (
      <Row className="no-gutters bg-wave1" style={{minHeight: '120px'}}>
        <Col xs="12">
          <div className="pt-3 pl-5 pr-3">
            <Button color="link" className="float-right p-0" onClick={this.viewHandler(2)}>
              {stats[1].name}
              <i className="fal fa-minus fa-rotate-90 mx-0p5"></i>
              {stats[2].name}
            </Button>
            <div className="fs-s1 tx-c3 mb-1">{stats[0].name}</div>
            <h1 className="fs-l1 fw-b tx-c2">{stats[0].value !== 0 && this.props.view !== "watchlist" ? FormatCurrency(stats[0].value, prec) : '--'}</h1>
          </div>
        </Col>
      </Row>
    )

    const view2 = (
      <Row className="no-gutters bg-wave2" style={{minHeight: '120px'}}>
        <Col xs="6">
          <div className="pt-3 pl-5 pr-3">
            <div className="fs-s1 tx-c3 mb-1">{stats[1].name}</div>
            <h1 className="fs-l1 fw-b tx-c2">{stats[1].value !== 0 && this.props.view !== "watchlist" ? FormatCurrency(stats[1].value, prec) : '--'}</h1>
          </div>
        </Col>
        <Col xs="6" className="border-left border-gray-2">
          <div className="pt-3 pl-5 pr-3">
            <Button color="link" className="float-right p-0" onClick={this.viewHandler(1)}>
              <i className="fal fa-times"></i>
            </Button>
            <div className="fs-s1 tx-c3 mb-1">{stats[2].name}</div>
            <h1 className="fs-l1 fw-b tx-c2">{stats[2].value !== 0 && this.props.view !== "watchlist" ? FormatCurrency(stats[2].value, prec) : '--'}</h1>
          </div>
        </Col>
      </Row>
    )

    return view === 1 ? view1 : view2;
  }
}

export class KeyStats4 extends React.Component {
  state = { view: 1 }

  viewHandler = view => ev => this.setState({ view });

  render() {
    const { stats, prec = 0 } = this.props;
    const { view } = this.state;

    const view1 = (
      <Row className="no-gutters bg-wave1" style={{minHeight: '120px'}}>
        <Col xs="12">
          <div className="pt-3 pl-5 pr-3">
            <Button color="link" className="float-right p-0" onClick={this.viewHandler(2)}>
              {stats[1].name}
              <i className="fal fa-minus fa-rotate-90 mx-0p5"></i>
              {stats[2].name}
            </Button>
            <div className="fs-s1 tx-c3 mb-1">{stats[0].name}</div>
            <h1 className="fs-l1 fw-b tx-c2">{stats[0].value == 0 ? '$0' : FormatCurrency(stats[0].value, prec)}</h1>
          </div>
        </Col>
      </Row>
    )

    const view2 = (
      <Row className="no-gutters bg-wave2" style={{minHeight: '120px'}}>
        <Col xs="6">
          <div className="pt-3 pl-5 pr-3">
            <div className="fs-s1 tx-c3 mb-1">{stats[1].name}</div>
            <h1 className="fs-l1 fw-b tx-c2">{stats[1].value == 0 ? '$0' : FormatCurrency(stats[1].value, prec)}</h1>
          </div>
        </Col>
        <Col xs="6" className="border-left border-gray-2">
          <div className="pt-3 pl-5 pr-3">
            <Button color="link" className="float-right p-0" onClick={this.viewHandler(1)}>
              <i className="fal fa-times"></i>
            </Button>
            <div className="fs-s1 tx-c3 mb-1">{stats[2].name}</div>
            <h1 className="fs-l1 fw-b tx-c2">{stats[2].value == 0 ? '$0' : FormatCurrency(stats[2].value, prec)}</h1>
          </div>
        </Col>
      </Row>
    )

    return view === 1 ? view1 : view2;
  }
}


export class KeyStats1 extends React.Component {
  render() {
    const { stats, prec = 0 } = this.props;

    return (
      <Row className="no-gutters bg-wave1" style={{minHeight: '120px'}}>
        <Col xs="12">
          <div className="pt-3 pl-5 pr-3">
            <div className="fs-s1 tx-c3 mb-1">{stats[0].name}</div>
            <h1 className="fs-l1 fw-b tx-c2">{stats[0].value !== 0 ? FormatCurrency(stats[0].value, prec) : '--'}</h1>
          </div>
        </Col>
      </Row>
    )
  }
}

export class KeyStatsPort1 extends React.Component {
  render() {
    const { stats, prec = 0 } = this.props;

    return (
      <Row className="no-gutters bg-wave1" style={{height: '100%'}}>
        <Col xs="12" className="port-custom-container">
          <div className="pt-3 pl-5 pr-3">
            <div className="fs-s1 tx-c3 mb-1">{stats[0].name}</div>
            <h1 className="fs-l1 fw-b tx-c2">{stats[0].value !== 0 ? FormatCurrency(stats[0].value, prec) : '--'}</h1>
          </div>
        </Col>
      </Row>
    )
  }
}


export const FormatCurrency = (value, prec = 0, curr = '$') => {
  let _prefix = (value < 0 ? '-' : '');
  let [_value, _suffix, _prec] = [Math.abs(value), '', prec];

  if (_value >= 1e9)
    [_value, _suffix, _prec] = [_value/1e9, 'B', 2];
  else if (_value >= 1e6)
    [_value, _suffix, _prec] = [_value/1e6, 'M', 2];

  if (_value >= 100 && _prec >= 0) _prec = 0;
  else if (_value >= 10 && _prec >= 0) _prec = 1;

  return `${_prefix}${curr}${numFmt(_value, _prec)}${_suffix}`
}

export const TruncatedNameSponsor = ({ ticker, name, sponsor, lines = 1}) => {
  return (
    <div className="w-100">
      {ticker && <h5 className="mb-0 text-blue">{ticker}</h5>}
      <div className="pb-1">
        <img src={sponsor ? sponsorLogo(sponsor) : ''} className="img-fluid" alt={sponsor} style={{height: '20px'}} />
      </div>
      <h5 className="font-weight-bold text-ssf-blue-d2 mb-0 truncate lh-125" title={name}>{name}</h5>
    </div>
  )
}

export const withScrollBar = (WrappedComponent) => {
  return class extends React.Component {
    componentDidMount() {
      this.ps = new PerfectScrollbar('.ps-wrap', { suppressScrollX: true });
    }

    componentWillUnmount() {
      this.ps.destroy();
      this.ps = null;
    }

    render() {
      return (
        <div className="ps-wrap">
          <WrappedComponent {...this.props} />
        </div>
      )
    }
  }
}

export const Placeholder = ({ title, height }) => {
  return (
    <React.Fragment>
      <h5 className="mb-1 text-secondary">{title}</h5>
      <div className="d-flex justify-content-center align-items-center" style={{minHeight: height || '225px'}}>
        <h3 className="pb-3 text-gray-2"><i className="fas fa-puzzle-piece fa-3x" title="Placeholder"></i></h3>
      </div>
    </React.Fragment>
  )
}

export const NoData = ({ height }) => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{height: height}}>
      <h3 className="text-gray-4 mb-4">- no data -</h3>
    </div>
  )
}

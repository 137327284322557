import React, { useContext } from 'react';
import ActReportContext from '../ActReportContext';
import PerformanceWrap from './PerformanceWrap';
import UpDownTableWrap from './UpDownTableWrap';
import CorrelationMatrixWrap from './CorrelationMatrixWrap';
import FactorExposuresWrap from './FactorExposuresWrap';

const PerformanceAnalytics = (props) => {
  const reportData = useContext(ActReportContext);
  let { selectedFunds, correlationData, yearlyReturnsData, printSelection, benchmark, bestTicker } = reportData;
  selectedFunds = selectedFunds.slice(0,12);
  const totFunds = selectedFunds.length;
  const breakPoint = 5;
  return (
    <React.Fragment>
      <PerformanceWrap
        createdDate={props.createdDate}
        selFunds={selectedFunds}
        yearlyReturnsData={yearlyReturnsData}
        totFunds={totFunds}
        breakPoint={breakPoint}
        bestTicker={bestTicker}
        printSelection={printSelection}
        benchmark={benchmark}
      />
      <UpDownTableWrap
        selFunds={selectedFunds}
        totFunds={totFunds}
        breakPoint={breakPoint}
        benchmark={benchmark}
        bestTicker={bestTicker}
        printSelection={printSelection}
      />
      {printSelection.includes('perf-analytics-cor-matrix') && <CorrelationMatrixWrap
        selFunds={selectedFunds}
        correlationData={correlationData}
        totFunds={totFunds}
        pageName={'perf-analytics-cor-matrix'}
        bestTicker={bestTicker}
        printSelection={printSelection}
      />}
      <FactorExposuresWrap
        selFunds={selectedFunds}
        totFunds={totFunds}
        breakPoint={breakPoint}
        bestTicker={bestTicker}
        printSelection={printSelection}
      />
    </React.Fragment>
  )
};

export default PerformanceAnalytics;

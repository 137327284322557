import React, { Component, Fragment } from 'react';
import { Row, Col, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { addEventToAnalytics } from 'Utils';

import InfiniteListHeader from './InfiniteListHeader';
import ClientNameEditModal from 'components/ClientNameEditModal';
import { ShowSpinner } from '../Shared/ShowSpinner';
// import { getColViewName } from '../../Utils';

class InfiniteList extends Component {
	state = {
		editModalVisible: false,
		editClientDetails: {},
	}

	loadMore = () => {
		const { downloadedList, fetchData, loading } = this.props;
		if (loading) {
			return;
		}
		// pagination fallback must be same as defined in "reducer state"
		const pagination = downloadedList && typeof downloadedList.pagination !== 'undefined' ? downloadedList.pagination : {
			count: 0,
			page_number: 0,
			totalListData: 0,
			totalPageNumber : 0,
			currentPageNumber: 0,
		};
		// const dataSourceLength = pagination.totalListData;
		let currentPageNumber = pagination.currentPageNumber;
		if (pagination.currentPageNumber !== pagination.totalPageNumber) {
			currentPageNumber = currentPageNumber + 1;
		}
		if (fetchData) {
			fetchData({
				count: pagination.count,
				page_number: currentPageNumber,
			});
		}
	}

	onItemClick = ({ row, queryVar }) => {
		const { onRowClick } = this.props;
		if (onRowClick && row) {
			onRowClick({ row, queryVar });
		}
	}

	getQueryVarColDesign = ({ row, variable, queryVar, viewName, selectedQueryVarIndex }) => {
		if (!variable || !row || !viewName || typeof selectedQueryVarIndex === 'undefined') return '-';
		if ((!queryVar || !queryVar.length) && !queryVar[selectedQueryVarIndex]) return '-';

		const multiplier = queryVar[selectedQueryVarIndex].multiplier || 1;
		const value = (row[variable] !== '' && row[variable] !== null && !isNaN(row[variable]) && !isNaN( parseFloat(row[variable])*multiplier )) ? (parseFloat(row[variable])*multiplier).toFixed(2) : '-';
		const suffix = (queryVar[selectedQueryVarIndex].view_name === 'Sectors' || queryVar[selectedQueryVarIndex].view_name === 'Regions' || viewName === 'Risk' || viewName === 'Returns') ? '%' : queryVar[selectedQueryVarIndex].suffix;

		if(value === '-') {
			return value;
		}

		return value + suffix;
	}

	editClientAccountName = (event, id, name) => {
		event.preventDefault();
		event.stopPropagation();
		addEventToAnalytics(
			'Accounts Client Name Edit Button Clicked',
			'Accounts Client Name Edit Button Clicked',
			'ACCOUNTS_CLIENT_NAME_EDIT_BUTTON_CLICKED', {
				client_id: id,
				name: name,
			},
			true,
		);
		this.setState({
			editModalVisible: true,
			editClientDetails: {
				client_id: id,
				client_name: name,
			},
		})
	}

	closeEditModalToggle = () => {
		this.setState({
			editModalVisible: false,
			editClientDetails: {
				client_id: '',
				client_name: '',
			},
		})
	}

	render () {
		const {
			loading,
			colSpan,
			variable,
			viewName,
			queryVar,
			selectedRow,
			customHeader,
			downloadedList,
			selectedQueryVarIndex,
			clientUploadModalToggle,
			updateClientAccountNameRequest,
		} = this.props;
		const { pagination } = downloadedList;
		const dataSourceLength = pagination.totalListData;
		const downloaded = (downloadedList && typeof downloadedList.data !== 'undefined') ? downloadedList.data : [];

		let nameColSpan = colSpan['name'] || { span: downloadedList.isSearchQueryBased ? 5 : 6 };
		let accNoColSpan = colSpan['acc_no'] || { span: downloadedList.isSearchQueryBased ? 7 : 9 };
		let custodianColSpan = colSpan['custodian'] || { span: downloadedList.isSearchQueryBased ? 6 : 9 };
		let queryVarColSpan = colSpan['query_var'] || { span: 6 };

		const EllipsisTooltip = ({ ...props }) => {
			if (!props.text) return null;
			return (
				<div
          {...props}
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
          }}
          title={props.text}
        >
          {props.text}
        </div>
			);
		};

		const LoadingData = () => {
			return (
				<Row className="infinite-list-row" key={'loading-more-row'}>
					<Col {...nameColSpan}>
						<Skeleton.Input active size={'small'} />
					</Col>
					<Col {...accNoColSpan}>
						<Skeleton.Input active size={'small'} />
					</Col>
					<Col {...custodianColSpan}>
						<Skeleton.Input active size={'small'} />
					</Col>
					{(downloadedList.isSearchQueryBased) && (
						<Col {...queryVarColSpan}>
							<Skeleton.Input active size={'small'} />
						</Col>
					)}
				</Row>
			);
		};

		return (
			<Fragment>
				<div className="infinite-list-container">
					<InfiniteListHeader headerColData={customHeader} />
					<div
						id="infinite-list-wrapper"
						className="infinite-list-wrapper"
						style={{
							height: downloadedList.isSearchQueryBased ? 'calc(98vh - 374px)' : 'calc(98vh - 332px)',
							overflow: 'auto',
						}}
					>
						<InfiniteScroll
							scrollableTarget="infinite-list-wrapper"
							dataLength={downloaded.length}
							hasMore={downloaded.length < dataSourceLength}
							loader={<LoadingData />}
							// endMessage={<div className='add-more-column' onClick={()=> clientUploadModalToggle(true)}>Add More</div>}
							next={this.loadMore}
						>
							{(downloaded && downloaded.length) ? (
								downloaded.map((item, index) => (
									<Row
										className={`infinite-list-row ${(selectedRow && selectedRow.id && selectedRow.id === item.id && !loading) ? 'selected-row' : ''}`}
										key={index+'_'+item.id+'_'+item.acc_no}
										onClick={() => this.onItemClick({ row: item, queryVar })}
									>
										<Col {...nameColSpan} className={'client-name-col'}>
											<div className={'iconWrapper'}>
												<EllipsisTooltip text={item.name} />
												<i
													onClick={(event) => this.editClientAccountName(event, item.id, item.name)}
													className={`far fa-pencil editIcon`}
													title="Edit Name"
												/>
											</div>
										</Col>
										<Col {...accNoColSpan}>
											<EllipsisTooltip text={item.acc_no} />
										</Col>
										<Col {...custodianColSpan}>
											<EllipsisTooltip text={item.custodian} />
										</Col>
										{(downloadedList.isSearchQueryBased) && (
											<Col {...queryVarColSpan}>
												<EllipsisTooltip text={this.getQueryVarColDesign({ row: item, queryVar, variable, viewName, selectedQueryVarIndex })} />
											</Col>
										)}
									</Row>
								))
							) : (
								<ShowSpinner />
							)}
						</InfiniteScroll>
					</div>
					<div className='add-more-column' onClick={()=> clientUploadModalToggle(true)}>Add More</div>
				</div>
				<ClientNameEditModal
					For={'pagination'}
					data={this.state.editClientDetails}
					visible={this.state.editModalVisible}
					closeModalToggle={this.closeEditModalToggle}
					updateClientAccountNameRequest={updateClientAccountNameRequest}
				/>
			</Fragment>
		)
	}
}

export default InfiniteList;

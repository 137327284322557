import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, ButtonGroup, Form, FormGroup, Label, Input } from 'reactstrap';
import { PrimaryButton } from 'shared-components';
import CustomAlert from 'components/CustomAlert';
import { csvToJSON, checkForDuplicateClients, mapCsvToJSON } from '../../Utils';
import { uploadClientList } from 'actions/clientportfolio';
import { addEventToAnalytics } from 'Utils';

class PortfolioUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      closePfUpload: true,
      csvFormatErrorMessage: null,
    }
  }

  componentDidMount(){
    if(this.props.uploadDemo){
      this.demoUpload();
    }
  }

  handleChange = (e) => {
    const { client_exist, clientList } = this.props;
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = () => {
        const filters = { headersArray: ['name', 'account_number', 'custodian', 'ticker', 'shares'], maxHeadersCount: 8, addHeader: ['price', 'lot_date', 'cost_basis'] };
        // let uploadedClientList = csvToJSON(reader.result)
        // console.log('uploadedClientList ---> ', uploadedClientList);
        let uploadedClientList = JSON.parse(mapCsvToJSON(reader.result, filters));
        console.log('--- uploadedClientList -->', uploadedClientList);
        if (uploadedClientList && typeof uploadedClientList.error !== 'undefined') {
          this.setState({
            csvFormatErrorMessage: uploadedClientList.error || '',
          });
          setTimeout(() => {
            this.setState({
              csvFormatErrorMessage: null,
            });
          }, 2000)
        } else if (uploadedClientList && uploadedClientList.length > 0) {
          if(client_exist && clientList.length > 0) {
            uploadedClientList = checkForDuplicateClients(uploadedClientList, clientList)
          }
          this.props.uploadClientList(uploadedClientList);
          this.props.clientUploadModalToggle(false)
        }
      };
      // start reading the file. When it is done, calls the onload event defined above.
      reader.readAsBinaryString(file);
    }
  }

  demoUpload = () => {
    // Read data from CSV instead of json dump
    fetch('/assets/data/demo_client_accounts.csv')
      .then(response => response.text())
      .then(data => {
        const { client_exist, clientList } = this.props;
        let uploadedClientList = csvToJSON(data);
        if(client_exist && clientList.length > 0){
          uploadedClientList = checkForDuplicateClients(uploadedClientList, clientList)
        }
        this.props.uploadClientList(uploadedClientList)
      })
      .catch(error => console.error(error));
  }

  render() {
    const { showClientUploadModal, client_exist, uploadDemo, redirectTo } = this.props;
    let fileColumns = [{
      index: 'A',
      title: 'Name*',
      required: true,
    }, {
      index: 'B',
      title: 'Account Number*',
      required: true,
    }, {
      index: 'C',
      title: 'Custodian*',
      required: true,
    }, {
      index: 'D',
      title: 'Tickers*',
      required: true,
    }, {
      index: 'E',
      title: 'Shares*',
      required: true,
    }, {
      index: 'F',
      title: 'Price',
      required: false,
    }, {
      index: 'G',
      title: 'Lot Date',
      required: false,
    }, {
      index: 'H',
      title: 'Cost Basis',
      required: false,
    }];

    return (
      <React.Fragment>
        {!uploadDemo &&
          <Modal
            isOpen={this.state.closePfUpload}
            toggle={() => {
              this.setState({closePfUpload: false})
            }}
            centered
            keyboard={false}
            backdrop="static"
            className="pf-up-sm-light"
            backdropClassName="pf-up-sm-light-modal-backdrop"
            >
            <ModalBody className="ssf-modal__body">
              {!client_exist && redirectTo ?
                <Link to={`/${redirectTo}`}>
                  <i className="fal fa-times cp-modal-close"></i>
                </Link> :
                <i className="fal fa-times cp-modal-close cus-pointer"
                  onClick={() => {
                      this.props.clientUploadModalToggle(false)
                      this.setState({closePfUpload: false})
                    }
                  }
                >
                </i>
              }
              <div className="pf-upload-wrapper text-center ">
                <h3>Upload Your Client's Portfolio</h3>
                {/*<FormGroup className="element-box pos-rel">
                  <Label htmlFor="pfName" className="label">Label</Label>
                  <Input type="name" name="pfName" id="pfName" className={'pfName'} />
                </FormGroup>*/}
                <FormGroup className="mb-0">
                  <Label for="uploadPortfolio" className="select-btn-label">
                  <span className="uploadPortfolioBtn"><img src="/assets/images/upload-pf-white.svg" />Click To Upload A New File</span>
                  </Label>
                  <Input
                    type="file"
                    name="file"
                    id="uploadPortfolio"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    value={this.state.upFile}
                    onChange={ (e) => this.handleChange(e) }
                    style={{opacity: 0, position: 'absolute', zIndex: -1}}
                  />
                </FormGroup>
                <div className="table-preview-wrapper">
                  {fileColumns && fileColumns.length > 0 && fileColumns.map((fc) => {
                    return (
                      <div className="table-preview-col" style={{ width: `calc(100% / ${fileColumns.length})` }}>
                        <div className="table-head">
                          <div className="table-title">{fc.index}</div>
                        </div>
                        <div className="table-cell">
                          <div className={`table-title ${fc.required ? 'table-cell-required-field' : ''}`}>
                            {fc.title}
                          </div>
                        </div>
                        <div className="table-cell" style={{ marginTop: 2, padding: '5px 0' }}></div>
                      </div>
                    )
                  })}
                </div>
                <a
                  className="dld-sample"
                  href="/assets/data/mock_client_accounts.csv"
                  style={{ textAlign: 'right' }}
                  onClick={() => {
                    addEventToAnalytics('Client Download Sample', 'Client Download Sample', 'CLIENT_DOWNLOAD_SAMPLE', {}, false)
                  }}
                >
                  Download Sample
                </a>
                {!showClientUploadModal && <a className="dld-sample" style={{ margin: '0 0 25px 0' }} onClick={() => {
                  addEventToAnalytics('Client Add Demo', 'Client Add Demo', 'CLIENT_ADD_DEMO', {}, false)
                  this.demoUpload()
                }}>Use Demo Portfolio</a>}
                <p className="foot-note" style={{ marginTop: '-12px' }}>* Mandatory <br /> Lot date and cost basis are required for tax optimization</p>
              </div>
            </ModalBody>
          </Modal>
        }
        {(this.state.csvFormatErrorMessage && this.state.csvFormatErrorMessage.length > 0) && (
          <CustomAlert className="alert-windown-wrapper" color='danger'>
            {this.state.csvFormatErrorMessage}
          </CustomAlert>
        )}
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = {
  uploadClientList,
}

export default connect(
  null,
  mapDispatchToProps
)(PortfolioUpload);

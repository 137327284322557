const numFmt = (num, prec = 2) => (num || 0).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: prec })

export const FormatCurrency = (value, prec = 0, curr = '$') => {
  let _prefix = (value < 0 ? '-' : '');
  let [_value, _suffix, _prec] = [Math.abs(value), '', prec];

  if (_value >= 1e9)
    [_value, _suffix, _prec] = [_value/1e9, 'B', 2];
  else if (_value >= 1e6)
    [_value, _suffix, _prec] = [_value/1e6, 'M', 2];
  else if (_value >= 1e3)
    [_value, _suffix, _prec] = [_value/1e3, 'K', 2];

  if (_value >= 100 && _prec >= 0) _prec = 0;
  else if (_value >= 10 && _prec >= 0) _prec = 1;

  return `${_prefix}${curr}${numFmt(_value, _prec)}${_suffix}`
}

export const joinNames = names => (
  names.length < 2
  ? names.join('')
  : [names.slice(0, -1).join(', '), names.slice(-1)[0]].join(' and ') 
)

export const getColor = (c, i) => c[i%c.length]

export const AttrColors = ['#3f7367', '#a5685f', '#555'];

export const ScoreAttrName = code => ScoreAttrs[code].name

export const ScoreAttrs = {
  risk: { name: 'Risk' , icon: 'heart-rate' },
  return: { name: 'Return' , icon: 'chart-line' },
  fee: { name: 'Fee' , icon: 'tag' },
}

import React, { Component } from 'react';


class Confirm extends React.Component {

  render() {

    let btn1ClassName = "popup-btn mr-4 " + this.props.type

    return (
      
      <div className="popup-overlay">
          <div className="model-popup md-popup ">
              <a onClick={this.props.closeModal} className="popup-cross"><i className="fa fa-times"></i></a>
              <h1>{this.props.heading}</h1>
              <div className="model-body text-center">

                {this.props.userInput && <div className="reject-document-textarea">
                  <textarea name="userInputArea" placeholder={this.props.placeholder} onChange={this.props.onChange} value={this.props.userInputArea}></textarea>
                </div>}

                <button onClick={this.props.handleModalAction} className={btn1ClassName}>{this.props.btn1}</button>
                <button onClick={this.props.closeModal} className="popup-btn line-btn">{this.props.btn2}</button>
              </div>
          </div>
      </div>
    )
  }

}

export default Confirm;

// absolute imports
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { createLoadingSelector } from 'selectors/loading-selector';
import { Loader } from './Elements/Loader';

import Table from './Elements/Table/index';
import { IconButton } from 'shared-components'
import {getProposalsListRequest} from 'actions/profile'
import Search from '../Search'

class ArchivedList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      headerHeight: '',
      searchedQuery: '',
      fetchingArchivedDetails: false
    }
  }

  componentDidMount() {
  ///  document.body.classList.add('cp-comp');
    this.props.getProposalsListRequest();
  }


  componentWillUnmount() {
  //  document.body.classList.remove('cp-comp');
    // Clear search results if there is any
  //  this.props.searchAccFundsReset();
  }

  getTypedQuery = (query) => {
    if (typeof query !== 'undefined') {
      this.setState({ searchedQuery: query });
    }
  }

  goBack = () => {
    this.props.history.push('/settings/proposals');
  }

  handleSearchText = (e) => {
    this.setState({
      searchedQuery: e
    })
  }

  handleClearIcon = ()=>{
    this.setState({
      searchedQuery: ''
    })
  }

  render() {
    const { searchedQuery, fetchingArchivedDetails } = this.state;
    const {  proposalsList, loadingProposals, initialProposalList } = this.props;

    return (
      <div className="cp-wrap">
        {(loadingProposals)
          && <Loader loading />
        }

        {
          <div className="client-portfolio-screen-wrapper">
            <div className="content-body">
              <div className="general-wrapper gen-spacing archiveWrapper">

                <div onClick={this.goBack} className="content-title archiveTitle">
                  <a><IconButton iconClass="fa-arrow-left" style={{fontSize:'30px'}}/></a>
                  {'Proposals Archive'}
                </div>

                <div className="archiveSearchss">
                  <Search onChange={this.handleSearchText}/>
                  {/*<input value={searchedQuery} type="text" placeholder="Search across downloaded proposals" onChange={this.handleSearchText}/>*/}
                  {/*{searchedQuery.length>0 && <i onClick={this.handleClearIcon} className={'far fa-times fa-lg clear-icon'}></i>}*/}
                  {/*<i className="fa fa-search" ></i>*/}
                </div>

              </div>

              {(!loadingProposals) && <Table searchedQuery={searchedQuery}/>}
            </div>
          </div>
        }

      </div>
    )
  }
}

const mapStateToProps = ({
  profile:{proposalsList, initialProposalList},
  auth: { loggedIn },loading
}) => ({
  loggedIn,
  proposalsList,
  initialProposalList,
  loadingProposals: createLoadingSelector(['GET_SUBSCRIPTION_PLAN', 'PROFILE', 'PREMIUM_FEATURE_ACCESS'])({ loading }),
})

const mapDispatchToProps = {
  getProposalsListRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArchivedList);

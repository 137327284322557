import React, { useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { withHeaderFooter } from './HeaderFooter';
import SimplePieChart from './SimplePieChart';
import { CustomBarChart } from './BarChart';
import PortfolioContext from '../PortfolioContext';
import { numberCheck } from 'layouts/utils';

const AssetAllocation = withHeaderFooter((props) => {
  const reportData = useContext(PortfolioContext)
  console.log('AssetAllocation reportData => ', reportData)
  console.log(props);

  let { basePortfolio: currentPerformanceImpact, comparePortfolio: enhancedPerformanceImpact, ratioString } = reportData;

  console.log('currentPerformanceImpact -->', currentPerformanceImpact)

  // let portfolio = assetAllocData(props.current)
  let EplusTRation = props.ratioString.split('/')[0]
  let bondsRation = 100 - (parseInt(EplusTRation, 10))
  let portfolio = newAssetAllocData({ equities: props.equityValue, bonds: props.bondValue1, others: props.thematicValue });
  console.log('portfolio --> ', portfolio)

  let BenchMarkValues = {
    bondValue: 0,
    equityValue: 0,
    thematicValue: 0
  }

  if (props.selectedBenchmark === 'SPY') {
    BenchMarkValues.equityValue = 100;
  } else if (props.selectedBenchmark === 'AGG') {
    BenchMarkValues.bondValue = 100;
  } else if (props.selectedBenchmark === 'ACWI') {
    BenchMarkValues.equityValue = 100;
  } else if (props.selectedBenchmark && props.selectedBenchmark.match(/\d+/g) && props.selectedBenchmark.match(/\d+/g).length) {
    let vals = props.selectedBenchmark.match(/\d+/g);
    BenchMarkValues.equityValue = parseInt(vals[0]);
    BenchMarkValues.bondValue = parseInt(vals[1]);
  }

  console.log('BenchMarkValues --> ', BenchMarkValues)

  let benchmark = assetAllocData({ equities: BenchMarkValues.equityValue, bonds: BenchMarkValues.bondValue, others: BenchMarkValues.thematicValue })

  if (enhancedPerformanceImpact && enhancedPerformanceImpact.asset_alloc) {
    benchmark = assetAllocData(enhancedPerformanceImpact.asset_alloc);
  }

  // let benchmark = assetAllocData(props.benchmark)
  // let selectedBenchmark = props.selectedBenchmark
  let { up_3yr: upCapture, down_3yr: downCapture } = currentPerformanceImpact

  if (currentPerformanceImpact) {
    if (currentPerformanceImpact.up_10yr && currentPerformanceImpact.down_10yr) {
      upCapture = numberCheck(currentPerformanceImpact.up_10yr, 1);
      downCapture = numberCheck(currentPerformanceImpact.down_10yr, 1);
    } else if (currentPerformanceImpact.up_5yr && currentPerformanceImpact.down_5yr) {
      upCapture = numberCheck(currentPerformanceImpact.up_5yr, 1);
      downCapture = numberCheck(currentPerformanceImpact.down_5yr, 1);
    } else if (currentPerformanceImpact.up_3yr && currentPerformanceImpact.down_3yr) {
      upCapture = numberCheck(currentPerformanceImpact.up_3yr, 1);
      downCapture = numberCheck(currentPerformanceImpact.down_3yr, 1);
    } else if (currentPerformanceImpact.up_1yr && currentPerformanceImpact.down_1yr) {
      upCapture = numberCheck(currentPerformanceImpact.up_1yr, 1);
      downCapture = numberCheck(currentPerformanceImpact.down_1yr, 1);
    }
  }

  let expectedBarData = [
    {
      "name": "Up Market Capture Ratio*",
      "portfolio": upCapture,
      "currentColor": "#1e5f91",
    },
    {
      "name": "Down Market Capture Ratio*",
      "portfolio": downCapture,
      "currentColor": "#1e5f91",
    }
  ];
  let colors = [
    "rgb(29,96,145)",
    "rgb(86,169,232)",
    "rgb(85, 85, 85)"
  ];

  console.log('up/down -> ', expectedBarData)

  return (
    <React.Fragment>
      <div className="content">
        <div style={{ marginBottom: `30px` }} className="pr-v2-port-performance pr-v2-asset-alloc">
          <h4 className="pr-v2-aa-header">Portfolio vs Comparison Portfolio* ({EplusTRation}% SPY + {bondsRation}% AGG)</h4>
          <Row className="pr-v2-pp-sections" style={{ marginTop: '30px' }}>
            <Col xs="12">
              <div className="bg-chart-block">
                <p className="mb-0" style={{ fontSize: '1.3rem', color: '#505050' }}>Up and Down Capture</p>
                <div style={{ height: '320px', width: '890px' }}>
                  <CustomBarChart
                    data={expectedBarData}
                  />
                </div>
                <div className="pr-v2-chart-legends">
                  <p>Portfolio</p>
                </div>
              </div>
              {/* <div>
                <p>Upcapture and Downcapture shows us whether a given fund has gained more or lost less than a broad market benchmark during periods of market strength and weakness. </p>
              </div> */}
            </Col>
          </Row>
        </div>
        <div className="pr-v2-asset-alloc">
          <h4 className="pr-v2-aa-header">Portfolio vs Benchmark Allocations</h4>
          <Row>
            <Col xs="6">
              <div>
                <div className="pr-v2-clm-alignAll">
                  <h4 className="pr-v2-light-blue">Portfolio</h4>
                  <SimplePieChart data={portfolio} colors={colors} decimal={1} width={200} height={200} />
                </div>
              </div>
            </Col>
            <Col xs="6">
              <div className="pr-v2-clm-alignAll">
                <h4 className="pr-v2-dark-blue">Comparison Portfolio*</h4>
                <SimplePieChart data={benchmark} colors={colors} decimal={1} width={200} height={200} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
});

export default AssetAllocation;

export const newAssetAllocData = (obj) => {
  return [
    {
      name: "Equity",
      value: obj.equities,
      originalValue: obj.equities
    },
    {
      name: "Bonds",
      value: obj.bonds,
      originalValue: obj.bonds
    },
    {
      name: "Others*",
      value: obj.others,
      originalValue: obj.others
    }
  ]
}

export const assetAllocData = (obj) => {
  const otherVal = getOthersVal(obj);
  return [
    {
      name: "Equity",
      value: obj.equities,
      originalValue: obj.equities
    },
    {
      name: "Bonds",
      value: obj.bonds,
      originalValue: obj.equities
    },
    {
      name: "Others*",
      value: otherVal,
      originalValue: otherVal
    }
  ]
}

export const getOthersVal = (obj) => {
  let sum = 0;
  for (let key in obj) {
    if (key != 'equities' && key != 'bonds') { sum += obj[key]; }
  }
  return sum;
}

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { createLoadingSelector } from 'selectors/loading-selector';
import { equityBatchRequest } from 'actions/buildportfolio';

import ActContent from './ActContent';
import CardLoader from '../../../Elements/CardLoader';

class ActTab extends Component {
  render() {
    const { actTabLoading, actTab, activeTab } = this.props;
    return (
      <React.Fragment>
        {actTabLoading ? (
          <CardLoader className="skeleton-bg-transparent" skeleton={3} minHeight="50vh" />
        ) : (
          <ActContent {...this.props}/>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ buildportfolio, loading }) => ({
  actTabLoading: createLoadingSelector(['SET_SUMMARY'])({ loading }),
})

const mapDispatchToProps = {
  equityBatchRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActTab);

import React from 'react';
import { Row, Col, Menu, Select } from 'antd';
import { MODEL_PORTFOOLIO_QUERY_MAP } from 'DataSet';
import { CustomBarChart } from './Charts';
import PortfolioLineChart from './PortfolioLineChart';

const { Option } = Select;

const DEFUALT_ORDER_FOR_PORTFOLIO_CARD = ['Fees', 'Returns', 'Risk'];

const ORDER_FOR_PORTFOLIO_CARD = [{
  query: 'similar',
  label: 'Low Cost Alternatives',
  order: ['Fees', 'Returns', 'Risk'],
}, {
  query: 'outperform',
  label: 'Best Performing Options In Category',
  order: ['Returns', 'Risk', 'Fees'],
}, {
  query: 'safe_alternative',
  label: 'Lowest Risk Options',
  order: ['Risk', 'Returns', 'Fees'],
}, {
  query: 'high_yield_alternative',
  label: 'Highest Yield Options',
  option: 'Yield - %age and dollar value',
  order: ['Fees', 'Returns', 'Risk'],
}, {
  query: 'long_track_record_alternatives',
  label: 'Long Track Record Alternatives',
  option: 'Track Record  + Annual fee if needed',
  order: ['Fees', 'Returns', 'Risk'],
}, {
  query: 'user_criteria',
  label: 'Optimize Using My Criteria',
  option: 'Track Record  + Annual fee if needed',
  order: ['Returns', 'Risk', 'Fees'],
}, {
  query: 'low_cost_alternative_models',
  label: 'Low Cost Alternatives',
  order: ['Fees', 'Returns', 'Risk'],
}, {
  query: 'low_risk_alternative_models',
  label: 'Lowest Risk Alternatives',
  order: ['Risk', 'Returns', 'Fees'],
}, {
  query: 'high_yield_alternative_models',
  label: 'Highest Yield Alternatives',
  option: 'Yield - %age and dollar value',
  order: ['Fees', 'Returns', 'Risk'],
}, {
  query: 'high_sharpe_alternative_models',
  label: 'Highest Risk Adjusted Return Alternatives',
  order: ['Fees', 'Returns', 'Risk'],
}, {
  query: 'user_criteria_models',
  label: 'Best Alternative Using My Investment Selector',
  order: ['Returns', 'Risk', 'Fees'],
}];

const getRowDesign = ({ data, cardFor }) => {
  const renderRows = data.map((item) => {
    const children = item.children.map((o) => <Col id={o.id} span={o.span} className={o.className} style={o.style}>{o.col()}</Col>);
    return (
      <Row id={item.id} gutter={item.gutter} className={item.className} style={item.style}>
        {children}
      </Row>
    )
  });

  return (
    <div className={`portfolio-impact-card ${cardFor ? cardFor+'-card' : ''}`}>
      {renderRows}
    </div>
  )
}

const convertAmount = (labelValue) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e+9

  ? Math.round(Math.abs(Number(labelValue)) / 1.0e+9) + "B"
  // Six Zeroes for Millions
  : Math.round(Math.abs(Number(labelValue)) >= 1.0e+6)

  ? Math.round(Math.abs(Number(labelValue)) / 1.0e+6) + "M"

  : Math.round(Math.abs(Number(labelValue)));
  // Three Zeroes for Thousands
  // : Math.abs(Number(labelValue)) >= 1.0e+3
  //
  // ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"
  //
  // : Math.abs(Number(labelValue));
}

export const FeesCard = ({ data, query }) => {
  if (!data && typeof data === 'undefined') return null;

  const { portfolioEnhanceReplace, currentStats, enhanceStats } = data;
  const curCumlValue = (currentStats && typeof currentStats.cumlValue !== 'undefined') ? currentStats.cumlValue : '';
  const enhanceCumlValue = (enhanceStats && typeof enhanceStats.cumlValue !== 'undefined') ? enhanceStats.cumlValue : '';
  let expenseRatioChildren = [{
      col: () => ('Expense Ratio'),
      span: 12,
    },{
      className: 'current-stats-color',
      col: () => (`${currentStats.expenseRatio}%`),
      span: 6,
    }, {
      className: 'enhance-stats-color',
      col: ()=> (''),
      span: 6,
    },
  ];
  let annualFeeChildren = [{
      col: () => ('Annual fee'),
      span: 12,
    },
    {
      className: 'current-stats-color',
      col: () => (`$${convertAmount(Math.round(currentStats.fee))}`),
      span: 6,
    },
    {
      className: 'enhance-stats-color',
      col: ()=> (''),
      span: 6,
    },
  ];
  let yieldFeeChildren = [{
      col: () => ('Yield'),
      span: 12,
    },
    {
      className: 'current-stats-color',
      col: () => (`${currentStats.yield}%`),
      span: 6,
    },
    {
      className: 'enhance-stats-color',
      col: ()=> (''),
      span: 6,
    },
  ];
  let outperformChildren = [{
      col: () => ('Outperform'),
      span: 12,
    },
    {
      className: 'current-stats-color',
      col: () => (`${currentStats.outperform}%`),
      span: 6,
    },
    {
      className: 'enhance-stats-color',
      col: ()=> (''),
      span: 6,
    },
  ];
  let trackRecordChildren = [{
      col: () => ('Track Record'),
      span: 12,
    },
    {
      className: 'current-stats-color',
      col: () => (`${currentStats.trackRecord}yrs`),
      span: 6,
    },
    {
      className: 'enhance-stats-color',
      col: ()=> (''),
      span: 6,
    },
  ];

  if (portfolioEnhanceReplace.length > 0  && enhanceStats) {
    annualFeeChildren = [
      {
        col: () => ('Annual fee'),
        span: 12,
      },
      {
        className: 'current-stats-color',
        col: () => (`$${convertAmount(Math.round(currentStats.fee))}`),
        span: 6,
      },
      {
        className: 'enhance-stats-color',
        col: ()=> (`$${convertAmount(Math.round(enhanceStats.fee))}`),
        span: 6,
      },
    ];
    expenseRatioChildren = [{
        col: () => ('Expense Ratio'),
        span: 12,
      },{
        className: 'current-stats-color',
        col: () => (`${currentStats.expenseRatio}%`),
        span: 6,
      }, {
        className: 'enhance-stats-color',
        col: ()=> (`${enhanceStats.expenseRatio}%`),
        span: 6,
      },
    ];
    yieldFeeChildren = [{
        col: () => ('Yield'),
        span: 12,
      },
      {
        className: 'current-stats-color',
        col: () => (`${currentStats.yield}%`),
        span: 6,
      },
      {
        className: 'enhance-stats-color',
        col: ()=> (`${enhanceStats.yield}%`),
        span: 6,
      },
    ];
    outperformChildren = [{
        col: () => ('Outperform'),
        span: 12,
      },
      {
        className: 'current-stats-color',
        col: () => (`${currentStats.outperform}%`),
        span: 6,
      },
      {
        className: 'enhance-stats-color',
        col: ()=> (`${curCumlValue && enhanceCumlValue ? parseFloat((enhanceCumlValue - curCumlValue).toFixed(2))+'%' : '--'}`),
        span: 6,
      },
    ];
    trackRecordChildren = [{
        col: () => ('Track Record'),
        span: 12,
      },
      {
        className: 'current-stats-color',
        col: () => (`${currentStats.trackRecord}yrs`),
        span: 6,
      },
      {
        className: 'enhance-stats-color',
        col: ()=> (`${enhanceStats.trackRecord}yrs`),
        span: 6,
      },
    ];
  }

  const rowJson = [
    {
      className: '',
      style: { margin: '0px 0px 15px' },
      gutter: [16, 8],
      children: annualFeeChildren,
      query: 'all'
    },
    {
      className: '',
      style: { margin: '0px 0px 15px' },
      gutter: [16, 8],
      children: expenseRatioChildren,
      query: 'all'
    },
    {
      className: '',
      style: { margin: 0 },
      gutter: [16, 8],
      children: yieldFeeChildren,
      query: 'high_yield_alternative'
    },
    {
      className: '',
      style: { margin: 0 },
      gutter: [16, 8],
      children: outperformChildren,
      query: 'outperform'
    },
    {
      className: '',
      style: { margin: 0 },
      gutter: [16, 8],
      children: trackRecordChildren,
      query: 'long_track_record_alternatives'
    }
  ];

  const renderRows = rowJson.map((item, index) => {
    const queryIncludeModels = (query && query.includes('model')) ? MODEL_PORTFOOLIO_QUERY_MAP[query] : query;
    if(item.query == 'all' || item.query == query){
      const children = item.children.map((o) => <Col span={o.span} className={o.className} style={o.style}>{o.col()}</Col>);
      return (
        <Row gutter={item.gutter} className={item.className} style={item.style}>
          {children}
        </Row>
      )
    }
  });

  return (
    <div className={`portfolio-impact-card fees-card`}>
      {renderRows}
    </div>
  )
}

// risk chart
export const RiskCard = ({ query, data,portfolioBenchmarkData,selectedPortfolioBenchmarkTicker,handleUpDownDropDownChange }) => {
  if (!data && typeof data === 'undefined') return null;
	console.log(data)
	const { portfolioEnhanceReplace, currentStats, enhanceStats } = data;

	let maxDrawDown = [
		{
			col: () => ('Max Drawdown'),
			span: 12,
		}, {
			className: 'current-stats-color',
			col: () => (`${currentStats.dMax}%`),
			span: 6,
		}, {
			className: 'enhance-stats-color',
			col: () => (''),
			span: 6,
		},
	]

	let expectedBarData = [
		{
			name: '1 Year',
			current: currentStats.anVol1yr,
			currentColor: '#56a9e8',
		},
		{
			name: '3 years',
			current: currentStats.anVol3yr,
			currentColor: '#56a9e8',
		},
		{
			name: '5 Years',
			current: currentStats.anVol5yr,
			currentColor: '#56a9e8',
		},
	]
	let upData = [
		{
			name: '1 Year',
      current: currentStats.up_1yr,
			currentDown: currentStats.down_1yr,
			currentColor: '#56a9e8',
		 
		},
		{
			name: '3 years',
      current: currentStats.up_3yr,
			currentDown: currentStats.down_3yr,
			currentColor: '#56a9e8',
		 
		},
		{
			name: '5 Years',
      current: currentStats.up_5yr,
			currentDown: currentStats.down_5yr,
			currentColor: '#56a9e8',
		},
	]
  let downData = [
    {
      name: '1 Year',
      current: currentStats.down_1yr,
      currentColor: '#56a9e8',

    },
    {
      name: '3 years',
      current: currentStats.down_3yr,
      currentColor: '#56a9e8',

    },
    {
      name: '5 Years',
      current: currentStats.down_5yr,
      currentColor: '#56a9e8',
    },
  ]
  upData = upData.map(v=>{
    let data = {...v}
    if(v.current===null||v.current===Infinity){
      delete data.current
    }
    return data
  })
  downData = downData.map(v=>{
    let data = {...v}
    if(v.current===null||v.current===Infinity){
      delete data.current
    }
    return data
  })
  if (portfolioEnhanceReplace.length > 0  && enhanceStats) {
    maxDrawDown = [
      {
        col: () => ('Max Drawdown'),
        span: 12,
      },
      {
        className: 'current-stats-color',
        col: () => (`${currentStats.dMax}%`),
        span: 6,
      },
      {
        className: 'enhance-stats-color',
        col: ()=> (`${enhanceStats.dMax}%`),
        span: 6,
      },
    ];
	  expectedBarData = [
		  {
			  name: '1 Year',
			  current: currentStats.anVol1yr,
			  currentColor: '#56a9e8',
			  enhanced: enhanceStats.anVol1yr,
			  enhancedColor: '#1e5f91',
		  },
		  {
			  name: '3 years',
			  current: currentStats.anVol3yr,
			  currentColor: '#56a9e8',
			  enhanced: enhanceStats.anVol3yr,
			  enhancedColor: '#1e5f91',
		  },
		  {
			  name: '5 Years',
			  current: currentStats.anVol5yr,
			  currentColor: '#56a9e8',
			  enhanced: enhanceStats.anVol5yr,
			  enhancedColor: '#1e5f91',
		  },
	  ]
    upData = [
      {
        name: '1 Year',
        current: currentStats.up_1yr,
        currentColor: '#56a9e8',
        enhanced: enhanceStats.up_1yr,
        enhancedColor: '#1e5f91',

      },
      {
        name: '3 years',
        current: currentStats.up_3yr,
        currentColor: '#56a9e8',
        enhanced: enhanceStats.up_3yr,
        enhancedColor: '#1e5f91',

      },
      {
        name: '5 Years',
        current: currentStats.up_5yr,
        currentColor: '#56a9e8',
        enhanced: enhanceStats.up_5yr,
        enhancedColor: '#1e5f91',
      },
    ]
	  downData = [
		  {
			  name: '1 Year',
			  current: currentStats.down_1yr,
			  currentColor: '#56a9e8',
			  enhanced: enhanceStats.down_1yr,
			  enhancedColor: '#1e5f91',

		  },
		  {
			  name: '3 years',
        current: currentStats.down_3yr,
			  currentColor: '#56a9e8',
        enhanced: enhanceStats.down_3yr,
			  enhancedColor: '#1e5f91',

		  },
		  {
			  name: '5 Years',
        current: currentStats.down_5yr,
			  currentColor: '#56a9e8',
        enhanced: enhanceStats.down_5yr,
			  enhancedColor: '#1e5f91',
		  },
	  ]

    upData = upData.map(v=>{
      let data = {...v}
      if(v.current===null||v.current===Infinity){
        delete data.current
      }
      if(v.enhanced===null||v.enhanced===Infinity){
        delete data.enhanced
      }

      return data
    })
    downData = downData.map(v=>{
      let data = {...v}
      if(v.current===null||v.current===Infinity){
        delete data.current
      }
      if(v.enhanced===null||v.enhanced===Infinity){
        delete data.enhanced
      }

      return data
    })
  }

  const rowJson = (query && query.includes('model')) ? [
	  {
		  className: '',
		  style: { margin: '0 0 20px 0' },
		  gutter: [16, 8],
		  children: [
			  {
				  col: () => ('Annualized Volatility'),
				  span: 12,
			  },
			  {
				  col: () => (''),
				  span: 6,
			  },
			  {
				  col: () => (''),
				  span: 6,
			  },
		  ],
	  },
	  {
		  className: '',
		  style: { margin: '0 0 20px 0' },
		  gutter: [],
		  children: [
			  {
				  style: { height: '100%' },
				  col: () => (
					  <div className="barchart-block">
						  <CustomBarChart
							  data={expectedBarData}
						  />
					  </div>
				  ),
				  span: 24,
			  },
		  ],
	  },
	  {
		  className: '',
		  style: { margin: '0 0 20px 0' },
		  gutter: [16, 8],
		  children: maxDrawDown,
	  }
  ] : [
	  {
		  className: '',
		  style: { margin: '0 0 20px 0' },
		  gutter: [16, 8],
		  children: [
			  {
				  col: () => ('Annualized Volatility'),
				  span: 12,
			  },
			  {
				  col: () => (''),
				  span: 6,
			  },
			  {
				  col: () => (''),
				  span: 6,
			  },
		  ],
	  },
	  {
		  className: '',
		  style: { margin: '0 0 20px 0' },
		  gutter: [],
		  children: [
			  {
				  style: { height: '100%' },
				  col: () => (
					  <div className="barchart-block">
						  <CustomBarChart
							  data={expectedBarData}
						  />
					  </div>
				  ),
				  span: 24,
			  },
		  ],
	  },
	  {
		  className: '',
		  style: { margin: '0 0 20px 0' },
		  gutter: [16, 8],
		  children: maxDrawDown,
	  },
	  {
		  className: 'up-down-chart-txt',
		  style: { margin: '0 0 20px 0' },
		  gutter: [16, 8],
		  children: [
			  {
				  col: () => ('Up capture'),
				  span: 12,
			  },
			  {
				  col: () => (''),
				  span: 6,
			  },
			  {
				  col: () => (
            <Select onChange={handleUpDownDropDownChange}
                    value={selectedPortfolioBenchmarkTicker}
                    className='up-down-capture-dropdown'
                    trigger={['click']}
                    dropdownClassName='up-down-capture-menu'>
	            {portfolioBenchmarkData.map(item => <Option key={item.ticker} value={item.ticker}>{item.ticker}</Option>)}
            </Select>
          ),
				  span: 6,
			  },
		  ],
	  },
    {
      className: '',
      style: { margin: '0 0 20px 0' },
      gutter: [],
      children: [
        {
          style: { height: '100%' },
          col: () => (
            <div className="barchart-block">
              <CustomBarChart
                data={upData}
              />
            </div>
          ),
          span: 24,
        },
      ],
    },
    {
      className: 'up-down-chart-txt',
      style: { margin: '0 0 20px 0' },
      gutter: [16, 8],
      children: [
        {
          col: () => ('Down capture'),
          span: 12,
        },
        {
          col: () => (''),
          span: 6,
        },
        {
          col: () => (
	          <Select onChange={handleUpDownDropDownChange}
	                  value={selectedPortfolioBenchmarkTicker}
	                  className='up-down-capture-dropdown'
	                  trigger={['click']}
	                  dropdownClassName='up-down-capture-menu'>
		          {portfolioBenchmarkData.map(item => <Option key={item.ticker} value={item.ticker}>{item.ticker}</Option>)}
	          </Select>
          ),
          span: 6,
        },
      ],
    },
    {
      className: '',
      style: { margin: '0px 0 20px 0' },
      gutter: [],
      children: [
        {
          style: { height: '100%' },
          col: () => (
            <div className="barchart-block">
              <CustomBarChart
                data={downData}
              />
            </div>
          ),
          span: 24,
        },
      ],
    },
  ];

  const renderDesign = getRowDesign({ data: rowJson, cardFor: 'risk' });

  return renderDesign;
}

// returns chart
export const ReturnCard = ({ data, yearChange, defaultYears, dropDownData, selectedYear }) => {
  if (!data && typeof data === 'undefined' && typeof dropDownData === 'undefined' && typeof  dropDownData.pfRet1y === 'undefined') return null;

  const { currentStats } = data;
  const portfolioEnhanceReplace = typeof data.portfolioEnhanceReplace !== 'undefined' ? data.portfolioEnhanceReplace  : [];
  const enhanceStats = typeof data.enhanceStats !== 'undefined' ? data.enhanceStats : null;
  const enhancedSeries = (enhanceStats && typeof enhanceStats.selectedSeries !== 'undefined') ? enhanceStats.selectedSeries : [];
  const currentSeries  = (currentStats && typeof currentStats.selectedSeries !== 'undefined') ? currentStats.selectedSeries : [];
  const sharpeRatioValue = (enhanceStats && typeof enhanceStats[`sharpe_${selectedYear}`] !== 'undefined') ? enhanceStats[`sharpe_${selectedYear}`] : '';

  const onChange = (selected) => {
    if (yearChange) {
      yearChange(selected);
    }
  }

  const years = [
    {
      label: dropDownData['pfRet1y'].year || '1 year',
      value: 'pfRet1y',
      disabled: dropDownData['pfRet1y'].disabled,
      col: dropDownData['pfRet1y'].col || 6,
    },
    {
      label: dropDownData['pfRet3y'].year || '3 years',
      value: 'pfRet3y',
      disabled: dropDownData['pfRet3y'].disabled,
      col: dropDownData['pfRet3y'].col || 6,
    },
    {
      label: dropDownData['pfRet5y'].year || '5 years',
      value: 'pfRet5y',
      disabled: dropDownData['pfRet5y'].disabled,
      col: dropDownData['pfRet5y'].col || 6,
    },
  ];

  const disabledDropDown = false; //dropDownData['pfRet3y'].disabled && dropDownData['pfRet5y'].disabled;

  // console.log(currentSeries);

  const rowJson = [
    {
      className: '',
      id: 'annualized-years',
      style: { margin: '0 0 20px 0' },
      gutter: [16, 8],
      children: [
        {
          col: () => ('Annualized return'),
          span: 12,
        },
        {
          col: () => (''),
          span: years.find((k) => k.col === 12) ? 0 : 6,
          style: { display: years.find((k) => k.col === 12) ? 'none' : '' },
        },
        {
          col: ()=> (
            <Select defaultValue={defaultYears} value={selectedYear} disabled={disabledDropDown} style={{ width: '100%' }} getPopupContainer={() => document.getElementById('annualized-years')} onChange={onChange} bordered={false}>
              {years.map((u) => <Option value={u.value} disabled={u.disabled}>{u.label}</Option>)}
            </Select>
          ),
          span: years.find((k) => k.col === 12) ? 12 : 6,
        },
      ],
    },
    {
      className: 'linechart-block',
      style: { margin: '0 0 20px 0' },
      gutter: [],
      children: [
        {
          style: { height: '100%' },
          col: () => (
            <div className="linechart-block">
              <PortfolioLineChart
                linechartId="portfolio-linechart"
                portfolioEnhanceReplace={portfolioEnhanceReplace}
                currentStats={currentSeries}
                enhanceStats={enhancedSeries}
              />
            </div>
          ),
          span: 24,
        },
      ],
    },
    {
      className: '',
      style: { margin: 0 },
      gutter: [16, 8],
      children: [
        {
          col: () => ('Sharpe Ratio'),
          span: 12,
        },
        {
          className: 'current-stats-color',
          col: () => (currentStats[`sharpe_${selectedYear}`]),
          span: 6,
        },
        {
          className: 'enhance-stats-color',
          col: ()=> (sharpeRatioValue),
          span: 6,
        },
      ],
    }
  ];

  const renderDesign = getRowDesign({ data: rowJson, cardFor: 'return' });

  return renderDesign;
}

export const getOrderOfCard = ({ orderBy, CARD_ORDER_OBJ }) => {
  if ((!ORDER_FOR_PORTFOLIO_CARD && typeof ORDER_FOR_PORTFOLIO_CARD === 'undefined') || ORDER_FOR_PORTFOLIO_CARD.length === 0) return null;

  const ff = ORDER_FOR_PORTFOLIO_CARD.filter(yy => yy.query === orderBy);

  if (ff.length) {
    return ff[0].order.map((ki) => CARD_ORDER_OBJ[ki]);
  } else {
    // console.log('DEFUALT_ORDER_FOR_PORTFOLIO_CARD', DEFUALT_ORDER_FOR_PORTFOLIO_CARD);
    return DEFUALT_ORDER_FOR_PORTFOLIO_CARD.map((ii) => CARD_ORDER_OBJ[ii]);
  }
}

export default {
  FeesCard,
  RiskCard,
  ReturnCard,
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash'
import { Checkbox, Menu, Dropdown, Radio, Tooltip } from 'antd';
import {
  DownOutlined,
} from '@ant-design/icons';

import { getPortfolioStats, getInvestmentWatchlistStats, getInvestmentPositionStats } from 'selectors/investment';
import { setEnhancerState, updateEvaluateCheckboxRequest } from 'actions/enhancer';

import { uniqValues, arrayToHash, addEventToAnalytics } from 'Utils';
import { CN  } from 'DataSet';

import CustomAlert from 'components/CustomAlert';

class Evaluate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      originalTicker: [],
      tickerDropdownVisible: false,
      enhanceTickerFilter: props.enhanceTickerFilter,
      alert: false,
    }
  }

  setPfEnhancementTicker = (e, ticker) => {
    const { updateEvaluateCheckboxRequest, enhanceTickerFilter } = this.props;
		let evaluateTickers = enhanceTickerFilter;
    const val = document.getElementById(e.target.id).checked;

		if (val) {
			evaluateTickers.push(ticker);
		} else {
      if (enhanceTickerFilter.filter((r) => r !== ticker).length !== 0) {
        evaluateTickers = enhanceTickerFilter.filter((r) => r !== ticker);
      } else {
        // console.log('Alert trigger');
        this.setState({
          alert: true
        }, () => {
          setTimeout(() => {
            this.setState({
              alert: false,
            });
          }, 3000);
        })
      }
		}

		if (updateEvaluateCheckboxRequest) {
			updateEvaluateCheckboxRequest({
				evaluateTickers: _.orderBy(evaluateTickers, ['asc']),
			});
		}
  }

  // setAllPfEnhancementTicker = (e) => {
  //   const val = document.getElementById(e.target.id).checked;
	// 	const { totalTargetCardTickers, updateEvaluateCheckboxRequest } = this.props;
	// 	if (val) {
	// 		// console.log('check all ');
	// 		// console.log(totalTargetCardTickers);
	// 		if (updateEvaluateCheckboxRequest) {
	// 			updateEvaluateCheckboxRequest({
	// 				evaluateTickers: totalTargetCardTickers,
	// 			});
	// 		}
	// 	} else {
	// 		// console.log('uncheck all ');
	// 		if (updateEvaluateCheckboxRequest) {
	// 			updateEvaluateCheckboxRequest({
	// 				evaluateTickers: [],
	// 			});
	// 		}
	// 	}
  // }

  handleMenuClick = () => {
    this.setState({
      tickerDropdownVisible: !this.state.tickerDropdownVisible,
    })
  }

  render() {
    const {
			query,
			fundStats,
			fundPortfolios,
			fundUniverse,
			enhanceReplace,
			enhanceTickerFilter,
			replacePortfolioToggle,
      invalidTickerFunds,
		} = this.props;
    const { tickerDropdownVisible, alert } = this.state;
    // console.log(invalidTickerFunds);
    // const unsupportedTickers = invalidTickerFunds.map((kl) => kl)

    const portfolioTickers = (
      <div className="evaluate-ticker-box-container">
        <div className="pf-enhace-option pf-enhance-tick">
          {
            fundPortfolios && fundPortfolios.length > 0 && _.uniqBy(fundPortfolios, 'ticker').map((item, i) => {
              let targetCard = (item && item.target_card && item.target_card.funds && item.target_card.funds.length > 0) ? item.target_card.funds[0] : null;
              return (
                <Checkbox
                  key={i}
                  id={item.ticker}
                  value={item.ticker}
                  checked={enhanceTickerFilter.includes(item.ticker)}
                  onChange={(e) => this.setPfEnhancementTicker(e, item.ticker)}
                >
                  {item.ticker}
                  {/* <span>{item.ticker} </span><span>{(targetCard && targetCard.weight) && (targetCard.weight.toFixed(2) + "%")}</span> */}
                </Checkbox>
              )
            })
          }
        </div>
        {/*
          <div className="checkbox-options-wrap">
            <div>Tickers excluded from analysis</div>
            <div></div>
          </div>
          <div className="checkbox-options-wrap">
            <div>Unsupported tickers</div>
            <div className="unsupported-tickers"></div>
          </div>
        */}
      </div>
    );

    return (
			<div className="prt-cat-coll-wrapper">
        {(alert) && (
            <CustomAlert className="alert-windown-wrapper" color='warning'>
              Atleast one ticker should be selected.
            </CustomAlert>
          )
        }
        {/*
          <Checkbox
            id='all-ticker'
            onChange={(e) => this.setAllPfEnhancementTicker(e)}
            defaultChecked={true}
            checked={fundPortfolios.length === enhanceTickerFilter.length}
          />
        */}
				<Dropdown
					overlayClassName="portfolioMenuListWrapper"
					overlay={portfolioTickers}
					onVisibleChange={this.handleMenuClick}
					visible={tickerDropdownVisible}
					trigger={['click']}>
					<a className="sub-head" style={{ paddingRight: 30, paddingTop: 4 }}>
						Evaluate All Funds <DownOutlined className={'down-fund-arrow'}/>
					</a>
				</Dropdown>
			</div>
    )
  }
}

  const mapStateToProps = state => ({
    query: state.enhancer.query,
    fundUniverse: state.enhancer.fundUniverse,
    fundPortfolios: state.enhancer.fundPortfolios,
    enhanceReplace:state.enhancer.enhanceReplace,
    enhanceTickerFilter: state.enhancer.enhanceTickerFilter,
		totalTargetCardTickers: state.enhancer.totalTargetCardTickers,
    replacePortfolioToggle: state.enhancer.replacePortfolioToggle,
    invalidTickerFunds: state.enhancer.summary.invalidTickerFunds,
    fundStats: getPortfolioStats(state),
  });

  const mapDispatchToProps = {
    setEnhancerState,
		updateEvaluateCheckboxRequest,
  }

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(Evaluate))

import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Input,
  UncontrolledTooltip,
} from 'reactstrap';

import {
  uploadClientListV2
} from 'actions/clientportfolioV2';

import {
  csvToJSON,
  checkForDuplicateClients
} from 'layouts/WebDashboard/ClientPortfolio/Utils';

class ClientUploadModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  handleChange = (e) => {
    const { client_exist, clientList } = this.props;
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = () => {
        let uploadedClientList = csvToJSON(reader.result)
        if(client_exist && clientList.length > 0){
          uploadedClientList = checkForDuplicateClients(uploadedClientList, clientList)
        }
        this.props.uploadClientListV2(uploadedClientList);
        if(uploadedClientList){
          this.props.clientUploadModalToggle(false)
        }
      };
      // start reading the file. When it is done, calls the onload event defined above.
      reader.readAsBinaryString(file);
    }
  }

  render() {
    const { className } = this.props;
    return (
      <div className={`client-upload-modal-wrapper ${className}`}>
        <div className="client-upload-modal-btn">
          <span>Add Multiple Clients</span>
          <Input
            type="file"
            name="file"
            id="uploadPortfolio"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            value={this.state.upFile}
            onChange={(e) => this.handleChange(e)}
          />
        </div>
        <span id="multiple-upload-tooltip"><i className="fal fa-info-circle"></i></span>
        <UncontrolledTooltip className="upl-tool-info" placement="right" target="multiple-upload-tooltip">
          Please upload a .csv file with the following columns –
          <br /><br /> Mandatory :<br /><br />
          1) name – name of client<br />
          2) account number – a numbers identifies the owner of an account<br />
          3) custodian - financial institution that holds customer's securities<br />
          4) ticker - the symbol used on a trading exchange<br />
          5) shares – quantity held<br /><br />
          Optional :<br /><br />
          6) price - the price at which the security was purchased<br />
          7) lot data - number of units of a financial instrument that is traded on an exchange<br />
          8) cost basis - the original value of an asset for tax purposes<br />
        </UncontrolledTooltip>
      </div>
    );
  }
}

const mapDispatchToProps = {
  uploadClientListV2,
}

export default connect(
  null,
  mapDispatchToProps
)(ClientUploadModal);

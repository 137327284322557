import React from 'react';

export const NoProposalsComponent = (props) => {
  return (
  	<div className="w-100 text-center">
	    <div className="flex-column align-item-center">
	      <img width={80} src="/assets/images/NoProposalFound.svg" />
	      <span className="mt-2">{props.message ? props.message : 'No proposals to display!'}</span>
	    </div>
    </div>
  );
}

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { Card, CardBody, Button, Row, Col, PopoverBody, DropdownToggle, DropdownMenu, DropdownItem, Tooltip } from 'reactstrap'
// import Slider from 'react-slick';

import { withPopover } from './Popover'
import { quintileClass, quintileSize, numFmt, arrSum, sortFn, convertToCapitalCase, isViewPresentInChartTypes, getNest, recursiveSearch, formatTickerForView, getImageOrFallback,sponsorPdfUrl } from '../Utils'
import { SimpleLineChart, SimplePieChart, SimpleRadialBarChart, SimpleRadarChart } from './Charts'
import { D3Sunburst } from './charts/D3Charts'
import GaugeChart from './charts/GaugeChart'
import { AllocationAttrs, SectorsAttrs, QuinItems, cn, sponsorLogo, AssetType, D3Colors09, ViewNames } from '../DataSet'
import { RegionChartMapping, SectorChartMapping } from '../ChartData'
import { RegionColors, SectorColors } from '../data/Colors'
import { TruncatedText } from './sf/Common'
import { DonutChartS1 } from './charts/Recharts'
import Flipster from 'components/charts/Flipster'
import { isNull, isUndefined } from 'util'
import NotApplicable from './NotApplicable'
import { stat } from 'fs';
import Relevance from './FundRevelance';
import DetailsModal from 'components/detailsModal';
import { Item } from 'rc-menu';
import { fundsApiCallTime, clearFundsApiLoadPercent } from 'actions/global';
import { searchQuery, dymLogs  } from 'actions/funds';
import { setFundsState  } from 'actions/discovery';
import config from './../env'
import { addEventToAnalytics } from 'Utils';
import {
  VolumeChart,
  TopHoldingChart,
  ThemeScoreChart,
  ReturnsChart,
  SharpeGaugeChartWrap,
  TrackingErrorGaugeChartWrap,
  SortinoGaugeChartWrap,
  DynamicGuageChart,
  DividendYieldChart,
  RegionDiversificationChart,
  SectorDiversificationChart,
  HoldingsDiversificationChart,
  AssetChart,
  AssetAllocationChart,
  RegionChart,
  SectorChart,
  FudiciaryChart,
  MarketCapChart,
} from '../layouts/WebDashboard/SearchResultsV3/ItemCard/ChartHelper'
import {
  Link
} from "react-router-dom";
import { Dropdown ,Menu, Tooltip as AntToolTip } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons'
var classNames = require('classnames');
const iconWidth = 35;

class ItemCard extends Component {
  constructor(props){
    super(props);
    this.state={
      learnMoreDropdown : false
    }
  }

  handleListScroll = () => {
    if(this.state.learnMoreDropdown){
      this.setState({ learnMoreDropdown: false });
    }
  }

  getChartDataByViewName = (viewObject, card, chartData) => {
    const  { view, mappingVariable } = viewObject;
    return chartData.length ? chartData.filter((item) => {
      if (mappingVariable && mappingVariable !== '' && mappingVariable.length) {
        return item.display_view_name === view && item.variable === mappingVariable;
      } else {
        return item.display_view_name === view;
      }
    }) : [];
  }

  isDynamicChart(view, _chartData) {
    // staticChart === false && chartType === "odometer"
    let chartData =  _chartData.length ? _chartData.filter((item) => item.view_name === view) : [];
    if(chartData.length && !chartData[0].Static && chartData[0].chart_type === 'odometer') {
      return true;
    }
    return false;
  }

  render() {
    const { card, view: viewList,funds, showCarousel, secondaryView, chartData, activeRegion, searchFundsActiveRegion, highlightText, checkPopOpen, firstPop, k, smallScreen, bigScreen, sponsors_tickers } = this.props;
    const lineChartColumn = 'col-4';
    const viewChartsColumn = 'col-2';
    let othersHolding = card.holdingData.find(e => e.name === 'Others');
    const dataSortedHolding = card.holdingData.filter(e => e.name !== 'Others').sort(sortFn('value'));
    const GuageChartWidth = smallScreen ? 90 : (bigScreen ? 130 : null);
    const PieChartSize = smallScreen ? 74 : (bigScreen ? 100 : null);
    const getView = (_View, secondaryView, staticChart, chartType, mappingVariable, _card, _chrtD) => {
      let view = (_chrtD.length > 1 && mappingVariable === '' && _chrtD.find((g) => g.display_view_name === _View)) ? _chrtD.find((g) => g.display_view_name === _View).view_name : _View;
      let _chartData = (_chrtD.length > 1 && mappingVariable === '') ? _chrtD.filter((g) => g.display_view_name === _View) : _chrtD;
      // console.log('view -> ', view, view === 'Market Cap');
      return [
        view === 'Regions'   && <RegionChart width={PieChartSize} height={PieChartSize} key={k} card={_card} chartData={_chartData} name={'Allocations'} colors={RegionColors} activeRegion={activeRegion} searchFundsActiveRegion={searchFundsActiveRegion} view={view} />,
        view === 'Sectors'   && <SectorChart width={PieChartSize} height={PieChartSize} key={k} card={_card} chartData={_chartData} attrs={SectorsAttrs} name={'Sectors'} colors={SectorColors} view={view} />,
        view === 'Assets'    && <AssetChart width={GuageChartWidth} key={k} card={_card} chartData={_chartData} qi={QuinItems[2]} />,
        view === 'Volume'    && <VolumeChart width={GuageChartWidth} key={k} card={_card} chartData={_chartData} qi={QuinItems[1]} />,
        view === 'Top Holdings'  && <TopHoldingChart width={PieChartSize} height={PieChartSize} key={k} card={_card} chartData={_chartData} data={othersHolding ? [...dataSortedHolding, othersHolding] : dataSortedHolding} name={'Top Holdings'} colors={SectorColors} view={view} />,
        view === 'Asset Allocation' && <AssetAllocationChart width={PieChartSize} height={PieChartSize} key={k} card={_card} chartData={_chartData} attrs={AssetType} name={'Asset Types'} colors={SectorColors} />,
        view === 'Themes' && <ThemeScoreChart width={GuageChartWidth} imgSize={smallScreen ? 40 : 50} key={k} card={_card} chartData={_chartData} name={'Theme Score'}/>,
        (view === 'Diversification' && secondaryView === 'Holdings')&& <HoldingsDiversificationChart width={PieChartSize} height={PieChartSize} key={k} card={_card} chartData={_chartData} colors={D3Colors09} name={'Holdings Diversification'} />,
        (view === 'Diversification' && secondaryView === 'Region') && <RegionDiversificationChart width={PieChartSize} height={PieChartSize} key={k} card={_card} chartData={_chartData} colors={D3Colors09} name={'Region Diversification'} />,
        (view === 'Diversification' && secondaryView === 'Sector') && <SectorDiversificationChart width={PieChartSize} height={PieChartSize} key={k} card={_card} chartData={_chartData} colors={D3Colors09} name={'Sector Diversification'} />,
        (view === 'Return Quality' && secondaryView === 'Sharpe Ratio') && <SharpeGaugeChartWrap width={GuageChartWidth} key={k} card={_card} chartData={_chartData} name={'Sharpe'} />,
        (view === 'Return Quality' && secondaryView === 'Sortino Ratio') && <SortinoGaugeChartWrap width={GuageChartWidth} key={k} card={_card} chartData={_chartData} name={'Sortino'} />,
        (view === 'Return Quality' && secondaryView === 'Tracking Error') && <TrackingErrorGaugeChartWrap width={GuageChartWidth} key={k} card={_card} chartData={_chartData} name={'Tracking Error'} multiplier={100} />,
        view === '1 Year Yield' && <DividendYieldChart width={GuageChartWidth} key={k} card={_card} chartData={_chartData} name={'Dividend Yield'}/>,
        view === 'Fiduciary Risk' && <FudiciaryChart width={GuageChartWidth} key={k} card={_card} chartData={_chartData} name={'Fiduciary Risk'}/> ,
        view === 'Market Cap' && <MarketCapChart width={GuageChartWidth} key={k} card={_card} chartData={_chartData} />,
        (!isViewPresentInChartTypes(view) && this.isDynamicChart(view, _chartData)) && DynamicGuageChart(view, _card, GuageChartWidth, _chartData),
      ];
    };
    const renderSingleChart = () => {
      let forSingleChart = {
        staticChart: '',
        chartType: '',
      };
      const forSingleChartArr = this.getChartDataByViewName(viewList, card, chartData);
      if (forSingleChartArr.length && typeof forSingleChartArr[0].Static !== 'undefined') {
        forSingleChart.staticChart = forSingleChartArr[0].Static;
        forSingleChart.chartType = forSingleChartArr[0].chart_type;
      }
      if (viewList.mappingVariable && viewList.mappingVariable !== '' && viewList.mappingVariable.length) {
        const foundQueryVarData = chartData.filter((item) => item.display_view_name === viewList.view && item.variable === viewList.mappingVariable); // card.chartData.filter((item) => item.display_view_name === viewList.view && item.variable === viewList.mappingVariable);
        const _view = foundQueryVarData && foundQueryVarData[0].view_name;
        const _chartType = foundQueryVarData && foundQueryVarData[0].chartType;
        const _staticChart = foundQueryVarData && foundQueryVarData[0].staticChart;
        const getViewDesign = getView(_view, viewList.secondaryView, _staticChart, _chartType, viewList.mappingVariable, card, foundQueryVarData);
        return getViewDesign;
      } else {
        // card._chartData = chartData; // card.chartData;
        const getViewDesign = getView(viewList.view, viewList.secondaryView, forSingleChart.staticChart, forSingleChart.chartType, viewList.mappingVariable, card, chartData);
        return getViewDesign;
      }
    }

    return (
      <Card
        className={classNames(
          ' mb-2',
          'app-card',
          {
            'border-bg-filter-bg-color': !card._selected,
            'border-ssf-orange-d1': card._selected
          },
          'itemCard'
        )}
      >
        <CardBody className="p-0">
          <Row className="no-gutters">
            <Col className="col-md-4 col-lg-4 col-sm-12 py-1 pl-1 paddingTpBtLt">
              <CardName {...this.props} highlightText={highlightText} />
            </Col>
            <Col className="col-lg-2 col-md-3 p-1 paddingTpBt media-none">
              <ReturnRisk
                funds={funds}
                card={card}
                chartData={chartData}
                highlightText={highlightText}
                checkPopOpen={checkPopOpen}
                firstPop={firstPop}
              />
            </Col>
            <Col className={`${viewChartsColumn} py-1 paddingTpBt media-none`}>
              <div
                className="w-100 h-100 card-background d-flex align-items-center"
                style={{
                  justifyContent:
                    viewList.length >= 2 ? 'space-evenly' : 'center'
                }}
              >
                {renderSingleChart()}
              </div>
            </Col>
            <Col
              className={`${lineChartColumn} p-1 paddingTpBtRt media-none col-lg-4 col-md-3`}
            >
              <div className="position-relative h-100 card-background d-flex align-items-center app-card-right justify-content-center">
                <ReturnsChart
                  lineData={(card._returnsChart && card._returnsChart.series) ? card._returnsChart.series : []}
                  fid={cn(card, 'Ticker')}
                  line3={card._opticker}
                  ydomain={card._returnRange}
                  itemsKey={this.props.itemsKey}
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }
}

const mapStateToProps = ({ funds,global }) => ({
  // funds,
  // query: funds.query,
  smallScreen: global.smallScreen,
  bigScreen: global.bigScreen,
})

const mapDispatchToProps = {
  searchQuery,
  setFundsState,
  dymLogs,
  fundsApiCallTime,
  clearFundsApiLoadPercent,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemCard,NamePopover)

const AllocationChart = props => {
  var mult = props.name === 'Theme' ? 100 : 1
  const data = props.data
    ? props.data
    : props.attrs.map(v => ({
      name: v,
      value: cn(props.card, v)
        ? parseFloat((cn(props.card, v) * mult).toFixed(1))
        : 0
    }))
  let sum = 0.0
  data.forEach((e, i) => {
    sum += e.value
    if (e.name == 'Other Sectors') {
      data[i].name = 'Others'
    }
  })
  if (isNaN(sum) || sum <= 0.0) return <NotApplicable />
  const { card, view, name } = props
  // if (typeof card.chartData === 'undefined') return null;
  const currentChartData = card.chartData.filter(
    e => e.display_view_name == view
  )[0]
  // if (typeof currentChartData === 'undefined') return null;

  let primaryTextStyle = {},
    secondaryTextStyle = {}
  const chartTypePresent = typeof name !== 'undefined' ? name : ''
  let centerText,
    centerDisplay = ''

  if (typeof currentChartData !== 'undefined') {
    if (currentChartData.data) {
      let displayText = SectorChartMapping.filter(
        e => e.chart_name === currentChartData.data
      )
      if (displayText.length) {
        // console.log(displayText[0].sector_name, card[displayText[0].sector_name]);
        if (displayText[0].sector_name && card[displayText[0].chart_name]) {
          centerDisplay = `${card[displayText[0].chart_name].toFixed(1)}%`
        }
        centerText = displayText[0].sector_name
        // centerText = centerText.substring(0, 8);
        primaryTextStyle =
          chartTypePresent === 'Sectors'
            ? {
              // top: '39%',
              fontSize: 11,
              fontWeight: 'bold',
              zIndex: '5'
            }
            : {}
        secondaryTextStyle =
          chartTypePresent === 'Sectors'
            ? {
              top: '52%',
              fontSize: centerText.length > 8 ? 9 : 9
            }
            : {}
      }
    }
  }

  return (
    <div className="simplePieChartWrapper">
      {name === 'Sectors' && (
        <div className="primaryTextStyle" style={primaryTextStyle}>
          {centerDisplay}
          <div className="highlightTooltip">
            {centerText}: {centerDisplay}
          </div>
        </div>
      )}
      {/* {(name === 'Sectors')
        && (
          <p className="secondaryTextStyle" style={secondaryTextStyle}
          >
            { centerText }
          </p>
        )
      } */}
      <SimplePieChart
        centerDisplay={centerDisplay}
        data={data}
        colors={props.colors}
        width={100}
        height={100}
      />
    </div>
  )
}

const AllocationAssetTypeChart = props => {
  if (props.card.length <= 0) return <NotApplicable />
  var mult = props.name === 'Asset Types' ? 100 : 1
  let sum = 0.0
  const absoluteData = props.attrs.forEach(v => {
    sum += Math.abs(cn(props.card, v))
  })
  const data = props.attrs.map(v => {
    return {
      name: v,
      value: Math.round((Math.abs(cn(props.card, v)) * 100) / sum),
      originalValue: Math.round((cn(props.card, v) * 100) / sum)
    }
  })

  const filterData = data.filter(x => x.value > 0);
  if (filterData.length <= 0) return <NotApplicable />;

  return (
    <SimplePieChart
      data={data}
      colors={props.colors}
      chartName={'asset_type'}
    />
  )
}

const AllocationTopHoldingChart = props => {
  let { card, view, data } = props
  if (data.length <= 0) return <NotApplicable />
  let dataVar, centerDisplay, centerText
  if (typeof card.chartData !== 'undefined') {
    const currentChartData = card.chartData.filter(
      e => e.display_view_name == view
    )[0]
    if (typeof currentChartData !== 'undefined') {
      if (
        Object.prototype.toString.call(currentChartData.data) ===
        '[object Array]'
      ) {
        dataVar = currentChartData.data[0]
      } else if (typeof currentChartData.data === 'string') {
        dataVar = currentChartData.data
      }
      dataVar = formatTickerForView(dataVar)
      if (typeof card[currentChartData.variable] !== 'undefined') {
        if (card[currentChartData.variable] === 0) {
          const value = card[currentChartData.variable]
          centerDisplay = currentChartData.chart_center_abovetext.replace(
            'value',
            value
          )
          centerText = currentChartData.chart_center_belowtext.replace(
            'ticker',
            dataVar
          )
        } else {
          const value = parseFloat(card[currentChartData.variable]).toFixed(1)
          centerDisplay = currentChartData.chart_center_abovetext.replace(
            'value',
            value
          )
          centerText = currentChartData.chart_center_belowtext.replace(
            'ticker',
            dataVar
          )
        }
      }
    }
  }

  return (
    <SimplePieChart
      data={props.data}
      colors={props.colors}
      chartName={props.name}
      centerDisplay={centerDisplay}
      centerText={centerText}
    />
  )
}

const AllocationChartB_ = props => {
  const { card, view, name } = props
  // if (typeof card.chartData === 'undefined') return null;
  const currentChartData = card.chartData.filter(
    e => e.display_view_name == view
  )[0]

  const formatTree = data => {
    if (!data) return data
    return data.map(e => {
      let out = { name: e.n, size: e.v }
      if (e.sub) out.children = formatTree(e.sub)
      return out
    })
  }
  const data = {
    name: props.name,
    children: formatTree(cn(props.card, 'Region'))
  }
  if (data.children.length == 0) {
    return <NotApplicable />
  }

  const sum = (arr, key) => {
    return arr.reduce((a, b) => a + (b[key] || 0), 0)
  }

  let newArr = JSON.parse(JSON.stringify(data))

  newArr.children.map(item => {
    item.size = sum(item.children, 'size')
    return item
  })

  let primaryTextStyle = {},
    secondaryTextStyle = {}
  const chartTypePresent = typeof name !== 'undefined' ? name : ''
  let centerText,
    centerDisplay = '',
    searchResult

  if (typeof currentChartData !== 'undefined') {
    if (currentChartData.data) {
      let displayText = RegionChartMapping.filter(
        e => e.chart_name === currentChartData.node
      )
      if (displayText.length) {
        if (displayText[0].region_name) {
          if (card[currentChartData.data]) {
            centerDisplay = `${card[currentChartData.data].toFixed(1)}%`
            centerText = displayText[0].region_name
            // centerText = centerText.substring(0, 11);
            primaryTextStyle =
              chartTypePresent === 'Allocations'
                ? {
                  // top: '39%',
                  fontSize: 11,
                  fontWeight: 'bold'
                }
                : {}
            secondaryTextStyle =
              chartTypePresent === 'Allocations'
                ? {
                  top: centerText.length > 8 ? '54%' : '52%',
                  fontSize: centerText.length > 8 ? 7 : 9
                }
                : {}
          }
        }
      }
    }
  }

  let d3value = '';

  const showTooltip = (evt, text) => {
    let tooltip = document.getElementById(`${card.ticker}_d3tooltip`);
    d3value = text;
    tooltip.innerHTML = text;
    tooltip.style.display = "block";
    tooltip.style.left = evt.pageX + 10 + 'px';
    tooltip.style.top = evt.pageY + 10 + 'px';
  }

  const hideTooltip = () => {
    var tooltip = document.getElementById(`${card.ticker}_d3tooltip`);
    tooltip.style.display = "none";
  }

  return (
    <div className="d3ChartWrapper">
      {name === 'Allocations' && (
        <div className="primaryTextStyle" style={primaryTextStyle}>
          {centerDisplay}
          <div className="highlightTooltip">
            {centerText}: {centerDisplay}
          </div>
        </div>
      )}
      {/* {(name === 'Allocations')
        && (
          <p className="secondaryTextStyle" style={secondaryTextStyle}
          >
            { centerText }
            <div className="highlightTooltip">Emerging Markets</div>
          </p>
        )
      } */}
      <div id={`${card.ticker}_d3tooltip`} className="d3Tooltip">{d3value}</div>
      <D3Sunburst
        data={data}
        width={84}
        height={84}
        colors={props.colors}
        clickHandler={props.searchFundsActiveRegion}
        activeSegment={props.activeRegion}
        showTooltip={showTooltip}
        hideTooltip={hideTooltip}
      />
    </div>
  )
}
const AllocationChartB = React.memo(AllocationChartB_)
//
// const ReturnsChart_ = ({ lineData, fid, ydomain, itemsKey }) => {
//   return (
//     <SimpleLineChart itemsKey={itemsKey} lineData={lineData} fid={fid} ydomain={[ydomain.min, ydomain.max]} />
//   )
// }
// const ReturnsChart = React.memo(ReturnsChart_);

// class ReturnsChart extends Component {
//   constructor(props) {
//     super(props)
//     this.itemsKey = props.itemsKey
//   }
//
//   shouldComponentUpdate(nextProps, nextState) {
//     if (this.itemsKey !== nextProps.itemsKey) {
//       this.itemsKey = nextProps.itemsKey
//       return true
//     }
//     return false
//   }
//
//   render() {
//     return <SimpleLineChart {...this.props} />
//   }
// }

const ReturnRisk = props => {
  const { card, chartData, highlightText, checkPopOpen, firstPop, funds } = props
  const flags =
    card._flags.returnsMax || card._flags.risksMin || card._flags.feesMin
  // justifyContent: 'space-around', alignItems: 'center'
  return (
    <div
      className="card-background h-100 feesReturnRiskWrapper"
      style={{ display: 'flex' }}
    >
      {card._returnsChart && (
        <AnalyticsPopover
          funds={funds}
          highlight={highlightText.feesHighLightEnable}
          card={props.card}
          checkPopOpen={checkPopOpen}
          firstPop={firstPop}
          icon={'fa-tags-'}
          qi={QuinItems[0]}
          suffix={<Pct />}
        />
      )}
      {card._returnsChart && (
        <ReturnPopover
          highlight={highlightText.returnHighLightEnable}
          {...props}
        />
      )}
      {(card._returnsChart &&
        <RiskPopover highlight={highlightText.riskHighLightEnable} {...props} />
      )}
    </div>
  )
}

const GaugeChartWrap = ({ card, qi }) => {
  if(typeof card._categorySeries == 'undefined' || !card._categorySeries) return <NotApplicable />
  const mult = qi.name === 'Assets' ? 1000 * 1000 : 1
  const _min = card._categorySeries[qi.col + '_min'],
    _max = card._categorySeries[qi.col + '_max'],
    _val = qi.name === 'Assets' ? card[qi.col] : card[qi.col] * mult,
    _pc = card[qi.pcCol]

  const suffix = ['', 'K', 'M', 'B']

  if(typeof _val == 'undefined' || _val == null) return <NotApplicable />
  if(typeof _min == 'undefined' || _min == null) return <NotApplicable />
  if(typeof _max == 'undefined' || _max == null) return <NotApplicable />

  const kmb = (val, i = 0) =>
    val < 100 || i > 2
      ? `$${val.toFixed(1)}${suffix[i]}`
      : kmb(val / 1000, i + 1)
  const noMFData =
    (card.vehicle !== 'MF' && qi.name === 'Volume') || qi.name === 'Assets'
      ? true
      : false

  return (
    <div className="w-100 p-1" style={{ minWidth: 134 }}>
      <div className="d-flex flex-column align-items-center">
        {/* <h6 className="text-center mb-0">{qi.name} <span className="text-gray-6i">vs Category</span></h6> */}
        {noMFData && (
          <Fragment>
            <GaugeChart pc={_pc} />
            <Row className="no-gutters h6 mb-0 w-100">
              <Col
                className="col-4 pr-2 text-gray-6i text-right h7 fz-9"
                style={{ marginTop: '-2px' }}
              >
                {kmb(_min)}
              </Col>
              <Col
                className="col-4 text-center font-weight-bold mb-0 lh-100 fz-9"
                style={{ marginTop: '2px' }}
              >
                {kmb(_val)}
              </Col>
              <Col
                className="col-4 pl-2 text-gray-6i text-left h7 fz-9"
                style={{ marginTop: '-2px' }}
              >
                {kmb(_max)}
              </Col>
            </Row>
          </Fragment>
        )}
        {!noMFData && <NotApplicable />}
      </div>
    </div>
  )
}

const ESGScoreGaugeChartWrap = ({ card, qi }) => {
  if(card._categorySeries) return <NotApplicable />
  const mult = qi.name === 'Social Impact' ? 100 : 1
  const _min = card._categorySeries[qi.col + '_min'] * mult,
    _max = card._categorySeries[qi.col + '_max'] * mult,
    _val = card[qi.col].score * mult
  // _pc = card[qi.pcCol];
  const suffix = ['', 'K', 'M', 'B']

  if(typeof _val == 'undefined' || _val == null) return <NotApplicable />
  if(typeof _min == 'undefined' || _min == null) return <NotApplicable />
  if(typeof _max == 'undefined' || _max == null) return <NotApplicable />

  const kmb = (val, i = 0) =>
    val < 100 || i > 2 ? `${val.toFixed(1)}` : kmb(val / 1000, i + 1)
  // console.log('min', _min)
  // console.log('max', _max)
  return (
    _max > 0.0 && (
      <div className="w-100 p-1">
        <div
          className="d-flex flex-column align-items-center"
          style={{ minWidth: 134 }}
        >
          <h6 className="text-center mb-0">
            {qi.name} <span className="text-gray-6i">vs Category</span>
          </h6>
          <GaugeChart value={_val} min={_min} max={_max} />
          <Row className="no-gutters h6 mb-0 w-100">
            <Col
              className="col-4 pr-2 text-gray-6i text-right h7 fz-9"
              style={{ marginTop: '-2px' }}
            >
              {_min.toFixed(1)}
            </Col>
            <Col
              className="col-4 text-center font-weight-bold mb-0 lh-100 fz-9"
              style={{ marginTop: '2px' }}
            >
              {_val.toFixed(1)}
            </Col>
            <Col
              className="col-4 pl-2 text-gray-6i text-left h7 fz-9"
              style={{ marginTop: '-2px' }}
            >
              {_max.toFixed(1)}
            </Col>
          </Row>
        </div>
      </div>
    )
  )
}

const WeightHeldGaugeChartWrap = ({ card, name }) => {
  const mult = name === '% Held' ? 1 : 100
  const _min = card.weightHeld['min_value'] * mult,
    _max = card.weightHeld['max_value'] * mult,
    _val = card.weightHeld.weight * mult
  // _pc = card[qi.pcCol];
  const suffix = ['', 'K', 'M', 'B']

  if(typeof _val == 'undefined' || _val == null) return <NotApplicable />
  if(typeof _min == 'undefined' || _min == null) return <NotApplicable />
  if(typeof _max == 'undefined' || _max == null) return <NotApplicable />

  const kmb = (val, i = 0) =>
    val < 100 || i > 2 ? `${val.toFixed(1)}` : kmb(val / 1000, i + 1)
  // console.log('min', _min)
  // console.log('max', _max)
  return (
    _max > 0.0 && (
      <div className="w-100 p-1">
        <div
          className="d-flex flex-column align-items-center"
          style={{ minWidth: 134 }}
        >
          <h6 className="text-center mb-0">
            Percentage <span className="text-gray-6i">vs Category</span>
          </h6>
          <GaugeChart value={_val} min={_min} max={_max} />
          <Row className="no-gutters h6 mb-0 w-100">
            <Col
              className="col-4 pr-2 text-gray-6i text-right h7 fz-9"
              style={{ marginTop: '-2px' }}
            >
              {_min.toFixed(1)}
            </Col>
            <Col
              className="col-4 text-center font-weight-bold mb-0 lh-100 fz-9"
              style={{ marginTop: '2px' }}
            >
              {_val.toFixed(1)}
            </Col>
            <Col
              className="col-4 pl-2 text-gray-6i text-left h7 fz-9"
              style={{ marginTop: '-2px' }}
            >
              {_max.toFixed(1)}
            </Col>
          </Row>
        </div>
      </div>
    )
  )
}

const SimilarityGaugeChartWrap = ({ card, name }) => {
  const mult = name === 'Overlap' ? 100 : 1
  let _min = 0 * mult,
    _max = 1 * mult,
    _val = card.overlap * mult
  _val = parseFloat(_val.toFixed(1))

  if(typeof _val == 'undefined' || _val == null) return <NotApplicable />
  if(typeof _min == 'undefined' || _min == null) return <NotApplicable />
  if(typeof _max == 'undefined' || _max == null) return <NotApplicable />

  return (
    _max > 0.0 && (
      <div className="w-100 p-1">
        <div
          className="d-flex flex-column align-items-center"
          style={{ minWidth: 134 }}
        >
          {/* <h6 className="text-center mb-0">Percentage Overlap</h6> */}
          <h6 className="text-center mb-0">&nbsp;</h6>
          <GaugeChart value={_val} min={_min} max={_max} />
          <Row className="no-gutters h6 mb-0 w-100">
            <Col
              className="col-4 pr-2 text-gray-6i text-right h7 fz-9"
              style={{ marginTop: '-2px' }}
            >
              {_min.toFixed(0)}%
            </Col>
            <Col
              className="col-4 text-center font-weight-bold mb-0 lh-100 fz-9"
              style={{ marginTop: '2px' }}
            >
              {_val.after() == 0 ? _val.toFixed(0) : _val.toFixed(1)}%
            </Col>
            <Col
              className="col-4 pl-2 text-gray-6i text-left h7 fz-9"
              style={{ marginTop: '-2px' }}
            >
              {_max.toFixed(0)}%
            </Col>
          </Row>
        </div>
      </div>
    )
  )
}

const OutperformanceGaugeChartWrap = ({ card, name }) => {
  const mult = name === 'Outperformance' ? 100 : 1
  let _min = 0 * mult,
    _max = card.outperformanceMax * mult,
    _val = card.outperform * mult
  _val = parseFloat(_val.toFixed(1))

  if(typeof _val == 'undefined' || _val == null) return <NotApplicable />
  if(typeof _min == 'undefined' || _min == null) return <NotApplicable />
  if(typeof _max == 'undefined' || _max == null) return <NotApplicable />

  return (
    _max > 0.0 && (
      <div className="w-100 p-1">
        <div
          className="d-flex flex-column align-items-center"
          style={{ minWidth: 134 }}
        >
          <h6 className="text-center mb-0">
            {card.ticker} -{' '}
            <span className="text-gray-6i">{card.queryCategoryValue}</span>
          </h6>
          <GaugeChart value={_val} min={_min} max={_max} />
          <Row className="no-gutters h6 mb-0 w-100">
            <Col
              className="col-4 pr-2 text-gray-6i text-right h7 fz-9"
              style={{ marginTop: '-2px' }}
            >
              {_min.toFixed(0)}%
            </Col>
            <Col
              className="col-4 text-center font-weight-bold mb-0 lh-100 fz-9"
              style={{ marginTop: '2px' }}
            >
              {_val.after() == 0 ? _val.toFixed(0) : _val.toFixed(1)}%
            </Col>
            <Col
              className="col-4 pl-2 text-gray-6i text-left h7 fz-9"
              style={{ marginTop: '-2px' }}
            >
              {_max.after() == 0 ? _max.toFixed(0) : _max.toFixed(1)}%
            </Col>
          </Row>
        </div>
      </div>
    )
  )
}


const QuintileWrap = ({ card, qi }) => {
  const val = qi.name === 'Volume' ? card[qi.col] / (1000 * 1000) : card[qi.col]
  const cval =
    qi.name === 'Volume'
      ? card._categorySeries[qi.col] / (1000 * 1000)
      : card._categorySeries[qi.col]
  const catValFmt = (
    <span>
      <span className="text-gray-5">$</span>
      {numFmt(cval)}
      <span className="text-gray-6"> M</span>
    </span>
  )

  return (
    <div className="w-100 p-2">
      <div className="h5 d-flex justify-content-between pt-1">
        <span>{qi.name}</span>
        <span className="text-gray-8 font-weight-bold">
          <span className="text-gray-5">$</span>
          {numFmt(val)}
          <span className="text-gray-6"> M</span>
        </span>
      </div>
      <Quintile
        item={qi}
        value={card[qi.qcol]}
        catValue={catValFmt}
        bg={quintileClass(card[qi.qcol], qi.reverse)}
        w100
      />
    </div>
  )
}

const Quintile = props => {
  const { cardCategory } = props;
  var off = (props.value - 1) * 20 + '%'
  const date = !props.date ? '' : `(${props.date})`
  const categoryText = !date ? null : (
    <div className="category-label text-overflow-ellipse-line-2">
      <span className="category-key">vs. Category </span>
      <span className="category-value text-gray-6">{`(${cardCategory})`}</span>
    </div>
  );

  let renderCategoryDesign = (
    <div className={classNames("h6 mb-0 text-gray-7 d-flex justify-content-between align-items-center date-range-label")}>
      <span className="date-range-key">{date}</span>
      <span className="date-range-value">{props.catValue}</span>
    </div>
  );
  // console.log(date);
  if (!date) {
    renderCategoryDesign = (
      <div className="h6 text-gray-7 d-flex justify-content-between mb-0">
        <div className="category-label text-overflow-ellipse-line-2" style={{ fontSize: 12 }}>
          <span className="category-key">vs. Category </span>
          <span className="category-value text-gray-6">{`(${cardCategory})`}</span>
        </div>
        <div className={classNames("h6 text-gray-7 mb-0 date-range-label")} style={{ fontSize: 12 }}>
          <span className="date-range-value" style={{ fontSize: 12 }}>{props.catValue}</span>
        </div>
      </div>
    );
  }
  return (
    <div className="popover-content-min-wrapper" style={props.w100 ? null : { minWidth: '240px' }}>
      {cardCategory && categoryText}
      {props.catValue === undefined ? <h6 className="text-dark mb-0">{date}</h6> : renderCategoryDesign}

      <div className="progress">
        <div
          className="progress-bar bg-progress"
          role="progressbar"
          style={{ width: off }}
          aria-valuenow={off}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
        <div
          className={'progress-bar bg-' + props.bg}
          role="progressbar"
          style={{ width: '20%' }}
          aria-valuenow="20"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div className="d-flex justify-content-between h6 text-gray-5 mb-0">
        <div>Low</div>
        <div>Avg</div>
        <div>High</div>
      </div>
    </div>
  )
}

class CardName extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.viewHandler = this.viewHandler.bind(this);
    this.state = {
      imageValidPath: false,
      changeHome: true,
      changeFees: false,
      changeReturn: false,
      changeRisk: false,
      showDetailsModal: false,
    };
  }

  componentDidMount() {
    const { card } = this.props;
    if (cn(card, 'Sponsor')) {
      getImageOrFallback(
        sponsorLogo(cn(card, 'Sponsor'))
      ).then(result => {
        if (result) {
          this.setState({ imageValidPath: true });
        } else {
          this.setState({ imageValidPath: false });
        }
      });
    }
  }

  toggle = e => {
    const removeId = e.currentTarget.dataset.index
    const element = document.getElementById(removeId)
    const elementText = element.innerHTML
    const elementFullText = document.querySelector(`[data-value = ${removeId}]`).value
    const containClass = element.classList.contains('text-ellipse')
    const head = e.currentTarget.dataset.head || ''

    if (containClass) {
      const elems = document.querySelectorAll('.show-less')
        ;[].forEach.call(elems, function (el) {
          if (el.id !== element) {
            el.classList.add('text-ellipse')
            const elFullText = (el.id.indexOf('text') < 0) ? document.querySelector(`[data-value = ${el.id}]`).value : document.querySelector(`[data-value = ${el.id}-less]`).value
            el.innerHTML = elFullText
            const truncateTextParts = el.innerHTML.split(' ')
            const lineHeight = getLineHeight(el)
            const lines = el.id === 'short_desc' || el.id === 'thm_txt' ? 3 : 2

            while (lines * lineHeight < el.offsetHeight) {
              truncateTextParts.pop()
              el.innerHTML = truncateTextParts.join(' ')
            }
          }
          document.querySelector(`[data-index = ${el.id}]`).style.position = 'absolute'
          document.querySelector(`[data-index = ${el.id}]`).innerHTML = '... more'
        })
      element.innerHTML = elementFullText
      document.getElementById(removeId).classList.remove('text-ellipse')
      document.querySelector(`[data-index = ${removeId}]`).style.position = 'relative'
      document.querySelector(`[data-index = ${removeId}]`).innerHTML = 'less'
      // this.addMargin()
    } else {
      if (head === '') {
        const truncateTextParts = elementText.split(' ')
        const lineHeight = getLineHeight(element)
        const lines = removeId === 'short_desc' || removeId === 'thm_txt' ? 3 : 2

        while (lines * lineHeight < element.offsetHeight) {
          truncateTextParts.pop()
          element.innerHTML = truncateTextParts.join(' ')
        }
      } else {
        const lessText = document.querySelector(`[data-value = ${removeId}-less]`).value
        element.innerHTML = lessText
      }
      document.getElementById(removeId).classList.add('text-ellipse')
      document.querySelector(`[data-index = ${removeId}]`).style.position = 'absolute'
      document.querySelector(`[data-index = ${removeId}]`).innerHTML = '... more'
      // this.addMargin()
    }
  }

  // addMargin = () => {
  //   const elems = document.querySelectorAll('.pop-wrapper')
  //   ;[].forEach.call(elems, function(el) {
  //     const space = window.innerHeight - (el.offsetTop + el.offsetHeight)
  //     el.style.paddingBottom = `470px`
  //     el.style.marginBottom = `-470px`
  //     setTimeout(() => {
  //       el.style.paddingBottom = `0px`
  //       el.style.marginBottom = `0px`
  //     }, 3000)
  //   })
  // }

  truncString = () => {
    const elems = document.querySelectorAll('.show-less')
    if (elems.length) {
      ;[].forEach.call(elems, function (el) {
        if (el.classList.contains('text-ellipse')) {
          if (el.id !== '') {
            el.classList.add('text-ellipse')
            if (document.querySelector(`[data-value = ${el.id}]`)) {
              if (!document.querySelector(`[data-value = ${el.id}-less]`)) {
                const elFullText = document.querySelector(`[data-value = ${el.id}]`).value || ''
                el.innerHTML = elFullText
                const truncateTextParts = el.innerHTML.split(' ')
                const lineHeight = getLineHeight(el)
                const lines =
                  el.id === 'short_desc' || el.id === 'thm_txt' ? 3 : 2
                if (lines * lineHeight > el.offsetHeight)
                  document.querySelector(`[data-index = ${el.id}]`).style.display = 'none'
                while (lines * lineHeight < el.offsetHeight) {
                  truncateTextParts.pop()
                  el.innerHTML = truncateTextParts.join(' ')
                }
              } else {
                const elFullText = document.querySelector(`[data-value = ${el.id}]`).value
                const lessText = document.querySelector(`[data-value = ${el.id}-less]`).value
                if (elFullText !== lessText) {
                  el.innerHTML = lessText
                } else {
                  el.innerHTML = elFullText
                  const truncateTextParts = el.innerHTML.split(' ')
                  const lineHeight = getLineHeight(el)
                  const lines = el.id === 'short_desc' || el.id === 'thm_txt' ? 3 : 2
                  if (lines * lineHeight > el.offsetHeight)
                    document.querySelector(`[data-index = ${el.id}]`).style.display = 'none'
                  while (lines * lineHeight < el.offsetHeight) {
                    truncateTextParts.pop()
                    el.innerHTML = truncateTextParts.join(' ')
                  }
                }
              }
              document.querySelector(`[data-index = ${el.id}]`).style.position = 'absolute'
              document.querySelector(`[data-index = ${el.id}]`).innerHTML = '... more'
            }
          }
        }
      })
    }
  }

  componentDidUpdate() {
    this.truncString();
  }

  onClick() {
    this.props.selectHandler(this.props.card)
    this.forceUpdate()
  }

  handleEvent(event) {
    const { card, funds } = this.props;
    this.setState({
      value: event
    });
    if (event === 0) {
      this.setState({
        changeHome: true,
        changeFees: false,
        changeReturn: false,
        changeRisk: false
      });

      addEventToAnalytics('Fund Details Popup','Fund Details Popup','FUND_DETAILS_POPUP',{ query: funds.query, logId: funds.logId, ticker: card.ticker },true);
    }
    else if (event === 1) {
      this.setState({
        changeHome: false,
        changeFees: true,
        changeReturn: false,
        changeRisk: false
      });

      addEventToAnalytics('Fees Popup','Fees Popup','FEES_POPUP',{ query: funds.query, logId: funds.logId, ticker: card.ticker },true);
    }
    else if (event === 2) {
      this.setState({
        changeHome: false,
        changeFees: false,
        changeReturn: true,
        changeRisk: false
      });

       addEventToAnalytics('Return Popup','Return Popup','RETURN_POPUP',{ query: funds.query, logId: funds.logId, ticker: card.ticker },true);
    }
    else if (event === 3) {
      this.setState({
        changeHome: false,
        changeFees: false,
        changeReturn: false,
        changeRisk: true
      });

      addEventToAnalytics('Risk Popup','Risk Popup','RISK_POPUP',{ query: funds.query, logId: funds.logId, ticker: card.ticker },true);
    }
  }

  toggleDetailsModal = () => {
    const { card, funds } = this.props;
    this.setState({
      showDetailsModal: !this.state.showDetailsModal,
    })

      addEventToAnalytics('Fund Details Mobile Popup','Fund Details Mobile Popup','FUND_DETAILS_MOBILE_POPUP',{ query: funds.query, logId: funds.logId, ticker: card.ticker },true);
  }

  viewHandler(view, secondaryView, mappingVariable) {
    // console.log(view, secondaryView, mappingVariable);
    const { setFundsState } = this.props;
    setFundsState({
      itemCardView: {view, secondaryView, mappingVariable}
    });
  }

  searchFundsActiveRegion = (activeRegion) => {
    console.log(activeRegion);
    const { setFundsState } = this.props;
    setFundsState({
      activeRegion
    });
  }

  moreStocksOnClick = () => {
    const { moreStocksOnClick } = this.props;
    if (moreStocksOnClick) {
      moreStocksOnClick();
    }
  }

  render() {
    const { imageValidPath, changeHome, changeFees, changeReturn, changeRisk } = this.state;
    const { card, index, checkPopOpen, firstPop, firstPopIndex, chartData, highlightText, reponsiveDetailsModal, funds, showGoBackSection, SHOWING_ALL_STOCKS,sponsors_tickers,queryUUID } = this.props;
    const { view: _fundView, itemCardView, activeRegion } = funds;
    const { value, showDetailsModal } = this.state;

    const weightChartData = highlightText.popupHighLightEnable ? highlightText.popupHighLightEnable : null;
    const thm_txt = cn(card, 'thm_txt');
    let value_stk = cn(card, 'value_stk');
    if (value_stk) {
      try {
        let stk_split = value_stk.split('$$$$$');
        let stk_parse = [];
        stk_split.map(e => {
          let text, company;
          let row_split = e.split('##');
          if (row_split.includes('text') && row_split.includes('company')) {
            const STKObject = getSTKData(row_split);
            if (STKObject) {
              if (typeof STKObject.text !== 'undefined' && typeof STKObject.company !== 'undefined') {
                stk_parse.push({
                  text: STKObject.text.value,
                  company: STKObject.company.value,
                  less_description: STKObject.startend.value
                });
              }
            }
          }
          // if(row_split.length == 7) {
          //   stk_parse.push({
          //     text: row_split[2],
          //     company: row_split[6]
          //   });
          // }
          else {
            throw Error('wrong text');
          }
        });
        value_stk = stk_parse;
      } catch (e) {
        value_stk = null;
      }
    }

    let SponseredCardLink = '';
    let currentFund = null;
    let fund_info = null;
    let fact_sheet = null;
    let prospectus = null;
    let channel_name = null;
    if((card.is_sponsored || card.is_learn_more) && config.sponsoredTicker && sponsors_tickers.length){
      let sponsArray = sponsors_tickers.filter(el=> el.ticker === card.ticker)
      if(sponsArray.length){
        currentFund = sponsArray[0];
        if(currentFund){
          fund_info = currentFund.fund_information_advisor ? currentFund.fund_information_advisor : currentFund.fund_information ? currentFund.fund_information : null;
          fact_sheet = currentFund.fact_sheet_advisor ? currentFund.fact_sheet_advisor : currentFund.fact_sheet ? currentFund.fact_sheet : null;
          prospectus = currentFund.prospectus_advisor ? currentFund.prospectus_advisor : currentFund.prospectus ? currentFund.prospectus : null;
          channel_name = currentFund.channel_name;
        }
        if(sponsArray[0].learn_more_link){
          SponseredCardLink = sponsArray[0].learn_more_link;
        } else if(sponsArray[0].link){
           let splittedArray = []
           splittedArray = sponsArray[0].link.split("||")
           if(splittedArray.length){
            SponseredCardLink = splittedArray[0]
            // if (SponseredCardLink.includes('pdf')) {
            //   SponseredCardLink = '/assets/data'+ SponseredCardLink ;
            // }
            SponseredCardLink = sponsorPdfUrl(splittedArray[0], '/assets/data');
           }
        }
      }
    }

    const detailsModalContent = (
      <React.Fragment>
        <Row className="no-gutters text-gray-7">
          <Col className="col-12 p-2 pt-3">
            <div className="pb-1 d-flex justify-content-between" onClick={() => this.toggleDetailsModal(detailsModalContent)}>
              {(imageValidPath && cn(card, 'Sponsor')) ? (
                <img src={sponsorLogo(cn(card, 'Sponsor'))} className="img-fluid" alt={cn(card, 'Sponsor')} style={{ height: '20px' }} />
              ) : (
                  <div className="sponsor-logo-placeholder" title={cn(card, 'Sponsor') || ''}>{cn(card, 'Sponsor') || ''}</div>
                )}
            </div>
          </Col>
          <Col className="col-12 p-2">
            <h4 className="fs-17 text-ssf-blue-d2 mb-1">{cn(card, 'Name')}</h4>
            <div className="shw-container btn-white">
              <input type="text" data-value={"short_desc-"+index} readOnly value={cn(card, 'short_desc')} hidden={true} />
              <span className="fs-13 font-three text-justify mt-2  show-less text-ellipse" id={"short_desc-"+index}>{cn(card, 'short_desc')}</span>
              <button className="shw-ls-btn font-three fs-13" id="btn-white" type="button" data-index={"short_desc-"+index} onClick={this.toggle} style={{ backgroundColor: '#fff !important' }} >
                ... more
                </button>
            </div>
          </Col>
          {
            weightChartData && weightChartData.length > 0 &&
            <Col className="col-12 p-2 bg-light">
              <span className="font-weight-semi-bold mb-1 text-info">Weighting Strategy</span><span className="mb-1">: {card.weighting_scheme_desc}</span>
            </Col>
          }
          {
            thm_txt && thm_txt != "nan" && thm_txt != "NaN" &&
            <Col className="col-12 p-2 bg-light">
              <h6 className="font-weight-semi-bold mb-1 text-info">Theme information from fund prospectus</h6>
              <div className="shw-container">
                <input type="text" data-value="thm_txt" value={thm_txt} hidden="true" />
                <span className="fs-13 font-three text-justify show-less text-ellipse" id="thm_txt">{thm_txt}</span>
                <button className="shw-ls-btn font-three fs-13" type="button" data-index="thm_txt" onClick={this.toggle} style={{ backgroundColor: '#f8f9fa !important' }} >
                  ... more
                </button>
              </div>
            </Col>
          }
          {
            value_stk && value_stk != "nan" && value_stk != "NaN" &&
            <Col className="col-12 p-2 bg-light">
              <h6 className="font-weight-semi-bold mb-1 text-blue-info">Theme information from annual reports of underlying holdings</h6>
              {
                value_stk.map((v, i) => (
                  // <div key={v.company} className="fs-13 font-three text-justify">
                  // <strong>{convertToCapitalCase(v.company)}:</strong> <span dangerouslySetInnerHTML={{ __html: v.text }} /></div>
                  <div key={v.company} className="fs-13 font-three text-justify">
                    <strong>{convertToCapitalCase(v.company)}:</strong>
                    <div className="shw-container">
                      <input type="text" data-value={`text-${i}`} value={v.text} data-less={v.less_description} hidden="true" />
                      <input type="text" data-value={`text-${i}-less`} value={v.less_description} hidden="true" />
                      <span className="fs-13 font-three text-justify mt-2 show-less text-ellipse" id={`text-${i}`} dangerouslySetInnerHTML={{ __html: v.text }}></span>
                      <button className="btn-white shw-ls-btn font-three" type="button" data-index={`text-${i}`} data-head="value_stk" onClick={this.toggle} style={{ backgroundColor: '#fff !important' }}>
                        ... more
                    </button>
                    </div>
                  </div>
                ))
              }
            </Col>
          }
          <Col className="col-12 p-2 viewChartsColumn">
            <Relevance
              funds={funds}
              view={_fundView || reponsiveDetailsModal.view}
              chartData={chartData}
              itemCardView={itemCardView}
              selectedFunDetails={card}
              activeRegion={activeRegion}
              searchFundsActiveRegion={this.searchFundsActiveRegion}
              viewHandler={this.viewHandler}
            />
          </Col>
        </Row>
        {this.truncString()}
      </React.Fragment>
    )

    const E3 = () => (
      <React.Fragment>
        <PopoverBody className="p-0 media-popover">
          <Row className="no-gutters text-gray-7">
            <Col className="col-12 p-2 pt-3">
              <h4 className="fs-17 text-ssf-blue-d2 mb-1">{cn(card, 'Name')}</h4>
              <div className="fs-13 font-three text-justify mt-2">{cn(card, 'short_desc')}</div>
            </Col>
            {
              weightChartData && weightChartData.length > 0 &&
              <Col className="col-12 p-2 bg-light">
                <span className="font-weight-semi-bold mb-1 text-info">Weighting Strategy</span><span className="mb-1">: {card.weighting_scheme_desc}</span>
              </Col>
            }
            {thm_txt && thm_txt != "nan" && thm_txt != "NaN" &&
              <Col className="col-12 p-2 bg-light">
                <h6 className="font-weight-semi-bold mb-1 text-info">Theme information from fund prospectus</h6>
                <div className="fs-13 font-three text-justify">{thm_txt}</div>
              </Col>}
            {value_stk && value_stk != "nan" && value_stk != "NaN" &&
              <Col className="col-12 p-2 bg-light">
                <h6 className="font-weight-semi-bold mb-1 text-info">Theme information from annual reports of underlying holdings</h6>
                {value_stk.map(v => (
                  <div key={v.company} className="fs-13 font-three text-justify">
                    <strong>{convertToCapitalCase(v.company)}:</strong> <span dangerouslySetInnerHTML={{ __html: v.text }} /></div>
                ))}
              </Col>}
          </Row>
        </PopoverBody>
      </React.Fragment>
    );


    let component;
    switch (value) {
      case 0:
        component = <NamePopover
          {...this.props}
          card={card}
          funds={funds}
          imageValidPath={imageValidPath}
          reponsiveDetailsModal={reponsiveDetailsModal}
          toggleDetailsModal={this.toggleDetailsModal}
          index={index} checkPopOpen={checkPopOpen}
          firstPop={firstPop}
          firstPopIndex={firstPopIndex}
          highlightText={highlightText}
        />;
        break;
      case 1:
        component = <ResponsiveCardPopover
          card={card}
          funds={funds}
          chartData={chartData}
          highlightText={highlightText}
          checkPopOpen={checkPopOpen}
          firstPop={firstPop}
        />;
        break;
      case 2:
        component = <ResponsiveReturnPopoverComp
          card={card}
          funds={funds}
          chartData={chartData}
          highlightText={highlightText}
          checkPopOpen={checkPopOpen}
          firstPop={firstPop}
        />;
        break;
      case 3:
        component = <ResponsiveRiskPopoverComp
          card={card}
          funds={funds}
          chartData={chartData}
          highlightText={highlightText}
          checkPopOpen={checkPopOpen}
          firstPop={firstPop}
        />;
        break;
      default:
        component = <NamePopover
          {...this.props}
          funds={funds}
          card={card}
          imagePath={imageValidPath}
          reponsiveDetailsModal={reponsiveDetailsModal}
          toggleDetailsModal={this.toggleDetailsModal}
          index={index} checkPopOpen={checkPopOpen}
          firstPop={firstPop}
          firstPopIndex={firstPopIndex}
          highlightText={highlightText}
        />;
        break;
    }

    let renderDesign = '';
    if (window.screen.width < 760) {
      renderDesign = (
        <div className="pb-1 d-flex justify-content-between" onClick={() =>{
          if(!(card.is_sponsored && config.sponsoredTicker)){
            this.props.toggleDetailsModal(detailsModalContent)
          } 
        }}>
          {(imageValidPath && cn(card, 'Sponsor')) ? (
            <img src={sponsorLogo(cn(card, 'Sponsor'))} className="img-fluid" alt={cn(card, 'Sponsor')} style={{ height: '20px' }} />
          ) : (
              <div className="sponsor-logo-placeholder" title={cn(card, 'Sponsor') || ''}>{cn(card, 'Sponsor') || ''}</div>
            )}
          {/*cn(card, 'Sponsored') && <h6 className="badge badge-info mb-0 ml-2">{'Ad'}</h6>*/}
        </div>
      );
    }

    let mobileRender = '';
    if (window.screen.width < 760) {
      mobileRender = (
        card.is_sponsored && config.sponsoredTicker ?
            <Col className="col-1 p-0 d-md-none media-show">
            <label style={{fontSize:'10px',fontFamily:'Roboto',width:'63px',height:'15px!important',backgroundColor:'#4a4a4a',position:'absolute',bottom:'28px',color:'#ffffff',textAlign:'center',right:'-37px',borderRadius:'3px'}}>SPONSORED</label>
              <label className="orange-checkbox ml-2 mr-5">
                <input
                  type="checkbox"
                  onChange={this.onClick}
                  checked={card._selected}
                />
                <span className="checkmark" style={{top:'-3px'}}></span>
              </label>
              {card.vehicle && (
                <div className="vehicle-label media-vehicle-label" style={{marginTop:'19px'}}>
                  {card.vehicle === 'SHARE' ? 'Stock' : card.vehicle}
                </div>
              )
            }
            </Col>
            :
            <Col className="col-1 p-0 d-md-none media-show">
              <label className="orange-checkbox ml-2 mr-5">
                <input
                  type="checkbox"
                  onChange={this.onClick}
                  checked={card._selected}
                />
                <span className="checkmark" style={{top: '8px'}}></span>
              </label>
              {card.vehicle && (
                <div className="vehicle-label media-vehicle-label" style={{marginTop:'29px'}}>
                  {card.vehicle === 'SHARE' ? 'Stock' : card.vehicle}
                </div>
              )
            }
            </Col>
      );
    }
    else {
      mobileRender = (
      card.is_sponsored && config.sponsoredTicker?
            <Col className="col-1 p-0 d-md-none media-show">
            <label style={{fontSize:'10px',fontFamily:'Roboto',width:'63px',height:'15px',backgroundColor:'#4a4a4a',position:'absolute',bottom:'35px',color:'#ffffff',textAlign:'center',right:'-47px',borderRadius:'3px'}}>SPONSORED</label>
              <label className="orange-checkbox ml-2 mr-5">
                <input
                  type="checkbox"
                  onChange={this.onClick}
                  checked={card._selected}
                />
                <span className="checkmark" style={{top:'-8px'}}></span>
              </label>
              {card.vehicle && (
                <div className="vehicle-label media-vehicle-label" style={{marginTop:0}}>
                  {card.vehicle === 'SHARE' ? 'Stock' : card.vehicle}
                </div>
              )
            }
            </Col>
            :
            <Col className="col-1 p-0 d-md-none media-show">
              <label className="orange-checkbox ml-2 mr-5">
                <input
                  type="checkbox"
                  onChange={this.onClick}
                  checked={card._selected}
                />
                <span className="checkmark"></span>
              </label>
              {card.vehicle && (
                <div className="vehicle-label media-vehicle-label">
                  {card.vehicle === 'SHARE' ? 'Stock' : card.vehicle}
                </div>
              )
            }
          </Col>
      );
    }

    const SHOW_MORE_STOCKS_BUTTON = (!showGoBackSection && card && card.type === 'SHARE' && !SHOWING_ALL_STOCKS);
    const cardStyle = SHOW_MORE_STOCKS_BUTTON ? {
      display: 'flex',
      flexDirection: 'column',
    } : {};

    const learnMoreMenuItem = (
      <Menu className='learn-more-menu'>
        {fund_info ? <Menu.Item
                  key={`fund_info`}
                  className="learn-more-submenu learn_more_item"
                  onClick={() => {
                    addEventToAnalytics('Learn More Fund Information','Learn More Fund Information','LEARN_MORE_FUND_INFORMATION',{ ticker: card.ticker },true);
                    window.open(fund_info.includes('pdf') && !fund_info.includes('https://') ? '/assets/data'+ fund_info : fund_info, '_blank').focus();
                  }}
                >
                  Fund Information
              </Menu.Item>:null}
              {fact_sheet ? <Menu.Item
                  key={`fact_sheet`}
                  className="learn-more-submenu learn_more_item"
                  onClick={() => {
                    addEventToAnalytics('Learn More Fact Sheet','Learn More Fact Sheet','LEARN_MORE_FACT_SHEET',{ ticker: card.ticker },true);
                    window.open(fact_sheet.includes('pdf') && !fact_sheet.includes('https://') ? '/assets/data'+ fact_sheet : fact_sheet, '_blank').focus();
                  }}
                >
                  Fact Sheet
              </Menu.Item>:null}
              {prospectus ? <Menu.Item
                  key={`prospectus`}
                  className="learn-more-submenu learn_more_item"
                  onClick={() => {
                    addEventToAnalytics('Prospectus','Prospectus','LEARN_MORE_PROSPECTUS',{ ticker: card.ticker },true);
                    window.open(prospectus.includes('pdf') && !prospectus.includes('https://') ? '/assets/data'+ prospectus : prospectus, '_blank').focus();
                  }}
                >
                  Prospectus
              </Menu.Item>:null}
              {channel_name ?<Menu.Item
                  key={`more_here`}
                  className=" learn-more-submenu learn_more_item"
                  onClick={() => {
                    addEventToAnalytics('More From', 'More From','LEARN_MORE_FROM',{ ticker: card.ticker, channel: channel_name },true);
                    this.props.queryHandler(`${channel_name} Sponsored Funds`, 'search_bar', true)
                  }}
                >
                  More From {channel_name}
              </Menu.Item>:null}
      </Menu>
    )
    return (
      <div  id={`fundclass-${card.ticker}`} className={`h-100 card-background px-2 py-1 position-relative app-card-left`}  style={cardStyle}>
        {(card.vehicle === 'MF' && typeof card.class_type !== 'undefined' && card.class_type !== '')
          && (
            <div>
              <AntToolTip 
              title={card.class_type === 'retail' ? 'Retail Class' : 'Institutional Class'}
              arrowPointAtCenter={false} 
              overlayClassName="magnifi-tooltip-wrapper"
              placement={'topRight'}
              >
              <img id={`fundclass-${card.ticker}`} className="card-Ad-Icon" src={`/assets/images/${card.class_type}.png`} width='10' />
              {/* <Tooltip className="col-grey" placement="right" isOpen={this.state.tooltipOpen} target={`fundclass-${card.ticker}`} toggle={() => this.setState({ tooltipOpen: !this.state.tooltipOpen })}>
                {card.class_type === 'retail' ? 'Retail Class' : 'Institutional Class'}
              </Tooltip> */}
              </AntToolTip>
            </div>
          )
        }
         {(currentFund) ?
         <Dropdown
          trigger={'click'}
          placement='bottomRight'
          overlayClassName='learnMoreDropdown_M'
          overlay={learnMoreMenuItem}
          getPopupContainer={() => document.getElementById(`fundclass-${card.ticker}`)}
          >
            <span
                className={(card.vehicle === 'MF' && typeof card.class_type !== 'undefined' && card.class_type !== '') ? 'learn-more-text-extpad':'learn-more-text'}
                style={{
                  fontFamily: "Open Sans",
                  fontSize: "11px",
                  fontWeight: 400,
                  color: "#1B8BE0",
                  paddingBottom: 2,
                  float:'right'
                }}
              >
                Learn More&nbsp;<CaretDownOutlined />
              </span>
          </Dropdown> : (card._learnMoreLink || SponseredCardLink) ? <Link
            target='_blank'
            to={{ pathname: SponseredCardLink ? SponseredCardLink : this.props.learnMoreLink }}
            onClick={() => {
              addEventToAnalytics(
                'Sponsor Learn More Web',
                'Sponsor Learn More Web',
                'SPONSOR_LEARN_MORE_WEB',
                {
                  ticker: card.ticker,
                  query_uuid: queryUUID,
                }
              )
            }}
            style={{
              position: "absolute",
              top: 5,
              right: 0,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontFamily: "Open Sans",
                fontSize: "11px",
                fontWeight: 400,
                color: "#1B8BE0",
                paddingBottom: 2
              }}
            >
              Learn More
            </span>
            <img
              src={`/assets/images/learn_more.svg`}
              alt="learn more link"
              style={{ padding: 5 }}
            />
          </Link> : null
        }
        <Row className="no-gutters align-items-center h-100 media-flex-nowrap">
          <Col className="col-lg-3 col-md-auto d-lg-block d-sm-none">
            <Button
              outline={!card._selected}
              color={card._selected ? 'unselect' : 'select'}
              className="btn h4 px-1 py-3 mb-0 text-uppercase font-weight-bold btn-block selectBtn "
              onClick={this.onClick}
            >
              {card._selected ? 'Clear' : 'Select'}
            </Button>
            {card.vehicle && (
              <div className="vehicle-label">{card.vehicle === 'SHARE' ? 'Stock' : card.vehicle}</div>
            )}
          </Col>
          {/* CHANGES I HAVE MADE */}

          {mobileRender}
          <Col className="col-auto col-md-5 text-center">
            <h5 className="mb-0 text-blue text-gray-5 ticker-size ticker-price">
              {/* {card.vehicle != 'MP' ? cn(card, 'Ticker') : (card.sponsor == 'BlackRock Advisors LLC' ? 'BLKMP' : card.sponsor == 'WisdomTree'? 'WSDMP': card.sponsor == 'Commonwealth' ? 'CWMD' : 'INVMP')} */}
              {cn(card, 'Ticker Short')}
            </h5>
            <h3 className="mt-1 mb-0 text-info" style={{ minWidth: '5rem' }}>
              <span className="ticker-price text-gray-5">$</span>
              <span className="ticker-price">
                {cn(card, 'Nav Price') ? cn(card, 'Nav Price').toFixed(1) : ''}
              </span>
            </h3>
          </Col>
          <Col className="media-mycard-desc col-md-6">
            {renderDesign}
            {/* <div className="pb-1 d-flex justify-content-between" onClick = {() => this.props.toggleDetailsModal(detailsModalContent)}>
            {(imageValidPath && cn(card, 'Sponsor')) ? (
              <img src={sponsorLogo(cn(card, 'Sponsor'))} className="img-fluid" alt={cn(card, 'Sponsor')} style={{height: '20px'}}/>
            ): (
              <div className="sponsor-logo-placeholder" title={cn(card, 'Sponsor') || ''}>{cn(card, 'Sponsor') || ''}</div>
            )}
            {cn(card, 'Sponsored') && <h6 className="badge badge-info mb-0 ml-2">{'Ad'}</h6>}
          </div> */}
            {/* <div>
          {(card.vehicle === 'MF' && typeof card.class_type !== 'undefined' && card.class_type !== '')
                && (
                  <div className="card-Ad-Icon" style={{top:'0px'}}>
                    <img id={`fundclass-${card.ticker}`} src={`/assets/images/${card.class_type}.png`} width='10' />
                    <Tooltip className="col-grey" placement="right" isOpen={this.state.tooltipOpen} target={`fundclass-${card.ticker}`} toggle={() => this.setState({ tooltipOpen: !this.state.tooltipOpen })}>
                      {card.class_type === 'retail' ? 'Retail Class' : 'Institutional Class'}
                    </Tooltip>
                  </div>
                )
            }
            </div> */}
            {component}
          </Col>
          <Col md="12" className="d-md-none d-sm-block d-flex">
            <div className="d-flex align-items-center mt-2 ml-5">
              <i className="mr-3 h2 mb-0" onClick={() => this.handleEvent(0)}>
                <span className={`icon-Slader-Home ${this.state.changeHome ? 'hover-orange' : ''}`}>
                  <span className="path1"></span><span className="path2"></span>
                </span>
              </i>
              <i className="mr-3 h2 mb-0" onClick={() => this.handleEvent(1)}>
                <span className={`icon-Slader-Fees ${this.state.changeFees ? 'hover-orange' : ''}`}>
                  {/* <span className="icon-Slader-Fees h2 mb-0 hover-orange"> */}
                  <span className="path1"></span><span className="path2"></span>
                </span>
              </i>
              <i className="mr-3 h2 mb-0" onClick={() => this.handleEvent(2)}>
                <span className={`icon-Slader-Return ${this.state.changeReturn ? 'hover-orange' : ''}`}>
                  {/* <span className="icon-Slader-Return  h2 mb-0 hover-orange"> */}
                  <span className="path1"></span><span className="path2"></span><span className="path3"></span>
                </span>
              </i>
              <i className="mr-3 h2 mb-0" onClick={() => this.handleEvent(3)}>
                <span className={`icon-Slader-Risk ${this.state.changeRisk ? 'hover-orange' : ''}`}>
                  {/* <span className=" icon-Slader-Risk h2 hover-orange"> */}
                  <span className="path1"></span><span className="path2"></span><span className="path3"></span>
                </span>
              </i>
            </div>
            <div className="view_res_funds_details_btn mt-2" onClick={() => this.toggleDetailsModal(detailsModalContent)}>
              <i className={`far fa-arrow-circle-right ${showDetailsModal ? 'active' : ''}`}></i>
            </div>
          </Col>
        </Row>
        <DetailsModal key={card.ticker} item={card} data={detailsModalContent} open={showDetailsModal} toggle={() => this.toggleDetailsModal()} />
        {(SHOW_MORE_STOCKS_BUTTON) && (
          <div className='render-more-stocks-section'>
            <div className='render-more-text' onClick={() => this.moreStocksOnClick()} >More Stocks <i class="fas fa-chevron-right"></i></div>
          </div>
        )}
    </div>
    )
  }
}

const ResponsiveCardPopover = props => {
  const { card, chartData, checkPopOpen, firstPop } = props
  const { highlightText } = chartData
  const flags =
    card._flags.returnsMax || card._flags.risksMin || card._flags.feesMin
  const customStyle = {
    width: 42,
    height: 40,
    position: 'relative',
    top: '-2px',
    fontSize: 38,
    color: '#363a3e'
  }
  const iconUrls = {
    fee: '/assets/images/icons/badge-low-fee.svg',
    risk: '/assets/images/icons/badge-low-risk.svg',
    return: '/assets/images/icons/badge-high-return.svg'
  }

  return (
    // <ul className="list-inline mt-1 mb-0 w-100 d-flex">
    //   <li className="list-inline-item fassf m-0 mr-2 " onClick={() => console.log('Hi clicked')}>
    <ResponsiveAnalyticsPopover
      highlight={highlightText && highlightText.feesHighLightEnable}
      card={card}
      checkPopOpen={checkPopOpen}
      firstPop={firstPop}
      icon={'fa-tags-'}
      qi={QuinItems[0]}
      suffix={<Pct />} />
    // {/* { (card._flags.feesMin||true) && <i class=" h2 mb-0 hover-orange"><span class="icon-Slader-Fees"><span class="path1"></span><span class="path2"></span></span></i> } */}
    //  </li>
    // <li className="list-inline-item fassf m-0 mr-2 " onClick={() => console.log('ResponsiveReturnPopover clicked')}>
    //   <ResponsiveReturnPopover onClick={() => console.log('clicked')} highlight={highlightText&&highlightText.returnHighLightEnable} {...props} />
    //   {/* { (card._flags.returnsMax||true) && <span class="icon-Slader-Return  h2 mb-0 hover-orange"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span> } */}
    //  </li>
    // <li className="list-inline-item fassf m-0 mr-2 " onClick={() => console.log('ResponsiveRiskPopover clicked')}>
    //  <ResponsiveRiskPopover onClick={() => console.log('clicked')} highlight={highlightText&&highlightText.riskHighLightEnable} {...props} />
    // {/* { (card._flags.risksMin||true) && <span class=" icon-Slader-Risk h2 hover-orange"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span> } */}
    //</li>
    //</ul>
  )
}

const ResponsiveReturnPopoverComp = (props) => {
  const { card, chartData, checkPopOpen, firstPop } = props;
  const { highlightText } = chartData;
  const flags = card._flags.returnsMax || card._flags.risksMin || card._flags.feesMin;
  const customStyle = {
    width: 42,
    height: 40,
    position: 'relative',
    top: '-2px',
    fontSize: 38,
    color: '#363a3e',
  };
  const iconUrls = {
    fee: '/assets/images/icons/badge-low-fee.svg',
    risk: '/assets/images/icons/badge-low-risk.svg',
    return: '/assets/images/icons/badge-high-return.svg',
  }

  return (
    <ResponsiveReturnPopover highlight={highlightText && highlightText.returnHighLightEnable} {...props} />
  )
}

const ResponsiveRiskPopoverComp = (props) => {
  const { card, chartData, checkPopOpen, firstPop } = props;
  const { highlightText } = chartData;
  const flags = card._flags.returnsMax || card._flags.risksMin || card._flags.feesMin;
  const customStyle = {
    width: 42,
    height: 40,
    position: 'relative',
    top: '-2px',
    fontSize: 38,
    color: '#363a3e',
  };
  const iconUrls = {
    fee: '/assets/images/icons/badge-low-fee.svg',
    risk: '/assets/images/icons/badge-low-risk.svg',
    return: '/assets/images/icons/badge-high-return.svg',
  }

  return (
    <ResponsiveRiskPopover highlight={highlightText && highlightText.riskHighLightEnable} {...props} />
  )
}

const getLineHeight = element => {
  const lineHeight = window.getComputedStyle(element)['line-height']
  if (lineHeight === 'normal') {
    return 1.16 * parseFloat(window.getComputedStyle(element)['font-size'])
  } else {
    return parseFloat(lineHeight)
  }
}

class NamePopover extends Component {
  state = {
    imageValidPath: false,
  }

  toggle = e => {
    const removeId = e.currentTarget.dataset.index
    const element = document.getElementById(removeId)
    const elementText = element.innerHTML
    const elementFullText = document.querySelector(`[data-value = ${removeId}]`).value
    const containClass = element.classList.contains('text-ellipse')
    const head = e.currentTarget.dataset.head || ''

    if (containClass) {
      const elems = document.querySelectorAll('.show-less')
        ;[].forEach.call(elems, function (el) {
          if (el.id !== element) {
            el.classList.add('text-ellipse')
            const elFullText = document.querySelector(`[data-value = ${el.id}]`).value;
            const lessText = document.querySelector(`[data-value = ${el.id}-less]`);
            if (!lessText) el.innerHTML = elFullText;
            else el.innerHTML = lessText.value;
            const truncateTextParts = el.innerHTML.split(' ')
            const lineHeight = getLineHeight(el)
            const lines = el.id === 'short_desc' || el.id === 'thm_txt' ? 3 : 2

            while (lines * lineHeight < el.offsetHeight) {
              truncateTextParts.pop()
              el.innerHTML = truncateTextParts.join(' ')
            }
          }
          document.querySelector(`[data-index = ${el.id}]`).style.position = 'absolute'
          document.querySelector(`[data-index = ${el.id}]`).innerHTML = '... more'
        })
      element.innerHTML = elementFullText
      document.getElementById(removeId).classList.remove('text-ellipse')
      document.querySelector(`[data-index = ${removeId}]`).style.position = 'relative'
      document.querySelector(`[data-index = ${removeId}]`).innerHTML = 'less'
      this.addMargin()
    } else {
      if (head === '') {
        const truncateTextParts = elementText.split(' ')
        const lineHeight = getLineHeight(element)
        const lines = removeId === 'short_desc' || removeId === 'thm_txt' ? 3 : 2

        while (lines * lineHeight < element.offsetHeight) {
          truncateTextParts.pop()
          element.innerHTML = truncateTextParts.join(' ')
        }
      } else {
        const lessText = document.querySelector(
          `[data-value = ${removeId}-less]`
        ).value
        element.innerHTML = lessText
      }
      document.getElementById(removeId).classList.add('text-ellipse')
      document.querySelector(`[data-index = ${removeId}]`).style.position =
        'absolute'
      document.querySelector(`[data-index = ${removeId}]`).innerHTML =
        '... more'
      this.addMargin()
    }
  }

  addMargin = () => {
    const elems = document.querySelectorAll('.pop-wrapper')
      ;[].forEach.call(elems, function (el) {
        const space = window.innerHeight - (el.offsetTop + el.offsetHeight)
        el.style.paddingBottom = `470px`
        el.style.marginBottom = `-470px`
        setTimeout(() => {
          el.style.paddingBottom = `0px`
          el.style.marginBottom = `0px`
        }, 3000)
      })
  }

  truncString = () => {
    const elems = document.querySelectorAll('.show-less')
    if (elems.length) {
      ;[].forEach.call(elems, function (el) {
        if (el.classList.contains('text-ellipse')) {
          if (el.id !== '') {
            el.classList.add('text-ellipse')
            if (document.querySelector(`[data-value = ${el.id}]`)) {
              if (!document.querySelector(`[data-value = ${el.id}-less]`)) {
                const elFullText =
                  document.querySelector(`[data-value = ${el.id}]`).value || ''
                el.innerHTML = elFullText
                const truncateTextParts = el.innerHTML.split(' ')
                const lineHeight = getLineHeight(el)
                const lines =
                  el.id === 'short_desc' || el.id === 'thm_txt' ? 3 : 2
                if (lines * lineHeight > el.offsetHeight)
                  document.querySelector(
                    `[data-index = ${el.id}]`
                  ).style.display = 'none'
                while (lines * lineHeight < el.offsetHeight) {
                  truncateTextParts.pop()
                  el.innerHTML = truncateTextParts.join(' ')
                }
              } else {
                const elFullText = document.querySelector(
                  `[data-value = ${el.id}]`
                ).value
                const lessText = document.querySelector(
                  `[data-value = ${el.id}-less]`
                ).value
                if (elFullText !== lessText) {
                  el.innerHTML = lessText
                } else {
                  el.innerHTML = elFullText
                  const truncateTextParts = el.innerHTML.split(' ')
                  const lineHeight = getLineHeight(el)
                  const lines =
                    el.id === 'short_desc' || el.id === 'thm_txt' ? 3 : 2
                  if (lines * lineHeight > el.offsetHeight)
                    document.querySelector(
                      `[data-index = ${el.id}]`
                    ).style.display = 'none'
                  while (lines * lineHeight < el.offsetHeight) {
                    truncateTextParts.pop()
                    el.innerHTML = truncateTextParts.join(' ')
                  }
                }
              }
              document.querySelector(`[data-index = ${el.id}]`).style.position =
                'absolute'
              document.querySelector(`[data-index = ${el.id}]`).innerHTML =
                '... more'
            }
          }
        }
      })
    }
  }

  componentDidMount() {
    const { card } = this.props;
    if (cn(card, 'Sponsor')) {
      getImageOrFallback(
        sponsorLogo(cn(card, 'Sponsor'))
      ).then(result => {
        if (result) {
          this.setState({ imageValidPath: true });
        } else {
          this.setState({ imageValidPath: false });
        }
      });
    }
  }

  viewHandler(view, secondaryView, mappingVariable) {
    // console.log(view, secondaryView, mappingVariable);
    const { setFundsState } = this.props;
    setFundsState({
      itemCardView: {view, secondaryView, mappingVariable}
    });
  }

  searchFundsActiveRegion = (activeRegion) => {
    console.log(activeRegion);
    const { setFundsState } = this.props;
    setFundsState({
      activeRegion
    });
  }

  render() {
    const { imageValidPath } = this.state;
    const { card, index, checkPopOpen, firstPop, firstPopIndex, highlightText, reponsiveDetailsModal, imagePath, funds, chartData } = this.props;
    const { view: _fundView, itemCardView, activeRegion } = funds;
    const weightChartData = highlightText.popupHighLightEnable ? highlightText.popupHighLightEnable : null;
    const highlightValue = weightChartData !== null && weightChartData.length > 0 ? card[weightChartData[0].data] : 1;
    const thm_txt = cn(card, 'thm_txt');
    let value_stk = cn(card, 'value_stk');
    if (value_stk) {
      try {
        let stk_split = value_stk.split('$$$$$')
        let stk_parse = []
        stk_split.map(e => {
          let text, company
          let row_split = e.split('##')
          if (row_split.includes('text') && row_split.includes('company')) {
            const STKObject = getSTKData(row_split)
            if (STKObject) {
              if (
                typeof STKObject.text !== 'undefined' &&
                typeof STKObject.company !== 'undefined'
              ) {
                stk_parse.push({
                  text: STKObject.text.value,
                  company: STKObject.company.value,
                  less_description: STKObject.startend.value
                })
              }
            }
          }
          // if(row_split.length == 7) {
          //   stk_parse.push({
          //     text: row_split[2],
          //     company: row_split[6]
          //   });
          // }
          else {
            throw Error('wrong text')
          }
        })
        value_stk = stk_parse
      } catch (e) {
        value_stk = null
      }
    }

    const letNameWillBe = cn(card, 'Name2') || cn(card, 'Name')
    const filterNameBySemiColon = letNameWillBe
    const letPopOverNameWillbe = cn(card, 'Name')
    const filterPopOverNameWillbe = letPopOverNameWillbe

    const E1 = ({ onClick }) => (
      <React.Fragment>
        <div className="pb-1 d-flex justify-content-between" onClick={onClick}>
          {(imageValidPath && cn(card, 'Sponsor')) ? (
            <img src={sponsorLogo(cn(card, 'Sponsor'))} className="img-fluid" alt={cn(card, 'Sponsor')} style={{ height: '20px' }} />
          ) : (
              <div className="sponsor-logo-placeholder" title={cn(card, 'Sponsor') || ''}>{cn(card, 'Sponsor') || ''}</div>
            )}
          {/*cn(card, 'Sponsored') && <h6 className="badge badge-info mb-0 ml-2">{'Ad'}</h6>*/}
        </div>
        <h5 className="mb-1 text-ssf-blue-d2 text-overflow-ellipse-line-2" style={{ fontWeight: 600 }}>
          {filterNameBySemiColon}
        </h5>
        {/* <h5 className="mb-0 text-gray-6">
        {cn(card, 'Category')}</h5> */}
        <h5 className="mb-0 text-gray-6"
            onClick={() => {
            if(!(card.is_sponsored && config.sponsoredTicker)){
              this.props.history.push('/securities');
              this.props.queryHandler(cn(card, 'Category'));
            }
          }
        } style={card.is_sponsored ? {} : {cursor:'pointer'}}>{cn(card, 'Category')}</h5>

      </React.Fragment>
    );

    const E = ({ onClick }) => (
      <React.Fragment>
        {/* <div onClick = {onClick}>  */}
        {/* {(imageValidPath && cn(card, 'Sponsor')) ? (
            <img src={sponsorLogo(cn(card, 'Sponsor'))} className="img-fluid" alt={cn(card, 'Sponsor')} style={{height: '20px'}} />
          ): (
            <div className="sponsor-logo-placeholder" title={cn(card, 'Sponsor') || ''}>{cn(card, 'Sponsor') || ''}</div>
          )}
          {cn(card, 'Sponsored') && <h6 className="badge badge-info mb-0 ml-2">{'Ad'}</h6>} */}

        <h5 className="mb-1 text-ssf-blue-d2 text-overflow-ellipse-line-2" style={{ fontWeight: 600 }}>
          {filterNameBySemiColon}
        </h5>
        {/* <h5 className="mb-0 text-gray-6" >
          {cn(card, 'Category')}</h5> */}
          <h5 className="mb-0 text-gray-6"
            onClick={() => {
            if(!card.is_sponsored){
              this.props.history.push('/securities');
              this.props.queryHandler(cn(card, 'Category'),'fund_category');
            }
          }
        } style={card.is_sponsored ? {}: {cursor:'pointer'}}>{cn(card, 'Category')}</h5>

        {/* </div>  */}
      </React.Fragment>
    )

    const E2 = () => (
      <React.Fragment>
        <PopoverBody className="p-0 media-popover">
          <Row className="no-gutters text-gray-7">
            <Col className="col-12 p-2 pt-3 br-radius">
              <h4 className="fs-17 text-ssf-blue-d2 mb-1">
                {filterPopOverNameWillbe}
              </h4>
              {/* <div className="fs-13 font-three text-justify mt-2">{cn(card, 'short_desc')}</div> */}
              <div className="shw-container btn-white">
                <input
                  type="text"
                  data-value="short_desc"
                  readOnly
                  value={cn(card, 'short_desc')}
                  hidden={true}
                />
                <span
                  className="fs-13 font-three text-justify mt-2 show-less text-ellipse"
                  id="short_desc"
                >
                  {cn(card, 'short_desc')}
                </span>
                <button
                  className="shw-ls-btn font-three fs-13"
                  id="btn-white"
                  type="button"
                  data-index="short_desc"
                  onClick={this.toggle}
                  style={{ backgroundColor: '#fff !important' }}
                >
                  ... more
                </button>
              </div>
            </Col>
            {thm_txt && thm_txt != 'nan' && thm_txt != 'NaN' && (
              <Col className="col-12 p-2 bg-light br-radius">
                <h6 className="font-weight-semi-bold mb-1 text-info">
                  Theme information from fund prospectus
                </h6>
                {/* <div className="fs-13 font-three text-justify">{thm_txt}</div> */}
                <div className="shw-container">
                  <input
                    type="text"
                    data-value="thm_txt"
                    value={thm_txt}
                    hidden="true"
                  />
                  <span
                    className="fs-13 font-three text-justify mt-2 show-less text-ellipse"
                    id="thm_txt"
                  >
                    {thm_txt}
                  </span>
                  <button
                    className="shw-ls-btn font-three fs-13"
                    type="button"
                    data-index="thm_txt"
                    onClick={this.toggle}
                    style={{ backgroundColor: '#f8f9fa !important' }}
                  >
                    ... more
                  </button>
                </div>
              </Col>
            )}
            {value_stk && value_stk != 'nan' && value_stk != 'NaN' && (
              <Col className="col-12 p-2 bg-light br-radius">
                <h6 className="font-weight-semi-bold mb-1 text-info">
                  Theme information from annual reports of underlying holdings
                </h6>
                {value_stk.map((v, i) => (
                  // <div key={v.company} className="fs-13 font-three text-justify">
                  //   <strong>{convertToCapitalCase(v.company)}:</strong> <span dangerouslySetInnerHTML={{ __html: v.text }} /></div>
                  <div
                    key={v.company}
                    className="fs-13 font-three text-justify"
                  >
                    <strong>{convertToCapitalCase(v.company)}:</strong>
                    <div className="shw-container">
                      <input
                        type="text"
                        data-value={`text-${i}`}
                        value={v.text}
                        data-less={v.less_description}
                        hidden="true"
                      />
                      <input
                        type="text"
                        data-value={`text-${i}-less`}
                        value={v.less_description}
                        hidden="true"
                      />
                      <span
                        className="fs-13 font-three text-justify mt-2 show-less text-ellipse"
                        id={`text-${i}`}
                        dangerouslySetInnerHTML={{ __html: v.text }}
                      ></span>
                      <button
                        className="btn-white shw-ls-btn font-three"
                        type="button"
                        data-index={`text-${i}`}
                        data-head="value_stk"
                        onClick={this.toggle}
                        style={{ backgroundColor: '#fff !important' }}
                      >
                        ... more
                      </button>
                    </div>
                  </div>
                ))}
              </Col>
            )}
            {weightChartData && weightChartData.length > 0 && (
              <Col className="col-12 p-2s bg-light br-radius">
                {weightChartData.map((item, i) => (
                  <Col className="col-12">
                    <span className="font-weight-semi-bold mb-1 text-info">
                      {item['Name of the chart on UI']}
                    </span>
                    <span className="mb-1">: {item['name']}</span>
                  </Col>
                ))}
              </Col>
            )}
          </Row>
        </PopoverBody>
        {this.truncString()}
      </React.Fragment>
    )

    const detailsModalContent = (
      <React.Fragment>
        <Row className="no-gutters text-gray-7">
          <Col className="col-12 p-2 pt-3">
            <div className="pb-1 d-flex justify-content-between" onClick={() => this.props.toggleDetailsModal(detailsModalContent)}>
              {(imageValidPath && cn(card, 'Sponsor')) ? (
                <img src={sponsorLogo(cn(card, 'Sponsor'))} className="img-fluid" alt={cn(card, 'Sponsor')} style={{ height: '20px' }} />
              ) : (
                  <div className="sponsor-logo-placeholder" title={cn(card, 'Sponsor') || ''}>{cn(card, 'Sponsor') || ''}</div>
                )}
              {/* cn(card, 'Sponsored') && <h6 className="badge badge-info mb-0 ml-2">{'Ad'}</h6> */}
            </div>
          </Col>
          <Col className="col-12 p-2 pt-3">
            <h4 className="fs-17 text-ssf-blue-d2 mb-1">{cn(card, 'Name')}</h4>
            <div className="fs-13 font-three text-justify mt-2">{cn(card, 'short_desc')}</div>
          </Col>
          {
            weightChartData && weightChartData.length > 0 &&
            <Col className="col-12 p-2 bg-light">
              <span className="font-weight-semi-bold mb-1 text-info">Weighting Strategy</span><span className="mb-1">: {card.weighting_scheme_desc}</span>
            </Col>
          }
          {thm_txt && thm_txt != "nan" && thm_txt != "NaN" &&
            <Col className="col-12 p-2 bg-light">
              <h6 className="font-weight-semi-bold mb-1 text-info">Theme information from fund prospectus</h6>
              <div className="fs-13 font-three text-justify">{thm_txt}</div>
            </Col>}
          {value_stk && value_stk != "nan" && value_stk != "NaN" &&
            <Col className="col-12 p-2 bg-light">
              <h6 className="font-weight-semi-bold mb-1 text-info">Theme information from annual reports of underlying holdings</h6>
              {value_stk.map(v => (
                <div key={v.company} className="fs-13 font-three text-justify">
                  <strong>{convertToCapitalCase(v.company)}:</strong> <span dangerouslySetInnerHTML={{ __html: v.text }} /></div>
              ))}
            </Col>}
          <div className="modal-body-ssf text-center pt-4 viewChartsColumn">
            <Relevance
              funds={funds}
              view={_fundView || reponsiveDetailsModal.view}
              chartData={chartData}
              itemCardView={itemCardView}
              selectedFunDetails={card}
              activeRegion={activeRegion}
              searchFundsActiveRegion={this.searchFundsActiveRegion}
              viewHandler={this.viewHandler}
            />
          </div>
        </Row>
      </React.Fragment>
    );

    const E3 = () => (
      <React.Fragment>
        <PopoverBody className="p-0 media-popover">
          <Row className="no-gutters text-gray-7">
            <Col className="col-12 p-2 pt-3">
              <h4 className="fs-17 text-ssf-blue-d2 mb-1">
                {cn(card, 'Name')}
              </h4>
              <div className="fs-13 font-three text-justify mt-2">
                {cn(card, 'short_desc')}
              </div>
            </Col>
            {weightChartData && weightChartData.length > 0 && (
              <Col className="col-12 p-2 bg-light">
                <span className="font-weight-semi-bold mb-1 text-info">
                  Weighting Strategy
                </span>
                <span className="mb-1">: {card.weighting_scheme_desc}</span>
              </Col>
            )}
            {thm_txt && thm_txt != 'nan' && thm_txt != 'NaN' && (
              <Col className="col-12 p-2 bg-light">
                <h6 className="font-weight-semi-bold mb-1 text-info">
                  Theme information from fund prospectus
                </h6>
                <div className="fs-13 font-three text-justify">{thm_txt}</div>
              </Col>
            )}
            {value_stk && value_stk != 'nan' && value_stk != 'NaN' && (
              <Col className="col-12 p-2 bg-light">
                <h6 className="font-weight-semi-bold mb-1 text-info">
                  Theme information from annual reports of underlying holdings
                </h6>
                {value_stk.map(v => (
                  <div
                    key={v.company}
                    className="fs-13 font-three text-justify"
                  >
                    <strong>{convertToCapitalCase(v.company)}:</strong>{' '}
                    <span dangerouslySetInnerHTML={{ __html: v.text }} />
                  </div>
                ))}
              </Col>
            )}
          </Row>
        </PopoverBody>
      </React.Fragment>
    )

    const WithPopover = withPopover(E1, E2, { id: "NamePopover-" + cn(card, 'Id'), placement: 'right', index, checkPopOpen, firstPop, firstPopIndex, namePopClass: 'namePop', trigger: 'click', funds, card, popUp: 'Fund Details Popup' })
    if (window.screen.width > 760) {
      return (
        <WithPopover />
      );
    }
    else {
      return (
        <React.Fragment>
          <E onClick={() => this.props.toggleDetailsModal(detailsModalContent)} />
        </React.Fragment>
      );
    }
  }
}

class Analytics extends Component {
  render() {
    const { card, checkPopOpen, firstPop } = this.props

    return (
      <div className="w-100 d-flex align-items-center bg-ssf-gray-l5">
        <ul className="list-inline h3 pt-3 text-ssf-blue-d2 w-100 d-flex justify-content-around">
          <li className="list-inline-item">
            <AnalyticsPopover
              card={card}
              checkPopOpen={checkPopOpen}
              firstPop={firstPop}
              icon={'fa-tags-'}
              qi={QuinItems[0]}
              suffix={<Pct />}
            />
          </li>
          <li className="list-inline-item">
            <AnalyticsPopover
              card={card}
              checkPopOpen={checkPopOpen}
              firstPop={firstPop}
              icon={'fa-hand-holding-usd-'}
              qi={QuinItems[1]}
              prefix={<span className="text-gray-5">$</span>}
              suffix={<span className="text-gray-6"> K</span>}
            />
          </li>
          <li className="list-inline-item">
            <AnalyticsPopover
              card={card}
              checkPopOpen={checkPopOpen}
              firstPop={firstPop}
              icon={'fa-coins-'}
              qi={QuinItems[2]}
              prefix={<span className="text-gray-5">$</span>}
              suffix={<span className="text-gray-6"> M</span>}
            />
          </li>
        </ul>
      </div>
    )
  }
}

class AnalyticsPopover extends Component {
  render() {
    const { card, qi, icon, highlight, checkPopOpen, firstPop, funds } = this.props
    let val =
      qi.name === 'Volume' ? Math.round(card[qi.col] / 1000) : card[qi.col]
    // if(qi.name == "Fees" && cn(card, 'Vehicle') == "MF"){
    if (qi.name == 'Fees') {
      // val = val * 100;
      val = val ? val.toFixed(2) : '--'
    }

    const valFmt = val !== '--' ? <span className="text-gray-8 font-weight-bold">{this.props.prefix}{val.toLocaleString('en', { minimumFractionDigits: 0 })}{ val !== '--' ? <Pct /> : ''}</span> : '--';

    // const catVal =
    //   qi.name === 'Fees'
    //     ? card._categorySeries && card._categorySeries[qi.col] && card._categorySeries[qi.col].toFixed(2)
    //     : Math.round(card._categorySeries[qi.col])

    const catVal = (card._categorySeries && card._categorySeries !== null) ? (qi.name === 'Fees' ? (card._categorySeries[qi.col] ? card._categorySeries[qi.col].toFixed(2) : '--') : Math.round(card._categorySeries[qi.col])) : '--';
    const catValFmt = (
      <span>
        {this.props.prefix}
        {catVal.toLocaleString('en', { minimumFractionDigits: 0 })}%
      </span>
    )

    const hikeLabel = (val !== '--') ? (quintileSize(card[qi.qcol], qi.reverse) === 2 ? 'Average' : quintileSize(card[qi.qcol], qi.reverse) === 3 ? 'High' : 'Low') : ''
    // const statusColor = quintileClass(card[qi.qcol], qi.reverse) === 'danger' ? '#ff4d4d' : (quintileClass(card[qi.qcol], qi.reverse) === 'success' ? '#416D60' : '#1b8be0');

    const e1 = () => (
      <div className="fees-flag-color">
        <i
          className={classNames(
            'far fa-fw fassf',
            icon + quintileSize(card[qi.qcol], qi.reverse)
          )}
        />
        <p
          style={{ margin: 0, fontSize: 11, lineHeight: 1, fontWeight: 'bold' }}
        >
          {this.props.prefix}
          {val ? val.toLocaleString('en', { minimumFractionDigits: 0 })+'%' : '--'}
        </p>
        <p style={{ margin: 0, fontSize: 10, lineHeight: 1 }}>{hikeLabel}</p>
      </div>
    )

    const e2 = () => (
      <React.Fragment>
        <PopoverBody className="p-0">
          <Row className="no-gutters">
            {/* <Col className="col-12 p-2 pt-3 d-flex justify-content-between align-items-center">
              <h4 className="fs-17 text-ssf-blue-d2 mb-1">{qi.name}</h4>
            </Col> */}
            <Col className="col-12 p-2 text-gray-9 bg-light br-2">
              <div className="h5 d-flex justify-content-between pt-1">
                <div className="popover-drop">
                  <div
                    className="fees-pop-tog popover-drop__toggle"
                    onMouseOver={this.onMouseOver}
                    onMouseLeave={this.onMouseLeave}
                    onClick={this.onMouseOver,this.onMouseLeave}
                >
                    <span className="pop-toggle-name">Fees</span>
                </div>
              </div>
              <span>{valFmt}</span>
              </div>
            <Quintile
              cardCategory={cn(card, 'Category')}
              date={null}
              item={qi}
              value={card[qi.qcol]}
              catValue={catValFmt}
              bg={quintileClass(card[qi.qcol], qi.reverse)}
            />
            </Col>
          </Row>
        </PopoverBody>
      </React.Fragment >
    )

    const highlightClassName = highlight ? 'search-fees-return-stats-padding search-risk-stats-highlighting align-items-center d-flex' : (card._flags.feesMin ? 'search-fees-return-stats-padding search-risk-stats-highlighting align-items-center d-flex' : 'search-fees-return-stats-padding align-items-center d-flex');
    const WithPopover = withPopover(e1, e2, {
      id: "AnalyticsPopover-" + qi.name + '-' + cn(card, 'Id'), placement: 'right', className: highlightClassName,
      checkPopOpen, firstPop, funds, card, popUp: 'Fees Popup',
    })

    return (
      <WithPopover />
    );
  }
}

class ResponsiveAnalyticsPopover extends Component {

  render() {
    const { card, qi, icon, highlight, checkPopOpen, firstPop } = this.props

    let val = qi.name === 'Volume' ? Math.round(card[qi.col] / 1000) : card[qi.col]
    if (qi.name == 'Fees' && cn(card, 'Vehicle') == 'MF') {
      // val = val * 100;
      val = val ? val.toFixed(2) : '--';
    }

    const valFmt = <span className="font-weight-bold">{this.props.prefix}{val ? val.toLocaleString('en', { minimumFractionDigits: 0 })+'%' : '--'}</span>
    const catVal = qi.name === 'Fees' ? card._categorySeries[qi.col]?card._categorySeries[qi.col].toFixed(2):card._categorySeries[qi.col] : Math.round(card._categorySeries[qi.col]);
    const catValFmt = <span>{this.props.prefix}{catVal ? catVal.toLocaleString('en', { minimumFractionDigits: 0 })+'%' :'--'}</span>

    const hikeLabel = (val !== '--') ? (quintileSize(card[qi.qcol], qi.reverse) === 2 ? 'Average' : quintileSize(card[qi.qcol], qi.reverse) === 3 ? 'High' : 'Low') : '';

    // const statusColor = quintileClass(card[qi.qcol], qi.reverse) === 'danger' ? '#ff4d4d' : (quintileClass(card[qi.qcol], qi.reverse) === 'success' ? '#416D60' : '#1b8be0');
    //console.log(hikeLabel);
    // const e1 = () => (
    //     <span className="icon-Slader-Fees h2 mb-0 hover-orange">
    //       <span className="path1"></span><span className="path2"></span>
    //     </span>
    // );

    // const e2 = () => (
    //   <React.Fragment>
    //     <PopoverBody className="p-0">
    //       <Row className="no-gutters">
    //         {/* <Col className="col-12 p-2 pt-3 d-flex justify-content-between align-items-center">
    //           <h4 className="fs-17 text-ssf-blue-d2 mb-1">{qi.name}</h4>
    //         </Col> */}
    //         <Col className="col-12 p-2 text-gray-9 bg-light br-2">
    //           <div className="h5 d-flex justify-content-between pt-1">
    //             <div className="popover-drop">
    //               <div className="fees-pop-tog popover-drop__toggle" onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave} >
    //                 <span className="pop-toggle-name">Fees</span>
    //               </div>
    //             </div>
    //             <span>{valFmt}</span>
    //           </div>
    //           <Quintile date={null} item={qi} value={card[qi.qcol]} catValue={catValFmt} bg={quintileClass(card[qi.qcol], qi.reverse)} />
    //         </Col>
    //       </Row>
    //     </PopoverBody>
    //   </React.Fragment>
    // );

    // const e2=({onClick}) => (
    //   <React.Fragment>
    //     <div className="pb-1 d-flex justify-content-between" onClick = {onClick}>
    //       {/* {(imageValidPath && cn(card, 'Sponsor')) ? (
    //         <img src={sponsorLogo(cn(card, 'Sponsor'))} className="img-fluid" alt={cn(card, 'Sponsor')} style={{height: '20px'}} />
    //       ): (
    //         <div className="sponsor-logo-placeholder" title={cn(card, 'Sponsor') || ''}>{cn(card, 'Sponsor') || ''}</div>
    //       )} */}
    //       {cn(card, 'Sponsored') && <h6 className="badge badge-info mb-0 ml-2">{'Ad'}</h6>}
    //     </div>
    //     <h5 className="mb-1 text-ssf-blue-d2" style={{ fontWeight: 600 ,lineHeight: 1.5}}>
    //     Fees {hikeLabel}
    //     </h5>
    //     <h5 className="mb-1 text-ssf-blue-d2" style={{ fontWeight: 600 ,lineHeight: 1.5}}>
    //       Fees ({valFmt}) vs Category ({catValFmt})
    //     </h5>
    //   </React.Fragment>
    // );

    return (
      <React.Fragment>
        <h5 className="mb-1 text-ssf-blue-d2" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          Fees <span className="label-value">{hikeLabel}</span>
        </h5>
        <h5 className="mb-0 text-gray-6">
          Fees (<span style={{ fontWeight: 'bold' }}>{valFmt}</span>) vs Category (<span style={{ fontWeight: 'bold' }}>{catValFmt}</span>)
        </h5>
      </React.Fragment>
    )

    // const highlightClassName = highlight ? 'search-fees-return-stats-padding search-risk-stats-highlighting' : (card._flags.feesMin ? 'search-fees-return-stats-padding search-risk-stats-highlighting' : 'search-fees-return-stats-padding');
    // const WithPopover = withPopover( e2, { id: "ResponsiveAnalyticsPopover-" + qi.name + '-' + cn(card, 'Id'), placement: 'bottom',
    //    checkPopOpen, firstPop
    // })

  }
}

class ReturnPopover extends Component {
  onMouseOver = () => {
    const element = document.querySelector('.popover-drop__item')
    element.style.display = 'block'
  }

  onMouseOverItem = () => {
    const element = document.querySelector('.popover-drop__item')
    element.style.display = 'block'
  }

  onMouseLeave = () => {
    const element = document.querySelector('.popover-drop__item')
    element.style.display = 'none'
  }

  setItem = e => {
    const { card, funds } = this.props;
    const val = e.target.id
    const element = document.querySelector('.pop-toggle-name')
    element.innerHTML = val
    document.querySelector('.popover-drop__item').style.display = 'none'

    if (val === 'Annualized Return') {
      const element1 = document.querySelector('.popover-drop__item__wrapper')
      element1.innerHTML = `<li class="popover-drop__item__wrapper__list" id="Cumulative Return">Cumulative Return</li>`
      const elems1 = document.querySelectorAll('.cumulative')
        ;[].forEach.call(elems1, function (el) {
          el.style.display = 'none'
        })
      const elems2 = document.querySelectorAll('.annualized-average')
        ;[].forEach.call(elems2, function (el) {
          el.style.display = 'block'
        })
    } else {
      const element1 = document.querySelector('.popover-drop__item__wrapper')
      element1.innerHTML = `<li class="popover-drop__item__wrapper__list" id="Annualized Return">Annualized Return</li>`
      const elems1 = document.querySelectorAll('.annualized-average')
        ;[].forEach.call(elems1, function (el) {
          el.style.display = 'none'
        })
      const elems2 = document.querySelectorAll('.cumulative')
        ;[].forEach.call(elems2, function (el) {
          el.style.display = 'block'
        })
    }

    addEventToAnalytics('Return Popup -- Dropdown','Return Popup -- Dropdown','RETURN_POPUP -- DROPDOWN',{ query: funds.query, logId: funds.logId, optionSelected: val, card: card.ticker },true);
    this.addEvent()
  }

  addEvent = () => {
    const abc = document.querySelector('.popover-drop__item__wrapper__list')
    if (abc !== null) {
      abc.addEventListener('click', this.setItem)
    }
  }

  render() {
    const { card, highlight, checkPopOpen, firstPop, funds } = this.props
    const i = 3
    const icon = 'fa-chart-line-'

    const hikeLabel =
      quintileSize(card._return_quin, QuinItems[i].reverse) === 2
        ? 'Average'
        : quintileSize(card._return_quin, QuinItems[i].reverse) === 3
          ? 'High'
          : 'Low'
    // const statusColor = quintileClass(card._return_quin, QuinItems[i].reverse) === 'danger' ? '#ff4d4d' : (quintileClass(card._return_quin, QuinItems[i].reverse) === 'success' ? '#416D60' : '#1b8be0');
    const statusText = (card && card._return_v) ? card._return_v.toFixed(2) + '%' : '--'

    const e1 = () => (
      <div className="returns-flag-color">
        <i
          className={classNames(
            'far fa-fw fassf',
            icon + quintileSize(card._return_quin, QuinItems[i].reverse)
          )}
        />
        <p
          style={{ margin: 0, fontSize: 11, lineHeight: 1, fontWeight: 'bold' }}
        >
          {statusText}
        </p>
        <p style={{ margin: 0, fontSize: 10, lineHeight: 1 }}>{hikeLabel}</p>
      </div>
    )

    const e2 = () => (
      <React.Fragment>
        <PopoverBody className="p-0">
          <Row className="no-gutters">
            {/* <Col className="col-12 p-2 pt-3 d-flex justify-content-between align-items-center">
              <h4 className="fs-17 text-ssf-blue-d2 mb-1">{'Return'}<span className="h6 font-three text-gray-7 ml-2">{card._stats.start + ' - ' + card._stats.end}</span></h4>
            </Col> */}
            <Col className="col-12 p-2 text-gray-9 bg-light br-2">
              <div
                className={classNames('d-flex justify-content-between')}
              >
                <div className="popover-drop">
                  <div
                    className="popover-drop__toggle"
                    onMouseOver={this.onMouseOver}
                    onMouseLeave={this.onMouseLeave}
                  >
                    <span className="pop-toggle-name">Annualized Return</span>{' '}
                    <i className="fas fa-caret-down"></i>
                  </div>
                  <div
                    className="popover-drop__item"
                    onMouseOver={this.onMouseOver}
                    onMouseLeave={this.onMouseLeave}
                    onClick={this.onMouseOver,this.onMouseLeave}
                >
                    <ul className="popover-drop__item__wrapper">
                    {/* <li className="popover-drop__item__wrapper__list" onClick={() => this.setItem('Annualized Average')}>Annualized Average</li> */}
                    <li
                      className="popover-drop__item__wrapper__list"
                      id="Cumulative Return"
                    >
                      Cumulative Return
                      </li>
                  </ul>
                </div>
              </div>
              <span className="font-weight-bold annualized-average">
                { card._return_v.toFixed(2)}
                <Pct />
              </span>
              <span className="font-weight-bold cumulative">
                {card._return_cuml_v.toFixed(2)}
                <Pct />
              </span>
              </div>

            <div className="annualized-average">
              <Quintile
                cardCategory={cn(card, 'Category')}
                date={`${card._returnsChart.start} - ${card._returnsChart.end}`}
                item={QuinItems[i]}
                value={card._return_quin}
                catValue={
                  <span>
                    {card._categorySeries.yRet.toFixed(2)}
                    <Pct />
                  </span>
                }
                bg={quintileClass(card._return_quin, QuinItems[i].reverse)}
              />
            </div>
            <div className="cumulative">
              <Quintile
                cardCategory={cn(card, 'Category')}
                date={`${card._returnsChart.start} - ${card._returnsChart.end}`}
                item={QuinItems[i]}
                value={card._return_cuml_quin}
                catValue={
                  <span>
                    {card._categorySeries.cuml.toFixed(2)}
                    <Pct />
                  </span>
                }
                bg={quintileClass(card._return_cuml_quin, QuinItems[i].reverse)}
              />
            </div>
            </Col>
          </Row>
        </PopoverBody>
        { this.addEvent() }
      </React.Fragment >
    )

    const highlightClassName = highlight
      ? 'search-fees-return-stats-padding hover-orange search-risk-stats-highlighting align-items-center d-flex'
      : card._flags.returnsMax
        ? 'search-fees-return-stats-padding search-risk-stats-highlighting align-items-center d-flex'
        : 'search-fees-return-stats-padding  align-items-center d-flex'
    const WithPopover = withPopover(e1, e2, {
      id: 'ReturnPopover-' + cn(card, 'Id'),
      placement: 'right',
      className: highlightClassName,
      checkPopOpen,
      firstPop,
      funds,
      card,
      popUp: 'Return Popup'
    })
    return <WithPopover />
  }
}

class ResponsiveReturnPopover extends Component {

  setItem = e => {
    const val = e.target.id

    const element = document.querySelector('.pop-toggle-name')
    element.innerHTML = val
    document.querySelector('.popover-drop__item').style.display = 'none'

    if (val === 'Annualized Return') {
      const element1 = document.querySelector('.popover-drop__item__wrapper')
      element1.innerHTML = `<li class="popover-drop__item__wrapper__list" id="Cumulative Return">Cumulative Return</li>`
      const elems1 = document.querySelectorAll('.cumulative')
        ;[].forEach.call(elems1, function (el) {
          el.style.display = 'none'
        })
      const elems2 = document.querySelectorAll('.annualized-average')
        ;[].forEach.call(elems2, function (el) {
          el.style.display = 'block'
        })
    } else {
      const element1 = document.querySelector('.popover-drop__item__wrapper')
      element1.innerHTML = `<li class="popover-drop__item__wrapper__list" id="Annualized Return">Annualized Return</li>`
      const elems1 = document.querySelectorAll('.annualized-average')
        ;[].forEach.call(elems1, function (el) {
          el.style.display = 'none'
        })
      const elems2 = document.querySelectorAll('.cumulative')
        ;[].forEach.call(elems2, function (el) {
          el.style.display = 'block'
        })
    }

    this.addEvent()
  }

  addEvent = () => {
    const abc = document.querySelector('.popover-drop__item__wrapper__list')
    if (abc !== null) {
      abc.addEventListener('click', this.setItem)
    }
  }

  render() {
    const { card, highlight, checkPopOpen, firstPop } = this.props
    const i = 3
    const icon = 'fa-chart-line-'

    const hikeLabel =
      quintileSize(card._return_quin, QuinItems[i].reverse) === 2
        ? 'Average'
        : quintileSize(card._return_quin, QuinItems[i].reverse) === 3
          ? 'High'
          : 'Low'
    // const statusColor = quintileClass(card._return_quin, QuinItems[i].reverse) === 'danger' ? '#ff4d4d' : (quintileClass(card._return_quin, QuinItems[i].reverse) === 'success' ? '#416D60' : '#1b8be0');
    const statusText =  card._return_v.toFixed(2) + '%'

    // const e1 = () => (
    //     <span className="icon-Slader-Return  h2 mb-0 hover-orange"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
    // );

    // const e1 = () => (
    //   <React.Fragment>
    //     <PopoverBody className="p-0">
    //       <Row className="no-gutters">
    //         {/* <Col className="col-12 p-2 pt-3 d-flex justify-content-between align-items-center">
    //           <h4 className="fs-17 text-ssf-blue-d2 mb-1">{'Return'}<span className="h6 font-three text-gray-7 ml-2">{card._stats.start + ' - ' + card._stats.end}</span></h4>
    //         </Col> */}
    //         <Col className="col-12 p-2 text-gray-9 bg-light br-2">
    //           <div className={classNames("h5 d-flex justify-content-between pt-1")}>
    //             <div className="popover-drop">
    //               <div className="popover-drop__toggle" onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave} >
    //                 <span className="pop-toggle-name">Annualized Return</span> <i className="fas fa-caret-down"></i>
    //               </div>
    //               <div className="popover-drop__item" onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}>
    //                 <ul className="popover-drop__item__wrapper">
    //                   {/* <li className="popover-drop__item__wrapper__list" onClick={() => this.setItem('Annualized Average')}>Annualized Average</li> */}
    //                   <li className="popover-drop__item__wrapper__list" id="Cumulative Return">Cumulative Return</li>
    //                 </ul>
    //               </div>
    //             </div>
    //             <span className="font-weight-bold annualized-average">{ card._return_v.toFixed(2)}<Pct /></span>
    //             <span className="font-weight-bold cumulative">{card._return_cuml_v.toFixed(2)}<Pct /></span>
    //           </div>

    //           <div className="annualized-average">
    //             <Quintile date={`${card._stats.start} - ${card._stats.end}`} item={QuinItems[i]} value={card._return_quin}
    //               catValue={<span>{card._categorySeries.yRet.toFixed(2)}<Pct /></span>}
    //               bg={quintileClass(card._return_quin, QuinItems[i].reverse)} />
    //           </div>
    //           <div className="cumulative">
    //             <Quintile date={`${card._stats.start} - ${card._stats.end}`} item={QuinItems[i]} value={card._return_cuml_quin}
    //               catValue={<span>{card._categorySeries.cuml.toFixed(2)}<Pct /></span>}
    //               bg={quintileClass(card._return_cuml_quin, QuinItems[i].reverse)} />
    //           </div>
    //         </Col>
    //       </Row>
    //     </PopoverBody>
    //     {this.addEvent()}
    //   </React.Fragment>
    // );

    return (
      <React.Fragment>
        <h5 className="mb-1 text-ssf-blue-d2" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          Return <span className="label-value">{hikeLabel}</span>
        </h5>
        <h5 className="mb-0 text-gray-6">
          Annualized Avg (<span style={{ fontWeight: 'bold' }}>{ card._return_v.toFixed(2)}%</span>) vs Category (<span style={{ fontWeight: 'bold' }}>{card._categorySeries.yRet.toFixed(2)}%</span>)
        </h5>
      </React.Fragment>
    )

    // const highlightClassName = highlight ? 'search-fees-return-stats-padding hover-orange search-risk-stats-highlighting' : (card._flags.returnsMax ? 'search-fees-return-stats-padding search-risk-stats-highlighting' : 'search-fees-return-stats-padding');
    // const WithPopover = withPopover(e1, { id: "ResponsiveReturnPopover-" + cn(card, 'Id'), placement: 'bottom',
    //    checkPopOpen, firstPop
    //  });
    // return (
    //   <WithPopover />
    // );
  }
}

class RiskPopover extends Component {
  onMouseOver = () => {
    const element = document.querySelector('.popover-drop__item')
    element.style.display = 'block'
  }

  onMouseOverItem = () => {
    const element = document.querySelector('.popover-drop__item')
    element.style.display = 'block'
  }

  onMouseLeave = () => {
    const element = document.querySelector('.popover-drop__item')
    element.style.display = 'none'
  }

  setItem = e => {
    const { card, funds } = this.props;
    const val = e.target.id
    const element = document.querySelector('.pop-toggle-name')
    element.innerHTML = val
    document.querySelector('.popover-drop__item').style.display = 'none'

    if (val === 'Annualized Volatility') {
      const element1 = document.querySelector('.popover-drop__item__wrapper')
      element1.innerHTML = `<li class="popover-drop__item__wrapper__list" id="Drawdown">Drawdown</li>`
      const elems1 = document.querySelectorAll('.drawdown')
        ;[].forEach.call(elems1, function (el) {
          el.style.display = 'none'
        })
      const elems2 = document.querySelectorAll('.annualized-volatility')
        ;[].forEach.call(elems2, function (el) {
          el.style.display = 'block'
        })
    } else {
      const element1 = document.querySelector('.popover-drop__item__wrapper')
      element1.innerHTML = `<li class="popover-drop__item__wrapper__list" id="Annualized Volatility">Annualized Volatility</li>`
      const elems1 = document.querySelectorAll('.annualized-volatility')
        ;[].forEach.call(elems1, function (el) {
          el.style.display = 'none'
        })
      const elems2 = document.querySelectorAll('.drawdown')
        ;[].forEach.call(elems2, function (el) {
          el.style.display = 'block'
        })
    }
    addEventToAnalytics('Risk Popup -- Dropdown','Risk Popup -- Dropdown','RISK_POPUP -- DROPDOWN',{ query: funds.query, logId: funds.logId, optionSelected: val, card: card.ticker },true);
    this.addEvent()
  }

  addEvent = () => {
    const abc = document.querySelector('.popover-drop__item__wrapper__list')
    if (abc !== null) {
      abc.addEventListener('click', this.setItem)
    }
  }

  render() {
    const { card, highlight, checkPopOpen, firstPop, funds } = this.props
    const i = 4
    const blueUrl = `/assets/images/icons/blue-heart-rate-${quintileSize(
      card._volatility_quin,
      QuinItems[i].reverse
    )}.svg`
    const iconUrl = `/assets/images/icons/heart-rate-${quintileSize(
      card._volatility_quin,
      QuinItems[i].reverse
    )}.svg`

    const hikeLabel =
      quintileSize(card._volatility_quin, QuinItems[i].reverse) === 2
        ? 'Average'
        : quintileSize(card._volatility_quin, QuinItems[i].reverse) === 3
          ? 'High'
          : 'Low'
    // const statusColor = quintileClass(card._volatility_quin, QuinItems[i].reverse) === 'danger' ? '#ff4d4d' : (quintileClass(card._volatility_quin, QuinItems[i].reverse) === 'success' ? '#416D60' : '#1b8be0');
    const statusText = (card && card._volatility_v) ? card._volatility_v.toFixed(2) + '%' : '--'

    const e1 = () => (
      <div className="risk-flag-color">
        <img
          src={highlight ? blueUrl : card._flags.risksMin ? blueUrl : iconUrl}
          width="22"
          alt="Risk"
          style={{ marginBottom: '3px' }}
        />
        <p
          style={{ margin: 0, fontSize: 11, lineHeight: 1, fontWeight: 'bold' }}
        >
          {statusText}
        </p>
        <p style={{ margin: 0, fontSize: 10, lineHeight: 1 }}>{hikeLabel}</p>
      </div>
    )

    const e2 = () => (
      <React.Fragment>
        <PopoverBody className="p-0">
          <Row className="no-gutters">
            {/* <Col className="col-12 p-2 pt-3 d-flex justify-content-between align-items-center">
              <h4 className="fs-17 text-ssf-blue-d2 mb-1">{'Risk'}<span className="h6 font-three text-gray-7 ml-2">{card._stats.start + ' - ' + card._stats.end}</span></h4>
            </Col> */}
            <Col className="col-12 p-2 text-gray-9 bg-light br-2">
              <div
                className={classNames("d-flex justify-content-between align-items-center")}
                style={{ lineHeight: 1 }}
              >
                <div className="popover-drop">
                  <div
                    className="popover-drop__toggle"
                    onMouseOver={this.onMouseOver}
                    onMouseLeave={this.onMouseLeave}
                  >
                    <span className="pop-toggle-name">
                      Annualized Volatility
                    </span>{' '}
                    <i className="fas fa-caret-down"></i>
                  </div>
                  <div
                    className="popover-drop__item"
                    onMouseOver={this.onMouseOver}
                    onMouseLeave={this.onMouseLeave}
                    onClick={this.onMouseOver,this.onMouseLeave}
                >
                    <ul className="popover-drop__item__wrapper">
                    {/* <li className="popover-drop__item__wrapper__list" onClick={() => this.setItem('Annualized Volatility')}>Annualized Volatility</li> */}
                    <li
                      className="popover-drop__item__wrapper__list"
                      id="Drawdown"
                    >
                      Drawdown
                      </li>
                  </ul>
                </div>
              </div>
              <span className="font-weight-bold annualized-volatility">
                {card._volatility_v.toFixed(2)}
                <Pct />
              </span>
              <span className="font-weight-bold drawdown">
                {card._drawdown_v.toFixed(2)}
                <Pct />
              </span>
              </div>

            <div className="annualized-volatility">
              <Quintile
                cardCategory={cn(card, 'Category')}
                date={`${card._returnsChart.start} - ${card._returnsChart.end}`}
                item={QuinItems[i]}
                value={card._volatility_quin}
                catValue={
                  <span>
                    {card._categorySeries.volt.toFixed(2)}
                    <Pct />
                  </span>
                }
                bg={quintileClass(card._volatility_quin, QuinItems[i].reverse)}
              />
            </div>
            <div className="drawdown">
              <Quintile
                cardCategory={cn(card, 'Category')}
                date={`${card._returnsChart.start} - ${card._returnsChart.end}`}
                item={QuinItems[i]}
                value={card._drawdown_quin}
                catValue={
                  <span>
                    {card._categorySeries.dMax.toFixed(2)}
                    <Pct />
                  </span>
                }
                bg={quintileClass(card._drawdown_quin, QuinItems[i].reverse)}
              />
            </div>
            </Col>
          </Row>
        </PopoverBody>
        { this.addEvent() }
      </React.Fragment >
    )

    const highlightClassName = highlight
      ? 'search-risk-stats-padding search-risk-stats-highlighting align-items-center d-flex'
      : card._flags.risksMin
        ? 'search-risk-stats-padding search-risk-stats-highlighting align-items-center d-flex'
        : 'search-risk-stats-padding  align-items-center d-flex'
    const WithPopover = withPopover(e1, e2, {
      id: 'RiskPopover-' + cn(card, 'Id'),
      placement: 'right',
      className: highlightClassName,
      checkPopOpen,
      firstPop,
      funds,
      card,
      popUp: 'Risk Popup'
    })
    return <WithPopover />
  }
}

class ResponsiveRiskPopover extends Component {

  onMouseOver = () => {
    const element = document.querySelector('.popover-drop__item')
    element.style.display = 'block'
  }

  onMouseOverItem = () => {
    const element = document.querySelector('.popover-drop__item')
    element.style.display = 'block'
  }

  onMouseLeave = () => {
    const element = document.querySelector('.popover-drop__item')
    element.style.display = 'none'
  }

  setItem = e => {
    const val = e.target.id

    const element = document.querySelector('.pop-toggle-name')
    element.innerHTML = val
    document.querySelector('.popover-drop__item').style.display = 'none'

    if (val === 'Annualized Volatility') {
      const element1 = document.querySelector('.popover-drop__item__wrapper')
      element1.innerHTML = `<li class="popover-drop__item__wrapper__list" id="Drawdown">Drawdown</li>`
      const elems1 = document.querySelectorAll('.drawdown')
        ;[].forEach.call(elems1, function (el) {
          el.style.display = 'none'
        })
      const elems2 = document.querySelectorAll('.annualized-volatility')
        ;[].forEach.call(elems2, function (el) {
          el.style.display = 'block'
        })
    } else {
      const element1 = document.querySelector('.popover-drop__item__wrapper')
      element1.innerHTML = `<li class="popover-drop__item__wrapper__list" id="Annualized Volatility">Annualized Volatility</li>`
      const elems1 = document.querySelectorAll('.annualized-volatility')
        ;[].forEach.call(elems1, function (el) {
          el.style.display = 'none'
        })
      const elems2 = document.querySelectorAll('.drawdown')
        ;[].forEach.call(elems2, function (el) {
          el.style.display = 'block'
        })
    }
    this.addEvent()
  }

  addEvent = () => {
    const abc = document.querySelector('.popover-drop__item__wrapper__list')
    if (abc !== null) {
      abc.addEventListener('click', this.setItem)
    }
  }

  render() {
    const { card, highlight, checkPopOpen, firstPop } = this.props
    const i = 4
    const blueUrl = `/assets/images/icons/blue-heart-rate-${quintileSize(
      card._volatility_quin,
      QuinItems[i].reverse
    )}.svg`
    const iconUrl = `/assets/images/icons/heart-rate-${quintileSize(
      card._volatility_quin,
      QuinItems[i].reverse
    )}.svg`

    const hikeLabel =
      quintileSize(card._volatility_quin, QuinItems[i].reverse) === 2
        ? 'Average'
        : quintileSize(card._volatility_quin, QuinItems[i].reverse) === 3
          ? 'High'
          : 'Low'
    // const statusColor = quintileClass(card._volatility_quin, QuinItems[i].reverse) === 'danger' ? '#ff4d4d' : (quintileClass(card._volatility_quin, QuinItems[i].reverse) === 'success' ? '#416D60' : '#1b8be0');
    const statusText = card._volatility_v.toFixed(2) + '%'

    const e1 = () => (
      <span className=" icon-Slader-Risk h2 hover-orange"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
    );

    // const e2 = () => (
    //   <React.Fragment>
    //     <PopoverBody className="p-0">
    //       <Row className="no-gutters">
    //         {/* <Col className="col-12 p-2 pt-3 d-flex justify-content-between align-items-center">
    //           <h4 className="fs-17 text-ssf-blue-d2 mb-1">{'Risk'}<span className="h6 font-three text-gray-7 ml-2">{card._stats.start + ' - ' + card._stats.end}</span></h4>
    //         </Col> */}
    //         <Col className="col-12 p-2 text-gray-9 bg-light br-2">
    //           <div className={classNames("h5 d-flex justify-content-between pt-1")}>

    //             <div className="popover-drop">
    //               <div className="popover-drop__toggle" onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave} >
    //                 <span className="pop-toggle-name">Annualized Volatility</span> <i className="fas fa-caret-down"></i>
    //               </div>
    //               <div className="popover-drop__item" onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}>
    //                 <ul className="popover-drop__item__wrapper">
    //                   {/* <li className="popover-drop__item__wrapper__list" onClick={() => this.setItem('Annualized Volatility')}>Annualized Volatility</li> */}
    //                   <li className="popover-drop__item__wrapper__list" id="Drawdown">Drawdown</li>
    //                 </ul>
    //               </div>
    //             </div>
    //             <span className="font-weight-bold annualized-volatility">{card._volatility_v.toFixed(2)}<Pct /></span>
    //             <span className="font-weight-bold drawdown">{card._stats.dMax.toFixed(2)}<Pct /></span>
    //           </div>

    //           <div className="annualized-volatility">
    //             <Quintile date={`${card._stats.start} - ${card._stats.end}`} item={QuinItems[i]} value={card._volatility_quin}
    //               catValue={<span>{card._categorySeries.volt.toFixed(2)}<Pct /></span>}
    //               bg={quintileClass(card._volatility_quin, QuinItems[i].reverse)} />
    //           </div>
    //           <div className="drawdown">
    //             <Quintile date={`${card._stats.start} - ${card._stats.end}`} item={QuinItems[i]} value={card._stats.dMaxQ}
    //               catValue={<span>{card._categorySeries.dMax.toFixed(2)}<Pct /></span>}
    //               bg={quintileClass(card._stats.dMaxQ, QuinItems[i].reverse)} />
    //           </div>
    //         </Col>
    //       </Row>
    //     </PopoverBody>
    //     {this.addEvent()}
    //   </React.Fragment>
    // );

    return (
      <React.Fragment>
        <h5 className="mb-1 text-ssf-blue-d2" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          Risk <span className="label-value">{hikeLabel}</span>
        </h5>
        <h5 className="mb-0 text-gray-6">
          Annualized Volatility (<span style={{ fontWeight: 'bold' }}>{card._volatility_v.toFixed(2)}%</span>) vs Category (<span style={{ fontWeight: 'bold' }}>{card._categorySeries.volt.toFixed(2)}%</span>)
        </h5>
      </React.Fragment>
    );
    // const highlightClassName = highlight ? 'search-risk-stats-padding search-risk-stats-highlighting' : (card._flags.risksMin ? 'search-risk-stats-padding search-risk-stats-highlighting' : 'search-risk-stats-padding');
    // const WithPopover = withPopover(e1, e2, { id: "ResponsiveRiskPopover-" + cn(card, 'Id'), placement: 'bottom', className: '',
    //   checkPopOpen, firstPop
    // });
    // return (
    //   <WithPopover />
    // );
  }
}

const Pct = props => <small className="text-gray-5">%</small>

// const boldString = (str, find) => {
//   const re = new RegExp(find, 'g')
//   return str.replace(re, '<b>' + find + '</b>')
// }

const boldString = (str, find) => {
  const re = new RegExp(find, 'ig');
  const st = str.toLowerCase().indexOf(find.toLowerCase());
  const startIndex = st;
  const endIndex = startIndex + find.length
  const boldText = str.slice(startIndex, endIndex)
  return str.replace(boldText, '<b>' + boldText + '</b>');
}

const getSTKData = dataArr => {
  if (dataArr) {
    const valueSTKObject = {
      text: {
        position: null,
        value: null
      },
      company: {
        position: null,
        value: null
      },
      entity: {
        position: null,
        value: null
      },
      startend: {
        startposition: null,
        endposition: null,
        value: null
      }
    }
    if (dataArr.includes('text') && dataArr.includes('company')) {
      const { text, company, entity } = valueSTKObject
      valueSTKObject.text = {
        ...text,
        position: dataArr.indexOf('text')
      }
      valueSTKObject.company = {
        ...company,
        position: dataArr.indexOf('company')
      }
      // check if text & company position doesnt clash
      // or text next position's element could not be company
      if (
        valueSTKObject.text.position !== null &&
        valueSTKObject.text.position > 0 &&
        valueSTKObject.text.position + 1 !== valueSTKObject.company.position
      ) {
        if (dataArr.includes('entity')) {
          valueSTKObject.entity = {
            ...entity,
            position: dataArr.indexOf('entity')
          }
          if (
            valueSTKObject.text.position !== null &&
            valueSTKObject.text.position > 0 &&
            valueSTKObject.text.position + 1 === valueSTKObject.entity.position
          )
            return

          // find entity value
          const entityValuePos = valueSTKObject.entity.position + 1
          valueSTKObject.entity.value = dataArr[entityValuePos]
        }
        // find text value
        const textValuePos = valueSTKObject.text.position + 1
        valueSTKObject.text.value = dataArr[textValuePos]

        // find the less description text
        // valueSTKObject.startend.startposition = parseInt(dataArr[dataArr.indexOf('start') + 1]);
        // valueSTKObject.startend.endposition = parseInt(dataArr[dataArr.indexOf('end') + 1]);
        valueSTKObject.startend.startposition = parseInt(dataArr[dataArr.indexOf('start') + 1]) == 0 ? parseInt(dataArr[dataArr.indexOf('start') + 1]) : parseInt(dataArr[dataArr.indexOf('start') + 1]) - 1;
        valueSTKObject.startend.endposition = parseInt(dataArr[dataArr.indexOf('end') + 1]) == 0 ? parseInt(dataArr[dataArr.indexOf('end') + 1]) : parseInt(dataArr[dataArr.indexOf('end') + 1]);

        if (valueSTKObject.startend.endposition > valueSTKObject.startend.startposition) {
          valueSTKObject.startend.value = dataArr[textValuePos].slice(valueSTKObject.startend.startposition, valueSTKObject.startend.endposition).trim();
        }
        else
          valueSTKObject.startend.value = '';
        // console.log('------Sid',  dataArr[dataArr.indexOf('start')], valueSTKObject.startend.startposition,  dataArr[dataArr.indexOf('end')], valueSTKObject.startend.endposition, dataArr[textValuePos], valueSTKObject.startend.value )
        // highlight text using entity value
        if (valueSTKObject.entity.value !== '') {
          const highlightedString = boldString(
            valueSTKObject.text.value,
            valueSTKObject.entity.value
          )
          valueSTKObject.startend.value = valueSTKObject.startend.value.toLowerCase().includes(
            valueSTKObject.entity.value.toLowerCase()
          )
            ? boldString(
              valueSTKObject.startend.value,
              valueSTKObject.entity.value
            )
            : valueSTKObject.startend.value
          valueSTKObject.text.value = highlightedString
        }

        // find company value
        const companyValuePos = valueSTKObject.company.position + 1
        valueSTKObject.company.value = dataArr[companyValuePos]
        return valueSTKObject
      }
    }
  } else {
    // console.log('missing STK data');
  }
}

/*
    // Return Risk at bottom of chart
    // css: .zi-5 { bottom: 0; left: 0; }
    const e1 = () => (
      <div className="h6 text-gray-7 bg-black-o05 px-2 py-1 mb-0 d-flex justify-content-around">
        { [3, 4].map((i) => (
          <div key={i}>
            <span style={{minWidth: '65px'}}>
              <i className={classNames("far fa-fw mb-0", {"fa-chart-line": i === 3, "fa-heart-rate": i === 4 })}></i>
              <span className="mx-1">{quinItems[i].name}</span>
            </span>
            <span>{card[quinItems[i].col].toFixed(1)}<small className="text-gray-6"> %</small></span>
          </div>
        ))}
      </div>
    );
    const WithPopover = withPopover(e1, e2, { id: "ReturnPopover-" + cn(card, 'Id'), placement: 'left', className: 'zi-5 w-100' })
    const WithPopover = withPopover(e1, e2, { id: "ReturnChartPopover-" + cn(card, 'Id'), placement: 'left', className: 'pb-3'})
*/

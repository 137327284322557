export const RegionColors = {
  // 'Allocations': 'rgb(27,139,224)',
  // 'Allocations': 'rgb(150,240,250)',
//   'Allocations': '#f9f9f9',

// 'Emerging Markets': 'rgb(85,169,232)',

// 'Developed Markets': 'rgb(101,184,247)',

// 'North America': 'rgb(120,199,240)',

// 'Cash': 'rgb(133,214,277)',

// 'Australia': 'rgb(27,139,224)',

// 'Austria': 'rgb(49,153,239)',

// 'Belgium': 'rgb(72,167,254)',

// 'Cyprus': 'rgb(95,181,269)',

// 'Denmark': 'rgb(118,195,285)',

// 'Finland': 'rgb(141,210,300)',

// 'France': 'rgb(164,224,315)',

// 'Germany': 'rgb(187,238,331)',

// 'Hong Kong': 'rgb(27,139,224)',

// 'Iceland': 'rgb(30,152,236)',

// 'Ireland': 'rgb(34,166,249)',

// 'Israel': 'rgb(56,178,262)',

// 'Italy': 'rgb(78,190,275)',

// 'Japan': 'rgb(101,202,289)',

// 'Luxembourg': 'rgb(123,214,302)',

// 'Netherlands': 'rgb(146,226,316)',

// 'New Zealand': 'rgb(168,238,329)',

// 'Norway': 'rgb(27,139,224)',

// 'Portugal': 'rgb(32,150,235)',

// 'Singapore': 'rgb(53,164,250)',

// 'South Korea': 'rgb(74,179,265)',

// 'Spain': 'rgb(95,193,281)',

// 'Sweden': 'rgb(116,208,296)',

// 'Switzerland': 'rgb(137,223,312)',

// 'U.K.': 'rgb(27,139,224)',

// 'Jersey': 'rgb(31,150,231)',

// 'Isle of Man': 'rgb(36,161,238)',

// 'Monaco': 'rgb(57,175,256)',

// 'Guernsey': 'rgb(78,190,275)',

// 'British Virgin Islands': 'rgb(99,204,293)',

// 'Cayman Islands': 'rgb(120,219,312)',

// 'Belize': 'rgb(141,234,331)',

// 'Bahrain': 'rgb(27,139,224)',

// 'Malta': 'rgb(34,155,234)',

// 'Argentina': 'rgb(1,121,243)',

// 'Bangladesh': 'rgb(23,135,258)',

// 'Brazil': 'rgb(46,149,273)',

// 'Bulgaria': 'rgb(69,163,288)',

// 'Chile': 'rgb(92,177,304)',

// 'China': 'rgb(115,192,319)',

// 'Colombia': 'rgb(138,206,334)',

// 'Czech Republic': 'rgb(161,220,350)',

// 'Ecuador': 'rgb(27,139,224)',

// 'Egypt': 'rgb(30,152,236)',

// 'Greece': 'rgb(34,166,249)',

// 'Hungary': 'rgb(56,178,262)',

// 'India': 'rgb(78,190,275)',

// 'Indonesia': 'rgb(101,202,289)',

// 'Kenya': 'rgb(123,214,302)',

// 'Kuwait': 'rgb(146,226,316)',

// 'Lebanon': 'rgb(168,238,329)',

// 'Malaysia': 'rgb(27,139,224)',

// 'Mexico': 'rgb(32,150,235)',

// 'Morocco': 'rgb(53,164,250)',

// 'Nigeria': 'rgb(74,179,265)',

// 'Oman': 'rgb(95,193,281)',

// 'Pakistan': 'rgb(116,208,296)',

// 'Peru': 'rgb(137,223,312)',

// 'Philippines': 'rgb(27,139,224)',

// 'Poland': 'rgb(31,150,231)',

// 'Qatar': 'rgb(36,161,238)',

// 'Romania': 'rgb(57,175,256)',

// 'Russia': 'rgb(78,190,275)',

// 'Saudi Arabia': 'rgb(99,204,293)',

// 'Senegal': 'rgb(120,219,312)',

// 'South Africa': 'rgb(141,234,331)',

// 'Taiwan': 'rgb(27,139,224)',

// 'Thailand': 'rgb(34,155,234)',

// 'Tunisia': 'rgb(41,172,245)',

// 'Turkey': 'rgb(59,181,260)',

// 'U.A.E.': 'rgb(78,190,275)',

// 'Ukraine': 'rgb(97,199,290)',

// 'Uruguay': 'rgb(116,208,305)',

// 'Venezuela': 'rgb(135,217,320)',

// 'Vietnam': 'rgb(27,139,224)',

// 'Macau': 'rgb(49,153,239)',

// 'Bermuda': 'rgb(72,167,254)',

// 'Panama': 'rgb(95,181,269)',

// 'Kazakhstan': 'rgb(118,195,285)',

// 'Zambia': 'rgb(141,210,300)',

// 'Paraguay': 'rgb(164,224,315)',

// 'Mozambique': 'rgb(187,238,331)',

// 'Jamaica': 'rgb(27,139,224)',

// 'Jordan': 'rgb(30,152,236)',

// 'Serbia': 'rgb(34,166,249)',

// 'Bolivia': 'rgb(56,178,262)',

// 'Slovakia': 'rgb(78,190,275)',

// 'Ivory Coast': 'rgb(101,202,289)',

// 'Mongolia': 'rgb(123,214,302)',

// 'Ghana': 'rgb(146,226,316)',

// 'Trinidad & Tobago': 'rgb(168,238,329)',

// 'Croatia': 'rgb(27,139,224)',

// 'Bahamas': 'rgb(31,150,231)',

// 'Lithuania': 'rgb(36,161,238)',

// 'Costa Rica': 'rgb(57,175,256)',

// 'Gabon': 'rgb(78,190,275)',

// 'Sri Lanka': 'rgb(99,204,293)',

// 'Iraq': 'rgb(120,219,312)',

// 'Brunei': 'rgb(141,234,331)',

// 'Mauritius': 'rgb(27,139,224)',

// 'Slovenia': 'rgb(34,155,234)',

// 'Latvia': 'rgb(41,172,245)',

// 'Liechtenstein': 'rgb(59,181,260)',

// 'Gibraltar': 'rgb(78,190,275)',

// 'Fiji': 'rgb(97,199,290)',

// 'Barbados': 'rgb(116,208,305)',

// 'Tanzania': 'rgb(135,217,320)',

// 'Canada': 'rgb(92,177,304)',

// 'U.S.': 'rgb(46,149,273)',

// 'Angola': 'rgb(27,139,224)',

// 'Armenia': 'rgb(31,150,231)',

// 'Azerbaijan': 'rgb(36,161,238)',

// 'Belarus': 'rgb(57,175,256)',

// 'Cambodia': 'rgb(78,190,275)',

// 'Cameroon': 'rgb(99,204,293)',

// 'Christmas Island': 'rgb(120,219,312)',

// 'Curacao': 'rgb(141,234,331)',

// 'Dominican Republic': 'rgb(27,139,224)',

// 'El Salvador': 'rgb(34,155,234)',

// 'Ethiopia': 'rgb(41,172,245)',

// 'Faroe Islands': 'rgb(59,181,260)',

// 'Georgia': 'rgb(78,190,275)',

// 'Guatemala': 'rgb(97,199,290)',

// 'Honduras': 'rgb(116,208,305)',

// 'Korea': 'rgb(135,217,320)',

// 'Maldives': 'rgb(27,139,224)',

// 'Marshall Islands': 'rgb(30,152,236)',

// 'Moldova': 'rgb(34,166,249)',

// 'Namibia': 'rgb(56,178,262)',

// 'Papua New Guinea': 'rgb(78,190,275)',

// 'Puerto Rico': 'rgb(101,202,289)',

// 'Rwanda': 'rgb(123,214,302)',

// 'Suriname': 'rgb(146,226,316)',

// 'Tajikistan': 'rgb(168,238,329)',

// 'Togo': 'rgb(27,139,224)',

// 'Turks And Caicos Islands': 'rgb(49,153,239)',

// 'Unidentified': 'rgb(87,164,253)',

// 'Virgin Islands (U.S.)': 'rgb(108,178,269)',
// 'Australia':'#064697',

// 'Austria':'#075189',

// 'Belgium':'#085c9b',

// 'Cyprus':'#0967ae',

// 'Denmark':'#0b7dd3',

// 'Finland':'#0c88e6',

// 'France':'#1293f3',

// 'Germany':'#259bf4',

// 'Hong Kong':'#38a4f5',

// 'Iceland':'#4aacf6',

// 'Ireland':'#5db5f7',

// 'Israel':'#70bdf8',

// 'Italy':'#82c6f9',

// 'Japan':'#95cefa',

// 'Luxembourg':'#a8d7fb',

// 'Netherlands':'#bae0fc',

// 'New Zealand':'#cde8fc',

// 'Norway':'#e0f1fd',

// 'Portugal':'#174d76',

// 'Singapore':'#1d6093',

// 'South Korea':'#216ba3',

// 'Spain':'#2577b5',

// 'Sweden':'#2882c6',

// 'Switzerland':'#2f94e0',

// 'U.K.':'#329ded',

// 'Jersey':'#42acfb',

// 'Isle of Man':'#6bbffd',

// 'Monaco':'#8bcdfd',

// 'Guernsey':'#a9d9fb',

// 'British Virgin Islands':'#98cbef',

// 'Cayman Islands':'#8abfe5',

// 'Belize':'#9ac8e9',

// 'Bahrain':'#95bbd6',

// 'Malta':'#83acca',

// 'Mexico':'#064697',

// 'Morocco':'#075189',

// 'Nigeria':'#085c9b',

// 'Oman':'#0967ae',

// 'Pakistan':'#0b7dd3',

// 'Peru':'#0c88e6',

// 'Philippines':'#1293f3',

// 'Poland':'#259bf4',

// 'Qatar':'#38a4f5',

// 'Romania':'#4aacf6',

// 'Russia':'#5db5f7',

// 'Saudi Arabia':'#70bdf8',

// 'Senegal':'#82c6f9',

// 'South Africa':'#95cefa',

// 'Taiwan':'#a8d7fb',

// 'Thailand':'#bae0fc',

// 'Tunisia':'#cde8fc',

// 'Turkey':'#e0f1fd',

// 'U.A.E.':'#d8edfc',

// 'Ukraine':'#174d76',

// 'Uruguay':'#1d6093',

// 'Venezuela':'#216ba3',

// 'Vietnam':'#2577b5',

// 'Macau':'#2882c6',

// 'Bermuda':'#2f94e0',

// 'Panama':'#329ded',

// 'Kazakhstan':'#42acfb',

// 'Zambia':'#6bbffd',

// 'Paraguay':'#8bcdfd',

// 'Mozambique':'#a9d9fb',

// 'Jamaica':'#98cbef',

// 'Jordan':'#8abfe5',

// 'Serbia':'#9ac8e9',

// 'Bolivia':'#95bbd6',

// 'Slovakia':'#83acca',

// 'Ivory Coast':'#7ea5c1',

// 'Mongolia':'6b90aa',

// 'Ghana':'5b7d95',

// 'Argentina':'#04497c',

// 'Bangladesh':'#075189',

// 'Brazil':'#044c82',

// 'Bulgaria':'#02538f',

// 'Chile':'#025a9b',

// 'China':'#0260a5',

// 'Colombia':'#016ab6',

// 'Czech Republic':'#0279cf',

// 'Ecuador':'#0085e5',

// 'Egypt':'#0190f8',

// 'Greece':'#104b77',

// 'Hungary':'#104b77',

// 'India':'#145b90',

// 'Indonesia':'#176cab',

// 'Kenya':'#1876bc',

// 'Kuwait':'#1876bc',

// 'Lebanon':'#1a8add',

// 'Malaysia':'#1f99f3',

// 'Christmas Island':'#225175',

// 'Maldives':'#225175',

// 'Suriname':'#225175',

// 'Angola':'#2a638e',

// 'Unidentified':'#2882c6',

// 'Tajikistan':'#2e92de',

// 'Armenia':'#339beb',

// 'Trinidad & Tobago':'#3f6581',

// 'Croatia':'#46708f',

// 'Bahamas':'#5282a6',

// 'Lithuania':'#5a8db4',

// 'Costa Rica':'#629ac4',

// 'Gabon':'#6ba6d2',

// 'Sri Lanka':'#74b2e1',

// 'Iraq':'#7bbced',

// 'Brunei':'#84caff',

// 'Mauritius':'#92d0fe',

// 'Slovenia':'#8cc6f1',

// 'Latvia':'#81b8e1',

// 'Liechtenstein':'#74a6cc',

// 'Gibraltar':'#6694b7',

// 'Fiji':'#5881a1',

// 'Barbados':'#4d708c',

// 'Tanzania':'#43637c',

// 'Curacao':'#334f64',

// 'Moldova':'2d4557',

// 'Marshall Islands':'#364957',

// 'Belarus':'#415768',

// 'Puerto Rico':'#4d667a',

// 'Guatemala':'#58758c',

// 'Turks And Caicos Islands':'#6586a0',

// 'Georgia':'#7193ae',

// 'Ethiopia':'#7ba0bd',

// 'El Salvador':'#84accb',

// 'Papua New Guinea':'#91bbdc',

// 'Rwanda':'#98c5e8',

// 'Cambodia':'#a0cef2',

// 'Cameroon':'#a8d6fa',

// 'Faroe Islands':'#a8d6fa',

// 'Azerbaijan':'#9ecaec',

// 'Honduras':'#8db5d4',

// 'Korea':'#83a8c5',

// 'Namibia':'#789ab5',

// 'Dominican Republic':'#6d8ca5',

// 'Togo':'#607c93',

// 'North America':'#4885b2',

// 'Emerging Markets':'#528ebc',

// 'Cash':'#90c9f5',

// 'Allocations':'#efefef',

// 'Developed Markets':'#064676',

// 'Canada':'#add9fa',

// 'U.S.':'#a5cfee',

// 'Virgin Islands (U.S.)':'#95b9d3',
'Allocations':'#efefef',
'Emerging Markets':'#3a80b7',
'Developed Markets':'#5194c8',
'North America':'#64a1d1',
'Cash':'#83bce8',
'Others':'#83bce8',
'Frontier Markets':'#5998C9',

'Others ':'#2173ad',
'Australia':'#2173ad',
'Austria':'#297ab3',
'Belgium':'#3280b7',
'Cyprus':'#4185b5',
'Denmark':'#4987b7',
'Finland':'#528ebc',
'France':'#64a1d1',
'Germany':'#6da5d1',
'Hong Kong':'#77b0db',
'Iceland':'#2173ad',
'Ireland':'#297ab3',
'Israel':'#3280b7',
'Italy':'#4185b5',
'Japan':'#4987b7',
'Luxembourg':'#528ebc',
'Netherlands':'#64a1d1',
'New Zealand':'#6da5d1',
'Norway':'#77b0db',
'Portugal':'#2173ad',
'Singapore':'#297ab3',
'South Korea':'#3280b7',
'Spain':'#4185b5',
'Sweden':'#4987b7',
'Switzerland':'#528ebc',
'United Kingdom':'#64a1d1',
'U.K.':'#64a1d1',
'Jersey':'#6da5d1',
'Isle of Man':'#77b0db',
'Monaco':'#2173ad',
'Guernsey':'#297ab3',
'British Virgin Islands':'#3280b7',
'Cayman Islands':'#4185b5',
'Belize':'#4987b7',
'Bahrain':'#528ebc',
'Malta':'#64a1d1',
'Argentina':'#6da5d1',
'Bangladesh':'#77b0db',
'Brazil':'#2173ad',
'Bulgaria':'#297ab3',
'Chile':'#3280b7',
'China':'#4185b5',
'Colombia':'#4987b7',
'Czech Republic':'#528ebc',
'Ecuador':'#64a1d1',
'Egypt':'#6da5d1',
'Greece':'#77b0db',
'Hungary':'#2173ad',
'India':'#297ab3',
'Indonesia':'#3280b7',
'Kenya':'#4185b5',
'Kuwait':'#4987b7',
'Lebanon':'#528ebc',
'Malaysia':'#64a1d1',
'Mexico':'#6da5d1',
'Morocco':'#77b0db',
'Nigeria':'#2173ad',
'Oman':'#297ab3',
'Pakistan':'#3280b7',
'Peru':'#4185b5',
'Philippines':'#4987b7',
'Poland':'#528ebc',
'Qatar':'#64a1d1',
'Romania':'#6da5d1',
'Russia':'#77b0db',
'Saudi Arabia':'#2173ad',
'Saudia Arabia':'#2173ad',
'Senegal':'#297ab3',
'South Africa':'#3280b7',
'Taiwan':'#4185b5',
'Thailand':'#4987b7',
'Tunisia':'#528ebc',
'Turkey':'#64a1d1',
'U.A.E.':'#6da5d1',
'United Arab Emirates':'#6da5d1',
'Ukraine':'#77b0db',
'Uruguay':'#2173ad',
'Venezuela':'#297ab3',
'Vietnam':'#3280b7',
'Macau':'#4185b5',
'Bermuda':'#4987b7',
'Panama':'#528ebc',
'Kazakhstan':'#64a1d1',
'Zambia':'#6da5d1',
'Paraguay':'#77b0db',
'Mozambique':'#2173ad',
'Jamaica':'#297ab3',
'Jordan':'#3280b7',
'Serbia':'#4185b5',
'Bolivia':'#4987b7',
'Slovakia':'#528ebc',
'Ivory Coast':'#64a1d1',
'Mongolia':'#6da5d1',
'Ghana':'#77b0db',
'Trinidad & Tobago':'#2173ad',
'Trinidad Tobago':'#2173ad',
'Croatia':'#297ab3',
'Bahamas':'#3280b7',
'Lithuania':'#4185b5',
'Costa Rica':'#4987b7',
'Gabon':'#528ebc',
'Sri Lanka':'#64a1d1',
'Iraq':'#6da5d1',
'Brunei':'#77b0db',
'Mauritius':'#2173ad',
'Slovenia':'#297ab3',
'Latvia':'#3280b7',
'Liechtenstein':'#4185b5',
'Gibraltar':'#4987b7',
'Fiji':'#528ebc',
'Barbados':'#64a1d1',
'Tanzania':'#6da5d1',
'Canada':'#77b0db',
'United States':'#2173ad',
'U.S.':'#2173ad',
'Angola':'#297ab3',
'Armenia':'#3280b7',
'Azerbaijan':'#4185b5',
'Belarus':'#4987b7',
'Cambodia':'#528ebc',
'Cameroon':'#64a1d1',
'Christmas Island':'#6da5d1',
'Curacao':'#77b0db',
'Dominican Republic':'#2173ad',
'El Salvador':'#297ab3',
'Ethiopia':'#3280b7',
'Faroe Islands':'#4185b5',
'Georgia':'#4987b7',
'Guatemala':'#528ebc',
'Honduras':'#64a1d1',
'Korea':'#6da5d1',
'Maldives':'#77b0db',
'Marshall Islands':'#2173ad',
'Moldova':'#297ab3',
'Namibia':'#3280b7',
'Papua New Guinea':'#4185b5',
'Puerto Rico':'#4987b7',
'Rwanda':'#528ebc',
'Suriname':'#64a1d1',
'Tajikistan':'#6da5d1',
'Togo':'#77b0db',
'Turks And Caicos Islands':'#2173ad',
'Unidentified':'#297ab3',
'Virgin Islands (U.S.)':'#3280b7',
'Virgin Islands':'#3280b7',
'American Samoa':'#2173ad',
'Benin':'#297ab3',
'Burundi':'#3280b7',
'Guam':'#4185b5',
'Guatermala':'#4987b7',
'Uzberkistan':'#528ebc',
'Zimbabwe':'#64a1d1',
};

export const AssetAllocationBurstChart = {
  'Allocations':'#efefef',
  'Equities':'#3a80b7',
  'Bonds':'#5194c8',
  'Cash':'#64a1d1',
  'Commodities':'#83bce8',
  'Currencies':'#83bce8',
  'Derivatives': '#5998C9',
  'Other': 'rgb(85, 85, 85)',
};

export const SectorColors = [
  'rgb(6,70,118)',
  'rgb(30,95,145)',
  'rgb(44,112,165)',
  'rgb(58,128,183)',
  'rgb(72,133,178)',
  'rgb(77,143,192)',
  'rgb(76,143,188)',
  'rgb(82,142,187)',
  'rgb(109,165,209)',
  'rgb(119,176,219)',
  'rgb(131,188,232)',
  'rgb(144,201,245)',
  'rgb(154,210,253)'
]

export const GaugeColors = [
  // '#8CB8B2', '#75A9A3', '#5E9B93',
  // '#65b8f7', '#55a9e8', '#1b8be0',
    '#1d5b8a', '#4489cf', '#99d1fc',
];

export const GYRGaugeColors = [
  '#9BBF63','#F2C94B','#E07363',
]

export const ProfitLossColors = ['#3f7367', '#a5685f'];

import React from 'react'
import { connect } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap'
import { PrimaryButton } from 'shared-components'
import { createSubscriptionRequest } from 'actions/profile'
import { Checkbox } from 'antd';
import config from 'env';
import {addEventToAnalytics} from 'Utils';
import TermsConditions from 'layouts/WebDashboard/SearchResults/Elements/Registrations/AgreeTermsNConditions';

class PremiumPlanDetails extends React.Component {
  state = {
    isTermsOpen: false,
    enableGetStarted: false
  }

  constructor(props) {
    super(props)
    // this.props.createSubscriptionRequest();
    this.generateSessionId = this.generateSessionId.bind(this);
    this.subscribeNow = this.subscribeNow.bind(this);
  }

  generateSessionId() {
    this.props.createSubscriptionRequest(this.subscribeNow)
  }

  subscribeNow(sessionId) {
    if(this.props.featureName){
      addEventToAnalytics('Premium Upgrade','Premium Upgrade','PREMIUM_UPGRADE',{feature_name: this.props.featureName},false);
    }
    // const sessionId = this.props.stripeSessionID;
    if(sessionId){
      this.stripe = window.Stripe(config.stripePublishableKey);
      this.stripe.redirectToCheckout({
        sessionId
      }).then((result) => {
        // console.log("results status",result)
      });
    }
   }

  onTermsAndConditionClick() {
    this.setState({
      isTermsOpen: !this.state.isTermsOpen
    })
  }

  onTermCheckBoxClixk() {
    this.setState({
      enableGetStarted: !this.state.enableGetStarted
    })
  }

  render() {
    const { isTermsOpen, enableGetStarted } = this.state

    return (
      <React.Fragment>
        <div className={"premium-msg-plan-details "+ (!this.props.showPremium ? 'd-none' : '')}>
          <ul>
            <li>Selector: Personalize our patented fund selector to find the best fund for you among relevant funds discovered. </li>
            <li>Builder: Start with selected funds or initial portfolio to build a portfolio for proposals or trades.</li>
            <li>Enhancer: Read in client, prospect or sponsor provided portfolios and evaluate enhancements with simple language based queries.</li>
            <li>Proposals: for clients to help convert and explain; and for compliance from fund selector, builder and enhancer. </li>
            <li>Save:  Results and download to spreadsheets.</li>
          </ul>
          <div className="d-flex justify-content-center terms-condition-select">
            <Checkbox class="custom-checkbox" onClick={()=> this.onTermCheckBoxClixk()}>&nbsp;I agree to the&nbsp;<span className="terms-text" onClick={() => this.onTermsAndConditionClick()}>terms and conditions</span></Checkbox>
          </div>
        </div>
        <ButtonGroup className="cta-wrapper justify-content-center button-blue">
          <PrimaryButton disabled={!this.props.stripeSessionID} className="btn secondary-btn fs-20" type="button" onClick={this.generateSessionId} disabled={!enableGetStarted} style={{ maxWidth: 218, margin: 0 }}>
            GET STARTED
          </PrimaryButton>
        </ButtonGroup>
        {isTermsOpen && <TermsConditions onTermsClick={() => this.onTermsAndConditionClick()}/>}
        {/*<p className="premium-msg-raf">or <a href="#" onClick={this.props.referNow}>Refer a friend</a> <i class="fal fa-info-circle"></i> </p>   */}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ profile }) => ({
  stripeSessionID: profile.stripeSessionID
})

const mapDispatchToProps = {
  createSubscriptionRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PremiumPlanDetails);

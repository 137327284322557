import React, { Component, Fragment } from 'react';

import NotApplicable from '../../NotApplicable';
import CarouselChart from './CarouselChart'

class RenderCarousel extends Component {
  render() {
    const { data, queryVar } = this.props;
    const { view_name, Secondary_view_name } = queryVar;
    let topTitle = view_name + (Secondary_view_name ? ' ' + Secondary_view_name : '');

    if (!data) return <NotApplicable />;
    const themes = [data[1], data[0], data[2]];

    return (
      <div className="siw-carouse-holder print-carouse-holder print-wrapper">
        <span
          className="siw-pie-title"
          title={topTitle}
          title="Themes"
        >
          {topTitle}
        </span>
        <CarouselChart data={themes} />
      </div>
    );
  }
};

export default RenderCarousel;
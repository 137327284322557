import React, { Component, useState } from 'react'
import { connect } from 'react-redux';
import _ from 'lodash';
import { Row, Col, Card, Tabs, Button, Input, AutoComplete, Dropdown, Menu } from 'antd'
import { CardBody, Tooltip } from 'reactstrap';
import { searchUrl, arrayToHash, CapitalizeEachWord, addEventToAnalytics, deflatLastNode } from 'Utils'
import { CN  } from 'DataSet';
import style from './CardRow.module.scss'
import { DownOutlined, SwapOutlined } from '@ant-design/icons'
import {
  getMPDetailsCustomReplaceRequest,
  setModelEnhancementData,
  removeCustomMPSelection,
  loadCPMListRequest
} from 'actions/enhancer'
import { getPortfolioStats, getInvestmentWatchlistStats, getInvestmentPositionStats } from 'selectors/investment';

// import AutoCompleteJJ from '../AutComplete';
import ItemList from './ItemList';
import ItemCard from './ItemCard';
import { Loader } from '../../../Elements/Loader'
import UploadSection from '../../OnBoardScreen/UploadSection'
// import { Loader } from '../shared/Loader';
import { Select } from 'antd';

const { Option:OptionSelect } = Select;
const { TabPane } = Tabs;
const { Option } = AutoComplete;
const { SubMenu } = Menu;
const { Search } = Input;
class CardRow extends Component {

  constructor(props){
    super(props)
    this.state = {
      resetEnhanceFundCheckStatus: false,
      resetCustomAddedFund: false,
      activeRegion: '',
      keyIndex:1,
      // portfolioFunds: props.portfolioFunds,
      modelEnhancement:{
        current: {},
        enhanced: {},
        otherEnhancements: [],
      },
      IMG_OBJECT: {
        'Sponsor Models': '/assets/images/SponsorProvidedModel_black.svg',
        'My Models': '/assets/images/MyPortfolios-black.svg',
      },
      _viewIndex: "1",
    };
  }
componentWillMount() {
  if (this.props.marketPlaceList.length === 0) {
    this.props.loadCPMListRequest()
  }
}

  componentDidMount() {
    this.props.setModelEnhancementData(this.state.modelEnhancement)
  }

  componentDidUpdate(prevProps) {
    if(prevProps.replacePortfolioWith.ticker !== this.props.replacePortfolioWith.ticker){
      this.props.setModelEnhancementData(this.state.modelEnhancement)
    }
  }

  onSelectTab = (value) => {
    if(value==='2'){
      addEventToAnalytics('Specify Custom Ticker Models','Specify Custom Ticker Models','SPECIFY_CUSTOM_TICKER_MODELS',{},false);
    }
    this.setState({
      keyIndex:value
    })
  }

  setModelEnhancement = (modelEnhancement) => {
    this.setState({modelEnhancement});
  }

  resetCheckBoxFor = (mode) => {
    switch (mode) {
      case 'enhanceFund':
      this.setState({
        resetEnhanceFundCheckStatus: false,
      });
      break;
      case 'customAddedFund':
      this.setState({
        resetEnhanceFundCheckStatus: true,
      });
      break;
      default:
      break;
    }
  }

  resetReplaceTicker = (replaceTicker, ticker, query, calledFrom) => {
    // console.log(replaceTicker, ticker, query, calledFrom);
  }

  setReplaceTicker = (e, replaceTickerData, replaceTicker, query) => {
    const val = document.getElementById(e.target.id).checked;
    // console.log(e, replaceTickerData, replaceTicker, query);

    let ticker = replaceTicker, tickerData = replaceTickerData;

    if (val) {
      addEventToAnalytics('Enhancer MP Select','Enhancer MP Select','ENHANCER_MP_SELECT',{},false);
      ticker = replaceTicker;
      tickerData = replaceTickerData;
      if (this.state.enhanceReplace === ticker) {
        ticker = '';
        tickerData = {};
      }
    } else {
      //for unchecking checkbox
      ticker = '';
      tickerData = {};
    }

    this.setState({
      enhanceReplace: ticker,
    }, () => {
      const { query, profile, currentPerformanceImpact, modelPortfolios, readableQuery, portfolioQuery } = this.props;
      const { myCurrentPortfolio, modelUniverse } = modelPortfolios;

      const { replaceCurrentModelPortfolioWithRequest, setEnhancerState } = this.props;
      if (replaceCurrentModelPortfolioWithRequest && setEnhancerState) {
        setEnhancerState({
          enhanceReplace: [{
            query,
            replace: ticker,
            selectedPortfolioFunds: tickerData,
            ticker: myCurrentPortfolio.portfolioDetails.name,
          }],
        });
        replaceCurrentModelPortfolioWithRequest({
          replacePortfolioWith: tickerData,
        });
      }
    });
  }

  searchFundsActiveRegion = (activeRegion) => {
    // console.log(activeRegion);
    this.setState({
      activeRegion
    });
  }

  removeCustomMP = ()=>{
    console.log('remove')
    this.props.removeCustomMPSelection()
  }

  openChange = () => {
    const element = document.querySelector('.market-popup-wrapper');
    const dropdownEl = document.querySelectorAll('.ant-dropdown-menu-submenu-title');
    if (element != null) {
      element.style.height = element.clientHeight > 0 && `${element.clientHeight}px`
    }
    if(dropdownEl){
      dropdownEl.forEach((el, i) => {
        dropdownEl[i].title = '';
      })
      // console.log('here el', dropdownEl);
    }
  }

  setMarketPlace = data => {
    console.log(data)
    const { getMPDetailsCustomReplaceRequest, portfolioId} = this.props;

    let obj = { portfolio_id: data.ticker,
      portfolio_name:data.name,
      isSponsored:true,
      target_pf_id:portfolioId};
    getMPDetailsCustomReplaceRequest(obj)
  }

  selectedPortfolio = portfolioData => {
    // console.log(portfolioData)
    const { getMPDetailsCustomReplaceRequest,portfolioId} = this.props;
    const obj = {
      portfolio_id: portfolioData.id,
      portfolio_name:portfolioData.portfolio_name,
      isSponsored:false,
      target_pf_id:portfolioId
    };
    getMPDetailsCustomReplaceRequest(obj)
  }

  tabOnChange = (selectedKey, data, ticker, query, index) => {
    this.setState({ _viewIndex: selectedKey });
  }

  render() {
    const { query, profile, currentPerformanceImpact,customReplaceMPOptions,isGettingMPData, modelPortfolios, readableQuery, portfolioQuery,query_english,replacePortfolioOptions, activeViewTab } = this.props;
    const { myCurrentPortfolio, modelUniverse } = modelPortfolios;
    const { resetEnhanceFundCheckStatus, portfolioFunds,IMG_OBJECT, activeRegion, modelEnhancement, keyIndex, _viewIndex } = this.state;
    let { _NEW_QUERY_VAR_OBJECT } = modelPortfolios.replacePortfolioOptions;

    let currentPortfolioNameInitial = '';
    if (myCurrentPortfolio && typeof myCurrentPortfolio.portfolioDetails !== 'undefined') {
      const { portfolioDetails } = myCurrentPortfolio;

      if (portfolioDetails.profile.firstName || portfolioDetails.profile.lastName) {
        if (portfolioDetails.profile.firstName) {
          currentPortfolioNameInitial = portfolioDetails.profile.firstName[0];
        }
        if (portfolioDetails.profile.lastName) {
          currentPortfolioNameInitial = currentPortfolioNameInitial+portfolioDetails.profile.lastName[0];
        }
      } else {
        currentPortfolioNameInitial = 'N/A';
      }
    }

    return (
      <React.Fragment>
        <Row className="enhance-result-page-container">
					<Col className="static-card-wrapper">
						<div className="static-card-label" id="short_name_tooltip">Selected Portfolio</div>
            <Card className="static-card-body">
              <ItemCard
                i={1}
                universe={''}
                staticCard
                activeViewTab={_viewIndex}
                className="model-portfolio-item-card"
                data={myCurrentPortfolio}
                portfolioQuery={portfolioQuery}
                universe={modelUniverse || ''}
                currentPortfolioNameInitial={currentPortfolioNameInitial}
                highlights={modelPortfolios.replacePortfolioOptions.Highlights}
                query={query}
                ticker={null}
                activeRegion={activeRegion}
                defaultFRR={_NEW_QUERY_VAR_OBJECT._defaultFRR}
                relevanceChartTabs={_NEW_QUERY_VAR_OBJECT._relevanceChartTabs}
                originalQueryVar={_NEW_QUERY_VAR_OBJECT._originalQueryVar}
                searchFundsActiveRegion={this.searchFundsActiveRegion}
                modelEnhancement={modelEnhancement}
                setModelEnhancement={this.setModelEnhancement}
                tabOnChange={this.tabOnChange}
              />
            </Card>
					</Col>
          <Col className="swap-icon-col">
            <SwapOutlined className="swap-icon-wrapper" />
          </Col>
					<Col className="dynamic-card-wrapper">
						<Tabs className="card-tab-container" defaultActiveKey="1" tabBarGutter={32} onChange={this.onSelectTab}>
							<TabPane className="tabPaneClass" tab={CapitalizeEachWord(readableQuery)} key="1">
                <Card style={{position:`relative`}} className="dynamic-card-body">
                  {keyIndex==1 && <ItemList
										staticCard={false}
                    activeViewTab={_viewIndex}
                    activeRegion={activeRegion}
                    currentPortfolioNameInitial={currentPortfolioNameInitial}
										data={modelPortfolios.replacePortfolioOptions}
                    universe={modelUniverse || ''}
										query={query}
										query_english={query_english}
										ticker={null}
                    portfolioQuery={portfolioQuery}
										resetCheck={resetEnhanceFundCheckStatus}
                    enhanceModelPortfolio={modelPortfolios}
                    myCurrentPortfolio={modelPortfolios.myCurrentPortfolio}
                    replacePortfolioWith={modelPortfolios.replacePortfolioWith}
                    portfolioQuery={query}
										resetCheckBoxFor={this.resetCheckBoxFor}
                    searchFundsActiveRegion={this.searchFundsActiveRegion}
                    modelEnhancement={modelEnhancement}
                    setModelEnhancement={this.setModelEnhancement}
                    replaceCurrentModelPortfolioWith={this.replaceCurrentModelPortfolioWith}
                    setReplaceTicker={this.setReplaceTicker}
                    tabOnChange={this.tabOnChange}
									/>}
								</Card>
							</TabPane>
              {replacePortfolioOptions && Object.keys(replacePortfolioOptions).length>0&& Object.keys(replacePortfolioOptions.query_var).length>0 &&<TabPane  tab="Specify Custom Replacement" className={style.customMPCardContainer} key="2" onTabClick={this.onSelectTab}>
                <div  className={style.customMPCard}>
                  {/*{ isGettingMPData && <Loader loading={true} />}*/}
                  {customReplaceMPOptions&&Object.keys(customReplaceMPOptions).length!==0&& <div className={style.selectionContainer}>
                    {keyIndex==2 && <ItemList
                      staticCard={false}
                      activeViewTab={_viewIndex}
                      activeRegion={activeRegion}
                      currentPortfolioNameInitial={currentPortfolioNameInitial}
                      data={customReplaceMPOptions}
                      universe={modelUniverse || ''}
                      query={query}
                      query_english={query_english}
                      ticker={null}
                      portfolioQuery={portfolioQuery}
                      resetCheck={resetEnhanceFundCheckStatus}
                      enhanceModelPortfolio={modelPortfolios}
                      myCurrentPortfolio={modelPortfolios.myCurrentPortfolio}
                      replacePortfolioWith={modelPortfolios.replacePortfolioWith}
                      resetCheckBoxFor={this.resetCheckBoxFor}
                      searchFundsActiveRegion={this.searchFundsActiveRegion}
                      modelEnhancement={modelEnhancement}
                      setModelEnhancement={this.setModelEnhancement}
                      replaceCurrentModelPortfolioWith={this.replaceCurrentModelPortfolioWith}
                      setReplaceTicker={this.setReplaceTicker}
                      tabOnChange={this.tabOnChange}
                    />}
                    <div style={{position:'absolute',right:'0',top:'24px',cursor:'pointer'}} onClick={this.removeCustomMP} ><img src="/assets/images/card-close.png" /></div>
                  </div>}
                  {(Object.keys(customReplaceMPOptions|| {})).length===0 && <div className={style.noSelectionContainer}>
                      <img className={style.plus} src="/assets/images/plus.png"/>
                      <div className={style.ddContainer}>
                        <Dropdown
                          onVisibleChange={e => {
                            const dropdownEl = document.querySelectorAll('.ant-dropdown-menu-submenu-title')
                            if (dropdownEl) {
                              dropdownEl.forEach((el, i) => {
                                dropdownEl[i].title = ''
                              })
                            }
                          }}
                          trigger={'hover'}
                          className={style.sponsored}
                          overlay={<CustomSponsoredDropDown openChange={this.openChange}
                                                            setMarketPlace={this.setMarketPlace}
                                                            marketPlaceList={this.props.marketPlaceList}
                          />}
                          overlayClassName={style.portfolioDDContainer}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <img src={IMG_OBJECT['Sponsor Models']} height="20"/>
                            <div className='row d-flex align-items-center justify-content-between' style={{ marginLeft: '10px', width: '100%', marginRight: '0px' }}>
                              <div >Sponsor Models </div>
                              <DownOutlined/>
                            </div>
                          </div>
                        </Dropdown>
                        <Dropdown
                          trigger={'hover'}
                          className={style.myPortfolio}
                          overlayClassName={style.portfolioDDContainer}
                          overlay={<PortfolioDDSearch
                            portfolioList={this.props.portfolioList}
                            selectedPortfolio={this.selectedPortfolio}
                          />}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <img src={IMG_OBJECT['My Models']} height="20"/>
                            <div className='row d-flex align-items-center justify-content-between' style={{ marginLeft: '10px', width: '100%', marginRight: '0px' }}>
                              <div >My Models </div>
                              <DownOutlined/>
                            </div>
                          </div>
                        </Dropdown>
                      </div>
                    </div>
                }
                </div>
              </TabPane>}
						</Tabs>
					</Col>
        </Row>
      </React.Fragment>
    )
  }
}

// export default CardRow;

const mapStateToProps = ({ enhancer, portfolio, profile }) => ({
  query_english: enhancer.query_english,
  marketPlaceList:enhancer.marketPlaceList,
  portfolioList: enhancer.portfolioList,
  portfolioId: enhancer.portfolioId,
  isGettingMPData: enhancer.isGettingMPData,
  customReplaceMPOptions: enhancer.modelPortfolios.customReplaceMPOptions,
  replacePortfolioOptions:enhancer.modelPortfolios.replacePortfolioOptions,
})
//
const mapDispatchToProps = {
  // deletePortfolioEnhanceReplace,
  setModelEnhancementData,
  getMPDetailsCustomReplaceRequest,
  removeCustomMPSelection,
  loadCPMListRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardRow)


export class CustomSponsoredDropDown extends React.Component{
  constructor(props) {
    super(props)
  }
  state={
    input:''
  }

  componentWillMount() {
    let { obj:newMarketPlaceList,allItems:allList } = deflatLastNode(this.props.marketPlaceList,true)
    this.setState({
      newMarketPlaceList,
      allList
    })
  }
  handleInputChange =(e)=>{
    this.setState({
      input:e
    })
  }
 render(){
    const {openChange,setMarketPlace} = this.props
    const {input,newMarketPlaceList,allList} = this.state
    let filterdList = input.length!==0 && allList.filter(item=>item.name.toLowerCase().includes(input.toLowerCase()))
    return (
      <Menu onOpenChange={openChange} className={style.portfolioMenuWrapper}>
        <Menu.Item className={style.inputContainer}>
          <Search onChange={(e)=>{e.preventDefault();this.handleInputChange(e.target.value)}} className={style.input} placeholder="Search for better results" allowClear onSearch={this.handleInputChange} />
        </Menu.Item>
        {input.length!==0 && filterdList.map(item=> <Menu.Item className={style.onSearchMenuContainer}>
            <div title={item.name} className={style.onSearchMenu} onClick={() => setMarketPlace(item)} >
              <i className={`far fa-fw fa-search ${style.resultSearchIcon}`}/>
              <div className={style.resultDetail}>
                <p title={item.name}>{item.name}</p>
                <p title={item.sponsor}>{item.sponsor}</p>
              </div>
            </div>
          </Menu.Item>
        )}
        {input.length!==0 && filterdList.length===0 && 	<Menu.Item className={`${style.noHighlight} ${style.noResult}`} key="0">
          <div >No Result Found</div>
        </Menu.Item>}
        {input.length===0 && newMarketPlaceList &&
          typeof newMarketPlaceList == 'object' &&
          Object.keys(newMarketPlaceList).length > 0 &&
          Object.keys(newMarketPlaceList).map((item, index) => {
          return (
            <SubMenu
              key={item}
              title={item}
              className={style.subMenu}
              popupClassName={style.popupClass}
            >
              {Object.keys(newMarketPlaceList[item]).length > 0 &&
              Object.keys(newMarketPlaceList[item]).map((subItem, findex) => {
                if(subItem == 'uncatagorized'){
                  return newMarketPlaceList[item][subItem].length > 0 && newMarketPlaceList[item][subItem].map(
                        (subItem1, index) => {
                          return (
                            <div
                              title={subItem1.name}
                              key={`market_name-label_${index}`}
                              className={style.itemLable}
                              onClick={() =>
                                setMarketPlace(subItem1)
                              }
                            >
                              {subItem1.name}
                            </div>
                          )
                        }
                      )

                }
                else{
                  return (
                    <SubMenu
                      key={subItem}
                      title={subItem}
                      className={style.subMenu}
                      popupClassName={style.popupClass}
                    >
                      {newMarketPlaceList[item][subItem].length > 0 &&
                        newMarketPlaceList[item][subItem].map(
                          (subItem1, index) => {
                            return (
                              <div
                                title={subItem1.name}
                                key={`market_name-label_${index}`}
                                className={style.itemLable}
                                onClick={() =>
                                  setMarketPlace(subItem1)
                                }
                              >
                                {subItem1.name}
                              </div>
                            )
                          }
                        )}
                    </SubMenu>
                  )
                }
              })}
            </SubMenu>
          )
        })
        }
        {newMarketPlaceList &&
        newMarketPlaceList.message &&
        newMarketPlaceList.message == 'No tickers Present.' && (
          <Menu.Item key="0">
            <div className="portfolio_name-label dropdown-style">No data</div>
          </Menu.Item>
        )}
      </Menu>
    );
  }


}


export class PortfolioDDSearch extends React.Component  {
  constructor(props) {
    super(props);
  }
  state={
    input:''
  }
  handleInputChange =(e)=>{
    // console.log(e)
    this.setState({
      input:e
    })
  }

  render(){
    const { portfolioList,selectedPortfolio } =this.props;
    const {input} =this.state
    let filterList = portfolioList && portfolioList.length>0 ? portfolioList.filter(item=>item.portfolio_name.toLowerCase().includes(input.toLowerCase())):[]
    return (
      <Menu className={style.portfolioMenuWrapper}>
        <Menu.Item className={style.inputContainer}>
          <Search onChange={(e)=>{e.preventDefault();this.handleInputChange(e.target.value)}} className={style.input} placeholder="Search for better results" allowClear onSearch={this.handleInputChange} />
        </Menu.Item>
        {input.length!==0 && filterList.length===0 && 	<Menu.Item className={`${style.noHighlight} ${style.noResult}`} key="0">
          <div >No Result Found</div>
        </Menu.Item>}
        {input.length===0 && filterList.length===0 && 	<Menu.Item className={`${style.noHighlight} ${style.noResult}`} key="0">
          <div >No User Models</div>
        </Menu.Item>}
        {filterList &&
        filterList.length &&
        filterList.map((item, index) => {
          return (
            <Menu.Item className={style.onSearchMenuContainerUP} key={item.portfolio_name + index}>
              <div title={item.portfolio_name} className={style.onSearchMenu}  onClick={() => {selectedPortfolio(item)}} >
                {input.length>0 && <i className={`far fa-fw fa-search ${style.resultSearchIcon}`}/>}
                <div className={style.resultDetail}>
                  <p title={item.portfolio_name}>{item.portfolio_name}</p>
                </div>
              </div>
            </Menu.Item>
          )
        })
        }
      </Menu>

  );
}

}

import React from "react";
import PropTypes from "prop-types";
import { PrimaryButton } from "shared-components/";
import style from './Brokers.module.scss'
import { Form } from 'reactstrap'

export default function BrokerList(props) {
  const items = props.brokerList.map((broker, idx) => {
    if(broker.disabled|| (broker.control && !props.showMagnifi))
      return null
    else
      return (
      <li key={idx} className="brokers__item">
        <button
          className={style.brokerButton}
          onClick={() => props.onBrokerSelect(broker)}
          disabled={broker.disabled}
        >
         {broker.displayName}
        </button>
      </li>
    );
  });

  return (
    <div className={style.brokerModal}>
      <span className={style.title}>Select Broker</span>
      <ul className="brokers">{items}</ul>
    </div>
  );
}

BrokerList.propType = {
  onBrokerSelect: PropTypes.func.isRequired
};

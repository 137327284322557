import React, { useState } from 'react'
import { Checkbox, Tooltip, Radio, Space } from 'antd';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  ButtonGroup,
  Button,
  Tooltip as ReactToolTip
 } from 'reactstrap';
 import {
   PlusOutlined,
 } from '@ant-design/icons';

import { cn, isDemoUser, sponsorLogo, MIN_INVESTMENT_FUNDS } from 'DataSet';
import { numFmt, arrayToHash, getImageOrFallback, addEventToAnalytics, getNest } from 'Utils';
import { numberCheck } from 'layouts/utils';

import ItemCarousel from 'components/ItemCarousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { TruncatedNameSponsor } from '../Common';
import { AccountDropDownValue, ExecutationType, UnitsValue } from './helper'
import Emitter from 'realTimePrice/emitter'
import get from 'lodash/get';
import style from './ActTab.module.scss'
import isNull from 'lodash/isNull';

const classNames = require('classnames');
const regexFiveDigit = /^[0-9]*(\.[0-9]{0,5})?$/;
const regexTwoDigit = /^[0-9]*(\.[0-9]{0,2})?$/;

class ItemCard extends React.Component {
  state = {
    price: '',
    qty: '',
    tvalue: '',
    amtType: 'Units',
    valid: false,
    activeKey: 0,
    imageValidPath: false,
    type:'',
    action:'buy',
    realTimePrices:[],
    transitionTimedOut: false,
    showErrorToolTip: null,
    showErrorInput: false,
    readyTValueRef: false,
    readyQtyRef: false,
    isBasketTrade: false
  }

  constructor (props) {
    super(props);
    this.initState();
    this.tvalueRef = React.createRef();
    this.qtyRef = React.createRef();
  }

  componentDidMount() {
    const { numberOfItems = 1, ipWeight, card, sfm : { myportfolio } } = this.props;
    if (cn(card, 'Sponsor')) {
      getImageOrFallback(
        sponsorLogo(cn(card, 'Sponsor'))
      ).then(result => {
        if (result) {
          this.setState({ imageValidPath: true });
        } else {
          this.setState({ imageValidPath: false });
        }
      });
    }

    if (this.props.card._order === undefined) {
      this.props.card._order = {
        otype: 'Market',
        price: '',
        qty: '',
        validity: undefined,
        state: {...this.state},
      };
    } else {
      this.setState({
        ...this.props.card._order.state,
      });
    }

    if (this.props.card && typeof this.props.card._selectedType !== 'undefined') {
      this.setState({ action: this.props.card._selectedType });
    }

    Emitter.on('GET_LIVE_PRICES',  (price) => {
      // console.log('==== GET_LIVE_PRICES ====');
      // console.log(price.symbol,price.price);
      let index;
      let priceClass = '';

      let realTimePrices = this.state.realTimePrices

      if (realTimePrices.length) {
        index = realTimePrices.findIndex(x => x.ticker === price.symbol);

        if (index > -1) {
          //if (realTimePrices[index] && realTimePrices[index].ticker === price.symbol) {
            //console.log(price.price , realTimePrices[index].price, price.symbol, realTimePrices[index].ticker);
            if (price.price > realTimePrices[index].price) {
              priceClass = 'priceUp';
            } else if (price.price < realTimePrices[index].price) {
              priceClass = 'priceDown';
            } else {
              priceClass = realTimePrices[index].priceClass;
            }
            //console.log(price.price , realTimePrices[index].price, price.symbol, realTimePrices[index].ticker, priceClass);
         // }
          realTimePrices.splice(index, 1);
        }

        realTimePrices.push({'price': price.price, 'ticker': price.symbol, 'priceClass': priceClass})
      } else {
        realTimePrices.push({'price': price.price, 'ticker': price.symbol, 'priceClass': priceClass})
      }

      this.setState({realTimePrices:realTimePrices, transitionTimedOut: false})
    });

    if(myportfolio && typeof ipWeight !== 'undefined' && !isNull(ipWeight)) {
      // console.log('numberOfItems ==>', numberOfItems, ipWeight);
      this.setAction("buy");
      const marketValueSum = myportfolio && myportfolio.length > 0 ? myportfolio.reduce((acc, v) => acc+=parseFloat(v.market_value || 0), 0) : 0;
      const finalVal = parseFloat(((ipWeight*marketValueSum)/numberOfItems).toFixed(2));
      this.state.amtType = "Value";
      this.state.tvalue = isFinite(finalVal)? finalVal : null;
      card._order.state = {...this.state};
      this.setState({ ...this.state });
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.isMF !== this.props.isMF){
      this.setState({ type: this.props.isMF });
    }
    if(MIN_INVESTMENT_FUNDS.includes(nextProps.card.vehicle) && this.state.amtType !== "Value"){
      this.setState({amtType: nextProps.card.vehicle === "BASKET" ? 'Value' : this.state.amtType, isBasketTrade: true});
    }
  }

  shouldComponentUpdate(props) {
    const commH = arrayToHash(props.card.brokers, 'name');
    props.brokers.forEach((e, i) => props.flag[i].sel && (props.card.broker = commH[e.name])); // not the right place?
    props.card._brokerFlags = props.flag;  // need rework
    props.card._order.state = {
      ...this.state,
    };
    this.tradeValue();
    props.buyHandler();
    return true;
  }

  unselectCard = (item) => {
    const { funds } = this.props;
    this.props.clearHandler(item);
    if (funds) {
      addEventToAnalytics('ACT fund delete','ACT fund delete','ACT_FUND_DELETE',{ query: funds.query, logId: funds.logId, ticker: item.ticker },true);
    }
  }

  selectComm = (broker) => {
    const { selectBroker } = this.props;
    if(selectBroker) {
      selectBroker(broker, cn(this.props.card, 'Ticker'));
    }
  }

  ot = () => ['Market', 'Limit'][this.state.otype];

  otypeHandler = (event) => {
    const otype = event.target.value;
    this.props.card._order.otype = otype;
    this.setState({ otype });
  }

  qtyHandler = (event) => {
    if (event && event.target && regexFiveDigit.test(event.target.value)) {
      let query = event.target.value;
      const qty = query.includes('.') || query.length < 2   ?  query : query.replace(/^0+/, '');
      this.state.qty = qty;
      this.state.amtType = 'Units';
      this.setState({ amtType: 'Units', qty });
    }
  }

  setAction = (action) => {
    //wrote as ^ fn. not the standard ik.
    this.state.action = action
    if(["sell", "sell_all"].includes(action) && (this.state.showErrorInput || this.state.showErrorToolTip)){
      this.setState({
        showErrorInput: false,
        showErrorToolTip: false
      });
    }
    const { card } = this.props;
    this.state.price = "";
    this.state.qty = "";
    this.state.tvalue = "";
    card._order.state = {...this.state};

    this.setState({
      action
    })
    const { setFundSelectionTypeRequest } = this.props;
    if (setFundSelectionTypeRequest) {
      setFundSelectionTypeRequest({ selectedFund: card, selectedType: action });
    }
  }

  priceHandler = (event) => {
    this.props.card._order.price = event.target.value;
    this.setState({ price: event.target.value });
  }

  tvalueHandler = (event) => {
    if (event && event.target && regexTwoDigit.test(event.target.value)) {
      this.state.tvalue = event.target.value;
      this.state.amtType = 'Value';
      this.setState({ amtType: 'Value' });
    }
  }

  validityHandler = (event) => {
    // console.log('validityHandler =>>', event.target.value);
    const validity = event.target.value;
    this.state.validity = validity;
    this.setState({ validity }, () =>{
      this.props.card._order.validity = this.state.validity;
    });
  }

  tradeValue = () => {
    const { card, flag } = this.props;
    let _comm = flag.some(e => e.sel) ? card.broker.commission : 0.0;
    let price = (card._order.otype === 'Market' ? cn(card, 'Nav Price') : card._order.price) || 0.0;
    if (this.state.amtType === 'Value') {
      let tvalue = this.state.tvalue - _comm;
      // console.log('tvalue ==>', tvalue);
      card._order.qty = tvalue >= 0 && price > 0.0 ? parseFloat((tvalue/price).toFixed(5)) : ''
      card._order.tvalue = tvalue;
      // console.log('typeof card._order.qty ==>', card._order.qty);
      this.state.qty = '';
      // console.log("Value", card._order);
      // card._order.tvalue = '';
    }
    else if (this.state.amtType === 'Units') {
      card._order.tvalue = this.state.qty > 0 && price > 0 ? (this.state.qty*price + _comm) : 0.0;
      card._order.qty = this.state.qty
      this.state.tvalue = '';
      // card._order.qty = '';
      // console.log("Units", card._order);
    }
    this.state.valid = this.validate();
    // this.state.valid2 = this.validate2();
    // console.log(card._order.state, card.ticker, this.state);
    card._order.state = {...this.state};
  }

  initState = () => {
    const {card} = this.props;
    if (card._order.state)
      this.state = {...card._order.state}
    this.state.qty = ''
    this.state.otype = card._order.otype;
    this.state.price = card._order.price;
    this.state.validity = card._order.validity;
  }

  validate = () => {
    const { card, flag } = this.props;
    const isNum = n => +n > 0
    return ((isNum(card._order.qty) || isNum(card._order.tvalue)) &&
    (card._order.otype === 'Market' || (isNum(card._order.price) &&
    ['End Of Day', 'Cancelled'].indexOf(this.state.validity) >= 0))) || (this.state.action === "sell_all");

    // return (isNum(card._order.qty) || isNum(card._order.tvalue)) &&
    // (card._order.otype === 'Market' || (isNum(card._order.price) &&
    // ['End Of Day', 'Cancelled'].indexOf(this.state.validity) >= 0)) &&
    // flag.some(e => e.sel)
  }

  // validate2 = () => {
  //   const { card, flag } = this.props;
  //   const isNum = n => +n > 0
  //   return (isNum(card._order.qty) || isNum(card._order.tvalue)) &&
  //   (card._order.otype === 'Market' || isNum(card._order.price))
  // }

  accountHandler = (broker, account_number, card) => {
    const { accountHandler } = this.props;
    accountHandler(broker, account_number, card);
  }

  handleActiveIndexKey = (index) => {
    this.setState({
      activeKey: index,
    });
  }

  selectedBrokerInfo = (broker) => {
    this.selectComm(broker);
  }

  selectFundToExecute = (event) => {
    const { selectFundToExecute, card } = this.props;
    if (selectFundToExecute) {
      selectFundToExecute(event.target.checked, cn(card, 'Ticker'))
    }
  }

  toggleAddMoneyPopup = () => {
    const { toggleAddMoneyPopup } = this.props;
    if (toggleAddMoneyPopup) {
      toggleAddMoneyPopup()
    }
  }

  updateClass = () => {
    this.setState({transitionTimedOut: true})
  }

  amtHandler = (amtType) => {
    if(amtType !== this.state.amtType){
      const { card } = this.props;
      this.state.price = "";
      this.state.qty = "";
      this.state.tvalue = "";
      card._order.state = {...this.state};
    }
    this.setState({ amtType });
  }

  handleInputBlur = (e, getMyHoldingIFExists, realPrice) => {
    const value = e.target.value;
    const { card } = this.props;
    const { isBasketTrade } = this.state;
    let showErrorInput = false;
    if(value){
        let toolTipError = null;
        switch (getNest(['card', '_selectedType'], this.props)) {
          case "buy":
              // if (isBasketTrade && get(card, '_order.tvalue') < get(card, 'minimum_investment')) {
              //   let _minValu = get(card, 'minimum_investment');
              //   _minValu = numberCheck(_minValu, 2); // (_minValu !== null && !isNaN(_minValu) && !isNaN(parseFloat( (_minValu).toFixed(2) ))) ? parseFloat((_minValu).toFixed(2)) : 0
              //   toolTipError = `The value must be greater than or equal to ${numberFormatForAct(_minValu, 2)}`;
              //   showErrorInput = true;
              // }else if(get(this.props, 'card._order.tvalue') < 5){
              //   toolTipError = "The value must be greater than or equal to $5";
              //   showErrorInput = true;
              // }
              if (get(card, '_order.tvalue') < get(card, 'minimum_investment')) {
                let _minValu = get(card, 'minimum_investment');
                _minValu = numberCheck(_minValu, 2); // (_minValu !== null && !isNaN(_minValu) && !isNaN(parseFloat( (_minValu).toFixed(2) ))) ? parseFloat((_minValu).toFixed(2)) : 0
                toolTipError = `The value must be greater than or equal to ${numberFormatForAct(_minValu, 2)}`;
                showErrorInput = true;
              }
            break;
          case "sell":
            const myHoldingValue = card.vehicle === "BASKET" ? parseFloat(get(getMyHoldingIFExists, '[0].market_value')).toFixed(5) : parseFloat(get(getMyHoldingIFExists, '[0].shares')).toFixed(5);
            // console.log("myHoldingValue?>>>>", myHoldingValue, (myHoldingValue*realPrice), card.vehicle)
            // console.log('== input value ==', parseFloat(get(this.props, 'card._order.tvalue')));

            if(myHoldingValue && card.vehicle === "BASKET" && (parseFloat(get(this.props, 'card._order.tvalue')) > myHoldingValue) ){
              // console.log('=== BASKET market_value ===', myHoldingValue);
              toolTipError = "The value must be less than or equal to current holding";
              showErrorInput = true;
            } else {
              let _inputValue = (!isNaN(get(this.props, 'card._order.tvalue')) &&
                                get(this.props, 'card._order.tvalue') !== null &&
                                !isNaN(parseFloat(get(this.props, 'card._order.tvalue')))) ? parseFloat(get(this.props, 'card._order.tvalue')) : 0;
              let _sharesValue = myHoldingValue;
              // console.log('== input value ==>', _inputValue);
              // console.log('== _sharesValue*realPrice ==>', _sharesValue*realPrice);
              if (_inputValue > (_sharesValue*realPrice)) {
                toolTipError = "The quantity must be less than or equal to current holding";
                showErrorInput = true;
              }
            }
            // console.log(get(this.props, 'card._order.qty'), myHoldingValue);
            // if(myHoldingValue && parseFloat(get(this.props, 'card._order.qty')) > myHoldingValue){
            //   toolTipError = "The quantity must be less than or equal to current holding";
            //   showErrorInput = true;
            // }
            break;
          default:
            console.log("work without validation")
            break;
        }
        this.setState({
          showErrorToolTip: toolTipError,
          showErrorInput
        }, () => {
          setTimeout(()=> {
            this.setState({
              showErrorToolTip: null,
            });
          }, 3000);
        });
    }else{
      this.setState({
        showErrorInput: false,
        showErrorToolTip: null,
      });
    }
    // if(value && (tradeValueCondition || (isBasketTrade &&  get(this.props, 'card._order.tvalue') < get(this.props, 'card.minimum_investment'))) && getNest(['card', '_selectedType'], this.props) === "buy"){
    //   let toolTipError = "The value must be greater than or equal to $5";
    //   if(isBasketTrade){
    //     toolTipError = `The value must be greater than or equal to $${get(this.props, 'card.minimum_investment')}`;
    //   }
    //   this.setState({
    //     showErrorToolTip: toolTipError,
    //     showErrorInput: true
    //   }, () => {
    //     setTimeout(()=> {
    //       this.setState({
    //         showErrorToolTip: null,
    //       });
    //     }, 3000);
    //   })
    // }else{
    //   this.setState({
    //     showErrorInput: false,
    //     showErrorToolTip: null,
    //   });
    // }
  }

  handleInputFocus = () => {
    this.setState({
      showErrorInput: false,
      showErrorToolTip: null,
    });
  }

  // handleSellAllToggle = (e) => {
  //   const checked = e.target.checked;
  //   this.sellAllToggle(checked);
  // }

  // sellAllToggle = (checked) => {
  //   const { card } = this.props;
  //   this.state.price = "";
  //   this.state.qty = "";
  //   this.state.tvalue = "";
  //   this.state.sell_all = checked;
  //   card._order.state = {...this.state};
  //   this.props.buyHandler();
  //   this.setState({
  //     sell_all: checked
  //   });
  // }

  render() {
    const { activeKey, imageValidPath, realTimePrices, showErrorToolTip, showErrorInput, readyQtyRef, readyTValueRef, amtType, action } = this.state;
    const { card, brokers, idx, flag, amt, isBrokerRegistered, importedAccounts, isMF, showBalance, showPendingAccount, avaBalance, noBankAccount } = this.props;
    if (!card.broker || !card._order) return null;
    const commH = arrayToHash(card.brokers, 'name');
    const disabledAll = flag && flag.every(e => e.disabled);

    // console.log('this.state.action ===>>', this.state.action);
    // const checkedToExecute = (card._order && card._order.state && card._order.state.checkedToExecute);
    const brokerName = brokers && brokers.length && brokers[activeKey] && brokers[activeKey].name;
    // console.log('brokerName ==>', brokerName);
    const addBalanceText = brokerName ? `Add Balance in ${brokerName} Account` : null;
    const prec = 2;

    let realPriceArr = realTimePrices.filter(e => e.ticker === card.ticker);

    let priceClass = ((realPriceArr.length > 0) ? realPriceArr[0].priceClass : '');
    // console.log(realPriceArr);
    let realPrice = ((realPriceArr.length > 0) ? realPriceArr[0].price.toFixed(2) : (cn(card, 'Nav Price') ? cn(card, 'Nav Price').toFixed(2) : '--' ))

    // hack to pass RealTimePric in ItemList.js
    card.realPrice = realPrice || cn(card, 'Nav Price');

    const showOnlyValue = card.vehicle === "BASKET";
    const getMyHoldingIFExists = (this.props.sfm && this.props.sfm.myportfolio && this.props.sfm.myportfolio.length > 0) ? this.props.sfm.myportfolio.filter(v => v.script === card.ticker) : [];
    // const currentHoldings = !isNaN(get(getMyHoldingIFExists, '[0].shares')) ? showOnlyValue ? FormatCurrency(parseFloat(get(getMyHoldingIFExists, '[0].market_value')).toFixed(5)) : FormatCurrency(realPrice * parseFloat(get(getMyHoldingIFExists, '[0].shares')).toFixed(5)) : null;

    const __marketValue = ((typeof get(getMyHoldingIFExists, '[0].market_value') !== 'undefined' &&
                          get(getMyHoldingIFExists, '[0].market_value') !== null) &&
                          !isNaN(get(getMyHoldingIFExists, '[0].market_value')) &&
                          !isNaN( parseFloat(get(getMyHoldingIFExists, '[0].market_value')).toFixed(5) )) ? Number(parseFloat(get(getMyHoldingIFExists, '[0].market_value')).toFixed(5)) : 0;

    const __sharesValue = ((typeof get(getMyHoldingIFExists, '[0].shares') !== 'undefined' &&
                          get(getMyHoldingIFExists, '[0].shares') !== null) &&
                          !isNaN(get(getMyHoldingIFExists, '[0].shares')) &&
                          !isNaN( parseFloat(get(getMyHoldingIFExists, '[0].shares')).toFixed(5) )) ? Number(parseFloat(get(getMyHoldingIFExists, '[0].shares')).toFixed(5)) : 0;

    const __fundType = get(getMyHoldingIFExists, '[0].type');

    const _currentHoldings = showOnlyValue ? __marketValue : (realPrice * __sharesValue);
    const currentHoldings = `${numberFormatForAct(_currentHoldings, 2)}`;

    const isSelling = action === "sell" || action === "sell_all";
    return (
      <Card className={classNames("shadow-sm mb-2 border-0")} style={{ minHeight: 113 }}>
        <CardBody className="p-0 ">
          <div className="no-gutters display-flex-row">
            <div className={` ${isBrokerRegistered ?'col-40' : 'col-50' } padding-5-right-1`}>
              <div className="bg-ssf-gray-l5 p-1 h-100 d-flex align-items-center pos-rel" style={{ minHeight: 113 }}>
                <div className="d-flex align-items-center h-100 close-wrapper pl-1">
                  <Checkbox
                    className='custom-ant-checkbox'
                    type='checkbox'
                    title='Select'
                    id={card.ticker}
                    inline
                    checked={card._checkedToExecute}
                    onChange={(e) => this.selectFundToExecute(e, card)}
                  />
                </div>
                <div className="pr-1 px-2 text-center">
                  <h5 className="mb-0 text-blue text-gray-5 ticker-size ticker-price">{cn(card, 'Ticker Short')}</h5>
                  <h3 className={`mb-0 text-info`} style={{minWidth: '5rem'}}>
                    <span className={(this.state.transitionTimedOut) ? `ticker-price text-gray-5` : `ticker-price text-gray-5 ${priceClass}`} onTransitionEnd={() => this.updateClass()}>$</span>

                    <span className={(this.state.transitionTimedOut) ? `ticker-price actual-price` : `ticker-price actual-price ${priceClass}`} onTransitionEnd={() => this.updateClass()}>{realPrice}</span>
                    {/*<span className="ticker-price actual-price">{cn(card, 'Nav Price') ? cn(card, 'Nav Price').toFixed(1) : '--'}</span>*/}
                  </h3>
                </div>
                <TruncatedNameSponsor
                  style={{ flex: 1, maxWidth: 125, minWidth: 125 }}
                  showSponsorLogo={card._showSponsoredOnSFM}
                  imageValidPath={imageValidPath}
                  name={cn(card, 'Name2')}
                  sponsor={cn(card, 'Sponsor')}
                  lines={1}
                />
                <div className="d-flex align-items-start h-100 close-wrapper">
                  <div className="close-icon-box pl-0">
                    <i className="fal fa-times-circle" onClick={() => this.unselectCard(card)}></i>
                  </div>
                </div>
              </div>
            </div>
            <div className={` ${isBrokerRegistered ?'col-20' : 'col-25' } padding-5-right-1`}>
              <div className="bg-ssf-gray-l9 px-2 py-1 h-100 d-flex flex-column align-items-center justify-content-center">
                <UnitsValue showOnlyValue={showOnlyValue} funds={this.props.funds} value={amtType} amtHandler={this.amtHandler} />
                {/* <ExecutationType/> */}
                <div className=" d-flex flex-column">
                  { amtType === 'Units' &&
                    <React.Fragment>
                      <FormGroup className="m-auto" style={{maxWidth: 100}}>
                        <Input
                          // type="number"
                          name="qty"
                          id={"orderQty"+idx}
                          ref={this.qtyRef}
                          bsSize="sm"
                          placeholder="Units"
                          min="0"
                          className="text-center"
                          value={this.state.qty}
                          onChange={this.qtyHandler}
                          onBlur={(e) => this.handleInputBlur(e, getMyHoldingIFExists, realPrice)}
                          onFocus={this.handleInputFocus}
                          invalid={showErrorInput}
                          readOnly={action === "sell_all"}
                          />
                          {this.qtyRef && this.qtyRef.current && <ReactToolTip
                            className={`col-grey-bottom`}
                            placement="top"
                            target={"orderQty"+idx}
                            isOpen={!!showErrorToolTip}
                          >
                          {showErrorToolTip}
                          </ReactToolTip>}
                      </FormGroup>
                      <div className="text-center h5 lh-125 my-0">
                        <small><span className="text-gray-5">Est. Value: </span>${numFmt(card._order.tvalue, 2)}</small>
                      </div>
                    </React.Fragment> }
                  { amtType === 'Value' &&
                    <React.Fragment>
                      <FormGroup className="m-auto" style={{maxWidth: 100}}>
                        <Input
                          // type="number"
                          ref={this.tvalueRef}
                          name="tvalue"
                          id={"orderTvalue"+idx}
                          bsSize="sm"
                          placeholder="Value"
                          min="0"
                          className="text-center"
                          value={this.state.tvalue}
                          onChange={this.tvalueHandler}
                          onBlur={(e) => this.handleInputBlur(e, getMyHoldingIFExists, realPrice)}
                          onFocus={this.handleInputFocus}
                          invalid={showErrorInput}
                          readOnly={action === "sell_all"}
                        />
                        {this.tvalueRef && this.tvalueRef.current && <ReactToolTip
                            className={`col-grey-bottom`}
                            placement="top"
                            target={"orderTvalue"+idx}
                            isOpen={!!showErrorToolTip}
                          >
                          {showErrorToolTip}
                        </ReactToolTip>}
                      </FormGroup>
                      <div className="text-center h5 lh-125 my-0">
                        <small><span className="text-gray-5">Est. Units: </span>{numFmt(card._order.qty|| 0, 5)}</small>
                      </div>
                    </React.Fragment>}
                </div>
              </div>
            </div>
            <div className={` ${isBrokerRegistered ?'col-20 padding-5-right-1' : 'col-25 padding-5-right-1'}`}>
              <div className="bg-ssf-gray-l9 px-2 py-1 h-100 d-flex align-items-center justify-content-center" style={{minHeight: '75px'}}>
                <div className="form-row w-100">
                  <FormGroup className="col-12 mb-0 d-none" >
                    <Input type="select" name="otype" id="orderType" bsSize="sm" required
                      value={this.state.otype}
                      onChange={this.otypeHandler}>
                      <option value="Market">Market</option>
                      {/* <option value="Limit">Select Limit</option> */}
                    </Input>
                  </FormGroup>
                  <div className="text-center mx-auto">
                      <InvestMentTextCard
                        isSelling={isSelling}
                        currentHoldings={currentHoldings}
                        fundType={__fundType}
                        sharesValue={__sharesValue}
                        minInvestment={get(this.props, 'card.minimum_investment')}
                        prec={prec}
                      />
                      {/* <h5>{isSelling ? currentHoldings || "..." : !isNaN(get(this.props, 'card.minimum_investment')) ? FormatCurrency(get(this.props, 'card.minimum_investment'), prec) : "..."}</h5>

                      <h5 className="mb-0" style={{minWidth: '4rem', lineHeight: 1.5}}>{isSelling ? "Current Value Held" : "Minimum Investment Value"}</h5> */}
                    {/* <h5 className="mb-0 text-info " style={{minWidth: '4rem', lineHeight: 1.5}}>
                      <div className="mb-1" style={{ display: 'flex'}}>
                        <div className="font-weight-bold" style={{
                          background: 'rgb(53,53,53,0.16)',
                          color: '#353a3d',
                          width: '45px',
                          borderRadius: '3px',
                          marginRight: '5px'
                        }}
                        >
                          Bid
                        </div>
                        <div className="" style={{color: '#6c757d'}}>${(cn(card, 'Nav Price')-(cn(card, 'Nav Price')*0.5/100)).toFixed(2)}</div>
                      </div>
                      <div className="m-0" style={{ display: 'flex'}}>
                        <div className="font-weight-bold" style={{
                          background: 'rgb(53,53,53,0.16)',
                          color: '#353a3d',
                          width: '45px',
                          borderRadius: '3px',
                          marginRight: '5px'
                        }}
                        >
                          Ask
                        </div>
                        <div className="" style={{color: '#6c757d'}}>${(cn(card, 'Nav Price')+(cn(card, 'Nav Price')*0.5/100)).toFixed(2)}</div>
                      </div>
                    </h5> */}
                  </div>

                  { this.state.otype !== 'Market' &&
                    <React.Fragment>
                      <FormGroup className="col-5 mb-0 mt-1">
                        <Input type="number" name="price" id="orderPrice" bsSize="sm" placeholder="Price"
                          min="0" step="0.05" className="px-1" readOnly={this.state.otype === 'Market'}
                          value={this.state.price}
                          onChange={this.priceHandler} />
                      </FormGroup>
                      <FormGroup className="col-7 mb-0 mt-1 pl-0">
                        <Input type="select" name="validity" id="orderValid" bsSize="sm" required
                          style={{padding: '.25rem 2px'}}
                          value={this.state.validity}
                          defaultValue=""
                          onChange={this.validityHandler}>
                          <option disabled value="">Good Till</option>
                          <option>End Of Day</option>
                          <option>Cancelled</option>
                        </Input>
                      </FormGroup>
                    </React.Fragment> }
                </div>
              </div>
            </div>
            <div className={` ${isBrokerRegistered ?'col-20' : 'col-25' } padding-5`}>
              <div className="bg-ssf-gray-l9 px-2 py-1 h-100 d-flex flex-column align-items-center justify-content-center">
                <BuySell currentHoldings={_currentHoldings} setAction={this.setAction} value={this.state.action} />
                {/* {this.state.action === "sell" && <div className="close-wrapper">
                  <Checkbox
                    className='danger-checkbox pt-1'
                    type='checkbox'
                    title='Select'
                    inline
                    checked={sell_all}
                    onChange={this.handleSellAllToggle}
                  >
                  Sell All
                  </Checkbox>
                </div>} */}
              </div>
            </div>
            {isBrokerRegistered
              && (
                <div className="col-20 padding-5">
                  <div className="bg-ssf-gray-l9 px-2 py-1 h-100 d-flex align-items-center justify-content-center" style={{minHeight: '75px'}}>
                    {showBalance && (
                      <div className="sfm-act-tab-acc-balance-wrapper">
                        <i className="far fa-wallet"></i>
                        <span className="wallet-text">{!isNaN(avaBalance) ? FormatCurrency(avaBalance, prec) : '...'}</span>
                        <span id={`sfm-act-tab-${card.ticker}`}>
                          {addBalanceText ? (
                            <Tooltip
                              placement="bottom"
                              color="#707070"
                              overlayClassName="magnifi-tooltip-wrapper"
                              title={addBalanceText}
                              getPopupContainer={() => document.getElementById(`sfm-act-tab-${card.ticker}`)}
                            >
                              <i className="fal fa-plus-circle" onClick={this.toggleAddMoneyPopup}></i>
                            </Tooltip>
                          ) : null}
                        </span>
                      </div>
                    )}
                    {showPendingAccount && (
                      <div className="sfm-act-tab-acc-balance-wrapper">
                        <a href={'settings/tradingAccount'} style={{color:`#55a8e8!important`}}>Verification Pending</a>
                      </div>
                    )}
                    {noBankAccount && (
                      <div className="sfm-act-tab-acc-balance-wrapper">
                        <Link to={{ pathname: "/settings/tradingAccount", linkaccount: true }}>Link Bank Account</Link>
                      </div>
                    )}
                    <div className="broker-carousel-wrapper">
                      <ItemCarousel
                        {...this.props}
                        isDemoUser={isDemoUser}
                        isMutualFund={card.vehicle}
                        itemArray={brokers}
                        card={cn(card, 'Ticker')}
                        accountHandler={this.accountHandler}
                        AccountDropDownValue={AccountDropDownValue}
                        activeKey={activeKey}
                        handleActiveIndexKey={this.handleActiveIndexKey}
                        selectedBrokerInfo={this.selectedBrokerInfo}
                      />
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default ItemCard;

const BuySell = ({ value, setAction, currentHoldings }) => {
  // const [rSelected, setRSelected] = useState(value);
  // console.log('=== currentHoldings ===> ', currentHoldings);
  let onClick = (event) => {
    // if (setAction) {
      setAction(event.target.value);
    // }
  }
  // console.log('rSelected ==>', rSelected);
  return (
    <div>
      <Radio.Group onChange={onClick} value={value}>
        <Space direction="vertical">
          <Radio className={`${style.radioInput} ${value === "buy" ? "" : ""}`} value="buy">BUY</Radio>
          <Radio className={`${style.radioInput} ${value === "sell" ? "" : ""}`} value="sell" disabled={!currentHoldings}>SELL</Radio>
          <Radio className={`${style.radioInput} ${value === "sell_all" ? "" : ""}`} value="sell_all" disabled={!currentHoldings}>SELL TO $0</Radio>
        </Space>
      </Radio.Group>
      {/* <ButtonGroup className={'actionBS-group'}>
        <Button className={`actionBS ${value === 'buy' && 'actionBSActive'}`}  onClick={() => onClick('buy')} >Buy</Button>
        <Button className={`actionBS ${value === 'sell' && 'actionBSActive actionBSActiveSell'}`} onClick={() => onClick('sell')} >Sell</Button>
      </ButtonGroup> */}
    </div>
  );
}

const InvestMentTextCard = ({ currentHoldings, sharesValue, isSelling, minInvestment, prec, fundType }) => {
  let _minInvestment = (minInvestment !== null && !isNaN(minInvestment) && !isNaN(parseFloat( (minInvestment).toFixed(2) ))) ? parseFloat((minInvestment).toFixed(2)) : 5;
  // console.log('==== _minInvestment ====> ', _minInvestment);
  // console.log('==== currentHoldings =====>', currentHoldings);
  if(isSelling){
    return <div style={{ height: 68 }}>
        <p className={style.investmentPriceText} style={{ minWidth: '4rem', margin: '0 0 19px 0' }}>Current Holding</p>
        <div style={{ fontSize: '11px', lineHeight: '14px', marginBottom: 10 }}>
          <span class="text-gray-5">Value: </span> {currentHoldings || "$0"}
        </div>
        {fundType !== 'BASKET' ? (
          <div style={{ fontSize: '11px', lineHeight: '14px' }}>
            <span class="text-gray-5">Units: </span> {sharesValue ? Number(sharesValue.toFixed(5)) : "..."}
          </div>
        ) :null}
      </div>
  }else{
    return <div style={{ height: 68 }}>
      <p className={style.investmentPriceText} style={{ minWidth: '4rem', margin: '0 0 14px 0' }}>Minimum Investment</p>
      <h5 className={style.investmentPrice}>{!isNaN(minInvestment) ? `${numberFormatForAct(_minInvestment, 2)}` : "$0"}</h5>
    </div>
  }
}

const numberFormatForAct = (num, prec = 0) => {
  let _prec = (Number.isInteger(num)) ? 0 : prec;
  let amount = typeof num === "number"
    ? num.toLocaleString('en', {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: _prec,
        minimumFractionDigits: 0
      })
    : '';
  if (amount.includes('.00')) {
    amount = (amount.split('.00') && amount.split('.00').length > 0) ? amount.split('.00')[0] : amount;
    // let _amount = amount.split('$')
    // _amount = (_amount.length > 1 && _amount[1] && !isNaN(Number(_amount[1]))) ? `$${Number(_amount[1])}` : amount;
    // amount = _amount;
  }
  return amount;
}

const numberFormat = (num, prec = 2) =>
  typeof num === "number"
    ? num.toLocaleString('en', {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: prec,
        minimumFractionDigits: 0
      })
    : ''

const FormatCurrency = (value, prec = 0, curr = '$') => {
  if(value === 0){
    return `${curr}${value}`;
  }
  let _prefix = (value < 0 ? '-' : '');
  let [_value, _suffix, _prec] = [Math.abs(value), '', prec];

  if (_value >= 1e9)
    [_value, _suffix, _prec] = [_value/1e9, 'B', 2];
  else if (_value >= 1e6)
    [_value, _suffix, _prec] = [_value/1e6, 'M', 2];

  if (_value >= 100 && _prec >= 0) _prec = 0;
  else if (_value >= 10 && _prec >= 0) _prec = 1;

  return `${_prefix}${numberFormat(_value)}${_suffix}`
}

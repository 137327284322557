import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import  { Modal,Row, Tabs,notification } from 'antd'
import _ from 'lodash';
import { addEventToAnalytics } from 'Utils';

import { createLoadingSelector } from 'selectors/loading-selector';
import { customTickerRequest, setBuildPortfolioState } from 'actions/buildportfolio';

import CalculatorSlider from '../../../Elements/CalculatorSlider';
import CardLoader from '../../../Elements/CardLoader';
import ActTab from './ActTab';
import EquityTab from './EquityTab';
import BondsTab from './BondsTab';
import ThematicTab from './ThematicTab';

const { TabPane } = Tabs;
let isNoFundPopupShown = false
let ispopupBeingShown = false
class FolderTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabHeaderMenu: props.tabHeaderMenu,
      activeTab: 'equityTab',
    };
  }

  componentDidMount() {
    const { customTickerRequest, portfolioBatchRequest } = this.props;
    // if (customTickerRequest) {
    //   customTickerRequest();
    // }
    this.calculateSliderValue();
  }

  componentDidUpdate(prevProps) {
    if(this.props.tabHeaderMenu !== prevProps.tabHeaderMenu){
      // console.log(prevProps.tabHeaderMenu);
      // console.log(this.props.tabHeaderMenu);
      this.setState({tabHeaderMenu: this.props.tabHeaderMenu});
    }
    const { buildportfolio } = this.props;
    if (buildportfolio.summary.timeline === 'Act' && this.state.activeTab !== 'Act') {
      this.setState({
        activeTab: 'Act',
      });
    } else if (buildportfolio.summary.timeline !== 'Act' && this.state.activeTab === 'Act') {
      this.setState({
        activeTab: 'equityTab',
      });
    }
    if ( !isNoFundPopupShown && !ispopupBeingShown && this.props.buildportfolio.noResult ) {
      ispopupBeingShown= true
      notification.open({
        description: 'There are no fund holdings!',
        className: 'toastFail',
        duration: 4,
        message: null, top: 84,
        onClose:()=>{
          isNoFundPopupShown= true
        }
      })
    }

  }

  tabChange = (key) => {
    this.setState({ activeTab: key });
    const { setBuildPortfolioState } = this.props;
    if (setBuildPortfolioState) {
      addEventToAnalytics('Builder '+ key + ' Tab','Builder '+ key + ' Tab','BUILDER_'+key.toUpperCase()+'_TAB',{},false);
      setBuildPortfolioState({ activeTab: key });
    }
  }

  getTabHeaderDeign = (data) => {
    const {
      key,
      label,
      locked,
      showInput,
      inputNumberDisabled,
      sliderEnabled,
      sliderValue,
      sliderTooltipEnabled,
    } = data;

    let selectedFundCount = 0, tab = this.props.buildportfolio[key];
    if(tab && Object.keys(tab.selectedFunds).length){
      Object.keys(tab.selectedFunds).forEach((element, index) => {
        selectedFundCount += tab.selectedFunds[element].length;
      });
    }
    return (
      <div className="folder-tabs-header-wrap">
        <div className="text-label">
          <div className="label">{label}</div>
          <div className="slider-value">{ this.props.buildportfolio.summary.timeline === 'Act' ? parseFloat(this.props.buildportfolio.calculatedTabHeader[key].toFixed(2)) : parseFloat(sliderValue.toFixed(2)) }%</div>
        </div>
        <div className="selected-funds-text">
          {selectedFundCount} {selectedFundCount > 1 ? 'Funds': 'Fund'} Selected
        </div>
      </div>
    );
  }

  calculateSliderValue = (label, value) => {
    //filter the header reqquired for slider wt calc after the change
    //it may be less than the total in case of manual slider chnage
    //or it may be all if the components loads for the first time or weightingMethodology chnage

    let { weightingMethodology, setBuildPortfolioState } = this.props;
    let totalSum = 0.0, filterObjsTotalSum = 0.0, diff = 0.0;
    let filterObjs = [], unfilterObjs = [];

    //delete this code after testing the equal and manual mode
    // if(weightingMethodology == 'Equal') {
    //   filterObjs = JSON.parse(JSON.stringify(this.state.tabHeaderMenu));
    //   filterObjs.forEach((item,index) => {filterObjs[index].sliderValue = 1});
    //   console.log('sid eqaul', filterObjs)
    // }
    // else {
      this.state.tabHeaderMenu.forEach(e => {
        let item = JSON.parse(JSON.stringify(e));
        if(weightingMethodology == 'Equal') item.sliderValue = 1
        totalSum += item.sliderValue;
        if((item.label != label && item.locked == false) || weightingMethodology == 'Equal') { //filter condition
          filterObjsTotalSum += item.sliderValue;
          filterObjs.push(item);
        }
        else {
          unfilterObjs.push(item);
        }
      });
    // }

    if(filterObjs.length>0) {
      diff = 100 - totalSum;
      filterObjs.forEach((obj, index) => {
        let ratio = obj.sliderValue == 0 ? 0 : obj.sliderValue / filterObjsTotalSum;
        let wt = diff * ratio;
        filterObjs[index].sliderValue = Math.round(obj.sliderValue + wt);
      });

      filterObjs = _.sortBy(filterObjs.concat(unfilterObjs),['position']);
      this.setState({ tabHeaderMenu: filterObjs });
      if (setBuildPortfolioState) {
        setBuildPortfolioState({ tabHeaderMenu : filterObjs })
      }
    }
  }

  // lockChangeCallback = (props) => {
  //   if (typeof props.locked !== 'undefined' && props.label) {
  //     this.state.tabHeaderMenu.forEach((item) => {
  //       if (item.label === props.label) {
  //         item.locked = props.locked;
  //         item.sliderEnabled = props.locked;
  //       }
  //     });
  //     this.setState({ tabHeaderMenu : this.state.tabHeaderMenu });
  //   }
  // }

  getTabBodyContent = (data) => {
    const { buildportfolio } = this.props;
    const { actTab, equityTab, bondTab, thematicTab, summary } = buildportfolio;
    const { label } = data;
    const tabBodyWillBe = summary.timeline === 'Act' ? summary.timeline : label;
    switch (tabBodyWillBe) {
      case 'Equity':
        return (
          <EquityTab
            {...data}
            weightingMethodology={equityTab.weightingMethodology}
            tabHeaderMenu={this.state.tabHeaderMenu}
            activeTab={this.state.activeTab}
          />
        );
        break;
      case 'Bonds':
        return (
          <BondsTab
            {...data}
            weightingMethodology={equityTab.weightingMethodology}
            tabHeaderMenu={this.state.tabHeaderMenu}
            activeTab={this.state.activeTab}
          />
        );
        break;
      case 'Thematic Exposure':
        return (
          <ThematicTab
            {...data}
            weightingMethodology={equityTab.weightingMethodology}
            tabHeaderMenu={this.state.tabHeaderMenu}
            activeTab={this.state.activeTab}
          />
        );
        break;
      default:
        // return <EquityTab />;
    }
  }

  render() {
    const { buildportfolio } = this.props;
    const { summary } = buildportfolio;
    const { tabHeaderMenu, activeTab } = this.state;
    // console.log(tabHeaderMenu);

    return (
      <React.Fragment>
        <Row className="folder-container">
          <Tabs
            className="folder-tabs-wrapper"
            type="card"
            activeKey={activeTab}
            onChange={this.tabChange}
          >
            {
              tabHeaderMenu.map((item) => {
                return (
                  <TabPane
                    forceRender
                    disabled={summary.timeline === 'Act'}
                    tab={this.getTabHeaderDeign(item)}
                    key={item.key}
                  >
                    {this.getTabBodyContent(item)}
                  </TabPane>
                );
              })
            }
          </Tabs>
          {(summary.timeline === 'Act') && (
              <ActTab {...this.props}/>
            )
          }
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ buildportfolio, loading }) => ({
  buildportfolio,
  tabHeaderMenu: buildportfolio.tabHeaderMenu,
  weightingMethodology: buildportfolio.weightingMethodology,
  // updateLoading: createLoadingSelector(['SET_SUMMARY'])({ loading }),
})

const mapDispatchToProps = {
  customTickerRequest,
  setBuildPortfolioState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FolderTab);

import React, { Component } from 'react';
import { connect } from 'react-redux';
// import moment from 'moment';
// import { uniqBy } from 'lodash';
import { Card } from 'antd';

import { getPortfolioDataByIDRequest, setEnhancerState, useSuggestedFundsRequest, loadCPMListRequest, downloadCurrentPortfolioReport, } from 'actions/enhancer';
// import { addFundToSFM, setSFMCatData, refreshSFMState  } from 'actions/sfm';
// import { setFundsState } from 'actions/funds';
import { getSummaryFunds } from 'selectors/enhancer';
import { createLoadingSelector } from 'selectors/loading-selector';

import CustomAlert from 'components/CustomAlert';
// import { cn } from 'DataSet';
import { getPortfolioBenchmarkOfSelectedTicker } from 'Utils'

import Board from './Board';
import ContentHead from './ContentHead';
import PortfolioModal from '../../Elements/PortfolioModal';
import { geopMeanCalc } from '../../../AccountsPage/utils'
import { addEventToAnalytics } from 'Utils'


class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      summaryDrawer: props.summaryDrawer,
    };
  }

  componentDidMount() {
    // console.log('========>>>>>> summary page componentDidMount <<<<<<========');
    const { location, match, getPortfolioDataByIDRequest, loading } = this.props;
    try {
      if (match && !loading) {
        // const id = match.params.id
        if (location && location.search) {
          const params = new URLSearchParams(location.search)
          if (params) {
            const portfolioName = params.get('portfolioId');
            const portfolioType = params.get('portfolioType');
            const sponsoredPortfolioName = params.get('sponsoredPortfolioName');
            // console.log(sponsoredPortfolioName);
            if (portfolioName &&
              portfolioType &&
              getPortfolioDataByIDRequest &&
              portfolioName !== 'undefined' &&
              portfolioType !== 'undefined') {
              let requestPayloadWillBe = {
                filename: portfolioName,
                portfolioType: portfolioType,
              };

              if (portfolioType === 'MarketPlace') {
                if (sponsoredPortfolioName && typeof sponsoredPortfolioName !== 'undefined' && sponsoredPortfolioName !== '' && sponsoredPortfolioName !== null) {
                  requestPayloadWillBe = {
                    filename: portfolioName,
                    portfolioType: portfolioType,
                    sponsoredPortfolioName
                  };
                  // getPortfolioDataByIDRequest(requestPayloadWillBe, (res) => {
                  //   console.log('-- Portfolio Holdings --');
                  //   console.log(res);
                  //   console.log('------------------------');
                  // });
                } else {
                  throw 'sponsoredPortfolioName not found in url'
                }
              }
              // else {
              //   getPortfolioDataByIDRequest(requestPayloadWillBe, (res) => {
              //     console.log('-- Portfolio Holdings --');
              //     console.log(res);
              //     console.log('------------------------');
              //   });
              // }

              const { PORTFOLIO_ID, CLIENT_LIST, loadCPMListRequest } = this.props;
              if ((PORTFOLIO_ID === null ||
                typeof PORTFOLIO_ID === 'undefined' ||
                PORTFOLIO_ID === '' ||
                CLIENT_LIST === null ||
                CLIENT_LIST === '' ||
                !CLIENT_LIST ||
                CLIENT_LIST.length === 0 ||
                typeof CLIENT_LIST === 'undefined'
              ) && loadCPMListRequest) {
                loadCPMListRequest({
                  portfolioName,
                  portfolioType,
                }, () => {
                  getPortfolioDataByIDRequest(requestPayloadWillBe, (res) => {
                    console.log('-- Portfolio Holdings --');
                    console.log(res);
                    console.log('------------------------');
                  });
                });
              } else {
                getPortfolioDataByIDRequest(requestPayloadWillBe, (res) => {
                  console.log('-- Portfolio Holdings --');
                  console.log(res);
                  console.log('------------------------');
                });
              }

            } else {
              throw 'data not found in url'
            }
          } else {
            throw 'data not found in url'
          }
        } else {
          throw 'data not found in url'
        }
      }
    } catch (e) {
      console.log(e);
      const { history } = this.props;
      if (history) {
        const newPath = `/enhancer`
        history.push(newPath)
      }
    }
  }

  componentDidUpdate() {
    const headerElement = document.getElementById("magnifi-header-wrapper");
    const portfolioContentHeaderElement = document.getElementById("funds-board-content-header");
    if (portfolioContentHeaderElement && headerElement) {
      const portfolioSummaryDrawerElement = document.getElementById("summaryDrawerElement");
      if (portfolioSummaryDrawerElement) {
        const drawerxContentElement = portfolioSummaryDrawerElement.getElementsByClassName("ant-drawer-content-wrapper")[0];
        drawerxContentElement.style.top = portfolioContentHeaderElement.offsetHeight+15+'px';
      }
    }
  }

  openDrawer = (visible) => {
    if(visible == true)
      addEventToAnalytics('Enhancer Summary','Enhancer Summary','ENHANCER_SUMMARY',{},false);

    // console.log('--- drawer button clicked ---', visible);
    this.setState({
      summaryDrawer: visible,
    });
  }

  handleUpDownDropDownChange = (value) => {
    const { setEnhancerState, performanceImpact } = this.props;
    const { enhancedPerformanceImpact, currentPerformanceImpact, upDownDataSet } = performanceImpact;
    if (setEnhancerState) {
      let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(upDownDataSet, value)
      // console.log(enhancedPerformanceImpact)
      if (Object.keys(enhancedPerformanceImpact).length>0) {
        let { pfRet1y, pfRet3y, pfRet5y } = enhancedPerformanceImpact
        let { pfRet1y:pfRet1yC,pfRet3y:pfRet3yC,pfRet5y:pfRet5yC } = currentPerformanceImpact
        let { up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr } = geopMeanCalc(portfolioBenchmark, pfRet1y, pfRet3y, pfRet5y)
        let { up_1yr:up_1yrC, down_1yr:down_1yrC, up_3yr:up_3yrC, down_3yr:down_3yrC, up_5yr:up_5yrC, down_5yr:down_5yrC } = geopMeanCalc(portfolioBenchmark, pfRet1yC, pfRet3yC, pfRet5yC)
        let updateEnhanceStats = { ...enhancedPerformanceImpact, up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr}
        let updatedCurrentStats = { ...currentPerformanceImpact, up_1yr:up_1yrC, down_1yr:down_1yrC, up_3yr:up_3yrC, down_3yr:down_3yrC, up_5yr:up_5yrC, down_5yr:down_5yrC}
        setEnhancerState({
          performanceImpact: {
            ...performanceImpact,
            selectedBenchmark: value,
            currentPerformanceImpact: updatedCurrentStats,
            enhancedPerformanceImpact: updateEnhanceStats,
          }
        });
      } else {
        let { pfRet1y, pfRet3y, pfRet5y } = currentPerformanceImpact
        let { up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr } = geopMeanCalc(portfolioBenchmark, pfRet1y, pfRet3y, pfRet5y)
        let updatedCurrentStats = { ...currentPerformanceImpact, up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr}
        setEnhancerState({
          performanceImpact: {
            ...performanceImpact,
            selectedBenchmark: value,
            currentPerformanceImpact: updatedCurrentStats
          }
        });
      }
    }
  }

  // auto open/close sfm window
  modalToggle = (modalToggleState) => {
    const { setSFMState } = this.props;
    if (modalToggleState === 'add') {
      setSFMState({
        modalToggleState: true,
        modal: true,
      });
    } else if (modalToggleState === 'remove') {
      setSFMState({
        modalToggleState: false,
        modal: false,
      });
    }
  }

  // // Set 'Select' button state for Funds/SFM reducers
  // selectHandler(item, index) {
  //   // this.state.data: ->  mark the item in main list if exist
  //   // this.state.items: -> mark the item in sfm list if exist
  //   // console.log('sele ctHandler clicked');
  //   // console.log(item, index);
  //
  //   const { setSFMState, setFundsState, summary } = this.props;
  //   const { retData } = summary;
  //   // console.log(retData);
  //
  //   const { items, data } = this.state;
  //   if (item === undefined) {
  //     this.state.items.forEach(d => { d._selected = false; d._order = undefined } );
  //     data.forEach(d => { d._selected = false; d._order = undefined });
  //     this.modalToggle('remove');
  //     setSFMState({
  //       items: [],
  //       modal: false,
  //     });
  //     setFundsState({
  //       data,
  //     });
  //     const { refreshWeightState } = this.props;
  //     refreshWeightState();
  //     return;
  //   }
  //
  //   const foundT = retData.find(e => cn(e, 'Ticker') === item.ticker);
  //   if (foundT) {
  //     // console.log(foundT, foundT._selected);
  //     foundT._selected = !foundT._selected;
  //   }
  //
  //   item._selected = !item._selected;
  //
  //   if (this.state.data && this.state.data.length) {
  //     let _item = this.state.data.find(e => cn(e, 'Ticker') === cn(item, 'Ticker'));
  //     if (_item) _item._selected = item._selected;
  //     setFundsState({
  //       data: this.state.data,
  //     });
  //   }
  //
  //   if (item._selected) {
  //     let selectedCards = this.state.items.slice();
  //     index !== undefined ? selectedCards.splice(index, 0, item) : selectedCards.push(item);
  //     setSFMState({
  //       items: selectedCards,
  //     });
  //   } else {
  //     setSFMState({
  //       items: this.state.items.filter((e) => cn(e, 'Id') !== cn(item, 'Id')),
  //     });
  //     this.setState({ items: this.state.items.filter((e) => cn(e, 'Id') !== cn(item, 'Id')) }, () => {
  //       if (this.state.items.length === 0) {
  //         this.modalToggle('remove');
  //       }
  //     });
  //     item._order = undefined;
  //   }
  // }

  // SFM V-2 logic
  // Set 'Select' button state for Funds/SFM reducers
  // selectHandler(item, index) {
  //   console.log('selectHandler ---- index.js v2');
  //   // const { setSFMState, setFundsState, summary } = this.props;
  //   // const { retData } = summary;
  //   //
  //   // const foundT = retData.find(e => cn(e, 'Ticker') === item.ticker);
  //   // if (foundT) {
  //   //   // console.log(foundT, foundT._selected);
  //   //   foundT._selected = !foundT._selected;
  //   // }
  //   //
  //   // item._selected = !item._selected;
  //   //
  //   // //V-2 logic
  //   // console.log(item);
  //   // if (item === undefined || typeof item === 'undefined') {
  //   //   // DELETE ALL SFM FUNDS/ITEMS
  //   //   console.log('=====> DELETE ALL SFM FUNDS/ITEMS <=====');
  //   // } else {
  //   //   // NOTE: Step 1: Add item-> Ticker in SFM ->`selectedItemTickers`
  //   //   // Step 2: using ticker name SFM look into FUNDS reducer >
  //   //   // if ticker already present in funds reducer state then use that data else Call API to fetch ticker data in SFM
  //   //   const { sfmSelectedItemTickers, addSFMItemTickersRequest, removeSFMItemTickersRequest } = this.props;
  //   //   const alreadyPresent = sfmSelectedItemTickers.find((l) => l === item.ticker)
  //   //   if (alreadyPresent) {
  //   //     // un_select logic
  //   //     console.log('-- un_select logic --');
  //   //     if (removeSFMItemTickersRequest) {
  //   //       removeSFMItemTickersRequest({
  //   //         removeTicker: item.ticker
  //   //       })
  //   //     }
  //   //   } else {
  //   //     // select logic
  //   //     console.log('-- select logic --');
  //   //     if (addSFMItemTickersRequest) {
  //   //       addSFMItemTickersRequest({
  //   //         selectedItemTickers: item.ticker
  //   //       })
  //   //     }
  //   //   }
  //   // }
  // }

  generateProposalClick = () => {
    this.generateCurrentPortfolioReport();
  }

  generateCurrentPortfolioReport = () => {
    addEventToAnalytics('Current Portfolio Report','Current Portfolio Report','CURRENT_PORTFOLIO_REPORT',{query_uuid:this.props.query_uuid},false);//

    const { enhancer, summary, profile, downloadCurrentPortfolioReport } = this.props;
    let logo = profile.profile.custom_report_logo ? profile.profile.custom_report_logo : '';
    let { fundUniverse, modelUniverse } = enhancer;
    if (enhancer.performanceImpact && enhancer.performanceImpact.currentPerformanceImpact) {
      const obj = {
        investment: {
          portfolioValue: enhancer.portfolioValue
        },
        portfolio: {
          query: enhancer.query,
          query_english: enhancer.query_english,
          currentPerformanceImpact: enhancer.performanceImpact.currentPerformanceImpact, // enhancer.finalizeData.finalizePerformanceImpact.currentPerformanceImpact,
          selectedBenchmark:enhancer.performanceImpact.selectedBenchmark,
          logo
        },
        portfolioName: enhancer.portfolioName,
        fundUniverse,
        modelUniverse,
        summary: {
          portfolioFunds: summary.portfolioFunds,
          invalidTickerFunds: summary.invalidTickerFunds,
          tickerNotSupported: summary.tickerNotSupported
        },
        disclosures: profile ? profile.profile.disclosures : '',
        advisory_fees: profile.profile ? profile.profile.advisory_fees : 0,
      };
      downloadCurrentPortfolioReport(obj);
    }
  }

  onAlertDismiss = () => {
    const { setEnhancerState } = this.props
    setEnhancerState({
      pdfReportDownloadStatus: {
        message: '',
        downloadStatus: ''
      }
    });
  }

  render() {
    const {
      enhancer,
      loading,
      loadingCPM,
      fundPortfolios,
      query,
      query_uuid,
      currentTargetCard,
      // selectedWeightOptions,
      // queryEnglish,
      // searchTypeMode,
      performanceImpact,
      enhanceTickerFilter,
      enhanceReplace,
      summary,
      portfolioName,
      onboardData,
      SuggestedFundsData,
      usingSuggestedFunds,
      currentPortlioLoading,
      performanceImpactLoading,
      pdfReportDownloadStatus
    } = this.props;

    const { currentPerformanceImpact, enhancedPerformanceImpact, upDownDataSet, selectedBenchmark } = performanceImpact;
    const { summaryDrawer } = this.state;
    const { portfolioFunds } = summary;

    // console.log(getSummaryFunds(enhancer));
    return (
      <React.Fragment>
        {!loading && (
          <div className="summary-container">
            {portfolioFunds && portfolioFunds.length > 0 ? (
              <div>
                <ContentHead
                  {...this.props}
                  summaryDrawer={summaryDrawer}
                  openDrawer={this.openDrawer}
                  generateProposalClick={this.generateProposalClick}
                  performanceImpactLoading={performanceImpactLoading}
                />
                <Board
                  summary={summary}
                  funds={portfolioFunds}
                  onboardData={onboardData}
                  retData={summary.retData}
                  history={this.props.history}
                  portfolioName={portfolioName}
                  activeFund={summary.activeFund}
                  currentTargetPortfolio={currentTargetCard}
                  loading={loading || performanceImpactLoading || currentPortlioLoading}
                  fundStats={getSummaryFunds(enhancer)}
                />
              </div>
            ) : (
              (loadingCPM) ? (
                <Card className="card-background-grey empty-message-wrapper no-funds-wrapper">
                  <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5" style={{ fontSize: '2em' }}></i>
                  <div className="text-box">
                    <span>Data is currently being calculated</span>
                  </div>
                </Card>
              ) : (
                <Card className="card-background-grey empty-message-wrapper no-funds-wrapper">
                  <img src='/assets/images/icons/NoFundsFound.svg' alt='' />
                  <div className="text-box">
                    <span>No DATA</span> were found for portfolio <span>{portfolioName}</span>.
                  </div>
                </Card>
              )
            )}
            {(
              <PortfolioModal
                closable
                mask={false}
                enhancePortfolioVisible={true}
                replacementToggleEnable={false}
                summaryPage={true}
                query={query}
                query_uuid={query_uuid}
                visible={summaryDrawer}
                loading={performanceImpactLoading}
                portfolioEnhanceReplace={enhanceReplace}
                enhanceStats={enhancedPerformanceImpact}
                currentStats={currentPerformanceImpact}
                enhanceTickerFilter={enhanceTickerFilter}
                portfolioBenchmarkData={upDownDataSet}
                selectedPortfolioBenchmarkTicker={selectedBenchmark}
                usingSuggestedFunds={usingSuggestedFunds}
                SuggestedFundsData={SuggestedFundsData}
                openDrawer={this.openDrawer}
                handleUpDownDropDownChange={this.handleUpDownDropDownChange}
                toggleChange={this.toggleChange}
                setPfEnhancement={this.setPfEnhancement}
                evaluateOnChange={this.evaluateOnChange}
                setPortfolioState={this.props.setPortfolioState}
                replacePortfolioToggle={this.props.replacePortfolioToggle}
                useSuggestedFundsRequest={this.props.useSuggestedFundsRequest}
                originalQueryVar={fundPortfolios && fundPortfolios.length > 0 && fundPortfolios[0].result.query_var}
              />
            )}
          </div>
        )}
        {(pdfReportDownloadStatus && pdfReportDownloadStatus.message !== '' && pdfReportDownloadStatus.downloadStatus !== '') && (
          <CustomAlert className="alert-windown-wrapper" color={pdfReportDownloadStatus.downloadStatus} onDismissCallback={this.onAlertDismiss}>
            {pdfReportDownloadStatus.message}
          </CustomAlert>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ loading, enhancer, profile, portfolio }) => ({
  profile,
  enhancer,
  summary: enhancer.summary,
  query: enhancer.query,
  PORTFOLIO_ID: enhancer.portfolioId,
  CLIENT_LIST: enhancer.clientList,
  query_uuid:enhancer.query_uuid,
  onboardData: enhancer.onboardData,
  summaryDrawer: enhancer.summaryDrawer,
  portfolioName: enhancer.portfolioName,
  fundPortfolios: enhancer.fundPortfolios,
  queryEnglish: enhancer.query_english,
  searchTypeMode: enhancer.searchTypeMode,
  guidanceMessage: enhancer.guidanceMessage,
  performanceImpact: enhancer.performanceImpact,
  enhanceReplace: enhancer.enhanceReplace,
  enhanceTickerFilter: enhancer.enhanceTickerFilter,
  selectedWeightOptions: portfolio.selectedWeightOptions,
  SuggestedFundsData: enhancer.useSuggestedFunds || [],
  usingSuggestedFunds: enhancer.usingSuggestedFunds,
  pdfReportDownloadStatus: enhancer.pdfReportDownloadStatus,
  currentTargetCard: enhancer.currentTargetCard,
  loadingCPM: createLoadingSelector(['LOAD_CPM_LIST'])({ loading }),
  loading: createLoadingSelector(['GET_PORTFOLIO_BY_ID'])({ loading }),
  currentPortlioLoading: createLoadingSelector(['GET_CURRENT_PORTFOLIO_STATS'])({ loading }),
  performanceImpactLoading: createLoadingSelector(['GET_CURRENT_PORTFOLIO_STATS', 'GET_ENHANCED_STATS_FOR_SELECTED_TICKER'])({ loading }),
})

const mapDispatchToProps = {
  getPortfolioDataByIDRequest,
  setEnhancerState,
  useSuggestedFundsRequest,
  loadCPMListRequest,
  downloadCurrentPortfolioReport,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Summary);

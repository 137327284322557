import React, { useState } from 'react';
import { Alert, Modal, ModalBody } from 'reactstrap';

const CustomAlert = (props) => {
  const [visible, setVisible] = useState(true);
  const [interval, setInterval] = useState(3000);

  const onDismiss = () => {
    if (props.onDismissCallback) {
      props.onDismissCallback();
    }
    setVisible(false);
  };

  if (props.interval) {
    setTimeout(function () {
      onDismiss();
    }, props.interval);
  } else {
    setTimeout(function () {
      onDismiss();
    }, 3000);
  }

  return (
    <Modal modalClassName="alert-window-modal-class" className="alert-window-modal" isOpen={visible} toggle={onDismiss} zIndex={props.zIndex}>
      <ModalBody>
        <Alert className={props.className} color={props.color || 'info'} isOpen={visible} toggle={onDismiss}>
          {props.children}
        </Alert>
      </ModalBody>
    </Modal>
  );
}

export default CustomAlert;

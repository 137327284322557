import React from 'react';
import { fixedValue, handleNoData, mul100 } from '../shared/Utils'
import BestTickerBadge from '../shared/BestTickerBadge';

const UpDownTable = (props) => {
  const { selFunds, bestTicker, benchmark } = props;
  return (
    <table className="updown-table">
      <thead>
        <tr className="updown-tbl-header">
          <th
            rowspan="2"
            colspan="1"
            style={{
              minWidth: '110px',
              width: '110px',
              maxWidth: '110px',
              textAlign: 'left',
              paddingLeft: `20px`
            }}
          >
            Funds <p style={{ fontSize: `13px` }}></p>
          </th>
          <th>
            Drawdown <span className={'smallText'}>(%)</span>
          </th>
          <th colspan="2">
            # of Months
          </th>
          <th colspan="2">
            Average Returns <span className={'smallText'}>(%)</span>
          </th>
          <th colspan="2">
            Avg Returns <span className={'smallText'}>(%)</span> vs Comparison ETF (SPY)*
          </th>
          {/*<th colspan="2">
            1 - Year (%)
          </th>*/}
          <th colspan="2">
            Month <span className={'smallText'}>(%)</span>
          </th>
        </tr>
        <tr className="updown-tbl-sub-header">
          <th className="border-rt"></th>
          <th>Up</th>
          <th className="border-rt">Down</th>
          <th>Up Months</th>
          <th className="border-rt">Down Months</th>
          <th>Up Market</th>
          <th className="border-rt">Down Market</th>
          {/*<th>Best</th>
          <th className="border-rt">Worst</th>*/}
          <th>Best</th>
          <th className="border-rt">Worst</th>
        </tr>
      </thead>
      <tbody>
        {selFunds.map((item, index) => {
          return (
            <tr key={index}>
              <td className="border-lt-rt">
                {item.ticker_short || '-'}
                {(item.ticker_short && item.ticker_short === bestTicker) && (
                  <BestTickerBadge style={{ paddingLeft: "10px" }} />
                )}
                {/* <p style={{ fontSize: `11px`, margin: 0, opacity: `0.6` }}>{benchmark === "MPT" ? item.benchmark_primary_index : item.category_proxy_name}</p> */}
              </td>
              <td className="border-rt">{handleNoData(fixedValue(mul100(item.drawdown_3y)))}</td>
              <td>{handleNoData(fixedValue(item.months_up))}</td>
              <td className="border-rt">{handleNoData(fixedValue(item.months_down))}</td>
              <td>{handleNoData(fixedValue(mul100(item.average_up_ret)))}</td>
              <td className="border-rt">{handleNoData(fixedValue(mul100(item.average_down_ret)))}</td>
              <td>{handleNoData(benchmark === "MPT" ? fixedValue(mul100(item.average_mkt_up_ret)) : fixedValue(mul100(item.average_mkt_up_ret_category)))}</td>
              <td className="border-rt">{handleNoData(benchmark === "MPT" ? fixedValue(mul100(item.average_mkt_down_ret)) : fixedValue(mul100(item.average_mkt_down_ret_category)))}</td>
              {/*<td>{handleNoData(fixedValue(item.best_year_ret))}</td>
              <td className="border-rt">{handleNoData(fixedValue(item.worst_year_ret) ||)td>*/}
              <td>{handleNoData(fixedValue(mul100(item.best_month_ret)))}</td>
              <td className="border-rt">{handleNoData(fixedValue(mul100(item.worst_month_ret)))}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default UpDownTable;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import { Formik } from 'formik'
import * as Yup from 'yup'

import { REDIRECT_DOMAIN_PATH } from 'DataSet';
import ForgotPasswordPage from './ForgotPasswordMsg';
import { sendChangePwdEmail } from 'actions/auth';

const magnifiLogo = "/assets/images/magnifi_by_tifin.svg";
const magnifiInvestResponsiblyLogo = '/assets/images/register-magnifi-invest-resp-logo.svg';

class ForgotPwd extends Component {

      state = {
        verifyEmailErrMsg: ''
      }

      initialValues = {
          email: ''
        }

      componentDidMount() {
        document.getElementsByTagName('body')[0].style.paddingTop = 0;
      }

      verifyEmaildStatus = (status, msg = '' )=> {
        if (status === 'failure') this.setState({ verifyEmailErrMsg: msg.message });
      }

      handleSubmit = (values, { setSubmitting }) => {
        this.props.sendChangePwdEmail(values, this.verifyEmaildStatus);
        this.setState({ verifyEmailErrMsg: '' });
        setSubmitting(false);
      }

      registerSchema = Yup.object().shape({
        email: Yup.string()
          .email('Email must be a valid email')
          .required('Email is a required field')
      })

  render() {

    let renderDesign = (
        <Formik
        initialValues={this.initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={this.registerSchema}
        render={props => (
          <ForgotPasswordPage
          formProps={props}
          error={this.props.error}
          verifyEmailErrMsg={this.state.verifyEmailErrMsg}
          />
        )}
      />
    )

      return (
      <React.Fragment>
          <Row className="magnifi-auth-page-wrapper">
          <Col md="6" lg="6" className="left-side-panel-container">
            <a className="logo-wrapper" href={REDIRECT_DOMAIN_PATH}>
              <img src={process.env.REACT_APP_SUB_DOMAIN === 'retail' ? magnifiInvestResponsiblyLogo : magnifiLogo} height={70} alt="magnifi" />
            </a>
          </Col>
          <Col md="6" lg="6" className="right-side-panel-container">
            <Link className="back-to-page" to="/login">
              <i className="fas fa-long-arrow-left"></i>
            </Link>
            {renderDesign}
          </Col>
            </Row>
        </React.Fragment>
      )
  }
}

const mapStateToProps = ({  }) => ({

})

const mapDispatchToProps = {
  sendChangePwdEmail,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ForgotPwd))

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Badge, Menu, Dropdown, Button, Input } from 'antd';
import { DownOutlined, UserOutlined, RightOutlined } from '@ant-design/icons';
import { get } from 'lodash'
import { UserMenu } from 'components/user/UserMenu'
import { REDIRECT_DOMAIN_PATH } from 'DataSet';
import * as AuthUtils from 'common/auth-utils'
import { connect } from 'react-redux';
import {fetchProfile} from 'apis/profile';
import { setSFMState } from '../../../actions/sfm';
import { uploadClientListV2 } from 'actions/clientportfolioV2';
import { csvToJSON, addEventToAnalytics } from 'Utils';


// const Logo = '/assets/images/MagnifiLogo/Magnifi_Logo_Charcoal_RGB.png'
const styles = {
  '1024':{ width:'72%', paddingLeft: '18px', marginLeft: 'auto' },
  '1920':{ width:'57%', paddingLeft: '8px' },
  'default': { width:'72%', paddingLeft: '4px', marginLeft: 'auto' }
}

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      screenSize: null
    }
  }

  componentDidMount(){
    this.setState({screenSize: window.screen.width});
  }

  redirect = () =>{
    this.props.history.push('/settings/referral?invite=true');
  }

  redirectToAccount = () =>{
    addEventToAnalytics('Header Enhancer','Header Enhancer','HEADER_ENHANCER',{url: window.location.href},false);
    if(!this.props.loggedIn) {
      this.props.onHeaderButtonsClick();
      return;
    }
    this.props.history.push('/investments');
  }

  redirectToBuildPortfolio = () => {
    addEventToAnalytics('Header Builder','Header Builder','HEADER_BUILDER',{url: window.location.href},false);
    if(!this.props.loggedIn) {
      this.props.onHeaderButtonsClick();
      return;
    }
    this.props.history.push('/build-portfolio');
  }

  redirectToSearchResult = () => {
    addEventToAnalytics('Header Selector','Header Selector','HEADER_SELECTOR',{url: window.location.href},false);
    const { sfmModalToggle } = this.props;
    sfmModalToggle();
  }

  demoUpload = () => {
    // Read data from CSV instead of json dump
    fetch('/assets/data/demo_client_accounts.csv')
      .then(response => response.text())
      .then(data => {
        const demoClients = csvToJSON(data);
        const { uploadClientListV2 } = this.props;
        if (uploadClientListV2) {
          uploadClientListV2(demoClients);
          this.redirectToAccounts(true);
        }
      })
      .catch(error => console.error(error));
  }

  clientPortfolioClickHandler = (type) => {
    if(!this.props.loggedIn) {
      this.props.onHeaderButtonsClick();
      return;
    }

    if (type === 'uploadFile') {
      document.getElementById('uploadCustomPortfolio').click();
    } else if (type === 'demo') {
      addEventToAnalytics('Client Add Demo','Client Add Demo','CLIENT_ADD_DEMO',{},false);
      this.demoUpload();
    }

  }

  redirectToAccounts = () => {
    addEventToAnalytics('Header Client Portfolio','Header Client Portfolio','HEADER_CLIENT_PORTFOLIO',{url: window.location.href},false);
    this.props.history.push('/advisor/account');
  }

  downloadSampleFile = () => {
    addEventToAnalytics('Client Add Download','Client Add Download','CLIENT_ADD_DOWNLOAD',{},false);
    document.getElementById('mock-file').click();
  }

  render() {
    const { height, logo, sfmItems, resultPageCart, client_exist, user_advisor_menu, uploadClientListV2 } = this.props;
    const { screenSize } = this.state;
    const selectorCount = sfmItems.filter((v) => v._selector === true && v._selected === true).length;
    // const builderCount = sfmItems.filter((v) => v._builder === true).length;
    const builderCount = resultPageCart.length;

    const readCSV = (file) => {
      var reader = new FileReader();
      reader.onload = () => {
        const clientList = csvToJSON(reader.result)
        if (uploadClientListV2) {
          uploadClientListV2(clientList);
          addEventToAnalytics('Client Add Upload','Client Add Upload','CLIENT_ADD_UPLOAD',{},false);
          this.props.history.push('/advisor/account');
        }
      };
      // start reading the file. When it is done, calls the onload event defined above.
      reader.readAsBinaryString(file[0]);
    }

    const mediaScreen = window.matchMedia("(max-width: 1459px)");

    const menu = (
      <Menu>
        {mediaScreen.matches && (
          <Menu.Item key="none" className="disabled-events" disabled>
            <span className="menu-label" style={{ paddingRight: 20 }}>Client Portfolio</span>
          </Menu.Item>
        )}
        <Menu.Item
          key="uploadFile"
          icon={<img className="icon prefix-icon" src="/assets/images/upload-pf.svg" />}
        >
          <Input
            type="file"
            name="file"
            id="uploadCustomPortfolio"
            onChange={(e) => readCSV(e.target.files)} style={{ zIndex: 1 }}
          />
          <span style={{ paddingRight: 20 }}>Upload File</span>
          <img className="icon suffix-icon" src="/assets/images/download-pf.svg" onClick={this.downloadSampleFile} />
          <a href="/assets/data/mock_client_accounts.csv" id='mock-file' />
        </Menu.Item>
        <Menu.Item
          key="demo"
          onClick={() => this.clientPortfolioClickHandler('demo')}
          icon={<img className="icon prefix-icon" src="/assets/images/UseDemoFile-pf.svg" />}
        >
          <span style={{ paddingRight: 20 }}>Use Demo File</span>
          <RightOutlined className="suffix-icon" />
        </Menu.Item>
      </Menu>
    );

    const renderClientPortfolioMenuButton = !this.props.loggedIn ? (
      <Button
        className="client-portfolio-menu-button"
        onClick={this.props.onHeaderButtonsClick}
      >
        <i className="far fa-fw fa-list mr-2"></i> <span className="button-label">Client Portfolio</span> <DownOutlined style={{ fontSize: 10 }} />
      </Button>
    ) : ((user_advisor_menu && !client_exist) ? (
      <Dropdown
        getPopupContainer={() => document.getElementById('client-portfolio-menu')}
        overlayClassName="client-portfolio-menu-dropdown"
        overlay={menu}
        trigger={['click']}
      >
        <Button className="client-portfolio-menu-button">
          <i className="far fa-fw fa-list mr-2"></i> <span className="button-label">Client Portfolio</span> <DownOutlined />
        </Button>
      </Dropdown>
    ) : (
      <Button
        className="client-portfolio-menu-button"
        onClick={this.redirectToAccounts}
      >
        <i className="far fa-fw fa-list mr-2"></i> <span className="button-label">Client Portfolio</span>
      </Button>
    ));

    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-md navbar-dark bg-c5 py-0 shadow-0 navBarWrapper">
          <div className="container py-1">
            <a className="navbar-brand logoWrapper" href={REDIRECT_DOMAIN_PATH}>
              <img
                src={logo}
                width={height ? '' : '162'}
                height={height}
                alt="magnifi"
                />
            </a>
            <>
              <div className="d-flex justify-content-start portfolio-tools-wrap" style={screenSize <= 1024 ? styles['1024'] : screenSize >=1920 ? styles['1920'] : styles['default']}>
                {window.screen.width >= 1024 && [<span className='tools-txt search-result-tool-txt'>Portfolio Tools:</span> ,
                <Badge className='tool-badge selected search-result-tool-badge'><a className='tools-link'><img src='/assets/images/icons/Discover-White.svg' alt='' />Discovery</a></Badge>,
                <Badge count={selectorCount} className='tool-badge search-result-tool-badge'><a className='tools-link' onClick={this.redirectToSearchResult}><img src='/assets/images/icons/Selector.svg' alt='' />Selector</a></Badge>,
                <Badge count={builderCount} className='tool-badge search-result-tool-badge'><a className='tools-link' onClick={this.redirectToBuildPortfolio}><img src='/assets/images/icons/New-BuilerIcon-Blue.svg' alt='' /><span>Builder</span></a></Badge>,
                <Badge className='tool-badge search-result-tool-badge'><a className='tools-link' onClick={this.redirectToAccount}><img src='/assets/images/icons/Enhancer.svg' alt='' />Enhancer</a></Badge>]}
               </div>
              {/*
                <div className="d-flex justify-content-end referral-wrap">
                <a className='referral-link' onClick={this.redirect}>Referrals</a>
                </div>
              */}
              <div
                id="client-portfolio-menu"
                className="d-flex justify-content-end client-portfolio-menu"
              >
                {renderClientPortfolioMenuButton}
              </div>
            </>
            <UserMenu
              className="user-menu-popover "
              onLogout={this.props.onLogout}
              isLoggedIn={this.props.isLoggedIn}
              showSinginIcon={this.props.isLoggedIn}
            />
            </div>
          </nav>
        </React.Fragment>
      )
    }
  }

  Header.propType = {
    onLogout: PropTypes.func.isRequired
  }

  const mapStateToProps = ({ auth: { user_advisor_menu, client_exist, loggedIn }, profile, sfm, buildportfolio }) => ({
    loggedIn,
    sfmItems: sfm.items,
    resultPageCart: buildportfolio.resultPageCart,
    logo: profile.logo,
    user_advisor_menu,
    client_exist,
  })

  const mapDispatchToProps = {
    // fetchProfile,
    setSFMState,
    uploadClientListV2,
  }

  // export default Header
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)

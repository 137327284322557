import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Card, Row, Col } from 'antd';
import { Modal, ModalBody, FormGroup } from 'reactstrap';
import { addEventToAnalytics } from 'Utils'

import {
  getModelPortfoliosByIdRequest,
  setEnhancerState,
  resetSummaryDataRequest,
  replaceCurrentModelPortfolioWithRequest,
  downloadEnhancerReport,
  downloadRecommendationEnhancerReport,
  finalizeModelPortfolioRequest,
  finalizeModelPortfolioSuccess,
  setEnhancedTransaction,
  savePortfolioRequest,
  resetFinalizeDataRequest,
  useSuggestedFundsRequest,
} from 'actions/enhancer';
import { setBuildPortfolioState } from 'actions/buildportfolio';

import { createLoadingSelector } from 'selectors/loading-selector';

import ContentHead from './ContentHead';
import EnhancePortfolio from './EnhancePortfolio';
import CardRow from './EnhancePortfolio/CardRow';
import PortfolioModal from '../../Elements/PortfolioModal';

import { REDIRECT_PATH } from 'DataSet';
import { getPortfolioBenchmarkOfSelectedTicker } from 'Utils'
import { geopMeanCalc } from '../../../AccountsPage/utils'
import { getFinalizeSummaryFunds } from 'selectors/enhancer'
import SummaryBoard from '../Summary/Board'

import { Loader } from '../../../AccountsPage/Elements/Loader'
import CustomAlert from 'components/CustomAlert'
import GenerateTradeList from '../Funds/Elements/GenerateTradeList'

class Models extends Component {
  constructor(props) {
    super(props);
    this.state = {
      summaryDrawer: false,
      fileName: '',
      generateTradeList: false,
    };
  }

  componentDidMount() {
    const {
      location,
      onboardData,
      history,
      setEnhancerState,
      match,
      getModelPortfoliosByIdRequest,
      portfolioName,
      loading,
      summary,
      performanceImpact,
      redirectFrom,
    } = this.props;
    this.executeQuery({
      location,
      onboardData,
      history,
      setEnhancerState,
      match,
      getModelPortfoliosByIdRequest,
      portfolioName,
      loading,
      summary,
      performanceImpact,
      redirectFrom,
    });
  }

  componentWillReceiveProps(nextProps) {
    const {
      location,
      onboardData,
      history,
      setEnhancerState,
      match,
      getModelPortfoliosByIdRequest,
      portfolioName,
      loading,
      summary,
      performanceImpact,
      redirectFrom,
    } = nextProps;
    // console.log(location);
    // console.log(location.pathname.includes('/enhancer/dashboard/models'));
    if (location !== this.props.location && location.search !== this.props.location.search && location.pathname && location.pathname.includes('/enhancer/dashboard/models')) {
      this.executeQuery({
        location,
        onboardData,
        history,
        setEnhancerState,
        match,
        getModelPortfoliosByIdRequest,
        portfolioName,
        loading,
        summary,
        performanceImpact,
        redirectFrom,
      });
    }
  }

  componentDidUpdate() {
    const headerElement = document.getElementById("magnifi-header-wrapper");
    const portfolioContentHeaderElement = document.getElementById("funds-board-content-header");
    if (portfolioContentHeaderElement && headerElement) {
      const portfolioSummaryDrawerElement = document.getElementById("summaryDrawerElement");
      if (portfolioSummaryDrawerElement) {
        const drawerxContentElement = portfolioSummaryDrawerElement.getElementsByClassName("ant-drawer-content-wrapper")[0];
        drawerxContentElement.style.top = portfolioContentHeaderElement.offsetHeight + 15 + 'px';
      }
    }
  }

  executeQuery = (data) => {
    const {
      location,
      onboardData,
      history,
      setEnhancerState,
      match,
      getModelPortfoliosByIdRequest,
      portfolioName,
      loading,
      summary,
      performanceImpact,
      redirectFrom,
    } = data;
    try {
      if (match && !loading) {
        const id = match.params.id
        if (location && location.search) {
          const params = new URLSearchParams(location.search)
          if (params) {
            const universe = params.get('universe');
            const weight = params.get('weight');
            const similarityMeasures = params.get('similarity-measures');
            const query = params.get('q');
            const portfolioId = params.get('portfolioId');
            const portfolioType = params.get('portfolioType');
            const tickers = params.get('tickers');
            const _redirectPath = params.get('callbackPath');

            if (universe &&
              similarityMeasures &&
              query &&
              universe !== 'undefined' &&
              query !== 'undefined' &&
              similarityMeasures !== 'undefined' &&
              portfolioId &&
              portfolioType &&
              getModelPortfoliosByIdRequest &&
              portfolioId !== 'undefined' &&
              portfolioType !== 'undefined' &&
              tickers &&
              tickers !== 'undefined') {

              let _activeSelectorUniverse = [];
              // if (weight && typeof weight !== 'undefined' && weight.length > 0) {
              //   console.log('-- weight --', weight);
              //   _activeSelectorUniverse = ['udc']; // hardcode value for Rank Selector toggle
              // }

              let _payloadRequest = {
                query,
                tickers,
                similarityMeasures,
                searchTypeMode: 'MODELS',
                modelUniverse: universe,
                activeModelUniverse: universe.split(','),
                activeFundUniverse: [],
                activeSelectorUniverse: _activeSelectorUniverse,
                portfolioName: portfolioId,
              }

              if (_redirectPath && (_redirectPath !== 'undefined' || _redirectPath !== '')) { // check present in URL
                if (REDIRECT_PATH[_redirectPath]) { // url has VALUE
                  if (Object.keys(redirectFrom).length > 0) { // check redirectFrom must present
                    _payloadRequest.redirect = REDIRECT_PATH[_redirectPath];
                  } else { // return back to '_redirectPath'
                    if (history) {
                      history.push(REDIRECT_PATH[_redirectPath])
                    }
                  }
                } else {
                  console.log('-- _redirectPath not present --');
                }
              } else {
                console.log('-- _redirectPath not present --');
              }

              if (summary && summary.portfolioFunds && summary.portfolioFunds.length === 0) {
                // current portfolio is empty then call apis to get data
                throw '-- summary data missing for model portfolio --'
              } else {
                // console.log(performanceImpact.currentPerformanceImpact);
                if (performanceImpact && performanceImpact.currentPerformanceImpact && Object.keys(performanceImpact.currentPerformanceImpact).length > 0) {
                  const { currentPerformanceImpact } = performanceImpact;
                  const { portfolioFunds, portfolioValue } = summary;
                  // map current portfolion data
                  if (portfolioType === 'MarketPlace') {
                    const sponsoredPortfolioName = params.get('sponsoredPortfolioName');
                    console.log(sponsoredPortfolioName);
                    if (sponsoredPortfolioName && typeof sponsoredPortfolioName !== 'undefined' && sponsoredPortfolioName !== '' && sponsoredPortfolioName !== null) {
                      _payloadRequest.sponsoredPortfolioName = sponsoredPortfolioName;
                      // setEnhancerState({
                      //   ..._payloadRequest,
                      //   onboardData: {
                      //     ...onboardData,
                      //     selectedPortfolioType: portfolioType,
                      //   }
                      // });
                      getModelPortfoliosByIdRequest({
                        ..._payloadRequest,
                        portfolioType: portfolioType,
                        onboardData: {
                          ...onboardData,
                          selectedPortfolioType: portfolioType,
                        },
                        myCurrentPortfolio: {
                          portfolioDetails: {
                            name: portfolioId,
                            profile: {
                              firstName: window.localStorage.getItem('firstName'),
                              lastName: window.localStorage.getItem('lastName'),
                            }, // do not change this
                            riskFeeReturn: currentPerformanceImpact,
                            chart: currentPerformanceImpact.asset_alloc,
                          },
                          holdings: _.orderBy(portfolioFunds, ['weight'], ['desc']),
                          portfolioValue: portfolioValue, // || 10000,
                        },
                      });
                    } else {
                      throw 'sponsoredPortfolioName not found in url'
                    }
                  } else {
                    // setEnhancerState({
                    //   ..._payloadRequest,
                    //   onboardData: {
                    //     ...onboardData,
                    //     selectedPortfolioType: portfolioType,
                    //   }
                    // });
                    getModelPortfoliosByIdRequest({
                      ..._payloadRequest,
                      portfolioType: portfolioType,
                      onboardData: {
                        ...onboardData,
                        selectedPortfolioType: portfolioType,
                      },
                      myCurrentPortfolio: {
                        portfolioDetails: {
                          name: portfolioId,
                          profile: {
                            firstName: window.localStorage.getItem('firstName'),
                            lastName: window.localStorage.getItem('lastName'),
                          }, // do not change this
                          riskFeeReturn: currentPerformanceImpact,
                          chart: currentPerformanceImpact.asset_alloc,
                        },
                        holdings: _.orderBy(portfolioFunds, ['weight'], ['desc']),
                        portfolioValue: portfolioValue, // || 10000,
                      },
                    });
                  }
                } else {
                  throw '-- performanceImpact missing for current portfolio --'
                }
              }
            } else {
              throw 'data not found in url'
            }
          } else {
            throw 'data not found in url'
          }
        } else {
          throw 'data not found in url'
        }
      }
    } catch (e) {
      console.log(e);
      this.routeToOnBoardScreen();
      // const { history } = this.props;
      // if (history) {
      //   const newPath = `/enhancer`
      //   history.push(newPath);
      // }
    }
  }

  routeToOnBoardScreen = () => {
    addEventToAnalytics('Enhancer Edit Home', 'Enhancer Edit Home', 'ENHANCER_EDIT_HOME', {}, false);
    const { history, resetSummaryDataRequest } = this.props;
    if (resetSummaryDataRequest && history) {
      resetSummaryDataRequest('', () => {
        const newPath = `/enhancer/onboard-screen`;
        history.push(newPath);
      })
    }
  }

  openDrawer = (visible) => {
    if (visible === true)
      addEventToAnalytics('Enhancer Summary', 'Enhancer Summary', 'ENHANCER_SUMMARY', {}, false);
    this.setState({
      summaryDrawer: visible,
    });
  }

  finalizeClick = () => {
    // console.log('call finalize api');
    const { enhancer, finalizeModelPortfolioRequest, finalizeModelPortfolioSuccess, onboardData, modelPortfolios } = this.props;
    const { myCurrentPortfolio } = modelPortfolios;
    const { summary } = enhancer;
    const requestData = enhancer.enhanceReplace[0];
    const replaceDataArr = requestData.selectedPortfolioFunds.holdings.map((o) => {
      return {
        ticker: o.h_ticker,
        shares: o._h_quantity,
        "purchase_price": o.nav,
        "purchase_date": moment(new Date()).format('DD-MM-YYYY'),
      };
    });
    const requestDataArr = summary && summary.portfolioFunds.length > 0 ? summary.portfolioFunds.map((l) => {
      return {
        ticker: l.ticker,
        shares: l.shares,
      }
    }) : [];
    // console.log(requestDataArr);
    let obj = {
      "portfolio_name": requestData.ticker, // current_portfolio's name
      "replace_portfolio_type": "custom_portfolio", // custom_portfolio/model_portfolio
      "replace_portfolio_id": requestData.replace, // model_portfolio's ticker
      "replace_data": replaceDataArr,
      // "data": requestDataArr,
    };
    if (onboardData.selectedPortfolioType === "MarketPlace" && myCurrentPortfolio && myCurrentPortfolio.holdings) {
      const holdsTickers = myCurrentPortfolio.holdings.map((h) => {
        return {
          ticker: h.ticker,
          shares: h.shares,
        }
      });
      // console.log(holdsTickers);
      if (holdsTickers) {
        obj.data = holdsTickers;
      }
    }

    finalizeModelPortfolioRequest({
      request: obj,
      enhanceCTA: 'Finalize',
    }, (response) => {
      // console.log('finalizePortfolioRequest executed');
      // console.log(response);
      if (response) {
        finalizeModelPortfolioSuccess({
          finalizeData: { ...response },
        });
      }
    });
  }

  generateProposalClick = (isRecommendation = false) => {
    console.log('call generateProposal api');
    isRecommendation ? this.downloadModelReport() : this.downloadEnhancerPDF()
  }

  generateTradeListClick = () => {
    console.log('call generateTradelist api');
    addEventToAnalytics('Enhancer Tradelist', 'Enhancer Tradelist', 'ENHANCER_TRADELIST', {}, false);
    this.tradeList()
  }

  tradeList = () => {
    this.setState({
      generateTradeList: !this.state.generateTradeList,
      alert: false,
    })
    const { finalizeData } = this.props;
    this.props.setEnhancedTransaction(finalizeData.portfolioName);
  }

  savePortfolioClick = () => {
    // console.log('open popup for portfolio name');
    const { savePortfolioRequest, finalizeData, onboardData, history, redirectToPath, redirectFrom, setEnhancerState, setBuildPortfolioState, resetFinalizeDataRequest } = this.props;
    try {
      if (finalizeData &&
        finalizeData.portfolioName &&
        finalizeData.summary &&
        finalizeData.onboardData &&
        finalizeData.enhancedPortfolioId &&
        finalizeData.onboardData.selectedPortfolioType &&
        typeof finalizeData.summary !== 'undefined' &&
        typeof finalizeData.summary.retData !== 'undefined' &&
        finalizeData.summary.retData.length > 0 &&
        typeof finalizeData.summary.portfolioFunds !== 'undefined' &&
        finalizeData.summary.portfolioFunds.length > 0) {

        const enhancedPortfolioId = finalizeData.enhancedPortfolioId || undefined;
        const portfolioType = finalizeData.onboardData.selectedPortfolioType || onboardData.selectedPortfolioType || 'My Portfolio';

        if (redirectFrom &&
          Object.keys(redirectFrom).length > 0 &&
          typeof redirectFrom.module !== 'undefined' &&
          redirectFrom.module === 'builder' &&
          typeof redirectFrom.savedPortfolio !== 'undefined' &&
          redirectFrom.portfolioName !== '' &&
          typeof redirectFrom.portfolioName !== 'undefined' &&
          resetFinalizeDataRequest &&
          setBuildPortfolioState &&
          setEnhancerState
        ) {
          let updatedPortfolio = {
            ...redirectFrom,
            portfolioName: redirectFrom.portfolioName,
            returnPortfolioID: enhancedPortfolioId
          }
          setBuildPortfolioState({
            portfolioName: redirectFrom.portfolioName,
            savedPortfolio: {
              ...redirectFrom.savedPortfolio,
              portfolio_id: enhancedPortfolioId,
            },
          });
          setEnhancerState({
            redirectFrom: updatedPortfolio,
            searchTypeMode: 'FUNDS', // NOTE: NEED TO SET DEFAULT
            finalizeData: {},
          });
          if (history && redirectToPath && redirectToPath !== '') {
            history.push(redirectToPath);
          }
        } else {
          this.setState({ saveEnhancePortfolioModal: true });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  toggleSavePortfolioModal = () => {
    this.setState({ saveEnhancePortfolioModal: !this.state.saveEnhancePortfolioModal })
  }

  closeSavePortfolioModal = () => {
    this.setState({ saveEnhancePortfolioModal: false })
  }

  //model recommendation report
  downloadModelReport = () => {
    let out = {};
    const { customLogo, enhancer, modelEnhancement, downloadRecommendationEnhancerReport } = this.props;
    let { fundUniverse, modelUniverse } = enhancer
    let newModelEnhancement = {
      ...modelEnhancement,
      enhanced: {
        ...modelEnhancement.enhanced,
        portfolioName: modelEnhancement.enhanced.data.custom_replace_pf_name
      }
    };
    out.query = enhancer.query;
    out.queryEnglish = enhancer.query_english;
    out.logo = customLogo ? customLogo : '';
    out.searchTypeMode = enhancer.searchTypeMode;
    out.modelEnhancement = modelEnhancement.enhanced.data.custom_replace_type === 'user' ? newModelEnhancement : modelEnhancement;
    let analytics_data = enhancer.enhanceReplace.map(item => {
      return {
        ticker: item.ticker,
        replace: item.replace
      }
    })
    addEventToAnalytics('Rec-Report Download Request', 'Rec-Report Download Request', 'REC-REPORT DOWNLOAD REQUEST', { funds: analytics_data, category: 'advisory_PTC' }, false);
    out.fundUniverse = fundUniverse
    out.modelUniverse = modelUniverse === 'mp' ? 'Sponsor Models' : 'My models'
    downloadRecommendationEnhancerReport(out);
  }

  //model enhancer report final
  downloadEnhancerPDF = () => {
    console.log('enh report')
    addEventToAnalytics('Enhancer Report', 'Enhancer Report', 'ENHANCER_REPORT', {}, false);
    //modelEnhancement , portfolio
    const { view, enhancer, portfolioValue, portfolio, fundSelector, customLogo, modelEnhancement, downloadEnhancerReport } = this.props;
    let logo = customLogo ? customLogo : ''
    let fundStats = getFinalizeSummaryFunds(enhancer)
    let { fundUniverse, modelUniverse } = enhancer
    console.log({ view, fundStats, portfolioValue, portfolio, fundSelector, logo, modelEnhancement })
    if (fundStats && fundStats.funds) {
      // const { positions } = fundStats;
      // const ALL_FUNDS_TICKER = uniqValues(positions, 'symbol');

      let enhanceReplace = enhancer.enhanceReplace;
      enhanceReplace = enhanceReplace.filter(e => e.query != '').filter((k) => k.ticker !== k.replace); // selected EVALUATE & Selected FUNDS; //added for not displaying funds with no recomendations
      enhanceReplace.forEach((e, i) => {
        let itemResultSet = enhancer.fundPortfolios.filter(el => el.ticker == e.ticker)[0];
        if (!this.props.hideGenerateProposalBtn && enhanceReplace[i].selectedPortfolioFunds && itemResultSet && itemResultSet.target_card && itemResultSet.target_card.funds[0]) {
          enhanceReplace[i].selectedPortfolioFunds.target_card = itemResultSet.target_card.funds[0];
          const selectedPortfolioTicker = enhanceReplace[i].selectedPortfolioFunds.ticker;
          enhanceReplace[i].selectedPortfolioFunds._frs = itemResultSet.result.funds.find(item => item.ticker === selectedPortfolioTicker)._frs;
        }
      });
      let newModelEnhancement = {
        ...modelEnhancement,
        enhanced: {
          ...modelEnhancement.enhanced,
          portfolioName: modelEnhancement.enhanced.data.custom_replace_pf_name
        }
      }
      // console.log(enhanceReplace);
      const obj = {
        investment: {
          portfolioValue: enhancer.portfolioValue
        },
        portfolio: {
          //mp query toggle
          isModelPortfolioQuery: true,
          query: enhancer.query,
          query_english: enhancer.query_english,
          enhanceReplace: enhanceReplace,
          fundSelector: fundSelector.myScoreAttrs,
          enhancedPerformanceImpact: enhancer.performanceImpact.currentPerformanceImpact,
          currentPerformanceImpact: enhancer.finalizeData.finalizePerformanceImpact.currentPerformanceImpact,
          selectedBenchmark: enhancer.performanceImpact.selectedBenchmark,
          logo,
          modelEnhancement: modelEnhancement.enhanced.data.custom_replace_type === 'user' ? newModelEnhancement : modelEnhancement,
          fundUniverse,
          modelUniverse: modelUniverse === 'mp' ? 'Sponsor Models' : 'My models'
        },
        fundUniverse,
        modelUniverse: modelUniverse === 'mp' ? 'Sponsor Models' : 'My models'
      };
      // console.log(obj)
      // obj.portfolio.currentPerformanceImpact.averageCostAdjRet10Yr /= 100;
      // obj.portfolio.enhancedPerformanceImpact.averageCostAdjRet10Yr /= 100;
      console.log('generate proposal', obj)
      downloadEnhancerReport(obj);
    }
  }

  handleUpDownDropDownChange = (value) => {
    const { setEnhancerState, performanceImpact } = this.props;
    const { enhancedPerformanceImpact, currentPerformanceImpact, upDownDataSet } = performanceImpact;
    if (setEnhancerState) {
      let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(upDownDataSet, value)
      // console.log(enhancedPerformanceImpact)
      if (Object.keys(enhancedPerformanceImpact).length > 0) {
        let { pfRet1y, pfRet3y, pfRet5y } = enhancedPerformanceImpact
        let { pfRet1y: pfRet1yC, pfRet3y: pfRet3yC, pfRet5y: pfRet5yC } = currentPerformanceImpact
        let { up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr } = geopMeanCalc(portfolioBenchmark, pfRet1y, pfRet3y, pfRet5y)
        let { up_1yr: up_1yrC, down_1yr: down_1yrC, up_3yr: up_3yrC, down_3yr: down_3yrC, up_5yr: up_5yrC, down_5yr: down_5yrC } = geopMeanCalc(portfolioBenchmark, pfRet1yC, pfRet3yC, pfRet5yC)
        let updateEnhanceStats = { ...enhancedPerformanceImpact, up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr }
        let updatedCurrentStats = { ...currentPerformanceImpact, up_1yr: up_1yrC, down_1yr: down_1yrC, up_3yr: up_3yrC, down_3yr: down_3yrC, up_5yr: up_5yrC, down_5yr: down_5yrC }
        setEnhancerState({
          performanceImpact: {
            ...performanceImpact,
            selectedBenchmark: value,
            currentPerformanceImpact: updatedCurrentStats,
            enhancedPerformanceImpact: updateEnhanceStats,
          }
        });
      } else {
        let { pfRet1y, pfRet3y, pfRet5y } = currentPerformanceImpact
        let { up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr } = geopMeanCalc(portfolioBenchmark, pfRet1y, pfRet3y, pfRet5y)
        let updatedCurrentStats = { ...currentPerformanceImpact, up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr }
        setEnhancerState({
          performanceImpact: {
            ...performanceImpact,
            selectedBenchmark: value,
            currentPerformanceImpact: updatedCurrentStats
          }
        });
      }
    }
  }

  onCloseTradeList = () => {
    this.setState({
      generateTradeList: !this.state.generateTradeList,
    }, () => {
      setTimeout(() => {
        this.setState({
          alert: !this.state.alert,
        });
      }, 2000)
    })
  }

  onDownloadTradeComplete = () => {
    // const { setEnhancerState } = this.props
    this.setState({
      alert: !this.state.alert,
    }, () => {
      // setEnhancerState({ enhanceReplace: [], enhanceTickerFilter: [], resultSet: [], filterResultSet: [] })
    })
  }

  DirectCloseTrade = () => {
    this.setState({
      generateTradeList: !this.state.generateTradeList,
    })
  }

  setFileName = (e) => {
    const name = document.getElementById('save-file-name-box').value;
    this.setState({ fileName: name })
  }

  // savePortfolioEnhance = () => {
  //   try {
  //     addEventToAnalytics('Enhancer Save','Enhancer Save','ENHANCER_SAVE',{},false);
  //   } catch (e) {
  //     console.log(e);
  //   }
  //
  //   const { savePortfolioRequest, finalizeData, onboardData, history } = this.props;
  //
  //   try {
  //     if (finalizeData &&
  //       finalizeData.portfolioName &&
  //       finalizeData.enhancedPortfolioId &&
  //       finalizeData.summary &&
  //       finalizeData.onboardData &&
  //       finalizeData.onboardData.selectedPortfolioType &&
  //       typeof finalizeData.summary !== 'undefined' &&
  //       typeof finalizeData.summary.retData !== 'undefined' &&
  //       finalizeData.summary.retData.length > 0 &&
  //       typeof finalizeData.summary.portfolioFunds !== 'undefined' &&
  //       finalizeData.summary.portfolioFunds.length > 0) {
  //
  //       const enhancedPortfolioId = finalizeData.enhancedPortfolioId || undefined;
  //       const portfolioType = finalizeData.onboardData.selectedPortfolioType || onboardData.selectedPortfolioType || 'My Portfolio';
  //
  //       if (this.state.fileName == '') {
  //         this.setState({ fileNameError: true })
  //       } else {
  //         if (savePortfolioRequest) {
  //           savePortfolioRequest({
  //            portfolio_id: enhancedPortfolioId,
  //            portfolio_name: this.state.fileName,
  //            portfolioType,
  //          }, (response) => {
  //             console.log('-- saved! --');
  //             console.log(response);
  //             this.setState({ fileName: '', fileNameError: false })
  //             if (response && history) {
  //               let urlParam = [];
  //               for (var i in response){
  //                 urlParam.push(encodeURIComponent(i) + "=" + encodeURIComponent(response[i]));
  //               }
  //               const newPath = `/enhancer/dashboard/summary?${urlParam.join("&")}`;
  //               console.log(newPath);
  //               history.push(newPath);
  //             }
  //           });
  //         }
  //       }
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  savePortfolioEnhance = () => {
    try {
      addEventToAnalytics('Enhancer Save', 'Enhancer Save', 'ENHANCER_SAVE', {}, false);
    } catch (e) {
      console.log(e);
    }

    const { savePortfolioRequest, finalizeData, onboardData, history, redirectToPath, redirectFrom, setEnhancerState, setBuildPortfolioState, resetFinalizeDataRequest } = this.props;

    try {
      if (finalizeData &&
        finalizeData.portfolioName &&
        finalizeData.summary &&
        finalizeData.onboardData &&
        finalizeData.enhancedPortfolioId &&
        finalizeData.onboardData.selectedPortfolioType &&
        typeof finalizeData.summary !== 'undefined' &&
        typeof finalizeData.summary.retData !== 'undefined' &&
        finalizeData.summary.retData.length > 0 &&
        typeof finalizeData.summary.portfolioFunds !== 'undefined' &&
        finalizeData.summary.portfolioFunds.length > 0) {

        const enhancedPortfolioId = finalizeData.enhancedPortfolioId || undefined;
        const portfolioType = finalizeData.onboardData.selectedPortfolioType || onboardData.selectedPortfolioType || 'My Portfolio';

        if (this.state.fileName == '') {
          this.setState({ fileNameError: true })
        } else {
          if (savePortfolioRequest && resetFinalizeDataRequest) {
            savePortfolioRequest({
              portfolio_id: enhancedPortfolioId,
              portfolio_name: this.state.fileName,
              portfolioType,
            }, (response) => {
              console.log('-- saved! --');
              this.setState({ fileName: '', fileNameError: false }, () => {
                // console.log(redirectFrom);
                if (redirectFrom && Object.keys(redirectFrom).length > 0 && typeof redirectFrom.payload !== 'undefined') {
                  let { payload, payload: { clientDetails } } = redirectFrom;
                  let updatedPortfolio = {
                    ...redirectFrom,
                    payload: {
                      ...payload,
                      clientDetails: {
                        ...clientDetails,
                        custom_portfolio: {
                          portfolio_name: response.portfolioId,
                        },
                        model_portfolio: {},
                        selected_portfolio: response.portfolioId,
                      }
                    },
                    portfolioName: response.portfolioId,
                    returnPortfolioID: enhancedPortfolioId
                  }
                  // setEnhancerState({
                  //   redirectFrom: updatedPortfolio,
                  //   searchTypeMode: 'FUNDS', // NOTE: NEED TO SET DEFAULT
                  //   finalizeData: {},
                  // });

                  // NOTE: ALL STATE WILL BE RESET TO `initialState` except the state which is passed.
                  // NOTE: PASS STATE TO ASSIGN STATE AFTER RESET
                  resetFinalizeDataRequest({
                    redirectFrom: updatedPortfolio,
                    searchTypeMode: 'FUNDS', // NOTE: NEED TO SET DEFAULT
                    finalizeData: {},
                  });
                  if (response && history) {
                    let urlParam = [];
                    for (var i in response) {
                      urlParam.push(encodeURIComponent(i) + "=" + encodeURIComponent(response[i]));
                    }
                    // redirect to client page
                    if (redirectToPath && redirectToPath !== '') {
                      history.push(redirectToPath);
                    }
                  }
                } else {
                  // NOTE: ALL STATE WILL BE RESET TO `initialState` except the state which is passed.
                  // NOTE: PASS STATE TO ASSIGN STATE AFTER RESET
                  resetFinalizeDataRequest();

                  if (response && history) {
                    let urlParam = [];
                    for (var i in response) {
                      urlParam.push(encodeURIComponent(i) + "=" + encodeURIComponent(response[i]));
                    }
                    const newPath = `/enhancer/dashboard/summary?${urlParam.join("&")}`;
                    history.push(newPath);
                  }
                }
              })
            });
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const {
      loading,
      enhancer,
      summary,
      profile,
      modelPortfolios,
      query,
      selectedWeightOptions,
      queryEnglish,
      searchTypeMode,
      performanceImpact,
      enhanceTickerFilter,
      enhanceReplace,
      finalizeData,
      savingPortfolioError,
      fundPortfolios,
      savingPortfolioErrorText,
      guidanceMessage,
      pdfReportLoader,
      pdfReportDownloadStatus,
    } = this.props;

    const { summaryDrawer, fileNameError, fileName, portfolioSaveError, saveEnhancePortfolioModal } = this.state;
    const { replacePortfolioOptions, replacePortfolioWith, myCurrentPortfolio } = modelPortfolios;
    const { portfolioFunds } = summary;

    const { currentPerformanceImpact, enhancedPerformanceImpact, upDownDataSet, selectedBenchmark } = performanceImpact;
    const renderTextQuery = (query === 'user_criteria') ? (`Best Alternatives Using ${selectedWeightOptions && selectedWeightOptions.name}`) : (queryEnglish || query);

    let _enhancedPerformanceImpact = enhancedPerformanceImpact || {};
    let _enhanceReplace = enhanceReplace || [];
    if (finalizeData &&
      finalizeData.portfolioName &&
      finalizeData.summary &&
      typeof finalizeData.summary !== 'undefined' &&
      typeof finalizeData.summary.retData !== 'undefined' &&
      finalizeData.summary.retData.length > 0 &&
      typeof finalizeData.summary.portfolioFunds !== 'undefined' &&
      finalizeData.summary.portfolioFunds.length > 0) {
      _enhancedPerformanceImpact = {} // hack
      _enhanceReplace = []
    }

    if (enhanceReplace.filter((item) => item.ticker !== item.replaceTicker).length === 0) {
      _enhancedPerformanceImpact = {} // hack
      _enhanceReplace = []
    }

    let isItRenderingSummaryPageContent = (finalizeData &&
      finalizeData.portfolioName &&
      finalizeData.summary &&
      typeof finalizeData.summary !== 'undefined' &&
      typeof finalizeData.summary.retData !== 'undefined' &&
      finalizeData.summary.retData.length > 0 &&
      typeof finalizeData.summary.portfolioFunds !== 'undefined' &&
      finalizeData.summary.portfolioFunds.length > 0) || false;

    return (
      <React.Fragment>

        {(this.state.generateTradeList) && (
          <GenerateTradeList onCloseClick={this.onCloseTradeList} onDownload={this.onDownloadTradeComplete} OnDirectClose={this.DirectCloseTrade} />
        )
        }
        {(this.state.alert) && (
          <CustomAlert className="alert-windown-wrapper" color='info'>
            Tradelist Download Successfully
          </CustomAlert>
        )
        }
        {(pdfReportDownloadStatus && pdfReportDownloadStatus.message !== '' && pdfReportDownloadStatus.downloadStatus !== '') && (
          <CustomAlert className="alert-windown-wrapper" color={pdfReportDownloadStatus.downloadStatus} onDismissCallback={this.onAlertDismiss}>
            {pdfReportDownloadStatus.message}
          </CustomAlert>
        )
        }
        {!loading && (
          <div className="enhancer-model-portfolio-container">
            <ContentHead
              {...this.props}
              summaryDrawer={summaryDrawer}
              readableQuery={queryEnglish}
              openDrawer={this.openDrawer}
              finalizeClick={this.finalizeClick}
              generateProposalClick={this.generateProposalClick}
              savePortfolioClick={this.savePortfolioClick}
              generateTradeListClick={this.generateTradeListClick}
            />
            {(finalizeData &&
              finalizeData.portfolioName &&
              finalizeData.summary &&
              typeof finalizeData.summary !== 'undefined' &&
              typeof finalizeData.summary.retData !== 'undefined' &&
              finalizeData.summary.retData.length > 0 &&
              typeof finalizeData.summary.portfolioFunds !== 'undefined' &&
              finalizeData.summary.portfolioFunds.length > 0) ? (

              <SummaryBoard
                {...this.props}
                portfolioName={finalizeData.portfolioName}
                summary={finalizeData.summary}
                retData={finalizeData.summary.retData}
                activeFund={finalizeData.summary.activeFund}
                onboardData={finalizeData.onboardData}
                funds={finalizeData.summary.portfolioFunds}
                fundStats={getFinalizeSummaryFunds(enhancer)}
              />
            ) : myCurrentPortfolio && myCurrentPortfolio.targetCardData && !_.isEmpty(myCurrentPortfolio.targetCardData) ? (
              <div className="custom-scroll pb-5 pt-2" style={{ marginRight: '-7px', paddingRight: 8 }}>
                <CardRow
                  {...this.props}
                  portfolioFunds={portfolioFunds}
                  currentPerformanceImpact={currentPerformanceImpact}
                  profile={profile}
                  modelPortfolios={modelPortfolios}
                  readableQuery={queryEnglish}
                  portfolioQuery={query}
                  replacePortfolioWith={replacePortfolioWith}
                  replaceCurrentModelPortfolioWithRequest={this.props.replaceCurrentModelPortfolioWithRequest}
                  setEnhancerState={this.props.setEnhancerState}
                />
              </div>
            ) : (
              <Card className="card-background-grey empty-message-wrapper no-funds-wrapper">
                <img src='/assets/images/icons/NoFundsFound.svg' alt='' />
                <div className="text-box">
                  {(searchTypeMode === 'MODELS' && (query === 'user_criteria' || query === 'outperform')) ? (
                    <span>The query may be applicable only for fund enhancements.</span>
                  ) : (
                    <><span>No funds</span> were found for <span>{renderTextQuery}</span></>
                  )}
                </div>
              </Card>
            )}
            {
              typeof currentPerformanceImpact !== 'undefined' &&
              typeof currentPerformanceImpact.pfRet1y !== 'undefined' &&
              typeof enhancedPerformanceImpact !== 'undefined' && (
                <PortfolioModal
                  closable
                  mask={false}
                  enhancePortfolioVisible={false}
                  replacementToggleEnable={false}
                  summaryPage={isItRenderingSummaryPageContent}
                  query={query}
                  visible={summaryDrawer}
                  portfolioEnhanceReplace={_enhanceReplace}
                  enhanceStats={_enhancedPerformanceImpact}
                  currentStats={currentPerformanceImpact}
                  enhanceTickerFilter={enhanceTickerFilter}
                  portfolioBenchmarkData={upDownDataSet}
                  selectedPortfolioBenchmarkTicker={selectedBenchmark}
                  openDrawer={this.openDrawer}
                  handleUpDownDropDownChange={this.handleUpDownDropDownChange}
                  toggleChange={this.toggleChange}
                  setPfEnhancement={this.setPfEnhancement}
                  evaluateOnChange={this.evaluateOnChange}
                  setPortfolioState={this.props.setPortfolioState}
                  replacePortfolioToggle={this.props.replacePortfolioToggle}
                  useSuggestedFundsRequest={this.props.useSuggestedFundsRequest}
                  originalQueryVar={replacePortfolioOptions && replacePortfolioOptions.query_var}
                />
              )
            }
            {(saveEnhancePortfolioModal) &&
              (
                <Modal
                  isOpen={saveEnhancePortfolioModal}
                  toggle={this.toggleSavePortfolioModal}
                  centered
                  keyboard={true}
                  backdrop
                  className="premium-msg-modal account-modal save-portfolio-modal"
                  backdropClassName="premium-feature-process-modal-backdrop"
                >
                  <ModalBody className="ssf-modal__body">
                    <div className="premium-feature-process-modal-container">
                      <div className="header-wrapper">
                        <h1 className="text-align-left">Save Your Enhanced Portfolio</h1>
                      </div>
                      <i className="fal fa-times-circle premium-feature-completed-close" onClick={this.closeSavePortfolioModal}></i>
                      <p className="content-wrapper">
                        <form className="upload-form-content">
                          <FormGroup className="element-box pos-rel">
                            <label>Name</label>
                            <input
                              id="save-file-name-box"
                              className="input-area"
                              type="text"
                              onChange={this.setFileName}
                              autoComplete="off"
                            />
                            {fileNameError && <span className="upload-error">Please enter a Name to save</span>}
                            {savingPortfolioError && <span className="upload-error">{savingPortfolioErrorText !== '' ? savingPortfolioErrorText : 'Error on file save'}</span>}
                          </FormGroup>
                          <div className="col-12 cta-wrapper ">
                            <button type="button" id="save-prtf" className="ssf-btn-primary btn secondary-btn btn btn-secondary" onClick={this.savePortfolioEnhance}>
                              <span>SAVE</span>
                            </button>
                          </div>
                        </form>
                      </p>

                    </div>
                  </ModalBody>
                </Modal>
              )
            }
          </div>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ loading, enhancer, portfolio, profile, weights }) => ({
  summary: enhancer.summary,
  query: enhancer.query,
  profile: profile.user,
  queryEnglish: enhancer.query_english,
  fundPortfolios: enhancer.fundPortfolios || [],
  performanceImpact: enhancer.performanceImpact,
  portfolioName: enhancer.portfolioName,
  modelPortfolios: enhancer.modelPortfolios,
  searchTypeMode: enhancer.searchTypeMode,
  onboardData: enhancer.onboardData,
  guidanceMessage: enhancer.guidanceMessage,
  enhanceTickerFilter: enhancer.enhanceTickerFilter || [],
  enhanceReplace: enhancer.enhanceReplace,
  savingPortfolioError: enhancer.savingPortfolioError || false,
  savingPortfolioErrorText: enhancer.savingPortfolioErrorText || '',
  selectedWeightOptions: portfolio.selectedWeightOptions,
  loading: createLoadingSelector(['GET_MODEL_PORTFOLIOS_BY_ID'])({ loading }),
  portfolio,
  modelEnhancement: enhancer.modelEnhancement,
  enhancer,
  pdfReportLoader: enhancer.pdfReportLoader,
  pdfReportDownloadStatus: enhancer.pdfReportDownloadStatus,
  finalizeData: enhancer.finalizeData || {},
  redirectToPath: enhancer.redirect || '',
  redirectFrom: enhancer.redirectFrom || {},
  customLogo: profile.profile.custom_report_logo,
  fundSelector: weights,
})

const mapDispatchToProps = {
  getModelPortfoliosByIdRequest,
  setEnhancerState,
  resetSummaryDataRequest,
  replaceCurrentModelPortfolioWithRequest,
  downloadEnhancerReport,
  downloadRecommendationEnhancerReport,
  finalizeModelPortfolioRequest,
  finalizeModelPortfolioSuccess,
  setEnhancedTransaction,
  savePortfolioRequest,
  setBuildPortfolioState,
  resetFinalizeDataRequest,
  useSuggestedFundsRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Models);

import React, { useState } from 'react'
import { Select, Spin } from 'antd';
import { orderBy } from 'lodash';
import debounce from 'lodash/debounce';
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  ButtonGroup,
  Button
 } from 'reactstrap';
import {
  PlusOutlined,
} from '@ant-design/icons';
import { Loader } from '../Loader';

const { Option } = Select;

const classNames = require('classnames');

class SearchCard extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      value: [],
    };
  }

  componentDidMount() {
    const { getTickerListRequest, magnifiTickers } = this.props;
    if (magnifiTickers && magnifiTickers.length === 0 && getTickerListRequest) {
      getTickerListRequest();
    }
  }

  searchedTicker = (selectedData) => {
    // console.log('selectedData ==>', selectedData);
    this.setState({ value: selectedData });
    const { searchedCustomTicker } = this.props;
    if (searchedCustomTicker) {
      // console.log(typeof searchedCustomTicker);
      searchedCustomTicker(selectedData);
    }
  }

  fetchUserList = (searchKey, tickerList) => {
    // console.log('ticker ==>', ticker);
    // console.log(tickerList.length);
    // let _tickerList = tickerList.filter(suggestion => ((suggestion && suggestion.ticker && suggestion.ticker.toLowerCase().includes(searchKey.toLowerCase())) || (suggestion && suggestion.name && suggestion.name.toLowerCase().includes(searchKey.toLowerCase())) ));
    let _presentInTickerField = tickerList.filter(suggestion => (suggestion && suggestion.ticker && suggestion.ticker.toLowerCase().includes(searchKey.toLowerCase())) );
    let _presentInNameField = tickerList.filter(suggestion => (suggestion && suggestion.name && suggestion.name.toLowerCase().includes(searchKey.toLowerCase())) );
    let _tickerList = [..._presentInTickerField, ..._presentInNameField];
    return _tickerList;

    // return orderBy(_tickerList, ['ticker', 'name'], ['asc', 'desc']);

    // return new Promise((resolve, reject) => {
    //   return tickerList.filter(suggestion => suggestion.ticker.toLowerCase().includes(ticker.toLowerCase()));
    // });
    // const filteredSuggestions = tickerList.filter(
    //   suggestion => suggestion.toLowerCase().indexOf(username.toLowerCase()) > -1
    // );
    // console.log(filteredSuggestions);
    // return filteredSuggestions;
    // return fetch('https://randomuser.me/api/?results=5')
    //   .then((response) => response.json())
    //   .then((body) =>
    //     body.results.map((user) => ({
    //       label: `${user.name.first} ${user.name.last}`,
    //       value: user.login.username,
    //     })),
    //   );
  }

  render() {
    const { magnifiTickers, loading } = this.props;
    const { value } = this.state;
    // console.log('magnifiTickers length ==>', magnifiTickers.length);
    const notFoundContent = <div class="no-suggestions"><em>No suggestions found.</em></div>
    return (
      <Card className={classNames("shadow-sm mb-2 border-0")}>
        <CardBody className="p-0 ">
          <div className="no-gutters display-flex-row">
            <div className="col padding-5">
              <div className="bg-ssf-gray-l9 px-2 py-1 h-100 d-flex flex-column align-items-center justify-content-center" style={{minHeight: '75px'}}>
                {loading && (
                  <div style={{ width: '100%', height: 95 }}>
                    <Loader loading />
                  </div>
                )}
                {!loading && (
                  <div id="custom-ticker-search-box-wrapper" className="custom-ticker-search-box-wrapper" style={{ width: 200 }}>
                    <div className="plus-box"><PlusOutlined /></div>
                    <DebounceSelect
                      value={value}
                      dataList={magnifiTickers}
                      notFoundContent={notFoundContent}
                      fetchOptions={(value, list) => this.fetchUserList(value, list)}
                      onChange={(newValue) => {
                        this.searchedTicker(newValue);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default SearchCard;

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
  const dataList = props.dataList || [];
  const [fetching, setFetching] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const fetchRef = React.useRef(0);
  // console.log(dataList.length);
  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      const newOptions = fetchOptions(value, dataList);
      if (newOptions) {
        // console.log(newOptions.length);
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      }
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  // console.log('options ==> ', options.length);
  // console.log('fetching ==>', fetching);
  return (
    <Select
      labelInValue
      showSearch
      filterOption={false}
      listHeight={108}
      value={props.value}
      // options={options}
      dropdownClassName="custom-ticker-search-box-dropdown"
      dropdownMenuStyle="custom-ticker-search-box-dropdown-menu-style"
      placeholder="Find Security/Ticker"
      onSearch={debounceFetcher}
      onChange={props.onChange}
      getPopupContainer={() => document.getElementById('custom-ticker-search-box-wrapper')}
      notFoundContent={fetching === true ? <Spin size="small" /> : ((fetching === null) ? null : props.notFoundContent)}
      suffixIcon={<i class="far fa-fw fa-search fa-lg text-secondary search-icon"></i>}
    >
      {options.map((kl, ki) => {
        return (
          <Option key={kl.label+ki} value={kl.value}>{kl.label} - {kl.name}</Option>
        )
      })}
    </Select>
  );
} // Usage of DebounceSelect

// {options.map((kl, ki) => {
//   return (
//     <Option key={kl.label+ki} value={kl.value}>{kl.label} - {kl.name}</Option>
//   )
// })}

// dropdownRender={menu => (
//   <div>
//     {(menu && menu.length > 0) && menu}
//     {(menu && menu.length === 0) && props.notFoundContent}
//   </div>
// )}

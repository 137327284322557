import React, { Component } from 'react';
// import { render } from 'react-dom';
import moment from 'moment';

import { extent as d3Extent } from 'd3-array';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  // CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  // Sector,
  Cell,
  // Area,
  // ComposedChart,
  ReferenceLine,
  // Label,
  RadialBarChart,
  RadialBar
} from 'recharts';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  // PolarRadiusAxis
} from 'recharts';
import { getNestDefault, convertToCapitalCase } from '../Utils';

//const d3 = require("d3/d3.min.js");

export const SimpleLineChart = props => {
  const baseValue = 100; // line-chart base '$100' value;
  const colors = ['#bbb', '#3f7367', '#1a7166'];
  let TRACK_PREVIOUS_NODE = [];
  const yaxisPlacement = props.ydomain
    ? typeof props.ydomain.min !== 'undefined' &&
      typeof props.ydomain.max !== 'undefined'
      ? [props.ydomain.min, props.ydomain.max]
      : []
    : []
  const labelFormatter = label => {
    return label
  }
  const tickFormatter = label => {
    return '.'
  } // wider tick label dont show all xticks
  const formatter = (value, name, props) => value.toFixed(2)
  const labelStyle = { fontSize: '0.75rem' }
  const itemStyle = { fontSize: '0.75rem', padding: '0' }

  let tickerStartDate = (props.tickerStartDate && props.tickerStartDate !== 'undefined' && props.tickerStartDate !== null) ? props.tickerStartDate : 'Start';

  // prepend a zero point so that line always starts at zero
  const first = props.lineData.findIndex(e => e.y1 !== undefined)
  let data = props.lineData.slice()
  // data.splice(first, 0, { x: 'Start', y1: 0, y2: 0, y3: 0, y4: 0 })
  // data.splice(first, 0, { x: tickerStartDate, y1: 0, y2: 0, y3: 0, y4: 0 });
  // NOTE:  grisha changes
  // Show a wider popup with 2 lines:
  // Start 26 Jun 2019 - 30 Jun 2019 0.76% ("26" is the start_date and "30" is the month end date for each month)

  if (data &&
    data[first] &&
    data[first].x &&
    typeof data[first].x !== 'undefined' &&
    typeof data[first] !== 'undefined' &&
    tickerStartDate !== 'Start' &&
    tickerStartDate !== null &&
    moment(tickerStartDate, 'DD MMM YYYY', true).isValid()
  ) {
    let startDate = moment(tickerStartDate).format('DD MMM YYYY');
    let endOfMonthDate = `30 ${JSON.parse(JSON.stringify(data[first].x))}`;
    let _tickerStartDate = `Start ${startDate} - ${endOfMonthDate}`;
    // alter start_date data
    data.splice(first, 1, {
      ...data[first],
      x: _tickerStartDate,
      splitDate: {
        _tickerStartDate,
        _startDate: startDate,
        _endOfMonthDate: endOfMonthDate,
      },
    });
    // console.log(data);
    let _first = data.findIndex(e => e.y1 !== undefined);
    // console.log(_first, data[_first]);
    data.splice(_first, 0, { x: 'Inactive Date', y1: 0, y2: 0, y3: 0, y4: 0 });
  } else {
    data.splice(first, 0, { x: tickerStartDate, y1: 0, y2: 0, y3: 0, y4: 0 });
  }

  // let last = data[data.length - 1]
  let filterUndefined = data.filter(e => e.y1);
  let last = filterUndefined[filterUndefined.length-1];

  const posY = () => {
    let out = { y1off: 0, y4off: 0 }
    if (!props.line3) return out

    const last = filterUndefined[filterUndefined.length-1];
    const ranges = ['y1', 'y2', 'y4'].reduce(
      (acc, y) => acc.concat(d3Extent(data, e => e[y])),
      []
    )
    const [min, max] = d3Extent(ranges) // ydomain across all 3 lines
    const y1pos = last.y1 / (max - min),
      y4pos = last.y4 / (max - min)
    const diff = Math.abs(y1pos - y4pos)

    // if difference is more than 20% then no collision
    if (diff > 0.2) return out

    const adj = Math.round((0.2 - diff) * 30) // consider 12px text height, 12px = 20%
    if (y1pos > y4pos) {
      // y1 appears first
      if (y1pos > 0.9) out.y4off = 2 * adj
      // y1 almost at top, so just move y4 down
      else if (y4pos < 0.1) out.y1off = -2 * adj
      // y4 almost at bottom, so just move y1 up
      else out = { y1off: -adj, y4off: adj } // move y1 up, y4 down by same amt
    } else {
      // y4 appears first
      if (y4pos > 0.9) out.y1off = 2 * adj
      // y4 almost at top, so just move y1 down
      else if (y1pos < 0.1) out.y4off = -2 * adj
      // y1 almost at bottom, so just move y4 up
      else out = { y1off: adj, y4off: -adj } // move y4 up, y1 down by same amt
    }
    return out
  }

  const labelOff = posY()

  const segs = () => {
    const cc = ['#a5685f', colors[1]],
      tick = 1 / (data.length - 1 - first)
    let out = [],
      dir

    for (let i = first + 1; i < data.length; i++) {
      let _dir = data[i].y3 < 0 ? 0 : 1
      if (dir === _dir) {
        out[out.length - 1] = (
          <stop
            key={`e${i}`}
            offset={(i - first) * tick}
            stopColor={cc[_dir]}
            stopOpacity={1}
          />
        )
      } else {
        out.push(
          <stop
            key={`s${i}`}
            offset={(i - first - 1) * tick}
            stopColor={cc[_dir]}
            stopOpacity={1}
          />
        )
        out.push(
          <stop
            key={`e${i}`}
            offset={(i - first) * tick}
            stopColor={cc[_dir]}
            stopOpacity={1}
          />
        )
      }
      dir = _dir
    }
    return out
  }

  const setTrackNode = (props) => {
    if (props && props.length === 0) {
      TRACK_PREVIOUS_NODE = [];
    } else {
      TRACK_PREVIOUS_NODE.push(props);
    }
  }

  const gradientId = `color-${props.fid}-${props.gSuffix || ''}`

  // <Tooltip content={(props) => (<CustomTooltip label={props.label} active={props.active} payload={props.payload} dataKey="y1" />)} />
  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      className={'responsive-linechart'}
    >
      <LineChart
        onClick
        width={props.width || 360}
        height={props.height || 91}
        data={data}
        syncId={props.syncId || 'anyId'}
        margin={{ top: 8, right: 32, left: 30, bottom: 8 }}
      >
        <defs>
          <linearGradient id={gradientId} x1="0" y1="0" x2="1" y2="0">
            {segs()}
          </linearGradient>
        </defs>

        <ReferenceLine
          y={0}
          stroke="#bbb"
          shapeRendering="crispEdges"
          label={<ReferenceLineCustomLabel value={`$${baseValue}`} stroke="#666" />}
        >
          {/* <Label value="$100" position="insideBottomRight" fill='#666' fontSize={10} /> */}
        </ReferenceLine>
        <XAxis
          dataKey="x"
          tick={false}
          hide={true}
          tickFormatter={tickFormatter}
          height={1}
          strokeWidth="1"
          stroke="#aaa"
          strokeDasharray="2 2"
        />
        <YAxis hide={true} domain={yaxisPlacement || ['auto', 'auto']} />

        {props.line3 && (
          <Line
            type="monotone"
            dataKey="y4"
            name="Return"
            unit="%"
            stroke={'#57869c'}
            strokeWidth={1}
            dot={false}
            activeDot={{ r: 3, strokeWidth: 1 }}
            isAnimationActive={false}
            label={
              <CustomizedLabel
                size={data.length}
                data={last}
                stroke={'#57869c'}
                labelText={props.line3}
                yoff={labelOff.y4off}
              />
            }
          />
        )}

        <Line
          type="monotone"
          dataKey="y2"
          name="Category"
          unit="%"
          stroke={colors[0]}
          strokeWidth={1}
          dot={false}
          activeDot={{ r: 3, strokeWidth: 1 }}
          isAnimationActive={false}
        />

        <Tooltip dataKey="y1" content={<CustomTooltip />} />

        <Line
          type="monotone"
          dataKey="y1"
          name="Return"
          unit="%"
          stroke={`url(#${gradientId})`}
          strokeWidth={2}
          dot={false}
          activeDot={{ r: 3, strokeWidth: 1, fill: colors[1] }}
          isAnimationActive={false}
          label={
            <CustomizedLabel
              data={last}
              setTrackNode={setTrackNode}
              originalData={data}
              size={data.length}
              stroke={colors[1]}
              labelText={props.fid}
              yoff={labelOff.y1off}
              baseValue={baseValue}
              yaxisPlacement={yaxisPlacement}
              trackNode={TRACK_PREVIOUS_NODE}
              getLabelValue={props.getLabelValue || null}
            />
          }
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

/*
export const MultiLineChart = (props) => {
  const labelFormatter = (label) => { return label }
  const tickFormatter = (label) => { return '.' } // wider tick label dont show all xticks
  const formatter = (value, name, props) => value.toFixed(2)
  const labelStyle = {fontSize: '0.75rem'}
  const itemStyle = {fontSize: '0.75rem', padding: '0'}
  var colors = d3.scale.category20();
  //console.log([...Array(20).keys()].map(i => colors(i)))

	return (
  	<LineChart width={360} height={105} data={props.lineData}
            margin={{top: 0, right: 0, left: 0, bottom: 0}}>
      <CartesianGrid horizontalPoints={[4, 24, 44, 64, 84, 104]} horizontal={true} vertical={false} strokeWidth="0.5" stroke="#d7d7d7" strokeOpacity="1" />
      <XAxis dataKey="x" tick={false} hide={true} />
      { [...Array(props.lines).keys()].map(i =>
        <Line key={i} type="monotone" dataKey={'y'+i} name="Category" unit="%" stroke={colors(i)} strokeWidth={1} dot={false} activeDot={{r: 3, strokeWidth: 1}} isAnimationActive={false} />
      )}
    </LineChart>
  );
}
*/

export const SimplePieChart = props => {
  /*
      <Tooltip
        wrapperClassName="tfin-tooltip"
        labelFormatter={labelFormatter}
        formatter={formatter}
        labelStyle={labelStyle}
        itemStyle={itemStyle} />
  const data = [{name: 'Group A', value: 400}, {name: 'Group B', value: 300},
                  {name: 'Group C', value: 300}, {name: 'Group D', value: 200}];
  */
  const itemStyle = { fontSize: '0.75rem', padding: '0' }
  const formatter = (value, data, index) =>
    props.chartName === 'asset_type'
      ? index.payload.originalValue.toFixed(1) + '%'
      : value + '%'
  const { data, colors, centerDisplay, chartName } = props

  let centerText = props.centerText || ''
  if (chartName === 'Holdings Diversification') {
    centerDisplay > 1 ? (centerText = 'Holdings') : (centerText = 'Holding')
  } else if (chartName === 'Region Diversification') {
    centerDisplay > 1 ? (centerText = 'Regions') : (centerText = 'Region')
  } else if (chartName === 'Sector Diversification') {
    centerDisplay > 1 ? (centerText = 'Sectors') : (centerText = 'Sector')
  }
  // console.log(chartName, centerDisplay, centerText);

  const chartTypePresent = typeof chartName !== 'undefined' ? chartName : ''

  let primaryTextStyle =
    chartTypePresent === 'Top Holdings' ||
    chartName === 'Holdings Diversification' ||
    chartName === 'Region Diversification' ||
    chartName === 'Sector Diversification'
      ? {
          // top: '39%',
          fontSize: 11,
          fontWeight: 'bold',
          zIndex: '5'
        }
      : {}
  const secondaryTextStyle =
    chartTypePresent === 'Top Holdings'
      ? {
          top: '52%',
          fontSize: 10
        }
      : {}

  if (props.chartData && typeof props.chartData !== 'undefined' && props.chartData.length > 0) {
    const nodeData = props.chartData[0].data;
    const nodeCategory = props.chartData[0].category;
    if (nodeData &&  nodeCategory === 'asset_type') {
      primaryTextStyle = {
        fontSize: 11,
        fontWeight: 'bold',
        zIndex: '5'
      };
    }
  }

  return (
    <div className="simplePieChartWrapper">
      {(chartName === 'Holdings Diversification' ||
        chartName === 'Region Diversification' ||
        chartName === 'Sector Diversification' ||
        chartName === 'asset_type' ||
        (chartName === 'Top Holdings' && centerDisplay !== '')) && (
        <div className="primaryTextStyle" style={primaryTextStyle}>
          {centerDisplay}
          <div className="highlightTooltip">
            {centerText}: {centerDisplay}
          </div>
        </div>
      )}
      {/* {(chartName === 'Holdings Diversification' || chartName === 'Region Diversification' || chartName === 'Sector Diversification')
          && (
            <p className="secondaryTextStyle" style={secondaryTextStyle}
            >
              { centerText }
            </p>
          )
        } */}
        <PieChart width={props.width || 90} height={props.height || 90}>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={props.width ? (props.width === 100 ? 49 : (props.width > 100 ? 65 : 37)) : 42}
            innerRadius={props.width ? (props.width === 100 ? 22 : (props.width > 100 ? 35 : 17)) : 22}
            isAnimationActive={false}
            fill="#8884d8"
          >
            {props.data.map((v, i) => (
              <Cell
                key={i}
                fill={v.color ? v.color : colors[i % colors.length]}
              />
            ))}{' '}
            {/* added if the data itself has the colors. */}
          </Pie>
          <Tooltip
            wrapperClassName="ssf-tooltip"
            formatter={props.formatter || formatter}
            itemStyle={itemStyle}
          />
        </PieChart>
    </div>
  )
}

// const CustomTooltip = ({ active, payload, label }) => {
//   if (active && payload && payload.length) {
//     return (
//       <div className="custom-tooltip">
//         <p className="label">{`${label} : ${payload[0].value}`}</p>
//         <p className="desc">Anything you want can be displayed here.</p>
//       </div>
//     );
//   }
//
//   return null;
// };

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    // payload = payload.filter((item) => item.name !== 'Category');
    let xVal = payload[0] && payload[0].payload && payload[0].payload.x; // getNestDefault(['payload', 'x'], payload[0]);
    let val = (payload[0] && payload[0].payload && !isNaN(payload[0].payload.y3) && typeof payload[0].payload.y3 !== 'undefined') ? payload[0].payload.y3.toFixed(2) : '--';
    let unit = (payload[0] && payload[0].unit) ? payload[0].unit : '--';
    // NOTE: DO NOT add new class or edit or remove this -> className="custom-tooltip", Tooltip component is using this className
    return (
      <div className="custom-tooltip">
        <div>
          <span className="label">{(xVal === 'Inactive Date' || val === '--') ? 'Inactive' : label}</span>
          {(val !== '--') && (
            <span className="value">
              {val}
              <span className="text-muted small">{unit}</span>
            </span>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="custom-tooltip">
        <div className="label">{'Inactive'}</div>
      </div>
    );
  }
};

// const CustomTooltip = props => {
//   const { active, payload, label, dataKey } = props;
//   if (active && payload && payload.length > 0) {
//     const _payload = payload[0];
//     if (_payload) {
//       // let val = getNestDefault(['payload', 'y3'], _payload, 0.0).toFixed(2);
//        // getNestDefault(['payload', 'y3'], _payload, 0.0).toFixed(2);
//       let xVal = _payload.payload.x; // getNestDefault(['payload', 'x'], _payload);
//       let val = _payload.payload && typeof _payload.payload.y3 !== 'undefined' ? _payload.payload.y3.toFixed(2) : '--'
//       if (xVal === 'Inactive Date') {
//         return (
//           <div className="recharts-default-tooltip ssf-tooltip-custom">
//             <div>
//               <span className="label">Inactive</span>
//             </div>
//           </div>
//         )
//       } else if (_payload.payload && _payload.payload.splitDate) {
//         let val = _payload.payload && _payload.payload.y3 ? _payload.payload.y3.toFixed(2) : '--';
//         let splitDate = _payload.payload.splitDate // getNestDefault(['payload', 'splitDate'], _payload);
//         // 26 Jun - 30 Jun 2019 0.76%
//         return (
//           <div className="recharts-default-tooltip ssf-tooltip-custom">
//             <div>
//               <span className="label">{splitDate._startDate} - {splitDate._endOfMonthDate}</span>
//                 <span className="value">
//                   {val}
//                   <span className="text-muted small">{_payload.unit}</span>
//                 </span>
//             </div>
//           </div>
//         )
//       } else {
//         let val = _payload.payload && _payload.payload.y3 ? _payload.payload.y3.toFixed(2) : '--';
//         return (
//           <div className="recharts-default-tooltip ssf-tooltip-custom">
//             <div>
//               <span className="label">{label}</span>
//               <span className="value">
//                 {val}
//                 <span className="text-muted small">{_payload.unit}</span>
//               </span>
//             </div>
//           </div>
//         )
//       }
//     } else {
//       return (
//         <div className="recharts-default-tooltip ssf-tooltip-custom">
//           <div className="label">{'Inactive'}</div>
//         </div>
//       )
//     }
//   }
//   return null;
// }

const CustomizedLabel = props => {
  const { index, size, stroke, data, originalData, trackNode, setTrackNode, getLabelValue = null, baseValue, yaxisPlacement } = props;
  let { x, y } = props;
  // let y;
  let trackNodeYAxis, trackNodeXAxis;

  if (originalData && originalData.length > 1 && typeof originalData[index].y4 === 'undefined' && originalData[index - 1] && typeof originalData[index - 1].y4 !== 'undefined') {
    if (trackNode && trackNode.length > 0 && trackNode[trackNode.length - 1]) {
      trackNodeXAxis = trackNode[trackNode.length - 1].x;
      trackNodeYAxis = trackNode[trackNode.length - 1].y;
    }
    if (setTrackNode) {
      setTrackNode([]);
    }
    // const value = typeof data.y4 !== 'undefined' && !isNaN(data.y4) ? data.y4 : data.y1
    // return (
    //   <text
    //     x={x}
    //     y={y}
    //     dx={4}
    //     dy={4}
    //     fill={stroke}
    //     fontSize={10}
    //     textAnchor="start"
    //   >
    //     {`$${Math.round(100 + value)}`}
    //   </text>
    // )
  } else {
    if (setTrackNode) {
      setTrackNode(props);
    }
  }

  // console.log(y);

  if (index === size - 1 && typeof y !== 'undefined' && y !== null && data) {
    // console.log(data && data.y4);
    const value = data && typeof data.y4 !== 'undefined' && !isNaN(data.y4) ? data.y4 : data.y1
    // console.log(y, value);
    // if (!y) y = 49;
    if (getLabelValue && typeof getLabelValue === 'function') {
      getLabelValue({
        baseValue,
        labelValue: Math.round(100 + value),
        domain: yaxisPlacement,
      });
    }
    return (
      <text
        x={x}
        y={y}
        dx={0}
        dy={4}
        fill={stroke}
        fontSize={10}
        textAnchor="start"
      >
        {`$${Math.round(100 + value)}`}
      </text>
    )
  } else if (trackNodeYAxis && trackNodeXAxis && data) {
    const value = data && typeof data.y4 !== 'undefined' && !isNaN(data.y4) ? data.y4 : data.y1
    // console.log(trackNodeYAxis, trackNodeXAxis, value);
    // if (!y) y = 49;
    if (getLabelValue && typeof getLabelValue === 'function') {
      getLabelValue({
        baseValue,
        labelValue: Math.round(100 + value),
        domain: yaxisPlacement,
      });
    }
    return (
      <text
        x={trackNodeXAxis}
        y={trackNodeYAxis}
        dx={0}
        dy={4}
        fill={stroke}
        fontSize={10}
        textAnchor="start"
      >
        {`$${Math.round(100 + value)}`}
      </text>
    )
  }

  return null
}

// const CustomizedLabel = (props) => {
//   const { index, size, x, y, stroke, data, val, labelText, yoff } = props;
//   // const val = (typeof val !== 'undefined' && !isNaN(val)) ? data.val : data.y1;
//   if (index === size-1) {
//     let xoff = labelText ? 34 : 0;
//     return (
//       <text x={x} y={y+yoff} dx={4} dy={4} fill={stroke} fontSize={10} textAnchor="start">
//         { labelText && <tspan>{labelText}</tspan> }
//         <tspan x={x+xoff}>{`$${Math.round(100+val)}`}</tspan>
//       </text>
//     )
//   }
//   return null;
// };

const ReferenceLineCustomLabel = props => {
  const { viewBox, stroke, value } = props
  return (
    <text
      x={0}
      y={viewBox.y}
      dx={4}
      dy={4}
      fill={stroke}
      fontSize={10}
      textAnchor="start"
    >
      {value}
    </text>
  )
}

export const SimpleRadialBarChart = props => {
  const width = 160,
    height = 80
  const itemStyle = { fontSize: '0.75rem', padding: '0' }
  const formatter = (value, name, index) => parseFloat(value).toFixed(0)
  const labelFormatter = index => convertToCapitalCase(data[index].name)
  const legendFormatter = (value, entry) => value.substring(0, 8)

  const { data, dataKey } = props

  return (
    <div className="themeChart">
      <RadialBarChart
        width={width}
        height={height}
        innerRadius={18}
        outerRadius={height - 20}
        data={data}
        startAngle={180}
        endAngle={0}
        barCategoryGap={1.5}
        cy="100%"
      >
        <RadialBar
          minAngle={15}
          background
          clockWise={true}
          dataKey={dataKey}
          label={{ position: 'insideStart', fill: '#fff', fontSize: '10px' }}
          isAnimationActive={false}
          margin={{ top: 0, right: 0, left: 0, bottom: 5 }}
          aspect={1.5}
        />
        <Tooltip
          labelFormatter={labelFormatter}
          wrapperClassName="ssf-tooltip"
          formatter={formatter}
          itemStyle={itemStyle}
        />
        <Legend
          iconSize={8}
          iconType="square"
          formatter={legendFormatter}
          verticalAlign="top"
          wrapperStyle={{ top: '-4px', left: 0 }}
        />
      </RadialBarChart>
    </div>
  )
}

/* data = [ {name: 'Axis-1', value: 123 }, ... ] */
export const SimpleRadarChart = props => {
  const width = 140,
    height = 90
  const labelStyle = { fontSize: '0.75rem', fill: '#888' }

  const { data } = props

  return (
    <RadarChart
      cx={width / 2}
      cy={(height * 2) / 3}
      outerRadius={height / 2 - 5}
      width={width}
      height={height}
      data={data}
    >
      <PolarGrid />
      <PolarAngleAxis dataKey="name" tick={labelStyle} />
      {/* <PolarRadiusAxis angle={90} tick={labelStyle}/> */}
      <Radar
        name="Mike"
        dataKey="value"
        stroke="#8884d8"
        fill="#8884d8"
        fillOpacity={0.6}
        isAnimationActive={false}
      />
    </RadarChart>
  )
}

import zipObject from 'lodash/zipObject'

const actions = [
  'FETCH_IMPORTED_ACCOUNT_REQUEST',
  'FETCH_IMPORTED_ACCOUNT_SUCCESS',
  'FETCH_IMPORTED_ACCOUNT_FAILURE',
  
  'SETUP_BROKER_REQUEST',
  'SETUP_BROKER_SUCCESS',
  'SETUP_BROKER_FAILURE',

  'COMPLETE_BROKER_SETUP_REQUEST',
  'COMPLETE_BROKER_SETUP_SUCCESS',
  'COMPLETE_BROKER_SETUP_FAILURE',

  'FETCH_BROKER_ACCOUNT_REQUEST',
  'FETCH_BROKER_ACCOUNT_SUCCESS',
  'FETCH_BROKER_ACCOUNT_FAILURE',
  
  'IMPORT_BROKER_ACCOUNT_REQUEST',
  'IMPORT_BROKER_ACCOUNT_SUCCESS',
  'IMPORT_BROKER_ACCOUNT_FAILURE',
    
  'COMPLETE_TDAMERITRADE_BROKER_SETUP_REQUEST',
  'COMPLETE_TDAMERITRADE_BROKER_SETUP_SUCCESS',
  'COMPLETE_TDAMERITRADE_BROKER_SETUP_FAILURE',
  
  'SET_AS_DEFAULT_REQUEST',
  'SET_AS_DEFAULT_SUCCESS',
  'SET_AS_DEFAULT_FAILURE',
  
  'DELETE_IMPORTED_ACCOUNT_REQUEST',
  'DELETE_IMPORTED_ACCOUNT_SUCCESS',
  'DELETE_IMPORTED_ACCOUNT_FAILURE',

  'COMPLETE_TRADE_IT_BROKER_SETUP',

  'TRADE_IT_MORE_INFO_STATUS',
  'TRADE_IT_MORE_INFO_VEIRFY_REQUEST',
  'TRADE_IT_MORE_INFO_VEIRFY_SUCCESS',
  'TRADE_IT_MORE_INFO_VEIRFY_ERROR',
  'TRADE_IT_MORE_INFO_VEIRFY_RESET'
]

export default zipObject(actions, actions)

import React from 'react';
import { Row, Col } from 'antd';
import { QuinItems } from 'DataSet';
import { getImage, quintileSize, quintileClass } from 'Utils';
import { checkValidNumber, getFRRAttrData } from 'layouts/utils';

export const FeeReturnRisk = ({ FRR }) => {
  return (
    <Row className="no-gutters FRR-wrapper">
      {FRR && FRR.map(e => (
        <Col span={8} key={e.attr}>
          <QuintileIconText {...e} />
        </Col>
      ))}
    </Row>
  )
}

const QuintileIconText = (props) => {
  let {
    name,
    quin = 1,
    multiplier = 1,
    decimal,
    suffix,
    icon,
    value,
  } = props;
  const attributeFromName = getFRRAttrData().find((kj) => kj.name === name);
  if (attributeFromName && attributeFromName.attr === 'fees') icon = 'fa-tags-1';
  let Quin = QuinItems.find((item) => item.name === name);
  const renderIcon = attributeFromName && attributeFromName.icon + '-' + quintileSize(quin); // qi.reverse = true
  return (
    <div
      className={`${attributeFromName && attributeFromName.attr}-flag-color FRR-container px-2 py-2`}
      style={{ height: '100%', textAlign: 'center' }}
    >
      <p
        className="__state"
        style={{
          margin: 0,
          fontSize: 10,
          minHeight: 20,
          lineHeight: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {name === 'Returns' ? '3 Year Annualized Returns' : name || props['Name of the chart on UI']}
      </p>
      {!renderIcon ? (
        <img
          className="far fa-fw"
          src={getImage("Assets")} 
          width="auto"
          height={32}
        />
      ) : (
        <i className={`far fa-fw fassf ${renderIcon}`} />
      )}
      <p
        className="__val"
        style={{
          margin: 0,
          fontSize: 11,
          lineHeight: 1,
          fontWeight: 'bold',
          color: `${name === 'Risk' && '#A5685F'}`
        }}
      >
        {checkValidNumber(
          parseFloat(value || 0) * (multiplier || 1),
          decimal || 2,
          suffix || '%',
          '--')
        }
      </p>
    </div>
  )
}

import React from 'react'
import { connect } from 'react-redux'
import { unsubscribeUserRequest } from 'actions/profile'
import { Alert, Col, Collapse, Row, Container, Form, FormGroup, Label, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup } from 'reactstrap'
import { PrimaryButton } from 'shared-components'
import SubscriptionCancellationRequest from './SubscriptionCancellationRequest'
import SubscriptionCancellationSuccess from './SubscriptionCancellationSuccess'
import SubscriptionCancellationFailure from './SubscriptionCancellationFailure'
import { addEventToAnalytics } from '../../Utils';

class ProfileUnsubscriptionModal extends React.Component {
  constructor(props) {
    super(props)
    this.unSubscribe = this.unSubscribe.bind(this);
  }

  unSubscribe() {
    addEventToAnalytics('Unsubscribe Yes', 'Unsubscribe Yes', 'UNSUBSCRIBE_YES', {}, false);
    this.props.unsubscribeUserRequest(this.props.subscription);
  }

  render() {

    let showContent = '';
    if (this.props.cancellation_request_status) {
      // If the user has already unsubscribed and subscription hasn't expired yet
      showContent = <SubscriptionCancellationSuccess changeUnsubscribeModalStatus={this.props.changeUnsubscribeModalStatus} />
    } else if (!this.props.unSubscribeUserStatus && !this.props.unSubscriptionError) {
      // Show the cancellation confirmation modal
      showContent = <SubscriptionCancellationRequest changeUnsubscribeModalStatus={this.props.changeUnsubscribeModalStatus} unSubscribe={this.unSubscribe} subscriptionID={this.props.subscription && this.props.subscription.subscription_id} />
    } else if (this.props.unSubscribeUserStatus && !this.props.unSubscriptionError) {
      // Display message on successful cancellation
      showContent = <SubscriptionCancellationSuccess changeUnsubscribeModalStatus={this.props.changeUnsubscribeModalStatus} />
    } else {
      // Show cancellation error message
      showContent = <SubscriptionCancellationFailure changeUnsubscribeModalStatus={this.props.changeUnsubscribeModalStatus} />
    }

    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.modalStatus}
          toggle={() => { this.props.changeUnsubscribeModalStatus(false) }}
          centered
          keyboard={true}
          backdrop
          className="premium-msg-modal-sm-light"
          backdropClassName="premium-feature-process-modal-backdrop"
        >
          <ModalBody className="ssf-modal__body">
            {showContent}
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ profile, auth }) => ({
  subscription: profile.profile.subscription,
  unSubscribeUserStatus: profile.unSubscribeUserStatus,
  unSubscriptionError: profile.unSubscriptionError,
  cancellation_request_status: auth.user.cancellation_request_status
})

const mapDispatchToProps = {
  unsubscribeUserRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileUnsubscriptionModal);

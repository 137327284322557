import React from 'react';
import { fixedValue, handleNoData, mul100 } from '../shared/Utils'
import BestTickerBadge from '../shared/BestTickerBadge';

const PerformanceTable = (props) => {
  const { selFunds, bestTicker, benchmark } = props;
  return (
    <table className="perf-table">
      <thead>
        <tr className="perf-tbl-header">
          <th
            rowspan="2"
            colspan="1"
            style={{
              minWidth: '100p',
              width: '100p',
              maxWidth: '100p',
              textAlign: 'left',
              paddingLeft: `10px`
            }}
          >
            Funds  <p style={{ fontSize: `13px` }}></p>
          </th>
          <th
            colspan="2"
            style={{
              width: '21%'
            }}
          >
            Return
          </th>
          <th
            colspan="4"
            style={{
              width: '32%'
            }}
          >
            Risk
          </th>
          <th
            colspan="4"
            style={{
              width: '32%'
            }}
          >
            vs. Comparison ETF ({benchmark})*
          </th>
        </tr>
        <tr className="perf-tbl-sub-header">
          <th>Ann. Return<br /><span className={'smallText'}>(%)</span></th>
          <th className="border-rt">Cumulative Return<br /><span className={'smallText'}>(%)</span></th>
          <th>Std Dev.<br /><span className={'smallText'}>(%)</span></th>
          <th>Downside Risk<br /><span className={'smallText'}>(%)</span></th>
          <th>Sharpe Ratio</th>
          <th className="border-rt">Beta vs Comparison ETF ({benchmark})* <br /><span className={'smallText'}>(%)</span></th>
          <th>Ann. Excess Return<br /><span className={'smallText'}>(%)</span></th>
          <th>Cumulative Excess Return<br /><span className={'smallText'}>(%)</span></th>
          <th>Info. Ratio</th>
          <th className="border-rt">Tracking Error<br /><span className={'smallText'}>(%)</span></th>
        </tr>
      </thead>
      <tbody>
        {selFunds.map((item, index) => {
          return (
            <tr key={index}>
              <td className="border-lt-rt">
                {item.ticker_short || '-'}
                {(item.ticker_short && item.ticker_short === bestTicker) && (
                  <BestTickerBadge style={{ paddingLeft: "10px" }} />
                )}
                {/* <p >{benchmark === 'MPT' ? item.benchmark_primary_index : item.category_proxy_name}</p> */}
              </td>
              <td>{handleNoData(fixedValue(mul100(item.returns_3y)))}</td>
              <td className="border-rt">{handleNoData(fixedValue(mul100(Number(item.cumulative_returns_3y))))}</td>
              <td>{handleNoData(fixedValue(mul100(item.volatility_3y)))}</td>
              <td>{handleNoData(fixedValue(mul100(item.downside_risk_3y)))}</td>
              <td>{handleNoData(fixedValue(item.sharpe_3y))}</td>
              <td className="border-rt">{handleNoData(fixedValue(item.technical_beta))}</td>
              <td>{handleNoData(benchmark === 'MPT' ? fixedValue(mul100(item.annualret_excess_3y)) : fixedValue(mul100(item.annualret_excess_3y_category)))}</td>
              <td>{handleNoData(benchmark === 'MPT' ? fixedValue(mul100(item.cumret_excess_3y)) : fixedValue(mul100(item.cumret_excess_3y_category)))}</td>
              <td>{handleNoData(benchmark === 'MPT' ? fixedValue(item.info_ratio) : fixedValue(item.info_ratio_category))}</td>
              <td className="border-rt">{handleNoData(benchmark === 'MPT' ? fixedValue(item.tracking_error) : fixedValue(mul100(item.tracking_error_category)))}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default PerformanceTable;

const getSharpeRatio = (item) => {
  const { returns_3y, volatility_3y } = item;
  return returns_3y && volatility_3y ? returns_3y / volatility_3y : '';
}

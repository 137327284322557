import React from 'react'
import PropTypes from 'prop-types'
const classNames = require('classnames');

const Overlay = ({ children, overlayInnerComponent, isBlocked, className, wrapperClassName, modalToggleState }) => {
	const containerClassName = 'overlay-container ' + className
	const OverlayComponent = isBlocked ? <div id="overlay-container" className={containerClassName}>{overlayInnerComponent}</div>: null;
	return (<div id="overlay-wrapper" className={`${wrapperClassName} ${classNames("overlay-wrapper", modalToggleState ? 'blur-effect' : '')}`}>
		{OverlayComponent}
		{children}
	</div>)
}

export default Overlay

Overlay.propTypes = {
	children: PropTypes.element.isRequired,
	overlayInnerComponent: PropTypes.element
}

Overlay.defaultProps = {
	overlayInnerComponent: null
}

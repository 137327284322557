import _ from 'lodash';
import { numberCheck } from 'layouts/utils';
import { formatTickerForView, findDelimiter, convertToLowerCase, convertToUpperCase, convertToFloatvalues } from 'Utils';
import { RegionChartMapping, SectorChartMapping } from 'ChartData'

export const csvToJSON = (csv) => {
	var lines=csv.split("\n");
	var result = [];
	var existingAccount = [];
	var headers=lines[0].split(",");
	var inc = 1;
	for(var i=1;i<lines.length;i++){
		var obj = {};
		var currentline=lines[i].split(",");
		if(!existingAccount.includes(currentline[1])){
			// Null value check
			if(currentline[0] && currentline[1] && currentline[2] && currentline[3] && currentline[4]){
				existingAccount.push(currentline[1])
				obj.refID = inc;
				obj.name = formatValues(currentline[0]);
				obj.account_number = formatValues(currentline[1]);
				obj.custodian = formatValues(currentline[2]);
				obj.holdings = [{
					ticker: formatValues(currentline[3]),
					shares: Number(formatValues(currentline[4])), // parseInt(formatValues(currentline[4])),
          // price: currentline[5] && parseFloat(formatValues(currentline[5])), // price is missing in CSV
          lot_date: currentline[5] && formatValues(currentline[5]),
					cost_basis: currentline[6] && parseFloat(formatValues(currentline[6]))
				}]
				inc++;
			}
		}else{
			// Null value check
			if(currentline[0] && currentline[1] && currentline[2] && currentline[3] && currentline[4]){
				for(var j=0;j<result.length;j++){
					if(result[j].account_number == currentline[1]){
						// console.log("price -> ", currentline[4] && parseFloat(formatValues(currentline[4])));
						// console.log("lot_date -> ", currentline[5] && parseFloat(formatValues(currentline[5])));
						// console.log("cost_basis -> ", currentline[6] && parseFloat(formatValues(currentline[6])));
						result[j].holdings.push({
							ticker: formatValues(currentline[3]),
							shares: Number(formatValues(currentline[4])), // parseInt(formatValues(currentline[4])),
							// price: currentline[5] && parseFloat(formatValues(currentline[5])), // price is missing in CSV
              lot_date: currentline[5] && formatValues(currentline[5]),
              cost_basis: currentline[6] && parseFloat(formatValues(currentline[6]))
						})
					}
				}
			}
		}
		if(Object.entries(obj).length) result.push(obj);
	}
	return result; //JSON
}

const formatValues = (val) => {
	return val.replace(/(\r\n|\n|\r)/gm,"");
}

export const checkForDuplicateClients = (uploadedClientList, clientList) => {
  // Use isDuplicate key only for sorting,
  // lets review and remove it later
  let showUpdateColumnInPreview = false;
  let updatedPfList = uploadedClientList.map(i => {
    const checkExists = clientList.find(e => e.name == i.name && e.acc_no == i.account_number)
    if(checkExists){
      i.existingClientID = checkExists.id;
      i.isDuplicate = true;
      i.updateData = true;
      showUpdateColumnInPreview = true;
    }else{
      i.isDuplicate = false;
    }
    return i;
  })
  return {
    uploadedClientList,
    showUpdateColumnInPreview
  };
}

export const scrollBarCheck = (val, margin = false) => {
	let element1 = document.querySelector(val + ' .rdt_TableBody');
	let hasScrollBar = (element1) && element1.scrollHeight > element1.clientHeight;
	if(hasScrollBar){
		let element2 = document.querySelector(val + ' .rdt_TableHeadRow');
		if(element2) margin ? element2.style.marginRight = '20px' : element2.style.paddingRight = '20px';
	}else{
    let element2 = document.querySelector(val + ' .rdt_TableHeadRow');
    if(element2) margin ? element2.style.marginRight = '' : element2.style.paddingRight = '';
  }
}

export const ViewNames = [
  { name: 'Regions',      display: true },
  { name: 'Sectors',      display: true },
  { name: 'Assets',       display: true },
  { name: 'Volume',       display: true },
  { name: 'Top Holdings',     display: true },
  { name: 'Asset Allocation',   display: true },
  { name: 'Themes',  display: true },
  // { name: '% Held',  display: false }, //name changed to dynamic
  { name: 'Diversification',  display: true },
  { name: 'Return Quality',  display: true },
  { name: 'Dividend Yield',  display: true },
]

export const isViewPresentInChartTypes = (view) => {
  return ViewNames.some(item => item.name === view);
}

const isDynamicChart = (view, query_var) => {
  if (!query_var.Static && query_var.chart_type === 'odometer') {
    return true;
  }
  return false;
}

export const getColName = (results, pfTable) => {

  if(!results.query_var.length) return 'Assets';

  let type;

  const staticVal = results.query_var && results.query_var[0] ?
    results.query_var[0].Static : false;

  const viewName = results.query_var && results.query_var[0] ?
    results.query_var[0].view_name : '';

  if (!results[viewName] || Object.keys(results[viewName]).length === 0 || results.query_var.length === 0 && !type){
    type = 'asset_chart';
  }

  if (!staticVal && !isViewPresentInChartTypes(viewName) && isDynamicChart(viewName, results.query_var[0]) && !type){
    type = 'gauge_chart';
  }

  if (staticVal && ((viewName === "Top Holdings") || (viewName === "Asset Allocation") || (viewName === "Asset Allocation")) && !type){
    type = 'pie_chart';
  }

  if (staticVal && viewName === "Regions" && !type){
    type = 'burst_chart';
  }

  if (viewName === "Themes" && !type){
    type = 'carousel';
  }

  if(viewName == 'Sectors' && pfTable){
  	return '% ' + results.query_var[0]['Name of the chart on UI'];
  }

  const { query_var : query_var } = results;

  if(!type) {
    type = query_var && query_var[0] ? query_var[0].chart_type : null;
  }

  if(type == 'odometer'){
    const { view_name, Secondary_view_name } = query_var[0];
    let name = view_name + (Secondary_view_name ? ` (${Secondary_view_name})` : '');
    name = name.replace('ticker', query_var[0].data);
    return name;
  }else if(type == 'burst_chart'){
    const { view_name, node, ontology_node } = query_var[0]
    let centerDisplayText;
    const displayText = RegionChartMapping.filter(e => e.chart_name === node);
    centerDisplayText = displayText.length ? displayText[0].region_name : '';
    return centerDisplayText !== '' ? `% ${centerDisplayText}` : view_name;
  }else if(type == 'pie_chart'){
    const { view_name, Secondary_view_name, node, chart_center_abovetext, chart_center_belowtext } = query_var[0];
    let topTitle = view_name + (Secondary_view_name ? ' ' + Secondary_view_name : '');
    let centerDisplayText, dataVar;
    if (view_name === 'Diversification') {
      centerDisplayText = topTitle;
    }else if (view_name === 'Asset Allocation'){
      // No condition for chart title
    }else if (view_name === 'Top Holdings') {
      if (Object.prototype.toString.call(results.query_var[0].data) === '[object Array]') {
        dataVar = results.query_var[0].data[0];
      }
      else if (typeof results.query_var[0].data === 'string') {
        dataVar = results.query_var[0].data;
      }
      centerDisplayText = chart_center_belowtext.replace('ticker', dataVar);
    }else{
      const displayText = SectorChartMapping.filter(e => e.chart_name === node);
      centerDisplayText = displayText.length ? displayText[0].sector_name : '';
    }
    return centerDisplayText && centerDisplayText !== '' ? `% ${centerDisplayText}` : topTitle;
  }else if(type == 'carousel'){
    const { view_name, Secondary_view_name } = query_var[0];
    let topTitle = view_name + (Secondary_view_name ? ' ' + Secondary_view_name : '');
    return topTitle;
  }else if(type == 'gauge_chart'){
    const { view_name, Secondary_view_name } = query_var[0];
    let name = view_name + (Secondary_view_name ? ` (${Secondary_view_name})` : '');
    name = name.replace('ticker', query_var[0].data);
    return name;
  }else if(type == 'asset_chart'){
    return 'Assets';
  }else{
    return ''
  }

}

export const getColViewName = (name, chartData) => {
    // const { isSectorDataFoundInFunds } = this.props;
    let split_name = name.split(' & ')
    split_name.forEach((item, index) => {
      const currentChartData = chartData.filter(
        e => e.view_name == item
      )[0]
      let toBeDisplayedView
      if (currentChartData) {
        if (item == 'Regions' && currentChartData.data) {
          let displayText = RegionChartMapping.filter(
            e => e.chart_name === currentChartData.node
          )
          if (displayText.length) {
            toBeDisplayedView = '%\u00a0' + displayText[0].region_name
            split_name[index] = toBeDisplayedView
            // return name;
          } else split_name[index] = item
        } else if (item == 'Sectors' && currentChartData.data) {
          let find = SectorChartMapping.filter(
            e => e.chart_name === currentChartData.node
          )
          if (find.length > 0) {
            toBeDisplayedView = '%\u00a0' + find[0].sector_name
            split_name[index] = toBeDisplayedView
          } else split_name[index] = item
        } else if (
          item == 'Top Holdings' &&
          currentChartData.data &&
          (currentChartData.variable.includes('holdings_') ||
            currentChartData.variable.include('neg_holdings_'))
        ) {
          let ticker = ''
          if (Object.prototype.toString.call(currentChartData.data) === '[object Array]') {
            ticker = currentChartData.data[0].toUpperCase()
          } else if (typeof currentChartData.data === 'string') {
            ticker = currentChartData.data.toUpperCase()
          }
          if (ticker.length > 4) ticker = formatTickerForView(ticker)
          toBeDisplayedView = '%\u00a0' + ticker
          split_name[index] = toBeDisplayedView
          // return name;
        } else if(item == 'Highlight' && currentChartData.chart_type.includes('highlight_')){
           const itemType = currentChartData.chart_type.split('_');
           const availableTypes = ['Fees', 'Risk', 'Returns'];
           const type = availableTypes.find(e => e.toLowerCase() == itemType[1].toLowerCase())
           split_name[index] = type ? type : ''
        }else {
           split_name[index] = item
        }
      } else {
        split_name[index] = item
      }
			// for "view_name = performance vs ticker" case
			if (split_name && split_name[index] && split_name[index].includes('ticker')) {
				if (Object.prototype.toString.call(currentChartData.data) === '[object Array]' && currentChartData.data[0]) {
					split_name[index] = split_name[index].replace("ticker", currentChartData.data[0]);
				}
				else if (typeof currentChartData.data === 'string') {
					split_name[index] = split_name[index].replace("ticker", currentChartData.data);
				}
			}
    })

    if (split_name.length > 1) {
      return split_name[0] + ' & ' + split_name[1]
    } else {
      return split_name[0]
    }
  }

export const getModelName = (obj) => {
  if(Object.keys(obj.model_portfolio).length){
    return obj.model_portfolio.portfolio_name;
  }else if(Object.keys(obj.custom_portfolio).length){
    return obj.custom_portfolio.portfolio_name;
  }else{
    return 'Allocation Pending';
  }
}

// new mapCsvToJSON function

// executed when we upload csv file
export const mapCsvToJSON = (csv, filter) => {
	const invalidPriceFormat = new RegExp(/[ `!@#$%^&*()_+\=\[\]{};':"\\|,<>\/?~]/);
  let lines = csv.split(/\r\n|\n/)
  const identified_delimeter = findDelimiter(csv)
  if (lines.length > 1) {
    let result = [], inc = 1;
    let headers = lines[0].split(identified_delimeter)

    headers = headers
      .map(l => {
        l = convertToLowerCase(l);
				if (l === 'name' || l === 'names') l = 'name';
				if (l === 'account_number' || l === 'account_numbers' || l === 'account number' || l === 'account numbers') l = 'account_number';
				if (l === 'lot_date' || l === 'lot_dates' || l === 'lot date' || l === 'lot dates') l = 'lot_date';
				if (l === 'cost_basis' || l === 'cost basis') l = 'cost_basis';
				if (l === 'custodian' || l === 'custodians') l = 'custodian';
				if (l === 'ticker' || l === 'tickers') l = 'ticker';
				if (l === 'share' || l === 'shares') l = 'shares';
				if (l === 'price' || l === 'prices') l = 'price';
        return l;
      })
      .filter(item => item)

    if (!headers.length || headers.length < 5) {
      const obj = {
        error: `Please upload a file with columns - 'name', 'account_number', 'custodian', 'ticker', 'shares'.`,
        headers
      }
      return JSON.stringify(obj)
    }

    if (!headers.includes('name')) {
      const obj = {
        error: 'Name column is missing.',
				headers
      }
      return JSON.stringify(obj)
    }

		if (!headers.includes('account_number')) {
			const obj = {
				error: 'Account Number column is missing.',
				headers
			}
			return JSON.stringify(obj)
		}

		if (!headers.includes('custodian')) {
			const obj = {
				error: 'Custodian column is missing.',
				headers
			}
			return JSON.stringify(obj)
		}

		if (!headers.includes('ticker')) {
			const obj = {
				error: 'Tickers column is missing.',
				headers
			}
			return JSON.stringify(obj)
		}

		if (!headers.includes('shares')) {
			const obj = {
				error: 'Shares column is missing.',
				headers
			}
			return JSON.stringify(obj)
		}

    // console.log('-------lines------',lines);

    if (filter) {
			// NOTE: 'headersArray' is what expecting from csv.
			// NOTE: 'headers' is what we are getting from csv.
			// NOTE: 'addHeader' is additional header which not Mandatory.
      const { headersArray, maxHeadersCount, addHeader } = filter
      if (headersArray && headersArray.length > 0 && maxHeadersCount) {
				// console.log('headers --> ', headers);
				// console.log('headersArray --> ', headersArray);
				let finalHeader = [...headersArray];
				if (addHeader && addHeader.length) {
					finalHeader = [...finalHeader, ...addHeader];
				}
        if (headers.length <= maxHeadersCount) {
          // const datePresent = _.includes(headersArray, 'date')
          let found = headers.filter(l => {
            if (_.includes(finalHeader, l)) {
              return l
            }
          })
					// console.log('found --> ', found);
          if (found.length === headers.length) {
            let uniqueTickers = [], uniqueAccountNumber = [];
            lines.forEach((item, i) => {
              let obj = {}, holdingsData = {};
              const commaDelimiter = /,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/g
              let line_delimeter = identified_delimeter
              if (identified_delimeter == ',') {
                line_delimeter = commaDelimiter
              }
              let currentline = lines[i].split(line_delimeter)
              found.forEach((y, k) => {
                obj[found[k]] = currentline[k]
              });
							// NOTE: Step: 1.a -> Check Mandatory Fields 'Name', 'Custodian', 'Account Number'.
							if (obj.name && obj.custodian && obj.account_number) {
								// obj.refID = inc;
								obj.name = formatValues(obj.name);
								obj.custodian = formatValues(obj.custodian);
								obj.account_number = formatValues(obj.account_number);
							}
							// NOTE: Step: 1.b -> Check Mandatory Fields 'Ticker', 'Shares'.
							if (obj.ticker && obj.shares) {
								holdingsData = {
									ticker: convertToUpperCase(obj.ticker),
									shares: convertToFloatvalues(obj.shares),
								};
							}
							// console.log('uniqueAccountNumber --> ', uniqueAccountNumber);
							if (obj.account_number && formatValues(obj.account_number)) {
								// console.log('row ->', obj);
								let foundAccountNumber = uniqueAccountNumber.includes(formatValues(obj.account_number)); // result.find((kl) => kl.account_number === numberCheck(obj.account_number))
								// console.log('foundAccountNumber --> ', foundAccountNumber, obj.account_number);
								if (!foundAccountNumber) {
									if (typeof obj.price !== 'undefined') {
										if (invalidPriceFormat.test(obj.price)) {
											holdingsData.price = obj.price.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
											holdingsData.price = convertToFloatvalues(holdingsData.price);
										} else {
											holdingsData.price = convertToFloatvalues(obj.price);
											holdingsData.price = convertToFloatvalues(holdingsData.price);
										}
										// after assinging in 'holdings', delete it from 'Obj'
										delete obj.price;
									}
									if (typeof obj.lot_date !== 'undefined') {
										holdingsData.lot_date = formatValues(obj.lot_date);
										// after assinging in 'holdings', delete it from 'Obj'
										delete obj.lot_date;
									}
									if (typeof obj.cost_basis !== 'undefined') {
										holdingsData.cost_basis = formatValues(obj.cost_basis);
										// after assinging in 'holdings', delete it from 'Obj'
										delete obj.cost_basis;
									}
									// obj.refID = inc;
									obj.holdings = [{
										...holdingsData,
									}];
									result.push(obj);
									uniqueAccountNumber.push(formatValues(obj.account_number));
								} else {
									// NOTE: 'account_number' already present in 'uniqueAccountNumber', so merge holdings (ticker) data;
									// console.log('account_number already present ->', obj.account_number, obj.name);
									// NOTE: when account_number is already present, then populate 'holdings' in 'result' array
									// console.log('result --> ', result);
									if (result && result.length) {
										result.forEach((kl) => {
											// NOTE: check if ticker is already in holdings array.
											if (kl && kl.account_number && kl.account_number === obj.account_number) {
												if (kl.holdings && kl.holdings.length) {
													let foundInHoldings = kl.holdings.find((item) => item.ticker === holdingsData.ticker);
													if (!foundInHoldings) {
														// found unique ticker, so push into 'holdings' array.
														if (typeof obj.price !== 'undefined') {
															if (invalidPriceFormat.test(obj.price)) {
																holdingsData.price = obj.price.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
																holdingsData.price = convertToFloatvalues(holdingsData.price);
															} else {
																holdingsData.price = convertToFloatvalues(obj.price);
																holdingsData.price = convertToFloatvalues(holdingsData.price);
															}
															// after assinging in 'holdings', delete it from 'Obj'
															delete obj.price;
														}
														if (typeof obj.lot_date !== 'undefined') {
															holdingsData.lot_date = formatValues(obj.lot_date);
															// after assinging in 'holdings', delete it from 'Obj'
															delete obj.lot_date;
														}
														if (typeof obj.cost_basis !== 'undefined') {
															holdingsData.cost_basis = formatValues(obj.cost_basis);
															// after assinging in 'holdings', delete it from 'Obj'
															delete obj.cost_basis;
														}
														kl.holdings.push(holdingsData);
													} else {
														// ticker already present in 'holdings' array.
														// NOTE: Merge duplicate ticker data
														// Add duplicate values of tickers shares, amount and weight
														// NOTE: 'obj' refers to particular 'row' in csv.
														// NOTE: 'kl' refers to 'result' (expected ARRAY output).
														// NOTE: 'item' refers to particular 'row' in 'result'.
														kl.holdings = kl.holdings.map(item => {
															if (convertToUpperCase(item.ticker) === convertToUpperCase(obj.ticker)) {
																if (item.shares && obj.shares) {
																	item.shares = convertToFloatvalues(item.shares) + convertToFloatvalues(obj.shares);
																}
																if (item.price && obj.price) {
																	item.price = convertToFloatvalues(item.price) + convertToFloatvalues(obj.price);
																}
																if (item.cost_basis && obj.cost_basis) {
																	item.cost_basis = convertToFloatvalues(item.cost_basis) + convertToFloatvalues(obj.cost_basis);
																}
																if (item.lot_date && obj.lot_date) {
																	// recent 'lot_date';
																	item.lot_date = formatValues(obj.lot_date);
																}
																return item
															} else {
																if (item && item.ticker) {
																	return item;
																}
															}
														});
													}
												}
											}
										});
									} else {
										console.log('-- result array is empty, even when account_number is already present --', result, obj.account_number, obj.name);
									}
								}
							}

						});

						// NOTE: Do your stuff with 'results' variable
						result = result.filter((item) => item.name !== 'name' && item.custodian !== 'custodian' && item.account_number !== 'account_number' && item.ticker !== 'ticker')
													 .filter((h) => h && typeof h !== 'undefined');

						result.forEach((kl, ki) => kl.refID = ki+1);
						console.log(result);
						//--------- upto here -------

            return JSON.stringify(result)
          } else {
            const obj = {
              error: `Invalid csv format, ${headers.toString()}`,
              // error: `Invalid csv format, ${headers.toString()}`,
              headers
            }
            return JSON.stringify(obj)
          }
        } else {
          const obj = {
            error: `Invalid csv format, ${headers.toString()}`,
            // error: `Invalid csv format, ${headers.toString()}`,
            headers
          }
          return JSON.stringify(obj)
        }
      } else {
        const obj = {
          error: `Invalid csv format, ${headers.toString()}`,
					// error: `Invalid csv format, ${headers.toString()}`,
					headers
        }
        return JSON.stringify(obj)
      }
    } else {
      // console.log(lines.splice(0, 1));
      lines.forEach((item, i) => {
        let obj = {}
        let currentline = lines[i].split(identified_delimeter)
        headers.forEach((y, k) => {
          obj[headers[k]] = currentline[k]
        })
        result.push(obj)
      })
      return JSON.stringify(result)
    }
  } else {
    console.log('not enough lines', lines)
    const obj = {
      error: 'Not Enough Lines',
    }
    return JSON.stringify(obj)
  }
}

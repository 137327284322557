import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { createLoadingSelector } from 'selectors/loading-selector';

class TrendingSearch extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      searchQueryList:[
        'Accounts that are down this quarter',
        'Clients with high cash positions',
        'Accounts that are up this year',
        'Accounts overweighted in Tesla',
        'Accounts with fees over 1 bps',

        'Accounts that are down this quarter',
        'Clients with high cash positions',
        'Accounts that are up this year',
        'Accounts overweighted in Tesla',
        'Accounts with fees over 1 bps',

        'Accounts that are down this quarter',
        'Clients with high cash positions',
        'Accounts that are up this year',
        'Accounts overweighted in Tesla',
        'Accounts with fees over 1 bps',

        'Accounts that are down this quarter',
        'Clients with high cash positions',
        'Accounts that are up this year',
        'Accounts overweighted in Tesla',
        'Accounts with fees over 1 bps',

        'Accounts that are down this quarter',
        'Clients with high cash positions',
        'Accounts that are up this year',
        'Accounts overweighted in Tesla',
        'Accounts with fees over 1 bps',
      ],
    }
  }

  render() {
    const { searches, searchQuery, clientListLoading } = this.props;
    const { searchQueryList } = this.state;

    const searchQueryF = (q) =>{
      if(!clientListLoading) {
        searchQuery(q);
      }
    }
    return (
      <Row className='trending-search'>
        <Col span={6} className='trending-search-label'>Trending Searches: </Col>
        <Col span={18} className={`trending-marquee-container ${clientListLoading ? 'disabled': ''}`}>
          <div className='trending-search-marquee'>
            {searchQueryList.map((item,i) => (
              <span key={i} id={i} title={item} className="query-text" onClick={() => searchQueryF(item)}>{item}</span>
            ))}
          </div>
        </Col>
      </Row>
    )}
}

const mapStatetoProps = ({loading, trendingSearches : { searches } , clientportfolioV2 : { clientListLoading } } ) => ({
  searches,
  clientListLoading: createLoadingSelector(['GET_CLIENT_LIST_BY_QUERY'])({ loading }) || clientListLoading,
})

export default connect(
  mapStatetoProps
)(TrendingSearch);

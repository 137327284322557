import React, { Component } from 'react';
import './_index.scss';
import { Row, Col } from 'reactstrap';


class VidIntro extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }


  render() {
    return (
        <div className="vid-container r32">
          <div className="vid-container-title">What we're about.</div>
          <div className="vid-container-subtitile">Here's a quick introduction showing the capabilities of our platform.</div>
          <div className="vid-container-video">
          <iframe title="Magnifi - Discover a world of investing" src="https://player.vimeo.com/video/383181963?title=0&amp;byline=0&amp;portrait=0?dnt=1&amp;app_id=122963" width="840" height="473" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen=""></iframe>
          </div>
        </div>
    );
  }
}


export default VidIntro;

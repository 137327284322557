import React from 'react';

export const NoClientsComponent = (props) => {
  return (
  	<div className="w-100 text-center">
	    <div className="flex-column align-item-center" style={{marginTop:'5%'}}>
	      <img width={118} src="/assets/images/ErrorNoAcc.svg" />
	      <span className="mt-2 no-account" style={{paddingTop:'18px'}} >{props.message ? props.message : 'No clients to display!'}</span>
		  <span className='no-add-account'style={{marginTop:'40px'}}>{props.child ? props.child : ''}</span>
	    </div>
    </div>
  );
}

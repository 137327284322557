import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer className="app-footer footer-fixed text-muted w-100" style={{background: '#A7A9AC'}}>
        <div className="container-fluid py-2 main-container__footer">
          <div className="row">
            <div className="col-12" style={{ textAlign: 'center', margin: 'auto 0' }}>
              
                <div style={{ float: "center", marginTop: "-2px" }}>
                  <a className="footer-link" href={'/'}><img src="/assets/images/MagnifiLogo/Magnifi_Logo_Charcoal_RGB.png" width="60" alt="Magnifi"/></a>
                  <span className="h5 ml-0">&nbsp;&copy; 2018 - 19</span>
                  <span className="float-right"><a className="footer-link" href={'https://www.linkedin.com/company/magnififinance/'} target="_blank"><img src="/assets/images/LinkedIn-Blue-21@2x.png" height="19" alt="OpenSeSaFi LinkedIn"/></a></span>
                </div>
              
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;

import React from 'react'
import { ButtonGroup } from 'reactstrap'
import { PrimaryButton } from 'shared-components'
import paymentSuccessImage from '../../../../../assets/images/payment-success.png'

class PaymentSuccessMessage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <React.Fragment>
        <div className="premium-feature-process-modal-container payment-status-modal">
          <div className="payment-message-image text-align-center">
            <img src={'/assets/images/congratulations-Icon.svg'} alt="Congratulations"/>
          </div>
          <div className="payment-status-heading">
            <h1 className="text-align-center payment-success">Congratulations!</h1>
          </div>
          <i className="fal fa-times-circle premium-feature-completed-close close-light" onClick={this.props.closePaymentStatus}></i>
          <p className="text-align-center mb-0">
            You can now experience the full power of Magnifi!
          </p>
        </div>
      </React.Fragment>
    )
  }
}

export default PaymentSuccessMessage

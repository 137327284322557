import ActionTypes from '../action-types'

export function load(payload) {
  return {
    type: ActionTypes.LOAD_VIDEO,
    payload,
  }
}

export function playPause(payload) {
  return {
    type: ActionTypes.PLAY_PAUSE_VIDEO,
    payload,
  }
}

export function pip(payload) {
  return {
    type: ActionTypes.PIP_VIDEO,
    payload,
  }
}

export function stop(payload) {
  return {
    type: ActionTypes.STOP_VIDEO,
    payload,
  }
}

export function toggleLoop(payload) {
  return {
    type: ActionTypes.TOGGLE_LOOP_VIDEO,
    payload,
  }
}

export function setVolume(payload) {
  return {
    type: ActionTypes.SET_VOLUME_VIDEO,
    payload,
  }
}

export function toggleMuted(payload) {
  return {
    type: ActionTypes.TOGGLE_MUTED_VIDEO,
    payload,
  }
}

export function setPlaybackRate(payload) {
  return {
    type: ActionTypes.SET_PLAYBACK_RATE_VIDEO,
    payload,
  }
}

export function onPlay(payload) {
  return {
    type: ActionTypes.ON_PLAY_VIDEO,
    payload,
  }
}

export function onEnablePIP(payload) {
  return {
    type: ActionTypes.ON_ENABLE_PIP_VIDEO,
    payload,
  }
}

export function onDisablePIP(payload) {
  return {
    type: ActionTypes.ON_DISABLE_PIP_VIDEO,
    payload,
  }
}

export function onPause(payload) {
  return {
    type: ActionTypes.ON_PAUSE_VIDEO,
    payload,
  }
}

export function onSeekMouseDown(payload) {
  return {
    type: ActionTypes.ON_SEEK_MOUSE_DOWN_VIDEO,
    payload,
  }
}

export function onSeekChange(payload) {
  return {
    type: ActionTypes.ON_SEEK_CHANGE_VIDEO,
    payload,
  }
}

export function onSeekMouseUp(payload) {
  return {
    type: ActionTypes.ON_SEEK_MOUSE_UP_VIDEO,
    payload,
  }
}

export function onProgress(payload) {
  return {
    type: ActionTypes.ON_PROGRESS_VIDEO,
    payload,
  }
}

export function onEnded(payload) {
  return {
    type: ActionTypes.ON_ENDED_VIDEO,
    payload,
  }
}

export function onDuration(payload) {
  return {
    type: ActionTypes.ON_DURATION_VIDEO,
    payload,
  }
}

export function updatePlayerState(payload) {
  return {
    type: ActionTypes.UPDATE_VIDEO_STATE,
    payload,
  }
}

export function refreshVideoState() {
  return {
    type: ActionTypes.REFRESH_VIDEO_STATE,
  }
}

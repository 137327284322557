import React from 'react'
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  UncontrolledDropdown
} from 'reactstrap'
import FlipMove from 'react-flip-move'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import LinesEllipsis from 'react-lines-ellipsis'
import { addEventToAnalytics } from 'Utils';
// import { DatePicker } from 'antd';
import moment from 'moment'
import { dateToMonth } from 'Utils';

import { sponsorLogo, flipMoveElevator } from 'DataSet'

// const { RangePicker } = DatePicker;

export class DateRangeSelector extends React.Component {
  opts = [
    { name: 'All funds active', code: 'active' },
    { name: 'Last 1 Year', code: 'l1y' },
    { name: 'Last 3 Year', code: 'l3y' },
    { name: 'Last 5 Year', code: 'l5y' }
  ]

  optsRef = [ 'ignore', 1, 3, 5]

  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
      //view: this.codeToName(props.range),
      initialYearRange: [],
      allowedStartYearRange: [],
      allowedEndYearRange: [],
      initialMonthRange: [],
      allowedStartMonthRange: [],
      allowedEndMonthRange: [],
      startMonth: '',
      startYear: '',
      endMonth: '',
      endYear: '',
    }
  }

  componentWillMount(){
    // console.log(this.props.dateRange);
    this.initializeDateRange(this.props.dateRange);
  }

  componentDidUpdate(prevProps){
    // This will be triggered when a fund/ticker is added or removed from the sf modal
    if(prevProps.activeLabel !== this.props.activeLabel){
      this.initializeDateRange(this.props.dateRange);
    }
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  rangeHandler(val) {
    if(this.props.handleDateChangeCombineScore){
      this.props.handleDateChangeCombineScore({
        interval: val.code
      })
    }

    //this.setState({ view: val.name });
    this.props.rangeHandler(this.props.col, val.code)
  }

  onDateChange = (date, dateString) => {
    const selectedRange = {
      start: date[0],
      end: date[1]
    }
    const { dropdownScoreAttrs } = this.props;
    // console.log(dropdownScoreAttrs);
    let range = {
      // return: selectedRange,
      // risk: selectedRange,
      // weight_trend: selectedRange,
      // weight_fund_flows: selectedRange,
      // weight_premium_to_nav: selectedRange,
      fromDatePicker: true,
    }
    if (dropdownScoreAttrs && dropdownScoreAttrs.length > 0) {
      dropdownScoreAttrs.filter((jk) => jk.yearDropdown).map((nl) => {
        if (nl.code) {
          range[`${nl.code}`] = selectedRange
        }
      });
    }
    // range.fromDatePicker = true;

    this.props.rangeHandler(this.props.col, range);
  }

  initializeDateRange = (dateRange) => {
    // console.log(JSON.parse(JSON.stringify(dateRange)));
    const currDate = new Date();
    const currYear = currDate.getFullYear();

    let initialYearRange = [];
    const startYear = moment(dateRange.start).format('YYYY');
    // console.log(startYear);

    for(let i=currYear; i>=startYear; i--){
      initialYearRange.push(i);
    }

    const initialMonthRange = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    this.setState({
      initialYearRange,
      allowedStartYearRange: initialYearRange,
      allowedEndYearRange: initialYearRange,
      initialMonthRange,
      allowedStartMonthRange: initialMonthRange,
      allowedEndMonthRange: initialMonthRange,
      startMonth: moment(dateRange.start).format('MMM'),
      startYear: Number(moment(dateRange.start).format('YYYY')),
      endMonth: moment(dateRange.end).format('MMM'),
      endYear: Number(moment(dateRange.end).format('YYYY')),
    }, () => this.filterEndMonthYearRange(false));
  }

  changeSelectedRange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    }, () =>  this.filterEndMonthYearRange());
  }

  updateSelectedRange = () => {
    if(this.props.handleDateChangeCombineScore){
      this.props.handleDateChangeCombineScore({
        start: `${this.state.startMonth} ${this.state.startYear}`,
        end: `${this.state.endMonth} ${this.state.endYear}`
      })
    }

    const selectedRange = {
      start: moment(new Date('01-'+this.state.startMonth+'-'+this.state.startYear)), // SAFARI support 2015-07-06 not 'Jul 2020'
      end: moment(new Date('01-'+this.state.endMonth+'-'+this.state.endYear)), // SAFARI support 2015-07-06 not 'Jul 2020'
    }
    // console.log(selectedRange);
    const { dropdownScoreAttrs } = this.props;
    // console.log(dropdownScoreAttrs);
    // let range = {
    //   return: selectedRange,
    //   risk: selectedRange,
    //   fromDatePicker: true,
    // };
    let range = {
      // return: selectedRange,
      // risk: selectedRange,
      // weight_trend: selectedRange,
      // weight_fund_flows: selectedRange,
      // weight_premium_to_nav: selectedRange,
      fromDatePicker: true,
    }
    if (dropdownScoreAttrs && dropdownScoreAttrs.length > 0) {
      dropdownScoreAttrs.filter((jk) => jk.yearDropdown).map((nl) => {
        if (nl.code) {
          // console.log(nl.code);
          range[`${nl.code}`] = selectedRange
        }
      })
    }
    // range.fromDatePicker = true;

    this.props.rangeHandler(this.props.col, range);
  }

  filterEndMonthYearRange = (shouldUpdate = true) => {
    // shouldUpdate is to check if we just want to filter the dropdown values or to submit the selected values
    // during initialization shouldUpdate must be set to false

    // Logic to filter the allowed end year range based on the selected start year
    const allowedEndYearRange = this.state.initialYearRange.filter(item => item >= this.state.startYear);
    let endYear = allowedEndYearRange.includes(Number(this.state.endYear)) ? this.state.endYear : allowedEndYearRange[0];

    let allowedStartMonthRange = this.state.initialMonthRange;
    let allowedEndMonthRange = this.state.initialMonthRange;
    let endMonth = this.state.endMonth;
    let startMonth = this.state.startMonth;

    // Logic to disable selection of future dates
    const currDate = new Date();
    const currYear = currDate.getFullYear();
    const currMonth = currDate.getMonth();

    if(Number(this.state.startYear) === currYear){
      allowedStartMonthRange = this.state.initialMonthRange.filter((item, index) => index <= currMonth);
      startMonth = allowedStartMonthRange.includes(this.state.startMonth) ? this.state.startMonth : allowedStartMonthRange[0];
    }

    if(Number(this.state.endYear) === currYear){
      allowedEndMonthRange = this.state.initialMonthRange.filter((item, index) => index <= currMonth);
      endMonth = allowedEndMonthRange.includes(this.state.endMonth) ? this.state.endMonth : allowedEndMonthRange[0];
    }

    // Logic to disable selction of date lesser than the start date
    if(Number(this.state.startYear) === Number(this.state.endYear)){
      let startMonthIndex = this.state.initialMonthRange.indexOf(this.state.startMonth);
      allowedEndMonthRange = allowedEndMonthRange.filter((item, index) => index >= startMonthIndex);
      endMonth = allowedEndMonthRange.includes(this.state.endMonth) ? this.state.endMonth : allowedEndMonthRange[0];
    }

    this.setState({ allowedEndYearRange, allowedEndMonthRange, allowedStartMonthRange, endMonth, endYear }, () => shouldUpdate && this.updateSelectedRange())
  }

  codeToName = (code) => {
    let selectedOpt = this.opts.find(e => e.code === code);
    return selectedOpt ? selectedOpt.name : dateToMonth(this.props.range.start) + ' - ' + dateToMonth(this.props.range.end);
  }

  render() {
    const { initialYearRange, allowedStartYearRange, allowedEndYearRange, initialMonthRange, allowedStartMonthRange, allowedEndMonthRange, startMonth, startYear, endMonth, endYear } = this.state;

    if (this.props.activeLabel) this.opts[0].name = this.props.activeLabel;

    if (this.props.displayYear && this.props.range) {
      const displayYear = this.codeToName(this.props.range);
      // console.log(displayYear);
      this.props.displayYear(displayYear);
    }
    // console.log(this.props.range);
    const activeLabel = this.props.fromDatePicker && this.props.range && this.props.range.start ? dateToMonth(this.props.range.start) + ' - ' + dateToMonth(this.props.range.end) : this.codeToName(this.props.range);
    return (
      <div className="d-flex-datepicker">
        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle
            caret
            color="link"
            className="h6 text-gray-8 my-1 lh-100 sfmodal-date"
          >
            {activeLabel}
          </DropdownToggle>
          <DropdownMenu
            className="rounded-0 h6 py-1"
            style={{ minWidth: '5rem' }}
          >
          {this.props.accountCreatedDate && typeof this.props.accountCreatedDate !== 'undefined' ? this.opts.map((v, ini) => {
            if(typeof this.optsRef[ini] === 'string' || (typeof this.optsRef[ini] === 'number' && moment().diff(moment(this.props.accountCreatedDate), 'years') >= this.optsRef[ini])){
              return (
                <DropdownItem
                  key={v.code}
                  className="py-1"
                  onClick={() => this.rangeHandler(v)}
                >
                  {v.name}
                </DropdownItem>
              )
            }
            return null
          }) : this.opts.map(v => (
            <DropdownItem
              key={v.code}
              className="py-1"
              onClick={() => this.rangeHandler(v)}
            >
              {v.name}
            </DropdownItem>
          )) }
          {/* <DropdownItem divider style={{margin: '0.3rem 0'}} /> */}

          {!this.props.noCustomDate ?<UncontrolledDropdown className="dropdown-datepicker" direction="left">

              <DropdownItem className="py-1">
                <DropdownToggle tag="span" className="user-menu__link spaced-icon">
                  <span>
                    Custom
                  </span>
                  <i class="fas fa-chevron-right chev"></i>
                </DropdownToggle>
              </DropdownItem>

              <DropdownMenu className="custom-timeline h6">

                <DropdownItem className="py-1" toggle={false}>

                  <div className="date-select">
                    <span className="select-label">From:</span>
                    <span>
                      <select value={startYear} name="startYear" onChange={this.changeSelectedRange}>
                        { allowedStartYearRange.map((item, i) => <option key={i} value={item}>{item}</option>) }
                      </select>
                    </span>
                    <span>
                      <select value={startMonth} name="startMonth" onChange={this.changeSelectedRange}>
                        { allowedStartMonthRange.map((item, i) => <option key={i} value={item}>{item}</option>) }
                      </select>
                    </span>
                  </div>

                </DropdownItem>

                <DropdownItem className="py-1" toggle={false}>

                  <div className="date-select">
                    <span className="select-label">To:</span>
                    <span>
                      <select value={endYear} name="endYear" onChange={this.changeSelectedRange}>
                        { allowedEndYearRange.map((item, i) => <option key={i} value={item}>{item}</option>) }
                      </select>
                    </span>
                    <span>
                      <select value={endMonth} name="endMonth" onChange={this.changeSelectedRange}>
                        { allowedEndMonthRange.map((item, i) => <option key={i} value={item}>{item}</option>) }
                      </select>
                    </span>
                  </div>

                </DropdownItem>
              </DropdownMenu>

            </UncontrolledDropdown>:null}

          </DropdownMenu>
        </ButtonDropdown>
        {/*<RangePicker
          picker="month"
          placeholder={['Start', 'End']}
          value={
            [
              moment(this.props.dateRange.start),
              moment(this.props.dateRange.end)
            ]
          }
          onChange={this.onDateChange}
        />*/}
      </div>
    )
  }
}

export class AttrCharts extends React.Component {
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const weights = result.map(({ weight }) => weight)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result.map((attr, i) => ({
      ...attr,
      weight: weights[i]
    }))
  }

  onDragEnd = result => {
    const { funds } = this.props;
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const newList = this.reorder(
      this.props.scoreAttrs,
      result.source.index,
      result.destination.index
    )
    this.props.scoreAttrsHandler2(newList)
    if (funds) {
      //.addKrakenEvent({name: this.props.tab ? this.props.tab : 'Weight Reorder', body: {query: funds.query, logId: funds.logId}});
      addEventToAnalytics('Compare weight reorder','Compare weight reorder','COMPARE_WEIGHT_REORDER',{ query: funds.query, logId: funds.logId },true);
    }
  }

  getItemStyle = (isDragging, draggableStyle) => {
    /* draggableStyle sets position to fixed and top, left values relative to body.
       But within modal it positions relative to modal making item placement shifted to right/bottom.
       Hack to make item position correct. */

    let hackeddraggableStyle = {
      ...draggableStyle,
      position: draggableStyle.position === 'fixed' ? 'absolute' : 'unset',
      left: 'unset',
      top: 'unset'
    }

    return {
      userSelect: 'none',
      background: isDragging ? 'lightblue' : '#6c757d',
      ...hackeddraggableStyle
    }
  }

  getListStyle = isDraggingOver => ({
    //background: isDraggingOver ? '#6c757d' : '#6c757d',
  })

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={this.getListStyle(snapshot.isDraggingOver)}
            >
              <FlipMove
                {...flipMoveElevator}
              >
                {this.props.filterScoreattrs.map((e, i) => {
                  if(!e.weight && !this.props.isMagnifiactivescreener) {
                    return null;
                  }

                  return (
                  <Draggable key={e.col} draggableId={e.col} index={i}>
                    {(provided, snapshot) => (
                      <div
                        className="draggable rounded-bottom"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={this.getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <this.props.chart
                          idx={i + 1}
                          attr={e}
                          sinceDate={this.props.sinceDate}
                          dropdownScoreAttrs={this.props.dropdownScoreAttrs}
                          scoreAttrs={this.props.scoreAttrs}
                          items={this.props.dataItems}
                          allWtStats={this.props.allWtStats}
                          allWtStatsBase={this.props.allWtStatsBase}
                          type={this.props.type}
                          range={this.props.range}
                          dateRange={this.props.dateRange}
                          rangeHandler={this.props.rangeHandler}
                          displayYear={this.props.displayYear}
                          scoreAttrsHandler={this.props.scoreAttrsHandler}
                          filterScoreattrs={this.props.filterScoreattrs}
                          commonActiveSFMReturnsRange={this.props.commonActiveSFMReturnsRange}
                        />
                      </div>
                    )}
                  </Draggable>
                )})}
                {provided.placeholder}
              </FlipMove>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }
}

export const FooterActions = ({ backHandler, clearHandler, hideBack }) => {
  return (
    <div className="d-flex mr-auto">
      {!hideBack && (
        <Button
          color="select"
          outline
          className="h3 p-2 mb-0 mr-1 comm border-0 footer-delete-icon "
          onClick={backHandler}
          title="Back"
        >
          <i className="far fa-fw fa-long-arrow-left"></i>
        </Button>
      )}
      <Button
        color="select"
        outline
        className="h3 mb-0  mr-1 comm border-0 footer-delete-icon"
        onClick={() => {
          clearHandler();
          addEventToAnalytics('SFM Clear Cart','SFM Clear Cart','SFM_CLEAR_CART',{ clickBtn: 'Delete' },true);
        }}
        title="Clear"
      >
        <i className="far fa-fw fa-trash-alt"></i>
      </Button>
    </div>
  )
}

export const TruncatedText = ({ text, lines = 1 }) => {
  return (
    <LinesEllipsis
      text={text}
      title={text}
      maxLine={lines}
      trimRight={true}
      basedOn="letters"
    />
  )
}

export const TruncatedName = ({ name, lines = 2 }) => {
  return (
    <h5
      className="color-sfmodal-header mb-0 media-f-md media-text-overflow`"
      style={{ fontWeight: '600', lineHeight: '1.3' }}
    >
      <LinesEllipsis
        text={name}
        title={name}
        maxLine={lines}
        trimRight={true}
        basedOn="letters"
      />
    </h5>
  )
}

export const TruncatedNameSponsor = ({
  ticker,
  name,
  sponsor,
  lines = 2,
  imageValidPath,
  style,
  showSponsorLogo = false,
}) => {
  const sty = {
    // marginBottom: '2px',
    ...style
  }
  return (
    <React.Fragment>
      <div className="truncated-name-card" style={sty}>
        {ticker && <h5 className="mb-0 ticker-price pb-1">{ticker}</h5>}
        {showSponsorLogo && <div className="sponsored-logo-tag">SPONSORED</div>}
        <div className="funds-image-holder pb-1">
          {imageValidPath && sponsor ? (
            <img
              src={sponsorLogo(sponsor)}
              className="img-fluid"
              alt={sponsor}
              style={{ height: '20px' }}
            />
          ) : (
            <div className="sponsor-logo-placeholder" title={sponsor || ''}>
              {sponsor || ''}
            </div>
          )}
        </div>
        {/* <TruncatedName name={name} lines={lines} /> */}
        <p
          className={`funds-name color-sfmodal-header mb-0 p-0 media-f-md media-text-overflow text-overflow-ellipse-line-${lines}`}
          style={{ fontWeight: '600', lineHeight: '1.2' }}
          title={name}
        >
          {name}
        </p>
      </div>
    </React.Fragment>
  )
}

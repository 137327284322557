import React, { Component } from 'react';
import { SimpleLineChart } from 'components/Charts';

class ReturnsChart extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   dimensions: null,
    //   returnsChartDesign: null,
    // }
    this.itemsKey = props.itemsKey;
  }

  // componentDidMount() {
  //   console.log(this.container, this.container.offsetWidth);
  //   if (this.container !== null && typeof this.container.offsetWidth !== 'undefined') {
  //     const { smallScreen, bigScreen } = this.props;
  //     const chartWidth = this.container ? this.container.offsetWidth : (smallScreen ? 270 : (bigScreen ? 410 : 360));
  //     const lineChartWidth = chartWidth - 30;
  //     const lineChartHeight = smallScreen ? 80 : (bigScreen ? 91 : 90);
  //     this.setState({
  //       dimensions: {
  //         width: lineChartWidth,
  //         height: lineChartHeight,
  //       },
  //       returnsChartDesign: <SimpleLineChart width={lineChartWidth} height={lineChartHeight} {...this.props} />,
  //     }, () => {
  //       console.log(lineChartWidth, lineChartHeight ,this.state);
  //     });
  //   }
  // }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.itemsKey !== nextProps.itemsKey) {
      this.itemsKey = nextProps.itemsKey;
      return true;
    }
    return false;
  }

  render() {
    // const { dimensions } = this.state;
    // console.log(this.state);
    // const renderDesign = this.state.dimensions ? this.state.returnsChartDesign : null;

    // return (
    //   <div className="position-relative h-100 card-background d-flex align-items-center justify-content-center w-100" ref={el => (this.container = el)}>
    //     {renderDesign}
    //   </div>
    // )

    // if (data.children.length === 0) {
    //     return <NotApplicable />;
    // }

    return (
      <SimpleLineChart {...this.props} />
    )
  }
}

export default ReturnsChart;

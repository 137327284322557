// absolute imports
import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import DocumentTitle from 'react-document-title';
import { ContainerQuery } from 'react-container-query';
import { Layout, Result } from 'antd';
import { Route, Switch, Redirect } from 'react-router-dom';

import { logout } from 'actions/auth';
import { setGlobalState } from 'actions/global';
import { subscriptionPlan, setProfileState, fetchProfile, setPremiumState, premiumFeatureAccessRequest } from 'actions/profile';
import { getClientListRequest } from 'actions/investment';
import { setPortfolioState } from 'actions/portfolio';
import { createLoadingSelector } from 'selectors/loading-selector';
import Authorized from 'common/Authorized';
import { PremiumModal } from 'components/Premium';
import { getWindowScreen } from 'Utils';

// relative imports
import { Loader } from './Elements/Loader';
import OnBoardScreen from './Pages/OnBoardScreen/OnBoardScreen';
import Dashboard from './Pages/Dashboard';
import OpenSummaryPage from './Pages/OpenSummaryPage';

import { getRoutes } from './utils';
import { getMenuData } from './routermenu';
import { getRouterData } from './EnhancerRouter';
import { InnerPageFooter } from 'shared-components/'

const { Content } = Layout;
const { AuthorizedRoute, check } = Authorized;

const redirectData = [];
const getRedirect = (item) => {
  if (item && item.children) {
    if (item.children[0] && item.children[0].path) {
      redirectData.push({
        from: `${item.path}`,
        to: `${item.children[0].path}`,
      });
      item.children.forEach((children) => {
        getRedirect(children);
      });
    }
  }
};
getMenuData().forEach(getRedirect);

const containerQuery = {
  'screen-xs': {
    maxWidth: 575,
  },
  'screen-sm': {
    minWidth: 576,
    maxWidth: 767,
  },
  'screen-md': {
    minWidth: 768,
    maxWidth: 991,
  },
  'screen-lg': {
    minWidth: 992,
    maxWidth: 1199,
  },
  'screen-xl': {
    minWidth: 1200,
  },
};

class EnhancerRouter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      headerHeight: '',
      notify: false,
      premiumModal: false,
      premiumFeature: 'false'
    };
  }

  componentDidMount() {
    document.body.style.paddingTop = 0;
    const {
      setGlobalState,
      selectedPortfolioType,
      portfolioName,
      similarityMeasures,
      query,
      fundUniverse,
      modelUniverse,
      tickers,
      searchTypeMode,
      callbackPath,
      selectedWeightOptions,
    } = this.props;

    const windowScreen = getWindowScreen();
    if (setGlobalState && windowScreen) {
      setGlobalState({
        ...windowScreen,
      });
    }

    if (portfolioName && portfolioName !== '' && selectedPortfolioType && selectedPortfolioType !== '') {
      let jObj = {
        "portfolioType": selectedPortfolioType,
        "portfolioId": portfolioName,
      };
      if (query === 'user_criteria' && selectedWeightOptions && selectedWeightOptions.value) {
        jObj.weight = selectedWeightOptions.value;
      }
      switch (searchTypeMode) {
        case 'FUNDS':
          jObj = {
            "q": query,
            "similarity-measures": similarityMeasures,
            "tickers": tickers,
            "universe": fundUniverse,
            "portfolioType": selectedPortfolioType,
            "portfolioId": portfolioName,
          };
          if (callbackPath && callbackPath !== '') {
            jObj.callbackPath = callbackPath;
          }
          // redirect to enhancer/dashboard/FUNDS
          this.routeTo(selectedPortfolioType, 'dashboard/funds', jObj);
          break;
        case 'MODELS':
          jObj = {
            "q": query,
            "similarity-measures": similarityMeasures,
            "tickers": tickers,
            "universe": modelUniverse,
            "portfolioType": selectedPortfolioType,
            "portfolioId": portfolioName,
          };
          if (callbackPath && callbackPath !== '') {
            jObj.callbackPath = callbackPath;
          }
          // redirect to enhancer/dashboard/MODELS
          this.routeTo(selectedPortfolioType, 'dashboard/models', jObj);
          break;
        default:
          // redirect to enhancer/dashboard/Summary
          this.routeTo(selectedPortfolioType, 'dashboard/summary', jObj);
      }
    }

    setTimeout(() => {
      const { premiumFeatureAccessRequest, premiumNoticeModalOpen, access, fetchingPremiumDetails } = this.props;
      if(!premiumNoticeModalOpen && (access === 'Denied' || access === '') && !fetchingPremiumDetails) {
        premiumFeatureAccessRequest('enhancer', (res) => {
          this.setState({ premiumModal: res.premiumNoticeModalOpen });
        });
      }
    }, 1000)
  }

  routeTo = (selectedPortfolioType, path, jObj) => {
    const { history } = this.props;
    if (selectedPortfolioType && path && jObj) {
      if (history) {
        let urlParam = [];
        for (var i in jObj) {
          urlParam.push(encodeURI(i) + "=" + encodeURI(jObj[i]));
        }
        const newPath = `/enhancer/${path}?${urlParam.join("&")}`;
        history.push(newPath);
      }
    }
  }

  getPageTitle() {
    const { routerData, location } = this.props;
    const { pathname } = location;
    let title = 'Magnifi';
    if (routerData[pathname] && routerData[pathname].name) {
      title = `${routerData[pathname].name} - Magnifi`;
    }
    return title;
  }

  getBashRedirect = () => {
    // According to the url parameter to redirect
    const urlParams = new URL(window.location.href); //eslint-disable-line
    const redirect = urlParams.searchParams.get('redirect');
    // Remove the parameters in the url
    if (redirect) {
      urlParams.searchParams.delete('redirect');
      window.history.replaceState(null, 'redirect', urlParams.href);
    } else {
      const { routerData } = this.props;
      // get the first authorized route path in routerData
      const authorizedPath = Object.keys(routerData).find(item => check(routerData[item].authority, item) && item !== '/');
      return authorizedPath;
    }
    return redirect;
  }

  premiumModalToggle = () => {
    this.setState({
      premiumModal: !this.state.premiumModal,
    });
  }

  render() {
    const {
      match,
      loading,
      location,
      routerData,
      premiumUser,
      pdfReportLoader,
      cacheClearLoading,
      fetchingPremiumDetails,
    } = this.props;
    const { pathname } = location;

    const bashRedirect = this.getBashRedirect();

    const layout = (
      <Layout>
        <Content id="enhancer-content-wrapper" className={`enhancer-content-wrapper ${pathname === '/enhancer/onboard-screen' ? 'p-0' : ''}`} style={{ justifyContent: 'center' }}>
          {(cacheClearLoading) && <Loader loading />}
          {(fetchingPremiumDetails || loading || pdfReportLoader || this.props.evaluateLoading)
            && <Loader loading />
          }
          {!fetchingPremiumDetails && (
            <Fragment>
              <Switch>
                {
                  redirectData.map(item => <Redirect key={item.from} exact from={item.from} to={item.to} />)
                }
                {
                  getRoutes(match.path, routerData).map(item => {
                    return (
                      <AuthorizedRoute
                        exact
                        key={item.key}
                        path={item.path}
                        render={props => (
                          item.path === '/enhancer/onboard-screen' ? (
                            <div style={{ display: "flex", flexDirection: "column", overflow: 'auto' }} className="custom-scroll w-100">
                              <OnBoardScreen />
                              <InnerPageFooter style={{ width: "100%", bottom: 0, borderTop: "1px solid #d5cbcb", textAlign: "center", fontFamily: "Open Sans", fontSize: "12px", padding: ".4em", background: "#f7f7f7" }} />
                            </div>
                          ) : (
                            (item.path === '/enhancer/summary') ? (
                              <OpenSummaryPage />
                            ) : (
                              <Dashboard {...props} component={item.component} />
                            )
                          )
                        )}
                        authority={item.authority || 'user'}
                        protected={item.protected || false}
                        redirectPath="/"
                      />
                    )
                  })
                }
                <Redirect exact from="/" to={bashRedirect} />
                <Route render={NotFound} />
              </Switch>
              {(this.state.premiumModal && (this.props.access !== 'Granted' || this.props.access === '')) && (
                <PremiumModal
                  access={this.props.access}
                  premiumUser={premiumUser}
                  planName={this.props.planName}
                  activeFeatureName={this.props.activeFeatureName}
                  premiumNoticeModalOpen={this.props.premiumNoticeModalOpen}
                  onClickOK={this.premiumModalToggle}
                  onClickCancel={this.premiumModalToggle}
                  onToggle={this.premiumModalToggle}
                  testDriveAction={this.premiumModalToggle}
                />
              )}
            </Fragment>
          )}
        </Content>
      </Layout>
    );
    return (
      <DocumentTitle title={this.getPageTitle()}>
        <ContainerQuery query={containerQuery}>
          {params => <div className={classNames(params)}>{layout}</div>}
        </ContainerQuery>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = ({ auth, router, loading, enhancer, profile }) => ({
  premiumUser: auth.user.premiumUser,
  planName: auth.user.planName,
  isTermsAgreed: auth.termsAgreed,
  loggedIn: auth.loggedIn,
  accConfirmedModal: auth.accConfirmedModal,
  premiumFeatures: profile.premiumFeatures,
  activeFeatureName: profile.activeFeatureName,
  premiumNoticeModalOpen: profile.premiumNoticeModalOpen,
  access: profile.access, // 'Access' or 'Denied' or 'Test Drive'
  location: router.location,
  summary: enhancer.summary,
  portfolioName: enhancer.portfolioName,
  modelPortfolios: enhancer.modelPortfolios,
  fundPortfolios: enhancer.fundPortfolios,
  selectedPortfolioType: enhancer.onboardData.selectedPortfolioType,
  similarityMeasures: enhancer.similarityMeasures,
  query: enhancer.query,
  fundUniverse: enhancer.fundUniverse,
  modelUniverse: enhancer.modelUniverse,
  tickers: enhancer.tickers,
  searchTypeMode: enhancer.searchTypeMode,
  callbackPath: (enhancer.redirectFrom && enhancer.redirectFrom.module) ? enhancer.redirectFrom.module : '',
  selectedWeightOptions: enhancer.selectedWeightOptions,
  pdfReportLoader: enhancer.pdfReportLoader,
  routerData: getRouterData(),
  evaluateLoading: createLoadingSelector(['UPDATE_EVALUATE_FILTER', 'USE_SUGGESTED_FUNDS'])({ loading }), // this function is not working need to debug
  fetchingPremiumDetails: createLoadingSelector(['GET_SUBSCRIPTION_PLAN', 'PROFILE', 'PREMIUM_FEATURE_ACCESS'])({ loading }),
  loading: createLoadingSelector([
    // 'LOAD_CPM_LIST',
    'GET_PORTFOLIO_BY_ID',
    'RESET_SUMMARY_STATS_RETURN_TO_ONBOARDSCREEN',
    'GET_FUNDS_PORTFOLIO_DATA_BY_ID',
    'FINALIZE_PORTFOLIO',
    'CLEAR_FINALIZE_DATA',
    'SAVE_PORTFOLIO',
    // 'GET_MODEL_PORTFOLIOS_BY_ID',
    'GET_MP_DETAILS_CUSTOM_REPLACE',
  ])({ loading }),
  cacheClearLoading: createLoadingSelector(['CLEAR_FINALIZE_DATA'])({ loading }), // do not remove this
});

const mapDispatchToProps = {
  logoutHandler: logout,
  setGlobalState,
  subscriptionPlan,
  setProfileState,
  fetchProfile,
  // getHomeOfficeModelsRequest,
  getClientListRequest,
  setPortfolioState,
  setPremiumState,
  premiumFeatureAccessRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnhancerRouter);

const NotFound = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
    />
  )
}

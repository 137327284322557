// absolute imports
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import DocumentTitle from 'react-document-title';
import { ContainerQuery } from 'react-container-query';
import { isEmpty, uniqBy } from 'lodash';
import moment from 'moment';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout, Result } from 'antd';

import { logout } from 'actions/auth';
import { setGlobalState } from 'actions/global';
import { subscriptionPlan, setProfileState, fetchProfile, setPremiumState, premiumFeatureAccessRequest } from 'actions/profile';
import { getClientListRequest } from 'actions/investment';
import {
  updateFundsData,
  updateSelFundsInCrd,
  setFundsState
} from 'actions/discovery';
import {
  setSFMState,
  setSFMCatData,
  setSFMItems,
  scoreAttrsHandler,
  trackFundsCompareOrder,
  updateReportForFundTracking,
  updateSFMTrackYear,
  deleteAllSFMItemTickersRequest,
  removeSFMItemTickersRequest,
  addSFMItemTickersRequest,
  clearFundsTracking,
  saveBgDownload,
} from 'actions/sfm';
import { setBuildPortfolioState } from 'actions/buildportfolio';
import { refreshWeightState, fetchWeightsData } from 'actions/weights';
import { getApexAccountDetailsRequest } from 'actions/accounts';

// import { getPremiumFeatureDetails } from 'apis/test-drive';
import { createLoadingSelector } from 'selectors/loading-selector';

// import { PremiumModal } from 'components/Premium';
import SFM from 'layouts/WebDashboard/SFM';
import Confirmation from 'layouts/WebDashboard/SFM/ConfirmTab/Confirmation';
import SpoofSfMain from 'layouts/WebDashboard/SearchResultsV3/Elements/SpoofSfModal';

import { ReservedRoutes, optWeightList, DateRange } from 'DataSet';
import Authorized from 'common/Authorized';
import { apexRedirectionWhereTo } from 'layouts/utils';
import { getWindowScreen, addEventToAnalytics, nameToDateRange, monthToDate, fixedValue } from 'Utils';
import { calcCombineStatsWeighted } from 'Stats';

// relative imports
import { Loader } from './Elements/Loader';
import AccountConfirmed from './Elements/Registrations/AccountConfirmed';
import TermsNConditions from './Elements/Registrations/TermsNConditions';
// import RegistrationCompleted from './Elements/Registrations/RegistrationCompleted';
// import OnBoardScreen from './Pages/OnBoardScreen/index';
import Dashboard from './Pages/Dashboard';
import OpenSummaryPage from './Pages/OpenSummaryPage';

import { getRoutes } from './utils';
import { getMenuData } from './routermenu';
import { getRouterData } from './AccountsPageRouter';
// import { getQueryItems } from '../../utils';
import TradingExecutePopup from '../Trading/TradingExecutePopup'

const { Content } = Layout;
const { AuthorizedRoute, check } = Authorized;

const redirectData = [];
const getRedirect = (item) => {
  if (item && item.children) {
    if (item.children[0] && item.children[0].path) {
      redirectData.push({
        from: `${item.path}`,
        to: `${item.children[0].path}`,
      });
      item.children.forEach((children) => {
        getRedirect(children);
      });
    }
  }
};
getMenuData().forEach(getRedirect);

const query = {
  'screen-xs': {
    maxWidth: 575,
  },
  'screen-sm': {
    minWidth: 576,
    maxWidth: 767,
  },
  'screen-md': {
    minWidth: 768,
    maxWidth: 991,
  },
  'screen-lg': {
    minWidth: 992,
    maxWidth: 1199,
  },
  'screen-xl': {
    minWidth: 1200,
  },
};

let shareCombineState;

class Accounts extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      headerHeight: '',
      notify: false,
      premiumModal: false,
      premiumFeature: 'false',
    };
    this.modalToggle = this.modalToggle.bind(this);
    this.SpoofModalToggle = this.SpoofModalToggle.bind(this);
    this.selectHandler = this.selectHandler.bind(this);
  }

  componentWillMount() {
    const { loggedIn, scoreAttrs } = this.props;
    if (loggedIn && isEmpty(scoreAttrs)) {
      this.props.fetchWeightsData();
    }
  }

  componentDidMount() {
    document.body.style.paddingTop = 0;
    const {
      setGlobalState,
      profile,
      // fetchProfile,
    } = this.props;
    const windowScreen = getWindowScreen();
    if (setGlobalState && windowScreen) {
      setGlobalState({
        ...windowScreen,
      });
    }
    // if (fetchProfile) {
    //   fetchProfile();
    // }
    this.executeRedirection({ profile });
  }

  executeRedirection = ({ profile }) => {
    const { history } = this.props;
    const _apexRedirectionWhereTo = apexRedirectionWhereTo({ profile });
    if (history && _apexRedirectionWhereTo !== null && _apexRedirectionWhereTo !== 'Not to apex route') {
      history.push(_apexRedirectionWhereTo);
    }
  }

  getPageTitle() {
    const { routerData, location } = this.props;
    const { pathname } = location;
    let title = 'Magnifi';
    if (routerData[pathname] && routerData[pathname].name) {
      title = `${routerData[pathname].name} - Magnifi`;
    }
    return title;
  }

  getBashRedirect = () => {
    // According to the url parameter to redirect
    const urlParams = new URL(window.location.href); //eslint-disable-line
    const redirect = urlParams.searchParams.get('redirect');
    // Remove the parameters in the url
    if (redirect) {
      urlParams.searchParams.delete('redirect');
      window.history.replaceState(null, 'redirect', urlParams.href);
    } else {
      const { routerData } = this.props;
      // get the first authorized route path in routerData
      const authorizedPath = Object.keys(routerData).find(item => check(routerData[item].authority, item) && item !== '/');
      return authorizedPath;
    }
    return redirect;
  }

  premiumModalToggle = () => {
    this.setState({
      premiumModal: !this.state.premiumModal,
    });
  }

  //--- SFM RELATED FUNC begins ---
  selectHandler(item, index) {
    //V-2 logic
    // console.log(item);
    const { sfmSelectedItemTickers, addSFMItemTickersRequest, removeSFMItemTickersRequest, deleteAllSFMItemTickersRequest, funds, fetchProfile } = this.props;
    if (item === undefined || typeof item === 'undefined') {
      // DELETE ALL SFM FUNDS/ITEMS
      console.log('=====> DELETE ALL SFM FUNDS/ITEMS <=====');
      if (deleteAllSFMItemTickersRequest) {
        deleteAllSFMItemTickersRequest()
      }
    } else {
      // NOTE: Step 1: Add item-> Ticker in SFM ->`selectedItemTickers`
      // Step 2: using ticker name SFM look into FUNDS reducer >
      // if ticker already present in funds reducer state then use that data else Call API to fetch ticker data in SFM
      const alreadyPresent = sfmSelectedItemTickers.find((l) => l === item.ticker)
      // let updateArr = [...sfmSelectedItemTickers];
      if (alreadyPresent) {
        // un_select logic
        // console.log('-- un_select logic --');
        if (removeSFMItemTickersRequest) {
          removeSFMItemTickersRequest({
            removeTicker: item.ticker
          })
        }
      } else {
        // select logic
        console.log('-- select logic --');
        if (addSFMItemTickersRequest) {
          addSFMItemTickersRequest({
            selectedItemTickers: item.ticker
          });
          if (funds.query) {
            addEventToAnalytics('Funds Select', 'Funds Select', 'FUNDS_SELECT', { query: funds.query, logId: funds.logId, ticker: item.ticker, category: 'advisory_PTC' }, true);
          }
        }
      }
    }
  }

  selectBuilderHandler = (item, clear) => {
    const { buildportfolio, setBuildPortfolioState, setSFMItems, fundsData, sfm, updateFundsData } = this.props;
    if (clear) {
      // item._selected = item._selector ? true : false;
      // item._selector = false;
      item._builder = false;
      let filteredBuilderArray = buildportfolio.resultPageCart.filter((el) => el.id != item.id);
      // let filteredSfmArray = sfm.items.filter((el) => el.id != item.id);
      let fundArr = [];
      fundsData.forEach(item => {
        if (item._selector) {
          item._selected = true;
          fundArr.push(item);
        }
      })
      let obj = { resultPageCart: filteredBuilderArray };
      updateFundsData({
        data: this.props.fundsData,
      });
      // setSFMItems({
      //   items: fundArr,
      // });
      this.searchAnalytics(item);
      setBuildPortfolioState(obj);
    } else {
      // item._selected = true;
      item._builder = true;
      let itemArr = [...buildportfolio.resultPageCart];
      // let sfmItemArr = sfm.items;
      // if(!item._selector) sfmItemArr.push(item);
      itemArr.push(item);
      let obj = { resultPageCart: itemArr };
      // setSFMItems({
      //   items: sfmItemArr,
      // });
      this.searchAnalytics(item);
      addEventToAnalytics('Add to Builder', 'Add to Builder', 'ADD_TO_BUILDER', { selected_ticker: item.ticker, category: 'advisory_PTC' }, true);
      setBuildPortfolioState(obj);
    }
  }

  modalToggle = (modalToggleState) => {
    const { setSFMState, isRedirectFromHeaderButtons } = this.props;
    if (modalToggleState === 'add') {
      setSFMState({
        modalToggleState: true,
        modal: true,
      });
    } else if (modalToggleState === 'remove') {
      setSFMState({
        modalToggleState: false,
        modal: false,
        autoOpenModal: undefined
      });
    }
  }

  SpoofModalToggle = () => {
    localStorage.setItem('counter', 2);
    localStorage.setItem('plusCounter', 2);
    const { setSFMState, setGlobalState, autoOpenModal, surveyModal, isRedirectFromHeaderButtons } = this.props;
    if (!surveyModal) {
      setGlobalState({
        surveyModal: true,
      });
    }
    if (!autoOpenModal) {
      setSFMState({
        autoOpenModal: true,
      });
    }
  }

  compareStats = (items, scoreAttrs) => {
    const dFun = this.props.sfmTrackFundsState;
    dFun.map((j, index) => {
      const f = j.funds.map((jItem) => {
        const getFundFromItemlistByTicker = items.filter((r) => r.ticker === jItem.ticker)[0];
        if (typeof jItem.score === 'undefined' && getFundFromItemlistByTicker && getFundFromItemlistByTicker._sfStats) {
          const sf = this.calFundsStats(getFundFromItemlistByTicker._sfStats, getFundFromItemlistByTicker);
          return sf; // calculate SFSTATS manually
        } else {
          return jItem;
        }
      });
      dFun[index].funds = f;
    });
  }

  combineStats = (items, scoreAttrs) => {
    const { sfmTrackFundsState } = this.props;
    const dFun = JSON.parse(JSON.stringify(sfmTrackFundsState));
    const lastestArr = dFun[dFun.length - 1];
    if (lastestArr && lastestArr.funds) {
      const result = lastestArr.funds.map((jItem) => {
        const getFundFromItemlistByTicker = items.filter((r) => r.ticker === jItem.ticker)[0];
        if (getFundFromItemlistByTicker && getFundFromItemlistByTicker._sfWtStats) {
          const sf = this.calFundsStats(getFundFromItemlistByTicker._sfWtStats, getFundFromItemlistByTicker);
          return sf;
        }
      });
      const COMBINE_FUNDS = result.filter((some) => typeof some !== 'undefined'); // result;
      lastestArr.funds = COMBINE_FUNDS;
      lastestArr.trackCompareOrder = scoreAttrs;
      this.setState({
        combineStats: lastestArr,
      })
    }
  }

  sortFn = (a, b) => {
    let [_a, _b] = [a._sfStats, b._sfStats]
    if (_a && _b) {      // descending order
      if (_a.score < _b.score) return 1;
      else if (_a.score > _b.score) return -1;
    }
    return 0;
  }

  combineSortFn = (a, b) => {
    let [_a, _b] = [a._sfWtStats, b._sfWtStats]
    if (_a && _b) {      // descending order
      if (_a.score < _b.score) return 1;
      else if (_a.score > _b.score) return -1;
    }
    return 0;
  }

  nextsortFn = (_a, _b) => {
    if (_a && _b) {      // descending order
      if (_a.score < _b.score) return 1;
      else if (_a.score > _b.score) return -1;
    }
    return 0;
  }

  executeData = (isCompareOnly = false, printSelection, isBackgroundDownload) => {
    // console.log(isBackgroundDownload)
    const { query, correctedQuery, chartReportData, selectedCalculator, fundsTicker, profile, scoreAttrs, sfm } = this.props;
    const itemslist = sfm.items.filter((el) => el._selector === true);
    chartReportData.allFunds['1 Year Yield'] = chartReportData.allFunds['Dividend Yield']
    const logo = profile.custom_report_logo ? profile.custom_report_logo : '';
    const benchmark = profile.mpt_benchmark ? profile.mpt_benchmark : 'CATEGORY'; // got deleted WHY ?
    const attrsWithWeight = scoreAttrs.filter(item => item.weight > 0);
    const formattedSelectedFunds = this.getFormattedFundsForCompare(itemslist);
    const compareFund = formattedSelectedFunds.filter((g) => g.ticker).sort(this.nextsortFn);
    const sortDescFunds = JSON.parse(JSON.stringify(compareFund));
    const defaultAttrs = this.props.customCalculatorAttrs.filter(item => item.default);
    const allAttrs = scoreAttrs.concat(defaultAttrs);
    const compare = [{
      attrs: attrsWithWeight.map((item) => { return item.name.toLowerCase() }),
      funds: uniqBy(sortDescFunds, 'ticker'),
      // date,
      attrsWithScore: attrsWithWeight.map((item) => { return { name: item.name, weight: item.weight } }),
      selectedCalculator
    }];
    let combine = {};
    if (!isCompareOnly) {
      const currentPFSelection = shareCombineState.opts;
      // const selectedPortfolio = shareCombineState.portfolios.filter((item) => item.code === currentPFSelection.optPf);
      const selectedPortfolio = shareCombineState.portfolios.filter((item) => item.code === currentPFSelection.optPf).length ?
        shareCombineState.portfolios.filter((item) => item.code === currentPFSelection.optPf) :
        shareCombineState.portfolios2.filter((item) => item.portfolio_name === currentPFSelection.optPf);
      const selectedDate = shareCombineState.returnsChartDateRange || '';
      const selectedFunds = JSON.parse(JSON.stringify(shareCombineState.items));
      const sortedCombineFunds = selectedFunds.sort(this.combineSortFn);
      const bestFundsFactors = this.getBestFundsFactors(sortedCombineFunds, 'combine');
      const sortedBestFundsFactors = bestFundsFactors.sort(this.nextsortFn);
      const highestScore = Math.max.apply(Math, sortedCombineFunds.map((o) => o._sfWtStats.score));
      const bestFund = sortedCombineFunds.find(item => item._sfWtStats.score === highestScore);
      const activeDateRange = this.nameToDateRange({ return: 'active', risk: 'active' }, sortedCombineFunds, true);
      // const pfDetails = shareCombineState.data.portfolios.find(e => e.code === currentPFSelection.optPf);
      const pfDetails = shareCombineState.data.portfolios.find(e => e.code === currentPFSelection.optPf) ?
        shareCombineState.data.portfolios.find(e => e.code === currentPFSelection.optPf) :
        shareCombineState.portfolios2.find(e => e.portfolio_name === currentPFSelection.optPf);

      combine = {
        all: {
          factor: currentPFSelection.optWt,
          attrs: ["return", "risk", "fee"],
          funds: sortedBestFundsFactors,
          dateRange: selectedDate,
        },
        best: {
          ticker: bestFund.ticker,
          attrs: ["return", "risk", "fee"],
          factors: this.getBestFundFactor(bestFund, pfDetails, activeDateRange),
        },
        portfolio: `${selectedPortfolio[0].name} ${selectedPortfolio[0].desc}`,
      }
    }
    const reports = {
      search: {
        query,
        correctedQuery,
        funds: fundsTicker.split(','), // e.g: ['IEMG', 'VWO', ...]
        date: new moment().format('MMM DD, YYYY') // e.g: '02-Sep-2019'
      },
      allAttrs,
      // or selectedFunds: ['IEMG', 'VWO', 'EELV']
      selectedFundDetails: chartReportData.selectedFunds,
      allFunds: chartReportData.allFunds,
      selectedFunds: chartReportData.selectedFunds.map(item => {
        return item.ticker
      }), // [{ticker: '',name: '',price: 0.0, sponsor: "", volume: 0, volumnMin: 0, volumnMax: 0, volumnPc: 0 }]
      compare, // NOTE: ORDER MATTER ! {attrs: ['return', 'risk', 'fee'], funds: [{ ticker: 'IEMG', return: 5.18, risk: 17.98, fee: 0.13, score: 100 }, ...]}
      combine,
      isCompareOnly,
      logo,
      benchmark,
      printSelection,
      isBackgroundDownload
    }
    // console.log(reports)
    this.sendReportData(reports);
  }

  getBestFundFactor = (bestFund, pfDetails, dateRange) => {
    const _FactorsArr = optWeightList.map((wt) => {
      if (bestFund && pfDetails && wt.value && dateRange) {
        const factorVar = calcCombineStatsWeighted(bestFund, pfDetails, wt.value, dateRange);
        return {
          factor: factorVar.weight,
          return: parseFloat(factorVar.yRet.toFixed(2)),
          risk: parseFloat(factorVar.risk.toFixed(2)),
          fee: parseFloat(factorVar.fees.toFixed(2)),
        };
      }
    });
    return _FactorsArr;
  }

  nameToDateRange = (range, items, forReport) => {
    let d = moment.max(items.map(e => monthToDate(e._start)))
    let sDefault = moment(DateRange.start),
      sActive = moment.max(sDefault, d)
    let maxRange = r => ({
      start: r !== 'active' ? sDefault : sActive,
      end: r == 'active' && forReport ? moment() : moment(DateRange.end)
    });
    return {
      return: nameToDateRange(range.return, maxRange(range.return)),
      risk: nameToDateRange(range.risk, maxRange(range.risk))
    }
  }

  sendReportData = (reports) => {
    if (reports) {
      const { updateReportForFundTracking } = this.props
      updateReportForFundTracking({
        reports
      })
    }
  }

  SFMOnAlertDismiss = () => {
    const { setSFMState } = this.props
    setSFMState({
      reportPDFDownloadStatus: {
        message: '',
        downloadStatus: ''
      },
      reportPDFDownloadStatusCompareOnly: {
        message: '',
        downloadStatus: ''
      }
    })
  }

  prepareDataForReport = (data) => {
    const { query, sfmTrackFundsState, selectedCards, fundsTicker, correctedQuery, isCompareOnly, printSelection, isBackgroundDownload } = data;
    this.executeData(isCompareOnly, printSelection, isBackgroundDownload);
  }

  getBestFundsFactors = (funds, section) => {
    const ff = JSON.parse(JSON.stringify(funds))
    const formattedFunds = ff.map(item => {
      const _sfFundsArr = shareCombineState.items.filter((ff) => ff.ticker === item.ticker);
      const SFStats = item._sfStats ? item._sfStats : _sfFundsArr[0]._sfStats;
      const SFWTStats = item._sfWtStats ? item._sfWtStats : _sfFundsArr[0]._sfWtStats;
      const scoreValue = section === 'compare' ? parseFloat(SFStats.score !== null ? SFStats.score.toFixed(2) : 0) : parseFloat(SFWTStats.score !== null ? SFWTStats.score.toFixed(2) : 0);
      const scoreRoundFigure = fixedValue(scoreValue);
      return {
        ticker: item.ticker,
        return: section === 'compare' ? parseFloat(SFStats.yRet !== null ? SFStats.yRet.toFixed(2) : 0) : SFWTStats.yRetOff ? 'Inactive' : parseFloat(SFWTStats.yRet.toFixed(2)),
        risk: section === 'compare' ? parseFloat(SFStats.risk !== null ? SFStats.risk.toFixed(2) : 0) : SFWTStats.riskOff ? 'Inactive' : parseFloat(SFWTStats.risk.toFixed(2)),
        fee: section === 'compare' ? parseFloat(SFStats.fees !== null ? SFStats.fees.toFixed(2) : 0) : SFWTStats.feesOff ? 'Inactive' : parseFloat(SFWTStats.fees.toFixed(2)),
        score: scoreRoundFigure,
      };
    });
    return formattedFunds;
  }

  shareCombineState = (data) => {
    shareCombineState = data;
  }

  calFundsStats = (SFStats, item) => {
    // console.log(SFStats, item);
    if (typeof SFStats !== 'undefined') {
      const scoreValue = parseFloat(SFStats.score !== null ? SFStats.score.toFixed(2) : 0);
      const scoreRoundFigure = Math.round(scoreValue);
      return {
        ticker: item.ticker,
        return: parseFloat(SFStats.yRet !== null ? SFStats.yRet.toFixed(2) : 0),
        risk: parseFloat(SFStats.risk !== null ? SFStats.risk.toFixed(2) : 0),
        fee: parseFloat(SFStats.fees !== null ? SFStats.fees.toFixed(2) : 0),
        score: scoreRoundFigure,
      };
    } else {
      return {
        ticker: item.ticker,
        return: 0,
        risk: 0,
        fee: 0,
        score: 0,
      };
    }
  }

  getFormattedFundsForCompare = (funds) => {
    const formattedFunds = funds.map(item => {
      const SFStats = item._sfStats;
      // console.log(SFStats);
      if (typeof SFStats !== 'undefined') {
        const scoreValue = parseFloat(SFStats.score !== null ? SFStats.score.toFixed(2) : 0);
        const scoreRoundFigure = fixedValue(scoreValue);
        return {
          ticker: item.ticker,
          return: SFStats.yRet ? parseFloat(SFStats.yRet.toFixed(2)) : null,
          risk: SFStats.risk ? parseFloat(SFStats.risk.toFixed(2)) : null,
          fee: SFStats.fees ? parseFloat(SFStats.fees.toFixed(2)) : 0,
          "premium to nav": SFStats.weight_premium_to_nav ? parseFloat(SFStats.weight_premium_to_nav) : null,
          "fund flows": SFStats.weight_fund_flows ? parseFloat(SFStats.weight_fund_flows) : null,
          "assets": SFStats.aum ? parseFloat(SFStats.aum) : null,
          "volume": SFStats.vol ? parseFloat(SFStats.vol) : null,
          "tracking error": SFStats.trkerror ? parseFloat(SFStats.trkerror) : null,
          "yield": SFStats.weight_yield ? parseFloat(SFStats.weight_yield) : null,
          "bid-ask spread": SFStats.weight_bid_ask ? parseFloat(SFStats.weight_bid_ask) : null,
          "track record": SFStats.weight_track_record ? parseFloat(SFStats.weight_track_record) : null,
          "trend": SFStats.weight_trend ? parseFloat(SFStats.weight_trend) : null,
          score: (scoreRoundFigure < 0) ? 0 : scoreRoundFigure,
          _sfStats: SFStats,
        };
      } else {
        return {
          ticker: item.ticker,
          return: 0,
          risk: 0,
          fee: 0,
          "premium to nav": 0,
          "fund flows": 0,
          "assets": 0,
          "volume": 0,
          "tracking error": 0,
          "yield": 0,
          "bid-ask spread": 0,
          "track record": 0,
          "trend": 0,
          score: 0,
          _sfStats: SFStats,
        };
      }
    });
    return formattedFunds;
  }

  getScoreAttrs = (scoreAttrs) => {
    const { scoreAttrsHandler, selectedCards } = this.props;
    scoreAttrsHandler({
      scoreAttrs
    })
  }

  confirmHandler = (confirm, payload) => {
    this.setState({ confirm, confirmPayload: payload })
  }

  searchedCustomTicker = (data) => {
    // console.log(data);
    if (data && data.value) {
      const { sfmSelectedItemTickers, addSFMItemTickersRequest, removeSFMItemTickersRequest, deleteAllSFMItemTickersRequest, funds, setSFMItems, selectedCards } = this.props;

      // console.log(JSON.parse(JSON.stringify(selectedCards)));
      if (setSFMItems && selectedCards) {
        setSFMItems({ items: JSON.parse(JSON.stringify(selectedCards)) })
      }

      // NOTE: Step 1: Add item-> Ticker in SFM ->`selectedItemTickers`
      // Step 2: using ticker name SFM look into FUNDS reducer >
      // if ticker already present in funds reducer state then use that data else Call API to fetch ticker data in SFM
      const alreadyPresent = sfmSelectedItemTickers.find((l) => l === data.value)
      // let updateArr = [...sfmSelectedItemTickers];
      if (alreadyPresent) {
        // un_select logic
        // console.log('-- un_select logic --');
        if (removeSFMItemTickersRequest) {
          removeSFMItemTickersRequest({
            removeTicker: data.value
          })
        }
      } else {
        // select logic
        console.log('-- select logic --');
        if (addSFMItemTickersRequest) {
          addSFMItemTickersRequest({
            selectedItemTickers: data.value
          });
          if (funds.query) {
            addEventToAnalytics('SFM CUSTOM SEARCHED TICKER', 'SFM CUSTOM SEARCHED TICKER', 'SFM_CUSTOM_SEARCHED_TICKER', { logId: funds.logId, ticker: data.value }, true);
          }
        }
      }
    }
  }

  toggleExecuteOrderPopup = (eraseData = false) => {
    console.log('=== toggleExecuteOrderPopup ====', eraseData);
    if (eraseData) {
      const { fetchProfile, setSFMState, sfmTradeOrderResponse } = this.props;
      // if trade order response result -> not in error -> then refresh profile state
      // and refresh holdings data as well
      if (fetchProfile && sfmTradeOrderResponse && typeof sfmTradeOrderResponse.error !== 'undefined' && !sfmTradeOrderResponse.error) {
        fetchProfile();
      }
      if (setSFMState) {
        setSFMState({ sfmTradeOrderResponse: {} })
      }
    }
  }

  //--- SFM RELATED FUNC ends ---

  render() {
    const {
      routerData,
      match,
      loggedIn,
      loading,
      // location,
      // isTermsAgreed,
      // accConfirmedModal,
      premiumUser,
      fetchingPremiumDetails,
      pdfReportLoader,
      // selectorCount,
      sfm,
      modalToggleState,
      reportLoader,
      reportLoaderCompareOnly,
      reportPDFDownloadStatus,
      reportPDFDownloadStatusCompareOnly,
      autoOpenModal,
      buildportfolio,
      // fundsData,
      fetchingSFMData,
      sfmTradeOrderResponse
    } = this.props;
    // const { headerHeight } = this.state;
    // const { pathname } = location;

    // const enabledReservedRoutes = ReservedRoutes.includes(pathname)
    //const blurClass = (this.props.sfmToggle || enabledReservedRoutes) ? 'blur-effect' : '';
    const blurClass = (this.props.sfmToggle) ? 'blur-effect' : '';
    // let renderModal = '';

    // if (loggedIn && isTermsAgreed === 0) {
    //   renderModal = <TermsNConditions />
    // } else if (loggedIn && accConfirmedModal) {
    //   renderModal = <AccountConfirmed />
    // }

    const bashRedirect = this.getBashRedirect();
    const cartItems = sfm.items.filter((el) => el._selector === true);

    const layout = (
      <Layout>
        <Content id="accounts-content-wrapper" className={`accounts-content-wrapper ${blurClass}`}>
          {(fetchingPremiumDetails || loading || pdfReportLoader || fetchingSFMData)
            && <Loader loading />
          }
          {!fetchingPremiumDetails && (
            <Fragment>
              <Switch>
                {
                  redirectData.map(item => <Redirect key={item.from} exact from={item.from} to={item.to} />)
                }
                {
                  getRoutes(match.path, routerData).map(item => {
                    return (
                      <AuthorizedRoute
                        exact
                        key={item.key}
                        path={item.path}
                        render={props => (
                          (item.path === '/accounts/summary') ? (
                            <OpenSummaryPage />
                          ) : (
                            <Dashboard {...props} component={item.component} />
                          )
                        )}
                        authority={item.authority || 'user'}
                        protected={item.protected || false}
                        redirectPath="/"
                      />
                    )
                  })
                }
                <Redirect exact from="/" to={bashRedirect} />
                <Route render={NotFound} />
              </Switch>
              {/*(this.state.premiumModal && (this.props.access !== 'Granted' || this.props.access === '')) && (
                <PremiumModal
                  access={this.props.access}
                  premiumUser={premiumUser}
                  planName={this.props.planName}
                  activeFeatureName={this.props.activeFeatureName}
                  premiumNoticeModalOpen={this.props.premiumNoticeModalOpen}
                  onClickOK={this.premiumModalToggle}
                  onClickCancel={this.premiumModalToggle}
                  onToggle={this.premiumModalToggle}
                  testDriveAction={this.premiumModalToggle}
                />
              )*/}
              <section className={classNames("fixed-top bg-ssf-gray-l3 sm topSectionWrapper", modalToggleState ? 'blur-effect' : '')}>
                {(cartItems.length > 0 && loggedIn)
                  && (
                    <SFM
                      {...this.props}
                      enablePrintReports
                      enableOnlyActTab
                      premiumUser={premiumUser}
                      autoOpenModal={autoOpenModal}
                      reportLoader={reportLoader}
                      reportLoaderCompareOnly={reportLoaderCompareOnly}
                      reportPDFDownloadStatus={reportPDFDownloadStatus}
                      reportPDFDownloadStatusCompareOnly={reportPDFDownloadStatusCompareOnly}
                      items={cartItems}
                      builderSelected={buildportfolio.resultPageCart.length}
                      // dateRange={this.props.funds.dateRange}
                      getScoreAttrs={this.getScoreAttrs}
                      modalToggle={this.modalToggle}
                      confirmHandler={this.confirmHandler}
                      printReport={this.prepareDataForReport}
                      shareCombineState={this.shareCombineState}
                      onAlertDismiss={this.SFMOnAlertDismiss}
                      compareStats={this.compareStats}
                      combineStats={this.combineStats}
                      selectHandler={this.selectHandler}
                      selectBuilderHandler={this.selectBuilderHandler}
                      searchedCustomTicker={this.searchedCustomTicker}
                    />
                  )
                }
                {(cartItems.length > 0 && !loggedIn)
                  && (
                    <SpoofSfMain
                      {...this.props}
                      items={cartItems}
                      modalToggle={this.SpoofModalToggle}
                      confirmHandler={this.confirmHandler}
                    />
                  )
                }
                {this.state.confirm && (
                  <Confirmation
                    {...this.props}
                    confirmHandler={this.confirmHandler}
                    confirmPayload={this.state.confirmPayload}
                  />
                )}
              </section>
            </Fragment>
          )}
          {(Object.keys(sfmTradeOrderResponse).length > 0) && (
            <TradingExecutePopup
              data={sfmTradeOrderResponse}
              history={this.props.history}
              open={(Object.keys(sfmTradeOrderResponse).length > 0)}
              toggle={this.toggleExecuteOrderPopup}
              sfm={this.props.sfm}
            />
          )}
        </Content>
      </Layout>
    );

    return (
      <DocumentTitle title={this.getPageTitle()}>
        <ContainerQuery query={query}>
          {params => <div className={classNames(params)}>{layout}</div>}
        </ContainerQuery>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = ({ auth, router, loading, profile, sfm, weights, discovery, buildportfolio, funds, accounts }) => ({
  discovery,
  funds: discovery,
  sfm,
  buildportfolio,
  premiumUser: auth.user.premiumUser,
  planName: auth.user.planName,
  isTermsAgreed: auth.termsAgreed,
  loggedIn: auth.loggedIn,
  accConfirmedModal: auth.accConfirmedModal,

  profile: profile.profile, // for sfm
  premiumFeatures: profile.premiumFeatures,
  activeFeatureName: profile.activeFeatureName,
  premiumNoticeModalOpen: profile.premiumNoticeModalOpen,
  access: profile.access, // 'Access' or 'Denied' or 'Test Drive'
  location: router.location,

  scoreAttrs: weights.scoreAttrs,
  selectedCalculator: weights.selectedCalculator,
  customCalculatorAttrs: weights.customCalculatorAttrs,

  sfmToggle: sfm.modalToggleState,
  modalToggleState: sfm.modalToggleState,
  selectedCards: sfm.items,
  clearAllFunds: sfm.clearAllFunds,
  sfmTrackFundsState: sfm.trackFundsCompareOrder,
  sfmSelectedItemTickers: sfm.selectedItemTickers,
  resetTrackFundsState: sfm.resetTrackFundsState,
  reportLoader: sfm.reportLoader,
  reportLoaderCompareOnly: sfm.reportLoaderCompareOnly,
  reportPDFDownloadStatus: sfm.reportPDFDownloadStatus,
  reportPDFDownloadStatusCompareOnly: sfm.reportPDFDownloadStatusCompareOnly,
  sfmTrackYear: sfm.sfmTrackYear,
  autoOpenModal: sfm.autoOpenModal,
  selectorCount: sfm.items.filter((k) => k._selector || k._selected).length || sfm.selectorCount,

  sfmTradeOrderResponse: sfm.sfmTradeOrderResponse,

  apexBalanceData: accounts.apexBalanceData,

  routerData: getRouterData(),
  fetchingApexAmountBalance: createLoadingSelector(['GET_APEX_AMOUNT_DETAILS'])({ loading }) || false,
  fetchingPremiumDetails: createLoadingSelector(['GET_SUBSCRIPTION_PLAN', 'PROFILE', 'PREMIUM_FEATURE_ACCESS'])({ loading }),
  loading: createLoadingSelector([
    'UPLOAD_ACCOUNTS_PORTFOLIO_DATA_BY_ID',
    'GET_ACCOUNTS_PORTFOLIO_BY_ID',
    'RESET_ACCOUNTS_STATE',
    'CANCEL_DEPOSITS',
    'REFRESH_APEX_STATE',
  ])({ loading }),
  fetchingSFMData: createLoadingSelector(['ADD_SFM_ITEM_TICKERS', 'REMOVE_SFM_ITEM', 'UPDATE_HRS_DATA', 'GET_SFM_ITEM_DETAILS'])({ loading }),
});

const mapDispatchToProps = {
  logoutHandler: logout,
  setGlobalState,
  subscriptionPlan,
  setProfileState,
  fetchProfile,
  getClientListRequest,
  setPremiumState,
  premiumFeatureAccessRequest,

  setSFMState,
  setSFMCatData,
  setSFMItems,
  scoreAttrsHandler,
  trackFundsCompareOrder,
  updateReportForFundTracking,
  saveBgDownload,
  updateSFMTrackYear,
  deleteAllSFMItemTickersRequest,
  removeSFMItemTickersRequest,
  addSFMItemTickersRequest,
  clearFundsTracking,

  updateSelFundsInCrd,
  updateFundsData,

  refreshWeightState,
  fetchWeightsData,

  setBuildPortfolioState,

  setFundsState,
  getApexAccountDetailsRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Accounts);

const NotFound = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
    />
  )
}

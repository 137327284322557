import React, { Component, useState } from 'react';
import { Tabs, Row, Col, Card, Checkbox, Progress, Typography, Divider } from 'antd';
import _ from 'lodash';
import { Tooltip } from 'reactstrap';
import { sponsorLogo } from 'DataSet';
import { FormatCurrency } from 'components/reports/shared/Utils';
import { TruncatedText } from 'components/sf/Common';
import { FeeReturnRisk } from './FundsHelper';
import { EditOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;
const { Paragraph } = Typography;

export default class FundCardV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false
    }
  }

  render() {
    const { data, query, ticker, i, highlights, className, deleteFund, Checkbox } = this.props;

    const weight = data._sliderValue || 0;
    // const weight = selectedTickers.indexOf(data.ticker) > -1 ? selectedFunds[query][selectedTickers.indexOf(data.ticker)]._sliderValue : 0;

    let highlightsObj = (typeof highlights !== 'undefined' && highlights) ? highlights : { feesHighLightEnable: false, returnHighLightEnable: false, riskHighLightEnable: false };
    const id = data.index ? 'cb-'+ticker+data.index+data.id : 'cb-'+ticker+data.vehicle+data.ticker;

    data._frs = {
      fees: data.fees,
      yRet: data.Fee_Risk_Return['Annualized 3 year Average'],
      volt: data.Fee_Risk_Return['Annualized 3 year Volatility'],
      feesQ: data.feesq,
      yRetQ: data.Fee_Risk_Return.returnq,
      voltQ: data.Fee_Risk_Return.riskq,
      queryIcon: '',
    };
    let overlapTicker = 'overlap_'+ticker.toLowerCase();

    if (query.includes('outperform')) {
      data._frs.queryIcon = 'OutPerform';
      const _outPerformVal = (typeof data['outperform'] !== 'undefined' && data['outperform'] !== '--') ? data['outperform']*100 : '--';
      data._frs.OutPerform = (_outPerformVal !== '--' && !Number.isNaN(_outPerformVal)) ? _outPerformVal.toFixed(1) + "%" : '--';
    } else if (query.includes('yield')) {
      data._frs.queryIcon = 'Yield';
      const _yieldVal = (typeof data['attribute/fundamental/yield'] !== 'undefined' && data['attribute/fundamental/yield'] !== '--') ? data['attribute/fundamental/yield']*100 : '--';
      data._frs.Yield = (_yieldVal !== '--' && _yieldVal) ? _yieldVal.toFixed(1) + "%" : '--';
    } else if (query.includes('track record')) {
      data._frs.queryIcon = 'TrackRecord';
      const _trackRecordVal = (typeof data['attribute/track_record'] !== 'undefined' && data['attribute/track_record'] !== '--') ? data['attribute/track_record'] : null;
      data._frs.TrackRecord = (_trackRecordVal !== '--' && _trackRecordVal) ? _trackRecordVal.toFixed() + "yrs" : '--';
    } else if (query.includes('tracking error')) {
      console.log('TrackingError');
      // queryIcon = 'TrackingError';
    }

    const renderGraph = (
      <div className="p-2 progress-chrt-box d-flex flex-column justify-content-center align-items-center">
        <span style={{ lineHeight: 1.4, color: '#1e5f91',fontSize:`11px`,marginBottom:`5px` }}>Allocated Weight</span>
        <Progress
          strokeWidth={12}
          strokeColor="#1d6091"
          width={70}
          type="circle"
          percent={parseFloat(weight.toFixed(1))}
          format={(value) => (<div className="progress-chart-value">{value}</div>) }
        />
      </div>
    )

    return (
      <React.Fragment>
        <Card
          style={{ margin: '0 auto' }}
          className={`fund-card-container  card-background-grey' ${className}`}>
          {deleteFund && (
            <div className="delete-fund-card" onClick={deleteFund}>
              <i class="far fa-times"></i>
            </div>
          )}
          <div className="p-1 border-bottom">
            <Row style={{minHeight: '90px'}}>
              <div className="action-box">
                {Checkbox}
              </div>
              <TickerPrice
                className="d-flex flex-column justify-content-center align-items-center"
                ticker={data.ticker}
                price={data.nav}
              />
              <NameSponsor
                className="d-flex flex-column justify-content-center align-items-left"
                name={data.short_name}
                sponsor={data.sponsor}
                category={data.category}
              />
              {(typeof data.class_type !== 'undefined' && data.class_type &&  ["institutional", "retail"].includes(data.class_type))
              && (
                <div>
                  <img id={`fundclass-${data.ticker}`} className="card-Ad-Icon" src={`/assets/images/${data.class_type}.png`} width='10' />
                  <Tooltip className="col-grey" placement="right" isOpen={this.state.tooltipOpen} target={`fundclass-${data.ticker}`} toggle={() => this.setState({ tooltipOpen: !this.state.tooltipOpen })}>
                    {data.class_type === 'retail' ? 'Retail Class' : 'Institutional Class'}
                  </Tooltip>
                </div>)
              }
            </Row>

          </div>
          <FeeReturnRisk data={data._frs} highlights={highlightsObj} />
          {renderGraph}
        </Card>
      </React.Fragment>
    )
  }
}

// const ImageOrText = ({ img, text = '' }) => {
//   const [valid, setValid] = useState(true);
//   return (
//     valid
//     ? <img src={img} className="img-fluid" alt="" onError={() => setValid(false)} style={{height: '20px'}} />
//     : <div className="sponsor-logo-placeholder" title={text}>{text}</div>
//   );
// }

// const getId = (function* () {
//   let n = 1;
//   while (true) yield n++;
// })();


//className="d-flex flex-column justify-content-center align-items-center" style={{minWidth: '4rem'}}
const TickerPrice = ({ ticker, price, className }) => (
  <Col className={className} span={8}>
    <h5 className="mb-0 ticker-size ticker-price">{ticker}</h5>
    <h4 className="mt-1 mb-0 ticker-price">{FormatCurrency(price, 2)}</h4>
  </Col>
)

const NameSponsor = ({ name, sponsor, category, className }) => (
  <Col className={className} span={16}>
    <div className="pb-1">
      <ImageOrText img={sponsorLogo(sponsor)} text={sponsor} />
    </div>
    <h5 className="m-0" title={name} style={{ fontWeight: 600, lineHeight: 1.2 }}>
      <Paragraph
        className="text-muted item-card-text"
        ellipsis={{rows: 2}}
        title={name}
      >
        {name}
      </Paragraph>
    </h5>
    <h5 className="mb-0 text-gray-6" title={category} style={{ lineHeight: 1.4, color: '#57869c', marginTop: 3 }} >
      <Paragraph
        className="m-0 category-text"
        ellipsis={{rows: 1}}
        title={category}
      >
        {category}
      </Paragraph>
    </h5>
  </Col>
)

const ImageOrText = ({ img, text = '' }) => {
  const [valid, setValid] = useState(true);

  return (
    valid
      ? <img src={img} className="img-fluid" alt="" onError={() => setValid(false)} style={{height: '20px'}} />
      : <div className="sponsor-logo-placeholder" title={text}>{text}</div>
  );
}

const getId = (function* () {
  let n = 1;
  while (true) yield n++;
})();

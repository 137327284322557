import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Slider from "react-slick";
import PerfectScrollbar from 'perfect-scrollbar';
import { Card } from 'antd';

import ItemCard from './ItemCard';
import { Selectable } from './SfmSelectable';
import { arrayToHash, getPortfolioBenchmarkOfSelectedTicker } from 'Utils'
import { CN  } from 'DataSet';
import { statsForPerformanceImpact } from 'layouts/utils'

import { RightOutlined, LeftOutlined } from '@ant-design/icons';

import { setPortfolioReplace, setEnhancementData, addPortfolioEnhanceReplace, deletePortfolioEnhanceReplace, setPortfolioState } from 'actions/portfolio';
import { getInvestmentWatchlistStats, getInvestmentPositionStats, getPortfolioFunds } from 'selectors/investment';

class ItemList extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      enhanceReplace : '',
    }
    // this.myRef = React.createRef();
  }

  componentDidMount() {
    // const { data, ticker, query, replacePortfolioToggle, addPortfolioEnhanceReplace,  view, resultSet, enhanceTickerFilter, portfolioFunds, marketFunds, watchlistFundstats, accountFundstats } = this.props;
    //
    // if (replacePortfolioToggle && data.funds.length) {
    //   this.setState({ enhanceReplace: data.funds[0].ticker }, () => {
    //     // const filterResultSet = resultSet.filter((item) => enhanceTickerFilter.includes(item.ticker));
    //     const tickerWiseRet = arrayToHash(resultSet, CN['Ticker']);
    //     let selectedPortfolioFunds = {}
    //     if (tickerWiseRet[ticker] && tickerWiseRet[ticker].result && tickerWiseRet[ticker].result.funds) {
    //       const rA = tickerWiseRet[ticker].result.funds;
    //       const fA = rA.filter(i => i.ticker === data.funds[0].ticker)[0];
    //
    //       // const pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
    //       let pF = {}
    //       if (view == 'account') {
    //         pF = accountFundstats.funds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
    //       } else if (view == 'watchlist') {
    //         pF = watchlistFundstats.positions.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
    //       } else if (view == 'mrktPl') {
    //         pF = marketFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
    //       } else {
    //         pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
    //       }
    //       // console.log(pF);
    //       selectedPortfolioFunds = { ...fA, _pF: pF }
    //     }
    //     addPortfolioEnhanceReplace({ replaceTicker: data.funds[0].ticker, ticker, query, selectedPortfolioFunds })
    //   })
    // }
    // this.ps = new PerfectScrollbar(this.myRef.current, { suppressScrollY: true });
  }

  // componentWillUnmount() {
  //   this.ps.destroy();
  //   this.ps = null;
  // }

  componentWillReceiveProps(nextProps) {
    // const { data, ticker, query, replacePortfolioToggle, replaceAllPortfolioToggle, view, resultSet, enhanceTickerFilter, portfolioFunds, marketFunds, watchlistFundstats, accountFundstats, customReplaceActiveTab, setCustomActiveTag } = nextProps;
    // if (this.props.replacePortfolioToggle !== replacePortfolioToggle && data.funds.length) {
    //     if (replacePortfolioToggle && data.funds.length) {
    //       this.setState({ enhanceReplace: data.funds[0].ticker }, () => {
    //         const filterResultSet = enhanceTickerFilter.length > 0 ? resultSet.filter((item) => enhanceTickerFilter.includes(item.ticker)) : resultSet;
    //         const tickerWiseRet = arrayToHash(filterResultSet, CN['Ticker']);
    //         let selectedPortfolioFunds = {}
    //         if (tickerWiseRet[ticker] && tickerWiseRet[ticker].result && tickerWiseRet[ticker].result.funds) {
    //           const rA = tickerWiseRet[ticker].result.funds;
    //           const fA = rA.filter(i => i.ticker === data.funds[0].ticker)[0];
    //
    //           // const pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
    //           let pF = {}
    //           if (view == 'account') {
    //             pF = accountFundstats.funds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
    //           } else if (view == 'watchlist') {
    //             pF = watchlistFundstats.positions.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
    //           } else if (view == 'mrktPl') {
    //             pF = marketFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
    //           } else {
    //             pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
    //           }
    //           // console.log(pF);
    //           selectedPortfolioFunds = { ...fA, _pF: pF }
    //         }
    //         this.props.addPortfolioEnhanceReplace({ replaceTicker: data.funds[0].ticker, ticker, query, selectedPortfolioFunds })
    //       })
    //   } else if (!replacePortfolioToggle && replaceAllPortfolioToggle) {
    //     this.setState({ enhanceReplace: '' })
    //   }
    // }
  }

  // setReplaceTicker1 = (e, replaceTicker, ticker, query) => {
  //   const val = document.getElementById(e.target.id).checked;
  //
  //   const { view, resultSet, enhanceTickerFilter, addPortfolioEnhanceReplace, deletePortfolioEnhanceReplace, portfolioFunds, marketFunds, watchlistFundstats, accountFundstats, customReplaceActiveTab, setCustomActiveTag, setPortfolioState } = this.props;
  //
  //   if (val) {
  //     if(customReplaceActiveTab.ticker == 'customReplace') setCustomActiveTag(ticker, 'itemList');
  //     this.setState({ enhanceReplace: replaceTicker }, () => {
  //       const filterResultSet = enhanceTickerFilter.length > 0 ? resultSet.filter((item) => enhanceTickerFilter.includes(item.ticker)) : resultSet;
  //       const tickerWiseRet = arrayToHash(filterResultSet, CN['Ticker']);
  //
  //       let selectedPortfolioFunds = {}
  //       if (tickerWiseRet[ticker] && tickerWiseRet[ticker].result && tickerWiseRet[ticker].result.funds) {
  //
  //         const rA = tickerWiseRet[ticker].result.funds;
  //         filterResultSet.forEach((o, k) => {
  //           if (o.query === query) {
  //             o.result.funds.forEach((y, i) => {
  //               if (y.ticker === replaceTicker) {
  //                 y._selected = true;
  //               } else {
  //                 y._selected = false;
  //               }
  //             });
  //           }
  //         });
  //
  //         const fA = rA.filter(i => i.ticker === replaceTicker)[0];
  //
  //         // const pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
  //         let pF = {}
  //         if (view == 'account') {
  //           pF = accountFundstats.funds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
  //         } else if (view == 'watchlist') {
  //           pF = watchlistFundstats.positions.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
  //         } else if (view == 'mrktPl') {
  //           pF = marketFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
  //         } else {
  //           pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
  //         }
  //         // console.log(pF);
  //         selectedPortfolioFunds = {
  //           // ...fA,
  //           category: fA.category,
  //           ticker: fA.ticker,
  //           dividend_yield: fA.dividend_yield,
  //           fees: fA.fees,
  //           feesq: fA.feesq,
  //           name: fA.name,
  //           nav: fA.nav,
  //           returns: fA.returns,
  //           short_desc: fA.short_desc,
  //           short_name: fA.short_name,
  //           sponsor: fA.sponsor,
  //           vehicle: fA.vehicle,
  //           _pF: pF,
  //           _selected: true,
  //         };
  //       }
  //       setPortfolioState({ resultSet: filterResultSet });
  //
  //       addPortfolioEnhanceReplace({ replaceTicker, ticker, query, selectedPortfolioFunds })
  //     })
  //   } else {
  //     this.setState({ enhanceReplace: '' }, () => {
  //       deletePortfolioEnhanceReplace({ replaceTicker, ticker, query })
  //     })
  //   }
  //   this.props.resetCheckBoxFor('enhanceFund');
  // }

  setReplaceTicker1 = (e, replaceTicker, ticker, query) => {
    const val = document.getElementById(e.target.id).checked;

    const {
      view,
      resultSet,
      enhanceTickerFilter,
      addPortfolioEnhanceReplace,
      deletePortfolioEnhanceReplace,
      portfolioFunds,
      marketFunds,
      watchlistFundstats,
      accountFundstats,
      customReplaceActiveTab,
      setCustomActiveTag,
      setPortfolioState,
      enhanceReplace,
      enhancePortfolioVisible,
      portfolioQuery,
	    upDownDataSet,
	    selectedBenchmark,
    } = this.props;

    let selectedPortfolioFunds = {}, enhancedPerformanceImpact = {};
    let pF = {};
    if (view == 'account') {
      pF = accountFundstats.funds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
    } else if (view == 'watchlist') {
      pF = watchlistFundstats.positions.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
    } else if (view == 'mrktPl') {
      pF = marketFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
    } else {
      pF = portfolioFunds.filter((dd) => ((dd.script || dd.symbol) === ticker) && dd.type !== 'SHARE')[0];
    }

    if (val) {
      if(customReplaceActiveTab.ticker == 'customReplace') setCustomActiveTag(ticker, 'itemList');

      this.setState({ enhanceReplace: replaceTicker }, () => {
        try {
          const filterResultSet = enhanceTickerFilter.length > 0 ? resultSet.filter((item) => enhanceTickerFilter.includes(item.ticker)) : resultSet;

          filterResultSet.forEach((o, k) => {
            if (o.query === query) {
              o.result.funds.forEach((y, i) => {
                if (y.ticker === replaceTicker) {
                  y._selected = true;
                  selectedPortfolioFunds = {
                    ...y,
                    query_var: o.result.query_var, // for showing correlation chart
                    'Fee_Risk_Return': y.Fee_Risk_Return,
                    category: y.category,
                    ticker: y.ticker,
                    dividend_yield: y.dividend_yield,
                    fees: y.fees,
                    feesq: y.feesq,
                    name: y.name,
                    nav: y.nav,
                    returns: y.returns,
                    short_desc: y.short_desc,
                    short_name: y.short_name,
                    sponsor: y.sponsor,
                    vehicle: y.vehicle,
                    weight_yield: y.weight_yield,
                    weight_track_record: y.weight_track_record,
                    'attribute/fundamental/yield': y['attribute/fundamental/yield'] || y['yield'],
                    outperform : y['outperform'] || 0.0,
                    'attribute/track_record': y['attribute/track_record'] || y['track_record'],
                    'attribute/technical/returns/risk_adjusted_returns': y['attribute/technical/returns/risk_adjusted_returns'],
                    _pF: pF,
                    _selected: true,
                  };
                } else {
                  y._selected = false;
                }
              });
            }
          });

          const index = enhanceReplace.findIndex(x => x.ticker === ticker);
          if (index >= 0) {
            enhanceReplace[index]['replace'] = replaceTicker;
            enhanceReplace[index]['replaceTicker'] = replaceTicker;
            enhanceReplace[index]['query'] = query;
            enhanceReplace[index]['selectedPortfolioFunds'] = selectedPortfolioFunds;
          } else {
            enhanceReplace.push({ ticker, replace: replaceTicker, query, selectedPortfolioFunds });
          }

	        let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(upDownDataSet,selectedBenchmark);

          statsForPerformanceImpact({
            portfolioEnhanceReplace: [...enhanceReplace],
            watchlistFundstats,
            accountFundstats,
            portfolioFunds,
            marketFunds,
            portfolioQuery,
            enhancePortfolioVisible,
            view,
	          portfolioBenchmark
          }).then(stats => {
            if (stats && typeof stats.enhancedPerformanceImpact !== 'undefined') {
              enhancedPerformanceImpact = stats.enhancedPerformanceImpact;
              setPortfolioState({ filterResultSet: filterResultSet, enhanceReplace: [...enhanceReplace], enhancedPerformanceImpact });
            }
          });

        } catch (e) {
          console.log(e);
        }
      })
    } else {
      this.setState({ enhanceReplace: '' }, () => {
        try {
          const filterResultSet = enhanceTickerFilter.length > 0 ? resultSet.filter((item) => enhanceTickerFilter.includes(item.ticker)) : resultSet;

          filterResultSet.forEach((o, k) => {
            if (o.query === query) {
              o.result.funds.forEach((y, i) => {
                if (y.ticker === replaceTicker) {
                  y._selected = false; // uncheck for deleting
                }
              });
            }
          });

          const index = enhanceReplace.findIndex(x => x.ticker === ticker);
          if (index >= 0) {
            // deleting from array
            const currentData = enhanceReplace[index];
            // console.log(currentData);
            const found = filterResultSet.filter((item) => item.ticker === currentData.ticker);

            if (found.length > 0 && found[0].target_card && found[0].target_card.funds && found[0].target_card.funds.length > 0) {

              const targetCardData = found[0].target_card.funds[0];
              // console.log(targetCardData);

              enhanceReplace.splice(index, 1, {
                query: currentData.query,
                replace: currentData.ticker,
                selectedPortfolioFunds: {
                  ...targetCardData,
                  query_var: found[0].result.query_var || [], // for showing correlation chart
                  [`overlap_${targetCardData.ticker}`] : targetCardData[`overlap_${targetCardData.ticker}`],
                  'Fee_Risk_Return': targetCardData.Fee_Risk_Return,
                  category: targetCardData.category,
                  ticker: targetCardData.ticker,
                  dividend_yield: targetCardData.dividend_yield,
                  fees: targetCardData.fees,
                  feesq: targetCardData.feesq,
                  name: targetCardData.name,
                  nav: targetCardData.nav,
                  returns: targetCardData.returns,
                  short_desc: targetCardData.short_desc,
                  short_name: targetCardData.short_name,
                  sponsor: targetCardData.sponsor,
                  vehicle: targetCardData.vehicle,
                  asset_alloc: targetCardData.asset_alloc,
                  weight_yield: targetCardData.weight_yield,
                  weight_track_record: targetCardData.weight_track_record,
                  'attribute/fundamental/yield': targetCardData['attribute/fundamental/yield'] || targetCardData['yield'],
                  outperform : targetCardData['outperform'] || 0.0,
                  'attribute/track_record': targetCardData['attribute/track_record'] || targetCardData['track_record'],
                  'attribute/technical/returns/risk_adjusted_returns': targetCardData['attribute/technical/returns/risk_adjusted_returns'],
                  _pF: pF,
                },
                ticker: currentData.ticker,
              });

            }

            // console.log(enhanceReplace);

            const isAllFundsUnChecked = enhanceReplace.filter((k, i) => k.ticker === k.replace);
            // console.log('isAllFundsUnChecked', isAllFundsUnChecked.length, enhanceReplace.length);

            if (isAllFundsUnChecked.length === enhanceReplace.length) {
              // remove enhanceStats and disable finalize
              setPortfolioState({
                filterResultSet: filterResultSet,
                enhanceReplace: [],
                enhancedPerformanceImpact: {},
              });
            } else {
	            let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(upDownDataSet,selectedBenchmark)
              statsForPerformanceImpact({
                portfolioEnhanceReplace: [...enhanceReplace],
                watchlistFundstats,
                accountFundstats,
                portfolioFunds,
                marketFunds,
                portfolioQuery,
                enhancePortfolioVisible,
                view,
	              portfolioBenchmark
              }).then(stats => {
                if (stats && typeof stats.enhancedPerformanceImpact !== 'undefined') {
                  enhancedPerformanceImpact = stats.enhancedPerformanceImpact;
                  setPortfolioState({
                    filterResultSet: filterResultSet,
                    enhanceReplace: [...enhanceReplace],
                    enhancedPerformanceImpact
                  });
                }
              });
            }

          }

        } catch (e) {
          console.log(e);
        }

        // deletePortfolioEnhanceReplace({ replaceTicker, ticker, query })
      })
    }
    this.props.resetCheckBoxFor('enhanceFund');
  }

  tabOnChange = (selectedKey, data, ticker, query, index) => {
    const { tabOnChange } = this.props;
    if (tabOnChange) {
     tabOnChange(selectedKey, data, ticker, query, index)
    }
  }

  render () {
    const { data, query, ticker, staticCard, resetCheck, portfolioQuery, activeViewTab, relevanceChartTabs, defaultFRR, originalQueryVar, portfolioReadableQuery } = this.props;
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      nextArrow: <RightOutlined />,
      prevArrow: <LeftOutlined />,
      responsive: [
        {
          breakpoint: 1900,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 1530,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 1180,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const messgageText = (q) => {
      if (q.includes('Outperform')) {
        return <span>You already hold <span className="text-bold">the best performing fund</span><br/> in this category.</span>;
      } else if (q.includes('Yield')) {
        return <span>There are <span className="text-bold">no funds</span> similar to <br/><span className="text-bold">{ticker}</span> with <span className="text-bold">higher yield</span>.</span>;
      } else if (q.includes('Track Record')) {
        return <span>There are <span className="text-bold">no funds</span> similar to <br/><span className="text-bold">{ticker}</span> with <span className="text-bold">longer track record</span>.</span>;
      } else if (q.includes('High Risk Adjusted')) {
        return <span>There are <span className="text-bold">no funds</span> similar to <br/><span className="text-bold">{ticker}</span> with <span className="text-bold">higher risk adjusted return</span>.</span>;
      } else if (q.includes('Risk Replacement')) {
        return <span>There are <span className="text-bold">no funds</span> similar to <br/><span className="text-bold">{ticker}</span> with <span className="text-bold">lower cost and risk replacements</span>.</span>;
      } else if (q.includes('Drawdown')) {
        return <span>There are <span className="text-bold">no funds</span> similar to <br/><span className="text-bold">{ticker}</span> with <span className="text-bold">lower drawdown alternatives</span>.</span>;
      } else if (q.includes('Risk')) {
        return <span>There are <span className="text-bold">no funds</span> similar to <br/><span className="text-bold">{ticker}</span> with <span className="text-bold">lower risk</span>.</span>;
      } else if (q.includes('Cost')) {
        return <span>There are <span className="text-bold">no funds</span> similar to <br/><span className="text-bold">{ticker}</span> with <span className="text-bold">lower cost</span>.</span>;
      } else if (q.includes('Fund Selector')) {
        return <span>There are <span className="text-bold">no funds</span> similar to <br/><span className="text-bold">{ticker}</span> with the <span className="text-bold">given criteria</span>.</span>;
      } else {
        return <span>There are <span className="text-bold">no funds</span> similar to <br/><span className="text-bold">{ticker}</span> for <span className="text-bold">'{portfolioQuery}'</span>.</span>;
      }
    }

    const renderDesign = data.funds.length ? (
      <Slider {...settings}>
        { data.funds.map((e, i) => (
          <ItemCard
            data={e}
            query={query}
            portfolioQuery={portfolioQuery}
            i={i}
            activeViewTab={activeViewTab}
            defaultFRR={defaultFRR}
            relevanceChartTabs={relevanceChartTabs}
            resetCheck={resetCheck}
            ticker={ticker}
            staticCard={staticCard}
            originalQueryVar={originalQueryVar}
            selected={e._selected}
            highlights={data.Highlights}
            setReplaceTicker={this.setReplaceTicker1}
            enhanceReplace={this.state.enhanceReplace}
            customReplaceActiveTab= {this.props.customReplaceActiveTab}
            tabOnChange={this.tabOnChange}
            createdForm="itemList"
          />
        )) }
      </Slider>
    ) : (
      <Card className="card-background-grey empty-message-wrapper" style={{ height: 279, margin: '0 8px' }}>
        <div className="text-box">
          {messgageText(portfolioReadableQuery || portfolioQuery)}
        </div>
      </Card>
    );

    return (
      <div className="itemlist-carousel-wrap" style={{ display: 'inherit', width: '100%' }}>
        {renderDesign}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  view: state.investment.view,
  replacePortfolioToggle: state.portfolio.replacePortfolioToggle,
  replaceAllPortfolioToggle: state.portfolio.replaceAllPortfolioToggle,
  resultSet: state.portfolio.resultSet,
  enhanceTickerFilter: state.portfolio.enhanceTickerFilter,
  portfolioFunds: getPortfolioFunds(state), //state.investment.portfolioFunds,
  marketFunds: state.investment.marketFunds,
  watchlistFundstats: getInvestmentWatchlistStats(state),
  accountFundstats: getInvestmentPositionStats(state),
  enhancePortfolioVisible: state.portfolio.enhancePortfolioVisible,
  enhanceReplace: state.portfolio.enhanceReplace,
  portfolioQuery: state.portfolio.query,
  portfolioReadableQuery: state.portfolio.query_english,
  enhancePortfolioVisible: state.portfolio.enhancePortfolioVisible,
	upDownDataSet:state.portfolio.upDownDataSet,
	selectedBenchmark:state.portfolio.selectedBenchmark
});

const mapDispatchToProps = {
  addPortfolioEnhanceReplace,
  deletePortfolioEnhanceReplace,
  setPortfolioState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemList);

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Form,
  FormGroup,
  Label,
  Input,
  Breadcrumb,
  BreadcrumbItem,
  Alert
} from 'reactstrap'
import GoogleLogin from 'react-google-login'

import { CustomFormFeedback, PrimaryButton } from 'shared-components'

import config from  'env';

export default class LoginForm extends Component {
  static propTypes = {
    formProps: PropTypes.object.isRequired,
    onGoogleSignInSuccess: PropTypes.func.isRequired,
    onGoogleSignInError: PropTypes.func.isRequired
  }

  render() {
    const { formProps, error } = this.props
    const {
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit
    } = formProps
    return (
      <Form className="sign-in" noValidate={true} onSubmit={handleSubmit}>
        <FormGroup>
          <legend className="sign-in__caption">
            Sign in with your credentials
          </legend>
          <Label htmlFor="email" className="sr-only">
            Email
          </Label>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors.email && touched.email
                ? 'sign-in__input-field sign-in__input-email is-invalid'
                : 'sign-in__input-field sign-in__input-email'
            }
          />
          <Label htmlFor="password" className="sr-only">
            Password
          </Label>
          <Input
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors.password && touched.password
                ? 'sign-in__input-field sign-in__input-password is-invalid'
                : 'sign-in__input-field sign-in__input-password'
            }
          />
          <CustomFormFeedback formProps={formProps} fieldName="email" />
          <CustomFormFeedback formProps={formProps} fieldName="password" />
          {error && error.error && <Alert color="danger">{error.error}</Alert>}
        </FormGroup>
        <FormGroup>
          <PrimaryButton
            type="submit"
            className="sign-in__submit"
            block
            disabled={isSubmitting}
          >
            Sign in
          </PrimaryButton>
        </FormGroup>
        <FormGroup>
          <Breadcrumb className="sign-in__breadcrumb">
            <BreadcrumbItem className="sign-in__breadcrumb-item">
              <Link to="/register" className="sign-in__breadcrumb-link">
                Register
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem className="sign-in__breadcrumb-item">
              <Link to="/forgot-password" className="sign-in__breadcrumb-link">
                Forgot Password?
              </Link>
            </BreadcrumbItem>
          </Breadcrumb>
        </FormGroup>
        <FormGroup>
          <GoogleLogin
            clientId={config.googleClientId}
            render={renderProps => (
              <button
                className="btn w-100 sign-in__googleBtn"
                onClick={renderProps.onClick}
              >
                {/* <div className="d-flex align-items-center justify-content-center">
                  <i className="fab fa-google mr-2 font-size-lg" />
                  Sign in with Google
                </div> */}
                <img src="assets/images/btn_google_signin_light_normal_web.png"></img>
              </button>
            )}
            onSuccess={this.props.onGoogleSignInSuccess}
            onFailure={this.props.onGoogleSignInError}
          />
        </FormGroup>
      </Form>
    )
  }
}

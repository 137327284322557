import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Space, Input, Menu, Button } from 'antd';
import { filterProposals } from 'actions/profile';
import DatePicker from 'react-datepicker';
import * as style from './_index.scss';
import * as moment from 'moment';

class DropdownContent extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      searchText: ''
    }
    this[this.props.selector] = React.createRef();
  }

  componentDidMount(){
    if (this.props.selector && this.props.selector !== 'created_at') {
      this[this.props.selector] && this[this.props.selector].current.focus();
    }

    if (this.props.selector && this.props.selector === 'created_at') {

    }
  }

  componentDidUpdate(prevProps){
    if (this.props.selector && this.props.selector !== 'created_at') {
      this[this.props.selector] && this.props.showDropdown && this[this.props.selector].current.focus();
    }

    if(prevProps.proposalsfilteredColumn != this.props.proposalsfilteredColumn ||
      prevProps.proposalsClearAllFilter != this.props.proposalsClearAllFilter){
      this.setState({
        searchText: ''
      })
    }
  }

  handleSearchText = (e) => {
    this.setState({
      searchText: e.target.value
    })
  }

  handleReset = () => {
    this.setState({
      searchText: ''
    }, () => this.props.handleSearchReset())
  }

  handleSearchSubmit = () => {

    let filteredProposals;
    const { selector, initialProposalList } = this.props;
    const { searchText } = this.state;
    if (searchText && initialProposalList.length > 0) {
      if (selector === 'advisor') {
        filteredProposals = initialProposalList.filter( item =>
            (item['first_name'].toLowerCase().includes(searchText.toLowerCase()) || item['last_name'].toLowerCase().includes(searchText.toLowerCase()))
        )
      } else if (selector === 'created_at') {
        filteredProposals = initialProposalList.filter(item => item[selector] &&
          moment(searchText).isSame( moment(item[selector]),'date'))
      } else {
        filteredProposals = initialProposalList.filter(item => item[selector] &&
            item[selector].toLowerCase().includes(searchText.toLowerCase()))
      }

      if (filteredProposals.length > 0) {
        this.props.filterProposals({
          proposalsList: filteredProposals,
          proposalsfilteredColumn: selector,
          proposalsfilteredText: searchText,
          proposalsClearAllFilter: false
        })
      }else{
        // Create a reset filter or no results action
        this.props.filterProposals({
          proposalsList: [],
          proposalsfilteredColumn: selector,
          proposalsfilteredText: searchText,
          proposalsClearAllFilter: false
        })
      }
    }
    this.props.dropdownToggle();
  };

  handleDateChange = (dateInstance) => {
    this.setState({
      searchText: dateInstance
    })
  }

  render() {
    const { title, selector, handleSearchReset, filterName } = this.props;

    return (
      <Menu className="ant-table-filter-dropdown archiveFilters">
        <div style={{ padding: 8, marginTop: 10 }}>
          {
            selector && selector === 'created_at' ?

            <DatePicker
              id={filterName}
              selected={(this.state.searchText) ? this.state.searchText : ''}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText={filterName}
              maxDate={new Date()}
              autoComplete="off"
              onChange={(dateInstance, dateString) => {
                this.handleDateChange(dateInstance)
              }}
              className="picker ant-input"
              dateFormat="dd MMM, YYYY | hh:mm:ss aa"
              timeIntervals={15}
            />
            :
             <Input
              placeholder={filterName}
              ref={this[selector]}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
              value={this.state.searchText}
              onChange={this.handleSearchText}
              onPressEnter={this.handleSearchSubmit}
            />
          }
          <Space>
            <Button
              type="primary"
              size="small"
              style={{ width: 90 }}
              onClick={this.handleSearchSubmit}
            >
              Filter
            </Button>
            <Button
              size="small"
              style={{ width: 90 }}
              onClick={this.handleReset}
            >
              Reset
            </Button>
          </Space>
        </div>
      </Menu>
    );
  }
}

const mapStateToProps = ({ profile: { proposalsList, initialProposalList, proposalsfilteredText, proposalsfilteredColumn, proposalsClearAllFilter } }) => ({
  proposalsList,
  initialProposalList,
  proposalsfilteredText,
  proposalsfilteredColumn,
  proposalsClearAllFilter
})

const mapDispatchToProps = {
  filterProposals
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropdownContent);

import React from 'react';
import { isUrl } from './utils';

// const menuData = [{
//   name: 'Accounts',
//   icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
//   path: 'accounts',
//     children: [{
//       name: 'Accounts',
//       icon: <i style={{ marginRight: 10 }} className="fas fa-gift" />,
//       path: 'onboard-screen',
//       // hideInMenu: true,
//     }, {
//       name: 'Dashboard',
//       icon: <i style={{ marginRight: 10 }} className="fas fa-gift" />,
//       path: 'dashboard',
//       // hideInMenu: true,
//       children: [{
//         name: 'Summary',
//         icon: <i style={{ marginRight: 10 }} className="fas fa-gift" />,
//         path: 'summary',
//         // hideInMenu: true,
//       }, {
//         name: 'Funds',
//         icon: <i style={{ marginRight: 10 }} className="fas fa-gift" />,
//         path: 'funds',
//         // hideInMenu: true,
//       }, {
//         name: 'Models',
//         icon: <i style={{ marginRight: 10 }} className="fas fa-gift" />,
//         path: 'models',
//         // hideInMenu: true,
//       }],
//     }],
//   },
// ];
const menuData = [{
  name: 'Accounts',
  icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
  path: 'accounts',
    children: [{
      name: 'Dashboard',
      icon: <i style={{ marginRight: 10 }} className="fas fa-gift" />,
      path: 'dashboard',
      // hideInMenu: true,
      children: [{
        name: 'Summary',
        icon: <i style={{ marginRight: 10 }} className="fas fa-gift" />,
        path: 'summary',
        // hideInMenu: true,
      }, {
        name: 'Funds',
        icon: <i style={{ marginRight: 10 }} className="fas fa-gift" />,
        path: 'funds',
        // hideInMenu: true,
      }, {
        name: 'Models',
        icon: <i style={{ marginRight: 10 }} className="fas fa-gift" />,
        path: 'models',
        // hideInMenu: true,
      }]
    }, {
      name: 'Apex',
      icon: <i style={{ marginRight: 10 }} className="fas fa-gift" />,
      path: 'apex',
      // hideInMenu: true,
      children: [{
        name: 'Empty Result',
        icon: <i style={{ marginRight: 10 }} className="fas fa-gift" />,
        path: 'empty-result',
        // hideInMenu: true,
      }, {
        name: 'Create',
        icon: <i style={{ marginRight: 10 }} className="fas fa-gift" />,
        path: 'create',
        // hideInMenu: true,
      }, {
        name: 'Under Verification',
        icon: <i style={{ marginRight: 10 }} className="fas fa-gift" />,
        path: 'under-verification',
        // hideInMenu: true,
      }]
    }],
  },
];

function formatter(data, parentPath = '/', parentAuthority) {
  return data.map((item) => {
    let { path } = item;
    if (!isUrl(path)) {
      path = parentPath + item.path;
    }
    const result = {
      ...item,
      path,
      authority: item.authority || parentAuthority,
    };
    if (item.children) {
      result.children = formatter(item.children, `${parentPath}${item.path}/`, item.authority);
    }
    return result;
  });
}

export const getMenuData = () => formatter(menuData);

import React, { Component } from 'react'
import { Redirect, Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Button,
  ButtonGroup,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'

import { PrimaryButton, SecondaryButton, TickIcon } from 'shared-components'
import { getQuery } from 'common/auth-utils'
import { logout, termsAgreement } from 'actions/auth'
import { isTermsAgreed, isNewUser } from 'selectors/auth-selector'
import { createLoadingSelector } from 'selectors/loading-selector'
import { Spinner } from 'shared-components/'

class TermsNConditions extends Component {

  handleDisagree = () => {
    this.props.logout()
  }

  handleAgree = () => {
    this.props.termsAgreement()
  }

  render() {
    const {
      isFetching,
      isTermsAgreed,
      isNewUser,
      isDisagreeFetching
    } = this.props

     
    const cancelBtn = (
      <SecondaryButton type="button" onClick={this.handleDisagree}>
        DISAGREE
      </SecondaryButton>
    );

    return (
      <Modal
        isOpen={true}
        centered
        className="registrations-process-modal"
        backdropClassName="registrations-process-modal-backdrop"
      >
        <Spinner isFetching={isDisagreeFetching} />
        <Spinner isFetching={isFetching} />
        <Form className="register-form" noValidate={true}>
          <ModalBody className="ssf-modal__body">
            <div className="registrations-process-modal-container">
              <div className="header-wrapper">
                <h1 className="text-align-left">Terms & Conditions</h1>
              </div>
              <p className="content-wrapper">
               {/* By registering on our website, you agree not to use the service in any way that violates local or international laws.  The agreement to use the service lawfully is a necessary condition to providing you access to the website.  All content and functionality on this site is exclusively the property of Magnifi LLC, unless otherwise explicitly stated. */}
                Thank you for using Magnifi. By operating our website, you agree not to use the service in any way that violates local or international laws.  The agreement to use the service lawfully is a necessary condition to providing you access to the website.  All content and functionality on this site is exclusively the property of Magnifi LLC, unless otherwise explicitly stated.
              </p>
              <ButtonGroup className="cta-wrapper justify-content-left">
                <PrimaryButton className="btn secondary-btn" type="button" onClick={this.handleAgree} style={{ maxWidth: 150 }}>
                  Accept
                </PrimaryButton>
              </ButtonGroup>
            </div>
          </ModalBody>
        </Form>
      </Modal>
    )
  }
}

const mapStateToProps = ({ auth, loading }) => ({
  isTermsAgreed: isTermsAgreed(auth),
  isNewUser: isNewUser(auth),
  isFetching: createLoadingSelector(['TERMS_AGREEMENT'])({ loading }),
  isDisagreeFetching: createLoadingSelector(['LOGOUT'])({ loading })
})

const mapDispatchToProps = {
  logout,
  termsAgreement
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TermsNConditions))

import React, { Component } from 'react'
import { Route, NavLink, Switch, Redirect } from 'react-router-dom'
import SelectBrokerContainer from './SelectBrokerContainer'
import { PrivateRoute } from 'shared-components'

export default class CallbackTDAmeritrade extends Component {
  render() {
    console.log('td amer', this.props);
    let searchParams = new URLSearchParams(window.location.search);
    let code = searchParams.get('code');
    let error = searchParams.get('error');
    if(code){
      localStorage.setItem('activeBrokerName','tdameritrade')
    }
    return <Redirect to={{
      pathname: "/settings/broker/select",
      state: { 
        received_code: code 
      }
    }} />
  }
}

import React, { Component} from 'react'
import { connect } from 'react-redux';
import ConfigureWeights from '../components/sf/ConfigureWeights';
import { fetchWeightsData } from '../actions/weights';

class WeightsContainer extends Component {

    constructor (props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchWeightsData();
    }

    render () {
        return (
            <div className="weights-container settings__content-area">
                <ConfigureWeights scoreAttrs={[]}  scoreAttrsWeightHandler={()=>{}}/>
            </div>
        )
    }

}

const mapStateToProps = ({  }) => ({
  })
  
  const mapDispatchToProps = {
    fetchWeightsData,
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(WeightsContainer)


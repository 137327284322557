import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Card } from 'antd';
import { Modal, ModalBody, FormGroup } from 'reactstrap';
import { addEventToAnalytics } from 'Utils'
import { InnerPageFooter } from 'shared-components/'

import {
  getModelPortfoliosByIdRequest,
  setEnhancerState,
  resetSummaryDataRequest,
  replaceCurrentModelPortfolioWithRequest,
  downloadEnhancerReport,
  downloadRecommendationEnhancerReport,
  finalizeModelPortfolioRequest,
  finalizeModelPortfolioSuccess,
  setEnhancedTransaction,
  savePortfolioRequest,
  resetFinalizeDataRequest,
  useSuggestedFundsRequest,
  getCurrentPortfolioScoreRequest,
  getEnhancedPortfolioScoreRequest,
  getEnhanceStatsForSelectedTickerRequest,
} from 'actions/enhancer';
import { setBuildPortfolioState } from 'actions/buildportfolio';

import { createLoadingSelector } from 'selectors/loading-selector';

import ContentHead from './ContentHead';
// import EnhancePortfolio from './EnhancePortfolio';
import CardRow from './EnhancePortfolio/CardRow';
import PortfolioModal from '../../Elements/PortfolioModal';

import { REDIRECT_PATH } from 'DataSet';
import { getPortfolioBenchmarkOfSelectedTicker } from 'Utils';
import { geopMeanCalc, getCalculatedEnhanceStats } from '../../../AccountsPage/utils';
import { getFinalizeSummaryFunds } from 'selectors/enhancer';
import SummaryBoard from '../Summary/Board';

// import { Loader } from '../../../AccountsPage/Elements/Loader';
import CustomAlert from 'components/CustomAlert';
import GenerateTradeList from '../Funds/Elements/GenerateTradeList';

const isPortfolioDataOld = (portfolioLastUpdatedOn) => {
  if (portfolioLastUpdatedOn && typeof portfolioLastUpdatedOn !== 'undefined') {
    // const currentDate = moment.utc(moment()).format(); // moment.utc(moment()).format('YYYY-MM-DD'); // NOTE: DO NOT CHANGE 'YYYY-MM-DD' format
    // // const portfolioLastUpdated = (portfolioLastUpdatedOn !== null) ? moment.utc(portfolioLastUpdatedOn).format('YYYY-MM-DD') : null; // NOTE: DO NOT CHANGE 'YYYY-MM-DD' format
    // const portfolioLastUpdated = (portfolioLastUpdatedOn !== null) ? moment.utc(portfolioLastUpdatedOn).format() : null;
    // // const loadPortfolio = portfolioLastUpdated !== null ? moment(currentDate).isAfter(portfolioLastUpdated) : null;
    // return portfolioLastUpdated !== null ? currentDate.diff(portfolioLastUpdated).asHours() > 24 : null;

    // return (
    //   moment.duration(
    //     moment.utc(
    //       moment()
    //     ).format()
    //   ).diff(
    //     moment.utc(portfolioLastUpdatedOn).format()
    //   )
    // ).asHours() > 24

    const currentDate = moment.utc(moment()).format();
    const portfolioLastUpdated = (portfolioLastUpdatedOn !== null) ? moment.utc(portfolioLastUpdatedOn).format() : null;
    return (
      moment.duration(
        moment(currentDate).diff(portfolioLastUpdated)
      ).asHours() > 24
    );
  }
  return false;
}

class Models extends Component {
  constructor(props) {
    super(props);
    this.state = {
      summaryDrawer: false,
      fileName: '',
      generateTradeList: false,
      modelsTimeout: null,
      nullLastUpdateLoading: false
    };
  }

  componentDidMount() {
    const {
      location,
      onboardData,
      history,
      setEnhancerState,
      match,
      getModelPortfoliosByIdRequest,
      portfolioName,
      loading,
      summary,
      performanceImpact,
      redirectFrom,
    } = this.props;
    this.setState({ nullLastUpdateLoading: true });
    this.executeQuery({
      location,
      onboardData,
      history,
      setEnhancerState,
      match,
      getModelPortfoliosByIdRequest,
      portfolioName,
      loading,
      summary,
      performanceImpact,
      redirectFrom,
    }, () => {
      this.executeCallBack(this.props);
    });
  }

  componentWillReceiveProps(nextProps) {
    const {
      location,
      onboardData,
      history,
      setEnhancerState,
      match,
      getModelPortfoliosByIdRequest,
      portfolioName,
      loading,
      summary,
      performanceImpact,
      redirectFrom,
      // modelPortfolios
    } = nextProps;
    // const { replacePortfolioOptions } = modelPortfolios;
    // console.log(location);
    // console.log(location.pathname.includes('/enhancer/dashboard/models'));
    if (location !== this.props.location &&
      location.search !== this.props.location.search &&
      location.pathname &&
      location.pathname.includes('/enhancer/dashboard/models')
    ) {
      // console.log('componentWillReceiveProps executeQuery -- ')
      this.executeQuery({
        location,
        onboardData,
        history,
        setEnhancerState,
        match,
        getModelPortfoliosByIdRequest,
        portfolioName,
        loading,
        summary,
        performanceImpact,
        redirectFrom,
      }, () => {
        this.executeCallBack(nextProps);
      });
    }
  }

  componentDidUpdate() {
    const headerElement = document.getElementById("magnifi-header-wrapper");
    const portfolioContentHeaderElement = document.getElementById("funds-board-content-header");
    if (portfolioContentHeaderElement && headerElement) {
      const portfolioSummaryDrawerElement = document.getElementById("summaryDrawerElement");
      if (portfolioSummaryDrawerElement) {
        const drawerxContentElement = portfolioSummaryDrawerElement.getElementsByClassName("ant-drawer-content-wrapper")[0];
        drawerxContentElement.style.top = portfolioContentHeaderElement.offsetHeight + 15 + 'px';
      }
    }
  }

  executeCallBack(props = this.props) {
    // console.log('<-- executeCallBack -->');
    // console.log(props.modelPortfolios);
    // console.log(this.props.modelPortfolios);
    const {
      location,
      onboardData,
      history,
      setEnhancerState,
      match,
      getModelPortfoliosByIdRequest,
      portfolioName,
      loading,
      summary,
      performanceImpact,
      redirectFrom,
      modelPortfolios
    } = this.props;
    const { replacePortfolioOptions } = modelPortfolios;
    // if (replacePortfolioOptions) {
    //   console.log(replacePortfolioOptions)
    //   console.log(replacePortfolioOptions.last_updated);
    //   console.log(moment(replacePortfolioOptions.last_updated), moment().diff(moment(replacePortfolioOptions.last_updated), 'hours'));
    //   console.log('-- date is greater than 24 hrs -->', moment().diff(moment(replacePortfolioOptions.last_updated), 'hours'));
    //   console.log(moment.duration(moment(moment.tz(moment.utc(moment()).format(), "utc").format()).diff(moment(moment.tz(replacePortfolioOptions.last_updated, "utc").format()))).asHours())
    //   console.log('replacePortfolioOptions ->', moment.duration(moment(moment.tz(moment.utc(moment()).format(), "utc").format()).diff(moment(moment.tz(replacePortfolioOptions.last_updated, "utc").format()))).asHours() > 24)
    // }

    // let weAreCalculatingData = (replacePortfolioOptions &&
    //   typeof replacePortfolioOptions.last_updated !== 'undefined' &&
    //   (replacePortfolioOptions.last_updated === null ||
    //     moment.duration(moment(moment.tz(moment.utc(moment()).format(), "utc").format()).diff(moment(moment.tz(replacePortfolioOptions.last_updated, "utc").format()))).asHours() > 24
    //   )
    // );

    let weAreCalculatingData = (replacePortfolioOptions &&
      typeof replacePortfolioOptions.last_updated !== 'undefined' &&
      (replacePortfolioOptions.last_updated === null || isPortfolioDataOld(replacePortfolioOptions.last_updated))
    );

    if (weAreCalculatingData) {
      this.executeQuery({
        location,
        onboardData,
        history,
        setEnhancerState,
        match,
        getModelPortfoliosByIdRequest,
        portfolioName,
        loading,
        summary,
        performanceImpact,
        redirectFrom,
      }, () => {
        this.executeCallBack();
      }, true);
    } else {
      this.setState({ nullLastUpdateLoading: false });
    }
  }

  executeQuery = (data, callback, repeated = false) => {
    const {
      location,
      onboardData,
      history,
      // setEnhancerState,
      match,
      getModelPortfoliosByIdRequest,
      // portfolioName,
      loading,
      summary,
      performanceImpact,
      redirectFrom,
    } = data;
    try {
      if (match && !loading) {
        // const id = match.params.id
        if (location && location.search) {
          const params = new URLSearchParams(location.search)
          if (params) {
            const universe = params.get('universe');
            // const weight = params.get('weight');
            const similarityMeasures = params.get('similarity-measures');
            const query = params.get('q');
            const portfolioId = params.get('portfolioId');
            const portfolioType = params.get('portfolioType');
            const tickers = params.get('tickers');
            const _redirectPath = params.get('callbackPath');

            if (universe &&
              similarityMeasures &&
              // query &&
              universe !== 'undefined' &&
              query !== 'undefined' &&
              similarityMeasures !== 'undefined' &&
              portfolioId &&
              portfolioType &&
              getModelPortfoliosByIdRequest &&
              portfolioId !== 'undefined' &&
              portfolioType !== 'undefined' &&
              tickers &&
              tickers !== 'undefined') {

              let _activeSelectorUniverse = [];
              let _payloadRequest = {
                tickers,
                similarityMeasures,
                searchTypeMode: 'MODELS',
                modelUniverse: universe,
                activeModelUniverse: universe.split(','),
                activeFundUniverse: [],
                activeSelectorUniverse: _activeSelectorUniverse,
                portfolioName: portfolioId,
              }

              if (query) {
                _payloadRequest = {
                  query, // query needs to be at first, for URL
                  ..._payloadRequest,
                }
              }

              if (_redirectPath && (_redirectPath !== 'undefined' || _redirectPath !== '')) { // check present in URL
                if (REDIRECT_PATH[_redirectPath]) { // url has VALUE
                  if (Object.keys(redirectFrom).length > 0) { // check redirectFrom must present
                    _payloadRequest.redirect = REDIRECT_PATH[_redirectPath];
                  } else { // return back to '_redirectPath'
                    if (history) {
                      history.push(REDIRECT_PATH[_redirectPath])
                    }
                  }
                } else {
                  console.log('-- _redirectPath not present --');
                }
              } else {
                console.log('-- _redirectPath not present --');
              }

              if (summary && summary.portfolioFunds && summary.portfolioFunds.length === 0) {
                // current portfolio is empty then call apis to get data
                throw '-- summary data missing for model portfolio --'
              } else {
                // console.log(performanceImpact.currentPerformanceImpact);
                // if (performanceImpact && performanceImpact.currentPerformanceImpact && Object.keys(performanceImpact.currentPerformanceImpact).length > 0) {
                const { currentPerformanceImpact } = performanceImpact;
                const { portfolioFunds, portfolioValue } = summary;
                // map current portfolion data
                if (portfolioType === 'MarketPlace') {
                  const sponsoredPortfolioName = params.get('sponsoredPortfolioName');
                  // console.log(sponsoredPortfolioName);
                  if (sponsoredPortfolioName && typeof sponsoredPortfolioName !== 'undefined' && sponsoredPortfolioName !== '' && sponsoredPortfolioName !== null) {
                    _payloadRequest.sponsoredPortfolioName = sponsoredPortfolioName;
                    getModelPortfoliosByIdRequest({
                      ..._payloadRequest,
                      portfolioType: portfolioType,
                      repeated: repeated,
                      onboardData: {
                        ...onboardData,
                        selectedPortfolioType: portfolioType,
                      },
                      myCurrentPortfolio: {
                        portfolioDetails: {
                          name: portfolioId,
                          profile: {
                            firstName: window.localStorage.getItem('firstName'),
                            lastName: window.localStorage.getItem('lastName'),
                          }, // do not change this
                          riskFeeReturn: currentPerformanceImpact,
                          chart: currentPerformanceImpact.asset_alloc,
                        },
                        holdings: _.orderBy(portfolioFunds, ['weight'], ['desc']),
                        portfolioValue: portfolioValue, // || 10000,
                      },
                    }, callback);
                  } else {
                    throw 'sponsoredPortfolioName not found in url'
                  }
                } else {
                  getModelPortfoliosByIdRequest({
                    ..._payloadRequest,
                    portfolioType: portfolioType,
                    repeated: repeated,
                    onboardData: {
                      ...onboardData,
                      selectedPortfolioType: portfolioType,
                    },
                    myCurrentPortfolio: {
                      portfolioDetails: {
                        name: portfolioId,
                        profile: {
                          firstName: window.localStorage.getItem('firstName'),
                          lastName: window.localStorage.getItem('lastName'),
                        }, // do not change this
                        riskFeeReturn: currentPerformanceImpact,
                        chart: currentPerformanceImpact.asset_alloc,
                      },
                      holdings: _.orderBy(portfolioFunds, ['weight'], ['desc']),
                      portfolioValue: portfolioValue, // || 10000,
                      targetCard: {}
                    },
                  }, callback);
                }
                // } else {
                //   // NOTE: Or we can show Notification with some message like 'fetching current stats please try again later' because api is calculating current-stats
                //   throw '-- performanceImpact missing for current portfolio --'
                // }
              }
            } else {
              throw 'data not found in url'
            }
          } else {
            throw 'data not found in url'
          }
        } else {
          throw 'data not found in url'
        }
      }
    } catch (e) {
      console.log(e);
      this.routeToOnBoardScreen();
      // const { history } = this.props;
      // if (history) {
      //   const newPath = `/enhancer`
      //   history.push(newPath);
      // }
    }
  }

  routeToOnBoardScreen = () => {
    addEventToAnalytics('Enhancer Edit Home', 'Enhancer Edit Home', 'ENHANCER_EDIT_HOME', {}, false);
    const { history, resetSummaryDataRequest } = this.props;
    if (resetSummaryDataRequest && history) {
      resetSummaryDataRequest('', () => {
        history.push(`/enhancer/onboard-screen`);
      })
    }
  }

  openDrawer = (visible) => {
    if (visible === true)
      addEventToAnalytics('Enhancer Summary', 'Enhancer Summary', 'ENHANCER_SUMMARY', {}, false);
    this.setState({
      summaryDrawer: visible,
    });
  }

  finalizeClick = () => {
    // console.log('call finalize api');
    const { enhancer, finalizeModelPortfolioRequest, finalizeModelPortfolioSuccess, onboardData, modelPortfolios } = this.props;
    const { myCurrentPortfolio } = modelPortfolios;
    // const { summary } = enhancer;
    const requestData = enhancer.enhanceReplace[0];
    const replaceDataArr = requestData.selectedPortfolioFunds.holdings.map((o) => {
      return {
        ticker: o.h_ticker,
        shares: o._h_quantity,
        "purchase_price": o.nav,
        "purchase_date": moment(new Date()).format('DD-MM-YYYY'),
      };
    });
    // const requestDataArr = summary && summary.portfolioFunds.length > 0 ? summary.portfolioFunds.map((l) => {
    //   return {
    //     ticker: l.ticker,
    //     shares: l.shares,
    //   }
    // }) : [];
    // console.log(requestDataArr);
    let obj = {
      "portfolio_name": requestData.ticker, // current_portfolio's name
      "replace_portfolio_type": "custom_portfolio", // custom_portfolio/model_portfolio
      "replace_portfolio_id": requestData.replace, // model_portfolio's ticker
      "replace_data": replaceDataArr,
      // "data": requestDataArr,
    };
    if (onboardData.selectedPortfolioType === "MarketPlace" && myCurrentPortfolio && myCurrentPortfolio.holdings) {
      const holdsTickers = myCurrentPortfolio.holdings.map((h) => {
        return {
          ticker: h.ticker,
          shares: h.shares,
        }
      });
      // console.log(holdsTickers);
      if (holdsTickers) {
        obj.data = holdsTickers;
      }
    }

    finalizeModelPortfolioRequest({
      request: obj,
      enhanceCTA: 'Finalize',
    }, (response) => {
      // console.log('finalizePortfolioRequest executed');
      // console.log(response);
      if (response) {
        finalizeModelPortfolioSuccess({
          finalizeData: { ...response },
        });
      }
    });
  }

  generateProposalClick = (isRecommendation = false) => {
    // console.log('call generateProposal api');
    isRecommendation ? this.downloadModelReport() : this.downloadEnhancerPDF()
  }

  generateTradeListClick = () => {
    // console.log('call generateTradelist api');
    addEventToAnalytics('Enhancer Tradelist', 'Enhancer Tradelist', 'ENHANCER_TRADELIST', {}, false);
    this.tradeList()
  }

  tradeList = () => {
    this.setState({
      generateTradeList: !this.state.generateTradeList,
      alert: false,
    })
    const { finalizeData } = this.props;
    this.props.setEnhancedTransaction(finalizeData.portfolioName);
  }

  savePortfolioClick = () => {
    // console.log('open popup for portfolio name');
    const { finalizeData, history, redirectToPath, redirectFrom, setEnhancerState, setBuildPortfolioState, resetFinalizeDataRequest } = this.props;
    try {
      if (finalizeData &&
        finalizeData.portfolioName &&
        finalizeData.summary &&
        finalizeData.onboardData &&
        finalizeData.enhancedPortfolioId &&
        finalizeData.onboardData.selectedPortfolioType &&
        typeof finalizeData.summary !== 'undefined' &&
        typeof finalizeData.summary.retData !== 'undefined' &&
        finalizeData.summary.retData.length > 0 &&
        typeof finalizeData.summary.portfolioFunds !== 'undefined' &&
        finalizeData.summary.portfolioFunds.length > 0) {

        const enhancedPortfolioId = finalizeData.enhancedPortfolioId || undefined;

        if (redirectFrom &&
          Object.keys(redirectFrom).length > 0 &&
          typeof redirectFrom.module !== 'undefined' &&
          redirectFrom.module === 'builder' &&
          typeof redirectFrom.savedPortfolio !== 'undefined' &&
          redirectFrom.portfolioName !== '' &&
          typeof redirectFrom.portfolioName !== 'undefined' &&
          resetFinalizeDataRequest &&
          setBuildPortfolioState &&
          setEnhancerState
        ) {
          let updatedPortfolio = {
            ...redirectFrom,
            portfolioName: redirectFrom.portfolioName,
            returnPortfolioID: enhancedPortfolioId
          }
          setBuildPortfolioState({
            portfolioName: redirectFrom.portfolioName,
            savedPortfolio: {
              ...redirectFrom.savedPortfolio,
              portfolio_id: enhancedPortfolioId,
            },
          });
          setEnhancerState({
            redirectFrom: updatedPortfolio,
            searchTypeMode: 'FUNDS', // NOTE: NEED TO SET DEFAULT
            finalizeData: {},
          });
          if (history && redirectToPath && redirectToPath !== '') {
            history.push(redirectToPath);
          }
        } else {
          this.setState({ saveEnhancePortfolioModal: true });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  toggleSavePortfolioModal = () => {
    this.setState({ saveEnhancePortfolioModal: !this.state.saveEnhancePortfolioModal })
  }

  closeSavePortfolioModal = () => {
    this.setState({ saveEnhancePortfolioModal: false })
  }

  //model recommendation report
  downloadModelReport = () => {
    let out = {};
    const { customLogo, enhancer, modelEnhancement, downloadRecommendationEnhancerReport, _disclosures } = this.props;
    let { fundUniverse, modelUniverse } = enhancer;
    // console.log('modelEnhancement --> ', modelEnhancement);
    let newModelEnhancement = {
      ...modelEnhancement,
      enhanced: {
        ...modelEnhancement.enhanced,
        portfolioName: modelEnhancement.enhanced.data.custom_replace_pf_name
      },
    };
    out.query = enhancer.query;
    out.queryEnglish = enhancer.query_english;
    out.logo = customLogo ? customLogo : '';
    out.disclosures = _disclosures || ''
    out.searchTypeMode = enhancer.searchTypeMode;
    out.modelEnhancement = modelEnhancement.enhanced.data.custom_replace_type === 'user' ? newModelEnhancement : modelEnhancement;
    let analytics_data = enhancer.enhanceReplace.map(item => {
      return {
        ticker: item.ticker,
        replace: item.replace
      }
    })
    // console.log(' -- Rec-Report Download Request -- ', this.props.query_uuid);
    addEventToAnalytics('Re-Report Download Request', 'Re-Report Download Request', 'RE-REPORT DOWNLOAD REQUEST', { funds: analytics_data, query_uuid: this.props.query_uuid, category: 'advisory_PTC' }, false);
    out.fundUniverse = fundUniverse;
    out.modelUniverse = (modelUniverse === 'mp') ? 'Sponsor Models' : 'My models';
    // console.log('out --> ', out);
    downloadRecommendationEnhancerReport(out);
  }

  //model enhancer report final
  downloadEnhancerPDF = (beforeFinalizeGenerateProposal = false) => {
    //modelEnhancement , portfolio
    const { enhancer, fundSelector, customLogo, modelEnhancement, downloadEnhancerReport, _disclosures, searchTypeMode, advisorFees } = this.props;
    let logo = customLogo ? customLogo : ''
    const disclosures = _disclosures || '';
    let { fundUniverse, modelUniverse } = enhancer;
    if (beforeFinalizeGenerateProposal) {
      addEventToAnalytics(
        'Enhancer Report',
        'Enhancer Report',
        'ENHANCER_REPORT', {
        query_uuid: this.props.query_uuid,
        source: 'Before Finalize'
      },
        false,
      );
      // const { positions } = fundStats;
      // const ALL_FUNDS_TICKER = uniqValues(positions, 'symbol');
      let enhanceReplace = enhancer.enhanceReplace;
      enhanceReplace = enhanceReplace.filter(e => e.query != '').filter((k) => k.ticker !== k.replace); // selected EVALUATE & Selected FUNDS; //added for not displaying funds with no recomendations
      enhanceReplace.forEach((e, i) => {
        let itemResultSet = enhancer.fundPortfolios.filter(el => el.ticker == e.ticker)[0];
        if (!this.props.hideGenerateProposalBtn && enhanceReplace[i].selectedPortfolioFunds && itemResultSet && itemResultSet.target_card && itemResultSet.target_card.funds[0]) {
          enhanceReplace[i].selectedPortfolioFunds.target_card = itemResultSet.target_card.funds[0];
          const selectedPortfolioTicker = enhanceReplace[i].selectedPortfolioFunds.ticker;
          enhanceReplace[i].selectedPortfolioFunds._frs = itemResultSet.result.funds.find(item => item.ticker === selectedPortfolioTicker)._frs;
        }
      });
      let newModelEnhancement = {
        ...modelEnhancement,
        enhanced: {
          ...modelEnhancement.enhanced,
          portfolioName: modelEnhancement.enhanced.data.custom_replace_pf_name
        }
      }
      // console.log(enhanceReplace);
      const obj = {
        investment: {
          portfolioValue: enhancer.portfolioValue
        },
        portfolio: {
          //mp query toggle
          isModelPortfolioQuery: true,
          query: enhancer.query,
          query_english: enhancer.query_english,
          enhanceReplace: enhanceReplace,
          fundSelector: fundSelector.myScoreAttrs,
          enhancedPerformanceImpact: enhancer.performanceImpact.enhancedPerformanceImpact, // enhancer.performanceImpact.currentPerformanceImpact,
          currentPerformanceImpact: enhancer.performanceImpact.currentPerformanceImpact, // enhancer.finalizeData.finalizePerformanceImpact.currentPerformanceImpact,
          selectedBenchmark: enhancer.performanceImpact.selectedBenchmark,
          logo,
          disclosures,
          modelEnhancement: modelEnhancement.enhanced.data.custom_replace_type === 'user' ? newModelEnhancement : modelEnhancement,
          fundUniverse,
          advisory_fees: advisorFees,
          modelUniverse: modelUniverse === 'mp' ? 'Sponsor Models' : 'My models',
        },
        // NOTE: '--- SOME-TIME it crash for missing "modelEnhancement" ---'
        isModelPortfolioQuery: true,
        query: enhancer.query,
        query_english: enhancer.query_english,
        enhanceReplace: enhanceReplace,
        fundSelector: fundSelector.myScoreAttrs,
        enhancedPerformanceImpact: enhancer.performanceImpact.enhancedPerformanceImpact, // enhancer.performanceImpact.currentPerformanceImpact,
        currentPerformanceImpact: enhancer.performanceImpact.currentPerformanceImpact, // enhancer.finalizeData.finalizePerformanceImpact.currentPerformanceImpact,
        selectedBenchmark: enhancer.performanceImpact.selectedBenchmark,
        logo,
        disclosures,
        modelEnhancement: modelEnhancement.enhanced.data.custom_replace_type === 'user' ? newModelEnhancement : modelEnhancement,
        fundUniverse,
        modelUniverse: modelUniverse === 'mp' ? 'Sponsor Models' : 'My models',
        advisory_fees: advisorFees,
        // ------------------------------------------------------------------------------------------
        searchTypeMode,
      };
      // console.log(obj)
      // obj.portfolio.currentPerformanceImpact.averageCostAdjRet10Yr /= 100;
      // obj.portfolio.enhancedPerformanceImpact.averageCostAdjRet10Yr /= 100;
      // console.log('Comparison Report', obj)
      downloadEnhancerReport(obj);
    } else {
      addEventToAnalytics(
        'Enhancer Report',
        'Enhancer Report',
        'ENHANCER_REPORT', {
        query_uuid: this.props.query_uuid,
        source: 'After Finalize'
      },
        false,
      );
      let fundStats = getFinalizeSummaryFunds(enhancer);
      // console.log({ view, fundStats, portfolioValue, portfolio, fundSelector, logo, modelEnhancement })
      if (fundStats && fundStats.funds) {
        // const { positions } = fundStats;
        // const ALL_FUNDS_TICKER = uniqValues(positions, 'symbol');

        let enhanceReplace = enhancer.enhanceReplace;
        enhanceReplace = enhanceReplace.filter(e => e.query != '').filter((k) => k.ticker !== k.replace); // selected EVALUATE & Selected FUNDS; //added for not displaying funds with no recomendations
        enhanceReplace.forEach((e, i) => {
          let itemResultSet = enhancer.fundPortfolios.filter(el => el.ticker == e.ticker)[0];
          if (!this.props.hideGenerateProposalBtn && enhanceReplace[i].selectedPortfolioFunds && itemResultSet && itemResultSet.target_card && itemResultSet.target_card.funds[0]) {
            enhanceReplace[i].selectedPortfolioFunds.target_card = itemResultSet.target_card.funds[0];
            const selectedPortfolioTicker = enhanceReplace[i].selectedPortfolioFunds.ticker;
            enhanceReplace[i].selectedPortfolioFunds._frs = itemResultSet.result.funds.find(item => item.ticker === selectedPortfolioTicker)._frs;
          }
        });
        let newModelEnhancement = {
          ...modelEnhancement,
          enhanced: {
            ...modelEnhancement.enhanced,
            portfolioName: modelEnhancement.enhanced.data.custom_replace_pf_name
          }
        }
        // console.log(enhanceReplace);
        const obj = {
          investment: {
            portfolioValue: enhancer.portfolioValue
          },
          portfolio: {
            //mp query toggle
            isModelPortfolioQuery: true,
            query: enhancer.query,
            query_english: enhancer.query_english,
            enhanceReplace: enhanceReplace,
            fundSelector: fundSelector.myScoreAttrs,
            enhancedPerformanceImpact: enhancer.performanceImpact.enhancedPerformanceImpact, // enhancer.performanceImpact.currentPerformanceImpact,
            currentPerformanceImpact: enhancer.performanceImpact.currentPerformanceImpact, // enhancer.finalizeData.finalizePerformanceImpact.currentPerformanceImpact,
            selectedBenchmark: enhancer.performanceImpact.selectedBenchmark,
            logo,
            disclosures,
            modelEnhancement: modelEnhancement.enhanced.data.custom_replace_type === 'user' ? newModelEnhancement : modelEnhancement,
            fundUniverse,
            modelUniverse: modelUniverse === 'mp' ? 'Sponsor Models' : 'My models',
          },
          // NOTE: '--- SOME-TIME it crash for missing "modelEnhancement" ---'
          isModelPortfolioQuery: true,
          query: enhancer.query,
          query_english: enhancer.query_english,
          enhanceReplace: enhanceReplace,
          fundSelector: fundSelector.myScoreAttrs,
          enhancedPerformanceImpact: enhancer.performanceImpact.enhancedPerformanceImpact, // enhancer.performanceImpact.currentPerformanceImpact,
          currentPerformanceImpact: enhancer.performanceImpact.currentPerformanceImpact, // enhancer.finalizeData.finalizePerformanceImpact.currentPerformanceImpact,
          selectedBenchmark: enhancer.performanceImpact.selectedBenchmark,
          logo,
          disclosures,
          modelEnhancement: modelEnhancement.enhanced.data.custom_replace_type === 'user' ? newModelEnhancement : modelEnhancement,
          fundUniverse,
          modelUniverse: modelUniverse === 'mp' ? 'Sponsor Models' : 'My models',
          // ------------------------------------------------------------------------------------------
          searchTypeMode,
        };
        // console.log(obj)
        // obj.portfolio.currentPerformanceImpact.averageCostAdjRet10Yr /= 100;
        // obj.portfolio.enhancedPerformanceImpact.averageCostAdjRet10Yr /= 100;
        // console.log('Comparison Report', obj)
        downloadEnhancerReport(obj);
      }
    }
  }

  handleUpDownDropDownChange = (value) => {
    const { setEnhancerState, performanceImpact } = this.props;
    const { enhancedPerformanceImpact, currentPerformanceImpact, upDownDataSet } = performanceImpact;
    if (setEnhancerState) {
      let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(upDownDataSet, value)
      // console.log(enhancedPerformanceImpact)
      if (Object.keys(enhancedPerformanceImpact).length > 0) {
        let { pfRet1y, pfRet3y, pfRet5y } = enhancedPerformanceImpact
        let { pfRet1y: pfRet1yC, pfRet3y: pfRet3yC, pfRet5y: pfRet5yC } = currentPerformanceImpact
        let { up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr } = geopMeanCalc(portfolioBenchmark, pfRet1y, pfRet3y, pfRet5y)
        let { up_1yr: up_1yrC, down_1yr: down_1yrC, up_3yr: up_3yrC, down_3yr: down_3yrC, up_5yr: up_5yrC, down_5yr: down_5yrC } = geopMeanCalc(portfolioBenchmark, pfRet1yC, pfRet3yC, pfRet5yC)
        let updateEnhanceStats = { ...enhancedPerformanceImpact, up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr }
        let updatedCurrentStats = { ...currentPerformanceImpact, up_1yr: up_1yrC, down_1yr: down_1yrC, up_3yr: up_3yrC, down_3yr: down_3yrC, up_5yr: up_5yrC, down_5yr: down_5yrC }
        setEnhancerState({
          performanceImpact: {
            ...performanceImpact,
            selectedBenchmark: value,
            currentPerformanceImpact: updatedCurrentStats,
            enhancedPerformanceImpact: updateEnhanceStats,
          }
        });
      } else {
        let { pfRet1y, pfRet3y, pfRet5y } = currentPerformanceImpact
        let { up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr } = geopMeanCalc(portfolioBenchmark, pfRet1y, pfRet3y, pfRet5y)
        let updatedCurrentStats = { ...currentPerformanceImpact, up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr }
        setEnhancerState({
          performanceImpact: {
            ...performanceImpact,
            selectedBenchmark: value,
            currentPerformanceImpact: updatedCurrentStats
          }
        });
      }
    }
  }

  onCloseTradeList = () => {
    this.setState({
      generateTradeList: !this.state.generateTradeList,
    }, () => {
      setTimeout(() => {
        this.setState({
          alert: !this.state.alert,
        });
      }, 2000)
    })
  }

  onDownloadTradeComplete = () => {
    // const { setEnhancerState } = this.props
    this.setState({
      alert: !this.state.alert,
    }, () => {
      // setEnhancerState({ enhanceReplace: [], enhanceTickerFilter: [], resultSet: [], filterResultSet: [] })
    })
  }

  DirectCloseTrade = () => {
    this.setState({
      generateTradeList: !this.state.generateTradeList,
    })
  }

  setFileName = (e) => {
    const name = document.getElementById('save-file-name-box').value;
    this.setState({ fileName: name })
  }

  savePortfolioEnhance = () => {
    try {
      // console.log(' -- Enhancer Save -- ', this.props.query_uuid);
      addEventToAnalytics('Enhancer Save', 'Enhancer Save', 'ENHANCER_SAVE', {}, false);
    } catch (e) {
      // console.log(e);
    }

    const { savePortfolioRequest, finalizeData, onboardData, history, redirectToPath, redirectFrom, setEnhancerState, setBuildPortfolioState, resetFinalizeDataRequest } = this.props;

    try {
      if (finalizeData &&
        finalizeData.portfolioName &&
        finalizeData.summary &&
        finalizeData.onboardData &&
        finalizeData.enhancedPortfolioId &&
        finalizeData.onboardData.selectedPortfolioType &&
        typeof finalizeData.summary !== 'undefined' &&
        typeof finalizeData.summary.retData !== 'undefined' &&
        finalizeData.summary.retData.length > 0 &&
        typeof finalizeData.summary.portfolioFunds !== 'undefined' &&
        finalizeData.summary.portfolioFunds.length > 0) {

        const enhancedPortfolioId = finalizeData.enhancedPortfolioId || undefined;
        const portfolioType = finalizeData.onboardData.selectedPortfolioType || onboardData.selectedPortfolioType || 'My Portfolio';

        if (this.state.fileName == '') {
          this.setState({ fileNameError: true })
        } else {
          if (savePortfolioRequest && resetFinalizeDataRequest) {
            savePortfolioRequest({
              portfolio_id: enhancedPortfolioId,
              portfolio_name: this.state.fileName,
              portfolioType,
            }, (response) => {
              console.log('-- saved! --');
              this.setState({ fileName: '', fileNameError: false }, () => {
                // console.log(redirectFrom);
                if (redirectFrom && Object.keys(redirectFrom).length > 0 && typeof redirectFrom.payload !== 'undefined') {
                  let { payload, payload: { clientDetails } } = redirectFrom;
                  let updatedPortfolio = {
                    ...redirectFrom,
                    payload: {
                      ...payload,
                      clientDetails: {
                        ...clientDetails,
                        custom_portfolio: {
                          portfolio_name: response.portfolioId,
                        },
                        model_portfolio: {},
                        selected_portfolio: response.portfolioId,
                      }
                    },
                    portfolioName: response.portfolioId,
                    returnPortfolioID: enhancedPortfolioId
                  }
                  // setEnhancerState({
                  //   redirectFrom: updatedPortfolio,
                  //   searchTypeMode: 'FUNDS', // NOTE: NEED TO SET DEFAULT
                  //   finalizeData: {},
                  // });

                  // NOTE: ALL STATE WILL BE RESET TO `initialState` except the state which is passed.
                  // NOTE: PASS STATE TO ASSIGN STATE AFTER RESET
                  resetFinalizeDataRequest({
                    redirectFrom: updatedPortfolio,
                    searchTypeMode: 'FUNDS', // NOTE: NEED TO SET DEFAULT
                    finalizeData: {},
                  });
                  if (response && history) {
                    let urlParam = [];
                    for (var i in response) {
                      urlParam.push(encodeURIComponent(i) + "=" + encodeURIComponent(response[i]));
                    }
                    // redirect to client page
                    if (redirectToPath && redirectToPath !== '') {
                      history.push(redirectFrom.link);
                    }
                  }
                } else {
                  // NOTE: ALL STATE WILL BE RESET TO `initialState` except the state which is passed.
                  // NOTE: PASS STATE TO ASSIGN STATE AFTER RESET
                  resetFinalizeDataRequest({
                    // NOTE: after saving portfolio, portfolioId is missing in enhancer state
                    portfolioId: enhancedPortfolioId,
                  });

                  if (response && history) {
                    let urlParam = [];
                    for (var i in response) {
                      urlParam.push(encodeURIComponent(i) + "=" + encodeURIComponent(response[i]));
                    }
                    const newPath = `/enhancer/dashboard/summary?${urlParam.join("&")}`;
                    history.push(newPath);
                  }
                }
              })
            });
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  useSuggestedFunds = (props) => {
    const { useSuggestedFundsRequest, getEnhanceStatsForSelectedTickerRequest, enhancer } = this.props;
    if (useSuggestedFundsRequest && props) {
      useSuggestedFundsRequest(props);
    }

    const {
      query,
      // summary,
      enhanceReplace,
      performanceImpact,
      // enhanceTickerFilter,
    } = enhancer;

    if (performanceImpact && enhanceReplace) {
      let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(performanceImpact.upDownDataSet, performanceImpact.selectedBenchmark);
      let updatedArray = enhanceReplace.map((item) => {
        return item.selectedPortfolioFunds
      });

      if (portfolioBenchmark && updatedArray) {
        const STATS = getCalculatedEnhanceStats(updatedArray, query, portfolioBenchmark);
        console.log('========== PLOTTING PERFORMANCE IMPACT DATA ==========');
        let enhancedRequestData = updatedArray.map((item) => {
          return {
            ticker: item.ticker,
            weight: item.weight,
          };
        });

        // let __portfolioFunds = (summary && summary.portfolioFunds) ? summary.portfolioFunds : [];
        // console.log('__portfolioFunds --> ', __portfolioFunds);
        // if (enhanceTickerFilter && enhancedRequestData.length !== __portfolioFunds.length) {
        //   __portfolioFunds.forEach((item, i) => {
        //     if (!enhanceTickerFilter.includes(item.ticker)) {
        //       console.log(' --  found missing ticker --> ', item.ticker, item.weight);
        //       enhancedRequestData = [
        //         ...enhancedRequestData, {
        //           ticker: item.ticker,
        //           weight: item.weight,
        //         }
        //       ];
        //     }
        //   });
        // }
        // console.log('enhancedRequestData --> ', enhancedRequestData);

        // console.log(enhancedRequestData);
        if (enhancedRequestData &&
          enhancedRequestData.length > 0 &&
          portfolioBenchmark &&
          STATS &&
          getEnhanceStatsForSelectedTickerRequest
        ) {
          getEnhanceStatsForSelectedTickerRequest({
            portfolioBenchmark,
            enhancedRequestData,
            ui_enhancedStats: STATS,
          }, (res) => {
            // console.log('------- getEnhanceStatsForSelectedTickerRequest res -------');
            // console.log(res);
            // console.log('-----------------------------------------------------------');
            const { resetTotumScore } = this.props;
            if (resetTotumScore) {
              resetTotumScore();
            }

            setTimeout(() => {
              this.getTotumScore();
            }, 500);
          });
        }
      } else {
        console.log('-- Failed to calculate portfolio benchmark data after deleting specify custom funds --');
      }
    } else {
      console.log('-- Failed to calculate after deleting specify custom funds, missing performanceImpact --');
    }
  }

  getTotumScore = () => {
    const { getCurrentPortfolioScoreRequest, getEnhancedPortfolioScoreRequest, portfolioFunds, enhanceReplace, totumScore, tickerNotSupported, usingSuggestedFunds } = this.props;
    const { scoreMeter } = totumScore;
    let orderedScoreMeter = _.orderBy(scoreMeter.filter((item) => item.value !== null), ['value'], ['asc']);
    let scores = orderedScoreMeter.map((item) => item.value);
    if (getCurrentPortfolioScoreRequest && getEnhancedPortfolioScoreRequest && portfolioFunds && enhanceReplace) {
      // NOTE: check for 'current' score
      if (scores.length === 0) {
        // call request for 'current' score
        let allData = [...portfolioFunds, ...tickerNotSupported];
        const requestData = allData.map((item) => {
          return {
            ticker: item.ticker,
            weight: item.weight,
          };
        });
        if (requestData && requestData.length > 0) {
          getCurrentPortfolioScoreRequest({
            requestData,
            For: 'current',
          });
        }
      }
      // NOTE: get for 'enhanced' score
      let enhancedSelectedFunds = enhanceReplace.map((item) => item.selectedPortfolioFunds) || [];
      if (enhancedSelectedFunds && enhancedSelectedFunds.length > 0 && usingSuggestedFunds) {
        let enhanceAllData = [...enhancedSelectedFunds, ...tickerNotSupported];
        let requestData = enhanceAllData.map((k) => {
          return {
            ticker: k.ticker,
            weight: k.weight,
          }
        }) || [];
        if (requestData && requestData.length > 0) {
          getEnhancedPortfolioScoreRequest({
            requestData,
            For: 'enhanced',
          });
        }
      } else {
        console.log('--- NO ENHANCED REPLACEMENT TICKER FOUND FOR SCORE ---');
      }
    }
  }

  render() {
    const {
      loading,
      enhancer,
      summary,
      profile,
      modelPortfolios,
      query,
      query_uuid,
      selectedWeightOptions,
      queryEnglish,
      searchTypeMode,
      performanceImpact,
      enhanceTickerFilter,
      enhanceReplace,
      finalizeData,
      savingPortfolioError,
      // fundPortfolios,
      savingPortfolioErrorText,
      // guidanceMessage,
      // pdfReportLoader,
      pdfReportDownloadStatus,
      currentPortlioLoading,
      resettingFinalizeData,
      setEnhancerState
    } = this.props;

    const { summaryDrawer, fileNameError, saveEnhancePortfolioModal } = this.state;
    const { replacePortfolioOptions, replacePortfolioWith, myCurrentPortfolio } = modelPortfolios;
    const { portfolioFunds } = summary;

    let { currentPerformanceImpact, enhancedPerformanceImpact, upDownDataSet, selectedBenchmark } = performanceImpact;
    const renderTextQuery = (query === 'user_criteria') ? (`Best Alternatives Using ${selectedWeightOptions && selectedWeightOptions.name}`) : (queryEnglish || query);

    let _enhancedPerformanceImpact = enhancedPerformanceImpact || {};
    let _enhanceReplace = enhanceReplace || [];
    if (finalizeData &&
      finalizeData.portfolioName &&
      finalizeData.summary &&
      typeof finalizeData.summary !== 'undefined' &&
      typeof finalizeData.summary.retData !== 'undefined' &&
      finalizeData.summary.retData.length > 0 &&
      typeof finalizeData.summary.portfolioFunds !== 'undefined' &&
      finalizeData.summary.portfolioFunds.length > 0) {
      _enhancedPerformanceImpact = {} // hack
      _enhanceReplace = []
      currentPerformanceImpact = enhancedPerformanceImpact;
    }

    if (enhanceReplace.filter((item) => item.ticker !== item.replaceTicker).length === 0) {
      _enhancedPerformanceImpact = {} // hack
      _enhanceReplace = []
    }

    let isItRenderingSummaryPageContent = (finalizeData &&
      finalizeData.portfolioName &&
      finalizeData.summary &&
      typeof finalizeData.summary !== 'undefined' &&
      typeof finalizeData.summary.retData !== 'undefined' &&
      finalizeData.summary.retData.length > 0 &&
      typeof finalizeData.summary.portfolioFunds !== 'undefined' &&
      finalizeData.summary.portfolioFunds.length > 0) || false;

    // let weAreCalculatingData = (replacePortfolioOptions &&
    //   typeof replacePortfolioOptions.last_updated !== 'undefined' &&
    //   (replacePortfolioOptions.last_updated === null ||
    //     moment.duration(moment(moment.tz(moment.utc(moment()).format(), "utc").format()).diff(moment(moment.tz(replacePortfolioOptions.last_updated, "utc").format()))).asHours() > 24
    //   )
    // );

    // if (replacePortfolioOptions && typeof replacePortfolioOptions.last_updated !== 'undefined') {
    //   console.log('replacePortfolioOptions.last_updated --> ', replacePortfolioOptions.last_updated);
    //   console.log('isPortfolioDataOld --> ', isPortfolioDataOld(replacePortfolioOptions.last_updated));
    // }

    let weAreCalculatingData = (replacePortfolioOptions &&
      typeof replacePortfolioOptions.last_updated !== 'undefined' &&
      (replacePortfolioOptions.last_updated === null || isPortfolioDataOld(replacePortfolioOptions.last_updated))
    );

    // let renderMessage = weAreCalculatingData ? (<><span>Data is currently being calculated</span></>) : (<><span>No portfolios</span> were found for <span>{renderTextQuery}</span></>)
    // console.log(myCurrentPortfolio);
    // console.log(myCurrentPortfolio && myCurrentPortfolio.targetCard && !_.isEmpty(myCurrentPortfolio.targetCard) ? myCurrentPortfolio.targetCard : 'empty')
    return (
      <React.Fragment>
        {(this.state.generateTradeList) && (
          <GenerateTradeList onCloseClick={this.onCloseTradeList} onDownload={this.onDownloadTradeComplete} OnDirectClose={this.DirectCloseTrade} />
        )}
        {(this.state.alert) && (
          <CustomAlert className="alert-windown-wrapper" color='info'>
            Tradelist Download Successfully
          </CustomAlert>
        )}
        {(pdfReportDownloadStatus && pdfReportDownloadStatus.message !== '' && pdfReportDownloadStatus.downloadStatus !== '') && (
          <CustomAlert className="alert-windown-wrapper" color={pdfReportDownloadStatus.downloadStatus} onDismissCallback={() => setEnhancerState({
            pdfReportDownloadStatus: {
              message: '',
              downloadStatus: ''
            }
          })}>
            {pdfReportDownloadStatus.message}
          </CustomAlert>
        )}
        {(this.state.nullLastUpdateLoading || loading || currentPortlioLoading || weAreCalculatingData) && (
          <div className="enhancer-model-portfolio-container">
            <Card className="card-background-grey empty-message-wrapper no-funds-wrapper">
              <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5" style={{ fontSize: '2em' }}></i>
              <div className="text-box">
                <span>Data is currently being calculated</span>
              </div>
            </Card>
          </div>
        )}
        {(!loading && !this.state.nullLastUpdateLoading && !currentPortlioLoading && !weAreCalculatingData) && (
          <div className="enhancer-model-portfolio-container">
            <ContentHead
              {...this.props}
              summaryDrawer={summaryDrawer}
              readableQuery={queryEnglish}
              resettingFinalizeData={resettingFinalizeData}
              isItRenderingSummaryPageContent={isItRenderingSummaryPageContent}
              openDrawer={this.openDrawer}
              finalizeClick={this.finalizeClick}
              generateProposalClick={this.generateProposalClick}
              savePortfolioClick={this.savePortfolioClick}
              generateTradeListClick={this.generateTradeListClick}
              beforeFinalizeGenerateProposal={() => this.downloadEnhancerPDF(true)}
            />
            {(finalizeData &&
              finalizeData.portfolioName &&
              finalizeData.summary &&
              typeof finalizeData.summary !== 'undefined' &&
              typeof finalizeData.summary.retData !== 'undefined' &&
              finalizeData.summary.retData.length > 0 &&
              typeof finalizeData.summary.portfolioFunds !== 'undefined' &&
              finalizeData.summary.portfolioFunds.length > 0) ? (
              <SummaryBoard
                {...this.props}
                afterFinalize
                portfolioName={finalizeData.portfolioName}
                summary={finalizeData.summary}
                retData={finalizeData.summary.retData}
                activeFund={finalizeData.summary.activeFund}
                onboardData={finalizeData.onboardData}
                funds={finalizeData.summary.portfolioFunds}
                fundStats={getFinalizeSummaryFunds(enhancer)}
              />
            ) : myCurrentPortfolio && myCurrentPortfolio.targetCard && !_.isEmpty(myCurrentPortfolio.targetCard) ? (
              <div className="custom-scroll pb-3 pt-2" style={{ marginRight: 0, paddingRight: 8, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                <CardRow
                  {...this.props}
                  portfolioFunds={portfolioFunds}
                  currentPerformanceImpact={currentPerformanceImpact}
                  profile={profile}
                  modelPortfolios={modelPortfolios}
                  readableQuery={queryEnglish}
                  portfolioQuery={query}
                  replacePortfolioWith={replacePortfolioWith}
                  replaceCurrentModelPortfolioWithRequest={this.props.replaceCurrentModelPortfolioWithRequest}
                  setEnhancerState={this.props.setEnhancerState}
                />
                <InnerPageFooter style={{ width: "100%", borderTop: "1px solid #d5cbcb", textAlign: "center", fontFamily: "Open Sans", fontSize: "12px", padding: ".4em", background: "#f7f7f7" }} />
              </div>
            ) : (
              <Card className="card-background-grey empty-message-wrapper no-funds-wrapper">
                <img src='/assets/images/icons/NoFundsFound.svg' alt='' />
                <div className="text-box">
                  {(searchTypeMode === 'MODELS' && (query === 'user_criteria' || query === 'outperform')) ? (
                    <span>The query may be applicable only for fund enhancements.</span>
                  ) : <><span>No portfolios</span> were found for <span>{renderTextQuery}</span></>}
                </div>
              </Card>
            )}
            {(typeof currentPerformanceImpact !== 'undefined' &&
              typeof currentPerformanceImpact.pfRet1y !== 'undefined' &&
              typeof enhancedPerformanceImpact !== 'undefined') && (
                <PortfolioModal
                  closable
                  mask={false}
                  enhancePortfolioVisible={false}
                  replacementToggleEnable={false}
                  summaryPage={isItRenderingSummaryPageContent}
                  query={query}
                  query_uuid={query_uuid}
                  visible={summaryDrawer}
                  portfolioEnhanceReplace={_enhanceReplace}
                  enhanceStats={_enhancedPerformanceImpact}
                  currentStats={currentPerformanceImpact}
                  enhanceTickerFilter={enhanceTickerFilter}
                  portfolioBenchmarkData={upDownDataSet}
                  selectedPortfolioBenchmarkTicker={selectedBenchmark}
                  openDrawer={this.openDrawer}
                  handleUpDownDropDownChange={this.handleUpDownDropDownChange}
                  toggleChange={this.toggleChange}
                  setPfEnhancement={this.setPfEnhancement}
                  evaluateOnChange={this.evaluateOnChange}
                  setPortfolioState={this.props.setPortfolioState}
                  replacePortfolioToggle={this.props.replacePortfolioToggle}
                  useSuggestedFundsRequest={this.useSuggestedFunds}
                  originalQueryVar={replacePortfolioOptions && replacePortfolioOptions.query_var}
                />
              )}
            {(saveEnhancePortfolioModal) && (
              <Modal
                isOpen={saveEnhancePortfolioModal}
                toggle={this.toggleSavePortfolioModal}
                centered
                keyboard={true}
                backdrop
                className="premium-msg-modal account-modal save-portfolio-modal"
                backdropClassName="premium-feature-process-modal-backdrop"
              >
                <ModalBody className="ssf-modal__body">
                  <div className="premium-feature-process-modal-container">
                    <div className="header-wrapper">
                      <h1 className="text-align-left">Save Your Enhanced Portfolio</h1>
                    </div>
                    <i className="fal fa-times-circle premium-feature-completed-close" onClick={this.closeSavePortfolioModal}></i>
                    <p className="content-wrapper">
                      <form className="upload-form-content">
                        <FormGroup className="element-box pos-rel">
                          <label>Name</label>
                          <input
                            id="save-file-name-box"
                            className="input-area"
                            type="text"
                            onChange={this.setFileName}
                            autoComplete="off"
                          />
                          {fileNameError && <span className="upload-error">Please enter a Name to save</span>}
                          {savingPortfolioError && <span className="upload-error">{savingPortfolioErrorText !== '' ? savingPortfolioErrorText : 'Error on file save'}</span>}
                        </FormGroup>
                        <div className="col-12 cta-wrapper ">
                          <button type="button" id="save-prtf" className="ssf-btn-primary btn secondary-btn btn btn-secondary" onClick={this.savePortfolioEnhance}>
                            <span>SAVE</span>
                          </button>
                        </div>
                      </form>
                    </p>
                  </div>
                </ModalBody>
              </Modal>
            )}
          </div>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ loading, enhancer, profile, weights }) => ({
  enhancer,
  summary: enhancer.summary,
  query: enhancer.query,
  query_uuid: enhancer.query_uuid,
  profile: profile.user,
  queryEnglish: enhancer.query_english,
  fundPortfolios: enhancer.fundPortfolios || [],
  performanceImpact: enhancer.performanceImpact,
  portfolioName: enhancer.portfolioName,
  modelPortfolios: enhancer.modelPortfolios,
  searchTypeMode: enhancer.searchTypeMode,
  onboardData: enhancer.onboardData,
  guidanceMessage: enhancer.guidanceMessage,
  enhanceTickerFilter: enhancer.enhanceTickerFilter || [],
  enhanceReplace: enhancer.enhanceReplace,
  savingPortfolioError: enhancer.savingPortfolioError || false,
  savingPortfolioErrorText: enhancer.savingPortfolioErrorText || '',
  selectedWeightOptions: enhancer.selectedWeightOptions, // portfolio.selectedWeightOptions,
  // portfolio,
  modelEnhancement: enhancer.modelEnhancement,
  pdfReportLoader: enhancer.pdfReportLoader,
  pdfReportDownloadStatus: enhancer.pdfReportDownloadStatus,
  finalizeData: enhancer.finalizeData || {},
  redirectToPath: enhancer.redirect || '',
  redirectFrom: enhancer.redirectFrom || {},
  customLogo : profile.profile.custom_report_logo,
  _disclosures : profile.profile.disclosures,
  advisorFees: profile.profile.advisory_fees,
  totumScore: enhancer.totumScore,
  portfolioType: enhancer.portfolioType,
  advisorId: profile.totum_advisor_id || null,
  totumAccessEmail: profile.email || null,
  portfolioFunds: enhancer.summary.portfolioFunds,
  usingSuggestedFunds: enhancer.usingSuggestedFunds,
  pdfReportDownloadStatus: enhancer.pdfReportDownloadStatus,
  tickerNotSupported: enhancer.summary.tickerNotSupported || [],
  fundSelector: weights,
  loading: createLoadingSelector(['GET_MODEL_PORTFOLIOS_BY_ID'])({ loading }),
  resettingFinalizeData: createLoadingSelector(['CLEAR_FINALIZE_DATA'])({ loading }),
  currentPortlioLoading: createLoadingSelector(['GET_CURRENT_PORTFOLIO_STATS'])({ loading }),
  // performanceStatsLoading: createLoadingSelector(['GET_PORTFOLIO_BY_ID', 'GET_CURRENT_PORTFOLIO_STATS', 'GET_ENHANCED_STATS_FOR_SELECTED_TICKER'])({ loading }),
})

const mapDispatchToProps = {
  getModelPortfoliosByIdRequest,
  setEnhancerState,
  resetSummaryDataRequest,
  replaceCurrentModelPortfolioWithRequest,
  downloadEnhancerReport,
  downloadRecommendationEnhancerReport,
  finalizeModelPortfolioRequest,
  finalizeModelPortfolioSuccess,
  setEnhancedTransaction,
  savePortfolioRequest,
  setBuildPortfolioState,
  resetFinalizeDataRequest,
  useSuggestedFundsRequest,
  getEnhanceStatsForSelectedTickerRequest,
  getCurrentPortfolioScoreRequest,
  getEnhancedPortfolioScoreRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Models);

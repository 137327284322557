import React, { useContext } from 'react';
import { Row, Col, Table } from 'reactstrap';
import { withHeaderFooter } from '../shared/HeaderFooter';
// import { StackedAreaChart } from '../shared/Charts';
import { QuinItems } from 'DataSet';
import { quintileSize, quintileClass } from 'Utils';
// import { ViewChart } from './ViewChart';
// import DynamicGaugeChart from '../shared/DynamicGaugeChart';
import { getImage } from 'Utils';
import { ViewFund, ViewFee, ViewReturn, ViewRisk, TickerWeight } from '../FeeReturnRisk';
import PortfolioContext from '../PortfolioContext'
import moment from 'moment';
import style from '../../EnhancerInterimReport/RecommendationReport/ItemCard.module.scss'
import { checkValidNumber, getFRRAttrData } from 'layouts/utils';
import { namespace } from 'd3';
// import AnalyticsPopover from './AnalyticsPopover';
// import ReturnPopover from './ReturnPopover';
// import RiskPopover from './RiskPopover';

const RecommendedEnhancements = withHeaderFooter((props) => {
  const { data: items, query, queryEnglish: qE, index, finalArray } = props;
  const reportData = useContext(PortfolioContext);
  let { 
    portfolio: { 
      enhancedPerformanceImpact: { 
        pfRet3y: Returns3Yr 
      },
      replacementNotChosen: noReplacementChosen = [],
      replacementNotFound: noReplacementFound = []
    }
  } = reportData;
  let startDate = Returns3Yr && (Returns3Yr.length > 0 && Returns3Yr[0]) ? (Returns3Yr[0].d) : null;
  let endDate = Returns3Yr && (Returns3Yr.length > 0 && Returns3Yr[Returns3Yr.length - 1]) ? (Returns3Yr[Returns3Yr.length - 1].d) : null;
  let dateRange = startDate && endDate ? `${moment(startDate).format('MMM YYYY')} - ${moment(endDate).format('MMM YYYY')}` : null;
  let textWithOnlyTicker = null, textWithOnlyTickerTNS = null;
  if (reportData.invalidTickerData !== undefined && typeof reportData.invalidTickerData !== 'undefined' && reportData.invalidTickerData !== 'undefined' && reportData.invalidTickerData && reportData.invalidTickerData.textWithOnlyTicker && typeof reportData.invalidTickerData.textWithOnlyTicker !== 'undefined') {
    textWithOnlyTicker = reportData.invalidTickerData.textWithOnlyTicker
  }
  if (reportData.tickerNotSupported !== undefined && typeof reportData.tickerNotSupported !== 'undefined' && reportData.tickerNotSupported !== 'undefined' && reportData.tickerNotSupported && reportData.tickerNotSupported.textWithOnlyTicker && typeof reportData.tickerNotSupported.textWithOnlyTicker !== 'undefined') {
    textWithOnlyTickerTNS = reportData.tickerNotSupported.textWithOnlyTicker
  }

  const RenderElement = items
    .filter((item) => item.selectedPortfolioFunds && item.selectedPortfolioFunds.target_card)
    .map((item, i) => {
      let {
        selectedPortfolioFunds: enhanced,
        selectedPortfolioFunds: {
          target_card: current,
        },
      } = item;

      let CurrentFeesFromFRR = current.FRR ? current.FRR.find(k => k.name === 'Fees') || current.chart.find(k => k.name === 'Fees') || null : null;
      let CurrentRiskFromFRR = current.FRR ? current.FRR.find(k => k.name === 'Risk') || current.chart.find(k => k.name === 'Risk') || null : null;
      let CurrentReturnsFromFRR = current.FRR ? current.FRR.find(k => k.name === 'Returns') || current.chart.find(k => k.name === 'Returns') || null : null;

      let EnhancedFeesFromFRR = enhanced.FRR ? enhanced.FRR.find(k => k.name === 'Fees') || enhanced.chart.find(k => k.name === 'Fees') || null : null;
      let EnhancedRiskFromFRR = enhanced.FRR ? enhanced.FRR.find(k => k.name === 'Risk') || enhanced.chart.find(k => k.name === 'Risk') || null : null;
      let EnhancedReturnsFromFRR = enhanced.FRR ? enhanced.FRR.find(k => k.name === 'Returns') || enhanced.chart.find(k => k.name === 'Returns') || null : null;

      let currentFeesValue =
        CurrentFeesFromFRR && typeof CurrentFeesFromFRR.value !== 'undefined'
        ? checkValidNumber(parseFloat(CurrentFeesFromFRR.value || 0) * (CurrentFeesFromFRR.multiplier || 1), CurrentFeesFromFRR.decimal || 2, CurrentFeesFromFRR.suffix || '%', '--')
        : '--';
      let enhancedFeesValue =
        EnhancedFeesFromFRR && typeof EnhancedFeesFromFRR.value !== 'undefined'
        ? checkValidNumber(parseFloat(EnhancedFeesFromFRR.value || 0) * (EnhancedFeesFromFRR.multiplier || 1), EnhancedFeesFromFRR.decimal || 2, EnhancedFeesFromFRR.suffix || '%', '--')
        : '--';
      let currentReturnValue =
        CurrentReturnsFromFRR && typeof CurrentReturnsFromFRR.value !== 'undefined'
        ? checkValidNumber(parseFloat(CurrentReturnsFromFRR.value || 0) * (CurrentReturnsFromFRR.multiplier || 1), CurrentReturnsFromFRR.decimal || 2, CurrentReturnsFromFRR.suffix || '%', '--')
        : '--'; // current.Fee_Risk_Return['Annualized 3 year Average'] !== 'undefined' ? current.Fee_Risk_Return['Annualized 3 year Average'].toFixed(2)+'%';
      let enhancedReturnValue = 
        EnhancedReturnsFromFRR && typeof EnhancedReturnsFromFRR.value !== 'undefined'
        ? checkValidNumber(parseFloat(EnhancedReturnsFromFRR.value || 0) * (EnhancedReturnsFromFRR.multiplier || 1), EnhancedReturnsFromFRR.decimal || 2, EnhancedReturnsFromFRR.suffix || '%', '--')
        : '--';//  enhanced.Fee_Risk_Return['Annualized 3 year Average'].toFixed(2)+'%';
      let currentRiskValue = 
        CurrentRiskFromFRR && typeof CurrentRiskFromFRR.value !== 'undefined'
        ? checkValidNumber(parseFloat(CurrentRiskFromFRR.value || 0) * (CurrentRiskFromFRR.multiplier || 1), CurrentRiskFromFRR.decimal || 2, CurrentRiskFromFRR.suffix || '%', '--')
        : '--'; // current.Fee_Risk_Return['Annualized 3 year Volatility'].toFixed(2)+'%';
      let enhancedRiskValue = 
        EnhancedRiskFromFRR && typeof EnhancedRiskFromFRR.value !== 'undefined'
        ? checkValidNumber(parseFloat(EnhancedRiskFromFRR.value || 0) * (EnhancedRiskFromFRR.multiplier || 1), EnhancedRiskFromFRR.decimal || 2, EnhancedRiskFromFRR.suffix || '%', '--')
        : '--'; // enhanced.Fee_Risk_Return['Annualized 3 year Volatility'].toFixed(2)+'%';
      
      let isExistingQuery = true;
      let showQueryEnComp = '', showQueryCrComp = '', showQueryHeader = '', showHeaderIcon = '';
      let header1, header2 = (
        <span>
          <span className="d-flex justify-content-center align-items-center header-icon-title">
            <i className="far fa-fw fassf fa-chart-line-1" /><span>Ann. <br /> Returns</span>
          </span>
          <span style={{ fontSize: 12, fontWeight: 600 }}>{dateRange}</span>
        </span>
      );
      let header3 = (
        <span>
          <span className="d-flex justify-content-center align-items-center header-icon-title">
            <img src="/assets/images/icons/dark-blue-heart-rate-1.svg" width="20" alt="Risk" style={{ marginBottom: '3px', marginRight: '5px' }} />Risk<br />
          </span>
          <span style={{ fontSize: 12, fontWeight: 600 }}>{dateRange}</span>
        </span>
      );
      
      if (
        enhanced &&
        enhanced.FRR &&
        enhanced.FRR.length &&
        current &&
        current.FRR &&
        current.FRR.length
      ) {
        header1 = <AttrHeader {...EnhancedFeesFromFRR} />;
        header2 = <AttrHeader {...EnhancedReturnsFromFRR} des={dateRange} />;
        header3 = <AttrHeader {...EnhancedRiskFromFRR} des={dateRange} />;
        showQueryCrComp = <AttrContent {...CurrentFeesFromFRR} />
        showQueryEnComp = <AttrContent {...EnhancedFeesFromFRR} />
        isExistingQuery = false;
      } else {
        // showQueryCrComp = <ViewFee fee={typeof CurrentFeesFromFRR.value !== 'undefined' ? formatValue(CurrentFeesFromFRR.value, 2, '%') : '-'} qE={qE} />
        // showQueryEnComp = <ViewFee fee={typeof EnhancedFeesFromFRR.value !== 'undefined' ? formatValue(EnhancedFeesFromFRR.value, 2, '%') : '-'} qE={qE} />
        // showQueryHeader = 'Fee'
        showQueryCrComp = <ViewFee fee={currentFeesValue} qE={qE} />
        showQueryEnComp = <ViewFee fee={enhancedFeesValue} qE={qE} />
        header1 = <span>{showHeaderIcon} {'Fee'}</span>;
        showHeaderIcon = <i className="far fa-fw fassf fa-tags-1" />
      }

      // let renderText = 'Overlap', chartValue = enhanced['overlap_' + (current.ticker)];
      // let currentReturnValue = checkValidNumber(current.Fee_Risk_Return['Annualized 3 year Average'], 2, '%', '--'); // current.Fee_Risk_Return['Annualized 3 year Average'] !== 'undefined' ? current.Fee_Risk_Return['Annualized 3 year Average'].toFixed(2)+'%';
      // let enhancedReturnValue = checkValidNumber(enhanced.Fee_Risk_Return['Annualized 3 year Average'], 2, '%', '--'); //  enhanced.Fee_Risk_Return['Annualized 3 year Average'].toFixed(2)+'%';
      // let currentRiskValue = checkValidNumber(current.Fee_Risk_Return['Annualized 3 year Volatility'], 2, '%', '--'); // current.Fee_Risk_Return['Annualized 3 year Volatility'].toFixed(2)+'%';
      // let enhancedRiskValue = checkValidNumber(enhanced.Fee_Risk_Return['Annualized 3 year Volatility'], 2, '%', '--'); // enhanced.Fee_Risk_Return['Annualized 3 year Volatility'].toFixed(2)+'%';
      
      // if (query === "safe_alternative") {
      //   currentRiskValue = current['attribute/technical/risk'] ? (current['attribute/technical/risk'] * 100).toFixed(2) + '%' : current.Fee_Risk_Return['Annualized 3 year Volatility'].toFixed(2) + '%';
      //   enhancedRiskValue = enhanced['attribute/technical/risk'] ? (enhanced['attribute/technical/risk'] * 100).toFixed(2) + '%' : enhanced.Fee_Risk_Return['Annualized 3 year Volatility'].toFixed(2) + '%';
      // }
      
      return (
        <Row key={i}>
          <Col xs="12">
            <Table bordered className={"pr-v2-rec-enh-table " + ((i == 0) && "tbl-first")}>
              <thead>
                <tr className="rec-enh-tbl-hdr">
                  <th xs="1"><span>{((i + 1) < 10 && (i + 1) > 0) ? '0' + (i + 1) : i + 1}</span></th>
                  <th>{(i == 0) && "Fund"}</th>
                  {/* <th>{(i == 0) && <span>Ticker<br/>and Price</span>}</th> */}
                  <th>{(i == 0) && <span>Ticker &<br />Allocated Weight</span>}</th>
                  <th>{(i == 0) && <>{header1}</>}</th>
                  <th>{(i == 0) && <>{header2}</>}</th>
                  <th>{(i == 0) && <>{header3}</>}</th>
                  {/* <th>{(i == 0) && <span>Similarity</span>}</th> */}
                  {/*<th>{(i == 0) && <span>Holdings Overlap Between <br /> Two Funds</span>}</th>*/}
                </tr>
              </thead>
              <tbody>
                <tr className="rec-enh-align">
                  <td xs="1" className="rec-tt-wrapper lb-border">
                    <span className="rec-transform-text">Current</span>
                  </td>
                  <td>
                    <ViewFund
                      sponsor={current.cardInfo && current.cardInfo.sponsor}
                      fundName={current.cardInfo && current.cardInfo.short_name}
                    />
                  </td>
                  <td className="rec-enh-ticker-name">
                    <TickerWeight
                      ticker={current.cardInfo && current.cardInfo.ticker}
                      weight={current.weight}
                    />
                    {/* <TickerPrice ticker={current.ticker} price={current.weight} /> */}
                  </td>
                  <td>
                    {showQueryCrComp}
                  </td>
                  <td>
                    {isExistingQuery ? (
                      <ViewReturn returns={currentReturnValue} />
                    ) : (
                      <AttrContent {...CurrentReturnsFromFRR} />
                    )}
                  </td>
                  <td>
                    {isExistingQuery ? (
                      <ViewRisk risk={currentRiskValue} qE={qE} />
                    ) : (
                      <AttrContent {...CurrentRiskFromFRR} />
                    )}
                  </td>
                  {/*<td rowSpan="2">*/}
                  {/*  <DynamicGaugeChart width={150} chartFor={renderText} value={chartValue} enTick={enhanced.ticker} crTick={current.ticker} />*/}
                  {/*</td>*/}
                </tr>
                <tr className="rec-enh-align">
                  <td xs="1" className="rec-tt-wrapper blue-bg">
                    <span className="rec-transform-text">Enhanced</span>
                  </td>
                  <td>
                    <ViewFund
                      sponsor={enhanced.cardInfo && enhanced.cardInfo.sponsor}
                      fundName={enhanced.cardInfo && enhanced.cardInfo.short_name}
                    />
                  </td>
                  <td className="rec-enh-ticker-name">
                    <TickerWeight
                      ticker={enhanced.cardInfo && enhanced.cardInfo.ticker}
                      weight={enhanced.weight}
                    />
                    {/* <TickerPrice ticker={enhanced.ticker} price={enhanced.nav} /> */}
                  </td>
                  <td>
                    {showQueryEnComp}
                  </td>
                  <td>
                    {isExistingQuery ? (
                      <ViewReturn returns={enhancedReturnValue} />
                    ) : (
                      <AttrContent {...EnhancedReturnsFromFRR} />
                    )}
                  </td>
                  <td>
                    {isExistingQuery ? (
                      <ViewRisk risk={enhancedRiskValue} qE={qE} />
                    ) : (
                      <AttrContent {...EnhancedRiskFromFRR} />
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      )
    });

  return (
    <React.Fragment>
      <div className="content">
        <div className="pr-v2-header">
          <h3>Appendix</h3>
        </div>
        <div className="pr-v2-rec-enh">
          <h4>Recommended Enhancements</h4>
          {RenderElement}
        </div>
        {(finalArray && finalArray.length > 0 && ((finalArray.length - 1) === index)) && (
          <div>
            {noReplacementChosen.length > 0 && noReplacementFound.length > 0 && (
              <p className={style.noReplaceText}>
                {noReplacementChosen.length === 1 ? 'Fund' : 'Funds'} for which no {
                  noReplacementChosen.length === 1 ? 'replacement' : 'replacements'} {
                  noReplacementChosen.length === 1 ? 'was' : ' were'} selected {
                  noReplacementChosen.length === 1 ? ' is' : 'are'} {
                  noReplacementChosen.map((h, i) => `${
                    h.targetCard && h.targetCard.cardInfo && h.targetCard.cardInfo.short_name
                  } (${h.targetCard && h.targetCard.cardInfo && h.targetCard.cardInfo.ticker})${i < noReplacementChosen.length - 1 ? ', ' : ''}`
                  )} & {
                  noReplacementFound.length === 1 ? 'fund' : 'funds'} for which no {
                  noReplacementFound.length === 1 ? 'replacement' : 'replacements'} {
                  noReplacementFound.length === 1 ? 'was' : ' were'} found {
                  noReplacementFound.length === 1 ? ' is' : ' are'} {
                  noReplacementFound.map((h, i) => `${
                    h.targetCard && h.targetCard.cardInfo && h.targetCard.cardInfo.short_name
                  } (${h.targetCard && h.targetCard.cardInfo && h.targetCard.cardInfo.ticker})${i < noReplacementFound.length - 1 ? ', ' : '.'}`
                )}
              </p>
            )}
            {(noReplacementChosen.length > 0 && noReplacementFound.length === 0) && (
              <p className={style.noReplaceText}>
                {noReplacementChosen.length === 1 ? 'Fund' : 'Funds'} for which no {
                  noReplacementChosen.length === 1 ? 'replacement' : 'replacements'} {
                  noReplacementChosen.length === 1 ? 'was' : 'were'} selected {
                  noReplacementChosen.length === 1 ? ' is' : 'are'} {
                  noReplacementChosen.map((h, i) => `${
                    h.targetCard && h.targetCard.cardInfo && h.targetCard.cardInfo.short_name
                  } (${h.targetCard && h.targetCard.cardInfo && h.targetCard.cardInfo.ticker})${i < noReplacementChosen.length - 1 ? ', ' : '.'}`
                )}
              </p>
            )}
            {(noReplacementFound.length > 0 && noReplacementChosen.length === 0) && (
              <p className={style.noReplaceText}>
                {noReplacementFound.length === 1 ? 'Fund' : 'Funds'} for which no {
                  noReplacementFound.length === 1 ? 'replacement' : 'replacements'} {
                  noReplacementFound.length === 1 ? 'was' : 'were'} found {
                  noReplacementFound.length === 1 ? ' is' : 'are'} {
                  noReplacementFound.map((h, i) => `${
                    h.targetCard && h.targetCard.cardInfo && h.targetCard.cardInfo.short_name
                  } (${h.targetCard && h.targetCard.cardInfo && h.targetCard.cardInfo.ticker})${i < noReplacementFound.length - 1 ? ', ' : '.'}`
                )}
              </p>
            )}
            {(textWithOnlyTicker &&
              typeof textWithOnlyTicker !== 'undefined' &&
              textWithOnlyTicker !== null &&
              textWithOnlyTicker.length > 0
            ) && (
              <p className="exluded-tickers-style">Unrecognized Securities: {textWithOnlyTicker}</p>
            )}
            {(textWithOnlyTickerTNS &&
              typeof textWithOnlyTickerTNS !== 'undefined' &&
              textWithOnlyTickerTNS !== null &&
              textWithOnlyTickerTNS.length > 0
            ) && (
              <p className="exluded-tickers-style">Unsupported Securities: {textWithOnlyTickerTNS}</p>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  )
});

export default RecommendedEnhancements;

const AttrHeader = (props) => {
  // const { attrDetails, attrDetails: { name } } = props;
  const {
    name,
    // decimal,
    // highlights,
    // value,
    // suffix,
    // multiplier
  } = props;
  
  let labelWillBe = (name === "Returns") ? (<span>Ann. <br /> Returns</span>) : name;
  let subText = props.des;
  switch (name) {
    case 'ESG':
      subText = null;
    break;
    case 'Fees':
      labelWillBe = 'Expense Ratio';
    break;
    case 'Returns':
      labelWillBe = (<p>3 Year Annualized Return</p>);
    break;
    default:
      labelWillBe = <p>3 Year Annualized Risk</p>;
  }

  return (
    <span>
      <span className="d-flex justify-content-center align-items-center header-icon-title">
        <GetIcon
          // attr={attrDetails}
          width={20}
          name={name}
          // addClass={'greyScale'}
          style={{ marginBottom: '3px', marginRight: '5px' }}
        />
        <span>{labelWillBe}</span>
      </span>
      {subText && <span style={{ fontSize: 12, fontWeight: 600 }}>{subText}</span>}
    </span>

  )
}

const AttrContent = (props) => {
  const {
    name,
    decimal,
    highlights,
    value,
    suffix,
    multiplier,
    ticker,
  } = props;
  let valueWillBe = typeof value === 'string' ? value :
    typeof value !== 'undefined'
    ? checkValidNumber(
      parseFloat(value || 0) * (multiplier || 1),
      decimal || 2,
      suffix || '%',
      '--')
    : '--';
  return (
    <div className={`returns-flag-color ${highlights ? 'flag-highlight' : ''}`}>
      <GetIcon
        name={name}
        width={25}
        style={{ marginBottom: '10px' }}
        addClass={'greyScale'}
      />
      <p className="__val" style={{ margin: 0, lineHeight: 1 }}> {valueWillBe} </p>
      <p className="__state d-none" style={{ margin: 0, lineHeight: 1 }}> Low </p>
    </div>
  )
}

const GetIcon = (props) => {
  let {
    quin = 1,
    // quinReverse = false,
    name,
    // iconImg,
    icon,
    width,
    style,
    addClass
  } = props;
  const attributeFromName = getFRRAttrData().find((kj) => kj.name === name);
  if (attributeFromName && attributeFromName.attr === 'fees') icon = 'fa-tags-1';
  let Quin = QuinItems.find((item) => item.name === name);
  const renderIcon = attributeFromName && attributeFromName.icon + '-' + quintileSize(quin); // qi.reverse = true
  const attrIcon = (attributeFromName && !attributeFromName.icon) ? (
    <img
      className={addClass}
      src={getImage("Assets")} // "Assets" common image for all unknown node
      width={width ? width : 20}
      style={style}
    />
  ) : (
    <i className={`far fa-fw fassf ${renderIcon}`} />
  )
  return attrIcon;
}

// const formatValue = (value, prec = 2, suffix) => {
//   if (value) {
//     value = suffix ? value.toFixed(prec) + suffix : value.toFixed(prec);
//   } else {
//     value = '-';
//   }
//   return value;
// }

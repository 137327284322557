import React from 'react'
import { Col, Row, FormGroup, Label } from 'reactstrap'
import dateFns from 'date-fns'
import { FieldValue } from 'shared-components'

export default function ViewProfileForm({ formProps }) {
  const { values } = formProps
  const stateCityZipList = [values.state, values.city, values.zipcode].filter(
    Boolean
  )
  const stateCityZipValue = stateCityZipList.length
    ? stateCityZipList.join(', ')
    : ''

  return (
    <div className="profile-view-form">
      {/* <Debug data={values} /> */}
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="userName">Name</Label>
            <FieldValue dashIfEmpty>
              {values.first_name} {values.last_name}
            </FieldValue>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="address">Address</Label>
            <FieldValue dashIfEmpty>{values.address}</FieldValue>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="address">State, City, Zip</Label>
            <FieldValue dashIfEmpty>{stateCityZipValue}</FieldValue>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="address">Country</Label>
            <FieldValue dashIfEmpty>{values.country}</FieldValue>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="phone">Phone</Label>
            <FieldValue dashIfEmpty>{values.phone}</FieldValue>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="gender">Gender</Label>
            <FieldValue dashIfEmpty>
              {values.gender && (values.gender === 'M' ? 'Male' : 'Female')}
            </FieldValue>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="date_of_birth">Birth Date</Label>
            <FieldValue dashIfEmpty>
              {values.date_of_birth
                ? dateFns.format(values.date_of_birth, 'MMM DD, YYYY')
                : ''}
            </FieldValue>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="investableAssets">Investable Assets</Label>
            <FieldValue dashIfEmpty>{values.investable_assets}</FieldValue>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="iam">I am</Label>
            <FieldValue dashIfEmpty>{values.self_role}</FieldValue>
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

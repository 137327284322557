import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { CaretDownOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { Row, Col, Dropdown, Tooltip } from 'antd';

import { fetchWeightsData } from 'actions/weights';
import { setEnhancerUniverse } from 'actions/enhancer';
import { createLoadingSelector } from 'selectors/loading-selector';

import { addEventToAnalytics } from 'Utils';
import Toggle from 'components/Toggle';
import PButton from 'components/PButton';

import SelectorDropdown from './SelectorDropdown';
import { EnhancePositionsUniverse, EnhancePortfolioUniverse } from './utils';

class UniverseSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      premiumModal: false,
      notify: false,
      premiumFeature: false,
      checkMsg: false,
      activeSelectorUniverse: props.activeSelectorUniverse || [],
      searchTypeMode: props.searchTypeMode || 'FUNDS',
      activeFundUniverse: props.activeFundUniverse || [],
      activeModelUniverse: props.activeModelUniverse || [],
      fundsTooltipsOpen: (EnhancePositionsUniverse && EnhancePositionsUniverse.length > 0) ? EnhancePositionsUniverse.map((kl) => {
        return {
          [kl.tooltipTargetKey]: false,
        }
      }) : [],
      modelsTooltipsOpen: (EnhancePortfolioUniverse && EnhancePortfolioUniverse.length > 0) ? EnhancePortfolioUniverse.map((kl) => {
        return {
          [kl.tooltipTargetKey]: false,
        }
      }) : [],
    };
  }

  componentWillMount() {
    const { loggedIn, scoreAttrs } = this.props;
    if(loggedIn && _.isEmpty(scoreAttrs))  {
      this.props.fetchWeightsData();
    }
  }

  modelsUniverseHandler = (toggleState, value) => {
    const { activeFundUniverse, activeModelUniverse } = this.state;
    if (toggleState) {
      this.setState({ activeFundUniverse: [], activeSelectorUniverse: [], activeModelUniverse: [value], checkMsg: false }, () => this.queryHandler(value, 'MODELS'))
    } else {
      // check if any models toggle ON or OFF
      if (activeFundUniverse.length > 1 || activeModelUniverse.length > 1) {
        _.remove(activeModelUniverse, (n) => n === value);
        this.setState({ activeModelUniverse, checkMsg: false }, () => this.queryHandler(value, 'MODELS'));
      } else {
        // even models toggle are OFF then push atleast one
        activeModelUniverse.push(value);
        this.setState({
          activeModelUniverse: _.union(activeModelUniverse, value),
        });
        this.setState({ checkMsg: true })
      }
    }
    if (addEventToAnalytics) {
      addEventToAnalytics('Enhancer Universe Toggle','Enhancer Universe Toggle','ENHANCER_UNIVERSE_TOGGLE',{ [value]: toggleState });
    }
  }

  fundsUniverseHandler = (toggleState, value) => {
    const { activeFundUniverse, activeModelUniverse } = this.state;
    if (toggleState) {
      activeFundUniverse.push(value);
      this.setState({ activeModelUniverse: [], activeFundUniverse: _.union(activeFundUniverse, value), checkMsg: false }, () => this.queryHandler(value, 'FUNDS'))
    } else {
      if (activeFundUniverse.length > 1 || activeModelUniverse.length > 1) {
        _.remove(activeFundUniverse, (n) => n === value);
        this.setState({ activeFundUniverse, checkMsg: false }, () => this.queryHandler(value, 'FUNDS'));
      } else {
        activeFundUniverse.push(value);
        this.setState({
          activeFundUniverse: _.union(activeFundUniverse, value),
        });
        this.setState({ checkMsg: true })
      }
    }
    if (addEventToAnalytics) {
      addEventToAnalytics('Enhancer Universe Toggle','Enhancer Universe Toggle','ENHANCER_UNIVERSE_TOGGLE',{ [value]: toggleState });
    }
  }

  weightToggleHandler = (toggleState, value) => {
    const { activeSelectorUniverse, activeModelUniverse } = this.state;
    const { disableSelectorUniverse } = this.props;
    if ((activeModelUniverse && activeModelUniverse.length > 0) || disableSelectorUniverse) {
      this.setState({ activeSelectorUniverse: [] }, () => this.scoreAttrsWeightHandler(value));
    } else {
      if (toggleState) {
        activeSelectorUniverse.push(value);
        this.setState({ activeSelectorUniverse: _.union(activeSelectorUniverse, value), checkMsg: false }, () => this.scoreAttrsWeightHandler(value))
      } else {
        _.remove(activeSelectorUniverse, (n) => n === value);
        this.setState({ activeSelectorUniverse }, () => this.scoreAttrsWeightHandler(value));
      }
      if (value === 'udc') {
        addEventToAnalytics('Using Default Criteria','Using Default Criteria','USING_DEFAULT_CRITERIA',{ [value]: toggleState });
      }
    }
  }

  queryHandler = (val, searchTypeMode) => {
    // console.log(val, searchTypeMode);
    const { setEnhancerUniverse, toggleHandler } = this.props;
    if (setEnhancerUniverse) {
      setEnhancerUniverse({
        searchTypeMode,
        modelUniverse: (this.state.activeModelUniverse.length > 0) ? this.state.activeModelUniverse.join() : '',
        activeModelUniverse: this.state.activeModelUniverse,
        fundUniverse: (this.state.activeFundUniverse.length > 0) ? this.state.activeFundUniverse.join() : '',
        activeFundUniverse: this.state.activeFundUniverse,
        disableSelectorUniverse: (this.state.activeModelUniverse.length > 0),
        activeSelectorUniverse: (this.state.activeModelUniverse.length > 0) ? [] : this.state.activeSelectorUniverse,
      })
    }
    if (toggleHandler) {
      toggleHandler({
        searchTypeMode,
        modelUniverse: (this.state.activeModelUniverse.length > 0) ? this.state.activeModelUniverse.join() : '',
        fundUniverse: (this.state.activeFundUniverse.length > 0) ? this.state.activeFundUniverse.join() : '',
        switchFundState: (searchTypeMode === 'FUNDS'),
      });
    }
  }

  scoreAttrsWeightHandler = (values, universe) => {
    const { setEnhancerUniverse, scoreAttrsWeightHandler, selectedCalculator } = this.props;
    if (setEnhancerUniverse) {
      setEnhancerUniverse({
        activeSelectorUniverse: this.state.activeSelectorUniverse,
      })
    }
    if (scoreAttrsWeightHandler &&
      this.state.activeFundUniverse &&
      this.state.activeFundUniverse.length > 0
    ) {
      scoreAttrsWeightHandler(selectedCalculator, this.state.activeSelectorUniverse);
    }
  }

  validQuery = (query) => query.trim().length > 0

  fundsToggleTooltip = (tooltipTargetKey) => {
    const foundTooltipState = this.state.fundsTooltipsOpen.find((jk) => (Object.keys(jk) && Object.keys(jk).length > 0 && Object.keys(jk)[0] === tooltipTargetKey))
    if (this.state.fundsTooltipsOpen && foundTooltipState) {
      const ss = this.state.fundsTooltipsOpen.map((jk, index) => {
        if (Object.keys(jk) && Object.keys(jk).length > 0 && Object.keys(jk)[0] === tooltipTargetKey) {
          if (this.state.fundsTooltipsOpen[index] && typeof this.state.fundsTooltipsOpen[index][Object.keys(jk)] !== 'undefined' && typeof this.state.fundsTooltipsOpen[index][Object.keys(jk)[0]] !== 'undefined') {
            this.state.fundsTooltipsOpen[index][Object.keys(jk)] = !this.state.fundsTooltipsOpen[index][Object.keys(jk)[0]];
            return this.state.fundsTooltipsOpen[index];
          }
        } else {
          return this.state.fundsTooltipsOpen[index];
        }
      });
      this.setState({ fundsTooltipsOpen: ss })
    }
  }

  modelsToggleTooltip = (tooltipTargetKey) => {
    const foundTooltipState = this.state.modelsTooltipsOpen.find((jk) => (Object.keys(jk) && Object.keys(jk).length > 0 && Object.keys(jk)[0] === tooltipTargetKey))
    if (this.state.modelsTooltipsOpen && foundTooltipState) {
      const ss = this.state.modelsTooltipsOpen.map((jk, index) => {
        if (Object.keys(jk) && Object.keys(jk).length > 0 && Object.keys(jk)[0] === tooltipTargetKey) {
          if (this.state.modelsTooltipsOpen[index] && typeof this.state.modelsTooltipsOpen[index][Object.keys(jk)] !== 'undefined' && typeof this.state.modelsTooltipsOpen[index][Object.keys(jk)[0]] !== 'undefined') {
            this.state.modelsTooltipsOpen[index][Object.keys(jk)] = !this.state.modelsTooltipsOpen[index][Object.keys(jk)[0]];
            return this.state.modelsTooltipsOpen[index];
          }
        } else {
          return this.state.modelsTooltipsOpen[index];
        }
      });
      this.setState({ modelsTooltipsOpen: ss })
    }
  }

  render() {
    const {
      loggedIn,
      premiumUser,
      selectedCalculator,
      disableSelectorUniverse,
      SimilarityMeasuresOverlay,
      SelectedSimilarityMeasuresLabel,
      planName,
      featureAllowed,
      // performanceStatsLoading,
    } = this.props;
    const { activeSelectorUniverse, activeFundUniverse, activeModelUniverse } = this.state;
    const renderEnhancePositionsToggle = (EnhancePositionsUniverse && EnhancePositionsUniverse.length > 0) ? (
      EnhancePositionsUniverse.filter((kl) => kl.key && kl.label).map((un) => {
        return (
          <Col key={un.tooltipTargetKey} xs={10} sm={10} md={10} lg={10} xl={10} className="universe-section__item">
            <div className="d-flex align-items-center">
              {(un.premiumFeature) ? (
                <PButton
                  size="small"
                  type="toggle"
                  premiumAction={true}
                  style={{ marginRight: 5 }}
                  featureName={un.featureName}
                  // disabled={performanceStatsLoading}
                  checked={activeFundUniverse.includes(un.key)}
                  onChange={(value) => this.fundsUniverseHandler(value, un.key)}
                />
              ) : (
                <Toggle
                  size="small"
                  style={{ marginRight: 5 }}
                  // disabled={performanceStatsLoading}
                  checked={activeFundUniverse.includes(un.key)}
                  onChange={(value) => this.fundsUniverseHandler(value, un.key)}
                />
              )}
              <span className="check-label" style={{ paddingRight: 5 }} onClick={(e) => e.preventDefault()}>
                {un.label}
              </span>
              {(un.tooltip && un.tooltip !== '' && un.tooltipTargetKey && un.tooltipTargetKey !== '') && (
                <Tooltip
                  className="col-grey"
                  placement="right"
                  title={un.tooltip}
                  // disabled={performanceStatsLoading}
                >
                  <span className="check-label" id={un.tooltipTargetKey}>
                    <i class="fal fa-info-circle"></i>
                  </span>
                </Tooltip>
              )}
            </div>
          </Col>
        )
      })
    ) : (<div></div>);

    const renderEnhancePortfoliosToggle = (EnhancePortfolioUniverse && EnhancePortfolioUniverse.length > 0) ? (
      EnhancePortfolioUniverse.filter((kl) => kl.key && kl.label).map((un) => {
        // const foundTooltipStateIndex = modelsTooltipsOpen.findIndex((jk) => (Object.keys(jk) && Object.keys(jk).length > 0 && Object.keys(jk)[0] === un.tooltipTargetKey));
        // let tooltipTargetKeyState = (modelsTooltipsOpen[foundTooltipStateIndex] && typeof modelsTooltipsOpen[foundTooltipStateIndex][un.tooltipTargetKey] !== 'undefined') ? modelsTooltipsOpen[foundTooltipStateIndex][un.tooltipTargetKey] : null;
        return (
          <Col key={un.tooltipTargetKey} xs={24} sm={24} md={24} lg={24} xl={24} className="universe-section__item">
            <div className="d-flex align-items-center">
              {(un.premiumFeature) ? (
                <PButton
                  size="small"
                  type="toggle"
                  premiumAction={true}
                  style={{ marginRight: 5 }}
                  featureName={un.featureName}
                  // disabled={performanceStatsLoading}
                  checked={activeModelUniverse.includes(un.key)}
                  onChange={(value) => this.modelsUniverseHandler(value, un.key)}
                />
              ) : (
                <Toggle
                  size="small"
                  style={{ marginRight: 5 }}
                  // disabled={performanceStatsLoading}
                  checked={activeModelUniverse.includes(un.key)}
                  onChange={(value) => this.modelsUniverseHandler(value, un.key)}
                />
              )}
              <span className="check-label" style={{ paddingRight: 5 }} onClick={(e) => e.preventDefault()}>
                {un.label}
              </span>
              {(un.tooltip && un.tooltip !== '' && un.tooltipTargetKey && un.tooltipTargetKey !== '') && (
                <Tooltip
                  className="col-grey"
                  placement="right"
                  title={un.tooltip}
                  // disabled={performanceStatsLoading}
                >
                  <span className="check-label" id={un.tooltipTargetKey}>
                    <i class="fal fa-info-circle"></i>
                  </span>
                </Tooltip>
              )}
            </div>
          </Col>
        )
      })
    ) : (<div></div>);

    return (
      <Fragment>
        <Row id="universe-container">
          <Col xs={24} sm={24} md={24} lg={24} xl={11} className="enhancer-positions-universe-wrapper">
            <Row className="label">Enhance Positions</Row>
            <Row className="universe-section">
              {renderEnhancePositionsToggle}
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={2} className="enhancer-universe-divider-wrapper">
            <Row className="label">Or</Row>
            <Row className="enhancer-universe-divider"></Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={11} className="enhancer-portfolio-universe-wrapper">
            <Row className="label">Enhance Portfolio</Row>
            <Row className="universe-section">
              {renderEnhancePortfoliosToggle}
    				</Row>
          </Col>
        </Row>
        {(this.state.checkMsg) && (
          <div className="check-error">Note: Atleast one option should be selected.</div>
        )}
        <Row className="universe-section" style={{ paddingTop: 20 }}>
          <Col className="universe-section__item" style={{ margin: 0 }}>
            <div className="sidebar-dropdown-wrapper">
              <span className="label" style={{ marginRight: 6, marginBottom: 0 }}>Similarity Measure: </span>
              <div id="portfolio-class-dropdown-container" className="portfolio-class-dropdown-container dropdown-span">
                <Dropdown
                  overlayClassName="portfolio-class-dropdown-wrapper"
                  trigger={['hover']}
                  overlay={SimilarityMeasuresOverlay}
                  getPopupContainer={() => document.getElementById('portfolio-class-dropdown-container')}
                >
                  <a className="ant-dropdown-link portfolio-class-dropdown-text" onClick={e => e.preventDefault()}>
                    {SelectedSimilarityMeasuresLabel} <CaretDownOutlined style={{ fontSize: 12, verticalAlign: 0 }} />
                  </a>
                </Dropdown>
              </div>
            </div>
          </Col>
        </Row>
        {planName === "Premium Lite" && featureAllowed && featureAllowed.includes("enhancer") ? null : (
          <Row className="universe-section user-criteria-wrap" style={{ paddingTop: 20 }}>
            <Col className="universe-section__item" style={{ margin: 0 }}>
              <div className="sidebar-dropdown-wrapper">
                <div className="sidebar-selector-span">
                  <PButton
                    size="small"
                    type="toggle"
                    featureName="rank_using_default_selector_criteria"
                    premiumAction={true}
                    disabled={disableSelectorUniverse}
                    style={{ marginRight: 5 }}
                    checked={activeSelectorUniverse.includes('udc')}
                    onChange={(value) => this.weightToggleHandler(value, 'udc')}
                  />
                  <span className="label" style={{ margin: 0, lineHeight: 1.3 }} onClick={(e) => e.preventDefault()}>
                    Selector:
                  </span>
                </div>
                <div className="sidebar-selector-span">
                  <span className="d-flex">{(loggedIn && premiumUser) ? (
                    <SelectorDropdown
                      className="font-color-charcoal"
                      hideRadioButton={true}
                      disabled={disableSelectorUniverse}
                      dropdownLabel={selectedCalculator || 'My Selector'}
                      refreshSearch={() => this.weightToggleHandler(true, 'udc')}
                      scoreAttrsWeightHandler={(values) => this.weightToggleHandler(true, 'udc')}
                    />
                  ) : (
                    <PButton
                      size="small"
                      type="anchor"
                      featureName="rank_using_default_selector_criteria"
                      premiumAction={true}
                      disabled={disableSelectorUniverse}
                      style={{ marginRight: 5 }}
                      checked={activeSelectorUniverse.includes('udc')}
                      onChange={(value) => this.weightToggleHandler(value, 'udc')}
                    >
                      <span className="discover-myselector-dropdown">My Selector <CaretDownOutlined className="down-arrow" /></span>
                    </PButton>
                  )}</span>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ auth, investment, enhancer, profile, global, weights, loading }) => ({
  loading: investment.loading,
  loggedIn: auth.loggedIn,
  premiumUser: profile.user && profile.user.premiumUser,
  bigScreen: global.bigScreen,
  desktopScreen: global.desktopScreen,
  smallScreen: global.smallScreen,
  activeFundUniverse: enhancer.activeFundUniverse,
  activeModelUniverse: enhancer.activeModelUniverse,
  searchTypeMode: enhancer.searchTypeMode,
  activeSelectorUniverse: enhancer.activeSelectorUniverse,
  scoreAttrs: weights.scoreAttrs,
  selectedCalculator: weights.selectedCalculator,
  disableSelectorUniverse: weights.disableSelectorUniverse,
  featureAllowed: _.get(profile.user, 'feature_allowed', []),
  planName: _.get(profile.user, 'planName', ""),
  // performanceStatsLoading: false,
  // performanceStatsLoading: createLoadingSelector(['GET_PORTFOLIO_BY_ID', 'GET_CURRENT_PORTFOLIO_STATS', 'GET_ENHANCED_STATS_FOR_SELECTED_TICKER'])({ loading }),
})

const mapDispatchToProps = {
  setEnhancerUniverse,
  fetchWeightsData,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UniverseSection)

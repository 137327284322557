import React from 'react';
import RenderRegionsChart from '../shared/RegionsChart'
import RenderBurst from '../shared/RenderBurst'
import RenderCarousel from '../shared/RenderCarousel'
import RenderOdometer from '../shared/RenderOdometer'
import RenderPieChart from '../shared/RenderPieChart'
import DynamicGaugeChart from '../shared/DynamicGaugeChart'
import NotApplicable from '../../NotApplicable';

export const ViewChart = ({ fund, i, width, queryVar, data, chartType, size }) => {
  switch (chartType) {
    case 'regionsChart':
      return <RenderRegionsChart width={width} data={data} i={i} />;
    case 'burst_chart':
      return <RenderBurst width={width} fund={fund} data={data} i={i} queryVar={queryVar} />;
    case 'carousel':
      return <RenderCarousel width={width} data={data} queryVar={queryVar} />;
    case 'odometer':
      return <RenderOdometer width={width} tickerData={data} queryVar={queryVar} />;
    case 'pie_chart':
      return <RenderPieChart width={width} fund={fund} item={data} queryVar={queryVar} />;
    case 'dynamicGaugeChart':
      return <DynamicGaugeChart width={width} tickerData={data} queryVar={queryVar} />;
    default:
      return <NotApplicable />;
  }
}

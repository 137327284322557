import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import qs from 'query-string'
import { FormGroup, Alert } from 'reactstrap'

import { Modal, ModalHeader, ModalBody, Spinner } from 'shared-components'
import { createLoadingSelector } from 'selectors/loading-selector'
import { BrokersList } from '../DataSet'
import { completeSetupTDAmeritrade } from 'actions/broker'

class BrokerAmeritradeSetupContainer extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.brokerSetupSuccess !== prevProps.brokerSetupSuccess) {
      if (this.props.brokerSetupSuccess) {
        // window.location.history.replaceState({}, document.title, window.origin)
        this.props.history.push(`/settings/broker/tdameritrade/account`)
      }
    }
  }

  componentDidMount() {
    const query = qs.parse(window.location.search)
    if (query.code) {
      this.props.completeSetupTDAmeritrade({
        code: query.code
      })
    }
  }

  handleToggle = () => {
    this.props.history.push('/settings/broker/select')
  }

  render() {
    const { isFetching, brokerSetupFailed } = this.props
    const broker = BrokersList.find(b => b.name === 'tdameritrade')

    return (
      <Modal
        isOpen={true}
        toggle={this.handleToggle}
        centered
        className="brokers-signin"
      >
        <ModalHeader>Setup TDAmeritrade</ModalHeader>
        <Spinner isFetching={isFetching} />
        <ModalBody>
          <FormGroup className="text-center">
            <img src={broker.imgSrc} />
          </FormGroup>

          {brokerSetupFailed && (
            <Alert color="danger">
              Sorry, looks like something went wrong on our end. Try again
              later.
            </Alert>
          )}
          {isFetching && (
            <Alert color="info">Please wait we are processing request</Alert>
          )}
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  brokerSetupSuccess: state.tdameritradeBroker.brokerSetupSuccess,
  brokerSetupFailed: state.tdameritradeBroker.brokerSetupFailed,
  isFetching: createLoadingSelector(['COMPLETE_TDAMERITRADE_BROKER_SETUP'])(
    state
  )
})

const mapDispatchToProps = {
  completeSetupTDAmeritrade
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrokerAmeritradeSetupContainer)

import React, { Component, Fragment } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { Row, Col, Button } from 'antd';
import Knob, { SkinWrap, KnobProps, composeTwo, Donut } from 'react-dial-knob';

import { createLoadingSelector } from 'selectors/loading-selector';
import {fetchPortfolioRequest, fetchPortfolioSuccess} from 'actions/buildportfolio';
import { setBuildPortfolioState } from 'actions/buildportfolio';

import { Spinner } from '../../Elements/Loader';

class AssetAllocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabHeaderMenu: props.tabHeaderMenu,
      total: {
        equity: 0,
        bond: 0,
        thematic: 0
      }
    };
  }

  componentDidMount() {
    const {buildportfolio} = this.props;
    // const {onboardData} = buildportfolio;

    let obj = {type: buildportfolio.onboardData.type};

    const layoutContentTag = document.getElementById('build-portfolio-content-wrapper');
    if (layoutContentTag) {
      layoutContentTag.style.background = '#ffff';
    }

    if(buildportfolio.onboardData.type === 'demo_portfolio2'){
      this.props.fetchPortfolioRequest(obj);
    }else if(buildportfolio.onboardData.type === 'tickers'){
      let tickerObj = {tickers: buildportfolio.onboardData.data};
      this.props.fetchPortfolioRequest(tickerObj);
    }else if(buildportfolio.onboardData.selectedPortfolioFor === 'From Scratch'){
      // obj.name = buildportfolio.onboardData.data;
      // this.props.fetchPortfolioRequest(obj);
    }else{
      obj.name = buildportfolio.onboardData.data;
      this.props.fetchPortfolioRequest(obj);
    }
  }

  componentWillUnmount() {
    const layoutContentTag = document.getElementById('build-portfolio-content-wrapper');
    if (layoutContentTag) {
      layoutContentTag.style.background = '#F7F7F7';
    }
  }

  onValueChange = (value, key) => {
    let sumLockedHeaders = 0.0;
    if (value && key) {
      this.state.tabHeaderMenu.forEach((item) => {
        if(item.locked == true)
          sumLockedHeaders += item.sliderValue;
      });

      this.state.tabHeaderMenu.forEach((item) => {
        if (item.key === key) {
          item.sliderValue = value > (100-sumLockedHeaders) ? (100-sumLockedHeaders) : value;
        }
      });

      this.setState({ tabHeaderMenu : this.state.tabHeaderMenu }, () => {
        this.calculateSliderValue(key, value)
      });
    }
  }

  calculateSliderValue = (label, value) => {
    //filter the header reqquired for slider wt calc after the change
    //it may be less than the total in case of manual slider chnage
    //or it may be all if the components loads for the first time or weightingMethodology chnage

    let { weightingMethodology, setBuildPortfolioState } = this.props;
    let totalSum = 0.0, filterObjsTotalSum = 0.0, diff = 0.0;
    let filterObjs = [], unfilterObjs = [];

    this.state.tabHeaderMenu.forEach(e => {
      let item = JSON.parse(JSON.stringify(e));
      if(weightingMethodology == 'Equal') item.sliderValue = 1
      totalSum += item.sliderValue;
      if((item.label != label && item.locked == false) || weightingMethodology == 'Equal') { //filter condition
        filterObjsTotalSum += item.sliderValue;
        filterObjs.push(item);
      }
      else {
        unfilterObjs.push(item);
      }
    });

    if(filterObjs.length>0) {
      diff = 100 - totalSum;
      filterObjs.forEach((obj, index) => {
        let ratio = obj.sliderValue == 0 ? 0 : obj.sliderValue / filterObjsTotalSum;
        let wt = diff * ratio;
        filterObjs[index].sliderValue = Math.round(obj.sliderValue + wt);
      });

      filterObjs = _.sortBy(filterObjs.concat(unfilterObjs),['position']);
      this.setState({ tabHeaderMenu: filterObjs });
      if (setBuildPortfolioState) {
        setBuildPortfolioState({ tabHeaderMenu : filterObjs })
      }
    }
  }

  selectFunds = () => {
    const { buildportfolio, history, setBuildPortfolioState } = this.props;
    const { onboardData, tabHeaderMenu, summary } = buildportfolio;
    if (onboardData && onboardData.onboardFrom !== '' && history) {
      const urlObj = {
        onboardData,
        tabHeaderMenu,
      };
      // console.log(urlObj);
      setBuildPortfolioState({
        summary: {
          ...summary,
          timeline: 'Select Funds'
        }
      })
      const newPath = `/build-portfolio${onboardData.onboardFrom}`;
      history.push(newPath);
    }
  }

  render() {
    const { buildportfolio, history, loading } = this.props;
    const { onboardData } = buildportfolio;
    // const {total} = this.state;

    if (onboardData.onboardFrom === '' || onboardData.selectedPortfolioFor === '') {
      if (history) {
        history.push('/build-portfolio');
      }
    }

    const chartData = buildportfolio.tabHeaderMenu;

    const equityData = chartData.find((f) => f.label === 'Equity');
    const bondsData = chartData.find((f) => f.label === 'Bonds');
    const thematicData = chartData.find((f) => f.label === 'Thematic Exposure');

    const width = 160;

    let total = {
      equity: 0,
      bond: 0,
      thematic: 0,
      global: 0,
    };

    buildportfolio.preSelectedTickers.map(funds => {
      if(funds.category === 'equity'){
        total.equity += 1;
      }else if(funds.category === 'bonds'){
        total.bond += 1;
      }else if(funds.category === 'themes'){
        total.thematic += 1;
      } else if(funds.sub_category === 'Global'){
        total.global += 1;
      }
    });

    const portfolioDetails = onboardData.selectedPortfolioFor === 'From Scratch' ? null : buildportfolio.preSelectedTickers.length > 0 ?
    <p className="title">You have selected {total.equity} Equity funds, {total.bond} Bond funds, {total.thematic} Thematic Exposure fund</p> : null;

    return (
      <Row className="asset-allocation-screen-container">
        {loading ? (
          <Spinner />
        ) : (
          <Col className="asset-allocation-screen-wraper">
            <div>
            {portfolioDetails}
              <p className="sub-title">Let’s start with asset allocation</p>
            </div>
            <Row className="section section-mg">
              <Col span={8} className="chart-box">
                <div className="chart-label">{equityData.label}</div>
                <Donut
                  style={{ width, margin: '0 auto' }}
                  diameter={width}
                  min={0}
                  max={100}
                  step={1}
                  value={equityData.sliderValue}
                  theme={{
                    donutColor: '#1d6091',
                    centerColor: "white",
                    centerFocusedColor: "white",
                    donutThickness: 32,
                  }}
                  renderText={(props) => <RenderText {...props} />}
                  ariaLabelledBy={'equityTab-label'}
                  onValueChange={(value) => this.onValueChange(value, 'equityTab')}
                />
              </Col>
              <Col span={8} className="chart-box">
                <div className="chart-label">{bondsData.label}</div>
                <Donut
                  style={{ width, margin: '0 auto' }}
                  diameter={width}
                  min={0}
                  max={100}
                  step={1}
                  donutThickness={38}
                  centerColor="#fff"
                  focusedCenterColor="#fff"
                  value={bondsData.sliderValue}
                  theme={{
                    donutColor: '#1d6091',
                    centerColor: "white",
                    centerFocusedColor: "white",
                    donutThickness: 32,
                  }}
                  renderText={(props) => <RenderText {...props} />}
                  ariaLabelledBy={'bondTab-label'}
                  onValueChange={(value) => this.onValueChange(value, 'bondTab')}
                />
              </Col>
              <Col span={8} className="chart-box">
                <div className="chart-label">{thematicData.label}</div>
                <Donut
                  style={{ width, margin: '0 auto' }}
                  diameter={width}
                  min={0}
                  max={100}
                  step={1}
                  donutThickness={38}
                  centerColor="#fff"
                  focusedCenterColor="#fff"
                  value={thematicData.sliderValue}
                  theme={{
                    donutColor: '#1d6091',
                    centerColor: "white",
                    centerFocusedColor: "white",
                    donutThickness: 32,
                  }}
                  renderText={(props) => <RenderText {...props} />}
                  ariaLabelledBy={'thematicTab-label'}
                  onValueChange={(value) => this.onValueChange(value, 'thematicTab')}
                />
              </Col>
            </Row>
            <Row className="section">
              <Button shape="round" type="default" className="action-btn" onClick={this.selectFunds}>Select Funds</Button>
            </Row>
          </Col>
        )}
      </Row>
    )
  }
}

const mapStateToProps = ({ buildportfolio, loading }) => ({
  buildportfolio,
  tabHeaderMenu: buildportfolio.tabHeaderMenu,
  weightingMethodology: buildportfolio.weightingMethodology,
  loading: createLoadingSelector(['PORTFOLIO_DATA'])({ loading }),
})

const mapDispatchToProps = {
  setBuildPortfolioState,
  fetchPortfolioRequest,
  fetchPortfolioSuccess
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AssetAllocation));


const RenderText = (props) => {
  console.log(props);
}

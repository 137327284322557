import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Card, CardBody, CustomInput } from 'reactstrap';
import { Row, Col, Typography, Tabs } from 'antd';

import { sponsorLogo } from 'DataSet';
import { FormatCurrency } from 'components/reports/shared/Utils';
import { TruncatedText } from 'components/sf/Common';
import NotApplicable from 'components/NotApplicable';
import { FeeReturnRisk } from './FeeReturnRisk';
import RenderOdometer from './RenderOdometer';
import AssetAllocationChart from './AssetAllocationChart';
import RegionChart from './RegionChart';
import { ViewChart } from '../../Funds/Elements/ViewChart';
import { SectorColors, RegionColors, AssetAllocationBurstChart } from 'data/Colors';
import { AssetType, cn, cardsParams, kmb } from 'DataSet';

import { setPortfolioReplace, setEnhancementData } from 'actions/portfolio';
import { getPortfolioStats, getInvestmentWatchlistStats, getInvestmentPositionStats } from 'selectors/investment';
import { getFRRAttrDataForModels, textCapitalize } from 'layouts/utils';
import { quintileSize, quintileText, getImage } from 'Utils';
import DataRow from './DataRow';

const { Paragraph } = Typography;
const { TabPane } = Tabs;

const nullValues = ['', null];

export class ItemCard extends Component {
  constructor(props) {
    super(props)
  }

  tabOnChange = (selectedKey, data, ticker, query, index) => {
    const { tabOnChange } = this.props;
    if (tabOnChange) {
      tabOnChange(selectedKey, data, ticker, query, index);
    }
  }

  getItemCardDesign = () => {
    const {
      query,
      ticker,
      enhanceReplace,
      i,
      staticCard,
      resetCheck,
      highlights,
      className,
      searchFundsActiveRegion,
      universe,
      portfolioQuery,
      defaultFRR,
      relevanceChartTabs,
      originalQueryVar,
      first_name,
      last_name,
      activeViewTab,
    } = this.props;

    let { data, } = this.props;
    let new_data = '', FirstBlock = '', id = '', FRS = {}, holdings = data.holdings || [], chart = '', centerDisplayContent = '';
    let highlightsObj = (typeof highlights !== 'undefined' && highlights) ? highlights : { feesHighLightEnable: false, returnHighLightEnable: false, riskHighLightEnable: false };
    let portfolioInfo = data.portfolioDetails;
    let {custom_replace_type,custom_replace_pf_name} = data
    // ---- QUERY_VAR MAPPING ----
    let riskFromQueryVar = originalQueryVar.find((yuu) => yuu.node === 'risk');
    let returnFromQueryVar = originalQueryVar.find((yuu) => yuu.node === 'returns');

    let riskMultiplier = (riskFromQueryVar && riskFromQueryVar.multiplier && riskFromQueryVar.multiplier !== '') ? riskFromQueryVar.multiplier : 1; // DataSET ref
    let returnMultiplier = (returnFromQueryVar && returnFromQueryVar.multiplier && returnFromQueryVar.multiplier !== '') ? returnFromQueryVar.multiplier : 1; // DataSET ref

    let riskReturnYield = (data.risk_return_yield && data.risk_return_yield.length > 0) ? data.risk_return_yield[0] : null;

    let riskValue = (riskFromQueryVar && data[riskFromQueryVar.variable]) ? data[riskFromQueryVar.variable] : ((riskReturnYield && riskReturnYield['attribute/technical/risk'] ) ? riskReturnYield['attribute/technical/risk'].toFixed(2) : '--');
    let returnVlaue = (returnFromQueryVar && data[returnFromQueryVar.variable]) ? data[returnFromQueryVar.variable] : ((riskReturnYield && riskReturnYield['attribute/technical/returns']) ? riskReturnYield['attribute/technical/returns'].toFixed(2) : '--');


    if (staticCard && data && typeof data.portfolioDetails !== 'undefined') {
      const { portfolioDetails } = data;
      portfolioInfo = data.portfolioDetails;
      holdings = data.holdings;
      id = 'cb-'+portfolioDetails.name;

      let name = '';
      if (portfolioDetails.profile.firstName || portfolioDetails.profile.lastName) {
        if (portfolioDetails.profile.firstName) {
          name = portfolioDetails.profile.firstName[0];
        }
        if (portfolioDetails.profile.lastName) {
          name = name+portfolioDetails.profile.lastName[0];
        }
      } else {
        name = 'N/A';
      }
      FirstBlock = (
        <div className="profile-card">
          <div className="profile-initial-icon">{name}</div>
          <h5 className="m-0" title={portfolioDetails.name} style={{ fontWeight: 600 }}>
            <Paragraph
              className="m-0 profile-name"
              ellipsis={{rows: 1}}
              title={portfolioDetails.name}
            >
              {portfolioDetails.name}
            </Paragraph>
          </h5>
        </div>
      );

      let targetRiskValue = (data.targetCardData && data.targetCardData['attribute/technical/risk']) ? (data.targetCardData['attribute/technical/risk']) : '--';
      let targetReturnVlaue = (data.targetCardData && data.targetCardData['attribute/technical/returns']) ? (data.targetCardData && data.targetCardData['attribute/technical/returns']) : '--';
      // target card(LHS) FRS for mp and up
      FRS = {
        ...data.targetCardData,
        fees: parseFloat(portfolioDetails.riskFeeReturn.expenseRatio),//data.fees,
        feesQ: parseFloat(portfolioDetails.riskFeeReturn.fee),//data.feesq,
        yRetQ: parseFloat(portfolioDetails.riskFeeReturn.anRet3yr), //data.Fee_Risk_Return.returnq,
        voltQ: parseFloat(portfolioDetails.riskFeeReturn.anVol3yr), //data.Fee_Risk_Return.riskq,
        yRet: targetReturnVlaue,
        volt: targetRiskValue,
        queryIcon: '',
      };

      // FRS = data && FRS && query && enhancerCards('targetCardValue', data, FRS, query);

      chart = data.targetCard;
      data.Region = data.targetCard ? data.targetCard.Region : [];
      data.chartData = [];
      data = portfolioDetails;
    } else {
      if (universe !== '' && universe === "up" && typeof this.props.currentPortfolioNameInitial !== 'undefined') {
        const { portfolioDetails } = this.props.data;
        const { currentPortfolioNameInitial } = this.props;
        id = data.index ? 'cb-'+ticker+data.index+data.id : 'cb-'+ticker+data.vehicle+data.ticker;

        if (typeof data.Fee_Risk_Return !== 'undefined') {
          // FRS for RHS in user portfolio
          // FRS = enhancerCards('rhsMpUp', data, FRS, query, 'upFundCards');
          FRS = {
            ...data,
            feesQ: data.feesq,
            yRetQ: (data.Fee_Risk_Return && data.Fee_Risk_Return.returnq) ? data.Fee_Risk_Return.returnq : null,
            voltQ: (data.Fee_Risk_Return && data.Fee_Risk_Return.riskq )? data.Fee_Risk_Return.riskq : null,
            yRet: returnVlaue,
            volt: riskValue,
          }

          if (typeof data.asset_alloc !== 'undefined') {
            chart = data.asset_alloc;
          }

          FirstBlock = (
            <div className="profile-card">
              <div className="profile-initial-icon">{currentPortfolioNameInitial}</div>
              <h5 className="m-0" title={data.name || custom_replace_pf_name} style={{ fontWeight: 600 }}>
                <Paragraph
                  className="m-0 profile-name"
                  ellipsis={{rows: 1}}
                  title={data.name||custom_replace_pf_name}
                >
                  {data.name||custom_replace_pf_name}
                </Paragraph>
              </h5>
            </div>
          );
        }

        if(custom_replace_type==='sponsored'){
          FirstBlock = <NameSponsor name={data.short_name} sponsor={data.sponsor} category={data.category} />
        }

        if(typeof data._queryVar !== 'undefined') {
          data._queryVar.view_name = 'Asset Allocation Overlap';
          if(data._queryVar.variable && typeof data[data._queryVar.variable] !== 'undefined') {
            centerDisplayContent = Math.round(data[data._queryVar.variable])+'%';
          }
        }

      }
      else {
        id = data.index ? 'cb-'+ticker+data.index+data.id : 'cb-'+ticker+data.vehicle+data.ticker;

        if (typeof data.Fee_Risk_Return !== 'undefined' && data.risk_return_yield !== 'undefined') {
          // FRS for RHS in model portfolio
          // FRS = enhancerCards('rhsMpUp', data, FRS, query, 'mpFundCards');
          FRS = {
            ...data,
            feesQ: data.feesq,
            yRetQ: (data.Fee_Risk_Return && data.Fee_Risk_Return.returnq) ? data.Fee_Risk_Return.returnq : null,
            voltQ: (data.Fee_Risk_Return && data.Fee_Risk_Return.riskq )? data.Fee_Risk_Return.riskq : null,
            yRet: returnVlaue,
            volt: riskValue,
          }
          if (typeof data.asset_alloc !== 'undefined') {
            chart = data.asset_alloc;
          }

          FirstBlock = custom_replace_type ==='user' ? <div className="profile-card">
            <div className="profile-initial-icon">{`${first_name.substring(0,1)}${last_name.substring(0,1)}`}</div>
            <h5 className="m-0" title={custom_replace_pf_name} style={{ fontWeight: 600 }}>
              <Paragraph
                className="m-0 profile-name"
                ellipsis={{rows: 1}}
                title={custom_replace_pf_name}
              >
                {custom_replace_pf_name}
              </Paragraph>
            </h5>
          </div>: <NameSponsor name={data.short_name} sponsor={data.sponsor} category={data.category} />

        }
        if(typeof data._queryVar !== 'undefined') {
          // console.log('==============>> data._queryVar <<==============');
          data._queryVar.view_name = 'Asset Allocation Overlap';
          if(data._queryVar.variable && typeof data[data._queryVar.variable] !== 'undefined') {
            centerDisplayContent = Math.round(data[data._queryVar.variable])+'%';
          }
        }
      }
    }

    let checkedTrue = false;
    if (data) {
      checkedTrue = enhanceReplace === data.ticker;
      if (resetCheck) {
        checkedTrue = false;
      } else {
        checkedTrue = enhanceReplace === data.ticker;
      }
    }

    const { smallScreen, bigScreen, key } = this.props;
    const GuageChartWidth = smallScreen ? 90 : (bigScreen ? 130 : 90);
    const PieChartSize = smallScreen ? 74 : (bigScreen ? 100 : 74);
    const AssetAllocationChartTitle = "Asset Allocation";
    const view = "Regions";
    const activeRegion = this.props.activeRegion || '';

    if (staticCard) {
      data.chartData = [];
      data.Region = chart ? chart.Region : [];
    } else {
      data.chartData = [];
      data.Region = data.Region || [];
    }

    FRS.highlight = highlightsObj;

    const reportData = {
      currentPortfolioNameInitial: this.props.currentPortfolioNameInitial,
      portfolioName: data.name,
      short_name: data.short_name,
      sponsor: data.sponsor,
      category: data.category,
      FRS,
      chartTitle: staticCard ? AssetAllocationChartTitle : 'Overlap',
      chartData: data.Region,
      centerDisplayText: staticCard ? '' : centerDisplayContent,
      colors: AssetAllocationBurstChart,
      holdings,
      data,
    }

    const modelType = staticCard ? 'current' : 'enhanced';

    let { modelEnhancement, setModelEnhancement } = this.props;

    if(staticCard || checkedTrue) {
      if(data && FRS && query){
        modelEnhancement[modelType] = reportData;
        setModelEnhancement(modelEnhancement);
      }
    }else{
      if(data && FRS && query){
        const otherEnhancements = _.uniqBy([...modelEnhancement.otherEnhancements, reportData], 'portfolioName')
        modelEnhancement.otherEnhancements = otherEnhancements;
        setModelEnhancement(modelEnhancement);
      }
    }

    const getOdometerChartData = (tabName) => {
      // console.log(tabName, FRS);
      const FRR_DATA_ATTRS = getFRRAttrDataForModels(query, highlights);
      let FRR_ATTRS = FRR_DATA_ATTRS.filter((item) => item.attr === tabName);
      if (FRR_ATTRS && FRR_ATTRS.length > 0) {
        const elems = FRR_ATTRS.map(attr => {
          const qs = quintileSize(data[attr.qcol], attr.reverse);
          // console.log(attr.col, ' ==> ', data[attr.col] !== null, data[attr.col] !== null ? data[attr.col] : '--');
          const _val = (typeof data[attr.col] !== 'undefined' && data[attr.col] !== null && data[attr.col] !== '--') ?
          (attr.name === 'Assets' ? (kmb(data[attr.col])) : (
            (attr.decimal && attr.decimal !== '' ? (
              data[attr.col] * attr.multiplier).toFixed(attr.decimal)
              : Math.round((data[attr.col] * attr.multiplier)) ) + (attr.suffix)
            )) : '--';
          const odoObj = FRS.odometerChart ? FRS.odometerChart.find((lp) => lp.attr === attr.attr) : {};
          const odoViewData = odoObj ? odoObj._viewData : {};
          return {
            ...odoObj,
            _viewData: {
              ...attr,
              ...odoViewData,
              value: _val,
            }
          }
        });
        const found = elems.find((p) => p.attr === tabName);
        if (found) {
          return (
            <ViewChart
              size="md"
              i={'overlap_'+ticker}
              data={found}
              width={GuageChartWidth}
            />
          )
        } else {
          return (
            <NotApplicable />
          );
        }
      } else {
        return (
          <NotApplicable />
        );
      }
    }

    // const dump = {
    //   attr: 'risk',
    //   chart_type: "odometer",
    //   data: "WNTMP14D40",
    //   value: 21,
    //   view_name: "Risk",
    //   _viewData: {
    //     attr: "risk",
    //     col: "volt",
    //     decimal: 2,
    //     highlightClass: "",
    //     icon: "fa-heart-rate-alt",
    //     max: 100,
    //     min: 0,
    //     multiplier: 100,
    //     name: "Risk",
    //     qcol: "voltQ",
    //     reverse: true,
    //     suffix: "%",
    //     value: 21 || "--",
    //   },
    // };
    // console.log(dump);

    // dynamic chart ==> "odometerChart" logic

    // <div className="relevance-charts-tab-wrapper">
    //   {relevanceChartTabs && relevanceChartTabs.length > 0 && (
    //     <Tabs activeKey={activeViewTab} tabPosition="bottom" size="small" style={{ marginBottom: 5 }} onChange={(key) => this.tabOnChange(key, data, ticker, query, this.props.i)}>
    //       {relevanceChartTabs.map((op, opI) => {
    //         return (
    //           <TabPane tab={(op === 'BurstChart') ? (AssetAllocationChartTitle ? AssetAllocationChartTitle : 'Overlap') : op} key={opI+1}>
    //             {(op === 'BurstChart') ? (
    //               data ? (
    //                 (staticCard && (view === "Regions" && data.Region)) ? (
    //                   <div className="w-100 h-100 card-background d-flex align-items-center chart-block" style={{ flexDirection: 'column' }}>
    //                     <RegionChart
    //                       width={PieChartSize}
    //                       height={PieChartSize}
    //                       key={key}
    //                       card={chart}
    //                       name="Allocations"
    //                       colors={AssetAllocationBurstChart}
    //                       activeRegion={activeRegion}
    //                       searchFundsActiveRegion={searchFundsActiveRegion}
    //                       view="Regions"
    //                     />
    //                     {/*AssetAllocationChartTitle && (
    //                       <div className="asset-allocation-bottom-title text-muted">{AssetAllocationChartTitle}</div>
    //                     )*/}
    //                   </div>
    //                 ) : (
    //                   (typeof data._queryVar !== 'undefined' && typeof data._queryVar.view_name !== 'undefined' && typeof data._viewData !== 'undefined') ? (
    //                     <RenderOdometer
    //                       key={'overlap_'+data.ticker.toLowerCase()}
    //                       width={GuageChartWidth}
    //                       tickerData={data._viewData}
    //                       queryVar={data._queryVar}
    //                     />
    //                   ) : (
    //                     (!staticCard && data && typeof data.Region !== 'undefined') ? (
    //                       <div className="w-100 h-100 card-background d-flex align-items-center chart-block" style={{ flexDirection: 'column' }}>
    //                         {(view === "Regions" && data.Region) ? (
    //                           <RegionChart
    //                             width={PieChartSize}
    //                             height={PieChartSize}
    //                             key={key}
    //                             card={data}
    //                             name="Allocations"
    //                             colors={AssetAllocationBurstChart}
    //                             activeRegion={activeRegion}
    //                             searchFundsActiveRegion={searchFundsActiveRegion}
    //                             view="Regions"
    //                             centerDisplay={centerDisplayContent}
    //                             centerText="Overlap"
    //                           />
    //                         ) : (
    //                           <AssetAllocationChart
    //                             width={PieChartSize}
    //                             height={PieChartSize}
    //                             key={key}
    //                             card={chart}
    //                             attrs={AssetType}
    //                             name={'Asset Types'}
    //                             colors={SectorColors}
    //                             centerDisplayContent={centerDisplayContent}
    //                             centerTooltipPrefixText="Overlap"
    //                           />
    //                         )}
    //                         {/*<div className="asset-allocation-bottom-title text-muted">Overlap</div>*/}
    //                       </div>
    //                     ) : (
    //                       <NotApplicable />
    //                     )
    //                   )
    //                 )) : (
    //                 <NotApplicable />
    //               )
    //             ) : getOdometerChartData(op)
    //           }
    //           </TabPane>
    //         )
    //       })}
    //     </Tabs>
    //   )}
    // </div>

    return (
      <Card key={enhanceReplace+i} className={`${!staticCard ? 'card-background-grey' : 'sddf'} ${className}`}>
        <CardBody className="p-0">
          {!staticCard && data
            && (
              <CustomInput
                type="checkbox"
                id={id}
                inline
                value={data}
                onClick={(e) => this.props.setReplaceTicker(e, data, data.ticker, query)}
                className='p-top-right'
                title="Select"
                checked={checkedTrue}
              />
            )
          }
          <Row className="model-portfolio-item-card-wrapper border-bottom">
            <Col span={6} className="p-1">
              {FirstBlock}
            </Col>
            <Col span={10} className="p-1" style={{ height: '100%' }}>
              <FeeReturnRisk
                originalQueryVar={originalQueryVar}
                defaultFRR={defaultFRR}
                data={FRS}
                query={query}
                highlights={highlightsObj}
              />
            </Col>
            <Col span={6} className="p-1" style={{ marginRight: !staticCard ? 10 : '', minWidth: 82 }}>
              {data ? (
                (staticCard && (view === "Regions" && data.Region)) ? (
                  <div className="w-100 h-100 card-background d-flex align-items-center chart-block" style={{ flexDirection: 'column' }}>
                    <RegionChart
                      width={PieChartSize}
                      height={PieChartSize}
                      key={key}
                      card={chart}
                      name="Allocations"
                      colors={AssetAllocationBurstChart}
                      activeRegion={activeRegion}
                      searchFundsActiveRegion={searchFundsActiveRegion}
                      view="Regions"
                    />
                    {AssetAllocationChartTitle && (
                      <div className="asset-allocation-bottom-title text-muted">{AssetAllocationChartTitle}</div>
                    )}
                  </div>
                ) : (
                  (typeof data._queryVar !== 'undefined' && typeof data._queryVar.view_name !== 'undefined' && typeof data._viewData !== 'undefined') ? (
                    <RenderOdometer
                      key={'overlap_'+data.ticker.toLowerCase()}
                      width={GuageChartWidth}
                      tickerData={data._viewData}
                      queryVar={data._queryVar}
                    />
                  ) : (
                    (!staticCard && data && typeof data.Region !== 'undefined') ? (
                      <div className="w-100 h-100 card-background d-flex align-items-center chart-block" style={{ flexDirection: 'column' }}>
                        {(view === "Regions" && data.Region) ? (
                          <RegionChart
                            width={PieChartSize}
                            height={PieChartSize}
                            key={key}
                            card={data}
                            name="Allocations"
                            colors={AssetAllocationBurstChart}
                            activeRegion={activeRegion}
                            searchFundsActiveRegion={searchFundsActiveRegion}
                            view="Regions"
                            centerDisplay={centerDisplayContent}
                            centerText="Overlap"
                          />
                        ) : (
                          <AssetAllocationChart
                            width={PieChartSize}
                            height={PieChartSize}
                            key={key}
                            card={chart}
                            attrs={AssetType}
                            name={'Asset Types'}
                            colors={SectorColors}
                            centerDisplayContent={centerDisplayContent}
                            centerTooltipPrefixText="Overlap"
                          />
                        )}
                        {<div className="asset-allocation-bottom-title text-muted">Overlap</div>}
                      </div>
                    ) : (
                      <NotApplicable />
                    )
                  )
                )) : (
                <NotApplicable />
              )}
            </Col>
          </Row>
          <DataRow data={holdings} />
        </CardBody>
      </Card>
    );
  }

  render() {
    const renderDesign = this.getItemCardDesign();

    return (
      <React.Fragment>
        {renderDesign}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ global,profile }) => ({
  smallScreen: global.smallScreen,
  bigScreen: global.bigScreen,
  first_name:profile.profile.first_name,
  last_name:profile.profile.first_name,
})

const mapDispatchToProps = {
  setPortfolioReplace,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemCard)

const NameSponsor = ({ name, sponsor, category }) => (
  <div className="w-100 name-sponsor-block">
    <div className="pb-1">
      {sponsor && (
        <ImageOrText img={sponsorLogo(sponsor)} text={sponsor} />
      )}
    </div>
    <h5 className="m-0" title={name} style={{ fontWeight: 600 }}>
      <Paragraph
        className="m-0 short-name"
        ellipsis={{rows: 1}}
        title={name}
        >
        {name}
      </Paragraph>
    </h5>
    <h5 className="m-0" title={category}>
      <Paragraph
        className="m-0 category-text"
        ellipsis={{rows: 1}}
        title={category}
        >
        {category}
      </Paragraph>
    </h5>
  </div>
)

const TickerPrice = ({ ticker, price }) => (
  <div className="d-flex flex-column justify-content-center align-items-center" style={{minWidth: '4rem'}}>
    <h5 className="mb-0 text-blue text-gray-5 ticker-size ticker-price">{ticker}</h5>
    <h4 className="mt-1 mb-0 ticker-price">{FormatCurrency(price, 2)}</h4>
  </div>
)

const ImageOrText = ({ img, text = '' }) => {
  const [valid, setValid] = useState(true);

  return (
    valid
    ? <img src={img} className="img-fluid" alt="" onError={() => setValid(false)} style={{height: '20px'}} />
  : <div className="sponsor-logo-placeholder" title={text}>{text}</div>
);
}

const getId = (function* () {
  let n = 1;
  while (true) yield n++;
})();

import ActionTypes from '../action-types'
import { areIntervalsOverlapping } from 'date-fns'

// add state which will be refreshed on component unmount
const initialState = {
  loading: false,
  portfolioValue: '728000',
  portfolioName: '',
  calculatedPortfolio: {},
  calculatedTabHeader: {},
  activeTab: 'equityTab',
  data: [],
  preSelectedTickers: [],
  tabHeaderMenu: [
    {
      color: '#1d6091',
      key: 'equityTab',
      label: 'Equity',
      position: 1,
      locked: false,
      showInput: false,
      inputNumberDisabled: true,
      sliderEnabled: false,
      sliderValue: 0,
      sliderTooltipEnabled: false
    },
    {
      color: '#56a9e8',
      key: 'bondTab',
      label: 'Bonds',
      position: 2,
      locked: false,
      showInput: false,
      inputNumberDisabled: true,
      sliderEnabled: false,
      sliderValue: 0,
      sliderTooltipEnabled: false
    },
    {
      color: '#acd6f5',
      key: 'thematicTab',
      label: 'Thematic Exposure',
      position: 3,
      locked: false,
      showInput: false,
      inputNumberDisabled: true,
      sliderEnabled: false,
      sliderValue: 0,
      sliderTooltipEnabled: false
    }
  ],
  weightingMethodology: 'Manual',
  actTab: {
    data: [],
    selectedFunds: {},
    weightingMethodology: 'Equal'
  },
  equityTab: {
    data: [],
    selectedFunds: {},
    weightingMethodology: 'Equal',
    newQuery: '',
    forQuery: '',
    oldDataBeforeSearch: {},
    noResultFound: false
  },
  bondTab: {
    data: [],
    newQuery: '',
    forQuery: '',
    selectedFunds: {},
    weightingMethodology: 'Equal',
    noResultFound: false
  },
  thematicTab: {
    data: [],
    newQuery: '',
    forQuery: '',
    themes: [],
    selectedFunds: {},
    allocationChartData: [],
    selectedThemes: ['Climate Change'],
    weightingMethodology: 'Equal',
    chooseThemeButtonToggle: true,
    noResultFound: false
  },
  tickerList: [],
  clearBuilderFunds: false,
  resultSection: {
    chartData: [],
    fundList: [],
		analyticsData: {}
  },
  portfolio_name: '',
  saveState: {},
  savedPortfolio: {},
  summary: {},
  tickerNotExist: [],
  tickers: [],
  oldHeaderMenuState: undefined,
  uploadfileDuplicate: null,
  uploadfileIncomplete: null,
  uploadFileFailure: null,
  uploadFileFailureMsg: null,
  uploadFilePartial: null,
  uploadFilePartialMsg: null,
  tickerFetchError:false
}

const definedState = {
  ...initialState,
  onboardData: {
    selectedPortfolioFor: '',
    onboardFrom: ''
  },
  summary: {
    name: '',
    timeline: 'Select Funds'
  },
  resultPageCart: []
}

export function buildportfolio(state = definedState, action) {
  const { payload } = action
  switch (action.type) {
    case ActionTypes.SET_BUILD_PORTFOLIIO_STATE:
      if (payload.callback && typeof payload.callback === 'function') {
        return (
          {
            ...state,
            ...payload
          },
          () => payload.callback()
        )
      } else {
        return {
          ...state,
          ...payload
        }
      }
    case ActionTypes.EQUITY_BATCH_SUCCESS:
      return {
        ...state,
        ...payload
      }
    case ActionTypes.BONDS_BATCH_SUCCESS:
      return {
        ...state,
        ...payload
      }
    case ActionTypes.THEMATIC_BATCH_FETCH:
      return {
        ...state,
        ...payload
      }
    case ActionTypes.THEMATIC_BATCH_SUCCESS:
      return {
        ...state,
        ...payload
      }
    case ActionTypes.CUSTOM_TICKER_SUCCESS:
      return {
        ...state,
        ...payload
      }
    case ActionTypes.TICKER_DATA_FETCH:
      return {
        ...state,
        ...payload
      }
    case ActionTypes.TICKER_DATA_SUCCESS:
      return {
        ...state,
        ...payload
      }
    case ActionTypes.DELETE_TICKER_DATA:
      const { item, index, searchedTicker, forTicker, forQuery, tab } = payload
      const tabStates = state[`${tab}`]

      tabStates.data.map(e => {
        if (e.ticker === forTicker && e.query === forQuery) {
          e.customSearchTickerData = {}
        }
      })

      const newObject = {
        [`${tab}`]: tabStates
      }

      return {
        ...state,
        ...newObject
      }
    case ActionTypes.THEMES_SUCCESS:
      return {
        ...state,
        ...payload
      }
    case ActionTypes.SET_WEIGHTS_REQUEST:
      return {
        ...state,
        ...payload
      }
    case ActionTypes.SET_WEIGHTS_SUCCESS:
      return {
        ...state,
        ...payload
      }
    case ActionTypes.SET_SUMMARY_REQUEST:
      return {
        ...state,
        ...payload
      }
    case ActionTypes.SET_SUMMARY_SUCCESS:
      return {
        ...state,
        ...payload
      }
    case ActionTypes.FINALIZE_SAVE_PORTFOLIO_REQUEST:
      return {
        ...state,
        ...payload
      }
    case ActionTypes.FINALIZE_SAVE_PORTFOLIO_SUCCESS:
      return {
        ...state,
        ...payload
      }
    case ActionTypes.SEARCH_NEW_QUERY_FETCH:
      return {
        ...state,
        ...payload
      }
    case ActionTypes.SEARCH_NEW_QUERY_SUCCESS:
      return {
        ...state,
        ...payload
      }
    case ActionTypes.PORTFOLIO_DATA_FETCH:
      return {
        ...state,
        ...payload
      }
    case ActionTypes.PORTFOLIO_DATA_SUCCESS:
      return {
        ...state,
        ...payload
      }
    case ActionTypes.PRE_SELECTED_THEMATIC_TICKERS_FETCH:
      return {
        ...state,
        ...payload
      }
    case ActionTypes.PRE_SELECTED_THEMATIC_TICKERS_SUCCESS:
      return {
        ...state,
        ...payload
      }
    case ActionTypes.RESET_BUILD_PORTFOLIO_STATE:
      return {
        ...state,
        ...initialState,
        tabHeaderMenu: [
          {
            color: '#1d6091',
            key: 'equityTab',
            label: 'Equity',
            position: 1,
            locked: false,
            showInput: false,
            inputNumberDisabled: true,
            sliderEnabled: false,
            sliderValue: 0,
            sliderTooltipEnabled: false
          },
          {
            color: '#56a9e8',
            key: 'bondTab',
            label: 'Bonds',
            position: 2,
            locked: false,
            showInput: false,
            inputNumberDisabled: true,
            sliderEnabled: false,
            sliderValue: 0,
            sliderTooltipEnabled: false
          },
          {
            color: '#acd6f5',
            key: 'thematicTab',
            label: 'Thematic Exposure',
            position: 3,
            locked: false,
            showInput: false,
            inputNumberDisabled: true,
            sliderEnabled: false,
            sliderValue: 0,
            sliderTooltipEnabled: false
          }
        ],
        oldHeaderMenuState: undefined,
        oldBuildportfolioState: undefined,
        oldBondData: undefined,
        oldEquityData: undefined,
        oldThematicData: undefined
      }
    case ActionTypes.CHECK_BUILDER_TICKER_EXIST_REQUEST:
      return {
        ...state,
        ...payload
      }

    case ActionTypes.CHECK_BUILDER_TICKER_EXIST_FETCH:
      return {
        ...state,
        ...payload
      }
    case ActionTypes.CHECK_BUILDER_TICKER_EXIST_SUCCESS:
      return {
        ...state,
        ...payload
      }
      case ActionTypes.SET_CHART_DATA:
        return {
          ...state,
          resultSection: {
            ...state.resultSection,
            analyticsData: payload.analyticsData
          }
        }
      case ActionTypes.DOWNLOAD_BUILDER_REPORT:
        return {
          ...state,
          ...payload
        }
    default:
      return state
  }
}

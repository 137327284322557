import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Checkbox, Dropdown, Menu } from 'antd';
// import {
//   Dropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem
// } from 'reactstrap';
import {
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import {
  UncontrolledTooltip,
} from 'reactstrap';

const Sort = ({ id, sortFor, asc, desc, hightlight, enableDropdown = true, onChange, ...props }) => {
  // const [ascSort, setAscSort] = useState(false);
  // const [descSort, setDescSort] = useState(false);
  // let dropdownOpenState = false;
  // const toggleDropdown = () => {
  //   dropdownOpenState = !dropdownOpenState;
  // }
	const sortOnChange = (e, type) => {
		if (type === 'asc') {
			if (e.target.checked) {
				// setAscSort(true)
				// setDescSort(false)
				onChange({
					id,
					key: sortFor,
					sortEnabled: true,
					asc: true,
					desc: false,
				});
			} else {
				// setDescSort(false)
				// setAscSort(false)
				onChange({
					id,
					key: sortFor,
					sortEnabled: false,
					asc: false,
					desc: false,
				});
			}
		} else if (type === 'desc') {
			if (e.target.checked) {
				// setDescSort(true)
				// setAscSort(false)
				onChange({
					id,
					key: sortFor,
					sortEnabled: true,
					asc: false,
					desc: true,
				});
			} else {
				// setDescSort(false)
				// setAscSort(false)
				onChange({
					id,
					key: sortFor,
					sortEnabled: false,
					asc: false,
					desc: false,
				});
			}
		}
	}

  const blockStyle = {

  };

  const orderOptions = (
    <Menu className="FRR_sort_dropdown_wrapper">
      <Menu.Item key="asc" className="py-1 FRR_sort_dropdown_menu-item">
        <Checkbox className="FRR_sort_dropdown_menu-item-checkbox" checked={asc} onChange={(e) => sortOnChange(e, 'asc')}>
          {asc ? 'Default' : 'Low to High'}
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="desc" className="py-1 FRR_sort_dropdown_menu-item">
        <Checkbox className="FRR_sort_dropdown_menu-item-checkbox" checked={desc} onChange={(e) => sortOnChange(e, 'desc')}>
          {desc ? 'Default' : 'High to Low'}
        </Checkbox>
      </Menu.Item>
    </Menu>
  );

  return (
		<div id={`${id}HighLightEnable`} className={`FRR-Label ${hightlight[`${id}HighLightEnable`] ? 'highlight' : ''} FRR_space pos-rel`} style={blockStyle}>
      {!enableDropdown ? (
          <>{props.children}</>
        ) : (
          <Dropdown
            overlayClassName="FRR_sort_dropdown_container"
            placement="bottomCenter"
            overlay={orderOptions}
            getPopupContainer={() => document.getElementById(`${id}HighLightEnable`)}
          >
            <div className="text-blue FRR_sort-trigger-wrap">
              {sortFor}
            </div>
          </Dropdown>
        )
      }
      <i id={`${id}_up`} className={`sort-state-icon ${asc ? 'active fas fa-caret-down' : ''} ${desc ? 'active fas fa-caret-up' : ''}`}></i>
      {/*
        <div className="viewDropdownContainer">
          <Dropdown
            className="viewDropdownWrapper"
            isOpen={dropdownOpenState}
            toggle={toggleDropdown}
          >
            <DropdownToggle
              color="link"
              className="h6 text-blue my-1 viewBtn"
              >
              {sortFor} {(asc || desc) && <i id={`${id}_up`} className={`${asc ? 'active fas fa-caret-up' : ''} ${desc ? 'active fas fa-caret-down' : ''}`}></i>}
            </DropdownToggle>
            <DropdownMenu className="rounded-0 h6 viewDropDownList">
              <div className="viewDropDownList-Child">
                <Checkbox className="sort-options-checkbox" checked={asc} onChange={(e) => sortOnChange(e, 'asc')}>
                  <DropdownItem
                    key="Fees; Low to High"
                    className="py-1"
                    >
                    {asc ? 'Fees; Default' : 'Fees; Low to High'}
                  </DropdownItem>
                </Checkbox>
                <Checkbox className="sort-options-checkbox" checked={desc} onChange={(e) => sortOnChange(e, 'desc')}>
                  <DropdownItem
                    key="Fees; High to Low"
                    className="py-1"
                    >
                    {desc ? 'Fees; Default' : 'Fees; High to Low'}
                  </DropdownItem>
                </Checkbox>
              </div>
            </DropdownMenu>
          </Dropdown>
        </div>
      */}

      {/*
        <div style={{ width: 'auto' }}>{sortFor}</div>
        <div className="sort-options-wrap">
          <Checkbox className="sort-options-checkbox" checked={asc} onChange={(e) => sortOnChange(e, 'asc')}>
            <i id={`${id}_up`} className={`${asc ? 'active fas' : 'fal'} fa-caret-up`}></i>
            {asc && (
              <UncontrolledTooltip placement="top" target={`${id}_up`} className="share-component__tooltip">
                {asc ? "Click to cancel sorting" : "Click to sort funds by asc order"}
              </UncontrolledTooltip>
            )}
          </Checkbox>
          <Checkbox className="sort-options-checkbox" checked={desc} onChange={(e) => sortOnChange(e, 'desc')}>
            <i id={`${id}_down`} className={`${desc ? 'active fas' : 'fal'} fa-caret-down `}></i>
            {desc && (
              <UncontrolledTooltip placement="bottom" target={`${id}_down`} className="share-component__tooltip">
                {desc ? "Click to cancel sorting" : "Click to sort funds by desc order"}
              </UncontrolledTooltip>
            )}
          </Checkbox>
        </div>
      */}
		</div>
	)
}

export default Sort;

import styled from 'styled-components/macro'

export const SearchInputWrapper = styled.form`
  position: relative;
  width: ${props => (props.isLandingPage ? '890px' : '100%')};
  @media (max-width: 940px) {
    width: 100%;
  }
  display: block;
  font-family: Roboto;
  // display: flex;
  // align-items: center;

  .glyphicon {
    color: #66b3ea;
    position: absolute;
    left: ${props => (props.isLandingPage ? '12px' : '5px')} ;
    top: 50%;
    z-index: 5;
    transform: translateY(-50%) scale(-0.75) rotate(-90deg);
  }

  .close-icon-section {
    position: absolute;
    bottom: unset;
    margin: auto;
    top: 11px;
    @media (max-width: 1024px) {
      top: 12px !important
    } 
    @media (max-width: 991px) {
      top: 15px !important
    }
    right: 55px;
    line-height: 10px;
    i {
      position: unset;
      right: unset;
    }
    
    @media (max-width: 1024px) {
      i {
        font-size: 15px !important;
      }
    }
    @media (max-width: 768px) {
      i {
        font-size: 13px !important;
      }
    }
  }

  .save-icon-section {
    position: absolute;
    top: 33%;
    @media (max-width: 768px){
      top: 35% !important;
    }
    height: 14px;
    line-height: 10px;
    right: 30px;
    //cursor: pointer
    @media (max-width: 1024px) {
      img {
        height: 14px !important;
        width: 14px !important;
      }
    }
    @media (max-width: 768px) {
      img {
        height: 13px !important;
        width: 13px !important;
      }
    }
  }

  .add-icon-section {
    position: absolute;
    top: 32%;
    @media (max-width: 768px){
      top: 34% !important;
    }
    height: 14px;
    line-height: 10px;
    right: 33px;
    cursor: pointer
  }

  @media (max-width: 768px) {
    .save-list-ext-padd{
      top: 8px !important;
    }
  }

  .save-list-section {
    position: absolute;
    top: 8px;
    right: 0;
    padding: 0;
    line-height: 10px;

    .button-wrapper {
      margin-right: 5px;
      padding: 4px;
      border-radius: 50%;
      border: unset;
      box-shadow: none;
      border-radius: 50%;
      background-color: transparent;
    }
    @media (max-width: 768px) {
      .button-wrapper {
        margin-right: 5px;
        padding: 4px 4px 5px 4px;
        border-radius: 50%;
        border: unset;
        box-shadow: none;
        border-radius: 50%;
        background-color: transparent;
      }
    }
  }

  .save-list-btn-hide {
    border: unset;
    border-color: unset;
    box-shadow: unset;
    border-radius: 50%;
    background-color: transparent;

    .button-wrapper {
      background-color: transparent;
    }
  }

  .save-list-btn {
    padding: 2px 0px 0px 0px;
    border: unset;
    box-shadow: none;
    border-radius: 50%;
    background-color: transparent;
    .button-wrapper {
      background-color: #E5E5E5;
    }
  }

  span {
    border-radius: 2em;
  }
  button.hidden___btn {
    display: none;
  }
  .react-autosuggest__container {
    position: relative;
    width: 100%;
  }
  .react-autosuggest__input {
    width: 100%;
    padding: 10px 40px 10px 20px;
    font-family: Roboto;
    font-weight: 400;
    font-size: 18px;
    border: none;
    border-radius: 25px;
    background-color: ${props => (props.isLandingPage ? '#f5f7f9' : '#fff')};
    color: ${props => (props.isLandingPage ? '#66b3ea !important' : '#000')};
  }

  .react-autosuggest__input--focused {
    outline: none;
  }
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0px -2px 6px 0px rgb(0 0 0 / 5%);
    background-color: #fff;
  }
  .react-autosuggest__suggestions-container {
    display: none;
  }
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top:40px;
    width: 100%;
    border: none;
    background-color: #fff;
    font-family: Roboto;
    font-weight: 300;
    font-size: 16px;
    border-top: 1px solid #727272;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0px 3px 3px 1.5px rgb(0 0 0 / 5%);
    max-height: 420px;
    overflow-y: scroll;
    overflow-x: hidden;
    overflow-y: overlay;
    scroll-behavior: smooth;
    z-index: 10;
    text-align: left;

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 20px;
      margin-bottom: 16px;
    }
    &::-webkit-scrollbar-thumb {
      background: #2c70a5;
      border-radius: 20px;
    }

    ::-webkit-scrollbar {
      width: 0 !important;
    }
    scrollbar-width: none;
    &:hover {
      scrollbar-width: thin;
      ::-webkit-scrollbar {
        width: 0.8vh !important;
      }
    }
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 0px 20px;
    line-height: 1.57;

    .suggestion-row{
      font-family: Roboto;
      color: #4f4f4f;
      font-size: 13px;
      font-weight: 400;
      padding-bottom: 2px;
      display: inline-flex

      .ellipsis-content{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-top: 3px;  
      }

      @media (max-width: 768px) {
        font-size: 12px;
      }
      strong {
        font-weight: 800;
      }
    }

    .suggestion-row:last-child{
      //padding-bottom: 20px;
    }
   
    // span {
    //   font-family: Roboto;
    //   color: #4f4f4f;
    //   font-size: 13px;
    //   font-weight: 400;
    //   @media (max-width: 768px) {
    //     font-size: 12px;
    //   }
    //   strong {
    //     font-weight: 800;
    //   }
    // }
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #f5f7f9;
  }

  .react-autosuggest__section-container {
    border-top: 1px solid #cbcbcb;
    ul:last-child{
      padding-bottom: 10px;
    }
  }

  .react-autosuggest__section-container--first {
    border-top: 0;
  }

  .react-autosuggest__section-title {
    padding: 10px 0 0 20px;
    span {
      font-size: 17px;
      color: #66b3ea;
      font-weight: 600;
      font-family: Roboto;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
`

export const Typer = styled.p`
  @media (min-width: 320px) and (max-width: 575px) {
    font-size: 16px !important;
    line-height: 1;
    width: max-content !important;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;
    margin: 0 !important;
    margin-left: 40px !important;
    left: 0;
  }
    border-right: solid 1px rgba(0, 255, 0, 0.75);
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    margin: 0 20px !important;
    margin-left: 42px !important;
    font-weight: normal;
    color: #66b3ea !important;
    font-size: 18px;
    cursor: initial;

    animation: animated-text-search 1s steps(35, end) 1s 1 normal both,
    animated-cursor 800ms steps(35, end) infinite;

  @keyframes animated-text-search {
    from {
      width: 0;
    }
    to {
      width: 244px;
    }
  }

  @keyframes animated-cursor {
    from {
      border-right-color: #1E5F91;
    }
    to {
      border-right-color: transparent;
    }
  }
`

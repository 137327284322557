import ActionTypes from '../action-types'
import { areIntervalsOverlapping } from 'date-fns';

const initialState = {
  loading: false,
  valid: false,
  query: '',
  query_english: '',
  downloadQuery: '',
  replacePortfolioToggle: true,
  replaceAllPortfolioToggle: false,
  enhanceReplace: [],
  generateProposalEnhanceReplace: [],
  enhanceTickerFilter: [],
  filterResultSet: [],
  resultSet: [],
  onBoardScreen: true,
  enhancementData: [],
  enhance_transaction:[],
  summaryDrawer: false,
  enhancedPortfolioName: '',
  enhancedPortfolioId: '',
  portfolioCheck: false,
  portfolioSave: false,
  portfolioSaveError: '',
  allTickers:[],
  customizedCardData:[],
  caching: {},
  enhanceCTA: 'Enhance',
  currentPerformanceImpact: {},
  enhancedPerformanceImpact: {},
  enhancePortfolioVisible: false,
  enhancePortfolio: {
    myCurrentPortfolio: {
      portfolioDetails: {
        name: '',
      },
      holdings: [],
    },
    replacePortfolioOptions: [],
    replacePortfolioWith: {},
  },
  hideGenerateProposalBtn: false,
  tickerNotExist: [],
  shareClass: '', //'A Class',
  header2Disable: false,
  similarityMeasures: 'overlap',
  fileName: '',
  modelEnhancement:{
    current: {},
    enhanced: {},
    otherEnhancements: [],
  },
  searchTypeMode: '',
  guidanceMessage: [],
  selectedWeightOptions: {
    name: 'Passive Fund Selector',
    value: 'passive fund selector',
    code: 'passive_fund_selector',
  },
};

const definedState = {
  ...initialState,
  enableEnhancePortfolioQueries: false,
};

export function portfolio(state = definedState, action) {
  const { enhanceReplace, customizedCardData } = state;
  const { payload } = action;
  switch (action.type) {
    case ActionTypes.RESET_CACHING_FOR_PORTFOLIO:
      console.log('------ RESET CACHING FOR PORTFOLIO ------');
      console.log(state);
      return {
        ...state,
        caching: {},
      }
    case ActionTypes.SET_CACHING_FOR_PORTFOLIO:
      const CACHE_STATE = { ...state };
      console.log('------ CACHING FOR PORTFOLIO ------');
      if (payload && typeof payload.enhanceReplace !== 'undefined') {
        console.log('----payload present----');
        console.log(payload.enhanceReplace);
        CACHE_STATE.enhanceReplace = payload.enhanceReplace;
      }
      console.log(CACHE_STATE);
      console.log('-----------------------------------');
      return {
        ...state,
        caching: CACHE_STATE,
      }
    case ActionTypes.SET_DEFAULT_PORTFOLIO:
      return {
        ...initialState,
      }
    case ActionTypes.SET_ENHANCEMENT_DATA:
      return {
        ...state,
        enhancementData: payload.enhancementData,
      }
    case ActionTypes.DISABLE_ENHANCE_BTN_CONTROL:
      return {
        ...state,
        enableEnhanceBtnControl: false,
      }
    case ActionTypes.ENABLE_ENHANCE_BTN_CONTROL:
      return {
        ...state,
        enableEnhanceBtnControl: true,
      }
    case ActionTypes.SET_PORTFOLIO_STATE:
      return {
        ...state,
        ...payload,
      }
    case ActionTypes.PORTFOLIO_QUERY:
      return {
        ...state,
        loading: true,
        valid: false,
      }

    case ActionTypes.PORTFOLIO_FETCH_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        valid: true,
      }

    case ActionTypes.SET_PORTFOLIO_REPLACE:
      return {
        ...state,
        enhanceReplace: [...payload],
      }

    case ActionTypes.SAVE_ENHANCED_TICKER:
      return {
        ...state,
        enhanceTickerFilter: [...payload],
      }

    case ActionTypes.SAVE_ENHANCED_TRANSACTIONS:
      return {
        ...state,
        enhance_transaction: payload,
      }

    case ActionTypes.SET_REPLACE_PORTFOLIO_TOGGLE:
      return {
        ...state,
        replacePortfolioToggle: payload,
      }

    case ActionTypes.ADD_PORTFOLIO_ENHANCE_REPLACE:
      const { replaceTicker, ticker, query, selectedPortfolioFunds } = payload

      const index = enhanceReplace.findIndex(x => x.ticker === ticker);
      if (index >= 0) {
        enhanceReplace[index]['replace'] = replaceTicker;
        enhanceReplace[index]['query'] = query;
        enhanceReplace[index]['selectedPortfolioFunds'] = selectedPortfolioFunds;
      } else {
        enhanceReplace.push({ ticker, replace: replaceTicker, query, selectedPortfolioFunds });
      }
      return {
        ...state,
        enhanceReplace: [...enhanceReplace],
      }

    case ActionTypes.DELETE_PORTFOLIO_ENHANCE_REPLACE:
      const { enhanceReplace : deleteEnhanceRepalce } = state;
      const { ticker: deleteTicker } = payload

      const index1 = deleteEnhanceRepalce.findIndex(x => x.ticker === deleteTicker);
      if (index1 >= 0) {
        console.log('found ticker to delete');
        deleteEnhanceRepalce.splice(index1, 1)
      }
      return {
        ...state,
        enhanceReplace: [...deleteEnhanceRepalce],
      }

    case ActionTypes.SET_REPLACE_TICKER:
      return {
        ...state,
        cardloading: true,
      }

    case ActionTypes.SAVE_REPLACE_TICKER:
      return {
        ...state,
        allTickers: payload,
        cardloading: false,
      }
    case ActionTypes.SAVE_ENHANCED_CARD:
      const { overlap, fundsData } = payload;

      customizedCardData.push({ ticker:overlap, fundsData: fundsData[0]  });

      return {
        ...state,
        //customizedCardData: [...state.customizedCardData, payload]
        customizedCardData: [...customizedCardData]
      }

    case ActionTypes.DELETE_CUSTOM_REPLACE_TICKER:
      const { ticker: Ticker } = payload;
      const indexForEnhanceStateArray = enhanceReplace.findIndex(x => x.ticker === Ticker);
      const indexForCustomReplaceStateArray = customizedCardData.findIndex(x => x.ticker === Ticker);
      console.log(indexForCustomReplaceStateArray);

      if (indexForEnhanceStateArray >= 0) {
        enhanceReplace.splice(indexForEnhanceStateArray, 1)
      }

      if (indexForCustomReplaceStateArray >= 0) {
        customizedCardData.splice(indexForCustomReplaceStateArray, 1)
      }

      return {
        ...state,
        customizedCardData: [...customizedCardData],
        enhanceReplace: [...enhanceReplace],
      }

    case ActionTypes.SET_ENHANCE_PORTFOLIO_VISIBLE:
      return {
        ...state,
        ...payload,
      }

    case ActionTypes.GET_MY_CURRENT_PORTFOLIO_FETCH:
      return {
        ...state,
        ...payload,
      }
    case ActionTypes.GET_MY_CURRENT_PORTFOLIO_SUCCESS:
      return {
        ...state,
        ...payload,
      }

    case ActionTypes.GET_REPLACE_PORTFOLIO_OPTIONS_FETCH:
      return {
        ...state,
        ...payload,
      }
    case ActionTypes.GET_REPLACE_PORTFOLIO_OPTIONS_SUCCESS:
      return {
        ...state,
        ...payload,
      }
    case ActionTypes.FINALIZE_REPLACE_MODEL_PORTFOLIO_FETCH:
      return {
        ...state,
        ...payload,
      }
    case ActionTypes.FINALIZE_REPLACE_MODEL_PORTFOLIO_SUCCESS:
      return {
        ...state,
        ...payload,
      }
    case ActionTypes.REPLACE_CURRENT_MODEL_PORTFOLIO_WITH:
      return {
        ...state,
        enhancePortfolio: {
          ...state.enhancePortfolio,
          replacePortfolioWith: payload.replacePortfolioWith,
        },
      }
    case ActionTypes.RESET_MODEL_ENHANCE_PORTFOLIOS_STATE:
      return {
        ...state,
        enhancePortfolioVisible: false,
        enhancePortfolio: {
          myCurrentPortfolio: {
            portfolioDetails: {
              name: '',
            },
            holdings: [],
          },
          replacePortfolioOptions: [],
          replacePortfolioWith: {},
        },
        hideGenerateProposalBtn: false,
      }

    case ActionTypes.RESET_FUNDS_ENHANCE_PORTFOLIOS_STATE:
      return {
        ...state,
        query: '',
        query_english: '',
        downloadQuery: '',
        enhanceReplace: [],
        generateProposalEnhanceReplace: [],
        enhanceTickerFilter: [],
        filterResultSet: [],
        resultSet: [],
      }

    case ActionTypes.RESET_PORTFOLIO_STATE:
      return {
        ...state,
        ...initialState,
      }

      case ActionTypes.CHECK_TICKER_EXIST_REQUEST:
        return {
          ...state,
          ...payload,
        }

      case ActionTypes.CHECK_TICKER_EXIST_FETCH:
        return {
          ...state,
          ...payload,
        }
      case ActionTypes.CHECK_TICKER_EXIST_SUCCESS:
        return {
          ...state,
          ...payload,
        }
      case ActionTypes.SET_MODEL_ENHANCEMENT_DATA:
        return {
          ...state,
          modelEnhancement: payload,
        }

    default:
      return state
  }
}

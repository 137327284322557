import { takeEvery, fork, call, put, all, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { push } from 'connected-react-router';
import get from 'lodash/get';

import ActionTypes from 'action-types';
import * as actions from 'actions/quicktrade';
import * as sfmActions from 'actions/sfm';

import * as brokerAPI from 'apis/broker';
import * as discoveryAPI from 'apis/discovery';

import {
  addEventToAnalytics,
  clearQuickTrade,
} from 'Utils';
import Emitter from 'realTimePrice/emitter';
import { apexPageState, numberCheck } from 'layouts/utils';

// V-2
function* addQuickTradeTicker(action) {
  try {
    const { payload } = action;
    if (payload) {
      yield put(actions.addTickersToQuickTradeFetch())

      let {
        ticker,
        price,
        isSponsored,
        sponsorLogo,
        name,
        category,
        minInvestment,
        vehicle,
      } = payload;

      if (typeof ticker === 'undefined' ||
        typeof price === 'undefined' ||
        typeof isSponsored === 'undefined' ||
        typeof sponsorLogo === 'undefined' ||
        typeof name === 'undefined' ||
        typeof category === 'undefined' ||
        typeof minInvestment === 'undefined' ||
        typeof vehicle === 'undefined'
      ) throw '-- Payload missing --';

      // NOTE: ======= creating socket connection =======
      // console.log(ticker, typeof ticker);
      let _tickers = [];
      if (typeof ticker === 'string') {
        _tickers.push(ticker);
      } else if (typeof ticker === 'array') {
        _tickers = [...ticker]
      }

      // removed below "ANKIT" if not testing
      // ticker.push("ANKIT");
      // console.log('=== ticker ===', _tickers, typeof _tickers);
      Emitter.emit('SUBSCRIBE_TO_STREAM', _tickers);
      // console.log('Subscribed');
      // NOTE: ======= created connection =======

      // minimum investment is $5
      // let _minInvestment = ((typeof minInvestment !== 'undefined' &&
      //                       minInvestment !== null) &&
      //                       !isNaN(minInvestment) &&
      //                       !isNaN( parseFloat(minInvestment).toFixed(5) ) && minInvestment > 5) ? Number(parseFloat(minInvestment).toFixed(5)) : 5;
      let _minInvestment = numberCheck(minInvestment, 5) || 5; // minimum investment is $5

      // let _price = ((typeof price !== 'undefined' &&
      //                       price !== null) &&
      //                       !isNaN(price) &&
      //                       !isNaN( parseFloat(price).toFixed(5) )) ? Number(parseFloat(price).toFixed(5)) : 0;
      let _price = numberCheck(price, 5);

      // if minInvestment is 0 || null then -> check _price with $5
      if (!numberCheck(minInvestment) && _price < _minInvestment) {
        _minInvestment = _price;
        // console.log('====== _minInvestment ======> ', _minInvestment);
      }

      const funds = [{
        ...payload,
        name,
        ticker,
        category,
        isSponsored,
        price: _price,
        sponsor: sponsorLogo,
        minInvestment: _minInvestment,
        _checkedToExecute: true,
      }];

      yield put(actions.addTickersToQuickTradeSuccess({
        funds,
        modalToggle: true,
      }));

    } else {
      throw '-- Payload missing --'
    }
  } catch (error) {
    const errorDetails = get(error, 'response.data', error.stack)
    console.log(errorDetails)
    if(errorDetails){
      yield put(actions.addTickersToQuickTradeSuccess({
        pageCrash: errorDetails,
      }));
    } else {
      yield put(actions.addTickersToQuickTradeSuccess({
        pageCrash: '-- Failed to process --',
      }));
    }
  }
}

function* removeQuickTradeTicker(action) {
  try {
    const { payload, callback } = action;
    if (payload && payload.removeTicker) {
      yield put(actions.removeQuickTradeTickersFetch());

      // NOTE: UNSUBSCRIBE_STREAM or disconnect socket for deselected tickers
      // console.log('=== payload.removeTicker ===', [payload.removeTicker]);
      if (payload.removeTicker) {
        Emitter.emit('UNSUBSCRIBE_STREAM', [payload.removeTicker]);
      }

      yield put(actions.removeQuickTradeTickersSuccess({
        removeTicker: payload.removeTicker,
      }));

      // let quickTradeState = yield select(state => state.quicktrade);
      // if (quickTradeState && quickTradeState.funds && quickTradeState.funds.length === 0) {
      //   clearQuickTrade();
      //   let qq = window.sessionStorage.getItem('query');
      //   let _location = '/securities' + (qq ? `?query=${encodeURIComponent(qq)}` : '');
      //   console.log(_location);
      //   yield put(push(_location));
      // }

      if (callback && typeof callback === 'function') {
        let quickTradeState = yield select(state => state.quicktrade);
        callback(quickTradeState);
      }

    } else {
      throw '-- Payload missing --'
    }
  } catch (error) {
    console.log(error);
    yield put(actions.removeQuickTradeTickersSuccess({
      pageCrash: '-- Failed to process --',
    }));
  }
}

function* deleteAllQuickTrades(action) {
  try {
    const { payload, callback } = action;
    yield put(actions.deleteAllQuickTradeTickersFetch());
    // yield delay(700)

    // NOTE: UNSUBSCRIBE_STREAM or disconnect socket for all tickers
    const ___SELECTED_ITEM_TICKERS = yield select(state => state.sfm.selectedItemTickers);
    const unsubscribeTickers = ___SELECTED_ITEM_TICKERS.map((h) => h.ticker)
    // console.log('===== unsubscribeTickers =====', unsubscribeTickers);
    if (unsubscribeTickers) {
      Emitter.emit('UNSUBSCRIBE_STREAM', unsubscribeTickers);
    }

    // yield put(fundActions.removeAllFundsSelectorState());
    // yield put(accountsActions.removeAllFundsSelectorState());
    yield put(actions.deleteAllQuickTradeTickersSuccess(payload));

    // let quickTradeState = yield select(state => state.quicktrade);
    // if (quickTradeState && quickTradeState.funds && quickTradeState.funds.length === 0) {
    //   clearQuickTrade();
    //   let qq = window.sessionStorage.getItem('query');
    //   let _location = '/securities' + (qq ? `?query=${encodeURIComponent(qq)}` : '');
    //   console.log(_location);
    //   yield put(push(_location));
    // }

    if (callback && typeof callback === 'function') {
      let quickTradeState = yield select(state => state.quicktrade);
      callback(quickTradeState);
    }
  } catch (error) {
    console.log(error);
    yield put(actions.deleteAllQuickTradeTickersSuccess({
      pageCrash: '-- Failed to process --',
    }));
  }
}

function* quickTradeToSFModal(action) {
  try {
    const { payload, callback } = action;
    yield put(actions.quickTradeToSFMFetch());
    // yield delay(700)

    // NOTE: UNSUBSCRIBE_STREAM or disconnect socket for all tickers
    const SELECTED_QUICK_TRADE_FUNDS = yield select(state => state.quicktrade.funds);
    const SELECTED_QUICK_TRADE_TICKERS = SELECTED_QUICK_TRADE_FUNDS.map((kl) => kl.ticker);

    // console.log('==== SELECTED_QUICK_TRADE_TICKERS ====', SELECTED_QUICK_TRADE_TICKERS);

    if (SELECTED_QUICK_TRADE_TICKERS && SELECTED_QUICK_TRADE_TICKERS.length) {
      const unsubscribeTickers = SELECTED_QUICK_TRADE_TICKERS.map((h) => h)
      // console.log('===== unsubscribeTickers =====', unsubscribeTickers);
      if (unsubscribeTickers) {
        Emitter.emit('UNSUBSCRIBE_STREAM', unsubscribeTickers);
      }
      yield put(sfmActions.addSFMItemTickersRequest({
        selectedItemTickers: SELECTED_QUICK_TRADE_TICKERS[0],
      }));
    }

    yield delay(700)
    yield put(actions.quickTradeToSFMSuccess());

    if (callback && typeof callback === 'function') {
      let quickTradeState = yield select(state => state.quicktrade);
      callback(quickTradeState);
    }
  } catch (error) {
    console.log(error);
    yield put(actions.quickTradeToSFMSuccess({
      pageCrash: '-- Failed to process --',
    }));
  }
}

function* fetchBrokers(action) {
  try {
    const { payload, callback } = action;
    yield put(actions.getBrokerDetailsFetch());

    let profileState = yield select(state => state.profile);
    let { profile } = profileState;
    let APEX_PAGE_STATE = apexPageState({ profile });

    if (APEX_PAGE_STATE && APEX_PAGE_STATE !== null && process.env.REACT_APP_SUB_DOMAIN === 'retail') {
      yield put(actions.getBrokerDetailsSuccess({
        broker: APEX_PAGE_STATE,
      }));
    } else {
      yield put(actions.getBrokerDetailsSuccess({
        broker: {},
      }));
    }

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(actions.getBrokerDetailsSuccess({
      broker: {},
      pageCrash: '-- Failed to process --',
    }));
  }
}

function* executeMagnifiBrokerOrders(action) {
  const { payload, callback } = action;
  // console.log(payload)
  // console.log(callback);
  try{
    if (payload && payload.data) {
      yield put(actions.executeQuickTradeFetch());
      const response = yield call(discoveryAPI.executeTradeOrder, payload)
      // console.log(response);
      if(response) {
        yield put(sfmActions.executeMagnifiOrderSuccess({
          sfmTradeOrderResponse: {
            response,
            payload,
            error: false,
          },
        }));
        // yield put(actions.deleteAllQuickTradeTickersRequest());
        yield put(actions.executeQuickTradeSuccess(response));
      } else {
        yield put(sfmActions.executeMagnifiOrderSuccess({
          sfmTradeOrderResponse: {
            response,
            payload,
            error: true
          }
        }));
        yield put(actions.executeQuickTradeSuccess());
      }

      yield put(actions.deleteAllQuickTradeTickersRequest());
      yield delay(700);
      if (callback && typeof callback === 'function') {
        let quickTradeState = yield select(state => state.quicktrade);
        callback(quickTradeState);
      }

    } else {
      throw '-- Payload missing --'
    }
  } catch (error) {
    const errorDetails = get(error, 'response.data', error.stack)
    if(errorDetails) {
      console.log(errorDetails)
      yield put(sfmActions.executeMagnifiOrderSuccess({
        sfmTradeOrderResponse: {
          response: errorDetails,
          payload: payload.data,
          error: true,
        },
        pageCrash: errorDetails,
      }));
      yield put(actions.executeQuickTradeSuccess({
        pageCrash: errorDetails,
      }));
    } else {
      console.log(error)
      yield put(sfmActions.executeMagnifiOrderSuccess({
        sfmTradeOrderResponse: {
          response: error,
          payload: payload.data,
          error: true,
        },
        pageCrash: '-- Failed to process --',
      }));
      yield put(actions.executeQuickTradeSuccess({
        pageCrash: '-- Failed to process --',
      }));
    }

    yield put(actions.deleteAllQuickTradeTickersRequest());
    yield delay(700);
    if (callback && typeof callback === 'function') {
      let quickTradeState = yield select(state => state.quicktrade);
      callback(quickTradeState);
    }
  }
}

function* quickTradeSaga() {
  yield all([
    fork(takeEvery, ActionTypes.ADD_TICKERS_TO_QUICK_TRADE_REQUEST, addQuickTradeTicker),
    fork(takeEvery, ActionTypes.REMOVE_QUICK_TRADE_TICKERS_REQUEST, removeQuickTradeTicker),
    fork(takeEvery, ActionTypes.DELETE_ALL_QUICK_TRADE_TICKERS_REQUEST, deleteAllQuickTrades),
    fork(takeEvery, ActionTypes.QUICK_TRADE_TO_SFM_REQUEST, quickTradeToSFModal),
    fork(takeEvery, ActionTypes.GET_BROKER_DETAILS_REQUEST, fetchBrokers),
    fork(takeEvery, ActionTypes.EXECUTE_QUICK_TRADE_ORDERS_REQUEST, executeMagnifiBrokerOrders),

  ])
}

export default quickTradeSaga;

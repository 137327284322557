import React, { createElement } from 'react';
import * as pathToRegexp from 'path-to-regexp';
import { getMenuData } from './routermenu';

import AccountsPage from 'layouts/WebDashboard/AccountsPage';
import OnBoardScreen from './Pages/OnBoardScreen';
import Dashboard from './Pages/Dashboard';
import Summary from './Pages/Summary';
import OpenSummaryPage from './Pages/OpenSummaryPage'
import Funds from './Pages/Funds';
import Models from './Pages/Models';

import Exception400 from './Pages/Exception/400';
import Exception403 from './Pages/Exception/403';
import Exception404 from './Pages/Exception/404';
import Exception500 from './Pages/Exception/500';
import ExceptionNoTrade from './Pages/Exception/NoTrade'

import Create from './Pages/Apex/Create';
import UnderVerification from './Pages/Apex/UnderVerification';
import EmptyResult from './Pages/Apex/EmptyResult';

// {"nav": [
// {
//   "label": "HOME",
//   "route": {
//     "url": "/",
//     "exact": true
//   },
//   "component": {
//     "path": "home",
//     "name": "home"
//   }
// }}
// createRoutes() {
//     return this.props.data.nav.map((item: any, index: number) => {
//         let DynamicComponent = require('../../' + item.component.path + '/' + item.component.name).default;
//         return <Route key={index} path={item.route.url}
//                       exact={item.route.exact}
//                       render={() => (<DynamicComponent key={item.component.name}/>)}/>
//     });
// }

function getFlatMenuData(menus) {
  let keys = {};
  menus.forEach((item) => {
    if (item.children) {
      keys[item.path] = { ...item };
      keys = { ...keys, ...getFlatMenuData(item.children) };
    } else {
      keys[item.path] = { ...item };
    }
  });
  return keys;
}

export const getRouterData = () => {
  const routerConfig = {
    '/accounts': {
      component: AccountsPage,
      authority: 'user',
      protected: true,
    },
    '/accounts/apex/create': {
      component: Create,
    },
    '/accounts/apex/under-verification': {
      component: UnderVerification,
    },
    '/accounts/apex/empty-result': {
      component: EmptyResult,
    },
    '/accounts/onboard-screen': {
      component: OnBoardScreen,
      authority: 'user',
      protected: true,
    },
    '/accounts/dashboard/': {
      component: Dashboard,
      authority: 'user',
      protected: false,
    },
    '/accounts/summary': {
      component: OpenSummaryPage,
      authority: 'user',
      protected: false,
    },
    '/accounts/dashboard/summary': {
      component: Summary,
      authority: 'user',
      protected: false,
    },
    '/accounts/dashboard/funds': {
      component: Funds,
      authority: 'user',
      protected: false,
    },
    '/accounts/dashboard/models': {
      component: Models,
      authority: 'user',
      protected: false,
    },
    '/accounts/exception/400': {
      component: Exception400,
    },
    '/accounts/exception/403': {
      component: Exception403,
    },
    '/accounts/exception/404': {
      component: Exception404,
    },
    '/accounts/exception/500': {
      component: Exception500,
    },
    '/accounts/exception/no-trade': {
      component: ExceptionNoTrade,
    },
  };
  // Get name from ./menu.js or just set it in the router data.
  const menuData = getFlatMenuData(getMenuData());
  // Route configuration data
  // eg. {name,authority ...routerConfig }
  const routerData = {};
  // The route matches the menu
  Object.keys(routerConfig).forEach((path) => {
    // Regular match item name
    // eg.  router /user/:id === /user/chen
    const pathRegexp = pathToRegexp(path);
    const menuKey = Object.keys(menuData).find(key => pathRegexp.test(`${key}`));
    let menuItem = {};
    // If menuKey is not empty
    if (menuKey) {
      menuItem = menuData[menuKey];
    }
    let router = routerConfig[path];
    // If you need to configure complex parameter routing,
    // https://github.com/ant-design/ant-design-pro-site/blob/master/docs/router-and-nav.md#%E5%B8%A6%E5%8F%82%E6%95%B0%E7%9A%84%E8%B7%AF%E7%94%B1%E8%8F%9C%E5%8D%95
    // eg . /list/:type/user/info/:id
    router = {
      ...router,
      name: router.name || menuItem.name,
      authority: router.authority || menuItem.authority,
      protected: router.protected || menuItem.protected,
    };
    routerData[path] = router;
  });
  // console.log(routerData);
  return routerData;
};

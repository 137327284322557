import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { UncontrolledPopover, PopoverBody } from 'reactstrap'

import * as AuthUtils from 'common/auth-utils'

const classNames = require('classnames')

export class UserMenuPopover extends Component {
  static propType = {
    onLogout: PropTypes.func.isRequired
  }

  state = { toggleUserMenu: false }

  componentDidMount() {
    // document.addEventListener(
    //   'click',
    //   this.handleDocumentClick.bind(this),
    //   false
    // )
  }

  componentWillUnmount() {
    // document.removeEventListener(
    //   'click',
    //   this.handleDocumentClick.bind(this),
    //   false
    // )
  }

  handleDocumentClick(e) {
    if (this.popoverRef && this.popoverRef.contains(e.target)) {
      this.setState({
        toggleUserMenu: false
      })
    }
  }

  handleUserMenuToggle = () => {
    this.setState(prevState => ({
      toggleUserMenu: !prevState.toggleUserMenu
    }))
  }

  render() {
    const { toggleUserMenu } = this.state
    console.log(toggleUserMenu, 'toggleUserMenu')
    const { firstName = '', lastName = '' } = AuthUtils.getAuthToken()
    const userName = `${firstName} ${lastName}`
    const shortName = `${firstName && firstName[0]}${firstName && lastName[0]}`

    return (
      <UncontrolledPopover
        offset={50}
        isOpen={toggleUserMenu}
        toggle={this.handleUserMenuToggle}
        placement="top-end"
        trigger={window.innerWidth > 1200 ? 'hover' : 'legacy'}
        target="user-popover"
        className={classNames('user-popover', this.props.className)}
      >
        <PopoverBody>
          {window.innerWidth > 1200 ? 'hover' : 'click'}
          <ul ref={node => (this.popoverRef = node)} className="user-menu">
            <li className="user-menu__item user-menu__item--user-info">
              <span className="user-menu__name-badge">
                {shortName && shortName.toUpperCase()}
              </span>
              <span className="user-menu__user-name">{userName}</span>
            </li>

            <li className="user-menu__item">
              <Link to="/investments" className="user-menu__link">
                <i className="far fa-fw fa-hand-holding-usd mr-2" />
                Account
              </Link>
            </li>
            <li className="user-menu__item">
              <Link to="/settings" className="user-menu__link">
                <i className="far fa-fw fa-cog mr-2" />
                Settings
              </Link>
            </li>
            <li className="user-menu__item">
              <Link
                to="#"
                className="user-menu__link"
                onClick={this.props.onLogout}
              >
                <i className="far fa-fw fa-sign-out-alt mr-2" />
                Sign out
              </Link>
            </li>
          </ul>
        </PopoverBody>
      </UncontrolledPopover>
    )
  }
}

import React from 'react';
import _ from 'lodash';
import { Button, Card, Skeleton } from 'antd';
import { Loader } from '../Loader';

export default ({ active, style, className, minHeight = 'calc(98vh - 224px)', skeleton = 3, children }) => {
 const sty = { ...style, minHeight };
  return (
    <Card className={`no-funds-found-wrapper ${className}`} style={sty}>
      {children}
    </Card>
  )
};

import React from 'react';
import _ from 'lodash';
// import { Row, Col, Table } from 'reactstrap'
// import PortfolioContext from '../PortfolioContext'

import { withHeaderFooter } from './HeaderFooter'

const Appendix = withHeaderFooter((props) => {
  let holdingsData = _.orderBy(props.data, 'weight', 'desc');
  return (
    <React.Fragment>
      <div className="content">
        <div>
          <div className="pr-v2-header">
            <h3>Appendix</h3>
          </div>
          <Holdings
            index={props.index}
            data={holdingsData}
          />
        </div>
      </div>
    </React.Fragment>
  )
})

export default Appendix

export const Holdings = ({ data, index }) => {
  let arrIndex = 35*index;
  return (
    <div className="result-section-content-wrapper holdings-section">
      <div className="list-container">
        <table className={`appendix`}>
          <tbody>
            <tr className={`head`}>
              <td className={`serial`}>#</td>
              <td className={`ticker`}>Ticker</td>
              <td className={`fund`}>Fund Name</td>
              <td className={`weight`}>Weight</td>
            </tr>
            {data.map((item, index) => (
              <tr key={`i-${index + arrIndex}`} className="list-body-content-row" style={{ background: `#E0E0E0` }}>
                <td className={`serial`}>{index+1+arrIndex}</td>
                <td className={`ticker`}>{item.ticker ? item.ticker : '--'}</td>
                <td className={`fund`}>{item.name ? item.name : item.short_name ? item.short_name : '--'}</td>
                <td className={`weight`}>{item.weight ? `${parseFloat(item.weight.toFixed(1))}%` : '--'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

import React, { useContext } from 'react'
import _ from 'lodash'
import { Row, Col } from 'reactstrap'

import { withHeaderFooter } from './HeaderFooter'
// import { std } from '../../BuildPortfolio/Pages/BuildFromScratch/utils'
// import { BarChartWithAxis, SimpleLineChartWithAxis } from '../../BuildPortfolio/Elements/Charts'
import PortfolioLineChart from './PortfolioLineChart'
import { CustomBarChart } from './BarChart'

import PortfolioContext from '../PortfolioContext'

import { getDrawdownByYears, getAnnualizedReturnsByYears, getAnnualizedVolatilityByYears } from '../../AccountsPage/utils';
import { numberCheck } from 'layouts/utils';

const Performance = withHeaderFooter((props) => {
  console.log('performance props --> ', props);
  // let EplusTRation = props.ratioString.split('/')[0]
  // let bondsRation = 100 - (parseInt(EplusTRation, 10))
  // // console.log(EplusTRation, bondsRation)
  // let chartdata = props.data[props.defaultYears]
  // let currentReturnsLegend = props.data.anRet3yr;
  // let dataForVolandD = [
  //   {
  //     "name": "Volatility*",
  //     "current": parseFloat(props.data.anVol3yr),
  //     "currentColor": "#56a9e8",
  //     "enhancedColor": "#1e5f91"
  //   },
  //   {
  //     "name": "Drawdown*",
  //     "current": parseFloat(props.data.dMax),
  //     "currentColor": "#56a9e8",
  //     "enhancedColor": "#1e5f91"
  //   }
  // ]
  // const getAnnualizedReturnsByYears = (retArray) => {
  //   let returnsArrayByYears = JSON.parse(JSON.stringify(retArray));
  //   if (!returnsArrayByYears || typeof returnsArrayByYears === 'undefined' || returnsArrayByYears.length === 0) return null;
  //   const userIndex = _.keyBy(returnsArrayByYears, 'd');
  //   const sum = _.sum(_.map(userIndex, 'v'));
  //   const mean = _.mean(_.map(userIndex, 'v'));
  //   const avg = (sum / returnsArrayByYears.length) || 0;
  //   const AnnualizedReturns = returnsArrayByYears.length >= 12 ? mean * 12 : mean * returnsArrayByYears.length;
  //
  //   return AnnualizedReturns;
  // }
  //
  // const getAnnualizedVolatilityByYears = (retArray) => {
  //   let returnsArrayByYears = JSON.parse(JSON.stringify(retArray));
  //   if (!returnsArrayByYears || typeof returnsArrayByYears === 'undefined' || returnsArrayByYears.length === 0) return null;
  //   const userIndex = _.keyBy(returnsArrayByYears, 'd');
  //   const filterARRAY = _.map(userIndex, 'v');
  //   const AnnualizedVolumes = std(filterARRAY) * Math.sqrt(12);
  //   return AnnualizedVolumes;
  // }
  //
  // const getDrawdownByYears = (retArray) => {
  //   let out = { n: 0, dMax: 0.0 }, sum = 0.0, cuml = 0.0;
  //   let clone = _.cloneDeep(retArray)
  //   clone.forEach(d => {
  //     d.v = d.v / 100
  //     cuml = (1 + cuml) * (1 + d.v) - 1;
  //     sum += d.v;
  //     out.n += 1;
  //     if (out.n === 1) {
  //       out.cumlMax = cuml;
  //     }
  //     else {
  //       (cuml > out.cumlMax) && (out.cumlMax = cuml);
  //       let dmax = (out.cumlMax - cuml) / (1 + out.cumlMax);
  //       (dmax > out.dMax) && (out.dMax = dmax);
  //     }
  //   })
  //   out.dMax = out.dMax * 100; // negate???
  //   return out;
  // }
  // const enhancedPercent = getAnnualizedReturnsByYears(props.ratio36)
  // const enhancedVol = getAnnualizedVolatilityByYears(props.ratio36).toFixed(2)
  // const { dMax } = getDrawdownByYears(props.ratio36)
  // dataForVolandD[0]['enhanced'] = parseFloat(enhancedVol)
  // dataForVolandD[1]['enhanced'] = parseFloat(dMax.toFixed(2))

  const reportData = useContext(PortfolioContext)
  console.log('performance reportData --> ', reportData)

  let { basePortfolio: currentPerformanceImpact, comparePortfolio: enhancedPerformanceImpact, ratioString } = reportData;

  let drowdownData = null;
  let enhancedPercent = null;

  let returnsCalcBasedOn = '3-Year',
      volatilityCalcBasedOn = '3-Year',
      currentPortfolioVolatility = '',
      enhancedPortfolioVolatility = '',
      currentPortfolioDrawdown = currentPerformanceImpact && currentPerformanceImpact.dMax ?  currentPerformanceImpact.dMax : '',
      enhancedPortfolioDrawdown = null;

  let currentStats = currentPerformanceImpact.pfRet3y;
  let enhanceStats = enhancedPerformanceImpact.pfRet3y;
  let currentReturnsLegend = currentPerformanceImpact.anRet3yr;

  if (currentPerformanceImpact) {
    if (currentPerformanceImpact.pfRet10y && currentPerformanceImpact.pfRet10y.length) {
      returnsCalcBasedOn = '10-Year';
      volatilityCalcBasedOn = '10-Year';
      currentStats = currentPerformanceImpact.pfRet10y;
      enhanceStats = enhancedPerformanceImpact.pfRet10y;
      currentReturnsLegend = currentPerformanceImpact.anRet10yr;
      currentPortfolioVolatility = currentPerformanceImpact.anVol10yr;
      drowdownData = getDrawdownByYears(enhancedPerformanceImpact.pfRet10y);
      enhancedPercent = getAnnualizedReturnsByYears(enhancedPerformanceImpact.pfRet10y);
      enhancedPortfolioVolatility = numberCheck(getAnnualizedVolatilityByYears(enhancedPerformanceImpact.pfRet10y), 2);
    } else if (currentPerformanceImpact.pfRet5y && currentPerformanceImpact.pfRet5y.length) {
      returnsCalcBasedOn = '5-Year';
      volatilityCalcBasedOn = '5-Year';
      currentStats = currentPerformanceImpact.pfRet5y;
      enhanceStats = enhancedPerformanceImpact.pfRet5y;
      currentReturnsLegend = currentPerformanceImpact.anRet5yr;
      currentPortfolioVolatility = currentPerformanceImpact.anVol5yr;
      drowdownData = getDrawdownByYears(enhancedPerformanceImpact.pfRet5y);
      enhancedPercent = getAnnualizedReturnsByYears(enhancedPerformanceImpact.pfRet5y);
      enhancedPortfolioVolatility = numberCheck(getAnnualizedVolatilityByYears(enhancedPerformanceImpact.pfRet5y), 2);
    } else if (currentPerformanceImpact.pfRet3y && currentPerformanceImpact.pfRet3y.length) {
      returnsCalcBasedOn = '3-Year';
      volatilityCalcBasedOn = '3-Year';
      currentStats = currentPerformanceImpact.pfRet3y;
      enhanceStats = enhancedPerformanceImpact.pfRet3y;
      currentReturnsLegend = currentPerformanceImpact.anRet3yr;
      currentPortfolioVolatility = currentPerformanceImpact.anVol3yr;
      drowdownData = getDrawdownByYears(enhancedPerformanceImpact.pfRet3y);
      enhancedPercent = getAnnualizedReturnsByYears(enhancedPerformanceImpact.pfRet3y);
      enhancedPortfolioVolatility = numberCheck(getAnnualizedVolatilityByYears(enhancedPerformanceImpact.pfRet3y), 2);
    } else if (currentPerformanceImpact.pfRet1y && currentPerformanceImpact.pfRet1y.length) {
      returnsCalcBasedOn = '1-Year';
      volatilityCalcBasedOn = '1-Year';
      currentStats = currentPerformanceImpact.pfRet1y;
      enhanceStats = enhancedPerformanceImpact.pfRet1y;
      currentReturnsLegend = currentPerformanceImpact.anRet1yr;
      currentPortfolioVolatility = currentPerformanceImpact.anVol1yr;
      drowdownData = getDrawdownByYears(enhancedPerformanceImpact.pfRet1y);
      enhancedPercent = getAnnualizedReturnsByYears(enhancedPerformanceImpact.pfRet1y);
      enhancedPortfolioVolatility = numberCheck(getAnnualizedVolatilityByYears(enhancedPerformanceImpact.pfRet1y), 2);
    }
  }

  console.log(`========= Showing Returns of ${returnsCalcBasedOn} =========`);
  console.log('drowdownData -> ', drowdownData);
  console.log('enhancedPercent -> ', enhancedPercent);
  console.log('currentPortfolioVolatility -> ', currentPortfolioVolatility);
  console.log('enhancedPortfolioVolatility -> ', enhancedPortfolioVolatility);
  console.log(`============================================================`);

  if (drowdownData && drowdownData.dMax) {
    enhancedPortfolioDrawdown = drowdownData.dMax;
  }

  let selectedBenchmark = ratioString;

  // if (!["SPY", "ACWI", "AGG"].includes(ratioString)) {
  //   let [equity, bond] = ratioString.match(/\d+/g);
  //   selectedBenchmark = `${equity}% ACWI/${bond}% AGG`
  // }

  let dataForVolandD = [
    {
      "name": "Volatility*",
      "portfolio": numberCheck(currentPortfolioVolatility, 2),
      "portfolioColor": "#56a9e8",
      "benchmarkColor": "#1e5f91",
      "benchmark": numberCheck(enhancedPortfolioVolatility, 2),
    },
    {
      "name": "Drawdown*",
      "portfolio": numberCheck(currentPortfolioDrawdown, 2),
      "portfolioColor": "#56a9e8",
      "benchmarkColor": "#1e5f91",
      "benchmark": numberCheck(enhancedPortfolioDrawdown, 2),
    }
  ];

  let EplusTRation = ratioString.split('/')[0]
  let bondsRation = 100 - (parseInt(EplusTRation, 10))
  // console.log(EplusTRation, bondsRation)
  // let chartdata = props.data[props.defaultYears]
  // let currentReturnsLegend = props.data.anRet3yr;

  return (
    <React.Fragment>
      <div className="content">
        <div className="pr-v2-header">
          <h3>Performance Analytics</h3>
        </div>
        <div className="pr-v2-port-performance">
          <Row className="pr-v2-pp-sections">
            <Col xs="12">
              {props.createdDate && <span>Data as of {props.createdDate}.</span>}
              <h4>Portfolio vs Comparison Portfolio* ({EplusTRation}% SPY + {bondsRation}% AGG)</h4>
              <div className="bg-chart-block">
                <p className="mb-0" style={{ fontSize: '1.3rem', color: '#505050' }}>{returnsCalcBasedOn} Annualized Returns*</p>
                <div style={{ display: 'flex', width: '100%', height: '170px' }}>
                  <PortfolioLineChart
                    linechartId="portfolio-linechart"
                    currentStats={currentStats}
                    enhanceStats={enhanceStats}
                  />
                </div>
                <div className="pr-v2-chart-legends">
                  <p>Portfolio <span className="pr-v2-light-blue">{currentReturnsLegend}%</span></p>
                  <p>Comparison Portfolio* <span className="pr-v2-dark-blue">{enhancedPercent.toFixed(2)}%</span></p>
                </div>
              </div>
              <div>
                <p style={{ fontSize: '12px' }}>The line graph represents the cumulative performance of a hypothetical $100 investment. The returns are net of fund expense ratio but do not reflect the
                  payment of any brokerage commissions or brokerage costs incurred as a result of buying or selling fund shares and do not re ect the deduction of taxes to which
                  an investor would be subject as a result of owning or selling shares of the fund. If they did, the returns would be lower than those shown.</p>
                {/* <p>Annualized return is the amount of money earned by an investment each year assuming the annual return was compounded</p> */}
              </div>
            </Col>
          </Row>
          <Row className="pr-v2-pp-sections" style={{ marginTop: '30px' }}>
            <Col xs="12">
              <h4>Risk</h4>
              <div className="bg-chart-block">
                <p className="mb-0" style={{ fontSize: '1.3rem', color: '#505050' }}>Volatility and Peak to trough</p>
                <div style={{ height: '320px', width: '890px' }}>
                  <CustomBarChart
                    data={dataForVolandD}
                  />
                </div>
                <div className="pr-v2-chart-legends">
                  <p>Portfolio</p>
                  <p>Comparison Portfolio*</p>
                </div>
              </div>
              {/* <div>
                <p>Volatility is one indicator to portfolio risk. Too high means the portfolio is at risk of large losses. Too low, the portfolio’s potential is restricted.</p>
                <p>Drawdown is a peak-to-trough decline of a portfolio. E.g. If a trading account has $10,000 in it, and the funds drop to $7,000 before moving back above $10,000, then the drawdown in this portfolio is 30%.</p>
              </div> */}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment >
  )
})
export default Performance

import React, { useContext } from 'react';
import ActReportContext from '../ActReportContext';
import ConclusionCompareOnly from 'components/reportsV2/sf/ConclusionCompareOnly'
import { withHeaderFooter } from 'components/reportsV2/shared/HeaderFooter'

export const ConclusionWrapper = withHeaderFooter((props) => {
  const reportData = useContext(ActReportContext)
  const { isCompareOnly } = reportData;
  return isCompareOnly ? <ConclusionCompareOnly /> : <Conclusion />
})

const Conclusion = () => {
  const reportData = useContext(ActReportContext)
  // const { combine: { best: { ticker } }, search: { correctedQuery } } = reportData;
  const { bestTicker } = reportData;
  return (
    <div className="pr-v2-conclusion">
      <div className="pr-v2-sec-title">
        <h3>Conclusion</h3>
        <p>The best investment option for you {bestTicker.includes('and') ? 'are' : 'is'} "{bestTicker}".</p>
      </div>
    </div>
  )
}

export default Conclusion;

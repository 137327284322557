import React, { Component } from 'react';
import { connect } from 'react-redux'


import { Button } from 'reactstrap';
import ItemList from './ItemList';
import Item from './Item';
import { searchUrl, arrayToHash } from 'Utils';
import { CN  } from 'DataSet';

import { setPortfolioReplace, setEnhancementData  } from 'actions/portfolio';
import { getPortfolioStats, getInvestmentWatchlistStats, getInvestmentPositionStats } from 'selectors/investment';

class SearchResult extends Component {
  constructor(props){
    super(props)
    this.state = {
      enhanceReplace: [],
      query: '',
      replaceTicker: '',
      enhanceTickerFilter: [],
      expand: false,
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   this.setState({ enhanceTickerFilter: nextProps.enhanceTickerFilter })
  // }

  setReplaceTicker = (e, replaceTicker, ticker, query) => {
    const val = document.getElementById(e.target.id).checked;
    const { view, enhanceReplace, setPortfolioReplace, setEnhancementData, filterResultSet, portfolioFunds, marketFunds, watchlistFundstats, accountFundstats } = this.props;

    const index = enhanceReplace.findIndex(x => x.ticker === ticker);

    // const tickerWiseRet = arrayToHash(filterResultSet, CN['Ticker']);
    if (index >= 0) {
      if (val) {
        enhanceReplace[index]['replace'] = replaceTicker;
        enhanceReplace[index]['query'] = query;
        // if (tickerWiseRet[ticker] && tickerWiseRet[ticker].result && tickerWiseRet[ticker].result.funds) {
        //   const rA = tickerWiseRet[ticker].result.funds;
        //   const fA = rA.filter(i => i.ticker === replaceTicker)[0];

        //   // const pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
        //   let pF = {}
        //   if (view == 'account') {
        //     pF = accountFundstats.funds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
        //   } else if (view == 'watchlist') {
        //     pF = watchlistFundstats.positions.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
        //   } else if (view == 'mrktPl') {
        //     pF = marketFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
        //   } else {
        //     pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
        //   }
        //   enhanceReplace[index]['selectedPortfolioFunds'] = {
        //     ...fA,
        //     _pF: pF,
        //   };
        // }
        setPortfolioReplace(enhanceReplace);
        this.setState({ enhanceReplace, query, replaceTicker});
      } else {
        enhanceReplace.splice(index, 1)

        // if (tickerWiseRet[ticker] && tickerWiseRet[ticker].result && tickerWiseRet[ticker].result.funds) {
        //   const rA = tickerWiseRet[ticker].result.funds;
        //   const fA = rA.filter(i => i.ticker === replaceTicker)[0];
        //   const pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
        //   enhanceReplace[index]['selectedPortfolioFunds'] = {
        //     ...fA,
        //     _pF: pF,
        //   };
        // }
        setPortfolioReplace(enhanceReplace);
        this.setState({ enhanceReplace, query, replaceTicker});
      }
    } else {

      // if (tickerWiseRet[ticker] && tickerWiseRet[ticker].result && tickerWiseRet[ticker].result.funds) {
      //   const rA = tickerWiseRet[ticker].result.funds;
      //   const fA = rA.filter(i => i.ticker === replaceTicker)[0];

      //   // const pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
      //   let pF = {}
      //     if (view == 'account') {
      //       pF = accountFundstats.funds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
      //     } else if (view == 'watchlist') {
      //       pF = watchlistFundstats.positions.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
      //     } else if (view == 'mrktPl') {
      //       pF = marketFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
      //     } else {
      //       pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
      //     }
      //   enhanceReplace.push({ ticker, replace: replaceTicker, query, selectedPortfolioFunds: {
      //     ...fA,
      //     _pF: pF,
      //   }});
      // }
      this.setState({ enhanceReplace, query, replaceTicker});
      setPortfolioReplace(enhanceReplace);
    }

    // if (ticker) {
    //   if (tickerWiseRet[ticker] && tickerWiseRet[ticker].result && tickerWiseRet[ticker].result.funds) {
    //     const rA = tickerWiseRet[ticker].result.funds;
    //     const fA = rA.filter(i => i.ticker === replaceTicker);
    //     if (fA.length) {
    //       setEnhancementData({
    //         enhancementData: fA,
    //       });
    //     }
    //   }
    // }
  }

  render() {
    const { data: { query, result, short_name, ticker }, enhanceTickerFilter, setReplaceTicker, replaceTicker, portfolioFunds, filterResultSet } = this.props;
    // const { expand  } = this.state;

    // let noOfCard = 5;
    // if (window.screen.width < 1456 && window.screen.width > 1024) {
    //   noOfCard = 3;
    // } else if (window.screen.width < 1811 && window.screen.width > 1456) {
    //   noOfCard = 4;
    // }

    // const more = result.funds.length > noOfCard;
    // const _result = result.funds.slice(0, expand ? 10 : noOfCard);
    const uniqueFunds = [];

    portfolioFunds.map(x => uniqueFunds.filter(a => ((a.script == x.script) && x.type !== 'SHARE')).length > 0 ? null : uniqueFunds.push(x));

    if (enhanceTickerFilter.includes(ticker) || enhanceTickerFilter.length == 0) {
      return (
        <div className="mb-3">
        {/* {data.length > 0 ?
          data.map((i) => {
            if(i.ticker == ticker)
              return <h3 style={{color:'#4a4a4a',fontSize:'1.4375rem',fontWeight: '600'}}>{i.short_name} ({i.ticker})</h3>
          })
          :
          null
        } */}

        <h3 style={{color:'#4a4a4a',fontSize:'1.4375rem',fontWeight: '600'}}>{short_name} ({ticker})</h3>
        {/*  */}
          <div className="mb-3" style={{color:'#1e5f91',fontSize:'15px',fontWeight:'600'}}>{query}
          {query.indexOf('my criteria') > -1 ? null : <a href={searchUrl(query)} className="ml-2" title="Search" target="_blank">
             <i className={`far fa-fw fa-search`} />
          </a>}
          {/* { more && !expand && <Button color={"link"} size="sm" className="lh-100 p-0 float-right" onClick={() => this.setState({ expand: true })}>More<i className="fas fa-caret-right fs-11 ml-1"></i></Button>}
          { more && expand && <Button color={"link"} size="sm" className="lh-100 p-0 float-right" onClick={() => this.setState({ expand: false })}><i className="fal fa-times"></i></Button>} */}
        </div>
        {/* {
          !expand &&
          <LessItemList data={_result} query={query} ticker={ticker} setReplaceTicker={this.setReplaceTicker} enhanceReplace={enhanceReplace} replaceTicker={replaceTicker}  />
        } */}
        {/* {
          expand &&  */}

        {/* } */}
        <ItemList data={result} query={query} ticker={ticker} />
      </div>
      )
    } else return ''
  }
}

const mapStateToProps = (state) => ({
  // valid: portfolio.valid,
  // data: portfolio.resultSet,
  view: state.investment.view,
  // enhanceReplace: state.portfolio.enhanceReplace,
  // filterResultSet: state.portfolio.filterResultSet,
  enhanceTickerFilter: state.portfolio.enhanceTickerFilter,
  portfolioFunds: state.investment.portfolioFunds,
  marketFunds: state.investment.marketFunds,
  watchlistFundstats: getInvestmentWatchlistStats(state),
  accountFundstats: getInvestmentPositionStats(state),
})

const mapDispatchToProps = {
  setPortfolioReplace,
  setEnhancementData,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResult)

const LessItemList = ({ data, query, ticker, setReplaceTicker, enhanceReplace, replaceTicker }) => {
  return (
    <div className="d-flex flex-row">
      { data.map((e, i) => (
          <div key={i} className="pr-3">
            <Item data={e} query={query}  ticker={ticker} setReplaceTicker={setReplaceTicker} enhanceReplace={enhanceReplace} replaceTicker={replaceTicker} />
          </div>
      )) }
    </div>
  )
}

import React from 'react';

export const ShowSpinner = (props) => {
  return (
    <div className="v-center">
	    <div className="w-100 text-center">
	      <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5"></i>
	      {props.message && <h3 className="mt-4"><span>{props.message}</span></h3>}
	    </div>
    </div>
  );
}

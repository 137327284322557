import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { addEventToAnalytics } from 'Utils';

import { DonutChartS1 } from 'components/charts/Recharts'

import { RegionColors, SectorColors } from 'data/Colors'
import { SelectorDD, KeyStats3, KeyStats4, NoData } from './Common'

// export const Gains = (props) => {
//   const stats = [
//     { name: "Gains For Today", value: props.stats.gainToday },
//     { name: 'Gains For The Month', value: props.stats.gainTotal },
//     { name: 'Gains For The Year', value: props.stats.gainToday },
//   ]
//   return (
//     <KeyStats3 {...props} stats={stats} prec={2} />
//   )
// }
//
// export const PortfolioGains = (props) => {
//   const stats = [
//     { name: "Gains For Today", value: props.stats.gainToday },
//     { name: 'Gains For The Month', value: props.stats.gainMonthly },
//     { name: 'Gains For The Year', value: props.stats.gainYearly },
//   ]
//   return (
//     <KeyStats4 {...props} stats={stats} prec={2} />
//   )
// }

export class Allocations extends React.Component {
  opts = [ 'Sectors', 'Regions' ]
  state = { opt: this.opts[0] }

  optHandler = opt => {
    if(opt == 'Sectors'){
      addEventToAnalytics('Enhancer Allocation Sector','Enhancer Allocation Sector','ENHANCER_ALLOCATION_SECTOR',{},false);
    }
    else{
      addEventToAnalytics('Enhancer Allocation Region','Enhancer Allocation Region','ENHANCER_ALLOCATION_REGION',{},false);
    }
    this.setState({ opt })
  };
  formatter = val => `${val.toFixed(1)}%`;

  chartData = () => {
    const { priceRegions: regions, priceSectors: sectors } = this.props.stats;
    return this.state.opt === this.opts[0]
            // ? Object.keys(sectors).map((e, i) => ({ name: e, value: sectors[e], color: SectorColors[i%SectorColors.length] }))
            ? this.props.stats.allocationPriceSectors.length > 0 ? this.props.stats.allocationPriceSectors : []
            // : Object.keys(regions).map(e => ({ name: e, value: regions[e], color: RegionColors[e] }));
            : this.props.stats.priceRegions.length > 0 ? this.props.stats.priceRegions : [];
  }

  render() {
    const data = this.chartData();
    const regionData = this.props.stats.priceRegions.length > 0 ? this.props.stats.priceRegions : [];
    const sectorData = this.props.stats.allocationPriceSectors.length > 0 ? this.props.stats.allocationPriceSectors : [];
    // { data.length <= 0
    //   ? <NoData height='225px' />
    // :  <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '192px', width: '100%' }}>
    //       <div style={{ width: "90%", height: "140px" }}>
    //         <DonutChartS1 data={data} formatter={this.formatter} />
    //       </div>
    //     </div> }
    // <SelectorDD opts={this.opts} optHandler={this.optHandler} />
    return (
      <React.Fragment>
        <h5 className="mb-1 font-one OpenSummaryPage-card-title" style={{ textAlign: 'center' }}>Allocations</h5>
        <Row gutter={16} style={{ minHeight: '192px',  width: '100%' }}>
          <Col span={12}>
            <div style={{ width: "70%", height: "180px", margin: '0 auto', textAlign: 'center' }}>
              {(sectorData <= 0) ? (
                <NoData height='180px' />
              ) : (
                <DonutChartS1 data={sectorData} formatter={this.formatter} />
              )}
            </div>
            <div style={{ textAlign: 'center' }}>By Sectors</div>
          </Col>
          <Col span={12}>
            <div style={{ width: "70%", height: "180px", margin: '0 auto', textAlign: 'center' }}>
              {(regionData <= 0) ? (
                <NoData height='180px' />
              ) : (
                <DonutChartS1 data={regionData} formatter={this.formatter} />
              )}
            </div>
            <div style={{ textAlign: 'center' }}>By Regions</div>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

import React, { Component } from 'react';
import './_index.scss';
import { Button } from 'reactstrap';

class PopularSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
        <>
        <div className="popular-search r32">
            <h1>Popular Searches</h1>
            <div className="row ">
            <div className="col-lg-2">
            <Button onClick={()=>this.props.queryHandler('HEALTHCARE')} className="popular-search-button" outline color="secondary">HEALTHCARE</Button>
            </div>
            <div className="col-lg-3">
            <Button onClick={()=>this.props.queryHandler('BEST TECH EXPOSURE')} className="popular-search-button" outline color="secondary">BEST TECH EXPOSURE</Button>
            </div>
            <div className="col-lg-2">
            <Button onClick={()=>this.props.queryHandler('INVEST IN GOLD')} className="popular-search-button " outline color="secondary">INVEST IN GOLD</Button>
            </div>
            <div className="col-lg-5">
            <Button onClick={()=>this.props.queryHandler('THEMATIC FUNDS WITH ASSETS MORE THAN $1BILLION')} className="popular-search-button " outline color="secondary">THEMATIC FUNDS WITH ASSETS MORE THAN $1BILLION</Button>
            </div>
            <div className="col-lg-4">
            <Button onClick={()=>this.props.queryHandler('FUNDS THAT DO WELL WHEN MARKETS FALL')} className="popular-search-button " outline color="secondary">FUNDS THAT DO WELL WHEN MARKETS FALL</Button>
            </div>
            <div className="col-lg-4">
            <Button onClick={()=>this.props.queryHandler('US FUNDS THAT OUTPERFORM THE MARKET')} className="popular-search-button " outline color="secondary">US FUNDS THAT OUTPERFORM THE MARKET</Button>
            </div>
            <div className="col-lg-2">
            <Button onClick={()=>this.props.queryHandler('DIVERSITY')} className="popular-search-button " outline color="secondary">DIVERSITY</Button>
            </div>
            <div className="col-lg-2">
            <Button onClick={()=>this.props.queryHandler('HIGH YIELD FUNDS')} className="popular-search-button " outline color="secondary">HIGH YIELD FUNDS</Button>
            </div>
            </div>
        </div>
        </>
    );
  }
}


export default PopularSearch

import React from 'react';
import { Modal, ModalBody, ButtonGroup, Button, Spinner } from 'reactstrap';
import { CloseButton } from 'shared-components';

import './modal.scss';

const CustomModal = (props) => {
    const { open, onClose, iconTop, title, subtitle, children } = props;
    return ( <Modal
                isOpen={open}
                toggle={onClose}
                centered
                keyboard={true}
                backdrop
                className="modalFeature "
                backdropClassName="premium-feature-process-modal-backdrop"
            >
                <ModalBody className="ssf-modal__body">
                <div className="premium-feature-process-modal-container">
                    <div className="header-wrapper-modal">
                        {iconTop && <div className="top-icon">{iconTop}</div>}
                        {title && <h1 className="text-align-center pb-2 modal_title_box">{title}</h1>}
                        {subtitle && <p className="text-align-center pb-2 h3_Title">Try these popular searches</p>}
                        <div className="custom-modal-body">{children}</div>
                    </div>
                    <i className="fal fa-times-circle premium-feature-completed-close" onClick={onClose}></i>
                </div>
                </ModalBody>
            </Modal>
            )
}

export default CustomModal;
import React, { useContext } from 'react'
import { Row, Col, Table } from 'reactstrap'
import { numberCheck } from 'layouts/utils'
import { withHeaderFooter } from '../shared/HeaderFooter'
import { StackedAreaChart } from '../shared/Charts'
import { getConclusionContent, FormatCurrency } from '../shared/Utils'
import { convertToLowerCase } from 'Utils'
import PortfolioContext from '../PortfolioContext'

const ConclusionPage = withHeaderFooter(props => {
  const reportData = useContext(PortfolioContext)
  const advisory_fees =
    reportData.advisory_fees && !isNaN(reportData.advisory_fees)
      ? parseFloat(reportData.advisory_fees)
      : 0

  let {
    investment,
    portfolio,
    portfolio: { enhancedPerformanceImpact: epi }
  } = reportData

  let query =
    portfolio.query_english || convertToLowerCase(portfolio.query, true)
  // minus_1_sd_10yr: annualizedRet10y+-1*annualizedVolt10y,
  // minus_1_sd_10yr: (numberCheck(epi.anRet10yr) - advisory_fees)+-1*numberCheck(epi.anVol10yr),

  // plus_1_sd_10yr: annualizedRet10y+annualizedVolt10y*1,
  // plus_1_sd_10yr: (numberCheck(epi.anRet10yr) - advisory_fees)+numberCheck(epi.anVol10yr)*1,

  let returnsCalcBasedOn = '3 Year',
    volatilityCalcBasedOn = '3 Year'
  if (numberCheck(epi.anRet10yr)) {
    returnsCalcBasedOn = '10 Year'
  } else if (numberCheck(epi.anRet5yr)) {
    returnsCalcBasedOn = '5 Year'
  } else {
    returnsCalcBasedOn = '3 Year'
  }

  if (numberCheck(epi.anVol10yr)) {
    volatilityCalcBasedOn = '10 Year'
  } else if (numberCheck(epi.anVol5yr)) {
    volatilityCalcBasedOn = '5 Year'
  } else {
    volatilityCalcBasedOn = '3 Year'
  }

  // fallback condition for 10 years returns/volatility
  let _EPIanRetyr = numberCheck(epi.anRet10yr)
    ? numberCheck(epi.anRet10yr)
    : numberCheck(epi.anRet5yr)
    ? numberCheck(epi.anRet5yr)
    : numberCheck(epi.anRet3yr) 
  let _EPIanVolyr = numberCheck(epi.anVol10yr)
    ? numberCheck(epi.anVol10yr)
    : numberCheck(epi.anVol5yr)
    ? numberCheck(epi.anVol5yr)
    : numberCheck(epi.anVol3yr)

  const EPIanRetyrPercent = _EPIanRetyr / 100; //returns
  const EPIanVolyrPercent = _EPIanVolyr /100;   //volatility
  let invPortfolioValue = investment.portfolioValue //portfolio
  const lbConstant = 1.036433391
  const ubConstant = -1.036433391

  let annualMeanReturn = (numberCheck(_EPIanRetyr) - advisory_fees) / 100

  const getLbValue = index => {
    const calculatedValue =
    EPIanRetyrPercent -
      (0.5 * EPIanVolyrPercent ** 2 + (lbConstant / Math.sqrt(index)) * EPIanVolyrPercent)

    const lbDollarValue = invPortfolioValue * (1 + calculatedValue) ** index
    return lbDollarValue
  }

  const getMeanValue = index => {
    const meanDollar = invPortfolioValue * (1 + EPIanRetyrPercent) ** index
    return meanDollar
  }

  const getUpperValue = index => {
    const calculatedValue =
    EPIanRetyrPercent -
      (0.5 * EPIanVolyrPercent ** 2 + (ubConstant / Math.sqrt(index)) * EPIanVolyrPercent)

    const ubDollarValue = invPortfolioValue * (1 + calculatedValue) ** index
    return ubDollarValue
  }

  // console.log('advisory_fees --> ', advisory_fees)
  // console.log('returnsCalcBasedOn -->', returnsCalcBasedOn, _EPIanRetyr);
  // console.log('volatilityCalcBasedOn -->', volatilityCalcBasedOn, _EPIanVolyr);
  // console.log('annualMeanReturn -->', annualMeanReturn)

  let annualizedVolatility = numberCheck(_EPIanVolyr) / 100
  // let minus_1_sd_10yr = epi.minus_1_sd_10yr/100;
  let minus_1_sd_10yr =
    (numberCheck(_EPIanRetyr) - advisory_fees + -1 * numberCheck(_EPIanVolyr)) /
    100
  // let plus_1_sd_10yr = epi.plus_1_sd_10yr/100;
  let plus_1_sd_10yr =
    (numberCheck(_EPIanRetyr) - advisory_fees + numberCheck(_EPIanVolyr) * 1) /
    100

  // Chart Computation
  let chartData = []
  let noOfYrs = 10

  let minValue = Number.POSITIVE_INFINITY
  let maxValue = Number.NEGATIVE_INFINITY

  for (var i = 0; i <= noOfYrs; i++) {
    let name = i == 0 ? 'Today' : 'Year ' + i
    let lb = i == 0 ? Math.round(invPortfolioValue) : Math.round(getLbValue(i))
    if (lb < minValue) minValue = lb
    if (lb > maxValue) maxValue = lb

    let mb =
      i == 0 ? Math.round(invPortfolioValue) : Math.round(getMeanValue(i))
    if (mb < minValue) minValue = mb
    if (mb > maxValue) maxValue = mb
    // console.log('series row -->', mb)

    let ub =
      i == 0 ? Math.round(invPortfolioValue) : Math.round(getUpperValue(i))
    if (ub < minValue) minValue = ub
    if (ub > maxValue) maxValue = ub
    chartData.push({ name, lb, mb, ub })
  }

  let minMaxValues = {
    minValue: isFinite(minValue) ? minValue : 'auto',
    maxValue: isFinite(maxValue) ? maxValue : 'auto'
  }

  let chartOptions = [
    {
      dataKey: 'ub',
      fillColor: '#56a9e8',
      fillOpacity: 0.3,
      stroke: '#56a9e8'
    },
    {
      dataKey: 'mb',
      fillColor: '#56a9e8',
      fillOpacity: 0.3,
      stroke: '#56a9e8'
    },
    { dataKey: 'lb', fillColor: '#56a9e8', fillOpacity: 0.3, stroke: '#56a9e8' }
  ]

  let portPerfPercent = numberCheck(
    ((chartData[noOfYrs].mb - invPortfolioValue) / invPortfolioValue) * 100,
    2
  ) // ((chartData[noOfYrs].mb-invPortfolioValue)/invPortfolioValue * 100).toFixed(1);
  let avgReturn = numberCheck(_EPIanRetyr, 2) // numberCheck(epi.anRet3yr, 2); // parseFloat(epi.anRet3yr).toFixed(2);
  let expRisk = numberCheck(_EPIanVolyr, 2) // numberCheck(epi.anVol3yr, 2); // parseFloat( epi.anVol3yr).toFixed(2);
  let avgGrowth = chartData[noOfYrs].mb - invPortfolioValue
  let avgGrowthChart = chartData[noOfYrs].mb

  // console.log(chartData)

  return (
    <React.Fragment>
      <div className="content">
        <div className="pr-v2-header">
          <h3>Conclusion</h3>
        </div>
        <div className="pr-v2-conclusion">
          {getConclusionContent(
            query,
            portPerfPercent,
            avgReturn,
            expRisk,
            avgGrowth,
            noOfYrs,
            reportData.portfolio.isModelPortfolioQuery,
            returnsCalcBasedOn,
            volatilityCalcBasedOn,
            annualMeanReturn
          )}
          <Row>
            <Col xs="12">
              <div
                style={{
                  width: '970px',
                  height: '360px',
                  marginTop: '20px',
                  marginBottom: '10px',
                  position: 'relative'
                }}
              >
                <StackedAreaChart
                  chartData={chartData}
                  chartOptions={chartOptions}
                  minMaxValues={minMaxValues}
                  showLabels
                />
                <div className="pr-v2-conc-desc">
                  <p className="mb-0">Year 10</p>
                  <p className="mb-0">
                    50% Chance of having:
                    <span>{FormatCurrency(avgGrowthChart, 0, '$', 'unf')}</span>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <div>
            {returnsCalcBasedOn !== '10 Year' && (
              <p className="pr-v2-small-paragraph">
                *The default time period for the return calculation is 10 years.
                However, if any of the enhanced funds have less than 10 years of
                returns, the calculation will adjusts to the next longest common
                time frame (5 years or 3 years) in which all enhanced funds have
                return values.
              </p>
            )}
            {/*
              <p className="pr-v2-small-paragraph">
                {
                  advisory_fees
                    ? `This graph represents a range of expected potential outcomes for the Enhanced portfolio assuming a start with today's portfolio balance and using the compound historical performance, and volatility of the portfolio's constituents. The potential outcomes in the above graph are net of advisory fees of ${advisory_fees}%. There is no gurantee that any of the outcomes represented above will be achieved. Please refer to additional important disclosures at the end of this document.`
                    : `This graph represents a range of expected potential outcomes for the Enhanced portfolio assuming a start with today's portfolio balance and using the compound historical performance, and volatility of the portfolio's constituents. There is no gurantee that any of the outcomes represented above will be achieved. Please refer to additional important disclosures at the end of this document.`
                }
              </p>
            */}
            <p className="pr-v2-small-paragraph">
              This information was generated by an investment analysis tool that
              produces simulations and statistical analyses that present
              thelikelihood of various investment outcomes if certain
              investments are made or certain investment strategies or styles
              are undertaken. Past performance does not guarantee future
              results. TIFIN Wealth Tech LLC does not guarantee any minimum
              level of investment performance or the success of any portfolio or
              investment strategy. Results may vary with each use and over time.
              There is apotential for loss in any investment, including the loss
              of capital. All investments involve risk, and different types of
              investments involve varying degrees of risk. Investment
              recommendations will not always be profitable. No representation
              is being made that any client account will or is likely to achieve
              profit or losses similar to those shown in the chart above.
              Hypothetical performance information shown in text, charts, tables
              and graphs is provided for informational purposes only and should
              not be considered investment advice or a recommendation to buy or
              sell any particular security. There are certain limitations
              inherent in hypothetical results like those portrayed,
              particularly that such hypothetical results do notreflect trading
              in actual client accounts and do not reflect the impact that
              material economic and market factors may have had onthe
              investments chosen had the adviser actually been managing client
              accounts. Unlike an actual performance record, hypothetical
              performance results do not represent actual trading. These types
              of simulations, in general, benefit compared to actual performance
              results because such simulations are designed with the benefit of
              hindsight. In addition, simulated trading does not involve or
              takeinto account financial risk and does not take into account
              that material and market factors may have on investments chosen,
              all of which can adversely affect actual trading results and
              performance.
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
})

export default ConclusionPage

import React, { Component, useState, Fragment } from 'react'
import { Link } from 'react-router-dom'

import { get, toLower } from 'lodash'
import moment from 'moment'
import { Regex } from 'common/regex'

import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap'
import GoogleLogin from 'react-google-login'
import {
  CustomFormFeedback,
  Spinner,
  DatePicker,
  PrimaryButton,
  SecondaryButton,
  DropdownDatePicker,
  AppleLogin,
  IconButton
} from 'shared-components'
import { addEventToAnalytics } from 'Utils';
import {
  countryList,
  areYouAnList,
  investableAssetsList,
  assetUnderManagementList,
  ageGroupList,
  whereDidYouHear,
  usStates,
  RETAIL_USERS_ROLES
} from '../Constant/FormData'

import TermsConditions from './../SearchResults/Elements/Registrations/AgreeTermsNConditions'
import VideoPlayer from 'components/VideoPlayer'
import { Carousel, CarouselItem, CarouselIndicators } from 'reactstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { REDIRECT_DOMAIN_PATH } from 'DataSet'
import './RegisterFormOpt2.scss'
import { Checkbox, Steps } from 'antd'
import config from  'env';

import { ReactComponent as AppleLogoImg } from './apple_logo_SVG.svg';
import { failedAppleRegister } from 'actions/register'
// import { AppleFilled } from '@ant-design/icons';

const googleLogo = '/assets/images/google@3x.png'
const magnifiLogo =
  '/assets/images/MagnifiLogo/Magnifi_Tagline_Charcoal_RGB.png'
const magnifiLogoWhite =
  '/assets/images/MagnifiLogo/Magnifi_Tagline_White_RGB@2x.png'
const lhsmuchmore = '/assets/images/misc/2-Much-More.png'
const lhsCalculator = '/assets/images/misc/1-Calculator.png'
const lhsEnhance = '/assets/images/misc/2-Enhancement.png'
const magnifiInvestResponsiblyLogo = '/assets/images/register-magnifi-invest-resp-logo.svg';
const blueLogo = '/assets/images/Magnifi_Invest_res.svg'
const { Step } = Steps;
const LHSMsg = [
  {
    src: lhsCalculator,
    altText: '',
    caption: 'Access calculators to compare funds'
  },
  {
    src: lhsEnhance,
    altText: '',
    caption: 'Build and evaluate changes to your portfolio'
  },
  {
    src: lhsmuchmore,
    altText: '',
    caption: 'And much more...'
  }
]

const RegisterFormOpt2 = props => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  const returnedToEdit = props.history && props.history.location && props.history.location.returnToEdit ? true : false
  const form1InitialValues = {
    first_name: '',
    last_name: '',
    email: props.profileData.profile && props.profileData.profile.email ? props.profileData.profile.email : '',
    password: returnedToEdit ? '1234567@Test' : '',
    rePassword: returnedToEdit ? '1234567@Test' : '',
    self_role: process.env.REACT_APP_SUB_DOMAIN === 'retail' ? 'Individual Investor' : '',
    investable_assets: props.profileData.profile && props.profileData.profile.investable_assets ? props.profileData.profile.investable_assets : '',
    estimated_net_worth: '',
    refree_code: '',
    areYouAnOther: '',
    termsCondition: false,
    country: '',
    zipcode: '',
    state: '',
    stateInput: '',
    state_value: '',
    city: '',
    address: '',
    date_of_birth: props.profileData.profile && props.profileData.profile.date_of_birth ? moment(props.profileData.profile.date_of_birth).format('YYYY-MM-DD') : '',
    dateHasValue: ''
  }
  const validationSchema1 = Yup.object().shape({
    email: Yup.string()
      .email()
      .required('Email is a required field'),
    password: Yup.string()
      .min(8, 'Password has to be longer than 8 characters!')
      .required('Password is a required field'),
    rePassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Re-enter password is a required field'),
    // date_of_birth: Yup.string()
    //   .required('Please select your DOB')
    //   .test(
    //     'is-valid-date',
    //     'Invalid Date',
    //     dob => moment(dob).isValid()
    //   )
    //   .test(
    //     'is-over-18',
    //     'Age must be over 18',
    //     dob => moment().diff(dob, 'years') >= 18
    //   ),
    // investable_assets: Yup.string().required('Please select asset amount')
  })

  const validationSchema2 = Yup.object().shape({
    first_name: Yup.string()
      .required('First name is a required field')
      .matches(Regex.fullName, 'First name is not valid'),
    last_name: Yup.string()
      .required('Last name is a required field')
      .matches(Regex.fullName, 'Last name is not valid'),
    email: Yup.string()
      .email()
      .required('Email is a required field'),
    password: Yup.string()
      .min(8, 'Password has to be longer than 8 characters!')
      .required('Password is a required field'),
    rePassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Re-enter password is a required field'),
    self_role: Yup.string().required('Please select your profile'),
    country: Yup.string().required('Please select a country'),
    zipcode: Yup.string()
      .required('Please enter your zipcode')
      .matches(Regex.zipCode, 'zip code is not valid'),
    //age_group:Yup.string(),
    date_of_birth: Yup.string()
      .nullable()
      .required('Please select your DOB'),
    state: Yup.string().required('Please enter your state'),
    city: Yup.string().required('Please enter your city'),
    address: Yup.string()
      .required('This is a required field')
      .matches(Regex.pobox, 'Invalid street address')
      .max(30, 'Street must be at most 30 characters'),
    investable_assets: Yup.string(),
    estimated_net_worth: Yup.string()
  })

  const handleFormSubmit = (values, { setSubmitting }) => {
    props.nextClick(values, { setSubmitting }, props.plan_selected === 'basic')
  }

  const videoSetState = _state => {
    addEventToAnalytics('Register Video', 'Register Video', 'REGISTER_VIDEO', {}, false)
  }

  return (
    <Row className={process
      .env.REACT_APP_SUB_DOMAIN === 'retail' ? "magnifi-auth-page-wrapper register-magnifi-opt-2 retail_different_layout" : "magnifi-auth-page-wrapper register-magnifi-opt-2"}>
      {process
        .env.REACT_APP_SUB_DOMAIN === 'retail' ? <Col className="left-side-panel-container">
        <Row className="parent_row custom-scroll">
          <Col sm={12} style={{ height: '88%', display: 'flex', alignItems: 'center' }}>
            <div className="w-100 text-center">
              <a className="logo-wrapper" href={REDIRECT_DOMAIN_PATH}>
                <img src={blueLogo} height={70} alt="magnifi" />
              </a>
              <h1 className="ai_heading">Become an AI-Powered Investor</h1>
              <p className="ai_desc">Combine AI powered natural language search with robust investment intelligence to find, compare, and invest.</p>
              <div className="w-100 mt-4 video_frame_container">
                <VideoPlayer
                  key={'https://magnifi-videos.s3.us-east-2.amazonaws.com/Demo_Video_Revised_02.mp4'}
                  link={'https://magnifi-videos.s3.us-east-2.amazonaws.com/Demo_Video_Revised_02.mp4'}
                  title={'Magnifi'}
                  onPlayPauseState={videoSetState}
                  onPauseState={videoSetState}
                  onEndedState={videoSetState}
                />
              </div>
            </div>
          </Col>
          <Col style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'flex-end' }}>
            <div className="w-100 text-center">
              <p className="any_questions">
                Questions?
              </p>
              <p className="get_support">
                <a href="mailto:support@magnifi.com">support@magnifi.com</a> | Anytime
              </p>
            </div>
          </Col>
        </Row>
        {/* {!isMobile && (
          <Row style={{ flex: 1 }}>
            <div className="contact-info">
              <p><a href="mailto:support@magnifi.com">support@magnifi.com</a> | Anytime</p>
            </div>
          </Row>
        )} */}
      </Col> : <Col className="left-side-panel-container">
        <Row style={{ flex: 7, alignItems: "center" }}>
          <a className="logo-wrapper" href={REDIRECT_DOMAIN_PATH}>
            <img src={process.env.REACT_APP_SUB_DOMAIN === 'retail' ? magnifiInvestResponsiblyLogo : magnifiLogoWhite} height={70} alt="magnifi" />
          </a>
        </Row>
        {!isMobile && (
          <Row style={{ flex: 1 }}>
            <div className="contact-info">
              {/* <p>Call or Text Magnifi Support: <a href="tel:7204455530">(720) 445-5530</a> | 9am - 6pm EST</p> */}
              <p><a href="mailto:support@magnifi.com">support@magnifi.com</a> | Anytime</p>
            </div>
          </Row>
        )}
      </Col>}
      {props.step === 1 && (!props.loggedIn || returnedToEdit) ? (
        <Col
          sm="12"
          md="12"
          lg="6"
          xl="6"
          className="right-side-panel-container register-page-height"
        >
          <div className="form_content">
            <Spinner isFetching={props.isFetching} />
            {!returnedToEdit ? <BackButton clickHandler={props.backHandler} /> : <div style={{ height: 40 }}></div>}
            <Formik
              initialValues={form1InitialValues}
              onSubmit={handleFormSubmit}
              validationSchema={
                process.env.REACT_APP_SUB_DOMAIN === 'retail'
                  ? validationSchema1
                  : validationSchema2
              }
              render={formProps => (
                <>
                  <RegisterFormRHS
                    returnedToEdit={returnedToEdit}
                    formProps={formProps}
                    profileData={props.profileData}
                    onToggle={props.onToggle}
                    error={props.error}
                    plan_selected={props.plan_selected}
                    onGoogleSignInSuccess={props.onGoogleSignInSuccess}
                    onGoogleSignInError={props.onGoogleSignInError}
                    history={props.history}
                    subscribeNow={props.subscribeNow}
                    createSubscriptionRequest={props.createSubscriptionRequest}
                    disableRegisterButton={props.disableRegisterButton}
                    registerForTrade={props.registerForTrade}
                  />
                  {isMobile && (
                    <Row style={{ flex: 1 }}>
                      <div className="contact-info w-100 text-center">
                        {/* <p>Call or Text Magnifi Support: <a href="tel:7204455530">(720) 445-5530</a> | 9am - 6pm EST</p> */}
                        <p><a href="mailto:support@magnifi.com">support@magnifi.com</a> | Anytime</p>
                      </div>
                    </Row>
                  )}
                </>
              )}
            />
          </div>
        </Col>
      ) : (
        <Col
          md="6"
          lg="6"
          className={`right-side-panel-container register-page-height pt-3 ${process
            .env.REACT_APP_SUB_DOMAIN === 'retail' &&
            'd-flex justify-content-center'}`}
        >
          <RegisterFormRHS3 {...props} />
        </Col>
      )}
    </Row>
  )
}

export default RegisterFormOpt2

class RegisterFormRHS3 extends Component {
  componentDidMount() {
    document.getElementsByTagName('body')[0].style.paddingTop = 0
  }

  createTradingAccount = () => {
    this.props.history.push('register/trading')
  }

  upgradeToPremium = () => {
    this.props.updatePlanChange(true)
  }

  render() {
    const { openTradingRegisterPage, history } = this.props;

    // if (openTradingRegisterPage === 'OPEN_TRADING_REGISTER_PAGE' && history) {
    //   return history.push('/register/trading');
    // }

    const profile_self_role = window.localStorage.getItem('profile_self_role')
      ? window.localStorage.getItem('profile_self_role')
      : 'loggedOut'
    const selectorText = RETAIL_USERS_ROLES.includes(profile_self_role)
      ? 'Cart'
      : 'Selector'

    return (
      <Form
        name="RegisterFormRHS3"
        className="magnifi-auth-design-container register-magnifi-auth-design"
        style={{
          height:
            process.env.REACT_APP_SUB_DOMAIN === 'retail' ? 'auto' : '100%'
        }}
        noValidate={true}
      >
        <Tagline />
        <div className="header-area">
          <div className="header-label">
            You are now a registered user at magnifi.com that allows you to
            Discover a new world of investment shopping
          </div>
        </div>
        <div className="cta-wrapper registration-btn-wrapper">
          <PrimaryButton
            type="button"
            className="btn primary-btn"
            onClick={this.createTradingAccount}
          >
            Create Magnifi Investment Account
          </PrimaryButton>
        </div>
        {process.env.REACT_APP_SUB_DOMAIN !== 'retail' && (
          <Fragment>
            <div className="trading-benefits">
              <h1 style={{ paddingTop: `10px` }}>Upgrade to Premium</h1>
              <h2>Benefits Of premium account</h2>
              <ul className="row">
                <li className="col-lg-6 col-12">
                  <b>{selectorText}:</b> Personalize our patented fund selector
                  to find the best fund for you among relevant funds discovered.
                </li>
                <li className="col-lg-6 col-12">
                  <b>Builder:</b> Start with selected funds or initial portfolio
                  to build a portfolio for proposals or trades.
                </li>
                <li className="col-lg-6 col-12">
                  <b>Enhancer:</b> Read in client, prospect or sponsor provided
                  portfolios and evaluate enhancements with simple language
                  based queries.
                </li>
                <li className="col-lg-6 col-12">
                  <b>Proposals:</b> For clients to help convert and explain; and
                  for Compliance from fund selector, builder and enhancer.{' '}
                </li>
                <li className="col-lg-6 col-12">
                  <b>Save:</b> Results and download to spreadsheets.
                </li>
              </ul>
            </div>
            <div className="cta-wrapper registration-btn-wrapper">
              <PrimaryButton
                type="button"
                onClick={this.upgradeToPremium}
                className="btn primary-btn"
              >
                Upgrade to Premium
              </PrimaryButton>
            </div>
          </Fragment>
        )}
        <p
          className={'skip'}
          onClick={() => {
            this.props.history.push('/')
          }}
        >
          Skip and Start Discovering
        </p>
      </Form>
    )
  }
}

class RegisterFormRHS extends Component {
  constructor(props) {
    super(props);
    this.FormRef = React.createRef();
  }

  state = {
    areYouAnOther: false,
    areYouanValue: '',
    tooltipOpen: false,
    termsCondition: false,
    countryState: false,
    freeTrial: false,
    isManualChangeFreeTrial: false,
    dataCaptureObj: {}
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].style.paddingTop = 0;
    document.addEventListener("keydown", this._handleKeyDown);
  }


  _handleKeyDown = (e) => {
    if (e.code === 'Tab') {
      if (!this.props.returnedToEdit) {
        document.getElementById('email').disabled = false
        document.getElementById('password').disabled = false
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this._handleKeyDown);
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.state.areYouAnOther &&
      nextProps.formProps.values.areYouAn === 'Other/Curious'
    ) {
      this.setState({ areYouAnOther: true }, () =>
        document.getElementById('areYouAnOther').focus()
      )
    } else if (
      this.state.areYouAnOther &&
      nextProps.formProps.values.self_role === 'Other/Curious'
    ) {
    } else this.setState({ areYouAnOther: false })

    let self_role = nextProps.formProps.values.self_role
      ? nextProps.formProps.values.self_role
      : ''
    if (!this.state.isManualChangeFreeTrial) {
      if (
        self_role === 'RIA' ||
        self_role === 'Hybrid Advisor' ||
        self_role === 'Broker/Dealer/Wirehouse Advisor'
      ) {
        !nextProps.formProps.values.freeTrial &&
          nextProps.formProps.setFieldValue('freeTrial', true)
      } else {
        nextProps.formProps.values.freeTrial &&
          nextProps.formProps.setFieldValue('freeTrial', false)
      }
    } else {
      if (
        self_role === 'Individual Investor' ||
        self_role === 'Other/Curious'
      ) {
        nextProps.formProps.values.freeTrial &&
          nextProps.formProps.setFieldValue('freeTrial', false)
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.error && this.props.error !== prevProps.error) {
      const { setErrors } = this.props.formProps
      setErrors({
        email: get(this.props, 'error.data.email[0]'),
        password: get(this.props, 'error.password[0]'),
        refree_code: get(this.props, 'error.refree_code[0]')
      })
    }
  }

  onTermsAndConditionClick = () => {
    this.setState({
      termsCondition: !this.state.termsCondition
    })
  }

  onTermsAndConditionClose = () => {
    this.setState({
      termsCondition: !this.state.termsCondition
    })
  }

  toggleAreYouAn = () => {
    this.setState({
      self_role: !this.state.self_role
    })
  }

  toggleCountry = () => {
    this.setState({
      countryState: !this.state.countryState
    })
  }

  toggleFreeTrial = e => {
    const { formProps } = this.props
    this.setState(
      {
        freeTrial: !this.state.freeTrial,
        isManualChangeFreeTrial: true
      },
      () => {
        formProps.handleChange(e)
      }
    )
  }

  onCountrySelect = value => {
    const { setFieldValue } = this.props.formProps
    setFieldValue('country', value)
    setFieldValue('countryInput', value)
  }

  onCountryChange = e => {
    const { formProps } = this.props
    if (!this.state.countryState) {
      this.setState({
        countryState: true
      })
    }
    formProps.handleChange(e)
  }

  toggleinvestableAssets = () => {
    this.setState({
      investable_assets: !this.state.investable_assets
    }, () => {
      if (this.state.investable_assets) {
        setTimeout(() => {
          document.getElementById('investable_assets_child0').focus()
        }, 100)
      }
    })
  }

  toggleAssetManagement = () => {
    this.setState({
      estimated_net_worth: !this.state.estimated_net_worth
    })
  }

  handleChangeSub = () => {
    const { formProps } = this.props
    const { values, handleSubmit } = formProps
    if (
      values.first_name === '' &&
      values.last_name === '' &&
      values.email === '' &&
      values.password === '' &&
      values.rePassword === '' &&
      values.country === '' &&
      values.zipcode === ''
    ) {
      // console.log('redirect')
      this.props.history.push('/join')
    } else {
      // console.log('redirect2')
      localStorage.setItem('isPlanChange', 'true')
      handleSubmit()
    }
  }

  onStateChange = e => {
    const { formProps } = this.props

    if (e.target.value == '') {
      formProps.setFieldValue('state', e.target.value)
    }

    if (!this.state.statesState) {
      this.setState({
        statesState: true
      })
    }
    formProps.handleChange(e)
  }

  onStateSelect = item => {
    const { setFieldValue } = this.props.formProps
    setFieldValue('state', item.abbreviation)
    setFieldValue('state_value', item.abbreviation)
    setFieldValue('stateInput', item.name)
  }

  toggleStateStatus = () => {
    this.setState({
      statesState: !this.state.statesState
    })
  }

  getSubmitButtonText = (plan_type, self_role) => {
    switch (plan_type) {
      case 'basic':
        const str =
          self_role === '' ||
            self_role === 'Individual Investor' ||
            self_role === 'Other/Curious'
            ? 'next'
            : 'Personalize & Start Discovering'
        return process.env.REACT_APP_SUB_DOMAIN === 'advisor'
          ? 'Personalize & Start Discovering'
          : str
      case 'pro':
        return 'Magnifi pro for $250 month'
      case 'pro-plus':
        return 'Magnifi pro+ for $500 month'
      default:
        return 'Personalize & Start Discovering'
    }
  }

  createFreeMagnifiTradingAccountClick = () => {
    // debugger
    // addEventToAnalytics('Create Free Magnifi Trading Account Click', 'Create Free Magnifi Trading Account Click', 'CREATE_FREE_MAGNIFI_TRADING_ACCOUNT_CLICK', {
    //   clickPage: 'Register Page'
    // })
    const { registerForTrade } = this.props;
    if (registerForTrade) {
      // debugger
      registerForTrade('OPEN_TRADING_REGISTER_PAGE');
    }
  }

  handleDataCaptures = (key, text, capitalized, errors = null) => {
    let email = this.props.formProps && this.props.formProps.values && this.props.formProps.values.email ? this.props.formProps.values.email : ''
    let captureStatusObj = this.state.dataCaptureObj;
    if (key && captureStatusObj[key]) {
      return
    }
    captureStatusObj[key] = true;
    this.setState({ dataCaptureObj: captureStatusObj })
    let captureObj = {
      clickPage: 'Register Page',
      email: email
    };
    if (errors) {
      captureObj['errors'] = errors;
    }
    addEventToAnalytics(text, text, capitalized, captureObj)
  }

  registerToTradeLaterClick = () => {
    // debugger
    // addEventToAnalytics('Register Now Trade Later Click', 'Register Now Trade Later Click', 'REGISTER_NOW_TRADE_LATER_CLICK', {
    //   clickPage: 'Register Page'
    // })
    const { registerForTrade } = this.props;
    if (registerForTrade) {
      // debugger
      registerForTrade(null);
    }
  }

  render() {
    const { formProps, plan_selected, returnedToEdit, profileData } = this.props
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      isValid,
      dirty
    } = formProps

    let renderModal = ''

    if (this.state.termsCondition)
      renderModal = (
        <TermsConditions onTermsClick={this.onTermsAndConditionClose} />
      )

    const handleDateChange = dateInstance => {
      let dob = moment(dateInstance).format('YYYY-MM-DD')
      if (dateInstance) {
        setFieldValue('date_of_birth', dob)
      } else {
        setFieldValue('date_of_birth', '')
      }
    }

    return (
      <Form
        id="RegisterFormRHS"
        innerRef={this.FormRef}
        name="RegisterFormRHS"
        className="magnifi-auth-design-container register-magnifi-auth-design"
        noValidate={true}
        onSubmit={handleSubmit}
      >
        {process.env.REACT_APP_SUB_DOMAIN === 'retail' ? <div className="retail_form_container header-area text-center">
          <h1 className="header-label main">
            Start Today
          </h1>
          <div style={{ visibility: 'hidden', height: 0, overflow: 'hidden' }}>
            <h2>Investing Made Easy</h2>
            <p>At Magnifi we help you identify thematic portfolios for you to choose from, meaning you invest in what matters to you. We’re more than just an investment marketplace, we help you change the way you find & invest in stocks and funds, commission free. </p>
            <h2>Goal-Based Investments & Technology</h2>
            <p>Magnifi has the perfect blend of information and technology available to help any investor meet their goals. Compare holdings, performance, insights and more with just a few clicks - and find the perfect stocks for you. </p>
            <h2>Registration Takes Seconds</h2>
            <p>When you register for a Magnifi account you’ll see how easy we make it. From at-a-glance performance monitoring to informed investment purchases - it all starts with filling out our registration form and funding your Magnifi account. Easier & faster investments are only a few minutes away.</p>
            <h2>Revolutionizing the Way You Invest</h2>
            <p>With the world's only language powered search engine for finance, Magnifi will help you discover ETFs, stocks, and mutual funds. Make adjustments on the fly, and take confident steps towards your future utilizing our insights & metrics. </p>
          </div>
        </div> : <Tagline />}

        {process.env.REACT_APP_SUB_DOMAIN === 'retail' ? <div className="mb-4" style={{ width: '100%' }}>
          <section class="step-indicator" style={{ width: '100%', padding: '0 0.8rem' }}>
            {Array(3).fill().map((el, i) => {
              let stepNames = ['Login', 'About You', 'Disclosures']
              let timer = ['(1 min)', '(1 min)', '(1-2 min)']
              return (
                <>
                  <div class={`step step${i + 1} ${i + 1 <= 1 ? 'active' : ''}`}>
                    <div class="step-icon">{i + 1}</div>
                    <span className={stepNames[i] === 'About You' ? 'set_span_text' : ''}>{stepNames[i]}</span>
                    {/* <span className="timer">{timer[i]}</span> */}
                  </div>
                  {i < 2 ? <div class={`indicator-line ${i + 1 < 1 ? 'active' : ''} ${i + 1 === 1 ? 'nextToActive' : ''}`}></div> : null}
                </>
              )
            })}
          </section>
        </div> : null}

        {process.env.REACT_APP_SUB_DOMAIN === 'retail' ?
          // <div className="header-area">
          //   <div className="header-label sub">
          //     Personalize Your Search Results
          //   </div>
          //   <div className="header-label-sub-gray pt-2">
          //     These Fields Will Help Magnifi Deliver Personalized Search Results For You
          //   </div>
          // </div>
          null : <div className="header-area"><div className="header-label-advisor">
            Help us personalize your search results
          </div></div>}
        {/*main form */}
        <div className="body-wrapper">
          {process.env.REACT_APP_SUB_DOMAIN === 'retail' ? (
            <div className="retail_form_container">
              <span className="heading_in_between">First, let's create your login:</span>
              {/*email*/}
              <Row className="form_input_container_gray border_radius_top">
                <Col>
                  <FormGroup className="element-box pos-rel" onClick={() => {
                    if (!returnedToEdit) {
                      document.getElementById('email').disabled = false
                      document.getElementById('email').focus();
                    }
                  }}>
                    <Label style={{ width: '100%' }} className={values.email ? "label new_label has_value" : "label new_label"} htmlFor="email">
                      E-mail
                    </Label>
                    <Input
                      disabled={true}
                      type="text"
                      name="email"
                      id="email"
                      value={values.email}
                      onChange={(e) => {
                        this.handleDataCaptures('email', 'Email Input', 'EMAIL_INPUT');
                        handleChange(e)
                      }}
                      onBlur={handleBlur}
                      className={
                        errors.email && touched.email
                          ? 'is-invalid input-area'
                          : returnedToEdit ? 'input-area disabled' : 'input-area'
                      }
                    />
                    <CustomFormFeedback formProps={formProps} fieldName="email" />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="form_input_container_gray border_radius_bottom">
                <Col md="12" lg="12" xl={values.password ? "6" : "12"}>
                  {/*password*/}
                  <FormGroup className="element-box pos-rel" onClick={() => {
                    if (!returnedToEdit) {
                      document.getElementById('password').disabled = false
                      document.getElementById('password').focus();
                    }
                  }}>
                    <Label style={{ width: '100%' }} className={values.password ? "label new_label has_value" : "label new_label"} htmlFor="password">
                      Password
                    </Label>
                    <Input
                      type="password"
                      disabled={true}
                      name="password"
                      id="password"
                      value={values.password}
                      onChange={(e) => {
                        this.handleDataCaptures('password', 'Password Input', 'PASSWORD_INPUT');
                        handleChange(e)
                      }}
                      onBlur={handleBlur}
                      className={
                        errors.password && touched.password
                          ? 'is-invalid input-area'
                          : returnedToEdit ? 'input-area disabled' : 'input-area'
                      }
                    />
                    <CustomFormFeedback
                      formProps={formProps}
                      fieldName="password"
                    />
                  </FormGroup>
                </Col>
                {values.password ? <Col md="12" lg="12" xl="6">
                  {/*confirm password*/}
                  <FormGroup className="element-box pos-rel">
                    <Label className={values.rePassword ? "label new_label has_value" : "label new_label"} htmlFor="rePassword">
                      Confirm Password
                    </Label>
                    <Input
                      type="password"
                      disabled={returnedToEdit}
                      name="rePassword"
                      id="rePassword"
                      value={values.rePassword}
                      onChange={(e) => {
                        this.handleDataCaptures('rePassword', 'RePassword Input', 'REPASSWORD_INPUT');
                        handleChange(e)
                      }}
                      onBlur={handleBlur}
                      className={
                        errors.rePassword && touched.rePassword
                          ? 'is-invalid input-area'
                          : returnedToEdit ? 'input-area disabled' : 'input-area'
                      }
                    />
                    <CustomFormFeedback
                      formProps={formProps}
                      fieldName="rePassword"
                    />
                  </FormGroup>
                </Col> : null}
              </Row>

              {/* <Row className="form_input_container_gray">
                <Col md="12" lg="12" xl="6">
                  <FormGroup className="element-box dropdown-element pos-rel">
                    <Label className={values.date_of_birth || this.state.dateHasValue ? "label new_label has_value addtion_icon_label" : "label new_label addtion_icon_label"} htmlFor="date_of_birth">
                      <span className="addition_icon">Date of Birth <span>&dagger;</span></span>
                    </Label>
                    <DatePicker
                      onKeyDown={(e)=> {
                        if(e.key === 'Tab'){
                          e.preventDefault();
                          document.getElementById('investable_assets').click();
                          document.getElementById('investable_assets').focus();
                        }
                      }}
                      id="date_of_birth"
                      selected={
                        values.date_of_birth
                          ? typeof values.date_of_birth.getMonth === 'function'
                            ? values.date_of_birth
                            : moment(values.date_of_birth)._d
                          : values.date_of_birth
                      }
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={
                        new Date(
                          moment()
                            .subtract(18, 'years')
                            .format('YYYY-MM-DD')
                        )
                      }
                      autoComplete="off"
                      onChange={(dateInstance, dateString) =>{
                        this.handleDataCaptures('date_of_birth', 'DOB Input', 'DOB_INPUT');
                        this.setState({ dateHasValue: dateString })
                        handleDateChange(dateInstance)
                      }}
                      className={
                        errors.date_of_birth && touched.date_of_birth
                          ? 'is-invalid input-area form-control'
                          : 'input-area form-control'
                      }
                    />
                    {errors.date_of_birth && touched.date_of_birth && (
                      <div className="form-invalid-field">
                        {errors['date_of_birth']}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="12" lg="12" xl="6">
                  {(values.self_role === 'Individual Investor' ||
                    values.self_role === 'Other/Curious') && (
                      <FormGroup className="element-box dropdown-element pos-rel country-wrapper">
                        <Label className={values.investable_assets ? "label new_label has_value addtion_icon_label" : "label new_label addtion_icon_label"} htmlFor="investable_assets">
                          <span className="addition_icon">Investable Assets <span>&dagger;</span></span>
                        </Label>
                        <Dropdown

                          className={
                            errors.investable_assets && touched.investable_assets
                              ? 'is-invalid dropdown-wrapper'
                              : 'dropdown-wrapper'
                          }
                          isOpen={this.state.investable_assets}
                          toggle={this.toggleinvestableAssets}
                        >
                          <DropdownToggle
                            id="investable_assets"
                            value={values.investable_assets}
                            style={{ backgroundColor: '#fff' }}
                            className={
                              errors.investable_assets &&
                                touched.investable_assets
                                ? 'is-invalid input-area'
                                : 'input-area'
                            }
                            caret
                          >
                            {values.investable_assets || ''}
                          </DropdownToggle>
                          <DropdownMenu center>
                            {investableAssetsList.map((item, ind) => {
                              return (
                                <DropdownItem
                                  value={item}
                                  id={'investable_assets_child'+ind}
                                  onClick={e => {
                                    this.handleDataCaptures('investable_assets', 'Investable Assets Input', 'INVESTIBALE_ASSET_INPUT');
                                    setFieldValue(
                                      'investable_assets',
                                      e.target.value
                                    )
                                  }}
                                >
                                  {item}
                                </DropdownItem>
                              )
                            })}
                          </DropdownMenu>
                        </Dropdown>
                        {errors.investable_assets &&
                          touched.investable_assets && (
                            <div className="form-invalid-field">
                              {errors['investable_assets']}
                            </div>
                          )}
                      </FormGroup>
                    )}
                </Col>
              </Row> */}
              {/* <Row style={{ marginBottom: 10, paddingBottom: '0.85rem' }} className="form_input_container_gray border_radius_bottom">
                <Col md={12} className="text-center">
                  <span className="help_note"><span>&dagger;</span> These fields will guide search suggestions based on user similarity</span>
                </Col>
              </Row> */}
              {/* toggle checkbox  */}
              {plan_selected === 'basic' &&
                values.self_role &&
                !['Individual Investor', 'Other/Curious'].includes(
                  values.self_role
                ) && (
                  <div className={'free-trial'}>
                    <Checkbox
                      value={values.freeTrial}
                      name="freeTrial"
                      id="freeTrial"
                      checked={values.freeTrial}
                      onChange={this.toggleFreeTrial}
                    >
                      Sign Up for a free 14 day trial
                    </Checkbox>
                  </div>
                )}
            </div>
          ) : (
            <>
              {/*f-name l-name*/}
              <Row>
                <Col>
                  <FormGroup className="element-box pos-rel">
                    <Label className="label required" htmlFor="first_name">
                      First Name*
                    </Label>
                    <Input
                      type="text"
                      name="first_name"
                      id="first_name"
                      value={values.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.first_name && touched.first_name
                          ? 'is-invalid input-area'
                          : 'input-area'
                      }
                      placeholder="Enter your first name"
                    />
                    <CustomFormFeedback
                      formProps={formProps}
                      fieldName="first_name"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="element-box pos-rel">
                    <Label className="label required" htmlFor="last_name">
                      Last Name*
                    </Label>
                    <Input
                      type="text"
                      name="last_name"
                      id="last_name"
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.last_name && touched.last_name
                          ? 'is-invalid input-area'
                          : 'input-area'
                      }
                      placeholder="Enter your last name"
                    />
                    <CustomFormFeedback
                      formProps={formProps}
                      fieldName="last_name"
                    />
                  </FormGroup>
                </Col>
              </Row>

              {/*email*/}
              <FormGroup className="element-box pos-rel">
                <Label className="label" htmlFor="email">
                  E-mail*
                </Label>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.email && touched.email
                      ? 'is-invalid input-area'
                      : 'input-area'
                  }
                  placeholder="Enter your email address"
                />
                <CustomFormFeedback formProps={formProps} fieldName="email" />
              </FormGroup>

              {/*password and confirm*/}
              <Row>
                <Col>
                  {/*password*/}
                  <FormGroup className="element-box pos-rel">
                    <Label className="label" htmlFor="password">
                      Password*
                    </Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.password && touched.password
                          ? 'is-invalid input-area'
                          : 'input-area'
                      }
                      placeholder="Enter a password"
                    />
                    <CustomFormFeedback
                      formProps={formProps}
                      fieldName="password"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  {/*confirm password*/}
                  <FormGroup className="element-box pos-rel">
                    <Label className="label" htmlFor="rePassword">
                      Confirm Password*
                    </Label>
                    <Input
                      type="password"
                      name="rePassword"
                      id="rePassword"
                      value={values.rePassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.rePassword && touched.rePassword
                          ? 'is-invalid input-area'
                          : 'input-area'
                      }
                      placeholder="Re-enter password"
                    />
                    <CustomFormFeedback
                      formProps={formProps}
                      fieldName="rePassword"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                {/*country*/}
                <Col>
                  <FormGroup className="element-box dropdown-element pos-rel country-wrapper">
                    <Label className="label required" htmlFor="country">
                      Country*
                    </Label>
                    <Dropdown
                      className={
                        errors.country && touched.country
                          ? 'is-invalid dropdown-wrapper'
                          : 'dropdown-wrapper'
                      }
                      isOpen={this.state.countryState}
                      toggle={this.toggleCountry}
                    >
                      <DropdownToggle
                        value={values.country}
                        style={{ backgroundColor: '#fff' }}
                        className={
                          errors.country && touched.country
                            ? 'is-invalid input-area'
                            : 'input-area'
                        }
                        caret
                      >
                        <input
                          style={{ padding: 0, height: `20px` }}
                          type="search"
                          placeholder="Select a country"
                          name="countryInput"
                          value={values.countryInput}
                          onChange={e => this.onCountryChange(e)}
                          className="typeahead-field"
                          autocomplete="off"
                        />
                      </DropdownToggle>
                      <DropdownMenu>
                        {/* <DropdownItem value={item.name} onClick={(e) => {setFieldValue('country', e.target.value)}}>{item.name}</DropdownItem> */}
                        {countryList.map(item => {
                          if (
                            !values.countryInput ||
                            toLower(item.name).includes(
                              toLower(values.countryInput)
                            )
                          ) {
                            return (
                              <DropdownItem
                                value={item.name}
                                onClick={e =>
                                  this.onCountrySelect(e.target.value)
                                }
                              >
                                {item.name}
                              </DropdownItem>
                            )
                          }
                          return null
                        })}
                        {/* <DropdownItem value="India" onClick={(e) => {setFieldValue('country', e.target.value)}}>India</DropdownItem>
                    <DropdownItem value="U.S.A" onClick={(e) => {setFieldValue('country', e.target.value)}}>U.S.A</DropdownItem> */}
                      </DropdownMenu>
                    </Dropdown>

                    {/* <CustomFormFeedback formProps={formProps} fieldName="country" /> */}
                    {errors.country && touched.country && (
                      <div className="form-invalid-field">
                        {errors['country']}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                {/*state*/}
                <Col lg={{ size: 4 }}>
                  <FormGroup className="element-box dropdown-element pos-rel country-wrapper">
                    <Label className="label required" htmlFor="state">
                      State*
                    </Label>
                    {values.country !== 'United States' ? (
                      <>
                        <Input
                          type="text"
                          name="state"
                          id="state"
                          value={values.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.state && touched.state
                              ? 'is-invalid input-area'
                              : 'input-area'
                          }
                          placeholder="Enter State"
                        />
                      </>
                    ) : (
                      <Dropdown
                        className={
                          errors.state && touched.state
                            ? 'is-invalid dropdown-wrapper'
                            : 'dropdown-wrapper'
                        }
                        isOpen={this.state.statesState}
                        toggle={this.toggleStateStatus}
                      >
                        <DropdownToggle
                          value={values.state}
                          style={{ backgroundColor: '#fff' }}
                          className={
                            errors.state && touched.state
                              ? 'is-invalid input-area'
                              : 'input-area'
                          }
                          caret
                        >
                          <input
                            style={{ padding: 0, height: `20px` }}
                            type="search"
                            placeholder="Select"
                            name="stateInput"
                            value={values.stateInput}
                            onChange={e => this.onStateChange(e)}
                            className="typeahead-field"
                            autoComplete={`stateInput`}
                          />
                        </DropdownToggle>
                        <DropdownMenu>
                          {usStates.map(item => {
                            if (
                              !values.stateInput ||
                              toLower(item.name).includes(
                                toLower(values.stateInput)
                              )
                            ) {
                              return (
                                <DropdownItem
                                  value={item.abbreviation}
                                  onClick={e => this.onStateSelect(item)}
                                >
                                  {item.name}
                                </DropdownItem>
                              )
                            }
                            return null
                          })}
                        </DropdownMenu>
                      </Dropdown>
                    )}
                    {/*<CustomFormFeedback formProps={formProps} fieldName="state" />*/}
                    {errors.state && touched.state && (
                      <div className="form-invalid-field">
                        {errors['state']}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                {/*city*/}
                <Col lg={{ size: 4 }}>
                  <FormGroup className="element-box pos-rel">
                    <Label className="label required" htmlFor="city">
                      City*
                    </Label>
                    <Input
                      type="text"
                      name="city"
                      id="city"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.city && touched.city
                          ? 'is-invalid input-area'
                          : 'input-area'
                      }
                      placeholder="Enter City"
                    />
                    {errors.city && touched.city && (
                      <div className="form-invalid-field">{errors['city']}</div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={{ size: 4 }}>
                  <FormGroup className="element-box pos-rel">
                    <Label className="label required" htmlFor="address">
                      Street*
                    </Label>
                    <Input
                      type="text"
                      name="address"
                      id="address"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.address && touched.address
                          ? 'is-invalid input-area'
                          : 'input-area'
                      }
                      placeholder="Enter Street"
                    />
                    {/* <CustomFormFeedback formProps={formProps} fieldName="street" /> */}
                    {errors.address && touched.address && (
                      <div className="form-invalid-field">
                        {errors['address']}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={{ size: 4 }}>
                  {/*zip*/}
                  <FormGroup className="element-box pos-rel">
                    <Label className="label required" htmlFor="zipcode">
                      Zip code*
                    </Label>
                    <Input
                      type="text"
                      name="zipcode"
                      id="zipcode"
                      value={values.zipcode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.zipcode && touched.zipcode
                          ? 'is-invalid input-area'
                          : 'input-area'
                      }
                      placeholder="Enter the zip code"
                    />
                    <CustomFormFeedback
                      formProps={formProps}
                      fieldName="zipcode"
                    />
                  </FormGroup>
                </Col>
                <Col lg={{ size: 4 }}>
                  <FormGroup className="element-box dropdown-element pos-rel">
                    <Label className="label" htmlFor="date_of_birth">
                      Date of Birth*
                    </Label>
                    <DropdownDatePicker
                      id="date_of_birth"
                      value={values.date_of_birth
                        ? typeof values.date_of_birth.getMonth === 'function'
                          ? values.date_of_birth._d
                          : moment(values.date_of_birth)._d
                        : null}
                      onDateChange={(dateInstance, dateString) => {
                        setFieldValue(
                          'date_of_birth',
                          moment(dateInstance).format('YYYY-MM-DD') || null
                        )
                      }}
                      maxDate={new Date(
                        moment()
                          .subtract(18, 'years')
                          .format('YYYY-MM-DD')
                      )}
                    />
                    {/* <DatePicker
                      id="date_of_birth"
                      selected={
                        values.date_of_birth
                          ? typeof values.date_of_birth.getMonth === 'function'
                            ? values.date_of_birth
                            : moment(values.date_of_birth)._d
                          : values.date_of_birth
                      }
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={
                        new Date(
                          moment()
                            .subtract(18, 'years')
                            .format('YYYY-MM-DD')
                        )
                      }
                      autoComplete="off"
                      onChange={(dateInstance, dateString) => {
                        setFieldValue(
                          'date_of_birth',
                          moment(dateInstance).format('YYYY-MM-DD') || null
                        )
                      }}
                      className={
                        errors.date_of_birth && touched.date_of_birth
                          ? 'is-invalid input-area form-control'
                          : 'input-area form-control'
                      }
                    /> */}
                    {errors.date_of_birth && touched.date_of_birth && (
                      <div className="form-invalid-field">
                        {errors['date_of_birth']}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              {process.env.REACT_APP_SUB_DOMAIN !== 'retail' && (
                <FormGroup className="element-box dropdown-element pos-rel">
                  <Label className="label" htmlFor="refree_code">
                    Are you an*
                  </Label>
                  <Dropdown
                    className={
                      errors.self_role && touched.self_role
                        ? 'is-invalid dropdown-wrapper'
                        : 'dropdown-wrapper'
                    }
                    isOpen={this.state.self_role}
                    toggle={this.toggleAreYouAn}
                    onChange={this.handleAreYouAnChange}
                  >
                    <DropdownToggle
                      value={values.self_role}
                      style={{ backgroundColor: '#fff' }}
                      className={
                        errors.areYouAnOther && touched.areYouAnOther
                          ? 'is-invalid input-area'
                          : 'input-area'
                      }
                      caret
                    >
                      {values.self_role || 'Select'}
                    </DropdownToggle>
                    <DropdownMenu center>
                      {areYouAnList.map(item => {
                        if (
                          process.env.REACT_APP_SUB_DOMAIN === 'advisor' &&
                          item === 'Individual Investor'
                        ) {
                          return null
                        }
                        return (
                          <DropdownItem
                            value={item}
                            onClick={e => {
                              setFieldValue('self_role', e.target.value)
                              if (item !== 'Other/Curious')
                                setFieldValue('areYouAnOther', '')
                            }}
                          >
                            {item}
                          </DropdownItem>
                        )
                      })}
                      {/* <DropdownItem value="Something" onClick={(e) => {setFieldValue('areYouAn', e.target.value); setFieldValue('areYouAnOther', '')}}>Something</DropdownItem>
                <DropdownItem value="Other" onClick={(e) => {setFieldValue('areYouAn', e.target.value)}}>Other</DropdownItem> */}
                    </DropdownMenu>
                  </Dropdown>
                  {/* <CustomFormFeedback formProps={formProps} fieldName="areYouAn" /> */}
                  {errors.self_role && touched.self_role && (
                    <div className="form-invalid-field">
                      {errors['self_role']}
                    </div>
                  )}
                </FormGroup>
              )}

              {/*Individual Investor or other */}
              {(values.self_role === 'Individual Investor' ||
                values.self_role === 'Other/Curious') && (
                  <FormGroup className="element-box dropdown-element pos-rel country-wrapper">
                    <Label className="label required" htmlFor="country">
                      Investable Assets
                    </Label>
                    <Dropdown
                      className={
                        errors.investable_assets && touched.investable_assets
                          ? 'is-invalid dropdown-wrapper'
                          : 'dropdown-wrapper'
                      }
                      isOpen={this.state.investable_assets}
                      toggle={this.toggleinvestableAssets}
                    >
                      <DropdownToggle
                        value={values.investable_assets}
                        style={{ backgroundColor: '#fff' }}
                        className={
                          errors.investable_assets && touched.investable_assets
                            ? 'is-invalid input-area'
                            : 'input-area'
                        }
                        caret
                      >
                        {values.investable_assets || 'Select'}
                      </DropdownToggle>
                      <DropdownMenu center>
                        {investableAssetsList.map(item => {
                          return (
                            <DropdownItem
                              value={item}
                              onClick={e => {
                                setFieldValue('investable_assets', e.target.value)
                              }}
                            >
                              {item}
                            </DropdownItem>
                          )
                        })}
                        {/* <DropdownItem value="India" onClick={(e) => {setFieldValue('country', e.target.value)}}>India</DropdownItem>
                <DropdownItem value="U.S.A" onClick={(e) => {setFieldValue('country', e.target.value)}}>U.S.A</DropdownItem> */}
                      </DropdownMenu>
                    </Dropdown>

                    {/* <CustomFormFeedback formProps={formProps} fieldName="country" /> */}
                    {errors.investable_assets && touched.investable_assets && (
                      <div className="form-invalid-field">
                        {errors['investable_assets']}
                      </div>
                    )}
                  </FormGroup>
                )}

              {/*NOT Individual Investor or other */}
              {values.self_role &&
                !['Individual Investor', 'Other/Curious'].includes(
                  values.self_role
                ) && (
                  <FormGroup className="element-box dropdown-element pos-rel country-wrapper">
                    <Label className="label required" htmlFor="country">
                      Assets Under Management/ Advice ?
                    </Label>
                    <Dropdown
                      className={
                        errors.estimated_net_worth &&
                          touched.estimated_net_worth
                          ? 'is-invalid dropdown-wrapper'
                          : 'dropdown-wrapper'
                      }
                      isOpen={this.state.estimated_net_worth}
                      toggle={this.toggleAssetManagement}
                    >
                      <DropdownToggle
                        value={values.estimated_net_worth}
                        style={{ backgroundColor: '#fff' }}
                        className={
                          errors.estimated_net_worth &&
                            touched.estimated_net_worth
                            ? 'is-invalid input-area'
                            : 'input-area'
                        }
                        caret
                      >
                        {values.estimated_net_worth || 'Select'}
                      </DropdownToggle>
                      <DropdownMenu center>
                        {assetUnderManagementList.map(item => {
                          return (
                            <DropdownItem
                              value={item}
                              onClick={e => {
                                setFieldValue(
                                  'estimated_net_worth',
                                  e.target.value
                                )
                              }}
                            >
                              {item}
                            </DropdownItem>
                          )
                        })}
                      </DropdownMenu>
                    </Dropdown>
                    {errors.estimated_net_worth &&
                      touched.estimated_net_worth && (
                        <div className="form-invalid-field">
                          {errors['estimated_net_worth']}
                        </div>
                      )}
                  </FormGroup>
                )}

              {/* toggle checkbox  */}
              {plan_selected === 'basic' &&
                values.self_role &&
                !['Individual Investor', 'Other/Curious'].includes(
                  values.self_role
                ) && (
                  <div className={'free-trial'}>
                    <Checkbox
                      value={values.freeTrial}
                      name="freeTrial"
                      id="freeTrial"
                      checked={values.freeTrial}
                      onChange={this.toggleFreeTrial}
                    >
                      Sign Up for a free 14 day trial
                    </Checkbox>
                  </div>
                )}
            </>
          )}
        </div>

        <FormGroup className="cta-wrapper">
          {process.env.REACT_APP_SUB_DOMAIN !== 'retail' && (
            <div onClick={this.handleChangeSub}>
              <p className={'change-sub'}>Change Your Subscription Plan</p>
            </div>
          )}

          <div style={{ width: '100%' }} onClick={() => {
            let erro = formProps.dirty ? Object.keys(formProps.errors).map(key => formProps.errors[key]) : 'All fields are empty';
            if (!dirty && returnedToEdit) {
              this.handleDataCaptures('', 'Register Button Clicked', 'REGISTER_BUTTON_CLICKED');
            } else {
              this.handleDataCaptures('', isValid ? 'Register Button Clicked' : 'Register Button Clicked With Invalid Details', isValid ? 'REGISTER_BUTTON_CLICKED' : 'REGISTER_BUTTON_CLICKED_WITH_INVALID_DETAILS', erro);
            }
            this.createFreeMagnifiTradingAccountClick()
          }}>
            <PrimaryButton
              type="submit"
              className="btn primary-btn"
              style={{ marginTop: '6px', marginBottom: '15px' }}
            >
              Continue
            </PrimaryButton>
          </div>

          {/* <div style={{ width: '100%' }} onClick={this.registerToTradeLaterClick}>
            <SecondaryButton
              type="submit"
              className="btn secondary-btn register-trade-later-btn"
              style={{ marginTop: '15px', marginBottom: '15px' }}
            >
              Register Now, Open Investment Account Later
            </SecondaryButton>
          </div> */}

          <div className="auth-provider">
            <div className="item" onClick={() => this.handleDataCaptures('', 'Register With Google Clicked', 'REGISTER_WITH_GOOGLE_CLICKED')}>
              <GoogleLogin
                clientId={config.googleClientId}
                render={renderProps => (
                  <SecondaryButton
                    className="btn secondary-btn sign-in__googleBtn"
                    onClick={renderProps.onClick}
                  >
                    <img src={googleLogo} width={20} style={{ padding: '.27rem 0' }} alt="google-logo" />
                    <span style={{ paddingLeft: 10, fontSize: '14px', fontWeight: 600 }}>Continue With Google</span>
                  </SecondaryButton>
                )}
                onSuccess={this.props.onGoogleSignInSuccess}
                onFailure={this.props.onGoogleSignInError}
              />
            </div>
            <div className="item">
              <AppleLogin>
                <button
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    background: "white",
                    color: "#56a9e8",
                    border: "1px solid #56a9e8",
                    borderRadius: "100px",
                    padding: ".7em",
                    // paddingRight: "18px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "14px",
                    fontWeight: 600
                  }}
                >
                  <AppleLogoImg onClick={() => this.handleDataCaptures('', 'Register With Apple Clicked', 'REGISTER_WITH_APPLE_CLICKED')} />
                  {/* <AppleFilled style={{ color: "black", fontSize: "1.5em" }} /> */}
                  <span onClick={() => this.handleDataCaptures('', 'Register With Apple Clicked', 'REGISTER_WITH_APPLE_CLICKED')} style={{ paddingLeft: "6px" }}>Continue With Apple</span>
                </button>
              </AppleLogin>
            </div>
          </div>



          <div className="or-partition">or</div>

          <div className="or-partition fs-20 mb-3" onClick={() => this.handleDataCaptures('', 'Already Registered', 'ALREADY_REGISTERED')}>
            Already have an account?
            <Link className="hyper-link fs-20" style={{ textDecoration: 'underline' }} to="/login">
              {' '}
              Login
            </Link>
          </div>
        </FormGroup>
      </Form >
    )
  }
}

const Tagline = () => (
  <div className="mb-3" style={{ width: '90%' }}>
    <a className="logo-wrapper" href={REDIRECT_DOMAIN_PATH}>
      <img src={magnifiLogo} width="100%" alt="magnifi" />
    </a>
  </div>
)

const BackButton = ({ clickHandler }) => (
  <div className="align-self-stretch h1 mb-0 px-3 pt-3">
    <IconButton
      onClick={clickHandler}
      iconClass="fa-long-arrow-left"
      style={{ fontSize: '38px' }}
    />
  </div>
)

const RegisterFormLHS = () => (
  <div className="text-center w-75 carousel-panel">
    <h1 className="header-1">Why Personalize?</h1>
    <MessagesCarousel />
  </div>
)

const MessagesCarousel = props => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)
  const items = LHSMsg

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = newIndex => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = items.map(item => {
    return (
      <CarouselItem
        className="carouselImg"
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
        id="caraousel-id"
      >
        <img src={item.src} alt={item.altText} />
        <h4 style={{ color: '#fff', fontWeight: '400', fontSize: '20px' }}>
          {item.caption}
        </h4>
      </CarouselItem>
    )
  })

  return (
    <Carousel
      interval={2000}
      ride="carousel"
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
        className="dots"
        id="caraousel-dots"
      />
      {slides}
    </Carousel>
  )
}

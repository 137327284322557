import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu, Dropdown, Row, Col } from 'antd';
import UploadSection from './UploadSection';
import PortfolioUpload from '../../ClientPortfolio/Elements/PortfolioUpload/index';
import PfListPreview from '../../ClientPortfolio/Elements/PfListPreview/index';

import { investmentLoading } from 'actions/investment';
import {deflatLastNode} from 'Utils'

import {
  DownOutlined,
} from '@ant-design/icons';

const { SubMenu } = Menu;

class SelectPortfolioForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [ 'All', ...props.brokers],
      showClientUploadModal: false,
      uploadDemo: false
    };
  }

  uploadFile = () => {
    console.log('uploadFile');
  }

  downloadFile = () => {
    console.log('downloadFile');
  }

  onFileSuccess = () => {
    const { onFileSuccess } = this.props;
    if (onFileSuccess) {
      onFileSuccess();
    }
  }

  selectedPortfolio = (portfolioName) => {
    const { selectedPortfolio } = this.props;
    if (selectedPortfolio && portfolioName) {
      selectedPortfolio(portfolioName);
    }
  }

  deletePortfolio = (portfolioName) => {
    const { deletePort } = this.props;
    if (deletePort && portfolioName) {
      deletePort(portfolioName);
    }
  }

  openChange = () => {
    const element = document.querySelector('.market-popup-wrapper')
    const dropdownEl = document.querySelectorAll('.ant-dropdown-menu-submenu-title');

    if (element != null) {
      element.style.height = element.clientHeight > 0 && `${element.clientHeight}px`
    }
    if(dropdownEl){
      dropdownEl.forEach((el, i) => {
        dropdownEl[i].title = '';
      })
    }
    // const head = document.querySelector('.market-popup-head');
    // if (head && head.classList) {
    //   head.classList.remove('ant-dropdown-menu');
    // }
    // const popup = document.querySelector('.market-popup');
    // if (popup && popup.classList) {
    //   popup.classList.remove('ant-dropdown-menu');
    // }
  }

  clickHandler = (data) => {
    const { selectedClientPortfolio } = this.props;
    if (selectedClientPortfolio) {
      selectedClientPortfolio(data);
    }
  }

  clientUploadModalToggle = (val, uploadDemo) => {
    this.setState({
      showClientUploadModal: val,
      uploadDemo: uploadDemo && uploadDemo
    })
  }

  goToSelectedPortfolio = (selectedClient) => {
    this.clickHandler(selectedClient.original_portfolio);
  }

  showLoader = () => {
    this.props.investmentLoading(true);
  }

  render() {
    const { number, className, setContent, loading, valid, portfolioList, marketPlaceList, setWatchlist, setActiveAccount, setMarketPlace, setDemoPortfolio, clientList, clientListLoading } = this.props;
    const { accounts, showClientUploadModal, uploadDemo } = this.state;
    const { client_exist, uploadedClientList, showUpdateColumnInPreview } = this.props;

    let { obj:newMarketPlaceList } = deflatLastNode(marketPlaceList)

    const menu = (
      <Menu >
        {
          accounts.length > 0 && accounts.map((e,i) => (
            <Menu.Item key={i} onClick={() => setActiveAccount(e)}>
              {e}
            </Menu.Item>
          ))
        }
      </Menu>
    );

    const portfolioMenus = () => {
      // <div className="upload_btn" onClick={() => setDemoPortfolio()}>
      //   Use Demo Portfolio
      // </div>
      if (portfolioList && portfolioList.message) {
        return (
          <Menu>
            <Menu.Item key="Your-watchlist">
              <div className="upload_btn" onClick={() => setWatchlist()}>
              Your Watchlist
              </div>
            </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="Demo_portfolio">
            <UploadSection className="upload_btn" fileFor="Demo_portfolio" onFileSuccess={this.onFileSuccess} setContent={setContent}>
              Use Demo Portfolio
            </UploadSection>
          </Menu.Item>
            <Menu.Item key="Upload_portfolio">
              <UploadSection className="upload_btn" fileFor="Custom_portfolio" onFileSuccess={this.onFileSuccess} setContent={setContent}>
                Upload Portfolio
              </UploadSection>
            </Menu.Item>
          </Menu>
        )
      }
      const aa = portfolioList && portfolioList.length && portfolioList.map((item, index) => {
        return (
          <Menu.Item key={item.portfolio_name+index}>
            <div className="portfolio_name-label" onClick={() => { this.selectedPortfolio(item.portfolio_name) }}>
              {item.portfolio_name}
            </div>
            <div className="delete-icon" onClick={() => this.deletePortfolio(item.portfolio_name)}>
              <i class="far fa-fw fa-trash-alt"></i>
            </div>
          </Menu.Item>
        )
      });
      return (
        <Menu>
          <Menu.Item key="Your-watchlist">
              <div className="upload_btn" onClick={() => setWatchlist()}>
              Your Watchlist
              </div>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="Demo_portfolio">
            <UploadSection className="upload_btn" fileFor="Demo_portfolio" onFileSuccess={this.onFileSuccess} setContent={setContent}>
              Use Demo Portfolio
            </UploadSection>
          </Menu.Item>
          <Menu.Item key="Upload_portfolio">
            <UploadSection className="upload_btn" fileFor="Custom_portfolio" onFileSuccess={this.onFileSuccess} setContent={setContent}>
              Upload Portfolio
            </UploadSection>
          </Menu.Item>
          {aa}
        </Menu>
      )
    }

    const marketPlaceDropMenus = (
      <Menu onOpenChange={this.openChange}>
        {
          newMarketPlaceList && typeof newMarketPlaceList == 'string' &&
          <div></div>
        }
        {
          newMarketPlaceList && typeof newMarketPlaceList == 'object' && Object.keys(newMarketPlaceList).length > 0 && Object.keys(newMarketPlaceList).map((item, index) => {
            return (
                <SubMenu key={item} title={item} className="dropdown-style" popupClassName="market-popup-head enhancer-onboard-screen-dropdown  dropdown-style">
                   {/* <div className="portfolio_name-label" onClick={() => setMarketPlace(item.ticker)}>
                      BlackRock {item.name}
                    </div> */}
                  {/* <div className="portfolio_name-label">
                    {item}
                  </div> */}
                    {
                      Object.keys(newMarketPlaceList[item]).length > 0 && Object.keys(newMarketPlaceList[item]).map((subItem, index) => {
                        if(subItem == 'uncatagorized'){
                          return (<div className="market-popup-wrapper">
                            {
                              newMarketPlaceList[item][subItem].length > 0 && newMarketPlaceList[item][subItem].map((subItem1, index) => {
                                return (
                                  // <Menu.Item key={subItem1.name} onClick={() => setMarketPlace(subItem1.ticker)}>
                                    <div className="market_name-label dropdown-style" onClick={() => setMarketPlace(subItem1.ticker)}>
                                      {subItem1.name}
                                    </div>
                                  // </Menu.Item>
                                )
                              })
                            }
                          </div>)
                        }
                        else{
                          return (
                            <SubMenu key={subItem} title={subItem} className="dropdown-style" popupClassName="market-popup dropdown-style">
                              <div className="market-popup-wrapper">
                                {
                                  newMarketPlaceList[item][subItem].length > 0 && newMarketPlaceList[item][subItem].map((subItem1, index) => {
                                    return (
                                      // <Menu.Item key={subItem1.name} onClick={() => setMarketPlace(subItem1.ticker)}>
                                        <div className="market_name-label dropdown-style" onClick={() => setMarketPlace(subItem1.ticker)}>
                                          {subItem1.name}
                                        </div>
                                      // </Menu.Item>
                                    )
                                  })
                                }
                              </div>
                            </SubMenu>
                          )
                        }
                      })
                    }
                </SubMenu>
            )
          })
        }
        {
          newMarketPlaceList && newMarketPlaceList.message && newMarketPlaceList.message == 'No tickers Present.' &&
            <Menu.Item key='0'>
              <div className="portfolio_name-label" >
                No data
              </div>
            </Menu.Item>
        }
      </Menu>
    );

    const clientListMenuItems = clientList && clientList.length && clientList.map((item, index) => {
      return (
        <Menu.Item key={item.name+item.id}>
          <div className="portfolio_name-label" onClick={() => { this.clickHandler(item.original_portfolio) }}>
            {item.name}
          </div>
        </Menu.Item>
      )
    });

    const clientListMenu = (
      <Menu>
       <Menu.Item key="use_demo">
          <div className="upload_btn" onClick={() => this.clientUploadModalToggle(true, true)}>
            Use Demo Account
          </div>
        </Menu.Item>
        <Menu.Item key="upload_portfolio">
          <div className="upload_btn" onClick={() => this.clientUploadModalToggle(true)}>
            Add Account
          </div>
        </Menu.Item>
        {clientListMenuItems}
      </Menu>
    );

    return (
      <React.Fragment>
        {/*
          <div className="select-portfolio-text">Select Portfolio From:
            <hr className="select-underline"/>
          </div>
        */}
        <div class="mc-container">
          {showClientUploadModal &&
            <PortfolioUpload
              showClientUploadModal={showClientUploadModal}
              clientUploadModalToggle={this.clientUploadModalToggle}
              client_exist={client_exist}
              clientList={clientList}
              uploadDemo={uploadDemo}
            />
          }
          {uploadedClientList.length > 0 &&
            <PfListPreview
              clientList={clientList}
              pfList={uploadedClientList}
              showUpdateColumnInPreview={showUpdateColumnInPreview}
              allowSelection
              callback={this.goToSelectedPortfolio}
              clientUploadModalToggle={() => this.clientUploadModalToggle(true, false)}
              showLoader={this.showLoader}
            />
          }
          {/*
            <div class="item1">
              <Dropdown overlayClassName="market-place-dropdown" overlay={marketPlaceDropMenus}>
                <div className="block">
                  <div className="imgBlock">
                      <img src="/assets/images/SponsorProvidedModel-White.svg" style={{color:'white'}}/>
                  </div>
                  <div className="detailsBlock">
                    <div className="head">
                      Sponsor Models <DownOutlined />
                    </div>
                  </div>
                </div>
              </div>
              
                <div className="label">
                  Use Sponsor Provided Models
                </div>
              
            </div>*/
           }
          <div class="item1">
            <Dropdown overlayClassName="portfolioMenuListWrapper" overlay={portfolioMenus()}>
              <div className="block">
                <div className="imgBlock">
                  <img src="/assets/images/MyPortfolios-White.svg" />
                </div>
                <div className="detailsBlock">
                  <div className="head">
                    My Models <DownOutlined />
                  </div>
                  {/*  <div className="sub-head">
                     Use Uploaded Portfolio
                   </div> */}
                </div>
              </div>
              {/*
                <div className="label">
                  Use Uploaded Portfolio
                </div>
              */}
            </Dropdown>
          </div>
          <div class="item1">
            <Dropdown overlayClassName="portfolioMenuListWrapper" overlay={clientListMenu}>
              <div className="block">
                <div className="imgBlock">
                  <img src="/assets/images/client-icon.svg" style={{ width: 32 }} />
                </div>
                <div className="detailsBlock">
                  <div className="head">
                    Client Accounts <DownOutlined style={{position:'relative'}}/>
                  </div>
                </div>
              </div>
            </Dropdown>


            {/*
              <div className="label">
                Use Connected Accounts
              </div>
            */}
          </div>
          {/*
            <div class="item1">
              <div className="block">
                <div className="imgBlock">
                  <img src="/assets/images/client-icon.svg" style={{ width: 26 }} />
                </div>
                <div className="detailsBlock">
                  <div className="head">
                    <Dropdown overlayClassName="brokerListWrapper" overlay={menu}>
                      <span>Client Accounts <DownOutlined style={{position:'relative'}}/></span>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="label">
                Use Connected Accounts
              </div>
            </div>
          */}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({
  clientportfolio: { uploadedClientList, showUpdateColumnInPreview },
  auth: { client_exist, user_advisor_menu },
  investment: { copyOfRedirectFrom }
}) => ({
  uploadedClientList,
  showUpdateColumnInPreview,
  client_exist,
  user_advisor_menu
})

const mapDispatchToProps = {
  investmentLoading
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectPortfolioForm);

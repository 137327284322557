import React, { useState } from 'react';

const InnerPageFooter = (props) => {
    const [showMore, setShowMore] = useState(false);

    return (
        <footer style={props.style}>
            <a href="https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/terms_of_use.pdf" target="_blank">Terms &#38; Conditions</a>&nbsp;
            | <a href="https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/privacy_policy.pdf" target="_blank">Privacy Policy</a>&nbsp;
            | <a href="https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/cookie_policy.pdf" target="_blank">Cookie Policy</a>&nbsp;
            | <a href="https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/crs_309397.pdf" target="_blank">Form CRS</a>&nbsp;
            | <a href="https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/Magnifi%20-%20Part%202A%20Brochure%207-26-2021.docx.pdf" target="_blank">Part2 Brochure</a>&nbsp;
            | 
            <span style={{ lineHeight: '19px' }}>&#169; 2022 Magnifi All rights reserved. Patent Pending</span>
            {!props.noText ? <div style={{ padding: 10, paddingTop: 10, textAlign: 'justify', textJustify: 'inter-word', fontSize: 12, color: 'rgb(169, 169, 169)', lineHeight: '19px', fontStyle: 'italic' }}>
                <p className="mb-0"><span style={{ color: 'rgb(30, 95, 145)', fontWeight: 'bold', fontSize: 12 }}>Advisory services</span> are offered through Magnifi LLC, an SEC Registered Investment Advisor. Being registered as an investment adviser does not imply a certain level of skill or training. The information contained herein should in no way be construed or interpreted as a solicitation to sell or offer to sell advisory services to any residents of any State where notice-filed or otherwise legally permitted. {!showMore ? <a style={{ fontSize: 13 }} href="javascript:void(0)" onClick={()=> setShowMore(true)}>Read More</a>:null}{showMore ? "All content is for information purposes only. It is not intended to provide any tax or legal advice or provide the basis for any financial decisions. Nor is it intended to be a projection of current or future performance or indication of future results. Moreover, this material has been derived from sources believed to be reliable but is not guaranteed as to accuracy and completeness and does not purport to be a complete analysis of the materials discussed. Purchases are subject to suitability. This requires a review of an investor's objective, risk tolerance, and time horizons. Investing always involves risk and possible loss of capital.":null}</p>
                {showMore ? <p>Opinions expressed herein are solely those of Magnifi LLC and our editorial staff. The information contained in this material has been derived from sources believed to be reliable but is not guaranteed as to accuracy and completeness and does not purport to be a complete analysis of the materials discussed. All information and ideas should be discussed in detail with your individual adviser prior to implementation. Images and photographs are included for the sole purpose of visually enhancing the website. <a style={{ fontSize: 13 }} href="javascript:void(0)" onClick={()=> setShowMore(false)}>Show Less</a></p>:null}
            </div>:null}
        </footer>
    )
}

export default InnerPageFooter;

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get, find } from 'lodash'
import { connect } from 'react-redux'

import {
  CloseButton,
  PrimaryButton,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from 'shared-components'

import {
  Alert,
  Row,
  Col,
  Input,
  FormGroup,
  Form,
  FormFeedback
} from 'reactstrap'
import { Formik, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { BrokerAccountList } from './BrokerAccountList'

import { isDemoUser, BrokersList, BrokerNameMap } from '../../../../DataSet'
import { createLoadingSelector } from 'selectors/loading-selector'
import { CustomFormFeedback } from 'shared-components'

import {
  fetchBrokerAccounts,
  importBrokerAccounts,
  setDefaultAccount
} from 'actions/broker'

class BrokerAccountListContainer extends Component {
  state = { defaultSelectedAccount: null }

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.fetchBrokerAccounts(this.props.match.params.brokerName)
  }

  componentDidUpdate(prevProps) {
    if (this.props.importAccountsSuccess !== prevProps.importAccountsSuccess) {
      if (this.props.importAccountsSuccess) {
        // this.props.history.push('/settings/broker')
        const defaultedAccount = this.state.defaultSelectedAccount
        if (defaultedAccount) {
          this.props.setDefaultAccount({
            account_number: defaultedAccount.account_number,
            brokerName: this.props.match.params.brokerName
          })
        } else {
          this.props.history.push('/settings/broker')
        }
      }
    }

    if (this.props.setAsDefaultSuccess !== prevProps.setAsDefaultSuccess) {
      if (this.props.setAsDefaultSuccess) {
        this.props.history.push('/settings/broker')
        // this.props.fetchBrokerAccounts(this.props.match.params.brokerName)
      }
    }
  }

  accountSelectionSchema = Yup.object().shape({
    accountIds: Yup.array().required('At least one account is required')
    // defaultAccountsId: Yup.array().required(
    //   'At least one account needs to be set as default'
    // )
  })

  handleToggle = () => {
    this.props.history.push('/settings/broker')
  }

  handleSelectAccount = (e, account, formValues) => {
    // Remove set as default if no longer imported or going to be imported account
    if (!e.target.checked) {
      const selectedAccount = this.state.defaultSelectedAccount
      if (
        selectedAccount &&
        selectedAccount.account_number === account.account_number
      ) {
        formValues.defaultAccountsId = formValues.defaultAccountsId.filter(
          accNo => accNo !== account.account_number
        )
        this.setState({
          defaultSelectedAccount: null
        })
      }
    }
  }

  handleSetAsDefault = (e, account, accounts) => {
    const { brokerName } = this.props.match.params

    // if (e.target.checked) {
    //   this.props.setDefaultAccount({
    //     account_number: account.account_number,
    //     brokerName: brokerName
    //   })
    // }

    if (e.target.checked) {
      this.setState({
        defaultSelectedAccount: { ...account }
      })
    } else {
      this.setState({
        defaultSelectedAccount: null
      })
    }
  }

  getBrokerForAccount = accountId => {
    return get(
      find(this.props.brokerAccounts, {
        account_number: accountId
      }),
      'broker',
      ''
    )
  }

  handleSubmit = values => {
    console.log('account import submit', values)
    const selectedAccounts = values.accountIds.map(account_number => ({
      account_number,
      broker: this.getBrokerForAccount(account_number)
    }))
    var brokerName = this.props.match.params.brokerName
    this.props.importBrokerAccounts(selectedAccounts, brokerName)
  }

  render() {
    const {
      error,
      match,
      brokerAccounts = [],
      selectedBroker,
      isFetching,
      fetchAccountError,
      importAccountError,
      importAccountsSuccess,
      setAsDefaultFailed
    } = this.props

    const brokerName = match.params.brokerName
    const broker = BrokersList.find(b => b.name === brokerName)
    const defaultAccounts = brokerAccounts.find(a => a.default_account)
    const defaultAccountsId =
      (defaultAccounts && [defaultAccounts.account_number]) || []
    const defaultSelectedAccount =
      this.state.defaultSelectedAccount || defaultAccounts
    return (
      <Formik
        initialValues={{
          accountIds: [],
          defaultAccountsId: defaultAccountsId || []
        }}
        onSubmit={this.handleSubmit}
        validationSchema={this.accountSelectionSchema}
        enableReinitialize
        render={props => (
          <Form
            className="broker-account-form"
            noValidate={true}
            onSubmit={props.handleSubmit}
          >
            <Modal
              isOpen={true}
              toggle={this.handleToggle}
              keyboard
              centered
              className="broker-account-modal"
            >
              <ModalHeader>
                <CloseButton onClick={this.handleToggle} />
                Select Account
              </ModalHeader>
              <ModalBody>
                <FormGroup className="text-center">
                  <img src={broker.imgSrc} />
                </FormGroup>
                {fetchAccountError ? (
                  <Alert color="danger" className="text-center">
                    {fetchAccountError.message}
                  </Alert>
                ) : null}

                {importAccountError ? (
                  <Alert color="danger" className="text-center">
                    {importAccountError.message}
                  </Alert>
                ) : null}

                {setAsDefaultFailed ? (
                  <Alert color="danger" className="text-center">
                    Unable to set as default account
                  </Alert>
                ) : null}

                <FormGroup className="text-left">
                  <Input type="hidden" className="is-invalid" />
                  <CustomFormFeedback
                    formProps={props}
                    fieldName="accountIds"
                  />
                  {/*<CustomFormFeedback
                    formProps={props}
                    fieldName="defaultAccountsId"
                  />*/}
                </FormGroup>
                <Spinner isFetching={isFetching} />
                <BrokerAccountList
                  accounts={brokerAccounts}
                  formProps={props}
                  defaultSelectedAccount={defaultSelectedAccount}
                  onSetDefaultAccount={this.handleSetAsDefault}
                  onSelectAccount={this.handleSelectAccount}
                />
              </ModalBody>
              {brokerAccounts.length ? (
                <ModalFooter>
                  <PrimaryButton
                    type="submit"
                    className="brokers-signin-form__submit"
                    onClick={props.handleSubmit}
                  >
                    Submit
                  </PrimaryButton>
                </ModalFooter>
              ) : null}
            </Modal>
          </Form>
        )}
      />
    )
  }
}

const mapStateToProps = state => ({
  brokerAccounts: state.fetchBrokerAccount.accounts,
  fetchAccountError: state.fetchBrokerAccount.error,
  importAccountError: state.importBrokerAccount.error,
  importAccountsSuccess: state.importBrokerAccount.importAccountsSuccess,
  setAsDefaultSuccess: state.setDefaultAccount.success,
  setAsDefaultFailed: state.setDefaultAccount.failed,
  isFetching: createLoadingSelector([
    'FETCH_BROKER_ACCOUNT',
    'IMPORT_BROKER_ACCOUNT',
    'SET_AS_DEFAULT'
  ])(state)
})

const mapDispatchToProps = {
  fetchBrokerAccounts,
  importBrokerAccounts,
  setDefaultAccount
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrokerAccountListContainer)

import React from 'react';
import { connect } from 'react-redux';
import { setSFMCatData, setSFMState } from 'actions/sfm';
import { ModalFooter } from 'reactstrap';
import { Footer } from './Footer';
import ItemList from './ItemList';

class CompareTab extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    opts: {},
  }

  componentDidMount() {
    if (typeof this.props.accountsPage === 'undefined') {
      this.props.compareStats(this.props.items, this.props.scoreAttrs.filter(x => x.weight !== 0));
    }
    this.props.actionHandler('Compare', 'all');
  }

  onAlertDismiss = () => {
    const { onAlertDismiss } = this.props;
    if (onAlertDismiss) {
      onAlertDismiss();
    }
  }

  shareCompareState = (state) => {
    // console.log(state);
    const { shareCompareState } = this.props;
    if (shareCompareState) {
      shareCompareState(state);
    }
  }

  render() {
    return (
      <div>
        <div className="modal-body-ssf">
          <ItemList
            {...this.props}
            shareCompareState={this.shareCompareState}
          />
        </div>
        { !this.props.mobileDevice &&
          <ModalFooter className="py-2 media-none sfm-modal-footer">
            <Footer {...this.props} />
          </ModalFooter>
        }
      </div>
    )
  }
}

const mapStateToProps = ({ sfm, weights }) => ({
  sfm,
  defaultCalculator: weights.defaultCalculator,
})

const mapDispatchToProps = {
  setSFMState,
  setSFMCatData,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompareTab)

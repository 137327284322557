import React, { Component } from 'react';
import { createAPIInstance } from 'apis/api';
import ErrorBoundary from 'components/shared/ErrorBoundary';
import Content from './Content';
import { PortfolioContextProvider } from './PortfolioContext'
import { processAndValidateUrls, sanitizeInput } from '../utils';

export const fetchReportData = async (requestQuery) => {
  return createAPIInstance()
    .get('/user/get-compare-reports', {params: {id: requestQuery}})
    .then(response => response.data)
}
class PortfolioReportV2 extends Component {
  constructor (props) {
    super(props);
    this.state = {
      id: '',
      reportData: '',
    };
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const rid = query.get('id')
    if (!rid) return;
    this.setState({ id: rid })
    fetchReportData(rid).then(
      response => {
        if (response && response.length > 0 && response.message !== 'No Data Present') {
          const __data = JSON.parse(response[0].json);
          // processAndValidateUrls(__data);
          const updatedData = sanitizeInput(__data);
          this.setState({ 
            reportData: { 
              ...updatedData,
              reportCreatedAt: response[0].created_at
            },
          });
        }
      }  
    );
    document.body.classList.add('portfolio-reports-v2');
  }

  componentWillUnmount() {
    document.body.classList.remove('portfolio-reports-v2');
  }

  render() {
    if (!this.state.reportData) return null;
    return (
      <ErrorBoundary>
        <PortfolioContextProvider value={this.state.reportData}>
          <Content />
        </PortfolioContextProvider>
      </ErrorBoundary>
    )
  }
}

export default PortfolioReportV2;

import React from 'react';
import { fixedValue, handleNoData, mul100 } from '../shared/Utils'
import BestTickerBadge from '../shared/BestTickerBadge';

const FactorExposuresTable = (props) => {
  const { selFunds, bestTicker } = props;
  return (
    <table className="perf-table factor-expo-tbl">
      <thead>
        <tr className="perf-tbl-header">
          <th
            rowspan="2"
            colspan="1"
            style={{
              minWidth: '110px',
              width: '110px',
              maxWidth: '110px',
              textAlign: 'left',
              paddingLeft: `20px`
            }}
          >
            Funds
          </th>
          <th
            colspan="6"
          >
            Popular Factor Exposures <br />
            <span className="fund-ret-sub-header">
              ret<sub>fund</sub> - r<sub>f</sub> = b<sub>mkt</sub>(ret<sub>mkt</sub> - r<sub>f</sub>) + b<sub>value</sub>(HML) + b<sub>size</sub>(SMB) + b<sub>mom</sub>(MOM) + Ɛ
            </span>
          </th>
          <th
            style={{
              minWidth: '220px',
              width: '220px',
              maxWidth: '220px',
            }}
          >
            Market Exposure <br />
            <span className="fund-ret-sub-header">
              ret<sub>fund</sub> - r<sub>f</sub> = beta(ret<sub>mkt</sub> - r<sub>f</sub>) + Ɛ
            </span>
          </th>
        </tr>
        <tr className="perf-tbl-sub-header">
          <th>Alpha (%)</th>
          <th>Market <br />
            Comparison ETF(SPY)*</th>
          <th>Value</th>
          <th>Size</th>
          <th>Momentum</th>
          <th className="border-rt">R<sup>2</sup> (%)</th>
          <th className="border-rt">Beta</th>
        </tr>
      </thead>
      <tbody>
        {selFunds.map((item, index) => {
          return (
            <tr key={index}>
              <td className="border-lt-rt">{item.ticker_short || '-'}
                {(item.ticker_short && item.ticker_short === bestTicker) && (
                  <BestTickerBadge style={{ paddingLeft: "10px" }} />
                )}
              </td>
              <td>{handleNoData(fixedValue(mul100(item.zypher_alpha)))}</td>
              <td>{handleNoData(fixedValue(item.zypher_market))}</td>
              <td>{handleNoData(fixedValue(item.zypher_value))}</td>
              <td>{handleNoData(fixedValue(item.zypher_size))}</td>
              <td>{handleNoData(fixedValue(item.zypher_momentum))}</td>
              <td className="border-rt">{handleNoData(fixedValue(item.zypher_rsq))}</td>
              <td className="border-rt">{handleNoData(fixedValue(item.zypher_capm_beta))}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default FactorExposuresTable;

import React, { useState, useRef } from 'react'
import Autosuggest from 'react-autosuggest'
import { addEventToAnalytics } from 'Utils'
import { getSuggestions , getSaveSearchList , saveSearchItemToList, getSearchRecentList, deleteSearchItemOfList } from './GetSuggestions'
import { SearchInputWrapper, Typer } from './Styles'
import {
  FieldTimeOutlined,
  StarFilled,
} from '@ant-design/icons'
import { Button , Dropdown , Menu , message, Row, Col, Tooltip as AntToolTip  } from 'antd';
import { renderToString } from 'react-dom/server';
import {starSaveListBlankWhiteIcon, starSaveListFilledIcon, unsavedStarIcon, starAddItemToList, savedItemToList,icStarAddItemToList} from '../../SvgComponent';
import './dropdownStyles.scss';

function SearchSuggestions({
  loggedIn,
  universe,
  onPressEnter,
  searchText,
  search_type,
  setSearchText,
  setEnableSearchIcon,
  enableSaveIcon,
  starAddIcon,
  fetchSaveSearchList,
  surveyModelOpen,
  surveyFormConfirmation,
  saveListDataResponse,
  isLandingPage = false,
  isLoading = false,
  isClearIconActive = false
}) {
  const [suggestionsList, setSuggestionsList] = useState([])
  const [showAnimatedText, toggleAnimatedText] = useState(true)
  const [recentDataActive, setRecentDataActive]= useState(false)
  const [saveSearchListActive, setSaveSearchListActive]= useState(false)
  const [loginPrompt, setloginPrompt]= useState(false)
  const inputRef = useRef()
  let onFocusClick = false;
  const _unsavedIconId='unsavedStarIcon';
  const unsavedIcon = <img width={14} height={14} id={_unsavedIconId} src={'/assets/images/ic_Add_star.svg'}/>
  /**GETTING SEARCH LIST ON THE BASIS OF TYPED QUERY */
  const fetchSuggestions = async value => {
      if(value){
        if(value && value.length==1){
          setSuggestionsList([])  /*TO AVOID RECENT SEARCH CONTENT AFTER FIRST CONTENT */
        }
        setRecentDataActive(false)
        const response = await getSuggestions({ query: value, universe })
        setSuggestionsList(response)
      }else{
        // Nothing to do---
        setSuggestionsList([])
        if(!onFocusClick){
          if (loggedIn) {
            setRecentDataActive(true)
            fetchRecentSuggestions()
          }
        }
      }
  }

  /**GET RECENT SUGGESTION SEARCH LIST */
  const fetchRecentSuggestions = async () => {
    if (loggedIn) {
      const response = await getSearchRecentList()
      if(response){
        onFocusClick=false;
        setSuggestionsList(response)
      }
    } else {
      return null
    }
  }

  /**ADD QUERY TO SAVE SEARCH LIST */
  const addSearchItemToList = async () => {
    if (loggedIn) {
      if(searchText && searchText.length > 0){
        const response= await saveSearchItemToList(searchText,search_type)
        if(response && response.data){
          fetchSaveSearchList() /**Fetch Save Search List After Adding Item Into Save List */
          message.success(response.data.result, 5);
        }
      }
    } else {
      surveyModelOpen && surveyModelOpen()
      return null
    };
  }

  /**ADD RECENT SEARCH ITEM TO SAVED LIST */
  const addRecentSearchItemToList = async (item) =>{
    if(item && typeof item ==='object'){
      const response= await saveSearchItemToList(item.query_string,item.search_type)
      if(response && response.data){
        fetchSaveSearchList() /**Fetch Save Search List After Adding Item Into Save List */
        message.success(response.data.result, 5);
      }
    }
  }

  /**DELETING SAVED ITEMS */
  const deleteSavedItems = async (id) => {
    if (loggedIn) {
      const response = await deleteSearchItemOfList(id)
      if(response && response.data){
        fetchSaveSearchList() /**Fetch Save Search List After Deleting Item Into Save List */
        message.success(response.data.result, 5);
      }
    } else {
      return null
    };
  }

  const escapeRegExp = string => string.replace(/([.*+?^${}()|[\]\\])/g, '\\$1')

  /** RESPONSIBLE TO DISPLAY EACH ROW OF AUTOSUGGEST DROPDOWN */
  const renderSuggestion = value => {

    const ele_space = renderToString(<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>)
    let modValue = ''
    let _styles = {width:'100%'}
    if(recentDataActive && value && typeof value==='object'){
      _styles = {display:'inline-flex',width:'100%'}
      modValue =value.query_string && value.query_string.replace(
        new RegExp(`\\b${escapeRegExp(searchText)}\\b`, 'gi'),
        str => '<strong>' + str + '</strong>'
      )
      const star_icon= renderToString(<span style={{float:'right',paddingLeft:'10px'}}><img width={14} height={14} src={'/assets/images/savedStar.svg'}/></span>)
      const unsaved_star_icon = renderToString(<span style={{float:'right', paddingLeft:'10px'}}>{unsavedIcon}</span>)
      const time_icon= renderToString(<span style={{float:'left'}}><FieldTimeOutlined/></span>)
      const updatedModValue = `<span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; padding-top: 3px; width: 88%" title='${value.query_string}'>${modValue}</span>`
      modValue = value.is_saved ? `${time_icon}${ele_space}${updatedModValue}${star_icon}` : `${time_icon}${ele_space}${updatedModValue}${unsaved_star_icon}`
    }else if(value && typeof value ==='string'){
      modValue= value && value.replace(
        new RegExp(`\\b${escapeRegExp(searchText)}\\b`, 'gi'),
        str => '<strong>' + str + '</strong>'
      )
    }
    return <span className='suggestion-row' style={_styles} dangerouslySetInnerHTML={{ __html: modValue }} />
  }


  const onSaveListIconClick = () =>{
    if(!loggedIn){
      setSaveSearchListActive(false)
      surveyModelOpen && surveyModelOpen()
    }
  }

/** SAVE LIST DROPDOWN */
  const saveListmenuItem =
  saveListDataResponse && saveListDataResponse.length ? (
    <Menu className='save-list-menu'>
        <div className='save-list-menu-title'>
          <span>Saved Searches</span>
        </div>
      {saveListDataResponse.map((item) => (
        <Menu.Item className="save-list-submenu">
          <Row>
            <Col
              span={22}
              className="submenu-container-text"
              title={item.query}
              onClick={() => {
                setSaveSearchListActive(false)
                setSearchText(item.query)
                onPressEnter(item.query, {}, 'saved_search')
                addEventToAnalytics(
                  'Save Search Submit',
                  'Save Search Submit',
                  'SAVE_SEARCH_SUBMIT',
                  { query : item.query },
                  true
                )
              }
              }
            >
              {item.query}
            </Col>
            <Col span={2} className="submenu-container-icon">
              <img
                src={'/assets/images/TrashIcon.svg'}
                onClick={() => {
                  setSaveSearchListActive(false)
                  deleteSavedItems(item.id)
                  addEventToAnalytics(
                    'Deleting Saved Items',
                    'Deleting Saved Items',
                    'DELETING_SAVED_ITEMS',
                    {query: item.query},
                    true
                  )
                }}
              />
            </Col>
          </Row>
        </Menu.Item>
      ))
      }
    </Menu>
  ):(
    <Menu className={loggedIn ? 'save-list-menu':'save-list-section-hide'} style={{ padding: '4px 0' }}>
        <div className='save-list-no-content'>
          <span>There are no saved searches for you to view.</span>
          <span>Create a search and click {unsavedStarIcon({})} to add to saved searches.</span>
        </div>
    </Menu>
  );

  /**:(
    <Menu className='save-list-notLogged'>
      <div className='save-list-loginPrompt'>
         {surveyFormConfirmation}
      </div>
    </Menu>
  ) */

  // (suggestionsList.length === 1 &&
  // suggestionsList[0].title &&
  // suggestionsList[0].title === 'Investments') ||
  // (suggestionsList.length > 1 &&
  // suggestions[data.sectionIndex] &&
  // suggestionsList[data.sectionIndex].title &&
  // suggestionsList[data.sectionIndex].title === 'Investments')

  return (<>
    <SearchInputWrapper id={`magnifi-auto-suggestion-search-box`} isLandingPage={isLandingPage}>
      <div className={`glyphicon glyphicon-user far fa-fw fa-search ${isLandingPage ? 'fa-lg' : 'fa-sm'}`}></div>
      <Autosuggest
        multiSection={true}
        suggestions={suggestionsList}
        onSuggestionsClearRequested={() => setSuggestionsList([])}
        onSuggestionsFetchRequested={({ value }) =>{
          fetchSuggestions(value)
        }}
        shouldRenderSuggestions={(value, reason) => ( value.length === 0 || value.length > 0)}
        onSuggestionSelected={(e, data) => {
          // NOTE: When query is selected from dropdown (Investments section), pass query as -> query: `'ticker': ${query}` in api payload
          if(e && e.target && e.target.id === _unsavedIconId ){
            addEventToAnalytics(
              'Recent Search Query Saved',
              'Recent Search Query Saved',
              'RECENT_SEARCH_QUERY_SAVED',
              {query:data.suggestionValue.query_string},
              true
            )
            setSearchText('')
            addRecentSearchItemToList(data.suggestionValue);
          }else{
            try {
              if(recentDataActive){
                addEventToAnalytics(
                  'Recent Search Submit',
                  'Recent Search Submit',
                  'RECENT_SEARCH_SUBMIT',
                  {query:data.suggestionValue.query_string},
                  true
                )
              }else{
                addEventToAnalytics(
                  'Search Text Bar Submit',
                  'Search Text Bar Submit',
                  'SEARCH_TEXT_BAR_SUBMIT',
                  {},
                  true
                )
              }
            }catch(e){
              console.log('Error in Analytics event')
            }
            if (e.key !== 'Enter') {
              setEnableSearchIcon()
              if (suggestionsList &&
                suggestionsList.length > 0 &&
                suggestionsList[data.sectionIndex] &&
                suggestionsList[data.sectionIndex].title &&
                suggestionsList[data.sectionIndex].title === 'Investments'
              ) {
                onPressEnter(data.suggestionValue, { dropdownSection: 'Investments' }, 'search_bar')
              } else {
                onPressEnter(data.suggestionValue, {}, recentDataActive ? 'recent_search' : 'search_bar')
              }
            }

            // if (data.sectionIndex === 1) { // '0' for Searches section and '1' for Investments section
            //   if(e.key !== 'Enter'){
            //     setEnableSearchIcon()
            //     onPressEnter(data.suggestionValue, { dropdownSection: 'Investments' }, 'search_bar' )
            //   }
            // } else {
            //   if(e.key !== 'Enter'){
            //     setEnableSearchIcon()
            //     onPressEnter(data.suggestionValue, {}, recentDataActive ? 'recent_search' : 'search_bar')
            //   }
            // }

          }
        }}
        inputProps={{
          placeholder: isLandingPage ? '' : 'Search for something...',
          value: searchText,
          onChange: (e, { newValue }) => {
            if (!isLoading){
              if(typeof newValue==='object'){
                setSearchText(newValue.query_string)
              }else{
                setSearchText(newValue)
              }
            }
          },
          onKeyDown: e => {
            if (e.key === 'Enter' && searchText && searchText.length > 0) {
              setEnableSearchIcon()
              onPressEnter(searchText,{}, recentDataActive ? 'recent_search' :'search_bar')
              inputRef.current.blur()
              addEventToAnalytics(
                'Search Text Bar Submit',
                'Search Text Bar Submit',
                'SEARCH_TEXT_BAR_SUBMIT',
                {},
                true
              )
              setSuggestionsList([])
            }
          },
          disabled: isLoading,
          onFocus: () => {
            addEventToAnalytics(
              'Search Text Bar Click',
              'Search Text Bar Click',
              'SEARCH_TEXT_BAR_CLICK',
              {},
              true
            )
            if(!searchText){
              setRecentDataActive(true)
              onFocusClick=true;
              fetchRecentSuggestions()
            }else{
              toggleAnimatedText(false)
            }
          },
          onBlur: () => toggleAnimatedText(true),
          ref: inputRef,
          required: 'true'
        }}
        renderSuggestion={renderSuggestion}
        renderSectionTitle={section => <span>{section.title}</span>}
        getSectionSuggestions={section => section.suggestions}
        getSuggestionValue={value => value}
      />
      {(!searchText || searchText.length === 0) &&
        isLandingPage &&
        showAnimatedText && (
          <Typer onClick={() => inputRef.current.focus()}>
            What do you want to invest in?
          </Typer>
        )}
        {searchText && searchText.length > 0 && (
          <span className={`close-icon-section`}>
            <i className={`far fa-times fa-lg text-secondary ${isLandingPage ? 'clear-icon active' : 'search-clear-icon'} ${(!isLandingPage && isClearIconActive) ? 'active': 'inactive'}`}
                onClick={() => setSearchText('')}
            />
          </span>
        )}
        {((!loggedIn && suggestionsList.length===0 && searchText)  || ((enableSaveIcon && suggestionsList.length===0) && searchText)) && (
           starAddIcon ?
           <span className={`save-icon-section`}>
             <img height={14} width={14} src={'/assets/images/savedStar.svg'}/>
           </span>:
           <AntToolTip
           title={'Save Searched Query'}
           arrowPointAtCenter={false}
           overlayClassName="magnifi-tooltip-wrapper"
           placement={'topRight'}>
           <span
           className={`add-icon-section`}
           onClick={()=>{
            addSearchItemToList();
            addEventToAnalytics(
              'Search Bar Query Saved',
              'Search Bar Query Saved',
              'SEARCH_BAR_QUERY_SAVED',
              { query : searchText },
              true
            )
           }
            }>
             {icStarAddItemToList({})}
           </span>
           </AntToolTip>
        )}
        {
          <Dropdown
            trigger={'click'}
            placement='bottomRight'
            overlayClassName='save-list-dropdown'
            overlay={saveListmenuItem}
            onVisibleChange={(e) => {
              if(!loggedIn){
                setSaveSearchListActive(false)
              }else{
                setSaveSearchListActive(e)
              }
            }}
            getPopupContainer={() => document.getElementById(`magnifi-auto-suggestion-search-box`)}
          >
            <Button
              type="primary"
              className={` save-list-section ${ saveSearchListActive ? 'save-list-btn' : 'save-list-btn-hide' }  ${starAddIcon ?'':'save-list-ext-padd'}`}
              onClick={() => {
                addEventToAnalytics(
                  'Save Search List',
                  'Save Search List',
                  'SAVE_SEARCH_LIST',
                  {},
                  true
                )
                onSaveListIconClick()
              }
              }
              size={'small'}
            >
            <AntToolTip
            title={'View Saved Searches'}
            arrowPointAtCenter={false}
            overlayClassName="magnifi-tooltip-wrapper"
            placement={'topRight'}>
              <div className={`button-wrapper`}>
                { saveSearchListActive ?
                  starSaveListFilledIcon({}):
                  starSaveListBlankWhiteIcon({})
                }
              </div>
            </AntToolTip>
            </Button>
          </Dropdown>
        }
    </SearchInputWrapper>
     {/* {
      loginPrompt && <div style={{zIndex:'11', paddingLeft: '100px'}}>
         {surveyFormConfirmation}
         </div>
     } */}
    </>
  )
}

export default SearchSuggestions

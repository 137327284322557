import React from 'react'
import { Col } from 'reactstrap'
import { Helmet } from 'react-helmet';
import { PLAN_NAME_MAPPING } from 'DataSet';

const SubscriptionScheduleDemoModal = (props) => {
  const featureName = PLAN_NAME_MAPPING[props.featureName];
  const iconStyles = {
    position: 'absolute',
    right: '15px',
    top: '10px',
    fontSize: '20px',
    cursor: 'pointer',
  };

  return (
    <React.Fragment>
      <div className="header-wrapper" style={iconStyles}>
        <i className="far fa-times" onClick={() => props.onChangeModal(false)} />
      </div>
      <Col md="24" lg="24" className="right-side-panel-container register-page-height">
        <div style={{ margin: 'auto' }}>
          <div
            class="meetings-iframe-container"
            data-src={`https://meetings.hubspot.com/rob-stella/magnifi-for-advisors-30-min-demo?embed=true&utm_content=${featureName}`}
            style={{ minHeight: 640 }}
          ></div>
          <Helmet>
            <script
              type="text/javascript"
              src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
            ></script>
          </Helmet>
        </div>
      </Col>
    </React.Fragment>
  )
}

export default SubscriptionScheduleDemoModal

import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { Row, Col, Button, Menu, Dropdown, Tooltip } from 'antd'
import { Modal, ModalBody } from 'reactstrap';
import { DownOutlined } from '@ant-design/icons';
import { addEventToAnalytics } from 'Utils';
import VideoPlayer from 'components/VideoPlayer';
// import { PremiumModal } from 'components/Premium';

import { setEnhancerState, resetEnhancerState, loadCPMListRequest, deletePortfolioByIdRequest } from 'actions/enhancer';
import { premiumFeatureAccessRequest } from 'actions/profile';

import { createLoadingSelector } from 'selectors/loading-selector';
import { sponsoredMenus, portfolioMenus, CustomSponsoredDropDown, ClientDDSearch, PortfolioDDSearch } from '../OnBoardScreen/Helper';
import { Loader } from '../../Elements/Loader';

import PortfolioUpload from '../../../ClientPortfolio/Elements/PortfolioUpload/index';
import PfListPreview from '../../../ClientPortfolio/Elements/PfListPreview/index';
import style from '../OnBoardScreen/OnBoardScreen.module.scss'
import Content from './Content';

class OpenSummaryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portfolioType: '',
      IMG_OBJECT: {
        'Selected Funds': '/assets/images/UsingSelectedFund-White.svg',
        'Sponsor Models': '/assets/images/SponsorProvidedModel-White.svg',
        'My Models': '/assets/images/MyPortfolios-White.svg',
        'Client Accounts': '/assets/images/client-icon.svg',
        'Scratch': '/assets/images/FromScratch-White.svg',
        'Demo Portfolio': '/assets/images/DemoPortfolio-White.svg',
      },
      videoData: {
        title: 'Magnifi',
        link: 'https://magnifi-videos.s3.us-east-2.amazonaws.com/Enhancer_01.mp4',
      },
      showClientUploadModal: false,
      uploadDemo: false,
      clientPortfolioLoader: false,
      deleteConfirm: false,
      deletePortfolioName: '',
      clientDDVisible:false,
      myPfDDVisible:false,
    };
  }

  componentDidMount(){
    const mainContent = document.getElementById('enhancer-content-wrapper');
    if (mainContent) {
      document.body.classList.add('body-bg-white');
      mainContent.style.background = '#fff';
      mainContent.style.display = 'flex';
    }
    const { loadCPMListRequest } = this.props;
    if (loadCPMListRequest) {
      loadCPMListRequest();
    }
  }

  componentWillUnmount() {
    const mainContent = document.getElementById('enhancer-content-wrapper');
    if (mainContent) {
      document.body.classList.remove('body-bg-white');
      mainContent.style.background = 'transparent';
      mainContent.style.display = 'unset';
      mainContent.style.alignItems = 'unset';
    }
  }

  selectedPortfolio = portfolioData => {
    addEventToAnalytics('Enhancer UP','Enhancer UP','ENHANCER_UP',{},false);
    const { location, history, setEnhancerState, enhancer } = this.props;
    const obj = {
      portfolio_name: portfolioData.portfolio_name,
      portfolio_id: portfolioData.id,
      status: portfolioData.status,
    };
    this.routeTo('My Portfolio', 'dashboard', { data: portfolioData.id }, obj);
  }

  setMarketPlace = sponserData => {
    addEventToAnalytics('Enhancer MP','Enhancer MP','ENHANCER_MP',{},false);
    const { setEnhancerState } = this.props;
    if (sponserData && sponserData.ticker) {
      let obj = {
        portfolio_name: sponserData.ticker,
          portfolio_id: sponserData.ticker,

      };
      this.routeTo('MarketPlace', 'dashboard', { data: sponserData.ticker }, obj);
    }
  }

  uploadPortfolio = (data) => {
    const { enhancer } = this.props;
    if (enhancer.portfolioName && enhancer.portfolioName !== '') {
      let obj = { portfolio_name: enhancer.portfolioName,portfolio_id:enhancer.portfolioId };
      this.routeTo('Upload Portfolio', 'dashboard', { data: '' }, obj);
    }
  }

  watchlistPortfolio = () => {
    addEventToAnalytics('Enhancer Watchlist','Enhancer Watchlist','ENHANCER_WATCHLIST',{},false);
    let obj = { portfolio_name: 'Watchlist' };
    this.routeTo('Your Watchlist', 'dashboard', { data: '' }, obj);
  }

  demoPortfolio = (data) => {
    const { enhancer } = this.props;
    if (enhancer.portfolioName && enhancer.portfolioName !== '') {
      let obj = {
        portfolio_name: enhancer.portfolioName,
        portfolio_id:enhancer.portfolioId
      };
      this.routeTo('Demo Models', 'dashboard', { data: '' }, obj);
    }
  }

  clientSelectedPortfolio = portfolioData => {
    addEventToAnalytics('Enhancer Client Portfolio','Enhancer Client Portfolio','ENHANCER_CLIENT_PORTFOLIO',{},false);
    const { location, history, setEnhancerState, enhancer } = this.props;
    const obj = { portfolio_name: portfolioData.original_portfolio, portfolio_id: portfolioData.original_portfolio_id };
    this.routeTo('Client Portfolio', 'dashboard', { data: portfolioData.original_portfolio_id }, obj);
  }

  routeTo = (selectedPortfolioType, path, payloadRequest, portfolioData) => {
    const { history, setEnhancerState } = this.props;
    if (setEnhancerState && selectedPortfolioType && path) {
      let portfolioId =portfolioData.portfolio_id
      setEnhancerState({
        portfolioName: portfolioData.portfolio_name,
        portfolioId,
        onboardData: {
          selectedPortfolioType,
          selectedPortfolioPath: `${path}`,
        },
      });
      if (history) {
        const jObj = {
          portfolioType: selectedPortfolioType,
          portfolioId: portfolioData.portfolio_name,
        }
        let urlParam = [];
        for (var i in jObj){
          urlParam.push(encodeURI(i) + "=" + encodeURI(jObj[i]));
        }
        const newPath = `/enhancer/summary?${urlParam.join("&")}`;
        history.push(newPath);
      }
    }
  }

  // clientUploadModalToggle = (val, uploadDemo) => {
  //   this.setState({
  //     showClientUploadModal: val,
  //     uploadDemo: uploadDemo && uploadDemo
  //   })
  // }

  goToSelectedPortfolio = (selectedClient) => {
    this.setState({
      clientPortfolioLoader: false
    }, () => this.clientSelectedPortfolio(selectedClient));
  }

  showLoader = () => {
    this.setState({
      clientPortfolioLoader: true
    })
  }

  videoSetState = (_state) => {
    addEventToAnalytics('Enhancer Video','Enhancer Video','ENHANCER_VIDEO',{},false);
  }

  openChange = () => {
    const element = document.querySelector('.market-popup-wrapper');
    const dropdownEl = document.querySelectorAll('.ant-dropdown-menu-submenu-title');
    if (element != null) {
      element.style.height = element.clientHeight > 0 && `${element.clientHeight}px`
    }
    if(dropdownEl){
      dropdownEl.forEach((el, i) => {
        dropdownEl[i].title = '';
      })
    }
  }

  deletePortfolio = (portfolioName) => {
    this.setState({ myPfDDVisible: false });
    this.setState({ deletePortfolioName : portfolioName, deleteConfirm: true })
  }

  confirmDeletePortfolio = () => {
    addEventToAnalytics('Enhancer Delete','Enhancer Delete','ENHANCER_DELETE',{},false);
    const { deletePortfolioByIdRequest } = this.props;
    if (deletePortfolioByIdRequest && this.state.deletePortfolioName && this.state.deletePortfolioName !== '') {
      deletePortfolioByIdRequest(this.state.deletePortfolioName, () => this.setState({ deleteConfirm: false }));
    } else {
      console.log('-- PORTFOLIO ID MISSING --');
    }
  }

  myPfDDHandle = (flag)=>{
    this.setState({ myPfDDVisible: flag });
  }

  clientDDHandle = (flag)=>{
    this.setState({ clientDDVisible: flag });
  }

  handleClientDDMenuClick = (item)=>{
    this.setState({ clientDDVisible: false });
    if(item.name==='Demo Account'){
      this.clientUploadModalToggle(true, true)
    }else{
      this.clientSelectedPortfolio(item)
    }
  }

  render() {
    const {
      loading,
      portfolioList,
      marketPlaceList,
      setMarketPlace,
      clientList,
      client_exist,
      uploadedClientList,
      showUpdateColumnInPreview,
      demoPortfolioLoader,
      premiumUser,
    } = this.props;

    const { IMG_OBJECT, videoData, showClientUploadModal, uploadDemo, clientPortfolioLoader, deleteConfirm, deletePortfolioName } = this.state;

    // const SPONSORED_MENUS = sponsoredMenus({
    //   props: {...this.props},
    //   openChange: this.openChange,
    //   setMarketPlace: this.setMarketPlace,
    // });

    // const MY_PORTFOLIO_MENUS = portfolioMenus({
    //   props: {...this.props},
    //   selectedPortfolio: this.selectedPortfolio,
    //   demoPortfolio: this.demoPortfolio,
    //   uploadPortfolio: this.uploadPortfolio,
    //   watchlistPortfolio: this.watchlistPortfolio,
    //   deletePortfolio: this.deletePortfolio,
    // });

    // const clientListMenuItems = clientList && clientList.length && clientList.map((item, index) => {
    //   return (
    //     <Menu.Item key={item.name+item.id}>
    //       <div className="portfolio_name-label" onClick={() => { this.clientSelectedPortfolio(item) }}>
    //         {item.name}
    //       </div>
    //     </Menu.Item>
    //   )
    // });
    //
    // const CLIENT_LIST_MENUS = (
    //   <Menu>
    //     <Menu.Item key="use_demo">
    //       <div className="active-label upload_btn" onClick={() => this.clientUploadModalToggle(true, true)}>
    //         Use Demo Account
    //       </div>
    //     </Menu.Item>
    //     <Menu.Item key="upload_portfolio">
    //       <div className="active-label upload_btn" onClick={() => this.clientUploadModalToggle(true)}>
    //         Add Account
    //       </div>
    //     </Menu.Item>
    //     { clientList && clientList.length > 0 && <Menu.Divider /> }
    //     {clientListMenuItems}
    //   </Menu>
    // );

    const styles = {
      width: '100%',
      maxWidth: '1440px',
      padding: '15px 0',
      flexDirection: 'row',
    };

    const { location, history, match } = this.props;
    let portfolioId = '', portfolioType = '';

    try {
      if (location && location.search) {
        const params = new URLSearchParams(location.search)
        if (params) {
          portfolioId = params.get('portfolioId');
          portfolioType = params.get('portfolioType');
        } else {
          throw 'data not found in url'
        }
      } else {
        throw 'data not found in url'
      }
    } catch (e) {
      console.log(e);
    }

    return (
      <div className={`enhancer-onboard-screen-wrapper row onBoardScreenWrapper ${loading ? " enhancer-onboard-screen-wrapper-loader-screen-overlay " : ""}`} style={styles}>
        {(loading || demoPortfolioLoader || clientPortfolioLoader) && <Loader loading />}
        {!loading && (
          <>
            <div className={`content-body buttons-block`} style={{ marginBottom: 15 }}>
              <div className={`buttons-items-wrapper `} style={{ justifyContent: 'space-between', alignItems: 'center', padding: '0px 8px' }}>
                <Dropdown
                  trigger={'click'}
                  onVisibleChange={this.myPfDDHandle}
                  visible={this.state.myPfDDVisible}
                  className={style.ddButtons}
                  overlayClassName={style.portfolioDDContainer}
                  overlay={<PortfolioDDSearch
                    uploadPortfolio={ this.uploadPortfolio}
                    watchlistPortfolio= {this.watchlistPortfolio}
                    deletePortfolio={this.deletePortfolio}
                    handleDDMenuClick={this.myPfDDHandle}
                    demoPortfolio={this.demoPortfolio}
                    portfolioList={this.props.portfolioList}
                    selectedPortfolio={this.selectedPortfolio}
                  />}
                >
                  <div id={'pfSearch'} className="d-flex align-items-center justify-content-between OpenSummaryPage-view-portfolio-dropdown" style={{ border: 0, width: 'auto' }}>
                    <div className='row d-flex align-items-center justify-content-between' style={{ marginLeft: '10px', width: '100%', marginRight: '0px' }}>
                      <div style={{ paddingRight: 10 }}>View More Portfolio</div>
                      <DownOutlined/>
                    </div>
                  </div>
                </Dropdown>
                <div className="OpenSummaryPage-id-text">{portfolioId}</div>
                <div className="delete-icon OpenSummaryPage-delete-icon" style={{ textAlign: 'right' }} onClick={() => console.log('delete this portfolio')}>
                  <i class="far fa-fw fa-trash-alt"></i>
                </div>
              </div>
            </div>
            <Content {...this.props} />
          </>
        )}
        <Modal
          isOpen={deleteConfirm}
          toggle={() => this.setState({ deleteConfirm: !deleteConfirm })}
          centered
          keyboard={true}
          backdrop
          className="delete-confirm-modal premium-msg-modal account-modal"
          backdropClassName="premium-feature-process-modal-backdrop top-60px"
          // modalClassName="top-60px"
          >
          <ModalBody className="ssf-modal__body">
            <div className="premium-feature-process-modal-container">
              <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => this.setState({ deleteConfirm: false })}></i>
              <p className="content-wrapper">
                <div className="col-12 question">Are you sure you want to delete {deletePortfolioName} portfolio?</div>
                <div className="col-12 cta-wrapper margin-left-51">
                  <button type="button" className="ssf-btn-primary btn secondary-btn btn btn-secondary" onClick={() => this.confirmDeletePortfolio()}><span>YES</span></button>
                  <button type="button" className="ssf-btn-primary btn secondary-btn btn btn-secondary" onClick={() => this.setState({ deleteConfirm: false })}><span>NO</span></button>
                </div>
              </p>
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({ enhancer, investment, auth, loading, sfm, clientportfolio, profile }) => ({
  enhancer,
  sfmItems: sfm.items,
  marketPlaceList: enhancer.marketPlaceList,
  portfolioList: enhancer.portfolioList,
  clientList: enhancer.clientList,
  uploadedClientList: clientportfolio.uploadedClientList,
  showUpdateColumnInPreview: clientportfolio.showUpdateColumnInPreview,
  client_exist: auth.client_exist,
  premiumUser: auth.user.premiumUser,
  premiumFeatures: profile.premiumFeatures,
  activeFeatureName: profile.activeFeatureName,
  premiumNoticeModalOpen: profile.premiumNoticeModalOpen,
  access: profile.access, // 'Access' or 'Denied' or 'Test Drive'
  loading: createLoadingSelector(['GET_SUBSCRIPTION_PLAN', 'LOAD_CPM_LIST'])({ loading }),
  demoPortfolioLoader: createLoadingSelector(['UPLOAD_CSV', 'UPLOAD_CSV_FILE', 'VERIFY_CSV_TICKER_AND_PRICE', 'CHECK_SINGLE_TICKER_EXIST', 'DELETE_PORTFOLIO_BY_ID'])({ loading }),
})

const mapDispatchToProps = {
  setEnhancerState,
  resetEnhancerState,
  loadCPMListRequest,
  deletePortfolioByIdRequest,
  premiumFeatureAccessRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OpenSummaryPage));

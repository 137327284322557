import React, { useState, useEffect } from 'react';
import { Row, Col, Table } from 'reactstrap';
import { getImageOrFallback } from 'Utils'

import { DonutChartS1 } from 'components/charts/Recharts';
import { BarChartPrintSn2 } from 'components/charts/BarCharts'
import { sortFn, nameToDateRange } from 'Utils'
import { NameSponsorTicker } from './Common'
import { withHeaderFooter } from '../shared/HeaderFooter'
import { TitleWrap3, TitleWrap1 } from '../shared/Wrappers'
import { D3Colors09, DateRange } from 'DataSet';
import { RegionColors, SectorColors } from 'data/Colors';
import { LineChartS1 } from '../shared/Charts';
import { calcCumulativeReturns } from 'Stats';
import { sponsorLogo } from 'DataSet';
import ActReportContext from '../ActReportContext';
import { FormatCurrency, getColor } from '../shared/Utils'
import moment from 'moment';
import NotApplicable from 'components/NotApplicable';

// import MultiSeriesLineChart from 'layouts/WebDashboard/PortfolioReportV2/shared/MultiSeriesLineChart'

const FundsPage = (props) => (
  <ActReportContext.Consumer>
    {data =>
      <React.Fragment>
        {data.selectedFunds.map(e => <div className="reports"><FundDetails key={e.ticker} item={e} {...props} pageName={'appendix,fund-fact-sheet'} /></div>)}
      </React.Fragment>
    }
  </ActReportContext.Consumer>
)

export default FundsPage;

const FundDetails = withHeaderFooter(({ item, ...props }) => {
  let holdingData = item._charts.holdings
    .sort(sortFn('v'))
    .map((e, i) => ({ name: e.name, value: e.weight, color: e.name !== 'Others' ? getColor(SectorColors, i) : '#ddd' }));

  // let sum = holdingData.reduce((s, e) => s + e.value, 0);
  // if (holdingData.length > 0 && sum < 100)
  //   holdingData.push({ name: 'Others', value: 100 - sum, color: '#ddd' });

  const sectorData = item._charts.sectors
    .filter(e => e.v > 0.05)
    .sort(sortFn('v'))
    .map((e, i) => ({ name: e.n, value: e.v, color: getColor(SectorColors, i) }));

  const regionData = item._charts.regions
    .reduce((acc, e) => acc.concat(e.sub), [])
    .filter(e => e.v > 0.05)
    .sort(sortFn('v'))
    .map(e => ({ name: (e.n === 'Non Classified Non Country' ? 'Others' : e.n), value: e.v, color: RegionColors[e.n] || '#555' }));

  const perfSeries = [
    { key: 'Fund', color: '#8884d8' },
    { key: 'Category', color: '#82ca9d' },
  ];

  return (
    <React.Fragment>
      <div className="content">
        <Title item={item} />
        <Row className="wide">
          <Col className="col-md-4">
            <TitleWrap3 title={'Description'}><div className="p-3 text-desc">{item.short_desc}</div></TitleWrap3>
            <TitleWrap3 title={'Key Facts'}><FactsData item={item} date={props.date} /></TitleWrap3>
          </Col>
          <Col className="col-md-8 wrap-margin">
            <GrowthChart item={item} />
            <p className="asteriskDisclaimer">The line graph represents the cumulative performance of a hypothetical $100 investment. The returns are net of the fund's expense ratio but do not reflect the payment of any brokerage commissions or brokerage costs
              incurred as a result of buying or selling fund shares and do not reflect the deduction of taxes to which an investor would be subject as a result of owning or selling shares of the fund. If they did, the returns would
              be lower than those shown.</p>
            <TitleWrap1 title={'Performance'} klasses="">
              <div className="chart-wrap-2x px-3 py-2">
                {
                  item._charts.performance.length > 0 ?
                    <BarChartPrintSn2 margin={{ top: 14, right: 0, left: 0, bottom: 5 }} series={perfSeries} data={item._charts.performance} /> :
                    <NotApplicable />
                }
              </div>
            </TitleWrap1>
            <div className="p-3"></div>
            <TitleWrap1 title={'Top Holdings'} klasses="type2"><ChartWrap data={holdingData} /></TitleWrap1>
            <TitleWrap1 title={'Sectors'} klasses="type2"><ChartWrap data={sectorData} /></TitleWrap1>
            <TitleWrap1 title={'Regions'} klasses="type2"><ChartWrap data={regionData} /></TitleWrap1>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
});

const Title = ({ item }) => {
  const [url, setUrl] = useState('')
  useEffect(() => {
    getImageOrFallback(sponsorLogo(item.sponsor))
      .then(result => setUrl(result));
  }, [item])
  return (
    <div className="header">
      <div className="d-flex align-items-end">
        <div>
          <h5>Fund Details</h5>
          <h3>{item.name}</h3>
        </div>
        <div className="ml-auto">
          {url
            ? <img src={url} className="img-fluid" alt={item.sponsor} style={{ height: '24px' }} />
            : item.sponsor
          }
        </div>
      </div>
    </div>
  )
}

const FactsData = ({ item, date }) => {
  const isDef = v => v !== undefined
  const data = [
    { name: 'Expense Ratio', show: isDef(item.fees), value: item.fees ? item.fees.toFixed(2) + '%' : '-' },
    { name: 'Assets under Management', show: isDef(item.aum), value: FormatCurrency(item.aum) },
    { name: 'Shares Outstanding', show: isDef(item.sharesout), value: FormatCurrency(item.sharesout, 2, '') },
    { name: 'Benchmark Index', show: isDef(item.underlying_index), value: (item.underlying_index === 0) ? '-' : item.underlying_index },
    { name: 'Beta vs. S&P 500', show: isDef(item.betaVs), value: item.betaVs },
    { name: 'Standard Deviation', show: isDef(item.stdDev), value: item.stdDev + '%' },
    { name: 'Price to Earnings', show: isDef(item.priceToEarnings), value: item.priceToEarnings },
    { name: 'Price to Book Ratio', show: isDef(item.priceToBookRatio), value: item.priceToBookRatio },
  ].filter(e => e.show);

  return (
    <div className="p-2">
      <NameSponsorTicker name={item.name} sponsor={item.sponsor} ticker={item.ticker_short} />
      <div className="mt-2 mb-3 h6">Fact Sheet as of {date}</div>
      <hr />
      <Table size="sm" className="mb-0">
        <tbody>
          {data.map((e, i) => (
            <tr key={i}>
              <td className="key text-nowrap">{e.name}</td>
              <td className="value">{e.value}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

const GrowthChart = ({ item }) => {
  // console.log(item, 'sfsd')
  // const DateRangeMoment = item._start && item._end ? {
  //   start: moment(item._start),
  //   end: moment(item._end)
  // } : {
  //   start: moment(DateRange.start),
  //   end: moment(DateRange.end)
  // }
  // console.log(item.ticker, 'date range -->', item._start, item._end)
  // console.log(item)
  const DateRangeMoment = {
    start: moment(item._returnsChart.start),
    end: moment(item._returnsChart.end)
  };

  // console.log(nameToDateRange('l10y', DateRangeMoment))

  let { series: s1 } = calcCumulativeReturns(item._charts.returns, DateRangeMoment);
  // console.log(s1);

  let [first, last] = [s1[1], s1[s1.length - 1]];
  if (s1 && s1.length > 1 && s1[1].d && s1[0].d === 'Start') {
    s1[0].d = s1[1].d;
  }
  const series = [{ name: item.ticker, data: s1, color: D3Colors09[1], label: `$${Math.round(last.cuml + 100)}` }]

  // title={`Growth of Hypothetical $100 since ${item._start}`}

  return (
    <TitleWrap1 title={`Growth of Hypothetical $100 since inception`}>
      {/* ${first ? first.d : item._charts.returns[item._charts.returns.length - 1].d} */}
      <div className="chart-wrap-2x px-3 py-2">
        <LineChartS1
          series={series}
          xkey="d"
          ykey="cuml"
        />
      </div>
    </TitleWrap1>
  )
}

const ChartWrap = ({ data }) => {
  if (data.length == 0) return <NotApplicable />
  return (
    <Row className="py-2 px-3">
      <Col className="col-auto d-flex justify-content-center">
        <div style={{ width: "135px", height: "135px" }}>
          <DonutChartS1 data={data} />
        </div>
      </Col>
      <Col className="col h6 mb-0 text-gray-7 font-weight-normal">
        <Row>
          <Col className="col-6">
            {data.slice(0, 5).map((e, i) =>
              <ChartLegend key={i} name={e.name} value={e.value} color={e.color} />)}
          </Col>
          <Col className="col-6">
            {data.slice(5, 10).map((e, i) =>
              <ChartLegend key={5 + i} name={e.name} value={e.value} color={e.color} />)}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

const ChartLegend = ({ name, value, color }) => {
  return (
    <Row className="no-gutters" style={{ padding: '6px 0' }}>
      <Col className="col-auto" style={{ height: '13px', borderLeft: `6px solid ${color}` }}></Col>
      <Col className="pl-2">{name}</Col>
      <Col className="pl-1 col-auto">{(typeof value !== 'undefined' && value !== null) ? value.toFixed(1) : '--'}%</Col>
    </Row>
  )
}

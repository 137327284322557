import React from 'react';
import { Row, Col } from 'reactstrap';
import NotApplicable from 'components/NotApplicable';
import GaugeChart from 'components/charts/GaugeChart';
import { isNull, isUndefined } from 'util';

export const DividendYieldChart = ({ card, name, width }) => {
  const mult = name === 'Dividend Yield' ? 100 : 1;
  if (card.dividend_yield === '' || isNull(card.dividend_yield) || isUndefined(card.dividend_yield) || card.dividend_yield == "null" || typeof card.dividend_yield == 'undefined') return <NotApplicable />;
  if (card.dividendMin === '' || isNull(card.dividendMin) || isUndefined(card.dividendMin)) return <NotApplicable />;
  if (card.dividendMax === '' || isNull(card.dividendMax) || isUndefined(card.dividendMax) || card.dividendMax <= 0.00000) return <NotApplicable />;
  if (card.dividendMax == card.dividendMin) return <NotApplicable />;

  let _min = card.dividendMin,
    _max = card.dividendMax,
    _val = card.dividend_yield;
  // _val = parseFloat(_val.toFixed(1));
  return (
    <div className="w-100 p-1 guage-chart-wrapper">
      <div className="d-flex flex-column align-items-center" >
        {/* style={{ minWidth: width || 134 }} */}
        {/* <h6 className="text-center mb-0">Percentage Overlap</h6> */}
        {(_min === _max && _max === _val) ? (
          <GaugeChart minWidth={width} pc={0.5} />
        ) : (
          <GaugeChart minWidth={width} value={_val} min={_min} max={_max} />
        )}
        <Row className="no-gutters h6 mb-0 w-100 guage-chart-details">
          <Col className="col-4 pr-2 text-gray-6i text-right h7 fz-9" style={{ marginTop: '-2px' }}>{_min.toFixed(2)}%</Col>
          <Col className="col-4 text-center font-weight-bold mb-0 lh-100 fz-9" style={{ marginTop: '2px' }}>{_val == 0 ? _val : _val.toFixed(2)}%</Col>
          <Col className="col-4 pl-2 text-gray-6i text-left h7 fz-9" style={{ marginTop: '-2px' }}>{_max.toFixed(2)}%</Col>
        </Row>
      </div>
    </div>
  );
}

export default DividendYieldChart;

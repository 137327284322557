import React, { useState } from 'react'
import { Input, UncontrolledTooltip } from 'reactstrap'
import { padStart } from 'lodash'
import Slider from 'rc-slider'

import 'rc-slider/assets/index.css'

const WeightItem = ({
  attr,
  uniqID,
  inputValue,
  setValues,
  onSliderValueChange,
  allValues,
  onLockButtonClick,
  afterSliderChange,
  lockedVariables = []
}) => {
  const isEditEnabled = !lockedVariables.includes(attr.col)

  const onEditbuttonClick = () => {
    const inputEl = document.querySelector('#attr-' + uniqID)
    if (inputEl) {
      window.setTimeout(() => {
        inputEl.focus()
      }, 100)
    }
    onLockButtonClick(attr.col)
  }

  const onValueChange = e => {
    const val = parseInt(e.target.value, 10) || 0
    setValues({
      ...allValues,
      [attr.col]: val
    })
    onSliderEnd(val)
  }

  //const lockUnlock = !isEditEnabled ? 'fa fa-lock' : 'far fa-unlock'
  const lockUnlock = !isEditEnabled ? <img src="/assets/images/unlock.svg" style={{height:'25px'}}></img> : <img src="/assets/images/lock.svg" style={{transform:'inherit',height:'25px'}}></img>
  const onSliderEnd = value => {
    afterSliderChange({
      [attr.col]: value
    })
  }

  // const lockUnlock = !isEditEnabled ? 'fa fa-lock' : 'far fa-unlock'
  return (
    <div key={attr.col} className="weight-cell-item">
      <div className="name">
        <div className="variable-name variable-row">
          <div className="column-1">
            <div className="variable-wrapper">
              <span>{attr.name}</span>
              <i
                id={'circle-'+uniqID}
                class="fal fa-info-circle tooltip-icon"
              ></i>
            </div>
            <UncontrolledTooltip
              placement="right"
              target={'circle-'+uniqID}
            >
              {attr.tooltip}
            </UncontrolledTooltip>
          </div>
          <div className="column-2">
            <div className="editor-pen"  onClick={() => onEditbuttonClick()}>
              {lockUnlock}
            </div>
            {/* <i
              class={`${lockUnlock}-alt pl-1 editor-pen`}
              onClick={() => onEditbuttonClick()}
            ></i> */}
          </div>
        </div>
        <div className="variable-row">
          <div className="column-1">
            <Slider
              value={inputValue}
              min={0}
              max={100}
              step={1}
              tabIndex={-1}
              onChange={value =>
                onSliderValueChange({
                  [attr.col]: value
                })
              }
              onAfterChange={onSliderEnd}
              railStyle={{
                backgroundColor: '#dbdbdb',
                height: 5
              }}
              trackStyle={{
                backgroundColor: '#1e5f91',
                height: 5
              }}
              handleStyle={{
                boxShadow: '0 0 5px 0 #00000029',
                borderColor: '#1e5f91',
                backgroundColor: '#1e5f91',
                height: 12,
                width: 12,
                marginTop: -4
              }}
              disabled={!isEditEnabled}
              allowCross={false}
              pushable={false}
            />
          </div>
          <div className="column-2">
            <Input
              type="text"
              name={attr.col}
              id={'attr-'+uniqID}
              value={inputValue}
              onChange={onValueChange}
              maxLength={3}
              pattern="\d*"
              min={0}
              max={100}
              className="value"
              disabled={!isEditEnabled}
              autoFocus
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WeightItem

import React from 'react';
import moment from 'moment';

import { categoryCol, cn } from '../../DataSet';
import { getQuintile, getNest, arrayToHash, monthToDate, dateToMonth, getNestDefault } from '../../Utils';
import { calcStats } from '../../Stats';

const dateRangeMonthList = range => {
  let out = [];  // List of months
  let rangeEnd = moment(range.end);
  for (let m = moment(range.start); m.isSameOrBefore(rangeEnd); m.add(1, 'months')) {
    out.push(dateToMonth(m));
  }
  return out;
}

const getFilteredListByRange = ({ items, catData, range }) => {
  const rangeFilter = e => {
    let dt = monthToDate(e.d);
    return dt.isAfter(moment(range.start).subtract(1, 'seconds')) && dt.isBefore(moment(range.end).add(1, 'seconds'));
  }
  // const valTraslate = v => Math.log10((v+100)/100);
  const valTraslate = v => v < 0 ? -1*Math.log10((100-v)/100) : Math.log10((100+v)/100);

  let x = dateRangeMonthList(range);

  let catSum = {};
  catData.forEach(e => {
    let out = {}, ysum = 0;
    e.returns.filter(rangeFilter).forEach(d => {
      ysum = (1 + ysum)*(1 + d.v) - 1;
      out[d.d] = ysum*100;
    })
    catSum[e.name] = out
  })


  let rMin = 1e6, rMax = -1e6;
  let catDataH = arrayToHash(catData, 'name');

  // const itemsVisible = items.filter(e => !e._hidden);
  items.forEach(e => {
    if (e.returns.length > 0) {
      if (typeof e._stats === 'undefined') {
        let points = e.returns.filter(rangeFilter);
        if (points.length <= 0) {
          e._hidden = true;
          return;
        }

        let cat = catDataH[categoryCol(cn(e, 'Category'))];
        let cindex = cat.returns.findIndex(e => e.d === points[0].d);
        e._cstats = calcStats(cat.returns.slice(cindex));
        ['fees', 'liquidity', 'assets', 'liquidity_min', 'assets_min', 'liquidity_max', 'assets_max', 'esgScore', 'esgScore_min', 'esgScore_max'].forEach(a => e._cstats[a] = cat[a] || 0.0 );

        const stats = calcStats(points);
        if (typeof stats.series !== 'undefined') {
          let series = x.map(d => {
            let val = getNest([d, 'cuml'], stats.series),
                ival = val !== undefined ? valTraslate(val) : val,
                cval = ival !== undefined ? valTraslate(getNest([d, 'cuml'], e._cstats.series)) : 0.0,
                // opval = ival !== undefined ? valTraslate(getNest(['_opstats', 'series', d, 'cuml'], e)) : 0.0,
                ival2 = ival !== undefined ? getNest([d, 'ret'], stats.series) : 'Inactive';

            // const dyn = val !== undefined ? getNest(['_opstats', 'series', d, 'cuml'], e) : 0.0;

            return { x: d, y1: ival, y2: cval, y3: ival2, y4: val };
            // return { x: d, y1: ival, y2: cval, y3: ival2, y4: opval, label: ival2, val, dyn };
          });

          var stats2 = {
            cumlQ: getQuintile(stats.cuml, e._cstats.cuml),
            yRetQ: getQuintile(stats.yRet, e._cstats.yRet),
            voltQ: getQuintile(stats.volt, e._cstats.volt),
            dMaxQ: getQuintile(stats.dMax, e._cstats.dMax),
            start: x[0],
            end:   x[x.length-1],
          }
          e._stats = {...stats, ...stats2, series};
        }

      }
    }
  });

  return items;
};

export default getFilteredListByRange;

import React from 'react'
import { Col as AntCol, Row as AntRow } from 'antd';
import { Col, Row } from 'reactstrap';
import { kmb, sponsorLogo } from 'DataSet'
import { withHeaderFooter } from './HeaderFooter'
import ItemCard from '../ItemCard'
import { checkValidNumber } from 'layouts/utils';
import style from '../ItemCard.module.scss'
import { SwapOutlined } from '@ant-design/icons'

const SingleReplacement = withHeaderFooter((props) => {
  let { data } = props
  // console.log({ data });
  // let otherFunds = data.result && data.result.funds && data.result.funds.filter(fund => fund && fund.ticker !== data.selected.ticker)
  // let numberOfOtherToShow = data.target_card.short_desc.length > 200 ? 3 : 6
  let currentTargetCard = data && data.target_card;
  let suggestionsList = data && data.suggestions || [];
  if (!currentTargetCard) return null;

  let otherFunds = suggestionsList.map(row => {
    if (row && row.ticker !== data.selected.ticker) {
      return row
    }
  }).filter(k => k) || []
  let numberOfOtherToShow = (
    currentTargetCard &&
    currentTargetCard.cardInfo &&
    currentTargetCard.cardInfo.short_desc &&
    currentTargetCard.cardInfo.short_desc.length > 200
  ) ? 3 : 6;
  // console.log('numberOfOtherToShow -> ', numberOfOtherToShow);
  let CurrentFeesFromFRR = currentTargetCard.FRR ? currentTargetCard.FRR.find(k => k.name === 'Fees') || null : null;
  let expenseRatio =
    typeof CurrentFeesFromFRR.value !== 'undefined'
    ? checkValidNumber(
      parseFloat(CurrentFeesFromFRR.value || 0) * (CurrentFeesFromFRR.multiplier || 1),
      CurrentFeesFromFRR.decimal || 2,
      CurrentFeesFromFRR.suffix || '%',
      '--'
    )
    : '--'
  return (
    <React.Fragment>
      <div className="content">
        {/*heading*/}
        <div className="pr-v2-header">
          <h4 className={style.titleH4}>Additional Details of Suggested Replacement
            for {currentTargetCard.cardInfo.ticker}</h4>
        </div>
        <div className="pr-v2-port-performance">
          <Row className="pr-v2-pp-sections">
            <Col xs="12">
              <div className={style.existingHoldingWrapper}>
                <h5 className={style.subsectionH5}>Existing Holding </h5>
                <div>
                  <h5 className={style.subsectionH5} style={{ display: 'inline-block' }}>
                    {currentTargetCard.cardInfo && currentTargetCard.cardInfo.short_name}
                  </h5>
                  <img className={style.tickerLogo} src={sponsorLogo(currentTargetCard && currentTargetCard.cardInfo && currentTargetCard.cardInfo.sponsor)} alt="" />
                  <span>
                    {currentTargetCard && currentTargetCard.cardInfo && currentTargetCard.cardInfo.ticker}
                  </span>
                </div>
                <p>{currentTargetCard && currentTargetCard.cardInfo && currentTargetCard.cardInfo.short_desc}</p>
              </div>
              <div className={style.keyFactsWrapper}>
                <h5 className={style.subsectionH5}>Key Facts</h5>
                <div className={style.keyFacts}>
                  {(CurrentFeesFromFRR) && <>
                    <div className={style.expense}>
                      <p>Expense Ratio </p>
                      <p>{expenseRatio} </p>
                    </div>
                  </>}
                  {(currentTargetCard.cardInfo && currentTargetCard.cardInfo.aum) && <>
                    <div className={style.border} />
                    <div className={style.aum}>
                      <p>Aum </p>
                      <p> {kmb(currentTargetCard.cardInfo.aum)} </p>
                    </div>
                  </>}
                  {(currentTargetCard.cardInfo && currentTargetCard.cardInfo.underlying_index) && <>
                    <div className={style.border} />
                    <div className={style.underIndex}>
                      <p>Benchmark Index </p>
                      <p> {currentTargetCard.cardInfo.underlying_index} </p>
                    </div>
                  </>}
                </div>
              </div>
            </Col>
          </Row>
          {/*suggested replacement*/}
          <AntRow className={style.suggestedReplacementWrapper} style={{ padding: '0 16px'}}>
            <AntCol span={10} />
            <AntCol span={4} />
            <AntCol span={10}>
              <h5 className={style.subsectionH5}>Suggested Replacement</h5>
            </AntCol>
          </AntRow>
          <div className={style.suggestedReplacementWrapper}>
            <ItemCard
              classes={style.cardGrayBackground}
              data={currentTargetCard}
              // query={data.query}
              // defaultFRR={data._defaultFRR}
              // relevanceChartTabs={data._relevanceChartTabs}
              ticker={data.ticker}
              // originalQueryVar={data.query_var}
              // highlights={currentTargetCard.Highlights}
            />
            <SwapOutlined className="swap-icon-wrapper" />
            <ItemCard
              classes={style.cardBlueBorder}
              data={data.selected}
              // query={data.query}
              // originalQueryVar={data.query_var}
              // defaultFRR={data._defaultFRR}
              // relevanceChartTabs={data._relevanceChartTabs}
              ticker={data.ticker}
              // highlights={data.Highlights}
            />
          </div>
          {/*other replacement*/}
          {otherFunds.length > 0 && <>
            <h5 className={style.subsectionH5}>Other Possible Recommendations</h5>
            <div className={style.otherResultCards}>
              {otherFunds.map((ticker, i) => {
                return (ticker && i < numberOfOtherToShow) ? (
                  <ItemCard
                    data={ticker}
                    query={data.query}
                    defaultFRR={data._defaultFRR}
                    relevanceChartTabs={data._relevanceChartTabs}
                    ticker={data.ticker}
                    originalQueryVar={data.query_var}
                    highlights={data.Highlights}
                  />
                ) : null
              })}
            </div>
          </>
          }

        </div>
      </div>
    </React.Fragment>
  )
})
export default SingleReplacement

import React, { Component, useState, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import qs from 'query-string'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { get } from 'lodash'
import moment from 'moment'

import {
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  Form
} from 'reactstrap'
import { CheckOutlined } from '@ant-design/icons'

import { auth, failedGoogleAuth } from 'actions/auth'
import { registerProfileData, registerGoogle, setRegisterState } from 'actions/register'
import { storeAuthToken } from 'common/auth-utils'
import * as ProfileApi from '../../../apis/profile'
import { createLoadingSelector } from 'selectors/loading-selector'
import { Spinner } from 'shared-components/'
import { isTermsAgreed } from 'selectors/auth-selector'
import { REDIRECT_DOMAIN_PATH } from 'DataSet'
import { addEventToAnalytics } from 'Utils'
import { getAgeMapping, investableAssetsList, ADVISOR_REGISTER_LHS } from '../Constant/FormData'
import '../Register/RegisterFormOpt2.scss'
import { createSubscriptionRequest, updateProfile } from 'actions/profile'

import RegistrationRequiredDetails, {
  Tagline
} from './RegistrationRequiredDetails'
import config from '../../../env'
import { PrimaryButton } from 'shared-components/'
// import { Regex } from 'common/regex'
import { updateMetaInfo } from '../utils'

// const magnifiLogo = '/assets/images/MagnifiLogo/Magnifi_Tagline_White_RGB@2x.png'
const lhsmuchmore = '/assets/images/misc/2-Much-More.png'
const lhsCalculator = '/assets/images/misc/1-Calculator.png'
const lhsEnhance = '/assets/images/misc/2-Enhancement.png'
// const magnifiInvestResponsiblyLogo = '/assets/images/register-magnifi-invest-resp-logo.svg';
const magnifiByTiffinLogo = '/assets/images/magnifi_by_tifin.svg';

class Register extends Component {
  state = {
    modal: true,
    showSuccess: false,
    isStripeSubscription: false,
    planName: '',
    plan_selected: '',
    isPlanChange: false,
    showStep2: false,
    openTradingRegisterPage: null,
    registerForTrade: null,
    isFetching: false,
    isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    initialValues: {
      termsCondition: false,
      // country: '',
      // zipcode: '',
      // state: '',
      // stateInput: '',
      // state_value: '',
      // city: '',
      // address: '',
      // date_of_birth: this.props.profileData.profile && this.props.profileData.profile.date_of_birth ? this.props.profileData.profile.date_of_birth : '',
      self_role:
        process.env.REACT_APP_SUB_DOMAIN === 'retail'
          ? 'Individual Investor'
          : '',
      areYouAnOther: '',
      investable_assets: this.props.profileData.profile && this.props.profileData.profile.investable_assets ? this.props.profileData.profile.investable_assets : '',
      estimated_net_worth: '',
      hear_aboutus: '',
      // freeTrial: false,
      // company_name: '',
      // financial_advisor: false
    },
    CalendarInviteScreen: false,
  }

  // initialValues = {
  //   termsCondition: false,
  //   country: '',
  //   zipcode: '',
  //   state: '',
  //   stateInput: '',
  //   state_value: '',
  //   city: '',
  //   address: '',
  //   date_of_birth: '',
  //   self_role:
  //     process.env.REACT_APP_SUB_DOMAIN === 'retail'
  //       ? 'Individual Investor'
  //       : '',
  //   areYouAnOther: '',
  //   investable_assets: '',
  //   estimated_net_worth: '',
  //   hear_aboutus: '',
  //   freeTrial: false
  // }

  componentDidMount() {
    updateMetaInfo({ title: 'Register With Magnifi | Innovation-Based Investing & Portfolio Management', description: 'Create your free Magnifi account and get access to customized investment portfolios for you or your client. Discover a whole new world of investing opportunities today!' });
    document.getElementsByTagName('body')[0].style.paddingTop = 0
    let email = localStorage.getItem('socialmail') || window.sessionStorage.getItem('socialmail') || "";
    let { plan_selected } = this.props
    this.setState({
      plan_selected: plan_selected ? plan_selected : 'basic'
    })
    addEventToAnalytics('Register Google Page', 'Register Google Page', 'REGISTER_GOOGLE_PAGE', {
      clickPage: 'Register Google Page',
      email: email
    })

    const {
      match,
      setRegisterState,
    } = this.props
    console.log("match -> ", match)
    if (
      match &&
      typeof match.params !== 'undefined' &&
      typeof match.params.id !== 'undefined' &&
      match.params.id !== '' &&
      setRegisterState
    ) {
      console.log(match.params.id)
      setRegisterState({
        registerGoogleAuthToken: match.params.id,
      });
    }
  }

  // Stripe disabled, handled by back-end side.
  // componentDidUpdate(prevProps) {
  //   const {
  //     stripeSessionID,
  //     createSubscriptionRequest,
  //     registerSuccess,
  //     planType
  //   } = this.props
  //   const { isStripeSubscription } = this.state
  //
  //   if (
  //     registerSuccess !== prevProps.registerSuccess &&
  //     registerSuccess &&
  //     !stripeSessionID
  //   ) {
  //     createSubscriptionRequest({
  //       plan_name: this.state.planName || 'basic',
  //       planType: planType || '',
  //     })
  //   }
  //
  //   if (
  //     prevProps.stripeSessionID !== stripeSessionID &&
  //     stripeSessionID &&
  //     isStripeSubscription
  //   ) {
  //     this.subscribeNow()
  //     return
  //   }
  // }

  componentWillUnmount() {
    updateMetaInfo();
  }

  updateUsersProfile = async (postData, callback) => {
    try {
      this.setState({ isFetching: true })
      let _responseData = await ProfileApi.updateProfile(
        qs.stringify({ ...postData })
      )
      callback({ responseData: _responseData })
      this.setState({ isFetching: false })
    } catch (error) {
      console.log(error)
      this.setState({ isFetching: false })
      if (error && typeof error.response !== 'undefined') {
        if (typeof error.response.data !== 'undefined') {
          //TO DO
        }
      }
    }
  }

  handleSubmit = (values, { setSubmitting }, showStep2 = false) => {
    const returnedToEdit= this.props.history && this.props.history.location && this.props.history.location.returnToEdit ? true : false;

    if(returnedToEdit){
      let postData = JSON.parse(JSON.stringify(this.props.profileData.profile))
      postData['investable_assets'] = values.investable_assets;
      postData['date_of_birth'] = values.date_of_birth
      ? typeof values.date_of_birth.getMonth === 'function'
        ? moment(values.date_of_birth).format('YYYY-MM-DD')
        : moment(values.date_of_birth).format('YYYY-MM-DD')
      : values.date_of_birth;
      this.updateUsersProfile(postData, ()=>{
        this.props.history.push({ pathname: '/register/trading', fromRegister: true, fromPathRouter: this.props.location.pathname })
      })

      return
    }

    // console.log(values)
    window.localStorage.removeItem('socialmail');
    window.localStorage.setItem('profile_self_role', values.self_role)
    const ageValue = getAgeMapping(values.age_group)
    let { plan_selected } = this.state
    let data = {
      ...values,
      register_step_status: '1',
      age_group: ageValue || null
    }
    let isPlanChange = localStorage.getItem('isPlanChange')
      ? JSON.parse(localStorage.getItem('isPlanChange'))
      : false
    if (!isPlanChange) {
      if (plan_selected === 'pro') {
        this.subscribePlan('$250')
      } else if (plan_selected === 'pro-plus') {
        this.subscribePlan('$500')
      }
    } else {
      this.setState({
        isPlanChange: isPlanChange
      })
    }
    if (
      values.self_role === 'Individual Investor' ||
      values.self_role === 'Other/Curious'
    ) {
      data = {
        ...data,
        estimated_net_worth: null
      }
    } else if (values.self_role === 'Other/Curious') {
      data = {
        ...data,
        self_role: values.areYouAnOther
      }
    } else {
      data = {
        ...data,
        investable_assets: null
      }
    }

    addEventToAnalytics(
      'Register with Google login',
      'Register with Google login',
      'REGISTER_WITH_GOOGLE_LOGIN'
    )

    const { registerGoogleAuthToken, registerProfileData } = this.props
    if (registerGoogleAuthToken) {
      const payload = {
        data,
        token: registerGoogleAuthToken
      }
      registerProfileData(payload, () => {
        setTimeout(() => {
          // if (
          //   plan_selected === 'basic' &&
          //   process.env.REACT_APP_SUB_DOMAIN === 'retail'
          // ) {
          //   // for retail after filling second form, redirect to '/'
          //   // this.setState({
          //   //   showStep2: true
          //   // })
          //   if (this.state.registerForTrade === 'OPEN_TRADING_REGISTER_PAGE') {
          //     // debugger
          //     addEventToAnalytics('Create Free Magnifi Trading Account Click', 'Create Free Magnifi Trading Account Click', 'CREATE_FREE_MAGNIFI_TRADING_ACCOUNT_CLICK', {
          //       clickPage: 'Google Register Page'
          //     })
          //     this.props.history.push({ pathname: '/register/trading', fromRegister: true, fromPathRouter: this.props.location.pathname });
          //   } else {
          //     addEventToAnalytics('Register Now Trade Later Click', 'Register Now Trade Later Click', 'REGISTER_NOW_TRADE_LATER_CLICK', {
          //       clickPage: 'Google Register Page'
          //     })
          //     this.props.history.push('/')
          //   }
          //   // this.props.history.push('/')
          // } else {
          //   // let data2 = {
          //   //   termsCondition:false
          //   // }
          //   // this.props.updateProfile(data2);
          //   // if (plan_selected == 'basic' && (values.self_role !== 'Individual Investor' &&  values.self_role !== 'Other/Curious' && !isPlanChange)) {
          //   //   // NOTE: Show Calendar invite screen
          //   //   // ------------------------------------------------------------
          //   //   this.setState({
          //   //     CalendarInviteScreen: true,
          //   //   });
          //   //   // ------------------------------------------------------------
          //   //   // this.props.history.push('/')
          //   // }
          //   this.setState({
          //     CalendarInviteScreen: true,
          //   });
          // }

          this.setState({
            CalendarInviteScreen: true,
          });

        }, 1000)
      })
      setSubmitting(false)
    }

    // plan_selected==='basic'&&this.setState({
    //   showStep2:true
    // })
  }

  registerSchema = Yup.object().shape({
    // termsCondition: Yup.boolean().oneOf(
    //   [true],
    //   'Please accept terms and conditions'
    // ),
    self_role: Yup.string().required('Please select your profile'),

    age_group: Yup.string(),
    hear_aboutus: Yup.string(),
    // country: Yup.string().required('Please select a country'),
    // country: Yup.string().when('self_role', {
    //   is: self_role =>
    //     !['', 'Individual Investor', 'Other/Curious'].includes(self_role),
    //   then: Yup.string().required('Please select a country'),
    //   otherwise: Yup.string()
    // }),

    // zipcode: Yup.string().required('Please enter your zipcode').matches(Regex.zipCode, 'zip code is not valid'),

    // zipcode: Yup.string().when('self_role', {
    //   is: self_role =>
    //     !['', 'Individual Investor', 'Other/Curious'].includes(self_role),
    //   then: Yup.string()
    //     .required('Please enter your zipcode')
    //     .matches(Regex.zipCode, 'zip code is not valid'),
    //   otherwise: Yup.string()
    // }),

    //age_group:Yup.string(),

    // date_of_birth: Yup.string()
    //   .required('Please select your DOB')
    //   .test(
    //     'is-valid-date',
    //     'Invalid Date',
    //     dob => moment(dob).isValid()
    //   )
    //   .test(
    //     'is-over-18',
    //     'Age must be over 18',
    //     dob => moment().diff(dob, 'years') >= 18
    //   ),

    // state: Yup.string().required('Please enter your state'),
    // state: Yup.string().when('self_role', {
    //   is: self_role =>
    //     !['', 'Individual Investor', 'Other/Curious'].includes(self_role),
    //   then: Yup.string().required('Please enter your state'),
    //   otherwise: Yup.string()
    // }),

    // city: Yup.string().required('Please enter your city'),
    // city: Yup.string().when('self_role', {
    //   is: self_role =>
    //     !['', 'Individual Investor', 'Other/Curious'].includes(self_role),
    //   then: Yup.string().required('Please enter your city'),
    //   otherwise: Yup.string()
    // }),

    // address: Yup.string().required('This is a required field').matches(
    //   Regex.pobox,
    //   'Invalid street address'
    // ).max(30, 'Street must be at most 30 characters'),
    // address: Yup.string().when('self_role', {
    //   is: self_role =>
    //     !['', 'Individual Investor', 'Other/Curious'].includes(self_role),
    //   then: Yup.string()
    //     .required('This is a required field')
    //     .matches(Regex.pobox, 'Invalid street address')
    //     .max(30, 'Street must be at most 30 characters'),
    //   otherwise: Yup.string()
    // }),

    areYouAnOther: Yup.string().when('self_role', {
      is: self_role => self_role === '',
      then: Yup.string().required('Enter a value'),
      otherwise: Yup.string()
    }),

    investable_assets: Yup.string().when('self_role', {
      is: (self_role) => ['', 'Individual Investor', 'Other/Curious'].includes(self_role),
      then: Yup.string().required('Please Select a Investable Assets'),
      otherwise: Yup.string()
    }),
    // company_name: Yup.string()
    //   .required('Company name is a required field'),
    // financial_advisor: Yup.bool().oneOf([true], 'This is required field.'),
    estimated_net_worth: Yup.string()
    // .when('self_role', {
    //   is: (self_role) => (self_role === 'Financial Advisor' ||  self_role === 'Family Office' ||
    //   self_role === 'Portfolio Manager' || self_role === 'Hedge Fund Manager' ||
    //   self_role === 'Corporate Investor'
    //   ),
    //   then: Yup.string()
    //     .required('Please Select a Assets Under Management/ Advice.'),
    // }),
  })

  routeToPage = () => {
    const currentPath = get(this.props, 'location.pathname', '')
    const pathname = this.props.fromPath || '/'
    if (pathname !== currentPath) {
      this.props.history.push(pathname)
    }
  }

  subscribeNow = () => {
    const sessionId = this.props.stripeSessionID
    if (sessionId) {
      const stripe = window.Stripe(config.stripePublishableKey)
      stripe
        .redirectToCheckout({
          sessionId
        })
        .then(result => {
          console.log('results status', result)
        })
    }
  }

  subscribePlan = plan => {
    switch (plan) {
      case '$250':
        // $250 plan
        this.setState({
          planName: 'Premium Lite'
        })
        break
      case '$500':
        // $500 plan
        this.setState({
          planName: 'Premium Plan'
        })
        break
      default:
        // $250 plan
        this.setState({
          planName: 'Premium Lite'
        })
    }
    this.setState({ isStripeSubscription: true })
  }

  updatePlanChange = value => {
    this.setState({
      isPlanChange: value
    })
  }

  registerForTrade = (state) => {
    // console.log('registerForTrade ==> ', state);
    this.setState({ registerForTrade: state });
  }

  handleCalendarOkClick = () => {
    addEventToAnalytics('Not Yet. Take Me To My Account','Not Yet. Take Me To My Account','NOT_YET_TAKE_ME_TO_MY_ACCOUNT',{});
    this.props.history.push('/')
  }

  render() {
    const {
      // loggedIn,
      registerSuccess,
      // registerStepStatus,
      // registerGoogleAuthToken,
      // match,
      profileUpdateSuccess
    } = this.props
    // const { params } = match
    const {
      // isStripeSubscription,
      plan_selected,
      isPlanChange,
      showStep2,
      CalendarInviteScreen
    } = this.state

    let returnedToEdit= this.props.history && this.props.history.location && this.props.history.location.returnToEdit ? true : false;

    if ((registerSuccess || profileUpdateSuccess) && isPlanChange) {
      localStorage.removeItem('isPlanChange')
      // console.log('plan change redirect to join')
      return (
        <Redirect
          to={{
            pathname: '/join',
            state: { isPlanChange: true }
          }}
        />
      )
    }

    // console.log('CalendarInviteScreen --> ', CalendarInviteScreen);

    return (
      <Fragment>
        <Row className="magnifi-auth-page-wrapper register-magnifi-opt-2">
          <Col className="left-side-panel-container different_advisor">
            <Row style={{ alignItems: "center", width: '100%' }}>
              <Col sm={12} className="text-center">
                <a className="logo-wrapper mt-1" href={REDIRECT_DOMAIN_PATH} style={{ width: '100%' }}>
                  <img style={{ width: '60%' }} src={magnifiByTiffinLogo} height={70} alt="magnifi" />
                </a>
              </Col>
              <Col sm={12}>
                <div className="list_content text-center">
                  <span className="span_head">Become an AI-Powered Financial Advisor</span>
                  <div style={{ width: '90%', margin: 'auto' }}>
                    {ADVISOR_REGISTER_LHS.map((el, index) => {
                      return (
                        <Row className={index === 0 ? "text-left pt-5" : "text-left pt-4"} style={{ maxWidth: '100%' }}>
                          <Col className={'text-right p-0 pr-2'} sm={2}><CheckOutlined style={{ color: '#fff', fontWeight: 'bold', fontSize: '1.4rem' }} /></Col>
                          <Col className={'p-0 pl-3'} sm={10} style={{ display: 'flex', alignItems: 'center' }}><span style={{ color: '#fff', fontSize: '1.1rem', lineHeight: '1.5rem' }}>{el}</span></Col>
                        </Row>
                      )
                    })}
                  </div>
                </div>
              </Col>
            </Row>
            {/* {!isMobile && (
              <Row style={{ flex: 1 }}>
                <div className="contact-info">
                  <p><a href="mailto:support@magnifi.com">support@magnifi.com</a> | Anytime</p>
                </div>
              </Row>
            )} */}
          </Col>
          <Spinner isFetching={this.state.isFetching}/>
          {CalendarInviteScreen ? (
            <Col md="6" lg="6" className="right-side-panel-container register-page-height">
              <div className="magnifi-auth-design-container advisor_different_layout calendar-invite-screen-wrapper" style={{ margin: 'auto' }}>
                <div className="header-area retail_form_container text-center">
                  <h1>Your account is ready!</h1>
                  <div className="header-label main">Schedule a personalized demo to see Magnifi in action</div>
                </div>
                <div
                  class="meetings-iframe-container"
                  data-src="https://meetings.hubspot.com/rob-stella/magnifi-for-advisors-30-min-demo?embed=true"
                  style={{ minHeight: 640 }}
                ></div>
                <Helmet>
                  <script
                    type="text/javascript"
                    src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
                  ></script>
                </Helmet>
                <div className="cta-wrapper registration-btn-wrapper">
                  <PrimaryButton
                    type="button"
                    className="btn primary-btn"
                    onClick={this.handleCalendarOkClick}
                  >
                    Not yet. Take me to my account
                  </PrimaryButton>
                </div>
              </div>
            </Col>
          ) : (
            <Fragment>
              {!showStep2 ? (
                <Col
                  md="6"
                  lg="6"
                  className="right-side-panel-container register-page-height"
                >
                  <Formik
                    initialValues={this.state.initialValues}
                    onSubmit={this.handleSubmit}
                    validationSchema={this.registerSchema}
                    render={prop => (
                      <>
                        <RegistrationRequiredDetails
                          {...this.props}
                          formProps={prop}
                          returnedToEdit={returnedToEdit}
                          onToggle={this.handleToggle}
                          error={this.props.error}
                          subscribeNow={plan => this.subscribePlan(plan)}
                          plan_selected={plan_selected}
                          registerForTrade={this.registerForTrade}
                        />
                        {this.state.isMobile && (
                          <Row style={{ flex: 1 }}>
                            <div className="contact-info">
                              {/* <p>Call or Text Magnifi Support: <a href="tel:7204455530">(720) 445-5530</a> | 9am - 6pm EST</p> */}
                              <p><a href="mailto:support@magnifi.com">support@magnifi.com</a> | Anytime</p>
                            </div>
                          </Row>
                        )}
                      </>
                    )}
                  />
                </Col>
              ) : (
                <Col
                  md="6"
                  lg="6"
                  className={`right-side-panel-container register-page-height ${process.env.REACT_APP_SUB_DOMAIN === 'retail' && 'd-flex flex-column justify-content-center'}`}
                >
                  <RegisterFormRHS3
                    {...this.props}
                    plan_selected={plan_selected}
                    updatePlanChange={this.updatePlanChange}
                  />
                </Col>
              )}
            </Fragment>
          )}
        </Row>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ auth, register, loading, profile }) => ({
  registerGoogleAuthToken: register.registerGoogleAuthToken,
  registerStepStatus: register.registerStepStatus,
  registerFailed: register.registerFailed,
  registerSuccess: register.registerSuccess,
  error: register.error,
  isTermsAgreed: isTermsAgreed(auth),
  loggedIn: auth.loggedIn,
  profileUpdateSuccess: profile.profileUpdateSuccess,
  stripeSessionID: profile.stripeSessionID,
  plan_selected: register.plan_selected,
  planType: register.planType,
  profileData: profile,
  isFetching: createLoadingSelector(['REGISTER_PROFILE'])({ loading }),
})

const mapDispatchToProps = {
  failedGoogleAuth,
  registerProfileData,
  createSubscriptionRequest,
  updateProfile,
  setRegisterState,
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)

const RegisterFormLHS = () => (
  <div className="text-center w-75 carousel-panel">
    <h1 className="header-1">Why Personalize?</h1>
    {/* <img src={lhsStep1} width="100%" alt="magnifi" /> */}
    <MessagesCarousel />
  </div>
)

const LHSMsg = [
  {
    src: lhsCalculator,
    altText: '',
    caption: 'Access calculators to compare funds'
  },
  {
    src: lhsEnhance,
    altText: '',
    caption: 'Build and evaluate changes to your portfolio'
  },
  {
    src: lhsmuchmore,
    altText: '',
    caption: 'And much more...'
  }
]

const MessagesCarousel = props => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)
  const items = LHSMsg

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = newIndex => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  // const slides = items.map((item, i) => {
  //   return (
  //     <CarouselItem key={i} className="h4"
  //       onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} >
  //       {item}
  //     </CarouselItem>
  //   );
  // });

  const slides = items.map(item => {
    return (
      <CarouselItem
        className="carouselImg"
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
        id="caraousel-id"
      >
        <img src={item.src} alt={item.altText} />
        <h4 style={{ color: '#fff', fontWeight: '400', fontSize: '20px' }}>
          {item.caption}
        </h4>
        {/* <CarouselCaption captionText={item.caption} captionHeader={item.caption} /> */}
      </CarouselItem>
    )
  })

  return (
    // <Carousel activeIndex={activeIndex} next={next} previous={previous}
    //   interval={5000} ride="carousel" >
    //   <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} className="dots" />
    //   {slides}
    // </Carousel>
    <Carousel
      interval={2000}
      ride="carousel"
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
        className="dots"
        id="caraousel-dots"
      />
      {slides}
      {/* <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} /> */}
    </Carousel>
  )
}

class RegisterFormRHS3 extends Component {
  componentDidMount() {
    document.getElementsByTagName('body')[0].style.paddingTop = 0
  }

  createTradingAccount = () => {
    this.props.history.push('/register/trading')
  }

  upgradeToPremium = () => {
    this.props.updatePlanChange(true)
  }

  validateForm(e, plan) {
    const { subscribeNow, formProps } = this.props
    const { validateForm, handleSubmit } = formProps

    subscribeNow(plan)
    handleSubmit(e)
  }

  render() {
    const { openTradingRegisterPage , history } = this.props;
    // const profile_self_role = window.localStorage.getItem('profile_self_role') ? window.localStorage.getItem('profile_self_role') : 'loggedOut';
    // const selectorText = RETAIL_USERS_ROLES.includes(profile_self_role) ? "Cart" : "Selector";

    const selectorText =
      process.env.REACT_APP_SUB_DOMAIN === 'retail' ? 'Cart' : 'Selector'
    console.log('=== this.props.plan_selected ===', this.props.plan_selected)
    if (
      process.env.REACT_APP_SUB_DOMAIN === 'retail' &&
      this.props.plan_selected === 'basic'
    ) {
      return (
        <Form
          name="RegisterFormRHS3"
          className={`magnifi-auth-design-container register-magnifi-auth-design ${process
            .env.REACT_APP_SUB_DOMAIN === 'retail' &&
            'd-flex flex-column justify-content-center'}`}
          noValidate={true}
        >
          <Tagline />
          <div className="header-area">
            <div className="header-label">
              You are now a registered user at magnifi.com that allows you to
              Discover a new world of investment shopping
            </div>
          </div>
          {/*<div className="trading-benefits">*/}
          {/*  <h1>Create Magnifi Trading Account</h1>*/}
          {/*  <h2>Benefits of Trading Account With Magnifi</h2>*/}
          {/*  <ul className="row">*/}
          {/*    <li className="col-lg-6 col-12"><b>Selector:</b> Personalize our patented fund selector to find the best fund for you among relevant funds discovered.</li>*/}
          {/*    <li className="col-lg-6 col-12"><b>Builder:</b> Start with selected funds or initial portfolio to build a portfolio for proposals or trades.</li>*/}
          {/*    <li className="col-lg-6 col-12"><b>Enhancer:</b> Read in client, prospect or sponsor provided portfolios and evaluate enhancements with simple language based queries.</li>*/}
          {/*    <li className="col-lg-6 col-12"><b>Proposals:</b> For clients to help convert and explain; and for compliance from fund selector, builder and enhancer. </li>*/}
          {/*    <li className="col-lg-6 col-12"><b>Save:</b> Results and download to spreadsheets.</li>*/}
          {/*  </ul>*/}
          {/*</div>*/}

          <div className="cta-wrapper registration-btn-wrapper">
            <PrimaryButton
              type="button"
              className="btn primary-btn"
              onClick={this.createTradingAccount}
            >
              Create Magnifi investment account
            </PrimaryButton>
          </div>
          {process.env.REACT_APP_SUB_DOMAIN !== 'retail' && (
            <Fragment>
              <div className="trading-benefits">
                <h1 style={{ paddingTop: `10px` }}>Upgrade to Premium</h1>
                <h2>Benefits Of premium account</h2>
                <ul className="row">
                  <li className="col-lg-6 col-12">
                    <b>{selectorText}:</b> Personalize our patented fund
                    selector to find the best fund for you among relevant funds
                    discovered.
                  </li>
                  <li className="col-lg-6 col-12">
                    <b>Builder:</b> Start with selected funds or initial
                    portfolio to build a portfolio for proposals or trades.
                  </li>
                  <li className="col-lg-6 col-12">
                    <b>Enhancer:</b> Read in client, prospect or sponsor
                    provided portfolios and evaluate enhancements with simple
                    language based queries.
                  </li>
                  <li className="col-lg-6 col-12">
                    <b>Proposals:</b> For clients to help convert and explain;
                    and for Compliance from fund selector, builder and enhancer.{' '}
                  </li>
                  <li className="col-lg-6 col-12">
                    <b>Save:</b> Results and download to spreadsheets.
                  </li>
                </ul>
              </div>
              <div className="cta-wrapper registration-btn-wrapper">
                {/*<Row className="registration-btn-wrapper">*/}
                {/*  <Col className="gutter-row">*/}
                {/*    <SecondaryButton*/}
                {/*      type="button"*/}
                {/*      className="btn secondary-btn cta-btn"*/}
                {/*      onClick={(e) => this.validateForm(e, '$250')}*/}
                {/*    >*/}
                {/*      PREMIUM LITE FOR $250 MONTH*/}
                {/*    </SecondaryButton>*/}
                {/*  </Col>*/}
                {/*  <Col className="gutter-row">*/}
                {/*    <SecondaryButton*/}
                {/*      type="button"*/}
                {/*      className="btn secondary-btn cta-btn"*/}
                {/*      onClick={(e) => this.validateForm(e, '$500')}*/}
                {/*    >*/}
                {/*      PREMIUM FOR $500 MONTH*/}
                {/*    </SecondaryButton>*/}
                {/*  </Col>*/}
                {/*</Row>*/}
                <PrimaryButton
                  type="button"
                  onClick={this.upgradeToPremium}
                  className="btn primary-btn"
                >
                  Upgrade to Premium
                </PrimaryButton>
              </div>
            </Fragment>
          )}
          <p
            className={'skip'}
            onClick={() => {
              this.props.history.push('/')
            }}
          >
            Skip and Start Discovering
          </p>
        </Form>
      )
    } else {
      return null
    }
  }
}

import React, { Component } from 'react';
import './_index.scss';
import { addEventToAnalytics } from 'Utils';
import  SearchSuggestions  from 'shared-components/SearchSuggestions';

class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
        query:'',
        placeholder:'What do you want to invest in?',
        index:0,
    }
    this.inputRef = React.createRef();
  }

  handleSearch = (val) => {
    if (val) {
      addEventToAnalytics('Search TextBar Submit','Search TextBar Submit','SEARCH_TEXT_BAR_SUBMIT',{});
      this.props.queryHandler(val);
    }
  }

  queryHandler = (link) => {
    const { profile } = this.props;
    // console.log('== profile ==>',profile);
    if (profile && profile.apex) {
      const apex_obj = profile.apex;
      const isApexEmpty = apex_obj && Object.keys(apex_obj).length === 0 && apex_obj.constructor === Object;
      if (isApexEmpty) {
        // if apex object is empty or not present then open link
        if(link){
          addEventToAnalytics('Scrolling Query Click','Scrolling Query Click','SCROLLING_QUERY_CLICK',{});
          window.open(
            link,
            '_blank'
          );
        }
      }
    } else {
      // if apex key not present then open link
      if(link){
        addEventToAnalytics('Scrolling Query Click','Scrolling Query Click','SCROLLING_QUERY_CLICK',{});
        window.open(
          link,
          '_blank'
        );
      }
    }
  }

  handleChange=(val)=>{
    this.setState({query:val})
  }
  
  render() {
    return (
            <>
            <div className="inner-addon right-addon" style={{ position: 'relative' }}>
              <SearchSuggestions
                isLandingPage={true}
                searchText={this.state.query}
                setSearchText={(val)=>{this.handleChange(val)}}
                onPressEnter={this.handleSearch}
              />
            </div>
           </>
    );
  }
}


export default Search

import React from 'react';
import { formatTickerForView } from 'Utils';
import { SimplePieChart } from 'components/Charts';
import NotApplicable from 'components/NotApplicable';

export const TopHoldingChart = (props) => {
  let { card, view, data, chartData } = props;

  if (data.length <= 0) return <NotApplicable />;

  let dataVar, centerDisplay, centerText;
  const chrtD = chartData || card.chartData;
  if (typeof chrtD !== 'undefined') {
    const currentChartData = chrtD.filter(e => e.view_name === view)[0];
    if (typeof currentChartData !== 'undefined') {
      if (Object.prototype.toString.call(currentChartData.data) === '[object Array]') {
        dataVar = currentChartData.data[0];
      } else if( typeof  currentChartData.data === 'string' ) {
        dataVar = currentChartData.data;
      }
      dataVar = formatTickerForView(dataVar);
      if (typeof card[currentChartData.variable] !== 'undefined') {
        if (card[currentChartData.variable] === 0) {
          const value = card[currentChartData.variable];
          centerDisplay = currentChartData.chart_center_abovetext.replace('value', value);
          centerText = currentChartData.chart_center_belowtext.replace('ticker', dataVar);
        } else {
          const value = parseFloat(card[currentChartData.variable]).toFixed(1);
          centerDisplay = currentChartData.chart_center_abovetext.replace('value', value);
          centerText = currentChartData.chart_center_belowtext.replace('ticker', dataVar);
        }
      }
    };
  }

  return (
    <SimplePieChart
      {...props}
      data={props.data}
      colors={props.colors}
      chartName={props.name}
      centerDisplay={centerDisplay}
      centerText={centerText}
    />
  )
}

export default TopHoldingChart;

import React from 'react';
// import { Row, Col } from 'reactstrap';
import NotApplicable from 'components/NotApplicable';
import GaugeChart from './GaugeChart';

export const AssetChart = ({ card }) => {
  if(typeof card._categorySeries == 'undefined' || !card._categorySeries) return <NotApplicable />
  // const mult = qi.name === 'Assets' ? 1000 * 1000 : 1;
  const _min = card._categorySeries['aum_min'],
  _max = card._categorySeries['aum_max'],
  _val = card['aum'],
  _pc = card['aum'];
  if(typeof _val == 'undefined' || _val == null) return <NotApplicable />
  if(typeof _min == 'undefined' || _min == null) return <NotApplicable />
  if(typeof _max == 'undefined' || _max == null) return <NotApplicable />
  const suffix = ['', 'K', 'M', 'B'];
  const kmb = (val, i = 0) => (val < 100 || i > 2) ? `$${val.toFixed(1)}${suffix[i]}` : kmb(val / 1000, i + 1)
  // const noMFData = ((card.vehicle !== "MF" && qi.name === 'Volume') || qi.name === 'Assets') ? true : false;
  // const noMFData = true
  return (
    <div className="siw-meter print-wrapper">
      <span style={{fontSize:`14px`}}>Assets</span>
      <GaugeChart
        value={_val}
        max={_max}
        min={_min}
      />
      <div className="d-flex" style={{ width: '100px', justifyContent :"space-between" }}>
        <span className="siw-meter-min">
          {kmb(_min)}
        </span>
        <span className="siw-meter-value">
          {kmb(_val)}
        </span>
        <span className="siw-meter-max">
          {kmb(_max)}
        </span>
      </div>
    </div>
  );
}

export default AssetChart;

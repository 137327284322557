import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Space, Input, Menu, Button } from 'antd';
import { getModelName } from '../../../Utils';
import { filterClients, filterDownloadedListRequest } from 'actions/clientportfolioV2';

class DropdownContent extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      searchText: ''
    }
    this[this.props.selector] = React.createRef();
  }

  componentDidMount(){
    this[this.props.selector] && this[this.props.selector].current.focus();
  }

  componentDidUpdate(prevProps){
    this[this.props.selector] && this.props.showDropdown && this[this.props.selector].current.focus();
    if(prevProps.clientsfilteredColumn != this.props.clientsfilteredColumn ||
      prevProps.clientsClearAllFilter != this.props.clientsClearAllFilter){
      this.setState({
        searchText: ''
      })
    }
  }

  handleSearchText = (e) => {
    this.setState({
      searchText: e.target.value
    })
  }

  handleReset = () => {
    this.setState({
      searchText: ''
    }, () => {
      this.props.handleSearchReset();
    });
  }

  handleSearchSubmit = () => {
    let filteredClients;
    const { selector, clientList, initClientList } = this.props;
    const { searchText } = this.state;
    // if(searchText && clientList.length > 0){
    //   if(selector !== 'selectedModel'){
    //     filteredClients = clientList.filter(item => item[selector] &&
    //       item[selector].toLowerCase().includes(searchText.toLowerCase()))
    //   }else{
    //     filteredClients = clientList.filter(item => getModelName(item) &&
    //       getModelName(item).toLowerCase().includes(searchText.toLowerCase()))
    //   }
    //   if(filteredClients.length > 0){
    //     this.props.filterClients({
    //       clientList: filteredClients,
    //       clientsfilteredColumn: selector,
    //       clientsfilteredText: searchText,
    //       clientsClearAllFilter: false
    //     })
    //   }else{
    //     // Create a reset filter or no results action
    //     this.props.filterClients({
    //       clientList: [],
    //       clientsfilteredColumn: selector,
    //       clientsfilteredText: searchText,
    //       clientsClearAllFilter: false
    //     })
    //   }
    // }

    const { filterDownloadedListRequest } = this.props;
    if (filterDownloadedListRequest) {
      filterDownloadedListRequest({
        key: selector,
        query: searchText,
        clientsfilteredColumn: selector,  // used in old-logic
        clientsfilteredText: searchText,  // used in old-logic
        clientsClearAllFilter: false,  // used in old-logic
      });
    }
    this.props.dropdownToggle();
  };

  render() {
    const { title, selector, handleSearchReset, filterName } = this.props;
    return (
      <Menu className="ant-table-filter-dropdown">
        <div style={{ padding: 8, marginTop: 10 }}>
          <Input
            placeholder={filterName}
            ref={this[selector]}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            value={this.state.searchText}
            onChange={this.handleSearchText}
            onPressEnter={this.handleSearchSubmit}
          />
          <Space>
            <Button
              type="primary"
              size="small"
              style={{ width: 90 }}
              onClick={this.handleSearchSubmit}
            >
              Filter
            </Button>
            <Button
              size="small"
              style={{ width: 90 }}
              onClick={this.handleReset}
            >
              Reset
            </Button>
          </Space>
        </div>
      </Menu>
    );
  }
}

const mapStateToProps = ({ clientportfolioV2: { clientList, initClientList, clientsfilteredText, clientsfilteredColumn, clientsClearAllFilter } }) => ({
  clientList,
  initClientList,
  clientsfilteredText,
  clientsfilteredColumn,
  clientsClearAllFilter
})

const mapDispatchToProps = {
  filterClients,
  filterDownloadedListRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropdownContent);

const config = {
  403: {
    img: 'https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg',
    code: 'Oops!',
    title: 'Error 403 — Permission Denied',
    desc: 'Sorry, you do not have permission to access this page',
  },
  404: {
    img: 'https://gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg',
    code: 'Oops!',
    title: 'Error 404 — Page Not Found',
    desc: 'Sorry, the page you visited does not exist',
  },
  500: {
    img: 'https://gw.alipayobjects.com/zos/rmsportal/RVRUAYdCGeYNBWoKiIwB.svg',
    code: 'Oops!',
    title: 'Error 500',
    desc: 'This page is down. Our team is working on fixing it.',
  },
  network: {
    img: 'https://gw.alipayobjects.com/zos/rmsportal/RVRUAYdCGeYNBWoKiIwB.svg',
    code: 'Uh Oh.',
    title: 'No Internet Connection',
    desc: 'You appear to be offline, please reconnect and try again.',
  },
  uc: {
    img: 'https://gw.alipayobjects.com/zos/rmsportal/RVRUAYdCGeYNBWoKiIwB.svg',
    code: 'Under Construction',
    title: 'Work In Progress',
    desc: 'We are currently working on the Customer Dashboard and making sure it ticks all the boxes for you.',
  },
};

export default config;

import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Row, Col, Card, Typography, Tabs, Checkbox } from 'antd';
import { Tooltip } from 'reactstrap';
import { FormatCurrency } from 'components/reports/shared/Utils';
import { FeeReturnRisk } from './FeeReturnRisk';
import { ViewChart } from './ViewChart';
import {
	setEnhancerState
} from 'actions/enhancer';
import { searchQueryRequest, setFundsState } from 'actions/discovery';
import { clearFundsApiLoadPercent } from 'actions/global';

import { sponsorLogo, QUERY_MAP } from 'DataSet';
import { searchUrlV3 } from 'Utils';

const { Paragraph } = Typography;
const { TabPane } = Tabs;

export class ItemCard extends Component {
  constructor(props){
    super(props)
    this.state = {
      tooltipOpen: false,
      // selected: false
      //this.props.sponsorsTicker----need to bind redirectedLink
      _showOdometerChart: true,
      _showLearnMoreSection : false,
      _learnMoreSectionObject: [{
        text:'Fund Information',
        img: '/assets/images/FundInfo.svg',
        redirectedLink: this.props.sponsorsTicker && this.props.sponsorsTicker.length ? this.props.sponsorsTicker[0].fund_information:'',
      },{
        text:'Visit Website',
        img: '/assets/images/metroLink.svg',
        redirectedLink: this.props.sponsorsTicker && this.props.sponsorsTicker.length ? this.props.sponsorsTicker[0].fact_sheet :'',
      },{
        text:'More funds from ',
        img: '/assets/images/moreFundsSponsor.svg',
        redirectedLink: this.props.sponsorsTicker && this.props.sponsorsTicker.length ? this.props.sponsorsTicker[0].channel_name:'',
      },{
        text:'Details',
        img: '/assets/images/details.svg',
        redirectedLink: this.props.sponsorsTicker && this.props.sponsorsTicker.length ? this.props.sponsorsTicker[0].ticker:'',
      },
    ]
    }
  }

  _learnMore = () => {
    this.setState({
      _showOdometerChart:false,
      _showLearnMoreSection:true
    })
  }

  _closeLearnMore = () =>{
    this.setState({
      _showOdometerChart:true,
      _showLearnMoreSection:false
    })
  }

  _execute = (data) =>{
		const {
			searchTypeMode,
			fundUniverse,
			// modelUniverse,
			similarityMeasures,
			query,
			// query_english,
      activeSelectorUniverse,
      callbackPath,
			selectedWeightOptions
		} = data;
    const {selectedPortfolioType,portfolioName,tickers,sponsoredPortfolioName,history}=this.props;
		let jObj = {}, urlParam = [];
		if (activeSelectorUniverse && activeSelectorUniverse.length > 0 && selectedWeightOptions && selectedWeightOptions.value) {
			jObj.weight = selectedWeightOptions.value;
		} else {
			delete jObj.weight;
		}
		// console.log("callbackPath", callbackPath);
		if (callbackPath && callbackPath !== '') {
			callbackPath = callbackPath === 'go' ? 'client' : 'builder';
		}
		if (history) {
			switch (searchTypeMode) {
				case 'FUNDS':
				jObj = {
					...jObj,
					"universe": fundUniverse,
					"similarity-measures": similarityMeasures,
					"q": query,
					"portfolioType": selectedPortfolioType,
					"portfolioId": portfolioName,
					"tickers": tickers,
				};
				if (selectedPortfolioType === 'MarketPlace' && sponsoredPortfolioName && typeof sponsoredPortfolioName !== 'undefined' && sponsoredPortfolioName !== '' && sponsoredPortfolioName !== null) {
					jObj.sponsoredPortfolioName = sponsoredPortfolioName;
				}
				// console.log("callbackPath", callbackPath);
				if (callbackPath && callbackPath !== '') {
					jObj.callbackPath = callbackPath;
				}
				for (var i in jObj){
					urlParam.push(encodeURIComponent(i) + "=" + encodeURIComponent(jObj[i]));
				}
				history.push(`/enhancer/dashboard/funds?${urlParam.join("&")}`);
				break;

			}
		}
  }

  _getQuery = (query) =>{
		let mappedQuery = QUERY_MAP[query] || query;
    return mappedQuery;
  }

  _enhanceSearchQuery = (q)=>{
    // console.log(q);
		if (q) {
			const { searchTypeMode, fundUniverse, modelUniverse, similarityMeasures, tickers } = this.props;
			const { setEnhancerState, selectedWeightOptions, callbackPath } = this.props;
			if (setEnhancerState) {
				let _payloadRequest = {
					searchTypeMode,
					fundUniverse,
					modelUniverse,
					similarityMeasures,
					query: q,
					query_english: this._getQuery(q) || q,
				}
				// console.log("callbackPath", callbackPath);
				if (callbackPath && callbackPath !== '') {
					_payloadRequest.callbackPath = callbackPath === 'go' ? 'client' : 'builder';
				}
        //_payloadRequest.callbackPath='client';
				// console.log(_payloadRequest);
				// console.log(selectedWeightOptions);
				setEnhancerState({
					..._payloadRequest,
				});
				this._execute({
					..._payloadRequest,
					selectedWeightOptions,
				});
			}
		}
  }

  _checkRoutes = (query) => {
    // const { history } = this.props;
    // const _query = window.sessionStorage.getItem('query');
    // if(_query){
    //   const securitiesPath = '/securities' + (_query ? `?query=${encodeURIComponent(_query)}` : '');
    //   if (history) {
    //     history.push(securitiesPath)
		//   }
    // }
		const { history } = this.props;
		if (history) {
			history.push(searchUrlV3(query));
		}
  }

  _discoverSearchQuery = (query) =>{
    if(query){
      window.sessionStorage.setItem('query', query);
      const {setFundsState,clearFundsApiLoadPercent,searchQueryRequest}=this.props
      const search_type = 'search_bar';
      clearFundsApiLoadPercent();
      setFundsState({
        actions: true,
        currentPage: 1,
      });
      this._checkRoutes(query)
      searchQueryRequest({
        query,
        search_type,
      });
    }
  }

  _learnMoreSectionDetail = (_redirectedLink,isQueryHandler,isDiscoverQuerySearch) =>{
    if(_redirectedLink && !isQueryHandler){
    //addEventToAnalytics('Learn More Fund Information','Learn More Fund Information','LEARN_MORE_FUND_INFORMATION',{ ticker: card.ticker },true);
    window.open(_redirectedLink.includes('pdf') && !_redirectedLink.includes('https://') ? '/assets/data'+ _redirectedLink : _redirectedLink, '_blank').focus();
    }
    if(isQueryHandler && !isDiscoverQuerySearch){
      this._enhanceSearchQuery(`Funds By ${_redirectedLink}`)
    }
    if(isDiscoverQuerySearch){
      this._discoverSearchQuery(`Funds By ${_redirectedLink}`)
    }
  }

  tabOnChange = (selectedKey, data, ticker, query, index) => {
    const { tabOnChange } = this.props;
    if (tabOnChange) {
      tabOnChange(selectedKey, data, ticker, query, index);
    }
  }

  getItemCardDesign = () => {
    const {
      data,
      query,
      ticker,
      enhanceReplace,
      i,
      staticCard,
      // resetCheck,
      // highlights,
      className,
      // selected,
      // portfolioQuery,
      activeViewTab,
      // relevanceChartTabs,
      // defaultFRR,
      // originalQueryVar,
      checked,
      // invalidTickerFunds,
      // summaryPortfolioFunds,
    } = this.props;

    const {_showOdometerChart,_showLearnMoreSection,_learnMoreSectionObject}=this.state;

    if (data && data.ticker) {
      const id = data.index ? 'cb-'+ticker+data.index+data.id : 'cb-'+ticker+data.vehicle+data.ticker;
      const {
        // yRet,
        // volt,
        // feesQ,
        // yRetQ,
        // voltQ,
        // queryIcon,
        FRR,
        // reportData,
        odometerChart,
        // _selected,
      } = data;
      const getTabpaneDesign = (chartDataObj) => {
        // console.log(chartDataObj);
        if (chartDataObj) {
          const { smallScreen, bigScreen, key } = this.props;
          const GuageChartWidth = smallScreen ? 90 : (bigScreen ? 130 : 90);
          return (
            <ViewChart
              size="md"
              i={id}
              data={chartDataObj}
              width={GuageChartWidth}
            />
          )
        }
      }
      const getTabHeadText = (chartDataObj) => {
        try {
          // console.log(chartDataObj);
          if (chartDataObj && chartDataObj.name) {
            if (chartDataObj.name.includes('Overlap')) {
              return `Overlap with ${ticker}`;
            } else if (chartDataObj.name.includes('Correlation')) {
              return `Correlation with ${ticker}`;
            } else if (chartDataObj.name.includes('Similarity')) {
              return `Similarity with ${ticker}`;
            } else {
              return chartDataObj.name;
            }
          }
        } catch (e) {
          console.log(e);
        }
      }

      return (
        <Card
          key={enhanceReplace+i}
          className={`${!staticCard ? 'card-background-grey' : 'sddf'} ${className}`}
          style={{ minWidth: '250px', width: '250px', margin: '0 auto' }}
        >
          <div className="p-1 border-bottom">
            <Row style={{minHeight: '90px'}}>
              {!staticCard
                && (
                  <Checkbox
                    inline
                    className="p-top-right custom-ant-checkbox"
                    type="checkbox"
                    title="Select"
										id={id}
                    checked={checked}
                    onClick={(e) => this.props.setReplaceTicker(e, data, data.ticker, ticker, query)}
                  />
                )
              }
              <TickerPrice
                className="d-flex flex-column justify-content-center align-items-center"
                ticker={data.ticker_short || data.ticker}
                price={data.nav}
                is_sponsored={data.is_sponsored}
              />
              <NameSponsor
                className="d-flex flex-column justify-content-center align-items-left"
                name={data.short_name}
                sponsor={data.sponsor}
                category={data.category}
              />
              {(typeof data.class_type !== 'undefined' && data.class_type &&  ["institutional", "retail"].includes(data.class_type))
              && (
              <div>
                <img id={`fundclass-${data.ticker}`} className="card-Ad-Icon" src={`/assets/images/${data.class_type}.png`} width='10' />
                <Tooltip className="col-grey" placement="right" isOpen={this.state.tooltipOpen} target={`fundclass-${data.ticker}`} toggle={() => this.setState({ tooltipOpen: !this.state.tooltipOpen })}>
                  {data.class_type === 'retail' ? 'Retail Class' : 'Institutional Class'}
                </Tooltip>
              </div>)
              }
            </Row>
          </div>
          <div className="border-bottom">
            <FeeReturnRisk FRR={FRR} />
          </div>
          { _showOdometerChart ?
            <div className="relevance-charts-tab-wrapper">
              {odometerChart && odometerChart.length > 0 && (
                <Tabs activeKey={activeViewTab} size="small" style={{ marginBottom: 5 }} onChange={(key) => this.tabOnChange(key, data, ticker, query, this.props.i)}>
                  {odometerChart.map((op, opI) => {
                    return (
                      <TabPane tab={getTabHeadText(op)} key={opI+1}>
                        {getTabpaneDesign(op)}
                      </TabPane>
                    )
                  })}
                </Tabs>
              )}
          </div>:''
          }
          { _showLearnMoreSection ?
            <div className='box-layout'>
            <div className="relevance-charts-tab-wrapper">
                <div className='d-flex' style={{paddingTop:'28px',paddingLeft:'12px'}}>
                { _learnMoreSectionObject[0].redirectedLink ?
                <div className='learn-more-action-layout d-flex align-items-center'  onClick={()=>this._learnMoreSectionDetail(_learnMoreSectionObject[0].redirectedLink,false,false)}>
                  <img src={_learnMoreSectionObject[0].img} width={20} />
                  <span style={{ maxWidth: 130 }}>{_learnMoreSectionObject[0].text}</span>
                </div> : ''
                }
                {_learnMoreSectionObject[1].redirectedLink && (_learnMoreSectionObject[0].redirectedLink !==_learnMoreSectionObject[1].redirectedLink) ? <div className='learn-more-action-layout d-flex  align-items-center' style={{marginLeft:'10px'}}  onClick={()=>this._learnMoreSectionDetail(_learnMoreSectionObject[1].redirectedLink,false,false)}>
                  <img src={_learnMoreSectionObject[1].img} width={20} />
                  <span style={{ maxWidth: 130 }}>{_learnMoreSectionObject[1].text}</span>
                </div> : ''
                }
                </div>
                <div className='d-flex' style={{paddingTop:'12px',paddingLeft:'12px'}}>
                { _learnMoreSectionObject[2].redirectedLink ?
                <div className='learn-more-action-layout d-flex align-items-center'  onClick={()=>this._learnMoreSectionDetail(_learnMoreSectionObject[2].redirectedLink,true,false)}>
                  <img src={_learnMoreSectionObject[2].img} width={20} />
                  <span style={{ maxWidth: 130 }}>{_learnMoreSectionObject[2].text}{_learnMoreSectionObject[2].redirectedLink}</span>
                </div> : ''
                }
                { _learnMoreSectionObject[3].redirectedLink ?
                <div className='learn-more-action-layout d-flex align-items-center' style={{marginLeft:'10px'}}  onClick={()=>this._learnMoreSectionDetail(_learnMoreSectionObject[3].redirectedLink,true,true)}>
                  <img src={_learnMoreSectionObject[3].img} width={20} />
                  <span style={{ maxWidth: 130 }}>{_learnMoreSectionObject[3].text}</span>
                </div> : ''
                }
                </div>
             </div>
            <div className='border-top-padding' style={{borderTop:'unset'}}><div className='cus-link-pointer' onClick={()=>this._closeLearnMore()}>Close<span><img src="/assets/images/minus.svg" /></span></div></div></div>
            :''
          }
          { data.is_sponsored && !_showLearnMoreSection ? <div className='border-top-padding'><div className='cus-link-pointer' onClick={()=>this._learnMore()}>Learn More<span><img src="/assets/images/LearnMore.svg" /></span></div></div>:'' }
          {/* {_showLearnMoreSection ?<div className='border-top-padding' style={{borderTop:'unset'}}><div className='cus-link-pointer' onClick={()=>this._closeLearnMore()}>Close<span><img src="/assets/images/minus.svg" /></span></div></div>:'' }        */}
        </Card>
      );
      // return (<div>{ticker}</div>)
    }
  }

  render() {
    const renderDesign = this.getItemCardDesign();
    return (
      <React.Fragment>
        {renderDesign}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ global , enhancer}) => ({
  // enhanceReplace: state.portfolio.enhanceReplace,
  smallScreen: global.smallScreen,
  bigScreen: global.bigScreen,
  enhancer: enhancer,
  similarityMeasures: enhancer.similarityMeasures,
  searchTypeMode: enhancer.searchTypeMode,
  fundUniverse: enhancer.fundUniverse,
  modelUniverse: enhancer.modelUniverse,
  callbackPath: (enhancer.redirectFrom && enhancer.redirectFrom.module) ? enhancer.redirectFrom.module : '',
  selectedWeightOptions: enhancer.selectedWeightOptions,
  activeSelectorUniverse: enhancer.activeSelectorUniverse,
  selectedPortfolioType: enhancer.portfolioType,
  portfolioName: enhancer.portfolioName,
  tickers: enhancer.tickers,
  sponsoredPortfolioName: enhancer.sponsoredPortfolioName,
})

const mapDispatchToProps = {
  setEnhancerState,
  searchQueryRequest,
  setFundsState,
  clearFundsApiLoadPercent
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemCard)


//className="d-flex flex-column justify-content-center align-items-center" style={{minWidth: '4rem'}}
const TickerPrice = ({ ticker, price, className , is_sponsored }) => (
  <Col className={className} style={{marginTop:'1.25rem'}} span={8}>
    <h5 className="mb-0 ticker-size ticker-price">{ticker}</h5>
    <h4 className="mt-1 mb-0 ticker-price">{FormatCurrency(price, 2)}</h4>
    { is_sponsored ? <div className='sponsored-layout'><span className='sponsored-text'>Sponsored</span></div> :''}
  </Col>
)

const NameSponsor = ({ name, sponsor, category, className }) => (
  <Col className={className} span={16}>
    <div className="pb-1">
      <ImageOrText img={sponsorLogo(sponsor)} text={sponsor} />
    </div>
    <h5 className="m-0" title={name} style={{ fontWeight: 600, lineHeight: 1.2 }}>
      <Paragraph
        className="text-muted item-card-text"
        ellipsis={{rows: 2}}
        title={name}
      >
        {name}
      </Paragraph>
    </h5>
    <h5 className="mb-0 text-gray-6" title={category} style={{ lineHeight: 1.4, color: '#57869c', marginTop: 3 }} >
      <Paragraph
        className="m-0 category-text"
        ellipsis={{rows: 1}}
        title={category}
        >
        {category}
      </Paragraph>
    </h5>
  </Col>
)

const ImageOrText = ({ img, text = '' }) => {
  const [valid, setValid] = useState(true);

  return (
    valid
    ? <img src={img} className="img-fluid" alt="" onError={() => setValid(false)} style={{height: '20px'}} />
    : <div className="sponsor-logo-placeholder" title={text}>{text}</div>
  );
}

const getId = (function* () {
  let n = 1;
  while (true) yield n++;
})();

import React, { useContext } from 'react';
import { Row, Col, Table } from 'reactstrap';
import { withHeaderFooter } from '../shared/HeaderFooter';
import { StackedAreaChart } from '../shared/Charts';
import { getSummaryContent } from '../shared/Utils';
import { convertToLowerCase } from 'Utils';
import { numberCheck } from 'layouts/utils';
import PortfolioContext from '../PortfolioContext';

const ExecutiveSummary = withHeaderFooter((props) => {
  const { createdDate } = props;
  const reportData = useContext(PortfolioContext)
  // console.log(reportData);
  let { investment, portfolio, portfolio: { enhancedPerformanceImpact: epi, currentPerformanceImpact: cpi } } = reportData;

  let query = portfolio.query;
  let query_english = portfolio.query_english || convertToLowerCase(portfolio.query, true);
  let invPortfolioValue = investment.portfolioValue;
  let fundCount = portfolio.enhanceReplace.length;
  let currentFee = numberCheck(cpi.fee);
  let enhancedFee = numberCheck(epi.fee);

  // let currentReturnsFor10Yrs = numberCheck(invPortfolioValue * (1 + numberCheck(cpi.anRet10yr))) ;
  // let currentRiskFor10Yrs = numberCheck(cpi.anVol10yr);
  // console.log('---- currentReturnsFor10Yrs ---->', currentReturnsFor10Yrs, ' ----- currentRiskFor10Yrs ------>', currentRiskFor10Yrs);
  //
  // let enhancedReturnsFor10Yrs = numberCheck(invPortfolioValue * (1 + numberCheck(epi.anRet10yr))) ;;
  // let enhancedRiskFor10Yrs = numberCheck(epi.anVol10yr);
  // console.log('---- enhancedReturnsFor10Yrs ---->', enhancedReturnsFor10Yrs, ' ----- enhancedRiskFor10Yrs ------>', enhancedRiskFor10Yrs);

  let _CPIanRet10yr = numberCheck(cpi.anRet10yr_report, 'all') ? numberCheck(cpi.anRet10yr_report, 'all') : (numberCheck(cpi.anRet5yr_report, 'all') ? numberCheck(cpi.anRet5yr_report, 'all') : numberCheck(cpi.anRet3yr_report, 'all'));
  // NOTE:  '_report' is missing then fallBack to default
  _CPIanRet10yr = ((!_CPIanRet10yr || typeof _CPIanRet10yr === 'undefined' || isNaN(_CPIanRet10yr)) ? (numberCheck(cpi.anRet10yr, 'all') ? numberCheck(cpi.anRet10yr, 'all') : (numberCheck(cpi.anRet5yr, 'all') ? numberCheck(cpi.anRet5yr, 'all') : numberCheck(cpi.anRet3yr, 'all'))) : _CPIanRet10yr);

  let avgReturnOld = numberCheck(_CPIanRet10yr, 'all') / 100;
  // console.log('avgReturnOld===> ', avgReturnOld);

  let _EPIanRet10yr = numberCheck(epi.anRet10yr_report, 'all') ? numberCheck(epi.anRet10yr_report, 'all') : (numberCheck(epi.anRet5yr_report, 'all') ? numberCheck(epi.anRet5yr_report, 'all') : numberCheck(epi.anRet3yr_report, 'all'));
  // NOTE:  '_report' is missing then fallBack to default
  _EPIanRet10yr = ((!_EPIanRet10yr || typeof _EPIanRet10yr === 'undefined' || isNaN(_EPIanRet10yr)) ? (numberCheck(epi.anRet10yr, 'all') ? numberCheck(epi.anRet10yr, 'all') : (numberCheck(epi.anRet5yr, 'all') ? numberCheck(epi.anRet5yr, 'all') : numberCheck(epi.anRet3yr, 'all'))) : _EPIanRet10yr);

  let avgReturnNew = numberCheck(_EPIanRet10yr, 'all') / 100;
  // console.log('avgReturnNew===> ', avgReturnNew);
  // Chart Computation
  let chartData = [];
  let noOfYrs = 10;

  let minValue = Number.POSITIVE_INFINITY;
  let maxValue = Number.NEGATIVE_INFINITY;

  let crMaxValue = Number.NEGATIVE_INFINITY;
  let enMaxValue = Number.NEGATIVE_INFINITY;
  // console.log(epi);
  // console.log(cpi);

  for (var i = 0; i <= noOfYrs; i++) {
    let name = i == 0 ? 'Today' : 'Year ' + i;
    let cr = i == 0 ? Math.round(invPortfolioValue) : Math.round(chartData[i - 1].cr * (1 + avgReturnOld));
    if (cr < minValue) minValue = cr;
    if (cr > crMaxValue) crMaxValue = cr;
    if (cr > maxValue) maxValue = cr;
    let en = i == 0 ? Math.round(invPortfolioValue) : Math.round(chartData[i - 1].en * (1 + avgReturnNew));
    if (en < minValue) minValue = en;
    if (en > enMaxValue) enMaxValue = en;
    if (en > maxValue) maxValue = en;
    chartData.push({ name, cr, en })
  }

  let minMaxValues = {
    minValue: isFinite(minValue) ? minValue : 'auto',
    maxValue: isFinite(maxValue) ? maxValue : 'auto'
  }

  let chartOptions = [
    {
      dataKey: 'en',
      fillColor: '#1d6091',
      fillOpacity: 1,
      stroke: 'none'
    }, {
      dataKey: 'cr',
      fillColor: '#56a9e8',
      fillOpacity: 1,
      stroke: 'none'
    },
  ];

  // console.log("chartData -> ", chartData);

  if (crMaxValue > enMaxValue) { chartOptions = chartOptions.reverse() }

  let totalSavingsCostNYrs = chartData[noOfYrs].en - chartData[0].cr;
  let totalSavingsPercent = numberCheck(((totalSavingsCostNYrs / invPortfolioValue) * 100), 1); // ((totalSavingsCostNYrs / invPortfolioValue) * 100).toFixed(1);
  // let totalSavingsCost = (chartData[noOfYrs].en - chartData[0].cr) / noOfYrs;

  //return
  let crStd = numberCheck(cpi.anRet3yr, 2) + '%'; // parseFloat(cpi.anRet3yr).toFixed(2)+'%';
  let enStd = numberCheck(epi.anRet3yr, 2) + '%'; // parseFloat(epi.anRet3yr).toFixed(2)+'%';

  //volatility
  let crVolPrc = numberCheck(cpi.anVol3yr, 2); // parseFloat(cpi.anVol3yr).toFixed(2);
  let enVolPrc = numberCheck(epi.anVol3yr, 2); // parseFloat(epi.anVol3yr).toFixed(2);

  //yield
  let crYieldRange = numberCheck(cpi.yield, 2); // parseFloat(cpi.yield).toFixed(2);
  let enYieldRange = numberCheck(epi.yield, 2); // parseFloat(epi.yield).toFixed(2);

  let crTrackRange = numberCheck(cpi.trackRecord, 2); // Math.round(cpi.trackRecord);
  let enTrackRange = numberCheck(epi.trackRecord, 2); // Math.round(epi.trackRecord);

  let genExpRet = numberCheck((((chartData[noOfYrs].en - chartData[noOfYrs].cr) / invPortfolioValue) * 100), 2); // parseFloat(((chartData[noOfYrs].en - chartData[noOfYrs].cr)/invPortfolioValue) * 100).toFixed(2);

  //Filtering the value based on weight > 0 and then adding string together with , and 'and' in right places
  let fundSelector = Object.values(portfolio.fundSelector).filter((key) => key.weight > 0).reduce((t, k, i, v) => (i == v.length - 1) ? t + k.name : (i == v.length - 2) ? t + k.name + ' and ' : t + k.name + ', ', '');

  let totalSavingsCostPerYr = numberCheck(currentFee - enhancedFee);
  let totalSavingsCostOver10Yrs = numberCheck(totalSavingsCostPerYr) * 10;
  // console.log('---- totalSavingsCostPerYr ---->', totalSavingsCostPerYr, ' ----- totalSavingsCostOver10Yrs ------>', totalSavingsCostOver10Yrs);

  return (
    <React.Fragment>
      <div className="content">
        <div className="pr-v2-header">
          <h3>Executive Summary</h3>
          {createdDate && (<p>Data as of {createdDate}</p>)}
        </div>
        <div className="pr-v2-ex-summary">
          {getSummaryContent(query_english, fundCount, totalSavingsCostPerYr, totalSavingsCostOver10Yrs, totalSavingsPercent, noOfYrs, crStd, enStd, crVolPrc, enVolPrc, crYieldRange, enYieldRange, crTrackRange, enTrackRange, fundSelector, reportData.portfolio, genExpRet)}
          <Row>
            <Col xs="12">
              <div style={{ width: '970px', height: '360px', marginTop: '20px' }}>
                <StackedAreaChart chartData={chartData} chartOptions={chartOptions} minMaxValues={minMaxValues} />
              </div>
              <div className="pr-v2-chart-legends" style={{ marginTop: '30px' }}>
                <p>Current Portfolio Value</p>
                <p>Enhanced Portfolio Value</p>
              </div>
            </Col>
          </Row>
          <div style={{ marginTop: 50 }}>
            <p className="pr-v2-small-paragraph">
              This information was generated by an investment analysis tool that produces simulations and statistical analyses that present the likelihood of various investment outcomes if certain investments are made or certain investment strategies or styles are
              undertaken. Past performance does not guarantee future results. TIFIN Wealth Tech LLC does not guarantee any minimum level of investment performance or the success of any portfolio or investment strategy. Results may vary with each use and over time. There is a
              potential for loss in any investment, including the loss of capital. All investments involve risk, and different types of investments
              involve varying degrees of risk. Investment recommendations will not always be profitable. No representation is being made that
              any client account will or is likely to achieve profit or losses similar to those shown in the chart above. Hypothetical performance information shown in text, charts, tables and graphs is provided for informational purposes only and should not be considered investment advice or a recommendation to buy or sell any particular security.
            </p>
            <p className="pr-v2-small-paragraph">
              There are certain limitations inherent in hypothetical results like those portrayed, particularly that such hypothetical results do not reflect trading in actual client accounts and do not reflect the impact that material economic and market factors may have had on the investments chosen had the adviser actually been managing client accounts. Unlike an actual performance record, hypothetical performance results do not represent actual trading. These types of simulations, in general, benefit compared to actual performance results because such simulations are designed with the benefit of hindsight. In addition, simulated trading does not involve or take into account financial risk and does not take into account that material and market factors may have on investments
              chosen, all of which can adversely affect actual trading results and performance.
            </p>
          </div>
          {/*<Row>
            <Col xs="12">
              <Table striped className="pr-v2-ex-sum-table">
                <tbody>
                  <tr>
                    <td scope="row"></td>
                    <td>Current</td>
                    <td>Enhanced</td>
                  </tr>
                  <tr>
                    <td scope="row">Expense Ratio</td>
                    <td>0.74%</td>
                    <td>0.21%</td>
                  </tr>
                  <tr>
                    <td scope="row">Projected Annual Expense Ration Cost</td>
                    <td>$ 36,735</td>
                    <td>$ 10,425</td>
                  </tr>
                  <tr>
                    <td scope="row">Projected Annual Fee</td>
                    <td>$ 36,735</td>
                    <td>$ 15,887</td>
                  </tr>
                  <tr>
                    <td scope="row">Projected 10 Year Benefit due to Fee Savings</td>
                    <td>-</td>
                    <td>$ 47,862,920</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>*/}
        </div>
      </div>
    </React.Fragment>
  )
});

export default ExecutiveSummary;

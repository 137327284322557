import React, { Component, Fragment } from 'react'
import { Cell, PieChart, Pie } from 'recharts'

import NotApplicable from 'components/NotApplicable'
import { checkValidNumber } from 'layouts/utils'

export const GaugeColors = ['#7B73E4', '#A0A3F2', '#D6D7FA']

const getTabHeadText = (chartDataObj, ticker) => {
  try {
    if (chartDataObj && chartDataObj.name) {
      if (chartDataObj.name.includes('Overlap')) {
        return `Overlap with ${ticker}`
      } else if (chartDataObj.name.includes('Correlation')) {
        return `Correlation with ${ticker}`
      } else {
        return chartDataObj.name
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export const ViewChart = props => {
  const { data, ticker, width } = props;
  if (!data) {
    return (
      <div className="not-applicable-icon-wrapper">
        <NotApplicable />
      </div>
    )
  }
  const { decimal, value, suffix, min = 0, max = 100, multiplier = 1 } = props.data;
  if (typeof value === 'undefined' ||
    typeof min === 'undefined' ||
    typeof max === 'undefined'
  ) {
    return (
      <div className="not-applicable-icon-wrapper">
        <NotApplicable />
      </div>
    );
  };

  let name = getTabHeadText(data, ticker);
  const deTailsWidth = width || '100px';

  return (
    <div className="siw-meter print-wrapper">
      <div className="siw-meter-title" title={name}>
        {name}
      </div>
      <GaugeChart
        chartWidth={width}
        value={checkValidNumber(
          parseFloat(value || 0) * (multiplier || 1),
          decimal || 2,
          null,
          0,
        )}
        max={checkValidNumber(
          parseFloat(max || 100) * (multiplier || 1),
          decimal || 2,
          null,
          100,
        )}
        min={checkValidNumber(
          parseFloat(min || 0) * (multiplier || 1),
          decimal || 2,
          null,
          0,
        )}
      />
      <div
        className="d-flex meter-value"
        style={{ width: deTailsWidth, justifyContent: 'space-between' }}
      >
        <span className="siw-meter-min" style={{ minWidth: 25 }}>
          {checkValidNumber(
            parseFloat(min || 0) * (multiplier || 1),
            decimal || 2,
            suffix,
            0,
          )}
        </span>
        <span className="siw-meter-value">
          {checkValidNumber(
            parseFloat(value || 0) * (multiplier || 1),
            decimal || 2,
            suffix,
            0,
          )}
        </span>
        <span className="siw-meter-max">
          {checkValidNumber(
            parseFloat(max || 100) * (multiplier || 1),
            decimal || 2,
            suffix,
            0,
          )}
        </span>
      </div>
    </div>
  )
}

const GaugeChart = ({ value, min, max, pc, chartWidth }) => {
  const width = chartWidth || 100
  const sumValues = 300
  const colorData = GaugeColors.slice(0, 3).map(e => ({ value: 100, color: e }))
  const chartValue =
    sumValues * (pc !== undefined ? pc : (value - min) / (max - min))

  const arrowData = [
    { value: chartValue },
    { value: 0 },
    { value: sumValues - chartValue }
  ]

  const pieProps = {
    startAngle: 180,
    endAngle: 0,
    cx: width / 2,
    cy: width / 2
  }

  const pieRadius = {
    innerRadius: (width / 2) * 0.6,
    outerRadius: (width / 2) * 0.95
  }

  const Arrow = ({ cx, cy, midAngle, outerRadius }) => {
    const RADIAN = Math.PI / 180
    const sin = Math.sin(-RADIAN * midAngle),
      cos = Math.cos(-RADIAN * midAngle)
    const mx = cx + (outerRadius - width * 0.03) * cos,
      my = cy + (outerRadius - width * 0.03) * sin
    return (
      <g>
        <circle cx={cx} cy={cy} r={3} fill="#999" stroke="none" />
        <path
          d={`M${cx},${cy}L${mx},${my}`}
          strokeWidth="2"
          stroke="#999"
          fill="none"
          strokeLinecap="round"
        />
      </g>
    )
  }

  return (
    <PieChart
      width={width}
      height={width / 2 + 5}
      margin={{ top: 0, right: 0, left: 0, bottom: 5 }}
      aspect={1.5}
    >
      <Pie
        data={colorData}
        dataKey="value"
        fill="#8884d8"
        isAnimationActive={false}
        {...pieRadius}
        {...pieProps}
      >
        {colorData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colorData[index].color} />
        ))}
      </Pie>
      <Pie
        stroke="none"
        fill="none"
        activeIndex={1}
        activeShape={Arrow}
        data={arrowData}
        dataKey="value"
        outerRadius={pieRadius.innerRadius}
        {...pieProps}
      />
    </PieChart>
  )
}

export default {
  ViewChart
}

// import React from 'react';
import * as pathToRegexp from 'path-to-regexp';
import { getMenuData } from './routermenu';

import Enhancer from 'layouts/WebDashboard/Enhancer';
import OnBoardScreen from 'layouts/WebDashboard/Enhancer/Pages/OnBoardScreen/OnBoardScreen';
import Dashboard from 'layouts/WebDashboard/Enhancer/Pages/Dashboard';
import Summary from 'layouts/WebDashboard/Enhancer/Pages/Summary';
import OpenSummaryPage from 'layouts/WebDashboard/Enhancer/Pages/OpenSummaryPage'
import Funds from 'layouts/WebDashboard/Enhancer/Pages/Funds';
import Models from 'layouts/WebDashboard/Enhancer/Pages/Models';

import Exception403 from 'layouts/WebDashboard/Enhancer/Pages/Exception/403';
import Exception404 from 'layouts/WebDashboard/Enhancer/Pages/Exception/404';
import Exception500 from 'layouts/WebDashboard/Enhancer/Pages/Exception/500';

// {"nav": [
// {
//   "label": "HOME",
//   "route": {
//     "url": "/",
//     "exact": true
//   },
//   "component": {
//     "path": "home",
//     "name": "home"
//   }
// }}
// createRoutes() {
//     return this.props.data.nav.map((item: any, index: number) => {
//         let DynamicComponent = require('../../' + item.component.path + '/' + item.component.name).default;
//         return <Route key={index} path={item.route.url}
//                       exact={item.route.exact}
//                       render={() => (<DynamicComponent key={item.component.name}/>)}/>
//     });
// }

function getFlatMenuData(menus) {
  let keys = {};
  menus.forEach((item) => {
    if (item.children) {
      keys[item.path] = { ...item };
      keys = { ...keys, ...getFlatMenuData(item.children) };
    } else {
      keys[item.path] = { ...item };
    }
  });
  return keys;
}

export const getRouterData = () => {
  const self_role = (window.localStorage.getItem('profile_self_role')) ? window.localStorage.getItem('profile_self_role') : "loggedOut"

  const routerConfig = {
    '/enhancer': {
      component: Enhancer,
      authority: 'user',
      protected: true,
    },
    '/enhancer/onboard-screen': {
      component: OnBoardScreen,
      authority: 'user',
      protected: true,
    },
    '/enhancer/dashboard/': {
      component: Dashboard,
      authority: 'user',
      protected: false,
    },
    '/enhancer/summary': {
      component: OpenSummaryPage,
      authority: 'user',
      protected: false,
    },
    '/enhancer/dashboard/summary': {
      component: Summary,
      authority: 'user',
      protected: false,
    },
    '/enhancer/dashboard/funds': {
      component: Funds,
      authority: 'user',
      protected: false,
    },
    '/enhancer/dashboard/models': {
      component: Models,
      authority: 'user',
      protected: false,
    },
    '/enhancer/exception/403': {
      component: Exception403,
    },
    '/enhancer/exception/404': {
      component: Exception404,
    },
    '/enhancer/exception/500': {
      component: Exception500,
    },
  };

  if (["","Other/Curious", "Individual Investor"].includes(self_role) === true) {
    delete routerConfig['/enhancer/onboard-screen']
  }
  // Get name from ./menu.js or just set it in the router data.
  const menuData = getFlatMenuData(getMenuData());
  // Route configuration data
  // eg. {name,authority ...routerConfig }
  const routerData = {};
  // The route matches the menu
  Object.keys(routerConfig).forEach((path) => {
    // Regular match item name
    // eg.  router /user/:id === /user/chen
    const pathRegexp = pathToRegexp(path);
    const menuKey = Object.keys(menuData).find(key => pathRegexp.test(`${key}`));
    let menuItem = {};
    // If menuKey is not empty
    if (menuKey) {
      menuItem = menuData[menuKey];
    }
    let router = routerConfig[path];
    router = {
      ...router,
      name: router.name || menuItem.name,
      authority: router.authority || menuItem.authority,
      protected: router.protected || menuItem.protected,
    };
    routerData[path] = router;
  });
  // console.log(routerData);
  return routerData;
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addEventToAnalytics } from 'Utils';
import { searchAccFundsRequest } from 'actions/clientportfolioV2';

const classNames = require('classnames');

let PLACEHOLDER = '';
let placeholder_i = 0, speed = 20, placeholder_v = '';

function typeWriter(text) {
  if (document.getElementById("type-writer") && PLACEHOLDER && PLACEHOLDER.length > 0) {
    // if (placeholder_i < PLACEHOLDER.length) {
    //   placeholder_v += PLACEHOLDER.charAt(placeholder_i);
    //   document.getElementById("type-writer").setAttribute('placeholder', placeholder_v);
    //   placeholder_i++;
    //   setTimeout(typeWriter, speed);
    // }
    if (placeholder_i < PLACEHOLDER.length) {
      placeholder_v += PLACEHOLDER.charAt(placeholder_i);
      document.getElementById("type-writer").innerHTML = placeholder_v;
      placeholder_i++;
      setTimeout(typeWriter, speed);
    }
  }
}

class PfSearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.accSearchQuery || '',
      active: false,
      submitStatus: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const elem = document.getElementById("fund-search-box");
    if (this.props.placeholder && this.props.placeholder.length > 0 && elem && this.state.value === "") {
      PLACEHOLDER = this.props.placeholder;
      typeWriter();
    }
  }

  componentWillUnmount() {
    document.getElementById("fund-search-box").setAttribute('placeholder', '');
    placeholder_i = 0;
    placeholder_v = '';
  }

  componentWillReceiveProps(newProps) {
    const elem = document.getElementById("fund-search-box");
    if (newProps.placeholder && newProps.placeholder.length > 0 && elem && this.state.value === "") {
      PLACEHOLDER = newProps.placeholder;
      typeWriter();
    }
    if(newProps.accSearchQuery != this.props.accSearchQuery){
      this.setState({
        value: newProps.accSearchQuery || ''
      }, () => {
        console.log('this.state.value =====>>', this.state.value, this.state.value.length);
        if (this.state.value.length === 0) {
          this.searchFieldFocusOut();
        } else {
          this.searchFieldFocusIn();
        }
      });
    }
  }

  searchFieldFocusIn = () => {
    if (document.getElementById('type-writer')) {
      document.getElementById('type-writer').style.visibility = "hidden";
      document.getElementById('fund-search-box').focus();
    }
  }

  searchFieldFocusOut = () => {
    if (document.getElementById('type-writer')) {
      if (this.state.value === "") {
        document.getElementById('type-writer').style.visibility = "visible";
      }
    }
  }

  handleChange(event) {
    this.setState({
      value: event.target.value,
      active: this.validQuery(event.target.value)
    }, () => (
      this.state.submitStatus && !this.state.value && this.clearSearch()
    ));
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      submitStatus: true
    }, () => this.searchRequest());
  }

  clearSearch = () => {
    this.setState({
      value: '',
      submitStatus: false
    }, this.searchRequest);
    document.getElementById("fund-search-box").focus();
  }

  searchRequest = () => {
    // if(this.props.accSearchQuery != this.state.value){
    //   addEventToAnalytics('Client Search', 'Client Search', 'CLIENT_SEARCH', { query: this.state.value }, false)
      this.props.onChange(this.state.value);
    // }
  }

  validQuery = (query) => query.trim().length > 0

  render() {
    const { placeholder, disabled } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className={classNames("input-group h2 mb-0 search-box pos-rel")}>
          <input
            id="fund-search-box"
            value={this.state.value}
            onChange={this.handleChange}
            className="form-control form-control-lg rounded bg-white"
            type="text"
            placeholder=""
            autoComplete="off"
            aria-label="Search"
            onClick={this.searchFieldFocusIn}
            onFocus={this.searchFieldFocusIn}
            onBlur={this.searchFieldFocusOut}
            style={{ paddingRight: (this.state.value && this.state.value.length > 0) ? '60px' : '50px' }}
            disabled={disabled || false}
          />
          <p id="type-writer" className="type-writer" onClick={this.searchFieldFocusIn}></p>
          {(this.state.value && this.state.value.length > 0) && (
            <i className={`far fa-times fa-lg text-secondary clear-icon ${this.state.clearSearch ? '' : ''} ${this.state.active ? 'active' : ''}`}
              onClick={this.clearSearch} />
          )}
          <i className={classNames("far fa-fw fa-search fa-lg text-secondary search-icon", {'active': this.state.active})}
            onClick={this.handleSubmit} />
        </div>
      </form>
    );
  }
}

const mapStateToProps = ({ clientportfolioV2: { accSearchQuery } }) => ({
  accSearchQuery
})

const mapDispatchToProps = {
  searchAccFundsRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PfSearchBox);

import { SectorColors } from 'data/Colors';
import ActReportContext from '../ActReportContext';
import { useContext } from 'react'
const numFmt = (num, prec = 2) => (num || 0).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: prec })

export const FormatCurrency = (value, prec = 0, curr = '$') => {
  let _prefix = (value < 0 ? '-' : '');
  let [_value, _suffix, _prec] = [Math.abs(value), '', prec];

  if (_value >= 1e9)
    [_value, _suffix, _prec] = [_value / 1e9, 'B', 2];
  else if (_value >= 1e6)
    [_value, _suffix, _prec] = [_value / 1e6, 'M', 2];
  else if (_value >= 1e3)
    [_value, _suffix, _prec] = [_value / 1e3, 'K', 2];

  if (_value >= 100 && _prec >= 0) _prec = 0;
  else if (_value >= 10 && _prec >= 0) _prec = 1;

  return `${_prefix}${curr}${numFmt(_value, _prec)}${_suffix}`
}

export const joinNames = names => (
  names.length < 2
    ? names.join('')
    : [names.slice(0, -1).join(', '), names.slice(-1)[0]].join(' and ')
)

export const getColor = (c, i) => c[i % c.length]

export const AttrColors = ['#3f7367', '#a5685f', '#555'];

export const ScoreAttrName = code => ScoreAttrs[code].name

export const ScoreAttrs = {
  risk: { name: 'Risk', icon: 'heart-rate' },
  return: { name: 'Return', icon: 'chart-line' },
  fee: { name: 'Fee', icon: 'tag' },
}

export const RfScoreAttrs = {
  'risk': { name: 'Risk', icon: 'heart-rate', suffix: '%', multiplier: 1, parser: '' },
  'return': { name: 'Return', icon: 'chart-line', suffix: '%', multiplier: 1, parser: '' },
  'fee': { name: 'Fee', icon: 'tag', suffix: '%', multiplier: 1, parser: '' },
  'premium to nav': { name: 'Premium to NAV', icon: '', suffix: '%', multiplier: 100, parser: '' },
  'assets': { name: 'Assets', icon: '', suffix: '', multiplier: 1, parser: val => FormatCurrency(val || 0) },
  'yield': { name: 'Yield', icon: '', suffix: '%', multiplier: 1, parser: '' },
  'fund flows': { name: 'Fund Flows', icon: '', suffix: '', multiplier: 1, parser: val => FormatCurrency(val || 0) },
  'volume': { name: 'Volume', icon: '', suffix: '', multiplier: 1, parser: val => FormatCurrency(val || 0) },
  'tracking error': { name: 'Tracking error', icon: '', suffix: '%', multiplier: 1, parser: '' },
  'bid-ask spread': { name: 'Bid-Ask Spread', icon: '', suffix: '%', multiplier: 1, parser: '' },
  'track record': { name: 'Track Record', icon: '', suffix: 'yrs', multiplier: 1, parser: '' },
  'trend': { name: 'Trend', icon: '', suffix: '%', multiplier: 1, parser: '' },
  'manager tenure': { name: 'Manager Tenure', suffix: 'Yrs.', multiplier: 1 },
  'expense ratio': { name: 'Expense Ratio*', suffix: '%', multiplier: 1 },
  'aum': { name: 'AUM', suffix: '', multiplier: 1, parser: val => FormatCurrency(val || 0) },
  'up capture 5yr': { name: 'Up Capture 5Yr', suffix: '%', multiplier: 1 },
  'up capture 10yr': { name: 'Up Capture 10Yr', suffix: '%', multiplier: 1 },
  'down capture 5yr': { name: 'Down Capture 5Yr', suffix: '%', multiplier: 1 },
  'down capture 10yr': { name: 'Down Capture 10Yr', suffix: '%', multiplier: 1 },
  'outperformance 3yr': { name: 'Outperformance 3yr', suffix: '%', multiplier: 1 },
  'outperformance 5yr': { name: 'Outperformance 5yr', suffix: '%', multiplier: 1 },
  'outperformance 10yr': { name: 'Outperformance 10yr', suffix: '%', multiplier: 1 },
  'alpha 5yr': { name: 'Alpha 5yr', suffix: '%', multiplier: 1 },
  'alpha 10yr': { name: 'Alpha 10yr', suffix: '%', multiplier: 1 },
}

export const concatAttrs = (items) => {
  if (!items) {
    return '';
  }
  if (!Array.isArray(items)) {
    return items;
  }
  return items.reduce((acc, curr, i) => {
    curr = curr === 'return' ? 'historical returns' : curr;
    return i !== 0 ? acc + `${items.length - 1 === i ? ` and  ` : ', '}` + curr : curr
  }, '');
}

export const concatQuery = (items) => {
  if (!items) {
    return '';
  }
  if (!Array.isArray(items)) {
    return items;
  }
  return items.reduce((acc, curr, i) => {
    return i !== 0 ? acc + `${items.length - 1 === i ? ` and  ` : ', '}` + `"${curr}"` : `"${curr}"`
  }, '');
}

export const groupByFunds = (selectedFunds, property) => {
  let i, val, result = [];
  for (i = 0; i < selectedFunds.length; i++) {
    if (val !== selectedFunds[i][property]) {
      result.push({
        isHeading: true,
        correctedQuery: selectedFunds[i].correctedQuery
      })
    }
    selectedFunds[i]['rowIndex'] = i;
    result.push(selectedFunds[i]);
    val = selectedFunds[i][property];
  }
  return result;
}

export const arrayChunk = (selectedFunds, maxCutOff) => {
  let i, j = 0, array = [], result = [];
  for (i = 0; i < selectedFunds.length; i++) {
    array.push(selectedFunds[i]);
    if (j === maxCutOff - 1 || i === selectedFunds.length - 1) {
      const isHeading = array[array.length - 1].isHeading;
      if (!isHeading) {
        result.push(array);
      } else {
        // If the last item of the array is the header
        // remove heading from the array and push it to result
        array.pop();
        result.push(array);
        // Decrement i when the last element is removed
        // to accomodate it in the next loop
        i--;
      }
      array = [];
      j = -1;
    }
    j++;
  }
  return result;
}

export const fixedValue = (val, prec = 2) => {
  return (val || val === 0) ? Number(Number(val).toFixed(prec)) : '';
}

export const splitDataForTicks = (start, end, noOfTicks, round = 0) => {
  // var result = [],
  //     delta = (end - start) / (noOfTicks - 1);
  // while (start < end) {
  //     result.push(!round ? Math.round(start) : round === -1 ? start : Number(start.toFixed(round)));
  //     start += delta;
  // }
  // result.push(!round ? Math.round(end) : round === -1 ? end : Number(end.toFixed(round)));
  // return [...new Set(result)];

  let result = [start];
  let range = end - start;
  let delta = range / (noOfTicks - 1);
  let prevValue = start;
  for (let i = 1; i < noOfTicks - 1; i++) {
    let value = prevValue + delta;
    prevValue = value;
    value = !round ? Math.round(value) : round === -1 ? value : Number(value.toFixed(round));
    result.push(value);
  }
  result.push(end);
  return result;
}

export const toFixedNoRounding = function (num, prec) {
  if (!num || isNaN(Number(num))) return num;
  num = num.toFixed(prec + 1);
  return num.substring(0, num.length - prec)
}

export const indexPageMapping = {
  'ex-sum': {
    name: 'Executive Summary',
    showPageNum: true,
  },
  'rel-funds': {
    name: 'Relevant Funds',
    showPageNum: true,
  },
  'sel-criteria': {
    name: 'Selection Criteria',
    showPageNum: true,
  },
  'perf-analytics-tradeoff': {
    name: 'Performance Analytics : Return-Risk Trade-Off',
    showPageNum: true,
  },
  'perf-analytics-up-down-capture': {
    name: 'Performance Analytics : Upside-Downside Capture',
    showPageNum: true,
  },
  'perf-analytics-cor-matrix': {
    name: 'Performance Analytics : Correlation Matrix',
    showPageNum: true,
  },
  'perf-analytics-factor-exposure': {
    name: 'Performance Analytics : Factor Exposures',
    showPageNum: true,
  },
  'high-rel-funds': {
    name: 'Highest Scoring Relevant Investment Options',
    showPageNum: true,
  },
  'conclusion': {
    name: 'Conclusion',
    showPageNum: true,
  },
  'appendix': {
    name: 'Appendix',
    showPageNum: false,
  },
  'other-rel-funds': {
    name: 'a. Other Relevant Funds',
    showPageNum: true,
  },
  'fund-fact-sheet': {
    name: 'Fund Fact Sheets',
    showPageNum: true,
  },
}

const performanceKeyMapping = {
  returnRiskKeys_MPT: ['volatility_3y', 'returns_3y'],
  returnRiskKeys_CATEGORY: ['volatility_3y', 'returns_3y'],
  cumExcessKeys_MPT: ['tracking_error', 'cumret_excess_3y'],
  cumExcessKeys_CATEGORY: ['tracking_error_category', 'cumret_excess_3y_category'],
  annExcessKeys_MPT: ['tracking_error', 'annualret_excess_3y'], //
  annExcessKeys_CATEGORY: ['tracking_error_category', 'annualret_excess_3y_category'], //
  numOfMonthsKeys_MPT: ['months_down', 'months_up'],
  numOfMonthsKeys_CATEGORY: ['months_down', 'months_up'],
  avgRetvsMarketKeys_MPT: ['average_down_ret', 'average_up_ret'], //
  avgRetvsMarketKeys_CATEGORY: ['average_down_ret', 'average_up_ret'], //
  sizeValueKeys_MPT: ['zypher_value', 'zypher_size'],
  sizeValueKeys_CATEGORY: ['zypher_value', 'zypher_size'],
  momentumValueKeys_MPT: ['zypher_value', 'zypher_momentum'],
  momentumValueKeys_CATEGORY: ['zypher_value', 'zypher_momentum'],
}

const mul100Cases = (key, value) => {
  switch (key) {
    case 'returns_3y':
      return mul100(value)
    case 'volatility_3y':
      return mul100(value)
    case 'tracking_error_category':
      return mul100(value)
    case 'annualret_excess_3y':
      return mul100(value)
    case 'annualret_excess_3y_category':
      return mul100(value)
    case 'average_up_ret':
      return mul100(value)
    case 'average_down_ret':
      return mul100(value)
    default:
      return value
  }
}

export const getPerformaceChartdata = (funds, dataKeys) => {
  // console.log(funds, dataKeys);
  const reportData = useContext(ActReportContext)
  const { benchmark } = reportData;
  // console.log(benchmark);
  const keys = dataKeys && performanceKeyMapping[`${dataKeys}_${benchmark}`];

  // console.log(keys);
  // console.log(dataKeys);

  if (!dataKeys || !keys) return;

  const chartData = funds
    .map((fund, index) => {
      return ({
        name: fund.ticker_short,
        color: customColors[index % customColors.length],
        data: [{
          x: fixedValue(mul100Cases(keys[0], fund[keys[0]])),
          y: fixedValue(mul100Cases(keys[1], fund[keys[1]])),
        }],
      })
    });
  return chartData;
}

export const getPercentileData = (inputData, percentileKeys) => {
  const out = percentileKeys.map((item, index) => {
    return {
      name: item,
      value: getPercentileValue(inputData, item)
    }
  })
  return out;
}

const getPercentileValue = (value, percentile) => {
  value.sort(function (a, b) { return a - b; });
  const index = (percentile / 100) * value.length;
  let result;
  if (Math.floor(index) == index) {
    result = (value[index - 1] + value[index]) / 2;
  }
  else {
    result = value[Math.floor(index)];
  }
  return parseFloat(result.toFixed(1));
}

export const customColors = ['#83AED4', '#8592C9', '#AB90E0', '#D59AF5', '#D1799E', '#E6B58A',
  '#E07777', '#D7E8A8', '#9AD8F5', '#85C7C9', '#8EE9A1', '#009A9A'];

export const handleNoData = (val) => {
  return (val || val === 0) ? val : '-';
}

export const handleSingleCount = (count) => count < 10 ? '0' + count : count;

export const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const mul100 = (value, key) => {
  return (value || value === 0) ? value * 100 : ''
}

import React from 'react'
import { Table } from 'reactstrap'

import { withHeaderFooter } from './HeaderFooter'

const ContentTable = withHeaderFooter((props) => {
  let { first, list, split,split20,SPLIT_CONTENT_PAGE,index,replacementChosen,noReplacementChosen,noReplacementFound } = props
  //start = offset +( index * items per page)
  let start = split.length +1  + (index*SPLIT_CONTENT_PAGE)
  return (
    <React.Fragment>
      {list && <div className="content">
        {first && <div className="pr-v2-header">
          <h3>Table of Contents</h3>
        </div>}
        <Table striped className="pr-v2-toc">
          <tbody>
          {first && <tr>
            <td scope="row">Overview of Suggested Replacements</td>
            {/*next page split20.length will be overview */}
            <td>01</td>
          </tr>}
          {list && list.map((ticker, i) => {
            return ticker.selected.ticker && <tr>
              <td scope="row">Additional Details of Suggested Replacements for {ticker.ticker} </td>
              <td>
                {start+i<10?`0${start+i}`:start+i}
              </td>
            </tr>
          })}
          </tbody>
        </Table>
      </div>}
    </React.Fragment>
  )
})
export default ContentTable

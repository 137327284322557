import React, { Component, Fragment } from 'react';
// import moment from 'moment';
import { connect } from 'react-redux';
// import { Redirect } from 'react-router-dom';
import { get } from 'lodash';

import { Tooltip } from 'reactstrap'
import { Badge } from 'antd';
import { createLoadingSelector } from 'selectors/loading-selector';
import { searchQueryRequest, setFundsState } from 'actions/discovery';
import { fetchProfile } from 'actions/profile';
import { fundsApiCallTime, clearFundsApiLoadPercent } from 'actions/global';
import { subscriptionPlan, setProfileState } from 'actions/profile';
import { getAlertCountRequest } from 'actions/accounts';

// import { getPremiumFeatureDetails } from 'apis/test-drive';

// import EmbedSection from 'components/EmbedSection';
// import { PremiumModal, LoginWall } from 'components/Premium';
// import PButton from 'components/PButton';
// import PremiumNoticeModal from 'components/PremiumNoticeModal';
// import CustomAlert from 'components/CustomAlert';
import { searchUrlV3, addEventToAnalytics, clearWindowUtmSession } from 'Utils';
// import { SeoLinks, ReservedRoutes, REDIRECT_DOMAIN_PATH } from 'DataSet';
import {  customScroll } from 'layouts/utils'

import TopFeeds from '../../Elements/TopFeeds';
import AlertFeeds from '../../Elements/AlertFeeds';
// import Plugins from '../../Elements/Plugins';
import PopularThemes from '../../Elements/Plugins/PopularThemes';
import SearchBox from '../../Elements/SearchBox';
// import Spinner from '../../Elements/Spinner';

class SideBar extends Component {
  constructor(props){
    super(props);
    this.state = {
      isEmbedOpen: false,
      isNotifyOpen: false,
      download:false,
      alert:false,
      premiumModal: false,
      notify: false,
      premiumFeature: 'false',
			dataWasFetched: 'false',
      QTTooltip:false
    };
  }

  componentDidMount() {
		// const { apex_account_id, getAlertCountRequest, getAccountsTrendingSearchesRequest } = this.props;
    // const id = this.props.match.params.id;
		// if (getAccountsTrendingSearchesRequest) {
		// 	getAccountsTrendingSearchesRequest();
		// }
		// if (apex_account_id && getAlertCountRequest) {
		// 	getAlertCountRequest({ apex_account_id, count: 3 });
		// }
    this.updateScrollDiv();
    this.fetchApexAlerts();
  }

  componentDidUpdate() {
    this.updateScrollDiv();
  }

  componentWillUnmount() {
    if (this.alertTimer) {
      clearInterval(this.alertTimer)
      this.alertTimer = null;
    }
  }

  fetchApexAlerts = () => {
    const { apex_account_id, getAlertCountRequest, profile, alertCountData } = this.props;
    if (apex_account_id && getAlertCountRequest) {
      const orders        = (profile) ? get(profile, 'apex.orders') : [];
      const tradeRequest  = (profile) ? get(profile, 'apex.trade_request') : [];
      if ((orders && orders.length === 0) && (tradeRequest && tradeRequest.length === 0) && !alertCountData.data) {
          getAlertCountRequest({
            apex_account_id,
            count: 3,
          });
      }
      if (this.alertTimer) clearInterval(this.alertTimer);
      this.alertTimer = setInterval(() => getAlertCountRequest({
        apex_account_id,
        count: 3,
      }), 120000);
    }
  }

  updateScrollDiv = () => {
    customScroll();
    const staticElement = document.getElementById("static-elements");
    const headerElement = document.getElementById("magnifi-header-wrapper");
    if (staticElement && headerElement) {
      const scrollableElement = document.getElementById("scrollable-elements");
      if (scrollableElement) {
        let heightForList = headerElement.offsetHeight + staticElement.offsetHeight + 20 +"px"; // 20 is padddingTop for itemListHeaderWrapper
        // console.log('heightForList =>>', `calc(98vh - ${heightForList})`);
        scrollableElement.style.height = `calc(98vh - ${heightForList})`;
      }
    }
  }

  checkRoutes = (query, clearUTMSession = false) => {
    // const { location, isTermsAgreed, loggedIn, match } = this.props;
    // const { pathname } = location;
    // const query = window.sessionStorage.getItem('query');
    // if (loggedIn && isTermsAgreed === 0 && query) return;
    // if (!match.params.id || match.params.id == '') this.props.history.push(searchUrlV3(query));
    const { history } = this.props;
    if (history) {
      if (clearUTMSession) {
        clearWindowUtmSession();
      }
      history.push(searchUrlV3(query));
    }
  }

  queryHandler = (query, search_type) => {
		const { query: _query, setFundsState } = this.props;
		if (_query !== query) {
			setFundsState({
				requestPayloadFilters: {
					filterKeys: [],
					filterValues: [],
				},
				requestPayloadSort: {
					name: '',
					value: '',
				},
			});
		}
    if (query) {
      if(search_type == 'trending_searches')
        addEventToAnalytics('Trending Searches','Trending Searches','TRENDING_SEARCHES',{clicked_query: query},true);

      const { searchQueryRequest, setFundsState, fundsApiCallTime, clearFundsApiLoadPercent } = this.props;
      clearFundsApiLoadPercent();
      window.sessionStorage.setItem('query', query);
      setFundsState({
        actions: true,
        currentPage: 1,
      });
      this.checkRoutes(query);
      window.sessionStorage.setItem('queryFrom', 'acc');
      searchQueryRequest({
        query,
        search_type,
      });
    }
  }

  redirect = (to)=>{
    this.props.history.push(to)
  }

	onClickAlert = (alertData, actionType) => {
		// console.log(alertData);
		// console.log(actionType);
    // route to settings/alert
    this.redirect('/settings/alert');
	}

  render() {
    const { query, discovery, loading, fundsLoader, loggedIn, premiumUser, trendingQueries, smallScreen, alertCountData, alert_count } = this.props;
    const { related, didYouMean } = discovery;

		const tendringQueryList = trendingQueries && trendingQueries.length ? trendingQueries.map((item) => {
			if (typeof item.query !== 'undefined') {
				return item.query
			}
		}).filter((t) => typeof t !== 'undefined') : null;

		const feedsQueries = tendringQueryList ? JSON.parse(JSON.stringify(tendringQueryList)) : [];
		const initialList = tendringQueryList ? JSON.parse(JSON.stringify(tendringQueryList.splice(0, 4))) : [];

    return (
      <React.Fragment>
        <div className="sideBarWrapper accountsV2SidebarWrapper">
					<div id="static-elements">
						<div className="element">
							<div className="label">What do you want to invest in?</div>
							<div className="searchInput">
								<SearchBox
									queryHandler={this.queryHandler}
									query={query}
									history={this.props.history}
									loggedIn={loggedIn}
                  universe={discovery.universe}
								/>
							</div>
						</div>
					</div>
          <div id="scrollable-elements" className="sidebar-content custom-scroll elementPenal accountPageV4">
						{!loading &&
							<Fragment>
                <div className="element">
                  <div className="label">Quick Links</div>
									<div className="quick-link-wrapper">
										{/* <a className="v2-quick-link"><img src="/assets/images/icons/OpenAnAccount.svg"/>Open a New Account</a> */}
										<a onClick={()=>this.redirect('/settings/tradingAccount?show=linked_accounts')} className="v2-quick-link"><img src="/assets/images/icons/TransferFunds.svg"/>Add Balance</a>
										<a onClick={()=>this.redirect('/settings/tradingAccount?show=downld_doc')}className="v2-quick-link"><img src="/assets/images/icons/Documents.svg"/>Documents</a>
                    <span>
                      <a id={'qtTrade'} className="v2-quick-link"><img src="/assets/images/icons/QuickTrade_gray.svg"/>Quick Trade
                        <Tooltip placement="bottom" isOpen={this.state.QTTooltip} autohide={false} target="qtTrade" toggle={() => this.setState({ QTTooltip: !this.state.QTTooltip })} className='selector-btn-tooltip'>
                          Coming Soon!
                        </Tooltip>
                      </a>
                    </span>
									</div>
								</div>
                {alertCountData.data && alertCountData.error !== '' && (
                  <div className="element">
                    <div className="label pos-rel">
                      Alerts <span className="sub-label">(click on any to access alerts)</span>
                      {(alertCountData.data && alertCountData.data.length && alertCountData.error !== '') && (
                        <Badge className="accounts-sidebar-notification-count" count={alert_count} onClick={() => this.onClickAlert('all', 'count_click')} />
                      )}
                    </div>
                    {(alertCountData.data && alertCountData.data.length && alertCountData.error !== '') && (
                      <Fragment>
                        <AlertFeeds
                          data={alertCountData.data}
                          onClickAlert={this.onClickAlert}
                        />
                    </Fragment>
                    )}
                  </div>
                )}
                {(feedsQueries && feedsQueries.length > 0)
                  && (
                    <div className="element">
                      <div className="label">Trending Searches <span className="sub-label">(click on any to see results)</span></div>
                      <TopFeeds
                        initialList={initialList}
                        queryList={feedsQueries}
                        queryHandler={this.queryHandler}
                      />
                    </div>
                  )
                }
                <PopularThemes
                  className={loading ? 'hide' : 'show'}
                  loading={loading}
                  // query={query}
                  queryHandler={this.queryHandler}
                />
                {/* <Plugins executeCall={this.executeCall} queryHandler={this.queryHandler} history={this.props.history} /> */}
              </Fragment>
						}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ auth, discovery, router, profile, global, loading, state, accounts, trendingSearches }) => ({
	discovery,
  query: discovery.query,
  fundsLoader: discovery.loading,
  // trendingQueries: discovery.trendingQueries,
  profile: profile.profile, // for sfm
  location: router.location,
  loggedIn: auth.loggedIn,
  premiumUser: auth.user.premiumUser,
  inviteSuccess:profile.inviteSuccess,
  inviteSentTo:profile.inviteSentTo,
	smallScreen: global.smallScreen,
	apex_account_id: get(profile.profile, 'apex.account[0].id'),
	alertCountData: accounts.alertCountData,
  alert_count: profile.profile.apex && profile.profile.apex.alert_count,
	trendingQueries: trendingSearches.searches,
  // apex_account_id: get(state.profile.profile, 'apex.account[0].id'),
  alertLoading: createLoadingSelector(['GET_ALERT_COUNT'])({ loading }),
  loading: createLoadingSelector(['GET_ACCOUNTS_TRENDING_SEARCHES'])({ loading }) || discovery.actions,
})

const mapDispatchToProps = {
  searchQueryRequest,
  setFundsState,
  fundsApiCallTime,
  clearFundsApiLoadPercent,
  subscriptionPlan,
  setProfileState,
	getAlertCountRequest,
  fetchProfile,
  // getAccountsTrendingSearchesRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideBar)

import React, {useEffect, useState, Fragment } from 'react';
import style from './TradingAccount.module.scss';
import {
    employmentTypeTradingAccount,
    financialGoalsTradingAccount,
    liquidityTimeTradingAccount,
    occupationDisclosure,
    uploadTypeTradAcc,
    tradingExperience,
    investMentObjectives,
    annualIncomeRange,
    formattedRange,
    totalNetWorth,
    riskTolerance,
    dividentReinvestment,
    liquidNetWorth,
    taxBracket
} from '../../Constant/FormData';
import CountrySelect from '../Profile/CountrySelect'
import StateSelect from '../Profile/StateSelect'
import { CustomFormFeedback, FieldValue } from 'shared-components';
import { FormGroup, Input, Label, Spinner as StrapSpin } from 'reactstrap';
import { Select, Radio } from 'antd';
import { capitalizeAll } from '../../../utils';
import PhoneNumberInput from '../../../../components/PhoneNumberInput';

const { Option } = Select;

export const EditableJointDetails  = (props) => {
    const [activeTab, setActiveTab] = useState('0');
    const { formProps, handleCitizen, handleYourDClick, handleExperence, handleUserDetailsChange, showMartialSelection, showUserDetailsUpdate, toggleMartial, toggleTrustedContact, showTrustedContactSelection, toggleUserDetailsUpdate } = props;

    const {
      values,
      initialValues,
      touched,
      errors,
      handleChange,
      handleBlur,
      setFieldValue
    } = formProps;

    const handleTrustedSelect = (e) =>{
      const { setFieldValue, values } = formProps;
      let trusted = [...values.trusted_select];
      if(!e.target.checked && values.trusted_select.includes(e.target.name)){
        if(trusted.length > 1)
          trusted = values.trusted_select.filter(val => val !== e.target.name);
      }else{
        trusted.push(e.target.name);
      }
      setFieldValue('trusted_select' , trusted);
      toggleUserDetailsUpdate(true);
    }

    const getSplittedValue = (value) => {
      let formattedIndex = formattedRange.findIndex(el => Object.keys(el)[0] === value)
      if(formattedIndex >= 0){
        return formattedRange[formattedIndex][value]
      }
      return value
    }

    return(
        <Fragment>
            <div className={style.viewTrBtn}>
                <button type="button" onClick={() => setActiveTab('0')} className={` ${activeTab === '0' && style.active}`}>Primary Applicant</button>
                <button type="button" onClick={() => setActiveTab('1')} className={` ${activeTab === '1' && style.active}`}>Secondary Applicant</button>
            </div>
            {activeTab === '0' ?
                <>
                {/* APPLICANT */}
                <FormGroup className={`${style.addressGroup} ${style.formGroup} `}>
                  <Label className={style.key} for='address'>Applicant</Label>
                  {/* {!showAddressEdit && <div onClick={toggleAddress}
                    className={`${style.cursorPointer} ${style.value}`}>Select</div>} */}
                  <div
                    className={style.value}
                  >
                    {values.customerType}
                  </div>
                </FormGroup>
                {/*Marital Status*/}
                <FormGroup className={style.formGroup}>
                  <Label className={style.key} for='marital'>Marital Status</Label>
                  {!showMartialSelection &&
                  <div
                    onClick={toggleMartial}
                    className={`${style.cursorPointer}
                    ${style.value}`}
                  >
                    {values.marital_status === 'married' ? 'Married' : values.marital_status === 'single' ? 'Single' : 'Not Specified'}
                  </div>}
                  {showMartialSelection &&
                    <div className={style.genderSelection}>
                      <Radio.Group id={'martial'} onChange={(e) => handleUserDetailsChange('marital_status', e.target.value, 'showMartialSelection')} value={values.marital_status}>
                        <Radio value={'married'}>Married</Radio>
                        <Radio value='single'>Single</Radio>
                        <Radio value={''}>Not Specified</Radio>
                      </Radio.Group>
                    </div>
                  }
                </FormGroup>
                {/* CITIZENSHIP */}
                <FormGroup className={style.formGroup}>
                  <Label className={style.key} for='self_role'>Citizenship</Label>
                  <div
                    className={`${style.cursorNotAllowed} ${style.value}`}
                  >
                    <FieldValue className={style.value} dashIfEmpty>
                            {values.citizenship}
                    </FieldValue>
                  </div>
                </FormGroup>
                {/* SOCIAL SECURITY NUMBER */}
                <FormGroup className={style.formGroup}>
                    <Label className={style.key} for='ssn'>Social Security Number</Label>
                    <div
                        className={`${style.cursorNotAllowed} ${style.value}`}>
                        {values.ssn ? values.ssn : 'Select'}
                    </div>
                </FormGroup>
                {/* INVESTING EXPERENCE */}
                <FormGroup className={style.formGroup}>
                  <Label className={style.key} for='experience'>Investment Experience</Label>
                  <Select
                    type='select'
                    name='experience'
                    id='experience'
                    value={values.experience}
                    onChange={(val) => handleUserDetailsChange('experience', val)}
                    bordered={false}
                    suffixIcon={null}
                    dropdownMatchSelectWidth={false}
                    dropdownClassName={style.dropdownANT}
                    onBlur={handleBlur}
                    className={
                      errors.self_role && touched.self_role ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                    }
                  >
                    {
                      tradingExperience.map((item) => {
                        return <Option key={`experience ${item.value}`} value={item.value}>{item.value}</Option>
                      })
                    }
                  </Select>
                  <CustomFormFeedback
                    formProps={formProps}
                    fieldName='experience'
                  />
                </FormGroup>

                {/* TRUSTED CONTACT */}
                <FormGroup className={style.formGroup} style={{ height : 'auto'}}>
                    <div className={style.formBoxPanel}>
                    <div className={style.trustedPerson}>
                  <Label className={style.key} style={{marginBottom: 'auto'}} for='trustedContacts'>Trusted Contact Person</Label>
                  {!showTrustedContactSelection &&
                  <div
                    className={`${style.cursorPointer} ${style.value}`}
                    style={{height: 'auto'}}
                    onClick={(e) => toggleTrustedContact(true)}
                  >
                    <div className={style.trustedData}>
                      <div>{values.trusted_contact ? "YES" : "NO"}</div>
                      {values.trusted_contact &&
                      <Fragment>
                      <div>{`${values.trusted_contact_firstname} ${values.trusted_contact_lastname}`}</div>
                        {values.trusted_select.includes("trusted_email") &&
                          <Fragment>
                            <div>{values.trusted_contact_email}</div>
                          </Fragment>
                        }
                        {values.trusted_select.includes("trusted_phone") &&
                          <Fragment>
                            <div>{values.trusted_contact_country_code} {values.trusted_contact_mobile}</div>
                          </Fragment>
                        }
                        {values.trusted_select.includes("trusted_address") &&
                          <Fragment>
                            <div>{values.trusted_contact_street} {values.trusted_contact_city} {values.trusted_contact_state} {values.trusted_contact_country} {values.trusted_contact_zipcode}</div>
                          </Fragment>
                        }
                      </Fragment>
                      }
                    </div>
                  </div>}
                  {showTrustedContactSelection &&
                    <div className={style.genderSelection}>
                      <Radio.Group id={'trusted_contact'} onChange={(e) => handleUserDetailsChange('trusted_contact', e.target.value)} value={values.trusted_contact}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </div>

                  }
                  </div>
                  {values.trusted_contact && showTrustedContactSelection ?
                    <div className={style.trustedContactContainer}>
                      <FormGroup className="element-box pos-rel">

                      <div className={style.formBox_view}>
                        <div className={style.leftPart}>Name</div>
                        <div className={style.rightPart}>
                        <div className={style.errorWithFeedback}>
                          <Input
                                type='text'
                                name='trusted_contact_firstname'
                                id='trusted_contact_firstname'
                                placeholder="First Name"
                                value={values.trusted_contact_firstname}
                                onChange={(e) => handleUserDetailsChange('trusted_contact_firstname', e.target.value)}
                                onBlur={handleBlur}
                                className={errors.trusted_contact_firstname && touched.trusted_contact_firstname ? `is-invalid ${style.inputInvalid}` : style.input}
                          />
                          <CustomFormFeedback
                            formProps={formProps}
                            fieldName='trusted_contact_firstname'
                          />
                        </div>
                        <div className={style.errorWithFeedback}>
                          <Input
                                type='text'
                                name='trusted_contact_lastname'
                                id='trusted_contact_lastname'
                                placeholder="Last Name"
                                value={values.trusted_contact_lastname}
                                onChange={(e) => handleUserDetailsChange('trusted_contact_lastname', e.target.value)}
                                onBlur={handleBlur}
                                className={errors.trusted_contact_lastname && touched.trusted_contact_lastname ? `is-invalid ${style.inputInvalid}` : style.input}
                          />
                          <CustomFormFeedback
                            formProps={formProps}
                            fieldName='trusted_contact_lastname'
                          />
                        </div>
                        </div>
                      </div>

                      </FormGroup>
                      <FormGroup className="element-box pos-rel">
                      <div className={style.formBox_view}>
                      <div className={style.leftPart}>
                      <Label className={style.key} style={{marginBottom: 'auto'}} for='trustedContacts'>Email</Label>
                      <Input
                          type="checkbox"
                          name="trusted_email"
                          id="trusted_email"
                          checked={values.trusted_select.includes("trusted_email")}
                          onChange={handleTrustedSelect}
                          className={errors.trusted_email && touched.trusted_email ? style.inputInvalid
                                : style.checkboxInput}
                        />
                      </div>

                      <div className={style.rightPart}>
                        {values.trusted_select.includes("trusted_email") &&
                          <div className={style.secInfo}>
                          <Input
                              type='email'
                              name='trusted_contact_email'
                              id='trusted_contact_email'
                              placeholder="Email"
                              value={values.trusted_contact_email}
                              onChange={(e) => handleUserDetailsChange('trusted_contact_email', e.target.value)}
                              onBlur={handleBlur}
                              className={errors.trusted_contact_email && touched.trusted_contact_email ? `is-invalid ${style.inputInvalid}` : style.input}
                          />
                          <CustomFormFeedback
                            formProps={formProps}
                            fieldName='trusted_contact_email'
                          />
                          </div>
                          }
                          </div>
                          </div>
                      </FormGroup>
                      <FormGroup className="element-box pos-rel">
                      <div className={style.formBox_view}>
                      <div className={style.leftPart}>
                        <Label className={style.key} style={{marginBottom: 'auto'}} for='trustedContacts'>Phone Number</Label>
                        <Input
                          type="checkbox"
                          name="trusted_phone"
                          id="trusted_phone"
                          checked={values.trusted_select.includes("trusted_phone")}
                          onChange={handleTrustedSelect}
                          className={errors.trusted_phone && touched.trusted_phone ? style.inputInvalid
                            : style.checkboxInput}
                        />
                        </div>
                        <div className={style.rightPart}>
                          {values.trusted_select.includes("trusted_phone") &&
                            <div className={`${style.errorWithFeedback} phoneContainer`}>
                              <PhoneNumberInput
                                hideCode={true}
                                onChangePhoneCode={(val) => handleUserDetailsChange('trusted_contact_country_code', val)}
                                errors={errors}
                                touched={touched}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                phoneCodeName="trusted_contact_country_code"
                                phoneName="trusted_contact_mobile"
                              />
                              {errors.trusted_contact_mobile && touched.trusted_contact_mobile && <div className={style.formInvalidField}>{errors['trusted_contact_mobile']}</div>}
                            </div>
                          }
                        </div>
                        </div>
                      </FormGroup>
                      <FormGroup className="element-box pos-rel">
                      <div className={style.formBox_view}>
                      <div className={style.leftPart}>
                        <Label className={style.key} style={{marginBottom: 'auto'}} for='trustedContacts'>Address</Label>
                        <Input
                          type="checkbox"
                          name="trusted_address"
                          id="trusted_address"
                          checked={values.trusted_select.includes("trusted_address")}
                          onChange={handleTrustedSelect}
                          className={errors.trusted_address && touched.trusted_address ? style.inputInvalid
                            : style.checkboxInput}
                          />
                          </div>
                          <div className={style.rightPart}>
                          {values.trusted_select.includes("trusted_address") &&
                            <Fragment>
                                <div className="row spacing-5 ml-5">
                                  <div className="col-12 col-sm-12 col-md-4">
                                  <FormGroup className="element-box pos-rel pl-2">
                                  <CountrySelect
                                      trusted
                                      dropdownClassName={style.dropdownANT}
                                      className={`${style.input} ${style.antSelect}`}
                                      value={values.trusted_contact_country}
                                      onChange={(val) => handleUserDetailsChange('trusted_contact_country', val)}
                                      bordered={false}
                                      suffixIcon={null}
                                      dropdownMatchSelectWidth={false}
                                      onBlur={handleBlur}
                                  />
                                  {errors.trusted_contact_country && touched.trusted_contact_country && <div className={style.formInvalidField}>{errors['trusted_contact_country']}</div>}
                                </FormGroup>
                                </div>
                                <div className="col-12 col-sm-12 col-md-4">
                                <FormGroup className="element-box pos-rel pl-2">
                                  <StateSelect
                                      dropdownClassName={style.dropdownANT}
                                      className={`${style.input} ${style.antSelect}`}
                                      value={values.trusted_contact_state}
                                      onChange={(val) => handleUserDetailsChange('trusted_contact_state', val)}
                                      bordered={false}
                                      suffixIcon={null}
                                      dropdownMatchSelectWidth={false}
                                      onBlur={handleBlur}
                                  />
                                  {errors.trusted_contact_state && touched.trusted_contact_state && <div className={style.formInvalidField}>{errors['trusted_contact_state']}</div>}
                                </FormGroup>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-4">
                                  <FormGroup className="element-box pos-rel pl-2">
                                  <Input
                                      type='text'
                                      placeholder={'Street Address'}
                                      name='trusted_contact_street'
                                      id='trusted_contact_street'
                                      value={values.trusted_contact_street}
                                      onChange={(e) => handleUserDetailsChange('trusted_contact_street', e.target.value)}
                                      onBlur={handleBlur}
                                      className={errors.trusted_contact_street && touched.trusted_contact_street ? style.inputInvalid : style.input} />
                                  {errors.trusted_contact_street && touched.trusted_contact_street && <div className={style.formInvalidField}>{errors['trusted_contact_street']}</div>}
                              </FormGroup>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-4">
                                  <FormGroup className="element-box pos-rel pl-2">
                                  <Input
                                    type='text'
                                    placeholder={'City'}
                                    name='trusted_contact_city'
                                    id='trusted_contact_city'
                                    value={values.trusted_contact_city}
                                    onChange={(e) => handleUserDetailsChange('trusted_contact_city', e.target.value)}
                                    onBlur={handleBlur}
                                    className={errors.trusted_contact_city && touched.trusted_contact_city ? style.inputInvalid : style.input} />
                                    {errors.trusted_contact_city && touched.trusted_contact_city && <div className={style.formInvalidField}>{errors['trusted_contact_city']}</div>}
                                </FormGroup>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-4">
                                  <FormGroup className="element-box pos-rel pl-2">
                                  <Input
                                    type='text'
                                    name='trusted_contact_zipcode'
                                    id='trusted_contact_zipcode'
                                    placeholder={'Zipcode'}
                                    value={values.trusted_contact_zipcode}
                                    onChange={(e) => handleUserDetailsChange('trusted_contact_zipcode', e.target.value)}
                                    onBlur={handleBlur}
                                    className={errors.trusted_contact_zipcode && touched.trusted_contact_zipcode ? `is-invalid ${style.inputInvalid}` : style.input}
                                  />
                                  <CustomFormFeedback
                                    formProps={formProps}
                                    fieldName='trusted_contact_zipcode'
                                  />
                              </FormGroup>
                                  </div>

                                </div>



                        </Fragment>
                        }
                        </div>
                        </div>
                      </FormGroup>
                    </div>
                  : null}
                        </div>

              </FormGroup>
                {/* MAILING ADDRESS */}
                <FormGroup className={`${style.addressGroup} ${style.formGroup} `}>
                  <Label className={style.key} for='address'>Mailing Address</Label>
                  <div
                    className={`${style.cursorNotAllowed}
                    ${style.value}`}>
                    {values.addressM} {values.cityM} {values.stateM} {values.countryM} {values.zipcodeM}
                  </div>
                </FormGroup>
                {/* BUSINESS ADDRESS */}
                <FormGroup className={style.formGroup}>
                    <Label className={style.key} for='bussinessAddress'>Business Address</Label>
                    <div
                        className={`${style.cursorNotAllowed} ${style.value}`}
                    >
                        {values.addressB} {values.cityB} {values.stateB} {values.countryB} {values.zipcodeB}
                    </div>
                </FormGroup>
                <FormGroup className={`${style.addressGroup} ${style.formGroup} `}>
                  <Label className={style.key} for='address'>Investment Objective</Label>
                  <div className={style.errorWithFeedback}>
                    <Select
                        type='select'
                        name='investmentObjective'
                        id='investmentObjective'
                        value={values.investmentObjective}
                        onChange={(val) => handleUserDetailsChange('investmentObjective', val)}
                        bordered={false}
                        suffixIcon={null}
                        dropdownMatchSelectWidth={false}
                        dropdownClassName={style.dropdownANT}
                        onBlur={handleBlur}
                        className={
                        errors.self_role && touched.self_role ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                        }
                    >
                        <Option value='' disabled>Select</Option>
                        {investMentObjectives.map((item,i) => {
                                return <Option key={`investmentObjective${i}`} value={item} >{capitalizeAll(item.split('_').map(i=>i.toLowerCase()).join(' '))}</Option>
                            })}
                    </Select>
                    {errors.investmentObjective && touched.investmentObjective && <div className={style.formInvalidField}>{errors['investmentObjective']}</div>}
                  </div>
                </FormGroup>

                <FormGroup className={`${style.addressGroup} ${style.formGroup} `}>
                  <Label className={style.key} for='address'>Annual Income</Label>
                  <div className={style.errorWithFeedback}>
                    <Select
                        type='select'
                        name='annualIncomeUSD'
                        id='annualIncomeUSD'
                        value={values.annualIncomeUSD}
                        onChange={(val) => handleUserDetailsChange('annualIncomeUSD', val)}
                        bordered={false}
                        suffixIcon={null}
                        dropdownMatchSelectWidth={false}
                        dropdownClassName={style.dropdownANT}
                        onBlur={handleBlur}
                        className={
                        errors.self_role && touched.self_role ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                        }
                    >
                        <Option value='' disabled>Select</Option>
                        {annualIncomeRange.map((item,i) => {
                                return <Option key={`annualIncomeRange${i}`} value={item} >{getSplittedValue(capitalizeAll(item.split('_').map(i=>i.toLowerCase()).join(' ')) )}</Option>
                            })}
                    </Select>
                    {errors.annualIncomeUSD && touched.annualIncomeUSD && <div className={style.formInvalidField}>{errors['annualIncomeUSD']}</div>}
                  </div>
                </FormGroup>
                {/* <FormGroup className={`${style.addressGroup} ${style.formGroup} `}>
                  <Label className={style.key} for='address'>Liquid Net Worth</Label>
                  <div className={style.errorWithFeedback}>
                    <Select
                        type='select'
                        name='liquidNetWorthUSD'
                        id='liquidNetWorthUSD'
                        value={values.liquidNetWorthUSD}
                        onChange={(val) => handleUserDetailsChange('liquidNetWorthUSD', val)}
                        bordered={false}
                        suffixIcon={null}
                        dropdownMatchSelectWidth={false}
                        dropdownClassName={style.dropdownANT}
                        onBlur={handleBlur}
                        className={
                        errors.self_role && touched.self_role ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                        }
                    >
                        <Option value='' disabled>Select</Option>
                        {liquidNetWorth.map((item,i) => {
                                return <Option key={`liquidNetWorth${i}`} value={item} >{capitalizeAll(item.split('_').map(i=>i.toLowerCase()).join(' '))}</Option>
                            })}
                    </Select>
                    {errors.liquidNetWorthUSD && touched.liquidNetWorthUSD && <div className={style.formInvalidField}>{errors['liquidNetWorthUSD']}</div>}
                  </div>
                </FormGroup> */}
                <FormGroup className={`${style.addressGroup} ${style.formGroup} `}>
                  <Label className={style.key} for='address'>Total Net Worth</Label>
                  <div className={style.errorWithFeedback}>
                    <Select
                        type='select'
                        name='totalNetWorthUSD'
                        id='totalNetWorthUSD'
                        value={values.totalNetWorthUSD}
                        onChange={(val) => handleUserDetailsChange('totalNetWorthUSD', val)}
                        bordered={false}
                        suffixIcon={null}
                        dropdownMatchSelectWidth={false}
                        dropdownClassName={style.dropdownANT}
                        onBlur={handleBlur}
                        className={
                        errors.self_role && touched.self_role ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                        }
                    >
                        <Option value='' disabled>Select</Option>
                        {totalNetWorth.map((item,i) => {
                                return <Option key={`totalNetWorth${i}`} value={item} >{getSplittedValue(capitalizeAll(item.split('_').map(i=>i.toLowerCase()).join(' ')))}</Option>
                            })}
                    </Select>
                    {errors.totalNetWorthUSD && touched.totalNetWorthUSD && <div className={style.formInvalidField}>{errors['totalNetWorthUSD']}</div>}
                  </div>
                </FormGroup>
                {/* <FormGroup className={`${style.addressGroup} ${style.formGroup} `}>
                  <Label className={style.key} for='address'>Risk Tolerance</Label>
                  <div className={style.errorWithFeedback}>
                    <Select
                        type='select'
                        name='riskTolerance'
                        id='riskTolerance'
                        value={values.riskTolerance}
                        onChange={(val) => handleUserDetailsChange('riskTolerance', val)}
                        bordered={false}
                        suffixIcon={null}
                        dropdownMatchSelectWidth={false}
                        dropdownClassName={style.dropdownANT}
                        onBlur={handleBlur}
                        className={
                        errors.self_role && touched.self_role ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                        }
                    >
                        <Option value='' disabled>Select</Option>
                        {riskTolerance.map((item,i) => {
                                return <Option key={`riskTolerance${i}`} value={item} >{capitalizeAll(item.split('_').map(i=>i.toLowerCase()).join(' '))}</Option>
                            })}
                    </Select>
                    {errors.riskTolerance && touched.riskTolerance && <div className={style.formInvalidField}>{errors['riskTolerance']}</div>}
                  </div>
                </FormGroup> */}
                {/* <FormGroup className={`${style.addressGroup} ${style.formGroup} `}>
                  <Label className={style.key} for='address'>Tax Bracket</Label>
                  <div className={style.errorWithFeedback}>
                    <Input
                        type='text'
                        placeholder={''}
                        name='federalTaxBracketPercent'
                        id='federalTaxBracketPercent'
                        value={values.federalTaxBracketPercent}
                        onChange={(e) => handleUserDetailsChange('federalTaxBracketPercent', e.target.value)}
                        onBlur={handleBlur}
                        className={errors.federalTaxBracketPercent && touched.federalTaxBracketPercent ? style.inputInvalid : `${style.input} ${style.styledInput}`} />

                    {errors.federalTaxBracketPercent && touched.federalTaxBracketPercent && <div className={style.formInvalidField}>{errors['federalTaxBracketPercent']}</div>}
                  </div>
                </FormGroup> */}
                <FormGroup className={`${style.addressGroup} ${style.formGroup} `}>
                  <Label className={style.key} for='address'>Dividend Reinvestment</Label>
                  <div className={style.errorWithFeedback}>
                    <Select
                        type='select'
                        name='dividendReinvestment'
                        id='dividendReinvestment'
                        value={values.dividendReinvestment}
                        onChange={(val) => handleUserDetailsChange('dividendReinvestment', val)}
                        bordered={false}
                        suffixIcon={null}
                        dropdownMatchSelectWidth={false}
                        dropdownClassName={style.dropdownANT}
                        onBlur={handleBlur}
                        className={
                        errors.self_role && touched.self_role ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                        }
                    >
                        <Option value='' disabled>Select</Option>
                        {dividentReinvestment.map((item,i) => {
                                return <Option key={`dividendReinvestment${i}`} value={item} >{capitalizeAll(item.split('_').map(i=>i.toLowerCase()).join(' '))}</Option>
                            })}
                    </Select>
                    {errors.dividendReinvestment && touched.dividendReinvestment && <div className={style.formInvalidField}>{errors['dividendReinvestment']}</div>}
                  </div>
                </FormGroup>
                {showUserDetailsUpdate && <div className={`${style.formGroup} ${style.btnFlexEnd}`}>
                  <button type='submit' className={style.update}>
                    Update
                  </button>
                </div>}
              </>
                :
                // secondary details
                <Fragment>
                    <FormGroup className={`${style.addressGroup} ${style.formGroup} `}>
                        <Label className={style.key} for="address">Applicant</Label>
                        <div
                            className={`${style.cursorPointer} ${style.value}`}>
                             <div className={style.userInfo}>
                                <div>
                                    <label>First Name</label><br/>
                                    {values.joint_first_name || '-'}
                                </div>
                                <div>
                                    <label>Last Name</label><br/>
                                    {values.joint_last_name || '-'}
                                </div>
                            </div>
                        </div>
                    </FormGroup>
                    {/* CITIZENSHIP */}
                    <FormGroup className={style.formGroup}>
                        <Label className={style.key} for='Citizenship'>Citizenship</Label>
                        <div
                          className={`${style.cursorNotAllowed} ${style.value}`}
                        >
                          <FieldValue className={style.value} dashIfEmpty>
                            {values.joint_citizenship}
                          </FieldValue>
                        </div>
                    </FormGroup>
                    {/* SOCIAL SECURITY NUMBER */}
                    <FormGroup className={style.formGroup}>
                        <Label className={style.key} for="ssn">Social Security Number</Label>
                        <div className={`${style.cursorNotAllowed} ${style.value}`}>
                            {values.joint_ssn}
                        </div>
                    </FormGroup>
                    <FormGroup className={style.formGroup}>
                        <Label className={style.key} for="secondary_phone_number">Phone Number</Label>
                        <div className={`${style.cursorNotAllowed} ${style.value}`}>
                          {values.joint_country_code} {values.joint_phone}
                        </div>
                    </FormGroup>
                    <FormGroup className={style.formGroup}>
                        <Label className={style.key} for="secondary_email">Email</Label>
                        <div className={`${style.cursorNotAllowed} ${style.value}`}>
                            {values.joint_email}
                        </div>
                    </FormGroup>
                    <FormGroup className={style.formGroup}>
                        <Label className={style.key} for="secondary_dob">Date of Birth</Label>
                        <div className={`${style.cursorNotAllowed} ${style.value}`}>
                            {values.joint_dob}
                        </div>
                    </FormGroup>
                    {/* INVESTING EXPERENCE */}
                    {/* <FormGroup className={style.formGroup}>
                    <Label className={style.key} for="experience">Investment Experience</Label>
                        <Select
                            type="select"
                            name="experience"
                            id="experience"
                            value={values.experience}
                            onChange={ handleExperence &&  handleYourDClick}
                            bordered={false}
                            suffixIcon={null}
                            dropdownMatchSelectWidth={false}
                            dropdownClassName={style.dropdownANT}
                            onBlur={handleBlur}
                            className={
                            errors.self_role && touched.self_role ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                            }
                        >
                            {tradingExperience.map((item) =><Option value={item.value}>{item.value}</Option>)}
                        </Select>
                        <CustomFormFeedback
                            formProps={formProps}
                            fieldName="experience"
                        />
                    </FormGroup> */}
                    <FormGroup className={style.formGroup}>
                        <Label className={style.key} for="bussinessAddress">Address</Label>
                        <div className={`${style.cursorNotAllowed} ${style.value}`}>
                            {values.joint_street} {values.joint_city} {values.joint_state} {values.joint_country} {values.joint_postal}
                        </div>
                    </FormGroup>
                </Fragment>
            }
        </Fragment>
    )
}

export const JointEditableDisclosure  = (props) => {
    const [activeTab, setActiveTab] = useState('0');
    const {
        formProps,
        showUpdateFinra,
        showUpdateFinraJoint,
        showUpdate,
        showEmployeerName,
        showJointEmployeerName,
        upload_file_name,
        joint_upload_file_name,
        downLoadState,
        fileUpload,
        handleSeniorExecutive,
        handleStockNameChange,
        handleUploadChange,
        handleUpload,
        onDownloadSnap,
        handleDelete,
        handlePoliticalExp,
        handleChangePoliticalOrganization,
        removePoliticalRelation,
        addMorePoliticalRelation,
        handleEmpTypeChange,
        toggleEmployeerName,
        toggleJointEmployeerName,
        handleOccupationChange,
        handleLiquidityNeed,
        handleTimeHorizon,
        handleEmpNameChange,
        handleFirmChange,
        handleUserDetailsChange,
        toggleUpdateFinraJoint,
        toggleUpdate,
        handleAffiliatedWith,
        togglePoliticallyExposed,
        togglePoliticallyExposedJoint,
        showPoliticallyExposed,
        showPoliticallyExposedJoint
     } = props;
    const {
        values,
        initialValues,
        touched,
        errors,
        handleChange,
        handleBlur,
        setFieldValue } = formProps;
console.log('joint props >>', props);
    const handleJointAffiliatedWith = () => {
        const { setFieldValue } = formProps;
        if (values.joint_is_affiliated_exchange_finra === 'YES') {
            setFieldValue('joint_is_affiliated_exchange_finra', 'NO')
            toggleUpdate(true)
            toggleUpdateFinraJoint(true);
        } else {
            setFieldValue('joint_is_affiliated_exchange_finra', 'YES')
            toggleUpdate(true)
            toggleUpdateFinraJoint(true);
        }
      }

    const handleJointPoliticalExp = (val) => {
        const { values } = formProps;
        if (val == 'NO') {
          setFieldValue('joint_is_political_exposed', 'NO')
        } else {
          setFieldValue('joint_is_political_exposed', 'YES')
          setFieldValue('joint_political_organization', !(values.joint_political_organization && values.joint_political_organization.length) ? [{
            political_organization: '',
            relation: ''
          }] : values.joint_political_organization);
        }
        toggleUpdate(true);
    }

    const addMoreJointPoliticalRelation = () => {
        const { values, setFieldValue } = formProps;
        let pol = values.joint_political_organization;
        if (pol) {
          pol.push({ political_organization: '', relation: '' })
        }
        setFieldValue('joint_political_organization', pol)
        toggleUpdate(true)
    }

    const removeJointPoliticalRelation = (index) => {
        const { values, setFieldValue } = formProps;
        let pol = values.joint_political_organization.filter((val, i) => i !== index);
        setFieldValue('joint_political_organization', pol)
        toggleUpdate(true)
    }

    const handleChangeJointPoliticalOrganization = (e, name, index) => {
        const { values, setFieldValue } = formProps;
        let pol = values.joint_political_organization;
        pol[index][name] = e.target.value;
        setFieldValue('joint_political_organization', pol);
        toggleUpdate(true);
    }

    return(
        <Fragment>
            <div className={style.viewTrBtn}>
                <button type="button" onClick={() => setActiveTab('0')} className={` ${activeTab === '0' && style.active}`}>Primary Applicant</button>
                <button type="button" onClick={() => setActiveTab('1')} className={` ${activeTab === '1' && style.active}`}>Secondary Applicant</button>
            </div>
            {activeTab === '0' ?
            <Fragment>
            <div className={`${style.addressGroup} ${style.formGroup} `}>
              <div className={style.key}>You/Family members is a senior executive or 10% shareholder at a public trading company
              </div>
              <div className={style.addressInfo}>
                <div className={style.firstInfo}>
                  <div id='is_control_person'
                       className={`${style.AccIve} ${style.value}`}
                       onClick={e => {
                         e.preventDefault()
                         handleSeniorExecutive(values.is_control_person)
                       }}
                  >
                    {values.is_control_person == 'YES' ? 'Yes' : 'No'}
                  </div>
                  {values.is_control_person == 'YES' ?
                    <div>
                      <div className={`${style.AccIve} ${style.value}`}>Stock Name</div>
                      <Input
                        style={{ width: `120px` }}
                        type='text'
                        required
                        placeholder={'Company Name'}
                        name='stock_name'
                        id='stock_name'
                        value={values.stock_name}
                        onChange={(e) => handleUserDetailsChange('stock_name', e.target.value, null, 'discloser')}
                        onBlur={handleBlur}
                        className={errors.stock_name && touched.stock_name ? style.inputInvalid : `${style.input} ${style.styledInput}`}
                        // disabled={true}
                      />
                    </div>
                    : null
                  }
                </div>
              </div>
            </div>

            <div className={`${style.addressGroup} ${style.formGroup} `}>
              <div className={style.key}>You/Family, or anyone authorized to trade in your account, affiliated with,
                 work with or work for a member firm of a stock exchange or FINRA?
              </div>
              <div id='isAffiliatedExchangeOrFINRA'
                   onClick={e => {
                     e.preventDefault()
                     handleAffiliatedWith()
                   }}
                   className={`${style.AccIve} ${style.value}`}>{values.isAffiliatedExchangeOrFINRA == 'YES' ? 'Yes' : 'No'}
              </div>
            </div>
            {values.isAffiliatedExchangeOrFINRA == 'YES' &&
            <>
            <FormGroup className={style.formGroup}>
                <Label className={style.key} for='firm_name'>Firm Name</Label>

                <div className={style.errorWithFeedback}>
                  <Input
                    type='text'
                    name='firm_name'
                    id='firm_name'
                    placeholder={'Firm Name'}
                    value={values.firm_name}
                    onChange={(e) => handleFirmChange(e)}
                    onBlur={handleBlur}
                    className={errors.firm_name && touched.firm_name ? `is-invalid ${style.inputInvalid}` : `${style.input2} ${style.styledInput}`}
                  />
                  <CustomFormFeedback formProps={formProps} fieldName='firm_name' />
                </div>
              </FormGroup>
              <FormGroup className={style.formGroup}>
                <Label className={style.key} for='employement_type'>Upload Type</Label>
                <div className={style.errorWithFeedback}>
                  <Select
                    type='select'
                    name='upload_type'
                    id='upload_type'
                    value={values.upload_type}
                    onChange={handleUploadChange}
                    bordered={false}
                    suffixIcon={null}
                    dropdownMatchSelectWidth={false}
                    dropdownClassName={style.dropdownANT}
                    onBlur={handleBlur}
                    className={
                      errors.upload_type && touched.upload_type ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                    }
                  >
                    {
                      uploadTypeTradAcc.map((item) => {
                        return <Option disabled={item.disabled} value={item.value}>{item.name}</Option>
                      })
                    }
                  </Select>
                  {errors.upload_type && touched.upload_type &&
                  <div className={style.formInvalidField}>{errors.upload_type}</div>}
                </div>
              </FormGroup>
              {
                values.upload_type !== '' &&
                <FormGroup className={style.formGroup}>
                  <Label className={style.key} for='upload_file'>
                    {values.upload_file && upload_file_name}
                  </Label>
                  <div className={style.addressInfo}>
                    <div className={style.firstInfo}>
                      <input id='upload_file' accept="image/*" type='file' style={{ visibility: 'hidden', width: 0 }}
                             onChange={(e) => fileUpload(e, 'upload_file')} />
                      <button onClick={(e) => handleUpload(e, 'upload_file')} className={style.update}>
                        Upload
                      </button>
                    </div>
                  </div>
                </FormGroup>
              }
              {values.upload_3210 !== '' && !showUpdateFinra &&
              <div className={`${style.addressGroup} ${style.formGroup} `}>
                <div className={style.key}>3210 Letter</div>
                <div className={style.addressInfo}>
                  <div className={style.firstInfo}>
                    <input id='identity_proof' accept="image/*" type='file' style={{ visibility: 'hidden', width: 0 }}
                           onChange={(e) => fileUpload(e, 'identity_proof')} />
                    {values.identity_proof && <div>{values.identity_proof}</div>}
                    {downLoadState.id === 'upload_3210' && downLoadState.loading &&
                    <StrapSpin />}
                    <button className={`${style.key} ${style.dwnContainer}`}
                      disabled={downLoadState.loading}
                      onClick={() => onDownloadSnap(values.upload_3210, 'upload_3210')}>
                      <i class='fa fa-download' aria-hidden='true'></i>
                      {values.identity_proof &&
                      <i className='far fa-fw fa-trash-alt' style={{ color: '#9f9f9f', marginLeft: 5 }}
                         onClick={(e) => handleDelete(e, 'identity_proof')}></i>}
                    </button>
                  </div>
                </div>
              </div>}
              {values.upload_407 !== '' && !showUpdateFinra &&
              <div className={`${style.addressGroup} ${style.formGroup} `}>
                <div className={style.key}>407 Letter</div>
                <div className={style.addressInfo}>
                  <div className={style.firstInfo}>
                    <input id='address_proof' accept="image/*" type='file' style={{ visibility: 'hidden', width: 0 }}
                           onChange={(e) => fileUpload(e, 'address_proof')} />
                    {values.address_proof && <div>{values.address_proof}</div>}
                    {downLoadState.id === 'upload_407' && downLoadState.loading &&
                    <StrapSpin />}
                    <button className={`${style.key} ${style.dwnContainer}`}
                      disabled={downLoadState.loading}
                      onClick={() => onDownloadSnap(values.upload_407, 'upload_407')}>
                      <i class='fa fa-download' aria-hidden='true'></i>
                      {values.address_proof &&
                      <i className='far fa-fw fa-trash-alt' style={{ color: '#9f9f9f', marginLeft: 5 }}
                         onClick={(e) => handleDelete(e, 'address_proof')}></i>}
                    </button>
                  </div>
                </div>
              </div>}
              {errors.upload_file && touched.upload_file &&
              <div style={{ textAlign: 'right' }} className={style.formInvalidField}>{errors.upload_file}</div>}
            </>
            }

            <div style={{ position: 'relative', alignItems: 'start' }} className={`${style.formGroup} ${style.addressGroup} ${style.politicallyExposedContainer}`}>
                <div className={style.key}>You/Family members are politically exposed</div>
                {!showPoliticallyExposed && <div
                  className={`${style.key} ${style.cursorPointer} ${style.value}`}
                  onClick={togglePoliticallyExposed}
                  >
                  {values.isPoliticallyExposed == "YES" ? "Yes" : "No"}
                </div>}
                {showPoliticallyExposed &&
                      <div className={style.genderSelection}>
                        <Radio.Group id={'politicaly'} onChange={(e) => handlePoliticalExp(e.target.value)} value={values.isPoliticallyExposed}>
                          <Radio value="YES">Yes</Radio>
                          <Radio value="NO">No</Radio>
                        </Radio.Group>
                      </div>
                }
              </div>
              {!showPoliticallyExposed && values.isPoliticallyExposed == "YES" && <FormGroup className={style.formGroup}
                onClick={togglePoliticallyExposed} style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <table style={{width: '50%', textAlign: 'right'}}>
                      <thead>
                        <th style={{color: "#56a9e8"}}>Name</th>
                        <th style={{color: "#56a9e8"}}>Relation</th>
                      </thead>
                      <tbody>
                        {values.political_organization.map((o, i) =>
                        <tr>
                          <th style={{color: "#56a9e8"}}>{o.political_organization}</th>
                          <th style={{color: "#56a9e8"}}>{o.relation}</th>
                        </tr>
                        )}
                      </tbody>
                    </table>
              </FormGroup>}
              {showPoliticallyExposed && values.isPoliticallyExposed == "YES" && values.political_organization.length > 0 && <div>
              <div className="row">
                <div className={`${style.key} ${style.cursorPointer} ${style.value} col`}>Name</div>
                <div className={`${style.key} ${style.cursorPointer} ${style.value} col`}>Relation</div>
              </div>

              {values.political_organization.map((o, i) => (
                <div className={`${style.secInfo} pos-rel`} key={`political_organization${i}`}>
                  <div className={`${style.errorWithFeedback} col p-0 ml-0`} title={o.political_organization}>
                    <Input
                      type='text'
                      placeholder={'political_organization'}
                      name='name'
                      id='political_organization'
                      value={o.political_organization}
                      onChange={(e) => handleChangePoliticalOrganization(e, 'political_organization', i)}
                      onBlur={handleBlur}
                      className={errors.political_organization && touched.political_organization ? `${style.inputInvalid} ${style.multipleInputs}` : `${style.input} ${style.multipleInputs}`} />
                    {errors.political_organization && touched.political_organization && <div
                      className={style.formInvalidField}>{errors.political_organization[i] && errors.political_organization[i].political_organization}</div>}
                  </div>
                  <div className={`${style.errorWithFeedback} col p-0`} title={o.relation}>
                    <Input
                      type='text'
                      placeholder={'relation'}
                      name='relation'
                      id='relation'
                      value={o.relation}
                      onChange={(e) => handleChangePoliticalOrganization(e, 'relation', i)}
                      onBlur={handleBlur}
                      className={errors.political_organization && touched.political_organization ? `${style.inputInvalid} ${style.multipleInputs}` : `${style.input} ${style.multipleInputs}`} />
                    {errors.political_organization && touched.political_organization && <div
                      className={style.formInvalidField}>{errors.political_organization[i] && errors.political_organization[i].relation}</div>}
                  </div>
                  {i > 0 && <i className={`fas fa-trash ach-trash ${style.removePoliticalRelation}`}
                              onClick={() => removePoliticalRelation(i)}></i>}
                </div>))}
              <div className={style.secInfo} key={`political_organization-btn`}>
                <button type='button' className={style.addPoliticalRelation}
                        onClick={addMorePoliticalRelation}>Add
                </button>
              </div>
              </div>}

            <FormGroup className={style.formGroup}>
              <Label className={style.key} for='employement_type'>Employment Type</Label>
              <div className={style.errorWithFeedback}>
                <Select
                  type='select'
                  name='employement_type'
                  id='employement_type'
                  value={values.employement_type}
                  onChange={handleEmpTypeChange}
                  bordered={false}
                  suffixIcon={null}
                  dropdownMatchSelectWidth={false}
                  dropdownClassName={style.dropdownANT}
                  onBlur={handleBlur}
                  className={
                    errors.occupation && touched.occupation ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                  }
                >
                  {
                    employmentTypeTradingAccount.map((item) => {
                      return <Option disabled={item.disabled} key={item.value} value={item.value}>{item.name}</Option>
                    })
                  }
                </Select>
                <CustomFormFeedback formProps={formProps} fieldName='occupation' />
              </div>
            </FormGroup>

            {values.employement_type == 'Employed' &&
            <>
              <FormGroup className={style.formGroup}>
                <Label className={style.key} for='employer_name'>Name of Employer</Label>
                {!showEmployeerName && <div onClick={toggleEmployeerName}
                                            className={`${style.cursorPointer} ${style.value}`}>{values.employer_name ? values.employer_name : 'NA'}</div>}
                {showEmployeerName && <div className={style.errorWithFeedback}>
                  <Input
                    type='text'
                    name='employer_name'
                    id='employer_name'
                    placeholder={'Employeer Name'}
                    value={values.employer_name}
                    onChange={(e) => handleEmpNameChange(e)}
                    onBlur={handleBlur}
                    className={errors.phone && touched.phone ? `is-invalid ${style.inputInvalid}` : style.input2}
                  />
                  <CustomFormFeedback formProps={formProps} fieldName='employer_name' />
                </div>}
              </FormGroup>
              <FormGroup className={style.formGroup}>
                <Label className={style.key} for='occupation'>Occupation</Label>
                <div className={style.errorWithFeedback}>
                  <Input
                    type='text'
                    name='occupation'
                    id='occupation'
                    placeholder={'Occupation'}
                    value={values.occupation}
                    onChange={(e) => handleOccupationChange(e)}
                    onBlur={handleBlur}
                    className={errors.phone && touched.phone ? `is-invalid ${style.inputInvalid}` : `${style.input2} ${style.styledInput}`}
                  />
                  <CustomFormFeedback formProps={formProps} fieldName='occupation' />
                </div>
              </FormGroup>
            </>
            }
            {/* <FormGroup className={style.formGroup}>
              <Label className={style.key} for='time_horizon'>Number of Years planned to invest to achieve particular
                financial goal</Label>
              <Select
                type='select'
                name='time_horizon'
                id='time_horizon'
                value={values.time_horizon}
                onChange={handleTimeHorizon}
                bordered={false}
                suffixIcon={null}
                dropdownMatchSelectWidth={false}
                dropdownClassName={style.dropdownANT}
                onBlur={handleBlur}
                className={
                  errors.time_horizon && touched.time_horizon ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                }
              >
                {
                  financialGoalsTradingAccount.map((item) => {
                    return <Option disabled={item.disabled} value={item.value}>{item.name}</Option>
                  })
                }

              </Select>
              <CustomFormFeedback
                formProps={formProps}
                fieldName='time_horizon'
              />
            </FormGroup> */}
            {/* <FormGroup className={style.formGroup}>
              <Label className={style.key} for='liquidity_needs'>Ability to quickly and easily convert all or a
                portion of the account assets into cash without experiencing significant loss</Label>
              <Select
                type='select'
                name='liquidity_needs'
                id='liquidity_needs'
                value={values.liquidity_needs}
                onChange={handleLiquidityNeed}
                bordered={false}
                suffixIcon={null}
                dropdownMatchSelectWidth={false}
                dropdownClassName={style.dropdownANT}
                onBlur={handleBlur}
                className={
                  errors.liquidity_needs && touched.liquidity_needs ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                }
              >

                {
                  liquidityTimeTradingAccount.map((item) => {
                    return <Option disabled={item.disabled} value={item.value}>{item.name}</Option>
                  })
                }
              </Select>
              <CustomFormFeedback
                formProps={formProps}
                fieldName='liquidity_needs'
              />
            </FormGroup> */}
          </Fragment>
                :

                // secondary applicant data
                <Fragment>
                <div className={`${style.addressGroup} ${style.formGroup} `}>
                    <div className={style.key}>You/Family members is a senior executive or 10% shareholder at a public trading company</div>
                    <div className={style.addressInfo}>
                        <div className={style.firstInfo}>
                            <div id="joint_is_control_person" className={`${style.AccIve} ${style.value}`}
                                onClick={e => {
                                    e.preventDefault()
                                    handleUserDetailsChange('joint_is_control_person', values.joint_is_control_person == 'YES' ? 'NO' : 'YES', null, 'discloser')
                                }}
                            >
                            {values.joint_is_control_person == 'YES' ? 'Yes' : 'No'}
                            </div>

                            {values.joint_is_control_person === "YES"  ?
                                <div>
                                    <div className={`${style.AccIve} ${style.value}`}>Stock Name</div>
                                    <Input
                                      style={{ width: `120px` }}
                                      type="text"
                                      placeholder={'Company Name'}
                                      name="joint_stock_name"
                                      id="joint_stock_name"
                                      value={values.joint_stock_name}
                                      onChange={
                                          (e)=>
                                          handleUserDetailsChange('joint_stock_name', e.target.value, null, 'discloser')
                                      }
                                      onBlur={handleBlur}
                                      className={errors.joint_stock_name && touched.joint_stock_name ? style.inputInvalid : `${style.input} ${style.styledInput}`}
                                    />
                                    {errors.joint_stock_name && touched.joint_stock_name && <div className={style.formInvalidField}>{errors.joint_stock_name}</div>}
                                </div>
                            : null
                            }
                        </div>
                        </div>
                    </div>
                <div className={`${style.addressGroup} ${style.formGroup} `}>
                    <div className={style.key}>You/Family, or anyone authorized to trade in your account, affiliated with, work with or work for a member firm of a stock exchange or FINRA?</div>
                <div id="joint_is_affiliated_exchange_finra" onClick={e => {
                    e.preventDefault()
                    handleJointAffiliatedWith()
                }}
                    className={`${style.AccIve} ${style.value}`}>{values.joint_is_affiliated_exchange_finra === "YES" ? 'Yes' : 'No'}</div>
                    </div>
                    {values.joint_is_affiliated_exchange_finra === "YES" &&
                    <>
                    <FormGroup className={style.formGroup}>
                        <Label className={style.key} for='joint_firm_name'>Firm Name</Label>
                        <div className={style.errorWithFeedback}>
                        <Input
                            type='text'
                            name='joint_firm_name'
                            id='joint_firm_name'
                            placeholder={'Firm Name'}
                            value={values.joint_firm_name}
                            onChange={(e) => handleUserDetailsChange('joint_firm_name', e.target.value, null, 'discloser')}
                            onBlur={handleBlur}
                            className={errors.joint_firm_name && touched.joint_firm_name ? `is-invalid ${style.inputInvalid}` : `${style.input2} ${style.styledInput}`}
                        />
                        <CustomFormFeedback formProps={formProps} fieldName='joint_firm_name' />
                        </div>
                    </FormGroup>
                    <FormGroup className={style.formGroup}>
                    <Label className={style.key} for="employement_type">Upload Type</Label>
                    <div className={style.errorWithFeedback}>
                    <Select
                        type="select"
                        name="joint_upload_file_type"
                        id="joint_upload_file_type"
                        value={values.joint_upload_file_type}
                        onChange={(val) => handleUserDetailsChange('joint_upload_file_type', val, null, 'discloser')}
                        bordered={false}
                        suffixIcon={null}
                        dropdownMatchSelectWidth={false}
                        dropdownClassName={style.dropdownANT}
                        onBlur={handleBlur}
                        className={
                        errors.joint_upload_file_type && touched.joint_upload_file_type ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                        }
                    >
                        {
                            uploadTypeTradAcc.map((item) => {
                                return <Option disabled={item.disabled} value={item.value}>{item.name}</Option>
                            })
                        }
                    </Select>
                    {errors.joint_upload_file_type && touched.joint_upload_file_type && <div className={style.formInvalidField}>{errors.joint_upload_file_type}</div>}
                        </div>
                    </FormGroup>
                    {
                        values.joint_upload_file_type !== "" &&
                        <FormGroup className={style.formGroup}>
                        <Label className={style.key} for="joint_upload_file_type">
                          {values.joint_upload_file_type && joint_upload_file_name}
                        </Label>
                        <div className={style.addressInfo}>
                            <div className={style.firstInfo}>
                        <input key="joint-upload" accept="image/*" id="joint_upload_file" type="file" style={{visibility: 'hidden' ,width:0 , height:0}} onChange={(e) => fileUpload(e, "joint_upload_file")}/>
                        <button onClick={(e) => handleUpload(e, "joint_upload_file")} className={style.update}>
                            Upload
                        </button>
                        </div>
                        </div>
                        </FormGroup>

                    }
                    { values.joint_upload_3210 !== "" && !showUpdateFinraJoint && <div className={`${style.addressGroup} ${style.formGroup} `}>
                        <div className={style.key} >3210 Letter</div>
                        <div className={style.addressInfo}>
                            <div className={style.firstInfo}>
                            <input id="identity_proof" type="file" accept="image/*" style={{visibility: 'hidden' ,width:0}} onChange={(e)=> fileUpload(e,"identity_proof")}/>
                            { values.identity_proof && <div>{values.identity_proof}</div>}
                            {downLoadState.id === 'joint_upload_3210' && downLoadState.loading && <StrapSpin />}
                            <button className={`${style.key} ${style.dwnContainer}`}
                             disabled={downLoadState.loading}
                            onClick={() =>  onDownloadSnap(values.joint_upload_3210, 'joint_upload_3210')}>
                            <i class="fa fa-download" aria-hidden="true"></i>
                            {values.identity_proof &&<i className="far fa-fw fa-trash-alt" style={{color:"#9f9f9f" ,marginLeft:5}} onClick={(e)=> handleDelete(e ,"identity_proof")}></i>}
                            </button>
                            </div>
                    </div>
                    </div>}
                    { values.joint_upload_407 !== "" && !showUpdateFinraJoint && <div className={`${style.addressGroup} ${style.formGroup} `}>
                        <div className={style.key}>407 Letter</div>
                        <div className={style.addressInfo}>
                            <div className={style.firstInfo}>
                            <input id="address_proof" accept="image/*" type="file" style={{visibility: 'hidden',width:0}} onChange={(e)=> fileUpload(e,"address_proof")}/>
                            { values.address_proof && <div>{values.address_proof}</div>}
                            {downLoadState.id === 'joint_upload_407' && downLoadState.loading && <StrapSpin />}
                            <button className={`${style.key} ${style.dwnContainer}`}
                             disabled={downLoadState.loading}
                            onClick={() =>  onDownloadSnap(values.joint_upload_407, 'joint_upload_407')}>
                                <i class="fa fa-download" aria-hidden="true"></i>
                                {values.address_proof && <i className="far fa-fw fa-trash-alt" style={{color:"#9f9f9f",marginLeft:5}} onClick={(e)=> handleDelete(e,"address_proof")}></i>}
                            </button>
                            </div>
                    </div>
                    </div>}
                    {errors.joint_upload_file && touched.joint_upload_file && <div style={{ textAlign: 'right' }} className={style.formInvalidField}>{errors.joint_upload_file}</div>}
                    </>
                    }

                    <div style={{ position: 'relative', alignItems: 'start' }} className={`${style.formGroup} ${style.addressGroup} ${style.politicallyExposedContainer}`}>
                <div className={style.key}>You/Family members are politically exposed</div>
                {!showPoliticallyExposedJoint && <div
                  className={`${style.key} ${style.cursorPointer} ${style.value}`}
                  onClick={togglePoliticallyExposedJoint}
                  >
                  {values.joint_is_political_exposed == "YES" ? "Yes" : "No"}
                </div>}
                {showPoliticallyExposedJoint &&
                      <div className={style.genderSelection}>
                        <Radio.Group id={'politicaly'} onChange={(e) => handleJointPoliticalExp(e.target.value)} value={values.joint_is_political_exposed}>
                          <Radio value="YES">Yes</Radio>
                          <Radio value="NO">No</Radio>
                        </Radio.Group>
                      </div>
                }
              </div>
              {!showPoliticallyExposedJoint && values.joint_is_political_exposed == "YES" && values.joint_political_organization.length && <FormGroup className={style.formGroup}
                onClick={togglePoliticallyExposedJoint} style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <table style={{width: '50%', textAlign: 'right'}}>
                      <thead>
                        <th style={{color: "#56a9e8"}}>Name</th>
                        <th style={{color: "#56a9e8"}}>Relation</th>
                      </thead>
                      <tbody>
                        {values.joint_political_organization.map((o, i) =>
                        <tr>
                          <th style={{color: "#56a9e8"}}>{o.political_organization}</th>
                          <th style={{color: "#56a9e8"}}>{o.relation}</th>
                        </tr>
                        )}
                      </tbody>
                    </table>
              </FormGroup>}
              {showPoliticallyExposedJoint && values.joint_is_political_exposed == "YES" && values.joint_political_organization.length > 0 && <div>
                <div className="row">
                  <div className={`${style.key} ${style.cursorPointer} ${style.value} col`}>Name</div>
                  <div className={`${style.key} ${style.cursorPointer} ${style.value} col`}>Relation</div>
                </div>

                {values.joint_political_organization.map((o, i) => (
                  <div className={`${style.secInfo} pos-rel`} key={`joint_political_organization${i}`}>
                    <div className={`${style.errorWithFeedback} col p-0 ml-0`} title={o.political_organization}>
                      <Input
                        type='text'
                        placeholder={'political_organization'}
                        name='name'
                        id='political_organization'
                        value={o.political_organization}
                        onChange={(e) =>  handleChangeJointPoliticalOrganization(e, 'political_organization', i, 'joint_political_organization')}
                        onBlur={handleBlur}
                        className={errors.political_organization && touched.political_organization ? `${style.inputInvalid} ${style.multipleInputs}` : `${style.input} ${style.multipleInputs}`} />
                      {errors.joint_political_organization && touched.joint_political_organization && <div
                        className={style.formInvalidField}>{errors.joint_political_organization[i] && errors.joint_political_organization[i].political_organization}</div>}
                    </div>
                    <div className={`${style.errorWithFeedback} col p-0`} title={o.relation}>
                      <Input
                        type='text'
                        placeholder={'relation'}
                        name='relation'
                        id='relation'
                        value={o.relation}
                        onChange={(e) =>  handleChangeJointPoliticalOrganization(e, 'relation', i)}
                        onBlur={handleBlur}
                        className={errors.political_organization && touched.political_organization ? `${style.inputInvalid} ${style.multipleInputs}` : `${style.input} ${style.multipleInputs}`} />
                      {errors.joint_political_organization && touched.joint_political_organization && <div
                        className={style.formInvalidField}>{errors.joint_political_organization[i] && errors.joint_political_organization[i].relation}</div>}
                    </div>
                    {i > 0 && <i className={`fas fa-trash ach-trash ${style.removePoliticalRelation}`}
                                onClick={() => removeJointPoliticalRelation(i)}></i>}
                  </div>))}
                <div className={style.secInfo} key={`political_organization-btn`}>
                  <button type='button' className={style.addPoliticalRelation}
                          onClick={addMoreJointPoliticalRelation}>Add
                  </button>
                </div>
                </div>}
                <FormGroup className={style.formGroup}>
                <Label className={style.key} for="is_joint_employed">Employment Type</Label>
                <div className={style.errorWithFeedback}>
                        <Select
                            type="select"
                            name="is_joint_employed"
                            id="is_joint_employed"
                            value={values.is_joint_employed}
                            onChange={(value) => handleUserDetailsChange("is_joint_employed", value, null, "discloser")}
                            bordered={false}
                            suffixIcon={null}
                            dropdownMatchSelectWidth={false}
                            dropdownClassName={style.dropdownANT}
                            onBlur={handleBlur}
                            className={
                                errors.occupation && touched.occupation ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                            }
                        >
                        {
                        employmentTypeTradingAccount.map((item) => {
                            return <Option disabled={item.disabled} key={item.value} value={item.value}>{item.name}</Option>
                        })
                        }
                        </Select>
                    <CustomFormFeedback formProps={formProps} fieldName="occupation" />
                    </div>
                </FormGroup>

                { values.is_joint_employed == "Employed" &&
                <>
                <FormGroup className={style.formGroup}>
                    <Label className={style.key} for="joint_employer_name">Name of Employer</Label>
                    {!showJointEmployeerName && <div
                        onClick={ toggleJointEmployeerName }
                        className={`${style.cursorPointer} ${style.value}`}
                    >
                        {values.joint_employer_name ? values.joint_employer_name :'NA'}
                    </div>}
                    {showJointEmployeerName && <div className={style.errorWithFeedback}>
                        <Input
                            type="text"
                            name="joint_employer_name"
                            id="joint_employer_name"
                            placeholder={'Employeer Name'}
                            value={values.joint_employer_name}
                            onChange={(e) => handleUserDetailsChange("joint_employer_name", e.target.value, null, "discloser")}
                            onBlur={handleBlur}
                            className={errors.joint_employer_name && touched.joint_employer_name ? `is-invalid ${style.inputInvalid}` : style.input2}
                        />
                        {errors.joint_employer_name && touched.joint_employer_name && <div
                        className={style.formInvalidField}>{errors.joint_employer_name}</div>}
                    </div>}
                </FormGroup>
                <FormGroup className={style.formGroup}>
                    <Label className={style.key} for="joint_occupation">Occupation</Label>
                    <div className={style.errorWithFeedback}>
                      <Input
                        type='text'
                        name='joint_occupation'
                        id='joint_occupation'
                        placeholder={'Occupation'}
                        value={values.joint_occupation}
                        onChange={(e) => handleUserDetailsChange("joint_occupation", e.target.value, null, "discloser")}
                        onBlur={handleBlur}
                        className={errors.joint_occupation && touched.joint_occupation ? `is-invalid ${style.inputInvalid}` : `${style.input2} ${style.styledInput}`}
                      />
                      <CustomFormFeedback formProps={formProps} fieldName="joint_occupation" />
                    </div>
                </FormGroup>
                </>
                }
                {/* <FormGroup className={style.formGroup}>
                    <Label className={style.key} for="time_horizon">Number of Years planned to invest to achieve particular financial goal</Label>
                    <Select
                        type="select"
                        name="time_horizon"
                        id="time_horizon"
                        value={values.time_horizon}
                        onChange={(value) => handleUserDetailsChange("time_horizon", value, null, "discloser")}
                        bordered={false}
                        suffixIcon={null}
                        dropdownMatchSelectWidth={false}
                        dropdownClassName={style.dropdownANT}
                        onBlur={handleBlur}
                        className={
                        errors.time_horizon && touched.time_horizon ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                        }
                    >
                        {financialGoalsTradingAccount.map((item) => <Option key={`financial-${item.name}`} disabled={item.disabled} value={item.value}>{item.name}</Option>)}
                    </Select>
                <CustomFormFeedback
                    formProps={formProps}
                    fieldName="time_horizon"
                />
                </FormGroup>
                <FormGroup className={style.formGroup}>
                    <Label className={style.key} for="liquidity_needs">Ability to quickly and easily convert all or a portion of the account assets into cash without experiencing significant loss</Label>
                    <Select
                        type="select"
                        name="liquidity_needs"
                        id="liquidity_needs"
                        value={values.liquidity_needs}
                        onChange={ handleLiquidityNeed }
                        bordered={false}
                        suffixIcon={null}
                        dropdownMatchSelectWidth={false}
                        dropdownClassName={style.dropdownANT}
                        onBlur={handleBlur}
                        className={
                        errors.liquidity_needs && touched.liquidity_needs ? style.inputInvalid : `${style.input} ${style.antSelect} ${style.aum}`
                        }
                    >
                    {
                        liquidityTimeTradingAccount.map((item) =><Option key={`secondary-${item.name}`}  disabled={item.disabled}  value={item.value}>{item.name}</Option>)
                    }
                    </Select>
                    <CustomFormFeedback
                        formProps={formProps}
                        fieldName="liquidity_needs"
                    />
                </FormGroup> */}
            </Fragment>}
            {showUpdate && <div className={`${style.formGroup} ${style.btnFlexEnd}` }>
                <button type="submit" className={style.update}>
                    Update
                </button>
            </div>}
        </Fragment>
    )
}

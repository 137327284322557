import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { searchQueryRequest } from 'actions/discovery';
import { PopularThemes as PopularThemesContent } from 'DataSet';
import { addEventToAnalytics } from 'Utils';
export class PopularThemes extends Component {
  constructor(props){
    super(props);
    this.state = {
      randomNumbers: [],
    }
  }

  componentWillMount() {
    this.generateTwoRandomNumber(this.props.query);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.query !== this.props.query) this.generateTwoRandomNumber(newProps.query);
  }

  generateTwoRandomNumber = (query) => {
    const num1 = this.generateRandomNumber(PopularThemesContent.length - 3, query);
    const num2 = this.generateUniqueSecondNumber(num1, query)
    this.setState({
      randomNumbers : [num1, num2]
    })
  }

  generateRandomNumber = (max, query) => {
    const unique = Math.floor(Math.random() * max);
    if (PopularThemesContent[unique].label.toLowerCase() === query) return this.generateRandomNumber(max, query)
    else return unique;
  }

  generateUniqueSecondNumber = (num1, query) => {
    const uniqNum = this.generateRandomNumber(PopularThemesContent.length - 3);
    if(num1 === uniqNum) return this.generateUniqueSecondNumber(num1);
    if(PopularThemesContent[uniqNum].label.toLowerCase() === query) return this.generateUniqueSecondNumber(num1, query)
    else return uniqNum;
  }

  queryHandler = (query) => {
    const { queryHandler, discovery } = this.props;
    if (query) {
      queryHandler(query, 'popular_themes');
      addEventToAnalytics('Popular Theme','Popular Theme','POPULAR_THEME',{ query: discovery.query, logId: discovery.logId, popularQuery: query },true);
    }
  }

  render() {
    const { loading, className } = this.props;
    const { randomNumbers } = this.state;
    const renderDesign = (randomNumbers && randomNumbers.length) ? (
      randomNumbers.map((ranIndex) => {
        const que = PopularThemesContent[ranIndex].label;
        const img = `/assets/images/popThemes/${PopularThemesContent[ranIndex].image}`;
        const title = PopularThemesContent[ranIndex].label;
        return (
          <div key={que+ranIndex} onClick={this.queryHandler.bind(this, que)} className="popTheme-wrapper__item fin-img">
            <span className="popTheme-wrapper__title">{title}</span>
            <img src={img} />
          </div>
        )
      })
    ) : null;
    return (
      <React.Fragment>
        {!loading
          && (
            <div className={`element ${className}`}>
              <div className="label">Popular Themes</div>
                <div className="popTheme-wrapper">
                {renderDesign}
              </div>
            </div>
          )
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ discovery }) => ({
  discovery
})

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopularThemes)

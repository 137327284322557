import React, { useState } from 'react'
import { keys, maxBy, minBy, values as ldValues } from 'lodash'
import { ButtonGroup, Form, Table, UncontrolledTooltip } from 'reactstrap'
import { PrimaryButton, SecondaryButton } from 'shared-components'
import { SearchOutlined } from '@ant-design/icons'
import SelectorName from './SelectorName'
import CreateSelector from './CreateSelector'
import WeightItem from './WeightItem'
import WeightsPieChart from './WeightsPieChart'

const deriveFinalValues = (values, col, lockedVariables) => {
  let totalLockedValues = 0
  let totalUnlockedValue = 0

  // gather the sum of total locked & unlocked variables
  Object.keys(values).map(key => {
    const intValue = parseInt(values[key], 10)
    if (lockedVariables.includes(key)) {
      totalLockedValues += intValue
    } else if (key !== col) {
      totalUnlockedValue += intValue
    }
  })

  // derive max variable balance for the
  const maxColBalance = 100 - totalLockedValues

  if (values[col] > maxColBalance) {
    values[col] = maxColBalance
  }

  // distribute the weightage based on the values increased
  const availableBalance = maxColBalance - totalUnlockedValue - values[col]
  const totalLockedVariables = [...lockedVariables, col]
  let finalUnlockedValues = 0
  const finalUnlockedVariables = []
  Object.keys(values).map(key => {
    if (!totalLockedVariables.includes(key)) {
      let columnBalance =
        Math.round(
          (values[key] / totalUnlockedValue) * availableBalance + values[key]
        ) || 0
      values[key] = columnBalance
      finalUnlockedValues += columnBalance
      if (values[key] !== 0) {
        finalUnlockedVariables.push({
          [key]: values[key]
        })
      }
    }
  })

  // adjust the lien values on max or min variable for small value changes
  const finalBalanceValues =
    values[col] + totalLockedValues + finalUnlockedValues

  if (finalBalanceValues < 100) {
    const minValueKey = minBy(finalUnlockedVariables, item => ldValues(item)[0])
    const minKey = keys(minValueKey)[0]
    if (minKey) {
      values[minKey] = values[minKey] + (100 - finalBalanceValues)
    }
  } else if (finalBalanceValues > 100) {
    const maxValueKey = maxBy(finalUnlockedVariables, item => ldValues(item)[0])
    const maxKey = keys(maxValueKey)[0]
    if (maxKey) {
      values[maxKey] = values[maxKey] + (100 - finalBalanceValues)
    }
  }

  // derive minimum column balance
  let sumOfUnlockedVariables = 0
  Object.keys(values).map(key => {
    if (!totalLockedVariables.includes(key)) {
      sumOfUnlockedVariables += values[key]
    }
  })

  const minColBalance = 100 - totalLockedValues - sumOfUnlockedVariables

  if (values[col] < minColBalance) {
    values[col] = minColBalance
  }

  return values
}

const ConfigureWeightsTable = ({
  scoreAttrs,
  setScoreAttrs,
  handleSubmit,
  values,
  errors,
  handleChange,
  resetForm,
  loading,
  weightError,
  weightSuccess,
  setValues,
  selectedCalculator,
  selectorName,
  setSelectorName,
  customCalculators,
  lockedVariables,
  setLockedVariables,
}) => {
  const [genSelectorError, setGenSelectorError] = useState('');
  const [genSelectorWarning, setGenSelectorWarning] = useState('');
  const selectorErrorText = genSelectorError || genSelectorWarning || '';
  // const [tempValues, setTempValues] = useState([]);
  const onSliderValueChange = value => {
    setValues({
      ...values,
      ...value
    })
  }

  const afterSliderChange = value => {
    const col = Object.keys(value)[0]
    const normalisedValues = deriveFinalValues(
      {
        ...values,
        ...value
      },
      col,
      lockedVariables
    )
    setValues(normalisedValues)
  }

  const onLockButtonClick = col => {
    const newLockedVariables = [...lockedVariables]
    const colIndex = newLockedVariables.indexOf(col)
    if (colIndex !== -1) {
      newLockedVariables.splice(colIndex, 1)
    } else {
      newLockedVariables.push(col)
    }
    setLockedVariables(newLockedVariables)
  }

  const onResetClick = () => {
    setLockedVariables([])
    resetForm()
  }

  const onEqualizeClick = () => {
    setLockedVariables([])
    const allValues = Object.keys(values);
    let updatedValues = {};
    const totalSum = 100;
    const totalAttrs = allValues.length;
    const leftOverSum = totalAttrs - (totalSum % totalAttrs); 
    const equalPortion = Math.floor(totalSum / totalAttrs);
    allValues.map((item, index) => {
      if(index >= leftOverSum){ 
        updatedValues[item] = equalPortion + 1;
      }else{
        updatedValues[item] = equalPortion;
      }
    });
    setValues(updatedValues);
  }

  const isCreateCalculator = selectedCalculator === 'create custom selector';

  const showSelectorOption = (selectedCalculator === 'create custom selector')
    || (selectedCalculator in customCalculators);

  let saveSelectorError = '';

  if(!selectorName){
    saveSelectorError = 'Please name your selector';
  }else if(!scoreAttrs.length > 0){
    saveSelectorError = 'Please add a metric to save your selector';
  }

  let tempValues = {};
  if(scoreAttrs.length > 0){
    scoreAttrs.forEach(item => {
      tempValues[item.col] = item.weight;
    })
  }
  

  return (
    <React.Fragment>
      {(isCreateCalculator && scoreAttrs.length < 1) ? (
        <div className="onboard-selector">
          <h1>Create Custom Selector</h1>
          <div className="d-flex justify-content-center">
            <SelectorName
              showError
              setSelectorName={setSelectorName}
              setGenSelectorError={setGenSelectorError}
            />
          </div>
          <p className="selector-input-desc">Please select the variables you would like to include in your fund selector</p>
          <CreateSelector
            selectorName={selectorName}
            setSelectorName={setSelectorName}
            currentScoreAttrs={[]}
            selectedCalculator={selectedCalculator}
            setGenSelectorWarning={setGenSelectorWarning}
            setScoreAttrs={setScoreAttrs}
            suffixIcon={<SearchOutlined />}
          />
        </div>
      ) : (
        <Form onSubmit={handleSubmit} noValidate={true}>
          {/* {errors && errors.formAlert && (
            <Alert color="danger" className="m-0">
              {errors.formAlert}
            </Alert>
          )}
          {weightSuccess && (
            <Alert color="success" className="m-0">
              Successfully Updated
            </Alert>
          )}
          {weightError && weightError !== '' && (
            <Alert color="danger" className="m-0">
              {weightError}
            </Alert>
          )} */}

          <div
            className={
              loading == true
                ? 'overlay weight-active weight-overlay'
                : 'overlay weight-deactive weight-overlay'
            }
          >
            <div className="text rounded">
              <i className="fal fa-circle-notch fa-3x fa-spin text-gray-5"></i>
              <h5 className="mt-2 mb-0">Updating weights data</h5>
            </div>
          </div>

          <Table className="m-0 configure-weights-table">
            <tbody>
              <tr>
                <td className="weight-cell-column">
                  <div className="cell-header-section">
                    <div className="modal-addtional-text">
                      <div className="header-wrapper">
                        {!isCreateCalculator ? (
                          <React.Fragment>
                            <h1
                              className="text-align-left"
                              style={{ fontWeight: '600' }}
                            > 
                              Personalize Your Fund Selector
                            </h1>
                            <span
                              className="selector-input-desc"
                              style={{
                                marginBottom: '0px',
                              }}
                            >
                              The weights assigned will be used to generate a score to find
                              the best security from the selected securities -
                              ((variable1*weight1)+(variable2*weight2)…….)/ (sum of weights)
                            </span>
                            {showSelectorOption && (
                              <div>
                                <div className="selector-name-wrapper">
                                  <div className="selector-name">
                                    <span className="name-text">
                                      {selectedCalculator}
                                    </span>
                                  </div>
                                </div>
                                <CreateSelector
                                  selectorName={selectorName}
                                  setSelectorName={setSelectorName}
                                  currentScoreAttrs={values}
                                  selectedCalculator={selectedCalculator}
                                  setGenSelectorWarning={setGenSelectorWarning}
                                  setScoreAttrs={setScoreAttrs}
                                  suffixIcon={<SearchOutlined />}
                                />
                              </div>
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div class="editable-header-text">
                              <h1
                                className="text-align-left"
                                style={{ fontWeight: '600' }}
                              >
                                Create Custom Selector :
                              </h1>
                              <SelectorName
                                selectorName={selectorName}
                                setSelectorName={setSelectorName}
                                setGenSelectorError={setGenSelectorError}
                              />
                            </div>
                            <p className="selector-input-desc">Please select the variables you would like to include in your fund selector</p>
                            <CreateSelector
                              selectorName={selectorName}
                              setSelectorName={setSelectorName}
                              currentScoreAttrs={tempValues}
                              selectedCalculator={selectedCalculator}
                              setGenSelectorWarning={setGenSelectorWarning}
                              setScoreAttrs={setScoreAttrs}
                              suffixIcon={<SearchOutlined />}
                            />
                            <span
                              className="selector-input-desc"
                              style={{
                                marginTop: '10px',
                                marginBottom: '0px',
                              }}
                            >
                              The weights assigned will be used to generate a score to find
                              the best security from the selected securities -
                              ((variable1*weight1)+(variable2*weight2)…….)/ (sum of weights)
                            </span>
                          </React.Fragment>
                        )}
                      </div>                      
                    </div>
                    <div className={`weights-pie-chart-wrapper ${isCreateCalculator ? 'adjustAlign' : ''}`}>
                      <WeightsPieChart dataObject={values} />
                      {scoreAttrs.length > 0 && (
                        <span
                          className="equalize-weight"
                          onClick={onEqualizeClick}
                        >
                          Equalize Weights
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="weight-cell-container">
                    <div className="right-line"></div>
                    <div className="bottom-line"></div>
                    <div className="weight-cell-wrapper d-flex">
                      {scoreAttrs.map((attr, index) => {
                        const inputValue =
                          typeof values[attr.col] === 'number'
                            ? values[attr.col]
                            : 0
                        return (
                          <WeightItem
                            key={attr.col}
                            attr={attr}
                            onSliderValueChange={onSliderValueChange}
                            afterSliderChange={afterSliderChange}
                            inputValue={inputValue}
                            setValues={setValues}
                            allValues={values}
                            onLockButtonClick={onLockButtonClick}
                            lockedVariables={lockedVariables}
                            uniqID={index}
                          />
                        )
                      })}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="weight-cell-column footer">
                  {selectedCalculator === 'create custom selector' ? (
                    <ButtonGroup className="justify-content-center w-100">
                      <SecondaryButton
                        type="button"
                        className="btn-link btn-reset"
                        onClick={onResetClick}
                        style={{
                          maxWidth: '203px',
                          cursor: 'pointer',
                          color: '#1e5f91',
                        }}
                      >
                        Reset
                      </SecondaryButton>
                      <span id={'tooltip-error'}>
                        <PrimaryButton
                          type="submit"
                          className="btn-link btn-update"
                          style={{
                            width: '203px',
                            maxWidth: '203px',
                            cursor: 'pointer',
                          }}
                          disabled={!selectorName || genSelectorError || (!scoreAttrs.length > 0)}
                        >
                          Save
                        </PrimaryButton>
                      </span>
                      {saveSelectorError &&
                        <UncontrolledTooltip                    
                          className="save-selector-error" 
                          placement="bottom"
                          target={'tooltip-error'}
                        >
                          {saveSelectorError}
                        </UncontrolledTooltip>
                      }              
                    </ButtonGroup>
                  ) : (
                    <ButtonGroup className="justify-content-center w-100">
                      <SecondaryButton
                        type="button"
                        className="btn-link btn-reset"
                        onClick={onResetClick}
                        style={{
                          maxWidth: '203px',
                          cursor: 'pointer',
                          color: '#1e5f91'
                        }}
                      >
                        Reset
                      </SecondaryButton>
                      <PrimaryButton
                        type="submit"
                        className="btn-link btn-update"
                        style={{ maxWidth: '203px', cursor: 'pointer' }}
                      >
                        Update
                      </PrimaryButton>
                    </ButtonGroup>
                  )}
                  <div className="gen-selector-errors">
                    {showSelectorOption && (
                      <span>{selectorErrorText}</span>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Form>
      )}
    </React.Fragment>
  )
}

export default ConfigureWeightsTable;
import React from 'react';
import { connect } from 'react-redux';
import TabComponents from  './TabComponents/index';
import { addEventToAnalytics } from 'Utils';

class SelectedModel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {};
  }

  moveBack = () => {
    addEventToAnalytics('Client Allocate Search Back', 'Client Allocate Search Back', 'CLIENT_ALLOCATE_SEARCH_BACK', {}, false)
    const { moveBackTo, searchedQuery } = this.props;
    if (moveBackTo && typeof searchedQuery !== 'undefined' && searchedQuery !== '') {
      moveBackTo(searchedQuery);
    }
  }

  getModalHeading = () => {
    const {modelSearchResults, selectedModel, modelSearchQuery, noSearchResults, searchedQuery } = this.props;
    let heading;
    if(Object.keys(modelSearchResults).length){
      heading = <span>Showing Model Portfolios For <span>"{modelSearchQuery}"</span></span>;
    }else if(noSearchResults){
      heading = <span>We Could Not Find Results For "{modelSearchQuery}"</span>
    }else if (selectedModel){
      heading = (typeof searchedQuery !== 'undefined' && searchedQuery !== '') ? (
        <span style={{ paddingLeft: 25 }} onClick={() => this.moveBack()}><i class="fas fa-redo"></i> {'Selected Allocation : ' + selectedModel}</span>
      ) : (
        <span>{'Selected Allocation : ' + selectedModel}</span>
      );
    }else{
      heading = 'Pending Allocation';
    }
    return heading
  }

  render() {
    const { selectedModel, selectedClient, selectedTabIndex, setSelectedTabIndex, modelSearchResults } = this.props
    return (
      <React.Fragment>
        <div className="model-panel">
          <div className="model-header sm">
            <h4 className="pos-rel">{this.getModalHeading()}</h4>
          </div>
          <TabComponents {...this.props} />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ clientportfolio: { modelSearchResults, modelSearchQuery, noSearchResults } }) => ({
  modelSearchResults,
  modelSearchQuery,
  noSearchResults,
})

export default connect(
  mapStateToProps
)(SelectedModel);

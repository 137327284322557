import React from 'react'
import { Modal, ModalBody, ButtonGroup } from 'reactstrap'
import { PrimaryButton } from 'shared-components'

const LoginWall = ({ onToggle, history }) => {
  const onClose = () => {
    onToggle()
  }

  const onRegisterClick = () => {
    history.push({
      pathname: '/register',
      state: {
        from: 'premium-modal'
      }
    })
  }
  
  return (
    <Modal
      isOpen
      centered
      keyboard={true}
      backdrop
      className="premium-msg-modal premium-feature-process-modal-container"
      backdropClassName="premium-feature-process-modal-backdrop"
    >
      <ModalBody className="ssf-modal__body" style={window.screen.width > 767 ? {padding: '3rem'} : {padding: '1rem'}}>
        <div className="premium-feature-process-modal-container text-center">
          <div className="header-wrapper">
            <h1 className="text-align-center pb-1">
              You have discovered a premium feature!
            </h1>
            <p className="fs-18" style={{marginTop: '-5px'}}>Try it out for free by registering.</p>
          </div>
          <i
            className="fal fa-times-circle premium-feature-completed-close"
            onClick={onClose}
          ></i>
        </div>
        <div className={`${window.screen.width > 767 && "d-flex"} w-100 justify-content-center pt-4`}>
          <ButtonGroup className="cta-wrapper justify-content-center">
            <PrimaryButton
              className={`btn secondary-btn p-1 ${window.screen.width > 767 && 'mr-2'} download-modal-btn`}
              type="button"
              style={window.screen.width < 767 ? { minWidth: 160, marginLeft: '0.5rem' } : { minWidth: 160 }}
              onClick={onRegisterClick}
            >
              REGISTER
            </PrimaryButton>
          </ButtonGroup>
          <ButtonGroup className="cta-wrapper justify-content-center">
            <PrimaryButton
              className="btn secondary-btn p-1 ml-2 download-modal-btn"
              type="button"
              style={{ minWidth: 160 }}
              onClick={onToggle}
            >
              NO, THANK YOU
            </PrimaryButton>
          </ButtonGroup>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default LoginWall

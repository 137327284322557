import React, { Component, Fragment } from 'react'
import { Row, Col } from 'reactstrap';
import D3Sunburst from './common/D3Sunburst'
import { pieColors, regionColors } from './common/colors';
import { SimplePieChart } from './common/PieChart'
import GaugeChart from './common/GaugeChart'
import { CarouselChart } from './common/CarouselChart'
import NotApplicable from './NotApplicable'
import {
  RegionChartMapping,
  sectorsNames,
  SectorChartMapping,
  isViewPresentInChartTypes,
} from './Utils'

const validChartTypes = ['odometer', 'burst_chart', 'pie_chart', 'carousel']
const devirsificationKeys = {
  Holdings: 'n_hldgs',
  Countries: 'n_countires',
  Sectors: 'n_sectors',
}

const formatNumberValue = (value, fraction) => {
  const leftPart = (value + '').split('.')[0]

  if (leftPart.length >= 4 && leftPart.length < 7) {
    return (value / 1000).toFixed(fraction) + 'K'
  } else if (leftPart.length >= 7 && leftPart.length < 10) {
    return (value / 1000000).toFixed(fraction) + 'M'
  } else if (leftPart.length >= 10) {
    return (value / 1000000000).toFixed(fraction) + 'B'
  } else {
    return value.toFixed(fraction)
  }
}

const capitalize = (str) => {
  if (str.length) {
    return str[0].toUpperCase() + str.slice(1)
  }
  return str
}

const baseURL = 'https://api.magnifi.com/'
const version = 'search'

// - Holding IBM
// - Funds that do well in falling markets
// - Thematic funds
// - Funds that do well when markets fall
// - Thematic funds

class ResultCharts extends Component {  

  constructor(props) {
    super(props)
    this.state = {
      results: this.props.allResults
    }
  }  

  burstRefs = []


  isDynamicChart(view, query_var) {
    // staticChart === false && chartType === "odometer"
    // let chartData =  card.chartData.length ? card.chartData.filter((item) => {
    //   return item.display_view_name === view
    // }) : [];
    // if(chartData.length){
    if (!query_var.Static && query_var.chart_type === 'odometer') {
      return true;
    }
    // }
    return false;
  }

  renderChart = (item, i) => {
    const { results } = this.state;
    const staticVal = this.state.results.query_var && this.state.results.query_var[0] ?
      this.state.results.query_var[0].Static : false;

    const viewName = this.state.results.query_var && this.state.results.query_var[0] ?
      this.state.results.query_var[0].view_name : '';

    if (!results[viewName] || Object.keys(results[viewName]).length === 0 || this.state.results.query_var.length === 0) return this.renderDefaultAsset(item, i);

    if (!staticVal && !isViewPresentInChartTypes(viewName) && this.isDynamicChart(viewName, this.state.results.query_var[0])) return this.DynamicGuageChart(item);
    if (staticVal && ((viewName === "Top Holdings") || (viewName === "Asset Allocation") || (viewName === "Asset Allocation"))) return this.renderPieChart(item);
    if (staticVal && viewName === "Regions") return this.renderBurst(item, i);
    if (viewName === "Themes") return this.renderCarousel(item);

    const type = this.state.results.query_var && this.state.results.query_var[0] ?
      this.state.results.query_var[0].chart_type : null;
    switch (type) {
      case 'odometer':
        return this.renderOdometer(item);
      case 'burst_chart':
        return this.renderBurst(item, i);
      case 'pie_chart':
        return this.renderPieChart(item);
      case 'carousel':
        return this.renderCarousel(item);
      default:
        return null;
    }
  };

  DynamicGuageChart = (item) => {
    const { results } = this.state;
    const { view_name, Secondary_view_name, decimal, suffix, multiplier } = this.state.results.query_var[0];

    const tickerData = results[view_name][item.ticker];
    if (!tickerData) return <NotApplicable />;

    const mult = multiplier !== '' ? multiplier : 1;
    let name = view_name + (Secondary_view_name ? ` (${Secondary_view_name})` : '');
    name = name.replace('ticker', results.query_var[0].data);


    let _min = tickerData.min * mult,
      _max = tickerData.max * mult,
      _value = tickerData.value * mult;

    return (
      <div className="siw-meter">
        {/*<span className="siw-meter-title" title={name}>{name}</span>*/}
        <GaugeChart
          value={_value}
          max={_max}
          min={_min}
        />
        <span className="siw-meter-value">
          {_value.toFixed(decimal)}{suffix}
        </span>
        <span className="siw-meter-min">
          {_min.toFixed(decimal)}{suffix}
        </span>
        <span className="siw-meter-max">
          {_max.toFixed(decimal)}{suffix}
        </span>
      </div>
    );
  }

  renderDefaultAsset = (item) => {
    const { results } = this.state;
    const tickerData = results['Assets'][item.ticker];
    if (!tickerData) return <NotApplicable />;
    
    const min = tickerData.min;
    const max = tickerData.max;
    const value = tickerData.value;

    const minRender = formatNumberValue(min);
    const maxRender = formatNumberValue(max);
    const valueRender = formatNumberValue(value);

    return (
      <div className="siw-meter">
        {/*<span className="siw-meter-title" title="Assets">Assets</span>*/}
        <GaugeChart
          value={value}
          max={max}
          min={min}
        />
        <span className="siw-meter-value">
          {valueRender}
        </span>
        <span className="siw-meter-min">
          {minRender}
        </span>
        <span className="siw-meter-max">
          {maxRender}
        </span>
      </div>
    );
  }

  renderOdometer = (item) => {
    const { results } = this.state;
    const { view_name, Secondary_view_name, decimal, suffix } = this.state.results.query_var[0];

    const tickerData = results[view_name][item.ticker];
    if (!tickerData) return <NotApplicable />;

    const currentMultiplier = tickerData.multiplier || 1;
    const currentSuffix = view_name !== "Volume" ? (tickerData.suffix || suffix || '') : '';
    const currentDecimal = tickerData.decimal || decimal || 1;
    let name = view_name + (Secondary_view_name ? ` (${Secondary_view_name})` : '');
    name = name.replace('ticker', results.query_var[0].data);

    let min, max, value;
    if (view_name === 'Return Quality') {
      // implementing like seach after discussing with business
      const subField = tickerData.find(type => Secondary_view_name.includes(type.name));
      if(!subField) return <NotApplicable />
      min = (subField.min * 100).toFixed(2);
      max = (subField.max * 100).toFixed(2);
      value = (subField.value * 100).toFixed(2);
    } else {
      min = tickerData.min;
      max = tickerData.max;
      value = tickerData.value;
    }

    let minWithMultiplier = (min * currentMultiplier);
    let maxWithMultiplier = (max * currentMultiplier);
    let valueWithMultiplier = (value * currentMultiplier);
    let minRender, maxRender, valueRender;

    if (results.query_var[0].chart_type === 'odometer') {
      if (view_name === 'Assets') {
        minRender = formatNumberValue((min / 1000), currentDecimal);
        maxRender = formatNumberValue((max / 1000), currentDecimal);
        valueRender = formatNumberValue((value / 1000), currentDecimal);
      } else
        if (view_name === 'Return Quality') {
          // implementing like seach after discussing with business
          const subField = tickerData.find(type => Secondary_view_name.includes(type.name));
          minRender = (subField.min * currentMultiplier).toFixed(currentDecimal) + (currentSuffix || '');
          maxRender = (subField.max * currentMultiplier).toFixed(currentDecimal) + (currentSuffix || '');
          valueRender = (subField.value * currentMultiplier).toFixed(currentDecimal) + (currentSuffix || '');
        } else {
          minRender = formatNumberValue(minWithMultiplier, currentDecimal) + currentSuffix;
          maxRender = formatNumberValue(maxWithMultiplier, currentDecimal) + currentSuffix;
          valueRender = formatNumberValue(valueWithMultiplier, currentDecimal) + currentSuffix;
        }
    } else {
      minRender = minWithMultiplier.toFixed(currentDecimal) + currentSuffix;
      maxRender = maxWithMultiplier.toFixed(currentDecimal) + currentSuffix;
      valueRender = valueWithMultiplier.toFixed(currentDecimal) + currentSuffix;
    }

    return (
      <div className="siw-meter">
        {/*<span className="siw-meter-title" title={name}>{name}</span>*/}
        <GaugeChart
          value={value}
          max={max}
          min={min}
        />
        <span className="siw-meter-value">
          {valueRender}
        </span>
        <span className="siw-meter-min">
          {minRender}
        </span>
        <span className="siw-meter-max">
          {maxRender}
        </span>
      </div>
    );
  };

  renderBurst = (item, i) => {
    const { results } = this.state;
    const { view_name, node, ontology_node } = results.query_var[0];
    let centerDisplayText, centerValueText;
    const displayText = RegionChartMapping.filter(e => e.chart_name === node);
    centerDisplayText = displayText.length ? displayText[0].region_name : '';
    centerValueText = `${item[ontology_node] ? item[ontology_node].toFixed(1) : ''}%`;

    if (results[view_name][item.ticker].length === 0) return <NotApplicable />;

    const data = {
      name: 'Allocations',
      children: results[view_name][item.ticker].map(group => ({
        size: 0,
        name: group.n,
        children: group.sub.map(groupItem => ({
          name: (group.n == 'Others') ? 'Others ' : groupItem.n,
          size: groupItem.v,
        })),
      })),
    };

    const primaryTextStyle = (view_name === 'Regions') ? {
      // top: '39%',
      fontSize: 11,
      fontWeight: 'bold',
      zIndex: '5',
    } : {};

    return (
      <div className="siw-pie-holder">

        {/*<span
          className="siw-pie-title"
          title={centerDisplayText}
        >
          {centerDisplayText !== '' ? `% ${centerDisplayText}` : view_name}
        </span>*/}

        <div className="siw-d3ChartWrapper">
          {(view_name === 'Regions') && centerDisplayText !== ''
            && (
              <div className="primaryTextStyle" style={primaryTextStyle}
              >
                {centerValueText}
                <div className="highlightTooltip">{centerDisplayText}: {centerValueText}</div>
              </div>
            )
          }
          <D3Sunburst
            width={70}
            height={70}
            colors={regionColors}
            data={data}
            ref={el => this.burstRefs[i] = el}
            clickHandler={this.handleBurstClick}
            activeSegment="Allocations"
          />
        </div>
      </div>
    );
  };

  renderRegionsChart = (item, i) => {
    const { results } = this.state;

    if (results['Regions'][item.ticker].length === 0) return <NotApplicable />;

    const data = {
      name: 'Allocations',
      children: results['Regions'][item.ticker].map(group => ({
        size: 0,
        name: group.n,
        children: group.sub.map(groupItem => ({
          name: (group.n == 'Others') ? 'Others ' : groupItem.n,
          size: groupItem.v,
        })),
      })),
    };

    const primaryTextStyle = {
      // top: '39%',
      fontSize: 11,
      fontWeight: 'bold',
      zIndex: '5',
    };

    return (
      <div className="siw-pie-holder">

        {/*<span
          className="siw-pie-title"
          title="Regions"
        >
          Regions
          </span>*/}

        <div className="siw-d3ChartWrapper">
          <div className="primaryTextStyle" style={primaryTextStyle} >
          </div>
          <D3Sunburst
            width={70}
            height={70}
            colors={regionColors}
            data={data}
            ref={el => this.burstRefs[i] = el}
            clickHandler={this.handleBurstClick}
          />
        </div>
      </div>
    );
  };

  handleBurstClick = (node) => {
    const name = node.data.name;
    this.burstRefs.forEach(burstRef => {
      const searchNode = burstRef.clickableNodes.find(node => node.data.name === name);
      if (searchNode) {
        burstRef.clicked(searchNode);
      }
    });
  };

  renderPieChart = (item) => {
    const { results } = this.state;
    const { view_name, Secondary_view_name, node, variable, chart_center_abovetext, chart_center_belowtext, } = results.query_var[0];
    let sum = 0.0;
    let data = [];
    let topTitle = view_name + (Secondary_view_name ? ' ' + Secondary_view_name : '');
    let title = '';
    let decimal = 0, centerDisplayText, centerValueText, dataVar;
    if (view_name === 'Diversification') {
      let key = devirsificationKeys[Secondary_view_name];
      let chartData = results[view_name].find(t => t.ticker === item.ticker);
      if (!chartData) return <NotApplicable />;
      // title = chartData[key] + ' ' + Secondary_view_name;
      let top20Value = chartData.top_20_prct_coverage;
      data = [
        {
          name: 'Percentage help by top 20% positions',
          value: top20Value * 100,
        },
        {
          name: 'Percentage help by lower 80% positions',
          value: (1 - top20Value) * 100,
        },
      ]
      centerDisplayText = topTitle;
      centerValueText = chartData.n_countries || '';
    } else
      if (view_name === 'Asset Allocation') {
        let chartData = results[view_name].find(t => t.ticker === item.ticker);
        if (!chartData) return <NotApplicable />;

        chartData && Object.keys(chartData).forEach(key => {
          if (key === 'ticker') return;
          data.push({
            name: capitalize(key),
            value: +chartData[key],
          });
        })
      } else if (view_name === 'Top Holdings') {
        const resultData = results[view_name].funds || []
        let chartData = resultData.find(t => t.ticker === item.ticker);
        if (!chartData) return <NotApplicable />;
        const totalSum = (chartData && chartData.holdings.reduce((total, current) => total + current.weight, 0.0)) || 0;
        decimal = 1;

        if (Object.prototype.toString.call(results.query_var[0].data) === '[object Array]') {
          dataVar = results.query_var[0].data[0];
        }
        else if (typeof results.query_var[0].data === 'string') {
          dataVar = results.query_var[0].data;
        }
        const centerValue = item[variable];
        centerValueText = chart_center_abovetext.replace('value', centerValue.toFixed(1));
        centerDisplayText = chart_center_belowtext.replace('ticker', dataVar);

        data = chartData.holdings.map(d => ({
          name: d.name,
          value: d.weight
        }));
        data.push({
          name: "Others",
          value: 100 - totalSum.toFixed(decimal),
          color: '#ddd'
        })
      } else {
        sectorsNames.forEach((name) => {
          const Sectors = this.state.results.Sectors;
          const value = Sectors[item.ticker][name];
          if (!value) return <NotApplicable />;

          if (value && value > 0) {
            const labelName = SectorChartMapping.filter(e => e.chart_name === name);
            data.push({
              name: labelName.length ? labelName[0].sector_name : '',
              value,
            });
          }
        });
        const displayText = SectorChartMapping.filter(e => e.chart_name === node);
        centerDisplayText = displayText.length ? displayText[0].sector_name : '';
        centerValueText = displayText.length ? `${item[displayText[0].chart_name].toFixed(1)}%` : '';
      }

    data = data.map(v => {
      return { name: v.name, value: (Math.abs(v.value)), originalValue: (v.value) }
    });

    return (
      <div className="siw-pie-holder">
        {/*<span
          className="siw-pie-title"
        >
          {centerDisplayText && centerDisplayText !== '' ? `% ${centerDisplayText}` : topTitle}
        </span>*/}

        {title && (
          <span className="siw-pie-center-text">{title}</span>
        )}
        <SimplePieChart
          data={data}
          colors={view_name === 'Diversification' ? ['rgb(39,147,232)', '#ddd'] : pieColors}
          decimal={decimal}
          centerDisplayText={centerDisplayText}
          centerValueText={centerValueText}
          chartName={view_name}
        />
      </div>
    );
  };

  renderCarousel = (item) => {
    const { results } = this.state;
    const { view_name, Secondary_view_name } = results.query_var[0];
    let topTitle = view_name + (Secondary_view_name ? ' ' + Secondary_view_name : '');
    let themes = results.Themes[item.ticker];
    if (!themes) return <NotApplicable />;
    themes = [themes[1], themes[0], themes[2]];

    return (
      <div className="siw-carouse-holder">
        {/*<span
          className="siw-pie-title"
          title={topTitle}
        >
          {topTitle}
        </span>*/}
        <CarouselChart data={themes} />
      </div>
    );
  };  

  renderItem = (item, i) => {
    const { results } = this.state;

    return (
      <div className="siw-result-item-holder">
        {results.query_var.length > 0 && results.query_var[0].active && validChartTypes.includes(results.query_var[0].chart_type) &&
          <div
            className="siw-meter-col"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {this.renderChart(item, i)}
          </div>}
        {results.query_var.length === 0 &&
          <div
            className="siw-meter-col"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {this.renderDefaultAsset(item, i)}
          </div>}
      </div>
    )
  };

  render() {
    const {
      item
    } = this.props
    return (
      <div className={`siw-1-root`} id="siw-2-2019">
        <div className="siw-1-results">
          {this.renderItem(item, item.index)}
        </div>
      </div>
    )
  }
}

export default ResultCharts

import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Row, Col, Card, Tabs } from 'antd';
// import { CardBody, Tooltip } from 'reactstrap';
import { CapitalizeEachWord } from 'Utils';
// import { CN  } from 'DataSet';

import { SwapOutlined } from '@ant-design/icons';

import { deletePortfolioEnhanceReplace, setModelEnhancementData } from 'actions/portfolio';
// import { getPortfolioStats, getInvestmentWatchlistStats, getInvestmentPositionStats } from 'selectors/investment';

// import AutoCompleteJJ from '../AutComplete';
import ItemList from './ItemList';
import ItemCard from './ItemCard';
// import { Loader } from '../shared/Loader';

const { TabPane } = Tabs;
// const { Option } = AutoComplete;

class CardRow extends Component {

  constructor(props){
    super(props)
    this.state = {
      resetEnhanceFundCheckStatus: false,
      resetCustomAddedFund: false,
      activeRegion: '',
      // portfolioFunds: props.portfolioFunds,
      modelEnhancement:{
        current: {},
        enhanced: {},
        otherEnhancements: [],
      },
    };
  }

  componentDidMount() {
    // if (this.props.portfolioFunds && this.props.portfolioFunds.length > 0) {
    //   const portfolioFunds = JSON.parse(JSON.stringify(this.props.portfolioFunds));
    //   portfolioFunds.map((r) => {
    //     r.weight = Math.round(r.weight*100);
    //   });
    //   this.setState({
    //     portfolioFunds,
    //   })
    // }
    this.props.setModelEnhancementData(this.state.modelEnhancement)
  }

  componentDidUpdate(prevProps) {
    if(prevProps.replacePortfolioWith.ticker !== this.props.replacePortfolioWith.ticker){
      this.props.setModelEnhancementData(this.state.modelEnhancement)
    }
  }

  onSelectTab = (value) => {
    console.log(value);
  }

  setModelEnhancement = (modelEnhancement) => {
    this.setState({modelEnhancement});
  }

  resetCheckBoxFor = (mode) => {
    switch (mode) {
      case 'enhanceFund':
      this.setState({
        resetEnhanceFundCheckStatus: false,
      });
      break;
      case 'customAddedFund':
      this.setState({
        resetEnhanceFundCheckStatus: true,
      });
      break;
      default:
      break;
    }
  }

  resetReplaceTicker = (replaceTicker, ticker, query, calledFrom) => {
    console.log(replaceTicker, ticker, query, calledFrom);
  }

  setReplaceTicker1 = (e, replaceTicker, ticker, query) => {
    const val = document.getElementById(e.target.id).checked;
    console.log(val, replaceTicker, ticker, query);
  }

  searchFundsActiveRegion = (activeRegion) => {
    this.setState({
      activeRegion
    });
  }

  render() {
    const { query, profile, currentPerformanceImpact, enhancePortfolio, readableQuery, portfolioQuery } = this.props;
    const { myCurrentPortfolio, fundUniverse } = enhancePortfolio;
    const { resetEnhanceFundCheckStatus, portfolioFunds, activeRegion, modelEnhancement } = this.state;
    let { _NEW_QUERY_VAR_OBJECT } = enhancePortfolio.replacePortfolioOptions;

    let currentPortfolioNameInitial = '';
    if (myCurrentPortfolio && typeof myCurrentPortfolio.portfolioDetails !== 'undefined') {
      const { portfolioDetails } = myCurrentPortfolio;

      if (portfolioDetails.profile.firstName || portfolioDetails.profile.lastName) {
        if (portfolioDetails.profile.firstName) {
          currentPortfolioNameInitial = portfolioDetails.profile.firstName[0];
        }
        if (portfolioDetails.profile.lastName) {
          currentPortfolioNameInitial = currentPortfolioNameInitial+portfolioDetails.profile.lastName[0];
        }
      } else {
        currentPortfolioNameInitial = 'N/A';
      }
    }

    return (
      <React.Fragment>
        <Row className="enhance-result-page-container">
					<Col className="static-card-wrapper">
						<div className="static-card-label" id="short_name_tooltip">Selected Portfolio</div>
            <Card className="static-card-body">
              <ItemCard
                i={1}
                universe={''}
                staticCard
                className="model-portfolio-item-card"
                data={myCurrentPortfolio}
                portfolioQuery={portfolioQuery}
                universe={fundUniverse || ''}
                currentPortfolioNameInitial={currentPortfolioNameInitial}
                highlights={enhancePortfolio.replacePortfolioOptions.Highlights}
                query={query}
                ticker={null}
                activeRegion={activeRegion}
                defaultFRR={_NEW_QUERY_VAR_OBJECT._defaultFRR}
                relevanceChartTabs={_NEW_QUERY_VAR_OBJECT._relevanceChartTabs}
                originalQueryVar={_NEW_QUERY_VAR_OBJECT._originalQueryVar}
                searchFundsActiveRegion={this.searchFundsActiveRegion}
                modelEnhancement={modelEnhancement}
                setModelEnhancement={this.setModelEnhancement}
              />
            </Card>
					</Col>
          <Col className="swap-icon-col">
            <SwapOutlined className="swap-icon-wrapper" />
          </Col>
					<Col className="dynamic-card-wrapper">
						<Tabs className="card-tab-container" defaultActiveKey="1" tabBarGutter={32} onChange={this.onSelectTab}>
							<TabPane className="tabPaneClass" tab={CapitalizeEachWord(readableQuery)} key="1">
								<Card className="dynamic-card-body">
									<ItemList
										staticCard={false}
                    activeRegion={activeRegion}
                    currentPortfolioNameInitial={currentPortfolioNameInitial}
										data={enhancePortfolio.replacePortfolioOptions}
                    universe={fundUniverse || ''}
										query={query}
										ticker={null}
                    portfolioQuery={portfolioQuery}
										resetCheck={resetEnhanceFundCheckStatus}
										resetCheckBoxFor={this.resetCheckBoxFor}
                    searchFundsActiveRegion={this.searchFundsActiveRegion}
                    modelEnhancement={modelEnhancement}
                    setModelEnhancement={this.setModelEnhancement}
									/>
								</Card>
							</TabPane>
						</Tabs>
					</Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ investment, portfolio, profile }) => ({
  view: investment.view,
  // currentPortfolioName: investment.activeAccount,
  portfolioFunds: investment.portfolioFunds,
  currentPerformanceImpact: portfolio.currentPerformanceImpact,
  profile: profile.user,
  enhancePortfolio: portfolio.enhancePortfolio,
  readableQuery: portfolio.query_english,
  portfolioQuery: portfolio.query,
  replacePortfolioWith: portfolio.enhancePortfolio.replacePortfolioWith,
})

const mapDispatchToProps = {
  deletePortfolioEnhanceReplace,
  setModelEnhancementData
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardRow)

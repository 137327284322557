import React, { Component } from 'react'
import NewSettings from './Settings.js'
class Renderer extends Component {
  render() {
    return (
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        <NewSettings {...this.props}/>       
      </div>
    )
  }
}

export default Renderer

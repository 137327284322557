import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux';
import { Link , withRouter } from 'react-router-dom';
import { Badge } from 'antd';
import { UserMenu } from './user/UserMenu';
import { REDIRECT_DOMAIN_PATH } from 'DataSet';
import * as AuthUtils from 'common/auth-utils'
import { setSFMState } from 'actions/sfm';
import {addEventToAnalytics} from '../Utils';
// import {changeLogo} from '../Utils';

class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isProfile: true,
      isAccount: true,
    };
  }

  componentDidMount(){
  }

  componentWillMount() {
    let path = window.location.pathname
    if (path === '/settings/referral') {
      this.setState({ isProfile: false })
    }
    if (path === '/investments') {
      this.setState({ isAccount: false })
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.match.path === '/settings') {
      this.setState({ isProfile: false })
    }
    if (newProps.match.path === '/investments') {
      this.setState({ isAccount: false })
    }
  }

  redirect = () => {
    this.props.history.push('/settings/referral?invite=true')
  }

  redirectToAccount = () => {
    addEventToAnalytics('Header Enhancer','Header Enhancer','HEADER_ENHANCER',{url: window.location.href},false);
    this.props.history.push('/investments')
  }

  redirectToBuildPortfolio = () => {
    addEventToAnalytics('Header Builder','Header Builder','HEADER_BUILDER',{url: window.location.href},false);
    this.props.history.push('/build-portfolio');
  }
  
  onSelectorClick = () => {
    addEventToAnalytics('Header Selector','Header Selector','HEADER_SELECTOR',{url: window.location.href},false);
    this.props.setSFMState({
      isRedirectFromHeaderButtons: true
    })
    this.redirectToSearchResult();
  }

  redirectToSearchResult = () => {
    addEventToAnalytics('Header Discovery','Header Discovery','HEADER_DISCOVERY',{url: window.location.href},false);
    this.props.history.push('/securities');
  }

  render() {
    const { height, logo, sfmItems } = this.props;
    const { firstName = '', lastName = '' } = AuthUtils.getAuthToken()
    const userName = `${firstName} ${lastName}`
    const shortName = `${firstName && firstName[0]}${firstName && lastName[0]}`
    const selectorCount = sfmItems.filter((v) => v._builder === false).length;
    console.log(window.innerWidth);
    let renderDesign
    // if (this.props.isLoggedIn && this.state.isProfile) {
    //   renderDesign = (
    //     <a className="referral-link" onClick={this.redirect}>
    //       Referrals
    //     </a>
    //   )
    // }
    if (
      this.props.isLoggedIn &&
      this.state.isAccount &&
      window.innerWidth >= 1920
    ) {
      renderDesign = (
        <div
          className="d-flex justify-content-start portfolio-tools-wrap"
          style={{ width: '65%' }}
        >
          <Badge className='tool-badge'><a className='tools-link' onClick={this.redirectToSearchResult}><img src='/assets/images/icons/Discover.svg' alt='' />Discovery</a></Badge>,
          <Badge count={selectorCount} className='tool-badge'><a className='tools-link' onClick={this.onSelectorClick}><img src='/assets/images/icons/Selector.svg' alt='' />Selector</a></Badge>
          <Badge count={this.props.count} className='tool-badge'><a className='tools-link' onClick={this.redirectToBuildPortfolio}><img src='/assets/images/icons/New-BuilerIcon-Blue.svg' alt='' />Builder</a></Badge>
          <Badge className="tool-badge">
            <a className="tools-link" onClick={this.redirectToAccount}>
            <img src='/assets/images/icons/Enhancer.svg' alt='' />Enhancer
            </a>
          </Badge>
        </div>
      )
    }
    if (
      this.props.isLoggedIn &&
      this.state.isAccount &&
      window.innerWidth >= 1024 && window.innerWidth < 1920
    ) {
      renderDesign = (
        <div
          className="d-flex justify-content-start portfolio-tools-wrap"
          style={{ width: '73%' }}
        >
          <Badge className='tool-badge'><a className='tools-link' onClick={this.redirectToSearchResult}><img src='/assets/images/icons/Discover.svg' alt='' />Discovery</a></Badge>,
          <Badge count={selectorCount} className='tool-badge'><a className='tools-link' onClick={this.onSelectorClick}><img src='/assets/images/icons/Selector.svg' alt='' />Selector</a></Badge>
          <Badge count={this.props.count} className='tool-badge'><a className='tools-link' onClick={this.redirectToBuildPortfolio}><img src='/assets/images/icons/New-BuilerIcon-Blue.svg' alt='' />Builder</a></Badge>
          <Badge className="tool-badge">
            <a className="tools-link" onClick={this.redirectToAccount}>
            <img src='/assets/images/icons/Enhancer.svg' alt='' />Enhancer
            </a>
          </Badge>
        </div>
      )
    }
    if (
      this.props.isLoggedIn &&
      this.state.isProfile &&
      this.state.isAccount &&
      window.innerWidth >= 1024
    ) {
      renderDesign = (
        <>
          <div
            className="d-flex justify-content-start portfolio-tools-wrap"
            style={{ width: '73%' }}
          >
            <Badge className='tool-badge'><a className='tools-link' onClick={this.redirectToSearchResult}><img src='/assets/images/icons/Discover.svg' alt='' />Discovery</a></Badge>,
            <Badge count={selectorCount} className='tool-badge'><a className='tools-link' onClick={this.onSelectorClick}><img src='/assets/images/icons/Selector.svg' alt='' />Selector</a></Badge>
            <Badge count={this.props.count} className='tool-badge'><a className='tools-link' onClick={this.redirectToBuildPortfolio}><img src='/assets/images/icons/New-BuilerIcon-Blue.svg' alt='' />Builder</a></Badge>
            <Badge className="tool-badge">
              <a className="tools-link" onClick={this.redirectToAccount}>
              <img src='/assets/images/icons/Enhancer.svg' alt='' />Enhancer
              </a>
            </Badge>          
          </div>
          {/*<div className="d-flex justify-content-end referral-wrap">
            <a className="referral-link" onClick={this.redirect}>
              Referrals
            </a>
          </div>*/}
        </>
      )
    }
    if (
      this.props.isLoggedIn &&
      this.state.isAccount &&
      window.innerWidth > 760 && window.innerWidth < 1024
    ) {
      renderDesign = (
        <div
          className="d-flex justify-content-start portfolio-tools-wrap"
          style={{ width: '73%' }}
        >
        </div>
      )
    }
    if (
      this.props.isLoggedIn &&
      this.state.isProfile &&
      this.state.isAccount &&
      window.innerWidth > 760 && window.innerWidth < 1024
    ) {
      renderDesign = (
        <>
          <div
            className="d-flex justify-content-start portfolio-tools-wrap"
            style={{ width: '73%' }}
          >
          </div>
          {/*<div className="d-flex justify-content-end referral-wrap">
           <a className="referral-link" onClick={this.redirect}>
              Referrals
            </a>
          </div>*/}
        </>
      )
    }

    return (
      <nav className="navbar navbar-expand-md navbar-dark bg-c5 py-0 shadow-0 navBarWrapper">
        <div className="container py-1">
          <a className="navbar-brand logoWrapper" href={REDIRECT_DOMAIN_PATH}>
            <img
              src={logo}
              width={height ? '' : '162'}
              height={height}
              alt="magnifi"
            />
          </a>
          {renderDesign}
          <UserMenu
            className="userMenuWrapper"
            onLogout={this.props.onLogout}
            isLoggedIn={this.props.isLoggedIn}
            showSinginIcon={this.props.showSinginIcon}
          />
        </div>
      </nav>
    )
  }
}

Navbar.propType = {
  onLogout: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  // loggedIn: state.auth.loggedIn,
  sfmItems: state.sfm.items,
});

const mapDispatchToProps = {
  setSFMState
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Navbar));
// export default withRouter(Navbar)

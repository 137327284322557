import React from 'react';
import { extent as d3Extent } from 'd3-array';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ReferenceLine, CartesianGrid } from 'recharts';
import { ProfitLossColors } from 'data/Colors'
import { getNestDefault } from 'Utils';

const getCenteredYDomain = (data, keyFn) => {
  const [min, max] = d3Extent(data, keyFn);
  let max2 = Math.max(Math.abs(min), Math.abs(max));
  if (max2 === 0) max2 = 1;
  return [-max2, max2];
}

export const SimpleLineChartWithAxis = (props) => {
  const { lines, xkey, ykey, series, xAxisLabelClassName, yAxisLabelClassName } = props;

  const colors = ['#56a9e8', '#1e5f91'];

  if (!lines || !lines.length || typeof lines === 'undefined') return null;

  let dt = new Date();
  let yr = dt.getFullYear();
  let mn = new Date(dt.setMonth(dt.getMonth()+1)).getMonth();
  let allmn = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

  const tickFormatter = label => {
    let lastYear = label.substring(label.length,4)
    if(label.substring(0,3) == allmn[mn]){
      return (lastYear == yr) ? xAxisYear(label) : xAxisYear(label)
    }else if(label.substring(0,3) == allmn[mn-1] && lastYear == yr){
      return xAxisYear(label)
    }
    return ''
  }// wider tick label dont show all xticks

  const lineData = lines[0].data;
  const domain = getCenteredYDomain(lineData, e => e[ykey]);

  const first = lineData.findIndex(e => e[ykey] !== undefined)
  let data = lineData.slice()

  data.splice(first, 0, { x: 'Start', y1: 0, y2: 0, y3: 0, y4: 0, orgVal: 0 })
  let last = data[data.length - 1]

  let referenceLines = data.map(i => (i.x.substring(0,3) == allmn[mn] && i.x.substring(i.x.length,4) != yr) && i.x).filter(j => j);

  const posY = () => {
    let out = { y1: 0, y2 : 0 }
    if (typeof data[data.length - 1].label !== "Enhanced") return out

    const ranges = ['y1', 'y2'].reduce(
      (acc, y) => acc.concat(d3Extent(data, e => e[y])),
      []
    )
    const [min, max] = d3Extent(ranges) // ydomain across all 3 lines
    const y1pos = last.y1 / (max - min),
      y2pos = last.y2 / (max - min)
    const diff = Math.abs(y1pos - y2pos)

    // if difference is more than 20% then no collision
    if (diff > 0.2) return out

    const adj = Math.round((0.2 - diff) * 30) // consider 12px text height, 12px = 20%
    if (y1pos > y2pos) {
      // y1 appears first
      if (y1pos > 0.9) out.y2 = 2 * adj
      // y1 almost at top, so just move y4 down
      else if (y2pos < 0.1) out.y1 = -2 * adj
      // y2 almost at bottom, so just move y1 up
      else out = { y1: -adj, y2: adj } // move y1 up, y2 down by same amt
    } else {
      // y2 appears first
      if (y2pos > 0.9) out.y1 = 2 * adj
      // y2 almost at top, so just move y1 down
      else if (y1pos < 0.1) out.y2 = -2 * adj
      // y1 almost at bottom, so just move y2 up
      else out = { y1: adj, y2: -adj } // move y2 up, y1 down by same amt
    }
    return out
  }
  const labelOff = posY()

  const segs = (color) => {
    const tick = 1 / (data.length - 1 - first)
    let out = [],
      dir

    for (let i = first + 1; i < data.length; i++) {
      let _dir = data[i].y3 < 0 ? 0 : 1
      if (dir === _dir) {
        out[out.length - 1] = (
          <stop
            key={`s${i}`}
            offset={(i-first-1)*tick}
            stopColor={color}
            stopOpacity={1}
          />
        )
      } else {
        out.push(
          <stop
            key={`s${i}`}
            offset={(i - first - 1) * tick}
            stopColor={color}
            stopOpacity={1}
          />
        )
        out.push(
          <stop
            key={`e${i}`}
            offset={(i - first) * tick}
            stopColor={color}
            stopOpacity={1}
          />
        )
      }
      dir = _dir
    }
    return out
  }

  const gradientId = `color-${props.fid}-${props.gSuffix || ''}`;

   return (
     <ResponsiveContainer id={props.id || 'linechart'} width="100%" height="100%">
       <LineChart
         data={data}
         margin={{ top: 15, right: 30,  bottom: 10, left: -30 }}
       >
        <defs>
          { lines.map((s, index) => (
            <linearGradient id={`color-${s.name}`} x1="0" y1="0" x2="1" y2="0" key={s.name}>
              { segs(data, s.color) }
            </linearGradient>
          ))}
        </defs>

        <XAxis
          dataKey={xkey}
          tickSize={0}
          tickMargin={10}
          stroke="#707070"
          shapeRendering="crispEdges"
          tickFormatter={value => tickFormatter(value)}
          allowDataOverflow={true}
          interval={0}
        />

        <YAxis stroke="#707070" tick={<YAxisTick />}  shapeRendering="crispEdges" />

        { referenceLines.map((v,i) => (
          <ReferenceLine
            x={v}
            key={i}
            stroke="rgba(112, 112, 112, 0.16)"
            shapeRendering="crispEdges"
          />
        ))}

        <Tooltip content={<CTooltip />}
          cursor={{ stroke: '#666', strokeWidth: 1, strokeDasharray: "2 2" }}/>

        <CartesianGrid hide={true} stroke="rgba(112, 112, 112, 0.16)" strokearray="4 4" shapeRendering="crispEdges" />

        <Line
          dataKey={ykey}
          type="monotone"
          unit="%"
          stroke={lines[0].color}
          dot={false}
          strokeWidth={1}
          activeDot={{r: 3, strokeWidth: 1, fill: colors[1]}}
          label={<CLabel
            data={last}
            dataKey={ykey}
            size={data.length}
            label={lines[0].label}
            stroke={lines[0].color}
          />}
          isAnimationActive={false}
        />
      </LineChart>
     </ResponsiveContainer>
   )
}

const labelPc = label => label.toFixed(2)+'%';

const xAxisYear = value => (value && value.length && value.split(' ').length > 1) ? value.split(' ')[1] : value;

const YAxisTick = (props) => {
  const {
    x, y, width, height, fill, stroke, payload
  } = props;
  if (payload) {
    const { coordinate, offset, tickCoord, isShow } = payload;
    const value = Math.round(payload.value*100);
    console.log("value, x,", value, x, props)
    const xoff = ((value < 100) ? (value < 10 ? (value > -10 ? x-32 : x-30) : x-32) : x-32);
    return (
      <text className="y-axis-label" x={xoff} y={y+3} fill={fill}>
        {`$${Math.round(100 + value)}`}
      </text>
    );
  }
}

const CLabel = (props) => {
  const { index, size, x, y, stroke, label, dx, dy, dataKey, labelText, viewBox, data, value } = props;
  if (index === (size - 1)) {
    return (
      <text
        id={`linechart-label-for-${dataKey}`}
        dataKey={dataKey}
        x={x}
        y={y}
        dx={4}
        dy={4}
        fill={stroke}
        fontSize={10}
        textAnchor="start"
      >
        {label}
      </text>
    )
  }
  return null;
};

const CTooltip = (props) => {
  const { active, payload, label, vkey } = props;

  if (active && payload) {
    const key = vkey ? ['payload', vkey] : ['value'];
    return (
      <div className="recharts-default-tooltip ssf-tooltip-custom">
        <div>
          <div className="label">{label}</div>
          { payload.map((e, index) => (
            <div key={e.name} className="d-flex" style={{color: e.color}}>
              <span className="ml-auto">{(getNestDefault(['payload', `y${index+1}T`], e, 0.0)).toFixed(2)}</span>
              <span className="">{e.unit}</span>
            </div>
          )) }
        </div>
      </div>
    );
  }

  return null;
}

export const ReturnsLineChart = (props) => {
  const { lines, xkey, ykey, series, xAxisLabelClassName, yAxisLabelClassName } = props;

  const colors = ['#56a9e8', '#1e5f91'];

  if (!lines || !lines.length || typeof lines === 'undefined') return null;

  let dt = new Date();
  let yr = dt.getFullYear();
  let mn = new Date(dt.setMonth(dt.getMonth()+1)).getMonth();
  let allmn = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

  const tickFormatter = label => {
    let lastYear = label.substring(label.length,4)
    if(label.substring(0,3) == allmn[mn]){
      return (lastYear == yr) ? xAxisYear(label) : xAxisYear(label)
    }else if(label.substring(0,3) == allmn[mn-1] && lastYear == yr){
      return xAxisYear(label)
    }
    return ''
  }// wider tick label dont show all xticks

  const lineData = lines[0].data;
  const domain = getCenteredYDomain(lineData, e => e[ykey]);

  const first = lineData.findIndex(e => e[ykey] !== undefined)
  let data = lineData.slice()

  data.splice(first, 0, { x: 'Start', y1: 0, y2: 0, y3: 0, y4: 0, orgVal: 0 })
  let last = data[data.length - 1]

  let referenceLines = data.map(i => (i.x.substring(0,3) == allmn[mn] && i.x.substring(i.x.length,4) != yr) && i.x).filter(j => j);

  const posY = () => {
    let out = { y1: 0, y2 : 0 }
    if (typeof data[data.length - 1].label !== "Enhanced") return out

    const ranges = ['y1', 'y2'].reduce(
      (acc, y) => acc.concat(d3Extent(data, e => e[y])),
      []
    )
    const [min, max] = d3Extent(ranges) // ydomain across all 3 lines
    const y1pos = last.y1 / (max - min),
      y2pos = last.y2 / (max - min)
    const diff = Math.abs(y1pos - y2pos)

    // if difference is more than 20% then no collision
    if (diff > 0.2) return out

    const adj = Math.round((0.2 - diff) * 30) // consider 12px text height, 12px = 20%
    if (y1pos > y2pos) {
      // y1 appears first
      if (y1pos > 0.9) out.y2 = 2 * adj
      // y1 almost at top, so just move y4 down
      else if (y2pos < 0.1) out.y1 = -2 * adj
      // y2 almost at bottom, so just move y1 up
      else out = { y1: -adj, y2: adj } // move y1 up, y2 down by same amt
    } else {
      // y2 appears first
      if (y2pos > 0.9) out.y1 = 2 * adj
      // y2 almost at top, so just move y1 down
      else if (y1pos < 0.1) out.y2 = -2 * adj
      // y1 almost at bottom, so just move y2 up
      else out = { y1: adj, y2: -adj } // move y2 up, y1 down by same amt
    }
    return out
  }
  const labelOff = posY()

  const segs = (color) => {
    const tick = 1 / (data.length - 1 - first)
    let out = [],
      dir

    for (let i = first + 1; i < data.length; i++) {
      let _dir = data[i].y3 < 0 ? 0 : 1
      if (dir === _dir) {
        out[out.length - 1] = (
          <stop
            key={`s${i}`}
            offset={(i-first-1)*tick}
            stopColor={color}
            stopOpacity={1}
          />
        )
      } else {
        out.push(
          <stop
            key={`s${i}`}
            offset={(i - first - 1) * tick}
            stopColor={color}
            stopOpacity={1}
          />
        )
        out.push(
          <stop
            key={`e${i}`}
            offset={(i - first) * tick}
            stopColor={color}
            stopOpacity={1}
          />
        )
      }
      dir = _dir
    }
    return out
  }

  const gradientId = `color-${props.fid}-${props.gSuffix || ''}`;

   return (
     <ResponsiveContainer id={props.id || 'linechart'} width="100%" height="100%">
       <LineChart
         data={data}
         margin={{ top: 15, right: 30,  bottom: 10, left: -20 }}
       >
        <defs>
          { lines.map((s, index) => (
            <linearGradient id={`color-${s.name}`} x1="0" y1="0" x2="1" y2="0" key={s.name}>
              { segs(data, s.color) }
            </linearGradient>
          ))}
        </defs>

        <XAxis
          dataKey={xkey}
          tickSize={0}
          tickMargin={10}
          stroke="#707070"
          shapeRendering="crispEdges"
          tickFormatter={value => tickFormatter(value)}
          allowDataOverflow={true}
          interval={0}
        />

        <YAxis stroke="#707070" tick={<YAxisTick />} shapeRendering="crispEdges" />

        { referenceLines.map((v,i) => (
          <ReferenceLine
            x={v}
            key={i}
            stroke="rgba(112, 112, 112, 0.16)"
            shapeRendering="crispEdges"
          />
        ))}

        <Tooltip content={<CTooltip />}
          cursor={{ stroke: '#666', strokeWidth: 1, strokeDasharray: "2 2" }}/>

        <CartesianGrid hide={true} stroke="rgba(112, 112, 112, 0.16)" strokearray="4 4" />

        <Line
          dataKey={ykey}
          type="monotone"
          unit="%"
          stroke={lines[0].color}
          dot={false}
          strokeWidth={1}
          activeDot={{r: 3, strokeWidth: 1, fill: colors[1]}}
          label={<CLabel
            data={last}
            dataKey={ykey}
            size={data.length}
            label={lines[0].label}
            stroke={lines[0].color}
          />}
          isAnimationActive={false}
        />
      </LineChart>
     </ResponsiveContainer>
   )
}
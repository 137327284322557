import React from 'react';

import { DonutChartS1 } from 'components/charts/Recharts'

import {
  AssetAllocationChart,
  SectorChart,
} from 'layouts/WebDashboard/SearchResultsV3/ItemCard/ChartHelper';

import { SectorsAttrs, AssetType } from 'DataSet';
import { addEventToAnalytics } from 'Utils';
import { SectorColors } from 'data/Colors';

import { SelectorDD, NoData } from './Common';
import { Spinner } from '../../../Elements/Loader';

export class Allocations extends React.Component {
  opts = ['Regions', 'Sectors', 'Assets']
  state = { opt: this.opts[0] }

  optHandler = opt => {
    switch (opt) {
      case 'Regions':
        addEventToAnalytics(
          'Enhancer Allocation Region',
          'Enhancer Allocation Region',
          'ENHANCER_ALLOCATION_REGION',
          {},
          false
        );
        break;
      case 'Sectors':
        addEventToAnalytics(
          'Enhancer Allocation Sector',
          'Enhancer Allocation Sector',
          'ENHANCER_ALLOCATION_SECTOR',
          {},
          false
        );
        break;
      case 'Assets':
        addEventToAnalytics(
          'Enhancer Allocation Assets',
          'Enhancer Allocation Assets',
          'ENHANCER_ALLOCATION_ASSETS',
          {},
          false
        );
        break;
      default:
    }
    this.setState({ opt })
  };

  formatter = val => `${val.toFixed(1)}%`;

  chartData = (card) => {
    switch (this.state.opt) {
      case 'Regions':
        return this.props.stats.priceRegions.length > 0 ? this.props.stats.priceRegions : [];
      case 'Sectors':
        return this.props.stats.allocationPriceSectors.length > 0 ? this.props.stats.allocationPriceSectors : [];
      case 'Assets':
        return card.assetTypeData ? [card.assetTypeData] : [];
      default:
    }
  }

  selectedRegions = (event) => {
    console.log('selectedRegions -> ', event);
  }

  render() {
    const { currentTargetPortfolio } = this.props;
    const PieChartSize = 140;
    const card = (currentTargetPortfolio && currentTargetPortfolio.length && currentTargetPortfolio[0]) ? currentTargetPortfolio[0] : {};
    const data = this.chartData(card);

    // console.log(card);
    // <div style={{ width: "90%", height: "140px" }}>
    //   <DonutChartS1 data={data} formatter={this.formatter} />
    // </div>

    // <div style={{ width: "90%", height: "140px" }}>
    //   <DonutChartS1 data={data} formatter={this.formatter} />
    // </div>

    // <RegionChart
    //   key={'Regions'}
    //   name={'Allocations'}
    //   card={card}
    //   chartData={[]}
    //   width={PieChartSize}
    //   view={this.state.opt}
    //   height={PieChartSize}
    //   colors={RegionColors}
    //   searchFundsActiveRegion={this.selectedRegions}
    // />

    // console.log('currentPortlioLoading -> ', this.props.loading, this.state.opt)

    const renderChart = [
      this.state.opt === 'Regions' && (
        <>
          {(data.length <= 0) ? (
            <NoData height='225px' />
          ) : (
            <div style={{ width: "90%", height: "140px" }}>
              <DonutChartS1 data={data} formatter={this.formatter} />
            </div>
          )}
        </>
      ),
      this.state.opt === 'Sectors' && (
        <>
          {(data.length <= 0) ? (
            <NoData height='225px' />
          ) : (
            <SectorChart
              key={'Sectors'}
              name={'Sectors'}
              data={data}
              chartData={[]}
              view={this.state.opt}
              attrs={SectorsAttrs}
              colors={SectorColors}
              width={PieChartSize}
              height={PieChartSize}
              formatter={this.formatter}
            />
          )}
        </>
      ),
      this.state.opt === 'Assets' && (
        <>
          {(this.props.loading) ? (
            <div style={{ position: 'relative' }}>
              <Spinner style={{ background: '#fff' }} />
            </div>
          ) : (
            card && card.assetTypeData ? (
              <AssetAllocationChart
                key={'Assets'}
                name={'Asset Types'}
                card={card}
                chartData={[]}
                attrs={AssetType}
                width={PieChartSize}
                view={this.state.opt}
                colors={SectorColors}
                height={PieChartSize}
              />
            ) : (
              <NoData height='225px' />
            )
          )}
        </>
      ),
    ];

    return (
      <React.Fragment>
        <SelectorDD opts={this.opts} optHandler={this.optHandler} />
        <h5 className="mb-1 text-heading font-one">Allocations</h5>
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '225px' }}>
          {renderChart}
        </div>
      </React.Fragment>
    )
  }
}

export default Allocations;

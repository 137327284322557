import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Row, Col, Button, Pagination, Tooltip } from 'antd';

import { getSummaryFundsForReactState } from 'selectors/accounts';
import { createLoadingSelector } from 'selectors/loading-selector'

import { getProfileOrders } from 'actions/profile';
import Emitter from 'realTimePrice/emitter';

import { fetchBalance } from 'layouts/WebDashboard/SFM/ActTab/ItemList';

import { Holdings } from './Elements/Holdings';
import { SecurityReturns } from './Elements/SecurityReturns';
import { BasketHoldings } from './Elements/BasketHoldings';
import { Transactions } from './Elements/Transactions';
import { SingleAllocationsChart, MultipleAllocationsChart } from './Elements/Allocations';
import { ProfitLoss, PortfolioValue, BuyingPower } from './Elements/ProfitLoss';
import { PortfolioGains, NoData } from './Elements/Common';
import { numFmt, convertToFloatvalues } from 'Utils'
import { InnerPageFooter } from 'shared-components/'

class Board extends React.PureComponent {
  constructor(props) {
    super(props);
		this.state = {
			activeFund: '',
      acView: '',
      transactionType : 'security',
      transactionTitle : 'Recent Security Transactions',
      transactionBtnTitle : 'Recent Bank Transactions',
      renderReturnStats: null,
      fundStats: props.fundStats,
      sortedFunds: (props.funds && props.funds) ? _.orderBy(props.funds, ['market_value'], ['desc']) : [],
      PortfolioGainsStats: (props.fundStats && props.funds) ? [
        { id: 0, name: 'Gains For Today', key: 'Today', value: null },
        { id: 1, name: 'Gains For MTD', key: 'MTD', value: null },
        { id: 2, name: 'Gains For YTD', key: 'YTD', value: null },
      ] : [],
      selectedPortfolioGainsView: null, //(props.fundStats && props.funds) ? { id: 0, name: 'Gains For Today', key: 'Today', value: props.fundStats.gainToday } : null,
      activePage: 1
		}
    this.buyHandler = this.buyHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
  }

  componentDidMount() {
    const { funds, retData, summary, profile, apexBalanceData } = this.props;
    const { activeFund, acView } = this.state;

    let sortedFunds = _.orderBy(funds, ['market_value'], ['desc'])
    const _fundStats = getSummaryFundsForReactState(sortedFunds);
    let PortfolioGainsStats = [
      { id: 0, name: 'Gains For Today', key: 'Today', value: _fundStats.gainToday },
      { id: 1, name: 'Gains For MTD', key: 'MTD', value: _fundStats.gainMonthly },
      { id: 2, name: 'Gains For YTD', key: 'YTD', value: _fundStats.gainYearly },
    ]
    this.setState({
      PortfolioGainsStats,
      fundStats: _fundStats,
      sortedFunds: _.orderBy(sortedFunds, ['market_value'], ['desc']),
    }, () => {
      if (this.state.selectedPortfolioGainsView && this.state.selectedPortfolioGainsView.key === 'Today') {
        this.viewHandlerPortfolioGains(this.state.selectedPortfolioGainsView);
      }
    });

		if (sortedFunds) {
			Emitter.on('GET_LIVE_PRICES', (response) => {
				// console.log('==== GET_LIVE_PRICES response ====>', response.symbol, response.price, response.diff);
        sortedFunds.forEach((kl, ki) => {
          // let tickerWillBe = "ANKIT" || kl.ticker; // <-- for testing use "ANKIT" as a ticker
          // //=== removed from here ===
          // kl._realPriceObj = response;
          // kl.current_price = response.price;
          // const quantityValue = convertToFloatvalues(kl.shares);
          // let marketValue = (!isNaN(kl.current_price) && kl.current_price !== 'NaN' && kl.current_price !== '') ? convertToFloatvalues(kl.current_price) * quantityValue : '--';
          // kl.market_value = marketValue;
          // //=== removed to here ===
          // if (response.symbol === 'BPESX') console.log(response);
          let tickerWillBe = kl.ticker;
          if (tickerWillBe === response.symbol) {
            // console.log(response.symbol, response.price);
            kl._realPriceObj = response;
            kl.current_price = response.price;
            const quantityValue = convertToFloatvalues(kl.shares);
            if (kl.type !== 'BASKET') {
              let marketValue = (!isNaN(kl.current_price) && kl.current_price !== 'NaN' && kl.current_price !== '') ? convertToFloatvalues(kl.current_price) * quantityValue : '--';
              kl.market_value = marketValue;
            }
          }
        });
        const _fundStats = getSummaryFundsForReactState(sortedFunds);
        let PortfolioGainsStats = [
          { id: 0, name: 'Gains For Today', key: 'Today', value: _fundStats.gainToday },
          { id: 1, name: 'Gains For MTD', key: 'MTD', value: _fundStats.gainMonthly },
          { id: 2, name: 'Gains For YTD', key: 'YTD', value: _fundStats.gainYearly },
        ]
        this.setState({
          PortfolioGainsStats,
          fundStats: _fundStats,
          sortedFunds: _.orderBy(sortedFunds, ['market_value'], ['desc']),
        }, () => {
          if (this.state.selectedPortfolioGainsView && this.state.selectedPortfolioGainsView.key === 'Today') {
            this.viewHandlerPortfolioGains(this.state.selectedPortfolioGainsView);
          }
        });
			})
		}
	}

  buyHandler = (ticker) => {
    // console.log(ticker);
    // setSFMState({
    //   items,
    // });
  }

  deleteHandler = (item) => {
    // console.log(item);
    // const { dropFund, setPortfolioState } = this.props;
    // dropFund({
    //   ticker: item.symbol,
    // });
    // setPortfolioState({ summaryDrawer: false });
  }

  selectHandler = (item, index) => {
    // console.log(item, index);
    // const { selectHandler } = this.props;
    // selectHandler(item, index);
  }

	holdingsItemClickHandler = (activeTicker) => {
    // console.log(activeTicker);
		if (activeTicker) {
			this.setState({ activeFund: activeTicker });
		}
	}

  loadMoreAction = (type) => {
    this.setState({ acView: type });
  }

  buyingPowerCallBack = (props) => {
    console.log(props);
  }

  changeTransactionType = () => {
    let type                = (this.state.transactionType === 'security') ? 'bank' : 'security'
    let transactionTitle    = 'Recent Security Transactions';
    let transactionBtnTitle = 'Recent Bank Transactions';

    if (type === 'bank') {
      transactionTitle    = 'Recent Bank Transactions';
      transactionBtnTitle = 'Recent Security Transactions';
    }

    this.setState({ transactionType: type, transactionTitle: transactionTitle, transactionBtnTitle: transactionBtnTitle });
    const { refreshApexStateRequest } = this.props;
    if (refreshApexStateRequest) {
      refreshApexStateRequest();
    }
  }

  selectReturnYear = (selectedYear, items) => {
    const { selectReturnYear, refreshApexStateRequest } = this.props;
    if (selectReturnYear) {
      selectReturnYear(selectedYear, items)
    }
    if (refreshApexStateRequest) {
      refreshApexStateRequest();
    }
  }

  cancelDeposits = (props, type) => {
    const { cancelDepositsRequest } = this.props;
    if (cancelDepositsRequest && props) {
      cancelDepositsRequest(props, type);
    }
  }

  viewHandlerPortfolioGains = props => {
    if (props && props.key) {
      const { PortfolioGainsStats } = this.state;
      this.setState({
        selectedPortfolioGainsView: PortfolioGainsStats.find((item) => item.key === props.key),
      });
    }
  };

  handlePaginationChange = pageNumber => {
    this.setState({ activePage: pageNumber })
    this.props.getProfileOrders(pageNumber)
  }

  render() {
    const { funds, retData, summary, profile, smallScreen, apexBalanceData, refreshApexStateRequest } = this.props;
		const { activeFund, acView, fundStats, sortedFunds, selectedPortfolioGainsView, PortfolioGainsStats } = this.state;

    const hdExpand = acView === 'holdings',
          txExpand = acView === 'transactions',
          invalidTickersExpand = acView === 'invalidTickers';

    const _sortedFunds = _.orderBy(sortedFunds, ['market_value'], ['desc']);
    // console.log(sortedFunds, sortedFunds[0]);
    let _activeFund = (activeFund !== '') ? activeFund : (_sortedFunds && _sortedFunds.length !== 0 && typeof _sortedFunds[0] !== 'undefined' ? _sortedFunds[0].ticker : null);
    let securityReturns = retData && retData.length > 0 && retData.find((jk) => jk.ticker === _activeFund);
    let activeCard = (funds && funds.length > 0 && funds.find((jj) => jj.ticker === _activeFund)) ? funds.find((jj) => jj.ticker === _activeFund) : null;
    securityReturns = securityReturns || (funds && funds.length > 0 && funds.find((jj) => jj.ticker === _activeFund) && funds.find((jj) => jj.ticker === _activeFund).return);

    let avaBalance = '';
    let cashEquity = 0;
    if (apexBalanceData && typeof apexBalanceData.balancePower !== 'undefined') {
      avaBalance = apexBalanceData.balancePower;
    }

    if(apexBalanceData.cash_equity && typeof apexBalanceData.cash_equity !== 'undefined'){
      cashEquity = apexBalanceData.cash_equity;
    }

    let lastPage = profile.profile.apex.orders_total_count / 10;
    if (lastPage && !Number.isInteger(lastPage)) {
      lastPage = Math.trunc(lastPage) + 1;
    }

    return (
      <div className="accounts-page-summary-wrapper custom-scroll pb-5" style={{  }}>
        <Row className="compact accounts-summary-card-row">
          <Col span={12} className="accounts-summary-card-col" style={{ paddingTop: 4 }}>
            <div className="bg-white rounded border border-gray-2 h-100">
              <PortfolioValue avaBalance={avaBalance} stats={fundStats} cashEquity={cashEquity} selectedOption={selectedPortfolioGainsView} />
            </div>
          </Col>
          <Col span={6} className="accounts-summary-card-col" style={{ paddingTop: 4 }}>
            <div className="bg-white no-gutters apex-bg-2 rounded border border-gray-2 h-100">
              <PortfolioGains
                prec={2}
                fundStats={PortfolioGainsStats}
                selectedOption={selectedPortfolioGainsView}
                viewHandler={this.viewHandlerPortfolioGains}
              />
            </div>
          </Col>
          <Col span={6} className="accounts-summary-card-col" style={{ paddingTop: 4 }}>
            <div className="bg-white rounded border border-gray-2 h-100">
              <BuyingPower history={this.props.history} avaBalance={avaBalance} cashEquity={cashEquity} stats={fundStats} callBack={(props) => this.buyingPowerCallBack(props)} />
            </div>
          </Col>
        </Row>
        <Row className="compact accounts-summary-card-row">
          <Col span={12} className="accounts-summary-card-col">
            <div className="bg-white rounded border border-gray-2 h-100">
              <Holdings
                title="My Holdings"
                items={_sortedFunds}
                actions={FundActions}
                expand={hdExpand}
                invalidTickersExpand={invalidTickersExpand}
                summary={summary}
                activeFundTicker={_activeFund}
                refreshApexStateRequest={refreshApexStateRequest}
                loadMoreAction={this.loadMoreAction}
                deleteHandler={this.deleteHandler}
                buyHandler={this.buyHandler}
                selectHandler={this.selectHandler}
                itemClickHandler={this.holdingsItemClickHandler}
              />
            </div>
          </Col>
          <Col span={12} className="accounts-summary-card-col">
            { !txExpand && (
              <div className="p-3 bg-white rounded border h-100 border-selected-color">
                {(activeCard && activeCard.type === 'BASKET') ? (
                  (activeCard && activeCard.basket_trades) ? (
                    <BasketHoldings
                      card={activeCard}
                      items={securityReturns && securityReturns._series ? securityReturns : {}}
                      height={(summary.invalidTickerFunds.length > 0) ? 252 : 265}
                      selectReturnYear={this.selectReturnYear}
                    />
                  ) : (
                    <NoData height='100%' />
                  )
                ) : (
                  securityReturns && securityReturns._series ? (
                    <SecurityReturns
                      card={activeCard}
                      items={securityReturns}
                      height={(summary.invalidTickerFunds.length > 0) ? 252 : 225}
                      selectReturnYear={this.selectReturnYear}
                    />
                  ) : (
                    <NoData height='100%' />
                  )
                )}
              </div>
            )}
          </Col>
        </Row>
        <Row className="compact accounts-summary-card-row">
          <Col span={12} className="accounts-summary-card-col">
            <div className="bg-white rounded border border-gray-2 h-100" style={{ position: 'relative' }}>
              <Transactions
                loadMoreAction={this.loadMoreAction}
                title={this.state.transactionTitle}
                transactionBtnTitle={this.state.transactionBtnTitle}
                items={funds}
                profile={profile}
                expand={txExpand}
                transactionType={this.state.transactionType}
                changeTransactionType={this.changeTransactionType}
                cancelRequestForDeposits={this.cancelDeposits}
                loading={this.props.profileLoading}
              />
              <div className="" style={{ display: "flex", justifyContent: "center", alignItems: "center", position: 'absolute', left: 0, right: 0, bottom: 0, backgroundColor: '#fff', paddingTop: 5 }}>
                { this.state.transactionType !== "bank" &&  <div className='transactions-pagination-wrapper'>
                <Tooltip placement="left" title='First Page'>
                  {this.state.activePage == 1 ?
                    <img className='jump-icn flip disabled' src='/assets/images/icons/SideArrow-deactive.svg' alt='' />
                    : <img className='jump-icn flip' src='/assets/images/icons/SideArrow-Active.svg' alt='' onClick={() => this.handlePaginationChange(1)} />
                  }
                </Tooltip>
                <Pagination 
                  size="small"
                  showSizeChanger={false}
                  current={this.state.activePage}
                  total={profile.profile.apex.orders_total_count || 10} 
                  onChange={this.handlePaginationChange} 
                  className='transaction-pagination'
                />
                <Tooltip placement="right" title='Last Page'>
                  {lastPage == this.state.activePage ?
                    <img className='jump-icn disabled' src='/assets/images/icons/SideArrow-deactive.svg' alt='' />
                    : <img className='jump-icn' src='/assets/images/icons/SideArrow-Active.svg' alt='' onClick={() => this.handlePaginationChange(lastPage)} />
                  }
                </Tooltip>
              </div>}
              </div>
            </div>
          </Col>
          <Col span={12}>
            { (!hdExpand && !invalidTickersExpand) &&
              <React.Fragment>
                <Col className="compact accounts-summary-card-col">
                  <div className="p-3 bg-white rounded border border-gray-2 h-100 w-100">
                    <MultipleAllocationsChart stats={fundStats} />
                  </div>
                </Col>
                <Col className="compact accounts-summary-card-col">
                  <div className="p-3 bg-white rounded border border-gray-2 h-100 w-100">
                    <ProfitLoss stats={fundStats} />
                  </div>
                </Col>
              </React.Fragment> }
          </Col>
        </Row>
        <InnerPageFooter style={{ width: "100%", borderTop: "1px solid #d5cbcb", textAlign: "center", fontFamily: "Open Sans", fontSize: "12px", padding: ".4em", background: "#f7f7f7", marginTop: "10px" }} />        
      </div>
    )
  }
}

const mapStateToProps = ({ loading }) => ({
  profileLoading: createLoadingSelector(['GET_PROFILE_ORDERS'])({ loading }),
})

const mapDispatchToProps = {
  getProfileOrders,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Board)

const FundActions = () => {
  return (
    <React.Fragment>
      <Button color={"select"} outline
        className="h6 mb-0 py-1 badge-pill">Buy</Button>
      <Button color={"select"} outline
        className="h6 mb-0 mt-2 py-1 badge-pill">Drop</Button>
    </React.Fragment>
  )
}

import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList, ReferenceLine, Pie, PieChart, Legend } from 'recharts';
import { kmb } from 'DataSet';

export const BarChartS1 = (props) => {
  const parsedValue = value => props.parser ? kmb(value): value.toFixed(2)
  const valueAccessor = e => (e.labelNA && e.value == 0) ? e.labelNA : e.payload.label || ((props.prefix) ? props.prefix + parsedValue(e.value) + props.suffix : parsedValue(e.value) + props.suffix);
  const values = props.data.map(e => e.value);
  const [min, max] = [Math.min(...values), Math.max(...values)];
  const domain = [ min < 0 ? 'auto' : 0, max > 0 ? 'auto' : 0 ]

  return (
    <ResponsiveContainer width="100%" height="100%" >
      <BarChart data={props.data} margin={{top: 18, right: 0, left: 0, bottom: 12}}>
        {props.showTickerOnAxis && props.data.length < 11 ?
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tickMargin={min < 0 ? 8 : 1}
            tick={{fontSize: 11}}
            interval={0}
            height={min < 0 ? 16 : 10}
          />
          : <XAxis dataKey="name" hide={true}/>
        }
        <ReferenceLine y={0} stroke="#bbb" shapeRendering="crispEdges"/>
        <YAxis hide={true} domain={domain} />
        <Tooltip content={<CustomTooltipBar showValueOnTooltip={props.showTickerOnAxis && props.data.length > 10} parsedValue={parsedValue} suffix={props.suffix} />} cursor={false} isAnimationActive={false} />
        <Bar dataKey="value" unit="%" fill="#8884d8" maxBarSize={50} isAnimationActive={false}>
          {props.data.length < 11 &&
            <LabelList valueAccessor={valueAccessor} position="top" offset={3} className="ssf" />
          }
          { props.data.map((v, i) => (
              <Cell key={`cell-${i}`} fill={v.color}/>
            )) }
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}


const CustomTooltipBar = (props) => {
  const { active, payload, label, showValueOnTooltip, parsedValue, suffix } = props;

  if (active && payload && payload[0]) {
    return (
      <div className="recharts-default-tooltip ssf-tooltip-custom">
        <div>
          <span className="label mr-0">{label} {showValueOnTooltip && <span>: {parsedValue(payload[0].value)}{suffix}</span>}</span>
        </div>
      </div>
    );
  }

  return null;
}

export const BarChartS2 = (props) => {
  const labelFormatter = (label) => label.toFixed(2)+'%'
  const values = props.data.map(e => e.value);
  const refValues = props.data.map(e => e.refValue);
  const [rmin, rmax] = [Math.min(...refValues), Math.max(...refValues)];
  const [min, max] = [Math.min(...values, rmin), Math.max(...values, rmax)];
  const domain = [ min < 0 ? 'auto' : 0, max > 0 ? 'auto' : 0 ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={props.data} margin={{top: 18, right: 0, left: 0, bottom: 12}} barGap="1">
        <XAxis dataKey="name" hide={true}/>
        <ReferenceLine y={0} stroke="#bbb" shapeRendering="crispEdges"/>
        <YAxis hide={true} domain={domain} />
        <Tooltip content={<CustomTooltipBar />} cursor={false} isAnimationActive={false} />
        <Bar dataKey="refValue" fill="#bbb" barSize={4} />
        <Bar dataKey="value" unit="%" fill="#8884d8" barSize={50} maxBarSize={50} isAnimationActive={false}>
          <LabelList dataKey="value" position="top" offset={3} formatter={labelFormatter} className="ssf" />
          { props.data.map((v, i) => (
              <Cell key={`cell-${i}`} fill={v.color}/>
            )) }
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

const CustomTooltipPieChartS1 = (props) => {
  const { active, payload, label } = props;
  if (active && payload[0]) {
    return (
      <div className="recharts-default-tooltip ssf-tooltip-custom">
        <div>
          <span className="label mr-0">{payload[0].name} : {payload[0].value}%</span>
        </div>
      </div>
    );
  }

  return null;
}

export const PieChartS1 = (props) => {
  const itemStyle = {fontSize: '0.75rem', padding: '0'}
  const { data, colors } = props;

  return (
    <ResponsiveContainer width="90%" height="90%" aspect={1}>
    	<PieChart>
        <Pie data={data} dataKey="value" nameKey="name"
          outerRadius={'95%'}
          isAnimationActive={true}
          fill="#8884d8" >
          { data.map((v, i) => <Cell key={i} fill={colors[i%colors.length]}/>) }
        </Pie>
        <Tooltip
          content={<CustomTooltipPieChartS1/>}
          wrapperClassName="ssf-tooltip"
          itemStyle={itemStyle} />
      </PieChart>
    </ResponsiveContainer>
  );
}

export const HBarChartS1 = (props) => {
  const labelFormatter = (label) => label.toFixed(1)+'%';
  const labelStyle = {fontSize: '0.65rem', whiteSpace: 'no-wrap'};
  const values = props.data.map(e => e.value);
  const [min, max] = [Math.min(...values), Math.max(...values)];
  const domain = [ min < 0 ? 'auto' : 0, max > 0 ? 'auto' : 0 ]

  return (
    <ResponsiveContainer width="100%" height="100%" >
      <BarChart data={props.data} margin={{top: 4, right: 10, left: 10, bottom: 4}} layout="vertical">
        <XAxis type="number" hide={true} />
        <YAxis dataKey="name" type="category" width={260} tick={labelStyle} minTickGap={0} tickLine={false} />
        <Bar dataKey="value" unit="%" fill="#8884d8" maxBarSize={8} isAnimationActive={false}>
          <LabelList dataKey="value" position="right" offset={5} formatter={labelFormatter} className="ssf" />
          { props.data.map((v, i) => (
              <Cell key={`cell-${i}`} fill={v.color}/>
            )) }
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}


export const DonutChartS1 = (props) => {
  const itemStyle = {fontSize: '0.75rem', padding: '0'}
  const { data } = props;
  const width = (props.width) ? props.width : "100%";
  const height = (props.height) ? props.height : "100%";
  // console.log(data);
  return (
    <ResponsiveContainer width={width} height={height}>
    	<PieChart>
        <Pie data={data} dataKey="value" nameKey="name" unit="%"
          outerRadius={'100%'} innerRadius={'55%'}
          isAnimationActive={false} >
          { data.map((v, i) => <Cell key={i} fill={v.color}/>) }
        </Pie>
        <Tooltip
          content={<CustomTooltipDonutChartS1 unit="%" formatter={props.formatter} />}
          wrapperClassName="ssf-tooltip"
          itemStyle={itemStyle} />
      </PieChart>
    </ResponsiveContainer>
  );
}

const CustomTooltipDonutChartS1 = (props) => {
  const { active, payload, unit, formatter } = props;
  if (active && payload[0]) {
    const value = formatter ? formatter(payload[0].value) : payload[0].value.toFixed(2)+unit;
    return (
      <div className="recharts-default-tooltip ssf-tooltip-custom">
        <div>
          <span className="label mr-0">{payload[0].name} : {value}</span>
        </div>
      </div>
    );
  }

  return null;
}


/*
        <Bar dataKey="value" unit="%" fill="#8884d8" label={{ position: 'top', fill: '#aaa', fontSize: 10 }} maxBarSize={50} >
          <LabelList dataKey="value" position="top" formatter={labelFormatter} />
*/

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import { createLoadingSelector } from 'selectors/loading-selector';
import { equityBatchRequest } from 'actions/buildportfolio';

import EquityContent from './EquityContent';
import CardLoader from '../../../Elements/CardLoader';

class EquityTab extends Component {
  componentDidMount() {
    const { equityBatchRequest, preSelectedTickers, equityTabData, buildportfolio } = this.props;
    let preEquityTickers = [];
    if (equityBatchRequest) {
      if(preSelectedTickers && preSelectedTickers.length > 0) {
        preEquityTickers = preSelectedTickers.filter(e => e.category == 'equity');
      }
      if (equityTabData.length === 0) {
        equityBatchRequest({ query: 'pf_builder_equity', tab: 'equityTab', preEquityTickers: preEquityTickers.length > 0 ? preEquityTickers: null, allStates: buildportfolio});
      }
    }
  }

  componentWillReceiveProps(newProps) {
    const { equityTabData, tabHeaderMenu, selectedFunds, buildportfolio } = newProps;
    // console.log(buildportfolio.tabHeaderMenu);
    // console.log(buildportfolio.oldHeaderMenuState);
    // console.log(oldBuildportfolioState);
    // console.log(buildportfolio.activeTab);

    if (buildportfolio.oldHeaderMenuState && typeof buildportfolio.oldHeaderMenuState !== 'undefined' && buildportfolio.oldHeaderMenuState.length) {
      const qw = buildportfolio.oldHeaderMenuState.filter((l) => l.key === 'equityTab')[0]; // 100
      const lw = buildportfolio.tabHeaderMenu.filter((k) => k.key === 'equityTab')[0]; // 60

      // console.log('old equityTab', qw.sliderValue, 'new equityTab', lw.sliderValue);

      if (qw.sliderValue !== lw.sliderValue) {
        if (Object.keys(selectedFunds) && Object.keys(selectedFunds).length > 0) {
          // console.log('pratik 1')
          const diff = lw.sliderValue - qw.sliderValue;
          // console.log('sid diff---->', diff);
          const getActiveFunds = Object.keys(selectedFunds).filter((ty) => (selectedFunds[ty].length > 0 && equityTabData.find((er) => er.query === ty).sliderValue > 0));

          if (getActiveFunds.length > 0) {
            // console.log('pratik 2')
            let oldSum = 0;
            if (buildportfolio.oldEquityData) {
              // console.log('pratik 3')
              buildportfolio.oldEquityData.forEach((er) => {
                // console.log(er.sliderValue)
                oldSum += er.sliderValue;
              })
            }
            getActiveFunds.map((rt) => {
              if (rt) {
                // console.log('pratik 4')
                const fd = equityTabData.find((er) => er.query === rt);
                // console.log('sid1761', oldSum)
                // console.log(fd.query);
                if (fd) {
                  // console.log('pratik 5')
                  //USA sliderValue
                  // console.log(`${fd.query} sliderValue`);
                  // equityTabData.find((er) => er.query === rt).sliderValue = newProps.tabHeaderMenu.find((item) => item.key === 'equityTab').sliderValue/getActiveFunds.length;

                  if(oldSum != diff){
                    // console.log('pratik 6')
                    if (getActiveFunds.length === 1) {
                      // console.log('pratik 7')
                      equityTabData.find((er) => er.query === rt).sliderValue = newProps.tabHeaderMenu.find((item) => item.key === 'equityTab').sliderValue/getActiveFunds.length;
                    } else if (getActiveFunds.length > 1 && buildportfolio.oldEquityData && buildportfolio.oldEquityData.find((er) => er.query === rt) && buildportfolio.oldEquityData.find((er) => er.query === rt).sliderValue > 0) {
                      // console.log('pratik 8')
                      // console.log(buildportfolio.oldEquityData.find((er) => er.query === rt).sliderValue);
                      // console.log(oldSum);
                      // console.log(diff);
                      // console.log((buildportfolio.oldEquityData.find((er) => er.query === rt).sliderValue / oldSum)*diff);
                      equityTabData.find((er) => er.query === rt).sliderValue = buildportfolio.oldEquityData.find((er) => er.query === rt).sliderValue + ((buildportfolio.oldEquityData.find((er) => er.query === rt).sliderValue == 0 ? 0 : buildportfolio.oldEquityData.find((er) => er.query === rt).sliderValue / oldSum)*diff);
                    } else if (getActiveFunds.length > 1 && buildportfolio.oldEquityData && buildportfolio.oldEquityData.find((er) => er.query === rt) && buildportfolio.oldEquityData.find((er) => er.query === rt).sliderValue === 0) {
                      // console.log('pratik 9')
                      if (equityTabData.find((er) => er.query === rt).sliderValue > 0) {
                        // console.log('pratik 10')
                        equityTabData.find((er) => er.query === rt).sliderValue = newProps.tabHeaderMenu.find((item) => item.key === 'equityTab').sliderValue/getActiveFunds.length;
                      }
                    }
                  }

                  // USA selectedFunds sliderValue
                  // console.log(`${fd.query} selectedFunds sliderValue`);
                  // console.log(rt, selectedFunds[rt]);
                  if (selectedFunds[rt] && selectedFunds[rt].length > 0) {
                    // console.log('pratik 11')
                    selectedFunds[rt].map((o) => {
                      if (o) {
                        // console.log('pratik 12')
                        if(selectedFunds[rt].length == 1 ){
                          if(equityTabData.find((er) => er.query === rt)){
                            o._sliderValue = equityTabData.find((er) => er.query === rt).sliderValue/selectedFunds[rt].length;
                          }
                          // console.log(o._sliderValue);
                        }
                        else if(selectedFunds[rt].length > 1 && o._sliderValue > 0 && buildportfolio.oldEquityTab.selectedFunds[rt] && _.sumBy(buildportfolio.oldEquityTab.selectedFunds[rt], '_sliderValue') <= 0){
                          //tab chnage condiiton on zero increase slider
                          // console.log('pratik 12.3.1',  o.ticker, o._sliderValue)
                          if(equityTabData.find((er) => er.query === rt)){
                            o._sliderValue = equityTabData.find((er) => er.query === rt).sliderValue/selectedFunds[rt].length;
                          }
                          // console.log('pratik 12.3.1', o.ticker, o._sliderValue)
                        }
                        else if(selectedFunds[rt].length > 1 && (o._sliderValue <= 0 || isNaN(o._sliderValue) || !o._sliderValue)) {
                          // console.log('pratik 12.3',  o.ticker, o._sliderValue)
                          if(equityTabData.find((er) => er.query === rt)){
                            o._sliderValue = equityTabData.find((er) => er.query === rt).sliderValue/selectedFunds[rt].length;
                          }
                          // console.log('pratik 12.3.1', o.ticker, o._sliderValue)
                        }
                        else if(selectedFunds[rt].length > 1 && o._sliderValue > 0) {
                          const oldFound = buildportfolio.oldEquityTab.selectedFunds[rt].find(x => x.ticker == o.ticker);
                          if(oldFound) {
                            let diff = equityTabData.find((er) => er.query === rt).sliderValue - buildportfolio.oldEquityData.find((er) => er.query === rt).sliderValue;
                            let ratio = oldFound._sliderValue == 0 ? 0 : oldFound._sliderValue / buildportfolio.oldEquityData.find((er) => er.query === rt).sliderValue;
                            let wt = diff * ratio;
                            o._sliderValue = oldFound._sliderValue + wt;
                            // console.log('pratik 121', oldFound, diff, ratio);
                          }
                        }
                        // console.log('pratik 12.1', o.ticker, o._sliderValue)      
                      }
                    })
                  }
                  //USA  fund list sliderValue
                  // console.log(`${fd.query} fund list sliderValue`);
                  // equityTabData.map((l) => {
                  //   if (l.query === rt) {
                  //     l.result.funds.map((w) => {
                  //       w._sliderValue = 0;
                  //       // const found = getActiveFunds.find((o) => o.ticker === w.ticker);
                  //       if (rt.ticker === w.ticker) {
                  //         // console.log(rt, w.ticker);
                  //         w._sliderValue = l.sliderValue/selectedFunds[rt].length;
                  //       }
                  //     });
                  //   }
                  // });

                  equityTabData.map((l) => {
                    // console.log('pratik 13')
                    l.result.funds.map((w) => {
                      w._sliderValue = 0;
                      if (selectedFunds[l.query] && typeof selectedFunds[l.query] !== 'undefined') {
                        // console.log('pratik 14')
                        const found = selectedFunds[l.query].find((o) => o.ticker === w.ticker);
                        const oldFound = buildportfolio.oldEquityTab.selectedFunds[l.query] && buildportfolio.oldEquityTab.selectedFunds[l.query].find(x => x.ticker == w.ticker);
                        // console.log('pratik 14.1', w.ticker, found, selectedFunds[l.query], oldFound, oldFound && oldFound._sliderValue)
                        if (found && typeof found !== 'undefined' && (found.ticker === w.ticker)) {
                          // console.log('pratik 15', w.ticker, l.query)
                          if(selectedFunds[l.query].length == 1){
                            // console.log('pratik 15.01', w.ticker)
                            w._sliderValue = l.sliderValue/selectedFunds[l.query].length;
                          }
                          else if(selectedFunds[l.query].length > 1 && oldFound && oldFound._sliderValue > 0) {
                            // console.log('pratik 15.02', w.ticker, l.query)
                            const oldFound = buildportfolio.oldEquityTab.selectedFunds[l.query].find(x => x.ticker == w.ticker);
                            if(oldFound){
                              // console.log('pratik 15.03', w.ticker)
                              let diff = l.sliderValue - buildportfolio.oldEquityData.find((er) => er.query === l.query).sliderValue;
                              let ratio = oldFound._sliderValue == 0 ? 0 : oldFound._sliderValue / buildportfolio.oldEquityData.find((er) => er.query === l.query).sliderValue;
                              let wt = diff * ratio;
                              w._sliderValue = oldFound._sliderValue + wt;
                              // console.log('pratik 15.04', oldFound, diff, ratio);
                            }
                          }
                          else if(selectedFunds[l.query].length > 1 && oldFound && oldFound._sliderValue <= 0) {
                            // console.log('pratik 15.05', w.ticker)
                            w._sliderValue = l.sliderValue/selectedFunds[l.query].length;
                            // console.log('pratik 15.06', w.ticker, w._sliderValue)
                          }
                          // console.log('pratik 15.1', w.ticker, w._sliderValue, l.query)   
                        }
                      }
                    });
                  });

                }
              }
            });
          }
          else if(qw.sliderValue == 0 && Object.keys(selectedFunds).filter((ty) => (selectedFunds[ty].length > 0)).length > 0){
            console.log('pratik 21')
            const selFunds = Object.keys(selectedFunds).filter((ty) => (selectedFunds[ty].length > 0));
            const diff = lw.sliderValue - qw.sliderValue;
            // console.log('pratik diff---->', diff);
            selFunds.map((rt) => {
              if (rt) {
                console.log('pratik 22', selFunds)
                const fd = equityTabData.find((er) => er.query === rt);
                console.log('pratik 23', fd)
                if (fd) {
                  equityTabData.find((er) => er.query === rt).sliderValue = lw.sliderValue / selFunds.length;
                  console.log('pratik 24', equityTabData.find((er) => er.query === rt).ticker, equityTabData.find((er) => er.query === rt).sliderValue, lw.sliderValue, selFunds.length)
                }
              }
            })
          }
        } else {
          console.log('----- exit 1-----');
        }
        console.log('-----------------');
      } else {
        console.log('EQUAL');
      }

    }

    // when sliderValue change for [US, DM, EM]
    // console.log(buildportfolio.oldBuildportfolioState);
    if (buildportfolio.oldBuildportfolioState && typeof buildportfolio.oldBuildportfolioState !== 'undefined') {
      const { oldBuildportfolioState } = newProps.buildportfolio;
      const oldEquityTabSelectedFunds = oldBuildportfolioState.equityTab.selectedFunds;

      if (oldEquityTabSelectedFunds && typeof oldEquityTabSelectedFunds !== 'undefined') {

        const qw = (Object.keys(oldEquityTabSelectedFunds));
        const lw = (Object.keys(newProps.selectedFunds));

        if (qw.length !== lw.length) {
          if (lw && lw.length > 0) {
            const getActiveFunds = lw.filter((ty) => (lw[ty] && lw[ty].length > 0));
            // console.log(getActiveFunds);

            if (getActiveFunds.length > 0) {
              getActiveFunds.map((rt) => {
                if (rt) {
                  const fd = equityTabData.find((er) => er.query === rt);
                  // console.log(fd);
                  if (fd && equityTabData.find((er) => er.query === rt)) {
                    // USA selectedFunds sliderValue
                    if (getActiveFunds[rt] && getActiveFunds[rt].length > 0) {
                      getActiveFunds[rt].map((o) => {
                        if (o) {
                          o._sliderValue = equityTabData.find((er) => er.query === rt).sliderValue/newProps.selectedFunds[rt].length;
                        }
                      })
                    }
                    //USA sliderValue
                    // equityTabData.find((er) => er.query === rt).sliderValue = newProps.tabHeaderMenu.find((item) => item.key === 'equityTab').sliderValue/getActiveFunds.length;
                    //USA  fund list sliderValue
                    equityTabData.map((l) => {
                      l.result.funds.map((w) => {
                        w._sliderValue = 0;
                        if (newProps.selectedFunds[l.query] && typeof newProps.selectedFunds[l.query] !== 'undefined') {
                          const found = newProps.selectedFunds[l.query].find((o) => o.ticker === w.ticker);
                          if (found && typeof found !== 'undefined' && (found.ticker === w.ticker)) {
                            w._sliderValue = equityTabData.find((er) => er.query === rt).sliderValue/newProps.selectedFunds[l.query].length;
                          }
                        }
                      });
                    });
                  }
                }
              })
            }
          }
          console.log('-----------------');
        } else {
          console.log('EQUAL');
        }

      }
    }

  }

  render() {
    const { equityTabLoading, weightingMethodology, equityTab, activeTab, equityTabData, tabHeaderMenu, selectedFunds } = this.props;

    // console.log(equityTabData);

    return (
      <React.Fragment>
        {equityTabLoading ? (
          <CardLoader />
        ) : (
          <EquityContent topTabHeaderMenu={tabHeaderMenu} activeTab={activeTab} data={equityTabData} weightingMethodology={weightingMethodology} equityTab={equityTab}/>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ buildportfolio, loading }) => ({
  buildportfolio,
  preSelectedTickers: buildportfolio.preSelectedTickers,
  equityTabData: buildportfolio.equityTab.data,
  equityTab: buildportfolio.equityTab,
  selectedFunds: buildportfolio.equityTab.selectedFunds,
  equityTabLoading: createLoadingSelector(['EQUITY_BATCH'])({ loading }),
})

const mapDispatchToProps = {
  equityBatchRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EquityTab);

import { createSelector } from 'reselect'
import { ProfitLossColors, RegionColors, SectorColors } from 'data/Colors'
import { cn, SectorsAttrs } from '../DataSet';
import { uniqValues, convertToFloatvalues } from 'Utils'
import moment from 'moment'

export const getAllAccountsState = state => state.accounts;
export const getPortfolioFunds = accounts => accounts.summary.portfolioFunds;
// export const getFinalizePortfolioFunds = accounts => (accounts.finalizeData && typeof accounts.finalizeData.summary !== 'undefined' && typeof accounts.finalizeData.summary.portfolioFunds !== 'undefined') ? accounts.finalizeData.summary.portfolioFunds  : [];

const formatTree = data => {
  if (!data) return data;
  return data.map(e => {
    let out = { name: e.n, size: e.v }
    if (e.sub) out.children = formatTree(e.sub);
    return out;
  })
}

const sum = (arr, key) => {
  return arr.reduce((a, b) => a + (b[key] || 0), 0);
}

const sortFn = attr => (a, b) => {
  // sort descending
  if (a[attr] < b[attr]) return 1
  else if (a[attr] > b[attr]) return -1
  return 0
}

export const getAccountsState = createSelector(
  [ getAllAccountsState ],
  ( accountsStates ) => {
    return accountsStates;
  }
)

// export const getFinalizeSummaryFunds = createSelector(
//   [ getFinalizePortfolioFunds ],
//   ( data ) => {
//     let out = {
//       funds: [], positions: [],
//       priceSectors: [], priceRegions: [],
//       portfolioValue: 0, fundBalance: 0, cashBalance: 0,
//       gainToday: 0, gainMonthly: 0, gainYearly: 0,
//       allocationPriceSectors: [],
//     };
//     if (!data) return out;
//
//     out.funds = data;
//
//     let fundsH = {}, totalMarketValue = 0, totalProfitLoss = 0;
//     let allRegionData = [], uniqueRegions = [],
//       allSectorData = [], uniqueSectors = [];
//
//     for (const e of out.funds) {
//       if (fundsH[e.script] === undefined) fundsH[e.script] = { ...e, _price: 0, symbol: e.script };
//
//       totalMarketValue += e.market_value;
//       out.portfolioValue += (e.price * e.shares);
//       // console.log('sid ankit', e.daily_nav, e.monthly_nav, e.yearly_nav, e.price);
//       if (e.daily_nav !== null ) out.gainToday += (e.price - e.daily_nav) * e.shares;
//       if (e.monthly_nav !== null ) out.gainMonthly += (e.price - e.monthly_nav) * e.shares;
//       if (e.yearly_nav !== null ) out.gainYearly += (e.price - e.yearly_nav) * e.shares;
//
//       fundsH[e.script]._price += e.price;
//
//       // Sectors
//       if (e.sector && Object.keys(e.sector).length > 0) {
//         let sec = SectorsAttrs.map(v => ({ name: v, value: cn(e.sector, v) ? parseFloat((cn(e.sector, v)* 1).toFixed(1)) : 0 }));
//         sec = sec.map((item) => {
//           (!uniqueSectors.includes(item.name)) && uniqueSectors.push(item.name);
//           const obj = {
//             name: item.name,
//             value: (item.value * e.market_value),
//             pl: ((e.price - parseFloat(e.purchase_price)) * e.shares) * (item.value / 100) ,
//           }
//           allSectorData.push(obj);
//         })
//       }
//
//
//       // Regions
//       if (e.region && Object.keys(e.region).length > 0) {
//         let reg = formatTree(cn(e, 'Region'));
//         reg = reg.map((item) => {
//           (!uniqueRegions.includes(item.name)) && uniqueRegions.push(item.name);
//           const region = {
//             name: item.name,
//             size: (sum(item.children, 'size') * e.market_value),
//             pl: ((e.price - parseFloat(e.purchase_price)) * e.shares) * (sum(item.children, 'size') / 100),
//           }
//           return region;
//         })
//         allRegionData.push(reg)
//       }
//
//     }
//
//     let priceSectors = [];
//     if (uniqueSectors.length > 0) {
//       priceSectors = uniqueSectors.reduce((acc, item, i) => {
//         const value = allSectorData.reduce((sum, data) => {
//           if (data.name === item) sum['sum'] = sum['sum'] + data.value;
//           if (data.name === item) sum['plSum'] = sum['plSum'] + data.pl;
//           return sum;
//         }, {
//           sum: 0,
//           plSum: 0,
//         });
//
//         const obj = {
//           name: item,
//           value: (value.sum / totalMarketValue),
//           color: SectorColors[i%SectorColors.length],
//           pl: value.plSum,
//         }
//         acc.push(obj);
//         return acc;
//       }, [])
//     } else out.priceSectors = [];
//
//     out.allocationPriceSectors = priceSectors;
//
//     if (uniqueSectors.length > 0) out.priceSectors = priceSectors.sort((a, b) => b.pl - a.pl)
//                                                                   .filter((item, index) => (index <= 4 && item.pl > 0) || (index >= (priceSectors.length - 5) && item.pl <= 0));
//
//
//     if(uniqueRegions.length > 0) {
//       out.priceRegions = uniqueRegions.reduce((acc, item) => {
//         const size = allRegionData.reduce((sum, data) => {
//           const index = data.findIndex(x => x.name === item)
//           if(index >= 0) sum['sum'] = sum['sum'] + data[index].size;
//           if(index >= 0) sum['plSum'] = sum['plSum'] + data[index].pl;
//           return sum;
//         }, {
//           sum: 0,
//           plSum: 0,
//         });
//         const abc = {
//           name: item,
//           value: (size.sum / totalMarketValue) < 0 ? 0 : (size.sum / totalMarketValue),
//           color: RegionColors[item],
//           pl: size.plSum,
//         }
//         acc.push(abc)
//         return acc;
//       }, []);
//     } else out.priceRegions = [];
//
//     out.funds.sort((a, b) => {
//       return new Date(b.purchase_date) - new Date(a.purchase_date);
//     })
//
//     out.positions = Object.values(fundsH).sort(sortFn('_price'));
//
//     out.tickers = out.positions.map(item => {
//       return { ticker: item.symbol, shares: item.quantity }
//     })
//
//     return out;
//   }
// )

export const getSummaryFunds = createSelector(
  [ getPortfolioFunds ],
  ( data ) => {
    let out = {
      funds: [], positions: [],
      priceSectors: [], priceRegions: [],
      portfolioValue: 0, fundBalance: 0, cashBalance: 0,
      gainToday: 0, gainMonthly: 0, gainYearly: 0,
      allocationPriceSectors: [],
    };
    if (!data) return out;

    out.funds = data;

    let fundsH = {}, totalMarketValue = 0, totalProfitLoss = 0, costOfMyInvestment = 0;
    let allRegionData = [], uniqueRegions = [],
      allSectorData = [], uniqueSectors = [];

    for (const e of out.funds) {
      if (fundsH[e.script] === undefined) fundsH[e.script] = { ...e, _price: 0, symbol: e.script };

      totalMarketValue += e.market_value;
      costOfMyInvestment += (e.shares * e.purchase_price)
      out.portfolioValue += (e.current_price * e.shares);

      // if (e.daily_nav !== null ) out.gainToday += (e.current_price - e.daily_nav) * e.shares;
      if (e.daily_nav !== null && e.purchase_price !== null  && moment().diff(moment(e.purchase_date),'day')<1) {
        e.daily_nav = e.purchase_price;
      }
      if (e.daily_nav !== null ) e.retToday = e.current_price / e.daily_nav-1;
      if (e.daily_nav !== null ) e.retTodayAmount =e.daily_nav *  e.retToday * e.shares;
      if (e.daily_nav !== null ) out.gainToday += e.daily_nav * e.retToday * e.shares;

      // if (e.monthly_nav !== null ) out.gainMonthly += (e.current_price - e.monthly_nav) * e.shares;
      // if (e.monthly_nav !== null && moment().diff(moment(e.purchase_date), 'month') < 1) {
      if (e.monthly_nav !== null && (moment().month() == moment(e.purchase_date).month() && moment().year() == moment(e.purchase_date).year())) {
          // e.retMonthly = e.retToday
          e.monthly_nav = e.purchase_price
      }
      if (e.monthly_nav !== null ) e.retMonthly = e.current_price / e.monthly_nav-1;
      if (e.monthly_nav !== null ) e.retMonthlyAmount = e.monthly_nav * e.retMonthly * e.shares;
      if (e.monthly_nav !== null ) out.gainMonthly += e.monthly_nav * e.retMonthly * e.shares;

      // if (e.yearly_nav !== null ) out.gainYearly += (e.current_price - e.yearly_nav) * e.shares;
      // if (e.yearly_nav !== null && moment().diff(moment(e.purchase_date), 'year') < 1) {
      if (e.yearly_nav !== null && (moment().year() == moment(e.purchase_date).year())) {
          // e.retYearly = e.retMonthly
          e.yearly_nav = e.purchase_price
      }
      if (e.yearly_nav !== null ) e.retYearly = e.current_price / e.yearly_nav-1;
      if (e.yearly_nav !== null ) e.retYearlyAmount = e.yearly_nav * e.retYearly * e.shares;
      if (e.yearly_nav !== null ) out.gainYearly += e.yearly_nav * e.retYearly * e.shares;

      fundsH[e.script]._price += e.current_price;

      // Sectors
      if (e.sector && Object.keys(e.sector).length > 0) {
        let sec = SectorsAttrs.map(v => ({ name: v, value: cn(e.sector, v) ? parseFloat((cn(e.sector, v)* 1).toFixed(1)) : 0 }));
        sec = sec.map((item) => {
          (!uniqueSectors.includes(item.name)) && uniqueSectors.push(item.name);
          const obj = {
            name: item.name,
            value: (item.value * e.market_value),
            pl: ((e.price - parseFloat(e.purchase_price)) * e.shares) * (item.value / 100) ,
          }
          allSectorData.push(obj);
        })
      }

      // Regions
      if (e.region && Object.keys(e.region).length > 0) {
        let reg = formatTree(cn(e, 'Region'));
        reg = reg.map((item) => {
          (!uniqueRegions.includes(item.name)) && uniqueRegions.push(item.name);
          const region = {
            name: item.name,
            size: (sum(item.children, 'size') * e.market_value),
            pl: ((e.price - parseFloat(e.purchase_price)) * e.shares) * (sum(item.children, 'size') / 100),
          }
          return region;
        })
        allRegionData.push(reg)
      }

    }

    let priceSectors = [];
    if (uniqueSectors.length > 0) {
      priceSectors = uniqueSectors.reduce((acc, item, i) => {
        const value = allSectorData.reduce((sum, data) => {
          if (data.name === item) sum['sum'] = sum['sum'] + data.value;
          if (data.name === item) sum['plSum'] = sum['plSum'] + data.pl;
          return sum;
        }, {
          sum: 0,
          plSum: 0,
        });

        const obj = {
          name: item,
          value: (value.sum / totalMarketValue),
          color: SectorColors[i%SectorColors.length],
          pl: value.plSum,
        }
        acc.push(obj);
        return acc;
      }, [])
    } else out.priceSectors = [];

    out.allocationPriceSectors = priceSectors;

    if (uniqueSectors.length > 0) out.priceSectors = priceSectors.sort((a, b) => b.pl - a.pl)
                                                                  .filter((item, index) => (index <= 4 && item.pl > 0) || (index >= (priceSectors.length - 5) && item.pl <= 0));


    if(uniqueRegions.length > 0) {
      out.priceRegions = uniqueRegions.reduce((acc, item) => {
        const size = allRegionData.reduce((sum, data) => {
          const index = data.findIndex(x => x.name === item)
          if(index >= 0) sum['sum'] = sum['sum'] + data[index].size;
          if(index >= 0) sum['plSum'] = sum['plSum'] + data[index].pl;
          return sum;
        }, {
          sum: 0,
          plSum: 0,
        });
        const abc = {
          name: item,
          value: (size.sum / totalMarketValue) < 0 ? 0 : (size.sum / totalMarketValue),
          color: RegionColors[item],
          pl: size.plSum,
        }
        acc.push(abc)
        return acc;
      }, []);
    } else out.priceRegions = [];

    out.funds.sort((a, b) => {
      return new Date(b.purchase_date) - new Date(a.purchase_date);
    })

    out.positions = Object.values(fundsH).sort(sortFn('_price'));

    out.tickers = out.positions.map(item => {
      return { ticker: item.symbol, shares: item.quantity }
    })

    // console.log("costOfMyInvestment ==>", costOfMyInvestment);
    out.costOfMyInvestment = costOfMyInvestment;

    return out;
  }
)

export const getSummaryFundsForReactState = (data) => {
  // data is from --> state.accounts.portfolioFunds
  let out = {
    funds: [], positions: [],
    priceSectors: [], priceRegions: [],
    portfolioValue: 0, fundBalance: 0, cashBalance: 0,
    gainToday: 0, gainMonthly: 0, gainYearly: 0,
    allocationPriceSectors: [],
  };
  if (!data) return out;

  out.funds = data;

  let fundsH = {}, totalMarketValue = 0, totalProfitLoss = 0, costOfMyInvestment = 0;
  let allRegionData = [], uniqueRegions = [],
    allSectorData = [], uniqueSectors = [];

  for (const e of out.funds) {
    if (fundsH[e.script] === undefined) fundsH[e.script] = { ...e, _price: 0, symbol: e.script };

    if (e._realPriceObj && typeof e._realPriceObj.diff !== 'undefined') {
      e.retToday = e._realPriceObj.diff;
      // console.log('=== e.retToday ===>', e.retToday);
      e.current_price = e._realPriceObj.price;
    }

    totalMarketValue += e.market_value;
    costOfMyInvestment += (e.shares * e.purchase_price)
    let marketValue = (e.market_value !== '' && e.market_value !== '--' && !isNaN(e.market_value) && e.market_value !== 'NaN' && e.market_value !== null && !isNaN(convertToFloatvalues(e.market_value))) ? convertToFloatvalues(e.market_value) : 0;
    e.market_value = marketValue;
    out.portfolioValue += (e.type === 'BASKET') ? (marketValue) : (e.current_price * e.shares);

    // if (e.daily_nav !== null ) out.gainToday += (e.current_price - e.daily_nav) * e.shares;
    if (e.daily_nav !== null && e.purchase_price !== null  && moment().diff(moment(e.purchase_date),'day')<1) {
      e.daily_nav = e.purchase_price;
    }
    if (e.daily_nav !== null ) e.retToday = e.current_price / e.daily_nav-1;
    if (!isFinite(e.retToday )) e.retToday = 0;
    if (e.daily_nav !== null ) e.retTodayAmount =e.daily_nav *  e.retToday * e.shares;
    if (e.daily_nav !== null ) out.gainToday += e.daily_nav * e.retToday * e.shares;

    // if (e.monthly_nav !== null ) out.gainMonthly += (e.current_price - e.monthly_nav) * e.shares;
    // if (e.monthly_nav !== null && moment().diff(moment(e.purchase_date), 'month') < 1) {
    if (e.monthly_nav !== null && (moment().month() == moment(e.purchase_date).month() && moment().year() == moment(e.purchase_date).year())) {
        // e.retMonthly = e.retToday
        e.monthly_nav = e.purchase_price
    }
    if (e.monthly_nav !== null ) e.retMonthly = e.current_price / e.monthly_nav-1;
    if (!isFinite(e.retMonthly )) e.retMonthly = 0;
    if (e.monthly_nav !== null ) e.retMonthlyAmount = e.monthly_nav * e.retMonthly * e.shares;
    if (e.monthly_nav !== null ) out.gainMonthly += e.monthly_nav * e.retMonthly * e.shares;

    // if (e.yearly_nav !== null ) out.gainYearly += (e.current_price - e.yearly_nav) * e.shares;
    // if (e.yearly_nav !== null && moment().diff(moment(e.purchase_date), 'year') < 1) {
    if (e.yearly_nav !== null && (moment().year() == moment(e.purchase_date).year())) {
        // e.retYearly = e.retMonthly
        e.yearly_nav = e.purchase_price
    }
    if (e.yearly_nav !== null ) e.retYearly = e.current_price / e.yearly_nav-1;
    if (!isFinite(e.retYearly )) e.retYearly = 0;
    if (e.yearly_nav !== null ) e.retYearlyAmount = e.yearly_nav * e.retYearly * e.shares;
    if (e.yearly_nav !== null ) out.gainYearly += e.yearly_nav * e.retYearly * e.shares;

    fundsH[e.script]._price += e.current_price;

    // Sectors
    if (e.sector && Object.keys(e.sector).length > 0) {
      let sec = SectorsAttrs.map(v => ({ name: v, value: cn(e.sector, v) ? parseFloat((cn(e.sector, v)* 1).toFixed(1)) : 0 }));
      sec = sec.map((item) => {
        (!uniqueSectors.includes(item.name)) && uniqueSectors.push(item.name);
        const obj = {
          name: item.name,
          value: (item.value * e.market_value),
          pl: ((e.price - parseFloat(e.purchase_price)) * e.shares) * (item.value / 100) ,
        }
        allSectorData.push(obj);
      })
    }

    // Regions
    if (e.region && Object.keys(e.region).length > 0) {
      let reg = formatTree(cn(e, 'Region'));
      reg = reg.map((item) => {
        (!uniqueRegions.includes(item.name)) && uniqueRegions.push(item.name);
        const region = {
          name: item.name,
          size: (sum(item.children, 'size') * e.market_value),
          pl: ((e.price - parseFloat(e.purchase_price)) * e.shares) * (sum(item.children, 'size') / 100),
        }
        return region;
      })
      allRegionData.push(reg)
    }
  }

  let priceSectors = [];
  if (uniqueSectors.length > 0) {
    priceSectors = uniqueSectors.reduce((acc, item, i) => {
      const value = allSectorData.reduce((sum, data) => {
        if (data.name === item) sum['sum'] = sum['sum'] + data.value;
        if (data.name === item) sum['plSum'] = sum['plSum'] + data.pl;
        return sum;
      }, {
        sum: 0,
        plSum: 0,
      });

      const obj = {
        name: item,
        value: (value.sum / totalMarketValue),
        color: SectorColors[i%SectorColors.length],
        pl: value.plSum,
      }
      acc.push(obj);
      return acc;
    }, [])
  } else out.priceSectors = [];

  out.allocationPriceSectors = priceSectors;

  if (uniqueSectors.length > 0) out.priceSectors = priceSectors.sort((a, b) => b.pl - a.pl)
                                                                .filter((item, index) => (index <= 4 && item.pl > 0) || (index >= (priceSectors.length - 5) && item.pl <= 0));

  if(uniqueRegions.length > 0) {
    out.priceRegions = uniqueRegions.reduce((acc, item) => {
      const size = allRegionData.reduce((sum, data) => {
        const index = data.findIndex(x => x.name === item)
        if(index >= 0) sum['sum'] = sum['sum'] + data[index].size;
        if(index >= 0) sum['plSum'] = sum['plSum'] + data[index].pl;
        return sum;
      }, {
        sum: 0,
        plSum: 0,
      });
      const abc = {
        name: item,
        value: (size.sum / totalMarketValue) < 0 ? 0 : (size.sum / totalMarketValue),
        color: RegionColors[item],
        pl: size.plSum,
      }
      acc.push(abc)
      return acc;
    }, []);
  } else out.priceRegions = [];

  out.funds.sort((a, b) => {
    return new Date(b.purchase_date) - new Date(a.purchase_date);
  })

  out.positions = Object.values(fundsH).sort(sortFn('_price'));

  out.tickers = out.positions.map(item => {
    return { ticker: item.symbol, shares: item.quantity }
  })

  // console.log("costOfMyInvestment ==>", costOfMyInvestment);
  out.costOfMyInvestment = costOfMyInvestment;
  // console.log('=== out.gainToday ===>', out.gainToday);

  return out;
}

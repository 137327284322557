  import React from 'react'
  import ReactQuill from 'react-quill';
  import 'react-quill/dist/quill.snow.css';
  import './ToolbarFormatterTextArea.scss';

  const CustomToolbar = () => (
  <div id="toolbar">
      {/* <select className="ql-header" defaultValue="">
          <option value="1"></option>
          <option value="2"></option>
          <option value=""></option>
      </select> */}
      {/* <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" /> */}
      {/* <button className="ql-align" value="" />
      <button className="ql-align" value="center" />
      <button className="ql-align" value="right" />
      <select className="ql-color" defaultValue="">
        <option value="red"></option>
        <option value="green"></option>
        <option value="blue"></option>
        <option value="orange"></option>
        <option value="violet"></option>
        <option value="#d0d1d2"></option>
        <option value=""></option>
      </select> */}
    </div>
  )

  export class ToolbarFormatterTextArea extends React.Component {
    constructor (props) {
      super(props)
      // one can import text, html or the raw delta (eg. stored in the db)
      this.state = {
        editorHtml: '',
      };
      // this.onChange = this.onChange.bind(this)
    }

    handleChange = (html) => {
      const { textAreaHtml } = this.props
      if (textAreaHtml) {
        textAreaHtml(html);
      }
    }

    render() {
        const { placeholder, value, style, className } = this.props;
        return (
            <div className={`text-editor ${className ? className : '' }`}>
                <CustomToolbar />
                <ReactQuill
                  value={value}
                  style={style}
                  placeholder={placeholder}
                  modules={ToolbarFormatterTextArea.modules}
                  formats={ToolbarFormatterTextArea.formats}
                  theme={"snow"} // pass false to use minimal theme
                  // onChange={this.handleChange}
                  onChange={(newValue, delta, source) => {
                    if (source === 'user') {
                      this.handleChange(newValue);
                    }
                  }}
                />
            </div>
            )
        }
  }

  /*
   * Quill modules to attach to editor
   *
   */
  ToolbarFormatterTextArea.modules = {
    toolbar: {
      container: "#toolbar"
    }
  }

  /*
   * Quill editor formats
   * See
   */
  ToolbarFormatterTextArea.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color',
    'align'
  ]

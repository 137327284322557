import React from 'react';
import { Row, Col, Menu, Select } from 'antd';
import { BarChartWithAxis, SimpleLineChartWithAxis } from '../../../Elements/Charts';
import { getLineChartMatrix } from '../utils';

const { Option } = Select;

const years = [
  {
    label: '1 year',
    value: 'pfRet1y',
  },
  {
    label: '3 years',
    value: 'pfRet3y',
  },
  {
    label: '5 years',
    value: 'pfRet5y',
  },
];

// risk chart
export const RiskCard = ({ data, yearChange, defaultYears, yearShortName = '3yr',...props }) => {
  if (!data && typeof data === 'undefined' && typeof defaultYears === 'undefined') return null;
	if (!data[defaultYears] && typeof data[defaultYears] === 'undefined') return null;
	const mapData = [
		{
			name: 'Annualized Volatility',
			node: [
				{
					n: 'anVol1yr',
					v: '1 year',
					c: '#1d6091',
				}, {
					n: 'anVol3yr',
					v: '3 years',
					c: '#1d6091',
				}, {
					n: 'anVol5yr',
					v: '5 years',
					c: '#1d6091',
				}],
			years: defaultYears,
			hide: false,
			negative: false,
		},
		{
			name: 'Maximum Drawdown',
			node: [
				{
					n: 'dMax1',
					v: '1 year',
					c: '#1d6091',
				}, {
					n: 'dMax',
					v: '3 years',
					c: '#1d6091',
				}, {
					n: 'dMax5',
					v: '5 years',
					c: '#1d6091',
				}],
			color: '#1d6091',
			years: defaultYears,
			hide: false,
			negative: false,
		},
		{
			name: 'Up Capture',
			node: [
				{
					n: 'up_1yr',
					v: '1 year',
					c: '#1d6091',
				}, {
					n: 'up_3yr',
					v: '3 years',
					c: '#1d6091',
				}, {
					n: 'up_5yr',
					v: '5 years',
					c: '#1d6091',
				}],
			color: '#1d6091',
			years: defaultYears,
			hide: false,
			negative: false,
		},
		{
			name: 'Down Capture',
			node: [
				{
					n: 'down_1yr',
					v: '1 year',
					c: '#1d6091',
				}, {
					n: 'down_3yr',
					v: '3 years',
					c: '#1d6091',
				}, {
					n: 'down_5yr',
					v: '5 years',
					c: '#1d6091',
				}],
			color: '#1d6091',
			years: defaultYears,
			hide: false,
			negative: true,
		}]

  const getNodeData = (node,name='') => {
    return node.map((v) => {
	    let temp = {
		    name: v.v, value: data[v.n], color: v.c,
	    }
	    if(name==='Down Capture'||name==='Up Capture'){
	    	if(data[v.n]===null){
	    		delete temp.value
		    }
	    }
	    return temp
    });
  }

  const rowJson = mapData.filter(r => !r.hide).map((mD, index) => {
	  const obj = {
      className: '',
      style: {},
      gutter: [],
      children: [
        {
          className: 'result-section-label',
          style: { paddingBottom: 4,
	          display: `flex`,
	          alignItems: `center` },
          col: () => (mD.name),
          span: 12,
        },
	      {
		      className: 'dropdown',
		      style: { paddingBottom: 4 },
		      col: ()=>((mD.name === 'Up Capture'||mD.name === 'Down Capture') && <Select
			      onChange={props.handleUpDownDropDownChange}
			      value={props.selectedPortfolioBenchmarkTicker}
			      className='up-down-capture-dropdown'
			      bordered={false}
			      trigger={['click']}
			      dropdownClassName='up-down-capture-menu'>
			      {props.portfolioBenchmarkData && props.portfolioBenchmarkData.map(item => <Option key={item.ticker} value={item.ticker}>{item.ticker}</Option>)}
		      </Select>),
		      span: 12,
	      },
        {
          className: '',
          style: { height: '100%' },
          col: () => (
            <div key={`i-${index}`} className="barchart-block">
              <BarChartWithAxis
                yAxisLabelClassName="y-axis-label"
                xAxisLabelClassName="x-axis-label"
                data={getNodeData(mD.node,mD.name)}
                negativeChart={mD.negative}
              />
            </div>
          ),
          span: 24,
        },
      ],
    };
    return obj;
  });

	const renderRows = rowJson.map((item, index) => {

		const children = item.children.map((o, i) => (
			<Col key={`i-${i}`} span={o.span} className={o.className} style={o.style}>{o.col()}</Col>
			)
		)

		return (
			<Row key={`i-${index}`} gutter={item.gutter} className={item.className} style={item.style}>
				{children}
			</Row>
		)
	})

  return (
    <div className="portfolio-impact-card return-card">
      {renderRows}
    </div>
  )
}

// returns chart
export const ReturnCard = ({ data, yearChange, defaultYears, yearShortName = '3yr' }) => {
  if (!data && typeof data === 'undefined' && typeof defaultYears === 'undefined') return null;

  // console.log(data);
  if (!data[defaultYears] && typeof data[defaultYears] === 'undefined') return null;

  const rawSeries = {
    ykey: 'y1',
    ...makeCurrentSeries(data[defaultYears], yearShortName),
  };

  const filteredSeries = [];
  if (rawSeries && rawSeries._stats) {
    rawSeries._stats.series.map((sD, sI) => {
      if (sD.x && sD.y1 && sD.y3 && sD.y4) {
        filteredSeries.push({
          x: sD.x,
          y1: sD.y1,
          y1T: sD.y3,
          y1Val: sD.y4,
          orgVal: sD.orgVal,
        });
      }
    });
  }

  if (filteredSeries && filteredSeries.length) {
    const lineChartSeries = filteredSeries;//data[defaultYears];

    const onChange = (selected) => {
      if (yearChange) {
        yearChange(selected);
      }
    }

    const summaryStatsData = [
      {
        name: 'Annualized Return',
        value: data[`anRet${yearShortName}`]+'%',
      }, {
        name: 'Annualized Volatility',
        value: data[`anVol${yearShortName}`]+'%',
      }, {
        name: 'Max Drawdown',
        value: data[`dMax`]+'%',
      }, {
        name: 'Sharpe Ratio',
        value: data[`sharpe_${defaultYears}`]+'%',
      }
    ]

    const rowJson = [
      {
        className: '',
        style: {},
        gutter: [],
        children: [
          {
            className: 'result-section-label',
            style: { paddingBottom: 4 },
            col: () => ('Cumulative Return'),
            span: 21,
          },
          {
            className: 'linechart-block',
            style: { margin: '0 0 20px 0' },
            col: () => (
              <SimpleLineChartWithAxis
                id="build-portfolio-linechart"
                lines={[{ ...rawSeries ,name: yearShortName, color: '#1d6091', data: lineChartSeries }]}
                series={lineChartSeries}
                xkey="x"
                ykey={rawSeries.ykey}
              />
            ),
            span: 24,
          },
        ],
      },
      {
        className: '',
        style: {},
        gutter: [],
        children: [
          {
            className: 'result-section-label',
            col: () => ('Summary Statistics'),
            span: 24,
          },
          {
            className: '',
            style: {},
            col: () => (
              <div className="summary-stats-list-container">
                <SummaryStatsList data={summaryStatsData} />
              </div>
            ),
            span: 24,
          },
        ],
      },
    ];

    const renderDesign = getRowDesign({ data: rowJson, cardFor: 'return' });

    return renderDesign;
  } else {
    return null;
  }

}

const getRangeFromSeries = (series) => {
  if (!series && !series.length) return null;
  if (series.length > 2) {
    return {
      start: series[0].d,
      end: series[series.length - 1].d,
    };
  } else {
    return {
      start: series[0].d,
      end: series[0].d,
    };
  }
}

const makeCurrentSeries = (data, label) => {
  const arr1 = [];
  const data1 = JSON.parse(JSON.stringify(data));

  if (data1 && typeof data1 !== 'undefined' && data1.length) {
    // const eleminateLastBlankData = data1[data1.length-1].v === 0 ? data1.splice(0, data1.length - 1, 1): data1;
    data1.map((a) => {
      arr1.push({ d: a.d, y1: a.v, label, v: a.v});
    });

    const range = getRangeFromSeries(arr1);
    if (range) {
      const getCurrentComlSeries = getLineChartMatrix(range, arr1, label);
      return getCurrentComlSeries;
    }
  } else {
    return arr1; // return []
  }
}

const SummaryStatsList = (props) => {
  const { data } = props;
  if (!data || typeof data === 'undefined') return null;

  return data.map((p, index) => {
    return (
      <Row key={`i-${index}`} className="summary-stats-row">
        <Col className="summary-stats-col stats-name">{p.name}</Col>
        <Col className="summary-stats-col stats-value">{p.value}</Col>
      </Row>
    )
  });
}

const getRowDesign = ({ data, cardFor }) => {
  const renderRows = data.map((item, index) => {
    const children = item.children.map((o, i) => <Col key={`i-${i}`} span={o.span} className={o.className} style={o.style}>{o.col()}</Col>);
    return (
      <Row key={`i-${index}`} gutter={item.gutter} className={item.className} style={item.style}>
        {children}
      </Row>
    )
  });

  return (
    <div className={`portfolio-impact-card ${cardFor ? cardFor+'-card' : ''}`}>
      {renderRows}
    </div>
  )
}

export default {
  RiskCard,
  ReturnCard,
};

import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, Card, CardBody, Row, Col, ModalFooter } from 'reactstrap';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import { UncontrolledTooltip, ButtonGroup } from 'reactstrap';
import FlipMove from 'react-flip-move';
import { BarChartS1 } from '../charts/Recharts';
import { cn, CN, Api, ApiHeaders, D3Colors09, D3AppColors09, sponsorLogo, ScoreAttrs, DateRange, calculatorOptions } from '../../DataSet';
import { nameToDateRange, getNest, getNestDefault, arrayToHash, numDc, validWeight, monthToDate, dateToMonth, getImageOrFallback } from '../../Utils';
import { variablesWithoutDateRange } from './utils'
import { calcCombineStatsWeighted, calcScores, calcEnhancementScores } from '../../Stats';
import { DateRangeSelector, FooterActions, TruncatedNameSponsor } from './Common';
import CombineSm from './SmallScreen/Combine';
import moment from 'moment';
import CustomAlert from 'components/CustomAlert';
import PremiumNoticeModal from 'components/PremiumNoticeModal';
import { fundWeights, fetchBasePortfolios } from 'apis/funds';
import Spinner from 'layouts/WebDashboard/SearchResults/Elements/Spinner';
import { addEventToAnalytics } from 'Utils';
import { getPremiumFeatureDetails } from '../../apis/test-drive';

var classNames = require('classnames');

class Combine extends React.Component {
  state = {
    opts: {},
    premiumModal: false,
    reportLoader: false,
    buttonDisabled: true,
    premiumFeature: 'false',
    notify: false,
  }

  componentDidMount() {
    if (this.props.subscriptionPlan) {
      this.props.subscriptionPlan();
    }


    getPremiumFeatureDetails('print_report_combine').then(res => {
      this.setState({ premiumFeature: res.test_drive });
    });

    // Revisit this later:
    // Generate compare data if the user hasn't entered the compare tab
    // And directly moves to the combine tab
    if (this.props.shareCompareState) {
      if (!this.props.trackFundsCompareOrder.length) {
        const items = this.props.items.slice().sort(this.sortFn);
        const filterScoreattrs = this.props.scoreAttrs.filter(x => x.weight !== 0);
        this.props.shareCompareState(items, filterScoreattrs, 'Since ' + dateToMonth(this.props.dateRange['return'].start));
      }
    }
  }

  buyHandler = () => {
    this.props.buyHandler('all');
  }

  optSelHandler = (opts) => {
    this.setState({ opts });
  }

  openPremiumModal = () => {
    const { premiumUser } = this.props;
    if (premiumUser) {
      this.confirmPrintReport();

    } else if (this.state.premiumFeature === 'true') {
      getPremiumFeatureDetails('print_report_combine').then(res => {
        console.log('openPremiumModal function', res.test_drive);
        this.setState({ premiumFeature: res.test_drive });
      });
      this.setState({
        premiumModal: true,
      });
    } else {
      this.setState({
        premiumModal: true,
      });
    }
  }

  testDrivePrintReport = () => {
    getPremiumFeatureDetails('print_report_combine').then(res => {
      console.log('openPremiumModal function', res.test_drive);
      this.setState({ premiumFeature: res.test_drive });
    });
    this.confirmPrintReport();
  }

  openNotify = () => {
    this.setState({
      notify: true,
    });
  }

  notifyToggle = () => {
    this.setState({
      notify: !this.state.notify,
    });
  }

  closePremiumModal = () => {
    this.setState({
      premiumModal: false,
    });
  }

  premiumModalToggle = () => {
    this.setState({
      premiumModal: !this.state.premiumModal,
    });
  }

  confirmPrintReport = () => {
    this.closePremiumModal();
    // this.setState({
    //   reportLoader: true,
    // })
    const { printReport, premiumUser } = this.props;
    const data = {
      ...this.state,
      ...this.props,
    };
    if (printReport && premiumUser) {
      printReport(data);
    } else if (printReport && this.state.premiumFeature === 'true') {
      printReport(data);
    }
  }

  shareCombineState = (state) => {
    // console.log(state);
    const { shareCombineState } = this.props;
    if (shareCombineState) {
      shareCombineState(state);
    }
  }

  onAlertDismiss = () => {
    // console.log('trrrr');
    const { onAlertDismiss } = this.props;
    if (onAlertDismiss) {
      onAlertDismiss();
    }
  }

  loadingStatus = (status) => {
    this.setState({
      buttonDisabled: status,
    });
  }

  render() {
    const { premiumModal, buttonDisabled, notify } = this.state;
    const { reportLoader, reportPDFDownloadStatus, premiumUser } = this.props;
    return (
      <div className="sfm-combine-tab-wrapper position-relative">
        <PremiumNoticeModal
          {...this.props}
          open={premiumModal}
          premiumUser={premiumUser}
          onClickOK={this.confirmPrintReport}
          onClickCancel={this.closePremiumModal}
          onToggle={this.premiumModalToggle}
          notify={notify}
          openNotify={this.openNotify}
          notifyToggle={this.notifyToggle}
          premiumFeature={this.state.premiumFeature}
          modalActionType={'print_report_combine'}
          modalAction={this.testDrivePrintReport}
          featureName='print_report_combine'
        />
        {reportLoader
          && (
            <div className="report-spinner-wrapper">
              <Spinner />
            </div>
          )
        }
        {(reportPDFDownloadStatus && reportPDFDownloadStatus.message !== '' && reportPDFDownloadStatus.downloadStatus !== '')
          && (
            <CustomAlert className="alert-windown-wrapper" color={reportPDFDownloadStatus.downloadStatus} onDismissCallback={this.onAlertDismiss}>
              {reportPDFDownloadStatus.message}
            </CustomAlert>
          )
        }
        <div className="modal-body-ssf">
          <ItemList {...this.props} loadingStatus={this.loadingStatus} shareCombineState={this.shareCombineState} optSelHandler={this.optSelHandler} buyHandler={this.buyHandler} />
        </div>
        {!this.props.mobileDevice &&
          <ModalFooter className="py-2 sfm-modal-footer">
            <Footer {...this.props} accountsPage={typeof this.props.accountsPage !== 'undefined' && this.props.accountsPage} disabled={reportLoader || buttonDisabled} printReport={this.openPremiumModal} buyHandler={this.buyHandler} opts={this.state.opts} />
          </ModalFooter>
        }
      </div>
    )
  }
}

const mapStateToProps = ({ sfm }) => ({
  trackFundsCompareOrder: sfm.trackFundsCompareOrder,
})

export default connect(
  mapStateToProps
)(Combine)

class ItemList extends React.Component {
  state = {
    items: this.props.items,
    opts: { optWt: 0.1 },
    range: { return: 'active', risk: 'active' },
    chart: 'delta',
    returnsChartDateRange: '',
  }

  async componentDidMount() {
    let data = await this.fetchPfData(this.props.query);
    data.portfolios.forEach((item, index) => {
      item.returns.forEach((r, i) => {
        data.portfolios[index].returns[i].v = data.portfolios[index].returns[i].v / 100;
      });
    });
    let portfolios = data.portfolios.map(e => ({ name: e.name, code: e.code, desc: e.desc }));
    let opts = { ...this.state.opts, optPf: getNest([0, 'code'], portfolios) };
    this.setState({ data, portfolios, opts }, this.update);

    const elems = document.querySelectorAll('.custom-scroll');
    if (elems.length) {
      [].forEach.call(elems, function (el) {
        if (el.scrollHeight > el.clientHeight) el.style.paddingRight = '.25rem';
        else el.style.paddingRight = '.25rem';
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items.length !== this.props.items.length) {
      this.setState({ items: this.props.items }, this.update);
    }
  }

  update = async () => {
    //this.setState({ loading: true });
    this.props.loadingStatus(true);
    this.calcStats(this.state.items, this.state.range, ScoreAttrs, this.state.opts);
    this.props.loadingStatus(false);
    //this.setState({ loading: false });
  }

  optSelHandler = (opts) => {
    this.setState({ opts: { ...this.state.opts, ...opts } }, this.update);
    this.props.optSelHandler(opts);
  }

  rangeHandler = (attr, selRange) => {
    const { funds } = this.props;
    let range;
    if (selRange.fromDatePicker) {
      range = selRange;
    } else {
      range = { return: selRange, risk: selRange }
    }
    this.setState({ range }, this.update)
    if (funds) {
      addEventToAnalytics('Combine time period change', 'Combine time period change', 'COMBINE_TIME_PERIOD_CHANGE', { query: funds.query, logId: funds.logId }, true);
    }
  }

  toggleChart = (type) => {
    this.setState({ chart: type });
  }

  calcStats = () => {
    if (!this.state.data) return;

    const { items, range, opts } = this.state;

    let dateRange;
    if (range.fromDatePicker) {
      dateRange = range;
    } else {
      dateRange = this.nameToDateRange(range);
    }

    let pfolio = this.state.data.portfolios.find(e => e.code === opts.optPf);

    items.forEach((e, i) => {
      e._sfWtStats = calcCombineStatsWeighted(e, pfolio, opts.optWt, dateRange);
    })
    let activeDateRange = this.nameToDateRange({ return: 'active', risk: 'active' });
    this.setState({ items, dateRange: activeDateRange });
  }

  sortFn = (a, b) => {
    let [_a, _b] = [a._sfStats, b._sfStats]
    if (_a && _b) {      // descending order
      if (_a.score < _b.score) return 1;
      else if (_a.score > _b.score) return -1;
    }
    return 0;
  }

  nameToDateRange = range => {
    let d = moment.max(this.state.items.map(e => monthToDate(e._start)));
    let sDefault = moment(DateRange.start),
      sActive = moment.max(sDefault, d);
    let maxRange = r => ({ start: r !== 'active' ? sDefault : sActive, end: moment(this.props.dateRange.end) });

    return {
      return: nameToDateRange(range.return, maxRange(range.return)),
      risk: nameToDateRange(range.risk, maxRange(range.risk)),
    };
  }

  async fetchPfData() {
    let portfolio = await fetchBasePortfolios()
    return portfolio;
  }

  scoreAttrsStr = () => {
    let names = ScoreAttrs.map(e => e.name);
    return [names.slice(0, -1).join(', '), names.slice(-1)[0]].join(' and '); // will fail for names.length < 2
  }

  displayYear = (returnsChartDateRange) => {
    if (returnsChartDateRange !== this.state.returnsChartDateRange) {
      this.setState({
        returnsChartDateRange,
      });
    }
  }

  render() {
    if (!this.state.data || !this.state.dateRange) return null;

    const items = this.state.items.filter(e => getNest(['_order', 'stage'], e) === 'combine').sort(this.sortFn);
    const { funds } = this.props;
    const { chart } = this.state;
    let _itemEnhancementScore = calcEnhancementScores(items.map(e => e._sfWtStats), ScoreAttrs);
    const filterScoreattrs = ScoreAttrs.filter(x => x.weight !== 0 && _.find(ScoreAttrs, { name: x.name }));

    if (typeof this.props.accountsPage === 'undefined') {
      if (this.props.shareCombineState) {
        this.props.shareCombineState(this.state);
        this.props.combineStats(items, ScoreAttrs);
      }
    }

    if (this.props.mobileDevice) {
      return (
        <CombineSm {...this.props}
          items={items}
          enhancementScore={_itemEnhancementScore}
          filterScoreattrs={filterScoreattrs}
          opts={this.state.opts}
          allWtStats={this.state.allWtStats}
          allWtStatsBase={this.state.allWtStatsBase}
          range={this.state.range}
          dateRange={this.state.dateRange}
          portfolios={this.state.portfolios}
          displayYear={this.displayYear}
          rangeHandler={this.rangeHandler}
          optSelHandler={this.optSelHandler} />
      )
    }

    return (
      <Row className="no-gutters">
        <Col className="col-12 text-center" style={{ padding: '1rem 1rem 12px 1rem' }}>
          <Title funds={funds} optSelHandler={this.optSelHandler} portfolios={this.state.portfolios} />
        </Col>
        <Col className="col-6 pl-1 pr-2 order-2 h-100">
          <h5 className="mb-0 text-center">
            <Title2 optSelHandler={this.optSelHandler} toggleChart={this.toggleChart} scoreAttrs={ScoreAttrs} />
          </h5>
          <div className="custom-scroll" style={{ height: 360 }}>
            <div className="bg-secondary rounded invisible-scroll" style={{ paddingTop: '2px' }}>
              {chart === 'return'
                ? <ReturnChart
                  range={this.state.range}
                  dateRange={this.state.dateRange}
                  rangeHandler={this.rangeHandler} />
                : filterScoreattrs.map((e, i) =>
                  <Chart attr={e} key={e.code}
                    items={items}
                    allWtStats={this.state.allWtStats}
                    allWtStatsBase={this.state.allWtStatsBase}
                    range={this.state.range}
                    dateRange={this.state.dateRange}
                    displayYear={this.displayYear}
                    rangeHandler={this.rangeHandler} />
                )
              }
            </div>
          </div>
        </Col>
        <Col className="col-6 pl-2 h-100">
          <h5 className="mb-0 text-center">
            {process.env.REACT_APP_SUB_DOMAIN === 'retail'
              ? <div className="w-75 mx-auto pt-2 pb-1 rounded-top">Investments selected to evaluate addition</div>
              : <div className="w-75 mx-auto pt-2 pb-1 rounded-top">Funds selected to evaluate addition</div>
            }
          </h5>
          <div className="custom-scroll" style={{ height: 360 }}>
            <div className="bg-secondary rounded" style={{ paddingTop: '2px' }}>
              <FlipMove>
                {items.map((e, i) => {
                  return (
                    <div key={cn(e, 'Id')}>
                      <ItemCard card={e} idx={i} last={i === items.length - 1}
                        weight={getNest(['_sfWtStats', 'weight'], e)}
                        itemIndex={i}
                        funds={funds}
                        enhancementScore={_itemEnhancementScore}
                        opts={this.state.opts}
                        clearHandler={this.props.clearHandler}
                        buyHandler={this.props.buyHandler} />
                    </div>
                  )
                })}
              </FlipMove>
              {this.state.loading &&
                <div className="overlay active">
                  <div className="text rounded">
                    <i className="fal fa-circle-notch fa-3x fa-spin text-gray-5"></i>
                    <h5 className="mt-2 mb-0">Comparing your selections</h5>
                  </div>
                </div>}
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export class ItemCard extends React.Component {
  wtPc = wt => Math.round(wt * 100);

  state = {
    wtEdit: false,
    weight: this.wtPc(this.props.weight),
    imageValidPath: false,
  }

  componentDidMount() {
    const { card } = this.props;
    if (cn(card, 'Sponsor')) {
      getImageOrFallback(
        sponsorLogo(cn(card, 'Sponsor'))
      ).then(result => {
        if (result) {
          this.setState({ imageValidPath: true });
        } else {
          this.setState({ imageValidPath: false });
        }
      });
    }
  }

  inRef = React.createRef();

  buyHandler = () => {
    const { funds, card } = this.props;
    this.props.buyHandler(card);
    if (funds) {
      addEventToAnalytics('Combine fund buy', 'Combine fund buy', 'COMBINE_FUND_BUY', { query: funds.query, logId: funds.logId, ticker: card.ticker, category: 'advisory_PTC' }, true);
    }
  }

  unselectCard = (item) => {
    const { funds } = this.props;
    this.props.clearHandler(item);
    if (funds) {
      addEventToAnalytics('Combine fund delete', 'Combine fund delete', 'COMBINE_FUND_DELETE', { query: funds.query, logId: funds.logId, ticker: item.ticker }, true);
    }
  }

  callEvent = () => {
    const { funds, card } = this.props;
    if (funds) {
      addEventToAnalytics('Combine score hover', 'Combine score hover', 'COMBINE_SCORE_HOVER', { query: funds.query, logId: funds.logId, ticker: card.ticker }, true);
    }
  }

  render() {
    const { imageValidPath } = this.state;
    const { card, idx, enhancementScore, itemIndex, funds } = this.props;
    // const score = Math.round(getNest(['_sfStats', 'score'], card));
    let score = Math.round(getNest(['score'], enhancementScore[itemIndex]));
    score = (isNaN(score) || score < 0) ? 0 : score;

    return (
      <div style={{ padding: '0 2px 2px' }}>
        <Card className="shadow-sm border-0 card-item">
          <CardBody className="p-0 card">
            <Row className="no-gutters" style={{ height: '109px' }}>
              <Col className="col-12 px-1 pt-1 progress-wrapper">
                <div className="progress-box position-relative">
                  <div className="progress score">
                    {score !== -1 &&
                      <div className="progress-bar" role="progressbar"
                        style={{
                          minWidth: '30px',
                          width: `${score}%`,
                          backgroundColor: score === 0 ? 'transparent' : D3AppColors09[idx % D3AppColors09.length],
                          color: score === 0 ? '#4f5357' : '#fff',
                        }}
                        aria-valuenow={score} aria-valuemin="0" aria-valuemax="100" onMouseEnter={this.callEvent.bind(this)}>
                        <span id={'tooltip-' + idx} className="ml-2">{score}%</span>
                        <UncontrolledTooltip className="w-sm" placement="top" target={'tooltip-' + idx}>
                          The enhancement score reflects the value addition that this security makes to the selected portfolio.
                        </UncontrolledTooltip>
                      </div>}
                  </div>
                </div>
                <div className="close-icon-box" title="Remove Fund">
                  <i className="fal fa-times-circle" onClick={() => this.unselectCard(card)}></i>
                </div>
              </Col>
              <Col className="col-12">
                <div className="selected-card bg-ssf-gray-l5 p-1 h-100 d-flex align-items-center"
                  style={{ borderLeftColor: D3AppColors09[idx % D3AppColors09.length], border: 0, minHeight: 80 }}>
                  {/*card._showSponsoredOnSFM && <div className="sponsored-logo-tag" style={{ top: 5, left: 6 }}>SPONSORED</div>*/}
                  <div className="pr-1 text-center">
                    <h5 className="mb-0 text-blue text-gray-5 ticker-size ticker-price">{cn(card, 'Ticker Short')}</h5>
                    <h3 className={`mb-0 text-info`} style={{ minWidth: '5rem' }}>
                      <span className="ticker-price text-gray-5">$</span>
                      <span className="ticker-price">
                        {
                          cn(card, 'Nav Price') ? cn(card, 'Nav Price').toFixed(1) : ''
                        }
                      </span>
                    </h3>
                  </div>
                  <TruncatedNameSponsor showSponsorLogo={card._showSponsoredOnSFM} imageValidPath={imageValidPath} name={cn(card, 'Name2')} sponsor={cn(card, 'Sponsor')} lines={1} />
                  <div className="ml-auto pl-2 d-flex flex-column h-100">
                    <div className="d-flex align-items-center h-100 btn-wrap m-0">
                      <Button color={"select"} outline
                        className="btn h4 mb-0 text-uppercase font-weight-bold px-3 py-1 modal-btn-br"
                        style={{ marginTop: '2px' }}
                        onClick={() => {
                          this.buyHandler()
                          if (funds) {
                            addEventToAnalytics('Combine Buy Button', 'Combine Buy Button', 'COMBINE_BUY_BUTTON', { query: funds.query, logId: funds.logId, tickers: card.ticker, clickBtn: 'Buy' }, true);
                          }
                        }}>
                        Buy</Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const Footer = (props) => {
  const { funds, items, funds: { chartReportData: { selectedFunds } }, selectedCalculator } = props;
  const disableProposal = selectedFunds.length === 0 || selectedCalculator === calculatorOptions.magnifiactivescreener;
  let tickerNames;
  if (items.length > 0) {
    tickerNames = items.map(x => x.ticker);
  }
  return (
    <React.Fragment>
      <FooterActions hideBack={true} backHandler={() => props.actionHandler('compare')} clearHandler={props.clearAllHandler} />
      {(!props.accountsPage) && (
        <Button color="tools"
          style={{ width: '135px' }}
          className="btn h3 text-uppercase font-weight-bold p-3 mb-0 ml-auto mt-0 secondary-btn"
          title={(!props.disabled && !disableProposal) &&
            "Download search analytics report for the last query"}
          onClick={() => {
            props.printReport()
            if (funds) {
              addEventToAnalytics('Combine print report', 'Combine print report', 'COMBINE_PRINT_REPORT', { query: funds.query, logId: funds.logId, tickers: tickerNames, clickBtn: 'Print Report', category: 'advisory_PTC' }, true);
            }
          }}
          disabled={props.disabled || disableProposal}
        >
          PROPOSAL
        </Button>
      )}

      <Button color="select"
        style={{ width: '135px' }}
        className={`h3 mb-0 ml-3 mt-0 text-uppercase font-weight-bold p-3 secondary-btn ${props.accountsPage ? 'ml-auto' : ''}`}
        onClick={() => {
          props.buyHandler();
          if (funds) {
            addEventToAnalytics('Combine fund buy', 'Combine fund buy', 'COMBINE_FUND_BUY', { query: funds.query, logId: funds.logId, tickers: tickerNames, clickBtn: 'Buy All', category: 'advisory_PTC' }, true);
          }
        }}
      >
        Buy All
      </Button>
    </React.Fragment>
  )
}

export const Chart = (props) => {
  const { attr, items } = props;
  if (!items[0] || !items[0]._sfStats) return null;

  const { name, col, code, colOff, suffix, parser } = attr;
  let data;

  data = items.map((e, i) => {
    let out = {
      name: cn(e, 'Ticker Short'),
      color: D3AppColors09[i % D3AppColors09.length],
    }

    return getNest(['_sfWtStats', colOff], e)
      ? { ...out, value: 0.0, label: 'Inactive' }
      : { ...out, value: numDc(getNest(['_sfWtStats', col], e)) }
  });

  return (
    <div style={{ padding: '0 2px 2px' }}>
      <div className="p-1 bg-white rounded">
        <div className="bg-ssf-gray-l1" style={{ height: '109px' }}>
          <div className="d-flex align-items-center px-2" style={{ height: '20px' }}>
            <div className="h6 mb-0 font-weight-bold">&Delta; {name}</div>
            {!variablesWithoutDateRange.includes(name) &&
              ((props.dateRange[code]) && typeof props.displayYear !== 'undefined' && typeof (props.dateRange[code]).start !== undefined) &&
              (
                <div className="ml-auto">
                  <DateRangeSelector
                    rangeHandler={props.rangeHandler}
                    col={code} range={props.range[code]}
                    activeLabel={'Since ' + dateToMonth(props.dateRange[code].start)}
                    displayYear={props.displayYear}
                    dateRange={props.dateRange[code]}
                    fromDatePicker={props.range.fromDatePicker}
                  />
                </div>
              )
            }
            {!variablesWithoutDateRange.includes(name) &&
              ((props.dateRange[code]) && typeof props.displayYear === 'undefined' && typeof (props.dateRange[code]).start !== undefined) &&
              (
                <div className="ml-auto">
                  <DateRangeSelector
                    rangeHandler={props.rangeHandler}
                    col={code} range={props.range[code]}
                    activeLabel={'Since ' + dateToMonth(props.dateRange[code].start)}
                    dateRange={props.dateRange[code]}
                    fromDatePicker={props.range.fromDatePicker}
                  />
                </div>
              )
            }
          </div>
          <div className="px-1 text-gray-3 chart-wrap" style={{ height: 89 }}>
            <BarChartS1 data={data} suffix={suffix} parser={parser} showTickerOnAxis />
          </div>
        </div>
      </div>
    </div>
  )
}

class Title extends React.Component {
  constructor(props) {
    super(props);

    this.optsPf = props.portfolios;
    this.state = {
      dropdownOpen2: false,
      optPf: this.optsPf[0],
    };
  }

  componentDidMount() {
    this.props.optSelHandler({ optPf: this.state.optPf.code });
  }

  toggle = (name) => {
    this.setState({
      [name]: !this.state[name]
    });
  }

  optPfHandler(optPf) {
    const { funds } = this.props
    this.setState({ optPf });
    this.props.optSelHandler({ optPf: optPf.code });
    if (funds) {
      addEventToAnalytics('Combine Portfolio Dropdown', 'Combine Portfolio Dropdown', 'COMBINE_PORTFOLIO_DROPDOWN', { query: funds.query, logId: funds.logId, optPf: optPf.code }, true);
    }
  }

  render() {
    const { optPf } = this.state;
    return (
      <div className="d-flex justify-content-center" style={{ margin: '-2px 0 -5px' }}>
        {process.env.REACT_APP_SUB_DOMAIN === 'retail'
          ? <h4 className="mb-0"><strong>Evaluate</strong> addition of selected investments to&nbsp;</h4>
          : <h4 className="mb-0"><strong>Evaluate</strong> addition of selected funds to&nbsp;</h4>
        }
        <div className="lh-100">
          <ButtonDropdown isOpen={this.state.dropdownOpen2} toggle={() => this.toggle('dropdownOpen2')} style={{ verticalAlign: 'inherit' }}>
            <DropdownToggle caret color="link" className="text-gray-9 p-0 lh-100 sfmodal-comb-drpdwn">{optPf.name}</DropdownToggle>
            <DropdownMenu className="rounded-0 h6 py-1" style={{ minWidth: '5rem' }}>
              {this.optsPf.map((e, i) => <DropdownItem key={i} className="py-1" onClick={() => this.optPfHandler(e)}>{e.name}</DropdownItem>)}
            </DropdownMenu>
          </ButtonDropdown>
          <h6 className="mb-0 text-gray-7">{optPf.desc}</h6>
        </div>
      </div>
    )
  }
}

class Title2 extends React.Component {
  optsWt = [
    { name: '5%', value: 0.05 },
    { name: '10%', value: 0.10 },
    { name: '15%', value: 0.15 },
    { name: '20%', value: 0.20 },
    { name: '25%', value: 0.25 },
  ]

  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen1: false,
      optWt: this.optsWt[1],
      chart: 'delta',
      dropdownText: this.optsWt[1].name
    };
  }

  componentDidMount() {
    this.props.optSelHandler({ optWt: this.state.optWt.value });
  }

  toggle = (name) => {
    this.setState({
      [name]: !this.state[name]
    });
  }

  optWtHandler = optWt => {
    addEventToAnalytics('Combine Add Impact', 'Combine Add Impact', 'COMBINE_ADD_IMPACT', {}, false)
    this.setState({ optWt, dropdownText: optWt.name });
    this.props.optSelHandler({ optWt: optWt.value });
  }

  handleChange = (e) => {
    const numbersOnly = new RegExp(/^\d*\.?\d*$/);
    const value = e.target.value.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
    if (value === '' || numbersOnly.test(value) && (value > -1 && value < 101)) {
      const dropdownText = value + '%';
      this.setState({
        dropdownText
      });
    } else {
      const dropdownText = value > 100 ? '100%' : this.optsWt[1].name;
      this.setState({
        dropdownText
      });
    }
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleSubmit();
    }
    this.setState({ dropdownOpen1: false });
  }

  handleSubmit = () => {
    if (this.state.dropdownText && this.state.dropdownText !== '%') {
      const value = parseFloat(this.state.dropdownText.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '')) / 100;
      addEventToAnalytics('Combine Add Impact', 'Combine Add Impact', 'COMBINE_ADD_IMPACT', {}, false);
      this.props.optSelHandler({ optWt: value });
    } else {
      this.setState({
        dropdownText: this.optsWt[1].name
      })
      this.props.optSelHandler({ optWt: this.optsWt[1].value });
    }
  }

  toggleChart = (chart) => {
    this.setState({ chart }, () => {
      this.props.toggleChart(chart);
    });
  }

  render() {
    const { optWt } = this.state;
    const ddStyle = { fontWeight: 500, padding: '1px 0px', margin: '-2px 0' }
    return (
      <div className="position-relative">
        <div className="w-75x mx-auto pt-2 pb-1 rounded-top d-flex justify-content-center" style={{ width: '67%' }}>
          Impact of adding&nbsp;
          <div className="custom-combobox">
            {/*<ButtonDropdown isOpen={this.state.dropdownOpen1} toggle={() => this.toggle('dropdownOpen1')} style={{verticalAlign: 'inherit'}}>*/}
            <UncontrolledDropdown direction="down" style={{ verticalAlign: 'inherit' }}>
              <DropdownToggle caret color="dummy" className="border-light rounded-0 h5 lh-100 d-flex" style={ddStyle} >
                {this.state.dropdownText}
              </DropdownToggle>
              <DropdownMenu className="rounded-0 h6 py-1 dropdown-menu-center" style={{ minWidth: '5rem' }}>
                <DropdownItem className="py-0" toggle={false}>
                  <div className="custom-combo-input">
                    <input type="text"
                      value={this.state.dropdownText}
                      placeholder={'Enter Value'}
                      onChange={this.handleChange}
                      onKeyDown={this._handleKeyDown}
                      onBlur={this.handleSubmit}
                    />
                    <i className="fa fa-pencil"></i>
                  </div>
                </DropdownItem>
                <DropdownItem divider style={{ margin: '0.3rem 0' }} />
                {this.optsWt.map((e, i) => <DropdownItem key={i} className="py-1" onClick={() => this.optWtHandler(e)}>{e.name}</DropdownItem>)}
              </DropdownMenu>
            </UncontrolledDropdown>
            {/*</ButtonDropdown>*/}
          </div>
          &nbsp;to portfolio
        </div>
        {/* <div className="position-absolute" style={{right: '4px', top: '2px'}}>
          <ButtonGroup>
            <Button color={this.state.chart === 'delta' ? 'secondary' : 'light'}
              className="lh-100 h5 non-clickable"
              style={{padding: '2px 4px'}}
              onClick={() => this.toggleChart('delta')}>
              <i className="far fa-fw fa-chart-bar" title="&Delta; Return, Risk, Fee"></i>
            </Button>
            <Button color={this.state.chart === 'return' ? 'secondary' : 'light'}
              className="lh-100 h5 non-clickable"
              style={{padding: '2px 4px'}}
              onClick={() => this.toggleChart('return')}>
              <i className="far fa-fw fa-chart-line" title="Cumulative Returns"></i></Button>
          </ButtonGroup>
        </div> */}
      </div>
    )
  }
}

const ReturnChart = (props) => {
  const code = 'return';

  return (
    <div style={{ padding: '0 2px 2px' }}>
      <div className="p-1 bg-white rounded">
        <div className="bg-ssf-gray-l1">
          <div className="d-flex align-items-center px-2" style={{ height: '20px' }}>
            <div className="h6 mb-0 font-weight-bold">Cumulative Return</div>
            {(props.dateRange[code] && typeof (props.dateRange[code]).start !== undefined)
              && (
                <div className="ml-auto">
                  <DateRangeSelector
                    rangeHandler={props.rangeHandler}
                    col={code} range={props.range[code]}
                    activeLabel={'Since ' + dateToMonth(props.dateRange[code].start)} />
                </div>
              )
            }
          </div>
          <div className="px-1 text-gray-3 chart-wrapX">
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '200px' }}>
              <h3 className="pb-3"><i className="fal fa-fw fa-chart-line fa-5x" title="Placeholder"></i></h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

class BuyButton extends React.Component {
  state = { dropdownOpen: false };

  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  render() {
    return (
      <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle color="select" outline={this.props.outline}
          className={"h3 mb-0 text-uppercase font-weight-bold" + this.props.className}
          caret>{this.props.label}</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={this.props.buyHandler}>Individual</DropdownItem>
          <DropdownItem onClick={this.props.buyHandler}>Combination</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}

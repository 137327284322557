import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import ErrorBoundary from 'components/shared/ErrorBoundary'
import IndexPage from './Pages/IndexPage'
import ExecutiveSummary from './Pages/ExecutiveSummary'
import Performance from './Pages/Performance'
import AssetAllocation from './Pages/AssetAllocation'
import Appendix from './Pages/Appendix'
import { GlossaryPage } from './Pages/Glossary'
import { fetchReportData, fetchRatio } from './dataService'
import { PortfolioContextProvider } from './PortfolioContext'

import { getUploadedPortfolioStats } from '../AccountsPage/utils'
import { processAndValidateUrls, sanitizeInput } from '../utils';
import './_index.scss'

class PortfolioOverviewReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: '',
      reportData: '',
      basePortfolio: '',
      rationString: ''
      // reportData: JSON.parse(ReportData[0].json)
    }
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search)
    const rid = query.get('id')
    if (!rid) return
    this.setState({ id: rid })
    fetchReportData(rid).then(reportData => {
      if (reportData) {
        let parseData = JSON.parse(reportData[0].json)
        // fetchPortfolioBenchmark(parseData.portfolio.selectedBenchmark).then(data => {
        let benchMark = ['SPY', 'ACWI'].includes(
          parseData.portfolio.selectedBenchmark
        )
          ? '100/0'
          : parseData.portfolio.selectedBenchmark === 'AGG'
          ? '0/100'
          : parseData.portfolio.selectedBenchmark
        fetchRatio(benchMark.match(/\d+/g).join('/')).then(data => {
          // console.log('data --> ', [data]);
          const portfolioStats = getUploadedPortfolioStats(
            [data],
            '',
            parseData.investment.portfolioValue,
            null
          )
          // console.log('portfolioStats -> ', portfolioStats)
          const rD = {
            reportData: {
              ...parseData,
              reportCreatedAt: reportData[0].created_at
            },
            basePortfolio: { ...data, ...portfolioStats },
            rationString: parseData.portfolio.selectedBenchmark
          };
          // processAndValidateUrls(rD);
          sanitizeInput(rD);
          const updatedData = sanitizeInput(rD);
          this.setState(
            updatedData,
            () => {
              document.body.classList.add('portfolio-reports-v2')
            }
          )
        })
      }
    })
  }

  componentWillUnmount() {
    document.body.classList.remove('portfolio-reports-v2')
  }

  render() {
    const { reportData, basePortfolio, rationString } = this.state
    console.log('====== Portfolio Overview Report =======')
    console.log(reportData)
    console.log(basePortfolio)
    console.log('========================================')

    const { logo } = reportData.portfolio || ''
    const { summary } = reportData
    const { disclosures } = reportData

    let createdDate = moment(
      new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })
    ).format('MM/DD/YYYY')

    let renderAppendix = <></>
    if (typeof summary !== 'undefined') {
      let summaryDetails = summary.portfolioFunds.concat(
        summary.invalidTickerFunds,
        summary.tickerNotSupported
      )
      let feedDate = summaryDetails.find(k => k.feed_date)
      if (feedDate && feedDate.feed_date) {
        createdDate = moment(feedDate.feed_date).format('MM/DD/YYYY')
      }
      let summaryChunk = _.chunk(summaryDetails, 35)
      renderAppendix = summaryChunk.map((data, index) => {
        return <Appendix logo={logo} data={data} index={index} />
      })
    }

    console.log('createdDate -->', createdDate)

    if (!reportData) return null
    return (
      <ErrorBoundary>
        <PortfolioContextProvider value={reportData}>
          <IndexPage
            disclosures={disclosures}
            createdDate={createdDate}
            logo={logo}
          />
          <div className="container h-100">
            <ExecutiveSummary
              disclosures={disclosures}
              createdDate={createdDate}
              logo={logo}
            />
            <Performance
              {...basePortfolio}
              ratioString={rationString}
              disclosures={disclosures}
              createdDate={createdDate}
              logo={logo}
            />
            <AssetAllocation
              disclosures={disclosures}
              createdDate={createdDate}
              logo={logo}
            />
            {renderAppendix}
            <GlossaryPage />
          </div>
        </PortfolioContextProvider>
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = state => ({
  portfolioReport: state.global.portfolioReport
})

const mapDispatchToProps = {
  // setGlobalState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PortfolioOverviewReport)

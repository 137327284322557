import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'antd';
import DropdownContent from './DropdownContent';
import { filterProposals } from 'actions/profile';

class SearchDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false
    }
  }

  dropdownToggle = (visible) =>{
    this.setState({
      showDropdown: !this.state.showDropdown
    })
  }

  handleSearchReset = () => {
    const { initialProposalList } = this.props;
    this.props.filterProposals({
      proposalsList: initialProposalList,
      proposalsfilteredColumn: '',
      proposalsfilteredText: '',
      proposalsClearAllFilter: true
    })
  }

  render() {
    const { title, selector, proposalsfilteredColumn } = this.props;
    const shouldHightlight = selector == proposalsfilteredColumn;
    return (
      <span>
        <Dropdown
          overlay={<DropdownContent
            dropdownToggle={this.dropdownToggle}
            showDropdown={this.state.showDropdown}
            handleSearchReset={this.handleSearchReset}
            {...this.props}
          />}
          trigger={['click']}
          placement="bottomCenter"
          visible={this.state.showDropdown}
          onVisibleChange={this.dropdownToggle}
        >
          <i className={`far fa-fw fa-filter cus-pointer ${shouldHightlight ? 'filter-highlight' : ''}`}
            onClick={e => e.preventDefault()}
            title="Filter Proposals"
          />
        </Dropdown>
        {shouldHightlight && <i className={`far fa-fw fa-times cus-pointer filter-reset`}
          onClick={this.handleSearchReset}
          title="Clear All Filters"
        />}
      </span>
    );
  }
}

const mapStateToProps = ({ profile: { proposalsfilteredColumn, initialProposalList } }) => ({
  proposalsfilteredColumn,
  initialProposalList
})

const mapDispatchToProps = {
  filterProposals
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchDropdown);

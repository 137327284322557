import React from 'react';
import { isUrl } from './utils';

const menuData = [{
  name: 'Enhancer',
  icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
  path: 'enhancer',
    children: [{
      name: 'Enhancer',
      icon: <i style={{ marginRight: 10 }} className="fas fa-gift" />,
      path: 'onboard-screen',
      // hideInMenu: true,
    }, {
      name: 'Dashboard',
      icon: <i style={{ marginRight: 10 }} className="fas fa-gift" />,
      path: 'dashboard',
      // hideInMenu: true,
      children: [{
        name: 'Summary',
        icon: <i style={{ marginRight: 10 }} className="fas fa-gift" />,
        path: 'summary',
        // hideInMenu: true,
      }, {
        name: 'Funds',
        icon: <i style={{ marginRight: 10 }} className="fas fa-gift" />,
        path: 'funds',
        // hideInMenu: true,
      }, {
        name: 'Models',
        icon: <i style={{ marginRight: 10 }} className="fas fa-gift" />,
        path: 'models',
        // hideInMenu: true,
      }],
    }],
  },
];

function formatter(data, parentPath = '/', parentAuthority) {
  return data.map((item) => {
    let { path } = item;
    if (!isUrl(path)) {
      path = parentPath + item.path;
    }
    const result = {
      ...item,
      path,
      authority: item.authority || parentAuthority,
    };
    if (item.children) {
      result.children = formatter(item.children, `${parentPath}${item.path}/`, item.authority);
    }
    return result;
  });
}

export const getMenuData = () => formatter(menuData);

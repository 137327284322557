import React from 'react'
import { connect } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap'
import { PrimaryButton } from 'shared-components'
import { createSubscriptionRequest } from 'actions/profile'
import { Checkbox } from 'antd';
import config from '../../env';
import {addEventToAnalytics} from '../../Utils';
import TermsConditions from '../../layouts/WebDashboard/SearchResults/Elements/Registrations/AgreeTermsNConditions';
import style from 'components/Premium/PremiumPlanDetails.module.scss'


const PREMIUM ='Premium Plan'
const LITE ='Premium Lite'
class PremiumPlanDetails extends React.Component {
  state = {
    isTermsOpen: false,
    enableGetStarted: false,
    tab:PREMIUM,
  }

  constructor(props) {
    super(props)
    // this.props.createSubscriptionRequest();
    this.generateSessionId = this.generateSessionId.bind(this);
    this.subscribeNow = this.subscribeNow.bind(this);
  }

  generateSessionId() {
    this.props.createSubscriptionRequest({ plan_name: this.state.tab },this.subscribeNow)
  }

  subscribeNow(sessionId) {
    if(this.props.featureName){
      addEventToAnalytics('Premium Upgrade','Premium Upgrade','PREMIUM_UPGRADE',{feature_name: this.props.featureName},false);
    }
    // const sessionId = this.props.stripeSessionID;
    if(sessionId){
      this.stripe = window.Stripe(config.stripePublishableKey);
      this.stripe.redirectToCheckout({
        sessionId
      }).then((result) => {
        // console.log("results status",result)
      });
    }
   }

  onTermsAndConditionClick() {
    this.setState({
      isTermsOpen: !this.state.isTermsOpen
    })
  }

  onTermCheckBoxClixk() {
    this.setState({
      enableGetStarted: !this.state.enableGetStarted
    })
  }
  changePlanSelection =(plan)=>{
    this.setState({tab:plan})
  }
  render() {
    const { isTermsOpen, enableGetStarted,tab } = this.state

    return (
      <React.Fragment>
        <div className={style.planHeader}>
          <span onClick={()=>this.changePlanSelection(PREMIUM)} className={`${tab===PREMIUM && style.active}  ${style.subHeader}`}>Premium</span>
          <div className={style.separator}/>
          <span onClick={()=>this.changePlanSelection(LITE)} className={`${tab===LITE && style.active} ${style.subHeader}`}>Premium Lite</span>
        </div>
        <div className={"premium-msg-plan-details "+ (!this.props.showPremium ? 'd-none' : '')}>
          <ul>
            <li key={1}>Selector: Compare funds, SMAs, and models based on customizable portfolio attributes using Magnifi's patented fund Selector. See the impact of adding new holdings into your portfolio, or download the data to excel. </li>
            <li key={2}>Client Proposals: White-label ready proposals document and communicate investment strategies to clients. Use the proposals to store your due diligence for Reg BI, Suitability, and other firm requirements.</li>
            {tab===PREMIUM && <li key={3}>Builder: Magnifi's Builder capability helps construct better portfolios via existing models or from scratch and test changes, add thematic tilts, and more. </li>}
            {tab===PREMIUM && <li key={4}>Enhancer: Produce instant value by showing clients and prospects enhancements to their portfolios. Generate actionable proposals with clear adjustments to meet individual investment objectives.</li>}
            {tab===PREMIUM && <li key={5}>Client Portal: Look across all your client accounts in seconds. Use plain-English search to find specific holdings and allocations within accounts, to adjust exposures quickly. </li>}
          </ul>
          <div className="d-flex justify-content-center terms-condition-select">
            <Checkbox class="custom-checkbox" onClick={()=> this.onTermCheckBoxClixk()}>&nbsp;I agree to the&nbsp;<span className="terms-text" onClick={() => this.onTermsAndConditionClick()}>terms and conditions</span></Checkbox>
          </div>
        </div>
        <ButtonGroup className="cta-wrapper justify-content-center button-blue">
          <PrimaryButton disabled={!this.props.stripeSessionID} className="btn secondary-btn fs-20" type="button" onClick={()=>{this.generateSessionId()}} disabled={!enableGetStarted} style={{ maxWidth: 'auto', margin: 0 }}>
            {(tab === PREMIUM) ? 'GET STARTED WITH PREMIUM' : 'GET STARTED WITH PREMIUM LITE'}
          </PrimaryButton>
        </ButtonGroup>
        {isTermsOpen && <TermsConditions onTermsClick={() => this.onTermsAndConditionClick()}/>}
        {/*<p className="premium-msg-raf">or <a href="#" onClick={this.props.referNow}>Refer a friend</a> <i class="fal fa-info-circle"></i> </p>   */}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ profile }) => ({
  stripeSessionID: profile.stripeSessionID
})

const mapDispatchToProps = {
  createSubscriptionRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PremiumPlanDetails);

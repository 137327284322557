import React, { useContext } from 'react';
import { Row, Col, Table } from 'reactstrap';

const HoldingsTable = (props) => {
  let { type, holdings, cutOff } = props;
  holdings = holdings.slice(0, cutOff);
  const imageSrc = '/assets/images/sponsors/';
  return (
    <React.Fragment>
    	<table className={`w-100 h-100 holdings-table ${type}`}
	        border="0" cellPadding="0" cellSpacing="0">
	      <thead>
	        <tr className={type}>
	          <th>Current Funds</th>
	          <th className="text-center">Weights</th>
	        </tr>
	      </thead>
	      <tbody>
	        {holdings.map((item, index) => {
	          return(
	            <tr key={index}>
	              <td>
	                {item && <span className="holdings-sponsor">
	                  <span style={{
	                  	display: 'block',
	                  	height: '15px',
	                  	marginBottom: '3px',
	                  }}>
		                  <img
		                    src={`${imageSrc}${item.sponsor}.png`}
		                    alt={item.sponsor}
		                    onError={(e) => handleBrokenImage(e)}
		                  />
	                  </span>
	                  <span className="holdings-pf">{item.short_name || item.name}</span>
	                  <span>{item ? item.h_ticker || item.ticker || item.symbol : ''}</span>
	                </span>}
	              </td>
	              <td className="text-center">{item && Math.round(item.weight) + '%'}</td>
	            </tr>
	          )}
	        )}
	      </tbody>
	    </table>
    </React.Fragment>
  )
};

export default HoldingsTable;

const handleBrokenImage = (e) => {
  e.target.style.display = 'none';
}
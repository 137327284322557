import React, { Component } from 'react';
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Form, FormGroup, Row, Col, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import moment from 'moment'
import { isEmpty } from 'lodash';
import {
  DatePicker,
  PrimaryButton
} from 'shared-components'
import {
  investableAssetsList,
} from '../../Constant/FormData'
import * as ProfileApi from '../../../../apis/profile';
import qs from 'query-string'
import { addEventToAnalytics } from 'Utils';
class AddDob extends Component {
  state = {
    investable_assets: false, dateHasValue: '', isFetching: false,
    initialValues: {
      date_of_birth: this.props.profile && this.props.profile.date_of_birth ? (moment().diff(moment(this.props.profile.date_of_birth), 'years') >= 18)
        ? moment(this.props.profile.date_of_birth).utc().format('YYYY-MM-DD')
        : '' : '',
      investable_assets: this.props.profile && this.props.profile.investable_assets ? this.props.profile.investable_assets : ''
    }
  }

  registerSchema = Yup.object().shape({
    date_of_birth: Yup.string()
      .required('Please select your DOB')
      .test(
        'is-valid-date',
        'Invalid Date',
        dob => moment(dob).isValid()
      )
      .test(
        'is-over-18',
        'Age must be over 18',
        dob => moment().diff(dob, 'years') >= 18
      ),
    investable_assets: Yup.string().required('Please select asset amount')
  })


  updateUsersProfile = async (postData, callback) => {
    try {
      this.setState({ isFetching: true })
      let _responseData = await ProfileApi.updateProfile(
        qs.stringify({ ...postData })
      )
      callback({ responseData: _responseData })
      this.setState({ isFetching: false })
    } catch (error) {
      console.log(error)
      this.setState({ isFetching: false })
      if (error && typeof error.response !== 'undefined') {
        if (typeof error.response.data !== 'undefined') {
          //TO DO
        }
      }
    }
  }

  submitValues = (e) => {
    addEventToAnalytics('Recommendor Submit','Recommendor Submit','RECOMMENDOR_SUBMIT');
    this.updateUsersProfile(e, this.props.profileUpdateCallBack);
  }


  render() {

    return (
      <React.Fragment>
        <div className="survey-container register-magnifi-opt-2" style={{ backgroundColor: '#fff', maxWidth: 'unset', width: '45%' }}>
          <Formik
            initialValues={this.state.initialValues}
            onSubmit={this.submitValues}
            validationSchema={this.registerSchema}
            render={props => {
              return (
                <Form
                  className="magnifi-auth-design-container survey-wrapper dob-wrap retail_form_container"
                  noValidate={true}
                  style={{ padding: '35px 35px' }}
                  onSubmit={props.handleSubmit}
                >
                  <div className="header-area">
                    <i
                      className="fal fa-times-circle dob-close-icon"
                      onClick={() => this.props.dobModalToggle()}
                    ></i>
                    <div className="header-title text-center">
                      These fields will guide search suggestions based on user similarity.
                    </div>
                  </div>
                  <div style={{ padding: '0 12%', width: '100%' }}>
                    <Row className="body-wrapper pb-0 form_input_container_gray border_radius_bottom border_radius_top">
                      <Col md="12" lg="12" xl="12">
                        <FormGroup className="element-box dropdown-element pos-rel pb-0">
                          <Label className={props.values.date_of_birth || this.state.dateHasValue ? "label new_label has_value addtion_icon_label" : "label new_label addtion_icon_label"} htmlFor="date_of_birth">
                            Date of Birth
                          </Label>
                          <DatePicker
                            onKeyDown={(e) => {
                              if (e.key === 'Tab') {
                                e.preventDefault();
                                document.getElementById('investable_assets').click();
                                document.getElementById('investable_assets').focus();
                              }
                            }}
                            disabled={this.props.profile && this.props.profile.date_of_birth && this.props.profile.apex && !isEmpty(this.props.profile.apex)}
                            id="date_of_birth"
                            selected={
                              props.values.date_of_birth
                                ? typeof props.values.date_of_birth.getMonth === 'function'
                                  ? props.values.date_of_birth
                                  : moment(props.values.date_of_birth)._d
                                : props.values.date_of_birth
                            }
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            maxDate={
                              new Date(
                                moment()
                                  .subtract(18, 'years')
                                  .format('YYYY-MM-DD')
                              )
                            }
                            autoComplete="off"
                            onChange={(dateInstance, dateString) => {
                              let dob = moment(dateInstance).format('YYYY-MM-DD')
                              if (dateInstance) {
                                props.setFieldValue('date_of_birth', dob)
                              } else {
                                props.setFieldValue('date_of_birth', '')
                              }
                              this.setState({ dateHasValue: dateString })
                            }}
                            className={
                              props.errors.date_of_birth && props.touched.date_of_birth
                                ? 'is-invalid input-area form-control'
                                : this.props.profile && this.props.profile.date_of_birth && this.props.profile.apex && !isEmpty(this.props.profile.apex) ? 'input-area form-control disabled' : 'input-area form-control'
                            }
                          />
                          {props.errors.date_of_birth && props.touched.date_of_birth && (
                            <div className="form-invalid-field">
                              {props.errors['date_of_birth']}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="12" lg="12" xl="12">
                        <FormGroup className="element-box dropdown-element pos-rel" style={{ paddingBottom: '0.85rem' }}>
                          <Label className={props.values.investable_assets ? "label new_label has_value addtion_icon_label" : "label new_label addtion_icon_label"} htmlFor="investable_assets">
                            Investable Assets
                          </Label>
                          <Dropdown

                            className={
                              props.errors.investable_assets && props.touched.investable_assets
                                ? 'is-invalid dropdown-wrapper'
                                : 'dropdown-wrapper'
                            }
                            isOpen={this.state.investable_assets}
                            toggle={() => this.setState({ investable_assets: !this.state.investable_assets }, () => {
                              if (this.state.investable_assets) {
                                setTimeout(() => {
                                  document.getElementById('investable_assets_child0').focus()
                                }, 100)
                              }
                            })}
                          >
                            <DropdownToggle
                              id="investable_assets"
                              value={props.values.investable_assets}
                              style={{ backgroundColor: '#fff' }}
                              className={
                                props.errors.investable_assets &&
                                  props.touched.investable_assets
                                  ? 'is-invalid input-area'
                                  : 'input-area'
                              }
                              caret
                            >
                              {props.values.investable_assets || ''}
                            </DropdownToggle>
                            <DropdownMenu center>
                              {investableAssetsList.map((item, ind) => {
                                return (
                                  <DropdownItem
                                    value={item}
                                    id={'investable_assets_child' + ind}
                                    onClick={e => {
                                      props.setFieldValue(
                                        'investable_assets',
                                        e.target.value
                                      )
                                    }}
                                  >
                                    {item}
                                  </DropdownItem>
                                )
                              })}
                            </DropdownMenu>
                          </Dropdown>
                          {props.errors.investable_assets &&
                            props.touched.investable_assets && (
                              <div className="form-invalid-field">
                                {props.errors['investable_assets']}
                              </div>
                            )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="pb-0">
                      <Col md="12" lg="12" xl="12" className="pl-0 pr-0">
                        <PrimaryButton
                          type="submit"
                          disabled={this.state.isFetching}
                          className="btn primary-btn dob_sub mt-3"
                        >
                          SUBMIT
                        </PrimaryButton>
                      </Col>
                    </Row>
                  </div>
                </Form>
              )
            }}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default AddDob;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, ButtonGroup, Form } from 'reactstrap';
import { CloseButton, PrimaryButton } from 'shared-components';
import InviteFriends from 'layouts/WebDashboard/SearchResults/Elements/InviteFriends';
import { getPremiumFeatureDetails, allowTestDrive } from '../apis/test-drive';
import PremiumPlanDetails from './sf/premiumPlanDetails';
import { addEventToAnalytics } from '../Utils';
import { Collapse, Icon } from 'antd';

const { Panel } = Collapse;


const PremiumNoticeModal = ({ modalAction, modalActionType, onClickOK, onClickCancel, onToggle, premiumUser, premiumFeature, featureName, ...props }) => {
  const { open, referralPage, match, loggedIn } = props;
  const [isInviteFriendsModal, setInviteFriendsModalOpen] = useState(false);
  const [notifyModal, setNotifyModalOpen] = useState(false);
  const [isWeightsModalOpen, setWeightsModalOpen] = useState(false);
  const [isFeatureModalOpen, setFeaturesModalOpen] = useState(false);
  const modalHeader = {
    testDrive: 'You have discovered a premium feature!',
    referal: 'You have discovered a premium feature!'
  }
  //const [isTermModalOpen, isTermModalOpen] = useState(false);
  const onFeaturesModalOkClick = () => {
    setFeaturesModalOpen(false);
    cancel();
    if (premiumUser) onClickOK();
  }

  const referNow = () => {
    if (!loggedIn) {
      props.history.push('/login');
    }
    else {
      onToggle();
      setFeaturesModalOpen(false);
      if (!premiumUser) setInviteFriendsModalOpen(true);
    }
  }

  const testDrive = () => {
    if (featureName) {
      addEventToAnalytics('Test Drive', 'Test Drive', 'TEST_DRIVE', { feature_name: featureName }, false);
    }
    allowTestDrive(modalActionType);
    if (modalActionType === 'download_excel') {
      getPremiumFeatureDetails(modalActionType).then(res => {
        modalAction(res.test_drive);
      });
    } else if (modalActionType === 'my_calculator') {
      // console.log(premiumUser, premiumFeature, modalActionType, 'my calculator')
      modalAction();
    } else if (modalActionType === 'print_report_combine') {
      modalAction();
    } else if (modalAction) {
      modalAction();
    }
    onToggle();
    setFeaturesModalOpen(false);
  }

  const testApiCall = () => {
    getPremiumFeatureDetails('enhancement').then(res => console.log(res))
    getPremiumFeatureDetails('my_calculator').then(res => console.log(res))
    getPremiumFeatureDetails('download_excel').then(res => console.log(res))
  }

  const cancel = () => {
    if (featureName) {
      console.log('here', featureName)
      addEventToAnalytics('Test Drive Cancel', 'Test Drive Cancel', 'TEST_DRIVE_CANCEL', { feature_name: featureName }, false);
    }
    if (match && match.path === '/investments' || window.location.pathname === '/investments' || window.location.pathname.includes('/build-portfolio/') || window.location.pathname.includes('/enhancer') || window.location.pathname === '/advisor/account') {
      props.history.push('/securities');
    }
    onToggle();
    setFeaturesModalOpen(false);
  }

  const inviteHandleCallBack = (response) => {
    if (response && !premiumUser) {
      setInviteFriendsModalOpen(false);
    }
  }

  const cancelInvite = () => {
    if (match && match.path === '/investments' || window.location.pathname === '/investments' || window.location.pathname === '/build-portfolio/onboard-screen') {
      props.history.push('/securities');
      setInviteFriendsModalOpen(false);
    } else {
      setInviteFriendsModalOpen(false);
    }
  }

  const closeOkGotIt = () => {
    if (match && match.path === '/investments' || window.location.pathname === '/investments' || window.location.pathname === '/build-portfolio/onboard-screen') {
      props.history.push('/securities');
      setNotifyModalOpen(false);
    } else {
      setNotifyModalOpen(false);
    }
  }

  if (referralPage) {
    if (props.inviteSuccess && open) {
      if (props.setProfileState) {
        props.setProfileState({
          inviteSuccess: undefined,
        });
        onToggle();
        props.openNotify();
      }
    }
  } else {
    if (props.inviteSuccess && isInviteFriendsModal) {
      if (props.setProfileState) {
        props.setProfileState({
          inviteSuccess: undefined,
        });
        setInviteFriendsModalOpen(false);
        setNotifyModalOpen(true);
      }
    }

  }

  const premiumMsgModal = (
    <Modal
      isOpen={open}
      toggle={cancel}
      centered
      keyboard={true}
      backdrop
      className="premium-msg-modal"
      backdropClassName="premium-feature-process-modal-backdrop"
    >
      <ModalBody className="ssf-modal__body">
        <div className="premium-feature-process-modal-container">
          <div className="header-wrapper">
            <h1 className={"text-align-left " + ((premiumFeature !== 'false') ? 'pb-4' : '')}>{premiumFeature !== 'false' ? modalHeader.testDrive : modalHeader.referal}</h1>
            {premiumFeature == 'false' && (<p className="text-align-left pb-2" style={{ marginTop: '-5px' }}>Access this and all other premium features for $500 per month or try Magnifi Pro for $250 per month.</p>)}
          </div>
          <i className="fal fa-times-circle premium-feature-completed-close" onClick={cancel}></i>

          {premiumFeature === 'false' &&
            <span>
              <PremiumPlanDetails featureName={featureName} referNow={referNow} showPremium />
            </span>
          }
          {(premiumUser) && (
            <ButtonGroup className="cta-wrapper justify-content-left">
              <PrimaryButton className="btn secondary-btn" type="button" onClick={onFeaturesModalOkClick} style={{ maxWidth: 150 }}>
                OK, GOT IT
              </PrimaryButton>
            </ButtonGroup>
          )}
          {(!premiumUser && premiumFeature !== 'false') && (
            <ButtonGroup className="cta-wrapper justify-content-center">
              <PrimaryButton className="btn secondary-btn fs-20" type="button" onClick={testDrive} style={{ maxWidth: 218, margin: 0 }}>
                TRY IT FOR FREE
              </PrimaryButton>
            </ButtonGroup>
          )}
        </div>
      </ModalBody>
    </Modal>
  );

  return ([
    (typeof referralPage === 'undefined' && premiumMsgModal),
    <Modal
      isOpen={typeof referralPage === 'undefined' ? isInviteFriendsModal : open}
      toggle={typeof referralPage === 'undefined' ? () => cancelInvite : cancel}
      centered
      keyboard={true}
      backdrop
      className="premium-feature-process-modal"
      backdropClassName="premium-feature-process-modal-backdrop"
    >
      <ModalBody className="ssf-modal__body">
        <div className="premium-feature-process-modal-container">
          <i className="fal fa-times-circle premium-feature-completed-close" onClick={typeof referralPage === 'undefined' ? () => cancelInvite() : cancel}></i>
          <InviteFriends />
        </div>
      </ModalBody>
    </Modal>,
    <Modal
      isOpen={typeof referralPage === 'undefined' ? notifyModal : props.notify}
      toggle={typeof referralPage === 'undefined' ? () => setNotifyModalOpen(false) : props.notifyToggle}
      centered
      keyboard={true}
      backdrop
      className="premium-msg-modal"
      backdropClassName="premium-feature-process-modal-backdrop"
    >
      <ModalBody className="ssf-modal__body">
        <div className="premium-feature-process-modal-container">
          <div className="header-wrapper">
            <h1 className="text-align-left">Confirmation</h1>
          </div>
          <i className="fal fa-times-circle premium-feature-completed-close" onClick={typeof referralPage === 'undefined' ? () => closeOkGotIt() : props.notifyToggle}></i>
          <p className="content-wrapper">
            <span>
              Your invitation has been sent to
              {` ${props.inviteSentTo ? (props.inviteSentTo.length === 1 ? props.inviteSentTo[0] : [props.inviteSentTo.slice(0, props.inviteSentTo.length - 1).join(", "), props.inviteSentTo[props.inviteSentTo.length - 1]].join(" and ")) : ''}`}.
            </span>
            <br />
          </p>
          <ButtonGroup className="cta-wrapper justify-content-left">
            <PrimaryButton className="btn secondary-btn" type="button" onClick={typeof referralPage === 'undefined' ? () => closeOkGotIt() : props.notifyToggle} style={{ maxWidth: 150 }}>
              OK, GOT IT
            </PrimaryButton>
          </ButtonGroup>
        </div>
      </ModalBody>
    </Modal>,
  ])
}

PremiumNoticeModal.defaultProps = {
  scoreAttrs: []
}

export default PremiumNoticeModal;

import React from 'react'
import { Alert } from 'reactstrap'

export default function Notification(props) {
  const {
    isNotifyOpen,
    notifyClassName,
    notifyBody,
  } = props
  return isNotifyOpen
      &&
      <div className={notifyClassName}>
        {notifyBody}
      </div>
}


import React from 'react';
import { Modal, ModalBody, ButtonGroup, Button, Spinner } from 'reactstrap';
import { CloseButton } from 'shared-components';
import { doNotLeaveButtons } from '../../DataSet';
import { addEventToAnalytics } from 'Utils';

import '../DoNotLeave/doNotLeave.scss';

const FinancialProfPop = (props) => {
    const { open, cancel, history } = props;
    const onClose = () => {
        cancel();
    }
    // newTrendingSearches.searches.length = 5;
    return (<Modal
        isOpen={open}
        toggle={onClose}
        centered
        keyboard={true}
        backdrop
        className="premium-msg-modal modalFeature"
        backdropClassName="premium-feature-process-modal-backdrop"
    >
        <ModalBody className="ssf-modal__body">
            <div className="premium-feature-process-modal-container">
                <div className="header-wrapper">
                    <h1 className="text-align-center pb-2 modal_title_box">Thank you for your input!</h1>
                    <p className="text-align-center pb-2 h3_Title">Did you know that we have special solutions for advisors and asset managers?</p>
                </div>
                <i className="fal fa-times-circle premium-feature-completed-close" onClick={onClose}></i>
                <div className="botBtn">
                    <Button type="button" className="btns-do-not-leave" onClick={() => {
                        addEventToAnalytics('Magnifi for Financial Advisors', 'Magnifi for Financial Advisors', 'MAGNIFI_FOR_FINANCIAL_ADVISORS', {});
                        window.location.href = 'https://magnifi.com/pages/advisors/';
                    }}>
                        Magnifi for Financial Advisors
                    </Button>
                    <Button type="button" className="btns-do-not-leave" onClick={() => {
                        addEventToAnalytics('Magnifi for Asset Managers', 'Magnifi for Asset Managers', 'MAGNIFI_FOR_ASSET_MANAGERS', {});
                        window.location.href = 'https://magnifi.com/pages/asset-managers/';
                    }}>
                        Magnifi for Asset Managers
                    </Button>
                </div>
            </div>
        </ModalBody>
    </Modal>
    )
}

export default FinancialProfPop;
import React, { Component, useState } from 'react';
import { connect } from 'react-redux'
import { Row, Col, Typography } from 'antd';
import _ from 'lodash';
import { sponsorLogo } from 'DataSet';
import { FormatCurrency } from 'components/reports/shared/Utils';
import { TruncatedText } from 'components/sf/Common';
import { customScroll } from '../../../utils';

const { Paragraph } = Typography;

class DataRow extends Component {
  constructor(props){
    super(props);
  }

  componentDidUpdate() {
    customScroll();
  }

  render() {
    const { data } = this.props;
    const renderRows = data.map((item) => {
      return (
        <Row className="portfolio-funds-list-data-row">
          <Col span={6} className="portfolio-funds-list-data-col">
            {item.h_ticker || item.ticker || item.symbol}
          </Col>
          <Col span={14} className="portfolio-funds-list-data-col">
            <NameSponsor name={item.short_name || item.name} sponsor={item.sponsor} category={item.category} />
          </Col>
          <Col span={4} className="portfolio-funds-list-data-col">{Math.round(item.weight)}%</Col>
        </Row>
      )
    })
    return (
      <React.Fragment>
        <div className="portfolio-funds-list-wrap">
          <Row className="portfolio-funds-list-head-row">
            <Col span={6} className="portfolio-funds-list-head-col">Ticker</Col>
            <Col span={14} className="portfolio-funds-list-head-col">Name</Col>
            <Col span={4} className="portfolio-funds-list-head-col">Weight</Col>
          </Row>
          <div className="portfolio-funds-list-data-body">
            <div className="portfolio-funds-list-data-body-scroll custom-scroll">
              {renderRows}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default DataRow;

const NameSponsor = ({ name, sponsor, category }) => (
  <div className="w-100 name-sponsor-block">
    <div className="pb-1">
      {sponsor ? (
        <ImageOrText img={sponsorLogo(sponsor)} text={sponsor} />
      ) : (
        <ImageOrText img={null} text="" />
      )}
    </div>
    <h5 className="m-0" title={name} style={{ fontWeight: 600 }}>
      <Paragraph
        className="m-0 short-name"
        ellipsis={{rows: 1}}
        title={name}
      >
        {name}
      </Paragraph>
    </h5>
  </div>
)

const TickerPrice = ({ ticker, price }) => (
  <div className="d-flex flex-column justify-content-center align-items-center" style={{minWidth: '4rem'}}>
    <h5 className="mb-0 text-blue text-gray-5 ticker-size ticker-price">{ticker}</h5>
    <h4 className="mt-1 mb-0 ticker-price">{FormatCurrency(price, 2)}</h4>
  </div>
)

const ImageOrText = ({ img, text = '' }) => {
  const [valid, setValid] = useState(true);

  return (
    valid
    ? <img src={img} className="img-fluid" alt="" onError={() => setValid(false)} style={{height: '20px'}} />
    : <div className="sponsor-logo-placeholder" title={text}>{text}</div>
  );
}

const getId = (function* () {
  let n = 1;
  while (true) yield n++;
})();

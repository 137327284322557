import React, { Component } from 'react';
import { connect } from 'react-redux'
import { cn } from 'DataSet';
import { toggleModal, setSFMState, refreshSFMState, addFundToSFM, sfmFundAdd, sfmFundDelete } from 'actions/sfm'
import { refreshWeightState } from 'actions/weights'

import SfMain from 'components/sf/Main'
import { Confirmation } from 'components/sf/Confirm'

class Sfm extends Component {
  constructor(props){
    super(props)
    this.state = { confirm: false };
  }

  modalToggle = (modalToggleState) => {
    const { setSFMState, toggleModal } = this.props;
    if (modalToggleState === 'add') {
      setSFMState({
        modalToggleState: true,
        modal: true,
      });
    } else if (modalToggleState === 'remove') {
      setSFMState({
        modalToggleState: false,
        modal: false,
      });
    }
  }

  confirmHandler = (confirm, payload) => {
    this.setState({ confirm, confirmPayload: payload });
    if (confirm) {
      const { refreshSFMState } = this.props;
      refreshSFMState();
    }
  }

  selectHandler = (item, index) => {
    const { setSFMState, refreshWeightState, items, sfmFundAdd, sfmFundDelete } = this.props;

    if (item === undefined) {
      items.forEach(e => {
        if (typeof e._update === "function") e._update(false);
      });

      refreshWeightState();

      setSFMState({
        items: [],
        modal: false,
        modalToggleState: false,
      });
    }
    else {
      item._selected = !item._selected;
      if (item._selected) {
        sfmFundAdd(item, index);
      }
      else {
        sfmFundDelete(item);
      }
      if (typeof item._update === "function") item._update(item._selected);
    }
  }


  render() {
    const { items } = this.props;
    if (items.length <= 0) return null;

    return (
      <React.Fragment>
      <SfMain
        {...this.props}
        accountsPage
        selectHandler={this.selectHandler}
        modalToggle={this.modalToggle}
        confirmHandler={this.confirmHandler}
      />
      { this.state.confirm &&
        <Confirmation {...this.props} triggerInvestment={this.triggerInvestment}
          confirmHandler={this.confirmHandler} confirmPayload={this.state.confirmPayload} /> }
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ portfolio, auth, sfm }) => ({
  premiumUser: auth.user.premiumUser,
  sfmFunds: sfm.selectedFunds,
  modalToggleState: sfm.modalToggleState,
  modal: sfm.modal,
  sfmCatData: sfm.sfmCatData,
  items: sfm.items,
})

const mapDispatchToProps = {
  toggleModal,
  setSFMState,
  refreshSFMState,
  addFundToSFM,
  refreshWeightState,
  sfmFundAdd,
  sfmFundDelete,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sfm)

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'antd';
import DropdownContent from './DropdownContent';
import { filterClients, resetFilterDownloadedListRequest } from 'actions/clientportfolioV2';

class SearchDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false
    }
  }

  dropdownToggle = (visible) =>{
    this.setState({
      showDropdown: !this.state.showDropdown
    })
  }

  handleSearchReset = () => {
    //   const { initClientList ,searchResultsData} = this.props;
    //   if(searchResultsData){
    //   this.props.filterClients({
    //     clientList: searchResultsData['client_list'],
    //     clientsfilteredColumn: '',
    //     clientsfilteredText: '',
    //     clientsClearAllFilter: true
    //   })
    // }else{
    //   this.props.filterClients({
    //     clientList: initClientList,
    //     clientsfilteredColumn: '',
    //     clientsfilteredText: '',
    //     clientsClearAllFilter: true
    //   })
    // }
    const { resetFilterDownloadedListRequest } = this.props;
    if (resetFilterDownloadedListRequest) {
      resetFilterDownloadedListRequest();
    }
  }

  render() {
    const { title, selector, clientsfilteredColumn } = this.props;
    const shouldHightlight = selector == clientsfilteredColumn;
    return (
      <span style={{display:'flex'}}>
        <Dropdown
          overlay={<DropdownContent
            dropdownToggle={this.dropdownToggle}
            showDropdown={this.state.showDropdown}
            handleSearchReset={this.handleSearchReset}
            {...this.props}
          />}
          trigger={['click']}
          placement="bottomCenter"
          visible={this.state.showDropdown}
          onVisibleChange={this.dropdownToggle}
        >
          <i className={`far fa-fw fa-filter cus-pointer ${shouldHightlight ? 'filter-highlight' : ''}`}
            onClick={e => e.preventDefault()}
            title="Filter Clients"
          />
        </Dropdown>
        {shouldHightlight && <i className={`far fa-fw fa-times cus-pointer filter-reset`}
          onClick={this.handleSearchReset}
          title="Clear All Filters"
        />}
      </span>
    );
  }
}

const mapStateToProps = ({ clientportfolioV2: { clientsfilteredColumn, clientList,initClientList,searchResultsData } }) => ({
  clientsfilteredColumn,
  clientList,
  initClientList,
  searchResultsData
})

const mapDispatchToProps = {
  filterClients,
  resetFilterDownloadedListRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchDropdown);

import React from 'react'
import PropTypes from 'prop-types'

export default function Debug(props) {
  return <pre>{JSON.stringify(props.data, null, 2)}</pre>
}

Debug.propType = {
  data: PropTypes.any.isRequired
}

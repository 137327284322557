import React, { useContext } from 'react'
import { Row, Col, Table } from 'reactstrap'

import { withHeaderFooter } from './HeaderFooter'

const Appendix = withHeaderFooter((props) => {
  console.log(props)
  return (
    <React.Fragment>
      <div className="content">
        <div>
          <div className="pr-v2-header">
            <h3>Appendix</h3>
          </div>
          <Holdings
            {...props}
          />
        </div>
      </div>
    </React.Fragment>
  )
})
export default Appendix
export const Holdings = (fundList) => {
  console.log(fundList)
  let fundLists = fundList.fundList
  return (
    <div className="result-section-content-wrapper holdings-section">
      <div className="list-container">
        <table className={`appendix`}>
          <tr className={`head`}>
            <td className={`serial`}>#</td>
            <td className={`ticker`}>Ticker</td>
            <td className={`fund`}>Fund Name</td>
            <td className={`weight`}>Weight</td>
          </tr>
            {fundLists.map((item, index) =>
                  <tr key={`i-${index}`} className="list-body-content-row" style={{ background: `#E0E0E0` }}>
                    <td className={`serial`}>{index+1}</td>
                    <td className={`ticker`}>{item.ticker}</td>
                    <td className={`fund`}>{item.name || item.short_name}</td>
                    <td className={`weight`}>{parseFloat(item._sliderValue.toFixed(1))}%</td>
                  </tr>
            )}
        </table>
      </div>
    </div>
  )
}

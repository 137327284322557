import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { CSVLink } from "react-csv";
import { Tooltip, Radio, Space } from 'antd';
import {
  Button,
  ButtonGroup,
  Dropdown,
  FormGroup,
  ModalBody,
  Input,
  ModalFooter,
  Modal,
  Form,
  UncontrolledTooltip,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
 } from 'reactstrap';

import { BrokerNameMap, BrokersList } from 'DataSet';
import { getNest, closeTraditWindow, addEventToAnalytics } from 'Utils';
import { numberCheck } from 'layouts/utils';
import { Loader } from '../Loader';
import { FooterActions } from '../Common';
import style from './ActTab.module.scss';

export class FetchBroker extends React.Component {
  componentDidMount() {
    const { loggedIn, getBrokerDetailsRequest } = this.props;
    if (loggedIn && getBrokerDetailsRequest) {
      getBrokerDetailsRequest();
    }
  }
  render () {
    const { loadBrokerDetails } = this.props;
    if (loadBrokerDetails) return (<Loader loading />)
    return (
      <></>
    );
  }
}

export class UnitsValue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      view: 'Units'
    };
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  changeView(view) {
    const { fund, amtHandler } = this.props
    this.setState({ view });
    if (amtHandler) {
      amtHandler(view)
    }
    if (fund) {
      addEventToAnalytics('Quick Trade ACT Unit Dropdown','Quick Trade ACT Unit Dropdown','QUICK_TRADE_ACT_UNIT_DROPDOWN',{ query: fund.query, logId: fund.logId, view },true);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== this.state.view) {
      this.setState({ view: this.props.value });
    }
  }

  render() {
    return (
      <div className="text-center">
        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle caret color="link" className="h6 text-gray-8 p-0 mb-2 lh-100 sfmodal-unit">{this.state.view}</DropdownToggle>
          <DropdownMenu className="rounded-0 h6 py-1" style={{minWidth: '5rem'}}>
            { ['Units', 'Value'].map(v => ( <DropdownItem key={v} disabled={(this.props.showOnlyValue) && v === 'Units'} className="py-1" onClick={() => this.changeView(v)}>{v}</DropdownItem> ))}
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    )
  }
}

export class ExecutationType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      view: 'Market'
    };
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  changeView(view) {
    this.setState({ view });
  }

  render() {
    return (
      <div className="text-center">
        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle caret color="link" className="h6 text-gray-8 p-0 mb-2 lh-100 sfmodal-unit">{this.state.view}</DropdownToggle>
          <DropdownMenu className="rounded-0 h6 py-1" style={{minWidth: '5rem'}}>
            { ['Market', 'Limit'].map(v => ( <DropdownItem disabled={v==='Limit'} key={v} className="py-1" onClick={() => this.changeView(v)}>{v}</DropdownItem> ))}
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    )
  }
}

export const BuySell = ({ value, setAction, sellDisable }) => {
  let onClick = (event) => {
    if (setAction) {
      setAction(event.target.value);
    }
  }
  return (
    <div>
      <Radio.Group onChange={onClick} value={value}>
        <Space direction="vertical">
          <Radio className={`${style.radioInput} ${value === "buy" ? "" : ""}`} value="buy">BUY</Radio>
          <Radio className={`${style.radioInput} ${value === "sell" ? "" : ""}`} value="sell" disabled={sellDisable}>SELL</Radio>
          <Radio className={`${style.radioInput} ${value === "sell_all" ? "" : ""}`} value="sell_all" disabled={sellDisable}>SELL TO $0</Radio>
        </Space>
      </Radio.Group>
    </div>
  );
}

export const InvestMentTextCard = ({ basketFund, holdingValue, sharesValue, isSelling, minInvestment, prec = 2 }) => {
  let _minInvestment = numberCheck(minInvestment, prec);
  if (isSelling) {
    return (
      <div style={{ height: 68 }}>
        <p className={style.investmentPriceText} style={{ minWidth: '4rem', margin: '0 0 19px 0' }}>Current Holding</p>
        <div style={{ fontSize: '11px', lineHeight: '14px', marginBottom: 10 }}>
          <span class="text-gray-5">Value: </span> {numberFormatForAct(holdingValue, prec) || "$0"}
        </div>
        {(!basketFund) && (
          <div style={{ fontSize: '11px', lineHeight: '14px' }}>
            <span class="text-gray-5">Units: </span> {numberCheck(sharesValue, 5)}
          </div>
        )}
      </div>
    )
  } else {
    return (
      <div style={{ height: 68 }}>
        <p className={style.investmentPriceText} style={{ minWidth: '4rem', margin: '0 0 14px 0' }}>Minimum Investment</p>
        <h5 className={style.investmentPrice}>{!isNaN(minInvestment) ? `${numberFormatForAct(_minInvestment, prec)}` : "$0"}</h5>
      </div>
    )
  }
}

export const numberFormatForAct = (num, prec = 0) => {
  let _prec = (Number.isInteger(num)) ? 0 : prec;
  let amount = typeof num === "number"
    ? num.toLocaleString('en', {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: _prec,
        minimumFractionDigits: 0
      })
    : '';
  if (amount.includes('.00')) {
    amount = (amount.split('.00') && amount.split('.00').length > 0) ? amount.split('.00')[0] : amount;
    // let _amount = amount.split('$')
    // _amount = (_amount.length > 1 && _amount[1] && !isNaN(Number(_amount[1]))) ? `$${Number(_amount[1])}` : amount;
    // amount = _amount;
  }
  return amount;
}

export const numberFormat = (num, prec = 2) =>
  typeof num === "number"
    ? num.toLocaleString('en', {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: prec,
        minimumFractionDigits: 0
      })
    : ''

export const FormatCurrency = (value, prec = 0, curr = '$') => {
  if(value === 0){
    return `${curr}${value}`;
  }
  let _prefix = (value < 0 ? '-' : '');
  let [_value, _suffix, _prec] = [Math.abs(value), '', prec];

  if (_value >= 1e9)
    [_value, _suffix, _prec] = [_value/1e9, 'B', 2];
  else if (_value >= 1e6)
    [_value, _suffix, _prec] = [_value/1e6, 'M', 2];

  if (_value >= 100 && _prec >= 0) _prec = 0;
  else if (_value >= 10 && _prec >= 0) _prec = 1;

  return `${_prefix}${numberFormat(_value)}${_suffix}`
}

export default {
	UnitsValue,
  BuySell,
  InvestMentTextCard,
  numberFormatForAct,
  numberFormat,
  FormatCurrency,
  FetchBroker,
}

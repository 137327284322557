import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { extent as d3Extent } from 'd3-array';
import { LabelList, Legend, Label, CartesianGrid } from 'recharts';
import { LineChart, Line } from 'recharts';


const getCenteredYDomain = (data, keyFn) => {
  const [min, max] = d3Extent(data, keyFn);
  let max2 = Math.max(Math.abs(min), Math.abs(max));
  if (max2 === 0) max2 = 1;
  return [-max2, max2];
}


export const BarChartPL = React.memo(({ data, ...props }) => {
  if (!data || data.length <= 0) return null;

  const domain = getCenteredYDomain(data, e => e.pl);

  return (
    <ResponsiveContainer width="100%" height="100%" >
      <BarChart data={data} margin={{top: 18, right: 0, left: 0, bottom: 12}}>
        <XAxis dataKey="name" hide={true}/>
        <ReferenceLine y={0} stroke="#bbb" shapeRendering="crispEdges"/>
        <YAxis hide={true} domain={domain} />
        <Tooltip content={<CustomTooltipBar formatter={props.formatter} />} cursor={false} isAnimationActive={false} />
        <Bar dataKey="pl" unit="%" fill="#8884d8" maxBarSize={15} isAnimationActive={false}>
          { data.map((v, i) => (
              <Cell key={`cell-${i}`} fill={v.color}/>
            )) }
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
})

const CustomTooltipBar = (props) => {
  const { active, payload, label, formatter } = props;

  if (active && payload[0]) {
    let { value } = payload[0];
    if (formatter) value = formatter(value);

    return (
      <div className="recharts-default-tooltip ssf-tooltip-custom">
        <div>
          <span className="label">{label}</span>
          <span className="value">{value}</span>
        </div>
      </div>
    );
  }

  return null;
}



/*
  data: [ { name: 'item1', value: 1.23, color: '#bbb' }, ... ]
*/

export const BarChartPrintS1 = ({ data }) => {
  // console.log(data);
  const domain = [ dataMin => Math.min(dataMin, 0), dataMax => Math.max(dataMax, 0) ]
  return (
    <ResponsiveContainer width="100%" height="100%" >
      <BarChart data={data} margin={{top: 14, right: 0, left: 0, bottom: 14}}>
        <XAxis dataKey="name" tickLine={false} axisLine={false} hide={true}/>
        <YAxis hide={true} domain={domain} />
        <ReferenceLine y={0} stroke="#edf8fc" shapeRendering="crispEdges"/>
        <Bar dataKey="value" maxBarSize={50} isAnimationActive={false} >
          <LabelList dataKey="value" position="top" className="ssf" formatter={labelPc} />
          <LabelList dataKey="name" position="center" className="ssf" fill="black" content={renderCustomizedLabel} />
          { data.map((v, i) => <Cell key={`cell-${i}`} fill={v.color}/>) }
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value, fill, color } = props;
  const textProps = { color, width, height, fill, x: x+width/2, y: y+height/2 }
  if (Math.abs(height) < 20) {
    textProps.y = y + height + (height < 0 ? -8 : 8);
  }

  return (
    <text {...textProps} className="recharts-text recharts-label ssf" textAnchor="middle">
      <tspan x={textProps.x} dy="0.355em">{value}</tspan>
    </text>
  );
};

/*
  data: [ { name: 'item1', value1: 1.23, value2: 2.34, value3: 3.45 }, ... ],
  series: [ { key: 'value1', color: '#8884d8' }, ... ]
*/

export const BarChartPrintSn = ({ data, series, margin }) => {
  const defaultMargin = margin || {top: 5, right: 0, left: 0, bottom: 5}
  return (
    <ResponsiveContainer width="100%" height="100%" >
      <BarChart data={data} margin={defaultMargin}>
        <XAxis interval={0} dataKey="name" tickLine={false} axisLine={false} mirror={false} dy={12} />
        <ReferenceLine y={0} stroke="#bbb" shapeRendering="crispEdges"/>
        { series.map(s => (
          <Bar key={s.key} dataKey={s.key} fill={s.color} maxBarSize={30} isAnimationActive={false}>
            <LabelList dataKey={s.key} position="top" className="ssf" formatter={labelPc}/>
          </Bar>
        )) }
        <Legend align="left" wrapperStyle={{ bottom: 0 }} />
      </BarChart>
    </ResponsiveContainer>
  );
}

//customized x axis label double line
export const BarChartPrintSn2 = ({ data, series, margin }) => {
  const defaultMargin = margin || {top: 5, right: 0, left: 0, bottom: 5}

  return (
    <ResponsiveContainer width="100%" height="100%" >
      <BarChart data={data} margin={defaultMargin}>
        <XAxis tick={<CustomXAxisTick labelFormatter={labelPc}/>} interval={0} dataKey="name" tickLine={false} axisLine={false} mirror={false} dy={12} />
        <ReferenceLine y={0} stroke="#bbb" shapeRendering="crispEdges"/>
        { series.map(s => (
          <Bar key={s.key} dataKey={s.key} fill={s.color} maxBarSize={30} isAnimationActive={false}>
            <LabelList dataKey={s.key} position="top" className="ssf" formatter={labelPc}/>
          </Bar>
        )) }
        <Legend align="left" wrapperStyle={{ bottom: 0 }} />
      </BarChart>
    </ResponsiveContainer>
  );
}

const CustomXAxisTick = (props) => {
  const {
    x, y, fill, payload,
  } = props;

  if (payload) {
    return (
      <>
        <text x={x} y={y+20} fill={fill} textAnchor="middle" dominantBaseline="middle">
          {payload.value.split(',')[0]}
        </text>
        <text style={{fontSize:`9px`}} x={x} y={y+34} fill={fill} textAnchor="middle" dominantBaseline="middle">
          {payload.value.split(',')[1] && '('}{payload.value.split(',')[1]}{payload.value.split(',')[1] && ')'}
        </text>
        </>
    );
  }
}

const labelPc = label => label.toFixed(2)+'%'

/*
  series: [ { name: 'S1', data: [{ d: 'Mar 2017', cuml: 1.23 }, ... ], color: '#bbb' }, ... ],
  xkey: 'd',
  ykey: 'cuml',
*/
export const LineChartPrintSn = (props) => {
  const { series, xkey, ykey } = props;
 // let ydomain = ['auto', 'auto'];

	return (
    <ResponsiveContainer width="100%" height="100%" >
      <LineChart margin={{top: 15, right: 5, left: 5, bottom: 5}}>

        <XAxis dataKey={xkey} tickLine={false} type="category" allowDuplicatedCategory={false} padding={{ left: 50, right: 50 }}/>
        <YAxis>
          <Label value="Percent" position="insideLeft" angle={-90} style={{ textAnchor: 'middle', fontSize: 13 }} />
        </YAxis>
        <CartesianGrid vertical={false} strokeWidth="0.5" stroke="#d7d7d7" strokeOpacity="1" shapeRendering="crispEdges" />

        { series.map(s => (
          <Line dataKey={ykey} data={s.data} name={s.name} key={s.name}
            type="linear" unit="%" stroke={s.color} strokeWidth={2}
            dot={false}
            isAnimationActive={false} />
        ))}

        <Legend iconType="rect" wrapperStyle={{ paddingTop: "5px" }} />
      </LineChart>
    </ResponsiveContainer>
  );
}

import React from 'react';
import { Button } from 'reactstrap';
import { getNest } from 'Utils';
import { FooterActions } from '../Common';
import { SelectButtonDisabledTooltip } from './helper';

class Footer extends React.Component {
  render() {
    const items = this.props.items.filter(e => getNest(['_order', 'stage'], e) === 'buy');
    let disabled = this.props.disabled || items.length === 0;
    return (
      <React.Fragment>
        <FooterActions backHandler={() => this.props.actionHandler('Act')} clearHandler={this.props.clearAllHandler} />
        <Button id="select-tt-confirm" color="select" disabled={disabled}
          className="btn h3 text-uppercase font-weight-bold p-3 mb-0 ml-auto secondary-btn"
          onClick={this.props.confirmHandler}
        >
          Confirm
        </Button>
        { this.props.disabled && <SelectButtonDisabledTooltip target={'select-tt-confirm'} placement={'top'} /> }
      </React.Fragment>
    )
  }
}

export default Footer;

import React, { Component } from 'react';
import { connect } from 'react-redux';
// import qs from 'query-string'
// import { get } from 'lodash'

// import { Security } from '@okta/okta-react'
// import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { oktaConfig } from 'oktaAuth'

import { Row, Col } from 'reactstrap'
import { Skeleton } from 'antd'
import { CheckOutlined } from '@ant-design/icons'

import { setOktaAuthLoader, oktaAuthLoginRequest } from 'actions/auth';
import { setRegisterState } from 'actions/register';

import { createLoadingSelector } from 'selectors/loading-selector';

// import config from 'env';
import { searchUrlV3 } from 'Utils';
import { REDIRECT_DOMAIN_PATH } from 'DataSet';

import {
  ADVISOR_REGISTER_LHS
} from '../Constant/FormData'

import {redirectOktaNonGroupMagnifiUsers} from 'common/auth-utils'

const magnifiByTiffinLogo = '/assets/images/magnifi_by_tifin.svg';

class ValidateOktaAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sourceURI: null,
      validateOktaUserFor: null,
    };

    // this.oktaAuth = new OktaAuth(oktaConfig.oidc);
    // console.log('this.oktaAuth ---> ', this.oktaAuth);

    this.oktaAuth = window.OktaAuth;
    if (window.OktaAuth) {
      this.oktaAuth = new window.OktaAuth(oktaConfig.oidc);
    }
  }

  componentWillMount() {
    this.verifyWithOkta();
  }

  verifyWithOkta = () => {
    const { setOktaAuthLoader, setRegisterState } = this.props
    if (this.oktaAuth &&
      this.oktaAuth.token &&
      setOktaAuthLoader &&
      this.oktaAuth.token.isLoginRedirect()
    ) {
      setOktaAuthLoader(true);
      // console.log(this.oktaAuth);
      // console.log('this.oktaAuth.token --> ', this.oktaAuth.token)
      const { oktaAuthLoginRequest } = this.props;
      this.oktaAuth.token.parseFromUrl().then(tokenResponse => {
        // console.log('tokenResponse --> ', tokenResponse)
        try {
          if (tokenResponse && tokenResponse.tokens) {
            redirectOktaNonGroupMagnifiUsers(tokenResponse)
            this.oktaAuth.tokenManager.setTokens(tokenResponse.tokens);
            const { accessToken } = tokenResponse.tokens
            if (accessToken && accessToken.accessToken) {
              console.log('validate component accessToken.accessToken -> ', accessToken.accessToken)
              let requestPayload = {
                token: accessToken.accessToken,
              };
              try {
                if (tokenResponse.state && tokenResponse.state.length > 0) {
                  const parseStateData = JSON.parse(decodeURIComponent(tokenResponse.state));
                  console.log(
                    'parseStateData -->', parseStateData,
                  )
                  console.log(
                    'parseStateData.sourceURI --> ', parseStateData.sourceURI
                  )
                  if (parseStateData.sourceURI) {
                    this.setState({
                      sourceURI: parseStateData.sourceURI,
                    })
                  }
                  if (parseStateData.validateOktaUserFor === 'register') {
                    requestPayload.validateOktaUserFor = 'register';
                    this.setState({
                      validateOktaUserFor: requestPayload.validateOktaUserFor,
                    });
                  }
                  if (parseStateData.validateOktaUserFor === 'google') {
                    requestPayload.validateOktaUserFor = 'google';
                    this.setState({
                      validateOktaUserFor: requestPayload.validateOktaUserFor,
                    });
                  }
                }
              } catch (error) {
                console.log(error)
              } finally {
                oktaAuthLoginRequest(requestPayload, (oktaAuthLoginResponse) => {
                  console.log('VALIDATE component oktaAuthLoginResponse --> ', oktaAuthLoginResponse)
                  if (oktaAuthLoginResponse) {
                    if (!oktaAuthLoginResponse.error &&
                      ((typeof oktaAuthLoginResponse.register_step_status !== 'undefined' &&
                        oktaAuthLoginResponse.register_step_status === '') || (
                        !oktaAuthLoginResponse.original_self_role
                      ))// which means profile data is incomplete
                    ) {
                      setRegisterState({
                        registerStepStatus: oktaAuthLoginResponse.register_step_status,
                        registerGoogleAuthToken: accessToken.accessToken,
                      });
                      this.props.history.push(`/register-profile/${accessToken.accessToken}`)
                    } else if (oktaAuthLoginResponse.profile) {
                      // All set, now redirect to "/"
                      let redirectPath = '/'
                      if (window.localStorage.getItem('query')) {
                        redirectPath = searchUrlV3(window.localStorage.getItem('query')); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
                      }
                      this.props.history.push(redirectPath)
                    } else if (oktaAuthLoginResponse.error) {
                      // this.setState({
                      //   oktaAuthLoginError: oktaAuthLoginResponse.error
                      // })
                      console.log('sourceURI -->', this.state.sourceURI)
                      if (this.state.sourceURI) {
                        this.props.history.push(this.state.sourceURI)
                      }
                      // return;
                    }
                  }
                });
                setOktaAuthLoader(false);
              }
            } else {
              throw '--- Missing Access Token ---'
            }
          } else {
            throw '--- OKTA access token api failed ---'
          }
        } catch (error) {
          console.log(error)
          setOktaAuthLoader(false);
        }
      });
    } else {
      // const { loading, oktaAuthLoginError } = this.props;
      // if (!loading && !oktaAuthLoginError) {
      //   this.props.history.push('/');
      // }
    }
  }

  render() {
    const { validateOktaUserFor } = this.state;
    return (
      <Row className="magnifi-auth-page-wrapper register-magnifi-opt-2">
        <Col className="left-side-panel-container different_advisor">
          <Row style={{ alignItems: "center", width: '100%' }}>
            <Col sm={12} className="text-center">
              <a className="logo-wrapper mt-1" href={REDIRECT_DOMAIN_PATH} style={{ width: '100%' }}>
                <img style={{ width: '60%' }} src={magnifiByTiffinLogo} height={70} alt="magnifi" />
              </a>
            </Col>
            {(validateOktaUserFor) && (
              <Col sm={12}>
                <div className="list_content text-center">
                  <span className="span_head">Become an AI-Powered Financial Advisor</span>
                  <div style={{ width: '90%', margin: 'auto' }}>
                    {ADVISOR_REGISTER_LHS.map((el, index) => {
                      return (
                        <Row className={index === 0 ? "text-left pt-5" : "text-left pt-4"} style={{ maxWidth: '100%' }}>
                          <Col className={'text-right p-0 pr-2'} sm={2}><CheckOutlined style={{ color: '#fff', fontWeight: 'bold', fontSize: '1.4rem' }} /></Col>
                          <Col className={'p-0 pl-3'} sm={10} style={{ display: 'flex', alignItems: 'center' }}><span style={{ color: '#fff', fontSize: '1.1rem', lineHeight: '1.5rem' }}>{el}</span></Col>
                        </Row>
                      )
                    })}
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Col>
        <Col
          md="6"
          lg="6"
          className="right-side-panel-container register-page-height"
          style={{ justifyContent: 'center', padding: '0 3rem' }}
        >
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = ({ auth, loading }) => ({
  oktaAuthLoginError: auth.error,
  loading: createLoadingSelector([
    'AUTH',
    'REGISTER',
    'OKTA_AUTH_LOGIN',
    'OKTA_AUTH_REGISTER',
    'VALIDATE_OKTA_USER',
  ])({ loading }) || auth.oktaAuthLoader,
});

const mapDispatchToProps = {
  setRegisterState,
  setOktaAuthLoader,
  oktaAuthLoginRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValidateOktaAuth);

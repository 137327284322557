import React from 'react';
import { get } from 'lodash';
import { Button, Row, Col, Card, CardBody } from 'reactstrap';
import { D3AppColors09, cn, sponsorLogo, Brokers } from 'DataSet';
import { numFmt, getImageOrFallback } from 'Utils';
import { TruncatedNameSponsor } from '../Common';

const classNames = require('classnames');

class ItemCard extends React.Component {
  state = {
    imageValidPath: false,
    card: {},
    idx: ''
  }

  componentDidMount() {
    const { card, idx } = this.props;
    getImageOrFallback(
      sponsorLogo(cn(card, 'Sponsor'))
    ).then(result => {
      if (result) {
        this.setState({ card, idx, imageValidPath: true });
      } else {
        this.setState({ card, idx, imageValidPath: false });
      }
    });
  }

  componentDidUpdate() {
    const { card, idx } = this.props;
    getImageOrFallback(
      sponsorLogo(cn(card, 'Sponsor'))
    ).then(result => {
      if (result) {
        this.setState({ card, idx, imageValidPath: true });
      } else {
        this.setState({ card, idx, imageValidPath: false });
      }
    });
  }

  componentWillMount() {
    const random11 = () => `${Math.floor(10000 + Math.random() * 90000)}${Math.floor(100000 + Math.random() * 900000)}`;
    this.props.card._order.oid = random11();
  }

  unselectCard = (item) => {
    this.props.clearHandler(item);
  }

  render() {
    const { imageValidPath, card, idx } = this.state;
    if (!card.broker) return null;
    const broker = Brokers.find(e => e.name === card.broker.name);
    const price = (card._order.otype === 'Market' ? cn(card, 'Nav Price').toFixed(2) : card._order.price) || 0.0;
    const qty = card._order.state && card._order.state.amtType === 'Units' ? card._order.state.qty : card._order.qty;
    const tvalue = qty > 0 && price > 0 ? (qty * price + card.broker.commission) : 0.0;
    if(broker.parent && broker.parent == 'TradeIT' && card._order.previewResult && card._order.previewResult.status == "REVIEW_ORDER")
      return (
        <div>
        <Card className={classNames("shadow-sm mb-2 border-0")}>
          <CardBody className="p-0">
            <Row className="no-gutters">
              <Col className="col-3 p-1">
                <div className="selected-card bg-ssf-gray-l5 px-2 py-1 h-100 d-flex align-items-center" style={{ borderLeftColor: D3AppColors09[idx % D3AppColors09.length] }}>
                  <TruncatedNameSponsor style={{ flex: 1 }} imageValidPath={imageValidPath} ticker={cn(card, 'Ticker')} name={cn(card, 'Name2')} sponsor={cn(card, 'Sponsor')} />
                  <div className="d-flex align-items-start h-100 close-wrapper">
                    <div className="close-icon-box pl-0">
                      <i className="fal fa-times-circle" onClick={() => this.unselectCard(card)}></i>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="col-4 py-1">
                <Row className="no-gutters h-100">
                  <Col className="col-5 pr-1 h-100">
                    <div className="p-1 py-2 h-100 d-flex align-items-center bg-ssf-gray-l5">
                      <Button color="link" block
                        className="broker px-1"
                        style={{ backgroundColor: broker.color, height: '80px', maxHeight: '80px' }} >
                        <h5 className="mb-1" style={{ color: '#fff' }}>{broker.name}</h5>
                        <h5 className="mb-0 font-weight-bold" style={{ color: '#fff' }}>{'$' + card._order.previewResult.data.orderDetails.estimatedOrderCommission}</h5>
                        <h6 className="mt-2 mb-0 font-weight-bold confirm-info-account-number" style={{ color: '#fff' }}>({card._order.account_number})</h6>
                      </Button>
                    </div>
                  </Col>
                  <Col className="col-7 h-100">
                    <div className="p-2 h-100 d-flex align-items-center bg-ssf-gray-l5 text-gray-8">
                      <Button
                        color="link"
                        block
                        className="broker px-1"
                        style={{ backgroundColor: broker.color, textAlign: 'inherit' }}
                      >
                        <div className="row no-gutters w-100 mb-0">
                          <div className="col-auto text-gray-8 confirm-info-container" >
                            <h6 className="mb-1"><i className="fal fa-fw fa-money-bill-wave-alt mr-1 align-self-center" ></i>Trade Value</h6>
                            <h6 className="mb-1"><i className="fal fa-fw fa-coins mr-1 align-self-center"></i>Quantity</h6>
                            <h6 className="mb-1"><i className="fal fa-fw fa-clipboard-check mr-1 align-self-center"></i>Order Type</h6>
                            <h6 className="mb-1"><i className="fal fa-fw fa-at mr-1 align-self-center"></i>Price</h6>
                            {card._order.otype !== 'Market' &&
                              <h6 className="mb-1"><i className="fal fa-fw fa-hourglass-start mr-2 align-self-center"></i>Good Until</h6>}
                          </div>
                          <div className="col-6 ml-auto mr-auto confirm-info-container-inner-right">
                            <h6 className="mb-1 font-weight-bold" style={{ whiteSpace: 'nowrap', color: '#fff' }}>${numFmt(card._order.previewResult.data.orderDetails.estimatedTotalValue)}</h6>
                            <h6 className="mb-1 font-weight-bold" style={{ color: '#fff' }}>{numFmt(parseInt(card._order.previewResult.data.orderDetails.orderQuantity || 0, 10))}</h6>
                            <h6 className="mb-1" style={{ color: '#fff' }}>{card._order.otype}</h6>
                            <h6 className="mb-1" style={{ color: '#fff' }}>${numFmt(card._order.previewResult.data.orderDetails.estimatedOrderValue)}</h6>
                            {card._order.otype !== 'Market' &&
                              <h6 className="mb-1">{card._order.validity}</h6>}
                          </div>
                        </div>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="col p-1">
                <div className="p-1 h-100 d-flex flex-column align-items-center justify-content-center bg-ssf-gray-l5">
                  {card._order.previewResult.data.orderDetails.warnings.length > 0 &&
                  <div>
                    <Row className="no-gutters"><h5 className="p-1 font-weight-bold text-warning confirm-info-error"><i className="fas fa-exclamation-triangle"></i> Warnings</h5></Row>
                    {card._order.previewResult.data.orderDetails.warnings.map((warn, index) => {
                      this.props.handleCheckbox()
                      return(
                        <Row key={warn.message} className="no-gutters mb-1">
                          <Col className="col-1 text-center">{warn.requiresAcknowledgement && <h5 className="mb-0 text-gray-7 confirm-info-error"><input className='warning-tradeit' id={"warning-" + card.ticker + "-" + index} type="checkbox" onChange={this.props.handleCheckbox}></input></h5>}</Col>
                          <Col className="col-11"><h5 className="mb-0 text-gray-7 confirm-info-error">{warn.message}</h5></Col>
                        </Row>
                      );
                    })}
                  </div>}
                  {card._order.previewResult.data.orderDetails.warnings.length == 0 &&
                  <div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <i className="fal fa-fw fa-check-circle fa-2x text-success"></i>
                      <h5 className="text-gray-8 fs-11" style={{ marginTop: '0.5rem' }}>Order has been created successfully.<br />Please click on Confirm to proceed.</h5>
                    </div>
                  </div>}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        </div>
      );
    else if(broker.parent && broker.parent == 'TradeIT' && card._order.previewResult && card._order.previewResult.status == "Error" &&
    get(card, '_order.previewResult.message.default', '') == 'Session Expired' && card.type != 'MF'){
      return (
        <Card className={classNames("shadow-sm mb-2 border-0")}>
          <CardBody className="p-0">
            <Row className="no-gutters">
              <Col className="col-3 p-1">
                <div className="selected-card bg-ssf-gray-l5 px-2 py-1 h-100 d-flex align-items-center" style={{ border: 0 }}>
                  <TruncatedNameSponsor style={{ flex: 1 }} imageValidPath={imageValidPath} ticker={cn(card, 'Ticker')} name={cn(card, 'Name2')} sponsor={cn(card, 'Sponsor')} />
                  <div className="d-flex align-items-start h-100 close-wrapper">
                    <div className="close-icon-box pl-0">
                      <i className="fal fa-times-circle" onClick={() => this.unselectCard(card)}></i>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="col-4 py-1">
                <Row className="no-gutters h-100">
                  <Col className="col-5 pr-1 h-100">
                    <div className="p-1 py-2 h-100 d-flex align-item-flex-start bg-ssf-gray-l5">
                      <Button color="link" block
                        className="broker px-1"
                        style={{ backgroundColor: broker.color, height: '80px', maxHeight: '80px' }} >
                        <h5 className="mb-1" style={{ color: '#fff' }}>{broker.name}</h5>
                        <h5 className="mb-0 font-weight-bold" style={{ color: '#fff' }}>{'$' + card.broker.commission}</h5>
                        <h6 className="mt-2 mb-0 font-weight-bold confirm-info-account-number" style={{ color: '#fff' }}>({card._order.account_number})</h6>
                      </Button>
                    </div>
                  </Col>
                  <Col className="col-7 h-100">
                    <div className="p-2 h-100 d-flex align-item-flex-start bg-ssf-gray-l5 text-gray-8">
                      <Button
                        color="link"
                        block
                        className="broker px-1"
                        style={{ backgroundColor: broker.color, textAlign: 'inherit', height: '100%' }}
                      >
                        <div className="row no-gutters w-100 mb-0" style={{ height: '100%' }}>
                          <div className="col-auto text-gray-8 confirm-info-container" >
                            <h6 className="mb-0"><i className="fal fa-fw fa-money-bill-wave-alt mr-1 align-self-center" ></i>Trade Value</h6>
                            <h6 className="mb-0"><i className="fal fa-fw fa-coins mr-1 align-self-center"></i>Quantity</h6>
                            <h6 className="mb-0"><i className="fal fa-fw fa-clipboard-check mr-1 align-self-center"></i>Order Type</h6>
                            <h6 className="mb-0"><i className="fal fa-fw fa-at mr-1 align-self-center"></i>Price</h6>
                            {card._order.otype !== 'Market' &&
                              <h6 className="mb-1"><i className="fal fa-fw fa-hourglass-start mr-2 align-self-center"></i>Good Until</h6>}
                          </div>
                          <div className="col-6 ml-auto mr-auto confirm-info-container-inner-right">
                            <h6 className="mb-1 font-weight-bold" style={{ whiteSpace: 'nowrap', color: '#fff' }}>${numFmt(tvalue)}</h6>
                            <h6 className="mb-1 font-weight-bold" style={{ color: '#fff' }}>{numFmt(parseInt(qty || 0, 10))}</h6>
                            <h6 className="mb-1" style={{ color: '#fff' }}>{card._order.otype}</h6>
                            <h6 className="mb-0" style={{ color: '#fff' }}>${numFmt(price)}</h6>
                            {card._order.otype !== 'Market' &&
                              <h6 className="mb-0 mt-1">{card._order.validity}</h6>}
                          </div>
                        </div>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="col p-1">
                <div className="p-1 h-100 d-flex flex-column align-items-center justify-content-center bg-ssf-gray-l5">
                {card._order.previewResult.status == "Error" && (
                  <div style={{ width: '100%' }}>
                    <Row className="no-gutters">
                      <h5 className="p-1 font-weight-bold text-ssf-red-d1 confirm-info-error"><i className="fas fa-exclamation-triangle"></i> Errors</h5>
                    </Row>
                    {typeof card._order.previewResult.message === 'string' && (
                      <Row className="no-gutters mb-1">
                        <Col className="col-1 text-center"></Col>
                        <Col className="col-11"><h5 className="mb-0 text-gray-7 confirm-info-error">{get(card, '_order.previewResult.message.default', '')}</h5></Col>
                      </Row>
                    )}
                    <Row className="no-gutters mb-1">
                      <Col className="col-1 text-center"></Col>
                      <Col className="col-11"><h5 className="mb-0 text-ssf-red-d1 confirm-info-error">This order would't be executed if you click confirm.</h5></Col>
                    </Row>
                    {typeof card._order.previewResult.message === 'object' && card._order.previewResult.message.constructor === Object && card._order.previewResult.message.longMessage.map((warn, index) => {
                      return(
                        <Row key={index+'error-'+warn} className="no-gutters mb-1">
                          <Col className="col-1 text-center"></Col>
                          <Col className="col-11"><h5 className="mb-0 text-gray-7 confirm-info-error">{warn}</h5></Col>
                        </Row>
                      );
                    })}
                    {typeof card._order.previewResult.message === 'string' && (
                      <Row key={'error-'+card._order.previewResult.message} className="no-gutters mb-1">
                        <Col className="col-1 text-center"></Col>
                        <Col className="col-11"><h5 className="mb-0 text-gray-7 confirm-info-error">{card._order.previewResult.message}</h5></Col>
                      </Row>
                    )}
                  </div>
                )}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      );
    }
    else{
      return (
        <Card className={classNames("shadow-sm mb-2 border-0")}>
          <CardBody className="p-0">
            <Row className="no-gutters">
              <Col className="col-3 p-1">
                <div className="selected-card bg-ssf-gray-l5 px-2 py-1 h-100 d-flex align-items-center" style={{ borderLeftColor: D3AppColors09[idx % D3AppColors09.length] }}>
                  <TruncatedNameSponsor style={{ flex: 1 }} imageValidPath={imageValidPath} ticker={cn(card, 'Ticker')} name={cn(card, 'Name2')} sponsor={cn(card, 'Sponsor')} />
                  <div className="h-100 d-flex align-items-end ml-auto">
                    <div className="btn btn-link text-gray-4 p-0 border-0 lh-100">
                      <i className="far fa-times-circle" onClick={() => this.unselectCard(card)}></i>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="col-4 py-1">
                <Row className="no-gutters h-100">
                  <Col className="col-5 pr-1 h-100">
                    <div className="p-1 py-2 h-100 d-flex align-items-center bg-ssf-gray-l5">
                      <Button color="link" block
                        className="broker px-1"
                        style={{ backgroundColor: broker.color, height: '80px', maxHeight: '80px' }} >
                        <h5 className="mb-1" style={{ color: '#fff' }}>{broker.name}</h5>
                        <h5 className="mb-0 font-weight-bold" style={{ color: '#fff' }}>{'$' + card.broker.commission}</h5>
                        <h6 className="mt-2 mb-0 font-weight-bold confirm-info-account-number" style={{ color: '#fff' }}>({card._order.account_number})</h6>
                      </Button>
                    </div>
                  </Col>
                  <Col className="col-7 h-100">
                    <div className="p-2 h-100 d-flex align-items-center bg-ssf-gray-l5 text-gray-8">
                    <Button color="link" block
                        className="broker px-1"
                        style={{ backgroundColor: broker.color, textAlign: 'inherit' }} >
                      <div className="row no-gutters w-100 mb-0">
                        <div className="col-auto text-gray-8 confirm-info-container" >
                          <h6 className="mb-1"><i className="fal fa-fw fa-money-bill-wave-alt mr-1 align-self-center" ></i>Trade Value</h6>
                          <h6 className="mb-1"><i className="fal fa-fw fa-coins mr-1 align-self-center"></i>Quantity</h6>
                          <h6 className="mb-1"><i className="fal fa-fw fa-clipboard-check mr-1 align-self-center"></i>Order Type</h6>
                          <h6 className="mb-1"><i className="fal fa-fw fa-at mr-1 align-self-center"></i>Price</h6>
                          {card._order.otype !== 'Market' &&
                            <h6 className="mb-1"><i className="fal fa-fw fa-hourglass-start mr-2 align-self-center"></i>Good Until</h6>}
                        </div>
                        <div className="col-6 ml-auto mr-auto confirm-info-container-inner-right">
                          <h6 className="mb-1 font-weight-bold" style={{ whiteSpace: 'nowrap', color: '#fff' }}>${numFmt(tvalue)}</h6>
                          <h6 className="mb-1 font-weight-bold" style={{ color: '#fff' }}>{numFmt(parseInt(qty || 0, 10))}</h6>
                          <h6 className="mb-1" style={{ color: '#fff' }}>{card._order.otype}</h6>
                          <h6 className="mb-1" style={{ color: '#fff' }}>${numFmt(price)}</h6>
                          {card._order.otype !== 'Market' &&
                            <h6 className="mb-1">{card._order.validity}</h6>}
                        </div>
                      </div>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="col p-1">
                <div className="p-1 h-100 d-flex flex-column align-items-center justify-content-center bg-ssf-gray-l5">
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <i className="fal fa-fw fa-check-circle fa-2x text-success"></i>
                    <h5 className="text-gray-8 fs-11" style={{ marginTop: '0.5rem' }}>Order has been created successfully.<br />Please click on Confirm to proceed.</h5>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      );
    }
  }
}

export default ItemCard;

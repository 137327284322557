import React, { Component, useState } from 'react'
import style from './TradingAccount.module.scss'
import { Spinner } from 'shared-components/'
import { Col, Form, FormGroup, Input, Label, Modal, ModalBody, Row, Spinner as RSpinner, Tooltip } from 'reactstrap'
import { Regex } from 'common/regex';
import EditableTradingAccountForm from "./EditableTradingAccountForm"
import { Formik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { fetchProfile , cancelAchRelation ,updateTradAccDisclosure } from 'actions/profile'
import noAccount from "../../../../assets/images/NonExistingTradingAccount.svg"
import { Radio } from 'antd'
import AddMoneyPopup from '../../SFM/ActTab/AddMoney'
import { fetchApexBalance } from 'apis/funds'
import { fetchBalanceData } from '../../SFM/ActTab/ItemList'
import * as ProfileApi from '../../../../apis/profile';
import qs from 'query-string'
import { isEmpty, startCase } from 'lodash'
import CustomAlert from 'components/CustomAlert';
import minusImg from '../../../../assets/images/minus.svg'
import plusImg from '../../../../assets/images/plus.svg'
import {
  trustedContactCountry
} from '../../Constant/FormData';
import * as RegisterApi from 'apis/register'
import { PlaidLink } from 'react-plaid-link'
import * as PropTypes from 'prop-types'
import { createAchRelation, rebuildACH } from 'apis/register'
import { numFmt } from '../../../../Utils';

const imagesPath = {
  minus: minusImg,
  plus: plusImg
}

class TradingAccount extends Component {
  state = {
    showAddMoneyPopup:false,
    isWithdrawal:false,
    showMartialSelection:false,
    balance: '',
    startBalance:'',
    newAchData: [],
    profileLoading: false,
    bankAccountArray: [],
    showUpdate: false,
    showUserDetailsUpdate: false,
    showDiscloserMessage: null,
    showTrustedContactSelection: false,
    showPoliticallyExposed: false,
    showPoliticallyExposedJoint: false,
    loadingBankDetails: false,
    bankDetailCalcultion: this.props.history.location.linkaccount ? false : true,
    bankData: null,
    form:{
      //your details section
        customerType:"",
        marital_status:"",
        citizenship:"",
        ssn:"",
        experience:"",
        countryM:"",
        cityM:"",
        stateM:"",
        zipcodeM:"",
        addressM:"",
        countryB:"",
        cityB:"",
        stateB:"",
        zipcodeB:"",
        addressB:"",
        // primary_firstname: "",
        // primary_lastname      : "",
        secondary_firstname   : "",
        secondary_lastname    : "",
        identity_proof:"",
        address_proof:"",
        citizenship_proof:"",
        employement_type:"",
        employer_name:"",
        occupation:"",
        report_year:"",
        //Disclosure
        isAffiliatedExchangeOrFINRA:"",
        isPoliticallyExposed:"",
        upload_3210:"",
        upload_407:"",
        upload_ria:"",
        political_organization: [],
        upload_type           :"",
        upload_file           :"",
        //requestsection
        transfer_account_firstname:"",
        transfer_account_secondname:"",
        transfer_account_ssn:"",
        transfer_account_relation:"",
        transfer_account_citizenship:"",
        //acc details
        account_id :"",
        request_status :"",
        request_type:"",
        isLoading: false,
        status: '',
        trusted_contact: false,
        trusted_select         : ["trusted_email"],
        trusted_contact_email   :"",
        trusted_contact_mobile  :"",
        trusted_contact_firstname:"",
        trusted_contact_lastname :"",
        trusted_contact_country : "",
        trusted_contact_state   :"",
        trusted_contact_city    :"",
        trusted_contact_street  :"",
        trusted_contact_zipcode :"",
        trusted_contact_country_code: "+1",
        investmentObjective: "",
        annualIncomeUSD: "",
        // liquidNetWorthUSD: "",
        totalNetWorthUSD: "",
        // riskTolerance: "",
        // federalTaxBracketPercent: "",
        dividendReinvestment: "REINVEST",
        firm_name: "",
        service_profile: "",

        joint_is_affiliated_exchange_finra: "",
        joint_upload_file_type: "",
        joint_is_political_exposed: "",
        joint_is_control_person: "",
        is_joint_employed: "",
        joint_country_code: "+1",
        joint_phone: "",
        joint_postal: "",
        joint_city: "",
        joint_street: "",
        joint_state: "",
        joint_country: "",
        joint_email: "",
        joint_dob: "",
        joint_citizenship: "",
        joint_ssn: "",
        joint_first_name: "",
        joint_last_name: "",
        joint_occupation: "",
        joint_employer_name: ""
    },
    isCollapsedLinkedAcc:this.props.history.location.search.includes('linked_accounts')?false:true,
    token:'',
    wdTooltipOpen:false,
    addTooltipOpen:false,
  }

  componentDidMount() {
    this.setState({ profileLoading: true });
    this.props.fetchProfile({},()=>{
      this.setState({ profileLoading: false });
      if(this.props.history.location.linkaccount){
        this.setState({ isCollapsedLinkedAcc: false })
      }
    });
    document.getElementsByTagName('body')[0].style.paddingTop = 0;
    this.getPlaidToken();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.profile !== prevProps.profile) {
      let {profile} = this.props
      if( profile.apex && Object.keys( profile.apex).length>0){
        let balanceData = await fetchBalanceData();
        // console.log('--- balanceData ---', balanceData);
        // let balance = balanceData.total;
        let balance = balanceData.total > balanceData.balancePower ? balanceData.balancePower : balanceData.total; // assign MIN value
        let startBalance = balanceData.startDayCashAvailable;
        let deposits = profile.apex.deposits ?profile.apex.deposits :[]
        let newAchData = [], bankAccountArray = [];
        profile.apex.ach && profile.apex.ach.forEach(item=>{
          let temp = {...item}
          for (let i = 0; i < deposits.length; i++) {
            if(temp.ach_id===deposits[i].relationship_id){
              temp.amount = deposits[i].amount
              break
            }
          }
          newAchData.push(temp)
        })
        profile.apex.bank && profile.apex.bank.forEach(item=>{
          bankAccountArray.push(item)
        })
        let genData = profile.apex.account && profile.apex.account .length ? profile.apex.account[0] : {}
        let reData = isEmpty(profile.apex.request) ? {} : profile.apex.request[0]
        let reqData = genData.request_data
        let jsonData = JSON.parse(reqData).forms[0].jsonData
        let jointUser = isEmpty(profile.apex.joint_user) ? {} : profile.apex.joint_user
        let service_profile =  isEmpty(genData.service_profile) ? {} : JSON.parse(genData.service_profile);
        let uploadType = '';
        let uploadFile = '';
        if(genData.upload_3210 && genData.upload_3210 !== ''){
          uploadType = 'upload_3210';
          uploadFile = genData.upload_3210;
        }
        if(genData.upload_407 && genData.upload_407 !== ''){
          uploadType = 'upload_407';
          uploadFile = genData.upload_407;
        }
        const getSelectedTrustedAccount = (profile) => {
          let selected = ["trusted_email"];
          if(!profile){
            return selected;
          }
          if(profile.trusted_contact_mobile !== ""){
            selected.push("trusted_phone");
          }
          if(profile.trusted_contact_city !== ""){
            selected.push("trusted_address");
          }
          return selected;
        }
        const filterd_joint_country = trustedContactCountry.filter(val => val['alpha-3'] === jointUser.joint_country);
        let joint_country = "";
        if(filterd_joint_country.length){
          joint_country = filterd_joint_country[0].name;
        }
        this.setState(prevState =>({
          balance,
          startBalance,
          newAchData,
          bankAccountArray,
          bankDetailCalcultion: false,
          showUpdate: false,
          showUserDetailsUpdate: false,
          showTrustedContactSelection: false,
          showPoliticallyExposed: false,
          showPoliticallyExposedJoint: false,
          form:{
            ...prevState.form,
            // Your Details
            customerType:jsonData.customerType,
            marital_status:genData.marital_status,
            citizenship:genData.citizenship,
            experience: genData.investment_exprience ? startCase(genData.investment_exprience.toLowerCase()) : '',

            investmentObjective: jsonData.investmentProfile ? jsonData.investmentProfile.investmentObjective : '',
            annualIncomeUSD: jsonData.investmentProfile ? `${jsonData.investmentProfile.annualIncomeUSD.min}-${jsonData.investmentProfile.annualIncomeUSD.max}` : '',
            // liquidNetWorthUSD: jsonData.investmentProfile && jsonData.investmentProfile.liquidNetWorthUSD ? `${jsonData.investmentProfile.liquidNetWorthUSD.min}-${jsonData.investmentProfile.liquidNetWorthUSD.max}` : '',
            totalNetWorthUSD: jsonData.investmentProfile ? `${jsonData.investmentProfile.totalNetWorthUSD.min}-${jsonData.investmentProfile.totalNetWorthUSD.max}` : '',
            // riskTolerance: jsonData.investmentProfile && jsonData.investmentProfile.riskTolerance ? jsonData.investmentProfile.riskTolerance : '',
            // federalTaxBracketPercent: jsonData.investmentProfile && jsonData.investmentProfile.federalTaxBracketPercent ? jsonData.investmentProfile.federalTaxBracketPercent : "",
            dividendReinvestment: service_profile && service_profile.dividendReinvestment ? service_profile.dividendReinvestment : "",

            ssn:genData.ss_number,
            //address
            countryM:profile && profile.country,
            stateM:profile && profile.state,
            cityM:profile && profile.city,
            addressM:profile && profile.address,
            zipcodeM:profile && profile.zipcode,
            countryB:profile && profile.country,
            stateB:profile && profile.state,
            cityB:profile && profile.city,
            addressB:profile && profile.address,
            zipcodeB:profile && profile.zipcode,
            isAffiliatedExchangeOrFINRA:genData.is_affiliated_exchange_finra,
            isPoliticallyExposed: genData.is_political_exposed,
            employement_type:genData.is_employed,
            employer_name:genData.employer_name,
            occupation:genData.occupation,
            // liquidity_needs:genData.liquidity_needs ? genData.liquidity_needs : '',
            // time_horizon:genData.time_horizon ? genData.time_horizon : '',
            is_control_person:genData.is_control_person,
            stock_name:genData.stock_name,
            upload_file: uploadFile,
            upload_type: uploadType,
            upload_3210: genData.upload_3210,
            upload_407: genData.upload_407,
            upload_ria:genData.upload_ria,
            political_organization: (genData && genData.political_organization) ? JSON.parse(genData.political_organization) : [],
            // account details
            account_id: genData.id,
            request_status:reData.status,
            request_type:reData.request_type,
            status: profile && profile.status,
            trusted_contact: genData && genData.trusted_contact === 'YES' ? true : false,
            trusted_select: getSelectedTrustedAccount(profile),
            trusted_contact_email     :  genData && genData.trusted_contact_email,
            trusted_contact_country_code: genData && genData.trusted_contact_country_code,
            trusted_contact_mobile    :  genData && genData.trusted_contact_mobile,
            trusted_contact_firstname :  genData && genData.trusted_contact_firstname,
            trusted_contact_lastname  :  genData && genData.trusted_contact_lastname,
            trusted_contact_country   :  genData && genData.trusted_contact_country,
            trusted_contact_state     :  genData && genData.trusted_contact_state,
            trusted_contact_city      :  genData && genData.trusted_contact_city,
            trusted_contact_street    :  genData && genData.trusted_contact_street,
            trusted_contact_zipcode   :  genData && genData.trusted_contact_zipcode,
            firm_name   : genData &&  genData.firm_name,

            joint_is_affiliated_exchange_finra: jointUser.joint_is_affiliated_exchange_finra,

            joint_upload_file_type: jointUser.joint_upload_file_type ? jointUser.joint_upload_file_type.toLowerCase().replace(' ', '_') : "",
            joint_upload_file: jointUser.joint_upload || "",

            joint_upload_3210: jointUser.joint_upload_file_type && jointUser.joint_upload_file_type === 'Upload 3210' ? jointUser.joint_upload : '',

            joint_upload_407: jointUser.joint_upload_file_type && jointUser.joint_upload_file_type === 'Upload 407' ? jointUser.joint_upload : '',

            joint_political_organization: (genData && genData.joint_political_organization) ? JSON.parse(genData.joint_political_organization) : [],
            joint_is_political_exposed: jointUser.joint_is_political_exposed,
            joint_is_control_person: jointUser.joint_is_control_person,
            joint_stock_name: jointUser.joint_stock_name ? jointUser.joint_stock_name : "",
            is_joint_employed: jointUser.is_joint_employed ? startCase(jointUser.is_joint_employed.toLowerCase()) : '',
            joint_country_code: jointUser.joint_country_code,
            joint_phone: jointUser.joint_phone,
            joint_postal: jointUser.joint_postal,
            joint_city: jointUser.joint_city,
            joint_street: jointUser.joint_street,
            joint_state: jointUser.joint_state,
            joint_country: joint_country,
            joint_email: jointUser.joint_email,
            joint_dob: jointUser.joint_dob,
            joint_citizenship: jointUser.joint_citizenship,
            joint_ssn: jointUser.joint_ssn,
            joint_first_name: jointUser.joint_first_name,
            joint_last_name: jointUser.joint_last_name,
            joint_firm_name: jointUser.joint_firm_name ? jointUser.joint_firm_name : "",
            joint_occupation: jointUser.joint_occupation,
            joint_employer_name: jointUser.joint_employer_name ? jointUser.joint_employer_name : '',
          }
        }))
      }
    }
    if (this.props.updateDisclosure !== prevProps.updateDisclosure) {
      if(this.props.updateDisclosure.data){
          this.setState({
            showDiscloserMessage: {
              message:  "Updated Successfully",
              type: 'info'
            }
          });
      }else if(this.props.updateDisclosure.error){
        this.setState({
          showDiscloserMessage: {
            message: this.props.updateDisclosure.error,
            type: 'danger'
          }
        });
      }else{
        this.setState({
          showDiscloserMessage: null
        });
      }
    }
  }

  toggleTrustedContact = (status = true) => {
    this.setState({
      showTrustedContactSelection: status
    })
  }

  toggleUpdate = (status = false) => {
    this.setState({showUpdate: status});
  }

  toggleUserDetailsUpdate = (status = false) => {
    this.setState({showUserDetailsUpdate: status});
  }

  handleRedirect=()=>{
      const {history}=this.props;
      history.push('/register/trading')
  }

  toggleAddMoneyPopup = (isWithdrawal=false)=>{
    // console.log(isWithdrawal)
    this.setState({
      showAddMoneyPopup:!this.state.showAddMoneyPopup,
      isWithdrawal
    })
  }

  handleArcCancel = (id)=>{
    this.props.cancelAchRelation({
      id,
      comment : "Hi Test"
    })
  }

  updateTradingAccount = async (postData) => {
    this.props.updateTradAccDisclosure(postData)
  }

  cancelTradingAccount = async (postData) => {
    try {
      this.setState({isFetching: true});
      let _responseData = await ProfileApi.cancelAccount(postData);
     if(_responseData.status == '200')
     {
      this.props.fetchProfile();
      this.setState({success: true});
     }
      this.setState({isFetching: false});
    }  catch(error) {
      console.log(error);
      this.setState({isFetching: false});
      if (error && typeof error.response !== 'undefined') {
        if (typeof error.response.data !== 'undefined') {
          //TO DO
        }
      }
    }
  }

  cancelDeposits = async (postData) => {
    try {
      this.setState({isLoading: true});
      let _responseData = await ProfileApi.cancelDeposits(postData);

      if(_responseData.status == '200')
      {
        this.props.fetchProfile();
        this.setState({success: true});
      }

      this.setState({isLoading: false});
    } catch(error) {
      console.log(error);
      this.setState({isLoading: false});
      if (error && typeof error.response !== 'undefined') {
        if (typeof error.response.data !== 'undefined') {
          //TO DO
        }
      }
    }
  }

  checkInActiveAccount = (apex) => {
    if(apex){
        if(apex.account && !apex.account[0].is_active){
          return true;
        }
        if(apex.request && apex.request.length){
          let closed = apex.request.filter(req => req.request_type === 'CLOSE_ACCOUNT' && (req.status === "REQUESTED" || req.status === "INPROGRESS" || req.status === "APPROVED") || (req.request_type === 'CLOSE_ACCOUNT' && (req.status === 'INPROGRESS_FUNDS_SOLD' || req.status === 'INPROGRESS_CASH_WITHDRAWN')));
          if(closed.length){
            return true;
          }
        }
    }
    return false;
  }

  handleFormSubmit = (values) =>{
    const { liquidity_needs, time_horizon, occupation, employer_name ,employement_type ,isPoliticallyExposed ,isAffiliatedExchangeOrFINRA ,upload_type,stock_name,is_control_person ,upload_file, political_organization, firm_name,
    marital_status, ssn, experience, trusted_select, trusted_contact, trusted_contact_city, trusted_contact_country, trusted_contact_email, trusted_contact_firstname, trusted_contact_lastname, trusted_contact_mobile, trusted_contact_state, trusted_contact_street, trusted_contact_zipcode, investmentObjective, annualIncomeUSD, liquidNetWorthUSD, totalNetWorthUSD,  riskTolerance, federalTaxBracketPercent, dividendReinvestment, customerType, joint_is_affiliated_exchange_finra, joint_upload_file_type,
    joint_upload_file, joint_upload_3210, joint_upload_407, joint_political_organization, joint_is_political_exposed,
    joint_is_control_person, joint_stock_name, is_joint_employed, joint_phone, joint_postal, joint_city, joint_street,
    joint_state, joint_country, joint_email, joint_dob, joint_citizenship, joint_ssn, joint_first_name, joint_last_name,
    joint_firm_name, joint_occupation, joint_employer_name } = values;

    let data = {
      // liquidity_needs : liquidity_needs.split(' ').join('_').toUpperCase(),
      // time_horizon : time_horizon.toUpperCase(),
      occupation ,
      employer_name,
      is_employed: employement_type ,
      is_political_exposed: isPoliticallyExposed,
      political_organization: isPoliticallyExposed ? JSON.stringify(political_organization) : null,
      is_affiliated_exchange_finra: isAffiliatedExchangeOrFINRA,
      is_control_person,
      stock_name,
      marital_status,
      "upload_3210"                    : "",
      "upload_407"                     : "",
      // ss_number: ssn,
      investment_exprience             : experience.toUpperCase(),
      trusted_contact                  : trusted_contact ? "YES" : "NO",
      trusted_contact_city             : trusted_select.includes("trusted_address") ? trusted_contact_city : "",
      trusted_contact_country          : trusted_select.includes("trusted_address") ? trusted_contact_country : "",
      trusted_contact_email            : trusted_select.includes("trusted_email") ? trusted_contact_email : "",
      trusted_contact_firstname        : trusted_contact ? trusted_contact_firstname : "",
      trusted_contact_lastname         : trusted_contact ? trusted_contact_lastname : "",
      trusted_contact_country          : values.trusted_contact && values.trusted_select.includes("trusted_phone" ) ? values.trusted_contact_country : "",
      trusted_contact_mobile           : trusted_select.includes("trusted_phone") ? trusted_contact_mobile : "",
      trusted_contact_country_code     : values.trusted_contact && values.trusted_select.includes("trusted_phone" ) ?  values.trusted_contact_country_code : "",
      trusted_contact_state            : trusted_select.includes("trusted_address") ? trusted_contact_state : "",
      trusted_contact_street           : trusted_select.includes("trusted_address") ? trusted_contact_street : "",
      trusted_contact_zipcode          : trusted_select.includes("trusted_address") ? trusted_contact_zipcode : "",
      firm_name,
      // "risk_to_laerance"               : riskTolerance,
      // "feederal_tax_bracket"           : federalTaxBracketPercent,
      "annual_income_max"              : (annualIncomeUSD) ? annualIncomeUSD.split("-")[1] : "",
      "annual_income_min"              : (annualIncomeUSD) ? annualIncomeUSD.split("-")[0] : "",
      // "liquid_net_max"                 : (liquidNetWorthUSD) ? liquidNetWorthUSD.split("-")[1] : "",
      // "liquid_net_min"                 : (liquidNetWorthUSD) ? liquidNetWorthUSD.split("-")[0] : "",
      "total_net_worth_max"            : (totalNetWorthUSD) ? totalNetWorthUSD.split("-")[1] : "",
      "total_net_worth_min"            : (totalNetWorthUSD) ? totalNetWorthUSD.split("-")[0] : "",
      "investment_obj"                 : investmentObjective,
      "divident_reinvestment"          : dividendReinvestment,

    }

    if(customerType === "JOINT"){
      data = {
        ...data,
        joint_firm_name,
        is_joint_employed,
        joint_phone,
        joint_is_political_exposed,
        joint_political_organization    : JSON.stringify(joint_political_organization),
        joint_is_control_person,
        joint_stock_name,
        joint_occupation,
        joint_employer_name,
        joint_is_affiliated_exchange_finra,
        joint_upload_file_type           : joint_upload_file_type ? startCase(joint_upload_file_type.replace('_', ' ')) : '',
        joint_upload: joint_upload_file
      }
    }

    if(isAffiliatedExchangeOrFINRA === 'YES'){
        data[upload_type] = upload_file;
    }
    this.updateTradingAccount(data)
  }

  updateEmail = async (postData) => {
    try {
      this.setState({isLoading: true});
      let data = {
        apex_email: postData
      }
      // let bodyFormData = new FormData();
      // bodyFormData.append('apex_email', postData);

      let _responseData = await ProfileApi.updateTradingAccount(data);

      if(_responseData.status == '200')
      {
        this.props.fetchProfile();
        this.setState({success: true});
      }

      this.setState({isLoading: false});
    } catch(error) {
      console.log(error);
      this.setState({isLoading: false});
      if (error && typeof error.response !== 'undefined') {
        if (typeof error.response.data !== 'undefined') {
          //TO DO
        }
      }
    }
  }

  getPlaidToken = async () => {
    this.setState({
      isLoading:true
    })
    try {
      let getLinkData = await RegisterApi.getLinkToken();
      this.setState({
        token: getLinkData.link_token,
        isLoading:false
      })
    }
    catch(error) {
      console.log(error);
      this.setState({isLoading:false});
    }
  }

  onSuccess= async (public_token, metadata)=> {
    // console.log(public_token,metadata)
    // send token to client server
    if (public_token && metadata && metadata.institution && metadata.accounts) {
      let postData = {
        public_token       : public_token,
        institution_name   : metadata.institution.name,
        link_token         : this.state.token,
        acc_numbers        : metadata.accounts.map(el => el.id)
      }

      try {
        this.setState({
          isLoading:true,
          loadingBankDetails: true
        })
        let _responseData = await RegisterApi.getUsersAccountDetails(postData);
        if (_responseData) {
          if(_responseData.length && _responseData.length === 1){
            let plaid_account_id = _responseData[0].account_id;
            let plaid_user_id = _responseData[0].plaid_user_id;
            this.setState({ bankData: {
              plaid_account_id,plaid_user_id
            }},()=>{
              this.handleAddAccount();
            })
          } else{
            this.setState({
              bankAccountDataStatus: true,
              bankAccountDetails: _responseData,
              institution_name: metadata.institution.name,
            })
            this.setState({ isLoading:false })
          }
        }
        this.setState({ loadingBankDetails: false })
      }
      catch(error) {
        console.log(error);
        this.setState({isLoading: false, loadingBankDetails: false});
      }
    }
  }


  hidePlaidBankSelect = ()=>{
    this.setState({
      bankAccountDataStatus: false,
      bankAccountDetails: null,
      institution_name:null,
    })
  }

  rebuildAch = async ()=>{
    this.setState({
      isLoading:true
    })
    try{
      let data = await rebuildACH({"apex_bank_id": this.props.profile.apex.bank[0].id})
      this.props.fetchProfile()
      this.setState({
        isLoading:false
      })
    }
    catch (e) {
      console.log(e)
      this.setState({
        isLoading:false
      })
    }
  }

  togglePoliticallyExposed = () => {
    this.setState({
      showPoliticallyExposed: true,
    });
  }

  togglePoliticallyExposedJoint = () => {
    this.setState({
      showPoliticallyExposedJoint: true,
    });
  }

  radioChange = (event) => {
    let plaid_account_id = event.target.value;
    let plaid_user_id = event.target.getAttribute("data-user-id");
    this.setState({ bankData: {
      plaid_account_id,plaid_user_id
    }})
  }

  handleAddAccount =async ()=>{
    this.setState({
      isLoading: true
    })
    try {
      await createAchRelation(this.state.bankData)
      this.props.fetchProfile({},()=>{
        this.hidePlaidBankSelect();
        this.setState({
          isLoading:false
        })
      });
    }catch (e) {
      this.setState({
        isLoading:false
      })
    }
  }

  render() {
    const { profile } = this.props
    const { showAddMoneyPopup, balance, newAchData, startBalance, showUpdate, showDiscloserMessage, form: { status },isCollapsedLinkedAcc, showUserDetailsUpdate, customerType,token, showPoliticallyExposed, showPoliticallyExposedJoint, bankAccountArray, profileLoading, bankDetailCalcultion } = this.state;
    const { isLoading } = this.props.profile;

    // console.log("trading Account props",this.props)
    // console.log("trading Account state",this.state)
    // console.log("isloading", isLoading)

    // console.log('== Available Cash Withdrawal Amount ==', balance);

    let validationSchema = getValidationSchema(customerType, showUpdate);
    return (
      <>
        {this.state.loadingBankDetails ? <div className='whole_page_just_loading'>
          <Row>
            <Col sm={12} className="text-center">
              <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5" style={{ fontSize: '4em' }}></i>
            </Col>
            <Col sm={12} className="text-center mt-2">
              <span>Fetching Account Information From Your Bank</span>
            </Col>
          </Row>
        </div> : null}
        <Spinner isFetching={this.state.isLoading || isLoading || profileLoading}/>
        {this.state.success &&
            <div>
                <CustomAlert className="alert-windown-wrapper" color='info'>
                    Requested successfully.
                </CustomAlert>
              </div>
        }
        {showDiscloserMessage &&
            <div>
                <CustomAlert className="alert-windown-wrapper" color={showDiscloserMessage.type}>
                    {showDiscloserMessage.message}
                </CustomAlert>
              </div>
        }
        {profile.apex &&  Object.keys(profile.apex).length && profile.apex.account && profile.apex.account.length ?
        <div className={`${style.container} ${this.checkInActiveAccount(profile.apex)
          ? style.inActiveSettings : ''}`}>
          <div className={style.divider} />
          <div
            onClick={() => this.setState({ isCollapsedLinkedAcc: !this.state.isCollapsedLinkedAcc })}
            className={style.sectionHeader}>
            <span className={style.subHeader}>{bankDetailCalcultion ? 'Transfer Funds' : !bankAccountArray.length ? 'Link your account' : 'Transfer Funds'}</span>
            {/* <i class={!isCollapseACHRelationship?"fas fa-plus-circle":"fas fa-minus-circle"} onClick={()=> this.setState({isCollapseACHRelationship:!this.state.isCollapseACHRelationship})} ></i> */}
            <img style={{ width: 20 }} src={!isCollapsedLinkedAcc ? imagesPath.minus : imagesPath.plus} />
          </div>
          {!isCollapsedLinkedAcc && (!bankAccountArray.length ? <div>
            {!this.state.bankAccountDetails && (<div className={style.formGroup}>
              <Label className={style.key}>Bank Account</Label>
              <div className={style.key}></div>
              {token ?
                <PlaidLink
                  product={['auth']}
                  onSuccess={this.onSuccess}
                  className={`${style.update} ${style.updatePlaid}`}
                  token={token}>
                  ADD ACCOUNT
                </PlaidLink> :
                <p className={`${style.update} ${style.updatePlaid}`}> Add Account</p>}
            </div>)}
            {this.state.bankAccountDetails && this.state.bankAccountDetails.length > 0 && (
              <div className={style.formGroup}>
                <Label className={style.key}>Select the bank account you want to link</Label>
              </div>
            )}
            {this.state.bankAccountDetails && this.state.bankAccountDetails.length > 0 && (
              <div className={style.formGroup}>
                <label className={style.key}><b>Bank Account: </b> {this.state.institution_name}</label>
                <div className={style.value}>
                  <button
                        onClick={() => {this.hidePlaidBankSelect()}}
                        id={'changeAcc'}
                        className={style.update}
                >
                  CHANGE BANK
                </button>
                </div>
              </div>
            )}
            {this.state.bankAccountDetails && this.state.bankAccountDetails.length > 0 &&
              this.state.bankAccountDetails.map((obj, idx) => {
                return (
                  <div className={style.formGroup}>
                    <div className={style.key}>
                      <div className="element-box bank-details">
                        <Input type="radio" name="account_id" data-user-id={obj.plaid_user_id} value={obj.account_id} id={`account_${obj.account_id}`} onChange={(e) => this.radioChange(e)} />
                        <label htmlFor={`account_${obj.account_id}`} className="label">{obj.name}</label>
                      </div>
                    </div>
                    <div className={style.key} style={{ position: 'relative' }}>
                      <label>{`XXXX${obj.mask}`}</label>
                      {this.state.bankData && this.state.bankData.plaid_account_id && this.state.bankData.plaid_account_id === obj.account_id && (<span onClick={()=> this.handleAddAccount()} className={style.link_account_point}>Link Account</span>)}
                    </div>
                  </div>
                )
              })}
          </div> : <div>
            {profile.apex.bank && profile.apex.bank[0] ? <FormGroup className={style.formGroup}>
              <Label className={style.key}
                     for='gender'>{profile.apex.bank && profile.apex.bank[0].bank_account_owner_name}</Label>
              <div
                className={`${style.cursorPointer} ${style.value}`}>XXXX{profile.apex.bank && profile.apex.bank[0].bank_account_no.slice(profile.apex.bank[0].bank_account_no.length - 4)}</div>
            </FormGroup>:null}
            <div className={style.formGroup}>
              <div className={style.key}></div>
              {token ?
                <PlaidLink
                  product={['auth']}
                  onSuccess={this.onSuccess}
                  className={`${style.update} ${style.updatePlaid}`}
                  token={token}>
                  Update Account
                </PlaidLink> :
                <p className={`${style.update} ${style.updatePlaid}`}> Update Account</p>
              }
            </div>
            <FormGroup className={style.formGroup}>
              <Label className={style.key} for={'gender'}>Start of Day Cash Balance</Label>
              <div className={`${style.cursorPointer} ${style.value}`}>${startBalance === '' || !startBalance ? '0.00' : numFmt(startBalance)}</div>
            </FormGroup>
            <FormGroup className={style.formGroup}>
              <Label className={style.key} for={'gender'}>Available Cash Withdrawal Amount</Label>
              <div className={`${style.cursorPointer} ${style.value}`}>${balance ? numFmt(balance) : '0.00'}</div>
            </FormGroup>
            <div className={style.formGroup}>
              <div className={style.key}></div>
              <div className={style.value}>
                <button disabled={status === 'REJECTED'}
                        onClick={(e) => {
                          e.preventDefault();
                          newAchData.length && balance > 0 && this.toggleAddMoneyPopup(true)
                        }}
                        style={{ marginRight: `10px` }}
                        id={'wdBalance'}
                        className={`${style.update} ${balance<=0 || !newAchData.length ? style.disabled : ""}`}
                >
                  Withdraw
                </button>
              {balance<=0 || !newAchData.length ? <Tooltip className={`col-grey-bottom`} placement="bottom" isOpen={this.state.wdTooltipOpen} target={`wdBalance`} toggle={() => this.setState({ wdTooltipOpen: !this.state.wdTooltipOpen })}>
                {!newAchData.length ? "Your bank account information is not found. Please link your investment account with your bank account to continue trading." : `Available Withdrawal Amount : $${balance}`}
              </Tooltip> : null}
                <button
                  disabled={status === 'REJECTED'}
                  id={'addBalance'}
                  onClick={(e) => {
                    e.preventDefault()
                    newAchData.length && this.toggleAddMoneyPopup(false)
                  }}
                  className={`${style.update} ${!newAchData.length && style.disabled}`}
                  >Add Balance
                </button>
                {!newAchData.length ? <Tooltip className={`col-grey-bottom`} placement="bottom" isOpen={this.state.addTooltipOpen} target={`addBalance`} toggle={() => this.setState({ addTooltipOpen: !this.state.addTooltipOpen })}>
                {"Your bank account information is not found. Please link your investment account with your bank account to continue trading."}
              </Tooltip> : null}
              </div>
            </div>
          </div>)}
          {!bankAccountArray.length ? null : <PlaidBankSelectModal
            hidePlaidBankSelect={this.hidePlaidBankSelect}
            bankAccountDataStatus={this.state.bankAccountDataStatus}
            institutionName={this.state.institution_name}
            bankAccountDetails={this.state.bankAccountDetails}
            fetchProfile={this.props.fetchProfile}/>}
          <div className={style.divider}/>
          {/* <span className={style.subHeader}>Your Details</span> */}
          <Formik
                enableReinitialize
                initialValues={this.state.form}
                validationSchema={validationSchema}
                onSubmit={this.handleFormSubmit}
                render={props => (
                    <Form
                      className={style.form}
                      noValidate={true}
                      onSubmit={props.handleSubmit}
                    >
                      <EditableTradingAccountForm
                        history={this.props.history}
                        newAchData={newAchData}
                        profile={profile}
                        formProps={props}
                        handleArcCancel={(id)=>this.handleArcCancel(id)}
                        updateTradingAccount={this.updateTradingAccount}
                        cancelTradingAccount={this.cancelTradingAccount}
                        cancelDeposits={this.cancelDeposits}
                        toggleUpdate={this.toggleUpdate}
                        showUpdate={showUpdate}
                        showUserDetailsUpdate={showUserDetailsUpdate}
                        toggleUserDetailsUpdate={this.toggleUserDetailsUpdate}
                        updateEmail={this.updateEmail}
                        onSuccess={this.onSuccess}
                        token={token}
                        hidePlaidBankSelect={this.hidePlaidBankSelect}
                        bankAccountDataStatus={this.state.bankAccountDataStatus}
                        institutionName={this.state.institution_name}
                        bankAccountDetails={this.state.bankAccountDetails}
                        rebuildAch={this.rebuildAch}
                        toggleTrustedContact={this.toggleTrustedContact}
                        showTrustedContactSelection={this.state.showTrustedContactSelection}
                        showPoliticallyExposed={showPoliticallyExposed}
                        showPoliticallyExposedJoint={showPoliticallyExposedJoint}
                        togglePoliticallyExposed={this.togglePoliticallyExposed}
                        togglePoliticallyExposedJoint={this.togglePoliticallyExposedJoint}
                      />
                    </Form>
                )}
            />
        </div> :
          profile.apex ? <div className={style.container}>
            <div className={style.notFound}>
                <img src={noAccount} />
                <div className={style.subHeader}>You Have Not Created Any Magnifi Investment Account</div>
                <button className={style.createButton} onClick={()=>this.handleRedirect()} >Create A Magnifi Investment Account</button>
            </div>
        </div>:<Spinner isFetching={true}/>}
        { <AddMoneyPopup wdBalance={balance} toggleAddMoneyPopup={this.toggleAddMoneyPopup} isWithdrawal={this.state.isWithdrawal} showAddMoneyPopup={this.state.showAddMoneyPopup}  />}
      </>
    )
  }
}

const getValidationSchema  = () => {
  let userDetailsvalidationSchema = Yup.object().shape({
    customerType: Yup.string(),
    experience: Yup.string().required("Investment experience is required"),
    investmentObjective: Yup.string().required("Investment Objective is required"),
    annualIncomeUSD: Yup.string().required("Annual Income is required"),
    // liquidNetWorthUSD: Yup.string().required("Liquid Net Worth is required"),
    totalNetWorthUSD: Yup.string().required("Total Net Worth is required"),
    // riskTolerance: Yup.string().required("Risk Tolerance is required"),
    // federalTaxBracketPercent: Yup.number().required('This is a required field').min(0, 'Min value 0.')
    // .max(100, 'Max value 100.').test(
    //   'maxDigitsAfterDecimal',
    //   'Tax Bracket must have 2 digits or less after decimal',
    //   value => (value + "").match(/^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/),
    // ),
    dividendReinvestment: Yup.string().required("Dividend Reinvestment is required"),
    isAffiliatedExchangeOrFINRA: Yup.string(),
    stock_name: Yup.string().when('is_control_person' , {
      is: (is_control_person) => (is_control_person === 'YES'),
      then: Yup.string().required('This is a required field').matches(
        Regex.someCharacter,
        'This is a required field'
      ),
    }),
    firm_name: Yup.string().when('isAffiliatedExchangeOrFINRA' , {
      is: (isAffiliatedExchangeOrFINRA) => (isAffiliatedExchangeOrFINRA === 'YES'),
      then: Yup.string().required('Firm Name is a required field').matches(
        Regex.someCharacter,
        'This is a required field'
      ),
    }),
    upload_type : Yup.string().when('isAffiliatedExchangeOrFINRA' , {
      is: (isAffiliatedExchangeOrFINRA) => (isAffiliatedExchangeOrFINRA === 'YES'),
      then: Yup.string().required('This is a required field'),
    }),
    upload_file : Yup.string().when('upload_type' , {
      is: (upload_type) => (upload_type === "upload_3210" || upload_type == 'upload_407'),
      then: Yup.string().required('This is a required field'),
    }),
    isPoliticallyExposed : Yup.string().required('This is a required field'),
    political_organization: Yup.array()
    .when(['isPoliticallyExposed'], {
              is: (isPoliticallyExposed) => {
                return isPoliticallyExposed === 'YES';
              },
              then: Yup.array().of(Yup.object().shape({
                relation: Yup.string().required('This is a required field').matches(
                  Regex.someCharacter,
                  'This is a required field'
                ),
                political_organization: Yup.string().required('This is a required field').matches(
                  Regex.someCharacter,
                  'This is a required field'
                )
              }))
    }),
    trusted_contact_firstname     : Yup.string().when('trusted_contact', {
      is: (trusted_contact) => (trusted_contact),
      then: Yup.string().required('This is a required field').matches(
        Regex.someCharacter,
        'This is a required field'
      ),
      }),
    trusted_contact_lastname      : Yup.string().when('trusted_contact', {
        is: (trusted_contact) => (trusted_contact),
        then: Yup.string().required('This is a required field').matches(
          Regex.someCharacter,
          'This is a required field'
        ),
        }),
    trusted_contact_email      : Yup.string().when(['trusted_contact','trusted_select'], {
        is: (trusted_contact, trusted_select) => (trusted_contact && trusted_select.includes("trusted_email")),
        then: Yup.string().email('Invalid email address').required('This is a required field'),
        }),
    trusted_contact_mobile      : Yup.string().when(['trusted_contact','trusted_select'], {
        is: (trusted_contact, trusted_select) => (trusted_contact && trusted_select.includes("trusted_phone")),
        then: Yup.string().required('This is a required field').matches(
          Regex.phone,
          'Phone number is not valid. Sample format (515) 989-2899'
        ),
        }),
    trusted_contact_country      : Yup.string().when(['trusted_contact','trusted_select'], {
        is: (trusted_contact, trusted_select) => (trusted_contact && trusted_select.includes("trusted_address")),
        then: Yup.string().required('This is a required field'),
        }),
    trusted_contact_state      : Yup.string().when(['trusted_contact','trusted_select'], {
        is: (trusted_contact, trusted_select) => (trusted_contact && trusted_select.includes("trusted_address")),
        then: Yup.string().required('This is a required field'),
        }),
    trusted_contact_city      : Yup.string().when(['trusted_contact','trusted_select'], {
        is: (trusted_contact, trusted_select) => (trusted_contact && trusted_select.includes("trusted_address")),
        then: Yup.string().required('This is a required field'),
        }),
    trusted_contact_street      : Yup.string().when(['trusted_contact','trusted_select'], {
    is: (trusted_contact, trusted_select) => (trusted_contact && trusted_select.includes("trusted_address")),
    then: Yup.string().required('This is a required field').matches(
      Regex.pobox,
      'Invalid street address'
    ).max(30, 'Street must be at most 30 characters'),
    }),
    trusted_contact_zipcode     : Yup.string().when(['trusted_contact','trusted_select'], {
      is: (trusted_contact, trusted_select) => (trusted_contact && trusted_select.includes("trusted_address")),
      then: Yup.string().required('This is a required field').matches(Regex.zipCode, 'zip code is not valid'),
    }),

    // joint user
    joint_is_control_person: Yup.string().when('customerType', {
      is: (customerType) => (customerType === "JOINT"),
      then: Yup.string().required('This is a required field'),
    }),
    joint_stock_name: Yup.string().when(['customerType', 'joint_is_control_person'], {
      is: (customerType, joint_is_control_person) => (customerType === "JOINT", joint_is_control_person === "YES"),
      then: Yup.string().required('This is a required field'),
    }),
    joint_upload_file_type     : Yup.string().when(['customerType', 'joint_is_affiliated_exchange_finra'], {
      is: (customerType, joint_is_affiliated_exchange_finra) => (customerType === "JOINT" && joint_is_affiliated_exchange_finra === "YES"),
      then: Yup.string().required('This is a required field'),
    }),
    joint_upload_file     : Yup.string().when(['customerType', 'joint_is_affiliated_exchange_finra', 'joint_upload_file_type'], {
      is: (customerType, joint_is_affiliated_exchange_finra, joint_upload_file_type) => (customerType === "JOINT" && joint_is_affiliated_exchange_finra === "YES" && joint_upload_file_type === "upload_3210" || joint_upload_file_type == 'upload_407'),
      then: Yup.string().required('This is a required field'),
    }),
    joint_is_political_exposed : Yup.string().when(['customerType'], {
      is: (customerType) => (customerType === "JOINT" ),
      then: Yup.string().required('This is a required field'),
    }),
    joint_political_organization: Yup.array()
    .when(['isPoliticallyExposed', 'customerType'], {
      is: (isPoliticallyExposed, customerType) => (customerType === "JOINT" && isPoliticallyExposed === 'YES'),
      then: Yup.array().of(Yup.object().shape({
        relation: Yup.string().required('This is a required field').matches(
          Regex.someCharacter,
          'This is a required field'
        ),
        political_organization: Yup.string().required('This is a required field').matches(
          Regex.someCharacter,
          'This is a required field'
        )
      }))
    }),
    joint_firm_name: Yup.string().when(['joint_is_affiliated_exchange_finra', 'customerType'] , {
      is: (joint_is_affiliated_exchange_finra, customerType) => (customerType === "JOINT" && joint_is_affiliated_exchange_finra === 'YES'),
      then: Yup.string().required('Firm Name is a required field').matches(
        Regex.someCharacter,
        'This is a required field'
      ),
    }),
    is_joint_employed: Yup.string().when(['customerType'] , {
      is: (customerType) => (customerType === "JOINT"),
      then: Yup.string().required('This is a required field'),
    }),
    joint_employer_name: Yup.string().when(['customerType', 'is_joint_employed'] , {
      is: (customerType, is_joint_employed) => (customerType === "JOINT" && is_joint_employed === "Employed"),
      then: Yup.string().required('This is a required field'),
    }),
    joint_occupation: Yup.string().when(['customerType', 'is_joint_employed'] , {
      is: (customerType, is_joint_employed) => (customerType === "JOINT" && is_joint_employed === "Employed"),
      then: Yup.string().required('This is a required field'),
    }),
  });

  return userDetailsvalidationSchema;
}


const mapStateToProps = state => ({
    profile: state.profile.profile,
    updateDisclosure: state.profile.updateDisclosure,
  })

const mapDispatchToProps = {
    fetchProfile,
    cancelAchRelation,
    updateTradAccDisclosure
  }

export default connect(mapStateToProps, mapDispatchToProps)(TradingAccount)

export function PlaidBankSelectModal(props) {

  const [bankData,setBankData] = useState(null)
  const [loading,setLoading] = useState(false)
  const [error,setError] = useState(false)

  const radioChange = (event) => {
    let plaid_account_id = event.target.value;
    let plaid_user_id = event.target.getAttribute("data-user-id");
    setBankData({
      plaid_account_id,plaid_user_id
    })
  }

  const handleSubmit =async ()=>{
    setLoading(true)
    try {
      let resp = await createAchRelation(bankData )
      console.log(resp)
      setLoading(false)
      props.fetchProfile();
      props.hidePlaidBankSelect()
    }catch (e) {
      setLoading(false)
      setError(true)
      console.log(e)
    }
  }

  const onClose = ()=>{
    setBankData(null)
    setError(false)
  }

  const Spinner = () => <div className="w-100">
    <div className="text-secondary text-center">
      <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5"></i>
    </div>
  </div>;

  return <>
    <Modal
      keyboard
      centered
      backdrop
      onClosed={onClose}
      onExit={onClose}
      unmountOnClose={true}
      isOpen={props.bankAccountDataStatus}
      className='add-money-popup-sfm'
      backdropClassName='premium-feature-process-modal-backdrop'
    >
      <ModalBody className='ssf-modal__body'>
        <div className={style.PlaidBankSelectModal}>
          {loading ? <Spinner/> :
          !error ?
            <>
              <p className={style.header}>Select the bank account to link</p>
              <label className="mb-3"><b>Bank Name: </b> {props.institutionName}</label>
              {props.bankAccountDetails && props.bankAccountDetails.length > 0 &&
              props.bankAccountDetails.map((obj, idx) => {
                return (
                  <Row key={idx}>
                    <Col>
                      <div className="element-box bank-details">
                        <Input type="radio" name="account_id" data-user-id={obj.plaid_user_id} value={obj.account_id}
                               id={`account_${obj.account_id}`} onChange={radioChange} />
                        <label htmlFor={`account_${obj.account_id}`} className="label">{obj.name}</label>
                      </div>
                    </Col>
                    <Col className="text-right">
                      <label>{`XXXX${obj.mask}`}</label>
                    </Col>
                  </Row>
                )
              })
              }
              <div className={style.actionButtonGroup}>
                <button className={`${style.confirm} ${style.cancel}`} onClick={props.hidePlaidBankSelect}> Cancel
                </button>
                <button disabled={!bankData} className={style.confirm} onClick={handleSubmit}> Submit
                </button>
              </div>
            </> :
            <div>
              <p className={style.header}>Something went wrong</p>
              <p style={{textAlign:'center'}}>Please reach out to our customer support at tradingaccountsupport@magnifi.com</p>
              <div className={style.actionButtonGroup}>
                <button className={style.confirm} onClick={props.hidePlaidBankSelect}> OK
                </button>
              </div>
            </div>
          }
        </div>
      </ModalBody>
    </Modal>
  </>
}

import React from 'react';
import { Cell, PieChart, Pie } from 'recharts';

import { GaugeColors,GYRGaugeColors } from '../../data/Colors';

const GaugeChart = ({ chart_color,value, min, max, pc, minWidth }) => {
  const width = minWidth || 100;
  const sumValues = 300;
  let colorData
  if(chart_color==='gyr') {
    colorData = GYRGaugeColors.slice(0, 3).map(e => ({ value: 100, color: e }))
  }else if( chart_color==='ryg'){
    colorData= GYRGaugeColors.slice(0, 3).map(e => ({ value: 100, color: e })).reverse()
  }else{
    colorData =GaugeColors.slice(0, 3).map(e => ({ value: 100, color: e }));
  }


  const chartValue = sumValues*(pc !== undefined ? pc : (value-min)/(max - min));

  const arrowData = [
      { value: chartValue },
      { value: 0 },
      { value: sumValues - chartValue }
  ];

  const pieProps = {
      startAngle: 180, endAngle: 0,
      cx: width / 2, cy: width / 2
  };

  const pieRadius = {
    innerRadius: (width / 2) * 0.60,
    outerRadius: (width / 2) * 0.95
  };

  const Arrow = ({ cx, cy, midAngle, outerRadius }) => {
      const RADIAN = Math.PI / 180;
      const sin = Math.sin(-RADIAN * midAngle),
            cos = Math.cos(-RADIAN * midAngle);
      const mx = cx + (outerRadius - width * 0.03) * cos,
            my = cy + (outerRadius - width * 0.03) * sin;
      return (
          <g>
              <circle cx={cx} cy={cy} r={3} fill="#999" stroke="none"/>
              <path d={`M${cx},${cy}L${mx},${my}`} strokeWidth="2" stroke="#999" fill="none" strokeLinecap="round"/>
          </g>
      );
  };

  return (
    <PieChart width={minWidth || width} height={(width / 2) + 5} margin={{top: 0, right: 0, left: 0, bottom: 5}} aspect={1.5}>
        <Pie data={colorData} dataKey="value" fill="#8884d8" isAnimationActive={false}
            { ...pieRadius } { ...pieProps } >
            { colorData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colorData[index].color} />
                )) }
        </Pie>
        <Pie
            stroke="none" fill="none"
            activeIndex={1} activeShape={ Arrow }
            data={ arrowData } dataKey="value"
            outerRadius={ pieRadius.innerRadius }
            { ...pieProps }
        />
    </PieChart>
  );
};

export default GaugeChart;

import React, { useState } from 'react'
import { Checkbox, Tooltip, Radio, Space } from 'antd';
import get from 'lodash/get';
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Button,
  Tooltip as ReactToolTip
 } from 'reactstrap';

import { cn, sponsorLogo } from 'DataSet';
import { numFmt, getImageOrFallback, addEventToAnalytics } from 'Utils';
import { numberCheck } from 'layouts/utils';

import ItemCarousel from 'components/ItemCarousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { TruncatedNameSponsor } from '../Common';
import {
  UnitsValue,
  BuySell,
  InvestMentTextCard,
  numberFormatForAct,
  numberFormat,
  FormatCurrency,
} from './helper';

import Emitter from 'realTimePrice/emitter'
import style from './ActTab.module.scss'

const classNames = require('classnames');
const regexFiveDigit = /^[0-9]*(\.[0-9]{0,5})?$/;
const regexTwoDigit = /^[0-9]*(\.[0-9]{0,2})?$/;

class ItemCard extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      price: '',
      qty: '',
      tvalue: '',
      action: 'buy',
      amtType: 'Units',
      holdingValue: null,
      imageValidPath: false,
      showErrorToolTip: null,
      showErrorInput: false,
      realTimePrices:[],
    };
    this.tvalueRef = React.createRef();
    this.qtyRef = React.createRef();
  }

  componentWillMount () {
    const { card } = this.props;
    if (cn(card, 'Sponsor')) {
      getImageOrFallback(
        sponsorLogo(cn(card, 'Sponsor'))
      ).then(result => {
        if (result) {
          this.setState({ imageValidPath: true });
        } else {
          this.setState({ imageValidPath: false });
        }
      });
    }
  }

  componentDidMount() {
    Emitter.on('GET_LIVE_PRICES',  (price) => {
      let index;
      let priceClass = '';
      let realTimePrices = this.state.realTimePrices
      if (realTimePrices.length) {
        index = realTimePrices.findIndex(x => x.ticker === price.symbol);
        if (index > -1) {

          if (price.price > realTimePrices[index].price) {
            priceClass = 'priceUp';
          } else if (price.price < realTimePrices[index].price) {
            priceClass = 'priceDown';
          } else {
            priceClass = realTimePrices[index].priceClass;
          }

          realTimePrices.splice(index, 1);
        }
        realTimePrices.push({'price': price.price, 'ticker': price.symbol, 'priceClass': priceClass})
      } else {
        realTimePrices.push({'price': price.price, 'ticker': price.symbol, 'priceClass': priceClass})
      }
      this.setState({ realTimePrices })
    });
  }

  unselectCard = (item) => {
    const { removeFund } = this.props;
    if (removeFund) {
      removeFund(item)
    }
  }

  setAction = (action) => {
    if (addEventToAnalytics) {
      addEventToAnalytics(
        'Quick Trade Type',
        'Quick Trade Type',
        'QUICK_TRADE_Type',
        { tradeType: action },
        true
      );
    }
    this.setState({
      action,
      qty: '',
      tvalue: '',
    }, () => {
      const { card, getOrderDetails } = this.props;
      if (getOrderDetails) {
        const { realTimePrices, amtType, qty, tvalue } = this.state;
        let navPrice = parseFloat(card.price);
        let realPriceArr = realTimePrices.filter(e => e.ticker === card.ticker);
        let realPrice = ((realPriceArr.length > 0) ? realPriceArr[0].price.toFixed(2) : (isFinite(navPrice) ? navPrice.toFixed(2) : '--' ))
        realPrice = realPrice !== '--' ? realPrice : card.price;
        let value = '';
        getOrderDetails({
          cusip: '',
          value: 0,
          quantity: '',
          option: amtType,
          ticker: card.ticker,
          vehicle: card.vehicle,
          sell_all: (action === 'sell_all'),
          side: (action === 'sell_all') ? 'SELL' : action.toUpperCase(),
          analysisPrice: (amtType === 'Units') ? numberCheck(realPrice, 2) : 1,
        });
      }
    });
  }

  tvalueHandler = (event) => {
    if (event && event.target && regexTwoDigit.test(event.target.value)) {
      const { card, getOrderDetails } = this.props;
      const { realTimePrices, action } = this.state;
      let value = event.target.value;
      let navPrice = parseFloat(card.price);
      let realPriceArr = realTimePrices.filter(e => e.ticker === card.ticker);
      let realPrice = ((realPriceArr.length > 0) ? realPriceArr[0].price.toFixed(2) : (isFinite(navPrice) ? navPrice.toFixed(2) : '--' ))
      realPrice = realPrice !== '--' ? realPrice : card.price;
      let units = value/realPrice;
      this.setState({ amtType: 'Value', tvalue: value, qty: units }, () => {
        if (getOrderDetails) {
          getOrderDetails({
            value,
            cusip: '',
            option: 'Value',
            sell_all: (action === 'sell_all'),
            quantity: numberCheck(units, 5),
            ticker: card.ticker,
            vehicle: card.vehicle,
            analysisPrice: 1,
            side: (action === 'sell_all') ? 'SELL' : action.toUpperCase(),
          });
        }
      });
    }
  }

  qtyHandler = (event) => {
    if (event && event.target && regexFiveDigit.test(event.target.value)) {
      const { card, getOrderDetails } = this.props;
      const { realTimePrices, action } = this.state;
      let query = event.target.value;
      const qty = query.includes('.') || query.length < 2   ?  query : query.replace(/^0+/, '');
      let navPrice = parseFloat(card.price);
      let realPriceArr = realTimePrices.filter(e => e.ticker === card.ticker);
      let realPrice = ((realPriceArr.length > 0) ? realPriceArr[0].price.toFixed(2) : (isFinite(navPrice) ? navPrice.toFixed(2) : '--' ))
      realPrice = realPrice !== '--' ? realPrice : card.price;
      let tvalue = qty*realPrice;
      this.setState({ amtType: 'Units', qty, tvalue }, () => {
        if (getOrderDetails) {
          getOrderDetails({
            cusip: '',
            value: tvalue,
            quantity: numberCheck(qty, 5),
            option: 'Units',
            sell_all: (action === 'sell_all'),
            ticker: card.ticker,
            vehicle: card.vehicle,
            analysisPrice: numberCheck(realPrice, 2),
            side: (action === 'sell_all') ? 'SELL' : action.toUpperCase(),
          });
        }
      });
    }
  }

  selectFundToExecute = (event) => {
    // const { selectFundToExecute, card } = this.props;
    // if (selectFundToExecute) {
    //   selectFundToExecute(event.target.checked, card.ticker)
    // }
  }

  amtHandler = (amtType) => {
    if (addEventToAnalytics) {
      addEventToAnalytics(
        'Quick Trade Input Field Type',
        'Quick Trade Input Field Type',
        'QUICK_TRADE_INPUT_FIELD_TYPE',
        { inputType: amtType },
        true
      );
    }
    this.setState({
      amtType,
      qty: '',
      tvalue: '',
    });
  }

  handleInputBlur = (e, realPrice) => {
    if (addEventToAnalytics) {
      addEventToAnalytics(
        'Quick Trade Input Field',
        'Quick Trade Input Field',
        'QUICK_TRADE_INPUT_FIELD',
        {},
        true
      );
    }
    // console.log('realPrice ===>', realPrice);
    if (e && e.target && typeof realPrice !== 'undefined') {
      const value = e.target.value;
      const { card, getMyHoldingData } = this.props;
      const { tvalue, action, amtType } = this.state;
      let showErrorInput = false;
      if (value && getMyHoldingData) {
        let toolTipError = null;
        if (action === 'buy') {
          if (tvalue < card.minInvestment) {
            toolTipError = `The value must be greater than or equal to ${numberFormatForAct(card.minInvestment, 2)}`;
            showErrorInput = true;
          }
        } else {
          // validation check for 'sell' or 'sell_all';
          let { holdingValue = null } = getMyHoldingData({ card });
          if (holdingValue !== null && tvalue > holdingValue) {
            if(amtType === 'Units') {
              toolTipError = 'The quantity must be less than or equal to current holding';
              showErrorInput = true;
            } else {
              toolTipError = 'The value must be less than or equal to current holding';
              showErrorInput = true;
            }
          }
        }
        this.setState({
          showErrorToolTip: toolTipError,
          showErrorInput
        }, () => {
          const { invalidHandler } = this.props;
          if (invalidHandler) {
            invalidHandler(this.state.showErrorInput);
          }
          setTimeout(()=> {
            this.setState({
              showErrorToolTip: null,
            });
          }, 3000);
        });
      } else {
        this.setState({
          showErrorInput: false,
          showErrorToolTip: null,
        }, () => {
          const { invalidHandler } = this.props;
          if (invalidHandler) {
            invalidHandler(this.state.showErrorInput);
          }
        });
      }
    }
  }

  handleInputFocus = () => {
    this.setState({
      showErrorInput: false,
      showErrorToolTip: null,
    });
  }

  toggleAddMoneyPopup = () => {
    const { toggleAddMoneyPopup } = this.props;
    if (toggleAddMoneyPopup) {
      toggleAddMoneyPopup()
    }
  }

  getMyHoldingData = (realPrice) => {
    const { myportfolio, card } = this.props;
    let sharesValue = null, holdingValue = null;
    if (typeof realPrice !== 'undefined' && typeof card !== 'undefined') {
      const basketFund = card.vehicle === 'BASKET';
      let getMyHoldingIFExists = (myportfolio && myportfolio.length > 0) ? myportfolio.find(v => v.script === card.ticker) : null;
      const _minInvestment = numberCheck(card.minInvestment, 5);
      if (getMyHoldingIFExists && typeof getMyHoldingIFExists.market_value !== 'undefined') {
        if (basketFund) {
          holdingValue = numberCheck(getMyHoldingIFExists.market_value, 5);
        } else if (typeof getMyHoldingIFExists.shares !== 'undefined' && numberCheck(getMyHoldingIFExists.shares, 5)) {
          sharesValue = numberCheck(getMyHoldingIFExists.shares, 5);
          holdingValue = sharesValue*realPrice;
        }
        return {
          ...getMyHoldingIFExists,
          sharesValue,
          holdingValue,
        }
      } else {
        return { sharesValue, holdingValue };
      }
    } else {
      return { sharesValue, holdingValue };
    }
  }

  redirectToTrading = () => {
    const { quickTradeToSFM } = this.props;
    if (quickTradeToSFM) {
      quickTradeToSFM('verification-pending', '/settings/tradingAccount');
    }
  }

  render() {
    const { imageValidPath, realTimePrices, showErrorToolTip, showErrorInput, amtType, action } = this.state;
    const { card, isBrokerRegistered, idx, avaBalance, showBalance, broker, myportfolio, getMyHoldingData, verificationPending, noBankAccount } = this.props;

    const prec = 2;
    const isSelling = action === "sell" || action === "sell_all";
    const basketFund = card.vehicle === "BASKET";

    let navPrice = parseFloat(card.price);
    let realPriceArr = realTimePrices.filter(e => e.ticker === card.ticker);
    let priceClass = ((realPriceArr.length > 0) ? realPriceArr[0].priceClass : '');
    let realPrice = ((realPriceArr.length > 0) ? realPriceArr[0].price.toFixed(prec) : (isFinite(navPrice) ? navPrice.toFixed(prec) : '--' ));
    const _minInvestment = numberCheck(card.minInvestment, 5);
    // hack to pass RealTimePrice in ItemList.js
    card.realPrice = numberCheck(realPrice, 5) || card.price;

    let { sharesValue = null, holdingValue = null } = typeof getMyHoldingData !== 'undefined' && getMyHoldingData({ card });
    const currentHoldings = `${numberFormatForAct(holdingValue, 2)}`;

    return (
      <Card className={classNames("shadow-sm mb-2 border-0")} style={{ minHeight: 113 }}>
        <CardBody className="p-0 ">
          <div className="no-gutters display-flex-row">
            <div className={`${isBrokerRegistered && !verificationPending && !noBankAccount ? 'col-40' : 'col-50'} padding-5-right-1`}>
              <div className="bg-ssf-gray-l5 p-1 h-100 d-flex align-items-center pos-rel" style={{ minHeight: 113 }}>
                <div className="d-flex align-items-center h-100 close-wrapper pl-1">
                  <Checkbox
                    inline
                    className='custom-ant-checkbox'
                    type='checkbox'
                    title='Select'
                    id={card.ticker}
                    checked={card._checkedToExecute}
                    onChange={(e) => this.selectFundToExecute(e, card)}
                  />
                </div>
                <div className="pr-1 px-2 text-center">
                  <h5 className="mb-0 text-blue text-gray-5 ticker-size ticker-price">{card.ticker}</h5>
                  <h3 className={`mb-0 text-info`} style={{minWidth: '5rem'}}>
                    <span className={`ticker-price text-gray-5 ${priceClass}`}>$</span>
                    <span className={`ticker-price actual-price ${priceClass}`}>{realPrice}</span>
                  </h3>
                </div>
                <TruncatedNameSponsor
                  style={{ flex: 1, maxWidth: 125 }}
                  showSponsorLogo={card.isSponsored === 'true'}
                  imageValidPath={imageValidPath}
                  name={card.name}
                  sponsor={card.sponsor}
                  lines={1}
                />
                <div className="d-flex align-items-start h-100 close-wrapper">
                  <div className="close-icon-box pl-0">
                    <i className="fal fa-times-circle" onClick={() => this.unselectCard(card)}></i>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${isBrokerRegistered && !verificationPending && !noBankAccount ? 'col-20' : 'col-25'} padding-5-right-1`}>
              <div className="bg-ssf-gray-l9 px-2 py-1 h-100 d-flex flex-column align-items-center justify-content-center">
                <UnitsValue
                  funds={card}
                  value={amtType}
                  showOnlyValue={basketFund}
                  amtHandler={this.amtHandler}
                />
                <div className=" d-flex flex-column">
                  { amtType === 'Units' && (
                    <React.Fragment>
                      <FormGroup className="m-auto" style={{maxWidth: 100}}>
                        <Input
                          name="qty"
                          id={"orderQty"+idx}
                          ref={this.qtyRef}
                          bsSize="sm"
                          placeholder="Units"
                          min="0"
                          className="text-center"
                          value={this.state.qty}
                          onChange={this.qtyHandler}
                          onBlur={(e) => this.handleInputBlur(e, realPrice)}
                          onFocus={this.handleInputFocus}
                          invalid={showErrorInput}
                          readOnly={action === "sell_all"}
                          />
                        {this.qtyRef && this.qtyRef.current && (
                          <ReactToolTip
                            className={`col-grey-bottom`}
                            placement="top"
                            target={"orderQty"+idx}
                            isOpen={!!showErrorToolTip}
                          >
                            {showErrorToolTip}
                          </ReactToolTip>
                        )}
                      </FormGroup>
                      <div className="text-center h5 lh-125 my-0">
                        <small><span className="text-gray-5">Est. Value: </span>${numFmt(this.state.tvalue, 2)}</small>
                      </div>
                    </React.Fragment>
                  )}
                  { amtType === 'Value' && (
                    <React.Fragment>
                      <FormGroup className="m-auto" style={{maxWidth: 100}}>
                        <Input
                          ref={this.tvalueRef}
                          name="tvalue"
                          id={"orderTvalue"+idx}
                          bsSize="sm"
                          placeholder="Value"
                          min="0"
                          className="text-center"
                          value={this.state.tvalue}
                          onChange={this.tvalueHandler}
                          onBlur={(e) => this.handleInputBlur(e, realPrice)}
                          onFocus={this.handleInputFocus}
                          invalid={showErrorInput}
                          readOnly={action === "sell_all"}
                        />
                      {this.tvalueRef && this.tvalueRef.current && (
                        <ReactToolTip
                          className={`col-grey-bottom`}
                          placement="top"
                          target={"orderTvalue"+idx}
                          isOpen={!!showErrorToolTip}
                        >
                         {showErrorToolTip}
                        </ReactToolTip>
                      )}
                      </FormGroup>
                      <div className="text-center h5 lh-125 my-0">
                        <small><span className="text-gray-5">Est. Units: </span>{numFmt(this.state.qty|| 0, 5)}</small>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className={`${isBrokerRegistered && !verificationPending && !noBankAccount ? 'col-20' : 'col-25'} padding-5-right-1`}>
              <div className="bg-ssf-gray-l9 px-2 py-1 h-100 d-flex align-items-center justify-content-center" style={{minHeight: '75px'}}>
                <div className="form-row w-100">
                  <div className="pr-1 text-center mx-auto">
                    <InvestMentTextCard
                      prec={prec}
                      isSelling={isSelling}
                      basketFund={basketFund}
                      sharesValue={sharesValue}
                      holdingValue={holdingValue}
                      minInvestment={_minInvestment}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`${isBrokerRegistered && !verificationPending && !noBankAccount ? 'col-20 padding-5-right-1' : 'col-25 padding-5'}`}>
              <div className="bg-ssf-gray-l9 px-2 py-1 h-100 d-flex flex-column align-items-center justify-content-center">
                <BuySell
                  sellDisable={(holdingValue === null)}
                  value={this.state.action}
                  setAction={this.setAction}
                />
              </div>
            </div>
            {(isBrokerRegistered && !verificationPending && !noBankAccount) && (
              <div className="col-20 padding-5 add-broker-wrapper ml-0">
                <div className="bg-ssf-gray-l9 px-2 py-1 h-100 d-flex align-items-center justify-content-center" style={{ minHeight: '75px' }}>
                  {(broker.allowToTrade) && (
                    <div className="sfm-act-tab-acc-balance-wrapper">
                      <i className="far fa-wallet"></i>
                      <span className="wallet-text">{FormatCurrency(avaBalance, prec)}</span>
                      <span id={`sfm-act-tab-${card.ticker}`}>
                        <Tooltip
                          placement="bottom"
                          color="#707070"
                          overlayClassName="magnifi-tooltip-wrapper"
                          title={`Add Balance in ${broker.name} Account`}
                          getPopupContainer={() => document.getElementById(`sfm-act-tab-${card.ticker}`)}
                        >
                          <i className="fal fa-plus-circle" onClick={this.toggleAddMoneyPopup}></i>
                        </Tooltip>
                      </span>
                    </div>
                  )}
                  {(broker.status === 'Under Verification' || broker.status === 'Suspended') && (
                    <div className="sfm-act-tab-acc-balance-wrapper">
                      <div onClick={this.redirectToTrading} style={{ color: '#55a8e8', cursor: 'pointer' }}>Verification Pending</div>
                    </div>
                  )}
                  <div className="magnifi-broker">
                    <Button className="add-broker-button" style={{ pointerEvents: 'none' }}>
                      {broker.name}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default ItemCard;

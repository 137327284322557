import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

class MarketPlace extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="onBoardScreenWrapper">
          MarketPlace
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ portfolio, investment, auth, profile }) => ({
  portfolio, investment, auth, profile,
})

const mapDispatchToProps = {

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketPlace);

import React from 'react';
import { ModalFooter, Spinner, Modal, ModalBody, ButtonGroup } from 'reactstrap';

import { trackFunds } from 'apis/funds'

import { cn } from 'DataSet';
import { getNest } from 'Utils';
import { isNull } from 'util';
import { isEmpty } from 'lodash/lang'

import ConfirmTab from '../ConfirmTab';
import ItemList from './ItemList';
import Footer from './Footer';
import { Loader } from '../Loader';

let trades = []

export default class ActTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stage: 'Act', // this.props.stage || 'act',
      loading: false
    }
  }

  actionHandler = (type, item) => {
    const { actionHandler } = this.props;
    if (actionHandler && type) { // type: ['Confirm', 'Act'] which tab to open
      this.setState({
        stage: type,
      });
      actionHandler(type, item);
    }
  }

  render() {
    let { stage } = this.state;
    return (
      (stage === 'Act') ? <Act {...this.props} actionHandler={this.actionHandler} /> : (stage === 'Confirm') ? <ConfirmTab {...this.props} actionHandler={this.actionHandler} /> : null
    )
  }
}

class Act extends React.Component {
  state = { activateExecuteButton: false, activate2: false, doRegister: false, isGreaterThanMinValue: false, noBankAccountPopup: false }

  componentDidMount() {
    let {profile, activeTab} = this.props
      if(!isEmpty(profile.apex)){
        let apex_Status = profile.apex.account[0].status
        let apex_active = profile.apex.account[0].is_active
        // if(apex_Status!=='COMPLETE' || !apex_active){
        // }
        if(activeTab === 'Act' && apex_Status==='COMPLETE' && apex_active && (!profile.apex.bank || (profile.apex.bank && profile.apex.bank.length === 0))){
          this.setState({ noBankAccountPopup: true })
        }
    }
    this.props.actionHandler('Act', 'all');
  }

  buyHandler = () => {
    this.props.buyHandler('all');
  }

  activateHandler = (flag) => {
    if (flag !== this.state.activateExecuteButton)
      this.setState({ activateExecuteButton: flag });
  }

  activateHandler2 = (flag) => {
    if (flag !== this.state.activate2)
      this.setState({ activate2: flag });
  }

  handleGreaterValue = (val) => {
    if (val !== this.state.isGreaterThanMinValue)
    this.setState({ isGreaterThanMinValue: val });
  }

  doRegisterHandler = (flag) => {
    if (flag !== this.state.doRegister)
      this.setState({ doRegister: flag });
  }

  trackHandler = () => {
    this.trackFundData();
    this.props.clearAllHandler();
    this.props.confirmHandler(true);
  }

  trackFundData = async () => {
    const { items } = this.props;
    let funds = [];
    items.forEach(e => {
      let qty = getNest(['_order', 'state', 'amtType'], e) === 'Value'
                ? +getNest(['_order', 'qty'], e)
                : +getNest(['_order', 'state', 'qty'], e);
      if(qty <= 0 || isNaN(qty) || isNull(qty)) return;

      let out = {
        ticker: cn(e, 'Ticker'),
        price: parseFloat(cn(e, 'Nav Price').toFixed(2)),
        quantity: qty,
        account_number: 'Dummy',
        transaction_type: 'TRACK',
      }

      let flag = e._brokerFlags && e._brokerFlags.find(f => f.sel);
      let price = getNest(['_order', 'price'], e);

      if (flag) out.broker = flag.id;
      if (price) out.price = +price;
      funds.push(out);
    })
    if (funds.length <= 0)
      return;

    let postData = { funds };
    let resp = await trackFunds(postData)
    return resp;
  }

  executeHandler = () => {
    // console.log('executing')
    let isOtherThanMagnifiBroker = false
    trades = []
    this.props.items.forEach(item=>{
      item._brokerFlags.forEach(broker=>{
        if (broker.id !== 14 && broker.sel === true) {
          isOtherThanMagnifiBroker = true
        }
      })
      // console.log(item.ticker, item._checkedToExecute);
      let state = item._order.state;
      if (((state.action === "sell_all") || (item._order.qty !== '' && typeof item._order.qty !== 'undefined')) && item._checkedToExecute) {
        let ticker = {
          cusip: '',
          vehicle: item.vehicle || item.type,
          ticker: item.ticker,
          side: state.action === "sell_all" ? "SELL" : state.action.toUpperCase(),
          quantity: item._order.qty,
          analysisPrice: state.amtType == 'Value' ? 1 : item.nav,
          vehicle: item.vehicle || item.type,
          option: state.amtType,
          value: item._order.tvalue,
          sell_all: state.action === "sell_all"
        }
        trades.push(ticker)
      }
    })
    // console.log(isOtherThanMagnifiBroker,trades)
    if (trades.length) {
      let payload = {
        data: {
          trades
        }
      }
      //sfm-modal
      const { executeHandler } = this.props;
      if (executeHandler) {
        executeHandler(payload)
      }
    }
  }

  render() {
    const { loading, isGreaterThanMinValue } = this.state;
    const { importedAccounts, fetchFundsLoading, enableOnlyActTab, magnifiTickers, fetchingApexAmountBalance } = this.props;
    // console.log('ActTab')
    return (
      <div className="sfm-act-tab-container">

        <Modal
          isOpen={this.state.noBankAccountPopup}
          toggle={() => this.setState({ noBankAccountPopup: !this.state.noBankAccountPopup })}
          centered
          keyboard={true}
          backdrop
          className="premium-msg-modal-sm-light"
          backdropClassName="premium-feature-process-modal-backdrop"
        >
          <ModalBody className="ssf-modal__body text_21_rem">
            <div className="premium-feature-process-modal-container delete-confirmation-modal">
              <div className="header-wrapper">
                <h1 className="text-align-center pb-2">Link your Bank Account to Start Investing</h1>
              </div>
              <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => this.setState({ noBankAccountPopup: !this.state.noBankAccountPopup })}></i>
              <div className="confirmation-btn">
                <ButtonGroup
                  className="justify-content-center w-100 mb-2"
                >
                  <button
                    onClick={() => {
                      let email = localStorage.getItem('email') || window.sessionStorage.getItem('email') || "";
                      // addEventToAnalytics('Link Bank Account Now', 'Link Bank Account Now', 'LINK_BANK_ACCOUNT_NOW', { email: email }, true);
                      this.props.history.push({ pathname: "/settings/tradingAccount", linkaccount: true })
                    }}
                    className="ssf-btn-primary btn-link btn_link_now btn btn-secondary"
                  >
                    LINK ACCOUNT
                  </button>
                </ButtonGroup>
              </div>
            </div>
          </ModalBody>
        </Modal>


        {(fetchFundsLoading || fetchingApexAmountBalance) && <Loader loading />}
        {!fetchFundsLoading && <>
          <div className="modal-body-ssf p-2 media-none">
            <ItemList
              {...this.props}
              activateHandler={this.activateHandler}
              activateHandler2={this.activateHandler2}
              handleGreaterValue={this.handleGreaterValue}
              doRegisterHandler={this.doRegisterHandler}
            />
          </div>
          <div className="d-md-none modal-body-ssf d-flex align-items-center flex-column justify-content-center">
            <p className="text-center px-2 mb-2">Please visit magnifi.com on a desktop to access features for further analysis and trading</p>
          </div>
          <ModalFooter className="py-2 media-none sfm-modal-footer">
            <Footer
              {...this.props}
              executeHandler={this.executeHandler}
              buyHandler={this.buyHandler}
              trackHandler={this.trackHandler}
              activateExecuteButton={this.state.activateExecuteButton}
              activate2={this.state.activate2}
              doRegister={this.state.doRegister}
              isGreaterThanMinValue={isGreaterThanMinValue}
            />
          </ModalFooter>
        </>}
      </div>
    )
  }
}

import { createSelector } from 'reselect'
import { ProfitLossColors, RegionColors, SectorColors } from 'data/Colors'
import { cn, SectorsAttrs } from '../DataSet';
import { uniqValues } from 'Utils'

export const getPortfolioAccountStats = investment => typeof investment.caching.portfolioFunds !== 'undefined' ? investment.caching.portfolioFunds : investment.portfolioFunds;
export const getInvestmentFunds = state => (state.portfolio.enhanceCTA === 'Enhance' && typeof state.investment.caching.funds !== 'undefined') ? state.investment.caching.funds : state.investment.funds;
export const getPortfolioFunds = state => (state.portfolio.enhanceCTA === 'Enhance' && typeof state.investment.caching.portfolioFunds !== 'undefined') ? state.investment.caching.portfolioFunds : state.investment.portfolioFunds;
export const getMarketFunds = state => typeof state.investment.caching.marketFunds !== 'undefined' ? state.investment.caching.marketFunds : state.investment.marketFunds;
export const getInvestmentBrokerCb = state => typeof state.investment.caching.brokerCb !== 'undefined' ? state.investment.caching.brokerCb : state.investment.brokerCb;
export const getInvestmentPositions = state => typeof state.investment.caching.positions !== 'undefined' ? state.investment.caching.positions : state.investment.positions;
export const getInvestmentRetData = state => (state.portfolio.enhanceCTA === 'Enhance' && typeof state.investment.caching.retData !== 'undefined') ? state.investment.caching.retData : state.investment.retData;
export const getInvestmentActiveAccount = state => (state.portfolio.enhanceCTA === 'Enhance' && typeof state.investment.caching.activeAccount !== 'undefined') ? state.investment.caching.activeAccount : state.investment.activeAccount;
export const getInvestmentActiveFund = state => (state.portfolio.enhanceCTA === 'Enhance' && typeof state.investment.caching.activeFund !== 'undefined') ? state.investment.caching.activeFund : state.investment.activeFund;
export const getInvestmentValid = state => (state.portfolio.enhanceCTA === 'Enhance' && typeof state.investment.caching.valid !== 'undefined') ? state.investment.caching.valid : state.investment.valid;
export const getInvestmentUploadPartial = state => typeof state.investment.caching.uploadFilePartial !== 'undefined' ? state.investment.caching.uploadFilePartial : state.investment.uploadFilePartial;
export const getportfolioOldTickersBeforeUpload = state => typeof state.investment.caching.portfolioOldTickersBeforeUpload !== 'undefined' ? state.investment.caching.portfolioOldTickersBeforeUpload : state.investment.portfolioOldTickersBeforeUpload;
export const getAllInvestmentState = state => state.investment;
export const getInvestmentMarketTickers = state => typeof state.investment.caching.marketTickers !== 'undefined' ? state.investment.caching.marketTickers : state.investment.marketTickers;
export const getFileName = state => typeof state.investment.caching.filename !== 'undefined' ? state.investment.caching.filename : state.investment.filename;
export const getPortfolioSelectedBenchmark= state => state.portfolio.selectedBenchmark;

const formatTree = data => {
  if (!data) return data;
  return data.map(e => {
    let out = { name: e.n, size: e.v }
    if (e.sub) out.children = formatTree(e.sub);
    return out;
  })
}

const sum = (arr, key) => {
  return arr.reduce((a, b) => a + (b[key] || 0), 0);
}


export const getInvestmentAccountStats = createSelector(
  [ getInvestmentValid, getInvestmentBrokerCb, getInvestmentActiveAccount ],
  ( valid, data, account ) => {
    let out = { portfolioValue: 0, fundBalance: 0, cashBalance: 0 };
    if (!valid) return out;

    let _data = account === 'All' ? data : data.filter(e => e.broker__name === account);
    for (const e of _data) {
      out.portfolioValue += +e.net_account_value;
      out.cashBalance += +e.available_cash;
    }
    out.fundBalance = out.portfolioValue - out.cashBalance;

    return out;
  }
)

export const getInvestmentFundStats = createSelector(
  [ getInvestmentValid, getInvestmentFunds, getInvestmentActiveAccount ],
  ( valid, data, account ) => {
    let out = { funds: [] };
    if (!valid) return out;

    let _data = account === 'All' ? data : data.filter(e => e.broker === account);
    // sort descending, simple string sort works as date format is "2019-02-20T14:14:22.320Z"
    out.funds = _data.filter(e => e.transaction_type !== 'TRACK').sort(sortFn('transaction_date'));
    return out;
  }
)


export const getInvestmentPositionStats = createSelector(
  [ getInvestmentValid, getInvestmentPositions, getInvestmentActiveAccount ],
  ( valid, data, account ) => {
    let out = {
      gainToday: 0, gainTotal: 0,
      priceSectors: {}, priceRegions: {}, plSectors : {}, plRegions: {},
      plSectors2 : [], plRegions2: [], tickers: [],
    };
    if (!valid) return out;

    let _data = account === 'All' ? data : data.filter(e => e.broker === account);

    let fundsH = {}, totalMarketValue = 0, totalProfitLoss = 0;
    let allRegionData = [], uniqueRegions = [],
        allSectorData = [], uniqueSectors = [];

    for (const e of _data) {
      if (fundsH[e.symbol] === undefined) fundsH[e.symbol] = { ...e, _price: 0, _realTimePrice:0, quantity: 0 };
      totalMarketValue += (e.last_price !== null) ? e.quantity * parseFloat(e.last_price) : 0;
      const mktValue = (e.last_price !== null) ? e.quantity * parseFloat(e.last_price) : 0;
      totalProfitLoss += parseFloat(e.last_price) - parseFloat(e.cost_basis);

      const price = +e.last_price * +e.quantity,
            gain = +e.total_gain_loss_absolute;
      fundsH[e.symbol]._price += price;
      fundsH[e.symbol]._realTimePrice += price;
      fundsH[e.symbol].quantity += parseInt(e.quantity);

      out.gainToday += +e.today_gain_loss_absolute;
      out.gainTotal += gain;

      // if (out.priceSectors[e.sector] === undefined) out.priceSectors[e.sector] = 0;
      // if (out.priceRegions[e.region] === undefined) out.priceRegions[e.region] = 0;
      if (out.plSectors[e.sector] === undefined) out.plSectors[e.sector] = 0;
      if (out.plRegions[e.region] === undefined) out.plRegions[e.region] = 0;

      // out.priceSectors[e.sector] += price;
      // out.priceRegions[e.region] += price;
      out.plSectors[e.sector] += gain;
      out.plRegions[e.region] += gain;


      //For Sectors
      if (e.sector && Object.keys(e.sector).length > 0) {
        let sec = SectorsAttrs.map(v => ({ name: v, value: cn(e.sector, v) ? parseFloat((cn(e.sector, v)* 1).toFixed(1)) : 0 }));
        // allSectorData.push(sec);
        sec = sec.map((item) => {
          (!uniqueSectors.includes(item.name)) && uniqueSectors.push(item.name);
          const obj = {
            name: item.name,
            value: (item.value * mktValue),
            pl: (parseFloat(e.last_price) - parseFloat(e.cost_basis)) * (item.value / 100),
          }
          allSectorData.push(obj);
        })
      }

      // Regions
      if (e.region && Object.keys(e.region).length > 0) {
        let reg = formatTree(cn(e, 'Region'));
        reg = reg.map((item) => {
          (!uniqueRegions.includes(item.name)) && uniqueRegions.push(item.name);
          const region = {
            name: item.name,
            size: (sum(item.children, 'size') * mktValue),
            pl: (parseFloat(e.last_price) - parseFloat(e.cost_basis)) * (sum(item.children, 'size') / 100),
          }
          return region;
        })
        allRegionData.push(reg)
      }

    }

    out.funds = Object.values(fundsH).sort(sortFn('_price'));

    let priceSectors = [];
    if (uniqueSectors.length > 0) {
      priceSectors = uniqueSectors.reduce((acc, item, i) => {
        const value = allSectorData.reduce((sum, data) => {
          if (data.name === item) sum['sum'] = sum['sum'] + data.value;
          if (data.name === item) sum['plSum'] = sum['plSum'] + data.pl;
          return sum;
        }, {
          sum: 0,
          plSum: 0,
        });
        const obj = {
          name: item,
          value: (value.sum / totalMarketValue),
          color: SectorColors[i%SectorColors.length],
          pl: value.plSum,
        }
        acc.push(obj);
        return acc;
      }, [])
    } else out.priceSectors = [];

    out.allocationPriceSectors = priceSectors;

    if (uniqueSectors.length > 0) out.priceSectors = priceSectors.sort((a, b) => b.pl - a.pl)
                                                                  .filter((item, index) => index <= 4 || index >= (priceSectors.length - 5));

    if(uniqueRegions.length > 0) {
      out.priceRegions = uniqueRegions.reduce((acc, item) => {
        const size = allRegionData.reduce((sum, data) => {
          const index = data.findIndex(x => x.name === item)
          if(index >= 0) sum['sum'] = sum['sum'] + data[index].size;
          if(index >= 0) sum['plSum'] = sum['plSum'] + data[index].pl;
          return sum;
        }, {
          sum: 0,
          plSum: 0,
        });
        const abc = {
          name: item,
          value: (size.sum / totalMarketValue) < 0 ? 0 : (size.sum / totalMarketValue),
          color: RegionColors[item],
          pl: size.plSum,
        }
        acc.push(abc)
        return acc;
      }, []);
    } else out.priceRegions = [];

    const opts = ['plSectors', 'plRegions'];
    opts.forEach(k => {
      let _data = out[k];
      let data = Object.keys(_data).map((e, i) => ({ name: e, value: _data[e], color: ProfitLossColors[_data[e] < 0 ? 1 : 0] }));
      // take most significant 6
      out[k+'2'] = data.sort((a, b) => Math.abs(b.value) - Math.abs(a.value)).slice(0, 6).sort((a, b) => a.value - b.value);
    })

    // out.tickers = uniqValues(out.funds, 'symbol').join(',');
    out.tickers = out.funds.map(item => {
      return { ticker: item.symbol, shares: parseFloat(item.quantity) }
    })

    return out;
  }
)


export const getInvestmentActiveFundStats = createSelector(
  [ getInvestmentValid, getInvestmentRetData, getInvestmentActiveFund ],
  ( valid, data, fundId ) => {
    if (!valid || !fundId) return {};

    return { fund: data.find(e => e.ticker === fundId) };
  }
)

export const getInvestmentWatchlistStats = createSelector(
  [ getInvestmentValid, getInvestmentFunds ],
  ( valid, data ) => {
    let out = {
      funds: [], positions: [],
      priceSectors: [], priceRegions: [],
      portfolioValue: 0, fundBalance: 0, cashBalance: 0, tickers: [],
    };
    if (!valid) return out;

    out.funds = (data || []).filter(e => e.transaction_type === 'TRACK').sort(sortFn('transaction_date'));


  // New Region and Sector api

    let fundsH = {}, totalMarketValue = 0, totalProfitLoss = 0;
    let allRegionData = [], uniqueRegions = [],
        allSectorData = [], uniqueSectors = [];

    for (const e of out.funds) {
      if (fundsH[e.script] === undefined) fundsH[e.script] = { ...e, _price: 0, _realTimePrice:0, quantity:0, symbol: e.script };
      totalMarketValue += e.market_value;
      totalProfitLoss += e.market_value - e.invested_amount;

      const price = +e.price * + e.quantity;
      fundsH[e.script]._price += price;
      fundsH[e.script]._realTimePrice += price;
      fundsH[e.script].quantity += e.quantity;

      // if (out.priceSectors[e.sector] === undefined) out.priceSectors[e.sector] = 0;
      // if (out.priceRegions[e.region] === undefined) out.priceRegions[e.region] = 0;

      // out.priceSectors[e.sector] += price;
      // out.priceRegions[e.region] += price;
      out.portfolioValue += price;


      // Sectors
      if (e.sector && Object.keys(e.sector).length > 0) {
        let sec = SectorsAttrs.map(v => ({ name: v, value: cn(e.sector, v) ? parseFloat((cn(e.sector, v)* 1).toFixed(1)) : 0 }));
        // allSectorData.push(sec);
        sec = sec.map((item) => {

          (!uniqueSectors.includes(item.name)) && uniqueSectors.push(item.name);
          const obj = {
            name: item.name,
            value: (item.value * e.market_value),
            pl: (e.market_value - e.invested_amount) * (item.value / 100),
          }
          allSectorData.push(obj);
        })
      }


      // Regions
      if (e.region && Object.keys(e.region).length > 0) {
        let reg = formatTree(cn(e, 'Region'));
        reg = reg.map((item) => {
          (!uniqueRegions.includes(item.name)) && uniqueRegions.push(item.name);
          const region = {
            name: item.name,
            size: (sum(item.children, 'size') * e.market_value),
            pl: (e.market_value - e.invested_amount) * (sum(item.children, 'size') / 100),
          }
          return region;
        })
        allRegionData.push(reg)
      }
    }

    let priceSectors = [];
    if (uniqueSectors.length > 0) {
      priceSectors = uniqueSectors.reduce((acc, item, i) => {
        const value = allSectorData.reduce((sum, data) => {
          if (data.name === item) sum['sum'] = sum['sum'] + data.value;
          if (data.name === item) sum['plSum'] = sum['plSum'] + data.pl;
          return sum;
        }, {
          sum: 0,
          plSum: 0,
        });

        const obj = {
          name: item,
          value: (value.sum / totalMarketValue),
          color: SectorColors[i%SectorColors.length],
          pl: value.plSum,
        }
        acc.push(obj);
        return acc;
      }, [])
    } else out.priceSectors = [];

    out.allocationPriceSectors = priceSectors;

    if (uniqueSectors.length > 0) out.priceSectors = priceSectors.sort((a, b) => b.pl - a.pl)
                                                                  .filter((item, index) => (index <= 4 && item.pl > 0) || (index >= (priceSectors.length - 5) && item.pl <= 0));

    if(uniqueRegions.length > 0) {
      out.priceRegions = uniqueRegions.reduce((acc, item) => {
        const size = allRegionData.reduce((sum, data) => {
          const index = data.findIndex(x => x.name === item)
          if(index >= 0) sum['sum'] = sum['sum'] + data[index].size;
          if(index >= 0) sum['plSum'] = sum['plSum'] + data[index].pl;
          return sum;
        }, {
          sum: 0,
          plSum: 0,
        });
        const abc = {
          name: item,
          value: (size.sum / totalMarketValue) < 0 ? 0 : (size.sum / totalMarketValue),
          color: RegionColors[item],
          pl: size.plSum,
        }
        acc.push(abc)
        return acc;
      }, []);
    } else out.priceRegions = [];

    out.positions = Object.values(fundsH).sort(sortFn('_price'));
    out.tickers = out.positions.map(item => {
      return { ticker: item.symbol, shares: item.quantity }
    })

    return out;
  }
)

export const getPortAccountStats = createSelector(
    [ getPortfolioAccountStats ],
    ( data ) => {
      let out = {
        funds: [], positions: [],
        priceSectors: [], priceRegions: [],
        portfolioValue: 0, fundBalance: 0, cashBalance: 0,
        gainToday: 0, gainMonthly: 0, gainYearly: 0,
        allocationPriceSectors: [],
      };
      if (!data) return out;

      out.funds = data;

      let fundsH = {}, totalMarketValue = 0, totalProfitLoss = 0;
      let allRegionData = [], uniqueRegions = [],
        allSectorData = [], uniqueSectors = [];

      for (const e of out.funds) {
        if (fundsH[e.script] === undefined) fundsH[e.script] = { ...e, _price: 0, symbol: e.script };

        totalMarketValue += e.market_value;
        out.portfolioValue += e._price;
        if (e.daily_nav !== null || e.daily_nav !== 'null') out.gainToday += (e._realTimePrice - e.daily_nav) * e.shares;
        if (e.monthly_nav !== null || e.monthly_nav !== 'null') out.gainMonthly += (e._realTimePrice - e.monthly_nav) * e.shares;
        if (e.yearly_nav !== null || e.yearly_nav !== 'null') out.gainYearly += (e._realTimePrice - e.yearly_nav) * e.shares;

        fundsH[e.script]._price += e.price;

        // Sectors
        if (e.sector && Object.keys(e.sector).length > 0) {
          let sec = SectorsAttrs.map(v => ({ name: v, value: cn(e.sector, v) ? parseFloat((cn(e.sector, v)* 1).toFixed(1)) : 0 }));
          sec = sec.map((item) => {
            (!uniqueSectors.includes(item.name)) && uniqueSectors.push(item.name);
            const obj = {
              name: item.name,
              value: (item.value * e.market_value),
              pl: ((e._realTimePrice - parseFloat(e.purchase_price)) * e.shares) * (item.value / 100) ,
            }
            allSectorData.push(obj);
          })
        }


        // Regions
        if (e.region && Object.keys(e.region).length > 0) {
          let reg = formatTree(cn(e, 'Region'));
          reg = reg.map((item) => {
            (!uniqueRegions.includes(item.name)) && uniqueRegions.push(item.name);
            const region = {
              name: item.name,
              size: (sum(item.children, 'size') * e.market_value),
              pl: ((e._realTimePrice - parseFloat(e.purchase_price)) * e.shares) * (sum(item.children, 'size') / 100),
            }
            return region;
          })
          allRegionData.push(reg)
        }

      }

      let priceSectors = [];
      if (uniqueSectors.length > 0) {
        priceSectors = uniqueSectors.reduce((acc, item, i) => {
          const value = allSectorData.reduce((sum, data) => {
            if (data.name === item) sum['sum'] = sum['sum'] + data.value;
            if (data.name === item) sum['plSum'] = sum['plSum'] + data.pl;
            return sum;
          }, {
            sum: 0,
            plSum: 0,
          });

          const obj = {
            name: item,
            value: (value.sum / totalMarketValue),
            color: SectorColors[i%SectorColors.length],
            pl: value.plSum,
          }
          acc.push(obj);
          return acc;
        }, [])
      } else out.priceSectors = [];

      out.allocationPriceSectors = priceSectors;

      if (uniqueSectors.length > 0) out.priceSectors = priceSectors.sort((a, b) => b.pl - a.pl)
                                                                    .filter((item, index) => (index <= 4 && item.pl > 0) || (index >= (priceSectors.length - 5) && item.pl <= 0));


      if(uniqueRegions.length > 0) {
        out.priceRegions = uniqueRegions.reduce((acc, item) => {
          const size = allRegionData.reduce((sum, data) => {
            const index = data.findIndex(x => x.name === item)
            if(index >= 0) sum['sum'] = sum['sum'] + data[index].size;
            if(index >= 0) sum['plSum'] = sum['plSum'] + data[index].pl;
            return sum;
          }, {
            sum: 0,
            plSum: 0,
          });
          const abc = {
            name: item,
            value: (size.sum / totalMarketValue) < 0 ? 0 : (size.sum / totalMarketValue),
            color: RegionColors[item],
            pl: size.plSum,
          }
          acc.push(abc)
          return acc;
        }, []);
      } else out.priceRegions = [];

      out.funds.sort((a, b) => {
        return new Date(b.purchase_date) - new Date(a.purchase_date);
      })

      out.positions = Object.values(fundsH).sort(sortFn('_price'));

      return out;
    }
  )

export const getPortfolioStats = createSelector(
    [ getPortfolioFunds ],
    ( data ) => {
      let out = {
        funds: [], positions: [],
        priceSectors: [], priceRegions: [],
        portfolioValue: 0, fundBalance: 0, cashBalance: 0,
        gainToday: 0, gainMonthly: 0, gainYearly: 0,
        allocationPriceSectors: [],
      };
      if (!data) return out;

      out.funds = data;

      let fundsH = {}, totalMarketValue = 0, totalProfitLoss = 0;
      let allRegionData = [], uniqueRegions = [],
        allSectorData = [], uniqueSectors = [];

      for (const e of out.funds) {
        if (fundsH[e.script] === undefined) fundsH[e.script] = { ...e, _price: 0, symbol: e.script };

        totalMarketValue += e.market_value;
        out.portfolioValue += e._price;
        if (e.daily_nav !== null) out.gainToday += (e._realTimePrice - e.daily_nav) * e.shares;
        if (e.monthly_nav !== null) out.gainMonthly += (e._realTimePrice - e.monthly_nav) * e.shares;
        if (e.yearly_nav !== null) out.gainYearly += (e._realTimePrice - e.yearly_nav) * e.shares;
        fundsH[e.script]._price += e.price;

        // Sectors
        if (e.sector && Object.keys(e.sector).length > 0) {
          let sec = SectorsAttrs.map(v => ({ name: v, value: cn(e.sector, v) ? parseFloat((cn(e.sector, v)* 1).toFixed(1)) : 0 }));
          sec = sec.map((item) => {
            (!uniqueSectors.includes(item.name)) && uniqueSectors.push(item.name);
            const obj = {
              name: item.name,
              value: (item.value * e.market_value),
              pl: ((e._realTimePrice - parseFloat(e.purchase_price)) * e.shares) * (item.value / 100) ,
            }
            allSectorData.push(obj);
          })
        }


        // Regions
        if (e.region && Object.keys(e.region).length > 0) {
          let reg = formatTree(cn(e, 'Region'));
          reg = reg.map((item) => {
            (!uniqueRegions.includes(item.name)) && uniqueRegions.push(item.name);
            const region = {
              name: item.name,
              size: (sum(item.children, 'size') * e.market_value),
              pl: ((e._realTimePrice - parseFloat(e.purchase_price)) * e.shares) * (sum(item.children, 'size') / 100),
            }
            return region;
          })
          allRegionData.push(reg)
        }

      }

      let priceSectors = [];
      if (uniqueSectors.length > 0) {
        priceSectors = uniqueSectors.reduce((acc, item, i) => {
          const value = allSectorData.reduce((sum, data) => {
            if (data.name === item) sum['sum'] = sum['sum'] + data.value;
            if (data.name === item) sum['plSum'] = sum['plSum'] + data.pl;
            return sum;
          }, {
            sum: 0,
            plSum: 0,
          });

          const obj = {
            name: item,
            value: (value.sum / totalMarketValue),
            color: SectorColors[i%SectorColors.length],
            pl: value.plSum,
          }
          acc.push(obj);
          return acc;
        }, [])
      } else out.priceSectors = [];

      out.allocationPriceSectors = priceSectors;

      if (uniqueSectors.length > 0) out.priceSectors = priceSectors.sort((a, b) => b.pl - a.pl)
                                                                    .filter((item, index) => (index <= 4 && item.pl > 0) || (index >= (priceSectors.length - 5) && item.pl <= 0));


      if(uniqueRegions.length > 0) {
        out.priceRegions = uniqueRegions.reduce((acc, item) => {
          const size = allRegionData.reduce((sum, data) => {
            const index = data.findIndex(x => x.name === item)
            if(index >= 0) sum['sum'] = sum['sum'] + data[index].size;
            if(index >= 0) sum['plSum'] = sum['plSum'] + data[index].pl;
            return sum;
          }, {
            sum: 0,
            plSum: 0,
          });
          const abc = {
            name: item,
            value: (size.sum / totalMarketValue) < 0 ? 0 : (size.sum / totalMarketValue),
            color: RegionColors[item],
            pl: size.plSum,
          }
          acc.push(abc)
          return acc;
        }, []);
      } else out.priceRegions = [];

      out.funds.sort((a, b) => {
        return new Date(b.purchase_date) - new Date(a.purchase_date);
      })

      out.positions = Object.values(fundsH).sort(sortFn('_price'));

      return out;
    }
  )


export const getMarketStats = createSelector(
  [ getMarketFunds ],
  ( data ) => {
    let out = {
      funds: [], positions: [],
      priceSectors: [], priceRegions: [],
      portfolioValue: 0, fundBalance: 0, cashBalance: 0,
      gainToday: 0, gainMonthly: 0, gainYearly: 0,
      allocationPriceSectors: [],
    };
    if (!data) return out;

    out.funds = data;

    let fundsH = {}, totalMarketValue = 0, totalProfitLoss = 0;
    let allRegionData = [], uniqueRegions = [],
      allSectorData = [], uniqueSectors = [];

    for (const e of out.funds) {
      if (fundsH[e.script] === undefined) fundsH[e.script] = { ...e, _price: 0, symbol: e.script };

      totalMarketValue += e.market_value;
      out.portfolioValue += e._price;
      if (e.daily_nav !== null || e.daily_nav !== 'null') out.gainToday += (e._realTimePrice - e.daily_nav) * e.shares;
      if (e.monthly_nav !== null || e.monthly_nav !== 'null') out.gainMonthly += (e._realTimePrice - e.monthly_nav) * e.shares;
      if (e.yearly_nav !== null || e.yearly_nav !== 'null') out.gainYearly += (e._realTimePrice - e.yearly_nav) * e.shares;

      fundsH[e.script]._price += e.price;

      // Sectors
      if (e.sector && Object.keys(e.sector).length > 0) {
        let sec = SectorsAttrs.map(v => ({ name: v, value: cn(e.sector, v) ? parseFloat((cn(e.sector, v)* 1).toFixed(1)) : 0 }));
        sec = sec.map((item) => {
          (!uniqueSectors.includes(item.name)) && uniqueSectors.push(item.name);
          const obj = {
            name: item.name,
            value: (item.value * e.market_value),
            pl: ((e._realTimePrice - parseFloat(e.purchase_price)) * e.shares) * (item.value / 100) ,
          }
          allSectorData.push(obj);
        })
      }


      // Regions
      if (e.region && Object.keys(e.region).length > 0) {
        let reg = formatTree(cn(e, 'Region'));
        reg = reg.map((item) => {
          (!uniqueRegions.includes(item.name)) && uniqueRegions.push(item.name);
          const region = {
            name: item.name,
            size: (sum(item.children, 'size') * e.market_value),
            pl: ((e._realTimePrice - parseFloat(e.purchase_price)) * e.shares) * (sum(item.children, 'size') / 100),
          }
          return region;
        })
        allRegionData.push(reg)
      }

    }

    let priceSectors = [];
    if (uniqueSectors.length > 0) {
      priceSectors = uniqueSectors.reduce((acc, item, i) => {
        const value = allSectorData.reduce((sum, data) => {
          if (data.name === item) sum['sum'] = sum['sum'] + data.value;
          if (data.name === item) sum['plSum'] = sum['plSum'] + data.pl;
          return sum;
        }, {
          sum: 0,
          plSum: 0,
        });

        const obj = {
          name: item,
          value: (value.sum / totalMarketValue),
          color: SectorColors[i%SectorColors.length],
          pl: value.plSum,
        }
        acc.push(obj);
        return acc;
      }, [])
    } else out.priceSectors = [];

    out.allocationPriceSectors = priceSectors;

    if (uniqueSectors.length > 0) out.priceSectors = priceSectors.sort((a, b) => b.pl - a.pl)
                                                                  .filter((item, index) => (index <= 4 && item.pl > 0) || (index >= (priceSectors.length - 5) && item.pl <= 0));


    if(uniqueRegions.length > 0) {
      out.priceRegions = uniqueRegions.reduce((acc, item) => {
        const size = allRegionData.reduce((sum, data) => {
          const index = data.findIndex(x => x.name === item)
          if(index >= 0) sum['sum'] = sum['sum'] + data[index].size;
          if(index >= 0) sum['plSum'] = sum['plSum'] + data[index].pl;
          return sum;
        }, {
          sum: 0,
          plSum: 0,
        });
        const abc = {
          name: item,
          value: (size.sum / totalMarketValue) < 0 ? 0 : (size.sum / totalMarketValue),
          color: RegionColors[item],
          pl: size.plSum,
        }
        acc.push(abc)
        return acc;
      }, []);
    } else out.priceRegions = [];

    out.funds.sort((a, b) => {
      return new Date(b.purchase_date) - new Date(a.purchase_date);
    })

    out.positions = Object.values(fundsH).sort(sortFn('_price'));

    out.tickers = out.positions.map(item => {
      return { ticker: item.symbol, shares: item.quantity }
    })

    return out;
  }
)


const sortFn = attr => (a, b) => {
  // sort descending
  if (a[attr] < b[attr]) return 1
  else if (a[attr] > b[attr]) return -1
  return 0
}

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { uniqBy } from 'lodash';
import { Card } from 'antd';

import { getPortfolioDataByIDRequest, setEnhancerState, resetFundsPortfolioSuccess } from 'actions/enhancer';
import { getSummaryFunds } from 'selectors/enhancer';
import { createLoadingSelector } from 'selectors/loading-selector';

import { cn } from 'DataSet';
import { getPortfolioBenchmarkOfSelectedTicker } from 'Utils'

import Board from './Board';
import ContentHead from './ContentHead';
import PortfolioModal from '../../Elements/PortfolioModal';
import { geopMeanCalc } from '../../../AccountsPage/utils';
import { customScroll } from 'layouts/utils';
import { addEventToAnalytics } from 'Utils';

class Content extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   summaryDrawer: props.summaryDrawer,
    // };
  }

  componentWillUnmount() {
    const { resetFundsPortfolioSuccess } = this.props;
    if (resetFundsPortfolioSuccess) {
      resetFundsPortfolioSuccess();
    }
  }

  componentDidMount() {
		customScroll();
    const { location, onboardData, history, buildportfolio, setBuildPortfolioState, match, getPortfolioDataByIDRequest, portfolioName, loading } = this.props;
    try {
			console.log(match, loading);
      if (match && !loading) {
        const id = match.params.id
        if (location && location.search) {
          const params = new URLSearchParams(location.search)
					console.log(params);
          if (params) {
            const portfolioId = params.get('portfolioId')
            const portfolioType = params.get('portfolioType')
            if (portfolioId &&
              portfolioType &&
              getPortfolioDataByIDRequest &&
              portfolioId !== 'undefined' &&
              portfolioType !== 'undefined') {
              getPortfolioDataByIDRequest({
                filename: portfolioId,
                portfolioType: portfolioType,
              }, () => {
                console.log('=---- loaded ----=');
              });
            } else {
              throw 'data not found in url'
            }
          } else {
            throw 'data not found in url'
          }
        } else {
          throw 'data not found in url'
        }
      }
    } catch (e) {
      console.log(e);
      // const { history } = this.props;
      // if (history) {
      //   const newPath = `/enhancer`
      //   history.push(newPath)
      // }
    }
  }

	componentWillReceiveProps(newProps) {
		const { location, onboardData, history, buildportfolio, setBuildPortfolioState, match, getPortfolioDataByIDRequest, portfolioName, loading } = newProps;
		const { location: currentLocation } = this.props;
		try {
			if (match && !loading) {
				const id = match.params.id;
				if (location && location.search) {
					const params = new URLSearchParams(location.search);
					const currentParams = new URLSearchParams(currentLocation.search);
					if (params) {
						const currentPortfolioId = currentParams.get('portfolioId');
						const portfolioId = params.get('portfolioId');
						const portfolioType = params.get('portfolioType');
						if (portfolioId &&
							portfolioType &&
							currentPortfolioId !== portfolioId &&
							getPortfolioDataByIDRequest &&
							portfolioId !== 'undefined' &&
							portfolioType !== 'undefined') {
							getPortfolioDataByIDRequest({
								filename: portfolioId,
								portfolioType: portfolioType,
							}, () => {
								console.log('=---- loaded ----=');
							});
						} else {
							throw 'data not found in url'
						}
					} else {
						throw 'data not found in url'
					}
				} else {
					throw 'data not found in url'
				}
			}
		} catch (e) {
			console.log(e);
			// const { history } = this.props;
			// if (history) {
			//   const newPath = `/enhancer`
			//   history.push(newPath)
			// }
		}
	}

  componentDidUpdate() {
    const headerElement = document.getElementById("magnifi-header-wrapper");
    const portfolioContentHeaderElement = document.getElementById("funds-board-content-header");
    if (portfolioContentHeaderElement && headerElement) {
      const portfolioSummaryDrawerElement = document.getElementById("summaryDrawerElement");
      if (portfolioSummaryDrawerElement) {
        const drawerxContentElement = portfolioSummaryDrawerElement.getElementsByClassName("ant-drawer-content-wrapper")[0];
        drawerxContentElement.style.top = portfolioContentHeaderElement.offsetHeight+15+'px';
      }
    }
  }

  // openDrawer = (visible) => {
  //   if(visible == true)
  //     addEventToAnalytics('Enhancer Summary','Enhancer Summary','ENHANCER_SUMMARY',{},false);
  //   this.setState({
  //     summaryDrawer: visible,
  //   });
  // }

  // handleUpDownDropDownChange = (value) => {
  //   const { setEnhancerState, performanceImpact } = this.props;
  //   const { enhancedPerformanceImpact, currentPerformanceImpact, upDownDataSet } = performanceImpact;
  //   if (setEnhancerState) {
  //     let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(upDownDataSet, value)
  //     // console.log(enhancedPerformanceImpact)
  //     if (Object.keys(enhancedPerformanceImpact).length>0) {
  //       let { pfRet1y, pfRet3y, pfRet5y } = enhancedPerformanceImpact
  //       let { pfRet1y:pfRet1yC,pfRet3y:pfRet3yC,pfRet5y:pfRet5yC } = currentPerformanceImpact
  //       let { up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr } = geopMeanCalc(portfolioBenchmark, pfRet1y, pfRet3y, pfRet5y)
  //       let { up_1yr:up_1yrC, down_1yr:down_1yrC, up_3yr:up_3yrC, down_3yr:down_3yrC, up_5yr:up_5yrC, down_5yr:down_5yrC } = geopMeanCalc(portfolioBenchmark, pfRet1yC, pfRet3yC, pfRet5yC)
  //       let updateEnhanceStats = { ...enhancedPerformanceImpact, up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr}
  //       let updatedCurrentStats = { ...currentPerformanceImpact, up_1yr:up_1yrC, down_1yr:down_1yrC, up_3yr:up_3yrC, down_3yr:down_3yrC, up_5yr:up_5yrC, down_5yr:down_5yrC}
  //       setEnhancerState({
  //         performanceImpact: {
  //           ...performanceImpact,
  //           selectedBenchmark: value,
  //           currentPerformanceImpact: updatedCurrentStats,
  //           enhancedPerformanceImpact: updateEnhanceStats,
  //         }
  //       });
  //     } else {
  //       let { pfRet1y, pfRet3y, pfRet5y } = currentPerformanceImpact
  //       let { up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr } = geopMeanCalc(portfolioBenchmark, pfRet1y, pfRet3y, pfRet5y)
  //       let updatedCurrentStats = { ...currentPerformanceImpact, up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr}
  //       setEnhancerState({
  //         performanceImpact: {
  //           ...performanceImpact,
  //           selectedBenchmark: value,
  //           currentPerformanceImpact: updatedCurrentStats
  //         }
  //       });
  //     }
  //   }
  // }
  //
  // // auto open/close sfm window
  // modalToggle = (modalToggleState) => {
  //   const { setSFMState, toggleModal } = this.props;
  //   if (modalToggleState === 'add') {
  //     setSFMState({
  //       modalToggleState: true,
  //       modal: true,
  //     });
  //   } else if (modalToggleState === 'remove') {
  //     setSFMState({
  //       modalToggleState: false,
  //       modal: false,
  //     });
  //   }
  // }

  render() {
    const {
      enhancer,
      loading,
      fundPortfolios,
      query,
      selectedWeightOptions,
      queryEnglish,
      searchTypeMode,
      performanceImpact,
      enhanceTickerFilter,
      enhanceReplace,
      summary,
      portfolioName,
      onboardData,
    } = this.props;

    const { currentPerformanceImpact, enhancedPerformanceImpact, upDownDataSet, selectedBenchmark } = performanceImpact;
    // const { summaryDrawer } = this.state;
    const { portfolioFunds } = summary;

    const fundStats = getSummaryFunds(enhancer);

    return (
      <React.Fragment>
        {!loading && (
          <div className="summary-container OpenSummaryPage-container" style={{ width: '100%' }}>
            {portfolioFunds && portfolioFunds.length > 0 ? (
              <div>
                {/*
                  <ContentHead
                    {...this.props}
                    summaryDrawer={summaryDrawer}
                    openDrawer={this.openDrawer}
                  />
                */}
                <Board
                  portfolioName={portfolioName}
                  summary={summary}
                  retData={summary.retData}
                  activeFund={summary.activeFund}
                  onboardData={onboardData}
                  funds={portfolioFunds}
                  fundStats={getSummaryFunds(enhancer)}
                />
              </div>
            ) : (
              <Card className="card-background-grey empty-message-wrapper no-funds-wrapper">
                <img src='/assets/images/icons/NoFundsFound.svg' alt='' />
                <div className="text-box">
                  <span>No DATA</span> were found for portfolio <span>{portfolioName}</span>.
                </div>
              </Card>
            )}
            {/*
              typeof currentPerformanceImpact !== 'undefined' &&
              typeof currentPerformanceImpact.pfRet1y !== 'undefined' &&
              (currentPerformanceImpact || enhancedPerformanceImpact) && (
                <PortfolioModal
                  closable
                  mask={false}
                  enhancePortfolioVisible={true}
                  summaryPage={false}
                  visible={summaryDrawer}
                  query={query}
                  portfolioEnhanceReplace={enhanceReplace}
                  enhanceStats={enhancedPerformanceImpact}
                  currentStats={currentPerformanceImpact}
                  enhanceTickerFilter={enhanceTickerFilter}
                  portfolioBenchmarkData={upDownDataSet}
                  selectedPortfolioBenchmarkTicker={selectedBenchmark}
                  openDrawer={this.openDrawer}
                  handleUpDownDropDownChange={this.handleUpDownDropDownChange}
                  toggleChange={this.toggleChange}
                  setPfEnhancement={this.setPfEnhancement}
                  evaluateOnChange={this.evaluateOnChange}
                  setPortfolioState={this.props.setPortfolioState}
                  replacePortfolioToggle={this.props.replacePortfolioToggle}
                />
              )
            */}
          </div>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ loading, enhancer, portfolio, sfm, funds }) => ({
  enhancer,
  summary: enhancer.summary,
  query: enhancer.query,
  onboardData: enhancer.onboardData,
  summaryDrawer: enhancer.summaryDrawer,
  portfolioName: enhancer.portfolioName,
  fundPortfolios: enhancer.fundPortfolios,
  queryEnglish: enhancer.query_english,
  searchTypeMode: enhancer.searchTypeMode,
  guidanceMessage: enhancer.guidanceMessage,
  performanceImpact: enhancer.performanceImpact,
  enhanceReplace: enhancer.enhanceReplace,
  enhanceTickerFilter: enhancer.enhanceTickerFilter,
  selectedWeightOptions: portfolio.selectedWeightOptions,
  loading: createLoadingSelector(['GET_PORTFOLIO_BY_ID'])({ loading }),
})

const mapDispatchToProps = {
  getPortfolioDataByIDRequest,
  setEnhancerState,
  resetFundsPortfolioSuccess,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Content);

import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Modal, ModalBody, ButtonGroup } from 'reactstrap';
import { Steps, Button, message, Menu, Dropdown, Icon, Row } from 'antd';
import { setPortfolioState } from 'actions/portfolio';
import { investmentFetch, investmentOptions, fetchPortfolioData, fetchMarketData, investmentFetchSuccess, deletePortfolio, getClientListRequest } from 'actions/investment';
import { setPortfolioReplace, set } from 'actions/portfolio';
import IndexIcon from '../Elements/IndexIcon';
import VideoPlayer from 'components/VideoPlayer';
import SelectPortfolioForm from './SelectPortfolioForm';
import { demoPortfolio } from 'actions/investment';
import PremiumNoticeModal from 'components/PremiumNoticeModal';
import { setProfileState } from 'actions/profile';
import { createLoadingSelector } from 'selectors/loading-selector';
import {getPremiumFeatureDetails} from '../../../../apis/test-drive';
import { addEventToAnalytics } from 'Utils';

const { Step } = Steps;

class OnBoardScreen extends Component {
  Watchlist = { name: 'Watchlist', code: 'watchlist' }
  UploadPortfolio = { label: 'Upload Portfolio', name: 'Portfolio', code: 'uplPrt' }
  MarketPlace = { label: 'Market Place', name: 'Market', code: 'mrktPl' }

  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      deletePortfolio: '',
      deleteConfirm: false,
      premiumModal: false,
      notify: false,
      premiumFeature: 'false',
      moduleLoading: false,
      videoData: {
        title: 'Magnifi',
        link: 'https://magnifi-videos.s3.us-east-2.amazonaws.com/Enhancer_01.mp4',
      },
    };
  }

  componentDidMount(){
    let testDrive = null;
    getPremiumFeatureDetails('enhancement').then(res => {
      testDrive = res.test_drive;
      this.setState({premiumFeature: testDrive});
    });
    // document.body.classList.add('body-bg-white');
    if(this.props.redirectFrom.module || this.props.redirectFrom.type){
      this.setState({moduleLoading: true});
    }
    this.props.getClientListRequest();
  }

  componentWillUnmount() {
    // document.body.classList.remove('body-bg-white');
  }

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  onFileSuccess = () => {
    const { setPortfolioState, uploadFilePartial } = this.props;
    !uploadFilePartial && setPortfolioState({ onBoardScreen: false });
  }

  deletePortfolio = (fileName) => {
    // if (!this.props.premiumUser) this.setState({ premiumModal: true })
    // else {
    // this.props.investmentFetchSuccess({ setPfEnhancementContent: false })
    this.setState({ deletePortfolio : fileName, deleteConfirm: true })
    // }
  }

  confirmDeletePortfolio = () => {
    addEventToAnalytics('Enhancer Delete','Enhancer Delete','ENHANCER_DELETE',{},false);
    const opts = { view: 'account', activeAccount: 'All', activeAccAccount: 'All', acView: 'default' }
    this.props.investmentOptions(opts);
    this.props.deletePortfolio(this.state.deletePortfolio, () => this.setState({ deleteConfirm: false }));
  }

  showPortfolioDetails = (selectedPortfolio) => {
    addEventToAnalytics('Enhancer UP','Enhancer UP','ENHANCER_UP',{},false);
    this.props.setPortfolioReplace([]);
    this.props.investmentFetchSuccess({ setPfEnhancementContent: false })
    // if (!this.props.premiumUser) this.setState({ premiumModal: true })
    // else {
    this.props.fetchPortfolioData(selectedPortfolio)
    const opts = { view: this.UploadPortfolio.code, activePortAccount: selectedPortfolio, activeAccount: selectedPortfolio, activeAccAccount: 'All', acView: 'default' }
    this.props.investmentOptions(opts);
    // }
  }

  selectedClientPortfolio = (selectedPortfolio) => {
    addEventToAnalytics('Enhancer Client Portfolio','Enhancer Client Portfolio','ENHANCER_CLIENT_PORTFOLIO',{},false);
    this.props.setPortfolioReplace([]);
    this.props.investmentFetchSuccess({ setPfEnhancementContent: false })
    // if (!this.props.premiumUser) this.setState({ premiumModal: true })
    // else {
    this.props.fetchPortfolioData(selectedPortfolio)
    const opts = { view: this.UploadPortfolio.code, activePortAccount: selectedPortfolio, activeAccount: selectedPortfolio, activeAccAccount: 'All', acView: 'default' }
    this.props.investmentOptions(opts);
  }

  setWatchlist = () => {
    addEventToAnalytics('Enhancer Watchlist','Enhancer Watchlist','ENHANCER_WATCHLIST',{},false);
    let opts = { view: this.Watchlist.code, activeAccAccount: 'All', activeAccount: 'All', acView: 'default', activePortAccount: '' }
    this.props.investmentOptions(opts);
    this.props.investmentFetchSuccess({ setPfEnhancementContent: false })
    this.props.setPortfolioState({ onBoardScreen: false })
  }

  setActiveAccount = (acc) => {
    addEventToAnalytics('Enhancer Linked','Enhancer Linked','ENHANCER_LINKED',{},false);
    this.props.investmentFetchSuccess({ setPfEnhancementContent: false })
    let opts = { view: 'account', activeAccAccount: 'All', activeAccount: acc, filename: acc, acView: 'default', activePortAccount: '' }
    this.props.investmentOptions(opts);
    this.props.setPortfolioState({ onBoardScreen: false })
  }

  setMarketPlace = (ticker) => {
    const { setPortfolioReplace, investmentFetchSuccess, fetchMarketData, investmentOptions } = this.props;
    addEventToAnalytics('Enhancer MP','Enhancer MP','ENHANCER_MP',{},false);
    setPortfolioReplace([]);
    investmentFetchSuccess({ setPfEnhancementContent: false })
    fetchMarketData(ticker)
    let opts = { view: this.MarketPlace.code, activeAccAccount: ticker, activeAccount: ticker, filename: ticker, acView: 'default', activePortAccount: '' }
    investmentOptions(opts);
    setPortfolioState({ onBoardScreen: false })
  }

  setDemoPortfolio = () => {
    this.props.demoPortfolio();
  }

  redirectToAccount = () =>{
    this.props.history.push('/investments');
  }

  openNotify = () => {
    this.setState({
      notify: true,
    });
  }

  notifyToggle = () => {
    this.setState({
      notify: !this.state.notify,
    });
  }

  closePremiumModal = () => {
    this.props.history.push('/securities');
  }

  premiumModalToggle = () => {
    this.setState({
      premiumModal: !this.state.premiumModal,
    });
  }

  videoSetState = (_state) => {
    // console.log(_state);
  }

  render() {
    const { enhancementData, onBoardScreen, brokers, loading, premiumUser, clientList, clientListLoading } = this.props;
    const { current, deleteConfirm, moduleLoading, videoData } = this.state;
    const steps = [
      {
        title: 'Identify Portfolio',
        content: <SelectPortfolioForm
        clientListLoading={clientListLoading}
        clientList={clientList}
        brokers={brokers}
        deletePort={this.deletePortfolio}
        selectedPortfolio={this.showPortfolioDetails}
        setWatchlist={this.setWatchlist}
        setActiveAccount={this.setActiveAccount}
        setMarketPlace={this.setMarketPlace}
        onFileSuccess={this.onFileSuccess}
        setDemoPortfolio={this.setDemoPortfolio}
        selectedClientPortfolio={this.selectedClientPortfolio}
        {...this.props} />,
    },
    {
      title: 'Evaluate Enhancements',
      content: 'Second-content',
    },
    {
      title: 'Generate Tradelist / Proposal',
      content: 'Last-content',
    },
  ];

  return (
    <React.Fragment>
      <div className="onBoardScreenWrapper">
        <Modal
          isOpen={deleteConfirm}
          toggle={() => this.setState({ deleteConfirm: !deleteConfirm })}
          centered
          keyboard={true}
          backdrop
          className="delete-confirm-modal premium-msg-modal account-modal"
          backdropClassName="premium-feature-process-modal-backdrop top-60px"
          modalClassName="top-60px"
          >
          <ModalBody className="ssf-modal__body">
            <div className="premium-feature-process-modal-container">
              <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => this.setState({ deleteConfirm: false })}></i>
              <p className="content-wrapper">
                <div className="col-12 question">Are you sure you want to delete {deletePortfolio} portfolio?</div>
                <div className="col-12 cta-wrapper margin-left-51">
                  <button type="button" className="ssf-btn-primary btn secondary-btn btn btn-secondary" onClick={() => this.confirmDeletePortfolio()}><span>YES</span></button>
                  <button type="button" className="ssf-btn-primary btn secondary-btn btn btn-secondary" onClick={() => this.setState({ deleteConfirm: false })}><span>NO</span></button>
                </div>
              </p>
            </div>
          </ModalBody>
        </Modal>
        {moduleLoading || !loading && (
          <Fragment>
            { premiumUser === false && (
              <PremiumNoticeModal
                {...this.props}
                open={!this.state.premiumModal}
                premiumUser={premiumUser}
                onClickCancel={this.closePremiumModal}
                onToggle={this.premiumModalToggle}
                notify={this.state.notify}
                openNotify={this.openNotify}
                notifyToggle={this.notifyToggle}
                premiumFeature={this.state.premiumFeature}
                modalActionType={'enhancement'}
                featureName='enhancer'
              />
            )}
            <Row>
              <div className='col-6' style={{paddingLeft: '0px'}}>
                <div className="page-head-wrapper">
                  <h1 className="head-label">Enhancer</h1>
                  <div className="page-description">
                    Read in existing or prospect portfolio for easy enhancements using funds, home office models or sponsor models.
                  </div>
                </div>
                <div className="content_title">
                  Analyze Portfolio For Possible Enhancements
                   <div className="select-portfolio-text">Start with -</div>
                </div>

              </div>
              <div className='col-6 video-block'>
                <VideoPlayer
                  key={videoData.link}
                  link={videoData.link}
                  title={videoData.title}
                  onPlayPauseState={this.videoSetState}
                  onPauseState={this.videoSetState}
                  onEndedState={this.videoSetState}
                />
                {/* <iframe src="https://player.vimeo.com/video/428290451?autoplay=1&muted=1" allow="autoplay"></iframe> */}
              </div>
            </Row>
            <div className="onBoardScreen_contentWrapper">
              {steps[current].content}
            </div>
          </Fragment>
        )}
      </div>
    </React.Fragment>
  )
}
}

const mapStateToProps = ({ portfolio, investment, auth, profile, loading }) => ({
  brokers: investment.brokers,
  onBoardScreen: portfolio.onBoardScreen,
  enhancementData: portfolio.enhancementData,
  valid: investment.valid,
  loading: investment.loading,
  redirectFrom: investment.redirectFrom,
  portfolioList: investment.portfolioList,
  marketPlaceList: investment.marketPlaceList,
  uploadFilePartial: investment.uploadFilePartial,
  premiumUser: auth.user.premiumUser,
  inviteSuccess:profile.inviteSuccess,
  inviteSentTo:profile.inviteSentTo,
  clientList: investment.clientList,
  clientListLoading: createLoadingSelector(['GET_CLIENT_LIST'])({ loading }),
});

const mapDispatchToProps = {
  investmentOptions,
  setPortfolioReplace,
  investmentFetchSuccess,
  fetchPortfolioData,
  investmentFetch,
  deletePortfolio,
  setPortfolioState,
  fetchMarketData,
  demoPortfolio,
  setProfileState,
  getClientListRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnBoardScreen)

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Button, Row, Col, PopoverBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withPopover } from 'components/Popover';
import { TruncatedText } from 'components/sf/Common';
import { convertToCapitalCase, getNest, getImageOrFallback } from 'Utils';
import { cn, sponsorLogo } from 'DataSet';
import { getLineHeight, getSTKData, getIndexSearchTextData } from '../utils';
import { fundsApiCallTime, clearFundsApiLoadPercent } from 'actions/global';
import { searchQuery, setFundsState, dymLogs  } from 'actions/funds';
import LinesEllipsis from 'react-lines-ellipsis';

class NamePopover extends Component {
  constructor(props){
    super(props);
  }

  state = {
    imageValidPath: false,
  }

  componentDidMount() {
    const { card } = this.props;
    if (cn(card, 'Sponsor')) {
      getImageOrFallback(
        sponsorLogo(cn(card, 'Sponsor'))
      ).then(result => {
        if (result) {
          this.setState({ imageValidPath: true });
        } else {
          this.setState({ imageValidPath: false });
        }
      });
    }
  }

  toggle = (e) => {
    try {
      const removeId = e.currentTarget.dataset.index;
      const element = document.getElementById(removeId);
      const elementText = element.innerHTML;
      // console.log(document.querySelector(`[data-value = ${removeId}]`));
      const elementFullText = document.querySelector(`[data-value = ${removeId}]`).value;
      const containClass = element.classList.contains("text-ellipse");
      const head = e.currentTarget.dataset.head || '';

      // console.log(containClass);
      if (containClass) {
        const elems = document.querySelectorAll(".show-less");
        [].forEach.call(elems, function (el) {
          if (el.id !== element) {
            el.classList.add("text-ellipse");
            const elFullText = document.querySelector(`[data-value = ${el.id}]`).value;
            const lessText = document.querySelector(`[data-value = ${el.id}-less]`);
            if (!lessText) el.innerHTML = elFullText;
            else el.innerHTML = lessText.value;
            const truncateTextParts = el.innerHTML.split(' ');
            const lineHeight = getLineHeight(el);
            const lines = (el.id === 'short_desc' || el.id === 'thm_txt') ? 3 : 2;

            while (lines * lineHeight < el.offsetHeight) {
              truncateTextParts.pop();
              el.innerHTML = truncateTextParts.join(' ');
            }
          }
          document.querySelector(`[data-index = ${el.id}]`).style.position = "absolute";
          document.querySelector(`[data-index = ${el.id}]`).innerHTML = "... more";
        });
        element.innerHTML = elementFullText;
        document.getElementById(removeId).classList.remove("text-ellipse");
        document.querySelector(`[data-index = ${removeId}]`).style.position = "relative";
        document.querySelector(`[data-index = ${removeId}]`).innerHTML = "less";
        this.addMargin();
      } else {
        if (head === '') {
          const truncateTextParts = elementText.split(' ');
          const lineHeight = getLineHeight(element);
          const lines = (removeId === 'short_desc' || removeId === 'thm_txt') ? 3 : 2;

          while (lines * lineHeight < element.offsetHeight) {
            truncateTextParts.pop();
            element.innerHTML = truncateTextParts.join(' ');
          }
        } else {
          const lessText = document.querySelector(`[data-value = ${removeId}-less]`).value;
          element.innerHTML = lessText;
        }
        document.getElementById(removeId).classList.add("text-ellipse");
        document.querySelector(`[data-index = ${removeId}]`).style.position = "absolute";
        document.querySelector(`[data-index = ${removeId}]`).innerHTML = "... more";
        this.addMargin();
      }
    } catch (e) {
      console.log(e);
    }
  }

  addMargin = () => {
    try {
      const elems = document.querySelectorAll(".pop-wrapper");
      [].forEach.call(elems, function (el) {
        const space = window.innerHeight - (el.offsetTop + el.offsetHeight);
        el.style.paddingBottom = `470px`;
        el.style.marginBottom = `-470px`;
        setTimeout(() => {
          el.style.paddingBottom = `0px`;
          el.style.marginBottom = `0px`;
        }, 3000)
      });
    } catch (e) {
      console.log(e);
    }
  }

  truncString = () => {
    try {
      const elems = document.querySelectorAll(".show-less");
      if (elems.length) {
        [].forEach.call(elems, function (el) {
          if (el.classList.contains("text-ellipse")) {
            if (el.id !== '') {
              el.classList.add("text-ellipse");
              if (document.querySelector(`[data-value = ${el.id}]`)) {
                if (!document.querySelector(`[data-value = ${el.id}-less]`)) {
                  const elFullText = document.querySelector(`[data-value = ${el.id}]`).value || '';
                  el.innerHTML = elFullText;
                  const truncateTextParts = el.innerHTML.split(' ');
                  const lineHeight = getLineHeight(el);
                  const lines = (el.id === 'short_desc' || el.id === 'thm_txt') ? 3 : 2;
                  if (lines * lineHeight > el.offsetHeight) document.querySelector(`[data-index = ${el.id}]`).style.display = "none";
                  while (lines * lineHeight < el.offsetHeight) {
                    truncateTextParts.pop();
                    el.innerHTML = truncateTextParts.join(' ');
                  }
                } else {
                  const elFullText = document.querySelector(`[data-value = ${el.id}]`).value;
                  const lessText = document.querySelector(`[data-value = ${el.id}-less]`).value;
                  if (elFullText !== lessText) {
                    el.innerHTML = lessText;
                  } else {
                    el.innerHTML = elFullText;
                    const truncateTextParts = el.innerHTML.split(' ');
                    const lineHeight = getLineHeight(el);
                    const lines = (el.id === 'short_desc' || el.id === 'thm_txt') ? 3 : 2;
                    if (lines * lineHeight > el.offsetHeight) document.querySelector(`[data-index = ${el.id}]`).style.display = "none";
                    while (lines * lineHeight < el.offsetHeight) {
                      truncateTextParts.pop();
                      el.innerHTML = truncateTextParts.join(' ');
                    }
                  }
                }
                document.querySelector(`[data-index = ${el.id}]`).style.position = "absolute";
                document.querySelector(`[data-index = ${el.id}]`).innerHTML = "... more";
              }
            }
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  queryHandler = (query) => {
    if (query) {
      const { searchQuery, setFundsState, clearFundsApiLoadPercent, dymLogs, logId, query: _query  } = this.props;
      // console.log(_query, query);
      clearFundsApiLoadPercent();
      const old_query = window.sessionStorage.getItem('query');
      if (old_query) {
        window.sessionStorage.setItem('query', query);
        if (_query !== query) {
          setFundsState({
            requestPayloadFilters: {
              filterKeys: [],
              filterValues: [],
            },
            requestPayloadSort: {
              name: '',
              value: '',
            },
            actions: true,
          });
        } else {
          setFundsState({
            actions: true,
          });
        }
        this.props.history.push('/securities?query='+query);
        dymLogs({
          originalQuery: old_query,
          dymQueryClicked: query,
          logId: logId,
        })
        // console.log('searchQuery category hit !!!!');
        searchQuery({
          query,
          search_type: 'fund_category',
        });
      }
    }
  }

  render() {
    const { imageValidPath } = this.state;
    const { card, index, checkPopOpen, firstPop, firstPopIndex, highlightText, funds } = this.props;
    const weightChartData = highlightText.popupHighLightEnable ? highlightText.popupHighLightEnable : null;
    const thm_txt = cn(card, 'thm_txt');
    let value_stk = cn(card, 'value_stk');
    let index_search_text = cn(card, 'index_search_text');
    let index_search_text_parse = [];

    const highlightValue = weightChartData !== null && weightChartData.length > 0 ? card[weightChartData[0].data] : 1;
    // console.log(value_stk);
    if (value_stk) {
      try {
        let stk_split = value_stk.split('$$$$$');
        let stk_parse = [];

        stk_split.map(e => {
          let text, company;
          let row_split = e.split('##');
          if (row_split.includes('text') && row_split.includes('company')) {
            const STKObject = getSTKData(row_split);
            if (STKObject) {
              if (typeof STKObject.text !== 'undefined' && typeof STKObject.company !== 'undefined') {
                stk_parse.push({
                  text: STKObject.text.value,
                  company: STKObject.company.value,
                  less_description: STKObject.startend.value
                });
              }
            }
          }
          else {
            throw Error('wrong text');
          }
        });
        value_stk = stk_parse;
      } catch (e) {
        value_stk = null;
      }
    }

    if (index_search_text) {
      try {
        let index_search_text_split = index_search_text.split('$$$$$');

        index_search_text_split.map(e => {
          let text, company;
          let row_split = e.split('##');
          if (row_split.includes('text') && row_split.includes('company')) {
            const indexSearchText = getIndexSearchTextData(row_split);
            // console.log(indexSearchText);

            if (indexSearchText) {
              if (typeof indexSearchText.text !== 'undefined' && typeof indexSearchText.company !== 'undefined') {
                index_search_text_parse.push({
                  text: indexSearchText.text.value,
                  company: indexSearchText.company.value,
                  less_description: indexSearchText.text.value,
                });
              }
              // console.log(index_search_text_parse);
            }
          }
          else {
            throw Error('wrong text');
          }
        });
        // value_stk = index_search_text_parse;
      } catch (e) {
        value_stk = null;
      }
    }

    if (index_search_text_parse.length > 0) {
      value_stk = index_search_text_parse;
    }

    const letNameWillBe = cn(card, 'Name2') || cn(card, 'Name');
    const filterNameBySemiColon = letNameWillBe;
    const letPopOverNameWillbe = cn(card, 'Name');
    const filterPopOverNameWillbe = letPopOverNameWillbe;
    const cardNameStyles = {
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    };
    //<TruncatedText text={filterNameBySemiColon} lines={2} /> old logic for CardName

    const E1 = ({ onClick }) => (
      <React.Fragment>
        <div className="pb-1 d-flex justify-content-between" onClick={onClick}>
          {(imageValidPath && cn(card, 'Sponsor')) ? (
            <img src={sponsorLogo(cn(card, 'Sponsor'))} className="img-fluid" alt={cn(card, 'Sponsor')} style={{ height: '20px' }} />
          ) : (
              <div className="sponsor-logo-placeholder" title={cn(card, 'Sponsor') || ''}>{cn(card, 'Sponsor') || ''}</div>
            )}
          {/*cn(card, 'Sponsored') && <h6 className="badge badge-info mb-0 ml-2">{'Ad'}</h6>*/}
        </div>
        <h5 className="mb-1 text-ssf-blue-d2 text-overflow-ellipse-line-2" style={{ fontWeight: 600 }}>
          {filterNameBySemiColon}
        </h5>
        {/* <h5 className="mb-0 text-gray-6" >
          <TruncatedText text={cn(card, 'Category')} lines={2} />
        </h5> */}
      </React.Fragment>
    );

    const Category = () => (
      <div>
          <h5 className="mb-0 text-gray-6"
          onClick={() => {
          // this.props.history.push('/securities');
          this.queryHandler(cn(card, 'Category'));
        }} style={{cursor:'pointer'}}>
          <span>{cn(card, 'Category')}</span>
        </h5>
      </div>
    )

    const E2 = () => (
      <React.Fragment>
        <PopoverBody className="p-0 media-popover">
          <Row className="no-gutters text-gray-7">
            <Col className="col-12 p-2 pt-3 br-radius">
              <h4 className="fs-17 text-ssf-blue-d2 mb-1">{filterPopOverNameWillbe}</h4>
              {/* <div className="fs-13 text-justify mt-2">{cn(card, 'short_desc')}</div> */}
              <div className="shw-container btn-white text-justify">
                <input type="text" data-value="short_desc" readOnly value={cn(card, 'short_desc')} hidden={true} />
                <span className="fs-13 text-justify mt-2 show-less text-ellipse" id="short_desc" >
                  {cn(card, 'short_desc')}
                </span>
                <button className="shw-ls-btn fs-13" id="btn-white" type="button" data-index="short_desc" onClick={this.toggle} style={{ backgroundColor: '#fff !important' }}>... more</button>
              </div>
            </Col>
            {thm_txt && thm_txt != "nan" && thm_txt != "NaN" &&
              <Col className="col-12 p-2 bg-light br-radius">
                <h6 className="font-weight-semi-bold mb-1 text-blue-info">Theme information from fund prospectus</h6>
                {/* <div className="fs-13 text-justify">{thm_txt}</div> */}
                <div className="shw-container">
                  <input type="text" data-value="thm_txt" value={thm_txt} hidden="true" />
                  <span className="fs-13 text-justify mt-2 show-less text-ellipse" id="thm_txt" >
                    {thm_txt}
                  </span>
                  <button className="shw-ls-btn fs-13" type="button" data-index="thm_txt" onClick={this.toggle} style={{ backgroundColor: '#f8f9fa !important' }}>... more</button>
                </div>
              </Col>}
            {value_stk && value_stk != "nan" && value_stk != "NaN" &&
              <Col className="col-12 p-2 bg-light br-radius">
                <h6 className="font-weight-semi-bold mb-1 text-blue-info">
                  {(index_search_text_parse.length > 0) ? (
                    <>Exposure information from annual reports of underlying holdings</>
                  ) : (
                    <>Theme information from annual reports of underlying holdings</>
                  )}
                </h6>
                {value_stk.map((v, i) => (
                  // <div key={v.company} className="fs-13 text-justify">
                  //   <strong>{convertToCapitalCase(v.company)}:</strong> <span dangerouslySetInnerHTML={{ __html: v.text }} /></div>
                  <div key={v.company} className="fs-13 text-justify">
                    <strong>{convertToCapitalCase(v.company)}:</strong>
                    <div className="shw-container">
                      <input type="text" data-value={`text-${i}`} value={v.text} data-less={v.less_description} hidden="true" />
                      <input type="text" data-value={`text-${i}-less`} value={v.less_description} hidden="true" />
                      <span className="fs-13 text-justify mt-2 show-less text-ellipse" id={`text-${i}`} dangerouslySetInnerHTML={{ __html: v.text }} >

                      </span>
                      <button className="btn-white shw-ls-btn" type="button" data-index={`text-${i}`} data-head="value_stk" onClick={this.toggle} style={{ backgroundColor: '#fff !important' }}>... more</button>
                    </div>
                  </div>
                ))}
              </Col>}
            {
              weightChartData && weightChartData.length > 0 && highlightValue > 0 &&
              <Col className="col-12 p-2s bg-light br-radius">
                {weightChartData.map((item, i) =>
                  <Col className="col-12">
                    <span className="font-weight-semi-bold mb-1 text-blue-info">{item['Name of the chart on UI']}</span><span className="mb-1">: {item['name']}</span>
                  </Col>
                )}
              </Col>
            }
          </Row>
        </PopoverBody>
        {this.truncString()}
      </React.Fragment>
    );

    const E3 = () => (
      <React.Fragment>
        <PopoverBody className="p-0 media-popover">
          <Row className="no-gutters text-gray-7">
            <Col className="col-12 p-2 pt-3">
              <h4 className="fs-17 text-ssf-blue-d2 mb-1">{cn(card, 'Name')}</h4>
              <div className="fs-13 text-justify mt-2">{cn(card, 'short_desc')}</div>
            </Col>
            {
              weightChartData && weightChartData.length > 0 && highlightValue === 1 &&
              <Col className="col-12 p-2 bg-light">
                <span className="font-weight-semi-bold mb-1 text-blue-info">Weighting Strategy</span><span className="mb-1">: {card.weighting_scheme_desc}</span>
              </Col>
            }
            {thm_txt && thm_txt != "nan" && thm_txt != "NaN" &&
              <Col className="col-12 p-2 bg-light">
                <h6 className="font-weight-semi-bold mb-1 text-blue-info">Theme information from fund prospectus</h6>
                <div className="fs-13 text-justify">{thm_txt}</div>
              </Col>}
            {value_stk && value_stk != "nan" && value_stk != "NaN" &&
              <Col className="col-12 p-2 bg-light">
                <h6 className="font-weight-semi-bold mb-1 text-blue-info">Theme information from annual reports of underlying holdings</h6>
                {value_stk.map(v => (
                  <div key={v.company} className="fs-13 text-justify">
                    <strong>{convertToCapitalCase(v.company)}:</strong> <span dangerouslySetInnerHTML={{ __html: v.text }} /></div>
                ))}
              </Col>}
          </Row>
        </PopoverBody>
      </React.Fragment>
    );

    if (document.querySelectorAll(`NamePopover-${cn(card, 'Id')}`)) {
      // console.log(document.querySelectorAll(`NamePopover-${cn(card, 'Id')}`));
      const WithPopover = withPopover(E1, E2, { id: "NamePopover-" + cn(card, 'Id'), placement: 'right', index, checkPopOpen, firstPop, firstPopIndex, namePopClass: 'namePop', trigger: 'click', highlightValue, funds, card,  popUp: 'Fund Details Popup' })
      if (window.screen.width > 760)
        return (
          <React.Fragment>
            <WithPopover />
            <Category/>
        </React.Fragment>
        );
    }

    return (
      <React.Fragment>
        <E1 onClick={() => this.props.toggleDetailsModal(E3)} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ funds }) => ({
  query: funds.query,
})

const mapDispatchToProps = {
  searchQuery,
  setFundsState,
  dymLogs,
  fundsApiCallTime,
  clearFundsApiLoadPercent,
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NamePopover)

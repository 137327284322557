import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

export const ShareFunds = ({ onClickShare }) => {
  return (
    <div style={{display:'flex', alignItems: 'center'}}>
       {/* <div className="share-component__wrapper"></div> */}
      <span className="label"  style={{marginRight:'7px', marginBottom: 0}}>Share</span>
      <span style={{marginRight:'7px', marginTop: '2px'}}>
        <a className="share-component__item" key="1" id="Embed" data-item="Embed" onClick={onClickShare} style={{backgroundColor:'white'}}>
          {/* <i className="far fa-globe" style={{color:'#55a9e8'}}></i> */}
          <img src="/assets/images/world.svg"/>
        </a>
        <UncontrolledTooltip placement="bottom" target="Embed" className="share-component__tooltip">
          Share on your blog or website
        </UncontrolledTooltip>
      </span>
      <span style={{marginRight:'7px', marginTop: '2px'}}>
        <a className="share-component__item" key="2" id="Mail" data-item="Mail" onClick={onClickShare} style={{backgroundColor:'white'}}>
          {/* <i className="far fa-envelope" style={{color:'#55a9e8'}}></i> */}
          <img src="/assets/images/email.svg"/>
        </a>
        <UncontrolledTooltip placement="bottom" target="Mail" className="share-component__tooltip">
          Share using mail
        </UncontrolledTooltip>
      </span>
      <span style={{marginTop: '2px'}}>
        <a className="share-component__item" key="3" id="Twitter" data-item="Twitter" onClick={onClickShare} style={{backgroundColor:'white'}}>
          {/* <i className="fab fa-twitter" style={{color:'#55a9e8'}}></i> */}
          <img src="/assets/images/twitterN.svg"/>
        </a>
        <UncontrolledTooltip placement="bottom" target="Twitter" className="share-component__tooltip">
          Share using twitter
        </UncontrolledTooltip>
      </span>
    </div>
  );
}

export default {
  ShareFunds,
}

import React, { useContext } from 'react'
// import { Row, Col, Table } from 'reactstrap';

import MdlEnhancerContext from '../Context/MdlEnhancerContext'

import PfCardWrapper from '../Components/PfCardWrapper'
import { withHeaderFooter } from '../Shared/HeaderFooter'

const OtherReplacementsPage = withHeaderFooter(props => {
  let {
    modelEnhancement: { current, enhanced, otherEnhancements }
  } = useContext(MdlEnhancerContext)
  otherEnhancements = otherEnhancements.filter(
    item => item.portfolioName !== enhanced.portfolioName
  )
  otherEnhancements = otherEnhancements.slice(0, 3)
  const holdingsCutOff = 5
  return (
    <React.Fragment>
      <div className="content">
        <div className="pr-v2-header">
          <h3>
            Additional Details of Suggested Replacements for{' '}
            <h3 style={{
              maxWidth: '70%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>{current.portfolioName}</h3>
          </h3>
        </div>
        <div className="mdl-enh-rep-sub-header">
          <h4>
            {!otherEnhancements.length
              ? `We couldn't find any other replacement suggestions for the portfolio.`
              : `Other Possible Replacements`}
          </h4>
        </div>
        {otherEnhancements.length !== 0 && (
          <div className="other-rep-section">
            {otherEnhancements &&
              otherEnhancements.map((item, index) => {
                return (
                  <div className="other-rep-wrapper" key={index}>
                    <PfCardWrapper
                      data={item}
                      type={'others'}
                      divider={4} // Space between cards in pixels
                      holdingsCutOff={holdingsCutOff}
                    />
                  </div>
                )
              })}
          </div>
        )}
      </div>
    </React.Fragment>
  )
})
export default OtherReplacementsPage

import React from 'react';
import { connect } from 'react-redux';
import { Input } from 'antd';
import { Nav, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Modal, ModalBody, ButtonGroup } from 'reactstrap';

import PremiumNoticeModal from 'components/PremiumNoticeModal';
// import CSVPreviewUpload from 'components/CSVPreviewUpload';
import CSVPreviewUpload from 'components/CSVUpload';
import CustomAlert from 'components/CustomAlert';
import { Notification } from 'shared-components'
import { uniqValues, mapCsvToJSON, addEventToAnalytics, findDelimiter, convertToUpperCase, convertToLowerCase, updateTickerData, calculatePortfolioDataFormat } from 'Utils'
import { numberCheck } from 'layouts/utils';
import { createLoadingSelector } from 'selectors/loading-selector';

import { getPortfolioStats } from 'selectors/investment'
import { investmentOptions, fetchPortfolioData, deletePortfolio, uploadPortfolio, getRealTimePriceForTickersRequest } from 'actions/investment';
import { subscriptionPlan, setProfileState } from 'actions/profile';
import {
  setEnhancerState,
  checkTickerRequest,
  checkSingleTickerValidityRequest,
  uploadCSVRequest,
  verifyCSVTickerAndPriceRequest,
  uploadCSVFileRequest,
  uploadCSVFileFetch,
  uploadCSVFileSuccess,
  uploadPortfolioDataByIdRequest,
  uploadPortfolioCSVRequest
} from 'actions/enhancer';

import UploadFile from './UploadFile';
import { Loader } from '../../Elements/Loader';
import { demoPortfolioJSON } from './utils';

import _ from 'lodash';

class UploadSection extends React.PureComponent {
  Watchlist = { name: 'Watchlist', code: 'watchlist' }
  UploadPortfolio = { label: 'Upload Portfolio', name: 'Portfolio', code: 'uplPrt' }

  constructor(props) {
    super(props);
    this.state = {
      portfolioDropdownOpen: false,
      uplPrtModal: false,
      notify: false,
      isNotifyOpen: false,
      csvVisible: false,
      csvDataList: [],
      validTickers: [],
      invalidTickers: [],
      csvFormatErrorMessage: '',
      messageModal: false,
      uploadFileFor: '',
      emptyCSVModal: false,
      portfolioValueWillBe: props.portfolioValue,
      allocationTypeValue: null,
      weightCalculatedFromCSVBy: null,
    };
    this.portfolioNameInput = React.createRef();
  }

  // componentWillReceiveProps(newProps) {
  //   if (((newProps.tickerNotExist && newProps.tickerNotExist.length > 0) || (newProps.tickerNotSupported && newProps.tickerNotSupported.length > 0)) && !this.state.messageModal) {
  //     this.setState({ messageModal: false, csvVisible: true });
  //   }
  // }

  uploadFileFor = () => {
    const { fileFor, portfolioID } = this.props;
    this.setState({ uploadFileFor: fileFor });
    switch (fileFor) {
      case 'Demo_portfolio':
        this.uploadFileForDemo();
      break;
      case 'MarketPlace_Portfolio':
        if (document.getElementById('sponsored-portfolio-dropdown')) {
          document.getElementById('sponsored-portfolio-dropdown').click();
        }
        this.uploadFileForMarketPlace(portfolioID);
      break;
      case 'Client_Portfolio':
        if (document.getElementById('clientSearch')) {
          document.getElementById('clientSearch').click();
        }
        this.uploadFileForClientPortfolio(portfolioID);
      break;
      case 'Custom_portfolio':
        this.openUplPrt();
      break;
      default:
    }
  }

  uploadFileForClientPortfolio = (selectedClientPortfolio) => {
    const { uploadPortfolioDataByIdRequest, uploadCSVFileSuccess, verifyCSVTickerAndPriceRequest } = this.props;
    // NOTE: Step: 1, fetch Holdings data for selected MarketPlace ID
    try {
      if (selectedClientPortfolio && selectedClientPortfolio.original_portfolio && selectedClientPortfolio.name) {
        const { name: _clientPortfolioName, original_portfolio_id: _selectedClientPortfolioId, original_portfolio: _selectedClientPortfolioTicker } = selectedClientPortfolio;
        if (uploadPortfolioDataByIdRequest) {
          const requestObj = {
            portfolioType: 'Client Portfolio',
            filename: _selectedClientPortfolioTicker,
            selectedPortfolioName: _clientPortfolioName,
            selectedPortfolioTicker: _selectedClientPortfolioTicker,
            selectedPortfolioId: _selectedClientPortfolioId,
          };
          uploadPortfolioDataByIdRequest(requestObj, (res) => {
            if (res && res.csvFormatPortfolioData && res.csvFormatPortfolioData.length > 0 && res.tickers && typeof res.realTimePrice !== 'undefined') {
              // console.log(res);
              const { tickers, realTimePrice, csvFormatPortfolioData } = res;
              this.setState({
                JSONData: csvFormatPortfolioData,
              }, () => {
                // NOTE: Step: 2, Map 'verifyCSVTickerAndPriceRequest' data with 'JSONData';
                if(tickers && tickers.length > 0) {
                  this.mapRealTimeToJSONData(realTimePrice);
                } else {
                  throw `-- To verifyCSVTickerAndPriceRequest, tickers ${tickers} is not valid --`;
                }
              });
            } else {
              throw `-- To fetch data, sponsored ticker ${res.tickers} or realTimePrice ${res.realTimePrice} or portfolio funds ${res.csvFormatPortfolioData} is missing --`;
            }
          });
        } else {
          throw `-- Redux actions uploadPortfolioDataByIdRequest missing --`;
        }
      } else if (selectedClientPortfolio && selectedClientPortfolio.name && selectedClientPortfolio.name === 'Demo Account') {
        console.log('=== Upload demo portfolio for client accounts ===');
        this.onFileSuccess(selectedClientPortfolio);
      } else {
        throw `-- To fetch data, sponsored ticker ${selectedClientPortfolio} is missing --`;
      }
    } catch (e) {
      console.log(e);
    }
  }

  uploadFileForMarketPlace = (selectedMarketPlaceData) => {
    // console.log('--------- uploadFileForMarketPlace ----------');
    // console.log('selectedMarketPlaceData =>', selectedMarketPlaceData);
    const { uploadPortfolioDataByIdRequest, uploadCSVFileSuccess, verifyCSVTickerAndPriceRequest } = this.props;
    // NOTE: Step: 1, fetch Holdings data for selected MarketPlace ID
    try {
      if (selectedMarketPlaceData && selectedMarketPlaceData.ticker && selectedMarketPlaceData.name) {
        const { name: _sponsoredPortfolioName, ticker: _selectedMarketPlaceId } = selectedMarketPlaceData;
        if (uploadPortfolioDataByIdRequest) {
          const requestObj = {
            portfolioType: 'MarketPlace',
            filename: _selectedMarketPlaceId,
            selectedPortfolioName: _sponsoredPortfolioName,
          };
          uploadPortfolioDataByIdRequest(requestObj, (res) => {
            if (res && res.csvFormatPortfolioData && res.csvFormatPortfolioData.length > 0 && res.tickers && typeof res.realTimePrice !== 'undefined') {
              const { tickers, realTimePrice, csvFormatPortfolioData } = res;
              this.setState({
                JSONData: csvFormatPortfolioData,
              }, () => {
                // NOTE: Step: 2, Map 'verifyCSVTickerAndPriceRequest' data with 'JSONData';
                if(tickers && tickers.length > 0) {
                  this.mapRealTimeToJSONData(realTimePrice);
                } else {
                  throw `-- To verifyCSVTickerAndPriceRequest, tickers ${tickers} is not valid --`;
                }
              });
            } else {
              throw `-- To fetch data, sponsored ticker ${res.tickers} or realTimePrice ${res.realTimePrice} or portfolio funds ${res.csvFormatPortfolioData} is missing --`;
            }
          });
        } else {
          throw `-- Redux actions uploadPortfolioDataByIdRequest missing --`;
        }
      } else {
        throw `-- To fetch data, sponsored ticker ${selectedMarketPlaceData} is missing --`;
      }
    } catch (e) {
      console.log(e);
    }
  }

  uploadFileForDemo = () => {
    addEventToAnalytics('Enhancer Demo','Enhancer Demo','ENHANCER_DEMO',{},false);
    // console.log('--------- uploadFileForDemo ----------');
    if (demoPortfolioJSON) {
      // NOTE: Step: 1, call 'verifyCSVTickerAndPriceRequest' api for tickers
      this.setState({
        JSONData: demoPortfolioJSON,
      }, () => {
        this.processDemoPortfolioData(this.state.JSONData);
      });
    }
  }

  processDemoPortfolioData = (JSONData) => {
    const { uploadCSVFileSuccess } = this.props;
    let tickers = demoPortfolioJSON.map((r) => r.ticker);
    // NOTE: Step: 2, Map 'verifyCSVTickerAndPriceRequest' data with 'JSONData';
    if(tickers && tickers.length > 0){
      tickers = tickers.join();
      const { verifyCSVTickerAndPriceRequest } = this.props;
      if (verifyCSVTickerAndPriceRequest) {
        verifyCSVTickerAndPriceRequest({ tickers }, this.mapRealTimeToJSONData);
      }
    }
  }

  mapRealTimeToJSONData = (realTimeTickerPrice) => {
    // NOTE:
    /*
    given input of each row:
    {
      "ticker": "ACWI",
      "price": 74.53924915,
      "shares": 1172,
      "date": "25-07-2019"
    }

    After mapping expected output of each row sample:
    {
      date: "25-07-2019"
      price: 74.53924915
      realTimePrice: 80.8562
      shares: 1172
      ticker: "ACWI"
      values: 94763.4664
      weight(%): 11.87388352446596
    }
    */
    if (realTimeTickerPrice) {
      const { funds } = realTimeTickerPrice;
      let sumOfPriceAndShares = 0;
      const { portfolioValue, magnifiTickers, mode } = this.props;
      // console.log('this.state.JSONData ==> ', this.state.JSONData);
      let mappedArray = calculatePortfolioDataFormat(this.state.JSONData, realTimeTickerPrice, portfolioValue);
      // console.log('mappedArray ==> ', mappedArray);
      if (magnifiTickers && magnifiTickers.length > 0) {
        mappedArray.forEach((item, i) => {
          if (item.ticker) {
            const foundTicker = magnifiTickers.find((kl) => kl.ticker === item.ticker);
            if (foundTicker) {
              item.name = foundTicker.name;
            }
          }
        });
      }

      if (mappedArray && mappedArray.length > 0) {
        if (mode === 'create') {
          this.setState({ emptyCSVModal: true });
        } else {
          this.setState({ csvVisible: true });
        }
        this.setState({ csvDataList: mappedArray, csvFormatErrorMessage: '', uplPrtModal: false }, () => {
          this.showOnlyTickersAvailable();
        });
      } else {
        console.log('-- Failed to map and classify valid/invallid CSV data --');
      }
    }
  }

  openUplPrt = () => {
    const { mode } = this.props;
    // console.log('-==== uploadFileFor ====-', mode);
    addEventToAnalytics('Enhancer Upload', 'Enhancer Upload', 'ENHANCER_UPLOAD', {}, false);
    // console.log('---- mode ----> ', mode);
    if (mode === 'create') {
      this.setState({ emptyCSVModal: true });
    } else {
      this.setState({ uplPrtModal: true });
    }
  }

  openNotify = () => {
    this.setState({
      notify: true,
    });
  }

  notifyToggle = () => {
    this.setState({
      notify: !this.state.notify,
    });
  }

  onFileSuccess = (responseOBJ) => {
    this.setState({ uplPrtModal: false, emptyCSVModal: false, isNotifyOpen: true }, () => {
      this.csvOnClose();
      const { onFileSuccess } = this.props;
      if (onFileSuccess) {
        onFileSuccess(responseOBJ);
      }
      setTimeout(() => {
        this.setState({ isNotifyOpen: false })
      }, 3000);
    })
  }

  getCSVData = (data, fileList) => {
    const { uploadPortfolioCSVRequest } = this.props;
    if (data && data.name) {
      var reader = new FileReader();
      reader.onload = () => {
        if (uploadPortfolioCSVRequest) {
          let formData = new FormData();
          formData.append("file", data);
          formData.append("name", data.name);
          uploadPortfolioCSVRequest(formData, (response) => {
            // console.log('uploadPortfolioCSVRequest response', response);
            let validTickers = [], invalidTickers = [];
            if (response) {
              this.processCsvPreviewDataFromAPI(response)
            } else {
              console.log('-- response issue --');
            }
          });
        }
        // NOTE: to enable UI end CSV conversion and preview logic, uncomment below line
        // this.processUploadData(reader.result)
      };
      // start reading the file. When it is done, calls the onload event defined above.
      reader.readAsBinaryString(data);
    }
  }

  processCsvPreviewDataFromAPI = (clientList, tickerNamesForCSVPreview = [], noData = false) => {
    // const filters = { headersArray: ['ticker','price','shares','date','lot date','weight','amount'], maxHeadersCount: 6, addHeader: ['weight(%)'] };
    const { portfolioValue, mode } = this.props;
    // let clientList = JSON.parse(mapCsvToJSON(this.state.csvData, filters, realTimeTickerPrice, noData, portfolioValue));
    // console.log('clientList-- >', clientList);
    if (typeof clientList.error !== 'undefined') {
      this.setState({
        csvFormatErrorMessage: clientList.error || '',
      });
    } else {
      let weightCalculatedFromCSVBy = this.state.weightCalculatedFromCSVBy;
      let headerRow = clientList.filter((item) => (item.ticker && item.ticker !== '' && item.ticker === 'ticker'));
      let filteredClientList = clientList.filter((item) => (item.ticker && item.ticker !== '' && item.ticker !== 'ticker'));

      // NOTE: tickerNamesForCSVPreview is an array which has valid-tickers with NAME
      if (tickerNamesForCSVPreview && typeof tickerNamesForCSVPreview !== 'undefined' && tickerNamesForCSVPreview.length > 0) {
        filteredClientList = filteredClientList.map((kl) => {
          if (kl && kl.ticker) {
            const found = tickerNamesForCSVPreview.find((j) => j.ticker === kl.ticker)
            if (found && found.name && typeof found.name !== 'undefined') {
              kl.name = found.name;
            } else {
              kl.name = null;
            }
            weightCalculatedFromCSVBy = kl.weight_calculated_format;
          }
          return kl;
        }).filter((item) => item)
      }

      if (mode === 'create') {
        this.setState({ emptyCSVModal: true });
      } else {
        this.setState({ csvVisible: true });
      }

      let sumOfAllWeights = filteredClientList.filter((item) => item.original_weight !== 'NA' && item.original_weight !== '' && typeof item.original_weight !== 'undefined').map((item) => item.original_weight).reduce((a, b) => a + b, 0);
      let sumOfAllValues = filteredClientList.filter((item) => item.values !== 'NA' && item.values !== '' && typeof item.values !== 'undefined').map((item) => item.values).reduce((a, b) => a + b, 0);
      let _portfolioValueWillBe = this.props.portfolioValue;
      // console.log(headerRow);

      if (headerRow &&
        headerRow.length > 0 &&
        headerRow[0] &&
        Object.keys(headerRow[0]).length > 0 &&
        filteredClientList &&
        filteredClientList.length > 0 &&
        filteredClientList[0] &&
        typeof filteredClientList[0].weight_calculated_format !== 'undefined'
      ) {
        if (Object.keys(headerRow[0]).length > 0) {
          let rows = Object.keys(headerRow[0]);
          let portfolioValueBySMV = rows.find((item) => item === 'shares' || item === 'amount' || item === 'values')
          if (portfolioValueBySMV) {
            // console.log(' --- portfolioValueBySMV ---> ', portfolioValueBySMV);
            // set portfolioValue by sum of all amounts (bcoz shares||amount||values present in csv)
            _portfolioValueWillBe = sumOfAllValues
          } else {
            _portfolioValueWillBe = this.props.portfolioValue
          }
        }
        // _portfolioValueWillBe = // allData[0].weight_calculated_format === 'weight' ? this.props.portfolioValue : sumOfAllValues;
      }

      // console.log('portfolioValueWillBe --> ', _portfolioValueWillBe);
      if (!weightCalculatedFromCSVBy && filteredClientList && filteredClientList[0] && filteredClientList[0].weight_calculated_format) {
        weightCalculatedFromCSVBy = filteredClientList[0].weight_calculated_format;
      }

      this.setState({
        weightCalculatedFromCSVBy,
        csvDataList: filteredClientList,
        csvFormatErrorMessage: '',
        uplPrtModal: false,
        portfolioValueWillBe: _portfolioValueWillBe,
        allocationTypeValue: (weightCalculatedFromCSVBy === 'values' || weightCalculatedFromCSVBy === 'value' || weightCalculatedFromCSVBy === 'amount') ? 'value' : 'weight', // 'value' for toggle "&" "%" in csv preview modal
      }, () => {
        // console.log('allocationTypeValue --> ', this.state.allocationTypeValue, weightCalculatedFromCSVBy);
        this.categorizeTickers();
      });
    }
  }

  categorizeTickers = () => {
    // const { tickerNotExist, tickerNotSupported } = this.props;
    const { csvDataList } = this.state;
    let validTickers = [];
    let invalidTickers = [];
    csvDataList.forEach((u, i) => {
      if (!u.invalid && u.invalidType === 'supported') {
        u.key = i;
        u.validTicker = true;
        u.invalidType = 'supported';
        validTickers.push(u);
      } else {
        if (u.invalidType === 'unrecognized') {
          u.invalidType = 'unrecognized';
          u.message = 'Ticker not identified';
        } else if (u.invalidType === 'unsupported') {
          u.invalidType = 'unsupported';
          u.message = 'Ticker not supported';
        }
        u.key = i;
        u.validTicker = false;
        u.showInMissingTicker = true;
        invalidTickers.push(u);
      }
    });

    this.setState({
      validTickers,
      invalidTickers,
    })
  }


  processUploadData = (csvData) => {
    const { uploadCSVFileSuccess, getRealTimePriceForTickersRequest, setEnhancerState } = this.props;
    this.setState({
      csvDataList: [],
      messageModal: false,
      csvVisible: false,
      emptyCSVModal: false,
    }, () => {
      setEnhancerState({ tickerNotExist: [], tickerNotSupported: [] });
    });

    let lines = csvData.split("\n");
    const identified_delimiter = findDelimiter(csvData);
    let tickers = '';
    let headers = lines[0].split(identified_delimiter);
    headers = headers.map(item => convertToLowerCase(item));
    let tickerIndex = headers.indexOf('ticker');

    /* eslint-disable */
    for (let i=1; i<lines.length; i++) {
      let currentline = lines[i].split(identified_delimiter);
      if(currentline[tickerIndex]){
        tickers = i == 1 ? tickers+convertToUpperCase(currentline[tickerIndex]) : tickers+','+convertToUpperCase(currentline[tickerIndex])
      }
    }

    this.setState({
      csvData
    }, () => {
      const { uploadCSVFileSuccess } = this.props;
      if (uploadCSVFileSuccess) {
        uploadCSVFileSuccess();
      }
      if(tickers){
        const { verifyCSVTickerAndPriceRequest } = this.props;
        if (verifyCSVTickerAndPriceRequest) {
          verifyCSVTickerAndPriceRequest({ tickers }, this.buildClientList);
        }
      }else{
        this.buildClientList([], [], true)
      }
    });
  }

  buildClientList = (realTimeTickerPrice, tickerNamesForCSVPreview = [], noData = false) => {
    const filters = { headersArray: ['ticker','price','shares','date','lot date','weight','amount'], maxHeadersCount: 6, addHeader: ['weight(%)'] };
    const { portfolioValue, mode } = this.props;
    let clientList = JSON.parse(mapCsvToJSON(this.state.csvData, filters, realTimeTickerPrice, noData, portfolioValue));
    console.log('clientList-- >', clientList);
    if (typeof clientList.error !== 'undefined') {
      this.setState({
        csvFormatErrorMessage: clientList.error || '',
      });
    } else {
      let weightCalculatedFromCSVBy = this.state.weightCalculatedFromCSVBy;
      let headerRow = clientList.filter((item) => (item.ticker && item.ticker !== '' && item.ticker === 'ticker'));
      let filteredClientList = clientList.filter((item) => (item.ticker && item.ticker !== '' && item.ticker !== 'ticker'));
      if (tickerNamesForCSVPreview && typeof tickerNamesForCSVPreview !== 'undefined' && tickerNamesForCSVPreview.length > 0) {
        filteredClientList = filteredClientList.map((kl) => {
          if (kl && kl.ticker) {
            const found = tickerNamesForCSVPreview.find((j) => j.ticker === kl.ticker)
            if (found && found.name && typeof found.name !== 'undefined') {
              kl.name = found.name;
            } else {
              kl.name = null;
            }
            weightCalculatedFromCSVBy = kl.weight_calculated_format;
          }
          return kl;
        }).filter((item) => item)
      }
      if (mode === 'create') {
        this.setState({ emptyCSVModal: true });
      } else {
        this.setState({ csvVisible: true });
      }

      let sumOfAllWeights = filteredClientList.filter((item) => item.original_weight !== 'NA' && item.original_weight !== '' && typeof item.original_weight !== 'undefined').map((item) => item.original_weight).reduce((a, b) => a + b, 0);
      let sumOfAllValues = filteredClientList.filter((item) => item.values !== 'NA' && item.values !== '' && typeof item.values !== 'undefined').map((item) => item.values).reduce((a, b) => a + b, 0);
      let _portfolioValueWillBe = this.props.portfolioValue;
      // console.log(headerRow);

      if (headerRow &&
        headerRow.length > 0 &&
        headerRow[0] &&
        Object.keys(headerRow[0]).length > 0 &&
        filteredClientList &&
        filteredClientList.length > 0 &&
        filteredClientList[0] &&
        typeof filteredClientList[0].weight_calculated_format !== 'undefined'
      ) {

        if (Object.keys(headerRow[0]).length > 0) {
          let rows = Object.keys(headerRow[0]);
          let portfolioValueBySMV = rows.find((item) => item === 'shares' || item === 'amount' || item === 'values')
          if (portfolioValueBySMV) {
            // console.log(' --- portfolioValueBySMV ---> ', portfolioValueBySMV);
            // set portfolioValue by sum of all amounts (bcoz shares||amount||values present in csv)
            _portfolioValueWillBe = sumOfAllValues
          } else {
            _portfolioValueWillBe = this.props.portfolioValue
          }
        }
        // _portfolioValueWillBe = // allData[0].weight_calculated_format === 'weight' ? this.props.portfolioValue : sumOfAllValues;
      }

      console.log('portfolioValueWillBe --> ', _portfolioValueWillBe);

      this.setState({
        weightCalculatedFromCSVBy,
        csvDataList: filteredClientList,
        csvFormatErrorMessage: '',
        uplPrtModal: false,
        portfolioValueWillBe: _portfolioValueWillBe,
        allocationTypeValue: (weightCalculatedFromCSVBy === 'values' || weightCalculatedFromCSVBy === 'value' || weightCalculatedFromCSVBy === 'amount') ? 'value' : 'weight', // 'value' for toggle "&" "%" in csv preview modal
      }, () => {
        // const tickersList = this.state.csvDataList.map((u) => {
        //   if (u.ticker && u.ticker !== '' && u.ticker !== 'ticker') {
        //     return u.ticker;
        //   }
        // });
        this.showOnlyTickersAvailable();
      });
    }
  }

  showOnlyTickersAvailable = () => {
    const { tickerNotExist, tickerNotSupported } = this.props;
    const { csvDataList } = this.state;
    let validTickers = [];
    let invalidTickers = [];
    csvDataList.forEach((u, i) => {
      if (typeof tickerNotExist.find((k) => k === u.ticker) === 'undefined' && typeof tickerNotSupported.find((k) => k === u.ticker) === 'undefined') {
        u.key = i;
        u.validTicker = true;
        u.invalidType = 'supported';
        validTickers.push(u);
      } else {
        if (typeof tickerNotExist.find((k) => k === u.ticker) !== 'undefined') {
          u.invalidType = 'unrecognized';
          u.message = 'Ticker not identified';
        } else if (typeof tickerNotSupported.find((k) => k === u.ticker) !== 'undefined') {
          u.invalidType = 'unsupported';
          u.message = 'Ticker not supported';
        }
        u.key = i;
        u.validTicker = false;
        u.showInMissingTicker = true;
        invalidTickers.push(u);
      }
    });

    const allData = [...invalidTickers, ...validTickers];
    // console.log(allData);
    // let sumOfAllValues = allData.map((item) => item.values).reduce((a, b) => a + b, 0);
    // let _portfolioValueWillBe = sumOfAllValues;
    //
    // let sumOfAllWeights = allData.filter((item) => item.original_weight !== 'NA' && item.original_weight !== '' && typeof item.original_weight !== 'undefined').map((item) => item.original_weight).reduce((a, b) => a + b, 0);
    // let sumOfAllValues = allData.filter((item) => item.values !== 'NA' && item.values !== '' && typeof item.values !== 'undefined').map((item) => item.values).reduce((a, b) => a + b, 0);

    // if (allData && allData.length > 0 && allData[0] && typeof allData[0].weight_calculated_format !== 'undefined') {
    //   _portfolioValueWillBe = // allData[0].weight_calculated_format === 'weight' ? this.props.portfolioValue : sumOfAllValues;
    // }

    // console.log('portfolioValueWillBe --> ', _portfolioValueWillBe);

    this.setState({
      validTickers,
      invalidTickers,
      // portfolioValueWillBe: _portfolioValueWillBe,
    })
  }

  checkTicker = (ticker, callback) => {
    const { checkTickerRequest } = this.props;
    if (checkTickerRequest && ticker && callback) {
      checkTickerRequest({ tickers: [ticker] }, callback);
    }
  }

  csvOnClose = () => {
    this.setState({
      portfolioDropdownOpen: false,
      uplPrtModal: false,
      notify: false,
      isNotifyOpen: false,
      csvVisible: false,
      csvDataList: [],
      validTickers: [],
      invalidTickers: [],
      csvFormatErrorMessage: '',
      messageModal: false,
      uploadFileFor: '',
      emptyCSVModal: false,

      // csvVisible: false,
      // csvDataList: [],
      // validTickers: [],
      // invalidTickers: [],
      // emptyCSVModal: false,
      // uploadFileFor: '',
    }, () => {
      this.props.setEnhancerState({ tickerNotExist: [], tickerNotSupported: [] });
    });
  }

  handleUploadSubmit = () => {
    const updatedTickers = [...this.state.validTickers, ...this.state.invalidTickers];
    let validTickers = [];
    let invalidTickers = [];
    updatedTickers.forEach((u) => {
      if(u.showInMissingTicker){
        invalidTickers.push(u)
      }else{
        validTickers.push(u)
      }
    })
    this.setState({
      validTickers,
      invalidTickers
    }, () => this.csvOnClickOk(updatedTickers));
  }

  csvOnClickOk = (dataJson) => {
    if (dataJson) {
      const {
        onboardScreenData,
        originalCSVFormatPortfolioData,
        originalCSVFormatPortfolioTickers,
        sponsoredPortfolioName,
        uploadedPortfolioTicker,
        selectedPortfolioName,
        selectedPortfolioID,
      } = this.props;

      // console.log('allocationTypeValue ===> ', this.state.allocationTypeValue);
      dataJson.forEach((u, i) => {
        if (this.state.allocationTypeValue !== null) {
          u[`weight_calculated_format`] = this.state.allocationTypeValue === 'weight' ? 'weight' : 'values';
          u[`format_value`] = this.state.allocationTypeValue === 'weight' ? u.weight : u.values;
        };
      });

      const reqJSONTickers = dataJson.map((jk) => jk.ticker);
      const notMatchTickers = originalCSVFormatPortfolioTickers.filter((tick) => !reqJSONTickers.includes(tick));
      let EDITED = false;

      // NOTE: check if CSV file is edited or not
      originalCSVFormatPortfolioData.map((item) => {
        const found = dataJson.find(g => g.ticker === item.ticker);
        if (found && (found.weight !== item.weight || found.amount !== item.amount)) {
          EDITED = true;
        }
      });

      // console.log('EDITED -->', EDITED);

      if (onboardScreenData && onboardScreenData.selectedPortfolioType === 'MarketPlace') {
        // upload for Sponsored Portfolio
        // console.log(' ==== MarketPlace CSV data ==== ');
        // NOTE: commenting below logic, whick checks whether Preview List data is modified or not.
        // ------------------------------------------------------------------------------------------------------

        //step: if TICKER has changed (or edit operation performed)
        if (EDITED || notMatchTickers.length > 0) {
          // and not matched with the ones uploaded from dropdown then call 'upload-portfolio' api
          this.props.setEnhancerState({ tickerNotExist: [], tickerNotSupported: [] });
          this.setState({ messageModal: false });
          if (this.props.fileName !== '') {
            this.props.uploadCSVRequest({ data: dataJson, "file_name": this.props.fileName }, this.onFileSuccess);
          } else {
            this.props.uploadCSVRequest({ data: dataJson }, this.onFileSuccess);
          }
        } else {
          // console.log('"NO" change/update/edit operation performed');
          // or else "NO" change/update/edit operation performed, then call 'model-portfolio' api
          //by passing SPONSORED_PORTFOLIO_TICKER which was selected from dropdown
          if (uploadedPortfolioTicker && sponsoredPortfolioName) {
            const sponsoredObj = {
              ticker: uploadedPortfolioTicker,
              name: sponsoredPortfolioName,
            };
            // console.log(sponsoredObj);
            this.onFileSuccess(sponsoredObj);
          } else {
            console.log(`--- uploadedPortfolioTicker ${uploadedPortfolioTicker} or sponsoredPortfolioName ${sponsoredPortfolioName} is missing ---`);
          }
        }
        // ------------------------------------------------------------------------------------------------------


      } else if (onboardScreenData && onboardScreenData.selectedPortfolioType === 'Client Portfolio') {
        // upload for Client Portfolio

        //step: if TICKER has changed (or edit operation performed)
        if (EDITED || notMatchTickers.length > 0) {
          // and not matched with the ones uploaded from dropdown then call 'upload-portfolio' api
          this.props.setEnhancerState({ tickerNotExist: [], tickerNotSupported: [] });
          this.setState({ messageModal: false });
          if (this.props.fileName !== '') {
            this.props.uploadCSVRequest({ data: dataJson, "file_name": this.props.fileName }, this.onFileSuccess);
          } else {
            this.props.uploadCSVRequest({ data: dataJson }, this.onFileSuccess);
          }
        } else {
          // console.log('"NO" change/update/edit operation performed');
          // or else "NO" change/update/edit operation performed, then call 'model-portfolio' api
          //by passing CLIENT_PORTFOLIO_TICKER which was selected from dropdown
          if (uploadedPortfolioTicker &&
            selectedPortfolioName &&
            selectedPortfolioName !== '' &&
            typeof selectedPortfolioName !== 'undefined' &&
            selectedPortfolioID &&
            typeof selectedPortfolioID !== 'undefined' &&
            selectedPortfolioID !== ''
          ) {
            const clientRequestObj = {
              original_portfolio: uploadedPortfolioTicker,
              original_portfolio_id: selectedPortfolioID,
            };
            // console.log(clientRequestObj);
            this.onFileSuccess(clientRequestObj);
          } else {
            console.log(`--- uploadedPortfolioTicker ${uploadedPortfolioTicker} or selectedPortfolioName ${selectedPortfolioName} is missing ---`);
          }
        }
        // ------------------------------------------------------------------------------------------------------

      } else {
        // upload for My Portfolio
        this.props.setEnhancerState({ tickerNotExist: [], tickerNotSupported: [] });
        this.setState({ messageModal: false });
        if (this.props.fileName !== '') {
          this.props.uploadCSVRequest({ data: dataJson, "file_name": this.props.fileName }, this.onFileSuccess);
        } else {
          this.props.uploadCSVRequest({ data: dataJson }, this.onFileSuccess);
        }
      }

    }
  }

  // delete operation triggers getUpdatedData
  getUpdatedData = (data) => {
    const dataJson = JSON.parse(JSON.stringify(data));
    this.getUpdatedPortfolioDataState({
      OVER_RIDE_DATA: true,
      validTickers: dataJson,
      invalidTickers: this.state.invalidTickers,
      portfolioValueWillBe: this.state.portfolioValueWillBe,
      deleteOperation: false,
    });
  }

  getTickerList = (items) => {
    return items.reduce((acc, curr, i) =>
    i !== 0 ? acc + `${items.length - 1 === i ? ` and  ` : ', '}` + curr : curr, '');
  }

  uploadModalToggle = () => {
    this.setState({
      csvFormatErrorMessage: '', // dont change this
      uplPrtModal: false,
    });
  }

  updateTickerStatus = (invalidData) => {
    const { checkSingleTickerValidityRequest } = this.props;
    const { updatedTicker, selectedRecord } = invalidData;
    if (checkSingleTickerValidityRequest) {
      checkSingleTickerValidityRequest(invalidData, this.updateCSVData)
    }
  }

  // edit operation triggers updateCSVData
  updateCSVData = (oldTicker, selectedRecord, updateStatus) => {
    let invalidTickers = this.state.invalidTickers.map(obj => {
      let item = Object.assign({}, obj);
      if(item.key == selectedRecord.key) {
        item.ticker = selectedRecord.ticker;
        item.name = selectedRecord.name;
        item.invalid = (item.invalid === true) ? !updateStatus : item.invalid;
        item.validTicker = updateStatus;
        item.message = updateStatus ? 'Valid Ticker' : 'Ticker not identified';
        item.loading = false;
        item.showInMissingTicker = true;
        item.realTimePrice = selectedRecord.realTimePrice || 'NA';
      }
      return item;
    })

    this.getUpdatedPortfolioDataState({
      OVER_RIDE_DATA: true,
      validTickers: this.state.validTickers,
      invalidTickers: invalidTickers,
      portfolioValueWillBe: this.state.portfolioValueWillBe,
      deleteOperation: false,
      allocationTypeValue: this.state.allocationTypeValue,
    });
  }

  getUpdatedPortfolioDataState = ({ OVER_RIDE_DATA, validTickers, invalidTickers, deleteOperation, allocationTypeValue, portfolioValueWillBe: portfolioValue }) => {
    // console.log(' ---- getUpdatedPortfolioDataState ----', 'allocationTypeValue --> ', allocationTypeValue);
    if (validTickers && invalidTickers && typeof portfolioValue !== 'undefined') {
      // const updatedTickers = [...this.state.validTickers, ...this.state.invalidTickers];
      const allData = [...validTickers, ...invalidTickers];
      // const { portfolioValue } = this.props;
      // console.log(JSON.parse(JSON.stringify(allData)));
      // console.log('portfolioValue ==> ', portfolioValue);
      // console.log('weightCalculatedFromCSVBy --> ', this.state.weightCalculatedFromCSVBy);
      if (!deleteOperation) {
        allData.forEach((item, i) => {
          if (item.weight && typeof item.weight_calculated_format !== 'undefined' && item.weight_calculated_format === 'weight') { delete item.values; delete item.shares; delete item.amount; };
          if (item.shares && typeof item.weight_calculated_format !== 'undefined' && item.weight_calculated_format === 'shares') { delete item.values; delete item.weight; delete item.amount; };
          if (item.amount && typeof item.weight_calculated_format !== 'undefined' && item.weight_calculated_format === 'amount') { delete item.values; delete item.shares; delete item.weight; };
        });
      }
      // console.log(JSON.parse(JSON.stringify(allData)));
      const allTickersData = updateTickerData(allData, portfolioValue, this.state.weightCalculatedFromCSVBy, OVER_RIDE_DATA); // OVER_RIDE_DATA = true; // to over-ride 'weight'
      // console.log('getUpdatedPortfolioDataState ==> ', allTickersData);
      let _validTickers = [];
      let _invalidTickers = [];
      allTickersData.forEach((u) => {
        // invalid ticker case
        if (typeof u.amount === 'undefined' || u.amount === 'NA' || u.amount === null) {
          u[`amount`] = 0;
        }
        // invalid ticker case
        if (typeof u.values === 'undefined' || u.values === 'NA' || u.values === null) {
          u[`values`] = 0;
        }
        // invalid ticker case
        if (u.weight_calculated_format !== 'undefined') {
          u[`format_value`] = u.weight_calculated_format === 'value' ? u.values : u[`${u.weight_calculated_format}`];
        }

        if (u.shares &&
          u.shares !== 'NA' &&
          typeof u.shares !== 'undefined' &&
          u.realTimePrice &&
          u.realTimePrice !== 'NA' &&
          typeof u.realTimePrice !== 'undefined' &&
          allocationTypeValue === 'weight'
        ) {
          u[`values`] = u.shares * u.realTimePrice;
          u[`amount`] = u[`values`];
        }

        if(u.showInMissingTicker) {
          _invalidTickers.push(u)
        } else {
          _validTickers.push(u)
        }
      });
      this.setState({
        validTickers: _validTickers,
        invalidTickers: _invalidTickers,
        allocationTypeValue,
      });
    }
  }

  getValidPortfolioData = (data, OVER_RIDE_DATA = false, allocationTypeValue, ForNewTicker = false) => {
    // console.log('getValidPortfolioData upload section --> ', data, 'OVER_RIDE_DATA -> ', OVER_RIDE_DATA, 'allocationTypeValue --> ', allocationTypeValue);
    const dataJson = JSON.parse(JSON.stringify(data));

    let validTickers = [];
    let invalidTickers = [];

    let allData = [...this.state.invalidTickers, ...dataJson];
    allData = _.unionBy(allData, 'ticker');

    let sumOfAllValues = allData.map((item) => item.values).reduce((a, b) => a + b, 0);
    let _portfolioValueWillBe = sumOfAllValues; // this.props.portfolioValue;

    // if (allData && allData.length > 0 && allData[0] && typeof allData[0].weight_calculated_format !== 'undefined') {
    //   _portfolioValueWillBe = allData[0].weight_calculated_format === 'weight' ? this.props.portfolioValue : sumOfAllValues;
    // }

    // if (allocationTypeValue !== null) {
    //   _portfolioValueWillBe = (allocationTypeValue === 'weight' && ForNewTicker) ? _portfolioValueWillBe : sumOfAllValues;
    // };

    // console.log('_portfolioValueWillBe --> ', _portfolioValueWillBe);

    // console.log('-- allData ---> ', allData);

    // 'dataJson' is an array of validTickers
    if (allData && allData.length > 0) {
      allData.forEach((kl, i) => {
        // console.log('check for unsupported ticker in list');
        // check for unsupported ticker in list
        if (kl.invalidType === 'unsupported' || kl.invalidType === 'unrecognized') {
          // console.log('unsupported -> ', kl);
          invalidTickers.push(kl);
        } else {
          validTickers.push(kl);
        }
      });
    }

    validTickers.forEach((u, i) => {
      if (typeof u[`weight_calculated_format`] !== 'undefined') { delete u[`weight_calculated_format`]; };
      if (typeof u[`message`] !== 'undefined') { delete u[`message`]; };
      if (typeof u[`showInMissingTicker`] !== 'undefined') { delete u[`showInMissingTicker`]; };
      u[`shares`] = 0;
      if ((typeof u[`original_weight`] !== 'undefined' && u[`original_weight`] !== u[`weight`]) || typeof u[`weight`] === 'undefined') {
        u[`weight`] = u[`original_weight`];
      }
    });

    invalidTickers.forEach((u, i) => {
      if (typeof u[`weight_calculated_format`] !== 'undefined') { delete u[`weight_calculated_format`]; };
      u[`shares`] = 0;
      if ((typeof u[`original_weight`] !== 'undefined' && u[`original_weight`] !== u[`weight`]) || typeof u[`weight`] === 'undefined') {
        u[`weight`] = u[`original_weight`];
      }
    });

    this.getUpdatedPortfolioDataState({
      OVER_RIDE_DATA,
      validTickers,
      invalidTickers,
      portfolioValueWillBe: _portfolioValueWillBe,
      deleteOperation: false,
      allocationTypeValue,
    });
  }

  getScratchPortfolioData = (data, OVER_RIDE_DATA = false, allocationTypeValue) => {
    // console.log('getScratchPortfolioData upload section --> ', data, 'OVER_RIDE_DATA -> ', OVER_RIDE_DATA);
    const dataJson = JSON.parse(JSON.stringify(data));

    const allData = [...dataJson];
    // console.log(allData);
    let sumOfAllValues = allData.map((item) => item.values).reduce((a, b) => a + b, 0);
    let _portfolioValueWillBe = this.props.portfolioValue;

    // if (allData && allData.length > 0 && allData[0] && typeof allData[0].weight_calculated_format !== 'undefined') {
    //   _portfolioValueWillBe = allData[0].weight_calculated_format === 'weight' ? this.props.portfolioValue : sumOfAllValues;
    // }

    if (allocationTypeValue !== null) {
      _portfolioValueWillBe = allocationTypeValue === 'weight' ? _portfolioValueWillBe : sumOfAllValues;
    };

    // console.log('_portfolioValueWillBe --> ', _portfolioValueWillBe);

    dataJson.forEach((u, i) => {
      u[`shares`] = 0;
      if ((typeof u[`original_weight`] !== 'undefined' && u[`original_weight`] !== u[`weight`]) || typeof u[`weight`] === 'undefined') {
        u[`weight`] = u[`original_weight`];
      }
    });

    this.getUpdatedPortfolioDataState({
      OVER_RIDE_DATA,
      validTickers: dataJson,
      invalidTickers: this.state.invalidTickers,
      portfolioValueWillBe: _portfolioValueWillBe,
      deleteOperation: false,
      allocationTypeValue,
    });

  }

  getInvalidPortfolioData = (data, OVER_RIDE_DATA = false, allocationTypeValue) => {
    let dataJson = JSON.parse(JSON.stringify(data));
    // console.log('getInvalidPortfolioData in UploadSection', dataJson, 'OVER_RIDE_DATA => ', OVER_RIDE_DATA);
    let validTickers = [];
    let invalidTickers = [];

    let currentInValidTickerState = JSON.parse(JSON.stringify(this.state.invalidTickers));
    // console.log('before currentInValidTickerState --> ', currentInValidTickerState);

    // 'dataJson' is an array of validTickers
    if (currentInValidTickerState && currentInValidTickerState.length > 0 && dataJson && dataJson.length > 0) {
      currentInValidTickerState.forEach((kl, i) => {
        // check if existing ticker is modify or not
        let _newObj = dataJson.find((item) => (item.oldTicker && (item.oldTicker === kl.ticker || item.oldTicker === kl.oldTicker)));
        if (_newObj) {
          // console.log('_newObj --> ', _newObj);
          _newObj = JSON.parse(JSON.stringify(_newObj));
          currentInValidTickerState[i] = {
            ..._newObj,
          }
        }
      });
    }

    // console.log('after currentInValidTickerState --> ', currentInValidTickerState);
    let currentValidTickerState = JSON.parse(JSON.stringify(this.state.validTickers));
    let allData = [...currentValidTickerState, ...currentInValidTickerState];
    allData = _.unionBy(allData, 'ticker');

    // console.log('-- allData ---> ', allData);

    // 'dataJson' is an array of validTickers
    if (allData && allData.length > 0) {
      allData.forEach((kl, i) => {
        // console.log('check for unsupported ticker in list');
         // check for unsupported ticker in list
        if (kl.invalidType === 'unsupported' || kl.invalidType === 'unrecognized') {
          // console.log('unsupported -> ', kl);
          invalidTickers.push(kl);
        } else {
          validTickers.push(kl);
        }
      });
    }

    validTickers.forEach((u, i) => {
      if (typeof u[`weight_calculated_format`] !== 'undefined') { delete u[`weight_calculated_format`]; };
      if (typeof u[`message`] !== 'undefined') { delete u[`message`]; };
      if (typeof u[`showInMissingTicker`] !== 'undefined') { delete u[`showInMissingTicker`]; };
      u[`shares`] = 0;
      if ((typeof u[`original_weight`] !== 'undefined' && u[`original_weight`] !== u[`weight`]) || typeof u[`weight`] === 'undefined') {
        u[`weight`] = u[`original_weight`];
      }
    });

    invalidTickers.forEach((u, i) => {
      if (typeof u[`weight_calculated_format`] !== 'undefined') { delete u[`weight_calculated_format`]; };
      u[`shares`] = 0;
      if ((typeof u[`original_weight`] !== 'undefined' && u[`original_weight`] !== u[`weight`]) || typeof u[`weight`] === 'undefined') {
        u[`weight`] = u[`original_weight`];
      }
    });

    this.getUpdatedPortfolioDataState({
      OVER_RIDE_DATA,
      validTickers: JSON.parse(JSON.stringify(validTickers)),
      invalidTickers: JSON.parse(JSON.stringify(invalidTickers)),
      portfolioValueWillBe: this.state.portfolioValueWillBe,
      deleteOperation: false,
      allocationTypeValue,
    });

  }

  deleteInvalidTickers = (record, tableFor, OVER_RIDE_DATA = false, allocationTypeValue) => {
    let _record = JSON.parse(JSON.stringify(record));
    let deleteOperation = true;
    // console.log('deleteInvalidTickers csvpreview index --> ', _record, tableFor, 'OVER_RIDE_DATA -> ', OVER_RIDE_DATA);

    if (_record) {
      if (tableFor === 'Invalid') {

        let { invalidTickers, validTickers } = this.state;
        let _dataSource = invalidTickers.filter(item => item.ticker !== _record.ticker);
        _dataSource.forEach((item, i) => {
          if (typeof item[`weight_calculated_format`] !== 'undefined') { delete item[`weight_calculated_format`]; };
          item[`shares`] = 0;
          if ((typeof item[`original_weight`] !== 'undefined' && item[`original_weight`] !== item[`weight`]) || typeof item[`weight`] === 'undefined') {
            item[`weight`] = item[`original_weight`];
          }
        });

        validTickers.forEach((item, i) => {
          if (typeof item[`weight_calculated_format`] !== 'undefined') { delete item[`weight_calculated_format`]; };
          item[`shares`] = 0;
          if ((typeof item[`original_weight`] !== 'undefined' && item[`original_weight`] !== item[`weight`]) || typeof item[`weight`] === 'undefined') {
            item[`weight`] = item[`original_weight`];
          }
        });

        const allData = [..._dataSource, ...validTickers];
        // console.log(allData);
        let sumOfAllValues = allData.map((item) => item.values).reduce((a, b) => a + b, 0);
        // console.log('sumOfAllValues --> ', sumOfAllValues, allocationTypeValue);
        let _portfolioValueWillBe = this.props.portfolioValue;

        if (allocationTypeValue !== null) {
          _portfolioValueWillBe = allocationTypeValue === 'weight' ? _portfolioValueWillBe : sumOfAllValues;
        };

        // console.log('_portfolioValueWillBe --> ', _portfolioValueWillBe);
        this.getUpdatedPortfolioDataState({
          OVER_RIDE_DATA,
          validTickers: validTickers,
          invalidTickers: _dataSource,
          portfolioValueWillBe: _portfolioValueWillBe, // this.state.portfolioValueWillBe,
          deleteOperation,
          allocationTypeValue,
        });

        // this.setState({
        //   invalidTickers: _dataSource,
        // });

      } else if (tableFor === 'Valid') {

        let { validTickers } = this.state;
        let _dataSource = validTickers.filter(item => item.ticker !== _record.ticker);
        _dataSource.forEach((item, i) => {
          if (typeof item[`weight_calculated_format`] !== 'undefined') { delete item[`weight_calculated_format`]; };
          item[`shares`] = 0;
          if ((typeof item[`original_weight`] !== 'undefined' && item[`original_weight`] !== item[`weight`]) || typeof item[`weight`] === 'undefined') {
            item[`weight`] = item[`original_weight`];
          }
        });

        const allData = [..._dataSource, ...this.state.invalidTickers];
        // console.log(allData);
        let sumOfAllValues = allData.map((item) => item.values).reduce((a, b) => a + b, 0);
        // console.log('sumOfAllValues --> ', sumOfAllValues, allocationTypeValue);
        let _portfolioValueWillBe = this.props.portfolioValue;

        if (allocationTypeValue !== null) {
          _portfolioValueWillBe = allocationTypeValue === 'weight' ? _portfolioValueWillBe : sumOfAllValues;
        };

        // console.log('_portfolioValueWillBe --> ', _portfolioValueWillBe);

        this.getUpdatedPortfolioDataState({
          OVER_RIDE_DATA,
          validTickers: _dataSource,
          invalidTickers: this.state.invalidTickers,
          portfolioValueWillBe: _portfolioValueWillBe, // this.state.portfolioValueWillBe,
          deleteOperation,
          allocationTypeValue,
        });

        // this.setState({
        //   validTickers: _dataSource,
        // });
      }

    }
  }

  savePortfolioName = (event) => {
    // console.log(event.target.value);
    const { setEnhancerState } = this.props;
    if (setEnhancerState && event && event.target && event.target.value) {
      setEnhancerState({ fileName: event.target.value })
    }
  }

  focusOnPortfolioName = () => {
    // console.log('=== focusOnPortfolioName ===', this.portfolioNameInput);
    if (this.portfolioNameInput && this.portfolioNameInput.current && this.portfolioNameInput.current.focus) {
      this.portfolioNameInput.current.focus();
    }
  }

  render() {
    const { portfolioDropdownOpen, uplPrtModal, premiumModal, notify, isNotifyOpen, csvVisible, csvFormatErrorMessage, emptyCSVModal } = this.state;
    const {
      premiumUser,
      uploadFilePartial,
      uploadFilePartialMsg,
      setEnhancerState,
      children,
      className,
      childrenWrapClassName,
      tickerNotExist,
      csvValidating,
      fileFor,
      portfolioLoading,
      prefixTag,
      fetchingAllTickersList,
      mode,
    } = this.props;
    let { magnifiTickers } = this.props;

    let { validTickers, invalidTickers, allocationTypeValue } = this.state;
    let allData = [...validTickers, ...invalidTickers]

    let allMagnifiTickers = magnifiTickers;
    magnifiTickers = magnifiTickers.filter((kl) => !allData.find((l) => l.ticker === kl.ticker))

    let CSVPreviewUploadTOP = 60;
    const headerElement = document.getElementById("magnifi-header-wrapper");
    if (headerElement) {
      CSVPreviewUploadTOP = `${headerElement.offsetHeight}px`;
    }

    let sumOfAllValues = 0, sumOfAllWeights = 0;

    const modalTitle = (mode) => {
      if (mode === 'create') {
        return (
          <div className="csv-preview-modal-title-head-input-wrapper">
            <Input ref={this.portfolioNameInput} defaultValue="My Portfolio" onPressEnter={this.savePortfolioName} onBlur={this.savePortfolioName} />
            <i className={`fas fa-pencil-alt csv-preview-uplaod-pencil-icon`} onClick={this.focusOnPortfolioName} />
          </div>
        )
      } else {
        return (
          <div class="title-head">Uploaded Portfolio</div>
        )
      }
    }

    // console.log('validTickers --> ', validTickers);
    // console.log('invalidTickers --> ', invalidTickers);
    // console.log('allocationTypeValue --> ', allocationTypeValue);

    return (
      <div className={className}>
        {prefixTag}
        <div
          className={childrenWrapClassName}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.uploadFileFor()
          }}
        >
          {children}
        </div>

        {(this.state.uploadFileFor === fileFor) && (
          <div>
            {(mode !== 'create') && (
              <Modal
                isOpen={uplPrtModal}
                toggle={() => this.uploadModalToggle()}
                centered
                keyboard={true}
                backdrop
                className="premium-msg-modal account-modal"
                backdropClassName="premium-feature-process-modal-backdrop top-60px"
                modalClassName="top-60px"
              >
                <ModalBody className="ssf-modal__body" style={{ minHeight: 212 }}>
                  <div className="premium-feature-process-modal-container">
                    <div className="header-wrapper">
                      <h1 className="text-align-left">Upload and view analytics on portfolio holdings</h1>
                    </div>
                    <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => this.uploadModalToggle()}></i>
                    <p className="content-wrapper">
                      <UploadFile
                        getCSVData={this.getCSVData}
                        csvFormatErrorMessage={csvFormatErrorMessage}
                      />
                    </p>
                  </div>
                </ModalBody>
              </Modal>
            )}

            {(csvVisible || (emptyCSVModal && mode === 'create')) && (
              <CSVPreviewUpload
                modalClassName="csv-preview-upload"
                width={700}
                mode={mode}
                footer={null}
                centered={true}
                maskStyle={{ top: CSVPreviewUploadTOP }}
                visible={csvVisible || (emptyCSVModal && mode === 'create')}
                data={validTickers}
                loading={csvValidating}
                emptyCSVModal={emptyCSVModal}
                invalidTickers={invalidTickers}
                magnifiTickers={magnifiTickers}
                allMagnifiTickers={allMagnifiTickers}
                allocationTypeValue={allocationTypeValue}
                fetchingAllTickersList={fetchingAllTickersList}
                onModalClose={this.csvOnClose}
                getDataList={this.getUpdatedData}
                onClickOk={this.handleUploadSubmit}
                checkTicker={this.checkTicker}
                updateTickerStatus={this.updateTickerStatus}
                deleteInvalidTickers={this.deleteInvalidTickers}
                getValidPortfolioData={this.getValidPortfolioData}
                getScratchPortfolioData={this.getScratchPortfolioData}
                getInvalidPortfolioData={this.getInvalidPortfolioData}
                modalTitle={modalTitle(mode)}
                disclaimerMessage={<div className="disclaimer-text mb-0"><i class="fas fa-asterisk"></i>Deleting a position will remove the security entirely from the analysis. The security's allocation will be pro-rated to the other securities in the portfolio.</div>}
              />
            )}

            {(this.props.uploadFileFailure || this.props.uploadfileDuplicate) && (
              <CustomAlert className="alert-windown-wrapper" color='danger'>
                {this.props.uploadfileDuplicate ? 'Label already exists' : this.props.uploadFileFailureMsg}
              </CustomAlert>
            )}

            {isNotifyOpen && (
              <Notification
                isNotifyOpen={isNotifyOpen}
                notifyClassName="notify-card"
                notifyBody="File uploaded successfully"
              />
            )}
          </div>
        )}
      </div>
    );
  }
}


export default connect(
  state => ({
    premiumUser: state.auth.user.premiumUser,
    uploadFilePartial: state.enhancer.uploadFilePartial,
    uploadFilePartialMsg: state.enhancer.uploadFilePartialMsg,
    uploadFileFailure: state.enhancer.uploadFileFailure,
    uploadFileFailureMsg: state.enhancer.uploadFileFailureMsg,
    uploadfileDuplicate: state.enhancer.uploadfileDuplicate,
    tickerNotExist: state.enhancer.tickerNotExist,
    tickerNotSupported: state.enhancer.tickerNotSupported,
    csvValidating: createLoadingSelector(['VERIFY_CSV_TICKER_AND_PRICE', 'UPLOAD_PORTFOLIO_CSV'])({ loading: state.loading }),
    fileName: state.enhancer.fileName,
    portfolioValue: state.profile.portfolioValue,
    onboardScreenData: state.enhancer.onboardData,
    originalCSVFormatPortfolioData: state.enhancer.originalCSVFormatPortfolioData,
    originalCSVFormatPortfolioTickers: state.enhancer.originalCSVFormatPortfolioTickers,
    uploadedPortfolioTicker: state.enhancer.uploadedPortfolioTicker,
    sponsoredPortfolioName: state.enhancer.sponsoredPortfolioName,
    selectedPortfolioName: state.enhancer.portfolioName,
    selectedPortfolioID: state.enhancer.portfolioID,
    magnifiTickers: state.global.magnifiTickers,
    fetchingAllTickersList: createLoadingSelector(['GET_ALL_TICKER_LIST'])({ loading: state.loading }),
  }),
  {
    investmentOptions,
    fetchPortfolioData,
    setEnhancerState,
    checkTickerRequest,
    uploadCSVRequest,
    getRealTimePriceForTickersRequest,
    checkSingleTickerValidityRequest,
    uploadCSVFileRequest,
    uploadCSVFileFetch,
    uploadCSVFileSuccess,
    verifyCSVTickerAndPriceRequest,
    uploadPortfolioDataByIdRequest,
    uploadPortfolioCSVRequest,
  }
)(UploadSection);

const Spinner = () => {
  return (
    <div className="spinner-wrapper">
      <div className="text-secondary text-center">
        <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5"></i>
      </div>
    </div>
  )
}

import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux'
import { setFundsState, refreshFundsState, updateFundsData } from 'actions/funds';
import Home from './Home';

class Content extends Component {
  constructor(props){
    super(props)
  }

  componentDidMount() {
    document.body.classList.remove('ht5');
  }

  render() {
    const { funds, loading, query, correctedQuery, messages, itemslist } = this.props;
    const { fundsResponse, filterAttrData, filterAttrs, cardsCount, sortAttr, data, sortDesc } = funds;

    return (
      <React.Fragment>
        <div className="account-page-home-wrapper custom-scroll">
          <Home />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ funds, sfm }) => ({
  funds,
  query: funds.query,
  sortAttr: funds.sortAttr,
  correctedQuery: funds.correctedQuery,
  messages: funds.messages,
})

const mapDispatchToProps = {
  setFundsState,
  updateFundsData,
  refreshFundsState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Content)

import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import ResultCard from './ResultCard';

export const ConfirmationPayload = ({ data }) => {
    if (data.length === 0) return null;
    const style = { overflowY: 'auto' }
    return (
      <div className="px-1 pt-2" style={style}>
        <div className="custom-scroll">
          {data.map((e, i) => {
            return (
              <div key={e.ticker}>
                <ResultCard data={e} />
              </div>
            )
          })}
        </div>
      </div>
    );
}

export const SelectButtonDisabledTooltip = ({ target, placement = 'bottom' }) => {
  return (
    <UncontrolledTooltip className="w-sm" placement={placement} target={target}>
      Please acknowledge the warnings displayed on the screen.
    </UncontrolledTooltip>
  )
}

export default {
  ConfirmationPayload,
  SelectButtonDisabledTooltip,
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PrimaryButton } from 'shared-components/';
class ThankYou extends Component {

  handlePageChange() {
    window.location.href = "https://magnifi.com/widget";
  }

  render() {
    const { token, email, pendingWidgetToken } = this.props;

    if (pendingWidgetToken) {
      return (
        <div>
          <div className="text-center"><i className="far fa-check-circle check-cirlce" /></div>
          <br />
          <div className="thank-you-label text-center">Thanks for registering.</div>
          <br />
          <div className="user-label text-center">Your request is under process.</div>
          <br />
          <div className="thank-you-button">
            <PrimaryButton className="btn primary-btn"
              onClick={this.handlePageChange}
            >
              GO TO INTEGRATION PAGE
            </PrimaryButton>
          </div>
        </div>
      )

    } else if (email === undefined) {
      return (
        <div>
          <div className="text-center"><i className="far fa-check-circle check-cirlce" /></div>
          <br />
          <div className="thank-you-label text-center">You are already registerd</div>
          <br />

          <div className="thank-you-label text-center">Your access token is</div>

          <div className="token-label text-center">{token}</div>
          <br />

          <div className="thank-you-button">
            <PrimaryButton className="btn primary-btn"
              onClick={this.handlePageChange}
            >
              GO TO INTEGRATION PAGE
            </PrimaryButton>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div className="text-center"><i className="far fa-check-circle check-cirlce" /></div>
          <br />
          <div className="thank-you-label text-center">Thanks for registering.</div>
          <br />
          <div className="thank-you-label text-center">Your access token is</div>

          <div className="token-label text-center">{token}</div>

          <div className="thank-you-label text-center">we have also sent you same on</div>

          <div className="user-label text-center">{email}</div>
          <br />

          <div className="thank-you-button">
            <PrimaryButton className="btn primary-btn"
              onClick={this.handlePageChange}
            >
              GO TO INTEGRATION PAGE
            </PrimaryButton>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = ({ register }) => ({
  token: register.token,
  email: register.email,
  register,
})

export default connect(
  mapStateToProps,
)(withRouter(ThankYou))

import zipObject from 'lodash/zipObject'

const actions = [
  'SET_DEFAULT_PORTFOLIO',
  'SET_ENHANCEMENT_DATA',
  'DISABLE_ENHANCE_BTN_CONTROL',
  'ENABLE_ENHANCE_BTN_CONTROL',
  'SET_PORTFOLIO_STATE',
  'PORTFOLIO_QUERY',
  'PORTFOLIO_FETCH_SUCCESS',
  'SET_PORTFOLIO_REPLACE',
  'SAVE_PORTFOLIO_REPLACE_OPTS',
  'SAVE_ACCOUNT_REPLACE_OPTS',
  'SAVE_ENHANCED_TICKER',
  'SAVE_ENHANCED_TRANSACTIONS',
  'SET_ENHANCED_TRANSACTIONS',
  'ADD_PORTFOLIO_ENHANCE_REPLACE',
  'DELETE_PORTFOLIO_ENHANCE_REPLACE',
  'SET_REPLACE_PORTFOLIO_TOGGLE',
  'SAVE_ENHANCE_PROPOSAL_PORTFOLIO',
  'SET_REPLACE_TICKER',
  'SAVE_REPLACE_TICKER',
  'SET_ENHANCED_CARD',
  'SAVE_ENHANCED_CARD',
  'DELETE_CUSTOM_REPLACE_TICKER',
  'SET_CACHING_FOR_PORTFOLIO',
  'RESET_CACHING_FOR_PORTFOLIO',

  'SET_ENHANCE_PORTFOLIO_VISIBLE',

  'GET_MY_CURRENT_PORTFOLIO_REQUEST',
  'GET_MY_CURRENT_PORTFOLIO_FETCH',
  'GET_MY_CURRENT_PORTFOLIO_SUCCESS',

  'GET_REPLACE_PORTFOLIO_OPTIONS_REQUEST',
  'GET_REPLACE_PORTFOLIO_OPTIONS_FETCH',
  'GET_REPLACE_PORTFOLIO_OPTIONS_SUCCESS',

  'REPLACE_CURRENT_MODEL_PORTFOLIO_WITH',

  'FINALIZE_REPLACE_MODEL_PORTFOLIO_FETCH',
  'FINALIZE_REPLACE_MODEL_PORTFOLIO_REQUEST',
  'FINALIZE_REPLACE_MODEL_PORTFOLIO_SUCCESS',

  'RESET_MODEL_ENHANCE_PORTFOLIOS_STATE',
  'RESET_PORTFOLIO_STATE',
  'RESET_FUNDS_ENHANCE_PORTFOLIOS_STATE',

  'CHECK_TICKER_EXIST_REQUEST',
  'CHECK_TICKER_EXIST_FETCH',
  'CHECK_TICKER_EXIST_SUCCESS',

  'CHECK_SINGLE_TICKER_EXIST_REQUEST',

  'SET_MODEL_ENHANCEMENT_DATA',
]

export default zipObject(actions, actions)

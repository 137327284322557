import React, { Component } from 'react'
import { Radio, Select } from 'antd'
import { usStates } from '../../Constant/FormData';

export default class StateSelect extends Component {
  
  render() {
    const { Option } = Select;
    const optionsList = usStates.map(item => {
      return (
        <Option key={item.code} value={item.abbreviation}>
          {item.name}
        </Option>
      )
    })
    return (
      <Select
        showSearch
        type="select"
        name="state"
        id="state"
        showArrow={false}
        suffixIcon={null}
        placeholder="state"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        {...this.props}
      >
        <Option value="">
          Select
        </Option>
        {optionsList}
      </Select>
    )
  }
}

import ActionTypes from '../action-types'

// add state which will be refreshed on component unmount
const initialState = {
  loading: false,
  portfolioValue: 0,
  portfolioName: '',
  portfolioID: '',
  filename:'',
  tickers: [],

  searchTypeMode: 'FUNDS',
  fundUniverse: 'etf,etn,mf,cef',
  activeFundUniverse: ['etf,etn', 'mf', 'cef'],
  modelUniverse: '',// 'mp',
  activeModelUniverse: [],
  similarityMeasures: 'overlap',
  activeSelectorUniverse: [],

  query: '',
  query_english: '',
  guidanceMessage: '',
  selectedWeightOptions: {},

  tickerNotExist: [],
  sponsoredPortfolioName: '',
  selectedPortfolioTicker: '',
  uploadedCSVPortfolioRequest: [],
  originalCSVFormatPortfolioData: [],
  originalCSVFormatPortfolioTickers: [],

  alertCountData: {
    data: null,
    error: null
  },

  trendingQueries: [],
  apexBalanceData: {},
}

const definedState = {
  ...initialState,
  summary: {
    portfolioFunds: [],
    invalidTickerFunds: [],
    tickerNotSupported: [], // for summary page holdings listing
    activeFund: '',
  },
  performanceImpact: {
    selectedBenchmark: 'SPY',
    upDownDataSet: [],
    currentPerformanceImpact: {},
    enhancedPerformanceImpact: {},
  },
  onboardData: {
    selectedPortfolioType: '',
    selectedPortfolioPath: '',
  },
}

export function accounts(state = definedState, action) {
  const { payload } = action;
  switch (action.type) {
    case ActionTypes.SET_ACCOUNTS_STATE:
      if (payload.callback && typeof payload.callback === 'function') {
        return (
          {
            ...state,
            ...payload
          },
          () => payload.callback()
        )
      } else {
        return {
          ...state,
          ...payload
        }
      }

    case ActionTypes.GET_ACCOUNTS_PORTFOLIO_BY_ID_FETCH:
      let { onboardData: _onboardData } = state;
      const { filename, portfolioType } = payload;
      if (filename && portfolioType) {
        _onboardData = {
          selectedPortfolioType: portfolioType,
          selectedPortfolioPath: filename,
        };
      }
      return {
        ...state,
        onboardData: _onboardData,
      }

    case ActionTypes.GET_ACCOUNTS_PORTFOLIO_BY_ID_SUCCESS:
      return {
        ...state,
        ...payload,
      }

    case ActionTypes.UPLOAD_ACCOUNTS_PORTFOLIO_DATA_BY_ID_SUCCESS:
      return {
        ...state,
        ...payload,
      }

    case ActionTypes.RESET_ACCOUNTS_STATE_SUCCESS:
      return {
        ...definedState,
      }

    case ActionTypes.GET_ALERT_COUNT_SUCCESS:
      return {
        ...state,
        ...payload,
      }

    case ActionTypes.GET_ALERT_COUNT_SUCCESS:
      return {
        ...state,
        ...payload,
      }

    case ActionTypes.GET_ACCOUNTS_TRENDING_SEARCHES_REQUEST:
      return {
        ...state,
        ...payload,
      }

    case ActionTypes.REMOVE_ALL_SFM_FUNDS_FROM_ACCOUNTS_STATE:
      let { summary: { portfolioFunds: _portfolioFunds } } = state;
      // console.log(_portfolioFunds);
      _portfolioFunds.forEach((kl, i) => {
        if (kl && kl.ticker) {
          kl._selected = false;
          kl._selector = false;
          if (kl._order) {
            delete kl._order
          }
        }
      });

      return {
        ...state,
        summary: {
          ...state.summary,
          portfolioFunds: _portfolioFunds,
        },
        // ...payload,
      }

    case ActionTypes.REMOVE_SFM_FUND_FROM_ACCOUNTS_STATE:
      const { summary: { portfolioFunds: _portfolioF } } = state;
      const { unSelectTicker } = payload;
      _portfolioF.forEach((kl, i) => {
        if (kl && kl.ticker && kl.ticker === unSelectTicker) {
          kl._selected = false;
          kl._selector = false;
          if (kl._order) {
            delete kl._order
          }
        }
      });
      return {
        ...state,
        summary: {
          ...state.summary,
          portfolioFunds: _portfolioF,
        },
      }

    case ActionTypes.ADD_SFM_FUND_STATE_TO_ACCOUNTS_STATE:
      const { summary: { portfolioFunds: _portfolioFU } } = state;
      const { selectedFunds, selectedType: _sfmSelectedType, realTimePriceSocket } = payload;

      console.log('==== realTimePriceSocket ====', realTimePriceSocket);

      _portfolioFU.forEach((kl, i) => {
        if (kl && kl.ticker && (Object.prototype.toString.call(selectedFunds) === '[object Array]' ? selectedFunds.includes(kl.ticker) : selectedFunds === kl.ticker)) {
          kl._selected = true;
          kl._selector = true;
          kl._selectedType = _sfmSelectedType;
        }
      });
      return {
        ...state,
        summary: {
          ...state.summary,
          portfolioFunds: _portfolioFU,
        },
      }

    case ActionTypes.SET_ACCOUNTS_HOLDINGS_FUND_SELECTION_TYPE:
      const { summary: { portfolioFunds: _portfolioFFFU } } = state;
      const { selectedFunds: _selectedFFunds, selectedType: _sfmSSSelectedType } = payload;
      _portfolioFFFU.forEach((kl, i) => {
        if (kl && kl.ticker && _selectedFFunds && _selectedFFunds.ticker && kl.ticker === _selectedFFunds.ticker) {
          kl._selectedType = _sfmSSSelectedType;
        }
      });
      return {
        ...state,
        summary: {
          ...state.summary,
          portfolioFunds: _portfolioFFFU,
        },
      }

    case ActionTypes.GET_APEX_AMOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        ...payload,
      }

    case ActionTypes.GET_PORTFOLIO_GAINS_SUCCESS:
      return {
        ...state,
        ...payload,
      }

    // case ActionTypes.GET_ALERT_COUNT_LOADING: {
    //   const { alertCountData } = state;
    //   alertCountData.isLoading = payload;
    //   return {
    //     ...state,
    //     alertCountData,
    //   }
    // }
    // case ActionTypes.GET_ALERT_COUNT_SUCCESS: {
    //   const { alertCountData } = state;
    //   alertCountData.data = payload;
    //   return {
    //     ...state,
    //     alertCountData,
    //   }
    // }
    // case ActionTypes.GET_ALERT_COUNT_FAILURE: {
    //   const { alertCountData } = state;
    //   alertCountData.error = payload;
    //   return {
    //     ...state,
    //     alertCountData,
    //   }
    // }

    default:
      return state
  }
}

import React from 'react';
import { SimplePieChart } from 'components/Charts';
import NotApplicable from 'components/NotApplicable';
import { cn } from 'DataSet';
import { SectorChartMapping } from 'ChartData';

export const SectorChart = (props) => {
  var mult = props.name === 'Theme' ? 100 : 1;
  const data = props.data ? props.data : props.attrs.map(v => ({ name: v, value: cn(props.card, v) ? parseFloat((cn(props.card, v)*mult).toFixed(1)) : 0 }));
  let sum = 0.0;
  data.forEach((e,i) => {
    sum += e.value;
    if (e.name === 'Other Sectors') {
      data[i].name = 'Others';
    }
  });
  if(isNaN(sum) || sum <= 0.000000) return <NotApplicable />;
  const { card, view, name, chartData } = props;
  // if (typeof card.chartData === 'undefined') return null;
  const chrtD = chartData || card.chartData;
  const currentChartData = chrtD.filter(e => e.view_name == view)[0];
  // console.log(currentChartData);
  // if (typeof currentChartData === 'undefined') return null;

  let primaryTextStyle = {}, secondaryTextStyle = {};
  const chartTypePresent = typeof name !== 'undefined' ? name : '';
  let centerText, centerDisplay = "";

  if (typeof currentChartData !== 'undefined') {
    // console.log(currentChartData);
    if (currentChartData.data) {
      let displayText = SectorChartMapping.filter(e => e.chart_name === currentChartData.data);
      // console.log('=== displayText ===', displayText);
      if (displayText.length) {
        // console.log(displayText[0].sector_name, card[displayText[0].sector_name]);
        if (displayText[0].sector_name && card[displayText[0].chart_name]) {
          centerDisplay = `${(card[displayText[0].chart_name].toFixed(1))}%`;
        }
        centerText = displayText[0].sector_name;
        // centerText = centerText.substring(0, 8);
        primaryTextStyle = chartTypePresent === 'Sectors' ?  {
          // top: '39%',
          fontSize: 11,
          fontWeight: 'bold',
          zIndex: '5',
        }: {};
        secondaryTextStyle = chartTypePresent === 'Sectors' ? {
          top: '52%',
          fontSize: centerText.length > 8 ? 9 : 9,
        } : {};
      }
    }
  }

  return (
    <div className="simplePieChartWrapper">
      {(name === 'Sectors')
        && (
          <div className="primaryTextStyle" style={primaryTextStyle}
          >
            { centerDisplay }
            <div className="highlightTooltip">{centerText}: {centerDisplay}</div>
          </div>
        )
      }
      <SimplePieChart {...props} data={data} colors={props.colors} />
    </div>
  )
}

export default SectorChart;

import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button } from 'antd';

import { checkTickerRequest } from 'actions/portfolio';
import { getTickerRealTimePriceRequest } from 'actions/enhancer';
// import { getTickerListRequest } from 'actions/global';
import { createLoadingSelector } from 'selectors/loading-selector';

// import { PrimaryButton } from 'shared-components';
import { numberCheck } from 'layouts/utils';

import DataList from './DataList';
import EmptyDataList from './EmptyDataList';

class CSVPreviewUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: props.data || [],
      errorFields: [],
      tableHeaderArr: [],
      allocationTypeValue: props.allocationTypeValue || 'weight',
      tickerNotExist: props.tickerNotExist || [],
      showAllInvalidTickers: false,
      // submitDisabled: false,
      emptyDataList: [],
    }
  }

  componentDidMount() {
		const { data, tickerNotExist, invalidTickers } = this.props;
		if (data && data.length > 0) {
			data.map((item, key) => {
				item.key = key.toString();
			});
			this.setState({
				data: data,
			}, () => {
        if (data.length > 0) {
          const obj = data[0];
          const tableHeaderArr = this.getSortedTableHeader(obj);
          this.setState({
            tableHeaderArr
          })
        }
      });
		} else if (invalidTickers && invalidTickers.length > 0) {
      invalidTickers.map((item, key) => {
        item.key = key.toString();
      });
      if (invalidTickers.length > 0) {
        const obj = invalidTickers[0];
        const tableHeaderArr = this.getSortedTableHeader(obj);
        this.setState({
          tableHeaderArr
        })
      }
    }
    // commented below submitDisabled logic
    // if(!this.state.data.length) {
    //   const checkValidTickers = this.props.invalidTickers.filter(item => item.validTicker);
    //   this.setState({
    //     submitDisabled: checkValidTickers.length == 0
    //   })
    // }
	}

	componentWillReceiveProps(newProps) {
		if (newProps.data && newProps.data.length > 0 && newProps.data !== this.state.data) {
			newProps.data.map((item, key) => {
				item.key = key.toString();
			});
			this.setState({
				data: newProps.data,
			}, () => {
        if (this.state.data.length > 0) {
          const obj = this.state.data[0];
          const tableHeaderArr = this.getSortedTableHeader(obj);
          this.setState({
            tableHeaderArr
          })
        }
      });
		} else if (newProps.invalidTickers && newProps.invalidTickers.length > 0 && this.state.tableHeaderArr.length === 0) {
      newProps.invalidTickers.map((item, key) => {
        item.key = key.toString();
      });
      if (newProps.invalidTickers.length > 0) {
        const obj = newProps.invalidTickers[0];
        const tableHeaderArr = this.getSortedTableHeader(obj);
        this.setState({
          tableHeaderArr,
        });
      }
    }
    if (newProps.allocationTypeValue && newProps.allocationTypeValue !== this.state.allocationTypeValue) {
      this.setState({
        allocationTypeValue: newProps.allocationTypeValue,
      });
    }
    // commented below submitDisabled logic
    // if(!this.state.data.length) {
    //   const checkValidTickers = newProps.invalidTickers.filter(item => item.validTicker);
    //   this.setState({
    //     submitDisabled: checkValidTickers.length == 0
    //   })
    // }
	}

  getSortedTableHeader = (obj) => {
    const headers = ['ticker', 'name', 'weight(%)', 'values'];
    return headers;
    // const objKeys = Object.keys(obj);
    // let tableHeaderArr1 = [];
    // let tableHeaderArr2 = [];
    // objKeys.forEach(item => {
    //   if(headers.includes(item)){
    //     tableHeaderArr1.push(item);
    //   }else{
    //   //   if(item !== 'key' &&
    //   //   item !== 'date' &&
    //   //   item !== 'price' &&
    //   //   item !== 'amount' &&
    //   //   item !== 'weight' &&
    //   //   item !== 'validTicker' &&
    //   //   item !== 'shares' &&
    //   //   item !== 'original_weight' &&
    //   //   item !== 'invalid' &&
    //   //   item !== 'weight_calculated_format' &&
    //   //   item !== 'format_value' &&
    //   //   item !== 'invalidType' &&
    //   //   item !== 'realTimePrice' &&
    //   //   item !== 'showInMissingTicker' &&
    //   //   item !== 'message'
    //   // ) {
    //   //     tableHeaderArr2.push(item);
    //   //   }
    //   }
    // })
    // const sortedHeaderArr = tableHeaderArr1.sort(function (a,b){
    //   return headers.indexOf(a) - headers.indexOf(b)
    // });
    // let tableHeaderArr = [...sortedHeaderArr, ...tableHeaderArr2];
    // return tableHeaderArr;
  }

	onModalClose = () => {
		this.setState({ visible: false, data: [] }, () => {
			const { onModalClose, clearData } = this.props;
			// if (clearData) {
			// 	clearData();
			// }
			if (onModalClose) {
				onModalClose(false);
			}
		});
	}

	onClickOk = () => {
		const { onClickOk } = this.props;
		if (onClickOk) {
			onClickOk(this.state.data);
		}
	}

  getUpdatedData = (data) => {
    // console.log(data);
    if (data) {
      this.setState({
        data,
      }, () => {
        const { getDataList } = this.props;
        if (getDataList) {
          getDataList(this.state.data);
        }
      });
    }
  }

  getScratchPortfolioData = (data, tableFor, OVER_RIDE_DATA = false, allocationTypeValue) => {
    console.log('getScratchPortfolioData csvpreview index --> ', data, 'allocationTypeValue --> ', allocationTypeValue);
    if (data) {
      const { getScratchPortfolioData } = this.props;
      if (getScratchPortfolioData) {
        getScratchPortfolioData(data, OVER_RIDE_DATA, allocationTypeValue);
      }
    }
  }

  getValidPortfolioData = (data, tableFor, OVER_RIDE_DATA = false, allocationTypeValue, ForNewTicker) => {
    console.log('getValidPortfolioData csvpreview index --> ', data, 'allocationTypeValue --> ', allocationTypeValue, 'ForNewTicker ->', ForNewTicker);
    if (data) {
      const { getValidPortfolioData } = this.props;
      if (getValidPortfolioData) {
        getValidPortfolioData(data, OVER_RIDE_DATA, allocationTypeValue, ForNewTicker);
      }
    }
  }

  getInvalidPortfolioData = (data, tableFor, OVER_RIDE_DATA = false, allocationTypeValue) => {
    console.log('getInvalidPortfolioData csvpreview index --> ', data, 'allocationTypeValue --> ', allocationTypeValue);
    const { getInvalidPortfolioData } = this.props;
    // const { allocationTypeValue } = this.state;
    if (getInvalidPortfolioData && data && data.length > 0) {
      getInvalidPortfolioData(data, OVER_RIDE_DATA, allocationTypeValue);
    }
  }

  deleteInvalidTickers = (data, tableFor, OVER_RIDE_DATA = false, allocationTypeValue) => {
    console.log('deleteInvalidTickers csvpreview index --> ', data, 'allocationTypeValue --> ', allocationTypeValue);
    if (data && tableFor) {
      const { deleteInvalidTickers } = this.props;
      if (deleteInvalidTickers) {
        deleteInvalidTickers(data, tableFor, OVER_RIDE_DATA, allocationTypeValue);
      }
    }
  }

  errorHandle = (fields) => {
    this.setState({
      errorFields: fields,
    });
  }

  checkTicker = (ticker, callback) => {
    const { checkTicker } = this.props;
    if (checkTicker && ticker && callback) {
      return checkTicker(ticker, () => {
        callback(this.state.tickerNotExist)
      });
    }
  }

  showAllInvalidTickersToggle = (val) => {
    this.setState({
      showAllInvalidTickers: val
    })
  }

  getTickerListRequest= () => {
    const { getTickerListRequest } = this.props;
    if (getTickerListRequest) {
      getTickerListRequest()
    }
  }

  searchedCustomTicker = (tickerData) => {
    // console.log(tickerData);
    const { searchedCustomTicker } = this.props;
    if (searchedCustomTicker && tickerData) {
      searchedCustomTicker(tickerData)
    }
  }

  allocationChange = (value) => {
    this.setState({
      allocationTypeValue: value,
    });
  }

  render() {
    const {
      mode,
      loading,
      disclaimerMessage,
      modalTitle,
      wrapClassName,
      width,
      style,
      maskStyle,
      centered,
      footer,
      invalidTickers,
      fetchingAllTickersList,
      magnifiTickers,
      allMagnifiTickers,
      realTimePriceLoading,
      getTickerRealTimePriceRequest,
      emptyCSVModal,
    } = this.props;

    const { errorFields, tableHeaderArr, tickerNotExist, showAllInvalidTickers, allocationTypeValue, emptyDataList } = this.state;
    const unsupportedTickers = invalidTickers.filter((item) => item.invalidType === 'unsupported');
    // console.log('unsupportedTickers --> ', unsupportedTickers);

    const _invalidTickersData = invalidTickers.filter((item) => item.invalidType === 'unrecognized');
    // console.log('_invalidTickersData --> ', _invalidTickersData);

    // console.log('tableHeaderArr --> ', tableHeaderArr, tableHeaderArr.length > 0, _invalidTickersData.length > 0);

    const allData = [...invalidTickers, ...this.state.data];

    // let sumOfAllValues = 0, sumOfAllWeights = 0;
    // console.log(allData);
    let sumOfAllWeights = allData.filter((item) => item.original_weight !== 'NA' && item.original_weight !== '' && typeof item.original_weight !== 'undefined').map((item) => item.original_weight).reduce((a, b) => a + b, 0);
    let sumOfAllValues = allData.filter((item) => item.values !== 'NA' && item.values !== '' && typeof item.values !== 'undefined').map((item) => item.values).reduce((a, b) => a + b, 0);
    // allData.map((item) => {}).forEach((kl, i) => {
    //   if (typeof kl.weight !== 'undefined') {
    //     sumOfAllWeights += kl.weight;
    //   }
    //   if (typeof kl.values !== 'undefined') {
    //     sumOfAllValues += kl.values;
    //   }
    // });

    let portfolioTotal = {
      weight: numberCheck(sumOfAllWeights, 2),
      values: numberCheck(sumOfAllValues, 2),
    };

    // console.log('portfolioTotal ==> ', portfolioTotal);

    // let submitDisabled = (allData.filter(item => item.validTicker).length === 0) || (
    //   allocationTypeValue === 'weight' ? (portfolioTotal.weight !== 100) : (portfolioTotal.value === 0)
    // );
    let submitDisabled = (allData.filter(item => item.validTicker).length === 0) || (portfolioTotal.weight !== 100);
    // console.log('CSVUpload index allocationTypeValue --> ', allocationTypeValue);

    return (
      <React.Fragment>
        <Modal
          destroyOnClose
          width={width}
          footer={footer}
          title={modalTitle}
          centered={centered}
          maskStyle={maskStyle}
          visible={this.props.visible}
          style={{ ...style, display: loading ? 'none' : '' }}
          bodyStyle={{ minHeight: 212, display: loading ? 'none' : '' }}
          wrapClassName={`csv-preview-upload ${wrapClassName}`}
          onOk={() => this.onClickOk()}
          onCancel={() => this.onModalClose()}
          closeIcon={<i className="fal fa-times premium-feature-completed-close cp-modal-close" onClick={() => this.onModalClose()}></i>}
        >
          <div className="pf-list-preview-wrapper h-100 fx-clm">
            {(emptyCSVModal && mode === 'create') ? (
              <div className="valid-ticker-table fx-clm-space-between h-100" style={{ display: loading ? 'none' : '' }}>
                <EmptyDataList
                  {...this.props}
                  tableFor="Valid"
                  renderHeader={(<div className="head-note"><p>Portfolio holdings</p></div>)}
                  data={this.state.data}
                  rowsArr={['ticker', 'name', 'weight(%)', 'values']}
                  magnifiTickers={magnifiTickers}
                  portfolioTotal={portfolioTotal}
                  allMagnifiTickers={allMagnifiTickers}
                  allocationTypeValue={allocationTypeValue}
                  realTimePriceLoading={realTimePriceLoading}
                  fetchingAllTickersList={fetchingAllTickersList}
                  getRealTimePrice={getTickerRealTimePriceRequest}
                  errorHandle={this.errorHandle}
                  checkTicker={this.checkTicker}
                  allocationChange={this.allocationChange}
                  getUpdatedData={this.getScratchPortfolioData}
                  getTickerListRequest={this.getTickerListRequest}
                  searchedCustomTicker={this.searchedCustomTicker}
                  deleteInvalidTickers={this.deleteInvalidTickers}
                />
              </div>
            ) : (
              <>
                {_invalidTickersData.length > 0 && tableHeaderArr.length > 0 && (
                  <div
                    className={`invalid-ticker-table fx-clm-space-between h-100 ${showAllInvalidTickers ? 'pb-0' : ''}`}
                    style={{ display: loading ? 'none' : '' }}
                  >
                    <DataList
                      {...this.props}
                      tableFor="Invalid"
                      renderHeader={(<div className="head-note"><p>Unrecognized securities</p></div>)}
                      validTickers={this.state.data}
                      data={_invalidTickersData}
                      rowsArr={tableHeaderArr}
                      invalidTickers={invalidTickers}
                      magnifiTickers={magnifiTickers}
                      portfolioTotal={portfolioTotal}
                      allMagnifiTickers={allMagnifiTickers}
                      allocationTypeValue={allocationTypeValue}
                      realTimePriceLoading={realTimePriceLoading}
                      showAllInvalidTickers={showAllInvalidTickers}
                      fetchingAllTickersList={fetchingAllTickersList}
                      getRealTimePrice={getTickerRealTimePriceRequest}
                      errorHandle={this.errorHandle}
                      // getUpdatedData={this.getUpdatedData}
                      getUpdatedData={this.getInvalidPortfolioData}
                      checkTicker={this.checkTicker}
                      allocationChange={this.allocationChange}
                      getTickerListRequest={this.getTickerListRequest}
                      searchedCustomTicker={this.searchedCustomTicker}
                      // updateTickerStatus={this.props.updateTickerStatus}
                      deleteInvalidTickers={this.deleteInvalidTickers}
                      showAllInvalidTickersToggle={this.showAllInvalidTickersToggle}
                    />

                    {/*
                      <DataList
                        {...this.props}
                        tableFor="Invalid"
                        renderHeader={(<div className="head-note"><p>Unrecognized securities</p></div>)}
                        rowsArr={tableHeaderArr}
                        data={_invalidTickersData}
                        validTickers={this.state.data}
                        invalidTickers={invalidTickers}

                        // data={this.state.data}
                        // validTickers={this.state.data}
                        // rowsArr={tableHeaderArr}
                        // invalidTickers={unsupportedTickers}

                        magnifiTickers={magnifiTickers}
                        portfolioTotal={portfolioTotal}
                        allocationTypeValue={allocationTypeValue}
                        realTimePriceLoading={realTimePriceLoading}
                        fetchingAllTickersList={fetchingAllTickersList}
                        getRealTimePrice={getTickerRealTimePriceRequest}
                        errorHandle={this.errorHandle}
                        // getUpdatedData={this.getUpdatedData}
                        getUpdatedData={this.getInvalidPortfolioData}
                        checkTicker={this.checkTicker}
                        allocationChange={this.allocationChange}
                        getTickerListRequest={this.getTickerListRequest}
                        searchedCustomTicker={this.searchedCustomTicker}
                        deleteInvalidTickers={this.deleteInvalidTickers}
                      />
                    */}

                  </div>
                )}
                {(unsupportedTickers.length > 0 && tableHeaderArr.length > 0) && (
                  <div
                    className={`invalid-ticker-table fx-clm-space-between h-100 ${showAllInvalidTickers ? 'pb-0' : ''}`}
                    style={{ display: loading ? 'none' : '' }}
                  >
                    <DataList
                      {...this.props}
                      tableFor="Invalid"
                      renderHeader={(<div className="head-note"><p>Unsupported {(unsupportedTickers && unsupportedTickers.length > 1) ? 'securities' : 'security'}</p></div>)}
                      validTickers={this.state.data}
                      data={unsupportedTickers}
                      rowsArr={tableHeaderArr}
                      invalidTickers={invalidTickers}
                      magnifiTickers={magnifiTickers}
                      portfolioTotal={portfolioTotal}
                      allMagnifiTickers={allMagnifiTickers}
                      allocationTypeValue={allocationTypeValue}
                      realTimePriceLoading={realTimePriceLoading}
                      showAllInvalidTickers={showAllInvalidTickers}
                      fetchingAllTickersList={fetchingAllTickersList}
                      getRealTimePrice={getTickerRealTimePriceRequest}
                      errorHandle={this.errorHandle}
                      // getUpdatedData={this.getUpdatedData}
                      getUpdatedData={this.getInvalidPortfolioData}
                      checkTicker={this.checkTicker}
                      allocationChange={this.allocationChange}
                      getTickerListRequest={this.getTickerListRequest}
                      searchedCustomTicker={this.searchedCustomTicker}
                      deleteInvalidTickers={this.deleteInvalidTickers}
                      showAllInvalidTickersToggle={this.showAllInvalidTickersToggle}
                    />
                    {/*
                      <DataList
                        {...this.props}
                        tableFor="Invalid"
                        renderHeader={(<div className="head-note"><p>Unsupported {(unsupportedTickers && unsupportedTickers.length > 1) ? 'securities' : 'security'}</p></div>)}
                        data={unsupportedTickers}
                        rowsArr={tableHeaderArr}
                        validTickers={this.state.data}
                        invalidTickers={invalidTickers}

                        // data={this.state.data}
                        // validTickers={this.state.data}
                        // rowsArr={tableHeaderArr}
                        // invalidTickers={invalidTickers}

                        magnifiTickers={magnifiTickers}
                        portfolioTotal={portfolioTotal}
                        allocationTypeValue={allocationTypeValue}
                        realTimePriceLoading={realTimePriceLoading}
                        fetchingAllTickersList={fetchingAllTickersList}
                        getRealTimePrice={getTickerRealTimePriceRequest}
                        errorHandle={this.errorHandle}
                        // getUpdatedData={this.getUpdatedData}
                        getUpdatedData={this.getInvalidPortfolioData}
                        checkTicker={this.checkTicker}
                        allocationChange={this.allocationChange}
                        getTickerListRequest={this.getTickerListRequest}
                        searchedCustomTicker={this.searchedCustomTicker}
                        deleteInvalidTickers={this.deleteInvalidTickers}
                      />
                    */}
                  </div>
                )}
                {tableHeaderArr.length > 0 && !showAllInvalidTickers && this.state.data.length > 0 && (
                  <div className="valid-ticker-table fx-clm-space-between h-100" style={{ display: loading ? 'none' : '' }}>
                    <DataList
                      {...this.props}
                      tableFor="Valid"
                      renderHeader={(invalidTickers.length > 0 && <div className="head-note"><p>Positions forwarded into enhancer</p></div>)}
                      data={this.state.data}
                      validTickers={this.state.data}
                      rowsArr={tableHeaderArr}
                      invalidTickers={invalidTickers}
                      magnifiTickers={magnifiTickers}
                      portfolioTotal={portfolioTotal}
                      allMagnifiTickers={allMagnifiTickers}
                      allocationTypeValue={allocationTypeValue}
                      realTimePriceLoading={realTimePriceLoading}
                      fetchingAllTickersList={fetchingAllTickersList}
                      getRealTimePrice={getTickerRealTimePriceRequest}
                      errorHandle={this.errorHandle}
                      // getUpdatedData={this.getUpdatedData}
                      getUpdatedData={this.getValidPortfolioData}
                      checkTicker={this.checkTicker}
                      allocationChange={this.allocationChange}
                      getTickerListRequest={this.getTickerListRequest}
                      searchedCustomTicker={this.searchedCustomTicker}
                      deleteInvalidTickers={this.deleteInvalidTickers}
                    />
                  </div>
                )}
              </>
            )}
            {showAllInvalidTickers && this.state.data.length > 0 ?
              <div className="d-flex w-100 justify-content-center" style={{ paddingTop: 18 }}>
                <Button
                  className="btn secondary-btn fill p-1 mr-4"
                  type="button"
                  onClick={() => this.showAllInvalidTickersToggle(false)}
                  style={{ minWidth: 120 }}
                >
                  BACK
                </Button>
              </div>
              :
              <div className="d-flex w-100 justify-content-center" style={{ paddingTop: 18 }}>
                <Button
                  className="btn secondary-btn fill p-1 mr-4"
                  type="button"
                  disabled={submitDisabled}
                  onClick={() => this.onClickOk()}
                  style={{ minWidth: 120 }}
                >
                  SUBMIT
                </Button>
                <Button
                  className="btn secondary-btn p-1 ml-2"
                  type="button"
                  onClick={() => this.onModalClose()}
                  style={{ minWidth: 120 }}
                >
                  CANCEL
                </Button>
              </div>
            }
            <>{disclaimerMessage}</>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ loading }) => ({
  realTimePriceLoading: createLoadingSelector(['GET_TICKER_REAL_TIME_PRICE'])({ loading }),
});

const mapDispatchToProps = {
  checkTickerRequest,
  getTickerRealTimePriceRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CSVPreviewUpload);

import React, { Component } from 'react'
import { connect } from 'react-redux'
import $ from 'jquery'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { isEqual, find, isEmpty } from 'lodash'
import moment from 'moment'
import { updateBasketAgreement } from 'apis/profile'
import { getTickerListRequest } from 'actions/global';
import {
  quickTradeModalToggle,
  deleteAllQuickTradeTickersRequest,
  removeQuickTradeTickersRequest,
  quickTradeToSFMRequest,
  getBrokerDetailsRequest,
  executeQuickTradeRequest,
} from 'actions/quicktrade';
import { getApexAccountDetailsRequest } from 'actions/accounts';

import { createLoadingSelector } from 'selectors/loading-selector';
import { addEventToAnalytics, clearQuickTrade } from 'Utils';

// relative imports
import Tabs from './Tabs'

const classNames = require('classnames');

class QuickTrade extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: props.modalToggle || false,
      alert: true,
      showFunDetails: false,
      selectedFunDetails: undefined,
      basketAggreement: true
    }
    this.inRef = React.createRef()
  }

  componentDidMount() {
    document.body.classList['remove']('modal-open');
    setTimeout(() => {
      this.setState({ alert: false })
    }, 2000)
    $('.sfp-backdrop').click(this.toggle) // hack as backdrop click not working
    const { funds } = this.props;
    if (funds && funds.length) {
      const fundData = funds[0] || {};
      const tickers = (funds.map((kl) => kl.ticker).length > 0) ? funds.map((kl) => kl.ticker).join() : '';
      if (addEventToAnalytics) {
        addEventToAnalytics(
          'Quick Trade Open',
          'Quick Trade Open',
          'QUICK_TRADE_OPEN',
          {
            ticker: tickers,
            fundData,
          },
          true
        );
      }
    }
  }

  componentWillUnmount() {
    const { funds } = this.props;
    if (funds && funds.length === 0) {
      this.quickTradeToSFM();
    }
    $('.sfp-backdrop').unbind('click', this.toggle)
    document.body.classList['remove']('modal-open-sfm')
    if (addEventToAnalytics) {
      addEventToAnalytics(
        'Quick Trade Close',
        'Quick Trade Close',
        'QUICK_TRADE_CLOSE',
        {},
        true
      );
    }
  }

  autoOpenModal = () => {
    const { quickTradeModalToggle } = this.props;
    if (quickTradeModalToggle) {
      quickTradeModalToggle({ modalToggle: true });
    }
  }

  updateBasketAggrementStatus = async () => {
    try{
      await updateBasketAgreement();
    }catch(err){}
    this.setState({ basketAggreement: false });
  }

  toggle = (buttonClickedFrom = '') => {
    // console.log('==== buttonClickedFrom ====> ', buttonClickedFrom);
    const { quickTradeModalToggle, modalToggle } = this.props;
    if (quickTradeModalToggle) {
      quickTradeModalToggle({ modalToggle: !modalToggle });
    }
    this.quickTradeToSFM(buttonClickedFrom);
  }

  deleteAll = () => {
    const { deleteAllQuickTradeTickersRequest, funds } = this.props;
    if (funds && funds.length > 0) {
      const tickers = (funds.map((kl) => kl.ticker).length > 0) ? funds.map((kl) => kl.ticker).join() : '';
      if (addEventToAnalytics) {
        addEventToAnalytics(
          'Quick Trade Fund Delete All',
          'Quick Trade Fund Delete All',
          'QUICK_TRADE_FUND_DELETE_ALL',
          { ticker: tickers },
          true
        );
      }
    }
    this.setState({ open: false }, () => {
      setTimeout(() => {
        if (deleteAllQuickTradeTickersRequest) {
          deleteAllQuickTradeTickersRequest('', (quickTradeState) => {
            this.clearQuickTradeAndSession(quickTradeState);
          });
        }
      }, 300)
    })
  }

  removeFund = (fund) => {
    if (fund) {
      if (addEventToAnalytics) {
        addEventToAnalytics(
          'Quick Trade Fund Delete',
          'Quick Trade Fund Delete',
          'QUICK_TRADE_FUND_DELETE',
          {
            ticker: fund.ticker,
          },
          true
        );
      }
      const { removeQuickTradeTickersRequest } = this.props;
      if (removeQuickTradeTickersRequest) {
        removeQuickTradeTickersRequest({ removeTicker: fund.ticker }, (quickTradeState) => {
          this.clearQuickTradeAndSession(quickTradeState);
        });
      }
    }
  }

  quickTradeToSFM = (buttonClickedFrom = '', redirectTo = '') => {
    const { quickTradeToSFMRequest } = this.props;
    if (quickTradeToSFMRequest) {
      quickTradeToSFMRequest('', (quickTradeState) => {
        this.clearQuickTradeAndSession(quickTradeState, redirectTo);
      });
    }
  }

  executeHandler = (payload) => {
    if (addEventToAnalytics) {
      addEventToAnalytics(
        'Quick Trade Execute Clicked',
        'Quick Trade Execute Clicked',
        'QUICK_TRADE_EXECUTE_CLICKED',
        { trade: payload },
        true
      );
    }
    const { executeQuickTradeRequest, loggedIn } = this.props;
    if (executeQuickTradeRequest && loggedIn) {
      executeQuickTradeRequest(payload, (quickTradeState) => {
        this.clearQuickTradeAndSession(quickTradeState);
      });
    }
  }

  clearQuickTradeAndSession = (quickTradeState, redirectTo = '') => {
    const { history } = this.props;
    if (history && quickTradeState && quickTradeState.funds && quickTradeState.funds.length === 0) {
      clearQuickTrade();
      let qq = window.sessionStorage.getItem('query');
      let _location = '/securities' + (qq ? `?query=${encodeURIComponent(qq)}` : '');
      if (redirectTo !== '') {
        return history.push(redirectTo);
      }
      return history.push(_location);
    }
  }

  getOrderDetails = (order) => {
    // console.log('===== ORDER =====');
    // console.log(order);
    // console.log('=================');
  }

  render() {
    const { showFunDetails, selectedFunDetails, open } = this.state
    const {
      funds,
      items,
      mobileDevice,
      selectedItems,
      importedAccounts,
      fetchFundsLoading,
      modalToggle,
    } = this.props;

    if (funds.length === 0) return null ;

    const size = modalToggle ? 'full' : this.state.alert ? 'anim' : 'min'
    const classes = mobileDevice ? ' modal-full-screen' : ''

    const renderBtn = (
      <button
        className={`close ${open ? 'media-close' : ''}`}
        onClick={() => this.quickTradeToSFM('Quick Trade Header Close Icon')}
      >
        <div className={`hamburger ${ size === 'full' && !showFunDetails ? ' active' : ''} `}>
          <div className="hamburger-box">
            <div className="hamburger-inner"></div>
          </div>
        </div>
        <i className={`far text-white ${modalToggle ? 'fa-times' : 'fa-plus'}`}></i>
      </button>
    );;

    let selectedFunds = selectedItems && selectedItems.length > 0 ? selectedItems : items;

    if (document.body) {
      document.body.classList[modalToggle ? 'add' : 'remove']('modal-open-sfm');
    }

    return (
      <Modal
        isOpen
        backdrop
        className="fund-container"
        modalClassName={'sfp sel-dropdown quick-trade-modal-container '+size+classes}
        className="modal-lg"
        contentClassName="bg-ssf-blue-d2 quick-trade-modal-wrapper"
        backdropClassName="sfp-backdrop"
        innerRef={this.inRef}
        toggle={() => this.toggle('quick-trade-back-drop')}
      >
        <ModalHeader
          className="media-details-tab bg-sfmodal-header text-white modal-header-title-body"
          toggle={() => this.toggle('quick-trade-header-area')}
          close={renderBtn}
          tag="div"
        >
          <div className="modal-header-title-container" onClick={() => this.toggle('quick-trade-header-area')}>
            <span onClick={() => this.toggle('quick-trade-header-text')} className="font-weight-bold">
              Quick Trade
            </span>
          </div>
        </ModalHeader>
        <ModalBody className="p-0 bg-sfmodal-grey font-one">
          <Tabs
            {...this.props}
            card={this.props.card || {}}
            showFunDetails={false}
            selectedFunDetails={selectedFunDetails}
            open={size === 'full' && !showFunDetails}
            deleteAll={this.deleteAll}
            removeFund={this.removeFund}
            executeHandler={this.executeHandler}
            getOrderDetails={this.getOrderDetails}
            quickTradeToSFM={this.quickTradeToSFM}
            updateBasketAggrementStatus={this.updateBasketAggrementStatus}
            basketAggreement={this.state.basketAggreement}
          />
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = ({ auth, global, quicktrade, sfm, loading, profile }) => {
  return {
    loggedIn: auth.loggedIn,
    funds: quicktrade.funds,
    broker: quicktrade.broker,
    myportfolio: sfm.myportfolio,
    profile: profile.profile,
    mobileDevice: global.mobileDevice,
    modalToggle: quicktrade.modalToggle,
    magnifiTickers: global.magnifiTickers,
    isBrokerRegistered: (quicktrade.broker &&
                        (Object.keys(quicktrade.broker).length > 0) &&
                        (typeof quicktrade.broker.status !== 'undefined' &&
                        quicktrade.broker.status !== 'Create')),
    fetchingAllTickersList: createLoadingSelector(['GET_ALL_TICKER_LIST'])({ loading }),
    profileLoading: createLoadingSelector(['PROFILE'])({ loading }),
    loadBrokerDetails: createLoadingSelector(['GET_BROKER_DETAILS'])({ loading }),
    loading: createLoadingSelector([
      'ADD_TICKERS_TO_QUICK_TRADE',
      'REMOVE_QUICK_TRADE_TICKERS',
      'DELETE_ALL_QUICK_TRADE_TICKERS',
      'EXECUTE_QUICK_TRADE_ORDERS'
    ])({ loading }),
  }
}

const mapDispatchToProps = {
  getTickerListRequest,
  quickTradeModalToggle,
  quickTradeToSFMRequest,
  getBrokerDetailsRequest,
  executeQuickTradeRequest,
  getApexAccountDetailsRequest,
  removeQuickTradeTickersRequest,
  deleteAllQuickTradeTickersRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuickTrade)

import React, { useState } from 'react'

import { Button } from 'antd';
import { InfoCircleOutlined, CheckCircleOutlined, StopOutlined } from '@ant-design/icons';

import { addEventToAnalytics } from 'Utils'

const LoginErrorMessage = ({ resendEmailAction, disabledAction = false, email, error }) => {
  // console.log('render LoginErrorMessage')
  const [emailSent, setEmailSentFlag] = useState(false)
  const resendEmail = () => {
    // console.log('-- resendEmail --', resendEmailAction)
    addEventToAnalytics(
      'Click Resend Okta Email',
      'Click Resend Okta Email',
      'CLICK_RESEND_OKTA_EMAIL',
      {
        email,
        cta_clicked: 'resend_email',
        account_status: 'pending_activation'
      }
    )
    if (resendEmailAction) {
      resendEmailAction({
        email,
      }, (response) => {
        console.log('LoginErrorMessage response -> ', response)
        if (response && response.oktaPasswordResetEmailSent) {
          setEmailSentFlag(response.oktaPasswordResetEmailSent)
        }
      });
    }
  }
  // console.log('disabledAction --> ', disabledAction)

  console.log('error -->', error)

  return (
    <div className={'okta-login-error-message-container'}>
      {(error && error.length && error.includes('locked')) ? (
        <>
          <div className={'okta-login-error-message-head'}>
            <StopOutlined className={'error'} />
            <span>Your account is locked.</span>
          </div>
          <p>
            Please contact <a className="subtitle mail" href="mailto:support@magnifi.com">support@magnifi.com</a>
          </p>
        </>
      ) : (
        emailSent ? (
          <>
            <div className={'okta-login-error-message-head'}>
              <CheckCircleOutlined className={'success'} />
              <span>Email sent successfully!</span>
            </div>
            <p>
              An email to reset your password has just been sent from <span style={{ color: '#1E5F91' }}>noreply@magnifi.com</span>. Don't see it? Don’t forget to check your junk or spam folder!
            </p>
          </>
        ) : (
          <>
            <div className={'okta-login-error-message-head'}>
              <InfoCircleOutlined />
              <span>Having trouble signing in?</span>
            </div>
            <p>
              Reminder, you should have received an email to reset your password as part of a planned security upgrade.
            </p>
            <Button type="link" block disabled={disabledAction} onClick={resendEmail}>
              Click here to re-send that email
            </Button>
          </>
        )
      )}
    </div>
  )
}

export default LoginErrorMessage

import { takeEvery, fork, call, put, all, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { push } from 'connected-react-router';
import moment from 'moment';
import get from 'lodash/get';
import _ from 'lodash';
import * as AccountsApis from 'apis/account';
import * as ProfileApi from 'apis/profile';

import * as actions from 'actions/accounts';
import qs from 'query-string'

// import actions-types
import ActionTypes from 'action-types';

// import actions
import {
  setAccountsState,
  resetAccountsState,
  getAccountsPortfolioDataByIDRequest,
  getAccountsPortfolioDataByIDFetch,
  getAccountsPortfolioDataByIDSuccess,
  uploadAccountsPortfolioDataByIdFetch,
  uploadAccountsPortfolioDataByIdSuccess,
  resetAccountsStateSuccess,
  fetchAlertCount,
  getAlertCountRequest,
  getAlertCountFetch,
  getAlertCountSuccess,
  getAccountsTrendingSearchesFetch,
  getAccountsTrendingSearchesSuccess,
  cancelDepositsFetch,
  cancelDepositsSuccess,
  refreshApexStateRequest,
  refreshApexStateFetch,
  refreshApexStateSuccess,
  getApexAccountDetailsRequest,
  getApexAccountDetailsFetch,
  getApexAccountDetailsSuccess,
  cancelTradesFetch,
  cancelTradesRequest,
  cancelTradesSuccess
} from 'actions/accounts';
import { getPortfolioDetailsForSFMSuccess } from 'actions/sfm';

// import selectors
import { getSFMItems } from 'selectors/sfm';
import { getAccountsState } from 'selectors/accounts';

// import apis
import {
  cancelDeposits,
  cancelTrades,
  fetchApexData,
} from 'apis/profile';
import {
  fetchAccountSummary,
  getMarketResultAPI,
  getPortfolioResultAPI,
  fetchDailyReturns,
  getPortfolioGains,
} from 'apis/account';
import {
  fetchReturnsDataV1,
  fetchRealTimePrices,
  fetchUpAndDownChart,
  getTrendingQueries,
  fetchApexBalance,
} from 'apis/funds';
import {
  checkTickerExist,
} from 'apis/portfolio'
import {
  fetchModelPfHoldingsData,
  fetchClientPortfolioHoldingsById,
} from 'apis/clientportfolio';

// import functions
import { uniqValues, getNestDefault, nameToDateRange, arrayToHash, getPortfolioBenchmarkOfSelectedTicker, convertToUpperCase } from 'Utils';
import { DateRange, cn, CN } from 'DataSet'
import { calcCumulativeReturns } from 'Stats';
import {
  accountsPageUpdatePrice,
  portfolioDataToCSVUploadPortfolioFormat,
  dropdownAndReturnSeries,
  apexRedirectionWhereTo,
  lineChartStartDate,
  dailyReturnSeries,
} from 'layouts/utils';
import {
  getCurrentPfStats,
  getCalculatedModelPortfolioEnhanceStats,
  getCalculatedEnhanceStats,
} from 'layouts/WebDashboard/AccountsPage/utils';

import Emitter from 'realTimePrice/emitter';

export function* getAccountsPortfolioDataById(action) {
  const { payload, callback } = action;
  try {
    const { filename, portfolioType, _for, allocatedModel, finalizedSponsorModel } = payload;
    // console.log(payload);
    if (filename && portfolioType) {
      yield put(getAccountsPortfolioDataByIDFetch(payload));

      const _profileReduxState = yield select(state => state.profile);
      let _onboardData = {
        selectedPortfolioType: portfolioType,
        selectedPortfolioPath: filename,
      };
      let ticks;
      let retData = [], positions = [], portfolioFunds = [], portfolioValue = 0, _upDownDataSet = [], invalidTickerFunds = [], tickerNotSupported = [], accountBalanceResponse;

      // calling notification api for Alerts
      if (_profileReduxState && _profileReduxState.profile) {
        let apex_account_id = get(_profileReduxState.profile, 'apex.account[0].id');
        if (apex_account_id) {
          yield put(getAlertCountRequest({ apex_account_id, count: 3 }));
        }
      }

      if (allocatedModel) {
        console.log(' === CLIENT PORTAL REQUEST === ');
        [ portfolioFunds, accountBalanceResponse ] = yield all([
          call(fetchModelPfHoldingsData, {ticker: filename}),
          // call(fetchUpAndDownChart),
          call(fetchApexBalance),
        ]);
        portfolioFunds = portfolioFunds.data;
        console.log(' ==============================');
      } else {
        if (filename === 'Watchlist') {
          //load Watchlist Data
          let [ _summary, accountBalanceResponse ] = yield all([
            call(fetchAccountSummary),
            // call(fetchUpAndDownChart),
            call(fetchApexBalance),
          ]);
          portfolioFunds = (_summary && _summary.results) ? _summary.results : [];
          // positions = (_positions && _positions.positions) ? _positions.positions : [];
        } else if (portfolioType === 'MarketPlace' && typeof finalizedSponsorModel === 'undefined') {
          //load MarketPlace Data
          // portfolioFunds = yield call(getMarketResultAPI, filename);
          [ portfolioFunds, accountBalanceResponse ] = yield all([
            call(getMarketResultAPI, filename),
            // call(fetchUpAndDownChart),
            call(fetchApexBalance),
          ]);
        } else {
          // portfolioFunds = yield call(getPortfolioResultAPI, filename);
          [ portfolioFunds, accountBalanceResponse ] = yield all([
            call(getPortfolioResultAPI, filename),
            // call(fetchUpAndDownChart),
            call(fetchApexBalance),
          ]);
        }
      }

      let apexBalanceData = {};
      if (accountBalanceResponse && accountBalanceResponse.data) {
        apexBalanceData = accountBalanceResponse.data;
      }

      if (portfolioFunds) {
        if (portfolioFunds.message) {
          if (portfolioFunds.message === "Failed") {
            // yield put(setPortfolioState({ onBoardScreen: true }));
            // NOTE:  return back to on-BoardScreen with Failed to load message.
            yield put(push('/accounts/exception/400'));
            yield put(resetAccountsStateSuccess());
            yield put(getAccountsPortfolioDataByIDSuccess({
              onboardData: _onboardData,
              apexBalanceData,
              summary: {
                portfolioFunds: [],
                pageCrash: `Failed to load data for ${filename}`,
              },
            }));
          } else {
            // upload-portfolio respond message: "Failed" - "No trades placed", but apex data are present, for that
            // if you want to show summary page for APEX only.
            const _profReduxState = yield select(state => state.profile);
            let _profileState = (_profReduxState && _profReduxState.profile && _profReduxState.profile.apex) ? _profReduxState.profile.apex : null;
            if (_profileState !== null && portfolioFunds.message === "No trades placed") {
              const _apexRedirectionWhereTo = apexRedirectionWhereTo({ profile: _profReduxState.profile, push });
              if (_apexRedirectionWhereTo !== null && _apexRedirectionWhereTo !== 'Not to apex route') {
                yield put(push(_apexRedirectionWhereTo));
              }
              yield put(getAccountsPortfolioDataByIDSuccess({
                onboardData: _onboardData,
                apexBalanceData,
                summary: {
                  portfolioFunds: [],
                  pageCrash: `Failed to load data for ${filename}`,
                },
              }));
            } else {
              // yield put(setPortfolioState({ onBoardScreen: true }));
              // NOTE:  return back to on-BoardScreen with Failed to load message.
              // yield put(push('/accounts/exception/no-trade'));
              yield put(push('/accounts/apex/empty-result'));
              yield put(resetAccountsStateSuccess());
              yield put(getAccountsPortfolioDataByIDSuccess({
                onboardData: _onboardData,
                apexBalanceData,
                summary: {
                  portfolioFunds: [],
                  pageCrash: `Failed to load data for ${filename}`,
                },
              }));
            }
          }
        } else if (portfolioFunds.length > 0 ) {
          let ticks1 = portfolioFunds.filter(kl => kl.type !== 'BASKET').map(e => e.ticker || e.script || e.symbol ); // ignore BASKET type funds for realtimeprice api call
          let chartTicks = portfolioFunds.map(e => e.ticker || e.script || e.symbol );

          let ticks = [...new Set([...ticks1])].join(',');
          let chartAPITicks = [...new Set([...chartTicks])].join(',');
          // NOTE: ======= creating socket connection =======
          let tickerListToSubscribe = portfolioFunds.filter(lk => lk.type !== 'MF' && lk.type !== 'SMA' && lk.type !== 'CEF' && lk.type !== 'BASKET').map(e => e.ticker || e.script || e.symbol) || [];
          // console.log(tickerListToSubscribe);
          // removed below "ANKIT" if not testing
          // tickerListToSubscribe.push("ANKIT");
          // console.log('=== tickerListToSubscribe ===', tickerListToSubscribe, typeof tickerListToSubscribe);
          Emitter.emit('SUBSCRIBE_TO_STREAM', tickerListToSubscribe);
          // console.log('Subscribed');
          // NOTE: ======= created connection =======

          let [retData, fundsPrice] = yield all([
            call(fetchDailyReturns, { tickers: chartAPITicks }),
            call(fetchRealTimePrices, { tickers: ticks }),
          ]);
          // console.log(retData);
          if (fundsPrice && retData) {
            const updatedFundsPriceArr = (fundsPrice.funds && fundsPrice.funds.length > 0) ? accountsPageUpdatePrice(fundsPrice.funds, portfolioFunds, 'script') : portfolioFunds;
            const updatedPositionsPriceArr = (fundsPrice.funds && fundsPrice.funds.length > 0 && positions && positions.length > 0) ? accountsPageUpdatePrice(fundsPrice.funds, positions, 'symbol') : positions;
            if (updatedPositionsPriceArr && updatedPositionsPriceArr.funds) {
              positions = updatedPositionsPriceArr.funds;
            }
            if (updatedFundsPriceArr && updatedFundsPriceArr.funds) {
              portfolioFunds = updatedFundsPriceArr.funds;
            }
            retData.forEach((e,index) => {
              // NOTE: IF YOU CHANGE ANYTHING IN THIS MAP FUNCTION,
              // --> MAKE SURE TO CHECK => map of "retData" => `selectReturnYear` function => in 'AccountsPageV2/Pages/Summary/index.js' file as well
              e._selectedYear = 'l1m';
              if (e.returns.length > 0) {
                let n = getNestDefault(['returns', 'length'], e, 0);
                if (n > 0) {
                  e.returns.forEach((e,i) => {retData[index].returns[i].v =  retData[index].returns[i].v/100; return e.v = +e.v})
                  let DARS = dailyReturnSeries({ returns: e.returns, defaultYear: e._selectedYear }); // dropdownAndReturnSeries({ returns: e.returns });
                  // console.log(e.ticker);
                  // console.log(DARS);
                  if (DARS && DARS.dropdown && DARS.cumlSeries && DARS.cumlSeries[e._selectedYear]) {
                    if(DARS.dropdown['l1m'] && DARS.dropdown['l1m'].disabled === true){
                      e._selectedYear = 'max'
                    }
                    // NOTE: tooltip 'start_date' logic for line-chart
                    // portfolioFunds.forEach((kq, i) => {
                    //   if (kq && (kq.ticker || kq.script || kq.symbol) === e.ticker) {
                    //     if (kq && DARS.outSeries && DARS.outSeries[e._selectedYear] && DARS.outSeries[e._selectedYear].length > 0) {
                    //       // accounts page retData structure is not same as discovery fund's _returnsChart, so make it.
                    //       let RETURNS_CHART_SERIES = DARS.outSeries[e._selectedYear];
                    //       kq._returnsChart = {
                    //         start: RETURNS_CHART_SERIES[0].d,
                    //         end: RETURNS_CHART_SERIES[RETURNS_CHART_SERIES.length - 1].d,
                    //         series: RETURNS_CHART_SERIES,
                    //       };
                    //       // kq = lineChartStartDate(kq);
                    //       if (lineChartStartDate(kq) && lineChartStartDate(kq)._tickerStartDate) {
                    //         kq._tickerStartDate = lineChartStartDate(kq)._tickerStartDate
                    //       }
                    //       // if (typeof kq._tickerStartDate !== 'undefined' && kq._tickerStartDate) {
                    //       //   RETURNS_CHART_SERIES = [
                    //       //     { d: kq._tickerStartDate, v: 0, cuml: 0, cumlLog: 0 },
                    //       //     ...RETURNS_CHART_SERIES,
                    //       //   ];
                    //       // }
                    //       // else {
                    //       //   // if _tickerStartDate not present then push default 'Start'
                    //       //   RETURNS_CHART_SERIES = [
                    //       //     { d: 'Start', v: 0, cuml: 0, cumlLog: 0 },
                    //       //     ...RETURNS_CHART_SERIES,
                    //       //   ];
                    //       // }
                    //       // set return chart series
                    //       e._series = RETURNS_CHART_SERIES;
                    //     }
                    //   }
                    // });
                    e._dropdown = DARS.dropdown;
                    e._series = DARS.cumlSeries[e._selectedYear];
                    e._start = DARS.cumlSeries.start;
                    e._end = DARS.cumlSeries.end;
                  }
                }
              }
            });
            const reducerSfmItems = yield select(getSFMItems);
            let tickerWiseRet = arrayToHash(retData, CN['Ticker']);
            let purchaseValuesCheck = [], SUM_OF_ALL_CURRENT_VALUE = 0;
            portfolioFunds.forEach((e,index) => {
              e._portfolioFund = true;
              e.ticker = e.ticker || e.script || e.symbol;
              e.price = e.price || e.current_price;
              e.transaction_date = e.transaction_date || e.purchase_date;
              e.nav = e.nav || e.fees;
              e._realTimePrice = e.price; // realTimePrice mapped to 'price' key by executing 'accountsPageUpdatePrice' func
              e.quantity = e.quantity || e.shares;
              if (e.type !== 'BASKET') {
                e.market_value = e.price * e.quantity;
              }
              e.return = tickerWiseRet[e.ticker];

              // calculating weight on UI end
              if (!isNaN(e.current_price) &&
                !isNaN(e.shares) &&
                e.shares !== '' &&
                e.current_price !== '' &&
                typeof e.shares !== 'undefined' &&
                typeof e.current_price !== 'undefined'
              ) {
                e.current_value = Number(e.current_price) * Number(e.shares);
                if (!isNaN(e.current_value)) {
                  SUM_OF_ALL_CURRENT_VALUE += e.current_value;
                }
              }

              // e.original_weight = Number(e.original_weight) || e.weight || 0;

              if (!e.invalid) {
                portfolioValue += (e.price * e.quantity);
              }
              if (e.purchase_price == null && e.purchase_date == null) purchaseValuesCheck.push(e.script);

              let i = reducerSfmItems.findIndex(s => cn(s, 'Ticker') === cn(e, 'Ticker'));
              if (i >= 0) {
                e._selected = true;
              }
              reducerSfmItems.forEach((s, i) => {
                if (cn(s, 'Ticker') === cn(e, 'Ticker')) {
                  e._selected = s._selected;
                  e._selector = s._selector;
                  e._selectedType = s._selectedType;
                }
              });
            });

            portfolioFunds.forEach((e,index) => {
              // calculating weight on UI end
              if (!isNaN(e.current_value) &&
                e.current_value !== '' &&
                typeof e.current_value !== 'undefined'
              ) {
                let weightBe = e.current_value/SUM_OF_ALL_CURRENT_VALUE;
                if (!isNaN(weightBe)) {
                  e.weight = weightBe * 100;
                  e.original_weight = Number(e.original_weight) || e.weight || 0;
                }
              }
            });

            const _portfolioFund = JSON.parse(JSON.stringify(portfolioFunds));
            tickerNotSupported = _portfolioFund.filter((gh) => gh.invalid && gh.invalid_type === 'unsupported');
            invalidTickerFunds = _portfolioFund.filter((kl) => kl.invalid && kl.invalid_type === 'unrecognized');
            // portfolioFunds = _portfolioFund.filter((gh) => !gh.invalid);  -- done by sid.
            // when api gives 'validTickers' key in each fund's data then use below line
            // portfolioFunds = portfolioFunds.filter((kl) => kl.invalid);
            ticks = portfolioFunds.map(e => e.ticker);
            const checkPurchaseValues = purchaseValuesCheck.length === portfolioFunds.length;
            const onboardData = yield select(state => state.enhancer.onboardData);
            const activeFund = portfolioFunds.length > 0 ? (portfolioFunds[0].ticker || portfolioFunds[0].symbol) : '';

            yield put(getAccountsPortfolioDataByIDSuccess({
              apexBalanceData,
              portfolioName: filename,
              portfolioValue,
              tickers: ticks,
              onboardData: {
                ...onboardData,
                selectedPortfolioType: portfolioType,
              },
              summary: {
                portfolioFunds,
                invalidTickerFunds,
                tickerNotSupported,
                retData,
                activeFund,
                realTimePrice: fundsPrice,
                checkPurchaseValues,
                portfolioTickers: ticks,
                portfolioRetData: retData[0].returns,
              },
            }));

            // updating SFM state
            yield put(getPortfolioDetailsForSFMSuccess({
              myportfolio: portfolioFunds,
            }));

            // NOTE: Step 2: execute `callback` function
            if (callback && typeof callback == 'function') {
              callback({
                apexBalanceData,
                portfolioName: filename,
                portfolioValue,
                tickers: ticks,
                onboardData: {
                  ...onboardData,
                  selectedPortfolioType: portfolioType,
                },
                summary: {
                  portfolioFunds,
                  invalidTickerFunds,
                  tickerNotSupported,
                  retData,
                  activeFund,
                  realTimePrice: fundsPrice,
                  checkPurchaseValues,
                  portfolioTickers: ticks,
                  portfolioRetData: retData[0].returns,
                },
              });
            }

          } else {
            throw '-- Funds price & Returns api error --'
          }
        } else {
          yield put(getAccountsPortfolioDataByIDSuccess({
            apexBalanceData,
            onboardData: _onboardData,
            summary: {
              portfolioFunds: [],
            },
          }));
        }
      } else {
        yield put(getAccountsPortfolioDataByIDSuccess({
          apexBalanceData,
          onboardData: _onboardData,
          summary: {
            portfolioFunds: [],
          },
        }));
      }
    } else {
      console.log('---- filename not present ----');
      throw '---- filename not present ----';
    }
  } catch (error) {
    const errorDetails = get(error, 'response.data', error.stack)
    console.log(errorDetails)
    yield put(getAccountsPortfolioDataByIDSuccess());
  }
}

function* getAlertsCount(action) {
  const { payload } = action;
  try {
    const { apex_account_id, count } = payload;
    if (typeof apex_account_id !== 'undefined' && typeof count !== 'undefined') {
      yield put(getAlertCountFetch());
      const response = yield call(AccountsApis.fetchAlertCount, qs.stringify(payload))
      if(response && response.data && response.data.result && Array.isArray(response.data.result)) {
        // yield put(actions.setAlertCountSuccess(response.data.result));
        yield put(getAlertCountSuccess({
          alertCountData: {
            data: response.data.result,
            error: null
          },
        }));
      } else {
        yield put(getAlertCountSuccess({
          alertCountData: {
            data: null,
            error: "No Alerts Yet!"
          },
        }));
        // yield put(actions.setAlertCountError("No Alerts Yet!"));
      }
    } else {
      throw '-- payload is empty --'
    }
  } catch (error) {
    console.log(error);
    const errorDetails = get(error, 'response.data', error.stack)
    // yield put(actions.setAlertCountError(errorDetails));
    yield put(getAlertCountSuccess({
      alertCountData: {
        data: null,
        error: errorDetails,
      },
    }));
  }
}

function* getTrendingFeeds(action) {
  try {
    yield put(getAccountsTrendingSearchesFetch());
    const response = yield call(getTrendingQueries);
    if(response && response.length > 0 && Array.isArray(response)) {
      yield put(getAccountsTrendingSearchesSuccess({
        trendingQueries: response,
      }));
    } else {
      yield put(getAccountsTrendingSearchesSuccess({
        trendingQueries: []
      }));
    }
  } catch (error) {
    console.log(error);
    yield put(getAccountsTrendingSearchesSuccess({
      trendingQueries: []
    }));
  }
}

function* cancelDepositsFunds(action) {
  const { payload } = action;
  try {
    yield put(cancelDepositsFetch());
    const response = yield call(cancelDeposits, payload)
    // console.log(response);
    if (response && response.status === 200) {
      const responseData = response.data && response.data.result;
      // console.log(responseData);
      yield put(cancelDepositsSuccess());
    } else {
      yield put(cancelDepositsSuccess());
    }
    yield put(refreshApexStateRequest());
  } catch (error) {
    console.log(error);
    yield put(cancelDepositsSuccess());
  }
}

function* refreshApexAccountsState(action) {
  const { payload } = action;
  try {
    yield put(refreshApexStateFetch());
    yield put(getApexAccountDetailsRequest());
    const _profileReduxState = yield select(state => state.profile);
    const _accountsReduxState = yield select(state => state.accounts);

    const currentApexData = _profileReduxState.profile.apex;
    const currentApexAlertCount = currentApexData.alert_count;

    // const response = yield call(fetchApexData);
    const details = yield call(ProfileApi.fetchProfileApexDetails, {})
    const response = yield select(state => state.profile.profile)

    response["apex"] = { ...response["apex"], deposits: details.data.deposits }

    if (response && typeof response.apex !== 'undefined' && response.apex) {
      const apexData = response.apex;
      // if alert_count changed then dispatch GET_ACCOUNTS_PORTFOLIO_BY_ID_REQUEST to reload holdings data!
      if (apexData &&
        typeof apexData.alert_count !== 'undefined' &&
        currentApexAlertCount !== apexData.alert_count &&
        _accountsReduxState.portfolioName !== '' &&
        typeof _accountsReduxState.portfolioName !== 'undefined'
      ) {
        yield put(getAccountsPortfolioDataByIDRequest({
          filename: _accountsReduxState.portfolioName,
          portfolioType: 'My Portfolio',
        }));
      } else {
        // calling notification api for Alerts
        if (_profileReduxState && _profileReduxState.profile) {
          let apex_account_id = get(_profileReduxState.profile, 'apex.account[0].id');
          if (apex_account_id) {
            yield put(getAlertCountRequest({ apex_account_id, count: 3 }));
          }
        }
      }
      // default success dispatch
      yield put(refreshApexStateSuccess({
        apex: {
          ...currentApexData,
          ...apexData,
        },
      }));
    } else {
      yield put(refreshApexStateSuccess());
    }
  } catch (error) {
    console.log(error);
    yield put(refreshApexStateSuccess());
  }
}

function* getApexAccountDetails(action) {
  const { payload, callback } = action;
  try {
    yield put(getApexAccountDetailsFetch());
    const accountBalanceResponse = yield call(fetchApexBalance);
    // console.log(accountBalanceResponse);
    if (accountBalanceResponse && accountBalanceResponse.status === 200 && accountBalanceResponse.data) {
      let apexBalanceData = accountBalanceResponse.data;
      yield put(getApexAccountDetailsSuccess({
        apexBalanceData,
      }));
    } else {
      yield put(getApexAccountDetailsSuccess());
    }
    if (callback && typeof callback === 'function') {
      callback(accountBalanceResponse);
    }
  } catch (error) {
    console.log(error);
    yield put(getApexAccountDetailsSuccess());
  }
}


function* cancelTradesTransaction(action) {
  const { payload } = action;
  try {
    yield put(cancelTradesFetch());
    const response = yield call(cancelTrades, payload)
    // console.log(response);
    if (response && response.status === 200) {
      const responseData = response.data && response.data.result;
      const profileData = yield select(state => state.profile.profile)
      let latestApex = { ...profileData["apex"], orders: profileData.apex.orders.map(el => {
        if(el.id === payload.request_id){
          el['status'] = 'CANCEL'
        }
        return el
      }) }
      yield put(refreshApexStateSuccess({
        apex: latestApex
      }));
      // console.log(responseData);
      yield put(cancelTradesSuccess());
    } else {
      yield put(cancelTradesSuccess());
    }
    yield put(refreshApexStateRequest());
  } catch (error) {
    console.log(error);
    yield put(cancelTradesSuccess());
  }
}

function* accountSaga() {
  yield all([
    fork(takeEvery, ActionTypes.GET_ACCOUNTS_PORTFOLIO_BY_ID_REQUEST, getAccountsPortfolioDataById),
    // fork(takeEvery, ActionTypes.UPLOAD_ACCOUNTS_PORTFOLIO_DATA_BY_ID_REQUEST, uploadAccountsPortfolioDataById),
    fork(takeEvery, ActionTypes.GET_ALERT_COUNT_REQUEST, getAlertsCount),
    fork(takeEvery, ActionTypes.GET_ACCOUNTS_TRENDING_SEARCHES_REQUEST, getTrendingFeeds),
    fork(takeEvery, ActionTypes.CANCEL_DEPOSITS_REQUEST, cancelDepositsFunds),
    fork(takeEvery, ActionTypes.CANCEL_TRADES_REQUEST, cancelTradesTransaction),
    fork(takeEvery, ActionTypes.REFRESH_APEX_STATE_REQUEST, refreshApexAccountsState),
    fork(takeEvery, ActionTypes.GET_APEX_AMOUNT_DETAILS_REQUEST, getApexAccountDetails),
  ])
}

export default accountSaga

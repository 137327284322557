import React, { useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { withHeaderFooter } from '../shared/HeaderFooter';
// import { StackedAreaChart } from '../shared/Charts';
import PortfolioLineChart from '../shared/PortfolioLineChart';
import { CustomBarChart } from '../shared/BarChart';
import PortfolioContext from '../PortfolioContext'
import moment from 'moment'

const PortfolioPerformance = withHeaderFooter((props) => {
  const { createdDate } = props;
  const reportData = useContext(PortfolioContext);

  console.log('========= PortfolioPerformance =========');
  console.log(reportData);
  console.log('========================================');

  let { portfolio } = reportData;
  let { currentPerformanceImpact, enhancedPerformanceImpact } = portfolio;

  let returnsCalcBasedOn = '3-Year',
      volatilityCalcBasedOn = '3-Year',
      currentPortfolioVolatility = '',
      enhancedPortfolioVolatility = '',
      portfolioReturnsData = [],
      currentPortfolioDrawdown = currentPerformanceImpact && currentPerformanceImpact.dMax ? currentPerformanceImpact.dMax : '',
      enhancedPortfolioDrawdown = enhancedPerformanceImpact && enhancedPerformanceImpact.dMax ? enhancedPerformanceImpact.dMax : null;

  let portfolioEnhanceReplace = portfolio.enhanceReplace;
  let currentStats = currentPerformanceImpact.pfRet3y;
  let enhanceStats = enhancedPerformanceImpact.pfRet3y;
  let currentReturnsLegend = currentPerformanceImpact.anRet3yr;
  let enhancedReturnsLegend = enhancedPerformanceImpact.anRet3yr;

  if (enhancedPerformanceImpact) {
    if (enhancedPerformanceImpact.pfRet10y && enhancedPerformanceImpact.pfRet10y.length) {
      returnsCalcBasedOn = '10-Year';
      volatilityCalcBasedOn = '10-Year';
      currentStats = currentPerformanceImpact.pfRet10y;
      enhanceStats = enhancedPerformanceImpact.pfRet10y;
      currentReturnsLegend = currentPerformanceImpact.anRet10yr;
      enhancedReturnsLegend = enhancedPerformanceImpact.anRet10yr;
      portfolioReturnsData = enhancedPerformanceImpact.pfRet10y;
      currentPortfolioVolatility = currentPerformanceImpact.anVol10yr;
      enhancedPortfolioVolatility = enhancedPerformanceImpact.anVol10yr;
    } else if (enhancedPerformanceImpact.pfRet5y && enhancedPerformanceImpact.pfRet5y.length) {
      returnsCalcBasedOn = '5-Year';
      volatilityCalcBasedOn = '5-Year';
      currentStats = currentPerformanceImpact.pfRet5y;
      enhanceStats = enhancedPerformanceImpact.pfRet5y;
      currentReturnsLegend = currentPerformanceImpact.anRet5yr;
      enhancedReturnsLegend = enhancedPerformanceImpact.anRet5yr;
      portfolioReturnsData = enhancedPerformanceImpact.pfRet5y;
      currentPortfolioVolatility = currentPerformanceImpact.anVol5yr;
      enhancedPortfolioVolatility = enhancedPerformanceImpact.anVol5yr;
    } else if (enhancedPerformanceImpact.pfRet3y && enhancedPerformanceImpact.pfRet3y.length) {
      returnsCalcBasedOn = '3-Year';
      volatilityCalcBasedOn = '3-Year';
      currentStats = currentPerformanceImpact.pfRet3y;
      enhanceStats = enhancedPerformanceImpact.pfRet3y;
      currentReturnsLegend = currentPerformanceImpact.anRet3yr;
      enhancedReturnsLegend = enhancedPerformanceImpact.anRet3yr;
      portfolioReturnsData = enhancedPerformanceImpact.pfRet3y;
      currentPortfolioVolatility = currentPerformanceImpact.anVol3yr;
      enhancedPortfolioVolatility = enhancedPerformanceImpact.anVol3yr;
    } else if (enhancedPerformanceImpact.pfRet1y && enhancedPerformanceImpact.pfRet1y.length) {
      returnsCalcBasedOn = '1-Year';
      volatilityCalcBasedOn = '1-Year';
      currentStats = currentPerformanceImpact.pfRet1y;
      enhanceStats = enhancedPerformanceImpact.pfRet1y;
      currentReturnsLegend = currentPerformanceImpact.anRet1yr;
      enhancedReturnsLegend = enhancedPerformanceImpact.anRet1yr;
      portfolioReturnsData = enhancedPerformanceImpact.pfRet1y;
      currentPortfolioVolatility = currentPerformanceImpact.anVol1yr;
      enhancedPortfolioVolatility = enhancedPerformanceImpact.anVol1yr;
    }
  }

  // console.log(`========= Showing Returns of ${returnsCalcBasedOn} =========`);
  // console.log(portfolioReturnsData);
  // console.log(`============================================================`);

  let startDate = portfolioReturnsData && (portfolioReturnsData.length > 0 && portfolioReturnsData[0]) ? (portfolioReturnsData[0].d) : null;
  let endDate = portfolioReturnsData && (portfolioReturnsData.length > 0 && portfolioReturnsData[portfolioReturnsData.length - 1]) ? (portfolioReturnsData[portfolioReturnsData.length - 1].d) : null;
  let dateRange = startDate && endDate ? `${moment(startDate).format('MMMM YYYY')} - ${moment(endDate).format('MMMM YYYY')}` : null;
  let expectedBarData = [
    {
      "name": "Volatility*",
      "current": currentPortfolioVolatility,
      "currentColor": "#56a9e8",
      "enhanced": enhancedPortfolioVolatility,
      "enhancedColor": "#1e5f91"
    },
    {
      "name": "Drawdown*",
      "current": currentPortfolioDrawdown,
      "currentColor": "#56a9e8",
      "enhanced": enhancedPortfolioDrawdown,
      "enhancedColor": "#1e5f91"
    }
  ];

  // // using API keys for 3y returns
  // if (reportData && portfolio && portfolio.modelEnhancement) {
  //   const { portfolio: { modelEnhancement } } = reportData;
  //   if (modelEnhancement && modelEnhancement.enhanced && modelEnhancement.current) {
  //     const { enhanced, current } =  modelEnhancement;
  //     if (current && enhanced && current.FRS && enhanced.FRS) {
  //       currentReturnsLegend = (current.FRS.yRet && !isNaN(Number(current.FRS.yRet))) ? Number(current.FRS.yRet * 100).toFixed(2)+'%' : '--';
  //       enhancedReturnsLegend = (enhanced.FRS.yRet && !isNaN(Number(enhanced.FRS.yRet))) ? Number(enhanced.FRS.yRet * 100).toFixed(2)+'%' : '--';
  //     }
  //   }
  // }

  let inceptionDate = (currentStats && currentStats.length && currentStats[0] && currentStats[0].d) ? currentStats[0].d : '--';
  return (
    <React.Fragment>
      <div className="content">
        <div className="pr-v2-header">
          <h3>Performance</h3>
          {createdDate && (<p>Data as of {createdDate}</p>)}
        </div>
        <div className="pr-v2-port-performance">
          <Row className="pr-v2-pp-sections">
            <Col xs="12">
              <h4>Enhanced vs Existing Portfolio Returns</h4>
              <div className="bg-chart-block">
                <p className="mb-0" style={{ fontSize: '1.3rem', color: '#505050' }}>Growth of Hypothetical $100 since {inceptionDate}</p>
                <div style={{ display: 'flex', width: '920px', height: '170px' }}>
                  <PortfolioLineChart
                    linechartId="portfolio-linechart"
                    currentStats={currentStats}
                    enhanceStats={enhanceStats}
                    portfolioEnhanceReplace={portfolioEnhanceReplace}
                  />
                </div>
                <div className="pr-v2-chart-legends-container">
                  <span className="mb-0" style={{ fontSize: '1.3rem', color: '#505050' }}>Annualized {returnsCalcBasedOn} Total Return<sup>*</sup></span>
                  <div className="pr-v2-chart-legends">
                    <p>Current <span className="pr-v2-light-blue">{currentReturnsLegend}</span></p>
                    <p>Enhanced <span className="pr-v2-dark-blue">{enhancedReturnsLegend}</span></p>
                  </div>
                </div>
              </div>
              <div>
                <p>The line graph represents the cumulative performance of a hypothetical $100 investment. The returns are net of fund expense ratios but do not reflect the payment of any brokerage commissions or brokerage costs incurred as a result of buying or selling fund shares and do not reflect the deduction of taxes to which an investor would be subject as a result of owning or selling shares of the fund. If they did, the returns would be lower than those shown.</p>
                {/* <p>Annualized return is the amount of money earned by an investment each year assuming the annual return was compounded and including the reinvestments of dividends and distributions, without factoring in financial advisor fees.</p> */}
              </div>
            </Col>
          </Row>
          <Row className="pr-v2-pp-sections" style={{ marginTop: '30px' }}>
            <Col xs="12">
              <h4>Enhanced vs Existing Portfolio Risk</h4>
              <div className="bg-chart-block">
                <p className="mb-0" style={{ fontSize: '1.3rem', color: '#505050' }}>Volatility and Peak to trough</p>
                {dateRange && <p className="mb-0" style={{ fontSize: '1rem', color: '#505050', fontWeight: '600' }}>{dateRange}</p>}
                <div style={{ height: '320px', width: '890px' }}>
                  <CustomBarChart data={expectedBarData} />
                </div>
                <div className="pr-v2-chart-legends">
                  <p>Current</p>
                  <p>Enhanced</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
});

export default PortfolioPerformance;

import React from 'react';
import { D3Sunburst } from 'components/charts/D3Charts';
import NotApplicable from 'components/NotApplicable';
import { RegionChartMapping } from 'ChartData';
import { SectorColors } from 'data/Colors';
import { AssetAllocationBurstChartMapping, CN } from 'DataSet';

const AllocationChartB_ = (props) => {
  let { card, view, name, centerDisplay } = props;

  if (typeof card === 'undefined' || typeof card.Region === 'undefined' || typeof card.chartData === 'undefined') return <NotApplicable />;

  const currentChartData = card.chartData.filter(e => e.display_view_name === view)[0];

  const formatTree = data => {
    if (!data) return data;
    return data.map(e => {
      let name = e.n;
      if (name && AssetAllocationBurstChartMapping[`${name}`] !== 'undefined') {
        name = AssetAllocationBurstChartMapping[`${name}`];
      }
      if (typeof name === 'undefined') {
        name = e.n;
      }
      let out = { name, size: e.v }
      if (e.sub) out.children = formatTree(e.sub);
      return out;
    })
  }

  const data = {
    name: props.name,
    children: formatTree(card.Region)
  };

  if (data.children.length === 0) {
    return <NotApplicable />;
  }

  const sum = (arr, key) => {
    return arr.reduce((a, b) => a + (b[key] || 0), 0);
  }

  let newArr = JSON.parse(JSON.stringify(data));

  newArr.children.map((item) => {
    item.size = sum(item.children, 'size')
    return item;
  });

  let colorsObject = props.colors;
  if (data.children && data.children.length > 0) {
    data.children.map((h, i) => {
      h.children.map((f, k) => {
        if (typeof props.colors[f.name] === 'undefined') {
          colorsObject[f.name] = SectorColors[k % SectorColors.length]
          // colorsObject = {
          //   ...colorsObject,
          //   [f.name] : SectorColors[k % SectorColors.length],
          // };
        } else {
          colorsObject = props.colors;
        }
      });
    });
  }

  let primaryTextStyle = {
		fontSize: 11,
		fontWeight: 'bold',
		zIndex: '5',
	}, secondaryTextStyle = {};
  const chartTypePresent = typeof name !== 'undefined' ? name : '';
	let centerText = props.centerText || '';

  if (typeof currentChartData !== 'undefined') {
    if (currentChartData.data) {
      let displayText = RegionChartMapping.filter(e => e.chart_name === currentChartData.node);
      if (displayText.length) {
        if (displayText[0].region_name) {
          if (card[currentChartData.data] >= 0) {
            centerDisplay = `${card[currentChartData.data].toFixed(1)}%`;
            centerText = displayText[0].region_name;
            // centerText = centerText.substring(0, 11);
            primaryTextStyle = chartTypePresent === 'Allocations' ?  {
              // top: '39%',
              fontSize: 11,
              fontWeight: 'bold',
            }: {};
            secondaryTextStyle = chartTypePresent === 'Allocations' ? {
              top: centerText.length > 8 ? '54%' : '52%',
              fontSize: centerText.length > 8 ? 7 : 9,
            } : {};
          }
        }
      }
    }
  }

  let d3value = '';

  const showTooltip = (evt, text) => {
    let tooltip = document.getElementById(`${card.ticker}_d3tooltip`);
    d3value = text;
    tooltip.innerHTML = text;
    tooltip.style.display = "block";
		tooltip.style.position = 'absolute';
    tooltip.style.right = evt.offsetX + 10 + 'px';
    tooltip.style.top = evt.offsetY + 10 + 'px';
  }

  const hideTooltip = () => {
    var tooltip = document.getElementById(`${card.ticker}_d3tooltip`);
    tooltip.style.display = "none";
  }

  if (typeof card.overlap_data !== 'undefined' && card.overlap_data && card.overlap_data.length > 0) {
    if (props.activeRegion !== 'Allocations' &&  props.activeRegion !== '') {
      // console.log(card.overlap_data);
      const activeSegment = CN[props.activeRegion];
      // console.log('activeSegment --> ', activeSegment);
      if (activeSegment) {
        const overlapD = card.overlap_data[0];
        const overlapValue = overlapD[activeSegment];
        // console.log(props.activeRegion, centerDisplay, CN[props.activeRegion], overlapValue, card.ticker);
        if (typeof overlapValue !== 'undefined') {
          centerDisplay = Math.round(overlapValue)+'%';
        }
      }
    }
  }

  return (
    <div className="simplePieChartWrapper d3ChartWrapper">
      {(name === 'Allocations')
        && (
          <div className="primaryTextStyle" style={primaryTextStyle}
          >
            { centerDisplay }
            <div className="highlightTooltip">{centerText}: {centerDisplay}</div>
          </div>
        )
      }
			{((centerDisplay !== '' && centerText !== '' && name === 'Allocations')) && (
				<div className="primaryTextStyle" style={primaryTextStyle}>
					{centerDisplay}
					<div className="highlightTooltip">
						{centerText}: {centerDisplay}
					</div>
				</div>
			)}
      <div id={`${card.ticker}_d3tooltip`} className="d3Tooltip">{d3value}</div>
      <D3Sunburst
        data={data}
        width={props.width || 84}
        height={props.height || 84}
        colors={colorsObject}
        clickHandler={props.searchFundsActiveRegion}
        activeSegment={props.activeRegion}
        showTooltip={showTooltip}
        hideTooltip={hideTooltip}
      />
    </div>
  )
}

export const RegionChart = React.memo(AllocationChartB_);

export default RegionChart;

import React from 'react';
import { connect } from 'react-redux';

import { Nav, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Modal, ModalBody, ButtonGroup } from 'reactstrap';

import UploadFile from './UploadFile';
import PremiumNoticeModal from 'components/PremiumNoticeModal';
import { investmentOptions, fetchPortfolioData, deletePortfolio, investmentFetchSuccess, downloadPortfolioReport } from 'actions/investment';
import { getPortfolioStats } from 'selectors/investment'
import { subscriptionPlan, setProfileState } from 'actions/profile';
import { Notification } from 'shared-components'
import { uniqValues } from 'Utils'

class Navs extends React.PureComponent {
  Watchlist = { name: 'Watchlist', code: 'watchlist' }
  UploadPortfolio = { label: 'Upload Portfolio', name: 'Portfolio', code: 'uplPrt' }

  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      portfolioDropdownOpen: false,
      accounts: [ 'All', ...props.brokers, this.Watchlist.name ],
      uplPrtModal: false,
      deletePortfolio: '',
      deleteConfirm: false,
      premiumModal: false,
      notify: false,
      isNotifyOpen: false,
    };
  }

  componentDidMount() {
    // if (this.props.subscriptionPlan) {
    //   this.props.subscriptionPlan();
    // }
  }

  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  activateAccount = (account) => {
    if (account === 'Portfolio') {
      if (!this.props.premiumUser) this.setState({ premiumModal: true })
      else this.openUplPrt();
    }
    else {
      let opts = account === this.Watchlist.name
               ? { view: this.Watchlist.code, activeAccount: 'All', acView: 'default' }
               : { view: 'account', activeAccount: account };

      if (this.props.view !== 'account' && account !== this.Watchlist.name)
        opts.acView = 'default';

      this.props.investmentOptions(opts);
    }
  }

  fetchPortfolioFile = (file_name) => {
    if (!this.props.premiumUser) this.setState({ premiumModal: true })
    else {
      this.props.fetchPortfolioData(file_name)
      const opts = { view: this.UploadPortfolio.code, activeAccount: file_name, acView: 'default' }
      this.props.investmentOptions(opts);
    }
  }

  deletePortfolio = (fileName) => {
    if (!this.props.premiumUser) this.setState({ premiumModal: true })
    else {
      this.setState({ deletePortfolio : fileName, deleteConfirm: true })
    }
  }

  confirmDeletePortfolio = () => {
    const opts = { view: 'account', activeAccount: 'All', acView: 'default' }
    this.props.investmentOptions(opts);
    this.props.deletePortfolio(this.state.deletePortfolio, () => this.setState({ deleteConfirm: false }));
  }

  openUplPrt = () => {
    this.setState({ uplPrtModal: true })
  }

  setUploadContent = () => {
    const opts = { view: this.UploadPortfolio.code, activeAccount: 'All', acView: 'default' }
    this.props.investmentOptions(opts);
  }

  openNotify = () => {
    this.setState({
      notify: true,
    });
  }

  notifyToggle = () => {
    this.setState({
      notify: !this.state.notify,
    });
  }

  closePremiumModal = () => {
    this.setState({
      premiumModal: false,
    });
  }

  premiumModalToggle = () => {
    this.setState({
      premiumModal: !this.state.premiumModal,
    });
  }

  onFileSuccess = () => {
    this.setState({ uplPrtModal: false, isNotifyOpen: true }, () => {
      setTimeout(() => {
        this.setState({ isNotifyOpen: false })
      }, 3000);
    })
  }

  title = () => {
    const { activeAccount, view } = this.props;
    switch (view) {
      case 'account':
        return <span><strong className="font-one">Account : </strong>{activeAccount}</span>
        break;

      case 'watchlist':
        return <strong className="font-one">{this.Watchlist.name}</strong>
        break;

      case 'uplPrt':
        return <span><strong className="font-one">{this.UploadPortfolio.name}: </strong>{`${activeAccount} (ETFs and MFs)`}</span>
        break;

      default:
        return '';
        break;
    }
  }

  downloadPDF = (query) => {
    // console.log('downloadPDF');
    const { fundStats } = this.props;

    if (fundStats && fundStats.funds) {
      const { positions } = fundStats;
      const ALL_FUNDS_TICKER = uniqValues(positions, 'symbol');
      const { downloadPortfolioReport } = this.props;
      downloadPortfolioReport({
        tickers: ALL_FUNDS_TICKER.slice(0, 5).join(','),
        query,
      });
    }
  }

  render() {
    const { dropdownOpen, portfolioDropdownOpen, accounts, uplPrtModal, deleteConfirm, deletePortfolio, premiumModal, notify, isNotifyOpen } = this.state;
    const { portfolioList, view, premiumUser, uploadFilePartial, uploadFilePartialMsg, investmentFetchSuccess } = this.props;

    return (
      <div>
        <PremiumNoticeModal
          {...this.props}
          // premiumMsgModal
          // referralPage
          open={premiumModal}
          premiumUser={premiumUser}
          onClickOK={this.confirmPrintReport}
          onClickCancel={this.closePremiumModal}
          onToggle={this.premiumModalToggle}
          notify={notify}
          openNotify={this.openNotify}
          notifyToggle={this.notifyToggle}
        />

        <div className="account-nav-header">
          {/* <Nav pills justified className="blueBg">
            <Dropdown nav isOpen={dropdownOpen} toggle={this.toggle}>
              <DropdownToggle nav caret className="lh-100 py-3 active">
                {this.title()}&nbsp;
              </DropdownToggle>
              <DropdownMenu style={{marginLeft: '45%'}}>
                { accounts.map(e => (
                    <React.Fragment key={e}>
                      { e === this.Watchlist.name && <DropdownItem divider />}
                      <DropdownItem onClick={() => this.activateAccount(e)}>{e}</DropdownItem>
                    </React.Fragment>
                )) }
                <DropdownItem divider />
                {portfolioList.length > 0 && <DropdownItem><strong>Your Portfolios</strong></DropdownItem>}
                {
                  portfolioList.length > 0 &&
                  portfolioList.map((item, i) => (
                    <div className="portfolio-dropdown" key={i}>
                      <DropdownItem  onClick={() => this.fetchPortfolioFile(item.portfolio_name)}>
                        <div >{item.portfolio_name}</div>
                      </DropdownItem>
                      <span className="delete-icon" onClick={() => this.deletePortfolio(item.portfolio_name)}><i class="far fa-fw fa-trash-alt"></i></span>
                    </div>
                  ))
                }
                <DropdownItem className="upl-port-label" onClick={() => this.activateAccount(this.UploadPortfolio.name)}>{this.UploadPortfolio.label}</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav> */}

          {
            view === 'uplPrt' &&
            <Nav pills justified className="whiteBg">
              <Dropdown nav isOpen={portfolioDropdownOpen} toggle={() => this.setState({ portfolioDropdownOpen : !portfolioDropdownOpen })}>
                <DropdownToggle nav caret className="lh-100 py-3 active">
                  Portfolio Enhancements&nbsp;
                </DropdownToggle>
                <DropdownMenu style={{marginLeft: '45%'}}>
                  <DropdownItem onClick={() => this.downloadPDF('similar')}>
                    <div>Low cost alternatives</div>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => this.downloadPDF('outperform')}>
                    <div>Category funds that outperform</div>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => this.downloadPDF('all')}>
                    <div>All</div>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          }
        </div>

        <Modal
          isOpen={uplPrtModal}
          toggle={() => this.setState({ uplPrtModal: !uplPrtModal })}
          centered
          keyboard={true}
          backdrop
          className="premium-msg-modal account-modal"
          backdropClassName="premium-feature-process-modal-backdrop"
          >
          <ModalBody className="ssf-modal__body">
            <div className="premium-feature-process-modal-container">
              <div className="header-wrapper">
                <h1 className="text-align-left">Upload and view analytics on portfolio holdings</h1>
              </div>
              <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => this.setState({ uplPrtModal: false })}></i>
              <p className="content-wrapper">
                  <UploadFile onFileSuccess={this.onFileSuccess} />
              </p>

            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={deleteConfirm}
          toggle={() => this.setState({ deleteConfirm: !deleteConfirm })}
          centered
          keyboard={true}
          backdrop
          className="delete-confirm-modal premium-msg-modal account-modal"
          backdropClassName="premium-feature-process-modal-backdrop"
          >
          <ModalBody className="ssf-modal__body">
            <div className="premium-feature-process-modal-container">
              {/* <div className="header-wrapper">
                <h1 className="text-align-left">Are you sure you want to delete {deletePortfolio} portfolio</h1>
              </div> */}
              <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => this.setState({ deleteConfirm: false })}></i>
              <p className="content-wrapper">
                <div className="col-12 question">Are you sure you want to delete {deletePortfolio} portfolio?</div>
                <div className="col-12 cta-wrapper margin-left-51">
                  <button type="button" className="ssf-btn-primary btn secondary-btn btn btn-secondary" onClick={() => this.confirmDeletePortfolio()}><span>YES</span></button>
                  <button type="button" className="ssf-btn-primary btn secondary-btn btn btn-secondary" onClick={() => this.setState({ deleteConfirm: false })}><span>NO</span></button>
                </div>
              </p>

            </div>
          </ModalBody>
        </Modal>

        {isNotifyOpen
          && (
            <Notification
              isNotifyOpen={isNotifyOpen}
              notifyClassName="notify-card"
              notifyBody="File uploaded successfully"
            />
          )
        }

        {
          uploadFilePartial &&
          <Modal
          isOpen={uploadFilePartial}
          toggle={() => investmentFetchSuccess({ uploadFilePartial: false, uploadFilePartialMsg: '' })}
          centered
          keyboard={true}
          backdrop
          className="premium-msg-modal account-modal"
          backdropClassName="premium-feature-process-modal-backdrop"
          >
          <ModalBody className="ssf-modal__body">
            <div className="premium-feature-process-modal-container">
              {/* <div className="header-wrapper">
                <h1 className="text-align-left">Upload your portfolio and view analytics on your current holdings</h1>
              </div> */}
              <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => investmentFetchSuccess({ uploadFilePartial: false, uploadFilePartialMsg: '' })}></i>
              <p className="content-wrapper ">
                <div style={{ marginBottom: '1.5rem' }}>{uploadFilePartialMsg}</div>
                <div className="col-12 cta-wrapper margin-left-51">
                  <button type="button" className="ssf-btn-primary btn secondary-btn btn btn-secondary" onClick={() => investmentFetchSuccess({ uploadFilePartial: false, uploadFilePartialMsg: '' })}><span>OK</span></button>
                </div>
              </p>

            </div>
          </ModalBody>
        </Modal>
        }
      </div>
    );
  }
}


export default connect(
  state => ({
    brokers: state.investment.brokers,
    activeAccount: state.investment.activeAccount,
    view: state.investment.view,
    portfolioList: state.investment.portfolioList,
    premiumUser: state.auth.user.premiumUser,
    uploadFilePartial: state.investment.uploadFilePartial,
    uploadFilePartialMsg: state.investment.uploadFilePartialMsg,
    inviteSentTo: state.profile.inviteSentTo,
    inviteReferralCode: state.profile.inviteReferralCode,
    inviteFailed: state.profile.inviteFailed,
    inviteSuccess: state.profile.inviteSuccess,
    inviteError: state.profile.error,
    inviteMessage: state.profile.inviteMessage,
    fundStats: getPortfolioStats(state),
    }),
  {
    investmentOptions,
    fetchPortfolioData,
    deletePortfolio,
    subscriptionPlan,
    investmentFetchSuccess,
    setProfileState,
    downloadPortfolioReport,
  }
)(Navs);

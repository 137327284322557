import zipObject from 'lodash/zipObject'

const actions = [
  'CLEAR_FUNDS_API_LOAD_PERCENT',
  'UPDATE_LOAD_PERCENT',
  'FUNDS_API_CALL_DURATION',
  'FUNDS_API_CALL_TIME',
  'SET_GLOBAL_STATE',

  'GET_ALL_TICKER_LIST_REQUEST',
  'GET_ALL_TICKER_LIST_FETCH',
  'GET_ALL_TICKER_LIST_SUCCESS',
  'SHOW_JOIN_MODAL',
]

export default zipObject(actions, actions)

import React, { useContext } from 'react'
// import { Row, Col, Table } from 'reactstrap';
import { AttrCharts } from './Common';
import ActReportContext from '../ActReportContext';
import { isEmpty } from 'lodash';
import BestTickerBadge from '../shared/BestTickerBadge';
import moment from 'moment'

const PortfolioAddition = ({ cbAttrs, cbChartData }) => {
  const reportData = useContext(ActReportContext)
  const { bestTicker } = reportData;
  const { dateRange, factor } = reportData.combine.all
  let range = getDateRange(dateRange)
  const portFolioName = !isEmpty(reportData.combine)?reportData.combine.portfolio:'60% Global Equities (ACWI Index) and 40% Bonds (AGG Index)'

   cbChartData.forEach(frr=>{
    frr.forEach(i=>{
       if(i.value ==='Inactive'){
         i.value=0
         i.labelNA = 'Inactive'
       }
    })
  })
  return (
    <div className="pr-v2-relevant-funds">
      <div>
        <h4 className="pr-v2-sub-header">Portfolio Addition</h4>
        <p>{`We evaluated the shortlisted funds for their impact to a baseline portfolio that consists of
           ${portFolioName.includes('My Models')?
          `your model - ${portFolioName.split('My Models')[0]}${portFolioName.split('My Models')[1]}`
          :portFolioName}. Analysis reduces each original holding of your portfolio proportionally by ${factor*100}% and substitutes it with selected security.`
          }</p>
      </div>
      <div>
        <div style={{display:`flex`}}>
          <div style={{minWidth:`180px`}}>
          {/*  blank space */}
          </div>
          <div className={'d-flex justify-content-around'} style={{width:`100%`}}>
            {cbChartData[0].map(v=>{
              return <div style={{width:`${100/cbChartData[0].length}%`,maxWidth:`${100/cbChartData[0].length}%`}} className={'d-flex justify-content-center align-items-center'} >
                <p style={{fontSize:`18px`,position:'relative'}}>
                  {(v.name && v.name === bestTicker) && (
                    <BestTickerBadge
                      style={{
                        position:'absolute',
                        left:'-26px',
                      }}
                    />
                  )}
                  {v.name}
                </p>
              </div>
            })}
        </div>
        </div>
        <div className="rel-results">
          <AttrCharts dateRange={range} attrs={cbAttrs} chartData={cbChartData} />
        </div>
      </div>
    </div>
  )
}

export default PortfolioAddition;

const getDateRange = (dateRange) =>{
  let range = ''
  if (dateRange.toLowerCase().includes('since')){
    range = `${moment(dateRange.split('Since ')[1]).format('MMM YYYY')} - ${moment().format('MMM YYYY')}`
  } else if (dateRange ==='Last 1 Year'){
    range = `${moment().subtract(11,'months').format('MMM YYYY')} - ${moment().format('MMM YYYY')}`
  }
  else if (dateRange ==='Last 3 Year'){
    range = `${moment().subtract(35,'months').format('MMM YYYY')} - ${moment().format('MMM YYYY')}`
  }
  else if (dateRange ==='Last 5 Year'){
    range = `${moment().subtract(59,'months').format('MMM YYYY')} - ${moment().format('MMM YYYY')}`
  } else if(dateRange.includes('-')) {
    let [start,end] = dateRange.split(' - ')
    range = `${moment(start).format('MMM YYYY')} - ${moment(end).format('MMM YYYY')}`
  }else {
    range = dateRange
  }
  return range
}

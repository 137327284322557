import React, { Component, useState } from 'react';
import { Tabs, Row, Col, Card, Checkbox, Progress, Typography, Divider } from 'antd';
import _ from 'lodash';
import { Tooltip } from 'reactstrap';
import { sponsorLogo } from 'DataSet';
import { FormatCurrency } from 'components/reports/shared/Utils';
import { TruncatedText } from 'components/sf/Common';
import { FeeReturnRisk } from './FundsHelper';
import { EditOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;
const { Paragraph } = Typography;

export default class FundCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false
    }
  }

  render() {
    const { data, query, ticker, i, highlights, className, deleteFund, Checkbox } = this.props;

    const weight = data._sliderValue || 0;
    // const weight = selectedTickers.indexOf(data.ticker) > -1 ? selectedFunds[query][selectedTickers.indexOf(data.ticker)]._sliderValue : 0;

    let highlightsObj = (typeof highlights !== 'undefined' && highlights) ? highlights : { feesHighLightEnable: false, returnHighLightEnable: false, riskHighLightEnable: false };
    const id = data.index ? 'cb-'+ticker+data.index+data.id : 'cb-'+ticker+data.vehicle+data.ticker;

    data._frs = {
      fees: data.fees,
      yRet: data.Fee_Risk_Return['Annualized Average'],
      volt: data.Fee_Risk_Return['Annualized Volatility'],
      feesQ: data.feesq,
      yRetQ: data.Fee_Risk_Return.returnq,
      voltQ: data.Fee_Risk_Return.riskq,
      queryIcon: '',
    };
    let overlapTicker = 'overlap_'+ticker.toLowerCase();

    if (query.includes('outperform')) {
      data._frs.queryIcon = 'OutPerform';
      const _outPerformVal = (typeof data['outperform'] !== 'undefined' && data['outperform'] !== '--') ? data['outperform']*100 : '--';
      data._frs.OutPerform = (_outPerformVal !== '--' && !Number.isNaN(_outPerformVal)) ? _outPerformVal.toFixed(1) + "%" : '--';
    } else if (query.includes('yield')) {
      data._frs.queryIcon = 'Yield';
      const _yieldVal = (typeof data['attribute/fundamental/yield'] !== 'undefined' && data['attribute/fundamental/yield'] !== '--') ? data['attribute/fundamental/yield']*100 : '--';
      data._frs.Yield = (_yieldVal !== '--' && _yieldVal) ? _yieldVal.toFixed(1) + "%" : '--';
    } else if (query.includes('track record')) {
      data._frs.queryIcon = 'TrackRecord';
      const _trackRecordVal = (typeof data['attribute/track_record'] !== 'undefined' && data['attribute/track_record'] !== '--') ? data['attribute/track_record'] : null;
      data._frs.TrackRecord = (_trackRecordVal !== '--' && _trackRecordVal) ? _trackRecordVal.toFixed() + "yrs" : '--';
    } else if (query.includes('tracking error')) {
      console.log('TrackingError');
      // queryIcon = 'TrackingError';
    }

    const renderGraph = (
      <div className="progress-chrt-box">
        <Progress
          strokeWidth={12}
          strokeColor="#1d6091"
          width={50}
          type="circle"
          percent={parseFloat(weight.toFixed(1))}
          format={(value) => (<div className="progress-chart-value">{value}</div>) }
        />
      </div>
    )

    return (
      <React.Fragment>
        <Card className={`fund-card-container ${deleteFund ? 'pos-rel' : ''}`}>
          {deleteFund && (
            <div className="delete-fund-card" onClick={deleteFund}>
              <i class="far fa-times"></i>
            </div>
          )}
          <Row className="name-block-wrapper">
            <Col span={16} className="first-block p-1 d-flex">
              <div className="d-flex flex-column justify-content-center align-items-left" style={{minWidth: '4rem'}}>
                <div className="py-1">
                  <ImageOrText img={sponsorLogo(data.sponsor)} text={data.sponsor} />
                </div>
                <div className="vertical-ticker-price-wrapper">
                  <h5 className="mb-0 ticker-price">{data.ticker}</h5>
                  <Divider type="vertical" />
                  <h4 className="mt-0 mb-0 ticker-price" style={{ fontWeight: 600 }}>{FormatCurrency(data.nav, 2)}</h4>
                  {["institutional", "retail"].includes(data.class_type) && <React.Fragment>
                  <Divider type="vertical" />
                  <Tooltip className="col-grey" isOpen={this.state.tooltipOpen} toggle={() => this.setState({ tooltipOpen: !this.state.tooltipOpen })} placement="right" target={`fundclass-${data.ticker}`}>
                      {data.class_type === 'retail' ? 'Retail Class' : 'Institutional Class'}
                  </Tooltip>
                  <img id={`fundclass-${data.ticker}`} src={`/assets/images/${data.class_type}.png`} width='10' />
                  </React.Fragment>}
                </div>
              </div>
              <div className="name-wrapper">
                <Paragraph
                  className="mb-0 text-ssf-blue-d2 font-details funds-name"
                  style={{ fontWeight: 600 }}
                  ellipsis={{ rows: 2 }}
                >
                  {data.short_name}
                </Paragraph>
                <Paragraph
                  ellipsis
                  className="mb-0 text-gray-6 font-details funds-category"
                >
                  {data.category}
                </Paragraph>
              </div>
            </Col>
            <Col span={8} className="p-1 chart-graph-wrapper">
              <div className="action-box">
                {Checkbox}
              </div>
              {renderGraph}
            </Col>
          </Row>
          <FeeReturnRisk data={data._frs} highlights={highlightsObj} />
        </Card>
      </React.Fragment>
    )
  }
}

const ImageOrText = ({ img, text = '' }) => {
  const [valid, setValid] = useState(true);
  return (
    valid
    ? <img src={img} className="img-fluid" alt="" onError={() => setValid(false)} style={{height: '20px'}} />
    : <div className="sponsor-logo-placeholder" title={text}>{text}</div>
  );
}

const getId = (function* () {
  let n = 1;
  while (true) yield n++;
})();

import ActionTypes from '../action-types'
import { createAction } from 'redux-actions'

// Fetch Broker's account list
export const fetchImportedAccounts = createAction(
  ActionTypes.FETCH_IMPORTED_ACCOUNT_REQUEST
)
export const successfulFetchImportedAccounts = createAction(
  ActionTypes.FETCH_IMPORTED_ACCOUNT_SUCCESS
)
export const failedFetchImportedAccounts = createAction(
  ActionTypes.FETCH_IMPORTED_ACCOUNT_FAILURE
)

export function setupBroker(broker, received_code) {
  return {
    type: ActionTypes.SETUP_BROKER_REQUEST,
    broker,
    received_code
  }
}

export function successfulSetupBroker(response) {
  return {
    type: ActionTypes.SETUP_BROKER_SUCCESS,
    response
  }
}

export function failedSetupBroker(error) {
  return {
    type: ActionTypes.SETUP_BROKER_FAILURE,
    error
  }
}

export function completeBrokerSetup(payload) {
  return {
    type: ActionTypes.COMPLETE_BROKER_SETUP_REQUEST,
    payload
  }
}

export function successfulCompleteBrokerSetup(response) {
  return {
    type: ActionTypes.COMPLETE_BROKER_SETUP_SUCCESS,
    response
  }
}

export function failedCompleteBrokerSetup(error) {
  return {
    type: ActionTypes.COMPLETE_BROKER_SETUP_FAILURE,
    error
  }
}

export function importBrokerAccounts(payload, brokerName) {
  return {
    type: ActionTypes.IMPORT_BROKER_ACCOUNT_REQUEST,
    payload,
    brokerName
  }
}

export const fetchBrokerAccounts = (payload, isBrokerSetup) => ({
  type: ActionTypes.FETCH_BROKER_ACCOUNT_REQUEST,
  payload,
  isBrokerSetup
});

export const successfulFetchBrokerAccount = (payload, brokerName) => ({
  type: ActionTypes.FETCH_BROKER_ACCOUNT_SUCCESS,
  payload,
  brokerName
})

export const failedFetchBrokerAccount = createAction(
  ActionTypes.FETCH_BROKER_ACCOUNT_FAILURE
)

// export const importBrokerAccounts = createAction(ActionTypes.IMPORT_BROKER_ACCOUNT_REQUEST)
export const successfulImportBrokerAccounts = createAction(
  ActionTypes.IMPORT_BROKER_ACCOUNT_SUCCESS
)
export const failedImportBrokerAccounts = createAction(
  ActionTypes.IMPORT_BROKER_ACCOUNT_FAILURE
)

// TDAmeritrade setup
export const completeSetupTDAmeritrade = createAction(
  ActionTypes.COMPLETE_TDAMERITRADE_BROKER_SETUP_REQUEST
)
export const successfulCompleteSetupTDAmeritrade = createAction(
  ActionTypes.COMPLETE_TDAMERITRADE_BROKER_SETUP_SUCCESS
)
export const failedCompleteSetupTDAmeritrade = createAction(
  ActionTypes.COMPLETE_TDAMERITRADE_BROKER_SETUP_FAILURE
)

// setAsDefault
export const setDefaultAccount = createAction(
  ActionTypes.SET_AS_DEFAULT_REQUEST
)
export const successfulSetDefaultAccount = createAction(
  ActionTypes.SET_AS_DEFAULT_SUCCESS
)
export const failedSetDefaultAccount = createAction(
  ActionTypes.SET_AS_DEFAULT_FAILURE
)

// Delete Broker's linked account
export const deleteImportedAccounts = createAction(
  ActionTypes.DELETE_IMPORTED_ACCOUNT_REQUEST
)
export const successfulDeletedImportedAccount = createAction(
  ActionTypes.DELETE_IMPORTED_ACCOUNT_SUCCESS
)
export const failedDeletedImportedAccount = createAction(
  ActionTypes.DELETE_IMPORTED_ACCOUNT_FAILURE
)

export const completeTradeItSetup = (payload, callback) => ({
  type: ActionTypes.COMPLETE_TRADE_IT_BROKER_SETUP,
  payload,
  callback,
})

export const displayTradeItVerificationScreen = createAction(
  ActionTypes.TRADE_IT_MORE_INFO_STATUS
)

export const tradeItVerificationRequest = createAction(
  ActionTypes.TRADE_IT_MORE_INFO_VEIRFY_REQUEST
)
export const tradeItVerificationSuccess = createAction(
  ActionTypes.TRADE_IT_MORE_INFO_VEIRFY_SUCCESS
)
export const tradeItVerificationError = createAction(
  ActionTypes.TRADE_IT_MORE_INFO_VEIRFY_ERROR
)
export const tradeItVerificationReset = createAction(
  ActionTypes.TRADE_IT_MORE_INFO_VEIRFY_RESET
)

import ActionTypes from '../action-types'

export function relatedInsightRequest(payload) {
  return {
    type: ActionTypes.FETCH_RELATED_INSIGHTS_DATA_REQUEST,
    payload,
  }
}

export function relatedInsightFetch(payload) {
  return {
    type: ActionTypes.FETCH_RELATED_INSIGHTS_DATA_FETCH,
    payload,
  }
}

export function relatedInsightSuccess(payload) {
  return {
    type: ActionTypes.FETCH_RELATED_INSIGHTS_DATA_SUCCESS,
    payload,
  }
}

export function clearRelatedInsightData(payload) {
  return {
    type:ActionTypes.CLEAR_PLUGINS,
    payload,
  }
}

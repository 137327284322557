import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Row, Col, Card, Menu, Collapse, Tooltip } from 'antd';
import _ from 'lodash';
import { addEventToAnalytics } from 'Utils';

import { createLoadingSelector } from 'selectors/loading-selector';
import { tickerDataRequest, deleteCustomCard, setBuildPortfolioState, searchForNewQueryRequest } from 'actions/buildportfolio';

import { customScroll } from 'layouts/utils';
import { getSelectedFundsCount } from 'layouts/WebDashboard/BuildPortfolio/utils';

import CalculatorSlider from '../../../Elements/CalculatorSlider';
import InputSearch from '../../../Elements/InputSearch';
import ListView from './ListView';

const { SubMenu } = Menu;
const { Panel } = Collapse;

const buildPortfolioMenus = [{
  label: 'Equal',
  icon: null,
  disabled: true,
  className: 'disabled-menu',
  tooltip: null,
}, {
  label: 'Risk Parity',
  icon: null,
  disabled: true,
  style: { margin: 0 },
  className: 'disabled-menu',
  tooltip: children => (
    <Tooltip placement="top" title="Coming soon">
      {children}
    </Tooltip>
  ),
}];

class BondsContent extends Component {
  constructor(props) {
    super(props);
    this.collapseRef = React.createRef();
    this.state = {
      fundCollectionList: [],
      activeTabPane: '1',
    };
  }

  componentDidMount() {
    customScroll();
    const { data } = this.props;
    if (data && data.length) {
      this.setState({ fundCollectionList: data }, () => {
        // this.calculateSliderValue();
      });
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.data !== prevProps.data) {
      const { buildportfolio } = this.props;
      if(!buildportfolio.bondTab._isCustomInput){
        this.props.data.map((l) => {
          if (typeof buildportfolio.bondTab.selectedFunds[l.query] !== 'undefined') {
            // const getRowDataSliderValue = buildportfolio.bondTab.data.find((t) => t.query === l.query);
            const selF = buildportfolio.bondTab.selectedFunds[l.query];
            l.result.funds.map((w) => {
              if(!w._isCustomInput) {
                w._sliderValue = 0;
                const found = selF.find((o) => o.ticker === w.ticker);

                if (found && typeof found !== 'undefined' && (found.ticker === w.ticker)) {
                  w._sliderValue = l.sliderValue / selF.length;
                }
                buildportfolio.bondTab.selectedFunds[l.query].forEach((y) => {
                  if (y && typeof y !== 'undefined' ) {
                    y._sliderValue = l.sliderValue/buildportfolio.bondTab.selectedFunds[l.query].length;
                  }
                });
              }
            });
          }
        });
        this.setState({fundCollectionList: this.props.data});
      }
    }
  }

  calculateSliderValue = (value, label) => {
    //filter the header reqquired for slider wt calc after the change
    //it may be less than the total in case of manual slider chnage
    //or it may be all if the components loads for the first time or weightingMethodology chnage
    const topTab = this.props.topTabHeaderMenu.find((item) => item.key === 'bondTab');
    let { weightingMethodology, bondTab, setBuildPortfolioState } = this.props;
    let totalSum = 0.0, filterObjsTotalSum = 0.0, diff = 0.0;
    let filterObjs = [], unfilterObjs = [];

    //delete this code after testing the equal and manual mode
    // if(weightingMethodology == 'Equal') {
    //   filterObjs = JSON.parse(JSON.stringify(this.state.fundCollectionList));
    //   filterObjs.forEach((item,index) => {filterObjs[index].sliderValue = 1});
    //   console.log('sid eqaul', filterObjs)
    // }
    // else {
      this.state.fundCollectionList.forEach(e => {
        let item = JSON.parse(JSON.stringify(e));
        if(weightingMethodology == 'Equal') item.sliderValue = 1
        totalSum += item.sliderValue;
        if((item.label != label && item.locked == false) || weightingMethodology == 'Equal') { //filter condition
          filterObjsTotalSum += item.sliderValue;
          filterObjs.push(item);
        }
        else {
          unfilterObjs.push(item);
        }
      });
    // }

    if(filterObjs.length>0) {
      diff = topTab.sliderValue - totalSum;
      filterObjs.forEach((obj, index) => {
        let ratio = obj.sliderValue == 0 ? 0 : obj.sliderValue / filterObjsTotalSum;
        let wt = diff * ratio;
        filterObjs[index].sliderValue = Math.round(obj.sliderValue + wt);
      });

      filterObjs = _.sortBy(filterObjs.concat(unfilterObjs),['position']);
      bondTab.data = filterObjs;
      this.setState({ bondTab })
      // if (setBuildPortfolioState) {
      //   setBuildPortfolioState({ bondTab : bondTab })
      // }
    }
  }

  getTickerData = ({ searchedTicker, forTicker, forQuery }) => {
    // const { tickerDataRequest, activeTab } = this.props;
    // if (tickerDataRequest) {
    //   tickerDataRequest({ searchedTicker, forTicker, forQuery, tab: activeTab });
    // }
  }

  deleteCard = ({ item, index, searchedTicker, forTicker, forQuery }) => {
    const { deleteCustomCard, activeTab } = this.props;
    deleteCustomCard({
      item,
      index,
      searchedTicker,
      forTicker,
      forQuery,
      tab: activeTab,
    });
  }

  menuHandleClick = (key) => {
    if (key && typeof key.key !== 'undefined') {
      const { bondTab } = this.props;
      const { weightingMethodology } = bondTab;
      bondTab.weightingMethodology = key.key;
      const { setBuildPortfolioState } = this.props;
      if (setBuildPortfolioState) {
        setBuildPortfolioState({ bondTab });
      }
    }
  }

  removeSelectedThemes = (event, fund, forQuery) => {
    event.stopPropagation();
    const { bondTab } = this.props;
    if (forQuery && fund && typeof fund.ticker !== 'undefined') {
      const oldSelectedfunds = JSON.parse(JSON.stringify(bondTab.selectedFunds));
      const filteredArray = bondTab.selectedFunds[forQuery];
      const updatedArray = filteredArray.filter((h) => h.ticker !== fund.ticker);
      this.getSelectedFunds({
        selectedFunds: updatedArray,
        forQuery,
        oldSelectedfunds: oldSelectedfunds,
      });
    }
  }

  runWeightAllocation = (allStateMappedData, topTabHeaderMenu) => {
    const equityTabFunds = allStateMappedData.equityTabFunds;
    const bondTabFunds = allStateMappedData.bondTabFunds;
    const thematicTabFunds = allStateMappedData.thematicTabFunds;
    const currentBondSliderValue = topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue;
    // console.log('bondTabFunds', bondTabFunds);

    const foundTabWithWeights = topTabHeaderMenu.filter((x) => x.sliderValue !== 0);

    if (equityTabFunds && bondTabFunds && thematicTabFunds) {
      if (currentBondSliderValue === 0) {
        let old1 = 0, old2 = 0, diff = 36;
        old1 = topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue;
        old2 = topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue

        topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue = old1 + ((old1 == 0 ? 0 : old1/(old1+old2))*-diff);
        topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue = diff;
        topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue = old2 + ((old2 == 0? 0 : old2/(old1+old2))*-diff);
      }
    } else if (equityTabFunds && bondTabFunds && (thematicTabFunds === 0)) {
      if (currentBondSliderValue === 0) {
        let old1 = 0, old2 = 0, diff = 40;
        old1 = topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue;
        old2 = topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue

        topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue = old1 + ((old1 == 0 ? 0 : old1/(old1+old2))*-diff);
        topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue = diff;
        topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue = old2 + ((old2 == 0? 0 : old2/(old1+old2))*-diff);
      }
    } else if (equityTabFunds && (bondTabFunds === 0) && thematicTabFunds) {
      if (currentBondSliderValue === 0) {
        let old1 = 0, old2 = 0, diff = -1*(100-(topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue + topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue));
        old1 = topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue;
        old2 = topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue

        topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue = old1 + ((old1 == 0 ? 0 : old1/(old1+old2))*-diff);
        topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue = 0;
        topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue = old2 + ((old2 == 0? 0 : old2/(old1+old2))*-diff);
      }
    } else if ((equityTabFunds === 0) && bondTabFunds && thematicTabFunds) {
      if (currentBondSliderValue === 0) {
        let old1 = 0, old2 = 0, diff = 90;
        old1 = topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue;
        old2 = topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue

        topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue = old1 + ((old1 == 0 ? 0 : old1/(old1+old2))*-diff);
        topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue = diff;
        topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue = old2 + ((old2 == 0? 0 : old2/(old1+old2))*-diff);
      }
    } else if (foundTabWithWeights.filter((a) => (a.key !== 'bondTab' && a.sliderValue !== 0)).length === 1) {
      foundTabWithWeights[0].sliderValue = 100;
    }

    // console.log(topTabHeaderMenu);

    return topTabHeaderMenu;
  }

  executeMe = (forQuery, newPropsSelectedFunds, currentTab, tabRows, allStateMappedData, sumofRows, topTabHeaderMenu) => {
    // console.log(forQuery);
    // console.log(allStateMappedData);
    // const { topTabHeaderMenu } = this.props;

    const referenceKey = 'bondTab';
    const globalTabWeight = topTabHeaderMenu.find((i) => i.key === 'bondTab').sliderValue;
    console.log('sumofRows ======> ', sumofRows, globalTabWeight);

    // console.log(`--------- ${referenceKey} ---------`);
    const currentSectionFunds = allStateMappedData[`${referenceKey}Section`];
    // check if (how many row has selected funds)
    // console.log(allStateMappedData[`${referenceKey}Funds`]);

    if (allStateMappedData[`${referenceKey}Funds`] > 0) {
      // console.log('yes we have selected funds');
      // console.log(newPropsSelectedFunds);

      if (Object.keys(currentSectionFunds).length > 0) {

        Object.keys(currentSectionFunds).forEach((p) => {
          // console.log(Object.keys(currentSectionFunds));

          const sfsdff = Object.keys(currentSectionFunds).filter((r) => currentSectionFunds[r] !== 0).length;
          // console.log(sfsdff);

          if (((tabRows.filter((s) => s.sliderValue !== 0).length === tabRows.length) && currentSectionFunds[p] > 0) && tabRows.find((s) => s.query === p) && sfsdff === tabRows.length && sumofRows === globalTabWeight) {
            console.log(`DO NOT CHANGE WEIGHT FOR ${p}`);
          } else {
            if (currentSectionFunds[p] > 0) {
              if (tabRows && typeof tabRows.length !== 'undefined' && tabRows.length > 0) {
                if (tabRows.find((s) => s.query === p) && typeof tabRows.find((s) => s.query === p) !== 'undefined' && tabRows.find((s) => s.query === p).sliderValue === 0) {
                  // tabRows.find((s) => s.query === p).sliderValue = (globalTabWeight)/sfsdff;
                  let diff = (10*globalTabWeight)/100;

                  tabRows.find((s) => s.query === forQuery).sliderValue = diff; //[USA]
                  // console.log('here');

                  let sum = 0;
                  tabRows.filter((s) => {// [USA, DM,EM]
                    // console.log(s.query, p, forQuery);
                    if ((s.query !== forQuery && s.sliderValue > 0)) {
                      sum = sum+s.sliderValue;
                      return s
                    }
                  });

                  tabRows.filter((s, i) => {
                    if ((s.query !== forQuery && s.sliderValue > 0)) {
                      s.sliderValue = s.sliderValue + ((s.sliderValue == 0 ? 0 : s.sliderValue/(sum))*-diff);
                    }
                  });
                }
              }
            } else {
              // console.log(`SET CURRENT ROW ${p} WEIGHT to 0 and split weight with other rows`);
              // console.log('WEIGHT for Other rows', (globalTabWeight)/sfsdff);

              if (tabRows && typeof tabRows.length !== 'undefined' && tabRows.length > 0) {
                let diff = tabRows.find((s) => s.query === p).sliderValue;
                tabRows.find((s) => s.query === p).sliderValue = 0;

                // tabRows.find((s) => s.query === forQuery).sliderValue = diff; //[USA]
                // console.log('here');

                let sum = 0;
                tabRows.filter((s) => {// [USA, DM,EM]
                  // console.log(s.query, p, forQuery);
                  if ((s.query !== forQuery && s.sliderValue > 0)) {
                    sum = sum+s.sliderValue;
                    return s
                  }
                });

                tabRows.filter((s, i) => {
                  if ((s.query !== forQuery && s.sliderValue > 0)) {
                    s.sliderValue = s.sliderValue + ((s.sliderValue == 0 ? 0 :s.sliderValue/(sum))*diff);
                  }
                });
                // if (tabRows.find((s) => s.query !== p && currentSectionFunds[p] !== 0) && typeof tabRows.find((s) => s.query !== p && currentSectionFunds[p] !== 0) !== 'undefined') {
                //   tabRows.find((s) => s.query !== p && currentSectionFunds[p] !== 0).sliderValue = (globalTabWeight)/sfsdff;
                // }
                // tabRows.filter((l) => l.query !== p).map(e => e.sliderValue = (topTabHeaderMenu.find((i) => i.key === currentTab.key).sliderValue)/sfsdff);
              }
            }
          }
          // console.log(tabRows);
        })
      }
    } else {
      // console.log(`NO FUNDS SELECTED for ${referenceKey}`);
      // then allocate weight to current ROW (using forQuery)
      if (tabRows && typeof tabRows.length !== 'undefined' && tabRows.length > 0) {
        tabRows.forEach((item, index) => { // tabRows: ['USA', 'EM', 'DM']
          // console.log(`active row ------ ${forQuery} ------`);
          if (newPropsSelectedFunds[item.query] && typeof newPropsSelectedFunds[item.query] !== 'undefined') {
            // console.log(newPropsSelectedFunds[item.query]);
            if (newPropsSelectedFunds[item.query].length > 0) {
              item.sliderValue = topTabHeaderMenu.find((i) => i.key === currentTab.key).sliderValue;
            } else {
              item.sliderValue = 0;
            }
          }
        });
        if (tabRows.filter((w) => w.sliderValue !== 0).length === 0) {
          // console.log('ALL ROWS WEIGHT ARE 0');
          currentTab.sliderValue = 0
          topTabHeaderMenu = this.runWeightAllocation(allStateMappedData, topTabHeaderMenu);
        }
      }
    }
  }

  // calCulateWeight = (forQuery) => {
  //   const { setBuildPortfolioState, buildportfolio } = this.props;
  //   const { bondTab} = buildportfolio;
  //
  //   let topTabHeaderMenu = JSON.parse(JSON.stringify(this.props.topTabHeaderMenu));
  //   // const bondTab = JSON.parse(JSON.stringify(buildportfolio.bondTab));
  //   const oldHeaderMenuState = JSON.parse(JSON.stringify(buildportfolio.tabHeaderMenu));
  //   const oldBuildportfolioState = JSON.parse(JSON.stringify(buildportfolio));
  //   const oldEquityData = JSON.parse(JSON.stringify(buildportfolio.equityTab.data));
  //   const oldThematicData = JSON.parse(JSON.stringify(buildportfolio.thematicTab.data));
  //   const oldBondData = JSON.parse(JSON.stringify(buildportfolio.bondTab.data));
  //
  //   const getStateMappedData = getSelectedFundsCount({ allStates: buildportfolio, tab: 'bondTab' });
  //   // console.log(getStateMappedData);
  //
  //   topTabHeaderMenu.forEach((item, i) => {
  //     // if all tabs has 0 weights
  //     if (topTabHeaderMenu[0].sliderValue === 0 && topTabHeaderMenu[1].sliderValue === 0 && topTabHeaderMenu[2].sliderValue === 0) {
  //       // then current active tab will be 100; for.e.g: equityTab: 100%
  //       if (item.key === 'bondTab') {
  //         item.sliderValue = 100;
  //         if (bondTab.data) {
  //           let sumofRows = 0;
  //           // console.log(bondTab.data);
  //           bondTab.data.map((k) => {
  //             sumofRows += k.sliderValue;
  //           });
  //           this.executeMe(forQuery, bondTab.selectedFunds, item, bondTab.data, getStateMappedData, sumofRows, topTabHeaderMenu);
  //         }
  //       }
  //     } else {
  //       // console.log('No, some tabs have weights');
  //       // before assigning weight to bondTab check if it is having funds ?
  //
  //       topTabHeaderMenu = this.runWeightAllocation(getStateMappedData, topTabHeaderMenu);
  //
  //       // console.log(topTabHeaderMenu);
  //
  //       if (item.key === 'bondTab') {
  //         if (bondTab.data) {
  //           let sumofRows = 0;
  //           bondTab.data.map((k) => {
  //             sumofRows += k.sliderValue;
  //           });
  //           this.executeMe(forQuery, bondTab.selectedFunds, item, bondTab.data, getStateMappedData, sumofRows, topTabHeaderMenu);
  //         }
  //       }
  //
  //     }
  //   });
  //
  //   // console.log(JSON.parse(JSON.stringify(bondTab)));
  //
  //   // const getRowDataSliderValue = bondTab.data.find((t) => t.query === forQuery);
  //   // bondTab.selectedFunds[forQuery].forEach((y) => {
  //   //   if (getRowDataSliderValue && typeof getRowDataSliderValue !== 'undefined') {
  //   //     y._sliderValue = getRowDataSliderValue.sliderValue/bondTab.selectedFunds[forQuery].length;
  //   //   }
  //   // });
  //
  //   // bondTab.data.map((l) => {
  //   //   if (l.query === forQuery) {
  //   //     l.result.funds.map((w) => {
  //   //       w._sliderValue = 0;
  //   //       if (bondTab.selectedFunds[forQuery] && typeof bondTab.selectedFunds[forQuery] !== 'undefined') {
  //   //         const found = bondTab.selectedFunds[forQuery].find((o) => o.ticker === w.ticker);
  //   //         if (found && typeof found !== 'undefined' && (found.ticker === w.ticker)) {
  //   //           w._sliderValue = getRowDataSliderValue.sliderValue/bondTab.selectedFunds[forQuery].length;
  //   //         }
  //   //         bondTab.selectedFunds[forQuery].forEach((y) => {
  //   //           if (getRowDataSliderValue && typeof getRowDataSliderValue !== 'undefined') {
  //   //             y._sliderValue = getRowDataSliderValue.sliderValue/bondTab.selectedFunds[forQuery].length;
  //   //           }
  //   //         });
  //   //       }
  //   //     });
  //   //   }
  //   // });
  //
  //   buildportfolio.bondTab.data.map((l) => {
  //     l.result.funds.map((w) => {
  //       w._sliderValue = 0;
  //       if (buildportfolio.bondTab.selectedFunds[l.query] && typeof buildportfolio.bondTab.selectedFunds[l.query] !== 'undefined') {
  //         const found = buildportfolio.bondTab.selectedFunds[l.query].find((o) => o.ticker === w.ticker);
  //         if (found && typeof found !== 'undefined' && (found.ticker === w.ticker)) {
  //           w._sliderValue = l.sliderValue/buildportfolio.bondTab.selectedFunds[l.query].length;
  //         }
  //         buildportfolio.bondTab.selectedFunds[l.query].forEach((y) => {
  //           if (y && typeof y !== 'undefined') {
  //             y._sliderValue = l.sliderValue/buildportfolio.bondTab.selectedFunds[l.query].length;
  //           }
  //         });
  //       }
  //     });
  //   });
  //
  //   // console.log(JSON.parse(JSON.stringify(bondTab)));
  //   console.log(bondTab);
  //
  //   // console.log(oldBuildportfolioState);
  //
  //   if (setBuildPortfolioState) {
  //     setBuildPortfolioState({
  //       oldHeaderMenuState,
  //       oldThematicData,
  //       oldEquityData,
  //       tabHeaderMenu: topTabHeaderMenu,
  //       bondTab: buildportfolio.bondTab,
  //     });
  //   }
  // }

  getSelectedFunds = ({ selectedFunds, forQuery, oldSelectedfunds }) => {
    const { bondTab, setBuildPortfolioState, topTabHeaderMenu, buildportfolio } = this.props;
    const oldHeaderMenuState = JSON.parse(JSON.stringify(buildportfolio.tabHeaderMenu));
    const oldEquityTab = JSON.parse(JSON.stringify(buildportfolio.equityTab));
    const oldBondTab = JSON.parse(JSON.stringify(buildportfolio.bondTab));
    const oldThematicTab = JSON.parse(JSON.stringify(buildportfolio.thematicTab));
    const oldEquityData = JSON.parse(JSON.stringify(buildportfolio.equityTab.data));
    const oldThematicData = JSON.parse(JSON.stringify(buildportfolio.thematicTab.data));
    const oldBondData = JSON.parse(JSON.stringify(buildportfolio.bondTab.data));
    const oldBuildportfolioState = JSON.parse(JSON.stringify(buildportfolio));

    // console.log(oldBondData);
    // console.log(oldSelectedfunds);
    // console.log(Object.keys(oldSelectedfunds).length);
    // console.log(oldBondData.find((g) => g.query === forQuery));
    // console.log(typeof oldSelectedfunds[forQuery] !== 'undefined');
    // console.log(typeof oldSelectedfunds[forQuery] !== 'undefined' && oldSelectedfunds[forQuery]);
    // console.log(typeof oldSelectedfunds[forQuery] !== 'undefined' && oldSelectedfunds[forQuery].length > selectedFunds.length);
    // console.log( typeof oldSelectedfunds[forQuery] !== 'undefined' && oldSelectedfunds[forQuery].length, selectedFunds.length);
    // console.log(oldBondData.find((g) => g.query === forQuery));
    // console.log(oldBondData.find((g) => g.query === forQuery).sliderValue <= 0);
    //
    // console.log(oldBondTab && Object.keys(oldSelectedfunds).length && typeof oldSelectedfunds[forQuery] !== 'undefined' && (oldSelectedfunds[forQuery].length > selectedFunds.length) && oldBondData.find((g) => g.query === forQuery) && oldBondData.find((g) => g.query === forQuery).sliderValue <= 0);

    // console.log(oldBondTab);
    if (oldBondTab && Object.keys(oldSelectedfunds).length && typeof oldSelectedfunds[forQuery] !== 'undefined' && (oldSelectedfunds[forQuery].length > selectedFunds.length) && oldBondData.find((g) => g.query === forQuery) && oldBondData.find((g) => g.query === forQuery).sliderValue <= 0) {
      //delete OPeration NO IMPACT
      console.log('delete OPeration NO IMPACT');
      oldBondData.find((g) => g.query === forQuery).sliderValue = 0;
      bondTab.data.find((g) => g.query === forQuery).sliderValue = 0;
      if (typeof selectedFunds !== 'undefined' && forQuery) {
        const filteredArray = bondTab.selectedFunds[forQuery];
        if (bondTab.selectedFunds[forQuery]) {
          bondTab.selectedFunds[forQuery] = selectedFunds;
        } else {
          bondTab.selectedFunds = {
            ...bondTab.selectedFunds,
            [forQuery]: selectedFunds,
          };
        }

        if (setBuildPortfolioState) {
          setBuildPortfolioState({
            bondTab: buildportfolio.bondTab,
          });
        }

      }

    } else {
      if (typeof selectedFunds !== 'undefined' && forQuery) {
        if (bondTab.selectedFunds[forQuery]) {
          bondTab.selectedFunds[forQuery] = selectedFunds;
        } else {
          bondTab.selectedFunds = {
            ...bondTab.selectedFunds,
            [forQuery]: selectedFunds,
          };
        }

        const getStateMappedData = getSelectedFundsCount({ allStates: buildportfolio, tab: 'bondTab' });
        // console.log(getStateMappedData);

        topTabHeaderMenu.forEach((item, i) => {
          // if all tabs has 0 weights
          if (topTabHeaderMenu[0].sliderValue === 0 && topTabHeaderMenu[1].sliderValue === 0 && topTabHeaderMenu[2].sliderValue === 0) {
            // then current active tab will be 100; for.e.g: equityTab: 100%
            if (item.key === 'bondTab') {
              item.sliderValue = 100;
              if (bondTab.data) {
                let sumofRows = 0;
                // console.log(bondTab.data);
                bondTab.data.map((k) => {
                  sumofRows += k.sliderValue;
                });
                this.executeMe(forQuery, bondTab.selectedFunds, item, bondTab.data, getStateMappedData, sumofRows, topTabHeaderMenu);
              }
            }
          } else {
            // console.log('No, some tabs have weights');
            // before assigning weight to bondTab check if it is having funds ?

            this.runWeightAllocation(getStateMappedData, topTabHeaderMenu);

            // console.log(topTabHeaderMenu);

            if (item.key === 'bondTab') {
              if (bondTab.data) {
                let sumofRows = 0;
                bondTab.data.map((k) => {
                  sumofRows += k.sliderValue;
                });
                this.executeMe(forQuery, bondTab.selectedFunds, item, bondTab.data, getStateMappedData, sumofRows, topTabHeaderMenu);
              }
            }

          }
        });

        // console.log(JSON.parse(JSON.stringify(bondTab)));

        // const getRowDataSliderValue = bondTab.data.find((t) => t.query === forQuery);
        // bondTab.selectedFunds[forQuery].forEach((y) => {
        //   if (getRowDataSliderValue && typeof getRowDataSliderValue !== 'undefined') {
        //     y._sliderValue = getRowDataSliderValue.sliderValue/bondTab.selectedFunds[forQuery].length;
        //   }
        // });

        // bondTab.data.map((l) => {
        //   if (l.query === forQuery) {
        //     l.result.funds.map((w) => {
        //       w._sliderValue = 0;
        //       if (bondTab.selectedFunds[forQuery] && typeof bondTab.selectedFunds[forQuery] !== 'undefined') {
        //         const found = bondTab.selectedFunds[forQuery].find((o) => o.ticker === w.ticker);
        //         if (found && typeof found !== 'undefined' && (found.ticker === w.ticker)) {
        //           w._sliderValue = getRowDataSliderValue.sliderValue/bondTab.selectedFunds[forQuery].length;
        //         }
        //         bondTab.selectedFunds[forQuery].forEach((y) => {
        //           if (getRowDataSliderValue && typeof getRowDataSliderValue !== 'undefined') {
        //             y._sliderValue = getRowDataSliderValue.sliderValue/bondTab.selectedFunds[forQuery].length;
        //           }
        //         });
        //       }
        //     });
        //   }
        // });

        buildportfolio.bondTab.data.map((l) => {
          l.result.funds.map((w) => {
            // w._sliderValue = 0;
            if(l.query && l.sliderValue != oldBondData.find((o) => o.query === l.query).sliderValue){
              w._sliderValue = 0;
              const diff = l.sliderValue - oldBondData.find((o) => o.query === l.query).sliderValue
              // console.log('sid30', l.query, forQuery, oldBondData, buildportfolio.bondTab.data)
              if (buildportfolio.bondTab.selectedFunds[l.query] && typeof buildportfolio.bondTab.selectedFunds[l.query] !== 'undefined') {
                //selected funds
                // console.log('sid31')
                //for the data
                const found = buildportfolio.bondTab.selectedFunds[l.query].find((o) => o.ticker === w.ticker);
                if (found && typeof found !== 'undefined' && (found.ticker === w.ticker)) {
                  // console.log('sid32')
                  if(buildportfolio.bondTab.selectedFunds[l.query].length && buildportfolio.bondTab.selectedFunds[l.query].length == 1){
                    // console.log('sid321')
                    w._sliderValue = l.sliderValue/buildportfolio.bondTab.selectedFunds[l.query].length;
                  }
                  else if(buildportfolio.bondTab.selectedFunds[l.query].length && buildportfolio.bondTab.selectedFunds[l.query].length > 1){
                    //diff
                    //ratio = 
                    //wt
                    // console.log('sid322')
                    const oldFound = oldBondTab.selectedFunds[l.query].find((o) => o.ticker === w.ticker);
                    let ratio = oldFound.sliderValue / oldBondData.find((o) => o.query === l.query).sliderValue;
                    let wt = diff * ratio;
                    w._sliderValue = oldFound.sliderValue + wt;
                  }
                }
                //for all selected funds
                buildportfolio.bondTab.selectedFunds[l.query].forEach((y) => {
                  if (y && typeof y !== 'undefined') {
                    // console.log('sid33', y)
                    if(buildportfolio.bondTab.selectedFunds[l.query].length == 1) {
                      // console.log('sid331')
                      y._sliderValue = l.sliderValue/buildportfolio.bondTab.selectedFunds[l.query].length;
                    }
                    else if(buildportfolio.bondTab.selectedFunds[l.query].length > 1){
                      // console.log('sid332')
                      //diff
                      //ratio
                      //wt
                      const oldFound = oldBondTab.selectedFunds[l.query].find((o) => o.ticker === y.ticker);
                      // console.log('sid334',oldBondTab, oldFound, oldBondData, l.query, w.ticker)
                      let ratio = oldFound._sliderValue / oldBondData.find((o) => o.query === l.query).sliderValue;
                      let wt = diff * ratio;
                      y._sliderValue = oldFound._sliderValue + wt;
                      // console.log('sid335',diff, ratio, wt, oldFound, oldBondData.find((o) => o.query === l.query));
                    }      
                  }
                });
              }
            }
            else if(l.query && l.sliderValue == oldBondData.find((o) => o.query === l.query).sliderValue && l.query == forQuery){
              w._sliderValue = 0;
              // console.log('sid30', l.query, forQuery, oldBondData, buildportfolio.bondTab.data)
              if (buildportfolio.bondTab.selectedFunds[l.query] && typeof buildportfolio.bondTab.selectedFunds[l.query] !== 'undefined') {
                //selected funds
                // console.log('sid31')
                //for the data
                const found = buildportfolio.bondTab.selectedFunds[l.query].find((o) => o.ticker === w.ticker);
                const oldFound = oldBondTab.selectedFunds[l.query].find((o) => o.ticker === w.ticker);
                if (found && typeof found !== 'undefined'&& oldFound && typeof oldFound !== 'undefined' && (found.ticker === w.ticker)) {
                  // console.log('sid32')
                  // console.log('sid321')
                  //if length == 1
                  // add
                  //delete
                  if(buildportfolio.bondTab.selectedFunds[l.query].length == 1){
                    // console.log('pratik 1')
                    w._sliderValue = l.sliderValue/buildportfolio.bondTab.selectedFunds[l.query].length;
                  }
                  else if(buildportfolio.bondTab.selectedFunds[l.query].length >= oldBondTab.selectedFunds[l.query].length 
                          && oldBondTab.selectedFunds[l.query].find((o) => o._sliderValue === 0)
                          && oldSelectedfunds && oldSelectedfunds[l.query] && oldSelectedfunds[l.query].length < selectedFunds.length) {
                    //add
                    // if fund == 0 add 10% diff
                    if(oldFound._sliderValue == 0){
                      w._sliderValue = (10*l.sliderValue)/100;
                      // console.log('pratik add 1', w.ticker, w._sliderValue)
                    }
                    else{
                      let diff = -(10*l.sliderValue)/100;
                      let ratio = oldFound._sliderValue / oldBondData.find((o) => o.query === l.query).sliderValue;
                      let wt = diff * ratio
                      w._sliderValue = oldFound._sliderValue + wt;
                      // console.log('pratik add >1', w.ticker, w._sliderValue, diff, ratio, wt)
                    }
                    // console.log('pratik add', w.ticker, w._sliderValue)
                  }
                  else if(buildportfolio.bondTab.selectedFunds[l.query].length >= oldBondTab.selectedFunds[l.query].length 
                          && !oldBondTab.selectedFunds[l.query].find((o) => o._sliderValue === 0)
                          && oldSelectedfunds && oldSelectedfunds[l.query] && oldSelectedfunds[l.query].length > selectedFunds.length) {
                    //delete check
                    //_.differenceBy([{ 'x': 2 }, { 'x': 1 }], [{ 'x': 1 }], 'x');
                    // old , new
                    let diffObj = _.differenceBy(oldSelectedfunds[l.query], selectedFunds, 'ticker');
                    if(diffObj.length){
                      let filterArrSum = _.sumBy(oldSelectedfunds[l.query].filter(o => o.ticker != diffObj[0].ticker),'_sliderValue');
                      // _.sumBy(objects, 'n');
                      let diff = diffObj[0]._sliderValue;
                      let ratio = oldFound._sliderValue / filterArrSum;
                      let wt = diff * ratio
                      w._sliderValue = oldFound._sliderValue + wt;
                      
                      // console.log('pratik delete check', w.ticker, oldFound._sliderValue, diff, ratio, wt, w._sliderValue)
                    }
                  }
                  else if(buildportfolio.bondTab.selectedFunds[l.query].length < oldBondTab.selectedFunds[l.query].length
                          && oldSelectedfunds && oldSelectedfunds[l.query] && oldSelectedfunds[l.query].length > selectedFunds.length) {
                    //delete
                    //_.differenceBy([{ 'x': 2 }, { 'x': 1 }], [{ 'x': 1 }], 'x');
                    // old , new
                    let diffObj = _.differenceBy(oldBondTab.selectedFunds[l.query], buildportfolio.bondTab.selectedFunds[l.query], 'ticker');
                    if(diffObj.length){
                      let filterArrSum = _.sumBy(oldBondTab.selectedFunds[l.query].filter(o => o.ticker != diffObj[0].ticker),'_sliderValue');
                      // _.sumBy(objects, 'n');
                      let diff = diffObj[0]._sliderValue;
                      let ratio = oldFound._sliderValue / filterArrSum;
                      let wt = diff * ratio
                      w._sliderValue = oldFound._sliderValue + wt;
                      // console.log('pratik delete', diffObj, w.ticker, w._sliderValue)
                    }
                  }
                  else{
                    // console.log('pratik else')
                    w._sliderValue = l.sliderValue/buildportfolio.bondTab.selectedFunds[l.query].length;
                  }
                // }
                  //for all selected funds
                  buildportfolio.bondTab.selectedFunds[l.query].forEach((y) => {
                    const oldFound = oldBondTab.selectedFunds[l.query].find((o) => o.ticker === y.ticker);
                    if (y && typeof y !== 'undefined') {
                      // console.log('sid33', y)
                      // console.log('sid331')
                      if(buildportfolio.bondTab.selectedFunds[l.query].length == 1){
                        // console.log('pratik 1')
                        y._sliderValue = l.sliderValue/buildportfolio.bondTab.selectedFunds[l.query].length;
                      }
                      else if(buildportfolio.bondTab.selectedFunds[l.query].length >= oldBondTab.selectedFunds[l.query].length 
                              && oldBondTab.selectedFunds[l.query].find((o) => o._sliderValue === 0)
                              && oldSelectedfunds && oldSelectedfunds[l.query] && oldSelectedfunds[l.query].length < selectedFunds.length) {
                        //add
                        // if fund == 0 add 10% diff
                        if(oldFound._sliderValue == 0){
                          y._sliderValue = (10*l.sliderValue)/100;
                        }
                        else{
                          let diff = -(10*l.sliderValue)/100;
                          let ratio = oldFound._sliderValue / oldBondData.find((o) => o.query === l.query).sliderValue;
                          let wt = diff * ratio
                          y._sliderValue = oldFound._sliderValue + wt;
                        }
                        // console.log('pratik add', y.ticker, y._sliderValue)
                      }
                      else if(buildportfolio.bondTab.selectedFunds[l.query].length >= oldBondTab.selectedFunds[l.query].length 
                        && !oldBondTab.selectedFunds[l.query].find((o) => o._sliderValue === 0)
                        && oldSelectedfunds && oldSelectedfunds[l.query] && oldSelectedfunds[l.query].length > selectedFunds.length) {
                        //delete check
                        //_.differenceBy([{ 'x': 2 }, { 'x': 1 }], [{ 'x': 1 }], 'x');
                        // old , new
                        let diffObj = _.differenceBy(oldSelectedfunds[l.query], selectedFunds, 'ticker');
                        if(diffObj.length){
                          let filterArrSum = _.sumBy(oldSelectedfunds[l.query].filter(o => o.ticker != diffObj[0].ticker),'_sliderValue');
                          // _.sumBy(objects, 'n');
                          let diff = diffObj[0]._sliderValue;
                          let ratio = oldFound._sliderValue / filterArrSum;
                          let wt = diff * ratio
                          y._sliderValue = oldFound._sliderValue + wt;
                          
                          // console.log('pratik delete check', y.ticker, oldFound._sliderValue, diff, ratio, wt, y._sliderValue)
                        }
                      }
                      else if(buildportfolio.bondTab.selectedFunds[l.query].length < oldBondTab.selectedFunds[l.query].length) {
                        //delete
                        //_.differenceBy([{ 'x': 2 }, { 'x': 1 }], [{ 'x': 1 }], 'x');
                        // old , new
                        let diffObj = _.differenceBy(oldBondTab.selectedFunds[l.query], buildportfolio.bondTab.selectedFunds[l.query], 'ticker');
                        if(diffObj.length){
                          let filterArrSum = _.sumBy(oldBondTab.selectedFunds[l.query].filter(o => o.ticker != diffObj[0].ticker),'_sliderValue');
                          // _.sumBy(objects, 'n');
                          let diff = diffObj[0]._sliderValue;
                          let ratio = oldFound._sliderValue / filterArrSum;
                          let wt = diff * ratio
                          y._sliderValue = oldFound._sliderValue + wt;
                          
                          // console.log('pratik delete', y.ticker, oldFound._sliderValue, diff, ratio, wt, y._sliderValue)
                        }
                      }
                      else{
                        // console.log('pratik else', buildportfolio.bondTab.selectedFunds[l.query].length , oldBondTab.selectedFunds[l.query].length)
                        y._sliderValue = l.sliderValue/buildportfolio.bondTab.selectedFunds[l.query].length; 
                      }
                    }
                  });
                }
              }
            }
          });
        });
        buildportfolio.bondTab._isCustomInput=false
        // console.log(JSON.parse(JSON.stringify(bondTab)));
        // console.log(bondTab);

        // console.log(oldBuildportfolioState);

        if (setBuildPortfolioState) {
          setBuildPortfolioState({
            oldHeaderMenuState,
            oldThematicData,
            oldEquityData,
            oldBondData,
            oldEquityTab,
            oldBondTab,
            oldThematicTab,
            bondTab: buildportfolio.bondTab,
          });
        }
        // console.log(selectedFunds);
        // console.log(bondTab);

        // if (setBuildPortfolioState) {
        //   setBuildPortfolioState({
        //     bondTab: buildportfolio.bondTab,
        //   });
        //   this.calCulateWeight(forQuery)
        // }
      }
    }

  }

  callback = (key) => {
    this.setState({ activeTabPane: key });
  }

  getCheckedListArray = (item) => {
    const { bondTab } = this.props;
    const { selectedFunds } = bondTab;
    if (selectedFunds) {
      const label = item.label || item;
      const arr = selectedFunds[label] || [];
      return arr;
    }
    return [];
  }

  handleClose = (event, currentKey) => {
    event.stopPropagation();
    if (this.state.activeTabPane !== currentKey) {
      this.setState({ activeTabPane: currentKey })
    }
  }

  getPaneHeader = (data, currentKey) => {
    if(data.forQuery === 'USA'){
      data.placeholder = 'US bond';
    } else if(data.forQuery === 'Developed Markets'){
      data.placeholder = 'developed market bond';
    } else if (data.forQuery === 'Emerging Markets'){
      data.placeholder = 'emerging market bond';
    } else if (data.forQuery === 'Global Markets') {
      data.placeholder = 'global market bond';
    }
    const { activeTabPane } = this.state;
    const { label, sliderValue, forQuery, placeholder } = data;
    const { bondTab } = this.props;
    const listArray = this.getCheckedListArray(label);

    return (
      <div className="accordin-head-wrap" onClick={(e) => this.handleClose(e, currentKey)}>
        <div className="title">
          <span>{label}</span><span className="slider-value">{parseFloat(sliderValue.toFixed(2))}%</span>
        </div>
        <Tooltip placement="bottom" title={`Which ${placeholder} funds would you like to invest in?`}>
          <div>
        <InputSearch
          placeholder={`Which ${placeholder} funds would you like to invest in?`}
          onClick={(e) => this.handleClose(e, currentKey)}
          onSearch={(value, event) => this.onSearch(value, forQuery, 'bondTab', event)}
          onChangeSearch={e => this.onChangeSearch(e, forQuery)}
          onBlur={() => this.onBlurDo(forQuery)}
        />
        </div></Tooltip>
        {(listArray.length === 0 ) && (
            <div className="pending-text">Pending Allocation</div>
          )
        }
        {listArray.filter((o) => o !== '' && o.ticker).map((y, index) => {
          return (
            <div key={`i-${index}`} className="selected-funds-tag">
              {y.ticker}
              <i className="far fa-times" onClick={(e) => this.removeSelectedThemes(e, y, label)} />
            </div>
          )
        })}
      </div>
    );
  }

  getCardTitle = (title) => {
    return (
      <div className="folder-content-card-title">
        {title}
      </div>
    )
  };

  getCardOptions = (menus) => {
    const { bondTab } = this.props;
    const menuItems = menus.map((u) => {
      return (
        <Menu.Item className={u.className} key={u.label} disabled={u.disabled} style={u.style}>
          {u.tooltip ? u.tooltip(<span>{u.icon}{u.label}</span>) : (
            <span>{u.icon}{u.label}</span>
          )}
        </Menu.Item>
      );
    });
    return (
      <Menu
        className="folder-content-card-menu"
        mode="horizontal"
        defaultSelectedKeys={[]}
        onClick={this.menuHandleClick}
      >
        <Menu.Item className="default-menu" key="Weighting Methodology" disabled>
          Initial Weight
        </Menu.Item>
        {menuItems}
      </Menu>
    )
  }

  onSearch = (value, forQuery, forTab, event) => {
    if (event) {
      event.stopPropagation();
    }
    const { searchQuery } = this.state;
    // if (this.collapseRef) {
    //   console.log(this.collapseRef.current);
    //   this.collapseRef.current.focus();
    // }
    if (value) {
      this.setState({
        searchQuery: value,
      }, () => {
        const { searchForNewQueryRequest } = this.props;
        if (searchForNewQueryRequest) {
          addEventToAnalytics('Builder Bond Search','Builder Bond Search','BUILDER_BOND_SEARCH',{ query: this.state.searchQuery },false);
          searchForNewQueryRequest({
            query: 'pf_builder_query',
            q: this.state.searchQuery,
            forQuery,
            forTab,
          });
        }
      });
    }
  }

  onBlurDo = (forQuery) => {
    if (this.state.searchQuery === '') {
     this.setState({
       searchQuery: forQuery,
     });
   }
  }

  onChangeSearch = (value, forQuery) => {
    // const value = e.target.value;
    this.setState({
      searchQuery: value,
    });
  }

  render() {
    const { tickerList, tickerLoading, customSearchTickerData, bondTab, weightingMethodology, buildportfolio } = this.props;
    const { fundCollectionList } = this.state;

    const checkedListArray = (item) => {
      const arr = this.getCheckedListArray(item);
      if (arr && arr.length) {
        return arr.map((v) => v.ticker);
      } else {
        return [];
      }
    }

    // fundCollectionList.map((l) => {
    //   const getRowDataSliderValue = buildportfolio.bondTab.data.find((t) => t.query === l.query);
    //   if (typeof buildportfolio.bondTab.selectedFunds[l.query] !== 'undefined') {
    //     const selF = buildportfolio.bondTab.selectedFunds[l.query];
    //     l.result.funds.map((w) => {
    //       w._sliderValue = 0;
    //       const found = selF.find((o) => o.ticker === w.ticker);
    //       if (found && typeof found !== 'undefined' && (found.ticker === w.ticker)) {
    //         w._sliderValue = getRowDataSliderValue.sliderValue/selF.length;
    //       }
    //     });
    //     buildportfolio.bondTab.selectedFunds[l.query].map((c) => {
    //       c._sliderValue = getRowDataSliderValue.sliderValue/selF.length;
    //     });
    //   }
    // });

    // console.log(fundCollectionList);

    return (
      <React.Fragment>
        <Row className="folder-content-container" style={{ paddingRight: 5 }}>
          <div className="bonds-section custom-scroll">
            <Card title={this.getCardTitle('Search and Select Funds')} extra={this.getCardOptions(buildPortfolioMenus)} className="folder-content-card-wrapper custom-scroll">
              <Collapse
                accordion
                ref={this.collapseRef}
                className="folder-content-accordion"
                activeKey={[this.state.activeTabPane]}
                expandIconPosition="right"
                expandIcon={() => (<div><i className="far fa-angle-down caret-icon" /></div>)}
                onChange={this.callback}
              >
                {
                  fundCollectionList.map((item, index) => {
                    return (
                      <Panel
                        header={this.getPaneHeader(item, `${index+1}`)}
                        key={`${index+1}`}
                      >
                        <ListView
                          data={item.result}
                          query={item.query}
                          newQuery={item.newQuery}
                          sliderValue={item.sliderValue}
                          weightingMethodology={weightingMethodology}
                          ticker={item.ticker}
                          staticCard={true}
                          customEdit={item.customEditScreen}
                          tickerList={tickerList}
                          tickerLoading={tickerLoading}
                          checkedListArray={checkedListArray(item)}
                          customSearchTickerCallback={() => this.getTickerData()}
                          customSearchTickerData={item.customSearchTickerData}
                          deleteCard={this.deleteCard}
                          getSelectedFunds={this.getSelectedFunds}
                          selectedFunds={bondTab.selectedFunds}
                        />
                      </Panel>
                    )
                  })
                }
              </Collapse>
            </Card>
          </div>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ buildportfolio, loading }) => ({
  buildportfolio,
  tickerList: buildportfolio.tickerList,
  customSearchTickerData: buildportfolio.bondTab.customSearchTickerData,
  tickerLoading: createLoadingSelector(['CUSTOM_TICKER', 'TICKER_DATA'])({ loading }),
})

const mapDispatchToProps = {
  tickerDataRequest,
  deleteCustomCard,
  setBuildPortfolioState,
  searchForNewQueryRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BondsContent);

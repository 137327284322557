import React from 'react';
import moment from 'moment'
import { isEmpty } from 'lodash'

export const IPODate = ({ card }) => {
    const ipoDate = !isEmpty(card) && !isEmpty(card.start_date) ? card.start_date : ''
    return (
        <div className="ipo-calender-container">
            <div className="ipo-date-container">
                <span className='ipo-calender-title'>IPO Date</span>
                <div><strong>{ipoDate ? new Date(ipoDate).getDate() : '-'}</strong></div>
                <div>{ipoDate ? moment(new Date(ipoDate)).format('MMM YYYY') : '-'}</div>
            </div>
        </div>
    )
}

export default IPODate;
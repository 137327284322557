import React from 'react';
import { QuinItems } from 'DataSet';
import NotApplicable from 'components/NotApplicable';
import FeesPopover from './FeesPopover';
import ReturnPopover from './ReturnPopover';
import RiskPopover from './RiskPopover';
import { Pct } from '../common';

const ReturnRisk = (props) => {
  const { card, chartData, highlightText, checkPopOpen, firstPop } = props;
  // if (typeof card._cstats === 'undefined') return;
  // const flags = card._flags.returnsMax || card._flags.risksMin || card._flags.feesMin;
  // console.log(flags);
  let feesQuin = QuinItems.find((item) => item.name === 'Fees');
  let returnQuin = QuinItems.find((item) => item.name === 'Return');
  let riskQuin = QuinItems.find((item) => item.name === 'Risk');

  return (
    <div className="card-background h-100 feesReturnRiskWrapper" style={{ display: 'flex' }}>
      {((typeof card._categorySeries === 'undefined' || card._categorySeries === null) && card.type === '') ? (
        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
          <NotApplicable />
        </div>
      ) : (
        <>
          <FeesPopover
            {...props}
            highlight={highlightText.feesHighLightEnable}
            icon={'fa-tags-'}
            qi={feesQuin}
            suffix={<Pct />}
          />
          <ReturnPopover
            {...props}
            highlight={highlightText.returnHighLightEnable}
            icon={'fa-chart-line-'}
            qi={returnQuin}
            suffix={<Pct />}
          />
          <RiskPopover
            {...props}
            highlight={highlightText.riskHighLightEnable}
            icon={'fa-tags-'}
            qi={riskQuin}
            suffix={<Pct />}
          />
        </>
      )}
    </div>
  );
}

export default ReturnRisk;

import React, { useContext } from 'react'
import { Row, Col } from 'reactstrap'

import { withHeaderFooter } from './HeaderFooter'
// import { std } from '../../BuildPortfolio/Pages/BuildFromScratch/utils'
// import { BarChartWithAxis, SimpleLineChartWithAxis } from '../../BuildPortfolio/Elements/Charts'
import PortfolioLineChart from './PortfolioLineChart'
import { CustomBarChart } from './BarChart'
import _ from 'lodash'
import PortfolioContext from '../PortfolioContext'

import { getDrawdownByYears, getAnnualizedReturnsByYears, getAnnualizedVolatilityByYears } from '../../AccountsPage/utils';
import { numberCheck } from 'layouts/utils';

const Performance = withHeaderFooter((props) => {
  const reportData = useContext(PortfolioContext)

  let { portfolio } = reportData;
  let { currentPerformanceImpact } = portfolio;

  let drowdownData = null;
  let enhancedPercent = null;

  let returnsCalcBasedOn = '3-Year',
      volatilityCalcBasedOn = '3-Year',
      currentPortfolioVolatility = '',
      enhancedPortfolioVolatility = '',
      currentPortfolioDrawdown = currentPerformanceImpact && currentPerformanceImpact.dMax ?  currentPerformanceImpact.dMax : '',
      enhancedPortfolioDrawdown = null;

  let currentStats = currentPerformanceImpact.pfRet3y;
  let enhanceStats = props.pfRet3y;
  let currentReturnsLegend = currentPerformanceImpact.anRet3yr;

  if (currentPerformanceImpact) {
    if (currentPerformanceImpact.pfRet10y && currentPerformanceImpact.pfRet10y.length) {
      returnsCalcBasedOn = '10-Year';
      volatilityCalcBasedOn = '10-Year';
      currentStats = currentPerformanceImpact.pfRet10y;
      enhanceStats = props.pfRet10y;
      drowdownData = getDrawdownByYears(props.pfRet10y);
      currentReturnsLegend = currentPerformanceImpact.anRet10yr;
      enhancedPercent = getAnnualizedReturnsByYears(props.pfRet10y);
      currentPortfolioVolatility = currentPerformanceImpact.anVol10yr;
      enhancedPortfolioVolatility = numberCheck(getAnnualizedVolatilityByYears(props.pfRet10y), 2);
    } else if (currentPerformanceImpact.pfRet5y && currentPerformanceImpact.pfRet5y.length) {
      returnsCalcBasedOn = '5-Year';
      volatilityCalcBasedOn = '5-Year';
      currentStats = currentPerformanceImpact.pfRet5y;
      enhanceStats = props.pfRet5y;
      drowdownData = getDrawdownByYears(props.pfRet5y);
      currentReturnsLegend = currentPerformanceImpact.anRet5yr;
      enhancedPercent = getAnnualizedReturnsByYears(props.pfRet5y);
      currentPortfolioVolatility = currentPerformanceImpact.anVol5yr;
      enhancedPortfolioVolatility = numberCheck(getAnnualizedVolatilityByYears(props.pfRet5y), 2);
    } else if (currentPerformanceImpact.pfRet3y && currentPerformanceImpact.pfRet3y.length) {
      returnsCalcBasedOn = '3-Year';
      volatilityCalcBasedOn = '3-Year';
      currentStats = currentPerformanceImpact.pfRet3y;
      enhanceStats = props.pfRet3y;
      drowdownData = getDrawdownByYears(props.pfRet3y);
      currentReturnsLegend = currentPerformanceImpact.anRet3yr;
      enhancedPercent = getAnnualizedReturnsByYears(props.pfRet3y);
      currentPortfolioVolatility = currentPerformanceImpact.anVol3yr;
      enhancedPortfolioVolatility = numberCheck(getAnnualizedVolatilityByYears(props.pfRet3y), 2);
    } else if (currentPerformanceImpact.pfRet1y && currentPerformanceImpact.pfRet1y.length) {
      returnsCalcBasedOn = '1-Year';
      volatilityCalcBasedOn = '1-Year';
      currentStats = currentPerformanceImpact.pfRet1y;
      enhanceStats = props.pfRet1y;
      drowdownData = getDrawdownByYears(props.pfRet1y);
      currentReturnsLegend = currentPerformanceImpact.anRet1yr;
      enhancedPercent = getAnnualizedReturnsByYears(props.pfRet1y);
      currentPortfolioVolatility = currentPerformanceImpact.anVol1yr;
      enhancedPortfolioVolatility = numberCheck(getAnnualizedVolatilityByYears(props.pfRet1y), 2);
    }
  }

  console.log(`========= Showing Returns of ${returnsCalcBasedOn} =========`);
  console.log('drowdownData -> ', drowdownData);
  console.log('enhancedPercent -> ', enhancedPercent);
  console.log('currentPortfolioVolatility -> ', currentPortfolioVolatility);
  console.log('enhancedPortfolioVolatility -> ', enhancedPortfolioVolatility);
  console.log(`============================================================`);

  if (drowdownData && drowdownData.dMax) {
    enhancedPortfolioDrawdown = drowdownData.dMax;
  }

  let selectedBenchmark = props.ratioString;

  if (!["SPY", "ACWI", "AGG"].includes(props.ratioString)) {
    let [equity, bond] = props.ratioString.match(/\d+/g);
    selectedBenchmark = `${equity}% ACWI/${bond}% AGG`
  }

	// let chartdata =  currentPerformanceImpact["pfRet3y"];
  let dataForVolandD = [
    {
      "name": "Volatility*",
      "portfolio": numberCheck(currentPortfolioVolatility, 2),
      "portfolioColor": "#56a9e8",
      "benchmarkColor": "#1e5f91",
      "benchmark": numberCheck(enhancedPortfolioVolatility, 2),
    },
    {
      "name": "Drawdown*",
      "portfolio": numberCheck(currentPortfolioDrawdown, 2),
      "portfolioColor": "#56a9e8",
      "benchmarkColor": "#1e5f91",
      "benchmark": numberCheck(enhancedPortfolioDrawdown, 2),
    }
  ];

	// dataForVolandD[0]['benchmark'] = parseFloat(enhancedVol)
	// dataForVolandD[1]['benchmark'] = parseFloat(dMax.toFixed(2))

	return (
    <React.Fragment>
        <div className="content">
          <div className="pr-v2-header">
            <h3>Performance Analytics</h3>
          </div>
          <div className="pr-v2-port-performance">
            <Row className="pr-v2-pp-sections">
              <Col xs="12">
                <h4>Portfolio vs Comparison ETF ({selectedBenchmark})*</h4>
                <div className="bg-chart-block">
                  <p className="mb-0" style={{fontSize: '1.3rem', color: '#505050'}}>Annualized Returns</p>
                  <div style={{display:'flex', width: '920px', height: '170px'}}>
                    <PortfolioLineChart
                      linechartId="portfolio-linechart"
                      currentStats={currentStats}
                      enhanceStats={enhanceStats}
                    />
                  </div>
                  <div className="pr-v2-chart-legends-container overview-portfolio-container">
                    <span className="mb-0" style={{ fontSize: '1rem', color: '#505050' }}>Annualized {returnsCalcBasedOn} Total Return<sup>*</sup></span>
                    <div className="pr-v2-chart-legends overview-portfolio">
                      <p>Portfolio <span className="pr-v2-light-blue">{currentReturnsLegend}%</span></p>
                      <p>Comparison ETF ({selectedBenchmark})* <span className="pr-v2-dark-blue">{enhancedPercent.toFixed(2)}%</span></p>
                    </div>
                  </div>
                </div>
                <div>
                  <p>The line graph represents the cumulative performance of a hypothetical $100 investment. The returns are net of fund expense ratios but do not reflect the payment of any brokerage commissions or brokerage costs incurred as a result of buying or selling fund shares and do not reflect the deduction of taxes to which an investor would be subject as a result of owning or selling shares of the fund. If they did, the returns would be lower than those shown.</p>
                  {/* <p>Annualized return is the amount of money earned by an investment each year assuming the annual return was compounded and including the reinvestments of dividends and distributions, without factoring in financial advisor fees.</p> */}
                </div>
              </Col>
            </Row>
            <Row className="pr-v2-pp-sections" style={{marginTop: '30px'}}>
              <Col xs="12">
                <h4>Risk</h4>
                <div className="bg-chart-block">
                  <p className="mb-0" style={{fontSize: '1rem', color: '#505050'}}>Volatility and Peak to trough</p>
                  <div style={{height: '320px', width: '890px'}}>
                    <CustomBarChart
                      data={dataForVolandD}
                    />
                  </div>
                  <div className="pr-v2-chart-legends">
                    <p>Portfolio</p>
										<p>Comparison ETF ({selectedBenchmark})* </p>
                  </div>
                </div>
                {/*
                  <div>
                    <p>Volatility is one indicator to portfolio risk. Too high means the portfolio is at risk of large losses. Too low, the portfolio’s potential is restricted.</p>
                    <p>Drawdown is a peak-to-trough decline of a portfolio. E.g. If a trading account has $10,000 in it, and the funds drop to $7,000 before moving back above $10,000, then the drawdown in this portfolio is 30%.</p>
                  </div>
                */}
              </Col>
            </Row>
          </div>
        </div>
    </React.Fragment>
  )
})
export default Performance

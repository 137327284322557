import React from 'react'
import { Modal, ModalBody } from 'reactstrap'

const OtherTextSubmitModal = ({ onModalToggle }) => (
  <Modal
    isOpen
    toggle={onModalToggle}
    centered
    keyboard={true}
    backdrop
    size="sm"
    className="premium-msg-modal"
    backdropClassName="premium-feature-process-modal-backdrop"
    style={{
      width: 300
    }}
  >
    <ModalBody className="ssf-modal__body">
      <div className="premium-feature-process-modal-container text-center">
        <i
          className="fal fa-times-circle premium-feature-completed-close"
          onClick={onModalToggle}
        ></i>
        <h4
          className="content-wrapper"
          style={{
            marginBottom: 0
          }}
        >
          Query has been recorded
        </h4>
      </div>
    </ModalBody>
  </Modal>
)

export default OtherTextSubmitModal

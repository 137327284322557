import React from 'react';
import { ENHANCER_PREDEFINED_SEARCH_QUERIES } from 'DataSet';

const numFmt = (num, prec = 2) => (num || 0).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: prec })

export const FormatCurrency = (value, prec = 0, curr = '$', type) => {
  let _prefix = (value < 0 ? '-' : '');
  let [_value, _suffix, _prec] = [Math.abs(value), '', prec];

  if (_value >= 1e9)
    [_value, _suffix, _prec] = [_value / 1e9, 'B', 2];
  else if (_value >= 1e6)
    [_value, _suffix, _prec] = [_value / 1e6, 'M', 2];
  else if (_value >= 1e3)
    [_value, _suffix, _prec] = [_value / 1e3, 'K', 2];

  if (_value >= 100 && _prec >= 0) _prec = 0;
  else if (_value >= 10 && _prec >= 0) _prec = 1;

  if (type == 'unf') return `${_prefix}${curr}${numFmt(Math.abs(value))}`
  return `${_prefix}${curr}${numFmt(_value, _prec)}${_suffix}`
}

export const joinNames = names => (
  names.length < 2
    ? names.join('')
    : [names.slice(0, -1).join(', '), names.slice(-1)[0]].join(' and ')
)

export const getColor = (c, i) => c[i % c.length]

export const AttrColors = ['#3f7367', '#a5685f', '#555'];

export const ScoreAttrName = code => ScoreAttrs[code].name

export const ScoreAttrs = {
  risk: { name: 'Risk', icon: 'heart-rate' },
  return: { name: 'Return', icon: 'chart-line' },
  fee: { name: 'Fee', icon: 'tag' },
}

export const assetAllocData = (obj) => {
  const otherVal = getOthersVal(obj);
  return [
    {
      name: "Equity",
      value: obj.equities,
      originalValue: obj.equities
    },
    {
      name: "Bonds",
      value: obj.bonds,
      originalValue: obj.equities
    },
    {
      name: "Others",
      value: otherVal,
      originalValue: otherVal
    }
  ]
}

export const assetAllocData2 = (obj) => {
  if (obj === null) return null
  let bondsPlusEq = 0
  let temp = []
  temp.push(obj.find(i => {
    if ((i.name === 'Equities')) {
      bondsPlusEq += i.value
      return {
        name: 'Equity',
        originalValue: i.value.toFixed(2),
        value: i.value.toFixed(2)
      }
    }
  }))
  temp.push(obj.find(i => {
    if ((i.name === 'Bonds')) {
      bondsPlusEq += i.value
      return {
        name: 'Fixed Income',
        originalValue: i.value.toFixed(2),
        value: i.value.toFixed(2)
      }
    }
  }))
  temp.push({
    name: "Others",
    value: 100 - bondsPlusEq.toFixed(2),
    originalValue: 100 - bondsPlusEq.toFixed(2),
  })
  return temp

}

export const getOthersVal = (obj) => {
  let sum = 0;
  for (let key in obj) {
    if (key != 'equities' && key != 'bonds') { sum += obj[key]; }
  }
  return sum;
}

export const ConclusionTextForHoldings = ({ portPerfPercent }) => {
  return {
    'Lower Cost Alternatives': `Replacing your current holdings with suggested lower cost alternative may enhance your portfolio performance by ${portPerfPercent}% of your starting portfolio`,
    'Best Performing Options In Category': `Replacing your current holdings with suggested better performing alternatives may enhance your portfolio performance by ${portPerfPercent}% of your starting portfolio.`,
    'Lower Risk Alternatives': `Replacing your current holdings with suggested lower risk alternatives may enhance your portfolio performance by ${portPerfPercent}% of your starting portfolio.`,
    // 'Lower Cost And Risk Alternatives' : '',
    // 'Lower Drawdown Alternatives' : '',
    'Highest Yield Alternatives': `Replacing your current holdings with suggested higher yield alternatives may enhance your portfolio performance by ${portPerfPercent}% of your starting portfolio.`,
    'Long Track Record Alternatives': `Replacing your current holdings with suggested higher track record alternatives may enhance your portfolio performance by ${portPerfPercent}% of your starting portfolio.`,
    // 'Lower Tracking Error Alternatives' : '',
    'Highest Risk Adjusted Return Alternatives': `Replacing your current holdings with suggested higher risk adjusted return alternatives may enhance your portfolio performance by ${portPerfPercent}% of your starting portfolio.`,
    'Best Alternatives Using Passive Fund Selector': `Replacing your current holdings with suggested alternatives may enhance your portfolio performance by ${portPerfPercent}% of your starting portfolio.`,
    // 'Best Alternatives Using Passive Fund Selector' : '',
    // 'Best Alternatives Using Active Fund Selector' : '',
    // 'Low Cost Alternatives' : '',
    // 'Low Cost And Low Risk Alternatives' : '',
    // 'Lower Drawdown Alternatives' : '',
    // 'Long Track Record Alternatives' : '',
    // 'Lower Tracking Error Alternatives' : '',
    // 'Lower Risk Alternatives' : '',
    // 'Highest Yield Alternatives' : '',
    // 'Highest Risk Adjusted Return Alternatives' : '',
    // 'Best Performing Options In Category' : '',
    // 'Best Alternative Using My Investment Selector' : '',
  }
};

export const getSummaryContent = (query, fundCount, totalSavingsCost, totalSavingsCostNYrs, totalSavingsPercent, noOfYrs, crStd, enStd, crVolPrc, enVolPrc, crYieldRange, enYieldRange, crTrackRange, enTrackRange, fundSelector, modelPortfolioData, genExpRet) => {
  let view = ''
  totalSavingsCost = FormatCurrency(totalSavingsCost, 0, '$', 'unf');
  totalSavingsCostNYrs = FormatCurrency(totalSavingsCostNYrs, 0, '$', 'unf');
  let fundCountTxt = fundCount > 1 ? 'are ' + fundCount + ' holdings' : 'is ' + fundCount + ' holding';
  let { currentPerformanceImpact: { sharpe_pfRet3y }, enhancedPerformanceImpact: { sharpe_pfRet3y: sharpe_pfRet3y_enhanced }, modelEnhancement } = modelPortfolioData;

  let getName = () => modelPortfolioData.enhanceReplace[0].selectedPortfolioFunds.short_name ? modelPortfolioData.enhanceReplace[0].selectedPortfolioFunds.short_name : modelPortfolioData.enhanceReplace[0].selectedPortfolioFunds.name

  if (modelPortfolioData.isModelPortfolioQuery == true) {
    if (query === 'Lower Cost Alternatives') {
      view = (
        <div>
          <p>We evaluated your portfolio for potential improvements using lower cost alternatives to your existing holdings.</p>
          <p>Your current portfolio can be replaced by {getName()} to generate a savings of {totalSavingsCost} per year. This could translates to {totalSavingsCostNYrs} over {noOfYrs} years or {totalSavingsPercent}% of starting portfolio value.</p>
        </div>
      )
    } else if (query === 'Best Performing Options In Category') {
      view = (
        <div>
          <p>We evaluated your portfolio for potential improvements using better performing Alternatives.</p>
          <p>Your current portfolio can be replaced by {getName()} to generate a higher return of {totalSavingsCost} per year. This could translates to {totalSavingsCostNYrs} over {noOfYrs} years or {totalSavingsPercent}% of starting portfolio value.</p>
        </div>
      )
    } else if (query === 'Lower Risk Alternatives') {
      view = (
        <div>
          <p>We evaluated your portfolio for potential improvements using lower risk alternatives.</p>
          {/*<p>Your current portfolio can be replaced by {getName()} to reduce your annualized volatility from {crVolPrc}% to {enVolPrc}% per year. This could change your expected returns from {crStd} to {enStd} per annum which could translate to additional returns of {totalSavingsCostNYrs} over {noOfYrs} years.</p>*/}
          <p>Your current portfolio can be replaced by {getName()} to reduce your 3 year annualized volatility from {crVolPrc}% to {enVolPrc}%. This could change your 3 year annualized returns from {crStd} to {enStd} which could translate to additional returns of {totalSavingsCostNYrs} over {noOfYrs} years.</p>
        </div>
      )
    } else if (query == 'Highest Yield Alternatives') {
      view = (
        <div>
          <p>We evaluated your portfolio for potential improvements using higher yield alternatives.</p>
          {/*<p>Your current portfolio can be replaced by {getName()} to improve the annual yield from {crYieldRange}% to {enYieldRange}%. This could change your expected returns from {crStd} to {enStd} per annum which could translate to additional returns of {totalSavingsCostNYrs} over {noOfYrs} years.</p>*/}
          <p>Your current portfolio can be replaced by {getName()} to improve 3 year annualized yield from {crYieldRange}% to {enYieldRange}%. This could change your 3 year annualized returns from {crStd} to {enStd} which could translate to additional returns of {totalSavingsCostNYrs} over {noOfYrs} years.</p>
        </div>
      )
    } else if (query === 'Long Track Record Alternatives') {
      view = (
        <div>
          <p>We evaluated your portfolio for potential improvements using alternatives with a higher track record.</p>
          {/*<p>Your current portfolio can be replaced by {getName()} with funds that have a longer track record, taking the average track record of your portfolio from {crTrackRange} years to {enTrackRange} years. This could change your expected returns from {crStd} to {enStd} per annum which could translate to additional returns of {totalSavingsCostNYrs} over {noOfYrs} years.</p>*/}
          <p>Your current portfolio can be replaced by {getName()} with funds that have a longer track record, taking the average track record of your portfolio from {crTrackRange} years to {enTrackRange} years. This could change your 3 year annualized returns from {crStd} to {enStd} which could translate to additional returns of {totalSavingsCostNYrs} over {noOfYrs} years.</p>
        </div>
      )
    } else if (query === 'Best Alternatives Using Passive Fund Selector') {
      view = (
        <div>
          <p>We evaluated your portfolio for potential improvements using {fundSelector}.</p>
          {/*<p>Your current portfolio can be replaced by {getName()} to improve your portfolio based on these metrics. This could change your expected returns from {crStd} to {enStd} per annum which could translate to additional returns of {totalSavingsCostNYrs} over {noOfYrs} years.</p>*/}
          <p>Your current portfolio can be replaced by {getName()} to improve your portfolio based on these metrics. This could change your 3 year annualized returns from {crStd} to {enStd} which could translate to additional returns of {totalSavingsCostNYrs} over {noOfYrs} years.</p>
        </div>
      )
    } else if (query === 'Highest Risk Adjusted Return Alternatives') {
      view = (
        <div>
          <p>We evaluated your portfolio for highest risk adjusted return alternatives.</p>
          <p>Your current portfolio can be replaced by {getName()} to improve your Sharpe ratio from {sharpe_pfRet3y} to {sharpe_pfRet3y_enhanced}.</p>
        </div>
      )
    } else if (query === 'Lower Drawdown Alternatives') {
      view = (
        <div>
          <p>We evaluated your portfolio for lower drawdown alternatives.</p>
          {/*<p>Your current portfolio can be replaced by {getName()}. The expected returns of the portfolio could change from {crStd} to {enStd} per annum and the expected risk (volatility) will change from {crVolPrc}% to {enVolPrc}% per annum.</p>*/}
          <p>Your current portfolio can be replaced by {getName()}. The 3 year annualized returns of the portfolio could change from {crStd} to {enStd} and 3 year annualized volatility could change from {crVolPrc}% to {enVolPrc}%.</p>
        </div>
      )
    } else if (query === 'Lower Cost And Risk Alternatives') {
      view = (
        <div>
          <p>We evaluated your portfolio for lower cost and risk alternatives.</p>
          {/*<p>Your current portfolio can be replaced by {getName()}. The expected returns of the portfolio could change from {crStd} to {enStd} per annum and the expected risk (volatility) will change from {crVolPrc}% to {enVolPrc}% per annum.</p>*/}
          <p>Your current portfolio can be replaced by {getName()}. The 3 year annualized returns of the portfolio could change from {crStd} to {enStd} and 3 year annualized volatility could change from {crVolPrc}% to {enVolPrc}%.</p>
        </div>
      )
    } else if (query === 'Lower Tracking Error Alternatives') {
      view = (
        <div>
          <p>We evaluated your portfolio for lower tracking error alternatives.</p>
          {/*<p>Your current portfolio can be replaced by {getName()}. The expected returns of the portfolio could change from {crStd} to {enStd} per annum and the expected risk (volatility) will change from {crVolPrc}% to {enVolPrc}% per annum.</p>*/}
          <p>Your current portfolio can be replaced by {getName()}. The 3 year annualized returns of the portfolio could change from {crStd} to {enStd} and 3 year annualized volatility could change from {crVolPrc}% to {enVolPrc}%.</p>
        </div>
      )
    } else if (query) {
      view = (
        <div>
          <p>We evaluated your portfolio for "{query}" alternatives.</p>
          {/*<p>Your current portfolio can be replaced by {getName()}. The expected returns of the portfolio could change from {crStd} to {enStd} per annum and the expected risk (volatility) will change from {crVolPrc}% to {enVolPrc}% per annum.</p>*/}
          <p>Your current portfolio can be replaced by {getName()}. The 3 year annualized returns of the portfolio could change from {crStd} to {enStd} and 3 year annualized volatility could change from {crVolPrc}% to {enVolPrc}%.</p>
        </div>
      )
    } else {
      view = '';
    }
  }
  else {
    if (query === 'Lower Cost Alternatives') {
      view = (
        <div>
          <p>We evaluated your portfolio for potential improvements using lower cost alternatives to your existing holdings.</p>
          <p>There {fundCountTxt} that can be replaced this could generate a savings of {totalSavingsCost} per year. This could translates to {totalSavingsCostNYrs} over {noOfYrs} years or {totalSavingsPercent}% of starting portfolio value.</p>
        </div>
      )
    } else if (query === 'Best Performing Options In Category') {
      view = (
        <div>
          <p>We evaluated your portfolio for potential improvements using better performing Alternatives.</p>
          <p>There {fundCountTxt} that can be replaced this could generate a higher return of {totalSavingsCost} per year. This could translates to {totalSavingsCostNYrs} over {noOfYrs} years or {totalSavingsPercent}% of starting portfolio value.</p>
        </div>
      )
    } else if (query === 'Lower Risk Alternatives') {
      view = (
        <div>
          <p>We evaluated your portfolio for potential improvements using lower risk alternatives.</p>
          {/*<p>There {fundCountTxt} that can be replaced to reduce your annualized volatility from {crVolPrc}% to {enVolPrc}% per year. This could change your expected returns from {crStd} to {enStd} per annum which could translate to additional returns of {totalSavingsCostNYrs} over {noOfYrs} years.</p>*/}
          <p>There {fundCountTxt} that can be replaced to reduce your 3 year annualized volatility from {crVolPrc}% to {enVolPrc}%. This could change your 3 year annualized returns from {crStd} to {enStd} which could translate to additional returns of {totalSavingsCostNYrs} over {noOfYrs} years.</p>
        </div>
      )
    } else if (query === 'Highest Yield Alternatives') {
      view = (
        <div>
          <p>We evaluated your portfolio for potential improvements using higher yield alternatives.</p>
          {/*<p>There {fundCountTxt} that can be replaced to improve the annual yield from {crYieldRange}% to {enYieldRange}%. This could change your expected returns from {crStd} to {enStd} per annum which could translate to additional returns of {totalSavingsCostNYrs} over {noOfYrs} years.</p>*/}
          <p>There {fundCountTxt} that can be replaced to improve the annual yield from {crYieldRange}% to {enYieldRange}%. This could change your 3 year annualized returns from {crStd} to {enStd} which could translate to additional returns of {totalSavingsCostNYrs} over {noOfYrs} years.</p>
        </div>
      )
    } else if (query === 'Long Track Record Alternatives') {
      view = (
        <div>
          <p>We evaluated your portfolio for potential improvements using alternatives with a higher track record.</p>
          {/*<p>There {fundCountTxt} that can be replaced with funds that have a longer track record, taking the average track record of your portfolio from {crTrackRange} years to {enTrackRange} years. This could change your expected returns from {crStd} to {enStd} per annum which could translate to additional returns of {totalSavingsCostNYrs} over {noOfYrs} years.</p>*/}
          <p>There {fundCountTxt} that can be replaced with funds that have a longer track record, taking the average track record of your portfolio from {crTrackRange} years to {enTrackRange} years. This could change your 3 year annualized returns from {crStd} to {enStd} which could translate to additional returns of {totalSavingsCostNYrs} over {noOfYrs} years.</p>
        </div>
      )
    } else if (query === 'Best Alternatives Using Passive Fund Selector') {
      view = (
        <div>
          <p>We evaluated your portfolio for potential improvements using {fundSelector}.</p>
          {/*<p>There {fundCountTxt} that can be replaced to improve your portfolio based on these metrics. This could change your expected returns from {crStd} to {enStd} per annum which could translate to additional returns of {totalSavingsCostNYrs} over {noOfYrs} years.</p>*/}
          <p>There {fundCountTxt} that can be replaced to improve your portfolio based on these metrics. This could change your 3 year annualized returns from {crStd} to {enStd} which could translate to additional returns of {totalSavingsCostNYrs} over {noOfYrs} years.</p>
        </div>
      )
    } else if (query === 'Highest Risk Adjusted Return Alternatives') {
      view = (
        <div>
          <p>We analyzed your portfolio for highest risk adjusted return alternatives.</p>
          <p>There {fundCountTxt} that we can replace to improve your Sharpe ratio from {sharpe_pfRet3y} to {sharpe_pfRet3y_enhanced}.</p>
        </div>
      )
    } else if (query === 'Lower Drawdown Alternatives') {
      view = (
        <div>
          <p>We analyzed your portfolio for lower drawdown alternatives.</p>
          {/*<p>There {fundCountTxt} that we can replace. The expected returns of the portfolio could change from {crStd} to {enStd} per annum and the expected risk (volatility) will change from {crVolPrc}% to {enVolPrc}% per annum.</p>*/}
          <p>There {fundCountTxt} that we can replace. The 3 year annualized returns of the portfolio could change from {crStd} to {enStd} and 3 year annualized volatility could change from {crVolPrc}% to {enVolPrc}%.</p>
        </div>
      )
    } else if (query === 'Lower Cost And Risk Alternatives') {
      view = (
        <div>
          <p>We analyzed your portfolio for lower cost and risk alternatives.</p>
          {/*<p>There {fundCountTxt} that we can replace. The expected returns of the portfolio could change from {crStd} to {enStd} per annum and the expected risk (volatility) will change from {crVolPrc}% to {enVolPrc}% per annum.</p>*/}
          <p>There {fundCountTxt} that we can replace. The 3 year annualized returns of the portfolio could change from {crStd} to {enStd} and 3 year annualized volatility could change from {crVolPrc}% to {enVolPrc}%.</p>
        </div>
      )
    } else if (query === 'Lower Tracking Error Alternatives') {
      view = (
        <div>
          <p>We analyzed your portfolio for lower tracking error alternatives.</p>
          {/*<p>There {fundCountTxt} that we can replace. The expected returns of the portfolio could change from {crStd} to {enStd} per annum and the expected risk (volatility) will change from {crVolPrc}% to {enVolPrc}% per annum.</p>*/}
          <p>There {fundCountTxt} that we can replace. The 3 year annualized returns of the portfolio could change from {crStd} to {enStd} and 3 year annualized volatility could change from {crVolPrc}% to {enVolPrc}%.</p>
        </div>
      )
    } else if (query) {
      view = (
        <div>
          <p>We analyzed your portfolio for "{query}" alternatives.</p>
          {/*<p>There {fundCountTxt} that we can replace. The expected returns of the portfolio could change from {crStd} to {enStd} per annum and the expected risk (volatility) will change from {crVolPrc}% to {enVolPrc}% per annum.</p>*/}
          <p>There {fundCountTxt} that we can replace. The 3 year annualized returns of the portfolio could change from {crStd} to {enStd} and 3 year annualized volatility could change from {crVolPrc}% to {enVolPrc}%.</p>
        </div>
      )
    }
    else {
      view = '';
    }
  }

  return (
    <React.Fragment>
      {view}
    </React.Fragment>
  )
}

export const getConclusionContent = (query, portPerfPercent, avgReturn, expRisk, avgGrowth, noOfYrs, isModelPortfolioQuery, returnsCalcBasedOn, volatilityCalcBasedOn, annualMeanReturn) => {
  let view = '', updatedQuery = '';
  avgGrowth = Math.round(avgGrowth);
  avgGrowth = FormatCurrency(avgGrowth, 0, '$', 'unf');

  let FIRST_PARA = (
    <p>Replacing your current portfolio with the suggested may enhance your portfolio performance by {portPerfPercent}% of your starting portfolio.</p>
  );
  let SECOND_PARA = (
    <p>The enhanced portfolio has a {returnsCalcBasedOn} annualized return of {avgReturn}%{returnsCalcBasedOn !== '10 Year' ? <sup>*</sup> : ''} with a {volatilityCalcBasedOn} annualized volatility of {expRisk}%. This could translate to a potential growth of {avgGrowth} over {noOfYrs} years. The chart below reflects the impact of portfolio risk on this potential growth and depicts potential outcomes of the {returnsCalcBasedOn} annualized return +/- one standard deviation.</p>
  );

  view = (
    <div>
      {FIRST_PARA}
      {SECOND_PARA}
    </div>
  )

  if (query) {
    if (isModelPortfolioQuery === true) {
      query = query.toLowerCase();
      FIRST_PARA = (
        <p>Replacing your current portfolio with the suggested {query} may enhance your portfolio performance by {portPerfPercent}% of your starting portfolio.</p>
      );
    } else {
      // console.log('query --> ', query);
      // console.log(ENHANCER_PREDEFINED_SEARCH_QUERIES);
      // console.log('ENHANCER_PREDEFINED_SEARCH_QUERIES includes query ? --> ', ENHANCER_PREDEFINED_SEARCH_QUERIES.includes(query));
      if (ENHANCER_PREDEFINED_SEARCH_QUERIES && ENHANCER_PREDEFINED_SEARCH_QUERIES.includes(query)) {
        updatedQuery = query.toLowerCase();
      } else {
        updatedQuery = `"${query}" alternatives`;
      }
      FIRST_PARA = (
        <p>Replacing your current holdings with suggested {updatedQuery} may enhance your portfolio performance by {portPerfPercent}% of your starting portfolio.</p>
      );
    }
    view = (
      <div>
        {FIRST_PARA}
        {SECOND_PARA}
      </div>
    )
  }

  // if(isModelPortfolioQuery == true){
  //   query = query ? query.toLowerCase() : '';
  //   // query = query.slice(0, -1); commited by anish singh
  //   view = (
  //     <div>
  //       <p>Replacing your current {isModelPortfolioQuery == true ? 'portfolio' : 'holdings'} with {isModelPortfolioQuery == true && 'the'} suggested {query} may enhance your portfolio performance by {portPerfPercent}% of your starting portfolio. {/*isModelPortfolioQuery == true ? 'The enhanced portfolio has similar exposure to your existing portfolio.' : 'The enhanced portfolio has diversified exposure to global equities and global bonds.'*/}</p>
  //       <p>The enhanced portfolio has a {returnsCalcBasedOn} annualized return of {avgReturn}% with a {volatilityCalcBasedOn} annualized volatility of {expRisk}%. This could translates to a potential growth of {avgGrowth} over {noOfYrs} years. The chart below reflects the impact of portfolio risk on this potential growth and depicts potential outcomes of the {returnsCalcBasedOn} annualized return +/- one standard deviation.</p>
  //     </div>
  //   )
  // }else if(query == 'Lower Cost Alternatives'){
  //   view = (
  //     <div>
  //       <p>Replacing your current {isModelPortfolioQuery == true ? 'portfolio' : 'holdings'} with {isModelPortfolioQuery == true && 'the'} suggested lower cost alternative {isModelPortfolioQuery == true && 'portfolio'} may enhance your portfolio performance by {portPerfPercent}% of your starting portfolio. {/*isModelPortfolioQuery == true ? 'The enhanced portfolio has similar exposure to your existing portfolio.' : 'The enhanced portfolio has diversified exposure to global equities and global bonds.'*/}</p>
  //       <p>The enhanced portfolio has a {returnsCalcBasedOn} annualized return of {avgReturn}% with a {volatilityCalcBasedOn} annualized volatility of {expRisk}%. This could translates to a potential growth of {avgGrowth} over {noOfYrs} years. The chart below reflects the impact of portfolio risk on this potential growth and depicts potential outcomes of the {returnsCalcBasedOn} annualized return +/- one standard deviation.</p>
  //     </div>
  //   )
  // }else if(query == 'Best Performing Options In Category'){
  //   view = (
  //     <div>
  //       <p>Replacing your current holdings with suggested better performing alternatives may enhance your portfolio performance by {portPerfPercent}% of your starting portfolio.</p>
  //       <p>The enhanced portfolio has a {returnsCalcBasedOn} annualized return of {avgReturn}% with a {volatilityCalcBasedOn} annualized volatility of {expRisk}%. This could translates to a potential growth of {avgGrowth} over {noOfYrs} years. The chart below reflects the impact of portfolio risk on this potential growth and depicts potential outcomes of the {returnsCalcBasedOn} annualized return +/- one standard deviation.</p>
  //     </div>
  //   )
  // }else if(query == 'Lower Risk Alternatives'){
  //   view = (
  //     <div>
  //       <p>Replacing your current holdings with suggested Lower risk alternatives may enhance your portfolio performance by {portPerfPercent}% of your starting portfolio.</p>
  //       <p>The enhanced portfolio has a {returnsCalcBasedOn} annualized return of {avgReturn}% with a {volatilityCalcBasedOn} annualized volatility of {expRisk}%. This could translates to a potential growth of {avgGrowth} over {noOfYrs} years. The chart below reflects the impact of portfolio risk on this potential growth and depicts potential outcomes of the {returnsCalcBasedOn} annualized return +/- one standard deviation.</p>
  //     </div>
  //   )
  // }else if(query == 'Highest Yield Alternatives'){
  //   view = (
  //     <div>
  //       <p>Replacing your current holdings with suggested higher yield alternatives may enhance your portfolio performance by {portPerfPercent}% of your starting portfolio.</p>
  //       <p>The enhanced portfolio has a {returnsCalcBasedOn} annualized return of {avgReturn}% with a {volatilityCalcBasedOn} annualized volatility of {expRisk}%. This could translates to a potential growth of {avgGrowth} over {noOfYrs} years. The chart below reflects the impact of portfolio risk on this potential growth and depicts potential outcomes of the {returnsCalcBasedOn} annualized return +/- one standard deviation.</p>
  //     </div>
  //   )
  // }else if(query == 'Long Track Record Alternatives'){
  //   view = (
  //     <div>
  //       <p>Replacing your current holdings with suggested higher track record alternatives may enhance your portfolio performance by {portPerfPercent}% of your starting portfolio.</p>
  //       <p>The enhanced portfolio has a {returnsCalcBasedOn} annualized return of {avgReturn}% with a {volatilityCalcBasedOn} annualized volatility of {expRisk}%. This could translates to a potential growth of {avgGrowth} over {noOfYrs} years. The chart below reflects the impact of portfolio risk on this potential growth and depicts potential outcomes of the {returnsCalcBasedOn} annualized return +/- one standard deviation.</p>
  //     </div>
  //   )
  // }else if(query == 'Best Alternatives Using Passive Fund Selector'){
  //   view = (
  //     <div>
  //       <p>Replacing your current holdings with suggested alternatives may enhance your portfolio performance by {portPerfPercent}% of your starting portfolio.</p>
  //       <p>The enhanced portfolio has a {returnsCalcBasedOn} annualized return of {avgReturn}% with a {volatilityCalcBasedOn} annualized volatility of {expRisk}%. This could translates to a potential growth of {avgGrowth} over {noOfYrs} years. The chart below reflects the impact of portfolio risk on this potential growth and depicts potential outcomes of the {returnsCalcBasedOn} annualized return +/- one standard deviation.</p>
  //     </div>
  //   )
  // }else if(query == 'Highest Risk Adjusted Return Alternatives'){
  //   view = (
  //     <div>
  //       <p>Replacing your current holdings with suggested higher risk adjusted return alternatives may enhance your portfolio performance by {portPerfPercent}% of your starting portfolio.</p>
  //       <p>The enhanced portfolio has a {returnsCalcBasedOn} annualized return of {avgReturn}% with a {volatilityCalcBasedOn} annualized volatility of {expRisk}%. This could translates to a potential growth of {avgGrowth} over {noOfYrs} years. The chart below reflects the impact of portfolio risk on this potential growth and depicts potential outcomes of the {returnsCalcBasedOn} annualized return +/- one standard deviation.</p>
  //     </div>
  //   )
  // }else if(query){
  //   let updatedQuery;
  //   if(query === 'Lower Drawdown Alternatives'){
  //     updatedQuery = 'lower drawdown alternatives';
  //   } else if(query === 'Lower Cost And Risk Alternatives'){
  //     updatedQuery = 'lower cost and risk alternatives';
  //   } else if(query === 'Lower Tracking Error Alternatives'){
  //     updatedQuery = 'lower tracking error alternatives';
  //   }else{
  //     updatedQuery = `"${query}" alternatives`;
  //   }
  //
  //   view = (
  //     <div>
  //       <p>Replacing your current holdings with suggested {updatedQuery} may enhance your portfolio performance by {portPerfPercent}% of your starting portfolio.</p>
  //       <p>The enhanced portfolio has a {returnsCalcBasedOn} annualized return of {avgReturn}% with a {volatilityCalcBasedOn} annualized volatility of {expRisk}%. This could translates to a potential growth of {avgGrowth} over {noOfYrs} years. The chart below reflects the impact of portfolio risk on this potential growth and depicts potential outcomes of the {returnsCalcBasedOn} annualized return +/- one standard deviation.</p>
  //     </div>
  //   )
  // }else{
  //   view = '';
  // }

  return (
    <React.Fragment>
      {view}
    </React.Fragment>
  )
}

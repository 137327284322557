import React from 'react';

import { addEventToAnalytics } from 'Utils';

import { BarChartPL } from 'components/charts/BarCharts'

import { SelectorDD, KeyStats3, KeyStats4, NoData } from './Common'

export const PortfolioValue = (props) => {
  const stats = [
    { name: 'Portfolio Value', value: props.stats.portfolioValue },
    { name: 'Securities Balance', value: props.stats.fundBalance },
    { name: 'Cash Balance', value: props.stats.cashBalance },
  ]
  return (
    <KeyStats3 stats={stats} />
  )
}

export const PortfolioGains = (props) => {
  const stats = [
    { name: "Gains For Today", value: props.stats.gainToday },
    { name: 'Gains For The Month', value: props.stats.gainMonthly },
    { name: 'Gains For The Year', value: props.stats.gainYearly },
  ]
  return (
    <KeyStats4 {...props} stats={stats} prec={2} />
  )
}

export class ProfitLoss extends React.PureComponent {
  opts = ['Regions', 'Sectors']
  state = { opt: this.opts[0] }

  optHandler = opt => {
    // if(opt == 'Sectors'){
    //   addEventToAnalytics('Enhancer PL Sector','Enhancer PL Sector','ENHANCER_PL_SECTOR',{},false);
    // }
    // else{
    //   addEventToAnalytics('Enhancer PL Region','Enhancer PL Region','ENHANCER_PL_REGION',{},false);
    // }
    switch (opt) {
      case 'Regions':
        addEventToAnalytics(
          'Enhancer PL Region',
          'Enhancer PL Region',
          'ENHANCER_PL_REGION',
          {},
          false
        );
        break;
      case 'Sectors':
        addEventToAnalytics(
          'Enhancer PL Sector',
          'Enhancer PL Sector',
          'ENHANCER_PL_SECTOR',
          {},
          false
        );
        break;
      default:
    }
    this.setState({ opt });
  }

  formatter = val => `$${val.toFixed(1)}`;

  chartData = () => {
    // return this.state.opt === this.opts[0]
    //        ? this.props.stats.priceSectors.length > 0 ? this.props.stats.priceSectors : []
    //        : this.props.stats.priceRegions.length > 0 ? this.props.stats.priceRegions : [];
    switch (this.state.opt) {
      case 'Regions':
        return this.props.stats.priceRegions.length > 0 ? this.props.stats.priceRegions : [];
      case 'Sectors':
        return this.props.stats.priceSectors.length > 0 ? this.props.stats.priceSectors : [];
      default:
    }
  }

  render() {
    const data = this.chartData();
    return (
      <React.Fragment>
        <SelectorDD opts={this.opts} optHandler={this.optHandler} />
        <h5 className="mb-1 text-heading font-one">Profit / Loss</h5>
        {(data.length <= 0)? (
            <NoData height='225px' />
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center" style={{minHeight: '225px'}}>
            <div style={{ width: "90%", height: "180px" }}>
              <BarChartPL data={data} formatter={this.formatter} />
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}

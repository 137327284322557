import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { Tooltip,Row, Col, Button, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import CountUp from 'react-countup';
import { addEventToAnalytics } from 'Utils';
import VideoPlayer from 'components/VideoPlayer';
import style from './OnBoardScreen.module.scss'
import { setBuildPortfolioState, fetchPortfolioRequest, fetchPortfolioSuccess, resetBuildPortfolioState,deletePortfolioBuilderByIdRequest } from 'actions/buildportfolio';
// import { loadCPMListRequest } from 'actions/enhancer';
// import { investmentFetch, investmentFetchSuccess, getClientListRequest } from 'actions/investment';
import { createLoadingSelector } from 'selectors/loading-selector';
import Tabs from './Tabs';
import { sponsoredMenus, portfolioMenus, CustomSponsoredDropDown, PortfolioDDSearch, ClientDDSearch } from './Helper'
import { Loader } from '../../Elements/Loader';

import PortfolioUpload from '../../../ClientPortfolio/Elements/PortfolioUpload/index';
import PfListPreview from '../../../ClientPortfolio/Elements/PfListPreview/index';
import UploadSection from './UploadSection'
import { Modal, ModalBody } from 'reactstrap'
import CustomAlert from 'components/CustomAlert'
import { InnerPageFooter } from 'shared-components/'

class OnBoardScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portfolioType: '',
      IMG_OBJECT: {
        'Selected Funds': '/assets/images/UsingSelectedFund-White.svg',
        'Sponsor Models': '/assets/images/SponsorProvidedModel-White.svg',
        'My Models': '/assets/images/MyPortfolios-White.svg',
        'Client Accounts': '/assets/images/client-icon.svg',
        'Scratch': '/assets/images/FromScratch-White.svg',
        'Demo Portfolio': '/assets/images/DemoPortfolio-White.svg',
      },
      videoData: {
        title: 'Magnifi',
        link: 'https://magnifi-videos.s3.us-east-2.amazonaws.com/Builder_01.mp4',
      },
      showClientUploadModal: false,
      uploadDemo: false,
      builderLoading: false,
      myPortfolioDDVisible:false,
      clientDDVisible:false,
      myPfDDVisible:false,
      deleteConfirm: false,
      deletePortfolioName: '',
    };
  }

  componentDidMount(){
    // this.props.investmentFetch();
    // this.props.getClientListRequest();
    // const { loadCPMListRequest } = this.props;
    // if (loadCPMListRequest) {
    //   loadCPMListRequest();
    // }

    const mainContent = document.getElementById('build-portfolio-content-wrapper');
    if (mainContent) {
      document.body.classList.add('body-bg-white');
      mainContent.style.background = '#fff';
      mainContent.style.display = 'flex';
      // mainContent.style.alignItems = 'center';
      mainContent.style.marginTop = '8.5%';
    }
    if(this.props.buildportfolio.portfolioName){
      this.selectedPortfolio({
        portfolio_name: this.props.buildportfolio.portfolioName,
        id: this.props.buildportfolio.savedPortfolio.portfolio_id,
        status: 'Success',
        redirectFromEnhancer: true
      });
    }
  }

  componentWillUnmount() {
    const mainContent = document.getElementById('build-portfolio-content-wrapper');
    if (mainContent) {
      document.body.classList.remove('body-bg-white');
      mainContent.style.background = 'transparent';
      mainContent.style.display = 'unset';
      mainContent.style.alignItems = 'unset';
    }
    const { resetBuildPortfolioState } = this.props;
    if (resetBuildPortfolioState) {
      // resetBuildPortfolioState();
    }
  }

  selectedPortfolio = portfolioData => {
    const { location, history, setBuildPortfolioState, buildportfolio } = this.props;
    setBuildPortfolioState({
      portfolioName: portfolioData.portfolio_name,
      savedPortfolio:{
        portfolio_name: portfolioData.portfolio_name,
        portfolio_id: portfolioData.id,
        status: portfolioData.status,
        redirectFromEnhancer: portfolioData.redirectFromEnhancer,
      }
    });
    addEventToAnalytics('Builder US','Builder US','BUILDER_US',{},false);
    this.routeToAssetAllocationFrom('portfolio', 'scratch', {type: 'portfolio', data: portfolioData.id});
  }

  setMarketPlace = sponserData => {
    const { setBuildPortfolioState, portfolioList } = this.props;
    let obj = {portfolio_name: sponserData.name};
    if (portfolioList && typeof portfolioList.length !== 'undefined') {
      portfolioList.forEach(portfolio => {
        if(portfolio.portfolio_name === sponserData.name){
          obj.portfolio_id = portfolio.id
        }
      })
      setBuildPortfolioState({
        portfolioName: sponserData.name,
        savedPortfolio: obj
      })
      addEventToAnalytics('Builder MP','Builder MP','BUILDER_MP',{ mp: sponserData.ticker },false);
      this.routeToAssetAllocationFrom('portfolio', 'scratch', {type: 'MP', data: sponserData.ticker});
    }else{
      setBuildPortfolioState({
        portfolioName: sponserData.name,
        savedPortfolio: obj,
      });
      addEventToAnalytics('Builder MP','Builder MP','BUILDER_MP',{ mp: sponserData.ticker },false);
      this.routeToAssetAllocationFrom('portfolio', 'scratch', {type: 'MP', data: sponserData.ticker});
    }
  }

  demoPortfolio = () => {
    this.routeToAssetAllocationFrom('Demo Model', 'scratch', {type: 'demo_portfolio2', data: ''});
  }

  openChange = () => {
    const element = document.querySelector('.market-popup-wrapper');
    const dropdownEl = document.querySelectorAll('.ant-dropdown-menu-submenu-title');
    if (element != null) {
      element.style.height = element.clientHeight > 0 && `${element.clientHeight}px`
    }
    if(dropdownEl){
      dropdownEl.forEach((el, i) => {
        dropdownEl[i].title = '';
      })
      // console.log('here el', dropdownEl);
    }
  }

  routeToAssetAllocationFrom = (selectedPortfolioFor, path, portfolioData) => {
    const { location, history, setBuildPortfolioState, buildportfolio } = this.props;
    const { onboardData, tabHeaderMenu, summary } = buildportfolio;
    if (setBuildPortfolioState && selectedPortfolioFor && path) {
      // setBuildPortfolioState({
      //   onboardData: {
      //     selectedPortfolioFor,
      //     onboardFrom: `/${path}`,
      //     type: portfolioData ? portfolioData.type : null,
      //     data: portfolioData ? portfolioData.data : null
      //   }
      // })
      let obj = {data:{type: portfolioData ? portfolioData.type : null}, selectedPortfolioFor, path: `/${path}`};
      if(selectedPortfolioFor === 'From Scratch'){
        addEventToAnalytics('Builder Scratch','Builder Scratch','BUILDER_SCRATCH',{},false);
        setBuildPortfolioState({onboardData: {selectedPortfolioFor, onboardFrom: `/${path}`}});
      } else if(portfolioData.type === 'tickers'){
        let tickerObj = {data:{tickers: portfolioData.data}, selectedPortfolioFor, path: `/${path}`};
        this.props.fetchPortfolioRequest(tickerObj);
      } else if(portfolioData.type === 'demo_portfolio2'){
        this.props.fetchPortfolioRequest(obj);
      } else{
        obj.data.name = portfolioData.data;
        this.props.fetchPortfolioRequest(obj);
      }
    }
  }

  redirect = () => {
    const { history, setBuildPortfolioState, buildportfolio } = this.props;
    const { onboardData, tabHeaderMenu, summary } = buildportfolio;
    if (onboardData && onboardData.onboardFrom !== '' && history) {
      const urlObj = {
        onboardData,
        tabHeaderMenu,
      };
      // console.log(urlObj);
      setBuildPortfolioState({
        summary: {
          ...summary,
          timeline: 'Select Funds'
        },
        // onboardData: {onboardFrom: ''}
      })
      const newPath = `/build-portfolio${onboardData.onboardFrom}`;
      history.push(newPath);
    }
  }

  useSelectedFunds = () => {
    const { buildportfolio, fetchPortfolioSuccess, fetchPortfolioRequest, history } = this.props;
    addEventToAnalytics('BUILDER_CART','BUILDER_CART','BUILDER_CART',{},false);
    let tickersArray = [];
    let obj = {tickers: ''};
    buildportfolio.resultPageCart.forEach(item => tickersArray.push(item.ticker));
    obj.tickers = tickersArray.join(',');
    this.routeToAssetAllocationFrom('Use selected funds', 'scratch', {type: 'tickers', data: obj.tickers});
  }

  clientSelectedPortfolio = portfolioData => {
    addEventToAnalytics('Builder Client Portfolio','Builder Client Portfolio','BUILDER_CLIENT_PORTFOLIO',{},false);
    const { location, history, setBuildPortfolioState, buildportfolio } = this.props;
    setBuildPortfolioState({
      portfolioName: portfolioData.original_portfolio,
      savedPortfolio:{portfolio_name: portfolioData.original_portfolio, portfolio_id: portfolioData.original_portfolio_id}
    });
    this.routeToAssetAllocationFrom('portfolio', 'scratch', { type: 'portfolio', data: portfolioData.original_portfolio_id });
  }

  clientUploadModalToggle = (val, uploadDemo) => {
    this.setState({
      showClientUploadModal: val,
      uploadDemo: uploadDemo && uploadDemo
    })
  }

  clientDDHandle = (flag)=>{
      this.setState({ clientDDVisible: flag });
  }

  handleClientDDMenuClick = (item)=>{
    this.setState({ clientDDVisible: false });
    if(item.name==='Demo Account'){
      this.clientUploadModalToggle(true, true)
    }else{
      this.clientSelectedPortfolio(item)
    }
  }

  myPfDDHandle = (flag)=>{
    console.log(flag)
    this.setState({ myPfDDVisible: flag });
  }
  deletePortfolio = (portfolioName) => {
    this.setState({ myPfDDVisible: false });
    this.setState({ deletePortfolioName : portfolioName, deleteConfirm: true })
  }

  confirmDeletePortfolio = () => {
    addEventToAnalytics('Builder Delete','Builder Delete','BUILDER_DELETE',{},false);
    const { deletePortfolioBuilderByIdRequest } = this.props;
    if (deletePortfolioBuilderByIdRequest && this.state.deletePortfolioName && this.state.deletePortfolioName !== '') {
      deletePortfolioBuilderByIdRequest(this.state.deletePortfolioName, () => this.setState({ deleteConfirm: false }));
    } else {
      console.log('-- PORTFOLIO ID MISSING --');
    }
  }

  handleMyPfMenuClick = (item)=>{
    this.setState({ myPfDDVisible: false });
    // if(item.name==='Demo Account'){
    //   this.clientUploadModalToggle(true, true)
    // }else{
    //   this.clientSelectedPortfolio(item)
    // }
  }

  goToSelectedPortfolio = (selectedClient) => {
    this.setState({
      builderLoading: false
    }, () => this.clientSelectedPortfolio(selectedClient));
  }

  showLoader = () => {
    this.setState({
      builderLoading: true
    })
  }

  videoSetState = (_state) => {
    // console.log(_state);
  }

  onAlertDismiss = () => {
    this.props.setBuildPortfolioState({
      tickerFetchError:false
    });
  }

  // shouldComponentUpdate(nextProps, nextState){
  //   return nextProps.loading !== this.props.loading;
  // }

  render() {
    const {
      premiumUser,
      loading,
      number,
      className,
      setContent,
      valid,
      portfolioList,
      marketPlaceList,
      setWatchlist,
      setActiveAccount,
      setMarketPlace,
      setDemoPortfolio,
      buildportfolio,
      builderCount,
      sfmItems,
      resultPageCart,
      clientList,
      clientListLoading,
      client_exist,
      uploadedClientList,
      showUpdateColumnInPreview,
      profileDetails,
      homeOfficeModelsList,
      demoPortfolioLoader,
    } = this.props;

    const { IMG_OBJECT, videoData, showClientUploadModal, uploadDemo, builderLoading ,deleteConfirm,deletePortfolioName} = this.state;

    // const USER_INFO = (typeof profileDetails !== 'undefined' && typeof profileDetails.self_role !== 'undefined' && typeof GET_USER_TYPE[profileDetails.self_role] !== 'undefined') ? GET_USER_TYPE[profileDetails.self_role] : GET_USER_TYPE['Default'];
    // const USER_INFO = (typeof profileDetails !== 'undefined' && typeof profileDetails.is_staff !== 'undefined' && profileDetails.is_staff !== false) ? GET_USER_TYPE['Sales Team'] : GET_USER_TYPE['Default'];

    // const count = sfmItems.filter((v) => v._builder === true).length;
    const count = resultPageCart.length;

    const SPONSORED_MENUS = sponsoredMenus({
      props: {...this.props},
      openChange: this.openChange,
      setMarketPlace: this.setMarketPlace,
    });

    const MY_PORTFOLIO_MENUS = portfolioMenus({
      props: {...this.props},
      selectedPortfolio: this.selectedPortfolio,
      demoPortfolio: this.demoPortfolio,
    });

    const myPortfolioCount = portfolioList && portfolioList.length ? portfolioList.length : 0;
    let sponsorCount = 0;
    if(Object.keys(marketPlaceList).length){
      Object.keys(marketPlaceList).forEach((el, index) => {
        Object.keys(marketPlaceList[el]).forEach((e, i) => {
          sponsorCount += marketPlaceList[el][e].length;
        })
      })
    }

    // onMouseEnter={() => (!props.disabled ? this.getImgForHoverEffect('Selected Funds', true) : null)}
    // onMouseLeave={() => (!props.disabled ? this.getImgForHoverEffect('Selected Funds', false) : null)}
    // console.log(typeof portfolioList.length, portfolioList.length);
    const myPortfolioLength = typeof portfolioList.length !== 'undefined' ? portfolioList.length : 0;

    const clientListMenuItems = clientList && clientList.length && clientList.map((item, index) => {
      return (
        <Menu.Item key={item.name+item.id}>
          <div className="portfolio_name-label" onClick={() => { this.clientSelectedPortfolio(item) }}>
            {item.name}
          </div>
        </Menu.Item>
      )
    });

    const CLIENT_LIST_MENUS = (
      <Menu>
        <Menu.Item key="use_demo">
          <div className="active-label" onClick={() => this.clientUploadModalToggle(true, true)}>
            Use Demo Account
          </div>
        </Menu.Item>
        <Menu.Item key="upload_portfolio">
          <div className="active-label" onClick={() => this.clientUploadModalToggle(true)}>
            Add Account
          </div>
        </Menu.Item>
        { clientList && clientList.length > 0 && <Menu.Divider /> }
        {clientListMenuItems}
      </Menu>
    );

    // const Collections = [
    //   {
    //     label: 'Build Portfolio',
    //     subTxt: 'Start with -',
    //     class: 'buttons-block',
    //     labelClassName: '',
    //     optionsWrapperClassName: 'buttons-items-wrapper',
    //     style: { marginBottom: 0 },
    //     options: [
    //       // {
    //       //   key: 'Selected Funds',
    //       //   disabled: (buildportfolio.resultPageCart.length === 0),
    //       //   Element: ({ children, ...props }) => (
    //       //     <div
    //       //       {...props}
    //       //       className={`buttons-item ${props.disabled ? 'disabled' : ''}`}
    //       //      >
    //       //       {children}
    //       //     </div>
    //       //   ),
    //       //   head: () => (<div className="buttons-item-label">{count} Selected {count === 1 ? 'Fund' : 'Funds'}</div>),
    //       //   img: () => (<img src={IMG_OBJECT['Selected Funds']} height="49" />),
    //       //   subHead: () => null,
    //       //   onClick: () => buildportfolio.resultPageCart.length > 0 ? this.useSelectedFunds() : null,
    //       // },
    //       // {
    //       //   key: 'Sponsor Models',
    //       //   disabled: false,
    //       //   Element: ({ children, ...props }) => (
    //       //     <Dropdown
    //       //       {...props}
    //       //       onVisibleChange={e => {
    //       //         const dropdownEl = document.querySelectorAll('.ant-dropdown-menu-submenu-title');
    //       //         if(dropdownEl){
    //       //           dropdownEl.forEach((el, i) => {
    //       //             dropdownEl[i].title = '';
    //       //           })
    //       //         }
    //       //       }}
    //       //       className="buttons-item"
    //       //       overlay={SPONSORED_MENUS}
    //       //      >
    //       //       <div className="d-flex align-items-center">{children}</div>
    //       //     </Dropdown>
    //       //   ),
    //       //   head: () => (<div className="buttons-item-label">Sponsor Models <DownOutlined /></div>),
    //       //   img: () => (<img src={IMG_OBJECT['Sponsor Models']} height="49" />),
    //       //   subHead: () => null,
    //       //   onClick: () => null,
    //       // },
    //       // {
    //       //   key: 'My Models',
    //       //   disabled: false,
    //       //   Element: ({ children, ...props }) => (
    //       //     <Dropdown
    //       //       {...props}
    //       //       className="buttons-item"
    //       //       overlayClassName="market-place-dropdown"
    //       //       overlay={MY_PORTFOLIO_MENUS}
    //       //      >
    //       //       <div className="d-flex align-items-center">
    //       //         {children}
    //       //       </div>
    //       //     </Dropdown>
    //       //   ),
    //       //   head: () => (<div className="buttons-item-label">My Models <DownOutlined /></div>),
    //       //   img: () => (<img src={IMG_OBJECT['My Models']} height="49" />),
    //       //   subHead: () => null,
    //       //   onClick: () => null,
    //       // },
    //       // {
    //       //   key: 'Client Accounts',
    //       //   disabled: false,
    //       //   Element: ({ children, ...props }) => (
    //       //     <Dropdown
    //       //       {...props}
    //       //       className={`buttons-item ${clientList && clientList.length === 0 ? 'disabled' : ''}`}
    //       //       overlayClassName="market-place-dropdown"
    //       //       overlay={CLIENT_LIST_MENUS}
    //       //      >
    //       //       <div className="d-flex align-items-center">
    //       //         {children}
    //       //       </div>
    //       //     </Dropdown>
    //       //   ),
    //       //   head: () => (<div className="buttons-item-label">Client Accounts <DownOutlined /></div>),
    //       //   img: () => (<img src={IMG_OBJECT['Client Accounts']} height="49" style={{ width: 32 }} />),
    //       //   subHead: () => null,
    //       //   onClick: () => null,
    //       // },
    //       // {
    //       //   key: 'From Scratch',
    //       //   disabled: false,
    //       //   Element: ({ children, ...props }) => (
    //       //     <div
    //       //       {...props}
    //       //       className="buttons-item"
    //       //       style={{ marginBottom: 0 }}
    //       //      >
    //       //       {children}
    //       //     </div>
    //       //   ),
    //       //   head: () => (<div className="buttons-item-label">From Scratch</div>),
    //       //   img: () => (<img src={IMG_OBJECT['Scratch']} height="49" />),
    //       //   subHead: () => null,
    //       //   onClick: () => this.routeToAssetAllocationFrom('From Scratch', 'scratch'),
    //       // },
    //     ]
    //   },
    //   {
    //     label: 'Overview',
    //     subTxt: 'A snapshot of your current portfolios',
    //     class: '',
    //     labelClassName: '',
    //     optionsWrapperClassName: 'overview-items-wrapper',
    //     style: {},
    //     options: [
    //       {
    //         key: 'My Models',
    //         disabled: false,
    //         Element: ({ children, ...props }) => (
    //           <div className="overview-item" style={{ padding: '4px 2px', marginLeft: '-5px' }}>
    //             {children}
    //           </div>
    //         ),
    //         head: () => (<div className="overview-item-label col-12">My Models</div>),
    //         img: () => (<img src="/assets/images/MyPortfolio-Blue.svg" width="80" />),
    //         subHead: () => (<div id='portfolio' className="overview-item-count col-12"><CountUp end={myPortfolioLength} /></div>),
    //         onClick: () => null,
    //       },
    //       {
    //         key: 'Sponsor Models',
    //         disabled: false,
    //         Element: ({ children, ...props }) => (
    //           <div className="overview-item" style={{ padding: '4px 15px' }}>
    //             {children}
    //           </div>
    //         ),
    //         head: () => (<div className="overview-item-label col-12">Sponsor Models</div>),
    //         img: () => (<img src="/assets/images/SponsorPortfolio-Blue.svg" width="88" />),
    //         subHead: () => (<div className="overview-item-count col-12"><CountUp end={sponsorCount} /></div>),
    //         onClick: () => null,
    //       }
    //     ]
    //   },
    // ];
    //
    // const renderDesign = Collections.map((section) => {
    //   const insideElement = section.options.map((i) => {
    //         const El = i.Element;
    //     return (
    //         <El
    //         disabled={i.disabled}
    //         onClick={() => i.onClick()}
    //        >
    //         {i.img()}
    //         <div className='row' style={ {marginLeft: '10px', width: '100%', marginRight: 0 }}>
    //           {i.head()}
    //           {i.subHead && i.subHead()}
    //         </div>
    //         </El>
    //     );
    //   });
    //   return (
    //     <div className={`content-body ${section.label === 'Build Portfolio' ? 'col-xl-5 col-lg-5' : 'col-6'} ${section.class}`} style={section.style}>
    //       <div className={`content-title ${section.labelClassName}`}>{section.label}</div>
    //       <div className={`content-title sub-txt ${section.labelClassName}`}>{section.subTxt}</div>
    //       <div className={`${section.optionsWrapperClassName}`}>
    //         {insideElement}
    //       </div>
    //     </div>
    //   );
    // });

    this.redirect();
    const dropdownEl = document.querySelectorAll('.ant-dropdown-menu-submenu-title');
    if(dropdownEl && dropdownEl.length > 10){
      // console.log('here el')
    }

    // <iframe src="https://player.vimeo.com/video/428290149?autoplay=1&muted=1" allow="autoplay" height="200" width="300"></iframe>

    return (
      <div style={{ width: "100vw", display: "flex", justifyContent: "center" }}>
        <div className={loading ? "build-portfolio-onboard-screen-wrapper build-portfolio-onboard-screen-wrapper-loader-screen-overlay row" : "build-portfolio-onboard-screen-wrapper row"}>
          {demoPortfolioLoader && <Loader loading />}
          {(loading || builderLoading) && <Loader loading />}
          {!loading && <>
                {showClientUploadModal &&
                  <PortfolioUpload
                    showClientUploadModal={showClientUploadModal}
                    clientUploadModalToggle={this.clientUploadModalToggle}
                    client_exist={client_exist}
                    clientList={clientList}
                    uploadDemo={uploadDemo}
                  />
                }
                {uploadedClientList.length > 0 &&
                  <PfListPreview
                    clientList={clientList}
                    pfList={uploadedClientList}
                    showUpdateColumnInPreview={showUpdateColumnInPreview}
                    allowSelection
                    callback={this.goToSelectedPortfolio}
                    clientUploadModalToggle={this.clientUploadModalToggle}
                    showLoader={this.showLoader}
                  />
                }

                <div className={style.container} style={{ width: "68%" }}>
                  <div className={style.left}>
                    <div className={style.headBlock}>
                      <h1 >Builder</h1>
                      <p>Start with selected funds or sponsor models to build your own models. Generate tradelists, custom proposals and set alerts.</p>
                    </div>
                    <div className={style.selectionContainer}>
                      <div className={style.leftSelection}>
                        <h3 className={style.subHeading}>Build Portfolio</h3>
                        <p  className={style.subText}>Start with -</p>
                        <div className={`${style.ddButtons} ${buildportfolio.resultPageCart.length === 0 ? style.ddButtonsDisabled : ''}`} onClick={() => buildportfolio.resultPageCart.length > 0 ? this.useSelectedFunds() : null}>
                          <img src={IMG_OBJECT['Selected Funds']} height="28" />
                          <div className='row d-flex align-items-center justify-content-between' style={{ marginLeft: '10px', width: '100%', marginRight: '0px' }}>
                            <div > {count} Selected {count === 1 ? 'Fund' : 'Funds'} </div>
                          </div>
                        </div>
                        <Dropdown
                          onVisibleChange={e => {
                            console.log(e);
                            const dropdownEl = document.querySelectorAll('.ant-dropdown-menu-submenu-title');
                            if(dropdownEl){
                              dropdownEl.forEach((el, i) => {
                                dropdownEl[i].title = '';
                              })
                            }
                          }}
                          trigger={'click'}
                          className={style.ddButtons}
                          overlay={<CustomSponsoredDropDown
                            openChange={this.openChange}
                            setMarketPlace={this.setMarketPlace}
                            marketPlaceList={this.props.marketPlaceList}
                          />}
                          overlayClassName={style.portfolioDDContainer}
                        >
                          <div id={'sponsorSearch'} className="d-flex align-items-center justify-content-between">
                            <img src={IMG_OBJECT['Sponsor Models']} height="28"/>
                            <div className='row d-flex align-items-center justify-content-between' style={{ marginLeft: '10px', width: '100%', marginRight: '0px' }}>
                              <div >Sponsor Models </div>
                              <DownOutlined/>
                            </div>
                          </div>
                        </Dropdown>
                        <Dropdown
                          trigger={['click']}
                          onVisibleChange={this.myPfDDHandle}
                          visible={this.state.myPfDDVisible}
                          className={style.ddButtons}
                          overlayClassName={style.portfolioDDContainer}
                          overlay={<PortfolioDDSearch
                            deletePortfolio={this.deletePortfolio}
                            handleDDMenuClick={this.myPfDDHandle}
                            demoPortfolio={this.demoPortfolio}
                            portfolioList={this.props.portfolioList}
                            selectedPortfolio={this.selectedPortfolio}
                          />}
                        >
                          <div id={'pfSearch'} className="d-flex align-items-center justify-content-between">
                            <img src={IMG_OBJECT['My Models']} height="28"/>
                            <div className='row d-flex align-items-center justify-content-between' style={{ marginLeft: '10px', width: '100%', marginRight: '0px' }}>
                              <div >My Models </div>
                              <div  className='d-flex align-items-center justify-content-between' style={{width:`50px`}}>
                                <div onClick={e=>e.stopPropagation()}>
                                  <UploadSection className={style.plusIcon} fileFor="Custom_portfolio" onFileSuccess={(item) => this.selectedPortfolio(item)}>
                                    <div onClick={(e)=>{
                                      this.myPfDDHandle(false)}}>
                                      <Tooltip title="Upload Portfolio">
                                        <i className={`far fa-fw fa-plus`}/>
                                      </Tooltip>
                                    </div>
                                  </UploadSection>
                                </div>

                                <DownOutlined/>
                              </div>
                            </div>
                          </div>
                        </Dropdown>
                        <Dropdown
                          trigger={'click'}
                          onVisibleChange={this.clientDDHandle}
                          visible={this.state.clientDDVisible}
                          className={style.ddButtons}
                          overlayClassName={style.portfolioDDContainer}
                          overlay={<ClientDDSearch
                            handleDDMenuClick={this.handleClientDDMenuClick}
                            clientList={clientList}
                            clientUploadModalToggle={this.clientUploadModalToggle}
                            clientSelectedPortfolio={this.clientSelectedPortfolio}
                          />}
                        >
                          <div id={'clientSearch'} className="d-flex align-items-center justify-content-between">
                            <img width={22} src={IMG_OBJECT['Client Accounts']} height="28"/>
                            <div className='row d-flex align-items-center justify-content-between' style={{ marginLeft: '10px', width: '100%', marginRight: '0px' }}>
                              <div >Client Accounts </div>
                              <div  className='d-flex align-items-center justify-content-between' style={{width:`50px`}}>
                                <div  onClick={(e) => {
                                  e.stopPropagation();
                                  this.setState({ clientDDVisible: false });
                                  this.clientUploadModalToggle(true)}} className={style.plusIcon}>
                                  <Tooltip title="Add Account ">
                                    <i className={`far fa-fw fa-plus`}/>
                                  </Tooltip>
                                </div>
                                <DownOutlined/>
                              </div>
                            </div>
                          </div>
                        </Dropdown>
                        <div className={style.ddButtons} onClick={() => this.routeToAssetAllocationFrom('From Scratch', 'scratch')}>
                          <img src={IMG_OBJECT['Scratch']} height="28" />
                          <div className='row d-flex align-items-center justify-content-between' style={{ marginLeft: '10px', width: '100%', marginRight: '0px' }}>
                            <div >From Scratch </div>
                          </div>
                        </div>
                      </div>
                      {/*<div className={style.rightSelection}>*/}
                      {/*  <p>Select from </p>*/}
                      {/*  */}
                      {/*  <div className={style.ddButtons} onClick={() => buildportfolio.resultPageCart.length > 0 ? this.useSelectedFunds() : null}>*/}
                      {/*    <img src={IMG_OBJECT['Selected Funds']} height="28" />*/}
                      {/*    <div className='row d-flex align-items-center justify-content-between' style={{ marginLeft: '10px', width: '100%', marginRight: '0px' }}>*/}
                      {/*      <div > Selected {count === 1 ? 'Fund' : 'Funds'} </div>*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                    </div>
                  </div>
                  <div className={style.right}>
                    <div className={style.videoBlock}>
                      <VideoPlayer
                        key={videoData.link}
                        link={videoData.link}
                        title={videoData.title}
                        onPlayPauseState={this.videoSetState}
                        onPauseState={this.videoSetState}
                        onEndedState={this.videoSetState}
                      />
                    </div>
                    <div className={style.overView}>
                      <h3 className={style.subHeading}>Overview</h3>
                      <p className={style.subText}>A snapshot of your current portfolios</p>
                      <div className={style.itemContainer} >
                        <div className={style.item} style={{marginRight:`12px`}}>
                          <img src="/assets/images/MyPortfolio-Blue.svg" width="80" />
                          <div>
                            <span className={style.itemLable} >My Models</span>
                            <span className={style.itemCount} id='portfolio' ><CountUp end={myPortfolioLength} /></span>
                          </div>
                        </div>
                        <div  className={style.item}>
                          <img src="/assets/images/SponsorPortfolio-Blue.svg" width="88" />
                          <div>
                            <span className={style.itemLable} >Sponsor Models</span>
                            <span  className={style.itemCount} ><CountUp end={sponsorCount} /></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>}
          {(buildportfolio.tickerFetchError)
          && (
            <CustomAlert className="alert-windown-wrapper" color={'danger'} onDismissCallback={this.onAlertDismiss}>
              {buildportfolio.tickerFetchError}
            </CustomAlert>
          )}
          <Modal
            isOpen={deleteConfirm}
            toggle={() => this.setState({ deleteConfirm: !deleteConfirm })}
            centered
            keyboard={true}
            backdrop
            className="delete-confirm-modal premium-msg-modal account-modal"
            backdropClassName="premium-feature-process-modal-backdrop top-60px"
            modalClassName="top-60px"
          >
            <ModalBody className="ssf-modal__body">
              <div className="premium-feature-process-modal-container">
                <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => this.setState({ deleteConfirm: false })}></i>
                <p className="content-wrapper">
                  <div className="col-12 question">Are you sure you want to delete {deletePortfolioName} portfolio?</div>
                  <div className="col-12 cta-wrapper margin-left-51">
                    <button type="button" className="ssf-btn-primary btn secondary-btn btn btn-secondary" onClick={() => this.confirmDeletePortfolio()}><span>YES</span></button>
                    <button type="button" className="ssf-btn-primary btn secondary-btn btn btn-secondary" onClick={() => this.setState({ deleteConfirm: false })}><span>NO</span></button>
                  </div>
                </p>
              </div>
            </ModalBody>
          </Modal>
          {/* {!loading && renderDesign} */}
          {/*{!loading && Collections.map((section) => {*/}
          {/*  return (*/}
          {/*    <div className={`content-body ${section.label === 'Build Portfolio' ? 'col-xl-5 col-lg-5' : 'col-6'} ${section.class}`} style={section.style}>*/}
          {/*      <div className={`content-title ${section.labelClassName}`}>{section.label}</div>*/}
          {/*      <div className={`content-title sub-txt ${section.labelClassName}`}>{section.subTxt}</div>*/}
          {/*      <div className={`${section.optionsWrapperClassName}`}>*/}
          {/*        {section.label != 'Overview' &&*/}
          {/*          <>*/}
          {/*            /!*<div>*!/*/}
          {/*            /!*  <p>Start With pre-existing</p>*!/*/}
          {/*            /!*  <Dropdown*!/*/}
          {/*            /!*    onVisibleChange={e => {*!/*/}
          {/*            /!*      console.log(e);*!/*/}
          {/*            /!*      const dropdownEl = document.querySelectorAll('.ant-dropdown-menu-submenu-title');*!/*/}
          {/*            /!*      if(dropdownEl){*!/*/}
          {/*            /!*        dropdownEl.forEach((el, i) => {*!/*/}
          {/*            /!*          dropdownEl[i].title = '';*!/*/}
          {/*            /!*        })*!/*/}
          {/*            /!*      }*!/*/}
          {/*            /!*    }}*!/*/}
          {/*            /!*    className="buttons-item"*!/*/}
          {/*            /!*    overlay={SPONSORED_MENUS}*!/*/}
          {/*            /!*    overlayClassName='dropdown-button-wrapper'*!/*/}
          {/*            /!*  >*!/*/}
          {/*            /!*    <div className="d-flex align-items-center">*!/*/}
          {/*            /!*      <img src={IMG_OBJECT['Sponsor Models']} height="49" />*!/*/}
          {/*            /!*      <div className='row' style={{marginLeft: '10px', width: '100%', marginRight: '0px'}}>*!/*/}
          {/*            /!*        <div className="buttons-item-label">Sponsor Models <DownOutlined /></div>*!/*/}
          {/*            /!*      </div>*!/*/}
          {/*            /!*    </div>*!/*/}
          {/*            /!*  </Dropdown>*!/*/}
          {/*            /!*  <Dropdown*!/*/}
          {/*            /!*    onVisibleChange={e => console.log(e)}*!/*/}
          {/*            /!*    className="buttons-item"*!/*/}
          {/*            /!*    overlayClassName="dropdown-button-wrapper market-place-dropdown"*!/*/}
          {/*            /!*    overlay={MY_PORTFOLIO_MENUS}*!/*/}
          {/*            /!*  >*!/*/}
          {/*            /!*    <div className="d-flex align-items-center">*!/*/}
          {/*            /!*      <img src={IMG_OBJECT['My Models']} height="49" />*!/*/}
          {/*            /!*      <div className='row' style={{marginLeft: '10px', width: '100%', marginRight: '0px'}}>*!/*/}
          {/*            /!*        <div className="buttons-item-label">My Models <DownOutlined /></div>*!/*/}
          {/*            /!*      </div>*!/*/}
          {/*            /!*    </div>*!/*/}
          {/*            /!*  </Dropdown>*!/*/}
          {/*            /!*  <Dropdown*!/*/}
          {/*            /!*    onVisibleChange={e => console.log(e)}*!/*/}
          {/*            /!*    className="buttons-item"*!/*/}
          {/*            /!*    overlayClassName="dropdown-button-wrapper market-place-dropdown"*!/*/}
          {/*            /!*    overlay={CLIENT_LIST_MENUS}*!/*/}
          {/*            /!*  >*!/*/}
          {/*            /!*    <div className="d-flex align-items-center">*!/*/}
          {/*            /!*      <img src={IMG_OBJECT['Client Accounts']} height="49" style={{ width: 32 }} />*!/*/}
          {/*            /!*      <div className='row' style={{marginLeft: '10px', width: '100%', marginRight: '0px'}}>*!/*/}
          {/*            /!*        <div className="buttons-item-label">Client Accounts <DownOutlined /></div>*!/*/}
          {/*            /!*      </div>*!/*/}
          {/*            /!*    </div>*!/*/}
          {/*            /!*  </Dropdown>*!/*/}
          {/*            /!*</div>*!/*/}
          {/*            /!*<div>*!/*/}
          {/*            /!*  <p>Select from </p>*!/*/}
          {/*            /!*  <div*!/*/}
          {/*            /!*    className="buttons-item"*!/*/}
          {/*            /!*    style={{ marginBottom: 0 }}*!/*/}
          {/*            /!*    onClick={() => this.routeToAssetAllocationFrom('From Scratch', 'scratch')}*!/*/}
          {/*            /!*  >*!/*/}
          {/*            /!*    <img src={IMG_OBJECT['Scratch']} height="49" />*!/*/}
          {/*            /!*    <div className='row' style={{marginLeft: '10px'}}>*!/*/}
          {/*            /!*      <div className="buttons-item-label">From Scratch</div>*!/*/}
          {/*            /!*    </div>*!/*/}
          {/*            /!*  </div>*!/*/}
          {/*            /!*  <div*!/*/}
          {/*            /!*    className={`buttons-item ${buildportfolio.resultPageCart.length === 0 ? 'disabled' : ''}`}*!/*/}
          {/*            /!*    onClick={() => buildportfolio.resultPageCart.length > 0 ? this.useSelectedFunds() : null}*!/*/}
          {/*            /!*  >*!/*/}
          {/*            /!*    <img src={IMG_OBJECT['Selected Funds']} height="49" />*!/*/}
          {/*            /!*    <div className='row' style={{marginLeft: '10px'}}>*!/*/}
          {/*            /!*      <div className="buttons-item-label">{count} Selected {count === 1 ? 'Fund' : 'Funds'}</div>*!/*/}
          {/*            /!*    </div>*!/*/}
          {/*            /!*  </div>*!/*/}
          {/*            /!*</div>*!/*/}
          {/*          </>*/}
          {/*        }*/}
          {/*        {section.label == 'Overview' && section.options.map((i) => {*/}
          {/*        const El = i.Element;*/}
          {/*          return (*/}
          {/*              <El*/}
          {/*              disabled={i.disabled}*/}
          {/*              onClick={() => i.onClick()}*/}
          {/*            >*/}
          {/*              {i.img()}*/}
          {/*              <div className='row' style={{marginLeft: '10px'}}>*/}
          {/*              {i.head()}*/}
          {/*              {i.subHead && i.subHead()}*/}
          {/*              </div>*/}
          {/*              </El>*/}
          {/*          );*/}
          {/*        })}*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  );*/}
          {/*})}*/}
          <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
            <InnerPageFooter style={{ width: "100%", borderTop: "1px solid #d5cbcb", textAlign: "center", fontFamily: "Open Sans", fontSize: "12px", padding: ".4em", background: "#f7f7f7", marginTop: 20 }} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ buildportfolio, enhancer, auth, loading, sfm, clientportfolioV2, profile }) => ({
  buildportfolio,
  sfmItems: sfm.items,
  resultPageCart: buildportfolio.resultPageCart,
  marketPlaceList: enhancer.marketPlaceList,
  portfolioList: enhancer.portfolioList,
  clientList: enhancer.clientList,
  // clientListLoading: createLoadingSelector(['GET_CLIENT_LIST'])({ loading }),
  uploadedClientList: clientportfolioV2.uploadedClientList,
  showUpdateColumnInPreview: clientportfolioV2.showUpdateColumnInPreview,
  client_exist: auth.client_exist,
  // loading: createLoadingSelector(['PORTFOLIO_DATA', 'INVESTMENT_FETCH', 'GET_CLIENT_LIST', 'GET_HOME_OFFICE_MODELS', 'LOAD_CPM_LIST'])({ loading }), //|| investment.loading,
  loading: createLoadingSelector(['PORTFOLIO_DATA', 'LOAD_CPM_LIST'])({ loading }),
  demoPortfolioLoader: createLoadingSelector(['CHECK_BUILDER_TICKER_EXIST',  'GET_REAL_TIME_PRICE_TICKERS', 'LOAD_DEMO_CSV','DELETE_PORTFOLIO_BUILDER_BY_ID'])({ loading }), //|| investment.loading,
})

const mapDispatchToProps = {
  setBuildPortfolioState,
  // investmentFetch,
  // investmentFetchSuccess,
  fetchPortfolioRequest,
  fetchPortfolioSuccess,
  resetBuildPortfolioState,
  // getClientListRequest,
  deletePortfolioBuilderByIdRequest,
  // loadCPMListRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OnBoardScreen));

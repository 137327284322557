import React, { useState, useEffect } from 'react';

const useInView = elRef => {
    const [inView, setInView] = useState(false)
    useEffect(() => {
        const onScroll = () => {
          if (!elRef.current) return
    
          const boundingRect = elRef.current.getBoundingClientRect()
          const elementHeight = elRef.current.offsetHeight
          const offsetTop = boundingRect.top
          const windowHeight = window.innerHeight
          const isVisible = offsetTop + elementHeight > 0 && offsetTop < windowHeight

          isVisible && !inView && setInView(isVisible)
          !isVisible && inView && setInView(isVisible)
        }
    
        window.addEventListener('scroll', onScroll)
        return () => {
          window.removeEventListener('scroll', onScroll)
        }
      }, [elRef, inView])
    
      return inView    
}

export default useInView
// use sessionStorage to store the authority info,
// which might be sent from server in actual project.

export function getAuthority() {
  return window.localStorage.getItem('auth-type') || 'user';
}

export function setAuthority(authority) {
  return window.localStorage.setItem('auth-type', authority);
}

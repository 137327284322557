// absolute imports
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import PfSearchBox from './PfSearchBox';
import ModelSearchBox from './ModelSearchBox';

class Search extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  queryHandler = (query) => {
    if (query) {
      console.log(query)
    }
  }

  render() {
    return (
      <Row className="search-wrapper">
        <Col xs={{ size: 8}}>
          <div className="searchInput">
            { !this.props.selectedClient ? <PfSearchBox
              queryHandler={this.queryHandler}
              getTypedQuery={this.props.getTypedQuery}
              history={this.props.history}
              trendingSearch={this.props.trendingSearch}
              trendingSearchQuery={this.props.trendingSearchQuery}
              placeholder="Use Language Based Search To Identify Client Portfolios"
            /> :
            <ModelSearchBox
              getTypedQuery={this.props.getTypedQuery}
              queryHandler={this.queryHandler}
              history={this.props.history}
              placeholder="Use Language Based Search To Identify Model Portfolios"
            /> }
          </div>
        </Col>
      </Row>
    )
  }
}

export default Search;

import React, { Component, Fragment } from 'react';
// import moment from 'moment';
import { connect } from 'react-redux';
import { Row, Col, Tabs, Tag } from 'antd';
import _ from 'lodash';
import { addEventToAnalytics, getPortfolioBenchmarkOfSelectedTicker } from 'Utils'

// import { QuinItems, SectorsAttrs } from 'DataSet';
// import { SectorColors } from 'data/Colors';

import { createLoadingSelector } from 'selectors/loading-selector';
import { setBuildPortfolioState, setAnalyticsChartData, customWeightInput } from 'actions/buildportfolio'
import { getSelectedFundList } from 'selectors/buildportfolio';

import { customScroll, numberCheck } from 'layouts/utils';

// import ChartStats from './ChartStats';
import CardLoader from '../../../Elements/CardLoader';
import AnalyticsTab from './AnalyticsTab';
import SliderChart from './SliderChart';
import { getUploadedPortfolioStats } from '../../../../AccountsPage/utils';
import { getCalculatedStats } from '../utils';

const { TabPane } = Tabs;

class ResultSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: props.chartData,
      activeTab: 'Allocation',
      screenSize: null,
      selectedTabData: {},
      selectedHeaderMenu: [],
      fundList: props.fundList || [],
    };
    this.onHoldingInputChangeDebounced = _.debounce(this.onHoldingInputChangeDebounced,3000)
  }

  componentDidMount() {
    customScroll();
    const { chartData, fundList, setAnalyticsChartData, upDownDataSet, selectedBenchmark, portfolioValue } = this.props;
    if (chartData !== this.state.chartData) {
      this.setState({ chartData });
    }
    if (fundList !== this.state.fundList) {
      this.setState({ fundList }, () => {
        if (setAnalyticsChartData && this.state.fundList && this.state.fundList.length) {
          let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(upDownDataSet, selectedBenchmark);
          // const holdings = this.state.fundList.map(k => {
          //   let _returns = [];
          //   k.returns.forEach(item => {
          //     _returns.push({ d: item.d, v: (item.v / 100) })
          //   });
          //   k._returns = _returns;
          //   return k;
          // });
          const analyticsData = getCalculatedStats(this.state.fundList, true, portfolioBenchmark)
          // const analyticsData = getUploadedPortfolioStats(holdings, false, numberCheck(portfolioValue), portfolioBenchmark);
          // console.log('holdings --> ', holdings);
          // console.log('oldStats --> ', oldStats);
          console.log('analyticsData --> ', analyticsData)
          setAnalyticsChartData({
            analyticsData,
          });
        }
      });
    }
    this.setState({screenSize: window.screen.width});
  }

  componentWillReceiveProps(newProps) {
    const { chartData, fundList, setAnalyticsChartData, upDownDataSet, selectedBenchmark, portfolioValue } = newProps;

    if (chartData !== this.state.chartData) {
      this.setState({ chartData });
    }

    if (fundList !== this.state.fundList && fundList.length) {
      this.setState({ fundList }, () => {
        if (setAnalyticsChartData && this.state.fundList && this.state.fundList.length) {
          let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(upDownDataSet, selectedBenchmark);
          // const holdings = this.state.fundList.map(k => {
          //   let _returns = [];
          //   k.returns.forEach(item => {
          //     _returns.push({ d: item.d, v: (item.v / 100) })
          //   });
          //   k._returns = _returns;
          //   return k;
          // });
          // console.log('holdings --> ', holdings);
          const analyticsData = getCalculatedStats(this.state.fundList, true, portfolioBenchmark);
          // const analyticsData = getUploadedPortfolioStats(holdings, false, numberCheck(portfolioValue), portfolioBenchmark);
          console.log('analyticsData --> ', analyticsData)
          // console.log('oldStats ->', oldStats)
          if (setAnalyticsChartData && !_.isEqual(newProps.analyticsData, analyticsData)) {
            setAnalyticsChartData({
              analyticsData,
            });
          }
        }
      });
    }

    if (newProps.fundList.length === 0) {
      this.onTabChange('Allocation');
    }

  }

  onTabChange = (active) => {
    if(active != this.state.activeTab)
      addEventToAnalytics('Builder '+ active + ' Tab','Builder '+ active + ' Tab','BUILDER_'+active.toUpperCase()+'_TAB',{},false);
    this.setState({ activeTab: active });
  }

  getTabHeaderDeign = (data) => {
    const {
      label,
      style,
    } = data;
    return (
      <div className="result-section-tabs-header-wrap" style={style}>
        <div className="text-label">{label}</div>
      </div>
    );
  }

  onHoldingInputChange= (e,ticker)=>{
    console.log(e,ticker)
    this.onHoldingInputChangeDebounced(e.target.value,ticker)
  }

  onHoldingInputChangeDebounced = (value,ticker)=>{
    this.props.customWeightInput({ value, ticker })
  }

  getTabBodyContent = (data, fundList) => {
    const { buildportfolio, getSelectedFundList } = this.props;
    const { activeTab, tabHeaderMenu, summary } = buildportfolio;
    const { chartData } = this.state;
    const { label } = data;
    const selectedTab = tabHeaderMenu.find((v) => v.key === activeTab);
    const activeTabLabel = selectedTab ? selectedTab.label : 'Equity';

    let selectedHeaderWeightingMethodology, selectedHeaderMenu = [];

    if(data.key == "Allocation"){
      const selectedTabData = buildportfolio[activeTab];
      // console.log(selectedTabData);
      if (selectedTabData) {
        selectedHeaderMenu = selectedTabData.data || [];
        selectedHeaderWeightingMethodology = selectedTabData.weightingMethodology || 'Equal';
        const selectedTabFunds = selectedTabData.selectedFunds;

        if (Object.keys(selectedTabFunds).length > 0) {
          selectedHeaderMenu = Object.keys(selectedTabFunds).map((v) => {
            if (selectedTabFunds[v] && selectedTabFunds[v].length) {
              if (buildportfolio[activeTab].data.length > 0) {
                const found = buildportfolio[activeTab].data.find((g) => g.query === v);
                if (found && typeof found !== 'undefined') {
                  return found;
                }
              }
            }
          }).filter((k) => typeof k !== 'undefined');
        }
        selectedHeaderMenu = _.uniqBy([...selectedTabData.data, ...selectedHeaderMenu], 'query');
        selectedHeaderMenu = _.sortBy(selectedHeaderMenu,['position']);
      }
      if (activeTab === 'thematicTab') {
        const selectedThemesFunds = buildportfolio[activeTab].selectedFunds;
        selectedHeaderMenu = []
        if (Object.keys(selectedThemesFunds).length > 0) {
          selectedHeaderMenu = Object.keys(selectedThemesFunds).map((v) => {
            if (selectedThemesFunds[v] && selectedThemesFunds[v].length) {
              if (buildportfolio[activeTab].data.length > 0) {
                const found = buildportfolio[activeTab].data.find((g) => g.query === v);
                if (found && typeof found !== 'undefined') {
                  return found;
                }
              }
            }
          }).filter((k) => typeof k !== 'undefined');
        }
        console.log(selectedHeaderMenu);
        // if (selectedHeaderMenu.filter((n) => (typeof n.selected !== 'undefined' && n.selected)).length > 0) {
        //   selectedHeaderMenu.filter((n) => (typeof n.selected !== 'undefined' && n.selected)).forEach((p) => {
        //     if (p) {
        //       p.sliderValue = selectedTab.sliderValue/selectedHeaderMenu.filter((d) => (typeof d.selected !== 'undefined' && d.selected)).length;
        //     }
        //   });
        // }
        selectedHeaderMenu = _.sortBy(selectedHeaderMenu,['position']);
        // selectedHeaderMenu = this.props.themeChartData;
        console.log(selectedHeaderMenu);
      }
    }

    const listHeaderDesign = (
      <Row className="list-head-wrapper">
        <Col span={2}>#</Col>
        <Col span={6}>Ticker</Col>
        <Col span={10}>Fund Name</Col>
        <Col span={6}>Weight</Col>
      </Row>
    );

    switch (label) {
      case 'Allocation':
        return (
          <div className="result-section-content-wrapper allocations-section">
            <div className="multi-chart-center-container custom-scroll">
              <Row className="result-chart-stats-container">
                <SliderChart
                  key="tab-chart"
                  dataKey="sliderValue"
                  className="responsive-donut-chart"
                  disableControls={summary.timeline === 'Act'}
                  activeTab={activeTab}
                  labelFor={label}
                  tabHeaderMenu={chartData}
                  oldData={this.props.buildportfolio.tabHeaderMenu}
                />
              </Row>
              {(selectedHeaderMenu.length > 0 && summary.timeline !== 'Act') && (
                <Row className="result-chart-stats-container">
                  <SliderChart
                    key="data-chart"
                    dataKey="sliderValue"
                    className="responsive-donut-chart"
                    disableControls={summary.timeline === 'Act'}
                    activeTab={activeTab}
                    labelFor={activeTabLabel}
                    tabHeaderMenu={selectedHeaderMenu}
                    weightingMethodology={selectedHeaderWeightingMethodology}
                    oldData={this.props.buildportfolio.tabHeaderMenu}
                    tabChart={true}
                  />
                </Row>
              )}
            </div>
          </div>
        );
        break;
      case 'Holdings':
        const {screenSize} = this.state;
        // console.log(fundList);

        let fundListArr = summary.timeline === 'Act' ? this.props.buildportfolio.calculatedPortfolio : fundList;
        fundListArr.forEach(item1 => {
          var itemFromArr2 = this.props.buildportfolio.preSelectedTickers.find(item2 => item2.ticker == item1.ticker);
          if (itemFromArr2) {
            item1.color = itemFromArr2.color;
          }
        });
        let equityFundsArr = fundListArr.filter((h) => h.color === '#DEF1FF');
        equityFundsArr = _.orderBy(equityFundsArr,['_sliderValue'], ['desc']);

        let bondFundsArr = fundListArr.filter((h) => h.color === '#E0E0E0');
        bondFundsArr = _.orderBy(bondFundsArr,['_sliderValue'], ['desc']);

        let thematicFundsArr = fundListArr.filter((h) => h.color === '#C0E1FA');
        thematicFundsArr = _.orderBy(thematicFundsArr,['_sliderValue'], ['desc']);

        fundListArr = [...equityFundsArr, ...bondFundsArr, ...thematicFundsArr];
        const legends = [
          {label: 'Equity', color: '#DEF1FF'},
          {label: 'Bonds', color: '#E0E0E0'},
          {label: 'Thematic Exposure', color: '#C0E1FA'},
        ]
        return (
          <div className="result-section-content-wrapper holdings-section">
            <div className="list-container">
              <Row className="list-head-wrapper">
                {/* <Col span={3}>#</Col> */}
                <Col span={screenSize > 1024 ? 6 : 7}>Ticker</Col>
                <Col span={screenSize > 1024 ?  12 : 10}>Fund Name</Col>
                <Col span={screenSize > 1024 ? 6 : 7}>Weight</Col>
              </Row>
              <Row id="holding-custom-scroll" className="list-body-wrapper custom-scroll">
                {fundListArr.map((item, index) => {
                  return (
                    <Row key={`i-${index}`} className="list-body-content-row" style={{background: item.color}}>
                      {/* <Col span={1} style={{background: item.color}}></Col> */}
                      <Col span={screenSize > 1024 ? 6 : 7}>{item.ticker}</Col>
                      <Col span={screenSize > 1024 ? 12 : 11}>{item.name || item.short_name}</Col>
                      <Col span={screenSize > 1024 ? 6 : 6}><CustomWeightInput isDisabled = {buildportfolio.summary.timeline==='Act'} ticker ={item}  customWeightInput ={this.props.customWeightInput} value={item._sliderValue ? parseFloat(item._sliderValue.toFixed(1)):0} /></Col>
                    </Row>
                  )}
                )}
              </Row>
              <div className='legends-body'>
                {legends.map((legend, index) => (
                  <Tag key={`i-${index}`} color={legend.color} className='tag'>{legend.label}</Tag>
                ))}
              </div>
            </div>
          </div>
        );
        break;
      case 'Analytics':
        return (
          <div className="result-section-content-wrapper analytics-section">
            <AnalyticsTab fundList={fundList} />
          </div>
        );
        break;
      default:
        // return <EquityTab />;
    }
  }

  resultTabState = () => {
    const { buildportfolio } = this.props
    const tabs = ['equityTab', 'bondTab', 'thematicTab'];
    return (tabs.map((item) => {
      const tab = buildportfolio[`${item}`];
      return Object.keys(tab.selectedFunds).length > 0 ? (
        Object.keys(tab.selectedFunds).map((u) => tab.selectedFunds[u].length > 0).filter((h) => h).length > 0
      ) : false;
    }).filter((h) => h).length > 0);
  }

  render() {
    const { loading, fundList } = this.props;

    const getCardTitle = (title) => {
      return (
        <div className="folder-content-card-title">
          {title}
        </div>
      )
    };

    const enableResultTabs = this.resultTabState(); // (equity || bond || thematic) ? false : true;

    const resultTabHeaderMenu = [{
      label: 'Allocation',
      key: 'Allocation',
      style: {},
      disable: false,
    }, {
      label: 'Holdings',
      key: 'Holdings',
      style: {},
      disable: !enableResultTabs,
    }, {
      label: 'Analytics',
      key: 'Analytics',
      style: {},
      disable: !enableResultTabs,
    }];

    return (
      <React.Fragment>
        <Row className="result-section-container">
          <Tabs
            className="result-section-tabs-wrapper"
            activeKey={this.state.activeTab}
            onChange={this.onTabChange}
            type="card"
          >
            {
              resultTabHeaderMenu.map((item) => {
                return (
                  <TabPane
                    forceRender
                    tab={this.getTabHeaderDeign(item)}
                    key={item.key}
                    disabled={item.disable}
                  >
                    {loading ?
                      (
                        <CardLoader style={{ border: 0 }} />
                      ) : this.getTabBodyContent(item, fundList)
                    }
                  </TabPane>
                );
              })
            }
          </Tabs>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ buildportfolio, loading,portfolio }) => ({
  buildportfolio,
  portfolioValue: buildportfolio.portfolioValue,
  analyticsData: buildportfolio.resultSection.analyticsData,
  loading: createLoadingSelector(['EQUITY_BATCH', 'BONDS_BATCH', 'THEMATIC_BATCH'])({ loading }),
  getSelectedFundList: getSelectedFundList(buildportfolio),
	upDownDataSet: portfolio.upDownDataSet,
	selectedBenchmark: portfolio.selectedBenchmark
})

const mapDispatchToProps = {
  setBuildPortfolioState,
  setAnalyticsChartData,
  customWeightInput,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResultSection);



export class CustomWeightInput extends React.Component {
  constructor(props) {
    super(props);
    // this.debouncedOnChange = _.debounce(this.debouncedOnChange, 900);
  }
  state = {
    value: this.props.value,
    error:false
  };

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      value:nextProps.value
    })
  }

  isError = (value)=>{
    return !!(value > 100 || value < 0 )
  }

  onChange(e) {
    let error = this.isError(parseFloat(e.target.value))
    this.setState({value: e.target.value,error})
  }

  debouncedOnChange=( )=> {
    let ticker = this.props.ticker
    const reg = /^\d+(\.\d+)?$/
    if(!this.state.value || this.state.value==='' || parseFloat(this.state.value)>100 ){
      //revert back values on wrong inputs
      console.log('revert')
      this.setState({value:this.props.value,error:false})
    }else if(parseFloat(this.state.value)!==parseFloat(this.props.value) ){
      let value = parseFloat(this.state.value)
      value >=0 && value<=100 && reg.test(value)&&  this.props.customWeightInput({value , ticker })
    }
  }


  render() {
    return (
      <div className="custom-weight-input">
        <input
          disabled={this.props.isDisabled}
          className={this.state.error&&'error'}
          style={{width:`100%`}}
          type={'number'}
          step={'any'}
          value={this.state.value}
          onChange={(e) => {
            this.onChange(e);
          }}
          onBlur={this.debouncedOnChange}
          onKeyDown = {(e)=>{
            if (e.key === 'Enter') {
              this.debouncedOnChange()
            }
          }}
        />
        <span>%</span>
      </div>
    );
  }
}

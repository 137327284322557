import React, { Component } from 'react'
import { Col, Row, Container, Form, FormGroup, Label, Input, Button } from 'reactstrap'
  import { Formik } from 'formik'
  import * as Yup from 'yup'

import {
  PrimaryButton,
  SecondaryButton,
  CustomFormFeedback
} from 'shared-components'

export default class ChangePassword extends Component {
  initialValue = {
    current_password: '',
    password: '',
    confirm_password: ''
  }

  passwordSchema = Yup.object().shape({
    current_password: Yup.string()
      .min(8, 'Current password has to be longer than 8 characters!')
      .required('Current password is a required field'),
    password: Yup.string()
      .min(8, 'Password has to be longer than 8 characters!')
      .required('Password is a required field'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Re-enter password is a required field')
  })

  handleCancel = resetForm => {
    resetForm(this.initialValue)
    this.props.onCancel()
  }

  render() {
    return (
      <Formik
        enableReinitialize
        initialValues={this.initialValue}
        onSubmit={this.props.onSubmit}
        validationSchema={this.passwordSchema}
        render={props => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm
          } = props
          return (
            <Form onSubmit={handleSubmit} noValidate={true} className="change-password-form">
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label for="current_password">Current Password</Label>
                    <Input
                      type="password"
                      name="current_password"
                      id="current_password"
                      value={values.current_password}
                      placeHolder={'Enter Current Password'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.current_password && touched.current_password
                          ? 'is-invalid'
                          : ''
                      }
                    />

                    <CustomFormFeedback
                      formProps={props}
                      fieldName="current_password"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label for="password">Password</Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      value={values.password}
                      placeHolder={'Enter New Password'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.password && touched.password ? 'is-invalid' : ''
                      }
                    />

                    <CustomFormFeedback
                      formProps={props}
                      fieldName="password"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label for="confirm_password">Confirm Password</Label>
                    <Input
                      type="password"
                      name="confirm_password"
                      id="confirm_password"
                      value={values.confirm_password}
                      placeHolder={'Confirm New Password'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.confirm_password && touched.confirm_password
                          ? 'is-invalid'
                          : ''
                      }
                    />

                    <CustomFormFeedback
                      formProps={props}
                      fieldName="confirm_password"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Row className="change-password__footer">
                      <Button color="tools"
                        className="btn h3 text-uppercase font-weight-bold p-3 mb-0 secondary-btn"
                        onClick={() => this.handleCancel(resetForm)}
                      >
                        CANCEL
                      </Button>
                      <Button color="tools"
                        className="btn h3 text-uppercase font-weight-bold p-3 mb-0 ml-auto secondary-btn"
                        >
                        UPDATE</Button>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          )
        }}
      />
    )
  }
}

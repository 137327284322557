import React from 'react';
import Emitter from 'realTimePrice/emitter';
import { calculatorOptions, cn, D3AppColors09, sponsorLogo } from 'DataSet';
import { Button, Card, CardBody, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { addEventToAnalytics, getImageOrFallback, getNest, fixedValue } from 'Utils';
import { TruncatedNameSponsor } from '../Common';

export default class ItemCard extends React.Component {
  state = {
    imageValidPath: false,
    realTimePrices: [],
  }

  componentDidMount() {
    const { card } = this.props;
    if (cn(card, 'Sponsor')) {
      getImageOrFallback(
        sponsorLogo(cn(card, 'Sponsor'))
      ).then(result => {
        if (result) {
          this.setState({ imageValidPath: true });
        } else {
          this.setState({ imageValidPath: false });
        }
      });
    }
    Emitter.on('GET_LIVE_PRICES', (price) => {
      let index;
      let realTimePrices = this.state.realTimePrices
      if (realTimePrices.length) {
        index = realTimePrices.findIndex(x => x.ticker === price.symbol);
        if (index > -1) {
          realTimePrices.splice(index, 1);
        }
        realTimePrices.push({ 'price': price.price, 'ticker': price.symbol })
      } else {
        realTimePrices.push({ 'price': price.price, 'ticker': price.symbol })
      }
      this.setState({ realTimePrices: realTimePrices })
    });
  }

  buyHandler = () => {
    const { funds, card } = this.props;
    this.props.buyHandler(card);
    if (funds) {
      addEventToAnalytics('Compare fund buy', 'Compare fund buy', 'COMPARE_FUND_BUY', { query: funds.query, logId: funds.logId, ticker: card.ticker, category: 'advisory_PTC' }, true);
    }
  }

  addHandler = () => {
    this.props.actionHandler('combine', this.props.card);
  }

  unselectCard = (item) => {
    const { funds } = this.props;
    this.props.clearHandler(item);
    if (funds) {
      addEventToAnalytics('Compare fund delete', 'Compare fund delete', 'COMPARE_FUND_DELETE', { query: funds.query, logId: funds.logId, ticker: item.ticker }, true);
    }
  }

  callEvent = () => {
    const { funds, card } = this.props;
    if (funds) {
      addEventToAnalytics('Compare score hover', 'Compare score hover', 'COMPARE_SCORE_HOVER', { query: funds.query, logId: funds.logId, ticker: card.ticker }, true);
    }
  }

  getFailedCriteriaMessage = (value) => {
    const totalFailedCriteria = value.length;
    const failedText = value.map(item => {
      const value = item.value + item.suffix;
      return `${item.name} ${item.condition} ${value}`;
    })
    const failedTextObj = {
      items: failedText,
      text: this.concatCriteria(failedText)
    }
    return failedTextObj;
  }

  concatCriteria = (items) => {
    if (!items) {
      return '';
    }
    if (!Array.isArray(items)) {
      return items;
    }
    return items.reduce((acc, curr, i) => {
      return i !== 0 ? acc + ' | ' + curr : curr
    }, '');
  }

  render() {
    const { imageValidPath, realTimePrices } = this.state;
    const { card, idx, funds, selectedCalculator } = this.props;
    let score = fixedValue(getNest(['_sfStats', 'score'], card));
    score = (isNaN(score) || score < 0) ? 0 : score;
    const failedCriteria = card._sfStats ? card._sfStats.failedConditions : null;
    const failedCriteriaMessage = failedCriteria ? this.getFailedCriteriaMessage(failedCriteria) : '';
    const isMagnifiactivescreener = selectedCalculator === calculatorOptions.magnifiactivescreener;
    // console.log(realTimePrices);
    let realPriceArr = realTimePrices.filter(e => e.ticker === card.ticker);
    let realPrice = ((realPriceArr.length > 0) ? realPriceArr[0].price.toFixed(2) : (cn(card, 'Nav Price') ? cn(card, 'Nav Price').toFixed(2) : '--'))
    let showTooltip = card.not_tradable === "1.0" && process.env.REACT_APP_SUB_DOMAIN === 'retail'

    return (
      <div style={{ padding: '0 2px 2px' }}>
        <Card className={`${(card.type !== 'SHARE') ? 'shadow-sm border-0 card-item' : 'shadow-sm border-0 card-item itemcard-disabled'}`}>
          <CardBody className="p-0 card">
            <Row className="no-gutters">
              <Col className="col-12 px-1 pt-1 progress-wrapper">
                <div className="progress-box position-relative">
                  {(card.type === 'SHARE') ? (
                    <div className="progress score flex-grow-1">
                      <div className="itemcard-disabled-text">Excluded From Analysis</div>
                    </div>
                  ) : (
                    <div className="progress score flex-grow-1">
                      {score !== -1 &&
                        <div className="progress-bar" role="progressbar"
                          style={{
                            minWidth: isMagnifiactivescreener ? 'auto' : '30px',
                            width: `${score}%`,
                            backgroundColor: score === 0 ? 'transparent' : D3AppColors09[idx % D3AppColors09.length],
                            color: score <= 14 ? '#4f5357' : '#fff',
                            overflow: 'visible',
                          }}
                          aria-valuenow={score} aria-valuemin="0" aria-valuemax="100" onMouseEnter={this.callEvent.bind(this)}>
                          {!isMagnifiactivescreener &&
                            <>
                              <span id={'compare-tooltip-' + idx} className="ml-2">{score}%</span>
                              <UncontrolledTooltip className="w-sm" placement="top" target={'compare-tooltip-' + idx}>
                                The score reflects the confidence that this security is indeed the best among the selected securities.
                              </UncontrolledTooltip>
                            </>
                          }
                        </div>
                      }
                      {(isMagnifiactivescreener && score === 0) &&
                        <span className="failed-criteria">
                          <div className="ban-icon-box" title="Failed Crieteria">
                            <i className="fal fa-ban"></i>
                          </div>
                          <span
                            className="failed-criteria-text"
                            id={'tooltip-failedtext-' + idx}
                          >
                            {failedCriteriaMessage.text}
                          </span>
                          {failedCriteriaMessage.items &&
                            failedCriteriaMessage.items.length > 2 ? (
                            <UncontrolledTooltip
                              placement="top"
                              target={'tooltip-failedtext-' + idx}
                            >
                              <FailedItems
                                items={failedCriteriaMessage.items}
                              />
                            </UncontrolledTooltip>
                          ) : ''}
                        </span>
                      }
                    </div>
                  )}
                </div>
                <div className="close-icon-box" title="Remove Fund">
                  <i className="fal fa-times-circle" onClick={() => this.unselectCard(card)}></i>
                </div>
              </Col>
              <Col className="col-12">
                <div
                  className="selected-card bg-ssf-gray-l5 p-1 h-100 d-flex align-items-center"
                  style={{ borderLeftColor: D3AppColors09[idx % D3AppColors09.length], border: 0, minHeight: 80 }}
                >
                  {/*card._showSponsoredOnSFM && <div className="sponsored-logo-tag" style={{ top: 5, left: 6 }}>SPONSORED</div>*/}
                  <div className="pr-1 text-center">
                    <h5 className="mb-0 text-blue text-gray-5 ticker-size ticker-price">{cn(card, 'Ticker Short')}</h5>
                    <h3 className={`mb-0 text-info`} style={{ minWidth: '5rem' }}>
                      <span className="ticker-price text-gray-5">$</span>
                      <span className="ticker-price">{realPrice}</span>
                    </h3>
                  </div>
                  <TruncatedNameSponsor showSponsorLogo={card._showSponsoredOnSFM} imageValidPath={imageValidPath} name={cn(card, 'Name2')} sponsor={cn(card, 'Sponsor')} lines={1} />
                  <div className="ml-auto pl-2 h-100">
                    <div className="d-flex align-items-center h-100 btn-wrap m-0">
                      <Button
                        color={"select"}
                        outline
                        className="btn h4 mb-0 mt-0 text-uppercase font-weight-bold px-3 py-1 modal-btn-br"
                        style={{ marginTop: '2px', background: showTooltip ? "#e8ecef" : "auto", borderColor: showTooltip ? "#d1d2d6" : "auto", color: showTooltip ? "#d1d2d6" : "auto" }}
                        onClick={() => {
                          this.buyHandler()
                          if (funds) {
                            addEventToAnalytics('Compare Buy Button', 'Compare Buy Button', 'COMPARE_BUY_BUTTON', { query: funds.query, logId: funds.logId, tickers: card.ticker, clickBtn: 'Buy' }, true);
                          }
                        }}>
                        BUY
                      </Button>
                      {showTooltip && (
                        <>
                          <div id="compare-buy-tooltip" style={{ position: "absolute", height: "30px", width: "60px", cursor: "no-drop" }}></div>
                          <UncontrolledTooltip className="w-sm" placement="bottom" target="compare-buy-tooltip" >
                            Security Not Tradable
                          </UncontrolledTooltip>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const FailedItems = ({ items }) => {
  return (
    <React.Fragment>
      {items.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {item} <br />
          </React.Fragment>
        )
      })}
    </React.Fragment>
  );
}

import React from 'react';
import { Row, Col } from 'reactstrap';
import { quintileSize, quintileText, getImage } from 'Utils';

export const FeeReturnRisk = ({ data, highlights }) => {
  const { feesHighLightEnable, returnHighLightEnable, riskHighLightEnable } = highlights;
  let attrs = [
    { attr: 'fees',          name: 'Fees',           col: 'fees',          qcol: 'feesq',                              reverse: true,  icon: 'fa-tags',           highlightClass: feesHighLightEnable ? 'search-risk-stats-highlighting' : '' },
    { attr: 'returns',       name: 'Returns',        col: 'yRet',          qcol: 'yRetQ',                              reverse: false, icon: 'fa-chart-line',     highlightClass: returnHighLightEnable ? 'search-risk-stats-highlighting' : '' },
    { attr: 'risk',          name: 'Risk',           col: 'volt',          qcol: 'voltQ',                              reverse: true,  icon: 'fa-heart-rate-alt', highlightClass: riskHighLightEnable ? 'search-risk-stats-highlighting' : '' },
  ]

  if (data.queryIcon !== '') {
    switch (data.queryIcon) {
      case 'OutPerform':
        attrs = [
          { attr: 'fees',    name: 'Outperform',     col: 'OutPerform',    qcol: 'outperform',                         reverse: true,  icon: 'Outperform',        highlightClass: 'search-risk-stats-highlighting' },
          { attr: 'returns', name: 'Returns',        col: 'yRet',          qcol: 'yRetQ',                              reverse: false, icon: 'fa-chart-line',     highlightClass: returnHighLightEnable ? 'search-risk-stats-highlighting' : '' },
          { attr: 'risk',    name: 'Risk',           col: 'volt',          qcol: 'voltQ',                              reverse: true,  icon: 'fa-heart-rate-alt', highlightClass: riskHighLightEnable ? 'search-risk-stats-highlighting' : '' },
        ]
        break;
      case 'TrackRecord':
        attrs = [
          { attr: 'fees',    name: 'Track Record',   col: 'TrackRecord',   qcol: 'attribute/track_record',             reverse: true,  icon: 'TrackRecord',       highlightClass: 'search-risk-stats-highlighting' },
          { attr: 'returns', name: 'Returns',        col: 'yRet',          qcol: 'yRetQ',                              reverse: false, icon: 'fa-chart-line',     highlightClass: returnHighLightEnable ? 'search-risk-stats-highlighting' : '' },
          { attr: 'risk',    name: 'Risk',           col: 'volt',          qcol: 'voltQ',                              reverse: true,  icon: 'fa-heart-rate-alt', highlightClass: riskHighLightEnable ? 'search-risk-stats-highlighting' : '' },
        ]
        break;
      case 'TrackingError':
        attrs = [
          { attr: 'fees',    name: 'Tracking Error', col: 'TrackingError', qcol: 'attribute/technical/tracking_error', reverse: true,  icon: 'fa-heart-rate-alt', highlightClass: 'search-risk-stats-highlighting' },
          { attr: 'returns', name: 'Returns',        col: 'yRet',          qcol: 'yRetQ',                              reverse: false, icon: 'fa-chart-line',     highlightClass: returnHighLightEnable ? 'search-risk-stats-highlighting' : '' },
          { attr: 'risk',    name: 'Risk',           col: 'volt',          qcol: 'voltQ',                              reverse: true,  icon: 'fa-heart-rate-alt', highlightClass: riskHighLightEnable ? 'search-risk-stats-highlighting' : '' },
        ]
        break;
      case 'Yield':
        attrs = [
          { attr: 'fees',    name: 'Yield',          col: 'Yield',         qcol: 'attribute/fundamental/yield',        reverse: true,  icon: 'Yield',             highlightClass: 'search-risk-stats-highlighting' },
          { attr: 'returns', name: 'Returns',        col: 'yRet',          qcol: 'yRetQ',                              reverse: false, icon: 'fa-chart-line',     highlightClass: returnHighLightEnable ? 'search-risk-stats-highlighting' : '' },
          { attr: 'risk',    name: 'Risk',           col: 'volt',          qcol: 'voltQ',                              reverse: true,  icon: 'fa-heart-rate-alt', highlightClass: riskHighLightEnable ? 'search-risk-stats-highlighting' : '' },
        ]
        break;
      default:
        attrs = [
          { attr: 'fees',    name: 'Fees',           col: 'fees',           qcol: 'feesq',                              reverse: true,  icon: 'fa-tags',           highlightClass: feesHighLightEnable ? 'search-risk-stats-highlighting' : '' },
          { attr: 'returns', name: 'Returns',        col: 'yRet',           qcol: 'yRetQ',                              reverse: false, icon: 'fa-chart-line',     highlightClass: returnHighLightEnable ? 'search-risk-stats-highlighting' : '' },
          { attr: 'risk',    name: 'Risk',           col: 'volt',           qcol: 'voltQ',                              reverse: true,  icon: 'fa-heart-rate-alt', highlightClass: riskHighLightEnable ? 'search-risk-stats-highlighting' : '' },
        ];
        break;
    }
  }

  const elems = attrs.map(attr => {
    if (data.queryIcon !== '' && attr.col === data.queryIcon) {
      return {
        attr: attr.attr,
        name: attr.name,
        icon: null,
        iconImg: (attr.col === data.queryIcon) ? data.queryIcon : `${attr.icon}-${quintileSize(data[attr.qcol], attr.reverse)}`,
        value: typeof data[data.queryIcon] !== 'undefined' ? data[data.queryIcon] : ((typeof data[attr.qcol] !== 'undefined' && data[attr.qcol] !== '--') ? data[attr.qcol].toFixed(2) + "%" : "--"),
        label: null,
        highlightClass: attr.highlightClass,
      }

    } else {
      const qs = quintileSize(data[attr.qcol], attr.reverse);
      return {
        attr: attr.attr,
        name: attr.name,
        icon: `${attr.icon}-${qs}`,
        iconImg: null,
        value: data[attr.col] ? data[attr.col].toFixed(2) + "%":'--',
        label: quintileText(qs),
        highlightClass: attr.highlightClass,
      }
    }
  })

  return (
    <Row className="border-bottom no-gutters FRR-wrapper fees-risk-return-stats-wrapper">
      { elems.map(e => (
          <QuintileIconText key={e.attr} {...e} />
      )) }
    </Row>
  );
}

const QuintileIconText = ({ attr, name, icon, iconImg, value, label, highlightClass }) => (
  <div className={`${attr}-flag-color FRR-container px-3 py-2 ${highlightClass}`} style={{ height: '100%' }}>
    <p className="__state">{name}</p>
    {(iconImg !== null && icon === null) ? (
      <img className="far fa-fw fassf" src={getImage(iconImg)} width="auto" height={32} />
    ) : (
      <i className={`far fa-fw fassf ${icon}`} />
    )}
    <p className="__val">{value}</p>
  </div>
)

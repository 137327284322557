import React from 'react';
import { Row, Col } from 'reactstrap';
import { haveHTMLData } from 'Utils';

export const Disclosures = ({ disclosure }) => {
    return (
        <React.Fragment>
            <div className='pr-v2-disclosure'>
                <Row>
                    <Col>
                        <b>Disclosures</b>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {disclosure && disclosure.length > 0 && haveHTMLData(disclosure) ? (
                            <div dangerouslySetInnerHTML={{ __html: disclosure }} />
                        ) : (
                            <p className={'cover-logo'} style={{ margin: '1rem 0 0 0' }}>
                                Your Disclosures Here
                                <br />
              Under Profile -> Proposals
                            </p>
                        )}
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}
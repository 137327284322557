import React from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Modal,
  Tabs,
  // Divider,
  Tooltip,
} from 'antd';
import { Range } from 'react-range';
import { CircleProgress } from 'shared-components';

import { numberCheck, prepareScoreMeterColorArray } from 'layouts/utils';
// import { TifinRiskLogo } from 'SvgComponent';

let TifinRiskLogo = '/assets/images/tifin-risk-logo.svg';

const { TabPane } = Tabs;
// const { TextArea } = Input;

// const copyLinkToClipBoard = link => {
//   if (navigator.clipboard) {
//     navigator.clipboard.writeText(link).then(() => {
//       console.log('Copied to clipboard successfully.');
//     }, (err) => {
//       console.log('Failed to copy the text to clipboard.', err);
//     });
//   } else if (window.clipboardData) {
//     window.clipboardData.setData("Text", link);
//   }
// }

const Direction = {
  Right: 'to right',
  Left: 'to left',
  Down: 'to bottom',
  Up: 'to top'
};

const getTrackBackground = ({
	values,
	colors,
	min,
	max,
	direction = Direction.Right,
	rtl = false
}) => {
	if (rtl && direction === Direction.Right) {
		direction = Direction.Left;
	} else if (rtl && Direction.Left) {
		direction = Direction.Right;
	}
	// sort values ascending
	const progress = values.slice(0).sort((a, b) => a - b).map(value => ((value - min) / (max - min)) * 100);
	const middle = progress.reduce((acc, point, index) => `${acc}, ${colors[index]} ${point}%, ${colors[index + 1]} ${point}%`, '');
	return `linear-gradient(${direction}, ${colors[0]} 0%${middle}, ${colors[colors.length - 1]} 100%)`;
}

export const ScoreMeter = (scoreMeterProps) => {
	const {
		min,
		max,
		step,
		width,
		scores,
		disabled,
    allScores,
		scoreMeter,
	} = scoreMeterProps;

  const { clientId } = scoreMeterProps;

  const setScores = (values) => {
    console.log(values);
  }

  // console.log('scoreMeter ==> ', scoreMeter);
  const scoreMeterClick = (event, elementID) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    // console.log('---- elementID ---->', elementID);
    const targetElement = document.getElementById(`${clientId}-score-meter-range-thumb-${elementID}`);
    const capacityElement = document.getElementById(`${clientId}-score-meter-range-thumb-capacity`);
    const preferenceElement = document.getElementById(`${clientId}-score-meter-range-thumb-preference`);

    if (elementID === 'current') {
      const siblingElement = document.getElementById(`${clientId}-score-meter-range-thumb-enhanced`);
      if (siblingElement && siblingElement.style) {
        siblingElement.style.zIndex = 0;
      }
      if (targetElement && targetElement.style && siblingElement && siblingElement.style) {
        targetElement.style.zIndex = siblingElement.style.zIndex + 1;
        if (capacityElement && capacityElement.style) {
          capacityElement.style.zIndex = targetElement.style.zIndex + 1
        }
        if (preferenceElement && preferenceElement.style) {
          preferenceElement.style.zIndex = targetElement.style.zIndex + 1
        }
      }
    } else if (elementID === 'enhanced') {
      const siblingElement = document.getElementById(`${clientId}-score-meter-range-thumb-current`);
      if (siblingElement && siblingElement.style) {
        siblingElement.style.zIndex = 0;
      }
      if (targetElement && targetElement.style && siblingElement && siblingElement.style) {
        targetElement.style.zIndex = siblingElement.style.zIndex + 1;
        if (capacityElement && capacityElement.style) {
          capacityElement.style.zIndex = targetElement.style.zIndex + 1
        }
        if (preferenceElement && preferenceElement.style) {
          preferenceElement.style.zIndex = targetElement.style.zIndex + 1
        }
      }
    }
  }

  // let rangeValue = scoreMeter.filter((item) => item.key === 'preference' || item.key === 'capacity').map((kl) => kl.value);
  // let currentScore = scoreMeter.find((item) => item.key === 'current') ? scoreMeter.find((item) => item.key === 'current').value : null;
  // let enhancedScore = scoreMeter.find((item) => item.key === 'enhanced') ? scoreMeter.find((item) => item.key === 'enhanced').value : null;
  // let scoreKeyOrders = scoreMeter.map((item) => item.key);
  // // console.log('===== rangeValue =====', rangeValue);
  // let minRange = null, maxRange = null;
  //
  // if (rangeValue && rangeValue.length > 1) {
  //   minRange = Math.min(...rangeValue);
  //   maxRange = Math.max(...rangeValue);
  // }

  let colorsArray = ['#9f9f9f', '#9f9f9f', '#9f9f9f']; // ['#ccc', '#56a9e8', '#ccc'];
  colorsArray = prepareScoreMeterColorArray({ scoreMeter });

  // if (scoreKeyOrders && scoreKeyOrders.length > 2) { // atleast scoreKeyOrders has =>> ['preference', 'capacity', 'current'] || ['preference', 'capacity', 'enhanced']
  //   let indexOfPreference = scoreKeyOrders.indexOf('preference');
  //   let indexOfCapacity = scoreKeyOrders.indexOf('capacity');
  //
  //   // let prepareColorArray = scoreKeyOrders.join('---,');
  //   // prepareColorArray = prepareColorArray.split(',');
  //   colorsArray = prepareScoreMeterColorArray({ scoreMeter });
  //
  //
  //   if (scoreKeyOrders.length === 3) { // ['preference', 'capacity', 'current']
  //     // NOTE: check 'current' or 'enhanced' position
  //     let indexOfCurrentOrEnahncedValue = scoreKeyOrders.indexOf('current') || scoreKeyOrders.indexOf('enhanced'); // after 'Finalize' case
  //     if (indexOfCurrentOrEnahncedValue !== 0 && indexOfCurrentOrEnahncedValue !== 2) {
  //       // NOTE: order are => ['preference', 'current', 'capacity']
  //       colorsArray = ['#9f9f9f', '#56a9e8', '#56a9e8', '#9f9f9f'];
  //     } else {
  //       // NOTE: check 'preference' & 'capacity' position
  //       if (indexOfPreference !== -1 && indexOfCapacity !== -1) {
  //         // NOTE: order either this => ['current', 'preference', 'capacity'] or this -> ['preference', 'capacity', 'current']
  //         console.log('-- current scores is not in range --');
  //         if (indexOfPreference !== 0 && indexOfCapacity !== 0) {
  //           // NOTE: color for order => ['current', 'preference', 'capacity'] || ['current', 'capacity', 'preference']
  //           // NOTE: '---- |current| ---- |preference| ---- |current| ----' || '---- |current| ---- |capacity| ---- |preference| ----'
  //           colorsArray = ['#9f9f9f', '#9f9f9f', '#56a9e8', '#9f9f9f'];
  //         } else if (indexOfPreference !== 2 && indexOfCapacity !== 2) {
  //           // NOTE: color for order => ['preference', 'capacity', 'current'] || ['capacity', 'preference', 'current']
  //           // NOTE: '---- |preference| ---- |capacity| ---- |current| ----' || '---- |capacity| ---- |preference| ---- |current| ----'
  //           colorsArray = ['#9f9f9f', '#56a9e8', '#9f9f9f', '#9f9f9f'];
  //         }
  //       } else {
  //         console.log('--- preference or capacity is missing ---> ', scoreKeyOrders);
  //       }
  //     }
  //   } else if (scoreKeyOrders.length === 4 && currentScore !== null && enhancedScore !== null) {  // ['preference', 'capacity', 'current', 'enhanced']
  //     // NOTE: check 'current' and 'enhanced' position
  //     let indexOfCurrent = scoreKeyOrders.indexOf('current');
  //     let indexOfEnhanced = scoreKeyOrders.indexOf('enhanced');
  //     if (indexOfCurrent !== -1 && indexOfCurrent !== -1) {
  //       // NOTE: ['preference', 'current', 'enhanced', 'capacity']
  //       if (indexOfCurrent !== 0 && indexOfCurrent !== 3 && indexOfEnhanced !== 0 && indexOfEnhanced !== 4) {
  //         // NOTE: color for order => ['preference', 'current', 'enhanced', 'capacity'] || ['preference', 'enhanced', 'current', 'capacity']
  //         // NOTE: '---- |preference| ---- |current| ---- |enhanced| ---- |capacity| ----'
  //         colorsArray = ['#9f9f9f', '#56a9e8', '#56a9e8', '#56a9e8', '#9f9f9f'];
  //       } else {
  //         // NOTE: ['current', 'enhanced', '---', '---'] || ['enhanced', 'current', '---', '---']
  //       }
  //     } else {
  //       console.log('---  enhanced or current is missing in ---> ', scoreKeyOrders);
  //     }
  //
  //     // colorsArray = ['#9f9f9f', '#9f9f9f', '#56a9e8', '#56a9e8', '#9f9f9f'];
  //     //
  //     // if (currentScore !== null && enhancedScore !== null && ((currentScore < minRange || currentScore > maxRange) && (enhancedScore < minRange || enhancedScore > maxRange))) {
  //     //   // NOTE: both scores are not in range
  //     //   console.log('-- both scores are not in range --');
  //     //   colorsArray = ['#9f9f9f', '#9f9f9f', '#56a9e8', '#56a9e8', '#9f9f9f'];
  //     // } else {
  //     //   if (currentScore !== null && (currentScore < minRange || currentScore > maxRange)) {
  //     //     // NOTE: current scores is not in range
  //     //     console.log('-- current scores is not in range --');
  //     //     colorsArray = ['#9f9f9f', '#9f9f9f', '#56a9e8', '#56a9e8', '#9f9f9f'];
  //     //   } else if (enhancedScore !== null && (enhancedScore < minRange || enhancedScore > maxRange)) {
  //     //     // NOTE: enhanced scores is not in range
  //     //     console.log('-- enhanced scores is not in range --');
  //     //     colorsArray = ['#9f9f9f', '#56a9e8', '#9f9f9f', '#56a9e8', '#9f9f9f'];
  //     //   } else {
  //     //     // NOTE: default colors
  //     //     console.log('-- default colors --', currentScore, enhancedScore);
  //     //     colorsArray = ['#9f9f9f', '#56a9e8', '#56a9e8', '#56a9e8', '#9f9f9f'];
  //     //   }
  //     // }
  //   }
  // }


  // console.log('-- minRange --> ', minRange, '-- maxRange --> ', maxRange);

  let tooltipText = (key) => {
    if (key === 'capacity') {
      return `Risk Capacity: Identifies the amount of risk one's life situation will accommodate at the same moment in time.`;
    } else if (key === 'preference') {
      return `Risk Preference: Represents the amount of risk one is willing to take at a moment in time.`
    }
  };

  let getThumbTitle = (scoreMeter, index) => {
    if (scoreMeter[index] && scoreMeter[index].key) {
      if (scoreMeter[index].key === 'capacity' || scoreMeter[index].key === 'preference') {
        // console.log('tooltipText=->>', tooltipText(scoreMeter[index].key));
        return (
          <Tooltip placement="rightTop" title="hi">
            <span>{numberCheck(scoreMeter[index].value)}</span>
          </Tooltip>
        )
      } else {
        return `${scoreMeter[index].label}: ${numberCheck(scoreMeter[index].value)}`;
      }
    }
  }

	return (
		<Range
			step={step}
			min={min}
			max={max}
      draggableTrack={false}
			values={scores}
			disabled={disabled}
			onChange={setScores}
			renderTrack={({ props, children }) => {
        // console.log(scoreKeyOrders);
				// let colorsArray = ['#9f9f9f', '#9f9f9f', '#9f9f9f']; // ['#ccc', '#56a9e8', '#ccc'];
				// if (scores.length === 3) {
        //   if (currentScore !== null && (currentScore < minRange || currentScore > maxRange)) {
        //     // NOTE: current scores is not in range
        //     console.log('-- current scores is not in range --', scores.length);
        //     colorsArray = ['#9f9f9f', '#9f9f9f', '#56a9e8', '#9f9f9f'];
        //     console.log(colorsArray);
        //   } else {
        //     colorsArray = ['#9f9f9f', '#56a9e8', '#56a9e8', '#9f9f9f']
        //   }
				// } else if (scores.length === 4) {
        //   if (currentScore !== null && enhancedScore !== null && ((currentScore < minRange || currentScore > maxRange) && (enhancedScore < minRange || enhancedScore > maxRange))) {
        //     // NOTE: both scores are not in range
        //     console.log('-- both scores are not in range --');
        //     colorsArray = ['#9f9f9f', '#9f9f9f', '#56a9e8', '#56a9e8', '#9f9f9f'];
        //   } else {
        //     if (currentScore !== null && (currentScore < minRange || currentScore > maxRange)) {
        //       // NOTE: current scores is not in range
        //       console.log('-- current scores is not in range --');
        //       colorsArray = ['#9f9f9f', '#9f9f9f', '#56a9e8', '#56a9e8', '#9f9f9f'];
        //     } else if (enhancedScore !== null && (enhancedScore < minRange || enhancedScore > maxRange)) {
        //       // NOTE: enhanced scores is not in range
        //       console.log('-- enhanced scores is not in range --');
        //       colorsArray = ['#9f9f9f', '#56a9e8', '#9f9f9f', '#56a9e8', '#9f9f9f'];
        //     } else {
        //       // NOTE: default colors
        //       console.log('-- default colors --', currentScore, enhancedScore);
        //       colorsArray = ['#9f9f9f', '#56a9e8', '#56a9e8', '#56a9e8', '#9f9f9f'];
        //     }
        //   }
				// }
				return (
					<div
						className={`${props.className} score-meter-range-track-container`}
						onMouseDown={null}
						onTouchStart={null}
						style={{ ...props.style }}
					>
						<div
							className={`score-meter-range-track-wrapper`}
							ref={props.ref}
							style={{
								background: getTrackBackground({
									values: scores,
									colors: colorsArray,
									min,
									max,
								}),
							}}
							>
							<span id={`${clientId}-score-meter-range-track-min`} className="score-meter-range-track-min">1</span>
							{children}
							<span id={`${clientId}-score-meter-range-track-max`} className="score-meter-range-track-max">99</span>
						</div>
					</div>
				)
			}}
			renderThumb={({ index, props, isDragged }) => {
        return (
					<div
						{...props}
            id={`${(scoreMeter[index] && scoreMeter[index].key) ? `${clientId}-score-meter-range-thumb-${scoreMeter[index].key}` : ''}`}
						className={`${props.className} score-meter-range-thumb-container ${(scoreMeter[index].key === 'capacity' || scoreMeter[index].key === 'preference') ? 'cursor-pointer' : ''}`}
						style={scoreMeter[index].key === 'enhanced' || scoreMeter[index].key === 'current' ? {
							...props.style,
							backgroundColor: '#ccc',
						} : {
							...props.style,
							backgroundColor: 'transparent',
              pointerEvents: 'none',
						}}
            onClick={(event => scoreMeter[index] && scoreMeter[index].key ? scoreMeterClick(event, scoreMeter[index].key) : null)}
            onMouseDown={(event => scoreMeter[index] && scoreMeter[index].key ? scoreMeterClick(event, scoreMeter[index].key) : null)}
            onTouchStart={(event => scoreMeter[index] && scoreMeter[index].key ? scoreMeterClick(event, scoreMeter[index].key) : null)}
					>

            {(scoreMeter[index].key === 'capacity' || scoreMeter[index].key === 'preference') ? (

              <Tooltip placement="rightTop" title={tooltipText(scoreMeter[index].key)}>
                <div
                  id={`${(scoreMeter[index] && scoreMeter[index].key) ? `${clientId}-score-meter-range-thumb-title-${scoreMeter[index].key}` : ''}`}
                  className={`score-meter-range-thumb-wrapper ${(scoreMeter[index].key === 'enhanced' || scoreMeter[index].key === 'current') ? 'current-enhanced' : 'not-current-enhanced'}`}
                >
                  <span>{numberCheck(scoreMeter[index].value)}</span>
                </div>
              </Tooltip>
            ): (
              <div
                id={`${(scoreMeter[index] && scoreMeter[index].key) ? `${clientId}-score-meter-range-thumb-title-${scoreMeter[index].key}` : ''}`}
                className={`score-meter-range-thumb-wrapper ${(scoreMeter[index].key === 'enhanced' || scoreMeter[index].key === 'current') ? 'current-enhanced' : 'not-current-enhanced'}`}
              >
                <span>{`${scoreMeter[index].label}: ${numberCheck(scoreMeter[index].value)}`}</span>
              </div>

            )}
						<div
              id={`${(scoreMeter[index] && scoreMeter[index].key) ? `${clientId}-score-meter-range-thumb-title-${scoreMeter[index].key}` : ''}`}
							className={`score-meter-range-thumb-wrapper ${(scoreMeter[index].key === 'enhanced' || scoreMeter[index].key === 'current') ? 'current-enhanced' : 'not-current-enhanced'}`}
						>

						</div>
						{(scoreMeter[index].key === 'enhanced' || scoreMeter[index].key === 'current') ? (
							<div className={`score-meter-range-thumb-circle-progress`}>
								<CircleProgress
									greyStrokeColor="#ccc"
                  width={38}
                  height={38}
									strokeWidth={20}
									percentage={parseInt(scores[index])}
									color={scoreMeter[index].key === 'enhanced' ? '#1e5f91' : '#56a9e8'}
								/>
							</div>
						) : null}
						<div
							className={`${(scoreMeter[index].key === 'enhanced' || scoreMeter[index].key === 'current') ? 'score-meter-range-thumb-circle-text' : 'score-meter-range-thumb-circle-vertical-bar'}`}
						>
							{scoreMeter[index].key === 'enhanced' || scoreMeter[index].key === 'current' ? parseInt(scores[index]) : (
                <i className="fas fa-filter score-meter-range-thumb-circle-vertical-bar-funnel"></i>
              )}
						</div>
					</div>
				)
			}}
		/>
	)
}

export const ClientDetailModal = ({ style, loading, wrapClassName, onClickOk, formData, onModalClose, ...props }) => {
  // console.log('formData ->', formData);
  return (
    <Modal
      {...props}
      centered={true}
      footer={null}
      width={600}
      style={{ ...style, display: loading ? 'none' : '' }}
      bodyStyle={{ minHeight: 212, display: loading ? 'none' : '' }}
      wrapClassName={`csv-preview-upload ${wrapClassName}`}
      onCancel={onModalClose}
      closeIcon={<i className="fal fa-times premium-feature-completed-close cp-modal-close" onClick={onModalClose}></i>}
    >
      <Tabs
        centered
        className="totum-questionnaire-form-tab-container"
        defaultActiveKey="1"
        tabBarGutter={2}
      >
        <TabPane className="totum-questionnaire-form-tabpane-wrapper" tab="Full Questionnaire" key="1">
          <div className="label-text"></div>
          <FullQuestionnaire formData={formData} onSubmit={onClickOk} />
        </TabPane>
        <TabPane className="totum-questionnaire-form-tabpane-wrapper" tab="Mobile Questionnaire" key="2">
          <MobileQuestionnaire />
        </TabPane>
      </Tabs>
      <div className="totum-questionnaire-form-footer-wrapper">
        {/* <span className="text">powered by</span> */}
        <span>
          <img src={TifinRiskLogo} className="icon_in_heading" width={'auto'} height={24} style={{ paddingLeft: 10, width: 'auto', height: 24 }} />
        </span>
      </div>
    </Modal>
  )
}

export const FullQuestionnaire = ({ formData, onSubmit }) => {
  const onFinish = (values) => {
    if (onSubmit) {
      onSubmit(values)
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  let values = formData ? formData : {
    email: '',
    first_name: '',
    last_name: '',
  };

  let fields = [
    {
      name: ['first_name'],
      value: values.first_name,
    },
    {
      name: ['last_name'],
      value: values.last_name,
    },
    {
      name: ['email'],
      value: values.email,
    },
  ];

  return (
    <Form
      name="basic"
      autoComplete="off"
      layout="vertical"
      className="totum-questionnaire-form-container"
      initialValues={{ remember: true, size: 'large' }}
      onFinish={onFinish}
      fields={fields}
      onFinishFailed={onFinishFailed}
    >
      <Row className="totum-questionnaire-form-row" gutter={[16, 16]} style={{ marginBottom: 0 }}>
        <Col className="totum-questionnaire-form-col" span={12}>
          <Form.Item name='first_name' rules={[{ required: true, message: '' }]}>
            <Input size="large" placeholder="First Name" />
          </Form.Item>
        </Col>
        <Col className="totum-questionnaire-form-col" span={12}>
          <Form.Item name='last_name' rules={[{ required: true, message: '' }]}>
            <Input size="large" placeholder="Last Name" />
          </Form.Item>
        </Col>
      </Row>

      <Row className="totum-questionnaire-form-row" gutter={[16, 0]} style={{ paddingBottom: 8 }}>
        <Col className="totum-questionnaire-form-col" span={24}>
          <Form.Item name='email' rules={[{ type: 'email', required: true, message: '' }]}>
            <Input size="large" placeholder="E-mail" />
          </Form.Item>
        </Col>
      </Row>

      <Row className="totum-questionnaire-form-row">
        <Form.Item className="totum-questionnaire-form-cta-wrap">
          <Button type="primary" htmlType="submit">
            PREVIEW
          </Button>
        </Form.Item>
      </Row>

    </Form>
  )
}

export const MobileQuestionnaire = () => {
  return (
    <div className="totum-questionnaire-coming-soon">Coming Soon</div>
  )
}

export const ResendEmailModal = ({ style, visible, wrapClassName, onClickOk, onModalClose, ...props }) => {
  // console.log('formData ->', formData);
  console.log('-- ResendEmailModal visible ---> ', visible);
  const onClickSend = () => {
    console.log('--- onClickSend ---');
    if (onClickOk) {
      onClickOk()
    }
  }
  const onClickCancel = () => {
    console.log('--- onClickCancel ---');
    if (onModalClose) {
      onModalClose()
    }
  }
  return (
    <Modal
      {...props}
      width={600}
      footer={null}
      centered={true}
      visible={visible}
      style={{ ...style }}
      bodyStyle={{ minHeight: 212 }}
      wrapClassName={`csv-preview-upload ${wrapClassName}`}
      onCancel={onModalClose}
      closeIcon={<i className="fal fa-times premium-feature-completed-close cp-modal-close" style={{ color: '#c5c5c5' }} onClick={onModalClose}></i>}
    >
      <div className="totum-questionnaire-resend-email-wrapper">
        <div class="title-head mt-3">Questionnaire is incomplete</div>
        <div className="message-text mt-3">Do you want to resend the link?</div>
        <div className="totum-questionnaire-email-cta-container mt-3">
          <Button className="edit-button" type="secondary" onClick={onClickCancel}>
            NO
          </Button>
          <Button type="primary" onClick={onClickSend}>
            YES
          </Button>
        </div>
      </div>
      <div className="totum-questionnaire-form-footer-wrapper mt-3">
        <span className="text">powered by</span>
        <span><img alt='' style={{ height: 12 }} src='/assets/images/tifin-risk-logo.svg' /></span>
      </div>
    </Modal>
  )
}

export default {
	ScoreMeter,
  ResendEmailModal,
  ClientDetailModal,
  FullQuestionnaire,
}

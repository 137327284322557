import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, ButtonGroup, Form, FormGroup, Label, Input } from 'reactstrap'
import { PrimaryButton } from 'shared-components'

class DelConfirmModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      closePfUpload: true
    }
  }

  render() {
    const { showDelConfirmModal, delClientDetails, deleteClient, delType, closeDelConfirmationModal } = this.props;

    let confirmationMessage;
    if (delType == 0) {
      confirmationMessage = `${delClientDetails.name ? delClientDetails.name + "'s" : 'this'} account`;
    } else if (delType == 1) {
      confirmationMessage = `the selected accounts`;
    } else {
      confirmationMessage = `all accounts`;
    }

    return (
      <React.Fragment>
        <Modal
          isOpen={showDelConfirmModal}
          toggle={() =>
            closeDelConfirmationModal()
          }
          centered
          keyboard={false}
          backdrop="static"
          className="pf-up-sm-light"
          backdropClassName="pf-up-sm-light-modal-backdrop"
        >
          <ModalBody className="ssf-modal__body sml-modal-wrapper">
            <i className="fal fa-times cp-modal-close" onClick={() => closeDelConfirmationModal()}></i>
            <div className="text-center confirm-text">
              <span>Do you want to delete {confirmationMessage}?</span>
            </div>
            <div className="col-12 cta-wrapper">
              <button type="button" className="ssf-btn-primary btn secondary-btn btn btn-secondary"
                onClick={() => deleteClient(delClientDetails)}><span>YES</span></button>
              <button type="button" className="ssf-btn-primary btn secondary-btn btn btn-secondary"
                onClick={() => closeDelConfirmationModal()}><span>NO</span></button>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default DelConfirmModal;

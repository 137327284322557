import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash'
import { Row, Col, Nav, UncontrolledTooltip } from 'reactstrap';
import { Checkbox, Menu, Dropdown, Radio, Tooltip } from 'antd';

import { uniqValues, arrayToHash } from 'Utils';
import { addEventToAnalytics } from 'Utils';
import { CN  } from 'DataSet';

import { getPortfolioStats, getInvestmentWatchlistStats, getInvestmentPositionStats } from 'selectors/investment';
import { downloadPortfolioReport, resetCachingForInvestment } from 'actions/investment';
import { setPortfolioState, setPortfolioReplace, saveEnhancedTicker, setEnhancementData, setReplacePortfolioToggle, disableEnhanceBtnControlState, resetCachingForPortfolio, resetModelEnhancePortfoliosState } from 'actions/portfolio';

import {
  DownOutlined,
} from '@ant-design/icons';

const PortfolioClass =  [
  {
    label: 'A Class',
    key: 'A Class',
  },
  {
    label: 'Institutional Class',
    key: 'Institutional Class',
  },
  {
    label: 'Retail Class',
    key: 'Retail Class',
  }
];

// const SimilarityMeasures =  [
//   {
//     label: 'Holdings Overlapoooo',
//     key: 'overlap',
//     tooltip: 'Find replacements for funds held that have similar holdings when available, else funds with similar returns'
//   },
//   {
//     label: 'Return Correlation',
//     key: 'correlation',
//     tooltip: 'Find replacements for funds held that have similar returns when available, else funds with similar holdings'
//   },
//   {
//     label: 'Fund Category',
//     key: 'fund_category',
//     tooltip: 'Find replacements for funds held from the same category they belong to'
//   },
//   // {
//   //   label: 'Overlap/Correlation',
//   //   key: 'combination',
//   // }
// ];

class PortfolioFilter extends Component {
  constructor(props) {
    super(props)

    // let enhanceTickerFilter = [];
    // const tickers = this.props.resultSet && this.props.resultSet.length > 0 && _.uniqBy(this.props.resultSet, 'ticker')
    // if (tickers && tickers.length > 0) {
    //   enhanceTickerFilter = []
    //   tickers.map(item => {
    //     enhanceTickerFilter.push(item.ticker)
    //   })
    // }
    // this.props.saveEnhancedTicker(enhanceTickerFilter)

    this.state = {
      showLogin: false,
      setCookie: false,
      enhanceTickerFilter: props.enhanceTickerFilter,
      // oldReplaceTicker:[],
      originalTicker: [],
      tickerDropdownVisible: false,
      portfolioShareClass: props.portfolioShareClass,
      similarityMeasures: props.similarityMeasures,
      hideForOptions: ["low_cost_alternative_models", "low_risk_alternative_models", "high_yield_alternative_models", "high_sharpe_alternative_models"]
    }
  }

  componentDidMount() {
    const { resultSet } = this.props;
    const originalTicker = resultSet.map((item, i) => {
      if (typeof item.result.funds !== 'undefined' && item.result.funds.length) {
        return item.result.funds[0].ticker;
      }
    }).filter((yy) => typeof yy !== 'undefined');

    this.setState({ originalTicker });
  }

  componentDidUpdate() {
    if (document.getElementById('check-label') && document.getElementById('check-label').offsetWidth) {
      const width = document.getElementById('check-label').offsetWidth;
      document.getElementById('enhance-switch').style.left = `${(410 - width)}px`;
    }
  }

  componentWillReceiveProps(newProps) {
    const { enhanceReplace, replacePortfolioToggle, resultSet } = newProps;

    // const newReplaceTicker = enhanceReplace.length > 0 && enhanceReplace.map((i) => {
    //   if (i.ticker !== i.replace) {
    //     return i.replace;
    //   }
    //   // return i.replace
    // });

    const newReplaceTicker = resultSet.map((item, i) => {
      if (typeof item.result.funds !== 'undefined' && item.result.funds.length) {
        const selectedTicker = item.result.funds.find((h) => h._selected)
        if (selectedTicker && selectedTicker.ticker) {
          return selectedTicker.ticker;
        }
        // return item.result.funds[0].ticker;
      }
    }).filter((yy) => typeof yy !== 'undefined');

    if (this.state.originalTicker.length > 0 && newReplaceTicker.length > 0) {
      if ( newReplaceTicker.sort().join() === this.state.originalTicker.sort().join() && replacePortfolioToggle !== true) {
        this.props.setPortfolioState({ replacePortfolioToggle: true });
      } else if (newReplaceTicker.sort().join() !== this.state.originalTicker.sort().join() && replacePortfolioToggle === true) {
        this.props.setPortfolioState({ replacePortfolioToggle: false, replaceAllPortfolioToggle: false });
      }
    }
    if (newProps.portfolioShareClass !== this.state.portfolioShareClass) {
      this.setState({
        portfolioShareClass: newProps.portfolioShareClass,
      });
    }
  }

  componentWillUnmount() {
    // NOTE: this case is written to stop NaN and Undefined issue on Performance impact drawer
    this.props.setPortfolioState({ replacePortfolioToggle: true, replaceAllPortfolioToggle: true });
  }

  setPfEnhancementTicker = (e, ticker) => {
    const { enhanceTickerFilter } = this.state;
    const { saveEnhancedTicker, resultSet, setPortfolioState, evaluateOnChange } = this.props;
    const val = document.getElementById(e.target.id).checked;
    if (val) {
      enhanceTickerFilter.push(ticker)

      let filterResultSet = [];
      if (enhanceTickerFilter.length > 0) {
        filterResultSet =  resultSet.filter(x => enhanceTickerFilter.includes(x.ticker))
      } else filterResultSet = resultSet;

      saveEnhancedTicker(enhanceTickerFilter)
      if (enhanceTickerFilter.length == resultSet.length) setPortfolioState({ replacePortfolioToggle: true })
      this.setState({ enhanceTickerFilter }, () => {
        if (document.getElementById('cat-alternative').checked === true) {
          // this.setEnhancePortfolioReplace()
          if (evaluateOnChange) {
            evaluateOnChange(this.state.enhanceTickerFilter);
          }
        }
      })
    } else {
      const index = enhanceTickerFilter.findIndex(x => x === ticker);
      index >= 0 && enhanceTickerFilter.splice(index,1);

      let filterResultSet = [];
      if (enhanceTickerFilter.length > 0) {
        filterResultSet =  resultSet.filter(x => enhanceTickerFilter.includes(x.ticker))
      } else filterResultSet = resultSet;

      saveEnhancedTicker(enhanceTickerFilter)
      this.setState({ enhanceTickerFilter }, () => {
        // this.setEnhancePortfolioReplace();
        if (evaluateOnChange) {
          evaluateOnChange(this.state.enhanceTickerFilter);
        }
      })
    }

  }

  setEnhancePortfolioReplace = () => {
    const { view, filterResultSet, setPortfolioReplace, portfolioFunds, marketFunds, watchlistFundstats, accountFundstats } = this.props;
    // const resultRadio = filterResultSet.filter(x => x.query.includes('similar') && this.state.enhanceTickerFilter.includes(x.ticker));
    const resultRadio = filterResultSet.filter(x => this.state.enhanceTickerFilter.includes(x.ticker));
    let enhanceReplaceCategory = [], acc = [];
    if (resultRadio.length > 0) {
      enhanceReplaceCategory = resultRadio.filter((item) => {
        let replaceTicker = '';
        if (item.result.funds.length > 0) {
          // console.log(item.result.funds[0].ticker);
          replaceTicker = item.result.funds[0].ticker;

          let pF = {}
          if (view == 'account') {
            pF = accountFundstats.funds.filter((dd) => (dd.script || dd.symbol) === item.ticker)[0];
          } else if (view == 'watchlist') {
            pF = watchlistFundstats.positions.filter((dd) => (dd.script || dd.symbol) === item.ticker)[0];
          } else if (view == 'mrktPl') {
            pF = marketFunds.filter((dd) => (dd.script || dd.symbol) === item.ticker)[0];
          } else {
            pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === item.ticker)[0];
          }

          item.ticker = item.ticker;
          item.replace = replaceTicker;
          item.replaceTicker = replaceTicker;
          item.query = item.query;
          item.selectedPortfolioFunds = {
            ...item.result.funds[0],
            query_var: item.result.query_var || [], // for showing correlation chart
            _pF: pF,
          };
          return item;
        }
        // const replaceTicker = item.result.funds[0].ticker;

        // const pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === item.ticker)[0];
        // let pF = {}
        // if (view == 'account') {
        //   pF = accountFundstats.funds.filter((dd) => (dd.script || dd.symbol) === item.ticker)[0];
        // } else if (view == 'watchlist') {
        //   pF = watchlistFundstats.positions.filter((dd) => (dd.script || dd.symbol) === item.ticker)[0];
        // } else if (view == 'mrktPl') {
        //   pF = marketFunds.filter((dd) => (dd.script || dd.symbol) === item.ticker)[0];
        // } else {
        //   pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === item.ticker)[0];
        // }
        //
        // acc.push({ ticker: item.ticker, replace: replaceTicker, query: item.query, selectedPortfolioFunds: {
        //   ...item.result.funds[0],
        //   _pF: pF,
        // } });
        // return acc;
      }, [])
    }
    console.log(enhanceReplaceCategory);
    // const enhanceReplaceCategory =  resultRadio.length > 0 && resultRadio.reduce((acc, item) => {
    //   console.log(acc);
    //   console.log(item.result.funds);
    //   let replaceTicker = '';
    //   if (item.result.funds.length > 0 && typeof acc !== 'undefined') {
    //     console.log(item.result.funds[0].ticker);
    //     replaceTicker = item.result.funds[0].ticker;
    //
    //     let pF = {}
    //     if (view == 'account') {
    //       pF = accountFundstats.funds.filter((dd) => (dd.script || dd.symbol) === item.ticker)[0];
    //     } else if (view == 'watchlist') {
    //       pF = watchlistFundstats.positions.filter((dd) => (dd.script || dd.symbol) === item.ticker)[0];
    //     } else if (view == 'mrktPl') {
    //       pF = marketFunds.filter((dd) => (dd.script || dd.symbol) === item.ticker)[0];
    //     } else {
    //       pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === item.ticker)[0];
    //     }
    //
    //     acc.push({ ticker: item.ticker, replace: replaceTicker, query: item.query, selectedPortfolioFunds: {
    //       ...item.result.funds[0],
    //       _pF: pF,
    //     } });
    //     return acc;
    //   }
    //   // const replaceTicker = item.result.funds[0].ticker;
    //
    //   // const pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === item.ticker)[0];
    //   // let pF = {}
    //   // if (view == 'account') {
    //   //   pF = accountFundstats.funds.filter((dd) => (dd.script || dd.symbol) === item.ticker)[0];
    //   // } else if (view == 'watchlist') {
    //   //   pF = watchlistFundstats.positions.filter((dd) => (dd.script || dd.symbol) === item.ticker)[0];
    //   // } else if (view == 'mrktPl') {
    //   //   pF = marketFunds.filter((dd) => (dd.script || dd.symbol) === item.ticker)[0];
    //   // } else {
    //   //   pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === item.ticker)[0];
    //   // }
    //   //
    //   // acc.push({ ticker: item.ticker, replace: replaceTicker, query: item.query, selectedPortfolioFunds: {
    //   //   ...item.result.funds[0],
    //   //   _pF: pF,
    //   // } });
    //   // return acc;
    // }, [])
    enhanceReplaceCategory ? setPortfolioReplace(enhanceReplaceCategory) : setPortfolioReplace([]);
  }

  setAllPfEnhancementTicker = (e) => {
    const val = document.getElementById(e.target.id).checked;
    const { saveEnhancedTicker, resultSet, setPortfolioState, enhanceReplace, evaluateOnChange } = this.props;
    const newReplaceTicker = enhanceReplace.map((i) => {
      return i.replace
    });
    let enhanceTickerFilter = [], filterResultSet = []
    const tickers = this.props.resultSet && this.props.resultSet.length > 0 && _.uniqBy(this.props.resultSet, 'ticker')
    if (val) {
      if (tickers && tickers.length > 0) {
        enhanceTickerFilter = []
        tickers.map(item => {
          enhanceTickerFilter.push(item.ticker)
        })
        if (enhanceTickerFilter.length > 0) {
          filterResultSet =  resultSet.filter(x => enhanceTickerFilter.includes(x.ticker))
        }
        this.setState({ enhanceTickerFilter }, () => {
          saveEnhancedTicker(enhanceTickerFilter);
          setPortfolioState({ replacePortfolioToggle: true });
          if (evaluateOnChange) {
            evaluateOnChange(this.state.enhanceTickerFilter);
          }
        } );
      }
    } else {
      this.setState({ enhanceTickerFilter: [] }, () => {
        saveEnhancedTicker([])
        if (evaluateOnChange) {
          evaluateOnChange([]);
        }
      });
    }
  }

  setPfEnhancementCategory = (e, category) => {
    const val = document.getElementById(e.target.id).checked;
    const { setPortfolioState, toggleChange } = this.props;
    addEventToAnalytics('Enhancer Replace All','Enhancer Replace All','ENHANCER_REPLACE_ALL',{},false);
    if (val) {
      setPortfolioState({ replacePortfolioToggle: true })
      if (toggleChange) {
        toggleChange(true);
      }
    } else {
      setPortfolioState({ replacePortfolioToggle: false, enhanceReplace: [], replaceAllPortfolioToggle: true })
      if (toggleChange) {
        toggleChange(false);
      }
    }

  }

  title = () => {
    switch(this.props.query) {
      case 'similar':
      return 'Replace All Funds With Lowest Cost Alternatives'

      case 'outperform':
      return 'Replace All Funds With Best Performing Options In Category'

      case 'safe_alternative':
      return 'Replace All Funds With Lowest Risk Alternatives'

      case 'high_yield_alternative':
      return 'Replace All Funds With Highest Yield Alternatives'

      case 'long_track_record_alternatives':
      return 'Replace All Funds With Long Track Record Alternatives'

      case 'lowest_tracking_error_option':
      return 'Replace All Funds With Lowest Tracking Error Options'

      case 'high_sharpe_alternative':
      return 'Replace All Funds With Highest Risk Adjusted Return Alternatives'

      case 'user_criteria':
      return 'Replace All Funds With Best Alternatives Using My Criteria'

      default:
      return `Replace All Funds with ${this.props.query}`
    }
  }

  handleMenuClick = () => {
    this.setState({
      tickerDropdownVisible: !this.state.tickerDropdownVisible,
    })
  }

  selectPortfolioClassOverlay = (data) => {
    if (data && data.key && data.key !== this.state.portfolioShareClass) {
      this.setState({
        portfolioShareClass: data.key,
      }, () => {
        const { shareClassCallback } = this.props;
        this.searchAgain(this.state.portfolioShareClass, this.state.similarityMeasures);
      })
    }
  }

  // similarityMeasuresOverlay = (data) => {
  //   console.log(data)
  //   if (data && data.key && data.key !== this.state.similarityMeasures) {
  //     this.setState({
  //       similarityMeasures: data.key,
  //     }, () => {
  //       const { shareClassCallback } = this.props;
  //       addEventToAnalytics('Enhancer Similarity Measures','Enhancer Similarity Measures','ENHANCE_SIMILARITY_MEASURES',{measure: this.state.similarityMeasures},false);
  //       this.searchAgain('', this.state.similarityMeasures);
  //     })
  //   }
  // }

  searchAgain = (mfClassType, similarityMeasures) => {
    const {
      query,
      investmentAllStates,
      portfolioCheck,
      enhanceCTA,
      setPortfolioState,
      onChangeCheckbox,
      disableEnhanceBtnControlState,
      resetCachingForInvestment,
      resetCachingForPortfolio,
      setInvestmentState,
      setPfEnhancement,
      enhancePortfolioVisible,
      resetModelEnhancePortfoliosState,
    } = this.props;

    if (
      investmentAllStates.caching &&
      typeof investmentAllStates.caching.portfolioFunds !== 'undefined'
    ) {
      if (!portfolioCheck && enhanceCTA === 'Finalize') {
        const cachedData = investmentAllStates.caching
        const usedStateList = [
          'portfolioLoading',
          'reportLoader',
          'droppedFund',
          'triggerInvestment',
          'checkPurchaseValues',
          'uploadfileDuplicate',
          'uploadFileFailure',
          'uploadfileIncomplete',
          'uploadFilePartial',
          'setPfEnhancementContent'
        ]
        usedStateList.forEach((uu, i) => {
          if (typeof cachedData[uu] !== 'undefined') {
            delete cachedData[uu]
          }
        })
        setInvestmentState({
          ...cachedData
        })
        resetCachingForInvestment()
        resetCachingForPortfolio()
      }
    }

    if (resetModelEnhancePortfoliosState) {
      resetModelEnhancePortfoliosState();
    }

    if (mfClassType !== '') {
      setPortfolioState({
        downloadQuery: '',
        enhancedPortfolioName: '',
        replacePortfolioToggle: true,
        enhanceReplace: [],
        enhanceTickerFilter: [],
        filterResultSet: [],
        resultSet: [],
        shareClass: mfClassType,
        similarityMeasures,
      })
    } else {
      setPortfolioState({
        downloadQuery: '',
        enhancedPortfolioName: '',
        replacePortfolioToggle: true,
        enhanceReplace: [],
        enhanceTickerFilter: [],
        filterResultSet: [],
        resultSet: [],
        similarityMeasures,
      })
    }

    setPfEnhancement()
    disableEnhanceBtnControlState()

  }

  render() {
    const { query, fundStats, resultSet, replacePortfolioToggle, loading, valid, setPfEnhancementContent, enhanceTickerFilter, enhancePortfolioVisible, fundUniverse } = this.props;
    const { tickerDropdownVisible, hideForOptions } = this.state;
    const tickers = uniqValues(fundStats.funds, 'symbol').join(',');

    const portfolioTickers = (
      <div className="pf-enhace-option pf-enhance-tick">
        {
          resultSet && resultSet.length > 0 && _.uniqBy(resultSet, 'ticker').map((item, i) => {
            return (
              <Checkbox key={i} id={item.ticker} value={item.ticker} onChange={(e) => this.setPfEnhancementTicker(e, item.ticker)} checked={this.state.enhanceTickerFilter.includes(item.ticker)}>{item.ticker} </Checkbox>
            )
          })
        }
      </div>
    );

    const PortfolioClassOverlay = (
      <Menu onClick={this.selectPortfolioClassOverlay} selectedKeys={[this.state.portfolioShareClass]}>
        {
          PortfolioClass.map((j) => (
            <Menu.Item key={j.key}>
              {j.label}
            </Menu.Item>
          ))
        }
      </Menu>
    );

    // const SimilarityMeasuresOverlay = (
    //   <Menu onClick={this.similarityMeasuresOverlay} selectedKeys={[this.state.similarityMeasures]}>
    //     {
    //       SimilarityMeasures.map((j) => (
    //         <Menu.Item key={j.key}>
    //           {j.label}
    //           <Tooltip placement='right' title={j.tooltip}>
    //             <span id={`info-icon-${j.key}`} className='info-icon'><i className="fal fa-info-circle"></i></span>
    //           </Tooltip> <Radio checked={this.state.similarityMeasures === j.key} />
    //           {/* <UncontrolledTooltip className="upl-tool-info" placement="bottom" target={`info-icon-${j.key}`}>
    //           {j.tooltip}
    //           </UncontrolledTooltip> */}
    //         </Menu.Item>
    //       ))
    //     }
    //   </Menu>
    // );

    // console.log('enhancePortfolioVisible -------->>>>', enhancePortfolioVisible);

    return (
      !enhancePortfolioVisible && (
        <div className="prt-cat-coll-wrapper">
          <Checkbox
            id='all-ticker'
            disabled={enhancePortfolioVisible}
            onChange={(e) => this.setAllPfEnhancementTicker(e)}
            defaultChecked={true}
            checked={this.props.resultSet.length ===
            this.state.enhanceTickerFilter.length}/>
          <Dropdown
            disabled={enhancePortfolioVisible}
            overlayClassName="portfolioMenuListWrapper"
            overlay={portfolioTickers}
            onVisibleChange={this.handleMenuClick}
            visible={tickerDropdownVisible}
            trigger={['click']}>
            <a className="sub-head" style={{ paddingRight: 30, paddingTop: 4 }}>
              Evaluate All Funds <DownOutlined
              className={enhancePortfolioVisible
                ? 'down-fund-arrow top-59-per'
                : 'down-fund-arrow'}/>
            </a>
          </Dropdown>
        </div>
      )
    )
    }
  }

  const mapStateToProps = state => ({
    investmentAllStates: state.investment,
    view: state.investment.view,
    query: state.portfolio.query,
    replacePortfolioToggle: state.portfolio.replacePortfolioToggle,
    loading: state.investment.loading,
    valid: state.investment.valid,
    setPfEnhancementContent: state.investment.setPfEnhancementContent,
    portfolioFunds: state.investment.portfolioFunds,
    marketFunds: state.investment.marketFunds,
    resultSet: state.portfolio.resultSet,
    filterResultSet: state.portfolio.filterResultSet,
    fundStats: getPortfolioStats(state),
    enhanceReplace:state.portfolio.enhanceReplace,
    watchlistFundstats: getInvestmentWatchlistStats(state),
    accountFundstats: getInvestmentPositionStats(state),
    enhancePortfolioVisible: state.portfolio.enhancePortfolioVisible,
    portfolioCheck: state.portfolio.portfolioCheck,
    enhanceCTA: state.portfolio.enhanceCTA,
    portfolioShareClass: state.portfolio.shareClass,
    fundUniverse: state.portfolio.fundUniverse,
    enhanceTickerFilter: state.portfolio.enhanceTickerFilter,
    similarityMeasures: state.portfolio.similarityMeasures,
  });

  const mapDispatchToProps = {
    setPortfolioState,
    setPortfolioReplace,
    saveEnhancedTicker,
    setEnhancementData,
    setReplacePortfolioToggle,
    disableEnhanceBtnControlState,
    resetCachingForInvestment,
    resetCachingForPortfolio,
    resetModelEnhancePortfoliosState,
  }

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(PortfolioFilter))

import React, { Component, Fragment } from 'react'
import Brokers from './Brokers/Brokers'
import Profile from './Profile/Profile'
import Subscription from './Subscription/Subscription'
import Branding from './Branding/Branding'
import TradingAccount from "./TradingAccount/TradingAccount";
import Notifications from "./Alert/Alert";
import {
  getFileName,
  getInvestmentPositionStats,
  getInvestmentWatchlistStats,
  getMarketStats,
  getPortAccountStats,
} from 'selectors/investment'
import { getPortfolioEnhanceReplace } from 'selectors/portfolio'
import { createLoadingSelector } from 'selectors/loading-selector'
import { logout } from 'actions/auth'
import { isEmpty, get } from 'lodash'
import style from './Settings.module.scss'
import { refreshFundsState, searchQuery, setFundsState } from 'actions/funds'
import { clearFundsApiLoadPercent, setGlobalState } from 'actions/global'
import {
  downloadPortfolioReport,
  fetchMarketData,
  fetchPortfolioData,
  investmentFetch,
  investmentFetchSuccess,
  investmentOptions,
  investmentOptionsRedirect,
  resetInvestmentState,
  setInvestmentState,
} from 'actions/investment'
import {
  finalizeReplaceModelPortfolioRequest,
  resetPortfolioState,
  saveAccountEnhancedOption,
  saveEnhancedOption,
  saveEnhanceProposalPortfolio,
  setEnhancedTransaction,
  setPortfolioReplace,
  setPortfolioState,
} from 'actions/portfolio'
import { changeCalculator } from 'actions/weights'
import { subscriptionPlan, changeFname, changeLname, toggleEdit, setUpdate } from 'actions/profile'
import { connect } from 'react-redux'
import { Alert, Col, Container, Nav, Row, Input, TabContent, TabPane, NavItem, NavLink as TabNav } from 'reactstrap'
import { Link, NavLink, Redirect, Switch } from 'react-router-dom'
import { PrivateRoute } from 'shared-components/'
import { getApexAccountStatus } from 'apis/register'
import { RETAIL_USERS_ROLES } from '../Constant/FormData';
import { Layout } from 'antd';
import { InnerPageFooter } from 'shared-components/'

const { Footer } = Layout;
const REASONS = {
  UPDATED_ROUTING_NUMBER_BY_NOC: 'Your routing number has been updated.'
}
class Settings extends Component {
  state = {
    showSuspendedAccount: null,
    accountStatus: '',
    editInput: false,
    apexStatus: null,
    activeTab: '1'
    // f_name               : "",
    // l_name               :""
  }

  async componentDidMount() {
    this.props.subscriptionPlan()
    document.body.classList.add('newProfile')
    this.forceUpdate()
  }
  componentWillUnmount() {
    document.body.classList.remove('newProfile')
  }

  toggleEdit = () => {
    this.props.toggleEdit()
    const data = {
      fname: this.props.firstName,
      lname: this.props.lastName,
    }
    this.props.setUpdate(data)
  }

  handleChangeFname = (e) => {
    this.props.changeFname(e.target.value)
  }
  handleChangeLname = (e) => {
    this.props.changeLname(e.target.value)
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    let apex = nextProps.apex
    // console.log(apex)
    let apexStatus
    let showSuspendedAccount = null;

    if (!isEmpty(apex) && apex.account && apex.account.length) {
      this.setState({
        accountStatus: apex.account[0].status
      })
      if (apex.account[0].status === 'SUSPENDED' || apex.account[0].status === 'ACTION_REQUIRED') {
        try {
          apexStatus = await getApexAccountStatus(apex.account[0].id)
          apexStatus = apexStatus.result
          this.setState({
            apexStatus,
          });
          // let ids = [];
          // if(!isEmpty(apexStatus.investigations)){
          //   ids = apexStatus.investigations.map((invest) => invest.sketchId.id);
          // }
          // apex.snaps && apex.snaps.forEach(snap=>{
          //   if(ids.includes(snap.sketch_id) && snap.status === 'rejected'){
          //     showSuspendedAccount = (<Alert color="danger">
          //         <div>Your account is rejected. Uploaded documents are under review.</div></Alert>);
          //   }
          // });

          !isEmpty(apexStatus.investigations) && (apexStatus.status === 'SUSPENDED' || apexStatus.status === 'ACTION_REQUIRED') && apexStatus.investigations.forEach(investigation => {
            if (!showSuspendedAccount) {
              !isEmpty(investigation.sketchId) && investigation.sketchId.cipCategories.length && investigation.sketchId.cipCategories.forEach(cat => {
                if (cat.categoryState === 'REJECTED' && cat.requestedDocuments.length > 0) {
                  showSuspendedAccount = (<Alert color="danger">
                    <div>Your account is rejected. Please <a style={{ color: `#721c24`, textDecoration: "underline" }} href="/trading/review">click here </a>to upload documents for review </div>
                    {/* <a style={{color:`#721c24`}} href="/trading/review">Your account is rejected. Please click here review.</a> */}
                  </Alert>);
                }
              })
            }
          })
          this.setState({
            showSuspendedAccount
          })
        } catch (e) {
          console.log(e)
        }
      }
    }
  }

  _getACHReason = (apex) => {
    if (apex && apex.ach && apex.ach.length) {
      let filtered = apex.ach.filter((val) => val.reason.includes("_NOC"));
      if (filtered.length) {
        return <Alert color="warning">
          <div>{REASONS[filtered[0].reason] ? REASONS[filtered[0].reason] : filtered[0].reason}</div>
        </Alert>
      } else {
        return null;
      }
    }
    return null;
  }

  _getDipositeReasonWarning = (apex) => {
    if (apex && apex.deposits && apex.deposits.length) {
      let filtered = apex.deposits.filter((val) => val.status === 'Returned');
      if (filtered.length) {
        return <Alert color="warning">
          <div>{REASONS[filtered[0].reason] ? REASONS[filtered[0].reason] : filtered[0].reason}</div>
        </Alert>
      } else {
        return null;
      }
    }
    return null;
  }

  render() {
    const { firstName, lastName, apex, location, f_name, l_name, isEdit } = this.props
    const { accountStatus, showSuspendedAccount, editInput, apexStatus } = this.state
    const profile_self_role = window.localStorage.getItem('profile_self_role') ? window.localStorage.getItem('profile_self_role') : 'loggedOut';
    const mailTo = process.env.REACT_APP_SUB_DOMAIN === 'retail' ? 'tradingaccountsupport@magnifi.com' : 'support@magnifi.com';
    return (
      <>
        <div className="homeWrapper" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", minHeight: "calc(100vh - 60px)" }}>
          <div id="settingsContainer" className={style.container}>
            <div className={style.leftNavigation}>
              <NavLink
                to="/settings/profile"
                activeClassName={style.active}
                className={`${style.menuItem}`}
              >
                Profile
              </NavLink>
              {!RETAIL_USERS_ROLES.includes(profile_self_role) && <NavLink
                to="/settings/broker"
                activeClassName={style.active}
                className={`${style.menuItem}`}
              >
                Brokers
              </NavLink>}

              {process.env.REACT_APP_SUB_DOMAIN === 'advisor' && <NavLink
                to="/settings/subscriptionPlan"
                activeClassName={style.active}
                className={`${style.menuItem}`}
              >
                Subscription
              </NavLink>}
              {process.env.REACT_APP_SUB_DOMAIN === 'advisor' && <NavLink
                to="/settings/proposals"
                activeClassName={style.active}
                className={`${style.menuItem}`}
              >
                Proposals
              </NavLink>}
              {RETAIL_USERS_ROLES.includes(profile_self_role) && <NavLink
                to="/settings/tradingAccount"
                activeClassName={style.active}
                className={`${style.menuItem}`}
              >
                Investment Account
              </NavLink>}
              {RETAIL_USERS_ROLES.includes(profile_self_role) && <NavLink
                to="/settings/alert"
                activeClassName={style.active}
                className={`${style.menuItem}`}
              >
                Alerts
              </NavLink>}
            </div>
            <div className={`${style.rightPages} ${location.pathname == "/settings/profile" && get(apex, 'account[0].is_active') === false ? style.inActiveSettings : ""}`}>
              {/* {location.pathname == "/settings/tradingAccount" && showSuspendedAccount} */}
              {location.pathname == "/settings/tradingAccount" && this._getACHReason(apex)}
              {location.pathname == "/settings/tradingAccount" && this._getDipositeReasonWarning(apex)}
              {location.pathname == "/settings/tradingAccount" && (apexStatus && apexStatus.status === 'SUSPENDED' && apexStatus.investigations && apexStatus.investigations[0].sketchId.result && apexStatus.investigations[0].sketchId.result.equifaxResult) && <Fragment>
                {apexStatus.investigations[0].sketchId.result.equifaxResult.results.reject &&
                  <Alert color="danger">
                    <b>Reasons for rejection:</b>
                    <Nav tabs className="reject_tabs_container" style={{ marginTop: 10 }}>
                      {apexStatus.investigations[1] && apexStatus.investigations[1].sketchId.result && apexStatus.investigations[1].sketchId.result.equifaxResult && apexStatus.investigations[1].sketchId.result.equifaxResult.results.reject && Object.keys(apexStatus.investigations[1].sketchId.result.equifaxResult.results.reject).length ? <NavItem>
                        <TabNav
                          className={this.state.activeTab === '1' ? 'active reject_tabs' : 'reject_tabs'}
                          onClick={() => { this.setState({ activeTab: '1' }) }}
                        >
                          {this.state.activeTab === '1' ? <b>Primary</b> : 'Primary'}
                        </TabNav>
                      </NavItem>:null}
                      {apexStatus.investigations[1] && apexStatus.investigations[1].sketchId.result && apexStatus.investigations[1].sketchId.result.equifaxResult && apexStatus.investigations[1].sketchId.result.equifaxResult.results.reject && Object.keys(apexStatus.investigations[1].sketchId.result.equifaxResult.results.reject).length ? <NavItem>
                        <TabNav
                          className={this.state.activeTab === '2' ? 'active reject_tabs' : 'reject_tabs'}
                          onClick={() => { this.setState({ activeTab: '2' }) }}
                        >
                          {this.state.activeTab === '2' ? <b>Secondary</b> : 'Secondary'}
                        </TabNav>
                      </NavItem>:null}
                    </Nav>
                    <TabContent activeTab={this.state.activeTab} style={{ padding: 10 }}>
                      <TabPane tabId="1">
                          {apexStatus.investigations[0].sketchId.result.equifaxResult.results.reject && Object.keys(apexStatus.investigations[0].sketchId.result.equifaxResult.results.reject).length ? Object.keys(apexStatus.investigations[0].sketchId.result.equifaxResult.results.reject).map(key => <p>{apexStatus.investigations[0].sketchId.result.equifaxResult.results.reject[key]}</p>) : " Not available"}
                      </TabPane>
                      {apexStatus.investigations[1] && apexStatus.investigations[1].sketchId.result && apexStatus.investigations[1].sketchId.result.equifaxResult && apexStatus.investigations[1].sketchId.result.equifaxResult.results.reject && Object.keys(apexStatus.investigations[1].sketchId.result.equifaxResult.results.reject).length ? <TabPane tabId="2">
                        {apexStatus.investigations[1].sketchId.result.equifaxResult.results.reject && Object.keys(apexStatus.investigations[1].sketchId.result.equifaxResult.results.reject).length ? Object.keys(apexStatus.investigations[1].sketchId.result.equifaxResult.results.reject).map(key => <p>{apexStatus.investigations[1].sketchId.result.equifaxResult.results.reject[key]}</p>) : " Not available"}
                      </TabPane>:null}
                    </TabContent>
                  </Alert>}
              </Fragment>}

              <div className='d-flex justify-content-between align-item-center'>
                {/* {location.pathname == "/settings/profile" ?
                  [!isEdit && !!firstName && !!lastName ?
                    <div className={style.nameEditBox}>
                      <span className={style.key}>Name </span>
                      {(!!firstName && !!lastName)
                        ? (
                          <div className={style.name}>
                            <span className={style.name}>{firstName} {lastName}</span>
                            <i className="fas fa-pencil" onClick = {()=>this.toggleEdit()}></i>
                          </div>
                        )
                        : (
                          <span className={style.name} style={{display:"flex" , width:'80%'}}>
                            <Input
                              type="text"
                              className={style.editInput}
                              placeholder={'Name'}
                              name="f_name"
                              id="f_name"
                              value={f_name}
                              onChange={(e)=>this.handleChangeFname(e)}
                            />
                            <Input
                              className={style.editInput}
                              type="text"
                              placeholder={'Surname'}
                              name="l_name"
                              id="l_name"
                              value={l_name}
                              onChange={(e)=>this.handleChangeLname(e)}
                            />
                            <i className="fas fa-times" onClick = {()=>this.toggleEdit()}></i>
                          </span>
                        )
                      }
                    </div>
                    :
                    <div className={style.nameEditBox}>
                      <span className={style.key}>Name </span>
                      <span className={style.name} style={{display:"flex" , width:'80%'}}>
                          <Input
                          type="text"
                          className={style.editInput}
                          placeholder={'FirstName'}
                          name="f_name"
                          id="f_name"
                          value={f_name}
                          onChange={(e)=>this.handleChangeFname(e)}
                          />
                          <Input
                          className={style.editInput}
                          type="text"
                          placeholder={'LastName'}
                          name="l_name"
                          id="l_name"
                          value={l_name}
                          onChange={(e)=>this.handleChangeLname(e)}
                          />
                          <i class="far fa-times" onClick={()=>this.toggleEdit()}></i>
                      </span>
                    </div>
                ]
                : */}
                {location.pathname !== "/settings/profile" && accountStatus && <span className={style.name}>
                  {firstName} {lastName}
                </span>}
                {/* } */}
                {location.pathname == "/settings/tradingAccount" && accountStatus && <div>
                  <span style={{ fontSize: `12px`, opacity: 0.5 }}>Status</span>
                  <p className={style.accStatus}>
                    {(accountStatus === 'COMPLETE' && apex.account[0].is_active) ? 'Active' : ((accountStatus === 'COMPLETE' && !apex.account[0].is_active) || (accountStatus === 'BACK_OFFICE') || (accountStatus === 'ACTION_REQUIRED')) ? 'Pending' : accountStatus}</p>
                </div>}
              </div>
              <Switch>
                <PrivateRoute path="/settings/profile" component={Profile} />
                <PrivateRoute path="/settings/broker" component={Brokers} />
                <PrivateRoute path="/settings/proposals" component={Branding} />
                <PrivateRoute path="/settings/tradingAccount" component={TradingAccount} />
                <PrivateRoute path="/settings/alert" component={Notifications} />
                {/* <PrivateRoute
                        path="/settings/weights"
                        component={WeightsContainer}
                      /> */}
                {/*<PrivateRoute*/}
                {/*  path="/settings/referral"*/}
                {/*  component={ReferralCodeContainer}*/}
                {/*/>*/}
                {/*<Redirect from="/settings" exact to="/settings/profile" />*/}
                <PrivateRoute
                  path="/settings/subscriptionPlan"
                  component={Subscription}
                />
              </Switch>
            </div>
          </div>
          <InnerPageFooter style={{ width: "100%", background: "inherit", borderTop: "1px solid #d5cbcb", textAlign: "center", fontFamily: "Open Sans", fontSize: "12px", padding: ".4em", zIndex: 1000 }} />

          {/* <Footer className={style.footerContainer}>
            <img src="/assets/images/SupportIcon.svg" />
            <span>
              Get In Touch With Us @
              <a id="mail-to-magnifi" href={`mailto:${mailTo}`}>
                {mailTo}
              </a>
            </span>
          </Footer> */}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  buildportfolio: state.buildportfolio,
  investment: state.investment,
  portfolio: state.portfolio,
  view: state.investment.view,
  investmentFunds: state.investment.funds,
  funds: state.funds,
  query: state.funds.query,
  loadPercent: state.global.fundsApiCallPercent,
  surveyModal: state.global.surveyModal,
  sfmToggle: state.sfm.modalToggleState,
  modalToggleState: state.sfm.modalToggleState,
  location: state.router.location,
  isTermsAgreed: state.auth.termsAgreed,
  isNewUser: state.auth.newUser,
  loggedIn: state.auth.loggedIn,
  planName: state.auth.user.planName,
  loggingIn: state.auth.loggingIn,
  accConfirmedModal: state.auth.accConfirmedModal,
  registerSuccess: state.register.registerSuccess,
  useremail: state.auth.user.email,
  firstName: state.profile.profile.first_name,
  lastName: state.profile.profile.last_name,
  portfolioLoading:
    state.investment.portfolioLoading || state.investment.reportLoader,
  setPfEnhancementContent: state.investment.setPfEnhancementContent,
  reportLoader: state.investment.reportLoader || state.investment.loading,
  summaryDrawer: state.portfolio.summaryDrawer,
  onBoardScreen: state.portfolio.onBoardScreen,
  resultSet: state.portfolio.resultSet,
  enhancedPortfolioName: state.portfolio.enhancedPortfolioName,
  enhancedPortfolioId: state.portfolio.enhancedPortfolioId,
  portfolioSave: state.portfolio.portfolioSave,
  portfolioSaveError: state.portfolio.portfolioSaveError,
  portfolioFunds: state.investment.portfolioFunds,
  marketFunds: state.investment.marketFunds,
  filename: getFileName(state), // state.investment.filename,
  newFileName: state.investment.filename,
  enableEnhanceBtnControl: state.portfolio.enableEnhanceBtnControl,
  fundStats: getPortAccountStats(state.investment),
  portfolioEnhanceReplace: getPortfolioEnhanceReplace(state), // state.portfolio.enhanceReplace,
  downloadQuery: state.portfolio.downloadQuery,
  portfolioQuery: state.portfolio.query,
  watchlistFundstats: getInvestmentWatchlistStats(state),
  accountFundstats: getInvestmentPositionStats(state),
  marketFundStats: getMarketStats(state),
  activeAccount: state.investment.activeAccount,
  letPortfolioLoad:
    state.portfolio.loading ||
    createLoadingSelector([
      'GET_MY_CURRENT_PORTFOLIO',
      'GET_REPLACE_PORTFOLIO_OPTIONS',
      'FINALIZE_REPLACE_MODEL_PORTFOLIO',
    ])({ loading: state.loading }),
  oldViewBeforeUpload: state.investment.oldViewBeforeUpload,
  enableEnhancePortfolioQueries: state.portfolio.enableEnhancePortfolioQueries,
  fundSelector: state.weights,
  redirectFrom: state.investment.redirectFrom,
  selectorCount: state.sfm.selectorCount,
  enhancePortfolioVisible: state.portfolio.enhancePortfolioVisible,
  enhanceModelPortfolio: state.portfolio.enhancePortfolio,
  investmentFetchLoading: createLoadingSelector(['INVESTMENT_FETCH'])({
    loading: state.loading,
  }),
  hideGenerateProposalBtn: state.portfolio.hideGenerateProposalBtn,
  logo: state.profile.logo,
  apex: state.profile.profile.apex,
  f_name: state.profile.update.fname,
  l_name: state.profile.update.lname,
  isEdit: state.profile.update.isEdit
})

const mapDispatchToProps = {
  logoutHandler: logout,
  setFundsState,
  refreshFundsState,
  setGlobalState,
  investmentFetch,
  investmentFetchSuccess,
  setPortfolioState,
  setEnhancedTransaction,
  saveEnhanceProposalPortfolio,
  downloadPortfolioReport,
  clearFundsApiLoadPercent,
  searchQuery,
  saveEnhancedOption,
  saveAccountEnhancedOption,
  setInvestmentState,
  changeCalculator,
  subscriptionPlan,
  investmentOptions,
  investmentOptionsRedirect,
  setPortfolioReplace,
  fetchPortfolioData,
  fetchMarketData,
  finalizeReplaceModelPortfolioRequest,
  resetInvestmentState,
  resetPortfolioState,
  changeFname,
  changeLname,
  toggleEdit,
  setUpdate
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)



// <span className={style.name}>
//   {firstName} {lastName}
// </span>
// <Switch>
//   <PrivateRoute path="/settings/profile" component={Profile} />
//   <PrivateRoute path="/settings/broker" component={Brokers} />
//   <PrivateRoute path="/settings/proposals" component={Branding} />
//   {/* <PrivateRoute
//         path="/settings/weights"
//         component={WeightsContainer}
//       /> */}
//   {/*<PrivateRoute*/}
//   {/*  path="/settings/referral"*/}
//   {/*  component={ReferralCodeContainer}*/}
//   {/*/>*/}
//   {/*<Redirect from="/settings" exact to="/settings/profile" />*/}
//   <PrivateRoute
//     path="/settings/subscriptionPlan"
//     component={Subscription}
//   />
// </Switch>

import { get, isEmpty, find } from 'lodash'
import ActionTypes from '../action-types'
import { ScoreAttrs, AdditionalScoreAttrs, ActiveCalcScoreAttrs, calculatorOptions } from '../DataSet'

const defaultMagifiCalcWeights = {
  yRet: 50,
  risk: 35,
  fees: 15
}

const magnifiCalculatorAttrs = ScoreAttrs.map(attr => ({
  ...attr,
  weight: defaultMagifiCalcWeights[attr.col]
}))

const initialState = {
  weightScore: [],
  loading: false,
  weightSuccess: false,
  error: '',
  scoreAttrs: [],
  // defaultScoreAttrs: magnifiCalculatorAttrs,
  defaultScoreAttrs: [],
  // myScoreAttrs: [...ScoreAttrs, ...AdditionalScoreAttrs],
  myScoreAttrs: [],
  // mycalculatorValues: [...ScoreAttrs, ...AdditionalScoreAttrs],
  mycalculatorValues: [],
  // customCalculatorAttrs: [...ScoreAttrs, ...AdditionalScoreAttrs, ...ActiveCalcScoreAttrs],
  customCalculatorAttrs: [],
  activeCalcScoreAttrs: [],
  selectedCalculator: calculatorOptions.magnificalculator,
  customSelectorName: '',
  customCalculators: {},
  allCalculators: [],
  defaultCalculator: 'Magnifi fund selector',
}

const sortScoreAttrs = (scoreAttrs, weightScore, isSetWeigthDate) => {
  const finalAttrs = []
  scoreAttrs.map(attr => {
    const weights = find(weightScore, { weight_name: attr.code })
    const weightValue = get(weights, 'weight_value')
    // isLocked variable is only used during the SET INITIAL WEIGHTS action
    const isLocked = weights && typeof weights.lock === 'boolean' ? weights.lock : false;    
    const weight =
      isSetWeigthDate && typeof weightValue === 'number'
        ? weightValue
        : attr.weight

    if(weights && weights.screener){
      attr.screener = weights.screener;
    }

    finalAttrs.push({
      ...attr,
      weight: attr.weight || weightValue || 0,
      lock: attr && typeof attr.lock === 'boolean' ? attr.lock : isLocked,
    })
  })

  return finalAttrs.sort((a, b) => {
    const aValue = parseFloat(a.weight)
    const bValue = parseFloat(b.weight)
    if (aValue < bValue) {
      return 1
    }
    if (aValue > bValue) {
      return -1
    }
    return 0
  })
}

const getWeightScoreForScreener = (scoreAttrs) => {
  const finalAttrs = [];
  scoreAttrs.map(attr => {
    finalAttrs.push({
      weight_name: attr.name,
      weight_value: attr.weight,
      screener: attr.screener,
    })
  })
  return finalAttrs;
}

export function weights(state = initialState, action) {
  const { payload } = action
  const availableCalculators = Object.values(calculatorOptions);
  switch (action.type) {
    case ActionTypes.REFRESH_WEIGHT_STATE:
      return {
        ...state,
        weightScore: [],
        loading: false,
        scoreAttrs: state.scoreAttrs || []
      }

    case ActionTypes.SET_SCORE_ATTRS:
      return{
        ...state,
        scoreAttrs: payload,
        activeCalcScoreAttrs: payload,
        weightScore: {
          ...state.weightScore,
          [state.selectedCalculator]: getWeightScoreForScreener(payload),
        },
      }

    case ActionTypes.SET_CUSTOM_SELECTOR_NAME:
      return{
        ...state,
        customSelectorName: payload,
      }

    case ActionTypes.UPDATE_CUSTOM_CALCULATOR_LIST:
      return{
        ...state,
        customCalculators: payload,
      }

    case ActionTypes.SET_CUSTOM_SELECTOR_SCORE_ATTRS:
      return{
        ...state,
        scoreAttrs: payload,
      }

    case ActionTypes.SET_INITIAL_WEIGHTS:
      const { calculatorWithAttrs, allCalculators, defaultCalculator } = payload;
      const magnifiCalc = calculatorOptions.magnificalculator;
      const myCalc = calculatorOptions.mycalculator;
      const activeCalc = calculatorOptions.magnifiactivecalculator;
      let updatedDefaultAttrs = state.defaultScoreAttrs;
      let updatedMyScoreAttrs = state.myScoreAttrs;
      let updatedActiveCalcScoreAttrs = state.activeCalcScoreAttrs;
      if(calculatorWithAttrs[magnifiCalc]){
        const magnifiCalcWeight = calculatorWithAttrs[magnifiCalc];
        const tempMyScoreAttrs = state.customCalculatorAttrs.filter(item => {
          return magnifiCalcWeight.find(attr => attr.weight_name === item.code);
        })
        updatedDefaultAttrs = sortScoreAttrs(tempMyScoreAttrs, calculatorWithAttrs[magnifiCalc], true);
      }
      if(calculatorWithAttrs[myCalc]){
        const myCalcWeight = calculatorWithAttrs[myCalc];
        const tempMyScoreAttrs = state.customCalculatorAttrs.filter(item => {
          return myCalcWeight.find(attr => attr.weight_name === item.code);
        })
        updatedMyScoreAttrs = sortScoreAttrs(tempMyScoreAttrs, calculatorWithAttrs[myCalc], true);
      }
      if(calculatorWithAttrs[activeCalc]){
        const activeCalcWeight = calculatorWithAttrs[activeCalc];
        const tempMyScoreAttrs = state.customCalculatorAttrs.filter(item => {
          return activeCalcWeight.find(attr => attr.weight_name === item.code);
        })
        updatedActiveCalcScoreAttrs = sortScoreAttrs(tempMyScoreAttrs, calculatorWithAttrs[activeCalc], true);
      }
      let customCalculators = {};
      Object.keys(calculatorWithAttrs).forEach(item => {
        if(!availableCalculators.includes(item)){
          const availableAttrsInCustomCalc = calculatorWithAttrs[item].map(item => item.weight_name);
          const customAttrs = state.customCalculatorAttrs.filter(item => availableAttrsInCustomCalc.includes(item.code));
          customCalculators[item] = sortScoreAttrs(customAttrs, calculatorWithAttrs[item], true);
        }
      })
      return {
        ...state,
        myScoreAttrs: updatedMyScoreAttrs,
        activeCalcScoreAttrs: updatedActiveCalcScoreAttrs,
        defaultScoreAttrs: updatedDefaultAttrs,
        customCalculators,
        weightScore: payload,
        allCalculators,
        defaultCalculator,
      }

    case ActionTypes.SET_WEIGHTS_DATA:
    case ActionTypes.SAVE_SCORE_ATTRS:
    if(state.selectedCalculator === calculatorOptions.magnifiactivescreener) {
      return {
        ...state,
      }
    }
      const weightScore =
        action.type === ActionTypes.SET_WEIGHTS_DATA
          ? payload.weightScore
          : state.weightScore[state.selectedCalculator]

      let scoreAttrs = []

      if (action.type === ActionTypes.SAVE_SCORE_ATTRS) {
        scoreAttrs = payload
      } else {
        scoreAttrs = (state.selectedCalculator === calculatorOptions.mycalculator) ? state.myScoreAttrs :
         (state.selectedCalculator === calculatorOptions.magnifiactivecalculator) ? state.activeCalcScoreAttrs :
         (state.selectedCalculator === calculatorOptions.magnificalculator) ? state.defaultScoreAttrs : state.scoreAttrs;
      }

      const sortedAttrs = sortScoreAttrs(
        scoreAttrs,
        weightScore,
        action.type === ActionTypes.SET_WEIGHTS_DATA
      )
      const finalMyScoreAttrs =
        action.type === ActionTypes.SAVE_SCORE_ATTRS &&
        state.selectedCalculator === calculatorOptions.mycalculator
          ? sortedAttrs
          : state.myScoreAttrs
      const finalActiveCalcScoreAttrs =
        action.type === ActionTypes.SAVE_SCORE_ATTRS &&
          state.selectedCalculator === calculatorOptions.magnifiactivecalculator
          ? sortedAttrs
          : state.activeCalcScoreAttrs
      const finalDefaultScoreAttr =
        action.type === ActionTypes.SAVE_SCORE_ATTRS &&
          state.selectedCalculator === calculatorOptions.magnificalculator
          ? sortedAttrs
          : state.defaultScoreAttrs
      let updateCustomCalculators = state.customCalculators;
      if(action.type === ActionTypes.SAVE_SCORE_ATTRS &&
          !availableCalculators.includes(state.selectedCalculator)){        
        updateCustomCalculators[state.selectedCalculator] = sortedAttrs
      }
      return {
        ...state,
        ...(action.type === ActionTypes.SET_WEIGHTS_DATA && (payload || {})),
        myScoreAttrs: finalMyScoreAttrs,
        activeCalcScoreAttrs: finalActiveCalcScoreAttrs,
        defaultScoreAttrs: finalDefaultScoreAttr,
        scoreAttrs: sortedAttrs,
        weightScore: {
          ...state.weightScore,
          [state.selectedCalculator]: weightScore,
        },
        customCalculators: updateCustomCalculators,
      }
    case ActionTypes.SET_CALCULATOR:
      let updatedScoreAttrs, updatedCalculatorAttrs;
      if(payload !== 'create custom selector'){
        const isCustomCalculator = state.customCalculators[payload];
        if(!isCustomCalculator){
          updatedScoreAttrs = (payload === calculatorOptions.magnificalculator ||
            payload === calculatorOptions.mycalculator)
              ? state.myScoreAttrs : state.activeCalcScoreAttrs;
        }else{
          updatedScoreAttrs = state.customCalculators[payload];
        }
        updatedCalculatorAttrs = sortScoreAttrs(
          updatedScoreAttrs,
          state.weightScore[payload],
          true
        )
      }else{
        updatedCalculatorAttrs = []
      }
      return {
        ...state,
        selectedCalculator: payload,
        scoreAttrs:
          payload === calculatorOptions.magnificalculator
            ? state.defaultScoreAttrs
            : updatedCalculatorAttrs
      }
    case ActionTypes.UPDATE_CALCULATOR_NAME:
      return {
        ...state,
        selectedCalculator: payload,
      }
    case ActionTypes.UPDATE_ALL_CALCULATOR_LIST:
      return {
        ...state,
        allCalculators: payload,
      }
    case ActionTypes.UPDATE_DEFAULT_CALCULATOR:
      return {
        ...state,
        defaultCalculator: payload,
      }
    case ActionTypes.UPDATE_ALL_SELECTOR_ATTRS:
      return{
        ...state,
        customCalculatorAttrs: payload,
      }
    default:
      return state
  }
}

import { createAPIInstance } from './api'
import qs from 'query-string'

export function fetchWeightsData() {
  return createAPIInstance()
    .get('/user/user-combine-weight')
    .then(response => response.data)
}

export function postWeightsFormData(payload) {
  return createAPIInstance()
    .post('/user/user-combine-weight', JSON.stringify(payload), {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => response.data)
}

export function fetchWeightsDataV1() {
  return createAPIInstance()
    .get('/v1/user/user-combine-weight')
    .then(response => response.data)
}

export function postWeightsFormDataV1(payload) {
  return createAPIInstance()
    .post('/v1/user/user-combine-weight', JSON.stringify(payload), {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => response.data)
}

export function deleteCalculator(calculator) {
  return createAPIInstance()
    .delete(`/v1/user/user-combine-weight?calculator_name=${calculator}`, {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => response.data)
}

export function setDefaultCalculator(payload) {
  return createAPIInstance()
    .post('/user/user-default-calculator', JSON.stringify(payload), {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => response.data)
}

export function getSelectorAttributes() {
  return createAPIInstance()
    .get('/user/selector-attributes')
    .then(response => response.data)
}
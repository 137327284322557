import React from 'react';
import { withHeaderFooter } from '../shared/HeaderFooter';

import FactorExposuresTable from './FactorExposuresTable';
import FactorExposureChart from '../shared/FactorExposureChart';
import ScatterCustomLegend from '../shared/ScatterCustomLegend';

import { getPerformaceChartdata } from '../shared/Utils';
import UpDownTableWrap from 'components/reportsV2/sf/UpDownTableWrap'

const FactorExposuresWrap = (props) => {
  const { selFunds, totFunds, breakPoint, bestTicker, printSelection } = props;
  return (
    <React.Fragment>
      {printSelection.includes('perf-analytics-factor-exposure') && <FactorExposuresTableContent
        selFunds={selFunds}
        totFunds={totFunds}
        breakPoint={breakPoint}
        pageName={'perf-analytics-factor-exposure'}
        bestTicker={bestTicker}
      />}
      {totFunds > breakPoint && printSelection.includes('perf-analytics-factor-exposure') && (
        <RenderChartSection
          selFunds={selFunds}
          totFunds={totFunds}
          pageName={'perf-analytics-factor-exposure'}
        />
      )}
    </React.Fragment>
  )
};

export default FactorExposuresWrap;

const FactorExposuresTableContent = withHeaderFooter((props) => {
  const { selFunds, totFunds, breakPoint, bestTicker } = props;
  return (
    <div className="content">
      <div className="pr-v2-perf-analytics">
        <div className="pr-v2-header">
          <h3>Performance Analytics : Factor Exposures</h3>
        </div>
        <div className="pr-v2-perf-analytics">
          <div className="perf-component-wrapper">
            <FactorExposuresTable bestTicker={bestTicker} selFunds={selFunds} />
            {totFunds <= breakPoint && (
              <RenderChartSection
                selFunds={selFunds}
                totFunds={totFunds}
                showHeaderFooter={false}
              />
            )}
          </div>
          <p className={'asteriskDisclaimer'}>Multifactor models describe the return on an asset in terms of the assets exposure to one or more factors. Such models generally include systematic factors, which explain the average returns of a large number of risky assets. Limitations in using Multifactor models include the subjective interpretation of the model output. Model correlation does not imply causation.</p>
        </div>

        {/* <p className={'asteriskDisclaimer'}>* Using SPY as a proxy for S&P 500 returns.</p> */}
      </div>
    </div>
  )
});

const RenderChartSection = withHeaderFooter((props) => {
  const { selFunds, totFunds } = props;
  return (
    <div className={`content ${totFunds > 5 ? 'pr-v2-perf-analytics' : ''}`}>
      <div className={totFunds > 5 ? 'perf-component-wrapper' : ''}>
        <FactorExposureChart
          chartTitle="Size / Value"
          chartData={getPerformaceChartdata(selFunds, 'sizeValueKeys')}
          axisLabels={['Value', 'Size']}
          axisUnits={['', '']}
          maxMinLabel={{ xMax: 'NA', xMin: 'NA', yMax: 'Large', yMin: 'Small' }}
        />
        <FactorExposureChart
          chartTitle="Momentum / Value"
          chartData={getPerformaceChartdata(selFunds, 'momentumValueKeys')}
          axisLabels={['Value', 'Momentum']}
          axisUnits={['', '']}
          maxMinLabel={{ xMax: 'NA', xMin: 'NA', yMax: 'High', yMin: 'Low' }}
        />
        <ScatterCustomLegend data={selFunds} />
      </div>
    </div>
  )
});
import React from 'react';
import {
  Form,
  Input,
  Button,
  Modal,
	Divider,
} from 'antd';

import {
  CopyrightOutlined,
} from '@ant-design/icons';

import { addEventToAnalytics } from 'Utils';
// import { TifinRiskLogo } from 'SvgComponent';

let TifinRiskLogo = '/assets/images/tifin-risk-logo.svg';

const { TextArea } = Input;

class EMailPreviewModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
			editing: false,
      values: {
        email_edited_body: '',
        signature: '',
      },
    };
    this.emailForm = React.createRef();
  }

  componentDidMount() {
    this.setDefaultFieldValue();
  }

  setDefaultFieldValue = () => {
    const { formData, selfName } = this.props;
    if (formData &&
      selfName &&
      formData.email &&
      formData.first_name &&
      formData.last_name
    ) {
      this.setState({
        values: {
          ...formData,
          email_edited_body: `Hi ${formData.first_name} ${formData.last_name}, \nYou've been invited to complete your personal risk assessment. Please call our office if you have questions.`, // Hi John Doe; You've been invited to complete your personal risk assessment. Please call our office if you have questions.
          signature: `Sincerely, \n${selfName}`, // Sincerely; Jason
        }
      });
    }
  }

	onFinish = (values) => {
    const { onClickOk, formData } = this.props;
    if (formData && formData.email && values) {
      let data = {
        ...formData,
        ...values,
      };
      if (onClickOk) {
        onClickOk(data)
      }
    }
	}

	onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	}

	cancelEditing = () => {
		// console.log('-- cancelEditing --');
    // this.setDefaultFieldValue();
    // if (this.emailForm && this.emailForm.current) {
    //   console.log(this.emailForm.current);
    //   let getFieldsValue = this.emailForm.current.getFieldsValue();
    //   if (getFieldsValue && Object.keys(getFieldsValue).length > 0) {
    //     const { values } = this.state;
    //     console.log(values);
    //     Object.keys(getFieldsValue).forEach((key, i) => {
    //       if (values && typeof values[key] !== 'undefined') {
    //         getFieldsValue[key] = values[key]
    //       }
    //     });
    //     this.emailForm.current.setFieldsValue({
    //       ...getFieldsValue,
    //     });
    //   }
    //   console.log('-- getFieldsValue -->', getFieldsValue);
    // }
    this.setState({
      editing: false,
    });
	}

	editEmailFormData = () => {
		// console.log('-- editEmailFormData --');
		this.setState({
			editing: true,
		});
    addEventToAnalytics('Totum Preview Edit Button', 'Totum Preview Edit Button', 'TOTUM_PREVIEW_EDIT_BUTTON', {}, false);
	}

  onValuesChange = (props) => {
    // console.log('--- onValuesChange ---', props);
    const { values } = this.state;
    if (props && Object.keys(props).length > 0) {
      Object.keys(props).forEach((key, i) => {
        if (values && typeof values[key] !== 'undefined') {
          // console.log(key, props[key]);
          values[key] = props[key]
        }
      });
      this.setState({ values })
    }
  }

  render () {
    const {
      style,
      loading,
      // formData,
      // selfName,
      onModalClose,
      wrapClassName,
    } = this.props;
		const { editing, values } = this.state;

    // let values = {
    //   ...formData,
    //   email_edited_body: `Hi ${formData.first_name} ${formData.last_name}, \nYou've been invited to complete your personal risk assessment. Please call our office if you have questions.`, // Hi John Doe; You've been invited to complete your personal risk assessment. Please call our office if you have questions.
    //   signature: `Sincerely, \n${selfName}`, // Sincerely; Jason
    // };

    let fields = [
      {
        name: ['email_edited_body'],
        value: values.email_edited_body,
      },
      {
        name: ['signature'],
        value: values.signature,
      },
    ];

    return (
      <Modal
        {...this.props}
        style={{ ...style, display: loading ? 'none' : '' }}
        bodyStyle={{ minHeight: 212, display: loading ? 'none' : '' }}
        wrapClassName={`csv-preview-upload ${wrapClassName}`}
        onCancel={this.onModalClose}
        closeIcon={<i className="fal fa-times premium-feature-completed-close cp-modal-close" onClick={onModalClose}></i>}
      >
        <Form
          name="basic"
          autoComplete="off"
          layout="vertical"
          className="totum-questionnaire-email-body-container"
					fields={fields}
          initialValues={{ size: 'large' }}
          ref={this.emailForm}
          onFinish={this.onFinish}
          onValuesChange={this.onValuesChange}
          onFinishFailed={this.onFinishFailed}
        >
          <div className="totum-questionnaire-email-body-wrapper">
            <div className="totum-questionnaire-form-footer-wrapper">
              {/* <span className="text">powered by</span> */}
              <span>
                {/*
                  <img
                    className="magnifi-logo"
                    src={'/assets/images/tifin-risk-logo.svg'}
                    width={115}
                    height={24}
                    alt="magnifi"
                  />
                */}
                <img
                  src={TifinRiskLogo}
                  className="magnifi-logo"
                  width={'auto'}
                  height={24}
                  style={{ paddingLeft: 10, width: 'auto', height: 12 }}
                />
              </span>
            </div>
            <Divider />
            <div className="email-head-text">Personal Risk Assessment</div>
            <Form.Item name='email_edited_body' rules={[{ required: true, message: '' }]}>
              <TextArea
                className={`${editing ? 'show-text-area' : ''}`}
                disabled={!editing}
                rows={3}
                placeholder={values.email_edited_body}
              />
            </Form.Item>
            <div className="email-preview-button">
              Take Questionnaire
            </div>
            <Form.Item name='signature' rules={[{ required: false, message: '' }]}>
              <TextArea
                className={`${false ? 'show-text-area' : ''}`}
                disabled={!editing || true}
                rows={2}
                placeholder=""
              />
            </Form.Item>
            <Divider />
            <div className="email-disclaimer-wrapper">
              <span>
                Note: Please do not reply to this message. This email was sent to <span className="light-blue">{values.email}</span>
              </span>
              <br />
              <span>
                <span><CopyrightOutlined className="copy-right-icon" /></span>
                <span className="light-blue" style={{ margin: '0 2px' }}>Totum Risk</span>
                <span>2021</span>
                <span><i class="fas fa-horizontal-rule" style={{ margin: '0 2px' }}></i></span>
                <span>1222 Pearl St, Suite 200, Boulder, CO 80302</span>
              </span>
            </div>
          </div>
          <div className="totum-questionnaire-email-cta-container">
            <Button className="edit-button" type="secondary" onClick={editing ? this.cancelEditing : this.editEmailFormData}>
              {editing ? 'CANCEL' : 'EDIT'}
            </Button>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                SEND
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    )
  }
}

export default EMailPreviewModal;

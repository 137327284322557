import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { get } from 'lodash'
import {
  CloseButton,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from 'shared-components'

import BrokerList from '../components/broker/BrokerList'
import { BrokersList, isDemoUser } from '../DataSet'

import { createLoadingSelector } from 'selectors/loading-selector'
import { setupBroker, completeTradeItSetup } from 'actions/broker'
import { ErrorAlert } from 'shared-components/';
import { closeTraditWindow } from '../Utils'
import config from '../env'

class SelectBrokerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTradeItFetching: false
    }
  }
  componentDidMount() {
    if (this.props.location.state && localStorage.getItem('activeBrokerName') != null) {
      var broker, received_code;
      BrokersList.forEach((element) => {
        if (element.name == localStorage.getItem('activeBrokerName')) {
          broker = element;
          received_code = this.props.location.state.received_code
          localStorage.removeItem('activeBrokerName');
          delete this.props.location.state.received_code;
        }
      });
      this.handleBrokerSelect(broker, received_code)
    }
  }

  componentDidUpdate(prevProps) {
    // if (this.props.receivedEndPoint !== prevProps.receivedEndPoint) {
    //   if (this.props.receivedEndPoint) {
    //     if(this.props.selectedBroker.name ==  'etrade'){
    //       this.props.history.push(
    //         `/settings/broker/${this.props.selectedBroker.name}/register`
    //       )
    //     }
    //     // else if(this.props.selectedBroker.name ==  'tdameritrade'){
    //     //   this.props.history.push(
    //     //     `/settings/broker/${this.props.selectedBroker.name}/account`
    //     //   )
    //     // }
    //   }
    // }
    if (this.props.importAccountsSuccess !== prevProps.importAccountsSuccess
      && this.props.importAccountsSuccess) {
      this.props.history.push('/settings/broker')
      this.setState({
        isTradeItFetching: false
      });
    }

    if (this.props.displayVerification !== prevProps.displayVerification
      && this.props.displayVerification) {
      this.props.history.push('/settings/broker/tradeit/security-questions');
      this.setState({
        isTradeItFetching: false
      });
    }
  }

  componentWillUnmount() {
    closeTraditWindow();
  }

  handleToggle = () => {
    this.props.history.push('/settings/broker')
  }

  handleBrokerSelect = (broker, received_code) => {
    if (broker.name == "tdameritrade" && !received_code) {
      closeTraditWindow();
      window.location.href = config.tdAmeritradeCallbackUrl;
    } else if (broker.name !== 'tdameritrade') {
      this.props.setupBroker(broker, { broker: broker.name })
      const messageHandler = (message) => {
        if ([config.tradeItOrigin, config.tradingTicketorigin].includes(message.origin)) {
          this.setState({
            isTradeItFetching: true
          });
          const parsedData = JSON.parse(message.data)
          const token = get(parsedData, 'oAuthVerifier', '');
          this.props.completeTradeItSetup({
            token,
            brokerName: broker.name
          })
        }
      }
      window.onmessage = messageHandler
    } else {
      this.props.setupBroker(broker, received_code)
    }
  }

  render() {
    const { isTradeItFetching } = this.state
    const { isFetching, isDemoUser, error } = this.props

    if (!isDemoUser) {
      return <Redirect to="/settings/broker" />
    }

    return (
      <Modal
        isOpen={true}
        backdrop
        toggle={this.handleToggle}
        keyboard
        centered
        className="brokers-modal"
        backdropClassName="brokers-backdrop"
      >
        <ModalHeader>
          Select Broker
          <CloseButton onClick={this.handleToggle} />
        </ModalHeader>
        <ModalBody>
          {error && <ErrorAlert />}
          <BrokerList
            brokerList={BrokersList}
            onBrokerSelect={this.handleBrokerSelect}
          />
          <Spinner isFetching={isFetching || isTradeItFetching} />
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  selectedBroker: state.broker.selectedBroker,
  receivedEndPoint: state.broker.receivedEndPoint,
  error: state.broker.error,
  isFetching: createLoadingSelector(['SETUP_BROKER'])(state),
  isDemoUser: isDemoUser(),
  importAccountsSuccess: state.importBrokerAccount.importAccountsSuccess,
  displayVerification: state.tradeItSecurityHandling.displayVerification,
})

const mapDispatchToProps = {
  setupBroker,
  completeTradeItSetup
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectBrokerContainer)

import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import ExecutiveSummary from './sf/ExecutiveSummary'
import ModelReplacement from './sf/ModelReplacement'
import PortfolioPerformance from './sf/PortfolioPerformance'
import AssetAllocations from './sf/AssetAllocations'
import ConclusionPage from './sf/ConclusionPage'
import RecommendedEnhancements from './sf/RecommendedEnhancements'
import { GlossaryPage1 } from './sf/Glossary'
import CoverPage from './sf/CoverPage'
import IndexPage from './sf/IndexPage'
import PortfolioContext from './PortfolioContext'

class Content extends React.Component {
  static contextType = PortfolioContext
  render() {
    let reportData = this.context
    let enhanceReplace = reportData.portfolio.enhanceReplace
    let finalArray = _.chunk(enhanceReplace, 3)
    let logo = reportData.portfolio.logo;
    const disclosures = reportData.portfolio.disclosures || ''

    let feedDate = enhanceReplace.find(
      k => k.selectedPortfolioFunds && k.selectedPortfolioFunds.feed_date
    )
    let createdDate = moment(
      new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })
    ).format('MM/DD/YYYY')
    // const createdDate = (feedDate &&
    //                      feedDate.selectedPortfolioFunds &&
    //                      feedDate.selectedPortfolioFunds.feed_date
    //                    ) ? moment(feedDate.selectedPortfolioFunds.feed_date).format('MM/DD/YYYY') : moment(new Date().toLocaleString("en-US", { timeZone: "America/New_York" })).format('MM/DD/YYYY')
    if (
      feedDate &&
      feedDate.selectedPortfolioFunds &&
      feedDate.selectedPortfolioFunds.feed_date
    ) {
      createdDate = moment(feedDate.selectedPortfolioFunds.feed_date).format(
        'MM/DD/YYYY'
      )
    }
    const renderDesign = finalArray.map((row, index) => {
      return (
        <RecommendedEnhancements
          isModelPortfolioQuery={reportData.portfolio.isModelPortfolioQuery}
          createdDate={createdDate}
          finalArray={finalArray}
          index={index}
          logo={logo}
          data={row}
          query={reportData.portfolio.query}
          queryEnglish={reportData.portfolio.query_english}
        />
      )
    })
    return (
      <>
        <CoverPage
          disclosures={disclosures}
          isModelPortfolioQuery={reportData.portfolio.isModelPortfolioQuery}
          createdDate={createdDate}
          logo={logo}
          reportData={reportData}
        />
        <div className="container h-100">
          <IndexPage
            isModelPortfolioQuery={reportData.portfolio.isModelPortfolioQuery}
            createdDate={createdDate}
            logo={logo}
            reportData={reportData}
          />
          <ExecutiveSummary
            isModelPortfolioQuery={reportData.portfolio.isModelPortfolioQuery}
            createdDate={createdDate}
            logo={logo}
          />
          {reportData.portfolio.isModelPortfolioQuery && (
            <ModelReplacement
              isModelPortfolioQuery={reportData.portfolio.isModelPortfolioQuery}
              createdDate={createdDate}
              logo={logo}
            />
          )}
          <PortfolioPerformance
            createdDate={createdDate}
            isModelPortfolioQuery={reportData.portfolio.isModelPortfolioQuery}
            logo={logo}
          />
          <AssetAllocations
            isModelPortfolioQuery={reportData.portfolio.isModelPortfolioQuery}
            createdDate={createdDate}
            logo={logo}
          />
          <ConclusionPage
            isModelPortfolioQuery={reportData.portfolio.isModelPortfolioQuery}
            createdDate={createdDate}
            logo={logo}
          />
          {!reportData.portfolio.isModelPortfolioQuery && renderDesign}
          <GlossaryPage1
            isModelPortfolioQuery={reportData.portfolio.isModelPortfolioQuery}
            createdDate={createdDate}
          />
        </div>
      </>
    )
  }
}

export default Content

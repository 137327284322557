import React, { Component, Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { quintileClass, numFmt } from 'Utils';
import GaugeChart from 'components/charts/GaugeChart';
import NotApplicable from 'components/NotApplicable'
var classNames = require('classnames');

export const ESGScoreGaugeChartWrap = ({ card, qi }) => {
  if(typeof card._cstats == 'undefined' || !card._cstats) return <NotApplicable />
  const mult = qi.name === 'Social Impact' ? 100 : 1;
  const _min = card._cstats[qi.col+'_min']*mult,
        _max = card._cstats[qi.col+'_max']*mult,
        _val = card[qi.col].score*mult;
        // _pc = card[qi.pcCol];
  const suffix = ['', 'K', 'M', 'B'];
  const kmb = (val, i = 0) => (val < 100 || i > 2) ? `${val.toFixed(1)}` : kmb(val/1000, i+1)
  // console.log('min', _min)
  // console.log('max', _max)
  return (
    _max > 0.0000000 && <div className="w-100 p-1">
      <div className="d-flex flex-column align-items-center" style={{ minWidth: 134 }}>
        <h6 className="text-center mb-0">{qi.name} <span className="text-gray-6i">vs Category</span></h6>
        <GaugeChart value={_val} min={_min} max={_max}/>
        <Row className="no-gutters h6 mb-0 w-100">
          <Col className="col-4 pr-2 text-gray-6i text-right h7 fz-9" style={{marginTop: '-2px'}}>{_min.toFixed(1)}</Col>
          <Col className="col-4 text-center font-weight-bold mb-0 lh-100 fz-9" style={{marginTop: '2px'}}>{_val.toFixed(1)}</Col>
          <Col className="col-4 pl-2 text-gray-6i text-left h7 fz-9" style={{marginTop: '-2px'}}>{_max.toFixed(1)}</Col>
        </Row>
      </div>
    </div>
  );
}

export const WeightHeldGaugeChartWrap = ({ card, name }) => {
  const mult = name === '% Held' ? 1 : 100;
  const _min = card.weightHeld['min_value']*mult,
        _max = card.weightHeld['max_value']*mult,
        _val = card.weightHeld.weight*mult;
        // _pc = card[qi.pcCol];
  const suffix = ['', 'K', 'M', 'B'];
  const kmb = (val, i = 0) => (val < 100 || i > 2) ? `${val.toFixed(1)}` : kmb(val/1000, i+1)
  // console.log('min', _min)
  // console.log('max', _max)
  return (
    _max > 0.0000000 && <div className="w-100 p-1">
      <div className="d-flex flex-column align-items-center" style={{ minWidth: 134 }}>
        <h6 className="text-center mb-0">Percentage <span className="text-gray-6i">vs Category</span></h6>
        <GaugeChart value={_val} min={_min} max={_max}/>
        <Row className="no-gutters h6 mb-0 w-100">
          <Col className="col-4 pr-2 text-gray-6i text-right h7 fz-9" style={{marginTop: '-2px'}}>{_min.toFixed(1)}</Col>
          <Col className="col-4 text-center font-weight-bold mb-0 lh-100 fz-9" style={{marginTop: '2px'}}>{_val.toFixed(1)}</Col>
          <Col className="col-4 pl-2 text-gray-6i text-left h7 fz-9" style={{marginTop: '-2px'}}>{_max.toFixed(1)}</Col>
        </Row>
      </div>
    </div>
  );
}

export const SimilarityGaugeChartWrap = ({ card, name }) => {
  const mult = name === 'Overlap' ? 100 : 1;
  let _min = 0* mult,
        _max = 1 * mult,
        _val = card.overlap* mult;
        _val = parseFloat(_val.toFixed(1));

  return (
    _max > 0.0000000 && <div className="w-100 p-1">
      <div className="d-flex flex-column align-items-center" style={{ minWidth: 134 }}>
        {/* <h6 className="text-center mb-0">Percentage Overlap</h6> */}
        <h6 className="text-center mb-0">&nbsp;</h6>
        <GaugeChart value={_val} min={_min} max={_max}/>
        <Row className="no-gutters h6 mb-0 w-100">
          <Col className="col-4 pr-2 text-gray-6i text-right h7 fz-9" style={{marginTop: '-2px'}}>{_min.toFixed(0)}%</Col>
          <Col className="col-4 text-center font-weight-bold mb-0 lh-100 fz-9" style={{marginTop: '2px'}}>{_val.after() == 0 ? _val.toFixed(0) : _val.toFixed(1)}%</Col>
          <Col className="col-4 pl-2 text-gray-6i text-left h7 fz-9" style={{marginTop: '-2px'}}>{_max.toFixed(0)}%</Col>
        </Row>
      </div>
    </div>
  );
}

export const OutperformanceGaugeChartWrap = ({ card, name }) => {
  const mult = name === 'Outperformance' ? 100 : 1;
  let _min = 0* mult,
        _max = card.outperformanceMax * mult,
        _val = card.outperform* mult;
        _val = parseFloat(_val.toFixed(1));

  return (
    _max > 0.0000000 && <div className="w-100 p-1">
      <div className="d-flex flex-column align-items-center" style={{ minWidth: 134 }}>
        <h6 className="text-center mb-0">{card.ticker} - <span className="text-gray-6i">{card.queryCategoryValue}</span></h6>
        <GaugeChart value={_val} min={_min} max={_max}/>
        <Row className="no-gutters h6 mb-0 w-100">
          <Col className="col-4 pr-2 text-gray-6i text-right h7 fz-9" style={{marginTop: '-2px'}}>{_min.toFixed(0)}%</Col>
          <Col className="col-4 text-center font-weight-bold mb-0 lh-100 fz-9" style={{marginTop: '2px'}}>{_val.after() == 0 ? _val.toFixed(0) : _val.toFixed(1)}%</Col>
          <Col className="col-4 pl-2 text-gray-6i text-left h7 fz-9" style={{marginTop: '-2px'}}>{_max.after() == 0 ? _max.toFixed(0) : _max.toFixed(1)}%</Col>
        </Row>
      </div>
    </div>
  );
}

export const QuintileWrap = ({ card, qi }) => {
  const val = qi.name === 'Volume' ? card[qi.col]/(1000*1000) : card[qi.col];
  const cval = qi.name === 'Volume' ? card._cstats[qi.col]/(1000*1000) : card._cstats[qi.col];
  const catValFmt = <span><span className="text-gray-5">$</span>{ numFmt(cval) }<span className="text-gray-6"> M</span></span>

  return (
    <div className="w-100 p-2">
      <div className="h5 d-flex justify-content-between pt-1">
        <span>{qi.name}</span>
        <span className="text-gray-8 font-weight-bold">
          <span className="text-gray-5">$</span>
          { numFmt(val) }
          <span className="text-gray-6"> M</span>
        </span>
      </div>
      <Quintile item={qi} value={card[qi.qcol]} catValue={catValFmt} bg={quintileClass(card[qi.qcol], qi.reverse)} w100 />
    </div>
  );
}

export const Quintile = (props) => {
  const { cardCategory } = props;
  var off = (props.value - 1) * 20 + '%'
  const date = !props.date ? '' : `(${props.date})`;
  const categoryText = !date ? null : (
    <div className="category-label text-overflow-ellipse-line-2">
      <span className="category-key">vs. Category </span>
      <span className="category-value text-gray-6">{`(${cardCategory})`}</span>
    </div>
  );

  let renderCategoryDesign = (
    <div className={classNames("h6 mb-0 text-gray-7 d-flex justify-content-between align-items-center date-range-label")}>
      <span className="date-range-key">{date}</span>
      <span className="date-range-value">{props.catValue}</span>
    </div>
  );
  // console.log(date);
  if (!date) {
    renderCategoryDesign = (
      <div className="h6 text-gray-7 d-flex justify-content-between mb-0">
        <div className="category-label text-overflow-ellipse-line-2" style={{ fontSize: 12 }}>
          <span className="category-key">vs. Category </span>
          <span className="category-value text-gray-6">{`(${cardCategory})`}</span>
        </div>
        <div className={classNames("h6 text-gray-7 mb-0 date-range-label")} style={{ fontSize: 12 }}>
          <span className="date-range-value" style={{ fontSize: 12 }}>{props.catValue}</span>
        </div>
      </div>
    );
  }
  return (
    <div className="popover-content-min-wrapper" style={props.w100 ? null : {minWidth: '240px'}}>
      {cardCategory && categoryText}
      { props.catValue === undefined ? <h6 className="text-dark mb-0">{date}</h6> : renderCategoryDesign }

      <div className="progress">
        <div className="progress-bar bg-progress" role="progressbar" style={{width: off}} aria-valuenow={off} aria-valuemin="0" aria-valuemax="100"></div>
        <div className={"progress-bar bg-"+props.bg} role="progressbar" style={{width: '20%'}} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <div className="d-flex justify-content-between h6 text-gray-5 mb-0">
        <div>Low</div>
        <div>Avg</div>
        <div>High</div>
      </div>
    </div>
  )
}

export const Pct = (props) => <small className="text-gray-5">%</small>

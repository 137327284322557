import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Slider from "react-slick";
// import PerfectScrollbar from 'perfect-scrollbar';
import { Card } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

import { setEnhancerState, addFundForEnhanceRequest, deleteFundForEnhanceRequest } from 'actions/enhancer';

import { arrayToHash, getPortfolioBenchmarkOfSelectedTicker } from 'Utils'
import { CN } from 'DataSet';

import ItemCard from './ItemCard';
import { addEventToAnalytics } from 'Utils'

class ItemList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      enhanceReplace: '',
    }
    // this.myRef = React.createRef();
  }

  setReplaceTicker = (e, selectedFund, replaceTicker, ticker, query) => {
    const checkedCard = e.target.checked;
    const {
      data,
      setEnhancerState,
      enhanceReplace,
      addFundForEnhanceRequest,
      deleteFundForEnhanceRequest,
    } = this.props;

    try {
      if (checkedCard) {
        addEventToAnalytics('Enhancer Fund Select', 'Enhancer Fund Select', 'ENHANCER_FUND_SELECT', { ticker, replaceTicker, category: 'advisory_PTC' }, false);
        // add card in enhanceReplace
        // console.log('add card in enhanceReplace');
        if (selectedFund && addFundForEnhanceRequest) {
          addFundForEnhanceRequest({
            replaceTicker,
            ticker,
            query,
            selectedPortfolioFunds: selectedFund,
          }, (res) => {
            // console.log(res);

          })
        } else {
          console.log(' -- data is missing --');
        }

      } else {
        addEventToAnalytics('Enhancer Fund Deselect', 'Enhancer Fund Deselect', 'ENHANCER_FUND_DESELECT', { ticker, replaceTicker }, false);
        // remove card from enhanceReplace
        // console.log('remove card from enhanceReplace');
        if (deleteFundForEnhanceRequest && selectedFund) {

          deleteFundForEnhanceRequest({
            replaceTicker,
            ticker,
            query,
            selectedPortfolioFunds: selectedFund,
          }, (res) => {
            // console.log(res);

          })

        } else {
          throw '-- selected fund missing --'
        }
      }
    } catch (e) {
      console.log(e);
    }

  }

  tabOnChange = (selectedKey, data, ticker, query, index) => {
    const { tabOnChange } = this.props;
    if (tabOnChange) {
      tabOnChange(selectedKey, data, ticker, query, index)
    }
  }

  render() {
    const { data, query, ticker, staticCard, resetCheck, portfolioQuery, activeViewTab, relevanceChartTabs, defaultFRR, originalQueryVar, portfolioReadableQuery, enhanceReplace } = this.props;
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      nextArrow: <RightOutlined />,
      prevArrow: <LeftOutlined />,
      responsive: [
        {
          breakpoint: 1900,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 1530,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 1180,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const messgageText = (q) => {
      // console.log('render messgageText', q);
      if (q.includes('Outperform')) {
        return <span>You already hold <span className="text-bold">the best performing fund</span><br /> in this category.</span>;
      } else if (q.includes('Yield')) {
        return <span>There are <span className="text-bold">no funds</span> similar to <br /><span className="text-bold">{ticker}</span> with <span className="text-bold">higher yield</span>.</span>;
      } else if (q.includes('Track Record')) {
        return <span>There are <span className="text-bold">no funds</span> similar to <br /><span className="text-bold">{ticker}</span> with <span className="text-bold">longer track record</span>.</span>;
      } else if (q.includes('High Risk Adjusted') || q === 'Highest Risk Adjusted Return Alternatives') {
        return <span>There are <span className="text-bold">no funds</span> similar to <br /><span className="text-bold">{ticker}</span> with <span className="text-bold">higher risk adjusted return</span>.</span>;
      } else if (q.includes('Risk Replacement') || q === 'Lower Cost And Risk Alternatives') {
        return <span>There are <span className="text-bold">no funds</span> similar to <br /><span className="text-bold">{ticker}</span> with <span className="text-bold">lower cost and risk replacements</span>.</span>;
      } else if (q.includes('Drawdown')) {
        return <span>There are <span className="text-bold">no funds</span> similar to <br /><span className="text-bold">{ticker}</span> with <span className="text-bold">lower drawdown alternatives</span>.</span>;
      } else if (q.includes('Risk') && !q.includes('Adjusted') && !q.includes('Replacement')) {
        return <span>There are <span className="text-bold">no funds</span> similar to <br /><span className="text-bold">{ticker}</span> with <span className="text-bold">lower risk</span>.</span>;
      } else if (q.includes('Cost')) {
        return <span>There are <span className="text-bold">no funds</span> similar to <br /><span className="text-bold">{ticker}</span> with <span className="text-bold">lower cost</span>.</span>;
      } else if (q.includes('Fund Selector')) {
        return <span>There are <span className="text-bold">no funds</span> similar to <br /><span className="text-bold">{ticker}</span> with the <span className="text-bold">given criteria</span>.</span>;
      } else {
        return <span>There are <span className="text-bold">no funds</span> similar to <br /><span className="text-bold">{ticker}</span> for <span className="text-bold">'{portfolioQuery}'</span>.</span>;
      }
    }

    const renderDesign = data.funds.length ? (
      <Slider {...settings}>
        {data.funds.map((e, i) => (
          <ItemCard
            createdForm="itemList"
            className="enhancer-fund-card"
            data={e}
            query={query}
            portfolioQuery={portfolioQuery}
            i={i}
            activeViewTab={activeViewTab}
            defaultFRR={defaultFRR}
            relevanceChartTabs={relevanceChartTabs}
            resetCheck={resetCheck}
            ticker={ticker}
            staticCard={staticCard}
            originalQueryVar={originalQueryVar}
            checked={enhanceReplace.find((lp) => lp.replaceTicker === e.ticker && lp.ticker === ticker) ? true : false}
            highlights={data.Highlights}
            setReplaceTicker={this.setReplaceTicker}
            enhanceReplace={this.state.enhanceReplace}
            customReplaceActiveTab={this.props.customReplaceActiveTab}
            tabOnChange={this.tabOnChange}
          />
        ))}
      </Slider>
    ) : (
      <Card className="card-background-grey empty-message-wrapper" style={{ height: 279, margin: '0 8px' }}>
        <div className="text-box">
          {messgageText(portfolioReadableQuery || portfolioQuery)}
        </div>
      </Card>
    );

    return (
      <div className="itemlist-carousel-wrap" style={{ display: 'inherit', width: '100%' }}>
        {renderDesign}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  portfolioQuery: state.enhancer.query,
  enhanceReplace: state.enhancer.enhanceReplace || [],
  performanceImpact: state.enhancer.performanceImpact,
});

const mapDispatchToProps = {
  setEnhancerState,
  addFundForEnhanceRequest,
  deleteFundForEnhanceRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemList);

import React from 'react';
import { connect } from 'react-redux';
import { Nav, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Modal, ModalBody, ButtonGroup } from 'reactstrap';

import PremiumNoticeModal from 'components/PremiumNoticeModal';
import CSVPreviewUpload from 'components/CSVPreviewUpload';
import CustomAlert from 'components/CustomAlert';
import { Notification } from 'shared-components'
import { uniqValues, mapCsvToJSON, addEventToAnalytics, findDelimiter, convertToUpperCase, convertToLowerCase, updateTickerData } from 'Utils'

import { createLoadingSelector } from 'selectors/loading-selector';

import { getPortfolioStats } from 'selectors/investment'
import { setBuildPortfolioState, uploadBuilderPortfolioRequest } from 'actions/buildportfolio';
import { investmentOptions, fetchPortfolioData, deletePortfolio, investmentFetchSuccess, downloadPortfolioReport, uploadPortfolio, getRealTimePriceForTickersRequest } from 'actions/investment';
import { subscriptionPlan, setProfileState } from 'actions/profile';
import { setPortfolioReplace, setPortfolioState, checkBuilderTickerRequest, loadDemoCSVRequest, loadDemoCSVFetch, loadDemoCSVSuccess } from 'actions/buildportfolio';
import { checkSingleTickerValidity } from 'actions/portfolio';

import UploadFile from './UploadFile';
import { Loader } from '../../Elements/Loader';

class UploadSection extends React.PureComponent {
  Watchlist = { name: 'Watchlist', code: 'watchlist' }
  UploadPortfolio = { label: 'Upload Portfolio', name: 'Builder portfolio', code: 'uplPrt' }

  constructor(props) {
    super(props);

    this.state = {
      // dropdownOpen: false,
      portfolioDropdownOpen: false,
      uplPrtModal: false,
      premiumModal: false,
      notify: false,
      isNotifyOpen: false,
      csvVisible: false,
      csvDataList: [],
      validTickers: [],
      invalidTickers: [],
      csvFormatErrorMessage: '',
      fileName: '',
      // messageModal: props.tickerNotExist.length > 0 || false,
      messageModal: false,
      uploadFileFor: '',
    };
  }

  componentDidMount() {
    // if (this.props.subscriptionPlan) {
    //   this.props.subscriptionPlan();
    // }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.tickerNotExist && newProps.tickerNotExist.length > 0 && !this.state.messageModal) {
      // this.setState({ messageModal: true, csvVisible: false });
      this.setState({ messageModal: false, csvVisible: true });
    }
  }

  uploadFileFor = () => {
    const { fileFor } = this.props;
    this.setState({ uploadFileFor: fileFor });
    switch (fileFor) {
      case 'Demo_portfolio':
        this.uploadFileForDemo();
        break;
      case 'Custom_portfolio':
        this.openUplPrt();
        break;
      default:
    }
  }

  async uploadFileForDemo () {
    addEventToAnalytics('Builder Demo','Builder Demo','BUILDER_DEMO',{},false);
    console.log('---- uploadFileForDemo -----');
    //Steps:
    // 1. read DEMO CSV file from /public/demo.json dir.
    // 2. pass to CSVPreviewUpload component
    const { loadDemoCSVRequest } = this.props;
    if (loadDemoCSVRequest) {
      loadDemoCSVRequest();
      const csvFile = await this.fetchCsv();
      if (csvFile) {
        this.processUploadData(csvFile);
      }
    }
  }

  fetchCsv = () => {
    const { loadDemoCSVFetch } = this.props;
    if (loadDemoCSVFetch) {
      loadDemoCSVFetch();
    }
    return fetch('/assets/data/demo-portfolio2.csv')
      .then(response => response.text())
      .then(data => {
        return data;
      })
      .catch(error => console.error(error));
  }

  // this function is not used anywhere - Detele it after confirming
  getCSVDataFromFile = (file) => {
    const filters = { headersArray: ['ticker','price','shares','date'], maxHeadersCount: 4, addHeader: ['weight(%)'] };
    // console.log(filters);
    const { portfolioValue } = this.props;
    const clientList = JSON.parse(mapCsvToJSON(file, filters, null, null, portfolioValue));
    // console.log(clientList);
    if (typeof clientList.error !== 'undefined') {
      // console.log(clientList.error);
      if (clientList.error.includes('Invalid')) {
        this.setState({
          csvFormatErrorMessage: clientList.error,
        });
      } else {
        // console.log(clientList.error);
        this.setState({
          csvFormatErrorMessage: '', // dont change this
        });
      }
    } else {
      this.setState({ csvDataList: clientList.filter((item) => (item.ticker !== '' && item.ticker !== 'ticker')), csvFormatErrorMessage: '', uplPrtModal: false, csvVisible: true }, () => {
        // console.log(this.state.csvDataList);
        const { checkBuilderTickerRequest } = this.props;
        const tickersList = this.state.csvDataList.map((u) => {
          if (u.ticker && u.ticker !== '') {
            return u.ticker;
          }
        });
        // console.log('tickersList--->', tickersList);
        if (checkBuilderTickerRequest) {
          checkBuilderTickerRequest({ tickers: tickersList });
        }
      });
    }
  }

  openUplPrt = () => {
    addEventToAnalytics('Builder Upload','Builder Upload','BUILDER_UPLOAD',{},false);
    this.setState({ uplPrtModal: true })
  }

  openNotify = () => {
    this.setState({
      notify: true,
    });
  }

  notifyToggle = () => {
    this.setState({
      notify: !this.state.notify,
    });
  }

  closePremiumModal = () => {
    this.setState({
      premiumModal: false,
    });
  }

  premiumModalToggle = () => {
    this.setState({
      premiumModal: !this.state.premiumModal,
    });
  }

  onFileSuccess = () => {
    this.setState({ uplPrtModal: false, isNotifyOpen: true }, () => {
      // const { onFileSuccess } = this.props;
      // if (onFileSuccess) {
      //   onFileSuccess();
      // }
      setTimeout(() => {
        this.setState({ isNotifyOpen: false })
      }, 3000);
    })
  }

  getCSVData = (data, fileList) => {
    if (data) {
      var reader = new FileReader();
      // console.log(data);
      reader.onload = () => {
        // console.log(filters);
        this.processUploadData(reader.result)
      };
      // start reading the file. When it is done, calls the onload event defined above.
      reader.readAsBinaryString(data);
    }
  }

  processUploadData = (csvData) => {
    this.setState({
      csvDataList: [],
      messageModal: false,
      csvVisible: false
    }, () => this.props.setBuildPortfolioState({ tickerNotExist: [] }) );
    let lines=csvData.split("\n");
    const identified_delimiter = findDelimiter(csvData);
    let tickers = '';
    let headers = lines[0].split(identified_delimiter);
    headers = headers.map(item => convertToLowerCase(item));
    let tickerIndex = headers.indexOf('ticker')
    for(let i=1;i<lines.length;i++){
      let currentline=lines[i].split(identified_delimiter);
      if(currentline[tickerIndex]){
        tickers = i == 1 ? tickers+convertToUpperCase(currentline[tickerIndex]) : tickers+','+convertToUpperCase(currentline[tickerIndex])
      }
    }
    this.setState({
      csvData
    }, () => {
      const { loadDemoCSVSuccess } = this.props;
      if (loadDemoCSVSuccess) {
        loadDemoCSVSuccess();
      }
      if(tickers){
        this.props.getRealTimePriceForTickersRequest(tickers, this.buildClientList)
      }else{
        this.buildClientList([], true)
      }
    });
  }

  buildClientList = (realTimeTickerPrice, noData=false) => {
    const filters = { headersArray: ['ticker','price','shares','date','lot date','weight','amount'], maxHeadersCount: 6, addHeader: ['weight(%)'] };
    const { portfolioValue } = this.props;
    const clientList = JSON.parse(mapCsvToJSON(this.state.csvData, filters, realTimeTickerPrice, noData, portfolioValue));
    if (typeof clientList.error !== 'undefined') {
      this.setState({
        csvFormatErrorMessage: clientList.error || '',
      });
    } else {
      const filteredClientList = clientList.filter((item) => (item.ticker && item.ticker !== '' && item.ticker !== 'ticker'));
      this.setState({ csvDataList: filteredClientList, csvFormatErrorMessage: '', uplPrtModal: false, csvVisible: true }, () => {
        // console.log(this.state.csvDataList);
        const { checkBuilderTickerRequest } = this.props;
        const tickersList = this.state.csvDataList.map((u) => {
          if (u.ticker && u.ticker !== '' && u.ticker !== 'ticker') {
            return u.ticker;
          }
        });
        // console.log('tickersList--->', tickersList);
        if (checkBuilderTickerRequest) {
          checkBuilderTickerRequest({ tickers: tickersList }, this.showOnlyTickersAvailable);
        }
      });
    }
  }

  // Use this function as a callback in line 232 and remove it from render
  showOnlyTickersAvailable = () => {
    const { tickerNotExist } = this.props;
    const { csvDataList } = this.state;
    let validTickers = [];
    let invalidTickers = [];
    csvDataList.forEach((u, i) => {
      if (typeof tickerNotExist.find((k) => k === u.ticker) === 'undefined') {
        u.key = i;
        u.validTicker = true;
        validTickers.push(u);
      }else{
        u.key = i;
        u.validTicker = false;
        u.showInMissingTicker = true;
        u.message = 'Ticker not identified';
        invalidTickers.push(u);
      }
    });
    this.setState({
      validTickers,
      invalidTickers
    })
  }

  checkTicker = (ticker, callback) => {
    const { checkBuilderTickerRequest } = this.props;
    if (checkBuilderTickerRequest && ticker && callback) {
      checkBuilderTickerRequest({ tickers: [ticker] }, callback);
    }
  }

  csvOnClose = () => {
    // console.log('csvOnClose');
    this.props.setBuildPortfolioState({ tickerNotExist: [] });
    this.setState({
      csvVisible: false,
      csvDataList: [],
      validTickers: [],
      invalidTickers: []
    });
  }

  handleUploadSubmit = () => {
    const updatedTickers = [...this.state.validTickers, ...this.state.invalidTickers];
    const allTickers = updateTickerData(updatedTickers);
    let validTickers = [];
    let invalidTickers = [];
    allTickers.forEach((u) => {
      if(u.showInMissingTicker){
        invalidTickers.push(u)
      }else{
        validTickers.push(u)
      }
    })
    this.setState({
      validTickers,
      invalidTickers
    }, () => this.csvOnClickOk(allTickers));
  }

  csvOnClickOk = (dataJson) => {
    if (dataJson) {
      const finalTickers = dataJson.filter(item => item.validTicker).map(val => {
        let obj = Object.assign({}, val);
        if ('key' in obj) {
          delete obj.key;
        }
        if ('weight(%)' in obj) {
          delete obj['weight(%)'];
        }
        if ('loading' in obj) {
          delete obj.loading;
        }
        if ('message' in obj) {
          delete obj.message;
        }
        if ('validTicker' in obj) {
          delete obj.validTicker;
        }
        if('showInMissingTicker' in obj) {
          delete obj.showInMissingTicker;
        }
        if ('price' in obj) {
          delete obj.price;
        }
        if ('amount' in obj) {
          delete obj.amount;
        }
        if ('date' in obj) {
          delete obj.date;
        }
        if ('values' in obj) {
          delete obj.values;
        }
        if ('weight' in obj) {
          delete obj.weight;
        }
        if(val.realTimePrice){
          obj.real_time_price = val.realTimePrice;
          delete obj.realTimePrice;
        }
        if ('shares' in obj) {
          obj.shares = Number(obj.shares);
        }
        return obj;
      });
      this.props.setBuildPortfolioState({ tickerNotExist: [] });
      this.setState({ messageModal: false });
      if (this.state.fileName !== '') {
        this.props.uploadBuilderPortfolioRequest({ data: finalTickers, "file_name": this.state.fileName }, this.onFileSuccess);
      } else {
        this.props.uploadBuilderPortfolioRequest({ data: finalTickers }, this.onFileSuccess);
      }
    }
  }

  getUpdatedData = (data) => {
    const dataJson = JSON.parse(JSON.stringify(data));
    this.setState({ validTickers: data });
  }

  getFileName = (fileName) => {
    this.setState({
      fileName
    })
  }

  closeMessageModal = (doesClientExist) => {
    const {csvDataList} = this.state;
    this.setState({ messageModal: false, csvVisible: doesClientExist });
    let result = csvDataList.filter((u) => {
      if (typeof this.props.tickerNotExist.find((k) => k === u.ticker) === 'undefined') {
        return u;
      }
    });
    if(result.length == 0){
      this.props.setBuildPortfolioState({tickerNotExist: []});
    }
  }

  getTickerList = (items) => {
    return items.reduce((acc, curr, i) =>
    i !== 0 ? acc + `${items.length - 1 === i ? ` and  ` : ', '}` + curr : curr, '');
  }

  uploadModalToggle = () => {
    this.setState({
      csvFormatErrorMessage: '', // dont change this
      uplPrtModal: false,
    });
  }

  updateTickerStatus = (invalidData) => {
    const { checkSingleTickerValidity } = this.props;
    const { updatedTicker, selectedRecord } = invalidData;
    if (updatedTicker && updatedTicker !== selectedRecord.ticker) {
      const invalidTickers = this.state.invalidTickers.map(item => {
        if(item.key == selectedRecord.key){
          item.loading = true;
        }
        // item.isDisabled = true;
        return item;
      })
      this.setState({
        invalidTickers
      }, () => checkSingleTickerValidity(invalidData, this.updateCSVData));
    }
  }

  updateCSVData = (oldTicker, selectedRecord, updateStatus) => {
    let invalidTickers = this.state.invalidTickers.map(obj => {
      let item = Object.assign({}, obj);
      if(item.key == selectedRecord.key){
        item.ticker = selectedRecord.ticker;
        item.validTicker = updateStatus;
        item.message = updateStatus ? 'Valid Ticker' : 'Ticker not identified';
        item.loading = false;
        item.showInMissingTicker = true;
        item.realTimePrice = selectedRecord.realTimePrice || 'NA';
      }
      return item;
    })
    this.setState({
      invalidTickers
    })
  }

  deleteInvalidTickers = (selectedRecord) => {
    let invalidTickers = this.state.invalidTickers.filter(item => item.key !== selectedRecord.key);
    this.setState({
      invalidTickers
    })
  }

  render() {
    const { portfolioDropdownOpen, uplPrtModal, premiumModal, notify, isNotifyOpen, csvVisible, csvFormatErrorMessage } = this.state;
    const { portfolioList, view, premiumUser, uploadFilePartial, uploadFilePartialMsg, investmentFetchSuccess, setPortfolioState, children, className, tickerNotExist, csvValidating, fileFor, portfolioLoading } = this.props;

    let { csvDataList, validTickers, invalidTickers } = this.state;

    return (
      <div className={className}>
        <PremiumNoticeModal
          {...this.props}
          open={premiumModal}
          premiumUser={premiumUser}
          onClickOK={this.confirmPrintReport}
          onClickCancel={this.closePremiumModal}
          onToggle={this.premiumModalToggle}
          notify={notify}
          openNotify={this.openNotify}
          notifyToggle={this.notifyToggle}
        />

        <div onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          this.uploadFileFor()
        }}>
          {children}
        </div>

        {(this.state.uploadFileFor === fileFor) && (
          <div>
            <Modal
              isOpen={uplPrtModal}
              toggle={() => this.uploadModalToggle()}
              centered
              keyboard={true}
              backdrop
              className="premium-msg-modal account-modal"
              backdropClassName="premium-feature-process-modal-backdrop top-60px"
              modalClassName="top-60px"
            >
              <ModalBody className="ssf-modal__body" style={{ minHeight: 212 }}>
                {csvValidating && (
                  <div className="text-secondary text-center">
                    <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5"></i>
                  </div>
                )}
                {!csvValidating && (
                  <div className="premium-feature-process-modal-container">
                    <div className="header-wrapper">
                      <h1 className="text-align-left">Upload Portfolio</h1>
                    </div>
                    <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => this.uploadModalToggle()}></i>
                    <p className="content-wrapper">
                      <UploadFile
                        onFileSuccess={this.onFileSuccess}
                        getCSVData={this.getCSVData}
                        csvFormatErrorMessage={csvFormatErrorMessage}
                        getFileName={this.getFileName}
                      />
                    </p>
                  </div>
                )}
              </ModalBody>
            </Modal>

            {csvVisible && !this.state.messageModal && ( validTickers.length > 0 || invalidTickers.length > 0 ) && (
              <CSVPreviewUpload
                width={600}
                centered={true}
                footer={null}
                maskStyle={{ top: 60 }}
                visble={csvVisible}
                data={validTickers}
                invalidTickers={invalidTickers}
                deleteInvalidTickers={this.deleteInvalidTickers}
                loading={csvValidating}
                modalClassName="csv-preview-upload"
                modalTitle={<div class="title-head">Uploaded Portfolio</div>}
                onModalClose={this.csvOnClose}
                getDataList={this.getUpdatedData}
                onClickOk={this.handleUploadSubmit}
                checkTicker={this.checkTicker}
                updateTickerStatus={this.updateTickerStatus}
                disclaimerMessage={<div className="disclaimer-text mb-0"><i class="fas fa-asterisk"></i>Deleting a position will remove the security entirely from the analysis. The security's allocation will be pro-rated to the other securities in the portfolio.</div>}
              />
            )}

            {(this.state.messageModal) && (
              <Modal
                isOpen={this.state.messageModal}
                toggle={() => this.closeMessageModal(csvDataList.length > 0 && true)}
                centered
                backdrop
                keyboard={true}
                className="premium-msg-modal account-modal"
                backdropClassName="premium-feature-process-modal-backdrop top-60px"
                modalClassName="top-60px"
              >
                <ModalBody className="ssf-modal__body no-ticker-exist-msg-wrapper">
                  {csvValidating && (
                    <div className="text-secondary text-center">
                      <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5"></i>
                    </div>
                  )}
                  {(!csvValidating && !csvVisible && tickerNotExist.length > 0) && (
                    <div className="premium-feature-process-modal-container">
                      <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => this.closeMessageModal(csvDataList.length > 0 && true)}></i>
                      <p className="content-wrapper ">
                        <div style={{ marginBottom: '1.5rem' }}>
                          {csvDataList.length > 0 ?
                            `We did not find ${this.getTickerList(tickerNotExist)} in our universe of securities. The remaining tickers have been uploaded successfully.`
                            : 'Oops..we did not find any matches for the tickers uploaded.Please check the file and try again.'
                          }
                        </div>
                        <div className="col-12 cta-wrapper margin-left-51">
                          <button type="button" disabled={csvValidating} className="ssf-btn-primary btn secondary-btn btn btn-secondary" onClick={() => this.closeMessageModal(csvDataList.length > 0 && true)}>
                            <span>OK</span>
                          </button>
                        </div>
                      </p>
                    </div>
                  )}
                </ModalBody>
              </Modal>
            )}

            {(this.props.uploadFileFailure || this.props.uploadfileDuplicate) && !this.props.portfolioLoading && (
                <CustomAlert className="alert-windown-wrapper" color='danger'>
                  {this.props.uploadfileDuplicate ? 'Label already exists' : this.props.uploadFileFailureMsg}
                </CustomAlert>
              )
            }

            {isNotifyOpen
              && (
                <Notification
                  isNotifyOpen={isNotifyOpen}
                  notifyClassName="notify-card"
                  notifyBody="File uploaded successfully"
                />
              )
            }
          </div>
        )}

        {/*
          uploadFilePartial &&
          <Modal
            isOpen={uploadFilePartial}
            toggle={() => { investmentFetchSuccess({ uploadFilePartial: false, uploadFilePartialMsg: '' }); setPortfolioState({ onBoardScreen: false })} }
            centered
            keyboard={true}
            backdrop
            className="premium-msg-modal account-modal"
            backdropClassName="premium-feature-process-modal-backdrop"
            >
            <ModalBody className="ssf-modal__body">
              <div className="premium-feature-process-modal-container">
                <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => { investmentFetchSuccess({ uploadFilePartial: false, uploadFilePartialMsg: '' }); setPortfolioState({ onBoardScreen: false })}}></i>
                <p className="content-wrapper ">
                  <div style={{ marginBottom: '1.5rem' }}>{uploadFilePartialMsg}</div>
                  <div className="col-12 cta-wrapper margin-left-51">
                    <button type="button" className="ssf-btn-primary btn secondary-btn btn btn-secondary" onClick={() => { investmentFetchSuccess({ uploadFilePartial: false, uploadFilePartialMsg: '' }); setPortfolioState({ onBoardScreen: false })}}><span>OK</span></button>
                  </div>
                </p>
              </div>
            </ModalBody>
          </Modal>
        */}

      </div>
    );
  }
}


export default connect(
  state => ({
    // activeAccount: state.investment.activeAccount,
    // activeAccAccount: state.investment.activeAccAccount,
    // activePortAccount: state.investment.activePortAccount,
    // view: state.investment.view,
    // portfolioList: state.investment.portfolioList,
    premiumUser: state.auth.user.premiumUser,
    // uploadFilePartial: state.investment.uploadFilePartial,
    // uploadFilePartialMsg: state.investment.uploadFilePartialMsg,
    // uploadFileFailure: state.investment.uploadFileFailure,
    // uploadFileFailureMsg: state.investment.uploadFileFailureMsg,
    // uploadfileDuplicate: state.investment.uploadfileDuplicate,
    // portfolioLoading: state.investment.portfolioLoading,
    // inviteSentTo: state.profile.inviteSentTo,
    // inviteReferralCode: state.profile.inviteReferralCode,
    // inviteFailed: state.profile.inviteFailed,
    // inviteSuccess: state.profile.inviteSuccess,
    // inviteError: state.profile.error,
    // inviteMessage: state.profile.inviteMessage,
    // fundStats: getPortfolioStats(state),
    tickerNotExist: state.buildportfolio.tickerNotExist,
    csvValidating: createLoadingSelector(['CHECK_BUILDER_TICKER_EXIST'])({ loading: state.loading }),
    // portfolioLoading: state.investment.portfolioLoading,
    portfolioValue: state.profile.portfolioValue,
  }),
  {
    // investmentOptions,
    // fetchPortfolioData,
    subscriptionPlan,
    // investmentFetchSuccess,
    // setProfileState,
    downloadPortfolioReport,
    // setPortfolioReplace,
    // setPortfolioState,
    checkBuilderTickerRequest,
    // uploadPortfolio,
    getRealTimePriceForTickersRequest,
    setBuildPortfolioState,
    uploadBuilderPortfolioRequest,
    checkSingleTickerValidity,
    loadDemoCSVRequest,
    loadDemoCSVFetch,
    loadDemoCSVSuccess,
  }
)(UploadSection);

const Spinner = () => {
  return (
    <div className="spinner-wrapper">
      <div className="text-secondary text-center">
        <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5"></i>
      </div>
    </div>
  )
}

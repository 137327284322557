import React, { Component, useState } from 'react';
import { connect } from 'react-redux'

import { Card, CardBody, Row, Col, CustomInput } from 'reactstrap';

import { sponsorLogo } from 'DataSet';
import { FormatCurrency } from 'components/reports/shared/Utils';
import { TruncatedText } from 'components/sf/Common';
import { ViewChart } from 'components/reports/sf/ViewChart';
import { FeeReturnRisk } from './FeeReturnRisk';

import { setPortfolioReplace, setEnhancementData  } from 'actions/portfolio';
import { getPortfolioStats, getInvestmentWatchlistStats, getInvestmentPositionStats } from 'selectors/investment';

export class Item extends Component {
  constructor(props){
    super(props)

    props.data._update = this.update;
    props.data._select = (checked) => this.selectHandler({ target: { checked } });
    this.state = {
      selected: false
    }
  }

  // selectHandler = e => {
  //   const { data, selectable } = this.props;
  //   data._selected = e.target.checked;
  //   selectable[data._selected ? 'add' : 'remove'](data);
  // }

  selectHandlerR = (replaceTicker) => {
    // const { ticker, enhanceReplace, setPortfolioReplace } = this.props;
    // const index = enhanceReplace.findIndex(x => x.replace === ticker);
    // if (index >= 0) {
    //   enhanceReplace[index]['replace'] = replaceTicker
    //   setPortfolioReplace(enhanceReplace);
    // } else {
    //   enhanceReplace.push({ ticker, replace: replaceTicker })
    //   setPortfolioReplace(enhanceReplace);
    // }
  }

  update = (selected) => {
    const { data, selectable } = this.props;
    data._selected = selected;
    if (!selected) data._order = undefined;
    selectable.update(selected);
  }

  // componentWillReceiveProps(nextProps) {
  //   const { enhanceReplace, data, query } = nextProps;
  //   if (this.props.enhanceReplace != enhanceReplace) {
  //     const index = enhanceReplace.length > 0 ? enhanceReplace.findIndex(x => x.replace === data.ticker && x.query === query) : -1;
  //     const selected = index >= 0 ? true : false;
  //     if (index >= 0) {
  //       this.setState({ selected })
  //       document.getElementById('cb-'+data.index+data.id).checked = true;
  //     }
  //   }
  // }

  setReplaceTicker = (e, replaceTicker, ticker, query) => {
    const val = document.getElementById(e.target.id).checked;
    const { view, enhanceReplace, setPortfolioReplace, setEnhancementData, filterResultSet, portfolioFunds, marketFunds, watchlistFundstats, accountFundstats } = this.props;
    
    const index = enhanceReplace.findIndex(x => x.ticker === ticker);

    // const tickerWiseRet = arrayToHash(filterResultSet, CN['Ticker']);
    if (index >= 0) {
      if (val) {
        enhanceReplace[index]['replace'] = replaceTicker;
        enhanceReplace[index]['query'] = query;
        // if (tickerWiseRet[ticker] && tickerWiseRet[ticker].result && tickerWiseRet[ticker].result.funds) {
        //   const rA = tickerWiseRet[ticker].result.funds;
        //   const fA = rA.filter(i => i.ticker === replaceTicker)[0];

        //   // const pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
        //   let pF = {}
        //   if (view == 'account') {
        //     pF = accountFundstats.funds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
        //   } else if (view == 'watchlist') {
        //     pF = watchlistFundstats.positions.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
        //   } else if (view == 'mrktPl') {
        //     pF = marketFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
        //   } else {
        //     pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
        //   }
        //   enhanceReplace[index]['selectedPortfolioFunds'] = {
        //     ...fA,
        //     _pF: pF,
        //   };
        // }
        setPortfolioReplace(enhanceReplace);
        // this.setState({ enhanceReplace, query, replaceTicker});
      } else {
        enhanceReplace.splice(index, 1)

        // if (tickerWiseRet[ticker] && tickerWiseRet[ticker].result && tickerWiseRet[ticker].result.funds) {
        //   const rA = tickerWiseRet[ticker].result.funds;
        //   const fA = rA.filter(i => i.ticker === replaceTicker)[0];
        //   const pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
        //   enhanceReplace[index]['selectedPortfolioFunds'] = {
        //     ...fA,
        //     _pF: pF,
        //   };
        // }
        setPortfolioReplace(enhanceReplace);
        // this.setState({ enhanceReplace, query, replaceTicker});
      }
    } else {

      // if (tickerWiseRet[ticker] && tickerWiseRet[ticker].result && tickerWiseRet[ticker].result.funds) {
      //   const rA = tickerWiseRet[ticker].result.funds;
      //   const fA = rA.filter(i => i.ticker === replaceTicker)[0];

      //   // const pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
      //   let pF = {}
      //     if (view == 'account') {
      //       pF = accountFundstats.funds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
      //     } else if (view == 'watchlist') {
      //       pF = watchlistFundstats.positions.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
      //     } else if (view == 'mrktPl') {
      //       pF = marketFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
      //     } else {
      //       pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
      //     }
      //   enhanceReplace.push({ ticker, replace: replaceTicker, query, selectedPortfolioFunds: {
      //     ...fA,
      //     _pF: pF,
      //   }});
      // }
      // this.setState({ enhanceReplace, query, replaceTicker});
      setPortfolioReplace(enhanceReplace);
    }

    // if (ticker) {
    //   if (tickerWiseRet[ticker] && tickerWiseRet[ticker].result && tickerWiseRet[ticker].result.funds) {
    //     const rA = tickerWiseRet[ticker].result.funds;
    //     const fA = rA.filter(i => i.ticker === replaceTicker);
    //     if (fA.length) {
    //       setEnhancementData({
    //         enhancementData: fA,
    //       });
    //     }
    //   }
    // }
  }

  render() {
    console.log('Item')
    const { data, query, ticker, selectable, enhanceReplace, i} = this.props;
    // const id = 'cb-'+getId.next().value;
    // const id = 'cb-'+getId.next().value;
    const id = 'cb-'+ticker+data.index+data.id;

    // const index = enhanceReplace.length > 0 ? enhanceReplace.findIndex(x => x.replace === data.ticker && x.query === query) : -1;
    // const selected = index >= 0 ? true : false;

    if (data.Fee_Risk_Return) {
      data._frs = {
        fees: data.fees,
        yRet: data.Fee_Risk_Return['Annualized Average'],
        volt: data.Fee_Risk_Return['Annualized Volatility'],
        feesQ: data.feesq,
        yRetQ: data.Fee_Risk_Return.returnq,
        voltQ: data.Fee_Risk_Return.riskq,
      };
  
      return (
        <Card className="" style={{minWidth: '250px', width: '250px'}}>
          <CardBody className="p-0">
          <div className="p-1 border-bottom">
            <Row className="compact" style={{minHeight: '90px'}}>
              <Col className="col-auto d-flex align-items-center">
                <TickerPrice ticker={data.ticker} price={data.nav} />
              </Col>
              <Col className="col-dummy d-flex align-items-center">
                <NameSponsor name={data.short_name} sponsor={data.sponsor} category={data.category} />
              </Col>
            </Row>
            <CustomInput type="checkbox" id={id} inline onClick={(e) => this.props.setReplaceTicker(e, data.ticker, ticker, query)} className='p-top-right' title="Select" checked={enhanceReplace == data.ticker} />
          </div>
          <div className="px-3 py-2 border-bottom">
            <FeeReturnRisk data={data._frs} />
          </div>
          <div className="p-1">
            <ViewChart fund={data} i={0} queryVar={data._queryVar} data={data._viewData} chartType={data._viewChartType} size={'md'} />
          </div>
          </CardBody>
        </Card>
      )
    } else {
      return ''
    }

    
  }
}

const mapStateToProps = (state) => ({
  // enhanceReplace: state.portfolio.enhanceReplace,
})

const mapDispatchToProps = {
  setPortfolioReplace,
  setEnhancementData,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Item)

const NameSponsor = ({ name, sponsor, category }) => (
  <div className="w-100">
    <div className="pb-1">
      <ImageOrText img={sponsorLogo(sponsor)} text={sponsor} />
    </div>
    <h5 className="mb-1 text-ssf-blue-d2" style={{ fontWeight: 600 }}>
      <TruncatedText text={name} lines={2} />
    </h5>
    <h5 className="mb-0 text-gray-6">
      <TruncatedText text={category} lines={1} />
    </h5>
  </div>
)


const TickerPrice = ({ ticker, price }) => (
  <div className="d-flex flex-column justify-content-center align-items-center" style={{minWidth: '4rem'}}>
    <h5 className="mb-0 text-blue text-gray-5 ticker-size ticker-price">{ticker}</h5>
    <h4 className="mt-1 mb-0 ticker-price">{FormatCurrency(price, 2)}</h4>
  </div>
)


const ImageOrText = ({ img, text = '' }) => {
  const [valid, setValid] = useState(true);

  return (
    valid
    ? <img src={img} className="img-fluid" alt="" onError={() => setValid(false)} style={{height: '20px'}} />
    : <div className="sponsor-logo-placeholder" title={text}>{text}</div>
  );
}

const getId = (function* () {
  let n = 1;
  while (true) yield n++;
})();

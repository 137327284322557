import React from 'react';
import { useCountUp } from 'react-countup';

const SimpleHook = (loadPercent, loading) => {
  const duration = (loadPercent > 75) ? 2 : 6;
  const endValue = loadPercent*70;
  const end = !loading ? (
     (endValue <= 7000 && endValue > 0) ? endValue : 6999
  ) : 6999;
  const { countUp } = useCountUp({
    start: 0,
    end,
    duration,
  });
  return countUp;
};

const Spinner = ({ message, loadPercent, loading, styles }) => {
  const spinnnerDesign = (
    <div className={ !message ? "text-secondary text-center" : "text-secondary text-center spinnerMessage"} style={styles}>
      <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5"></i>
      {(message)
       && <h3 className="mt-4"><span>Searching the universe of </span><span>{SimpleHook(loadPercent, loading)}+</span><span> Funds!</span></h3>
      }
    </div>
  );
  return spinnnerDesign;
}

export default Spinner;

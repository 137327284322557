import axios from 'axios'
import { createAPIInstance } from './api'
import _ from 'lodash'
import qs from 'query-string'
import store from '../index';
import config from './../env'

export function getDymLogs(payload) {
  return createAPIInstance()
    .post('/dym-logs', payload, {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => response.data)
}

export function getFunds(payload) {
  if (store) {
    const state = store && store.getState();
    const { universe } = state.funds;
    if (!universe) window.location = window.location.href;
    // if (universe.length === 3) payload.universe = 'all';
    // else {
    //   const uni = _.uniqBy(universe).join();
    //   payload.universe = _.uniqBy(uni.split(',')).join();
    // }
  }

  return createAPIInstance()
    .get('/v1/funds', {
      params: payload,
    })
    .then(response => {response.data.http_status = response.status; response.data.http_statusText = response.statusText; return response.data})
}

export function discoverSearch(payload) {
  if (store) {
    const state = store && store.getState();
    const { universe } = state.funds;
    if (!universe) window.location = window.location.href;
  }

  let counter = localStorage.getItem('counter');
  let plusCounter = localStorage.getItem('plusCounter');

  if (counter === null || counter === 'null') {
    localStorage.removeItem('counter')
    localStorage.setItem('counter', 1);
    if (plusCounter === null || plusCounter === 'null') localStorage.setItem('plusCounter', 3);
  } else {
    if (counter == plusCounter) localStorage.setItem('plusCounter', parseInt(plusCounter,10) + 5);
    counter++;
    localStorage.setItem('counter',counter);
  }

  // if(config.universeShare && process.env.REACT_APP_SUB_DOMAIN == 'retail')
  // payload.universe = payload.universe + ",share";

  return createAPIInstance()
    .get('/discover-search', {
      params: payload,
    })
    .then(response => {response.data.http_status = response.status; response.data.http_statusText = response.statusText; return response.data})
}

export function getTrendingSearchThemeData(payload) {
  return createAPIInstance()
    .get('/trending-themes', { params: payload })
    .then(res => res)
}

export function fetchFundsDetails(payload) {
  let counter = localStorage.getItem('counter');
  let plusCounter = localStorage.getItem('plusCounter');

  if (counter === null || counter === 'null') {
    localStorage.removeItem('counter')
    localStorage.setItem('counter', 1);
    if (plusCounter === null || plusCounter === 'null') localStorage.setItem('plusCounter', 3);
  } else {
    if (counter == plusCounter) localStorage.setItem('plusCounter', parseInt(plusCounter,10) + 5);
    counter++;
    localStorage.setItem('counter',counter);
  }

  return createAPIInstance()
    .post('/v1/funds/details', JSON.stringify(payload), {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => response.data)
}

export function getSelectorAttrsData(payload){
  return createAPIInstance()
    .get('/user/custom-selector', { params: payload })
    .then(res => res.data)
}

export function fetchReturnsData(payload) {
  return createAPIInstance()
    .get('/returns-chart', { params: payload })
    .then(res => res.data)
}

export function fetchReturnsDataV1(payload) {
  return createAPIInstance()
    .get('/v1/returns-chart', { params: payload })
    .then(res => res.data)
}

export function fetchRegionsChart(payload) {
  return createAPIInstance()
    .get('/region-charts', { params: payload })
    .then(res => res.data)
}

export function fetchUpAndDownChart(payload) {
	return createAPIInstance()
	.get('v1/user/portfolio-benchmark', { params: payload })
	.then(res => res.data).catch(e=>{
		console.log(e)
	})
}

export function fetchQueries() {
  return createAPIInstance()
    .get('/queries', {
      headers: {
        Authorization: null
      }
    })
    .then(response => response.data)
}

export function fetchFunds(payload) {
  if (store) {
    const state = store && store.getState();
    const { universe } = state.funds;
    if (!universe) window.location = window.location.href;
    // if (universe.length === 2) payload.universe = 'all';
    else {
      const uni = _.uniqBy(universe).join();
      payload.universe = _.uniqBy(uni.split(',')).join();
    }
  }

  return createAPIInstance()
    .get('/v1/funds', {
      params: payload,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
    .then(response => response.data)
}

export function fetchCategories(payload) {
  return createAPIInstance()
    .get('/v1/categories', { params: payload })
    .then(response => response.data)
}

export function fetchFundCommissions(payload) {
  return createAPIInstance()
    .get('/v1/fund-commissions', { params: payload })
    .then(response => response.data)
}

export function fetchChartsV1(payload) {
  return createAPIInstance()
    .get('/v1/charts-api', { params: payload })
    .then(res => res.data)
}

export function fetchRealTimePrices(payload) {
  return createAPIInstance()
    .get('/realtime-prices', { params: payload })
    .then(response => response.data)
}

export function fetchRealTimePricesNav(payload) {
  return createAPIInstance()
    .get('/get-realtime-price-nav', { params: payload })
    .then(response => response.data)
}

export function trackFunds(payload) {
  return createAPIInstance()
    .post('user/account/transaction', JSON.stringify(payload), {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => res.data)
}

export function trackFundsRemove(payload) {
  return createAPIInstance()
    .post('user/account/transaction/remove', qs.stringify(payload))
    .then(res => res.data)
}

export function fetchBasePortfolios(payload) {
  return createAPIInstance()
    .get('/base-portfolios', { params: payload })
    .then(response => response.data)
}

export function fundWeights(payload) {
  return createAPIInstance()
    .post('fund-weights', JSON.stringify(payload), {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => res.data)
}

export function fetchSponsor(payload) {
  return createAPIInstance()
    .get('/sponsor-data', { params: payload })
    .then(res => res.data)
}

export function fetchCharts(payload) {
  return createAPIInstance()
    .get('/v1/charts-api', { params: payload })
    .then(res => res.data)
}

export function fetchTopTenHoldings(payload) {
  return createAPIInstance()
    .get('/top-ten-holdings', { params: payload })
    .then(res => res.data)
}

export function fetchAssetTypes(payload) {
  return createAPIInstance()
    .get('/asset-type', { params: payload })
    .then(res => res.data)
}

export function fetchESGScore(payload) {
  return createAPIInstance()
    .get('/ESG-score', { params: payload })
    .then(res => res.data)
}

export function fetchThemeScore(payload) {
  return createAPIInstance()
    .get('/theme-chart', { params: payload })
    .then(res => res.data)
}

export function fetchWeightHeld(payload) {
  return createAPIInstance()
    .get('/get-funds-weight', { params: payload })
    .then(res => res.data)
}

export function fetchDiversificationData(payload) {
  return createAPIInstance()
    .get('/diversification-chart', { params: payload })
    .then(res => res.data)
}

export function fetchRiskAdjustedReturn(payload) {
  return createAPIInstance()
    .get('/risk-adjusted-returns-chart', { params: payload })
    .then(res => res.data)
}


export function placeTDFundOrder(payload) {
  return createAPIInstance()
    .post('user/broker/tdameritrade/placeorder', JSON.stringify(payload), {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => res.data)
}

export function placeTradeItFundOrderPreview(payload) {
  return createAPIInstance()
    .post('user/broker/tradeit/previeworder', JSON.stringify(payload), {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => res.data)
}

export function placeTradeItFundOrder(payload) {
  return createAPIInstance()
    .post('user/broker/tradeit/placeorder', JSON.stringify(payload), {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => res.data)
}

export function searchAnalyticsAPI(payload) {
  return createAPIInstance()
    .post('user/search-analytics', JSON.stringify(payload), {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => res.data)
}

export function sendFailureQuery(query, callback) {
  return createAPIInstance()
    .post('user/failed-query', JSON.stringify(query), {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => res.data)
    .then(res => callback && callback())
    .catch(err => callback && callback())
}

export function getTrendingQueries() {
  return createAPIInstance()
    .get('/trending-searches')
    .then(res => res.data)
}

export function fetchfeeRiskReturn(payload) {
  return createAPIInstance()
    .post('fee-risk-return', JSON.stringify(payload), {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => res.data)
}

/* -- Download CSV of Searched Results -- */

export function getComputedSearchResults(payload) {
  return createAPIInstance()
    .get('computed-search', { params: payload })
    .then(response => response.data)
}

/* SELECTOR WITH TIME FRAME API */

export function getTimeFrame(payload) {
  return createAPIInstance()
    .post('selector-with-time-frame', JSON.stringify(payload), {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => res.data)
}

// SAVE DISCOVER PREF
export function saveDiscoverPref(payload) {
  return createAPIInstance()
    .post('user/user/preference',payload,{
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then(res => res.data)
}

// SFM Trade Order Api
export function executeTradeOrder (payload) {
  return createAPIInstance()
    .post('user/apex/trade/submit',JSON.stringify(payload),{
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => res.data)
}

// Get Basket Data
// curl -X GET \
//   'http://localhost:8000/api/user/basket-holdings?basket_ticker=ATBBASKET' \
//   -H 'authorization: Token 98d91805bb735d5fc26e651e4e720b2ce68736de' \
//   -H 'cache-control: no-cache' \
//   -H 'postman-token: 26dc4949-b7c7-a290-2921-2bda6eae8d2e'
export function getBasketData(payload) {
  console.log(payload);
  return createAPIInstance()
    .get('user/basket-holdings', { params: payload })
    .then(response => response.data)
}

/* get similar searches */
export function getSimilarSearches(query) {
  return createAPIInstance()
    .get('user/recommender' + (query ? '?q=' + query : ''))
    .then(res => res.data)
}

import React from 'react';
import { withHeaderFooter } from '../shared/HeaderFooter';

import UpDownTable from './UpDownTable';
import RenderScatterChart from '../shared/RenderScatterChart';
import ScatterCustomLegend from '../shared/ScatterCustomLegend';

import { getPerformaceChartdata } from '../shared/Utils';
import DateRangeSubHeading from 'components/reportsV2/shared/DateRangeSubHeading';
import { BenchmarkDisclaimer } from 'components/reportsV2/sf/Common'

const UpDownTableWrap = (props) => {
  const { selFunds, yearlyReturnsData, totFunds, breakPoint, bestTicker,printSelection,benchmark } = props;
  return (
    <React.Fragment>
      {printSelection.includes('perf-analytics-up-down-capture') && <UpDownTableContent
        selFunds={selFunds}
        totFunds={totFunds}
        breakPoint={breakPoint}
        pageName={'perf-analytics-up-down-capture'}
        bestTicker={bestTicker}
        benchmark={benchmark}
      />}
      {totFunds > breakPoint && printSelection.includes('perf-analytics-up-down-capture') &&(
        <RenderChartSection
          selFunds={selFunds}
          totFunds={totFunds}
          pageName={'perf-analytics-up-down-capture'}
        />
      )}
    </React.Fragment>
  )
};

export default UpDownTableWrap;

const UpDownTableContent = withHeaderFooter((props) => {
  const { selFunds, totFunds, breakPoint, bestTicker,benchmark } = props;
  return (
    <div className="content">
      <div className="pr-v2-perf-analytics">
        <div className="pr-v2-header">
          <h3>Performance Analytics : Upside-Downside Capture</h3>
          <DateRangeSubHeading/>
        </div>
        <div className="pr-v2-perf-analytics">
          <div className="perf-component-wrapper">
            <UpDownTable benchmark ={benchmark} bestTicker={bestTicker} selFunds={selFunds} />
            {totFunds <= breakPoint && (
              <RenderChartSection
                selFunds={selFunds}
                totFunds={totFunds}
                showHeaderFooter={false}
              />
            )}
          </div>
        </div>
        <BenchmarkDisclaimer/>
        {/*<p className={'asteriskDisclaimer'}>vs Benchmark * – MPT Benchmark is the primary benchmark assigned to investments based on their Broad Morningstar category. This category-level benchmark is used to calculate Modern Portfolio Theory statistics.</p>*/}
      </div>
    </div>
  )
});

const RenderChartSection = withHeaderFooter((props) => {
  const { selFunds, totFunds } = props;
  return(
      <div className={`content ${totFunds > 5 ? 'pr-v2-perf-analytics' : ''}`}>
        <div className={totFunds > 5 ? 'perf-component-wrapper' : ''}>
          <RenderScatterChart
            chartTitle="# of Months"
            chartData={getPerformaceChartdata(selFunds, 'numOfMonthsKeys')}
            axisLabels={['Down Months', 'Up Months']}
            axisUnits={['', '']}
            tickConfig={{
              allowDecimals: false,
            }}
            showHorizontalGridLines
          />
          <RenderScatterChart
            chartTitle="Average Up Return vs Average Down Return"
            chartData={getPerformaceChartdata(selFunds, 'avgRetvsMarketKeys')}
            axisLabels={['Average Down Return', 'Average Up Return']}
            axisUnits={['%', '%']}
            tickConfig={{
              allowDecimals: true,
            }}
            showHorizontalGridLines
          />
          <ScatterCustomLegend data={selFunds}/>
        </div>
      </div>
    )
});
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Row, Col, Card, Menu, Collapse, Tooltip } from 'antd';
import _ from 'lodash';
import { addEventToAnalytics } from 'Utils';

import { createLoadingSelector } from 'selectors/loading-selector';
import { tickerDataRequest, deleteCustomCard, setBuildPortfolioState, searchForNewQueryRequest } from 'actions/buildportfolio';

import { customScroll } from 'layouts/utils';
import { getSelectedFundsCount } from 'layouts/WebDashboard/BuildPortfolio/utils';

import CalculatorSlider from '../../../Elements/CalculatorSlider';
import InputSearch from '../../../Elements/InputSearch';
import ListView from './ListView';

const { SubMenu } = Menu;
const { Panel } = Collapse;

const buildPortfolioMenus = [{
  label: 'Equal',
  icon: null,
  disabled: true,
  className: 'disabled-menu',
  tooltip: null,
}, {
  label: 'Risk Parity',
  icon: null,
  disabled: true,
  style: { margin: 0 },
  className: 'disabled-menu',
  tooltip: children => (
    <Tooltip placement="top" title="Coming soon">
      {children}
    </Tooltip>
  ),
}];
class EquityContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fundCollectionList: [],
      activeTabPane: '1',
      equityTabData: props.topTabHeaderMenu.filter((t) => t.key === 'equityTab')[0],
    };
  }

  componentDidMount() {
    customScroll();
    const { data } = this.props;
    if (data && data.length) {
      this.setState({ fundCollectionList: data }, () => {
        // this.calculateSliderValue();
      });
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.data !== prevProps.data){
      const { buildportfolio } = this.props;
      this.props.data.map((l) => {
        // const getRowDataSliderValue = buildportfolio.equityTab.data.find((t) => t.query === l.query);
        if (typeof buildportfolio.equityTab.selectedFunds[l.query] !== 'undefined') {
          const selF = buildportfolio.equityTab.selectedFunds[l.query];
          l.result.funds.map((w) => {
            w._sliderValue = 0;
            const found = selF.find((o) => o.ticker === w.ticker);
            if (found && typeof found !== 'undefined' && (found.ticker === w.ticker)) {
              w._sliderValue = l.sliderValue/selF.length;
            }
          });
        }
      });
      this.setState({fundCollectionList: this.props.data});
    }
  }

  calculateSliderValue = (value, label) => {
    //filter the header reqquired for slider wt calc after the change
    //it may be less than the total in case of manual slider chnage
    //or it may be all if the components loads for the first time or weightingMethodology chnage
    const topTab = this.props.topTabHeaderMenu.find((item) => item.key === 'equityTab');
    let { weightingMethodology, equityTab, setBuildPortfolioState } = this.props;
    let totalSum = 0.0, filterObjsTotalSum = 0.0, diff = 0.0;
    let filterObjs = [], unfilterObjs = [];

    //delete this code after testing the equal and manual mode
    // if(weightingMethodology == 'Equal') {
    //   filterObjs = JSON.parse(JSON.stringify(this.state.fundCollectionList));
    //   filterObjs.forEach((item,index) => {filterObjs[index].sliderValue = 1});
    //   console.log('sid eqaul', filterObjs)
    // }
    // else {
      this.state.fundCollectionList.forEach(e => {
        let item = JSON.parse(JSON.stringify(e));
        if(weightingMethodology == 'Equal') item.sliderValue = 1
        totalSum += item.sliderValue;

        if((item.label != label && item.locked == false) || weightingMethodology == 'Equal') { //filter condition
          filterObjsTotalSum += item.sliderValue;
          filterObjs.push(item);
        }
        else {
          unfilterObjs.push(item);
        }
      });
    // }

    if(filterObjs.length>0) {
      diff = topTab.sliderValue - totalSum;
      filterObjs.forEach((obj, index) => {
        let ratio = obj.sliderValue == 0 ? 0 : obj.sliderValue / filterObjsTotalSum;
        let wt = diff * ratio;
        filterObjs[index].sliderValue = Math.round(obj.sliderValue + wt);
      });

      filterObjs = _.sortBy(filterObjs.concat(unfilterObjs),['position']);

      equityTab.data = filterObjs;
      this.setState({ equityTab });
      if (setBuildPortfolioState) {
        setBuildPortfolioState({ equityTab : equityTab })
      }
    }
  }

  deleteCard = ({ item, index, searchedTicker, forTicker, forQuery }) => {
    const { deleteCustomCard, activeTab } = this.props;
    deleteCustomCard({
      item,
      index,
      searchedTicker,
      forTicker,
      forQuery,
      tab: activeTab,
    });
  }

  menuHandleClick = (key) => {
    if (key && typeof key.key !== 'undefined') {
      const { equityTab } = this.props;
      const { weightingMethodology } = equityTab;
      equityTab.weightingMethodology = key.key;
      const { setBuildPortfolioState } = this.props;
      if (setBuildPortfolioState) {
        setBuildPortfolioState({ equityTab });
      }
    }
  }

  runWeightAllocation = (allStateMappedData, topTabHeaderMenu) => {
    const equityTabFunds = allStateMappedData.equityTabFunds;
    const bondTabFunds = allStateMappedData.bondTabFunds;
    const thematicTabFunds = allStateMappedData.thematicTabFunds;
    const currentEquitySliderValue = topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue;

    const foundTabWithWeights = topTabHeaderMenu.filter((x) => x.sliderValue !== 0);

    if (equityTabFunds && bondTabFunds && thematicTabFunds) {
      if (currentEquitySliderValue === 0) {
        let old1 = 0, old2 = 0, diff = 54;
        old1 = topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue;
        old2 = topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue

        topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue = diff;
        topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue = old1 + ((old1 == 0 ? 0 :old1/(old1+old2))*-diff);
        topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue = old2 + ((old2 == 0 ? 0 :old2/(old1+old2))*-diff);
      }
    } else if (equityTabFunds && bondTabFunds && (thematicTabFunds === 0)) {
      if (currentEquitySliderValue === 0) {
        let old1 = 0, old2 = 0, diff = 60;
        old1 = topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue;
        old2 = topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue

        topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue = diff;
        topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue = old1 + ((old1 == 0 ? 0 :old1/(old1+old2))*-diff);
        topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue = old2 + ((old2 == 0 ? 0 :old2/(old1+old2))*-diff);
      }
    } else if (equityTabFunds && (bondTabFunds === 0) && thematicTabFunds) {
      if (currentEquitySliderValue === 0) {
        let old1 = 0, old2 = 0, diff = 90;
        old1 = topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue;
        old2 = topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue

        topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue = diff;
        topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue = old1 + ((old1 == 0 ? 0 :old1/(old1+old2))*-diff);
        topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue = old2 + ((old2 == 0 ? 0 :old2/(old1+old2))*-diff);
      }
    } else if ((equityTabFunds === 0) && bondTabFunds && thematicTabFunds) {
      if (currentEquitySliderValue === 0) {
        let old1 = 0, old2 = 0, diff = -1*(100-(topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue + topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue));
        old1 = topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue;
        old2 = topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue

        topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue = 0;
        topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue = old1 + ((old1 == 0 ? 0 :old1/(old1+old2))*-diff);
        topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue = old2 + ((old2 == 0 ? 0 :old2/(old1+old2))*-diff);
      }
    } else if (foundTabWithWeights.filter((a) => (a.key !== 'equityTab' && a.sliderValue !== 0)).length === 1) {
      foundTabWithWeights[0].sliderValue = 100;
    }
  }

  executeMe = (forQuery, newPropsSelectedFunds, currentTab, tabRows, allStateMappedData, sumofRows, topTabHeaderMenu) => {
    console.log(forQuery);
    console.log(allStateMappedData);
    console.log(currentTab);

    const referenceKey = 'equityTab';
    const globalTabWeight = topTabHeaderMenu.find((i) => i.key === 'equityTab').sliderValue;
    console.log('sumofRows ======> ', sumofRows, globalTabWeight);

    // console.log(`--------- ${referenceKey} ---------`);
    const currentSectionFunds = allStateMappedData[`${referenceKey}Section`];
    // check if (how many row has selected funds)

    if (allStateMappedData[`${referenceKey}Funds`] > 0) {
      // console.log('yes we have selected funds');
      // console.log(newPropsSelectedFunds);

      if (Object.keys(currentSectionFunds).length > 0) {
        // check if all rows weights are 0 ??
        // console.log(Object.keys(currentSectionFunds));
        Object.keys(currentSectionFunds).forEach((p) => {

          const sfsdff = Object.keys(currentSectionFunds).filter((r) => currentSectionFunds[r] !== 0).length;
          console.log(sfsdff);
          console.log(p);

          // console.log(tabRows.filter((s) => s.sliderValue !== 0).length === tabRows.length);

          if (((tabRows.filter((s) => s.sliderValue !== 0).length === tabRows.length) && currentSectionFunds[p] > 0) && tabRows.find((s) => s.query === p) && sfsdff === tabRows.length && sumofRows === globalTabWeight) {
            console.log(`DO NOT CHANGE WEIGHT FOR ${p}`);
          } else {
            if (currentSectionFunds[p] > 0) {
              if (tabRows && typeof tabRows.length !== 'undefined' && tabRows.length > 0) {
                if (tabRows.find((s) => s.query === p) && typeof tabRows.find((s) => s.query === p) !== 'undefined' && tabRows.find((s) => s.query === p).sliderValue === 0) {
                  // tabRows.find((s) => s.query === p).sliderValue = (globalTabWeight)/sfsdff;

                  let diff = (10*globalTabWeight)/100;
                  console.log(diff);

                  tabRows.find((s) => s.query === forQuery).sliderValue = diff; //[USA]
                  // console.log('here');

                  let sum = 0;
                  tabRows.filter((s) => {// [USA, DM,EM]
                    console.log(s.query, p, forQuery, s.sliderValue);
                    if ((s.query !== forQuery && s.sliderValue > 0)) {
                      sum = sum+s.sliderValue;
                      return s
                    }
                  });
                  console.log(sum);

                  tabRows.filter((s, i) => {
                    if ((s.query !== forQuery && s.sliderValue > 0)) {
                      console.log(sum);
                      console.log(s.sliderValue);
                      console.log(s.sliderValue/(sum));
                      console.log(diff);
                      console.log(s.sliderValue + ((s.sliderValue == 0 ? 0 :s.sliderValue/(sum))*-diff));
                      s.sliderValue = s.sliderValue + ((s.sliderValue == 0 ? 0 :s.sliderValue/(sum))*-diff);
                    }
                  });

                } else {
                  console.log('-----exit 1-----');
                }
              } else {
                console.log('-----exit 2-----');
              }
            } else {
              console.log(`SET CURRENT ROW ${p} WEIGHT to 0 and split weight with other rows`);
              // console.log('WEIGHT for Other rows', (globalTabWeight)/sfsdff);

              if (tabRows && typeof tabRows.length !== 'undefined' && tabRows.length > 0) {
                let diff = tabRows.find((s) => s.query === p).sliderValue;
                tabRows.find((s) => s.query === p).sliderValue = 0;

                // tabRows.find((s) => s.query === forQuery).sliderValue = diff; //[USA]
                // console.log('here');

                let sum = 0;
                tabRows.filter((s) => {// [USA, DM,EM]
                  // console.log(s.query, p, forQuery);
                  if ((s.query !== forQuery && s.sliderValue > 0)) {
                    sum = sum+s.sliderValue;
                    return s
                  }
                });

                tabRows.filter((s, i) => {
                  if ((s.query !== forQuery && s.sliderValue > 0)) {
                    s.sliderValue = s.sliderValue + ((s.sliderValue == 0 ? 0 :s.sliderValue/(sum))*diff);
                  }
                });

                // if (tabRows.find((s) => s.query !== p && currentSectionFunds[p] !== 0) && typeof tabRows.find((s) => s.query !== p && currentSectionFunds[p] !== 0) !== 'undefined') {
                //   tabRows.find((s) => s.query !== p && currentSectionFunds[p] !== 0).sliderValue = (globalTabWeight)/sfsdff;
                // }
                // tabRows.filter((l) => l.query !== p).map(e => e.sliderValue = (topTabHeaderMenu.find((i) => i.key === currentTab.key).sliderValue)/sfsdff);
              }
            }
          }
          // console.log(tabRows);
        })
      }

    } else {
      console.log(`NO FUNDS SELECTED for ${referenceKey}`);
      // then allocate weight to current ROW (using forQuery)
      if (tabRows && typeof tabRows.length !== 'undefined' && tabRows.length > 0) {
        tabRows.map((item, index) => { // tabRows: ['USA', 'EM', 'DM']
          // console.log(`active row ------ ${forQuery} ------`);
          if (item.query === forQuery) { // item: 'USA'
            // console.log(newPropsSelectedFunds);

            if (Object.keys(newPropsSelectedFunds).length) {
              Object.keys(newPropsSelectedFunds).map((p) => {

                // console.log(newPropsSelectedFunds[p]);

                if (newPropsSelectedFunds[p].length > 0) {
                  // console.log('set equity slidervalue', topTabHeaderMenu.find((i) => i.key === currentTab.key).sliderValue);
                  item.sliderValue = topTabHeaderMenu.find((i) => i.key === currentTab.key).sliderValue;
                } else {
                  // console.log('set equity slidervalue', 0);
                 item.sliderValue = 0;
               }
             });
            }
          }
        });
        if (tabRows.filter((w) => w.sliderValue !== 0).length === 0) {
          console.log('ALL ROWS WEIGHT ARE 0');
          currentTab.sliderValue = 0;
          console.log('runWeightAllocation');
          this.runWeightAllocation(allStateMappedData, topTabHeaderMenu);
        }
      }
    }
  }

  getSelectedFunds = ({ selectedFunds, forQuery, oldSelectedfunds }) => {
    const { equityTab, setBuildPortfolioState, topTabHeaderMenu, buildportfolio } = this.props;
    const oldHeaderMenuState = JSON.parse(JSON.stringify(buildportfolio.tabHeaderMenu));
    const oldEquityTab = JSON.parse(JSON.stringify(buildportfolio.equityTab));
    const oldBondTab = JSON.parse(JSON.stringify(buildportfolio.bondTab));
    const oldThematicTab = JSON.parse(JSON.stringify(buildportfolio.thematicTab));
    const oldEquityData = JSON.parse(JSON.stringify(buildportfolio.equityTab.data));
    const oldThematicData = JSON.parse(JSON.stringify(buildportfolio.thematicTab.data));
    const oldBondData = JSON.parse(JSON.stringify(buildportfolio.bondTab.data));
    const oldBuildportfolioState = JSON.parse(JSON.stringify(buildportfolio));
    // const tabHeaderMenu = topTabHeaderMenu;//JSON.parse(JSON.stringify(topTabHeaderMenu));

    // console.log(oldEquityData);
    // console.log(oldSelectedfunds);
    // console.log(Object.keys(oldSelectedfunds).length);
    // console.log(oldEquityData.find((g) => g.query === forQuery));
    // console.log(typeof oldSelectedfunds[forQuery] !== 'undefined');
    // console.log(typeof oldSelectedfunds[forQuery] !== 'undefined' && oldSelectedfunds[forQuery]);
    // console.log(typeof oldSelectedfunds[forQuery] !== 'undefined' && oldSelectedfunds[forQuery].length > selectedFunds.length);
    // console.log( typeof oldSelectedfunds[forQuery] !== 'undefined' && oldSelectedfunds[forQuery].length, selectedFunds.length);
    // console.log(oldEquityData.find((g) => g.query === forQuery));
    // console.log(oldEquityData.find((g) => g.query === forQuery).sliderValue <= 0);
    //
    // console.log(oldEquityTab && Object.keys(oldSelectedfunds).length && typeof oldSelectedfunds[forQuery] !== 'undefined' && (oldSelectedfunds[forQuery].length > selectedFunds.length) && oldEquityData.find((g) => g.query === forQuery) && oldEquityData.find((g) => g.query === forQuery).sliderValue <= 0);

    if (oldEquityTab && Object.keys(oldSelectedfunds).length && typeof oldSelectedfunds[forQuery] !== 'undefined' && (oldSelectedfunds[forQuery].length > selectedFunds.length) && oldEquityData.find((g) => g.query === forQuery) && oldEquityData.find((g) => g.query === forQuery).sliderValue <= 0) {
      //delete OPeration NO IMPACT
      console.log('delete OPeration NO IMPACT');
      oldEquityData.find((g) => g.query === forQuery).sliderValue = 0;
      equityTab.data.find((g) => g.query === forQuery).sliderValue = 0;
      if (typeof selectedFunds !== 'undefined' && forQuery) {
        const filteredArray = equityTab.selectedFunds[forQuery];
        if (equityTab.selectedFunds[forQuery]) {
          equityTab.selectedFunds[forQuery] = selectedFunds;
        } else {
          equityTab.selectedFunds = {
            ...equityTab.selectedFunds,
            [forQuery]: selectedFunds,
          };
        }

        if (setBuildPortfolioState) {
          setBuildPortfolioState({
            equityTab: buildportfolio.equityTab,
          });
        }

      }

    } else {
      if (typeof selectedFunds !== 'undefined' && forQuery) {
        const filteredArray = equityTab.selectedFunds[forQuery];
        if (equityTab.selectedFunds[forQuery]) {
          equityTab.selectedFunds[forQuery] = selectedFunds;
        } else {
          equityTab.selectedFunds = {
            ...equityTab.selectedFunds,
            [forQuery]: selectedFunds,
          };
        }

        const getStateMappedData = getSelectedFundsCount({ allStates: buildportfolio, tab: 'equityTab' });
        // console.log(getStateMappedData);

        topTabHeaderMenu.forEach((item, i) => {
          // if all tabs has 0 weights
          if (topTabHeaderMenu[0].sliderValue === 0 && topTabHeaderMenu[1].sliderValue === 0 && topTabHeaderMenu[2].sliderValue === 0) {
            // then current active tab will be 100; for.e.g: equityTab: 100%
            if (item.key === 'equityTab') {
              item.sliderValue = 100;
              if (equityTab.data) {
                let sumofRows = 0;
                // console.log(equityTab.data);
                equityTab.data.map((k) => {
                  sumofRows += k.sliderValue;
                });
                this.executeMe(forQuery, equityTab.selectedFunds, item, equityTab.data, getStateMappedData, sumofRows, topTabHeaderMenu);
              }
            }
          } else {
            // console.log('No, some tabs have weights');
            // before assigning weight to equityTab check if it is having funds ?
            console.log('runWeightAllocation');
            this.runWeightAllocation(getStateMappedData, topTabHeaderMenu);

            if (item.key === 'equityTab') {
              if (equityTab.data) {
                let sumofRows = 0;
                equityTab.data.map((k) => {
                  sumofRows += k.sliderValue;
                });
                this.executeMe(forQuery, equityTab.selectedFunds, item, equityTab.data, getStateMappedData, sumofRows, topTabHeaderMenu);
              }
            }

          }
        });

        // current weight allocation logic
        // for specific row e.g: USA row.
        // const getRowDataSliderValue = equityTab.data.find((t) => t.query === forQuery);
        // equityTab.selectedFunds[forQuery].forEach((y) => {
        //   if (getRowDataSliderValue && typeof getRowDataSliderValue !== 'undefined') {
        //     y._sliderValue = getRowDataSliderValue.sliderValue/equityTab.selectedFunds[forQuery].length;
        //   }
        // });

        // const getRowDataSliderValue = buildportfolio.equityTab.data.find((t) => t.query === forQuery);
        // equityTab.selectedFunds[forQuery].forEach((y) => {
        //   if (getRowDataSliderValue && typeof getRowDataSliderValue !== 'undefined') {
        //     y._sliderValue = getRowDataSliderValue.sliderValue/equityTab.selectedFunds[forQuery].length;
        //   }
        // });

        buildportfolio.equityTab.data.map((l) => {
          l.result.funds.map((w) => {
            // w._sliderValue = 0;
            if(l.query && l.sliderValue != oldEquityData.find((o) => o.query === l.query).sliderValue){
              w._sliderValue = 0;
              const diff = l.sliderValue - oldEquityData.find((o) => o.query === l.query).sliderValue
              // console.log('sid30', l.query, forQuery, oldEquityData, buildportfolio.equityTab.data)
              if (buildportfolio.equityTab.selectedFunds[l.query] && typeof buildportfolio.equityTab.selectedFunds[l.query] !== 'undefined') {
                //selected funds
                // console.log('sid31')
                //for the data
                const found = buildportfolio.equityTab.selectedFunds[l.query].find((o) => o.ticker === w.ticker);
                if (found && typeof found !== 'undefined' && (found.ticker === w.ticker)) {
                  // console.log('sid32')
                  if(buildportfolio.equityTab.selectedFunds[l.query].length && buildportfolio.equityTab.selectedFunds[l.query].length == 1){
                    // console.log('sid321')
                    w._sliderValue = l.sliderValue/buildportfolio.equityTab.selectedFunds[l.query].length;
                  }
                  else if(buildportfolio.equityTab.selectedFunds[l.query].length && buildportfolio.equityTab.selectedFunds[l.query].length > 1){
                    //diff
                    //ratio =
                    //wt
                    // console.log('sid322')
                    const oldFound = oldEquityTab.selectedFunds[l.query].find((o) => o.ticker === w.ticker);
                    let ratio = oldFound.sliderValue / oldEquityData.find((o) => o.query === l.query).sliderValue;
                    let wt = diff * ratio;
                    w._sliderValue = oldFound.sliderValue + wt;
                  }
                }
                //for all selected funds
                buildportfolio.equityTab.selectedFunds[l.query].forEach((y) => {
                  if (y && typeof y !== 'undefined') {
                    // console.log('sid33', y)
                    if(buildportfolio.equityTab.selectedFunds[l.query].length == 1) {
                      // console.log('sid331')
                      y._sliderValue = l.sliderValue/buildportfolio.equityTab.selectedFunds[l.query].length;
                    }
                    else if(buildportfolio.equityTab.selectedFunds[l.query].length > 1){
                      // console.log('sid332')
                      //diff
                      //ratio
                      //wt
                      const oldFound = oldEquityTab.selectedFunds[l.query].find((o) => o.ticker === y.ticker);
                      // console.log('sid334',oldEquityTab, oldFound, oldEquityData, l.query, w.ticker)
                      let ratio = oldFound._sliderValue / oldEquityData.find((o) => o.query === l.query).sliderValue;
                      let wt = diff * ratio;
                      y._sliderValue = oldFound._sliderValue + wt;
                      // console.log('sid335',diff, ratio, wt, oldFound, oldEquityData.find((o) => o.query === l.query));
                    }
                  }
                });
              }
            }
            else if(l.query && l.sliderValue == oldEquityData.find((o) => o.query === l.query).sliderValue && l.query == forQuery){
              w._sliderValue = 0;
              // console.log('sid30', l.query, forQuery, oldEquityData, buildportfolio.equityTab.data)
              if (buildportfolio.equityTab.selectedFunds[l.query] && typeof buildportfolio.equityTab.selectedFunds[l.query] !== 'undefined') {
                //selected funds
                // console.log('sid31')
                //for the data
                const found = buildportfolio.equityTab.selectedFunds[l.query].find((o) => o.ticker === w.ticker);
                const oldFound = oldEquityTab.selectedFunds[l.query].find((o) => o.ticker === w.ticker);
                if (found && typeof found !== 'undefined' && oldFound && typeof oldFound !== 'undefined' && (found.ticker === w.ticker)) {
                  // console.log('sid32')
                  // console.log('sid321')
                  //if length == 1
                  // add
                  //delete
                  if(buildportfolio.equityTab.selectedFunds[l.query].length == 1){
                    // console.log('pratik 1')
                    w._sliderValue = l.sliderValue/buildportfolio.equityTab.selectedFunds[l.query].length;
                  }
                  else if(buildportfolio.equityTab.selectedFunds[l.query].length >= oldEquityTab.selectedFunds[l.query].length
                          && oldEquityTab.selectedFunds[l.query].find((o) => o._sliderValue === 0)
                          && oldSelectedfunds && oldSelectedfunds[l.query] && oldSelectedfunds[l.query].length < selectedFunds.length) {
                    //add
                    // if fund == 0 add 10% diff
                    if(oldFound._sliderValue == 0){
                      w._sliderValue = (10*l.sliderValue)/100;
                      // console.log('pratik add 1', w.ticker, w._sliderValue)
                    }
                    else{
                      let diff = -(10*l.sliderValue)/100;
                      let ratio = oldFound._sliderValue / oldEquityData.find((o) => o.query === l.query).sliderValue;
                      let wt = diff * ratio
                      w._sliderValue = oldFound._sliderValue + wt;
                      // console.log('pratik add >1', w.ticker, w._sliderValue, diff, ratio, wt)
                    }
                    // console.log('pratik add', w.ticker, w._sliderValue)
                  }
                  else if(buildportfolio.equityTab.selectedFunds[l.query].length >= oldEquityTab.selectedFunds[l.query].length
                          && !oldEquityTab.selectedFunds[l.query].find((o) => o._sliderValue === 0)
                          && oldSelectedfunds && oldSelectedfunds[l.query] && oldSelectedfunds[l.query].length > selectedFunds.length) {
                    //delete check
                    //_.differenceBy([{ 'x': 2 }, { 'x': 1 }], [{ 'x': 1 }], 'x');
                    // old , new
                    let diffObj = _.differenceBy(oldSelectedfunds[l.query], selectedFunds, 'ticker');
                    if(diffObj.length){
                      let filterArrSum = _.sumBy(oldSelectedfunds[l.query].filter(o => o.ticker != diffObj[0].ticker),'_sliderValue');
                      // _.sumBy(objects, 'n');
                      let diff = diffObj[0]._sliderValue;
                      let ratio = oldFound._sliderValue / filterArrSum;
                      let wt = diff * ratio
                      w._sliderValue = oldFound._sliderValue + wt;

                      // console.log('pratik delete check', w.ticker, oldFound._sliderValue, diff, ratio, wt, w._sliderValue)
                    }
                  }
                  else if(buildportfolio.equityTab.selectedFunds[l.query].length < oldEquityTab.selectedFunds[l.query].length
                          && oldSelectedfunds && oldSelectedfunds[l.query] && oldSelectedfunds[l.query].length > selectedFunds.length) {
                    //delete
                    //_.differenceBy([{ 'x': 2 }, { 'x': 1 }], [{ 'x': 1 }], 'x');
                    // old , new
                    let diffObj = _.differenceBy(oldEquityTab.selectedFunds[l.query], buildportfolio.equityTab.selectedFunds[l.query], 'ticker');
                    if(diffObj.length){
                      let filterArrSum = _.sumBy(oldEquityTab.selectedFunds[l.query].filter(o => o.ticker != diffObj[0].ticker),'_sliderValue');
                      // _.sumBy(objects, 'n');
                      let diff = diffObj[0]._sliderValue;
                      let ratio = oldFound._sliderValue / filterArrSum;
                      let wt = diff * ratio
                      w._sliderValue = oldFound._sliderValue + wt;
                      // console.log('pratik delete', diffObj, w.ticker, w._sliderValue)
                    }
                  }
                  else{
                    // console.log('pratik else')
                    w._sliderValue = l.sliderValue/buildportfolio.equityTab.selectedFunds[l.query].length;
                  }

                  // console.log('pratik 56', w.ticker, w._sliderValue, oldFound && oldFound._sliderValue, buildportfolio.equityTab.selectedFunds[l.query].length, oldEquityTab.selectedFunds[l.query].length)
                // }
                //for all selected funds
                  buildportfolio.equityTab.selectedFunds[l.query].forEach((y) => {
                    const oldFound = oldEquityTab.selectedFunds[l.query].find((o) => o.ticker === y.ticker);
                    if (y && typeof y !== 'undefined') {
                      // console.log('sid33', y)
                      // console.log('sid331')
                      if(buildportfolio.equityTab.selectedFunds[l.query].length == 1){
                        // console.log('pratik 1')
                        y._sliderValue = l.sliderValue/buildportfolio.equityTab.selectedFunds[l.query].length;
                      }
                      else if(buildportfolio.equityTab.selectedFunds[l.query].length >= oldEquityTab.selectedFunds[l.query].length
                              && oldEquityTab.selectedFunds[l.query].find((o) => o._sliderValue === 0)
                              && oldSelectedfunds && oldSelectedfunds[l.query] && oldSelectedfunds[l.query].length < selectedFunds.length) {
                        //add
                        // if fund == 0 add 10% diff
                        if(oldFound._sliderValue == 0){
                          y._sliderValue = (10*l.sliderValue)/100;
                        }
                        else{
                          let diff = -(10*l.sliderValue)/100;
                          let ratio = oldFound._sliderValue / oldEquityData.find((o) => o.query === l.query).sliderValue;
                          let wt = diff * ratio
                          y._sliderValue = oldFound._sliderValue + wt;
                        }
                        // console.log('pratik add', y.ticker, y._sliderValue)
                      }
                      else if(buildportfolio.equityTab.selectedFunds[l.query].length >= oldEquityTab.selectedFunds[l.query].length
                        && !oldEquityTab.selectedFunds[l.query].find((o) => o._sliderValue === 0)
                        && oldSelectedfunds && oldSelectedfunds[l.query] && oldSelectedfunds[l.query].length > selectedFunds.length) {
                        //delete check
                        //_.differenceBy([{ 'x': 2 }, { 'x': 1 }], [{ 'x': 1 }], 'x');
                        // old , new
                        let diffObj = _.differenceBy(oldSelectedfunds[l.query], selectedFunds, 'ticker');
                        if(diffObj.length){
                          let filterArrSum = _.sumBy(oldSelectedfunds[l.query].filter(o => o.ticker != diffObj[0].ticker),'_sliderValue');
                          // _.sumBy(objects, 'n');
                          let diff = diffObj[0]._sliderValue;
                          let ratio = oldFound._sliderValue / filterArrSum;
                          let wt = diff * ratio
                          y._sliderValue = oldFound._sliderValue + wt;

                          // console.log('pratik delete check', y.ticker, oldFound._sliderValue, diff, ratio, wt, y._sliderValue)
                        }
                      }
                      else if(buildportfolio.equityTab.selectedFunds[l.query].length < oldEquityTab.selectedFunds[l.query].length) {
                        //delete
                        //_.differenceBy([{ 'x': 2 }, { 'x': 1 }], [{ 'x': 1 }], 'x');
                        // old , new
                        let diffObj = _.differenceBy(oldEquityTab.selectedFunds[l.query], buildportfolio.equityTab.selectedFunds[l.query], 'ticker');
                        if(diffObj.length){
                          let filterArrSum = _.sumBy(oldEquityTab.selectedFunds[l.query].filter(o => o.ticker != diffObj[0].ticker),'_sliderValue');
                          // _.sumBy(objects, 'n');
                          let diff = diffObj[0]._sliderValue;
                          let ratio = oldFound._sliderValue / filterArrSum;
                          let wt = diff * ratio
                          y._sliderValue = oldFound._sliderValue + wt;

                          // console.log('pratik delete', y.ticker, oldFound._sliderValue, diff, ratio, wt, y._sliderValue)
                        }
                      }
                      else{
                        // console.log('pratik else', buildportfolio.equityTab.selectedFunds[l.query].length , oldEquityTab.selectedFunds[l.query].length)
                        y._sliderValue = l.sliderValue/buildportfolio.equityTab.selectedFunds[l.query].length;
                      }
                      // console.log('pratik 57', y.ticker, y._sliderValue, oldFound && oldFound._sliderValue, buildportfolio.equityTab.selectedFunds[l.query].length, oldEquityTab.selectedFunds[l.query].length)
                    }
                  });
                }
              }
            }
          });
        });

        if (setBuildPortfolioState) {
          setBuildPortfolioState({
            oldHeaderMenuState,
            oldEquityTab,
            oldBondTab,
            oldThematicTab,
            oldEquityData,
            oldBondData,
            oldThematicData,
            equityTab: buildportfolio.equityTab,
          });
        }

      }
    }
  }

  removeSelectedThemes = (event, fund, forQuery) => {
    event.stopPropagation();
    const { equityTab } = this.props;
    if (forQuery && fund && typeof fund.ticker !== 'undefined') {
      const oldSelectedfunds = JSON.parse(JSON.stringify(equityTab.selectedFunds));
      const filteredArray = equityTab.selectedFunds[forQuery];
      const updatedArray = filteredArray.filter((h) => h.ticker !== fund.ticker);
      this.getSelectedFunds({
        selectedFunds: updatedArray,
        forQuery,
        oldSelectedfunds: oldSelectedfunds,
      });
    }
  }

  callback = (key) => {
    this.setState({ activeTabPane: key });
  }

  getCheckedListArray = (item) => {
    const { equityTab } = this.props;
    const { selectedFunds } = equityTab;
    if (selectedFunds) {
      const label = item.label || item;
      const arr = selectedFunds[label] || [];
      return arr;
    }
    return [];
  }

  handleClose = (event, currentKey) => {
    event.stopPropagation();
    if (this.state.activeTabPane !== currentKey) {
      this.setState({ activeTabPane: currentKey})
    }
  }

  getPaneHeader = (data, currentKey) => {
    if(data.forQuery === 'USA'){
      data.placeholder = 'US equity';
    } else if(data.forQuery === 'Developed Markets'){
      data.placeholder = 'developed market equity';
    } else if (data.forQuery === 'Emerging Markets'){
      data.placeholder = 'emerging market equity';
    } else if (data.forQuery === 'Global Markets') {
      data.placeholder = 'global market equity';
    }
    const { activeTabPane } = this.state;
    const { label, sliderValue, forQuery, placeholder } = data;
    const { equityTab } = this.props;
    const listArray = this.getCheckedListArray(label);

    return (
      <div className="accordin-head-wrap" onClick={(e) => this.handleClose(e, currentKey)}>
        <div className="title">
          <span>{label}</span><span className="slider-value">{parseFloat(sliderValue.toFixed(2))}%</span>
        </div>
        <Tooltip placement="bottom" title={`Which ${placeholder} funds would you like to invest in?`}>
          <div>
          <InputSearch
            placeholder={`Which ${placeholder} funds would you like to invest in?`}
            onClick={(e) => this.handleClose(e, currentKey)}
            onSearch={value => this.onSearch(value, forQuery, 'equityTab')}
            onChangeSearch={e => this.onChangeSearch(e, forQuery)}
            onBlur={() => this.onBlurDo(forQuery)}
          /></div>
        </Tooltip>
        {(listArray.length === 0 ) && (
            <div className="pending-text">Pending Allocation</div>
          )
        }
        {listArray.filter((o) => o !== '' && o.ticker).map((y, index) => {
          return (
            <div key={`i-${index}`} className="selected-funds-tag">
              {y.ticker}
              <i className="far fa-times" onClick={(e) => this.removeSelectedThemes(e, y, label)} />
            </div>
          )
        })}
      </div>
    );
  }

  getCardTitle = (title) => {
    return (
      <div className="folder-content-card-title">
        {title}
      </div>
    )
  };

  getCardOptions = (menus) => {
    const { equityTab } = this.props;
    const menuItems = menus.map((u) => {
      return (
        <Menu.Item className={u.className} key={u.label} disabled={u.disabled} style={u.style}>
          {u.tooltip ? u.tooltip(<span>{u.icon}{u.label}</span>) : (
            <span>{u.icon}{u.label}</span>
          )}
        </Menu.Item>
      );
    });
    return (
      <Menu
        className="folder-content-card-menu"
        mode="horizontal"
        defaultSelectedKeys={[]}
        onClick={this.menuHandleClick}
      >
        <Menu.Item className="default-menu" key="Weighting Methodology" disabled>
          Initial Weight
        </Menu.Item>
        {menuItems}
      </Menu>
    )
  }

  onSearch = (value, forQuery, forTab) => {
    const { searchQuery } = this.state;
    if (value) {
      this.setState({
        searchQuery: value,
      }, () => {
        const { searchForNewQueryRequest } = this.props;
        // console.log(forQuery, forTab);
        if (searchForNewQueryRequest) {
          addEventToAnalytics('Builder Equity Search','Builder Equity Search','BUILDER_EQUITY_SEARCH',{ query: this.state.searchQuery },false);
          searchForNewQueryRequest({
            query: 'pf_builder_query',
            q: this.state.searchQuery,
            forQuery,
            forTab,
          });
        }
      });
    }
  }

  onBlurDo = (forQuery) => {
    if (this.state.searchQuery === '') {
     this.setState({
       searchQuery: forQuery,
     });
   }
  }

  onChangeSearch = (value, forQuery) => {
    // const value = e.target.value;
    this.setState({
      searchQuery: value,
    });
  }

  render() {
    const { tickerList, tickerLoading, customSearchTickerData, equityTab, weightingMethodology, buildportfolio } = this.props;
    const { fundCollectionList } = this.state;

    const checkedListArray = (item) => {
      const arr = this.getCheckedListArray(item);
      if (arr && arr.length) {
        return arr.map((v) => v.ticker);
      } else {
        return [];
      }
    }

    // fundCollectionList.map((l) => {
    //   const getRowDataSliderValue = buildportfolio.equityTab.data.find((t) => t.query === l.query);
    //   if (typeof buildportfolio.equityTab.selectedFunds[l.query] !== 'undefined') {
    //     const selF = buildportfolio.equityTab.selectedFunds[l.query];
    //     l.result.funds.map((w) => {
    //       w._sliderValue = 0;
    //       const found = selF.find((o) => o.ticker === w.ticker);
    //       if (found && typeof found !== 'undefined' && (found.ticker === w.ticker)) {
    //         w._sliderValue = getRowDataSliderValue.sliderValue/selF.length;
    //       }
    //     });
    //   }
    // });

    // console.log(fundCollectionList);

    return (
      <React.Fragment>
        <Row className="folder-content-container" style={{ paddingRight: 5 }}>
          <div className="equity-section custom-scroll">
            <Card title={this.getCardTitle('Search and Select Funds')} extra={this.getCardOptions(buildPortfolioMenus)} className="folder-content-card-wrapper">
              <Collapse
                accordion
                className="folder-content-accordion"
                activeKey={[this.state.activeTabPane]}
                expandIconPosition="right"
                expandIcon={() => (<div><i className="far fa-angle-down caret-icon" /></div>)}
                onChange={this.callback}
              >
                {
                  fundCollectionList.map((item, index) => {
                    return (
                      <Panel
                        header={this.getPaneHeader(item, `${index+1}`)}
                        key={`${index+1}`}
                      >
                        <ListView
                          newQuerySearchLoader={item.loading || false}
                          data={item.result}
                          query={item.query}
                          newQuery={item.newQuery}
                          activeTab={this.props.activeTab}
                          sliderValue={item.sliderValue}
                          weightingMethodology={weightingMethodology}
                          ticker={item.ticker}
                          staticCard={true}
                          customEdit={item.customEditScreen}
                          tickerList={tickerList}
                          tickerLoading={tickerLoading}
                          checkedListArray={checkedListArray(item)}
                          customSearchTickerCallback={() => this.getTickerData()}
                          customSearchTickerData={item.customSearchTickerData}
                          deleteCard={this.deleteCard}
                          getSelectedFunds={this.getSelectedFunds}
                          selectedFunds={equityTab.selectedFunds}
                        />
                      </Panel>
                    )
                  })
                }
              </Collapse>
            </Card>
          </div>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ buildportfolio, loading }) => ({
  buildportfolio,
  tickerList: buildportfolio.tickerList,
  customSearchTickerData: buildportfolio.equityTab.customSearchTickerData,
  tickerLoading: createLoadingSelector(['CUSTOM_TICKER', 'TICKER_DATA'])({ loading }),
})

const mapDispatchToProps = {
  tickerDataRequest,
  deleteCustomCard,
  setBuildPortfolioState,
  searchForNewQueryRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EquityContent);

import React from 'react'
import { connect } from 'react-redux'
import { fetchProfile } from 'actions/profile'
// import {
//   Alert,
//   Col,
//   Collapse,
//   Row,
//   Container,
//   Form,
//   FormGroup,
//   Label,
//   Button,
//   Input
// } from 'reactstrap'
// import { PrimaryButton } from 'shared-components'
import PaymentStatusModal from '../../SearchResultsV3/Elements/PaymentStatus/PaymentStatusModal'
import style from './Subscription.module.scss'

class UserPlanType extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openUnsubscribeModal: false
    }
  }

  convertDateFormat(date, outputType) {
    if (date && outputType) {
      const d = new Date(date)
      const dtf = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long', day: '2-digit' })
      const [{ value: mo }, , { value: da }, , { value: ye }] = dtf.formatToParts(d)
      return (outputType == 'm-y') ? `${mo} ${ye}` : (outputType == 'm-d-y') ? `${mo} ${da}, ${ye}` : ''
    }
  }

  render() {
    let renderModal = ''
    if (window.sessionStorage.getItem('paymentStatus')) {
      renderModal = <PaymentStatusModal planName={this.props.planName} userEmail={this.props.useremail}
        paymentStatus={window.sessionStorage.getItem('paymentStatus')} />
    }
    let renews_on = this.props.userDetails.renews_on ? this.convertDateFormat(new Date(this.props.userDetails.renews_on), 'm-d-y') : ''
    let subscriber_since = this.props.userDetails.subscriber_since ? this.convertDateFormat(new Date(this.props.userDetails.subscriber_since), 'm-y') : ''
    let cancels_on = this.props.userDetails.cancels_on ? this.convertDateFormat(new Date(this.props.userDetails.cancels_on), 'm-d-y') : ''
    return (
      <React.Fragment>
        {renderModal}
        {/* <div className={style.userPlanContainer}>
          <div className={style.formGroup}>
            <span className={style.subHeader} style={{ visibility: 'hidden' }}> Plan Type</span>
            //old commented
            <span className={style.type}>{(this.props.userDetails.premiumUser) ? this.props.user.plan_name==='Premium Plan'?'Magnifi Pro Plus': 'Magnifi Pro' : 'Basic'}</span>

            <span className={style.type}>{(this.props.userDetails.premiumUser) ? this.props.user.plan_name === 'Premium Plan' ? 'Magnifi Pro Plus' : this.props.userDetails.feature_allowed.includes('enhancer') ? 'Magnifi Pro (Enhancer)' : 'Magnifi Pro (Selector + Client Proposal)' : 'Basic'}</span>
          </div>
          <div className={style.planDetails}>
                //old commented
                {subscriber_since && <p>Member since {subscriber_since}</p>}
            {cancels_on ? <p >Expires on {cancels_on}</p> : renews_on ?
              <p>Renews on {renews_on}</p> : ''}
          </div>
          <div className={style.divider} />
        </div> */}
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = {
  fetchProfile
}

export default connect(
  null,
  mapDispatchToProps
)(UserPlanType)

import React, { useContext } from 'react';
import { Row, Col, Table } from 'reactstrap';

import ImageRoundMask from './ImageRoundMask';

const PortfolioLogo = (props) => {
	const imageSrc = '/assets/images/sponsors/';
  return (
    <div className="pf-name">
      <span className="md-sponsor">
	      {props.sponsor && <img
	        src={`${imageSrc}${props.sponsor}.png`}
	        className="img-fluid"
	        alt={props.sponsor}
	        onError={(e) => handleBrokenImage(e)}
	      />}
	    </span>
      <span className="sponsor-card-name">
      	{props.name}
      </span>
      <span className="md-category">
      	{props.category}
      </span>
    </div>
  )
};
export default PortfolioLogo;

const handleBrokenImage = (e) => {
  e.target.style.display = 'none';
}
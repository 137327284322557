import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setGlobalState } from 'actions/global';

import AccountsContainer from 'layouts/WebDashboard/AccountsPage';
import MobileAccountPage from 'pages/AccountsContainer'

import { enquireScreen } from 'Utils';

class AccPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      media: '',
      mobileDevice: '',
      tabDevice: '',
      smallScreen: '',
      bigScreen: '',
      renderScreen: '',
    }
  }

  componentDidMount() {
    this.getWindowScreen();
  }

  componentDidUpdate(prevProps, prevState) {
    this.getWindowScreen();
  }

  componentWillUnmount() {
    const { setGlobalState } = this.props;
    setGlobalState({
      mobileDevice: false,
      tabDevice: false,
      smallScreen: false, //only screen and (min-width: 1024px) and (max-width: 1280px)
      desktopScreen: false,
      bigScreen: false,
    });
  }

  getWindowScreen = () => {
    if (window.innerWidth > 1680) { // hd desktop resol or bigScreen
      const media = 'only screen and (min-width: 1680px)';
      if (this.state.media !== media) {
        this.setState({
          media,
        }, () => {
          const { bigScreen } = this.props;
          enquireScreen((isBigScreen) => {
            if (typeof isBigScreen === undefined || bigScreen !== isBigScreen) {
              const { setGlobalState } = this.props;
              this.setState({
                bigScreen: isBigScreen || false,
                renderScreen: <AccountsContainer {...this.props} />,
              }, () => {
                setGlobalState({
                  bigScreen: isBigScreen || false,
                });
              });
            }
          }, this.state.media);
        });
      }
    } else if (window.innerWidth >= 1281 && window.innerWidth <= 1680) { // desktop resol or desktopScreen
      const media = 'only screen and (min-width: 1281px) and (max-width: 1680px)';
      if (this.state.media !== media) {
        this.setState({
          media,
        }, () => {
          const { desktopScreen } = this.props;
          enquireScreen((isDesktopScreen) => {
            if (typeof isDesktopScreen === undefined || desktopScreen !== isDesktopScreen) {
              const { setGlobalState } = this.props;
              this.setState({
                desktopScreen: isDesktopScreen || false,
                renderScreen: <AccountsContainer {...this.props} />,
              }, () => {
                setGlobalState({
                  desktopScreen: isDesktopScreen || false,
                });
              });
            }
          }, this.state.media);
        });
      }
    } else if (window.innerWidth >= 1024 && window.innerWidth <= 1280) { // smallScreen
      const media = 'only screen and (min-width: 1024px) and (max-width: 1280px)';
      if (this.state.media !== media) {
        this.setState({
          media,
        }, () => {
          const { smallScreen } = this.props;
          enquireScreen((isSmallScreen) => {
            if (typeof isSmallScreen === undefined || smallScreen !== isSmallScreen) {
              const { setGlobalState } = this.props;
              this.setState({
                smallScreen: isSmallScreen || false,
                renderScreen: <AccountsContainer {...this.props} />,
              }, () => {
                setGlobalState({
                  smallScreen: isSmallScreen || false,
                });
              });
            }
          }, this.state.media);
        });
      }
    } else if (window.innerWidth <= 1023) { // mobileDevice
      const media = 'only screen and (min-width: 320px) and (max-width: 1023px)';
      if (this.state.media !== media) {
        this.setState({
          media,
        }, () => {
          const { mobileDevice } = this.props;
          enquireScreen((isMobileDevice) => {
            if (typeof isMobileDevice === undefined || mobileDevice !== isMobileDevice) {
              const { setGlobalState } = this.props;
              this.setState({
                mobileDevice: isMobileDevice || false,
                renderScreen: <MobileAccountPage {...this.props} />,
              }, () => {
                setGlobalState({
                  mobileDevice: isMobileDevice || false,
                });
              });
            }
          }, this.state.media);
        });
      }
    }
  }

  render() {
    const { mobileDevice, renderScreen } = this.state;
    return (
      <React.Fragment>
        {renderScreen}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  mobileDevice: global.mobileDevice,
})

const mapDispatchToProps = {
  setGlobalState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccPage)

import React, { Component } from 'react';
import { Slider, InputNumber, Row, Col, Icon } from 'antd';

import { LockOutlined, UnlockOutlined } from '@ant-design/icons';

export default class CalculatorSlider extends Component {
  constructor(props) {
    super(props);
  }

  inputOnChange = value => {
    if(this.props.inputNumberDisabled)
      return null;
    this.sliderOnChange(value)
  }

  formatter = (value) => {
    return `${value}%`;
  }

  lockChange = (lockState) => {
    const {
      locked,
      lockedChange,
    } = this.props;
    if (lockState !== null && lockedChange) {
      lockedChange({
        ...this.props,
        locked: !locked,
      });
    }
  }

  onAfterChange = (value) => {
    const {
      onAfterChange,
    } = this.props;
    if (onAfterChange) {
      onAfterChange({
        ...this.props,
        sliderValue: value,
      });
    }
  }

  sliderOnChange = (value) => {
    const {
      sliderOnChange,
    } = this.props;
    if (sliderOnChange) {
      sliderOnChange({
        ...this.props,
        sliderValue: value,
      });
    }
  }

  labelClick = () => {
    const {
      labelClick,
    } = this.props;
    if (labelClick) {
      labelClick({
        ...this.props,
        customEditScreen: false,
      });
    }
  }

  render() {
    const {
      className,
      style,
      label,
      locked,
      showInput,
      inputNumberDisabled,
      sliderEnabled,
      sliderValue,
      maxSliderValue,
      sliderTooltipEnabled,
      sliderOnChange,
      lockedChange,
      sliderWrapper,
      sliderClassName,
      inputNumberClassName,
      mode = 'default',
    } = this.props;

    return (
      <React.Fragment>
        {mode === 'linear' ? (
          <div className={`linear-calc-slider-container calc-slider-container ${className}`} style={style}>
            <div className="label-holder" onClick={this.labelClick}>
              {label}
            </div>
            <div className={`slider-wrapper ${sliderWrapper}`}>
              <Slider
                className={`slider-element ${sliderClassName}`}
                min={0}
                max={maxSliderValue ? maxSliderValue : 100}
                disabled={sliderEnabled}
                tipFormatter={sliderTooltipEnabled ? this.formatter : null}
                onChange={this.sliderOnChange}
                onAfterChange={this.onAfterChange}
                value={sliderValue}
              />
            </div>
            <div className="number-wrapper">
              {showInput ? (
                <InputNumber
                  className={`input-number-element ${inputNumberClassName}`}
                  disabled={inputNumberDisabled}
                  min={0}
                  max={maxSliderValue ? maxSliderValue : 100}
                  style={{ marginLeft: 16 }}
                  value={sliderValue}
                  onChange={this.inputOnChange}
                />
              ) : (
                <div className="number-element">{sliderValue}</div>
              )}
            </div>
            <div className="icon-holder">
              {locked ?
                (
                  <LockOutlined className="lock" style={{ marginRight: 2 }} onClick={this.lockChange} />
                ) : (
                  <UnlockOutlined className="un-lock" style={{ marginRight: 2 }} onClick={this.lockChange} />
                )
              }
            </div>
          </div>
        ) : (
          <div className={`calc-slider-container ${className}`} style={style}>
            <Row className={`label-wrapper`}>
              <Col span={20} className="label-holder">
                {label}
              </Col>
              <Col span={4} className="icon-holder">
                {locked ?
                  (
                    <LockOutlined className="lock" style={{ marginRight: 2 }} onClick={this.lockChange} />
                  ) : (
                    <UnlockOutlined className="un-lock" style={{ marginRight: 2 }} onClick={this.lockChange} />
                  )
                }
              </Col>
            </Row>
            <Row className={`slider-wrapper ${sliderWrapper}`}>
              <Col span={20}>
                <Slider
                  className={`slider-element ${sliderClassName}`}
                  min={0}
                  max={maxSliderValue ? maxSliderValue : 100}
                  disabled={sliderEnabled}
                  tipFormatter={sliderTooltipEnabled ? this.formatter : null}
                  onChange={this.sliderOnChange}
                  onAfterChange={this.onAfterChange}
                  value={sliderValue}
                />
              </Col>
              <Col span={4}>
                {showInput ? (
                  <InputNumber
                    className={`input-number-element ${inputNumberClassName}`}
                    disabled={inputNumberDisabled}
                    min={0}
                    max={maxSliderValue ? maxSliderValue : 100}
                    style={{ marginLeft: 16 }}
                    value={sliderValue}
                    onChange={this.inputOnChange}
                  />
                ) : (
                  <div className="number-element">{sliderValue}</div>
                )}
              </Col>
            </Row>
          </div>
        )}
      </React.Fragment>
    )
  }
}

import React from 'react';
import { Menu, Input } from 'antd';
import { addEventToAnalytics,deflatLastNode} from 'Utils';
import style from './customdropdown.module.scss';
const { SubMenu } = Menu
const { Search } = Input
 
export class CustomSponsoredDropdown extends React.Component{
    constructor(props) {
        super(props)
        this.state={
          input:'',
          filterdList:[],
          allList:[],
        }
       
      }
    
    componentWillMount() {
        let { obj: dropDownData, allItems: allList } = deflatLastNode(
          this.props.dropDownData,
          true
        )
        this.setState({
        dropDownData,
        allList
        })
      }
   
    handleInputChange = e => {
        if(e){
          const {allList}=this.state;
          const filterdData=(e && e.length !== 0) && (allList && allList.filter(item =>item.name.toLowerCase().includes(e.toLowerCase())))
            this.setState({
                input: e,
                filterdList: filterdData
            })
          }else{
            this.setState({
              input:'',
              filterdList:[]
            })
          }

      }
    
    sendSelectedPortfolio = (name,ticker,client_id,isGlobalSelect) => {
      if (this.props.sendSelectedPortfolio) {
        if(this.props.isGlobalSelect){
          addEventToAnalytics('Client Select Model Macro', 'Client Select Model Macro', 'CLIENT_SELECT_MODEL_MACRO', {}, false)
        }else{
          addEventToAnalytics('Client Select Model Micro', 'Client Select Model Micro', 'CLIENT_SELECT_MODEL_MICRO', { client_id: this.props.refID }, false)
        }
        this.props.sendSelectedPortfolio(name,ticker,client_id,isGlobalSelect)
        }
    }

    render(){
        const {refID,isGlobalSelect}=this.props;
        const { input,dropDownData,filterdList} = this.state;
        
        
        return (
            <Menu onOpenChange={this.props.openChange} className={style.portfolioMenuWrapper}>
                <Menu.Item key={'search'} className={style.inputContainer}>
                    <Search
                    id={'sponsorSearch'}
                    autoFocus
                    onChange={e => {
                        e.preventDefault()
                        this.handleInputChange(e.target.value)
                      }}
                    className={style.input}
                    placeholder="Search for better results"
                    allowClear
                    onSearch={()=>this.handleInputChange()}
                 />
               </Menu.Item>
               {input.length !== 0 &&
               filterdList.map((item, index) => (
               <Menu.Item className={style.onSearchMenuContainer}>
                   <div className={style.onSearchMenu}>
                       <i className={`far fa-fw fa-search ${style.resultSearchIcon}`} />
                       <div className={style.resultDetail} onClick={()=>this.sendSelectedPortfolio(item.name, item.ticker, refID, isGlobalSelect)}>
                            <p title={item.name}>{item.name}</p>
                            <p title={item.sponsor}>{item.sponsor}</p>
                       </div>
                    </div>
               </Menu.Item>
                ))}
                {
                 input.length === 0 && dropDownData && typeof dropDownData == 'object' && Object.keys(dropDownData).length > 0 && Object.keys(dropDownData).map((item, index) => {
                   return (
                       <SubMenu key={item} className={style.subMenu} title={item} popupClassName={style.popupClass}>
                           {Object.keys(dropDownData[item]).length > 0 && 
                            Object.keys(dropDownData[item]).map((subItem, index) => {
                               if(subItem == 'uncatagorized'){
                                 return (dropDownData[item][subItem].length > 0 && 
                                         dropDownData[item][subItem].map((subItem1, index) => {
                                         return (
                                             <div className={style.itemLable} key={`market_name-label_${index}`} onClick={() =>
                                               this.sendSelectedPortfolio(subItem1.name, subItem1.ticker,refID, isGlobalSelect)
                                             }>
                                               {subItem1.name}
                                             </div>
                                         )
                                       })
                                     
                
                                 )
                               }
                               else{
                                 return (
                                 <SubMenu key={subItem} className={style.subMenu} title={subItem} popupClassName={style.popupClass}>
                                     {
                                       dropDownData[item][subItem].length > 0 && dropDownData[item][subItem].map((subItem1, index) => {
                                         return (
                                             <div  className={style.itemLable} key={`market_name-label_${index}`} onClick={() => {
                                               this.sendSelectedPortfolio(subItem1.name, subItem1.ticker, refID, isGlobalSelect)
                                             }}>
                                               {subItem1.name}
                                             </div>
                                         )
                                       })
                                     }
                                 </SubMenu>
                               )}
                             })
                           }
                       </SubMenu>
                   )
                 })
                }
               {input.length !== 0 && filterdList.length === 0 && (
               <Menu.Item
               className={`${style.noHighlight} ${style.noResult}`}
               key="0"
               >
              <div>No Result Found</div>
              </Menu.Item>
              )}
           </Menu>
        )
    }
}

export class CustomModelDropdown extends React.Component{
  constructor(props) {
    super(props)
    this.state={
      input:'',
    }   
  }

  handleInputChange = e => {
    if(e){
      this.setState({
            input: e
        })
      }else{
        this.setState({
          input:''
        })
      }

  }

  sendSelectedPortfolio = (name,ticker,client_id,isGlobalSelect) => {
    if (this.props.sendSelectedPortfolio) {
      if(this.props.isGlobalSelect){
        addEventToAnalytics('Client Select Model Macro', 'Client Select Model Macro', 'CLIENT_SELECT_MODEL_MACRO', {}, false)
      }else{
        addEventToAnalytics('Client Select Model Micro', 'Client Select Model Micro', 'CLIENT_SELECT_MODEL_MICRO', { client_id: this.props.refID }, false)
      }
      this.props.sendSelectedPortfolio(name,ticker,client_id,isGlobalSelect)
    }
  }

  render(){
    const {refID,isGlobalSelect,dropDownData,}=this.props;
    const { input} = this.state;
    const filterdData=(dropDownData && dropDownData.length &&  dropDownData.filter(item =>item.portfolio_name.toLowerCase().includes(input.toLowerCase())))

    return (

      <Menu onOpenChange={this.openChange} className={style.portfolioMenuWrapper}>
        {  filterdData && filterdData.length && (<Menu.Item key={'search'} className={style.inputContainer}>
          <Search
          id={'modelSearch'}
          autoFocus
          onChange={e => {
             e.preventDefault()
             this.handleInputChange(e.target.value)
             }}
          className={style.input}
          placeholder="Search for better results"
          allowClear
          onSearch={()=>this.handleInputChange()}
          />
        </Menu.Item>
        )}
       
          { filterdData && filterdData.length && filterdData.map((item, index) => {
            return (
              <Menu.Item
                className={style.myPfItems}
                key={item.portfolio_name + index}
              >
                <div
                  title={item.portfolio_name}
                  className={style.myPfItemsDetail}
                  onClick={() => {
                    this.sendSelectedPortfolio(item.portfolio_name, '', refID, isGlobalSelect)
                  }}
                >
                  {input.length > 0 && (
                    <i
                      className={`far fa-fw fa-search ${style.resultSearchIcon}`}
                    />
                  )}
                  <p>{item.portfolio_name}</p>
                </div>
              </Menu.Item>
            )
          })
          }
          {input && input.length !== 0 && filterdData && filterdData.length === 0 && (
               <Menu.Item
               className={`${style.noHighlight} ${style.noResult}`}
               key="0"
               >
              <div>No Result Found</div>
              </Menu.Item>
          )}
          {!filterdData && (
                <Menu.Item
                className={`${style.noHighlight} ${style.noResult}`}
                key="1"
                >
               <div>No Models Created</div>
               </Menu.Item>
          )}
    </Menu>
    )
  }

}

export default {
    CustomSponsoredDropdown,
    CustomModelDropdown,
}
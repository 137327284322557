import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addEventToAnalytics } from 'Utils';
import { modelSearchRequest } from 'actions/clientportfolioV2';

const classNames = require('classnames');

let PLACEHOLDER = '';
let placeholder_i = 0, speed = 20, placeholder_v = '';

function typeWriter() {
  if (document.getElementById("type-writer") && PLACEHOLDER && PLACEHOLDER.length > 0) {
    // if (placeholder_i < PLACEHOLDER.length) {
    //   placeholder_v += PLACEHOLDER.charAt(placeholder_i);
    //   document.getElementById("model-search-box").setAttribute('placeholder', placeholder_v);
    //   placeholder_i++;
    //   setTimeout(typeWriter, speed);
    // }
    if (placeholder_i < PLACEHOLDER.length) {
      placeholder_v += PLACEHOLDER.charAt(placeholder_i);
      document.getElementById("type-writer").innerHTML = placeholder_v;
      placeholder_i++;
      setTimeout(typeWriter, speed);
    }
  }
}


class ModelSearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.modelSearchQuery || '',
      active: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const elem = document.getElementById("model-search-box");
    if (this.props.placeholder && this.props.placeholder.length > 0 && elem) {
      PLACEHOLDER = this.props.placeholder;
      typeWriter();
    }
  }

  componentWillUnmount() {
    document.getElementById("model-search-box").setAttribute('placeholder', '');
    placeholder_i = 0;
    placeholder_v = '';
  }

  componentWillReceiveProps(newProps) {
    const elem = document.getElementById("model-search-box");
    if (newProps.placeholder && newProps.placeholder.length > 0 && elem) {
      PLACEHOLDER = newProps.placeholder;
      typeWriter();
    }
  }

  searchFieldFocusIn = () => {
    if (document.getElementById('type-writer')) {
      document.getElementById('type-writer').style.visibility = "hidden";
      document.getElementById('model-search-box').focus();
    }
  }

  searchFieldFocusOut = () => {
    if (document.getElementById('type-writer')) {
      if (this.state.value === "") {
        document.getElementById('type-writer').style.visibility = "visible";
      }
    }
  }

  handleChange(event) {
    this.setState({ value: event.target.value, active: this.validQuery(event.target.value) });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.state.value && this.searchRequest();
  }

  clearSearch = () => {
    this.setState({
      value: ''
    });
    document.getElementById("model-search-box").focus();
  }

  searchRequest = () => {
    // if(this.props.modelSearchQuery != this.state.value){
    addEventToAnalytics('Client Allocate Search', 'Client Allocate Search', 'CLIENT_ALLOCATE_SEARCH', { query: this.state.value }, false)
    const { getTypedQuery } = this.props;
    if (getTypedQuery) {
      getTypedQuery(this.state.value);
    }
    this.props.modelSearchRequest({q: this.state.value, c: 5});
    // }
  }

  validQuery = (query) => query.trim().length > 0

  render() {
    const { placeholder, disabled } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className={classNames("input-group h2 mb-0 search-box")}>
        <i className={classNames("far fa-fw fa-search fa-lg text-secondary search-icon", {'active': this.state.active})}
            onClick={this.handleSubmit} />
          <input
            id="model-search-box"
            value={this.state.value}
            onChange={this.handleChange}
            className="form-control form-control-lg rounded bg-white"
            type="text"
            placeholder=""
            autocomplete="off"
            aria-label="Search"
            onFocus={this.searchFieldFocusIn}
            onBlur={this.searchFieldFocusOut}
            style={{ paddingLeft: (this.state.value && this.state.value.length > 0) ? '60px' : '50px' }}
            disabled={disabled || false}
          />
          <p id="type-writer" className="type-writer" onClick={this.searchFieldFocusIn}></p>
          {(this.state.value && this.state.value.length > 0) && (
            <i className={`far fa-times fa-lg text-secondary clear-icon ${this.state.clearSearch ? '' : ''} ${this.state.active ? 'active' : ''}`}
              onClick={this.clearSearch} />
          )}
        </div>
      </form>
    );
  }
}

const mapStateToProps = ({ clientportfolioV2: { modelSearchQuery } }) => ({
  modelSearchQuery
})

const mapDispatchToProps = {
  modelSearchRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelSearchBox);

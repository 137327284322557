import React, { Component } from 'react';

import { connect } from 'react-redux'
import { sfmFundAdd, sfmFundDelete } from 'actions/sfm'


class Selectable_ extends Component {
  state = { selected: false }

  add = (e) => {
    this.setState({ selected: true }, () => {
      this.props.sfmFundAdd(e);
    })
  }

  remove = (e) => {
    this.setState({ selected: false }, () => {
      this.props.sfmFundDelete(e);
    })
  }

  update = (selected) => this.setState({ selected });

  render() {
    const { add, remove, update, state } = this;
    return (
      <div>
        {this.props.children({ add, remove, update, ...state })}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
  sfmFundAdd,
  sfmFundDelete,
}

export const Selectable = connect(
  mapStateToProps,
  mapDispatchToProps
)(Selectable_);

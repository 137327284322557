import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomAlert from 'components/CustomAlert';
import { fetchProfile } from 'actions/profile';
import { Select } from 'antd';
import { 
    Modal, 
    ModalBody,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Form,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
} from 'reactstrap';
import {transferType ,contraPartyList} from '../../Constant/FormData'
import './TransferAccModal.scss'
import { Formik } from 'formik'
import * as Yup from 'yup'
import * as ProfileApi from '../../../../apis/profile';
import { Spinner } from 'shared-components/'

const { Option } = Select;
const formInitialValues = {
    contra_party       :"",
    participant_number :"",
    participant_name   :"",
    type_of_transfer   :"",
    tickers            :[],
    t_name             :"",
    t_quantity         :"",
    cash_amount        :"0",
    contra_account_number      :""
}


class TransferAccForm extends Component {
    constructor(props) {
        super(props)
      }
    
      state = {
          transfer_type:false,
          contra_party:false,
          transferStatus: ''
      };

      handleAddTicker = () => {
        const { values } = this.props.formProps
        if(values.t_quantity === '' || values.t_name === ''){
            return;
        }
        let obj = {"ticker": values.t_name , "shares": values.t_quantity }
        this.props.formProps.setFieldValue("tickers",[ ...values.tickers , obj]);
        this.props.formProps.setFieldValue("t_name","");
        this.props.formProps.setFieldValue("t_quantity","");        
      }

      handleDeleteTicker = (index) =>{
        const {values} =this.props.formProps
        values.tickers.splice(index, 1);
        this.props.formProps.setFieldValue("tickers",values.tickers)
      }

      toggleTransferType = () => {
        this.setState({
            transfer_type: !this.state.transfer_type,
        })
      }
      toggleContraParty = () => {
        this.setState({
            contra_party: !this.state.contra_party,
        })
      }

      setContraParty = (obj) =>{
        this.props.formProps.setFieldValue('participant_number', obj.value)
        this.props.formProps.setFieldValue('participant_name', obj.name)
      } 

      render() {
        const { formProps } = this.props
        const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = formProps
          console.log("transfer Acc form",this.props)
        return (
            <Form
                name="TransferAcc"
                className=""
                noValidate={true}
                >
                    <Row>
                    <Col>
                        <FormGroup className="pos-rel">
                            <Label className="label" htmlFor="contra_party">Contra Party</Label>
                            <Select
                                showSearch
                                style={{ maxWidth: 327, width: '100%' }}
                                placeholder="Select"
                                optionFilterProp="children"
                                onChange={(index) => this.setContraParty(contraPartyList[index])}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                className={
                                    errors.participant_number && touched.participant_number ? 'transferSelect transferSelectError' : 'transferSelect'
                                }
                            >
                            {contraPartyList.map((item,i) => {
                                return <Option key={`contraPartyList${i}`} value={i}>{item.name}</Option>
                            })}
                            </Select>
                            {/* <Dropdown
                                className={errors.participant_name && touched.participant_name ? 'is-invalid dropdown-wrapper' : 'dropdown-wrapper'}
                                isOpen={this.state.contra_party}
                                toggle={this.toggleContraParty}
                                >
                                <DropdownToggle
                                    value={values.participant_name}
                                    style={{ backgroundColor: '#fff' }}
                                    className="transfer-acc-input"
                                    caret
                                >
                                {values.participant_name || 'Select'}
                                </DropdownToggle>
                                <DropdownMenu className="transfer-acc-dropdown" center>
                                {
                                    contraPartyList.map((item,i) => {
                                    return <DropdownItem className="transfer-acc-dropdown-items" key={`contraPartyList${i}`} value={item.name} id={item.value}
                                    // onClick={(e) => { setFieldValue('type_of_transfer', e.target.value)}}
                                    onClick={(e)=>this.setContraParty(e)}
                                    >{item.name}</DropdownItem>
                                    })
                                }
                                </DropdownMenu>
                            </Dropdown> */}
                            {/* <Input
                            type="text"
                            name="contra_party"
                            id="contra_party"
                            value={values.contra_party}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="transfer-acc-input"
                            placeholder="Enter Contra Party Name"
                            /> */}
                            {errors.participant_number && touched.participant_number && <div className="error-field">{errors['participant_number']}</div>}
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup className="pos-rel">
                            <Label className="label" htmlFor="type_of_transfer">Type Of Transfer</Label>
                            <Select
                                style={{ maxWidth: 327, width: '100%' }}
                                placeholder="Select"
                                value={values.type_of_transfer}
                                optionFilterProp="children"
                                onChange={(value) =>  setFieldValue('type_of_transfer', value)}
                                className={
                                    errors.participant_number && touched.participant_number ? 'transferSelect transferSelectError' : 'transferSelect'
                                }
                                
                            >
                            {transferType.map((item,i) => {
                                return <Option key={`contraPartyList${i}`} value={item}>{item}</Option>
                            })}
                            </Select>
                            {/* <Dropdown
                                className={errors.type_of_transfer && touched.type_of_transfer ? 'is-invalid dropdown-wrapper' : 'dropdown-wrapper'}
                                isOpen={this.state.transfer_type}
                                toggle={this.toggleTransferType}
                                >
                                <DropdownToggle
                                    value={values.type_of_transfer}
                                    style={{ backgroundColor: '#fff' }}
                                    className="transfer-acc-input"
                                    caret
                                >
                                {values.type_of_transfer || 'Select'}
                                </DropdownToggle>
                                <DropdownMenu center>
                                {
                                    transferType.map((item,i) => {
                                    return <DropdownItem key={`transferType${i}`} value={item}  onClick={(e) => { setFieldValue('type_of_transfer', e.target.value)}}>{item}</DropdownItem>
                                    })
                                }

                                </DropdownMenu>
                            </Dropdown> */}
                            {errors.type_of_transfer && touched.type_of_transfer && <div className="error-field">{errors['type_of_transfer']}</div>}
                        </FormGroup>
                    </Col>
                    </Row> 
                    <Row>
                    {values.type_of_transfer == 'PARTIAL' && 
                    <Col>
                        <FormGroup className="pos-rel">
                            <Label className="label" htmlFor="cash_amount">Cash Amount</Label>
                            <Input
                                type="text"
                                name="cash_amount"
                                id="cash_amount"
                                value={values.cash_amount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="transfer-acc-input"
                                placeholder="Enter Cash Amount"
                            />
                            {errors.cash_amount && touched.cash_amount && <div className="error-field">{errors['cash_amount']}</div>}
                        </FormGroup>
                    </Col>}
                    <Col>
                        <FormGroup className="pos-rel">
                        <Label className="label" htmlFor="contra_account_number">Contra account number</Label>
                            <Input
                                type="text"
                                name="contra_account_number"
                                id="contra_account_number"
                                value={values.contra_account_number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="transfer-acc-input"
                                placeholder="Enter Client Reference Id "
                            />
                            {errors.contra_account_number && touched.contra_account_number && <div className="error-field">{errors['contra_account_number']}</div>}
                        </FormGroup>
                    </Col>
                    </Row>   
                    {values.type_of_transfer == 'PARTIAL' && 
                    <Row>
                        <div className="ticker-container">
                            <Input
                                type="text"
                                name="t_name"
                                id="t_name"
                                value={values.t_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Ticker"
                            />
                            <Input
                                type="number"
                                name="t_quantity"
                                id="t_quantity"
                                value={values.t_quantity}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Quantity"
                            />
                            <button type="button" className="add-btn" onClick={()=>this.handleAddTicker()}>ADD TICKER</button>
                        </div>
                        { values.tickers.length > 0 ?
                            values.tickers.map((o,i)=>(
                                <div className="ticker-container hor-line" key={`ticker${i}`}>
                                    <div className="label">{o.ticker}</div>
                                    <div className="label">{o.shares}</div>
                                    <i class="fa fa-trash" aria-hidden="true" onClick={()=>this.handleDeleteTicker(i)}></i>
                                </div>
                            ))
                            :  <div className="ticker-container hor-line">
                                <div className="error-field"> Add tickers before proceeding </div>
                            </div>
                        }
                    </Row>}
                    <Row>
                        <div className="submit-container">
                            <button type='button' onClick={() => handleSubmit(values)} className="add-btn submit-btn">PROCEED</button>
                            <button className="add-btn submit-btn" onClick={this.props.onCloseIcon}>CANCEL</button>
                        </div>
                    </Row>
                    
                </Form> 
        )
    }
}

let validationSchema = Yup.object().shape({
    participant_number : Yup.string().required('This is Required Field'),
    type_of_transfer   : Yup.string().required('This is Required Field'),
    cash_amount        : Yup.string().required('This is Required Field'),
    contra_account_number  : Yup.string().required('This is Required Field'),
    // tickers: Yup.array()
    // .when('type_of_transfer', {
    //   is: value => value === 'PARTIAL',
    //   then: Yup.object().shape({
    //     ticker: Yup.string().required('This is a required field'),
    //     shares: Yup.string().required('This is a required field')
    //   }),
    // })
  })
class TransferAccModal extends Component {

  constructor(props) {
    super(props)
  }

  state = {
  };

  submitTransfer = async(values) =>{
    let formData  = {
        "contra_account_number"    : values.contra_account_number,
        "apex_account_id"          : this.props.acc_id,
        "transfer_type"            : values.type_of_transfer == "PARTIAL" ? 'PARTIAL_TRANSFER_RECEIVER' : 'FULL_TRANSFER',
        "cash"                     : values.type_of_transfer == "PARTIAL" ? values.cash_amount : undefined,
        "participant_name"          : values.participant_name,
        "participant_number"        : values.participant_number,
        "tickers"                  : values.type_of_transfer == "PARTIAL" ? values.tickers : undefined,
      }
      if(values.type_of_transfer == "PARTIAL" && !values.tickers.length){
        return;
      }
      try {
        this.setState({transferStatus: 'LOADING'});
        let _responseData = await ProfileApi.transferAccount(formData);
       if(_responseData.status == '200')
       {
            this.props.fetchProfile();
            this.setState({transferStatus: 'SUCCESS'});
            setTimeout(() => {
                    this.onCloseIcon();
            },2000);
       }
        // this.setState({isFetching: false});
      }  catch(error) {
        console.log(error);
        // this.setState({isFetching: false});
        if (error && typeof error.response !== 'undefined') {
          if (typeof error.response.data !== 'undefined') {
            //TO DO
            this.setState({transferStatus: 'ERORR'});
            this.setState({error: error.response.data});
          }
        }
      }
  }

  onCloseIcon = ()=>{
    console.log('close')
    this.props.toggleTransferAccPopup()
  }

 
  render() {
    let {showTransferAccountModal} =this.props;
    let {transferStatus} =this.state;
    console.log("sdfdfd", transferStatus)
    return (
      <Fragment>
        {transferStatus === "SUCCESS" &&
             <div>
                <CustomAlert className="alert-windown-wrapper" color='info'>
                    Requested successfully.
                </CustomAlert>
              </div>
        }
        {transferStatus === "SUCCESS" &&
             <div>
                <CustomAlert className="alert-windown-wrapper" color='info'>
                    Requested successfully.
                </CustomAlert>
              </div>
        }
        <Modal
          keyboard
          centered
          backdrop
          unmountOnClose={true}
          isOpen={showTransferAccountModal}
          className="transfer-acc-popup"
          backdropClassName="premium-feature-process-modal-backdrop"
          toggle={this.onCloseIcon}
        >  
          <ModalBody className="ssf-modal__body">
            <div className="header-container">
                <span className="header-name" >Transfer My Account</span>
                <i class="fas fa-times closeButton" onClick={this.onCloseIcon} ></i>
            </div>
            <div>
            <Spinner isFetching={transferStatus === "LOADING"}/>
            <Formik
                enableReinitialize
                initialValues={formInitialValues}
                onSubmit={(values) => this.submitTransfer(values)}
                validationSchema={validationSchema}
                render={formProps => (
                    <TransferAccForm formProps={formProps} onCloseIcon={this.onCloseIcon} success={true} />
                )}/>  
            </div>
          </ModalBody>
        </Modal>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ auth, profile }) => ({})

const mapDispatchToProps = {
    fetchProfile,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TransferAccModal))

import React from 'react'
import { Button, Popover } from 'reactstrap'
import { Card } from 'antd';
import { addEventToAnalytics } from 'Utils';

const modifiers = {
	preventOverflow: {
		enabled: true,
		boundariesElement: 'window'
	},
	flip: {
		enabled: false
	}
}

export function withPopover(TriggerComponent, PopoverData, props) {
	return class extends React.Component {
		constructor(props) {
			super(props)
			this.state = {
				popoverOpen: false
			}
		}

		componentDidMount() {
			if (
				props.firstPopIndex != null &&
				typeof props.firstPopIndex != 'undefined' &&
				props.index === props.firstPopIndex
			)
				this.setState({ popoverOpen: props.firstPop })

			window.addEventListener('scroll', this.handleScroll, true)
		}

		componentWillUnmount() {
			window.removeEventListener('scroll', this.handleScroll)
		}

		handleScroll = () => {
			this.close()
		}

		toggle = () => {
			if (window.innerWidth > 600)
				this.setState({ popoverOpen: !this.state.popoverOpen })
		}

		open = () => {
			if (props.firstPop === true) props.checkPopOpen(false)
			this.setState({ popoverOpen: true })
			const { discovery, card, popUp } = props;
			if (popUp === 'Fund Details Popup') {
				addEventToAnalytics('Fund Details Popup','Fund Details Popup','FUND_DETAILS_POPUP',{ query: discovery.query, logId: discovery.logId, ticker: card.ticker },true);
			}
			if (popUp === 'Risk Popup') {
				addEventToAnalytics('Risk Popup','Risk Popup','RISK_POPUP',{ query: discovery.query, logId: discovery.logId, ticker: card.ticker },true);

			}
			if (popUp === 'Fees Popup') {
				addEventToAnalytics('Fees Popup','Fees Popup','FEES_POPUP',{ query: discovery.query, logId: discovery.logId, ticker: card.ticker },true);
			}
			if (popUp === 'Return Popup') {
				addEventToAnalytics('Return Popup','Return Popup','RETURN_POPUP',{ query: discovery.query, logId: discovery.logId, ticker: card.ticker },true);
			}
		}

		close = () => {
			this.setState({ popoverOpen: false })
		}

		render() {
			const screenWidth = window.screen.width
			return (
				<div
					className={props.className}
					onMouseEnter={this.open}
					onMouseLeave={this.close}
				>
					<div id={props.id} style={props.style} className="w-100">
						<TriggerComponent />
					</div>
					{/* container={document.querySelector(".popoverContainer")} */}
					<Popover
						trigger="hover"
						modifiers={modifiers}
						className="namePop"
						placement={props.placement}
						isOpen={this.state.popoverOpen}
						target={props.id}
						toggle={this.toggle}
					>
						<div className="pop-wrapper">
							<Button
								close
								className="popover-close"
								onClick={this.close}
							/>
							<PopoverData />
						</div>
					</Popover>
				</div>
			)
		}
	}
}

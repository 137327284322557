import React from 'react';


export const TitleWrap2 = ({ title, children, klasses = '' }) => (
  <div>
    <h4 className={`title ${klasses}`}>{title}</h4>
    <div className="border-c1 border-top-0">
      { children }
    </div>
  </div>
)


export const TitleWrap3 = ({ title, children, klasses = '' }) => (
  <div className="bg-c6 mb-4">
    <h4 className={`title ${klasses}`}>{title}</h4>
    { children }
  </div>
)


export const TitleWrap1 = ({ title, children, klasses = '' }) => (
  <div className="wrap-1 mb-4">
    <div className={`title ${klasses}`}><h4>{title}</h4></div>
    { children }
  </div>
)

import React, { useContext } from 'react';
// import { Row, Col, Table } from 'reactstrap';
import { kmb } from 'DataSet';
import { quintileSize, quintileText, getImage } from 'Utils';
import { getFRRAttrDataForModels, textCapitalize } from 'layouts/utils';
import moment from 'moment'
import PortfolioContext from '../PortfolioContext'

export const TableFeeRiskReturnHead = (props) => {
  const reportData = useContext(PortfolioContext);
  const { highlight: highlights, _defaultFRR: defaultFRR, _originalQueryVar: originalQueryVar, query } = props.data;

  highlights.feesHighLightEnable   = (originalQueryVar.find((vc) => vc.node === 'cost') ? true : false)    || (query && (query.includes('cost') || query.includes('similar')));
  highlights.returnHighLightEnable = (originalQueryVar.find((vc) => vc.node === 'returns') ? true : false) || highlights.returnHighLightEnable;
  highlights.riskHighLightEnable   = (originalQueryVar.find((vc) => vc.node === 'risk') ? true : false)    || (query && (query.includes('risk')));

  let {portfolio: {enhancedPerformanceImpact: {pfRet3y: Returns3Yr}}} = reportData;
  let startDate = Returns3Yr && (Returns3Yr.length > 0 && Returns3Yr[0]) ? (Returns3Yr[0].d) : null;
  let endDate   = Returns3Yr && (Returns3Yr.length > 0 && Returns3Yr[Returns3Yr.length - 1]) ? (Returns3Yr[Returns3Yr.length - 1].d) : null;
  let dateRange = startDate && endDate ? `${moment(startDate).format('MMM YYYY')} - ${moment(endDate).format('MMM YYYY')}` : null;

  // console.log(highlights, defaultFRR, originalQueryVar);

  const FRR_DATA_ATTRS = getFRRAttrDataForModels(query, highlights);
  let FRR_ATTRS = FRR_DATA_ATTRS.filter((item) => defaultFRR.includes(item.attr));
  if (FRR_ATTRS.length !== defaultFRR.length) {
    const EXCLUDED_NODE_WAS = [];
    const renderingFRRs = FRR_ATTRS.map((kgf) => kgf.attr)
    const NEW_NODE = defaultFRR.filter((jh) => !renderingFRRs.includes(jh));

    NEW_NODE.map((kkl) => {
      if (kkl) {
        const foundInQueryVar = originalQueryVar.find((vc) => vc.node === kkl);
        if (foundInQueryVar) {
          EXCLUDED_NODE_WAS.push({
            reverse: true,
            multiplier: foundInQueryVar.multiplier !== '' ? foundInQueryVar.multiplier : 1,
            attr: foundInQueryVar.node,
            name: ((foundInQueryVar['Name of the chart on UI'] !== '') ? textCapitalize(foundInQueryVar['Name of the chart on UI']) : textCapitalize(foundInQueryVar.view_name)),
            qcol: foundInQueryVar.variable,
            col: foundInQueryVar.variable,
            img: "Assets", // common image for all unknown node
            suffix: foundInQueryVar.suffix,
            decimal: foundInQueryVar.decimal,
            highlightClass: 'search-risk-stats-highlighting',
          });
        }
      }
    });
    FRR_ATTRS = [...FRR_ATTRS, ...EXCLUDED_NODE_WAS].slice(0, 3); // limit 3
  }

  FRR_ATTRS.map((jt) => {
    const df = originalQueryVar.find((dc) => dc.node === jt.attr)
    if (df) {
      jt.decimal = df.decimal;
      jt.multiplier = df.multiplier !== '' ? df.multiplier : 1;
      jt.attr = df.node;
      jt.qcol = df.variable;
      jt.col = df.variable;//df.node.includes('tracking_error') ? df.variable : df.variable;
      jt.name = (df['Name of the chart on UI'] !== '' ? textCapitalize(df['Name of the chart on UI']) : textCapitalize(df.view_name));
    }
  });

  // console.log(FRR_ATTRS);

  const elems = FRR_ATTRS.map(attr => {
    const qs = quintileSize(props.data[attr.qcol], attr.reverse);
    const _val = (typeof props.data[attr.col] !== 'undefined' && props.data[attr.col] !== null && props.data[attr.col] !== '--') ?
    (attr.name === 'Assets' ? (kmb(props.data[attr.col])) : (
      (attr.decimal && attr.decimal !== '' ? (props.data[attr.col] * attr.multiplier).toFixed(attr.decimal)
        : (props.data[attr.col] * attr.multiplier).toFixed(attr.decimal) ) + (attr.suffix)
      )) : '--';

    return {
      attr: attr.attr,
      name: attr.name,
      icon: attr.icon ? `${attr.icon}-${qs}` : null,
      iconImg: attr.img ? attr.img : null,
      value: _val,
      label: quintileText(qs),
      highlightClass: attr.highlightClass,
      dateRange,
    }
  })

  return(
    <>
    	{elems.map((item, index) => {
    		return(
    			<TableHeadAttr {...item} />
    		)
    	})}
    </>
  )
};

export const TableFeeRiskReturnBody = (props) => {
  const { highlight: highlights, _defaultFRR: defaultFRR, _originalQueryVar: originalQueryVar, query } = props.data;

  highlights.feesHighLightEnable   = (originalQueryVar.find((vc) => vc.node === 'cost') ? true : false)    || (query && (query.includes('cost') || query.includes('similar')));
  highlights.returnHighLightEnable = (originalQueryVar.find((vc) => vc.node === 'returns') ? true : false) || highlights.returnHighLightEnable;
  highlights.riskHighLightEnable   = (originalQueryVar.find((vc) => vc.node === 'risk') ? true : false)    || (query && (query.includes('risk')));

  // console.log(highlights, defaultFRR, originalQueryVar);

  const FRR_DATA_ATTRS = getFRRAttrDataForModels(query, highlights);
  let FRR_ATTRS = FRR_DATA_ATTRS.filter((item) => defaultFRR.includes(item.attr));

  FRR_ATTRS.map((jt) => {
    const df = originalQueryVar.find((dc) => (dc.node === jt.attr && dc.view_name !== "Highlight"))
    if (df) {
      // jt.decimal = df.decimal;
      jt.multiplier = df.multiplier !== '' ? df.multiplier : 1;
      // jt.attr = df.node;
      // jt.qcol = df.variable; // quintile col
      jt.col = df.node.includes('tracking_error') ? df.variable : df.data;
      jt.name = (df['Name of the chart on UI'] !== '' ? textCapitalize(df['Name of the chart on UI']) : textCapitalize(df.view_name));
    }
  });


  if (FRR_ATTRS.length !== defaultFRR.length) {
    const EXCLUDED_NODE_WAS = [];
    const renderingFRRs = FRR_ATTRS.map((kgf) => kgf.attr)
    const NEW_NODE = defaultFRR.filter((jh) => !renderingFRRs.includes(jh));

    NEW_NODE.map((kkl) => {
      if (kkl) {
        const foundInQueryVar = originalQueryVar.find((vc) => vc.node === kkl);
        if (foundInQueryVar) {
          EXCLUDED_NODE_WAS.push({
            reverse: true,
            multiplier: foundInQueryVar.multiplier !== '' ? foundInQueryVar.multiplier : 1,
            attr: foundInQueryVar.node,
            name: ((foundInQueryVar['Name of the chart on UI'] !== '') ? textCapitalize(foundInQueryVar['Name of the chart on UI']) : textCapitalize(foundInQueryVar.view_name)),
            qcol: foundInQueryVar.variable,
            col: foundInQueryVar.variable,
            img: "Assets", // common image for all unknown node
            suffix: foundInQueryVar.suffix,
            decimal: foundInQueryVar.decimal,
            highlightClass: 'search-risk-stats-highlighting',
          });
        }
      }
    });
    FRR_ATTRS = [...FRR_ATTRS, ...EXCLUDED_NODE_WAS].slice(0, 3); // limit 3
  }

  // console.log(FRR_ATTRS);

  const elems = FRR_ATTRS.map(attr => {
    const qs = quintileSize(props.data[attr.qcol], attr.reverse);
    const _val = (typeof props.data[attr.col] !== 'undefined' && props.data[attr.col] !== null && props.data[attr.col] !== '--') ?
    (attr.name === 'Assets' ? (kmb(props.data[attr.col])) : (
      (attr.decimal && attr.decimal !== '' ? (
        props.data[attr.col] * attr.multiplier).toFixed(attr.decimal)
        : Math.round((props.data[attr.col] * attr.multiplier)) ) + (attr.suffix)
      )) : '--';

    return {
      attr: attr.attr,
      name: attr.name,
      icon: attr.icon ? `${attr.icon}-${qs}` : null,
      iconImg: attr.img ? attr.img : null,
      value: _val,
      label: quintileText(qs),
      highlightClass: attr.highlightClass,
    }
  })

  return(
    <>
    	{elems.map((item, index) => {
    		return(
    			<TableBodyAttr {...item} />
    		)
    	})}
    </>
  )
};

const TableBodyAttr = ({ attr, name, icon, iconImg, value, label, highlight, highlightClass }) => {
  // console.log(attr, name, icon, iconImg, value, label, highlight);
  return (
		<td>
			<div className={`${attr}-flag-color ${(highlight || highlightClass) ? 'flag-highlight' : ''}`}>
				{(iconImg !== null && icon === null) ? (
					<img className="far fa-fw" src={getImage(iconImg)} width="25" style={{ marginBottom: 10 }} />
				) : (
					<i className={`far fa-fw fassf ${icon}`} />
				)}
				<p className="__val" style={{ margin: 0, lineHeight: 1 }}>{value}</p>
			</div>
		</td>
  )
}

const TableHeadAttr = ({ attr, name, icon, iconImg, value, label, highlight, highlightClass,dateRange }) => {
  // console.log(attr, name, icon, iconImg, value, label, highlight);
	// className={`${attr}-flag-color ${(highlight || highlightClass) ? 'flag-highlight' : 'flag-highlight'} return-flag-color`}
  return (
		<th>
			<span className={`return-flag-color`}>
				{(iconImg !== null && icon === null) ? (
					<img className="far fa-fw" src={getImage(iconImg)} width="20" style={{ marginBottom: 3, marginRight: 5 }} />
				) : (
					<i className={`far fa-fw fassf ${icon}`} />
				)}
				{name}
			</span>
      {(attr==='returns' || attr==='risk') ? <p className={'subtitle'}>{dateRange}</p> :<p> </p>}
		</th>
  )
}

export default {
	TableFeeRiskReturnHead,
	TableFeeRiskReturnBody,
}

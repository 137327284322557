import React, { Component } from 'react'
import { get, toLower } from 'lodash'
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip
} from 'reactstrap'
import { CustomFormFeedback, DatePicker, DropdownDatePicker } from 'shared-components'
import { PrimaryButton, SecondaryButton } from 'shared-components/'
import {
  countryList,
  areYouAnList,
  investableAssetsList,
  assetUnderManagementList,
  whereDidYouHear,
  usStates
} from '../Constant/FormData'
import TermsConditions from './../SearchResults/Elements/Registrations/AgreeTermsNConditions'
import { REDIRECT_DOMAIN_PATH } from 'DataSet'
import '../Register/RegisterFormOpt2.scss'
import { Checkbox } from 'antd'
import moment from 'moment'
import { addEventToAnalytics } from 'Utils';

const magnifiLogo =
  '/assets/images/MagnifiLogo/Magnifi_Tagline_Charcoal_RGB.png'

export default class RegistrationRequiredDetails extends Component {
  state = {
    areYouAnOther: false,
    areYouanValue: '',
    referral: false,
    tooltipOpen: false,
    termsCondition: false,
    freeTrial: false,
    isManualChangeFreeTrial: false,
    dataCaptureObj: {},
    dateHasValue: ''
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].style.paddingTop = 0;
    // document.addEventListener("keydown", this._handleKeyDown);
  }

  // _handleKeyDown = (e) => {
  //   if(e.code === 'Tab' && e.target && e.target.id === 'date_of_birth'){
  //     document.getElementById('investable_assets').click()
  //   }
  // }

  componentWillUnmount(){
    // document.removeEventListener("keydown", this._handleKeyDown);
  }


  componentWillReceiveProps(nextProps) {
    if (
      !this.state.areYouAnOther &&
      nextProps.formProps.values.areYouAn === 'Other/Curious'
    ) {
      this.setState({ areYouAnOther: true }, () =>
        document.getElementById('areYouAnOther').focus()
      )
    } else if (
      this.state.areYouAnOther &&
      nextProps.formProps.values.self_role === 'Other/Curious'
    ) {
    } else this.setState({ areYouAnOther: false })

    let self_role = nextProps.formProps.values.self_role
      ? nextProps.formProps.values.self_role
      : ''
    if (!this.state.isManualChangeFreeTrial) {
      if (
        self_role === 'RIA' ||
        self_role === 'Hybrid Advisor' ||
        self_role === 'Broker/Dealer/Wirehouse Advisor'
      ) {
        !nextProps.formProps.values.freeTrial &&
          nextProps.formProps.setFieldValue('freeTrial', true)
      } else {
        nextProps.formProps.values.freeTrial &&
          nextProps.formProps.setFieldValue('freeTrial', false)
      }
    } else {
      if (
        self_role === 'Individual Investor' ||
        self_role === 'Other/Curious'
      ) {
        nextProps.formProps.values.freeTrial &&
          nextProps.formProps.setFieldValue('freeTrial', false)
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.error && this.props.error !== prevProps.error) {
      const { setErrors } = this.props.formProps
      setErrors({
        email: get(this.props, 'error.email[0]'),
        password: get(this.props, 'error.password[0]'),
        refree_code: get(this.props, 'error.refree_code[0]')
      })
    }
  }

  onTermsAndConditionClick = () => {
    this.setState({
      termsCondition: !this.state.termsCondition
    })
  }

  onTermsAndConditionClose = () => {
    this.setState({
      termsCondition: !this.state.termsCondition
    })
  }

  toggleCountry = () => {
    this.setState({
      countryState: !this.state.countryState
    })
  }

  toggleAreYouAn = () => {
    this.setState({
      self_role: !this.state.self_role
    })
  }

  handleLabel = () => {
    this.setState({
      referral: !this.state.referral
    })
  }

  toggleinvestableAssets = () => {
    this.setState({
      investable_assets: !this.state.investable_assets
    }, ()=>{
      if(this.state.investable_assets){
        setTimeout(()=>{
          document.getElementById('investable_assets_child0').focus()
        },100)
      }
    })
  }

  toggleAssetManagement = () => {
    this.setState({
      estimated_net_worth: !this.state.estimated_net_worth
    })
  }

  toggleAgeGroup = () => {
    this.setState({
      ageGroupState: !this.state.ageGroupState
    })
  }

  toggleWhereDidYouHeader = () => {
    this.setState({
      hear_aboutus: !this.state.hear_aboutus
    })
  }

  validateForm(e, plan) {
    const { subscribeNow, formProps } = this.props
    const { validateForm, handleSubmit } = formProps
    validateForm().then(() => {
      if (formProps.isValid) {
        subscribeNow(plan)
      }
      handleSubmit(e)
    })
  }

  onCountrySelect = value => {
    const { setFieldValue } = this.props.formProps
    setFieldValue('country', value)
    setFieldValue('countryInput', value)
  }

  onCountryChange = e => {
    const { formProps } = this.props
    if (!this.state.countryState) {
      this.setState({
        countryState: true
      })
    }
    formProps.handleChange(e)
  }

  toggleFreeTrial = e => {
    const { formProps } = this.props
    this.setState(
      {
        freeTrial: !this.state.freeTrial,
        isManualChangeFreeTrial: true
      },
      () => {
        formProps.handleChange(e)
      }
    )
  }
  onStateChange = e => {
    const { formProps } = this.props

    if (e.target.value == '') {
      formProps.setFieldValue('state', e.target.value)
    }

    if (!this.state.statesState) {
      this.setState({
        statesState: true
      })
    }
    formProps.handleChange(e)
  }

  onStateSelect = item => {
    const { setFieldValue } = this.props.formProps
    setFieldValue('state', item.abbreviation)
    setFieldValue('state_value', item.abbreviation)
    setFieldValue('stateInput', item.name)
  }

  toggleStateStatus = () => {
    this.setState({
      statesState: !this.state.statesState
    })
  }

  handleFormSubmit = (values, { setSubmitting }) => {
    this.props.formProps.handleSubmit(
      values,
      { setSubmitting },
      this.props.plan_selected === 'basic'
    )
  }

  getSubmitButtonText = (plan_type, self_role) => {
    switch (plan_type) {
      case 'basic':
        const str =
          self_role === '' ||
          self_role === 'Individual Investor' ||
          self_role === 'Other/Curious'
            ? 'CONTINUE'
            : 'Personalize & Start Discovering'
        return process.env.REACT_APP_SUB_DOMAIN === 'advisor'
          ? 'Personalize & Start Discovering'
          : str
      case 'pro':
        return 'Magnifi pro for $250 month'
      case 'pro-plus':
        return 'Magnifi pro+ for $500 month'
      default:
        return 'Personalize & Start Discovering'
    }
  }

  createFreeMagnifiTradingAccountClick = () => {
    // addEventToAnalytics('Create Free Magnifi Trading Account Click', 'Create Free Magnifi Trading Account Click', 'CREATE_FREE_MAGNIFI_TRADING_ACCOUNT_CLICK', {
    //   clickPage: 'Google Register Page'
    // })
    const { registerForTrade } = this.props;
    if (registerForTrade && process.env.REACT_APP_SUB_DOMAIN === 'retail') {
      registerForTrade('OPEN_TRADING_REGISTER_PAGE');
    }
  }

  registerToTradeLaterClick = () => {
    addEventToAnalytics('Register Now Trade Later Click', 'Register Now Trade Later Click', 'REGISTER_NOW_TRADE_LATER_CLICK', {
      clickPage: 'Apple Register Page'
    })
    const { registerForTrade } = this.props;
    if (registerForTrade && process.env.REACT_APP_SUB_DOMAIN === 'retail') {
      registerForTrade(null);
    }
  }

  handleDataCaptures = (key, text, capitalized, errors = null) => {
    let email = localStorage.getItem('socialmail') || window.sessionStorage.getItem('socialmail') || "";
    let captureStatusObj = this.state.dataCaptureObj;
    if(key && captureStatusObj[key]){
      return
    }
    captureStatusObj[key] = true;
    this.setState({ dataCaptureObj: captureStatusObj })
    let captureObj = {
      clickPage: 'Register Apple Page',
      email: email
    };
    if(errors){
      captureObj['errors'] = errors;
    }
    addEventToAnalytics(text, text, capitalized, captureObj)
  }

  render() {
    const { formProps, plan_selected, returnedToEdit } = this.props

    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue
    } = formProps

    let renderModal = ''
    if (this.state.termsCondition)
      renderModal = (
        <TermsConditions onTermsClick={this.onTermsAndConditionClose} />
      )

    const handleDateChange = dateInstance => {
      let dob = moment(dateInstance).format('YYYY-MM-DD')
      if (dateInstance){
        setFieldValue('date_of_birth', dob)
      } else {
        setFieldValue('date_of_birth', '')
      }
    }

    return (
      <Form
        className="magnifi-auth-design-container register-magnifi-auth-design"
        noValidate={true}
        onSubmit={handleSubmit}
        style={{ paddingTop: '3rem' }}
      >

{process.env.REACT_APP_SUB_DOMAIN === 'retail' ? <div className="header-area retail_form_container text-center">	
          <h1 className="header-label main">	
            You’re On Your Way to Set Up a Magnifi Account. Let’s Get Started!	
          </h1>	
        </div>: <Tagline />}	
        {process.env.REACT_APP_SUB_DOMAIN === 'retail' ? <div className="mb-4" style={{ width: '100%' }}>	
          <section class="step-indicator" style={{ width: '100%' }}>	
            {Array(3).fill().map((el, i)=>  {	
              let stepNames = ['Create a Login', 'About You', 'Information & Disclosures']	
              let timer = ['(1 min)', '(1 min)', '(1-2 min)']
            return (	
              <>	
                <div class={`step step${i+1} ${i+1 <= 1 ? 'active': ''}`}>	
                    <div class="step-icon">{i+1}</div>	
                    <span className={stepNames[i] === 'Information & Disclosures' ? 'set_span_text' : ''}>{stepNames[i]}</span>
                    <span className="timer">{timer[i]}</span>	
                </div>	
                {i < 2 ? <div class={`indicator-line ${i+1 < 1 ? 'active': ''} ${i+1 === 1 ? 'nextToActive': ''}`}></div>: null}	
            </>	
            )	
            })}	
          </section>	
        </div> : null}	
        {process.env.REACT_APP_SUB_DOMAIN === 'retail' ? <div className="header-area">	
          {/* <div className="header-label sub">	
            Register To Personalize Your Search Results	
          </div>	 */}
        </div> :  <div className="header-area"><div className="header-label-advisor">	
            Help us personalize your search results	
          </div></div>}

        <div className="body-wrapper">
          {(process.env.REACT_APP_SUB_DOMAIN === 'advisor') && (
            <FormGroup className="element-box dropdown-element pos-rel">
              <Label className="label" htmlFor="refree_code">
                Are you an*
              </Label>

              <Dropdown
                className={
                  errors.self_role && touched.self_role
                    ? 'is-invalid dropdown-wrapper'
                    : 'dropdown-wrapper'
                }
                isOpen={this.state.self_role}
                toggle={this.toggleAreYouAn}
                onChange={this.handleAreYouAnChange}
              >
                <DropdownToggle
                  value={values.self_role}
                  style={{ backgroundColor: '#fff' }}
                  className={
                    errors.areYouAnOther && touched.areYouAnOther
                      ? 'is-invalid input-area'
                      : 'input-area'
                  }
                  caret
                >
                  {values.self_role || 'Select'}
                </DropdownToggle>
                <DropdownMenu center>
                  {areYouAnList.map(item => {
                    return (
                      <DropdownItem
                        value={item}
                        onClick={e => {
                          setFieldValue('self_role', e.target.value)
                        }}
                      >
                        {item}
                      </DropdownItem>
                    )
                  })}

                </DropdownMenu>
              </Dropdown>
              {errors.self_role && touched.self_role && (
                <div className="form-invalid-field">{errors['self_role']}</div>
              )}
              {errors.areYouAnOther && touched.areYouAnOther && (
                <div className="form-invalid-field">
                  {errors['areYouAnOther']}
                </div>
              )}
            </FormGroup>
          )}

          {(process.env.REACT_APP_SUB_DOMAIN === 'retail') && (
            <>
            <Row className={'retail_form_container form_input_container_gray border_radius_top'}>
              <Col md="12" lg="12" xl="6">
                <FormGroup className="element-box dropdown-element pos-rel">
                  <Label className={values.date_of_birth || this.state.dateHasValue ? "label new_label has_value addtion_icon_label" : "label new_label addtion_icon_label"} htmlFor="date_of_birth">
                    <span className="addition_icon">Date of Birth* <span>&dagger;</span></span>
                  </Label>
                  {/* <DropdownDatePicker
                      id="date_of_birth"
                      value={values.date_of_birth
                        ? typeof values.date_of_birth.getMonth === 'function'
                          ? values.date_of_birth._d
                          : moment(values.date_of_birth)._d
                        : null}
                      onDateChange={(dateInstance, dateString) => {
                        this.handleDataCaptures('date_of_birth', 'DOB', 'DOB_INPUT');
                        setFieldValue(
                          'date_of_birth',
                          moment(dateInstance).format('YYYY-MM-DD') || null
                        )
                      }}
                      maxDate={new Date(
                        moment()
                          .subtract(18, 'years')
                          .format('YYYY-MM-DD')
                      )}
                    />                   */}
                  <DatePicker
                    id="date_of_birth"
                    onKeyDown={(e)=> {
                      if(e.key === 'Tab'){
                        e.preventDefault();
                        document.getElementById('investable_assets').click();
                        document.getElementById('investable_assets').focus();
                      }
                    }}
                    selected={
                      values.date_of_birth
                        ? typeof values.date_of_birth.getMonth === 'function'
                          ? values.date_of_birth
                          : moment(values.date_of_birth)._d
                        : values.date_of_birth
                    }
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={
                      new Date(
                        moment()
                          .subtract(18, 'years')
                          .format('YYYY-MM-DD')
                      )
                    }
                    autoComplete="off"
                    onChange={(dateInstance, dateString) =>{
                      this.handleDataCaptures('date_of_birth', 'DOB Input', 'DOB_INPUT');
                      this.setState({ dateHasValue: dateString })
                      handleDateChange(dateInstance)
                    }}
                    className={
                      errors.date_of_birth && touched.date_of_birth
                        ? 'is-invalid input-area form-control'
                        : 'input-area form-control'
                    }
                  />
                  {errors.date_of_birth && touched.date_of_birth && (
                    <div className="form-invalid-field">
                      {errors['date_of_birth']}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md="12" lg="12" xl="6">
                <FormGroup className="element-box dropdown-element pos-rel country-wrapper">
                  <Label className={values.investable_assets ? "label new_label has_value addtion_icon_label" : "label new_label addtion_icon_label"} htmlFor="investable_assets">
                    <span className="addition_icon">Investable Assets* <span>&dagger;</span></span>
                  </Label>
                  <Dropdown
                    className={
                      errors.investable_assets && touched.investable_assets
                        ? 'is-invalid dropdown-wrapper'
                        : 'dropdown-wrapper'
                    }
                    isOpen={this.state.investable_assets}
                    toggle={this.toggleinvestableAssets}
                  >
                    <DropdownToggle
                      id="investable_assets"
                      value={values.investable_assets}
                      style={{ backgroundColor: '#fff' }}
                      className={
                        errors.investable_assets && touched.investable_assets
                          ? 'is-invalid input-area'
                          : 'input-area'
                      }
                      caret
                    >
                      {values.investable_assets === ''
                        ? ''
                        : values.investable_assets}
                    </DropdownToggle>
                    <DropdownMenu center>
                      {investableAssetsList.map((item, ind) => {
                        return (
                          <DropdownItem
                            id={'investable_assets_child'+ind}
                            value={item}
                            onClick={e => {
                              this.handleDataCaptures('investable_assets', 'Investable Assets', 'INVESTABLE_ASSETS');
                              setFieldValue('investable_assets', e.target.value)
                            }}
                          >
                            {item}
                          </DropdownItem>
                        )
                      })}

                    </DropdownMenu>
                  </Dropdown>

                  {errors.investable_assets && touched.investable_assets && (
                    <div className="form-invalid-field">
                      {errors['investable_assets']}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginBottom: 10, paddingBottom: '0.85rem' }} className="retail_form_container form_input_container_gray border_radius_bottom">
                <Col md={12} className="text-center">
                  <span className="help_note"><span>&dagger;</span> These fields will guide search suggestions based on user similarity</span>
                </Col>
                <Col md={12} className="help_note mt-1 text-center">
                  * Mandatory 
                </Col>
            </Row>
          </>
          )}

          {(process.env.REACT_APP_SUB_DOMAIN === 'advisor' && false) && (
            <FormGroup className="element-box dropdown-element pos-rel country-wrapper">
              <Label className="label required" htmlFor="country">
                Assets Under Management/ Advice ?
              </Label>
              <Dropdown
                className={
                  errors.estimated_net_worth && touched.estimated_net_worth
                    ? 'is-invalid dropdown-wrapper'
                    : 'dropdown-wrapper'
                }
                isOpen={this.state.estimated_net_worth}
                toggle={this.toggleAssetManagement}
              >
                <DropdownToggle
                  value={values.estimated_net_worth}
                  style={{ backgroundColor: '#fff' }}
                  className={
                    errors.estimated_net_worth && touched.estimated_net_worth
                      ? 'is-invalid input-area'
                      : 'input-area'
                  }
                  caret
                >
                  {values.estimated_net_worth === ''
                    ? 'Select'
                    : values.estimated_net_worth}
                </DropdownToggle>
                <DropdownMenu center>
                  {assetUnderManagementList.map(item => {
                    return (
                      <DropdownItem
                        value={item}
                        onClick={e => {
                          setFieldValue('estimated_net_worth', e.target.value)
                        }}
                      >
                        {item}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </Dropdown>
              {errors.estimated_net_worth && touched.estimated_net_worth && (
                <div className="form-invalid-field">
                  {errors['estimated_net_worth']}
                </div>
              )}
            </FormGroup>
          )}

          {(process.env.REACT_APP_SUB_DOMAIN === 'advisor') && (
            <FormGroup
              className="element-box pos-rel pl-0 pb-0 credited-investor"
              check
              style={{ paddingBottom: '1.75rem' }}
            >
              <div className="toggle-switch-wrapper">
                <div className="toggle-content label">
                  Are you an accredited investor?{' '}
                  <span id="aggregatedInvestorTooltip">
                    <i
                      style={{ paddingRight: '5px' }}
                      className="fal fa-info-circle"
                    ></i>
                  </span>
                </div>
                <Label
                  check
                  className="register-form__term-cond-label toggle-switch"
                >
                  <Input
                    type="checkbox"
                    name="accredited_investor"
                    id="accredited_investor"
                    className={
                      errors.accredited_investor &&
                      touched.accredited_investor
                        ? 'register-form__checkbox is-invalid input-area toggle-switch-input'
                        : 'register-form__checkbox input-area toggle-switch-input'
                    }
                    checked={values.accredited_investor}
                    onChange={handleChange}
                  />
                  <span className="slider round"></span>
                </Label>
                <Tooltip
                  placement="right"
                  isOpen={this.state.tooltipOpen}
                  target="aggregatedInvestorTooltip"
                  toggle={() =>
                    this.setState({ tooltipOpen: !this.state.tooltipOpen })
                  }
                >
                  A person or a business entity who is allowed to deal in
                  securities that may not be registered with financial
                  authorities like the SEC.
                </Tooltip>
              </div>
            </FormGroup>
          )}

          {(process.env.REACT_APP_SUB_DOMAIN === 'advisor') && (
            <FormGroup className="element-box dropdown-element pos-rel country-wrapper">
              <Label className="label required" htmlFor="country">
                Where did you hear about us?
              </Label>
              <Dropdown
                className={
                  errors.hear_aboutus && touched.hear_aboutus
                    ? 'is-invalid dropdown-wrapper'
                    : 'dropdown-wrapper'
                }
                isOpen={this.state.hear_aboutus}
                toggle={this.toggleWhereDidYouHeader}
              >
                <DropdownToggle
                  value={values.hear_aboutus}
                  style={{ backgroundColor: '#fff' }}
                  className={
                    errors.hear_aboutus && touched.hear_aboutus
                      ? 'is-invalid input-area'
                      : 'input-area'
                  }
                  caret
                >
                  {values.hear_aboutus || 'Select'}
                </DropdownToggle>
                <DropdownMenu center>
                  {whereDidYouHear.map(item => {
                    return (
                      <DropdownItem
                        value={item}
                        onClick={e => {
                          setFieldValue('hear_aboutus', e.target.value)
                        }}
                      >
                        {item}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </Dropdown>
              {errors.hear_aboutus && touched.hear_aboutus && (
                <div className="form-invalid-field">
                  {errors['hear_aboutus']}
                </div>
              )}
              <CustomFormFeedback
                formProps={formProps}
                fieldName="accredited_investor"
              />
              {errors.accredited_investor && touched.accredited_investor && (
                <div className="form-invalid-field">
                  {errors['accredited_investor']}
                </div>
              )}
            </FormGroup>
          )}

          {(process.env.REACT_APP_SUB_DOMAIN === 'advisor') && (
            <Row>
              <Col>
                <FormGroup className="element-box dropdown-element pos-rel country-wrapper">
                  <Label className="label required" htmlFor="country">
                    Country*
                  </Label>
                  <Dropdown
                    className={
                      errors.country && touched.country
                        ? 'is-invalid dropdown-wrapper'
                        : 'dropdown-wrapper'
                    }
                    isOpen={this.state.countryState}
                    toggle={this.toggleCountry}
                  >
                    <DropdownToggle
                      value={values.country}
                      style={{ backgroundColor: '#fff' }}
                      className={
                        errors.country && touched.country
                          ? 'is-invalid input-area'
                          : 'input-area'
                      }
                      caret
                    >
                      <input
                        style={{ padding: 0, height: `20px` }}
                        type="search"
                        placeholder="Select a country"
                        name="countryInput"
                        value={values.countryInput}
                        onChange={e => this.onCountryChange(e)}
                        className="typeahead-field"
                        autocomplete="off"
                      />
                    </DropdownToggle>
                    <DropdownMenu>
                      {countryList.map(item => {
                        if (
                          !values.countryInput ||
                          toLower(item.name).includes(
                            toLower(values.countryInput)
                          )
                        ) {
                          return (
                            <DropdownItem
                              value={item.name}
                              onClick={e =>
                                this.onCountrySelect(e.target.value)
                              }
                            >
                              {item.name}
                            </DropdownItem>
                          )
                        }
                        return null
                      })}

                    </DropdownMenu>
                  </Dropdown>

                  {errors.country && touched.country && (
                    <div className="form-invalid-field">
                      {errors['country']}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col lg={{ size: 4 }}>
                <FormGroup className="element-box dropdown-element pos-rel country-wrapper">
                  <Label className="label required" htmlFor="state">
                    State*
                  </Label>
                  {values.country !== 'United States' ? (
                    <>
                      <Input
                        type="text"
                        name="state"
                        id="state"
                        value={values.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.state && touched.state
                            ? 'is-invalid input-area'
                            : 'input-area'
                        }
                        placeholder="Enter State"
                      />
                    </>
                  ) : (
                    <Dropdown
                      className={
                        errors.state && touched.state
                          ? 'is-invalid dropdown-wrapper'
                          : 'dropdown-wrapper'
                      }
                      isOpen={this.state.statesState}
                      toggle={this.toggleStateStatus}
                    >
                      <DropdownToggle
                        value={values.state}
                        style={{ backgroundColor: '#fff' }}
                        className={
                          errors.state && touched.state
                            ? 'is-invalid input-area'
                            : 'input-area'
                        }
                        caret
                      >
                        <input
                          style={{ padding: 0, height: `20px` }}
                          type="search"
                          placeholder="Select"
                          name="stateInput"
                          value={values.stateInput}
                          onChange={e => this.onStateChange(e)}
                          className="typeahead-field"
                          autoComplete={`stateInput`}
                        />
                      </DropdownToggle>
                      <DropdownMenu>
                        {usStates.map(item => {
                          if (
                            !values.stateInput ||
                            toLower(item.name).includes(
                              toLower(values.stateInput)
                            )
                          ) {
                            return (
                              <DropdownItem
                                value={item.abbreviation}
                                onClick={e => this.onStateSelect(item)}
                              >
                                {item.name}
                              </DropdownItem>
                            )
                          }
                          return null
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  )}
                  {errors.state && touched.state && (
                    <div className="form-invalid-field">
                      {errors['state']}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col lg={{ size: 4 }}>
                <FormGroup className="element-box pos-rel">
                  <Label className="label required" htmlFor="city">
                    City*
                  </Label>
                  <Input
                    type="text"
                    name="city"
                    id="city"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.city && touched.city
                        ? 'is-invalid input-area'
                        : 'input-area'
                    }
                    placeholder="Enter City"
                  />
                  {errors.city && touched.city && (
                    <div className="form-invalid-field">{errors['city']}</div>
                  )}
                </FormGroup>
              </Col>
            </Row>
          )}

          {(process.env.REACT_APP_SUB_DOMAIN === 'advisor') && (
            <Row>
              <Col lg={{ size: 4 }}>
                <FormGroup className="element-box pos-rel">
                  <Label className="label required" htmlFor="address">
                    Street*
                  </Label>
                  <Input
                    type="text"
                    name="address"
                    id="address"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.address && touched.address
                        ? 'is-invalid input-area'
                        : 'input-area'
                    }
                    placeholder="Enter Street"
                  />
                  {errors.address && touched.address && (
                    <div className="form-invalid-field">
                      {errors['address']}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col lg={{ size: 4 }}>

                <FormGroup className="element-box pos-rel">
                  <Label className="label required" htmlFor="zipcode">
                    Zip code*
                  </Label>
                  <Input
                    type="text"
                    name="zipcode"
                    id="zipcode"
                    value={values.zipcode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.zipcode && touched.zipcode
                        ? 'is-invalid input-area'
                        : 'input-area'
                    }
                    placeholder="Enter the zip code"
                  />
                  <CustomFormFeedback
                    formProps={formProps}
                    fieldName="zipcode"
                  />
                </FormGroup>
              </Col>
              <Col lg={{ size: 4 }}>
                <FormGroup className="element-box dropdown-element pos-rel">
                  <Label className="label" htmlFor="date_of_birth">
                    Date of Birth*
                  </Label>
                  <DropdownDatePicker
                      id="date_of_birth"
                      shortForm
                      value={values.date_of_birth
                        ? typeof values.date_of_birth.getMonth === 'function'
                          ? values.date_of_birth._d
                          : moment(values.date_of_birth)._d
                        : null}
                      onDateChange={(dateInstance, dateString) => {
                        setFieldValue(
                          'date_of_birth',
                          moment(dateInstance).format('YYYY-MM-DD') || null
                        )
                      }}
                      maxDate={new Date(
                        moment()
                          .subtract(18, 'years')
                          .format('YYYY-MM-DD')
                      )}
                    />                  
                  {/* <DatePicker
                    id="date_of_birth"
                    selected={
                      values.date_of_birth
                        ? typeof values.date_of_birth.getMonth === 'function'
                          ? values.date_of_birth
                          : moment(values.date_of_birth)._d
                        : values.date_of_birth
                    }
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={
                      new Date(
                        moment()
                          .subtract(18, 'years')
                          .format('YYYY-MM-DD')
                      )
                    }
                    autoComplete="off"
                    onChange={(dateInstance, dateString) => {
                      setFieldValue(
                        'date_of_birth',
                        moment(dateInstance).format('YYYY-MM-DD') || null
                      )
                    }}
                    className={
                      errors.date_of_birth && touched.date_of_birth
                        ? 'is-invalid input-area form-control'
                        : 'input-area form-control'
                    }
                  /> */}
                  {errors.date_of_birth && touched.date_of_birth && (
                    <div className="form-invalid-field">
                      {errors['date_of_birth']}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
          )}

          {(process.env.REACT_APP_SUB_DOMAIN === 'advisor') && (
            <div className={'free-trial'}>
              <Checkbox
                value={values.freeTrial}
                name="freeTrial"
                id="freeTrial"
                checked={values.freeTrial}
                onChange={this.toggleFreeTrial}
              >
                Sign Up for a free 14 day trial
              </Checkbox>
            </div>
          )}

        </div>

        <FormGroup className="cta-wrapper">
          {(process.env.REACT_APP_SUB_DOMAIN !== 'retail') && (
            <div
              onClick={() => {
                localStorage.setItem('isPlanChange', 'true')
                handleSubmit()
              }}
            >
              <p style={{ paddingBottom: `10px` }} className={'change-sub'}>
                Change Your Subscription Plan
              </p>
            </div>
          )}

          <div style={{ width: '100%' }} onClick={()=> {
            let erro = formProps.dirty ? Object.keys(formProps.errors).map(key => formProps.errors[key]) : 'All fields are empty';
            if(!formProps.dirty && returnedToEdit){
              this.handleDataCaptures('', 'Register Apple Button Clicked', 'REGISTER_BUTTON_CLICKED');
            } else {
              this.handleDataCaptures('', formProps.isValid ? 'Register Apple Button Clicked' : 'Register Apple Button Clicked With Invalid Details', formProps.isValid ? 'REGISTER_BUTTON_CLICKED' : 'REGISTER_BUTTON_CLICKED_WITH_INVALID_DETAILS', erro);
            }
            this.createFreeMagnifiTradingAccountClick()
          }}>
            <PrimaryButton type="submit" className="btn primary-btn">
              {this.getSubmitButtonText(plan_selected, values.self_role)}
            </PrimaryButton>
          </div>

          {(process.env.REACT_APP_SUB_DOMAIN !== 'retail') && <div className="or-partition mt-4">
            * Mandatory
          </div>}
        </FormGroup>
      </Form>
    )
  }
}

export const Tagline = () => (
  <div className="mb-3" style={{ width: '90%' }}>
    <a className="logo-wrapper" href={REDIRECT_DOMAIN_PATH}>
      <img src={magnifiLogo} width="100%" alt="magnifi" />
    </a>
  </div>
)

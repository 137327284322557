import React, { Component } from 'react';
import { Drawer, Button } from 'antd';
import { Spinner } from '../Loader';

export default class MDrawer extends React.Component {
  state = { visible: false };

  showDrawer = () => {
    this.props.onOpen();
  };

  onClose = () => {
    this.props.onClose();
  };

  render() {
    const { children, buttonClass, drawerClassName, className, style, buttonText, visible, onOpen, onClose, headerText, loader, FooterDesign } = this.props;
    return (
      <div className={`drawerWrapper ${className || ''}`}>
        {buttonText
          && (
            <Button className={`buttonStyle ${buttonClass || ''}`} type="primary" onClick={this.showDrawer}>
              {buttonText || 'Open'}
            </Button>
          )
        }
        <Drawer
          {...this.props}
          width={545}
          className={`drawerContainer ${drawerClassName || ''}`}
          title={headerText || ''}
          placement="right"
          onClose={this.onClose}
          visible={visible}
          getContainer={false}
          style={{ position: 'absolute' }}
        >
          <div className="mdrawer-body-wrapper custom-scroll">
            {loader && <Spinner loader={loader} />}
            {children}
          </div>
          {FooterDesign}
        </Drawer>
      </div>
    );
  }
}

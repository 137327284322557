import React, { Component, useState } from 'react';
import { Tabs, Row, Col, Card, Checkbox, Icon, Progress, Select } from 'antd';
import _ from 'lodash';

import { sponsorLogo } from 'DataSet';
import { FormatCurrency } from 'components/reports/shared/Utils';
import { TruncatedText } from 'components/sf/Common';

import CardLoader from '../../../Elements/CardLoader';
import CustomSearch from '../../../Elements/CustomSearch';
import FundCard from './FundCard';
import FundCardV2 from './FundCardV2'

const { TabPane } = Tabs;
const { Option } = Select;

export default class CustomCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.tickerList || [],
      value: '',
    }
  }

  componentDidMount() {
    const { tickerList } = this.props;
    if (tickerList) {
      this.setState({ data: tickerList });
    }
  }

  handleChange = (value) => {
    if (value) {
      const { onChange } = this.props;
      if (onChange) {
        onChange(value);
      }
    }
  }

  cardLoader = () => (
    <CardLoader className="skeleton-bg-transparent skeleton-body-padding-0" skeleton={1} minHeight="auto" />
  )

  deleteCard = (item, index, searchedTicker, forTicker, forQuery) => {
    const { deleteCard } = this.props;
    if (deleteCard) {
      deleteCard({ item, index, searchedTicker, forTicker, forQuery });
    }
  }

  render() {
    const { tickerList, tickerLoading, customSearchTickerData, query, ticker, highlights, CustomCheckbox } = this.props;
    return (
      <React.Fragment>
        {customSearchTickerData && typeof customSearchTickerData.dataMappedArray !== 'undefined' ? (
          customSearchTickerData.dataMappedArray.map((e, i) => {
            return (
              <FundCardV2
                data={e}
                query={query}
                i={i}
                ticker={ticker}
                highlights={highlights}
                deleteFund={() => this.deleteCard(e, i, e.ticker, ticker, query)}
                Checkbox={<CustomCheckbox  className='p-top-right custom-ant-checkbox' key={e} value={e} />}
              />
            )
          })
        ) : (
          <Card loading={tickerLoading} className={`fund-card-container custom-ticker-block ${tickerLoading ? 'card-loader' : ''}`} style={{ width: 240, height: 183 }}>
            <img src="/assets/images/plus.png"/>
            <CustomSearch onChange={this.handleChange} dataList={tickerList} />
          </Card>
        )
        }
      </React.Fragment>
    )
  }
}

const ImageOrText = ({ img, text = '' }) => {
  const [valid, setValid] = useState(true);
  return (
    valid
    ? <img src={img} className="img-fluid" alt="" onError={() => setValid(false)} style={{height: '20px'}} />
    : <div className="sponsor-logo-placeholder" title={text}>{text}</div>
  );
}

const getId = (function* () {
  let n = 1;
  while (true) yield n++;
})();

import React from 'react';
import { isUrl } from './utils';

const menuData = [{
  name: 'Build Portfolio',
  icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
  path: 'build-portfolio',
    children: [{
      name: 'Builder',
      icon: <i style={{ marginRight: 10 }} className="fas fa-gift" />,
      path: 'onboard-screen',
      // hideInMenu: true,
    }, {
      name: 'Market Place',
      icon: <i style={{ marginRight: 10 }} className="fas fa-gift" />,
      path: 'market-place',
      // hideInMenu: true,
    }],
},
];

function formatter(data, parentPath = '/', parentAuthority) {
  return data.map((item) => {
    let { path } = item;
    if (!isUrl(path)) {
      path = parentPath + item.path;
    }
    const result = {
      ...item,
      path,
      authority: item.authority || parentAuthority,
    };
    if (item.children) {
      result.children = formatter(item.children, `${parentPath}${item.path}/`, item.authority);
    }
    return result;
  });
}

export const getMenuData = () => formatter(menuData);

import React from 'react';
import { Link } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { PrimaryButton } from 'shared-components';

var classNames = require('classnames');

const fundsPreview = "/assets/images/funds-preview-30.png";

export default class Tabs extends React.Component {
  render() {
    const subHeaderStyle = { fontSize: '0.7rem', marginTop: '-4px', textTransform: 'capitalize'};
    return (
      <div>
        <Nav tabs justified pills>
          <NavItem>
            <NavLink className="active" >Funds<div className="media-none" style={subHeaderStyle}>View Details</div></NavLink>
          </NavItem>
          <NavItem>
            <NavLink>Compare<div className="media-none" style={subHeaderStyle}>Identify best</div></NavLink>
          </NavItem>
          <NavItem>
            <NavLink>
              {process.env.REACT_APP_SUB_DOMAIN === 'retail'
                ? <>Impact</>
                : <>Combine</>
              }
              <div className="media-none" style={subHeaderStyle}>Size position</div>
            </NavLink>
          </NavItem>
          <NavItem style={{ border: 0 }}>
            <NavLink>Act<div className="media-none" style={subHeaderStyle}>Propose or buy</div></NavLink>
          </NavItem>

        </Nav>
        <TabContent>
          <div className="spoof-body-wrapper">
            <img className="spoof-img" src={fundsPreview} alt="funds-preview" />
            <div className="overlay-button">
              <PrimaryButton
                type="button"
                className="sign-in__submit spoof-login-button"
                onClick={this.onSinginButtonClick}
              >
                <Link className="link" to="/login">
                  <i className="fal fa-lock-alt"></i>
                  <span className="button-text">LOGIN TO UNLOCK</span>
                </Link>
              </PrimaryButton>
            </div>
          </div>
        </TabContent>
      </div>
    );
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'

import Main from '../components/Main'
import { FeedbackButtonStickToBottom } from '../components/feedback/feedback'
import LoginContainer from './LoginContainer'

import { logout } from '../actions/auth';

class FundsContainer extends Component {
  constructor(props){
    super(props)
    this.state = {
      showLogin: false
    }
  }

  onSinginButtonClick = () => {
    this.setState({
      showLogin: true
    })
  }

  handleLoginClose = () => {
    this.setState({
      showLogin: false
    })
  }
  render() {
    return (
      <React.Fragment>
        <Main {...this.props} onSinginButtonClick={this.onSinginButtonClick} />
        {/* <FeedbackButtonStickToBottom /> */}
        {this.state.showLogin && <LoginContainer handleClose={this.handleLoginClose} fromPath={'/securities'}/>}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  query: state.funds.query,
  user: state.auth.user,
  auth: state.auth,
  isSSOUser: Boolean(state.auth.isGoogleLogin),
  loggedIn: state.auth.loggedIn,
  logoutSuccess: state.auth.logoutSuccess
})

const mapDispatchToProps = {
  logoutHandler: logout
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FundsContainer)

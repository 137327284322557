import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback} from 'reactstrap'
import { get } from 'lodash'

import { CustomFormFeedback, PrimaryButton } from 'shared-components'
import TermsConditions from './../SearchResults/Elements/Registrations/AgreeTermsNConditions';

export default class SignUp extends Component {

  state = {
    termsCondition:false,
  }

  onTermsAndConditionClick = () =>{
    this.setState({
      termsCondition: !this.state.termsCondition,
    })
  }

  onTermsAndConditionClose = () => {
    this.setState({
      termsCondition: !this.state.termsCondition,
    })
  }

  render() {
    const { formProps, error, clearError } = this.props;

    let renderModal = '';

    if(this.state.termsCondition)
        renderModal = <TermsConditions onTermsClick={this.onTermsAndConditionClose}/>

    const {
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit
    } = formProps
    const registerApiError = get(error, 'registerApiError', '');
    
    return (
      <Form className="sign-in magnifi-auth-design-container" noValidate={true} onSubmit={handleSubmit}>
        <div className="header-area">
          <div className="header-label">Sign up for Magnifi Widget</div>
        </div>

        <div className="body-wrapper widget-register">
          <FormGroup className="element-box">
            <Label htmlFor="first_name" className="label">
              First Name
            </Label>
            <Input
              type="text"
              name="first_name"
              id="first_name"
              value={values.first_name}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.first_name && touched.first_name ? 'is-invalid input-area' : 'input-area'
              }
              // placeholder="Enter your first name"
            />
            <CustomFormFeedback formProps={formProps} fieldName="first_name" />
          </FormGroup>

          <FormGroup className="element-box">
            <Label className="label" htmlFor="last_name">Last Name</Label>
            <Input
              type="text"
              name="last_name"
              id="last_name"
              value={values.last_name}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.last_name && touched.last_name ? 'is-invalid input-area' : 'input-area'
              }
              // placeholder="Enter your last name"
            />
            <CustomFormFeedback formProps={formProps} fieldName="last_name" />
          </FormGroup>

          <FormGroup className="element-box">
            <Label className="label" htmlFor="email">E-mail</Label>
            <Input
              type="text"
              name="email"
              id="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.email && touched.email ? 'is-invalid input-area' : 'input-area'}
              // placeholder="Enter your email address"
            />
            <CustomFormFeedback formProps={formProps} fieldName="email" />
          </FormGroup>

          <FormGroup className="element-box">
            <Label className="label" htmlFor="url">URL</Label>
            <Input
              type="text"
              name="url"
              id="url"
              value={values.url}
              onChange={(e) => { clearError();handleChange(e); }}
              onBlur={handleBlur}
              className={touched.url && (errors.url || registerApiError) ? 'is-invalid input-area' : 'input-area'}
              // placeholder="Enter your URL"
            />
            <CustomFormFeedback formProps={formProps} fieldName="url" />
            {registerApiError && <FormFeedback>{registerApiError}</FormFeedback>}
          </FormGroup>

          <FormGroup className="element-box">
            <div className="radio-group label">
              <div className="radio-group__item">
                <Input
                  type="radio"
                  name="version"
                  id="guided"
                  // checked={values.version}
                  value="guided"
                  onChange={handleChange}
                  className={errors.version && touched.version ? 'is-invalid input-area' : 'input-area'}
                />
                <label for="guided">Magnifi Guided Discovery Tool</label>
              </div>

              <div className="radio-group__item">
                <Input
                  type="radio"
                  name="version"
                  id="search"
                  value="search"
                  // checked={values.version}
                  onChange={handleChange}
                  className={errors.version && touched.version ? 'is-invalid input-area' : 'input-area'}
                />
                <label for="search">Magnifi Search API</label>
              </div>
            </div>
            {/* <CustomFormFeedback formProps={formProps} fieldName="version" /> */}
            {
              errors.version && touched.version && <div className="radio-invalid-feedback">{errors.version}</div>
            }
          </FormGroup>

          <FormGroup className="element-box" check>
            <Label check className="register-form__term-cond-label label">
              <Input
                type="checkbox"
                name="termsCondition"
                id="termsCondition"
                className={
                  errors.termsCondition && touched.termsCondition
                    ? 'register-form__checkbox is-invalid input-area'
                    : 'register-form__checkbox input-area'
                }
                checked={values.termsCondition}
                onChange={handleChange}
              />
              I agree to{' '}
              <Link to="#" className="tnc_hyper-link" onClick={this.onTermsAndConditionClick}>
                Terms and Conditions {renderModal}
              </Link>
              <CustomFormFeedback
                formProps={formProps}
                fieldName="termsCondition"
              />
            </Label>
          </FormGroup>

          <FormGroup className="cta-wrapper">
            <PrimaryButton
              type="submit"
              className="btn primary-btn"
              block
              disabled={isSubmitting}
            >
              SUBMIT
            </PrimaryButton>
          </FormGroup>
        </div>
      </Form>
    )
  }
}

import React, { Component, Fragment } from 'react';

import NotApplicable from '../../NotApplicable';
import D3Sunburst from './D3Sunburst';
import { RegionColors, SectorColors } from 'data/Colors';
import { RegionChartMapping } from 'ChartData';

class RenderBurst extends Component {

  burstRefs = [];

  handleBurstClick = (node) => {
    const name = node.data.name;
    this.burstRefs.forEach(burstRef => {
      const searchNode = burstRef.clickableNodes.find(node => node.data.name === name);
      if (searchNode) {
        burstRef.clicked(searchNode);
      }
    });
  };

  render() {
    const { fund, data, i, queryVar } = this.props;
    const { view_name, node, ontology_node } = queryVar;

    if (data.length === 0) return <NotApplicable />;

    let centerDisplayText, centerValueText;
    const displayText = RegionChartMapping.filter(e => e.chart_name === node);
    centerDisplayText = displayText.length ? displayText[0].region_name : '';
    centerValueText = `${fund[ontology_node] ? fund[ontology_node].toFixed(1) : '0'}%`;

    const chartData = {
      name: 'Allocations',
      children: data.map(group => ({
        size: 0,
        name: group.n,
        children: group.sub.map(groupItem => ({
          name: groupItem.n,
          size: groupItem.v,
        })),
      })),
    };

    const primaryTextStyle = {
      // top: '39%',
      fontSize: 11,
      fontWeight: 'bold',
      zIndex: '0',
      marginLeft: '-3px'
    };

    return (
      <div className="siw-pie-holder print-wrapper">
        <span
          className="siw-pie-title"
          title={centerDisplayText}
        >
          {centerDisplayText !== '' ? `% ${centerDisplayText}` : view_name}
        </span>

        <div className="d3ChartWrapper">
          {(view_name === 'Regions') && centerDisplayText !== ''
            && (
              <div className="primaryTextStyle" style={primaryTextStyle}
              >
                {centerValueText}
                <div className="highlightTooltip">{centerDisplayText}: {centerValueText}</div>
              </div>
            )
          }
          <D3Sunburst
            width={80}
            height={80}
            colors={RegionColors}
            data={chartData}
            ref={el => this.burstRefs[i] = el}
            clickHandler={this.handleBurstClick}
            isAnimationActive={false}
          />
        </div>
      </div>
    );
  }
};

export default RenderBurst;
import React, { Component, Fragment } from 'react';
import { Upload, message, Button, Form } from 'antd';

import { connect } from 'react-redux';
import { FormGroup, UncontrolledTooltip, Tooltip } from 'reactstrap'
import { investmentOptions, uploadPortfolio, investmentFetchSuccess  } from 'actions/investment';

import {
  UploadOutlined,
} from '@ant-design/icons';

class UploadFile extends Component {
  UploadPortfolio = { name: 'Portfolio', code: 'uplPrt' }

  constructor(props){
    super(props);
    this.state = {
      nickName: '',
      filesList: [],
      // file: {},
      fileUpload: false,
      uploadError: false,
      uploadInputError: false,
      multipleUploadError: false,
    }
    this.file = new FormData();
  }

  componentDidMount() {
    this.props.investmentFetchSuccess({
      uploadfileDuplicate: false,
      uploadfileIncomplete:false,
      uploadFileFailure: false ,
      uploadFileFailureMsg: '',
      uploadFilePartial: false,
      uploadFilePartialMsg: '',
    });
  }

  componentDidUpdate() {
  }

  setNickName = (e) => {
    const name = document.getElementById('file-name-box').value;
    this.setState({ nickName : name })
  }

  submitPortfolio = () => {
    // e.preventDefault();
    const { fileUpload } = this.state;
    const { uploadfileDuplicate } = this.props;
    const name = document.getElementById('file-name-box').value;

    if (name == '') {
      this.setState({ uploadInputError: true, uploadError: false })
    } else if (uploadfileDuplicate) {
      this.file.append('name', name);
      this.setState({ fileUpload: false, uploadInputError: false, uploadError: false, }, () => {
        this.props.uploadPortfolio(this.file, this.props.onFileSuccess);
      })
    } else if (!fileUpload) {
      this.setState({ uploadError: true, uploadInputError: false  })
    } else {
      this.file.append('name', name);
      this.setState({ fileUpload: false, filesList: [], uploadInputError: false, uploadError: false, }, () => {
        this.props.uploadPortfolio(this.file, this.props.onFileSuccess);
      })
    }
  }

  render() {
    const { uploadInputError, uploadError, multipleUploadError } = this.state;
    const { uploadfileDuplicate, uploadfileIncomplete, uploadFileFailure, uploadFileFailureMsg, uploadFilePartial } = this.props;
    const props = {
      name: 'file',
      accept: '.csv,',
      beforeUpload: (file) => {
        let fileExt = file.name.split('.');
        fileExt = fileExt[fileExt.length - 1];
        if (fileExt !== 'csv') {
          this.setState({ uploadError: true, fileUpload: false, filesList: [] })
          return false
        }
      },
      onRemove: () => {
        this.setState({ fileUpload: false, filesList: [] })
      },
      customRequest: ({ file }) => {
        const { filesList } = this.state;
        if (Object.keys(file).length > 0 && filesList.length === 0) {
          this.file.append('file', file);
          filesList.push(file);
          this.setState({ filesList, uploadError: false, multipleUploadError: false, fileUpload: true }, () => this.submitPortfolio());
        } else if (filesList.length === 1) {
          this.setState({ multipleUploadError: true })
        } else this.setState({ uploadError: true })
      },
    };

    return (
      <React.Fragment>
        <form className="upload-form-content">
          <FormGroup className="element-box pos-rel">
            <label>Label</label>
            <input
              id="file-name-box"
              className="input-area"
              type="text"
              // placeholder="Give your portfolio a nickname"
              onChange={this.setNickName}
              autoComplete="off"
              />
              {uploadInputError && <span className="upload-error">Please enter a label to upload</span>}
              {!uploadInputError && uploadfileDuplicate && <span className="upload-error">Label already exists</span>}
          </FormGroup>
          <div className="col-12 upload-btn element-box" >
            <Upload  className="upload-container" {...props} fileList={this.state.filesList} disabled={this.state.nickName !== '' ? false : true}>
              <Button >
                <UploadOutlined /> Select file
              </Button>
              <Fragment>
                <div className="arrow-up"></div>
                <span className="upld-cont-info">
                  Please enter 'Label' to upload portfolio
                </span>
              </Fragment>
            </Upload>
            <span id="upload-tooltip"><i className="fal fa-info-circle"></i></span>
            <span className="sample-file"><a href="/Portfolio.csv" download="Portfolio.csv" target="_blank" >Download sample</a></span>
            <UncontrolledTooltip className="upl-tool-info" placement="right" target="upload-tooltip">
              Please upload a .csv file with the following columns – <br /><br /> Mandatory :<br /><br />1) ticker – The symbol used on a trading exchange<br />2) shares – quantity held<br /><br /> Optional :<br /><br />3) price – The price at which the security was purchased<br />4) date – date on which the security was purchased
            </UncontrolledTooltip>
          </div>
          <div className="col-12 element-box">
            {uploadError && <span className="upload-error">Please upload a csv file</span>}
            {uploadfileIncomplete && <span className="upload-error">Please fill all required fields in the csv</span>}
            {multipleUploadError && <span className="upload-error">Please upload only one file</span>}
            {uploadFileFailure && <span className="upload-error">{uploadFileFailureMsg}</span>}
          </div>
          {/* <div className="col-12 cta-wrapper form-group element-box">
            <button type="submit" class="ssf-btn-primary btn secondary-btn btn btn-secondary" onClick={this.submitPortfolio}><span>UPLOAD</span></button>
          </div> */}
        </form>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ investment }) => ({
  uploadfileDuplicate: investment.uploadfileDuplicate,
  uploadfileIncomplete: investment.uploadfileIncomplete,
  uploadFileFailure: investment.uploadFileFailure,
  uploadFileFailureMsg: investment.uploadFileFailureMsg,
  uploadFilePartial: investment.uploadFilePartial,
  uploadFilePartialMsg: investment.uploadFilePartialMsg,
})

const mapDispatchToProps = {
  investmentOptions,
  uploadPortfolio,
  investmentFetchSuccess,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadFile)

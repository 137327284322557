import React, { Component } from 'react';
import $ from 'jquery';
//import $ from 'ion-rangeslider/node_modules/jquery/dist/jquery.min';
//import '../../node_modules/ion-rangeslider/js/ion.rangeSlider.min';
import { DateRange } from '../DataSet'
import 'ion-rangeslider/js/ion.rangeSlider';
import moment from 'moment';
var classNames = require('classnames');
const DateFmt = "MMM YYYY";

export default class RangeSlider extends Component {
  constructor(props) {
    super(props);

    this.inRef = React.createRef();
    this.state = {
      years: 3
    }
    this.dateRange = {
      start: moment(DateRange.start),
      end: props.dateRange ? props.dateRange.end : typeof props.dateRangeSelected.end === 'string' ? moment(new Date(`01 ${props.dateRangeSelected.end}`)) : moment(new Date(`01 ${props.dateRangeSelected.end._i}`)),
      // end: moment(new Date(this.props.dateRangeSelected.end))
    }

    this.months = []
    this.months_h = {}
    for (let m = moment(this.dateRange.start).startOf('month'); m.isSameOrBefore(this.dateRange.end); m.add(1, 'months')) {
      var i = this.months.push(m.clone())
      this.months_h[m.format(DateFmt)] = i - 1
    }
  }

  componentDidMount() {
    var change = (data) => {
      var range = { start: this.months[data.from].clone(), end: this.months[data.to].clone().endOf('month') }
      this.props.dateRangeHandler(range);
    }

    var _this = this;
    var opts = {
      type: 'double',
      drag_interval: true,
      keyboard: true,
      grid: false,
      grid_num: 12,
      //parseInt(months.length/12),
      onFinish: change,
      min: 0,
      max: _this.months.length - 1,
      force_edges: true,
      prettify: function (e) {
        return _this.months[e].format(DateFmt)
      }
    }

    $(this.inRef.current).ionRangeSlider(opts);
    this.clickHandler(this.state.years);
  }

  componentWillUnmount() {
    //$(this.refs.input).datepicker('destroy');
  }

  nameToRange(name) {
    var out = { end: this.dateRange.end.clone().endOf('month') };
    out.start = out.end.clone();

    if (name === 'l10y') { out.start.add(1, 'seconds').subtract(10, 'year') }
    else if (name === 'l5y') { out.start.add(1, 'seconds').subtract(5, 'year') }
    else if (name === 'l3y') { out.start.add(1, 'seconds').subtract(3, 'year') }
    else if (name === 'l1y') { out.start.add(1, 'seconds').subtract(1, 'year') }
    else { out.start = moment(DateRange.start) || moment(this.props.dateRange.start) }

    // console.log(out.start.toString(), out.end.toString());
    return out;
  }

  clickHandler(years) {
    var range = this.nameToRange('l' + years + 'y');
    var slider = $(this.inRef.current).data("ionRangeSlider");
    slider.update({ from: this.months_h[range.start.format(DateFmt)], to: this.months_h[range.end.format(DateFmt)] })

    this.setState({ years });
    this.props.dateRangeHandler(range);
  }

  /* range: { start: <moment obj> , end: <moment obj> } */
  dateRangeHandler(range) {
    var slider = $(this.inRef.current).data("ionRangeSlider");
    slider.update({ from: this.months_h[range.start.format(DateFmt)], to: this.months_h[range.end.format(DateFmt)] })
    this.props.dateRangeHandler(range);
  }

  render(props) {
    var opts = [
      { name: 'Maximum', years: 99, sm: 'Max' },
      { name: 'Last 10 Years', years: 10, sm: '10 years' },
      { name: 'Last 5 Years', years: 5, sm: '5 years' },
      { name: 'Last 3 Years', years: 3, sm: '3 years' },
      { name: 'Last 1 Year', years: 1, sm: '1 year' }
    ]
    return (
      <div id="date-range-slider-wrap" className="row no-gutters" style={{ marginTop: '9px' }}>
        <div className="col-12 col-md-11 col-lg-12">
          <input ref={this.inRef} {...props} />
        </div>
        <div className="col-12" style={{ marginTop: '3px' }}>
          <ul className="list-inline small mb-0 d-flex justify-content-between">
            {opts.map((v, i) =>
              <li key={i} className="list-inline-item mx-1">
                <button type="button"
                  className={classNames("btn btn-link p-0 lh-100 text-gray-5", { 'text-gray-8': this.state.years === v.years })}
                  onClick={() => this.clickHandler(v.years)}>
                  <small title={v.name}>{v.sm}</small>
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    )
  }
}

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,ReferenceLine} from 'recharts';
import { dailyReturnSeries } from '../../../Utils';
import { MultiSeriesLineChart } from '../../Shared/LineCharts';

class TotalAccountValueChart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {};
  }

  render() {
    const { clientChartDetails: { values }, yearsData, selectedYear } = this.props;
    const assetAllocData = values || [];
    let DARS = dailyReturnSeries({ returns: assetAllocData });
    let selectedSeries = {};
    // console.log('DARS ---> ', DARS);
    if (DARS && DARS.outSeries) {
      selectedSeries = DARS.outSeries;
    }
    // console.log('selectedSeries -->', selectedSeries);
    // console.log('selectedYear -->', selectedYear);
    let __selectedYear = (selectedYear && selectedYear.value) ? selectedYear.value : 'l1y'; // default '1Y'
    let _series = (selectedSeries && selectedSeries[__selectedYear]) ? selectedSeries[__selectedYear] : [];

    _series.forEach((item, i) => {
      if (item.d) {
        item.xAxis = moment(item.d).format('MMM YYYY');
      }
    });

    return (
      <div style={{width: '99%', height: '285px'}}>
        <MultiSeriesLineChart
          ydomain={['auto', 'auto']}
          id={`line-chart-client-portfolio`}
          lines={_series}
          series={_series}
          yearsData={yearsData}
          selectedYear={selectedYear}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ clientportfolioV2: { clientChartDetails } }) => ({
  clientChartDetails
})

export default connect(
  mapStateToProps,
)(TotalAccountValueChart);


const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{label}</p>
        <p className="sub-level">{`Amount : $${nFormatter(payload[0].value,4)}`}</p>
      </div>
    );
  }

  return null;
};

const legendFormatter=(value, entry) => 'Amount';

// const convertAmount = (labelValue) => {
//   // Nine Zeroes for Billions
//   return Math.abs(Number(labelValue)) >= 1.0e+9

//   ? Math.round(Math.abs(Number(labelValue)) / 1.0e+9) + "B"
//   // Six Zeroes for Millions
//   : Math.round(Math.abs(Number(labelValue)) >= 1.0e+6)

//   ? Math.round(Math.abs(Number(labelValue)) / 1.0e+6) + "M"

//   : Math.round(Math.abs(Number(labelValue)));
//   // Three Zeroes for Thousands
//   // : Math.abs(Number(labelValue)) >= 1.0e+3
//   //
//   // ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"
//   //
//   // : Math.abs(Number(labelValue));
// }

function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

const tickFormatter=(value)=>{
   return '$'+nFormatter(value,2);
}

const assetAllocCalc = () => {


}

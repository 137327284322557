import React, { useContext }  from 'react';
import { Row, Col } from 'reactstrap';
import { withHeaderFooter } from './HeaderFooter';
import SimplePieChart from './SimplePieChart'
import { CustomBarChart } from './BarChart'
import PortfolioContext from '../PortfolioContext'

const AssetAllocation = withHeaderFooter((props) => {
  const reportData = useContext(PortfolioContext)
  let { portfolio, portfolio: {currentPerformanceImpact: cpi}} = reportData;

  let portfolioAssetAlloc = newAssetAllocData({equities: cpi.asset_alloc.equities, bonds: cpi.asset_alloc.bonds, others: cpi.asset_alloc.other });

  let BenchMarkValues = {
    bondValue: 0,
    equityValue: 0,
    thematicValue: 0
  }

  let selectedBenchmark = portfolio.selectedBenchmark;

  if (!["SPY", "ACWI", "AGG"].includes(portfolio.selectedBenchmark)) {
    let [equity, bond] = portfolio.selectedBenchmark.match(/\d+/g);
    selectedBenchmark = `${equity}% ACWI/${bond}% AGG`
  }

  if(portfolio.selectedBenchmark === 'SPY'){
    BenchMarkValues.equityValue = 100;
  }
  else if(portfolio.selectedBenchmark === 'AGG'){
    BenchMarkValues.bondValue = 100;
  }
  else if(portfolio.selectedBenchmark === 'ACWI'){
    BenchMarkValues.equityValue = 100;
  }
  else if(portfolio.selectedBenchmark && portfolio.selectedBenchmark.match(/\d+/g) && portfolio.selectedBenchmark.match(/\d+/g).length){
    let vals = portfolio.selectedBenchmark.match(/\d+/g);
    BenchMarkValues.equityValue = parseInt(vals[0]);
    BenchMarkValues.bondValue = parseInt(vals[1]);
  }

  let benchmark = assetAllocData({equities: BenchMarkValues.equityValue, bonds: BenchMarkValues.bondValue, others: BenchMarkValues.thematicValue })

  let { up_3yr, down_3yr } = cpi
  let expectedBarData = [
    {
      "name": "Up Market Capture Ratio*",
      "portfolio": up_3yr,
      "portfolioColor": "#56a9e8",
      "benchmark": 100,
      "benchmarkColor": "#1e5f91"
    },
    {
      "name": "Down Market Capture Ratio*",
      "portfolio": down_3yr,
      "portfolioColor": "#56a9e8",
      "benchmark": -100,
      "benchmarkColor": "#1e5f91"
    }
  ];
  let colors = [
    "rgb(29,96,145)",
    "rgb(86,169,232)",
    "rgb(85, 85, 85)"
  ];

  return (
    <React.Fragment>
      <div className="content">
        <div style={{marginBottom:`30px`}} className="pr-v2-port-performance pr-v2-asset-alloc">
          <h4 className="pr-v2-aa-header">Portfolio vs Comparison ETF ({selectedBenchmark})*</h4>
          <Row className="pr-v2-pp-sections" style={{marginTop: '30px'}}>
            <Col xs="12">
              <div className="bg-chart-block">
                <p className="mb-0" style={{fontSize: '1.3rem', color: '#505050'}}>Up and Down Capture</p>
                <div style={{height: '320px', width: '890px'}}>
                  <CustomBarChart
                    data={expectedBarData}
                  />
                </div>
                <div className="pr-v2-chart-legends">
                  <p>Portfolio</p>
                  <p>Comparison ETF ({portfolio.selectedBenchmark})* </p>
                </div>
              </div>
              {/*
                <div>
                  <p>Upcapture and Downcapture shows us whether a given fund has gained more or lost less than a broad market benchmark during periods of market strength and weakness. </p>
                </div>
              */}
            </Col>
          </Row>
        </div>
        <div className="pr-v2-asset-alloc">
          <h4 className="pr-v2-aa-header">Portfolio vs Comparison ETF ({portfolio.selectedBenchmark})* Allocation</h4>
          <Row>
            <Col xs="6">
              <div>
                <div className="pr-v2-clm-alignAll">
                  <h4 className="pr-v2-light-blue">Portfolio</h4>
                  <SimplePieChart data={portfolioAssetAlloc} colors={colors} decimal={1} width={200} height={200}/>
                </div>
              </div>
            </Col>
            <Col xs="6">
              <div className="pr-v2-clm-alignAll">
                <h4 className="pr-v2-dark-blue">Comparison ETF ({portfolio.selectedBenchmark})*</h4>
                <SimplePieChart data={benchmark} colors={colors} decimal={1} width={200} height={200}/>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
});

export default AssetAllocation;
export const newAssetAllocData = (obj) => {
  return [
    {
      name: "Equity",
      value: obj.equities,
      originalValue: obj.equities
    },
    {
      name: "Bonds",
      value: obj.bonds,
      originalValue: obj.bonds
    },
    {
      name: "Others*",
      value: obj.others,
      originalValue: obj.others
    }
  ]
}

export const assetAllocData = (obj) => {
  const otherVal = getOthersVal(obj);
  return [
    {
      name: "Equity",
      value: obj.equities,
      originalValue: obj.equities
    },
    {
      name: "Bonds",
      value: obj.bonds,
      originalValue: obj.equities
    },
    {
      name: "Others*",
      value: otherVal,
      originalValue: otherVal
    }
  ]
}

export const getOthersVal = (obj) => {
  let sum = 0;
  for (let key in obj) {
    if(key != 'equities' && key != 'bonds'){sum += obj[key];}
  }
  return sum;
}

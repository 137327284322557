import React, { Component, Fragment } from 'react';

import NotApplicable from '../../NotApplicable';
import D3Sunburst from './D3Sunburst';
import { RegionColors, SectorColors } from 'data/Colors';



class RenderRegionsChart extends Component {

  burstRefs = [];

  handleBurstClick = (node) => {
    const name = node.data.name;
    this.burstRefs.forEach(burstRef => {
      const searchNode = burstRef.clickableNodes.find(node => node.data.name === name);
      if (searchNode) {
        burstRef.clicked(searchNode);
      }
    });
  };

  render() {
    const { data, i } = this.props;

    if (data.length === 0) return <NotApplicable />;

    const chartData = {
      name: 'Allocations',
      children: data.map(group => ({
        size: 0,
        name: group.n,
        children: group.sub.map(groupItem => ({
          name: groupItem.n,
          size: groupItem.v,
        })),
      })),
    };

    const primaryTextStyle = {
      // top: '39%',
      fontSize: 11,
      fontWeight: 'bold',
      zIndex: '5',
    };

    return (
      <div className="siw-pie-holder print-wrapper">

        <span
          className="siw-pie-title"
          title="Regions"
        >
          Regions
          </span>

        <div className="d3ChartWrapper">
          <div className="primaryTextStyle" style={primaryTextStyle} >
          </div>
          <D3Sunburst
            width={70}
            height={70}
            colors={RegionColors}
            data={chartData}
            ref={el => this.burstRefs[i] = el}
            clickHandler={this.handleBurstClick}
            isAnimationActive={false}
          />
        </div>
      </div>
    );
  }
};

export default RenderRegionsChart;
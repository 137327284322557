import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Space, Input, Menu, Dropdown, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import SearchDropdown from './SearchDropdown';

class ColumnSearchFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    	visible: false
    }
  }

  render() {
    const { title, selector, center, disable, filterName } = this.props;
    return (
			<div className={`col-title-filter ${center ? 'justify-content-center' : ''}`}>
				<span>{title}</span>
				{!disable && <SearchDropdown
          title={title}
          selector={selector}
          filterName={filterName}
        />}
			</div>
    );
  }
}

export default ColumnSearchFilter;

import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { Row, Col, Button, Tooltip } from 'antd'

import { addEventToAnalytics } from 'Utils';

import {
  setAccountsState,
  resetAccountsState,
  loadCPMListRequest,
  deletePortfolioByIdRequest,
} from 'actions/accounts';
import { premiumFeatureAccessRequest } from 'actions/profile';

import { createLoadingSelector } from 'selectors/loading-selector';

class OnBoardScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portfolioType: '',
    };
  }

  componentWillUnmount() {
    const mainContent = document.getElementById('enhancer-content-wrapper');
    if (mainContent) {
      document.body.classList.remove('body-bg-white');
      mainContent.style.background = 'transparent';
      mainContent.style.display = 'unset';
      mainContent.style.alignItems = 'unset';
    }
  }

  demoPortfolio = (data) => {
    const { accounts } = this.props;
    if (accounts.portfolioName && accounts.portfolioName !== '') {
      let obj = {
        portfolio_name: accounts.portfolioName,
        portfolio_id:accounts.portfolioId
      };
      this.routeTo('Demo Models', 'dashboard', { data: '' }, obj);
    }
  }

  routeTo = (selectedPortfolioType, path, payloadRequest, portfolioData) => {
    console.log(selectedPortfolioType);
    console.log(payloadRequest);
    console.log(portfolioData);
    const { history, setAccountsState } = this.props;
    if (setAccountsState && selectedPortfolioType && path) {
      const jObj = {
        portfolioType: selectedPortfolioType,
        portfolioId: portfolioData.portfolio_name,
      }
      if (portfolioData && portfolioData.sponsoredPortfolioName) {
        setAccountsState({
          portfolioName: portfolioData.portfolio_name,
          portfolioId: portfolioData.portfolio_id,
          sponsoredPortfolioName: portfolioData.sponsoredPortfolioName,
          onboardData: {
            selectedPortfolioType,
            selectedPortfolioPath: `${path}`,
          },
        });
        jObj.sponsoredPortfolioName = portfolioData.sponsoredPortfolioName;
      } else {
        setAccountsState({
          portfolioName: portfolioData.portfolio_name,
          portfolioId: portfolioData.portfolio_id,
          onboardData: {
            selectedPortfolioType,
            selectedPortfolioPath: `${path}`,
          },
        });
      }
      if (history) {
        let urlParam = [];
        for (var i in jObj){
          // console.log(jObj[i], encodeURI(jObj[i]), encodeURIComponent(jObj[i]));
          urlParam.push(encodeURIComponent(i) + "=" + encodeURIComponent(jObj[i]));
        }
        const newPath = `/enhancer/${path}/summary?${urlParam.join("&")}`;
        // console.log(newPath);
        history.push(newPath);
      }
    }
  }

  render() {
    const {
      loading,
      portfolioList,
      marketPlaceList,
      setMarketPlace,
      clientList,
      client_exist,
      uploadedClientList,
      showUpdateColumnInPreview,
      demoPortfolioLoader,
      premiumUser,
    } = this.props;

    return (
      <Row className={`enhancer-onboard-screen-wrapper row onBoardScreenWrapper ${loading ? " enhancer-onboard-screen-wrapper-loader-screen-overlay " : ""}`}>
        <div className='col-6' style={{paddingLeft: '0px'}}>
          <div className="page-head-wrapper">
            <h1 className="head-label">Lunch Break</h1>
            <div className="page-description">
              This page is under developement
            </div>
          </div>
          <div className="content_title">
            
          </div>
        </div>
      </Row>
    )
  }
}

const mapStateToProps = ({ accounts, loading, sfm, profile }) => ({
  accounts,
  sfmItems: sfm.items,
  access: profile.access, // 'Access' or 'Denied' or 'Test Drive'
  loading: createLoadingSelector(['GET_SUBSCRIPTION_PLAN', 'LOAD_CPM_LIST'])({ loading }),
})

const mapDispatchToProps = {
  setAccountsState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OnBoardScreen));

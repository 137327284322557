import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { CSVLink } from "react-csv";
import {
  Button,
  ButtonGroup,
  Dropdown,
  FormGroup,
  ModalBody,
  Input,
  ModalFooter,
  Modal,
  Form,
  UncontrolledTooltip,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
 } from 'reactstrap';

import { BrokerNameMap, BrokersList } from 'DataSet';
import { getNest, closeTraditWindow, addEventToAnalytics } from 'Utils';
import { FooterActions } from '../Common';

export class UnitsValue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      view: 'Units'
    };
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  changeView(view) {
    const { funds } = this.props
    this.setState({ view });
    this.props.amtHandler(view);
    if (funds) {
      addEventToAnalytics('ACT Unit Dropdown','ACT Unit Dropdown','ACT_UNIT_DROPDOWN',{ query: funds.query, logId: funds.logId, view },true);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== this.state.view) {
      this.setState({ view: this.props.value });
    }
  }

  render() {
    return (
      <div className="text-center">
        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle caret color="link" className="h6 text-gray-8 p-0 mb-2 lh-100 sfmodal-unit">{this.state.view}</DropdownToggle>
          <DropdownMenu className="rounded-0 h6 py-1" style={{minWidth: '5rem'}}>
            { ['Units', 'Value'].map(v => ( <DropdownItem key={v} disabled={(this.props.showOnlyValue) && v === 'Units'} className="py-1" onClick={() => this.changeView(v)}>{v}</DropdownItem> ))}
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    )
  }
}

export class AccountDropDownValue extends React.Component {
  constructor(props) {
    super(props);
    let defaultIndex;
    let accounts = this.props.accounts;
    if (accounts.length > 0) {
      accounts.forEach(function(element, index){
        if(element.default_account == true){
          defaultIndex = index;
        }
      });
      if(defaultIndex > 0){
        let e = accounts[defaultIndex];
        accounts.splice(defaultIndex, 1);
        accounts.unshift(e);
      }
    }
    this.state = {
      dropdownOpen: false,
      accounts: accounts,
      view: (accounts.length > 0) ? accounts[0].account_number : '',
      isLogInRequired : (accounts.length > 0) ? (accounts[0].is_login_required === 0 ? false : true) : false,
    };
    this.props.accountHandler(this.props.broker, this.state.view);
  }

  componentWillReceiveProps(newProps) {
    const accounts = newProps.accounts;
    if(accounts.length > 0 && accounts[0].is_login_required !== this.props.accounts[0].is_login_required) {
      this.setState({ isLogInRequired : (accounts.length > 0) ? (accounts[0].is_login_required === 0 ? false : true) : false })
    }
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  changeAccount(account_number) {
    const { card, funds } = this.props;
    this.setState({ view: account_number }, () => {
      this.props.accountHandler(this.props.broker, account_number, card);
    });
    if (funds) {
      addEventToAnalytics('ACT Account Change','ACT Account Change','ACT_ACCOUNT_CHANGE',{ query: funds.query, logId: funds.logId },true);
    }
  }

  handleBrokerSelect = (broker, received_code) => {
    if(broker.name == "tdameritrade" && !received_code){
      closeTraditWindow();
      window.location.href = 'https://auth.tdameritrade.com/oauth?client_id=LOCALTD5@AMER.OAUTHAP&response_type=code&redirect_uri=https://test.opensesafi.com/callbacks/tdameritrade/td-auth-code';
    } else if(broker.name !== 'tdameritrade') {
      this.props.setupBroker(broker, { broker: broker.name })
      const messageHandler = (message) => {
        if(['https://www.trade.it',"https://ems.tradingticket.com"].includes(message.origin)) {
          const parsedData = JSON.parse(message.data)
          const token = get(parsedData, 'oAuthVerifier','');
          this.props.completeTradeItSetup({
              token,
              brokerName: broker.name
            })
          // this.props.fetchAccounts();
        }
      }
      window.onmessage = messageHandler
    } else{
      this.props.setupBroker(broker, received_code)
    }
  }

  render() {
    const { broker } = this.props;
    const brokerDetail =  BrokersList.filter(b => b.name === BrokerNameMap(broker.name));
    return (
      <div className="text-center">
        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          {
            !this.state.isLogInRequired
            &&
            <React.Fragment>
              <DropdownToggle caret color="link" className="h6 text-gray-8 p-0 my-2 lh-100">{this.state.view}</DropdownToggle>
              <DropdownMenu className="rounded-0 h6 py-1" style={{minWidth: '5rem'}}>
                { (this.state.accounts.length > 0) ? this.state.accounts.map(v => <DropdownItem key={v.account_number} className="py-1" onClick={() => this.changeAccount(v.account_number)}>{v.account_number}</DropdownItem> ) : ''}
              </DropdownMenu>
            </React.Fragment>
          }
        </ButtonDropdown>
        {
          this.state.isLogInRequired
          &&
          <React.Fragment>
            <a className="relink-broker text-gray-8 btn-link broker-link" href="#" onClick={() => this.handleBrokerSelect(brokerDetail[0])}>Reactivate broker</a>
          </React.Fragment>
        }
      </div>
    )
  }
}

export const RegisterTooltip = ({ target, placement = 'bottom' }) => {
  return (
    <UncontrolledTooltip className="w-sm" placement={placement} target={target}>
      Please register or link broker account to your Magnifi account.
    </UncontrolledTooltip>
  )
}

export const RegisterTooltip2 = ({ target, placement = 'bottom' }) => {
  return (
    <UncontrolledTooltip className="w-sm" placement={placement} target={target}>
      Coming soon
    </UncontrolledTooltip>
  )
}

export class ExecutationType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      view: 'Market'
    };
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  changeView(view) {
    this.setState({ view });
  }

  render() {
    return (
      <div className="text-center">
        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle caret color="link" className="h6 text-gray-8 p-0 mb-2 lh-100 sfmodal-unit">{this.state.view}</DropdownToggle>
          <DropdownMenu className="rounded-0 h6 py-1" style={{minWidth: '5rem'}}>
            { ['Market', 'Limit'].map(v => ( <DropdownItem disabled={v==='Limit'} key={v} className="py-1" onClick={() => this.changeView(v)}>{v}</DropdownItem> ))}
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    )
  }
}

export default {
	UnitsValue,
	AccountDropDownValue,
	RegisterTooltip,
	RegisterTooltip2,
}

import React from 'react';
import { fixedValue } from '../shared/Utils';

const _CorrelationTable = (props) => {
  const { selFunds, correlationData } = props;
  return(
    <table className="correlation-table">
      <thead>
        <tr>
          <th></th>
          {selFunds.map((item, index) => {
            return(
              <th key={index}>
                {item.ticker_short || '-'}
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {selFunds.map((item, index) => {
          let corrData = correlationData
                  .find(data => item.ticker in data);
          corrData = corrData ? corrData[item.ticker] : [];
          return(
            <tr key={index}>
              <td>{item.ticker_short || '-'}</td>
              {selFunds.map((i, j) => {
                const tickerData = corrData && corrData.find(k => k.secondary_ticker === i.ticker_short);
                const formattedValue = (tickerData && tickerData.value) ? fixedValue(tickerData.value) : '-';
                const tickerValue = (index >= j) ? formattedValue : null;
                const cellStyle = getCellColor(tickerValue);
                return(
                  <td
                    key={j}
                    style={{
                      background: cellStyle.background,
                      color: cellStyle.color,
                    }}
                  >
                    {tickerValue}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const CorrelationTable = React.memo(_CorrelationTable);

export default CorrelationTable;

const getSharpeRatio = (item) => {
  const { returns_3y, volatility_3y } = item;
  return returns_3y && volatility_3y ? returns_3y / volatility_3y : '';
}

const getCellColor = (val) => {
  if(val === null) return {};
  let background, color = '#ffffff';
  if(val >= 0 && val <= 0.33){
    background = '#a7d7f9';
  }else if(val >= 0.34 && val <= 0.66){
    background = '#71add8';
  }else if(val >= 0.67 && val <= 1){
    background = '#1d75b2';
  }else if(val < 0){
    background = '#e7f3fc';
    color = 'inherit';
  }else if(val === '-'){
    background = '#e0e0e0';
    color = 'inherit';
  }
  return { background, color };
}
import moment from 'moment';
import _ from 'lodash';
import { isEmpty, uniqBy, reverse, orderBy, findIndex, get } from 'lodash';
import { formatTickerForView, nameToDateRangeForDailyReturns } from 'Utils';
import { RegionChartMapping, SectorChartMapping } from 'ChartData';
import { calcDailyCumulativeReturns } from 'Stats';

export const csvToJSON = (csv) => {
	var lines=csv.split("\n");
	var result = [];
	var existingAccount = [];
	var headers=lines[0].split(",");
	var inc = 1;
	for(var i=1;i<lines.length;i++){
		var obj = {};
		var currentline=lines[i].split(",");
		if(!existingAccount.includes(currentline[1])){
			// Null value check
			if(currentline[0] && currentline[1] && currentline[2] && currentline[3] && currentline[4]){
				existingAccount.push(currentline[1])
				obj.refID = inc;
				obj.name = formatValues(currentline[0]);
				obj.account_number = formatValues(currentline[1]);
				obj.custodian = formatValues(currentline[2]);
				obj.holdings = [{
					ticker: formatValues(currentline[3]),
					shares: parseInt(formatValues(currentline[4])),
          price: currentline[5] && parseFloat(formatValues(currentline[5])),
          lot_date: currentline[6] && formatValues(currentline[6]),
					cost_basis: currentline[7] && parseFloat(formatValues(currentline[7]))
				}]
				inc++;
			}
		}else{
			// Null value check
			if(currentline[0] && currentline[1] && currentline[2] && currentline[3] && currentline[4]){
				for(var j=0;j<result.length;j++){
					if(result[j].account_number == currentline[1]){
						result[j].holdings.push({
							ticker: formatValues(currentline[3]),
							shares: parseInt(formatValues(currentline[4])),
							price: currentline[5] && parseFloat(formatValues(currentline[5])),
              lot_date: currentline[6] && formatValues(currentline[6]),
              cost_basis: currentline[7] && parseFloat(formatValues(currentline[7]))
						})
					}
				}
			}
		}
		if(Object.entries(obj).length) result.push(obj);
	}
	return result; //JSON
}

const formatValues = (val) => {
	return val.replace(/(\r\n|\n|\r)/gm,"");
}

export const checkForDuplicateClients = (uploadedClientList, clientList) => {
  // Use isDuplicate key only for sorting,
  // lets review and remove it later
  let showUpdateColumnInPreview = false;
  let updatedPfList = uploadedClientList.map(i => {
    const checkExists = clientList.find(e => e.name == i.name && e.acc_no == i.account_number)
    if(checkExists){
      i.existingClientID = checkExists.id;
      i.isDuplicate = true;
      i.updateData = true;
      showUpdateColumnInPreview = true;
    }else{
      i.isDuplicate = false;
    }
    return i;
  })
  return {
    uploadedClientList,
    showUpdateColumnInPreview
  };
}

export const scrollBarCheck = (val, margin = false) => {
	let element1 = document.querySelector(val + ' .rdt_TableBody');
	let hasScrollBar = (element1) && element1.scrollHeight > element1.clientHeight;
	if(hasScrollBar){
		let element2 = document.querySelector(val + ' .rdt_TableHeadRow');
		if(element2) margin ? element2.style.marginRight = '20px' : element2.style.paddingRight = '20px';
	}else{
    let element2 = document.querySelector(val + ' .rdt_TableHeadRow');
    if(element2) margin ? element2.style.marginRight = '' : element2.style.paddingRight = '';
  }
}

export const ViewNames = [
  { name: 'Regions',      display: true },
  { name: 'Sectors',      display: true },
  { name: 'Assets',       display: true },
  { name: 'Volume',       display: true },
  { name: 'Top Holdings',     display: true },
  { name: 'Asset Allocation',   display: true },
  { name: 'Themes',  display: true },
  // { name: '% Held',  display: false }, //name changed to dynamic
  { name: 'Diversification',  display: true },
  { name: 'Return Quality',  display: true },
  { name: 'Dividend Yield',  display: true },
]

export const isViewPresentInChartTypes = (view) => {
  return ViewNames.some(item => item.name === view);
}

const isDynamicChart = (view, query_var) => {
  if (!query_var.Static && query_var.chart_type === 'odometer') {
    return true;
  }
  return false;
}

export const getColName = (results, pfTable) => {

  if(!results.query_var.length) return 'Assets';

  let type;

  const staticVal = results.query_var && results.query_var[0] ?
    results.query_var[0].Static : false;

  const viewName = results.query_var && results.query_var[0] ?
    results.query_var[0].view_name : '';

  if (!results[viewName] || Object.keys(results[viewName]).length === 0 || results.query_var.length === 0 && !type){
    type = 'asset_chart';
  }

  if (!staticVal && !isViewPresentInChartTypes(viewName) && isDynamicChart(viewName, results.query_var[0]) && !type){
    type = 'gauge_chart';
  }

  if (staticVal && ((viewName === "Top Holdings") || (viewName === "Asset Allocation") || (viewName === "Asset Allocation")) && !type){
    type = 'pie_chart';
  }

  if (staticVal && viewName === "Regions" && !type){
    type = 'burst_chart';
  }

  if (viewName === "Themes" && !type){
    type = 'carousel';
  }

  if(viewName == 'Sectors' && pfTable){
  	return '% ' + results.query_var[0]['Name of the chart on UI'];
  }

  const { query_var : query_var } = results;

  if(!type) {
    type = query_var && query_var[0] ? query_var[0].chart_type : null;
  }

  if(type == 'odometer'){
    const { view_name, Secondary_view_name } = query_var[0];
    let name = view_name + (Secondary_view_name ? ` (${Secondary_view_name})` : '');
    name = name.replace('ticker', query_var[0].data);
    return name;
  }else if(type == 'burst_chart'){
    const { view_name, node, ontology_node } = query_var[0]
    let centerDisplayText;
    const displayText = RegionChartMapping.filter(e => e.chart_name === node);
    centerDisplayText = displayText.length ? displayText[0].region_name : '';
    return centerDisplayText !== '' ? `% ${centerDisplayText}` : view_name;
  }else if(type == 'pie_chart'){
    const { view_name, Secondary_view_name, node, chart_center_abovetext, chart_center_belowtext } = query_var[0];
    let topTitle = view_name + (Secondary_view_name ? ' ' + Secondary_view_name : '');
    let centerDisplayText, dataVar;
    if (view_name === 'Diversification') {
      centerDisplayText = topTitle;
    }else if (view_name === 'Asset Allocation'){
      // No condition for chart title
    }else if (view_name === 'Top Holdings') {
      if (Object.prototype.toString.call(results.query_var[0].data) === '[object Array]') {
        dataVar = results.query_var[0].data[0];
      }
      else if (typeof results.query_var[0].data === 'string') {
        dataVar = results.query_var[0].data;
      }
      centerDisplayText = chart_center_belowtext.replace('ticker', dataVar);
    }else{
      const displayText = SectorChartMapping.filter(e => e.chart_name === node);
      centerDisplayText = displayText.length ? displayText[0].sector_name : '';
    }
    return centerDisplayText && centerDisplayText !== '' ? `% ${centerDisplayText}` : topTitle;
  }else if(type == 'carousel'){
    const { view_name, Secondary_view_name } = query_var[0];
    let topTitle = view_name + (Secondary_view_name ? ' ' + Secondary_view_name : '');
    return topTitle;
  }else if(type == 'gauge_chart'){
    const { view_name, Secondary_view_name } = query_var[0];
    let name = view_name + (Secondary_view_name ? ` (${Secondary_view_name})` : '');
    name = name.replace('ticker', query_var[0].data);
    return name;
  }else if(type == 'asset_chart'){
    return 'Assets';
  }else{
    return ''
  }

}

export const getColViewName = (name, chartData) => {
	// console.log('getColViewName -->', name, chartData);
    // const { isSectorDataFoundInFunds } = this.props;
    let split_name = name.split(' & ')
    split_name.forEach((item, index) => {
      const currentChartData = chartData.filter(
        e => e.view_name == item
      )[0]
      let toBeDisplayedView
      if (currentChartData) {
        if (item == 'Regions' && currentChartData.data) {
          let displayText = RegionChartMapping.filter(
            e => e.chart_name === currentChartData.node
          )
          if (displayText.length) {
            toBeDisplayedView = '%\u00a0' + displayText[0].region_name
            split_name[index] = toBeDisplayedView
            // return name;
          } else split_name[index] = item
        } else if (item == 'Sectors' && currentChartData.data) {
          let find = SectorChartMapping.filter(
            e => e.chart_name === currentChartData.node
          )
          if (find.length > 0) {
            toBeDisplayedView = '%\u00a0' + find[0].sector_name
            split_name[index] = toBeDisplayedView
          } else split_name[index] = item
        } else if (
          item == 'Top Holdings' &&
          currentChartData.data &&
          (currentChartData.variable.includes('holdings_') ||
            currentChartData.variable.include('neg_holdings_'))
        ) {
          let ticker = ''
          if (Object.prototype.toString.call(currentChartData.data) === '[object Array]') {
            ticker = currentChartData.data[0].toUpperCase()
          } else if (typeof currentChartData.data === 'string') {
            ticker = currentChartData.data.toUpperCase()
          }
          if (ticker.length > 4) ticker = formatTickerForView(ticker)
          toBeDisplayedView = '%\u00a0' + ticker
          split_name[index] = toBeDisplayedView
          // return name;
        } else if(item == 'Highlight' && currentChartData.chart_type.includes('highlight_')){
           const itemType = currentChartData.chart_type.split('_');
           const availableTypes = ['Fees', 'Risk', 'Returns'];
           const type = availableTypes.find(e => e.toLowerCase() == itemType[1].toLowerCase())
           split_name[index] = type ? type : ''
        }else {
           split_name[index] = item
        }
      } else {
        split_name[index] = item
      }
			// for "view_name = performance vs ticker" case
			if (split_name && split_name[index] && split_name[index].includes('ticker')) {
				if (Object.prototype.toString.call(currentChartData.data) === '[object Array]' && currentChartData.data[0]) {
					split_name[index] = split_name[index].replace("ticker", currentChartData.data[0]);
				}
				else if (typeof currentChartData.data === 'string') {
					split_name[index] = split_name[index].replace("ticker", currentChartData.data);
				}
			}
    })

    if (split_name.length > 1) {
      return split_name[0] + ' & ' + split_name[1]
    } else {
      return split_name[0]
    }
  }

export const getModelName = (obj) => {
  if(Object.keys(obj.model_portfolio).length){
    return obj.model_portfolio.portfolio_name;
  }else if(Object.keys(obj.custom_portfolio).length){
    return obj.custom_portfolio.portfolio_name;
  }else{
    return 'Allocation Pending';
  }
}

export const dailyReturnSeries = ({ returns, fund = null, defaultYear = 'l3m' }) => {
  if (!returns || (returns && returns.length === 0) || typeof returns === 'undefined') return;
  let _returns = returns.map((u) => {
    let _obj = {
      ...u,
			d: u.date,
			v: u.value/100,
      m: moment(u.date).format('MM/YYYY'),
    };
    return _obj;
  });

  if (!_returns || (_returns && _returns.length === 0) || typeof _returns === 'undefined') return;
  const defaultSeries = JSON.parse(JSON.stringify(_returns.reverse()));

	// console.log("defaultSeries --> ", defaultSeries);

  let updatedFundObject = fund, tickerStartDate = null; // '28 Aug 2014';

  if (defaultSeries && defaultSeries.length > 0 && typeof defaultSeries[0].d !== 'undefined') {
    let dropdown = {
      l1m: {
        label: '1 Month',
				shortLabel: '1M',
        value: 'l1m',
        disabled: false,
      },
      l3m: {
        label: '3 Months',
				shortLabel: '3M',
        value: 'l3m',
        disabled: false,
      },
      l6m: {
        label: '6 Months',
				shortLabel: '6M',
        value: 'l6m',
        disabled: false,
      },
      l1y: {
        label: '1 Year',
				shortLabel: '1Y',
        value: 'l1y',
        disabled: false,
      },
      l3y: {
        label: '3 Years',
				shortLabel: '1Y',
        value: 'l3y',
        disabled: false,
      },
			l5y: {
				label: '5 Years',
				shortLabel: '1Y',
				value: 'l5y',
				disabled: false,
			},
      max: {
        label: 'Max',
				shortLabel: 'Max',
        value: 'max',
        disabled: false,
      }
    };
    let outSeries = {};

    const currentDate = moment(new Date()).format('DD MMM YYYY');
    const commonDate = moment(new Date()).date(); // moment(defaultSeries[0].d).date();

    const start = moment(defaultSeries[defaultSeries.length - 1].d).date(commonDate);
    // console.log('=== start ===> ', start.format('DD MMM YYYY'));
    const end = moment(defaultSeries[0].d);
    // console.log('=== end ===> ', end.format('DD MMM YYYY'));

    outSeries = {
      start: start.format("MMM YYYY"),
      end: end.format("MMM YYYY"),
    };
    let cumlSeries = outSeries;
    const PortfolioDateRangeMoment = { start, end };

    let defaultRange = nameToDateRangeForDailyReturns(defaultYear, PortfolioDateRangeMoment);

		// console.log('defaultRange -->', defaultRange);

    Object.keys(dropdown).forEach((item, i) => {
      if (item) {
        defaultRange = nameToDateRangeForDailyReturns(item, PortfolioDateRangeMoment);
        let stats = calcDailyCumulativeReturns(defaultSeries, defaultRange, tickerStartDate);
        if (stats && stats.series) {
          const endDate = stats.series[stats.series.length - 1].d;
          cumlSeries = {
            ...cumlSeries,
            [`${item}`]: stats.series,
          }
          outSeries = {
            ...outSeries,
            [`${item}`]: stats.series.filter((k) => k.d !== 'Start'),
          };
        }
      }
    });

    let out = {
      outSeries,
      dropdown,
      cumlSeries,
    };

    if (outSeries &&
      Object.keys(outSeries).length > 0 &&
      (outSeries.l1y.length > 0) &&
      typeof outSeries.l1y[0].d !== 'undefined'
    ) {
      // check if current Month has 0 value... for 1m, 3m, 6m, 1 year, 3 years and 5 years.

      if (outSeries.l1m && outSeries.l1m[outSeries.l1m.length - 1].d === currentDate && outSeries.l1m[outSeries.l1m.length - 1].v === 0) {
        // console.log(`for 1 month Returns current ${currentDate} Month has 0 value`);
        const findIndex = _.findIndex(outSeries.l1m, ['d', currentDate]);
        if (typeof findIndex !== 'undefined') {
          outSeries.l1m = outSeries.l1m.splice(0, findIndex);
        }
      }

      if (outSeries.l3m && outSeries.l3m[outSeries.l3m.length - 1].d === currentDate && outSeries.l3m[outSeries.l3m.length - 1].v === 0) {
        // console.log(`for 3 months Returns current ${currentDate} Month has 0 value`);
        const findIndex = _.findIndex(outSeries.l3m, ['d', currentDate]);
        if (typeof findIndex !== 'undefined') {
          outSeries.l3m = outSeries.l3m.splice(0, findIndex);
        }
      }

      if (outSeries.l6m && outSeries.l6m[outSeries.l6m.length - 1].d === currentDate && outSeries.l6m[outSeries.l6m.length - 1].v === 0) {
        // console.log(`for 6 months Returns current ${currentDate} Month has 0 value`);
        const findIndex = _.findIndex(outSeries.l6m, ['d', currentDate]);
        if (typeof findIndex !== 'undefined') {
          outSeries.l6m = outSeries.l6m.splice(0, findIndex);
        }
      }

      if (outSeries.l1y && outSeries.l1y[outSeries.l1y.length - 1].d === currentDate && outSeries.l1y[outSeries.l1y.length - 1].v === 0) {
        // console.log(`for 1 year Returns current ${currentDate} Month has 0 value`);
        const findIndex = _.findIndex(outSeries.l1y, ['d', currentDate]);
        if (typeof findIndex !== 'undefined') {
          outSeries.l1y = outSeries.l1y.splice(0, findIndex);
        }
      }

      if (outSeries.l3y && outSeries.l3y[outSeries.l3y.length - 1].d === currentDate && outSeries.l3y[outSeries.l3y.length - 1].v === 0) {
        // console.log(`for 3 years Returns current ${currentDate} Month has 0 value`);
        const findIndex = _.findIndex(outSeries.l3y, ['d', currentDate]);
        if (typeof findIndex !== 'undefined') {
          outSeries.l3y = outSeries.l3y.splice(0, findIndex);
        }
      }

      if (outSeries.l5y && outSeries.l5y[outSeries.l5y.length - 1].d === currentDate && outSeries.l5y[outSeries.l5y.length - 1].v === 0) {
        // console.log(`for 5 years Returns current ${currentDate} Month has 0 value`);
        const findIndex = _.findIndex(outSeries.l5y, ['d', currentDate]);
        if (typeof findIndex !== 'undefined') {
          outSeries.l5y = outSeries.l5y.splice(0, findIndex);
        }
      }

      const ReturnActive1Month = outSeries.l1m && outSeries.l1m.filter((u) => u.v !== 0) || [];
      const ReturnActive3Months = outSeries.l3m && outSeries.l3m.filter((u) => u.v !== 0) || [];
      const ReturnActive6Months = outSeries.l6m && outSeries.l6m.filter((u) => u.v !== 0) || [];
      const ReturnActive1Year = outSeries.l1y && outSeries.l1y.filter((u) => u.v !== 0) || [];
      const ReturnActive3Years = outSeries.l3y && outSeries.l3y.filter((u) => u.v !== 0) || [];
      const ReturnActive5years = outSeries.l5y && outSeries.l5y.filter((u) => u.v !== 0) || [];
      let maxStats = { series: [] };
      if (ReturnActive1Month.length > 0) {
        const yearCouldBe = `${ReturnActive1Month[0].d} - ${ReturnActive1Month[ReturnActive1Month.length - 1].d}`; // ReturnActive1Month.length === 12 ? '1 year' : `${ReturnActive1Month[0].d} - ${ReturnActive1Month[ReturnActive1Month.length - 1].d}`;
        if(ReturnActive1Month.length < 22){
          out.dropdown.l1m = {
            ...out.dropdown.l1m,
            year: yearCouldBe,
            disabled: true,
          };
        } else {
          out.dropdown.l1m = {
            ...out.dropdown.l1m,
            year: yearCouldBe,
            disabled: false,
          };
        }
        out.dropdown.l3m.disabled = true;
        out.dropdown.l6m.disabled = true;
        out.dropdown.l1y.disabled = true;
        out.dropdown.l3y.disabled = true;
        out.dropdown.l5y.disabled = true;
        outSeries.l1m = ReturnActive1Month;
        // maxStats.series = cumlSeries.l1m;
      }

      let s3months = ReturnActive3Months.map((k) => k.d && moment(k.d).format('MMM YYYY')).filter((k) => typeof k !== 'undefined');
      s3months = uniqBy(s3months);
      if (s3months.length > 2) {
        // const yearCouldBe = s3months.length === 4 ? '3 months' : `${ReturnActive3Months[0].d} - ${ReturnActive3Months[ReturnActive3Months.length - 1].d}`;
        // if (s3months.length > 2) {
        //   out.dropdown.l3m = {
        //     ...out.dropdown.l3m,
        //     disabled: false,
        //   };
        // }
        if(s3months.length <= 3){
          out.dropdown.l3m = {
            ...out.dropdown.l3m,
            disabled: true,
            year: `${ReturnActive3Months[0].d} - ${ReturnActive3Months[ReturnActive3Months.length - 1].d}`,
          };
        } else {
          out.dropdown.l3m = {
            ...out.dropdown.l3m,
            disabled: false,
            year: `${ReturnActive3Months[0].d} - ${ReturnActive3Months[ReturnActive3Months.length - 1].d}`,
          };
        }
        out.dropdown.l6m.disabled = true;
        out.dropdown.l1y.disabled = true;
        out.dropdown.l3y.disabled = true;
        out.dropdown.l5y.disabled = true;
        outSeries.l3m = ReturnActive3Months;
        // maxStats.series = cumlSeries.l3m;
      }

      let s6months = ReturnActive6Months.map((k) => k.d && moment(k.d).format('MMM YYYY')).filter((k) => typeof k !== 'undefined');
      s6months = uniqBy(s6months);
      if (s6months.length > 4) {
        // const yearCouldBe = s6months.length === 7 ? '6 months' : `${ReturnActive6Months[0].d} - ${ReturnActive6Months[ReturnActive6Months.length - 1].d}`;
        // if (s6months.length > 4 && s6months.length <= 7) {
        //   out.dropdown.l6m = {
        //     ...out.dropdown.l6m,
        //     disabled: false,
        //   };
        // }
        if(s6months.length <= 6){
          out.dropdown.l6m = {
            ...out.dropdown.l6m,
            disabled: true,
            year: `${ReturnActive6Months[0].d} - ${ReturnActive6Months[ReturnActive6Months.length - 1].d}`,
          };
        } else {
          out.dropdown.l6m = {
            ...out.dropdown.l6m,
            disabled: false,
            year: `${ReturnActive6Months[0].d} - ${ReturnActive6Months[ReturnActive6Months.length - 1].d}`,
          };
        }
        out.dropdown.l1y.disabled = true;
        out.dropdown.l3y.disabled = true;
        out.dropdown.l5y.disabled = true;
        outSeries.l6m = ReturnActive6Months;
        // maxStats.series = cumlSeries.l6m;
      }

      let s12months = ReturnActive1Year.map((k) => k.d && moment(k.d).format('MMM YYYY')).filter((k) => typeof k !== 'undefined');
      s12months = uniqBy(s12months);
      if (s12months.length > 7) {
        // const yearCouldBe = s12months.length === 12 ? '1 year' : `${ReturnActive1Year[0].d} - ${ReturnActive1Year[ReturnActive1Year.length - 1].d}`;
        if (s12months.length <= 12) {
          out.dropdown.l1y = {
            ...out.dropdown.l1y,
            disabled: true, // disbale 1 year
            year: `${ReturnActive1Year[0].d} - ${ReturnActive1Year[ReturnActive1Year.length - 1].d}`,
          };
        } else {
          out.dropdown.l1y = {
            ...out.dropdown.l1y,
            disabled: false,
            year: `${ReturnActive1Year[0].d} - ${ReturnActive1Year[ReturnActive1Year.length - 1].d}`,
          };
        }

        out.dropdown.l3y.disabled = true;
        // out.dropdown.l5y.disabled = true;
        outSeries.l1y = ReturnActive1Year;
        // maxStats.series = cumlSeries.l1y;
      }

      let s36months = ReturnActive3Years.map((k) => k.d && moment(k.d).format('MMM YYYY')).filter((k) => typeof k !== 'undefined');
      s36months = uniqBy(s36months);

      if (s36months.length > 13) {
        // const yearCouldBe = s36months.length === 36 ? '3 years' : `${ReturnActive3Years[0].d} - ${ReturnActive3Years[ReturnActive3Years.length - 1].d}`;
        if (s36months.length <= 36) {
          out.dropdown.l3y = {
            ...out.dropdown.l3y,
            disabled: true, // disbale 3 year
            year: `${ReturnActive3Years[0].d} - ${ReturnActive3Years[ReturnActive3Years.length - 1].d}`,
          };
        } else {
          out.dropdown.l3y = {
            ...out.dropdown.l3y,
            disabled: false,
            year: `${ReturnActive3Years[0].d} - ${ReturnActive3Years[ReturnActive3Years.length - 1].d}`,
          };
        }
        out.dropdown.l5y.disabled = true;
        outSeries.l3y = ReturnActive3Years;
        // maxStats.series = cumlSeries.l3y;
      }

      if (ReturnActive5years.length > 1095) {
        const yearCouldBe = ReturnActive5years.length === 1825 ? '5 years' : `${ReturnActive5years[0].d} - ${ReturnActive5years[ReturnActive5years.length - 1].d}`;
        if (ReturnActive5years.length > 1095 && ReturnActive5years.length <= 1825) {
          out.dropdown.l5y = {
            ...out.dropdown.l5y,
            disabled: false,
          };
        }
        out.dropdown.l5y = {
          ...out.dropdown.l5y,
          year: `${ReturnActive5years[0].d} - ${ReturnActive5years[ReturnActive5years.length - 1].d}`,
        };
        outSeries.l5y = ReturnActive5years;
        out.returnObj = outSeries;
      }

      if (out.cumlSeries && out.cumlSeries.max && out.cumlSeries.max.length > 0 && out.outSeries && out.outSeries.max && out.outSeries.max.length > 0) {
        let _activeDropdown = {}, _inactiveDropdown = {};
        Object.keys(out.dropdown).map((l) => {
          if (!out.dropdown[l].disabled) {
            _activeDropdown[l] = out.dropdown[l];
          }
          if (out.dropdown[l].disabled) {
            _inactiveDropdown[l] = out.dropdown[l];
          }
        });
        if(out.dropdown && out.dropdown.l3y && out.dropdown.l3y.disabled === true){
          out.dropdown.max['disabled'] = false;
          out.dropdown.max['year'] = `${out.outSeries.max[0].d} - ${out.outSeries.max[out.outSeries.max.length - 1].d}`
        } else {
          out.dropdown.max['disabled'] = true;
        }

        // for Option: 3
        // out.dropdown = {
        //   ..._activeDropdown,
        //   max: {
        //     disabled: false,
        //     label: 'Max',
        //     value: 'max',
        //     year: `${maxStats.series[0].d} - ${maxStats.series[maxStats.series.length - 1].d}`,
        //   },
        //   ..._inactiveDropdown,
        // }

        out.cumlSeries.max = out.cumlSeries.max;
        out.outSeries = {
          ...out.outSeries,
          max: out.outSeries.max,
        };
      }

      console.log('========= out ==========');
      console.log(out);
      console.log('========================');
      return out;
    }

		console.log(out);
    return out;
  } else {
    console.log('-- dailyReturnSeries -> return series empty --');
    return null;
  }
}


export const getLineHeight = (element) => {
  const lineHeight = window.getComputedStyle(element)['line-height'];
  if (lineHeight === 'normal') {
    return 1.16 * parseFloat(window.getComputedStyle(element)['font-size']);
  } else {
    return parseFloat(lineHeight);
  }
}

export const boldString = (str, find) => {
  //const re = new RegExp(find, 'ig');
  const st = str.toLowerCase().indexOf(find.toLowerCase());
  const startIndex = st;
  const endIndex = startIndex + find.length
  const boldText = str.slice(startIndex, endIndex)
  return str.replace(boldText, '<b>' + boldText + '</b>');
}
// export const boldString = (str, find) => {
//   const re = new RegExp(find, 'i')
//   return str.replace(re, '<b>' + find + '</b>')
// }

export const getSTKData = (dataArr) => {
  if (dataArr) {
    const valueSTKObject = {
      text: {
        position: null,
        value: null
      },
      company: {
        position: null,
        value: null
      },
      entity: {
        position: null,
        value: null
      },
      startend: {
        startposition: null,
        endposition: null,
        value: null
      }
    };
    if (dataArr.includes('text') && dataArr.includes('company')) {
      const { text, company, entity } = valueSTKObject;
      if (dataArr[dataArr.indexOf('text')+1] !== '' && typeof dataArr[dataArr.indexOf('text')+1] !== 'undefined') {
        valueSTKObject.text = {
          ...dataArr[dataArr.indexOf('text')+1],
          position: dataArr.indexOf('text'),
        }
      }

      valueSTKObject.company = {
        ...dataArr[dataArr.indexOf('company')+1],
        position: dataArr.indexOf('company'),
      }
      // check if text & company position doesnt clash
      // or text next position's element could not be company
      if (valueSTKObject.text.position !== null &&
        valueSTKObject.text.position > 0 &&
        ((valueSTKObject.text.position + 1) !== valueSTKObject.company.position)) {
        if (dataArr.includes('entity')) {
          valueSTKObject.entity = {
            ...entity,
            position: dataArr.indexOf('entity'),
          }
          if (valueSTKObject.text.position !== null &&
            valueSTKObject.text.position > 0 &&
            ((valueSTKObject.text.position + 1) === valueSTKObject.entity.position)) return;

          // find entity value
          const entityValuePos = valueSTKObject.entity.position + 1;
          valueSTKObject.entity.value = dataArr[entityValuePos];
        }
        // find text value
        const textValuePos = valueSTKObject.text.position + 1;
        valueSTKObject.text.value = dataArr[textValuePos];

        // find the less description text
        // valueSTKObject.startend.startposition = parseInt(dataArr[dataArr.indexOf('start') + 1]);
        // valueSTKObject.startend.endposition = parseInt(dataArr[dataArr.indexOf('end') + 1]);

        valueSTKObject.startend.startposition = parseInt(dataArr[dataArr.indexOf('start')+ 1]) == 0 ? parseInt(dataArr[dataArr.indexOf('start')+ 1]) : parseInt(dataArr[dataArr.indexOf('start')+ 1]) -1;
        valueSTKObject.startend.endposition = parseInt(dataArr[dataArr.indexOf('end')+ 1]) == 0 ? parseInt(dataArr[dataArr.indexOf('end')+ 1]) : parseInt(dataArr[dataArr.indexOf('end')+ 1]);

        if(valueSTKObject.startend.endposition > valueSTKObject.startend.startposition) {
          valueSTKObject.startend.value = dataArr[textValuePos].slice(valueSTKObject.startend.startposition,valueSTKObject.startend.endposition).trim();
        }
        else
          valueSTKObject.startend.value = '';

        // highlight text using entity value
        if (valueSTKObject.entity.value !== "") {
          const highlightedString = boldString(valueSTKObject.text.value, valueSTKObject.entity.value);
          // valueSTKObject.startend.value = valueSTKObject.startend.value.includes(valueSTKObject.entity.value) ? boldString(valueSTKObject.startend.value, valueSTKObject.entity.value) : valueSTKObject.startend.value;
          // valueSTKObject.startend.value = valueSTKObject.startend.value.toLowerCase().includes(valueSTKObject.entity.value.toLowerCase()) ? boldString(valueSTKObject.startend.value, valueSTKObject.entity.value) : valueSTKObject.startend.value;
          valueSTKObject.startend.value = boldString(valueSTKObject.startend.value, valueSTKObject.entity.value);
          valueSTKObject.text.value = highlightedString;
        }

        // find company value
        const companyValuePos = valueSTKObject.company.position + 1;
        valueSTKObject.company.value = dataArr[companyValuePos];
        return valueSTKObject;
      }
    }
  } else {
    // console.log('missing STK data');
  }
}

export const getIndexSearchTextData = (dataArr) => {
  if (dataArr) {
    const IndexSearchTextData = {
      text: {
        position: null,
        value: null
      },
      company: {
        position: null,
        value: null
      },
    };

    if (dataArr.includes('text') && dataArr.includes('company')) {
      const { text, company, entity } = IndexSearchTextData;
      if (dataArr[dataArr.indexOf('text')+1] !== '' && typeof dataArr[dataArr.indexOf('text')+1] !== 'undefined') {
        IndexSearchTextData.text = {
          ...text,
          position: dataArr.indexOf('text'),
        }
      }

      IndexSearchTextData.company = {
        ...company,
        position: dataArr.indexOf('company'),
      }

      if (IndexSearchTextData.text.position !== null &&
        IndexSearchTextData.text.position > 0 &&
        ((IndexSearchTextData.text.position + 1) !== IndexSearchTextData.company.position)) {
        if (dataArr.includes('entity')) {
          if (IndexSearchTextData.text.position !== null &&
            IndexSearchTextData.text.position > 0 ) return;

          // find entity value
          const entityValuePos = IndexSearchTextData.entity.position + 1;
          IndexSearchTextData.entity.value = dataArr[entityValuePos];
        }
        // find text value
        const textValuePos = IndexSearchTextData.text.position + 1;
        IndexSearchTextData.text.value = dataArr[textValuePos];

        // find company value
        const companyValuePos = IndexSearchTextData.company.position + 1;
        IndexSearchTextData.company.value = dataArr[companyValuePos];
        return IndexSearchTextData;
      }
    }
  } else {
    // console.log('missing STK data');
  }
}

export default {
  getLineHeight,
  boldString,
  getSTKData,
  getIndexSearchTextData,
};

import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { reverse } from 'lodash'
import _ from 'lodash';

import { setSFMState, setSFMCatData } from 'actions/sfm'

import Slider from 'react-slick'
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  ModalFooter,
  Table
} from 'reactstrap'
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import PerfectScrollbar from 'perfect-scrollbar'
import LinesEllipsis from 'react-lines-ellipsis'

import { Typography } from 'antd'

import {
  cn,
  CN,
  sponsorLogo,
  Api,
  ApiHeaders,
  SectorsAttrs,
  DateRange
} from '../../DataSet'
import { RegionColors, SectorColors } from '../../data/Colors'
import {
  numFmt,
  getNest,
  uniqValues,
  arrayToHash,
  getNestDefault,
  numDc,
  sortFn,
  getImageOrFallback
} from '../../Utils'
import { FooterActions, TruncatedNameSponsor, TruncatedText } from './Common'
import { SimpleLineChart } from '../Charts'
import { DonutChartS1, HBarChartS1 } from '../../components/charts/Recharts'
import { calcStats } from '../../Stats'
import getFilteredListByRange from './utils'
import {
  fetchSponsor,
  fetchTopTenHoldings,
  fetchFundCommissions,
  fetchRealTimePrices
} from 'apis/funds'
import Relevance from '../FundRevelance'
import { getLineHeight } from '../../../src/layouts/WebDashboard/SearchResults/ItemCard/utils';
import { addEventToAnalytics } from 'Utils';

var classNames = require('classnames')
const { Paragraph } = Typography;

class Funds extends React.Component {
  buyHandler = () => {
    this.props.buyHandler('all')
  }

  addSponsoredFundsToSFM = funds => {
    const {
      setSFMState,
      items,
      sfmDateRange,
      sfmCatData,
      setSFMCatData
    } = this.props
    setSFMState({
      sponsoredFunds: funds
    })
    // if (typeof sfmCatData !== undefined) {
    //   setSFMCatData(sfmCatData);
    // }
  }

  render() {
    const { sfm } = this.props
    const { sfmCatData } = sfm
    return (
      <div>
        <div className="modal-body-ssf">
          <ItemList
            {...this.props}
            sfmCatData={sfmCatData}
            addSponsoredFundsToSFM={this.addSponsoredFundsToSFM}
          />
        </div>
        <ModalFooter className="py-2 media-none sfm-modal-footer">
          <Footer {...this.props} buyHandler={this.buyHandler} />
        </ModalFooter>
      </div>
    )
  }
}

const mapStateToProps = ({ sfm }) => ({
  sfm
})

const mapDispatchToProps = {
  setSFMState,
  setSFMCatData
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Funds)

function SampleNextArrow(props) {
  const { className, style, onClick, classname } = props
  return (
    <i
      className={classNames(className, classname)}
      style={{ ...style }}
      onClick={onClick}
    />
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick, classname } = props
  return (
    <i
      className={classNames(className, classname)}
      style={{ ...style }}
      onClick={onClick}
    />
  )
}

class ItemList extends React.Component {
  constructor(props) {
    super(props)
    console.log(this.props)
    this.state = {
      selCard: this.props.items[0],
      theList: []
    }
  }

  // addSeriesDataToCard() {
  //   this.props.items.forEach(element => {
  //     if (!element.series) {
  //       const valTraslate = v => Math.log10((v + 100) / 100)
  //       let stats = calcStats(element.returns)
  //       let dd = element.returns.map(e => e.d)
  //       element.series = dd.map(d => {
  //         let ival = getNest([d, 'cuml'], stats.series)
  //         return { x: d, y1: ival, y3: ival }
  //       })
  //     }
  //     if (!element._stats) {
  //       // pass date-range param to get filter item list, it will update this.props.item
  //       const sfmDateRange = {
  //         // or else check this.props.sfmDateRange and use ?
  //         start: element._start,
  //         end: element._end
  //       }
  //       console.log(sfmDateRange)
  //       if (this.props.items.length && sfmDateRange && this.props.sfmCatData) {
  //         getFilteredListByRange({
  //           items: this.props.items,
  //           range: sfmDateRange,
  //           catData: this.props.sfmCatData
  //         })
  //       } else {
  //         const { items, sfm } = this.props
  //         const { sfmCatData } = sfm
  //         getFilteredListByRange({
  //           items,
  //           range: sfmDateRange,
  //           catData: sfmCatData
  //         })
  //       }
  //     }
  //   })
  // }

  selCardHandler = item => {
    if (this.state.selCard !== item) this.setState({ selCard: item })
  }

  componentDidUpdate(prevProps) {
    if (!this.props.items.find(e => e === this.state.selCard))
      this.selCardHandler(this.props.items[0])
    // console.log(this.props.sfmDateRange);
    // this.addSeriesDataToCard()

    const elems = document.querySelectorAll('.custom-scroll')
    if (elems.length) {
      ;[].forEach.call(elems, function (el) {
        if (el.scrollHeight > el.clientHeight) el.style.paddingRight = '.25rem'
        else el.style.paddingRight = '.25rem'
      })
    }
  }

  // addSponsoredFundsToSFM = funds => {
  //   const { addSponsoredFundsToSFM } = this.props
  //   addSponsoredFundsToSFM(funds)
  // }

  render() {
    const { items, toggleFunDetails, showFunDetails, FunDetailTab } = this.props
    const { selCard, theList } = this.state
    const settings = {
      dots: false,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      vertical: true,
      height: 325,
      verticalSwiping: true,
      prevArrow: <SamplePrevArrow classname="fas fa-caret-up" />,
      nextArrow: <SampleNextArrow classname="fas fa-sort-down" />,
      beforeChange: function (currentSlide, nextSlide) {
        console.log('before change', currentSlide, nextSlide)
      },
      afterChange: function (currentSlide) {
        console.log('after change', currentSlide)
      }
    }

    // console.log(_.sortBy(items, (v) => v._showSponsoredOnSFM === true).map((c) => c.ticker));
    // console.log(items.sort(function(a,b){return a._showSponsoredOnSFM-b._showSponsoredOnSFM}).reverse());
    let selectorFunds = items.filter(v => v._selector === true);
    let DataList = selectorFunds.length > 0 ? selectorFunds : items;

    if (items.find((y) => y.is_sponsored)) {
      const fundsThatisSponsored = selectorFunds.length > 0 ? selectorFunds.filter((s) => s.is_sponsored) : items.filter((s) => s.is_sponsored);
      const fundsThatisNotSponsored = selectorFunds.length > 0 ? selectorFunds.filter((s) => !s.is_sponsored) : items.filter((s) => !s.is_sponsored);
      DataList = [...fundsThatisNotSponsored];
      if (fundsThatisSponsored.length) {
        fundsThatisSponsored.forEach((f, i) => {
          // if (f.ticker && DataList.find((s) => s.ticker !== f.ticker)) {
          //   DataList.unshift(f);
          // }
          DataList.unshift(f);
        });
      }
      // items.find((y) => y._showSponsoredOnSFM) ? (
      //   items.sort(function(a,b){return a._showSponsoredOnSFM-b._showSponsoredOnSFM}).reverse()
      // ) :
    }

    return (
      <div className="h-100">
        <Row
          className="no-gutters px-2 media-p-0 media-m-0 h-100"
          style={{ marginRight: '1px' }}
        >
          <Col
            className={`col-md-3 col-sm-12 list-wrap-h100 ${showFunDetails ? 'd-none pt-2 pr-1' : 'p-2'
              } funds-listing-warapper`}
            style={{ overflow: 'hidden' }}
          >
            <h5 className="mb-0 text-center">
              <div className="w-75 pt-2 pb-1 rounded-top media-header mx-md-auto">
                Selected Funds
              </div>
            </h5>
            <div
              className={`${showFunDetails ? '' : 'h-405'
                } custom-scroll media-scroll-wrapper`}
            >
              <div
                className="bg-secondary rounded media-fund-card"
                style={{ paddingTop: '2px' }}
              >
                {DataList.map((e, i) => {
                  return (
                    <div key={cn(e, 'Id')}>
                      <ItemCard
                        {...this.props}
                        card={e}
                        clearHandler={this.props.clearHandler}
                        selected={e === selCard}
                        toggleFunDetails={cardDetails => {
                          this.selCardHandler(cardDetails)
                          toggleFunDetails(cardDetails)
                        }}
                        selCardHandler={this.selCardHandler}
                      />
                    </div>
                  )
                })}
                {this.props.loading && <Loader />}
              </div>
            </div>
          </Col>
          <Col
            className={`col-md-9 ${showFunDetails ? 'col-sm-12' : 'media-none'
              }`}
          >
            <ItemInfo
              {...this.props}
              // addSponsoredFundsToSFM={this.addSponsoredFundsToSFM}
              FunDetailTab={FunDetailTab}
              card={selCard}
              selCardHandler={this.selCardHandler}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

class ItemCard extends React.Component {
  state = {
    imageValidPath: false
  }

  componentDidMount() {
    const { card } = this.props
    if (cn(card, 'Sponsor')) {
      getImageOrFallback(sponsorLogo(cn(card, 'Sponsor'))).then(result => {
        if (result) {
          this.setState({ imageValidPath: true })
        } else {
          this.setState({ imageValidPath: false })
        }
      })
    }
  }

  buyHandler = () => {
    this.props.buyHandler(this.props.card)
  }

  unselectCard = item => {
    const { funds } = this.props
    this.props.clearHandler(item)
    if (funds) {
      addEventToAnalytics('Fund Detail Selected -- Delete', 'Fund Detail Selected -- Delete', 'FUND_DETAIL_SELECTED -- DELETE', { query: funds.query, logId: funds.logId, ticker: item.ticker, category: 'advisory_PTC' }, true);
    }
  }

  handleCardSelect = card => {
    const { funds } = this.props;
    if (window.innerWidth > 500) this.props.selCardHandler(card)
    if (card._selected) {
      if (funds) {
        addEventToAnalytics('Fund Detail Selected', 'Fund Detail Selected', 'FUND_DETAIL_SELECTED', { query: funds.query, logId: funds.logId, ticker: card.ticker, category: 'advisory_PTC' }, true);
      }
    }
  }

  render() {
    const { imageValidPath } = this.state
    const { card, selected } = this.props

    return (
      <div style={{ padding: '0 2px 2px' }}>
        <Card
          className={classNames('shadow-sm media-px-3', {
            'border-white': !selected,
            'border-ssf-orange-d1': selected
          })}
        >
          <CardBody className="p-0" onClick={() => this.handleCardSelect(card)}>
            <Row className="no-gutters">
              <Col className="col-12 p-1">
                <div
                  className="selected-card bg-ssf-gray-l5 p-1 h-100 d-flex align-items-center"
                  style={{
                    borderLeft: 'none',
                    justifyContent: 'space-between'
                  }}
                >
                  {card._showSponsoredOnSFM && <div className="sponsored-logo-tag">SPONSORED</div>}
                  <div
                    style={{ width: '80%', cursor: 'pointer', marginTop: card._showSponsoredOnSFM ? 15 : 0 }}
                    onClick={() => this.props.toggleFunDetails(card)}
                  >
                    <TruncatedNameSponsor
                      imageValidPath={imageValidPath}
                      name={cn(card, 'Name2')}
                      sponsor={cn(card, 'Sponsor')}
                    />
                  </div>
                  <div className="d-flex flex-column h-100 close-wrapper">
                    <div className="close-icon-box pl-0">
                      <i
                        className="fal fa-times-circle"
                        onClick={() => this.unselectCard(card)}
                      ></i>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    )
  }
}

class ItemInfo extends React.Component {
  viewOpts = [
    { name: 'Key Facts', code: 'facts' },
    { name: 'Top 10 Holdings', code: 'holdings' },
    { name: 'Sectors', code: 'sectors' },
    { name: 'Regions', code: 'regions' }
  ]
  state = {
    dropdownOpen1: false,
    optSel: this.viewOpts[0],
  }

  toggle = name => {
    this.setState({
      [name]: !this.state[name]
    })
  }

  optSelHandler = optSel => {
    const { funds, card } = this.props;
    this.setState({ optSel })
    if (funds) {
      addEventToAnalytics('Fund Detail Selected -- Chart', 'Fund Detail Selected -- Chart', 'FUND_DETAIL_SELECTED -- CHART',
        { query: funds.query, logId: funds.logId, ticker: card.ticker, chart: optSel.name, category: 'advisory_PTC' }, true);
    }
  }

  // update = async () => {
  //   const { card } = this.props
  //   if (card && cn(card, 'Sponsor')) {
  //     getImageOrFallback(sponsorLogo(cn(card, 'Sponsor'))).then(result => {
  //       if (result) {
  //         this.setState({ imageValidPath: true })
  //       } else {
  //         this.setState({ imageValidPath: false })
  //       }
  //     })
  //   }
  //
  //   this.setState({ loading: true })
  //
  //   const responseData = await this.fetchSponsorData(card)
  //   if (responseData) {
  //     let { funds, holdings } = responseData
  //
  //     funds.forEach((element) => {
  //       element.query = window.sessionStorage.getItem('query');
  //       element._hidden = false;
  //       const d1 = moment("01 "+element._start, "DD MMM YYYY"), d2 = moment("01 "+element._end, "DD MMM YYYY");
  //
  //       // for. e.g: dateRangeSelected: 1st May 2019
  //       // whereas fund's start date is 1st Jul 2019 && end date is 1st July 2019
  //       // then check d1.isAfter(moment(dateRangeSelected.end).startOf('month')) and set true
  //       if (d1.isAfter(moment(DateRange.end).startOf('month'))) {
  //         element._hidden = true
  //       }
  //
  //       if (d2.isBefore(moment(DateRange.end).startOf('month'))) {
  //         element._hidden = true
  //       }
  //
  //       if (!element.series) {
  //         const valTraslate = v => Math.log10((v + 100) / 100)
  //         let stats = calcStats(element.returns)
  //         let dd = element.returns.map(e => e.d)
  //         element.series = dd.map(d => {
  //           let ival = getNest([d, 'cuml'], stats.series)
  //           return { x: d, y1: ival, y3: ival }
  //         })
  //       }
  //
  //       if (!element._stats) {
  //         // pass date-range param to get filter item list, it will update this.props.item
  //         // const { items, sfmDateRange, sfmCatData } = this.props;
  //         const { items, sfmCatData } = this.props
  //         const sfmDateRange = {
  //           start: element._start,
  //           end: element._end
  //         }
  //         // console.log(sfmDateRange);
  //         if (items.length && sfmDateRange && sfmCatData) {
  //           getFilteredListByRange({
  //             items,
  //             range: sfmDateRange,
  //             catData: sfmCatData
  //           })
  //         }
  //       }
  //     })
  //
  //     this.setState({
  //       sponsoredFunds: funds.filter(e => !e._hidden)
  //     })
  //
  //     let _sum = 0.0
  //     const holdingData = getNestDefault(['holdings'], holdings, []).map(
  //       (e, i) => {
  //         _sum += e.weight
  //         return {
  //           name: e.name,
  //           value: e.weight,
  //           color: SectorColors[i % SectorColors.length]
  //         }
  //       }
  //     )
  //     if (holdingData.length > 0 && _sum < 100)
  //       holdingData.push({ name: 'Others', value: 100 - _sum, color: '#ddd' })
  //
  //     const regionData = getNestDefault([CN['Region']], card, [])
  //       .reduce((acc, e) => acc.concat(e.sub), [])
  //       .map(e => ({ name: e.n, value: e.v, color: RegionColors[e.n] }))
  //
  //     let sectorData = []
  //     SectorsAttrs.forEach((e, i) => {
  //       let v = cn(card, e)
  //       if (v !== undefined && v > 0.0)
  //         sectorData.push({
  //           name: e,
  //           value: Math.round(cn(card, e)),
  //           color: SectorColors[i % SectorColors.length]
  //         })
  //     })
  //     this.viewOpts.find(e => e.code === 'holdings').hidden =
  //       holdingData.length <= 0
  //     this.viewOpts.find(e => e.code === 'sectors').hidden =
  //       sectorData.length <= 0
  //     this.viewOpts.find(e => e.code === 'regions').hidden =
  //       regionData.length <= 0
  //
  //     this.setState({
  //       loading: false,
  //       regionData,
  //       sectorData,
  //       holdingData,
  //       optSel: this.state.optSel.hidden ? this.viewOpts[0] : this.state.optSel
  //     })
  //     // set sfm reducer's state sponsoredFunds
  //     const { addSponsoredFundsToSFM } = this.props
  //     addSponsoredFundsToSFM(this.state.sponsoredFunds)
  //   }
  // }
  //
  // async componentDidMount() {
  //   // this.update()
  // }
  //
  // componentDidUpdate(prevProps) {
  //   if (
  //     prevProps.card !== this.props.card ||
  //     prevProps.items.length !== this.props.items.length
  //   ) {
  //     // this.update()
  //   }
  //   this.truncString()
  // }
  //
  // async fetchSponsorData(item) {
  //   if (item) {
  //     let funds = [],
  //       holdings = []
  //     const ticker = cn(item, 'Ticker')
  //     if (ticker) {
  //
  //       ;[funds, holdings] = await Promise.all([
  //         fetchSponsor({ ticker: ticker }).catch(() => ({})),
  //         fetchTopTenHoldings({ tickers: ticker })
  //       ])
  //     }
  //
  //     funds = funds.funds || []
  //     if (funds.length > 0) {
  //       let list = this.props.items.map(e => e.id)
  //       funds = funds.filter(e => !list.includes(e.id))
  //
  //       if (funds.length > 0) {
  //         let ticks = uniqValues(funds, 'ticker').join(',')
  //         let [comms, prices] = await Promise.all([
  //           fetchFundCommissions({ tickers: ticks })
  //           // fetchRealTimePrices({ tickers: ticks })
  //         ])
  //
  //         let commData = arrayToHash(comms['funds'], CN['Ticker'])
  //         // let priceData = arrayToHash(prices["funds"], CN['Ticker']);
  //         funds.forEach((e, index) => {
  //           funds[index].returns = reverse(funds[index].returns)
  //           let r = getNest(['returns', 0], e)
  //           if (r) {
  //             e._start = r['d']
  //             e._end = e.returns[e.returns.length - 1]['d']
  //           }
  //           e.returns.forEach((item, index) => {
  //             e.returns[index].v = item.v / 100
  //           })
  //
  //           e.brokers = getNest([cn(e, 'Ticker'), 'brokers'], commData)
  //           e.broker = getNest([0], e.brokers)
  //           // e[CN['Nav Price']] = getNestDefault([cn(e, 'Ticker'), 'price'], priceData, 0.0);
  //         })
  //       }
  //     }
  //
  //     if (holdings.funds)
  //       holdings = holdings.funds.find(e => cn(e, 'Ticker') === ticker)
  //
  //     return { funds, holdings }
  //   }
  // }
  //
  // selectSponsorCard = card => {
  //   const { funds } = this.props;
  //   const { card: selCard } = this.props
  //   const index = this.props.items.findIndex(e => e === selCard)
  //   this.props.selectHandler(card, index + 1)
  //   this.props.selCardHandler(card)
  //   if (funds) {
  //     addEventToAnalytics('Fund Detail Sponsor','Fund Detail Sponsor','FUND_DETAIL_SPONSOR',{ query: funds.query, logId: funds.logId, ticker: card.ticker },true);
  //   }
  // }

  moreToggle = (e) => {
    const removeId = e.currentTarget.dataset.index;
    const element = document.getElementById(removeId);
    const elementText = element.innerHTML;
    const elementFullText = document.querySelector(`[data-value = ${removeId}]`).value;
    const containClass = element.classList.contains("text-ellipse");
    const head = e.currentTarget.dataset.head || '';

    if (containClass) {
      const elems = document.querySelectorAll(".show-less");
      [].forEach.call(elems, function (el) {
        if (el.id !== element) {
          el.classList.add("text-ellipse");
          const elFullText = document.querySelector(`[data-value = ${el.id}]`).value;
          const lessText = document.querySelector(`[data-value = ${el.id}-less]`);
          if (!lessText) el.innerHTML = elFullText;
          else el.innerHTML = lessText.value;
          const truncateTextParts = el.innerHTML.split(' ');
          const lineHeight = getLineHeight(el);
          const lines = (el.id === 'short_desc' || el.id === 'thm_txt') ? 1 : 2;

          while (lines * lineHeight < el.offsetHeight) {
            truncateTextParts.pop();
            el.innerHTML = truncateTextParts.join(' ');
          }
        }
        document.querySelector(`[data-index = ${el.id}]`).style.position = "absolute";
        document.querySelector(`[data-index = ${el.id}]`).innerHTML = "... more";
      });
      element.innerHTML = elementFullText;
      document.getElementById(removeId).classList.remove("text-ellipse");
      document.querySelector(`[data-index = ${removeId}]`).style.position = "relative";
      document.querySelector(`[data-index = ${removeId}]`).innerHTML = "less";
      //this.addMargin();
    } else {
      if (head === '') {
        const truncateTextParts = elementText.split(' ');
        const lineHeight = getLineHeight(element);
        const lines = (removeId === 'short_desc' || removeId === 'thm_txt') ? 1 : 2;

        while (lines * lineHeight < element.offsetHeight) {
          truncateTextParts.pop();
          element.innerHTML = truncateTextParts.join(' ');
        }
      } else {
        const lessText = document.querySelector(`[data-value = ${removeId}-less]`).value;
        element.innerHTML = lessText;
      }
      document.getElementById(removeId).classList.add("text-ellipse");
      document.querySelector(`[data-index = ${removeId}]`).style.position = "absolute";
      document.querySelector(`[data-index = ${removeId}]`).innerHTML = "... more";
    }
  }

  truncString = () => {
    const elems = document.querySelectorAll(".show-less");
    if (elems.length) {
      [].forEach.call(elems, function (el) {
        if (el.classList.contains("text-ellipse")) {
          if (el.id !== '') {
            el.classList.add("text-ellipse");
            if (document.querySelector(`[data-value = ${el.id}]`)) {
              if (!document.querySelector(`[data-value = ${el.id}-less]`)) {
                const elFullText = document.querySelector(`[data-value = ${el.id}]`).value || '';
                el.innerHTML = elFullText;
                const truncateTextParts = el.innerHTML.split(' ');
                const lineHeight = getLineHeight(el);
                const lines = (el.id === 'short_desc' || el.id === 'thm_txt') ? 1 : 1;
                if (lines * lineHeight > el.offsetHeight) document.querySelector(`[data-index = ${el.id}]`).style.display = "block";
                while (lines * lineHeight < el.offsetHeight) {
                  truncateTextParts.pop();
                  el.innerHTML = truncateTextParts.join(' ');
                }
              } else {
                const elFullText = document.querySelector(`[data-value = ${el.id}]`).value;
                const lessText = document.querySelector(`[data-value = ${el.id}-less]`).value;
                if (elFullText !== lessText) {
                  el.innerHTML = lessText;
                } else {
                  el.innerHTML = elFullText;
                  const truncateTextParts = el.innerHTML.split(' ');
                  const lineHeight = getLineHeight(el);
                  const lines = (el.id === 'short_desc' || el.id === 'thm_txt') ? 1 : 2;
                  if (lines * lineHeight > el.offsetHeight) document.querySelector(`[data-index = ${el.id}]`).style.display = "none";
                  while (lines * lineHeight < el.offsetHeight) {
                    truncateTextParts.pop();
                    el.innerHTML = truncateTextParts.join(' ');
                  }
                }
              }
              document.querySelector(`[data-index = ${el.id}]`).style.position = "absolute";
              document.querySelector(`[data-index = ${el.id}]`).innerHTML = "... more";
            }
          }
        }
      });
    }
  }

  render() {
    console.log('ItemInfo')
    const { card } = this.props
    const { dropdownOpen1 } = this.state
    if (!card || !card._categorySeries || !card._categorySeries.series || !card._returnsChart)
      return null

    const cardSeries =
      typeof card._returnsChart !== 'undefined'
        ? typeof card._returnsChart.series !== 'undefined'
          ? card._returnsChart.series
          : card._returnsChart
        : card.series

    let optSel = this.state.optSel;

    if (card._holdingData && card._sectorData && card._regionData) {
      this.viewOpts.find(e => e.code === 'holdings').hidden = card._holdingData.length <= 0
      this.viewOpts.find(e => e.code === 'sectors').hidden = card._sectorData.length <= 0
      this.viewOpts.find(e => e.code === 'regions').hidden = card._regionData.length <= 0

      if (optSel && optSel.code === 'regions' && card._regionData.length <= 0) {
        optSel = this.viewOpts[0];
      } else if (optSel && optSel.code === 'sectors' && card._sectorData.length <= 0) {
        optSel = this.viewOpts[0];
      } else if (optSel && optSel.code === 'holdings' && card._holdingData.length <= 0) {
        optSel = this.viewOpts[0];
      }
    }

    const viewOpts = this.viewOpts.filter(e => !e.hidden)
    const shortDesc = cn(card, 'short_desc');

    let imageValidPath = true;
    if (card && cn(card, 'Sponsor')) {
      getImageOrFallback(sponsorLogo(cn(card, 'Sponsor'))).then(result => {
        if (result) {
          imageValidPath = true;
        } else {
          imageValidPath = false;
        }
      })
    }


    return (
      <Card className={`mt-2 media-card shadow-sm border-secondary d-flex `} style={{ borderWidth: '2px' }}>
        <CardBody className="p-1 d-flex flex-column info-tile" style={{ minHeight: 429 }}>
          <div className="bg-ssf-gray-l5 p-1 position-relative media-bb-1" style={{ marginBottom: 3, minHeight: 94 }}>
            <div className="d-flex ssf-spacer media-flex-column funds-info-title">
              {imageValidPath &&
                cn(card, 'Sponsor') &&
                cn(card, 'Sponsor') ? (
                <img
                  src={sponsorLogo(cn(card, 'Sponsor'))}
                  className="img-fluid h5 mb-0"
                  alt={cn(card, 'Sponsor')}
                  style={{ height: '20px' }}
                />
              ) : (
                <div
                  className="sponsor-logo-placeholder" title={cn(card, 'Sponsor') || ''}>
                  {cn(card, 'Sponsor') || ''}
                </div>
              )}
              <h4 className="color-sfmodal-header mb-0 mx-2 w-100" style={{ lineHeight: 'normal', fontWeight: '600' }}>
                {cn(card, 'Name2')}
              </h4>
              {/* <i className="fal fa-info-circle my-information d-md-none d-sm-block"></i> */}
              {/*<h6 className="text-nowrap text-ssf-orange-d1 mb-0 ml-auto">Fund Home Page</h6>*/}
            </div>
            <div className="ssf-spacer media-none" />
            {/* <h6 className="fs-13 font-one mt-1 mb-0 ml-2 text-muted">
                <TruncatedText text={cn(card, 'short_desc')} lines={3} />
              </h6> */}
            {window.screen.width < 760 ?
              (
                <div className="shw-container btn-white" style={{ paddingLeft: '9px' }}>
                  <span className="fs-13 text-justify mt-2 show-less text-ellipse" id="short_desc" >
                    {cn(card, 'short_desc')}
                  </span>
                  <input type="text" data-value="short_desc" readOnly value={cn(card, 'short_desc')} hidden={true} />
                  <button className="shw-ls-btn fs-13" id="btn-white" type="button" data-index="short_desc" onClick={this.moreToggle} style={{ backgroundColor: '#fff !important' }}>... more</button>
                </div>
              )
              : (
                <h6 className="m-0" title={cn(card, 'short_desc')}>
                  <Paragraph
                    className="fs-13 font-one mt-1 mb-0 ml-2 mr-2 text-muted"
                    ellipsis={{ rows: 3 }}
                    title={shortDesc}
                  >
                    {shortDesc}
                  </Paragraph>
                </h6>
              )}
            <div className="ssf-spacer media-none" />
          </div>
          <div className="bg-ssf-gray-l5 p-0 media-pl-2 media-pr-2 d-flex flex-column" style={{ flex: '1 0 auto', justifyContent: window.screen.width < 760 ? '' : 'space-evenly' }}>
            <h5 className="mb-0 text-center ssf-spacer media-left media-bb-1 text-blue-6i">
              View: &nbsp;
              <ButtonDropdown
                isOpen={dropdownOpen1}
                toggle={() => this.toggle('dropdownOpen1')}
                style={{ verticalAlign: 'inherit' }}
              >
                <DropdownToggle
                  caret
                  color="link"
                  className="h5 text-white-6i my-1 sfmodal-key"
                >
                  {optSel.name}
                </DropdownToggle>
                <DropdownMenu
                  className="rounded-0 h6 py-1"
                  style={{ minWidth: '5rem' }}
                >
                  {viewOpts.map((e, i) => (
                    <DropdownItem
                      key={i}
                      className="py-1"
                      onClick={() => this.optSelHandler(e)}
                    >
                      {e.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
            </h5>
            <div className={`ssf-spacer`} style={{ minHeight: 230 }}>
              {optSel.code === 'facts' && (
                <FactsData
                  {...this.props}
                  chartData={cardSeries}
                  fid={cn(card, 'Ticker')}
                  ydomain={card._returnRange}
                />
              )}
              {optSel.code === 'regions' && (
                <RegionsData {...this.props} data={card._regionData} />
              )}
              {optSel.code === 'sectors' && (
                <RegionsData {...this.props} data={card._sectorData} />
              )}
              {optSel.code === 'holdings' && (
                <RegionsData {...this.props} data={card._holdingData} />
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    )
  }
}

class ItemCard2 extends React.Component {
  state = {
    imageValidPath: false
  }

  componentDidMount() {
    const { card } = this.props
    if (cn(card, 'Sponsor')) {
      getImageOrFallback(sponsorLogo(cn(card, 'Sponsor'))).then(result => {
        if (result) {
          this.setState({ imageValidPath: true })
        } else {
          this.setState({ imageValidPath: false })
        }
      })
    }
  }

  render() {
    const { imageValidPath } = this.state
    const { card } = this.props

    return (
      <div style={{ padding: '0 0 2px 2px' }}>
        <Card className="shadow-sm border-0">
          <CardBody className="p-0">
            <Row className="no-gutters">
              <Col className="col-12 p-1" style={{ height: 77 }}>
                <div
                  className="selected-card bg-ssf-gray-l5 p-1 h-100 d-flex align-items-center"
                  style={{
                    borderLeft: 'none',
                    minHeight: '65px',
                    justifyContent: 'space-between'
                  }}
                >
                  <TruncatedNameSponsor
                    imageValidPath={imageValidPath}
                    name={cn(card, 'Name2')}
                    sponsor={cn(card, 'Sponsor')}
                  />
                  <div className="d-flex flex-column h-100 close-wrapper">
                    <div className="close-icon-box pl-0">
                      <i
                        className="fal fa-plus-circle"
                        onClick={this.props.selectHandler}
                      ></i>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    )
  }
}

const Footer = props => {
  const { funds, items } = props;
  let tickerNames;
  if (items.length > 0) {
    tickerNames = items.map(x => x.ticker);
  }
  return (
    <React.Fragment>
      <FooterActions hideBack={true} clearHandler={props.clearAllHandler} />

      <Button
        style={{ width: '135px' }}
        color="tools"
        className="btn h3 text-uppercase font-weight-bold p-3 mb-0 mt-0 ml-auto secondary-btn"
        onClick={() => {
          props.actionHandler('compare')
          if (funds) {
            addEventToAnalytics('Fund SFM Footer Button', 'Fund SFM Footer Button', 'FUND_SFM_FOOTER_BUTTON', { query: funds.query, logId: funds.logId, tickers: tickerNames, clickBtn: 'compare' }, true);
          }
        }}
      >
        Compare
      </Button>

      <Button
        style={{ width: '135px' }}
        color="select"
        className="btn h3 text-uppercase font-weight-bold p-3 mb-0 ml-3 mt-0 secondary-btn"
        onClick={() => {
          props.buyHandler()
          if (funds) {
            addEventToAnalytics('Fund SFM Footer Button', 'Fund SFM Footer Button', 'FUND_SFM_FOOTER_BUTTON', { query: funds.query, logId: funds.logId, tickers: tickerNames, clickBtn: 'Buy All' }, true);
          }
        }}
      >
        Buy All
      </Button>
    </React.Fragment>
  )
}

class FactsData extends React.Component {
  render() {
    const { card, chartData, ydomain, fid } = this.props
    let share = cn(card, 'sharesout')
      ? numFmt(cn(card, 'sharesout') / (1000 * 1000)) + 'M'
      : '-'
    // console.log(typeof fid, fid, chartData[0], ydomain);
    return (
      <Row className="no-gutters ssf-spacer">
        <div className="col-md-6 col-sm-12 h5 mb-0 pr-2 text-gray-7 font-weight-normal table-responsive">
          <Table
            size="sm"
            className="mb-0"
            style={{ fontSize: 13, overflow: 'hidden' }}
          >
            <tbody>
              <tr>
                <td className="border-0 text-blue-6i">Ticker</td>
                <td className="border-0">{cn(card, 'Ticker Short')}</td>
              </tr>
              <tr>
                <td className="text-blue-6i">Expense Ratio</td>
                <td className="">{numFmt(cn(card, 'Fees'))}%</td>
              </tr>
              <tr>
                <td className="text-blue-6i pr-0" style={{ width: '60%' }}>
                  Assets under Management
                </td>
                <td className="">${numFmt(cn(card, 'Assets'))}</td>
              </tr>
              <tr>
                <td className="text-blue-6i">Shares Outstanding</td>
                <td className="">{share}</td>
              </tr>
              <tr style={{ lineHeight: '1.2' }}>
                <td className="pb-0 text-blue-6i">Benchmark Index</td>
                <td className="pb-0" title={cn(card, 'underlying_index')}>
                  <Paragraph
                    className="font-one m-0"
                    style={{ lineHeight: '1.3' }}
                    ellipsis={{ rows: 4 }}
                    title={cn(card, 'underlying_index')}
                  >
                    {cn(card, 'underlying_index')}
                  </Paragraph>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <Col className="col-md-6 col-sm-12 m-0">
          <h6 className="text-center mb-0 text-blue-6i">
            Growth of Hypothetical $100
            <br />
            since inception ({chartData[0].x})
          </h6>
          <div className="ssf-spacer" />
          <div className="position-relative d-flex align-items-center media-justify-center">
            <SimpleLineChart
              lineData={chartData}
              height={125}
              width={320}
              syncId="dummy"
              fid={fid}
              gSuffix="fd"
            />
          </div>
        </Col>
      </Row>
    )
  }
}

class RegionsData extends React.Component {
  render() {
    const { data } = this.props
    let others = data.find(e => e.name === 'Others')
    const dataSorted = data
      .filter(e => e.name !== 'Others')
      .sort(sortFn('value'))

    return (
      <div className="container-fluid pb-4 mb-4 pl-0">
        <Row className="no-gutters align-items-center ssf-spacer media-flex-column chart-wrapper">
          <div className="col-md-4 col-sm-12 d-flex justify-content-center media-fund-detail-wrapper position-relative m-block">
            <div
              className="w-100 position-relative"
              style={{ width: '135px', height: '135px' }}
            >
              <DonutChartS1
                data={others ? [...dataSorted, others] : dataSorted}
              />
            </div>
          </div>
          <div className="col-md-8 col-sm-12 h6 mb-0 pr-2 text-gray-7 font-weight-normal chart-legend-wrapper">
            <Row>
              <Col className="col-md-6 col-sm-12 chart-legend col-12">
                {dataSorted.slice(0, 5).map((e, i) => (
                  <ChartLegend
                    key={i}
                    name={e.name}
                    value={e.value}
                    color={e.color}
                  />
                ))}
              </Col>
              <Col className="col-md-6 col-sm-12 chart-legend col-12">
                {dataSorted.slice(5, 10).map((e, i) => (
                  <ChartLegend
                    key={5 + i}
                    name={e.name}
                    value={e.value}
                    color={e.color}
                  />
                ))}
              </Col>
            </Row>
          </div>
        </Row>
      </div>
    )
  }
}

const ChartLegend = ({ name, value, color }) => {
  return (
    <Row className="no-gutters border-bottom" style={{ padding: '6px 0' }}>
      <Col
        className="pl-2"
        style={{ borderLeft: `6px solid ${color}`, display: 'inline-grid' }}
      >
        {/* <TruncatedText text={name} lines={1} /> */}
        <p className="d-inline-block text-truncate m-0" title={name}>
          {name}
        </p>
      </Col>
      <Col className="pl-1 col-auto">{value.toFixed(1)}%</Col>
    </Row>
  )
}

class HoldingsData extends React.Component {
  render() {
    const { chartData } = this.props
    const colors = SectorColors

    const data = chartData.map((e, i) => ({
      name: e.name,
      value: numDc(e.weight),
      color: colors[i % colors.length]
    }))

    return (
      <Row className="no-gutters align-items-center ssf-spacer">
        <Col className="col-12 d-flex justify-content-center">
          <div className="w-100" style={{ height: '135px' }}>
            <HBarChartS1 data={data} />
          </div>
        </Col>
      </Row>
    )
  }
}

const Loader = props => {
  return (
    <div className="overlay active">
      <div className="text rounded">
        <i className="fal fa-circle-notch fa-3x fa-spin text-gray-5"></i>
        <h5 className="mt-2 mb-0">Loading fund data</h5>
      </div>
    </div>
  )
}

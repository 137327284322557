import React from 'react';
import  _  from 'lodash';
// import { Redirect } from 'react-router-dom';
// import { connect } from 'react-redux';
// import { UncontrolledTooltip } from 'reactstrap';
import { Tooltip } from 'antd';
import { paraToSentence, addEventToAnalytics, convertToLowerCase, clearWindowUtmSession } from 'Utils';
// import EmbedSection from 'components/EmbedSection';
import RankedTags from 'components/RankedTags';
import { searchUrlV3 } from 'Utils';
// import { ShareFunds } from './ShareFunds';
// import { searchQueryRequest, setFundsState, dymLogs } from 'actions/discovery';
// import { fundsApiCallTime, clearFundsApiLoadPercent } from 'actions/global';
import { PrimaryButton } from 'shared-components/'
// import Overlay from 'components/Overlay'

class TopSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // isEmbedOpen: false,
      // isNotifyOpen: false,
      similarSearchList: [],
      disableSimilarSearchBlock: false,
    };
    // this.secRef = React.createRef();
    this.top = true;
  }

  checkRoutes = (query, clearUTMSession) => {
    const { history } = this.props;
    if (history) {
      if (clearUTMSession) {
        clearWindowUtmSession();
      }
      history.push(searchUrlV3(query));
    }
  }

  queryHandler = (query, search_type, clearUTMSession = false) => {
    if (query) {
      const { searchQueryRequest, clearFundsApiLoadPercent, dymLogs, queryUUID, logId, query: _query } = this.props;
      clearFundsApiLoadPercent();
      const old_query = _query || window.sessionStorage.getItem('query');
      if (old_query) {
        // window.sessionStorage.setItem('query', query);
        // NOTE: Commenting below logic, because added in Saga '*searchQuery'
        // NOTE: This has to be top-level logic for new query
        // --------------------------------------------------------------------------------------------
        // if (_query !== query) {
        // 	setFundsState({
        // 		requestPayloadFilters: {
        // 			filterKeys: [],
        // 			filterValues: [],
        // 		},
        // 		requestPayloadSort: {
        // 			name: '',
        // 			value: '',
        // 		},
        // 		// NOTE: resetting stocks preserve query/universe logic when new query searched
        // 		previousSearch: {
        // 			universe: null,
        // 		},
        // 		showGoBackSection: false,
        // 		isMoreStocksClicked: false,
        // 		showGoBackSectionClicked: false,
        // 	});
        // }
        // --------------------------------------------------------------------------------------------
        // setFundsState({
        //   actions: true,
        // });

        // this.props.history.push('/securities?query='+query);
        if (search_type === 'did_you_mean') {
          dymLogs({
            originalQuery: old_query,
            dymQueryClicked: query,
            queryUUID: queryUUID,
            // logId: logId,
          });
        }
        this.checkRoutes(query, clearUTMSession);
        searchQueryRequest({
          query,
          search_type,
        });
      }
    }
  }

  didMeanDesign = (didYouMean) => {
    const threeDidYouMean = didYouMean.slice(0, 5);
    const renderDesign = threeDidYouMean.map((item, index) => {
      const { discovery } = this.props;
      let renderText = item.split(' ').length > 12 ? item.trim().split(" ").slice(0, 13).join(" ") + "…" : item;
      return (
        <>
          {(threeDidYouMean.length > 2 && item.split(' ').length > 12) ? (
            <Tooltip className="did-you-mean-tooltip-container" overlayClassName="did-you-mean-tooltip-wrapper" placement="bottomLeft" title={item} overlayStyle={{ color: '#fff !important' }}>
              <span
                id={"didyoumean-text-"+index}
                key={item+index}
                className="didyoumean-text"
                onClick={() => {
                  this.queryHandler(item, 'did_you_mean', true);
                  addEventToAnalytics('Did you mean','Did you mean','DID_YOU_MEAN',{ query: discovery.query, logId: discovery.logId, didYouMeanQuery: item },true);
                  }
                }
              >
                {renderText}
              </span>
            </Tooltip> ) : (
            <span
              id={"didyoumean-text-"+index}
              key={item+index}
              className="didyoumean-text"
              onClick={() => {
                this.queryHandler(item, 'did_you_mean', true);
                addEventToAnalytics('Did you mean','Did you mean','DID_YOU_MEAN',{ query: discovery.query, logId: discovery.logId, didYouMeanQuery: item },true);
                }
              }
            >
              {item}
            </span>
          )
        }
      </>
    )});
    return (
      <>
        <span className="did-you-mean-head">Did you mean: </span>
        {renderDesign}
      </>
    );
  }

  showActivateNow = () => {
    const { profile } = this.props;
    if(profile.date_of_birth && profile.investable_assets){
      return false
    }
    return true
  }

  similarSearchDesign = (similarSearchList) => {
    const queriesCollection = similarSearchList; // similarSearchList.map((k) => k.query);
    const threeSimilarSearch = queriesCollection.slice(0, 5);
    const renderDesign = threeSimilarSearch.map((item, index) => {
      // console.log(item);
      const { discovery } = this.props;
      let renderText = item.split(' ').length > 12 ? item.trim().split(" ").slice(0, 13).join(" ") + "…" : item;
      return (
        <>
          {(threeSimilarSearch.length > 2 && item.split(' ').length > 12) ? (
            <Tooltip className="did-you-mean-tooltip-container" overlayClassName="did-you-mean-tooltip-wrapper" placement="bottomLeft" title={item} overlayStyle={{ color: '#fff !important' }}>
              <span
                id={"didyoumean-text-"+index}
                key={item+index}
                className="didyoumean-text"
                onClick={() => {
                  this.queryHandler(item, 'user_similar_search', true);
                  addEventToAnalytics('User Similar Searched','User Similar Searched','USER_SIMILAR_SEARCHED',{ query: discovery.query, logId: discovery.logId, userSimilarSearched: item }, true);
                  }
                }
              >
                {renderText}
              </span>
            </Tooltip> ) : (
            <span
              id={"didyoumean-text-"+index}
              key={item+index}
              className="didyoumean-text"
              title={item}
              onClick={() => {
                this.queryHandler(item, 'user_similar_search', true);
                addEventToAnalytics('User Similar Searched','User Similar Searched','USER_SIMILAR_SEARCHED',{ query: discovery.query, logId: discovery.logId, userSimilarSearched: item }, true);
                }
              }
            >
              {item}
            </span>
          )
        }
      </>
    )});
    return (
      <>
        <span className="user-similar-search-label" style={{ marginRight: 4 }}>Users Similar To You Also Searched:</span>
        {this.showActivateNow() ? <PrimaryButton
            type="button"
            style={{ padding: '6px 20px',
              fontSize: 12,
              fontWeight: 500 }}
            onClick={() => {
              addEventToAnalytics('Recommendor Api Activate Now','Recommendor Api Activate Now','RECOMMENDOR_API_ACTIVATE_NOW');
              this.props.dobModalToggle()
            }}
            className="btn primary-btn bt_activate"
          >
            Activate Now
          </PrimaryButton> : renderDesign}
      </>
    );
  }

  handleRankedOnClick = (sortOption) => {
    const { name, sort } = sortOption
    const { searchQueryRequest, setFundsState, clearFundsApiLoadPercent, query, discovery, chartData } = this.props;
    const { query_var } = discovery;
    let valObj = discovery.view.filter((o) => o.name === name)
    let sortName = (name !== '' && sort !== '') ? name : '';
    let sortValue = (name !== '' && sort !== '') ? sort : '';
    if (valObj && valObj.length > 0) {
      clearFundsApiLoadPercent();
      let search_type = "search_bar"
      sortName = valObj[0].variable + "_rank"
      setFundsState({
        actions: true,
        requestPayloadSort: {
          name: sortName,
          value: sortValue,
        },
      });
      searchQueryRequest({
        query,
        search_type,
      });
    } else {
      const foundView = query_var.find((g) => g['Name of the chart on UI'] === name);
      if (foundView) {
        clearFundsApiLoadPercent();
        let search_type = "search_bar"
        sortName = foundView.variable + "_rank"
        setFundsState({
          actions: true,
          requestPayloadSort: {
            name: sortName,
            value: sortValue,
          },
        });
        searchQueryRequest({
          query,
          search_type,
        });
      }
    }
  }

  activateSimilarSearch = () => {
    // console.log('-- activateSimilarSearch --');
    const { activateSimilarSearchRequest, getSimilarSearchListRequest, discovery } = this.props;
    if (activateSimilarSearchRequest && process.env.REACT_APP_SUB_DOMAIN === 'retail') {
      addEventToAnalytics('Activate Now Button','Activate Now Button','ACTIVATE_NOW_BUTTON',{ query: discovery.query, logId: discovery.logId }, true);
      activateSimilarSearchRequest({
        show_similar_user_queries: true,
      }, (responseData) => {
        // console.log(responseData);
        if (responseData && responseData.status === 'Activated' && getSimilarSearchListRequest) {
          getSimilarSearchListRequest(_, (similarListResp) => {
            if (similarListResp.length) {
              // console.log(' === similarListResp === ', similarListResp.length);
            }
            // if (similarListResp.length) {
            //   this.setState({ disableSimilarSearchBlock: false, similarSearchList: similarListResp });
            //     const child = document.getElementById("sort-filter-wrapper");
            //     if (child) {
            //       child.style.position = 'unset';
            //       child.style.padding = '15px 15px 0 15px';
            //     }
            // } else {
            //   this.setState({ disableSimilarSearchBlock: true, similarSearchList: [] });
            // }
          })
        }
      });
    }
  }

  render() {
    const {
      searchResultCount,
      loading,
      discovery,
      correctedQuery,
      query,
      messages,
      viewArray,
      showUserSimilarSearches,
      activateButtonLoading,
      similarSearchList,
      loggedIn,
      profileLoading,
      SortFilter,
      fundsUniverse,
      showGoBackSection,
      SHOWING_ALL_STOCKS,
    } = this.props;
    // const { disableSimilarSearchBlock, similarSearchList } = this.state;
    const { didYouMean, chartData, sort_value, sort_by } = discovery;
    const { query_var: originalQueryVar } = discovery;

    // console.log('similarSearchList ===> ', similarSearchList);
    const renderDidMean = didYouMean && didYouMean.length ? (
      <div className="element did-you-mean-overflow-wrap custom-scroll" style={{ width:'100%' }}>
        {this.didMeanDesign(didYouMean)}
      </div>
    ) : null;

    let renderMessageText = null;
    if (messages && messages.length > 0) {
      if (paraToSentence(messages[0])) {
        let renderMessageTextArr = paraToSentence(messages[0]) || [];
        renderMessageText = renderMessageTextArr.map((k, i) => {
          // NOTE: Messages count are more than 1, then render first row in 'div' and others in 'span' tag
          if (renderMessageTextArr.length > 1 && i === 0) {
            return (
              <div>{k}</div>
            )
          } else {
            return (
              <span>{k}</span>
            )
          }
        });
        // let _sentenceArr = paraToSentence(messages[0]) || [];
        // console.log(_sentenceArr);
        // if (_sentenceArr && _sentenceArr.length > 0) {
        //   renderMessageTextArray = chunkString( _sentenceArr[_sentenceArr.length - 1], 150 )
        //   console.log('renderMessageTextArray -->', renderMessageTextArray);
        // }
      }
    }

    const queryVarRankingName = _.uniq(_.uniqBy(originalQueryVar, 'variable').map((jk) => {
      if (jk['Name of the chart on UI'] && jk['Name of the chart on UI'] !== '' && jk.view_name !== '' && jk.active) {
        const foundChartDataObj = chartData.find((ol) => (ol.ontology_node === jk.ontology_node && jk.variable === ol.variable));
        if (foundChartDataObj && foundChartDataObj.display_view_name) {
          let fff = {
            name: foundChartDataObj.display_view_name,
            variable: foundChartDataObj.variable,
            preference: foundChartDataObj.preference || '<',
          }
          return fff;
        } else {
          let rankingNamesWillBe = convertToLowerCase(jk['Name of the chart on UI']) || jk['Name of the chart on UI'];
          if (rankingNamesWillBe.includes('ticker')) {
            if (Object.prototype.toString.call(jk.data) === '[object Array]') {
              rankingNamesWillBe = rankingNamesWillBe.replace("ticker", jk.data[0]);
            }
            else if (typeof jk.data === 'string') {
              rankingNamesWillBe = rankingNamesWillBe.replace("ticker", jk.data);
            }
            return _.upperFirst(rankingNamesWillBe) || rankingNamesWillBe;
          }
          let fff = {
            name: jk['Name of the chart on UI'],
            variable: jk['variable'],
            preference: jk['preference'] || '<',
          }
          return fff;
          // return jk['Name of the chart on UI']
        }
      }
    }).filter((l) => l !== undefined));

    // {(((similarSearchList && similarSearchList.length) || !showUserSimilarSearches) && loggedIn && process.env.REACT_APP_SUB_DOMAIN === 'retail' && !profileLoading) && (
    //   <div id="user-similar-search-wrapper" className="user-similar-search-wrapper" style={{ alignItems: messages.length < 1 ? '' : 'flex-end' }}>
    //     {(similarSearchList && similarSearchList.length) ? (
    //         <div className="element user-similar-search-overflow-wrap">
    //           {this.similarSearchDesign(similarSearchList)}
    //         </div>
    //       ) : ((!showUserSimilarSearches) ? (
    //         <>
    //           <span className="user-similar-search-label">Users Similar To You Also Searched:</span>
    //           <span>
    //             <Button
    //               className="user-similar-activate-btn"
    //               loading={activateButtonLoading}
    //               style={{ padding: activateButtonLoading ? '0 20px 0 12px' : '0 20px' }}
    //               onClick={this.activateSimilarSearch}
    //             >
    //               Activate Now
    //             </Button>
    //           </span>
    //         </>
    //       ) : null)}
    //   </div>
    // )}
    const renderSortFilter = queryVarRankingName.length === 0 ? SortFilter : null;

    const StockOnlyMessage = (SHOWING_ALL_STOCKS) ? ( // (SHOWING_ALL_STOCKS && showGoBackSection)
      <span className="show-only-stocks-message-text">Showing Stocks Only</span>
    ) : null;

    return (
      <React.Fragment>
        <div id="search-related-wrapper" className="search-related-wrapper" style={{ alignItems: messages.length < 1 ? '' : 'flex-end' }}>
          { messages.length < 1 ?
            <div id="search-related-search-label" className="search-label">
              <div className="results-count-color">
                <span>
                  Here are the relevant {<span>{`${(searchResultCount > 1) ? 'results' : 'result'}`}</span>} for <span style={{ fontWeight: 'bold' }}>{correctedQuery}</span>
                  {StockOnlyMessage}
                </span>
              </div>
            </div>
            :
            <div className="search-label d-flex-col-align-start">
              <div className="results-count-color">
                {renderMessageText}
                {StockOnlyMessage}
              </div>
            </div>
          }
          <span style={{ paddingTop: 5 }}>{renderSortFilter}</span>
        </div>
        <div id="investment-ranked-wrapper" className="element investment-ranked-wrapper" style={{ alignItems: messages.length < 1 ? '' : 'flex-end' }}>
          {queryVarRankingName.length > 0 && (
            <div className="search-label d-flex-col-align-start" style={{ padding: '5px 0' }}>
              <div id="investment-ranked-box" className="results-count-color d-flex-center ranking-tag-wrapper">
                Investments are ranked on:  {queryVarRankingName.map((op, index) => (
                  <RankedTags
                    {...this.props}
                    className="black-tag"
                    id={op.name+index}
                    tagName={op.name}
                    variable={op.variable}
                    preference={op.preference}
                    onClick={(sortOption) => this.handleRankedOnClick(sortOption)}
                  >
                    {op.name}
                  </RankedTags>
                ))}
                <span>{SortFilter}</span>
              </div>
            </div>
          )}
        </div>
        <div id="did-you-mean">
          {!loading && renderDidMean}
        </div>
        {(similarSearchList && similarSearchList.length > 0 && loggedIn && process.env.REACT_APP_SUB_DOMAIN === 'retail' && !profileLoading) && (
          <div id="user-similar-search-wrapper" className="user-similar-search-wrapper" style={{ alignItems: messages.length < 1 ? '' : 'flex-end' }}>
            <div className="element user-similar-search-overflow-wrap">
              {this.similarSearchDesign(similarSearchList)}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default TopSection;

// const mapStateToProps = () => ({
//   // discovery,
//   // query: discovery.query,
//   // logId: discovery.logId,
//   // viewArray: discovery.view,
//   // chartData: discovery.chartData,
//   // requestPayloadSort: discovery.requestPayloadSort,
// })
//
// const mapDispatchToProps = {
//   // searchQueryRequest,
//   // setFundsState,
//   // dymLogs,
//   // fundsApiCallTime,
//   // clearFundsApiLoadPercent,
// }
//
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(TopSection)

import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Row, Col, Tooltip, UncontrolledTooltip, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap';
import NamePopover from './NamePopover';
// import ResponsiveCardPopover from './ResponsiveCardPopover';
import { cn } from 'DataSet';
import { addEventToAnalytics, renderMappedVehicle, searchUrlV3, clearWindowUtmSession, sponsorPdfUrl } from 'Utils';
// import { configConsumerProps } from 'antd/lib/config-provider';
import config from 'env';
// import { Menu, Icon } from 'antd';
// import { CaretDownOutlined } from '@ant-design/icons';
// import { RETAIL_USERS_ROLES } from '../../../Constant/FormData';

import {
  Link
} from "react-router-dom";

class CardName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
      selector: false,
      builder: false,
      selectorImg: '/assets/images/icons/shopping-cart-Orange.svg',
      selectorRetailImg: '/assets/images/icons/plus-shopping-cart-blue.svg',
      builderImg: '/assets/images/icons/BuilderIcon-Orange.svg',
      learnMoreDropdown: false
    };
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    document.getElementById('itemListArea').addEventListener('scroll', this.handleListScroll);
  }

  componentWillUnmount() {
    document.getElementById('itemListArea').removeEventListener('scroll', this.handleListScroll);
  }

  handleListScroll = () => {
    if (this.state.learnMoreDropdown) {
      this.setState({ learnMoreDropdown: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.card._selector) {
      this.setState({ selectorRetailImg: '/assets/images/icons/plus-shopping-cart-blue.svg' })
    }
  }

  onClick(e) {
    if (e === 'builder') {
      this.props.selectBuilderHandler(this.props.card, false);
    } else {
      this.props.card._showSponsoredOnSFM = false;
      let selectorState = true;
      if (this.props.sfm.items.length > 0) {
        this.props.sfm.items.forEach(item => {
          if (item._selector) {
            selectorState = false;
          }
        })
      }
      this.props.selectHandler(this.props.card);
      if (this.props.funds && this.props.funds.data && this.props.funds.data.length > 0 && this.props.card) {
        let sponsorFund = this.props.funds.data.filter(e => e.is_sponsored === true);
        sponsorFund.forEach(fund => {
          if (!fund._selector && this.props.card._selector) {
            this.props.selectHandler(fund);
          }
        });
      }
    }
    this.forceUpdate();
  }

  onBuilderClearClick = (e) => {
    this.props.selectBuilderHandler(this.props.card, true);
  }

  handleMouseOver = (e, type) => {
    if (type === 'selector') {
      this.setState({
        selector: true,
        selectorImg: '/assets/images/icons/shopping-cart-White.svg',
        selectorRetailImg: '/assets/images/icons/plus-shopping-cart-white.svg',
      });
    } else {
      this.setState({
        builder: true,
        builderImg: '/assets/images/icons/BuilderIcon-White.svg',
      });
    }
  }

  handleMouseOut = (e, type) => {
    if (type === 'selector') {
      this.setState({
        selector: false,
        selectorImg: '/assets/images/icons/shopping-cart-Orange.svg',
        selectorRetailImg: '/assets/images/icons/plus-shopping-cart-blue.svg',
      });
    } else {
      this.setState({
        builder: false,
        builderImg: '/assets/images/icons/BuilderIcon-Orange.svg'
      });
    }
  }

  buyButtonClick = (data) => {
    // console.log(data);
    // console.log('--  addToCart --', data);
    if (data && data.ticker) { // && !data._selected && !data._selector
      const {
        funds,
        // selectHandler,
        addTickersToCartRequest,
        sfmSelectedItemTickers,
      } = this.props;
      // console.log('== sfmSelectedItemTickers ==> ', sfmSelectedItemTickers);
      // console.log('-- select logic --');
      if (addTickersToCartRequest) {
        let tickers = [...sfmSelectedItemTickers, data.ticker];
        tickers = _.uniqBy(tickers).join();
        // console.log('== tickers to add in SFM ==', tickers);
        addTickersToCartRequest({
          selectedItemTickers: tickers,
          buyFromURL: true,
        });
        if (funds.query) {
          addEventToAnalytics('BUY FUND FROM DISCOVERY', 'BUY FUND FROM DISCOVERY', 'BUY_FUND_FROM_DISCOVERY', { logId: funds.logId, ticker: data.ticker, category: 'advisory_PTC' }, true);
        }
        // if (selectHandler) {
        //   selectHandler(data);
        // }
      }
    }
  }

  checkRoutes = (query, clearUTMSession) => {
    // const { match, history } = this.props;
    // // const query = window.sessionStorage.getItem('query');
    // // if (loggedIn && isTermsAgreed === 0 && query) return;
    // if (!match.params.id || match.params.id == '') {
    // 	if (clearUTMSession) {
    // 		clearWindowUtmSession();
    // 	}
    //   if (history) {
    //     history.push(searchUrlV3(query));
    //   }
    // }
    const { history } = this.props;
    if (history) {
      if (clearUTMSession) {
        clearWindowUtmSession();
      }
      console.log('item card checkRoutes query -- >', query)
      history.push(searchUrlV3(query));
    }
  }

  queryHandler = (query, search_type, clearUTMSession = false) => {
    const { discovery } = this.props;
    // NOTE: Commenting below logic, because added in Saga '*searchQuery'
    // NOTE: This has to be top-level logic for new query
    // --------------------------------------------------------------------------------------------
    // if (_query !== query) {
    // 	setFundsState({
    // 		requestPayloadFilters: {
    // 			filterKeys: [],
    // 			filterValues: [],
    // 		},
    // 		requestPayloadSort: {
    // 			name: '',
    // 			value: '',
    // 		},
    // 		// NOTE: resetting stocks preserve query/universe logic when new query searched
    // 		previousSearch: {
    // 			universe: null,
    // 		},
    // 		showGoBackSection: false,
    // 		isMoreStocksClicked: false,
    // 		showGoBackSectionClicked: false,
    // 	});
    // }
    // --------------------------------------------------------------------------------------------
    if (query) {
      if (!search_type && window.sessionStorage.getItem('search_type')) {
        search_type = window.sessionStorage.getItem('search_type');
        window.sessionStorage.removeItem('search_type');
      }
      if (search_type == 'trending_searches')
        addEventToAnalytics('Trending Searches', 'Trending Searches', 'TRENDING_SEARCHES', { clicked_query: query }, true);

      if (search_type === 'related_searches') {
        // this.props.history.push('/securities');
        addEventToAnalytics('Related Searches', 'Related Searches', 'RELATED_SEARCHES', { query: discovery.query, logId: discovery.logId, relatedQuery: query }, true);
      }

      const { searchQueryRequest, setFundsState, clearFundsApiLoadPercent } = this.props;
      clearFundsApiLoadPercent();
      setFundsState({
        actions: true,
        currentPage: 1,
      });
      this.checkRoutes(query, clearUTMSession);
      searchQueryRequest({
        query,
        search_type,
      });
    }
  }


  render() {
    const { card, index, checkPopOpen, firstPop, firstPopIndex, highlightText, discovery, sponsors_tickers } = this.props;
    const { queryUUID } = discovery;
    const self_role = window.localStorage.getItem('profile_self_role') ? window.localStorage.getItem('profile_self_role') : 'loggedOut';

    const rowClass = (self_role !== 'loggedOut' && process.env.REACT_APP_SUB_DOMAIN === 'retail') ? 'options-btn-body d-flex align-center' : 'options-btn-body d-flex align-center';

    // let ctaButtonMargin = (["","Other/Curious", "Individual Investor"].includes(self_role) === false) ? '0 8px 0 0' : '0 0 0 8px';
    // if (self_role === 'loggedOut') {
    //   if (process.env.REACT_APP_SUB_DOMAIN === 'advisor') {
    //     ctaButtonMargin = '0 8px 0 0';
    //   } else {
    //     ctaButtonMargin = '0';
    //   }
    // }
    let SponseredCardLink = '';
    let currentFund = null;
    let fund_info = null;
    let fact_sheet = null;
    let prospectus = null;
    let channel_name = null;
    let fund_info_impression_tag = null, fund_sheet_impression_tag = null;
    if ((card.is_sponsored || card.is_learn_more) && config.sponsoredTicker && sponsors_tickers.length) {
      let sponsArray = sponsors_tickers.filter(el => el.ticker === card.ticker)
      if (sponsArray.length) {
        currentFund = sponsArray[0];
        if (currentFund) {
          fund_info = currentFund.fund_information_advisor ? currentFund.fund_information_advisor : currentFund.fund_information ? currentFund.fund_information : null;
          fact_sheet = currentFund.fact_sheet_advisor ? currentFund.fact_sheet_advisor : currentFund.fact_sheet ? currentFund.fact_sheet : null;
          prospectus = currentFund.prospectus_advisor ? currentFund.prospectus_advisor : currentFund.prospectus ? currentFund.prospectus : null;
          channel_name = currentFund.channel_name;
        }

        let fund_info_impression_array = currentFund.fundinfo_impression ? currentFund.fundinfo_impression.split('||') : [];
        let fund_sheet_impression_array = currentFund.factsheet_impression ? currentFund.factsheet_impression.split('||') : [];

        if (fund_info_impression_array && fund_info_impression_array.length > 0) {
          if (currentFund.channel_name === 'Vanguard') {
            fund_info_impression_tag = fund_info_impression_array.map((k, ki) => (
              <img key={k + ki} src={k} border="0" width="1" height="1" style={{ display: 'none' }} />
            ))
          } else {
            fund_info_impression_tag = (<img src={fund_info_impression_array[0]} border="0" width="1" height="1" style={{ display: 'none' }} />);
          }
        }
        if (fund_sheet_impression_array && fund_sheet_impression_array.length > 0) {
          if (currentFund.channel_name === 'Vanguard') {
            fund_sheet_impression_tag = fund_sheet_impression_array.map((k, ki) => (
              <img key={k + ki} src={k} border="0" width="1" height="1" style={{ display: 'none' }} />
            ))
          } else {
            fund_sheet_impression_tag = (<img src={fund_sheet_impression_array[0]} border="0" width="1" height="1" style={{ display: 'none' }} />);
          }
        }

        if (sponsArray[0].learn_more_link) {
          SponseredCardLink = sponsArray[0].learn_more_link;
        } else if (sponsArray[0].link) {
          let splittedArray = []
          splittedArray = sponsArray[0].link.split("||")
          if (splittedArray.length) {
            SponseredCardLink = splittedArray[0];
            SponseredCardLink = sponsorPdfUrl(splittedArray[0], '/assets/data');
          }
        }
      }
    }

    return (
      <div className="h-100 card-background px-2 position-relative">
        {(card.vehicle === 'MF' && typeof card.class_type !== 'undefined' && card.class_type !== '' && !this.props.learnMoreLink && !currentFund && !SponseredCardLink)
          && (
            <div>
              <img id={`fundclass-${card.ticker}`} className="card-Ad-Icon" src={`/assets/images/${card.class_type}.png`} width='10' />
              {card._categorySeries &&
                <Tooltip className="col-grey" placement="right" isOpen={this.state.tooltipOpen} target={`fundclass-${card.ticker}`} toggle={() => this.setState({ tooltipOpen: !this.state.tooltipOpen })}>
                  {card.class_type === 'retail' ? 'Retail Class' : 'Institutional Class'}
                </Tooltip>
              }
            </div>
          )
        }
        {card.is_sponsored && config.sponsoredTicker && (
          <div className="ad-logo">
            SPONSORED
            {currentFund && currentFund.impression_tag && currentFund.impression_tag.split('||').map((k, ki) => (
              <img
                key={k + ki}
                src={k}
                alt=""
                border="0"
                width="1"
                height="1"
                style={{ display: 'none' }}
              />
            ))}
          </div>
        )}
        {(currentFund) ?
          <Dropdown
            isOpen={this.state.learnMoreDropdown}
            toggle={() => this.setState({ learnMoreDropdown: !this.state.learnMoreDropdown })}
            className="learnMoreDropdown"
          >
            <DropdownToggle
              id="learn_more_dropdown"
              className="toggler_button"
              caret
            >
              <span
                style={{
                  fontFamily: "Open Sans",
                  fontSize: "11px",
                  fontWeight: 400,
                  color: "#1B8BE0",
                  paddingBottom: 2
                }}
              >
                Learn More
              </span>
            </DropdownToggle>
            <DropdownMenu center>
              {fund_info ? <DropdownItem
                key={`fund_info`}
                className="learn_more_item"
                onClick={() => {
                  addEventToAnalytics('Learn More Fund Information', 'Learn More Fund Information', 'LEARN_MORE_FUND_INFORMATION', { ticker: card.ticker }, true);
                  window.open(fund_info.includes('pdf') && !fund_info.includes('https://') ? '/assets/data' + fund_info : fund_info, '_blank').focus();
                }}
              >
                Fund Information
                {fund_info_impression_tag}
              </DropdownItem> : null}
              {fact_sheet ? <DropdownItem
                key={`fact_sheet`}
                className="learn_more_item"
                onClick={() => {
                  addEventToAnalytics('Learn More Fact Sheet', 'Learn More Fact Sheet', 'LEARN_MORE_FACT_SHEET', { ticker: card.ticker }, true);
                  window.open(fact_sheet.includes('pdf') && !fact_sheet.includes('https://') ? '/assets/data' + fact_sheet : fact_sheet, '_blank').focus();
                }}
              >
                Fact Sheet
                {fund_sheet_impression_tag}
              </DropdownItem> : null}
              {prospectus ? <DropdownItem
                key={`prospectus`}
                className="learn_more_item"
                onClick={() => {
                  addEventToAnalytics('Prospectus', 'Prospectus', 'LEARN_MORE_PROSPECTUS', { ticker: card.ticker }, true);
                  window.open(prospectus.includes('pdf') && !prospectus.includes('https://') ? '/assets/data' + prospectus : prospectus, '_blank').focus();
                }}
              >
                Prospectus
              </DropdownItem> : null}
              {channel_name ? <DropdownItem
                key={`more_here`}
                className="learn_more_item"
                onClick={() => {
                  addEventToAnalytics('More From', 'More From', 'LEARN_MORE_FROM', { ticker: card.ticker, channel: channel_name }, true);
                  this.queryHandler(`${channel_name} Sponsored Funds`, 'search_bar', true)
                }}
              >
                More From {channel_name}
              </DropdownItem> : null}
            </DropdownMenu>
          </Dropdown> : (card._learnMoreLink || SponseredCardLink) ? <Link
            target='_blank'
            to={{ pathname: SponseredCardLink ? SponseredCardLink : this.props.learnMoreLink }}
            onClick={() => {
              addEventToAnalytics(
                'Sponsor Learn More Web',
                'Sponsor Learn More Web',
                'SPONSOR_LEARN_MORE_WEB',
                {
                  ticker: card.ticker,
                  query_uuid: queryUUID,
                }
              )
            }}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontFamily: "Open Sans",
                fontSize: "12px",
                fontWeight: 400,
                color: "#1B8BE0",
                paddingBottom: 2
              }}
            >
              Learn More
            </span>
            <img
              src={`/assets/images/learn_more.svg`}
              alt="learn more link"
              style={{ padding: 5 }}
            />
          </Link> : null
        }
        <Row className="no-gutters align-items-center h-100 media-flex-nowrap">
          <Col className="col-lg-3 col-md-auto d-lg-block d-sm-none select-btn-box">
            <Row className={rowClass}>
              {(process.env.REACT_APP_SUB_DOMAIN === 'retail') && (
                <div className='retail-cta-card-button-wrapper'>
                  <div>
                    <Button
                      id={`cart-btn-${card.ticker}`}
                      className={`cart-btn ${card._selected ? 'selected-state' : ''} ${(card.not_tradable === "1.0") ? 'disabled' : ''} `}
                      onMouseOver={e => {
                        if (!card._selected && card.not_tradable !== "1.0") {
                          this.handleMouseOver(e, 'selector')
                        }
                      }}
                      onMouseOut={e => {
                        if (!card._selected && card.not_tradable !== "1.0") {
                          this.handleMouseOut(e, 'selector')
                        }
                      }}
                      onClick={() => {
                        if (card.not_tradable !== "1.0") {
                          this.onClick('cart');
                          this.setState({ selector: false });
                        }
                      }}
                    >
                      {(card.not_tradable === "1.0") ? (
                        <img
                          src='/assets/images/icons/minus-shopping-cart-grey.svg'
                          alt='de-select'
                          width="24px"
                        />
                      ) : (
                        (!card._selected) ? (
                          <img
                            src={this.state.selectorRetailImg}
                            alt='select'
                            width="22px"
                          />
                        ) : (
                          <img
                            src='/assets/images/icons/minus-shopping-cart-grey.svg'
                            alt='de-select'
                            width="24px"
                          />
                        )
                      )}
                    </Button>
                    <UncontrolledTooltip className="col-grey" placement="right" target={`cart-btn-${card.ticker}`} >
                      {(card.not_tradable === "1.0") ? 'Security Not Tradable' : (!card._selector ? 'Add to Cart' : 'Clear')}
                    </UncontrolledTooltip>
                  </div>
                  <div id={`buy-btn-${card.ticker}`} className="buy-btn-tooltip-wrapper">
                    <>
                      {card.not_tradable === "1.0" && <div id={`buy-btn-${card.ticker}`} style={{ position: "absolute", height: "30px", width: "100%", cursor: card.not_tradable === "1.0" ? "no-drop" : "auto" }} />}
                    </>
                    <Button
                      className={`buy-btn`}
                      color={'select'}
                      onClick={() => this.buyButtonClick(card)}
                      style={{ background: card.not_tradable === "1.0" ? "#e8ecef" : "auto", borderColor: card.not_tradable === "1.0" ? "#d1d2d6" : "auto", color: card.not_tradable === "1.0" ? "#d1d2d6" : "auto" }}
                    >
                      BUY
                    </Button>
                    {/* <UncontrolledTooltip className="col-grey" placement="right" target={`buy-btn-${card.ticker}`} >
                      {card.not_tradable === "1.0"
                        ? <>Security Not Tradable</>
                        : <>Quick Buy</>
                      }
                    </UncontrolledTooltip> */}
                  </div>
                </div>
              )}
              {(process.env.REACT_APP_SUB_DOMAIN === 'advisor') && (
                <>
                  <div className='selector-btn' style={{ margin: '0 8px 0 0' }}>
                    <Button
                      disabled={(typeof card._categorySeries === 'undefined')}
                      onClick={() => { this.onClick('cart'); this.setState({ selector: false }); }}
                      onMouseOver={e => this.handleMouseOver(e, 'selector')}
                      onMouseOut={e => this.handleMouseOut(e, 'selector')}
                      id={`selector-btn-${card.ticker}`} outline={!card._selector} color={card._selector ? 'unselect' : 'select'}
                      className={`unselected ${card._selector ? 'selected' : null}`}
                    >
                      {!card._selector ?
                        <img
                          src={this.state.selectorImg}
                          alt=''
                        /> :
                        <img
                          src='/assets/images/icons/shopping-cart-White.svg'
                          alt='dis'
                        />
                      }
                    </Button>
                    {(this.state.selector) && <div className={`btn-info-txt cart ${card._selector && 'clear'}`}>{card._selector ? 'CLEAR' : '+ SELECTOR'}</div>}
                  </div>
                  <div className='builder-btn'>
                    <Button
                      onClick={(e) => { card._builder ? this.onBuilderClearClick(e) : this.onClick('builder'); this.setState({ builder: false }); }}
                      onMouseOver={e => this.handleMouseOver(e, 'builder')}
                      onMouseOut={e => this.handleMouseOut(e, 'builder')}
                      id={`builder-btn-${card.ticker}`} color={card._builder ? 'unselect' : 'select'}
                      className={`unselected ${card._builder ? 'selected' : null}`}
                      disabled={card.vehicle === 'SHARE'}
                    >
                      {!card._builder ?
                        <img
                          src={this.state.builderImg}
                          alt=''
                        /> :
                        <img
                          src='/assets/images/icons/BuilderIcon-White.svg'
                          alt=''
                        />
                      }
                    </Button>
                    {(this.state.builder) && <div className={`btn-info-txt builder ${card._builder && 'clear builder-clear'}`}>{card._builder ? 'CLEAR' : '+ BUILDER'}</div>}
                  </div>
                </>
              )}
            </Row>
          </Col>
          <Col className="col-1 p-0 d-md-none media-show">
            <label className="orange-checkbox ml-2 mr-5">
              <input type="checkbox"
                onChange={this.onClick}
                checked={card._selected}
              />
              <span className="checkmark"></span>
            </label>
            {card.vehicle && card.vehicle !== 'MP'
              && (
                <div className="vehicle-label media-vehicle-label" style={{ textTransform: card.vehicle === 'SHARE' ? 'capitalize' : '' }}>
                  {card.vehicle === 'SHARE' ? 'Stock' : card.vehicle}
                </div>
              )
            }
            {card.vehicle && card.vehicle === 'MP'
              && (
                <div className="vehicle-label" style={{ maxWidth: 60 }}>Model Portfolio</div>
              )
            }
          </Col>
          <Col className="col-auto col-md-3 text-center fund-ticker-box">
            <h5 className="mb-0 text-blue text-gray-5 ticker-size ticker-price">
              {cn(card, 'Ticker Short')}
            </h5>
            <h3 className="mt-1 mb-0 text-info">
              <span className="ticker-price text-gray-5">$</span>
              <span className="ticker-price">{cn(card, 'Nav Price') ? cn(card, 'Nav Price').toFixed(1) : ''}</span>
            </h3>
            {card.vehicle && card.vehicle !== 'MP'
              && (
                <div className="vehicle-label mt-1 mb-0 text-gray-6 media-vehicle-label" style={{ textTransform: ['SHARE', 'BASKET'].includes(card.vehicle) ? 'capitalize' : '' }}>
                  {(card.vehicle === 'BASKET') ? (
                    <>Managed <br /> Portfolio</>
                  ) : renderMappedVehicle(card.vehicle)}
                </div>
              )
            }
            {card.vehicle && card.vehicle === 'MP'
              && (
                <div className="vehicle-label mt-1 mb-0 text-gray-6" style={{ maxWidth: 60 }}>Model Portfolio</div>
              )
            }
          </Col>
          <Col className="media-mycard-desc col-md-6 fund-name-box">
            <NamePopover {...this.props} discovery={discovery} card={card} toggleDetailsModal={this.props.toggleDetailsModal} index={index} checkPopOpen={checkPopOpen} firstPop={firstPop} firstPopIndex={firstPopIndex} highlightText={highlightText} />
          </Col>
          <Col md="12" className="d-md-none d-sm-block">
            <div className="d-flex align-items-center mt-2 ml-5">
              <i className="mr-3 h2 mb-0">
                <span className="icon-Slader-Home-Active">
                  <span className="path1"></span><span className="path2"></span>
                </span>
              </i>
              {/*card._categorySeries &&
                <ResponsiveCardPopover card={card} discovery={discovery} chartData={chartData} highlightText={highlightText} checkPopOpen={checkPopOpen} firstPop={firstPop} />
              */}
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default CardName;

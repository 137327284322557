import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link, Redirect } from 'react-router-dom';

import { Formik } from 'formik'
import * as Yup from 'yup'

import { FormGroup } from 'reactstrap';

import { register } from 'actions/register'
import { setGlobalState } from 'actions/global';
import { setSFMState } from 'actions/sfm';

import { createLoadingSelector } from 'selectors/loading-selector';
import { Spinner } from 'shared-components/';
import { isTermsAgreed } from 'selectors/auth-selector';
// import { REDIRECT_DOMAIN_PATH } from 'DataSet';
import { PrimaryButton } from 'shared-components/';
import SurveyForm from './SurveyForm';

// const magnifiLogo = "/assets/images/MagnifiLogo/Magnifi_Tagline_White_RGB@2x.png";

class Survey extends Component {
  state = { modal: true, showSuccess: false, visible: false }

  initialValues = {
    first_name: '',
    last_name: '',
    zip_code: '',
    email: '',
    country: '',
    areYouAn: '',
    areYouAnOther: '',
    accreditedInvestor: false
  }

  handleSubmit = (values, { setSubmitting, setFieldError, resetForm }) => {
    resetForm(this.initialValues);
    this.setPrintCookie(values)
  }

  setPrintCookie = (values) => {
    document.cookie = `magnifiSurveyInfo=${JSON.stringify(values)}`;
    // this.props.setProfileCookie();
    this.setState({ visible: true })
  }

  registerSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is a required field'),
    last_name: Yup.string().required('Last name is a required field'),
    zip_code: Yup.string().required('Zip code is a required field'),
    country: Yup.string().required('Country name is a required field'),
    areYouAn: Yup.string().required('Please select a value'),
    email: Yup.string()
      .email()
      .required('Email is a required field'),
    areYouAnOther: Yup.string().when('areYouAn', {
      is: (areYouAn) => areYouAn === 'Other',
      then: Yup.string().required('Enter a value'),
      otherwise: Yup.string()
    }),
    // accreditedInvestor: Yup.boolean().oneOf(
    //   [true],
    //   'Please accept terms and conditions'
    // )
  })

  changeGlobalSuveryState = () => {
    const { setGlobalState } = this.props;
    setGlobalState({
      surveyModal: false,
    });
  }

  changeGlobalandSFMState = () => {
    const { setGlobalState, setSFMState } = this.props;
    setGlobalState({
      surveyModal: false,
      replacePremiumWithSurvey: false,
    });
    setSFMState({
      autoOpenModal: undefined, // do not change this
      modalToggleState: false,
    });
  }

  render() {
    // const { loggedIn, registerSuccess } = this.props;
    return (
      <React.Fragment>
        <div className="survey-container">
          <Spinner isFetching={this.props.isFetching}/>
          {
            this.state.visible &&
            <div className="profile-success">
              <div>This information is saved in the profile page. For adding additional information, please go to Settings.</div>
              <FormGroup className="cta-wrapper">
                <PrimaryButton
                  type="button"
                  className="btn secondary-btn"
                  onClick={() => this.setState({ visible: !this.state.visible }, () => {this.props.setProfileCookie()})}
                >
                  OKAY
                </PrimaryButton>
              </FormGroup>

            </div>
          }
          {
            !this.state.visible &&
            <Formik
              initialValues={this.initialValues}
              onSubmit={this.handleSubmit}
              validationSchema={this.registerSchema}
              render={props => (
                <SurveyForm {...this.props} changeGlobalandSFMState={this.changeGlobalandSFMState} changeGlobalSuveryState={this.changeGlobalSuveryState} formProps={props} onToggle={this.handleToggle} error={this.props.error} />
              )}
            />
          }

      </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ auth, register, loading }) => ({
  error: register.error,
  registerFailed: register.registerFailed,
  registerSuccess: register.registerSuccess,
  isFetching: createLoadingSelector(['REGISTER'])({loading}),
  isTermsAgreed: isTermsAgreed(auth),
  loggedIn: auth.loggedIn,
})

const mapDispatchToProps = {
  register,
  setGlobalState,
  setSFMState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Survey);

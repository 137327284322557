import React, { Component } from 'react'
import { Form, FormGroup } from 'reactstrap'
import { connect } from 'react-redux'

import { addEventToAnalytics } from 'Utils'
import { setGlobalState } from 'actions/global'

import { PrimaryButton } from 'shared-components/'

import { jsonInURL } from 'Utils'

class SurveyForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      areYouAnOther: false,
      tooltipOpen: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].style.paddingTop = 0
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.state.areYouAnOther &&
      nextProps.formProps.values.areYouAn === 'Other'
    ) {
      this.setState({ areYouAnOther: true }, () =>
        document.getElementById('areYouAnOther').focus()
      )
    } else if (
      this.state.areYouAnOther &&
      nextProps.formProps.values.areYouAn === 'Other'
    ) {
    } else this.setState({ areYouAnOther: false })
  }

  openRegister = () => {
    this.props.changeGlobalSuveryState()
    addEventToAnalytics(
      'Register Popup',
      'Register Popup',
      'REGISTER_POPUP'
    )

    if (process.env.REACT_APP_SUB_DOMAIN === 'retail') {
      this.props.history.push('/register', {
        plan: 'basic',
        planType: 'selector'
      })
    } else {
      const registerParams = {
        user: 'sharing-alpha',
      };
      this.props.history.push(`${jsonInURL(registerParams, '/register')}`)
    }
  }

  openLogin = () => {
    this.props.changeGlobalSuveryState()
    addEventToAnalytics('Login Popup', 'Login Popup', 'LOGIN_POPUP')
    this.props.history.push('/login')
  }

  closeModal = () => {
    const counter =localStorage.getItem('counter')
    const val = parseInt(counter, 10)
    localStorage.setItem('plusCounter', val + 6)
    localStorage.setItem('counter', val + 1)
    this.props.changeGlobalandSFMState()
    // this.props.setGlobalState({ replacePremiumWithSurvey: false, surveyModal: false })
  }

  render() {
    const { formProps } = this.props
    const { handleSubmit } = formProps

    return (
      <Form
        className="survey-wrapper"
        noValidate={true}
        onSubmit={handleSubmit}
      >
        <div className="header-area">
          <i
            className="fal fa-times-circle survey-close-icon"
            onClick={this.closeModal}
          ></i>
          <div className="header-label text-center">New to Magnifi?</div>
        </div>

        <FormGroup className="cta-wrapper">
          <PrimaryButton
            type="submit"
            className={process.env.REACT_APP_SUB_DOMAIN === 'retail' ? "btn1 secondary-btn" : "btn secondary-btn"}
            onClick={this.openRegister}
          >
            {process.env.REACT_APP_SUB_DOMAIN === 'retail' ? 'Open a Magnifi Account': 'Register'}
          </PrimaryButton>
        </FormGroup>

        <p>
          <div className="circle">
            <span className="" style={{ color: '#fff', fontSize: '1rem' }}>
              OR{' '}
            </span>
          </div>
        </p>

        <div className="header-area">
          <div className="header-label text-center">Already a User?</div>
        </div>

        <FormGroup className="cta-wrapper" style={{ margin: 0 }}>
          <PrimaryButton
            type="submit"
            className="btn primary-btn"
            onClick={this.openLogin}
          >
            Login
          </PrimaryButton>
        </FormGroup>
      </Form>
    )
  }
}

const mapDispatchToProps = {
  setGlobalState,
}

export default connect(null, mapDispatchToProps)(SurveyForm)

import React from 'react';
import { Menu, Dropdown, Divider, Input } from 'antd'
import { DownOutlined } from '@ant-design/icons';
import UploadSection from './UploadSection';
import {deflatLastNode} from 'Utils';
import style from './OnBoardScreen.module.scss'
const { SubMenu } = Menu;
const { Search } = Input;
//hack for scroll not to jump for autofocus
let scroll = 0
export const portfolioMenus = ({ props, selectedPortfolio, demoPortfolio }) => {
	const { portfolioList } = props;
	return (
		<Menu className='portfolio-menu-wrapper'>
			<Menu.Item key="Demo Model">
				{/* <div
					className="active-label"
					onClick={() =>
						demoPortfolio()
					}
				>
					Use Demo Portfolio
				</div> */}
				<UploadSection className="active-label" fileFor="Demo_portfolio" onFileSuccess={() => demoPortfolio()}>
					Use Demo Portfolio
				</UploadSection>
			</Menu.Item>
			<Menu.Item key='Upload Portfolio'>
				<UploadSection className="active-label" fileFor="Custom_portfolio" onFileSuccess={(item) => selectedPortfolio(item)}>
					Upload Portfolio
				</UploadSection>
			</Menu.Item>
			<Menu.Divider />
			{portfolioList &&
				portfolioList.length &&
				portfolioList.map((item, index) => {
					return (
						<Menu.Item key={item.portfolio_name + index}>
							<div
								className="portfolio_name-label dropdown-style"
								onClick={() => {
									selectedPortfolio(item)
								}}
							>
								{item.portfolio_name}
							</div>
						</Menu.Item>
					)
				})
			}
		</Menu>
	);
}

export const sponsoredMenus = ({ props, openChange, setMarketPlace }) => {
	let { obj:newMarketPlaceList } = deflatLastNode(props.marketPlaceList)
	const { portfolioList } = props;

	return (
		<Menu onOpenChange={openChange}>
			{newMarketPlaceList && typeof newMarketPlaceList == 'string' && <div />}
			{newMarketPlaceList &&
				typeof newMarketPlaceList == 'object' &&
				Object.keys(newMarketPlaceList).length > 0 &&
				Object.keys(newMarketPlaceList).map((item, index) => {
					return (
						<SubMenu
							key={item}
							title={item}
							className="dropdown-style"
							popupClassName="market-popup-head build-portfolio-onboard-screen-dropdown dropdown-style"
						>
							{Object.keys(newMarketPlaceList[item]).length > 0 &&
								Object.keys(newMarketPlaceList[item]).map((subItem, index) => {
									if(subItem == 'uncatagorized'){
										return(
											<div className="market-popup-wrapper">
												{newMarketPlaceList[item][subItem].length > 0 &&
													newMarketPlaceList[item][subItem].map(
														(subItem1, index) => {
															return (
																<div
																	className="market_name-label dropdown-style"
																	onClick={() =>
																		setMarketPlace(subItem1)
																	}
																>
																	{subItem1.name}
																</div>
															)
														}
													)}
											</div>
										)
									}else{
										return (
											<SubMenu
												key={subItem}
												title={subItem}
												className="dropdown-style"
												popupClassName="market-popup dropdown-style"
											>
												<div className="market-popup-wrapper">
													{newMarketPlaceList[item][subItem].length > 0 &&
														newMarketPlaceList[item][subItem].map(
															(subItem1, index) => {
																return (
																	<div
																		className="market_name-label dropdown-style"
																		onClick={() =>
																			setMarketPlace(subItem1)
																		}
																	>
																		{subItem1.name}
																	</div>
																)
															}
														)}
												</div>
											</SubMenu>
										)
									}
								})}
						</SubMenu>
					)
				})}
			{newMarketPlaceList &&
				newMarketPlaceList.message &&
				newMarketPlaceList.message == 'No tickers Present.' && (
				<Menu.Item key="0">
					<div className="portfolio_name-label dropdown-style">No data</div>
				</Menu.Item>
			)}
		</Menu>
	);
}

export class CustomSponsoredDropDown extends React.Component{
	constructor(props) {
		super(props)
		scroll= window.pageYOffset
	}
	state={
		input:''
	}

	componentWillMount() {
		let { obj:newMarketPlaceList,allItems:allList } = deflatLastNode(this.props.marketPlaceList,true)
		this.setState({
			newMarketPlaceList,
			allList
		})
	}

	componentDidMount() {
		window.scrollTo(scroll,scroll)
	}

	handleInputChange =(e)=>{
		this.setState({
			input:e
		})
	}
	render(){
		const {openChange,setMarketPlace} = this.props
		const {input,newMarketPlaceList,allList} = this.state
		let filterdList = input.length!==0 && allList.filter(item=>item.name.toLowerCase().includes(input.toLowerCase()))
		return (
			<Menu onOpenChange={openChange} className={style.portfolioMenuWrapper}>
				<Menu.Item className={style.inputContainer}>
					<Search autoFocus onChange={(e)=>{e.preventDefault();this.handleInputChange(e.target.value)}} className={style.input} placeholder="Search for better results" allowClear onSearch={this.handleInputChange} />
				</Menu.Item>
				{input.length!==0 && filterdList.map(item=> <Menu.Item className={style.onSearchMenuContainer}>
						<div className={style.onSearchMenu} onClick={() => setMarketPlace(item)} >
							<i className={`far fa-fw fa-search ${style.resultSearchIcon}`}/>
							<div className={style.resultDetail}>
								<p title={item.name}>{item.name}</p>
								<p title={item.sponsor}>{item.sponsor}</p>
							</div>
						</div>
					</Menu.Item>
				)}
				{input.length===0 && newMarketPlaceList &&
				typeof newMarketPlaceList == 'object' &&
				Object.keys(newMarketPlaceList).length > 0 &&
				Object.keys(newMarketPlaceList).map((item, index) => {
					return (
						<SubMenu
							key={item}
							title={item}
							className={style.subMenu}
							popupClassName={style.popupClass}
						>
							{Object.keys(newMarketPlaceList[item]).length > 0 &&
							Object.keys(newMarketPlaceList[item]).map((subItem, findex) => {
								if(subItem == 'uncatagorized'){
									return newMarketPlaceList[item][subItem].length > 0 && newMarketPlaceList[item][subItem].map(
										(subItem1, index) => {
											return (
												<div
													key={`market_name-label_${index}`}
													className={style.itemLable}
													onClick={() =>
														setMarketPlace(subItem1)
													}
												>
													{subItem1.name}
												</div>
											)
										}
									)

								}
								else{
									return (
										<SubMenu
											key={subItem}
											title={subItem}
											className={style.subMenu}
											popupClassName={style.popupClass}
										>
											{newMarketPlaceList[item][subItem].length > 0 &&
											newMarketPlaceList[item][subItem].map(
												(subItem1, index) => {
													return (
														<div
															key={`market_name-label_${index}`}
															className={style.itemLable}
															onClick={() =>
																setMarketPlace(subItem1)
															}
														>
															{subItem1.name}
														</div>
													)
												}
											)}
										</SubMenu>
									)
								}
							})}
						</SubMenu>
					)
				})
				}
				{input.length!==0 && filterdList.length===0 && 	<Menu.Item className={`${style.noHighlight} ${style.noResult}`} key="0">
					<div >No Result Found</div>
				</Menu.Item>}
				{newMarketPlaceList &&
				newMarketPlaceList.message &&
				newMarketPlaceList.message == 'No tickers Present.' && (
					<Menu.Item key="0">
						<div className="portfolio_name-label dropdown-style">No data</div>
					</Menu.Item>
				)}
			</Menu>
		);
	}


}

export class PortfolioDDSearch extends React.Component  {
	constructor(props) {
		super(props);
		scroll= window.pageYOffset
	}
	state={
		input:'',
		portfolioList:[]
	}

	componentDidMount() {
		this.listGenerator()
		// console.log(document.getElementById('pfSearch').getBoundingClientRect().top)
		window.scrollTo(scroll,scroll)
	}
	componentWillReceiveProps(nextProps, nextContext) {
		let { portfolioList} =nextProps;
		//regenerate in case of deletion
		if (Math.abs(this.state.portfolioList.length- portfolioList.length) > 1|| portfolioList.message) {
			this.listGenerator()
		}
	}
	listGenerator=()=>{
		let { portfolioList} =this.props;
		portfolioList= portfolioList.length>0 ? [...portfolioList, { 'portfolio_name':'Demo Portfolio' }].sort((a,b)=> {
			let nameA = a.portfolio_name.toLowerCase();
			let nameB = b.portfolio_name.toLowerCase();
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			return 0;
		}):[{ 'portfolio_name':'Demo Portfolio' }]
		this.setState({
			portfolioList
		})
	}

	handleInputChange =(e)=>{
		// console.log(e)
		this.setState({
			input:e
		})
	}

	render(){
		const { selectedPortfolio,demoPortfolio,handleDDMenuClick,deletePortfolio } =this.props;
		const {input,portfolioList} =this.state
		let filterList = portfolioList &&portfolioList.length>0 && portfolioList.filter(item=>item.portfolio_name.toLowerCase().includes(input.toLowerCase()))
		return (
			<Menu className={style.portfolioMenuWrapper}>
				<Menu.Item key={'search'} className={style.inputContainer}>
					<Search autoFocus onChange={(e)=>{e.preventDefault();this.handleInputChange(e.target.value)}} className={style.input} placeholder="Search for better results" allowClear onSearch={this.handleInputChange} />
				</Menu.Item>
				{input.length!==0 && filterList.length===0 && 	<Menu.Item className={`${style.noHighlight} ${style.noResult}`} key="0">
					<div >No Result Found</div>
				</Menu.Item>}
				{filterList &&
				filterList.length &&
				filterList.map((item, index) => {
					return (item.portfolio_name==='Demo Portfolio'?<Menu.Item className={style.onSearchMenuContainerUP} key="Demo Model">
								<UploadSection fileFor="Demo_portfolio" onFileSuccess={() => demoPortfolio()}>
									<div onClick={()=>{handleDDMenuClick(false)}} className={`d-flex align-items-center justify-content-left ${style.onSearchMenu}`} >
										{input.length>0 && <i style={{opacity:`0.4`}} className={`far fa-fw fa-search ${style.resultSearchIcon}`}/>}
										<p style={{margin:'0'}}>
											Demo Portfolio
										</p>
									</div>
								</UploadSection>
						</Menu.Item>:<Menu.Item className={style.myPfItems} key={item.portfolio_name + index}>
							<div title={item.portfolio_name} className={style.myPfItemsDetail}  onClick={() => {selectedPortfolio(item)}} >
								{input.length>0 && <i className={`far fa-fw fa-search ${style.resultSearchIcon}`}/>}
								<p>{item.portfolio_name}</p>
							</div>
							<i onClick={() => deletePortfolio(item.portfolio_name)} className={`far fa-fw fa-trash-alt ${style.deleteIcon}`}/>
						</Menu.Item>
					)
				})
				}
			</Menu>

		);
	}

}

export class ClientDDSearch extends React.Component{
	constructor(props) {
		super(props);
		scroll= window.pageYOffset
	}

	state={
		input:'',
		clientList:[]
	}

	componentDidMount() {
		let { clientList } =this.props;
		clientList= [...clientList, { 'name':'Demo Account' }].sort((a,b)=> {
			let nameA = a.name.toLowerCase();
			let nameB = b.name.toLowerCase();
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			return 0;
		})
		this.setState({
			clientList
		})
		window.scrollTo(scroll,scroll)
	}

	handleInputChange =(e)=>{
		// console.log(e)
		this.setState({
			input:e
		})
	}

	handleClick=(item)=>{
		this.props.handleDDMenuClick(item)
	}

	render() {
		const {input,clientList} =this.state
		let filterList = clientList &&clientList.length>0 && clientList.filter(item=>item.name.toLowerCase().includes(input.toLowerCase()))
		return 	<Menu className={style.portfolioMenuWrapper}>
				<Menu.Item key={'search'} className={`${style.noHighlight} ${style.inputContainer}`}>
					<Search autoFocus onChange={(e)=>{e.preventDefault();this.handleInputChange(e.target.value)}} className={style.input} placeholder="Search for better results" allowClear onSearch={this.handleInputChange} />
				</Menu.Item>
				{input.length!==0 && filterList.length===0 && 	<Menu.Item className={`${style.noHighlight} ${style.noResult}`} key="0">
					<div >No Result Found</div>
				</Menu.Item>}
				{ filterList && filterList.length && filterList.map((item, index) => {
						return (
							<Menu.Item className={style.onSearchMenuContainerUP} key={item.name+item.id}>
								<div className={style.onSearchMenu}  onClick={() => {this.handleClick(item)}} >
									{input.length>0 && <i className={`far fa-fw fa-search ${style.resultSearchIcon}`}/>}
									<div title={item.name} className={style.resultDetail}>
										{item.name}
									</div>
								</div>
							</Menu.Item>
						)
					})
				}
			</Menu>
	}
}

export default {
	sponsoredMenus,
	portfolioMenus,
	CustomSponsoredDropDown,
	PortfolioDDSearch,
	ClientDDSearch
};

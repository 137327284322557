import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const detailsModal = ({ open, toggle, data, item, key, ...props }) => {
  const closebtn = <button className={`close mydetails-close`} onClick={toggle}>
    <div className={`hamburger ${open ? " active" : ""} `}>
      <div className="hamburger-box">
        <div className="hamburger-inner"></div>
      </div>
    </div>
  </button>
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      backdrop={true}
      modalClassName={`sfp modal-full-screen ${open ? 'full' : ''}`}
      className="modal-lg"
      contentClassName="bg-ssf-blue-d2"
      backdropClassName="sfp-backdrop"
    >
      <ModalHeader
        className=" media-details-tab position-relative text-white lh-100 border-0 d-flex justify-content-center font-one"
        toggle={toggle}
        tag="h4"
        close={closebtn}
      >

        <span className="font-weight-bold">Relevant Details</span>
      </ModalHeader>
      <ModalBody className="p-0 font-one bg-white relevance-modal-body">
        {data}
      </ModalBody>
    </Modal>
  )
}

export default detailsModal

import React from 'react'
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, ButtonGroup, Form, FormGroup, Label, Input } from 'reactstrap'
import { PrimaryButton } from 'shared-components'
import InputChips from '../Shared/InputChips'
import { updateRestrictedTickersRequest } from 'actions/clientportfolioV2'
import { addEventToAnalytics } from 'Utils';

class RestrictedTickersModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTickers: []
    }
  }

  componentDidMount(){
    const { clientDetails, clientList } = this.props;
    if(clientDetails && clientDetails.length == 1 && clientDetails[0].restricted_ticker){
      this.setState({
        selectedTickers: clientDetails[0].restricted_ticker.split(',')
      })
    }
  }

  updateTickers = (val) => {
    this.setState({
      selectedTickers: val
    })
  }

  handleSubmit = () => {
    const { clientDetails, clientList } = this.props;
    const { selectedTickers } = this.state;
    let updatedTickerList = [];
    // if( selectedTickers.length == 0 ) return;
    if(clientDetails && clientDetails.length == 1){
      addEventToAnalytics('Client Restricted Micro', 'Client Restricted Micro', 'CLIENT_RESTRICTED_MICRO', { client_id: clientDetails[0].id }, false)
      const tickerObj = {
        client_id: clientDetails[0].id,
        tickers: selectedTickers
      }
      updatedTickerList.push(tickerObj);
    }else{
      addEventToAnalytics('Client Restricted Macro', 'Client Restricted Macro', 'CLIENT_RESTRICTED_MACRO', {}, false)
      clientList.map(item => {
        if(!item.restricted_ticker){
          const tickerObj = {
            client_id: item.id,
            tickers: selectedTickers
          }
          updatedTickerList.push(tickerObj)
        }
      })
    }
    if(updatedTickerList.length > 0){
      this.props.updateRestrictedTickersRequest(updatedTickerList);      
    }
    this.props.closeRestrictedTickersModal();
  }

  render() {
    const { showRestrictedTickersModal, clientDetails, closeRestrictedTickersModal } = this.props;

    let accInfo;

    if (clientDetails && clientDetails.length == 1) {
      accInfo = `${clientDetails[0].name ? clientDetails[0].name + "'s" : 'this'} account`;
    }  else {
      accInfo = `all accounts`;
    }

    return (
      <React.Fragment>
        <Modal
          isOpen={showRestrictedTickersModal}
          toggle={() =>
            closeRestrictedTickersModal()
          }
          centered
          keyboard={false}
          backdrop="static"
          className="res-tickers-sm-light"
          backdropClassName="pf-up-sm-light-modal-backdrop"
          style={{ maxWidth: '550px !important' }}
        >
          <ModalBody className="ssf-modal__body sml-modal-wrapper">
            <i className="fal fa-times cp-modal-close" onClick={() => closeRestrictedTickersModal()}></i>
            <h3 className="text-center">Restricted Tickers</h3>
            <div className="confirm-text">
              <span>Please enter the restricted tickers for {accInfo}</span>
            </div>
            <div className="mdl-form-content">
              {/* <FormGroup className="element-box pos-rel">
                <input
                  id="save-file-name-box"
                  className="input-area"
                  type="text"
                  placeholder="Please hit enter after each ticker"
                  autoComplete="off"
                />
              </FormGroup> */}
              <InputChips placeholder="Please hit enter after each ticker" selectedTickers={this.state.selectedTickers} updateTickers={this.updateTickers} />
            </div>
            <div className="col-12 cta-wrapper">
              <button type="button" disabled={this.state.selectedTickers.length==0} className="ssf-btn-primary btn secondary-btn btn btn-secondary"
                onClick={() => this.handleSubmit()}><span>DONE</span></button>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ clientportfolioV2: { clientList } }) => ({
  clientList
})

const mapDispatchToProps = {
  updateRestrictedTickersRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestrictedTickersModal);

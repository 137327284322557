import React from 'react';
import { Row, Col } from 'reactstrap';
import GaugeChart from 'components/charts/GaugeChart';
import { isNull, isUndefined } from 'util';
import NotApplicable from 'components/NotApplicable';


export const FudiciaryChart = ({ card, name, width }) => {
  if (isNull(card.fiduciary_risk) || isUndefined(card.fiduciary_risk) || card.fiduciary_risk == "null" || typeof card.fiduciary_risk == 'undefined') return <NotApplicable />;
  let _min = 0,
    _max = 100,
    _val = card.fiduciary_risk ? card.fiduciary_risk : 0 ;
  let chart_color = "gyr"
  return (
    <div className="w-100 p-1 guage-chart-wrapper">
      <div className="d-flex flex-column align-items-center" >
        {/* style={{ minWidth: width || 134 }} */}
        {/* <h6 className="text-center mb-0">Percentage Overlap</h6> */}
        {(_min === _max && _max === _val) ? (
          <GaugeChart minWidth={width} pc={0.5} />
        ) : (
          <GaugeChart chart_color={chart_color} minWidth={width} value={_val} min={_min} max={_max} />
        )}
        <Row className="no-gutters h6 mb-0 w-100 guage-chart-details">
          <Col className="col-4 pr-2 text-gray-6i text-right h7 fz-9" style={{ marginTop: '-2px' }}>{_min.toFixed(0)}</Col>
          <Col className="col-4 text-center font-weight-bold mb-0 lh-100 fz-9" style={{ marginTop: '2px' }}>{_val == 0 ? _val : _val.toFixed(0)}</Col>
          <Col className="col-4 pl-2 text-gray-6i text-left h7 fz-9" style={{ marginTop: '-2px' }}>{_max.toFixed(0)}</Col>
        </Row>
      </div>
    </div>
  );
}

export default FudiciaryChart;


import React, { useState, useRef } from 'react'
import { Select, Spin } from 'antd';
import { orderBy } from 'lodash';
import debounce from 'lodash/debounce';

import {
  PlusOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import { colWidthObj } from './helper';
import { Loader } from '../Loader';

const { Option } = Select;

class SearchDropdown extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      value: [],
    };
  }

  componentDidMount() {
    const { getTickerListRequest, magnifiTickers, getElementRef } = this.props;
    if (magnifiTickers && magnifiTickers.length === 0 && getTickerListRequest) {
      getTickerListRequest();
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.value !== this.props.value && newProps.value !== this.state.value) {
      this.setState({
        value: newProps.value,
      });
    }
  }

  searchedTicker = (selectedTicker) => {
    // console.log('search dropdown -> ', selectedTicker);
    const { magnifiTickers, getRealTimePrice } = this.props;
    if (magnifiTickers && selectedTicker && selectedTicker.value) {
      if (getRealTimePrice) {
        getRealTimePrice({ ticker: selectedTicker.value }, (response) => {
          if (response && response.ticker === selectedTicker.value) {
            selectedTicker.price = response.price;
          }
          let foundTickerData = magnifiTickers.find((kl) => kl.ticker === selectedTicker.value);
          if (foundTickerData) {
            const { searchedCustomTicker, onChange } = this.props;
            if (searchedCustomTicker) {
              searchedCustomTicker(foundTickerData, selectedTicker);
            }
            // console.log('selectedTicker-->', selectedTicker);
            this.setState({ value: selectedTicker });
          }
        });
      }
    }
  }

  fetchUserList = (searchKey, tickerList) => {
    // console.log('ticker ==>', ticker);
    // console.log(tickerList.length);
    // let _tickerList = tickerList.filter(suggestion => ((suggestion && suggestion.ticker && suggestion.ticker.toLowerCase().includes(searchKey.toLowerCase())) || (suggestion && suggestion.name && suggestion.name.toLowerCase().includes(searchKey.toLowerCase())) ));
    let _presentInTickerField = tickerList.filter(suggestion => (suggestion && suggestion.ticker && suggestion.ticker.toLowerCase().includes(searchKey.toLowerCase())) );
    let _presentInNameField = tickerList.filter(suggestion => (suggestion && suggestion.name && suggestion.name.toLowerCase().includes(searchKey.toLowerCase())) );
    let _tickerList = [..._presentInTickerField, ..._presentInNameField];
    return _tickerList;

    // return orderBy(_tickerList, ['ticker', 'name'], ['asc', 'desc']);

    // return new Promise((resolve, reject) => {
    //   return tickerList.filter(suggestion => suggestion.ticker.toLowerCase().includes(ticker.toLowerCase()));
    // });
    // const filteredSuggestions = tickerList.filter(
    //   suggestion => suggestion.toLowerCase().indexOf(username.toLowerCase()) > -1
    // );
    // console.log(filteredSuggestions);
    // return filteredSuggestions;
    // return fetch('https://randomuser.me/api/?results=5')
    //   .then((response) => response.json())
    //   .then((body) =>
    //     body.results.map((user) => ({
    //       label: `${user.name.first} ${user.name.last}`,
    //       value: user.login.username,
    //     })),
    //   );
  }

  render() {
    const { magnifiTickers, loading, ref, onPressEnter, onBlur, placeHolder, showSearchIcon, getElementRef, searchDropdownHeight, searchDropdownTargetElement, realTimePriceLoading } = this.props;
    const { value } = this.state;
    // console.log('magnifiTickers length ==>', magnifiTickers.length);
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const notFoundContent = <div class="no-suggestions"><em>No suggestions found.</em></div>

    // console.log('search value ->', value);
    return (
			<div className="d-flex flex-column align-items-center justify-content-center pos-rel" style={{ height: 30 }}>
				{(loading || realTimePriceLoading) && (
					<div className="custom-ticker-search-box-loader">
						<Spin indicator={antIcon} />
					</div>
				)}
				{!loading && (
					<div id="custom-ticker-search-box-wrapper" className="custom-ticker-search-box-wrapper">
						<DebounceSelect
							value={value}
              // getRef={this.props.getRef}
              placeHolder={placeHolder}
							dataList={magnifiTickers}
              showSearchIcon={showSearchIcon}
							notFoundContent={notFoundContent}
              realTimePriceLoading={realTimePriceLoading}
              searchDropdownHeight={searchDropdownHeight}
              searchDropdownTargetElement={searchDropdownTargetElement}
							fetchOptions={(value, list) => this.fetchUserList(value, list)}
							onChange={(newValue) => {
								this.searchedTicker(newValue);
							}}
						/>
					</div>
				)}
			</div>
    );
  }
}

export default SearchDropdown;

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
  const dataList = props.dataList || [];
  const [fetching, setFetching] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const fetchRef = React.useRef(0);
  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      const newOptions = fetchOptions(value, dataList);
      if (newOptions) {
        // console.log(newOptions.length);
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      }
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  // console.log('Select ->>>>>>>>>', props.value);

  return (
    <Select
      {...props}
      labelInValue
      showSearch
      autoFocus
      dataList
      id={`custom-ticker-search-select-dropdown`}
      dropdownClassName="custom-ticker-search-box-dropdown"
      dropdownMenuStyle="custom-ticker-search-box-dropdown-menu-style"
      // ref={props.getRef}
      // options={options}
      filterOption={false}
      onSearch={debounceFetcher}
      onChange={props.onChange}
      placeholder={props.placeHolder}
      loading={props.realTimePriceLoading || false}
      listHeight={props.searchDropdownHeight || 160}
      getPopupContainer={() => document.getElementById(`${props.searchDropdownTargetElement ? props.searchDropdownTargetElement : 'custom-ticker-search-box-wrapper'}`)}
      notFoundContent={fetching === true ? <Spin size="small" /> : ((fetching === null) ? null : props.notFoundContent)}
      suffixIcon={props.showSearchIcon ? <i class="far fa-fw fa-search fa-lg text-secondary search-icon"></i> : null}
      clearIcon={<i id={`custom-ticker-search-box-clear-element`} style={{ display: 'none' }}></i>}
    >
      {options.map((kl, ki) => {
        return (
          <Option key={kl.label+ki} value={kl.value} className="custom-ticker-search-list-option">
            <span className="option-ticker" style={{ width: colWidthObj['ticker'] }}>{kl.label}</span>
            <span className="option-ticker-name" style={{ width: `calc(100% - ${colWidthObj['ticker']})` }}>{kl.name}</span>
          </Option>
        )
      })}
    </Select>
  );
} // Usage of DebounceSelect

// {options.map((kl, ki) => {
//   return (
//     <Option key={kl.label+ki} value={kl.value}>{kl.label} - {kl.name}</Option>
//   )
// })}

// dropdownRender={menu => (
//   <div>
//     {(menu && menu.length > 0) && menu}
//     {(menu && menu.length === 0) && props.notFoundContent}
//   </div>
// )}

import React from 'react'
import { connect } from 'react-redux'
import { ButtonGroup } from 'reactstrap'
import { PrimaryButton } from 'shared-components'
import paymentSuccessImage from '../../../../../assets/images/payment-success.png'

import { createSubscriptionRequest } from 'actions/profile'
import config from 'env';

class PaymentFailureMessage extends React.Component {
  constructor(props) {
    super(props)
    this.props.createSubscriptionRequest();
    this.subscribeNow = this.subscribeNow.bind(this)
  }

  subscribeNow() {
    const sessionId = this.props.stripeSessionID;
    if(sessionId){
      this.stripe = window.Stripe(config.stripePublishableKey);
      this.stripe.redirectToCheckout({
        sessionId
      }).then((result) => {
        // console.log("results status",result)
      });
    }   
  }

  render() {
    return (
      <React.Fragment>
        <div className="premium-feature-process-modal-container payment-status-modal">
          <div className="payment-message-image text-align-center">
            <img src={paymentSuccessImage} alt="Magnifi"/>
          </div>
          <div className="payment-status-heading">
            <h1 className="text-align-center payment-failure">Payment Failed!</h1>
          </div>
          <i className="fal fa-times-circle premium-feature-completed-close close-light" onClick={this.props.closePaymentStatus}></i>
          <p className="text-align-center mb-0">
            Some error occured. Please try again.
          </p>
          {/*<ButtonGroup className="cta-wrapper justify-content-center button-blue">
            <PrimaryButton className="btn secondary-btn" type="button" onClick={this.subscribeNow} style={{ maxWidth: 160, marginTop: 30, marginBottom: 0 }}>
              TRY AGAIN
            </PrimaryButton>
          </ButtonGroup>*/}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ profile }) => ({
  stripeSessionID: profile.stripeSessionID
})

const mapDispatchToProps = {
  createSubscriptionRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentFailureMessage);

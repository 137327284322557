import React from 'react';
// import { getPremiumFeatureDetails } from 'apis/test-drive';
// import PremiumNoticeModal from 'components/PremiumNoticeModal';
import { calcTestDriveKey, calculatorOptions, cn, flipMoveElevator } from 'DataSet';
import FlipMove from 'react-flip-move';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import { addEventToAnalytics, dateToMonth } from 'Utils';
import { AttrCharts } from '../Common';
import DeleteSelectorModal from '../DeleteSelectorModal';
// import CompareSm from '../SmallScreen/Compare';
import WeightChangeModal from '../WeightChangeModal';
import ItemCard from './ItemCard';
import Chart from './Chart';
import { Radio, Input, Menu, Dropdown } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import PButton from 'components/PButton';

export default class ItemList extends React.Component {
  constructor(props) {
    super(props);
    this.onCalculatorChange = this.onCalculatorChange.bind(this);
  }

  state = {
    items: this.props.items,
    displayYear: '',
    premiumModal: false,
    notify: false,
    premiumFeature: 'false',
    calculatorName: null,
    featureName: 'my_calculator',
    testDriveStatus: {},
    selectorName: '',
    deleteSelectorToggle: false,
    selectorDropdownVisible: false,
  }

  componentDidMount() {
    const elems = document.querySelectorAll('.custom-scroll');
    if (elems.length) {
      [].forEach.call(elems, function (el) {
        if (el.scrollHeight > el.clientHeight) el.style.paddingRight = '.25rem';
        else el.style.paddingRight = '.25rem';
      });
    }
  }

  sortFn = (a, b) => {
    let [_a, _b] = [a._sfStats, b._sfStats]
    if (_a && _b) {      // descending order
      if (_a.score < _b.score) return 1;
      else if (_a.score > _b.score) return -1;
    }
    return 0;
  }

  displayYear = (data) => {
    if (data !== this.state.displayYear) {
      this.setState({
        displayYear: data,
      });
    }
  }

  onCalculatorChange(e, setDefault = false) {
    const name = setDefault ? e.target.value : (e.key || e);
    // console.log(name);
    // const featureName = calcTestDriveKey[name] || 'my_calculator';
    this.setState({
      calculatorName: name,
    }, () => {
      this.switchCalculator(name, setDefault);
    })
  }

  switchCalculator(name, setDefault) {
    const { funds } = this.props;
    // const { featureName, testDriveStatus } = this.state;
    addEventToAnalytics('Compare select comparison criteria', 'Compare select comparison criteria', 'COMPARE_SELECT_COMPARISON_CRITERIA', { query: funds.query, logId: funds.logId, value: name }, true);
    this.toggleSelectorDropdownState();
    this.props.changeCalculator({ name, setDefault });
    this.setState({ selectorName: name });
  }

  deleteCalculator = (e, calc) => {
    e.stopPropagation();
    this.setState({
      selectorName: calc,
    },
      () => this.toggleDeleteModal(true));
  }

  toggleDeleteModal = (val) => {
    this.setState({
      deleteSelectorToggle: val,
    })
  }

  toggleSelectorDropdownState = () => {
    this.setState({
      selectorDropdownVisible: !this.state.selectorDropdownVisible,
    })
  }

  render() {
    const { selectedCalculator, scoreAttrs, premiumUser, customCalculators, defaultCalculator, commonActiveSFMReturnsRange } = this.props;
    const { premiumModal, notify, featureName, selectorName, deleteSelectorToggle, selectorDropdownVisible } = this.state;
    const items = this.props.items.slice().sort(this.sortFn);
    const filterScoreattrs = (selectedCalculator !== calculatorOptions.magnifiactivescreener)
      ? scoreAttrs.filter(x => x.weight !== 0) : scoreAttrs;
    // const { dropdownOpen } = this.state;
    const isMagnifiactivescreener = selectedCalculator === calculatorOptions.magnifiactivescreener;

    const dropdownSinceDate = (commonActiveSFMReturnsRange && commonActiveSFMReturnsRange.start && commonActiveSFMReturnsRange.end) ? 'Since ' + dateToMonth(commonActiveSFMReturnsRange.start) : null;
    // console.log(dropdownSinceDate);
    if (this.props.shareCompareState) {
      this.props.shareCompareState(this.state);
    }

    const SelectorMenus = (
      <div>
        {process.env.REACT_APP_SUB_DOMAIN === 'advisor' && <div className="py-1 set-default-label">
          <span>Set Default</span>
          <i id="set-default-tooltip" class="fal fa-info-circle tooltip-icon" />
          <UncontrolledTooltip placement="right" target={'set-default-tooltip'}>
            Select a default fund selector.
            You can use this in queries
            to get customized results e.g.
            "Emerging market funds using my fund selector"
          </UncontrolledTooltip>
        </div>}
        <div className="selector-scroll">
          <PButton
            type="menu"
            mapFor="selector"
            featureName={featureName}
            premiumAction={true}
            onClick={(e) => this.onCalculatorChange(e)}
            selectedKeys={[selectedCalculator]}
          >
            {Object.keys(calculatorOptions).map(name => {
              if (process.env.REACT_APP_SUB_DOMAIN === 'retail' && calculatorOptions[name] !== "Magnifi fund selector") {
                return null;
              }
              return (
                <Menu.Item
                  className="py-1"
                  key={calculatorOptions[name]}
                  disabled={(calculatorOptions[name] === calculatorOptions.sponsorcalculator) || (selectedCalculator === calculatorOptions[name])}
                >
                  <Radio
                    className="selector-radio"
                    value={calculatorOptions[name]}
                    checked={calculatorOptions[name] === defaultCalculator}
                    onChange={(e) => this.onCalculatorChange(e, true)}
                  />
                  <span className="selector-name">{calculatorOptions[name]}</span>
                </Menu.Item>
              )
            })}
            {Object.keys(customCalculators).map(item => {
              return (
                <Menu.Item disabled={selectedCalculator === item} key={item} className="py-1 selector-options">
                  <Radio
                    className="selector-radio"
                    value={item}
                    checked={item === defaultCalculator}
                    onChange={(e) => this.onCalculatorChange(e, true)}
                  />
                  <span className="selector-name">{item}</span>
                  <div className="delete-selector" onClick={(e) => this.deleteCalculator(e, item)}>
                    <i class="fas fa-trash-alt"></i>
                  </div>
                </Menu.Item>
              )
            })
            }
          </PButton>
        </div>
        {process.env.REACT_APP_SUB_DOMAIN === 'advisor' && <PButton
          type="menu"
          mapFor="selector"
          featureName="create custom selector"
          premiumAction={true}
          onClick={() => this.onCalculatorChange('create custom selector')}
        >
          <Menu.Divider />
          <Menu.Item key="create custom selector" className="py-1" style={{ marginTop: 3 }}>
            <div className="selector-options">
              create custom selector
            </div>
          </Menu.Item>
        </PButton>}
      </div>
    );

    return (
      <div>
        <DeleteSelectorModal
          modalToggle={deleteSelectorToggle}
          selectorName={selectorName}
          toggleDeleteModal={this.toggleDeleteModal}
        />
        <Row className="no-gutters">
          <Col className="col-12 text-center" style={{ padding: '1rem 1rem 10px 1rem' }}>
            <div id="selector-dropdown" className="d-flex align-items-center justify-content-center multiple-calculator">
              <h4 className="mb-0" style={{ lineHeight: '26px' }}><strong>Identify</strong> the best selection using </h4>
              <Dropdown
                overlay={SelectorMenus}
                //visible={selectorDropdownVisible}
                trigger={['click']}
                getPopupContainer={() => document.getElementById('selector-dropdown')}
              //onClick={this.toggleSelectorDropdownState}
              >
                <a className="ant-dropdown-link h6 my-1 view-btn" onClick={e => e.preventDefault()}>
                  {selectedCalculator || 'selected comparison criteria'} <CaretDownOutlined className="down-arrow" />
                </a>
              </Dropdown>
            </div>
          </Col>
          <Col className="col-6 pl-2 pr-1 h-100">
            <h5 className="mb-0 text-center">
              <div className="w-95 mx-auto pt-2 pb-1 rounded-top">Reorder to reflect what's important to you
                {(selectedCalculator !== calculatorOptions.magnificalculator) &&
                  <WeightChangeModal
                    {...this.props}
                    scoreAttrs={scoreAttrs}
                    premiumUser={this.state.premiumFeature === 'true' ? true : this.props.premiumUser}
                    scoreAttrsWeightHandler={this.props.scoreAttrsWeightHandler}
                    openModal={selectorName === 'create custom selector'}
                  />
                }
              </div>
            </h5>
            <div className="custom-scroll" style={{ height: 360 }}>
              <div className="bg-secondary rounded" style={{ paddingTop: '2px' }}>
                <AttrCharts
                  tab="Compare"
                  funds={this.props.funds}
                  scoreAttrs={scoreAttrs}
                  dataItems={items.filter((kl) => kl.type !== "SHARE")}
                  range={this.props.range}
                  dateRange={this.props.dateRange}
                  rangeHandler={this.props.rangeHandler}
                  displayYear={this.displayYear}
                  dropdownScoreAttrs={this.props.dropdownScoreAttrs}
                  scoreAttrsHandler={this.props.scoreAttrsHandler}
                  scoreAttrsHandler2={this.props.scoreAttrsHandler2}
                  filterScoreattrs={filterScoreattrs}
                  isMagnifiactivescreener={isMagnifiactivescreener}
                  commonActiveSFMReturnsRange={commonActiveSFMReturnsRange}
                  sinceDate={dropdownSinceDate}
                  chart={Chart}
                />
              </div>
            </div>
          </Col>
          <Col className="col-6 pl-1 pr-1 h-100">
            <h5 className="mb-0 text-center">
              <div className="w-75 mx-auto pt-2 pb-1 rounded-top">Best selection to reflect your preferences</div>
            </h5>
            <div className="custom-scroll" style={{ height: 360 }}>
              <div className="bg-secondary rounded" style={{ paddingTop: '2px' }}>
                <FlipMove
                  {...flipMoveElevator}
                >
                  {items.map((e, i) => {
                    return (
                      <div key={cn(e, 'Id')}>
                        <ItemCard
                          card={e}
                          funds={this.props.funds}
                          idx={i}
                          clearHandler={this.props.clearHandler}
                          buyHandler={this.props.buyHandler}
                          actionHandler={this.props.actionHandler}
                          selectedCalculator={selectedCalculator}
                        />
                      </div>
                    )
                  })}
                </FlipMove>
                {this.props.loading &&
                  <div className="overlay active">
                    <div className="text rounded">
                      <i className="fal fa-circle-notch fa-3x fa-spin text-gray-5"></i>
                      <h5 className="mt-2 mb-0">Comparing your selections</h5>
                    </div>
                  </div>}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

// absolute imports
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import CountUp from 'react-countup';
import StatItems from './StatItems';
import { kmb } from 'DataSet';
import { valuesToKmbFormat } from 'Utils';

class Stats extends React.Component {
  constructor(props) {
    super(props);

  }


  sponsorCount = (spl) => {
    let count = 0;
    if (Object.keys(spl).length) {
      Object.keys(spl).forEach((el, index) => {
        Object.keys(spl[el]).forEach((e, i) => {
          count += spl[el][e].length;
        })
      })
    }
    return count;
  }

  getTotalSum = (data) =>{
    let sum_totalAsset=0;
    if(data && data.length){
     data.forEach((ele)=>{
        if(ele){
          sum_totalAsset+=ele['portfolio_value'];
        }
      });
    }
    return sum_totalAsset;
  }

  render() {
    let { clientList, sponsoredPortfolioList, searchResultsData, totalAccounts, totalAssets, isSearchQueryBased, pagination, searchResultTotalAssets = 0, dynamicContent, isDynamicQuery, filter, currentTotalAssets, currentTotalAccounts } = this.props;
    const { clientUploadModalToggle } = this.props;

    // const totalAsset = initClientList && this.getTotalSum(initClientList);
    // let [totalValue, updatedAsset] = searchResultsData ? (clientList.length
    //   ? [searchResultsData['client_list'].length, this.convertAmount(this.getTotalSum(searchResultsData['client_list'])).toString()]
    //   : [0,this.convertAmount(0).toString()]
    // ) : [clientList.length, this.convertAmount(this.getTotalSum(clientList)).toString()];

    // let [totalValue, updatedAsset] = [pagination.totalListData, valuesToKmbFormat({ num: totalAssets, returnType: 'Object' })];

    // const CHAR_REGEX =  /^[a-zA-Z]+$/; //new RegExp("^[a-zA-Z]+$");
    // const NUMERIC_REGEX = /[+-]?([0-9]*[.])?[0-9]+/;
    // // const numbers = '2.2px 3.1px 4px -7.6px obj.key'.match(NUMERIC_REGEXP)
    // // console.log(numbers); // ["2.2", "3.1", "4", "-7.6"]
    //
    // let suffix = updatedAsset && updatedAsset.length > 1 ? updatedAsset[updatedAsset.length-1] : '';

    // // let updatedAssetNumber = updatedAsset && Number(updatedAsset.substring(0,updatedAsset.length-1));
    // console.log('updatedAsset -> ', updatedAsset);
    // // updatedAsset = updatedAsset.toString();
    // //
    // console.log(CHAR_REGEX.test(updatedAsset), updatedAsset.match(CHAR_REGEX));
    //
    // // let suffix = updatedAsset.match(CHAR_REGEX);
    //
    // // console.log('suffix -->', suffix);

    // let updatedAssetNumber = updatedAsset.match(NUMERIC_REGEX);
    // // console.log('updatedAssetNumber ->', updatedAssetNumber);
    // updatedAssetNumber = Array.isArray(updatedAssetNumber) && updatedAssetNumber.length > 0 ? updatedAssetNumber[0] : updatedAssetNumber;
    //
    // // console.log('searchResultTotalAssets -->', searchResultTotalAssets);
    // if (isSearchQueryBased) {
    //   totalValue = pagination.totalListData;
    //   updatedAssetNumber = valuesToKmbFormat({ num: searchResultTotalAssets });
    //   console.log('updatedAssetNumber ->', updatedAssetNumber);
    //   suffix = updatedAssetNumber && updatedAssetNumber.length > 1 ? updatedAssetNumber[updatedAssetNumber.length-1] : '';
    //   console.log('suffix -->', suffix);
    //
    //   updatedAssetNumber = updatedAssetNumber.match(NUMERIC_REGEX);
    //   updatedAssetNumber = Array.isArray(updatedAssetNumber) && updatedAssetNumber.length > 0 ? updatedAssetNumber[0] : updatedAssetNumber;
    //
    //   // updatedAssetNumber = updatedAssetNumber && Number(updatedAssetNumber.substring(0,updatedAssetNumber.length-1));
    // }

    let [totalValue, updatedAsset] = [pagination.totalListData, valuesToKmbFormat({ num: totalAssets, returnType: 'Object' })];

    let suffix = updatedAsset.suffix || '';
    let prefix = updatedAsset.prefix || '';
    let updatedAssetNumber = typeof updatedAsset.value !== 'undefined' ? updatedAsset.value : updatedAsset;

    // console.log('currentTotalAccounts -> ', currentTotalAccounts);
    // console.log('currentTotalAssets -> ', currentTotalAssets);
    if (isSearchQueryBased) {
      totalValue = pagination.totalListData;
      let conversionObject = valuesToKmbFormat({ num: searchResultTotalAssets, returnType: 'Object' });
      suffix = conversionObject.suffix || '';
      updatedAssetNumber = (typeof conversionObject.value !== 'undefined') ? conversionObject.value : conversionObject;
    } else if (filter && filter.key !== '' && filter.query !== '') {
      totalValue = pagination.totalListData;
      totalAccounts = currentTotalAccounts; // pagination.totalListData;
      let conversionObject = valuesToKmbFormat({ num: totalAssets, returnType: 'Object' });
      suffix = conversionObject.suffix || '';
      updatedAssetNumber = (typeof conversionObject.value !== 'undefined') ? conversionObject.value : conversionObject;
      // NOTE: After assigning 'totalAssets' to 'updatedAssetNumber', assign 'currentTotalAssets' -> 'totalAssets'
      totalAssets = currentTotalAssets;
    }

    const StatItemsArr = [
      {
        icon: '/assets/images/AccountVector.svg',
        title: 'Accounts',
        kmbConversionEnable: false,
        totalValue: totalAccounts,
        value: <CountUp end={totalValue || 0} />,
        strokeColor: '#E6C1EF',
        strokePercent: parseInt((totalValue/totalAccounts)*100)

      },
      {
        suffix,
        icon: '/assets/images/TotalAssetVector.svg',
        title: 'Total Assets affected',
        unit: prefix,
        kmbConversionEnable: true,
        totalValue: valuesToKmbFormat({ num: totalAssets }),
        value: <CountUp decimal={'.'} start={0} end={updatedAssetNumber || 0} />,
        strokeColor: '#A5D6FA',
        strokePercent: updatedAsset.value ? parseInt((updatedAssetNumber/updatedAsset.value)*100) : 0
      }
    ];

    return (
      <div className="stats-wrapper">
        <Row>
          <Col xs={12}>
            <StatItems items={StatItemsArr} clientUploadModalToggle={clientUploadModalToggle} isDynamicQuery={isDynamicQuery} dynamicContent={dynamicContent}/>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStatetoProps = ({ clientportfolioV2: { sponsoredPortfolioList, searchResultsData, totalAssets, totalAccounts, downloadedList: { data, filter, pagination, isSearchQueryBased, searchResultTotalAssets }, _currentTotalAssets, _currentTotalAccounts } }) => ({
  clientList: data,
  filter,
  currentTotalAssets: _currentTotalAssets,
  currentTotalAccounts: _currentTotalAccounts,
  pagination,
  totalAssets,
  totalAccounts,
  searchResultsData,
  isSearchQueryBased,
  sponsoredPortfolioList,
  searchResultTotalAssets,
})

export default connect(
  mapStatetoProps
)(Stats);

/* eslint-disable max-classes-per-file */
import React, { PureComponent, Fragment, useEffect, useState } from 'react';
import { Treemap, ResponsiveContainer, Tooltip } from 'recharts';
import { convertToFloatvalues } from 'Utils';

// const data = [
//   { name: 'FisheyeTreeFilter', size: 5219, price: '$'+50 },
//   { name: 'GraphDistanceFilter', size: 3165, price: '$'+50 },
//   { name: 'VisibilityFilter', size: 3509, price: '$'+50 },
//   { name: 'IOperator', size: 1286, price: '$'+50 },
//   { name: 'Labeler', size: 9956, price: '$'+50 },
//   { name: 'RadialLabeler', size: 3899, price: '$'+50 },
//   { name: 'StackedAreaLabeler', size: 3202, price: '$'+50 },
//   { name: 'AxisLayout', size: 6725, price: '$'+50 },
//   { name: 'BundledEdgeRouter', size: 3727, price: '$'+50 },
//   { name: 'CircleLayout', size: 9317, price: '$'+50 },
//   { name: 'CirclePackingLayout', size: 12003, price: '$'+50 },
//   { name: 'DendrogramLayout', size: 4853, price: '$'+50 },
//   { name: 'ForceDirectedLayout', size: 8411, price: '$'+50 },
//   { name: 'IcicleTreeLayout', size: 4864, price: '$'+50 },
//   { name: 'IndentedTreeLayout', size: 3174, price: '$'+50 },
//   { name: 'Layout', size: 7881, price: '$'+50 },
//   { name: 'NodeLinkTreeLayout', size: 12870, price: '$'+50 },
//   { name: 'PieLayout', size: 2728, price: '$'+50 },
//   { name: 'RadialTreeLayout', size: 12348, price: '$'+50 },
//   { name: 'RandomLayout', size: 870, price: '$'+50 },
//   { name: 'StackedAreaLayout', size: 9121, price: '$'+50 },
//   { name: 'TreeMapLayout', size: 9191, price: '$'+50 },
//   { name: 'Operator', size: 2490, price: '$'+50 },
//   { name: 'OperatorList', size: 5248, price: '$'+50 },
//   { name: 'OperatorSequence', size: 4190, price: '$'+50 },
//   { name: 'OperatorSwitch', size: 2581, price: '$'+50 },
//   { name: 'SortOperator', size: 2023, price: '$'+50 },
// ];

const COLORS = ['#1e5f91'];

export class CustomizedContent extends PureComponent {
  render() {
    const { root, depth, x, y, width, height, index, payload, colors, rank, name, size, price, onOthersClick, hash, treeMapData } = this.props;
    // console.log(this.props);
    // colors[Math.floor((index / root.children.length) * 6)]
    // let _r = treeMapData.length < 15 ? (15/2) : 15;
    // console.log('=== depth ===', depth);
    // {depth === 1 ? (
    //   <text x={x + width / 2} y={y + height / 2 + 7} textAnchor="middle" fill="#fff" fontSize={14}>
    //     {name}
    //   </text>
    // ) : null}
    // {depth === 1 ? (
    //   <text x={x + 4} y={y + 18} fill="#fff" fontSize={16} fillOpacity={0.9}>
    //     {index + 1}
    //   </text>
    // ) : null}
    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill: getFromDefinedColours(index, treeMapData),
            stroke: '#fff',
            strokeWidth: 6 / (depth + 1e-10),
            strokeOpacity: 1, //1 / (depth + 1e-10),
          }}
          onClick={(onOthersClick && name === 'Others') ? onOthersClick : null}
        />
        {(name !== 'Initial') ? (
          <text x={x + width / 2} y={y + height / 2 + 7} className="tree-map-chart-legends-wrapper" textAnchor="middle" fontSize={10} style={{ stroke: '#4a4a4a' }}>
            <tspan width={width} height={height} style={{ pointerEvents: 'none' }}>{name}</tspan>
            {/* <tspan>{size}</tspan> */}
          </text>
        ) : null}
        {/*(name !== 'Initial') ? (
          <text x={x + width / 2} y={y + height / 2 + 7} className="tree-map-chart-legends-wrapper" textAnchor="middle" fill="#fff" fontSize={10}>
            <tspan width={width} height={height}>{price}</tspan>
          </text>
        ) : null */}
      </g>
    );
  }
}

export class TreeMapChart extends PureComponent {
  render() {
    const { treeMapData, onOthersClick, backButtonVisible, onBackClick } = this.props;
    if (!treeMapData) return;

    return (
      <Fragment>
        {backButtonVisible && (
          <div className="btn-link tree-map-chart-back-button" onClick={onBackClick ? onBackClick : null}>back</div>
        )}
        <ResponsiveContainer
          width="100%"
          height="100%"
          className="tree-map-chart-container"
        >
          <Treemap
            width={400}
            height={200}
            data={treeMapData}
            dataKey="size"
            ratio={4 / 3}
            stroke="#fff"
            fill="#9ECAEE"
            content={<CustomizedContent treeMapData={treeMapData} onOthersClick={(onOthersClick) ? onOthersClick : null} />}
          >
            <Tooltip content={<CustomTooltip />} cursor={false} isAnimationActive={false} />
          </Treemap>
        </ResponsiveContainer>
      </Fragment>
    );
  }
}

const CustomTooltip = (props) => {
  const { active, label } = props;
  
  const [holdings, setHoldings] = useState([])

  useEffect(() => {
    setHoldings(props.payload.map(data => ({
      name: data.payload.full_name,
      ticker: data.payload.name,
      weight: `${data.payload.value.toFixed(2)}%`,
      shares_held: (convertToFloatvalues(data.payload.shares) || 0).toFixed(5),
      value_held: (convertToFloatvalues(data.payload.market_value) || 0).toFixed(2)
    })))
  }, [props])

  if (active && holdings) {
    return (
      <div className="recharts-default-tooltip ssf-tooltip-custom">
        <div className="label">{label}</div>
        { holdings.map((holding, index) => {
          if (holding) {
            if(holding.ticker !== 'Others'){
              return (
                <div key={index} className="d-flex flex-column p-1" style={{ color: "#4f5254" }}>
                  <span style={{ color: "#56a9e8" }}>{holding.name}</span>
                  <div>
                    <b>{holding.ticker} </b>
                    <b>{holding.weight}</b>
                  </div>
                  <div>
                    <span>Quantity: </span>
                    <b>{holding.shares_held}</b>
                  </div>
                  <div>
                    <span>Value: </span>
                    <b>${holding.value_held}</b>
                  </div>
                </div>
              )
            } else {
              return (
                <div key={index} className="d-flex flex-column p-1" style={{ color: "#4f5254" }}>
                  <span style={{ color: "#56a9e8" }}>Click for more details</span>
                </div>
              )
            }
          }
        }) }
      </div>
    );
  }

  return null;
}

export default {
  TreeMapChart,
}

const getFromDefinedColours = (percent, treeMapData) => {
  let colorsObj = {
    0: '#9ecaee',
    1: '#a8cff0',
    2: '#a8cff0',
    3: '#a8cff0',
    4: '#b1d5f1',
    5: '#b1d5f1',
    6: '#b1d5f1',
    7: '#bbdaf3',
    8: '#c5dff5',
    9: '#c5dff5',
    10: '#cfe5f7',
    11: '#d8eaf8',
    12: '#d8eaf8',
  }
  return colorsObj[percent] || '#d8eaf8';
}

const getColorShade = (color, percent) => {
  let R = parseInt(color.substring(1,3),16);
  let G = parseInt(color.substring(3,5),16);
  let B = parseInt(color.substring(5,7),16);

  R = parseInt(R * (100 + percent) / 100);
  G = parseInt(G * (100 + percent) / 100);
  B = parseInt(B * (100 + percent) / 100);

  R = (R<255)?R:255;
  G = (G<255)?G:255;
  B = (B<255)?B:255;

  var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
  var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
  var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

  return "#"+RR+GG+BB;
}

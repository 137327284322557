import React from 'react';
import { useState, Fragment } from 'react';

import { Button, Card, CardBody, CardHeader, Collapse, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import FlipMove from 'react-flip-move';

import { cn, calculatorOptions, calcTestDriveKey } from 'DataSet';
import { AttrCharts } from '../Common';
import { ItemCard, Chart } from '../Compare';
import WeightChangeModal from '../WeightChangeModal';
import PremiumNoticeModal from 'components/PremiumNoticeModal';
import { addEventToAnalytics } from 'Utils';
import { getPremiumFeatureDetails } from '../../../apis/test-drive';

import './_index.scss'
const clx = require('classnames');

const CompareSm = (props) => {
  const [active, setActive] = useState(0);

  const toggle = e => setActive(Number(e.target.dataset.id))
  return (
    <div className="page">
      <div className="page-header">
        <strong>Identify</strong> the best selection using
        <OptCaclSelect
          onCalculatorChange={props.onCalculatorChange}
          selectedCalculator={props.selectedCalculator}
          premiumUser={props.premiumUser}
          premiumFeature={props.premiumFeature}
          modalAction={props.modalAction}
          premiumModal={props.premiumModal}
          closePremiumModal={props.closePremiumModal}
          featureName={props.featureName}
          customCalculators={props.customCalculators}
          deleteCalculator={props.deleteCalculator}
          {...props}
        />
      </div>
      <div className="page-content">
        <div class="accordion">
          <Card className={clx("accordion-card", { 'active': active === 0 })}>
            <CardHeader onClick={toggle} data-id={0}>
              Reorder to reflect what's important to you
              {
                (props.selectedCalculator !== calculatorOptions.magnificalculator) &&
                <WeightChangeModal
                  {...props}
                  scoreAttrs={props.scoreAttrs}
                  premiumUser={props.premiumFeature === 'true' ? true : props.premiumUser}
                />
              }
            </CardHeader>
            <Collapse className="accordion-collapse" isOpen={active === 0}>
              <CardBody>
                <Charts {...props} />
              </CardBody>
            </Collapse>
          </Card>
          <Card className={clx("accordion-card", { 'active': active === 1 })}>
            <CardHeader onClick={toggle} data-id={1}>Best selection to reflect your preferences</CardHeader>
            <Collapse className="accordion-collapse" isOpen={active === 1}>
              <CardBody className="p-0">
                <div class="main">
                  <Funds {...props} />
                </div>
                <Footer {...props} />
              </CardBody>
            </Collapse>
          </Card>
        </div>
      </div>
    </div>
  );
}
export default CompareSm;


const Funds = React.memo((props) => (
  <div>
    <div className="bg-secondary rounded" style={{ paddingTop: '2px' }}>
      <FlipMove>
        {props.items.map((e, i) => {
          return (
            <div key={cn(e, 'Id')}>
              <ItemCard card={e} funds={props.funds} idx={i} clearHandler={props.clearHandler} buyHandler={props.buyHandler} actionHandler={props.actionHandler} selectedCalculator={props.selectedCalculator} />
            </div>
          )
        })}
      </FlipMove>
      {props.loading && <Spinner />}
    </div>
  </div>
))


const Charts = React.memo((props) => (
  <div className="mb-3">
    <div className="bg-secondary rounded" style={{ paddingTop: '2px' }}>
      <AttrCharts
        tab="Compare"
        funds={props.funds}
        scoreAttrs={props.scoreAttrs}
        dataItems={props.items}
        range={props.range}
        dateRange={props.dateRange}
        rangeHandler={props.rangeHandler}
        displayYear={props.displayYear}
        chart={Chart}
        scoreAttrsHandler={props.scoreAttrsHandler}
        scoreAttrsHandler2={props.scoreAttrsHandler2}
        filterScoreattrs={props.filterScoreattrs} />
    </div>
  </div>
))

const OptCaclSelect = ({
  onCalculatorChange,
  selectedCalculator,
  premiumUser,
  premiumFeature,
  modalAction,
  featureName,
  premiumModal,
  closePremiumModal,
  customCalculators,
  deleteCalculator,
  props
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notify, setNotify] = useState(false);

  const toggle = e => {
    e.stopPropagation();
    setDropdownOpen(prevState => !prevState);
  }

  const onCalculatorSelection = (optName) => {
    onCalculatorChange(optName);
  }

  const openNotify = () => {
    setNotify(!notify);
  }

  const notifyToggle = () => {
    setNotify(!notify);
  }

  // const closePremiumModal = () => {
  //   setPremiumModal(!premiumModal);
  // }

  const premiumModalToggle = () => {
    closePremiumModal(!premiumModal)
  }

  return (
    <div className="multiple-calculator">
      <PremiumNoticeModal
        {...props}
        open={premiumModal}
        premiumUser={premiumUser}
        onClickCancel={closePremiumModal}
        onToggle={premiumModalToggle}
        notify={notify}
        openNotify={openNotify}
        notifyToggle={notifyToggle}
        premiumFeature={premiumFeature}
        modalActionType={featureName}
        modalAction={() => modalAction()}
        featureName={featureName}
      />
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret tag="span" className="mydropdown">{selectedCalculator}</DropdownToggle>
        <DropdownMenu>
          {Object.keys(calculatorOptions).map((name, index) => {
            if (process.env.REACT_APP_SUB_DOMAIN === 'retail' && calculatorOptions[name] !== "Magnifi fund selector") {
              return null;
            }
            return (
              <DropdownItem
                key={index}
                className="py-1"
                //onClick={() => onCalculatorChange(calculatorOptions[name])}
                onClick={() => onCalculatorSelection(calculatorOptions[name])}
                disabled={
                  calculatorOptions[name] === calculatorOptions.sponsorcalculator
                }
                style={{ cursor: 'pointer' }}
              >
                {calculatorOptions[name]}
              </DropdownItem>
            )
          })}
          {Object.keys(customCalculators).map((item, index) => {
            return (
              <DropdownItem
                key={index}
                className="py-1"
                style={{ cursor: 'pointer' }}
              >
                <div className="selector-options">
                  <span
                    onClick={() => onCalculatorSelection(item)}
                    className="selector-name"
                  >
                    {item}
                  </span>
                  <span
                    className="delete-selector"
                    onClick={() => deleteCalculator(item)}
                  >
                    <i class="fas fa-trash-alt"></i>
                  </span>
                </div>
              </DropdownItem>
            )
          })}

          {process.env.REACT_APP_SUB_DOMAIN === 'advisor' &&
            <Fragment>
              <DropdownItem divider />
              <DropdownItem
                className="py-1"
                onClick={() => onCalculatorSelection('create custom selector')}
                style={{ cursor: 'pointer' }}
              >
                <div className="selector-options">
                  <span>create custom selector</span>
                </div>
              </DropdownItem>
            </Fragment>
          }
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

const Footer = ({ actionHandler, buyHandler }) => {
  const _actionHandler = () => actionHandler('combine');

  return (
    <div className="footer d-flex justify-content-center">
      <Button color="select" outline
        className="btn h4 mb-0 mx-3 text-uppercase font-weight-bold modal-btn-br"
        title="Evaluate addition to portfolios"
        onClick={() => {
          _actionHandler();
          addEventToAnalytics('Compare SFM Footer Button', 'Compare SFM Footer Button', 'COMPARE_SFM_FOOTER_BUTTON', { clickBtn: 'Add All', category: 'advisory_PTC' }, true);
        }}>Add All</Button>

      <Button color="select" outline
        className="btn h4 mb-0 mx-3 text-uppercase font-weight-bold modal-btn-br"
        onClick={() => {
          buyHandler('all');
          addEventToAnalytics('Compare SFM Footer Button', 'Compare SFM Footer Button', 'COMPARE_SFM_FOOTER_BUTTON', { clickBtn: 'Buy All', category: 'advisory_PTC' }, true);
        }}>Buy All</Button>
    </div>
  )
}


export const Spinner = () => (
  <div className="overlay active">
    <div className="text rounded">
      <i className="fal fa-circle-notch fa-3x fa-spin text-gray-5"></i>
      <h5 className="mt-2 mb-0">Comparing your selections</h5>
    </div>
  </div>
)

import React from 'react';

import { extent as d3Extent } from 'd3-array';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import { ProfitLossColors } from 'data/Colors'
import { getNestDefault } from 'Utils';


/*
  series: [ { name: 'S1', data: [{ d: 'Mar 2017', cuml: 1.23 }, ... ], color: '#bbb' }, ... ],
  xkey: 'd',
  ykey: 'cuml',
  ycentered: true,
*/
export const MultiSeriesLineChart = (props) => {
  const { series, xkey, ykey, ycentered } = props;
  let ydomain = ['auto', 'auto'];

  if (ycentered){
    const ranges = series.reduce((acc, s) => acc.concat(d3Extent(s.data, e => e[ykey])), []);
    const [min, max] = d3Extent(ranges);                  // ydomain across all series
    let max2 = Math.max(Math.abs(min), Math.abs(max));
    ydomain = [-max2, max2];
  }

  const colorfulLegendText = (value, entry) => {
  	const { color } = entry;
    return <span style={{ color, fontSize: 11 }}>{value}</span>;
  }

	return (
    <ResponsiveContainer width="100%" height="100%" >
      <LineChart margin={{top: 8, right: 8, left: 30, bottom: 8}}>

        <ReferenceLine y={0} stroke="#bbb" shapeRendering="crispEdges"
          label={<ReferenceLineCustomLabel value={'$100'} stroke='#666' />} />
        <XAxis dataKey={xkey} tick={false} hide={true} type="category" allowDuplicatedCategory={false} />
        <YAxis hide={true} domain={ydomain} />

        { series.map(s => (
          <Line dataKey={ykey} data={s.data} name={s.name} key={s.name}
            type="monotone" unit="%" stroke={s.color} strokeWidth={1}
            dot={false} activeDot={{r: 3, strokeWidth: 1}}
            isAnimationActive={false} />
        ))}

        <Tooltip content={<CustomTooltip />} cursor={{ stroke: '#666', strokeWidth: 1, strokeDasharray: "2 2" }}/>
        <Legend iconType="rect" formatter={colorfulLegendText} wrapperStyle={{ paddingTop: "5px" }} />
      </LineChart>
    </ResponsiveContainer>
  );
}

const CustomTooltip = (props) => {
  const { active, payload, label, vkey } = props;

  if (active && payload) {
    const key = vkey ? ['payload', vkey] : ['value'];
    // console.log(payload);
    return (
      <div className="recharts-default-tooltip ssf-tooltip-custom">
        <div>
          <div className="label">{label}</div>
          { payload.map(e => (
              <div key={e.name} className="d-flex" style={{color: e.color}}>
                <span className="mr-2">{e.name}</span>
                <span className="ml-auto">{getNestDefault(key, e, 0).toFixed(2)}</span>
                <span className="text-muted small">{e.unit}</span>
              </div>
          )) }
        </div>
      </div>
    );
  }

  return null;
}

const ReferenceLineCustomLabel = (props) => {
  const { viewBox, stroke, value } = props;
  return <text x={0} y={viewBox.y} dx={4} dy={4} fill={stroke} fontSize={10} textAnchor="start">{value}</text>
};


const getCenteredYDomain = (series, keyFn) => {
  const ranges = series.reduce((acc, s) => acc.concat(d3Extent(s.data, keyFn)), []);
  const [min, max] = d3Extent(ranges);                  // ydomain across all series
  let max2 = Math.max(Math.abs(min), Math.abs(max));
  return [-max2, max2];;
}


const colorSegments = (data, vkey) => {
  const first = 0;
  const cc = ProfitLossColors, tick = 1/(data.length-1-first);
  let out = [], dir;

  for (let i = first+1; i < data.length; i++) {
    let _dir = data[i][vkey] < 0 ? 1 : 0;
    if (dir === _dir) {
      out[out.length-1] = <stop key={`e${i}`} offset={(i-first)*tick} stopColor={cc[_dir]} stopOpacity={1}/>
    }
    else {
      out.push(<stop key={`s${i}`} offset={(i-first-1)*tick} stopColor={cc[_dir]} stopOpacity={1}/>)
      out.push(<stop key={`e${i}`} offset={(i-first)*tick} stopColor={cc[_dir]} stopOpacity={1}/>)
    }
    dir = _dir
  }
  return out;
}


/*
  series: [ { name: 'S1', data: [{ d: 'Mar 2017', cuml: 1.23 }, ... ], label: '$123' }, ... ],
  xkey: 'd',
  ykey: 'cuml',
  ykeyLabel: 'cuml', // optional; if ykey values are "log" value, then use base values for tooltip
  ycentered: true,
  vkey: 'v',         // value-key for finding up/down trend
*/
export const DualColorLineChart = (props) => {
  const { series, xkey, ykey, ycentered, vkey, ykeyLabel } = props;
  const colors = ProfitLossColors;
  let ydomain = ycentered ? getCenteredYDomain(series, e => e[ykey]) : ['auto', 'auto'];
  // console.log(series);
  return (
    <ResponsiveContainer width="100%" height="100%" >
      <LineChart margin={{top: 8, right: 32, left: 30, bottom: 8}}>
        <defs>
          { series.map(s => (
            <linearGradient id={`color-${s.name}`} x1="0" y1="0" x2="1" y2="0" key={s.name}>
              { colorSegments(s.data, vkey) }
            </linearGradient>
          ))}
        </defs>

        <ReferenceLine y={0} stroke="#bbb" shapeRendering="crispEdges"
          label={<ReferenceLineCustomLabel value={'$100'} stroke='#666' />} />
        <XAxis dataKey={xkey} tick={false} hide={true} type="category" allowDuplicatedCategory={false} />
        <YAxis hide={true} domain={ydomain} />

        { series.map(s => (
          <Line dataKey={ykey} data={s.data} name={s.name} key={s.name}
            type="monotone" unit="%"
            stroke={`url(#color-${s.name})`} strokeWidth={2}
            dot={false} activeDot={{r: 3, strokeWidth: 1, fill: colors[1]}}
            label={<CustomizedLabel size={s.data.length} label={s.label} stroke={colors[0]} />}
            isAnimationActive={false} />
        ))}

        <Tooltip content={<CustomTooltip vkey={ykeyLabel} />}
          cursor={{ stroke: '#666', strokeWidth: 1, strokeDasharray: "2 2" }}/>
      </LineChart>
    </ResponsiveContainer>
  );
}

const CustomizedLabel = (props) => {
  const { index, size, x, y, stroke, label } = props;
  if (index === size-1) {
    return (
      <text x={x-4} y={y} dx={4} dy={4} fill={stroke} fontSize={10} textAnchor="start">
        {label}
      </text>
    )
  }
  return null;
};

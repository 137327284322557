import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Card, CardBody, CustomInput } from 'reactstrap';
import { Row, Col, Typography, Tabs } from 'antd';
import { sponsorLogo } from 'DataSet';
import { FormatCurrency } from 'components/reports/shared/Utils';
import { TruncatedText } from 'components/sf/Common';
import { ViewChart } from 'components/reports/sf/ViewChart';
import { FeeReturnRisk } from '../FeeReturnRisk';

import { setPortfolioReplace, setEnhancementData  } from 'actions/portfolio';
import { getPortfolioStats, getInvestmentWatchlistStats, getInvestmentPositionStats } from 'selectors/investment';
import { fundsCardArray } from 'DataSet';
import { quintileSize, quintileText, getImage } from 'Utils';

const { Paragraph } = Typography;
const { TabPane } = Tabs;

// const enhancerCards = (type, data, query) => {
//   switch(type){
//     case 'condition':
//       let cardObj = fundsCardArray.find(o => query.includes(o.key));
//       if(cardObj){
//         data._frs.queryIcon = cardObj.queryIcon;
//         if(cardObj.multiplier == 1){
//           const _value = (typeof data[cardObj.paramKey] !== 'undefined' && data[cardObj.paramKey] !== '--')  ? data[cardObj.paramKey] : '--';
//           data._frs[cardObj.queryIcon] = (_value !== '--' && !Number.isNaN(_value)) ? _value.toFixed(1) : 0;
//         }
//         else if(cardObj.valueType == 'years'){
//           const _value = (typeof data[cardObj.paramKey] !== 'undefined' && data[cardObj.paramKey] !== '--') ? data[cardObj.paramKey] : null;
//           data._frs[cardObj.queryIcon] = (_value !== '--' && !Number.isNaN(_value)) ? _value.toFixed() + "yrs" : '--';
//         }
//         else{
//           const _value = (typeof data[cardObj.paramKey] !== 'undefined' && data[cardObj.paramKey] !== '--') ? data[cardObj.paramKey]* cardObj.multiplier : '--';
//           data._frs[cardObj.queryIcon] = (_value !== '--' && !Number.isNaN(_value)) ? _value.toFixed(1) + "%" : '--';
//         }
//       }
//       return data._frs;
//     default:
//       break;
//   }
// }

export class ItemCard extends Component {
  constructor(props){
    super(props)

    props.data._update = this.update;
    props.data._select = (checked) => this.selectHandler({ target: { checked } });
    this.state = {
      selected: false
    }
  }

  update = (selected) => {
    const { data, selectable } = this.props;
    data._selected = selected;
    if (!selected) data._order = undefined;
    selectable.update(selected);
  }

  setReplaceTicker = (e, replaceTicker, ticker, query) => {
    const val = document.getElementById(e.target.id).checked;
    const { view, enhanceReplace, setPortfolioReplace, setEnhancementData, filterResultSet, portfolioFunds, marketFunds, watchlistFundstats, accountFundstats } = this.props;

    const index = enhanceReplace.findIndex(x => x.ticker === ticker);

    if (index >= 0) {
      if (val) {
        enhanceReplace[index]['replace'] = replaceTicker;
        enhanceReplace[index]['query'] = query;

        setPortfolioReplace(enhanceReplace);
      } else {
        enhanceReplace.splice(index, 1)

        setPortfolioReplace(enhanceReplace);
      }
    } else {
      setPortfolioReplace(enhanceReplace);
    }

  }

  tabOnChange = (selectedKey, data, ticker, query, index) => {
    const { tabOnChange } = this.props;
    if (tabOnChange) {
      tabOnChange(selectedKey, data, ticker, query, index);
    }
  }

  getItemCardDesign = () => {
    const { data, query, ticker, enhanceReplace, i, staticCard, resetCheck, highlights, className, selected, portfolioQuery, activeViewTab, relevanceChartTabs, defaultFRR, originalQueryVar } = this.props;

    let new_data = '';
    if (data && data.Fee_Risk_Return) {
      let highlightsObj = (typeof highlights !== 'undefined' && highlights) ? highlights : { feesHighLightEnable: false, returnHighLightEnable: false, riskHighLightEnable: false };
      const id = data.index ? 'cb-'+ticker+data.index+data.id : 'cb-'+ticker+data.vehicle+data.ticker;

      let riskFromQueryVar = originalQueryVar.find((yuu) => yuu.node === 'risk');
      let returnFromQueryVar = originalQueryVar.find((yuu) => yuu.node === 'returns');

      let riskMultiplier = (riskFromQueryVar && riskFromQueryVar.multiplier && riskFromQueryVar.multiplier !== '') ? riskFromQueryVar.multiplier : 1;
      let returnMultiplier = (returnFromQueryVar && returnFromQueryVar.multiplier && returnFromQueryVar.multiplier !== '') ? returnFromQueryVar.multiplier : 1;

      let riskValue = (riskFromQueryVar && data[riskFromQueryVar.variable]) ? data[riskFromQueryVar.variable] : (data.Fee_Risk_Return['Annualized 3 year Volatility'] ? data.Fee_Risk_Return['Annualized 3 year Volatility'].toFixed(2) : 0);
      let returnVlaue = (returnFromQueryVar && data[returnFromQueryVar.variable]) ? data[returnFromQueryVar.variable] : (data.Fee_Risk_Return['Annualized 3 year Average'] ? data.Fee_Risk_Return['Annualized 3 year Average'].toFixed(2) : 0);
      data._frs && delete data._frs
      data._frs = {
        ...data,
        fees: data.fees,
        yRet: returnVlaue,
        volt: riskValue,
        feesQ: data.feesq,
        yRetQ: data.Fee_Risk_Return.returnq,
        voltQ: data.Fee_Risk_Return.riskq,
        queryIcon: '',
      };
      let overlapTicker = 'overlap_'+ticker;
      let correlationTicker = 'correlation_'+ticker;

      // data._frs = data && data._frs && query && enhancerCards('condition', data, query);

      // if (typeof portfolioQuery !== 'undefined' && (portfolioQuery.includes('safe') || portfolioQuery.includes('user_criteria'))) {
      //   const val = data['attribute/technical/risk'];
      //   data._frs.volt = val ? parseFloat(val)*100 : 0;
      // }

      if(data._queryVar == undefined && _.isEmpty(data.chart) == false || data._viewName != 'Overlap with ticker') {
        new_data = {
          ...data,
          _viewName: `Overlap with ticker`,
          _viewChartType:'dynamicGaugeChart',
          //_viewData: data.chart,
          _queryVar: {
            variable: data[`correlation_${data.ticker}`] > 0 ? `correlation_${data.ticker.toLowerCase()}` : `overlap_${data.ticker.toLowerCase()}`,
            data: ticker,
            ontology_node: "attribute/target_attribute/similarity",
            depth: 3,
            preference: 1,
            node: "similarity",
            chart_type: "odometer",
            min: "0",
            max: "data_max",
            suffix: "%",
            multiplier: 1,
            decimal: 0,
            round: "",
            display_cards: "",
            name: "",
            view_name: `Overlap with ticker`,
            Static: false,
            Secondary_view_name: "",
            active: true,
            chart_description: "",
            category: "attribute",
          },
        }
        if(data[overlapTicker] != undefined || data[correlationTicker] != undefined) {
          new_data = {
                ...new_data,
                _queryVar:{
                  ...new_data._queryVar,
                  variable: data[`correlation_${ticker}`] > 0 ? `correlation_${ticker.toLowerCase()}` : `overlap_${ticker.toLowerCase()}`,
                },
                _viewData : {
                  value: data[`correlation_${ticker}`] && data[`correlation_${ticker}`] > 0 ? data[`correlation_${ticker}`] : data[`overlap_${ticker}`],
                  min: 0,
                  max: 100,
                  decimal: 0,
                  suffix: "%",
                  multiplier: 1,
                }
              }
        }
        else {
          new_data = {
            ...new_data,
            _viewData: data.chart,
          };
        }
      }

      let checkedTrue = selected; // enhanceReplace === data.ticker;

      const getTabpaneDesign = (type) => {
        let chartDataObj = {};
        const { smallScreen, bigScreen, key } = this.props;
        const GuageChartWidth = smallScreen ? 90 : (bigScreen ? 130 : 90);

        if (type.includes('verlap') || type.includes('orrelation')) {
          const ff = new_data != "" ? (
            <ViewChart fund={new_data} i={0} queryVar={new_data._queryVar} data={new_data._viewData} chartType={new_data._viewChartType} size={'md'} />
          ) : (
            <ViewChart fund={data} i={0} queryVar={data._queryVar} data={data._viewData} chartType={data._viewChartType} size={'md'} />
          );
          return ff;
        } else {
          switch (type) {
            case 'risk':
              chartDataObj = {
                ...data,
                _viewName:'Risk',
                _viewChartType:'dynamicGaugeChart',
                _queryVar: {
                  value: riskValue,
                  variable: 'Risk',
                  data: ticker,
                  depth: 3,
                  preference: 1,
                  node: "Risk",
                  chart_type: "odometer",
                  min: "0",
                  max: "data_max",
                  suffix: "%",
                  multiplier: 1,
                  decimal: 0,
                  round: "",
                  display_cards: "",
                  name: "",
                  view_name: "Risk",
                  Static: false,
                  Secondary_view_name: "",
                  active: true,
                  chart_description: "",
                  category: "attribute",
                },
                _viewData : {
                  value: data.Fee_Risk_Return['Annualized 3 year Volatility'] ? data.Fee_Risk_Return['Annualized 3 year Volatility'].toFixed(2) : 0,
                  min: 0,
                  max: 100,
                  decimal: 0,
                  suffix: "%",
                  multiplier: 1,
                }
              }
              return (
                <ViewChart fund={chartDataObj} i={0} queryVar={chartDataObj._queryVar} data={chartDataObj._viewData} chartType={chartDataObj._viewChartType} size={'md'} />
              );
              break;
            case 'returns':
              chartDataObj = {
                ...data,
                _viewName:'Returns',
                _viewChartType:'dynamicGaugeChart',
                _queryVar: {
                  value: returnVlaue,
                  variable: 'Returns',
                  data: ticker,
                  depth: 3,
                  preference: 1,
                  node: "Returns",
                  chart_type: "odometer",
                  min: "0",
                  max: "data_max",
                  suffix: "%",
                  multiplier: 1,
                  decimal: 0,
                  round: "",
                  display_cards: "",
                  name: "",
                  view_name: "Returns",
                  Static: false,
                  Secondary_view_name: "",
                  active: true,
                  chart_description: "",
                  category: "attribute",
                },
                _viewData : {
                  value: returnVlaue,
                  min: 0,
                  max: 100,
                  decimal: 0,
                  suffix: "%",
                  multiplier: 1,
                }
              }
              return (
                <ViewChart fund={chartDataObj} i={0} queryVar={chartDataObj._queryVar} data={chartDataObj._viewData} chartType={chartDataObj._viewChartType} size={'md'} />
              );
              break;
            default:
          }
        }
      }

      const getTabHeadText = (label) => {
        if (label.includes('Overlap')) {
          return `Overlap with ${ticker}`;
        } else if (label.includes('Correlation')) {
          return `Correlation with ${ticker}`;
        } else {
          return label;
        }
      }

      const renderTabs = (
        <Tabs activeKey={activeViewTab} size="small" style={{ marginBottom: 5 }} onChange={(key) => this.tabOnChange(key, data, ticker, query, this.props.i)}>
          {relevanceChartTabs.map((op, opI) => {
            return (
              <TabPane tab={getTabHeadText(op)} key={opI+1}>
                {getTabpaneDesign(op)}
              </TabPane>
            )
          })}
        </Tabs>
      )

      return (
        <Card key={enhanceReplace+i} className={`${!staticCard ? 'card-background-grey' : 'sddf'} ${className}`} style={{minWidth: '250px', width: '250px', margin: '0 auto' }}>
          <CardBody className="p-0">
            <div className="p-1 border-bottom">
              <Row style={{minHeight: '90px'}}>
                <TickerPrice
                  className="d-flex flex-column justify-content-center align-items-center"
                  ticker={data.ticker}
                  price={data.nav}
                />
                <NameSponsor
                  className="d-flex flex-column justify-content-center align-items-left"
                  name={data.short_name}
                  sponsor={data.sponsor}
                  category={data.category}
                />
              </Row>
              {!staticCard
                && (
                  <CustomInput
                    type="checkbox"
                    id={id}
                    inline
                    onClick={(e) => this.props.setReplaceTicker(e, data.ticker, ticker, query)}
                    className='p-top-right'
                    title="Select"
                    checked={checkedTrue}
                  />
                )
              }
            </div>
            <div className="border-bottom">
              <FeeReturnRisk originalQueryVar={originalQueryVar} defaultFRR={defaultFRR} data={data._frs} query={query} highlights={highlightsObj} />
            </div>

            <div className="relevance-charts-tab-wrapper">
              {renderTabs}
            </div>

          </CardBody>
        </Card>
      );
    }
  }

  render() {
    const renderDesign = this.getItemCardDesign();

    return (
      <React.Fragment>
        {renderDesign}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  // enhanceReplace: state.portfolio.enhanceReplace,
  smallScreen: global.smallScreen,
  bigScreen: global.bigScreen,
})

const mapDispatchToProps = {
  setPortfolioReplace,
  setEnhancementData,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemCard)


//className="d-flex flex-column justify-content-center align-items-center" style={{minWidth: '4rem'}}
const TickerPrice = ({ ticker, price, className }) => (
  <Col className={className} span={8}>
    <h5 className="mb-0 ticker-size ticker-price">{ticker}</h5>
    <h4 className="mt-1 mb-0 ticker-price">{FormatCurrency(price, 2)}</h4>
  </Col>
)

const NameSponsor = ({ name, sponsor, category, className }) => (
  <Col className={className} span={16}>
    <div className="pb-1">
      <ImageOrText img={sponsorLogo(sponsor)} text={sponsor} />
    </div>
    <h5 className="m-0" title={name} style={{ fontWeight: 600, lineHeight: 1.2 }}>
      <Paragraph
        className="text-muted item-card-text"
        ellipsis={{rows: 2}}
        title={name}
      >
        {name}
      </Paragraph>
    </h5>
    <h5 className="mb-0 text-gray-6" title={category} style={{ lineHeight: 1.4, color: '#57869c', marginTop: 3 }} >
      <Paragraph
        className="m-0 category-text"
        ellipsis={{rows: 1}}
        title={category}
        >
        {category}
      </Paragraph>
    </h5>
  </Col>
)

const ImageOrText = ({ img, text = '' }) => {
  const [valid, setValid] = useState(true);

  return (
    valid
    ? <img src={img} className="img-fluid" alt="" onError={() => setValid(false)} style={{height: '20px'}} />
    : <div className="sponsor-logo-placeholder" title={text}>{text}</div>
  );
}

const getId = (function* () {
  let n = 1;
  while (true) yield n++;
})();

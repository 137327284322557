import React from 'react';

import { Row, Col } from 'antd';
import { kmb } from 'DataSet';
import { quintileSize, quintileText, getImage } from 'Utils';
import { getFRRAttrDataForModels, textCapitalize } from 'layouts/utils';

export const FeeReturnRisk = ({ data, highlights, query, defaultFRR, originalQueryVar }) => {
  // const { feesHighLightEnable, returnHighLightEnable, riskHighLightEnable } = highlights;

  highlights.feesHighLightEnable   = (originalQueryVar.find((vc) => vc.node === 'cost') ? true : false)    || query.includes('cost');
  highlights.returnHighLightEnable = (originalQueryVar.find((vc) => vc.node === 'returns') ? true : false) || highlights.returnHighLightEnable;
  highlights.riskHighLightEnable   = (originalQueryVar.find((vc) => vc.node === 'risk') ? true : false)    || query.includes('risk');

  const FRR_DATA_ATTRS = getFRRAttrDataForModels(query, highlights);
  let FRR_ATTRS = FRR_DATA_ATTRS.filter((item) => defaultFRR.includes(item.attr));

  if (FRR_ATTRS.length !== defaultFRR.length) {
    // console.log('some NODE is missing ===>', defaultFRR);
    const EXCLUDED_NODE_WAS = [];
    // console.log('originalQueryVar--->', originalQueryVar);
    const renderingFRRs = FRR_ATTRS.map((kgf) => kgf.attr)
    // console.log('renderingFRRs ===> ', renderingFRRs);
    const NEW_NODE = defaultFRR.filter((jh) => !renderingFRRs.includes(jh));
    // console.log('renderingFRRs not included this =>', NEW_NODE);

    NEW_NODE.map((kkl) => {
      if (kkl) {
        const foundInQueryVar = originalQueryVar.find((vc) => vc.node === kkl);
        if (foundInQueryVar) {
          EXCLUDED_NODE_WAS.push({
            reverse: true,
            multiplier: foundInQueryVar.multiplier !== '' ? foundInQueryVar.multiplier : 1,
            attr: foundInQueryVar.node,
            name: foundInQueryVar.view_name !== '' ? (foundInQueryVar['Name of the chart on UI'] !== '' ? textCapitalize(foundInQueryVar['Name of the chart on UI']) : textCapitalize(foundInQueryVar.view_name)) : textCapitalize(foundInQueryVar.view_name),
            qcol: foundInQueryVar.variable,
            col: foundInQueryVar.variable,
            img: "Assets", // common image for all unknown node
            suffix: foundInQueryVar.suffix,
            decimal: foundInQueryVar.decimal,
            highlightClass: 'search-risk-stats-highlighting',
          });
        }
      }
    });

    // console.log('---- EXCLUDED_NODE_WAS ----', EXCLUDED_NODE_WAS);
    // console.log(NEW_NODE);

    FRR_ATTRS = [...FRR_ATTRS, ...EXCLUDED_NODE_WAS].slice(0, 3); // limit 3
  }

  FRR_ATTRS.map((jt) => {
    const df = originalQueryVar.find((dc) => dc.node === jt.attr)
    if (df) {
      jt.decimal = df.decimal;
      // jt.reverse = true;
      jt.multiplier = df.multiplier !== '' ? df.multiplier : 1;
      // jt.attr = df.node;
      // jt.name = df.view_name !== '' ? (df['Name of the chart on UI'] !== '' ? textCapitalize(df['Name of the chart on UI']) : textCapitalize(df.view_name)) : textCapitalize(df.view_name);
      // jt.qcol = df.variable;
      // jt.col = df.variable;
      // jt.img = "Assets"; // common image for all unknown node
      // jt.suffix = df.suffix;
    }
  });

  const elems = FRR_ATTRS.map(attr => {
    const qs = quintileSize(data[attr.qcol], attr.reverse);
    // console.log(attr.col, ' ==> ', data[attr.col] !== null, data[attr.col] !== null ? data[attr.col] : '--');
    const _val = (typeof data[attr.col] !== 'undefined' && data[attr.col] !== null && data[attr.col] !== '--') ?
    (attr.name === 'Assets' ? (kmb(data[attr.col])) : (
      (attr.decimal && attr.decimal !== '' ? (
        data[attr.col] * attr.multiplier).toFixed(attr.decimal)
        : Math.round((data[attr.col] * attr.multiplier)) ) + (attr.suffix)
      )) : '--';

    return {
      attr: attr.attr,
      name: attr.name,
      icon: attr.icon ? `${attr.icon}-${qs}` : null,
      iconImg: attr.img ? attr.img : null,
      value: _val,
      label: quintileText(qs),
      highlightClass: attr.highlightClass,
    }
  })

  //conflict logic need to confirm this
  // const elems = attrs.map(attr => {
  //   if (data.queryIcon !== '' && attr.col === data.queryIcon) {
  //     return {
  //       attr: attr.attr,
  //       name: attr.name,
  //       icon: null,
  //       iconImg: (attr.col === data.queryIcon) ? data.queryIcon : `${attr.icon}-${quintileSize(data[attr.qcol], attr.reverse)}`,
  //       value: typeof data[data.queryIcon] !== 'undefined' ? (isNaN(data[data.queryIcon]) ? '--' : data[data.queryIcon]) + `${(data[data.queryIcon] == '--' || attr.col === 'Sharpe') ? '' : attr.col === 'TrackRecord' ? 'yrs' : '%'}` : ((typeof data[attr.qcol] !== 'undefined' && data[attr.qcol] !== '--') ? data[attr.qcol].toFixed(2) + "%" : "--"),
  //       label: null,
  //       highlightClass: attr.highlightClass,
  //     }
  //
  //   } else {
  //     const qs = quintileSize(data[attr.qcol], attr.reverse);
  //     return {
  //       attr: attr.attr,
  //       name: attr.name,
  //       icon: `${attr.icon}-${qs}`,
  //       iconImg: null,
  //       value: typeof data[attr.col] != 'undefined' && data[attr.col] !== null ? (isNaN(data[attr.col]) ? '--' : data[attr.col].toFixed(2) + `${(data[attr.col] == '--' || attr.col === 'Sharpe') ? '' : attr.col === 'TrackRecord' ? 'yrs' : '%'}`) : '--',
  //       label: quintileText(qs),
  //       highlightClass: attr.highlightClass,
  //     }
  //   }
  // })

  return (
    <Row className="no-gutters FRR-wrapper" style={{ height: '100%' }}>
      { elems.map(e => (
          <Col span={8} key={e.attr} style={{ height: '100%' }}>
            <QuintileIconText {...e} />
          </Col>
      )) }
    </Row>
  );
}

const colStyle = {
  height: '100%',
  minHeight: 108,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const QuintileIconText = ({ attr, name, icon, iconImg, value, label, highlightClass }) => (
  <div className={`${attr}-flag-color FRR-container px-2 py-2 ${highlightClass}`} style={colStyle}>
    <p className="__state" style={{ margin: 0, fontSize: 10, height: 20, lineHeight: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{name}</p>
    {(iconImg !== null && icon === null) ? (
      <img className="far fa-fw" src={getImage(iconImg)} width="auto" height={32} />
    ) : (
      <i className={`far fa-fw fassf ${icon}`} />
    )}
    <p className="__val" style={{ margin: 0, fontSize: 11, lineHeight: 1, fontWeight: 'bold' }}>{value}</p>
    {/* <p className="__state" style={{ margin: 0, fontSize: 10, lineHeight: 1, height: 10 }}>{label}</p> */}
  </div>
)

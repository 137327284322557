import get from 'lodash/get'

export const isTermsAgreed = authState => {
  return authState.termsAgreed === 1
}

export const isNewUser = authState => {
  return authState.newUser === 1
}

export const getAuthState = state => state.auth

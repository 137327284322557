import React, { Component } from 'react'

import {
  Dropdown,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardText,
  Row,
  Col,
  PopoverBody,
  Tooltip
} from 'reactstrap'
import { sendFeedback } from 'apis/feedback'
import { uniq } from 'lodash'
import { RegionChartMapping, SectorChartMapping } from '../ChartData'
import qs from 'query-string'
import $ from 'jquery'
import RangeSlider from './RangeSlider'
import { ViewNames } from '../DataSet'
import { withPopover } from './Popover'
import { getViewNameList, formatTickerForView } from '../Utils'

import EmbedSection from './EmbedSection'
import { Notification } from 'shared-components'
import { addEventToAnalytics } from 'Utils';
import SelectView from '../layouts/WebDashboard/SearchResultsV3/Elements/SelectView'

class CardListToolbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tooltipOpen: false
    }
  }

  toggle = e => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    })
  }

  render() {
    const { highlightText, view, query } = this.props
    const lineChartColumn = 'col-lg-4 col-md-3 col-sm-12'
    const viewChartsColumn = 'col-2'
    const screenWidth = window.screen.width

    return this.props.loading ? null : (
      <section className="bg-ssf-gray-l7 cardListToolbarWrapper">
        <div className="container">
          <div
            className="row no-gutters media-h-auto"
            style={{ minHeight: 93 }}
          >
            <div className="col-4 media-sm-none" >
              <div style={{ height: 19 }} className="media-h-auto">
                <div className="h6 text-blue my-1">&nbsp;</div>
              </div>
              <div
                className="fees-returns-risk-header-container media-h-auto"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <div className="FRR-Label">Name</div>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 text-center media-sm-none">
              <div style={{ height: 19 }} className="media-h-auto">
                <div className="h6 text-blue my-1">&nbsp;</div>
              </div>
              <div
                className="media-sm-none fees-returns-risk-header-container media-h-auto"
                style={{ display: 'flex', justifyContent: '' }}
              >
                <div
                  className={`FRR-Label ${
                    highlightText.feesHighLightEnable ? 'highlight' : ''
                  }`}
                >
                  Fees
                </div>
                <div
                  className={`FRR-Label ${
                    highlightText.returnHighLightEnable ? 'highlight' : ''
                  }`}
                >
                  Return
                </div>
                <div
                  className={`FRR-Label ${
                    highlightText.riskHighLightEnable ? 'highlight' : ''
                  }`}
                >
                  Risk
                </div>
              </div>
            </div>
            <div className={`${viewChartsColumn} text-center media-sm-none viewChartsColumn`}>
              <SelectView
                discovery={this.props.funds}
                itemCardView={this.props.itemCardView}
                viewHandler={this.props.viewHandler}
                view={this.props.view}
                chartData={this.props.chartData}
                showCarousel = {this.props.showCarousel}
              />
            </div>
            {screenWidth > 767 && (
              <div className={`${lineChartColumn} display-grid-center-range pr-2`}>
                {Object.keys(this.props.dateRangeSelected).length > 0 && (
                  <RangeSlider
                    dateRange={this.props.dateRange}
                    dateRangeHandler={this.props.dateRangeHandler}
                    dateRangeSelected={this.props.dateRangeSelected}
                    ref={this.props.inputRef}
                    query={this.props.query}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </section>
    )
  }
}

export default CardListToolbar

class ToolbarView extends React.Component {
  constructor(props) {
    super(props)
    const defaultViewObject =
      props.view.length > 1 ? props.view[2] : props.view[0]
    const defaultView = defaultViewObject.name
    let selectedView = props.chartData[0].display_view_name
    //view/default view is for view and dropdown
    // selected view will be passed to itemcard
    let secondaryQ = ''
    if (props.chartData[0].Secondary_view_name != '') {
      secondaryQ = props.chartData[0].Secondary_view_name
      props.viewHandler(selectedView, secondaryQ)
    } else if (defaultView == 'Diversification') {
      secondaryQ = 'Holdings'
      props.viewHandler(defaultView, 'Holdings')
    } else if (defaultView == 'Return Quality') {
      secondaryQ = 'Sharpe Ratio'
      props.viewHandler(defaultView, 'Sharpe Ratio')
    }

    this.state = {
      dropdownOpen: false,
      diversificationDropdownOpen: false,
      riskAdjustedReturnsDropdownOpen: false,
      view: defaultView,
      selectedView: selectedView,
      secondaryView: secondaryQ,
      // dynamicQueryCategory: props.dynamicQueryCategory,
      // queryCategoryValue: props.queryCategoryValue,
      suggestMoreIsActive: false,
      suggestMessage: '',
      isOnFinal: false,
      viewDropDownListTop: 22
    }

    this.toggle = this.toggle.bind(this)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.onMouseEnterToggleDiversification = this.onMouseEnterToggleDiversification.bind(
      this
    )
    this.onMouseLeaveToggleDiversification = this.onMouseLeaveToggleDiversification.bind(
      this
    )
    this.toggleDiversificationDropdown = this.toggleDiversificationDropdown.bind(
      this
    )
    this.onMouseEnterToggleRisk = this.onMouseEnterToggleRisk.bind(this)
    this.onMouseLeaveToggleRisk = this.onMouseLeaveToggleRisk.bind(this)
    this.toggleRiskAdjustedReturnsDropdown = this.toggleRiskAdjustedReturnsDropdown.bind(
      this
    )
    this.onSuggestModalClose = this.onSuggestModalClose.bind(this)
    this.suggestClick = this.suggestClick.bind(this)
    this.onSuggestMessageChange = this.onSuggestMessageChange.bind(this)
    this.suggestSubmit = this.suggestSubmit.bind(this)
  }

  toggle() {
    const cls = document.getElementById('view-text-container')
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      viewDropDownListTop: cls.offsetHeight || 22
    })
  }

  onMouseEnter() {
    const cls = document.getElementById('view-text-container')
    this.setState({
      dropdownOpen: true,
      viewDropDownListTop: cls.offsetHeight || 22
    })
  }

  onMouseLeave() {
    const cls = document.getElementById('view-text-container')
    // console.log('leave');
    this.setState({
      dropdownOpen: false,
      viewDropDownListTop: cls.offsetHeight || 22
    })
  }

  toggleDiversificationDropdown() {
    this.setState({
      diversificationDropdownOpen: !this.state.diversificationDropdownOpen
    })
  }

  onMouseEnterToggleDiversification() {
    this.setState({
      diversificationDropdownOpen: true
    })
  }

  onMouseLeaveToggleDiversification() {
    this.setState({
      diversificationDropdownOpen: false
    })
  }

  toggleRiskAdjustedReturnsDropdown() {
    this.setState({
      riskAdjustedReturnsDropdownOpen: !this.state
        .riskAdjustedReturnsDropdownOpen
    })
  }

  onMouseEnterToggleRisk() {
    this.setState({
      riskAdjustedReturnsDropdownOpen: true
    })
  }

  onMouseLeaveToggleRisk() {
    this.setState({
      riskAdjustedReturnsDropdownOpen: false
    })
  }

  changeView(view, secondaryView, selectedView) {
    const { funds,view: _originalPropView } = this.props;
    // item, secondaryView || '', viewName, selectedView
    if (!selectedView) {
      if (view.includes(' && ')) {
        selectedView = this.props.chartData[0].display_view_name
        secondaryView = secondaryView ? secondaryView : this.props.chartData[0].Secondary_view_name
      } else selectedView = view
    }
    let mappingVariable = '';
    const _found = this.props.chartData.filter((item) => item.display_view_name === selectedView);
    mappingVariable = (_found && _found.length > 0 &&  _found[0].variable) ? _found[0].variable : '';
    this.setState({ view, secondaryView, selectedView })
    this.props.viewHandler(selectedView, secondaryView,mappingVariable)
    if (_found && _found.length > 0 && _found[0].display_view_name) {
      // console.log(_found[0].display_view_name);
      // console.log(_originalPropView);
      const SliderElem = _originalPropView.find((k) => k.name.includes(' && '));
      // console.log(SliderElem);
      if (SliderElem) {
        const listForSliderElem = SliderElem.name.split(' && ');
        // console.log(listForSliderElem);
        const slideNum = (listForSliderElem.findIndex(x => x === _found[0].display_view_name));
        // console.log('slideNum =>', slideNum);
        this.setState({ slideNum });
        if (slideNum > -1 && this.slider) {
          setTimeout(() => {
            this.slider.slickGoTo(slideNum)
          }, 200)
        }
      }
    }
    addEventToAnalytics('View Chart','View Chart','VIEW_CHART',{ query: funds.query, logId: funds.logId, viewName: view },true);
  }

  suggestClick() {
    this.setState({
      suggestMoreIsActive: true
    })
  }
  onSuggestModalClose() {
    this.setState({
      suggestMoreIsActive: false
    })
  }

  onSuggestMessageChange(newMessage) {
    this.setState({ suggestMessage: newMessage.target.value })
  }

  suggestSubmit(event) {
    event.preventDefault()
    if (this.state.suggestMessage.length === 0) {
      return
    }

    console.log(
      sendFeedback(
        qs.stringify({ feedback: 'dataItem: ' + this.state.suggestMessage })
      )
    )

    this.setState({ isOnFinal: true }, () => {
      setTimeout(() => {
        this.toggle(false)
        setTimeout(() => {
          this.setState({ isOnFinal: false, suggestMoreIsActive: false })
        })
      }, 2000)
    })

    //this.setState({ suggestMoreIsActive: false, suggestMessage: '' })
  }

  getDropDownViewName = name => {
    // const { isSectorDataFoundInFunds } = this.props;
    let split_name = name.split(' && ')
    split_name.forEach((item, index) => {
      const currentChartData = this.props.chartData.filter(
        e => e.view_name == item
      )[0]
      let toBeDisplayedView
      if (currentChartData) {
        // if (item == 'Regions' && currentChartData.data) {
        //   let displayText = RegionChartMapping.filter(
        //     e => e.chart_name === currentChartData.node
        //   )
        //   if (displayText.length) {
        //     toBeDisplayedView = '%\u00a0' + displayText[0].region_name
        //     split_name[index] = toBeDisplayedView
        //     // return name;
        //   } else split_name[index] = item
        // } else if (item == 'Sectors' && currentChartData.data) {
        //   let find = SectorChartMapping.filter(
        //     e => e.chart_name === currentChartData.node
        //   )
        //   if (find.length > 0) {
        //     toBeDisplayedView = '%\u00a0' + find[0].sector_name
        //     split_name[index] = toBeDisplayedView
        //   } else split_name[index] = item
        // } else if (
        //   item == 'Top Holdings' &&
        //   currentChartData.data &&
        //   (currentChartData.variable.includes('holdings_') ||
        //   currentChartData.variable.include('neg_holdings_'))
        // ) {
        //   let ticker = ''
        //   if (
        //     Object.prototype.toString.call(currentChartData.data) ===
        //     '[object Array]'
        //   ) {
        //     ticker = currentChartData.data[0]
        //   } else if (typeof currentChartData.data === 'string') {
        //     ticker = currentChartData.data
        //   }
        //   if (ticker.length > 4) ticker = formatTickerForView(ticker)
        //   toBeDisplayedView = '%\u00a0' + ticker
        //   split_name[index] = toBeDisplayedView
        //   // return name;
        // }
        // // else if (
        // //   item == 'Asset Allocation' &&
        // //    currentChartData.data &&
        // //    typeof currentChartData.category !== 'undefined' &&
        // //    currentChartData.category === 'asset_type'
        // //  ) {
        // //
        // //   if (currentChartData.node) {
        // //     const chartDataNode = currentChartData.node === 'equity' ? 'equities' : currentChartData.node;
        // //     split_name[index] = `% ${convertToCapitalCase(chartDataNode)}`;
        // //   } else split_name[index] = item
        // //
        // // } else split_name[index] = item
        // else
        // {
        split_name[index] = item // remove this line if you uncomment above lines
        // }
      } else {
        split_name[index] = item
      }
    })
    if (split_name.length > 1) {
      // return split_name[0] + ' && ' + split_name[1]
      return 'Relevant Charts'
    } else {
      return split_name[0]
    }
  }

  callToChangeView = (item, secondaryView, viewName, selectedView) => {
    // this.setState({selectedView: selectedView, secondaryView: secondaryView})
    this.changeView(viewName, secondaryView || '', selectedView)
  }

  renderViewTextDesign = view => {
    let { chartData } = this.props
    // const updatedQueryArr = uniqBy(viewList.concat(selectedViewArr), 'name');

    // console.log(view, chartData, this.state.selectedView);
    let renderViewTextDesign = (
      <div className="view-text-label text-blue selected">
        {this.getDropDownViewName(this.state.view)}
      </div>
    )

    // console.log(updatedQueryArr[0]);
    // const newArr = updatedQueryArr.length === 3 ? updatedQueryArr[2] : updatedQueryArr[updatedQueryArr.length - 1];
    // const originalNameCanBe = newArr.originalName || [];
    if (this.state.view.includes(' & ')) {
      renderViewTextDesign = chartData.map(item => {
        // console.log(item)
        return (
          <div
            key={`chart-type-${item.view_name}`}
            className={`view-text-label text-blue ${
              item.display_view_name === this.state.selectedView
                ? 'selected'
                : ''
            }`}
            onClick={() =>
              this.callToChangeView(
                item,
                item.Secondary_view_name,
                this.state.view,
                item.display_view_name
              )
            }
          >
            {item.display_view_name
              ? this.getDropDownViewName(item.display_view_name)
              : this.getDropDownViewName(item.view_name)}
          </div>
        )
      })
    }

    return (
      <div id="view-text-container" className="view-text-container">
        {renderViewTextDesign}
      </div>
    )
  }

  renderSubTitle = viewName => {
    const selectedView = this.state.selectedView
    let renderSubTitle = <h6 className="h6 mb-0" />

    if (selectedView.includes('Performance vs ')) {
      renderSubTitle = (
        <h6 className="h6 mb-0 text-gray-6">
          <span>(3 year cumulative return)</span>
        </h6>
      )
    } else if (['Risk', 'Trend'].includes(selectedView)) {
      renderSubTitle = (
        <h6 className="h6 mb-0 text-gray-6">(Under Development)</h6>
      )
    } else {
      switch (selectedView) {
        case 'Assets':
          renderSubTitle = (
            <h6 className="h6 mb-0 text-gray-6">
              <span>Assets Managed vs Category</span>
            </h6>
          )
          break
        case 'Volume':
          renderSubTitle = (
            <h6 className="h6 mb-0 text-gray-6">
              <span>Volume vs Category</span>
            </h6>
          )
          break
        case 'Diversification':
          if (this.state.secondaryView) {
            renderSubTitle = (
              <h6 className="h6 mb-0 wid-100">
                <Dropdown
                  isOpen={this.state.diversificationDropdownOpen}
                  toggle={this.toggleDiversificationDropdown}
                  onMouseOver={this.onMouseEnterToggleDiversification}
                  onMouseLeave={this.onMouseLeaveToggleDiversification}
                >
                  <DropdownToggle
                    caret
                    color="link"
                    className="h6 text-blue mb-0 p-0 wid-100"
                  >
                    {this.state.secondaryView}
                  </DropdownToggle>
                  <DropdownMenu
                    className="rounded-0 h6 wid-100 text-center"
                    style={{ margin: 0 }}
                  >
                    <DropdownItem
                      key={'Holdings'}
                      className="py-1"
                      onClick={() =>
                        this.changeView(viewName, 'Holdings', selectedView)
                      }
                    >
                      {'Holdings'}
                    </DropdownItem>
                    <DropdownItem
                      key={'Region'}
                      className="py-1"
                      onClick={() =>
                        this.changeView(viewName, 'Region', selectedView)
                      }
                    >
                      {'Region'}
                    </DropdownItem>
                    <DropdownItem
                      key={'Sector'}
                      className="py-1"
                      onClick={() =>
                        this.changeView(viewName, 'Sector', selectedView)
                      }
                    >
                      {'Sector'}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </h6>
            )
          } else {
            renderSubTitle = <h6 className="h6 mb-0" />
          }
          break
        case 'Return Quality':
          if (this.state.secondaryView) {
            renderSubTitle = (
              <h6 className="h6 mb-0 wid-100">
                <Dropdown
                  isOpen={this.state.riskAdjustedReturnsDropdownOpen}
                  toggle={this.toggleRiskAdjustedReturnsDropdown}
                  onMouseOver={this.onMouseEnterToggleRisk}
                  onMouseLeave={this.onMouseLeaveToggleRisk}
                >
                  <DropdownToggle
                    caret
                    color="link"
                    className="h6 text-blue mb-0 p-0 wid-100"
                  >
                    {this.state.secondaryView}
                  </DropdownToggle>
                  <DropdownMenu
                    className="rounded-0 h6 wid-100 text-center"
                    style={{ margin: 0 }}
                  >
                    <DropdownItem
                      key={'Sharpe Ratio'}
                      className="py-1"
                      onClick={() =>
                        this.changeView(viewName, 'Sharpe Ratio', selectedView)
                      }
                    >
                      {'Sharpe Ratio'}
                    </DropdownItem>
                    <DropdownItem
                      key={'Sortino Ratio'}
                      className="py-1"
                      onClick={() =>
                        this.changeView(viewName, 'Sortino Ratio', selectedView)
                      }
                    >
                      {'Sortino Ratio'}
                    </DropdownItem>
                    <DropdownItem
                      key={'Tracking Error'}
                      className="py-1"
                      onClick={() =>
                        this.changeView(
                          viewName,
                          'Tracking Error',
                          selectedView
                        )
                      }
                    >
                      {'Tracking Error'}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </h6>
            )
          } else {
            renderSubTitle = <h6 className="h6 mb-0" />
          }
          break
        default:
          renderSubTitle = <h6 className="h6 mb-0" />
      }
    }

    return renderSubTitle
  }

  render() {
    const { view: relevantView, chartData } = this.props
    const { view, selectedView, viewDropDownListTop } = this.state
    let _viewNameCollections = uniq(
      ViewNames.map(item => item.name).concat(
        relevantView.map(item => item.name)
      )
    );
    let dropDownViewName = uniq(_viewNameCollections.map((k) => {
      if (!k.includes(' && ')) {
        return this.getDropDownViewName(k)
      } else {
        return k
      }
    }));
    const styleObj = {
      top: viewDropDownListTop,
      alignItems: 'center'
    }

    let renderViewTextDesign = this.renderViewTextDesign(view)
    const renderSubTitle = this.renderSubTitle(view)
    return (
      <div className="viewDropdownContainer">
        <Dropdown
          className="viewDropdownWrapper"
          isOpen={this.state.dropdownOpen}
          toggle={this.toggle}
          onMouseOver={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          <DropdownToggle
            caret
            color="link"
            className="h6 text-blue my-1 viewBtn"
          >
            View
          </DropdownToggle>
          <DropdownMenu
            className="rounded-0 h6 viewDropDownList"
            style={{ marginTop: `${viewDropDownListTop}px` }}
          >
            <div
              className="ghost-child"
              style={{
                height: `${viewDropDownListTop + 8}px`,
                top: `-${viewDropDownListTop + 3}px`
              }}
            />
            <div className="viewDropDownList-Child">
              {dropDownViewName.map(v => (
                <DropdownItem
                  key={v}
                  className="py-1"
                  onClick={() => {
                    if (v == 'Diversification') {
                      this.changeView(v, 'Holdings')
                    } else if (v == 'Return Quality') {
                      this.changeView(v, 'Sharpe Ratio')
                    } else this.changeView(v, '')
                  }}
                >
                  {this.getDropDownViewName(v)}
                </DropdownItem>
              ))}
              <DropdownItem
                key="Suggest more"
                className="py-1"
                onClick={() => this.suggestClick()}
              >
                Suggest more?
              </DropdownItem>
            </div>
          </DropdownMenu>
        </Dropdown>
        {renderViewTextDesign}
        <div className="view-sub-title-container">{renderSubTitle}</div>
        {this.state.suggestMoreIsActive && (
          <SuggestForm
            isOnFinal={this.state.isOnFinal}
            isOpen={this.state.suggestMoreIsActive}
            onClose={this.onSuggestModalClose}
            onMessageChange={this.onSuggestMessageChange}
            message={this.suggestMessage}
            onSubmit={this.suggestSubmit}
          />
        )}
      </div>
    )
  }
}

class SuggestForm extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    document.body.classList['add']('modal-open-suggest')
    $('.suggest-backdrop').click(() => this.props.onClose) // hack as bockdrop click not working
  }

  componentWillUnmount() {
    document.body.classList['remove']('modal-open-suggest')
    $('.suggest-backdrop').unbind('click', this.props.onClose)
  }

  render() {
    return (
      <Modal
        autoFocus={false}
        isOpen={this.props.isOpen}
        backdrop={true}
        centered
        fade
        backdropClassName="suggest-backdrop"
        modalClassName="suggest"
        contentClassName="b-2"
        toggle={this.props.onClose}
        id="suggestModal"
      >
        {this.props.isOnFinal ? (
          <SuggestionThanks />
        ) : (
          <Form onSubmit={this.props.onSubmit} inline>
            <FormGroup className="m-1 p-0" style={{ flex: '2' }}>
              <Input
                type="text"
                name="suggestMessage"
                value={this.props.message}
                onChange={this.props.onMessageChange}
                id="suggestMessage"
                placeholder="Suggest a new data item"
                style={{ resize: 'none' }}
                required
                className="w-100 p-0 px-2"
                maxLength={32}
                rows={1}
                autoFocus
                width="auto"
              />
            </FormGroup>
            <Button
              type="submit"
              onClick={this.props.onSubmit}
              color="select"
              className="btn h4 p-1 h-100 text-uppercase font-weight-bold m-0 mr-1"
            >
              Submit
            </Button>{' '}
          </Form>
        )}
      </Modal>
    )
  }
}

const SuggestionThanks = props => (
  <Card>
    <CardBody>
      <CardText>Thank you for your suggestion.</CardText>
    </CardBody>
  </Card>
)

class ViewPopover extends Component {
  render() {
    const { view, dynamicQueryCategory } = this.props
    if (view == dynamicQueryCategory) {
      return <div>&nbsp;</div>
    }
    const data = {
      Volume: 'Liquidity measure based on recent dollar value traded',
      Regions: 'Exposure to different regions and countries',
      Sectors: 'Allocations to Sectors',
      Assets: 'Assets under management',
      'Top Holdings': 'Allocation to top 10 holdings',
      'Asset Types': 'Allocations made across different Asset Typess',
      Impact:
        'Impact denotes an aggregated ESG (Environmental, Social and Governance ) Score of the holding companies',
      Themes: 'Top 4 theme exposures',
      'Holdings Diversification':
        '3 measures of diversification; A - No of underlyings, B - Herfindahl-Hirschman Index & C - allocation to top 10 holdings',
      'Region Diversification':
        '3 measures of diversification; A - No of underlyings, B - Herfindahl-Hirschman Index & C - allocation to top 10 holdings',
      'Sector Diversification':
        '3 measures of diversification; A - No of underlyings, B - Herfindahl-Hirschman Index & C - allocation to top 10 holdings'
    }
    const e1 = () => (
      <React.Fragment>
        <i className="far fa-info-circle"></i>
      </React.Fragment>
    )

    const e2 = () => (
      <React.Fragment>
        <PopoverBody className="p-0">
          <Row className="no-gutters">
            <Col className="col-12 p-2">
              <div className="h5 d-flex justify-content-between pt-1">
                {data[view]}
              </div>
            </Col>
          </Row>
        </PopoverBody>
      </React.Fragment>
    )

    const WithPopover = withPopover(e1, e2, {
      id: 'NamePopover-Sid',
      placement: 'bottom'
    })
    return (
      <h6 className="h6 mb-0 text-gray-6 truncate">
        <span>{data[view]}</span>
        <br />
        <span>
          <WithPopover />
        </span>
      </h6>
    )
  }
}

import React, { createElement } from 'react';
import classNames from 'classnames';
import { Button, Result } from 'antd';
import config from './config';

// NOTE: status: success | error | info | warning | 404 | 403 | 500
// const resultStatus = ["success", "error", "info", "warning", "404", "403", "500", "400"]

export default ({ className, style, linkElement = 'a', type, code, title, desc, img, actions, ...rest }) => {
  const contentType = type in config ? type : '404';
  const contentCode = code || config[contentType].code;
  const contentTitle = title || config[contentType].title;
  const contentDesc = desc || config[contentType].desc;
  const contentImgComponent = img || <img src={config[contentType].img} alt="uc images" />;
  const extraButton = actions || createElement(linkElement, {
    to: '/',
    href: '/',
  }, <Button type="primary">Return home</Button>)

  return (
    <Result
      {...rest}
      className={className}
      style={style}
      icon={contentImgComponent}
      status={contentCode}
      title={contentTitle}
      subTitle={contentDesc}
      extraButton={createElement(linkElement, {
        to: '/',
        href: '/',
      }, <Button type="primary">Return home</Button>)}
    >
      {rest.children}
    </Result>
  );
  // debugger
  // if (resultStatus.includes(type)) {
  //   return (
  //     <Result
  //       {...rest}
  //       className={className}
  //       style={style}
  //       icon={contentImgComponent}
  //       status={contentCode}
  //       title={contentTitle}
  //       subTitle={contentDesc}
  //       extraButton={createElement(linkElement, {
  //         to: '/',
  //         href: '/',
  //       }, <Button type="primary">Return home</Button>)}
  //     >
  //       {rest.children}
  //     </Result>
  //   );
  // } else {
  //   return (
  //     <div className={className} style={style}>
  //       <div className="codeWrapper">
  //         <h3>{contentTitle}</h3>
  //       </div>
  //       <div className="contentWrapper">
  //         <h5>{contentTitle}</h5>
  //         <div className="description">{contentDesc}</div>
  //         <div className="imageWrapper">
  //           {contentImgComponent}
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }
};

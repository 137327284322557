import React from 'react';
import { connect } from 'react-redux';

import { addEventToAnalytics } from 'Utils';

import { DonutChartS1 } from 'components/charts/Recharts'

import { RegionColors, SectorColors } from 'data/Colors'
import { SelectorDD, KeyStats3, KeyStats4, NoData } from './Common'

export class MultipleAllocationsChart extends React.Component {
  formatter = val => `${val.toFixed(1)}%`;

  render() {
    if (typeof this.props.stats === 'undefined' || typeof this.props.stats.allocationPriceSectors === 'undefined' || typeof this.props.stats.priceRegions === 'undefined') return (<NoData height='225px' />);

    // const { priceRegions: regions, priceSectors: sectors } = this.props.stats;
    const assetData = [];
    const sectorData = this.props.stats.allocationPriceSectors.length > 0 ? this.props.stats.allocationPriceSectors : []; // this.chartData();
    const regionData = this.props.stats.priceRegions.length > 0 ? this.props.stats.priceRegions : [];

    return (
      <React.Fragment>
        <h5 className="mb-1 card-title p-0">Allocations</h5>
        <div className="accounts-allocations-chart-container">
          {(assetData > 0) && (
            <div className="accounts-allocations-chart-wrapper">
              {(assetData <= 0) ? (
                <NoData height='100px' />
              ) : (
                <DonutChartS1 data={assetData} formatter={this.formatter} />
              )}
              <div className="accounts-allocations-chart-label">By Asset class</div>
            </div>
          )}
          <div className="accounts-allocations-chart-wrapper">
            {(sectorData <= 0) ? (
              <NoData height='100px' />
            ) : (
              <DonutChartS1 data={sectorData} formatter={this.formatter} />
            )}
            <div className="accounts-allocations-chart-label">By Sectors</div>
          </div>
          <div className="accounts-allocations-chart-wrapper">
            {(regionData <= 0) ? (
              <NoData height='100px' />
            ) : (
              <DonutChartS1 data={regionData} formatter={this.formatter} />
            )}
            <div className="accounts-allocations-chart-label">By Regions</div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export class SingleAllocationsChart extends React.Component {
  opts = [ 'Sectors', 'Regions' ]
  state = { opt: this.opts[0] }

  optHandler = opt => {
    if(opt == 'Sectors'){
      addEventToAnalytics('Enhancer Allocation Sector','Enhancer Allocation Sector','ENHANCER_ALLOCATION_SECTOR',{},false);
    }
    else{
      addEventToAnalytics('Enhancer Allocation Region','Enhancer Allocation Region','ENHANCER_ALLOCATION_REGION',{},false);
    }
    this.setState({ opt })
  };
  formatter = val => `${val.toFixed(1)}%`;

  chartData = () => {
    const { priceRegions: regions, priceSectors: sectors } = this.props.stats;
    return this.state.opt === this.opts[0]
            // ? Object.keys(sectors).map((e, i) => ({ name: e, value: sectors[e], color: SectorColors[i%SectorColors.length] }))
            ? this.props.stats.allocationPriceSectors.length > 0 ? this.props.stats.allocationPriceSectors : []
            // : Object.keys(regions).map(e => ({ name: e, value: regions[e], color: RegionColors[e] }));
            : this.props.stats.priceRegions.length > 0 ? this.props.stats.priceRegions : [];
  }

  render() {
    const data = this.chartData();

    return (
      <React.Fragment>
        <SelectorDD opts={this.opts} optHandler={this.optHandler} />
        <h5 className="mb-1 card-title p-0">Allocations</h5>
        { data.length <= 0
          ? <NoData height='225px' />
          :  <div className="d-flex flex-column justify-content-center align-items-center" style={{minHeight: '225px'}}>
              <div style={{ width: "90%", height: "140px" }}>
                <DonutChartS1 data={data} formatter={this.formatter} />
              </div>
            </div> }
      </React.Fragment>
    )
  }
}

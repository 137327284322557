import { createAPIInstance } from 'apis/api'


export const fetchReportData = async (requestQuery) => {
    return createAPIInstance()
      .get('/user/get-compare-reports', {params: {id: requestQuery}})
      .then(response => response.data)
}

export const fetchPortfolioBenchmark = async (ratio) => {
    console.log(ratio)
    return createAPIInstance()
      .get('/v1/user/portfolio-benchmark')
      .then(result => {
        let benchMark = result.data.filter(data => data.ticker === ratio)
        let ratio36 = []
        let { returns, asset_allocation } = benchMark[0]
        for (let i = 0; i < 36; i++) {
          let { d, v } = returns[i]
          ratio36.push({ d, v })
        }

        return { chart3yr: ratio36.reverse(), asset_alloc: asset_allocation }
      })
}

export const fetchRatio = async (ratio) => {
	console.log(ratio)
	return createAPIInstance()
		.post('/get-selected-base-portfolios', JSON.stringify({ 'benchmark': ratio }), {
			headers: { 'Content-Type': 'application/json' }
		})
		.then(result => {
			let ratio36 = [], _returns = [];
			let { returns, asset_alloc } = result.data
      if (returns && returns.length) {
        for (let i = 0; i < 36; i++) {
          let { d, v } = returns[returns.length - i - 1]
          ratio36.push({ d, v: v / 100 })
        }
        returns.forEach(item => {
          _returns.push({ d: item.d, v: (item.v / 100)/100 })
        })
      }
			return { chart3yr: ratio36.reverse(), returns: _returns, asset_alloc }
		})
}

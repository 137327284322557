import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import DataTable, { createTheme } from 'react-data-table-component';
import { mockData } from '../../PortfolioTable/mockData';
import { clientHoldingsRequest } from 'actions/clientportfolioV2';
import { FormatCurrency } from 'Utils';
import { scrollBarCheck } from '../../../Utils';
import orderBy from 'lodash/orderBy';
import { ShowSpinner } from '../../Shared/ShowSpinner';
import { NoDataComponent } from '../../Shared/NoDataComponent';

createTheme('blue-sm', {
  text: {
    primary: '#268bd2',
    secondary: '#2aa198',
  },
  background: {
    default: '#e1effa',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#FFFFFF',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
});

class Holdings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {};
  }  

  componentDidMount(){
    scrollBarCheck('.holdings-wrapper');
  }

  componentDidUpdate(){
    scrollBarCheck('.holdings-wrapper');
  }

  getValue = (price, shares) => {
    return (price && shares) ? FormatCurrency(Math.round(price.toFixed(1) * shares), 0, '$', 'unf') : 'NA'
  }

  customSort = (rows, field, direction = 'desc') => {
    const handleField = row => {
      if(field == 'Value'){
        return (row.current_price && row.shares) ? row.current_price * row.shares : 0;
      }else{
        if (row[field]) {
          return row[field].toLowerCase();
        }
        return row[field];
      }      
    };
    return orderBy(rows, handleField, direction);
  };

  render() {

    const { selectedClientHoldings } = this.props;
    // const HoldingsData = [{id: 1, ticker: 'FCOM', name: 'FIDELITY CONVINGTON TR', qty: 209, value: '$ 7095.55', price: '$ 33.95'}];
    const HoldingsData = selectedClientHoldings.holdings;

    const HoldingsColumns = [
      {
        name: 'Ticker',
        cell: row => row.ticker || row.script,
        sortable: false,
      },
      {
        name: 'Name',
        selector: 'name',
        sortable: false,
        grow: 2,
        wrap: true
      },
      {
        name: 'Quantity',
        selector: 'shares',
        sortable: false,
        center: true
      },
      {
        name: 'Value',
        cell: row => this.getValue(parseFloat(row.current_price), row.shares),
        sortable: false,
        center: true
      },
      {
        name: 'Price',
        cell: row => row.current_price ? FormatCurrency(parseFloat(row.current_price).toFixed(1), 0, '$', 'unf') : 'NA',
        sortable: false,
        center: true
      }
      
    ];

    const customStyles = {
      rows: {
        style: {
          minHeight: '35px', // override the row height
        }
      }
    };    

    return (
      <React.Fragment>
        <div className="tbl-content holdings-wrapper">
          <DataTable
            noHeader
            columns={HoldingsColumns}
            data={HoldingsData}
            customStyles={customStyles}
            pagination={false}
            theme="blue-sm" 
            fixedHeader={true}
            fixedHeaderScrollHeight={'100vh'}  
            sortFunction={this.customSort}      
            defaultSortField="Value" 
            defaultSortAsc={false}
            noDataComponent={<NoDataComponent />}
            progressPending={HoldingsData && HoldingsData.length > 0 ? false : true}
            progressComponent={<ShowSpinner />}
            persistTableHead
          />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ clientportfolio: { selectedClientHoldings } }) => ({
  selectedClientHoldings
})

const mapDispatchToProps = {
  clientHoldingsRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Holdings);
import React, { Component, useState } from 'react';
import _ from 'lodash';
import { Row, Col, Typography } from 'antd';

import { sponsorLogo } from 'DataSet';
import { FormatCurrency } from 'components/reports/shared/Utils';
import { FeeReturnRisk } from './FeeReturnRisk';
import { ViewChart } from './ViewChart';
import NotApplicable from 'components/NotApplicable'

import style from './ItemCard.module.scss'

const { Paragraph } = Typography;

export class ItemCard extends Component {
  render() {
    const {
      data,
      ticker,
      classes
    } = this.props;
    const id = data.index ? 'cb-'+ticker+data.index+data.id : 'cb-'+ticker+data.vehicle+data.ticker;
    const {
      FRR,
      odometerChart,
    } = data;
    const getTabpaneDesign = (chartDataObj) => {
      if (chartDataObj) {
        return (
          <ViewChart
            size="md"
            i={id}
            data={chartDataObj}
            width={130}
            ticker={ticker}
          />
        )
      }
    }
    return (
      <React.Fragment>
        <div className={`${style.card} ${classes}`}>
          <div className={`${style.topCard} p-1`}>
            <Row style={{minHeight: '90px'}}>
              <TickerPrice
                className="d-flex flex-column justify-content-center align-items-center"
                ticker={data.ticker}
                price={data.cardInfo && data.cardInfo.nav}
              />
              <NameSponsor
                className="d-flex flex-column justify-content-center align-items-left"
                name={data.cardInfo && data.cardInfo.short_name}
                sponsor={data.cardInfo && data.cardInfo.sponsor}
                category={data.cardInfo && data.cardInfo.category}
              />
            </Row>
          </div>
          <div className={style.middleCard}>
            <FeeReturnRisk FRR={FRR} />
          </div>
          <div className={`relevanceChart ${style.relevanceChart}`}>
            {(odometerChart && odometerChart.length > 0) ? getTabpaneDesign(odometerChart[0]) : (
              <div className="not-applicable-icon-wrapper">
                <NotApplicable />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ItemCard;

//className="d-flex flex-column justify-content-center align-items-center" style={{minWidth: '4rem'}}
const TickerPrice = ({ ticker, price, className }) => (
  <Col className={className} span={8}>
    <h5 className="mb-0 ticker-size ticker-price">{ticker}</h5>
    <h4 className="mt-1 mb-0 ticker-price">{FormatCurrency(price, 2)}</h4>
  </Col>
)

const NameSponsor = ({ name, sponsor, category, className }) => (
  <Col className={className} span={16}>
    <div className="pb-1">
      <ImageOrText img={sponsorLogo(sponsor)} text={sponsor} />
    </div>
    <h5 className="m-0" title={name} style={{ fontWeight: 600, lineHeight: 1.2 }}>
      <Paragraph
        className="text-muted item-card-text"
        ellipsis={{rows: 2}}
        title={name}
      >
        {name}
      </Paragraph>
    </h5>
    <h5 className={`${style.short_name} mb-0 text-gray-6`} title={category} style={{ lineHeight: 1.4, color: '#57869c', marginTop: 3, }}>
      <Paragraph
        className="m-0 category-text"
        ellipsis={{rows: 1}}
        title={category}
      >
        {category}
      </Paragraph>
    </h5>
  </Col>
)

const ImageOrText = ({ img, text = '' }) => {
  const [valid, setValid] = useState(true);
  return (
    valid
      ? <img src={img} className="img-fluid" alt="" onError={() => setValid(false)} style={{height: '20px'}} />
      : <div className="sponsor-logo-placeholder" title={text}>{text}</div>
  );
}

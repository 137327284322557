import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import qs from 'query-string'

// import { Formik } from 'formik'
import * as Yup from 'yup'
import { get } from 'lodash'

// import { Row, Col } from 'reactstrap';

import {
  successfulAuth,
  failedGoogleAuth,
  auth as authLogin,
  successfulGoogleAuth,
  oktaAuthLoginRequest,
  autoLoginByTokenRequest,
} from 'actions/auth';
import {
  register,
  registerGoogle,
  setRegisterState,
  successfulRegister,
  failedGoogleRegister,
  registerByOktaStepsRequest,
} from 'actions/register';
// import { storeAuthToken } from 'common/auth-utils'
import { registerProfileData, failedRegister } from 'actions/register';

import { updateProfile, successfulUpdateProfile, failedUpdateProfile, fetchProfile } from 'actions/profile'
import { createLoadingSelector } from 'selectors/loading-selector';
// import { Spinner } from 'shared-components/';
import { isTermsAgreed } from 'selectors/auth-selector';
// import { REDIRECT_DOMAIN_PATH } from 'DataSet';
// import { addEventToAnalytics } from 'Utils';

import RegisterFormOpt2 from './RegisterFormOpt2';
import * as RegisterApi from 'apis/register';
// import * as AuthUtils from '../../../common/auth-utils';
import * as ProfileApi from 'apis/profile';
// import { getAgeMapping } from '../Constant/FormData';
import { createSubscriptionRequest } from 'actions/profile'
import config from '../../../env';
import { getAdditionalDetailsForRegister, searchUrlV3, addEventToAnalytics } from 'Utils';
import TifinWealthMessage from 'components/TifinWealthMessage'
import { Regex } from 'common/regex';
import { encryptPassword, openNotificationWithIcon } from 'layouts/utils';

import { oktaAuth } from 'oktaAuth';

// const magnifiLogo = "/assets/images/MagnifiLogo/Magnifi_Tagline_White_RGB@2x.png";

class Register extends Component {
  state = {
    modal: true,
    showSuccess: false,
    step: 1,
    isStripeSubscription: false,
    plan_selected:'',
    isFetching: false,
    isPlanChange: false,
    disableRegisterButton: false,
    planType: '',
    CalendarInviteScreen: false
  }

  initialValues = {
    termsCondition: false,
    planName: 'Premium Lite', // default $250 plan
    //accredited_investor:false,
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].style.paddingTop = 0;
    let plan = this.props.history.location.state?this.props.history.location.state.plan?this.props.history.location.state.plan:null:null;
    const planType = get(this.props.history, 'location.state.planType', null);
    //basic, pro, pro-plus
    if(!this.props.loggedIn){
      if(plan===null){
        const parsedQ = qs.parse(this.props.history.location.search);
        if(parsedQ['plan'] && ['basic', 'pro', 'pro-plus', 'enhancer-pro'].indexOf(parsedQ['plan']) > -1){
          plan = parsedQ['plan'];
          if(plan === 'pro'){
            this.setState({ plan_selected:plan, planType: 'selector' })
          } else {
            if(plan === 'enhancer-pro'){
              plan = 'pro'
            }
            this.setState({ plan_selected:plan, planType: 'enhancer' })
          }
        } else {
          this.props.history.push(`/join?`);
        }
      }else{
        this.setState({
          plan_selected:plan,
          planType
        })
      }
      addEventToAnalytics('Register Page','Register Page','REGISTER_PAGE',{ clickPage : 'Register Page'});
    } else {
      this.props.history.push(`/securities`);
    }
  }

  componentDidUpdate(prevProps) {
    const { profileUpdateSuccess } = this.props
    const { isStripeSubscription } = this.state
    if(profileUpdateSuccess !== prevProps.profileUpdateSuccess && profileUpdateSuccess && isStripeSubscription){
      this.generateSessionId()
    }
  }

  generateSessionId() {
    this.setState({isFetching: true})
    this.props.createSubscriptionRequest({ plan_name: this.state.planName, planType: this.state.planType }, this.subscribeNow)
  }

  subscribePlan = (plan) => {
    switch (plan) {
      case '$250':
        // $250 plan
        this.setState({
          planName: 'Premium Lite',
        });
      break;
      case '$500':
        // $500 plan
        this.setState({
          planName: 'Premium Plan',
        });
      break;
      default:
        // $250 plan
        this.setState({
          planName: 'Premium Lite',
        });
    }
    this.setState({ isStripeSubscription: true })
  }

  subscribeNow = (sessionId) => {
    this.setState({isFetching: false})
    // const sessionId = this.props.stripeSessionID;
    if(sessionId){
      const stripe = window.Stripe(config.stripePublishableKey);
      stripe.redirectToCheckout({
        sessionId
      }).then((result) => {
        console.log(result)
        // console.log("results status",result)
      });
    }
  }

  nextClick = async (values,  {setSubmitting} ,showStep2=false) => {
    // console.log('nextClick',values, this.state.plan_selected)
    // values.password = encryptPassword(values.password);
    values.rePassword= values.password; //encryptPassword(values.rePassword);
    let data = {
      ...values,
      firstName: values.first_name, // for OKTA
      lastName: values.last_name, // for OKTA
      register_step_status: '1', // for OKTA
    };
    this.setState({
      disableRegisterButton:true
    })
    let isPlanChange=localStorage.getItem('isPlanChange')?JSON.parse(localStorage.getItem('isPlanChange')):false
    if(!isPlanChange){
      let { plan_selected } = this.state
      if(plan_selected==='pro'){
        this.subscribePlan('$250')
      }else if(plan_selected==='pro-plus'){
        this.subscribePlan('$500')
      }
    }else{
      this.setState({
        isPlanChange:isPlanChange
      })
    }

    try {
      // console.log({
      //   e : true,
      //   ...values,
      //   ...getAdditionalDetailsForRegister(),
      //   magnifi_self_role: values.self_role,
      //   groupIDs: ['00g5gibu72o0DTErw5d7'],
      // })
      const { registerByOktaStepsRequest } = this.props;
      if (registerByOktaStepsRequest) {
        const payload = {
          e : true,
          ...data,
          // ...getAdditionalDetailsForRegister(),
          magnifi_self_role: data.self_role,
          groupIDs: ['00g5gibu72o0DTErw5d7'],
        };
        registerByOktaStepsRequest(payload, (response) => {
          console.log('response --> ', response);
        });
      }

      // if (oktaAuth &&
      //   oktaAuth.idx &&
      //   autoLoginByTokenRequest
      // ) {
      //   const rep = await oktaAuth.idx.register()
      //   .then(r => {
      //     console.log('registerResponse -->', r)
      //     if (registerResponse) {
      //       const rep1 = await oktaAuth.idx.proceed({
      //         username: payload.email,
      //         email: payload.email,
      //         firstName: payload.firstName,
      //         lastName: payload.lastName,
      //         magnifi_self_role: payload.magnifi_self_role,
      //       }).then(step2 => {
      //         console.log('step2 -->', step2)
      //         const rep2 = await oktaAuth.idx.proceed({
      //           authenticator: 'okta_password',
      //         }).then(step3 => {
      //           console.log('step3 -->', step3)
      //           const rep3 = await oktaAuth.idx.proceed({
      //             password: payload.password,
      //           }).then(step4 => {
      //             console.log('step4 -->', step4)
      //             const rep4 = await oktaAuth.idx.proceed({
      //               skip: true,
      //             }).then(tokenResponse => {
      //               console.log('tokenResponse --> ', tokenResponse)
      //               if (tokenResponse &&
      //                 tokenResponse.status === 'SUCCESS' &&
      //                 tokenResponse.tokens &&
      //                 tokenResponse.tokens.accessToken &&
      //                 tokenResponse.tokens.accessToken.tokenType &&
      //                 tokenResponse.tokens.accessToken.accessToken
      //               ) {
      //                 const _tokenResponse = {
      //                   tokenType: tokenResponse.tokens.accessToken.tokenType,
      //                   accessToken: tokenResponse.tokens.accessToken.accessToken
      //                 };
      //                 console.log('tokenResponse --> ', _tokenResponse)
      //                 autoLoginByTokenRequest({
      //                   token: tokenResponse.tokens.accessToken.accessToken
      //                 });
      //
      //                 addEventToAnalytics(
      //                   'Register Save Profile',
      //                   'Register Save Profile',
      //                   'REGISTER_SAVE_PROFILE', {
      //                     email: data.email,
      //                     user_type: 'Advisor'
      //                   }
      //                 );
      //
      //               }
      //             }).catch(err => {
      //               console.log('step4 err', err)
      //             });
      //           }).catch(err => {
      //             console.log('step3 err', err)
      //           });
      //         }).catch(err => {
      //           console.log('step2 err', err)
      //         });
      //       }).catch(err => {
      //         console.log('step1 err', err)
      //       })
      //     }
      //
      //   })
      //   .catch(err => {
      //     console.log(err);
      //     if (err) {
      //       console.log('error --> ', err.toString())
      //       let error = err && err.data ? err : err.toString();
      //       this.setState({
      //         disableRegisterButton:false
      //       })
      //       if(error && typeof error.data !== 'undefined') {
      //         console.log(error.data)
      //         if (typeof error.data.email !=='undefined' && error.data.email[0] === 'E-mail ID already registered') {
      //           error = error.data.email[0];
      //         }
      //         if (error.data.errorCauses &&
      //           Array.isArray(error.data.errorCauses) &&
      //           error.data.errorCauses.length &&
      //           error.data.errorCauses[0] &&
      //           error.data.errorCauses[0].errorSummary
      //         ) {
      //           error = error.data.errorCauses[0].errorSummary;
      //         } else {
      //           if (error.data && error.data.errorSummary) {
      //             console.log(error.data)
      //             error = error.data.errorSummary;
      //           }
      //         }
      //       }
      //       setSubmitting(false);
      //       openNotificationWithIcon({
      //         duration: 5,
      //         type: 'error',
      //         message: 'Error',
      //         className: 'api-response-notification-class',
      //         description: error,
      //       });
      //     }
      //   })
      //
      //   console.log(rep);
      // }

      // RegisterApi.executOktaRegister({
      //   userProfile: {
      //     e : true,
      //     ...data,
      //     ...getAdditionalDetailsForRegister(),
      //     magnifi_self_role: data.self_role,
      //     groupIDs: ['00g5gibu72o0DTErw5d7'],
      //   },
      // }).then(oktaRegisterResponse => {
      //   console.log('oktaRegisterResponse --> ', oktaRegisterResponse)
      //   const { authLogin } = this.props;
      //   if (authLogin &&
      //     oktaRegisterResponse &&
      //     oktaRegisterResponse.activationToken
      //   ) {
      //     // activationToken: "i45X7UoYfYy8zZAW5x0H"
      //
      //     // this.setState({
      //     //   disableRegisterButton: false,
      //     //   oktaActivationToken: oktaRegisterResponse.activationToken
      //     // });
      //     // if (this.state.plan_selected == 'basic' && (values.self_role !== 'Individual Investor' &&  values.self_role !== 'Other/Curious' && !isPlanChange)) {
      //     //   // NOTE: Show Calendar invite screen
      //     //   // ------------------------------------------------------------
      //     //   this.setState({
      //     //     CalendarInviteScreen: true,
      //     //   });
      //     //   // ------------------------------------------------------------
      //     // } else {
      //     //   showStep2 && this.setStep(2)
      //     // }
      //
      //     authLogin({
      //       executOktaLoginFor: 'register',
      //       token: oktaRegisterResponse.activationToken,
      //     }, (callbackResponse) => {
      //       console.log('--- callback ---', callbackResponse)
      //       // // debugger
      //       // addEventToAnalytics('Not Yet. Take Me To My Account','Not Yet. Take Me To My Account','NOT_YET_TAKE_ME_TO_MY_ACCOUNT',{});
      //       // // NOTE: Get query from discovery reducer's state
      //       // // --------------------------------------------------------------------------------------------
      //       // // const { discoveryQuery } = this.props;
      //       // let discoveryQuery = this.props.discoveryQuery || window.sessionStorage.getItem('query') || ''
      //       // const securitiesPath = searchUrlV3(discoveryQuery); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
      //       // // --------------------------------------------------------------------------------------------
      //       // this.props.history.push(securitiesPath);
      //     })
      //
      //     addEventToAnalytics(
      //       'Register Save Profile',
      //       'Register Save Profile',
      //       'REGISTER_SAVE_PROFILE', {
      //         email: data.email,
      //         user_type: 'Advisor'
      //       }
      //     );
      //
      //   } else {
      //     throw oktaRegisterResponse;
      //   }
      //
      // }).catch(err => {
      //   console.log(err);
      //   if (err) {
      //   	console.log('error --> ', err.toString())
      //   	let error = err && err.data ? err : err.toString();
      //   	this.setState({
      //   		disableRegisterButton:false
      //   	})
      //   	if(error && typeof error.data !== 'undefined') {
      //   		console.log(error.data)
      //   		if (typeof error.data.email !=='undefined' && error.data.email[0] === 'E-mail ID already registered') {
      //   			error = error.data.email[0];
      //   		}
      //   		if (error.data.errorCauses &&
      //   			Array.isArray(error.data.errorCauses) &&
      //   			error.data.errorCauses.length &&
      //   			error.data.errorCauses[0] &&
      //   			error.data.errorCauses[0].errorSummary
      //   		) {
      //   			error = error.data.errorCauses[0].errorSummary;
      //   		} else {
      //   			if (error.data && error.data.errorSummary) {
      //   				console.log(error.data)
      //   				error = error.data.errorSummary;
      //   			}
      //   		}
      //   	}
      //   	setSubmitting(false);
      //   	openNotificationWithIcon({
      //   		duration: 5,
      //   		type: 'error',
      //   		message: 'Error',
      //   		className: 'api-response-notification-class',
      //   		description: error,
      //   	});
      //   }
      // })

      // NOTE: Current prod logic
      // ------------------------------------------------------------------------------------------
            // let _responseData = await RegisterApi.register(qs.stringify({
            //   ...values,
            //   e : true,
            //   ...getAdditionalDetailsForRegister()
            // }));
            // // NOTE: commenting clearUtmSession, we need to UTM values for INVESTMENT ACCOUNT CREATED EVENT
            // // clearUtmSession();
            // AuthUtils.storeAuthToken({
            //   token: _responseData.data.token,
            //   firstName: _responseData.data.first_name,
            //   lastName: _responseData.data.last_name.trim(),
            //   email: values.email,
            //   isGoogleLogin: false,
            //   newUser: _responseData.data.new_user,
            //   termsAgreed: _responseData.data.terms_agreed ? 1 : 0,
            //   profile_self_role: _responseData.data.self_role
            // })
            // _responseData.data.firstName= _responseData.data.first_name
            // _responseData.data.lastName= _responseData.data.last_name.trim()
            //
            // this.props.successfulRegister(_responseData.data);
            // this.props.successfulAuth({
            //   ..._responseData.data,
            //   termsAgreed: _responseData.data.terms_agreed ? 1 : 0,
            //   profile: {
            //     ..._responseData.data,
            //     newUser: _responseData.data.new_user,
            //     termsAgreed: _responseData.data.terms_agreed ? 1 : 0,
            //   }
            // })
            // let data2 = {
            //   zipcode:values.zipcode,
            //   country:values.country,
            //   termsCondition:false
            // }
            // addEventToAnalytics('Register', 'Register', 'REGISTER', { email: values.email, user_type: 'Advisor', action: 'Register' });
            //
            // // NOTE: Google Analytics event --> Advisor Event
            // // 'Magnifi Advisor Account Created', {properties: {category: 'Advisor'}, category: 'Advisor'}
            // // ------------------------------------------------------------------------------------------------------------------------------
            // addEventToAnalytics(
            //   'Magnifi Advisor Account Created',
            //   'Magnifi Advisor Account Created',
            //   'MAGNIFI_ADVISOR_ACCOUNT_CREATED', {
            //     properties: {
            //       category: 'Advisor'
            //     },
            //     category: 'Advisor'
            //   });
            // // ------------------------------------------------------------------------------------------------------------------------------
            //
            // this.props.updateProfile(data2);
            //
            // if (this.state.plan_selected == 'basic' && (values.self_role !== 'Individual Investor' &&  values.self_role !== 'Other/Curious' && !isPlanChange)) {
            //   // NOTE: Show Calendar invite screen
            //   // ------------------------------------------------------------
            //   this.setState({
            //     CalendarInviteScreen: true,
            //   });
            //   // ------------------------------------------------------------
            // } else {
            //   showStep2 && this.setStep(2)
            // }
            // this.setState({
            //   disableRegisterButton:false
            // })
            // addEventToAnalytics('Register Save Profile','Register Save Profile','REGISTER_SAVE_PROFILE',{ email:data.email, user_type: 'Advisor' });
            //
      // ------------------------------------------------------------------------------------------
    } catch (error) {
      console.log(error);
      this.setState({
        disableRegisterButton:false
      })
      if(error && typeof error.response !== 'undefined') {
        if (typeof error.response.data !== 'undefined' && typeof error.response.data.email !=='undefined' && error.response.data.email[0] === 'E-mail ID already registered') {
          this.props.failedRegister(error.response,error.response.data)
          setSubmitting(false);
        }
      }
    }
  }

  handleCalendarOkClick = () => {
    const { oktaActivationToken } = this.state;
    const { authLogin } = this.props;
    if (oktaActivationToken && authLogin) {
      authLogin({
        token: oktaActivationToken,
        executOktaLoginFor: 'register',
      }, (callbackResponse) => {
        console.log('--- callback ---', callbackResponse)
        // // debugger
        // addEventToAnalytics('Not Yet. Take Me To My Account','Not Yet. Take Me To My Account','NOT_YET_TAKE_ME_TO_MY_ACCOUNT',{});
        // // NOTE: Get query from discovery reducer's state
        // // --------------------------------------------------------------------------------------------
        // // const { discoveryQuery } = this.props;
        // let discoveryQuery = this.props.discoveryQuery || window.sessionStorage.getItem('query') || ''
        // const securitiesPath = searchUrlV3(discoveryQuery); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
        // // --------------------------------------------------------------------------------------------
        // this.props.history.push(securitiesPath);
      })
    }
  }

  setStep = step => {
    console.log('setStep',step)
    this.setState({ step })
  };

  backHandler = () => {
    const { step } = this.state;
    if (step === 1)
    this.routeToPage()
    else if (step === 2){
      // this.setState({ step: 1 });
    }
  }

  handleGoogleSignInSuccess = (event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    const { registerGoogle } = this.props;
    if (registerGoogle) {
      registerGoogle();
    }

    // NOTE: COMMENTING BELOW LINE, EVERYTHING HANDLED BY OKTA
    // ---------------------------------------------------------------
    // const profile = response.profileObj;
    // // const token = response.tokenObj;
    // let { plan_selected, planType, isPlanChange } = this.state
    // this.props.registerGoogle({
    //   email: profile.email,
    //   first_name: profile.givenName,
    //   last_name: profile.familyName,
    //   g: 1,
    //   token: response.tokenId,
    //   plan_selected,
    //   planType
    // }, (apiResponse) => {
    //   console.log('-- Google registration completed, api response --> ', apiResponse);
    //   if (apiResponse && plan_selected === 'basic' && (apiResponse.original_self_role !== 'Individual Investor' && apiResponse.original_self_role !== 'Other/Curious' && !isPlanChange)) {
    //     // NOTE: Show Calendar invite screen
    //     // ------------------------------------------------------------
    //     this.setState({
    //       CalendarInviteScreen: true,
    //     });
    //     // ------------------------------------------------------------
    //   } else {
    //     console.log('couldnt able to open CalendarInviteScreen, check condition isPlanChange/apiResponse.self_role -->', isPlanChange, apiResponse.self_role);
    //     // fallback trigger
    //     this.handleCalendarOkClick();
    //   }
    // })
    // ---------------------------------------------------------------
  }

  handleGoogleSignInError = error => {
    console.log({ error });
    //TODO: why Google sso triggered error first time
    if (error !== 'SDK not loaded') {
      this.props.failedGoogleAuth(error)
    }
  }

  registerSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is a required field').matches(
      Regex.fullName,
      'First name is not valid'
    ),
    last_name: Yup.string().required('Last name is a required field').matches(
      Regex.fullName,
      'Last name is not valid'
    ),
    email: Yup.string()
    .email()
    .required('Email is a required field'),
    password: Yup.string()
    .required('Password is a required field')
    .min(8, 'Password has to be longer than 8 characters!')
    .matches(
      /(?=.*[a-z])/,
      'Password should contain at least one lower case letter',
    )
    .matches(
      /(?=.*[A-Z])/,
      'Password should contain at least one upper case letter',
    )
    .matches(/(?=.*[0-9])/, 'Password should contain at least one number')
    .matches(
      /(?=.*[!@#\$%\^&\*?,.()|<>|])/,
      'Password should contain atleast one special character ',
    ),
    rePassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is a required field')
    .min(8, 'Password must be at least 8 characters long')
    .matches(
      /(?=.*[a-z])/,
      'Password should contain at least one lower case letter',
    )
    .matches(
      /(?=.*[A-Z])/,
      'Password should contain at least one upper case letter',
    )
    .matches(/(?=.*[0-9])/, 'Password should contain at least one number')
    .matches(
      /(?=.*[!@#\$%\^&\*?,.()|<>|])/,
      'Password should contain atleast one special character ',
    ),
    termsCondition: Yup.boolean().oneOf(
      [true],
      'Please accept terms and conditions'
    ),
    country:Yup.string().required('Please select a country'),
    zipcode:Yup.string().required('Zip is a required field'),
    age_group:Yup.string().required('Please select a age group'),
    self_role: Yup.string().required('Please select your profile'),

    areYouAnOther: Yup.string().when('self_role', {
      is: (self_role) => self_role === 'Other',
      then: Yup.string().required('Enter a value'),
      otherwise: Yup.string()
    }),

    investable_assets: Yup.string()
    .when('self_role', {
      is: (self_role) => (self_role === 'Individual Investor' ||  self_role === 'Other/Curious'),
      then: Yup.string().required('Please Select a Investable Assets'),
    }),

    estimated_net_worth: Yup.string()
    .when('self_role', {
      is: (self_role) => (self_role === 'Financial Advisor' ||  self_role === 'Family Office' ||
      self_role === 'Portfolio Manager' || self_role === 'Hedge Fund Manager' ||
      self_role === 'Corporate Investor'
      ),
      then: Yup.string().required('Please Select a Assets Under Management/ Advice.'),
    }),

  })

  routeToPage = () => {
    let discoveryQuery = this.props.discoveryQuery || window.sessionStorage.getItem('query') || ''
    const currentPath = get(this.props,'location.pathname','');
    // NOTE: Get query from discovery reducer's state
    // --------------------------------------------------------------------------------------------
    const securitiesPath = searchUrlV3(discoveryQuery); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
    // --------------------------------------------------------------------------------------------
    const pathname = this.props.fromPath || securitiesPath || '/';
    if(pathname !== currentPath) {
      if(this.props.fromPath){
        this.props.history.push(pathname)
      }
      else{
        this.props.history.goBack();
      }
    }
  }

  updateUsersProfile = async (postData, callback) => {
    try {
      this.setState({isFetching: true});
      let _responseData = await ProfileApi.updateProfile(qs.stringify({...postData}));
      callback({responseData: _responseData});
      this.setState({isFetching: false});
    }  catch(error) {
      console.log(error);
      this.setState({isFetching: false});
      if (error && typeof error.response !== 'undefined') {
        if (typeof error.response.data !== 'undefined') {
          //TO DO
        }
      }
    }
  }

  updatePlanChange= (value)=>{
    this.setState({
      isPlanChange:value
    })
  }

  render() {
    const { loggedIn, registerStepStatus, registerGoogleAuthToken, registerAppleAuthToken, googleRegisterSuccess, createSubscriptionRequest } = this.props;
    const { isStripeSubscription,plan_selected,isPlanChange,disableRegisterButton, planType, CalendarInviteScreen } = this.state
    // console.log({ loggedIn, isPlanChange,plan_selected})
    if (registerGoogleAuthToken && registerAppleAuthToken && registerStepStatus === '0') {
      // return <Redirect to={`/register-profile/${registerGoogleAuthToken}`} />
    } else {
      if(loggedIn && isPlanChange ) {
        // console.log('plan change redirect to join')
        localStorage.removeItem('isPlanChange')
        this.setState({
          isPlanChange: false
        })
        return <Redirect to={{
          pathname: "/join",
          state: { isPlanChange: true, planType }
        }} />
      }
      // if (!isStripeSubscription && loggedIn && window.location.search.length === 0 && (registerStepStatus == 2 || googleRegisterSuccess === true) && !isPlanChange) {
      if (!isStripeSubscription && loggedIn && window.location.search.length === 0 && (registerStepStatus === 2) && !isPlanChange) {
        // // console.log('redirect to securities')
        // // if query is not present and loggedIn then redirect to magnifi.com/discover
        // const pathname = '/securities';
        // return (
        //   <Redirect
        //     to={{
        //       pathname,
        //       search: window.location.search
        //     }}
        //   />
        // )

        // NOTE: Get query from discovery reducer's state
        // --------------------------------------------------------------------------------------------
        const discoveryQuery = this.props.discoveryQuery || window.sessionStorage.getItem('query') || '';
        const securitiesPath = searchUrlV3(discoveryQuery); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
        console.log('register flow google-login -> ', securitiesPath)
        this.props.history.push(securitiesPath);
        // --------------------------------------------------------------------------------------------

      }

      // if (!isStripeSubscription && profileUpdateSuccess) {
      //   // return <Redirect to="/register-complete" />
      //   console.log('redirect to securities')
      //   return <Redirect to="/securities" />
      // }
    }

    return (
      <>
        <TifinWealthMessage />
        <RegisterFormVariants
          showCalendarInviteScreen={CalendarInviteScreen}
          isFetching={this.props.isFetching || this.state.isFetching}
          initialValues={this.initialValues}
          onSubmit={this.handleSubmit}
          validationSchema={this.registerSchema}
          onToggle={this.handleToggle}
          nextClick={this.nextClick}
          error={this.props.error}
          onGoogleSignInSuccess={this.handleGoogleSignInSuccess}
          onGoogleSignInError={this.handleGoogleSignInError}
          step={this.state.step}
          setStep={this.setStep}
          backHandler={this.backHandler}
          history={this.props.history}
          stripeSessionID={this.props.stripeSessionID}
          createSubscriptionRequest={createSubscriptionRequest}
          subscribeNow={(plan) => this.subscribePlan(plan)}
          updateUsersProfile={this.updateUsersProfile}
          plan_selected={plan_selected}
          updatePlanChange={this.updatePlanChange}
          loggedIn ={this.props.loggedIn}
          disableRegisterButton={disableRegisterButton}
          handleCalendarOkClick={this.handleCalendarOkClick}
        />
      </>
    )
  }
}

const mapStateToProps = ({ auth, register, loading, profile, discovery }) => ({
  discoveryQuery: discovery.query,
  registerGoogleAuthToken: register.registerGoogleAuthToken,
  registerAppleAuthToken: register.registerAppleAuthToken,
  registerStepStatus: register.registerStepStatus,
  registerFailed: register.registerFailed,
  registerSuccess: register.registerSuccess,
  error: register.error,
  loggedIn: auth.loggedIn,
  user: auth.user,
  profileUpdateSuccess: profile.profileUpdateSuccess,
  googleRegisterSuccess: register.googleRegisterSuccess,
  stripeSessionID: profile.stripeSessionID,
  isTermsAgreed: isTermsAgreed(auth),
  isFetching: createLoadingSelector([
    'REGISTER',
    'OKTA_AUTH_REGISTER',
    'REGISTER_BY_OKTA_STEPS',
  ])({loading}),
})

const mapDispatchToProps = {
  authLogin,
  register,
  failedGoogleAuth,
  registerGoogle,
  setRegisterState,
  registerProfileData,
  failedRegister,
  successfulRegister,
  successfulAuth,
  updateProfile,
  successfulUpdateProfile,
  failedUpdateProfile,
  successfulGoogleAuth,
  fetchProfile,
  failedGoogleRegister,
  createSubscriptionRequest,
  oktaAuthLoginRequest,
  autoLoginByTokenRequest,
  registerByOktaStepsRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);


const RegisterFormVariants = (props) => {
  const variant = '1';
  if (variant === '1') {
    const { history: { location } } = props
    const state = location.state ? location.state : {};
    return (
      <RegisterFormOpt2 {...props} from={state.from} createSubscriptionRequest={props.createSubscriptionRequest} subscribeNow={props.subscribeNow}/>
    )
  }
}

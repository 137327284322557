import * as React from "react";

export default class CarouselChart extends React.Component {
  state = {
    activeIndex: 1,
  };

  setActiveSlide = (activeIndex) => {
    this.setState({
      activeIndex,
    });
  };

  render() {
    const { data } = this.props;
    const { activeIndex } = this.state;

    return (
      <div className="siw-carousel">
        {data.map((theme, i) => {
          let classSuffix = '';
          if (activeIndex === i) {
            classSuffix = '0';
          } else
          if (activeIndex - i === 1) {
            classSuffix = 'm1';
          } else
          if (i - activeIndex === 1){
            classSuffix = '1';
          } else
          if (activeIndex - i === 2) {
            classSuffix = 'm2';
          } else
          if (i - activeIndex === 2) {
            classSuffix = '2';
          }
          return (
            <div
              key={i}
              className={`siw-slide-${classSuffix}`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.setActiveSlide(i);
              }}
            >
              <img
                src={`assets/images/themeIcons/${theme.name}.png`}
                title={`${theme.name}: ${(theme.value*100).toFixed()}`}
                alt={`${theme.name}: ${(theme.value*100).toFixed()}`}
              />
              {activeIndex === i &&
              <span className="siw-slide-label">{theme.name}</span>}
            </div>
          );
        })}
      </div>
    );
  }
};
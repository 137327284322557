import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  withRouter } from 'react-router-dom';
import { auth } from 'actions/auth'
import { Row, Col } from 'reactstrap';
import { REDIRECT_DOMAIN_PATH } from 'DataSet';
import { get } from 'lodash'
const magnifiLogo = "/assets/images/MagnifiLogo/Magnifi_Tagline_White_RGB@2x.png";
class RedirectLogin extends Component  {    
  getUrlParameter = (name)=> {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1]);
  };
    componentDidMount() {
        let token = this.getUrlParameter('token')
        let email = this.getUrlParameter('email')
        console.log("email",email)
        console.log("token",token)
        let payload = {
          token,
          email
        }
        this.props.auth(payload)

      }

      routeToPage = () => {
        const currentPath = get(this.props,'location.pathname','')
        const pathname = this.props.fromPath || '/'
    
        if(pathname!==currentPath) {
          this.props.history.push(pathname)
        }
    
      }
    render() {
    return (
      <React.Fragment>
          <Row className={`magnifi-auth-page-wrapper`}>
          <Col md="12" lg="12" className="left-side-panel-container-login">
            <a className="logo-wrapper" href={REDIRECT_DOMAIN_PATH}>
              <img src={magnifiLogo} height={70} alt="magnifi" />
            </a>
          </Col>
          {/* <Col md="6" lg="6" className="right-side-panel-container">
            <div className="close-icon" onClick={this.routeToPage}>
              <i className="fal fa-times-circle"></i>
            </div>
          </Col> */}
        </Row>
        </React.Fragment>
    )
   }
}

const mapStateToProps = ({ auth }) => ({
    loggedIn: auth.loggedIn,
    user: auth.user,
    error: auth.error,
    authFailed: auth.authFailed,
    authSuccess: auth.authSuccess,
  })
  const mapDispatchToProps = {
    auth,
  }
  
export default connect(
  mapStateToProps,
  mapDispatchToProps
) (withRouter(RedirectLogin));
import React from 'react';
import { Row, Col } from 'reactstrap';

import { cn } from 'DataSet';
// import { getNest } from 'Utils';
// import { calcStats } from 'Stats';

import ItemCard from './ItemCard';
import FundInfo from './FundInfo';

import { Loader } from './helper';
// import getFilteredListByRange from '../utils';

class ItemList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selCard: this.props.items[0],
      theList: []
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.items.find(e => e === this.state.selCard))
      this.selCardHandler(this.props.items[0])

    const elems = document.querySelectorAll('.custom-scroll')
    if (elems.length) {
      ;[].forEach.call(elems, function(el) {
        if (el.scrollHeight > el.clientHeight) el.style.paddingRight = '.25rem'
        else el.style.paddingRight = '.25rem'
      })
    }
  }

  selCardHandler = item => {
    if (this.state.selCard !== item) this.setState({ selCard: item })
  }

  render() {
    const { items, toggleFunDetails, showFunDetails, FunDetailTab } = this.props
    const { selCard } = this.state

    let selectorFunds = items.filter(v => v._selector === true);
    let DataList = selectorFunds.length > 0 ? selectorFunds : items;

    if (items.find((y) => y.is_sponsored)) {
      const fundsThatisSponsored = selectorFunds.length > 0 ? selectorFunds.filter((s) => s.is_sponsored) : items.filter((s) => s.is_sponsored);
      const fundsThatisNotSponsored = selectorFunds.length > 0 ? selectorFunds.filter((s) => !s.is_sponsored) : items.filter((s) => !s.is_sponsored);
      DataList = [...fundsThatisNotSponsored];
      if (fundsThatisSponsored.length) {
        fundsThatisSponsored.forEach((f, i) => {
          DataList.unshift(f);
        });
      }
    }

    return (
      <div className="h-100">
        <Row
          className="no-gutters px-2 media-p-0 media-m-0 h-100"
          style={{ marginRight: '1px' }}
        >
          <Col
            className={`col-md-3 col-sm-12 list-wrap-h100 ${
              showFunDetails ? 'd-none pt-2 pr-1' : 'p-2'
            } funds-listing-warapper`}
            style={{ overflow: 'hidden' }}
          >
            <h5 className="mb-0 text-center">
              <div className="w-75 pt-2 pb-1 rounded-top media-header mx-md-auto">
                Selected Funds
              </div>
            </h5>
            <div
              className={`${
                showFunDetails ? '' : 'h-405'
              } custom-scroll media-scroll-wrapper`}
            >
              <div
                className="bg-secondary rounded media-fund-card"
                style={{ paddingTop: '2px' }}
              >
                {DataList.map((e, i) => {
                  return (
                    <div key={cn(e, 'Id')}>
                      <ItemCard
                        {...this.props}
                        card={e}
                        clearHandler={this.props.clearHandler}
                        selected={e === selCard}
                        toggleFunDetails={cardDetails => {
                          this.selCardHandler(cardDetails)
                          toggleFunDetails(cardDetails)
                        }}
                        selCardHandler={this.selCardHandler}
                      />
                    </div>
                  )
                })}
                {this.props.loading && <Loader />}
              </div>
            </div>
          </Col>
          <Col
            className={`col-md-9 ${
              showFunDetails ? 'col-sm-12' : 'media-none'
            }`}
          >
            <FundInfo
              {...this.props}
              FunDetailTab={FunDetailTab}
              card={selCard}
              selCardHandler={this.selCardHandler}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

export default ItemList;

import React, { Component, Fragment, useState, useRef, useEffect } from 'react';
import './_index.scss';
import { Col, Row } from 'reactstrap';
import CountUp from "react-countup";

import { ReactComponent as FacebookIcon }from './../../../assets/images/facebook-app-symbol.svg';
import { ReactComponent as InstaIcon }from './../../../assets/images/instagram.svg';
import { ReactComponent as TwitterIcon }from './../../../assets/images/twitter.svg';
import { ReactComponent as LinkedinIcon }from './../../../assets/images/linkedin.svg';

import useInView from 'hooks/useInView'

const BusinessCounter = ({ noBlue }) => {
    const footerRef = useRef(null)
    const inView = useInView(footerRef)
    const [alreadyShownOnce, setAlreadyShownOnce] = useState(true)
    const end = () => setAlreadyShownOnce(false)

    return (
        <div ref={footerRef}>
        {!noBlue && (
            <div className="footer-blue-section r32">
                <div className="blue">
                    <Row className="m-0">
                        <Col className ="footer-column" md={3}>
                            <h3>Magnifi provides a gateway to 2,000+ Exchange Traded Funds (ETFs), 7,000+ Mutual Funds (MFs), over 1,100+ Model Portfolios and 10,000+ SMAs.</h3>
                        </Col >
                        <Col className ="footer-column" md={3}>
                        <div>
                            <h1 className="title">
                                {inView && alreadyShownOnce && <CountUp end={2238} separator = "," onEnd={end} delay={.2} />}
                                {!alreadyShownOnce && "2,238"}
                            </h1>
                            <p className="subtitle">ETFs listed in the U.S.</p>
                        </div>
                        <div>
                            <h1 className="title">
                                {inView && alreadyShownOnce && <CountUp end={96.8} decimal = "." decimals={1} prefix="$" suffix=" B" delay={.2} />}
                                {!alreadyShownOnce && "$96.8 B"}
                            </h1>
                            <p className="subtitle">Average daily value of U.S. ETF transactions</p>
                        </div>
                        </Col>
                        <Col className ="footer-column" md={3}>
                        <div>
                            <h1 className="title">
                                {inView && alreadyShownOnce && <CountUp end={7575} separator = "," delay={.2} />}
                                {!alreadyShownOnce && "7,575"}
                            </h1>
                            <p className="subtitle">Mutual Funds listed in the U.S.</p>
                        </div>
                        <div>
                            <h1 className="title">
                                {inView && alreadyShownOnce && <CountUp end={18.7} decimal = "." decimals={1} prefix="$" suffix=" T" delay={.2} />}
                                {!alreadyShownOnce && "$18.7 T"}
                            </h1>
                            <p className="subtitle">Annual Mutual Fund asset value</p>
                        </div>
                        </Col>
                        <Col className ="footer-column" md={3}>
                        <div>
                            <h1 className="title">
                                {inView && alreadyShownOnce && <CountUp end={1100} separator = "," suffix = "+" delay={.2} />}
                                {!alreadyShownOnce && "1,100+"}
                            </h1>
                            <p className="subtitle">Model Portfolios</p>
                        </div>
                        <div>
                            <h1 className="title">
                                {inView && alreadyShownOnce && <CountUp end={10000} separator = "," suffix = "+" delay={.2} />}
                                {!alreadyShownOnce && "10,000+"}
                            </h1>
                            <p className="subtitle">SMAs</p>
                        </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )}
        </div>
    )
}

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
        showMore: false
    }
  }

//   handleMail = mail => {
//     window.location.href="https://webmail.brinkster.com/zimbra/?view=compose&to=mailto%3Ahello%40magnifi.com#1"
//   }

  handleClick=(name)=>{
    switch(name){
        case "facebook":
        window.open('https://www.facebook.com/DiscoverMagnifi/','_blank' )
        break;
        case "insta":
        window.open('https://www.instagram.com/DiscoverMagnifi/','_blank' )
        break;
        case "twitter":
        window.open('https://twitter.com/DiscoverMagnifi','_blank' )
        break;
        case "linkedin":
        window.open('https://www.linkedin.com/company/discovermagnifi','_blank' )
        break;
    }

  }

  render() {
    const { noBlue } = this.props;
    return (
        <div className="footer-container">
        <BusinessCounter noBlue={noBlue} />
        <div className="footer-white-section r32">
            <div className="blue">
                <Row className="m-0">
                    <Col className ="footer-column" md={3} >
                        <h2 className="title">New York Office</h2>
                        <p className="subtitle">1 Pennsylvania Plaza,</p>
                        <p className="subtitle">39th Floor</p>
                        <p className="subtitle">New York, NY 10119</p>
                    </Col>
                    <Col className ="footer-column" md={3}>
                        <h2 className="title">Boulder Office</h2>
                        <p className="subtitle">1222 Pearl, Ste 200,</p>
                        <p className="subtitle">Boulder, CO 80302</p>
                    </Col>
                    <Col className ="footer-column" md={3}>
                        <h2 className="title">Get in touch with us.</h2>
                        <p className="subtitle">To learn more, contact</p>
                        <a className="subtitle mail" href="mailto:hello@magnifi.com">hello@magnifi.com</a>
                        <br />
                        <br />
                        <p className="subtitle">For questions &#38; support, contact</p>
                        <a className="subtitle mail" href="mailto:support@magnifi.com">support@magnifi.com</a>
                    </Col>
                    <Col className ="footer-column" md={3}>
                    <div className="footer-image">
                      <a href="https://tifin.com/" target="_blank" rel="noopener noreferrer">
                        <img src="/assets/images/homepage/Tifin_logo.svg"/>
                      </a>
                    </div>
                    </Col>
                </Row>
            </div>
        </div>
        <div className="footer-grey-section r32">
            <div className="grey">
            <p>{TERMS_LINK.map(o => (<Fragment><a href={o.link} className="term-and-conditions-link" target="_blank">{o.title}</a>{" | "}</Fragment>))}© {new Date().getFullYear()} Magnifi All rights reserved. Patent Pending</p>
            <div className="social_Icon">
                <FacebookIcon className="media-icon" onClick={()=>this.handleClick("facebook")}/>
                <InstaIcon className="media-icon" onClick={()=>this.handleClick("insta")}/>
                <TwitterIcon className="media-icon" onClick={()=>this.handleClick("twitter")}/>
                <LinkedinIcon className="media-icon" onClick={()=>this.handleClick("linkedin")}/>
            </div>
            </div>
            <div className="grey">
                <div className="pt-3" style={{ fontStyle: 'italic', textAlign: 'justify', textJustify: 'inter-word', fontSize: 12 }}>
                <p style={{ fontSize: 12 }}>Advisory services are offered through Magnifi LLC, an SEC Registered Investment Advisor. Being registered as an investment adviser does not imply a certain level of skill or training. The information contained herein should in no way be construed or interpreted as a solicitation to sell or offer to sell advisory {!this.state.showMore ? <a style={{ fontSize: 13 }} href="javascript:void(0)" onClick={()=> this.setState({ showMore: true })}>Read More</a>:null}{this.state.showMore ? "services to any residents of any State where notice-filed or otherwise legally permitted. All content is for information purposes only. It is not intended to provide any tax or legal advice or provide the basis for any financial decisions. Nor is it intended to be a projection of current or future performance or indication of future results. Moreover, this material has been derived from sources believed to be reliable but is not guaranteed as to accuracy and completeness and does not purport to be a complete analysis of the materials discussed. Purchases are subject to suitability. This requires a review of an investor's objective, risk tolerance, and time horizons. Investing always involves risk and possible loss of capital.": null}</p>
                {this.state.showMore ? <p style={{ fontSize: 12 }}>Opinions expressed herein are solely those of Magnifi LLC and our editorial staff. The information contained in this material has been derived from sources believed to be reliable but is not guaranteed as to accuracy and completeness and does not purport to be a complete analysis of the materials discussed. All information and ideas should be discussed in detail with your individual adviser prior to implementation. Images and photographs are included for the sole purpose of visually enhancing the website. <a style={{ fontSize: 13 }} href="javascript:void(0)" onClick={()=> this.setState({ showMore: false })}>Show Less</a></p> : null}
                </div>
            </div>
        </div>

        </div>
    );
  }
}

const TERMS_LINK = [
    {
        title: "Terms & Conditions",
        link: "https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/terms_of_use.pdf",
    },
    {
        title: "Privacy Policy",
        link: "https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/privacy_policy.pdf",
    },
    {
        title: "Cookie Policy",
        link: "https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/cookie_policy.pdf",
    },
    {
        title: "Form CRS",
        link: "https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/crs_309397.pdf"
    }
    // ,
    // {
    //     title: "ADV Part 2 Brochure",
    //     link: "https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/Magnifi%20-%20Part%202A%20Brochure%207-26-2021.docx.pdf"
    // }
]

export default Footer

import React from 'react'
import { connect } from 'react-redux'
import { activeSubscriptionsCancellationRequest } from 'actions/profile'
import { Modal, ModalBody } from 'reactstrap'
import SubscriptionScheduleDemoModal from './SubscriptionScheduleDemoModal'
import SubscriptionCancelModal from './SubscriptionCancelModal'
import { addEventToAnalytics } from 'Utils';

class ProfileSubscriptionPlan extends React.Component {
  // constructor(props) {
  //   super(props)
  //   // this.unSubscribe = this.unSubscribe.bind(this);
  // }

  confirmCancellation = ({ selectedFeatures }) => {
    const { activeSubscriptionsCancellationRequest } = this.props;
    if (activeSubscriptionsCancellationRequest) {
      // console.log(selectedFeatures)
      activeSubscriptionsCancellationRequest(selectedFeatures, () => {
        // NOTE: After request sent, close the modal.
        this.props.onChangeModal(false);
      })
    }
  }

  declineCancellation = (modalState) => {
    addEventToAnalytics('Unsubscribe No', 'Unsubscribe No', 'UNSUBSCRIBE_NO', {}, false);
    this.props.onChangeModal(modalState);
  }

  render() {
    if (!this.props.modalType) return null;
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.modalStatus}
          toggle={() => { this.props.onChangeModal(false) }}
          centered
          keyboard={true}
          backdrop
          className="brokers-modal subscription-modal-container"
          backdropClassName="brokers-backdrop"
        >
          <ModalBody className="ssf-modal__body">
            {(this.props.modalType === 'scheduleDemo') && (
              <SubscriptionScheduleDemoModal
                featureName={this.props.featureName}
                onChangeModal={this.props.onChangeModal}
              />
            )}
            {(this.props.modalType === 'cancelSubscriptions') && (
              <SubscriptionCancelModal
                profile={this.props.profile}
                subscription={this.props.subscription}
                featureName={this.props.featureName}
                activatedFeatures={this.props.activatedFeatures}
                // unSubscribe={this.unSubscribe}
                declineCancellation={this.props.onChangeModal}
                confirmCancellation={this.confirmCancellation}
                subscriptionID={this.props.subscription && this.props.subscription.subscription_id}
              />
            )}
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ profile, auth }) => ({
  subscription: profile.profile.subscription,
  unSubscribeUserStatus: profile.unSubscribeUserStatus,
  unSubscriptionError: profile.unSubscriptionError,
  cancellation_request_status: auth.user.cancellation_request_status
})

const mapDispatchToProps = {
  // unsubscribeUserRequest,
  activeSubscriptionsCancellationRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileSubscriptionPlan);

import React from 'react';

import {
  Card,
  CardBody,
  Row,
  Col,
} from 'reactstrap';

import { cn, sponsorLogo } from 'DataSet';
import { getImageOrFallback, addEventToAnalytics } from 'Utils';

import { TruncatedNameSponsor } from '../Common';

const classNames = require('classnames');

class ItemCard extends React.Component {
  state = {
    imageValidPath: false
  }

  componentDidMount() {
    const { card } = this.props
    if (cn(card, 'Sponsor')) {
      getImageOrFallback(sponsorLogo(cn(card, 'Sponsor'))).then(result => {
        if (result) {
          this.setState({ imageValidPath: true })
        } else {
          this.setState({ imageValidPath: false })
        }
      })
    }
  }

  buyHandler = () => {
    this.props.buyHandler(this.props.card)
  }

  unselectCard = item => {
    const { funds } = this.props
    this.props.clearHandler(item)
    if (funds) {
      addEventToAnalytics('Fund Detail Selected -- Delete', 'Fund Detail Selected -- Delete', 'FUND_DETAIL_SELECTED -- DELETE', { query: funds.query, logId: funds.logId, ticker: item.ticker, category: 'advisory_PTC' }, true);
    }
  }

  handleCardSelect = card => {
    const { funds } = this.props;
    if (card.type !== '') {
      if (window.innerWidth > 500) this.props.selCardHandler(card)
      if (card._selected) {
        if (funds) {
          addEventToAnalytics('Fund Detail Selected', 'Fund Detail Selected', 'FUND_DETAIL_SELECTED', { query: funds.query, logId: funds.logId, ticker: card.ticker, category: 'advisory_PTC' }, true);
        }
      }
    }
  }

  toggleFunDetails = card => {
    const { toggleFunDetails } = this.props;
    if (toggleFunDetails && card && card.type !== 'SHARE') {
      toggleFunDetails(card);
    }
  }

  render() {
    const { imageValidPath } = this.state
    const { card, selected } = this.props

    return (
      <div style={{ padding: '0 2px 2px' }}>
        <Card
          className={`${(card.type === '') ? 'shadow-sm media-px-3 border-white itemcard-disabled' : classNames('shadow-sm media-px-3', {
            'border-white': !selected,
            'border-ssf-orange-d1': selected
          })}`}
        >
          <CardBody className="p-0" onClick={() => this.handleCardSelect(card)}>
            <Row className="no-gutters">
              <Col className="col-12 p-1">
                <div
                  className="selected-card bg-ssf-gray-l5 p-1 h-100 d-flex align-items-center"
                  style={{
                    borderLeft: 'none',
                    justifyContent: 'space-between'
                  }}
                >
                  {card._showSponsoredOnSFM && <div className="sponsored-logo-tag">SPONSORED</div>}
                  <div
                    style={{ width: '80%', cursor: 'pointer', marginTop: card._showSponsoredOnSFM ? 15 : 0 }}
                    onClick={() => this.toggleFunDetails(card)}
                  >
                    <TruncatedNameSponsor
                      imageValidPath={imageValidPath}
                      name={cn(card, 'Name2')}
                      sponsor={cn(card, 'Sponsor')}
                    />
                  </div>
                  <div className="d-flex flex-column h-100 close-wrapper">
                    <div className="close-icon-box pl-0">
                      <i
                        className="fal fa-times-circle"
                        onClick={() => this.unselectCard(card)}
                      ></i>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    )
  }
}

export default ItemCard;

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { addEventToAnalytics, deflatLastNode } from 'Utils';
import style from '../ClientDetailsModal/Elements/AccountDetail.module.scss';
import {CustomSponsoredDropdown,CustomModelDropdown} from './CustomSponsoredDropdown';

const { SubMenu } = Menu;

class ModelSelectorDropdown extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state={
      IMG_OBJECT: {
        'Sponsor Models': '/assets/images/SponsorProvidedModel-White.svg',
        'My Models': '/assets/images/MyPortfolios-White.svg',
      },
    }
  }

  openChange = () => {
    const element = document.querySelector('.market-popup-wrapper')
    const dropdownEl = document.querySelectorAll('.ant-dropdown-menu-submenu-title')
    if (element !== null) {
      element.style.height = element.clientHeight > 0 && `${element.clientHeight}px`
    }
    if (dropdownEl) {
      dropdownEl.forEach((el, i) => {
        dropdownEl[i].title = ''
      })
      // console.log('here el', dropdownEl);
    }
  }

  sendSelectedPortfolio = (name,ticker,client_id,isGlobalSelect) =>{
    if(this.props.sendSelectedPortfolio){
      this.props.sendSelectedPortfolio(name,ticker,client_id,isGlobalSelect)
    }
  }


  render() {
    let { sponsoredPortfolioList, customPortfolioList, clientList: { selected_portfolio }, selectedPortfolio,sendSelectedPortfolio } = this.props;
    let { obj:newSponsoredPortfolioList ,allItems: allList } = deflatLastNode(sponsoredPortfolioList,true)
    const {IMG_OBJECT} =this.state;
    const marketPlaceDropMenus = (
      <Menu onOpenChange={this.openChange}>
        {
          newSponsoredPortfolioList && typeof newSponsoredPortfolioList == 'string' &&
          <div></div>
        }
        <SubMenu key={'Sponsored'} title={<span>Sponsored</span>} popupClassName="market-popup-head client-portfolio-sponsor-dropdown">
          {
            newSponsoredPortfolioList && typeof newSponsoredPortfolioList == 'object' && Object.keys(newSponsoredPortfolioList).length > 0 && Object.keys(newSponsoredPortfolioList).map((item, index) => {
              return (
                  <SubMenu key={item} title={<span>{item}</span>} popupClassName="market-popup-head client-portfolio-sponsor-dropdown">
                      {
                        Object.keys(newSponsoredPortfolioList[item]).length > 0 && Object.keys(newSponsoredPortfolioList[item]).map((subItem, index) => {
                          if(subItem == 'uncatagorized'){
                            return (
                              <div className='market-popup'>
                              <div className="market-popup-wrapper">
                                {
                                  newSponsoredPortfolioList[item][subItem].length > 0 && newSponsoredPortfolioList[item][subItem].map((subItem1, index) => {
                                    return (
                                        <div className="market_name-label" onClick={() =>
                                          sendSelectedPortfolio(subItem1.name, subItem1.ticker, this.props.refID, this.props.isGlobalSelect)
                                        }>
                                          {subItem1.name}
                                        </div>
                                    )
                                  })
                                }
                              </div>
                              </div>
                            )
                          }
                          else{
                            return (
                            <SubMenu key={subItem} title={<span>{subItem}</span>} popupClassName="market-popup">
                              <div className="market-popup-wrapper">
                                {
                                  newSponsoredPortfolioList[item][subItem].length > 0 && newSponsoredPortfolioList[item][subItem].map((subItem1, index) => {
                                    return (
                                        <div className="market_name-label" onClick={() => {
                                          if(this.props.isGlobalSelect){
                                            addEventToAnalytics('Client Select Model Macro', 'Client Select Model Macro', 'CLIENT_SELECT_MODEL_MACRO', {}, false)
                                          }else{
                                            addEventToAnalytics('Client Select Model Micro', 'Client Select Model Micro', 'CLIENT_SELECT_MODEL_MICRO', { client_id: this.props.refID }, false)
                                          }
                                          sendSelectedPortfolio(subItem1.name, subItem1.ticker, this.props.refID, this.props.isGlobalSelect)
                                        }}>
                                          {subItem1.name}
                                        </div>
                                    )
                                  })
                                }
                              </div>
                            </SubMenu>
                          )}
                        })
                      }
                  </SubMenu>
              )
            })
          }
        </SubMenu>
        {customPortfolioList && customPortfolioList.length &&
          <SubMenu key={'Custom'} title={<span>Custom</span>} popupClassName="market-popup-head">
            <div className="market-popup-wrapper cus-pf-scroll">
              { customPortfolioList && customPortfolioList.length && customPortfolioList.map((item, index) => {
                  return(
                    <div className="market_name-label" onClick={() => {
                      if(this.props.isGlobalSelect){
                        addEventToAnalytics('Client Select Model Macro', 'Client Select Model Macro', 'CLIENT_SELECT_MODEL_MACRO', {}, false)
                      }else{
                        addEventToAnalytics('Client Select Model Micro', 'Client Select Model Micro', 'CLIENT_SELECT_MODEL_MICRO', { client_id: this.props.refID }, false)
                      }
                      sendSelectedPortfolio(item.portfolio_name, '', this.props.refID, this.props.isGlobalSelect)
                    }}>
                      {item.portfolio_name}
                    </div>
                  )})
              }
            </div>
          </SubMenu>
        }
        {(selectedPortfolio || this.props.isGlobalSelect) &&
          <div className="market_name-label" onClick={() =>
            sendSelectedPortfolio('', '', this.props.refID, this.props.isGlobalSelect)
          }>
            Clear Selection
          </div>
        }
        {
          newSponsoredPortfolioList && newSponsoredPortfolioList.message && newSponsoredPortfolioList.message == 'No tickers Present.' &&
            <Menu.Item key='0'>
              <div className="portfolio_name-label" >
                No data
              </div>
            </Menu.Item>
        }
      </Menu>
    );
    const modelDropdown =(
      <Menu onOpenChange={this.openChange} className={style.portfolioMenuWrapper}>
        <div className="market-popup-wrapper cus-pf-scroll">
              { customPortfolioList && customPortfolioList.length && customPortfolioList.map((item, index) => {
                  return(
                    <div className="market_name-label" onClick={() => {
                      if(this.props.isGlobalSelect){
                        addEventToAnalytics('Client Select Model Macro', 'Client Select Model Macro', 'CLIENT_SELECT_MODEL_MACRO', {}, false)
                      }else{
                        addEventToAnalytics('Client Select Model Micro', 'Client Select Model Micro', 'CLIENT_SELECT_MODEL_MICRO', { client_id: this.props.refID }, false)
                      }
                      sendSelectedPortfolio(item.portfolio_name, '', this.props.refID, this.props.isGlobalSelect)
                    }}>
                      {item.portfolio_name}
                    </div>
                  )})
              }
            </div>
      </Menu>
    );

    const sponsoredDropdown=(
      <Menu onOpenChange={this.openChange}>
       {
            newSponsoredPortfolioList && typeof newSponsoredPortfolioList == 'object' && Object.keys(newSponsoredPortfolioList).length > 0 && Object.keys(newSponsoredPortfolioList).map((item, index) => {
              return (
                  <SubMenu key={item} title={<span>{item}</span>} popupClassName="market-popup-head client-portfolio-sponsor-dropdown">
                      {
                        Object.keys(newSponsoredPortfolioList[item]).length > 0 && Object.keys(newSponsoredPortfolioList[item]).map((subItem, index) => {
                          if(subItem == 'uncatagorized'){
                            return (
                              <div className='market-popup'>
                              <div className="market-popup-wrapper">
                                {
                                  newSponsoredPortfolioList[item][subItem].length > 0 && newSponsoredPortfolioList[item][subItem].map((subItem1, index) => {
                                    return (
                                        <div className="market_name-label" onClick={() =>
                                          sendSelectedPortfolio(subItem1.name, subItem1.ticker, this.props.refID, this.props.isGlobalSelect)
                                        }>
                                          {subItem1.name}
                                        </div>
                                    )
                                  })
                                }
                              </div>
                              </div>
                            )
                          }
                          else{
                            return (
                            <SubMenu key={subItem} title={<span>{subItem}</span>} popupClassName="market-popup">
                              <div className="market-popup-wrapper">
                                {
                                  newSponsoredPortfolioList[item][subItem].length > 0 && newSponsoredPortfolioList[item][subItem].map((subItem1, index) => {
                                    return (
                                        <div className="market_name-label" onClick={() => {
                                          if(this.props.isGlobalSelect){
                                            addEventToAnalytics('Client Select Model Macro', 'Client Select Model Macro', 'CLIENT_SELECT_MODEL_MACRO', {}, false)
                                          }else{
                                            addEventToAnalytics('Client Select Model Micro', 'Client Select Model Micro', 'CLIENT_SELECT_MODEL_MICRO', { client_id: this.props.refID }, false)
                                          }
                                          sendSelectedPortfolio(subItem1.name, subItem1.ticker, this.props.refID, this.props.isGlobalSelect)
                                        }}>
                                          {subItem1.name}
                                        </div>
                                    )
                                  })
                                }
                              </div>
                            </SubMenu>
                          )}
                        })
                      }
                  </SubMenu>
              )
            })
          }
      </Menu>
    )
    return(<div style={{display:'flex'}}>
      <div style={{paddingLeft:'18px'}} className={style.modelContainer}>
      <Dropdown overlayClassName="market-place-dropdown" trigger={'click'} onVisibleChange={e => {
                      const dropdownEl = document.querySelectorAll('.ant-dropdown-menu-submenu-title')
                      if (dropdownEl) {
                        dropdownEl.forEach((el, i) => {
                          dropdownEl[i].title = ''
                        })
                      }
                    }}  overlay={<CustomSponsoredDropdown  openChange={this.openChange} dropDownData={sponsoredPortfolioList} sendSelectedPortfolio={this.sendSelectedPortfolio} refID={this.props.refID} isGlobalSelect={this.props.isGlobalSelect}/>}>
        { this.props.selectedPortfolio ? <span className={`selected-pf-dropdown ${this.props.refID == this.props.selectedClient ? ' active' : ''}`}> <span>{this.props.selectedPortfolio}</span> <i className="fas fa-caret-down"></i> </span> : <a className="sub-head" href="#">
        <div
                      id={'pfSearch'}
                      className="d-flex align-items-center justify-content-between"
                      style={{color:'#5488c9'}}
                    >
                      <img src={IMG_OBJECT['Sponsor Models']} height="28" />
                      <div
                        className="row d-flex align-items-center justify-content-between"
                        style={{
                          marginLeft: '10px',
                          width: '100%',
                          marginRight: '0px'
                        }}
                      >
                        <div>Sponsor Models </div>
                        <div
                          className="d-flex align-items-center justify-content-between"
                          style={{ width: `50px`,paddingLeft:'18px' }}
                        >
                          
                        <DownOutlined />
                        </div>
                      </div>
                    </div>
        </a> }
      </Dropdown>
      </div>
      <div style={{width:'3%'}}></div>
      <div style={{paddingLeft:'18px'}} className={style.modelContainer}>
      <Dropdown overlayClassName="market-place-dropdown" trigger={'click'} onVisibleChange={e => {
                      const dropdownEl = document.querySelectorAll('.ant-dropdown-menu-submenu-title')
                      if (dropdownEl) {
                        dropdownEl.forEach((el, i) => {
                          dropdownEl[i].title = ''
                        })
                      }
                    }} overlay={<CustomModelDropdown  openChange={this.openChange} dropDownData={customPortfolioList} sendSelectedPortfolio={this.sendSelectedPortfolio} refID={this.props.refID} isGlobalSelect={this.props.isGlobalSelect}/>}>
        { this.props.selectedPortfolio ? <span className={`selected-pf-dropdown ${this.props.refID == this.props.selectedClient ? ' active' : ''}`}> <span>{this.props.selectedPortfolio}</span> <i className="fas fa-caret-down"></i> </span> : <a className="sub-head" href="#">
        <div
                      id={'pfSearch'}
                      className="d-flex align-items-center justify-content-between"
                      style={{color:'#5488c9'}}
                    >
                      <img src={IMG_OBJECT['My Models']} height="28" />
                      <div
                        className="row d-flex align-items-center justify-content-between"
                        style={{
                          marginLeft: '10px',
                          width: '100%',
                          marginRight: '0px'
                        }}
                      >
                        <div>My Models </div>
                        <div
                          className="d-flex align-items-center justify-content-between"
                          style={{ width: `50px` ,paddingLeft:'18px'}}
                        >
                        <DownOutlined />
                        </div>
                      </div>
                    </div>
        </a> }
      </Dropdown>
      </div>
      </div>
    )
  }
}

const mapStateToProps = ({ clientportfolioV2: { customPortfolioList, sponsoredPortfolioList, clientList } }) => ({
  customPortfolioList,
  sponsoredPortfolioList,
  clientList
})

export default connect(
  mapStateToProps
)(ModelSelectorDropdown);

import React, { Component, Fragment } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Button,
  ButtonGroup,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from 'reactstrap'
import { PrimaryButton, CloseButton, Notification } from 'shared-components'


const EmbedSection = (props) => {
  const {
    isEmbedOpen,
    isEmbedClose,
    copylink,
    isNotifyOpen,
    notifyClassName,
    query,
  } = props;

  const iframeSrc = `<iframe src="${window.location.origin}/share-widget.html?query=${query}&isReadOnly=true"  height="385" width="760"  frameBorder="0" />`

  return (
    <Modal isOpen={isEmbedOpen} keyboard={true} toggle={isEmbedClose} backdropClassName="backdropClassName" centered className="embed-link-modal">
      <ModalHeader className="ssf-modal__header">SHARE RESULTS<CloseButton onClick={isEmbedClose} /></ModalHeader>
      <ModalBody className="ssf-modal__body">
        <div className="embed-section">
          <div className="embed-section__preview">
            <span className="embed-head">Preview</span>
            <div className="preview-section">
              <iframe src={`${window.location.origin}/share-widget.html?query=${encodeURIComponent(query)}&isReadOnly=true`} height="400" width="550" frameBorder="0" />
            </div>
          </div>
          <div className="embed-section__code">
            <div>
              <span className="embed-head">Code</span>
              <div className="code-section">
                <textarea id="embedText" className="code-section__text" value={`<iframe src="${window.location.origin}/share-widget.html?query=${encodeURIComponent(query)}&isReadOnly=true" height="385" width="760" frameBorder="0" />`} />
              </div>
            </div>
            <div>
              <div className="text-section">
                By embedding this code on your site, you are agreeing to Magnifi API terms of services
              </div>
            </div>
          </div>
        </div>
        <div className="account-confirm-modal__footer">
          <ButtonGroup>
            <PrimaryButton type="button" onClick={copylink}>
              COPY LINK
            </PrimaryButton>
          </ButtonGroup>
        </div>
      </ModalBody>
      {isNotifyOpen
        && (
          <Notification
            isNotifyOpen={isNotifyOpen}
            notifyClassName={notifyClassName}
            notifyBody="Link Copied to clipboard"
          />
        )
      }
    </Modal>
  )
};

export default EmbedSection;

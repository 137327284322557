import React from 'react';
import { connect } from 'react-redux'
import { createLoadingSelector } from 'selectors/loading-selector';

import { fetchBasePortfolios } from 'apis/funds';
import { cn, DateRange, ScoreAttrs } from 'DataSet';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import FlipMove from 'react-flip-move';
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import { calcCombineStatsWeighted, calcEnhancementScores } from 'Stats';
import { addEventToAnalytics, getNest, monthToDate, nameToDateRange, dateToMonth } from 'Utils';
import { apexRedirectionWhereTo } from 'layouts/utils';
import CombineSm from '../SmallScreen/Combine';
import ItemCard from './ItemCard';
import { Chart, ReturnChart } from './Charts';
import { fetchPortfolioList } from 'apis/account'
import {
  Dropdown,
  Menu,
  Input,
  // Affix, 
  // List
} from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { combineMyPFReturnsChart } from 'apis/sfm'
import style from '../../Enhancer/Pages/OnBoardScreen/OnBoardScreen.module.scss'
import { dateRangeActiveObj } from '../utils';
import Emitter from 'realTimePrice/emitter';
// import { trustedContactCountry } from '../../Constant/FormData';
import { Loader } from '../Loader';

import { getCombineScoreRequest } from 'actions/sfm'

const { Search } = Input;
const { SubMenu } = Menu;
class ItemList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.items,
      opts: { optWt: 0.1 },
      range: dateRangeActiveObj, // { return: 'active', risk: 'active' },
      chart: 'delta',
      returnsChartDateRange: '',
      CombineDDVisible: false,
      _itemEnhancementScore: [],
      enhacementScore: [],
      portfolios2: [],
      localLoad: false,
      emptyData: false
    }
  }

  async componentDidMount() {
    // let accountNumber = null;
    let data = await this.fetchPfData(this.props.query);
    let modelPf = await fetchPortfolioList()

    modelPf = Array.isArray(modelPf) ? modelPf.sort((a, b) => a.portfolio_name.localeCompare(b.portfolio_name)) : []
    //returns divide by 100
    data.portfolios.forEach((item, index) => {
      item.returns.forEach((r, i) => {
        data.portfolios[index].returns[i].v = data.portfolios[index].returns[i].v / 100;
      });
    });

    let portfolios = data.portfolios.map(e => ({ name: e.name, code: e.code, desc: e.desc })).filter(i => i.code !== 'yp');
    let opts = { ...this.state.opts, optPf: getNest([0, 'code'], portfolios) };
    this.setState({ data, portfolios, portfolios2: modelPf, opts }, () => {
      this.update();
      let tickersToSend = this.state.items.filter(item => item.type !== 'SHARE').map(item => item.ticker);
      if (tickersToSend.length > 0) {
        this.callScoreApi({
          tickers: tickersToSend,
          base_portfolio: this.state.portfolios2.length ? this.state.portfolios2[0].portfolio_name : '',
          weights: this.state.opts.optWt,
          portfolio: 1
        })
      }
    });

    const elems = document.querySelectorAll('.custom-scroll');
    if (elems.length) {
      [].forEach.call(elems, function (el) {
        if (el.scrollHeight > el.clientHeight) el.style.paddingRight = '.25rem';
        else el.style.paddingRight = '.25rem';
      });
    }

    this.setState({ _itemEnhancementScore: calcEnhancementScores(this.state.items.map(e => e._sfWtStats), ScoreAttrs) })

  }

  componentDidUpdate(prevProps) {
    if (prevProps.items.length !== this.props.items.length) {
      this.setState({ items: this.props.items }, this.update);
    }
  }

  callScoreApi = (data) => {
    if (process.env.REACT_APP_SUB_DOMAIN === 'retail' && data.base_portfolio) {
      this.setState({ localLoad: true })
      this.props.getCombineScoreRequest(data, () => {
        this.setState({ localLoad: false })
      })
    }
  }

  componentWillReceiveProps(nextProps) {

    if (process.env.REACT_APP_SUB_DOMAIN !== 'retail') {
      return
    }

    if (this.props.items.length !== nextProps.items.length && this.state.portfolios2.length) {
      let tickersToSend = nextProps.items.filter(item => item.type !== 'SHARE').map(item => item.ticker);
      if (tickersToSend.length > 0) {
        this.callScoreApi({
          tickers: tickersToSend,
          base_portfolio: this.state.portfolios2.length ? this.state.portfolios2[0].portfolio_name : '',
          weights: this.state.opts.optWt,
          portfolio: 1
        })
      }
    }

    if (
      nextProps.sfm.enhacementScore.length > 0 &&
      nextProps.sfm.enhacementScore !== this.state.enhacementScore &&
      nextProps.sfm.enhacementScore.length !== this.state.enhacementScore.length
    ) {

      let emptyData = nextProps.sfm.enhacementScore.filter(item => item.combine_score.inception.length)
      this.setState({ emptyData: emptyData.length ? false : true })

      let enhacementScoreDummy = this.props.items.map(el => {
        let index = nextProps.sfm.enhacementScore.findIndex(scor => scor.ticker === el.ticker)
        if (index >= 0) {
          return nextProps.sfm.enhacementScore[index]
        } else {
          return { ticker: el.ticker, combine_score: { inception: [] } }
        }
      })

      // this.props.loadingStatus(true);
      this.setState({
        _itemEnhancementScore: enhacementScoreDummy.map(item => ({
          ...item,
          score: item.combine_score.inception.length ? item.combine_score.inception[0].score * 100 : 0,
        }))
      })

      if (enhacementScoreDummy.length === this.props.items.length) {
        this.setState({
          items: enhacementScoreDummy.map((item, i) => ({
            ...this.state.items[this.state.items.findIndex(scor => scor.ticker === item.ticker)],
            _sfWtStats: {
              ...this.state.items[this.state.items.findIndex(scor => scor.ticker === item.ticker)]._sfWtStats,
              fees: item.combine_score.inception.length ? item.combine_score.inception[0].delta_fee * 100 : 0,
              risk: item.combine_score.inception.length ? item.combine_score.inception[0].delta_volatility * 100 : 0,
              yRet: item.combine_score.inception.length ? item.combine_score.inception[0].delta_returns * 100 : 0
            }
          }))
        })
      }

      // this.props.loadingStatus(false);

    }
  }

  handleWeightChangeCombineScore = (weights) => {
    let tickersToSend = this.props.items.filter(item => item.type !== 'SHARE').map(item => item.ticker);
    if (tickersToSend.length === 0)
      return
    this.callScoreApi({
      tickers: tickersToSend,
      base_portfolio: this.state.portfolios2.length ? this.state.portfolios2[0].portfolio_name : '',
      weights,
      portfolio: 1
    })
  }

  handleDateChangeCombineScore = (date_range) => {
    if (date_range.interval === "l1y") {
      console.log('l1y')
    } else if (date_range.interval === "l3y") {
      console.log('l3y')
    } else if (date_range.interval === "l5y") {
      console.log('l5y')
    } else if (date_range.interval === "active") {
      console.log('active')
    } else {
      let tickersToSend = this.props.items.filter(item => item.type !== 'SHARE').map(item => item.ticker);
      if (tickersToSend.length > 0) {
        this.callScoreApi({
          tickers: this.state.items.map(item => item.ticker),
          base_portfolio: this.state.portfolios2.length ? this.state.portfolios2[0].portfolio_name : '',
          weights: this.state.opts.optWt,
          portfolio: 1,
          date_range
        })
      }
    }
  }

  update = async () => {
    //this.setState({ loading: true });
    this.props.loadingStatus(true);
    this.calcStats(this.state.items, this.state.range, ScoreAttrs, this.state.opts);
    this.props.loadingStatus(false);
    //this.setState({ loading: false });
  }

  optSelHandler = async (opts, totalPF = {}) => {
    //opts is either code or my model pf name
    if (totalPF.id && totalPF.returns) {
      let tempPortfolios2 = this.state.portfolios2.map(item => item.id !== totalPF.id ? item : totalPF)
      this.setState({ opts: { ...this.state.opts, ...opts }, portfolios2: tempPortfolios2, }, this.update);
    } else {
      this.setState({ opts: { ...this.state.opts, ...opts } }, this.update);
    }
    this.props.optSelHandler(opts);
  }

  rangeHandler = (attr, selRange) => {
    const { funds } = this.props;
    let range;
    if (selRange.fromDatePicker) {
      range = selRange;
    } else {
      range = { return: selRange, risk: selRange }
    }
    this.setState({ range }, this.update)
    if (funds) {
      addEventToAnalytics('Combine time period change', 'Combine time period change', 'COMBINE_TIME_PERIOD_CHANGE', { query: funds.query, logId: funds.logId }, true);
    }
  }

  toggleChart = (type) => {
    this.setState({ chart: type });
  }

  calcStats = () => {
    if (!this.state.data) return;

    const { items, range, opts } = this.state;

    let dateRange;
    if (range.fromDatePicker) {
      dateRange = range;
    } else {
      dateRange = this.nameToDateRange(range);
    }

    let pfolio = this.state.data.portfolios.find(e => e.code === opts.optPf) ? this.state.data.portfolios.find(e => e.code === opts.optPf) : this.state.portfolios2.find(e => e.portfolio_name === opts.optPf);
    items.forEach((e, i) => {
      e._sfWtStats = calcCombineStatsWeighted(e, pfolio, opts.optWt, dateRange);
    })
    let activeDateRange = this.nameToDateRange({ return: 'active', risk: 'active' });
    this.setState({ items, dateRange: activeDateRange });
  }

  sortFn = (a, b) => {
    let [_a, _b] = [a._sfStats, b._sfStats]
    if (_a && _b) {      // descending order
      if (_a.score < _b.score) return 1;
      else if (_a.score > _b.score) return -1;
    }
    return 0;
  }

  nameToDateRange = range => {
    let d = moment.max(this.state.items.map(e => monthToDate(e._start)));
    let sDefault = moment(DateRange.start),
      sActive = moment.max(sDefault, d);
    let maxRange = r => ({ start: r !== 'active' ? sDefault : sActive, end: moment(this.props.dateRange.end) });

    return {
      return: nameToDateRange(range.return, maxRange(range.return)),
      risk: nameToDateRange(range.risk, maxRange(range.risk)),
    };
  }

  async fetchPfData() {
    let portfolio = await fetchBasePortfolios()
    return portfolio;
  }

  scoreAttrsStr = () => {
    let names = ScoreAttrs.map(e => e.name);
    return [names.slice(0, -1).join(', '), names.slice(-1)[0]].join(' and '); // will fail for names.length < 2
  }

  displayYear = (returnsChartDateRange) => {
    if (returnsChartDateRange !== this.state.returnsChartDateRange) {
      this.setState({
        returnsChartDateRange,
      });
    }
  }

  tradeForFree = () => {
    const { history, profile } = this.props;
    addEventToAnalytics('Impact Tab Trade For Free', 'Impact Tab Trade For Free', 'IMPACT_TAB_TRADE_FOR_FREE', {}, true);
    // this.props.history.push('/register/trading');
    try {
      if (this.props.loggedIn) {
        if (profile && profile.profile && profile.profile.apex) {
          const { profile: _profileState } = profile;
          const apex_obj = _profileState.apex;
          // console.log(apex_obj);
          const isApexEmpty = apex_obj && Object.keys(apex_obj).length === 0 && apex_obj.constructor === Object;
          if (!isApexEmpty) {
            const _apexRedirectionWhereTo = apexRedirectionWhereTo({ profile: _profileState });
            // console.log(_apexRedirectionWhereTo);
            if (_apexRedirectionWhereTo !== null && _apexRedirectionWhereTo !== 'Not to apex route') {
              if (history) {
                history.push(_apexRedirectionWhereTo)
              }
            } else {
              // console.log('-- _apexRedirectionWhereTo fn returns null --');
              if (history) {
                history.push('/register/trading');
              }
            }
          } else {
            // console.log('-- Apex object is empty --');
            if (history) {
              history.push('/register/trading');
            }
          }
        } else {
          throw '-- Profile || Profile.apex is missing --'
        }
      } else {
        throw '-- Not loggedIn --'
      }
    } catch (e) {
      console.log(e);
      if (history) {
        history.push('/register');
      }
    }
  }

  render() {
    if (!this.state.data || !this.state.dateRange) return null;

    const items = this.state.items.filter(e => getNest(['_order', 'stage'], e) === 'combine').sort(this.sortFn);
    const { funds, commonActiveSFMReturnsRange, impactLoading, profileLoading } = this.props;
    const { chart } = this.state;
    const filterScoreattrs = ScoreAttrs.filter(x => x.weight !== 0 && _.find(ScoreAttrs, { name: x.name }));
    let _itemEnhancementScore = process.env.REACT_APP_SUB_DOMAIN === 'retail' ? this.state._itemEnhancementScore : calcEnhancementScores(items.map(e => e._sfWtStats), ScoreAttrs);
    if (typeof this.props.accountsPage === 'undefined') {
      if (this.props.shareCombineState) {
        this.props.shareCombineState(this.state);
        this.props.combineStats(items, ScoreAttrs);
      }
    }

    let dropdownSinceDate = (commonActiveSFMReturnsRange && commonActiveSFMReturnsRange.start && commonActiveSFMReturnsRange.end) ? 'Since ' + dateToMonth(commonActiveSFMReturnsRange.start) : null;

    if (process.env.REACT_APP_SUB_DOMAIN === 'retail') {
      dropdownSinceDate = (commonActiveSFMReturnsRange && !isEmpty(this.props.profile.profile.apex) && this.props.profile.profile.apex.account[0].request_time && commonActiveSFMReturnsRange.end) ? 'Since ' + dateToMonth((moment(this.props.profile.profile.apex.account[0].request_time))) : null;
    }

    if (this.props.mobileDevice) {
      return (
        <CombineSm {...this.props}
          items={items}
          enhancementScore={_itemEnhancementScore}
          filterScoreattrs={filterScoreattrs}
          opts={this.state.opts}
          allWtStats={this.state.allWtStats}
          allWtStatsBase={this.state.allWtStatsBase}
          range={this.state.range}
          dateRange={this.state.dateRange}
          portfolios={this.state.portfolios}
          displayYear={this.displayYear}
          rangeHandler={this.rangeHandler}
          optSelHandler={this.optSelHandler}
        />
      )
    }

    if (impactLoading || profileLoading) {
      return (
        <Loader loading />
      )
    } else {
      if ((!this.props.tradingAccountExist || (this.props.uploadPortfolio && this.props.uploadPortfolio.message) || this.props.uploadPortfolio.length === 0) && process.env.REACT_APP_SUB_DOMAIN === 'retail') {
        return (
          <Row className="no-gutters" style={{ height: "100%" }}>
            <Col className="sfm-impact-window-wrapper">
              <img className="icon" src="/assets/images/icons/handshake.svg" />
              <h2 className="title">We can help you see the value addition <br /> your selected securities will have</h2>
              <h3 className="sub-title">Unlock a World of Investing with a Free <br /> Magnifi Investment Account</h3>
              <button className="primary-button" onClick={() => this.tradeForFree()}>Start Investing For Free</button>
            </Col>
          </Row>
        );
      } else {
        return (
          <Row className="no-gutters">
            {this.state.emptyData ? <div className="combine_no_data">
              <span>No Data Available</span>
            </div> : null}
            {(this.props.loading || this.state.localLoad) && <Loader loading />}
            {!this.props.loading && !this.state.localLoad && (
              <>
                <Col className="col-12 text-center" style={{ padding: '1rem 1rem 12px 1rem' }}>
                  <Title funds={funds} optSelHandler={this.optSelHandler} portfolios2={this.state.portfolios2} portfolios={this.state.portfolios} />
                </Col>
                <Col className="col-6 pl-1 pr-2 order-2 h-100">
                  <h5 className="mb-0 text-center">
                    <Title2
                      handleWeightChangeCombineScore={this.handleWeightChangeCombineScore}
                      optsWtValue={this.state.opts.optWt}
                      impactTabWeight={this.props.impactTabWeight}
                      optSelHandler={this.optSelHandler}
                      toggleChart={this.toggleChart}
                      scoreAttrs={ScoreAttrs} />
                  </h5>
                  <div className="custom-scroll" style={{ height: 360 }}>
                    <div className="bg-secondary rounded invisible-scroll" style={{ paddingTop: '2px' }}>
                      {chart === 'return'
                        ? <ReturnChart
                          range={this.state.range}
                          dateRange={this.state.dateRange}
                          rangeHandler={this.rangeHandler} />
                        : filterScoreattrs.map(e =>
                          <Chart
                            attr={e}
                            key={e.code}
                            items={items.filter((kl) => kl.type !== "SHARE")}
                            sinceDate={dropdownSinceDate}
                            allWtStats={this.state.allWtStats}
                            accountCreatedDate={!isEmpty(this.props.profile.profile.apex) && this.props.profile.profile.apex.account[0].request_time}
                            allWtStatsBase={this.state.allWtStatsBase}
                            range={this.state.range}
                            dateRange={this.state.dateRange}
                            handleDateChangeCombineScore={this.handleDateChangeCombineScore}
                            displayYear={this.displayYear}
                            rangeHandler={this.rangeHandler}
                            dropdownScoreAttrs={this.props.dropdownScoreAttrs}
                          />
                        )
                      }
                    </div>
                  </div>
                </Col>
                <Col className="col-6 pl-2 h-100">
                  <h5 className="mb-0 text-center">
                    {process.env.REACT_APP_SUB_DOMAIN === 'retail'
                      ? <div className="w-75 mx-auto pt-2 pb-1 rounded-top">Investments selected to evaluate addition</div>
                      : <div className="w-75 mx-auto pt-2 pb-1 rounded-top">Funds selected to evaluate addition</div>
                    }
                  </h5>
                  <div className="custom-scroll" style={{ height: 360 }}>
                    <div className="bg-secondary rounded" style={{ paddingTop: '2px' }}>
                      <FlipMove>
                        {items.map((e, i) => {
                          return (
                            <div key={cn(e, 'Id')}>
                              <ItemCard
                                card={e}
                                idx={i}
                                last={i === items.length - 1}
                                weight={getNest(['_sfWtStats', 'weight'], e)}
                                itemIndex={i}
                                funds={funds}
                                enhancementScore={_itemEnhancementScore}
                                opts={this.state.opts}
                                clearHandler={this.props.clearHandler}
                                buyHandler={this.props.buyHandler}
                              />
                            </div>
                          )
                        })}
                      </FlipMove>
                      {this.state.loading &&
                        <div className="overlay active">
                          <div className="text rounded">
                            <i className="fal fa-circle-notch fa-3x fa-spin text-gray-5"></i>
                            <h5 className="mt-2 mb-0">Comparing your selections</h5>
                          </div>
                        </div>}
                    </div>
                  </div>
                </Col>
              </>
            )}
          </Row>
        );
      }
    }
  }
}

const mapStateToProps = ({ sfm, loading }) => ({
  sfm,
  loading: createLoadingSelector([
    // 'GET_COMBINE_SCORE'
  ])({ loading })
})

const mapDispatchToProps = {
  getCombineScoreRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemList)

class Title extends React.Component {
  constructor(props) {
    super(props);
    this.optsPf = [];
    if (process.env.REACT_APP_SUB_DOMAIN === 'retail' && props.portfolios2.length) {
      this.optsPf = [{ ...props.portfolios2[0], name: 'My Account', desc: props.portfolios2[0].portfolio_name }];
    } else {
      this.optsPf = props.portfolios;
    }
    this.state = {
      dropdownOpen2: false,
      optPf: this.optsPf[0],
    };
  }

  componentDidMount() {
    if (process.env.REACT_APP_SUB_DOMAIN === 'retail' && this.props.portfolios2.length) {
      this.optPfHandler(this.optsPf[0]);
    } else {
      this.props.optSelHandler({ optPf: this.state.optPf.code });
    }
  }

  toggle = (name) => {
    this.setState({
      [name]: !this.state[name]
    });
  }

  async optPfHandler(optPf) {
    const { funds } = this.props

    if (optPf && optPf.portfolio_name) {
      if (!optPf.returns) {
        try {
          let data = await combineMyPFReturnsChart(optPf.id)

          if (data[0] && data[0].returns && data[0].returns.length > 0) {
            let temp = { ...optPf, returns: data[0].returns, aum: data[0].aum, fees: data[0].fees, tracking_error: data[0]['tracking error'], volume: data[0].volume }
            this.setState({ optPf });
            this.props.optSelHandler({ optPf: optPf.code ? optPf.code : optPf.portfolio_name }, temp);
          } else {
            console.log('to do::');
          }


        } catch (e) {
          console.log(e)
          this.setState({ optPf: this.props.portfolios[0] });
          this.props.optSelHandler({ optPf: this.props.portfolios[0].code });
        }
      } else {
        this.props.optSelHandler({ optPf: optPf.code ? optPf.code : optPf.portfolio_name }, optPf);
      }
    }
    else {
      this.setState({ optPf }); // added this because selected option was not getting set on dropdown text
      this.props.optSelHandler({ optPf: optPf && optPf.code ? optPf.code : optPf.portfolio_name });
    }
    if (funds) {
      addEventToAnalytics('Combine Portfolio Dropdown', 'Combine Portfolio Dropdown', 'COMBINE_PORTFOLIO_DROPDOWN', { query: funds.query, logId: funds.logId, optPf: optPf.code }, true);
    }
  }

  combineDDVisible = (flag) => {
    this.setState({ CombineDDVisible: flag });
  }

  selectedPortfolio = (item, isMyPF = false) => {
    this.combineDDVisible(false)
    if (isMyPF) {
      let item2 = { ...item, name: 'My Models', desc: item.portfolio_name }
      this.optPfHandler(item2)
    } else {
      this.optPfHandler(item)
    }
  }

  render() {
    const { optPf } = this.state;
    return (
      <div className="d-flex justify-content-center" style={{ margin: '-2px 0 -5px' }}>
        {process.env.REACT_APP_SUB_DOMAIN === 'retail'
          ? <h4 className="mb-0"><strong>Evaluate</strong> addition of selected investments to&nbsp;</h4>
          : <h4 className="mb-0"><strong>Evaluate</strong> addition of selected funds to&nbsp;</h4>
        }
        <div className="lh-100">
          {/*<ButtonDropdown isOpen={this.state.dropdownOpen2} toggle={() => this.toggle('dropdownOpen2')} style={{verticalAlign: 'inherit'}}>*/}
          {/*  <DropdownToggle caret color="link" className="text-gray-9 p-0 lh-100 sfmodal-comb-drpdwn">{optPf.name}</DropdownToggle>*/}
          {/*  <DropdownMenu className="rounded-0 h6 py-1" style={{minWidth: '5rem'}}>*/}
          {/*    { this.optsPf.map((e, i) => <DropdownItem key={i} className="py-1" onClick={() => this.optPfHandler(e)}>{e.name}</DropdownItem>) }*/}
          {/*  </DropdownMenu>*/}
          {/*</ButtonDropdown>*/}

          <Dropdown
            trigger={'click'}
            onVisibleChange={this.combineDDVisible}
            visible={this.state.CombineDDVisible}
            className={'ddButtons'}
            overlayClassName={'combineDropdownContainer'}
            overlay={<CombineDropdown
              selectedPortfolio={this.selectedPortfolio}
              portfolios2={this.props.portfolios2}
              portfolios={this.props.portfolios}
              clickDDVisible={this.combineDDVisible}
            />}
            disabled={process.env.REACT_APP_SUB_DOMAIN === 'retail'}
          >
            <div id={'pfSearch'} className="d-flex align-items-center sfmodal-comb-drpdwn justify-content-between">
              <p style={{ display: 'flex', margin: 0, alignItems: 'center', lineHeight: 1, cursor: (process.env.REACT_APP_SUB_DOMAIN === 'retail') ? 'default' : 'pointer' }}>{optPf && optPf.name} {process.env.REACT_APP_SUB_DOMAIN === 'advisor' && <CaretDownOutlined />}</p>
            </div>
          </Dropdown>
          <h6 title={optPf && optPf.desc} className="mb-0 sub-title-models text-gray-7">{optPf && optPf.desc}</h6>
        </div>
      </div>
    )
  }
}

class Title2 extends React.Component {
  optsWt = [
    { name: '5%', value: 0.05 },
    { name: '10%', value: 0.10 },
    { name: '15%', value: 0.15 },
    { name: '20%', value: 0.20 },
    { name: '25%', value: 0.25 },
  ]

  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen1: false,
      optWt: { name: this.optsWt.find(wt => wt.value === this.props.optsWtValue).name, value: this.props.optsWtValue } || this.optsWt[1],
      chart: 'delta',
      dropdownText: this.optsWt[1].name
    };
  }

  componentDidMount() {
    this.props.optSelHandler({ optWt: this.state.optWt.value });
    // const { impactTabWeight, ipWeight } = this.props;
    // if (impactTabWeight && ipWeight !== this.state.optWt.value) {
    //   impactTabWeight(this.state.optWt.value);
    // }
  }

  toggle = (name) => {
    this.setState({
      [name]: !this.state[name]
    });
  }

  optWtHandler = optWt => {
    this.props.handleWeightChangeCombineScore(optWt.value)

    addEventToAnalytics('Combine Add Impact', 'Combine Add Impact', 'COMBINE_ADD_IMPACT', {}, false)
    this.setState({ optWt, dropdownText: optWt.name });
    this.props.optSelHandler({ optWt: optWt.value });
    Emitter.emit('UPDATE_ALL_TICKER_BY_PERCENTAGE', optWt.value);
    const { impactTabWeight } = this.props;
    if (impactTabWeight) {
      impactTabWeight(optWt.value);
    }
  }

  handleChange = (e) => {
    const numbersOnly = new RegExp(/^\d*\.?\d*$/);
    const value = e.target.value.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
    const { impactTabWeight } = this.props;
    if (value === '' || numbersOnly.test(value) && (value > -1 && value < 101)) {
      this.props.handleWeightChangeCombineScore(value / 100)

      const dropdownText = value + '%';

      this.setState({
        dropdownText
      });
      if (impactTabWeight) {
        impactTabWeight(parseFloat((value / 100)));
      }
    } else {
      this.props.handleWeightChangeCombineScore(value > 100 ? 1 : this.optsWt[1].value)

      const dropdownText = value > 100 ? '100%' : this.optsWt[1].name;
      this.setState({
        dropdownText
      });
      if (impactTabWeight) {
        impactTabWeight(1);
      }
    }
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleSubmit();
    }
    this.setState({ dropdownOpen1: false });
  }

  handleSubmit = () => {
    if (this.state.dropdownText && this.state.dropdownText !== '%') {
      const value = parseFloat(this.state.dropdownText.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '')) / 100;
      addEventToAnalytics('Combine Add Impact', 'Combine Add Impact', 'COMBINE_ADD_IMPACT', {}, false);
      this.props.optSelHandler({ optWt: value });
      Emitter.emit('UPDATE_ALL_TICKER_BY_PERCENTAGE', value);
    } else {
      this.setState({
        dropdownText: this.optsWt[1].name
      })
      this.props.optSelHandler({ optWt: this.optsWt[1].value });
    }
  }

  toggleChart = (chart) => {
    this.setState({ chart }, () => {
      this.props.toggleChart(chart);
    });
  }

  render() {
    const { optWt } = this.state;
    const ddStyle = { fontWeight: 500, padding: '1px 0px', margin: '-2px 0' }
    return (
      <div className="position-relative">
        <div className="w-75x mx-auto pt-2 pb-1 rounded-top d-flex justify-content-center" style={{ width: '67%' }}>
          Impact of adding&nbsp;
          <div className="custom-combobox">
            {/*<ButtonDropdown isOpen={this.state.dropdownOpen1} toggle={() => this.toggle('dropdownOpen1')} style={{verticalAlign: 'inherit'}}>*/}
            <UncontrolledDropdown direction="down" style={{ verticalAlign: 'inherit' }}>
              <DropdownToggle caret color="dummy" className="border-light rounded-0 h5 lh-100 d-flex" style={ddStyle} >
                {this.state.dropdownText}
              </DropdownToggle>
              <DropdownMenu className="rounded-0 h6 py-1 dropdown-menu-center" style={{ minWidth: '5rem' }}>
                <DropdownItem className="py-0" toggle={false}>
                  <div className="custom-combo-input">
                    <input type="text"
                      value={this.state.dropdownText}
                      placeholder={'Enter Value'}
                      onChange={this.handleChange}
                      onKeyDown={this._handleKeyDown}
                      onBlur={this.handleSubmit}
                    />
                    <i className="fa fa-pencil"></i>
                  </div>
                </DropdownItem>
                <DropdownItem divider style={{ margin: '0.3rem 0' }} />
                {this.optsWt.map((e, i) => <DropdownItem key={i} className="py-1" onClick={() => this.optWtHandler(e)}>{e.name}</DropdownItem>)}
              </DropdownMenu>
            </UncontrolledDropdown>
            {/*</ButtonDropdown>*/}
          </div>
          &nbsp;to portfolio
        </div>
        {/* <div className="position-absolute" style={{right: '4px', top: '2px'}}>
          <ButtonGroup>
            <Button color={this.state.chart === 'delta' ? 'secondary' : 'light'}
              className="lh-100 h5 non-clickable"
              style={{padding: '2px 4px'}}
              onClick={() => this.toggleChart('delta')}>
              <i className="far fa-fw fa-chart-bar" title="&Delta; Return, Risk, Fee"></i>
            </Button>
            <Button color={this.state.chart === 'return' ? 'secondary' : 'light'}
              className="lh-100 h5 non-clickable"
              style={{padding: '2px 4px'}}
              onClick={() => this.toggleChart('return')}>
              <i className="far fa-fw fa-chart-line" title="Cumulative Returns"></i></Button>
          </ButtonGroup>
        </div> */}
      </div>
    )
  }
}


class CombineDropdown extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  state = {
    input: '',
  }

  handleClick = e => {
    if (e.key === 'search_item' || e.key === 'search') {
      return
    }

    const { selectedPortfolio, portfolios2, portfolios } = this.props;

    if (e.key !== 'not_present') {
      const split = e.key.split('+_+')
      if (split[0] === 'mypf') {
        let filterList = portfolios2 && portfolios2.length > 0 && portfolios2.filter(item => item.portfolio_name.toLowerCase().includes(this.state.input.toLowerCase()))
        selectedPortfolio(filterList[split[2]], true)
      } else {
        selectedPortfolio(portfolios[split[1]])
      }
    }
  };

  handleInputChange = (e) => {
    this.setState({
      input: e
    })
  }

  onOpenChange = (e) => {
    if (this.props.portfolios2 && this.props.portfolios2.length > 0) {
      this.inputElement && this.inputElement.focus();
    }
  }

  render() {
    const { selectedPortfolio, portfolios2, portfolios, clickDDVisible } = this.props;

    let filterList = portfolios2 && portfolios2.length > 0 && portfolios2.filter(item => item.portfolio_name.toLowerCase().includes(this.state.input.toLowerCase()))

    return (
      <Menu onClick={this.handleClick} className={'MenuWrapper'} mode="vertical" forceSubMenuRender={true} subMenuOpenDelay={1} defaultOpenKeys={['search_item', 'search']} onOpenChange={this.onOpenChange}>
        {portfolios.map((item, index) => (
          <Menu.Item key={`${item.name}+_+${index}`} className={'myPfItems'}>
            <p>{item.name}</p>
          </Menu.Item>
        ))}

        {process.env.REACT_APP_SUB_DOMAIN === 'advisor' && <SubMenu key="sub2" title="My Models" className={style.subMenu} popupClassName={`${style.popupClass} submenuContainerCustom`}>
          {(this.props.portfolios2 && this.props.portfolios2.length > 0) && <div
            key={`search`}
            className={'myPfItems customSubmenuSearch'}>
            <Search key={`search_item`} id={'sponsorSearch'} autoFocus={`true`} onChange={(e) => { e.preventDefault(); this.handleInputChange(e.target.value) }} className={style.input} placeholder="Search" allowClear ref={input => this.inputElement = input} />
          </div>}

          {(filterList && filterList.length > 0) ? filterList.map((item, ind) => (
            <Menu.Item
              key={`mypf+_+${item.portfolio_name}+_+${ind}`}
              className={'myPfItems customSubmenuLi'}
            >
              <p>{item.portfolio_name}</p>
            </Menu.Item>

          )) : <Menu.Item key={`not_present`} style={{ cursor: 'not-allowed' }} className={`myPfItems`}>
            <p>{(portfolios2 && portfolios2.length > 0 && this.state.input != '') ? "No Models Found" : "No Models Created"}</p>
          </Menu.Item>}


        </SubMenu>}


      </Menu>

    );

  }

}


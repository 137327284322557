import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Space, Popconfirm, Form } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { FormatCurrency } from 'Utils';

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
	errorHandle,
  checkTicker,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async e => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      // console.log(record);
      // console.log(values);

      if (checkTicker && values.ticker) {
        const status = checkTicker(values.ticker, (errorTickerlist) => {
          // console.log(errorTickerlist);
          if (errorTickerlist.length > 0) {
            // no ticker data found in db
            handleSave({ ...record, ...values });
            errorHandle({ fields: [] });
          } else {
            // ticker founds
            if (record.hasError) {
              delete record.hasError;
            }
            if (record.errorMsg) {
              delete record.errorMsg;
            }

            handleSave({ ...record, ...values });
            errorHandle({ fields: [] });
          }
        });

      } else {
        handleSave({ ...record, ...values });
        errorHandle({ fields: [] });
      }

    } catch (errInfo) {
      console.log('Save failed:', errInfo);
			errorHandle({ fields: errInfo.errorFields });
    }
  };

  if (record && typeof record !== 'undefined' && typeof record.hasError !== 'undefined') {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }

  let childNode = children;

  if (editable || (record && typeof record !== 'undefined' && typeof record.hasError !== 'undefined' && record.hasError)) {
    childNode = (editing) ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
        validateStatus={(record.hasError && dataIndex === 'ticker') ? "error" : ""}
        help={(record.errorMsg && dataIndex === 'ticker') ? record.errorMsg : ""}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className={`editable-cell-value-wrap ${(record && typeof record !== 'undefined' && typeof record.hasError !== 'undefined' && record.hasError) ? 'col-has-error' : ''}`}
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
        {(record && typeof record !== 'undefined' && typeof record.hasError !== 'undefined' && record.hasError && dataIndex === 'ticker') && (
          <span className="error-message">{record.errorMsg}</span>
        )}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

class DataList extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			searchText: '',
			searchedColumn: '',
			sortedInfo: {
				order: 'descend',
				columnKey: 'price',
		 	},
			pagination: {
				current: 1,
				pageSize: this.props.invalidTickers.length > 0 ? 7 : 10,
			},
			dataSource: props.data || [],
			count: 2,
      tickerNotExist: props.tickerNotExist || [],
		};
    this.columns = [];
		// this.columns = [
		// 	{
		// 		title: 'Ticker',
		// 		dataIndex: 'ticker',
		// 		key: 'ticker',
		// 		width: '20%',
		// 		ellipsis: true,
		// 		editable: true,
		// 		sorter: (a, b) => a.ticker.length - b.ticker.length,
		// 		...this.getColumnSearchProps('ticker'),
		// 	},
		// 	{
		// 		title: 'Shares',
		// 		dataIndex: 'shares',
		// 		key: 'shares',
		// 		width: '20%',
		// 		ellipsis: true,
		// 		editable: true,
		// 		sorter: (a, b) => a.shares - b.shares,
		// 	},
		// 	{
		// 		title: 'Price',
		// 		dataIndex: 'price',
		// 		key: 'price',
		// 		width: '20%',
		// 		ellipsis: true,
		// 		editable: true,
		// 		sorter: (a, b) => a.price - b.price,
		// 	},
		// 	{
		// 		title: 'Date',
		// 		dataIndex: 'date',
		// 		key: 'date',
		// 		width: '25%',
		// 		ellipsis: true,
		// 		editable: true,
		// 	},
		// 	{
		// 		title: '',
		// 		dataIndex: 'operation',
		// 		width: '10%',
		// 	},
		// ];
	}

	componentDidMount() {
		const { data, rowsArr, tickerNotExist } = this.props;
    // console.log(tickerNotExist);
    // if (tickerNotExist) {
    //   this.setState({
    //     tickerNotExist,
    //   });
    // }
		if (data) {
      // if (this.state.tickerNotExist.length > 0) {
      //   data.map((item, key) => {
      //     const found = this.state.tickerNotExist.find((g) => g === item.ticker);
      //     if (item.ticker === found) {
      //       item.hasError = true;
      //       item.errorMsg = 'Sorry ticker is not present in db';
      //     }
      //   });
      // }
			data.map((item, key) => {
				item.key = key.toString();
			});
			this.setState({
				dataSource: data,
			});
		}
    if (rowsArr && rowsArr.length) {
      let arr = [];
      rowsArr.map((item) => {
        const searchBox = (item === 'ticker') ? this.getColumnSearchProps(item) : {};
        arr.push({
          title: this.capitalize(item),
          dataIndex: item,
          key: item,
          ellipsis: true,
          editable: false,
          // sorter: (a, b) => a[item].length - b[item].length,
          // ...searchBox, // uncomment it for enaablig searchbox
        })
      });

      arr = [
        ...arr,
        {
          title: <div style={{ position: 'relative' }}>Delete <i class="fas fa-asterisk" style={{ position: 'absolute', right: 4, fontSize: 8 }}></i></div>,
          dataIndex: 'operation',
          width: '15%',
        },
      ];
      this.columns = arr;
    }
	}

	componentWillReceiveProps(newProps) {
    // console.log(newProps.data);
    // console.log(this.state.dataSource);
		if (newProps.data && newProps.data !== this.state.dataSource) {
			newProps.data.map((item, key) => {
				item.key = key.toString();
			});
      // console.log(newProps.data);
			this.setState({
				dataSource: newProps.data,
			});
		}

    // if (newProps.tickerNotExist && newProps.tickerNotExist.length !== this.state.tickerNotExist.length) {
    //   if (newProps.tickerNotExist.length === 0) {
    //     newProps.data.map((item, key) => {
    //       if (item.hasError) {
    //         delete item.hasError;
    //         delete item.errorMsg;
    //       }
    //     });
    //     // console.log(newProps.data);
    //     this.setState({
    //       dataSource: newProps.data,
    //       tickerNotExist: newProps.tickerNotExist,
    //     }, () => {
    //       // console.log(this.state.data);
    //     });
    //   } else {
    //     newProps.data.map((item, key) => {
    //       if (item.ticker === newProps.tickerNotExist.find((g) => g === item.ticker)) {
    //         item.hasError = true;
    //         item.errorMsg = 'Sorry ticker is not present in db';
    //       }
    //     });
    //     this.setState({
    //       dataSource: newProps.data,
    //       tickerNotExist: newProps.tickerNotExist,
    //     });
    //   }
    // }

    if(!newProps.invalidTickers.length && newProps.invalidTickers.length !== this.props.invalidTickers.length){
    	const { current } = this.state.pagination;
    	this.setState({
    		pagination : {
    			current,
    			pageSize: 10
    		}
    	})
    }
	}

  capitalize = (str) => {
    if (str.length) {
      return str[0].toUpperCase() + str.slice(1);
    }
    return str;
  };

	handleDelete = key => {
		const dataSource = [...this.state.dataSource];
		this.setState({
			dataSource: dataSource.filter(item => item.key !== key),
		}, () => {
			const { getUpdatedData } = this.props;
			if (getUpdatedData) {
				getUpdatedData(this.state.dataSource);
			}
		});
 	};

	handleAdd = () => {
		// console.log('add row in current dataSource');
		// const { count, dataSource } = this.state;
		// const newData = {
		// 	key: count,
		// 	name: `Edward King ${count}`,
		// 	age: 32,
		// 	address: `London, Park Lane no. ${count}`,
		// };
		// this.setState({
		// 	dataSource: [...dataSource, newData],
		// 	count: count + 1,
		// });
	};

	handleSave = row => {
    // console.log(row);
		const newData = [...this.state.dataSource];
		const index = newData.findIndex(item => row.key === item.key);
		const item = newData[index];
		newData.splice(index, 1, { ...item, ...row });

    // console.log(newData);

		this.setState({
			dataSource: newData,
		}, () => {
      // console.log(this.state.dataSource);
			const { getUpdatedData } = this.props;
			if (getUpdatedData) {
        // console.log(this.state.dataSource);
				getUpdatedData(this.state.dataSource);
			}
		});
	};

  checkFieldHasError = row => {
    // console.log(row);
    // const { tickerNotExist } = this.props;
    // console.log(tickerNotExist);
    return false;
  }

  getErrorMsg = row => {
    // console.log(row);
    if (true) {
      return '';
    } else {
      return 'some error msg';
    }
  }

	handleChange = (pagination, filters, sorter) => {
		// console.log('Various parameters', pagination, filters, sorter);
		this.setState({
      pagination,
			filteredInfo: filters,
			sortedInfo: sorter,
		});
	};

	clearFilters = () => {
		this.setState({ filteredInfo: null });
	};

	clearAll = () => {
		this.setState({
			filteredInfo: null,
			sortedInfo: null,
		});
	};

	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={node => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
					/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
						>
						Search
					</Button>
					<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
		render: text =>
		this.state.searchedColumn === dataIndex ? (
			<Highlighter
				highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
				searchWords={[this.state.searchText]}
				autoEscape
				textToHighlight={text.toString()}
				/>
		) : (
			text
		),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	handleReset = clearFilters => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	errorHandle = ({ fields }) => {
		// console.log(fields);
		this.setState({ errorFields: fields }, () => {
			const { errorHandle } = this.props;
			if (errorHandle) {
				errorHandle(fields);
			}
		});
	}

	render() {
		const { title, checkTicker } = this.props;
		const { dataSource, tickerNotExist } = this.state;
		let { sortedInfo, filteredInfo, pagination } = this.state;

		sortedInfo = sortedInfo || {};
		filteredInfo = filteredInfo || {};

		const components = {
			body: {
				row: EditableRow,
				cell: EditableCell,
			},
		};

    const deleteConfirm = (record) => (
      <Popconfirm
        getPopupContainer={() => document.getElementById('csv-preview-upload-container')}
        overlayClassName="csv-preview-upload-popover"
        title="Sure to delete?"
        onConfirm={() => this.handleDelete(record.key)}
      >
        <i className="far fa-trash-alt" onClick={() => this.handleDelete(record.key)}></i>
      </Popconfirm>
    );

		const columns = this.columns.map(col => {
      // console.log(col.dataIndex, col.title);
      // console.log(col);
      if (col.dataIndex === 'price') {
        return {
          ...col,
          render: (text, record) => <div>{'$'+parseFloat(Number(text).toFixed(2))}</div>,
        }
      }

      if (col.dataIndex === 'ticker') {
        return {
          ...col,
          width: '80px'
        }
      }

      if (col.dataIndex === 'realTimePrice') {
        return {
          ...col,
          title: 'Current Price',
          render: (text, record) => <div>{'$'+parseFloat(Number(text).toFixed(2))}</div>,
        }
      }

      if (col.dataIndex === 'values') {
        return {
          ...col,
          render: (text, record) => <div>{FormatCurrency(text, 0, '$', 'unf')}</div>,
        }
      }

      if (col.dataIndex.includes('weight')) {
        return {
          ...col,
          render: (text, record) => <div>{parseFloat(Number(text).toFixed(2))+'%'}</div>,
          width: '117px'
        }
      }

			if (col.dataIndex === 'operation') {
				return {
					...col,
					render: (text, record) =>
						this.state.dataSource.length >= 1 ? (
              <i className="far fa-trash-alt" onClick={() => this.handleDelete(record.key)}></i>
						) : null,
				}
			}

			if (!col.editable) {
				return col;
			}

			return {
				...col,
				sortOrder: sortedInfo.columnKey === col.dataIndex && sortedInfo.order,
				onCell: record => ({
					record,
					editable: col.editable,
					dataIndex: col.dataIndex,
					title: (col.dataIndex === 'price') ? '$'+col.title : (col.dataIndex.includes('weight') ? col.title.toFixed(2)+'%' : col.title),
					handleSave: this.handleSave,
					errorHandle: this.errorHandle,
          fieldError: this.checkFieldHasError,
          errorMsg: this.getErrorMsg,
          checkTicker,
				}),
			};
		});

    // console.log(tickerNotExist);
    // console.log(dataSource);

		return (
			<React.Fragment>
				<div id="csv-preview-upload-container" className="csv-preview-upload-container">
					{this.props.invalidTickers.length > 0 && <div className="head-note">
						<p>Positions forwarded into enhancer</p>
					</div>}
					<Table
						size="small"
						rowClassName={() => 'editable-row'}
						components={components}
						columns={columns}
						dataSource={dataSource}
						pagination={pagination}
						onChange={this.handleChange}
            onRow={(record, rowIndex) => {
              return {
                className: `ant-table-row ant-table-row-level-0 editable-row ${ tickerNotExist.find((g) => g === record.ticker) ? 'row-has-error' : ''}`,
              };
            }}
					/>
				</div>
			</React.Fragment>
		)
	}
}

export default DataList;

import React from 'react'
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, ButtonGroup, Form, FormGroup, Label, Input } from 'reactstrap'
import { PrimaryButton } from 'shared-components'
import InputChips from '../Shared/InputChips'
import { updateTaxRates } from 'actions/clientportfolioV2'
import { addEventToAnalytics } from 'Utils';

class TaxTransitionModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ltTax: '',
      stTax: '',
      accInfo: '',
      noContentMessage: ''
    }
  }

  componentDidMount() {
    const { clientDetails, clientList } = this.props;
    if(clientDetails){
      this.setState({
        ltTax: clientDetails[0].long_term_tax || 23.8,
        stTax: clientDetails[0].short_term_tax || 40.8,
      })
    }    

    let { accInfo, noContentMessage } = this.state;

    if (clientDetails && clientDetails.length == 1) {
      this.setState({
        accInfo: `for ${clientDetails[0].name && clientDetails[0].name }`
      })      
      if(clientDetails[0].has_tax_lot){
        if(!clientDetails[0].selected_portfolio){
          this.setState({
              noContentMessage: 'Please select a portfolio'
          })
        }        
      }else{
        this.setState({
            noContentMessage: 'Please upload tax lot information to optimize tax transition'
        })
      }
    }else{
      const hasTaxLot = clientList.filter(item => item.has_tax_lot);        
      if(hasTaxLot < 1){
        this.setState({
            noContentMessage: 'Please upload tax lot information to optimize tax transition'
        })
      }else{
        const hasPortfolio = hasTaxLot.filter(item => item.selected_portfolio)
        if(hasPortfolio.length < 1){
          this.setState({
              noContentMessage: 'Please select a portfolio'
          })
        }          
      }
    }

  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({[name]: value});
  }

  resetValues = () => {
    this.setState({
      ltTax: 23.8,
      stTax: 40.8
    })
  }

  handleSubmit = () => {
    const { clientDetails, clientList } = this.props;
    const { ltTax, stTax } = this.state;
    let updatedTaxList = [];
    if( !ltTax || !stTax ) return;
    if(clientDetails && clientDetails.length == 1){
      addEventToAnalytics('Client Estimate Tax Micro', 'Client Estimate Tax Micro', 'CLIENT_ESTIMATE_TAX_MICRO', { client_id: clientDetails[0].id }, false)
      const taxObj = {
        client_id: clientDetails[0].id,
        long_term_tax: ltTax,
        short_term_tax: stTax    
      }
      updatedTaxList.push(taxObj);
    }else{
      addEventToAnalytics('Client Estimate Tax Macro', 'Client Estimate Tax Macro', 'CLIENT_ESTIMATE_TAX_MACRO', {}, false)
      clientList.map(item => {
        const taxObj = {
          client_id: item.id,
          long_term_tax: ltTax,
          short_term_tax: stTax 
        }
        updatedTaxList.push(taxObj)
      })
    }
    if(updatedTaxList.length > 0){
      this.props.updateTaxRates(updatedTaxList);      
    }
    this.props.closeTaxTransitionModal();
  }

  render() {
    const { showTaxTransitionModal, clientDetails, delType, closeTaxTransitionModal } = this.props;
    const { accInfo, noContentMessage } = this.state;   

    return (
      <React.Fragment>
        <Modal
          isOpen={showTaxTransitionModal}
          toggle={() =>
            closeTaxTransitionModal()
          }
          centered
          keyboard={false}
          backdrop="static"
          className="res-tickers-sm-light"
          backdropClassName="pf-up-sm-light-modal-backdrop"
        >
          <ModalBody className="ssf-modal__body sml-modal-wrapper tt-wrap">
            <i className="fal fa-times cp-modal-close" onClick={() => closeTaxTransitionModal()}></i>
            <h3 className="text-center">Set Tax Rates {accInfo}</h3>
            {!noContentMessage ? <> 
            <div className="mdl-form-content">
              <div className="tax-trans-form-row">
                <span className="mr-1">Long Term Tax</span>
                <FormGroup className="element-box pos-rel">
                  <input
                    id="ltTax"
                    name="ltTax"
                    value={this.state.ltTax}
                    className="input-area"
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    onChange={this.changeHandler}
                  />                
                </FormGroup>
                <span className="ml-1">%</span>
              </div>
              <div className="tax-trans-form-row">
                <span className="mr-1">Short Term Tax</span>
                <FormGroup className="element-box pos-rel">
                  <input
                    id="stTax"
                    name="stTax"
                    value={this.state.stTax}
                    className="input-area"
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    onChange={this.changeHandler}
                  />                
                </FormGroup>
                <span className="ml-1">%</span>
              </div>
            </div>
            <div className="col-12 cta-wrapper">
              <button type="button" className="ssf-btn-primary btn secondary-btn btn btn-secondary"
                onClick={() => this.handleSubmit()}><span>SUBMIT</span></button>              
            </div>
            <a href="#" className="reset-link text-center" onClick={this.resetValues}>Reset Default</a>
            </> : <>
            <div className="confirm-text text-center">
              <span>{noContentMessage}</span>
            </div>
            <div className="col-12 cta-wrapper">
              <button type="button" className="ssf-btn-primary btn secondary-btn btn btn-secondary"
                onClick={() => closeTaxTransitionModal()}><span>OK</span></button>
            </div>
            </>}
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ clientportfolio: { clientList } }) => ({
  clientList
})

const mapDispatchToProps = {
  updateTaxRates,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxTransitionModal);

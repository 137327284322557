import ActionTypes from '../action-types'

const initialState = {
  alertsData: {
    data: null,
    isLoading: false,
    error: null
  },
  updateData: {
    data: null,
    isLoading: false,
    error: null
  }
}

export function alerts(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case ActionTypes.GET_ALERTS_LOADING: {
      const { alertsData } = state;
      alertsData.isLoading = payload;
      return {
        ...state,
        alertsData,
      }
    }
    case ActionTypes.GET_ALERTS_SUCCESS: {
      const { alertsData } = state;
      alertsData.data = payload;
      return {
        ...state,
        alertsData,
      }
    }
    case ActionTypes.GET_ALERTS_FAILURE: {
      const { alertsData } = state;
      alertsData.error = payload;
      return {
        ...state,
        alertsData,
      }
    }
    case ActionTypes.UPDATE_ALERT_LOADING: {
      const { alertsData } = state;
      alertsData.isLoading = payload;
      return {
        ...state,
        alertsData,
      }
    }
    case ActionTypes.UPDATE_ALERT_SUCCESS: {
      const { alertsData } = state;
      let data = null;
      if(alertsData.data && payload.id){
        data = alertsData.data.map(alert => { 
          if(alert.id === parseInt(payload.id)){
            return payload
          }else{
            return alert;
          }
        });
      }
      alertsData.data = data;
      return {
        ...state,
        alertsData,
      }
    }
    case ActionTypes.UPDATE_ALERT_FAILURE: {
      const { updateData } = state;
      updateData.error = payload;
      return {
        ...state,
        updateData,
      }
    }
    default:
      return state
  }
}

import React, { Component } from 'react';
import './_index.scss';

class LandingForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
//   componentDidMount() {
//     const script = document.createElement('script');
//     script.src = 'https://js.hsforms.net/forms/v2.js';
//     document.body.appendChild(script);

//     script.addEventListener('load', () => {
//       if(window.hbspt) {
//         window.hbspt.forms.create({
//           portalId: '7088340',
//             formId: '8f2ed46c-041f-4da2-8b27-32018e634933',
//             target: '#hubspotForm'
//       })
//     }
//     });
// }


  render() {
    return (
        <>
        <div className="landing-form r32">
            <div id="hubspotForm">
              <div className="hubspot-form-titile">Schedule a demo and unlock </div>
              <div className="hubspot-form-titile">a 14-day free trial of Magnifi Pro +</div>
              <button type="button" onClick={this.props.handleLandingBtnClick} class="btn btn-outline-primary schedule-demo-btn">SCHEDULE A DEMO</button>
            </div>
        </div>
        </>
    );
  }
}


export default LandingForm

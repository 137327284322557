import React from 'react'
import { connect } from 'react-redux'
import $ from 'jquery'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { isEqual, find } from 'lodash'
import { calcAttrStats, calcScores, calcScreenerScores } from 'Stats'
import Tabs from './Tabs'
import { DateRange, cn, CN, calculatorOptions } from 'DataSet'
import moment from 'moment'
import { createLoadingSelector } from 'selectors/loading-selector';
import { fetchImportedAccounts, fetchBrokerList } from 'apis/broker'
import { updateBasketAgreement } from 'apis/profile'
import { setupBroker, completeTradeItSetup } from 'actions/broker'
import { fetchProfile } from 'actions/profile'
import { selectorWithTimeFrameRequest, selectorWithTimeFrameSuccess } from 'actions/funds'
import { getTickerListRequest } from 'actions/global'
import { refreshWeightState, fetchWeightsData, saveScoreAttrs, changeCalculator } from 'actions/weights'
import { addEventToAnalytics, nameToDateRange, monthToDate, getNest } from 'Utils';
import { dateRangeActiveObj } from './utils';
import { setFundSelectionTypeRequest, executeMagnifiOrderRequest, getPortfolioDetailsForSFMRequest } from 'actions/sfm';
import { apexPageState } from 'layouts/utils';
import { RETAIL_USERS_ROLES } from '../Constant/FormData';

// let classNames = require('classnames')

let SelectorReportStartDate = null;

class Main extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      range: dateRangeActiveObj, // { return: 'active', risk: 'active' }
      dateRange: {},
      timeFrameData: [],
      loading: false,
      ipWeight: null,
      basketAggreement: true
    }
  }

  componentDidMount() {
    this.state.dateRange = this.nameToDateRange(this.props.items, this.state.range);
    this.calcStats(this.props.items, this.state.range, this.props.scoreAttrs);
    this.fetchAccounts();
  }

  componentWillReceiveProps(newProps) {
    if (!this.state.modal && newProps.items.length > this.props.items.length) {
      this.stageHandler('funds')
    }
    if (newProps.importAccountsSuccess === true) {
      this.fetchAccounts()
    }
    if (newProps.realTimePrices !== this.state.realTimePrices) {
      this.setState({ realTimePrices: newProps.realTimePrices })
    }
  }

  shouldComponentUpdate(newProps, newState) {
    if ( newProps.items.length !== this.props.items.length
      || newState.range !== this.state.range
      || !isEqual(newProps.scoreAttrs, this.props.scoreAttrs)
      || newProps.selectedCalculator !== this.props.selectedCalculator
    ) {
      //this.calcStats(newProps.items, newState.range, newState.scoreAttrs);
      let rr = {
        return: 'active',
        risk: 'active',
      };
      newProps.scoreAttrs.filter((jk) => jk.yearDropdown).map((hb) => {
        if (hb.code) {
          rr[`${hb.code}`] = 'active';
        }
      });
      let dateRange = this.nameToDateRange(newProps.items, rr);
      let range = newState.range;
      // Reset the range to active when a ticker/fund is added or deleted if a custom time range is selected
      if(newProps.items.length !== this.props.items.length && newState.range.fromDatePicker){
        range = {
          return: 'active',
          risk: 'active',
        };
        newProps.scoreAttrs.filter((jk) => jk.yearDropdown).map((hb) => {
          if (hb.code) {
            range[`${hb.code}`] = 'active';
          }
        });
      }
      this.setState({ loading: true, dateRange, range });
      setTimeout(() => {
        // console.log(newState.range);
        this.calcStats(newProps.items, newState.range, newProps.scoreAttrs)
        this.setState({ loading: false })
      }, 1000)
    }
    return true
  }

  fetchAccounts = async () => {
    let importedAccounts = await fetchImportedAccounts().then(result => {
      this.setState({ importedAccounts: result.data })
      this.fetchBrokers((Object.keys(result.data).includes('Magnifi')))
    });
  }

  updateBasketAggrementStatus = async () => {
    try{
      await updateBasketAgreement();
    }catch(err){}
    this.setState({ basketAggreement: false });
  }

  async fetchBrokers(addMagnifi=false) {
    let importedAccounts = await fetchBrokerList().then(result => {
      const { profile : { self_role: profile_self_role } } = this.props;
      if (addMagnifi && RETAIL_USERS_ROLES.includes(profile_self_role)) {
        result.data.brokers = []; // clear all brokers and push "magnifi"
        result.data.brokers.push({
          id:14,
          name:'Magnifi',
          status:'Registered',
        });
      } else if (RETAIL_USERS_ROLES.includes(profile_self_role)) {
        result.data.brokers = [];
      }
      this.setState({ brokers: result.data.brokers })
    })
  }

  calcStats = (items, range, scoreAttrs) => {
    items = items.filter((kl) => kl.type !== 'SHARE');
    const { customCalculatorAttrs } = this.props;
    let dateRange = {};
    if(range.fromDatePicker) {
      dateRange = {
        ...range,
      };
      scoreAttrs.filter((jk) => jk.yearDropdown).map((nl) => {
        if (nl.code && range[nl.code]) {
          dateRange[`${nl.code}`] = range[nl.code]
        }
      });
    } else {
      let _range = range;
      scoreAttrs.filter((jk) => jk.yearDropdown).map((nl) => {
        if (nl.code) {
          let firstKey = Object.keys(range)[0];
          if (firstKey && range[firstKey]) {
            _range[`${nl.code}`] = range[firstKey] // range[`${nl.code}`]
          }
        }
      });
      dateRange = this.nameToDateRange(items, _range);
    }

    const rangeFilter = (e, rnge) => {
      const dt = moment('01 ' + e.d, 'DD MMM YYYY')
      return (
        dt.isAfter(moment(rnge.start).subtract(1, 'seconds')) &&
        dt.isBefore(moment(rnge.end).add(1, 'seconds'))
      )
    }
    const active = item => !getNest(['_sfStats', 'feesOff'], item)
    const inactive = item => {
      if (typeof range.return !== 'undefined' && typeof range.risk !== 'undefined') {
        let mStart = monthToDate(item._start)
        let yRetOff = mStart.isAfter(moment(dateRange.return.start)),
          riskOff = mStart.isAfter(moment(dateRange.risk.start)),
          feesOff = yRetOff && riskOff
        return { yRetOff, riskOff, feesOff }
      }
    }

    items.forEach(e => {
      if (typeof range.return !== 'undefined' && typeof range.risk !== 'undefined') {
        if (range.return === range.risk) {
          e._sfStats = calcAttrStats(
            e.returns.filter(e => rangeFilter(e, dateRange.return))
          )
        } else {
          let stats1 = calcAttrStats(
            e.returns.filter(e => rangeFilter(e, dateRange.return)),
            'return'
          )
          let stats2 = calcAttrStats(
            e.returns.filter(e => rangeFilter(e, dateRange.risk)),
            'risk'
          )
          e._sfStats = { ...stats1, ...stats2 }
        }
        e.computedRisk = e._sfStats ? e._sfStats.risk : null;
        e.computedReturns = e._sfStats ? e._sfStats.yRet : null;
      }
      // console.log('e.computedReturns==>>>', e.computedReturns);
      // console.log('e.computedRisk===>>>', e.computedRisk);
      const allAttrData = e.selectorAttrsData || {};

      let WPN = cn(allAttrData, 'Premium to NAV'),
          WT  = cn(allAttrData, 'Trend'),
          WFF = cn(allAttrData, 'Fund Flows'),
          WD  = cn(allAttrData, 'Drawdown') || cn(allAttrData, 'Drawdown default'),
          WRISK = e.computedRisk,
          WRETURNS = e.computedReturns,
          WCUMULATIVE_RETURNS = cn(allAttrData, 'Cumulative Returns'),
          WSORTINO_RATIO = cn(allAttrData, 'Sortino Ratio');

      if (this.state.timeFrameData && this.state.timeFrameData.length > 0) {
        const found = this.state.timeFrameData.find((hj) => hj.ticker === e.ticker);
        // console.log(found);
        if (found) {
          WPN = found.premium;
          WT = found.trend;
          WFF = found.fund_flow;
          WD = found.drawdown;
          WCUMULATIVE_RETURNS = found.cumulative_return;
          WSORTINO_RATIO = found.sortino_ratio;
        }
      } else if (range) {
        // console.log(range);
        WPN      = (range.weight_premium_to_nav === 'l5y') ? cn(allAttrData, 'Premium to NAV 5yr') : ((range.weight_premium_to_nav === 'l3y') ? cn(allAttrData, 'Premium to NAV 3yr') : (range.weight_premium_to_nav === 'l1y') ? cn(allAttrData, 'Premium to NAV 1yr') : cn(allAttrData, 'Premium to NAV'));
        WT       = (range.weight_trend === 'l5y') ? cn(allAttrData, 'Trend 5yr') : ((range.weight_trend === 'l3y') ? cn(allAttrData, 'Trend 3yr') : (range.weight_trend === 'l1y') ? cn(allAttrData, 'Trend 1yr') : cn(allAttrData, 'Trend'));
        WFF      = (range.weight_fund_flows === 'l5y') ? cn(allAttrData, 'Fund Flows 5yr') : ((range.weight_fund_flows === 'l3y') ? cn(allAttrData, 'Fund Flows 3yr') : (range.weight_fund_flows === 'l1y') ? cn(allAttrData, 'Fund Flows 1yr') : cn(allAttrData, 'Fund Flows'));
        WD       = (range.drawdown === 'l5y') ? cn(allAttrData, 'Drawdown 5yr') : ((range.drawdown === 'l3y') ? cn(allAttrData, 'Drawdown 3yr') : (range.drawdown === 'l1y') ? cn(allAttrData, 'Drawdown 1yr') : cn(allAttrData, 'Drawdown') );
        WRISK    = (range.risk === 'l5y') ? cn(allAttrData, 'Risk 5yr') : ((range.risk === 'l3y') ? cn(allAttrData, 'Risk 3yr') : (range.risk === 'l1y') ? cn(allAttrData, 'Risk 1yr') : WRISK);
        WRETURNS = (range.return === 'l5y') ? cn(allAttrData, 'Returns 5yr') : ((range.return === 'l3y') ? cn(allAttrData, 'Returns 3yr') : (range.return === 'l1y') ? cn(allAttrData, 'Returns 1yr') : WRETURNS);
        WCUMULATIVE_RETURNS = (range['attribute/technical/returns/cumulative_returns'] === 'l5y') ? cn(allAttrData, 'Cumulative Returns 5yr') : ((range['attribute/technical/returns/cumulative_returns'] === 'l3y') ? cn(allAttrData, 'Cumulative Returns 3yr') : (range['attribute/technical/returns/cumulative_returns'] === 'l1y') ? cn(allAttrData, 'Cumulative Returns 1yr') : WCUMULATIVE_RETURNS);
        WSORTINO_RATIO = (range['attribute/technical/returns/risk_adjusted_returns/sortino_ratio'] === 'l5y') ? cn(allAttrData, 'Sortino Ratio 5yr') : ((range['attribute/technical/returns/risk_adjusted_returns/sortino_ratio'] === 'l3y') ? cn(allAttrData, 'Sortino Ratio 3yr') : (range['attribute/technical/returns/risk_adjusted_returns/sortino_ratio'] === 'l1y') ? cn(allAttrData, 'Sortino Ratio 1yr') : WSORTINO_RATIO);

        let riskSelectedYR = (range.risk === 'l5y') ? CN['Risk 5yr'] : ((range.risk === 'l3y') ? CN['Risk 3yr'] : (range.risk === 'l1y') ? CN['Risk 1yr'] : null);
        let returnSelectedYR = (range.return === 'l5y') ? CN['Returns 5yr'] : ((range.return === 'l3y') ? CN['Returns 3yr'] : (range.return === 'l1y') ? CN['Returns 1yr'] : null);

        WRISK = (riskSelectedYR && customCalculatorAttrs.find((j) => j.code === riskSelectedYR) && customCalculatorAttrs.find((j) => j.code === riskSelectedYR).multiplier) ? (WRISK*(customCalculatorAttrs.find((j) => j.code === riskSelectedYR).multiplier)) : WRISK;
        WRETURNS = (returnSelectedYR && customCalculatorAttrs.find((j) => j.code === returnSelectedYR) && customCalculatorAttrs.find((j) => j.code === returnSelectedYR).multiplier) ? (WRETURNS*(customCalculatorAttrs.find((j) => j.code === returnSelectedYR).multiplier)) : WRETURNS;

      }
      const trkerror = find(e.riskAdjReturn, {name: 'Tracking Error'});
      // console.log('drawdown', WD);

      e._sfStats = {
        ...e._sfStats,
        ...inactive(e),
        ...allAttrData,
        // Computed Values
        risk: WRISK, // e.computedRisk,
        yRet: WRETURNS, //e.computedReturns,
        fees: cn(e, 'Fees'),
        aum: cn(e, 'Assets'),
        weight_premium_to_nav: WPN, // (scoreAttrs.find((j) => j.code === 'weight_premium_to_nav') && scoreAttrs.find((j) => j.code === 'weight_premium_to_nav').multiplier) ? (WPN*(scoreAttrs.find((j) => j.code === 'weight_premium_to_nav').multiplier)) : WPN, // || cn(e, 'Premium to NAV'),
        weight_trend: WT, // (scoreAttrs.find((j) => j.code === 'weight_trend') && scoreAttrs.find((j) => j.code === 'weight_trend').multiplier) ? (WT*(scoreAttrs.find((j) => j.code === 'weight_trend').multiplier)) : WT, // || cn(e, 'Trend'),
        weight_fund_flows: WFF, // (scoreAttrs.find((j) => j.code === 'weight_fund_flows') && scoreAttrs.find((j) => j.code === 'weight_fund_flows').multiplier) ? (WFF*(scoreAttrs.find((j) => j.code === 'weight_fund_flows').multiplier)) : WFF, // || cn(e, 'Fund Flows'),
        drawdown: WD, // (scoreAttrs.find((j) => j.code === 'drawdown') && scoreAttrs.find((j) => j.code === 'drawdown').multiplier) ? (WD*(scoreAttrs.find((j) => j.code === 'drawdown').multiplier)) : WD, // || cn(e, 'Drawdown'),
        trkerror: trkerror ? trkerror.value : 0,
        score: -1,
        'attribute/technical/returns/cumulative_returns': WCUMULATIVE_RETURNS,
        'attribute/technical/returns/risk_adjusted_returns/sortino_ratio': WSORTINO_RATIO,
      }
      if(this.state.range.fromDatePicker) {
        //end month end year same?
        const { range:range2 } = this.state
        let _start = undefined , _end = undefined

        if(range2.return){
         _start = range2.return.start
         _end = range2.return.end
        }
        else if(range2.weight_trend){
          _start = range2.weight_trend.start
          _end = range2.weight_trend.end
        }
        else if(range2.weight_premium_to_nav){
          _start = range2.weight_premium_to_nav.start
          _end = range2.weight_premium_to_nav.end
        }else if(range2.weight_fund_flows){
          _start = range2.weight_fund_flows.start
          _end = range2.weight_fund_flows.end
        }

        SelectorReportStartDate = _start;

        if (_end !== undefined && _end.month() === moment().month() && _end.year() === moment().year()) {
          //start month === current - 1 month && start year === current - 1 year
          if (_start.month() === moment().add(1, 'seconds').subtract(11, 'months').month() &&
            _start.year() === moment().add(1, 'seconds').subtract(1, 'years').year()) {
              SelectorReportStartDate = `${moment().add(1, 'seconds').subtract(1, 'years')}`
              e._sfStats.yRet = e.selectorAttrsData.returns_1y ? (e.selectorAttrsData.returns_1y * 100).toFixed(2) : null
              e._sfStats.risk = e.selectorAttrsData.volatility_1y ? (e.selectorAttrsData.volatility_1y * 100).toFixed(2) : null
          } else if (_start.month() === moment().add(1, 'seconds').subtract(35, 'months').month() &&
            _start.year() === moment().add(1, 'seconds').subtract(3, 'years').year()) {
              SelectorReportStartDate = `${moment().add(1, 'seconds').subtract(3, 'years')}`
              e._sfStats.yRet = e.selectorAttrsData.returns_3y ? (e.selectorAttrsData.returns_3y * 100).toFixed(2) : null
              e._sfStats.risk = e.selectorAttrsData.volatility_3y ? (e.selectorAttrsData.volatility_3y * 100).toFixed(2) : null
          } else if (_start.month() === moment().add(1, 'seconds').subtract(59, 'months').month() &&
            _start.year() === moment().add(1, 'seconds').subtract(5, 'years').year()) {
              SelectorReportStartDate = `${moment().add(1, 'seconds').subtract(5, 'years')}`
            e._sfStats.yRet = e.selectorAttrsData.returns_5y ? (e.selectorAttrsData.returns_5y * 100).toFixed(2) : null
            e._sfStats.risk = e.selectorAttrsData.volatility_5y ? (e.selectorAttrsData.volatility_5y * 100).toFixed(2) : null
          } else if (_start.month() === moment().add(1, 'seconds').subtract(119, 'months').month() &&
            _start.year() === moment().add(1, 'seconds').subtract(10, 'years').year()) {
              SelectorReportStartDate = `${moment().add(1, 'seconds').subtract(10, 'years')}`
            e._sfStats.yRet = e.selectorAttrsData.returns_10y ? (e.selectorAttrsData.returns_10y * 100).toFixed(2) : null
            e._sfStats.risk = e.selectorAttrsData.volatility_10y ? (e.selectorAttrsData.volatility_10y * 100).toFixed(2) : null
          }
        }
      }
    })

    const { selectedCalculator } = this.props;
    if (selectedCalculator === 'Magnifi fund selector') {
      calcScores(items.filter(active).map(e => e._sfStats), scoreAttrs);
    } else if (selectedCalculator !== calculatorOptions.magnifiactivescreener) {
      calcScores(items.map(e => e._sfStats), scoreAttrs);
    } else {
      // calcScreenerScores(items.filter(active).map(e => e._sfStats), scoreAttrs);
      calcScreenerScores(items.map(e => e._sfStats), scoreAttrs);
    }
    // let activeDateRange = this.nameToDateRange(items, { return: 'active', risk: 'active' });
    let _defaultActiveRange = {};
    scoreAttrs.filter((jk) => jk.yearDropdown).map((nl) => {
      if (nl.code) {
        let firstKey = Object.keys(dateRangeActiveObj)[0];
        if (firstKey && dateRangeActiveObj[firstKey]) {
          _defaultActiveRange[`${nl.code}`] = 'active' // range[`${nl.code}`]
        }
      }
    });
    // console.log(_defaultActiveRange);
    this.setState({ dateRange }); // for custom dropdown
  }

  stageHandler = (stage, item = 'all') => {
    if (stage === 'combine') {
      this.combineHandler(item)
    }
    this.setState({
      stage: stage || this.state.prevStage,
      prevStage:
        stage === 'confirm' || this.state.stage === 'confirm'
          ? this.state.prevStage
          : this.state.stage
    })
  }

  buyHandler = item => {
    let order = {
      stage: 'buy',
      otype: 'Market',
      price: '',
      qty: '',
      tvalue: '',
      validity: undefined
    }

    if (item === 'all') {
      this.props.items.forEach(e => {
        if (e._order) e._order.stage = 'buy'
        else e._order = { ...order }
      })
    } else {
      if (item._order) item._order.stage = 'buy'
      else item._order = { ...order }
    }
    this.stageHandler('act')
  }

  combineHandler = item => {
    let order = {
      stage: 'compare',
      otype: 'Market',
      price: '',
      qty: '',
      tvalue: '',
      validity: undefined
    }

    if (item === 'all') order.stage = 'combine'

    this.props.items.forEach(e => {
      if (e._order) e._order.stage = order.stage
      else e._order = { ...order }
    })

    if (item !== 'all') item._order.stage = 'combine'
  }

  clearHandler = (item, unselect = true) => {
    if (item === 'all') {
      this.props.items.forEach(e => e._order && (e._order.stage = 'compare'))
      unselect && this.props.selectHandler()
    } else {
      item._order && (item._order.stage = 'compare')
      unselect && this.props.selectHandler(item)
    }
  }

  rangeHandler = (attr, selRange) => {
    const { funds, items: sfmFundsList  } = this.props;
    let range = {};
    // console.log(selRange);
    if(selRange.fromDatePicker) {
      range = this.state.dateRange || range;
      const { selectorWithTimeFrameRequest, selectorWithTimeFrameSuccess, dropdownScoreAttrs } = this.props;
      // call API only for ['weight_trend', 'weight_fund_flows', 'weight_premium_to_nav']
      if (dropdownScoreAttrs && dropdownScoreAttrs.length > 0) {
        // console.log(dropdownScoreAttrs);
        // let commonAttr = dropdownScoreAttrs.filter((nl) => nl.yearDropdown && selRange[nl.code]).length && dropdownScoreAttrs.filter((nl) => nl.yearDropdown && selRange[nl.code])[0]
        // let commonRange = commonAttr && selRange[commonAttr.code]
        // console.log(common_range);
        dropdownScoreAttrs.filter((jk) => jk.yearDropdown).map((nl) => {
          if (nl.code && selRange[nl.code]) {
            range[`${nl.code}`] = selRange[nl.code]
          }
          // if (nl.code) {
          //   range[`${nl.code}`] = common_range || selRange[nl.code]
          // }
        })
      }
      range.fromDatePicker = true;
      // console.log(range);

      if (selectorWithTimeFrameRequest && dropdownScoreAttrs.filter((jk) => !jk.default && jk.yearDropdown && jk.code === attr).length > 0) {
        // check for custom date range
        if (selRange[attr] && selRange[attr].start) {
          let startDate = moment(selRange[attr].start).format("MMM YYYY");
          let endDate = moment(selRange[attr].end).format("MMM YYYY");
          const tickersWillBe = sfmFundsList.filter(v => v._selector === true).map((g) => g.ticker);
          if (tickersWillBe && tickersWillBe.length > 0) {
            selectorWithTimeFrameRequest({
              start_date: startDate,
              end_date: endDate,
              tickers: tickersWillBe,
            }, (apiResponse) => {
              if (apiResponse) {
                this.setState({ timeFrameData: apiResponse }, () => {
                  if (selectorWithTimeFrameSuccess) {
                    selectorWithTimeFrameSuccess();
                    // calcStats not triggering after api response
                    this.calcStats(this.props.items, range, this.props.scoreAttrs);
                  }
                });
              }
            });
          }
        }
      }
    } else {
      const { dropdownScoreAttrs } = this.props;
      if (dropdownScoreAttrs && dropdownScoreAttrs.length > 0) {
        dropdownScoreAttrs.filter((jk) => jk.yearDropdown).map((nl) => {
          if (nl.code) {
            range[`${nl.code}`] = selRange
          }
        })
      }
      this.setState({ timeFrameData: [] }); // when not custom-range then empty ARRAY, to execute else of 'timeFrameData' in calcStats function
    }
    this.setState({ range })
    // console.log('this.state.range --> ', this.state);
    this.calcStats(this.props.items, this.state.range, this.props.scoreAttrs);
    if (funds) {
      addEventToAnalytics('Compare time period change','Compare time period change','COMPARE_TIME_PERIOD_CHANGE',{ query: funds.query, logId: funds.logId },true);
    }
  }

  scoreAttrsHandler = (name, move) => {
    const swap = (arr, i1, i2) => {
      let t = arr[i1]
      const i1Weigth = arr[i1].weight
      const i2Weigth = arr[i2].weight
      arr[i1] = arr[i2]
      arr[i1].weight = i1Weigth
      arr[i2] = t
      arr[i2].weight = i2Weigth
    }

    var scoreAttrs = [...this.props.scoreAttrs]
    var i = scoreAttrs.findIndex(e => e.name === name)

    if (move === 'up' && i > 0) swap(scoreAttrs, i, i - 1)
    else if (move === 'down' && i < scoreAttrs.length - 1)
      swap(scoreAttrs, i, i + 1)
    else return

    this.props.saveScoreAttrs(scoreAttrs);
    if (this.props.enablePrintReports) {
      const { getScoreAttrs } = this.props;
      getScoreAttrs(scoreAttrs);
    }
  }

  scoreAttrsHandler2 = (scoreAttrs) => {
    this.props.saveScoreAttrs(scoreAttrs);
    if (this.props.enablePrintReports) {
      const { getScoreAttrs, items } = this.props;
      getScoreAttrs(scoreAttrs, items);
    }
  }

  scoreAttrsWeightHandler = values => {

  }

  nameToDateRange = (items, range) => {
    let d = moment.max(items.map(e => monthToDate(e._start)))
    let sDefault = moment(DateRange.start),
        sActive = moment.max(sDefault, d);

    let maxRange = r => ({ start: r !== 'active' ? sDefault : sActive, end: moment(this.props.dateRange.end) });

    let rr = {};

    Object.keys(range).map((de) => {
      if (de && range[de]) {
        rr[`${de}`] = nameToDateRange(range[de], maxRange(range[de]));
      }
    });

    return rr;
  }

  modalToggle = mTog => {
    const { modalToggle } = this.props;
    if (modalToggle) {
      modalToggle(mTog)
    }
  }

  //new ExecuteOrderHandler
  executeOrderHandler = (payload) => {
    const { executeMagnifiOrderRequest, getPortfolioDetailsForSFMRequest, profileState } = this.props;
    if (executeMagnifiOrderRequest) {
      executeMagnifiOrderRequest(payload, () => {
        if (profileState) {
          let { profile } = profileState;
          if (profile) {
            let APEX_PAGE_STATE = apexPageState({ profile });
            if (process.env.REACT_APP_SUB_DOMAIN === 'retail' &&
              APEX_PAGE_STATE &&
              APEX_PAGE_STATE !== null &&
              APEX_PAGE_STATE.accountNo !== null &&
              typeof APEX_PAGE_STATE.accountNo !== 'undefined' &&
              getPortfolioDetailsForSFMRequest
            ) {
              getPortfolioDetailsForSFMRequest({ accountNumber: APEX_PAGE_STATE.accountNo });
            }
          }
        }
      });
    }
  }

  sortFn = (a, b) => {
    let [_a, _b] = [a._sfStats, b._sfStats]
    if (_a && _b) {      // descending order
      if (_a.score < _b.score) return 1;
      else if (_a.score > _b.score) return -1;
    }
    return 0;
  }

  impactTabWeight = (weight) => {
    this.setState({ ipWeight: weight });
  }

  printReport = (data) => {
    const { printReport } = this.props;
    console.log('printReport -> ', this.state.dateRange)
    if (printReport) {
      printReport({
        ...data,
        dateRange: this.state.dateRange,
      });
    }
  }

  render() {
    let selectedItems = this.props.items.filter(v => v._selector === true);
    console.log('SelectorReportStartDate --> ', this.state.dateRange)
    return (
      <SfModal
        {...this.props}
        ipWeight={this.state.ipWeight}
        executeOrderHandler={this.executeOrderHandler}
        autoOpenModal={this.props.autoOpenModal}
        backHandler={this.backHandler}
        buyHandler={this.buyHandler}
        actionHandler={this.stageHandler}
        clearHandler={this.clearHandler}
        modalToggle={this.modalToggle}
        selectHandler={this.props.selectHandler}
        range={this.state.range}
        dateRange={this.state.dateRange}
        rangeHandler={this.rangeHandler}
        scoreAttrsHandler={this.scoreAttrsHandler}
        scoreAttrsHandler2={this.scoreAttrsHandler2}
        scoreAttrsWeightHandler={this.scoreAttrsWeightHandler}
        scoreAttrs={this.props.scoreAttrs}
        loading={this.state.loading || this.props.isWeightsLoading || this.props.selectorTimeFrameLoader}
        stage={this.state.stage}
        importedAccounts={this.state.importedAccounts}
        brokers={this.state.brokers}
        fetchAccounts={this.fetchAccounts}
        updateBasketAggrementStatus={this.updateBasketAggrementStatus}
        basketAggreement={this.state.basketAggreement}
        fetchWeightsData={this.props.fetchWeightsData}
        selectedItems={selectedItems}
        impactTabWeight={this.impactTabWeight}
        printReport={this.printReport}
      />
    )
  }
}

const mapStateToProps = state => {
  const weights = state.weights;
  return {
    sfm: state.sfm,
    dateRange: state.discovery.dateRange,
    commonActiveSFMReturnsRange: state.sfm.commonActiveSFMReturnsRange,
    selectedBroker: state.broker.selectedBroker,
    receivedEndPoint: state.broker.receivedEndPoint,
    error: state.broker.error,
    // isFetching: createLoadingSelector(['SETUP_BROKER'])(state),
    // isDemoUser: isDemoUser(),
    importAccountsSuccess: state.importBrokerAccount.importAccountsSuccess,
    displayVerification: state.tradeItSecurityHandling.displayVerification,
    weights: weights.weightScore,
    mobileDevice: state.global.mobileDevice,
    scoreAttrs: weights.scoreAttrs,
    selectedCalculator: weights.selectedCalculator,
    isWeightsLoading: weights.loading,
    customCalculators: weights.customCalculators,
    dropdownScoreAttrs: weights.scoreAttrs.filter(x => x.weight !== 0 && x.yearDropdown) || [],
    magnifiTickers: state.global.magnifiTickers,
    profileState: state.profile,
    selectorTimeFrameLoader: createLoadingSelector(['SELECTOR_WITH_TIME_FRAME'])({ loading: state.loading }),
    // getSFMItemDetails: createLoadingSelector(['GET_SFM_ITEM_DETAILS'])({ loading: state.loading }),
    fetchingAllTickersList: createLoadingSelector(['GET_ALL_TICKER_LIST'])({ loading: state.loading }),
    fetchFundsLoading: createLoadingSelector(['ADD_SFM_ITEM_TICKERS', 'REMOVE_SFM_ITEM', 'UPDATE_HRS_DATA', 'GET_SFM_ITEM_DETAILS', 'ADD_TICKERS_TO_CART', 'GET_PORTFOLIO_DETAILS_FOR_SFM'])({ loading: state.loading }),
    executeOrderLoading: createLoadingSelector(['EXECUTE_MAGNIFI_BROKER_ORDERS'])({ loading: state.loading }),
    profileLoading: createLoadingSelector(['PROFILE'])({ loading: state.loading }),
  }
}

const mapDispatchToProps = {
  setupBroker,
  fetchProfile,
  completeTradeItSetup,
  refreshWeightState,
  fetchWeightsData,
  saveScoreAttrs,
  changeCalculator,
  selectorWithTimeFrameRequest,
  selectorWithTimeFrameSuccess,
  executeMagnifiOrderRequest,
  setFundSelectionTypeRequest,
  getTickerListRequest,
  getPortfolioDetailsForSFMRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main)

class SfModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: (props.sfm && props.sfm.modal) || false,
      open: true,
      alert: true,
      showFunDetails: false,
      selectedFunDetails: undefined
    }
    this.inRef = React.createRef()
    this.bw = document.body.clientWidth
  }

  componentDidMount() {
    document.body.classList['remove']('modal-open')
    document.body.classList[this.state.modal ? 'add' : 'remove'](
      'modal-open-sfm'
    )
    setTimeout(() => {
      this.setState({ alert: false })
    }, 2000)
    $('.sfp-backdrop').click(this.toggle) // hack as bockdrop click not working
    this.props.confirmHandler(false)
  }

  componentWillReceiveProps(newProps) {
    if (newProps.autoOpenModal && !this.state.modal) {
      this.autoOpenModal();
    }

    // console.log('==== newProps.sfm.modal ====> ', newProps.sfm.modal, newProps.sfm.modalToggleState);
    if (newProps.sfm.modal !== this.state.modal) {
      this.setState({ modal: newProps.sfm.modal }, () => {
        document.body.classList[this.state.modal ? 'add' : 'remove'](
          'modal-open-sfm'
        )
      })
    }

    if (!this.state.modal && newProps.items.length !== this.props.items.length) {
      this.setState({ alert: true });
      setTimeout(() => { this.setState({ alert: false }) }, 2000);
    }
  }

  componentWillUnmount() {
    $('.sfp-backdrop').unbind('click', this.toggle)
    document.body.classList['remove']('modal-open-sfm')
  }

  autoOpenModal = () => {
    this.setState({ modal: true }, () => {
      document.body.classList['add']('modal-open-sfm');
      this.props.modalToggle('add');
    });
  }

  toggle = () => {
    // console.log('tooglle')
    this.setState({ modal: !this.state.modal }, () => {
      document.body.classList[this.state.modal ? 'add' : 'remove'](
        'modal-open-sfm'
      )
      this.props.modalToggle(this.state.modal ? 'add' : 'remove')
      // this.inRef.current.style.paddingRight = `${this.state.modal ? document.body.clientWidth-this.bw : 0}px`;
      if(this.state.modal){
        addEventToAnalytics('SFM Maximize','SFM Maximize','SFM_MAXIMIZE',{ clickBtn: 'SFM Maximize' },true);

      }
      else {
        addEventToAnalytics('SFM Minimize','SFM Minimize','SFM_MINIMIZE',{ clickBtn: 'SFM Minimize' },true);
      }
    })
  }

  clearAllHandler = () => {
    this.setState({ open: false }, () => {
      setTimeout(() => {
        this.props.clearHandler('all', true)
      }, 300)
    })
  }

  open = () => {
    this.setState({ open: !this.state.open }, () => {})
  }

  toggleFunDetails = selectedFunDetails => {
    if (window.innerWidth < 760)
      this.setState(prevState => ({
        showFunDetails: !prevState.showFunDetails,
        selectedFunDetails
      }))
  }

  executeHandler = (payload) => {
    // this.toggle()
    const { executeOrderHandler } = this.props;
    if (executeOrderHandler) {
      executeOrderHandler(payload);
    }
  }

  render() {
    const { showFunDetails, selectedFunDetails, open, modal } = this.state
    const { items, mobileDevice, selectedItems, importedAccounts, fetchFundsLoading } = this.props;
    const size = this.state.modal ? 'full' : this.state.alert ? 'anim' : 'min'
    const classes = mobileDevice ? ' modal-full-screen' : ''
    const closeBtn = (
      <button
        className={`close ${open ? 'media-close' : ''} ${
          showFunDetails ? 'd-none' : ''
        }`}
        onClick={this.toggle}
      >
        {/* <i className={`fas text-white icon-cancel d-md-none ${this.state.modal && !showFunDetails ? "d-sm-block":"d-none"} `}></i> */}
        <div
          className={`hamburger ${
            size === 'full' && !showFunDetails ? ' active' : ''
          } `}
        >
          <div className="hamburger-box">
            <div className="hamburger-inner"></div>
          </div>
        </div>
        <i
          className={`far text-white ${
            this.state.modal ? 'fa-minus' : 'fa-plus'
          }`}
        ></i>
      </button>
    )
    const renderBtn = showFunDetails ? (
      this.state.modal ? (
        <div
          className="media-arrow d-md-none d-sm-block modal-back-arrow"
          onClick={() => this.toggleFunDetails(selectedFunDetails)}
        >
          <i className="fas fa-arrow-left" style={{ color: '#fff' }} />
        </div>
      ) : (
        <div
          className="media-arrow d-md-none d-sm-block modal-back-arrow"
          onClick={this.toggle}
        >
          <i className="fas fa-plus" style={{ color: '#fff' }} />
        </div>
      )
    ) : (
      closeBtn
    )
    let selectedFunds = selectedItems && selectedItems.length > 0 ? selectedItems : items;
    // console.log('importedAccounts ==>', importedAccounts);
    // console.log('MAIN.js fetchFundsLoading ===>', fetchFundsLoading);
    return (
      <Modal
        className="fund-container"
        modalClassName={'sfp sel-dropdown '+size+classes}
        className="modal-lg"
        contentClassName="bg-ssf-blue-d2"
        backdropClassName="sfp-backdrop"
        innerRef={this.inRef}
        backdrop={true}
        isOpen={open}
        toggle={this.toggle}
      >
        <ModalHeader
          className="media-details-tab bg-sfmodal-header text-white modal-header-title-body"
          toggle={this.toggle}
          close={renderBtn}
          tag="div"
        >
          <div className="modal-header-title-container" onClick={this.toggle}>
            <img className={`fas fa-briefcase mr-3 ${
                showFunDetails ? 'd-none' : ''
              }`} src='/assets/images/icons/shopping-cart-White-circle.svg' alt='' />
            {/* <i
              className={`fas fa-briefcase mr-3 ${
                showFunDetails ? 'd-none' : ''
              }`}
            ></i> */}
            <span onClick={this.toggle} className="font-weight-bold">
              {`${selectedFunds && selectedFunds.length && !showFunDetails ? (selectedFunds.length > 1 ? selectedFunds.length + " Funds Selected" : selectedFunds.length + " Fund Selected") : "Fund Information"}`}
            </span>
          </div>
        </ModalHeader>
        <ModalBody className="p-0 bg-sfmodal-grey font-one">
          {open && open && typeof importedAccounts !== 'undefined' && importedAccounts && (
            <Tabs
              {...this.props}
              card={this.props.card || {}}
              showFunDetails={showFunDetails}
              selectedFunDetails={selectedFunDetails}
              open={size === 'full' && !showFunDetails}
              executeHandler={this.executeHandler}
              toggleFunDetails={this.toggleFunDetails}
              clearAllHandler={this.clearAllHandler}
            />
          )}
        </ModalBody>
      </Modal>
    )
  }
}

import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Row, Col, Card, Typography, Tabs, Checkbox } from 'antd';
import { Tooltip } from 'reactstrap';
import { FormatCurrency } from 'components/reports/shared/Utils';
import { FeeReturnRisk } from './FeeReturnRisk';
import { ViewChart } from './ViewChart';

import { sponsorLogo } from 'DataSet';

const { Paragraph } = Typography;
const { TabPane } = Tabs;

export class ItemCard extends Component {
  constructor(props){
    super(props)
    this.state = {
      tooltipOpen: false
      // selected: false
    }
  }

  tabOnChange = (selectedKey, data, ticker, query, index) => {
    const { tabOnChange } = this.props;
    if (tabOnChange) {
      tabOnChange(selectedKey, data, ticker, query, index);
    }
  }

  getItemCardDesign = () => {
    const {
      data,
      query,
      ticker,
      enhanceReplace,
      i,
      staticCard,
      resetCheck,
      highlights,
      className,
      selected,
      portfolioQuery,
      activeViewTab,
      relevanceChartTabs,
      defaultFRR,
      originalQueryVar,
      checked,
      invalidTickerFunds,
      summaryPortfolioFunds,
    } = this.props;

    if (data && data.ticker) {
      const id = data.index ? 'cb-'+ticker+data.index+data.id : 'cb-'+ticker+data.vehicle+data.ticker;
      const {
        yRet,
        volt,
        feesQ,
        yRetQ,
        voltQ,
        queryIcon,
        FRR,
        reportData,
        odometerChart,
        _selected,
      } = data;
      const getTabpaneDesign = (chartDataObj) => {
        // console.log(chartDataObj);
        if (chartDataObj) {
          const { smallScreen, bigScreen, key } = this.props;
          const GuageChartWidth = smallScreen ? 90 : (bigScreen ? 130 : 90);
          return (
            <ViewChart
              size="md"
              i={id}
              data={chartDataObj}
              width={GuageChartWidth}
            />
          )
        }
      }
      const getTabHeadText = (chartDataObj) => {
        try {
          // console.log(chartDataObj);
          if (chartDataObj && chartDataObj.name) {
            if (chartDataObj.name.includes('Overlap')) {
              return `Overlap with ${ticker}`;
            } else if (chartDataObj.name.includes('Correlation')) {
              return `Correlation with ${ticker}`;
            } else {
              return chartDataObj.name;
            }
          }
        } catch (e) {
          console.log(e);
        }
      }

      return (
        <Card
          key={enhanceReplace+i}
          className={`${!staticCard ? 'card-background-grey' : 'sddf'} ${className}`}
          style={{minWidth: '250px', width: '250px', margin: '0 auto' }}
        >
          <div className="p-1 border-bottom">
            <Row style={{minHeight: '90px'}}>
              {!staticCard
                && (
                  <Checkbox
                    id={id}
                    inline
                    className='p-top-right custom-ant-checkbox'
                    type="checkbox"
                    title="Select"
                    checked={checked}
                    onClick={(e) => this.props.setReplaceTicker(e, data, data.ticker, ticker, query)}
                  />
                )
              }
              <TickerPrice
                className="d-flex flex-column justify-content-center align-items-center"
                ticker={data.ticker_short || data.ticker}
                price={data.nav}
              />
              <NameSponsor
                className="d-flex flex-column justify-content-center align-items-left"
                name={data.short_name}
                sponsor={data.sponsor}
                category={data.category}
              />
              {(typeof data.class_type !== 'undefined' && data.class_type &&  ["institutional", "retail"].includes(data.class_type))
              && (
              <div>
                <img id={`fundclass-${data.ticker}`} className="card-Ad-Icon" src={`/assets/images/${data.class_type}.png`} width='10' />
                <Tooltip className="col-grey" placement="right" isOpen={this.state.tooltipOpen} target={`fundclass-${data.ticker}`} toggle={() => this.setState({ tooltipOpen: !this.state.tooltipOpen })}>
                  {data.class_type === 'retail' ? 'Retail Class' : 'Institutional Class'}
                </Tooltip>
             </div>)
              }
            </Row>
          </div>
          <div className="border-bottom">
            <FeeReturnRisk FRR={FRR} />
          </div>
          <div className="relevance-charts-tab-wrapper">
            {odometerChart && odometerChart.length > 0 && (
              <Tabs activeKey={activeViewTab} size="small" style={{ marginBottom: 5 }} onChange={(key) => this.tabOnChange(key, data, ticker, query, this.props.i)}>
                {odometerChart.map((op, opI) => {
                  return (
                    <TabPane tab={getTabHeadText(op)} key={opI+1}>
                      {getTabpaneDesign(op)}
                    </TabPane>
                  )
                })}
              </Tabs>
            )}
          </div>
        </Card>
      );
      return (<div>{ticker}</div>)
    }
  }

  render() {
    const renderDesign = this.getItemCardDesign();
    return (
      <React.Fragment>
        {renderDesign}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  // enhanceReplace: state.portfolio.enhanceReplace,
  smallScreen: global.smallScreen,
  bigScreen: global.bigScreen,
})

const mapDispatchToProps = {

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemCard)


//className="d-flex flex-column justify-content-center align-items-center" style={{minWidth: '4rem'}}
const TickerPrice = ({ ticker, price, className }) => (
  <Col className={className} span={8}>
    <h5 className="mb-0 ticker-size ticker-price">{ticker}</h5>
    <h4 className="mt-1 mb-0 ticker-price">{FormatCurrency(price, 2)}</h4>
  </Col>
)

const NameSponsor = ({ name, sponsor, category, className }) => (
  <Col className={className} span={16}>
    <div className="pb-1">
      <ImageOrText img={sponsorLogo(sponsor)} text={sponsor} />
    </div>
    <h5 className="m-0" title={name} style={{ fontWeight: 600, lineHeight: 1.2 }}>
      <Paragraph
        className="text-muted item-card-text"
        ellipsis={{rows: 2}}
        title={name}
      >
        {name}
      </Paragraph>
    </h5>
    <h5 className="mb-0 text-gray-6" title={category} style={{ lineHeight: 1.4, color: '#57869c', marginTop: 3 }} >
      <Paragraph
        className="m-0 category-text"
        ellipsis={{rows: 1}}
        title={category}
        >
        {category}
      </Paragraph>
    </h5>
  </Col>
)

const ImageOrText = ({ img, text = '' }) => {
  const [valid, setValid] = useState(true);

  return (
    valid
    ? <img src={img} className="img-fluid" alt="" onError={() => setValid(false)} style={{height: '20px'}} />
    : <div className="sponsor-logo-placeholder" title={text}>{text}</div>
  );
}

const getId = (function* () {
  let n = 1;
  while (true) yield n++;
})();

import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Redirect } from 'react-router-dom';
// import { isEmpty } from 'lodash';
import { createLoadingSelector } from 'selectors/loading-selector';
// import { searchQueryRequest } from 'actions/discovery';
import config from 'env'
// import { PopularThemes as PopularThemesContent } from 'DataSet';
// import { getFundsByQuery } from '../utils';
import RelatedInsights from './RelatedInsights';
import PopularThemes from './PopularThemes';

class Plugins extends Component {
  constructor(props){
    super(props);
  }

  executeCall = (query) => {
    const { executeCall } = this.props;
    executeCall(query, 'related_searches');
  }

  queryHandler = (query, search_type) => {
    if (query) {
      const { queryHandler, history } = this.props;
      history.push('/securities');
      queryHandler(query, search_type);
    }
  }

  render() {
    const { query, loading, relatedInsights, sponsors_tickers, fundsData } = this.props;
    const foundSponsoredTicker = fundsData.find((kl) => (sponsors_tickers.find((l) => (l.ticker === kl.ticker && l.media_url && l.media_url !== '' && l.media_url.length)) && kl.is_sponsored))
    let isSponseredAvailable = (config.sponsoredTicker && sponsors_tickers && sponsors_tickers.length > 0 && typeof foundSponsoredTicker !== 'undefined')
    // console.log('isSponseredAvailable ==> ', isSponseredAvailable);
    return (
      <React.Fragment>
        <RelatedInsights
          {...this.props}
          query={query}
          loading={loading}
          isSponseredAvailable={isSponseredAvailable}
          className={`${isSponseredAvailable ? 'sponsors-insight' : ''}`}
          relatedInsights={relatedInsights}
          executeCall={this.executeCall}
        />
        <PopularThemes
          className={loading ? 'hide' : 'show'}
          query={query}
          queryHandler={this.queryHandler}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ discovery, plugins, loading }) => ({
  query: discovery.query,
  fundsData: discovery.data,
  sponsors_tickers: discovery.sponsorsTickers,
  relatedInsights: plugins.relatedInsights,
  loading: createLoadingSelector(['FETCH_RELATED_INSIGHTS_DATA'])({ loading }),
})

const mapDispatchToProps = {

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Plugins)

import React from 'react'
import { connect } from 'react-redux'
import { createSubscriptionRequest } from 'actions/profile'
import {
  Alert,
  Col,
  Collapse,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Button,
  Input
} from 'reactstrap'
import { PrimaryButton } from 'shared-components'
import ProfileUnsubscriptionModal from './ProfileUnsubscriptionModal'
import ContinueWithPlanModal from './ContinueWithPlanModal'
import ProfileReactivationModal from './ProfileReactivationModal'

import config from '../../env';

class ProfilePricingPlans extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openSubscriptionStatusModal: false,
      openContinueWithPlanModal: false,
      reactivatePlanModal: false
    }
    this.props.createSubscriptionRequest();
    this.subscribeNow = this.subscribeNow.bind(this);
    this.changeUnsubscribeModalStatus = this.changeUnsubscribeModalStatus.bind(this);
    this.changeContinueWithPlanModalStatus = this.changeContinueWithPlanModalStatus.bind(this);
    this.changeReactivatePlanModalStatus = this.changeReactivatePlanModalStatus.bind(this);
  }

  subscribeNow() {
    const sessionId = this.props.stripeSessionID;
    if (sessionId) {
      this.stripe = window.Stripe(config.stripePublishableKey);
      this.stripe.redirectToCheckout({
        sessionId
      }).then((result) => {
        // console.log("results status",result)
      });
    }
  }

  changeReactivatePlanModalStatus(status) {
    this.setState({
      reactivatePlanModal: status
    })
  }

  changeUnsubscribeModalStatus(status) {
    this.setState({
      openSubscriptionStatusModal: status
    })
  }

  changeContinueWithPlanModalStatus(status) {
    this.setState({
      openContinueWithPlanModal: status
    })
  }

  render() {

    let showButton = '';

    if (this.props.premiumUser && !this.props.cancellation_request_status) {
      showButton = (
        <Button color="tools"
          className="btn h3 text-uppercase font-weight-bold p-3 mb-0 ml-auto secondary-btn"
          onClick={() => this.setState({ openSubscriptionStatusModal: true })}
        >
          UNSUBSCRIBE
        </Button>
      )
    } else if (this.props.cancellation_request_status) {
      showButton = (
        <Button color="tools"
          disabled={!this.props.stripeSessionID}
          className="btn h3 text-uppercase font-weight-bold p-3 mb-0 ml-auto secondary-btn"
          onClick={() => this.setState({ reactivatePlanModal: true })}
        >
          UPGRADE
        </Button>
      )
    } else {
      showButton = (
        <Button color="tools"
          disabled={!this.props.stripeSessionID}
          className="btn h3 text-uppercase font-weight-bold p-3 mb-0 ml-auto secondary-btn"
          onClick={this.subscribeNow}
        >
          UPGRADE
        </Button>
      )
    }

    return (
      <React.Fragment>
        <ProfileUnsubscriptionModal modalStatus={this.state.openSubscriptionStatusModal} changeUnsubscribeModalStatus={this.changeUnsubscribeModalStatus} />
        <ContinueWithPlanModal modalStatus={this.state.openContinueWithPlanModal} changeContinueWithPlanModalStatus={this.changeContinueWithPlanModalStatus} premiumUser={this.props.premiumUser} />
        <ProfileReactivationModal modalStatus={this.state.reactivatePlanModal} changeReactivatePlanModalStatus={this.changeReactivatePlanModalStatus} />
        <div className="profile__section profile__personal-info bottom">
          <div className="profile__section-header">
          </div>
          <Container>
            <Row>
              <Col xs={12} className="profile-details-wrapper">
                <div className="">
                  <Row className="profile-element-wrapper">
                    <Col xs={12} className="profile-change-plan">
                      <h5>Pricing and plans</h5>

                      <Row>
                        <Col xs={12} className="basic-section">
                          <div className="">
                            <div className="profile-description-heading">
                              <Row>
                                <Col xs={6}>
                                  <h5>Basic</h5>
                                </Col>
                                <Col xs={6} className="text-right">
                                  <h5>Free</h5>
                                </Col>
                              </Row>
                            </div>
                            <div className="profile-description-list">
                              <ul>
                                <li><p><b>Discover</b> investments</p></li>
                                <li><p><b>Analyze and compare</b> investments</p></li>
                                <li><p><b>Trade</b> and buy funds</p></li>
                              </ul>
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} className="premium-section pb-2">
                          <div className="profile-description-heading">
                            <Row>
                              <Col xs={6}>
                                <h5>Premium</h5>
                              </Col>
                              <Col xs={6} className="text-right">
                                <p><b>$500</b>/month</p>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col xs={12} className="profile-details-wrapper">
                <div className="">
                  <Row className="profile-element-wrapper">
                    <Col xs={12} className="premium-feature-list">
                      <ul>
                        <li><p><b>Selector:</b> Personalize our patented fund selector to find the best fund for you among relevant funds discovered.</p></li>
                        <li><p><b>Builder:</b> Start with selected funds or initial portfolio to build a portfolio for proposals or trades.</p></li>
                        <li><p><b>Enhancer:</b> Read in client, prospect or sponsor provided portfolios and evaluate enhancements with simple language based queries.</p></li>
                        <li><p><b>Proposals:</b> for clients to help convert and explain; and for compliance from fund selector, builder and enhancer. </p></li>
                        <li><p><b>Save:</b>  Results and download to spreadsheets.</p></li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="profile__section-header">
          </div>
          <Container>
            <Row>
              <Col xs={12}>
                <div className="">
                  <div className="profile__subscription-button text-right">
                    <FormGroup>
                      {showButton}
                    </FormGroup>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ profile, auth }) => ({
  useremail: auth.user.email,
  premiumUser: auth.user.premiumUser,
  stripeSessionID: profile.stripeSessionID,
  subscription: profile.profile.subscription,
  subscription_id: auth.user.subscription_id,
  cancellation_request_status: auth.user.cancellation_request_status
})

const mapDispatchToProps = {
  createSubscriptionRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePricingPlans);

import React from 'react';

export const Loader = ({ loading, valid, ...props }) => {
  if (loading)
    return <ContentLoader {...props} />
  else if (!valid)
    return <NoResult {...props} />
  else
    return null
}

export const Spinner = () => {
  return (
    <div className="spinner-wrapper">
      <div className="text-secondary text-center">
        <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5"></i>
      </div>
    </div>
  )
}

export const ContentLoader = ({ ...props }) => {
  return (
    <div {...props} className="overlay active" style={{ position: 'absolute',  zIndex: '1052' }}>
      <div className="text-secondary text-center" style={{    position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
        <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5"></i>
      </div>
    </div>
  )
}


export const NoResult = ({ ...props }) => {
  return (
    <div {...props} className="spinner-wrapper">
      <div className="text-secondary text-center">
        <i className="fal fa-comment-exclamation fa-4x text-gray-5"></i>
        <h3 className="text-gray-4 mt-4">- no data -</h3>
      </div>
    </div>
  )
}

export default {
  Loader,
  Spinner,
  NoResult,
  ContentLoader,
};

import React, { Component } from 'react'
import { connect } from 'react-redux'

import 'components/reportsV2/_shared_reports.scss'
import 'components/reportsV2/_act-reports.scss'
import SfReportV2 from 'components/reportsV2/SfReportV2'

import { logout } from '../actions/auth'

class ReportsContainerV2 extends Component {
  render() {
    return <SfReportV2 {...this.props} />
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  auth: state.auth,
  isSSOUser: Boolean(state.auth.isGoogleLogin),
  loggedIn: state.auth.loggedIn,
  logoutSuccess: state.auth.logoutSuccess,
})

const mapDispatchToProps = {
  logoutHandler: logout,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsContainerV2)

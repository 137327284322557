import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button } from 'antd';

import { selectDomainToRedirect } from 'actions/auth';

import { PrimaryButton } from 'shared-components'
import CountDownTimer from 'components/CountDownTimer';

class SelectDomainPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: props.visible || true,
      submitDisabled: false
    }
  }

  componentDidMount() {

	}

	componentWillReceiveProps(newProps) {

	}

	toggleModalChange = () => {
		this.setState({ visible: false });
    const { selectDomainToRedirect } = this.props;
    if (selectDomainToRedirect) {
      selectDomainToRedirect({ advisorRetailRedirectData: undefined });
    }
	}

	onModalClose = () => {
    this.toggleModalChange();
    if (document.getElementById('mail-to-magnifi')) {
      document.getElementById('mail-to-magnifi').click();
    }
	}

	onClickOk = () => {
    this.toggleModalChange();
    const { advisorRetailRedirectData } = this.props;
    const visible = (advisorRetailRedirectData && typeof advisorRetailRedirectData !== 'undefined' && Object.keys(advisorRetailRedirectData).length > 0);
    if (!visible) return null;

    let {
      REACT_APP_SUB_DOMAIN,
      isRetailUser,
      redirectTo,
    } = advisorRetailRedirectData;

    console.log('-- redirect to --', redirectTo);
    window.location.href = redirectTo;
	}

  render() {
    const {
      width,
      style,
      footer,
      loading,
      closable,
      centered,
      maskStyle,
      modalTitle,
      wrapClassName,
      disclaimerMessage,
      advisorRetailRedirectData,
    } = this.props;

    const visible = (advisorRetailRedirectData && typeof advisorRetailRedirectData !== 'undefined' && Object.keys(advisorRetailRedirectData).length > 0);
    if (!visible) return null;

    let {
      REACT_APP_SUB_DOMAIN,
      isRetailUser,
      redirectTo,
    } = advisorRetailRedirectData;

    // {(REACT_APP_SUB_DOMAIN === 'advisor' && isRetailUser) && (
    //   <p style={{ marginBottom: 5 }}>Redirecting you to {retail_redirect}</p>
    // )}
    // {(REACT_APP_SUB_DOMAIN === 'retail' && !isRetailUser) && (
    //   <p style={{ marginBottom: 5 }}>Redirecting you to {redirectTo}</p>
    // )}

    let hostname = redirectTo.replace('http://', '').replace('https://', '').replace('www.', '').split(/[/?#]/)[0];
    // console.log(hostname);
    return (
      <React.Fragment>
        <Modal
          visible={visible}
          closable={closable}
          centered={centered}
          footer={footer}
          width={width}
          title={modalTitle}
          style={{ ...style, display: loading ? 'none' : '' }}
          maskStyle={maskStyle}
          bodyStyle={{ minHeight: 212, display: loading ? 'none' : '' }}
          wrapClassName={`select-domain-popup-container ${wrapClassName}`}
        >
          <div className="context-wrapper">
            <h3>You are registered as a { isRetailUser ? 'retail' : 'advisor' } user</h3>
            <p style={{ marginBottom: 5 }}>Redirecting you to {redirectTo}</p>
            <p>in <CountDownTimer renderOnlyText timeLimit={11} onTimesUp={() => this.onClickOk()} /> sec</p>
          </div>
          <div className="btn-wrap d-flex w-100 justify-content-center">
            <Button
              className="primary-btn"
              onClick={() => this.onClickOk()}
            >
              YES,
              <br />
              TAKE ME TO {hostname}
            </Button>
            <Button
              className="secondary-btn"
              onClick={() => this.onModalClose()}
            >
              <a id="mail-to-magnifi" href='mailto:hello@magnifi.com'>
                NO,
                <br />
                CONTACT SUPPORT
              </a>
            </Button>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = {
  selectDomainToRedirect,
}

export default connect(
  null,
  mapDispatchToProps
)(SelectDomainPopup);

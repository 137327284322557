import React from 'react'

class NotFound extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }  

  render() {
    return (
      <React.Fragment>
        <div className="not-found-wrapper">
          {/*<i className="far fa-hourglass-half"></i> */}
          <img src="/assets/images/pending-allocation.svg" />
          <span>Pending Portfolio Allocation</span>
        </div>
      </React.Fragment>
    )
  }
}

export default NotFound;

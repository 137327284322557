import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button ,Form, ButtonGroup } from 'reactstrap';
import { CloseButton, PrimaryButton, SecondaryButton } from 'shared-components';
import ConfigureWeights from './SelectorConfiguration/ConfigureWeights';
import ReferralContainer from 'pages/ReferralCodeContainer';
import TermsCondition from 'layouts/WebDashboard/SearchResults/Elements/Registrations/TermsNConditions';
import InviteFriends from 'layouts/WebDashboard/SearchResults/Elements/InviteFriends';
import PremiumPlanDetails from './premiumPlanDetails';
import { addEventToAnalytics } from 'Utils';

const WeightChangeModal = ({ scoreAttrs, scoreAttrsWeightHandler, premiumUser, disabled = false, selectedCalculator, openModal, renderCustomButton, showGearIcon = true, ...props }) => {
  const [isInviteFriendsModal, setInviteFriendsModalOpen] = useState(false);
  const [isWeightsModalOpen, setWeightsModalOpen] = useState(openModal);
  const [isFeatureModalOpen, setFeaturesModalOpen] = useState(false);
  const [notifyModal, setNotifyModalOpen] = useState(false);
  //const [isTermModalOpen, isTermModalOpen] = useState(false);
  useEffect(() => {
    if(openModal && selectedCalculator === "create custom selector"){
      setWeightsModalOpen(openModal);
    }
  }, [openModal, selectedCalculator]);

  const onFeaturesModalOkClick = (e) => {
    e.stopPropagation();
    const { funds } = props
    setWeightsModalOpen(true);
    if (funds) {
      addEventToAnalytics('Custom Weight Calculator','Custom Weight Calculator','CUSTOM_WEIGHT_CALCULATOR',{ query: funds.query, logId: funds.logId },true);
    }
  }

  const onModalCloseAction = (scoreAttrs) => {
    setWeightsModalOpen(false);
    const { onModalCloseAction } = props;
    if (onModalCloseAction) {
      onModalCloseAction(scoreAttrs);
    }
  }

  // const onClickHandle = (e) => {
  //   //e.stopPropagation();
  //   const { funds } = props
  //   setFeaturesModalOpen(true);
  // }

  // const referNow = () => {
  //   setFeaturesModalOpen(false);
  //   if (!premiumUser) setInviteFriendsModalOpen(true);
  // }

  // const inviteHandleCallBack = (response) => {
  //   if (response && !premiumUser) {
  //     setInviteFriendsModalOpen(false);
  //   }
  // }

  // if (props.inviteSuccess && isInviteFriendsModal) {
  //   if (props.setProfileState) {
  //     props.setProfileState({
  //       inviteSuccess: undefined,
  //     });
  //     setInviteFriendsModalOpen(false);
  //     setNotifyModalOpen(true);
  //   }
  // }

  let renderButton = renderCustomButton || '';
  if (window.location.pathname === '/investments') {
    renderButton = (
      <span style={{position:'absolute',cursor:'pointer'}}>
        {/* <button className="border-0 p-0 bg-sfmodal-grey float-right" onClick={premiumUser ? (() => onFeaturesModalOkClick()) : (() => setFeaturesModalOpen(true)), onClickHandle()}> */}
        {/* <button className="border-0 p-0 bg-sfmodal-grey float-right" onClick={() => <PremiumFeature}> */}

        {disabled ? (
          <button className="border-0 p-0 bg-sfmodal-grey float-right modal-trigger-btn icon-disabled">
            <i className="fal fa-cog"></i>
          </button>
        ): (
          <button className="border-0 p-0 bg-sfmodal-grey float-right modal-trigger-btn" style={{ cursor:'pointer' }} onClick={onFeaturesModalOkClick}>
            <i className="fal fa-cog" style={{color:'#1e5f91'}}></i>
          </button>
        )}
      </span>
    )
  }
  else {
    renderButton = (
      <span>
        {/* <button className="border-0 p-0 bg-sfmodal-grey float-right" onClick={premiumUser ? (() => onFeaturesModalOkClick()) : (() => setFeaturesModalOpen(true)), onClickHandle()}> */}
        {/* <button className="border-0 p-0 bg-sfmodal-grey float-right" onClick={() => <PremiumFeature}> */}
        <button className="border-0 p-0 bg-sfmodal-grey float-right modal-trigger-btn" onClick={onFeaturesModalOkClick}>
          <i className="fas fa-cog"></i>
        </button>
      </span>
    )
  }

  return (
    [renderButton,
    <Modal
      isOpen={isWeightsModalOpen}
      toggle={() => onModalCloseAction()}
      centered
      keyboard={true}
      className="premium-msg-modal configure-weight-modal"
      backdrop
      backdropClassName="backdropClassName"
      >
      <ModalBody className="ssf-modal__body premium-feature-process-modal-container">
        <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => onModalCloseAction()}></i>
        <ConfigureWeights
          scoreAttrs={scoreAttrs}
          scoreAttrsWeightHandler={values => {
            onModalCloseAction(values);
            scoreAttrsWeightHandler(values);
          }}
          modalToggle={setWeightsModalOpen}
        />
      </ModalBody>
    </Modal>,
    <Modal
      isOpen={isInviteFriendsModal}
      toggle={() => setInviteFriendsModalOpen(false)}
      centered
      keyboard={true}
      backdrop
      className="premium-feature-process-modal"
      backdropClassName="premium-feature-process-modal-backdrop"
      >
      <ModalBody className="ssf-modal__body">
        <div className="premium-feature-process-modal-container">
          <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => onFeaturesModalOkClick}></i>
          <InviteFriends />
        </div>
      </ModalBody>
    </Modal>
  ])
}

WeightChangeModal.defaultProps = {
  scoreAttrs: []
}


const mapStateToProps = ({ weights }) => ({
  selectedCalculator: weights.selectedCalculator,
})

export default connect(
  mapStateToProps,
)(WeightChangeModal);

import React from 'react';
import { connect } from 'react-redux';
import { toggleModal, setSFMState, setSFMCatData, updateSFMTrackYear, trackFundsCompareOrder } from '../actions/sfm';
import { isEqual, find, uniqBy, debounce } from 'lodash';
import { setGlobalState } from 'actions/global';
import { setProfileState, subscriptionPlan } from 'actions/profile';
// import { toggleModal, getSelectedFunds } from '../sagas/sfm';
import moment from 'moment';
import { UncontrolledTooltip } from 'reactstrap';
import { Tooltip as AntToolTip  } from 'antd';
import SpoofSfMain from 'layouts/WebDashboard/SearchResults/Elements/SpoofSfModal';
import Navbar from './Navbar';
import SfMain from './sf/Main';
import { Confirmation } from './sf/Confirm';
import { monthToDate, paraToSentence } from '../Utils';
import EmbedSection from './EmbedSection';
import UniverseSection from './UniverseSection';
import { addEventToAnalytics, changeLogo } from 'Utils';
import { getSaveSearchList,getSearchRecentList } from 'shared-components/SearchSuggestions/GetSuggestions';
import  SearchSuggestions  from 'shared-components/SearchSuggestions';

const classNames = require('classnames');

class TopSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEmbedOpen: false,
      isNotifyOpen: false,
      justTrackFundsState: props.sfmTrackFundsState || [],
      //relatedSearches: false,
    };
    this.secRef = React.createRef();
    this.top = true;
    this.state = { confirm: false };

    this.handleScroll = this.handleScroll.bind(this);
    this.handleHeight = this.handleHeight.bind(this);
    this.searchQuery = this.searchQuery.bind(this);
    this.onClickShare = this.onClickShare.bind(this);
  }

  componentDidMount() {
    if (this.props.subscriptionPlan && this.props.loggedIn) {
      this.props.subscriptionPlan();
    }
    //window.addEventListener('scroll', this.handleScroll);
    document.body.classList.add('main', 'ht0');
    //document.body.style.paddingTop = this.secRef.current.clientHeight+20+'px';
    if(this.props.loggedIn){
      changeLogo().then(res => this.props.setProfileState({logo: res}));
    }else{
      this.props.setProfileState({logo: '/assets/images/MagnifiLogo/Magnifi_byTIFIN-darkgrey.svg'});
    }
    // check old props present or not when new query fired, save until select button hit.
    if (this.props.sfmTrackFundsState && this.props.sfmTrackFundsState.length) {
      this.setState({
        justTrackFundsState: this.props.sfmTrackFundsState
      })
    }
  }

  componentDidUpdate() {
    const parent = document.getElementById("top-bar");
    const child = document.getElementById("overlay-wrapper");
    const overlay = document.getElementById("overlay-container");
    document.body.style.paddingTop = parent.offsetHeight ? (parent.offsetHeight <= 225 ? "" : parent.offsetHeight + "px") : "";
    if (overlay) {
      overlay.style.top = parent.offsetHeight ? (parent.offsetHeight <= 225 ? "" : (parent.offsetHeight - 125.41) + "px") : "";
    }

    const { items, modalToggleState, data, loading, setSFMState, setSFMCatData, range, sfmDateRange, sfmCatData, loggedIn, itemslist } = this.props;
    setSFMState({
      funds: data,
      modal: modalToggleState,
      loading,
      loggedIn,
      range,
      sfmDateRange,
    });
    if (typeof sfmCatData !== undefined) {
      setSFMCatData(sfmCatData);
    }
    if (items.length) {
      setSFMState({
        items,
      });
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.items.length) {
      setSFMState({
        items: newProps.items,
      });
    } else {
      const { itemslist } = this.props;
      setSFMState({
        items: itemslist,
      });
    }
  }

  componentWillUnmount() {
    this.handleMainSectionPadding()
  }

  // collapseRelatedSearches = () =>{
  //   this.setState({
  //     relatedSearches: !this.state.relatedSearches,
  //   });
  // }

  handleMainSectionPadding = () => {
    const parent = document.getElementById("top-bar");
    const child = document.getElementById("overlay-wrapper");
    const overlay = document.getElementById("overlay-container");
    document.body.style.paddingTop = parent.offsetHeight ? (parent.offsetHeight <= 225 ? "" : parent.offsetHeight + "px") : "";
    if (overlay) {
      overlay.style.top = parent.offsetHeight ? (parent.offsetHeight <= 225 ? "" : (parent.offsetHeight - 125.41) + "px") : "";
    }
  }

  handleScroll() {
    var value = window.pageYOffset || document.body.scrollTop;
    var top = value < 50;
    if (this.top !== top) {
      this.top = top;
      this.secRef.current.classList[top ? 'remove' : 'add']('sm');
      document.body.classList[top ? 'remove' : 'add']('ht0');
    }
  }

  handleHeight(visible, open) {
    document.body.classList[visible ? 'add' : 'remove']('ht1');
    document.body.classList[open ? 'add' : 'remove']('ht2');
  }

  confirmHandler = (confirm, payload) => {
    this.setState({ confirm, confirmPayload: payload });
  }

  searchQuery = (keyword,search_type=null) => {
    const { queryHandler } = this.props;
    queryHandler(keyword,search_type);
  }

  onClickShare = (e) => {
    switch (e.currentTarget.dataset.item) {
      case 'Embed':
        addEventToAnalytics('Share Blog','Share Blog','SHARE_BLOG',{},true);
        this.setState({ isEmbedOpen: true });
        break;

      case 'Mail':
        addEventToAnalytics('Share Email','Share Email','SHARE_EMAIL',{},true);
        this.shareMail();
        break;

      case 'Twitter':
        addEventToAnalytics('Share Twitter','Share Twitter','SHARE_TWITTER',{},true);
        this.shareTwitter();
        break;

      default:
        break;
    }
  }

  shareTwitter = () => {
    const { query } = this.props;
    const url = `${window.location.href}`;
    const text = `I searched for "${query}" on Magnifi.  Here's a link to the results.\n \n`;
    query && window.open('http://twitter.com/share?url=' + encodeURIComponent(url) + '&text=' + escape(text), '', 'left=0,top=0,width=750,height=550,personalbar=0,toolbar=0,scrollbars=0,resizable=0');
  }

  shareMail = () => {
    const { query } = this.props;
    const subject = `Shared from Magnifi`;
    const body = `I searched for "${query}" on Magnifi.  Here's what I found\n \n${window.location.href} \n \n \n \n \n \n`;
    query && window.open(`mailto:?subject=${encodeURIComponent(subject)}&body=${escape(body)}`, '_self');
  }

  isEmbedClose = () => {
    this.setState({ isEmbedOpen: false });
  }

  copylink = () => {
    let copyText = document.getElementById('embedText');
    copyText.select();
    document.execCommand("copy");
    this.setState({ isNotifyOpen: true });
    setTimeout(() => { this.setState({ isNotifyOpen: false }) }, 2000);
  }

  modalToggle = (modalToggle) => {
    this.props.modalToggle(modalToggle);
  }

  SpoofModalToggle = () => {
    localStorage.setItem('counter', 2);
    localStorage.setItem('plusCounter', 2);

    const { setSFMState, setGlobalState, autoOM, surveyModal } = this.props;
    if (!surveyModal) {
      setGlobalState({
        surveyModal: true,
      });
    }
    if (!autoOM) {
      setSFMState({
        autoOpenModal: true,
      });
    }
  }

  didMeanDesign = (didYouMean) => {
    const threeDidYouMean = didYouMean.slice(0, 3);
    const renderDesign = threeDidYouMean.map((item, index) => {
      const { funds } = this.props;
      return (
        <span
          key={item + index}
          style={{ color: '#55a9e8', cursor: 'pointer' }}
          title={item}
          onClick={() => {
              this.props.didYouMeanSearchQuery(item);
              addEventToAnalytics('Did you mean','Did you mean','DID_YOU_MEAN',{ query: funds.query, logId: funds.logId, didYouMeanQuery: item },true);
            }}
          >
            {(index ? ', ' : '') + item}
        </span>
      );
    });
    return renderDesign;
  }

  shareCompareState = (selectedCards, scoreAttrs, year) => {
    const { updateSFMTrackYear } = this.props;
    if (this.props.sfmTrackYear !== year) {
      updateSFMTrackYear({
        sfmTrackYear: year
      });
      this.updateSFMyear(selectedCards, scoreAttrs, year);
    }
  }

  updateSFMyear = (selectedCards, scoreAttrs, year) => {
    const { trackFundsCompareOrder, sfmTrackFundsState, query } = this.props;
    const dFun = JSON.parse(JSON.stringify(sfmTrackFundsState));
    const cards = JSON.parse(JSON.stringify(selectedCards));
    const filterSelectedCards = cards.filter((iii) => iii.query === query);
    const funds = this.getFormattedFundsForCompare(filterSelectedCards);
    if (year) {
      dFun.map((j, index) => {
        const f = j.funds.map((jItem) => {
          if (jItem._sfStats) {
            return this.calFundsStats(jItem._sfStats, jItem);
          } else {
            const getFundFromItemlistByTicker = this.props.itemslist.filter((r) => r.ticker === jItem.ticker)[0];
            if (typeof jItem.score === 'undefined' && getFundFromItemlistByTicker && getFundFromItemlistByTicker._sfStats && getFundFromItemlistByTicker._sfStats.score) {
              const sf = this.calFundsStats(getFundFromItemlistByTicker._sfStats, getFundFromItemlistByTicker);
              return sf; // calculate SFSTATS manually
            } else {
              return jItem;
            }
          }
        });
        dFun[index].funds = f;
      });
      const updatedList = dFun.filter((d) => d.sfmTrackYear !== "");
      if (updatedList.length > 1) {
        // check upcoming scoreAttrs is diff from previous scoreAttrs ?? if diff then add into trackFundsCompareOrder
        // if (justTrackFundsState[justTrackFundsState.length - 1].trackCompareOrder !== scoreAttrs) {
        if (scoreAttrs && !isEqual(updatedList[0].trackCompareOrder, scoreAttrs)) {
          const arr = updatedList;
          this.setState({
            justTrackFundsState: [ {
                funds: funds,
                trackCompareOrder: scoreAttrs,
                sfmTrackYear: year,
              }, ...arr],
          }, () => {
            const { trackFundsCompareOrder } = this.props;
            console.log('----------- since order is new, adding new arr with new sfmTrackYear ----------');
            trackFundsCompareOrder({
              trackFundsCompareOrder: this.state.justTrackFundsState,
            });
          });
        } else {
          console.log('----- COULDNT TRACK, RECENT ORDERING DETAILS ALREADY PRESENT. CHANGE ORDER FOR TRACKING -----');
          console.log('!! UPDATING FUNDS FOR RECENT TRACKED ENTRY !!');
          if (updatedList[0].sfmTrackYear !== year) {
            console.log('!!---- DIFFERENT DATE FOR SAME ORDER ----!!');
            updatedList[0].funds = funds;
            updatedList[0].sfmTrackYear = year;
            const { trackFundsCompareOrder } = this.props;
            trackFundsCompareOrder({
              trackFundsCompareOrder: updatedList,
            });
          } else {
            updatedList[0].funds = funds;
            const { trackFundsCompareOrder } = this.props;
            trackFundsCompareOrder({
              trackFundsCompareOrder: updatedList,
            });
          }
        }
      } else {
        this.setState({
          justTrackFundsState: [{
            funds: funds,
            trackCompareOrder: scoreAttrs,
            sfmTrackYear: year,
          }],
        }, () => {
          // console.log(this.state.justTrackFunds);
          const { trackFundsCompareOrder } = this.props;
          trackFundsCompareOrder({
            trackFundsCompareOrder: this.state.justTrackFundsState,
          });
        });
      }
    }
  }

  getFormattedFundsForCompare = (funds) => {
    const formattedFunds = funds.map(item => {
      const SFStats = item._sfStats;
      // console.log(SFStats);
      if (typeof SFStats !== 'undefined') {
        const scoreValue = parseFloat(SFStats.score !== null ? SFStats.score.toFixed(2) : 0);
        const scoreRoundFigure = Math.round(scoreValue);
        return {
          ticker: item.ticker,
          return: parseFloat(SFStats.yRet !== null ? SFStats.yRet.toFixed(2) : 0),
          risk: parseFloat(SFStats.risk !== null ? SFStats.risk.toFixed(2) : 0),
          fee: parseFloat(SFStats.fees !== null ? SFStats.fees.toFixed(2) : 0),
          "premium to nav": parseFloat(SFStats.weight_premium_to_nav !== null ? SFStats.weight_premium_to_nav : 0),
          "fund flows": parseFloat(SFStats.weight_fund_flows !== null ? SFStats.weight_fund_flows : 0),
          "assets": parseFloat(SFStats.aum !== null ? SFStats.aum : 0),
          "volume": parseFloat(SFStats.vol !== null ? SFStats.vol : 0),
          "tracking error": parseFloat(SFStats.trkerror !== null ? SFStats.trkerror : 0),
          "yield": parseFloat(SFStats.weight_yield !== null ? SFStats.weight_yield : 0),
          "bid-ask spread": parseFloat(SFStats.weight_bid_ask !== null ? SFStats.weight_bid_ask : 0),
          "track record": parseFloat(SFStats.weight_track_record !== null ? SFStats.weight_track_record : 0),
          "trend": parseFloat(SFStats.weight_trend !== null ? SFStats.weight_trend : 0),
          score: (scoreRoundFigure < 0) ? 0 : scoreRoundFigure,
        };
      } else {
        return {
          ticker: item.ticker,
          return: 0,
          risk: 0,
          fee: 0,
          "premium to nav" : 0,
          "fund flows" : 0,
          "assets" : 0,
          "volume" : 0,
          "tracking error" : 0,
          "yield" : 0,
          "bid-ask spread" : 0,
          "track record" : 0,
          "trend" : 0,
          score: 0,
        };
      }
    });
    return formattedFunds;
  }

  render() {
    const { relatedQueries, loading, correctedQuery, data, range, query, modalToggleState, items, itemslist, didYouMean, loggedIn, loggingIn } = this.props;
    const relatedArr = relatedQueries ? (relatedQueries.length ? relatedQueries : []) : [];

    const rangeFilter = e => {
      let dt = monthToDate(e.d);
      return dt.isAfter(moment(range.start).subtract(1, 'seconds')) && dt.isBefore(moment(range.end).add(1, 'seconds'));
    }

    let filteredArr = data.filter(item => !item._hidden);
    filteredArr.forEach(e => {
      if (e.returns.length > 0) {
        let points = e.returns.filter(rangeFilter);
        if (points.length <= 0) {
          e._hidden = true;
          return;
        }
      }
    });
    filteredArr = filteredArr.filter(item => !item._hidden);
    const searchResultCount = filteredArr.length;

    const renderBadge = relatedArr.map((item, index) => {
      const { funds } = this.props;
      return (
        <div
          className="search-badge"
          key={item + index}
          onClick={() => {
              this.searchQuery(item,'related_searches');
              addEventToAnalytics('Related Searches','Related Searches','RELATED_SEARCHES',{ query: funds.query, logId: funds.logId, relatedQuery: item },true);
            }}
          >
          {item}
        </div>
      )
    });

    const renderDidMean = didYouMean && didYouMean.length ? (
      <div className="didumean-wrapper">
        <div>Did you mean: {this.didMeanDesign(didYouMean)}</div>
      </div>
    ) : null;

    const renderShareIcons = () => (
      !loading ? window.screen.width < 760 ? <div className="share-icon-div">
      {/* <span clasName="check-label" style={{marginRight:'7px',fontSize:'10px'}}>Share</span> */}
      <AntToolTip
        title={'Share on your blog or website'}
        arrowPointAtCenter={false} 
        overlayClassName="magnifi-tooltip-wrapper"
        placement={'bottom'}>
      <span style={{marginRight:'7px'}}>
        <a className="share-component__item" key="1" id="Embed" data-item="Embed" onClick={this.onClickShare} style={{backgroundColor:'white'}}>
          <img src="/assets/images/world.svg"/>
        </a>
        {/* <UncontrolledTooltip placement="bottom" target="Embed" className="share-component__tooltip">
          Share on your blog or website
        </UncontrolledTooltip> */}
      </span>
      </AntToolTip>
      <AntToolTip
        title={'Share using mail'}
        arrowPointAtCenter={false} 
        overlayClassName="magnifi-tooltip-wrapper"
        placement={'bottom'}>
      <span style={{marginRight:'7px'}}>
        <a className="share-component__item" key="2" id="Mail" data-item="Mail" onClick={this.onClickShare} style={{backgroundColor:'white'}}>
          <img src="/assets/images/email.svg"/>
        </a>
        {/* <UncontrolledTooltip placement="bottom" target="Mail" className="share-component__tooltip">
          Share using mail
        </UncontrolledTooltip> */}
      </span>
      </AntToolTip>
      <AntToolTip
        title={'Share using twitter'}
        arrowPointAtCenter={false} 
        overlayClassName="magnifi-tooltip-wrapper"
        placement={'bottom'}>
      <span >
        <a className="share-component__item" key="3" id="Twitter" data-item="Twitter" onClick={this.onClickShare} style={{backgroundColor:'white'}}>
          <img src="/assets/images/twitterN.svg"/>
        </a>
        {/* <UncontrolledTooltip placement="bottom" target="Twitter" className="share-component__tooltip">
          Share using twitter
        </UncontrolledTooltip> */}
      </span>
      </AntToolTip>
    </div>
    :
    <div style={{display:'flex',position:'absolute',right:'15px'}}>
      <span clasName="check-label" style={{marginRight:'5px',fontSize:'12px',marginTop:'4px'}}>Share</span>
      <AntToolTip
        title={'Share on your blog or website'}
        arrowPointAtCenter={false} 
        overlayClassName="magnifi-tooltip-wrapper"
        placement={'bottom'}>
      <span style={{marginRight:'5px'}}>
        <a className="share-component__item" key="1" id="Embed" data-item="Embed" onClick={this.onClickShare} style={{backgroundColor:'white'}}>
          <img src="/assets/images/world.svg"/>
        </a>
        {/* <UncontrolledTooltip placement="bottom" target="Embed" className="share-component__tooltip">
          Share on your blog or website
        </UncontrolledTooltip> */}
      </span>
      </AntToolTip>
      <AntToolTip
        title={'Share using mail'}
        arrowPointAtCenter={false} 
        overlayClassName="magnifi-tooltip-wrapper"
        placement={'bottom'}>
      <span style={{marginRight:'5px'}}>
        <a className="share-component__item" key="2" id="Mail" data-item="Mail" onClick={this.onClickShare} style={{backgroundColor:'white'}}>
          <img src="/assets/images/email.svg"/>
        </a>
        {/* <UncontrolledTooltip placement="bottom" target="Mail" className="share-component__tooltip">
          Share using mail
        </UncontrolledTooltip> */}
      </span>
      </AntToolTip>
      <AntToolTip
        title={'Share using twitter'}
        arrowPointAtCenter={false} 
        overlayClassName="magnifi-tooltip-wrapper"
        placement={'bottom'}>
      <span >
        <a className="share-component__item" key="3" id="Twitter" data-item="Twitter" onClick={this.onClickShare} style={{backgroundColor:'white'}}>
          <img src="/assets/images/twitterN.svg"/>
        </a>
        {/* <UncontrolledTooltip placement="bottom" target="Twitter" className="share-component__tooltip">
          Share using twitter
        </UncontrolledTooltip> */}
      </span>
      </AntToolTip>
    </div>
    : null 
    )

    const renderMessageTextArray = this.props.messages ? (this.props.messages.length ? paraToSentence(this.props.messages[0]) : []) : [];
    return (
      <section id="top-bar" ref={this.secRef} className={classNames("fixed-top bg-ssf-gray-l3 sm topSectionWrapper", modalToggleState ? 'blur-effect' : '')}>
        <Navbar onLogout={this.props.logoutHandler} isLoggedIn={this.props.loggedIn || loggingIn} showSinginIcon={this.props.showSinginIcon} {...this.props}/>
        <h2 className="container search-header-label">What do you want to invest in?</h2>
        <SearchBox 
        queryHandler={this.props.queryHandler} 
        query={this.props.query} 
        loggedIn={this.props.loggedIn}
        surveyModelOpen={this.props.surveyModelOpen}
        />
        <div className="universe-container tab-universe-container">
          <UniverseSection
            history={this.props.history}
            queryHandler={this.props.queryHandler}
            query={this.props.query}
          />
          {renderShareIcons()}
        </div>
        {
        query && query !== '' &&
          <EmbedSection
            isEmbedOpen={this.state.isEmbedOpen}
            isEmbedClose={this.isEmbedClose}
            copylink={this.copylink}
            isNotifyOpen={this.state.isNotifyOpen}
            notifyClassName="notify-card"
            query={query}
          />
        }
        {!loading && data.length > 0
          && (
            <div className="container badgeWrapper" style={{ marginBottom: 10, marginBottom: 0 }}>
              {(relatedArr.length > 0)
                && (
                  <div className="badge-wrapper" style={{ marginBottom: 18 }}>
                    <div className="search-label" style={{ marginBottom: 2 }}>
                      Related searches 
                      {/* {window.screen.width < 431 && renderShareIcons()} */}
                    </div>
                    {/* <div className="search-label" style={{ marginBottom: 2, pointer:'cursor'}} onClick={this.collapseRelatedSearches}>Related searches</div> */}
                    {/* { (this.state.relatedSearches ===  false ||  this.state.relatedSearches === undefined ) ? '' :  */}
                    <div className="search-badge-node">
                      {renderBadge}
                    </div>
                    {/* } */}
                  </div>
                )
              }
              {(this.props.correctedQuery)
                && (
                  <div className="search-related-wrapper" style={{ marginBottom: 8, alignItems: this.props.messages.length < 1 ? '' : 'flex-end' }}>
                    {this.props.messages.length < 1 ?
                      <div className="search-label">
                        <div className="results-count-color">
                          <span>Here are the relevant {<span>{`${(searchResultCount > 1) ? 'results' : 'result'}`}</span>}for <span style={{ fontWeight: 'bold' }}>{this.props.correctedQuery}</span></span>
                        </div>
                      </div>
                      :
                      <div className="search-label d-flex-col-align-start">
                        {renderMessageTextArray.length &&
                          renderMessageTextArray.map((k, i) => {
                            return (
                              <div key={k + i} className="results-count-color d-flex-center" style={{ height: 30, marginTop: (i === (renderMessageTextArray.length - 1)) ? '-5px' : '0px' }}>
                                <span>{k}</span>
                              </div>
                            )
                          })
                        }
                      </div>
                    }

                    {/* <div className="share-component__wrapper">
                      <span>
                        <a className="share-component__item" key="1" id="Embed" data-item="Embed" onClick={this.onClickShare}>
                          <i className="far fa-globe"></i>
                        </a>
                        <UncontrolledTooltip placement="bottom" target="Embed" className="share-component__tooltip" trigger="hover">
                          Share on your blog or website
                        </UncontrolledTooltip>
                      </span>
                      <span>
                        <a className="share-component__item" key="2" id="Mail" data-item="Mail" onClick={this.onClickShare}>
                          <i className="far fa-envelope"></i>
                        </a>
                        <UncontrolledTooltip placement="bottom" target="Mail" className="share-component__tooltip" trigger="hover">
                          Share using mail
                        </UncontrolledTooltip>
                      </span>
                      <span>
                        <a className="share-component__item" key="3" id="Twitter" data-item="Twitter" onClick={this.onClickShare}>
                          <i className="fab fa-twitter"></i>
                        </a>
                        <UncontrolledTooltip placement="bottom" target="Twitter" className="share-component__tooltip" trigger="hover">
                          Share using twitter
                        </UncontrolledTooltip>
                      </span>
                    </div>
                    {
                      query && query !== ''
                      &&
                      <EmbedSection
                        isEmbedOpen={this.state.isEmbedOpen}
                        isEmbedClose={this.isEmbedClose}
                        copylink={this.copylink}
                        isNotifyOpen={this.state.isNotifyOpen}
                        notifyClassName="notify-card"
                        query={query}
                      />
                    } */}
                  </div>
                )
              }
              {renderDidMean}
            </div>
          )
        }
        {!loading && data.length > 0 && this.props.toolbar }
        {!loading && data.length > 0 && this.props.cardListToolbar }
        {(itemslist.length > 0 && loggedIn)
          && (
            <SfMain
              {...this.props}
              accountsPage
              autoOpenModal={this.props.autoOM}
              premiumUser={this.props.premiumUser}
              modalToggle={this.modalToggle}
              confirmHandler={this.confirmHandler}
              shareCompareState={this.shareCompareState}
              updateSFMyear={this.updateSFMyear}
            />
          )
        }
        {(itemslist.length > 0 && !loggedIn)
          && (
            <SpoofSfMain
              {...this.props}
              modalToggle={this.SpoofModalToggle}
              confirmHandler={this.confirmHandler}
            />
          )
        }
        {this.state.confirm && <Confirmation {...this.props} confirmHandler={this.confirmHandler} confirmPayload={this.state.confirmPayload} />}
        {!loading && this.props.moreStocks}
      </section>
    );
  }
}

const mapStateToProps = ({ sfm, auth, global, funds, profile }) => ({
  premiumUser: auth.user.premiumUser,
  sfmFunds: sfm.selectedFunds,
  funds,
  modal: sfm.modal,
  itemslist: sfm.items,
  autoOM: sfm.autoOpenModal,
  surveyModal: global.surveyModal,
  loggingIn: auth.loggingIn,
  logo: profile.logo,
  sfmTrackFundsState: sfm.trackFundsCompareOrder,
})

const mapDispatchToProps = {
  toggleModal,
  setSFMState,
  setSFMCatData,
  setGlobalState,
  setProfileState,
  subscriptionPlan,
  updateSFMTrackYear,
  trackFundsCompareOrder,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopSection);

class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '', active: false,  searchValue: '', enableSaveIcon: false, saveListDataResponse:[], saveRecentSearchResponse:[], search_type:'', starAddIcon:false, searchInputBlank:false };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const { loggedIn } = this.props;
    if (loggedIn) {
      this.fetchSaveSearchList();
      this.fetchRecentSuggestions();
    }
  }

  async componentWillReceiveProps(newProps) {
    if (newProps.query && newProps.loggedIn && !this.state.searchValue) {
      //this.fetchRecentSuggestions();
      //this.fetchSaveSearchList();
      (this.state.searchInputBlank && (newProps.query === this.props.query)) && this.setState({ value: newProps.query, active: this.validQuery(newProps.query) });
      (!this.state.searchInputBlank && (this.state.value === '' || newProps.query === this.state.value)) && this.setState({ value: newProps.query, active: this.validQuery(newProps.query) });
      (this.props.query !== newProps.query) && this.setState({ value: newProps.query, active: this.validQuery(newProps.query)});
      this.setState({enableSaveIcon: true})
      if(this.state.saveRecentSearchResponse && this.state.saveRecentSearchResponse.length){
        let _filterItem=this.state.saveRecentSearchResponse[0].suggestions.find(item=>item.query_string===newProps.query)
        if(_filterItem && typeof _filterItem === 'object'){
          this.setState({
            //enableSaveIcon: _filterItem.query_string === newProps.query,
            //enableSaveIcon: true,
            starAddIcon : this.state.saveListDataResponse.some(item=>item.query===newProps.query),
            search_type : _filterItem.search_type
          })
        }else{
          this.setState({
            //enableSaveIcon: true,
            starAddIcon : this.state.saveListDataResponse.some(item=>item.query===newProps.query),
            search_type : 'SearchBar'
          })
        }
      }
    }
    if(!newProps.loggedIn){
      (this.state.value === '' || newProps.query === this.state.value) && this.setState({ value: newProps.query, active: this.validQuery(newProps.query) });
      (this.props.query !== newProps.query) && this.setState({ value: newProps.query, active: this.validQuery(newProps.query)});
    }
  }

  fetchSaveSearchList = async () =>{
    const response = await getSaveSearchList();
    if(response && response.saveListResponse){
      this.setState({
        saveListDataResponse:response.saveListResponse,
        starAddIcon: response.saveListResponse.some(item=>item.query===this.state.value)
      })
    }
  }

  fetchRecentSuggestions = async () => {
    const response = await getSearchRecentList()
    if(response && response.length){
      this.setState({
        saveRecentSearchResponse:response
      })
    }
  }


  enableSaveIcon = () => {
    this.setState({
      enableSaveIcon:true
    })
  }

  handleChange(val) {
    let updateVal = typeof val==='object'? val.query_string : val;
    if(!updateVal){
      this.setState({searchInputBlank:true})  
    }
    this.setState({ value: updateVal, searchValue: updateVal, active: this.validQuery(updateVal) ,enableSaveIcon: false});
  }


  handleSubmit(val, extra, searchType) {
    const { loggedIn } = this.props;
    this.setState({searchValue: ''})
    let updateVal = typeof val==='object'? val.query_string : val;
    if (this.validQuery(updateVal))
      this.props.queryHandler(updateVal.trim(), searchType, extra);
      addEventToAnalytics('Search Bar', 'Search Bar', 'SEARCH_BAR', { query: updateVal.trim(), loggedIn });
  }

  validQuery = (query) => {
    if(typeof query !== "undefined"){
      if(typeof query === 'object'){
        return query.query_string.trim().length > 0
      }else{
        return query.trim().length > 0
      }
    }else{
      return false
    }
  }

  clearSearch = () => {
    this.setState({
      value: '',
    });
    document.getElementById("fund-search-box").focus();
  }

  render() {
    const {loggedIn,surveyModelOpen}=this.props;
    return (
      <section className="d-flex align-items-center dynamic e1 searchBoxWrapper">
        <div className="container phone_search">
        <SearchSuggestions
            loggedIn={loggedIn}
            searchText={this.state.value}
            search_type={this.state.search_type}
            setEnableSearchIcon={()=>this.enableSaveIcon()}
            setSearchText={(val)=>{this.handleChange(val)}}
            onPressEnter={( val, extra, searchType) => this.handleSubmit(val, extra, searchType)}
            fetchSaveSearchList={()=>this.fetchSaveSearchList()}
            isClearIconActive={this.state.active}
            saveListDataResponse={this.state.saveListDataResponse}
            enableSaveIcon={this.state.enableSaveIcon}
            starAddIcon={this.state.starAddIcon}
            surveyModelOpen={surveyModelOpen}
          />
          {/* <form onSubmit={this.handleSubmit}>
            <div className={classNames("input-group h2 mb-0 search-box")}>
              <input
                id="fund-search-box"
                value={this.state.value}
                onChange={this.handleChange}
                className="form-control form-control-lg rounded bg-white"
                type="text"
                autocomplete="off"
                placeholder=""
                aria-label="Search"
                style={{ paddingRight: (this.state.value && this.state.value.length > 0) ? '60px' : '40px' }}
              />
              {(this.state.value && this.state.value.length > 0) && (
                <i className={`far fa-times fa-lg text-secondary clear-icon ${this.state.clearSearch ? '' : ''} ${this.state.active ? 'active' : ''}`}
                  onClick={this.clearSearch} />
              )}
              <i className={classNames("far fa-fw fa-search fa-lg text-secondary search-icon", { 'active': this.state.active })}
                onClick={this.handleSubmit}></i>
            </div>
          </form> */}
        </div>
      </section>
    );
  }
}

import React from 'react';
import { connect } from 'react-redux';

import { Row } from 'reactstrap';

import { getInvestmentAccountStats, getInvestmentFundStats, getInvestmentPositionStats } from 'selectors/investment'
import { Wrapper, WrapperNP } from './Common'
import { Gains, Holdings, SecurityReturns, Allocations, ProfitLoss, FundActions } from './Positions'
import { PortfolioValue, Transactions } from './Summary'

class AccountContent extends React.PureComponent {
  render() {
    const { stats, fundStats, positionStats, acView } = this.props;
    const hdExpand = acView === 'holdings',
          txExpand = acView === 'transactions';

    return (
      <div className="mt-2">
        <Row className="compact">
          <WrapperNP className="col-lg-6"><PortfolioValue stats={stats} /></WrapperNP>
          <WrapperNP className="col-lg-6"><Gains stats={positionStats} /></WrapperNP>
        </Row>
        <Row className="compact">
          <div className="col-lg-6">
            <Row className="compact">
              <Wrapper className="col-lg-12">
                <Holdings title="Holdings" items={positionStats.funds} actions={FundActions} expand={hdExpand} />
              </Wrapper>
              { !hdExpand &&
                <React.Fragment>
                  <Wrapper className="col-6"><Allocations stats={positionStats} /></Wrapper>
                  <Wrapper className="col-6"><ProfitLoss stats={positionStats} /></Wrapper>
                </React.Fragment> }
            </Row>
          </div>
          <div className="col-lg-6">
            <Row className="compact">
              { !txExpand && <Wrapper className="col-lg-12"><SecurityReturns items={positionStats.funds} /></Wrapper> }
              <Wrapper className="col-lg-12">
                <Transactions title="Recent Transactions" items={fundStats.funds} expand={txExpand} />
              </Wrapper>
            </Row>
          </div>
        </Row>
      </div>
    )
  }
}

export default connect(
  state => ({
    funds: state.investment.funds,
    stats: getInvestmentAccountStats(state),
    fundStats: getInvestmentFundStats(state),
    positionStats: getInvestmentPositionStats(state),
    acView: state.investment.acView,
  }),
  {
  }
)(AccountContent);

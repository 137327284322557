import React from 'react';
import { Alert } from 'antd';

const TifinWealthMessage = () => {
  return (
    <Alert
      className={'tifin-wealth-banner-text-wrapper'}
      showIcon={false}
      message={
        <>
          Magnifi Advisor is now TIFIN Invest! Access your TIFIN Invest account by visiting our newly launched platform <a href="https://app.tifingrow.com/" target="_blank">app.tifingrow.com</a> today.
        </>
      }
      banner
      closable
    />
  );
}

export default TifinWealthMessage;

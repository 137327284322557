import React, { useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { withHeaderFooter } from '../shared/HeaderFooter';
import ActReportContext from '../ActReportContext';
import { groupByFunds, arrayChunk } from '../shared/Utils';
import NameSponsor from './Common';
import { ViewChart } from './ViewChart';
import _ from 'lodash';

const ExecutiveSummary = (props) => {
  const reportData = useContext(ActReportContext);
  const { selectedFunds, search: { query }, printSelection } = reportData;
  console.log({ selectedFunds });
  const sortedSelectedFunds = _.sortBy(selectedFunds, 'query');
  console.log({ sortedSelectedFunds });
  const groupBySelectedFunds = groupByFunds(sortedSelectedFunds, 'query');
  console.log({ groupBySelectedFunds });
  const cutOff = groupBySelectedFunds[8] && groupBySelectedFunds[8].isHeading ? 8 : 9;
  const items = groupBySelectedFunds.slice(0, cutOff);
  const otherItems = groupBySelectedFunds.slice(cutOff, groupBySelectedFunds.length);
  console.log({ otherItemsChunk });
  const otherItemsChunk = arrayChunk(otherItems, 11);
  const otherItemsLength = otherItemsChunk.length;

  return (
    <React.Fragment>
      {printSelection.includes('ex-sum') && <ExecutiveSummaryContent
        items={items}
        logo={props.logo}
        pageName={'ex-sum'}
        otherItemsLength={otherItemsLength}
      />}
      {printSelection.includes('ex-sum') && otherItemsChunk.length > 0 &&
        otherItemsChunk.map((item, index) => {
          return (
            <RelevantResults
              items={item}
              pageName={'ex-sum'}
              index={index}
              otherItemsLength={otherItemsLength}
            />
          )
        })
      }
    </React.Fragment>
  )
};

export default ExecutiveSummary;

const ExecutiveSummaryContent = withHeaderFooter((props) => {
  const reportData = useContext(ActReportContext)
  const { selectedFunds, search: { query } } = reportData;
  const { items, otherItemsLength } = props;
  return (
    <React.Fragment>
      <div className="content">
        <div className="pr-v2-header">
          <h3>Executive Summary</h3>
        </div>
        <div className="pr-v2-ex-summary">
          <div>
            <p>We retrieved and evaluated {query} for you. Among the available investment options, the most relevant selections using your diligence criteria are as follows:</p>
          </div>
          <RelevantResults
            items={items}
            showHeaderFooter={false}
          />
          <p className={'asteriskDisclaimer'}>* Tifin wealth proprietary score, as seen in the table above, is created by analyzing the prospectuses of the funds included. The score provides the thematic exposure of the fund relative to other securities</p>
          {!otherItemsLength && <BestFundContent />}
        </div>
      </div>
    </React.Fragment>
  )
});

const RelevantResults = withHeaderFooter((props) => {
  const { items, index, otherItemsLength } = props;
  return (
    <React.Fragment>
      <div className="content">
        {/*<h4 className="pr-v2-sub-header">Funds Selected from Relevant Results</h4>*/}
        <Col xs="12" className="rel-results grouped-results">
          {items.map((item, i) => (
            <>
              {!item.isHeading ?
                <Row key={i} className={`result-row pos-rel ${item.rowIndex % 2 == 0 ? 'odd' : 'even'}`}>
                  {(item.is_sponsored) && (
                    <div className="ad-logo" style={{ left: 5 }}>
                      SPONSORED
                    </div>
                  )}
                  <Col xs="3" className="fund-symbol text-center">
                    <p className="mb-0">{item.ticker_short}</p>
                    {/* <p className="mb-0">{FormatCurrency(item.nav, 2)}</p> */}
                  </Col>
                  <Col xs="5" className="fund-name">
                    <NameSponsor item={item} name={item.name} sponsor={item.sponsor} />
                  </Col>
                  <Col xs="4" className="fund-gauge text-center">
                    <ViewChart fund={item} i={i} queryVar={item.queryVar} data={item._viewData} chartType={item._viewChartType} size={'sm'} />
                  </Col>
                </Row>
                :
                <Row key={i} className="query-header">
                  <h4 className="pr-v2-sub-header">Funds Selected from Relevant Results for "{item.correctedQuery}"</h4>
                </Row>
              }
            </>
          ))}
        </Col>
        {(otherItemsLength && index === otherItemsLength - 1) && <BestFundContent />}
      </div>
    </React.Fragment>
  )
});

const BestFundContent = () => {
  const reportData = useContext(ActReportContext);
  let ticker = reportData.compare[0].funds[0].ticker;
  const { bestTicker } = reportData;
  return (
    <div className="ex-sum-best-fund">
      <p>{bestTicker} {bestTicker.includes('and') ? 'are' : 'is'} the best fit based upon thematic exposure, due diligence criteria chosen by the advisor, data provided by third party data providers, and the time frame chosen by the advisor.</p>
    </div>
  )
}

import ActionTypes from '../action-types'

export function setBuildPortfolioState(payload, callback) {
  if (callback && typeof callback === 'function') {
    return {
      type: ActionTypes.SET_BUILD_PORTFOLIIO_STATE,
      payload,
      callback,
    }
  } else {
    return {
      type: ActionTypes.SET_BUILD_PORTFOLIIO_STATE,
      payload,
    }
  }
}

export function getBatchResult(payload) {
  return {
    type: ActionTypes.GET_BUILD_PORTFOLIO_FUNDS,
    payload,
  }
}

/* --- EQUITY BATCH --- */
export function equityBatchRequest(payload) {
  return {
    type: ActionTypes.EQUITY_BATCH_REQUEST,
    payload,
  }
}

export function equityBatchFetch(payload) {
  return {
    type: ActionTypes.EQUITY_BATCH_FETCH,
    payload,
  }
}
export function equityBatchSuccess(payload) {
  return {
    type: ActionTypes.EQUITY_BATCH_SUCCESS,
    payload,
  }
}

/* --- BONDS BATCH --- */
export function bondsBatchRequest(payload) {
  return {
    type: ActionTypes.BONDS_BATCH_REQUEST,
    payload,
  }
}

export function bondsBatchFetch(payload) {
  return {
    type: ActionTypes.BONDS_BATCH_FETCH,
    payload,
  }
}
export function bondsBatchSuccess(payload) {
  return {
    type: ActionTypes.BONDS_BATCH_SUCCESS,
    payload,
  }
}

/* --- THEME BATCH --- */
export function thematicBatchRequest(payload) {
  return {
    type: ActionTypes.THEMATIC_BATCH_REQUEST,
    payload,
  }
}

export function thematicBatchFetch(payload) {
  return {
    type: ActionTypes.THEMATIC_BATCH_FETCH,
    payload,
  }
}

export function thematicBatchSuccess(payload) {
  return {
    type: ActionTypes.THEMATIC_BATCH_SUCCESS,
    payload,
  }
}

/* --- CUSTOM TICKERS --- */
export function customTickerRequest(payload) {
  return {
    type: ActionTypes.CUSTOM_TICKER_REQUEST,
    payload,
  }
}
export function customTickerFetch(payload) {
  return {
    type: ActionTypes.CUSTOM_TICKER_FETCH,
    payload,
  }
}
export function customTickerSuccess(payload) {
  return {
    type: ActionTypes.CUSTOM_TICKER_SUCCESS,
    payload,
  }
}

/* --- TICKER DATA --- */
export function tickerDataRequest(payload) {
  return {
    type: ActionTypes.TICKER_DATA_REQUEST,
    payload,
  }
}
export function tickerDataFetch(payload) {
  return {
    type: ActionTypes.TICKER_DATA_FETCH,
    payload,
  }
}
export function tickerDataSuccess(payload) {
  return {
    type: ActionTypes.TICKER_DATA_SUCCESS,
    payload,
  }
}

/* --- DELETE TICKER --- */
export function deleteCustomCard(payload) {
  return {
    type: ActionTypes.DELETE_TICKER_DATA,
    payload,
  }
}

/* --- THEMES LIST --- */
export function getThemesRequest(payload) {
  return {
    type: ActionTypes.THEMES_REQUEST,
    payload,
  }
}
export function getThemesFetch(payload) {
  return {
    type: ActionTypes.THEMES_FETCH,
    payload,
  }
}
export function getThemesSuccess(payload) {
  return {
    type: ActionTypes.THEMES_SUCCESS,
    payload,
  }
}

/* --- WEIGHTS BATCH --- */
export function setWeightsRequest(payload) {
  return {
    type: ActionTypes.SET_WEIGHTS_REQUEST,
    payload,
  }
}
export function setWeightsFetch(payload) {
  return {
    type: ActionTypes.SET_WEIGHTS_FETCH,
    payload,
  }
}
export function setWeightsSuccess(payload) {
  return {
    type: ActionTypes.SET_WEIGHTS_SUCCESS,
    payload,
  }
}

/* --- SUMMARY--- */
export function setSummaryRequest(payload) {
  return {
    type: ActionTypes.SET_SUMMARY_REQUEST,
    payload,
  }
}
export function setSummaryFetch(payload) {
  return {
    type: ActionTypes.SET_SUMMARY_FETCH,
    payload,
  }
}
export function setSummarySuccess(payload) {
  return {
    type: ActionTypes.SET_SUMMARY_SUCCESS,
    payload,
  }
}

/* --- SAVE PORTFOLIO --- */
export function finalizeSavePortfolioRequest(payload) {
  return {
    type: ActionTypes.FINALIZE_SAVE_PORTFOLIO_REQUEST,
    payload,
  }
}
export function finalizeSavePortfolioFetch(payload) {
  return {
    type: ActionTypes.FINALIZE_SAVE_PORTFOLIO_FETCH,
    payload,
  }
}
export function finalizeSavePortfolioSuccess(payload) {
  return {
    type: ActionTypes.FINALIZE_SAVE_PORTFOLIO_SUCCESS,
    payload,
  }
}

/* --- SEARCH NEW QUERY--- */
export function searchForNewQueryRequest(payload) {
  return {
    type: ActionTypes.SEARCH_NEW_QUERY_REQUEST,
    payload,
  }
}
export function searchForNewQueryFetch(payload) {
  return {
    type: ActionTypes.SEARCH_NEW_QUERY_FETCH,
    payload,
  }
}
export function searchForNewQuerySuccess(payload) {
  return {
    type: ActionTypes.SEARCH_NEW_QUERY_SUCCESS,
    payload,
  }
}

/* --- FETCH PORTFOLIO --- */
export function fetchPortfolioRequest(payload) {
  return {
    type: ActionTypes.PORTFOLIO_DATA_REQUEST,
    payload,
  }
}
export function fetchPortfolioFetch(payload) {
  return {
    type: ActionTypes.PORTFOLIO_DATA_FETCH,
    payload,
  }
}
export function fetchPortfolioSuccess(payload) {
  return {
    type: ActionTypes.PORTFOLIO_DATA_SUCCESS,
    payload,
  }
}

/* --- FETCH PRE-CACHED SELECTED TICKERS --- */
export function preSelectedThematicTickersRequest(payload) {
  return {
    type: ActionTypes.PRE_SELECTED_THEMATIC_TICKERS_REQUEST,
    payload,
  }
}
export function preSelectedThematicTickersFetch(payload) {
  return {
    type: ActionTypes.PRE_SELECTED_THEMATIC_TICKERS_FETCH,
    payload,
  }
}
export function preSelectedThematicTickersSuccess(payload) {
  return {
    type: ActionTypes.PRE_SELECTED_THEMATIC_TICKERS_SUCCESS,
    payload,
  }
}

/* --- RESET BUILDPORTFOLIO STATE --- */
export function resetBuildPortfolioState() { // dont need to pass payload... discuss with ankit if change required
  return {
    type: ActionTypes.RESET_BUILD_PORTFOLIO_STATE,
  }
}

/* --- FETCH PRE-CACHED SELECTED TICKERS --- */
export function editPortfolioRequest(payload) {
  return {
    type: ActionTypes.EDIT_PORTFOLIO_REQUEST,
    payload,
  }
}
export function editPortfolioSuccess(payload) {
  return {
    type: ActionTypes.EDIT_PORTFOLIO_SUCCESS,
    payload,
  }
}

/* --- UPLOAD BUILDER PORTFOLIO --- */
export function uploadBuilderPortfolioRequest(payload, callback){
  return {
    type: ActionTypes.UPLOAD_BUILDER_PORTFOLIO_REQUEST,
    payload,
    callback,
  }
}

// ------ csv preflight request, get all tickers validate -------
export function checkBuilderTickerRequest(payload, callback){
  return {
    type: ActionTypes.CHECK_BUILDER_TICKER_EXIST_REQUEST,
    payload,
    callback,
  }
}
export function checkBuilderTickerFetch(payload){
  return {
    type: ActionTypes.CHECK_BUILDER_TICKER_EXIST_FETCH,
    payload,
  }
}

/* --- SET ANALYTICS CHART DATA --- */
export function setAnalyticsChartData(payload) { // dont need to pass payload... discuss with ankit if change required
  return {
    type: ActionTypes.SET_CHART_DATA,
    payload,
  }
}

export function checkBuilderTickerSuccess(payload){
  return {
    type: ActionTypes.CHECK_BUILDER_TICKER_EXIST_SUCCESS,
    payload,
  }
}

export function downloadBuilderReportPdf(payload) {
  return {
    type: ActionTypes.DOWNLOAD_BUILDER_REPORT,
    payload
  }
}

/* ---- LOAD DEMO CSV FILE ---- */

export function loadDemoCSVRequest(payload) {
  return {
    type: ActionTypes.LOAD_DEMO_CSV_REQUEST,
    payload,
  }
}

export function loadDemoCSVFetch(payload) {
  return {
    type: ActionTypes.LOAD_DEMO_CSV_FETCH,
    payload,
  }
}

export function loadDemoCSVSuccess(payload) {
  return {
    type: ActionTypes.LOAD_DEMO_CSV_SUCCESS,
    payload,
  }
}

/* DELETE PORTFOLIO BY ID */

export function deletePortfolioBuilderByIdRequest(payload, callback) {
  return {
    type: ActionTypes.DELETE_PORTFOLIO_BUILDER_BY_ID_REQUEST,
    payload,
    callback,
  }
}

export function deletePortfolioBuilderByIdFetch(payload) {
  return {
    type: ActionTypes.DELETE_PORTFOLIO_BUILDER_BY_ID_FETCH,
    payload,
  }
}

export function deletePortfolioBuilderByIdSuccess(payload) {
  return {
    type: ActionTypes.DELETE_PORTFOLIO_BUILDER_BY_ID_SUCCESS,
    payload,
  }
}

export function customWeightInput(payload){
  return {
    type :ActionTypes.CUSTOM_WEIGHT_INPUT,
    payload
  }
}
export function removeZeroWeightFunds(){
  return {
    type :ActionTypes.REMOVE_ZERO_WEIGHT_FUNDS,
  }
}


import React from 'react';
// import moment from 'moment';
// import { reverse } from 'lodash';
import {
  Card,
  CardBody,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { Typography } from 'antd';

// import {
//   fetchSponsor,
//   fetchTopTenHoldings,
//   fetchFundCommissions,
// } from 'apis/funds';

// import Relevance from 'components/FundRevelance';

// import { getLineHeight } from 'layouts/WebDashboard/SearchResults/ItemCard/utils';
// import { RegionColors, SectorColors } from 'data/Colors';
import {
  cn,
  // CN,
  sponsorLogo,
  // SectorsAttrs,
  // DateRange
} from 'DataSet';
import {
  // getNest,
  // uniqValues,
  // arrayToHash,
  // getNestDefault,
  getImageOrFallback,
  addEventToAnalytics
} from 'Utils';
// import { calcStats } from 'Stats';

import { RegionsData, FactsData } from './helper';
// import getFilteredListByRange from '../utils';

const { Paragraph } = Typography;

const EventNameArray = {
  'Sectors': 'FUND_DETAIL_SELECTED_SECTORS_CHART',
  'Regions': 'FUND_DETAIL_SELECTED_REGIONS_CHART',
  'Key Facts': 'FUND_DETAIL_SELECTED_KEY_FACTS_CHART',
  'Top 10 Holdings': 'FUND_DETAIL_SELECTED_TOP_10_HOLDINGS_CHART',
}

class FundInfo extends React.Component {
  viewOpts = [
    { name: 'Key Facts', code: 'facts' },
    { name: 'Top 10 Holdings', code: 'holdings' },
    { name: 'Sectors', code: 'sectors' },
    { name: 'Regions', code: 'regions' }
  ]

  state = {
    dropdownOpen1: false,
    optSel: this.viewOpts[0],
    imageValidPath: false
  }

  componentDidMount() {
    const { card, discovery } = this.props;
    if (card && cn(card, 'Sponsor')) {
      getImageOrFallback(sponsorLogo(cn(card, 'Sponsor'))).then(result => {
        if (result) {
          this.setState({ imageValidPath: true })
        } else {
          this.setState({ imageValidPath: false })
        }
      })
      if (discovery && this.state.optSel && this.state.optSel.name) {
        const getEventName = EventNameArray[this.state.optSel.name];
        // console.log(`Fund Detail Selected -- Chart -${this.state.optSel.name}, Fund Detail Selected -- Chart-${this.state.optSel.name}, FUND_DETAIL_SELECTED -- CHART-${this.state.optSel.name}`)
        console.log(`Fund Detail Selected ${this.state.optSel.name} Chart`, `Fund Detail Selected ${this.state.optSel.name} Chart`, getEventName);
        console.log('query --> ', discovery.query);
        addEventToAnalytics(`Fund Detail Selected ${this.state.optSel.name} Chart`, `Fund Detail Selected ${this.state.optSel.name} Chart`, getEventName,
          { query: discovery.query, logId: discovery.logId, ticker: card.ticker, chart: this.state.optSel.name, category: 'advisory_PTC' }, true);
      }
    }
  }

  toggle = name => {
    this.setState({
      [name]: !this.state[name]
    })
  }

  optSelHandler = optSel => {
    const { discovery, card } = this.props;
    if (discovery && optSel && optSel.name) {
      // `Fund Detail Selected -- Chart -${optSel.name}, Fund Detail Selected -- Chart-${optSel.name}, FUND_DETAIL_SELECTED -- CHART-${optSel.name}`
      const getEventName = EventNameArray[optSel.name];
      console.log(`Fund Detail Selected ${optSel.name} Chart`, `Fund Detail Selected ${optSel.name} Chart`, getEventName)
      console.log('query --> ', discovery.query);
      addEventToAnalytics(`Fund Detail Selected ${optSel.name} Chart`, `Fund Detail Selected ${optSel.name} Chart`, getEventName,
        { query: discovery.query, logId: discovery.logId, ticker: card.ticker, chart: optSel.name, category: 'advisory_PTC' }, true);
    }
    this.setState({ optSel })
  }

  getTextFromHtmlString = (htmlstring) => {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = htmlstring;
    return divContainer.textContent || divContainer.innerText || "";
  }

  render() {
    const { card } = this.props;
    const { dropdownOpen1 } = this.state;

    if (!card || !card._returnsChart)
      return null

    if (card.type !== 'SHARE' && card.type !== 'BASKET' && (!card._categorySeries || !card._categorySeries.series))
      return null

    const cardSeries =
      typeof card._returnsChart !== 'undefined'
        ? typeof card._returnsChart.series !== 'undefined'
          ? card._returnsChart.series
          : card._returnsChart
        : card.series

    let optSel = this.state.optSel;

    if (card._holdingData && card._sectorData && card._regionData) {
      this.viewOpts.find(e => e.code === 'holdings').hidden = card._holdingData.length <= 0
      this.viewOpts.find(e => e.code === 'sectors').hidden = card._sectorData.length <= 0
      this.viewOpts.find(e => e.code === 'regions').hidden = card._regionData.length <= 0
    }

    if (optSel && optSel.code === 'regions' && ((card._regionData && card._regionData.length === 0) || !card._regionData)) {
      optSel = this.viewOpts[0];
    } else if (optSel && optSel.code === 'sectors' && ((card._sectorData && card._sectorData.length === 0) || !card._sectorData)) {
      optSel = this.viewOpts[0];
    } else if (optSel && optSel.code === 'holdings' && ((card._holdingData && card._holdingData.length === 0) || !card._holdingData)) {
      optSel = this.viewOpts[0];
    }

    const viewOpts = this.viewOpts.filter(e => !e.hidden)
    const shortDesc = cn(card, 'short_desc');

    return (
      <Card className="mt-2 media-card shadow-sm border-secondary d-flex" style={{ borderWidth: '2px' }}>
        <CardBody className="p-1 d-flex flex-column info-tile" style={{ minHeight: 429 }}>
          <div className="bg-ssf-gray-l5 p-1 position-relative media-bb-1" style={{ marginBottom: 3, minHeight: 97 }}>
            <div className="d-flex ssf-spacer media-flex-column funds-info-title" style={{ margin: '0 0.1rem' }}>
              {this.state.imageValidPath && cn(card, 'Sponsor') ? (
                <img
                  src={sponsorLogo(cn(card, 'Sponsor'))}
                  className="img-fluid h5 mb-0 mr-2"
                  alt={cn(card, 'Sponsor')}
                  style={{ height: '20px' }}
                />
              ) : (
                <div className="sponsor-logo-placeholder mr-2" title={cn(card, 'Sponsor') || ''}>
                  {cn(card, 'Sponsor') || ''}
                </div>
              )}
              <h4 className="color-sfmodal-header mb-0 w-100" style={{ lineHeight: 'normal', fontWeight: '600' }}>
                {cn(card, 'Name2')}
              </h4>
            </div>
            <div className="ssf-spacer media-none" />
            <h6 className="" title={this.getTextFromHtmlString(cn(card, 'short_desc'))} style={{ lineHeight: 1.3, margin: '0 0.1rem' }}>
              <Paragraph
                className="fs-13 font-one mt-0 mb-0 ml-0 mr-0 text-muted"
                ellipsis={{ rows: 3 }}
                title={this.getTextFromHtmlString(shortDesc)}
              >
                {this.getTextFromHtmlString(shortDesc)}
              </Paragraph>
            </h6>
            <div className="ssf-spacer media-none" />
          </div>
          <div className="bg-ssf-gray-l5 p-0 media-pl-2 media-pr-2 d-flex flex-column" style={{ flex: '1 0 auto', justifyContent: 'space-evenly' }}>
            <h5 className="mb-0 text-center ssf-spacer media-left media-bb-1 text-blue-6i">
              View: &nbsp;
              <ButtonDropdown
                isOpen={dropdownOpen1}
                toggle={() => this.toggle('dropdownOpen1')}
                style={{ verticalAlign: 'inherit' }}
              >
                <DropdownToggle
                  caret
                  color="link"
                  className="h5 text-white-6i my-1 sfmodal-key"
                  style={{ backgroundColor: '#2173ad' }}
                >
                  {optSel.name}
                </DropdownToggle>
                <DropdownMenu
                  className="rounded-0 h6 py-1"
                  style={{ minWidth: '5rem' }}
                >
                  {viewOpts.map((e, i) => (
                    <DropdownItem
                      key={i}
                      className="py-1"
                      onClick={() => this.optSelHandler(e)}
                    >
                      {e.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
            </h5>
            <div className={`ssf-spacer`} style={{ minHeight: 230 }}>
              {optSel.code === 'facts' && (
                <FactsData
                  {...this.props}
                  chartData={cardSeries}
                  fid={cn(card, 'Ticker')}
                  ydomain={card._returnRange}
                />
              )}
              {(optSel.code === 'regions' && card._regionData) && (
                <RegionsData {...this.props} data={card._regionData} />
              )}
              {(optSel.code === 'sectors' && card._sectorData) && (
                <RegionsData {...this.props} data={card._sectorData} />
              )}
              {(optSel.code === 'holdings' && card._holdingData) && (
                <RegionsData {...this.props} data={card._holdingData} />
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    )
  }
}

export default FundInfo;

import React, { Component, useContext, useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import ActReportContext from '../ActReportContext';
import { cn, sponsorLogo } from 'DataSet';
import { getImageOrFallback } from '../../../Utils'
import { BarChartReport } from '../shared/BarChartReport';
import { PercentChartWrap } from '../shared/Charts'
import { TitleWrap2 } from '../shared/Wrappers'
import { FormatCurrency, ScoreAttrs, RfScoreAttrs } from '../shared/Utils';

class NameSponsor extends Component {
  state = {
    imageValidPath: false,
  }

  componentDidMount() {
    const { item } = this.props;
    if (item && cn(item, 'Sponsor')) {
      getImageOrFallback(
        sponsorLogo(cn(item, 'Sponsor'))
      ).then(result => {
        if (result) {
          this.setState({ imageValidPath: true });
        } else {
          this.setState({ imageValidPath: false });
        }
      });
    }
  }

  render() {
    const { name, sponsor } = this.props;
    const { imageValidPath } = this.state;
    return (
      <div className="w-100">
        <div className="pb-1">
          {
            imageValidPath ?
              <img src={sponsorLogo(sponsor)} className="img-fluid" alt={sponsor} style={{ height: '20px' }} /> :
              <div className="sponsor-logo-placeholder" title={sponsor || ''}>{sponsor || ''}</div>
          }
        </div>
        <p className="mb-0" title={name}>{name}</p>
      </div>
    )
  }
}

export default NameSponsor;

export const NameSponsorTicker = ({ name, sponsor, ticker }) => {
  const [url, setUrl] = useState('')

  useEffect(() => {
    getImageOrFallback(sponsorLogo(sponsor))
      .then(result => setUrl(result));
  }, [sponsor])

  return (
    <div className="w-100">
      <div className="pb-1 d-flex">
        {url
          ? <img src={url} className="img-fluid" alt={sponsor} style={{ height: '20px' }} />
          : sponsor
        }
        <h5 className="font-weight-bold wealth-black mb-0 ml-auto lh-125">{ticker}</h5>
      </div>
      <h5 className="font-weight-bold wealth-black mb-0 lh-125" title={name}>{name}</h5>
    </div>
  )
}

export const FundsList = ({ items, stats, size, off = 0, ...props }) => (
  <div className="list-group list-group-flush">
    {items.map((e, i) => {
      const chartData = props.chartData ? props.chartData[0] : null;
      const details = chartData ? chartData.filter((item) => item.name === e.ticker)[0] : null;
      const fundColor = details ? (details.color ? details.color : null) : null;
      return <FundsItem color={fundColor} key={i} item={e} size={size} stats={stats[i]} idx={off + i} />
    })}
  </div>
)

const FundsItem = ({ item, stats, idx, ...props }) => {
  const style = { left: 0, top: 0, minWidth: '20px', textAlign: 'center' }
  const klasses = props.size === 'md' ? ' card-lg' : ' card-t2 sm';
  const fundColor = idx === 0 ? '#00a700' : '#f5a20a';
  const color = props.color || fundColor;

  return (
    <div className={"list-group-item center-vh" + klasses}>
      {props.size === 'md' &&
        <div className="position-absolute small text-muted bg-light" style={style}>{idx + 1}</div>}
      <Row className="compact w-100">
        <Col xs="3" className="d-flex flex-column justify-content-center align-items-center">
          <h3 className="mb-1 fs-m1 fw-b tx-c1">{item.ticker}</h3>
          <h4 className="mb-0 fs-m1 fw-b tx-c2">{FormatCurrency(item.nav, 2)}</h4>
        </Col>
        <Col xs="6" className="d-flex align-items-center">
          <NameSponsor item={item} name={item.name} sponsor={item.sponsor} />
        </Col>
        <Col xs="3">
          <PercentChartWrap width={130} value={stats.score} size={props.size} color={color} />
        </Col>
      </Row>
    </div>
  )
}

export const SelectedFunds = ({ title, allFunds, funds, off = 0, ...props }) => {
  return (
    <TitleWrap2 title={title}>
      <FundsList {...props} items={funds.map(f => allFunds.find(s => s.ticker === f.ticker))} stats={funds} size="md" off={off} />
    </TitleWrap2>
  )
}

export const SelectedFunds2Col = props => {
  const half = Math.ceil(props.funds.length / 2);
  const funds1 = props.funds.slice(0, half),
    funds2 = props.funds.slice(half);

  return (
    <Row>
      <Col className="col-md-6">
        <SelectedFunds {...props} funds={funds1} />
      </Col>
      <Col className="col-md-6">
        <SelectedFunds {...props} funds={funds2} off={half} />
      </Col>
    </Row>
  )
}

export const AttrCharts = ({ attrs, chartData, relFunds, dateRange = '' }) => {
  let compareAttrs = relFunds ? RfScoreAttrs : ScoreAttrs
  return (
    <React.Fragment>
      {attrs.map((a, i) => {
        if (!compareAttrs[a]) return null;
        chartData[i].map((j) => {
          if (j.value === null) {
            j.value = 0.0;
          }
        })
        return (
          <div key={i} className="result-row">
            <div className="inline-result">
              <div className="chart-title">
                <h6 className="attrBar-title"><ScoreAttr attr={a} sa={compareAttrs} /></h6>
                {dateRange && (compareAttrs[a].name === 'Return' || compareAttrs[a].name === 'Risk') && (
                  <span className={'date-range'}>{dateRange}</span>
                )}
              </div>
              <div className="chart-wrap">
                <BarChartReport data={chartData[i]} />
              </div>
            </div>
          </div>
        )
      })}
    </React.Fragment>
  )
}

export const AttrChartsRelFund = ({ attrs, chartData, dateRange, attrsWithScore }) => {
  let compareAttrs = RfScoreAttrs;
  return (
    <React.Fragment>
      {attrs.map((a, i) => {
        chartData[i].map((j) => {
          if (j.value === null) {
            j.value = 0.0;
          }
        })
        return (
          <div key={i} className="result-row">
            <div className="inline-result">
              <div className="chart-title">
                <h6 className="attrBar-title">
                  <ScoreAttrRelFund
                    attr={a}
                    sa={compareAttrs}
                    attrsWithScore={attrsWithScore}
                  />
                </h6>
              </div>
              <div className="chart-wrap">
                <BarChartReport data={chartData[i]} />
              </div>
            </div>
          </div>
        )
      })}
    </React.Fragment>
  )
}

export const ScoreAttrRelFund = ({ attr, attrsWithScore }) => {
  const reportData = useContext(ActReportContext);
  const { allAttrs, dateRange, createdDate } = reportData;
  // console.log('ScoreAttrRelFund reportData --> ', reportData)
  const attrDetails = allAttrs.find(item => item.name.toLowerCase() === attr.toLowerCase());
  const attrWeight = attrsWithScore.find(item => item.name.toLowerCase() == attr.toLowerCase());
  const iconPath = '/assets/images/selection-criteria/';

  let datePeriod = null, KEY = null;
  // // NOTE: Showing date-range only for 'Risk' and 'Return' attributes
  // if (dateRange && (attrDetails.code === 'risk' || attrDetails.code === 'return')) {
  //   // datePeriod = `From ${moment(dateRange.start).format('MM/DD/YYYY')} to ${moment(createdDate).format('MM/DD/YYYY')}`;
  //   datePeriod = `${dateRange} - ${moment(createdDate).format('MMM YYYY')}`;
  // }

  // NOTE: Showing date-range only for 'Risk' and 'Return' attributes
  if (attrDetails.code === 'risk') {
    KEY = 'risk'
  } else if (attrDetails.code === 'return') {
    KEY = 'return'
  }

  if (dateRange && dateRange[KEY]) {
    datePeriod = `From ${moment(dateRange[KEY].start).format('MM/DD/YYYY')} to ${moment(createdDate).format('MM/DD/YYYY')}`;
  }

  // console.log('datePeriod --> ', datePeriod)

  return (
    <span>
      {(attrDetails && attrDetails.icon) ? (
        <img src={iconPath + attrDetails.icon} />
      ) : (
        <img src={iconPath + 'DefaultIcon.svg'} />
      )}
      <span className="attr-name">{attrDetails.name === 'Risk' ? attrDetails.name+'*' : attrDetails.name}</span>
      {attrDetails.name === 'Fiduciary Risk' && <span className="attr-weight"> Powered by Fi360</span>}
      {(attrDetails.name.toLowerCase().includes('capture')
        || attrDetails.name.toLowerCase().includes('alpha')
        || attrDetails.name.toLowerCase().includes('outperformance')) &&
        <span style={{ opacity: `0.6` }} className="attr-weight">
          vs Benchmark*
        </span>
      }
      {attrWeight && <span className="attr-weight">Weight: {attrWeight.weight}%</span>}
      {datePeriod && <span className="attr-weight">{datePeriod}</span>}
    </span>
  )
}

export const ScoreAttr = ({ attr, sa }) => (
  <span>{sa[attr].icon && <i className={"mr-1 far fa-fw fa-" + sa[attr].icon}></i>}{sa[attr].name}</span>
)

export const BenchmarkDisclaimer = ({ style }) => {
  const reportData = useContext(ActReportContext);
  const { benchmark } = reportData;
  let text = benchmark === 'MPT' ?
    'MPT Benchmark is the primary benchmark assigned to investments based on their Broad Morningstar category. This category-level benchmark is used to calculate Modern Portfolio Theory statistics' :
    'Category Benchmark is assigned to investments based on the Morningstar category. The category benchmark is a proxy ETF for each category defined by Morningstar against which relative statistics are calculated for the investment.'
  return benchmark === 'MPT' ? <></> : <p style={style} className={'asteriskDisclaimer'}>{`vs Benchmark * – ${text}`}</p>
}

import ActionTypes from '../action-types'

export function getTredingSearches() {
  return {
    type: ActionTypes.GET_TRENDING_SEARCHES
  }
}

export function getTredingSearchesLoading(payload) {
  return {
    type: ActionTypes.GET_TRENDING_SEARCHES_LOADING,
    payload,
  }
}

export function getTredingSearchesSuccess(payload) {
  return {
    type: ActionTypes.GET_TRENDING_SEARCHES_SUCCESS,
    payload,
  }
}

export function getViolatorSearches() {
  return {
    type: ActionTypes.GET_VIOLATOR_SEARCHES
  }
}

export function getViolatorSearchesLoading(payload) {
  return {
    type: ActionTypes.GET_VIOLATOR_SEARCHES_LOADING,
    payload,
  }
}

export function getViolatorSearchesSuccess(payload) {
  return {
    type: ActionTypes.GET_VIOLATOR_SEARCHES_SUCCESS,
    payload,
  }
}

export function getViolatorSearchesError(payload) {
  return {
    type: ActionTypes.GET_VIOLATOR_SEARCHES_ERROR,
    payload,
  }
}


import React, { useContext } from 'react'
import { Row, Col, Table } from 'reactstrap'
import { withHeaderFooter } from './HeaderFooter'
import { StackedAreaChart } from '../../PortfolioReportV2/shared/Charts';
import PortfolioContext from '../PortfolioContext'
import { FormatCurrency} from '../../PortfolioReportV2/shared/Utils'
import { numberCheck } from 'layouts/utils'
import { getExecutiveSummaryContent } from '../Utils';

const ExecutiveSummary = withHeaderFooter((props) => {
  const { createdDate } = props;
  const reportData = useContext(PortfolioContext)
  const advisory_fees = reportData.advisory_fees && !isNaN(reportData.advisory_fees) ?  parseFloat(reportData.advisory_fees) : 0;
  console.log('advisory_fees --> ', advisory_fees)

  let {investment, portfolio, portfolio: {currentPerformanceImpact: cpi}} = reportData;

  let chartData = []

  let returnsCalcBasedOn = '3 Year', volatilityCalcBasedOn = '3 Year';
  if (numberCheck(cpi.anRet10yr)) {
    returnsCalcBasedOn = '10 Year';
  } else if (numberCheck(cpi.anRet5yr)) {
    returnsCalcBasedOn = '5 Year';
  } else {
    returnsCalcBasedOn = '3 Year';
  }

  if (numberCheck(cpi.anVol10yr)) {
    volatilityCalcBasedOn = '10 Year';
  } else if (numberCheck(cpi.anVol5yr)) {
    volatilityCalcBasedOn = '5 Year';
  } else {
    volatilityCalcBasedOn = '3 Year';
  }

  // fallback condition for 10 years returns/volatility
  let _CPIanRetyr = numberCheck(cpi.anRet10yr) ? numberCheck(cpi.anRet10yr) : (numberCheck(cpi.anRet5yr) ? numberCheck(cpi.anRet5yr) : numberCheck(cpi.anRet3yr));
  let _CPIanVolyr = numberCheck(cpi.anVol10yr) ? numberCheck(cpi.anVol10yr) : (numberCheck(cpi.anVol5yr) ? numberCheck(cpi.anVol5yr) : numberCheck(cpi.anVol3yr));

  const CPIanRetyrPercent = _CPIanRetyr / 100; //returns
  const CPIanVolyrPercent = _CPIanVolyr /100;   //volatility
  let invPortfolioValue = investment.portfolioValue //portfolio
  const lbConstant = 1.036433391
  const ubConstant = -1.036433391

  const getLbValue = index => {
    const calculatedValue =
    CPIanRetyrPercent -
      (0.5 * CPIanVolyrPercent ** 2 + (lbConstant / Math.sqrt(index)) * CPIanVolyrPercent)

    const lbDollarValue = invPortfolioValue * (1 + calculatedValue) ** index
    return lbDollarValue
  }

  const getMeanValue = index => {
    const meanDollar = invPortfolioValue * (1 + CPIanRetyrPercent) ** index
    return meanDollar
  }

  const getUpperValue = index => {
    const calculatedValue =
    CPIanRetyrPercent -
      (0.5 * CPIanVolyrPercent ** 2 + (ubConstant / Math.sqrt(index)) * CPIanVolyrPercent)

    const ubDollarValue = invPortfolioValue * (1 + calculatedValue) ** index
    return ubDollarValue
  }


  // let annualMeanReturn = parseInt(cpi.anRet10yr, 10) / 100
  let annualMeanReturn = (numberCheck(_CPIanRetyr) - advisory_fees)/100;
  console.log('annualMeanReturn -> ', annualMeanReturn)
  // let annualizedVolatility = parseInt(cpi.anVol10yr, 10) / 100
  let annualizedVolatility = numberCheck(_CPIanVolyr)/100;
  console.log('annualizedVolatility -> ', annualizedVolatility)

  // let minus_1_sd_10yr = parseInt(cpi.minus_1_sd_10yr, 10) / 100
  let minus_1_sd_10yr = ((numberCheck(_CPIanRetyr) - advisory_fees)+-1*numberCheck(_CPIanVolyr))/100;
  console.log('minus_1_sd_10yr -> ', minus_1_sd_10yr)
  // let plus_1_sd_10yr = parseInt(cpi.plus_1_sd_10yr, 10) / 100
  let plus_1_sd_10yr = ((numberCheck(_CPIanRetyr) - advisory_fees)+numberCheck(_CPIanVolyr)*1)/100;
  console.log('plus_1_sd_10yr -> ', plus_1_sd_10yr)

  // Chart Computation
  console.log('portfolioValue -> ', invPortfolioValue)

  let noOfYrs = 10

  let minValue = Number.POSITIVE_INFINITY
  let maxValue = Number.NEGATIVE_INFINITY

  console.log({minValue, maxValue},"minmax")

  for (var i = 0; i <= noOfYrs; i++) {
    let name = i == 0 ? 'Today' : 'Year ' + i
    let lb =
      i == 0
        ? Math.round(invPortfolioValue)
        : Math.round(getLbValue(i))
    if (lb < minValue) minValue = lb
    if (lb > maxValue) maxValue = lb
    let mb =
      i == 0
        ? Math.round(invPortfolioValue)
        : Math.round(getMeanValue(i))
    if (mb < minValue) minValue = mb
    if (mb > maxValue) maxValue = mb
    let ub =
      i == 0
        ? Math.round(invPortfolioValue)
        : Math.round(getUpperValue(i))
    if (ub < minValue) minValue = ub
    if (ub > maxValue) maxValue = ub
    chartData.push({ name, lb, mb, ub })
  }

  console.log(chartData)

  let minMaxValues = {
    minValue : isFinite(minValue) ? minValue : 'auto',
    maxValue : isFinite(maxValue) ? maxValue : 'auto'
  }

  let chartOptions = [{dataKey: 'ub', fillColor: '#56a9e8', fillOpacity: 0.3, stroke: '#56a9e8'},{dataKey: 'mb', fillColor: '#56a9e8', fillOpacity: 0.3, stroke: '#56a9e8'},{dataKey: 'lb', fillColor: '#56a9e8', fillOpacity: 0.3, stroke: '#56a9e8'}];

  let portPerfPercent = numberCheck(((chartData[noOfYrs].mb - invPortfolioValue)/invPortfolioValue * 100), 2); // ((chartData[noOfYrs].mb-invPortfolioValue)/invPortfolioValue * 100).toFixed(1);
  let avgReturn = numberCheck(_CPIanRetyr, 2); // numberCheck(cpi.anRet3yr, 2);
  let expRisk = numberCheck(_CPIanVolyr, 2); // numberCheck(cpi.anVol3yr, 2);
  let avgGrowth = chartData[noOfYrs].mb - invPortfolioValue;
  let avgGrowthChart = chartData[noOfYrs].mb;

  return (
    <React.Fragment>
      <div className="content">
        <div className="pr-v2-header">
          <h3>Executive Summary</h3>
          {createdDate && (<p>Data as of {createdDate}</p>)}
        </div>
        <div className="pr-v2-conclusion">
          {getExecutiveSummaryContent(avgReturn, expRisk, avgGrowth)}
          <Row>
            <Col xs="12">
              <div style={{width: '970px', height: '360px', marginTop: '20px', marginBottom: '10px', position: 'relative'}}>
                <StackedAreaChart chartData={chartData} chartOptions={chartOptions} minMaxValues={minMaxValues} showLabels/>
                <div className="pr-v2-conc-desc">
                  <p className="mb-0">Year 10</p>
                  <p className="mb-0">50% Chance of having:<span>{FormatCurrency(avgGrowthChart,0,'$','unf')}</span></p>
                </div>
              </div>
            </Col>
          </Row>
          <div style={{ marginTop: 50 }}>
            <p className="pr-v2-small-paragraph">
              This information was generated by an investment analysis tool that produces simulations and statistical analyses that present the likelihood of various investment outcomes if certain investments are made or certain investment strategies or styles are
              undertaken. Past performance does not guarantee future results. TIFIN Wealth Tech LLC does not guarantee any minimum level of investment performance or the success of any portfolio or investment strategy. Results may vary with each use and over time. There is a
              potential for loss in any investment, including the loss of capital. All investments involve risk, and different types of investments
              involve varying degrees of risk. Investment recommendations will not always be profitable. No representation is being made that
              any client account will or is likely to achieve profit or losses similar to those shown in the chart above. Hypothetical performance information shown in text, charts, tables and graphs is provided for informational purposes only and should not be considered investment advice or a recommendation to buy or sell any particular security.
            </p>
            <p className="pr-v2-small-paragraph">
              There are certain limitations inherent in hypothetical results like those portrayed, particularly that such hypothetical results do not reflect trading in actual client accounts and do not reflect the impact that material economic and market factors may have had on the investments chosen had the adviser actually been managing client accounts. Unlike an actual performance record, hypothetical performance results do not represent actual trading. These types of simulations, in general, benefit compared to actual performance results because such simulations are designed with the benefit of hindsight. In addition, simulated trading does not involve or take into account financial risk and does not take into account that material and market factors may have on investments
              chosen, all of which can adversely affect actual trading results and performance.
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
})

export default ExecutiveSummary

import React, { useContext } from 'react';
import ActReportContext from '../ActReportContext';
import { BarChart, Bar, Cell, XAxis, YAxis, ResponsiveContainer, ReferenceLine } from 'recharts';
// import { extent as d3Extent } from 'd3-array';
import { LabelList, Legend, CartesianGrid } from 'recharts';
import { LineChart, Line } from 'recharts';
// import { RfScoreAttrs as rfa } from './Utils';
import { kmb } from 'DataSet';

export const BarChartReport = ({ data }) => {
  const reportData = useContext(ActReportContext);
  const { allAttrs } = reportData;
  let tempData = data.map(v=>{
    return {...v,name:''}
  })
  // data = [{"name": "VIIX", "value": 0.7, "color": "#609f60"}, {"name": "VIXY", "value": 0.87, "color": "#7d91b5"}, {"name": "VXX", "value": 0.89, "color": "#C97CAC"}, {"name": "VIIX1", "value": 0.7, "color": "#609f60"}, {"name": "VIXY2", "value": 0.87, "color": "#7d91b5"}, {"name": "VXX3", "value": 0.89, "color": "#C97CAC"}, {"name": "VIIX4", "value": 0.7, "color": "#609f60"}, {"name": "VIXY5", "value": 0.87, "color": "#7d91b5"}, {"name": "VXX6", "value": 0.89, "color": "#C97CAC"}, {"name": "VIIX7", "value": 0.7, "color": "#609f60"} ]
  const domain = [ dataMin => Math.min(dataMin, 0), dataMax => Math.max(dataMax, 0) ]
  const minData = Math.min(...data.map(d => d.value))
  return (
    <ResponsiveContainer width="100%" height="100%" >
      <BarChart data={tempData} margin={{top: 14, right: 0, left: 0, bottom: minData < 0 ? 20 : 0}}>
        <XAxis dataKey="name" tickLine={false} axisLine={false} hide={true}/>
        <YAxis hide={true} domain={domain} />
        <ReferenceLine y={0} stroke="#edf8fc" shapeRendering="crispEdges"/>
        <Bar dataKey="value" maxBarSize={50} isAnimationActive={false} >
          <LabelList position="top" className="ssf" valueAccessor={(e) => valueAccessor(e, allAttrs)} />
          <LabelList dataKey="name" position="center" className="ssf" fill="white" content={renderCustomizedLabel} />
          { data.map((v, i) => <Cell key={`cell-${i}`} fill={v.color}/>) }
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

const valueAccessor = (e, allAttrs) => {
  const {payload: pld, attr} = e;
  if(!attr) return;
  const attrDetails = allAttrs.find(item => item.name.toLowerCase() === attr.toLowerCase());
  if(pld.relFunds){
    return (pld.labelNA || (attrDetails.parser ? kmb(pld.value || 0) : pld.value.toFixed(2)) + attrDetails.suffix)
  }else{
    return (pld.labelNA || `${pld.value.toFixed(2)}%`)
  }
}

const labelPc = label => label.toFixed(2)+'%'

const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value, fill, color } = props;
  const textProps = { color, width, height, fill, x: x+width/2, y: y+height/2 }
  if (Math.abs(height) < 20) {
    textProps.y = y + height + (height < 0 ? -8 : 8);
    textProps.fill = "black";
  }

  return (
    <text {...textProps} className="recharts-text recharts-label ssf" textAnchor="middle">
      <tspan x={textProps.x} dy="0.355em">{value}</tspan>
    </text>
  );
};

export const LineChartReport = (props) => {
  const { series, xkey, ykey } = props;
 // let ydomain = ['auto', 'auto'];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart margin={{top: 15, right: 5, left: 5, bottom: 5}}>

        <XAxis dataKey={xkey} tickLine={false} type="category" allowDuplicatedCategory={false} padding={{ left: 50, right: 50 }}/>
        <YAxis width={40} />
        <CartesianGrid vertical={false} strokeWidth="0.5" stroke="#d7d7d7" strokeOpacity="1" shapeRendering="crispEdges" />

        { series.map(s => (
          <Line dataKey={ykey} data={s.data} name={s.name} key={s.name}
            type="linear" unit="%" stroke={s.color} strokeWidth={2}
            dot={false}
            isAnimationActive={false} />
        ))}

        <Legend wrapperStyle={{ paddingTop: "5px", bottom: "-5px" }} content={renderCusomizedLegend} />
      </LineChart>
    </ResponsiveContainer>
  );
}

const renderCusomizedLegend = (props) => {
  const { payload } = props
  return (
    <div className="customized-legend">
      {
        payload.map((entry, i) => {
          const { dataKey, color, value } = entry
          return (
            <span key={i} className="legend-item">
              <span style={{borderLeft: `4px solid ${color}`}}>{value}</span>
            </span>
          )
        })
      }
    </div>
  )
}

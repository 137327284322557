import React, { Component } from 'react';
import { Input } from 'antd';

const classNames = require('classnames');

class InputSearch extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      value: props.defaultValue || '',
      active: props.defaultValue ? true : false,
      searchChange: false,
    };
  }

  componentWillReceiveProps(nextProps){
    // console.log('here props1', nextProps);

    if(nextProps.buildportfolio && nextProps.buildportfolio.thematicTab.newQuery !== this.props.buildportfolio.thematicTab.newQuery){
      if(this.props.buildportfolio.activeTab === 'thematicTab'){
        console.log('here props2', nextProps);
        this.setState({value: this.state.searchChange ? this.state.value : nextProps.buildportfolio.thematicTab.newQuery, searchChange: false});
      }
    }
  }

  clearSearch = () => {
    this.setState({
      value: '',
    });
    if (this.inputRef) {
      this.inputRef.current.focus();
      const { onChangeSearch } = this.props;
      if (onChangeSearch) {
        onChangeSearch('');
      }
    }
  }

  validQuery = (query) => query.trim().length > 0

  onSearch = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const { onSearch } = this.props;
    if (onSearch) {
      onSearch(this.state.value, event);
    }
  }

  onClick = (e) => {
    const { onClick } = this.props;
    if (onClick) {
     onClick(e);
   }
  }

  onBlurDo = () => {
    const { onBlurDo } = this.props;
    if (onBlurDo) {
     onBlurDo();
   }
  }

  onChangeSearch = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const value = e.target.value;
    this.setState({ value, searchChange: true, active: this.validQuery(value) }, () => {
      const { onChangeSearch } = this.props;
      if (onChangeSearch) {
        onChangeSearch(this.state.value);
      }
    });
  }

  render() {
    const { placeholder, disabled, defaultValue, className } = this.props;
    const { value, clearSearch, active } = this.state;

    return (
      <div className="query-search-box">
        <Input
          ref={this.inputRef}
          id="new-fund-search-box"
          className={classNames("search-input-field", className)}
          autoComplete="off"
          style={{ paddingRight: (value && value.length > 0) ? '60px' : '40px' }}
          value={value}
          placeholder={placeholder}
          onPressEnter={(event) => this.onSearch(event)}
          onClick={e => this.onClick(e)}
          onChange={e => this.onChangeSearch(e)}
          onBlur={() => this.onBlurDo()}
          suffix={<i className={classNames("far fa-fw fa-search fa-lg search-icon", {'active-icon': (active && value && value.length > 0)})} style={{ right: 12 }} onClick={this.onSearch} />}
        />

        {(value && value.length > 0) && (
          <i className={`far fa-times fa-lg clear-icon ${clearSearch ? '' : ''} ${active ? 'active-icon' : ''}`}
            onClick={this.clearSearch} />
        )}
      </div>
    );
  }
}

export default InputSearch;

import React from 'react';
import { withHeaderFooter } from '../shared/HeaderFooter';

import CorrelationTable from './CorrelationTable';
import DateRangeSubHeading from 'components/reportsV2/shared/DateRangeSubHeading'

const CorrelationMatrixWrap = withHeaderFooter((props) => {
  const { selFunds, correlationData, totFunds } = props;
  return (
    <React.Fragment>
      <div className="content">
        <div className="pr-v2-header">
          <h3>Performance Analytics : Correlation* Matrix</h3>
          <DateRangeSubHeading />
        </div>
        <div className="pr-v2-perf-analytics">
          <div className="perf-component-wrapper">
            <CorrelationTable
              selFunds={selFunds}
              correlationData={correlationData}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
});

export default CorrelationMatrixWrap;
import React, { useContext } from 'react';
import { Row, Col, Table } from 'reactstrap';
import { withHeaderFooter } from '../shared/HeaderFooter';
import ActReportContext from '../ActReportContext';
import { FormatCurrency, customColors, fixedValue } from '../shared/Utils';
import { AttrChartsRelFund } from './Common';
import { ViewChart } from './ViewChart';
import { calculatorOptions, ActiveCalcScoreAttrs } from 'DataSet';
import { concatAttrs } from '../shared/Utils';
import BestTickerBadge from 'components/reportsV2/shared/BestTickerBadge'
import { BenchmarkRow } from 'components/reportsV2/sf/RelevantFunds'
import { BenchmarkDisclaimer } from 'components/reportsV2/sf/Common'

const OtherRelevantFunds = withHeaderFooter((props) => {
  const reportData = useContext(ActReportContext)
  const { allFunds, allAttrs, compare: [{ attrs, funds, date, attrsWithScore, selectedCalculator }], flags, search: { query, correctedQuery }, selectedFundDetails, bestTicker, benchmark, printSelection } = reportData;
  //instead of 8, took 6 because of addition of vs S&P 500 and benchmark row
  const otherAttrs = printSelection.includes('rel-funds') ? attrs.slice(6, attrs.length) : attrs.slice(8, attrs.length)
  const selectedCalcOption = Object.keys(calculatorOptions).find(key => calculatorOptions[key] === selectedCalculator);
  // const getMultiplier = (col) => {
  //   const multiplierList = ['bid-ask spread', 'tracking error', 'premium to nav',
  //     'trend', 'outperformance 3yr', 'outperformance 5yr', 'outperformance 10yr',
  //     'alpha 5yr', 'alpha 10yr'];
  //   return multiplierList.includes(col) ? 100 : 1;
  // }
  const chartData = otherAttrs.map(a => funds.map((f, i) => {
    const sf = allFunds.find((k) => k.ticker == f.ticker)
    let labelNA = sf ? ((a === 'bid-ask spread' || a === 'premium to nav' || a === 'volume') && sf.type == 'MF' && f[a] == 0) ? 'NA' : '' : ''
    const selectedAttr = allAttrs.find(item => item.name.toLowerCase() == a.toLowerCase());
    const multiplier = selectedAttr ? selectedAttr.multiplier : 1;
    const attrValue = selectedAttr ? f._sfStats[selectedAttr.col] : f[a];
    if (attrValue === null) { labelNA = 'NA' }
    const value = fixedValue((attrValue && labelNA !== 'NA' ? attrValue : 0) * multiplier);
    return ({
      name: sf ? sf.ticker_short : f.ticker,
      value,
      color: customColors[i % customColors.length],
      labelNA,
      attr: a,
      relFunds: true
    })
  }));
  let showSPYDisclaimer = false
  attrsWithScore.forEach(item => {
    if (!showSPYDisclaimer) {
      if (item.name.toLowerCase().includes('capture') || item.name.toLowerCase().includes('alpha') || item.name.toLowerCase().includes('outperformance')) {
        showSPYDisclaimer = true
      }
    }
  })
  return (
    <React.Fragment>
      <div className="content">
        <div className="pr-v2-header">
          <h3 style={{ marginBottom: 10 }}>Selection Criteria</h3>
        </div>
        <div className="pr-v2-relevant-funds">
          <div>
            <p className="pr-v2-sm-title">What Matters to You</p>
            {/*ticker row*/}
            <div style={{ display: `flex` }}>
              <div style={{ minWidth: `180px` }}>
                {/*  blank space */}
              </div>
              <div className={'d-flex justify-content-around'} style={{ width: `100%` }}>
                {chartData[0].map(v => {
                  return <div style={{ maxWidth: `${100 / chartData[0].length}%`, width: `${100 / chartData[0].length}%` }} className={'d-flex justify-content-center align-items-center'} >
                    <p style={{ fontSize: `18px`, position: 'relative' }}>
                      {(v.name && v.name === bestTicker) && (
                        <BestTickerBadge
                          style={{
                            position: 'absolute',
                            left: '-26px',
                          }}
                        />
                      )}
                      {v.name}
                    </p>
                  </div>
                })}
              </div>
            </div>
            <div xs="12" className="rel-results">
              <AttrChartsRelFund attrs={otherAttrs} chartData={chartData} attrsWithScore={attrsWithScore} selectedCalcOption={selectedCalcOption} relFunds />
            </div>
            {/*benchmark row*/}
            {/* <BenchmarkRow benchmark={benchmark} selectedFundDetails={selectedFundDetails} chartData={chartData[0]}/> */}
          </div>
        </div>
        {showSPYDisclaimer && <BenchmarkDisclaimer style={{ paddingTop: `20px` }} />}
      </div>
    </React.Fragment>
  )
});

export default OtherRelevantFunds;
import { takeEvery, fork, call, put, all, select } from 'redux-saga/effects';
import { delay } from 'redux-saga'
import _ from 'lodash';
import get from 'lodash/get';
import { message } from 'antd';
import ActionTypes from 'action-types';

import {
  sendClientListData,
  fetchClientListData,
  deleteSelectedClients,
  fetchModelPfHoldingsData,
  fetchCustomPfHoldingsData,
  fetchClientHoldingsData,
  saveClientBenchMarkData,
  removeClientBenchMarkData,
  fetchClientChartDetails,
  sendFinalizeAllocation,
  fetchTradelistData,
  fetchSearchAccFundsData,
  fetchModelSearchData,
  sendRestrictedTickers,
  sendUpdatedTaxRates,
  fetchTaxBillsData,
  sendSelectedTaxBill,
  fetchClientListByPageNumber,
  searchQueryAndGetClientList,
} from 'apis/clientportfolioV2';

import { fetchPortfolioList, fetchMarketPlaceList, uploadPortfolioAPI } from 'apis/account';

import { fetchRealTimePrices, fetchUpAndDownChart } from 'apis/funds';

import {
  uploadClientListSuccess,
  clientListLoader,
  clientListRequest,
  clientListSuccess,
  clientDetailsSuccess,
  clientHoldingsSuccess,
  portfolioOptionsSuccess,
  updateClientListSuccess,
  createFinalizeObject,
  finalizeAllocationSuccess,
  generateTradelistSuccess,
  searchAccFundsSuccess,
  searchAccFundsSuccessQuery,
  searchAccFundsFailure,
  searchAccFundsReset,
  modelSearchSuccess,
  modelSearchNoResult,
  taxBillsSuccess,
  searchAccFundsRequest,
  clientDeleteRequest,
  chartDetailsSuccess,
  uploadSingleClientFetch,
  uploadSingleClientSuccess,
  setInitialClientList,
  accountPageClientListRequest,
  accountPageClientListFetch,
  accountPageClientListSuccess,
  getClientListBySearchQueryRequest,
  getClientListBySearchQueryFetch,
  getClientListBySearchQuerySuccess,
  downloadMoreDataFetch,
  downloadMoreDataSuccess,
  getSelectedClientDetailsRequest,
  getSelectedClientDetailsFetch,
  getSelectedClientDetailsSuccess,
  updateRestrictedTickersFetch,
  updateRestrictedTickersSuccess,
  deleteClientAccountFetch,
  deleteClientAccountSuccess,
  filterDownloadedListFetch,
  filterDownloadedListSuccess,
  resetFilterDownloadedListFetch,
  resetFilterDownloadedListSuccess,
} from 'actions/clientportfolioV2';

import { getClientListSuccess } from 'actions/enhancer';

import { successfulSetAdvisorData } from 'actions/auth';

import { replaceTicker } from 'Utils';

function* uploadClient(action) {
  try {
    let uploadedClientData
    // Temporary solution to maintain consistency will change this
    // after completing full flow
    if (Array.isArray(action.payload)) {
      uploadedClientData = {
        uploadedClientList: action.payload
      }
    } else {
      uploadedClientData = action.payload
    }
    console.log(uploadedClientData);
    yield put(uploadClientListSuccess(uploadedClientData))
  } catch (error) {
    console.log(error)
  }
}

function* sendClientList(action) {
  try {
    // Temporarily set client_exist to true until client list arrives
    // Review and replace this with proper loader
    let advisorData = {
      client_exist: true,
      user_advisor_menu:
        window.localStorage.getItem('user_advisor_menu') || false
    }
    window.localStorage.setItem('client_exist', true)
    window.localStorage.setItem(
      'user_advisor_menu',
      advisorData.user_advisor_menu
    )
    yield put(successfulSetAdvisorData(advisorData))

    // Empty the existing client list to show the loader
    // yield put(clientListSuccess([]))
    yield put(clientListLoader())

    const {
      payload: { pfList, filterClients, callback, selectedClient }
    } = action

    let uploadedClientList = pfList
    let deleteClientsIds = []
    let checkDeleteClients

    if (filterClients) {
      uploadedClientList = pfList.filter(item => {
        if (typeof item.updateData == 'undefined' || item.updateData) {
          deleteClientsIds.push(item.existingClientID)
          return item
        }
      })
    }

    if (deleteClientsIds.length > 0) {
      checkDeleteClients = yield call(deleteSelectedClients, {
        client_ids: deleteClientsIds
      })
    }

    if (
      uploadedClientList.length > 0 &&
      (typeof checkDeleteClients == 'undefined' ||
        checkDeleteClients.status == 200)
    ) {
      const result = yield call(sendClientListData, uploadedClientList)
      // No status key is received in the API response,
      // hence checking if the object is present
      // Request client list upon successful upload of the csv
      if (result) {
        // Clear uploaded clientlist from reducer to allow new request
        // Temporary solution: replace all upload client list function uniformly
        // in all headers and pages then change this
        yield put(uploadClientListSuccess({ uploadedClientList: [] }))
        if (callback && selectedClient) {
          const result = yield call(fetchClientListData)
          if (result && result.status == 200) {
            const selectedClientDetails =
              result.data &&
              result.data.find(
                client => client.acc_no == selectedClient.account_number
              )
            selectedClientDetails && callback(selectedClientDetails)
          }
        } else {

          let downloadedList = yield select(state => state.clientportfolioV2.downloadedList);
          if (downloadedList) {
            const { pagination, query } = downloadedList;
            if (query) {
              yield put(
                getClientListBySearchQueryRequest({
                  q: query,
                  count: pagination.count,
                  page_number: 1,
                })
              );
            } else {
              yield put(
                accountPageClientListRequest({
                  count: pagination.count || 20,
                  page_number: 1,
                })
              );
            }
          }

          // yield put(clientListRequest())
        }
      }
    }
  } catch (error) {
    console.log(error)
  }
}

function* fetchClientList(action) {
  try {
    yield put(clientListLoader())
    const result = yield call(fetchClientListData)
    if (result && result.status === 200) {
      const clientList = result.data;
      console.log('------ clientList --------');
      console.log(clientList);
      console.log('--------------------------');
      if (clientList && Array.isArray(clientList) && clientList.length) {
        yield put(clientListSuccess(clientList))
        yield put(setInitialClientList({
          initialClientList: clientList,
        }))
        yield put(getClientListSuccess({
          clientList: clientList,
        }))
        let advisorData = {
          client_exist: true,
          user_advisor_menu: true
        };
        window.localStorage.setItem('client_exist', true)
        window.localStorage.setItem('user_advisor_menu', true)
        yield put(successfulSetAdvisorData(advisorData));
      } else {
        throw '-- Failed to Client List, check api response --'
      }
    } else {
      throw '-- Failed to Client List, check api response --'
    }
  } catch (error) {
    console.log(error)
    message.warning('Something went wrong while loading accounts', 5);
    let advisorData = {
      client_exist: false,
      user_advisor_menu: window.localStorage.getItem('user_advisor_menu') || false
    };
    window.localStorage.setItem('client_exist', false)
    window.localStorage.setItem('user_advisor_menu', advisorData.user_advisor_menu)
    yield put(successfulSetAdvisorData(advisorData))
  }
}

function* deleteClients(action) {
  try {
    const { payload, callback } = action
    yield put(deleteClientAccountFetch());

    if (payload && payload.client_ids) {
      const result = yield call(deleteSelectedClients, payload)
      if ((result && result.status === 200 && result.data) && (
        result.data.message === "Client Deleted Successfully." || result.data.message === "Client Deleted Successfully"
      )) {

        yield put(deleteClientAccountSuccess({
          message: result.data.message,
        }));

        // old-logic below
        // const clientDetails = yield select(state => ({
        //   clientList: state.clientportfolioV2.clientList,
        //   accSearchQuery: state.clientportfolioV2.accSearchQuery,
        //   uploadedClientList: state.clientportfolioV2.uploadedClientList
        // }))
        // const { clientList, accSearchQuery, uploadedClientList } = clientDetails

        // NOTE: After deleting Client, refresh downloadedList/clientList
        // Also check if no '0' client present in downloadedList/clientList then set `client_exist: false` and show 'uploadModal' on UI
        // -------------------------------------------------------------------------------------------
        let downloadedList = yield select(state => state.clientportfolioV2.downloadedList);
        if (downloadedList) {
          const { pagination, query } = downloadedList;
          if (query) {
            yield put(
              getClientListBySearchQueryRequest({
                q: query,
                count: pagination.count,
                page_number: 1,
              }, () => {
                // NOTE: Toast a message on UI
                message.success('Client Deleted Successfully.', 5);
              })
            );
          } else {

            // NOTE:  below code added, is old-logic
            // --------------------------------------------------------------------
            // if (clientList.length === payload.client_ids.length) {
            //   let advisorData = {
            //     client_exist: false,
            //     user_advisor_menu: window.localStorage.getItem('user_advisor_menu') || false
            //   }
            //   window.localStorage.setItem('client_exist', false)
            //   window.localStorage.setItem(
            //     'user_advisor_menu',
            //     advisorData.user_advisor_menu
            //   )
            //   yield put(successfulSetAdvisorData(advisorData))
            //   // Remove uploaded client list if exists when the user deletes all the clients
            //   if (uploadedClientList) yield put(uploadClientListSuccess([]))
            //   // yield put(clientListSuccess([]))
            //   // return;
            // }
            // --------------------------------------------------------------------

            yield put(
              accountPageClientListRequest({
                count: pagination.count,
                page_number: 1,
              }, () => {
                // NOTE: Toast a message on UI
                message.success('Client Deleted Successfully.', 5);
              })
            );
          }

        }
        // -------------------------------------------------------------------------------------------

        // if (accSearchQuery) {
        //   // If the user chooses to delete all clients then remove the search query and results
        //   // Else run the search again after deleting the client
        //   // if (clientList.length == payload.client_ids.length) {
        //   //   yield put(searchAccFundsRequest())
        //   // } else {
        //   //   yield put(searchAccFundsRequest(accSearchQuery))
        //   // }
        // } else {
        //   // Manually set client_exists in localstorage when the user deletes all the clients
        //   if (clientList.length == payload.client_ids.length) {
        //     let advisorData = {
        //       client_exist: false,
        //       user_advisor_menu:
        //         window.localStorage.getItem('user_advisor_menu') || false
        //     }
        //     window.localStorage.setItem('client_exist', false)
        //     window.localStorage.setItem(
        //       'user_advisor_menu',
        //       advisorData.user_advisor_menu
        //     )
        //     yield put(successfulSetAdvisorData(advisorData))
        //     // Remove uploaded client list if exists when the user deletes all the clients
        //     if (uploadedClientList) yield put(uploadClientListSuccess([]))
        //     // yield put(clientListSuccess([]))
        //     // return;
        //   }
        //
        //   // yield put(clientListRequest())
        // }

      }
      if (callback && typeof callback === 'function') {
        callback(result)
      }
    } else {
      throw '--- Payload Missing, client_ids required to process. ---'
    }

  } catch (error) {
    console.log(error)
    yield put(deleteClientAccountSuccess({
      pageCrash: 'Failed to process request.'
    }));
  }
}

// This generator is not used, delete it after the feature completetion
function* fetchClientHoldings(action) {
  try {
    const result = yield call(fetchClientHoldingsData, action.payload)
    if (result && result.status === 200) {
      yield put(clientHoldingsSuccess(result.data))
    }
  } catch (error) {
    console.log(error)
  }
}

function* fetchClientDetails(action) {
  try {
    // Clear old data before adding new client data
    // Create a new action for reset (resetClientDetails) later
    yield put(clientDetailsSuccess(''))
    const query= yield select(state => ({
      query_var: state.clientportfolioV2.query_var,
    }))
    action.payload['query_var']= query && query.query_var;
    const result = yield call(fetchClientHoldingsData, action.payload)
    if (result && result.status === 200) {
      console.log('------ fetchClientHoldingsData --------');
      console.log(result.data);
      console.log('--------------------------');
      yield put(clientDetailsSuccess(result.data))
    }
  } catch (error) {
    console.log(error)
  }
}

function* sendBenchmarkData(action) {
  try {
    const result = yield call(saveClientBenchMarkData, action.payload)
    if (result.status === 200) {
    }
  } catch (error) {
    console.log(error)
  }
}

function* removeBenchmarkData(action){
  try{
    const result = yield call(removeClientBenchMarkData, action.payload)
    if (result.status === 200) {
    }
  }catch (error){
    console.log(error)
  }
}

function* fetchChartDetails(action){
  try{
     yield put(chartDetailsSuccess(''))
     const result = yield call(fetchClientChartDetails, action.payload)
     if (result.status === 200) {
        yield put(chartDetailsSuccess(result.data))
      }
    } catch (error) {
      console.log(error)
    }
  }

function* fetchPfHoldings(action) {
  try {
    let { payload } = action
    // If the ticker is present call the model portfolio details API
    // Else call the custom portfolio details API
    const result = payload.ticker
      ? yield call(fetchModelPfHoldingsData, { ticker: payload.ticker })
      : yield call(fetchCustomPfHoldingsData, { file_name: payload.file_name })
    if (result.status === 200) {
      let navValues = result.data.reduce(
        (t, k, i, v) => (i == v.length - 1 ? t + k.script : t + k.script + ','),
        ''
      )
      const getRealNavValues = yield call(fetchRealTimePrices, {
        tickers: navValues
      })
      let currentPfWithNav = result.data.map((item, i) => {
        let obj = Object.assign({}, item)
        let tickerDetails = getRealNavValues.funds.find(
          a => a.ticker == item.script
        )
        obj.nav = tickerDetails ? tickerDetails.price : item.nav
        return obj
      })
      yield put(modelSearchSuccess([]))
      yield put(clientHoldingsSuccess({ holdings: currentPfWithNav }))
    }
  } catch (error) {
    console.log(error)
  }
}

function* clearHoldings(action) {
  try {
    yield put(clientHoldingsSuccess({ holdings: [] }))
  } catch (error) {
    console.log(error)
  }
}

function* setSelectedPortfolio(action) {
  try {
    let {
      payload: { value, ticker, clientID, clientList, isGlobalSelect ,finalizeAllocationRequest}
    } = action
    const _initClientList= yield select(state => ({
      initialClientList: state.clientportfolioV2.clientList,
    }))
    let changedPfClients = []
    let UpdatedClientList = clientList.map((item, i) => {
      let obj = Object.assign({}, item)
      // Make all values to the globally selected value or change on selected value
      if (isGlobalSelect) {
        if (!ticker && !value) {
          obj.model_portfolio = {}
          obj.custom_portfolio = {}
          obj.selected_portfolio = ''
        } else if (ticker) {
          obj.model_portfolio = {
            portfolio_name: value,
            ticker: ticker
          }
          obj.custom_portfolio = {}
          obj.selected_portfolio = value
        } else {
          obj.model_portfolio = {}
          obj.custom_portfolio = {
            portfolio_name: value
          }
          obj.selected_portfolio = value
        }
      } else {
        if (item.id == clientID) {
          if (!ticker && !value) {
            obj.model_portfolio = {}
            obj.custom_portfolio = {}
            obj.selected_portfolio = ''
          } else if (ticker) {
            obj.model_portfolio = {
              portfolio_name: value,
              ticker: ticker
            }
            obj.custom_portfolio = {}
            obj.selected_portfolio = value
          } else {
            obj.model_portfolio = {}
            obj.custom_portfolio = {
              portfolio_name: value
            }
            obj.selected_portfolio = value
          }
          changedPfClients.push(obj);
        }
      }
      // let isChanged = _initClientList && _initClientList.initialClientList.filter(a => a.id == obj.id)
      // let {
      //   model_portfolio: { portfolio_name: mpa },
      //   custom_portfolio: { portfolio_name: cpa }
      // } = isChanged
      // // compare the current updated client with the initial client values and push changed values
      // if (obj && (mpa != obj.model_portfolio.portfolio_name ||cpa != obj.custom_portfolio.portfolio_name) ) {
      //   changedPfClients.push(obj)
      // }
      // return obj
    })
    yield put(createFinalizeObject(changedPfClients))
    yield put(updateClientListSuccess(UpdatedClientList))
    yield put(setInitialClientList(_initClientList))
    finalizeAllocationRequest(changedPfClients)
  } catch (error) {
    console.log(error)
  }
}

function* resetClientList(action) {
  try {
    let {
      payload: { initialClientList }
    } = action
    yield put(updateClientListSuccess(initialClientList))
    yield put(createFinalizeObject([]))
    yield put(modelSearchSuccess([]))
  } catch (error) {
    console.log(error)
  }
}

function* sendFinalAllocation(action) {
  try {
   // yield put(clientListLoader())
     const _accSearchQuery= yield select(state => ({
       state: state.clientportfolioV2.accSearchQuery,
    }))
    const result = yield call(sendFinalizeAllocation, action.payload)
    if (result && result.status === 200) {
      yield put(finalizeAllocationSuccess(result.data))
      // if(_accSearchQuery){
      //   // yield put(searchAccFundsRequest(_accSearchQuery.state))
      // }
      let downloadedList = yield select(state => state.clientportfolioV2.downloadedList);
      if (downloadedList) {
        let selectDefaultClient = (action.payload && action.payload.length > 0) ? action.payload[0] : action.payload;
        const { pagination, query } = downloadedList;
        if (query) {
          yield put(
            getClientListBySearchQueryRequest({
              selectDefaultClient,
              q: query,
              page_number: 1,
              count: pagination.count,
            })
          );
        } else {
          yield put(
            accountPageClientListRequest({
              selectDefaultClient,
              page_number: 1,
              count: pagination.cou || 20,
            })
          );
        }
      }
      //yield put(clientListSuccess([]))
      //yield put(clientListRequest())
    }
  } catch (error) {
    console.log(error)
  }
}

function* fetchTradelist(action) {
  try {
    const result = yield call(fetchTradelistData, action.payload)
    if (result.status === 200) {
      action.payload.dT(result.data)
      // yield put(generateTradelistSuccess(result.data));
    }
  } catch (error) {
    console.log(error)
  }
}

function* fetchSearchAccFunds(action) {
  try {
    if (action.payload) {
      // Clear client list to display the loader, then show either all clients
      // or filtered clients based on response
      yield put(clientListSuccess([]))
      yield put(clientListLoader())
      // Set clientSearchStatus to true to show the error modal in case of no results
      // yield put(searchAccFundsSuccess([action.payload, null]))
      // yield put(searchAccFundsReset());
      let result = yield call(fetchSearchAccFundsData, { q: action.payload })
      if (result && result.status === 200 && result.data.client_list.length > 0) {
        yield put(clientListSuccess(result.data.client_list))
        yield put(searchAccFundsSuccess([action.payload, result.data]))
      } else {
        // yield put(clientListRequest());
        yield put(searchAccFundsFailure([action.payload]))
      }
    } else {
       yield put(searchAccFundsSuccess([]))
       yield put(searchAccFundsSuccessQuery([]))
      //yield put(clientListRequest())
    }
  } catch (error) {
    console.log(error)
  }
}

function* fetchModelSearchResults(action) {
  try {
    if (action.payload) {
      let { payload } = action
      // Clear search results to display the loader, then show no data
      // or search results based on response
      yield put(modelSearchSuccess([action.payload.q, []]))
      let result = yield call(fetchModelSearchData, {
        q: payload.q,
        count: payload.c,
        universe: 'mp',
        type: 'account_model_search'
      })
      if (
        result.status === 200 &&
        result.data.funds &&
        result.data.funds.length > 0
      ) {
        // Remove query_var if the view_name is Highlight, to fall back to asset chart
        if (result.data.query_var) {
          result.data.query_var = result.data.query_var
            .filter(x => x.view_name !== '' && x.view_name !== 'Highlight')
            .map(r => replaceTicker(r))
        }
        yield put(modelSearchSuccess([action.payload.q, result.data]))
      } else {
        yield put(modelSearchNoResult())
      }
    }
  } catch (error) {
    console.log(error)
  }
}

function* updateRestrictedTickers(action) {
  try {
    if (action.payload) {
      let { payload } = action
      yield put(updateRestrictedTickersFetch());
      const accSearchQuery = yield select(
        state=>state.clientportfolioV2.accSearchQuery,
      )
      let result = yield call(sendRestrictedTickers, payload)
      if (result && result.status === 200) {
        yield put(updateRestrictedTickersSuccess());

        let downloadedList = yield select(state => state.clientportfolioV2.downloadedList);
        if (downloadedList) {
          const { pagination, query } = downloadedList;
          if (query) {
            yield put(
              getClientListBySearchQueryRequest({
                q: query,
                count: pagination.count,
                page_number: 1,
              })
            );
          } else {
            yield put(
              accountPageClientListRequest({
                count: pagination.count,
                page_number: 1,
              })
            );
          }
        }

        // if(accSearchQuery){
        //   yield put(searchAccFundsRequest(accSearchQuery))
        // } else {
        //   yield put(clientListSuccess([]))
        //   yield put(clientListRequest([]))
        // }

      }
    }
  } catch (error) {
    console.log(error)
  }
}

function* updateTaxRates(action) {
  try {
    if (action.payload) {
      let { payload } = action
      let result = yield call(sendUpdatedTaxRates, payload)
      if (result && result.status === 200) {

        let downloadedList = yield select(state => state.clientportfolioV2.downloadedList);
        if (downloadedList) {
          const { pagination, query } = downloadedList;
          if (query) {
            yield put(
              getClientListBySearchQueryRequest({
                q: query,
                count: pagination.count,
                page_number: 1,
              })
            );
          } else {
            yield put(
              accountPageClientListRequest({
                count: pagination.count,
                page_number: 1,
              })
            );
          }
        }

        // yield put(clientListSuccess([]))
        // yield put(clientListRequest())
      }
    }
  } catch (error) {
    console.log(error)
  }
}

function* fetchTaxBills(action) {
  try {
    if (action.payload) {
      let { payload } = action
      let result = yield call(fetchTaxBillsData, payload)
      if (result.status === 200) {
        yield put(taxBillsSuccess(result.data))
      }
    }
  } catch (error) {
    console.log(error)
  }
}

function* updateSelectedTaxBill(action) {
  try {
    if (action.payload) {
      let { payload } = action
      let result = yield call(sendSelectedTaxBill, payload)
      if (result && result.status === 200) {

        let downloadedList = yield select(state => state.clientportfolioV2.downloadedList);
        if (downloadedList) {
          const { pagination, query } = downloadedList;
          if (query) {
            yield put(
              getClientListBySearchQueryRequest({
                q: query,
                count: pagination.count,
                page_number: 1,
              })
            );
          } else {
            yield put(
              accountPageClientListRequest({
                count: pagination.count,
                page_number: 1,
              })
            );
          }
        }
        // yield put(clientListSuccess([]))
        // yield put(clientListRequest())
      }
    }
  } catch (error) {
    console.log(error)
  }
}

// Uploading Single client portfolio
function* uploadSingleClientCSV(data) {
  const { payload, callback } = data
  try {
    if (payload && payload.data) {
      const { data } = payload
      let weight_calculated_format;

      const updatedPayloadData = data.map(val => {
        let obj = Object.assign({}, val)
        if ('key' in obj) {
          delete obj.key
        }
        if ('weight(%)' in obj) {
          delete obj['weight(%)']
        }
        if ('loading' in obj) {
          delete obj.loading
        }
        if ('message' in obj) {
          delete obj.message
        }
        if ('showInMissingTicker' in obj) {
          delete obj.showInMissingTicker
        }
        if ('realTimePrice' in obj) {
          obj.realTimePrice =
            obj.realTimePrice === 'NA' ? 0 : Number(obj.realTimePrice)
        }
        if ('price' in obj) {
          obj.price = obj.price === 'NA' ? 0 : Number(obj.price)
        }
        if ('shares' in obj) {
          obj.shares =
            obj.shares === 'NA' ||
            obj.shares === null ||
            obj.shares === undefined
              ? 0
              : Number(obj.shares)
        }
        // for incorect-ticker-case
        if (
          typeof obj.values === 'undefined' ||
          obj.values === 'NA' ||
          obj.values === null ||
          obj.values === undefined
        ) {
          obj.values = 0
        }
        if ('validTicker' in obj) {
          obj.invalid = !obj.validTicker
          delete obj.validTicker
        }
        if ('invalidType' in obj) {
          obj.invalid_type = obj.invalidType
          delete obj.invalidType
        }
        if ('weight_calculated_format' in obj) {
          weight_calculated_format = obj.weight_calculated_format
          delete obj.weight_calculated_format
        }
        return obj
      })

      payload.data = updatedPayloadData
      if (weight_calculated_format) {
        payload.weight_calculated_format = weight_calculated_format
      }

      yield put(uploadSingleClientFetch(payload))

      // NOTE:  prepare data like this
      // account_number: "9820431"
      // custodian: "Fidelity"
      // existingClientID: 49464
      // holdings: [{ticker: "FILL", shares: 5330}, {ticker: "SECUX", shares: 1620}, {ticker: "ATRFX", shares: 6230},…]
      // isDuplicate: true
      // name: "George Fitch"
      // refID: 1
      // updateData: true

      let holdings = payload.data.map(holding => {
        return { ...holding, ticker: holding.ticker.toUpperCase()}
      });

      let singleClient = [{
        holdings,
        account_number: '',
        custodian: '',
        existingClientID: '',
        name: payload.file_name || '',
        refID: 1,
        updateData: true,
      }];

      // console.log('===== singleClient =====');
      // console.log(singleClient);
      // console.log('========================');

      const response = yield call(sendClientListData, singleClient);
      // console.log(response);

      if (response &&
        response.uploaded &&
        response.skipped &&
        Array.isArray(response.skipped) &&
        Array.isArray(response.uploaded)
      ) {
        yield put(uploadSingleClientSuccess({
          singleClientUploaded: response.uploaded,
        }));
      }

      if (callback && typeof callback === 'function') {
        callback(response)
      }

    } else {
      throw "-- 'data' key is missing --"
    }
  } catch (error) {
    console.log(error)
    const errorDetails = get(error, 'response.data', error.stack)
    yield put(uploadSingleClientSuccess())
  }
}

// NOTE: 'Client List' in client accounts page <- First Time
function* accountPageClientList(action) {
  const { payload, callback } = action;
  try {
    yield put(accountPageClientListFetch());

    // NOTE: To set default client selected, use 'selectDefaultClient'
    const { selectDefaultClient } = payload;

    const result = yield call(fetchClientListByPageNumber, payload);
    // console.log(result);
    if (result && result.data) {
      const clientList = result.data;
      if (Array.isArray(clientList) && clientList.length > 0) {
        let currentDownloadedListState = yield select(state => state.clientportfolioV2.downloadedList);
        // let currentDownloadedList = (typeof currentDownloadedListState !== 'undefined' &&
        //                               typeof currentDownloadedListState.data !== 'undefined'
        //                             ) ? currentDownloadedListState.data : [];

        let defaultPageCount = payload.count;
        let totalClientList = result.total;
        const totalPageNumber = Math.round(totalClientList/defaultPageCount) || 1;
        const finalData = clientList; // [...currentDownloadedList, ...clientList];
        let firstRow = finalData[0];

        // NOTE: To set default client selected, use 'selectDefaultClient'
        if (selectDefaultClient && typeof selectDefaultClient.id !== 'undefined') {
          // console.log('selectDefaultClient----->', selectDefaultClient);
          firstRow = selectDefaultClient;
        }

        let { filter } = currentDownloadedListState;
        // NOTE: For Filter case, preserve total_asset/total_account
        // ------------------------------------------------------
        let _currentTotalAssets = 0;
        let _currentTotalAccounts = 0;
        // ---------------------if filter is not applied, use api value---------------------------------
        if (filter && filter.key !== '' && filter.query !== '') {
          _currentTotalAssets = yield select(state => state.clientportfolioV2.totalAssets);;
          _currentTotalAccounts = yield select(state => state.clientportfolioV2.totalAccounts);
        }
        // ------------------------------------------------------

        yield put(accountPageClientListSuccess({
          clientList: finalData, // used in old-logic
          downloadedList: {
            ...currentDownloadedListState,
            data: finalData,
            pagination: {
              totalPageNumber,
              count: defaultPageCount,
              totalListData: totalClientList,
              currentPageNumber: payload.page_number,
            },
          },
          uploadedClientList: [], // used in old logic
          clientListLoading: false, // used in old logic
          initClientList: finalData, // used in old-logic
          // NOTE: set totalAssets and totalAccounts value on first time
          // ------------------------------------------------------
          totalAssets: result.total_asset,
          totalAccounts: totalClientList,
          // ------------------------------------------------------
          // NOTE: preserve current total_asset/total_account
          // ------------------------------------------------------
          _currentTotalAssets,
          _currentTotalAccounts,
           // ------------------------------------------------------
          selectedClientDetails: firstRow,
        }));

        // NOTE: after loading client-list, initiate client-details-api
        // ------------------------------------------------------
        yield call(delay, 600);
        // ------------------------------------------------------

        yield put(
          getSelectedClientDetailsRequest({
            selectedRow: firstRow,
            query_var: {},
          })
        )

      } else if (Array.isArray(result.data) && result.data.length === 0) {
        // NOTE: Also check if no '0' client present in downloadedList/clientList then set `client_exist: false` and show 'uploadModal' on UI

        // NOTE:  below code added, is old-logic used for showing 'UploadModal' in client account page and helps page 'Header' logic
        // --------------------------------------------------------------------
        let advisorData = {
          client_exist: false,
          user_advisor_menu: window.localStorage.getItem('user_advisor_menu') || false
        }
        window.localStorage.setItem('client_exist', false)
        window.localStorage.setItem('user_advisor_menu', advisorData.user_advisor_menu)
        yield put(successfulSetAdvisorData(advisorData))
        // --------------------------------------------------------------------

        yield put(accountPageClientListSuccess({
          clientList: [], // used in old-logic
          downloadedList: {
            data: [],
            query: '',
            pagination: {
              count: 20,
              totalListData: 0,
              totalPageNumber : 0,
              currentPageNumber: 0,
            },
            filter: {
              key: '',
            },
            isSearchQueryBased: false,
            searchResultTotalAssets: 0,
          },
          uploadedClientList: [], // used in old logic
          clientListLoading: false, // used in old logic
          initClientList: [], // used in old-logic
          // NOTE: set totalAssets and totalAccounts value on first time
          // ------------------------------------------------------
          totalAssets: 0,
          totalAccounts: 0,
          // ------------------------------------------------------
          selectedClientDetails: [],
        }));

      } else {
        throw '-- Failed to process API response --'
      }

    } else {
      throw '-- Failed to Client List, check api response --'
    }

    if (callback && typeof callback === 'function') {
      callback(result)
    }
  } catch (error) {
    console.log(error)
    message.warning('Something went wrong while loading accounts', 5);
    yield put(accountPageClientListSuccess({
      clientList: [],
      totalClientList: 0,
      clientListLoading: false,  // old logic
    }));
    if (callback && typeof callback === 'function') {
      callback()
    }
  }
}

// NOTE: 'Search Bar' in client accounts page
function* getClientListBySearchedQuery(action) {
  const { payload, callback } = action;
  try {
    if (payload && typeof payload.q !== 'undefined') {
      const { selectDefaultClient } = payload;
      let _currentDLS = yield select(state => state.clientportfolioV2.downloadedList);
      // NOTE: Before searching query, reset downloadedList state
      yield put(getClientListBySearchQueryFetch({
        selectedClientDetails: [],
        downloadedList: {
          ..._currentDLS,
          data: [],
          query: '',
          pagination: {
            ..._currentDLS.pagination,
            count: 20,
            page_number: 0,
            totalPageNumber : 0,
            currentPageNumber: 0,
          },
          filter: {
            key: '',
          }
        },
      }));
      const response = yield call(searchQueryAndGetClientList, payload);
      if (response && response.client_list) {
        const clientList = response.client_list;
        if (Array.isArray(clientList) && clientList.length > 0) {
          let currentDownloadedListState = yield select(state => state.clientportfolioV2.downloadedList);
          let _searchResultsData = yield select(state => state.clientportfolioV2.searchResultsData);
          let defaultPageCount = payload.count;
          let totalClientList = response.total;
          const totalPageNumber = Math.round(totalClientList/defaultPageCount) || 1;
          let firstRow = clientList[0];

          // NOTE: To set default client selected, use 'selectDefaultClient'
          if (selectDefaultClient && typeof selectDefaultClient.id !== 'undefined') {
            // console.log('selectDefaultClient----->', selectDefaultClient);
            firstRow = selectDefaultClient;
          }

          let queryVar = response['query_var']; // (response['query_var'] && response['query_var'].length) ? response['query_var'][0] : {};
          yield put(getClientListBySearchQuerySuccess({
            clientList, // used in old-logic
            downloadedList: {
              ...currentDownloadedListState,
              data: clientList,
              query: payload.q,
              pagination: {
                totalPageNumber,
                count: defaultPageCount,
                totalListData: totalClientList,
                currentPageNumber: payload.page_number,
              },
              isSearchQueryBased: true,
              // NOTE: for query based listing set 'total_asset'
              // ------------------------------------------------------
              searchResultTotalAssets: response.total_asset,
              // ------------------------------------------------------
            },
            // NOTE: used in old-logic
            // ------------------------------------------------------
            accSearchQuery: payload.q, // used in old-logic
            searchResultsData: {
              ..._searchResultsData,
              ...response,
              client_list: _searchResultsData && _searchResultsData.client_list ? [..._searchResultsData.client_list, ...clientList] : clientList,
            }, // used in old-logic
            clientSearchStatus: true, // used in old-logic
            query_var: response.query_var || {}, // used in old-logic
            clientListLoading: false, // used in old-logic
            // ------------------------------------------------------
          }));

          // NOTE: after loading client-list, initiate client-details-api
          // ------------------------------------------------------
          yield call(delay, 600);
          // ------------------------------------------------------
          yield put(
            getSelectedClientDetailsRequest({
              selectedRow: firstRow,
              query_var: queryVar,
            })
          )

        } else if (Array.isArray(response.data) && response.data.length === 0) {
          // NOTE: Also check if no '0' client present in downloadedList/clientList then set `client_exist: false` and show 'uploadModal' on UI

          // NOTE:  below code added, is old-logic used for showing 'UploadModal' in client account page and helps page 'Header' logic
          // --------------------------------------------------------------------
          let advisorData = {
            client_exist: false,
            user_advisor_menu: window.localStorage.getItem('user_advisor_menu') || false
          }
          window.localStorage.setItem('client_exist', false)
          window.localStorage.setItem(
            'user_advisor_menu',
            advisorData.user_advisor_menu
          )
          yield put(successfulSetAdvisorData(advisorData))
          // --------------------------------------------------------------------

          yield put(getClientListBySearchQuerySuccess({
            clientList: [], // used in old-logic
            downloadedList: {
              data: [],
              query: '',
              pagination: {
                count: 20,
                totalListData: 0,
                totalPageNumber : 0,
                currentPageNumber: 0,
              },
              filter: {
                key: '',
              },
              isSearchQueryBased: false,
              searchResultTotalAssets: 0,
            },
            uploadedClientList: [], // used in old logic
            clientListLoading: false, // used in old logic
            initClientList: clientList, // used in old-logic

            // NOTE: used in old-logic
            // ------------------------------------------------------
            accSearchQuery: '', // used in old-logic
            searchResultsData: '', // used in old-logic
            clientSearchStatus: true, // used in old-logic
            query_var: {}, // used in old-logic
            // ------------------------------------------------------

          }));

        } else if(Array.isArray(clientList) && clientList.length === 0){
          yield put(getClientListBySearchQuerySuccess({
            // NOTE: used in old-logic
            // ------------------------------------------------------
            accSearchQuery: payload.q, // used in old-logic
            searchResultsData: '', // used in old-logic
            clientSearchStatus: false, // used in old-logic
            query_var: {}, // used in old-logic
            clientListLoading: false, // used in old-logic
            // ------------------------------------------------------
          }))
        } else {
          throw '-- Failed to process API response --'
        }
      } else {
        throw '-- Failed to process API response --'
      }
      if (callback && typeof callback === 'function') {
        callback(response)
      }
    } else {
      throw '-- payload missing, query is required parameter --'
    }
  } catch (error) {
    console.log(error)
    message.warning('Something went wrong while loading accounts', 5);
    yield put(getClientListBySearchQuerySuccess({
      // NOTE: used in old-logic
      // ------------------------------------------------------
      accSearchQuery: payload.q, // used in old-logic
      searchResultsData: '', // used in old-logic
      clientSearchStatus: false, // used in old-logic
      query_var: {}, // used in old-logic
      clientListLoading: false, // used in old-logic
      // ------------------------------------------------------
    }));
  }

}

// NOTE: 'On List Scroll' in client accounts page
function* downloadMoreData(action) {
  const { payload, callback } = action;
  try {
    if (payload) {
      yield put(downloadMoreDataFetch());

      let currentDownloadedListState = yield select(state => state.clientportfolioV2.downloadedList);
      let currentDownloadedList = (typeof currentDownloadedListState !== 'undefined' &&
                                    typeof currentDownloadedListState.data !== 'undefined'
                                  ) ? currentDownloadedListState.data : [];
      let _searchResultsData = yield select(state => state.clientportfolioV2.searchResultsData);

      let defaultPageCount = payload.count || 20;
      let response = null;
      if (payload.isSearchQueryBased) {
        // console.log('payload ---> ', payload);
        let _payload = payload;
        delete _payload.isSearchQueryBased;
        response = yield call(searchQueryAndGetClientList, _payload);
        if (response && response.client_list && Array.isArray(response.client_list) && response.client_list.length > 0) {
          const clientList = response.client_list;
          if (clientList && Array.isArray(clientList) && clientList.length) {
            let totalClientList = response.total;
            const totalPageNumber = Math.round(totalClientList/defaultPageCount) || 1;
            const finalData = [...currentDownloadedList, ...clientList];
            yield put(downloadMoreDataSuccess({
              clientList: finalData, // used in old-logic
              totalClientList,
              downloadedList: {
                ...currentDownloadedListState,
                data: finalData,
                query: payload.q,
                pagination: {
                  totalPageNumber,
                  count: defaultPageCount,
                  totalListData: totalClientList,
                  currentPageNumber: payload.page_number,
                },
                isSearchQueryBased: true,
              },
              // NOTE: used in old-logic
              // ------------------------------------------------------
              accSearchQuery: payload.q, // used in old-logic
              searchResultsData: {
                ..._searchResultsData,
                client_list: _searchResultsData && _searchResultsData.client_list ? [..._searchResultsData.client_list, ...response.client_list] : response.client_list,
              }, // used in old-logic
              clientSearchStatus: true, // used in old-logic
              query_var: response.query_var || {}, // used in old-logic
              clientListLoading: false, // used in old-logic
              // ------------------------------------------------------

            }));
          } else {
            throw '-- Failed to Client List, check api response --'
          }
        } else {
          throw '-- Failed to Client List, check api response --'
        }
      } else {
        response = yield call(fetchClientListByPageNumber, payload);
        if (response && response.data && Array.isArray(response.data) && response.data.length > 0) {
          const clientList = response.data;
          if (clientList && Array.isArray(clientList) && clientList.length) {
            let totalClientList = response.total || clientList.length;
            const totalPageNumber = Math.round(totalClientList/defaultPageCount) || 1;
            const finalData = [...currentDownloadedList, ...clientList];
            yield put(downloadMoreDataSuccess({
              clientList: finalData, // used in old-logic
              totalClientList,
              downloadedList: {
                ...currentDownloadedListState,
                data: finalData,
                pagination: {
                  totalPageNumber,
                  count: defaultPageCount,
                  totalListData: totalClientList,
                  currentPageNumber: payload.page_number,
                },
              },
              uploadedClientList: [], // used in old logic
              clientListLoading: false, // used in old logic
              initClientList: finalData, // used in old-logic
            }));
          } else {
            throw '-- Failed to Client List, check api response --'
          }
        } else {
          throw '-- Failed to Client List, check api response --'
        }
      }
      if (callback && typeof callback === 'function') {
        callback(response)
      }
    } else {
      throw '-- payload missing, query is required parameter --'
    }
  } catch (error) {
    console.log(error)
    message.warning('Something went wrong while loading accounts', 5);
    if (payload.isSearchQueryBased) {
      yield put(downloadMoreDataSuccess({
        // NOTE: used in old-logic
        // ------------------------------------------------------
        accSearchQuery: payload.q, // used in old-logic
        searchResultsData: '', // used in old-logic
        clientSearchStatus: false, // used in old-logic
        query_var: {}, // used in old-logic
        clientListLoading: false, // used in old-logic
        // ------------------------------------------------------
      }));
    } else {
      yield put(downloadMoreDataSuccess({
        clientList: [],
        totalClientList: 0,
        clientListLoading: false,  // old logic
      }));
    }
  }
}

// NOTE: 'Selected Client details' in account page
function* getClientDetailsData(action) {
  const { payload, callback } = action;
  try {
    if (payload && typeof payload.selectedRow !== 'undefined') {
      const { selectedRow } = payload;
      // NOTE: Before searching query, reset downloadedList state
      yield put(getSelectedClientDetailsFetch({
        clientChartDetails: '',
        selectedClientDetails: selectedRow,
      }));

      let _clientChartDetails = '';
      let _selectedClientDetails = yield select(state => state.clientportfolioV2.selectedClientDetails);
      let client_id = selectedRow.id;

      let holdingsPayload = {
        client_id,
        query_var: payload.query_var || {},
      };

      let chartsPayload = {
        account_id: client_id,
      }

      let [holdingsResponse, chartsResponse] = yield all([
        call(fetchClientHoldingsData, holdingsPayload),
        call(fetchClientChartDetails, chartsPayload),
      ]);

      // console.log(holdingsResponse);
      // console.log(chartsResponse);

      if (holdingsResponse && !Array.isArray(holdingsResponse) && Object.keys(holdingsResponse)) {
        if (_selectedClientDetails && !Array.isArray(_selectedClientDetails) && _selectedClientDetails.id === holdingsResponse.id) {
          _selectedClientDetails = {
            ..._selectedClientDetails,
            ...holdingsResponse,
          };
        } else {
          _selectedClientDetails = holdingsResponse;
        }
      } else {
        console.log('-- Failed to load holdingsResponse --');
      }
      if (chartsResponse && !Array.isArray(chartsResponse) && Object.keys(chartsResponse)) {
        _clientChartDetails = chartsResponse;
      } else {
        console.log('-- Failed to load chartsResponse --');
      }

      yield put(getSelectedClientDetailsSuccess({
        selectedClientDetails: _selectedClientDetails,
        clientChartDetails: _clientChartDetails,
      }));

      if (callback && typeof callback === 'function') {
        callback({ selectedClientDetails: _selectedClientDetails, clientChartDetails: _clientChartDetails })
      }

      // if (response && response.client_list && Array.isArray(response.client_list) && response.client_list.length > 0) {
      //
      //   const clientList = response.client_list;
      //   // console.log('------ clientList --------');
      //   // console.log(clientList);
      //   // console.log('--------------------------');
      //   if (clientList && Array.isArray(clientList) && clientList.length) {
      //
      //     // let currentDownloadedListState = yield select(state => state.clientportfolioV2.downloadedList);
      //     // let _searchResultsData = yield select(state => state.clientportfolioV2.searchResultsData);
      //     // let defaultPageCount = payload.count;
      //     // let totalClientList = response.total;
      //     // const totalPageNumber = Math.round(totalClientList/defaultPageCount) || 1;
      //     // yield put(getSelectedClientDetailsSuccess({
      //     //
      //     // }));
      //
      //   } else {
      //     throw '-- Failed to get client details data, check api response --'
      //   }
      // } else {
      //   throw '-- Failed to get client details data, check api response  --'
      // }
      // if (callback && typeof callback === 'function') {
      //   callback(response)
      // }
    } else {
      throw '-- payload missing, selectedRow is required parameter --'
    }
  } catch (error) {
    console.log(error)
    message.warning('Something went wrong while loading accounts', 5);
    yield put(getSelectedClientDetailsSuccess({
      selectedClientDetails: [],
      clientChartDetails: '',
    }));
  }
}

// NOTE: 'Filter' request for client list in account page
function* filterDownloadedList(action) {
  const { payload, callback } = action;
  try {
    if (payload && typeof payload.key !== 'undefined' && typeof payload.query !== 'undefined') {
      yield put(filterDownloadedListFetch());
      let downloadedList = yield select(state => state.clientportfolioV2.downloadedList);
      if (downloadedList) {
        // let selectDefaultClient = (action.payload && action.payload.length > 0) ? action.payload[0] : action.payload;
        const { pagination, query } = downloadedList;
        if (query) {
          // NOTE: if filter is applied based on search-query
          yield put(
            getClientListBySearchQueryRequest({
              // selectDefaultClient,
              q: query,
              page_number: 1,
              filter_options: payload.key,
              filter_query: payload.query,
              count: pagination.count,
            })
          );
        } else {
          // NOTE: if filter is applied on normal client list
          yield put(
            accountPageClientListRequest({
              // selectDefaultClient,
              page_number: 1,
              filter_options: payload.key,
              filter_query: payload.query,
              count: pagination.count,
            })
          );
        }
      }
      yield put(filterDownloadedListSuccess({
        ...payload,
        filter: {
          key: payload.key,
          query: payload.query,
        }
      }));
      if (callback && typeof callback === 'function') {
        callback()
      }
    } else {
      throw '-- payload missing, "key" and "query" are required --'
    }
  } catch (error) {
    console.log(error)
    yield put(filterDownloadedListSuccess({
      pageCrash: 'Failed to process filter request.'
    }));
  }
}

// NOTE: 'RESET Filter' request for client list in account page
function* resetFilterDownloadedList(action) {
  const { payload, callback } = action;
  try {
    yield put(resetFilterDownloadedListFetch());
    let downloadedList = yield select(state => state.clientportfolioV2.downloadedList);
    if (downloadedList) {
      // let selectDefaultClient = (action.payload && action.payload.length > 0) ? action.payload[0] : action.payload;
      const { pagination, query } = downloadedList;
      if (query) {
        // NOTE: if filter is applied based on search-query
        yield put(
          getClientListBySearchQueryRequest({
            // selectDefaultClient,
            q: query,
            page_number: 1,
            count: pagination.count,
          })
        );
      } else {
        // NOTE: if filter is applied on normal client list
        yield put(
          accountPageClientListRequest({
            // selectDefaultClient,
            page_number: 1,
            count: pagination.count,
          })
        );
      }
    }
    yield put(resetFilterDownloadedListSuccess());
    if (callback && typeof callback === 'function') {
      callback()
    }
  } catch (error) {
    console.log(error)
    yield put(resetFilterDownloadedListSuccess({
      pageCrash: 'Failed to process filter request.'
    }));
  }
}

function* fetchPortfolioOptions(action) {
  try {
    const [
      customPortfolioList = [],
      sponsoredPortfolioList = [],
      upDownDataSet = []
    ] = yield all([
      call(fetchPortfolioList),
      call(fetchMarketPlaceList),
      call(fetchUpAndDownChart)
    ])
    upDownDataSet.forEach(v => {
      if (v.ticker === '0 ACWI/100 AGG') {
        v.ticker = 'AGG'
      }
      if (v.ticker === '100 ACWI/0 AGG') {
        v.ticker = 'ACWI'
      }
    })
    let upDownDataSetFormatted = [
      ...upDownDataSet.slice(0, 2),
      upDownDataSet[upDownDataSet.length - 1],
      ...upDownDataSet.slice(2, upDownDataSet.length - 1)
    ]
    yield put(
      portfolioOptionsSuccess([
        customPortfolioList,
        sponsoredPortfolioList,
        upDownDataSetFormatted
      ])
    )
  } catch (error) {
    console.log(error)
  }
}

function* clientPortfolioV2Saga() {
  yield all([
    fork(takeEvery, ActionTypes.UPLOAD_CLIENT_LIST, uploadClient),
    fork(takeEvery, ActionTypes.SEND_CLIENT_LIST, sendClientList),
    fork(takeEvery, ActionTypes.REQUEST_CLIENT_LIST, fetchClientList),
    // fork(takeEvery, ActionTypes.REQUEST_CLIENT_DELETE, deleteClients),
    // fork(takeEvery, ActionTypes.REQUEST_CLIENT_DETAILS, fetchClientDetails),
    fork(takeEvery, ActionTypes.REQUEST_CLIENT_BENCHMARK, sendBenchmarkData),
    fork(takeEvery, ActionTypes.REMOVE_CLIENT_BENCHMARK,removeBenchmarkData),
    // fork(takeEvery, ActionTypes.REQUEST_CHART_DETAILS, fetchChartDetails),
    fork(takeEvery, ActionTypes.REQUEST_CLIENT_HOLDINGS, fetchClientHoldings),
    fork(takeEvery, ActionTypes.REQUEST_PF_HOLDINGS, fetchPfHoldings),
    fork(takeEvery, ActionTypes.REQUEST_CLEAR_HOLDINGS, clearHoldings),
    fork(takeEvery, ActionTypes.REQUEST_PORTFOLIO_OPTIONS, fetchPortfolioOptions),
    fork(takeEvery, ActionTypes.SET_SELECTED_PORTFOLIO, setSelectedPortfolio),
    fork(takeEvery, ActionTypes.RESET_CLIENT_LIST, resetClientList),
    fork(takeEvery, ActionTypes.REQUEST_GENERATE_TRADELIST, fetchTradelist),
    fork(takeEvery, ActionTypes.REQUEST_SEARCH_ACCOUNT_FUNDS, fetchSearchAccFunds),
    fork(takeEvery,ActionTypes.REQUEST_MODEL_SEARCH_RESULTS,fetchModelSearchResults),
    fork(takeEvery, ActionTypes.UPDATE_RESTRICTED_TICKERS_REQUEST, updateRestrictedTickers),
    fork(takeEvery, ActionTypes.FINALIZE_ALLOCATION_REQUEST, sendFinalAllocation),
    fork(takeEvery, ActionTypes.UPDATE_TAX_RATES, updateTaxRates),
    fork(takeEvery, ActionTypes.REQUEST_TAX_BILLS, fetchTaxBills),
    fork(takeEvery, ActionTypes.UPDATE_SELECTED_TAX_BILL, updateSelectedTaxBill),
    fork(takeEvery, ActionTypes.UPLOAD_SINGLE_CLIENT_REQUEST, uploadSingleClientCSV),
    fork(takeEvery, ActionTypes.ACCOUNTPAGE_CLIENT_LIST_REQUEST, accountPageClientList),
    fork(takeEvery, ActionTypes.GET_CLIENT_LIST_BY_QUERY_REQUEST, getClientListBySearchedQuery),
    fork(takeEvery, ActionTypes.DOWNLOAD_MORE_DATA_REQUEST, downloadMoreData),
    fork(takeEvery, ActionTypes.GET_SELECTED_CLIENT_DETAILS_REQUEST, getClientDetailsData),
    fork(takeEvery, ActionTypes.DELETE_CLIENT_ACCOUNT_REQUEST, deleteClients),
    fork(takeEvery, ActionTypes.FILTER_CLIENT_LIST_REQUEST, filterDownloadedList),
    fork(takeEvery, ActionTypes.RESET_FILTER_CLIENT_LIST_REQUEST, resetFilterDownloadedList),
  ])
}

export default clientPortfolioV2Saga

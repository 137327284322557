import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash'
import { UncontrolledTooltip } from 'reactstrap';

import { createLoadingSelector } from 'selectors/loading-selector';
import { newQuery, searchQueryRequest, setFundsState, getTrendingFeeds } from 'actions/discovery';
import { fundsApiCallTime, clearFundsApiLoadPercent } from 'actions/global';
import { subscriptionPlan, setProfileState } from 'actions/profile';
import { addTickersToQuickTradeRequest } from 'actions/quicktrade';
import { clearRelatedInsightData } from 'actions/plugins';

import EmbedSection from 'components/EmbedSection';
import PButton from 'components/PButton';
import CustomAlert from 'components/CustomAlert';
import { searchUrlV3, addEventToAnalytics, clearWindowUtmSession } from 'Utils';
import { SeoLinks, REDIRECT_DOMAIN_PATH } from 'DataSet';
import { CHECK_QUICK_TRADE } from 'layouts/utils';

import TopFeeds from './Elements/TopFeeds';
import DownloadExcel from './Elements/DownloadExcel';
import { ShareFunds } from './Elements/ShareFunds';
import Plugins from './Elements/Plugins';
import SearchBox from './Elements/SearchBox';
import UniverseSection from './Elements/UniverseSection';

import { getQueryItems, customScroll } from 'layouts/utils'

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEmbedOpen: false,
      isNotifyOpen: false,
      download: false,
      alert: false,
      premiumModal: false,
      notify: false,
      premiumFeature: 'false',
    };
  }

  componentDidMount() {
    window.sessionStorage.removeItem('search_type');
    const id = this.props.match.params.id;
    const { history : { location } } = this.props;
    let params = new URLSearchParams(location.search);
		if (location && CHECK_QUICK_TRADE()) {
			if (params.get('buy') &&
				params.get('fundPrice') &&
				params.get('fundIsSponsored') &&
				params.get('fundSponsorLogo') &&
				params.get('fundName') &&
				params.get('fundCategory') &&
				params.get('fundMI') &&
				params.get('fundVehicle')
			) {
				let _fundTicker = params.get('buy');
				let _fundPrice = params.get('fundPrice');
				let _fundIsSponsored = params.get('fundIsSponsored');
				let _fundSponsorLogo = params.get('fundSponsorLogo');
				let _fundName = params.get('fundName');
				let _fundCategory = params.get('fundCategory');
				let _fundMI = params.get('fundMI');
				let _fundVehicle = params.get('fundVehicle');
				// let qury = params.get('query');
				let utm_campaign = params.get('utm_campaign');
				let utm_medium = params.get('utm_medium');
				let utm_source = params.get('utm_source');
				let utm_term = params.get('utm_term');
				let utm_content = params.get('utm_content');
				let utm_usage_log_id = params.get('utm_usage_log_id');
				let utm_token = params.get('utm_token');
				const quickTradeObj = {
					utm_term,
					utm_token,
					utm_medium,
					utm_source,
					utm_content,
					utm_campaign,
					ticker: _fundTicker,
					price: _fundPrice,
					vehicle: _fundVehicle,
					isSponsored: _fundIsSponsored,
					sponsorLogo: _fundSponsorLogo,
					name: _fundName,
					category: _fundCategory,
					minInvestment: _fundMI,
					logId: utm_usage_log_id,
				};
				this.quickTrade(quickTradeObj);
			}
		}
    if (params.get('search_type')) {
      window.sessionStorage.setItem('search_type', params.get('search_type'));
    }
		const { getTrendingFeeds } = this.props;
		const _q = getQueryItems(this.props);
		if (getTrendingFeeds && ((id && id != '') || (_q && _q !== null))) {
			getTrendingFeeds();
    }
    let queryFrom = window.sessionStorage.getItem('queryFrom');
    if (queryFrom !== 'acc') {
      if (id && id !== '') {
        const index = SeoLinks.findIndex(x => x.name.toLowerCase() == id.toLowerCase());
        if (index >= 0) {
          this.queryHandler(SeoLinks[index].query);
        } else {
          window.location.href = REDIRECT_DOMAIN_PATH;
          return;
        }
      } else {
        const { query: _discoveryState } = this.props;
        const _q = getQueryItems(this.props) || _discoveryState;
        if (_q && _q !== null) {
          this.queryHandler(_q);
        } else {
          console.log('-- query is missing in URL params and in discovery state --');
        }
      }
    }
    window.sessionStorage.removeItem('queryFrom')
  }

  componentDidUpdate() {
    customScroll();
		const staticElement = document.getElementById("static-elements");
    const headerElement = document.getElementById("magnifi-header-wrapper");
		if (staticElement && headerElement) {
			const scrollableElement = document.getElementById("scrollable-elements");
			if (scrollableElement) {
				let heightForList = headerElement.offsetHeight + staticElement.offsetHeight + 20 +"px"; // 20 is padddingTop for itemListHeaderWrapper
				scrollableElement.style.height = `calc(98vh - ${heightForList})`;
			}
		}
    // NOTE: Reset sidebar Clout Plugin when query is not present in URL
    // NOTE: DO-NOT clear or reset discovery state, it is used in SFM items and tracking activities.
    // --------------------------------------------------------------------------------------------
    if (isEmpty(getQueryItems(this.props))) {
      const { clearRelatedInsightData, newQuery, query } = this.props;
      if (newQuery && query !== '') {
        newQuery('');
        if (clearRelatedInsightData) {
          clearRelatedInsightData();
        }
      }
    }
    // --------------------------------------------------------------------------------------------
  }

  componentWillUnmount () {
    const { clearRelatedInsightData, newQuery } = this.props;
    if (newQuery) {
      newQuery('');
      if (clearRelatedInsightData) {
        clearRelatedInsightData();
      }
    }
  }

	quickTrade = (payload) => {
		const { addTickersToQuickTradeRequest } = this.props;
		if (addTickersToQuickTradeRequest && payload) {
			addTickersToQuickTradeRequest(payload)
		} else {
			console.log('== payload missing for quickTrade ==');
		}
	}

  shareTwitter = () => {
    const { query } = this.props;
    if (query) {
      const url = `${window.location.origin}/securities?query=${encodeURIComponent(query)}`;
      const text = `I searched for "${query}" on Magnifi.  Here's a link to the results.\n \n`;
      query && window.open('http://twitter.com/share?url='+encodeURIComponent(url)+'&text='+escape(text), '', 'left=0,top=0,width=750,height=550,personalbar=0,toolbar=0,scrollbars=0,resizable=0');
    }
  }

  shareMail = () => {
    const { query } = this.props;
    if (query) {
      const subject = `Shared from Magnifi`;
      const body = `I searched for "${query}" on Magnifi.  Here's what I found\n \n${window.location.origin}/securities?query=${encodeURIComponent(query)} \n \n \n \n \n \n`;
      query && window.open(`mailto:?subject=${encodeURIComponent(subject)}&body=${escape(body)}`, '_self');
    }
  }

  isEmbedClose = () => {
    this.setState({ isEmbedOpen: false });
  }

  copylink = () => {
    let copyText = document.getElementById('embedText');
    copyText.select();
    document.execCommand("copy");
    this.setState({ isNotifyOpen: true });
    setTimeout(() => { this.setState({ isNotifyOpen: false }) }, 2000);
  }

  checkRoutes = (query, clearUTMSession) => {
    // // console.log('sidebar checkRoutes --> ', query)
    // const { match, history } = this.props;
    // // const query = window.sessionStorage.getItem('query');
    // // if (loggedIn && isTermsAgreed === 0 && query) return;
    // if (!match.params.id || match.params.id == '') {
		// 	if (clearUTMSession) {
		// 		clearWindowUtmSession();
		// 	}
    //   if (history) {
    //     history.push(searchUrlV3(query));
    //   }
		// }

    const { history } = this.props;
    if (history) {
      if (clearUTMSession) {
        clearWindowUtmSession();
      }
      history.push(searchUrlV3(query));
    }
  }

  queryHandler = (query, search_type, clearUTMSession = false, extra) => {
		const { discovery } = this.props;
    if (query) {
      if(!search_type && window.sessionStorage.getItem('search_type')) {
        search_type = window.sessionStorage.getItem('search_type');
        window.sessionStorage.removeItem('search_type');
      }
      if(search_type == 'trending_searches') {
        addEventToAnalytics('Trending Searches', 'Trending Searches', 'TRENDING_SEARCHES', { clicked_query: query }, true);
      }
			if (search_type === 'related_searches') {
				addEventToAnalytics('Related Searches', 'Related Searches', 'RELATED_SEARCHES', { query: discovery.query, logId: discovery.logId, relatedQuery: query }, true);
			}
      const { searchQueryRequest, setFundsState, clearFundsApiLoadPercent } = this.props;
      clearFundsApiLoadPercent();
      setFundsState({
        actions: true,
        currentPage: 1,
      });
      this.checkRoutes(query, clearUTMSession);
      searchQueryRequest({
        query,
        search_type,
        extra,
      });
    }
  }

  tagDesign = (relatedArr) => {
    const sortRelated = relatedArr.sort((a, b) => {
      return a.length - b.length; // DESC -> b.length - a.length // ASC  -> a.length - b.length
    });
    const renderDesign = sortRelated.map((item, index) => {
      // const { discovery } = this.props;
      return (
        <div
          key={item+index}
          className="tag"
          title={item}
          onClick={() => this.queryHandler(item, 'related_searches', true)}>
          {item}
        </div>
      );
    });
    return renderDesign;
  }

  onClickShare = (e) => {
    switch(e.currentTarget.dataset.item) {
      case 'Embed':
        addEventToAnalytics('Share Blog','Share Blog','SHARE_BLOG',{},true);
        this.setState({ isEmbedOpen: true });
        break;

      case 'Mail' :
        addEventToAnalytics('Share Email','Share Email','SHARE_EMAIL',{},true);
        this.shareMail();
        break;

      case 'Twitter' :
        addEventToAnalytics('Share Twitter','Share Twitter','SHARE_TWITTER',{},true);
        this.shareTwitter();
        break;

      default :
        break;
    }
  }

  shareTwitter = () => {
    const { query } = this.props;
    if (query) {
      const url = `${window.location.origin}/securities?query=${encodeURIComponent(query)}`;
      const text = `I searched for "${query}" on Magnifi.  Here's a link to the results.\n \n`;
      query && window.open('http://twitter.com/share?url='+encodeURIComponent(url)+'&text='+escape(text), '', 'left=0,top=0,width=750,height=550,personalbar=0,toolbar=0,scrollbars=0,resizable=0');
    }
  }

  shareMail = () => {
    const { query } = this.props;
    if (query) {
      const subject = `Shared from Magnifi`;
      const body = `I searched for "${query}" on Magnifi.  Here's what I found\n \n${window.location.origin}/securities?query=${encodeURIComponent(query)} \n \n \n \n \n \n`;
      query && window.open(`mailto:?subject=${encodeURIComponent(subject)}&body=${escape(body)}`, '_self');
    }
  }

  isEmbedClose = () => {
    this.setState({ isEmbedOpen: false });
  }

  copylink = () => {
    let copyText = document.getElementById('embedText');
    copyText.select();
    document.execCommand("copy");
    this.setState({ isNotifyOpen: true });
    setTimeout(() => { this.setState({ isNotifyOpen: false }) }, 2000);
  }

	openDownloadExcel = () => {
		const { discovery } = this.props;
		if (!isEmpty(getQueryItems(this.props)) && discovery.data.length > 0) {
			addEventToAnalytics('Download Excel','Download Excel','DOWNLOAD_EXCEL',{},true);
			this.setState({ download: true, alert: false });
		}
  }

  openDownloadExcelTestDrive = (value) => {
    this.setState({download: value, premiumFeature: value});
  }

  onCloseExcel = () => {
    this.setState({ download: false });
  }

  onDownloadExcelComplete = () => {
    this.setState({
      alert:!this.state.alert,
    })
  }

  openNotify = () => {
    this.setState({
      notify: true,
    });
  }

  notifyToggle = () => {
    this.setState({
      notify: !this.state.notify,
    });
  }

  closePremiumModal = () => {
    this.setState({
      premiumModal: false,
    });
  }

  premiumModalToggle = () => {
    this.setState({
      premiumModal: !this.state.premiumModal,
    });
  }

  render() {
    const { query, discovery, loading, loggedIn, trendingQueries, smallScreen, surveyFormConfirmation, surveyModelOpen } = this.props;
    const { related } = discovery;

    // NOTE: Hide sidebar plugins (Related & Trending Searches and Clout Plugin) when query is not present in URL
    // --------------------------------------------------------------------------------------------
    const SEARCH_QUERY_MISSING = (isEmpty(getQueryItems(this.props)));
    // console.log('sidebar SEARCH_QUERY_MISSING -->', SEARCH_QUERY_MISSING, query)
    // --------------------------------------------------------------------------------------------

    const renderTagDesign = (!SEARCH_QUERY_MISSING && related.length) ? (
      <div className="element">
        <div className="label">Related Searches</div>
        <div className="tagWrapper">
          {this.tagDesign(related)}
        </div>
      </div>
    ) : null;

		const tendringQueryList = (!SEARCH_QUERY_MISSING && trendingQueries && trendingQueries.length) ? trendingQueries.map((item) => {
			if (typeof item.query !== 'undefined') {
				return item.query
			}
		}).filter((t) => typeof t !== 'undefined') : null;

		const feedsQueries = tendringQueryList ? JSON.parse(JSON.stringify(tendringQueryList)) : [];
		const initialList = tendringQueryList ? JSON.parse(JSON.stringify(tendringQueryList.splice(0, 4))) : [];

    return (
      <Fragment>
        {(this.state.alert)
          && (
            <CustomAlert className="alert-windown-wrapper" color='info'>
              File Downloaded Successfully
            </CustomAlert>
          )
        }
        <div className="sideBarWrapper">
					<div id="static-elements">
						<div className="element">
							<div className="label">What do you want to invest in?</div>
							<div className="searchInput">
								<SearchBox
									query={query}
                  fromSecurities={true}
									history={this.props.history}
									loggedIn={loggedIn}
                  surveyModelOpen={surveyModelOpen}
                  surveyFormConfirmation={surveyFormConfirmation}
                  universe={discovery.universe}
                  queryHandler={(query, search_type, extra) => this.queryHandler(query, search_type, true, extra)}
								/>
							</div>
						</div>
						<div className="universe-container element">
							<UniverseSection
								queryHandler={(query, search_type) => this.queryHandler(query, search_type, true)}
								query={query}
								history={this.props.history}
							/>
						</div>
            {(!loading && !SEARCH_QUERY_MISSING) && (
              <div className="element" style={{ display:'flex' }}>
                <ShareFunds onClickShare={this.onClickShare} />
                {(!smallScreen) && (
                  <>
                    <div className="label" style={{ display: process.env.REACT_APP_SUB_DOMAIN === 'advisor' ? 'flex' : 'none', alignItems: 'center', margin: '0 0 0 15px' }}> Download
                      <PButton type="div" featureName="download_excel" premiumAction={true} onClick={this.openDownloadExcel} style={{ cursor:'pointer', marginLeft:'7px', marginTop:'-3px' }} id="download">
                        <img src="/assets/images/Download-Excel_Icon.svg" style={{height:'25px'}}/>
                      </PButton>
                    </div>
                    {this.state.download &&
                      <DownloadExcel query={query} onCloseClick={this.onCloseExcel} onDownload={this.onDownloadExcelComplete} />
                    }
                    <UncontrolledTooltip placement="bottom" target="download" className="share-component__tooltip">
                      Save results to a spreadsheet
                    </UncontrolledTooltip>
                  </>
                )}
                <EmbedSection
                  isEmbedOpen={this.state.isEmbedOpen}
                  isEmbedClose={this.isEmbedClose}
                  copylink={this.copylink}
                  isNotifyOpen={this.state.isNotifyOpen}
                  notifyClassName="notify-card"
                  query={query}
                />
              </div>
            )}
						{(!loading && smallScreen) && (
							<div className="element" style={{ display:'flex', justifyContent: 'space-between' }}>
								<div className="label" style={{ display: process.env.REACT_APP_SUB_DOMAIN === 'advisor' ? 'flex' : 'none', alignItems: 'center' }}> Download
									<PButton type="div" featureName="download_excel" premiumAction={true} onClick={this.openDownloadExcel} style={{ cursor:'pointer', marginLeft:'7px', marginTop:'-1px' }} id="download">
										<img src="/assets/images/Download-Excel_Icon.svg" style={{height:'25px'}}/>
									</PButton>
								</div>
								{this.state.download &&
									<DownloadExcel query={query} onCloseClick={this.onCloseExcel} onDownload={this.onDownloadExcelComplete} />
								}
								<UncontrolledTooltip placement="bottom" target="download" className="share-component__tooltip">
									Save results to a spreadsheet
								</UncontrolledTooltip>
							</div>
						)}
					</div>
          <div id="scrollable-elements" className="sidebar-content custom-scroll elementPenal">
            {!loading &&
              <Fragment>
                {(!loading && !SEARCH_QUERY_MISSING && feedsQueries && feedsQueries.length > 0)
                  && (
                    <div className="element">
                      <div className="label">Trending Searches <span className="sub-label">(click on any to see results)</span></div>
                      <TopFeeds
												initialList={initialList}
                        queryList={feedsQueries}
                        queryHandler={(query, search_type) => this.queryHandler(query, search_type, true)}
                      />
                    </div>
                  )
                }
								{renderTagDesign}
                <Plugins
									executeCall={this.executeCall}
                  history={this.props.history}
									queryHandler={(query, search_type) => this.queryHandler(query, search_type, true)}
								/>
              </Fragment>
            }
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ auth, discovery, router, profile, global, loading, trendingSearches }) => ({
	discovery,
  query: discovery.query,
  loading: createLoadingSelector(['SEARCH_QUERY'])({ loading }) || discovery.actions,
  fundsLoader: discovery.loading,
	trendingQueries: trendingSearches.searches, // discovery.trendingQueries,
  location: router.location,
  loggedIn: auth.loggedIn,
  premiumUser: auth.user.premiumUser,
  inviteSuccess:profile.inviteSuccess,
  inviteSentTo:profile.inviteSentTo,
	smallScreen: global.smallScreen,
})

const mapDispatchToProps = {
  newQuery,
	getTrendingFeeds,
  searchQueryRequest,
  setFundsState,
  fundsApiCallTime,
  clearFundsApiLoadPercent,
  subscriptionPlan,
  setProfileState,
  clearRelatedInsightData,
	addTickersToQuickTradeRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideBar)

import React, { useState } from 'react'
import moment from 'moment';
import { Container, Col, Row, Form, FormGroup, Label, Input, Tooltip, DropdownItem, DropdownMenu } from 'reactstrap'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd';
import {
  CountrySelect,
  CustomFormFeedback,
  DatePicker
} from 'shared-components'
import { whereDidYouHear } from '../../layouts/WebDashboard/Constant/FormData'

export default function EditableProfileForm(props) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [showSelfRoleModal, setShowSelfRoleModal] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);


  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue
  } = props.formProps

  const handleCancelSelfRole = () => {
    setShowSelfRoleModal(!showSelfRoleModal);
  }

  console.log(values.date_of_birth ? ((typeof values.date_of_birth.getMonth === 'function') ? values.date_of_birth : moment(values.date_of_birth)._d) : values.date_of_birth, showSelfRoleModal);

  return (
    <div className="profile-editable-form">
    <Modal
          visible={showSelfRoleModal}
          closable={false}
          centered={true}
          footer={null}
          width="auto"
          title={null}
          style={{ height: 'auto' }}
          maskStyle={true}
          bodyStyle={{ minHeight: 212}}
          wrapClassName={`select-domain-popup-container self-role-popup-container`}
            >
            <div className="context-wrapper">
              <h3>Attention!</h3>
              <p>You are registered as {values.self_role}.</p>
              <p style={{ marginBottom: 5 }}>{process.env.REACT_APP_SUB_DOMAIN === 'retail' ? 'Would you like to register as an advisor?' : 'Would you like to register for a retail trading account?'}</p>
            </div>
            <div className="btn-wrap d-flex w-100 justify-content-center">
              <Button
                className="primary-btn"
                onClick={() => handleCancelSelfRole()}
              >
                
                <a id="mail-to-magnifi" href='mailto:support@magnifi.com'>
                  Yes,
                  <br />
                  Contact support
                </a>
              </Button>
              <Button
                className="secondary-btn"
                onClick={() => handleCancelSelfRole()}
              >
                NO,
                <br />
                CANCEL
              </Button>
            </div>
          </Modal>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="first_name">First Name<i className="profile__section-title-required-label">*</i></Label>
            <Input
              type="text"
              name="first_name"
              id="first_name"
              value={values.first_name}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.first_name && touched.first_name ? 'is-invalid' : ''
              }
            />

            <CustomFormFeedback
              formProps={props.formProps}
              fieldName="first_name"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="last_name">Last Name<i className="profile__section-title-required-label">*</i></Label>
            <Input
              type="text"
              name="last_name"
              id="last_name"
              value={values.last_name}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.last_name && touched.last_name ? 'is-invalid' : ''
              }
            />

            <CustomFormFeedback
              formProps={props.formProps}
              fieldName="last_name"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="address">Address</Label>
            <Input
              type="text"
              name="address"
              id="address"
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.address && touched.address ? 'is-invalid' : ''}
            />
            <CustomFormFeedback
              formProps={props.formProps}
              fieldName="address"
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="city">City</Label>
            <Input
              type="text"
              name="city"
              id="city"
              value={values.city}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.city && touched.city ? 'is-invalid' : ''}
            />
            <CustomFormFeedback formProps={props.formProps} fieldName="city" />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="state">State</Label>
            <Input
              type="text"
              name="state"
              id="state"
              value={values.state}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.state && touched.state ? 'is-invalid' : ''}
            />
            <CustomFormFeedback formProps={props.formProps} fieldName="state" />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="zipcode">Zip</Label>
            <Input
              type="text"
              name="zipcode"
              id="zipcode"
              value={values.zipcode}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.zipcode && touched.zipcode ? 'is-invalid' : ''}
            />
            <CustomFormFeedback
              formProps={props.formProps}
              fieldName="zipcode"
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="country">Country</Label>
            <CountrySelect
              value={values.country}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.country && touched.country ? 'is-invalid' : ''}
            />
            <CustomFormFeedback
              formProps={props.formProps}
              fieldName="country"
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="phone">Phone</Label>
            <Input
              type="text"
              name="phone"
              id="phone"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.phone && touched.phone ? 'is-invalid' : ''}
            />
            <CustomFormFeedback formProps={props.formProps} fieldName="phone" />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="gender">Gender</Label>
            <Input
              type="select"
              name="gender"
              id="gender"
              value={values.gender}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.gender && touched.gender ? 'is-invalid' : ''}
            >
              <option value="">
                Select
              </option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </Input>
            <CustomFormFeedback
              formProps={props.formProps}
              fieldName="gender"
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="date_of_birth">Birth Date</Label>
            <DatePicker
              id="date_of_birth"
              selected={values.date_of_birth ? ((typeof values.date_of_birth.getMonth === 'function') ? values.date_of_birth : moment(values.date_of_birth)._d) : values.date_of_birth}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              maxDate={new Date()}
              autoComplete="off"
              onChange={(dateInstance, dateString) => {
                setFieldValue('date_of_birth', dateInstance || null)
              }}
              className="form-control"
            />

            {/*<Input
              type="text"
              name="dateOfBirth"
              id="dateOfBirth"
              placeholder="MM-DD-YYYY"
              value={values.dateOfBirth}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.dateOfBirth && touched.dateOfBirth ? 'is-invalid' : ''
              }
            />*/}
            <CustomFormFeedback
              formProps={props.formProps}
              fieldName="date_of_birth"
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="hear_aboutus">Where did you hear about us?</Label>
            <Input
              type="select"
              name="hear_aboutus"
              id="hear_aboutus"
              value={values.hear_aboutus}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.hear_aboutus && touched.hear_aboutus ? 'is-invalid' : ''
              }
            >
              <option value="">Select</option>
              {whereDidYouHear.map((item) => <option value={item}>{item}</option>)}
            </Input>
            <CustomFormFeedback
              formProps={props.formProps}
              fieldName="hear_aboutus"
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="investable_assets">Investable Assets</Label>
            <Input
              type="select"
              name="investable_assets"
              id="investable_assets"
              value={values.investable_assets}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.investable_assets && touched.investable_assets
                  ? 'is-invalid'
                  : ''
              }
            >
              <option value="">Select</option>
              {/* <option value={values.investable_assets}>{values.investable_assets === '' ? 'Select' : values.investable_assets}</option> */}
              <option value="Under $250,000">Under $250,000</option>
              <option value="$250,000 to $500,000">$250,000 to $500,000</option>
              <option value="$500,000 to $2,000,000">$500,000 to $2,000,000</option>
              <option value="More than $2,000,000">More than $2,000,000</option>
            </Input>
            <CustomFormFeedback
              formProps={props.formProps}
              fieldName="investable_assets"
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs={10}>
          <FormGroup>
            <Label for="self_role">Are you an</Label>
            <Input
              type="select"
              name="self_role"
              id="self_role"
              value={values.self_role}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.self_role && touched.self_role ? 'is-invalid' : ''
              }
              disabled
            >

              {/* <option value="">Select</option> */}
              <option value={values.self_role}>{values.self_role === '' ? 'Select' : values.self_role}</option>
              <option value="RIA">RIA</option>
              <option value="Hybrid Advisor">Hybrid Advisor</option>
              <option value="Broker/Dealer/Wirehouse Advisor">Broker/Dealer/Wirehouse Advisor</option>
              <option value="Asset Manager">Asset Manager</option>
              <option value="Hedge Fund Manager">Hedge Fund Manager</option>
              <option value="Portfolio Manager">Portfolio Manager</option>
              <option value="Corporate/Business Development">Corporate/Business Development</option>
              <option value="Institutional Investor">Institutional Investor</option>
              <option value="Individual Investor">Individual Investor</option>
              {/* <option value="Other/Curious">Other/Curious</option> */}
            </Input>
            {/* <CustomFormFeedback
              formProps={props.formProps}
              fieldName="self_role"
            /> */}
          </FormGroup>
        </Col>
        <Col xs={2}>
          {values.self_role && <div style={{height: '100%', display: 'flex', alignItems: "center"}} ><i className="fas fa-pencil" onClick = {()=> handleCancelSelfRole()}></i></div>}
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="share_class">Preferred MF Share Class</Label>
            <Input
              type="select"
              name="share_class"
              id="share_class"
              value={values.share_class}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.share_class && touched.share_class ? 'is-invalid' : ''
              }
            >
              <option value="A Class">A class</option>
              <option value="Institutional Class">Institutional class</option>
              <option value="Retail Class">Retail class </option>
            </Input>
            <CustomFormFeedback
              formProps={props.formProps}
              fieldName="share_class"
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="estimated_net_worth">Assets Under Management/ Advice</Label>
            <Input
              type="select"
              name="estimated_net_worth"
              id="estimated_net_worth"
              value={values.estimated_net_worth}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.estimated_net_worth && touched.estimated_net_worth ? 'is-invalid' : ''
              }
            >
              <option value="">Select</option>
              {/* <option value={values.estimated_net_worth}>{values.estimated_net_worth === '' ? 'Select' : values.estimated_net_worth}</option> */}
              <option value="Under $10 million">Under $10 million</option>
              <option value="$10 million to $50 million">$10 million to $50 million</option>
              <option value="$50 million to $100 million">$50 million to $100 million</option>
              <option value="$100 million to $500 million">$100 million to $500 million</option>
              <option value="$500 million to $1 billion">$500 million to $1 billion</option>
              <option value="$1 billion to $2.5 billion">$1 billion to $2.5 billion</option>
              <option value="$2.5 billion to $5 billion">$2.5 billion to $5 billion</option>
              <option value="$5 billion to $10 billion">$5 billion to $10 billion</option>
              <option value="More than $10 billion">More than $10 billion</option>
            </Input>
            <CustomFormFeedback
              formProps={props.formProps}
              fieldName="estimated_net_worth"
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <FormGroup className="element-box pos-rel pl-0 pb-0" check>
            <div className="toggle-switch-wrapper">
              <div className="toggle-content label">Are you an accredited investor? <span id="aggregatedInvestorTooltip"><i style={{ paddingRight: '5px' }} className="fal fa-info-circle"></i></span></div>
              <Label check className="register-form__term-cond-label toggle-switch">
                <Input
                  type="checkbox"
                  name="accredited_investor"
                  id="accredited_investor"
                  className={
                    errors.accredited_investor && touched.accredited_investor
                      ? 'register-form__checkbox is-invalid input-area toggle-switch-input'
                      : 'register-form__checkbox input-area toggle-switch-input'
                  }
                checked={values.accredited_investor}
                 onChange={handleChange}
                />
                <span className="slider round"></span>
              </Label>
              <Tooltip placement="right" isOpen={tooltipOpen} target="aggregatedInvestorTooltip" toggle={toggle}>
                A person or a business entity who is allowed to deal in securities that may not be registered with financial authorities like the SEC.
              </Tooltip>
            </div>
            <CustomFormFeedback
              formProps={props.formProps}
              fieldName="accredited_investor"
            />
            {errors.accredited_investor && touched.accredited_investor && <div className="form-invalid-field">{errors['accredited_investor']}</div>}
          </FormGroup>
        </Col>
      </Row>

      <br />

    </div>
  )
}

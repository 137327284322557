import React, { Component } from 'react';
import { Dropdown, DropdownToggle } from 'reactstrap';
import { FilterOptions } from './common';

const classNames = require('classnames');

class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
    };

    this.toggle = this.toggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  onMouseEnter() {
    this.setState({ dropdownOpen: true });
  }

  onMouseLeave() {
    this.setState({ dropdownOpen: false });
  }

  render() {
    const { filtersActive, filterAttrs } = this.props;
    const filterActiveLen = Object.keys(filtersActive);
    const filterValue = (filterActiveLen.length &&  filterActiveLen.includes(filterAttrs)) ? true : false;
    return (
      <Dropdown
        direction="left"
        isOpen={this.state.dropdownOpen}
        onMouseOver={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        toggle={() => {}}
        className={filterValue ? "select-col" : ""}
      >
        <DropdownToggle
          caret={false}
          className="nav-link dropdown-button"
          color="link"
        >{this.props.filterAttrs}</DropdownToggle>
        <FilterOptions {...this.props} />
      </Dropdown>
    )
  }
}

export default Filter;

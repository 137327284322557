import React from 'react';
import { withRouter } from 'react-router-dom';

import Navbar from '../Navbar';

var classNames = require('classnames');

export default class TopSection extends React.Component {
  constructor(props) {
    super(props);
    this.secRef = React.createRef();
    this.top = true;
  }

  componentDidMount() {
    document.body.classList.add('main', 'ht5');
  }

  componentWillUnmount() {
    document.body.classList.remove('main', 'ht5');
  }

  queryHandler = (query) => {
    this.props.queryHandler(query);
    //this.props.history.push('/securities');
  }

  render() {
    const { modalToggleState } = this.props;
    return (
      <section id="top-bar" ref={this.secRef} className={classNames("fixed-top bg-ssf-gray-l3 sm", modalToggleState ? 'blur-effect' : '')}>
        <Navbar height="40" onLogout={this.props.logoutHandler} isLoggedIn={this.props.loggedIn} {...this.props}/>
        <SearchBoxWithRouter queryHandler={this.props.queryHandler} query={this.props.query} />
        { this.props.toolbar }
      </section>
    );
  }
}


class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };
  }

  componentWillReceiveProps(newProps) {
    newProps.query && this.setState({ value: newProps.query });
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
    // action
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.value.trim().length > 0) {
      this.props.queryHandler(this.state.value.trim());
      this.props.history.push('/securities');
    }
  }

  clearSearch = () => {
    this.setState({
      value: '',
    });
    document.getElementById("fund-search-box").focus();
  }

  render() {
    return (
      <section className="d-flex align-items-center dynamic e1">
        <div className="container">
          <form onSubmit={this.handleSubmit}>
            <div className={classNames("input-group h2 mb-0 search-box")}>
              <input
                id="fund-search-box"
                value={this.state.value}
                onChange={this.handleChange}
                className="form-control form-control-lg rounded bg-white"
                type="text"
                placeholder="What do you want to invest in?"
                autocomplete="off"
                aria-label="Search"
                style={{ paddingRight: (this.state.value && this.state.value.length > 0) ? '60px' : '40px' }}
              />
              {(this.state.value && this.state.value.length > 0) && (
                <i className={`far fa-times fa-lg text-secondary clear-icon ${this.state.clearSearch ? '' : ''} ${this.state.active ? 'active' : ''}`}
                  onClick={this.clearSearch} />
              )}
              <i className="far fa-fw fa-search fa-lg text-secondary search-icon"></i>
            </div>
          </form>
        </div>
      </section>
    );
  }
}
const SearchBoxWithRouter = withRouter(SearchBox);

import { takeEvery, fork, call, put, all, select } from 'redux-saga/effects'

import moment from 'moment';
import get from 'lodash/get';
import { mapDataFromSFMItems } from 'layouts/utils';
import ActionTypes from '../action-types';
import { investmentFetchSuccess, dropFundFromWhislist, setInvestmentState, investmentOptions, investmentFetchRequest, getClientListFetch, getClientListSuccess, getHomeOfficeModelsFetch, getHomeOfficeModelsSuccess, getRealTimePriceForTickersFetch, getRealTimePriceForTickersSuccess } from '../actions/investment';
import { setPortfolioState } from '../actions/portfolio';
import { getSFMItems } from '../selectors/sfm';
import { getInvestmentRetData, getInvestmentUploadPartial, getAllInvestmentState,getPortfolioSelectedBenchmark } from '../selectors/investment'
import { fetchAccountSummary, fetchAccountPositions, fetchPortfolioList, fetchMarketPlaceList, getMarketResultAPI, uploadPortfolioAPI, getPortfolioResultAPI, deletePortfolioAPI, downloadPortfolioReportPDF,downloadRecEnhancerReportPDF, demoPortfolioAPI, getClientList, getHomeOfficeModelsData } from '../apis/account';
import { fetchReturnsData, fetchReturnsDataV1, trackFundsRemove, fetchRealTimePrices, fetchRealTimePricesNav ,fetchUpAndDownChart} from '../apis/funds';
import { uniqValues, getNestDefault, nameToDateRange, arrayToHash } from '../Utils';
import { accountsPageUpdatePrice } from 'layouts/utils';
import { DateRange, cn, CN, } from '../DataSet'
import { calcCumulativeReturns } from '../Stats';
import { saveDemoPortfolio } from '../actions/investment';
import { upDownData } from '../DummyData'
import { getPortfolioListFetch, getPortfolioListSuccess } from 'actions/enhancer'
import { getPortfolioListBuilderFetch, getPortfolioListBuilderSuccess } from 'actions/investment'
const FileSaver = require('file-saver');

// const DateRangeMoment = { start: moment(DateRange.start), end: moment(DateRange.end) }

function* setInvState(action) {
  const { payload } = action;
  try {
    if (payload) {
      yield put(setInvestmentState(payload));
    }
  } catch (error) {
    console.log('error ', error)
    // yield put(setInvestmentState({ message }))
  }
}

function* dropFund(action) {
  const { payload } = action;
  try {
    // const response = { message: 'done' };
    const response = yield call(trackFundsRemove, payload);
    if (response) {
      yield put(setInvestmentState({
        dropFundResponse: response,
        droppedFund: true,
      }));
    }
  } catch (error) {
    console.log('error ', error)
    // yield put(setInvestmentState({ message }))
  }
}

function* downloadPDF(action) {
  const { payload } = action;
  try {
    if (payload) {
      yield put(
        setInvestmentState({
          reportLoader: true,
        })
      );

      const response = yield call(downloadPortfolioReportPDF, { json: JSON.stringify(payload) });
      if (response) {
        // console.log('!!!!! REPORT API RESPONSE !!!!!!');
        // console.log(response);

        const newBlob = new Blob([response.data], { type: response.headers['content-type'] });
        const currDate = moment().format('MMMM YYYY');
        const firstName = yield select(state => state.auth.user.firstName) || '';
        const lastName = yield select(state => state.auth.user.lastName) || '';
        const fullName = firstName + ' ' + lastName;
        FileSaver.saveAs(newBlob, fullName+' – Enhancer Proposal – '+currDate+'.pdf');

        yield put(
          setInvestmentState({
            reportPDFDownloadStatus: {
              message: 'PDF Downloaded successfully',
              downloadStatus: 'success',
            },
          })
        );
        // yield put(setPortfolioState({ enhanceReplace: [], enhanceTickerFilter: [], resultSet: [], filterResultSet: [] }))
      }
      yield put(
        setInvestmentState({
          reportLoader: false,
        })
      );
    }
  } catch (error) {
    const errorDetails = get(error, 'response.data', error.stack)
    console.log(errorDetails)
    yield put(
      setInvestmentState({
        reportLoader: false,
        reportPDFDownloadStatus: {
          message: 'Failed to download Report PDF file. Please email us at: help@magnifi.com',
          downloadStatus: 'danger',
        },
      })
    );
    // yield put(actions.failedToPerform(errorDetails))
  }
}
function* downloadRecEnhancerPDF(action) {
  const { payload } = action;
  try {
    if (payload) {
      yield put(
        setInvestmentState({
          reportLoader: true,
        })
      );
      console.log(payload)
      // const response = yield call(downloadRecEnhancerReportPDF, { json: JSON.stringify(payload) });
      // if (response) {
      //   // console.log('!!!!! REPORT API RESPONSE !!!!!!');
      //   // console.log(response);
      //
      //   const newBlob = new Blob([response.data], { type: response.headers['content-type'] });
      //   const currDate = moment().format('MMMM YYYY');
      //   const firstName = yield select(state => state.auth.user.firstName) || '';
      //   const lastName = yield select(state => state.auth.user.lastName) || '';
      //   const fullName = firstName + ' ' + lastName;
      //   FileSaver.saveAs(newBlob, fullName+' – Enhancer Proposal – '+currDate+'.pdf');
      //
      //   yield put(
      //     setInvestmentState({
      //       reportPDFDownloadStatus: {
      //         message: 'PDF Downloaded successfully',
      //         downloadStatus: 'success',
      //       },
      //     })
      //   );
      //   // yield put(setPortfolioState({ enhanceReplace: [], enhanceTickerFilter: [], resultSet: [], filterResultSet: [] }))
      // }
      yield put(
        setInvestmentState({
          reportLoader: false,
        })
      );
    }
  } catch (error) {
    const errorDetails = get(error, 'response.data', error.stack)
    console.log(errorDetails)
    yield put(
      setInvestmentState({
        reportLoader: false,
        reportPDFDownloadStatus: {
          message: 'Failed to download Report PDF file. Please email us at: help@magnifi.com',
          downloadStatus: 'danger',
        },
      })
    );
    // yield put(actions.failedToPerform(errorDetails))
  }
}
function* investmentInfo(action) {
  //try {
  yield put(investmentFetchRequest());
	const [ _summary, _positions, portfolioList = [], marketPlaceList = [] ,upDownDataSet=[]] = yield all([
      call(fetchAccountSummary),
      call(fetchAccountPositions),
      call(fetchPortfolioList),
      call(fetchMarketPlaceList),
			call(fetchUpAndDownChart)
    ]);
	upDownDataSet.forEach(v=>{
		if(v.ticker==='0 ACWI/100 AGG'){v.ticker = 'AGG'}
		if(v.ticker==='100 ACWI/0 AGG'){v.ticker = 'ACWI'}
	})
  let upDownDataSetFormatted = [...upDownDataSet.slice(0,2),upDownDataSet[upDownDataSet.length-1],...upDownDataSet.slice(2,upDownDataSet.length-1)]
	let { results: funds = [], broker_cash_balance: brokerCb = []} = _summary;
    let { positions = [] } = _positions;
    let brokers = uniqValues(brokerCb, 'broker__name');
    let portfolioRetData, retData = [];

    if (funds.length > 0) {
      retData = [];
      let ticks1 = positions.map(e => e.symbol),
          ticks2 = funds.filter(e => e.transaction_type === 'TRACK').map(e => e.script);
      let ticks = [...new Set([...ticks1, ...ticks2])].join(',');
      // retData = yield call(fetchReturnsData, { tickers: ticks })
      retData = yield call(fetchReturnsDataV1, { tickers: ticks })

      // const reduxRetData = yield select(getInvestmentRetData);
      // if (reduxRetData) {
      //   const portRetData = reduxRetData && reduxRetData.length > 0 && reduxRetData.filter(x => x._portfolioFund)
      //   portRetData.length > 0 && retData.push(...portRetData)
      // }

      const fundsPrice = yield call(fetchRealTimePrices, { tickers: ticks })

      if (fundsPrice) {
        yield put(setInvestmentState({
          realTimePrice: fundsPrice,
        }));

        const updatedFundsPriceArr = accountsPageUpdatePrice(fundsPrice.funds, funds, 'script');
        const updatedPositionsPriceArr = accountsPageUpdatePrice(fundsPrice.funds, positions, 'symbol');
        if (updatedPositionsPriceArr) {
          positions = updatedPositionsPriceArr.funds;
        }
        if (updatedFundsPriceArr) {
          funds = updatedFundsPriceArr.funds;
        }
      }

      portfolioRetData = yield select(getInvestmentRetData);
      if(portfolioRetData && portfolioRetData.length > 0) {
        portfolioRetData = portfolioRetData.filter(x => x._portfolioFund || x._marketFund);
      }
      portfolioRetData && portfolioRetData.length > 0 && retData.push(...portfolioRetData);
      const investmentStates = yield select(getAllInvestmentState);
      // console.log(investmentStates);
      retData.forEach((e,index) => {
        let n = getNestDefault(['returns', 'length'], e, 0);
        if (n > 0){
          const start = moment(new Date(`01 ${e.returns[e.returns.length - 1].d}`));
          const end = moment(new Date(`01 ${e.returns[0].d}`));
          const DateRangeMoment = { start, end };

          e.returns.forEach((e, i) => {
            if (investmentStates.view === 'watchlist' || investmentStates.view === '') {
              retData[index].returns[i].v = retData[index].returns[i].v / 100
            }
            return e.v = +e.v
          })
          e._start = e.returns[0]['d'];
          e._end = e.returns[n-1]['d'];
          let stats = calcCumulativeReturns(e.returns, nameToDateRange('l3y', DateRangeMoment));
          e._series = stats.series;
          e._selected = false;
        }
      });
    }

    const reducerSfmItems = yield select(getSFMItems);
    retData.forEach(e => {
      let i = reducerSfmItems.findIndex(s => cn(s, 'Ticker') === cn(e, 'Ticker'));
      if (i >= 0) {
        e._selected = true;
      }
    });

    let tickerWiseRet = arrayToHash(retData, CN['Ticker']);
    funds.forEach((e,index) => {
      e.return = tickerWiseRet[e.symbol || e.script]
    });

    positions.forEach((e,index) => {
      e.return = tickerWiseRet[e.symbol || e.script]
    });
    const benchmark = yield select(getPortfolioSelectedBenchmark)
		let selectedBenchmark =  benchmark || 'SPY'
		yield put(setPortfolioState({ selectedBenchmark,upDownDataSet :upDownDataSetFormatted}));
    yield put(investmentFetchSuccess({ funds, brokers, brokerCb, positions, retData, portfolioList, marketPlaceList}))
      const {callback} = action;
      if (callback && typeof callback == 'function') {
        callback();
      }

  //}
}

// Portfolio Section
export function* uploadPortfolio(data) {
  const { payload, callback } = data
  try {
    if (payload) {
      yield put(investmentFetchSuccess({ portfolioLoading: true }));
      const response = yield call(uploadPortfolioAPI, payload);
      // console.log(response);
      if (response.message === "Portfolio uploaded successfully." && response.portfolio_name) {
        yield call(fetchPortfoliofileTicker, { filename: response.portfolio_name, callback})
        // const portfolioList = yield call(fetchPortfolioList);
        // if (portfolioList.length > 0) {
        //   yield put(investmentFetchSuccess({ portfolioList, uploadfileDuplicate: false, uploadfileIncomplete: false, portfolioLoading: false }))
        // }
        yield put(investmentFetchSuccess({ uploadfileDuplicate: false, uploadfileIncomplete: false, portfolioLoading: false }))
      } else if (response.message === "File already Exist") {
        yield put(investmentFetchSuccess({ uploadfileDuplicate: true, portfolioLoading: false }));
      } else if (response.status === 'Fail' || response.message.includes('Failed')) {
        yield put(investmentFetchSuccess({ portfolioLoading: false, uploadFileFailure: true, uploadFileFailureMsg: response.message }));
      } else if (response.status === 'Partial' && response.portfolio_name) {
        yield put(investmentFetchSuccess({ uploadFilePartial: true, uploadFilePartialMsg: response.message }));
        yield call(fetchPortfoliofileTicker, { filename: response.portfolio_name, callback})
        // const portfolioList = yield call(fetchPortfolioList);
        // if (portfolioList.length > 0) {
        //   yield put(investmentFetchSuccess({ portfolioList, portfolioLoading: false }));
        // }
        yield put(investmentFetchSuccess({ portfolioLoading: false }));
      }
    }
  } catch (error) {
    yield put(investmentFetchSuccess({ portfolioLoading: false }));
    const errorDetails = get(error, 'response.data', error.stack)
    console.log(errorDetails)
    // yield put(actions.failedToPerform(errorDetails))
  }
}

function* fetchPortfolioData(data) {
  const { payload, callback } = data;
  try {
    yield put(investmentFetchSuccess({ portfolioLoading: true, filename: payload }));
    yield call(fetchPortfoliofileTicker, { filename: payload, callback});
    yield put(investmentFetchSuccess({ portfolioLoading: false }));
  } catch (error) {
    yield put(investmentFetchSuccess({ portfolioLoading: false }));
    const errorDetails = get(error, 'response.data', error.stack)
    console.log(errorDetails)
    // yield put(actions.failedToPerform(errorDetails))
  }
}

function* fetchPortfoliofileTicker(payload) {
  const { filename, callback } = payload
  try {
    const reduxRetData = yield select(getInvestmentRetData);
    const retData = reduxRetData.filter(x => !x._portfolioFund)
    let portfolioFunds = [];
    const portfolioResult = yield call(getPortfolioResultAPI, filename);
    if (portfolioResult.message && portfolioResult.message === "Failed") {
      yield put(setPortfolioState({ onBoardScreen: true }));
    } else if (portfolioResult.length > 0 ) {
      portfolioFunds = portfolioResult;
      let ticks1 = portfolioResult.map(e => e.script);
      let ticks = [...new Set([...ticks1])].join(',');
      // const portfolioRetData = yield call(fetchReturnsData, { tickers: ticks })
	    const [portfolioRetData, fundsPrice] = yield all([
		    call(fetchReturnsDataV1, { tickers: ticks }),
		    call(fetchRealTimePrices, { tickers: ticks }),
	    ])



      // const portfolioRetData = yield call(fetchReturnsDataV1, { tickers: ticks })
      // const fundsPrice = yield call(fetchRealTimePrices, { tickers: ticks })

      yield put(setInvestmentState({realTimePrice: fundsPrice}));
      portfolioRetData.forEach((e,index) => {
        if (e.returns.length > 0) {
          let n = getNestDefault(['returns', 'length'], e, 0);
          if (n > 0){
            const start = moment(new Date(`01 ${e.returns[e.returns.length - 1].d}`));
            const end = moment(new Date(`01 ${e.returns[0].d}`));
            const PortfolioDateRangeMoment = { start, end };
            e.returns.forEach((e,i) => {portfolioRetData[index].returns[i].v =  portfolioRetData[index].returns[i].v/100; return e.v = +e.v})
            e._start = e.returns[0]['d'];
            e._end = e.returns[n-1]['d'];
            let stats = calcCumulativeReturns(e.returns, nameToDateRange('l3y', PortfolioDateRangeMoment));
            e._series = stats.series;
            e._selected = false;
            e._portfolioFund = true;
          }
        }
      });

      retData.push(...portfolioRetData);
      const reducerSfmItems = yield select(getSFMItems);
      retData.forEach(e => {
        let i = reducerSfmItems.findIndex(s => cn(s, 'Ticker') === cn(e, 'Ticker'));
        if (i >= 0) {
          e._selected = true;
        }
      });
      let tickerWiseRet = arrayToHash(retData, CN['Ticker']);
      let purchaseValuesCheck = [];

      portfolioFunds.forEach((e,index) => {
        let realPriceTicker = [];
        if (e.type === 'ETF' || e.type === 'ETN') {
          realPriceTicker = fundsPrice.funds.filter(x => x.ticker === e.script)
          if (realPriceTicker.length > 0 ) e._price = (realPriceTicker[0].price * parseFloat(e.shares))
          else {
            realPriceTicker.push({ ticker: e.script, price: e.current_price })
            e._price = (e.current_price * parseFloat(e.shares))
          }
        } else {
          realPriceTicker.push({ ticker: e.script, price: e.current_price });
          if (realPriceTicker.length > 0 ) e._price = (realPriceTicker[0].price * parseFloat(e.shares))
        }

        e._portfolioFund = true;
        e.symbol = e.script;
        e.transaction_date = e.purchase_date;
        e._realTimePrice = realPriceTicker[0].price;
        e.quantity = e.shares;
        e.price = e._price;
        e.market_value = realPriceTicker[0].price * e.shares;
        e.return = tickerWiseRet[e.symbol]

         // to be commented out later
        //  e.purchase_price = null;
        //  e.transaction_date = null;

         if (e.purchase_price == null && e.purchase_date == null) purchaseValuesCheck.push(e.script);
        // purchaseValuesCheck.push(e.script);
      });

      const checkPurchaseValues = purchaseValuesCheck.length == portfolioFunds.length;

      yield put(investmentFetchSuccess({ portfolioFunds, retData, checkPurchaseValues, portfolioTickers: ticks, portfolioRetData: portfolioRetData[0].returns }));
      yield put(setPortfolioState({ summaryDrawer: false }));
      const UploadPartial = yield select(getInvestmentUploadPartial);
      if (UploadPartial == false) yield put(setPortfolioState({ onBoardScreen: false }));

      const opts = { view: 'uplPrt', activeAccount: filename, filename, acView: 'default' }
      yield put(investmentOptions(opts));
      if (callback && typeof callback == 'function') {
        callback();
      }
    }
  } catch (error) {
    const errorDetails = get(error, 'response.data', error.stack)
    console.log(errorDetails)
    // yield put(actions.failedToPerform(errorDetails))
  }
}

function* deletePortfolio(data) {
  const { payload, callback } = data;
  try {
    yield put(investmentFetchSuccess({ portfolioLoading: true }));
    const response = yield call(deletePortfolioAPI, payload);
    if (response.message === "File deleted successfully") {
      const portfolioList = yield call(fetchPortfolioList);
      if (portfolioList.length > 0) {
        yield put(investmentFetchSuccess({ portfolioList, portfolioLoading: false }))
      } else if (portfolioList.message === "No File Present.") {
        // let portfolioFunds = yield select(getPortfolioFunds);
        // portfolioFunds
        yield put(investmentFetchSuccess({ portfolioList: [], portfolioLoading: false }))
      }
    }
    callback && typeof callback == 'function' && callback();
  } catch (error) {
    yield put(investmentFetchSuccess({ portfolioLoading: false }));
    const errorDetails = get(error, 'response.data', error.stack)
    console.log(errorDetails)
    // yield put(actions.failedToPerform(errorDetails))
  }
}

function* fetchMarketData(data) {
  const { payload, callback } = data;
  try {
    yield put(investmentFetchSuccess({ portfolioLoading: true, filename: payload }));
    let marketFunds = [];
    const marketResult = yield call(getMarketResultAPI, payload);
    if (marketResult.length > 0) {
      const reduxRetData = yield select(getInvestmentRetData);
      const retData = reduxRetData.filter(x => !x._marketFund)
      marketFunds = marketResult;
      let ticks1 = marketResult.map(e => e.script);
      let ticks = [...new Set([...ticks1])].join(',');
      // const marketRetData = yield call(fetchReturnsData, { tickers: ticks })

      const [ marketRetData, fundsPrice ] = yield all([
        call(fetchReturnsDataV1, { tickers: ticks }),
        call(fetchRealTimePrices, { tickers: ticks })
      ]);

      // const marketRetData = yield call(fetchReturnsDataV1, { tickers: ticks })
      // const fundsPrice = yield call(fetchRealTimePrices, { tickers: ticks })

      marketRetData.forEach((e,index) => {
        let n = getNestDefault(['returns', 'length'], e, 0);
        if (n > 0){
          const start = moment(new Date(`01 ${e.returns[e.returns.length - 1].d}`));
          const end = moment(new Date(`01 ${e.returns[0].d}`));
          const MarketDateRangeMoment = { start, end };
          e.returns.forEach((e,i) => {marketRetData[index].returns[i].v =  marketRetData[index].returns[i].v/100; return e.v = +e.v})
          e._start = e.returns[0]['d'];
          e._end = e.returns[n-1]['d'];
          let stats = calcCumulativeReturns(e.returns, nameToDateRange('l3y', MarketDateRangeMoment));
          e._series = stats.series;
          e._selected = false;
          e._marketFund = true;
        }
      });

      retData.push(...marketRetData);
      const reducerSfmItems = yield select(getSFMItems);
      retData.forEach(e => {
        let i = reducerSfmItems.findIndex(s => cn(s, 'Ticker') === cn(e, 'Ticker'));
        if (i >= 0) {
          e._selected = true;
        }
      });
      let tickerWiseRet = arrayToHash(retData, CN['Ticker']);

      marketFunds.forEach((e,index) => {
        let realPriceTicker = [];
        if (e.type === 'ETF' || e.type === 'ETN') {
          realPriceTicker = fundsPrice.funds.filter(x => x.ticker === e.script)
          if (realPriceTicker.length > 0 ) e._price = (realPriceTicker[0].price * parseFloat(e.shares))
          else {
            realPriceTicker.push({ ticker: e.script, price: e.current_price })
            e._price = (e.current_price * parseFloat(e.shares))
          }
        } else {
          realPriceTicker.push({ ticker: e.script, price: e.current_price });
          if (realPriceTicker.length > 0 ) e._price = (realPriceTicker[0].price * parseFloat(e.shares))
        }

        e._marketFund = true;
        e.symbol = e.script;
        // e.transaction_date = e.purchase_date;
        e._realTimePrice = realPriceTicker[0].price;
        e.quantity = e.shares;
        e.price = e._price;
        e.market_value = realPriceTicker[0].price * e.shares;
        e.return = tickerWiseRet[e.symbol]

         // to be commented out later
        //  e.purchase_price = null;
        //  e.transaction_date = null;

        //  if (e.purchase_price == null && e.purchase_date == null) purchaseValuesCheck.push(e.script);
        // purchaseValuesCheck.push(e.script);
      })

      // const checkPurchaseValues = purchaseValuesCheck.length == portfolioFunds.length;

      yield put(investmentFetchSuccess({ marketFunds, retData, marketTickers: ticks, portfolioLoading: false }));
      yield put(setPortfolioState({ onBoardScreen: false }));

      callback && typeof callback == 'function' && callback();

      // const opts = { view: 'uplPrt', activeAccount: filename, acView: 'default' }
      // yield put(investmentOptions(opts));


    }

  } catch (error) {
    yield put(investmentFetchSuccess({ portfolioLoading: false }));
    const errorDetails = get(error, 'response.data', error.stack)
    console.log(errorDetails)
    // yield put(actions.failedToPerform(errorDetails))
  }
}

function* demoPortfolio(action) {
  try {
    const response = yield call( demoPortfolioAPI );
    // console.log(response);
    if (response.message === "Portfolio uploaded successfully.") {
      yield call(fetchPortfoliofileTicker, { filename: response.portfolio_name})
      // const portfolioList = yield call(fetchPortfolioList);
      // if (portfolioList.length > 0) {
      //   yield put(investmentFetchSuccess({ portfolioList, uploadfileDuplicate: false, uploadfileIncomplete: false, portfolioLoading: false }))
      // }
      yield put(investmentFetchSuccess({ uploadfileDuplicate: false, uploadfileIncomplete: false, portfolioLoading: false }))
      if (response.id && response.portfolio_name) {
        yield put(saveDemoPortfolio({
          demoPortfolioName: response.portfolio_name,
          portfolioId: response.id,
        }));
      }
    }
  }
  catch (error) {
    console.log(error)
  }
}

// function* getClientListData(action) {
//   try {
//     yield put(getClientListFetch());
//     const response = yield call( getClientList );
//     if (response) {
//       yield put(getClientListSuccess({
//         clientList: response,
//       }));
//     } else {
//       yield put(getClientListSuccess({
//         clientList: [],
//       }));
//     }
//   }
//   catch (error) {
//     console.log(error)
//   }
// }

function* redirectInvestmentOptions(action) {
  try {
     const { payload, callback } = action;
     yield put(investmentOptions({...payload}));
     callback();
  }
  catch (error) {
    console.log(error)
  }
}

function* getRealTimePriceForTickers(action) {
  try {
     const { payload, callback } = action;
     yield put(getRealTimePriceForTickersFetch())
     const realTimeTickerPrice = yield call(fetchRealTimePricesNav, { tickers: payload })
     callback(realTimeTickerPrice);
     yield put(getRealTimePriceForTickersSuccess())
  }
  catch (error) {
    console.log(error)
  }
}

function* getHomeOfficeModelsList(action) {
  try {
    yield put(getHomeOfficeModelsFetch());
    const response = yield call( getHomeOfficeModelsData );
    if (response) {
      yield put(getHomeOfficeModelsSuccess({
        homeOfficeModelsList: response,
      }));
    } else {
      yield put(getHomeOfficeModelsSuccess({
        homeOfficeModelsList: [],
      }));
    }
  }
  catch (error) {
    console.log(error)
  }
}

function* getPortfolioListData(action) {
  try {
    yield put(getPortfolioListBuilderFetch());
    const response = yield call(fetchPortfolioList);
    if (response) {

      yield put(getPortfolioListBuilderSuccess({
        portfolioList: response,
      }));
    } else {
      yield put(getPortfolioListBuilderSuccess({
        portfolioList: [],
      }));
    }
  }
  catch (error) {
    console.log(error)
    yield put(getPortfolioListSuccess({
      portfolioList: [],
      pageCrash: 'Failed to load query.',
    }));
  }
}
function* investmentSaga() {
  yield all([
    fork(takeEvery, ActionTypes.INVESTMENT_FETCH, investmentInfo),
    fork(takeEvery, ActionTypes.DROP_FUND, dropFund),
    fork(takeEvery, ActionTypes.FETCH_PORTFOLIO_DATA, fetchPortfolioData),
    fork(takeEvery, ActionTypes.FETCH_MARKET_DATA, fetchMarketData),
    fork(takeEvery, ActionTypes.UPLOAD_PORTFOLIO, uploadPortfolio),
    fork(takeEvery, ActionTypes.DELETE_PORTFOLIO, deletePortfolio),
    fork(takeEvery, ActionTypes.DOWNLOAD_PORTFOLIO_REPORT, downloadPDF),
    fork(takeEvery, ActionTypes.DOWNLOAD_REC_ENHANCER_REPORT, downloadRecEnhancerPDF),
    fork(takeEvery, ActionTypes.SET_DEMO_PORTFOLIO, demoPortfolio),
    fork(takeEvery, ActionTypes.INVESTMENT_OPTIONS_REDIRECT, redirectInvestmentOptions),
    // fork(takeEvery, ActionTypes.GET_CLIENT_LIST_REQUEST, getClientListData),
    fork(takeEvery, ActionTypes.GET_REAL_TIME_PRICE_TICKERS_REQUEST, getRealTimePriceForTickers),
    fork(takeEvery, ActionTypes.GET_HOME_OFFICE_MODELS_REQUEST, getHomeOfficeModelsList),
    fork(takeEvery, ActionTypes.GET_PORTFOLIO_LIST_BUILDER_REQUEST, getPortfolioListData),
  ])
}

export default investmentSaga

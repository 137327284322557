import React from 'react';
import { connect } from 'react-redux';

import { Row, Col, Button } from 'reactstrap';
import { addEventToAnalytics } from 'Utils';

import { BarChartPL } from 'components/charts/BarCharts'

import { SelectorDD, KeyStats3, KeyStats4, NoData } from './Common'

export const PortfolioValue = (props) => {
  const stats = [
    { name: 'Value Of Fund Holdings', value: props.stats.portfolioValue },
    { name: 'Securities Balance', value: props.stats.fundBalance },
    { name: 'Cash Balance', value: props.stats.cashBalance },
  ]
  return (
    <KeyStats3 stats={stats} />
  )
}

export const PortfolioGains = (props) => {
  const stats = [
    { name: "Gains For Today", value: props.stats.gainToday, alias: 'Today' },
    { name: 'Gains For The Month', value: props.stats.gainMonthly, alias: 'Month' },
    { name: 'Gains For The Year', value: props.stats.gainYearly, alias: 'Year' },
  ]
  return (
    <KeyStats4 {...props} stats={stats} prec={2} />
  )
}

export class ProfitLoss extends React.PureComponent {
  opts = [ 'Sectors', 'Regions' ]
  state = {
    opt: this.opts[0],
    sectorData: this.opts[0],
    regionData: this.opts[1],
  }

  optHandler = opt => {
    if(opt == 'Sectors'){
      addEventToAnalytics('Enhancer PL Sector','Enhancer PL Sector','ENHANCER_PL_SECTOR',{},false);
    }
    else{
      addEventToAnalytics('Enhancer PL Region','Enhancer PL Region','ENHANCER_PL_REGION',{},false);
    }
    this.setState({ opt });
  }

  formatter = val => `$${val.toFixed(1)}`;

  chartData = () => {
    return this.state.opt === this.opts[0]
           ? this.props.stats.priceSectors.length > 0 ? this.props.stats.priceSectors : []
           : this.props.stats.priceRegions.length > 0 ? this.props.stats.priceRegions : [];
  }

  render() {
    const data = this.chartData();
    const regionData = this.props.stats.priceRegions.length > 0 ? this.props.stats.priceRegions : [];
    const sectorData = this.props.stats.priceSectors.length > 0 ? this.props.stats.priceSectors : [];
    //className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '192px',  width: '100%' }}
    //<SelectorDD opts={this.opts} optHandler={this.optHandler} />
    return (
      <React.Fragment>
        <h5 className="mb-1 font-one OpenSummaryPage-card-title" style={{ textAlign: 'center' }}>Profit / Loss</h5>
        <Row style={{ minHeight: '192px',  width: '100%' }}>
          <Col>
            <div style={{ width: "90%", height: "180px", margin: '0 auto', textAlign: 'center' }}>
              {(sectorData <= 0) ? (
                <NoData height='180px' />
              ) : (
                <BarChartPL data={sectorData} formatter={this.formatter} />
              )}
            </div>
            <div style={{ textAlign: 'center' }}>By Sectors</div>
          </Col>
          <Col>
            <div style={{ width: "90%", height: "180px", margin: '0 auto', textAlign: 'center' }}>
              {(regionData <= 0) ? (
                <NoData height='180px' />
              ) : (
                <BarChartPL data={regionData} formatter={this.formatter} />
              )}
            </div>
            <div style={{ textAlign: 'center' }}>By Regions</div>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

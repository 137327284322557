import React, { useContext } from 'react'
import MdlEnhancerContext from '../Context/MdlEnhancerContext'
import {
  Header_Footer_Logo,
  Header_Footer_poweredByLogo,
  PoweredByIcon
} from '../../../../../common/constant'
const Logo = Header_Footer_Logo
const poweredByLogo = Header_Footer_poweredByLogo

export const withHeaderFooter = WrappedComponent => {
  return class extends React.Component {
    static contextType = MdlEnhancerContext
    render() {
      const { totalPageCount } = this.props
      const logo = this.context.logo || this.props.logo
      let page = pageNum.next().value
      return (
        <div className="page">
          <PageHeader logo={logo} page={page} total={totalPageCount} />
          <WrappedComponent {...this.props} />
          <PageFooter page={page} />
        </div>
      )
    }
  }
}

const PageHeader = ({ date, page, total, logo }) => (
  <div className="page-header d-print-block">
    <div className="d-flex justify-content-between align-item-center">
      {logo ? <img className={'logo'} src={logo} alt="" /> : <div></div>}
      <div className="d-grid">
        <img style={{ height: `20px` }} src={PoweredByIcon} alt="" />
        {/* <div style={{color:"#979797",fontWeight:600,paddingRight:2,marginTop:5 }} className="d-flex  justify-content-end align-item-center">For Institutional Use Only</div> */}
      </div>
    </div>
  </div>
)

const PageFooter = ({ date, page, total }) => (
  <div className="page-footer d-print-block">
    <div className="d-flex justify-content-between">
      <span className="d-flex flex-column justify-content-center">
        Please review the Disclosures contained at the end of this document for
        more information.
      </span>
      {page > 0 && (
        <span style={{ width: `100px`, textAlign: `end` }}>
          Page{' '}
          {total && total !== '-'
            ? `${page} | ${total < 10 ? '0' + total : total}`
            : `${page}`}
        </span>
      )}
    </div>
  </div>
)

const pageNum = (function*() {
  let n = 0
  while (true) yield n++
})()

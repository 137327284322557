import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Row, Col, PopoverBody, Popover } from 'reactstrap'
import { withPopover } from '../../Elements/Popover';
import { quintileSize, quintileClass,addEventToAnalytics } from 'Utils';
import { cn, QuinItems } from 'DataSet';
import { Quintile, Pct } from '../common';

// var classNames = require('classnames');

const ANNUALIZED = 'Annualized Volatility'

class RiskPopover2 extends Component {
  state = {
    popoverOpen: false,
  }

  onHover = () => {
    !this.state.popoverOpen && this.setState({
      popoverOpen: true,
    },()=>{
      if (this.props.discovery && this.props.discovery.query && this.props.discovery.queryUUID && this.props.card && this.props.card.ticker) {
        addEventToAnalytics('Risk Popup','Risk Popup','RISK_POPUP',{ query: this.props.discovery.query, queryUUID: this.props.discovery.queryUUID, ticker: this.props.card.ticker }, true);
      }
    })
  }

  onHoverLeave = () => {
    this.state.popoverOpen &&this.setState({
      popoverOpen: false,
    })
  }

  toggle=()=> {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    })
  }

  onMouseOver = () => {
    const element = document.querySelector('.popover-drop__item');
    element.style.display = 'block';
  }

  onMouseOverItem = () => {
    const element = document.querySelector('.popover-drop__item');
    element.style.display = 'block';
  }

  onMouseLeave = () => {
    const element = document.querySelector('.popover-drop__item');
    element.style.display = 'none';
  }

  render() {
    const { card, highlight, checkPopOpen, firstPop, funds, setRiskPopover, userRiskPrefer } = this.props;
    const i = 4;
    const annRiskIconQ = quintileSize(card._volatility_quin, QuinItems[i].reverse) ? quintileSize(card._volatility_quin, QuinItems[i].reverse) : 1;
    const drawRiskIconQ = quintileSize(card._drawdown_quin, QuinItems[i].reverse) ? quintileSize(card._drawdown_quin, QuinItems[i].reverse) : 1;
    // console.log('card ticker --> ', card.ticker);
    const riskStats = {
      label: (userRiskPrefer === ANNUALIZED) ? 'Annualized Volatility' : 'Drawdown',
      icon_name: (userRiskPrefer === ANNUALIZED) ? (card._volatility_quin ? annRiskIconQ : 1) : (card._drawdown_quin ? drawRiskIconQ : 1),
      volatility_v: (userRiskPrefer === ANNUALIZED) ? card._volatility_v : card._drawdown_v,
      volatility_quin: (userRiskPrefer === ANNUALIZED) ? card._volatility_quin : card._drawdown_quin,
      catVal: (userRiskPrefer === ANNUALIZED) ? (
                (card._categorySeries && card._categorySeries.volt !== null && typeof card._categorySeries.volt !== 'undefined') ? card._categorySeries.volt.toFixed(2) : '--'
              ) : (
                (card._categorySeries && card._categorySeries.dMax !== null && typeof card._categorySeries.dMax !== 'undefined') ? card._categorySeries.dMax.toFixed(2) : '--'
              ),
      catDateRange: (card._returnsChart && card._returnsChart.start && card._returnsChart.end) ? `${card._returnsChart.start} - ${card._returnsChart.end}` : null,
      highlight : card._flags ? card._flags.risksMin : false,
    };
    const blueUrl = `/assets/images/icons/blue-heart-rate-${riskStats.icon_name}.svg`;
    const iconUrl = `/assets/images/icons/heart-rate-${riskStats.icon_name}.svg`;
    const hikeLabel = riskStats.volatility_quin ? (quintileSize(riskStats.volatility_quin, QuinItems[i].reverse) === 2 ? 'Average' : (quintileSize(riskStats.volatility_quin, QuinItems[i].reverse) === 3 ? 'High' : 'Low')) : '--';
    const statusText = (riskStats.volatility_v || riskStats.volatility_v === 0) ? (riskStats.volatility_v.toFixed(2) + "%") : '--';
    const highlightClassName = highlight ? 'search-risk-stats-padding search-risk-stats-highlighting' : (riskStats.highlight ? 'search-risk-stats-padding search-risk-stats-highlighting' : 'search-risk-stats-padding');
    return (
      <div
        className={highlightClassName}
        onMouseEnter={this.onHover}
        onMouseLeave={this.onHoverLeave}>
        <div id={"RiskPopover-" + cn(card, 'Id')} className="w-100">
         <div className="risk-flag-color">
           <img src={highlight ? blueUrl : (riskStats.highlight ? blueUrl : iconUrl)} width="22" alt="Risk" style={{ marginBottom: '3px' }} />
           <p className="__val" style={{ margin: 0, fontSize: 11, lineHeight: 1, fontWeight: 'bold' }}>{statusText}</p>
           <p className="__state" style={{ margin: 0, fontSize: 10, lineHeight: 1 }}>{hikeLabel}</p>
         </div>
        </div>
        <Popover
          toggle={this.toggle}
          isOpen={this.state.popoverOpen}
          trigger={'hover'} placement="right"
          target={"RiskPopover-" + cn(card, 'Id')} >
          <div className="pop-wrapper">
            <PopoverBody className="p-0">
            <Row className="no-gutters">
              <Col className="col-12 p-2 text-gray-9 bg-light br-2">
                <div className={classNames("d-flex justify-content-between align-items-center")} style={{ lineHeight: 1 }}>
                  <div className="popover-drop">
                    <div className="popover-drop__toggle" onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave} >
                      <span className="pop-toggle-name">{riskStats.label}</span> <i className="fas fa-caret-down"></i>
                    </div>
                    <div className="popover-drop__item" onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}>
                      <ul className="popover-drop__item__wrapper">
                        {(userRiskPrefer === ANNUALIZED) ? (
                          <li  onClick={() => setRiskPopover('Drawdown')} className="popover-drop__item__wrapper__list" id="Drawdown">Drawdown</li>
                        ) : (
                          <li onClick={() => setRiskPopover('Annualized Volatility')}  className="popover-drop__item__wrapper__list" id="Annualized Volatility">Annualized Volatility</li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <span className="font-weight-bold ">{riskStats.volatility_v ? riskStats.volatility_v.toFixed(2) : '--'}<Pct /></span>
                </div>
                <div className="">
                  <Quintile
                    cardCategory={cn(card, 'Category')}
                    date={riskStats.catDateRange}
                    item={QuinItems[i]}
                    value={riskStats.volatility_quin}
                    catValue={<span>{riskStats.catVal}<Pct /></span>}
                    bg={riskStats.volatility_quin ? quintileClass(riskStats.volatility_quin, QuinItems[i].reverse) : null}
                  />
                </div>
              </Col>
            </Row>
          </PopoverBody>
          </div>
        </Popover>
      </div>
    );
  }
}


const mapStateToProps = ({ discovery }) => ({
  discovery
})

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiskPopover2)

// absolute imports
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { addEventToAnalytics } from 'Utils';
import orderBy from 'lodash/orderBy';

class PfListTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      shouldUpdateAll: true
    };
  }

  updateAllDuplicateClient = () => {
    const { shouldUpdateAll } = this.state;
    const { updateAllClients } = this.props;
    updateAllClients(!shouldUpdateAll);
    this.setState({
      shouldUpdateAll: !shouldUpdateAll
    })
  }

  customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return typeof row[field] === "boolean" ? row[field] : row[field].toLowerCase();
      }

      return row[field];
    };

    const sortedRows = orderBy(rows, handleField, direction);
    this.props.setSelectedClient(sortedRows[0])
    return sortedRows;
  };

  render() {

    const { pfList, showUpdateColumnInPreview, selectedClient, setSelectedClient, allowSelection } = this.props;
    const { shouldUpdateAll } = this.state;

    const data = pfList || [];

    const columns = [
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
      },
      {
        name: 'Account No',
        selector: 'account_number',
        sortable: true,
        width: showUpdateColumnInPreview ? '125px' : 'auto'
      },
      {
        name: 'Custodian',
        selector: 'custodian',
        sortable: true,
      },
      {
        name: <div><span className="cus-pointer" onClick={() => this.updateAllDuplicateClient(!shouldUpdateAll)}>Update*</span></div>,
        cell: (row) => <UpdateEntry row={row} updateSelectedClient={this.props.updateSelectedClient} />,
        center: true,
        width: allowSelection && '100px',
        omit: !showUpdateColumnInPreview
      },
      {
        name: <DeleteAll {...this.props} />,
        selector: 'enhancer',
        cell: (row) => <DeleteEntry {...this.props} refID={row.refID} />,
        center: true,
        width: allowSelection && '50px',
      },
      {
        name: '',
        selector: 'enhancer',
        cell: (row) => selectedClient.refID == row.refID && <SelectionArrow />,
        center: true,
        width: '50px',
        omit: !allowSelection
      }
    ];

    const customStyles = {
      rows: {
        style: {
          minHeight: '35px', // override the row height
          '&:hover': {
            cursor: allowSelection ? 'pointer' : 'default',
          },
        }
      }
    };

    const clientSelectionConditions = allowSelection ? {
      conditionalRowStyles : [
        {
          // If a portfolio is selected highlight it or highlight the first row
          when: row => selectedClient.refID == row.refID,
          style: {
            backgroundColor: '#e1effa',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
      ],
      onRowClicked: (row) => setSelectedClient(row)
    } : {}

    return (
      <div style={{marginTop: '5px'}}>
        <h3 className="text-center">Client List</h3>
        <p className="success-string-message">{data.length > 0 ? data.length+' Accounts' : data.length+' Account'} Successfully Uploaded! </p>
        <DataTable
          noHeader
          columns={columns}
          data={data}
          responsive
          pagination={data.length > 10 ? true : false}
          customStyles={customStyles}
          dense={false}
          defaultSortField="isDuplicate"
          defaultSortAsc={false}
          style={{overflowX: 'hidden'}}
          sortFunction={this.customSort}
          {...clientSelectionConditions}
        />
        <p className="note-message">Click Submit to Enhance Selected Client Account</p>
      </div>
    )
  }
}

export default PfListTable;

// Commenting delete all functionality, enable if required in the future
{/*<div className="delete-all" onClick={props.clearData}>Delete All</div>*/}
const DeleteAll = (props) => {
  return(
    <div className="delete-all">Delete</div>
  )
}

const DeleteEntry = (props) => {
  return(
    <div className="text-center delete-row"
      onClick={() => {
        addEventToAnalytics('Client Add Upload Delete', 'Client Add Upload Delete', 'CLIENT_ADD_UPLOAD_DELETE', {}, false)
        props.clearRow(props.refID)
      }}
    >
      <i className="far fa-fw fa-trash-alt"></i>
    </div>
  )
}

const UpdateEntry = ({row, updateSelectedClient}) => {
  return(
    <>
      {row.isDuplicate &&
        <div className="text-center update-row">
          <i
            className={`far fa-fw fa-check cus-pointer ${row.updateData ? 'active' : ''}`}
            onClick={() => updateSelectedClient(row)}
          >
          </i>
        </div>
      }
    </>
  )
}

const SelectionArrow = () => {
  return(
    <div className="text-center">
      {/* <i className="far fa-fw fa-arrow-right selection-arrow"></i> */}
      <img src='/assets/images/icons/Enhancer-Dark-Blue.svg' alt='' width='16' height='14' />
    </div>
  )
}

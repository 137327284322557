import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, ButtonGroup, UncontrolledTooltip } from 'reactstrap';
import { PrimaryButton, SecondaryButton } from 'shared-components';
import { addEventToAnalytics } from 'Utils';
import { postScreenerAttrs } from '../../actions/weights';
import WeightsPieChart from './WeightsPieChart';
import { Checkbox, Input, Select } from 'antd';
import { ActiveCalcScoreAttrs } from 'DataSet';

const { Option } = Select;

const ConfigureScreener = ({
  scoreAttrs,
  values,
  loading,
  modalToggle,
  ...props
}) => {

  const [initialScoreAttrsData, setInitialScoreAttrsData] = useState(scoreAttrs);
  const [scoreAttrsData, setScoreAttrsData] = useState(scoreAttrs);
  const [formErrors, setFormErrors] = useState({});
  const [submitStatus, setSubmitStatus] = useState(false);

  useEffect(() => {
    const hasError = Object.values(formErrors).some(item => item);
    setSubmitStatus(hasError);
  });

  const onResetClick = () => {
    setScoreAttrsData(initialScoreAttrsData)
  }

  const onSubmit = () => {
    props.postScreenerAttrs(scoreAttrsData);
    modalToggle(false)
  }

  const chartValues = () => {
    let values = {};
    let sumOfWeights = 0;
    ActiveCalcScoreAttrs.forEach((attr, index) => {
      let weight_value = Math.floor(100/ActiveCalcScoreAttrs.length);
      sumOfWeights += weight_value;
      if(index === ActiveCalcScoreAttrs.length-1){
        weight_value = weight_value + (100 - sumOfWeights);
      }
      values[attr.col] = weight_value;
    })
    return values;
  }

  return (
    <>
      <div
        className={
          loading == true
            ? 'overlay weight-active weight-overlay'
            : 'overlay weight-deactive weight-overlay'
        }
      >
        <div className="text rounded">
          <i className="fal fa-circle-notch fa-3x fa-spin text-gray-5"></i>
          <h5 className="mt-2 mb-0">Updating weights data</h5>
        </div>
      </div>

      <Table className="m-0 configure-weights-table configure-screener-wrapper">
        <tbody>
          <tr>
            <td className="weight-cell-column">
              <div className="cell-header-section">
                <div className="modal-addtional-text">
                  <div className="header-wrapper">
                    <h1
                      className="text-align-left"
                      style={{ fontWeight: '600' }}
                    >
                      Personalize Your Fund Screener
                    </h1>
                  </div>
                  {/*<span>The weights assigned will be used to generate a score to find
                  the best security from the selected securities -
                  ((variable1*weight1)+(variable2*weight2)…….)/ (sum of weights)</span>*/}
                  <div className="screener-text">
                    Select <Checkbox className="custom-checkbox-antd" checked={true} /> to activate screening criteria
                  </div>
                </div>
                <div className="weights-pie-chart-wrapper">
                  <WeightsPieChart dataObject={chartValues()} />
                </div>
              </div>
              <div className="weight-cell-container">
                <div className="right-line"></div>
                <div className="bottom-line"></div>
                <div className="weight-cell-wrapper d-flex">
                  {scoreAttrsData.map(attr => {
                    return (
                      <ScreenerItem
                        key={attr.col}
                        attr={attr}
                        scoreAttrsData={scoreAttrsData}
                        setScoreAttrsData={setScoreAttrsData}
                        formErrors={formErrors}
                        setFormErrors={setFormErrors}
                      />
                    )
                  })}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className="weight-cell-column footer">
              <ButtonGroup className="justify-content-center w-100">
                <SecondaryButton
                  type="button"
                  className="btn-link btn-reset"
                  onClick={onResetClick}
                  style={{
                    maxWidth: '203px',
                    cursor: 'pointer',
                    color: '#1e5f91'
                  }}
                >
                  Reset
                </SecondaryButton>
                <PrimaryButton
                  type="submit"
                  className="btn-link btn-update"
                  disabled={submitStatus}
                  onClick={onSubmit}
                  style={{
                    maxWidth: '203px',
                    cursor: 'pointer'
                  }}
                >
                  Update
                </PrimaryButton>
              </ButtonGroup>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}

const mapDispatchToProps = {
  postScreenerAttrs
}

export default connect(
  null,
  mapDispatchToProps
)(ConfigureScreener)

const ScreenerItem = ({
  attr,
  scoreAttrsData,
  setScoreAttrsData,
  formErrors,
  setFormErrors
}) => {

  const onValueChange = (e, type) => {
    const scoreAttrsObj = JSON.parse(JSON.stringify(scoreAttrsData));
    const updatedScoreAttrsData = scoreAttrsObj.map(scoreAttr => {
      const findAttr = ActiveCalcScoreAttrs.find(actCalcAttr => scoreAttr.col === actCalcAttr.col);
      scoreAttr = {...findAttr, ...scoreAttr};
      if(scoreAttr.col === attr.col){
        scoreAttr.screener.criteria.map(item => {
          if(type === 'input' && 
              item.id === Number(e.target.name)){
            if(type === 'input'){
              let newValue = e.target.value;
              newValue = newValue.replace(/(?!^-)[^0-9.]/g, "").replace(/(\..*)\./g, '$1');
              item.value = newValue;
            }
            // Uncomment the code below and comment lines 174 - 176
            // if you want to introduce checkbox for individual criteria
            // if(type === 'status'){
            //   item.status = e.target.checked;
            // }
          }
          if(type === 'select'){
            item.value = e;
          }
          if(type === 'status'){
            item.status = e.target.checked;
          }
          // Validate input field when a checkbox is checked
          // And clear error when checkbox is not checked
          if(item.status){
            if(item.validations){
              const {value, validations} = item;
              const validationError = validateAttribute(attr, value, validations);
              if(validationError){
                item.error = validationError;
                setFormErrors({...formErrors, [attr.col]: true})
              }else{
                if('error' in item){
                  delete item.error;
                }
                setFormErrors({...formErrors, [attr.col]: false})
              }
            }
          }else{
            if('error' in item){
              delete item.error;
            }
            setFormErrors({...formErrors, [attr.col]: false})
          }

        })
      }
      return scoreAttr;
    })
    setScoreAttrsData(updatedScoreAttrsData)
  }

  const attrStatus = (attr) => {    
    return attr.screener.criteria.some(item => item.status);
  }

  return (
    <div key={attr.col} className="weight-cell-item weight-screener-item">
      <div className="name">
        <div className="variable-name variable-row">
          <div className="column-1">
            <div className="variable-wrapper">
              <div className="screener-checkbox-wrapper">
                <Checkbox
                  checked={attrStatus(attr)}
                  onChange={(e) => onValueChange(e, 'status')}
                  className="custom-checkbox-antd"
                > 
                </Checkbox>
              </div>
              <span className="attr-name">{attr.name}</span>
              <i
                id={`${attr.col}-circle`}
                class="fal fa-info-circle tooltip-icon"
              ></i>
            </div>
            <UncontrolledTooltip
              placement="right"
              target={`${attr.col}-circle`}
            >
              {attr.tooltip}
            </UncontrolledTooltip>
          </div>
        </div>
        <div className="variable-row">
          <div className="column-1">
            {attr.screener.criteria.map(item => {
              return (
                <>
                  <div className="screener-input-wrapper">
                    {/* 
                      Uncomment the code below if you want to
                      introduce checkbox for individual criteria
                    */}
                    {/*<Checkbox
                      checked={item.status}
                      onChange={(e) => onValueChange(e, 'status')}
                      name={item.id}
                      className="custom-checkbox-antd"
                    > 
                    </Checkbox>*/}
                    <span className="criteria-label">{item.condition}</span>
                    <span className="criteria-value-input">
                      {(item.type === 'select') ?
                        <Select
                          value={item.value}
                          disabled={!attrStatus(attr)}
                          onChange={(e) => onValueChange(e, 'select')}
                        >
                          {item.options.map((option, index) => (
                            <Option value={option}>{option}</Option>
                          ))}
                        </Select> :
                        (item.type === 'input') ?
                        <Input
                          name={item.id}
                          value={item.value}
                          onChange={(e) => onValueChange(e, 'input')}
                          placeholder={item.suffix}
                          disabled={!attrStatus(attr)}
                          className={item.error ? 'error-highlight' : ''}
                        /> :
                        <span
                          style={{marginLeft: '5px'}}
                        >
                         {item.value}
                        </span> 
                      }
                    </span>
                    <span>{item.suffix}</span>
                  </div>
                  {item.error && <div className="screener-error">
                    {item.error}
                  </div>}
                </>
              )
            })}            
          </div>
        </div>
      </div>
    </div>
  )
}

const validateAttribute = (attr, value, validations) => {
  let error;
  if(validations.isRequired){
    error = !value && value !== 0 ? attr.name + ' cannot be empty' : error;
  }
  if(isNaN(value)){
    error = attr.name + ' must be a valid number';
  }
  if(validations.onlyPositive){
    error = value < 0 ? attr.name + ' cannot be negative' : error;
  }
  return error;
}
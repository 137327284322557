// absolute imports
import { Tooltip } from 'reactstrap';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Progress } from 'antd';
import HighlightStat from './HighlightStat';
import { kmb } from 'DataSet';
import { Table, Tag, Space, Tooltip as AntToolTip } from 'antd';
class StatItems extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen:false,
    };
  }

  clientUploadModalToggle = (value) => {
    if(this.props.clientUploadModalToggle){
      this.props.clientUploadModalToggle(value);
    }

  }

  _statsTopSectionWidth=()=>{
    const _dynamicArrowSection= document.getElementsByClassName('dynamic-sub-cont-2')
    const _dynamicArrowSectionS=document.getElementsByClassName('dynamic-sub-cont-2-s')
    const _paddingRight = document.getElementsByClassName('sw-gen-style-d')
    if(_dynamicArrowSection && _dynamicArrowSection.length){
        if(_dynamicArrowSection[0].clientWidth > 150){
          const _dWidth=_dynamicArrowSection[0].clientWidth -150;
          _paddingRight[0].style.paddingRight=54-(_dWidth/2) + 'px';
          _paddingRight[1].style.paddingRight=54-(_dWidth/2) + 'px';
        }else{
          _paddingRight[0].style.paddingRight=46 + 'px';
          _paddingRight[1].style.paddingRight=46 + 'px';
        } 
    }else{
      if(_dynamicArrowSectionS && _dynamicArrowSectionS.length){
        if(_dynamicArrowSectionS[0].clientWidth > 150){
          const _dWidth=_dynamicArrowSectionS[0].clientWidth -150;
          _paddingRight[0].style.paddingRight=46-(_dWidth/2) + 'px';
          _paddingRight[1].style.paddingRight=46-(_dWidth/2) + 'px';
        }else{
          _paddingRight[0].style.paddingRight=46 + 'px';
          _paddingRight[1].style.paddingRight=46 + 'px';
        }     
      }else{
        if(_paddingRight && _paddingRight.length){
          _paddingRight[0].style.paddingRight=46 + 'px';
          _paddingRight[1].style.paddingRight=46 + 'px';
        }
      }
    }
  }

  _dynamicColumnDetail = (data) =>{
    setTimeout(()=>this._statsTopSectionWidth(),0);
    return (<>
      <div className='dynamic-container' id='dynamicColumn'>
        <div className='dynamic-sub-cont-1'>Key Metrics based on</div>
        <div className={data && data.query_var_length > 1 ? 'dynamic-sub-cont-2':'dynamic-sub-cont-2-s'}>{data ? data.query_var_length > 1 ? <><div className={ data.index===0 ? 'disable-side-arrow left-arrow-d': 'side-arrow left-arrow'} onClick={() => data.columnTrackIcon('-',data.query_var_length)}>{'<'}</div> {data.columnJSX} <div className={ data.index===data.query_var_length-1 ? 'disable-side-arrow right-arrow-d': 'side-arrow right-arrow'} onClick={() => data.columnTrackIcon('+',data.query_var_length)}>{'>'}</div></>:<>{data.columnJSX}</>: ''}</div>
        {/* <div className='dynamic-sub-cont-3'>{data ? data.averageJSX : ''}</div> */}
      </div>
      
      </>
    )
  }


  render() {
    const {isDynamicQuery,dynamicContent}=this.props;
    if(!isDynamicQuery){
      const _paddingRight_1 = document.getElementsByClassName('sw-gen-style')
      if(_paddingRight_1 && _paddingRight_1.length){
        _paddingRight_1[0].style.paddingRight='unset';
        _paddingRight_1[1].style.paddingRight='unset';
      }
    }

    return (
      <div className={ isDynamicQuery ? "sw-card-wrapper padd-right": "sw-card-wrapper"}>
         {isDynamicQuery ? this._dynamicColumnDetail(dynamicContent):''} 
          <Row style={{marginLeft:'unset'}}>
          {this.props.items.map((item, index) => <div className={ isDynamicQuery ? "sw-card sw-gen-style-d": "sw-card sw-gen-style"} key={index}>
            <div className="sw-card-icon">
              <Progress type='circle' strokeColor={item.strokeColor} percent={item.strokePercent} format={()=><img src={item.icon} />}></Progress>
            </div>
            <div className="sw-card-det">
              { item.totalValue ?
              <div>
              <span className="main-title">{item.unit?item.unit:''}{item.value}{item.suffix?item.suffix:''}/</span>
              <span className="main-sub-title">{item.totalValue}</span>
              </div>:<>
              <span className="main-title" id='csAlloc'>{0}</span>
              {/* <Tooltip className="col-grey" placement="right" isOpen={this.state.tooltipOpen} target="csAlloc" toggle={() => this.setState({ tooltipOpen: !this.state.tooltipOpen })}>
              Book level calculation
            </Tooltip>*/}</>
              }
              <span className="sub-title">{item.title}</span>
            </div>
            {/* {this.props.items.length-1 !== index && <div className="vertical-line gen-divider"></div>} */}
          </div>)}
        </Row>
      </div>
    )
  }
}

export default StatItems;

import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Modal, Button } from 'antd';
import { addEventToAnalytics, searchUrlV3 } from 'Utils';

import { setCalendarInviteModalState } from 'actions/auth';

class CalendarInviteModal extends React.Component {
	onModalClose = () => {
		const { setCalendarInviteModalState, history } = this.props;
		if (setCalendarInviteModalState) {
			setCalendarInviteModalState(false);
			addEventToAnalytics(
				'Not Yet. Take Me To My Account',
				'Not Yet. Take Me To My Account',
				'NOT_YET_TAKE_ME_TO_MY_ACCOUNT',
				{}
			);
			if (history) {
				// NOTE: Get query from discovery reducer's state
				// --------------------------------------------------------------------------------------------
				// const { discoveryQuery } = this.props;
				let discoveryQuery = this.props.discoveryQuery || window.sessionStorage.getItem('query') || ''
				const securitiesPath = searchUrlV3(discoveryQuery); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
				// --------------------------------------------------------------------------------------------
				history.push(securitiesPath);
			}
		}
	}

  render() {
    const {
      width,
      style,
			centered,
			bodyStyle,
			maskStyle,
      modalTitle,
      wrapClassName,
			footer = null,
			openCalendarInviteModal,
    } = this.props;

		// console.log('openCalendarInviteModal --> ', openCalendarInviteModal)

		if (!openCalendarInviteModal) return null;

    return (
      <React.Fragment>
        <Modal
          destroyOnClose
          width={width}
          style={style}
          footer={footer}
          title={modalTitle}
          centered={centered}
          maskStyle={maskStyle}
          bodyStyle={bodyStyle}
          visible={openCalendarInviteModal}
          wrapClassName={`csv-preview-upload calendar-invite-modal ${wrapClassName}`}
          onOk={() => this.onModalClose()}
          onCancel={() => this.onModalClose()}
          closeIcon={<i className="fal fa-times premium-feature-completed-close cp-modal-close" onClick={() => this.onModalClose()}></i>}
        >
          <div className="pf-list-preview-wrapper h-100 fx-clm">
						<div className="header-area retail_form_container text-center">
							<h3>Your account is ready!</h3>
							<p>Schedule a personalized demo to see Magnifi in action</p>
						</div>
						<div className="calendar-invite-screen-wrapper">
							<div
								class="meetings-iframe-container"
								data-src="https://meetings.hubspot.com/rob-stella/magnifi-for-advisors-30-min-demo?embed=true"
							></div>
							<Helmet>
								<script
									type="text/javascript"
									src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
								></script>
							</Helmet>
						</div>

						<div className="d-flex w-100 justify-content-center" style={{ paddingTop: 18 }}>
							<Button
								type="button"
								className="btn secondary-btn fill p-1 pl-3 pr-3 m-0"
								onClick={() => this.onModalClose()}
								style={{ minWidth: 120 }}
							>
								Not yet. Take me to my account
							</Button>
						</div>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ auth, discovery }) => ({
	discoveryQuery: discovery.query,
	openCalendarInviteModal: auth.openCalendarInviteModal || null,
});

const mapDispatchToProps = {
	setCalendarInviteModalState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CalendarInviteModal));

import React, { useState, Fragment } from 'react';
import { calculatorOptions } from 'DataSet';
import { Button, Tooltip, UncontrolledTooltip } from 'reactstrap';
import { addEventToAnalytics } from 'Utils';
import PButton from 'components/PButton';
import { FooterActions } from '../Common';

const Footer = (props) => {
  const [proposalToolTip, setProposalToolTip] = useState(false);
  const { funds, items, funds: { chartReportData: { selectedFunds } }, selectedCalculator } = props;
  const disableProposal = selectedFunds.length === 0 || selectedCalculator === calculatorOptions.magnifiactivescreener;
  let tickerNames;
  if (items.length > 0) {
    tickerNames = items.map(x => x.ticker);
  }
  const isEveryFundIsShare = items.every(item => item.vehicle === 'SHARE');
  const showTooltip = props.items.every(fund => fund.not_tradable === "1.0") && process.env.REACT_APP_SUB_DOMAIN === 'retail'

  return (
    <React.Fragment>
      <FooterActions
        hideBack={true}
        backHandler={() => props.actionHandler('Compare', 'all')}
        clearHandler={props.clearAllHandler}
      />
      {(!props.accountsPage) && process.env.REACT_APP_SUB_DOMAIN === 'advisor' && (
        <Fragment>
          <PButton
            color="tools"
            type="button"
            featureName="print_report_combine"
            premiumAction={true}
            style={{ width: '135px' }}
            className={`btn h3 text-uppercase font-weight-bold p-3 mb-0 ml-auto mt-0 secondary-btn ${isEveryFundIsShare ? "disabled" : ""}`}
            title={(!props.disabled && !disableProposal && !isEveryFundIsShare) &&
              "Download search analytics report for the last query"}
            onClick={() => {
              if (!isEveryFundIsShare) {
                props.printReport()
                if (funds) {
                  addEventToAnalytics('Combine print report', 'Combine print report', 'COMBINE_PRINT_REPORT', { query: funds.query, logId: funds.logId, tickers: tickerNames, clickBtn: 'Print Report', category: 'advisory_PTC' }, true);
                }
              }
            }}
            disabled={props.disabled || disableProposal}
            id="execute-proposal-button-combine"
          >
            PROPOSAL
          </PButton>
          {isEveryFundIsShare && <Tooltip
            className={`col-grey-bottom`}
            placement="top"
            isOpen={proposalToolTip}
            target="execute-proposal-button-combine"
            toggle={() => setProposalToolTip(!proposalToolTip)}>
            Excluded from analysis
          </Tooltip>}
        </Fragment>
      )}
      <div>
        {showTooltip && <div id="combine-footer-tooltip" style={{ position: "absolute", height: "100%", width: "150px", cursor: "no-drop" }}></div>}
        <Button
          color="select"
          style={{ width: '135px', background: showTooltip ? "#e8ecef" : "auto", borderColor: showTooltip ? "#d1d2d6" : "auto", color: showTooltip ? "#d1d2d6" : "auto" }}
          className={`h3 mb-0 ml-3 mt-0 text-uppercase font-weight-bold p-3 secondary-btn ${props.accountsPage ? 'ml-auto' : ''}`}
          onClick={() => {
            props.buyHandler('all', null);
            if (funds) {
              addEventToAnalytics('Combine fund buy', 'Combine fund buy', 'COMBINE_FUND_BUY', { query: funds.query, logId: funds.logId, tickers: tickerNames, clickBtn: 'Buy All', category: 'advisory_PTC' }, true);
            }
          }}
        >
          Buy All
        </Button>
        {showTooltip && (
          <UncontrolledTooltip className="w-sm" placement="top" target="combine-footer-tooltip" >
            Security Not Tradable
          </UncontrolledTooltip>
        )}
      </div>
    </React.Fragment>
  )
}

export default Footer;

import React from 'react'

import { sponsorLogo } from 'DataSet'

export const TruncatedNameSponsor = ({
  ticker,
  name,
  sponsor,
  lines = 2,
  imageValidPath,
  style,
  showSponsorLogo = false,
}) => {
  const sty = {
    // marginBottom: '2px',
    ...style
  }
  return (
    <React.Fragment>
      <div className="truncated-name-card" style={sty}>
        {ticker && <h5 className="mb-0 ticker-price pb-1">{ticker}</h5>}
        {showSponsorLogo && <div className="sponsored-logo-tag">SPONSORED</div>}
        <div className="funds-image-holder pb-1">
          {imageValidPath && sponsor ? (
            <img
              src={sponsorLogo(sponsor)}
              className="img-fluid"
              alt={sponsor}
              style={{ height: '20px' }}
            />
          ) : (
            <div className="sponsor-logo-placeholder" title={sponsor || ''}>
              {sponsor || ''}
            </div>
          )}
        </div>
        {/* <TruncatedName name={name} lines={lines} /> */}
        <p
          className={`funds-name color-sfmodal-header mb-0 p-0 media-f-md media-text-overflow text-overflow-ellipse-line-${lines}`}
          style={{ fontWeight: '600', lineHeight: '1.2' }}
          title={name}
        >
          {name}
        </p>
      </div>
    </React.Fragment>
  )
}

import React from 'react';
import { connect } from 'react-redux';
import { ReturnsLineChart } from './ChartElements/LineChart';
import { getLineChartMatrix } from '../../../../BuildPortfolio/Pages/BuildFromScratch/utils';

import moment from 'moment';
import _ from 'lodash';
import { cn } from 'DataSet';
import { nameToDateRange, monthToDate } from 'Utils';
import { getMonthFromYearDiff, 
  getAnnualizedReturnsByYears, 
  getAnnualizedVolatilityByYears } from '../../../../AccountsPage/utils';

class Returns extends React.Component {
  constructor(props) {
    super(props)
    this.state = {};
  }  

  render() {

    const returnsData = returnsDataCalc(this.props.selectedClientHoldings.holdings)
    console.log("returnsData",returnsData)

    const yearShortName = '3yr';
    const defaultYears = 'pfRet3y';

    const rawSeries = {
      ykey: 'y1',
      ...makeCurrentSeries(returnsData[defaultYears], yearShortName),
    };

    const filteredSeries = [];
    if (rawSeries && rawSeries._stats) {
      rawSeries._stats.series.map((sD, sI) => {
        if (sD.x && sD.y1 && sD.y3 && sD.y4) {
          filteredSeries.push({
            x: sD.x,
            y1: sD.y1,
            y1T: sD.y3,
            y1Val: sD.y4,
            orgVal: sD.orgVal,
          });
        }
      });
    }

    const lineChartSeries = filteredSeries;

    const summaryStatsData = [
      {
        name: 'Annualized Return',
        value: returnsData[`anRet${yearShortName}`]+'%',
      }, {
        name: 'Annualized Volatility',
        value: returnsData[`anVol${yearShortName}`]+'%',
      }, {
        name: 'Max Drawdown',
        value: returnsData[`dMax`]+'%',
      }, {
        name: 'Sharpe Ratio',
        value: returnsData[`sharpe_${defaultYears}`]+'%',
      }
    ]

    return (
      <div className="risk-chart-wrapper">
        <div className="risk-single-chart">
          <h4>Annualized Volatility</h4>
          <div style={{width: '100%', height: '180px'}}>
            {rawSeries && <ReturnsLineChart
              id="build-portfolio-linechart"
              lines={[{ ...rawSeries ,name: yearShortName, color: '#1d6091', data: lineChartSeries }]}
              series={lineChartSeries}
              xkey="x"
              ykey={rawSeries.ykey}
            />}
          </div>
        </div>
        <div className="returns-summary-wrapper">
          <h4>Summary Statistics</h4>
          {summaryStatsData.map((item) => {
            return(
              <div className="returns-summary-item">
                <div className="returns-summary-name">{item.name}</div>
                <div className="returns-summary-value">{item.value}</div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ clientportfolio: { selectedClientHoldings } }) => ({
  selectedClientHoldings
})

export default connect(
  mapStateToProps
)(Returns);

const getNodeData = (node, data) => {
  return node.map((v) => {
    return {
      name: v.v, value: parseInt(data[v.n]), color: v.c,
    }
  });
}

export const returnsDataCalc = (currentP) => {
  //currentP is the array of portfolio object [{ticker, nav, returns}, {ticker, nav, returns}]
  console.log("check currentP", currentP)

  const currentPf = JSON.parse(JSON.stringify(currentP));
  let portfolioSumValue = 0.0, weightSum = 0.0;
  
  const  currentYear = moment(new Date()).year();
  currentPf.forEach((e,index) => {
    e.nav = e.nav || e.current_price;
    if(e.nav && e.shares)
      portfolioSumValue += e.nav * e.shares;
    const returnsArr = e.returns;
    const start = moment(new Date(`01 ${returnsArr[returnsArr.length - 1].d}`));
    const end = moment(new Date(`01 ${returnsArr[0].d}`));
    const DateRangeMoment = { start, end };
    const calculatePoints = (ret, year, startFrom) => {
      let returns = JSON.parse(JSON.stringify(ret))
      const range = {
        start: nameToDateRange(year, DateRangeMoment, startFrom).start._d,
        end: nameToDateRange(year, DateRangeMoment, startFrom).end._d,
      }
      return returns.filter((e) => {
        let dt = monthToDate(e.d);
        return dt.isAfter(moment(range.start).subtract(1, 'seconds')) && dt.isBefore(moment(range.end).add(1, 'seconds'));
      });
    }
    currentPf[index].points1y = calculatePoints(e.returns, 'l1y');
    currentPf[index].points3y = calculatePoints(e.returns, 'l3y');
    currentPf[index].points5y = calculatePoints(e.returns, 'l5y');
    
    currentPf[index].pfWeight = e.nav * e.shares;
    weightSum += currentPf[index].pfWeight;
    currentPf[index].retWeighted1y = [];
    currentPf[index].retWeighted3y = [];
    currentPf[index].retWeighted5y = [];
    e.points1y.forEach((el, index1) => {
      el.v = el.v * currentPf[index].pfWeight; //multiplied by 100 to negate the divide by 100 for charts
      currentPf[index].retWeighted1y.push(el);
    });
    e.points3y.forEach((el, index1) => {
      el.v = el.v * currentPf[index].pfWeight;
      currentPf[index].retWeighted3y.push(el);
    });
    e.points5y.forEach((el, index1) => {
      el.v = el.v * currentPf[index].pfWeight;
      currentPf[index].retWeighted5y.push(el);
    });
  });
  // end for each portfolio
  //
  // start pf ret calc
  let pfRet1y = getMonthFromYearDiff(1);
  let pfRet3y = getMonthFromYearDiff(3);
  let pfRet5y = getMonthFromYearDiff(5);
  
  let outd1 = {n: 0, dMax: 0.0}, cuml1 = 0.0;
  pfRet1y.forEach((el, index1) => {
    currentPf.forEach((e, index2) => {
      e.points1y.forEach((el1, index11) => {
        if(pfRet1y[index1].d == el1.d) {
          pfRet1y[index1].v += el1.v
        }
      });
    });
    pfRet1y[index1].v = pfRet1y[index1].v/weightSum;
    //calculate drawdown
    let r = pfRet1y[index1].v/100;
    cuml1 = (1 + cuml1)*(1 + r) - 1;
    outd1.n += 1;
    if (outd1.n === 1) {
      outd1.cumlMax = cuml1;
    }
    else {
      (cuml1 > outd1.cumlMax) && (outd1.cumlMax = cuml1);
      let dmax = (outd1.cumlMax-cuml1)/(1+outd1.cumlMax);
      (dmax > outd1.dMax) && (outd1.dMax = dmax);
    }
  });
  let outd = {n: 0, dMax: 0.0}, cuml = 0.0;
  pfRet3y.forEach((el, index1) => {
    currentPf.forEach((e, index2) => {
      e.points3y.forEach((el1, index11) => {
        if(pfRet3y[index1].d == el1.d) {
          pfRet3y[index1].v += el1.v
        }
      });
    });
    pfRet3y[index1].v = pfRet3y[index1].v/weightSum;
    //calculate drawdown
    let r = pfRet3y[index1].v/100;
    cuml = (1 + cuml)*(1 + r) - 1;
    outd.n += 1;
    if (outd.n === 1) {
      outd.cumlMax = cuml;
    }
    else {
      (cuml > outd.cumlMax) && (outd.cumlMax = cuml);
      let dmax = (outd.cumlMax-cuml)/(1+outd.cumlMax);
      (dmax > outd.dMax) && (outd.dMax = dmax);
    }
  });
  let outd5 = {n: 0, dMax: 0.0}, cuml5 = 0.0;
  pfRet5y.forEach((el, index1) => {
    currentPf.forEach((e, index2) => {
      e.points5y.forEach((el1, index11) => {
        if(pfRet5y[index1].d == el1.d) {
          pfRet5y[index1].v += el1.v
        }
      });
    });
    pfRet5y[index1].v = pfRet5y[index1].v/weightSum;
    //calculate drawdown
    let r = pfRet5y[index1].v/100;
    cuml5 = (1 + cuml5)*(1 + r) - 1;
    outd5.n += 1;
    if (outd5.n === 1) {
      outd5.cumlMax = cuml5;
    }
    else {
      (cuml5 > outd5.cumlMax) && (outd5.cumlMax = cuml5);
      let dmax = (outd5.cumlMax-cuml5)/(1+outd5.cumlMax);
      (dmax > outd5.dMax) && (outd5.dMax = dmax);
    }
  });
  let annualizedRet1y = getAnnualizedReturnsByYears(pfRet1y);
  let annualizedRet3y = getAnnualizedReturnsByYears(pfRet3y);
  let annualizedRet5y = getAnnualizedReturnsByYears(pfRet5y);
  let annualizedVolt1y = getAnnualizedVolatilityByYears(pfRet1y);
  let annualizedVolt3y = getAnnualizedVolatilityByYears(pfRet3y);
  let annualizedVolt5y = getAnnualizedVolatilityByYears(pfRet5y);
  let out ={
    pfRet1y,
    pfRet3y,
    pfRet5y,
    anRet1yr: getAnnualizedReturnsByYears(pfRet1y).toFixed(2),
    anVol1yr: getAnnualizedVolatilityByYears(pfRet1y).toFixed(2),
    anVol3yr: annualizedVolt3y.toFixed(2),
    anRet3yr: annualizedRet3y.toFixed(2),
    anVol5yr: getAnnualizedVolatilityByYears(pfRet5y).toFixed(2),
    anRet5yr: getAnnualizedReturnsByYears(pfRet5y).toFixed(2),
    dMax1: (outd1.dMax*100).toFixed(2),
    dMax: (outd.dMax*100).toFixed(2),
    dMax5: (outd5.dMax*100).toFixed(2),
    sharpe_ratio_3y: (annualizedRet3y/annualizedVolt3y).toFixed(2) == NaN ? 0.00 : (annualizedRet3y/annualizedVolt3y).toFixed(2),
    sharpe_pfRet1y: (annualizedRet1y/annualizedVolt1y).toFixed(2) == NaN ? 0.00 : (annualizedRet1y/annualizedVolt1y).toFixed(2),
    sharpe_pfRet3y: (annualizedRet3y/annualizedVolt3y).toFixed(2) == NaN ? 0.00 : (annualizedRet3y/annualizedVolt3y).toFixed(2),
    sharpe_pfRet5y: (annualizedRet5y/annualizedVolt5y).toFixed(2) == NaN ? 0.00 : (annualizedRet5y/annualizedVolt5y).toFixed(2),
  }
  return out;
}

const getRangeFromSeries = (series) => {
  if (!series && !series.length) return null;
  if (series.length > 2) {
    return {
      start: series[0].d,
      end: series[series.length - 1].d,
    };
  } else {
    return {
      start: series[0].d,
      end: series[0].d,
    };
  }
}

const makeCurrentSeries = (data, label) => {
  const arr1 = [];
  const data1 = JSON.parse(JSON.stringify(data));

  if (data1 && typeof data1 !== 'undefined' && data1.length) {
    // const eleminateLastBlankData = data1[data1.length-1].v === 0 ? data1.splice(0, data1.length - 1, 1): data1;
    data1.map((a) => {
      arr1.push({ d: a.d, y1: a.v, label, v: a.v});
    });

    const range = getRangeFromSeries(arr1);
    if (range) {
      const getCurrentComlSeries = getLineChartMatrix(range, arr1, label);
      return getCurrentComlSeries;
    }
  } else {
    return arr1; // return []
  }
}
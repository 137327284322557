import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap';


import {
  Label,
  PopoverBody,
  Popover,
  UncontrolledPopover,
  Collapse
} from 'reactstrap'
import { IconButton, ToggleSwitch } from 'shared-components'
import { BrokersList, BrokerNameMap } from '../../DataSet'
import { FormattedNumber } from 'react-intl'

function AccountList({
  broker,
  account,
  defaultAccounts = {},
  onToggleChange,
  onDeleteAccountList,
  onToggleAccountList
}) {
  return (
    <li className="broker__acc-list-item">
      <div className="broker__acc-list-item-wrapper">
        {/*<div className="broker__acc-list-cell broker__acc-list-item-setdefault">
				  <ToggleSwitch
					name={broker.broker}
					selectedValue={
					  defaultAccounts[broker.broker] &&
					  defaultAccounts[broker.broker].account_number
					}
					value={account.account_number}
					onChange={e => onToggleChange(e, broker, account)}
					className="text-center"
				  />
				  <span className="broker__default-label text-center">
					Set as default
				  </span>
		    </div>*/}
        <div className="broker__acc-list-cell broker__acc-list-item-acc-info">
          <span className="broker__account-label">
            {account.account_description || 'Account Number'}
          </span>
          <span className="broker__account-value">
            {account.account_number}
          </span>
        </div>
        <div className="broker__acc-list-cell broker__acc-list-item-current-value">
          <span className="broker__currency-label">Net Value</span>
          <span className="broker__currency-value">
            <FormattedNumber
              value={account.net_account_value}
              style="currency"
              currency="USD"
              useGrouping={true}
            />
          </span>
        </div>
        <div className="broker__acc-list-cell broker__acc-list-item-cash-balance">
          <span className="broker__currency-label">Cash Balance</span>
          <span className="broker__currency-value">
            <FormattedNumber
              value={account.available_cash || 0}
              style="currency"
              currency="USD"
              useGrouping={true}
            />
          </span>
        </div>
        <div className="broker__acc-list-cell broker__acc-list-item-delete">
          <Button className="btn-delete-orange" color="" title="Clear" onClick={() => onDeleteAccountList(broker, account)}>
            <i className="far fa-fw fa-trash-alt"></i>
          </Button>
        </div>
      </div>
    </li>
  )
}

AccountList.propTypes = {
  onToggleChange: PropTypes.func.isRequired
}

class BrokersWithAccountListItem extends Component {
  render() {
    const {
      broker,
      account,
      expandedHamburger,
      expandedBrokerName,
      onToggleChange,
      defaultAccounts,
      onToggleAccountList,
      onHamburgerClick,
      onHamburgerMenuClick,
      onDeleteAccountList
    } = this.props

    const accountCred = broker.accounts;
    const brokerDetail =  BrokersList.filter(b => b.name === BrokerNameMap(broker.broker));
    
    return (
      <div className="broker__list-item">
        <div className="broker__list-header">
          <div className="broker__header-logo">
            <IconButton
              id={`${broker.broker}Bars`}
              iconClass="fa-bars"
              className="broker__bars"
              onClick={() => onHamburgerClick(broker)}
            />
            <div className="broker_cred">
              <img src={broker.imgSrc} />
              {
                accountCred && accountCred[0].is_login_required === 1 &&
                <a className="relink-broker text-gray-8 btn-link broker-link" href="#" onClick={() => this.props.handleBrokerSelect(brokerDetail[0])}>Reactivate broker</a>
              }
            </div>
          </div>

          <Popover
            placement="bottom"
            target={`${broker.broker}Bars`}
            isOpen={expandedHamburger === broker.broker}
            toggle={this.handleToggle}
            className="broker-popover"
          >
            <PopoverBody>
              <ul
                className="hamburger-menu"
                onClick={() => onHamburgerMenuClick(broker)}
              >
                <li
                  className="hamburger__item"
                  onClick={() => onToggleAccountList(broker)}
                >
                  Account-wise Details
                </li>
                <li className="hamburger__item hamburger__item-link">
                  <Link
                    to={`/settings/broker/${broker.broker}/account`}
                    className="hamburger__link"
                  >
                    Add an account
                  </Link>
                </li>
              </ul>
            </PopoverBody>
          </Popover>

          <div className="broker__header-value">
            <div className="broker__header-value-item">
              <span className="broker__currency-label">Net Value</span>
              <span className="broker__currency-value">
                <FormattedNumber
                  value={broker.sumNetValue}
                  style="currency"
                  currency="USD"
                  useGrouping={true}
                />
              </span>
            </div>
            <div className="broker__header-value-item">
              <span className="broker__currency-label">Cash Balance</span>
              <span className="broker__currency-value">
                <FormattedNumber
                  value={broker.cashBalance || 0}
                  style="currency"
                  currency="USD"
                  useGrouping={true}
                />
              </span>
            </div>
          </div>
        </div>
        <IconButton
          id={`${broker.broker}Accounts`}
          iconClass="fa-bars"
          className="broker__bars"
          style={{ display: 'none' }}
        />
        <Collapse isOpen={broker.broker == expandedBrokerName}>
          <ul className="broker__acc-list">
            {broker.accounts.map(acc => (
              <AccountList
                key={acc.account_number}
                broker={broker}
                account={acc}
                defaultAccounts={defaultAccounts}
                onToggleChange={onToggleChange}
                onDeleteAccountList={onDeleteAccountList}
              />
            ))}
          </ul>
        </Collapse>
      </div>
    )
  }
}

function BrokersWithAccountList(props) {
  const { accounts = [], defaultAccounts = {} } = props
  return (
    <div className="broker__list">
      {accounts.map(broker => (
        <BrokersWithAccountListItem
          key={broker.broker}
          broker={broker}
          defaultAccounts={defaultAccounts}
          expandedBrokerName={props.expandedBrokerName}
          expandedHamburger={props.expandedHamburger}
          onHamburgerClick={props.onHamburgerClick}
          onHamburgerMenuClick={props.onHamburgerMenuClick}
          onToggleChange={props.onToggleChange}
          onToggleAccountList={props.onToggleAccountList}
          onDeleteAccountList={props.onDeleteAccountList}
          handleBrokerSelect={props.handleBrokerSelect}
        />
      ))}
    </div>
  )
}

BrokersWithAccountList.propTypes = {
  accounts: PropTypes.array.isRequired,
  onToggleChange: PropTypes.func.isRequired
}

export default BrokersWithAccountList

const config = {
  400: {
    img: '/assets/images/400.svg',
    code: 'Oops!',
    title: 'Oops! Error 400',
    desc: 'Something went wrong, please try again.',
  },
  403: {
    img: '/assets/images/403.svg',
    code: 'Oops!',
    title: 'Oops! Error 403 — Permission Denied',
    desc: 'Sorry, you do not have permission to access this page',
  },
  404: {
    img: '/assets/images/404.svg',
    code: 'Oops!',
    title: 'Oops! Error 404 — Page Not Found',
    desc: 'Sorry, the page you visited does not exist',
  },
  500: {
    img: '/assets/images/400.svg',
    code: 'Oops!',
    title: 'Oops! Error 500',
    desc: 'This page is down. Our team is working on fixing it.',
  },
  nt: {
    img: '/assets/images/400.svg',
    code: 'Under Verification',
    title: 'You are all set to trade!',
    desc: 'Discover securities using some popular searches.',
  },
  network: {
    img: '/assets/images/400.svg',
    code: 'Uh Oh.',
    title: 'No Internet Connection',
    desc: 'You appear to be offline, please reconnect and try again.',
  },
  uc: {
    img: '/assets/images/400.svg',
    code: 'Under Construction',
    title: 'Work In Progress',
    desc: 'You Have Not Created Any Magnifi Investment Account',
  },
  uv: {
    img: '/assets/images/400.svg',
    code: 'Under Verification',
    title: 'Your documents are under verification.',
    desc: 'Discover securities using popular searches to execute once the account is open for Investing.',
  },
  create: {
    img: '/assets/images/400.svg',
    code: 'Create A New Account',
    title: 'You Have Not Created Any Magnifi Investment Account',
    desc: null,
  },
  er: {
    img: '/assets/images/400.svg',
    code: 'Under Verification',
    title: 'You are all set to trade!',
    desc: 'Discover securities using some popular searches.',
  },
};

export default config;

import React, { Component } from 'react';
import { Skeleton } from 'antd';
import { addEventToAnalytics } from 'Utils';
import './RelatedInsights.scss';

const Sponsorstyless = {
  height: '33px',
  display: '-webkit-box',
  maxWidth: 400,
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineHeight: '1.4'
};

class RelatedInsights extends Component {
  insightClickHandler = (url, isSponsored = false, ticker) => {
    isSponsored ?
      addEventToAnalytics('Related Insights SPONSORED', 'Related Insights SPONSORED', 'RELATED_INSIGHTS_SPONSORED', { url, ticker }, true) :
      addEventToAnalytics('Related Insights', 'Related Insights', 'RELATED_INSIGHTS', { url, ticker }, true);
    window.open(url, "_blank");
  }

  render() {
    const { relatedInsights, loading, className, isSponseredAvailable } = this.props;
    if (!relatedInsights || !Array.isArray(relatedInsights) || relatedInsights.length === 0 || typeof relatedInsights[0] === 'undefined') return null;

    const renderDesign = (relatedInsights && relatedInsights.length > 0) ? (
      <div className={`element ${className}`}>
        <div className="label">Related Insights</div>
        <div className="relInsight-wrapper">
          {relatedInsights && relatedInsights.map((item) => {
            return (
              <div className="relInsight-wrapper__item fin-img" onClick={() => this.insightClickHandler(item.url, item.sponsored, item.ticker)}>
                <img src={item.imgSrc} />
                <div className="relInsight-wrapper__content" style={{ width: '225px', margin: '9px', height: '96px' }}>
                  {item.sponsored && (
                    <div className="ad_related_insight">
                      SPONSORED
                      {item.impressionTagArray ? (
                        item.impressionTagArray.map((k, ki) => (
                          <img key={k + ki} src={k} border="0" width="1" height="1" style={{ display: 'none' }} />
                        ))
                      ) : (item.impressionTag && <img src={item.impressionTag} border="0" width="1" height="1" style={{ display: 'none' }} />)}
                    </div>
                  )}
                  {!item.promoted && (
                    <h5>{item.title ? item.title.toUpperCase() : ''}</h5>
                  )}
                  <div className="body__Wrapper" style={Sponsorstyless}>{item.context}</div>
                </div>
              </div>
            )
          })}
          {(isSponseredAvailable && false) ? (<a href="https://www.myclout.com/" target="_blank" className="mio-logo">
            <img src="/assets/images/clout-logos-05.png" />
          </a>) : <div>&nbsp;</div>}
        </div>
      </div>
    ) : null;

    return (
      <React.Fragment>
        {loading ? (
          <div className={`element ${className}`}>
            <div className="label">Related Insights</div>
            <div className="relInsight-wrapper">
              <div className="skeleton-container">
                <div className="skeleton-wrap">
                  <Skeleton.Avatar className="skeleton-Avatar" active size={'large'} shape={'small'} />
                  <Skeleton paragraph={{ rows: 3 }} />
                </div>
                <div className="skeleton-wrap">
                  <Skeleton.Avatar className="skeleton-Avatar" active size={'large'} shape={'small'} />
                  <Skeleton paragraph={{ rows: 3 }} />
                </div>
              </div>
            </div>
          </div>
        ) : renderDesign}
      </React.Fragment>
    )
  }
}

export default RelatedInsights;

import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap';
import style from './Brokers.module.scss'

import {
  Label,
  PopoverBody,
  Popover,
  UncontrolledPopover,
  Collapse
} from 'reactstrap'
import { IconButton, ToggleSwitch } from 'shared-components'
import { isDemoUser, BrokersList, BrokerNameMap } from '../../../../DataSet'
import { FormattedNumber } from 'react-intl'
import { Modal } from 'antd'
import { addEventToAnalytics } from '../../../../Utils'
import { RETAIL_USERS_ROLES }  from '../../Constant/FormData';

function AccountList({
  broker,
  account,
  defaultAccounts = {},
  onToggleChange,
  onDeleteAccountList,
  onToggleAccountList
}) {
  return (
    <li className="broker__acc-list-item">
      <div className="broker__acc-list-item-wrapper">
        {/*<div className="broker__acc-list-cell broker__acc-list-item-setdefault">
				  <ToggleSwitch
					name={broker.broker}
					selectedValue={
					  defaultAccounts[broker.broker] &&
					  defaultAccounts[broker.broker].account_number
					}
					value={account.account_number}
					onChange={e => onToggleChange(e, broker, account)}
					className="text-center"
				  />
				  <span className="broker__default-label text-center">
					Set as default
				  </span>
		    </div>*/}
        <div className="broker__acc-list-cell broker__acc-list-item-acc-info">
          <span className="broker__account-label">
            {account.account_description || 'Account Number'}
          </span>
          <span className="broker__account-value">
            {account.account_number}
          </span>
        </div>
        <div className="broker__acc-list-cell broker__acc-list-item-current-value">
          <span className="broker__currency-label">Net Value</span>
          <span className="broker__currency-value">
            <FormattedNumber
              value={account.net_account_value}
              style="currency"
              currency="USD"
              useGrouping={true}
            />
          </span>
        </div>
        <div className="broker__acc-list-cell broker__acc-list-item-cash-balance">
          <span className="broker__currency-label">Cash Balance</span>
          <span className="broker__currency-value">
            <FormattedNumber
              value={account.available_cash || 0}
              style="currency"
              currency="USD"
              useGrouping={true}
            />
          </span>
        </div>
        <div className="broker__acc-list-cell broker__acc-list-item-delete">
          <Button className="btn-delete-orange" color="" title="Clear" onClick={() => onDeleteAccountList(broker, account)}>
            <i className="far fa-fw fa-trash-alt"></i>
          </Button>
        </div>
      </div>
    </li>
  )
}


class BrokersWithAccountListItem extends Component {

  state={
    showDeleteConfirm : false
  }

  toggleDeleteConfirm= ()=>{
    if (!this.state.showDeleteConfirm) {
      document.getElementById('settingsContainer').classList.add('blur-effect')
      // document.getElementById('headerWrapper').classList.add('blur-effect')
    } else {
      document.getElementById('settingsContainer').classList.remove('blur-effect')
      // document.getElementById('headerWrapper').classList.remove('blur-effect')
    }
    this.setState({
      showDeleteConfirm:!this.state.showDeleteConfirm
    })
  }
  deleteAccount = ()=>{
    this.toggleDeleteConfirm()
    this.props.onDeleteAccountList(this.props.broker,this.props.broker.accounts[0])
  }

  reactivateBroker = (broker)=>{
    addEventToAnalytics('Reactivate Broker','Reactivate Broker','REACTIVATE_BROKER',{},false);
    this.props.handleBrokerSelect(broker)
  }

  render() {
    const {
      broker,
      account,
      expandedHamburger,
      expandedBrokerName,
      onToggleChange,
      defaultAccounts,
      onToggleAccountList,
      onHamburgerClick,
      onHamburgerMenuClick,
      onDeleteAccountList
    } = this.props
    const accountCred = broker.accounts;
    const brokerDetail =  BrokersList.filter(b => b.name === BrokerNameMap(broker.broker));
    const profile_self_role =  window.localStorage.getItem('profile_self_role') ? window.localStorage.getItem('profile_self_role') : 'loggedOut';
    return (
      <div className={style.container}>
        <div className={style.accountProvider}>
          <div className={style.subHeader}>{RETAIL_USERS_ROLES.includes(profile_self_role) &&broker.broker}</div>
          <div className={style.deleteBroker}>
            {accountCred[0].is_login_required ===1 && <div  onClick={() =>{this.reactivateBroker(brokerDetail[0])}} className={style.subHeaderValue}>Reactivate <span style={{color:'#919191'}}>|</span></div>}
            <Button className={style.deleteIcon} color="" title="Clear" onClick={this.toggleDeleteConfirm}>
              <i className="far fa-fw fa-trash-alt"></i>
            </Button>
          </div>
        </div>
        <Modal
          title={null}
          centered={true}
          wrapClassName={style.modal}
          footer={null}
          onCancel={this.toggleDeleteConfirm}
          width={`470px`}
          destroyOnClose={true}
          visible={this.state.showDeleteConfirm}
        >
         <div className={style.deleteConfirmModal}>
           <span className={style.title}>Delete Account</span>
           <p>Are you sure you want to delete this account? </p>
             <button onClick={this.deleteAccount} color="tools"
                     className={style.update}>
               Delete
             </button>
         </div>
        </Modal>
        <div className={style.formGroup}>
          <div className={style.key}>Net Value </div>
          <div  className={style.value}>
            <FormattedNumber
              value={broker.sumNetValue || 0}
              style="currency"
              currency="USD"
              useGrouping={true}
            /></div>
        </div>
        <div className={style.formGroup}>
          <div className={style.key}>Cash Balance</div>
          <div  className={style.value}>
            <FormattedNumber
              value={broker.cashBalance || 0}
              style="currency"
              currency="USD"
              useGrouping={true}
            /></div>
        </div>
        <div className={style.formGroup}>
          <div className={style.key}>Account Number</div>
          <div  className={style.value}>{accountCred[0].account_number}</div>
        </div>

      </div>
    )
  }
}

function BrokersWithAccountList(props) {
  const { accounts = [], defaultAccounts = {} } = props
  return (
    <div className="broker__list">
      {accounts.map((broker,i) => (
        <div>
          <BrokersWithAccountListItem
            key={broker.broker}
            broker={broker}
            defaultAccounts={defaultAccounts}
            expandedBrokerName={props.expandedBrokerName}
            expandedHamburger={props.expandedHamburger}
            onHamburgerClick={props.onHamburgerClick}
            onHamburgerMenuClick={props.onHamburgerMenuClick}
            onToggleChange={props.onToggleChange}
            onToggleAccountList={props.onToggleAccountList}
            onDeleteAccountList={props.onDeleteAccountList}
            handleBrokerSelect={props.handleBrokerSelect}
          />
          {accounts.length >1 && i!==accounts.length-1 && <div className={style.divider}/>}
        </div>
      ))}
    </div>
  )
}

BrokersWithAccountList.propTypes = {
  accounts: PropTypes.array.isRequired,
  onToggleChange: PropTypes.func.isRequired
}

export default BrokersWithAccountList

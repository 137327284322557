import React, { useState, Fragment } from 'react';
import {
  Button,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import { isEmpty } from 'lodash/lang'
import CustomModal from '../../../../components/Modal';
const comingSoonValues = ['SMA', 'MP'];

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isAgreementChecked: false,
      isAgreementOpen: false,
    }
  }
  deleteAll = () => {
    const { deleteAll } = this.props;
    if (deleteAll) {
      deleteAll();
    }
  }

  executeOrder = () => {
    const { executeOrder } = this.props;
    if (executeOrder) {
      executeOrder();
    }
  }

  toggleAgreementModal = (open = false) => {
    this.setState({
      isAgreementOpen: open
    });
  }

  agreementCheckHandle = (e) => {
    this.setState({
      isAgreementChecked: e.target.checked
    });
  }

  closeModalAndExecute = () => {
    this.props.updateBasketAggrementStatus();
    this.setState({
      isAgreementOpen: false,
      isAgreementChecked: false
    });
    this.executeOrder();
  }

  render() {
    const { trades, inputFieldError, loggedIn, profileLoading, accountAllowedToTrade, holdingValueDisable, noBankAccount, verificationPending, items } = this.props;
    const sellToAllSelected = (trades.filter((item) => item.sell_all).length > 0);

    let noTradeData = (trades.length === 0) || (trades.filter((item) => {
      if (item.option === 'Units' && (item.quantity === '' || item.quantity === 0 || item.quantity === '0')) {
        return item;
      } else if (item.option === 'Value' && (item.value === '' || item.value === 0 || item.value === '0')) {
        return item;
      }
    }).length > 0);

    noTradeData = sellToAllSelected ? false : noTradeData;

    let isExecuteButtonDisabled = profileLoading || (loggedIn && !accountAllowedToTrade) || !loggedIn || inputFieldError || noTradeData || holdingValueDisable;
    const isBasketTradeExists = items.filter(item => item.vehicle === 'BASKET').length;
    return (
      <React.Fragment>
        <CustomModal
          open={this.state.isAgreementOpen}
          onClose={() => this.toggleAgreementModal(false)}
          iconTop={<img src="/assets/images/icons/noun_agreement.png" alt="agreement" />}
          title="Please review and accept the agreement below that allows Magnifi to be your investment advisor and manage this portfolio on your behalf."
        >
          <div className="form-container-act" style={{ marginTop: -15 }}>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" checked={this.state.isAgreementChecked} onChange={this.agreementCheckHandle} />{' '}
                <a style={{ textDecoration: 'underline' }} href="https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/Magnifi%20-%20Discretinary%20Non-Discretionary%20IMA%20%5BSent%207-22-21%5D-270408129-v7%20-%20Final.docx.pdf" target="_blank">Magnifi Investment Advisory Agreement</a>.
              </Label>
            </FormGroup>
            <FormGroup>
              <Button onClick={this.closeModalAndExecute} disabled={!this.state.isAgreementChecked} > Accept </Button>
            </FormGroup>
            <FormGroup style={{ marginBottom: '-2rem' }}>
              <a href="https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/Magnifi%20Thematic%20Risk%20Factors.pdf" target="_blank" style={{ fontSize: 12, textDecoration: 'underline' }}>Risk Factors and Disclosures for Magnifi Managed Portfolios</a>
            </FormGroup>
            {/* <FormGroup check>
              <Label check>
                <Input type="checkbox" />{' '}
                Don't ask me again.
              </Label>
            </FormGroup> */}
          </div>
        </CustomModal>
        <div className="d-flex mr-auto">
          <Button
            color="select"
            outline
            className="h3 mb-0  mr-1 comm border-0 footer-delete-icon"
            onClick={this.deleteAll}
            title="Clear"
          >
            <i className="far fa-fw fa-trash-alt"></i>
          </Button>
        </div>
        {isBasketTradeExists ? <a className="mr-auto" href="https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/Magnifi%20Thematic%20Risk%20Factors.pdf" target="_blank" style={{ fontSize: 12, color: '#fff', textDecoration: 'underline' }}>Risk Factors and Disclosures for Magnifi Managed Portfolios</a> : null}
        {false && (
          <span id="buy-tt-id" style={{ margin: '0 0 0 16px' }}>
            <Button
              style={{ width: '135px' }}
              color="tools"
              disabled={true}
              className="btn h3 text-uppercase font-weight-bold p-3 mb-0 ml-auto secondary-btn"
            ><img src='/assets/images/icons/Download-Orange.svg' alt='' /> Tradelist</Button>
          </span>
        )}
        <span id="buy-tt-id" style={{ margin: '0 4px 0 16px' }}>
          <Button
            id="execute-order-btn"
            color="select"
            disabled={isExecuteButtonDisabled}
            className={`btn h3 text-uppercase font-weight-bold p-3 mb-0 secondary-btn ${(isExecuteButtonDisabled || noBankAccount || verificationPending) ? 'disabled' : ''}`}
            style={{ width: '135px' }}
            onClick={() => {
              if (!isExecuteButtonDisabled && !noBankAccount && !verificationPending) {
                if (isBasketTradeExists && this.props.loggedIn && this.props.profile.apex && !isEmpty(this.props.profile.apex) && this.props.profile.apex.basket_agreement === false && this.props.basketAggreement) {
                  return this.toggleAgreementModal(true);
                }
                this.executeOrder();
              }
            }}
          >
            Execute
          </Button>
        </span>
      </React.Fragment>
    )
  }
}

export default Footer;

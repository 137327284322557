// absolute imports
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import PfSearchBox from './PfSearchBox';
import ModelSearchBox from './ModelSearchBox';

class Search extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  queryHandler = (query) => {
    if (query) {
      console.log(query)
    }
  }

  render() {
    return (
      <Row className="search-wrapper">
        <Col xs={{ size: 8, offset: 2 }}>
          <div className="searchInput">
             <PfSearchBox
               onChange={this.props.onChange}
              queryHandler={this.queryHandler}
              history={this.props.history}
              placeholder="Search Across Downloaded Proposals"
            />
          </div>
        </Col>
      </Row>
    )
  }
}

export default Search;

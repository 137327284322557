import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button } from 'reactstrap';
import { Row, Col, Divider } from 'antd';
import { getSummaryFunds } from 'selectors/enhancer'

import { Holdings } from './Elements/Holdings'
import { SecurityReturns } from './Elements/SecurityReturns'
import { Transactions } from './Elements/Transactions'
import { Allocations } from './Elements/Allocations'
import { Wrapper, WrapperNP, NoData } from './Elements/Common'
import { ProfitLoss, PortfolioGains, PortfolioValue } from './Elements/ProfitLoss'

class Board extends React.PureComponent {
  constructor(props) {
    super(props);
		this.state = {
			activeFund: '',
      acView: 'holdings',
		}
    this.buyHandler = this.buyHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
  }

  componentDidMount() {

  }

  componentWillReceiveProps(newProps) {

  }

  buyHandler = (ticker) => {
    console.log(ticker);
    // setSFMState({
    //   items,
    // });
  }

  deleteHandler = (item) => {
    console.log(item);
    // const { dropFund, setPortfolioState } = this.props;
    // dropFund({
    //   ticker: item.symbol,
    // });
    // setPortfolioState({ summaryDrawer: false });
  }

  selectHandler = (item, index) => {
    console.log(item, index);
    // const { selectHandler } = this.props;
    // selectHandler(item, index);
  }

	holdingsItemClickHandler = (activeTicker) => {
    console.log(activeTicker);
		if (activeTicker) {
			this.setState({ activeFund: activeTicker });
		}
	}

  loadMoreAction = (type) => {
    this.setState({ acView: type });
  }

  render() {
    const { funds, retData, fundStats } = this.props;
		const { activeFund, acView } = this.state;

    const hdExpand = acView === 'holdings',
          txExpand = acView === 'transactions';

    // console.log(retData);
    const sortedFunds = _.orderBy(fundStats.funds, ['market_value'], ['desc'])
    let _activeFund = activeFund !== '' ? activeFund : sortedFunds[0].ticker;
    let securityReturns = retData && retData.length > 0 && retData.find((jk) => jk.ticker === _activeFund);

    securityReturns = securityReturns || (funds && funds.length > 0 && funds.find((jj) => jj.ticker === _activeFund) && funds.find((jj) => jj.ticker === _activeFund).return);

    return (
      <Row className="summary-wrapper pb-5 OpenSummaryPage-wrapper">
        <Row gutter={[16, 8]} className="compact OpenSummaryPage-row" style={{ margin: 0 }}>
          <Col className="gutter-row OpenSummaryPage-col my-1" span={8}>
            <Row gutter={8} className="OpenSummaryPage-block" style={{ paddingBottom: 8 }}>
              <PortfolioValue stats={fundStats} />
            </Row>
            <Row gutter={8} className="OpenSummaryPage-block" style={{ paddingTop: 8 }}>
              <PortfolioGains stats={fundStats} />
            </Row>
          </Col>
          <Col className="gutter-row OpenSummaryPage-col" span={16}>
            <Row className="OpenSummaryPage-block my-1 bg-white rounded border border-gray-2 h-100">
              <Col className="OpenSummaryPage-col p-3" span={11}>
                <ProfitLoss stats={fundStats} />
              </Col>
              <Divider type="vertical" style={{ top: '20%', height: '60%', margin: '0 auto' }}></Divider>
              <Col className="OpenSummaryPage-col p-3" span={11}>
                <Allocations stats={fundStats} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 8]} className="compact OpenSummaryPage-row" style={{ margin: 0 }}>
          <Col className="gutter-row OpenSummaryPage-col" span={12}>
            <Row className="OpenSummaryPage-block my-1 bg-white rounded border border-gray-2">
              <Holdings
                title="Fund Holdings"
                items={sortedFunds}
                actions={FundActions}
                expand={hdExpand}
                loadMoreAction={this.loadMoreAction}
                deleteHandler={this.deleteHandler}
                buyHandler={this.buyHandler}
                selectHandler={this.selectHandler}
                itemClickHandler={this.holdingsItemClickHandler}
              />
            </Row>
          </Col>
          <Col className="gutter-row OpenSummaryPage-col" span={12}>
            <Row className="OpenSummaryPage-block my-1 bg-white rounded border border-gray-2 security-block">
              {securityReturns && securityReturns._series ? (
                <SecurityReturns items={securityReturns} />
              ) : (
                <NoData height='225px' />
              )}
            </Row>
          </Col>
        </Row>
      </Row>
    )
  }
}

// export default connect(
//   state => ({
// 		portfolioName: state.enhancer.portfolioName,
// 	  summary: state.enhancer.summary,
// 		retData: state.enhancer.summary.retData,
// 		activeFund: state.enhancer.summary.activeFund,
// 	  onboardData: state.enhancer.onboardData,
// 		funds: state.enhancer.summary.portfolioFunds, //getSummaryFunds(state),
//     fundStats: getSummaryFunds(state),
//   }),
//   {
//     // toggleModal,
//   }
// )(Board);

export default Board;

const FundActions = () => {
  return (
    <React.Fragment>
      <Button color={"select"} outline
        className="h6 mb-0 py-1 badge-pill">Buy</Button>
      <Button color={"select"} outline
        className="h6 mb-0 mt-2 py-1 badge-pill">Drop</Button>
    </React.Fragment>
  )
}

import zipObject from 'lodash/zipObject'

const actions = [
  'SET_BUILD_PORTFOLIIO_STATE',
  'GET_BUILD_PORTFOLIO_FUNDS',

  'EQUITY_BATCH_REQUEST',
  'EQUITY_BATCH_FETCH',
  'EQUITY_BATCH_SUCCESS',

  'BONDS_BATCH_REQUEST',
  'BONDS_BATCH_FETCH',
  'BONDS_BATCH_SUCCESS',

  'THEMATIC_BATCH_REQUEST',
  'THEMATIC_BATCH_FETCH',
  'THEMATIC_BATCH_SUCCESS',

  'CUSTOM_TICKER_REQUEST',
  'CUSTOM_TICKER_FETCH',
  'CUSTOM_TICKER_SUCCESS',

  'TICKER_DATA_REQUEST',
  'TICKER_DATA_FETCH',
  'TICKER_DATA_SUCCESS',

  'DELETE_TICKER_DATA',

  'THEMES_REQUEST',
  'THEMES_FETCH',
  'THEMES_SUCCESS',

  'SET_WEIGHTS_REQUEST',
  'SET_WEIGHTS_FETCH',
  'SET_WEIGHTS_SUCCESS',

  'SET_SUMMARY_REQUEST',
  'SET_SUMMARY_FETCH',
  'SET_SUMMARY_SUCCESS',

  'FINALIZE_SAVE_PORTFOLIO_REQUEST',
  'FINALIZE_SAVE_PORTFOLIO_FETCH',
  'FINALIZE_SAVE_PORTFOLIO_SUCCESS',

  'SEARCH_NEW_QUERY_REQUEST',
  'SEARCH_NEW_QUERY_FETCH',
  'SEARCH_NEW_QUERY_SUCCESS',

  'PORTFOLIO_DATA_REQUEST',
  'PORTFOLIO_DATA_FETCH',
  'PORTFOLIO_DATA_SUCCESS',

  'PRE_SELECTED_THEMATIC_TICKERS_REQUEST',
  'PRE_SELECTED_THEMATIC_TICKERS_FETCH',
  'PRE_SELECTED_THEMATIC_TICKERS_SUCCESS',

  'RESET_BUILD_PORTFOLIO_STATE',

  'EDIT_PORTFOLIO_REQUEST',
  'EDIT_PORTFOLIO_SUCCESS',

  'UPLOAD_BUILDER_PORTFOLIO_REQUEST',

  'CHECK_BUILDER_TICKER_EXIST_REQUEST',
  'CHECK_BUILDER_TICKER_EXIST_FETCH',
  'CHECK_BUILDER_TICKER_EXIST_SUCCESS',
  'SET_CHART_DATA',

  'DOWNLOAD_BUILDER_REPORT',

  'LOAD_DEMO_CSV_REQUEST',
  'LOAD_DEMO_CSV_FETCH',
  'LOAD_DEMO_CSV_SUCCESS',

  'CUSTOM_WEIGHT_INPUT',
  'REMOVE_ZERO_WEIGHT_FUNDS',

  'DELETE_PORTFOLIO_BUILDER_BY_ID_REQUEST',
  'DELETE_PORTFOLIO_BUILDER_BY_ID_FETCH',
  'DELETE_PORTFOLIO_BUILDER_BY_ID_SUCCESS',


]

export default zipObject(actions, actions)

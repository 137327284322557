import React from 'react';
import moment from 'moment'

import { numberCheck, checkValidNumber } from 'layouts/utils'

import BestTickerBadge from '../shared/BestTickerBadge';
import { withHeaderFooter } from '../shared/HeaderFooter';
// import { fixedValue, handleNoData, mul100 } from '../shared/Utils'

const TotalReturnsTable = withHeaderFooter((props) => {
  console.log('-- TotalReturnsTable --');
  console.log(props);
  console.log('-----------------------');
  const { createdDate, selFunds, yearlyReturnsData, bestTicker } = props;
  const numberOfYears = 10;
  const startYear = parseInt(moment().subtract(10, 'year').format('YYYY'));
  let years = [];
  for (let i = 0; i <= numberOfYears; i++) {
    years.push(startYear + i);
  }
  let returnsYear = [
    {
      code: 'ytd',
      label: 'YTD*',
    }, {
      code: '1 year',
      label: '1 year',
    }, {
      code: '3 year',
      label: '3 year',
    }, {
      code: '5 year',
      label: '5 year',
    }, {
      code: '10 year',
      label: '10 year',
    }, {
      code: 'Inception',
      label: 'Since Inception',
    }, {
      code: '_start',
      label: 'Since Inception Date',
    }
  ];

  const totalYears = years.length;
  // const currentDate = new Date(new Date().toLocaleString("en-US", { timeZone: "America/New_York" }));
  // const dateObj = {
  //   date: handleSingleCount(currentDate.getDate()),
  //   month: shortMonths[currentDate.getMonth()],
  //   year: currentDate.getFullYear(),
  // };
  // console.log(years);
  // console.log(returnsYear);
  // {dateObj.month} {dateObj.date}, {dateObj.year}
  // const geAnnualizedReturns = (fund, year) => {
  //   if (fund && fund.Fee_Risk_Return) {
  //     console.log('year --> ', year);
  //     console.log(`Annualized ${year} Returns -> `, fund.Fee_Risk_Return[`Annualized ${year} Returns`]);
  //     return checkValidNumber(fund.Fee_Risk_Return[`Annualized ${year} Returns`], 2)
  //   }
  //   return '-';
  // }
  return (
    <div className="content">
      <div className="pr-v2-perf-analytics">
        <div
          className="total-returns-wrapper"
          style={{
            marginBottom: '35px',
          }}
        >
          <div>
            <h4 class="pr-v2-sub-header">
              Total Return %
            </h4>
          </div>
          <table className="updown-table">
            <thead>
              <tr className="updown-tbl-header">
                <th
                  rowspan="2"
                  colspan="1"
                  style={{
                    minWidth: '100px',
                    width: '100px',
                    maxWidth: '100px',
                    textAlign: 'left',
                    paddingLeft: `20px`
                  }}
                >
                  Funds
                </th>
                <th
                  colspan={totalYears}
                >
                  Annualized Return %
                </th>
              </tr>
              <tr className="updown-tbl-sub-header">
                {returnsYear.map((item) => (
                  <th
                    className="border-rt"
                    style={{
                      minWidth: '100px',
                      width: '100px',
                      maxWidth: '100px',
                    }}
                  >
                    {item.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {selFunds.map((item, index) => {
                let returnsData = yearlyReturnsData.find(data => item.ticker in data);
                returnsData = returnsData ? returnsData[item.ticker] : [];
                return (
                  <tr key={index}>
                    <td className="border-lt-rt">
                      {item.ticker_short || '-'}
                      {(item.ticker_short && item.ticker_short === bestTicker) && (
                        <BestTickerBadge
                          style={{ paddingLeft: "10px" }}
                        />
                      )}
                    </td>
                    {returnsYear.map((yr) => {
                      let yearData = '';
                      if (yr.code === 'ytd') {
                        let fundsAttributeData = item['_sfStats'] || item['selectorAttrsData'];
                        let ytd_Value = (fundsAttributeData && typeof fundsAttributeData['returns_ytd'] !== 'undefined') ? (
                          fundsAttributeData['returns_ytd']
                        ) : null;
                        ytd_Value = numberCheck(ytd_Value, 'all')*100
                        yearData = checkValidNumber(ytd_Value, 2, '', '-');
                      } else if (yr.code === '_start') {
                        yearData = item.start_date ? moment(new Date(item.start_date)).format('MMM YYYY') : ''
                        //yearData = item[`${yr.code}`];
                      } else if (item &&
                        item.Fee_Risk_Return &&
                        typeof item.Fee_Risk_Return[`Annualized ${yr.code} Returns`] !== 'undefined'
                      ) {
                        yearData = checkValidNumber(item.Fee_Risk_Return[`Annualized ${yr.code} Returns`], 2, '', '-');
                      }
                      // else {
                      //   yearData = returnsData[0]['year_' + years[years.length - 1]];
                      //   yearData = mul100(yearData);
                      // }
                      // else {
                      //   yearData = returnsData[0]['year_' + years[years.length - 1]];
                      //   yearData = mul100(yearData); // handleNoData(fixedValue(mul100(yearData)));
                      //   console.log(item.ticker, 'ytd yearData ->', yearData)
                      // }
                      // else if (yr.code === 'ytd') {
                      //   yearData = returnsData[0]['year_' + years[years.length - 1]];
                      //   yearData = mul100(yearData); // handleNoData(fixedValue(mul100(yearData)));
                      //   console.log(item.ticker, 'ytd yearData ->', yearData)
                      // }
                      // return (
                      //   <td className="border-rt">{handleNoData(fixedValue(mul100(yearData)))}</td>
                      // )
                      return (
                        <td className="border-rt">{yearData}</td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div>
          <p className={'asteriskDisclaimer'}>* YTD as of {createdDate}.</p>
        </div>
      </div>
    </div>
  )
});

export default TotalReturnsTable;

// {returnsYear.map((item, index) => {
//   if (index === totalYears - 1) {
//     item = 'YTD*';
//   }
//   return (
//     <th
//       className="border-rt"
//       style={{
//         minWidth: '77px',
//         width: '77px',
//         maxWidth: '77px',
//       }}
//     >
//       {item}
//     </th>
//   )
// })}

// {selFunds.map((item, index) => {
//   let returnsData = yearlyReturnsData
//     .find(data => item.ticker in data);
//   returnsData = returnsData ? returnsData[item.ticker] : [];
//   return (
//     <tr key={index}>
//       <td className="border-lt-rt">{item.ticker_short || '-'}
//         {(item.ticker_short && item.ticker_short === bestTicker) && (
//           <BestTickerBadge
//             style={{ paddingLeft: "10px" }}
//           />
//         )}
//       </td>
//       {years.map((year, index) => {
//         let yearData = '';
//         if (returnsData.length > 0 && returnsData[0]) {
//           if (returnsData[0]['cumulative_returns_' + year]) {
//             yearData = returnsData[0]['cumulative_returns_' + year];
//           } else if (returnsData[0]['year_' + year]) {
//             yearData = returnsData[0]['year_' + year];
//           }
//         }
//         return (
//           <td className="border-rt">{handleNoData(fixedValue(mul100(yearData)))}</td>
//         )
//       })}
//     </tr>
//   )
// })}

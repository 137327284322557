import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, FormGroup } from 'reactstrap'
import moment from 'moment';
import { Card, Row, Col } from 'antd';

import {
  getFundsPortfolioBatchRequest,
  setEnhancerState,
  finalizePortfolioRequest,
  finalizePortfolioSuccess,
  savePortfolioRequest,
  downloadEnhancerReport,
  downloadRecommendationEnhancerReport,
  setEnhancedTransaction,
  resetFinalizeDataRequest,
  useSuggestedFundsRequest,
} from 'actions/enhancer';
import { setBuildPortfolioState } from 'actions/buildportfolio';
import { getPortAccountStats } from 'selectors/investment';
import { createLoadingSelector } from 'selectors/loading-selector';
import { getFinalizeSummaryFunds, getSummaryFunds } from 'selectors/enhancer';

import CustomAlert from 'components/CustomAlert';
import { addEventToAnalytics, getPortfolioBenchmarkOfSelectedTicker } from 'Utils';
import { REDIRECT_PATH } from 'DataSet';

import PortfolioModal from '../../Elements/PortfolioModal';
import ContentHead from './ContentHead';
import Board from './Board';
import SummaryBoard from '../Summary/Board';
import { Loader } from '../../../AccountsPage/Elements/Loader';
import { geopMeanCalc } from '../../../AccountsPage/utils';
import GenerateTradeList from './Elements/GenerateTradeList';

class Funds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      summaryDrawer: props.summaryDrawer,
      saveEnhancePortfolioModal: false,
      fileName: '',
      fileNameError: false,
      generateTradeList: false,
    };
  }

  componentDidMount() {
    const { location, onboardData, history, setEnhancerState, match, getFundsPortfolioBatchRequest, portfolioName, loading, redirectFrom } = this.props;
    this.executeQuery({
      location,
      onboardData,
      history,
      setEnhancerState,
      match,
      getFundsPortfolioBatchRequest,
      portfolioName,
      loading,
      redirectFrom,
    });
  }

  componentWillReceiveProps(nextProps) {
    const { location, onboardData, history, setEnhancerState, match, getFundsPortfolioBatchRequest, portfolioName, loading, redirectFrom } = nextProps;
    // console.log(location);
    // console.log(location.pathname.includes('/enhancer/dashboard/funds'));
    if (location !== this.props.location && location.search !== this.props.location.search && location.pathname && location.pathname.includes('/enhancer/dashboard/funds')) {
      this.executeQuery({
        location,
        onboardData,
        history,
        setEnhancerState,
        match,
        getFundsPortfolioBatchRequest,
        portfolioName,
        loading,
        redirectFrom,
      });
    }
  }

  componentDidUpdate() {
    const headerElement = document.getElementById("magnifi-header-wrapper");
    const portfolioContentHeaderElement = document.getElementById("funds-board-content-header");
    if (portfolioContentHeaderElement && headerElement) {
      const portfolioSummaryDrawerElement = document.getElementById("summaryDrawerElement");
      if (portfolioSummaryDrawerElement) {
        const drawerxContentElement = portfolioSummaryDrawerElement.getElementsByClassName("ant-drawer-content-wrapper")[0];
        drawerxContentElement.style.top = portfolioContentHeaderElement.offsetHeight + 15 + 'px';
      }
    }
  }

  executeQuery = (data) => {
    const {
      location,
      onboardData,
      history,
      setEnhancerState,
      match,
      getFundsPortfolioBatchRequest,
      portfolioName,
      loading,
      redirectFrom,
    } = data;
    try {
      if (match && !loading) {
        const id = match.params.id
        if (location && location.search) {
          const params = new URLSearchParams(location.search)
          if (params) {
            const universe = params.get('universe');
            const weight = params.get('weight');
            const similarityMeasures = params.get('similarity-measures');
            const query = params.get('q');
            const portfolioId = params.get('portfolioId');
            const portfolioType = params.get('portfolioType');
            const tickers = params.get('tickers');
            const _redirectPath = params.get('callbackPath');
            // console.log('=============== _redirectPath ===============');
            // console.log(_redirectPath);
            // console.log('=============================================');
            if (universe &&
              similarityMeasures &&
              query &&
              universe !== 'undefined' &&
              query !== 'undefined' &&
              similarityMeasures !== 'undefined' &&
              portfolioId &&
              portfolioType &&
              getFundsPortfolioBatchRequest &&
              portfolioId !== 'undefined' &&
              portfolioType !== 'undefined' &&
              tickers &&
              tickers !== 'undefined'
            ) {
              // 'etf,etn,mf,sma', ['etf,etn', 'mf', 'sma']  ['etf', 'etn', 'mf', 'sma']  ['mf', 'sma'] ['etf,etn', 'mf', 'sma']
              // console.log(universe);
              let _activeFundsUniverse = universe.split(',');
              // console.log(_activeFundsUniverse);
              if (universe.includes('etf,etn')) {
                let sdd = _activeFundsUniverse.filter((item) => (item !== 'etn' && item !== 'etf' && item !== 'etf,etn')) || [];
                // console.log(sdd);
                sdd.push('etf,etn');
                // console.log(sdd);
                _activeFundsUniverse = sdd
              }
              // universe.includes('etf,etn') ? universe.split(',').filter((kl) => (kl !== 'etn' || kl !== 'etf')).push('etf,etn') : universe.split(',');
              // console.log(_activeFundsUniverse);
              let _activeSelectorUniverse = [];
              if (weight && typeof weight !== 'undefined' && weight.length > 0) {
                console.log('-- weight --', weight);
                _activeSelectorUniverse = ['udc']; // hardcode value for Rank Selector toggle
              }
              let _payloadRequest = {
                query,
                tickers,
                similarityMeasures,
                searchTypeMode: 'FUNDS',
                fundUniverse: universe,
                portfolioName: portfolioId,
                activeModelUniverse: [],
                activeFundUniverse: _activeFundsUniverse,
                activeSelectorUniverse: _activeSelectorUniverse,
              }
              if (_redirectPath && (_redirectPath !== 'undefined' || _redirectPath !== '')) { // check present in URL
                if (REDIRECT_PATH[_redirectPath]) { // url has VALUE
                  if (Object.keys(redirectFrom).length > 0) { // check redirectFrom must present
                    _payloadRequest.redirect = REDIRECT_PATH[_redirectPath];
                  } else { // return back to '_redirectPath'
                    if (history) {
                      history.push(REDIRECT_PATH[_redirectPath])
                    }
                  }
                } else {
                  console.log('-- _redirectPath not present --');
                }
              } else {
                console.log('-- _redirectPath not present --');
              }
              if (portfolioType === 'MarketPlace') {
                const sponsoredPortfolioName = params.get('sponsoredPortfolioName');
                console.log(sponsoredPortfolioName);
                if (sponsoredPortfolioName && typeof sponsoredPortfolioName !== 'undefined' && sponsoredPortfolioName !== '' && sponsoredPortfolioName !== null) {
                  _payloadRequest.sponsoredPortfolioName = sponsoredPortfolioName;
                  // setEnhancerState({
                  //   ..._payloadRequest,
                  //   onboardData: {
                  //     ...onboardData,
                  //     selectedPortfolioType: portfolioType,
                  //   }
                  // });
                  getFundsPortfolioBatchRequest({
                    ..._payloadRequest,
                    onboardData: {
                      ...onboardData,
                      selectedPortfolioType: portfolioType,
                    },
                    filename: portfolioId,
                    portfolioType: portfolioType,
                  }, () => {
                    console.log('=---- loaded ----=');
                  });
                } else {
                  throw 'sponsoredPortfolioName not found in url'
                }
              } else {
                // setEnhancerState({
                //   ..._payloadRequest,
                //   onboardData: {
                //     ...onboardData,
                //     selectedPortfolioType: portfolioType,
                //   }
                // });
                getFundsPortfolioBatchRequest({
                  ..._payloadRequest,
                  onboardData: {
                    ...onboardData,
                    selectedPortfolioType: portfolioType,
                  },
                  filename: portfolioId,
                  portfolioType: portfolioType,
                }, () => {
                  console.log('=---- loaded ----=');
                });
              }
            } else {
              throw 'data not found in url'
            }
          } else {
            throw 'data not found in url'
          }
        } else {
          throw 'data not found in url'
        }
      }
    } catch (e) {
      console.log(e);
      if (history) {
        const newPath = `/enhancer`
        history.push(newPath)
      }
    }
  }

  openDrawer = (visible) => {
    if (visible === true)
      addEventToAnalytics('Enhancer Summary', 'Enhancer Summary', 'ENHANCER_SUMMARY', {}, false);
    this.setState({
      summaryDrawer: visible,
    });
  }

  finalizeClick = (portfolioOverwrite = false, callback) => {
    try {
      const {
        finalizePortfolioRequest,
        finalizePortfolioSuccess,
        enhanceReplace,
        portfolioName,
        onboardData,
        enhancer,
        redirectFrom,
      } = this.props;

      //if replacement ticker is not selected or
      let TempResultSet = enhancer.fundPortfolios
      let TempEnhanceReplace = enhancer.enhanceReplace

      let replacementNotFound = TempResultSet.filter((jk) => !jk.result.funds.length)
      let replacementNotChosen = TempResultSet.filter((jk) => jk.result.funds.length > 0 && TempEnhanceReplace.find(g => (g.ticker === jk.ticker && g.ticker === g.replace)))

      const _enhanceReplace = enhanceReplace.filter((item) => item.ticker !== item.replaceTicker);
      if (_enhanceReplace &&
        _enhanceReplace.length > 0 &&
        finalizePortfolioRequest &&
        portfolioName &&
        portfolioName !== '' &&
        onboardData &&
        onboardData.selectedPortfolioType) {
        const { fundPortfolios, summary: _summaryData } = enhancer;
        const { portfolioFunds: _summaryPortfolioFunds, invalidTickerFunds: _invalidTickerFunds } = _summaryData;
        let requestDataArr = fundPortfolios.length > 0 ? fundPortfolios.map((l) => {
          if (l.target_card && l.target_card.funds && l.target_card.funds.length > 0) {
            const dd = l.target_card.funds.map((p) => {
              return {
                ticker: p.ticker,
                shares: p._pF.quantity,
              }
            });
            if (dd && dd.length > 0) {
              return dd[0];
            }
          }
        }) : [];
        console.log(requestDataArr);
        let enhanceOptionObj = {
          "portfolio_name": portfolioName,
          "replace": _enhanceReplace,
          "enhanceCTA": "Finalize",
          "portfolioType": onboardData.selectedPortfolioType,
          replacementNotFound,
          replacementNotChosen
          // data: requestDataArr,
        };
        // builder/enhancer enhancement 'save & return' button case
        if (portfolioOverwrite) {
          enhanceOptionObj.portfolio_overwrite = true;
        } else {
          delete enhanceOptionObj.portfolio_overwrite;
        }

        if ((onboardData.selectedPortfolioType === "MarketPlace") || (redirectFrom && redirectFrom.type === 'model_portfolio')) {
          if (_summaryPortfolioFunds && _invalidTickerFunds) {
            const foundInvalidTickersData = _invalidTickerFunds.map((jk) => {
              return {
                ticker: jk.ticker,
                invalid: jk.invalid,
                original_weight: jk.original_weight,
                shares: jk.shares || 0,
              }
            });
            const foundValidTickersData = _summaryPortfolioFunds.map((jk) => {
              if (jk.ticker && requestDataArr.find((h) => h.ticker === jk.ticker)) {
                return {
                  ticker: jk.ticker,
                  invalid: jk.invalid,
                  original_weight: jk.original_weight,
                  shares: jk.shares || 0,
                }
              }
            });
            requestDataArr = [...foundValidTickersData, ...foundInvalidTickersData];
            enhanceOptionObj.data = requestDataArr; //[...requestDataArr, ...foundInvalidTickersData];
          } else {
            enhanceOptionObj.data = requestDataArr;
          }
        }
        // if (redirectFrom && redirectFrom.type === 'model_portfolio') {
        //   enhanceOptionObj.data = requestDataArr;
        // }
        // console.log(enhanceOptionObj);
        finalizePortfolioRequest(enhanceOptionObj, (response) => {
          // console.log(response);
          if (response) {
            if (portfolioOverwrite) {
              finalizePortfolioSuccess();
              if (callback && typeof callback === 'function') {
                callback()
              }
            } else {
              finalizePortfolioSuccess({
                finalizeData: { ...response },
              });
            }
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  generateProposalClick = (isRecommendation = false) => {
    // console.log('call generateProposal api');
    isRecommendation ? this.downloadRecommendationPDF() : this.downloadEnhancerPDF()
  }

  generateTradeListClick = () => {
    // console.log('call generateTradeList api');
    addEventToAnalytics('Enhancer Tradelist', 'Enhancer Tradelist', 'ENHANCER_TRADELIST', {}, false);
    this.tradeList()
  }

  tradeList = () => {
    this.setState({
      generateTradeList: !this.state.generateTradeList,
      alert: false,
    })
    const { finalizeData } = this.props;
    this.props.setEnhancedTransaction(finalizeData.portfolioName);
  }

  onCloseTradeList = () => {
    this.setState({
      generateTradeList: !this.state.generateTradeList,
    }, () => {
      setTimeout(() => {
        this.setState({
          alert: !this.state.alert,
        });
      }, 2000)
    })
  }

  onDownloadTradeComplete = () => {
    // const { setEnhancerState } = this.props
    this.setState({
      alert: !this.state.alert,
    }, () => {
      // setEnhancerState({ enhanceReplace: [], enhanceTickerFilter: [], resultSet: [], filterResultSet: [] })
    })
  }

  DirectCloseTrade = () => {
    this.setState({
      generateTradeList: !this.state.generateTradeList,
    })
  }

  downloadRecommendationPDF = () => {
    // console.log('rec report')
    const { downloadRecommendationEnhancerReport } = this.props
    let out = {
      positionsOut: {}
    }

    const { portfolio, profile, view, watchlistFundstats, enhancer, accountFundstats, downloadRecEnhancerReport, portfolioFunds } = this.props;
    //if replacement ticker is not selected or
    let TempResultSet = JSON.parse(JSON.stringify(enhancer.fundPortfolios))
    let TempEnhanceReplace = JSON.parse(JSON.stringify(enhancer.enhanceReplace))
    let { fundUniverse, modelUniverse } = enhancer
    let obj = TempResultSet.filter((jk) => TempEnhanceReplace.find(g => (g.ticker === jk.ticker && g.ticker !== g.replace)))
    let replacementNotFound = TempResultSet.filter((jk) => !jk.result.funds.length)
    let replacementNotChosen = TempResultSet.filter((jk) => jk.result.funds.length > 0 && TempEnhanceReplace.find(g => (g.ticker === jk.ticker && g.ticker === g.replace)))
    out.data = obj.map((v, i) => {
      let count = 0
      let temp = JSON.parse(JSON.stringify(v))
      temp.target_card = temp.target_card.funds[0]

      temp.result.funds.map(d => {
        let _foundSelected = TempEnhanceReplace.find(g => (g.ticker === v.ticker && g.ticker !== g.replace)) //TempEnhanceReplace.find(g => (g.replace === d.ticker && g.ticker === v.ticker && g.ticker !== g.replace)) // return selected card for Each row
        if (_foundSelected && typeof _foundSelected.selectedPortfolioFunds !== 'undefined') {
          temp.selected = _foundSelected.selectedPortfolioFunds;
        }
      })
      return temp
    })

    // out.data must have 'selected' before calling api
    out.queryEnglish = enhancer.query_english
    out.logo = profile.profile.custom_report_logo
    out.searchTypeMode = enhancer.searchTypeMode
    out.replacementNotFound = replacementNotFound
    out.replacementNotChosen = replacementNotChosen
    out.portfolioName = enhancer.portfolioName
    out.fundUniverse = fundUniverse
    out.modelUniverse = modelUniverse
    // console.log(out)

    if (enhancer && enhancer.summary && enhancer.summary.invalidTickerFunds && enhancer.summary.invalidTickerFunds.length > 0) {
      const { summary: _summaryData } = enhancer;
      const { portfolioFunds: _summaryPortfolioFunds, invalidTickerFunds: _invalidTickerFunds } = _summaryData;
      const textWithOnlyTicker = _invalidTickerFunds.map((item) => item.ticker).filter((kl) => kl !== 'undefined').join(', ').replace(/, ([^,]*)$/, ' and $1');
      const textWithTickerAndWeight = _invalidTickerFunds.map((item) => {
        const ogW = (item.original_weight !== null && item.original_weight !== '' && typeof item.original_weight !== 'undefined') ? Number(item.original_weight) : null;
        if (item.ticker && ogW !== null && ogW !== '') {
          return `${item.ticker}(${parseFloat(ogW.toFixed(2))}%)`
        }
      }).filter((kl) => kl !== 'undefined').join(', ').replace(/, ([^,]*)$/, ' and $1');
      out.invalidTickerData = {
        array: _invalidTickerFunds,
        textWithOnlyTicker,
        textWithTickerAndWeight,
      };
    }
    if (enhancer && enhancer.summary && enhancer.summary.tickerNotSupported && enhancer.summary.tickerNotSupported.length > 0) {
      const { summary: _summaryData } = enhancer;
      const { portfolioFunds: _summaryPortfolioFunds, tickerNotSupported: _tickerNotSupported } = _summaryData;
      const textWithOnlyTicker = _tickerNotSupported.map((item) => item.ticker).filter((kl) => kl !== 'undefined').join(', ').replace(/, ([^,]*)$/, ' and $1');
      const textWithTickerAndWeight = _tickerNotSupported.map((item) => {
        const ogW = (item.original_weight !== null && item.original_weight !== '' && typeof item.original_weight !== 'undefined') ? Number(item.original_weight) : null;
        if (item.ticker && ogW !== null && ogW !== '') {
          return `${item.ticker}(${parseFloat(ogW.toFixed(2))}%)`
        }
      }).filter((kl) => kl !== 'undefined').join(', ').replace(/, ([^,]*)$/, ' and $1');
      out.tickerNotSupported = {
        array: _tickerNotSupported,
        textWithOnlyTicker,
        textWithTickerAndWeight,
      };
    }
    console.log(out)
    let analytics_data = enhancer.enhanceReplace.map(item => {
      return {
        ticker: item.ticker,
        replace: item.replace
      }
    })
    addEventToAnalytics('Rec-Report Download Request', 'Rec-Report Download Request', 'REC-REPORT DOWNLOAD REQUEST', { funds: analytics_data, category: 'advisory_PTC' }, false);
    // localStorage.setItem('rec2', JSON.stringify(out))
    downloadRecommendationEnhancerReport(out)
  }

  downloadEnhancerPDF = () => {
    // console.log('enh report')
    addEventToAnalytics('Enhancer Report', 'Enhancer Report', 'ENHANCER_REPORT', {}, false);
    //modelEnhancement , portfolio
    const { view, enhancer, portfolioValue, portfolio, fundSelector, profile, modelEnhancement, downloadEnhancerReport } = this.props;
    let logo = profile.profile.custom_report_logo ? profile.profile.custom_report_logo : ''
    let fundStats = getFinalizeSummaryFunds(enhancer)
    let { fundUniverse, modelUniverse } = enhancer
    // console.log({ view, fundStats, portfolioValue, portfolio, fundSelector, logo, modelEnhancement })
    if (fundStats && fundStats.funds) {
      // const { positions } = fundStats;
      // const ALL_FUNDS_TICKER = uniqValues(positions, 'symbol');

      let enhanceReplace = enhancer.enhanceReplace;
      enhanceReplace = enhanceReplace.filter((k) => k.ticker !== k.replace); // selected EVALUATE & Selected FUNDS; //added for not displaying funds with no recomendations
      enhanceReplace.forEach((e, i) => {
        let itemResultSet = enhancer.fundPortfolios.filter(el => el.ticker == e.ticker)[0];
        if (!this.props.hideGenerateProposalBtn && enhanceReplace[i].selectedPortfolioFunds && itemResultSet && itemResultSet.target_card && itemResultSet.target_card.funds[0]) {
          enhanceReplace[i].selectedPortfolioFunds.target_card = itemResultSet.target_card.funds[0];
          // getting =>'_frs: undefined' && didnt see '_frs' use anywhere, thats why commented below logic
          // const selectedPortfolioTicker = enhanceReplace[i].selectedPortfolioFunds.ticker;
          // enhanceReplace[i].selectedPortfolioFunds._frs = itemResultSet.result.funds.find(item => item.ticker === selectedPortfolioTicker)._frs;
        }
      });
      const obj = {
        investment: {
          portfolioValue: enhancer.portfolioValue
        },
        portfolio: {
          //mp query toggle
          isModelPortfolioQuery: false,
          query: enhancer.query,
          query_english: enhancer.query_english,
          enhanceReplace: enhanceReplace,
          fundSelector: fundSelector.myScoreAttrs,
          enhancedPerformanceImpact: enhancer.performanceImpact.currentPerformanceImpact,
          currentPerformanceImpact: enhancer.finalizeData.finalizePerformanceImpact.currentPerformanceImpact,
          selectedBenchmark: enhancer.performanceImpact.selectedBenchmark,
          logo,
          modelEnhancement: {},
          replacementNotChosen: enhancer.finalizeData.replacementNotChosen,
          replacementNotFound: enhancer.finalizeData.replacementNotFound,
        },
        portfolioName: enhancer.portfolioName,
        fundUniverse,
        modelUniverse
      };
      // console.log(obj)
      // obj.portfolio.currentPerformanceImpact.averageCostAdjRet10Yr /= 100;
      // obj.portfolio.enhancedPerformanceImpact.averageCostAdjRet10Yr /= 100;
      // console.log('generate proposal', obj)

      if (enhancer && enhancer.summary && enhancer.summary.invalidTickerFunds && enhancer.summary.invalidTickerFunds.length > 0) {
        const { summary: _summaryData } = enhancer;
        const { portfolioFunds: _summaryPortfolioFunds, invalidTickerFunds: _invalidTickerFunds } = _summaryData;
        const textWithOnlyTicker = _invalidTickerFunds.map((item) => item.ticker).filter((kl) => kl !== 'undefined').join(', ').replace(/, ([^,]*)$/, ' and $1');
        const textWithTickerAndWeight = _invalidTickerFunds.map((item) => {
          const ogW = (item.original_weight !== null && item.original_weight !== '' && typeof item.original_weight !== 'undefined') ? Number(item.original_weight) : null;
          if (item.ticker && ogW !== null && ogW !== '') {
            return `${item.ticker}(${parseFloat(ogW.toFixed(2))}%)`
          }
        }).filter((kl) => kl !== 'undefined').join(', ').replace(/, ([^,]*)$/, ' and $1');
        obj.invalidTickerData = {
          array: _invalidTickerFunds,
          textWithOnlyTicker,
          textWithTickerAndWeight,
        };
      }
      if (enhancer && enhancer.summary && enhancer.summary.tickerNotSupported && enhancer.summary.tickerNotSupported.length > 0) {
        const { summary: _summaryData } = enhancer;
        const { portfolioFunds: _summaryPortfolioFunds, tickerNotSupported: _tickerNotSupported } = _summaryData;
        const textWithOnlyTicker = _tickerNotSupported.map((item) => item.ticker).filter((kl) => kl !== 'undefined').join(', ').replace(/, ([^,]*)$/, ' and $1');
        const textWithTickerAndWeight = _tickerNotSupported.map((item) => {
          const ogW = (item.original_weight !== null && item.original_weight !== '' && typeof item.original_weight !== 'undefined') ? Number(item.original_weight) : null;
          if (item.ticker && ogW !== null && ogW !== '') {
            return `${item.ticker}(${parseFloat(ogW.toFixed(2))}%)`
          }
        }).filter((kl) => kl !== 'undefined').join(', ').replace(/, ([^,]*)$/, ' and $1');
        obj.tickerNotSupported = {
          array: _tickerNotSupported,
          textWithOnlyTicker,
          textWithTickerAndWeight,
        };
      }

      downloadEnhancerReport(obj);
    }
  }

  onAlertDismiss = () => {
    const { setEnhancerState } = this.props
    setEnhancerState({
      pdfReportDownloadStatus: {
        message: '',
        downloadStatus: ''
      }
    });
  }

  // enhancing from Builder page -> Save & Return function execution logic
  savePortfolioClick = () => {
    // console.log('open popup for portfolio name');
    const { savePortfolioRequest, finalizeData, onboardData, history, redirectToPath, redirectFrom, setEnhancerState, setBuildPortfolioState, resetFinalizeDataRequest } = this.props;
    try {
      if (finalizeData &&
        finalizeData.portfolioName &&
        finalizeData.summary &&
        finalizeData.onboardData &&
        finalizeData.enhancedPortfolioId &&
        finalizeData.onboardData.selectedPortfolioType &&
        typeof finalizeData.summary !== 'undefined' &&
        typeof finalizeData.summary.retData !== 'undefined' &&
        finalizeData.summary.retData.length > 0 &&
        typeof finalizeData.summary.portfolioFunds !== 'undefined' &&
        finalizeData.summary.portfolioFunds.length > 0) {

        const enhancedPortfolioId = finalizeData.enhancedPortfolioId || undefined;
        const portfolioType = finalizeData.onboardData.selectedPortfolioType || onboardData.selectedPortfolioType || 'My Portfolio';
        const enhancedPortfolioName = finalizeData.portfolioName || undefined;

        if (redirectFrom &&
          Object.keys(redirectFrom).length > 0 &&
          typeof redirectFrom.module !== 'undefined' &&
          redirectFrom.module === 'builder' &&
          typeof redirectFrom.savedPortfolio !== 'undefined' &&
          redirectFrom.portfolioName !== '' &&
          typeof redirectFrom.portfolioName !== 'undefined' &&
          resetFinalizeDataRequest &&
          setBuildPortfolioState &&
          setEnhancerState &&
          savePortfolioRequest
        ) {
          this.finalizeClick(true, () => {
            let updatedPortfolio = {
              ...redirectFrom,
              portfolioName: enhancedPortfolioName, // redirectFrom.portfolioName,
              returnPortfolioID: enhancedPortfolioId,
              savedPortfolio: {
                ...redirectFrom.savedPortfolio,
                portfolio_id: enhancedPortfolioId,
                portfolio_name: enhancedPortfolioName, //After saving, pass new Enhanced Portfolio Name to builder/client
              },
            }
            // console.log('==[=]==[=]==[=]==[=]==');
            // console.log(updatedPortfolio);
            // console.log('======================');
            setBuildPortfolioState({
              portfolioName: redirectFrom.portfolioName,
              savedPortfolio: {
                ...redirectFrom.savedPortfolio,
                portfolio_id: enhancedPortfolioId,
                portfolio_name: enhancedPortfolioName, //After saving, pass new Enhanced Portfolio Name to builder/client
              },
            });
            // NOTE: ALL STATE WILL BE RESET TO `initialState` except the state which is passed.
            // NOTE: PASS STATE TO ASSIGN STATE AFTER RESET
            resetFinalizeDataRequest({
              redirectFrom: updatedPortfolio,
              searchTypeMode: 'FUNDS', // NOTE: NEED TO SET DEFAULT
            });
            if (redirectToPath && redirectToPath !== '') {
              history.push(redirectToPath);
            }
          })
        } else {
          this.setState({ saveEnhancePortfolioModal: true });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  toggleSavePortfolioModal = () => {
    this.setState({ saveEnhancePortfolioModal: !this.state.saveEnhancePortfolioModal })
  }

  closeSavePortfolioModal = () => {
    this.setState({ saveEnhancePortfolioModal: false })
  }

  setFileName = (e) => {
    const name = document.getElementById('save-file-name-box').value;
    this.setState({ fileName: name })
  }

  savePortfolioEnhance = () => {
    try {
      addEventToAnalytics('Enhancer Save', 'Enhancer Save', 'ENHANCER_SAVE', {}, false);
    } catch (e) {
      console.log(e);
    }
    const { savePortfolioRequest, finalizeData, onboardData, history, redirectToPath, redirectFrom, setEnhancerState, setBuildPortfolioState, resetFinalizeDataRequest } = this.props;
    try {
      if (finalizeData &&
        finalizeData.portfolioName &&
        finalizeData.summary &&
        finalizeData.onboardData &&
        finalizeData.enhancedPortfolioId &&
        finalizeData.onboardData.selectedPortfolioType &&
        typeof finalizeData.summary !== 'undefined' &&
        typeof finalizeData.summary.retData !== 'undefined' &&
        finalizeData.summary.retData.length > 0 &&
        typeof finalizeData.summary.portfolioFunds !== 'undefined' &&
        finalizeData.summary.portfolioFunds.length > 0) {

        const enhancedPortfolioId = finalizeData.enhancedPortfolioId || undefined;
        const portfolioType = finalizeData.onboardData.selectedPortfolioType || onboardData.selectedPortfolioType || 'My Portfolio';

        if (this.state.fileName == '') {
          this.setState({ fileNameError: true })
        } else {
          if (savePortfolioRequest && resetFinalizeDataRequest) {
            savePortfolioRequest({
              portfolio_id: enhancedPortfolioId,
              portfolio_name: this.state.fileName,
              portfolioType,
            }, (response) => {
              console.log('-- saved! --');
              console.log(response);
              console.log('-- -- -- -- --');
              this.setState({ fileName: '', fileNameError: false }, () => {
                // console.log(redirectFrom);
                if (redirectFrom && Object.keys(redirectFrom).length > 0 && typeof redirectFrom.payload !== 'undefined') {
                  let { payload, payload: { clientDetails } } = redirectFrom;
                  let updatedPortfolio = {
                    ...redirectFrom,
                    payload: {
                      ...payload,
                      clientDetails: {
                        ...clientDetails,
                        custom_portfolio: {
                          portfolio_name: response.portfolioId,
                        },
                        model_portfolio: {},
                        selected_portfolio: response.portfolioId,
                      }
                    },
                    portfolioName: response.portfolioId,
                    returnPortfolioID: enhancedPortfolioId
                  }
                  console.log('==[=]==[=]==[=]==[=]==');
                  console.log(updatedPortfolio);
                  console.log('======================');

                  setEnhancerState({
                    redirectFrom: updatedPortfolio,
                    searchTypeMode: 'FUNDS', // NOTE: NEED TO SET DEFAULT
                    finalizeData: {},
                  });

                  // NOTE: ALL STATE WILL BE RESET TO `initialState` except the state which is passed.
                  // NOTE: PASS STATE TO ASSIGN STATE AFTER RESET
                  resetFinalizeDataRequest({
                    redirectFrom: updatedPortfolio,
                    searchTypeMode: 'FUNDS', // NOTE: NEED TO SET DEFAULT
                  });
                  if (response && history) {
                    let urlParam = [];
                    for (var i in response) {
                      urlParam.push(encodeURIComponent(i) + "=" + encodeURIComponent(response[i]));
                    }
                    // redirect to client page
                    if (redirectToPath && redirectToPath !== '') {
                      history.push(redirectToPath);
                    }
                  }
                } else {
                  // NOTE: ALL STATE WILL BE RESET TO `initialState` except the state which is passed.
                  // NOTE: PASS STATE TO ASSIGN STATE AFTER RESET
                  resetFinalizeDataRequest();
                  if (response && history) {
                    let urlParam = [];
                    for (var i in response) {
                      urlParam.push(encodeURIComponent(i) + "=" + encodeURIComponent(response[i]));
                    }
                    const newPath = `/enhancer/dashboard/summary?${urlParam.join("&")}`;
                    history.push(newPath);
                  }
                }
              })
            });
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  handleUpDownDropDownChange = (value) => {
    const { setEnhancerState, performanceImpact } = this.props;
    const { enhancedPerformanceImpact, currentPerformanceImpact, upDownDataSet } = performanceImpact;

    if (setEnhancerState) {
      let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(upDownDataSet, value)
      // console.log(enhancedPerformanceImpact)

      if (Object.keys(enhancedPerformanceImpact).length > 0) {
        let { pfRet1y, pfRet3y, pfRet5y } = enhancedPerformanceImpact
        let { pfRet1y: pfRet1yC, pfRet3y: pfRet3yC, pfRet5y: pfRet5yC } = currentPerformanceImpact
        let { up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr } = geopMeanCalc(portfolioBenchmark, pfRet1y, pfRet3y, pfRet5y)
        let { up_1yr: up_1yrC, down_1yr: down_1yrC, up_3yr: up_3yrC, down_3yr: down_3yrC, up_5yr: up_5yrC, down_5yr: down_5yrC } = geopMeanCalc(portfolioBenchmark, pfRet1yC, pfRet3yC, pfRet5yC)
        let updateEnhanceStats = { ...enhancedPerformanceImpact, up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr }
        let updatedCurrentStats = { ...currentPerformanceImpact, up_1yr: up_1yrC, down_1yr: down_1yrC, up_3yr: up_3yrC, down_3yr: down_3yrC, up_5yr: up_5yrC, down_5yr: down_5yrC }

        setEnhancerState({
          performanceImpact: {
            ...performanceImpact,
            selectedBenchmark: value,
            currentPerformanceImpact: updatedCurrentStats,
            enhancedPerformanceImpact: updateEnhanceStats,
          }
        });

      } else {
        let { pfRet1y, pfRet3y, pfRet5y } = currentPerformanceImpact
        let { up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr } = geopMeanCalc(portfolioBenchmark, pfRet1y, pfRet3y, pfRet5y)
        let updatedCurrentStats = { ...currentPerformanceImpact, up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr }

        setEnhancerState({
          performanceImpact: {
            ...performanceImpact,
            selectedBenchmark: value,
            currentPerformanceImpact: updatedCurrentStats
          }
        });
      }
    }
  }

  render() {
    const {
      loading,
      enhancer,
      fundPortfolios,
      query,
      selectedWeightOptions,
      queryEnglish,
      searchTypeMode,
      performanceImpact,
      enhanceTickerFilter,
      enhanceReplace,
      finalizeData,
      savingPortfolioError,
      savingPortfolioErrorText,
      pdfReportLoader,
      pdfReportDownloadStatus,
      guidanceMessage,
      SuggestedFundsData,
      usingSuggestedFunds,
    } = this.props;

    const { currentPerformanceImpact, enhancedPerformanceImpact, upDownDataSet, selectedBenchmark } = performanceImpact;
    const { summaryDrawer, fileNameError, fileName, portfolioSaveError, saveEnhancePortfolioModal } = this.state;
    const renderTextQuery = (query === 'user_criteria') ? (`Best Alternatives Using ${selectedWeightOptions && selectedWeightOptions.name}`) : (queryEnglish || query);

    let _enhancedPerformanceImpact = enhancedPerformanceImpact || {};
    let _enhanceReplace = enhanceReplace || [];
    if (finalizeData &&
      finalizeData.portfolioName &&
      finalizeData.summary &&
      typeof finalizeData.summary !== 'undefined' &&
      typeof finalizeData.summary.retData !== 'undefined' &&
      finalizeData.summary.retData.length > 0 &&
      typeof finalizeData.summary.portfolioFunds !== 'undefined' &&
      finalizeData.summary.portfolioFunds.length > 0) {
      _enhancedPerformanceImpact = {} // hack
      _enhanceReplace = []
    }

    if (enhanceReplace.filter((item) => item.ticker !== item.replaceTicker).length === 0) {
      _enhancedPerformanceImpact = {} // hack
      _enhanceReplace = []
    }

    // console.log('_enhancedPerformanceImpact', _enhancedPerformanceImpact);
    let isItRenderingSummaryPageContent = (finalizeData &&
      finalizeData.portfolioName &&
      finalizeData.summary &&
      typeof finalizeData.summary !== 'undefined' &&
      typeof finalizeData.summary.retData !== 'undefined' &&
      finalizeData.summary.retData.length > 0 &&
      typeof finalizeData.summary.portfolioFunds !== 'undefined' &&
      finalizeData.summary.portfolioFunds.length > 0) || false;

    return (
      <React.Fragment>
        {!loading && (
          <div className="enhancer-funds-portfolio-container">
            <ContentHead
              {...this.props}
              summaryDrawer={summaryDrawer}
              readableQuery={queryEnglish}
              openDrawer={this.openDrawer}
              finalizeClick={this.finalizeClick}
              generateProposalClick={this.generateProposalClick}
              savePortfolioClick={this.savePortfolioClick}
              generateTradeListClick={this.generateTradeListClick}
            />
            {fundPortfolios && fundPortfolios.length > 0 ? (
              <>
                {(finalizeData &&
                  finalizeData.portfolioName &&
                  finalizeData.summary &&
                  typeof finalizeData.summary !== 'undefined' &&
                  typeof finalizeData.summary.retData !== 'undefined' &&
                  finalizeData.summary.retData.length > 0 &&
                  typeof finalizeData.summary.portfolioFunds !== 'undefined' &&
                  finalizeData.summary.portfolioFunds.length > 0) ? (

                  <SummaryBoard
                    {...this.props}
                    portfolioName={finalizeData.portfolioName}
                    summary={finalizeData.summary}
                    retData={finalizeData.summary.retData}
                    activeFund={finalizeData.summary.activeFund}
                    onboardData={finalizeData.onboardData}
                    funds={finalizeData.summary.portfolioFunds}
                    fundStats={getFinalizeSummaryFunds(enhancer)}
                  />
                ) : (
                  <Board />
                )}
              </>
            ) : (
              <Card className="card-background-grey empty-message-wrapper no-funds-wrapper">
                <img src='/assets/images/icons/NoFundsFound.svg' alt='' />
                <div className="text-box">
                  {(searchTypeMode === 'MODELS' && query === 'user_criteria') ? (
                    <span>The query may be applicable only for fund enhancements.</span>
                  ) : (
                    <><span>No funds</span> were found for <span>{renderTextQuery}</span></>
                  )}
                </div>
              </Card>
            )}
            {
              typeof currentPerformanceImpact !== 'undefined' &&
              typeof currentPerformanceImpact.pfRet1y !== 'undefined' &&
              typeof enhancedPerformanceImpact !== 'undefined' && (
                <PortfolioModal
                  closable
                  mask={false}
                  enhancePortfolioVisible={true}
                  replacementToggleEnable={true}
                  summaryPage={isItRenderingSummaryPageContent}
                  query={query}
                  visible={summaryDrawer}
                  portfolioEnhanceReplace={_enhanceReplace}
                  enhanceStats={_enhancedPerformanceImpact}
                  currentStats={currentPerformanceImpact}
                  enhanceTickerFilter={enhanceTickerFilter}
                  portfolioBenchmarkData={upDownDataSet}
                  selectedPortfolioBenchmarkTicker={selectedBenchmark}
                  usingSuggestedFunds={usingSuggestedFunds}
                  SuggestedFundsData={SuggestedFundsData}
                  openDrawer={this.openDrawer}
                  handleUpDownDropDownChange={this.handleUpDownDropDownChange}
                  setPfEnhancement={this.setPfEnhancement}
                  evaluateOnChange={this.evaluateOnChange}
                  setEnhancerState={this.props.setEnhancerState}
                  useSuggestedFundsRequest={this.props.useSuggestedFundsRequest}
                  originalQueryVar={fundPortfolios && fundPortfolios.length > 0 && fundPortfolios[0].result.query_var}
                  _portfolioEnhanceReplace={enhanceReplace} //as first render was not calculating _enhanceReplace
                />
              )
            }
            {(saveEnhancePortfolioModal) &&
              (
                <Modal
                  isOpen={saveEnhancePortfolioModal}
                  toggle={this.toggleSavePortfolioModal}
                  centered
                  keyboard={true}
                  backdrop
                  className="premium-msg-modal account-modal save-portfolio-modal"
                  backdropClassName="premium-feature-process-modal-backdrop"
                >
                  <ModalBody className="ssf-modal__body">
                    <div className="premium-feature-process-modal-container">
                      <div className="header-wrapper">
                        <h1 className="text-align-left">Save Your Enhanced Portfolio</h1>
                      </div>
                      <i className="fal fa-times-circle premium-feature-completed-close" onClick={this.closeSavePortfolioModal}></i>
                      <p className="content-wrapper">
                        <form className="upload-form-content">
                          <FormGroup className="element-box pos-rel">
                            <label>Name</label>
                            <input
                              id="save-file-name-box"
                              className="input-area"
                              type="text"
                              onChange={this.setFileName}
                              autoComplete="off"
                            />
                            {fileNameError && <span className="upload-error">Please enter a Name to save</span>}
                            {savingPortfolioError && <span className="upload-error">{savingPortfolioErrorText !== '' ? savingPortfolioErrorText : 'Error on file save'}</span>}
                          </FormGroup>
                          <div className="col-12 cta-wrapper ">
                            <button type="button" id="save-prtf" className="ssf-btn-primary btn secondary-btn btn btn-secondary" onClick={this.savePortfolioEnhance}>
                              <span>SAVE</span>
                            </button>
                          </div>
                        </form>
                      </p>
                    </div>
                  </ModalBody>
                </Modal>
              )
            }
          </div>
        )}
        {(this.state.generateTradeList) && (
          <GenerateTradeList onCloseClick={this.onCloseTradeList} onDownload={this.onDownloadTradeComplete} OnDirectClose={this.DirectCloseTrade} />
        )
        }
        {(this.state.alert) && (
          <CustomAlert className="alert-windown-wrapper" color='info'>
            Tradelist Download Successfully
          </CustomAlert>
        )
        }
        {(pdfReportDownloadStatus && pdfReportDownloadStatus.message !== '' && pdfReportDownloadStatus.downloadStatus !== '') && (
          <CustomAlert className="alert-windown-wrapper" color={pdfReportDownloadStatus.downloadStatus} onDismissCallback={this.onAlertDismiss}>
            {pdfReportDownloadStatus.message}
          </CustomAlert>
        )
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ loading, enhancer, portfolio, profile, investment, weights }) => ({
  enhancer,
  profile,
  fundSelector: weights,
  query: enhancer.query,
  summaryDrawer: enhancer.summaryDrawer,
  portfolioName: enhancer.portfolioName,
  fundPortfolios: enhancer.fundPortfolios || [],
  onboardData: enhancer.onboardData,
  queryEnglish: enhancer.query_english,
  searchTypeMode: enhancer.searchTypeMode,
  guidanceMessage: enhancer.guidanceMessage,
  performanceImpact: enhancer.performanceImpact || {},
  enhanceReplace: enhancer.enhanceReplace || [],
  enhanceTickerFilter: enhancer.enhanceTickerFilter || [],
  savingPortfolioError: enhancer.savingPortfolioError || false,
  savingPortfolioErrorText: enhancer.savingPortfolioErrorText || '',
  finalizeData: enhancer.finalizeData || {},
  redirectToPath: enhancer.redirect || '',
  redirectFrom: enhancer.redirectFrom || {},
  selectedWeightOptions: portfolio.selectedWeightOptions,
  SuggestedFundsData: enhancer.useSuggestedFunds || [],
  usingSuggestedFunds: enhancer.usingSuggestedFunds,
  // fundStats: getPortAccountStats(investment),
  pdfReportLoader: enhancer.pdfReportLoader,
  pdfReportDownloadStatus: enhancer.pdfReportDownloadStatus,
  summeryFunds: getSummaryFunds(enhancer),
  loading: createLoadingSelector(['GET_FUNDS_PORTFOLIO_DATA_BY_ID'])({ loading }),
})

const mapDispatchToProps = {
  getFundsPortfolioBatchRequest,
  setEnhancerState,
  finalizePortfolioRequest,
  finalizePortfolioSuccess,
  savePortfolioRequest,
  downloadEnhancerReport,
  downloadRecommendationEnhancerReport,
  setEnhancedTransaction,
  setBuildPortfolioState,
  resetFinalizeDataRequest,
  useSuggestedFundsRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Funds);

import React from 'react';
import { extent as d3Extent } from 'd3-array';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ResponsiveContainer, ReferenceLine } from 'recharts';
import { getNestDefault } from 'Utils';


export const RiskBarChart = ({ data, xAxisLabelClassName, yAxisLabelClassName }) => {
  if (typeof data === 'undefined' || typeof data.length === 'undefined' || data.length === 0) return null;

  // NOTE: value must be number not string
  const values = data.map(e => e.value);
  const [min, max] = [Math.min(...values), Math.max(...values)];
  const domain = [ min < 0 ? (min+min*0.30) : 0, max > 0 ? (max+max*0.30)  : 0 ];

  return (
    <ResponsiveContainer width="100%" height="100%" >
      <BarChart
        data={data}
        margin={{top: 15, right: 30, left: 10, bottom: 12}}
      >
        <XAxis
          dataKey="name"
          tickLine={false}
          y={10}
          axisLine={true}
          tick={
           <XAxisTick
             xAxisLabelClassName={xAxisLabelClassName}
             labelFormatter={labelPc}
           />
          }
          shapeRendering="crispEdges"
        />
        <YAxis
          domain={domain}
          axisLine={true}
          tick={<YAxisTick />}
          shapeRendering="crispEdges"
        />
        <ReferenceLine y={0} stroke="#707070" shapeRendering="crispEdges" />
        <CartesianGrid stroke="rgba(112, 112, 112, 0.16)" strokearray="3" shapeRendering="crispEdges" />
        <Tooltip content={<BarCustomTooltip />} cursor={false} isAnimationActive={false} />
        <Bar dataKey="value" unit="%"  maxBarSize={30} isAnimationActive={false} >
          <LabelList dataKey="value" position="top" className="text" formatter={labelPc} content={renderLabel} />
          { data.map((v, i) => <Cell key={`cell-${i}`} fill={i.color || "#1e5f91"}/>) }
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

const labelPc = label => label.toFixed(2)+'%';

const YAxisTick = (props) => {
  const {
    x, y, width, height, fill, stroke, payload
  } = props;

  if (payload) {
    const { coordinate, offset, tickCoord, isShow } = payload;
    const value = Math.round(payload.value);
    const xoff = (value < 10) ? ((value > -10) ? x-12 : x-16) : x-14;
    // value < 10 ? x-10 : x-20
    return (
      <text className="y-axis-label" x={30} y={y+5} fill={fill}>
        {value}
      </text>
    );
  }
}

const XAxisTick = (props) => {
  const {
    x, y, width, height, fill, payload
  } = props;

  if (payload) {
    const { coordinate, value, offset, tickCoord, isShow } = payload;
    return (
      <text className="x-axis-label" x={x} y={y+10} fill={fill} textAnchor="middle" dominantBaseline="middle">
        {value}
      </text>
    );
  }
}

const renderLabel = (props) => {
  const {
    x, y, width, height, value, fill
  } = props;

  let yForText = y - 10;

  if (value !== undefined && typeof value !== 'undefined') {
    if (value < 0) {
      yForText = y + 10;
    }
    return (
      <g>
        <text className="x-axis-label" x={x + width / 2} y={yForText} fill={fill} textAnchor="middle" dominantBaseline="middle">
          {value+'%'}
        </text>
      </g>
    );
  }
}

const BarCustomTooltip = (props) => {
  const { active, payload, label, vkey } = props;
  if (active && payload) {
    return (
      <div className="recharts-default-tooltip ssf-tooltip-custom">
        <div className="label">{label}</div>
        { payload.map((e, index) => (
          <div key={e.dataKey} className="d-flex" style={{ color: e.payload.color }}>
            {/*<span className="mr-2">{e.dataKey}</span>*/}
            <span className="ml-auto">{e.payload[e.dataKey]}</span>
            <span className="">{e.unit}</span>
          </div>
        )) }
      </div>
    );
  }

  return null;
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import {
  Input,
  Row,
  Col,
  FormGroup,
  Form,
  Alert,
  Label,
  NavLink
} from 'reactstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'

import {
  IconButton,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CustomFormFeedback,
  Spinner
} from 'shared-components'

import { createLoadingSelector } from 'selectors/loading-selector'
import { BrokersList } from '../DataSet'
import { completeBrokerSetup } from 'actions/broker'

class BrokerSignInContainer extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.importAccountsSuccess !== prevProps.importAccountsSuccess) {
      this.props.history.push(`/settings/broker`)
    }
  }

  brokerSchema = Yup.object().shape({
    token: Yup.string().required('Token is a required field')
  })

  handleToggle = () => {
    this.props.history.push('/settings/broker/select')
  }

  handleSubmit = values => {
    this.props.completeBrokerSetup({
      oauth_verifier: values.token,
      brokerName: this.props.match.params.brokerName
    })
  }

  render() {
    const { error, match, isFetching, redirectEndpoint } = this.props
    const brokerName = match.params.brokerName
    const broker = BrokersList.find(b => b.name === brokerName)

    if (!redirectEndpoint) {
      return <Redirect to="/settings/broker" />
    }

    return (
      <Modal
        isOpen={true}
        toggle={this.handleToggle}
        size="sm"
        keyboard
        centered
        className="brokers-signin"
      >
        <ModalHeader>
          <IconButton
            onClick={this.handleToggle}
            iconClass="fa-arrow-left"
            className="float-left"
          />
          Broker Sign In
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{ token: '' }}
            onSubmit={this.handleSubmit}
            validationSchema={this.brokerSchema}
            render={props => (
              <Form
                className="brokers-signin-form"
                noValidate={true}
                onSubmit={props.handleSubmit}
              >
                <FormGroup className="text-center">
                  <img src={broker.imgSrc} />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="token" className="sr-only">
                    Token
                  </Label>
                  <Input
                    type="text"
                    name="token"
                    id="token"
                    placeholder="Enter token"
                    value={props.values.token}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    className={
                      props.errors.token && props.touched.token
                        ? 'brokers-signin-form__input-field brokers-signin-form__input-token is-invalid'
                        : 'brokers-signin-form__input-field brokers-signin-form__input-token'
                    }
                  />
                  <CustomFormFeedback formProps={props} fieldName="token" />
                  {error && error.error && (
                    <Alert color="danger">{error.error}</Alert>
                  )}
                </FormGroup>
                <FormGroup>
                  <TertiaryButton
                    type="submit"
                    className="brokers-signin-form__submit"
                    block
                    disabled={props.isSubmitting}
                  >
                    Submit
                  </TertiaryButton>
                </FormGroup>
              </Form>
            )}
          />
          <Spinner isFetching={isFetching} />
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  importAccountsSuccess: state.importBrokerAccount.importAccountsSuccess,
  brokerSetupSuccess: state.broker.brokerSetupSuccess,
  redirectEndpoint: state.broker.redirectEndpoint,
  isFetching: createLoadingSelector(['COMPLETE_BROKER_SETUP'])(state)
})

const mapDispatchToProps = {
  completeBrokerSetup
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrokerSignInContainer)

import moment from 'moment';
import { FilterAttrData, DefaultSortAttr, DateRange, ViewNames } from '../DataSet';
import ActionTypes from '../action-types'

const initialState = {
  url: null,
  pip: false,
  playing: false,
  volume: 0.8,
  muted: false,
  played: 0,
  loaded: 0,
  duration: 0,
  playbackRate: 1.0,
  loop: false,
}

export function videoplayer(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case ActionTypes.REFRESH_VIDEO_STATE:
      return {
        url: null,
        pip: false,
        playing: false,
        volume: 0.8,
        muted: false,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: false,
      }
    case ActionTypes.LOAD_VIDEO:
      return {
        ...state,
        url: payload.url,
        pip: payload.pip,
        played: payload.played,
        loaded: payload.loaded,
        loop: payload.loop,
      }
    case ActionTypes.PLAY_PAUSE_VIDEO: // playPauseVideo(state, { payload }) {
      return {
        ...state,
        url: payload.url,
        playing: payload.playing,
      };
    case ActionTypes.PIP_VIDEO: //pipVideo(state, { payload }) {
      return {
        ...state,
        pip: payload.pip,
      };
    case ActionTypes.STOP_VIDEO: //stopVideo(state, { payload }) {
      return {
        ...state,
        url: payload.url,
        playing: payload.playing,
      };
    case ActionTypes.TOGGLE_LOOP_VIDEO: //toggleLoopVideo(state, { payload }) {
      return {
        ...state,
        loop: payload.loop,
      };
    case ActionTypes.SET_VOLUME_VIDEO: //setVolumeVideo(state, { payload }) {
      return {
        ...state,
        volume: payload.volume,
      };
    case ActionTypes.TOGGLE_MUTED_VIDEO: //toggleMutedVideo(state, { payload }) {
      return {
        ...state,
        muted: payload.muted,
      };
    case ActionTypes.SET_PLAYBACK_RATE_VIDEO: //setPlaybackRateVideo(state, { payload }) {
      return {
        ...state,
        playbackRate: payload.playbackRate,
      };
    case ActionTypes.ON_PLAY_VIDEO: //onPlayVideo(state, { payload }) {
      return {
        ...state,
        url: payload.url,
        playing: payload.playing,
      };
    case ActionTypes.ON_ENABLE_PIP_VIDEO: //onEnablePIPVideo(state, { payload }) {
      return {
        ...state,
        pip: payload.pip,
      };
    case ActionTypes.ON_DISABLE_PIP_VIDEO: //onDisablePIPVideo(state, { payload }) {
      return {
        ...state,
        pip: payload.pip,
      };
    case ActionTypes.ON_PAUSE_VIDEO: //onPauseVideo(state, { payload }) {
      return {
        ...state,
        url: payload.url,
        playing: payload.playing,
      };
    case ActionTypes.ON_SEEK_MOUSE_DOWN_VIDEO: //onSeekMouseDownVideo(state, { payload }) {
      return {
        ...state,
        seeking: payload.seeking,
      };
    case ActionTypes.ON_SEEK_CHANGE_VIDEO: //onSeekChangeVideo(state, { payload }) {
      return {
        ...state,
        played: payload.played,
      };
    case ActionTypes.ON_SEEK_MOUSE_UP_VIDEO: //onSeekMouseUpVideo(state, { payload }) {
      return {
        ...state,
        seeking: payload.seeking,
      };
    case ActionTypes.ON_PROGRESS_VIDEO: //onProgressVideo(state, { payload }) {
      return {
        ...state,
        seeking: payload.seeking,
      };
    case ActionTypes.ON_ENDED_VIDEO: //onEndedVideo(state, { payload }) {
      return {
        ...state,
        playing: payload.playing,
      };
    case ActionTypes.ON_DURATION_VIDEO: //onDurationVideo(state, { payload }) {
      return {
        ...state,
        duration: payload.duration,
      };
    case ActionTypes.UPDATE_VIDEO_STATE: //onDurationVideo(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    default:
      return state
  }
}

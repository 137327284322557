import React from 'react';
import {Cell, Pie, PieChart, Tooltip} from "recharts";

export const SimplePieChart = (props) => {
  const { data, colors, decimal, chartName, centerDisplayText, centerValueText } = props;
  const formatter = (value) => value.toFixed(decimal) + '%';

  const primaryTextStyle = (chartName === 'Top Holdings' || chartName === 'Sectors') ?  {
    // top: '39%',
    fontSize: 11,
    fontWeight: 'bold',
    zIndex: '5',
    left: '50%',
  }: {};

  return (
    <div className="siw-simplePieChartWrapper">
      {(chartName === 'Top Holdings' || chartName === 'Sectors' || chartName === 'Diversification') && (centerDisplayText !== '' && centerValueText !== '')
          && (
            <div className="primaryTextStyle" style={primaryTextStyle}
            >
              {centerValueText}
              <div className="highlightTooltip" style={{ left: '-12px' }}>{centerDisplayText}: {centerValueText}</div>
            </div>
          )
        }
      <PieChart width={80} height={80}>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx={35}
          cy={35}
          outerRadius={40}
          innerRadius={24}
          isAnimationActive
          fill="#8884d8"
        >
          {props.data.map((v, i) => (
            <Cell
              key={i}
              fill={v.color || colors[i % colors.length]}
            />
          ))}
        </Pie>
        <Tooltip
          formatter={formatter}
          itemStyle={{fontSize: '12px', padding: 0}}
        />
      </PieChart>
    </div>
  );
};

import React from 'react';
import ReactDOM from 'react-dom';
import { Tag, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

export default class InputChips extends React.Component {
  state = {
    selectedTickers: this.props.selectedTickers,
    inputVisible: false,
    inputValue: '',
  };

  handleClose = removedTag => {
    const selectedTickers = this.props.selectedTickers.filter(tag => tag !== removedTag);
    this.props.updateTickers(selectedTickers);
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { selectedTickers, updateTickers } = this.props;
    if (inputValue && selectedTickers.indexOf(inputValue.toUpperCase()) === -1) {
      selectedTickers = [...selectedTickers, inputValue.toUpperCase()];
      updateTickers(selectedTickers);
      this.setState({
        inputValue: '',
      });
    }
  };

  onKeyDown = (e) => {
    if (e.keyCode === 8 && !this.state.inputValue) {
      let { selectedTickers } = this.props;
      selectedTickers.pop();
      this.props.updateTickers(selectedTickers);
      // this.setState({ selectedTickers })
    }
  };

  clearSelectedTickers = () => {
    this.props.updateTickers([]);
    this.setState({ inputValue: '' })
  }

  saveInputRef = input => {
    this.input = input;
  };

  forMap = tag => {
    const tagElem = (
      <Tag
        closable
        onClose={e => {
          e.preventDefault();
          this.handleClose(tag);
        }}
      >
        <span className="tag-text">{tag}</span>
      </Tag>
    );
    return (
      <li key={tag}>
        {tagElem}
      </li>
    );
  };

  render() {
    const { inputValue } = this.state;
    const { selectedTickers, placeholder } = this.props;
    const tagChild = selectedTickers.map(this.forMap);
    return (
      <div className="input-chips-wrapper">
        <ul className="chips-list">
          {tagChild}
          <li class="input-chips">
            <Input
              ref={this.saveInputRef}
              type="text"
              size="small"
              value={inputValue}
              onChange={this.handleInputChange}
              onBlur={this.handleInputConfirm}
              onPressEnter={this.handleInputConfirm}
              onKeyDown={this.onKeyDown}
              placeholder={selectedTickers.length < 1 ? placeholder || '' : ''}
            />
          </li>
        </ul>
        <i class="far fa-fw fa-trash-alt cus-pointer" onClick={this.clearSelectedTickers}></i>
      </div>
    );
  }
}

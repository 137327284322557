import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment'
import { addEventToAnalytics } from 'Utils';
import {
  // Divider,
  Row,
  Col,
  // Card,
  // Menu,
  // Icon,
  // Skeleton,
  Button,
  Typography,
  Input,
  Tooltip
} from 'antd'
import {
  // StockOutlined,
  SaveOutlined,
  // SettingOutlined,
  EditOutlined,
  HomeOutlined,
  // UndoOutlined,
} from '@ant-design/icons'

import {
  setWeightsRequest,
  setSummarySuccess,
  setBuildPortfolioState,
  finalizeSavePortfolioRequest,
  finalizeSavePortfolioFetch,
  finalizeSavePortfolioSuccess,
  resetBuildPortfolioState,
  editPortfolioRequest,
  removeZeroWeightFunds
} from 'actions/buildportfolio'

import { setSFMItems } from 'actions/sfm';
import { createLoadingSelector } from 'selectors/loading-selector'
// import { fetchImportedAccounts } from 'actions/broker'
// import { aggregateNetCashBalanceSelector } from 'reducers/broker'

import FolderTab from './Folder/index'
import ResultSection from './ResultSection'
import CardLoader from '../../Elements/CardLoader'
// import FinalizeModal from './modals/finalizeModal';
import OverwriteFinalizeModal from './modals/overwriteFinalizeModal';
// import { FidelityData, orionData } from './dumpData'
// import GenerateTradeListModal from './genrateTradelistModal'
import CustomAlert from 'components/CustomAlert';
import { Loader } from '../../Elements/Loader';
import { setPortfolioState } from 'actions/portfolio'
import { InnerPageFooter } from 'shared-components/'

// const { Paragraph } = Typography

class BuildFromScratch extends Component {
  constructor(props) {
    super(props)
    this.nameInput = React.createRef()
    this.allocationInput = React.createRef();
    this.state = {
      inputDisabled: true,
      allocationInputDisabled: true,
      portfolioName: props.portfolioName,
      portfolioValue: '',
      downloadTradeList: false,
      selectedTradeList: '',
      selectedScreen: 'Select Funds',
      finalizeModal: false,
      overwriteFinalizeModal: false,
      clearBuilderFunds: false,
      overwriteEnable: false,
      load: false,
      fundList: this.getFundList(),
      redirectAct: true,
    }
  }

  componentDidMount() {
    const id = this.props.match.params.id
    const { location, onboardData, history, buildportfolio, setBuildPortfolioState } = this.props
    this.setState({portfolioName: buildportfolio.portfolioName});
    if (location && location.search) {
      const params = new URLSearchParams(location.search)
      if (params) {
        const weights = params.get('weights')
        if (weights) {
          const requestObj = JSON.parse(weights)
          // console.log(requestObj)
          if (
            typeof requestObj.onboardData !== 'undefined' &&
            typeof requestObj.tabHeaderMenu !== 'undefined'
          ) {
            const requestOnboardObj = requestObj.onboardData
            const requestArr = requestObj.tabHeaderMenu
            if (requestArr && Array.isArray(requestArr) && requestArr.length) {
              const { setWeightsRequest } = this.props
              setWeightsRequest({
                onboardData: requestOnboardObj,
                tabHeaderMenu: requestArr
              })
            }
          } else {
            const { history } = this.props
            // console.log('data not found in url')
            if (history) {
              const newPath = `/build-portfolio`
              history.push(newPath)
            }
          }
        }
      }
    }
    setBuildPortfolioState({onboardData: {...buildportfolio.onboardData, onboardFrom: ``}});
    if (this.state.fundList.length === 0 ) {
      this.setState({
        fundList: this.getFundList(),
      });
    }
  }

  componentWillUnmount() {
    const { resetBuildPortfolioState, fundsData, buildportfolio, setBuildPortfolioState } = this.props;
    if (resetBuildPortfolioState) {
      resetBuildPortfolioState();
      setBuildPortfolioState({clearBuilderFunds: this.state.clearBuilderFunds});
    }
  }

  componentWillReceiveProps (nextProps) {
    const {callBackSummarySuccess} = this;
    const {summary, buildportfolio, dataLoading} = this.props;
      if(nextProps.buildportfolio.savedPortfolio !== this.props.buildportfolio.savedPortfolio){
      if(nextProps.buildportfolio.savedPortfolio.portfolio_id && nextProps.buildportfolio.savedPortfolio.status !== 'actTabClose'){
        callBackSummarySuccess({
          summary: {
            ...summary,
            timeline: 'Act',
          },
        });
        this.setState({portfolioName: nextProps.buildportfolio.portfolioName});
      }
    }

    if(this.props.buildportfolio.savedPortfolio.portfolio_id){
      if(nextProps.buildportfolio.portfolioName !== this.props.buildportfolio.savedPortfolio.portfolio_name){
        // console.log('here overwrite', this.state.overwriteEnable);
        this.setState({overwriteEnable: false});
      }
      else {this.setState({overwriteEnable: true})}
    }

    if(nextProps.summary.timeline !== this.props.summary.timeline){
      this.setState({load: false});
    }else if(nextProps.buildportfolio.savedPortfolio.message){
      this.setState({load: false});
    }

    if (nextProps.buildportfolio !== this.props.buildportfolio) {
      this.setState({
        fundList: this.getFundList(),
      });
    }
    if(
      buildportfolio.savedPortfolio &&
      buildportfolio.savedPortfolio.status === 'Success' &&
      buildportfolio.savedPortfolio.redirectFromEnhancer &&
      buildportfolio.portfolioName &&
      !nextProps.dataLoading &&
      this.state.redirectAct
    ){
      this.clickHandler();
      this.setState({redirectAct: false});
      callBackSummarySuccess({
        summary: {
          ...summary,
          timeline: 'Act',
        },
      });
      this.setState({portfolioName: nextProps.buildportfolio.portfolioName});
    }
  }

  onChange = e => {
    this.setState({ portfolioName: e.target.value })
  }

  onAllocationChange = e => {
    let valu = e.target.value;

    if(valu === '' || Number(valu)){
      this.setState({ portfolioValue: valu })
    }
    if (!Number(valu)) {
    return;
    }
  }

  onEditClick = () => {
    addEventToAnalytics('Builder Edit Name','Builder Edit Name','BUILDER_EDIT_NAME',{},false);
    this.setState(
      {
        inputDisabled: false
      },
      () => {
        this.nameInput.current.focus()
      }
    )
  }

  onAllocationEditClick = () => {
    addEventToAnalytics('Builder Edit PFValue','Builder Edit PFValue','BUILDER_EDIT_PFVALUE',{},false);
    this.setState(
      {
        allocationInputDisabled: false
      },
      () => {
        this.allocationInput.current.focus()
      }
    )
  }

  onPressEnter = () => {
    this.setState(
      {
        inputDisabled: true
      },
      () => {
        this.nameInput.current.blur()
      }
    )
  }

  onAllocationPressEnter = () => {
    this.setState(
      {
        allocationInputDisabled: true
      },
      () => {
        this.allocationInput.current.blur()
      }
    )
  }

  onBlur = () => {
    this.setState(
      {
        inputDisabled: true
      },
      () => {
        const { setBuildPortfolioState, editPortfolioRequest, summary, buildportfolio } = this.props
        if (setBuildPortfolioState) {
          setBuildPortfolioState({ portfolioName: this.state.portfolioName });
          if(summary.timeline === 'Act'){
            const {savedPortfolio} = buildportfolio;
            editPortfolioRequest({ portfolio_name: this.state.portfolioName, portfolio_id: savedPortfolio.portfolio_id});
          }
        }
        this.nameInput.current.blur()
      }
    )
  }

  onAllocationBlur = () => {
    this.setState(
      {
        allocationInputDisabled: true
      },
      () => {
        const { setBuildPortfolioState } = this.props
        if (setBuildPortfolioState) {
          setBuildPortfolioState({ portfolioValue: this.state.portfolioValue })
        }
        this.allocationInput.current.blur()
      }
    )
  }

  toggleFinalizeModal = () => {
    this.setState({ finalizeModal: !this.state.finalizeModal })
  }

  toggleOverwriteFinalizeModal = () => {
    this.setState({ overwriteFinalizeModal: !this.state.overwriteFinalizeModal })
  }

  selectScreen = selectedScreen => {
    const { onboardData, history, setSummarySuccess, summary } = this.props
    if (setSummarySuccess && selectedScreen !== 'Asset Allocation') {
      setSummarySuccess({
        summary: {
          ...summary,
          timeline: selectedScreen
        }
      })
    }
    switch (selectedScreen) {
      case 'Asset Allocation':
        if (onboardData && onboardData.onboardFrom !== '' && history) {
          setSummarySuccess({
            summary: {
              ...summary,
              timeline: selectedScreen
            }
          })
          const newPath = `/build-portfolio/asset-allocation`
          history.push(newPath)
        }
        break
      case 'Act':
        // something for act
        break
      default:
    }
  }

  callBackSummarySuccess = reqData => {
    this.selectScreen('Act')
  }

  computeShares = (pfValue, pfSumWeight, fundList) => {
    let pf_save = [], list = [], sumCat = 0.0;
    JSON.parse(JSON.stringify(fundList)).map(item => {
      if (item) {
        Object.keys(item).map(v => {
          item[v].forEach((el, index) => {
            if(item[v][index]._sliderValue != 0 && item[v][index]._sliderValue > 0){
              let ratio = item[v][index]._sliderValue / pfSumWeight;
              let scaledSliderValue = ratio * 100;
              item[v][index]._sliderValue = scaledSliderValue;
              // (Total Value * Wt) / price
              item[v][index]._computedPFShares = Math.round(
                (pfValue * scaledSliderValue/100) / item[v][index].nav
              )
              sumCat += item[v][index]._sliderValue;
              pf_save.push({
                ticker: item[v][index].ticker,
                shares: item[v][index]._computedPFShares,
                purchase_date: moment().format('YYYY-MM-DD'),
                purchase_price: item[v][index].nav,
              })
            }
          })
          list.push(...item[v])
        })
      }
    })
    return {pf_save: pf_save, list: list, sumCat: sumCat};
  };

  createPF = (pfValue, option) => {
    const { buildportfolio, setBuildPortfolioState, summary } = this.props
    const equitySelectedFunds = buildportfolio.equityTab.selectedFunds
    const bondSelectedFunds = buildportfolio.bondTab.selectedFunds
    const thematicSelectedFunds = buildportfolio.thematicTab.selectedFunds

    let fundList = [
      { ...equitySelectedFunds },
      { ...bondSelectedFunds },
      { ...thematicSelectedFunds }
    ]

    let list = [],
    pf_save = [], pfSumWeight = 0.0;
    // sum of all weights
    JSON.parse(JSON.stringify(fundList)).map(item => {
      Object.keys(item).map(v => {
        item[v].forEach((el, index) => {
          pfSumWeight += item[v][index]._sliderValue;
        })
      })
    });

    //function call
    //pfValue, pfSumWeight, fundList
    const equity = this.computeShares(pfValue, pfSumWeight, [{...equitySelectedFunds}])
    const bond = this.computeShares(pfValue, pfSumWeight, [{...bondSelectedFunds}])
    const thematic = this.computeShares(pfValue, pfSumWeight, [{...thematicSelectedFunds}])

    list = [
      ...equity.list,
      ...bond.list,
      ...thematic.list
    ];
    list = _.orderBy(list,['_sliderValue'], ['desc']).filter(item=>item._sliderValue!==0);

    pf_save = [
      ...equity.pf_save,
      ...bond.pf_save,
      ...thematic.pf_save
    ];

    const calculatedTabHeader = {
      equityTab: equity.sumCat,
      bondTab: bond.sumCat,
      thematicTab: thematic.sumCat,
    };

    if (setBuildPortfolioState) {
      setBuildPortfolioState({
        portfolioValue: pfValue,
        calculatedPortfolio: list,
        calculatedTabHeader: calculatedTabHeader
      })
    }
    //use the name of the portfolio -> this.state.portfolioName or this.props.buildportfolio.portfolioName
    //send pf_save to save portfolio API

    let portfolioObj = {
      portfolio_name: buildportfolio.portfolioName,
      data: pf_save
    }

    if(summary.timeline !== 'Act' && option !== 'redirectFromEnhancer'){
      if(option === 'overwrite'){
        // console.log('here', option);
        portfolioObj.portfolio_id = buildportfolio.savedPortfolio.portfolio_id;
        this.props.finalizeSavePortfolioRequest(portfolioObj);
      }else if(option === 'createNew'){
        // console.log('here', option);
        portfolioObj.portfolio_name = '';
        this.props.finalizeSavePortfolioRequest(portfolioObj);
      }else{
        // console.log('here', option);
        if (
          buildportfolio.savedPortfolio &&
          buildportfolio.savedPortfolio.portfolio_id
        ) {
          portfolioObj.portfolio_id = buildportfolio.savedPortfolio.portfolio_id;
          this.props.finalizeSavePortfolioRequest(portfolioObj);
          // this.setState({portfolioName: buildportfolio.portfolio_name});
        } else {
          this.props.finalizeSavePortfolioRequest(portfolioObj);
          // console.log(buildportfolio.portfolio_name);
        }
      }
    }
  }

  finalizeButtonState = () => {
    const { buildportfolio } = this.props
    const tabs = ['equityTab', 'bondTab', 'thematicTab'];
    return (tabs.map((item) => {
      const tab = buildportfolio[`${item}`];
      return Object.keys(tab.selectedFunds).length > 0 ? (
        Object.keys(tab.selectedFunds).map((u) => tab.selectedFunds[u].length > 0).filter((h) => h).length > 0
      ) : false;
    }).filter((h) => h).length > 0);
  }

  getFundList = () => {
    const { buildportfolio } = this.props;
    const { equityTab, bondTab, thematicTab } = buildportfolio;
    const equitySelectedFunds = equityTab.selectedFunds;
    const bondSelectedFunds = bondTab.selectedFunds;
    let thematicSelectedFunds = thematicTab.selectedFunds;

    let fundList = [
      {...equitySelectedFunds},
      {...bondSelectedFunds},
      {...thematicSelectedFunds},
    ];

    const list = [];
    fundList.map((item) => {
      if (item) {
        Object.keys(item).map((v) => {
          list.push(...item[v]);
        });
      }
    });
    return _.orderBy(list,['_sliderValue'], ['desc']);
  }

  submit = (option) => {
    this.props.removeZeroWeightFunds()
    this.setState({load: true});
    const { callBackSummarySuccess, createPF } = this;
    const { summary, setBuildPortfolioState, buildportfolio, sfm, setSFMItems } = this.props;
    addEventToAnalytics('Builder Finalize','Builder Finalize','BUILDER_FINALIZE',{},false);
    if (setSummarySuccess) {

      let pfValue = this.state.portfolioValue === '' ? buildportfolio.portfolioValue : this.state.portfolioValue;
      if(option === 'createNew'){
        setBuildPortfolioState({savedPortfolio: {}});
      }

      createPF(parseFloat(pfValue), option);
      if(buildportfolio.onboardData.selectedPortfolioFor === 'Use selected funds'){
        sfm.items.forEach((fund, index) => {
          const resultPageCartFund = buildportfolio.resultPageCart.find(fund2 => fund2.ticker === fund.ticker);
          if(resultPageCartFund){
            // resultPageCartFund._selector = fund._selector;
            if(fund._selector){
              fund._builder = false;
            }
            else{
              sfm.items.splice(index, 1);
            }
          }
        })
        setBuildPortfolioState({ resultPageCart: [],
          clearBuilderFunds: true,
          saveState: {
            equityTab: buildportfolio.equityTab,
            bondTab: buildportfolio.bondTab,
            thematicTab: buildportfolio.thematicTab,
          },
          onboardData:{onboardFrom:'', selectedPortfolioFor: buildportfolio.selectedPortfolioFor}
        });
        setSFMItems({items: sfm.items})
        this.setState({clearBuilderFunds: true});
      }else{
        setBuildPortfolioState({
          saveState: {
            equityTab: buildportfolio.equityTab,
            bondTab: buildportfolio.bondTab,
            thematicTab: buildportfolio.thematicTab,
          },
          onboardData:{onboardFrom:'', selectedPortfolioFor: buildportfolio.selectedPortfolioFor}
        });
      }
      // this.onCloseFinalizeModal();
    }
  }

  clickHandler = () => {
    const { callBackSummarySuccess, createPF } = this;
    const { summary, setBuildPortfolioState, buildportfolio } = this.props;
    //set the portfolio value
    //navigate to act tab
    // console.log('here overwrite', this.state.overwriteEnable);
    if(buildportfolio.savedPortfolio.status === 'Success'){
      this.submit('redirectFromEnhancer');
    }
    else if(buildportfolio.savedPortfolio.portfolio_id && this.state.overwriteEnable){
      this.toggleOverwriteFinalizeModal();
    }else {
      // console.log('nope');
      this.submit('default');
    }
  }

  goToDashboard = () => {
    const {history} = this.props;
    addEventToAnalytics('Builder Back','Builder Back','BUILDER_BACK',{},false);
    this.props.setPortfolioState({ selectedBenchmark: 'SPY' })
    history.push('/build-portfolio');
  }

  onAlertDismiss = () => {
    const { setBuildPortfolioState, buildportfolio } = this.props
    setBuildPortfolioState({
      savedPortfolio: {
        portfolio_name: buildportfolio.savedPortfolio.portfolio_name,
        portfolio_id: buildportfolio.savedPortfolio.portfolio_id
      }
    });
  }

  render() {
    const {
      buildportfolio,
      buildportfolioLoader,
      chartData,
      summary,
      setBuildPortfolioState,
      activeTab,
      selectedThemesFunds,
      themeChartData,
      loadingPortfolioData,
    } = this.props
    const { load, inputDisabled, portfolioName, finalizeModal, allocationInputDisabled, portfolioValue, overwriteFinalizeModal } = this.state

    const enableFinalizeButton = this.finalizeButtonState(); // equity || bond || thematic ? false : true
    const fundList = this.getFundList();

    // console.log(fundList);

    let sliderChartData = [];
    //
    // if (Object.keys(selectedThemesFunds).length > 0) {
    //   sliderChartData = Object.keys(selectedThemesFunds).map((v) => {
    //     if (selectedThemesFunds[v] && selectedThemesFunds[v].length) {
    //       if (buildportfolio.thematicTab.data.length > 0) {
    //         const found = buildportfolio.thematicTab.data.find((g) => g.query === v);
    //         if (found && typeof found !== 'undefined') {
    //           return found;
    //         }
    //       }
    //     }
    //   }).filter((k) => typeof k !== 'undefined');
    // }
    //
    // console.log(sliderChartData);
    // console.log(_.sortBy(sliderChartData,['position']));
    // sliderChartData = _.sortBy(sliderChartData,['position']);

    // fundList.map((g) => {
    //   console.log(g.ticker, g._sliderValue);
    // });

    return (
      <React.Fragment>
        {(load || loadingPortfolioData) && <Loader loading/>}
        {(buildportfolio.savedPortfolio.message)
          && (
            <CustomAlert className="alert-windown-wrapper" color='danger' onDismissCallback={this.onAlertDismiss}>
              {buildportfolio.savedPortfolio.message}
            </CustomAlert>
          )
        }
        <Row className="build-from-scratch-container">
          <Row className="build-from-scratch-header">
            <Col className="d-flex-wrap portfolio-name-edit-wrapper" span={10}>
              <SaveOutlined className="save-icon" />
              <Tooltip placement="bottom" title={portfolioName}>
                <Input
                  ref={this.nameInput}
                  className={`edit-input ${inputDisabled ? 'edit-disabled' : ''}`}
                  disabled={inputDisabled}
                  placeholder='Name your portfolio'
                  value={portfolioName}
                  onChange={this.onChange}
                  onPressEnter={this.onPressEnter}
                  onBlur={this.onBlur}
                />
              </Tooltip>
              <EditOutlined
                className="edit-icon"
                onClick={inputDisabled ? this.onEditClick : null}
              />
              {summary.timeline === 'Act' ? null :
                <>
                  <Input
                    ref={this.allocationInput}
                    className={`edit-input pf-value-input ${allocationInputDisabled ? 'edit-disabled' : ''}`}
                    disabled={allocationInputDisabled}
                    placeholder='Portfolio Value'
                    value={portfolioValue}
                    onChange={this.onAllocationChange}
                    onPressEnter={this.onAllocationPressEnter}
                    onBlur={this.onAllocationBlur}
                  />
                  <EditOutlined
                    className="edit-icon"
                    onClick={allocationInputDisabled ? this.onAllocationEditClick : null}
                  />
                </>
              }
            </Col>
            <Col className="d-flex-wrap timeline-button-group" span={6}>
            {/* <Button
                style={{ width: 128, padding: 0 }}
                className={`timeline-button ${
                  summary.timeline === 'Asset Allocation'
                    ? 'timeline-active'
                    : ''
                }`}
                type="link"
                onClick={() => this.selectScreen('Asset Allocation')}
              >
                Asset Allocation
              </Button>
              <div className="divider-holder">
                <Divider>
                  <Button style={{ width: 108, padding: 0 }} className={`timeline-button ${summary.timeline === 'Select Funds' ? 'timeline-active' : ''}`} type="link">
                    Select Funds
                  </Button>
                </Divider>
              </div>
              <Button style={{ width: 48, padding: 0 }} className={`timeline-button ${summary.timeline === 'Act' ? 'timeline-active' : ''}`} type="link">
                Act
              </Button> */}
            </Col>
            <Col className="d-flex-wrap justify-content-end" span={8}>
              <Button
                shape="round"
                className={`primary-button go-to-dashboard ${summary.timeline === 'Act' ? 'home-icn' : 'back-icn'}`}
                type="primary"
                style={{padding: 0}}
                onClick={() => this.goToDashboard()}
              >
              {summary.timeline === 'Act' ?
                <span>Builder <HomeOutlined className='home' /></span>:
                // <UndoOutlined className='back' rotate={90} />
                <span><img width="42px" src="/assets/images/reload.svg"></img></span>

              }
              </Button>
              {summary.timeline === 'Act' ? null : (
                !enableFinalizeButton ? (
                  <Tooltip
                    placement="bottom"
                    title="Please select funds to finalize"
                    className="finalize-button-tooltip"
                  >
                    <Button
                      disabled={!enableFinalizeButton}
                      shape="round"
                      className="primary-button"
                      type="primary"
                      onClick={() => this.clickHandler()}
                    >
                      Finalize
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    shape="round"
                    className="primary-button"
                    type="primary"
                    onClick={() => this.clickHandler()}
                  >
                    Finalize
                  </Button>
                )
              )}
            </Col>
          </Row>
          <Row className="build-from-scratch-content-wrapper">
            <Col span={18} className="left-block">
              {buildportfolioLoader ? (
                <CardLoader skeleton={3} />
              ) : (
                <FolderTab getResultChartData={this.getResultChartData} createPF={(value) => this.createPF(value)} />
              )}
            </Col>
            <Col span={6} className="right-block">
              {buildportfolioLoader ? (
                <CardLoader skeleton={3} />
              ) : (
                <ResultSection fundList={fundList} chartData={chartData} />
              )}
            </Col>
          </Row>
        </Row>
        {/* <FinalizeModal
          isOpen={finalizeModal}
          toggleModal={this.toggleFinalizeModal}
          setSummarySuccess={this.callBackSummarySuccess}
          summary={summary}
          createPF={this.createPF}
        /> */}
        <OverwriteFinalizeModal
          isOpen={overwriteFinalizeModal}
          toggleModal={this.toggleOverwriteFinalizeModal}
          setSummarySuccess={this.callBackSummarySuccess}
          summary={summary}
          submit={this.submit}
        />
        <InnerPageFooter style={{ width: "100%", borderTop: "1px solid #d5cbcb", textAlign: "center", fontFamily: "Open Sans", fontSize: "12px", padding: ".4em", background: "#f7f7f7" }} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ buildportfolio, loading, funds, sfm }) => ({
  buildportfolio,
  sfm,
  activeTab: buildportfolio.activeTab,
  summary: buildportfolio.summary,
  chartData: buildportfolio.tabHeaderMenu,
  themeChartData: buildportfolio.thematicTab.data,
  selectedThemesFunds: buildportfolio.thematicTab.selectedFunds,
  onboardData: buildportfolio.onboardData,
  buildportfolioLoader: buildportfolio.loading,
  fundsData: funds.data,
  dataLoading: createLoadingSelector(['INVESTMENT_FETCH', 'EQUITY_BATCH', 'BONDS_BATCH', 'THEMATIC_BATCH'])({ loading }),
  loadingPortfolioData: createLoadingSelector(['GET_PORTFOLIO_BY_ID'])({ loading }),
})

const mapDispatchToProps = {
  // getBatchResult,
  setWeightsRequest,
  setSummarySuccess,
  setBuildPortfolioState,
  finalizeSavePortfolioRequest,
  finalizeSavePortfolioFetch,
  finalizeSavePortfolioSuccess,
  resetBuildPortfolioState,
  setSFMItems,
  editPortfolioRequest,
  setPortfolioState,
  removeZeroWeightFunds,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuildFromScratch)

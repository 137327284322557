import React from 'react'

import AppleSignin from 'react-apple-signin-auth'

import { registerApple, registerProfileData } from 'actions/register'
import { failedAppleAuth } from 'actions/auth'

import { useDispatch } from 'react-redux'

import { addEventToAnalytics } from '../Utils'
import config from '../env'

const AppleLogin = ({ children }) => {

    const clientId = "com.magnifi.applessoservice"
    // const redirectURI = "https://44573fdacd16.ngrok.io/register"
    // const redirectURI = "https://dev2.magnifi.com/register"
    const redirectURI = config.appleSSORedirectURL;
    // const redirectURI = process.env.NODE_ENV === 'production'
    //     ? "https://magnifi.com/register"
    //     : "https://dev2.magnifi.com/register"

    console.log('apple redirectURI', redirectURI, process.env.NODE_ENV )

    const dispatch = useDispatch()

    const handleAppleSignInSuccess = ({ authorization, user }) => {

        // sign up
        if ( user ) {

            // console.log('ani 7', user)

            addEventToAnalytics(
                'Register with Apple login',
                'Register with Apple login',
                'REGISTER_WITH_APPLE_LOGIN',
                { email: user.email }
            )
            // console.log('ani 8', user)
            // window.localStorage.setItem('socialmail', user.email)
            dispatch(registerApple({
                email: user.email,
                first_name: user.name.firstName,
                last_name: user.name.lastName,
                token: authorization.id_token,
                a: 1,
                plan_selected: 'basic'
            },(authToken)=>{
                const dataToSend = {
                    data: {
                      register_step_status: "1",
                      self_role: "Individual Investor"
                    },
                    token: authToken
                  }
                  dispatch(registerProfileData(dataToSend, ()=>{
                    addEventToAnalytics(
                      'Register with Apple login',
                      'Register with Apple login',
                      'REGISTER_WITH_APPLE_LOGIN',
                      { email: user.email }
                    )
                    addEventToAnalytics(
                      'Register Apple Button Clicked',
                      'Register Apple Button Clicked',
                      'REGISTER_BUTTON_CLICKED',
                      { email: user.email }
                    )
                    window.location.href = '/register/trading';
                  }));
            }))

            // track mongodb
            if (window.heap) {
                window.heap.identify(user.email)
                window.heap.addUserProperties({
                    'First Name': user.firstName,
                    'Last Name': user.lastName
                })
                window.heap.track('Sign In with Apple', { email: user.email })
            }

            window.addkrakenUser &&
            typeof window.addkrakenUser === 'function' &&
            window.addkrakenUser({
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName
            })

            // NOTE: Segment Script
            // ------------------------------------------------------------------------------------------
            if (window.analytics && window.analytics.identify && typeof window.analytics.identify === 'function') {
              // console.log('---- Apple Login Segment ----');
              window.analytics.identify(user.email, {
                name: user.firstName+' '+user.lastName,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                user_type: 'Advisor',
              });
            }
            // ------------------------------------------------------------------------------------------

        }

        // log in
        else {
            // console.log('ani 9', authorization)

            addEventToAnalytics(
                'Sign In with Apple',
                'Sign In with Apple',
                'SIGN_IN_WITH_APPLE',
                { token: authorization.id_token }
            )
            // console.log('ani 10', authorization)

            dispatch(registerApple({
                token: authorization.id_token,
                a: 1,
                plan_selected: 'basic'
            }))
        }

    }

    const handleAppleSignInError = error => {
        // console.log('ani 11', error)

        dispatch(failedAppleAuth(error))
    }

    return (
        <AppleSignin
            authOptions={{
                clientId,
                redirectURI,
                usePopup: true,
                scope: 'name email'
            }}
            onSuccess={handleAppleSignInSuccess}
            onError={handleAppleSignInError}
            render={props => <div {...props} style={{ width: "100%" }}>{children}</div>}
        />
    )
}

export default AppleLogin;

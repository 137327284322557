import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Row, Col, PopoverBody } from 'reactstrap';

import { quintileSize, quintileClass } from 'Utils';
import { cn } from 'DataSet';
import { getLineHeight, getSTKData } from '../utils';
import { Quintile, Pct } from '../common';
import { withPopover } from '../../Elements/Popover';

class FeesPopover extends Component {
  render() {
    const { card, qi, icon, highlight, checkPopOpen, firstPop, discovery } = this.props;

    let val = qi.name === 'Volume' ? Math.round(card[qi.col] / 1000) : card[qi.col];

    if(typeof val === 'undefined' || val === null) val = '--';

    const valFmt = (val !== null && val !== '--') ? <span className="text-gray-8 font-weight-bold">{this.props.prefix}{val.toLocaleString('en', { minimumFractionDigits: 0 })}{ val !== '--' ? <Pct /> : ''}</span> : '--';
    let feesMin = card._flags ? card._flags.feesMin : false;

    const catVal = (card._categorySeries && card._categorySeries !== null) ? (qi.name === 'Fees' ? (card._categorySeries[qi.col] ? card._categorySeries[qi.col].toFixed(2) : '--') : Math.round(card._categorySeries[qi.col])) : '--';
    // const catVal = qi.name === 'Fees' ? card[qi.col].toFixed(2) : Math.round(card[qi.col]);

    const catValFmt = catVal !==  null ? <span>{this.props.prefix}{catVal.toLocaleString('en', { minimumFractionDigits: 0 })}</span> : '--';
    const hikeLabel =  (val !== '--') ? (quintileSize(card[qi.qcol], qi.reverse) === 2 ? 'Average' : (quintileSize(card[qi.qcol], qi.reverse) === 3 ? 'High' : 'Low')) : '';
    const feesIcon = quintileSize(card[qi.qcol], qi.reverse) ? (icon + quintileSize(card[qi.qcol], qi.reverse)) : (icon + 1);
    const e1 = () => (
      <div className="fees-flag-color">
        <i
          className={classNames("far fa-fw fassf", feesIcon)}
        />
        <p className="__val" style={{ margin: 0, fontSize: 11, lineHeight: 1, fontWeight: 'bold' }}>
          {this.props.prefix}{val !== '--' ? val.toFixed(2).toLocaleString('en', { minimumFractionDigits: 0 })+'%' : '--'}
        </p>
        <p className="__state" style={{ margin: 0, fontSize: 10, lineHeight: 1 }}>
          {hikeLabel}
        </p>
      </div>
    );

    const e2 = () => (
      <React.Fragment>
        <PopoverBody className="p-0">
          <Row className="no-gutters">
            <Col className="col-12 p-2 text-gray-9 bg-light br-2">
              <div className="h5 d-flex justify-content-between pt-1">
                <div className="popover-drop">
                  <div className="fees-pop-tog popover-drop__toggle" onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave} >
                    <span className="pop-toggle-name">Fees</span>
                  </div>
                </div>
                <span>{valFmt}</span>
              </div>
              <Quintile cardCategory={cn(card, 'Category')} date={null} item={qi} value={card[qi.qcol]} catValue={catValFmt} bg={quintileClass(card[qi.qcol], qi.reverse)} />
            </Col>
          </Row>
        </PopoverBody>
      </React.Fragment>
    );

    const highlightClassName = highlight ? 'search-fees-return-stats-padding search-risk-stats-highlighting' : (feesMin ? 'search-fees-return-stats-padding search-risk-stats-highlighting' : 'search-fees-return-stats-padding');
    const WithPopover = withPopover(e1, e2, {
      id: "FeesPopover-" + qi.name + '-' + cn(card, 'Id'), placement: 'right', className: highlightClassName,
      checkPopOpen, firstPop, discovery, card, popUp: 'Fees Popup'
    })
    return (
      <WithPopover />
    );
  }
}

const mapStateToProps = ({ discovery }) => ({
  discovery
})

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeesPopover)

import React from 'react';
import { connect } from 'react-redux';
import { CaretDownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Radio } from 'antd';
import PButton from 'components/PButton';
// import PremiumNoticeModal from 'components/PremiumNoticeModal';
import { calcTestDriveKey, calculatorOptions } from 'DataSet';
import { UncontrolledTooltip } from 'reactstrap';
import { addEventToAnalytics } from 'Utils';
import { changeCalculator } from 'actions/weights';
import WeightChangeModal from '../../SFM/WeightChangeModal';
import DeleteSelectorModal from '../../SFM/DeleteSelectorModal';

class SelectorDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.items,
      displayYear: '',
      premiumModal: false,
      notify: false,
      premiumFeature: 'false',
      calculatorName: null,
      featureName: 'my_calculator',
      testDriveStatus: {},
      selectorName: '',
      deleteSelectorToggle: false,
      selectorDropdownVisible: false,
      previousDefaultCalcName: props.defaultCalculator,
    }
    this.onCalculatorChange = this.onCalculatorChange.bind(this);
  }

  onCalculatorChange(e, setDefault = false) {
    const name = setDefault ? (e.target ? e.target.value : (e.key || e)) : (e.key || e);
    // console.log(name);
    const featureName = calcTestDriveKey[name] || 'my_calculator';
    this.setState({
      calculatorName: name,
    }, () => {
      this.switchCalculator(name, setDefault);
    })
  }

  switchCalculator(name, setDefault) {
    const oldCustomCalculators = this.props.customCalculators;
    const { premiumUser, discovery, changeCalculator, refreshSearch, defaultCalculator } = this.props;
    const { featureName, testDriveStatus } = this.state;
    addEventToAnalytics('Compare select comparison criteria', 'Compare select comparison criteria', 'COMPARE_SELECT_COMPARISON_CRITERIA', { query: discovery.query, logId: discovery.logId, value: name }, true);

    // name === 'create custom selector' &&
    //   oldCustomCalculators &&
    //   this.props.oldCustomCalculators &&
    //   Object.keys(oldCustomCalculators).length === Object.keys(this.props.oldCustomCalculators).length

    console.log(name, setDefault);

    this.toggleSelectorDropdownState();
    this.props.changeCalculator({ name, setDefault });
    this.setState({ selectorName: name });

    (typeof refreshSearch === 'function' && setDefault) && refreshSearch();
  }

  deleteCalculator = (e, calc) => {
    e.stopPropagation();
    this.setState({
      selectorName: calc,
    },
      () => this.toggleDeleteModal(true));
  }

  toggleDeleteModal = (val) => {
    this.setState({
      deleteSelectorToggle: val,
    })
  }

  toggleSelectorDropdownState = () => {
    this.setState({
      selectorDropdownVisible: !this.state.selectorDropdownVisible,
    })
  }

  onWeightModalCloseAction = (scoreAttrs) => {
    console.log('onWeightModalCloseAction===> ', true);
    console.log(scoreAttrs);
    if (!scoreAttrs || scoreAttrs.length === 0) {
      const { defaultCalculator, changeCalculator } = this.props;
      this.setState({
        previousDefaultCalcName: defaultCalculator,
      });
      if (changeCalculator) {
        changeCalculator({ name: defaultCalculator, setDefault: false });
      }
    }
  }

  render() {
    const { selectedCalculator, scoreAttrs, premiumUser, customCalculators, defaultCalculator, style, className, hideRadioButton } = this.props;
    const { premiumModal, notify, featureName, selectorName, deleteSelectorToggle, selectorDropdownVisible } = this.state;
    const SelectorMenus = (
      <div>
        <div className="py-1 set-default-label">
          <span>Set Default</span>
          <i id="set-default-tooltip-discover" class="fal fa-info-circle tooltip-icon" />
          <UncontrolledTooltip placement="right" target={'set-default-tooltip-discover'}>
            Select a default fund selector.
            You can use this in queries
            to get customized results e.g.
            "Emerging market funds using my fund selector"
          </UncontrolledTooltip>
        </div>
        <div className="selector-scroll">
          <PButton
            type="menu"
            mapFor="selector"
            featureName={featureName}
            premiumAction={true}
            onClick={(e) => hideRadioButton ? this.onCalculatorChange(e, true) : this.onCalculatorChange(e)}
            selectedKeys={[selectedCalculator]}
          >
            {Object.keys(calculatorOptions).map(name => (
              <Menu.Item
                className="py-1"
                key={calculatorOptions[name]}
                disabled={(calculatorOptions[name] === calculatorOptions.sponsorcalculator) || (selectedCalculator === calculatorOptions[name])}
              >
                {!hideRadioButton && (
                  <Radio
                    className="selector-radio"
                    value={calculatorOptions[name]}
                    checked={calculatorOptions[name] === defaultCalculator}
                    onChange={(e) => this.onCalculatorChange(e, true)}
                  />
                )}
                <span className="selector-name">{calculatorOptions[name]}</span>
              </Menu.Item>
            ))}
            {Object.keys(customCalculators).map(item => {
              return (
                <Menu.Item disabled={selectedCalculator === item} key={item} className="py-1 selector-options">
                  {!hideRadioButton && (
                    <Radio
                      className="selector-radio"
                      value={item}
                      checked={item === defaultCalculator}
                      onChange={(e) => this.onCalculatorChange(e, true)}
                    />
                  )}
                  <span className="selector-name">{item}</span>
                  <div className="delete-selector" onClick={(e) => this.deleteCalculator(e, item)}>
                    <i class="fas fa-trash-alt"></i>
                  </div>
                </Menu.Item>
              )
            })
            }
          </PButton>
        </div>
        <PButton
          type="menu"
          mapFor="selector"
          featureName="create custom selector"
          premiumAction={true}
          onClick={() => this.onCalculatorChange('create custom selector')}
        >
          <Menu.Divider />
          <Menu.Item key="create custom selector" className="py-1" style={{ marginTop: 3 }}>
            <div className="selector-options">
              create custom selector
            </div>
          </Menu.Item>
        </PButton>
      </div>
    );

    return (
      <div className="sel-dropdown discover-dropdown">
        <DeleteSelectorModal
          modalToggle={deleteSelectorToggle}
          selectorName={selectorName}
          toggleDeleteModal={this.toggleDeleteModal}
        />
        <div
          id="selector-dropdown-discover"
          // className="d-flex align-items-center justify-content-center multiple-calculator"
          className="multiple-calculator"
        >
          <Dropdown
            overlay={SelectorMenus}
            trigger={['click']}
            getPopupContainer={() => document.getElementById('selector-dropdown-discover')}
          >
            <a className={`ant-dropdown-link h6 my-1 view-btn ${className}`} style={style} onClick={e => e.preventDefault()}>
              {'My Selector'} <CaretDownOutlined className="down-arrow" />
            </a>
          </Dropdown>
        </div>
        <div className="settings-gear">
          {(selectedCalculator !== calculatorOptions.magnificalculator) &&
            <WeightChangeModal
              {...this.props}
              scoreAttrs={scoreAttrs}
              premiumUser={this.state.premiumFeature === 'true' ? true : this.props.premiumUser}
              scoreAttrsWeightHandler={(value) => this.props.scoreAttrsWeightHandler(value)}
              openModal={selectorName === 'create custom selector'}
              onModalCloseAction={(values) => this.onWeightModalCloseAction(values)}
            />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, weights, discovery }) => ({
  discovery,
  premiumUser: auth.user.premiumUser || false,
  scoreAttrs: weights.scoreAttrs,
  selectedCalculator: weights.selectedCalculator,
  defaultCalculator: weights.defaultCalculator,
  customCalculators: weights.customCalculators,
})

const mapDispatchToProps = {
  changeCalculator,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectorDropdown)

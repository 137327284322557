import React from 'react';
import { get } from 'lodash'
import { ModalFooter } from 'reactstrap';

import { D3Colors09, cn, Brokers } from 'DataSet';
import { getNest, getNestDefault } from 'Utils';

import { placeTDFundOrder, placeTradeItFundOrderPreview, placeTradeItFundOrder } from 'apis/funds';
import { Spinner } from 'shared-components/';
import { addEventToAnalytics } from 'Utils';

import ItemList from './ItemList';
import Footer from './Footer';

const isError = (str = '') => str.toLowerCase() === 'error';
const comingSoon = ['MF', 'SMA', 'MP', 'CEF'];

class Confirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      warnings: {warnings: []},
      disabled: false
    };
    this.handleCheckbox = this.handleCheckbox.bind(this)
  }

  componentDidMount = () => {
    this.previewOrder()
      .then( result => {
        this.setState({
          previewOrderResult: result,
          loading: false
        });
      })
  }

  handleCheckbox() {
    var flag = false;
    let elements = document.getElementsByClassName('warning-tradeit');
    if(elements){
      for(let i = 0; i<elements.length; i++) {
        if(elements[i].checked == true){
          flag = true;
        }
        else{
          flag = false;
          break;
        }
      };
      if(flag == true && this.state.disabled != false){
        this.setState({disabled: false});
        return true;
      }
      else if(flag == false && this.state.disabled != true){
        this.setState({disabled: true});
        return false;
      }
    }
  }

  confirmHandler = () => {
    this.setState({ disabled: true, loading: true });
    addEventToAnalytics('Confirm SFM Footer Button','Confirm SFM Footer Button','CONFIRM_SFM_FOOTER_BUTTON',{ clickBtn: 'confirm' },true);
    this.placeFundsOrder()
      .then(result => {
        let payload = [];
        result.funds.forEach((e, i) => {
          let broker = Brokers.find(el => el.name === e.broker.name);
          let errorMsg, error = isError(getNest(['results', i, 'status'], result));
          if (error)
            errorMsg = getNest(['results', i, 'response', 'message'], result) || getNest(['results', i, 'shortMessage'], result) || getNest(['results', i, 'message', 'default'], result)

          let card = {
            ticker: cn(e, 'Ticker'),
            name: cn(e, 'Name2'),
            sponsor: cn(e, 'Sponsor'),
            color: D3Colors09[i % D3Colors09.length],
            broker: e.broker.name,
            brokerColor: broker.color,
            error,
            errorMsg,
            orderNumber: getNest(['results', i, 'data', 'orderNumber'], result),
            timestamp: getNest(['results', i, 'data', 'timestamp'], result),
            confirmationMessage: getNest(['results', i, 'data', 'confirmationMessage'], result)
          }
          payload.push(card);
        })

        this.setState({ disabled: false, loading: false });

        this.props.clearAllHandler();
        this.props.confirmHandler(true, payload);
      });
  }

  previewOrder = async () => {
    let funds = [];
    let promiseArrayTD = [];
    let promiseArray = [];
    let items = this.props.items.filter(e => !comingSoon.includes(getNest(['_order', 'state', 'type'], e)));
    items.forEach((e,index) => {
        let amtType = getNest(['_order', 'state', 'amtType'], e);
        let qty = amtType === 'Value'
          ? +getNest(['_order', 'qty'], e)
          : +getNest(['_order', 'state', 'qty'], e);
        if (qty <= 0 || qty === null || isNaN(qty)) return;
        let broker = Brokers.find(el => el.name === e.broker.name);
        const accbroker = this.props.brokers.find(b => b.name === broker.name); //setting account_number here
        if (typeof this.props.items[index]._order !== 'undefined') {
          const selectedFlag = e._brokerFlags.find(e => e.id === accbroker.id)
          this.props.items[index]._order.account_number = selectedFlag.account_number;
        }
        let ac = getNest(['_order', 'account_number'], e);
        let out = {
          "accountNo": ac,
          "orderType": "MARKET",
          "session": "NORMAL",
          "duration": "DAY",
          "orderStrategyType": "SINGLE",
          "orderLegCollection": [
            {
              "orderLegType": "EQUITY",
              "legId": 0,
              "instrument": {
                "assetType": "EQUITY",
                "symbol": cn(e, 'Ticker')
              },
              "instruction": "BUY",
              "quantity": qty,
              "quantityType": "ALL_SHARES"
            }
          ]
        }

        let flag = e._brokerFlags && e._brokerFlags.find(f => f.sel);
        let price = getNest(['_order', 'price'], e);

        funds.push(e);
        if(broker.name == 'TD Ameritrade') {
          let postData = out;
          promiseArrayTD.push(postData)
        }
        else {
          const previewOrder = (postData) => {
            return placeTradeItFundOrderPreview(postData)
            .then(result => {
              return result;
            })
            .catch(error => ({ ...getNestDefault(['response', 'data'], error, {}), status: 'Error' }))
          }
          let postData = {
            "accountNumber": ac,
            "orderAction": "buy",
            "orderQuantity": qty,
            "orderSymbol": cn(e, 'Ticker'),
            "orderPriceType": "market",
            "orderExpiration": "day",
            "orderQuantityType": amtType === 'Value' ? "TOTAL_PRICE" : "SHARES",
            "broker": broker.name
          }
          promiseArray[index] = previewOrder(postData);
        }
    }) //end for loop

    if (funds.length <= 0)
      return;

    return Promise.all(promiseArray)
      .then(results => {
        items.forEach((e,index) => {
          let amtType = getNest(['_order', 'state', 'amtType'], e);
          let qty = amtType === 'Value'
            ? +getNest(['_order', 'qty'], e)
            : +getNest(['_order', 'state', 'qty'], e);

          let b = Brokers.find(eb => eb.name === e.broker.name)
          if(b.parent == 'TradeIT' && qty > 0){
            if(results[index].status == "REVIEW_ORDER" ){
              if(e.ticker == results[index].data.orderDetails.orderSymbol){
                this.props.items[index]._order.previewResult = results[index];
              }
            }
            else if(results[index].status == "Error" ){
              this.props.items[index]._order.previewResult = results[index];
            }
            else if(results[index].message){
              this.props.items[index]._order.previewResult = results[index];
            }
          }
        });
        return { funds, promiseArrayTradeIT: results, promiseArrayTD };
      })
  }

  placeFundsOrder = async () => {
    const { items } = this.props;
    let funds = [];
    let promiseArray = [];

    items.forEach(e => {
      let amtType = getNest(['_order', 'state', 'amtType'], e);
      let qty = amtType === 'Value'
        ? +getNest(['_order', 'qty'], e)
        : +getNest(['_order', 'state', 'qty'], e);

      if (qty <= 0 || qty === null || isNaN(qty)) return;
      let broker = Brokers.find(el => el.name === e.broker.name);
      let ac = getNest(['_order', 'account_number'], e);

      let out = {
        // ticker: cn(e, 'Ticker'),
        // price: cn(e, 'Nav Price'),
        // quantity: qty,
        // accountNo: '494102028',
        // transaction_type: 'BUY',
        "accountNo": ac,
        "orderType": "MARKET",
        "session": "NORMAL",
        "duration": "DAY",
        "orderStrategyType": "SINGLE",
        "orderLegCollection": [
          {
            "orderLegType": "EQUITY",
            "legId": 0,
            "instrument": {
              "assetType": "EQUITY",
              "symbol": cn(e, 'Ticker')
            },
            "instruction": "BUY",
            "quantity": qty,
            "quantityType": "ALL_SHARES"
          }
        ]
      }

      let flag = e._brokerFlags && e._brokerFlags.find(f => f.sel);
      let price = getNest(['_order', 'price'], e);
      if(e.type != 'MF'){
        funds.push(e);
      }
      if(broker.name == 'TD Ameritrade') {
        let postData = out;
        promiseArray.push(placeTDFundOrder(postData))
      }
      else if(broker.parent && broker.parent == 'TradeIT') {
        if (typeof e._order !== 'undefined' && e._order.state.type != 'MF') {
          const placeOrder = (el) => {
            if(el.type != 'MF') {
            return placeTradeItFundOrder({ "orderId": getNest(['orderId'], el._order.previewResult.data), broker: broker.name })
            .then(result => {
              return result;
            })
            .catch(error => ({ ...getNestDefault(['response', 'data'], error, {}), status: 'Error' }))
          }
        }
          promiseArray.push(placeOrder(e))
        }
      }
    })
    if (funds.length <= 0)
      return;

    return Promise.all(promiseArray)
      .then(results => {
        return { funds, results };
      })
  }

  render() {
    console.log('ConfirmTab')
    return (
      <div>
        <div className="modal-body-ssf p-2">
          <Spinner isFetching={this.state.loading}/>
          {!this.state.loading &&  <ItemList {...this.props} previewResult={this.state.previewOrderResult} handleCheckbox={this.handleCheckbox}/>}
        </div>
        <ModalFooter className="py-2 sfm-modal-footer">
          <Footer {...this.props} confirmHandler={this.confirmHandler} handleCheckbox={this.handleCheckbox} disabled={this.state.disabled}/>
        </ModalFooter>
      </div>
    )
  }
}

export default Confirm;

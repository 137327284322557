import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { Button, Row, Col, Card } from 'antd';
import { addEventToAnalytics, getPortfolioBenchmarkOfSelectedTicker } from 'Utils';

// import { createLoadingSelector } from 'selectors/loading-selector';
import { setSummarySuccess, setBuildPortfolioState, downloadBuilderReportPdf } from 'actions/buildportfolio';
import { setPortfolioReplace, resetPortfolioState } from 'actions/portfolio';
import { setEnhancerState, getPortfolioDataByIDRequest } from 'actions/enhancer';
import { investmentFetchSuccess, fetchPortfolioData, downloadPortfolioReport, investmentOptions, setInvestmentState, investmentFetch, resetInvestmentState } from 'actions/investment';
import GenerateTradeListModal from '../genrateTradelistModal';
// import { orionData, FidelityData } from '../dumpData';
import { aggregateNetCashBalanceSelector } from 'reducers/broker';
import CustomAlert from 'components/CustomAlert';
import { getCalculatedStats } from '../utils'
import { Loader } from '../../../Elements/Loader';
import _ from 'lodash'
class EquityContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: '',
      redirectEnhancementPage: false,
      downloadTradeList: false,
      selectedTradeList: '',
      UploadPortfolio: { label: 'Upload Portfolio', name: 'Portfolio', code: 'uplPrt' },
    };
  }

  callback = (key) => {
    console.log(key);
  }
  componentDidMount() {
    // this.props.investmentFetch();
    // this.props.investmentFetchSuccess();
  }

  clsoeActTab = () => {
    const { summary, setSummarySuccess, buildportfolio, setBuildPortfolioState } = this.props;
    const data = {};
    if (setSummarySuccess) {
      addEventToAnalytics('Builder Act Cross', 'Builder Act Cross', 'BUILDER_ACT_CROSS', {}, false);
      setSummarySuccess({
        summary: {
          ...summary,
          timeline: 'Select Funds',
        },
        equityTab: buildportfolio.saveState.equityTab,
        bondTab: buildportfolio.saveState.bondTab,
        thematicTab: buildportfolio.saveState.thematicTab,
      });
      setBuildPortfolioState({
        savedPortfolio: {
          portfolio_id: buildportfolio.savedPortfolio.portfolio_id,
          portfolio_name: buildportfolio.savedPortfolio.portfolio_name,
          status: 'actTabClose'
        }
      });
    }
  }

  isTabEmpty(tabName) {
    const { buildportfolio } = this.props;
    return Object.keys(buildportfolio[tabName].selectedFunds).length === 0 ? false :
      buildportfolio[tabName].selectedFunds[Object.keys(buildportfolio[tabName].selectedFunds)[0]].length > 0 ?
        true : false;
  }

  returnArrayForTab = (tabName) => {
    const { buildportfolio } = this.props;
    let tabTickerArray = [];
    buildportfolio[tabName].selectedFunds[Object.keys(buildportfolio[tabName].selectedFunds)[0]].forEach(obj => {
      tabTickerArray.push(obj.ticker);
    });
    return tabTickerArray;
  }

  getFundlist = () => {
    const { buildportfolio } = this.props;
    const { equityTab, bondTab, thematicTab } = buildportfolio;
    const equitySelectedFunds = equityTab.selectedFunds;
    const bondSelectedFunds = bondTab.selectedFunds;
    let thematicSelectedFunds = thematicTab.selectedFunds;

    let fundList = [
      { ...equitySelectedFunds },
      { ...bondSelectedFunds },
      { ...thematicSelectedFunds },
    ];

    const list = [];
    fundList.map((item) => {
      if (item) {
        Object.keys(item).map((v) => {
          list.push(...item[v]);
        });
      }
    });
    return _.orderBy(list, ['_sliderValue'], ['desc']);
  }

  downloadPortfolio = () => {
    const { buildportfolio, downloadBuilderReportPdf, profile, selectedBenchmark, upDownDataSet } = this.props;
    const logo = profile.custom_report_logo ? profile.custom_report_logo : '';
    const disclosures = profile.disclosures;
    const advisory_fees = profile.advisory_fees || 0;
    const portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(upDownDataSet, selectedBenchmark);
    const glideChart = getCalculatedStats(this.getFundlist(), true, portfolioBenchmark)
    const appendix = buildportfolio.calculatedPortfolio
    const resultSection = buildportfolio.resultSection
    const portfolioValue = buildportfolio.portfolioValue
    const bondValue = Math.round(buildportfolio.tabHeaderMenu.filter(i => i['key'] === "bondTab")[0]['sliderValue'] / 10) * 10
    const bondValue1 = buildportfolio.tabHeaderMenu.filter(i => i['key'] === "bondTab")[0]['sliderValue'];
    const equityValue = buildportfolio.tabHeaderMenu.filter(i => i['key'] === "equityTab")[0]['sliderValue'];
    const thematicValue = buildportfolio.tabHeaderMenu.filter(i => i['key'] === "thematicTab")[0]['sliderValue'];
    const equityAndThematicValue = 100 - bondValue
    const portfolioName = buildportfolio.portfolioName;
    let ratio = `${equityAndThematicValue}/${bondValue}`
    let out = {
      glideChart,
      appendix,
      resultSection,
      portfolioValue,
      ratio,
      logo,
      disclosures,
      selectedBenchmark,
      portfolioName,
      bondValue1,
      equityValue,
      thematicValue,
      advisory_fees,
      portfolioBenchmark,
    };
    downloadBuilderReportPdf(out)
  }

  redirectPortfolioEnhancementPage = () => {
    // const {buildportfolio, investmentOptions, investmentFetch, resetInvestmentState, resetPortfolioState} = this.props;
    addEventToAnalytics('Builder Enhancement', 'Builder Enhancement', 'BUILDER_ENHANCEMENT', {}, false);
    // resetInvestmentState();
    // resetPortfolioState();
    // investmentFetch();
    // investmentOptions({
    //   redirectFrom: {
    //     module: 'builder',
    //     portfolioName: buildportfolio.portfolioName,
    //     link: 'build-portfolio/onboard-screen',
    //     summary: buildportfolio.summary,
    //     portfolioValue: buildportfolio.portfolioValue,
    //     overwritePf: true,
    //     savedPortfolio: buildportfolio.savedPortfolio,
    //   }
    // });
    // this.setState({redirectEnhancementPage: true})
    // console.log('redirectPortfolioEnhancementPage');
    const { history, setEnhancerState, getPortfolioDataByIDRequest, similarityMeasures, fundUniverse, buildportfolio } = this.props;
    if (setEnhancerState && buildportfolio && buildportfolio.portfolioName && buildportfolio.portfolioName !== '' && buildportfolio.portfolioValue) {
      getPortfolioDataByIDRequest({
        filename: buildportfolio.portfolioName,
        portfolioType: 'My Portfolio',
        _for: 'REDIRECTING'
      }, (res) => {
        if (res && res.tickers) {
          const { tickers } = res;
          if (history && tickers && tickers.length) {
            const { currentPerformanceImpact } = this.props;
            if (Object.keys(currentPerformanceImpact).length > 0) {
              setEnhancerState({
                portfolioValue: buildportfolio.portfolioValue,
                redirectFrom: {
                  savedPortfolio: {
                    ...buildportfolio.savedPortfolio,
                    redirectFromEnhancer: true,
                  },
                  module: 'builder',
                  portfolioName: buildportfolio.portfolioName,
                  link: '/build-portfolio/onboard-screen',
                  summary: buildportfolio.summary,
                  portfolioValue: buildportfolio.portfolioValue,
                }
              });
              const jObj = {
                "portfolioType": 'Client Portfolio',
                "portfolioId": buildportfolio.portfolioName,
                "universe": fundUniverse,
                "similarity-measures": similarityMeasures,
                "q": 'similar',
                "tickers": tickers,
                "callbackPath": "builder",
              }
              let urlParam = [];
              for (var i in jObj) {
                urlParam.push(encodeURI(i) + "=" + encodeURI(jObj[i]));
              }
              const newPath = `/enhancer/dashboard/funds?${urlParam.join("&")}`;
              history.push(newPath);
            }
          }
        } else {
          console.log('-- tickers missing --');
        }
      });
    }
  }

  toggleGenerateTradelistModal = () => {
    console.log('tradelist modal')
    addEventToAnalytics('Builder Tradelist', 'Builder Tradelist', 'BUILDER_TRADELIST', {}, false);
    this.setState({ downloadTradeList: !this.state.downloadTradeList })
  }

  selectedTradeList = (value) => {
    this.setState({ selectedTradeList: value });
  }

  onAlertDismiss = () => {
    const { setInvestmentState } = this.props
    setInvestmentState({
      reportPDFDownloadStatus: {
        message: '',
        downloadStatus: ''
      }
    });
  }

  render() {
    const { downloadTradeList } = this.state;
    const { accountList, calculatedPortfolio, reportPDFDownloadStatus, portfolioValue, reportLoader } = this.props;
    const getCardTitle = (title) => {
      return (
        <div className="folder-content-card-title">
          {title}
          <i className="far fa-times close-icon" onClick={this.clsoeActTab}></i>
        </div>
      )
    };
    // console.log(redirectEnhancementPage);
    // if(this.props.investment.funds && !this.props.investment.portfolioLoading && redirectEnhancementPage){
    //   this.props.history.push('/investments');
    //   // console.log('executed');
    // }
    return (
      <React.Fragment>
        {(reportPDFDownloadStatus && reportPDFDownloadStatus.message !== '' && reportPDFDownloadStatus.downloadStatus !== '')
          && (
            <CustomAlert className="alert-windown-wrapper" color={reportPDFDownloadStatus.downloadStatus} onDismissCallback={this.onAlertDismiss}>
              {reportPDFDownloadStatus.message}
            </CustomAlert>
          )
        }
        <Row className="folder-content-container act-content-container">
          <Card title={getCardTitle('Act')} className="folder-content-card-wrapper">
            <Row className="box-wrapper">
              <Col className={`box`} span={12}>
                {/* <Button onClick={() => this.downloadPortfolio()}>
                  <div className="title">Generate Proposal</div>
                </Button> */}
                <Button onClick={() => this.toggleGenerateTradelistModal()}>
                  <img className='tabs-icn' src='/assets/images/icons/Generate Tradelist.svg' alt='' />
                  <div className="title">Generate Tradelist</div>
                  <div className="sub-title">Download trades to enable transition to the target portfolio based on your custodian and other parameters</div>
                </Button>
              </Col>
              <Col className={`box`} span={12}>
                {/* <Button onClick={() => this.toggleGenerateTradelistModal()}>
                  <div className="title">Generate Tradelist</div>
                </Button> */}
                <Button onClick={() => this.redirectPortfolioEnhancementPage()}>
                  <img className='tabs-icn' src='/assets/images/icons/Enhancements.svg' alt='' />
                  <div className="title">Use Popular Enhancement Ideas</div>
                  <div className="sub-title">Have you left some money on the table ? Try applying the Magnifi Enhancer on your portfolio</div>
                </Button>
              </Col>
            </Row>
            <Row className="box-wrapper">
              <Col className={`box`} span={12}>
                {/* <Button onClick={() => this.redirectPortfolioEnhancementPage()}>
                  <div className="title">Use Popular Enhancement Ideas</div>
                </Button> */}
                <Button onClick={() => this.downloadPortfolio()}>
                  <img className='tabs-icn' src='/assets/images/icons/GenerateProposal.svg' alt='' width='28px' />
                  <div className="title">Generate Proposal</div>
                  <div className="sub-title">Download reports for prospects and end clients to highlight the performance characteristics of your portfolio</div>
                </Button>
              </Col>
              <Col className={`box disabled`} span={12}>
                <Button disabled>
                  <img className='tabs-icn' src='/assets/images/icons/Set-Alert.svg' alt='' />
                  <div className="title">Set Alerts</div>
                  <div className="sub-title">Receive automatic and personalized notifications in case of changes to your portfolio</div>
                  <div className='sub-title coming-soon'>Coming soon</div>
                </Button>
              </Col>
            </Row>
          </Card>
        </Row>
        {reportLoader &&
          <Loader loading={true} />
        }
        <GenerateTradeListModal
          isOpen={downloadTradeList}
          toggleModal={this.toggleGenerateTradelistModal}
          importedAccounts={accountList}
          items={calculatedPortfolio ? calculatedPortfolio : null}
          selectedTradeList={this.selectedTradeList}
          portfolioValue={portfolioValue}
          createPF={this.props.createPF}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ buildportfolio, portfolio, loading, importedAccounts, investment, profile, enhancer }) => ({
  buildportfolio,
  investment,
  portfolioValue: buildportfolio.portfolioValue,
  summary: buildportfolio.summary,
  reportLoader: investment.reportLoader,
  accountList: aggregateNetCashBalanceSelector(importedAccounts.accounts),
  calculatedPortfolio: buildportfolio.calculatedPortfolio,
  reportPDFDownloadStatus: investment.reportPDFDownloadStatus,
  profile: profile.profile,
  selectedBenchmark: portfolio.selectedBenchmark,
  upDownDataSet: portfolio.upDownDataSet,
  // customSearchTickerData: buildportfolio.equityTab.customSearchTickerData,
  // tickerLoading: createLoadingSelector(['CUSTOM_TICKER', 'TICKER_DATA'])({ loading }),
  similarityMeasures: enhancer.similarityMeasures,
  fundUniverse: enhancer.fundUniverse,
  currentPerformanceImpact: enhancer.performanceImpact.currentPerformanceImpact || {},
})

const mapDispatchToProps = {
  setSummarySuccess,
  downloadPortfolioReport,
  setPortfolioReplace,
  investmentFetchSuccess,
  fetchPortfolioData,
  investmentOptions,
  setInvestmentState,
  investmentFetch,
  setBuildPortfolioState,
  resetInvestmentState,
  resetPortfolioState,
  downloadBuilderReportPdf,
  setEnhancerState,
  getPortfolioDataByIDRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EquityContent));

import React from 'react';
import {
  Button,
  Row,
  Col,
  Table
} from 'reactstrap';
import { Typography } from 'antd';

import { SimpleLineChart } from 'components/Charts';
import { DonutChartS1, HBarChartS1 } from 'components/charts/Recharts';

import { SectorColors } from 'data/Colors';
import { cn, kmb } from 'DataSet';
import {
  numFmt,
  numDc,
  sortFn,
  addEventToAnalytics,
} from 'Utils';

import { FooterActions } from '../Common';

// const classNames = require('classnames')
const { Paragraph } = Typography;

export const Footer = props => {
  const { funds, items } = props;
  let tickerNames;
  if (items.length > 0) {
    tickerNames = items.map(x => x.ticker);
  }
  // const compareOnClick = () => {
  //   if (props.actionHandler) {
  //     props.actionHandler('Compare', null);
  //     if (funds) {
  //       addEventToAnalytics('Fund SFM Footer Button','Fund SFM Footer Button','FUND_SFM_FOOTER_BUTTON', { query: funds.query, logId: funds.logId, tickers: tickerNames, clickBtn: 'compare' },true);
  //     }
  //   }
  // }
  const buyOnClick = () => {
    if (props.buyHandler) {
      props.buyHandler()
      if (funds) {
        console.log('Fund Detail Buy');
        console.log('query --> ', funds.query);
        addEventToAnalytics('Fund Detail Buy', 'Fund Detail Buy', 'FUND_DETAIL_BUY_BUTTON', { query: funds.query, logId: funds.logId, tickers: tickerNames, clickBtn: 'Buy All' }, true);
      }
    }
  }
  const clearAllFunds = () => {
    if (props.clearAllFunds) {
      props.clearAllFunds()
    }
  }
  return (
    <React.Fragment>
      <FooterActions
        hideBack={true}
        clearHandler={() => clearAllFunds()}
      />

      {/*
        <Button
          style={{width: '135px'}}
          color="tools"
          className="btn h3 text-uppercase font-weight-bold p-3 mb-0 mt-0 ml-auto secondary-btn"
          onClick={() => compareOnClick()}
        >
          Compare
        </Button>
      */}

      <Button
        style={{ width: '135px' }}
        color="select"
        className="btn h3 text-uppercase font-weight-bold p-3 mb-0 ml-3 mt-0 secondary-btn"
        onClick={() => buyOnClick()}
      >
        Buy All
      </Button>
    </React.Fragment>
  )
}

export class FactsData extends React.Component {
  getLastActiveDate = (array) => {
    let index = array.findIndex(el => el.y3 && el.y3 !== 'Inactive')
    if (index >= 0) {
      return array[index].x
    }
    return null
  }

  render() {
    const { card, chartData, ydomain, fid } = this.props;

    if (!card) return null;

    let tickerStartDate = card._tickerStartDate;
    let share = cn(card, 'sharesout')
      ? numFmt(cn(card, 'sharesout') / (1000 * 1000)) + 'M'
      : '-';
    const assetValue = numFmt(cn(card, 'Assets'))
    const renderFactsData = (card.type !== 'SHARE') ? (
      <Table
        size="sm"
        className="mb-0"
        style={{ fontSize: 13, overflow: 'hidden' }}
      >
        <tbody>
          <tr>
            <td className="border-0 text-blue-6i">Ticker</td>
            <td className="border-0">{cn(card, 'Ticker Short')}</td>
          </tr>
          <tr>
            <td className="text-blue-6i">Expense Ratio</td>
            <td className="">{cn(card, 'Fees') ? numFmt(cn(card, 'Fees')) + '%' : ''}</td>
          </tr>
          <tr>
            <td className="text-blue-6i pr-0" style={{ width: '60%' }}>
              Assets under Management
            </td>
            <td className="">{cn(card, 'Assets') ? kmb(cn(card, 'Assets')) : ''}</td>
          </tr>
          <tr>
            <td className="text-blue-6i">Shares Outstanding</td>
            <td className="">{share}</td>
          </tr>
          <tr style={{ lineHeight: '1.2' }}>
            <td className="text-blue-6i">Benchmark Index</td>
            <td className="" title={cn(card, 'underlying_index')}>
              <Paragraph
                className="font-one m-0"
                style={{ lineHeight: '1.3' }}
                ellipsis={{ rows: 4 }}
                title={cn(card, 'underlying_index')}
              >
                {cn(card, 'underlying_index')}
              </Paragraph>
            </td>
          </tr>
          {card && card.vehicle && card.vehicle === "BASKET" ? <tr>
            <td className="text-blue-6i">Risk Factors</td>
            <td><a className="text-blue-6i" href="https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/Magnifi%20Thematic%20Risk%20Factors.pdf" style={{ textDecoration: 'underline' }} target="_blank">See More</a></td>
          </tr> : null}
        </tbody>
      </Table>
    ) : (
      <Table
        size="sm"
        className="mb-0"
        style={{ fontSize: 13, overflow: 'hidden' }}
      >
        <tbody>
          <tr>
            <td className="border-0 text-blue-6i">Ticker</td>
            <td className="border-0">{cn(card, 'Ticker Short')}</td>
          </tr>
          <tr>
            <td className="text-blue-6i">Dividend Yield</td>
            <td className="">{card.dividend_yield !== '' ? card.dividend_yield + '%' : '--'}</td>
          </tr>
          <tr>
            <td className="text-blue-6i pr-0" style={{ width: '60%' }}>
              Assets under Management
            </td>
            <td className="">{cn(card, 'Assets') ? kmb(cn(card, 'Assets')) : ''}</td>
          </tr>
          <tr>
            <td className="text-blue-6i">NASDAQ</td>
            <td className="">NASDAQ</td>
          </tr>
          {card && card.vehicle && card.vehicle === "BASKET" ? <tr>
            <td className="text-blue-6i">Risk Factors</td>
            <td><a className="text-blue-6i" href="https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/Magnifi%20Thematic%20Risk%20Factors.pdf" style={{ textDecoration: 'underline' }} target="_blank">See More</a></td>
          </tr> : null}
        </tbody>
      </Table>
    );

    return (
      <Row className="no-gutters ssf-spacer">
        <div className="col-md-6 col-sm-12 h5 mb-0 pr-2 text-gray-7 font-weight-normal table-responsive">
          {renderFactsData}
        </div>
        <Col className="col-md-6 col-sm-12 m-0">
          {(chartData && chartData[0] && chartData[0].x) && (
            <h6 className="text-center mb-0 text-blue-6i">
              Growth of Hypothetical $100
              <br />
              since inception ({this.getLastActiveDate(chartData)})
            </h6>
          )}
          <div className="ssf-spacer" />
          <div className="position-relative d-flex align-items-center media-justify-center">
            <SimpleLineChart
              fid={fid}
              width={320}
              height={125}
              syncId={fid}
              gSuffix={fid}
              ydomain={ydomain}
              lineData={chartData}
              tickerStartDate={tickerStartDate}
            />
          </div>
        </Col>
      </Row>
    )
  }
}

export class RegionsData extends React.Component {
  render() {
    const { data } = this.props
    let others = data && data.find(e => e.name === 'Others')
    const dataSorted = data && data.filter(e => e.name !== 'Others')
      .sort(sortFn('value'))

    return (
      <div className="container-fluid pb-4 mb-4 pl-0">
        <Row className="no-gutters align-items-center ssf-spacer media-flex-column chart-wrapper">
          <div className="col-md-4 col-sm-12 d-flex justify-content-center media-fund-detail-wrapper position-relative m-block">
            <div
              className="w-100 position-relative"
              style={{ width: '135px', height: '135px' }}
            >
              <DonutChartS1
                data={others ? [...dataSorted, others] : dataSorted}
              />
            </div>
          </div>
          <div className="col-md-8 col-sm-12 h6 mb-0 pr-2 text-gray-7 font-weight-normal chart-legend-wrapper">
            <Row>
              <Col className="col-md-6 col-sm-12 chart-legend col-12">
                {dataSorted.slice(0, 5).map((e, i) => (
                  <ChartLegend
                    key={i}
                    name={e.name}
                    value={e.value}
                    color={e.color}
                  />
                ))}
              </Col>
              <Col className="col-md-6 col-sm-12 chart-legend col-12">
                {dataSorted.slice(5, 10).map((e, i) => (
                  <ChartLegend
                    key={5 + i}
                    name={e.name}
                    value={e.value}
                    color={e.color}
                  />
                ))}
              </Col>
            </Row>
          </div>
        </Row>
      </div>
    )
  }
}

export const ChartLegend = ({ name, value, color }) => {
  return (
    <Row className="no-gutters border-bottom" style={{ padding: '6px 0' }}>
      <Col
        className="pl-2"
        style={{ borderLeft: `6px solid ${color}`, display: 'inline-grid' }}
      >
        {/* <TruncatedText text={name} lines={1} /> */}
        <p className="d-inline-block text-truncate m-0" title={name}>
          {name}
        </p>
      </Col>
      <Col className="pl-1 col-auto">{value.toFixed(1)}%</Col>
    </Row>
  )
}

export class HoldingsData extends React.Component {
  render() {
    const { chartData } = this.props
    const colors = SectorColors

    const data = chartData.map((e, i) => ({
      name: e.name,
      value: numDc(e.weight),
      color: colors[i % colors.length]
    }))

    return (
      <Row className="no-gutters align-items-center ssf-spacer">
        <Col className="col-12 d-flex justify-content-center">
          <div className="w-100" style={{ height: '135px' }}>
            <HBarChartS1 data={data} />
          </div>
        </Col>
      </Row>
    )
  }
}

export const Loader = props => {
  return (
    <div className="overlay active">
      <div className="text rounded">
        <i className="fal fa-circle-notch fa-3x fa-spin text-gray-5"></i>
        <h5 className="mt-2 mb-0">Loading fund data</h5>
      </div>
    </div>
  )
}

export default {
  HoldingsData,
  Loader,
  ChartLegend,
  RegionsData,
  FactsData,
  Footer,
}

import { takeEvery, fork, call, put, all, select } from 'redux-saga/effects'

import ActionTypes from 'action-types';
import { getTickerListFetch, getTickerListSuccess } from 'actions/global';

import { getAllTickersAPI_GET } from 'apis/enhancerAPIs'

function* getAllTickerListData(action) {
  try {
    yield put(getTickerListFetch());
    let result = yield call(getAllTickersAPI_GET);
    if (result && Array.isArray(result)) {
      result.forEach((kl, i) => {
        if (kl && kl.ticker) {
          kl.label = kl.ticker;
          kl.value = kl.ticker;
        }
      });
      yield put(getTickerListSuccess({
        magnifiTickers: result,
      }));
    } else {
      yield put(getTickerListSuccess([]));
    }
  } catch (error) {
    console.log(error)
    yield put(getTickerListSuccess([]));
  }
}

function* globalSaga() {
  yield all([
    fork(takeEvery, ActionTypes.GET_ALL_TICKER_LIST_REQUEST, getAllTickerListData),
  ])
}

export default globalSaga;

import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import * as serviceWorker from './serviceWorker'

import { IntlProvider } from 'react-intl'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import logger from 'redux-logger'

import App from './App'
import config from './env'
// import apiMiddleware from './middleware/api-middleware'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

// import fundsSaga from './sagas/funds'
import authSaga from './sagas/auth'
import sfmSaga from './sagas/sfm'
import registerSaga from './sagas/register'
import profileSaga from './sagas/profile'
import brokerSaga from './sagas/broker'
import pluginSaga from './sagas/plugins'
import weightSaga from './sagas/weights'
import investmentSaga from './sagas/investment'
import portfolioSaga from './sagas/portfolio'
import buildPortfolioSaga from './sagas/buildportfolio'
// import clientPortfolioSaga from './sagas/clientportfolio'
import clientPortfolioV2Saga from './sagas/clientportfolioV2';
import reportSaga from './sagas/reports'
import enhancerSaga from './sagas/enhancer'
import accountsSaga from './sagas/accounts'
import discoverySaga from './sagas/discovery'
import trendingsearchesSaga from './sagas/trendingsearches';
import alertsSaga from './sagas/alerts';
import globalSaga from './sagas/global';
import quickTradeSaga from './sagas/quicktrade';

import rootReducer from './reducers'

import 'components/reports/_reports.scss';
import './style/App.scss'
import './style/icomoon/style.css'

export const history = createBrowserHistory()

Sentry.init({
  environment: config ? config.sentryEnv : 'dev',
  dsn: "https://3a0d5637cb704a0cb3c07c79bb0bc684@o937001.ingest.sentry.io/5887422",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const sagaMiddleware = createSagaMiddleware()
// const middleware = process.env.REACT_APP_ENV === 'dev' ? [sagaMiddleware, logger] : [sagaMiddleware] //apiMiddleware
const middleware = [sagaMiddleware]
// Use the Redux Dev Tool extension only in dev environment
// const composeEnhancers = process.env.REACT_APP_ENV === 'dev'
//   ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({  trace: true, traceLimit: 10 })
//   : compose

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  rootReducer(history),
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      ...middleware
    )
  )
)

sagaMiddleware.run(authSaga)
sagaMiddleware.run(registerSaga)
sagaMiddleware.run(profileSaga)
sagaMiddleware.run(brokerSaga)
sagaMiddleware.run(investmentSaga)
sagaMiddleware.run(sfmSaga)
// sagaMiddleware.run(fundsSaga)
sagaMiddleware.run(pluginSaga)
sagaMiddleware.run(weightSaga)
sagaMiddleware.run(portfolioSaga)
sagaMiddleware.run(buildPortfolioSaga)
// sagaMiddleware.run(clientPortfolioSaga)
sagaMiddleware.run(clientPortfolioV2Saga)
sagaMiddleware.run(reportSaga)
sagaMiddleware.run(enhancerSaga)
sagaMiddleware.run(accountsSaga)
sagaMiddleware.run(discoverySaga)
sagaMiddleware.run(trendingsearchesSaga)
sagaMiddleware.run(alertsSaga)
sagaMiddleware.run(globalSaga)
sagaMiddleware.run(quickTradeSaga)

history.listen(function (location) {
  // console.log('recording ga');
  // window.ga('set', 'page', location.pathname + location.search);
  // window.ga('send', 'pageview');
});

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale="en">
      <App history={history} />
    </IntlProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

export default store;

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import style from './ActTab.module.scss'
import { Modal, ModalBody } from 'reactstrap';
import { addApexMoney } from 'apis/funds'
import moment from 'moment'
import { fetchProfile } from 'actions/profile'
import { AddMoneyStatusMapping } from 'DataSet'
import { numFmt } from 'Utils';

const IS_WITHDRAWAL = false
class AddMoney extends Component {

  constructor(props) {
    super(props)
    this.inputRef = React.createRef();
  }

  state = {
    value: '',
    loader:false,
    isMoneyAdded:false,
    error:false,
    response:'',
  };

  onChange(e) {
    if(e.target.value<0){
      this.setState({value:''})
    }else{
      let query = e.target.value ? e.target.value : "";
      let value = query.includes('.') || query.length < 2   ?  query : query.replace(/^0+/, '');
      this.setState({value})
    }
  }

  onCloseIcon = ()=>{
    this.props.toggleAddMoneyPopup()
  }

  onExit = ()=>{
    this.setState({
      value: '',
      loader:false,
      isMoneyAdded:false,
      error:false,
      response:'',
    })
  }

  onOkClick = ()=>{
    this.props.callBack ?this.props.callBack(): this.props.toggleAddMoneyPopup()
  }

  onConfirm = async ()=>{
    const { isWithdrawal=IS_WITHDRAWAL } = this.props;
    this.setState({
      loader:true
    })
    let payload = {
      data:{
        amount: isWithdrawal?(this.state.value*-1):this.state.value,
        memo:moment().toISOString()
      }
    }
    let result = await addApexMoney(payload);

    console.log(result)

    let data = AddMoneyStatusMapping(result.data.status,this.state.value, isWithdrawal)

    if(result.data.errorDescription || result.status !== 200 || !data ){
      this.setState({
        response: 'error',
        error: true,
        status: result.data.error ? result.data.error : 'Error',
        reason: result.data.errorDescription ? result.data.errorDescription : result.data.reason,
        loader:false,
        isMoneyAdded : true,
      })
      // this.props.fetchProfile()
    }
    else{
      this.setState({
        response:result.data.status,
        status:data.status,
        reason:data.popup_message,
        popup_img:data.popup_img,
        loader:false,
        isMoneyAdded : true,
      })
      // this.props.fetchProfile()
    }
    const { refreshApexAccountDetails } = this.props;
    if (refreshApexAccountDetails) {
      refreshApexAccountDetails();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  render() {
    const {
      showAddMoneyPopup,
      isWithdrawal=IS_WITHDRAWAL,
      wdBalance
    } = this.props;

    const { loader, isMoneyAdded, response, value } = this.state
    const Spinner = () => <div className="w-100">
      <div className="text-secondary text-center">
        <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5"></i>
      </div>
    </div>;

    return (
      <Fragment>
        <Modal
          keyboard
          centered
          onExit={this.onExit}
          onClosed={this.onExit}
          backdrop
          unmountOnClose={true}
          isOpen={showAddMoneyPopup}
          className="add-money-popup-sfm"
          backdropClassName="premium-feature-process-modal-backdrop"
          toggle={!loader && !isMoneyAdded && this.onCloseIcon}
        >
          <ModalBody className="ssf-modal__body">
            <div className={style.addMoney}>

              {!loader && !isMoneyAdded &&
              <i onClick={this.props.toggleAddMoneyPopup} className={`fal fa-times ${style.close}`} />}

              {/*before money add or withdrawal*/}
              {!loader && !isMoneyAdded && <div className={style.beforeAdd}>
                <p className={style.header}>{isWithdrawal ? 'Withdraw Money' : 'Add Balance To Trading Account'}</p>
                <input
                  className={style.addMoneyInput}
                  type={'number'}
                  ref={this.inputRef}
                  step={'any'}
                  min={0}
                  autoFocus={true}
                  placeholder={'$ Enter Amount'}
                  value={value}
                  onChange={(e) => {
                    this.onChange(e)
                  }}
                  // onBlur={this.debouncedOnChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      this.onConfirm()
                    }
                  }}
                />

                {(isWithdrawal && value>wdBalance) && <span className={style.errorWithdrawal}>{`Available Withdrawal Amount : $${numFmt(wdBalance)}`}</span>}
                <button className={style.confirm} disabled={!!!parseFloat(value) || (isWithdrawal && value>wdBalance) || !value.match(/^[\.0-9]\d*(\.\d{1,2})?$/) || value < 0.01}
                        onClick={this.onConfirm}> confirm
                </button>
              </div>}

              {loader && <Spinner/>}

              {/*after money add or withdrawal*/}
              {!loader && isMoneyAdded && <div className={style.afterMoney}>
                {response !== 'error' ?
                  <div className={style.div}>
                    <img alt={''} src={'/assets/images/icons/apex_'+this.state.popup_img+'.svg'} width="50" />
                    <p className={style.header}>{this.state.status}</p>
                    <p style={{fontSize:`14px`}}>{this.state.reason}</p>
                    <button className={style.confirm} onClick={this.onOkClick}> ok</button>
                  </div> :
                  <div className={style.div}>
                    <img alt={''} src='/assets/images/icons/apex_close.svg' width="50" />
                    <p className={style.header}>{this.state.status}</p>
                    <p style={{fontSize:`14px`}}>{this.state.reason ? this.state.reason: 'Try again later'}</p>
                    <button className={style.confirm} onClick={this.onOkClick}> ok</button>
                  </div>
                }
              </div>}
            </div>
          </ModalBody>
        </Modal>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ auth, profile }) => ({})

const mapDispatchToProps = {
  fetchProfile
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddMoney))


const Img = ({ status })=>{
  switch (status) {

  }
}

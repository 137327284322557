import React, { Component } from 'react';
import { Form } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import { REDIRECT_DOMAIN_PATH } from 'DataSet';
import { Link } from 'react-router-dom';

const magnifiLogo = "/assets/images/magnifi_by_tifin.svg";
const magnifiInvestResponsiblyLogo = '/assets/images/register-magnifi-invest-resp-logo.svg';

export default class ThankYou extends Component {
    componentDidMount() {
        document.getElementsByTagName('body')[0].style.paddingTop = 0;
      }

    render() {
        return (
            <React.Fragment>
                <Row className="magnifi-auth-page-wrapper">
                    <Col md="6" lg="6" className="left-side-panel-container">
                        <a className="logo-wrapper" href={REDIRECT_DOMAIN_PATH}>
                            <img src={process.env.REACT_APP_SUB_DOMAIN === 'retail' ? magnifiInvestResponsiblyLogo : magnifiLogo} height={70} alt="magnifi" />
                        </a>
                    </Col>
                    <Col md="6" lg="6" className="right-side-panel-container">
                        <Form className="sign-in magnifi-auth-design-container">
                            <div className="header-area">
                                <div className="header-label">Link is expired</div>
                                <br/>
                                <div className="sub-header-label">Please go to <Link to="/forgotpwd">Forgot password</Link> link to generate new password
                                </div>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, ButtonGroup } from 'reactstrap';
import { PrimaryButton } from 'shared-components'
import ModelAllocation from './ModelAllocation';
import SelectedModel from './SelectedModel';
import { clientHoldingsRequest, pfHoldingsRequest, clearHoldingsRequest, setSelectedPortfolio, finalizeAllocationRequest, resetClientList, searchAccFundsSuccess, modelSearchSuccess, modelSearchRequest } from 'actions/clientportfolioV2';
import { addEventToAnalytics } from 'Utils';

class ModelSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedModel: '',
      selectedTabIndex: '1'
    };
  }

  componentDidMount(){
    this.getHoldingsData(this.props.selectedClient);
  }

  getHoldingsData = (obj) => {
    // cd => client details
    let cd = this.props.clientList.find(a => a.id == obj);
    let { model_portfolio: mp, custom_portfolio: cp } = cd;
    if(mp.ticker){
      // Clear holdings request & search results for spinner
      this.props.modelSearchSuccess([])
      this.props.clearHoldingsRequest()
      this.props.pfHoldingsRequest({ticker: mp.ticker});
      this.setSelectedModel(mp.portfolio_name)
    }else if(cp.portfolio_name){
      // Clear holdings request & search results for spinner
      this.props.modelSearchSuccess([])
      this.props.clearHoldingsRequest()
      this.props.pfHoldingsRequest({file_name: cp.portfolio_name});
      this.setSelectedModel(cp.portfolio_name)
    }else{
      // Do nothing for now, will review later and change
      // the logic if required
      this.props.clearHoldingsRequest()
    }
  }

  setSelectedTabIndex = (val) => {
    this.setState({ selectedTabIndex: val })
  }

  setSelectedModel = (val) => {
    this.setState({selectedModel: val});
  }

  finalizeAllocation = () => {
    addEventToAnalytics('Client Allocate Finalize', 'Client Allocate Finalize', 'CLIENT_ALLOCATE_FINALIZE', {}, false)
    this.props.finalizeAllocationRequest(this.props.finalizeObject);
    this.props.searchAccFundsSuccess([]);
    this.props.setSelectedClient('');
  }

  cancelModelSelection = () => {
    addEventToAnalytics('Client Allocate Cancel', 'Client Allocate Cancel', 'CLIENT_ALLOCATE_CANCEL', {}, false)
    this.props.resetClientList({initialClientList: this.props.initialClientList});
    // this.props.searchAccFundsSuccess([]);
    this.props.setSelectedClient('');
  }

  updateClientPfObj = (pfName, ticker, clientID, clientList, isGlobalSelect) => {
    this.props.setSelectedPortfolio({
      value: pfName,
      ticker: ticker,
      clientID: clientID,
      clientList: clientList,
      isGlobalSelect: isGlobalSelect
    });
  }

  sendSelectedPortfolio = (pfName, pfTicker, clientID, isGlobalSelect) => {
    if(!pfName){
      this.setSelectedTabIndex("1");
    }
    // This is to highlight the selected row in model allocation
    this.props.setSelectedClient(clientID);
    // This is to set the right panel header title
    this.setSelectedModel(pfName);
    // Update the portfolio object in the client list
    this.updateClientPfObj(pfName, pfTicker, clientID, this.props.clientList, isGlobalSelect);
    // Clear holdings request & search results for spinner
    this.props.modelSearchSuccess([])
    this.props.clearHoldingsRequest()
    // Get the model/custom portfolio's holdings
    if(pfTicker || pfName) this.props.pfHoldingsRequest({ticker: pfTicker, file_name: pfName});
  }

  moveBackTo = (searchedQuery) => {
    const { modelSearchRequest } = this.props;
    if (modelSearchRequest) {
      modelSearchRequest({q: searchedQuery, c: 5});
    }
  }

  render() {

    const commonProps = {
      selectedClient: this.props.selectedClient,
      setSelectedClient: this.props.setSelectedClient,
      setSelectedModel: this.setSelectedModel,
      selectedModel: this.state.selectedModel,
      selectedTabIndex: this.state.selectedTabIndex,
      setSelectedTabIndex: this.setSelectedTabIndex,
      sendSelectedPortfolio: this.sendSelectedPortfolio,
      updateClientPfObj: this.updateClientPfObj,
      searchedQuery: this.props.searchedQuery,
      moveBackTo: this.moveBackTo,
    };

    return (
      <React.Fragment>
        <Row className="model-selector-wrapper">
          <Col xs={6} className="tbl-wrapper">
            <ModelAllocation {...commonProps} />
          </Col>
          <Col xs={6} className="tbl-wrapper">
            <SelectedModel {...commonProps} />
          </Col>
        </Row>
        <div className="cta-footer">
          <div className="d-flex w-100 justify-content-center pt-3 pb-2">
            <ButtonGroup className="cta-wrapper justify-content-left mr-3">
              <PrimaryButton
                className="btn secondary-btn fill p-1 mr-4 tgen-cta"
                type="button"
                style={{ minWidth: 120 }}
                disabled={!this.props.finalizeObject.length}
                onClick={this.finalizeAllocation}
              >
                FINALIZE
              </PrimaryButton>
            </ButtonGroup>
            <ButtonGroup className="cta-wrapper justify-content-left">
              <PrimaryButton
                className="btn secondary-btn no-fill p-1 mr-4 tgen-cta"
                type="button"
                style={{ minWidth: 120 }}
                onClick={this.cancelModelSelection}
              >
                CANCEL
              </PrimaryButton>
            </ButtonGroup>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStatetoProps = ({ clientportfolio: { initialClientList, clientList, finalizeObject } }) => ({
  initialClientList,
  clientList,
  finalizeObject
})

const mapDispatchToProps = {
  clientHoldingsRequest,
  pfHoldingsRequest,
  clearHoldingsRequest,
  setSelectedPortfolio,
  finalizeAllocationRequest,
  resetClientList,
  searchAccFundsSuccess,
  modelSearchSuccess,
  modelSearchRequest,
}

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(ModelSelector);

import axios from 'axios';

const https = require('https');

/* eslint no-useless-escape:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/g;

export const isUrl = (path) => {
  return reg.test(path);
}

export const updateMetaInfo = (obj = {}) => {
  document.getElementsByTagName('meta')["description"].content = obj.description ? obj.description : 'A digital platform to search, discover and trade ETFs, Mutual Funds and other investment options. Change how you shop for investment options now.' ;
  document.title = obj.title ? obj.title : "Magnifi – Discover a world of investing.";
}

function getRelation(str1, str2) {
  if (str1 === str2) {
    console.warn('Two path are equal!');  // eslint-disable-line
  }
  const arr1 = str1.split('/');
  const arr2 = str2.split('/');
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1;
  } else if (arr1.every((item, index) => item === arr2[index])) {
    return 2;
  }
  return 3;
}

function getRenderArr(routes) {
  let renderArr = [];
  renderArr.push(routes[0]);
  for (let i = 1; i < routes.length; i += 1) {
    let isAdd = false;
    isAdd = renderArr.every(item => getRelation(item, routes[i]) === 3);
    renderArr = renderArr.filter(item => getRelation(item, routes[i]) !== 1);
    if (isAdd) {
      renderArr.push(routes[i]);
    }
  }
  return renderArr;
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */

export const getRoutes = (path, routerData) => {
  let routes = Object.keys(routerData).filter(routePath => routePath.indexOf(path) === 0 && routePath !== path); // eslint-disable-line
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map(item => item.replace(path, ''));
  // Get the route to be rendered to remove the deep rendering
  const renderArr = getRenderArr(routes);
  // Conversion and stitching parameters
  const renderRoutes = renderArr.map((item) => {
    if (item) {
      const exact = !routes.some(route => route !== item && getRelation(route, item) === 1);
      return {
        ...routerData[`${path}${item}`],
        key: `${path}${item}`,
        path: `${path}${item}`,
        exact,
      };
    } else {
      const exact = !routes.some(route => route !== item && getRelation(route, item) === 1);
      return {
        ...routerData[`${path}`],
        key: `${path}`,
        path: `${path}`,
        exact,
      };
    }
  });
  return renderRoutes;
}

// function isValidImageUrl(urlString) {
//   const imageUrlRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?\.(png|jpe?g|gif)$/i;
//   return imageUrlRegex.test(urlString);
// }

// // Function to validate an image URL
// async function validateImageUrl(imageUrl) {
//   try {
//     const response = await axios.get(imageUrl, {
//       headers: {
//         Accept: 'image/*'
//       }
//     });
//     // const response = await axios.get(imageUrl);
//     return response.status === 200;
//   } catch (error) {
//     return false;
//   }
// }

// // Function to validate an image URL by fetching and checking content type
// async function validateImageUrl(imageUrl) {
//   try {
//     const response = await axios.get(imageUrl, {
//       responseType: 'arraybuffer' // Ensure binary response
//     });

//     // Check if response status is 200 and content type starts with "image/"
//     return response.status === 200 && response.headers['content-type'].startsWith('image/');
//   } catch (error) {
//     return false;
//   }
// }

// Function to validate an image URL using axios
async function validateImageUrl(imageUrl) {
  try {
    const response = await axios.get(imageUrl, {
      responseType: 'arraybuffer'
    });
    const contentType = response.headers['content-type'];
    return contentType && contentType.startsWith('image/');
  } catch (error) {
    return false;
  }
}

// Function to extract URLs from a nested object
export function extractUrlsFromObject(obj) {
  const urls = [];
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      // Check if the value is a URL pattern
      const urlRegex = /(https?|ftp):\/\/[^\s/$.?#].[^\s]*/i;
      if (urlRegex.test(obj[key])) {
        urls.push(obj[key]);
      }
    } else if (typeof obj[key] === 'object') {
      // Recursively search nested objects
      urls.push(...extractUrlsFromObject(obj[key]));
    }
  }
  return urls;
}

// Function to process and validate URLs in the data
export function processAndValidateUrls(data) {
  for (const key in data) {
    if (typeof data[key] === 'string' && data[key].startsWith('https://')) {
      if (!isValidImageUrl(data[key])) {
        console.log("Invalid logo URL. Please provide a valid image URL.")
        data[key] = null;
      } 
      // else {
      //   const isValid = await validateImageUrl(data[key]);
      //   if (!isValid) {
      //     data[key] = null;
      //   }
      // }
    } else if (typeof data[key] === 'object') {
      processAndValidateUrls(data[key]);
    }
  }
}

// const function sanitizeImagePaths(inputObj) {
//   const sanitizedObj = JSON.parse(JSON.stringify(inputObj)); // Create a deep copy to preserve the original object
  
//   function sanitizeUrl(url) {
//     // Define a regex pattern to check for known image file extensions
//     const imageExtensionRegex = /\.(png|jpe?g|gif)$/i;
    
//     // Check if the URL matches the image extension pattern
//     if (!imageExtensionRegex.test(url)) {
//       return null; // Return null for non-image URLs
//     }
    
//     // You can add more validation checks here if needed
    
//     return url; // Return the URL as it's a valid image URL
//   }
  
//   function recursivelySanitize(obj) {
//     for (const key in obj) {
//       if (obj.hasOwnProperty(key)) {
//         if (typeof obj[key] === 'string') {
//           obj[key] = sanitizeUrl(obj[key]) || ''; // Replace with empty string if URL is invalid
//         } else if (typeof obj[key] === 'object') {
//           recursivelySanitize(obj[key]); // Recursive call for nested objects
//         }
//       }
//     }
//   }
  
//   recursivelySanitize(sanitizedObj);
//   return sanitizedObj;
// }

function isValidImageUrl(urlString) {
  const imageUrlRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?\.(png|jpe?g|gif)$/i;
  return imageUrlRegex.test(urlString);
}

export const sanitizeInput = (input) => {
  const sanitizedInput = JSON.parse(JSON.stringify(input));

  function sanitizeUrl(url) {
    if (isValidImageUrl(url)) {
      return url;
    }
    return null; // Invalid URL, remove it from the object
  }

  function sanitizeObject(obj) {
    for (const key in obj) {
      if (typeof obj[key] === "string") {
        const sanitizedKeys = ["logo", "url", "icon", "image", "assets", "Logo", "Image", "img"];
        if (sanitizedKeys.some(sanitizedKey => key.includes(sanitizedKey)) || obj[key].startsWith("javascript:")) {
          obj[key] = sanitizeUrl(obj[key]);
        }
      } else if (typeof obj[key] === "object") {
        sanitizeObject(obj[key]);
      }
    }
  }

  sanitizeObject(sanitizedInput);

  return sanitizedInput;
}


export default {
  isUrl,
  getRoutes,
  sanitizeInput,
  validateImageUrl,
  extractUrlsFromObject,
  processAndValidateUrls,
};

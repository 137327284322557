import React, { useContext } from 'react'
import { Row, Col } from 'reactstrap'
import { numberCheck } from 'layouts/utils'
import { withHeaderFooter } from './HeaderFooter'
import GlideChart from './GlideChart'
// import { StackedAreaChart } from '../../PortfolioReportV2/shared/Charts'
import { FormatCurrency } from '../../PortfolioReportV2/shared/Utils'

const ExecutiveSummary = withHeaderFooter((props) => {
  console.log('ExecutiveSummary ->', props)
  let chartData = []

  const advisory_fees = props.advisoryFees && !isNaN(props.advisoryFees) ?  parseFloat(props.advisoryFees) : 0;

  let returnsCalcBasedOn = '3 Year', volatilityCalcBasedOn = '3 Year';
  if (numberCheck(props.anRet10yr)) {
    returnsCalcBasedOn = '10 Year';
  } else if (numberCheck(props.anRet5yr)) {
    returnsCalcBasedOn = '5 Year';
  } else {
    returnsCalcBasedOn = '3 Year';
  }

  if (numberCheck(props.anVol10yr)) {
    volatilityCalcBasedOn = '10 Year';
  } else if (numberCheck(props.anVol5yr)) {
    volatilityCalcBasedOn = '5 Year';
  } else {
    volatilityCalcBasedOn = '3 Year';
  }

  // fallback condition for 10 years returns/volatility
  let _CPIanRetyr = numberCheck(props.anRet10yr) ? numberCheck(props.anRet10yr) : (numberCheck(props.anRet5yr) ? numberCheck(props.anRet5yr) : numberCheck(props.anRet3yr));
  let _CPIanVolyr = numberCheck(props.anVol10yr) ? numberCheck(props.anVol10yr) : (numberCheck(props.anVol5yr) ? numberCheck(props.anVol5yr) : numberCheck(props.anVol3yr));


  // let annualMeanReturn = parseInt(props.anRet10yr, 10) / 100
  let annualMeanReturn = (numberCheck(_CPIanRetyr) - advisory_fees)/100;

  // let annualizedVolatility = parseInt(props.anVol10yr, 10) / 100
  let annualizedVolatility = numberCheck(_CPIanVolyr)/100;

  // let minus_1_sd_10yr = parseInt(props.minus_1_sd_10yr, 10) / 100
  let minus_1_sd_10yr = ((numberCheck(_CPIanRetyr) - advisory_fees)+-1*numberCheck(_CPIanVolyr))/100;

  // let plus_1_sd_10yr = parseInt(props.plus_1_sd_10yr, 10) / 100
  let plus_1_sd_10yr = ((numberCheck(_CPIanRetyr) - advisory_fees)+numberCheck(_CPIanVolyr)*1)/100;

  let invPortfolioValue = parseInt(props.pv, 10)

  // Chart Computation

  let noOfYrs = 10

  let minValue = Number.POSITIVE_INFINITY
  let maxValue = Number.NEGATIVE_INFINITY

  for (var i = 0; i <= noOfYrs; i++) {
    let name = i == 0 ? 'Today' : 'Year ' + i
    let lb =
      i == 0
        ? Math.round(invPortfolioValue)
        : Math.round(chartData[i - 1].lb * (1 + minus_1_sd_10yr))
    if (lb < minValue) minValue = lb
    if (lb > maxValue) maxValue = lb
    let mb =
      i == 0
        ? Math.round(invPortfolioValue)
        : Math.round(chartData[i - 1].mb * (1 + annualMeanReturn))
    if (mb < minValue) minValue = mb
    if (mb > maxValue) maxValue = mb
    let ub =
      i == 0
        ? Math.round(invPortfolioValue)
        : Math.round(chartData[i - 1].ub * (1 + plus_1_sd_10yr))
    if (ub < minValue) minValue = ub
    if (ub > maxValue) maxValue = ub
    chartData.push({ name, lb, mb, ub })
  }

  let minMaxValues = {
    minValue : isFinite(minValue) ? minValue : 'auto',
    maxValue : isFinite(maxValue) ? maxValue : 'auto'
  }

  let chartOptions = [{dataKey: 'ub', fillColor: '#56a9e8', fillOpacity: 0.3, stroke: '#56a9e8'},{dataKey: 'mb', fillColor: '#56a9e8', fillOpacity: 0.3, stroke: '#56a9e8'},{dataKey: 'lb', fillColor: '#56a9e8', fillOpacity: 0.3, stroke: '#56a9e8'}];

  let portPerfPercent = numberCheck(((chartData[noOfYrs].mb - invPortfolioValue)/invPortfolioValue * 100), 2); // ((chartData[noOfYrs].mb-invPortfolioValue)/invPortfolioValue * 100).toFixed(1);
  let avgReturn = numberCheck(_CPIanRetyr, 2); // numberCheck(cpi.anRet3yr, 2);
  let expRisk = numberCheck(_CPIanVolyr, 2); // numberCheck(cpi.anVol3yr, 2);
  let avgGrowth = chartData[noOfYrs].mb - invPortfolioValue;
  let avgGrowthChart = chartData[noOfYrs].mb;

  // <Row>
  //   <Col xs="12">
  //     <GlideChart {...props} />
  //   </Col>
  // </Row>

  return (
    <React.Fragment>
      <div className="content">
        <div className="pr-v2-header">
          <h3>Executive Summary</h3><br />
        </div>
        <div className="pr-v2-ex-summary">
          <div>
            {props.createdDate && <span>Data as of {props.createdDate}.</span>}
            <p>
              We have designed an investment portfolio intended reflect to your risk profile.
              <br />
              The portfolio has diversified exposure to global equities and global bonds with tilts to reflect your
              profile.
              <br />
              The portfolio has the potential for an average return of {avgReturn}% per annum with potential risk of {expRisk}%. This could
              translate to an expected average growth of {FormatCurrency(avgGrowth,0,'$','unf')} over 10 years. The chart below reflects the
              impact of portfolio risk on this potential growth.
            </p>
          </div>
          <Row>
            <Col xs="12">
              <GlideChart
                showLabels
                chartData={chartData}
                chartOptions={chartOptions}
                minMaxValues={minMaxValues}
                avgGrowthChart={avgGrowthChart}
              />
            </Col>
          </Row>
        </div>
        <div className="pr-v2-ex-summary">
          <div style={{ fontSize: '16px' }}>
            <p>This information was generated by an investment analysis tool that produces simulations and statistical analyses that present the
              likelihood of various investment outcomes if certain investments are made or certain investment strategies or styles are
              undertaken. Past performance does not guarantee future results. Magnifi LLC does not guarantee any minimum level of investment performance or the success of any portfolio or investment strategy. Results may vary with each use and over time. There is a
              potential for loss in any investment, including the loss of capital. All investments involve risk, and different types of investments
              involve varying degrees of risk. Investment recommendations will not always be profitable. No representation is being made that
              any client account will or is likely to achieve profit or losses similar to those shown in the chart above. Hypothetical performance
              information shown in text, charts, tables and graphs is provided for informational purposes only and should not be considered investment advice or a recommendation to buy or sell any particular security.
            </p>
            <br />
            <p>
              There are certain limitations inherent in hypothetical results like those portrayed, particularly that such hypothetical results do not
              reflect trading in actual client accounts and do not reflect the impact that material economic and market factors may have had on
              the investments chosen had the adviser actually been managing client accounts. Unlike an actual performance record, hypothetical performance results do not represent actual trading. These types of simulations, in general, benefit compared to actual performance results because such simulations are designed with the benefit of hindsight. In addition, simulated trading does not involve or take
              into account financial risk and does not take into account that material and market factors may have on investments
              chosen, all of which can adversely affect actual trading results and performance.
            </p>
            <br />
          </div>
        </div>
      </div>
    </React.Fragment >
  )
})

export default ExecutiveSummary

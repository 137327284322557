import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { MODEL_PORTFOOLIO_QUERY_MAP } from 'DataSet';
import MDrawer from './MDrawer';
import { FeesCard, ReturnCard, RiskCard, getOrderOfCard } from './PortfolioModalHelper';
import { getPerformanceSeriesAndYear } from '../utils';
import { addEventToAnalytics } from '../../../../Utils'

const DEFAULT_YEARS = 'pfRet1y';

class PortfolioModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentLoadData: {
        ...props.currentStats,
        selectedSeries: props.currentStats[DEFAULT_YEARS],
        sharpeRatio: props.currentStats[`sharpe_${DEFAULT_YEARS}`],
        cumlValue: props.currentStats[`cuml_${DEFAULT_YEARS}`]
      },
      enhanceLoadData: {},
      selectedYear: DEFAULT_YEARS,
    }
  }

  componentDidMount() {
    const { query, portfolioEnhanceReplace, enhanceStats, currentStats } = this.props;
    if (currentStats) {
      this.state = {
        currentLoadData: {
          ...currentStats,
          selectedSeries: currentStats[DEFAULT_YEARS],
          sharpeRatio: currentStats[`sharpe_${DEFAULT_YEARS}`],
          cumlValue: currentStats[`cuml_${DEFAULT_YEARS}`]
        },
        selectedYear: DEFAULT_YEARS,
      }
    }
  }

  componentWillReceiveProps(newProps) {
    const { portfolioEnhanceReplace, enhanceStats, currentStats } = newProps;
    if (currentStats !== this.props.currentStats) {
      if (currentStats) {
        this.state = {
          currentLoadData: {
            ...currentStats,
            selectedSeries: currentStats[DEFAULT_YEARS],
            sharpeRatio: currentStats[`sharpe_${DEFAULT_YEARS}`],
            cumlValue: currentStats[`cuml_${DEFAULT_YEARS}`]
          },
          selectedYear: DEFAULT_YEARS,
        }
      }
    }

    if (portfolioEnhanceReplace.length > 0) {
      if (enhanceStats && Object.keys(enhanceStats).length > 0 && enhanceStats !== this.props.enhanceStats) {
        this.state = {
          currentLoadData: {
            ...currentStats,
            selectedSeries: currentStats[DEFAULT_YEARS],
            sharpeRatio: currentStats[`sharpe_${DEFAULT_YEARS}`],
            cumlValue: currentStats[`cuml_${DEFAULT_YEARS}`]
          },
          enhanceLoadData: {
            ...enhanceStats,
            selectedSeries: enhanceStats[DEFAULT_YEARS],
            sharpeRatio: enhanceStats[`sharpe_${DEFAULT_YEARS}`],
            cumlValue: enhanceStats[`cuml_${DEFAULT_YEARS}`]
          },
          selectedYear: DEFAULT_YEARS,
        }
      }
    } else {
      this.state = {
        currentLoadData: {
          ...currentStats,
          selectedSeries: currentStats[DEFAULT_YEARS],
          sharpeRatio: currentStats[`sharpe_${DEFAULT_YEARS}`],
          cumlValue: currentStats[`cuml_${DEFAULT_YEARS}`]
        },
        selectedYear: DEFAULT_YEARS,
      }
    }
  }
	title = () => {
		switch(this.props.query) {
			case 'similar':
				return 'Use All Suggested Funds'

			case 'outperform':
				return 'Use All Suggested Funds'

			case 'safe_alternative':
				return 'Use All Suggested Funds'

			case 'high_yield_alternative':
				return 'Use All Suggested Funds'

			case 'long_track_record_alternatives':
				return 'Use All Suggested Funds'

			case 'lowest_tracking_error_option':
				return 'Use All Suggested Funds'

			case 'high_sharpe_alternative':
				return 'Use All Suggested Funds'

			case 'user_criteria':
				return 'Use All Suggested Funds'

			default:
				return `Use All Suggested Funds`
		}
	}
	setPfEnhancementCategory = (e, category) => {
		const val = document.getElementById(e.target.id).checked;
		const { setPortfolioState, toggleChange } = this.props;
		addEventToAnalytics('Enhancer Replace All','Enhancer Replace All','ENHANCER_REPLACE_ALL',{},false);
		if (val) {
			setPortfolioState({ replacePortfolioToggle: true })
			if (toggleChange) {
				toggleChange(true);
			}
		} else {
			setPortfolioState({ replacePortfolioToggle: false, enhanceReplace: [], replaceAllPortfolioToggle: true })
			if (toggleChange) {
				toggleChange(false);
			}
		}

	}
  yearChange = (selected) => {
    if (selected) {
      const { portfolioEnhanceReplace, enhanceStats, currentStats } = this.props;
      if (portfolioEnhanceReplace.length > 0  && enhanceStats) {
        this.setState({
          currentLoadData: {
            ...currentStats,
            selectedSeries: currentStats[selected],
            sharpeRatio: currentStats[`sharpe_${selected}`],
            cumlValue: currentStats[`cuml_${selected}`]
          },
          enhanceLoadData: {
            ...enhanceStats,
            selectedSeries: enhanceStats[selected],
            sharpeRatio: enhanceStats[`sharpe_${selected}`],
            cumlValue: enhanceStats[`cuml_${selected}`]
          },
          selectedYear: selected,
        });
      } else {
        this.setState({
          currentLoadData: {
            ...currentStats,
            selectedSeries: currentStats[selected],
            sharpeRatio: currentStats[`sharpe_${selected}`],
            cumlValue: currentStats[`cuml_${selected}`]
          },
          enhanceLoadData: {},
          selectedYear: selected,
        });
      }
    }
  }

  render() {
    const {
      query,
      openDrawer,
      visible,
      setPfEnhancementContent,
      portfolioEnhanceReplace,
      enhanceStats,
      currentStats,
      mask,
      closable,
      enhancePortfolioVisible,
      enhanceTickerFilter,
	    portfolioBenchmarkData,
	    selectedPortfolioBenchmarkTicker,
	    handleUpDownDropDownChange,
	    replacePortfolioToggle
    } = this.props;

    const { currentLoadData, enhanceLoadData, selectedYear } = this.state;

    let loadData = {
      ...this.props,
      currentStats: currentLoadData,
      enhanceStats: enhanceLoadData,
    };

    let retDropDown = {
      pfRet1y: {
        year: '1 Year',
        disabled: false,
        col: 6,
      },
      pfRet3y: {
        year: '3 Years',
        disabled: false,
        col: 6,
      },
      pfRet5y: {
        year: '5 Years',
        disabled: false,
        col: 6,
      },
    };

    const tickerLength = portfolioEnhanceReplace.filter((item) => (item.ticker !== item.replace && enhanceTickerFilter.includes(item.ticker))).length

    const mDrawerHeader = (setPfEnhancementContent) => {
      const renderDesign = setPfEnhancementContent ? <span>Performance Summary </span> : <span>
        Performance Impact {(enhancePortfolioVisible === false) && (
          <span className="sub-head-label">(
            {tickerLength} {tickerLength > 0 && tickerLength == 1 ? 'ticker' : 'tickers'} replaced )
          </span>
        )}
	      <div className="pft-category-item">
		      {enhancePortfolioVisible === false && (
			      <div className="pft-category-item-wrapper">
				      <label className="switch switch_modal" id="enhance-switch" htmlFor="cat-alternative" style={{ left : '0px' }}>
					      <span className="check-label" id="check-label">&nbsp;{this.title()}</span>
					      <input disabled={enhancePortfolioVisible} type="checkbox" id="cat-alternative" value={this.title()} onClick={(e) => this.setPfEnhancementCategory(e, 'similar')} checked={replacePortfolioToggle}/>
					      <div className={`slider round ${enhancePortfolioVisible ? 'disabled' : ''}`}></div>
				      </label>
			      </div>
		      )}
	      </div>
      </span>
      return renderDesign
    }

    // if (enhanceLoadData && currentLoadData && Object.keys(currentLoadData).length > 0 &&  Object.keys(enhanceLoadData).length > 0 && tickerLength > 0) {
    if (enhanceLoadData && currentLoadData && Object.keys(currentLoadData).length > 0 &&  Object.keys(enhanceLoadData).length > 0) {
      const filteredPerformanceObject = getPerformanceSeriesAndYear({ currentSeries: loadData.currentStats, enhancedSeries: enhanceLoadData, query, retDropDown });
      // console.log(filteredPerformanceObject.currentSeries);
      // console.log(filteredPerformanceObject);
      if (filteredPerformanceObject.enhancedSeries && filteredPerformanceObject.enhancedSeries.pfRet1y) {
        loadData = {
          ...this.props,
          currentStats: {
            ...loadData.currentStats,
            ...filteredPerformanceObject.currentSeries,
            selectedSeries: filteredPerformanceObject.currentSeries[selectedYear],
          },
          enhanceStats: {
            ...loadData.enhanceStats,
            ...filteredPerformanceObject.enhancedSeries,
            selectedSeries: filteredPerformanceObject.enhancedSeries[selectedYear],
          },
        };
      }

      retDropDown = {
        pfRet1y: filteredPerformanceObject.pfRet1y,
        pfRet3y: filteredPerformanceObject.pfRet3y,
        pfRet5y: filteredPerformanceObject.pfRet5y,
      };

    }

    // console.log(loadData);

    const CARD_ORDER_OBJ = {
      Fees: () => (<FeesCard data={loadData} query={(query && query.includes('model')) ? MODEL_PORTFOOLIO_QUERY_MAP[query] : query}/>),
      Returns: () => (
        <ReturnCard
          defaultYears={DEFAULT_YEARS}
          data={loadData}
          selectedYear={selectedYear}
          dropDownData={retDropDown}
          yearChange={this.yearChange}
        />
      ),
      Risk: () => (
      	<RiskCard
          query = {this.props.query}
		      data={loadData}
		      portfolioBenchmarkData={portfolioBenchmarkData}
		      selectedPortfolioBenchmarkTicker={selectedPortfolioBenchmarkTicker}
		      handleUpDownDropDownChange={handleUpDownDropDownChange}/>
      ),
    };

    const renderPortfoliosCard = getOrderOfCard({ orderBy: query, CARD_ORDER_OBJ });

    return (
      <MDrawer
        id="summaryDrawerElement"
        className="summaryDrawerWrapper"
        onOpen={() => openDrawer(true)}
        onClose={() => openDrawer(false)}
        visible={visible}
        headerText={mDrawerHeader(!setPfEnhancementContent)}
        mask={mask}
        closable={closable}
        icon={<i class="fas fa-times"></i>}
      >
        <Row className="content-head-row" gutter={[16, 8]} style={{ margin: '0 0 5px 0', padding: '0 15px' }}>
          <Col className="col-header-wrap" span={12}></Col>
          <Col className="col-header-wrap" span={6}>Current</Col>
          <Col className="col-header-wrap" span={6}>{(portfolioEnhanceReplace.length > 0  && enhanceStats) ? 'Enhanced' : ''}</Col>
        </Row>

        {renderPortfoliosCard.map((kk) => {
          if (kk && typeof kk !== 'undefined' && typeof kk === 'function') {
            return kk();
          }
        })}
      </MDrawer>
    )
  }
}

export default PortfolioModal;

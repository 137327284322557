import React from 'react';
import { Button, Row, Col, Form, FormGroup, Label, Input, ModalFooter } from 'reactstrap';

import { DummyUser } from '../../DataSet';

export default class Login extends React.Component {
  render() {
    return (
      <div>
        <div className="modal-body-ssf p-3">
          <LoginForm {...this.props} />
        </div>
        <ModalFooter className="py-2">
          <Footer {...this.props} />
        </ModalFooter>
      </div>
    )
  }
}

class LoginForm extends React.Component {
  state = {email: '', password: ''}

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.email === DummyUser.n && this.state.password === DummyUser.p)
      this.props.loginHandler();
    else
      this.setState({ error: true });
  }

  handleChange = (event) => {
    this.setState({ error: false });
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  render() {
    const { tid } = this.props;
    return (
      <Row className="justify-content-center align-items-center" style={{minHeight: '20rem'}}>
        <Col md="4">
          <Form className="login-form" onSubmit={this.handleSubmit}>
            <FormGroup>
              <Label for="loginEmail">Email</Label>
              <Input type="text" name="email" id={"loginEmail-"+tid} placeholder="Email or Username"
                value={this.state.email} onChange={this.handleChange} />
            </FormGroup>
            <FormGroup>
              <Label for="loginPassword">Password</Label>
              <Input type="password" name="password" id={"loginPassword-"+tid} placeholder="Password"
                value={this.state.password} onChange={this.handleChange} />
            </FormGroup>
            <Button color='select' block className="mt-4">Sign-in</Button>
              <div className="text-danger text-center mt-3" style={{minHeight: '20px'}}>
                { this.state.error && <div><i className="far fa-fw fa-exclamation-circle mr-1"></i>Invalid Credentials!</div> }
              </div>
          </Form>
        </Col>
      </Row>
    )
  }
}

const Footer = (props) => {
  return (
    <React.Fragment>
      <div className="mr-auto">
        <Button color="link" className="text-ssf-blue-d2 h5 mb-0 p-0" style={{minHeight: '55px'}}
          onClick={props.clearAllHandler}>Clear All</Button>
      </div>
    </React.Fragment>
  )
}

import React, { useContext } from 'react';
import { Row, Col, Table } from 'reactstrap';

import HoldingsTable from '../Shared/HoldingsTable';

const HoldingsSection = (props) => {
  return (
    <div
    	className="holdings-section-wrapper flex-column"
    	style={{
        minWidth: props.minWidth ? props.minWidth : '',
        width: '100%',
      }}
    >
      {props.title && <div className="pf-heading">
        <h5>{props.title}</h5>
      </div>}
      <div className="holdings-wrapper h-100">
        <HoldingsTable 
          holdings={props.holdings}
          cutOff={props.cutOff}
          type={props.type}
        />
      </div>
    </div>
  )
};

export default HoldingsSection;
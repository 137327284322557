import React, { Component } from 'react';
import * as RegisterApi from 'apis/register';
import { CloseButton, CustomFormFeedback, Spinner } from 'shared-components';
import { employmentType ,countryList,countryList1, accountType, maritalStatus, occupation, tradingExperience, relations ,financialGoals ,liquidityTime ,uploadType, usStates, trustedContactCountry, TEARM_LINKS, SUPPORTED_FORMATS, POLITICAL_TOOLTIP_CONTENT } from '../Constant/FormData';
import "cleave.js/dist/addons/cleave-phone.i18n.js";
import Cleave from 'cleave.js/react';
import { PrimaryButton,  DatePicker, DropdownDatePicker } from 'shared-components/';
import { toLower } from 'lodash'
import { Link } from 'react-router-dom';
import { Steps } from 'antd'
import {
    Form,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Tooltip
  } from 'reactstrap';
import { Tagline, StepsBar } from './TradingForm';
import moment from 'moment';
import PhoneNumberInput from '../../../components/PhoneNumberInput';
import { uploadReviewImage } from 'apis/funds'
import { getCityUsingZip } from 'apis/auth'
import { Regex } from 'common/regex'

const apex_disclosures = '/assets/disclosures/apex_disclosures.pdf';
const del = "/assets/images/delete.svg";
const { Step } = Steps;
export class SecondaryTradingFormRHS3 extends Component {

    state = {
      account_type        : '',
      marital_status      : '',
      secondary_account_citizenship         : '',
      secondary_account_citizenshipInput    : '',
      secondary_account_citizenship_value    : '',
      secondary_account_phone_number        : '',
      secondary_account_country_code        : '',
      secondary_account_ssn                 : '',
      experience        : false,
      experience_value    : '',
      identity_proof      : '',
      address_proof       : '',
      citizenship_proof   : '',
      // primary_firstname   : this.props.formProps.values.primary_firstname,
      // primary_lastname    : this.props.formProps.values.primary_lastname,
      secondary_account_first_name : '',
      secondary_account_last_name  : '',
      secondary_is_employed      : '',
      secondary_employer_name         : '',
      secondary_occupation            : '',
      trusted_contact       : false,
      trusted_contact_firstname : '',
      trusted_contact_lastname : '',
      secondary_account_user_email : '',
      secondary_dob : '',
      trusted_contact_phone : '',
      trusted_select        : '',
      trusted_contact_country : '',
      secondary_account_country_value  :'',
      country_value         : '',
      secondary_account_state_value :'',
      secondary_account_city    :'',
      secondary_account_street_value  :'',
      secondary_account_zipcode :'',

      stateInput    : '',
      state_value    : '',
      secondary_account_country_code: '',
      phone_code_input: "+1",
      phoneCodeState: false,
      secondary_firm_name: '',
      zipCodeTimeOut: null,
      secondryZipNotValid: ''
    }

    componentDidMount() {
      document.getElementsByTagName('body')[0].style.paddingTop = 0;
      this.props.formProps.setTouched({});
    }

    toggleAccountType = () => {
      this.setState({
        account_type: !this.state.account_type,
      })
    }

    toggleAreYouEmp = () => {
      this.setState({
        secondary_is_employed: !this.state.secondary_is_employed,
      }, ()=>{
        if(this.state.secondary_is_employed){
          setTimeout(()=>{
            document.getElementById('secondary_is_employed_child0').focus()
          },100)
        }
      })
    }

    handleDropDownEmp = (e) => {
       this.props.formProps.setFieldValue('secondary_is_employed', e.target.value)
      //  this.props.formProps.setFieldValue('secondary_is_employed',true)
    }


    toggleMaritalStatus = () => {
      this.setState({
        marital_status: !this.state.marital_status,
      })
    }

    toggleCitizenshipStatus = () => {
      this.setState({
        citizenshipState: !this.state.citizenshipState,
      })
    }

    toggleExperienceStatus = () => {
      this.setState({
        experience: !this.state.experience,
      })
    }

    triggerInputFile(e, elem)  {
      e.preventDefault();
      document.getElementById(elem).click()
    }

    fileSelectHandler = (e, elem) => {
      this.props.formProps.setFieldValue(elem, e.target.files[0])
    }
    handleUpload = async ( data ) => {
      try{
        let _responseData = await RegisterApi.uploadDocs(data);
        //console.log("_responseData",_responseData)
      }catch{

      }

    }

    onCitizenshipChange = e => {
      const { formProps } = this.props;

      if (e.target.value == "") {
        formProps.setFieldValue('citizenship', e.target.value)
      }

      if (!this.state.countryState) {
        this.setState({
          citizenshipState: true,
        })
      }
      formProps.handleChange(e)
    }

    onCitizenshipSelect = (item) => {
      const { setFieldValue } = this.props.formProps
      setFieldValue('secondary_account_citizenship', item.name)
      setFieldValue('secondary_account_citizenship_value', item.code)
      setFieldValue('secondary_account_citizenshipInput', item.name)
    }
    toggleOccupationStatus = () => {
      this.setState({
        secondary_occupation: !this.state.secondary_occupation,
      })
    }

    handleTrustedSelect = (e) =>{
      const { setFieldValue, values } = this.props.formProps;
      let trusted = [...values.trusted_select];

      if(!e.target.checked && values.trusted_select.includes(e.target.name)){
        if(trusted.length > 1)
          trusted = values.trusted_select.filter(val => val !== e.target.name);
      }else{
        trusted.push(e.target.name);
      }
      setFieldValue('trusted_select' , trusted);
    }

    toggleCountryStatus = () => {
      this.setState({
        countryState: !this.state.countryState,
      })
    }
    onCountrySelect = (value, name) => {
      const { setFieldValue } = this.props.formProps;
      setFieldValue('secondary_account_country_value', value);
      setFieldValue('secondary_account_country_name', name);
    }
    onCountryChange = e => {
      const { formProps } = this.props
      if(!this.state.countryState) {
        this.setState({
          countryState: true,
        })
      }
      formProps.handleChange(e)
    }

    toggleStateStatus = () => {
      this.setState({
        statesState: !this.state.statesState,
      })
    }

    onStateChange = e => {
      const { formProps } = this.props;

      if (e.target.value == "") {
        formProps.setFieldValue('secondary_account_state_value', e.target.value)
      }

      if (!this.state.statesState) {
        this.setState({
          statesState: true,
        })
      }
      formProps.handleChange(e)
    }

    onStateSelect = (item) => {
      const { setFieldValue } = this.props.formProps
      setFieldValue('secondary_account_state_value', item.abbreviation)
      // setFieldValue('state_value', item.abbreviation)
      setFieldValue('secondary_account_stateInput', item.name)
    }

    handleZipCodeChangeSecondry = async (value) => {
      const { setFieldValue, values } = this.props.formProps
      if(values.secondary_account_country_value === 'USA'){
        this.setState({ secondryZipNotValid: '' })
        setFieldValue('secondary_account_state_value', '')
        setFieldValue('secondary_account_stateInput', '')
      }
      if(this.state.zipCodeTimeOut){
        clearTimeout(this.state.zipCodeTimeOut);
      }
      this.setState({ zipCodeTimeOut: null }, () => {
        if(value && value.match(Regex.zipCode)){
          if(values.secondary_account_country_value === 'USA'){
            let mtTime = setTimeout(async ()=>{
              try{
                let cityData = await getCityUsingZip(value.substring(0,5));
                if(cityData.data){
                  if(cityData.data.state){
                    setFieldValue('secondary_account_state_value', cityData.data.state)
                    // setFieldValue('state_value', cityData.data.state)
                    setFieldValue('secondary_account_stateInput', this.props.mapAbbrToState(cityData.data.state))
                  }
                  if(cityData.data.city){
                    setFieldValue('secondary_account_city', cityData.data.city)
                  }
                } else {
                  this.setState({ secondryZipNotValid: 'Zip Code is not valid' })
                }
              }catch (err){
                this.setState({ secondryZipNotValid: 'Zip Code is not valid' })
              } 
            }, 700)
            this.setState({ zipCodeTimeOut: mtTime })
          }
        }
      })
    }


    phoneCodeChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value
      });
    }

    render() {

      const { formProps, step, merger, hideStepBar } = this.props
      //console.log("1 state",this.state)
      //console.log("1 props",this.props)
      const {
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      } = formProps

      let totalSteps = 3;

      totalSteps = (this.props.isBranded) ? ++totalSteps : ++totalSteps

      return (
       <>
            {/* {!hideStepBar?<StepsBar step={(this.props.isBranded) ? 3 : 2} account_type={values.account_type} totalSteps={totalSteps} />:null} */}
            {/* {!hideStepBar?<div className="header-area">
              <div className="header-label sub">Specify Secondary Applicant Details</div>
            </div>:null} */}
            <div className="w-100 mt-3">
              <span className='heading_in_between'>Specify Secondary Applicant Details</span>
            </div>
            {/* <div className="element-box section-heading">
                <Label className="label" htmlFor="secondary">Secondary Applicant*</Label>
            </div> */}
            <Row className="form_input_container_gray border_radius_top">
                <Col>
                  <FormGroup className="element-box dropdown-element pos-rel">
                    <Label className={values.secondary_account_first_name ? "label new_label has_value" : "label new_label"} htmlFor="secondaryFirstName">First Name</Label>
                    <Input
                      type="text"
                      name="secondary_account_first_name"
                      id="secondaryFirstName"
                      value={values.secondary_account_first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={errors.secondary_account_first_name && touched.secondary_account_first_name ? 'is-invalid input-area' : 'input-area'}
                      // placeholder="Enter first name"
                      />
                    <CustomFormFeedback formProps={formProps} fieldName="secondary_account_first_name" />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="element-box dropdown-element pos-rel">
                    <Label className={values.secondary_account_last_name ? "label new_label has_value" : "label new_label"} htmlFor="secondaryLastName">Last Name</Label>
                      <Input
                        type="text"
                        name="secondary_account_last_name"
                        id="secondaryLastName"
                        value={values.secondary_account_last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.secondary_account_last_name && touched.secondary_account_last_name ? 'is-invalid input-area' : 'input-area'}
                        // placeholder="Enter last name"
                        />
                      <CustomFormFeedback formProps={formProps} fieldName="secondary_account_last_name" />
                  </FormGroup>
                </Col>
              </Row>
            <Row className="form_input_container_gray border_radius_bottom">
              <Col md={6} lg={6}>
                <FormGroup className="element-box dropdown-element pos-rel">
                  <Label className={values.secondary_account_phone_number ? "label new_label has_value" : "label new_label"} htmlFor="secondary_account_phone_number">Phone Number</Label>
                  <PhoneNumberInput
                    hideCode={true}
                    onChangePhoneCode={(val) => setFieldValue('secondary_account_country_code', val)}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    phoneCodeName="secondary_account_country_code"
                    phoneName="secondary_account_phone_number"
                    phoneId="secondary_account_phone_number"
                  />
                  {errors.secondary_account_phone_number && touched.secondary_account_phone_number && <div className="form-invalid-field">{errors['secondary_account_phone_number']}</div>}
                  {/* <CustomFormFeedback formProps={formProps} fieldName="secondary_account_phone_number" /> */}
                </FormGroup>
              </Col>

              <Col md={6} lg={6}>
                <FormGroup className="element-box dropdown-element pos-rel">
                  <Label className={values.secondary_account_ssn ? "label new_label has_value" : "label new_label"} htmlFor="secondary_account_ssn">Social Security Number</Label>

                    <Cleave
                      // placeholder="SSN"
                      options={{
                        delimiters: ['-', '-'],
                        numericOnly: true,
                        uppercase: true,
                        blocks: [3, 2, 4]
                      }}
                      value={this.props.formProps.values.secondary_account_ssn}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={errors.secondary_account_ssn && touched.secondary_account_ssn ? 'is-invalid input-area form-control' : 'input-area form-control'}
                      id="secondary_account_ssn"
                      name="secondary_account_ssn"
                    />

                  <CustomFormFeedback formProps={formProps} fieldName="secondary_account_ssn" />
                </FormGroup>
              </Col>
              <Col md={6} lg={6}>
                    <FormGroup className="element-box dropdown-element pos-rel">
                        <Label className={values.secondary_account_user_email ? "label new_label has_value" : "label new_label"} htmlFor="secondary_account_user_email">Email</Label>
                        <Input
                            type="text"
                            name="secondary_account_user_email"
                            id="secondary_account_user_email"
                            value={values.secondary_account_user_email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.secondary_account_user_email && touched.secondary_account_user_email ? 'is-invalid input-area' : 'input-area'}
                            // placeholder="Enter Email"
                        />
                        <CustomFormFeedback formProps={formProps} fieldName="secondary_account_user_email" />
                    </FormGroup>
                </Col>

                <Col md={6} lg={6}>
                <FormGroup className="element-box dropdown-element pos-rel">
                    <Label className={values.secondary_dob ? "label new_label has_value" : "label new_label"}  htmlFor="secondary_dob">Date of Birth</Label>
                    <DatePicker
                      id="secondary_dob"
                      selected={values.secondary_dob ? ((typeof values.secondary_dob.getMonth === 'function') ? values.secondary_dob : moment(values.secondary_dob)._d) : values.secondary_dob}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={new Date(moment().subtract(18, 'years').format("YYYY-MM-DD"))}
                      autoComplete="off"
                      onChange={(dateInstance, dateString) => {
                        if (dateInstance){
                          this.props.formProps.setFieldValue('secondary_dob', moment(dateInstance).format("YYYY-MM-DD") || null)
                        }
                      }}  
                      className={errors.secondary_dob && touched.secondary_dob ? 'is-invalid input-area form-control' : 'input-area form-control'}
                    />

                    {/* <DropdownDatePicker
                      bg={'#f7f7f7'}
                      id="secondary_dob"
                      value={
                        values.secondary_dob 
                          ? ((typeof values.secondary_dob.getMonth === 'function') 
                            ? values.secondary_dob 
                            : moment(values.secondary_dob)._d) 
                          : null
                      }
                      onDateChange={(dateInstance, dateString) => {
                        if (dateInstance) this.props.formProps.setFieldValue('secondary_dob', moment(dateInstance).format("YYYY-MM-DD") || null)
                      }}
                      maxDate={new Date(
                        moment()
                          .subtract(18, 'years')
                          .format('YYYY-MM-DD')
                      )}
                    /> */}

                    {errors.secondary_dob && touched.secondary_dob && <div className="form-invalid-field">{errors['secondary_dob']}</div>}
                </FormGroup>
              </Col>

              <Col md={6} lg={6}>
                  <FormGroup className="element-box pos-rel">
                    <Label className={values.secondary_account_zipcode ? "label new_label has_value" : "label new_label"} htmlFor="secondary_account_zipcode">Zip code</Label>
                    <Input
                      type="text"
                      name="secondary_account_zipcode"
                      id="secondary_account_zipcode"
                      value={values.secondary_account_zipcode}
                      onChange={(e)=>{
                        this.handleZipCodeChangeSecondry(e.target.value)
                        handleChange(e)
                      }}
                      onBlur={handleBlur}
                      className={errors.secondary_account_zipcode && touched.secondary_account_zipcode ? 'is-invalid input-area' : 'input-area'}
                      // placeholder="Enter the zip code"
                      />
                      {this.state.secondryZipNotValid ? <div className="form-invalid-field">{this.state.secondryZipNotValid}</div> : <CustomFormFeedback formProps={formProps} fieldName="secondary_account_zipcode" />}
                  </FormGroup>
              </Col>
              <Col md={6} lg={6}>
                <FormGroup className="element-box pos-rel">
                  <Label className={values.secondary_account_street_value ? "label new_label has_value" : "label new_label"} htmlFor="secondary_account_street_value">Street Address</Label>
                  <Input
                    type="text"
                    onKeyDown={(e)=>{
                      if(e.key === 'Tab'){
                        e.preventDefault();
                        document.getElementById('secondary_is_employed').click();
                        document.getElementById('secondary_is_employed').focus();
                      }
                    }}
                    name="secondary_account_street_value"
                    id="secondary_account_street_value"
                    value={values.secondary_account_street_value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.secondary_account_street_value && touched.secondary_account_street_value ? 'is-invalid input-area' : 'input-area'}
                    // placeholder="Enter Street"
                    />
                  <CustomFormFeedback formProps={formProps} fieldName="secondary_account_street_value" />
                </FormGroup>
              </Col>

              <Col md={6} lg={6}>
                  <FormGroup className="element-box dropdown-element pos-rel">
                    <Label className={values.secondary_is_employed ? "label new_label has_value" : "label new_label"} htmlFor="secondary_is_employed">Employment Type</Label>
                      <Dropdown
                        className={errors.secondary_is_employed && touched.secondary_is_employed ? 'is-invalid dropdown-wrapper' : 'dropdown-wrapper'}
                        isOpen={this.state.secondary_is_employed}
                        toggle={this.toggleAreYouEmp}
                        // onChange={this.handleAccountTypeChange}
                        >
                        <DropdownToggle
                          id="secondary_is_employed"
                          value={values.secondary_is_employed}
                          style={{ backgroundColor: 'transparent' }}
                          className={errors.secondary_is_employed && touched.secondary_is_employed ? 'is-invalid input-area' : 'input-area'}
                          caret
                          >
                          {values.secondary_is_employed || ''}
                        </DropdownToggle>
                        <DropdownMenu center>
                          {
                            employmentType.map((item, i) => {
                              return <DropdownItem id={'secondary_is_employed_child'+i} value={item} onClick={(e) => this.handleDropDownEmp(e)}>{item}</DropdownItem>
                            })
                          }

                        </DropdownMenu>
                      </Dropdown>
                      {errors.secondary_is_employed && touched.secondary_is_employed && <div className="form-invalid-field">{errors['secondary_is_employed']}</div>}
                  </FormGroup>
                </Col>

                {(values.secondary_is_employed == "Employed") && <Col md={6} lg={6}>
                { (values.secondary_is_employed == "Employed") && <FormGroup className="element-box dropdown-element pos-rel">
                <Label className={values.secondary_employer_name ? "label new_label has_value" : "label new_label"} htmlFor="secondary_employer_name">Name of the Employer</Label>
                <Input
                  type="text"
                  name="secondary_employer_name"
                  id="secondary_employer_name"
                  value={values.secondary_employer_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.secondary_employer_name && touched.secondary_employer_name ? 'is-invalid input-area' : 'input-area'}
                  // placeholder="Enter employer name"
                  />
                <CustomFormFeedback formProps={formProps} fieldName="secondary_employer_name" />
              </FormGroup>}
            </Col>}

            {(values.secondary_is_employed == "Employed") && <Col md={6} lg={6}>
              <FormGroup className="element-box dropdown-element pos-rel">
                <Label className={values.secondary_occupation ? "label new_label has_value" : "label new_label"} htmlFor="secondary_occupation">Occupation</Label>
                  <Input
                    type="text"
                    name="secondary_occupation"
                    id="secondary_occupation"
                    value={values.secondary_occupation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.secondary_occupation && touched.secondary_occupation ? 'is-invalid input-area' : 'input-area'}
                    // placeholder="Enter occupation"
                  />
                  {errors.secondary_occupation && touched.secondary_occupation && <div className="form-invalid-field">{errors['secondary_occupation']}</div>}
              </FormGroup>
            </Col>}

              {/* <Col>
                <FormGroup className="element-box dropdown-element pos-rel country-wrapper">
                  <Label className={values.secondary_account_citizenship ? "label new_label has_value" : "label new_label"} htmlFor="secondary_account_citizenship">Citizenship*</Label>
                  <Dropdown
                    className={errors.secondary_account_citizenship && touched.secondary_account_citizenship ? 'is-invalid dropdown-wrapper' : 'dropdown-wrapper'}
                    isOpen={this.state.citizenshipState}
                    toggle={this.toggleCitizenshipStatus}
                  >
                    <DropdownToggle
                      id="secondary_account_citizenship"
                      value={values.secondary_account_citizenship}
                      style={{ backgroundColor: 'transparent' }}
                      className={errors.secondary_account_citizenship && touched.secondary_account_citizenship ? 'is-invalid input-area' : 'input-area'}
                      caret
                    >
                      <input style={{padding:0,height:`20px`}} type="search" name="secondary_account_citizenshipInput" value={values.secondary_account_citizenshipInput} onChange={e => this.onCitizenshipChange(e)} className="typeahead-field" autocomplete="off"/>
                    </DropdownToggle>
                    <DropdownMenu>
                      {
                        countryList1.map((item) => {
                          if(!values.secondary_account_citizenshipInput || toLower(item.name).includes(toLower(values.secondary_account_citizenshipInput))) {
                            return <DropdownItem key={`secondaryCitizenship${item.name}`} value={item.name} onClick={(e) => this.onCitizenshipSelect(item)}>{item.name}</DropdownItem>
                          }
                          return null;
                        })
                      }

                    </DropdownMenu>
                  </Dropdown>

                  {errors.secondary_account_citizenship && touched.secondary_account_citizenship && <div className="form-invalid-field">{errors['secondary_account_citizenship']}</div>}
                </FormGroup>

              </Col> */}
            </Row>


            {/* <div className="header-area form_title">
              <div className="header-label sub">
                Address
              </div>
            </div> */}

              {/* <Row className="form_input_container_gray border_radius_top border_radius_bottom">
                <Col lg={{size:4}}>
                  <FormGroup className="element-box dropdown-element pos-rel country-wrapper">
                    <Label className={values.secondary_account_country_value || values.secondary_account_country_name ? "label new_label has_value" : "label new_label"} htmlFor="secondary_account_country_value">Country*</Label>
                    <Dropdown
                      className={errors.secondary_account_country_value && touched.secondary_account_country_value ? 'is-invalid dropdown-wrapper' : 'dropdown-wrapper'}
                      isOpen={this.state.countryState}
                      toggle={this.toggleCountryStatus}
                      >
                      <DropdownToggle
                        id="secondary_account_country_value"
                        value={values.secondary_account_country_value}
                        style={{ backgroundColor: 'transparent' }}
                        className={errors.secondary_account_country_value && touched.secondary_account_country_value ? 'is-invalid input-area' : 'input-area'}
                        caret
                        >
                        <input style={{padding:0,height:`20px`}} type="search" name="secondary_account_country_name" value={values.secondary_account_country_name} onChange={e => this.onCountryChange(e)} className="typeahead-field" autoComplete={`secondary_account_country_name`}/>
                      </DropdownToggle>
                      <DropdownMenu>
                        {
                          trustedContactCountry.map((item,i) => {
                            if(!values.secondary_account_country_name || toLower(item.name).includes(toLower(values.secondary_account_country_name))) {
                              return <DropdownItem key={i} value={item['alpha-3']} onClick={(e) => this.onCountrySelect(e.target.value, item.name)}>{item.name}</DropdownItem>
                            }
                            return null;
                          })
                        }

                    </DropdownMenu>
                  </Dropdown>
                  {errors.secondary_account_country_value && touched.secondary_account_country_value && <div className="form-invalid-field">{errors['secondary_account_country_value']}</div>}
                </FormGroup>
              </Col>
              
              <Col lg={{size:4}}>
                <FormGroup className="element-box pos-rel dropdown-element">
                  <Label className={values.secondary_account_state_value || values.secondary_account_stateInput ? "label new_label has_value" : "label new_label"} htmlFor="secondary_account_state_value">State*</Label>
                  {values.secondary_account_country_value !== "USA"   ?
                  <>
                  <Input
                    type="text"
                    name="secondary_account_state_value"
                    id="secondary_account_state_value"
                    value={values.secondary_account_state_value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.secondary_account_state_value && touched.secondary_account_state_value ? 'is-invalid input-area' : 'input-area'}
                    />
                   <CustomFormFeedback formProps={formProps} fieldName="secondary_account_state_value" />
                  </>
                  :
                    <Dropdown
                      className={errors.secondary_account_state_value && touched.secondary_account_state_value ? 'is-invalid dropdown-wrapper' : 'dropdown-wrapper'}
                      isOpen={this.state.statesState}
                      toggle={this.toggleStateStatus}
                    >
                      <DropdownToggle
                        id="secondary_account_state_value"
                        value={values.secondary_account_state_value}
                        style={{ backgroundColor: 'transparent' }}
                        className={errors.secondary_account_state_value && touched.secondary_account_state_value ? 'is-invalid input-area' : 'input-area'}
                        caret
                      >
                        <input style={{padding:0,height:`20px`}} type="search" name="secondary_account_stateInput" value={values.secondary_account_stateInput} onChange={e => this.onStateChange(e)} className="typeahead-field" autoComplete={`secondary_account_stateInput`}/>
                      </DropdownToggle>
                      <DropdownMenu>
                        {
                          usStates.map((item) => {
                            if(!values.secondary_account_stateInput || toLower(item.name).includes(toLower(values.secondary_account_stateInput))) {
                              return <DropdownItem value={item.name} onClick={(e) => this.onStateSelect(item)}>{item.name}</DropdownItem>
                            }
                            return null;
                          })
                        }

                      </DropdownMenu>
                    </Dropdown>}
                  {errors.secondary_account_state_value && touched.secondary_account_state_value && <div className="form-invalid-field">{errors['secondary_account_state_value']}</div>}
                </FormGroup>
              </Col>
              
              </Row> */}
              {/* <Row className="form_input_container_gray border_radius_bottom">
              <Col lg={{size:4}}>
                <FormGroup className="element-box pos-rel">
                  <Label className={values.secondary_account_city ? "label new_label has_value" : "label new_label"} htmlFor="secondary_account_city">City*</Label>
                  <Input
                    type="text"
                    name="secondary_account_city"
                    id="secondary_account_city"
                    value={values.secondary_account_city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.secondary_account_city && touched.secondary_account_city ? 'is-invalid input-area' : 'input-area'}
                    />
                  <CustomFormFeedback formProps={formProps} fieldName="secondary_account_city" />
                </FormGroup>
              </Col>
              
              
              </Row> */}

          {!merger?<FormGroup className="cta-wrapper" style={{ display: 'inline-block', width: '100%' }}>

          {!this.props.isSubmitStep ? <PrimaryButton
            type="button"
            onClick={() => this.props.saveData(this.props.formProps.values)}
            className="btn secondary-btn"
            style={{ width: '48%', float: 'left' }}
          >
            Save Progress
          </PrimaryButton>:null}

          <PrimaryButton
            type="submit"
            className="btn primary-btn"
            style={this.props.isSubmitStep ? { width: '100%' } : { width: '48%', float: 'right' }}
          >
            {this.props.isSubmitStep ? 'Create Account' : 'Save & Continue'}
          </PrimaryButton>
          {this.props.isSubmitStep ? <div className="start_finding">
            <a href="javascript:void(0)" onClick={()=> this.props.finishLaterCall()}>Start Finding Investments...I'll Finish This Later</a>
          </div>:<div className="start_finding" style={{ marginTop: 60 }}><a style={{ color: '#56a9e8' }} onClick={() => {
                    this.props.finishLaterCall()
                  }} href="javascript:void(0)">I'll Finish This Later</a></div>}
        </FormGroup>:null}
        {/* {!merger?<div className="acc-access-option-wrapper text-center">
            Create Magnifi Investment Account later?
            <span className="hyper-link" onClick={this.props.toggleSkipModal}>
              {' '}
              Skip
            </span>
          </div>:null} */}
          {/* {!merger?<div className="or-partition text-center mb-3">
            * Mandatory
          </div>:null} */}
          </>
      )
      }
  }

export class SecondaryTradingFormRHS4 extends Component {
    state = {
      tooltipOpen           : false,
      secondary_account_timeHorizon           :'',
      secondary_account_liquidityNeeds        :'',
      secondary_account_are_you_family_member : false,
      secondary_account_stock_name            : '',
      fields                :[-1],
      secondary_account_upload_type           :'',
      uploadError: { secondary_account_upload_3210: null, secondary_account_upload_407: null },
      loader: false,
      politicalTooltip: false
    }

    componentDidMount() {
      document.getElementsByTagName('body')[0].style.paddingTop = 0;
      let {profile} = this.props
      this.props.formProps.setFieldValue("self_role", profile.self_role)
      this.props.formProps.setTouched({});
    }


    toggleFinancialGoals = () => {
      this.setState({
        secondary_account_timeHorizon: !this.state.secondary_account_timeHorizon,
      })
    }

    toggleLiquidityTime = () => {
      this.setState({
        secondary_account_liquidityNeeds: !this.state.secondary_account_liquidityNeeds,
      })
    }

    // toggleRelationStatus = () => {
    //   this.setState({
    //     relation: !this.state.relation,
    //   })
    // }
    fileSelectHandler = async (e, elem) => {
      this.props.formProps.setFieldValue(elem+'_name', '');
      try{
        let errorObj = { secondary_account_upload_3210: null, secondary_account_upload_407: null };
        if (e.target.files && e.target.files[0]) {
          const files = e.target.files;
          let formData = new FormData()
          const { user } = this.props.profile
          let fileSize = files[0].size / 1024 / 1024;
          if (fileSize > 15) {
            errorObj[elem] = `The file ${files[0].name} is too big. (files must be under 15MB in size).`;
            return this.setState({ uploadError: errorObj },()=>{
              this.props.formProps.setFieldValue(elem, '');
            });
          }else if(!SUPPORTED_FORMATS.includes(files[0].type)){
            errorObj[elem] = "Only .png,.jpg,.jpeg,.gif, image format are allowed";
            return this.setState({ uploadError: errorObj },()=>{
              this.props.formProps.setFieldValue(elem, '');
            });
          } else {
            errorObj[elem] = null;
            this.setState({ uploadError: errorObj });
          }
          this.setState({ loader: true });
          formData.append('file', files[0])
          formData.append('tag', 'AFFILIATED_APPROVAL')
          formData.append('user', user || '')
          let res = await uploadReviewImage(formData)
          this.props.formProps.setFieldValue(elem, res.record_id)
          this.props.formProps.setFieldValue(elem+'_name', files[0].name)
        }
        this.setState({ loader: false });
      }catch(err){
        this.setState({ loader: false });
        this.props.formProps.setFieldValue(elem, '');
      }
    }

    // handleRelationChange = (e,index) =>{
    //   let arr = this.props.formProps.values.relation
    //   let val = e.target.value
    //   arr[index] = val
    //   this.props.formProps.setFieldValue("relation", arr)
    // }
    handlePolOrgChange = (e, index) =>{
      let arr = this.props.formProps.values.secondary_account_political_organization
      let val = e.target.value
      arr[index][e.target.name] = val
      this.props.formProps.setFieldValue("secondary_account_political_organization", arr)
    }
    handleAddFields = (e) =>{
      e.preventDefault();
      let arr = this.props.formProps.values.secondary_account_political_organization
      arr.push({political_organization : '', relation: ''});
      this.props.formProps.setFieldValue("secondary_account_political_organization", arr)
    }

    handleDelFields = (index) =>{
      let arrPol = this.props.formProps.values.secondary_account_political_organization
      arrPol.splice(index, 1);
      this.props.formProps.setFieldValue("secondary_account_political_organization", arrPol)
    }
    toggleSelectUpload = () => {
      this.setState({
        secondary_account_upload_type: !this.state.secondary_account_upload_type,
      }, ()=>{
        if(this.state.secondary_account_upload_type){
          setTimeout(()=>{
            document.getElementById('secondary_account_upload_type0').focus()
          },100)
        }
      })
    }

    handleRedirect = (type) => {
      window.open(apex_disclosures, '_blank');
    }

    render() {
      const { formProps, from , profile, step, merger, hideStepBar } = this.props
      const { fields } = this.state
      const {
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      } = formProps

      let totalSteps = 3;

      totalSteps = (this.props.isBranded) ? ++totalSteps : ++totalSteps

      return (
        <>
          <Spinner isFetching={this.state.loader} />
          {/* {!hideStepBar?<StepsBar step={(this.props.isBranded) ? 4 : 3} account_type={values.account_type} totalSteps={totalSteps} />:null} */}
          {/* {!hideStepBar?<div className="header-area">
            <div className="header-label sub">Specify Secondary Applicant Details</div>
          </div>:null} */}
            <div className="w-100 mt-3">
              <span className='heading_in_between'>Disclosures <span style={{ fontSize: 12, marginTop: 5 }}> &nbsp;(not common)</span></span>
            </div>
            {/* <FormGroup className="element-box pos-rel pl-0 pb-3" check
              style={{paddingBottom:'1.75rem'}}>
              <div className="toggle-switch-wrapper">
                <div className="toggle-content label">Number of Years planned to invest to achieve particular financial goal*
                <Dropdown
                      className={errors.secondary_account_timeHorizon && touched.secondary_account_timeHorizon ? 'is-invalid dropdown-wrapper' : 'dropdown-wrapper'}
                      isOpen={this.state.secondary_account_timeHorizon}
                      toggle={this.toggleFinancialGoals}
                      onChange={this.handleFinancialGoalChange}
                      >
                      <DropdownToggle
                        value={values.secondary_account_timeHorizon}
                        style={{ backgroundColor: '#fff' }}
                        className={errors.secondary_account_timeHorizon && touched.secondary_account_timeHorizon ? 'is-invalid input-area' : 'input-area'}
                        caret
                        >
                        {values.secondary_account_timeHorizon || 'Select'}
                      </DropdownToggle>
                      <DropdownMenu center>
                        {
                          financialGoals.map((item ,i) => {
                            return <DropdownItem key={`secondaryFinancialGoals${i}`} value={item.value} onClick={(e) => { setFieldValue('secondary_account_timeHorizon', e.target.value)}}>{item.name}</DropdownItem>
                          })
                        }

                      </DropdownMenu>
                    </Dropdown>
                </div>
              </div>
            </FormGroup>

            <FormGroup className="element-box pos-rel pl-0 pb-3" check style={{paddingBottom:'1.75rem'}}>
              <div className="toggle-switch-wrapper">
                <div className="toggle-content label">Ability to quickly and easily convert all or a portion of the account assets into cash without experiencing significant loss*
                <Dropdown
                      className={errors.secondary_account_liquidityNeeds && touched.secondary_account_liquidityNeeds ? 'is-invalid dropdown-wrapper' : 'dropdown-wrapper'}
                      isOpen={this.state.secondary_account_liquidityNeeds}
                      toggle={this.toggleLiquidityTime}
                      onChange={this.handleLiquidityTimeChange}
                      >
                      <DropdownToggle
                        value={values.secondary_account_liquidityNeeds}
                        style={{ backgroundColor: '#fff' }}
                        className={errors.secondary_account_liquidityNeeds && touched.secondary_account_liquidityNeeds ? 'is-invalid input-area' : 'input-area'}
                        caret
                        >
                        {values.secondary_account_liquidityNeeds || 'Select'}
                      </DropdownToggle>
                      <DropdownMenu center>
                        {
                          liquidityTime.map((item ,i) => {
                            return <DropdownItem key={`secondaryFinancialGoals${i}`} value={item} onClick={(e) => { setFieldValue('secondary_account_liquidityNeeds', e.target.value)}}>{item}</DropdownItem>
                          })
                        }

                      </DropdownMenu>
                    </Dropdown>
                </div>
              </div>
            </FormGroup> */}
            <Row className="form_input_container_gray border_radius_top">
              <Col xs={10}>
              <FormGroup className="element-box pos-rel pl-0 pb-0 pt-3" check style={{paddingBottom:'1.75rem'}}>
              <div className="toggle-switch-wrapper">
                <div className="toggle-content label">Are you or a family member a senior executive or 10% shareholder at a publicly traded company?
                </div>
              </div>
              <CustomFormFeedback
                formProps={formProps}
                fieldName="secondary_account_are_you_family_member"
                />
              {errors.secondary_account_are_you_family_member && touched.secondary_account_are_you_family_member && <div className="form-invalid-field">{errors['secondary_account_are_you_family_member']}</div>}
            </FormGroup>
              </Col>
              <Col xs={2}>
              <FormGroup className="element-box pos-rel pl-0 pb-0 pt-3" check style={{paddingBottom:'1.75rem'}}>
              <div className="toggle-switch-wrapper" style={{ width: '100%' }}>
                <div className="toggle-content label text-right" style={{ width: '100%' }}>
                <Label check className="register-form__term-cond-label toggle-switch m-0 ml-2 mt-1">
                  <Input
                    type="checkbox"
                    name="secondary_account_are_you_family_member"
                    id="secondary_account_are_you_family_member"
                    className={
                      errors.secondary_account_are_you_family_member && touched.secondary_account_are_you_family_member
                      ? 'register-form__checkbox is-invalid input-area toggle-switch-input'
                      : 'register-form__checkbox input-area toggle-switch-input'
                    }
                    checked={values.secondary_account_are_you_family_member}
                    onChange={handleChange}
                    />
                  <span className="slider round"></span>
                </Label>
                </div>
              </div>
            </FormGroup>
              </Col>
            </Row>

            {values.secondary_account_are_you_family_member && <Row className="form_input_container_gray">
              <Col>
                <FormGroup className="element-box dropdown-element pos-rel">
                  <Label className={values.secondary_account_stock_name ? "label new_label has_value" : "label new_label"} htmlFor="secondary_account_stock_name">Company Ticker</Label>
                  <Input
                    type="text"
                    name="secondary_account_stock_name"
                    id="secondary_account_stock_name"
                    value={values.secondary_account_stock_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.secondary_account_stock_name && touched.secondary_account_stock_name ? 'is-invalid input-area' : 'input-area'}
                    // placeholder="Enter company ticker"
                    />
                  <CustomFormFeedback formProps={formProps} fieldName="secondary_account_stock_name" />
                </FormGroup>
              </Col>
              <Col>

              </Col>
            </Row>}


            <Row className="form_input_container_gray">
              <Col xs={10}>
              <FormGroup className="element-box pos-rel pl-0 pb-0 pt-1" check style={{paddingBottom:'1.75rem'}}>
              <div className="toggle-switch-wrapper">
                <div className="toggle-content label">Are you affiliated or work for a member firm of a Stock Exchange or FINRA?</div>
              </div>
              <CustomFormFeedback
                formProps={formProps}
                fieldName="secondary_account_are_you_authorize"
                />
              {errors.secondary_account_are_you_authorize && touched.secondary_account_are_you_authorize && <div className="form-invalid-field">{errors['secondary_account_are_you_authorize']}</div>}
            </FormGroup>
              </Col>
              <Col xs={2}>
              <FormGroup className="element-box pos-rel pl-0 pb-0 pt-1" check style={{paddingBottom:'1.75rem'}}>
              <div className="toggle-switch-wrapper" style={{ width: '100%' }}>
                <div className="toggle-content label text-right" style={{ width: '100%' }}>
                  <Label check className="register-form__term-cond-label toggle-switch m-0 ml-2 mt-1">
                  <Input
                    type="checkbox"
                    onKeyDown={(e)=>{
                      if(e.key === 'Tab' && values.secondary_account_are_you_authorize){
                        e.preventDefault();
                        document.getElementById('secondary_account_upload_type').click();
                        document.getElementById('secondary_account_upload_type').focus();
                      }
                    }}
                    name="secondary_account_are_you_authorize"
                    id="secondary_account_are_you_authorize"
                    className={
                      errors.secondary_account_are_you_authorize && touched.secondary_account_are_you_authorize
                      ? 'register-form__checkbox is-invalid input-area toggle-switch-input'
                      : 'register-form__checkbox input-area toggle-switch-input'
                    }
                    checked={values.secondary_account_are_you_authorize}
                    onChange={handleChange}
                    />
                  <span className="slider round"></span>
                </Label></div>
              </div>
            </FormGroup>
              </Col>
            </Row>

            { values.secondary_account_are_you_authorize &&
              <Row className="form_input_container_gray">
                 <Col sm={6}>
                <FormGroup className="element-box dropdown-element pos-rel">
                  <Label className={values.secondary_account_upload_type ? "label new_label has_value" : "label new_label"} htmlFor="secondary_account_upload_type">Select Upload Type</Label>
                    <Dropdown
                      className={errors.secondary_account_upload_type && touched.secondary_account_upload_type ? 'is-invalid dropdown-wrapper' : 'dropdown-wrapper'}
                      isOpen={this.state.secondary_account_upload_type}
                      toggle={this.toggleSelectUpload}
                      >
                      <DropdownToggle
                        id="secondary_account_upload_type"
                        value={values.secondary_account_upload_type}
                        style={{ backgroundColor: 'transparent' }}
                        className={errors.secondary_account_upload_type && touched.secondary_account_upload_type ? 'is-invalid input-area' : 'input-area'}
                        caret
                        >
                        {values.secondary_account_upload_type || ''}
                      </DropdownToggle>
                      <DropdownMenu center>
                        {
                          uploadType.map((item, i) => {
                            return <DropdownItem id={'secondary_account_upload_type'+i} value={item} onClick={(e) => { setFieldValue('secondary_account_upload_type', e.target.value)}}>{item}</DropdownItem>
                          })
                        }
                      </DropdownMenu>
                    </Dropdown>

                    {errors.secondary_account_upload_type && touched.secondary_account_upload_type && <div className="form-invalid-field">{errors['secondary_account_upload_type']}</div>}
                </FormGroup>
              </Col>
              {values.secondary_account_upload_type == "Upload 3210" && <Col sm={6}>
                <FormGroup className="element-box dropdown-element pos-rel">
                  <div className={'upload_div upload_div_new'}>
                    {/* <p>3210 Letter*</p> */}
                    <div className={'d-flex align-items-center  label-container justify-content-between'}>
                      <p style={{fontWeight:400}}>{  values.secondary_account_upload_3210_name || '3210 Letter*'}</p>
                      <Label className={`label holoButton`} htmlFor="secondary_account_upload_3210">Upload</Label>
                    </div>
                    <Input
                      type="file"
                      name="secondary_account_upload_3210"
                      id="secondary_account_upload_3210"
                      accept="image/*"
                      onChange={(e) => this.fileSelectHandler(e, 'secondary_account_upload_3210')}
                      onBlur={handleBlur}
                      className={errors.secondary_account_upload_3210 && touched.secondary_account_upload_3210 ? 'is-invalid input-area' : 'input-area'}
                      placeholder="3210 Letter*"
                    />
                  </div>
                  <CustomFormFeedback formProps={formProps} fieldName="secondary_account_upload_3210" />
                  {(errors.secondary_account_upload_3210 && touched.secondary_account_upload_3210) || this.state.uploadError.secondary_account_upload_3210 ?
                      (<div className="form-invalid-field">
                        {this.state.uploadError.secondary_account_upload_3210 ? this.state.uploadError.secondary_account_upload_3210 : errors['secondary_account_upload_3210']}
                      </div>)
                    :null}
                </FormGroup>
              </Col>}
              { values.secondary_account_upload_type == "Upload 407" && <Col sm={6}>
                <FormGroup className="element-box dropdown-element pos-rel">
                  <div className={'upload_div upload_div_new'}>
                    {/* <p>407 Letter*</p> */}
                    <div className={'d-flex align-items-center label-container justify-content-between'}>
                      <p style={{fontWeight:400}}>{  values.secondary_account_upload_407_name || '407 Letter*'}</p>
                      <Label className={`label holoButton`} htmlFor="secondary_account_upload_407">Upload</Label>
                    </div>
                    <Input
                      type="file"
                      name="secondary_account_upload_407"
                      id="secondary_account_upload_407"
                      accept="image/*"
                      onChange={(e) => this.fileSelectHandler(e, 'secondary_account_upload_407')}
                      onBlur={handleBlur}
                      className={errors.secondary_account_upload_407 && touched.secondary_account_upload_407 ? 'is-invalid input-area' : 'input-area'}
                      placeholder="407 Letter*"
                    />
                  </div>

                  <CustomFormFeedback formProps={formProps} fieldName="secondary_account_upload_407" />
                  {(errors.secondary_account_upload_407 && touched.secondary_account_upload_407) || this.state.uploadError.secondary_account_upload_407 ?
                      (<div className="form-invalid-field">
                        {this.state.uploadError.secondary_account_upload_407 ? this.state.uploadError.secondary_account_upload_407 : errors['secondary_account_upload_407']}
                      </div>)
                    :null}
                </FormGroup>
              </Col>}
              </Row>
            }

            {values.secondary_account_are_you_authorize &&
              <Row className="form_input_container_gray">
                <Col sm={6}>
                  <FormGroup className="element-box dropdown-element pos-rel">
                    <Label className={values.secondary_firm_name ? "label new_label has_value" : "label new_label"} htmlFor="jointFirmName">Firm Name</Label>
                    <Input
                      type="text"
                      name="secondary_firm_name"
                      id="jointFirmName"
                      value={values.secondary_firm_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={errors.secondary_firm_name && touched.secondary_firm_name ? 'is-invalid input-area' : 'input-area'}
                      // placeholder="Enter firm name"
                      />
                    <CustomFormFeedback formProps={formProps} fieldName="secondary_firm_name" />
                  </FormGroup>
                </Col>
              </Row>
            }

            <Row className={`form_input_container_gray ${values.secondary_account_are_you_exposed ? '' : 'border_radius_bottom'}`}>
              <Col xs={10}>
              <FormGroup className="element-box pos-rel pl-0 pb-1 pt-1" check style={{paddingBottom:'1.75rem'}}>
              <div className="toggle-switch-wrapper">
                <div className="toggle-content label">Are you or a family member politically exposed? <span className="politicaly_exposed_span" id="politicaly_exposed_tooltip1">&#9432;</span>
                <Tooltip style={{ textAlign: 'left', fontSize: 10, width: 210, maxWidth: 220 }} placement="right-start" isOpen={this.state.politicalTooltip} target="politicaly_exposed_tooltip1" toggle={()=> this.setState({ politicalTooltip: !this.state.politicalTooltip })}>
                    {POLITICAL_TOOLTIP_CONTENT.map(co=>{
                      return (
                        <p>{co}</p>
                      )
                    })}
                </Tooltip>
                </div>
              </div>
              <CustomFormFeedback
                formProps={formProps}
                fieldName="secondary_account_are_you_exposed"
                />
              {errors.secondary_account_are_you_exposed && touched.secondary_account_are_you_exposed && <div className="form-invalid-field">{errors['secondary_account_are_you_exposed']}</div>}
            </FormGroup>
              </Col>
              <Col xs={2}>
              <FormGroup className="element-box pos-rel pl-0 pb-1 pt-1" check style={{paddingBottom:'1.75rem'}}>
              <div className="toggle-switch-wrapper" style={{ width: '100%' }}>
                <div className="toggle-content label text-right" style={{ width: '100%' }}>
                <Label check className="register-form__term-cond-label toggle-switch m-0 ml-2">
                  <Input
                    type="checkbox"
                    name="secondary_account_are_you_exposed"
                    id="secondary_account_are_you_exposed"
                    className={
                      errors.secondary_account_are_you_exposed && touched.secondary_account_are_you_exposed
                      ? 'register-form__checkbox is-invalid input-area toggle-switch-input'
                      : 'register-form__checkbox input-area toggle-switch-input'
                    }
                    checked={values.secondary_account_are_you_exposed}
                    onChange={handleChange}
                    />
                  <span className="slider round"></span>
                </Label>
                </div>
              </div>
            </FormGroup>
              </Col>
            </Row>

            {(values.secondary_account_are_you_exposed) &&
            values.secondary_account_political_organization.map((obj,i)=>

              <Row key={`secondaryRelation${i}`} className="form_input_container_gray">
              <Col>
                <FormGroup className="element-box dropdown-element pos-rel">
                  <Label className={obj.relation ? "label new_label has_value" : "label new_label"} htmlFor={`relation${i}`}>Name</Label>
                  <Input
                    type="text"
                    name={`relation`}
                    id={`relation${i}`}
                    value={obj.relation}
                    onChange={(e)=>this.handlePolOrgChange(e,i)}
                    onBlur={handleBlur}
                    className={errors.secondary_account_political_organization && touched.secondary_account_political_organization ? 'is-invalid input-area' : 'input-area'}
                    // placeholder="Name"
                    />
                 {errors.secondary_account_political_organization && touched.secondary_account_political_organization &&errors.secondary_account_political_organization[i] && <div className="form-invalid-field">{errors.secondary_account_political_organization[i].relation}</div>}
                </FormGroup>
              </Col>


              <Col>
                <FormGroup className="element-box dropdown-element pos-rel">
                  <Label className={obj.political_organization ? "label new_label has_value" : "label new_label"} htmlFor={`secondary_account_political_organization${i}`}>Political Organization</Label>
                  <Input
                    type="text"
                    name="political_organization"
                    id={`secondary_account_political_organization${i}`}
                    value={obj.political_organization}
                    onChange ={(e)=>this.handlePolOrgChange(e,i)}
                    onBlur={handleBlur}
                    className={errors.secondary_account_political_organization && touched.secondary_account_political_organization ? 'is-invalid input-area' : 'input-area'}
                    // placeholder="Organization name"
                    />
                  {errors.secondary_account_political_organization && touched.secondary_account_political_organization &&errors.secondary_account_political_organization[i] && <div className="form-invalid-field">{errors.secondary_account_political_organization[i].political_organization}</div>}
                </FormGroup>
                  {i > 0 && <img className="delbtn" src={del} width={20} onClick={()=>this.handleDelFields(i)}/>}
              </Col>
            </Row>
            )
            }
            {(values.secondary_account_are_you_exposed) &&
              <Row className="form_input_container_gray border_radius_bottom">
                <Col>
                  <button className="btnfield" onClick={(e)=>this.handleAddFields(e)}> add family member</button>
                </Col>
              </Row>
            }

        {!merger?<FormGroup className="cta-wrapper" style={{ display: 'inline-block', width: '100%' }}>

        {!this.props.isSubmitStep ? <PrimaryButton
            type="button"
            onClick={() => this.props.saveData(this.props.formProps.values)}
            className="btn secondary-btn"
            style={{ width: '48%', float: 'left' }}
          >
            Save Progress
          </PrimaryButton>:null}

          <PrimaryButton
            type="submit"
            className="btn primary-btn"
            style={this.props.isSubmitStep ? { width: '100%' } : { width: '48%', float: 'right' }}
          >
            {this.props.isSubmitStep ? 'Create Account' : 'Save & Continue'}
          </PrimaryButton>
          {this.props.isSubmitStep ? <div className="start_finding">
            <a href="javascript:void(0)" onClick={()=> { this.props.finishLaterCall()}}>Start Finding Investments...I'll Finish This Later</a>
          </div>:<div className="start_finding" style={{ marginTop: 60 }}><a style={{ color: '#56a9e8' }} onClick={() => {
                    this.props.finishLaterCall()
                  }} href="javascript:void(0)">I'll Finish This Later</a></div>}
          </FormGroup>:null}
          {/* {!merger?<div className="acc-access-option-wrapper text-center">
              Create Magnifi Investment Account later?
              <span className="hyper-link" onClick={this.props.toggleSkipModal}>
                {' '}
                Skip
              </span>
            </div>:null} */}
            {/* {!merger?<div className="or-partition text-center mb-3">
              * Mandatory
            </div>:null} */}
      </>
    )
  }
  }

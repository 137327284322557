import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import moment from 'moment';
// import { Modal, ModalHeader, ModalBody } from 'reactstrap';
// import { isEqual, find, isEmpty, get } from 'lodash';

import { subscriptionPlan } from 'actions/profile';
// import { selectorWithTimeFrameRequest, selectorWithTimeFrameSuccess } from 'actions/funds';
// import { refreshWeightState, fetchWeightsData, saveScoreAttrs, changeCalculator } from 'actions/weights';
// import { setupBroker, completeTradeItSetup } from 'actions/broker';
import { setSFMState } from 'actions/sfm';
import { premiumFeatureAccessRequest } from 'actions/profile';

// import {fetchProfile} from 'apis/profile';
// import { fetchImportedAccounts, fetchBrokerList } from 'apis/broker';
// import { createLoadingSelector } from 'selectors/loading-selector';

// import { DateRange, cn, ScoreAttrs, AdditionalScoreAttrs, calculatorOptions } from 'DataSet';
// import { addEventToAnalytics, nameToDateRange, monthToDate, getNest } from 'Utils';
// import { calcAttrStats, calcScores, calcScreenerScores } from 'Stats';
// import Emitter from 'realTimePrice/emitter';

// relative imports
import Main from './Main';
// import Tabs from './Tabs'

// import { dateRangeActiveObj } from './utils';

// const classNames = require('classnames');

class SFM extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  render() {
    return (
      <React.Fragment>
        <Main {...this.props} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
  premiumUser: auth.user.premiumUser || false,
})

const mapDispatchToProps = {
  setSFMState,
  subscriptionPlan,
  premiumFeatureAccessRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SFM)

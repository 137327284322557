import React from 'react';
import { SectorColors } from 'data/Colors';
import { SimplePieChart } from 'components/Charts';
import NotApplicable from 'components/NotApplicable';


export const HoldingsDiversificationChart = ({ card, colors, name, ...props }) => {
  if (!card.diverseData) return <NotApplicable />;
  if (!card.diverseData.top_20_prct_coverage) return <NotApplicable />;
  var mult = name === 'Holdings Diversification' ? 100 : 1;
  let top_prct = card.diverseData.top_20_prct_coverage * mult;
  let remaining = 100 - top_prct;
  let centerDisplayContent = card.diverseData.n_hldgs;

  const data = [
    {
      name: 'Percentage held by lower 80% positions',
      value: parseFloat(remaining.toFixed(1)),
      color: SectorColors[1 % SectorColors.length]
    },
    {
      name: 'Percentage held by top 20% positions',
      value: parseFloat(top_prct.toFixed(1)),
      color: '#ddd'
    }

  ];

  return (
    <SimplePieChart {...props} data={data} centerDisplay={centerDisplayContent} chartName={name} />
  )
}

export const RegionDiversificationChart = ({ card, colors, name, ...props }) => {
  if (!card.diverseData) return <NotApplicable />;
  if (!card.diverseData.top_20_prct_reg_coverage) return <NotApplicable />;
  var mult = name === 'Region Diversification' ? 100 : 1;
  let top_prct = card.diverseData.top_20_prct_reg_coverage * mult;
  let remaining = 100 - top_prct;
  let centerDisplayContent = card.diverseData.n_countries;

  const data = [
    {
      name: 'Percentage held by lower 80% regions',
      value: parseFloat(remaining.toFixed(1)),
      color: SectorColors[1 % SectorColors.length]
    },
    {
      name: 'Percentage held by top 20% regions',
      value: parseFloat(top_prct.toFixed(1)),
      color: '#ddd'
    }

  ];

  return (
    <SimplePieChart {...props} data={data} centerDisplay={centerDisplayContent} chartName={name} />
  )
}

export const SectorDiversificationChart = ({ card, colors, name, ...props }) => {
  if (!card.diverseData) return <NotApplicable />;
  if (!card.diverseData.top_20_prct_sec_coverage) return <NotApplicable />;
  var mult = name === 'Sector Diversification' ? 100 : 1;
  let top_prct = card.diverseData.top_20_prct_sec_coverage * mult;
  let remaining = 100 - top_prct;
  let centerDisplayContent = card.diverseData.n_sectors;

  const data = [
    {
      name: 'Percentage held by lower 80% sectors',
      value: parseFloat(remaining.toFixed(1)),
      color: SectorColors[1 % SectorColors.length]
    },
    {
      name: 'Percentage held by top 20% sectors',
      value: parseFloat(top_prct.toFixed(1)),
      color: '#ddd'
    }

  ];

  return (
    <SimplePieChart {...props} data={data} centerDisplay={centerDisplayContent} chartName={name} />
  )
}

export default {
  RegionDiversificationChart,
  SectorDiversificationChart,
  HoldingsDiversificationChart,
};

import React from 'react';

import { Row, Col } from 'reactstrap';
import moment from 'moment';

import { numFmt } from 'Utils'
import { MoreButton, KeyStats3, TruncatedNameSponsor, withScrollBar, NoData } from './Common'

const classNames = require('classnames');

export const Transactions = React.memo(({ title, loadMoreAction, items, expand = false }) => {
  const more = items.length > 3;
  const _items = items.slice(0, expand ? 30 : 3);

  return (
    <React.Fragment>
      <MoreButton loadMoreAction={loadMoreAction} layout="transactions" more={more} expand={expand} />
      <h5 className="mb-1 text-heading font-one">{title}</h5>
      { expand
        ? <TransactionsItemListWithScrollBar items={_items} />
        : <TransactionsItemList items={_items} />
      }
    </React.Fragment>
  )
})

const TransactionsItemList = ({ items }) => {
  const count = items.length;
  if (count <= 0) return <NoData height='225px' />;

  return (
    <div style={{minHeight: '225px'}}>
      { items.map((e, i) => <TransactionsItem key={e.symbol+'-'+i} item={e} last={i === count-1} /> ) }
    </div>
  )
}
const TransactionsItemListWithScrollBar = withScrollBar(TransactionsItemList)

const TransactionsItem = ({ item, last }) => {
  const date = moment(item.transaction_date).format('MM/DD/YYYY');
  const unit = `${numFmt(item.quantity, 0)} ${item.quantity > 1 ? 'Units' : 'Unit'}`;

  if (date !== 'Invalid date') {
    return (
      <div className={classNames({"py-3 border-bottom": !last, "pt-3": last})}>
        <Row>
          <Col xs="6" sm="7">
            <TruncatedNameSponsor name={item.short_name} sponsor={item.sponsor} lines={1} />
          </Col>
          <Col xs="6" sm="5" className="d-flex justify-content-end align-items-center">
            <div className="text-gray-8 fs-11 text-right">
              <div>{unit} @ ${ item._portfolioFund === true ? numFmt(parseInt(item.purchase_price)) : numFmt(item.price)}</div>
              <div>{date}</div>
            </div>
          </Col>
        </Row>
      </div>
    )
  } else return ''


}

import React, { Component, Fragment } from 'react';

import { Button, Menu, Radio } from 'antd';
import { PrimaryButton } from 'shared-components';

const FULL_DASH_ARRAY = 283;
const WARNING_THRESHOLD = 10;
const ALERT_THRESHOLD = 5;

const COLOR_CODES = {
	info: {
		color: "green"
	},
	warning: {
		color: "orange",
		threshold: WARNING_THRESHOLD
	},
	alert: {
		color: "red",
		threshold: ALERT_THRESHOLD
	}
};


let timePassed = 0;
let timeLeft = 0;
let timerInterval = null;
let remainingPathColor = COLOR_CODES.info.color;

class CountDownTimer extends Component {
  constructor(props) {
    super(props)
    this.state = {
			TIME_LIMIT: props.timeLimit || 11,
    }
		timerInterval = this.state.TIME_LIMIT;
  }

	componentDidMount() {
		const { renderOnlyText } = this.props;
		if (renderOnlyText) {
			document.getElementById("count-down-timer").innerHTML = `${this.formatTime(
				timeLeft
			)}`;
		} else {
			document.getElementById("count-down-timer").innerHTML = `
				<div class="base-timer">
					<svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
						<g class="base-timer__circle">
							<circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
							<path
								id="base-timer-path-remaining"
								stroke-dasharray="283"
								class="base-timer__path-remaining ${remainingPathColor}"
								d="
									M 50, 50
									m -45, 0
									a 45,45 0 1,0 90,0
									a 45,45 0 1,0 -90,0
								"
							></path>
						</g>
					</svg>
					<span id="base-timer-label" class="base-timer__label">${this.formatTime(
						timeLeft
					)}</span>
				</div>
			`;
		}
		this.startTimer();
	}

	onTimesUp = () => {
		if (timerInterval) {
			clearInterval(timerInterval);
		}
		const { onTimesUp } = this.props;
		if (onTimesUp) {
			onTimesUp();
		}
	}

	startTimer = () => {
		const { renderOnlyText } = this.props;
		timerInterval = setInterval(() => {
			timePassed = timePassed += 1;
			timeLeft = this.state.TIME_LIMIT - timePassed;
			if (document.getElementById("count-down-timer")) {
				if (renderOnlyText) {
					document.getElementById("count-down-timer").innerHTML = `${this.formatTime(
						timeLeft
					)}`;
				} else {
					document.getElementById("base-timer-label").innerHTML = this.formatTime(
						timeLeft
					);
				}
			}

			this.setCircleDasharray();
			this.setRemainingPathColor(timeLeft);

			if (timeLeft === 0) {
				this.onTimesUp();
			}
		}, 1000);
	}

	formatTime = (time) => {
		const minutes = Math.floor(time / 60);
		let seconds = time % 60;

		if (seconds < 10) {
			seconds = `0${seconds}`;
		}

		if (minutes > 0) {
		 return `${minutes}:${seconds}`;
		} else {
		 return `${seconds}`;
		}

	}

	setRemainingPathColor = (timeLeft) => {
		const { alert, warning, info } = COLOR_CODES;
		if (document.getElementById("base-timer-path-remaining")) {
			if (timeLeft <= alert.threshold) {
				document
					.getElementById("base-timer-path-remaining")
					.classList.remove(warning.color);
				document
					.getElementById("base-timer-path-remaining")
					.classList.add(alert.color);
			} else if (timeLeft <= warning.threshold) {
				document
					.getElementById("base-timer-path-remaining")
					.classList.remove(info.color);
				document
					.getElementById("base-timer-path-remaining")
					.classList.add(warning.color);
			}
		}
	}

	calculateTimeFraction = () => {
		const rawTimeFraction = timeLeft / this.state.TIME_LIMIT;
		return rawTimeFraction - (1 / this.state.TIME_LIMIT) * (1 - rawTimeFraction);
	}

	setCircleDasharray = () => {
		const circleDasharray = `${(
			this.calculateTimeFraction() * FULL_DASH_ARRAY
		).toFixed(0)} 283`;
		if (document.getElementById("base-timer-path-remaining")) {
			document
				.getElementById("base-timer-path-remaining")
				.setAttribute("stroke-dasharray", circleDasharray);
		}
	}

  render() {
		return (
			<Fragment>
				<span id="count-down-timer"></span>
			</Fragment>
		)
  }
}

export default CountDownTimer;

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedNumber } from 'react-intl'
import { FieldArray } from 'formik'
import classNames from 'classnames'
import PerfectScrollbar from 'perfect-scrollbar'

import {
  Input,
  Row,
  Col,
  FormGroup,
  Form,
  Alert,
  Label,
  Table
} from 'reactstrap'

import {
  CloseButton,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CustomFormFeedback,
  Spinner,
  OSCheckbox,
  ToggleSwitch
} from 'shared-components'

function BrokerAccountListItem({
  accounts,
  account,
  formProps,
  defaultSelectedAccount,
  onSelectAccount,
  onSetDefaultAccount
}) {
  const { values } = formProps
  const accountNameStyle = classNames({
    'account-list__name': true,
    'account-list__name--disabled': account.authorized
  })
  return (
    <li className="account-list__item">
      <div className="account-list__item-wrapper">
        <div className="account-list__checkbox-wrapper">
          <FieldArray
            name="accountIds"
            render={arrayHelpers => (
              <FormGroup check>
                <OSCheckbox
                  id={account.account_number}
                  type="checkbox"
                  className="account-list__checkbox"
                  value={account.account_number}
                  disabled={account.authorized}
                  checked={values.accountIds.includes(account.account_number)}
                  onChange={e => {
                    if (e.target.checked)
                      arrayHelpers.push(account.account_number)
                    else {
                      const idx = values.accountIds.indexOf(
                        account.account_number
                      )
                      arrayHelpers.remove(idx)
                    }
                    onSelectAccount(e, account, values)
                  }}
                />1

                <Label
                  check
                  htmlFor={account.account_number}
                  className={accountNameStyle}
                >
                  {account.account_description || 'Account Name'}
                </Label>
              </FormGroup>
            )}
          />

          <FormGroup check>
            <div className="account-list__table-wrapper">
              <Table responsive borderless className="account-info">
                <thead className="account-info__head">
                  <tr className="account-info__head-row">
                    <th className="account-info__head-cell">Account id</th>
                    <th className="account-info__head-cell">
                      Net Account Value
                    </th>
                    <th className="account-info__head-cell">
                      Registration Type
                    </th>
                  </tr>
                </thead>
                <tbody className="account-info__body">
                  <tr className="account-info__body-row">
                    <td className="account-info__body-cell">
                      {account.account_number}
                    </td>
                    <td className="account-info__body-cell">
                      <FormattedNumber
                        value={account.net_account_value}
                        style="currency"
                        currency="USD"
                        useGrouping={false}
                      />
                    </td>
                    <td className="account-info__body-cell">
                      {account.registration_type}
                    </td>
                    {/*<td className="account-info__body-cell">
                                          {/* User has to select/imported account then only allow to set as default */}
                                          {/*<FieldArray
                                            name="defaultAccountsId"
                                            render={arrayHelpers => (
                                              <ToggleSwitch
                                                name="uniqueBrokerName"
                                                type="radio"
                                                disabled={
                                                  !(
                                                    account.authorized ||
                                                    values.accountIds.includes(
                                                      account.account_number
                                                    )
                                                  )
                                                }
                                                selectedValue={
                                                  defaultSelectedAccount
                                                    ? defaultSelectedAccount.account_number
                                                    : ''
                                                }
                                                value={account.account_number}
                                                onChange={e => {
                                                  if (e.target.checked) {
                                                    values.defaultAccountsId.splice(
                                                      0,
                                                      values.defaultAccountsId
                                                    )
                                                    arrayHelpers.push(account.account_number)
                                                  } else {
                                                    values.defaultAccountsId.splice(
                                                      0,
                                                      values.defaultAccountsId
                                                    )
                                                  }
                                                  onSetDefaultAccount(e, account, accounts)
                                                }}
                                                className="text-center"
                                              />
                                            )}
                                          />
                                        </td>*/}
                  </tr>
                </tbody>
              </Table>
            </div>
          </FormGroup>
        </div>
      </div>
    </li>
  )
}

export class BrokerAccountList extends Component {
  componentDidMount = () => {
    this.ps = new PerfectScrollbar('.account-list-wrapper')
  }

  componentWillUnmount = () => {
    this.ps.destroy()
    this.ps = null
  }

  render() {
    const {
      accounts = [],
      formProps,
      defaultSelectedAccount,
      onSelectAccount,
      onSetDefaultAccount
    } = this.props
    return (
      <ul className="account-list">
        <div className="account-list-wrapper">
          {accounts.map(a => (
            <BrokerAccountListItem
              key={a.account_number}
              accounts={accounts}
              account={a}
              formProps={formProps}
              defaultSelectedAccount={defaultSelectedAccount}
              onSelectAccount={onSelectAccount}
              onSetDefaultAccount={onSetDefaultAccount}
            />
          ))}
        </div>
      </ul>
    )
  }
}

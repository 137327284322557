import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Form,
  Modal,
  ButtonGroup,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { message, InputNumber } from 'antd';
import { CSVLink } from "react-csv";
import _ from 'lodash';

import { createLoadingSelector } from 'selectors/loading-selector';
import { downloadSearchedResultsRequest } from 'actions/discovery';
import { PrimaryButton } from 'shared-components';

import { Loader } from '../Loader';
import { convertToCapitalCase } from 'Utils';
import { cn } from 'DataSet';

const MAX_DOWNLOAD_LIMIT = 100, MIN_DOWNLOAD_LIMIT = 1;

class DownloadExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadExcel: true,
      selectedVar: [{ id: 'ticker', name: "Ticker", category: "selected" }],
      fundsData: '',
      selectedTitle: '',
      variable: [
        { id: 'ticker', name: "Ticker", category: "selected" },
        { id: 'vehicle', name: "Vehicle", category: "options" },
        { id: 'short_name', name: "Short Name", category: "options" },
        { id: 'category', name: "Category", category: "options" },
        { id: 'price', name: "Price", category: "options" },
        { id: 'fees', name: "Fees", category: "options" },
        { id: 'annualizedReturn', name: "Annualized Return", category: "options" },
        { id: 'cummulativeReturn', name: "Cumulative Return", category: "options" },
        { id: 'drawdownRisk', name: "Drawdown Risk", category: "options" },
        { id: 'annualizedRisk', name: "Annualized Risk", category: "options" },
        // {id:'regions',name:"Regions(Top 3)",category:"options"},
        // { id: 'sector', name: "Sectors", category: "options" },
        { id: 'assets', name: "Assets", category: "options" },
        { id: 'volume', name: "Volume", category: "options" },
        { id: 'topRegions', name: "Regions (Top 3)", category: "options" },
        { id: 'topSectors', name: "Sectors (Top 3)", category: "options" },
        { id: 'topHoldings',name: "Holdings (Top 3)",category: "options" },
        { id: 'sharpe_ratio', name: "Sharpe Ratio", category: "options" },
        { id: 'info_ratio', name: "Information Ratio", category: "options" },
        { id: 'tracking_error', name: "Tracking Error", category: "options" },
        { id: 'assetTypeData', name: "Asset Allocation", category: "options" },
        // { id: 'themes', name: "Themes", category: "options" },

      ],
      Headers: [
        { label: "Ticker", key: "ticker" },
        { label: "Vehicle", key: "vehicle" },
        { label: "Short Name", key: "short_name" },
        { label: "Name", key: "name" },
        { label: "Category", key: "category" },
        { label: "Price", key: "price" },
        { label: "Fees", key: "fees" },
        { label: "Annualized Return", key: "annualizedReturn" },
        { label: "Cumulative Return", key: "cummulativeReturn" },
        { label: "Annualized Risk", key: "annualizedRisk" },
        { label: "Drawdown Risk", key: "drawdownRisk" },
        { label: "Volume", key: "volume" },
        { label: "Assets", key: "assets" },
        { label: "Sharpe Ratio", key: "sharpe_ratio" },
        { label: "Information Ratio", key: "info_ratio" },
        { label: "Tracking Error", key: "tracking_error" },
        { label: "Asset Allocation", key: "assetTypeData" },
        // { label: "Themes", key: "themes" },
        { key: 'bonds', label: "Bonds" },
        { key: 'cash', label: "Cash" },
        { key: 'commodities', label: "Commodities" },
        { key: 'currencies', label: "Currencies" },
        { key: 'derivatives', label: "Derivatives" },
        { key: 'equities', label: "Equities" },
        { key: 'other', label: "Other" },
      ],
      downloadCSVLimit: 10,
      limitErrorMsg: '',
      limitError: false,
    }
    this.csvLink = React.createRef();
  }

  onDragStart = (ev, val) => {
    this.setState({
      selectedVar: _.unionBy(this.state.selectedVar, [val], 'id'),
    });
    ev.dataTransfer.setData("id", val.name);
  }

  onDragOver = (ev) => {
    ev.preventDefault();
  }

  onDrop = (ev, cat) => {
    let id = ev.dataTransfer.getData("id");
    const currentElement = ev.target;
    const isDraggableElemet = _.toArray(currentElement.classList).indexOf('draggable') !== -1;
    const currentElIndex = _.toArray(currentElement.parentElement.children).indexOf(currentElement);
    const selectedVariable = [];
    const unSelectedVariable = [];
    this.state.variable.forEach(item => {
      if(item.category === 'selected') {
        selectedVariable.push(item)
      }

      if(item.category !== 'selected') {
        unSelectedVariable.push(item)
      }
    })
    const clonedVariable = [...selectedVariable, ...unSelectedVariable];
    const indexOfVariable = clonedVariable.findIndex((task) => task.name == id && task.name != 'Ticker') ;
    const splicedItem = indexOfVariable !== -1 ? clonedVariable.splice(indexOfVariable, 1)[0]: [];

    let newCloneVariable = [...clonedVariable]
    if(indexOfVariable !== -1) {
      if(isDraggableElemet && currentElIndex > 0) {
        newCloneVariable = [
          ...newCloneVariable.slice(0, currentElIndex),
          {
            ...splicedItem,
            category: cat
          },
          ...newCloneVariable.slice(currentElIndex, newCloneVariable.length)
        ];
      } else {
        newCloneVariable = [...clonedVariable, {
          ...splicedItem,
          category: cat
        }]
      }
     }

    this.setState({
      ...this.state,
      selectedVar: this.state.selectedVar.map(selectedVariable => ({
        ...selectedVariable,
        category: cat
      })),
      variable: newCloneVariable,
    });
  }

  downloadCardTradeListExcel = () => {
    const { query, discovery, universe, downloadSearchedResultsRequest } = this.props;
    const { downloadCSVLimit } = this.state;
    if (downloadCSVLimit <= discovery.data.length) {
      const slicedData = discovery.data.slice(0, downloadCSVLimit);
      this.callback(slicedData);
    } else {
      if (downloadSearchedResultsRequest) {
        downloadSearchedResultsRequest({
          query,
          universe: universe.join(),
          requestCount: downloadCSVLimit,
        }, (response) => {
          // console.log(response);
          // const { searchedResultsForCSV } = this.props;
          if (response && typeof response.funds !== 'undefined' && response.funds.length > 0) {
            // console.log(response.funds);
            try {
              this.callback(response.funds);
            } catch (e) {
              // message.error('Failed to process this request.');
              console.log(e);
            }
          } else {
            console.log('========>>>> COMPUTRED SEARCH API RESPONSE ERROR <<<<========');
            this.callback(discovery.data);
          }
        });
      }
    }
  }

  callback = (funds) => {
    let sec = this.state.selectedVar.filter(x => (x.id === 'topSectors' && x.category === 'selected'));
    let topHold = this.state.selectedVar.filter(x => (x.id === 'topHoldings' && x.category === 'selected'));
    let topReg = this.state.selectedVar.filter(x => (x.id === 'topRegions' && x.category === 'selected'));

    let sectors = [
      { label: "Sector #1", key: "sector1" },
      { label: "Sector #1 Percentage", key: "sector1_wt" },
      { label: "Sector #2", key: "sector2" },
      { label: "Sector #2 Percentage", key: "sector2_wt" },
      { label: "Sector #3", key: "sector3" },
      { label: "Sector #3 Percentage", key: "sector3_wt" },
    ];

    let topHoldings = [
      { label: "Holding #1", key: "hold1" },
      { label: "Holding #1 Percentage", key: "hold1_wt" },
      { label: "Holding #2", key: "hold2" },
      { label: "Holding #2 Percentage", key: "hold2_wt" },
      { label: "Holding #3", key: "hold3" },
      { label: "Holding #3 Percentage", key: "hold3_wt" },
    ];

    let topRegions = [
      { label: "Regions #1", key: "reg1" },
      { label: "Regions #1 Percentage", key: "reg1_wt" },
      { label: "Regions #2", key: "reg2" },
      { label: "Regions #2 Percentage", key: "reg2_wt" },
      { label: "Regions #3", key: "reg3" },
      { label: "Regions #3 Percentage", key: "reg3_wt" },
    ];

    let headers = [...this.state.Headers];
    if(sec.length > 0) headers = [...headers, ...sectors]
    if(topHold.length > 0) headers = [...headers, ...topHoldings]
    if(topReg.length > 0) headers = [...headers, ...topRegions]

    var cardData = funds.map((i) => {
      var sectors = [
        {name: "Communications", value: i.communication_services},
        {name: "Consumer Discretionary", value: i.consumer_discretionary},
        {name: "Consumer Staples", value: i.consumer_staples},
        {name: "Energy", value: i.energy},
        {name: "Financial", value: i.financials},
        {name: "Health Care", value: i.health_care},
        {name: "Industrial", value: i.industrials},
        {name: "Materials", value: i.materials},
        {name: "Real Estate", value: i.real_estate},
        {name: "Utilities", value: i.utilities},
        {name: "Other Sectors", value: i.others_non_classified},
      ];
      sectors = _.sortBy(sectors, ['value']).reverse().splice(0,3);
      var regions = [];
      i.region.forEach(elem => {
        if(elem.sub){
          regions = [...regions, ...elem.sub]
        }
      });
      regions = _.sortBy(regions, ['v']).reverse().splice(0,3);
      // console.log(i);
      const assetAlloc = i.asset_alloc || i.assetTypeData;
      var info = {
        "ticker": i.ticker_short ? i.ticker_short : i.ticker,
        "vehicle": i.type,
        "short_name": i.short_name === 0 ? "" : i.short_name,
        "name": i.name,
        "category": i.category,
        "price": i.nav,
        "fees": i.fees ? i.fees.toFixed(2) + "%" : '--',
        "annualizedReturn": (i._return_v !== null && i._return_v !== undefined && typeof i._return_v !== 'undefined') ? i._return_v.toFixed(2) + "%" : (i.Fee_Risk_Return && i.Fee_Risk_Return['Annualized 3 year Average'] ? `${(i.Fee_Risk_Return['Annualized 3 year Average']).toFixed(2)}%` : '--'),
        "cummulativeReturn": (i._return_cuml_v !== null && i._return_cuml_v !== undefined && typeof i._return_cuml_v !== 'undefined') ? i._return_cuml_v.toFixed(2) + "%" : (i.Fee_Risk_Return && i.Fee_Risk_Return['Cumulative'] ? `${(i.Fee_Risk_Return['Cumulative']).toFixed(2)}%` : '--'),
        "annualizedRisk": (i._volatility_v !== null && i._volatility_v !== undefined && typeof i._volatility_v !== 'undefined') ? i._volatility_v.toFixed(2) + "%" : (i.Fee_Risk_Return && i.Fee_Risk_Return['Annualized 3 year Volatility'] ? `${(i.Fee_Risk_Return['Annualized 3 year Volatility']).toFixed(2)}%` : '--'),
        "drawdownRisk": (i._drawdown_v !== null && i._drawdown_v !== undefined && typeof i._drawdown_v !== 'undefined') ? i._drawdown_v.toFixed(2) + "%" : (i.Fee_Risk_Return && i.Fee_Risk_Return['Drawdown'] ? `${(i.Fee_Risk_Return['Drawdown']).toFixed(2)}%` : '--'),
        "assets": i.aum,
        "volume": cn(i, 'Volume'),
        "sector1": sectors[0].name,
        "sector1_wt": sectors[0].value ? sectors[0].value.toFixed(2) : '--',
        "sector2": sectors[1].name,
        "sector2_wt": sectors[1].value ? sectors[1].value.toFixed(2) : '--',
        "sector3": sectors[2].name,
        "sector3_wt": sectors[2].value ? sectors[2].value.toFixed(2) : '--',
        "hold1": _.get(i,'holdingData[0].name',''),
        "hold1_wt": _.get(i,'holdingData[0].value',0) ? _.get(i,'holdingData[0].value',0).toFixed(2) : '--',
        "hold2": _.get(i,'holdingData[1].name',''),
        "hold2_wt": _.get(i,'holdingData[1].value',0)? _.get(i,'holdingData[1].value',0).toFixed(2) : '--',
        "hold3": _.get(i,'holdingData[2].name',''),
        "hold3_wt": _.get(i,'holdingData[2].value',0) ? _.get(i,'holdingData[2].value',0).toFixed(2) : '--',
        "reg1": _.get(regions,'[0].n'),
        "reg1_wt": _.get(regions,'[0].v',0) ? _.get(regions,'[0].v',0).toFixed(2) : '--',
        "reg2": _.get(regions,'[1].n'),
        "reg2_wt": _.get(regions,'[1].v',0) ? _.get(regions,'[1].v',0).toFixed(2) : '--',
        "reg3": _.get(regions,'[2].n'),
        "reg3_wt": _.get(regions,'[2].v',0) ? _.get(regions,'[2].v',0).toFixed(2) : '--',
        "sharpe_ratio": i.sharpe_3y || '--',
        "info_ratio": i.info_ratio || '--',
        "tracking_error": i.tracking_error ? `${(i.tracking_error * 100).toFixed(2)}%` : '--',
        "assetTypeData": assetAlloc || '--',
        // "themes": i.themes || '--',
        "bonds": assetAlloc && assetAlloc.bonds ? `${(assetAlloc.bonds).toFixed(2)}%` : '--',
        "cash": assetAlloc && assetAlloc.cash ? `${(assetAlloc.cash).toFixed(2)}%` : '--',
        "commodities": assetAlloc && assetAlloc.commodities ? `${(assetAlloc.commodities).toFixed(2)}%` : '--',
        "currencies": assetAlloc && assetAlloc.currencies ? `${(assetAlloc.currencies).toFixed(2)}%` : '--',
        "derivatives": assetAlloc && assetAlloc.derivatives ? `${(assetAlloc.derivatives).toFixed(2)}%` : '--',
        "equities": assetAlloc && assetAlloc.equities ? `${(assetAlloc.equities).toFixed(2)}%` : '--',
        "other": assetAlloc && assetAlloc.other ? `${(assetAlloc.other).toFixed(2)}%` : '--',
      }
      return info;
    });

    this.setState({
      Headers: headers
    }, () => {
      // let secValue = ["communication_services", "consumer_discretionary", "consumer_staples", "energy",
      //   "financials", "health_care", "industrials", "materials", "real_estate", "utilities", "others_non_classified"]
      let secValue = ["sector1", "sector1_wt","sector2", "sector2_wt", "sector3", "sector3_wt"]
      let holdValue = ["hold1" , "hold1_wt" , "hold2" , "hold2_wt" , "hold3" , "hold3_wt"];
      let regValue = ["reg1" , "reg1_wt" , "reg2" , "reg2_wt" , "reg3" , "reg3_wt"];
      let selectedVal = this.state.variable.filter(x => x.category == 'selected')
      let head = this.state.Headers.map(x => x.key);
      let sel = [];
      selectedVal.forEach(x => {
        if(x.id === 'topSectors') {
          sel.push(...secValue)
        } else if(x.id === 'topHoldings') {
          sel.push(...holdValue)
        } else if(x.id === 'topRegions') {
          sel.push(...regValue)
        } else {
          sel.push(x.id)
        }
      });
      // console.log(selectedVal);
      let selectedHeader = (sel || []).filter(element => head.includes(element));
      let myHeader = [];
      // console.log(selectedHeader);
      selectedHeader.forEach(header => {
        const headerObj = _.find(this.state.Headers, {
          key: header
        });
        const assetTypeDataPresent = _.find(cardData, header);
        // console.log(assetTypeDataPresent);
        if (header === 'assetTypeData' && assetTypeDataPresent[header]) {
          // console.log(header);
          // console.log(assetTypeDataPresent[header]);
          let newAssetKeys = [];
          Object.keys(assetTypeDataPresent[header]).map((item) => {
            if (item !== 'ticker') {
              newAssetKeys.push({
                label: convertToCapitalCase(item),
                key: item,
              });
            }
          });
          // console.log(newAssetKeys);
          myHeader = [...myHeader, ...newAssetKeys];
        } else {
          if(headerObj) {
            myHeader.push(headerObj);
          }
        }
      });
      this.setState({
        selectedTitle: myHeader,
      }, () => {
        this.setState({
          fundsData: cardData
        }, () => {
          // console.log(myHeader);
          // console.log(this.state.fundsData);
          if (this.csvLink && this.csvLink.current) {
            this.csvLink.current.link.click();
            this.onCloseDownloadTradeList();
            this.props.onDownload();
          }
        });
      });
    });

  }

  deletItem = (ev, cat) => {
    let id = ev.id;
    let variable = this.state.variable.filter((task) => {
      if (task.id == id) {
        task.category = cat;
      }
      return task;
    });
    this.setState({
      ...this.state,
      variable,
    });
  }

  onCloseDownloadTradeList = () => {
    this.setState({ downloadExcel: false });
    this.props.onCloseClick();
  }

  onSelectDbleCLick = (e, val) => {
    let variable = this.state.variable.filter((task) => {
      if (task.name == e.target.innerText && task.name != 'Ticker') {
        task.category = "selected";
      }
      return task;
    });
    this.setState({
      ...this.state,
      variable,
    });
    let clickVal = this.state.variable.filter(x => x.name == e.target.innerText);
    let singleObject = _.extend.apply({}, clickVal)
    this.setState({
      selectedVar: [...this.state.selectedVar, singleObject],
    });
  }

  onRemoveDbleCLick = (e, val) => {
    let variable = this.state.variable.filter((task) => {
      if (task.name == e.target.innerText && task.name != 'Ticker') {
        task.category = "options";
      }
      return task;
    });
    this.setState({
      ...this.state,
      variable,
    });
  }

  setDownloadLimit = (value) => {
    const regex = /^[0-9\b]+$/;    // allow only numbers [0-9]
    if(regex.test(value)) {
      this.setState({
        downloadCSVLimit: value ? parseInt(value) : value,
      });
      if (value > MAX_DOWNLOAD_LIMIT || value < MIN_DOWNLOAD_LIMIT) {
        this.setState({
          limitErrorMsg: '*Please enter a value between 1 and 100',
          limitError: true,
        });
      } else {
        this.setState({
          limitErrorMsg: '',
          limitError: false,
        });
      }
    } else {
      console.log('--- NOT AN INTEGER ---');
    }
  }

  render() {
    const { csvLoading } = this.props;
    const { downloadCSVLimit, limitError, limitErrorMsg } = this.state;
    var variable = {
      options: [],
      selected: []
    }
    this.state.variable.forEach((t,i) => {
      // console.log(t);
      // console.log(variable[t.category]);
      variable[t.category].push(
        <div key={t.name}
          id={ i + 1 }
          onDragStart={(e) => this.onDragStart(e, t)}
          onDoubleClick={(e)=>this.onSelectDbleCLick(e,"options")}
          onClick={(e)=>this.onSelectDbleCLick(e,"options")}
          draggable
          className="draggable"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' ,cursor:'pointer'}}
        >
          {t.name}
          <span style={{ cursor: 'pointer', paddingRight: '6px' }} onClick={(e) => this.deletItem(t, 'options')}>{t.category === 'selected' && t.name != 'Ticker' && <img src="/assets/images/Cross.svg" />}</span>
          {/* <span style={{cursor:'pointer',position:'relative',right:'-120px'}} onClick={(e)=>this.deletItem(t,'options')}>{t.category === 'selected' &&<img src="/assets/images/Cross.svg"/>}</span> */}
        </div>
      );
    });

    return (
      <Modal
        isOpen={this.state.downloadExcel}
        toggle={() => this.onCloseDownloadTradeList()}
        centered
        keyboard={true}
        backdrop
        modalClassName="downloadExcel-modalClassName"
        className="account-broker-modal download-msg-modal account-modal"
        backdropClassName="download-feature-process-modal-backdrop"
      >
        <ModalBody className="ssf-modal__body" >
          {csvLoading && <Loader loading />}
          <div className="download-feature-process-modal-container">
            <div className="header-wrapper">
              <h1 className="text-align-center" style={{marginBottom:'39px'}}>Save Your Results</h1>
            </div>
            <i className="fal fa-times-circle download-feature-completed-close" style={{ color: '#1e5f91' }} onClick={() => this.onCloseDownloadTradeList()}></i>
            <div className="container-drag" style={{ display: 'flex' }}>
              <div>
                <div style={{ fontFamily: 'Roboto', fontSize: '19px', lineHeight: '2.19', textAlign: 'center', color: '#1e5f91' }}>Click or drag to select variables</div>
                <div className="wip"
                  onDragOver={(e) => this.onDragOver(e)}
                  onDrop={(e) => { this.onDrop(e, "options") }}>
                  <div style={{ height: '19px' }}></div>
                  <div className="option-var">
                    {variable.options}
                  </div>
                </div>
                {/* <span style={{ fontFamily: 'Roboto', fontSize: '12px', lineHeight: '2.19', textAlign: 'left', color: '#cccccc',marginLeft:'18px' }}>Click or drag and drop selected variables</span> */}
              </div>
              <div style={{ marginTop: '40px', marginRight: '16px', marginLeft: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src="/assets/images/DragAndDrop-Icon.svg" />
              </div>
              <div>
                <div style={{ fontFamily: 'Roboto', fontSize: '19px', lineHeight: '2.19', textAlign: 'center', color: '#1e5f91' }}>Your file will have</div>
                <div className="droppable"
                  onDragOver={(e) => this.onDragOver(e)}
                  onDoubleClick={(e)=>this.onRemoveDbleCLick(e,"selected")}
                  onClick={(e)=>this.onRemoveDbleCLick(e,"selected")}
                  onDrop={(e) => this.onDrop(e, "selected")}>
                  <div style={{ height: '19px' }}></div>
                  <div className="select-var">
                    {/* <div className="sel-label"></div> */}
                    {variable.selected}
                  </div>
                </div>
              </div>
            </div>
            <div className="download-csv" style={{ marginTop: '39px' }}>
              <div className="download-csv-limit-wrap">
                <span className="download-csv-limit-text">Download top</span>
                <span className="download-csv-limit-input">
                  <InputNumber
                    className=""
                    size="small"
                    min={MIN_DOWNLOAD_LIMIT}
                    max={MAX_DOWNLOAD_LIMIT}
                    value={downloadCSVLimit}
                    onChange={this.setDownloadLimit}
                  />
                </span>
                <span className="download-csv-limit-text">results</span>
              </div>
              <div className="download-csv-limit-error">
                {(limitError && limitErrorMsg !== '') && (
                  <span className="download-csv-limit-error-text">{limitErrorMsg}</span>
                )}
              </div>
              <ButtonGroup className="cta-wrapper justify-content-center">
                <PrimaryButton
                  className="btn secondary-btn"
                  type="button"
                  disabled={limitError || csvLoading}
                  onClick={this.downloadCardTradeListExcel}
                  style={{ maxWidth: '203px', cursor: 'pointer', color: '#ffffff', backgroundColor: '#1e5f91', letterSpacing: '0px',fontSize:'14px' }}
                >
                  Download
                </PrimaryButton>
              </ButtonGroup>
            </div>
            <CSVLink
              data={this.state.fundsData}
              headers={this.state.selectedTitle}
              filename={"SecurityData.csv"}
              target="_blank"
              ref={this.csvLink}
            ></CSVLink>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = ({ discovery, loading }) => ({
  discovery,
  universe: discovery.universe,
  searchedResultsForCSV: discovery.searchedResultsForCSV,
  csvLoading: createLoadingSelector(['DOWNLOAD_SEARCHED_RESULTS'])({ loading }),
})

const mapDispatchToProps = {
  downloadSearchedResultsRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DownloadExcel))

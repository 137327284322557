import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { Modal, Alert } from 'antd';

import { createLoadingSelector } from 'selectors/loading-selector';
import { oktaChangePasswordRequest } from 'actions/auth';

import {
  hasDigitRegex,
  hasLowerCaseRegex,
  hasUpperCaseRegex,
	// addEventToAnalytics, searchUrlV3
  hasSpecialCharactersRegex,
} from 'Utils';
import {
	Spinner,
	PrimaryButton,
  CustomFormFeedback,
  PasswordRequirement,
} from 'shared-components'

const initialValues = {
  password: '',
  rePassword: '',
}

class OktaChangePasswordModal extends React.Component {

	onModalClose = () => {
		console.log('--- onModalClose ---');
		// const { oktaChangePasswordRequest, history } = this.props;
		// if (oktaChangePasswordRequest) {
		// 	oktaChangePasswordRequest(false);
		// 	addEventToAnalytics(
		// 		'Not Yet. Take Me To My Account',
		// 		'Not Yet. Take Me To My Account',
		// 		'NOT_YET_TAKE_ME_TO_MY_ACCOUNT',
		// 		{}
		// 	);
		// 	// if (history) {
		// 	// 	// NOTE: Get query from discovery reducer's state
		// 	// 	// --------------------------------------------------------------------------------------------
		// 	// 	// const { discoveryQuery } = this.props;
		// 	// 	let discoveryQuery = this.props.discoveryQuery || window.sessionStorage.getItem('query') || ''
		// 	// 	const securitiesPath = searchUrlV3(discoveryQuery); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
		// 	// 	// --------------------------------------------------------------------------------------------
		// 	// 	history.push(securitiesPath);
		// 	// }
		// }
	}

	handleFormSubmit = (values, { setSubmitting }) => {
		console.log(values)
		const { oktaChangePasswordRequest } = this.props;
		if (values && oktaChangePasswordRequest) {
			oktaChangePasswordRequest(values, (res) => {
				console.log(res);
			})
		}
		// props.nextClick(values, { setSubmitting }, props.plan_selected === 'basic')
	}

  render() {
    const {
      width,
      style,
			loading,
			centered,
			bodyStyle,
			maskStyle,
      modalTitle,
      wrapClassName,
			footer = null,
			oktaChangePasswordError,
			oktaChangePasswordModal,
    } = this.props;

		// console.log('oktaChangePasswordModal --> ', oktaChangePasswordModal)

		if (!oktaChangePasswordModal) return null;

		const validationSchema1 = Yup.object().shape({
			password: Yup.string()
				.required('Password is a required field')
				.min(8, 'Password has to be longer than 8 characters!')
				.matches(
					hasLowerCaseRegex,
					'Password should contain at least one lower case letter',
				)
				.matches(
					hasUpperCaseRegex,
					'Password should contain at least one upper case letter',
				)
				.matches(hasDigitRegex, 'Password should contain at least one number')
				.matches(
					hasSpecialCharactersRegex,
					'Password should contain atleast one special character',
				),
			rePassword: Yup.string()
				.oneOf([Yup.ref('password'), null], 'Passwords must match')
				.required('Re-enter password is a required field'),
		})


    return (
      <React.Fragment>
        <Modal
          destroyOnClose
          width={width}
          style={style}
          footer={footer}
					closable={false}
          title={modalTitle}
          centered={centered}
          maskStyle={maskStyle}
          bodyStyle={{
						height: 'auto',
				    top: '60px',
				    bottom: 0,
				    margin: 'auto',
					}}
          visible={oktaChangePasswordModal}
          wrapClassName={`csv-preview-upload okta-change-password-modal ${wrapClassName}`}
          onOk={() => this.onModalClose()}
          onCancel={() => this.onModalClose()}
          closeIcon={<i className="fal fa-times premium-feature-completed-close cp-modal-close" onClick={() => this.onModalClose()}></i>}
        >
					{loading && (
						<Spinner isFetching={loading} />
					)}
					<Formik
						initialValues={initialValues}
						onSubmit={this.handleFormSubmit}
						validationSchema={validationSchema1}
						render={formProps => (
							<Form
								name="RegisterFormRHS"
								className="magnifi-auth-design-container register-magnifi-auth-design advisor_different_layout"
								noValidate={true}
								onSubmit={formProps.handleSubmit}
							>
								<div className="header-area retail_form_container text-center">
									<h1>Change Password</h1>
								</div>
								<div className="body-wrapper retail_form_container">
									<FormGroup className="element-box pos-rel">
										<Label className={formProps.values.password ? "label new_label has_value" : "label new_label"} htmlFor="password">
											Password
										</Label>
										<Input
											type="password"
											name="password"
											id="password"
											value={formProps.values.password}
											onChange={formProps.handleChange}
											onBlur={formProps.handleBlur}
											className={
												formProps.errors.password && formProps.touched.password
													? 'is-invalid input-area'
													: 'input-area'
											}
										// placeholder="Enter a password"
										/>
										<CustomFormFeedback
											formProps={formProps}
											fieldName="password"
										/>
									</FormGroup>

									<FormGroup className="element-box pos-rel">
										<Label className={formProps.values.rePassword ? "label new_label has_value" : "label new_label"} htmlFor="rePassword">
											Confirm Password
										</Label>
										<Input
											type="password"
											name="rePassword"
											id="rePassword"
											value={formProps.values.rePassword}
											onChange={formProps.handleChange}
											onBlur={formProps.handleBlur}
											className={
												formProps.errors.rePassword && formProps.touched.rePassword
													? 'is-invalid input-area'
													: 'input-area'
											}
										// placeholder="Re-enter password"
										/>
										<CustomFormFeedback
											formProps={formProps}
											fieldName="rePassword"
										/>
									</FormGroup>

									{oktaChangePasswordError && (
										<Alert className="mb-1" type="error" message={oktaChangePasswordError} />
									)}

									<FormGroup className="element-box pos-rel">
										<PasswordRequirement
											label={'Password Requirements:'}
											formProps={formProps}
											fieldName={'password'}
										/>
									</FormGroup>

									<FormGroup className="cta-wrapper">
										<PrimaryButton
											type="submit"
											disabled={loading}
											className="btn primary-btn"
											style={{ marginTop: '6px', marginBottom: '6px' }}
										>
											Change Password
										</PrimaryButton>

									</FormGroup>
								</div>
							</Form>
						)}
					/>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ auth, loading }) => ({
	oktaChangePasswordModal: auth.oktaChangePasswordModal || null,
	oktaChangePasswordError: auth.oktaChangePasswordError || null,
	loading: createLoadingSelector([
		'OKTA_CHANGE_PASSWORD'
	])({ loading }),
});

const mapDispatchToProps = {
	oktaChangePasswordRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OktaChangePasswordModal));

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Row, Col, Card, Menu, Collapse } from 'antd';
import _ from 'lodash';
import { addEventToAnalytics } from 'Utils';

import { createLoadingSelector } from 'selectors/loading-selector';
import { tickerDataRequest, deleteCustomCard, setBuildPortfolioState, searchForNewQueryRequest } from 'actions/buildportfolio';

import { customScroll } from 'layouts/utils';
import { getSelectedFundsCount } from 'layouts/WebDashboard/BuildPortfolio/utils';

import CalculatorSlider from '../../../Elements/CalculatorSlider';
import InputSearch from '../../../Elements/InputSearch';
import ListView from './ListView';
import { convertToCapitalCase } from 'Utils';

const { SubMenu } = Menu;
const { Panel } = Collapse;

class ThematicRadioFunds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabHeaderMenu: props.data || [],
      activeTabPane: '1',
      searchQuery: '',
    };
  }

  componentDidMount() {
    customScroll();
    const { data, noResultFound } = this.props;
    console.log(this.state.tabHeaderMenu);
    if (data && data.length) {

      let dataList = JSON.parse(JSON.stringify(data.filter((v) => v.mode === 'initial')));
      let newList = JSON.parse(JSON.stringify(data.filter((v) => v.mode === 'new')));

      if (newList.length > 0) {
        if (!noResultFound) {
          if (newList.filter((h) => h.query === this.props.requestedQuery).length) {
            dataList = newList.filter((h) => h.query === this.props.requestedQuery);
          }
        } else {
          // console.log(newList);
          if (this.state.searchQuery !== this.props.requestedQuery && this.state.searchQuery !== '') {
            dataList = newList.filter((h) => h.query === this.state.searchQuery);
          } else {
            // no result found .. therefore selecting first element.
            // console.log(newList[newList.length-1]);
            dataList = [newList[newList.length-1]];
          }
        }
      }
      console.log(dataList);

      this.setState({ tabHeaderMenu: dataList }, () => {
        // this.calculateSliderValue();
      });

    }

    // console.log(this.props);
    if (typeof this.props.queryFromThemeList !== 'undefined') {
      if (this.props.queryFromThemeList !== '' && this.props.queryFromThemeList.length > 0 && this.state.searchQuery !== this.props.queryFromThemeList) {
        // console.log(this.props.queryFromThemeList[0]);
        this.setState({ searchQuery: convertToCapitalCase(this.props.queryFromThemeList[0]) }, () => console.log(this.state.searchQuery));
      }
    }
  }

  componentWillReceiveProps(newProps) {
    const { thematicTab, buildportfolio, getUpdatedThematicTabData } = newProps;


    if (newProps.data !== this.state.tabHeaderMenu) {
      let dataList = newProps.data.filter((v) => v.mode === 'initial');
      let newList = newProps.data.filter((v) => v.mode === 'new');

      if (newList.length > 0) {
        if (!newProps.noResultFound) {
              if (newList.filter((h) => h.query === newProps.requestedQuery).length) {
            dataList = newList.filter((h) => h.query === newProps.requestedQuery);
          }else{
            if(newList.filter((h) => h.query === newProps.requestedQuery).length === 0 && newList.filter((h) => h.query === this.state.searchQuery).length === 0){
              dataList = [newList[newList.length-1]];
            }
          }
        } else {
          if (this.state.searchQuery !== newProps.requestedQuery && this.state.searchQuery !== '') {
            dataList = newList.filter((h) => h.query === this.state.searchQuery);
          } else {
            // no result found .. therefore selecting first element.
            dataList = [newList[newList.length-1]];
          }
        }
      }

      console.log(dataList);

      // assign slidervalue to each fund in list;
      // for specific row e.g: USA row.
      dataList.map((l) => {
        if (l.query) {
          l.result.funds.map((w) => {
            w._sliderValue = 0;
            if (buildportfolio.thematicTab.selectedFunds && Object.keys(buildportfolio.thematicTab.selectedFunds).length > 0) {
              if (buildportfolio.thematicTab.selectedFunds[l.query] && typeof buildportfolio.thematicTab.selectedFunds[l.query] !== 'undefined') {
                const found = buildportfolio.thematicTab.selectedFunds[l.query].find((o) => o.ticker === w.ticker);
                if (found && typeof found !== 'undefined' && (found.ticker === w.ticker)) {
                  if(!buildportfolio.thematicTab._isCustomInput){
                    console.log(w._sliderValue)
                    w._sliderValue = l.sliderValue ? l.sliderValue/buildportfolio.thematicTab.selectedFunds[l.query].length : 0;
                    console.log(w._sliderValue)
                  }

                }
              }
            }
          });
          if (typeof buildportfolio.thematicTab.selectedFunds[l.query] !== 'undefined') {
            // console.log(buildportfolio.thematicTab.data);
            // const getRowDataSliderValue = buildportfolio.thematicTab.data.find((t) => t.query === l.query);
            const selF = buildportfolio.thematicTab.selectedFunds[l.query];
            if (selF && typeof selF !== 'undefined') {
              buildportfolio.thematicTab.selectedFunds[l.query].map((c) => {
                if(! buildportfolio.thematicTab._isCustomInput){
                  console.log(c._sliderValue)
                  c._sliderValue = l.sliderValue/selF.length;
                  console.log(c._sliderValue)
                }

              });
            }
          }
        }
        // console.log(l.query, l.sliderValue);
      });

      this.setState({ tabHeaderMenu: dataList });

      if (getUpdatedThematicTabData) {
        getUpdatedThematicTabData(dataList);
      }
    }

    if (typeof newProps.queryFromThemeList !== 'undefined') {
      if (newProps.queryFromThemeList !== '' && newProps.queryFromThemeList.length > 0 && this.state.searchQuery !== newProps.queryFromThemeList) {
        console.log(newProps.queryFromThemeList[0]);
        this.setState({ searchQuery: convertToCapitalCase(newProps.queryFromThemeList[0]) }, () => console.log(this.state.searchQuery));
      }
    }

  }

  calculateSliderValue = (value, label) => {
    //filter the header reqquired for slider wt calc after the change
    //it may be less than the total in case of manual slider chnage
    //or it may be all if the components loads for the first time or weightingMethodology chnage
    const topTab = this.props.topTabHeaderMenu.find((item) => item.key === 'thematicTab');
    let { weightingMethodology, thematicTab, setBuildPortfolioState } = this.props;
    let totalSum = 0.0, filterObjsTotalSum = 0.0, diff = 0.0;
    let filterObjs = [], unfilterObjs = [];

    //delete this code after testing the equal and manual mode
    // if(weightingMethodology == 'Equal') {
    //   filterObjs = JSON.parse(JSON.stringify(this.state.tabHeaderMenu));
    //   filterObjs.forEach((item,index) => {filterObjs[index].sliderValue = 1});
    //   console.log('sid eqaul', filterObjs)
    // }
    // else {
      this.state.tabHeaderMenu.forEach(e => {
        let item = JSON.parse(JSON.stringify(e));
        if(weightingMethodology == 'Equal') item.sliderValue = 1
        totalSum += item.sliderValue;
        if((item.label != label && item.locked == false) || weightingMethodology == 'Equal') { //filter condition
          filterObjsTotalSum += item.sliderValue;
          filterObjs.push(item);
        }
        else {
          unfilterObjs.push(item);
        }
      });
    // }

    if(filterObjs.length>0) {
      diff = topTab.sliderValue - totalSum;
      filterObjs.forEach((obj, index) => {
        let ratio = obj.sliderValue == 0 ? 0 : obj.sliderValue / filterObjsTotalSum;
        let wt = diff * ratio;
        filterObjs[index].sliderValue = Math.round(obj.sliderValue + wt);
      });

      filterObjs = _.sortBy(filterObjs.concat(unfilterObjs),['position']);
      thematicTab.data = filterObjs;
      this.setState({ tabHeaderMenu:  thematicTab.data})
      if (setBuildPortfolioState) {
        setBuildPortfolioState({ thematicTab : thematicTab })
      }
    }
  }

  deleteCard = ({ item, index, searchedTicker, forTicker, forQuery }) => {
    const { deleteCustomCard, activeTab } = this.props;
    deleteCustomCard({
      item,
      index,
      searchedTicker,
      forTicker,
      forQuery,
      tab: activeTab,
    });
  }

  runWeightAllocation = (allStateMappedData, topTabHeaderMenu) => {
    const equityTabFunds = allStateMappedData.equityTabFunds;
    const bondTabFunds = allStateMappedData.bondTabFunds;
    const thematicTabFunds = allStateMappedData.thematicTabFunds;
    const currentThematicSliderValue = topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue;

    const foundTabWithWeights = topTabHeaderMenu.filter((x) => x.sliderValue !== 0);

    if (equityTabFunds && bondTabFunds && thematicTabFunds) {
      if (currentThematicSliderValue === 0) {
        let old1 = 0, old2 = 0, diff = 10;
        old1 = topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue;
        old2 = topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue

        topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue = old1 + ((old1 == 0 ? 0 : old1/(old1+old2))*-diff);
        topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue = old2 + ((old2 == 0 ? 0 : old2/(old1+old2))*-diff);
        topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue = diff;
      }
    } else if (equityTabFunds && bondTabFunds && (thematicTabFunds === 0)) {
      if (currentThematicSliderValue === 0) {
        let old1 = 0, old2 = 0, diff = -1*(100-(topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue + topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue));
        old1 = topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue;
        old2 = topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue

        topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue = old1 + ((old1 == 0 ? 0 : old1/(old1+old2))*-diff);
        topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue = old2 + ((old2 == 0 ? 0 : old2/(old1+old2))*-diff);
        topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue = 0;
      }
    } else if (equityTabFunds && (bondTabFunds === 0) && thematicTabFunds) {
      if (currentThematicSliderValue === 0) {
        let old1 = 0, old2 = 0, diff = 10;
        old1 = topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue;
        old2 = topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue

        topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue = old1 + ((old1 == 0 ? 0 : old1/(old1+old2))*-diff);
        topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue = old2 + ((old2 == 0 ? 0 : old2/(old1+old2))*-diff);
        topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue = diff;
      }
    } else if ((equityTabFunds === 0) && bondTabFunds && thematicTabFunds) {
      if (currentThematicSliderValue === 0) {
        let old1 = 0, old2 = 0, diff = 10;
        old1 = topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue;
        old2 = topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue

        topTabHeaderMenu.find((u) => u.key === 'equityTab').sliderValue = old1 + ((old1 == 0 ? 0 : old1/(old1+old2))*-diff);
        topTabHeaderMenu.find((u) => u.key === 'bondTab').sliderValue = old2 + ((old2 == 0 ? 0 : old2/(old1+old2))*-diff);
        topTabHeaderMenu.find((u) => u.key === 'thematicTab').sliderValue = diff;
      }
    } else if (foundTabWithWeights.filter((a) => (a.key !== 'thematicTab' && a.sliderValue !== 0)).length === 1) {
      foundTabWithWeights[0].sliderValue = 100;
    }

    // console.log(topTabHeaderMenu);

    return topTabHeaderMenu;
  }

  executeMe = (forQuery, newPropsSelectedFunds, currentTab, tabRows, allStateMappedData, sumofRows, topTabHeaderMenu) => {
    console.log(forQuery);
    console.log(allStateMappedData);

    const referenceKey = 'thematicTab';
    const globalTabWeight = topTabHeaderMenu.find((i) => i.key === 'thematicTab').sliderValue;
    console.log('sumofRows ======> ', sumofRows, globalTabWeight);

    // console.log(`--------- ${referenceKey} ---------`);
    const currentSectionFunds = allStateMappedData[`${referenceKey}Section`];
    // check if (how many row has selected funds)
    // console.log(allStateMappedData[`${referenceKey}Funds`]);

    if (allStateMappedData[`${referenceKey}Funds`] > 0) {
      // console.log('yes we have selected funds');
      // const { topTabHeaderMenu } = this.props;
      if (Object.keys(currentSectionFunds).length > 0) {
        Object.keys(currentSectionFunds).forEach((p) => {
          // console.log(Object.keys(currentSectionFunds));

          const sfsdff = Object.keys(currentSectionFunds).filter((r) => currentSectionFunds[r] !== 0).length;
          console.log(sfsdff);
          console.log(p);

          if (((tabRows.filter((s) => s.sliderValue !== 0).length === tabRows.length) && currentSectionFunds[p] > 0) && tabRows.find((s) => s.query === p) && sfsdff === tabRows.length && sumofRows === globalTabWeight) {
            console.log(`DO NOT CHANGE WEIGHT FOR ${p}`);
          } else {
            if (currentSectionFunds[p] > 0) {
              if (tabRows && typeof tabRows.length !== 'undefined' && tabRows.length > 0) {
                if (tabRows.find((s) => s.query === p) && typeof tabRows.find((s) => s.query === p) !== 'undefined' && tabRows.find((s) => s.query === p).sliderValue === 0) {
                  // tabRows.find((s) => s.query === p).sliderValue = (globalTabWeight)/sfsdff;
                  let diff = (10*globalTabWeight)/100;
                  console.log(diff);

                  tabRows.find((s) => s.query === forQuery).sliderValue = diff; //[USA]
                  // console.log('here');

                  let sum = 0;
                  tabRows.filter((s) => {// [USA, DM,EM]
                    console.log(s.query, p, forQuery, s.sliderValue);
                    if ((s.query !== forQuery && s.sliderValue > 0)) {
                      sum = sum+s.sliderValue;
                      return s
                    }
                  });
                  console.log(sum);

                  tabRows.filter((s, i) => {
                    if ((s.query !== forQuery && s.sliderValue > 0)) {
                      console.log(sum);
                      console.log(s.sliderValue);
                      console.log(s.sliderValue/(sum));
                      console.log(diff);
                      console.log(s.sliderValue + ((s.sliderValue == 0 ? 0 : s.sliderValue/(sum))*-diff));
                      s.sliderValue = s.sliderValue + ((s.sliderValue == 0 ? 0 : s.sliderValue/(sum))*-diff);
                    }
                  });
                } else {
                  console.log('-----exit 1-----');
                }
              } else {
                console.log('-----exit 2-----');
              }
            } else {
              console.log(`SET CURRENT ROW ${p} WEIGHT to 0 and split weight with other rows`);
              // console.log('WEIGHT for Other rows', (globalTabWeight)/sfsdff);

              if (tabRows && typeof tabRows.length !== 'undefined' && tabRows.length > 0) {
                if (tabRows.find((s) => s.query === p) && typeof tabRows.find((s) => s.query === p) !== 'undefined') {
                  let diff = tabRows.find((s) => s.query === p).sliderValue;
                  tabRows.find((s) => s.query === p).sliderValue = 0;

                  // tabRows.find((s) => s.query === forQuery).sliderValue = diff; //[USA]
                  // console.log('here');

                  let sum = 0;
                  tabRows.filter((s) => {// [USA, DM,EM]
                    // console.log(s.query, p, forQuery);
                    if ((s.query !== forQuery && s.sliderValue > 0)) {
                      sum = sum+s.sliderValue;
                      return s
                    }
                  });

                  tabRows.filter((s, i) => {
                    if ((s.query !== forQuery && s.sliderValue > 0)) {
                      s.sliderValue = s.sliderValue + ((s.sliderValue == 0 ? 0 : s.sliderValue/(sum))*diff);
                    }
                  });

                  // if (tabRows.find((s) => s.query === p) && typeof tabRows.find((s) => s.query === p) !== 'undefined' && tabRows.find((s) => s.query !== p && currentSectionFunds[p] !== 0) && typeof tabRows.find((s) => s.query !== p && currentSectionFunds[p] !== 0) !== 'undefined') {
                  //   tabRows.find((s) => s.query !== p && currentSectionFunds[p] !== 0).sliderValue = (globalTabWeight)/sfsdff;
                  // }
                  // tabRows.filter((l) => l.query !== p).map(e => e.sliderValue = (topTabHeaderMenu.find((i) => i.key === currentTab.key).sliderValue)/sfsdff);
                }

              }
            }
          }
          // console.log(tabRows);
        })
      }
    } else {
      console.log(`NO FUNDS SELECTED for ${referenceKey}`);
      // then allocate weight to current ROW (using forQuery)
      if (tabRows && typeof tabRows.length !== 'undefined' && tabRows.length > 0) {
        tabRows.forEach((item, index) => { // tabRows: ['USA', 'EM', 'DM']
          // console.log(`active row ------ ${forQuery} ------`);
          if (newPropsSelectedFunds[item.query] && typeof newPropsSelectedFunds[item.query] !== 'undefined') {
            // console.log(newPropsSelectedFunds[item.query]);
            if (newPropsSelectedFunds[item.query].length > 0) {
              item.sliderValue = topTabHeaderMenu.find((i) => i.key === currentTab.key).sliderValue;
            } else {
              item.sliderValue = 0;
            }
          }
        });

        // console.log(tabRows);
        // console.log(tabRows.filter((w) => w.sliderValue !== 0));
        if (tabRows.filter((w) => w.sliderValue !== 0).length === 0) {
          console.log('ALL ROWS WEIGHT ARE 0');
          currentTab.sliderValue = 0
           topTabHeaderMenu = this.runWeightAllocation(allStateMappedData, topTabHeaderMenu);
        }
      }
    }
  }

  // calCulateWeight = (forQuery) => {
  //   const { setBuildPortfolioState, buildportfolio } = this.props;
  //   const { thematicTab } = buildportfolio;
  //
  //   let topTabHeaderMenu = JSON.parse(JSON.stringify(buildportfolio.tabHeaderMenu));
  //   const oldHeaderMenuState = JSON.parse(JSON.stringify(buildportfolio.tabHeaderMenu));
  //   const oldEquityData = JSON.parse(JSON.stringify(buildportfolio.equityTab.data));
  //   const oldThematicData = JSON.parse(JSON.stringify(buildportfolio.thematicTab.data));
  //   const oldBondData = JSON.parse(JSON.stringify(buildportfolio.bondTab.data));
  //   const oldBuildportfolioState = JSON.parse(JSON.stringify(buildportfolio));
  //   const getStateMappedData = getSelectedFundsCount({ allStates: buildportfolio, tab: 'thematicTab' });
  //   // console.log(getStateMappedData);
  //
  //   topTabHeaderMenu.forEach((item, i) => {
  //     // if all tabs has 0 weights
  //
  //     if (topTabHeaderMenu[0].sliderValue === 0 && topTabHeaderMenu[1].sliderValue === 0 && topTabHeaderMenu[2].sliderValue === 0) {
  //       // then current active tab will be 100; for.e.g: equityTab: 100%
  //       if (item.key === 'thematicTab') {
  //         item.sliderValue = 100;
  //         if (thematicTab.data) {
  //           let sumofRows = 0;
  //           // console.log(thematicTab.data);
  //           thematicTab.data.map((k) => {
  //             sumofRows += k.sliderValue;
  //           });
  //           this.executeMe(forQuery, thematicTab.selectedFunds, item, thematicTab.data, getStateMappedData, sumofRows, topTabHeaderMenu);
  //         }
  //       }
  //     } else {
  //       // console.log('No, some tabs have weights');
  //       // before assigning weight to bondTab check if it is having funds ?
  //
  //       topTabHeaderMenu = this.runWeightAllocation(getStateMappedData, topTabHeaderMenu);
  //
  //       if (item.key === 'thematicTab') {
  //         if (thematicTab.data) {
  //           let sumofRows = 0;
  //           // console.log(thematicTab.data);
  //           thematicTab.data.map((k) => {
  //             sumofRows += k.sliderValue;
  //           });
  //           this.executeMe(forQuery, thematicTab.selectedFunds, item, thematicTab.data, getStateMappedData, sumofRows, topTabHeaderMenu);
  //         }
  //       }
  //
  //     }
  //   });
  //
  //   // console.log(thematicTab);
  //
  //   // after deletion updating 'thematic.data' --->>> sliderValue;
  //   const selectedThemesFunds = thematicTab.selectedFunds;
  //   let currentData = thematicTab.data;
  //   let updatedArray = [];
  //   const thematicHeader = topTabHeaderMenu.find((x) => x.key === 'thematicTab');
  //
  //   // console.log('before updating currentData ==>', currentData);
  //
  //   if (Object.keys(selectedThemesFunds).length > 0) {
  //     updatedArray = Object.keys(selectedThemesFunds).map((v, index) => {
  //       if (selectedThemesFunds[v] && selectedThemesFunds[v].length > 0) {
  //         if (currentData.length > 0) {
  //           const found = currentData.find((g) => g.query === v);
  //           if (found && typeof found !== 'undefined') {
  //             found.selected = true;
  //             return found;
  //           }
  //         }
  //       } else if (typeof selectedThemesFunds[v] !== 'undefined' && selectedThemesFunds[v].length === 0) {
  //         if (currentData.length > 0) {
  //           if (currentData.filter((g) => g.query !== v) && currentData.filter((g) => g.query !== v).length > 0) {
  //             currentData.forEach((gV, i) => {
  //               if (gV.query !== v) {
  //                 // gV.selected = false;
  //                 delete gV.selected;
  //               }
  //             });
  //           }
  //         }
  //       }
  //     }).filter((k) => typeof k !== 'undefined');
  //
  //     // console.log('currentData----->>', currentData);
  //     // updatedArray will be selected Funds -> Themes Name header details
  //     // console.log('updatedArray----->>', updatedArray);
  //
  //     if (updatedArray.length === 0) {
  //       if (this.state.searchQuery !== '') {
  //         updatedArray = currentData.filter((h) => h.query === this.state.searchQuery);
  //       }
  //     }
  //
  //     updatedArray.forEach((r) => {
  //       r.sliderValue = thematicHeader.sliderValue/updatedArray.length;
  //       r.sliderValue = parseFloat(r.sliderValue.toFixed(1));
  //     });
  //
  //     if (updatedArray.length !== currentData.length) {
  //       // updatedArray will selected Funds -> Themes Name header details
  //       // currentData is state before filtering out
  //       // console.log(updatedArray);
  //       const filterOut = _.differenceBy(currentData, updatedArray, 'query');
  //       filterOut.map((f, i) => {
  //         f.sliderValue = thematicHeader.sliderValue/filterOut.length;
  //         f.sliderValue = parseFloat(f.sliderValue.toFixed(1));
  //       });
  //
  //       // console.log(filterOut);
  //
  //       console.log(updatedArray);
  //       updatedArray = _.uniqBy([...updatedArray, ...filterOut], 'query'); // do not change this!
  //     }
  //     console.log(updatedArray.filter((n) => (typeof n.selected !== 'undefined' && n.selected)));
  //     if (updatedArray.filter((n) => (typeof n.selected !== 'undefined' && n.selected)).length > 0) {
  //       updatedArray.filter((n) => (typeof n.selected !== 'undefined' && n.selected)).forEach((p) => {
  //         if (p) {
  //           p.sliderValue = thematicHeader.sliderValue/updatedArray.filter((d) => (typeof d.selected !== 'undefined' && d.selected)).length;
  //         }
  //       });
  //       console.log(updatedArray);
  //       // updatedArray = _.uniqBy([...updatedArray, ...newS], 'query'); // do not change this!
  //     }
  //     console.log(updatedArray);
  //
  //   }
  //
  //   // console.log(updatedArray);
  //   buildportfolio.thematicTab.data = updatedArray;
  //
  //   buildportfolio.thematicTab.data.map((l) => {
  //     if (l.query) {
  //       l.result.funds.map((w) => {
  //         w._sliderValue = 0;
  //         if (buildportfolio.thematicTab.selectedFunds && Object.keys(buildportfolio.thematicTab.selectedFunds).length > 0) {
  //           if (buildportfolio.thematicTab.selectedFunds[l.query] && typeof buildportfolio.thematicTab.selectedFunds[l.query] !== 'undefined') {
  //             const found = buildportfolio.thematicTab.selectedFunds[l.query].find((o) => o.ticker === w.ticker);
  //             if (found && typeof found !== 'undefined' && (found.ticker === w.ticker)) {
  //               w._sliderValue = l.sliderValue ? l.sliderValue/buildportfolio.thematicTab.selectedFunds[l.query].length : 0;
  //             }
  //           }
  //         }
  //       });
  //       if (typeof buildportfolio.thematicTab.selectedFunds[l.query] !== 'undefined') {
  //         const getRowDataSliderValue = buildportfolio.thematicTab.data.find((t) => t.query === l.query);
  //         const selF = buildportfolio.thematicTab.selectedFunds[l.query];
  //         if (selF && typeof selF !== 'undefined') {
  //           buildportfolio.thematicTab.selectedFunds[l.query].map((c) => {
  //             c._sliderValue = getRowDataSliderValue.sliderValue/selF.length;
  //           });
  //         }
  //       }
  //     }
  //   });
  //
  //   if (setBuildPortfolioState) {
  //     setBuildPortfolioState({
  //       oldHeaderMenuState,
  //       oldBondData,
  //       oldEquityData,
  //       oldThematicData,
  //       thematicTab: {
  //         ...thematicTab,
  //         data: buildportfolio.thematicTab.data,
  //         selectedFunds: buildportfolio.thematicTab.selectedFunds,
  //       },
  //       tabHeaderMenu: topTabHeaderMenu,
  //     });
  //   }
  // }

  getSelectedFunds = ({ selectedFunds, forQuery, oldSelectedfunds }) => {
    const { thematicTab, setBuildPortfolioState, topTabHeaderMenu, buildportfolio } = this.props;
    const oldHeaderMenuState = JSON.parse(JSON.stringify(buildportfolio.tabHeaderMenu));
    const oldEquityTab = JSON.parse(JSON.stringify(buildportfolio.equityTab));
    const oldBondTab = JSON.parse(JSON.stringify(buildportfolio.bondTab));
    const oldThematicTab = JSON.parse(JSON.stringify(buildportfolio.thematicTab));
    const oldEquityData = JSON.parse(JSON.stringify(buildportfolio.equityTab.data));
    const oldThematicData = JSON.parse(JSON.stringify(buildportfolio.thematicTab.data));
    const oldBondData = JSON.parse(JSON.stringify(buildportfolio.bondTab.data));
    const oldBuildportfolioState = JSON.parse(JSON.stringify(buildportfolio));

    // console.log(selectedFunds);
    // console.log(thematicTab);
    if (oldThematicTab && Object.keys(oldSelectedfunds).length && typeof oldSelectedfunds[forQuery] !== 'undefined' && (oldSelectedfunds[forQuery].length > selectedFunds.length) && oldThematicData.find((g) => g.query === forQuery) && oldThematicData.find((g) => g.query === forQuery).sliderValue <= 0) {
      //delete OPeration NO IMPACT
      console.log('delete OPeration NO IMPACT');
      oldThematicData.find((g) => g.query === forQuery).sliderValue = 0;
      thematicTab.data.find((g) => g.query === forQuery).sliderValue = 0;
      if (typeof selectedFunds !== 'undefined' && forQuery) {
        const filteredArray = thematicTab.selectedFunds[forQuery];
        if (thematicTab.selectedFunds[forQuery]) {
          thematicTab.selectedFunds[forQuery] = selectedFunds;
        } else {
          thematicTab.selectedFunds = {
            ...thematicTab.selectedFunds,
            [forQuery]: selectedFunds,
          };
        }

        if (setBuildPortfolioState) {
          setBuildPortfolioState({
            thematicTab: buildportfolio.thematicTab,
            oldThematicData,
          });
        }

      }

    } else {
      console.log('add OPeration');
      if (typeof selectedFunds !== 'undefined' && forQuery) {
        if (thematicTab.selectedFunds[forQuery]) {
          thematicTab.selectedFunds[forQuery] = selectedFunds;
        } else {
          thematicTab.selectedFunds = {
            ...thematicTab.selectedFunds,
            [forQuery]: selectedFunds,
            // themes: selectedFunds,
          };
        }

        const getStateMappedData = getSelectedFundsCount({ allStates: buildportfolio, tab: 'thematicTab' });
        // console.log(getStateMappedData);

        topTabHeaderMenu.forEach((item, i) => {
          // if all tabs has 0 weights

          if (topTabHeaderMenu[0].sliderValue === 0 && topTabHeaderMenu[1].sliderValue === 0 && topTabHeaderMenu[2].sliderValue === 0) {
            // then current active tab will be 100; for.e.g: equityTab: 100%
            if (item.key === 'thematicTab') {
              item.sliderValue = 100;
              if (thematicTab.data) {
                let sumofRows = 0;
                // console.log(thematicTab.data);
                thematicTab.data.map((k) => {
                  sumofRows += k.sliderValue;
                });
                this.executeMe(forQuery, thematicTab.selectedFunds, item, thematicTab.data, getStateMappedData, sumofRows, topTabHeaderMenu);
              }
            }
          } else {
            // console.log('No, some tabs have weights');
            // before assigning weight to bondTab check if it is having funds ?

            this.runWeightAllocation(getStateMappedData, topTabHeaderMenu);

            if (item.key === 'thematicTab') {
              if (thematicTab.data) {
                let sumofRows = 0;
                // console.log(thematicTab.data);
                thematicTab.data.map((k) => {
                  sumofRows += k.sliderValue;
                });
                this.executeMe(forQuery, thematicTab.selectedFunds, item, thematicTab.data, getStateMappedData, sumofRows, topTabHeaderMenu);
              }
            }
          }
        });

        // console.log(thematicTab);

        // after deletion updating 'thematic.data' --->>> sliderValue;
        const selectedThemesFunds = thematicTab.selectedFunds;
        let currentData = thematicTab.data;
        let updatedArray = [];
        const thematicHeader = topTabHeaderMenu.find((x) => x.key === 'thematicTab');

        // console.log('before updating currentData ==>', currentData);

        if (Object.keys(selectedThemesFunds).length > 0) {
          updatedArray = Object.keys(selectedThemesFunds).map((v, index) => {
            if (selectedThemesFunds[v] && selectedThemesFunds[v].length > 0) {
              if (currentData.length > 0) {
                const found = currentData.find((g) => g.query === v);
                if (found && typeof found !== 'undefined') {
                  found.selected = true;
                  return found;
                }
              }
            } else if (typeof selectedThemesFunds[v] !== 'undefined' && selectedThemesFunds[v].length === 0) {
              if (currentData.length > 0) {
                if (currentData.filter((g) => g.query !== v) && currentData.filter((g) => g.query !== v).length > 0) {
                  currentData.forEach((gV, i) => {
                    if (gV.query !== v) {
                      gV.selected = false;
                      delete gV.selected;
                    }
                  });
                }
              }
            }
          }).filter((k) => typeof k !== 'undefined');

          // console.log('currentData----->>', currentData);
          // updatedArray will be selected Funds -> Themes Name header details
          // console.log('updatedArray----->>', updatedArray);

          if (updatedArray.length === 0) {
            if (this.state.searchQuery !== '') {
              updatedArray = currentData.filter((h) => h.query === this.state.searchQuery);
            }
          }

          // updatedArray.forEach((r) => {
          //   r.sliderValue = thematicHeader.sliderValue/updatedArray.length;
          //   r.sliderValue = parseFloat(r.sliderValue.toFixed(1));
          // });

          if (updatedArray.length !== currentData.length) {
            // updatedArray will selected Funds -> Themes Name header details
            // currentData is state before filtering out
            // console.log(updatedArray);
            const filterOut = _.differenceBy(currentData, updatedArray, 'query');
            // filterOut.map((f, i) => {
            //   f.sliderValue = thematicHeader.sliderValue/filterOut.length;
            //   f.sliderValue = parseFloat(f.sliderValue.toFixed(1));
            // });

            // console.log(filterOut);

            console.log(updatedArray);
            updatedArray = _.uniqBy([...updatedArray, ...filterOut], 'query'); // do not change this!
          }

          // console.log(updatedArray.filter((n) => (typeof n.selected !== 'undefined' && n.selected)));
          // if (updatedArray.filter((n) => (typeof n.selected !== 'undefined' && n.selected)).length > 0) {
          //   let diff = (10*thematicHeader.sliderValue)/100;
          //   console.log(diff);
          //   if (updatedArray.filter((n) => (typeof n.selected !== 'undefined' && n.selected)).length === 1) {
          //     diff = thematicHeader.sliderValue/updatedArray.filter((d) => (typeof d.selected !== 'undefined' && d.selected)).length;
          //   }
          //   console.log(diff);
          //
          //   updatedArray.filter((n) => (typeof n.selected !== 'undefined' && n.selected)).forEach((p) => { // [CARS]
          //     if (p.query === forQuery) {
          //       // p.sliderValue = 2;
          //       p.sliderValue = diff;
          //       // p.sliderValue = thematicHeader.sliderValue/updatedArray.filter((d) => (typeof d.selected !== 'undefined' && d.selected)).length;
          //     }
          //   });
          //
          //   let sum = 0;
          //   updatedArray.filter((n) => (typeof n.selected !== 'undefined' && n.selected)).filter((s) => {// [CC,FINTECH]
          //     console.log(s.query, forQuery, s.sliderValue);
          //     if ((s.query !== forQuery && s.sliderValue > 0)) {
          //       sum = sum+s.sliderValue;
          //       return s
          //     }
          //   });
          //   console.log(sum);
          //
          //   updatedArray.filter((n) => (typeof n.selected !== 'undefined' && n.selected)).filter((s, i) => {
          //     if ((s.query !== forQuery && s.sliderValue > 0) && oldThematicData.find(j => j.query === s.query)) {
          //       console.log(sum);
          //       console.log(s.sliderValue);
          //       console.log(s.sliderValue/(sum));
          //       console.log(diff);
          //       console.log(s.sliderValue + ((s.sliderValue/(sum))*-diff));
          //       console.log(oldThematicData.find(j => j.query === s.query));
          //       s.sliderValue = oldThematicData.find(j => j.query === s.query).sliderValue + ((oldThematicData.find(j => j.query === s.query).sliderValue/(sum))*-diff);
          //     }
          //   });
          //
          //   console.log(updatedArray);
          //   // updatedArray = _.uniqBy([...updatedArray, ...newS], 'query'); // do not change this!
          // }
          //
          // console.log(updatedArray);

        }

        // console.log(updatedArray);
        buildportfolio.thematicTab.data = updatedArray;

        buildportfolio.thematicTab.data.map((l) => {
          if (l.query) {
            l.result.funds.map((w) => {
              // w._sliderValue = 0;
              if(l.query && l.sliderValue != oldThematicData.find((o) => o.query === l.query).sliderValue){
                w._sliderValue = 0;
                const diff = l.sliderValue - oldThematicData.find((o) => o.query === l.query).sliderValue
                if (buildportfolio.thematicTab.selectedFunds && Object.keys(buildportfolio.thematicTab.selectedFunds).length > 0) {
                  if (buildportfolio.thematicTab.selectedFunds[l.query] && typeof buildportfolio.thematicTab.selectedFunds[l.query] !== 'undefined') {
                    const found = buildportfolio.thematicTab.selectedFunds[l.query].find((o) => o.ticker === w.ticker);
                    if (found && typeof found !== 'undefined' && (found.ticker === w.ticker)) {
                      if(buildportfolio.thematicTab.selectedFunds[l.query].length && buildportfolio.thematicTab.selectedFunds[l.query].length == 1){
                        // console.log('sid321')
                        w._sliderValue = l.sliderValue ? l.sliderValue/buildportfolio.thematicTab.selectedFunds[l.query].length : 0;
                      }
                      else if(buildportfolio.thematicTab.selectedFunds[l.query].length && buildportfolio.thematicTab.selectedFunds[l.query].length > 1){
                        //diff
                        //ratio = 
                        //wt
                        // console.log('sid322')
                        const oldFound = oldThematicTab.selectedFunds[l.query].find((o) => o.ticker === w.ticker);
                        let ratio = oldFound.sliderValue / oldThematicData.find((o) => o.query === l.query).sliderValue;
                        let wt = diff * ratio;
                        w._sliderValue = oldFound.sliderValue + wt;
                      }
                    }
                  }
                }
              }
              else if(l.query && l.sliderValue == oldThematicData.find((o) => o.query === l.query).sliderValue && l.query == forQuery){
                w._sliderValue = 0;
                if (buildportfolio.thematicTab.selectedFunds && Object.keys(buildportfolio.thematicTab.selectedFunds).length > 0) {
                  if (buildportfolio.thematicTab.selectedFunds[l.query] && typeof buildportfolio.thematicTab.selectedFunds[l.query] !== 'undefined') {
                    const found = buildportfolio.thematicTab.selectedFunds[l.query].find((o) => o.ticker === w.ticker);
                    const oldFound = oldThematicTab.selectedFunds[l.query].find((o) => o.ticker === w.ticker);
                    if (found && typeof found !== 'undefined' && oldFound && typeof oldFound !== 'undefined' && (found.ticker === w.ticker)) {
                      // console.log('sid321')
                      if(buildportfolio.thematicTab.selectedFunds[l.query].length == 1){
                        console.log('pratik 1')
                        w._sliderValue = l.sliderValue ? l.sliderValue/buildportfolio.thematicTab.selectedFunds[l.query].length : 0;
                      }
                      else if(buildportfolio.thematicTab.selectedFunds[l.query].length >= oldThematicTab.selectedFunds[l.query].length 
                              && oldThematicTab.selectedFunds[l.query].find((o) => o._sliderValue === 0)
                              && oldSelectedfunds && oldSelectedfunds[l.query] && oldSelectedfunds[l.query].length < selectedFunds.length) {
                        //add
                        // if fund == 0 add 10% diff
                        if(oldFound._sliderValue == 0){
                          w._sliderValue = (10*l.sliderValue)/100;
                          console.log('pratik add 1', w.ticker, w._sliderValue)
                        }
                        else{
                          let diff = -(10*l.sliderValue)/100;
                          let ratio = oldFound._sliderValue / oldThematicData.find((o) => o.query === l.query).sliderValue;
                          let wt = diff * ratio
                          w._sliderValue = oldFound._sliderValue + wt;
                          console.log('pratik add >1', w.ticker, w._sliderValue, diff, ratio, wt)
                        }
                        console.log('pratik add', w.ticker, w._sliderValue)
                      }
                      else if(buildportfolio.thematicTab.selectedFunds[l.query].length >= oldThematicTab.selectedFunds[l.query].length 
                              && !oldThematicTab.selectedFunds[l.query].find((o) => o._sliderValue === 0)
                              && oldSelectedfunds && oldSelectedfunds[l.query] && oldSelectedfunds[l.query].length > selectedFunds.length) {
                        //delete check
                        //_.differenceBy([{ 'x': 2 }, { 'x': 1 }], [{ 'x': 1 }], 'x');
                        // old , new
                        let diffObj = _.differenceBy(oldSelectedfunds[l.query], selectedFunds, 'ticker');
                        if(diffObj.length){
                          let filterArrSum = _.sumBy(oldSelectedfunds[l.query].filter(o => o.ticker != diffObj[0].ticker),'_sliderValue');
                          // _.sumBy(objects, 'n');
                          let diff = diffObj[0]._sliderValue;
                          let ratio = oldFound._sliderValue / filterArrSum;
                          let wt = diff * ratio
                          w._sliderValue = oldFound._sliderValue + wt;
                          
                          console.log('pratik delete check', w.ticker, oldFound._sliderValue, diff, ratio, wt, w._sliderValue)
                        }
                      }
                      else if(buildportfolio.thematicTab.selectedFunds[l.query].length < oldThematicTab.selectedFunds[l.query].length
                              && oldSelectedfunds && oldSelectedfunds[l.query] && oldSelectedfunds[l.query].length > selectedFunds.length) {
                        //delete
                        //_.differenceBy([{ 'x': 2 }, { 'x': 1 }], [{ 'x': 1 }], 'x');
                        // old , new
                        let diffObj = _.differenceBy(oldThematicTab.selectedFunds[l.query], buildportfolio.thematicTab.selectedFunds[l.query], 'ticker');
                        if(diffObj.length){
                          let filterArrSum = _.sumBy(oldThematicTab.selectedFunds[l.query].filter(o => o.ticker != diffObj[0].ticker),'_sliderValue');
                          // _.sumBy(objects, 'n');
                          let diff = diffObj[0]._sliderValue;
                          let ratio = oldFound._sliderValue / filterArrSum;
                          let wt = diff * ratio
                          w._sliderValue = oldFound._sliderValue + wt;
                          // console.log('pratik delete', diffObj, w.ticker, w._sliderValue)
                        }
                      }
                      else{
                        console.log('pratik else')
                        w._sliderValue = l.sliderValue/buildportfolio.thematicTab.selectedFunds[l.query].length;
                      }
                    }
                  }
                }
              }
            });
            //selected funds
            if(l.query && l.sliderValue != oldThematicData.find((o) => o.query === l.query).sliderValue){
              const diff = l.sliderValue - oldThematicData.find((o) => o.query === l.query).sliderValue
              if (typeof buildportfolio.thematicTab.selectedFunds[l.query] !== 'undefined') {
                const getRowDataSliderValue = buildportfolio.thematicTab.data.find((t) => t.query === l.query);
                const selF = buildportfolio.thematicTab.selectedFunds[l.query];
                if (selF && typeof selF !== 'undefined') {
                  buildportfolio.thematicTab.selectedFunds[l.query].map((c) => {
                    if(buildportfolio.thematicTab.selectedFunds[l.query].length == 1) {
                      // console.log('sid43');
                      c._sliderValue = getRowDataSliderValue.sliderValue/selF.length;
                    }
                    else if(buildportfolio.thematicTab.selectedFunds[l.query].length > 1){
                      // console.log('sid44');
                      //diff
                      //ratio = 
                      //wt
                      // console.log('sid322')
                      const oldFound = oldThematicTab.selectedFunds[l.query].find((o) => o.ticker === c.ticker);
                      let ratio = oldFound._sliderValue / oldThematicData.find((o) => o.query === l.query).sliderValue;
                      let wt = diff * ratio;
                      c._sliderValue = oldFound._sliderValue + wt;
                      // console.log('sid445',diff, ratio, wt, oldFound, oldThematicData.find((o) => o.query === l.query));
                    }
                    
                  });
                }
              }
            }
            else if(l.query && l.sliderValue == oldThematicData.find((o) => o.query === l.query).sliderValue && l.query == forQuery){
              if (typeof buildportfolio.thematicTab.selectedFunds[l.query] !== 'undefined') {
                const getRowDataSliderValue = buildportfolio.thematicTab.data.find((t) => t.query === l.query);
                const selF = buildportfolio.thematicTab.selectedFunds[l.query];
                if (selF && typeof selF !== 'undefined') {
                  buildportfolio.thematicTab.selectedFunds[l.query].map((c) => {
                    // console.log('sid45');
                    const oldFound = oldThematicTab.selectedFunds[l.query].find((o) => o.ticker === c.ticker);
                    if (c && typeof c !== 'undefined') {
                      c._sliderValue = getRowDataSliderValue.sliderValue/selF.length;
                      if(buildportfolio.thematicTab.selectedFunds[l.query].length == 1){
                        // console.log('pratik 1')
                        c._sliderValue = getRowDataSliderValue.sliderValue/selF.length;
                        // y._sliderValue = l.sliderValue/buildportfolio.thematicTab.selectedFunds[l.query].length; 
                      }
                      else if(buildportfolio.thematicTab.selectedFunds[l.query].length >= oldThematicTab.selectedFunds[l.query].length 
                              && oldThematicTab.selectedFunds[l.query].find((o) => o._sliderValue === 0)
                              && oldSelectedfunds && oldSelectedfunds[l.query] && oldSelectedfunds[l.query].length < selectedFunds.length) {
                        //add
                        // if fund == 0 add 10% diff
                        if(oldFound._sliderValue == 0){
                          c._sliderValue = (10*l.sliderValue)/100;
                        }
                        else{
                          let diff = -(10*l.sliderValue)/100;
                          let ratio = oldFound._sliderValue / oldThematicData.find((o) => o.query === l.query).sliderValue;
                          let wt = diff * ratio
                          c._sliderValue = oldFound._sliderValue + wt;
                        }
                        console.log('pratik add', c.ticker, c._sliderValue)
                      }
                      else if(buildportfolio.thematicTab.selectedFunds[l.query].length >= oldThematicTab.selectedFunds[l.query].length 
                        && !oldThematicTab.selectedFunds[l.query].find((o) => o._sliderValue === 0)
                        && oldSelectedfunds && oldSelectedfunds[l.query] && oldSelectedfunds[l.query].length > selectedFunds.length) {
                        //delete check
                        //_.differenceBy([{ 'x': 2 }, { 'x': 1 }], [{ 'x': 1 }], 'x');
                        // old , new
                        let diffObj = _.differenceBy(oldSelectedfunds[l.query], selectedFunds, 'ticker');
                        if(diffObj.length){
                          let filterArrSum = _.sumBy(oldSelectedfunds[l.query].filter(o => o.ticker != diffObj[0].ticker),'_sliderValue');
                          // _.sumBy(objects, 'n');
                          let diff = diffObj[0]._sliderValue;
                          let ratio = oldFound._sliderValue / filterArrSum;
                          let wt = diff * ratio
                          c._sliderValue = oldFound._sliderValue + wt;
                          
                          console.log('pratik delete check', c.ticker, oldFound._sliderValue, diff, ratio, wt, c._sliderValue)
                        }
                      }
                      else if(buildportfolio.thematicTab.selectedFunds[l.query].length < oldThematicTab.selectedFunds[l.query].length) {
                        //delete
                        //_.differenceBy([{ 'x': 2 }, { 'x': 1 }], [{ 'x': 1 }], 'x');
                        // old , new
                        let diffObj = _.differenceBy(oldThematicTab.selectedFunds[l.query], buildportfolio.thematicTab.selectedFunds[l.query], 'ticker');
                        if(diffObj.length){
                          let filterArrSum = _.sumBy(oldThematicTab.selectedFunds[l.query].filter(o => o.ticker != diffObj[0].ticker),'_sliderValue');
                          // _.sumBy(objects, 'n');
                          let diff = diffObj[0]._sliderValue;
                          let ratio = oldFound._sliderValue / filterArrSum;
                          let wt = diff * ratio
                          c._sliderValue = oldFound._sliderValue + wt;
                          
                          console.log('pratik delete', c.ticker, oldFound._sliderValue, diff, ratio, wt, c._sliderValue)
                        }
                      }
                      else{
                        console.log('pratik else', buildportfolio.thematicTab.selectedFunds[l.query].length , oldThematicTab.selectedFunds[l.query].length)
                        c._sliderValue = l.sliderValue/buildportfolio.thematicTab.selectedFunds[l.query].length; 
                      }
                    }
                  });
                }
              }
            }
          }
        });

        thematicTab._isCustomInput=false
        if (setBuildPortfolioState) {
          setBuildPortfolioState({
            oldHeaderMenuState,
            oldBondData,
            oldEquityData,
            oldThematicData,
            oldEquityTab,
            oldBondTab,
            oldThematicTab,
            thematicTab: {
              ...thematicTab,
              data: buildportfolio.thematicTab.data,
              selectedFunds: buildportfolio.thematicTab.selectedFunds,
            },
            tabHeaderMenu: topTabHeaderMenu,
          });
        }

        // if (setBuildPortfolioState) {
        //   setBuildPortfolioState({
        //     thematicTab,
        //   });
        //   this.calCulateWeight(forQuery)
        // }

      }
    }

  }

  callback = (key) => {
    this.setState({ activeTabPane: key });
  }

  getCheckedListArray = (item) => {
    const { thematicTab } = this.props;
    const { selectedFunds } = thematicTab;
    if (selectedFunds) {
      const label = item.label || item;
      const arr = selectedFunds[label] || []
      return arr;
    }
    return [];
  }

  removeSelectedThemes = (event, fund, forQuery) => {
    event.stopPropagation();
    // console.log('deleting funds for ==>', forQuery);
    const { thematicTab, setBuildPortfolioState, topTabHeaderMenu } = this.props;
    if (forQuery && fund && typeof fund.ticker !== 'undefined') {
      if (thematicTab.selectedFunds[forQuery]) {
        const oldSelectedfunds = JSON.parse(JSON.stringify(thematicTab.selectedFunds));
        const filteredArray = thematicTab.selectedFunds[`${forQuery}`];
        const selectedFundsArray = filteredArray.filter((h) =>{
          if (h.ticker !== fund.ticker) {
            delete h.selected;
            return h;
          }
        });
        // thematicTab.selectedFunds[forQuery] = selectedFundsArray;

        // // after deletion updating 'thematic.data' --->>> sliderValue;
        // const selectedThemesFunds = thematicTab.selectedFunds;
        // let currentData = thematicTab.data;
        // let updatedArray = [];
        // const thematicHeader = topTabHeaderMenu.find((x) => x.key === 'thematicTab');
        //
        // // console.log('before updating currentData ==>', currentData);
        //
        // if (Object.keys(selectedThemesFunds).length > 0) {
        //   updatedArray = Object.keys(selectedThemesFunds).map((v, index) => {
        //     if (selectedThemesFunds[v] && selectedThemesFunds[v].length > 0) {
        //       if (currentData.length > 0) {
        //         const found = currentData.find((g) => g.query === v);
        //         // if (currentData.filter((g) => g.query !== v) && currentData.filter((g) => g.query !== v).length > 0) {
        //         //   currentData.forEach((gV, i) => {
        //         //     if (gV.query !== v) {
        //         //       gV.selected = false;
        //         //     }
        //         //   });
        //         // }
        //         if (found && typeof found !== 'undefined') {
        //           found.selected = true;
        //           return found;
        //         }
        //       }
        //     }
        //   }).filter((k) => typeof k !== 'undefined');
        //
        //   // console.log('currentData----->>', currentData);
        //   // updatedArray will selected Funds -> Themes Name header details
        //   // console.log('updatedArray----->>', updatedArray);
        //
        //   if (updatedArray.length === 0) {
        //     if (this.state.searchQuery !== '') {
        //       updatedArray = currentData.filter((h) => h.query === this.state.searchQuery);
        //     }
        //   }
        //
        //   // updatedArray.forEach((r) => {
        //   //   r.sliderValue = thematicHeader.sliderValue/updatedArray.length;
        //   //   r.sliderValue = parseFloat(r.sliderValue.toFixed(1));
        //   // });
        //
        //   if (updatedArray.length !== currentData.length) {
        //     // updatedArray will selected Funds -> Themes Name header details
        //     // currentData is state before filtering out
        //     // console.log(updatedArray);
        //     const filterOut = _.differenceBy(currentData, updatedArray, 'query');
        //     // filterOut.map((f, i) => {
        //     //   f.sliderValue = thematicHeader.sliderValue/filterOut.length;
        //     //   f.sliderValue = parseFloat(f.sliderValue.toFixed(1));
        //     // });
        //
        //     // console.log(filterOut);
        //
        //     // console.log(updatedArray);
        //     updatedArray = _.uniqBy([...updatedArray, ...filterOut], 'query'); // do not change this!
        //   }
        //
        //   // if (updatedArray.filter((n) => (typeof n.selected !== 'undefined' && n.selected)).length > 0) {
        //   //   updatedArray.filter((n) => (typeof n.selected !== 'undefined' && n.selected)).forEach((p) => {
        //   //     if (p) {
        //   //       p.sliderValue = thematicHeader.sliderValue/updatedArray.filter((d) => (typeof d.selected !== 'undefined' && d.selected)).length;
        //   //     }
        //   //   });
        //   //   console.log(updatedArray);
        //   // }
        //
        // }
        //
        // // console.log(updatedArray);
        // thematicTab.data = updatedArray;

        this.getSelectedFunds({
          selectedFunds: selectedFundsArray,
          forQuery,
          oldSelectedfunds,
        });
      }

      // if (setBuildPortfolioState) {
      //   setBuildPortfolioState({
      //     thematicTab,
      //   });
      // }
    }
  }

  handleClose = (event, currentKey) => {
    event.stopPropagation();
    if (this.state.activeTabPane !== currentKey) {
      this.setState({ activeTabPane: currentKey })
    }
  }

  getPaneHeader = (data, currentKey) => {
    if(data.forQuery === 'USA'){
      data.placeholder = 'US';
    }else if(data.forQuery === 'Developed Markets'){
      data.placeholder = 'Developed Market';
    }else{
      data.placeholder = 'theme';
    }
    const { activeTabPane } = this.state;
    const { label, sliderValue, forQuery, placeholder, query } = data;
    const { thematicTab, globalTabHeaderMenu } = this.props;
    const thematicHeader = globalTabHeaderMenu.find((x) => x.key === 'thematicTab');
    const { selectedFunds } = thematicTab;

    // console.log('this.props.showSearchedQuery---->', this.props.showSearchedQuery, 'this.state.searchQuery-----> ', this.state.searchQuery, this.props.requestedQuery);
    return (
      <div className="head-wrap" onClick={(e) => this.handleClose(e, currentKey)}>
        <div className="title">
          <span>Themes</span><span className="slider-value">{parseFloat(thematicHeader.sliderValue.toFixed(2))}%</span>
        </div>
        <InputSearch
          {...this.props}
          placeholder={`Which Thematic Funds do you want to invest in?`}
          defaultValue={this.props.showSearchedQuery ? this.props.requestedQuery : ''}
          onClick={(e) => this.handleClose(e, currentKey)}
          onSearch={value => this.onSearch(value, forQuery, 'thematicTab')}
          onChangeSearch={e => this.onChangeSearch(e, forQuery)}
          onBlur={() => this.onBlurDo(forQuery)}
        />
        {(Object.keys(selectedFunds).length === 0) && (
            <div className="pending-text">Pending Allocation</div>
          )
        }
        {Object.keys(selectedFunds).map((v) => {
          return selectedFunds[v].filter((o) => o !== '' && o.ticker).map((y, index) => {
            return (
              <div key={`i-${index}`} className="selected-funds-tag">
                {y.ticker}
                <i className="far fa-times" onClick={(e) => this.removeSelectedThemes(e, y, v)} />
              </div>
            )})
          })
        }
      </div>
    );
  }

  onSearch = (value, forQuery, forTab) => {
    const { searchQuery } = this.state;
    const qq = this.state.searchQuery === '' ? forQuery : this.state.searchQuery;
    const forQueryCapitalCase = convertToCapitalCase(qq); // dont change this!
    if (value) {
      // this.setState({
      //   searchQuery: value,
      // }, () => {
        const { searchForNewQueryRequest } = this.props;
        if (searchForNewQueryRequest) {
          addEventToAnalytics('Builder Theme Search','Builder Theme Search','BUILDER_THEME_SEARCH',{ query: convertToCapitalCase(value) },false);
          searchForNewQueryRequest({
            query: 'pf_builder_query',
            q: convertToCapitalCase(value),
            forQuery: forQueryCapitalCase,
            forTab,
            mode: 'new',
          });
        }
      // });
    }
  }

  onBlurDo = (forQuery) => {
    if (this.state.searchQuery === '') {
     // this.setState({
     //   searchQuery: forQuery,
     // });
   }
  }

  onChangeSearch = (value, forQuery) => {
    // const value = e.target.value;
    // this.setState({
    //   searchQuery: value,
    // });
  }

  render() {
    const { tickerList, tickerLoading, customSearchTickerData, weightingMethodology, thematicTab, noResultFound, buildportfolio, getUpdatedThematicTabData } = this.props;
    const { tabHeaderMenu } = this.state;

    const checkedListArray = (item) => {
      const arr = this.getCheckedListArray(item);
      if (arr && arr.length) {
        return arr.map((v) => v.ticker);
      } else {
        return [];
      }
    }

    //
    // let dataList = tabHeaderMenu.filter((v) => v.mode === 'initial');
    // let newList = tabHeaderMenu.filter((v) => v.mode === 'new');
    //
    // // console.log('this.props.requestedQuery -->', this.props.requestedQuery);
    // // console.log('this.state.searchQuery -->',this.state.searchQuery);
    // // console.log('noResultFound', noResultFound);
    // // console.log(newList);
    //
    // if (newList.length > 0) {
    //   if (!noResultFound) {
    //     if (newList.filter((h) => h.query === this.props.requestedQuery).length) {
    //       dataList = newList.filter((h) => h.query === this.props.requestedQuery);
    //     }
    //   } else {
    //     // console.log(newList);
    //     if (this.state.searchQuery !== this.props.requestedQuery && this.state.searchQuery !== '') {
    //       dataList = newList.filter((h) => h.query === this.state.searchQuery);
    //     } else {
    //       // no result found .. therefore selecting first element.
    //       // console.log(newList[newList.length-1], );
    //       dataList = [newList[newList.length-1]];
    //     }
    //   }
    // }
    //
    // console.log(dataList);
    //
    // // assign slidervalue to each fund in list;
    // // for specific row e.g: USA row.
    // dataList.map((l) => {
    //   if (l.query) {
    //     l.result.funds.map((w) => {
    //       w._sliderValue = 0;
    //       if (thematicTab.selectedFunds && Object.keys(thematicTab.selectedFunds).length > 0) {
    //         if (thematicTab.selectedFunds[l.query] && typeof thematicTab.selectedFunds[l.query] !== 'undefined') {
    //           const found = thematicTab.selectedFunds[l.query].find((o) => o.ticker === w.ticker);
    //           if (found && typeof found !== 'undefined' && (found.ticker === w.ticker)) {
    //             w._sliderValue = l.sliderValue ? l.sliderValue/thematicTab.selectedFunds[l.query].length : 0;
    //           }
    //         }
    //       }
    //     });
    //     // if (typeof buildportfolio.thematicTab.selectedFunds[l.query] !== 'undefined') {
    //     //   const getRowDataSliderValue = buildportfolio.thematicTab.data.find((t) => t.query === l.query);
    //     //   const selF = buildportfolio.thematicTab.selectedFunds[l.query];
    //     //   if (selF && typeof selF !== 'undefined') {
    //     //     buildportfolio.thematicTab.selectedFunds[l.query].map((c) => {
    //     //       c._sliderValue = getRowDataSliderValue.sliderValue/selF.length;
    //     //     });
    //     //   }
    //     // }
    //   }
    //   console.log(l.query, l.sliderValue);
    // });
    //
    // console.log(buildportfolio.thematicTab.selectedFunds);
    // if (getUpdatedThematicTabData) {
    //   getUpdatedThematicTabData(dataList);
    // }
    return (
      <React.Fragment>
        <Row className="thematic-radio-funds-design-container">
          {tabHeaderMenu.map((item, index) => {
            return (
              <div key={`${index+1}`} className="thematic-radio-funds-design-head-container">
                {this.getPaneHeader(item, `${index+1}`)}
                <div className="TRFD-content-container">
                  <ListView
                    noResultFound={noResultFound}
                    staticCard={true}
                    tickerList={tickerList}
                    tickerLoading={tickerLoading}
                    weightingMethodology={weightingMethodology}
                    data={item.result}
                    query={item.newQuery}
                    newQuery={noResultFound ? this.props.requestedQuery : item.newQuery}
                    sliderValue={item.sliderValue}
                    ticker={item.ticker}
                    customEdit={item.customEditScreen}
                    customSearchTickerData={item.customSearchTickerData}
                    deleteCard={this.deleteCard}
                    getSelectedFunds={this.getSelectedFunds}
                    selectedFunds={thematicTab.selectedFunds}
                    checkedListArray={checkedListArray(item.newQuery)}
                    customSearchTickerCallback={() => this.getTickerData()}
                  />
                </div>
              </div>
            )
          })
        }
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ buildportfolio, loading }) => ({
  buildportfolio,
  globalTabHeaderMenu: buildportfolio.tabHeaderMenu,
  data: buildportfolio.thematicTab.data,
  noResultFound: buildportfolio.thematicTab.noResultFound,
  requestedQuery: buildportfolio.thematicTab.newQuery,
  tickerList: buildportfolio.tickerList,
  customSearchTickerData: buildportfolio.thematicTab.customSearchTickerData,
  tickerLoading: createLoadingSelector(['CUSTOM_TICKER', 'TICKER_DATA'])({ loading }),
})

const mapDispatchToProps = {
  tickerDataRequest,
  deleteCustomCard,
  setBuildPortfolioState,
  searchForNewQueryRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThematicRadioFunds);

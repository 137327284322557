import React from 'react';

import {
  ComposedChart,
  // ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ReferenceLine,
  CartesianGrid,
  Tooltip,
  Legend,
  Surface,
  Symbols,
  // foreignObject,
  // Dot,
  Label,
  Line,
} from 'recharts';

// import { D3Colors20 } from 'DataSet';
// import { SectorColors } from '../../../data/Colors';
import { fixedValue, splitDataForTicks } from './Utils';
import _ from 'lodash';

let ticksCount = {
  x: 6,
  y: 4
};

let maxTicksCount = {
  x: 8,
  y: 4
}

const _RenderScatterChart = (props) => {
  let {
    chartTitle,
    chartData,
    showHorizontalGridLines,
    axisLabels: customLabel,
    axisUnits: customUnits,
    legend,
    tickConfig
  } = props;
  let data = chartData;
  tickConfig = tickConfig || {};
  // console.log(data);
  const domain = getDomain(data, tickConfig);
  const ticks = getTicks(domain, ticksCount.x, ticksCount.y);
  const axisLabels = {
    x: customLabel ? customLabel[0] : '',
    y: customLabel ? customLabel[1] : '',
  };
  const axisUnits = {
    x: customUnits ? customUnits[0] : '',
    y: customUnits ? customUnits[1] : '',
  };
  const { percentileData, percentileKeys, axisTopRefLine } = props;
  if(percentileKeys) {
    // const percentileLineData = getPercentileLineDataOld(ticks, domain, percentileKeys);
    const percentileLineData = getPercentileLineData(domain, percentileData);
    data = [...data, ...percentileLineData];
  }
  if(!data.length) return '';
  return(
    <div className="pa-chart-wrapper">
      <h5 className="chart-header">
        {chartTitle}
      </h5>
      <div className="pa-scatter-chart-wrapper">
        <ComposedChart
          width={950}
          height={250}
          data={data}
          margin={{
            top: 20,
            right: 25,
            bottom: 25,
            left: 10,
          }}
        >
          <XAxis
            type="number"
            dataKey="x"
            name={axisLabels.x}
            unit={axisUnits.x}
            // tickSize={-8}
            // tickMargin={25}
            tickCount={4}
            stroke={'#c3c3c3'}
            shapeRendering="crispEdges"
            // domain={domain.xDomain}
            // ticks={ticks.xTicks}
            tickFormatter={tickFormatter}
          >
            <Label
              value={axisLabels.x}
              position="bottom"
            />
          </XAxis>
          <YAxis
            type="number"
            dataKey="y"
            name={axisLabels.y}
            unit={axisUnits.y}
            // tickSize={-8}
            // tickMargin={15}
            // tickCount={4}
            stroke={'#c3c3c3'}
            shapeRendering="crispEdges"
            // domain={domain.yDomain}
            // ticks={ticks.yTicks}
            tickFormatter={tickFormatter}
          >
            <Label
              value={axisLabels.y}
              position="insideLeft"
              angle={-90}
              offset={-1}
              style={{ textAnchor: 'middle' }}
            />
          </YAxis>
          <Tooltip cursor={{ strokeDasharray: '3 3' }} />
          {showHorizontalGridLines && (
            <CartesianGrid
              stroke={'#c3c3c3'}
              vertical={false}
              shapeRendering="crispEdges"
            />
          )}
          {axisTopRefLine && (
            <ReferenceLine
              y={domain.yDomain[1]}
              stroke={'#c3c3c3'}
              shapeRendering="crispEdges"
            />
          )}
          {percentileKeys && percentileKeys.map((item, index) => {
            const textValue = percentileData.find(val => val.name === item);
            return(
              <Line
                type="monotone"
                dataKey={item}
                stroke={'#c3c3c3'}
                strokeWidth="1"
                strokeDasharray="3 3"
                legendType="none"
                dot={false}
                activeDot={false}
                label={(
                  <CustomizedLineLabel
                    textValue={textValue ? fixedValue(textValue.value, 1) : null}
                  />
                )}
                isAnimationActive={false}
              />
            );
          })}
          {data.map((item, index) => {
            const shape = item.name === 'Benchmark' ? 'diamond' : 'circle';
            if(!item.data ||
              (!item.data[0].x && item.data[0].x !== 0) ||
              (!item.data[0].y && item.data[0].y !== 0) ||
              item.percentileValue) return;
            return(
              <Scatter
                key={index}
                name={item.name}
                data={item.data}
                fill={item.color}
                shape={shape}
              />
            );
          })}
          {legend && (
            <Legend
              content={renderLegend}
              layout="vertical"
              verticalAlign="bottom"
              align="right"
            />
          )}
        </ComposedChart>
      </div>
    </div>
  )
}

export default React.memo(_RenderScatterChart, areEqual);

const renderLegend = (props) => {
  const { payload } = props;
  const payloadChunk = _.chunk(payload, 5);
  return (
    <div className="scatter-legend-wrapper">
      <div className="all-legends">
        {payloadChunk.map((item, i) => {
          return(
            <ul className="scatter-legend" key={i}>
              {
                item.map((entry, index) => (
                  <li className="legend-item" key={`item-${index}`}>
                    <span>
                      <Surface width={10} height={10} viewBox="0 0 10 10">
                        <Symbols
                          cx={5}
                          cy={5}
                          type={entry.payload.shape}
                          size={50}
                          fill={entry.color}
                        />
                      </Surface>
                    </span>
                    <span>
                      {entry.value}
                    </span>
                  </li>
                ))
              }
            </ul>
          )
        })}
      </div>
    </div>
  );
}

const getDomain = (data, tickConfig) => {
  let xData = [], yData =[], xMin, xMax, yMin, yMax;
  data.forEach(item => {
    item.data.forEach(value => {
      xData.push(Number(value.x));
      yData.push(Number(value.y));
    })
  })
  xMin = Math.min(...xData);
  xMax = Math.max(...xData);
  yMin = Math.min(...yData);
  yMax = Math.max(...yData);
  return {
    xDomain: getMaxMinValue(xMin, xMax, 'x', tickConfig),
    yDomain: getMaxMinValue(yMin, yMax, 'y', tickConfig),
  }
}

const getMaxMinValue = (lowerBound = 0, upperBound = 0, type, tickConfig) => {
  let range = getRange(lowerBound, upperBound, type, tickConfig);
  let {lowerLimit, upperLimit} = range;
  lowerLimit = lowerLimit || 0;
  upperLimit = upperLimit || 0;
  return [lowerLimit, upperLimit];
}

const getRange = (lowerBound, upperBound, type, tickConfig) => {
  let maxTicks = type === 'x' ? ticksCount.x : ticksCount.y, range, rangeOffset, tickOffset, tickSpacing, lowerLimit, upperLimit;
  const { allowDecimals = true } = tickConfig;
  let lowerOffset = allowDecimals ? lowerBound : Math.floor(lowerBound);
  let upperOffset = allowDecimals ? upperBound : Math.ceil(upperBound);
  if(lowerOffset === upperOffset){
    if(lowerOffset === 0 && upperOffset === 0){
      lowerOffset = -1;
      upperOffset = 1;
    }else{
      lowerOffset = allowDecimals ? lowerOffset - 0.25 : lowerOffset - 1;
      upperOffset = allowDecimals ? upperOffset + 0.25 : upperOffset + 1;
    }
  }
  range = Number(upperOffset) - Number(lowerOffset);
  rangeOffset = 0.25 *  Math.abs(range);
  lowerLimit = Number(lowerBound) - rangeOffset;
  upperLimit = Number(upperBound) + rangeOffset;
  if(!allowDecimals){
    lowerLimit = Math.floor(lowerLimit);
    upperLimit = Math.ceil(upperLimit);
    let tempRange = Number(upperLimit) - Number(lowerLimit);
    let foundTickCount = false;
    for(let i = maxTicksCount[type]; i >= 4 ; i--){
      const offsetTicks = i - 1;
      let tickSpacing = tempRange / offsetTicks;
      if(tickSpacing % 1 === 0) {
        ticksCount[type] = i;
        foundTickCount = true;
        break;
      }
    }
    if(!foundTickCount){
      const rangeBasedTicks = getRangeBasedTicks(lowerOffset, upperOffset, ticksCount[type]);
      lowerLimit = rangeBasedTicks.lowerLimit;
      upperLimit = rangeBasedTicks.upperLimit;
    }
  }
  return {
    lowerLimit: Number(lowerLimit.toFixed(1)),
    upperLimit: Number(upperLimit.toFixed(1)),
  };
}

const getRangeBasedTicks = (lowerOffset = 0, upperOffset = 0, maxTicks = 1) => {
  let tempRange = Number(upperOffset) - Number(lowerOffset);
  let tempRangeOffset = 0.25 * Math.abs(tempRange);
  let lowerLimit = Math.floor(lowerOffset - tempRangeOffset);
  let upperLimit = Math.ceil(upperOffset + tempRangeOffset);
  let range = Number(upperLimit) - Number(lowerLimit);
  let tickOffset = maxTicks - 1;
  let tickSpacing = Math.ceil(range / tickOffset) * tickOffset;
  upperLimit = lowerLimit + tickSpacing;
  return { lowerLimit, upperLimit };
}

const getTicks = (domain, xTicksCount, yTicksCount) => {
  return {
    xTicks: splitDataForTicks(...domain.xDomain, xTicksCount, 1),
    yTicks: splitDataForTicks(...domain.yDomain, yTicksCount, 1),
  }
}

const tickFormatter = (value) => {
  return Number(Number(value).toFixed(1));
}

const getPercentileLineData = (domain, percentileData) => {
  const { xDomain, yDomain } = domain;
  let out = [];
  // b=y-mx || y=mx+b || x=(y-b)/m
  percentileData.forEach(item => {
    let x = 0, y = 0, b = 0, m = parseFloat(item.value);
    const coordinates = [
      {
        x: xDomain[0],
        [item.name]: (m * xDomain[0]) + b,
      },
      {
        [item.name]: yDomain[1],
        x: (yDomain[1] - b) / m,
      }
    ];
    // ignore if newx does not exist in the range of x
    if(!(coordinates[1].x >= xDomain[0] && coordinates[1].x <= xDomain[1])){
      coordinates[1] = {
        x: xDomain[1],
        [item.name]: (m * xDomain[1]) + b,
      }
    };
    out.push(...coordinates);
  });
  return out;
}

const getPercentileLineDataOld = (ticks, domain, percentileKeys) => {
  const { xDomain, yDomain } = domain;
  const { xTicks, yTicks } = ticks;
  let out = [];
  // This logic works under the assumption that
  // the number of ticks on x-axis is 6 and y-axis is 4
  // tweak the logic when the number of ticks varies
  const coordinates = (item, index) => {
    let data = [];
    if(index === 0){
      data = [
        {x: xDomain[0], [item]: yDomain[0]},
        {x: xTicks[5], [item]: yTicks[2]},
      ];
    }else if(index === 1){
      data = [
        {x: xDomain[0], [item]: yDomain[0]},
        {x: xTicks[2], [item]: yTicks[3]},
      ];
    }else if(index === 2){
      data = [
        {x: xDomain[0], [item]: yDomain[0]},
        {x: xTicks[1], [item]: yTicks[3]},
      ]
    }
    return data;
  }
  percentileKeys.forEach(item => {
    const index = item === 33 ? 0 : item === 50 ? 1 : 2;
    out.push(...coordinates(item, index));
  });
  return out;
}

const CustomizedLineLabel = (props) => {
  const {
    x, y, stroke, value, textValue,
  } = props;
  // Condition to remove labels from starting of the line
  // Hard coding x and y checks to 68 and 195 to remove the
  // first label change this if the x and y value of first
  // point changes
  if((x === null || y === null) || x === 68) return null;
  return (
    <text
      x={x}
      y={y}
      dx={12}
      dy={4}
      fill={stroke}
      textAnchor="middle"
      className="line-label"
    >
      {textValue}
    </text>
  )
}

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  return prevProps.chartTitle === nextProps.chartTitle ? true : false;
}

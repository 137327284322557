const demoConfig = {
    apiBaseUrl: 'https://api-demo.magnifi.com/main/api/', // 'https://api-demo.magnifi.com/profile/api',
    tdAmeritradeCallbackUrl:
      'https://auth.tdameritrade.com/oauth?client_id=RFAVCVHFFQXHBK5AQ5RX83GM1DEO0DUW@AMER.OAUTHAP&response_type=code&redirect_uri=https://advisor.magnifi.com/callbacks/td/td-auth-code',
    tradeItOrigin: 'https://www.trade.it',
    tradingTicketorigin: 'https://ems.tradingticket.com',
    googleAnalyticsCode: '',
    googleTaManagerCode: '',
    googleGTAGUAConfig: {},
    universeShare: true,
    heapAnalyticsCode: '2082602943',
    krakenId: '5df2a86d36c054a5e077c7e3',
    relatedInsight: true,
    impressionTag: true,
    tdCallbackPath: '/callbacks/td',
    sponsoredTicker: true,
    PtarmiganTag: false,
    advisorRetailRedirect: true,
    advisor_redirect: 'https://app.magnifi.com',
    retail_redirect: 'https://magnifi.com',
    stripePublishableKey: 'pk_live_Fbw7tmcssU5kLiVpqndAvr5F00rkoboPsx',
    version: '2.0.0',
    sentryEnv: "production",
    appleSSORedirectURL: "https://magnifi.com/register",
    transferRequest: false,
    googleManagerTag: true,
    vendorConversionPixel: true,
    REACT_APP_OKTA_IDP: '0oa1m1w5if9xYSwaf697',
    REACT_APP_OKTA_ISSUER: 'magnifi.okta.com',
    REACT_APP_OKTA_CLIENT_ID: '0oa1m1yl1txz8Zyyn697',
    feedbackGooglesheetKey: 'https://script.google.com/macros/s/AKfycbwFxb9keSNi0njT5NLptIy8T8U9Oy-Ksgxjcg6dGQRd-OyZ1D-j/exec',
    segmentWriteKey: '3tDu20xKNK2D53Ij8QH6FaQZNEjnB8ja',
    avatarURL: 'https://avatar.magnifi.com/',
    googleClientId: '326538060414-5vqrgaosddu857h2siom011r796tfl4j.apps.googleusercontent.com',
  }
  
  export default demoConfig
  
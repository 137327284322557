import React from 'react'
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, ButtonGroup, Form, FormGroup, Label, Input } from 'reactstrap'
import { uploadClientListV2, sendclientList, clientListRequest } from 'actions/clientportfolioV2';
import { PrimaryButton } from 'shared-components'
import PfListTable from './PfListTable';
import { checkForDuplicateClients } from '../../Utils';

class PfListPreview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPfListPreview: true,
      selectedClient: this.props.pfList ? this.props.pfList[0] : ''
    }
  }

  componentWillUnmount(){
    if(this.props.clientUploadModalToggle){
      this.props.clientUploadModalToggle(false, false)
    }
  }

  handleSubmit = () => {
    const {sendclientList, clientListRequest, pfList, showUpdateColumnInPreview, uploadedFrom, callback, showLoader} = this.props;
    const { selectedClient } = this.state;

    // console.log('--- pfList ---> ', pfList);

    const upperCasePfList = pfList.map(list => {
      return {
        ...list,
        holdings: list.holdings.map(holding => {
          return { ...holding, ticker: holding.ticker.toUpperCase()}
        })
      }
    })

    // console.log(' --- upperCasePfList ---> ', upperCasePfList);

    sendclientList({
      pfList: upperCasePfList,
      filterClients: showUpdateColumnInPreview,
      callback,
      selectedClient
    });
    showLoader && showLoader();
    if(pfList){
      this.setState({showPfListPreview: false})
    }
  }

  updateSelectedClient = (clientDetails) => {
    const { pfList } = this.props;
    let selectedClient = pfList.find(existingClient => existingClient.account_number == clientDetails.account_number)
    if(selectedClient){
      selectedClient.updateData = !selectedClient.updateData;
      let updatedPfList = pfList.map(client => {
        if(selectedClient.account_number == client.account_number){
          return selectedClient
        }else{
          return client
        }
      })
      this.props.uploadClientListV2(updatedPfList);
    }
  }

  updateAllClients = (val) => {
    const { pfList } = this.props;
    let updatedPfList = pfList.map(client => {
      if(typeof client.updateData != 'undefined'){
        client.updateData = val
      }
      return client;
    })
    this.props.uploadClientListV2(updatedPfList);
  }

  clearData = () => {
    this.props.uploadClientListV2({
      uploadedClientList: [],
      showUpdateColumnInPreview: false
    })
    this.props.clientUploadModalToggle(true, false)
  }

  clearRow = (id) => {
    const { uploadedClientList, clientList } = this.props;
    const updatedClientList = uploadedClientList.filter((i) => i.refID != id);
    // Check for duplicate clients to show/hide update column in preview
    const duplicateClients = updatedClientList.filter(item => item.updateData)
    const showUpdateColumnInPreview = duplicateClients.length > 0;
    // Condition to handle the case where the user deletes the selected client
    if(this.state.selectedClient.refID == id && updatedClientList.length > 0){
      this.setState({
        selectedClient: updatedClientList[0]
      })
    }
    this.props.uploadClientListV2({
      uploadedClientList: updatedClientList,
      showUpdateColumnInPreview
    })
  }

  setSelectedClient = (selectedClient) => {
    this.setState({
      selectedClient
    })
  }

  render() {
    const { clientList, showUpdateColumnInPreview, allowSelection } = this.props;
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.showPfListPreview}
          toggle={() => {
            this.setState({showPfListPreview: false})
          }}
          centered
          keyboard={false}
          backdrop="static"
          className="pf-list-sm-light"
          backdropClassName="pf-list-sm-light-modal-backdrop"
          >
          <ModalBody className="ssf-modal__body">
            <i className="fal fa-times cp-modal-close" onClick={this.clearData}></i>
            <div className="pf-list-preview-wrapper">
              <PfListTable {...this.props}
                clearRow={this.clearRow}
                clearData={this.clearData}
                updateSelectedClient={this.updateSelectedClient}
                updateAllClients={this.updateAllClients}
                selectedClient={this.state.selectedClient}
                setSelectedClient={this.setSelectedClient}
              />
              <div className="d-flex w-100 justify-content-center pt-4">
                <ButtonGroup className="cta-wrapper justify-content-left">
                  <PrimaryButton
                    className="btn secondary-btn fill p-1 mr-4 download-modal-btn"
                    type="button"
                    onClick={this.handleSubmit}
                    style={{ minWidth: 120 }}
                  >
                    SUBMIT
                  </PrimaryButton>
                </ButtonGroup>
                <ButtonGroup className="cta-wrapper justify-content-left">
                  <PrimaryButton
                    className="btn secondary-btn p-1 ml-2 download-modal-btn"
                    type="button"
                    onClick={this.clearData}
                    style={{ minWidth: 120 }}
                  >
                    ADD NEW FILE
                  </PrimaryButton>
                </ButtonGroup>
              </div>
              {showUpdateColumnInPreview && (
                <>
                  <div className="foot-note">
                    *Selecting the checkbox will update the existing client information.
                  </div>
                  <div className="foot-note">
                    All remaining uploaded client accounts will be saved and available for enhancement.
                  </div>
                </>
              )}
              {allowSelection && (
                <>
                  <div className="foot-note">
                    Select the client you would want to proceed with to enhancer
                  </div>
                </>
              )}
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ clientportfolioV2: { uploadedClientList } }) => ({
  uploadedClientList
})

const mapDispatchToProps = {
  uploadClientListV2,
  sendclientList,
  clientListRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PfListPreview);

import React, { Component } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import { CustomFormFeedback, PrimaryButton, PasswordRequirement } from 'shared-components';

export default class PasswordResetForm extends Component {

    render() {
        const { formProps } = this.props;

        const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
        } = formProps

        return (

            <Form className="sign-in magnifi-auth-design-container" noValidate={true} onSubmit={handleSubmit}>
                <div className="header-area">
                    <div className="header-label">Change Password</div>
                </div>

                <div className="body-wrapper">
                    <FormGroup className="element-box pos-rel">
                        <Label className="label" htmlFor="password">New Password</Label>
                        <Input
                        type="password"
                        name="password"
                        id="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                            errors.password && touched.password ? 'is-invalid input-area' : 'input-area'
                        }
                        />
                        <CustomFormFeedback formProps={formProps} fieldName="password" />
                    </FormGroup>
                    <FormGroup className="element-box pos-rel">
                        <Label className="label" htmlFor="rePassword">Confirm Password</Label>
                        <Input
                        type="password"
                        name="rePassword"
                        id="rePassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                            errors.rePassword && touched.rePassword ? 'is-invalid input-area' : 'input-area'
                        }
                        />
                        <CustomFormFeedback
                        formProps={formProps}
                        fieldName="rePassword"
                        />
                    </FormGroup>
                    <Row>
                      <Col>
                        <div className="body-wrapper">
                          <FormGroup className="element-box pos-rel">
                            <PasswordRequirement
                              label={'Password Requirements:'}
                              formProps={formProps}
                              fieldName={'password'}
                            />
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>
                    <FormGroup className="cta-wrapper">
                        <PrimaryButton
                            type="submit"
                            className="btn primary-btn"
                            block
                            disabled={isSubmitting}
                        >
                            Set Password
                        </PrimaryButton>
                    </FormGroup>
                </div>
            </Form>
        )
    }
}

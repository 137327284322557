import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, Card, Tabs, Button, Input, AutoComplete } from 'antd';
import { CardBody, Tooltip } from 'reactstrap';
import ItemList from './shared/ItemList';
import ItemCard from './shared/ItemCard';
import { Loader } from './shared/Loader';
import { searchUrl, arrayToHash, CapitalizeEachWord } from 'Utils';
import { CN  } from 'DataSet';

import { SwapOutlined } from '@ant-design/icons';

import AutoCompleteJJ from './AutComplete';
import { setPortfolioReplace, setEnhancementData, setReplaceTicker, addPortfolioEnhanceReplace, deletePortfolioEnhanceReplace, deleteCustomReplaceTicker, setPortfolioState  } from 'actions/portfolio';
import { getPortfolioStats, getInvestmentWatchlistStats, getInvestmentPositionStats } from 'selectors/investment';

const { TabPane } = Tabs;
const { Option } = AutoComplete;

class ResultPage extends Component {

  constructor(props){
    super(props)
    this.state = {
      dataSource: [],
      enhanceReplace : '',
      customReplaceActiveTab: {},
      resetEnhanceFundCheckStatus: false,
      resetCustomAddedFund: false,
      _viewIndex: "1",
    };
  }

  componentDidMount() {
    const { view, resultSet, enhanceTickerFilter} = this.props;
    let wichTabInit = [];
    resultSet.forEach(item => {
      wichTabInit[item.ticker] = 'itemList';
    }) ;
    this.setState({customReplaceActiveTab: wichTabInit});
  }

  onSelectTab = (value) => {
    if(value === '3') {
      if(this.props.allTickers.length == 0) {
        this.props.setReplaceTicker();
      }
    }
  }

  deleteCard(replaceTicker, ticker, query) {
    const { enhReplaceTicker } = this.props;
    const checkTickerPresent = enhReplaceTicker.filter((ft) => ft.replaceTicker === replaceTicker);
    if (checkTickerPresent.length) {
      this.resetReplaceTicker(replaceTicker, ticker, query, 'deleteCard');
    }
    this.props.deleteCustomReplaceTicker({ ticker });
  }

  setCustomActiveTag = (ticker, status) => {
    let customReplaceActiveTab = this.state.customReplaceActiveTab;
    customReplaceActiveTab.ticker = status;
    this.setState({customReplaceActiveTab: customReplaceActiveTab});
  }

  resetCheckBoxFor = (mode) => {
    switch (mode) {
      case 'enhanceFund':
      this.setState({
        resetEnhanceFundCheckStatus: false,
        resetCustomAddedFund: true,
      });
      break;
      case 'customAddedFund':
      this.setState({
        resetEnhanceFundCheckStatus: true,
        resetCustomAddedFund: false,
      });
      break;
      default:
      break;
    }
  }

  resetReplaceTicker = (replaceTicker, ticker, query, calledFrom) => {
    // console.log('calledFrom', calledFrom);
    const {
      view,
      resultSet,
      enhanceTickerFilter,
      addPortfolioEnhanceReplace,
      deletePortfolioEnhanceReplace,
      portfolioFunds,
      marketFunds,
      watchlistFundstats,
      accountFundstats,
      customizedCardData
    } = this.props;
    let customReplaceActiveTab = this.state.customReplaceActiveTab;
    customReplaceActiveTab.ticker = 'customReplace';
    this.setState({ enhanceReplace: replaceTicker, customReplaceActiveTab: customReplaceActiveTab }, () => {
      const filterResultSet = enhanceTickerFilter.length > 0 ? resultSet.filter((item) => enhanceTickerFilter.includes(item.ticker)) : resultSet;
      let selectedPortfolioFunds = {}
      if (calledFrom === 'deleteCard' && customizedCardData.length === 0) {
        this.setState({ enhanceReplace: '' }, () => {
          deletePortfolioEnhanceReplace({ replaceTicker, ticker, query })
        });
      }
    })
  }

  setReplaceTicker1 = (e, replaceTicker, ticker, query) => {
    const val = document.getElementById(e.target.id).checked;

    this.resetCheckBoxFor('customAddedFund');

    const { view, resultSet, enhanceTickerFilter, addPortfolioEnhanceReplace, deletePortfolioEnhanceReplace, portfolioFunds, marketFunds, watchlistFundstats, accountFundstats, customizedCardData } = this.props;

    if (val) {
      let customReplaceActiveTab = this.state.customReplaceActiveTab;
      customReplaceActiveTab.ticker = 'customReplace';
      this.setState({ enhanceReplace: replaceTicker, customReplaceActiveTab: customReplaceActiveTab }, () => {
        const filterResultSet = enhanceTickerFilter.length > 0 ? resultSet.filter((item) => enhanceTickerFilter.includes(item.ticker)) : resultSet;
        const tickerWiseRet = arrayToHash(customizedCardData, CN['Ticker']);
        let selectedPortfolioFunds = {}
        const found = resultSet.filter((h) => h.query === query);
        const _queryVar = found.length ? found[0].result.query_var : []; // for showing correlation chart
        if (tickerWiseRet[ticker] && tickerWiseRet[ticker].fundsData) {
          // const rA = tickerWiseRet[ticker].fundsData;
          // const fA = rA.filter(i => i.ticker === replaceTicker)[0];
          const fA = tickerWiseRet[ticker].fundsData;

          // const pF = portfolioFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
          let pF = {}
          if (view == 'account') {
            pF = accountFundstats.funds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
          } else if (view == 'watchlist') {
            pF = watchlistFundstats.positions.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
          } else if (view == 'mrktPl') {
            pF = marketFunds.filter((dd) => (dd.script || dd.symbol) === ticker)[0];
          } else {
            pF = portfolioFunds.filter((dd) => ((dd.script || dd.symsbol) === ticker) && dd.type !== 'SHARE')[0];
          }

          selectedPortfolioFunds = { ...fA, query_var: _queryVar, _pF: pF, _checked: val }
        }
        addPortfolioEnhanceReplace({ replaceTicker, replace: replaceTicker, ticker, query, selectedPortfolioFunds })
      })
    }  else {
      this.setState({ enhanceReplace: '' }, () => {
        deletePortfolioEnhanceReplace({ replaceTicker, ticker, query })
      })
    }
  }

  tabOnChange = (selectedKey, data, ticker, query, index) => {
    this.setState({ _viewIndex: selectedKey });
  }

  render() {
    const { data: { query, result, short_name, ticker, pseudo_query }, enhanceTickerFilter, setReplaceTicker, cardloading, portfolioFunds, allTickers, customizedCardData,filterResultSet, portfolioQuery } = this.props;
    const { _defaultFRR, _relevanceChartTabs } = result;
    const { resetEnhanceFundCheckStatus, resetCustomAddedFund, _viewIndex } = this.state;
    // const uniqueFunds = [];
    let enhanceData =[];
    let enhanceTicker;
    let enhanceCardTicker;

    // portfolioFunds.map(x => uniqueFunds.filter(a => a.script == x.script).length > 0 ? null : uniqueFunds.push(x));

    // const staticCardData = (result.funds && result.funds.length) ? result.funds[0] : null;
    // const cumstomReplace = (result.funds) ? ((result.funds.length > 1) ? result.funds[result.funds.length - 1] : result.funds[0]) : null;

    if(customizedCardData.length > 0) {
      var getTicker=customizedCardData.map(x=>x.ticker);
      if(getTicker.includes(ticker)){
        var index = getTicker.indexOf(ticker);
        enhanceData = customizedCardData.map(x=>x.fundsData)[index];
        enhanceCardTicker = enhanceData.ticker;
        enhanceTicker = customizedCardData.map(x=>x.ticker)[index];
        // for custom replace card
        if (typeof enhanceData.weight_track_record !== 'undefined' || typeof enhanceData["chart"].value !== 'undefined' || typeof enhanceData.dividend_yield !== 'undefined') {
          const trackRecord = enhanceData.weight_track_record || '--';
          const outPerform = enhanceData.outperform || '--';
          const dividendYield = (enhanceData.dividend_yield/100) || '--';
          Object.assign(enhanceData, {
            "attribute/track_record": trackRecord,
            "outperform": outPerform,
            "attribute/fundamental/yield": dividendYield,
          });
        }
      }
    }

    let staticCardValue;

    if(filterResultSet.length > 0) {
      var getFilterTicker=filterResultSet.map(x=>x.ticker)
      var index = getFilterTicker.indexOf(ticker);
      var targetCardData = filterResultSet[index].target_card;
      if (typeof targetCardData.funds !== 'undefined' && targetCardData.funds.length) {
        staticCardValue = targetCardData.funds[0];
      } else {
        return null;
      }
    }

    const dataSource = allTickers.map(x=>x.ticker);

    const renderReplace =  (customizedCardData.length > 0 && enhanceTicker == ticker) ? (
      <div className="d-flex flex-row" style={{ padding: '0px 10px' }}>
        <div style={{position:'absolute',left:'275px',top:'24px',cursor:'pointer'}}  onClick={this.deleteCard.bind(this, enhanceTicker, ticker, query)}><img src="/assets/images/card-close.png" /></div>
        <div className="pr-3" id="select-card">
          <ItemCard
            i={1}
            className="custom-card"
            staticCard={false}
            activeViewTab={_viewIndex}
            relevanceChartTabs={_relevanceChartTabs}
            defaultFRR={_defaultFRR}
            portfolioQuery={portfolioQuery}
            data={enhanceData}
            query={query}
            resetCheck={resetCustomAddedFund}
            ticker={enhanceTicker}
            highlights={result.Highlights}
            originalQueryVar={result.query_var}
            setReplaceTicker={this.setReplaceTicker1}
            enhanceReplace={this.state.enhanceReplace}
            customReplaceActiveTab={this.state.customReplaceActiveTab}
            createdForm = "customReplace"
            tabOnChange={this.tabOnChange}
          />
        </div>
      </div>
    ) : null;

    return (
      <React.Fragment>
        <Row className="enhance-result-page-container">
          {(enhanceTickerFilter.includes(ticker) || enhanceTickerFilter.length === 0 ) && (
            <Col className="static-card-wrapper">
              <div className="static-card-label" id="short_name_tooltip">{ticker} ({short_name})</div>
              {staticCardValue
                && (
                  <Card className="static-card-body">
                    <ItemCard
                      i={1}
                      staticCard
                      activeViewTab={_viewIndex}
                      relevanceChartTabs={_relevanceChartTabs}
                      defaultFRR={_defaultFRR}
                      portfolioQuery={portfolioQuery}
                      data={staticCardValue}
                      query={query}
                      originalQueryVar={result.query_var}
                      highlights={result.Highlights}
                      ticker={staticCardValue.ticker}
                      tabOnChange={this.tabOnChange}
                    />
                  </Card>
                )
              }
              <SwapOutlined className="swap-icon-wrapper" />
            </Col>
          )}
          {(enhanceTickerFilter.includes(ticker) || enhanceTickerFilter.length === 0 ) && (
            <Col className="dynamic-card-wrapper">
              <Tabs className="card-tab-container" defaultActiveKey="1" tabBarGutter={32} onChange={this.onSelectTab}>
                <TabPane className="tabPaneClass" tab={CapitalizeEachWord(pseudo_query)} key="1">
                  <Card className="dynamic-card-body">
                    <ItemList
                      staticCard={false}
                      activeViewTab={_viewIndex}
                      relevanceChartTabs={_relevanceChartTabs}
                      defaultFRR={_defaultFRR}
                      data={result}
                      query={query}
                      ticker={ticker}
                      portfolioQuery={portfolioQuery}
                      resetCheck={resetEnhanceFundCheckStatus}
                      originalQueryVar={result.query_var}
                      customReplaceActiveTab={this.state.customReplaceActiveTab}
                      setCustomActiveTag={this.setCustomActiveTag}
                      resetCheckBoxFor={this.resetCheckBoxFor}
                      tabOnChange={this.tabOnChange}
                    />
                  </Card>
                </TabPane>
                <TabPane tab="Specify Custom Replacement" key="3" onTabClick={this.onSelectTab}>
                  <Card className="dynamic-card-body custom-dynamic-card-body">
                    { renderReplace }
                    { cardloading && <Loader loading={true} />}
                    { (!cardloading && customizedCardData.length === 0)
                      && (
                        <div style={{ padding: '0 10px' }}>
                          <Card style={{minWidth: '250px', width: '250px', height:'279px' }}>
                            <CardBody className="p-0">
                              <div style={{textAlign:'center',paddingTop:'66px'}} >
                                <img src="/assets/images/plus.png"/>
                                <AutoCompleteJJ suggestions={dataSource} overlapTicker={ticker}/>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      )
                    }
                    {(customizedCardData.length > 0 && ticker !== enhanceTicker)
                      && (
                      <div style={{ padding: '0 10px' }}>
                        <Card style={{minWidth: '250px', width: '250px', height:'279px' }}>
                          <CardBody className="p-0">
                            <div style={{textAlign:'center',paddingTop:'66px'}} >
                              <img src="/assets/images/plus.png"/>
                              <AutoCompleteJJ suggestions={dataSource} overlapTicker={ticker}/>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    )}
                  </Card>
                </TabPane>
              </Tabs>
            </Col>
          )}
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  view: state.investment.view,
  enhanceTickerFilter: state.portfolio.enhanceTickerFilter,
  resultSet: state.portfolio.resultSet,
  portfolioFunds: state.investment.portfolioFunds,
  marketFunds: state.investment.marketFunds,
  watchlistFundstats: getInvestmentWatchlistStats(state),
  accountFundstats: getInvestmentPositionStats(state),
  allTickers:state.portfolio.allTickers,
  customizedCardData:state.portfolio.customizedCardData,
  filterResultSet:state.portfolio.filterResultSet,
  cardloading: state.portfolio.cardloading,
  enhReplaceTicker: state.portfolio.enhanceReplace,
  portfolioQuery: state.portfolio.query,
})

const mapDispatchToProps = {
  setPortfolioReplace,
  setEnhancementData,
  setReplaceTicker,
  deleteCustomReplaceTicker,
  addPortfolioEnhanceReplace,
  deletePortfolioEnhanceReplace,
  setPortfolioState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResultPage)

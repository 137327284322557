import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import { uniqBy, reverse } from 'lodash';
import { MoreButton, FormatCurrency, TruncatedNameSponsor, withScrollBar, NoData, invalidTickerWithScrollBar } from './Common';
import { sponsorLogo } from 'DataSet';
import { setEnhancerState } from 'actions/enhancer';
import { addFundToSFM, setSFMCatData, refreshSFMState, addSFMItemTickersRequest, removeSFMItemTickersRequest } from 'actions/sfm';

import { fetchFundByTicker } from 'apis/account';
import {
  fetchFunds,
  fetchFundsDetails,
  fetchCategories,
  fetchFundCommissions,
  fetchRealTimePrices,
  fetchCharts,
  fetchReturnsData,
  fetchRegionsChart,
  fetchReturnsDataV1,
  fetchChartsV1,
} from 'apis/funds';

import { calcStats } from 'Stats';
import {
  getNest,
  getNestDefault,
  arrayToHash,
  queryStr,
  uniqValues,
  convertToCapitalCase,
  arrayInChunk,
  getImageOrFallback,
} from 'Utils';
import {
  FilterAttrData,
  SortAttrs,
  DefaultSortAttr,
  DateRange,
  ViewNames,
  Api,
  ApiHeaders,
  cn,
  CN,
  MaxResult,
  Universe,
  BrokersComms,
} from 'DataSet';
import { SectorColors } from 'data/Colors'
import { getFundsUpdatedPrice } from 'layouts/utils';

import getFilteredListByRange from './utils';

const classNames = require('classnames');

class Holdings_ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invalidTickerFunds: (props.summary && props.summary.invalidTickerFunds) ? props.summary.invalidTickerFunds : [],
      tickerNotSupported: (props.summary && props.summary.tickerNotSupported) ? props.summary.tickerNotSupported : [],
      listOfInvalidTickerFunds: [],
      listOfTickerNotSupported: [],
      invalidTickerExpandState: false,
    };
    this.structuredFund = this.structuredFund.bind(this);
    this.getFundsData = this.getFundsData.bind(this);
  }

  itemClickHandler = fundId => ev => {
    // console.log(fundId);
		if (this.props.itemClickHandler) {
			this.props.itemClickHandler(fundId);
		}
  }

  deleteHandler = (d) => {
    const { deleteHandler } = this.props;
    if (deleteHandler) {
      deleteHandler(d);
    }
  }

  updateActiveFund = (fundId) => {
    // console.log(fundId);
    if (this.props.itemClickHandler) {
      this.props.itemClickHandler(fundId);
    }
  }

  invalidTickerLoadMoreFunc = (type) => {
    // console.log('type -->', type);
    let { listOfInvalidTickerFunds, listOfTickerNotSupported, invalidTickerFunds, tickerNotSupported } = this.state;
    // const arrayToChunk = (invalidTickerFunds && invalidTickerFunds.length > 0) ? arrayInChunk(invalidTickerFunds, 4) : [];
    const arrayToChunk = (invalidTickerFunds && invalidTickerFunds.length > 0) ? invalidTickerFunds : [];
    if (type === 'default') {
      this.setState({ listOfInvalidTickerFunds: [], listOfTickerNotSupported: [], invalidTickerExpandState: false });
    } else {
      this.setState({ listOfInvalidTickerFunds: arrayToChunk, listOfTickerNotSupported: tickerNotSupported, invalidTickerExpandState: true });
    }
    const { loadMoreAction } = this.props;
    if (loadMoreAction) {
      loadMoreAction(type)
    }
  }

  async structuredFund(_funds) {
    try {
      let funds = [];
      if (_funds["funds"] && _funds["funds"].length) {
        funds = _funds["funds"].slice(0, MaxResult);
      }
      if (funds.length <= 0)
        return { funds: [], categories: [], range: _funds["date_range"], filterAttrData: {} };

      let ticks = uniqValues(funds, 'ticker').join(',');

      let [returns, categories, charts] = await Promise.all([
        fetchReturnsDataV1({tickers:ticks}),
        fetchCategories({tickers:ticks}),
        fetchChartsV1({ tickers: ticks }),
      ]);

      let holdings = charts.topten,
          assetTypes = charts.asset_type,
          themeScore = charts.themes_new,
          diverseData = charts.diversification,
          riskAdjReturn = charts.risk_adjusted;

      let returnsData = arrayToHash(returns, CN['Ticker']);
      let chartsData = arrayToHash(charts, CN['Ticker']);

      funds.forEach((e, index) => {
        funds[index].region = chartsData[cn(e, 'Ticker')].result.regions ? chartsData[cn(e, 'Ticker')].result.regions.length ? chartsData[cn(e, 'Ticker')].result.regions[0].region : [] : [];
        funds[index].returns = returnsData[e.ticker].returns;
        funds[index].returns = reverse(funds[index].returns);
        let r = getNest(['returns', 0], e);
        if (r){
          e._start = r['d'];
          e._end = e.returns[e.returns.length-1]['d'];
        }
        e.returns.forEach((item,index) => {
          e.returns[index].v = item.v/100;
        });
        // e.brokers = getNest([cn(e, 'Ticker'), 'brokers'], commData);
        e.brokers = BrokersComms;
        e.broker = getNest([0], e.brokers);

        //regions data manipulation -- adding others
        if(e.region.length> 0){
          e.region.forEach((reg, i) => {
            let sum = 0;
            if(reg.n == 'Others'){
              reg.sub.forEach(child => {
                sum += child.v;
              })
              e.region[i].sub = [{ n: 'Others ', v: sum}];
            }
          });
        }

        //calculate assetType chart data
        e.assetTypeData = chartsData[cn(e, 'Ticker')].result.asset_type[0] || []

        //calculate theme score chart data
        e.themeScore = chartsData[cn(e, 'Ticker')].result.themes_new[cn(e, 'Ticker')];

        //calculate risk adjusted return chart data
        e.riskAdjReturn = chartsData[cn(e, 'Ticker')].result.risk_adjusted[cn(e, 'Ticker')];

        e.diverseData = chartsData[cn(e, 'Ticker')].result.diversification[0];

        e.diverseData = chartsData[cn(e, 'Ticker')].result.diversification[0];
        // console.log(e.diverseData)
        //calculate holding chart data
        let _sum = 0.0;
        let holdingData = chartsData[cn(e, 'Ticker')].result.topten.funds.length ? chartsData[cn(e, 'Ticker')].result.topten.funds[0].holdings : []
        e.holdingData = holdingData
          .map((el, i) => {
            _sum += el.weight;
            return {
              name: el.name,
              value: el.weight,
              color: SectorColors[i % SectorColors.length]
            }
          });
        if (e.holdingData.length > 0 && _sum < 100){
          let rem = 100 - _sum.toFixed(1);
          rem = parseFloat(rem.toFixed(1));
          e.holdingData.push({ name: 'Others', value: rem, color: '#ddd' });
        }

      });

      categories["categories"].forEach(e => {
        let r = getNest(['returns', 0], e);
        if (r){
          e._start = r['d'];
          e._end = e.returns[e.returns.length-1]['d'];
        }
        e.returns.forEach((item,index) => {
          e.returns[index].v = item.v/100;
        });
      });

      let filterAttrData = FilterAttrData.reduce((acc, v) => ({...acc, [v.name]: uniqValues(funds, v.col)}), {});
      filterAttrData = Object.keys(filterAttrData).reduce((acc,item) => {
        acc[item] = filterAttrData[item].filter(x => (x != 0 || x != ''))
        return acc;
      }, {});

      return { funds, categories: categories["categories"], filterAttrData };

    } catch (e) {
      console.log(e);
    }
  }

  async getFundsData(card, data) {
    try {
      // const { funds } = data;
      // console.log(card);
      const _funds = await this.structuredFund(data);
      const { categories, filteredAttrData, range } = _funds;
      let { funds } = _funds;
      // const { sfm, sfmDateRange } = this.props;
      const { sfm, realTimePrice } = this.props;
      const { addFundToSFM, setSFMCatData, setEnhancerState } = this.props;

      const { items } = sfm;
      if (funds && funds.length && funds.length > 0) {
        if (realTimePrice && realTimePrice.funds) {
          const updatedData = getFundsUpdatedPrice(realTimePrice, funds);
          if (updatedData) {
            funds = updatedData.funds;
          }
        } else {
          console.log('-- failed to load realTimePrice for Funds');
        }
        funds.forEach((element) => {
          if (!element.series) {
            const valTraslate = v => Math.log10((v+100)/100);
            let stats = calcStats(element.returns);
            let dd = element.returns.map(e => e.d);
            element.series = dd.map(d => {
              let ival = getNest([d, 'cuml'], stats.series)
              return { x: d, y1: ival, y3: ival }
            });
          }
          if (!element._stats) {
            // pass date-range param to get filter item list, it will update this.props.item
            const sfmDateRange = { // or else check this.props.sfmDateRange and use ?
              start: element._start,
              end: element._end,
            };
            if (funds.length && sfmDateRange && categories) {
              getFilteredListByRange({ items: funds, range: sfmDateRange, catData: categories });
            }
          }
          element.ticker_short = element.ticker;
          element._selected = true;
          card._selected = true;
          element._selector = true;
          card._selector = true;
        });

        const arr = funds.concat(items);
        const dataArr = uniqBy(arr, 'ticker');
        const NEWSFMCATDATA = this.props.sfmCatData || [];
        if (typeof categories !== undefined && setSFMCatData) {
          const newArr = uniqBy(NEWSFMCATDATA.concat(categories),'name');
          setSFMCatData(newArr);
        } else {
          throw 'categories data is undefined or setSFMCatData is missing'
        }

        if (addFundToSFM) {
          addFundToSFM({
            items: dataArr,
            modal: true,
            open: true,
          });
        } else {
          throw '-- addFundToSFM is missing --'
        }

        const { summary } = this.props;
        if (summary && summary.portfolioFunds) {
          const { portfolioFunds } = summary;
          const cardNewArray = [];
          const foundT = portfolioFunds.find(e => cn(e, 'Ticker') === card.ticker);

          if (foundT) {
            foundT._selected = true;
            foundT._selector = true;
            cardNewArray.push(foundT);
            const portfolioFundsUpdatedArray = uniqBy(portfolioFunds.concat(cardNewArray),'ticker');
            if (setEnhancerState) {
              setEnhancerState({
                summary: {
                  ...summary,
                  portfolioFunds: portfolioFundsUpdatedArray,
                }
              });
            } else {
              throw '-- setEnhancerState is missing --'
            }
          }
        } else {
          throw '-- summary portfolioFunds missing for SFM operation'
        }

      } else {
        throw '-- NO FUNDS found for SFM operation'
      }
      // const { setSFMCatData, sfmCatData } = this.props;
      // if (typeof categories !== undefined) {
      //   console.log(categories);
      //   setSFMCatData(categories);
      // }
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { title, items, loadMoreAction, expand = false, invalidTickersExpand = true, summary } = this.props;
    let { invalidTickerFunds, tickerNotSupported, listOfInvalidTickerFunds, listOfTickerNotSupported, invalidTickerExpandState } = this.state;
    const more = items.length > 3 || (invalidTickerFunds.length > 0 && items.length > 2 );
    const _items = items.slice(0, expand ? 30 : 3);
    // const arrayToChunk = (invalidTickerFunds && invalidTickerFunds.length > 0) ? arrayInChunk(invalidTickerFunds, 4) : [];
    // const arrayToChunk = (tickerNotSupported && tickerNotSupported.length > 0) || (invalidTickerFunds && invalidTickerFunds.length > 0);
    let invalidTickerMoreState = (tickerNotSupported && tickerNotSupported.length > 0) || (invalidTickerFunds && invalidTickerFunds.length > 0); // arrayToChunk.length > 3 || (arrayToChunk.length > 0 && arrayToChunk.length > 2);

    let commonLabelText = '', showMoreButtonFor = '';
    if (tickerNotSupported && tickerNotSupported.length > 0 && invalidTickerFunds && invalidTickerFunds.length > 0) {
      commonLabelText = 'Unsupported/Unrecognized Securities';
      if (!invalidTickersExpand) {
        showMoreButtonFor = 'Unsupported/Unrecognized';
      } else {
        showMoreButtonFor = 'Unsupported';
      }
    } else if (tickerNotSupported && tickerNotSupported.length > 0) {
      commonLabelText = 'Unsupported Securities';
      showMoreButtonFor = 'Unsupported';
    } else if (invalidTickerFunds && invalidTickerFunds.length > 0) {
      commonLabelText = 'Unrecognized Securities';
      showMoreButtonFor = 'Unrecognized';
    }

    return (
      <React.Fragment>
        <MoreButton loadMoreAction={loadMoreAction} layout="holdings" more={more} expand={expand} />
        <h5 className="mb-1 text-heading font-one">{title}</h5>
        {!invalidTickersExpand && (
          expand ? (
            <HoldingsItemListWithScrollBar
              {...this.props}
              items={_items}
              deleteHandler={this.deleteHandler}
              actions={this.props.actions}
              itemClickHandler={this.itemClickHandler}
              getFundsData={this.getFundsData}
              updateActiveFund={this.updateActiveFund}
            />
          ) : (
            <HoldingsItemList
              {...this.props}
              items={_items}
              deleteHandler={this.deleteHandler}
              buyHandler={this.buyHandler}
              itemClickHandler={this.itemClickHandler}
              getFundsData={this.getFundsData}
              updateActiveFund={this.updateActiveFund}
            />
          )
        )}
        {(invalidTickerMoreState) && (
          invalidTickersExpand ? (
            <InvalidTickerHoldingsItemScrollBar
              commonLabelText={commonLabelText}
              showMoreButtonFor={showMoreButtonFor}
              listOfInvalidTickerFunds={listOfInvalidTickerFunds}
              listOfTickerNotSupported={listOfTickerNotSupported}
              more={invalidTickerMoreState}
              expand={invalidTickersExpand}
              loadMore={this.invalidTickerLoadMoreFunc}
            />
          ) : (
            <InvalidTickerHoldingsItem
              commonLabelText={commonLabelText}
              showMoreButtonFor={showMoreButtonFor}
              listOfInvalidTickerFunds={[]}
              listOfTickerNotSupported={[]}
              more={invalidTickerMoreState}
              expand={invalidTickersExpand}
              loadMore={this.invalidTickerLoadMoreFunc}
            />
          )
        )}
      </React.Fragment>
    )
  }
}

export const Holdings = connect(
  state => ({
    sfm: state.sfm,
    sfmCatData: state.sfm.sfmCatData,
    sfmSelectedItemTickers: state.sfm.selectedItemTickers,
    sfmDateRange: state.funds.dateRange,
    // summary: state.enhancer.summary || {},
    realTimePrice: state.enhancer.summary.realTimePrice || {},
  }),
  {
    addFundToSFM,
    setSFMCatData,
    refreshSFMState,
    setEnhancerState,
    // SFM V-2
    addSFMItemTickersRequest,
    removeSFMItemTickersRequest,
  }
)(Holdings_);

const HoldingsItemList = (props) => {
  const count = props.items.length;
  if (count <= 0) return <NoData height='225px' />;
  // const buyHandler = (item) => {
  //   props.buyHandler(item);
  // }
  const itemClickHandler = (item) => {
    props.itemClickHandler(item);
  }

  const activeF = getActiveFunds(props.items, props.activeFund);

  if (activeF) {
    // console.log(activeF);
    props.updateActiveFund(activeF);
  }

  // const invalidTickerFunds = (props.summary && props.summary.invalidTickerFunds) ? props.summary.invalidTickerFunds : [];
  // console.log(invalidTickerFunds);

  return (
    <div style={{minHeight: '225px'}}>
      { props.items.map((e, i) => (
        <HoldingsItem
          {...props}
          key={e.symbol+'-'+i}
          actions={props.actions}
          itemClickHandler={props.itemClickHandler}
          item={e}
          last={i === count-1}
          getFundsData={props.getFundsData}
          deleteHandler={props.deleteHandler}
          invalidTickerFunds={props.invalidTickerFunds}
        />
      ))}
    </div>
  )
}

const HoldingsItemListWithScrollBar = withScrollBar(HoldingsItemList)

const getActiveFunds = (items, key) => {
  if (!items && !items.length && key) return;
  const index = items.findIndex((x) => x.symbol === key);
  let symbol = key;
  if (index < 0) {
    symbol = items[0].symbol;
  }
  return symbol;
}

const HoldingsItem = React.memo(({ item,  last, ...props }) => {
  const {
    sfm,
    items: portfolioItems,
    addFundToSFM,
    refreshSFMState,
    setEnhancerState,
    summary,
    view,
    sfmSelectedItemTickers,
    addSFMItemTickersRequest,
    removeSFMItemTickersRequest,
  } = props;
  const { items: sfmItems } = sfm;

  // const buyHandler = (thisItem) => {
  //   try {
  //     if (summary && summary.portfolioFunds) {
  //       const { portfolioFunds } = summary;
  //       const ticker = thisItem.ticker || thisItem.script || thisItem.symbol;
  //       // props.buyHandler(item);
  //       console.log(ticker);
  //       const foundT = portfolioFunds.find(e => cn(e, 'Ticker') === ticker);
  //       console.log(foundT);
  //
  //       if (foundT && foundT._selected) {
  //         const _item = sfmItems.find(e => cn(e, 'Ticker') === ticker);
  //         if (_item) {
  //           _item.selector = false;
  //           _item.selected = false;
  //
  //           const index = sfmItems.indexOf(_item);
  //           if (index > -1) {
  //             sfmItems.splice(index, 1);
  //             console.log(sfmItems);
  //             addFundToSFM({
  //               items: sfmItems,
  //             })
  //             if (sfmItems.length === 0) {
  //               console.log('refreshSFMState......');
  //               refreshSFMState();
  //             }
  //           }
  //         }
  //
  //         const cardNewArray = [];
  //         // Clear fund button
  //         foundT._selected = false;
  //         foundT._selector = false;
  //         cardNewArray.push(foundT);
  //         const portfolioFundsUpdatedArray = uniqBy(portfolioFunds.concat(cardNewArray),'ticker');
  //         setEnhancerState({
  //           summary: {
  //             ...summary,
  //             portfolioFunds: portfolioFundsUpdatedArray,
  //           },
  //         });
  //
  //       } else {
  //         // Add fund button
  //         fetchFundByTicker(ticker).then((response) => {
  //           const { getFundsData } = props;
  //           getFundsData(item, response);
  //         })
  //       }
  //     } else {
  //       throw '-- retData is missing --'
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  // SFM V-2 Logic
  const buyHandler = (thisItem) => {
    try {
      if (summary && summary.portfolioFunds) {
        const { portfolioFunds } = summary;
        const ticker = thisItem.ticker || thisItem.script || thisItem.symbol;
        // props.buyHandler(item);

        //NOTE: update 'fund._selected' in HOLDING LIST
        portfolioFunds.map((e) => {
          if (cn(e, 'Ticker') === ticker) {
            if (e._selected) {
              e._selected = false;
              e._selector = false;
            } else {
              e._selected = true;
              e._selector = true;
            }
          }
        });
        if (setEnhancerState) {
          setEnhancerState({
            summary: {
              ...summary,
              portfolioFunds: portfolioFunds,
            },
          });
        }

        // NOTE: Add fund to SFM
        //V-2 logic
        console.log(`========>>>> ADDING TICKER ${ticker} IN SFM <<<<========`);
        const alreadyPresent = sfmSelectedItemTickers.find((l) => l === ticker)
        if (alreadyPresent) {
          // un_select logic
          if (removeSFMItemTickersRequest) {
            removeSFMItemTickersRequest({
              removeTicker: ticker,
            })
          }
        } else {
          // select logic
          if (addSFMItemTickersRequest) {
            addSFMItemTickersRequest({
              selectedItemTickers: ticker,
            })
          }
        }

      } else {
        throw '-- retData is missing --'
      }
    } catch (e) {
      console.log(e);
    }
  }

  const deleteHandler = (item) => {
    props.deleteHandler(item);
  }

  // if (sfmItems.length === 0 && summary && summary.retData) {
  //   summary.retData.map(e => e._selected = false);
  //   item._selected = false;
  // }

	const cost = item.market_value || item.price;
  // console.log(item.weight);
  const weightToBe = item.original_weight ? parseFloat(item.original_weight.toFixed(2)) : null;
  // className={classNames({"py-2p5 border-bottom": !last, "pt-2p5": last})}
  return (
    <div
			className={classNames({"py-2p5 border-bottom": !last, "pt-2p5": last})}
      onClick={props.itemClickHandler(item.ticker)}
		>
			<Row>
				<Col xs="3" className="d-flex flex-column justify-content-center align-items-center">
					<h5 className="mb-1 fs-m1 fw-b tx-c1">{item.ticker_short || item.ticker}</h5>
          {weightToBe && <h3 className="mb-0 tx-c2 holdings-weight-font-size">{weightToBe}%</h3>}
				</Col>
				<Col xs="6" sm="7" className="d-flex align-items-center">
					<TruncatedNameSponsor weight={weightToBe} name={item.short_name} sponsor={item.sponsor} lines={1} style={{ width: '70%' }} />
          <h3 className="mb-0 tx-c2 holdings-value-font-size" style={{ width: '30%' }}>{FormatCurrency(cost, 2)}</h3>
				</Col>
				<Col xs="3" sm="2" className="d-flex flex-column justify-content-center">
          <Button
            outline={!item._selected}
            color={ item._selected ? "unselect" : "select" }
            className="h6 mb-0 py-1 badge-pill selectBtn buy-btn"
            id="buy-fund"
            disabled={(item.type === 'CASH')}
            onClick={() => { buyHandler(item) }}
            style={{ position: 'relative'}}
          >
            {(item._selected ? 'Clear' : 'Buy')}
          </Button>
					<Button
						color="select"
						outline
						disabled={true}
						className="h6 mb-0 mt-2 py-1 badge-pill sell-btn"
						onClick={() => { deleteHandler(item) }}
					>{props.view == 'watchlist' ? 'Drop' : 'Sell'}</Button>
				</Col>
			</Row>
    </div>
  )
})

const InvalidTickerHoldingsItem = React.memo(({ listOfInvalidTickerFunds, listOfTickerNotSupported, commonLabelText, showMoreButtonFor, loadMore, more, last, expand, ...props }) => {
  let moreBtnStyle = {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
  };

  const imageExists = (url, callback) => {
    var img = new Image();
    if (callback) {
      img.onload = function() { callback(true); };
      img.onerror = function() { callback(false); };
      img.src = url;
    }
  }

  const renderImg = (sponsor) => {
    const [imgPath, setImgPath] = useState('')
    imageExists(sponsorLogo(sponsor), exists => {
      if (exists) {
        setImgPath(sponsorLogo(sponsor))
      }
    })
    return (
      <>
        {imgPath
          ? <img src={imgPath} className="img-fluid" alt={sponsor} style={{height: '20px'}} />
          : <div className="sponsor-logo-placeholder" title={sponsor}>{sponsor}</div>
        }
      </>
    )
  }

  return (
    <>
      {(!expand) ? (
        <div className="" style={{ marginTop: 12 }}>
          <h5 className="pos-rel p-0 mb-0 text-heading font-one">{commonLabelText} <MoreButton style={moreBtnStyle} loadMoreAction={loadMore} layout="invalidTickers" more={more} expand={expand} /></h5>
        </div>
      ) : (
        <>
          {listOfTickerNotSupported && listOfTickerNotSupported.length > 0 && (
            <div className="pt-2p5">
              <h5 className="pos-rel p-0 mb-0 text-heading font-one">Unsupported Securities {showMoreButtonFor === 'Unsupported' && (<MoreButton style={moreBtnStyle} loadMoreAction={loadMore} layout="invalidTickers" more={more} expand={expand} />)}</h5>
              <div className="pt-2p5">
                { listOfTickerNotSupported && listOfTickerNotSupported.length > 0 && listOfTickerNotSupported.map((kl, i) => {
                  let ogW = Number(kl.original_weight);
                  ogW = parseFloat(ogW.toFixed(2));
                  let cost = kl.market_value || kl.price;
                  let renderWeightText = (ogW || ogW === 0) && (<h3 className="mb-0 tx-c2 holdings-weight-font-size">{ogW+"%"}</h3>);
                  let renderSubText = null;
                  if (typeof kl.weight_calculated_format !== 'undefined' && kl.weight_calculated_format !== '' && kl.format_value !== '' && typeof kl.format_value !== 'undefined') {
                    if (kl.weight_calculated_format === 'amount') {
                      renderSubText = (<h3 className="mb-0 tx-c2 holdings-value-font-size" style={{ width: '30%' }}>{"$"+ parseFloat(kl.format_value)}</h3>)
                    }
                  }
                  return (
                    <Row className={`${((listOfTickerNotSupported.length - 1) !== i) ? 'py-2p5 border-bottom' : 'pt-2p5'}`}>
                      <Col key={i} xs="3" className={`d-flex flex-column justify-content-center align-items-center`}>
                        <h5 className="mb-1 fs-m1 fw-b tx-c1">{kl.ticker_short || kl.ticker}</h5>
                        {renderWeightText}
                      </Col>
                      <Col xs="6" sm="7" className="d-flex align-items-center">
                        <div style={{ width: '70%' }}>
                          {kl.sponsor && (
                            <div className="pb-1">
                              {renderImg(kl.sponsor)}
                            </div>
                          )}
                          <h5 className="font-weight-bold text-ssf-blue-d2 mb-0 truncate lh-125" title={kl.short_name}>{kl.short_name}</h5>
                        </div>
                        {/*cost && <h3 className="mb-0 tx-c2 holdings-value-font-size" style={{ width: '30%' }}>{FormatCurrency(cost, 2)}</h3>*/}
                        {renderSubText}
                      </Col>
                    </Row>
                  )
                })}
              </div>
            </div>
          )}
          {listOfInvalidTickerFunds && listOfInvalidTickerFunds.length > 0 && (
            <div className="pt-2p5">
              <h5 className="pos-rel p-0 mb-0 text-heading font-one pt-2p5">Unrecognized Securities {showMoreButtonFor === 'Unrecognized' && (<MoreButton style={moreBtnStyle} loadMoreAction={loadMore} layout="invalidTickers" more={more} expand={expand} />)}</h5>
              <div className="pt-2p5">
                { listOfInvalidTickerFunds && listOfInvalidTickerFunds.length > 0 && listOfInvalidTickerFunds.map((kl, i) => {
                  let ogW = Number(kl.original_weight);
                  ogW = parseFloat(ogW.toFixed(2));
                  let cost = kl.market_value || kl.price;
                  let renderWeightText = (ogW || ogW === 0) && (<h3 className="mb-0 tx-c2 holdings-weight-font-size">{ogW+"%"}</h3>);
                  let renderSubText = null;
                  if (typeof kl.weight_calculated_format !== 'undefined' && kl.weight_calculated_format !== '' && kl.format_value !== '' && typeof kl.format_value !== 'undefined') {
                    if (kl.weight_calculated_format === 'amount') {
                      renderSubText = (<h3 className="mb-0 tx-c2 holdings-value-font-size" style={{ width: '30%' }}>{"$"+ parseFloat(kl.format_value)}</h3>)
                    }
                  }
                  return (
                    <Row className={`${((listOfInvalidTickerFunds.length - 1) !== i) ? 'py-2p5 border-bottom' : 'pt-2p5'}`}>
                      <Col key={i} xs="3" className={`d-flex flex-column justify-content-center align-items-center`}>
                        <h5 className="mb-1 fs-m1 fw-b tx-c1">{kl.ticker_short || kl.ticker}</h5>
                        {renderWeightText}
                      </Col>
                      <Col xs="6" sm="7" className="d-flex align-items-center">
                        <div style={{ width: '70%' }}>
                          {kl.sponsor && (
                            <div className="pb-1">
                              {renderImg(kl.sponsor)}
                            </div>
                          )}
                          <h5 className="font-weight-bold text-ssf-blue-d2 mb-0 truncate lh-125" title={kl.short_name}>{kl.short_name}</h5>
                        </div>
                        {/*cost && <h3 className="mb-0 tx-c2 holdings-value-font-size" style={{ width: '30%' }}>{FormatCurrency(cost, 2)}</h3>*/}
                        {renderSubText}
                      </Col>
                    </Row>
                  )
                })}
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
})

const InvalidTickerHoldingsItemScrollBar = invalidTickerWithScrollBar(InvalidTickerHoldingsItem)

export const FundActions = () => {
  return (
    <React.Fragment>
      <Button color={"select"} outline
        className="h6 mb-0 py-1 badge-pill buy-btn">Buy</Button>
      <Button color={"select"} outline
        className="h6 mb-0 mt-2 py-1 badge-pill sell-btn">Sell</Button>
    </React.Fragment>
  )
}

import React from 'react';
import { connect } from 'react-redux';
import { uniqBy } from 'lodash';
import { cn } from '../../DataSet';

import ErrorBoundary from '../shared/ErrorBoundary'
import TopSection from '../shared/TopSection'

import { investmentFetch, setInvestmentState } from 'actions/investment'
import { toggleModal, setSFMState, refreshSFMState } from 'actions/sfm'
import { refreshWeightState } from 'actions/weights'
import { setFundsState, searchQuery } from 'actions/funds'
import Navs from './Navs'
import AccountContent from './AccountContent'
import WatchlistContent from './WatchlistContent'
import UploadPortfolioContent from './UploadPortfolioContent'
import SfMain from '../sf/Main'
import { Confirmation } from '../sf/Confirm'

const classNames = require('classnames');


class Investments extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      data: [],
      confirm: false,
    };
    this.selectHandler = this.selectHandler.bind(this);
    this.confirmHandler = this.confirmHandler.bind(this);
  }

  componentDidMount() {
    const { items, data } = this.props;
    const { modalToggleState, setSFMState } = this.props;
    if (modalToggleState) {
      setSFMState({
        modalToggleState: false,
      });
    }
    this.setState({ items, data });
    this.props.investmentFetch();
    document.body.classList.add('accounts');
  }

  componentDidUpdate() {
    document.body.style.paddingTop = "";
    document.body.classList.remove('ht0');
  }

  componentWillReceiveProps(newProps) {
    if (newProps.items) {
      this.setState({
        items: newProps.items,
      });
    }
    if (typeof newProps.data !== undefined) {
      this.setState({
        data: newProps.data,
      });
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('accounts');
  }

  modalToggle = (modalToggleState) => {
    const { setSFMState, toggleModal } = this.props;
    if (modalToggleState === 'add') {
      setSFMState({
        modalToggleState: true,
        modal: true,
      });
    } else if (modalToggleState === 'remove') {
      setSFMState({
        modalToggleState: false,
        modal: false,
      });
    }
  }

  confirmHandler = (confirm, payload) => {
    this.setState({ confirm, confirmPayload: payload });
    if (confirm) {
      const { refreshSFMState } = this.props;
      refreshSFMState();
    }
  }

  triggerInvestment = () => {
    const { investmentFetch, setInvestmentState } = this.props;
    investmentFetch();
    setInvestmentState({
      view: "watchlist"
    });
  }

  selectHandler(item, index) {
    // this.state.data: ->  mark the item in main list if exist
    // this.state.items: -> mark the item in sfm list if exist
    console.log('selectHandler clicked');
    const { setSFMState, setFundsState, retData } = this.props;
    console.log(retData);
    const { items, data } = this.state;
    if (item === undefined) {
      this.state.items.forEach(d => { d._selected = false; d._order = undefined } );
      data.forEach(d => { d._selected = false; d._order = undefined });
      this.modalToggle('remove');
      setSFMState({
        items: [],
        modal: false,
      });
      setFundsState({
        data,
      });
      const { refreshWeightState } = this.props;
      refreshWeightState();
      return;
    }

    const foundT = retData.find(e => cn(e, 'Ticker') === item.ticker);
    if (foundT) {
      // console.log(foundT, foundT._selected);

      foundT._selected = !foundT._selected;
      const cardNewArray = [];
      cardNewArray.push(foundT);
      const retDataUpdatedArray = uniqBy(retData.concat(cardNewArray),'ticker');
      // console.log(retDataUpdatedArray);
      const { setInvestmentState } = this.props;
      setInvestmentState({
        retData: retDataUpdatedArray,
      });
    }

    item._selected = !item._selected;

    if (this.state.data && this.state.data.length) {
      let _item = this.state.data.find(e => cn(e, 'Ticker') === cn(item, 'Ticker'));
      if (_item) _item._selected = item._selected;
      setFundsState({
        data: this.state.data,
      });
    }

    if (item._selected) {
      let selectedCards = this.state.items.slice();
      index !== undefined ? selectedCards.splice(index, 0, item) : selectedCards.push(item);
      setSFMState({
        items: selectedCards,
      });
    } else {
      setSFMState({
        items: this.state.items.filter((e) => cn(e, 'Id') !== cn(item, 'Id')),
      });
      this.setState({ items: this.state.items.filter((e) => cn(e, 'Id') !== cn(item, 'Id')) }, () => {
        if (this.state.items.length === 0) {
          this.modalToggle('remove');
        }
      });
      item._order = undefined;
    }
  }

  render() {
    // const { items } = this.state;
    const { loading, valid, items, modalToggleState, sfmCatData, premiumUser, funds } = this.props;
    const nodata = false;

    return (
      <main role="main" id="investment">
        <ErrorBoundary>
          <TopSection
            modalToggleState={modalToggleState}
            queryHandler={this.props.queryHandler} query={''}
            logoutHandler={this.props.logoutHandler}
            loggedIn={this.props.loggedIn}
            {...this.props}
          />
        <div className={classNames("container mb-5 account-container", modalToggleState ? 'blur-effect' : '')}>
            { loading
              ? <Waiting />
              : nodata || !valid
              ? <NoResult />
            : <Content selectHandler={this.selectHandler} /> }
          </div>
        </ErrorBoundary>
        { items.length > 0 && (
          <SfMain
            {...this.props}
            accountsPage
            premiumUser={premiumUser}
            items={items}
            selectHandler={this.selectHandler}
            modalToggle={this.modalToggle}
            confirmHandler={this.confirmHandler}
          />
        ) }
        { this.state.confirm && <Confirmation {...this.props} triggerInvestment={this.triggerInvestment} confirmHandler={this.confirmHandler} confirmPayload={this.state.confirmPayload} /> }
      </main>
    )
  }
}

const mapStateToProps = ({ sfm, investment,profile, funds, auth }) => ({
  premiumUser: auth.user.premiumUser,
  loading: investment.loading,
  valid: investment.valid,
  sfmFunds: sfm.selectedFunds,
  modalToggleState: sfm.modalToggleState,
  modal: sfm.modal,
  sfmCatData: sfm.sfmCatData,
  items: sfm.items,
  logo: profile.logo,
  data: funds.data,
  retData: investment.retData,
  funds,
})

const mapDispatchToProps = {
  searchQuery,
  investmentFetch,
  toggleModal,
  setSFMState,
  refreshSFMState,
  setFundsState,
  setInvestmentState,
  refreshWeightState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Investments)


const Waiting = () => {
  return (
    <div className="w-100">
      <div className="text-secondary text-center mt-5">
        <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5"></i>
      </div>
    </div>
  )
}

const NoResult = () => {
  return (
    <div className="w-100">
      <div className="text-secondary text-center">
        <h2 className="mt-4">No transactions found.</h2>
      </div>
    </div>
  )
}

class Content_ extends React.PureComponent {

  selectHandler = (item, index) => {
    const { selectHandler } = this.props;
    selectHandler(item, index);
  }

  renderContent = () => {
    const { view } = this.props;
    switch (view) {
      case 'account':
        return <AccountContent />
        break;

      case 'watchlist':
        return <WatchlistContent selectHandler={this.selectHandler} />
        break;

      case 'uplPrt':
        return <UploadPortfolioContent selectHandler={this.selectHandler} />
        break;

      default:
        return '';
        break;
    }
  }

  render() {
    const { view, portfolioLoading } = this.props;
    return (
      <div>
        <Navs />
        {
          portfolioLoading && <Loader />
        }
        {this.renderContent()}
      </div>
    )
  }
}

const Content = connect(
  state => ({
    view: state.investment.view,
    portfolioLoading: state.investment.portfolioLoading || state.investment.reportLoader,
  }),
  {
  }
)(Content_);

const Loader = props => {
  return (
    <div className="overlay active">
      <div className="text rounded">
        <i className="fal fa-circle-notch fa-3x fa-spin text-gray-5"></i>
        <h5 className="mt-2 mb-0">Loading fund data</h5>
      </div>
    </div>
  )
}

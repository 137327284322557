import { takeEvery, fork, call, put, all } from 'redux-saga/effects'
import moment from 'moment';
import ActionTypes from 'action-types';
import { relatedInsightFetch, relatedInsightSuccess } from 'actions/plugins';
import * as pluginsAPI from 'apis/plugins';

import config from 'env';
import { sponsorPdfUrl } from 'Utils';
import { getInsightHashKey } from 'common/theme';


function* relatedInsightRequest(data) {
  let API_FEEDS = [], SPONSOR_API_FEEDS = [], PROMOTED_API_FEEDS = [];
  try {
    const { payload } = data;
    if (payload) {
      yield put(relatedInsightFetch());
      let { chartData, query, sponsor_tickers = [], searchResultsData = [] } = payload;
      let insightKey = query || null;

      const themeValue = chartData.map((item) => {
        if (item && item.ontology_node) {
          const themeData = item.ontology_node.split('/');
          if (themeData.length) {
            themeData.filter(i => i === 'theme');
            return themeData[themeData.length - 1];
          }
        }
      });

      if (config.relatedInsight) {
        // NOTE: Step 1: SPONSORED
        // NOTE: Check for 'sponsors_tickers' from discovery-api response
        // ---------------------------------------------------------------------------------------------------
        console.log('-- No theme found from query var --');
        // console.log(sponsor_tickers);

        if (sponsor_tickers && sponsor_tickers.length) {
          // console.log('-- finding related insight data in -> sponsor_tickers --');
          sponsor_tickers.forEach((item, i) => {
            const foundSponsoredTicker = searchResultsData.find((kl) => (kl.ticker === item.ticker && kl.is_sponsored))
            if (foundSponsoredTicker && item && item.media_url && item.media_url.length) {
              // NOTE: For 'Sponsored' case
              // --------------------------------------------------------------------------------------------------------------
              let splittedMediaArray = item.media_advisor ? item.media_advisor.split('||') : item.media_url ? item.media_url.split('||') : [];
              let splittedLinkArray = item.link_advisor ? item.link_advisor.split('||') : item.link ? item.link.split('||') : [];
              let splittedTextArray = item.text_advisor ? item.text_advisor.split('||') : item.text ? item.text.split('||') : [];
              let splittedImpressionArray = item.link_impression ? item.link_impression.split('|||') : item.impression_tag ? item.impression_tag.split('|||') : [];
              // console.log('splittedImpressionArray --> ', splittedImpressionArray);

              if ((splittedMediaArray.length === splittedLinkArray.length) &&
                (splittedLinkArray.length === splittedTextArray.length) &&
                (splittedTextArray.length === splittedMediaArray.length)
              ) {
                splittedMediaArray.forEach((child, cindex) => {
                  let imgFeed = splittedMediaArray[cindex];
                  let imgFeedlink = splittedLinkArray[cindex];
                  let impressionTag = (config.impressionTag && splittedImpressionArray[cindex]) ? splittedImpressionArray[cindex] : false;
                  let url = sponsorPdfUrl(imgFeedlink);
                  let title = item.channel_name;
                  let _ARRRR = [];
                  if (impressionTag &&
                    impressionTag.includes('||') &&
                    impressionTag.split('||') &&
                    impressionTag.split('||').length > 0
                  ) {
                    impressionTag.split('||').forEach(y => {
                      _ARRRR.push(y)
                    });
                  }
                  // console.log(_ARRRR);
                  SPONSOR_API_FEEDS.push({
                    url,
                    title,
                    impressionTag,
                    imgSrc: imgFeed,
                    promoted: false,
                    sponsored: true,
                    ticker: item.ticker,
                    context: splittedTextArray[cindex],
                    impressionTagArray: item.channel_name === 'Vanguard' ? _ARRRR : false,
                  });
                })
              }
              // --------------------------------------------------------------------------------------------------------------

              // NOTE: For 'Promoted' case
              // --------------------------------------------------------------------------------------------------------------
              let promotedMediaArray = item.promoted_media_url ? item.promoted_media_url.split('||') : [];
              let promotedLinkArray = item.promoted_link ? item.promoted_link.split('||') : [];
              let promotedTextArray = item.promoted_text ? item.promoted_text.split('||') : [];
              if ((promotedMediaArray.length === promotedLinkArray.length) &&
                (promotedLinkArray.length === promotedTextArray.length) &&
                (promotedTextArray.length === promotedMediaArray.length)
              ) {
                promotedMediaArray.forEach((child, cindex) => {
                  let imgFeed = promotedMediaArray[cindex];
                  let imgFeedlink = promotedLinkArray[cindex];
                  let impressionTag = false;
                  let url = sponsorPdfUrl(imgFeedlink);
                  let title = item.channel_name;
                  PROMOTED_API_FEEDS.push({
                    url,
                    title,
                    impressionTag,
                    imgSrc: imgFeed,
                    promoted: true,
                    sponsored: false,
                    ticker: item.ticker,
                    context: promotedTextArray[cindex],
                  })
                });
              }
              // --------------------------------------------------------------------------------------------------------------

            }
          })
        }
        // ---------------------------------------------------------------------------------------------------

        // NOTE: Step 2: THEMES
        // NOTE: Check for 'themes' from query_var then call 'clout' api
        // ---------------------------------------------------------------------------------------------------
        if (themeValue && themeValue.length) {
          themeValue.forEach(i => {
            if(!insightKey)
              insightKey = getInsightHashKey(i);
          });
          if (insightKey) { // Found 'theme'
            const pluginResponse = yield call(pluginsAPI.getRelatedInsightsData, { id_hash: insightKey });
            if (pluginResponse && pluginResponse.result && pluginResponse.result.success) {
              if (pluginResponse.result.success ===  true &&
                pluginResponse.result.data &&
                pluginResponse.result.data.feed &&
                pluginResponse.result.data.details
              ) {
                let { feed: _feeds, details: { account} } = pluginResponse.result.data;
                let filteredFeeds = _feeds.filter(item => (item.media_urls && item.media_urls.length > 0 && item.text !== ""));
                if (filteredFeeds && filteredFeeds.length) {
                  filteredFeeds.forEach((item, i) => {
                    if (item.media_urls &&
                      item.media_urls.length &&
                      item.media_urls[0] &&
                      item.ids &&
                      item.ids.channel &&
                      item.ids.channel.length &&
                      item.ids.channel[0] &&
                      item.links &&
                      item.links.social_permalink &&
                      account &&
                      account[item.ids.channel[0]]
                    ) {
                      const imgFeed = item.media_urls[0][0] || item.media_urls[0];
                      const title = account[item.ids.channel[0]].title;
                      const date = moment(item.created_time, 'YYYY/MM/DD');
                      const imgFeedlink = item.links.social_permalink;
                      let url = sponsorPdfUrl(imgFeedlink);
                      // console.log('sponsorPdfUrl --> ', url);
                      API_FEEDS.push({
                        ...item,
                        url,
                        date,
                        title,
                        sponsored: false,
                        context: item.text,
                        ticker: item.ticker,
                        impressionTag: false,
                        account: account[item.ids.channel[0]],
                        imgSrc: `${imgFeed.path}${imgFeed.name.resized}.${imgFeed.ext}`,
                      });
                    }
                  });
                } else {
                  throw '-- empty insight api response --'
                }
              } else {
                throw '-- no data for related insights --'
              }
            } else if (pluginResponse && pluginResponse.result && pluginResponse.result.success === false) {
              throw '-- failed to response --'
            }
          }
        }
        // ---------------------------------------------------------------------------------------------------

        // NOTE: Step 3: Merge SPONSOR_API_FEEDS and API_FEEDS
        // console.log('API_FEEDS ---> ', API_FEEDS);
        // console.log('SPONSOR_API_FEEDS --> ', SPONSOR_API_FEEDS);
        // console.log('PROMOTED_API_FEEDS --> ', PROMOTED_API_FEEDS);

        if (SPONSOR_API_FEEDS && SPONSOR_API_FEEDS.length > 0) {
          // if there is SPONSOR_API_FEEDS, then choose first-one SPONSOR_API_FEEDS and first-two API_FEEDS
          let totalSponsored = (SPONSOR_API_FEEDS.length > 2) ? 3 : ((SPONSOR_API_FEEDS.length > 1) ? 2 : 1);
          SPONSOR_API_FEEDS = SPONSOR_API_FEEDS.slice(0, totalSponsored); // [{...SPONSOR_API_FEEDS[0]}];
          // if SPONSOR_API_FEEDS length is less than 3, then use/append API_FEEDS
          if (API_FEEDS && API_FEEDS.length) {
            let totalFeeds = 2;
            switch (totalSponsored) {
              case 3:
                totalFeeds = 0;
                break;
              case 2:
                totalFeeds = 1;
                break;
              case 1:
                totalFeeds = 2;
                break;
              case 0:
                totalFeeds = 3;
                break;
              default:
            }
            API_FEEDS = API_FEEDS.slice(0, totalFeeds);
          }
        } else {
          if (API_FEEDS && API_FEEDS.length) {
            API_FEEDS = API_FEEDS.slice(0, 3);
          }
        }

        // console.log('API_FEEDS ---> ', API_FEEDS);
        // console.log('SPONSOR_API_FEEDS --> ', SPONSOR_API_FEEDS);
        // console.log('PROMOTED_API_FEEDS --> ', PROMOTED_API_FEEDS);

        let finalArr = [...SPONSOR_API_FEEDS, ...API_FEEDS, ...PROMOTED_API_FEEDS].slice(0, 3);
        // if (API_FEEDS && API_FEEDS.length > 0) {
        //   finalArr = [...SPONSOR_API_FEEDS, ...API_FEEDS, ...PROMOTED_API_FEEDS].slice(0, 3);
        // }

        // console.log('----- relayted insights data -----');
        // console.log(finalArr);
        // console.log('----------------------------------');

        // NOTE: Step 4: Dispatch 'relatedInsights' into 'plugins' reducer state
        // ---------------------------------------------------------------------------------------------------
        yield put(relatedInsightSuccess({
          relatedInsights: finalArr,
        }));
        // ---------------------------------------------------------------------------------------------------
      } else {
        throw '-- Related Insights is disabled in config --';
      }

    } else {
      throw '-- payload is missing --'
    }
  } catch (error) {
    console.log('----- error -----> ', error);
    //Pass both SPONSOR_API_FEEDS and API_FEEDS  In case step 2 is failing
    if (SPONSOR_API_FEEDS && SPONSOR_API_FEEDS.length > 0) {
      let _finalArr = [...SPONSOR_API_FEEDS, ...API_FEEDS];
      let _updatedFinalArr = _finalArr.slice(0,3);
      yield put(relatedInsightSuccess({
        relatedInsights: _updatedFinalArr,
      }));
    } else {
      yield put(relatedInsightSuccess({
        relatedInsights: [...SPONSOR_API_FEEDS, ...API_FEEDS],
      }));
    }
  }
}

// '1NBzg6mg'

// function* relatedInsightRequest(data) {
//   const { payload } = data;
//   try {
//     if (payload.query) {
//       yield put(relatedInsightFetch());
//       const response = yield call(pluginsAPI.getRelatedInsightsData, { query: payload.query });
//       if (response) {
//         let feeds = response.result.data.feed;
//         if (feeds) {
//           const newArr = feeds.filter(item => item.media_urls.length > 0);
//           if (newArr.length) {
//             newArr.length = 2;
//             const arr = [];
//             arr.push(newArr);
//             arr.push(response.result.data.details)
//             yield put(relatedInsightSuccess({
//               relatedInsights: arr,
//             }));
//           } else {
//             throw 'media_urls in response not found';
//           }
//         } else {
//           throw 'empty response';
//         }
//       } else {
//         throw '--- Failed to respond ---';
//       }
//     }
//   } catch (error) {
//     console.log('error -------->>>>>>>>>>>>>>>>>>>>>>>>>>', error)
//     yield put(relatedInsightSuccess({
//       relatedInsights: [],
//     }));
//   }
// }

function* pluginSaga() {
  yield all([
    fork(takeEvery, ActionTypes.FETCH_RELATED_INSIGHTS_DATA_REQUEST, relatedInsightRequest),
  ])
}

export default pluginSaga;

import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import Holdings from './Holdings';
import AssetAllocation from  './AssetAllocation';
import Risk from  './Risk';
import Returns from  './Returns';
import SearchResults from './SearchResults';
import NotFound from './NotFound';
import { ShowSpinner } from '../../Shared/ShowSpinner';
import { addEventToAnalytics } from 'Utils';

const { TabPane } = Tabs;

class TabComponents extends React.Component {
  constructor(props) {
    super(props)
  }  

  onChange = activeKey => {
    if(activeKey == "2"){
      addEventToAnalytics('Client Allocate Asset Tab', 'Client Allocate Asset Tab', 'CLIENT_ALLOCATE_ASSET_TAB', {}, false)
    }else if(activeKey == "3"){
      addEventToAnalytics('Client Allocate Risk Tab', 'Client Allocate Risk Tab', 'CLIENT_ALLOCATE_RISK_TAB', {}, false)
    }else if(activeKey == "4"){
      addEventToAnalytics('Client Allocate Returns Tab', 'Client Allocate Returns Tab', 'CLIENT_ALLOCATE_RETURNS_TAB', {}, false)
    }
    this.props.setSelectedTabIndex(activeKey);
  };

  render() {
    // Temporary change: Using acc_no key to disable tabs
    // Should be removed once proper values are assigned to the client
    const { selectedClientHoldings, modelSearchResults, modelSearchQuery, selectedModel } = this.props;
    const isDisabled = selectedClientHoldings.holdings && selectedClientHoldings.holdings.length;
    return (
      <>
        { modelSearchQuery ? <SearchResults {...this.props} /> : 
          <Tabs activeKey={this.props.selectedTabIndex} onChange={this.onChange} className={this.props.selectedTabIndex == 1 ? "tab-holdings" : ''}>
            <TabPane tab="Holdings" key="1">
              {selectedModel ? <Holdings /> : <NotFound />}
            </TabPane>
            <TabPane tab="Asset Allocation" key="2" disabled={!isDisabled}>
              {isDisabled ? <AssetAllocation /> : <ShowSpinner />}
            </TabPane>
            <TabPane tab="Risk" key="3" disabled={!isDisabled}>
              {isDisabled ? <Risk /> : <ShowSpinner />}
            </TabPane>
            <TabPane tab="Return" key="4" disabled={!isDisabled}>
              {isDisabled ? <Returns /> : <ShowSpinner />}
            </TabPane>
          </Tabs>
        }
      </>
    )
  }
}

const mapStateToProps = ({ clientportfolio: { selectedClientHoldings, modelSearchResults, modelSearchQuery } }) => ({
  selectedClientHoldings,
  modelSearchResults,
  modelSearchQuery
})

export default connect(
  mapStateToProps
)(TabComponents);
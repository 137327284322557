import React from 'react';
import { BarChartS1 } from 'components/charts/Recharts';
import { cn, D3AppColors09, kmb } from 'DataSet';
import { Button } from 'reactstrap';
import { addEventToAnalytics, dateToMonth, getNest, numDc } from 'Utils';
import { DateRangeSelector } from '../Common';
import { variablesWithoutDateRange } from '../utils';

const Chart = (props) => {
  const { idx, attr, items, filterScoreattrs, dropdownScoreAttrs, commonActiveSFMReturnsRange } = props;
  // console.log(' ---- attr ---- ');
  // console.log(attr);
  const { name, col, code, colOff, suffix, prefix, parser, multiplier = 1 } = attr;
  const parserFN = parser ? val => kmb(val || 0) : '';
  // const getMultiplier = (col, multiplier) => {
  //   const multiplierList = ['weight_bid_ask', 'trkerror', 'weight_premium_to_nav',
  //     'weight_trend', 'outperformance_3y', 'outperformance_5y', 'outperformance_10y',
  //     'alpha_5y', 'alpha_10y'];
  //   return multiplierList.includes(col) ? 100 : multiplier;
  // }
  const data = items.map((e, i) => {
    // console.log(e);
    // console.log(col);
    // console.log(getNest(['_sfStats', col], e));
    let out = {
      name: cn(e, 'Ticker Short'),
      color: D3AppColors09[i % D3AppColors09.length],
    }
    const decimalPrec = col === 'weight_bid_ask' ? 3 : 2;
    // const multiplier = getMultiplier(col);
    let labelNA = ((col === 'weight_bid_ask' || col === 'weight_premium_to_nav' || col === 'vol') && e.type === 'MF') ? 'NA' : '';
    if (getNest(['_sfStats', col], e) === null || typeof getNest(['_sfStats', col], e) === 'undefined') { labelNA = 'NA' }

    return getNest(['_sfStats', colOff], e)
      ? { ...out, value: 0.0, label: 'Inactive', decimalPrec, labelNA }
      : { ...out, value: numDc(getNest(['_sfStats', col], e) * multiplier, decimalPrec), decimalPrec, labelNA }
    // : { ...out, value: numDc(getNest(['_sfStats', col], e) * multiplier, decimalPrec), decimalPrec }
  });
  const maxScoreAttrs = (filterScoreattrs || []).length

  // console.log(props.dateRange[code]);
  //'Since ' + dateToMonth(props.dateRange[code].start)
  // console.log(props);
  // console.log(code, props.dateRange[code]);
  // console.log(props.dateRange);


  return (
    <div style={{ padding: '0 2px 2px' }}>
      <div className="p-1 bg-white rounded">
        <div className="bg-ssf-gray-l1">
          <div className="d-flex align-items-center px-2" style={{ height: '20px' }}>
            <div className="h6 mb-0 font-weight-bold">{name}</div>
            {
              idx !== 1 && <Button color={"select"} outline
                style={{ padding: '2px' }}
                className="h6 mb-0 lh-100 rounded-0 ml-2 sfmodal-btn-arw"
                title="Increase importance"
                onClick={() => {
                  props.scoreAttrsHandler(name, 'up')
                  addEventToAnalytics('Compare weight reorder', 'Compare weight reorder', 'COMPARE_WEIGHT_REORDER', { name: items.query, logId: items.logId, name: 'UP' }, true);
                }}>
                <i className="fas fa-fw fa-arrow-alt-up"></i></Button>
            }
            {
              idx !== maxScoreAttrs && <Button color={"select"} outline
                style={{ padding: '2px' }}
                className="h6 mb-0 lh-100 rounded-0 ml-2 sfmodal-btn-arw"
                title="Decrease importance"
                onClick={() => {
                  props.scoreAttrsHandler(name, 'down')
                  addEventToAnalytics('Compare weight reorder', 'Compare weight reorder', 'COMPARE_WEIGHT_REORDER', { query: items.query, logId: items.logId, name: 'DOWN' }, true);
                }}>
                <i className="fas fa-fw fa-arrow-alt-down"></i></Button>
            }
            {!variablesWithoutDateRange.includes(name) &&
              ((props.dateRange[code]) && props.range[code] && commonActiveSFMReturnsRange && commonActiveSFMReturnsRange.start && commonActiveSFMReturnsRange.end && typeof props.displayYear === 'undefined' && typeof (props.dateRange[code]).start !== 'undefined') &&
              (
                <div className="ml-auto">
                  <DateRangeSelector
                    col={code}
                    range={props.range[code]}
                    activeLabel={props.sinceDate}
                    rangeHandler={props.rangeHandler}
                    dropdownScoreAttrs={dropdownScoreAttrs}
                    fromDatePicker={props.range.fromDatePicker}
                    dateRange={commonActiveSFMReturnsRange || props.dateRange[code]}
                  />
                </div>
              )
            }
            {!variablesWithoutDateRange.includes(name) && dropdownScoreAttrs && dropdownScoreAttrs.find(c => c.code === code && c.yearDropdown) &&
              ((props.dateRange[code]) && props.range[code] && commonActiveSFMReturnsRange && commonActiveSFMReturnsRange.start && commonActiveSFMReturnsRange.end && typeof props.displayYear !== 'undefined' && typeof (props.dateRange[code]).start !== 'undefined') &&
              (
                <div className="ml-auto">
                  <DateRangeSelector
                    dropdownScoreAttrs={dropdownScoreAttrs}
                    rangeHandler={props.rangeHandler}
                    col={code}
                    range={props.range[code]}
                    activeLabel={props.sinceDate}
                    displayYear={props.displayYear}
                    dateRange={commonActiveSFMReturnsRange || props.dateRange[code]}
                    fromDatePicker={props.range.fromDatePicker}
                  />
                </div>
              )
            }
          </div>
          <div className="px-1 text-gray-3 chart-wrap" style={{ height: 89 }}>
            <BarChartS1 data={data} suffix={suffix} prefix={prefix} parser={parser} showTickerOnAxis />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chart;

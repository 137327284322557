import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Row, Col, Button, Menu, Spin, notification } from 'antd';
import { RightOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';

import { createLoadingSelector } from 'selectors/loading-selector';

import {
	setEnhancerState,
	resetFundsPortfolioRequest,
	deselectAllEnhancerFunds,
	savePortfolioRequest,
	checkTotumAdvisorRequest,
	submitTotumAdvisorRequest,
	loadCPMListRequest,
	resetTotumScore,
	resendTotumInviteRequest,
	getCurrentPortfolioScoreRequest,
	getEnhancedPortfolioScoreRequest,
	getTotumQuestionnaireStatusRequest,
} from 'actions/enhancer';
import { profileDataRequest } from 'actions/profile';

import { getClientDetailsForTotum } from 'layouts/utils';
import { addEventToAnalytics } from 'Utils';

import TotumSection from '../../Elements/TotumSection';
// import Evaluate from './Evaluate';

class ContentHead extends Component {
	constructor(props) {
		super(props);
		this.state = {

		}
	}

	componentWillReceiveProps(newProps) {
		const { resetTotumScore } = newProps;
		// console.log('resettingFinalizeData ---->', resettingFinalizeData);
		if ((newProps.isItRenderingSummaryPageContent &&
			newProps.isItRenderingSummaryPageContent !== this.props.isItRenderingSummaryPageContent) || (
				newProps.resettingFinalizeData &&
				newProps.resettingFinalizeData !== this.props.resettingFinalizeData)
		) {
			if (resetTotumScore) {
				resetTotumScore();
				setTimeout(() => {
					this.totumRenderButtonOnClick();
				}, 500)
			}
		}
	}

	componentWillUnmount() {
		const { resetTotumScore } = this.props;
		if (resetTotumScore) {
			resetTotumScore();
		}
	}

	routeToOnBoardScreen = () => {
		addEventToAnalytics('Enhancer Edit Home', 'Enhancer Edit Home', 'ENHANCER_EDIT_HOME', {}, false);
		const { history, resetFundsPortfolioRequest } = this.props;
		if (resetFundsPortfolioRequest && history) {
			resetFundsPortfolioRequest('', () => {
				const newPath = `/enhancer/onboard-screen`;
				history.push(newPath);
			})
		}
	}

	drawerToggle = () => {
		const { summaryDrawer, openDrawer } = this.props;
		if (openDrawer) {
			openDrawer(!summaryDrawer);
		}
	}

	finalize = () => {
		const { finalizeClick, enhanceReplace } = this.props;
		let analytics_data = enhanceReplace.map(item => {
			return {
				ticker: item.ticker,
				replace: item.replace
			}
		})
		addEventToAnalytics('Enhancer Finalize', 'Enhancer Finalize', 'ENHANCER_FINALIZE', { funds: analytics_data, query_uuid: this.props.query_uuid, category: 'advisory_PTC' }, false);  //
		if (finalizeClick) {
			finalizeClick();
		}
	}

	generateProposal = () => {
		const { generateProposalClick } = this.props;
		if (generateProposalClick) {
			//true for enhancer report
			generateProposalClick(false);
		}
	}

	generateRecommendationReport = () => {
		const { generateProposalClick } = this.props;
		if (generateProposalClick) {
			//true for recommendation report
			generateProposalClick(true);
		}
	}

	generateTradelist = () => {
		const { generateTradeListClick } = this.props;
		if (generateTradeListClick) {
			generateTradeListClick();
		}
	}

	savePortfolio = () => {
		addEventToAnalytics('Enhancer Save Btn', 'Enhancer Save Btn', 'ENHANCER_SAVE_BTN', { slider: this.state.label }, false)
		const { savePortfolioClick } = this.props;
		if (savePortfolioClick) {
			savePortfolioClick();
		}
	}

	deselectAllFunds = () => {
		addEventToAnalytics('Deselect All Enhancer Funds', 'Deselect All Enhancer Funds', 'DESELECT_ALL_ENHANCER_FUNDS', { query_uuid: this.props.query_uuid }, false)//
		const { deselectAllEnhancerFunds } = this.props;
		if (deselectAllEnhancerFunds) {
			deselectAllEnhancerFunds();
		}
	}

	handleNameSubmit = (e) => {
		const { history, portfolioType, savePortfolioRequest, portfolioId, enhancerData, setEnhancerState } = this.props;
		const enhancedPortfolioId = portfolioId;
		this.setState({ editName: false })
		savePortfolioRequest({
			portfolio_id: enhancedPortfolioId,
			portfolio_name: e.target.value,
			portfolioType,
		}, (response) => {
			if (response && history) {
				let urlParam = [];
				for (var i in response) {
					urlParam.push(encodeURIComponent(i) + "=" + encodeURIComponent(response[i]));
				}
				const newPath = `/enhancer/dashboard/summary?${urlParam.join("&")}`;
				history.push(newPath);
				let enhanceData = enhancerData;
				enhanceData['portfolioName'] = response.portfolioId;
				enhanceData['query'] = '';
				setEnhancerState(enhanceData);
			}
		});
	}

	totumRenderButtonOnClick = () => {
		const { getCurrentPortfolioScoreRequest, getEnhancedPortfolioScoreRequest, enhanceReplace, totumScore, finalizeData, isItRenderingSummaryPageContent, tickerNotSupported } = this.props;
		let { portfolioFunds } = this.props;

		if (isItRenderingSummaryPageContent &&
			finalizeData &&
			finalizeData.portfolioName &&
			finalizeData.summary &&
			typeof finalizeData.summary !== 'undefined' &&
			typeof finalizeData.summary.retData !== 'undefined' &&
			finalizeData.summary.retData.length > 0 &&
			typeof finalizeData.summary.portfolioFunds !== 'undefined' &&
			finalizeData.summary.portfolioFunds.length > 0
		) {
			portfolioFunds = finalizeData.summary.portfolioFunds;
		}

		const { scoreMeter } = totumScore;
		let orderedScoreMeter = _.orderBy(scoreMeter.filter((item) => item.value !== null), ['value'], ['asc']);
		let scores = orderedScoreMeter.map((item) => item.value);

		if (getCurrentPortfolioScoreRequest && getEnhancedPortfolioScoreRequest && portfolioFunds && enhanceReplace) {
			// FOR ENAHNCED TICKER, DO NOT CALL 'risk-score api' AFTER FINALIZE
			if (!isItRenderingSummaryPageContent && finalizeData && typeof finalizeData.summary === 'undefined') {
				// NOTE: check for 'current' score
				if (scores.length === 0) {
					// call request for 'current' score
					let allData = [...portfolioFunds, ...tickerNotSupported];
					const requestData = allData.map((item) => {
						return {
							ticker: item.ticker,
							weight: item.weight,
						};
					});
					if (requestData && requestData.length > 0) {
						getCurrentPortfolioScoreRequest({
							requestData,
							For: 'current',
						});
					}
				}
				// NOTE: get for 'enhanced' score
				let enhancedSelectedFunds = enhanceReplace.map((item) => item.selectedPortfolioFunds) || [];
				if (enhancedSelectedFunds && enhancedSelectedFunds.length > 0) {
					let enhanceAllData = [...enhancedSelectedFunds, ...tickerNotSupported];
					let requestData = enhanceAllData.map((k) => {
						return {
							ticker: k.ticker,
							weight: k.weight,
						}
					}) || [];
					if (requestData && requestData.length > 0) {
						getEnhancedPortfolioScoreRequest({
							requestData,
							For: 'enhanced',
						});
					}
				} else {
					console.log('--- NO ENHANCED REPLACEMENT TICKER FOUND FOR SCORE ---');
				}
			} else {
				// call when FINALIZE data is present
				// NOTE: check for 'current' score
				if (scores.length === 0) {
					// call request for 'current' score
					let allData = [...portfolioFunds, ...tickerNotSupported];
					const requestData = allData.map((item) => {
						return {
							ticker: item.ticker,
							weight: item.weight,
						};
					});
					if (requestData && requestData.length > 0) {
						getEnhancedPortfolioScoreRequest({
							requestData,
							For: 'enhanced',
						});
					}
				}
			}
		}
	}

	sendEmailOnClick = (data, callback) => {
		const { submitTotumAdvisorRequest } = this.props;
		if (submitTotumAdvisorRequest && data) {
			submitTotumAdvisorRequest(data);
		}
		setTimeout(() => {
			const { loadCPMListRequest } = this.props
			if (loadCPMListRequest) {
				loadCPMListRequest()
			}
			if (callback) {
				callback();
			}
		}, 2000)
	}

	activateOnClick = (callback) => {
		const { advisorId, checkTotumAdvisorRequest, profileDataRequest } = this.props;
		if ((advisorId === null || !advisorId || advisorId === '') && checkTotumAdvisorRequest) {
			checkTotumAdvisorRequest();
		}
		setTimeout(() => {
			if (profileDataRequest) {
				profileDataRequest()
			}
			if (callback) {
				callback();
			}
		}, 2000)
	}

	loadScoreData = () => {
		const { advisorId, clientList, totumScore, portfolioName } = this.props;
		let { showScoreMeter } = getClientDetailsForTotum(advisorId, clientList, totumScore, portfolioName);
		if (showScoreMeter) {
			this.totumRenderButtonOnClick();
		}
	}

	getQuestionnaireStatus = (callBack) => {
		const { advisorId, clientList, totumScore, portfolioName, getTotumQuestionnaireStatusRequest } = this.props;
		if (getTotumQuestionnaireStatusRequest) {
			let {
				clientId,
				advisorName,
				advisorEmail,
			} = getClientDetailsForTotum(advisorId, clientList, totumScore, portfolioName);
			if (clientId) {
				getTotumQuestionnaireStatusRequest({
					clientId,
					portfolioName,
				}, (res) => {
					// console.log('-- res --', res);
					if (callBack) {
						callBack(res);
					}
				});
			} else {
				console.log('-- clientId is missing --');
			}
		}
	}

	resendTotumMail = (formData = {}) => {
		// console.log('== resendTotumMail formData ==', formData);
		const { advisorId, clientList, totumScore, portfolioName, resendTotumInviteRequest } = this.props;
		if (resendTotumInviteRequest && formData && formData.client_id) {
			// if (resendTotumInviteRequest && advisorId && clientList && totumScore && portfolioName) {
			// let {
			// 	clientId,
			// 	advisorName,
			// 	advisorEmail,
			// } = getClientDetailsForTotum(advisorId, clientList, totumScore, portfolioName);
			// let firstName = advisorName.split(' ').length > 0 ? advisorName.split(' ')[0] : advisorName;
			// let lastName = advisorName.split(' ').length > 1 ? advisorName.split(' ')[1] : advisorName;
			resendTotumInviteRequest({
				...formData,
			}, (res) => {
				if (res) {
					// NOTE: trigger notification
					// console.log(res);
					if (res && res.result && res.result.message) {
						notification.open({
							description: 'Email sent successfully.',
							className: 'saveProfile_toastSuccess',
							duration: 2,
							message: null,
							top: 50,
						})
					} else {
						notification.open({
							description: 'Failed to process.',
							className: 'saveProfile_toastFail',
							duration: 2,
							message: null,
							top: 50,
						})
					}
				}
			})
		}
	}

	beforeFinalizeGenerateProposal = () => {
		const { beforeFinalizeGenerateProposal } = this.props;
		if (beforeFinalizeGenerateProposal) {
			beforeFinalizeGenerateProposal();
		}
	}

	render() {
		const {
			portfolioName,
			readableQuery,
			guidanceMessage,
			finalizeData,
			fundPortfolios,
			enhanceReplace,
			redirectToPath,
			portfolioType,
			sponsoredPortfolioName,
			// portfolioId,
			advisorId,
			clientList,
			totumScore,
			totumRiskLoading,
			CPMLoading,
			profileData,
			totumAccessEmail,
			emailSubmitLoading,
			resendingTotumEmail,
			performanceImpactLoading,
			isItRenderingSummaryPageContent,
			smallScreen,
			mobileDevice
		} = this.props;

		const noData = !fundPortfolios.length || !enhanceReplace.filter(item => item.replace !== item.ticker).length;

		let {
			min,
			max,
			clientId,
			advisorName,
			resendEmail,
			advisorEmail,
			showScoreMeter,
			allScoreMeter,
			orderedScoreMeter,
			renderScoreValues,
			totumClientDetails,
			renderAllScoreValues,
		} = getClientDetailsForTotum(advisorId, clientList, totumScore, portfolioName, isItRenderingSummaryPageContent);

		const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

		const FINALIZED_PORTFOLIO = (finalizeData &&
			finalizeData.portfolioName &&
			finalizeData.summary &&
			finalizeData.onboardData &&
			finalizeData.enhancedPortfolioId &&
			finalizeData.onboardData.selectedPortfolioType &&
			typeof finalizeData.summary !== 'undefined' &&
			typeof finalizeData.summary.retData !== 'undefined' &&
			finalizeData.summary.retData.length > 0 &&
			typeof finalizeData.summary.portfolioFunds !== 'undefined' &&
			finalizeData.summary.portfolioFunds.length > 0);

		const FINALIZED_STATE_FOR_CTA = (finalizeData &&
			finalizeData.portfolioName &&
			finalizeData.summary &&
			typeof finalizeData.summary !== 'undefined' &&
			typeof finalizeData.summary.retData !== 'undefined')

		console.log(portfolioType, 'portfolioType')
		const colSpan = portfolioType === "Client Portfolio" ? {
			firstCol: {
				span: 12,
				md: 6,
				lg: 6,
				xl: 11,
			},
			secondCol: {
				span: 12,
				md: 18,
				lg: 18,
				xl: 13,
			},
		} : {
			firstCol: {
				span: 18,
			},
			secondCol: {
				span: 6,
			},
		};
		return (
			<Fragment>
				<div id="funds-board-head" className="funds-board-head">
					<Row id="funds-board-content-header" className="pf-content-header m-0">
						<Col span={24} className="d-flex col-wrap">
							<Col span={20} className="enhancer-portfolio-name-container">
								<span style={{ color: '#494949' }}>Enhancement Ideas For </span>
								<span>
									{(portfolioType && portfolioType === 'MarketPlace' && sponsoredPortfolioName && sponsoredPortfolioName !== '') ? sponsoredPortfolioName : portfolioName}
								</span>
							</Col>
							<Col span={4} className="d-flex align-items-center" style={{ justifyContent: 'flex-end' }}>
								<Button
									shape="round"
									className="primary-button return-home-button"
									type="primary"
									style={{ padding: 0 }}
									onClick={this.routeToOnBoardScreen}
								>
									<img width="28px" src="/assets/images/reload.svg" />
								</Button>
							</Col>
						</Col>
						{(readableQuery && readableQuery !== '') && (
							<>
								{(FINALIZED_PORTFOLIO) ? (
									<></>
								) : (
									<Col span={24} className="d-flex pf-showing-result-label col-wrap showing-result-col">
										<Col span={18}>
											{guidanceMessage && guidanceMessage.length > 0 ? guidanceMessage[0] : `Showing results for “${readableQuery}“`}
										</Col>
										<Col span={6} className="d-flex align-items-center evaluate-col" style={{ justifyContent: 'flex-end' }}>
											{/* <Evaluate /> */}
										</Col>
									</Col>
								)
								}
							</>
						)}
					</Row>
					{(smallScreen || mobileDevice) ?
						<Row wrap={false} className={`cta-panel-container m-0`}>
							<Col span={19} md={19}>
								<Menu className="enhancer-controls-menu-container" mode="horizontal" style={{ width: '100%' }}>
									{!(FINALIZED_STATE_FOR_CTA) && (
										<Menu.Item>
											<Button
												className="enhancer-primary-cta-button"
												disabled={performanceImpactLoading || noData}
												style={{ cursor: (performanceImpactLoading || noData) ? 'progress' : 'pointer' }}
												onClick={this.finalize}
											>
												Finalize
											</Button>
										</Menu.Item>
									)}
									{(FINALIZED_STATE_FOR_CTA) && (
										<Menu.Item>
											<Button
												className="enhancer-primary-cta-button"
												disabled={performanceImpactLoading}
												style={{ cursor: (performanceImpactLoading) ? 'progress' : 'pointer' }}
												onClick={this.generateTradelist}
											>
												Generate Tradelist
											</Button>
										</Menu.Item>
									)}
									{!(FINALIZED_STATE_FOR_CTA) && (
										<Menu.Item>
											<Button
												icon={<img className="icon prefix-icon" src="/assets/images/icons/pdf.svg" style={{ marginRight: '5px' }} />}
												className="enhancer-primary-cta-button"
												disabled={performanceImpactLoading || noData}
												style={{ cursor: (performanceImpactLoading || noData) ? 'progress' : 'pointer' }}
												onClick={this.beforeFinalizeGenerateProposal}
											>
												Generate Proposal
											</Button>
										</Menu.Item>
									)}
									{(finalizeData &&
										finalizeData.portfolioName &&
										finalizeData.summary &&
										typeof finalizeData.summary !== 'undefined' &&
										typeof finalizeData.summary.retData !== 'undefined') && (
											<Menu.Item>
												<Button
													disabled={performanceImpactLoading}
													className="enhancer-primary-cta-button"
													onClick={this.savePortfolio}
												>
													{redirectToPath && redirectToPath !== '' ? "SAVE & RETURN" : "SAVE PORTFOLIO"}
												</Button>
											</Menu.Item>
										)}
									{(portfolioType !== 'Client Portfolio') && !(FINALIZED_STATE_FOR_CTA) && (
										<Menu.Item>
											<Button
												icon={<img className="icon prefix-icon" src="/assets/images/icons/notebook.svg" style={{ marginRight: '5px' }} />}
												className="reverse-primary-cta-button"
												onClick={this.generateRecommendationReport}
												disabled={performanceImpactLoading || noData}
												style={{ cursor: (performanceImpactLoading || noData) ? 'progress' : 'pointer' }}
											>
												Comparison Report
											</Button>
										</Menu.Item>
									)}

									{/* {!(FINALIZED_STATE_FOR_CTA) && (
										<Menu.Item>
											<Button
												icon={<img className="icon prefix-icon" src="/assets/images/icons/notebook.svg" style={{ marginRight: '5px' }} />}
												className="reverse-primary-cta-button"
												onClick={this.generateRecommendationReport}
												disabled={performanceImpactLoading || noData}
												style={{ cursor: (performanceImpactLoading || noData) ? 'progress' : 'pointer' }}
											>
												Comparison Report
											</Button>
										</Menu.Item>
									)}
									{(FINALIZED_STATE_FOR_CTA) && (
										<Menu.Item>
											<Button
												className="enhancer-primary-cta-button"
												disabled={performanceImpactLoading}
												style={{ cursor: (performanceImpactLoading) ? 'progress' : 'pointer' }}
												onClick={this.savePortfolio}
											>
												{redirectToPath && redirectToPath !== '' ? "SAVE & RETURN" : "SAVE PORTFOLIO"}
											</Button>
										</Menu.Item>
									)} */}
								</Menu>
							</Col>
							<Col span={5} md={5} flex="auto" className="d-flex align-items-center" style={{ justifyContent: 'flex-end' }}>
								<Button
									type="link"
									className="custom-link-button"
									style={{ textDecoration: 'underline' }}
									onClick={this.drawerToggle}>
									Performance Summary
								</Button>
							</Col>
							{(portfolioType === 'Client Portfolio') && !(FINALIZED_STATE_FOR_CTA) && (
								<Col span={5} md={7} xl={5} className="d-flex d-flex flex-column justify-content-center">
									<Menu className="enhancer-controls-menu-container" mode="horizontal">
										{/* {!(FINALIZED_STATE_FOR_CTA) && ( */}
										<Menu.Item>
											<Button
												icon={<img className="icon prefix-icon" src="/assets/images/icons/notebook.svg" style={{ marginRight: '5px' }} />}
												className="reverse-primary-cta-button"
												onClick={this.generateRecommendationReport}
												disabled={performanceImpactLoading || noData}
												style={{ cursor: (performanceImpactLoading || noData) ? 'progress' : 'pointer' }}
											>
												Comparison Report
											</Button>
										</Menu.Item>
										{/* )} */}
									</Menu>
								</Col>
							)}
							{(portfolioType === 'Client Portfolio') && (<Col span={19} md={17} xl={19} flex="none" className={`d-flex align-items-center ${(!CPMLoading && showScoreMeter) ? 'pt-3 pb-3' : 'pt-2 pb-2'}`} style={{ width: '60%', minWidth: 300, justifyContent: 'flex-end' }}>
								{(portfolioType === 'Client Portfolio') && (
									<Fragment>
										{!CPMLoading ? (
											<TotumSection
												min={min}
												max={max}
												clientId={clientId}
												advisorId={advisorId}
												profileData={profileData}
												advisorName={advisorName}
												resendEmail={resendEmail}
												advisorEmail={advisorEmail}
												portfolioType={portfolioType}
												allScoreMeter={allScoreMeter}
												scoreMeter={orderedScoreMeter}
												showScoreMeter={showScoreMeter}
												totumAccessEmail={totumAccessEmail}
												totumRiskLoading={totumRiskLoading}
												renderScoreValues={renderScoreValues}
												emailSubmitLoading={emailSubmitLoading}
												totumClientDetails={totumClientDetails}
												resendingTotumEmail={resendingTotumEmail}
												renderAllScoreValues={renderAllScoreValues}
												loadScoreData={this.loadScoreData}
												resendLink={(formData) => this.resendTotumMail(formData)}
												getQuestionnaireStatus={(callBack) => this.getQuestionnaireStatus(callBack)}
												activateOnClick={(callback) => this.activateOnClick(callback)}
												sendEmailOnClick={(data, callback) => this.sendEmailOnClick(data, callback)}
												totumRenderButtonOnClick={this.totumRenderButtonOnClick}
											/>
										) : (
											<div className="totum-risk-bar-container">
												<div className="totum-risk-bar-loader">
													<Spin indicator={antIcon} />
												</div>
											</div>
										)}
									</Fragment>
								)}
							</Col>)
							}
						</Row> :
						<Row wrap={false} className={`cta-panel-container m-0 ${(!CPMLoading && showScoreMeter) ? 'pt-3 pb-3' : 'pt-2 pb-2'}`}>
							<Col {...colSpan['firstCol']}>
								<Menu className="enhancer-controls-menu-container" mode="horizontal" style={{ width: '100%' }}>
									{!(FINALIZED_STATE_FOR_CTA) && (
										<Menu.Item>
											<Button
												className="enhancer-primary-cta-button"
												disabled={performanceImpactLoading || noData}
												style={{ cursor: (performanceImpactLoading || noData) ? 'progress' : 'pointer' }}
												onClick={this.finalize}
											>
												Finalize
											</Button>
										</Menu.Item>
									)}
									{(FINALIZED_STATE_FOR_CTA) && (
										<Menu.Item>
											<Button
												className="enhancer-primary-cta-button"
												disabled={performanceImpactLoading}
												style={{ cursor: (performanceImpactLoading) ? 'progress' : 'pointer' }}
												onClick={this.generateTradelist}
											>
												Generate Tradelist
											</Button>
										</Menu.Item>
									)}
									{!(FINALIZED_STATE_FOR_CTA) && (
										<Menu.Item>
											<Button
												icon={<img className="icon prefix-icon" src="/assets/images/icons/pdf.svg" style={{ marginRight: '5px' }} />}
												className="enhancer-primary-cta-button"
												disabled={performanceImpactLoading || noData}
												style={{ cursor: (performanceImpactLoading || noData) ? 'progress' : 'pointer' }}
												onClick={this.beforeFinalizeGenerateProposal}
											>
												Generate Proposal
											</Button>
										</Menu.Item>
									)}
									{!(FINALIZED_STATE_FOR_CTA) && (
										<Menu.Item>
											<Button
												icon={<img className="icon prefix-icon" src="/assets/images/icons/notebook.svg" style={{ marginRight: '5px' }} />}
												className="reverse-primary-cta-button"
												onClick={this.generateRecommendationReport}
												disabled={performanceImpactLoading || noData}
												style={{ cursor: (performanceImpactLoading || noData) ? 'progress' : 'pointer' }}
											>
												Comparison Report
											</Button>
										</Menu.Item>
									)}
									{(FINALIZED_STATE_FOR_CTA) && (
										<Menu.Item>
											<Button
												className="enhancer-primary-cta-button"
												disabled={performanceImpactLoading}
												style={{ cursor: (performanceImpactLoading) ? 'progress' : 'pointer' }}
												onClick={this.savePortfolio}
											>
												{redirectToPath && redirectToPath !== '' ? "SAVE & RETURN" : "SAVE PORTFOLIO"}
											</Button>
										</Menu.Item>
									)}
								</Menu>
							</Col>
							<Col {...colSpan['secondCol']} className="d-flex d-flex flex-column justify-content-center">
								<Row>
									<Col flex="none" className="d-flex align-items-center" style={{ width: '60%', minWidth: 300, justifyContent: 'flex-end' }}>
										{(portfolioType === 'Client Portfolio') && (
											<Fragment>
												{!CPMLoading ? (
													<TotumSection
														min={min}
														max={max}
														clientId={clientId}
														advisorId={advisorId}
														profileData={profileData}
														advisorName={advisorName}
														resendEmail={resendEmail}
														advisorEmail={advisorEmail}
														portfolioType={portfolioType}
														allScoreMeter={allScoreMeter}
														scoreMeter={orderedScoreMeter}
														showScoreMeter={showScoreMeter}
														totumAccessEmail={totumAccessEmail}
														totumRiskLoading={totumRiskLoading}
														renderScoreValues={renderScoreValues}
														emailSubmitLoading={emailSubmitLoading}
														totumClientDetails={totumClientDetails}
														resendingTotumEmail={resendingTotumEmail}
														renderAllScoreValues={renderAllScoreValues}
														loadScoreData={this.loadScoreData}
														resendLink={(formData) => this.resendTotumMail(formData)}
														getQuestionnaireStatus={(callBack) => this.getQuestionnaireStatus(callBack)}
														activateOnClick={(callback) => this.activateOnClick(callback)}
														sendEmailOnClick={(data, callback) => this.sendEmailOnClick(data, callback)}
														totumRenderButtonOnClick={this.totumRenderButtonOnClick}
													/>
												) : (
													<div className="totum-risk-bar-container">
														<div className="totum-risk-bar-loader">
															<Spin indicator={antIcon} />
														</div>
													</div>
												)}
											</Fragment>
										)}
									</Col>
									<Col flex="auto" className="d-flex align-items-center" style={{ justifyContent: 'flex-end' }}>
										<Button
											type="link"
											className="custom-link-button"
											style={{ textDecoration: 'underline' }}
											onClick={this.drawerToggle}>
											Performance Summary
											{/* <RightOutlined /> */}
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>
					}
				</div>
			</Fragment >
		)
	}
}

const mapStateToProps = ({ router, loading, enhancer, profile: { profile: _profile }, global }) => ({
	location: router.location,
	enhancerData: enhancer,
	profileData: _profile,
	portfolioName: enhancer.portfolioName,
	fundPortfolios: enhancer.fundPortfolios || [],
	enhanceReplace: enhancer.enhanceReplace,
	portfolioType: enhancer.portfolioType,
	sponsoredPortfolioName: enhancer.sponsoredPortfolioName,
	query_uuid: enhancer.query_uuid,
	portfolioId: enhancer.portfolioId,
	clientList: enhancer.clientList,
	portfolioFunds: enhancer.summary.portfolioFunds,
	totumScore: enhancer.totumScore,
	advisorId: _profile.totum_advisor_id || null,
	totumAccessEmail: _profile.email || null,
	tickerNotSupported: enhancer.summary.tickerNotSupported || [],
	CPMLoading: createLoadingSelector(['LOAD_CPM_LIST'])({ loading }),
	emailSubmitLoading: createLoadingSelector(['SUBMIT_TOTUM_ADVISOR'])({ loading }),
	resendingTotumEmail: createLoadingSelector(['RESEND_TOTUM_INVITE_EMAIL'])({ loading }),
	totumRiskLoading: createLoadingSelector(['GET_ENHANCED_PORTFOLIO_SCORE', 'GET_CURRENT_PORTFOLIO_SCORE', 'PROFILE_DATA'])({ loading }),
	performanceImpactLoading: createLoadingSelector(['GET_CURRENT_PORTFOLIO_STATS', 'GET_ENHANCED_STATS_FOR_SELECTED_TICKER'])({ loading }),
	bigScreen: global.bigScreen,
	desktopScreen: global.desktopScreen,
	smallScreen: global.smallScreen,
	mobileDevice: global.mobileDevice,
});

const mapDispatchToProps = {
	setEnhancerState,
	resetFundsPortfolioRequest,
	deselectAllEnhancerFunds,
	savePortfolioRequest,
	checkTotumAdvisorRequest,
	submitTotumAdvisorRequest,
	loadCPMListRequest,
	resetTotumScore,
	resendTotumInviteRequest,
	profileDataRequest,
	getCurrentPortfolioScoreRequest,
	getEnhancedPortfolioScoreRequest,
	getTotumQuestionnaireStatusRequest,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ContentHead);

import React, { Component } from 'react';
// import moment from 'moment';
// import { isEqual, find, uniqBy } from 'lodash';
import { connect } from 'react-redux'
// import Slider from "react-slick";
import { Pagination, Tooltip } from 'antd';
import { setPopOverOptionState, setProfileState, subscriptionPlan } from 'actions/profile'
import { InnerPageFooter } from 'shared-components/'
import {
  fetchFundsPrice,
  updateFundsData,
  refreshFundsState,
  searchQueryRequest,
  setFundsState,
  savePopoverOption,
  setUniverse,
  getFundDataForDrawerRequest,
} from 'actions/discovery';
import { getPortfolioDetailsForSFMRequest } from 'actions/sfm';
import { setGlobalState } from 'actions/global';
// import DetailsModal from 'components/detailsModal';
import { addEventToAnalytics, clearWindowUtmSession } from 'Utils';
// import { calcCombineStatsWeighted, calcAttrStats, calcScores } from 'Stats';
import { cn } from 'DataSet';
import { isNull } from 'util';
import ItemCard from '../ItemCard';
// import Spinner from '../Elements/Spinner';
// import FundDetailDrawer from '../Elements/FundDetailDrawer';
// import { getItemListData } from './utils';
import { customScroll } from '../../../utils';
// import AddMoney from '../../SFM/ActTab/AddMoney'
import TradingExecutePopup from '../../Trading/TradingExecutePopup'

// const classNames = require('classnames');

// let shareCombineState;

class List extends Component {
  constructor(props) {
    super(props)
    this.state = {
      range: { return: 'active', risk: 'active' },
      confirm: false,
      justTrackFundsState: props.sfmTrackFundsState || [],
      combineStats: [],
      // showExecuteOrderPopup: false,
      user_return_prefer: props.user_return_prefer,
      user_risk_prefer: props.user_risk_prefer,
      fundDetailVisible: false,
      openFundDetailDrawerFor: {},
    }
  }

  setReturnPopover = (value) => {
    const { loggedIn, savePopoverOption, setPopOverOptionState } = this.props
    this.setState({
      user_return_prefer: value
    })
    setPopOverOptionState({ user_return_prefer: value })
    loggedIn && savePopoverOption({ 'returns': value })
    addEventToAnalytics('Return Popover Select', 'Return Popover Select', 'RETURN_POPOVER_SELECT', { user_return_prefer: value }, true);
  }

  setRiskPopover = (value) => {
    const { loggedIn, savePopoverOption, setPopOverOptionState } = this.props
    this.setState({
      user_risk_prefer: value
    })
    setPopOverOptionState({ user_risk_prefer: value })
    loggedIn && savePopoverOption({ 'risk': value })
    addEventToAnalytics('Risk Popover Select', 'Risk Popover Select', 'RISK_POPOVER_SELECT', { user_risk_prefer: value }, true);
  }

  componentDidMount() {
    const { getPortfolioDetailsForSFMRequest, profileReducerState, sfm } = this.props;
    this.fetchPrice();
    customScroll()
    // const parent = document.getElementById("search-related-wrapper");
    // if (parent) {
    //   const child = document.getElementById("itemListArea");
    //   child.style.paddingBottom = parent.offsetHeight === 35 ? 48 + "px" : 95 + "px";
    // }
    // need to refresh holding values by calling upload-portfolio-results api
    if (getPortfolioDetailsForSFMRequest && profileReducerState) {
      if (profileReducerState.apex && profileReducerState.apex.account && profileReducerState.apex.account.length && profileReducerState.apex.account[0].account_no) {
        getPortfolioDetailsForSFMRequest({ accountNumber: profileReducerState.apex.account[0].account_no });
      }
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clearAllFunds || newProps.selectedCards.length === 0) {
      // CLEARING ALL LOCAL justTrackFundsState
      this.resetTrackFundsState()
    }
  }

  componentWillUnmount() {
    if (this.priceTimer) {
      clearInterval(this.priceTimer)
      this.priceTimer = null
    }
    if (this.props.modalToggleState) {
      const { modalToggleState, setSFMState } = this.props
      setSFMState({
        modal: false,
        modalToggleState: false
      })
    }
  }

  resetTrackFundsState = () => {
    this.setState({
      justTrackFundsState: []
    })
    return true
  }

  fetchPrice = () => {
    // intentionally used this.props.funds! (believe me dont change this)
    if (this.props.discovery.data && this.props.discovery.data.length && this.props.discovery.ticks && this.props.discovery.ticks.length) {
      const { fetchFundsPrice } = this.props;
      if (this.priceTimer) clearInterval(this.priceTimer);
      this.priceTimer = setInterval(() => fetchFundsPrice({
        data: this.props.discovery.data,
        ticks: this.props.discovery.ticks,
      }), 61000);
    }
  }

  handleClick = (e) => {
    addEventToAnalytics('Search Result Pagination', 'Search Result Pagination', 'SEARCH_RESULT_PAGINATION', { pageNumber: e }, true);
    const { setFundsState, searchQueryRequest, discovery, query } = this.props;
    // const { requestPayloadSort } = discovery;
    let search_type = "search_bar";
    if (window.sessionStorage.getItem('search_type')) {
      search_type = window.sessionStorage.getItem('search_type');
    }
    setFundsState({
      currentPage: e,
      // pageLoader: true,
    });
    clearWindowUtmSession();
    searchQueryRequest({
      query,
      search_type,
      page: e
    });
  }

  toggleExecuteOrderPopup = (eraseData = false) => {
    console.log('=== toggleExecuteOrderPopup ====', eraseData);
    if (eraseData) {
      const { fetchProfile, setSFMState } = this.props;
      if (fetchProfile) {
        fetchProfile();
      }
      if (setSFMState) {
        setSFMState({ sfmTradeOrderResponse: {}, autoOpenModal: undefined, modal: false, modalToggleState: false })
      }
    }
    // this.setState({ showExecuteOrderPopup: !this.state.showExecuteOrderPopup });
  }

  openDrawer = (fundDetailVisible) => {
    this.setState({ fundDetailVisible });
  }

  openFundDetailDrawer = (openFundDetailDrawerFor) => {
    if (openFundDetailDrawerFor && (openFundDetailDrawerFor.type === 'BASKET' || openFundDetailDrawerFor.vehicle === 'BASKET') && openFundDetailDrawerFor.ticker) {
      console.log('=== Drawer for ===', openFundDetailDrawerFor.ticker);
      // this.setState({ openFundDetailDrawerFor });
      const { getFundDataForDrawerRequest } = this.props;
      if (getFundDataForDrawerRequest) {
        getFundDataForDrawerRequest({
          basket_ticker: openFundDetailDrawerFor.ticker,
        }, () => {
          this.openDrawer(true);
        });
      }
    }
  }

  goBackfromMoreStocks = () => {
    const { clearFundsApiLoadPercent, previousSearch: { universe }, setUniverse, query, searchQueryRequest } = this.props;
    const defaultPageNo = 1;

    addEventToAnalytics('Go Back From More Stocks Page', 'Go Back From More Stocks Page', 'GO_BACK_FROM_MORE_STOCKS_PAGE', {}, true);
    if (setUniverse) {
      setUniverse({
        universe,
        page: defaultPageNo,
        showGoBackSection: false,
        showGoBackSectionClicked :true,
      });
    }

    clearWindowUtmSession();
    if (clearFundsApiLoadPercent) {
      clearFundsApiLoadPercent();
    }

    if (searchQueryRequest) {
      searchQueryRequest({
        query,
        page: defaultPageNo,
        search_type: 'search_bar',
      });
    }
  }

  moreStocksOnClick = () => {
    const { searchQueryRequest, clearFundsApiLoadPercent, setUniverse, fundsUniverse, query } = this.props;
    const defaultPageNo = 1;

    addEventToAnalytics('More Stocks Like Share', 'More Stocks Like Share', 'MORE_STOCKS_LIKE_SHARE', {'SEARCH':'Share'}, true);
    if (setUniverse) {
      setUniverse({
        page: defaultPageNo,
        universe: ['share'],
        previousSearch: {
          universe: fundsUniverse,
        },
        showGoBackSection: true,
        isMoreStocksClicked: true,
      });
    }

    clearWindowUtmSession();
    if (clearFundsApiLoadPercent) {
      clearFundsApiLoadPercent();
    }

    if (searchQueryRequest) {
      searchQueryRequest({
        query,
        page: defaultPageNo,
        search_type: 'search_bar',
      });
    }
  }

  render() {
    const {
      fundsData,
      currentPage,
      resultCount,
      sponsors_tickers,
      showGoBackSection,
      sfmTradeOrderResponse,
    } = this.props;

    let firstPopIndex = null;
    let lastPage = resultCount / 10;
    if (lastPage && !Number.isInteger(lastPage)) {
      lastPage = Math.trunc(lastPage) + 1;
    }

    const renderDesign = fundsData.map((e, i) => {
      if (e._hidden) return null
      if (isNull(firstPopIndex)) firstPopIndex = i
      return (
        <div
          key={`k_${i}_${cn(e, 'Id')}`}
          className="mb-2 popoverContainer"
        >
          <ItemCard
            {...this.props}
            itemsKey="itemsKey"
            card={e}
            index={i}
            k={`k_${i}_${cn(e, 'Id')}`}
            sponsors_tickers={sponsors_tickers}
            selectedCard={e._selected}
            firstPopIndex={firstPopIndex}
            learnMoreLink={e._learnMoreLink}
            userReturnPrefer={this.state.user_return_prefer}
            userRiskPrefer={this.state.user_risk_prefer}
            setReturnPopover={this.setReturnPopover}
            setRiskPopover={this.setRiskPopover}
            selectHandler={this.props.selectHandler}
            openFundDetailDrawer={this.openFundDetailDrawer}
            moreStocksOnClick={this.moreStocksOnClick}
          />
        </div>
      )
    });

    return (
      <React.Fragment>
        {showGoBackSection && (
          <div className='go-back-section'>
            <span onClick={() => this.goBackfromMoreStocks()}><img src='/assets/images/left_arrow_svg.svg'/><span>Go Back To All Results</span></span>
          </div>
        )}
        <div className={`itemListArea custom-scroll popoverContainer ${showGoBackSection && 'pt-0'}`} id="itemListArea" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <div>
            {renderDesign}
          </div>
          {resultCount > 10 && fundsData.filter((im) => !im._hidden).length > 0 &&
            <div className='pagination-wrapper'>
              <Tooltip placement="left" title='First Page'>
                {currentPage == 1 ?
                  <img className='jump-icn flip disabled' src='/assets/images/icons/SideArrow-deactive.svg' alt='' />
                  : <img className='jump-icn flip' src='/assets/images/icons/SideArrow-Active.svg' alt='' onClick={() => this.handleClick(1)} />
                }
              </Tooltip>
              <Pagination
                size="small"
                current={currentPage || 1}
                total={resultCount}
                onChange={(e) => this.handleClick(e)}
                className='funds-pagination'
                showSizeChanger={false}
              />
              <Tooltip placement="right" title='Last Page'>
                {lastPage == currentPage ?
                  <img className='jump-icn disabled' src='/assets/images/icons/SideArrow-deactive.svg' alt='' />
                  : <img className='jump-icn' src='/assets/images/icons/SideArrow-Active.svg' alt='' onClick={() => this.handleClick(lastPage)} />
                }
              </Tooltip>
            </div>
          }
          {/*
            <FundDetailDrawer
              closable
              mask={false}
              visible={fundDetailVisible}
              openDrawer={this.openDrawer}
            />
          */}
          <InnerPageFooter style={{ width: "100%", borderTop: "1px solid #d5cbcb", textAlign: "center", fontFamily: "Open Sans", fontSize: "12px", padding: ".4em", background: "#f7f7f7", marginTop: "50px" }} />
        </div>
        {(Object.keys(sfmTradeOrderResponse).length > 0) && (
          <TradingExecutePopup
            quicktrade={this.props.quicktrade}
            data={sfmTradeOrderResponse}
            history={this.props.history}
            open={(Object.keys(sfmTradeOrderResponse).length > 0)}
            toggle={this.toggleExecuteOrderPopup}
            sfm={this.props.sfm}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ discovery, sfm, auth, global, profile, weights, quicktrade }) => ({
  discovery,
  sfm,
  quicktrade,
  inviteSentTo: profile.inviteSentTo,
  sponsors_tickers: discovery.sponsorsTickers,
  inviteReferralCode: profile.inviteReferralCode,
  inviteFailed: profile.inviteFailed,
  inviteSuccess: profile.inviteSuccess,
  inviteError: profile.error,
  inviteMessage: profile.inviteMessage,
  profileReducerState: profile.profile,
  surveyModal: global.surveyModal,
  loading: discovery.actions,
  query: discovery.query,
  fundsTicker: discovery.ticks,
  fundsData: discovery.data,
  resultCount: discovery.resultCount,
  showGoBackSection: discovery.showGoBackSection,
  fundsUniverse: discovery.universe,
  stockFunds: discovery.stockFunds,
  SHOWING_ALL_STOCKS: discovery.SHOWING_ALL_STOCKS,
  // sponsoredData: discovery.sponsored_data,
  fundsResponse: discovery.fundsResponse,
  previousSearch: discovery.previousSearch,
  correctedQuery: discovery.correctedQuery,
  chartData: discovery.chartData || [],
  openFundDetailDrawerFor: discovery.openFundDetailDrawerFor,
  loggedIn: auth.loggedIn,
  premiumUser: auth.user.premiumUser,
  sfmTradeOrderResponse: sfm.sfmTradeOrderResponse,
  user_return_prefer: profile.profile.user_return_prefer ? profile.profile.user_return_prefer : 'Annualized Return',
  user_risk_prefer: profile.profile.user_risk_prefer ? profile.profile.user_risk_prefer : 'Annualized Volatility',
});

const mapDispatchToProps = {
  fetchFundsPrice,
  updateFundsData,
  refreshFundsState,
  setPopOverOptionState,
  setGlobalState,
  setProfileState,
  subscriptionPlan,
  searchQueryRequest,
  setFundsState,
  setUniverse,
  savePopoverOption,
  getFundDataForDrawerRequest,
  getPortfolioDetailsForSFMRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)

import React, { Component, Fragment } from 'react';
import { Cell, PieChart, Pie } from 'recharts';

import NotApplicable from 'components/NotApplicable';

export const GaugeColors = [
  '#65b8f7', '#55a9e8', '#1b8be0',
];

const formatNumberValue = (value, fraction) => {
  const leftPart = (value + '').split('.')[0];

  if (leftPart.length >= 4 && leftPart.length < 7) {
    return (value / 1000).toFixed(fraction) + 'K';
  } else
  if (leftPart.length >= 7 && leftPart.length < 10) {
    return (value / 1000000).toFixed(fraction) + 'M';
  } else
  if (leftPart.length >= 10) {
    return (value / 1000000000).toFixed(fraction) + 'B';
  } else {
    return value.toFixed(fraction)
  }
};

export const ViewChart = (props) => {
  const { data } = props;
  // console.log(props);
  const { view_name, Secondary_view_name, variable, queryVar, _viewData, width } = data;
  // console.log('here card', {view_name, data});

  if (!_viewData) return <div className="not-applicable-icon-wrapper">
    <NotApplicable />
  </div>;

  const { decimal, value, suffix, min, max } = _viewData;

  let name = view_name + (Secondary_view_name ? ` (${Secondary_view_name})` : '');

  if (variable && variable.includes('correlation')) {
    name = name.replace('Overlap', 'Correlation');
  }

  let _min = min, _max = max, _value = value;

  if (!_value || _value === '' || _value === 'NA') return (
    <div className="not-applicable-icon-wrapper" style={{ marginTop: 7 }}>
      <NotApplicable />
    </div>
  );

  const deTailsWidth = width || '100px';
  return (
    <div className="siw-meter print-wrapper">
      <div className="siw-meter-title" title={name}>{name}</div>
      <GaugeChart
        chartWidth={width}
        value={_value}
        max={_max}
        min={_min}
      />
      <div className="d-flex meter-value" style={{ width: deTailsWidth, justifyContent :"space-between" }}>
        <span className="siw-meter-min" style={{ minWidth: 25 }}>
          {_min}{suffix}
        </span>
        <span className="siw-meter-value">
          {_value}{suffix}
        </span>
        <span className="siw-meter-max">
          {_max}{suffix}
        </span>
      </div>
    </div>
  );
};

const GaugeChart = ({ value, min, max, pc, chartWidth }) => {
  const width = chartWidth || 100;
  const sumValues = 300;
  const colorData = GaugeColors.slice(0, 3).map(e => ({ value: 100, color: e }));
  const chartValue = sumValues*(pc !== undefined ? pc : (value-min)/(max - min));

  const arrowData = [
    { value: chartValue },
    { value: 0 },
    { value: sumValues - chartValue }
  ];

  const pieProps = {
    startAngle: 180, endAngle: 0,
      cx: width / 2, cy: width / 2
  };

  const pieRadius = {
    innerRadius: (width / 2) * 0.60,
    outerRadius: (width / 2) * 0.95
  };

  const Arrow = ({ cx, cy, midAngle, outerRadius }) => {
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle),
          cos = Math.cos(-RADIAN * midAngle);
    const mx = cx + (outerRadius - width * 0.03) * cos,
          my = cy + (outerRadius - width * 0.03) * sin;
    return (
      <g>
        <circle cx={cx} cy={cy} r={3} fill="#999" stroke="none"/>
        <path d={`M${cx},${cy}L${mx},${my}`} strokeWidth="2" stroke="#999" fill="none" strokeLinecap="round"/>
      </g>
    );
  };

  return (
    <PieChart width={width} height={(width / 2) + 5} margin={{top: 0, right: 0, left: 0, bottom: 5}} aspect={1.5}>
      <Pie data={colorData} dataKey="value" fill="#8884d8" isAnimationActive={false}
        { ...pieRadius } { ...pieProps } >
        { colorData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colorData[index].color} />
          ))
        }
      </Pie>
      <Pie
        stroke="none" fill="none"
        activeIndex={1} activeShape={ Arrow }
        data={ arrowData } dataKey="value"
        outerRadius={ pieRadius.innerRadius }
        { ...pieProps }
      />
    </PieChart>
  );
};

export default {
  ViewChart,
};

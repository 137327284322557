import React from 'react';

import { Row, Col } from 'antd';
import { kmb } from 'DataSet';
import { quintileSize, quintileText, getImage } from 'Utils';
import { getFRRAttrData, textCapitalize } from 'layouts/utils';

export const FeeReturnRisk = ({ FRR }) => {
  return (
    <Row className="no-gutters FRR-wrapper">
      { FRR.map(e => (
          <Col span={8} key={e.attr}>
            <QuintileIconText {...e} />
          </Col>
      )) }
    </Row>
  );
}

const QuintileIconText = ({ attr, name, icon, iconImg, value, label, highlightClass }) => (
  <div className={`${attr}-flag-color FRR-container px-2 py-2 ${highlightClass}`} style={{ height: '100%', textAlign: 'center' }}>
    <p className="__state" style={{ margin: 0, fontSize: 10, height: 20, lineHeight: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{name}</p>
    {(iconImg !== null && icon === null) ? (
      <img className="far fa-fw" src={getImage(iconImg)} width="auto" height={32} />
    ) : (
      <i className={`far fa-fw fassf ${icon}`} />
    )}
    <p className="__val" style={{ margin: 0, fontSize: 11, lineHeight: 1, fontWeight: 'bold' }}>{value}</p>
    {/* <p className="__state" style={{ margin: 0, fontSize: 10, lineHeight: 1, height: 10 }}>{label}</p> */}
  </div>
)

import React from 'react';

import { cn } from 'DataSet';
import { getNest } from 'Utils';

import ItemCard from './ItemCard';

const classNames = require('classnames');
const comingSoon = ['MF', 'SMA', 'MP', 'CEF'];

class ItemList extends React.Component {
  componentDidMount() {
    const elems = document.querySelectorAll('.custom-scroll');
    if (elems.length) {
      [].forEach.call(elems, function(el) {
        if (el.scrollHeight > el.clientHeight) {
          el.style.paddingRight = '.25rem';
          el.style.paddingLeft = '.40rem';
        } else el.style.paddingRight = '0rem';
      });
    }
  }

  render() {
    const items = this.props.items.filter(e => getNest(['_order', 'stage'], e) === 'buy' && getNest(['_order', 'state', 'valid'], e) && !comingSoon.includes(e.vehicle));
    if (items.length === 0) return null;
    return (
      <div className="h-425 custom-scroll">
        {items.map((e, i) => {
          return (
            <div key={cn(e, 'Id')}>
              <ItemCard card={e} idx={i} clearHandler={this.props.clearHandler} brokers={this.props.brokers} handleCheckbox={this.props.handleCheckbox} />
            </div>
          )
        })}
      </div>
    );
  }
}

export default ItemList;

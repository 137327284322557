import zipObject from 'lodash/zipObject'

const actions = [
  'GET_TRENDING_SEARCHES',
  'GET_TRENDING_SEARCHES_LOADING',
  'GET_TRENDING_SEARCHES_SUCCESS',
  'GET_TRENDING_SEARCHES_ERROR',

  'GET_VIOLATOR_SEARCHES',
  'GET_VIOLATOR_SEARCHES_LOADING',
  'GET_VIOLATOR_SEARCHES_SUCCESS',
  'GET_VIOLATOR_SEARCHES_ERROR'
]

export default zipObject(actions, actions)

import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import Slider from "react-slick";

const SETTINGS = {
	dots: true,
	infinite: true,
	slidesToShow: 3,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 2000,
	rtl: true,
	vertical: true,
	verticalSwiping: true,
	pauseOnHover: true,
};

let arr = [], arrToMap = [], index = 0, limit = 4, intervalIsPaused = false;

class TopFeeds extends Component {
  constructor(props){
    super(props);
		this.slider = React.createRef();
  }

	componentDidMount() {
		const { queryList, initialList } = this.props;
		if (queryList && queryList.length > 0) {
			arrToMap = queryList;
			initialList.forEach((item, i) => {
				if (initialList[i]) {
					let node = document.createElement("div");
					node.className = "marquee-child feeds-type-writer " + i;
					node.setAttribute('aria-data', initialList[i]);
					node.addEventListener("click", this.queryHandler);
					let textnode = document.createTextNode(initialList[i]);
					node.appendChild(textnode);
					let list = document.getElementById("marquee-feed");
					list.insertBefore(node, list.childNodes[0]);
				}
			});
			index = initialList.length;
			this.startInterval();
		}
	}

	startInterval = (data) => {
		this.interval = setInterval(() => {
			if (!intervalIsPaused) {
				if (arrToMap[index]) {
					let node = document.createElement("div");
					node.className = "marquee-child feeds-type-writer " + index;
					node.setAttribute('aria-data', arrToMap[index]);
					node.addEventListener("click", this.queryHandler);
					let textnode = document.createTextNode(arrToMap[index]);
					node.appendChild(textnode);
					let list = document.getElementById("marquee-feed");
					list.insertBefore(node, list.childNodes[0]);
					index++
				} else {
					index = 0
					let list = document.getElementById("marquee-feed");
					if (list && list.childNodes.length > limit && list.childNodes.length > arrToMap.length) {
						for (let i = 0; i < limit; i++) {
							list.removeChild(list.childNodes[list.childNodes.length - 1]);
						}
					}
				}
				let topFeedWrapperElem = document.getElementById("top-feeds-wrapper");
				let currentColor = topFeedWrapperElem.style.backgroundColor;
				if (topFeedWrapperElem) {
					if (index % 2) {
						if (currentColor === 'rgb(228, 228, 228)') {
							topFeedWrapperElem.style.backgroundColor = 'rgba(27, 139, 224, 0.25)';
						} else {
							topFeedWrapperElem.style.backgroundColor = 'rgb(228, 228, 228)';
						}
					}
				}
			}
		}, SETTINGS.autoplaySpeed);
	}

	resumeInterval = (event, data) => {
		this.startInterval(data);
	}

	pauseInterval = (event, data) => {
		if (this.interval) {
			clearInterval(this.interval);
			this.interval = null;
		}
	}

	componentWillUnmount() {
		if (this.interval) {
			clearInterval(this.interval);
			this.interval = null;
		}
	}

  executeCall = (query) => {
    const { executeCall } = this.props;
    executeCall(query, 'related_searches');
  }

  queryHandler = (event) => {
		const elem = event.target;
		if (elem) {
			const data = elem.getAttribute('aria-data');
			const { queryHandler } = this.props;
			if (queryHandler && data) {
				queryHandler(data, 'trending_searches');
			}
		}
  }

  render() {
    const { queryHandler, initialList, queryList } = this.props;
		const settings = {
			...SETTINGS,
			focusOnSelect: true,
			autoplay: false,
      beforeChange: function(currentSlide, nextSlide) {
        // console.log("before change", currentSlide, nextSlide);
      },
      afterChange: function(currentSlide) {
        console.log("current ", currentSlide);
      }
		};

    return (
      <React.Fragment>
				<div id="top-feeds-wrapper" className={`top-feeds-wrapper`}>
					<div className="marquee-area" onMouseOver={() => this.pauseInterval()} onMouseOut={() => this.resumeInterval()}>
						<div id="marquee-feed"></div>
					</div>
				</div>
      </React.Fragment>
    )
  }
}

export default TopFeeds;

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import $ from 'jquery'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { PrimaryButton } from 'shared-components'
import Tab from './Tab'

const magnifiLogo =
  '/assets/images/MagnifiLogo/Magnifi_Tagline_White_RGB@2x.png'

class Spoof extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      open: true,
      alert: true,
      showFunDetails: false,
      selectedFunDetails: undefined
    }
    this.inRef = React.createRef()
    this.bw = document.body.clientWidth
  }

  componentDidMount() {
    document.body.classList['remove']('modal-open')
    document.body.classList[this.state.modal ? 'add' : 'remove'](
      'modal-open-sfm'
    )
    setTimeout(() => {
      this.setState({ alert: false })
    }, 2000)
    $('.sfp-backdrop').click(this.toggle) // hack as bockdrop click not working
    this.props.confirmHandler(false)
  }

  componentWillReceiveProps(newProps) {
    if (
      !this.state.modal &&
      newProps.items.length !== this.props.items.length
    ) {
      this.setState({ alert: true })
      setTimeout(() => {
        this.setState({ alert: false })
      }, 2000)
    }
  }

  componentWillUnmount() {
    $('.sfp-backdrop').unbind('click', this.toggle)
    document.body.classList['remove']('modal-open-sfm')
  }

  toggle = () => {
    this.props.modalToggle();
    // this.props.history.push('/login');
    // this.props.history.push('/login')
    // this.setState({ modal: !this.state.modal }, () => {
    //   document.body.classList[this.state.modal ? 'add' : 'remove'](
    //     'modal-open-sfm'
    //   )
    //   this.props.modalToggle(this.state.modal ? 'add' : 'remove')
    //   // this.inRef.current.style.paddingRight = `${this.state.modal ? document.body.clientWidth-this.bw : 0}px`;
    // })
  }

  clearAllHandler = () => {
    this.setState({ open: false }, () => {
      setTimeout(() => {
        this.props.clearHandler('all', true)
      }, 300)
    })
  }

  open = () => {
    this.setState({ open: !this.state.open }, () => {})
  }

  toggleFunDetails = selectedFunDetails => {
    if (window.innerWidth < 760)
      this.setState(prevState => ({
        showFunDetails: !prevState.showFunDetails,
        selectedFunDetails
      }))
  }

  render() {
    const { showFunDetails, selectedFunDetails, open, modal } = this.state
    const { items } = this.props
    const size = this.state.modal ? 'full' : this.state.alert ? 'anim' : 'min'
    const closeBtn = (
      <button
        className={`close ${open ? 'media-close' : ''} ${
          showFunDetails ? 'd-none' : ''
        }`}
        onClick={this.toggle}
      >
        {/* <i className={`fas text-white icon-cancel d-md-none ${this.state.modal && !showFunDetails ? "d-sm-block":"d-none"} `}></i> */}
        <div
          className={`hamburger ${
            size === 'full' && !showFunDetails ? ' active' : ''
          } `}
        >
          <div className="hamburger-box">
            <div className="hamburger-inner"></div>
          </div>
        </div>
        <i
          className={`far text-white ${
            this.state.modal ? 'fa-minus' : 'fa-plus'
          }`}
        ></i>
      </button>
    )

    const renderBtn = showFunDetails ? (
      this.state.modal ? (
        <div
          className="media-arrow d-md-none d-sm-block modal-back-arrow"
          onClick={() => this.toggleFunDetails(selectedFunDetails)}
        >
          <i className="fas fa-arrow-left" style={{ color: '#fff' }} />
        </div>
      ) : (
        <div
          className="media-arrow d-md-none d-sm-block modal-back-arrow"
          onClick={this.toggle}
        >
          <i className="fas fa-plus" style={{ color: '#fff' }} />
        </div>
      )
    ) : (
      closeBtn
    )

    return (
      <Modal
        className="fund-container"
        isOpen={this.state.open}
        toggle={this.toggle}
        backdrop={true}
        modalClassName={'sfp ' + size}
        innerRef={this.inRef}
        className="modal-lg"
        contentClassName="bg-ssf-blue-d2"
        backdropClassName="sfp-backdrop"
      >
        <ModalHeader
          className="media-details-tab bg-sfmodal-header text-white modal-header-title-body"
          toggle={this.toggle}
          close={renderBtn}
          tag="div"
        >
          <div className="modal-header-title-container" onClick={this.toggle}>
            <img className={`fas fa-briefcase mr-3 ${
                showFunDetails ? 'd-none' : ''
              }`} src='/assets/images/icons/shopping-cart-White-circle.svg' alt='' />
            {/* <i
              className={`fas fa-briefcase mr-3 ${
                showFunDetails ? 'd-none' : ''
              }`}
            ></i> */}
            <span onClick={this.toggle} className="font-weight-bold 1">
              {`${
                items.length && !showFunDetails
                  ? items.length > 1
                    ? items.length + ' Funds Selected'
                    : items.length + ' Fund Selected'
                  : 'Fund Details'
              }`}
            </span>
          </div>
        </ModalHeader>
        <ModalBody className="p-0 bg-sfmodal-grey font-one spoof-body-container">
          {open && open && <Tab />}
        </ModalBody>
      </Modal>
    )
  }
}

export default withRouter(Spoof)

  import zipObject from 'lodash/zipObject'

const actions = [
  'UPDATE_PREMIUM_STATE',
  'SET_AUTH_STATE',

  'AUTH_REQUEST',
  'AUTH_FETCH',
  'AUTH_SUCCESS',
  'AUTH_FAILURE',

  'GOOGLE_AUTH_REQUEST',
  'GOOGLE_AUTH_SUCCESS',
  'GOOGLE_AUTH_FAILURE',

  'APPLE_AUTH_REQUEST',
  'APPLE_AUTH_SUCCESS',
  'APPLE_AUTH_FAILURE',

  'TERMS_AGREEMENT_REQUEST',
  'TERMS_AGREEMENT_SUCCESS',
  'TERMS_AGREEMENT_FAILURE',

  'LOGOUT_REQUEST',
  'LOGOUT_SUCCESS',
  'LOGOUT_FAILURE',

  'SEND_CHANGE_PWD_EMAIL',
  'VERIFY_CHANGE_PWD_TOKEN',
  'FORGOT_CHANGE_PASSWORD',
  'SET_OLD_PASSWORD',
  'SAVE_VERIFY_CHANGE_PWD_TOKEN',
  'SAVE_SET_PASSWORD',

  'SET_ADVISOR_DATA',
  'SELECT_DOMAIN_TO_REDIRECT',

  'OKTA_AUTH_LOGIN_REQUEST',
  'OKTA_AUTH_LOGIN_FETCH',
  'OKTA_AUTH_LOGIN_SUCCESS',

  'SET_OKTA_AUTH_LOADER',
  'SET_CALENDAR_INVITE_MODAL_STATE',

  'AUTO_LOGIN_BY_TOKEN_REQUEST',
  'AUTO_LOGIN_BY_TOKEN_FETCH',
  'AUTO_LOGIN_BY_TOKEN_SUCCESS',

  'REFRESH_AUTH_STATE',

  'OKTA_CHANGE_PASSWORD_REQUEST',
  'OKTA_CHANGE_PASSWORD_FETCH',
  'OKTA_CHANGE_PASSWORD_SUCCESS',

  'RESEND_PASSWORD_RESET_EMAIL_REQUEST',
  'RESEND_PASSWORD_RESET_EMAIL_FETCH',
  'RESEND_PASSWORD_RESET_EMAIL_SUCCESS',
]

export default zipObject(actions, actions)

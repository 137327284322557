import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  ButtonGroup,
  Modal,
  ModalBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

import { PrimaryButton, SecondaryButton, TickIcon } from 'shared-components'
import { getQuery } from 'common/auth-utils'
import { logout, termsAgreement } from 'actions/auth'
import { isTermsAgreed, isNewUser } from 'selectors/auth-selector'
import { createLoadingSelector } from 'selectors/loading-selector'
import { Spinner } from 'shared-components/'
import { setPortfolioReplace, setEnhancedTransaction,saveEnhancedTransactionData } from 'actions/portfolio';
import { fetchImportedAccounts } from 'apis/broker';
import { CSVLink } from "react-csv";

class GenerateTradeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
          downloadTradeList: true,
          tradeListDropdown:false,
          tradeListValue:'',
          accountDropdown:false,
          brokerDropdownValue : '',
          accountNumber:[],
          accounNoDropdown:false,
          selectAccountNumber:'',
          tradeList:'',
        }
        this.csvLink = React.createRef();
        this.fidelityCsvLink=React.createRef();
        this.schwabCsvLink=React.createRef();
      }

    async componentDidMount() {

    let response = await fetchImportedAccounts()
    if( response.status == 200 && response.data != null) {

        const brokerData = response.data;

        var values = Object.entries(brokerData).map(([k, v]) => ({[k]: v}))
        this.setState({
        brokerName:values,
        });
    }
       this.onDownloadTradeList();
    }

  handleDisagree = () => {
    this.props.logout()
  }

  handleAgree = () => {
    this.props.termsAgreement()
  }

  accountDropdownToggle =() => {
    this.setState({ accountDropdown: !this.state.accountDropdown });
  }

  accountNoDropdownToggle =() => {
    this.setState({ accounNoDropdown: !this.state.accounNoDropdown });
  }

  tradeListDropDownToggle =() =>{
    this.setState({ tradeListDropdown:!this.state.tradeListDropdown });
  }

  onCloseDownloadTradeList = () => {
    this.setState({ downloadTradeList: false });
    if(this.state.tradeListValue == '' && this.state.brokerDropdownValue == '' && this.state.brokerDropdownValue == ''){
      this.setState({tradeListValue:''});
      this.setState({brokerDropdownValue : ''});
      this.setState({selectAccountNumber : ''});
      this.props.OnDirectClose();
    }
    else {
     this.setState({tradeListValue:''});
     this.setState({brokerDropdownValue : ''});
     this.setState({selectAccountNumber : ''});
     this.props.onCloseClick();
    }
  }

  selectTradeList = (item) =>{
    this.setState({tradeListValue:item});
     this.setState({brokerDropdownValue : ''});
     this.setState({selectAccountNumber : ''});
  }

  getAccountName = (item) => {

    const accountArray = [];

    this.state.brokerName.map((i,key) => {

      if(item === Object.keys(i).toString()){
        accountArray.push(i);
      }
    });

    const ids = [];
      JSON.stringify(accountArray, (key, value) => {
        if (key === 'account_number') ids.push(value);
        return value;
      });

    this.setState({
      accountNumber:ids,
    });

    this.setState({
      brokerDropdownValue:item,
    });

    this.setState({selectAccountNumber : ''});
  }

  onDownloadTradeList = () => {
    this.setState({
      downloadTradeList: true,
    })

    // const { filename } = this.props;
    // this.props.setEnhancedTransaction(filename);
  }

  getAccountNumber = (item) => {
    this.setState({
      selectAccountNumber:item,
    })
  }

  downloadOrionTradeListExcel = () => {

    const { fetchretData, enhance_transaction, quantity } = this.props;

      var buyTicker = enhance_transaction.map((i) => {
        if (i.ticker !== null) {
          if(i.old_portfolio_shares == null)
          {
              var info = {
                  "account_number":this.state.selectAccountNumber,
                  "ticker": i.ticker,
                  "transaction":"SL",
                  "price":0,
                  "quantity":0,
              }
          }
          else {
              var info = {
                  "account_number":this.state.selectAccountNumber,
                  "ticker": i.ticker,
                  "transaction":"SL",
                  "price":i.old_portfolio_price,
                  "quantity":i.old_portfolio_shares,
              }
          }
          return info;
        }
      }).filter((item) => typeof item !== 'undefined');

      console.log(buyTicker);

      var selTicker = enhance_transaction.map((i) => {
        if (i.replace_ticker !== null) {
          if (i.enhance_portfolio_shares == null)
          {
                var info = {
              "account_number":this.state.selectAccountNumber,
              "ticker": i.replace_ticker,
              "transaction":"B",
              "price":0,
              "quantity":0,
            }
          }
          else {
              var info = {
                  "account_number":this.state.selectAccountNumber,
                  "ticker": i.replace_ticker,
                  "price":i.enhance_portfolio_price,
                  "transaction":"B",
                  "quantity":i.enhance_portfolio_shares,
              }
          }
          return info;
        }

      }).filter((item) => typeof item !== 'undefined');

      var finalCSV=[...buyTicker,...selTicker];

      this.setState({ tradeList: finalCSV }, () => {
        // click the CSVLink component to trigger the CSV download
        setTimeout(() => {
          if (this.csvLink && this.csvLink.current) {
            this.csvLink.current.link.click()
            this.props.onDownload();
            this.onCloseDownloadTradeList();
          }
        }, 1000);

      })


  }

  downloadFidelityTradeListExcel = () => {
    const { fetchretData, enhance_transaction, quantity } = this.props;
    const { brokerDropdownValue } = this.state;
      var buyTicker = enhance_transaction.map((i) => {
        if (i.ticker !== null) {
          var info = {};
          if (i.old_portfolio_shares == null)
          {
            info = {
                  "account_number":this.state.selectAccountNumber,
                  "ticker": i.ticker,
                  "transaction":"SL",
                  "price":0,
                  "quantity":0,
                  "instrx":"MOC",
                  "timeLimit":"D",
                  timing: 'M',
                  security_type: i.ticker_type ? (["ETF", "ETN"].includes(i.ticker_type) ? "EQ" : "M") : "",
            }
          }
          else {
              info = {
                  "account_number":this.state.selectAccountNumber,
                  "ticker": i.ticker,
                  "transaction":"SL",
                  "price":i.old_portfolio_price,
                  "quantity":i.old_portfolio_shares,
                  "instrx":"MOC",
                  "timeLimit":"D",
                  timing: 'M',
                  security_type: i.ticker_type ? (["ETF", "ETN"].includes(i.ticker_type) ? "EQ" : "M") : "",
              }
          }
          return info;
        }

      }).filter((item) => typeof item !== 'undefined');

      var selTicker = enhance_transaction.map((i) => {
        if (i.replace_ticker !== null) {
          var info = {};
          if (i.enhance_portfolio_shares == null)
          {
            info = {
              "account_number":this.state.selectAccountNumber,
              "ticker": i.replace_ticker,
              "transaction":"B",
              "price":0,
              "quantity":0,
              "instrx":"MOC",
              "timeLimit":"D",
              timing: 'M',
              security_type: i.replace_ticker_type ? (["ETF", "ETN"].includes(i.replace_ticker_type) ? "EQ" : "M") : "",
            }
        }
        else {
            info = {
                "account_number":this.state.selectAccountNumber,
                "ticker": i.replace_ticker,
                "price":i.enhance_portfolio_price,
                "transaction":"B",
                "quantity":i.enhance_portfolio_shares,
                "instrx":"MOC",
                "timeLimit":"D",
                timing: 'M',
                security_type: i.replace_ticker_type ? (["ETF", "ETN"].includes(i.replace_ticker_type) ? "EQ" : "M") : "",
            }
        }
          return info;
        }

     }).filter((item) => typeof item !== 'undefined');

      var finalCSV=[...buyTicker,...selTicker];
    
    this.setState({ tradeList: finalCSV }, () => {
      // click the CSVLink component to trigger the CSV download
      setTimeout(() => {
        if(brokerDropdownValue === "Schwab" && this.schwabCsvLink && this.schwabCsvLink.current){
          this.schwabCsvLink.current.link.click();
          this.props.onDownload();
          this.onCloseDownloadTradeList();
        }else if(this.fidelityCsvLink && this.fidelityCsvLink.current){
          this.fidelityCsvLink.current.link.click()
          this.props.onDownload();
          this.onCloseDownloadTradeList();
        }
      }, 1000);

    })

  }

  setAccountNumber = (e) => {
    const name = document.getElementById('account-number-box').value;
    this.setState({ selectAccountNumber : e.target.value })
  }

  selectTradeList = (item) =>{
     this.setState({tradeListValue:item});
     this.setState({brokerDropdownValue : ''});
     this.setState({selectAccountNumber : ''});
  }

  render() {
    const cancelBtn = (
      <SecondaryButton type="button" onClick={this.handleDisagree}>
        DISAGREE
      </SecondaryButton>
    );

    var tradeList = ["Orion","Not Applicable"];
    var accountList = ['Fidelity','Schwab','TD Ameritrade','Robinhood','Vanguard']

    var orionHeaders = [
      { label: "account", key: "account_number"},
      { label: "action", key: "transaction" },
      { label: "shares", key: "quantity" },
      { label: "ticker", key: "ticker" },
    ];

      var fidelityHeaders = [
        { label: "Acct", key: "account_number"},
        { label: "Action", key: "transaction" },
        { label: "Quantity", key: "quantity" },
        { label: "Symbol", key: "ticker" },
        { label: "Instrx", key: "instrx" },
        { label: "Price", key: "price" },
        { label: "Time Limit", key: "timeLimit" },
      ];

      var schwabHeaders = [
        { label: "Account Number", key: "account_number"},
        { label: "Action", key: "transaction" },
        { label: "Quantity", key: "quantity" },
        { label: "Security Symbol", key: "ticker" },
        { label: "Timing", key: "timing" },
        { label: "Security Type", key: "security_type" }
      ];

      let disabledCSV = (this.state.selectAccountNumber != '' && this.state.brokerDropdownValue == '') || this.state.selectAccountNumber == '';
      let disabledBroker = this.state.tradeListValue == '';
      let disabledAccount = (this.state.tradeListValue != '' && this.state.brokerDropdownValue == '') || (this.state.tradeListValue == '' && this.state.brokerDropdownValue == '')

    return (
        <Modal
        isOpen={this.state.downloadTradeList}
        toggle={() => this.setState({ downloadTradeList: !this.state.downloadTradeList })}
        centered
        keyboard={true}
        backdrop
        className="account-broker-modal premium-msg-modal account-modal"
        backdropClassName="premium-feature-process-modal-backdrop"
        >
        <ModalBody className="ssf-modal__body" >
          <div className="premium-feature-process-modal-container">
            <div className="header-wrapper">
              <h1 className="text-align-left">Download Tradelist</h1>
            </div>
            <i className="fal fa-times-circle premium-feature-completed-close" onClick={() =>this.onCloseDownloadTradeList()}></i>
            <div className="account-dropdown-element" style={{paddingBottom:'18px'}}>
            <span style={{color: 'white'}}>To Execute Selected Enhancements In Desired Format</span><br/>
            </div>
            <div className="account-dropdown-element">
            <span style={{color: 'white'}}>Execution Platform</span>

            <Dropdown
              isOpen={ this.state.tradeListDropdown }
              toggle={this.tradeListDropDownToggle}
              className="account-dropdown-wrapper"
            >
              <DropdownToggle caret
                className="dd-input-area"
              >
                 {this.state.tradeListValue}
              </DropdownToggle>
              <DropdownMenu center>
                  {
                      tradeList.map((item) => {
                          return <DropdownItem value={item} onClick={() =>this.selectTradeList(item)}>{item}</DropdownItem>
                      })
                    }
              </DropdownMenu>
            </Dropdown>
            </div>

            <div className="account-dropdown-element">
              <span style={{color: 'white'}}>Broker/Custodian</span>

              <Dropdown
                isOpen={this.state.accountDropdown}
                toggle={this.accountDropdownToggle}
                className="account-dropdown-wrapper"
              >
                <DropdownToggle caret
                  className="dd-input-area" disabled={disabledBroker}
                >
                  {this.state.brokerDropdownValue}
                </DropdownToggle>
                <DropdownMenu center>
                    {
                      this.state.tradeListValue === 'Orion' ?
                      accountList.map((item) => {
                          return <DropdownItem value={item} onClick={() => this.getAccountName(item)}>{item}</DropdownItem>
                        })
                        :
                        accountList.map((item) => {
                          if(['Fidelity', 'Schwab'].includes(item)){
                            return <DropdownItem value={item} onClick={() => this.getAccountName(item)}>{item}</DropdownItem>
                          }
                        })
                      }
                </DropdownMenu>
              </Dropdown>
            </div>

            <div>

                {
                  this.state.accountNumber.length > 0 ?
                  <div className="account-dropdown-element">
                <span style={{color: 'white'}}>Account</span>
                <Dropdown
                  isOpen={this.state.accounNoDropdown}
                  toggle={this.accountNoDropdownToggle}
                  className="account-dropdown-wrapper"
                >
                  <DropdownToggle caret
                    className="dd-input-area" disabled={disabledAccount}
                  >
                    {this.state.selectAccountNumber}
                  </DropdownToggle>
                    <DropdownMenu center>
                      {
                          this.state.accountNumber.map((item) => {
                              return <DropdownItem value={item} onClick={() => this.getAccountNumber(item)}>{item}</DropdownItem>
                          })
                        }
                  </DropdownMenu>
                </Dropdown>
              </div>
                :
                <div className="account-dropdown-element">
                <span style={{color: 'white'}}>Enter Account Number</span>
                    <input
                    id="account-number-box"
                    className="dd-input-area"
                    type="text"
                    value={this.state.selectAccountNumber}
                    onChange={(event) => { this.setAccountNumber(event) } }
                    autoComplete="off"
                    disabled={disabledAccount}
                    />
                  </div>
                }

            {  this.state.tradeListValue === 'Orion' ?
            <div className="download-csv">
              {/* <CSVLink
                    data={this.state.tradeList}
                    headers={orionHeaders}
                    filename={"MyTradeList.csv"}
                    target="_blank"
                  > */}
                    <ButtonGroup className="cta-wrapper justify-content-left">
                      <PrimaryButton className="btn secondary-btn" disabled={disabledCSV} type="button" onClick={this.downloadOrionTradeListExcel} style={{ maxWidth: '203px',cursor:'pointer' }}>
                        Download Tradelist
                      </PrimaryButton>
                    </ButtonGroup>
                 {/* </CSVLink> */}
              </div>
              :
              <div className="download-csv">
              {/* <CSVLink
                    data={this.state.tradeList}
                    headers={fidelityHeaders}
                    filename={"MyTradeList.csv"}
                    target="_blank"
                  > */}
                    <ButtonGroup className="cta-wrapper justify-content-left">
                      <PrimaryButton className="btn secondary-btn" disabled={disabledCSV} type="button" onClick={this.downloadFidelityTradeListExcel} style={{ maxWidth: '203px',cursor:'pointer' }}>
                        Download Tradelist
                      </PrimaryButton>
                    </ButtonGroup>
                 {/* </CSVLink> */}
              </div>
              }
            </div>
                  <CSVLink
                      data={this.state.tradeList}
                      headers={orionHeaders}
                      filename={"MyTradeList.csv"}
                      ref={this.csvLink}
                      >
                  </CSVLink>
                  <CSVLink
                      data={this.state.tradeList}
                      headers={fidelityHeaders}
                      filename={"MyTradeList.csv"}
                      ref={this.fidelityCsvLink}
                  />
                  <CSVLink
                    data={this.state.tradeList}
                    headers={schwabHeaders}
                    filename={"MyTradeList.csv"}
                    ref={this.schwabCsvLink}
                  />
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = ({ brokers, enhancer, investment }) => ({
  accounts: brokers,
  enhance_transaction: enhancer.enhance_transaction,
})

const mapDispatchToProps = {
    //setEnhancedTransaction,
    saveEnhancedTransactionData,
    fetchImportedAccounts,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GenerateTradeList))

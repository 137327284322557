import React from 'react';
import { Tabs } from 'antd';
import { Modal, ModalBody } from 'reactstrap';

import ActTab from './ActTab';

const { TabPane } = Tabs;

export default class SFMTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'Act',
    };
  }

  render() {
    const { activeTab } = this.state;
    let tabPaneArray = [{
      key: 'Act',
      tabHead: () => null,
      tabContent: () => (
        <ActTab
          {...this.props}
          actionHandler={this.actionHandler}
          buyHandler={this.buyHandler}
          clearHandler={this.clearHandler}
        />
      ),
      disabled: false,
    }];
    return (
      <div className="content-wrap">
        <Tabs
          className={`SFM-v2-tabs ${(tabPaneArray.length === 1) ? 'enableOnlyActTab' : ''}`}
          type="card"
          size="default"
          activeKey={activeTab}
          onChange={this.actionHandler}
        >
          {tabPaneArray && tabPaneArray.map((kl) => {
            return (
              <TabPane key={kl.key} disabled={kl.disabled} tab={null}>
                {kl.tabContent()}
              </TabPane>
            )
          })}
        </Tabs>
      </div>
    );
  }
}

import { takeEvery, fork, call, put, all, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import qs from 'query-string'
import get from 'lodash/get';
import { delay } from 'redux-saga';
// import { addEventToAnalytics } from 'Utils';
import ActionTypes from '../action-types'
import * as actions from 'actions/register'
import * as profileActions from 'actions/profile'
import * as authActions from 'actions/auth'
// import * as sfmActions from '../actions/sfm'
import {
  setPortfolioState,
} from '../actions/portfolio';

import * as globalActions from '../actions/global';

import { getGlobalState } from '../selectors/global';

// import * as AuthApi from 'apis/auth';
import * as RegisterApi from 'apis/register';

import config from 'env';
import { oktaAuth, OKTA_IDP, SCOPES, CLIENT_ID, REDIRECT_URI, OKTA_AUTHENTICATOR } from 'oktaAuth';
import { domainRedirectForRetailAdvisor, openNotificationWithIcon } from 'layouts/utils';

import * as AuthUtils from '../common/auth-utils'
import { getAdditionalDetailsForRegister, clearUtmSession, searchUrlV3 } from 'Utils'

function* register(action) {
  const { payload } = action
  try {
    const {data} = yield call(RegisterApi.register, qs.stringify({
      ...payload,
      e : true,
      ...getAdditionalDetailsForRegister()
    }))

    clearUtmSession();

    AuthUtils.storeAuthToken({
      token: data.token,
      firstName: data.first_name,
      lastName: data.last_name,
      email: payload.email,
      isGoogleLogin: false,
      newUser: data.new_user,
      termsAgreed: data.terms_agreed ? 1 : 0,
    })

    yield put(actions.successfulRegister(data))
    yield put(authActions.successfulAuth({
      ...data,
      termsAgreed: data.terms_agreed ? 1 : 0,
      profile: {
        ...data,
        newUser: data.new_user,
        termsAgreed: data.terms_agreed ? 1 : 0,
      }
    }))

    const { surveyModal } = yield select(getGlobalState);
    if (surveyModal) {
      yield put(globalActions.setGlobalState({ surveyModal: false }));
    }

    if (data) {
      const { share_class } = data;
      if (share_class && typeof share_class !== 'undefined') {
        yield put(setPortfolioState({ shareClass: share_class }));
      } else {
        console.log('======= SHARE CLASS NOT PRESENT =======');
      }
    }

    // if (data.terms_agreed === 0) {
    //   yield put(push('/agree-terms'))
    // } else if (data.new_user === 1) {
    //   yield put(push('/account-confirm'))
    // }
  } catch (error) {
    yield put(actions.failedRegister(error.response && error.response.data))
  }
}

function* oktaAuthRegister(action) {
  const { payload, callback } = action
  let oktaRegisterResponse = null;
  try {
    if (payload) {
      // NOTE: Step 1
      // ------------------------------------------------------------------------------------------

      yield put(actions.oktaAuthRegisterFetch({
        plan_selected: payload.plan_selected,
        planType: payload.planType
      }));

      oktaRegisterResponse = yield call(RegisterApi.executOktaRegister, qs.stringify({
        userProfile: {
          e : true,
          ...payload,
          ...getAdditionalDetailsForRegister(),
          magnifi_self_role: payload.self_role,
          groupIDs: ['00g5gibu72o0DTErw5d7'],
        },
      }));

      console.log('oktaRegisterResponse --> ', oktaRegisterResponse)
      // clearUtmSession();

      // if (oktaRegisterResponse &&
      //   oktaRegisterResponse.sessionToken &&
      //   oktaRegisterResponse.status === 'SUCCESS'
      // ) {
      //
      //
      // } else {
      //   throw 'Authentication failed'
      // }

      // clearUtmSession();
      //
      // AuthUtils.storeAuthToken({
      //   token: data.token,
      //   firstName: data.first_name,
      //   lastName: data.last_name,
      //   email: payload.email,
      //   isGoogleLogin: false,
      //   newUser: data.new_user,
      //   termsAgreed: data.terms_agreed ? 1 : 0,
      // })
      //
      // yield put(actions.successfulRegister(data))
      // yield put(authActions.successfulAuth({
      //   ...data,
      //   termsAgreed: data.terms_agreed ? 1 : 0,
      //   profile: {
      //     ...data,
      //     newUser: data.new_user,
      //     termsAgreed: data.terms_agreed ? 1 : 0,
      //   }
      // }))
      //
      // const { surveyModal } = yield select(getGlobalState);
      // if (surveyModal) {
      //   yield put(globalActions.setGlobalState({ surveyModal: false }));
      // }
      //
      // if (data) {
      //   const { share_class } = data;
      //   if (share_class && typeof share_class !== 'undefined') {
      //     yield put(setPortfolioState({ shareClass: share_class }));
      //   } else {
      //     console.log('======= SHARE CLASS NOT PRESENT =======');
      //   }
      // }

      if (callback && typeof callback === 'function') {
        callback(oktaRegisterResponse);
      }

      yield put(actions.oktaAuthRegisterSuccess({
        oktaRegisterResponse,
      }));

    } else {
      throw '-- PAYLOAD --'
    }
  } catch (error) {
    let errorDetails = get(error, 'response.data', error.stack)
    console.log(errorDetails);
    if (!errorDetails || (!errorDetails.error && errorDetails && errorDetails.includes('Authentication failed'))) {
      errorDetails = {
        error: 'Authentication failed'
      }
    }

    yield put(actions.failedRegister(errorDetails))

    yield put(actions.oktaAuthRegisterSuccess(errorDetails));

    if (callback && typeof callback === 'function') {
      callback(errorDetails);
    }
    openNotificationWithIcon({
      duration: 5,
      type: 'error',
      message: 'Error',
      className: 'api-response-notification-class',
      description: errorDetails && errorDetails.error ? errorDetails.error : errorDetails,
    });
    // yield put(actions.failedRegister(error.response && error.response.data))
  }
}

// function* validateOkta (action) {
//   const { payload, callback } = action;
//   try {
//     if (payload && payload.token) {
//       yield put(actions.validateOktaUserFetch())
//
//       // NOTE: Get query from discovery reducer's state
//       // --------------------------------------------------------------------------------------------
//       const discoveryQuery = yield select(state => state.discovery.query) || '';
//       // --------------------------------------------------------------------------------------------
//
//       const query = payload.query || discoveryQuery || window.sessionStorage.getItem('query');
//
//       if ('query' in payload) {
//         delete payload.query;
//       }
//
//       console.log('payload --> ', payload)
//
//       const loginDataResponse = yield call(AuthApi.getOktaLoginData, {
//         token: payload.token,
//       });
//
//       console.log('loginDataResponse --> ', loginDataResponse)
//       if (loginDataResponse) {
//         const result = loginDataResponse;
//         const email = loginDataResponse.profile.email;
//         const token = loginDataResponse.token || null ;
//         const self_role = loginDataResponse.original_self_role || null;
//         const remind_for_password_change = loginDataResponse.remind_for_password_change;
//
//         loginDataResponse.email = loginDataResponse.profile.email
//         loginDataResponse.firstName = loginDataResponse.profile.first_name
//         loginDataResponse.lastName = loginDataResponse.profile.last_name
//
//         /** NOTE- To Handle remind_for_password_change   */
//         if (remind_for_password_change) {
//           yield put(actions.successfulAuth(result))
//           // yield put(actions.setOldPassword(old_password)); <-- calling inside "auth*" function
//           yield put(push('/changepwd'))
//         } else {
//
//           if (config.advisorRetailRedirect && !payload.token) {
//             const redirectTo = domainRedirectForRetailAdvisor({
//               selfRole: self_role,
//               token,
//               email,
//             });
//             if (redirectTo) {
//               window.location.href = redirectTo;
//               return;
//             }
//           }
//
//           // console.log('==== GOOD TO GO ====');
//           AuthUtils.storeAuthToken({
//             token: loginDataResponse.token,
//             email: loginDataResponse.email,
//             lastName: loginDataResponse.lastName,
//             firstName: loginDataResponse.firstName,
//             self_role: loginDataResponse.self_role,
//             client_exist: loginDataResponse.client_exist,
//             termsAgreed: loginDataResponse.profile.terms_agreed,
//             user_advisor_menu: loginDataResponse.user_advisor_menu,
//             profile_self_role: loginDataResponse.profile.self_role,
//             restricted_universe: loginDataResponse.profile.restricted_universe || false,
//           })
//
//           if (window.heap) {
//             window.heap.identify(loginDataResponse.email);
//             window.heap.addUserProperties({
//               'First Name': loginDataResponse.firstName,
//               'Last Name': loginDataResponse.lastName
//             });
//             window.heap.track('Login', { email: loginDataResponse.email });
//           }
//
//           window.addkrakenUser && typeof window.addkrakenUser === "function" && window.addkrakenUser({
//             email: loginDataResponse.email,
//             firstName: loginDataResponse.firstName,
//             lastName:loginDataResponse.lastName,
//           });
//
//           // NOTE: Segment Script
//           // ------------------------------------------------------------------------------------------
//           if (window.analytics && window.analytics.identify && typeof window.analytics.identify === 'function') {
//             // console.log('---- Auth Segment ----');
//             window.analytics.identify(loginDataResponse.email, {
//               from: 'Auth',
//               user_type: 'Advisor',
//               email: loginDataResponse.email,
//               lastName: loginDataResponse.lastName,
//               firstName: loginDataResponse.firstName,
//               name: loginDataResponse.firstName+' '+loginDataResponse.lastName,
//             });
//           }
//           // ------------------------------------------------------------------------------------------
//
//           addEventToAnalytics('Login', 'Login', 'LOGIN', { email: loginDataResponse.email });
//           // NOTE: Google in login page triggers 'handleGoogleSignInError'
//           // Which set 'loggedIn: false', to resolve this -> `loggedInByToken` used in reducers/auth.js -> GOOGLE_AUTH_FAILURE
//           // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//           yield put(actions.validateOktaUserSuccess({
//             user: {
//               ...result,
//               premiumUser: false,
//               loggedInByToken: (payload.token && typeof payload.token !== 'undefined')
//             },
//             loggedIn: true,
//             loggingIn: false,
//             authSuccess: true,
//             token: loginDataResponse.token,
//             newUser: loginDataResponse.profile.new_user || false,
//             client_exist: loginDataResponse.client_exist,
//             termsAgreed: loginDataResponse.profile.terms_agreed,
//             user_advisor_menu: loginDataResponse.user_advisor_menu,
//             remind_for_password_change: loginDataResponse.remind_for_password_change,
//             loggedInByToken: (payload.token && typeof payload.token !== 'undefined')
//           }))
//           // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//
//           yield put(profileActions.loadProfile(loginDataResponse.profile))
//           yield put(profileActions.subscriptionPlan())
//
//           const { surveyModal } = yield select(getGlobalState);
//           if (surveyModal) {
//             yield put(globalActions.setGlobalState({ surveyModal: false }));
//           }
//
//           const loggedIn = (yield select(state => state.auth)).loggedIn;
//           //const remind_for_password_change = (yield select(state => state.auth)).remind_for_password_change;
//           if (loggedIn || window.localStorage.getItem('token')) {
//             const securitiesPath = searchUrlV3(query); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
//             console.log('login flow google-login -> ', securitiesPath)
//             yield put(push(securitiesPath));
//           }
//         }
//
//       } else {
//         throw 'Failed to fetch login data'
//       }
//
//       if (callback && typeof callback === 'function') {
//         callback();
//       }
//     } else {
//       throw '-- Payload Missing --'
//     }
//   } catch (error) {
//     let errorDetails = get(error, 'response.data', error.stack)
//     if (errorDetails && !errorDetails.error && errorDetails.includes('Authentication failed')) {
//       errorDetails = {
//         error: 'Authentication failed'
//       }
//     }
//     yield put(actions.validateOktaUserSuccess(errorDetails))
//     if (callback && typeof callback === 'function') {
//       callback(errorDetails);
//     }
//   }
// }

// function* proceedAutoLogin(action) {
//   const { payload, callback } = action
//   try {
//     if (payload) {
//
//       // NOTE: After step 1, okta gives "sessionToken", which we have pass in "executOktaLogin" api
//       // which will trigger okta's -> "signInWithCredentials" function
//
//       // NOTE: Step 2
//       // ------------------------------------------------------------------------------------------
//       let oktaRegisterResponse = yield call(AuthApi.executOktaLogin, {
//         username: payload.email,
//         password: payload.password,
//       });
//
//       console.log('oktaRegisterResponse --> ', oktaRegisterResponse)
//       if (oktaRegisterResponse &&
//         oktaRegisterResponse.sessionToken &&
//         oktaRegisterResponse.status === 'SUCCESS'
//       ) {
//         // NOTE: after OKTA login, redirect to '/', with "accessToken" and auto-login
//         // -----------------------------------------------------
//         const sessionToken = oktaRegisterResponse.sessionToken;
//         if (sessionToken) {
//           yield put(oktaAuth.signInWithRedirect({
//             sessionToken,
//             state: encodeURIComponent(
//               JSON.stringify({ provider: 'email', isNew: true }),
//             ),
//           }))
//         };
//         // -----------------------------------------------------
//       } else {
//         throw 'Authentication failed'
//       }
//
//       if (callback && typeof callback === 'function') {
//         callback(oktaRegisterResponse);
//       }
//
//     } else {
//       throw '-- PAYLOAD --'
//     }
//   } catch (error) {
//     yield put(actions.failedRegister(error.response && error.response.data))
//   }
// }

function* registerGoogle(action) {
  // let oktaRegisterResponse = null;
  // const { payload, callback } = action;
  try {
    yield put(actions.registerGoogleFetch());

    // yield put(oktaAuth.signInWithRedirect({
    //   scopes: SCOPES,
    //   clientId: CLIENT_ID,
    //   responseType: 'code',
    //   idp: '0oa5o7sccuy5YgrIz5d7',
    //   redirectUri: REDIRECT_URI,
    //   useInteractionCodeFlow: true,
    //   state: encodeURIComponent(
    //     JSON.stringify({ validateOktaUserFor: 'google' }),
    //   ),
    // }))

    // oktaAuth.signInWithRedirect({
    //   idp: OKTA_IDP,
    //   scopes: SCOPES,
    //   clientId: CLIENT_ID,
    //   responseType: 'code',
    //   redirectUri: REDIRECT_URI,
    //   useInteractionCodeFlow: true,
    //   state: encodeURIComponent(
    //     JSON.stringify({
    //       validateOktaUserFor: 'google',
    //       sourceURI: window.location.pathname === '/register' ? `/register?plan=basic` : `${window.location.pathname}`, // redirect user back to source path if something went wrong
    //     }),
    //   ),
    // })

    // const { availableSteps } = oktaAuth.idx.start({
    //   state: encodeURIComponent(
    //     JSON.stringify({
    //       validateOktaUserFor: 'google',
    //       sourceURI: window.location.pathname === '/register' ? `/register?plan=basic` : `${window.location.pathname}`, // redirect user back to source path if something went wrong
    //     }),
    //   ),
    // });

    const socialResponse = yield call(RegisterApi.oktaSocialLogin, {
      state: encodeURIComponent(
        JSON.stringify({
          validateOktaUserFor: 'google',
          sourceURI: window.location.pathname === '/register' ? `/register?plan=basic` : `${window.location.pathname}`, // redirect user back to source path if something went wrong
        }),
      ),
    });

    console.log('socialResponse -->', socialResponse)
    if (socialResponse) {
      const { availableSteps } = socialResponse;
      console.log('availableSteps --> ', availableSteps)
      if (availableSteps) {
        const socialLink = availableSteps.find( (step) => step.name === 'redirect-idp' && step.type === 'GOOGLE');
        if (socialLink && socialLink.href) window.location.href = socialLink.href;
      }
    }

    // if (payload) {
    //
    //   yield put(actions.registerGoogleFetch());
    //
    //   // NOTE: Step 1
    //   // ------------------------------------------------------------------------------------------
    //   // oktaRegisterResponse = yield call(RegisterApi.executOktaRegister, qs.stringify({
    //   //   email: payload.email,
    //   //   password: payload.password,
    //   // }));
    //
    //   // oktaRegisterResponse = yield call(RegisterApi.executOktaRegister, {
    //   //   userProfile: {
    //   //     e : true,
    //   //     ...payload,
    //   //     ...getAdditionalDetailsForRegister(),
    //   //     groupIDs: ['00g5gibu72o0DTErw5d7'],
    //   //   },
    //   // });
    //
    //
    //   // if (oktaRegisterResponse &&
    //   //   oktaRegisterResponse.sessionToken &&
    //   //   oktaRegisterResponse.status === 'SUCCESS'
    //   // ) {
    //   //   // NOTE: Step 2
    //   //   // ------------------------------------------------------------------------------------------
    //   //   let oktaLoginResponse = yield call(AuthApi.executOktaLogin, {
    //   //     username: payload.email,
    //   //     password: payload.password,
    //   //   });
    //   //
    //   //   console.log('oktaLoginResponse --> ', oktaLoginResponse)
    //   //   if (oktaLoginResponse &&
    //   //     oktaLoginResponse.sessionToken &&
    //   //     oktaLoginResponse.status === 'SUCCESS'
    //   //   ) {
    //   //     // NOTE: after OKTA login, redirect to '/'
    //   //     // -----------------------------------------------------
    //   //     const sessionToken = oktaLoginResponse.sessionToken;
    //   //     if (sessionToken) {
    //   //       yield put(oktaAuth.signInWithRedirect({
    //   //         sessionToken,
    //   //         state: encodeURIComponent(
    //   //           JSON.stringify({ provider: 'email', isNew: true }),
    //   //         ),
    //   //       }))
    //   //     };
    //   //
    //   //     // -----------------------------------------------------
    //   //   } else {
    //   //     throw 'Authentication failed'
    //   //   }
    //   //
    //   // } else {
    //   //   throw 'Registeration failed'
    //   // }
    //
    //
    //         // old flow
    //         // ----------------------------------------------------------------------
    //         // const response = yield call(RegisterApi.register, qs.stringify({
    //         //   ...payload,
    //         //   e : true,
    //         //   ...getAdditionalDetailsForRegister()
    //         // }));
    //         //
    //         // clearUtmSession();
    //         //
    //         // const { data } = response;
    //         // if (data) {
    //         //   if (typeof data.register_step_status !== 'undefined') {
    //         //     if (data.register_step_status !== '1') {
    //         //       // profile incomplete so add token to 'reducer-state' and redirect to register-profile
    //         //       yield put(actions.setRegisterState({
    //         //         registerStepStatus: data.register_step_status,
    //         //         registerGoogleAuthToken: data.token,
    //         //         plan_selected:payload.plan_selected,
    //         //         planType: payload.planType
    //         //       }));
    //         //
    //         //       if(process.env.REACT_APP_SUB_DOMAIN === 'retail') {
    //         //         callback(data.token);
    //         //       } else {
    //         //         yield put(push(`/register-profile/${data.token}`));
    //         //       }
    //         //     } else if (data.register_step_status === '1') {
    //         //       const self_role = data.original_self_role || null;
    //         //       const token = data.token || null ;
    //         //       const email = payload.email;
    //         //       // check self_role of a user
    //         //       // check url has subdomain or not
    //         //       if (config.advisorRetailRedirect) {
    //         //         const redirectTo = domainRedirectForRetailAdvisor({
    //         //           selfRole: self_role,
    //         //           token,
    //         //           email,
    //         //         });
    //         //         // console.log(redirectTo);
    //         //         if (redirectTo) {
    //         //           window.location.href = redirectTo;
    //         //           return;
    //         //         }
    //         //       }
    //         //       // profile complete so loggedIn
    //         //       AuthUtils.storeAuthToken({
    //         //         self_role: data.self_role,
    //         //         token: data.token,
    //         //         email: payload.email,
    //         //         firstName: payload.first_name,
    //         //         lastName: payload.last_name,
    //         //         isGoogleLogin: payload.g,
    //         //         termsAgreed: data.terms_agreed ? 1 : 0,
    //         //         profile_self_role: data.original_self_role,
    //         //       });
    //         //
    //         //       const result = {
    //         //         token: data.token,
    //         //         email: data.email,
    //         //         new_user: 1,
    //         //         terms_agreed: data.terms_agreed ? 1 : 0,
    //         //         firstName: payload.first_name,
    //         //         lastName: payload.last_name,
    //         //         termsAgreed: data.terms_agreed ? 1 : 0,
    //         //       };
    //         //       yield put(actions.successfulGoogleRegister(data))
    //         //       yield put(authActions.successfulGoogleAuth(result))
    //         //
    //         //       yield put(profileActions.fetchProfile());
    //         //
    //         //       // NOTE: After registration, show Calendar invite screen.
    //         //       // For that use 'callback' function and
    //         //       // ------------------------------------------------------------
    //         //       if (callback) {
    //         //         callback({
    //         //           response: data,
    //         //         })
    //         //       } else {
    //         //         // NOTE: Execute existing logic.
    //         //         const loggedIn = (yield select(state => state.auth)).loggedIn;
    //         //
    //         //         // NOTE: Get query from discovery reducer's state
    //         //         // --------------------------------------------------------------------------------------------
    //         //         const discoveryQuery = yield select(state => state.discovery.query) || window.sessionStorage.getItem('query') || '';
    //         //         const securitiesPath = searchUrlV3(discoveryQuery); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
    //         //         // console.log('register flow google-login -> ', securitiesPath)
    //         //         // --------------------------------------------------------------------------------------------
    //         //
    //         //         if (loggedIn || window.localStorage.getItem('token')) {
    //         //           if(process.env.REACT_APP_SUB_DOMAIN === 'retail' && !data.apex_account_id) {
    //         //             yield put(push('/register/trading'))
    //         //           } else {
    //         //             yield put(push(securitiesPath));
    //         //           }
    //         //         }
    //         //       }
    //         //       // ------------------------------------------------------------
    //         //     }
    //         //   } else {
    //         //     console.log('--- register_step_status is missing in api response ---');
    //         //   }
    //         // } else {
    //         //   console.log('--- Data is missing in api response ---');
    //         // }
    //         // ----------------------------------------------------------------------
    //
    // }

  } catch (error) {
    yield put(
      actions.failedGoogleRegister(error.response && error.response.data)
    )
  }
}

// function* oktaRegisterAutoLogin (action) {
//   const { payload, callback } = action;
//   try {
//     if (payload && payload.token) {
//       yield put(actions.oktaAuthRegisterFetch())
//
//       console.log('payload --> ', payload)
//       // const { data } = yield call(RegisterApi.register, qs.stringify({
//       //   ...payload,
//       //   e : true,
//       //   ...getAdditionalDetailsForRegister()
//       // }))
//
//       // NOTE: Step 2
//       // ------------------------------------------------------------------------------------------
//       let oktaLoginResponse = yield call(AuthApi.executOktaLogin, {
//         username: email,
//         password: old_password,
//       });
//
//       console.log('oktaLoginResponse --> ', oktaLoginResponse)
//       if (oktaLoginResponse &&
//         oktaLoginResponse.sessionToken &&
//         oktaLoginResponse.status === 'SUCCESS'
//       ) {
//         // NOTE: after OKTA login, redirect to '/'
//         // -----------------------------------------------------
//         const sessionToken = oktaLoginResponse.sessionToken;
//         if (sessionToken) {
//           yield put(oktaAuth.signInWithRedirect({
//             sessionToken,
//             state: encodeURIComponent(
//               JSON.stringify({ provider: 'email', isNew: true }),
//             ),
//           }))
//         };
//
//         const response = yield call(RegisterApi.register, qs.stringify({
//           ...payload,
//           e : true,
//           ...getAdditionalDetailsForRegister()
//         }));
//
//         clearUtmSession();
//
//         const { data } = response;
//         if (data) {
//           if (typeof data.register_step_status !== 'undefined') {
//             if (data.register_step_status !== '1') {
//               // profile incomplete so add token to 'reducer-state' and redirect to register-profile
//               yield put(actions.setRegisterState({
//                 registerStepStatus: data.register_step_status,
//                 registerGoogleAuthToken: data.token,
//                 plan_selected:payload.plan_selected,
//                 planType: payload.planType
//               }));
//
//               if(process.env.REACT_APP_SUB_DOMAIN === 'retail'){
//                 callback(data.token);
//               } else {
//                 yield put(push(`/register-profile/${data.token}`));
//               }
//             } else if (data.register_step_status === '1') {
//               const self_role = data.original_self_role || null;
//               const token = data.token || null ;
//               const email = payload.email;
//               // check self_role of a user
//               // check url has subdomain or not
//               if (config.advisorRetailRedirect) {
//                 const redirectTo = domainRedirectForRetailAdvisor({
//                   selfRole: self_role,
//                   token,
//                   email,
//                 });
//                 // console.log(redirectTo);
//                 if (redirectTo) {
//                   window.location.href = redirectTo;
//                   return;
//                 }
//               }
//               // profile complete so loggedIn
//               AuthUtils.storeAuthToken({
//                 self_role: data.self_role,
//                 token: data.token,
//                 email: payload.email,
//                 firstName: payload.first_name,
//                 lastName: payload.last_name,
//                 isGoogleLogin: payload.g,
//                 termsAgreed: data.terms_agreed ? 1 : 0,
//                 profile_self_role: data.original_self_role,
//               });
//
//               const result = {
//                 token: data.token,
//                 email: data.email,
//                 new_user: 1,
//                 terms_agreed: data.terms_agreed ? 1 : 0,
//                 firstName: payload.first_name,
//                 lastName: payload.last_name,
//                 termsAgreed: data.terms_agreed ? 1 : 0,
//               };
//               yield put(actions.successfulGoogleRegister(data))
//               yield put(authActions.successfulGoogleAuth(result))
//
//               yield put(profileActions.fetchProfile());
//
//               // NOTE: After registration, show Calendar invite screen.
//               // For that use 'callback' function and
//               // ------------------------------------------------------------
//               if (callback) {
//                 callback({
//                   response: data,
//                 })
//               } else {
//                 // NOTE: Execute existing logic.
//                 const loggedIn = (yield select(state => state.auth)).loggedIn;
//
//                 // NOTE: Get query from discovery reducer's state
//                 // --------------------------------------------------------------------------------------------
//                 const discoveryQuery = yield select(state => state.discovery.query) || window.sessionStorage.getItem('query') || '';
//                 const securitiesPath = searchUrlV3(discoveryQuery); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
//                 // console.log('register flow google-login -> ', securitiesPath)
//                 // --------------------------------------------------------------------------------------------
//
//                 if (loggedIn || window.localStorage.getItem('token')) {
//                   if(process.env.REACT_APP_SUB_DOMAIN === 'retail' && !data.apex_account_id) {
//                     yield put(push('/register/trading'))
//                   } else {
//                     yield put(push(securitiesPath));
//                   }
//                 }
//               }
//               // ------------------------------------------------------------
//             }
//           } else {
//             console.log('--- register_step_status is missing in api response ---');
//           }
//         } else {
//           console.log('--- Data is missing in api response ---');
//         }
//
//         // -----------------------------------------------------
//       }
//
//       // const loginDataResponse = yield call(AuthApi.getOktaLoginData, {
//       //   token: payload.token,
//       // });
//       //
//       // console.log('loginDataResponse --> ', loginDataResponse)
//       //
//       // if (loginDataResponse) {
//       //
//       //
//       //   // current workflow
//       //   // clearUtmSession();
//       //   //
//       //   // AuthUtils.storeAuthToken({
//       //   //   token: data.token,
//       //   //   firstName: data.first_name,
//       //   //   lastName: data.last_name,
//       //   //   email: payload.email,
//       //   //   isGoogleLogin: false,
//       //   //   newUser: data.new_user,
//       //   //   termsAgreed: data.terms_agreed ? 1 : 0,
//       //   // })
//       //   //
//       //   // yield put(actions.successfulRegister(data))
//       //   //
//       //   // yield put(authActions.successfulAuth({
//       //   //   ...data,
//       //   //   termsAgreed: data.terms_agreed ? 1 : 0,
//       //   //   profile: {
//       //   //     ...data,
//       //   //     newUser: data.new_user,
//       //   //     termsAgreed: data.terms_agreed ? 1 : 0,
//       //   //   }
//       //   // }))
//       //   //
//       //   // const { surveyModal } = yield select(getGlobalState);
//       //   // if (surveyModal) {
//       //   //   yield put(globalActions.setGlobalState({ surveyModal: false }));
//       //   // }
//       //
//       //
//       //
//       // } else {
//       //   throw 'Failed to fetch login data'
//       // }
//
//       if (callback && typeof callback === 'function') {
//         callback();
//       }
//     } else {
//       throw '-- Payload Missing --'
//     }
//   } catch (error) {
//     let errorDetails = get(error, 'response.data', error.stack)
//     if (errorDetails && !errorDetails.error && errorDetails.includes('Authentication failed')) {
//       errorDetails = {
//         error: 'Authentication failed'
//       }
//     }
//     yield put(actions.oktaAuthRegisterSuccess(errorDetails))
//     if (callback && typeof callback === 'function') {
//       callback(errorDetails);
//     }
//   }
// }
//

// function* registerGoogle(action) {
//   const { payload, callback } = action;
//   try {
//     // console.log('sagas registerGoogle middleware', action)
//     // if (payload && typeof payload.email !== 'undefined' && payload.first_name === '') {
//     //   const EMAIL_FIRST_NAME = payload.email && payload.email.split('@').length ? payload.email.split('@')[0] : '';
//     //   payload.first_name = EMAIL_FIRST_NAME;
//     // }
//     if (payload) {
//       const response = yield call(RegisterApi.register, qs.stringify({
//         ...payload,
//         e : true,
//         ...getAdditionalDetailsForRegister()
//       }));
//       clearUtmSession();
//       const { data } = response;
//       if (data) {
//         if (typeof data.register_step_status !== 'undefined') {
//           if (data.register_step_status !== '1') {
//             // profile incomplete so add token to 'reducer-state' and redirect to register-profile
//             yield put(actions.setRegisterState({
//               registerStepStatus: data.register_step_status,
//               registerGoogleAuthToken: data.token,
//               plan_selected:payload.plan_selected,
//               planType: payload.planType
//             }));
//
//             if(process.env.REACT_APP_SUB_DOMAIN === 'retail'){
//               callback(data.token);
//             } else {
//               yield put(push(`/register-profile/${data.token}`));
//             }
//           } else if (data.register_step_status === '1') {
//             const self_role = data.original_self_role || null;
//             const token = data.token || null ;
//             const email = payload.email;
//             // check self_role of a user
//             // check url has subdomain or not
//             if (config.advisorRetailRedirect) {
//               const redirectTo = domainRedirectForRetailAdvisor({
//                 selfRole: self_role,
//                 token,
//                 email,
//               });
//               // console.log(redirectTo);
//               if (redirectTo) {
//                 window.location.href = redirectTo;
//                 return;
//               }
//             }
//             // profile complete so loggedIn
//             AuthUtils.storeAuthToken({
//               self_role: data.self_role,
//               token: data.token,
//               email: payload.email,
//               firstName: payload.first_name,
//               lastName: payload.last_name,
//               isGoogleLogin: payload.g,
//               termsAgreed: data.terms_agreed ? 1 : 0,
//               profile_self_role: data.original_self_role,
//             });
//             const result = {
//               token: data.token,
//               email: data.email,
//               new_user: 1,
//               terms_agreed: data.terms_agreed ? 1 : 0,
//               firstName: payload.first_name,
//               lastName: payload.last_name,
//               termsAgreed: data.terms_agreed ? 1 : 0,
//             };
//             yield put(actions.successfulGoogleRegister(data))
//             yield put(authActions.successfulGoogleAuth(result))
//             // yield put(sfmActions.setSFMState({
//             //   isRedirectFromHeaderButtons: true,
//             //   autoOpenModal: false,
//             // })) // removed because it was blocking auto-open modal after sign in
//             yield put(profileActions.fetchProfile());
//
//             // NOTE: After registration, show Calendar invite screen.
//             // For that use 'callback' function and
//             // ------------------------------------------------------------
//             if (callback) {
//               callback({
//                 response: data,
//               })
//             } else {
//               // NOTE: Execute existing logic.
//               const loggedIn = (yield select(state => state.auth)).loggedIn;
//
//               // NOTE: Get query from discovery reducer's state
//               // --------------------------------------------------------------------------------------------
//               const discoveryQuery = yield select(state => state.discovery.query) || window.sessionStorage.getItem('query') || '';
//               const securitiesPath = searchUrlV3(discoveryQuery); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
//               // console.log('register flow google-login -> ', securitiesPath)
//               // --------------------------------------------------------------------------------------------
//
//               if (loggedIn || window.localStorage.getItem('token')) {
//                 if(process.env.REACT_APP_SUB_DOMAIN === 'retail' && !data.apex_account_id) {
//                   yield put(push('/register/trading'))
//                 } else {
//                   yield put(push(securitiesPath));
//                 }
//               }
//             }
//             // ------------------------------------------------------------
//           }
//         } else {
//           console.log('--- register_step_status is missing in api response ---');
//         }
//       } else {
//         console.log('--- Data is missing in api response ---');
//       }
//     }
//   } catch (error) {
//     yield put(
//       actions.failedGoogleRegister(error.response && error.response.data)
//     )
//   }
// }

function* registerApple(action) {
  const { payload, callback } = action
  // console.log('ani 1', payload);
  try {

    if ( payload ) {

      const { data } = yield call(RegisterApi.register, qs.stringify({
        ...payload,
        e : true,
        ...getAdditionalDetailsForRegister()
      }));

      // console.log('ani 2', data);

      clearUtmSession();

      if ( data && typeof data.register_step_status !== 'undefined' ) {
        // console.log('ani 3');
        if (data.register_step_status !== '1') {
          // console.log('ani 4', data);
          yield put(actions.setRegisterState({
            registerStepStatus: data.register_step_status,
            registerAppleAuthToken: data.token,
            plan_selected: payload.plan_selected
          }))

          AuthUtils.storeAuthToken({
            self_role: data.self_role,
            token: data.token,
            email: payload.email || data.email,
            firstName: payload.first_name || data.first_name,
            lastName: payload.last_name || data.last_name,
            isAppleLogin: true,
            termsAgreed: data.terms_agreed ? 1 : 0,
            profile_self_role: data.original_self_role,
          });

          if(process.env.REACT_APP_SUB_DOMAIN === 'retail'){
            callback(data.token);
          } else {
            yield put(push(`/register-profile/a/${data.token}`))
          }
        } else if (data.register_step_status === '1') {
          // console.log('ani 5', data);
          if (config.advisorRetailRedirect) {
            const redirectTo = domainRedirectForRetailAdvisor({
              selfRole: data.original_self_role || null,
              token: data.token || null,
              email: payload.email,
            });
            if (redirectTo) {
              window.location.href = redirectTo;
              return;
            }
          }

          // profile complete so loggedIn
          AuthUtils.storeAuthToken({
            self_role: data.self_role,
            token: data.token,
            email: payload.email || data.email,
            firstName: payload.first_name || data.first_name,
            lastName: payload.last_name || data.last_name,
            isAppleLogin: true,
            termsAgreed: data.terms_agreed ? 1 : 0,
            profile_self_role: data.original_self_role,
          });

          const result = {
            token: data.token,
            new_user: 1,
            terms_agreed: data.terms_agreed ? 1 : 0,
            email: payload.email || data.email,
            firstName: payload.first_name || data.first_name,
            lastName: payload.last_name || data.last_name,
            termsAgreed: data.terms_agreed ? 1 : 0,
          };

          yield put(actions.successfulAppleRegister(data))
          yield put(authActions.successfulAppleAuth(result))
          yield put(profileActions.fetchProfile());

          // const loggedIn = (yield select(state => state.auth)).loggedIn;
          // if (loggedIn || window.localStorage.getItem('token')) {
          //   if(process.env.REACT_APP_SUB_DOMAIN === 'retail'){
          //     if(data.apex_account_id && data.apex_account_id !== null){
          //       yield put(push('/securities'))
          //     } else {
          //       yield put(push('/register/trading'))
          //     }
          //   } else {
          //     yield put(push('/securities'))
          //   }
          // }

          const loggedIn = (yield select(state => state.auth)).loggedIn;

          // NOTE: Get query from discovery reducer's state
          // --------------------------------------------------------------------------------------------
          const discoveryQuery = yield select(state => state.discovery.query) || window.sessionStorage.getItem('query') || '';
          const securitiesPath = searchUrlV3(discoveryQuery); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
          // --------------------------------------------------------------------------------------------

          if (loggedIn || window.localStorage.getItem('token')) {
            if(process.env.REACT_APP_SUB_DOMAIN === 'retail' && !data.apex_account_id) {
              yield put(push('/register/trading'))
            } else {
              yield put(push(securitiesPath));
            }
          }

        }

      }

    }

  } catch (error) {
    // console.log('ani 6', error);
    yield put( actions.failedAppleRegister(error.response && error.response.data))
  } finally {

  }
}

function* registerProfileData(action) {
  const { payload, callback } = action;
  try {
    // if (payload && typeof payload.email !== 'undefined' && payload.first_name === '') {
    //   const EMAIL_FIRST_NAME = payload.email && payload.email.split('@').length ? payload.email.split('@')[0] : '';
    //   payload.first_name = EMAIL_FIRST_NAME;
    // }
    if (payload) {
      yield put(actions.registerProfileDataFetch());

      const response = yield call(RegisterApi.registerProfileData, payload);
      console.log('save-profile response ==>?', response)
      if (response) {
        const { data } = response;
        console.log(data);
        if (data && typeof data.email !== 'undefined' && typeof payload.token !== 'undefined') {

          const authProviderIdentifier = window.location.href.split('/')[4]

          if (authProviderIdentifier === 'a') {

            AuthUtils.storeAuthToken({
              token: payload.token,
              email: data.email,
              firstName: data.first_name,
              lastName: data.last_name,
              isAppleLogin: true,
              termsAgreed: data.terms_agreed ? 1 : 0,
            });

            yield put(actions.setRegisterState({
              registerAppleAuthToken: payload.token,
              registerStepStatus: '1',
            }));

          } else {

            AuthUtils.storeAuthToken({
              token: payload.token,
              email: data.email,
              firstName: data.first_name,
              lastName: data.last_name,
              isGoogleLogin: true,
              termsAgreed: data.terms_agreed ? 1 : 0,
            });

            yield put(actions.setRegisterState({
              registerGoogleAuthToken: payload.token,
              registerStepStatus: '1',
            }));
          }

          const result = {
            token: data.token,
            email: data.email,
            new_user: 1,
            terms_agreed: data.terms_agreed ? 1 : 0,
            firstName: payload.first_name,
            lastName: payload.last_name,
            termsAgreed: data.terms_agreed ? 1 : 0,
          };

          yield put(actions.registerProfileDataSuccess(data));

          if ( authProviderIdentifier === 'a' ) {
            yield put(authActions.successfulAppleAuth(result));
          } else {
            yield put(authActions.successfulGoogleAuth(result));
          }

          yield put(profileActions.fetchProfile());

          if (callback && typeof callback === 'function') {
            callback();
          }
        } else {
          console.log('--- email is missing in data or payload.token --- ', payload)
          throw data;
        }
      } else {
        throw '== no response for RegisterApi.registerProfileData =='
      }
    } else {
      throw '== no Payload =='
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.registerProfileDataFailure(error.response && error.response.data)
    )
    if (callback && typeof callback === 'function') {
      callback();
    }
  }
}

function* widgetregister(action) {
  const { payload } = action
  try {
    const {data} = yield call(RegisterApi.widgetRegister, qs.stringify(payload))
    if (data.status !== 'Failed' && data.token) {
        const { token ,email} = data;
        yield put(
          actions.setWidgetToken({
            token,
            email,
            pendingWidget: false,
          })
        )
    } else if (data.message && data.message === 'Widget is not approved yet.') {
        const { token } = data;
        yield put(
          actions.pendingWidgetToken({
            pendingWidgetToken: true,
          })
        )
      } else {
      console.log('error');
      yield put(
        actions.failedWidget(data.status || data.message)
      )
    }
  } catch (error) {
    yield put(actions.failedRegister(error.response && error.response.data))
  }
}

function* getPlaidLinkToken(action) {
  const { payload } = action
  try {
    const response = yield call(RegisterApi.getLinkToken, payload)
    if (response.status === 200) {
      yield put(response.link_token);
    }
  } catch (error) {
    yield put(
      actions.linkTokenError(error.response && error.response.data)
    )
  }
}

function* getUsersAccountsDetails(action) {
  const { payload } = action
  try {
    const response = yield call(RegisterApi.getUsersAccountDetails, payload)
    if (response.status === 200) {
      yield put(response);
    }
  } catch (error) {
    yield put(
      actions.linkTokenError(error.response && error.response.data)
    )
  }
}

function* createUserAccount(action) {
  const { payload } = action
  try {
    const response = yield call(RegisterApi.createUserAccount, payload)
    if (response.status === 200) {
      yield put(response);
    }
  } catch (error) {
    yield put(
      actions.linkTokenError(error.response && error.response.data)
    )
  }
}

function* registerByOktaSteps(action) {
  let { payload, callback } = action;
  let token = null, tokenType = null;
  try {
    if (payload) {
      yield put(actions.registerByOktaStepsFetch())
      // let [step1, step2, step3, step4, step5] = yield all([
      //   call(RegisterApi.executOktaIdxRegister),
      //   call(RegisterApi.executOktaIdxProceed, payload),
      //   call(RegisterApi.executOktaIdxProceed, {
      //     authenticator: OKTA_AUTHENTICATOR,
      //   }),
      //   call(RegisterApi.executOktaIdxProceed, {
      //     password: payload.password,
      //   }),
      //   call(RegisterApi.executOktaIdxProceed, {
      //     skip: true,
      //   }),
      // ]);
      // const step2 = yield call(RegisterApi.executOktaIdxProceed, {
      //   username: payload.email,
      //   email: payload.email,
      //   firstName: payload.firstName,
      //   lastName: payload.lastName,
      //   magnifi_self_role: payload.magnifi_self_role,
      //   authenticator: OKTA_AUTHENTICATOR,
      // })
      // //
      // console.log(step2)
      //
      // const step3 = yield call(RegisterApi.executOktaIdxProceed, {
      //   skip: true
      // })
      console.log(payload)
      let requestedRequested = {
        username: payload.email,
        email: payload.email,
        firstName: payload.firstName,
        lastName: payload.lastName,
        magnifi_self_role: payload.magnifi_self_role,
      }
      console.log(requestedRequested)
      const step1 = yield call(RegisterApi.executOktaIdxRegister)
      console.log(step1)

      if (step1 &&
        step1.messages &&
        Array.isArray(step1.messages) &&
        step1.messages.length &&
        step1.messages[0] &&
        step1.messages[0].message
      ) {
        throw step1.messages[0].message;

      } else {

        console.log('-- GOOD TO GO FOR STEP 2 --')

        if (step1 &&
          step1.status === 'SUCCESS' &&
          step1.tokens &&
          step1.tokens.accessToken &&
          step1.tokens.accessToken.accessToken
        ) {

          token = step1.tokens.accessToken.accessToken;
          tokenType = step1.tokens.accessToken.tokenType;

        } else {

          const step2 = yield call(RegisterApi.executOktaIdxProceed, requestedRequested);
          console.log('step2 -->', step2)

          if (step2 &&
            step2.messages &&
            Array.isArray(step2.messages) &&
            step2.messages.length &&
            step2.messages[0] &&
            step2.messages[0].message
          ) {

            throw step2.messages[0].message;

          } else {

            if (step2 &&
              step2.status === 'SUCCESS' &&
              step2.tokens &&
              step2.tokens.accessToken &&
              step2.tokens.accessToken.accessToken
            ) {

              token = step2.tokens.accessToken.accessToken;
              tokenType = step2.tokens.accessToken.tokenType;

            } else {

              const step3 = yield call(RegisterApi.executOktaIdxProceed, {
                authenticator: OKTA_AUTHENTICATOR,
              });

              console.log('step3 -->', step3)

              if (step3 &&
                step3.messages &&
                Array.isArray(step3.messages) &&
                step3.messages.length &&
                step3.messages[0] &&
                step3.messages[0].message
              ) {

                throw step3.messages[0].message;

              } else {

                if (step3 &&
                  step3.status === 'SUCCESS' &&
                  step3.tokens &&
                  step3.tokens.accessToken &&
                  step3.tokens.accessToken.accessToken
                ) {

                  token = step3.tokens.accessToken.accessToken;
                  tokenType = step3.tokens.accessToken.tokenType;

                } else {

                  const step4 = yield call(RegisterApi.executOktaIdxProceed, {
                    password: payload.password,
                  });
                  console.log('step4 -->', step4)

                  if (step4 &&
                    step4.messages &&
                    Array.isArray(step4.messages) &&
                    step4.messages.length &&
                    step4.messages[0] &&
                    step4.messages[0].message
                  ) {
                    throw step4.messages[0].message;
                  } else {

                    if (step4 &&
                      step4.status === 'SUCCESS' &&
                      step4.tokens &&
                      step4.tokens.accessToken &&
                      step4.tokens.accessToken.accessToken
                    ) {

                      token = step4.tokens.accessToken.accessToken;
                      tokenType = step4.tokens.accessToken.tokenType;

                    } else {

                      const step5 = yield call(RegisterApi.executOktaIdxProceed, {
                        skip: true
                      });
                      console.log('step5 -->', step5)

                      if (step5 &&
                        step5.messages &&
                        Array.isArray(step5.messages) &&
                        step5.messages.length &&
                        step5.messages[0] &&
                        step5.messages[0].message
                      ) {
                        throw step5.messages[0].message;
                      } else {
                        if (step5 &&
                          step5.status === 'SUCCESS' &&
                          step5.tokens &&
                          step5.tokens.accessToken &&
                          step5.tokens.accessToken.accessToken
                        ) {
                          token = step5.tokens.accessToken.accessToken;
                          tokenType = step5.tokens.accessToken.tokenType;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      console.log('token --> ', token)

      window.localStorage.setItem('tokenType', tokenType);

      yield put(authActions.oktaAuthLoginRequest({
        token,
      }));

      yield put(actions.registerByOktaStepsSuccess({
        token,
        tokenType,
      }));

      if (callback && typeof callback === 'function') {
        callback(token);
      }

    } else {
      throw '-- Missing Payload --'
    }
  } catch (error) {
    console.log(error)
    let errorDetails = get(error, 'response.data', error.stack)
    console.log(errorDetails);
    if (!errorDetails || (errorDetails && !errorDetails.error)) {
      errorDetails = {
        error
      }
    }
    if (callback && typeof callback === 'function') {
      callback(errorDetails);
    }
    openNotificationWithIcon({
      duration: 5,
      type: 'error',
      message: 'Error',
      className: 'api-response-notification-class',
      description: errorDetails && errorDetails.error ? errorDetails.error : errorDetails,
    });
    yield put(actions.registerByOktaStepsSuccess())
  }
}

function* registerSaga() {
  yield all([
    fork(takeEvery, ActionTypes.REGISTER_REQUEST, register),
    fork(takeEvery, ActionTypes.REGISTER_PROFILE_REQUEST, registerProfileData),
    fork(takeEvery, ActionTypes.REGISTER_GOOGLE_REQUEST, registerGoogle),
    fork(takeEvery, ActionTypes.REGISTER_APPLE_REQUEST, registerApple),
    fork(takeEvery, ActionTypes.REGISTER_WIDGET, widgetregister),
    fork(takeEvery, ActionTypes.GENERATE_LINK_TOKEN, getPlaidLinkToken),
    fork(takeEvery, ActionTypes.GET_USERS_BANK_ACCOUNTS, getUsersAccountsDetails),
    fork(takeEvery, ActionTypes.CREATE_ACCOUNT, createUserAccount),
    fork(takeEvery, ActionTypes.OKTA_AUTH_REGISTER_REQUEST, oktaAuthRegister),
    fork(takeEvery, ActionTypes.REGISTER_BY_OKTA_STEPS_REQUEST, registerByOktaSteps),
    // fork(takeEvery, ActionTypes.VALIDATE_OKTA_USER_REQUEST, validateOkta),
  ])
}

export default registerSaga

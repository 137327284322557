import React from 'react';
import { connect } from 'react-redux';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

class AssetAllocation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {};
  }  

  render() {

    const data = [
      {name: 'Bonds', uv: 50, pv: 75},
      {name: 'Equity', uv: 40, pv: 65},
      {name: 'Commodities', uv: 35, pv: 60},
      {name: 'Currency', uv: 25, pv: 45}
    ];    

    const assetAllocData = assetAllocCalc(this.props.selectedClientHoldings.holdings)

    return (
      <div style={{width: '100%', height: '300px'}}>
        <ResponsiveContainer width="100%" height="100%" >
          <BarChart data={assetAllocData}
                margin={{top: 30, right: 40, left: 60, bottom: 30}} layout="vertical">
             <CartesianGrid horizontal={false} vertical={true} stroke="#ced4dc" shapeRendering="crispEdges" />
             <XAxis type="number" ticks={xAxisTicks} tickFormatter={formatXAxis} stroke="#909497" shapeRendering="crispEdges" />
             <YAxis dataKey="name" type="category" stroke="#909497" shapeRendering="crispEdges" />
             <Tooltip content={<BarCustomTooltip />} cursor={false} isAnimationActive={false} />
             <Bar dataKey="model" fill="#1e5f91" isAnimationActive={false} />
             {/*<Bar dataKey="current" fill="#56a9e8" isAnimationActive={false} />*/}
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  }
}

const mapStateToProps = ({ clientportfolio: { selectedClientHoldings } }) => ({
  selectedClientHoldings
})

export default connect(
  mapStateToProps
)(AssetAllocation);

const assetAllocCalc = (values) => {  
  let data = [
    {name: 'Bonds', model: 0},
    {name: 'Equity', model: 0},
    {name: 'Commodities', model: 0},
    {name: 'Currency', model: 0},
    {name: 'Cash', model: 0},
    {name: 'Derivatives', model: 0},
    {name: 'Others', model: 0},
  ];  

 let dataKey = ['bonds', 'equities', 'commodities', 'currencies', 'cash', 'derivatives', 'other'];

  values.forEach(element => {
    let {asset_alloc} = element;
    dataKey.forEach((item, i) => {
      data[i].orgVal = parseFloat(data[i].model+asset_alloc[item]/values.length).toFixed(2);
      data[i].model = Math.abs(data[i].orgVal);
    })
  });

  return data;
}

const xAxisTicks = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

const formatXAxis = (tick) => {
  return tick + '%';
}

const BarCustomTooltip = (props) => {
  const { active, payload, label, vkey } = props;
  if (active && payload) {
    return (
      <div className="recharts-default-tooltip ssf-tooltip-custom">
        <div className="label">{label}</div>
        { payload.map((e, index) => (
          <div key={e.dataKey} className="d-flex" style={{color: e.payload[`${e.dataKey+'Color'}`]}}>
            <span className="mr-2" style={{textTransform: 'capitalize'}}>{e.dataKey}</span>
            <span className="ml-auto">{e.payload.orgVal || ''}</span>
            <span className="">%</span>
          </div>
        )) }
      </div>
    );
  }

  return null;
}

import React, { useContext } from 'react';
import { Row, Col, Table } from 'reactstrap';
import { kmb } from 'DataSet';
import { quintileSize, quintileText, getImage } from 'Utils';
import { getFRRAttrDataForModels, textCapitalize } from 'layouts/utils';

const FeeRiskReturn = (props) => {
  const { highlight: highlights, _defaultFRR: defaultFRR, _originalQueryVar: originalQueryVar, query } = props.data;

  highlights.feesHighLightEnable   = (originalQueryVar.find((vc) => vc.node === 'cost') ? true : false)    || (query && (query.includes('cost') || query.includes('similar')));
  highlights.returnHighLightEnable = (originalQueryVar.find((vc) => vc.node === 'returns') ? true : false) || highlights.returnHighLightEnable;
  highlights.riskHighLightEnable   = (originalQueryVar.find((vc) => vc.node === 'risk') ? true : false)    || (query && (query.includes('risk')));

  // console.log(highlights, defaultFRR, originalQueryVar);

  const FRR_DATA_ATTRS = getFRRAttrDataForModels(query, highlights);

  let FRR_ATTRS = FRR_DATA_ATTRS.filter((item) => defaultFRR.includes(item.attr));

  FRR_ATTRS.map((jt) => {
    const df = originalQueryVar.find((dc) => (dc.node === jt.attr && dc.view_name !== "Highlight"))
    if (df) {
      // jt.decimal = df.decimal;
      jt.multiplier = df.multiplier !== '' ? df.multiplier : 1;
      // jt.attr = df.node;
      // jt.qcol = df.variable; // quintile col
      jt.col = df.node.includes('tracking_error') ? df.variable : df.data;
      jt.name = (df['Name of the chart on UI'] !== '' ? textCapitalize(df['Name of the chart on UI']) : textCapitalize(df.view_name));
    }
  });

  // console.log(FRR_ATTRS)

  if (FRR_ATTRS.length !== defaultFRR.length) {
    const EXCLUDED_NODE_WAS = [];
    const renderingFRRs = FRR_ATTRS.map((kgf) => kgf.attr)
    const NEW_NODE = defaultFRR.filter((jh) => !renderingFRRs.includes(jh));

    NEW_NODE.map((kkl) => {
      if (kkl) {
        const foundInQueryVar = originalQueryVar.find((vc) => vc.node === kkl);
        if (foundInQueryVar) {
          EXCLUDED_NODE_WAS.push({
            reverse: true,
            multiplier: foundInQueryVar.multiplier !== '' ? foundInQueryVar.multiplier : 1,
            attr: foundInQueryVar.node,
            name: ((foundInQueryVar['Name of the chart on UI'] !== '') ? textCapitalize(foundInQueryVar['Name of the chart on UI']) : textCapitalize(foundInQueryVar.view_name)),
            qcol: foundInQueryVar.variable,
            col: foundInQueryVar.variable,
            img: "Assets", // common image for all unknown node
            suffix: foundInQueryVar.suffix,
            decimal: foundInQueryVar.decimal,
            highlightClass: 'search-risk-stats-highlighting',
          });
        }
      }
    });
    FRR_ATTRS = [...FRR_ATTRS, ...EXCLUDED_NODE_WAS].slice(0, 3); // limit 3
  }

  // console.log(FRR_ATTRS);

  const elems = FRR_ATTRS.map(attr => {
    const qs = quintileSize(props.data[attr.qcol], attr.reverse);
    const _val = (typeof props.data[attr.col] !== 'undefined' && props.data[attr.col] !== null && props.data[attr.col] !== '--') ?
    (attr.name === 'Assets' ? (kmb(props.data[attr.col])) : (
      (attr.decimal && attr.decimal !== '' ? (
        props.data[attr.col] * attr.multiplier).toFixed(attr.decimal)
        : Math.round((props.data[attr.col] * attr.multiplier)) ) + (attr.suffix)
      )) : '--';

    // console.log(_val);

    return {
      attr: attr.attr,
      name: attr.name,
      icon: attr.icon ? `${attr.icon}-${qs}` : null,
      iconImg: attr.img ? attr.img : null,
      value: _val,
      label: quintileText(qs),
      highlightClass: attr.highlightClass,
    }
  })

  // const attr = defaultFRR || ['Fees', 'Returns', 'Risk'];
  // const attrData = getAttrdata(props.data);

  // console.log(elems);

  return(
    <div className={`frr-wrapper
      ${props.type === 'current' || props.type === 'others' ? 'adjust-offset' : ''}`}>
    	{elems.map((item, index) => {
    		return(
    			<Attr {...item} />
    		)
    	})}
    </div>
  )
};
export default FeeRiskReturn;

// const Attr = (props) => {
//   const { name, attrData } = props;
//   const currentAttr = attrData[name];
//   const icon = currentAttr.icon || '';
//   const attrClass = currentAttr.class || '';
//   const highlight = currentAttr.highlight || '';
//   const value = currentAttr.value || '';
// 	return(
// 		<div className={`attr-wrapper ${attrClass} ${highlight ? 'highlight' : ''}`}>
// 			<span className="name">{name}</span>
// 			<i className={`far fa-fw fassf ${icon}`}></i>
// 			<span className="value">{value}</span>
// 		</div>
// 	)
// }

const Attr = ({ attr, name, icon, iconImg, value, label, highlight, highlightClass }) => {
  // console.log(attr, name, icon, iconImg, value, label, highlight);
  return (
    <div className={`attr-wrapper ${attr}-flag-color ${(highlight || highlightClass) ? 'highlight' : ''}`}>
      <span className="name" style={{ textAlign: 'center' }}>{name}</span>
      {(iconImg !== null && icon === null) ? (
        <img className="far fa-fw" src={getImage(iconImg)} width="auto" height={32} style={{ marginTop: 2, marginBottom: 2 }} />
      ) : (
        <i className={`far fa-fw fassf ${icon}`} />
      )}
      <span className="value">{value}</span>
    </div>
  )
}
//
// const frrMapping = {
//   Fees: {
//     icon: 'fa-tags-1',
//     class: 'fees-flag-color',
//   },
//   Returns: {
//     icon: 'fa-chart-line-1',
//     class: 'returns-flag-color',
//   },
//   Risk: {
//     icon: 'fa-heart-rate-alt-1',
//     class: 'risk-flag-color',
//   }
// }
//
// const getAttrdata = (data) => {
//   let out = {};
//   out.Fees = {
//     value: (data.fees && !isNaN(Number(data.fees))) ? Number(data.fees).toFixed(2)+'%' : '--',
//     ...frrMapping['Fees'],
//     highlight: data.highlight.feesHighLightEnable || false,
//   }
//   out.Returns = {
//     value: (data.yRet && !isNaN(Number(data.yRet))) ? Number(data.yRet * 100).toFixed(2)+'%' : '--',
//     ...frrMapping['Returns'],
//     highlight: data.highlight.returnHighLightEnable || false,
//   }
//   out.Risk = {
//     value: (data.volt && !isNaN(Number(data.volt))) ? Number(data.volt * 100).toFixed(2)+'%' : '--',
//     ...frrMapping['Risk'],
//     highlight: data.highlight.riskHighLightEnable || false,
//   }
//   return out;
// }

import React, { useContext } from 'react';
import _ from 'lodash';

import DateRangeSubHeading from 'components/reportsV2/shared/DateRangeSubHeading';
import { BenchmarkDisclaimer } from 'components/reportsV2/sf/Common'

import ActReportContext from '../ActReportContext';
import PerformanceTable from './PerformanceTable';
import RenderScatterChart from '../shared/RenderScatterChart';
import ScatterCustomLegend from '../shared/ScatterCustomLegend';
import TotalReturnsTable from './TotalReturnsTable';

import { withHeaderFooter } from '../shared/HeaderFooter';
import { getPerformaceChartdata, getPercentileData } from '../shared/Utils';

const PerformanceWrap = (props) => {
  const { createdDate, selFunds, yearlyReturnsData, totFunds, breakPoint, bestTicker,printSelection,benchmark } = props;
  return (
    <React.Fragment>
      {printSelection.includes('perf-analytics-tradeoff')&&<PerformanceContent
        selFunds={selFunds}
        totFunds={totFunds}
        breakPoint={breakPoint}
        pageName={'perf-analytics-tradeoff'}
        bestTicker={bestTicker}
        benchmark={benchmark}
      />}
      {totFunds > breakPoint && printSelection.includes('perf-analytics-tradeoff')&& (
        <RenderChartSection
          selFunds={selFunds}
          totFunds={totFunds}
          pageName={'perf-analytics-tradeoff'}
        />
      )}
      {printSelection.includes('perf-analytics-tradeoff')&&<TotalReturnsContent
        createdDate={createdDate}
        selFunds={selFunds}
        pageName={'perf-analytics-tradeoff'}
        yearlyReturnsData={yearlyReturnsData}
        bestTicker={bestTicker}
      />}
    </React.Fragment>
  )
};

export default PerformanceWrap;

const PerformanceContent = withHeaderFooter((props) => {
  const reportData = useContext(ActReportContext);
  console.log('PerformanceContent -> ', reportData)
  const { selFunds, totFunds, breakPoint,bestTicker,benchmark } = props;
  return (
    <React.Fragment>
      <div className="content">
        <div className="pr-v2-header">
          <h3>Performance Analytics : Return-Risk Trade-Off</h3>
          <DateRangeSubHeading/>
        </div>
        <div className="pr-v2-perf-analytics">
          <div className="perf-component-wrapper">
            <PerformanceTable
              benchmark={benchmark}
              bestTicker={bestTicker}
              selFunds={selFunds}
            />
            {totFunds <= breakPoint && (
              <RenderChartSection
                selFunds={selFunds}
                totFunds={totFunds}
                showHeaderFooter={false}
              />
            )}
          </div>
          <BenchmarkDisclaimer/>
          {/*<p className={'asteriskDisclaimer'}>vs Benchmark * – MPT Benchmark is the primary benchmark assigned to investments based on their Broad Morningstar category. This category-level benchmark is used to calculate Modern Portfolio Theory statistics.</p>*/}
        </div>
      </div>
    </React.Fragment>
  )
});

const RenderChartSection = withHeaderFooter((props) => {
  const { selFunds, totFunds } = props;
  // const inputDataPercentile = selFunds.map(item => item.sharpe_3y).filter(item => item);
  // Temporary logic until sharpe_3y is fixed from API
  // const inputDataPercentile = selFunds
  //   .filter(item => item.returns_3y && item.volatility_3y)
  //   .map(item => item.returns_3y / item.volatility_3y);
  // const percentileKeys = totFunds < 2 ? null : totFunds < 4 ? [50] : [33, 50, 66];
  return(
      <div className={`content ${totFunds > 5 ? 'pr-v2-perf-analytics' : ''}`}>
        <div className={totFunds > 5 ? 'perf-component-wrapper' : ''}>
          <RenderScatterChart
            chartTitle="Return/Risk"
            chartData={getPerformaceChartdata(selFunds, 'returnRiskKeys')}
            axisLabels={['Risk (Std. Dev.)', 'Ann. Return']}
            axisUnits={['%', '%']}
            // percentileKeys={percentileKeys}
            // percentileData={percentileKeys && getPercentileData(inputDataPercentile, percentileKeys)}
            // axisTopRefLine={percentileKeys !== null}
            tickConfig={{
              // axisOffset: {
              //   x: 2,
              //   y: 0,
              // },
              // roundOff: 1,
              // minValue: {
              //   x: 0,
              //   y: ''
              // },
              allowDecimals: true,
            }}
            showHorizontalGridLines
          />
          <RenderScatterChart
            chartTitle="Annual Excess Return vs Tracking Error"
            chartData={getPerformaceChartdata(selFunds, 'annExcessKeys')}
            axisLabels={['Tracking Error', 'Annual Excess Return']}
            axisUnits={['%', '%']}
            tickConfig={{
              // axisOffset: {
              //   x: 2,
              //   y: 0,
              // },
              // roundOff: 1,
              allowDecimals: true,
            }}
            showHorizontalGridLines
          />
          <ScatterCustomLegend data={selFunds} />
        </div>
      </div>
    )
});

const TotalReturnsContent = (props) => {
  const { createdDate, selFunds, yearlyReturnsData, bestTicker } = props;
  const fundsChunk = _.chunk(selFunds, 15);
  return(
    <React.Fragment>
      {fundsChunk.map(item => {
        return(
          <TotalReturnsTable
            selFunds={item}
            createdDate={createdDate}
            yearlyReturnsData={yearlyReturnsData}
            bestTicker={bestTicker}
          />
        )
      })}
    </React.Fragment>
  )
}

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { getNestDefault, getImageOrFallback } from 'Utils';
import { MoreButton, FormatCurrency, TruncatedNameSponsor, withScrollBar, NoData, invalidTickerWithScrollBar } from './Common';
import { DualColorLineChart } from 'components/charts/LineCharts';
import { returnYears } from './utils';
import {
  cn,
  sponsorLogo
} from 'DataSet';

const { Option } = Select;

class SecurityReturns_ extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
      imageValidPath: false
    }
    this.toggle = this.toggle.bind(this)
  }

  componentDidMount = () => {
    this.checkSponsorLogo(this.props.card);
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.card.name !== prevProps.card.name) {
      this.checkSponsorLogo(this.props.card);
    }
  }

  checkSponsorLogo = (card) => {
    if (cn(card, 'Sponsor')) {
      getImageOrFallback(
        sponsorLogo(cn(card, 'Sponsor'))
      ).then(result => {
        let imageValidPath = false;
        if (result) {
          imageValidPath = true;
        }
        this.setState({
          imageValidPath
        });
      });
    }
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    })
  }

  selectReturnYear = (selectedYear) => {
    const { selectReturnYear, items } = this.props;
    if (selectReturnYear) {
      selectReturnYear(selectedYear, items)
    }
  };

  render() {
    const { items, height, card } = this.props;
    const { imageValidPath } = this.state;
    // console.log(items);
    // console.log(card);
    const { _dropdown: ReturnDropdown } = items;
    const ticker = getNestDefault(['ticker'], items, '');
    const cost = card.market_value || card.price;
    const weightToBe = card.original_weight ? parseFloat(card.original_weight.toFixed(2)) : null;
    let renderSelectedYearRange = null; // <span className="mb-0 time-Unit">{`(${startDate} - ${endDate})`}</span>
    const renderOptions = Object.keys(ReturnDropdown).map((u) => {
      if (u && ReturnDropdown[u] &&
        typeof ReturnDropdown[u].value !== 'undefined' &&
        typeof ReturnDropdown[u].disabled !== 'undefined' &&
        typeof ReturnDropdown[u].label !== 'undefined') {
          if (typeof ReturnDropdown[items._selectedYear].year !== 'undefined') {
            renderSelectedYearRange = <span className="mb-0">{ReturnDropdown[items._selectedYear].year}</span>
          }
        return (<Option value={ReturnDropdown[u].value} disabled={ReturnDropdown[u].disabled}>{ReturnDropdown[u].label}</Option>)
      } else {
        return null
      }
    })
    return (
      <React.Fragment>
        <h5 className="mb-1 card-title p-0">Security Returns </h5>
        {card && (
          <Row className="accounts-summary-holdings-card px-0 border-0">
            <Col span={4} className="ticker-block">
              <h5 className="mb-0 primary-color">{card.ticker_short || card.ticker}</h5>
              {weightToBe && <h3 className="mb-0 tx-c2 holdings-weight-font-size">{weightToBe}%</h3>}
            </Col>
            <Col span={12} className="name-block">
              <TruncatedNameSponsor imageValidPath={imageValidPath} weight={weightToBe} name={card.short_name} sponsor={card.sponsor} lines={1} style={{ width: '100%' }} />
            </Col>
            <Col id="security-range-filter" span={8} className="d-flex flex-column justify-content-end align-items-end time-Unit">
              {(ReturnDropdown && Object.keys(ReturnDropdown).length > 0) && (
                <>
                  <Select
                    className="text-blue"
                    style={{ width: '100%' }}
                    bordered={false}
                    value={items._selectedYear}
                    getPopupContainer={() => document.getElementById('security-range-filter')}
                    onChange={this.selectReturnYear}
                    suffixIcon={<CaretDownOutlined />}
                  >
                    {renderOptions}
                  </Select>
                  {renderSelectedYearRange}
                </>
              )}
            </Col>
          </Row>
        )}
        <div className="d-flex justify-content-center align-items-center" style={{ width: '100%', height: '82%' }}>
          {(!items || items.returns.length <= 0) ? <NoData height={height+'px'} /> : <SecurityReturnsChart height={height || 460} fund={items} />}
        </div>
      </React.Fragment>
    )
  }
}

export const SecurityReturns = connect(
  state => ({
    // activeFund: getInvestmentActiveFundStats(state),
  }),
  {
  }
)(SecurityReturns_);

const SecurityReturnsChart = ({ fund, height }) => {
  let last = fund._series[fund._series.length-1];
  let series = [ {
    name: fund.ticker,
    data: fund._series,
    label: `$${Math.round(last.cuml+100)}`
  } ];

  return (
      <div className="w-100" style={{ minHeight: height, height }}>
        <DualColorLineChart series={series} ycentered={true} xkey="d" ykey="cumlLog" ykeyLabel="v" vkey="v" />
        {/* v for change from last position
        cuml for change from start */}
      </div>
  )
}

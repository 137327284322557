import React, { Component } from 'react'
import { ModalBody, Input, Modal } from 'reactstrap'
import {
  Divider,
  Row,
  Col,
  Card,
  Menu,
  Icon,
  Skeleton,
  Button,
  Typography
} from 'antd'

class FinalizeModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      qty: ''
    }
  }

  qtyHandler = event => {
    this.setState({ qty: event.target.value })
  }

  clickHandler = (type) => {
    const { setSummarySuccess, summary, submit } = this.props;
    if (setSummarySuccess) {
      submit(type);
  //   createPF(parseFloat(this.state.qty));
  //   setSummarySuccess({
  //     summary: {
  //       ...summary,
  //       timeline: 'Act',
  //     },
  //   });
      this.onCloseFinalizeModal();
    }
  }

  onCloseFinalizeModal = () => {
    const { toggleModal } = this.props;
    toggleModal();
  }
  render() {
    const { isOpen, toggleModal } = this.props
    return (
      <Modal
        isOpen={isOpen}
        toggle={() => toggleModal()}
        centered
        keyboard={true}
        backdrop
        className="account-broker-modal premium-msg-modal account-modal finalize-modal-body"
        backdropClassName="premium-feature-process-modal-backdrop"
      >
        <ModalBody className="ssf-modal__body finalize-modal">
          <div className="premium-feature-process-modal-container">
            <div className="header-wrapper">
              <h1 className="text-align-center">Do you want to overwrite your existing portfolio?</h1>
            </div>
            <i
              className="fal fa-times-circle premium-feature-completed-close finalize-close"
              onClick={() => this.onCloseFinalizeModal()}
            ></i>
            <Row>
              <Col className="act-button-wrapper" span={12}>
                <Button
                  shape="round"
                  className="finalize-button"
                  type="primary"
                  onClick={() => this.clickHandler('overwrite')}
                >
                  Yes
                </Button>
              </Col>
              <Col className="act-button-wrapper" span={12}>
                <Button
                  shape="round"
                  className="finalize-button"
                  type="primary"
                  onClick={() => this.clickHandler('createNew')}
                >
                  Create New Portfolio
                </Button>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

export default FinalizeModal

import React from 'react';
import { connect } from 'react-redux';

import RenderRow from './Elements/RenderRow';

class Board extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoadMoreUnsupported: false,
      isLoadMoreRecognised: false
    }
  }

  toggleLoadMore = () => {
    this.setState({
      isLoadMoreUnsupported: !this.state.isLoadMoreUnsupported
    });
  }

  toggleLoadMoreUnRec = () => {
    this.setState({
      isLoadMoreRecognised: !this.state.isLoadMoreRecognised
    });
  }

  render() {
    const { fundPortfolios, query, enhanceTickerFilter, summary } = this.props;
    const { invalidTickerFunds, tickerNotSupported } = summary;
    const { isLoadMoreUnsupported, isLoadMoreRecognised } = this.state;
    const loadedStyle = isLoadMoreUnsupported ? { margin: 0, maxWidth: '94%', float: 'left' } : {
      margin: 0,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: '94%',
      lineHeight: 1.5,
    };

    const loadedStyleRec = isLoadMoreRecognised ? { margin: 0, maxWidth: '94%', float: 'left' } : {
      margin: 0,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: '94%',
      lineHeight: 1.5,
    };

    const inValidTickerToString = invalidTickerFunds.map((item) => {
      const ogW = (item.original_weight !== null && item.original_weight !== '' && typeof item.original_weight !== 'undefined') ? Number(item.original_weight) : null;
      if (item.ticker && ogW !== null && ogW !== '') {
        return `${item.ticker}(${parseFloat(ogW.toFixed(2))}%)`
      }
    }).filter((kl) => kl !== 'undefined').join(', ').replace(/, ([^,]*)$/, ' and $1');

    const unsupportedTickerToString = tickerNotSupported.map((item) => {
      const ogW = (item.original_weight !== null && item.original_weight !== '' && typeof item.original_weight !== 'undefined') ? Number(item.original_weight) : null;
      if (item.ticker && ogW !== null && ogW !== '') {
        return `${item.ticker}(${parseFloat(ogW.toFixed(2))}%)`
      }
    }).filter((kl) => kl !== 'undefined').join(', ').replace(/, ([^,]*)$/, ' and $1');

    return (
      <div className="custom-scroll pb-5 pt-2" style={{ marginRight: '-7px' }}>
        {fundPortfolios.map((e, i) => {
          if (enhanceTickerFilter.includes(e.ticker) || enhanceTickerFilter.length === 0) {
            return (
              <RenderRow
                {...this.props}
                key={i}
                data={e}
                portfolioQuery={query}
              />
            )
          }
        })}
        {(inValidTickerToString && inValidTickerToString.length > 0) && (
          <div>
            <hr />
            <div style={{display: 'flex', position: 'relative'}}>
              <p className="unidentified_ticker_suggestion_text category-text" style={loadedStyleRec}><span>Unrecognized Securities : </span>{inValidTickerToString}.</p>
              {inValidTickerToString && inValidTickerToString.length > 78 && <button className="unidentified_ticker_suggestion_button" onClick={this.toggleLoadMoreUnRec} >{isLoadMoreRecognised ? <div className="showHide">Hide <i class="fa fa-angle-up" aria-hidden="true"></i></div> : <div className="showHide">More <i class="fa fa-angle-down" aria-hidden="true"></i></div>}</button>}
            </div>
          </div>
        )}
        {(unsupportedTickerToString && unsupportedTickerToString.length > 0) && (
          <div>
            <hr />
              <div style={{display: 'flex', position: 'relative'}}>
                <p className="unidentified_ticker_suggestion_text category-text" style={loadedStyle}><span>Unsupported Securities : </span> {unsupportedTickerToString}.</p>
                  {unsupportedTickerToString && unsupportedTickerToString.length > 78 && <button className="unidentified_ticker_suggestion_button" onClick={this.toggleLoadMore} >{isLoadMoreUnsupported ? <div className="showHide">Hide <i class="fa fa-angle-up" aria-hidden="true"></i></div> : <div className="showHide">More <i class="fa fa-angle-down" aria-hidden="true"></i></div>}</button>}
              </div>
          </div>
        )}
      </div>
    )
  }
}

export default connect(
  state => ({
    query: state.enhancer.query,
    portfolioName: state.enhancer.portfolioName,
    summary: state.enhancer.summary,
    retData: state.enhancer.summary.retData,
    activeFund: state.enhancer.summary.activeFund,
    onboardData: state.enhancer.onboardData,
    fundPortfolios: state.enhancer.fundPortfolios,
    enhanceTickerFilter: state.enhancer.enhanceTickerFilter,
    // customizedCardData:state.portfolio.customizedCardData, // check this logic
  }),
  {
    // toggleModal,
  }
)(Board);

import ActionTypes from '../action-types'

export function uploadClientListV2(payload) {
  return {
    type: ActionTypes.UPLOAD_CLIENT_LIST,
    payload
  }
}

export function setInitialClientList(payload){
  return {
    type: ActionTypes.SET_INITIAL_CLIENT_LIST,
    payload
  }
}

export function uploadClientListSuccess(payload) {
  return {
    type: ActionTypes.UPLOAD_CLIENT_LIST_SUCCESS,
    payload
  }
}

export function sendclientList(payload) {
  return {
    type: ActionTypes.SEND_CLIENT_LIST,
    payload
  }
}

export function clientListRequest(payload) {
  return {
    type: ActionTypes.REQUEST_CLIENT_LIST,
    payload,
  }
}

export function clientListLoader() {
  return {
    type: ActionTypes.CLIENT_LIST_LOADER
  }
}

export function clientListSuccess(payload) {
  return {
    type: ActionTypes.CLIENT_LIST_SUCCESS,
    payload
  }
}

export function filterClients(payload) {
  return {
    type: ActionTypes.FILTER_CLIENT_LIST,
    payload
  }
}

export function clientDeleteRequest(payload, callback) {
  return {
    type: ActionTypes.REQUEST_CLIENT_DELETE,
    payload,
    callback
  }
}

export function clientDeleteSuccess(payload) {
  return {
    type: ActionTypes.CLIENT_DELETE_SUCCESS,
    payload
  }
}

export function clientHoldingsRequest(payload) {
  return {
    type: ActionTypes.REQUEST_CLIENT_HOLDINGS,
    payload
  }
}

export function clientHoldingsSuccess(payload) {
  return {
    type: ActionTypes.CLIENT_HOLDINGS_SUCCESS,
    payload
  }
}

export function clientDetailsRequest(payload) {
  return {
    type: ActionTypes.REQUEST_CLIENT_DETAILS,
    payload
  }
}

export function clientBenchMarkData(payload){
  return {
    type: ActionTypes.REQUEST_CLIENT_BENCHMARK,
    payload
  }
}

export function removeClientBenchMarkData(payload){
  return {
    type: ActionTypes.REMOVE_CLIENT_BENCHMARK,
    payload
  }
}

export function clientChartDetailRequest(payload){
  return {
    type: ActionTypes.REQUEST_CHART_DETAILS,
    payload
  }
}

export function clientDetailsSuccess(payload) {
  return {
    type: ActionTypes.CLIENT_DETAILS_SUCCESS,
    payload
  }
}

export function chartDetailsSuccess(payload){
  return {
    type: ActionTypes.CHART_DETAIL_SUCCESS,
    payload
  }
}

export function pfHoldingsRequest(payload) {
  return {
    type: ActionTypes.REQUEST_PF_HOLDINGS,
    payload
  }
}

export function pfHoldingsSuccess(payload) {
  return {
    type: ActionTypes.PF_HOLDINGS_SUCCESS,
    payload
  }
}

export function clearHoldingsRequest(payload) {
  return {
    type: ActionTypes.REQUEST_CLEAR_HOLDINGS,
    payload
  }
}

export function portfolioOptionsRequest(payload) {
  console.log('---- portfolioOptionsRequest ----', payload);
  return {
    type: ActionTypes.REQUEST_PORTFOLIO_OPTIONS,
    payload
  }
}

export function portfolioOptionsSuccess(payload) {
  return {
    type: ActionTypes.PORTFOLIO_OPTIONS_SUCCESS,
    payload
  }
}

export function setSelectedPortfolio(payload) {
  return {
    type: ActionTypes.SET_SELECTED_PORTFOLIO,
    payload
  }
}

export function updateClientListSuccess(payload) {
  return {
    type: ActionTypes.UPDATE_CLIENT_LIST_SUCCESS,
    payload
  }
}

export function resetClientList(payload) {
  return {
    type: ActionTypes.RESET_CLIENT_LIST,
    payload
  }
}

export function createFinalizeObject(payload) {
  return {
    type: ActionTypes.CREATE_FINALIZE_OBJECT,
    payload
  }
}

export function finalizeAllocationRequest(payload, callback) {
  return {
    type: ActionTypes.FINALIZE_ALLOCATION_REQUEST,
    payload,
    callback
  }
}

export function finalizeAllocationFetch(payload, callback) {
  return {
    type: ActionTypes.FINALIZE_ALLOCATION_FETCH,
    payload,
    callback
  }
}

export function finalizeAllocationSuccess(payload) {
  return {
    type: ActionTypes.FINALIZE_ALLOCATION_SUCCESS,
    payload
  }
}

export function generateTradelistRequest(payload) {
  return {
    type: ActionTypes.REQUEST_GENERATE_TRADELIST,
    payload
  }
}

export function generateTradelistSuccess(payload) {
  return {
    type: ActionTypes.GENERATE_TRADELIST_SUCCESS,
    payload
  }
}

export function searchAccFundsRequest(payload) {
  return {
    type: ActionTypes.REQUEST_SEARCH_ACCOUNT_FUNDS,
    payload
  }
}

export function searchAccFundsSuccess(payload) {
  return {
    type: ActionTypes.SEARCH_ACCOUNT_FUNDS_SUCCESS,
    payload
  }
}

export function searchAccFundsSuccessQuery(payload){
  return {
    type: ActionTypes.SEARCH_ACCOUNT_FUNDS_SUCCESS_QUERY,
    payload
  }
}

export function searchAccFundsFailure(payload) {
  return {
    type: ActionTypes.SEARCH_ACCOUNT_FUNDS_FAILURE,
    payload
  }
}

export function searchAccFundsReset(payload) {
  return {
    type: ActionTypes.SEARCH_ACCOUNT_FUNDS_RESET,
    payload
  }
}

export function modelSearchRequest(payload) {
  return {
    type: ActionTypes.REQUEST_MODEL_SEARCH_RESULTS,
    payload
  }
}

export function modelSearchSuccess(payload) {
  return {
    type: ActionTypes.MODEL_SEARCH_RESULTS_SUCCESS,
    payload
  }
}

export function modelSearchNoResult(payload) {
  return {
    type: ActionTypes.MODEL_SEARCH_NO_RESULTS,
    payload
  }
}

export function updateTaxRates(payload) {
  return {
    type: ActionTypes.UPDATE_TAX_RATES,
    payload
  }
}

export function taxBillsRequest(payload) {
  return {
    type: ActionTypes.REQUEST_TAX_BILLS,
    payload
  }
}

export function taxBillsSuccess(payload) {
  return {
    type: ActionTypes.TAX_BILLS_SUCCESS,
    payload
  }
}

export function updateSelectedtaxBill(payload) {
  return {
    type: ActionTypes.UPDATE_SELECTED_TAX_BILL,
    payload
  }
}

/* ---- UPLOAD CSV-TO-JSON ---- */
export function uploadSingleClientRequest(payload, callback) {
  return {
    type: ActionTypes.UPLOAD_SINGLE_CLIENT_REQUEST,
    payload,
    callback
  }
}
export function uploadSingleClientFetch(payload) {
  return {
    type: ActionTypes.UPLOAD_SINGLE_CLIENT_FETCH,
    payload
  }
}
export function uploadSingleClientSuccess(payload) {
  return {
    type: ActionTypes.UPLOAD_SINGLE_CLIENT_SUCCESS,
    payload
  }
}


/* ---- GET CLIENT LIST  ---- */
export function accountPageClientListRequest(payload, callback) {
  return {
    type: ActionTypes.ACCOUNTPAGE_CLIENT_LIST_REQUEST,
    payload,
    callback,
  }
}
export function accountPageClientListFetch(payload) {
  return {
    type: ActionTypes.ACCOUNTPAGE_CLIENT_LIST_FETCH,
    payload
  }
}
export function accountPageClientListSuccess(payload) {
  return {
    type: ActionTypes.ACCOUNTPAGE_CLIENT_LIST_SUCCESS,
    payload
  }
}

/* ---- RESET PAGINATION AND DOWNLOADED LIST  ---- */
export function resetPaginationAndDownloadedList(payload, callback) {
  return {
    type: ActionTypes.RESET_PAGINATION_AND_DOWNLOADED_LIST,
    payload,
    callback,
  }
}

/* ---- SEARCH QUERY AND GET CLIENT LIST ---- */
export function getClientListBySearchQueryRequest(payload, callback) {
  return {
    type: ActionTypes.GET_CLIENT_LIST_BY_QUERY_REQUEST,
    payload,
    callback,
  }
}
export function getClientListBySearchQueryFetch(payload) {
  return {
    type: ActionTypes.GET_CLIENT_LIST_BY_QUERY_FETCH,
    payload
  }
}
export function getClientListBySearchQuerySuccess(payload) {
  return {
    type: ActionTypes.GET_CLIENT_LIST_BY_QUERY_SUCCESS,
    payload
  }
}

/* ---- ON SCROLL TRIGGER API  ---- */
export function downloadMoreDataRequest(payload, callback) {
  return {
    type: ActionTypes.DOWNLOAD_MORE_DATA_REQUEST,
    payload,
    callback,
  }
}
export function downloadMoreDataFetch(payload) {
  return {
    type: ActionTypes.DOWNLOAD_MORE_DATA_FETCH,
    payload
  }
}
export function downloadMoreDataSuccess(payload) {
  return {
    type: ActionTypes.DOWNLOAD_MORE_DATA_SUCCESS,
    payload
  }
}

/* ---- SELECTED CLIENT DETAILS  ---- */
export function getSelectedClientDetailsRequest(payload, callback) {
  return {
    type: ActionTypes.GET_SELECTED_CLIENT_DETAILS_REQUEST,
    payload,
    callback,
  }
}
export function getSelectedClientDetailsFetch(payload) {
  return {
    type: ActionTypes.GET_SELECTED_CLIENT_DETAILS_FETCH,
    payload
  }
}
export function getSelectedClientDetailsSuccess(payload) {
  return {
    type: ActionTypes.GET_SELECTED_CLIENT_DETAILS_SUCCESS,
    payload
  }
}

/* ---- UPDATE_RESTRICTED_TICKERS  ---- */
export function updateRestrictedTickersRequest(payload, callback) {
  return {
    type: ActionTypes.UPDATE_RESTRICTED_TICKERS_REQUEST,
    payload,
    callback,
  }
}
export function updateRestrictedTickersFetch(payload) {
  return {
    type: ActionTypes.UPDATE_RESTRICTED_TICKERS_FETCH,
    payload
  }
}
export function updateRestrictedTickersSuccess(payload) {
  return {
    type: ActionTypes.UPDATE_RESTRICTED_TICKERS_SUCCESS,
    payload
  }
}

/* ---- DELETE CLIENT ACCOUNT  ---- */
export function deleteClientAccountRequest(payload, callback) {
  return {
    type: ActionTypes.DELETE_CLIENT_ACCOUNT_REQUEST,
    payload,
    callback,
  }
}
export function deleteClientAccountFetch(payload) {
  return {
    type: ActionTypes.DELETE_CLIENT_ACCOUNT_FETCH,
    payload
  }
}
export function deleteClientAccountSuccess(payload) {
  return {
    type: ActionTypes.DELETE_CLIENT_ACCOUNT_SUCCESS,
    payload
  }
}

/* ---- FILTERS FOR CLIENT LIST DATA  ---- */
export function filterDownloadedListRequest(payload, callback) {
  return {
    type: ActionTypes.FILTER_CLIENT_LIST_REQUEST,
    payload,
    callback,
  }
}
export function filterDownloadedListFetch(payload) {
  return {
    type: ActionTypes.FILTER_CLIENT_LIST_FETCH,
    payload
  }
}
export function filterDownloadedListSuccess(payload) {
  return {
    type: ActionTypes.FILTER_CLIENT_LIST_SUCCESS,
    payload
  }
}

/* ---- RESET FILTERS FOR CLIENT LIST DATA  ---- */
export function resetFilterDownloadedListRequest(payload, callback) {
  return {
    type: ActionTypes.RESET_FILTER_CLIENT_LIST_REQUEST,
    payload,
    callback,
  }
}
export function resetFilterDownloadedListFetch(payload) {
  return {
    type: ActionTypes.RESET_FILTER_CLIENT_LIST_FETCH,
    payload
  }
}
export function resetFilterDownloadedListSuccess(payload) {
  return {
    type: ActionTypes.RESET_FILTER_CLIENT_LIST_SUCCESS,
    payload
  }
}

/* ---- SET SEARCH ACCOUNT TICKER INDEX -----*/

export function setSearchAccountTickerIndex(payload) {
  return {
    type: ActionTypes.SET_SEARCH_ACCOUNT_TICKER_INDEX,
    payload
  }
}

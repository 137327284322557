import React, { Component } from 'react'
import { connect } from 'react-redux'

import 'style/pages/_investment.scss'

import Footer from '../components/Footer'
import Investments from '../components/account/InvestmentsV2'
import { FeedbackButtonStickToBottom } from '../components/feedback/feedback'

import { logout } from '../actions/auth'
import { newQuery } from '../actions/funds'
import { REDIRECT_DOMAIN_PATH } from 'DataSet'
import { investmentFetchSuccess } from 'actions/investment';

class AccountsContainer extends Component {

  componentWillMount() {
    const { investmentFetchSuccess } = this.props;
    investmentFetchSuccess({ view: 'account', activeAccount: 'All' })
  }

  render() {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      window.location = REDIRECT_DOMAIN_PATH;
      return;
    }
    return <InvestmentsWrap {...this.props} />
  }
}

const mapStateToProps = state => ({
  query: state.funds.query,
  user: state.auth.user,
  auth: state.auth,
  isSSOUser: Boolean(state.auth.isGoogleLogin),
  loggedIn: state.auth.loggedIn,
  logoutSuccess: state.auth.logoutSuccess,
})

const mapDispatchToProps = {
  logoutHandler: logout,
  queryHandler: newQuery,
  investmentFetchSuccess,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountsContainer)

const InvestmentsWrap = props => {
  return (
    <React.Fragment>
      {/* <FeedbackButtonStickToBottom /> */}
      <Investments {...props} />
      {/*<Footer />*/}
    </React.Fragment>
  )
}

import moment from 'moment';
import _ from 'lodash';
import CryptoJS from 'crypto-js';
import * as momentTZ from 'moment-timezone';
import { notification } from 'antd';
import { uniqBy, reverse, orderBy, get } from 'lodash';

import {
  // fetchFunds,
  fetchFundsDetails,
  fetchCategories,
  // fetchFundCommissions,
  fetchRealTimePrices,
  // fetchCharts,
  // fetchReturnsData,
	fetchReturnsDataV1,
	// fetchRegionsChart,
	fetchChartsV1,
  getTrendingQueries,
  getSelectorAttrsData,
  // getSimilarSearches,
} from 'apis/discovery';

import {
  getCalculatedEnhanceStats,
  // dateSort,
  getCurrentPfStats,
} from 'layouts/WebDashboard/AccountsPage/utils';

import {
  arrayToHash,
  uniqValues,
  getNest,
  getNestDefault,
  convertToCapitalCase,
  monthToDate,
  convertToFloatvalues,
  quintileSize,
  quintileText,
  nameToDateRange,
  formatTickerForView,
  getQuintile,
  dateToMonth,
  // addEventToAnalytics,
  nameToDateRangeForDailyReturns,
} from 'Utils';

import {
  FilterAttrData,
  DateRange,
  // ViewNames,
  cn,
  CN,
  // AssetType,
  MaxResult,
  BrokersComms,
  DefaultYears,
  mappingRequiredKeys,
  skipRequiredKeys,
  SectorsAttrs,
} from 'DataSet';

import { RegionChartMapping, SectorChartMapping } from 'ChartData';
import { calcStats, calcCumulativeReturns, calcDailyCumulativeReturns } from 'Stats';

import { RegionColors, SectorColors } from 'data/Colors';
// import { getInsightHashKey } from 'common/theme';

import config from 'env';
import { getFilteredListByRange } from './WebDashboard/SFM/utils';

// find, compare and update response data with reducer SFM Items
export const mapDataFromSFMItems = (responseFunds, reducerSfmItems, reducerBuilderItems) => {
  if (reducerSfmItems.length && responseFunds.funds && responseFunds.funds.length) {
    if(reducerBuilderItems.length){
      responseFunds.funds.forEach(e => {
        if (reducerBuilderItems.length > 0) {
          const found = reducerBuilderItems.find(d => d.ticker === e.ticker);
          if (found && typeof found !== 'undefined') {
            e._builder = true;
          }
        }
        if (reducerSfmItems.length > 0) {
          const found = reducerSfmItems.find(d => d.ticker === e.ticker);
          if (found && typeof found !== 'undefined') {
            e._selected = true;
            e._selector = found._selector;
          }
        }
      });
    } else {
      responseFunds.funds.forEach(e => {
        if (reducerSfmItems.length > 0) {
          const found = reducerSfmItems.find(d => d.ticker === e.ticker);
          if (found && typeof found !== 'undefined') {
            e._selected = true;
            e._selector = true;
            e._builder = false;
          }
        }
      });
    }
    return responseFunds;
  }
  return responseFunds;
}

export const fetchPrice = (funds) => {
  // intentionally used this.props.funds! (believe me dont change this)
  if (funds.data && funds.data.length && funds.ticks && funds.ticks.length) {
    const { fetchFundsPrice } = this.props;
    setTimeout(() => fetchFundsPrice({
      data: funds.data,
      ticks: funds.ticks,
    }), 500);
    if (this.priceTimer) clearInterval(this.priceTimer);
    this.priceTimer = setInterval(() => fetchFundsPrice({
      data: funds.data,
      ticks: funds.ticks,
    }), 61000);
  }
}

export const customScroll = () => {
  const elems = document.querySelectorAll('.custom-scroll');
  if (elems.length) {
    [].forEach.call(elems, function (el) {
      if (el.scrollHeight > el.clientHeight) {
        el.style.paddingRight = '.25rem';
        el.style.paddingLeft = '.40rem';
      } else el.style.paddingRight = '0rem';
    });
  }
}

//aka fetchData
// Changes done with DateRange picker for SMA and All universe funds data
// NOTE: New change variable 'dateRangeSelected' renamed to 'dateRangeSelected', which means based on DefaultYears and API's 'date_range' value selected range will be set.
// Previously it was Picking value from DataSet => DateRange (which was harded at initail phase)

// export async function executeDataMapping(_fundTickers, query) {
//   if (uniqValues(_fundTickers.data, 'ticker').length <= 0)
//   return { funds: [], categories: [], range: { start: moment(DateRange.start), end: moment(DateRange.end) }, filterAttrData: {} };
//
//   let resultCount = _fundTickers.total_funds || 0;
//   let ticks = uniqValues(_fundTickers.data, 'ticker').join(',');
//   let didYouMean = _fundTickers.didumean;
//   let updated_at = new moment().format('YYYY/MM/D hh:mm:ss SSS');
//   let relatedInsightsResponse = {
//     relatedInsights: [],
//     message: '',
//   };
//   let trendingQueries = [];
//   let sponsors_tickers = _fundTickers.sponsors_tickers;
//   // let [_funds, returns, categories, charts] = await Promise.all([
//   let [_funds, returns, categories, charts, fundsPrice, trendingFeedsResponse, selectorAttrsData] = await Promise.all([
//     fetchFundsDetails(_fundTickers),
//     // getRelatedInsightsData({ query }),
//     fetchReturnsDataV1({ tickers: ticks }),
//     fetchCategories({ tickers: ticks }),
//     // fetchRegionsChart({tickers:ticks}),
//     fetchChartsV1({ tickers: ticks }),
//     fetchRealTimePrices({ tickers: ticks }),
//     getTrendingQueries(),
//     getSelectorAttrsData({ tickers: ticks }),
//   ]);
//
//   let holdings = charts.topten,
//   assetTypes = charts.asset_type,
//   themeScore = charts.themes_new,
//   diverseData = charts.diversification,
//   riskAdjReturn = charts.risk_adjusted;
//
//   const { log_id, query_uuid } = _fundTickers;
//   const { related, messages ,date_range, query_log } = _funds;
//   let { corrected_query } = _funds;
//
//   corrected_query = corrected_query || query_log.corrected_query_string;
//
//   let itemCardView = {};
//   let funds = [];
//   if (_funds["funds"] && _funds["funds"].length) {
//     funds = _funds["funds"].slice(0, MaxResult);
//   }
//
//   // window.addKrakenEvent({ name: 'Search Api' ,body: {query: corrected_query,logId:false}});
//   // if (window.heap) {
//   //   window.heap.track('Search Api',{query: corrected_query,logId:false});
//   // }
//   // window.gtag('event', 'SEARCH_API',{
//   //   'event_label': JSON.stringify({query: corrected_query,logId:false})});
//
//   if (funds.length <= 0)
//   return { funds: [], categories: [], range: _funds["date_range"], filterAttrData: {} };
//
//   const queryArr = _funds["query_var"];
//   let highlightArr = [];
//   let queryCategory = 'unknown';
//   let dynamicQueryCategory, queryCategoryValue, viewName, secondaryViewName, chartData = [], ticker, highlightText = {}, outPerfReturnsData;
//
//   let relevantCharts = (queryArr.filter(e => {
//     if (e.active && e.view_name && e.view_name != "") {
//       if (e.view_name != 'Highlight') {
//         return e;
//       }
//       else
//       highlightArr.push(e)
//     }
//   }) || []);
//
//   let relevantViewNames = [], jointNameStr = '', jointNameArray = [];
//   if (relevantCharts && relevantCharts.length > 0) {
//     let secondaryViewArr = [];
//     relevantCharts.forEach((r, i) => {
//       let view = r.view_name;
//       jointNameArray.push(view);
//       // NOTE: pick only dynamic chart
//       if (view === 'Regions') {
//         let displayText = RegionChartMapping.filter(
//           e => e.chart_name === r.node && r.Static === true
//         )
//         if (displayText.length) {
//           let toBeDisplayedView = '%\u00a0' + displayText[0].region_name;
//           view = toBeDisplayedView;
//         }
//       } else if (view === 'Sectors') {
//         let find = SectorChartMapping.filter(
//           e => e.chart_name === r.node && r.Static === true
//         )
//         if (find.length > 0) {
//           let toBeDisplayedView = '%\u00a0' + find[0].sector_name
//           view = toBeDisplayedView
//         }
//       }
//       else if (
//         view == 'Top Holdings' &&
//         r.data &&
//         (r.variable.includes('holdings_') ||
//         r.variable.include('neg_holdings_'))
//       ) {
//         let ticker = ''
//         if (Object.prototype.toString.call(r.data) === '[object Array]') {
//           ticker = r.data[0]
//         } else if (typeof r.data === 'string') {
//           ticker = r.data
//         }
//         if (ticker.length > 4) ticker = formatTickerForView(ticker)
//         let toBeDisplayedView = '%\u00a0' + ticker
//         view = toBeDisplayedView
//         // return name;
//       }
//       // NOTE: for Holdings mapping, pick 'view' from query_var.data
//       if (view && view.includes('ticker')) {
//         if (Object.prototype.toString.call(r.data) === '[object Array]') {
//           view = view.replace("ticker", r.data[0]);
//         }
//         else if (typeof r.data === 'string') {
//           view = view.replace("ticker", r.data);
//         }
//       }
//       //push the individual view name
//       relevantViewNames.push({ name: view, secondaryView: r.Secondary_view_name, originalName: [r.view_name], variable: r.variable });
//       relevantCharts[i].display_view_name = view;
//       // console.log(r.variable);
//       relevantCharts[i].variable = r.variable;
//       if (view == 'Return Quality') view = view + ' (' + r.Secondary_view_name + ')';
//       i == 0 ? jointNameStr += view : jointNameStr += ' && ' + view;
//       // secondaryViewArr.push(r.Secondary_view_name);
//     });
//     if (relevantViewNames.length > 1) relevantViewNames.push({ name: jointNameStr, secondaryView: '', originalName: jointNameArray });
//   }
//   else {
//     relevantViewNames.push({ name: 'Assets', secondaryView: '', originalName: ['Assets'] });
//     relevantCharts.push({ active: true, Static: true, view_name: 'Assets', Secondary_view_name: '', display_view_name: 'Assets' });
//   }
//
//   if (relevantViewNames && relevantViewNames.length > 0 && relevantViewNames[relevantViewNames.length - 1] && relevantViewNames[relevantViewNames.length - 1].name.includes('&')) {
//     itemCardView = {
//       view: relevantViewNames[0].name,
//       secondaryView: relevantViewNames[0].secondaryView,
//       mappingVariable: relevantViewNames[0].variable || '',
//     };
//   } else {
//     itemCardView = {
//       view: relevantCharts[0].display_view_name,
//       secondaryView: relevantCharts[0].Secondary_view_name,
//       mappingVariable: relevantCharts[0].variable || '',
//     };
//   }
//
//   if (relevantCharts.length > 0)
//   chartData = relevantCharts;
//   highlightText = {
//     feesHighLightEnable: false,
//     returnHighLightEnable: false,
//     riskHighLightEnable: false,
//   };
//
//   if (highlightArr.length) {
//     let highlightPopup = [];
//     const highlightText_local = highlightArr.map((item) => {
//       if (typeof item.chart_type !== 'undefined' && item.chart_type.length && item.active) {
//         if (item.chart_type == 'highlight_popup')
//         highlightPopup.push(item);
//         return item.chart_type.split('highlight_')[1];
//       }
//     });
//
//     if (highlightText_local.length) {
//       highlightText_local.map((item) => {
//         switch (item) {
//           case 'fees':
//           highlightText.feesHighLightEnable = true;
//           break;
//           case 'returns':
//           highlightText.returnHighLightEnable = true;
//           break;
//           case 'risk':
//           highlightText.riskHighLightEnable = true;
//           break;
//           case 'popup':
//           highlightText.popupHighLightEnable = highlightPopup;
//           break;
//           default:
//           highlightText = {
//             feesHighLightEnable: false,
//             returnHighLightEnable: false,
//             riskHighLightEnable: false,
//           };
//         }
//       });
//     } else {
//       highlightText = {
//         feesHighLightEnable: false,
//         returnHighLightEnable: false,
//         riskHighLightEnable: false,
//       };
//     }
//   } else {
//     highlightText = {
//       feesHighLightEnable: false,
//       returnHighLightEnable: false,
//       riskHighLightEnable: false,
//     };
//   }
//
//   const commData = BrokersComms;
//   const returnsData = arrayToHash(returns, CN['Ticker']);
//   const chartsData = arrayToHash(charts, CN['Ticker']);
//
//   funds.forEach((e, index) => {
//     // funds[index].region = regionChartData[e.ticker].region;
//     funds[index].region = (chartsData[cn(e, 'Ticker')] && chartsData[cn(e, 'Ticker')].result && chartsData[cn(e, 'Ticker')].result.regions) ? (chartsData[cn(e, 'Ticker')].result.regions.length ? chartsData[cn(e, 'Ticker')].result.regions[0].region : []) : [];
//     funds[index].returns = (returnsData[e.ticker] && returnsData[e.ticker].returns) ? returnsData[e.ticker].returns : [];
//     funds[index].returns = reverse(funds[index].returns);
//     let r = getNest(['returns', 0], e);
//     if (r && e.returns && e.returns.length > 0) {
//       e._start = r['d'];
//       e._end = e.returns[e.returns.length - 1]['d'];
//     }
//     // const currentSelectorData = selectorAttrsData.find(item => checkItemTicker(item, e.ticker));
//     const currentSelectorData = selectorAttrsData.find(item => item[e.ticker]);
//     e.selectorAttrsData = currentSelectorData && currentSelectorData[e.ticker] && currentSelectorData[e.ticker].length ? currentSelectorData[e.ticker][0] : {};
//     // adding query field for each fund inorder to generate 'REPORT' feature.
//     e.query = query;
//     e.correctedQuery = corrected_query;
//
//     if (e.returns && e.returns.length > 0) {
//       e.returns.forEach((item, index) => {
//         e.returns[index].v = item.v / 100;
//       });
//     }
//
//     // e.brokers = getNest([cn(e, 'Ticker'), 'brokers'], commData);
//     e.brokers = BrokersComms;
//     e.broker = getNest([8], e.brokers);
//
//     //regions data manipulation -- adding others
//     if (e.region.length > 0) {
//       e.region.forEach((reg, i) => {
//         let sum = 0;
//         if (reg.n == 'Others') {
//           reg.sub.forEach(child => {
//             sum += child.v;
//           })
//           e.region[i].sub = [{ n: 'Others ', v: sum }];
//         }
//       });
//     }
//
//     e.assetTypeData = chartsData[cn(e, 'Ticker')].result.asset_type[0] || []
//     //calculate theme score chart data
//     e.themeScore = chartsData[cn(e, 'Ticker')].result.themes_new[cn(e, 'Ticker')];
//     //calculate risk adjusted return chart data
//     e.riskAdjReturn = chartsData[cn(e, 'Ticker')].result.risk_adjusted[cn(e, 'Ticker')];
//     e.diverseData = chartsData[cn(e, 'Ticker')].result.diversification[0];
//
//     //calculate holding chart data
//     let _sum = 0.0;
//     let holdingData = chartsData[cn(e, 'Ticker')].result.topten.funds.length ? chartsData[cn(e, 'Ticker')].result.topten.funds[0].holdings : [] // level 1 holdings
//
//     if (chartsData[cn(e, 'Ticker')].result.topten.funds.length) {
//       // console.log(queryArr);
//       if (queryArr.length > 1) {
//         const modelPortFolio = queryArr.filter((v) => v.variable.includes('vehicle/model_portfolio'));
//         const ontologyNodeIncludesSTOCK = queryArr.filter((c) => c.ontology_node.includes('exposure/ticker/stock_ticker'));
//
//         if (chartsData[cn(e, 'Ticker')].result.topten.funds[0]) {
//           if (modelPortFolio && modelPortFolio.length > 0 && ontologyNodeIncludesSTOCK && ontologyNodeIncludesSTOCK.length > 0) {
//             console.log('---funds holdings level 2---');
//             holdingData = chartsData[cn(e, 'Ticker')].result.topten.funds[0].holdings2; // level 2 holdings
//           }
//         }
//       }
//     }
//
//     e.holdingData = holdingData // getNestDefault([cn(e, 'Ticker'),'holdings'], holdData, [])
//     .map((el, i) => {
//       _sum += el.weight;
//       return {
//         name: el.name,
//         value: el.weight,
//         color: SectorColors[i % SectorColors.length]
//       }
//     });
//     if (e.holdingData.length > 0 && _sum < 100) {
//       let rem = 100 - _sum.toFixed(1);
//       rem = parseFloat(rem.toFixed(1));
//       e.holdingData.push({ name: 'Others', value: rem, color: '#ddd' });
//     }
//
//     //add queryCategoryValue to fund card
//     if (queryCategoryValue) e.queryCategoryValue = queryCategoryValue;
//   });
//
//   categories["categories"].forEach(e => {
//     let r = getNest(['returns', 0], e);
//     if (r && e.returns && e.returns.length > 0) {
//       e._start = r['d'];
//       e._end = e.returns[e.returns.length - 1]['d'];
//     }
//     if (e.returns && e.returns.length > 0) {
//       e.returns.forEach((item, index) => {
//         e.returns[index].v = item.v / 100;
//       });
//     }
//   });
//
//   let filterAttrData = FilterAttrData.reduce((acc, v) => ({ ...acc, [v.name]: uniqValues(funds, v.col) }), {});
//   filterAttrData = Object.keys(filterAttrData).reduce((acc, item) => {
//     acc[item] = filterAttrData[item].filter(x => (x != 0 || x != ''))
//     return acc;
//   }, {});
//
//   if (fundsPrice) {
//     const updatedFundsPriceData = getFundsUpdatedPrice(fundsPrice, funds);
//     if (updatedFundsPriceData) {
//       funds = updatedFundsPriceData.funds;
//       updated_at = updatedFundsPriceData.updated_at;
//     }
//   }
//
//   const applyDateRangeFilter = {
//     start: (date_range && date_range.start) ? moment(new Date(`01 ${date_range.start}`)) : moment(DateRange.start),
//     end: moment(new Date(`01 ${date_range.end}`)),
//   }
//
//   // console.log(applyDateRangeFilter);
//
//   // based of API's date_range value execute `getUpdatedRangeFromYears` function.
//   const updatedFundsDateRange = getUpdatedRangeFromYears(DefaultYears, applyDateRangeFilter); // returns Jun 2016, May 2019
//   const newUpdatedFundsData = filterApply(funds, updatedFundsDateRange, filterAttrData); // JSON.parse(JSON.stringify(funds));
//   // let sliderEndDate = window.sessionStorage.getItem('sliderEndDate');
//   // if(!sliderEndDate){
//     //enable this code to save the end date in moment format
//     let dd = moment(JSON.parse(JSON.stringify(updatedFundsDateRange.end))).endOf('month');
//     if (!dd.isValid()) {
//       dd = moment("01 " + moment(updatedFundsDateRange.end, 'MMM YYYY')._i, "DD MMM YYYY");
//       dd = dd.endOf('month');
//     }
//     window.sessionStorage.setItem('sliderEndDate', dd.toString());
//   // }
//
//   // const NEW_DATA_FROM_FILTER = JSON.parse(JSON.stringify(updatedFundsDateRange));
//   const NEW_DATA_FROM_FILTER = applyDateRangeFilter; //API's date_range value for.e.g: 2014 - 2020
//   // console.log({ start: moment("01 "+NEW_DATA_FROM_FILTER.start, "DD MMM YYYY"), end: moment("01 "+NEW_DATA_FROM_FILTER.end, "DD MMM YYYY") });
//   // const sponsor_ticker = _fundTickers.sponsors_tickers;
//   // const themeValue = chartData.map((item) => {
//   //   if (item && item.ontology_node) {
//   //     const themeData = item.ontology_node.split('/');
//   //     if (themeData.length) {
//   //       themeData.filter(i => i === 'theme');
//   //       return themeData[themeData.length - 1];
//   //     }
//   //   }
//   // });
//   // try {
//   //   var insightKey = null;
//   //   if(themeValue && themeValue.length){
//   //     themeValue.forEach(i => {
//   //       if(!insightKey)
//   //         insightKey = getInsightHashKey(i);
//   //     });
//   //   }
//   //   if (themeValue && themeValue.length && insightKey && RelatedInsightsToggle) {
//   //     // call mio insight api
//   //     const idHash = insightKey;
//   //     let [pluginResponse] = await Promise.all([
//   //       getRelatedInsightsData({ id_hash: idHash }),
//   //     ])
//   //     if (pluginResponse.result.success ===  true) {
//   //       let feeds = pluginResponse.result.data.feed;
//   //       if (feeds) {
//   //         let newArr = feeds.filter(item => (item.media_urls.length > 0 && item.text != ""));
//   //         const arr = [];
//   //         // newArr = reverse(newArr);
//   //         if (newArr.length) {
//   //           if(config.sponsoredTicker && sponsor_ticker.length === 3) { newArr.length = 2; } //0
//   //           else if (config.sponsoredTicker && sponsor_ticker.length === 1) { newArr.length = 2; } //2
//   //           else if (config.sponsoredTicker && sponsor_ticker.length === 2) { newArr.length = 2; } //1
//   //           else { newArr.length = 3; }
//   //           // newArr.length = 3;
//   //           arr.push(newArr);
//   //           arr.push(pluginResponse.result.data.details);
//   //           relatedInsightsResponse = {
//   //             relatedInsights: arr,
//   //             message: 'success',
//   //           };
//   //         } else {
//   //           //console.log('media_urls in response not found');
//   //           relatedInsightsResponse = {
//   //             relatedInsights: [],
//   //             message: 'media_urls in response not found',
//   //           };
//   //         }
//   //       } else {
//   //         //console.log('empty insight api response');
//   //         relatedInsightsResponse = {
//   //           relatedInsights: [],
//   //           message: 'empty insight api response',
//   //         };
//   //       }
//   //     }
//   //     else if (pluginResponse.result.success === false) {
//   //       console.log('No data for related insights');
//   //       relatedInsightsResponse = {
//   //         relatedInsights: [],
//   //         message: 'No data',
//   //       };
//   //     }
//   //   }
//   // } catch (error) {
//   //   console.log('error -------->>>>>>>>>>>>>>>>>>>>>>>>>>', error);
//   //   relatedInsightsResponse = {
//   //     relatedInsights: [],
//   //     message: 'No data',
//   //   };
//   // }
//
//   if (trendingFeedsResponse) {
//     trendingQueries = trendingFeedsResponse;
//   }
//
//   return {
//     funds: newUpdatedFundsData,
//     highlightText,
//     categories: categories["categories"],
//     range: NEW_DATA_FROM_FILTER,
//     dateRangeSelected: updatedFundsDateRange,
//     filterAttrData,
//     relevantViewNames,
//     chartData,
//     related,
//     didYouMean,
//     corrected_query,
//     outPerfReturnsData,
//     messages,
//     ticks,
//     itemCardView,
//     relatedInsightsResponse,
//     log_id,
//     query_uuid,
//     updated_at,
//     sponsors_tickers,
//     trendingQueries,
//     resultCount,
//   };
// }

export const filterApply = (fundsData, range, filterAttrs) => {
  const FUNDS_DATA = JSON.parse(JSON.stringify(fundsData));

  // commenting because research team wants funds to be displayed even when returns are empty..
  const dd = moment(range.start);
  // console.log(dd);

  if (!dd.isValid()) {
    // console.log(moment(range.start, 'MMM YYYY')._i);
    range = {
      start: moment("01 " + moment(range.start, 'MMM YYYY')._i, "DD MMM YYYY"),
      end: moment("01 " + moment(range.end, 'MMM YYYY')._i, "DD MMM YYYY"),
    }
    //console.log(range);
  }

  const rangeFilter = e => {
    let dt = monthToDate(e.d);
    return dt.isAfter(moment(range.start).subtract(1, 'seconds')) && dt.isBefore(moment(range.end).add(1, 'seconds'));
  }

  FUNDS_DATA.forEach(e => {
    e._hidden = false;
    if (e.returns.length > 0) {
      const d1 = moment("01 " + e._start, "DD MMM YYYY"), d2 = moment("01 " + e._end, "DD MMM YYYY");
      // from itemlist/utils.js

      // commenting because research team wants funds to be displayed even when returns are empty..
      const points = e.returns.filter(rangeFilter);
      if (points.length <= 0) {
        e._hidden = true;
        return;
      }

      // for. e.g: dateRangeSelected: 1st May 2019
      // whereas fund's start date is 1st Jul 2019 && end date is 1st July 2019
      // then check d1.isAfter(moment(dateRangeSelected.end).startOf('month')) and set true
      if (d1.isAfter(moment(range.end).startOf('month'))) {
        e._hidden = true;
      }

      if (d2.isBefore(moment(range.start).startOf('month'))) {
        e._hidden = true;
      } else {
        for (var f in filterAttrs) {
          if (cn(e, f) === undefined || (filterAttrs[f].length > 0 && !filterAttrs[f].includes(cn(e, f)))) { // re-check 1st condition
            e._hidden = true;
          }
        }
      }

      // console.log("start date ==>", moment("01 " + range.start, "DD MMM YYYY"), range.start);
      // console.log("end date ==>", moment("01 " + range.end, "DD MMM YYYY"), range.end);
      // console.log('d2 ==>', d2);

      // const compareDate = moment().range(moment("01 " + range.start, "DD MMM YYYY"), moment("01 " + range.end, "DD MMM YYYY");
      // console.log(compareDate.contains(d2));

      // const startDateInBetweenRange = d1.isBetween(moment("01 " + range.start, "DD MMM YYYY"), moment("01 " + range.end, "DD MMM YYYY"), 'days', true);
      // const endDateInBetweenRange = d2.isBetween(moment("01 " + range.start, "DD MMM YYYY"), moment("01 " + range.end, "DD MMM YYYY"), 'days', true);
      // console.log('startDateInBetweenRange-> ',startDateInBetweenRange, ' endDateInBetweenRange ->', endDateInBetweenRange);

      // if (!startDateInBetweenRange || !endDateInBetweenRange) {
      //   e._hidden = true;
      // }
      // else {
      //   for (var f in filterAttrs) {
      //     if (cn(e, f) === undefined || (filterAttrs[f].length > 0 && !filterAttrs[f].includes(cn(e, f)))) { // re-check 1st condition
      //       e._hidden = true;
      //     }
      //   }
      // }
    }
  });

  // console.log(JSON.parse(JSON.stringify(FUNDS_DATA)));

  return FUNDS_DATA;
}

export const getFundsUpdatedPrice = (res, funds) => {
  const priceData = arrayToHash(res["funds"], CN['Ticker']);
  funds.forEach(e => {
    if (e[CN['Vehicle']] === 'ETF' || e[CN['Vehicle']] === 'ETN' || e[CN['Vehicle']] === 'SHARE' || e[CN['Vehicle']] === 'MF')
      e[CN['Nav Price']] = getNestDefault([cn(e, 'Ticker'), 'price'], priceData, cn(e, 'Nav Price'));
  });
  return {
    updated_at: new moment().format('YYYY/MM/D hh:mm:ss SSS'),
    funds,
  };
}

export const accountsPageUpdatePrice = (res, funds, tickerKey) => {
  funds.forEach(e => {
    if (e['asset'] !== 'MF') {
      res.map((item) => {
        if (tickerKey === 'script') {
          if (e[tickerKey] === item['ticker']) {
            e['price'] = item['price'];
          }
        }
        if (tickerKey === 'symbol') {
          if (e[tickerKey] === item['ticker'] && e['quantity'] && e['quantity'] !== null) {
            const qtyToStr = parseFloat(e['quantity']);
            e.last_price = parseFloat(Math.round(qtyToStr) * item['price']);
          }
        }
      });
    }
  });
  return {
    updated_at: new moment().format('YYYY/MM/D hh:mm:ss SSS'),
    funds,
  };
}

export const nameToRange = (name, dateRange) => {
  var out = { end: dateRange.end.clone().endOf('month') };
  out.start = out.end.clone();
  if (name === 'l10y') { out.start.add(1, 'seconds').subtract(10, 'year') }
  else if (name === 'l5y') { out.start.add(1, 'seconds').subtract(5, 'year') }
  else if (name === 'l3y') { out.start.add(1, 'seconds').subtract(3, 'year') }
  else if (name === 'l1y') { out.start.add(1, 'seconds').subtract(1, 'year') }
  else { out.start = moment(DateRange.start) }

  // console.log(out.start.toString(), out.end.toString());
  return out;
}

export const getUpdatedRangeFromYears = (years, dateRange) => {
  const range = nameToRange('l' + years + 'y', dateRange);
  // console.log(range);
  const daterange = {
    start: moment(range.start).format('MMM YYYY'),
    end: moment(range.end).format('MMM YYYY'),
  }
  return daterange;
}

export const CHECK_QUICK_TRADE = () => {
  let fundTicker = window.sessionStorage.getItem('buy');
  let fundPrice = window.sessionStorage.getItem('fundPrice');
  let fundIsSponsored = window.sessionStorage.getItem('fundIsSponsored');
  let fundSponsorLogo = window.sessionStorage.getItem('fundSponsorLogo');
  let fundName = window.sessionStorage.getItem('fundName');
  let fundCategory = window.sessionStorage.getItem('fundCategory');
  let fundMI = window.sessionStorage.getItem('fundMI');
  let fundVehicle = window.sessionStorage.getItem('fundVehicle');

  return (process.env.REACT_APP_SUB_DOMAIN === 'retail' &&
    typeof fundTicker !== 'undefined' &&
    typeof fundPrice !== 'undefined' &&
    typeof fundIsSponsored !== 'undefined' &&
    typeof fundSponsorLogo !== 'undefined' &&
    typeof fundName !== 'undefined' &&
    typeof fundCategory !== 'undefined' &&
    typeof fundMI !== 'undefined' &&
    typeof fundVehicle !== 'undefined' &&
    fundTicker &&
    fundPrice &&
    fundIsSponsored &&
    fundSponsorLogo &&
    fundName &&
    fundCategory &&
    fundMI &&
    fundVehicle
  );
}

export const getQueryItems = (props) => {
  let params,qury, paymentStatus, utm_campaign, utm_medium, utm_source, utm_term, utm_content, utm_usage_log_id, buy, compare, utm_token;
  let fundPrice, fundIsSponsored, fundSponsorLogo, fundName, fundCategory, fundMI, fundVehicle;
  if (props.location.search) {
    params = new URLSearchParams(props.location.search);
    qury = params.get('query');
    paymentStatus = params.get('paymentStatus');
    utm_campaign = params.get('utm_campaign');
    utm_medium = params.get('utm_medium');
    utm_source = params.get('utm_source');
    utm_term = params.get('utm_term');
    utm_content = params.get('utm_content');
    buy = params.get('buy');
    compare = params.get('compare');
    utm_usage_log_id = params.get('utm_usage_log_id');
    utm_token = params.get('utm_token');
    fundPrice = params.get('fundPrice');
    fundIsSponsored = params.get('fundIsSponsored');
    fundSponsorLogo = params.get('fundSponsorLogo');
    fundName = params.get('fundName');
    fundCategory = params.get('fundCategory');
    fundMI = params.get('fundMI');
    fundVehicle = params.get('fundVehicle');
  }

  if(paymentStatus){window.sessionStorage.setItem('paymentStatus', paymentStatus)}
  if(utm_campaign){window.sessionStorage.setItem('utm_campaign', utm_campaign)}
  if(utm_medium){window.sessionStorage.setItem('utm_medium', utm_medium)}
  if(utm_source){window.sessionStorage.setItem('utm_source', utm_source)}
  if(utm_term){window.sessionStorage.setItem('utm_term', utm_term)}
  if(utm_content){window.sessionStorage.setItem('utm_content', utm_content)}
  if(buy){window.sessionStorage.setItem('buy', buy)}
  if (compare) { window.sessionStorage.setItem('compare', compare) }
  if (utm_usage_log_id) { window.sessionStorage.setItem('utm_usage_log_id', utm_usage_log_id) }
  if (utm_token) { window.sessionStorage.setItem('utm_token', utm_token) }

  if (fundPrice) { window.sessionStorage.setItem('fundPrice', fundPrice) }
  if (fundIsSponsored) { window.sessionStorage.setItem('fundIsSponsored', fundIsSponsored) }
  if (fundSponsorLogo) { window.sessionStorage.setItem('fundSponsorLogo', fundSponsorLogo) }
  if (fundName) { window.sessionStorage.setItem('fundName', fundName) }
  if (fundCategory) { window.sessionStorage.setItem('fundCategory', fundCategory) }
  if (fundMI) { window.sessionStorage.setItem('fundMI', fundMI) }
  if (fundVehicle) { window.sessionStorage.setItem('fundVehicle', fundVehicle) }
  // 'n' number events call, so commenting below logic
  // if((utm_source || utm_medium || utm_campaign) && addEventToAnalytics) {
  //   let qq = props.query || qury || window.sessionStorage.getItem('query')
  //   // addEventToAnalytics('UTM_LINK_CAPTURED','UTM_LINK_CAPTURED','UTM_LINK_CAPTURED',{ utm_source, utm_medium, utm_campaign, query: qq},false); removed conflict
  //   addEventToAnalytics('UTM_LINK_CAPTURED','UTM_LINK_CAPTURED','UTM_LINK_CAPTURED',{ utm_source, utm_medium, utm_campaign, query: qq},true);
  // }

  // return props.query || qury || window.sessionStorage.getItem('query')
  return qury || '';
}

export const getCookie = (cname) => {
  let name = cname + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

// data mapping for enhancer page
export async function dataMapForEnhancer(response, query) {
  try {
    if (response && response.final_result) {
      let responseData = response.final_result;
      let guidanceMessage = response.messages || [];

      let _responseData = responseData.filter((r) => r.target_card && r.target_card.funds && r.target_card.funds.length > 0).filter(x => x.result.funds); // NOTE: TARGET CARD MUST BE PRESENT
      let updated_at = new moment().format('YYYY/MM/D hh:mm:ss SSS');
      let ticks = [], swapTickerData = [], checkboxToggleState = false, enhanceTickerFilter = [];

      if (_responseData.length <= 0) {
        return { data: [], categories: [], swapTickerData, updated_at, checkboxToggleState, guidanceMessage };
      }

      _responseData.forEach(e => {
        ticks = ticks.concat(uniqValues(e.result.funds, 'ticker'));
      });

      if (ticks.length <= 0)
      return { data: [], categories: [], swapTickerData, updated_at, checkboxToggleState, guidanceMessage };

      try {
        ticks = [...new Set(ticks)].join(',');

        if(query == 'user_criteria'){
          _responseData = _responseData.map(x => {x.query = 'Best alternatives to '+ x.ticker + ' optimized using my criteria'; return x;})
        }

        let [categories, fundsPrice] = await Promise.all([
          fetchCategories({ tickers: ticks }),
          fetchRealTimePrices({ tickers: ticks }),
        ]);

        categories["categories"].forEach(e => {
          let r = getNest(['returns', 0], e);
          if (r) {
            e._start = r['d'];
            e._end = e.returns[e.returns.length - 1]['d'];
          }
          e.returns.forEach((item, index) => {
            e.returns[index].v = item.v / 100;
          });
        });

        let _defaultFRR = ['fees', 'risk', 'returns'];
        const FRR_DATA_ATTRS = getFRRAttrData().map((kj) => kj.attr);
        // console.log(FRR_DATA_ATTRS);

        _responseData.forEach((e, index) => {
          // Tabs section: 'Tabs' view name => ['Overlap', 'Risk', 'Returns']
          // NOTE: default active View index [1, 2, 3]; whereas 1 for Overlap chart
          // and 2, 3 ... N for remaining 'Risk', 'Return' ... etc.
          e._viewIndex = "1"; // 1 ALWAYS stands for chart

          e.result._defaultFRR = ['fees', 'risk', 'returns'];
          const excludeNodesForFRR = ['correlation', 'overlap', 'similarity', 'fees', 'risk', 'returns'];
          e.result.query_var = e.result.query_var.reverse();
          const _nodes = e.result.query_var.filter((s) => (s.view_name !== '' && s.node !== "" && !excludeNodesForFRR.includes(s.node)))
                                           // .filter((l) => FRR_DATA_ATTRS.includes(l.node))
                                           .filter((w) => !w.view_name.includes('Highlight')).slice(0, 2)
                                           .map((k) => k.node);
          // console.log('_nodes', _nodes); // _nodes: ['Yield', 'Assets'];
          const chartNodes = e.result.query_var.filter(hg => (hg.variable.includes('overlap') || hg.variable.includes('correlation'))).filter((w) => !w.view_name.includes('Highlight')).map((k) => k.view_name);
          // console.log('chart Nodes', chartNodes);

          if (_nodes && _nodes.length > 0) {
            _nodes.map((l, li) => {
              if (typeof e.result._defaultFRR[li+1] === 'undefined') {
                e.result._defaultFRR.push(l);
              } else {
                e.result._defaultFRR[li+1] = l // e.result._defaultFRR: ['Fees', 'Yield', 'Assets'];
              }
            });
          }

          const filtr = ['fees', 'risk', 'returns'].filter((j) => !e.result._defaultFRR.includes(j));

          e.result._relevanceChartTabs = [
            ...chartNodes,
            ...filtr,
          ];

          let _funds = e.result.funds;
          enhanceTickerFilter.push(e.ticker);

          if (_funds.length > 0) {
            _funds.forEach((item, index) => {
              if (index === 0) {
                item._selected = true; // setting checkbox true for 1st fund
                swapTickerData.push({
                  query: e.query,
                  ticker: e.ticker,
                  replaceTicker: item.ticker,
                  replace: item.ticker,
                  selectedPortfolioFunds: {
                    ...item,
                    query_var: e.result.query_var, // for showing correlation chart
                    [`overlap_${e.ticker}`] : item[`overlap_${e.ticker}`],
                    'Fee_Risk_Return': item.Fee_Risk_Return,
                    category: item.category,
                    ticker: item.ticker,
                    dividend_yield: item.dividend_yield,
                    fees: item.fees,
                    feesq: item.feesq,
                    name: item.name,
                    nav: item.nav,
                    returns: item.returns,
                    short_desc: item.short_desc,
                    short_name: item.short_name,
                    sponsor: item.sponsor,
                    vehicle: item.vehicle,
                    asset_alloc: item.asset_alloc,
                    weight_yield: item.weight_yield,
                    weight_track_record: item.weight_track_record,
                    'attribute/technical/returns/risk_adjusted_returns': item['attribute/technical/returns/risk_adjusted_returns'],
                    'attribute/fundamental/yield': item['attribute/fundamental/yield'] || item['yield'],
                    outperform : item['outperform'] || 0.0,
                    'attribute/track_record': item['attribute/track_record'] || item['track_record'],
                  }, // NOTE:: need to remove unused keys from this object to only required ones, might be reason for speed issue
                })
              } else {
                item._selected = false;
              }
            });
            checkboxToggleState = true;
          }
          if (fundsPrice) {
            const updatedFundsPriceData = getFundsUpdatedPrice(fundsPrice, _funds);
            if (updatedFundsPriceData) {
              _funds = updatedFundsPriceData.funds;
              updated_at = updatedFundsPriceData.updated_at;
            }
          }
          e.result.funds = _funds;
          e.pseudo_query = e.query;
          e.query = e.query +" "+e.ticker;
        });

        return {
          data: _responseData,
          swapTickerData,
          categories,
          checkboxToggleState,
          updated_at,
          enhanceTickerFilter,
          guidanceMessage,
        };
      } catch (e) {
        console.log(e);
      }
    } else {
      throw '-- Response structure error: missing `final_response` --'
    }

  } catch (e) {
     console.log(e);
  }
}

export async function replicaDataMapForEnhancer(response, query, _oldPortfolioFunds, _enhTickerFil) {
  try {
    const _response = JSON.parse(JSON.stringify(response));
    const _enhanceTickerFilter = JSON.parse(JSON.stringify(_enhTickerFil))
    // console.log('--- _enhanceTickerFilter ---', _enhanceTickerFilter);
    if (response && _response.final_result) {
      let responseData = _response.final_result;
      let guidanceMessage = _response.messages || [];

      let _responseData = responseData.filter((r) => r.target_card && r.target_card.funds && r.target_card.funds.length > 0).filter(x => x.result.funds); // NOTE: TARGET CARD MUST BE PRESENT

      let updated_at = new moment().format('YYYY/MM/D hh:mm:ss SSS');
      let ticks = [], swapTickerData = [], checkboxToggleState = false, enhanceTickerFilter = [], useSuggestedFunds = [], usingSuggestedFunds = false, preserveEnhanceTickerFilter = [], preserveSwapTickerData = [];

      if (_responseData.length <= 0) {
        return {
          data: [],
          updated_at,
          categories: [],
          guidanceMessage,
          swapTickerData,
          preserveSwapTickerData,
          useSuggestedFunds,
          usingSuggestedFunds,
          checkboxToggleState,
          enhanceTickerFilter,
          preserveEnhanceTickerFilter,
        };
      }

      _responseData.forEach(e => {
        ticks = ticks.concat(uniqValues(e.result.funds, 'ticker'));
      });

      if (ticks.length <= 0)
      return {
        data: [],
        updated_at,
        categories: [],
        guidanceMessage,
        swapTickerData,
        preserveSwapTickerData,
        useSuggestedFunds,
        usingSuggestedFunds,
        checkboxToggleState,
        enhanceTickerFilter,
        preserveEnhanceTickerFilter,
      };

      try {
        ticks = [...new Set(ticks)].join(',');

        if(query == 'user_criteria'){
          _responseData = _responseData.map(x => {x.query = 'Best alternatives to '+ x.ticker + ' optimized using my criteria'; return x;})
        }

        let [fundsPrice] = await Promise.all([
          // fetchCategories({ tickers: ticks }),
          fetchRealTimePrices({ tickers: ticks }),
        ]);

        // categories["categories"].forEach(e => {
        //   let r = getNest(['returns', 0], e);
        //   if (r) {
        //     e._start = r['d'];
        //     e._end = e.returns[e.returns.length - 1]['d'];
        //   }
        //   e.returns.forEach((item, index) => {
        //     e.returns[index].v = item.v / 100;
        //   });
        // });

        let _defaultFRR = ['fees', 'risk', 'returns'];
        const FRR_DATA_ATTRS = getFRRAttrData().map((kj) => kj.attr);
        // console.log(FRR_DATA_ATTRS);

        _responseData.forEach((e, index) => {
          // Tabs section: 'Tabs' view name => ['Overlap', 'Risk', 'Returns']
          // NOTE: default active View index [1, 2, 3]; whereas 1 for Overlap chart
          // and 2, 3 ... N for remaining 'Risk', 'Return' ... etc.

          // NOTE: PHASE 1

          e._viewIndex = "1"; // 1 ALWAYS stands for chart

          e.result._defaultFRR = ['fees', 'risk', 'returns'];
          const excludeNodesForFRR = ['correlation', 'overlap', 'similarity', 'fees', 'risk', 'returns'];
          e.result.query_var = e.result.query_var.reverse();
          e.result.query_var = e.result.query_var.filter((item) => item.view_name != 'Highlight')
          const _nodes = e.result.query_var.filter((s) => (s.view_name !== '' && s.node !== "" && !excludeNodesForFRR.includes(s.node)))
                                           // .filter((l) => FRR_DATA_ATTRS.includes(l.node))
                                           .filter((w) => !w.view_name.includes('Highlight')).slice(0, 2)
                                           .map((k) => k.node);
          // console.log('_nodes', _nodes); // _nodes: ['Yield', 'Assets'];
          const chartNodes = e.result.query_var.filter(hg => (hg.variable.includes('overlap') || hg.variable.includes('correlation'))).filter((w) => !w.view_name.includes('Highlight')).map((k) => k.view_name);
          // console.log('chart Nodes', chartNodes);

          if (_nodes && _nodes.length > 0) {
            _nodes.map((l, li) => {
              if (typeof e.result._defaultFRR[li+1] === 'undefined') {
                e.result._defaultFRR.push(l);
              } else {
                e.result._defaultFRR[li+1] = l // e.result._defaultFRR: ['Fees', 'Yield', 'Assets'];
              }
            });
          }

          const filtr = ['fees', 'risk', 'returns'].filter((j) => !e.result._defaultFRR.includes(j));

          e.result._relevanceChartTabs = [
            ...chartNodes,
            ...filtr,
          ]; // chart odering


          // NOTE: PHASE 2

          let _funds = e.result.funds;
          let _targetCard = e.target_card && e.target_card.funds;

          let _fundReplica = [];
          let _targetCardReplica = [];

          // below logic looks weird but its realted to save 'enhanceTickerFilter' & 'enhanceReplace' state
          if (_enhanceTickerFilter && _enhanceTickerFilter.length > 0) {
            if (_enhanceTickerFilter.includes(e.ticker)) {
              preserveEnhanceTickerFilter.push(e.ticker);
            }
          }

          enhanceTickerFilter.push(e.ticker);

          let _highlights = {
            fee:    e.result.query_var.find((vc) => vc.node === 'cost') ? true    : (e.result.Highlights ? e.result.Highlights.feesHighLightEnable   : e.query.includes('cost')), // e.result.Highlights ? e.result.Highlights.feesHighLightEnable : false,
            return: e.result.query_var.find((vc) => vc.node === 'returns') ? true : (e.result.Highlights ? e.result.Highlights.returnHighLightEnable : e.query.includes('returns')),
            risk:   e.result.query_var.find((vc) => vc.node === 'risk') ? true    : (e.result.Highlights ? e.result.Highlights.riskHighLightEnable   : e.query.includes('risk')), //e.result.Highlights ? e.result.Highlights.returnHighLightEnable : false,
          };

          let riskFromQueryVar = e.result.query_var.find((yuu) => yuu.node === 'risk');
          let returnFromQueryVar = e.result.query_var.find((yuu) => yuu.node === 'returns');
          let _targetCardQuantity;

          let _foundOldPortfolioFund = _oldPortfolioFunds.find((k) => (k.script || k.symbol || k.ticker) === e.ticker)

          if (_targetCard && _targetCard.length > 0) {
            _targetCard.forEach((item, index) => {

              item.yield = item['attribute/fundamental/yield'] || item['yield'];
              item.track_record = item['attribute/track_record'] || item['track_record'];

              if (_foundOldPortfolioFund) {
                item.weight = _foundOldPortfolioFund.weight || null;
                item.monthly_nav = _foundOldPortfolioFund.monthly_nav || null;
                item.quantity = _foundOldPortfolioFund.quantity || _foundOldPortfolioFund.shares || 1;
                item.market_value = (_foundOldPortfolioFund.nav * item.quantity) || null;
                _targetCardQuantity = _foundOldPortfolioFund.quantity || _foundOldPortfolioFund.shares || 1;
              }

              // card info: ticker, price, category, name, Logo
              item.cardInfo = {
                name: item.name || null,
                category: item.category || null,
                desc: item.short_desc || null,
                short_name: item.short_name || null,
                sponsor: item.sponsor || null,
                vehicle: item.vehicle || null,
                ticker: item.ticker || null,
                nav: item.nav || null,
                is_sponsored: item.is_sponsored || false
              };

              // FRR
              let queryVarReturnMultiplier = (returnFromQueryVar && typeof returnFromQueryVar.multiplier !== 'undefined' && returnFromQueryVar.multiplier !== '') ? returnFromQueryVar.multiplier : 1
              let queryVarRiskMultiplier = (riskFromQueryVar && typeof riskFromQueryVar.multiplier !== 'undefined' && riskFromQueryVar.multiplier !== '') ? riskFromQueryVar.multiplier : 1
              item.yRet = (returnFromQueryVar && item[returnFromQueryVar.variable]) ? item[returnFromQueryVar.variable] : (item.Fee_Risk_Return && item.Fee_Risk_Return['Annualized 3 year Average'] ? (item.Fee_Risk_Return['Annualized 3 year Average']/queryVarReturnMultiplier).toFixed(2) : 0);
              item.volt = (riskFromQueryVar && item[riskFromQueryVar.variable]) ? item[riskFromQueryVar.variable] : (item.Fee_Risk_Return && item.Fee_Risk_Return['Annualized 3 year Volatility'] ? (item.Fee_Risk_Return['Annualized 3 year Volatility']/queryVarRiskMultiplier).toFixed(2) : 0);
              item.feesQ = item.feesq || null;
              item.yRetQ = item.Fee_Risk_Return && item.Fee_Risk_Return.returnq || null;
              item.voltQ = item.Fee_Risk_Return && item.Fee_Risk_Return.riskq || null;
              item.queryIcon = '';

              item.FRR = getFeeReturnRisk({
                data: item,
                highlights: _highlights,
                query: e.query,
                defaultFRR: e.result._defaultFRR,
                originalQueryVar: e.result.query_var,
              }) || null;

              item.reportData = item.FRR;

              // chart
              item.odometerChart = getOdometerChart({
                data: item,
                highlights: _highlights,
                ticker: item.ticker || item[`overlap_${e.ticker}`],
                query: e.query,
                chartTabs: e.result._relevanceChartTabs,
                originalQueryVar: e.result.query_var,
              }) || null;

              item.return = {
                returns: item.returns, // NOTE: have to remove this logic
              };

              item._pF = {
                quantity: item.quantity || item.shares || 1,
                _realTimePrice: item.nav,
              };

              // additional required: returns, expense_ratio,
              // _targetCardQuantity = item.quantity || item.shares;

              const requiredFields = {};
              Object.keys(item).map((kl) => {
                if (kl && mappingRequiredKeys.includes(kl) && !skipRequiredKeys.includes(kl)) {
                  requiredFields[kl] = item[kl] || null;
                }
              });

              _targetCardReplica.push({
                ...item.cardInfo,
                ...requiredFields,
                yRet: item.yRet,
                volt: item.volt,
                feesQ: item.feesQ,
                yRetQ: item.yRetQ,
                voltQ: item.voltQ,
                queryIcon: item.queryIcon,
                FRR: item.FRR,
                reportData: item.reportData,
                odometerChart: item.odometerChart,
                quantity: item.quantity || item.shares || 1,
                yield: item['attribute/fundamental/yield'] || item['yield'],
                track_record: item['attribute/track_record'] || item['track_record'],
                return: {
                  returns: item.returns, // NOTE: have to remove this logic
                },
                _pF: {
                  quantity: item.quantity || item.shares || 1,
                  _realTimePrice: item.nav,
                },
                _realTimePrice: item.nav,
              });
            });
          }

          if (_funds.length > 0) {
            _funds.forEach((item, index) => {
              item.yield = item['attribute/fundamental/yield'] || item['yield'];
              item.track_record = item['attribute/track_record'] || item['track_record'];

              if (_foundOldPortfolioFund) {
                item.weight = _foundOldPortfolioFund.weight || null;
                item.monthly_nav = _foundOldPortfolioFund.monthly_nav || null;
                item.quantity = _foundOldPortfolioFund.quantity || _foundOldPortfolioFund.shares || 1;
                item.market_value = (_foundOldPortfolioFund.nav * item.quantity) || null;
              }

              // card info: ticker, price, category, name, Logo
              item.cardInfo = {
                name: item.name || null,
                category: item.category || null,
                desc: item.short_desc || null,
                short_name: item.short_name || null,
                sponsor: item.sponsor || null,
                vehicle: item.vehicle || null,
                ticker: item.ticker || null,
                nav: item.nav || null,
                is_sponsored: item.is_sponsored || false
              };

              // FRR
              // FRR
              let queryVarReturnMultiplier = (returnFromQueryVar && typeof returnFromQueryVar.multiplier !== 'undefined' && returnFromQueryVar.multiplier !== '') ? returnFromQueryVar.multiplier : 1
              let queryVarRiskMultiplier = (riskFromQueryVar && typeof riskFromQueryVar.multiplier !== 'undefined' && riskFromQueryVar.multiplier !== '') ? riskFromQueryVar.multiplier : 1
              item.yRet = (returnFromQueryVar && item[returnFromQueryVar.variable]) ? item[returnFromQueryVar.variable] : (item.Fee_Risk_Return && item.Fee_Risk_Return['Annualized 3 year Average'] ? (item.Fee_Risk_Return['Annualized 3 year Average']/queryVarReturnMultiplier).toFixed(2) : 0);
              item.volt = (riskFromQueryVar && item[riskFromQueryVar.variable]) ? item[riskFromQueryVar.variable] : (item.Fee_Risk_Return && item.Fee_Risk_Return['Annualized 3 year Volatility'] ? (item.Fee_Risk_Return['Annualized 3 year Volatility']/queryVarRiskMultiplier).toFixed(2) : 0);
              item.feesQ = item.feesq || null;
              item.yRetQ = item.Fee_Risk_Return && item.Fee_Risk_Return.returnq || null;
              item.voltQ = item.Fee_Risk_Return && item.Fee_Risk_Return.riskq || null;
              item.queryIcon = '';

              item.FRR = getFeeReturnRisk({
                data: item,
                highlights: _highlights,
                query: e.query,
                defaultFRR: e.result._defaultFRR,
                originalQueryVar: e.result.query_var,
              }) || null;

              item.reportData = item.FRR;

              // chart
              item.odometerChart = getOdometerChart({
                data: item,
                highlights: _highlights,
                ticker: item.ticker || item[`overlap_${e.ticker}`],
                query: e.query,
                chartTabs: e.result._relevanceChartTabs,
                originalQueryVar: e.result.query_var,
              }) || null;

              // additional required keys: returns, expense_ratio,
              const requiredFields = {};

              Object.keys(item).map((kl) => {
                if (kl && mappingRequiredKeys.includes(kl) && !skipRequiredKeys.includes(kl)) {
                  requiredFields[kl] = item[kl] || null;
                }
              });

              if (index === 0) {
                // item._selected = true; // setting checkbox true for 1st fund
                useSuggestedFunds.push({
                  ticker: e.ticker,
                  replace: item.ticker,
                });

                // below logic looks weird but its realted to save 'enhanceTickerFilter' & 'enhanceReplace' state
                if (_enhanceTickerFilter && _enhanceTickerFilter.length > 0) {
                  if (_enhanceTickerFilter.includes(e.ticker)) {
                    preserveSwapTickerData.push({
                      query: e.query,
                      ticker: e.ticker,
                      replaceTicker: item.ticker,
                      replace: item.ticker,
                      selectedPortfolioFunds: {
                        ...item.cardInfo,
                        ...requiredFields,
                        queryIcon: item.queryIcon,
                        FRR: item.FRR,
                        reportData: item.reportData,
                        odometerChart: item.odometerChart,
                        [`overlap_${e.ticker}`]: item[`overlap_${e.ticker}`] || null,
                        [`overlap_${e.ticker}_rank`]: item[`overlap_${e.ticker}_rank`] || null,
                        quantity: _targetCardQuantity,
                        // yield: item['attribute/fundamental/yield'] || item['yield'],
                        // track_record: item['attribute/track_record'] || item['track_record'],
                        return: {
                          returns: item.returns, // NOTE: have to remove this logic
                        },
                        _pF: {
                          quantity: _targetCard[0].quantity || _targetCard[0].shares || _targetCardQuantity || 1,
                          _realTimePrice: _targetCard[0].nav || _targetCard[0].price,
                        },
                        _realTimePrice: _targetCard[0].nav || _targetCard[0].price,
                      }, // NOTE:: need to remove unused keys from this object to only required ones, might be reason for speed issue
                    });
                  }
                }
                swapTickerData.push({
                  query: e.query,
                  ticker: e.ticker,
                  replaceTicker: item.ticker,
                  replace: item.ticker,
                  selectedPortfolioFunds: {
                    ...item.cardInfo,
                    ...requiredFields,
                    queryIcon: item.queryIcon,
                    FRR: item.FRR,
                    reportData: item.reportData,
                    odometerChart: item.odometerChart,
                    [`overlap_${e.ticker}`]: item[`overlap_${e.ticker}`] || null,
                    [`overlap_${e.ticker}_rank`]: item[`overlap_${e.ticker}_rank`] || null,
                    quantity: _targetCardQuantity,
                    // yield: item['attribute/fundamental/yield'] || item['yield'],
                    // track_record: item['attribute/track_record'] || item['track_record'],
                    return: {
                      returns: item.returns, // NOTE: have to remove this logic
                    },
                    _pF: {
                      quantity: _targetCard[0].quantity || _targetCard[0].shares || _targetCardQuantity || 1,
                      _realTimePrice: _targetCard[0].nav || _targetCard[0].price,
                    },
                    _realTimePrice: _targetCard[0].nav || _targetCard[0].price,
                  }, // NOTE:: need to remove unused keys from this object to only required ones, might be reason for speed issue
                });
                usingSuggestedFunds = true; // DO NOT REMOVE THIS !
              } else {
                // item._selected = false;
              }

              _fundReplica.push({
                ...item.cardInfo,
                ...requiredFields,
                [`overlap_${e.ticker}`]: item[`overlap_${e.ticker}`] || null,
                [`overlap_${e.ticker}_rank`]: item[`overlap_${e.ticker}_rank`] || null,
                returns: item.returns,
                yRet: item.yRet,
                volt: item.volt,
                feesQ: item.feesQ,
                yRetQ: item.yRetQ,
                voltQ: item.voltQ,
                queryIcon: item.queryIcon,
                FRR: item.FRR,
                reportData: item.reportData,
                odometerChart: item.odometerChart,
                quantity: item.quantity || 1,
                // yield: item['attribute/fundamental/yield'] || item['yield'],
                // track_record: item['attribute/track_record'] || item['track_record'],
                _selected: item._selected,
                return: {
                  returns: item.returns, // NOTE: have to remove this logic
                },
                _pF: {
                  quantity: _targetCard[0].quantity || _targetCard[0].shares || 1,
                  _realTimePrice: _targetCard[0].nav || _targetCard[0].price,
                },
                _realTimePrice: _targetCard[0].nav || _targetCard[0].price,
              });
            });
            checkboxToggleState = true;
          } else {
            // when 0 funds for Target Card then choose Target Card for PERFORMANCE IMPACT
            console.log(`========== NO FUNDS FOUND FOR ${e.ticker} , USING TARGET CARD FOR PERFORMANCE IMPACT ==========`);
            // console.log(e.result.target_card);
            console.log(_targetCard);
            if (_targetCard && _targetCard.length > 0) {
              // console.log(_targetCard);
              const returns = _targetCard[0].returns.sort(dateSort);
              // returns.forEach((h, i) => {
              //   h.v = h.v/100
              // });
              useSuggestedFunds.push({
                ticker: e.ticker,
                replace: e.ticker,
              });

              // below logic looks weird but its realted to save 'enhanceTickerFilter' & 'enhanceReplace' state
              if (_enhanceTickerFilter && _enhanceTickerFilter.length > 0) {
                if (_enhanceTickerFilter.includes(e.ticker)) {
                  preserveSwapTickerData.push({
                    query: e.query,
                    ticker: e.ticker,
                    replaceTicker: e.ticker,
                    replace: e.ticker,
                    selectedPortfolioFunds: {
                      ..._targetCard[0],
                      yield: _targetCard[0]['attribute/fundamental/yield'] || _targetCard[0]['yield'],
                      track_record: _targetCard[0]['attribute/track_record'] || _targetCard[0]['track_record'],
                      _pF: {
                        quantity: _targetCard[0].quantity || _targetCard[0].shares || 1,
                        _realTimePrice: _targetCard[0].nav || _targetCard[0].price,
                      },
                      _realTimePrice: _targetCard[0].nav || _targetCard[0].price,
                      returns,
                      return: {
                        returns,
                      },
                    }, // NOTE:: need to remove unused keys from this object to only required ones, might be reason for speed issue
                  });
                }
              }
              swapTickerData.push({
                query: e.query,
                ticker: e.ticker,
                replaceTicker: e.ticker,
                replace: e.ticker,
                selectedPortfolioFunds: {
                  ..._targetCard[0],
                  yield: _targetCard[0]['attribute/fundamental/yield'] || _targetCard[0]['yield'],
                  track_record: _targetCard[0]['attribute/track_record'] || _targetCard[0]['track_record'],
                  _pF: {
                    quantity: _targetCard[0].quantity || _targetCard[0].shares || 1,
                    _realTimePrice: _targetCard[0].nav || _targetCard[0].price,
                  },
                  _realTimePrice: _targetCard[0].nav || _targetCard[0].price,
                  returns,
                  return: {
                    returns,
                  },
                }, // NOTE:: need to remove unused keys from this object to only required ones, might be reason for speed issue
              });
              usingSuggestedFunds = true; // DO NOT REMOVE THIS !
            } else {
              console.log(`========== NO TARGET CARD DATA FOUND FOR ${e.ticker} ==========`);
            }
          }

          if (fundsPrice) {
            const updatedFundsPriceData = getFundsUpdatedPrice(fundsPrice, _fundReplica);
            if (updatedFundsPriceData) {
              _fundReplica = updatedFundsPriceData.funds;
              updated_at = updatedFundsPriceData.updated_at;
            }
          }

          // console.log(_targetCardReplica);
          swapTickerData.forEach(swap=>{
            if(swap.ticker===e.ticker){
              _fundReplica.forEach(replace=>{
                if(replace.ticker===swap.replaceTicker){
                  swap.selectedPortfolioFunds.nav = replace.nav
                }
              })
            }
          })

          e.result.target_card = {
            funds: _targetCardReplica,
          };
          // console.log(e.result.target_card);
          e.result.funds = _fundReplica;
          e.pseudo_query = e.query;
          e.query = e.query +" "+e.ticker;
        });

        return {
          data: JSON.parse(JSON.stringify(_responseData)),
          swapTickerData,
          preserveSwapTickerData,
          useSuggestedFunds,
          usingSuggestedFunds,
          // categories,
          checkboxToggleState,
          guidanceMessage,
          updated_at,
          enhanceTickerFilter: orderBy(enhanceTickerFilter, ['asc']),
          preserveEnhanceTickerFilter: orderBy(preserveEnhanceTickerFilter, ['asc']),
        };
      } catch (e) {
        console.log(e);
      }
    } else {
      throw '-- Response structure error: missing `final_response` --'
    }

  } catch (e) {
     console.log(e);
  }
}

export const prepareFRRAndChart = ({ fundsArr, queryVar }) => {
  try {
    // NOTE: fundsArr: [{ ... collections of fund objects (same as batch search api's => results => 10 funds ) }]
    // NOTE: queryVar: query_var of 10 Funds
    if (queryVar && queryVar.length > 0) {
      const _fundsArr = JSON.parse(JSON.stringify(fundsArr));

      let _defaultFRR = ['fees', 'risk', 'returns'];
      const FRR_DATA_ATTRS = getFRRAttrData().map((kj) => kj.attr);

      _fundsArr.map((e) => {
        // Tabs section: 'Tabs' view name => ['Overlap', 'Risk', 'Returns']
        // NOTE: default active View index [1, 2, 3]; whereas 1 for Overlap chart
        // and 2, 3 ... N for remaining 'Risk', 'Return' ... etc.


        e._viewIndex = "1"; // 1 ALWAYS stands for chart

        e._defaultFRR = ['fees', 'risk', 'returns'];
        const excludeNodesForFRR = ['correlation', 'overlap', 'similarity', 'fees', 'risk', 'returns'];
        e.query_var = queryVar.reverse();

        const _nodes = e.query_var.filter((s) => (s.view_name !== '' && s.node !== "" && !excludeNodesForFRR.includes(s.node)))
                                         // .filter((l) => FRR_DATA_ATTRS.includes(l.node))
                                         .filter((w) => !w.view_name.includes('Highlight')).slice(0, 2)
                                         .map((k) => k.node);
        // console.log('_nodes', _nodes); // _nodes: ['Yield', 'Assets'];
        const chartNodes = e.query_var.filter(hg => (hg.variable.includes('overlap') || hg.variable.includes('correlation'))).filter((w) => !w.view_name.includes('Highlight')).map((k) => k.view_name);
        // console.log('chart Nodes', chartNodes);

        if (_nodes && _nodes.length > 0) {
          _nodes.map((l, li) => {
            if (typeof e._defaultFRR[li+1] === 'undefined') {
              e._defaultFRR.push(l);
            } else {
              e._defaultFRR[li+1] = l // e._defaultFRR: ['Fees', 'Yield', 'Assets'];
            }
          });
        }

        const filtr = ['fees', 'risk', 'returns'].filter((j) => !e._defaultFRR.includes(j));
        // console.log(filtr);

        e._relevanceChartTabs = [
          ...chartNodes,
          ...filtr,
        ];
      })

      return _fundsArr;

    } else {
      throw '-- Missing params --'
    }
  } catch (e) {
    console.log(e);
  }
}

export const prepareFundObject = ({ fundsArr, queryVar, query, ticker, targetCard = {} }) => {
  // NOTE: fundsArr: [{ ... collections of fund objects (same as batch search api's => results => 10 funds ) }]
  // NOTE: queryVar: query_var of 10 Funds
  // NOTE: query: query searched for 10 funds
  // NOTE: ticker: (target card ticker)

  try {
    if (fundsArr && fundsArr.length > 0 && typeof fundsArr !== 'undefined') {
      let _fundsArr = prepareFRRAndChart({ fundsArr, queryVar });
      _fundsArr = JSON.parse(JSON.stringify(_fundsArr));

      let TARGET_CARD_DATA =  targetCard && targetCard.funds && targetCard.funds.length > 0 && targetCard.funds[0] || {};
      // console.log('TARGET_CARD_DATA', TARGET_CARD_DATA);

      try {
        let _fundReplica = [], swapTickerData = [], odometerChartData = {};
        let _highlights = {
          fee:    queryVar.find((vc) => vc.node === 'cost') ? true    : query.includes('cost'),
          return: queryVar.find((vc) => vc.node === 'returns') ? true : query.includes('return'),
          risk:   queryVar.find((vc) => vc.node === 'risk') ? true    : query.includes('risk'),
        };

        let riskFromQueryVar = queryVar.find((yuu) => yuu.node === 'risk');
        let returnFromQueryVar = queryVar.find((yuu) => yuu.node === 'returns');

        _fundsArr.forEach((item, index) => {
          // card info: ticker, price, category, name, Logo
          item.yield = item['attribute/fundamental/yield'] || item['yield'];
          item.track_record = item['attribute/track_record'] || item['track_record'];

          item.cardInfo = {
            name: item.name || null,
            category: item.category || null,
            desc: item.short_desc || null,
            short_name: item.short_name || null,
            sponsor: item.sponsor || null,
            vehicle: item.vehicle || null,
            ticker: item.ticker || null,
            nav: item.nav || null,
            is_sponsored: item.is_sponsored || false
          };

          // FRR
          let queryVarReturnMultiplier = (returnFromQueryVar && typeof returnFromQueryVar.multiplier !== 'undefined' && returnFromQueryVar.multiplier !== '') ? returnFromQueryVar.multiplier : 1
          let queryVarRiskMultiplier = (riskFromQueryVar && typeof riskFromQueryVar.multiplier !== 'undefined' && riskFromQueryVar.multiplier !== '') ? riskFromQueryVar.multiplier : 1
          item.yRet = (returnFromQueryVar && item[returnFromQueryVar.variable]) ? item[returnFromQueryVar.variable] : (item.Fee_Risk_Return && item.Fee_Risk_Return['Annualized 3 year Average'] ? (item.Fee_Risk_Return['Annualized 3 year Average']/queryVarReturnMultiplier).toFixed(2) : 0);
          item.volt = (riskFromQueryVar && item[riskFromQueryVar.variable]) ? item[riskFromQueryVar.variable] : (item.Fee_Risk_Return && item.Fee_Risk_Return['Annualized 3 year Volatility'] ? (item.Fee_Risk_Return['Annualized 3 year Volatility']/queryVarRiskMultiplier).toFixed(2) : 0);
          item.feesQ = item.feesq || null;
          item.yRetQ = item.Fee_Risk_Return && item.Fee_Risk_Return.returnq || null;
          item.voltQ = item.Fee_Risk_Return && item.Fee_Risk_Return.riskq || null;
          item.queryIcon = '';

          // for 'correlation_TICKER'
          if (queryVar && queryVar.find(vc => vc.node === 'correlation' || (vc.variable && vc.variable.includes('correlation')))) {
            item[`correlation_${ticker}`] = item[`correlation_${ticker}`] || ((item['chart'] && typeof item['chart'] !== 'undefined' && typeof item['chart']['value'] !== 'undefined') ? item['chart']['value'] : null) || null;
            odometerChartData = {
              [`correlation_${ticker}`]: item[`correlation_${ticker}`],
            };
          } else {
            // bydefault show 'Overlap' chart
            item[`overlap_${ticker}`] = item[`overlap_${ticker}`] || ((item['chart'] && typeof item['chart'] !== 'undefined' && typeof item['chart']['value'] !== 'undefined') ? item['chart']['value'] : null) || null;
            // item[`overlap_${ticker}_rank`] = item[`overlap_${ticker}_rank`] || null;
            odometerChartData = {
              [`overlap_${ticker}`]: item[`overlap_${ticker}`],
              [`overlap_${ticker}_rank`]: item[`overlap_${ticker}_rank`] || null,
            }
          }

          if (item._defaultFRR && typeof item._defaultFRR !== 'undefined') {
            item.FRR = getFeeReturnRisk({
              data: item,
              highlights: _highlights,
              query: query,
              defaultFRR: item._defaultFRR,
              originalQueryVar: queryVar,
            }) || null;
          }

          item.reportData = item.FRR;

          // chart
          if (item._relevanceChartTabs && typeof item._relevanceChartTabs !== 'undefined') {
            item.odometerChart = getOdometerChart({
              data: item,
              highlights: _highlights,
              ticker: item.ticker || item[`overlap_${ticker}`] || item[`correlation_${ticker}`],
              query: query,
              chartTabs: item._relevanceChartTabs,
              originalQueryVar: queryVar,
            }) || null;
          }

          // additional required keys: returns, expense_ratio,
          const requiredFields = {};
          Object.keys(item).map((kl) => {
            if (kl && mappingRequiredKeys.includes(kl) && !skipRequiredKeys.includes(kl)) {
              requiredFields[kl] = item[kl] || null;
            }
          });

          if (index === 0) {
            item._selected = true; // setting checkbox true for 1st fund
            swapTickerData.push({
              query: query,
              ticker: ticker,
              replaceTicker: item.ticker,
              replace: item.ticker,
              selectedPortfolioFunds: {
                ...item.cardInfo,
                ...requiredFields,
                queryIcon: item.queryIcon,
                FRR: item.FRR,
                reportData: item.reportData,
                odometerChart: item.odometerChart,
                quantity: item.quantity || 1,
                // yield: item[0]['attribute/fundamental/yield'] || item[0]['yield'],
                // track_record: item[0]['attribute/track_record'] || item[0]['track_record'],
                return: {
                  returns: item.returns, // NOTE: have to remove this logic
                },
                weight: item.weight || (TARGET_CARD_DATA && typeof TARGET_CARD_DATA.weight !== 'undefined') ? TARGET_CARD_DATA.weight : null,
                _pF: (TARGET_CARD_DATA && typeof TARGET_CARD_DATA._pF !== 'undefined') ? TARGET_CARD_DATA._pF : {},
              },
            });
          } else {
            item._selected = false;
          }

          _fundReplica.push({
            ...item.cardInfo,
            ...requiredFields,
            ...odometerChartData, // it can either be 'correlation' or 'overlap'
            returns: item.returns,
            yRet: item.yRet,
            volt: item.volt,
            feesQ: item.feesQ,
            yRetQ: item.yRetQ,
            voltQ: item.voltQ,
            queryIcon: item.queryIcon,
            FRR: item.FRR || [],
            reportData: item.reportData,
            odometerChart: item.odometerChart || [],
            // yield: item[0]['attribute/fundamental/yield'] || item[0]['yield'],
            // track_record: item[0]['attribute/track_record'] || item[0]['track_record'],
            _selected: item._selected,
            return: {
              returns: item.returns, // NOTE: have to remove this logic
            },
            weight: item.weight || (TARGET_CARD_DATA && typeof TARGET_CARD_DATA.weight !== 'undefined') ? TARGET_CARD_DATA.weight : null,
            _pF: (TARGET_CARD_DATA && typeof TARGET_CARD_DATA._pF !== 'undefined') ? TARGET_CARD_DATA._pF : {},
          });
        });

        // console.log(_fundReplica);

        return {
          data: JSON.parse(JSON.stringify(_fundReplica)),
          enhanceReplace: swapTickerData,
        };
      } catch (e) {
        console.log(e);
      }
    } else {
      throw "--- fundsArr is missing for preparing fund's object ---"
    }
  } catch (e) {
    console.log(e);
  }
}

export const getFeeReturnRisk = (params) => {
  try {
    const { data, highlights, query, defaultFRR, originalQueryVar } = params;
    if (typeof data !== 'undefined' &&
      typeof highlights !== 'undefined' &&
      typeof query !== 'undefined' &&
      typeof defaultFRR !== 'undefined' &&
      typeof originalQueryVar !== 'undefined'
    ) {
      let _highlights = {
        feesHighLightEnable: highlights.fee,
        returnHighLightEnable: highlights.return,
        riskHighLightEnable: highlights.risk,
      };

      const FRR_DATA_ATTRS = getFRRAttrData(query, _highlights); // static UI data set
      let FRR_ATTRS = FRR_DATA_ATTRS.filter((item) => defaultFRR.includes(item.attr));

      if (FRR_ATTRS.length !== defaultFRR.length) {
        // console.log('some NODE is missing ===>', defaultFRR);
        const EXCLUDED_NODE_WAS = [];
        // console.log('originalQueryVar--->', originalQueryVar);
        const renderingFRRs = FRR_ATTRS.map((kgf) => kgf.attr)
        // console.log('renderingFRRs ===> ', renderingFRRs);
        const NEW_NODE = defaultFRR.filter((jh) => !renderingFRRs.includes(jh));
        // console.log('renderingFRRs not included this =>', NEW_NODE);

        NEW_NODE.map((kkl) => {
          if (kkl) {
            const foundInQueryVar = originalQueryVar.find((vc) => vc.node === kkl);
            if (foundInQueryVar) {
              let letViewNameWillBe = foundInQueryVar.view_name !== '' ? (foundInQueryVar['Name of the chart on UI'] !== '' ? textCapitalize(foundInQueryVar['Name of the chart on UI']) : textCapitalize(foundInQueryVar.view_name)) : textCapitalize(foundInQueryVar.view_name);
              // NOTE: for outperform query we get 'Performance vs Ticker' in ['Name of the chart on UI']
              if (letViewNameWillBe && letViewNameWillBe.includes('ticker')) {
                let D_DATA = foundInQueryVar.data;
                if (D_DATA && Object.prototype.toString.call(D_DATA) === '[object Array]') {
                  letViewNameWillBe = letViewNameWillBe.replace("ticker", D_DATA[0]);
                }
                else if (typeof D_DATA === 'string') {
                  letViewNameWillBe = letViewNameWillBe.replace("ticker", D_DATA);
                }
              }
              EXCLUDED_NODE_WAS.push({
                reverse: true,
                multiplier: foundInQueryVar.multiplier !== '' ? foundInQueryVar.multiplier : 1,
                attr: foundInQueryVar.node,
                name: letViewNameWillBe,
                qcol: foundInQueryVar.variable,
                col: foundInQueryVar.variable,
                img: "Assets", // common image for all unknown node
                suffix: foundInQueryVar.suffix,
                decimal: foundInQueryVar.decimal,
                highlightClass: 'search-risk-stats-highlighting',
              });
            }
          }
        });

        FRR_ATTRS = [...FRR_ATTRS, ...EXCLUDED_NODE_WAS].slice(0, 3); // limit 3
      }

      // for 'higher returns' query, when query_var has 2 duplicate 'nodes' with diff VIEW_NAME
      // console.log(originalQueryVar);
      FRR_ATTRS.map((jt) => {
        const df = originalQueryVar.find((dc) => dc.node === jt.attr)
        if (df) {
          let letViewNameWillBe = df.view_name !== '' ? (df['Name of the chart on UI'] !== '' ? textCapitalize(df['Name of the chart on UI']) : textCapitalize(df.view_name)) : textCapitalize(jt.name);
          // NOTE: for outperform query we get 'Performance vs Ticker' in ['Name of the chart on UI']
          if (letViewNameWillBe && letViewNameWillBe.includes('ticker')) {
            let D_DATA = df.data;
            if (D_DATA && Object.prototype.toString.call(D_DATA) === '[object Array]') {
              letViewNameWillBe = letViewNameWillBe.replace("ticker", D_DATA[0]);
            }
            else if (typeof D_DATA === 'string') {
              letViewNameWillBe = letViewNameWillBe.replace("ticker", D_DATA);
            }
          }
          jt.name = letViewNameWillBe;
          jt.decimal = df.decimal;
          jt.multiplier = df.multiplier !== '' ? df.multiplier : 1;
        }
      });

      return FRR_ATTRS.map(attr => {
        const qs = quintileSize(data[attr.qcol], attr.reverse);
        const _val = (typeof data[attr.col] !== 'undefined' && data[attr.col] !== null && data[attr.col] !== '') ?
        ((attr.decimal && attr.decimal !== '' ? (
            data[attr.col] * attr.multiplier).toFixed(attr.decimal)
            : Math.round((data[attr.col] * attr.multiplier)) ) + (attr.suffix)
          ) : '--';

        return {
          ...attr,
          value: _val,
          attr: attr.attr,
          name: attr.name,
          icon: attr.icon ? `${attr.icon}-${qs}` : null,
          iconImg: attr.img ? attr.img : null,
          highlightClass: attr.highlightClass,
          quintileValue: data[attr.qcol],
          label: quintileText(qs),
        };
      });
    } else {
      throw 'missing expected params'
    }
  } catch (e) {
    console.log(e);
  }
}

export const getOdometerChart = (params) => {
  try {
    // console.log(params);
    const { data, ticker, query, chartTabs, originalQueryVar, highlights, forModel } = params;

    if (typeof data !== 'undefined' &&
      typeof ticker !== 'undefined' &&
      typeof query !== 'undefined' &&
      typeof chartTabs !== 'undefined' &&
      typeof originalQueryVar !== 'undefined' &&
      typeof highlights !== 'undefined'
    ) {

      let _highlights = {
        feesHighLightEnable: highlights.fee,
        returnHighLightEnable: highlights.return,
        riskHighLightEnable: highlights.risk,
      };

      const FRR_DATA_ATTRS = (typeof forModel !== 'undefined' && forModel) ? getFRRAttrDataForModels(query, _highlights) : getFRRAttrData(query, _highlights);
      let FRR_ATTRS = FRR_DATA_ATTRS.filter((item) => chartTabs.includes(item.attr));
      // console.log(FRR_ATTRS);

      if (FRR_ATTRS.length !== chartTabs.length) {
        const EXCLUDED_NODE_WAS = [];
        const renderingFRRs = FRR_ATTRS.map((kgf) => kgf.attr);
        const NEW_NODE = chartTabs.filter((jh) => !renderingFRRs.includes(jh));

        NEW_NODE.map((kkl) => {
          if (kkl) {
            const foundInQueryVar = originalQueryVar.find((vc) => vc.view_name === kkl);
            if (foundInQueryVar) {
              EXCLUDED_NODE_WAS.push({
                reverse: true,
                multiplier: foundInQueryVar.multiplier !== '' ? foundInQueryVar.multiplier : 1,
                attr: foundInQueryVar.node,
                name: foundInQueryVar.view_name !== '' ? (foundInQueryVar['Name of the chart on UI'] !== '' ? textCapitalize(foundInQueryVar['Name of the chart on UI']) : textCapitalize(foundInQueryVar.view_name)) : textCapitalize(foundInQueryVar.view_name),
                qcol: foundInQueryVar.variable,
                col: foundInQueryVar.variable,
                suffix: foundInQueryVar.suffix,
                decimal: foundInQueryVar.decimal,
                highlightClass: 'search-risk-stats-highlighting',
                img: "Assets", // common image for all unknown node
              });
            }
          }
        });

        FRR_ATTRS = [...FRR_ATTRS, ...EXCLUDED_NODE_WAS].slice(0, 3); // limit 3
      }

      FRR_ATTRS.map((jt) => {
        const df = originalQueryVar.find((dc) => dc.node === jt.attr)
        if (df) {
          jt.decimal = df.decimal;
          jt.multiplier = df.multiplier !== '' ? df.multiplier : 1;
        }
      });

      const elems = FRR_ATTRS.map(attr => {
        const qs = quintileSize(data[attr.qcol], attr.reverse);
        const _val = (typeof data[attr.col] !== 'undefined' && data[attr.col] !== null) ?
        ((attr.decimal && attr.decimal !== '' ? (
            data[attr.col] * attr.multiplier).toFixed(attr.decimal)
            : Math.round((data[attr.col] * attr.multiplier)) )
          ) : '--';
        // console.log('-- _val ==> ', _val, convertToFloatvalues(_val));
        const foundInQueryVar = originalQueryVar.find((vc) => vc.node === attr.attr);

        return {
          view_name: attr.name,
          ...foundInQueryVar,
          value: convertToFloatvalues(_val) || 0,
          attr: attr.attr,
          name: attr.name,
          icon: attr.icon ? `${attr.icon}-${qs}` : null,
          iconImg: attr.img ? attr.img : null,
          highlightClass: attr.highlightClass,
          quintileValue: data[attr.qcol],
          label: quintileText(qs),
          _viewChartType: 'dynamicGaugeChart',
          chart_type: "odometer",
          data: ticker,
          _viewData : {
            value: convertToFloatvalues(_val) || 0,
            min: attr.min ? parseInt(attr.min) : 0,
            max: attr.max ? parseInt(attr.max) : 100,
            decimal: attr.decimal,
            suffix: attr.suffix,
            multiplier: attr.multiplier,
          }
        };
      });

      return elems.reverse();
    } else {
      throw 'missing expected params'
    }
  } catch (e) {
    console.log(e);
  }
}

export const calCulateSeriesPoints = (currentPf) => {
  if (currentPf && currentPf.length > 0) {
    let out = [];

    currentPf.map((e, index) => {
      const returnsArr = e.returns;
      if (returnsArr.length > 0) {
        const start = moment(new Date(`01 ${returnsArr[returnsArr.length - 1].d}`));
        const end = moment(new Date(`01 ${returnsArr[0].d}`));
        const DateRangeMoment = { start, end };

        const calculatePoints = (ret, year) => {
          let returns = JSON.parse(JSON.stringify(ret))
          const range = {
            start: nameToDateRange(year, DateRangeMoment).start._d,
            end: nameToDateRange(year, DateRangeMoment).end._d,
          }
          return returns.filter((e) => {
            let dt = monthToDate(e.d);
            return dt.isAfter(moment(range.start).subtract(1, 'seconds')) && dt.isBefore(moment(range.end).add(1, 'seconds'));
          });
        }

        let newSeriesCollections = {
          points1y: calculatePoints(returnsArr, 'l1y'),
          points3y: calculatePoints(returnsArr, 'l3y'),
          points5y: calculatePoints(returnsArr, 'l5y'),
          points10y: calculatePoints(returnsArr, 'l10y'),
        };

        newSeriesCollections.points1y.forEach((el, index1) => {
          el.v = el.v * 100; //multiplied by 100 to negate the divide by 100 for charts
        });

        newSeriesCollections.points3y.forEach((el, index1) => {
          el.v = el.v * 100;
        });

        newSeriesCollections.points5y.forEach((el, index1) => {
          el.v = el.v * 100;
        });

        newSeriesCollections.points10y.forEach((el, index1) => {
          el.v = el.v * 100;
        });

        out.push({
          [`${e.ticker}`]: newSeriesCollections,
        })

      }
    })

    return out;
  } else {
    console.log('--- no data ---');
    return [];
  }
}

export async function replicaStatsForPerformanceImpact({
  portfolioEnhanceReplace,
  portfolioFunds,
  portfolioQuery,
  portfolioBenchmark
}) {

  try {
    if (typeof portfolioEnhanceReplace === 'undefined' ||
    typeof portfolioFunds === 'undefined' ||
    typeof portfolioQuery === 'undefined' ||
    typeof portfolioBenchmark === 'undefined') {
      return {}
    }

    let _portfolioEnhanceReplace = JSON.parse(JSON.stringify(portfolioEnhanceReplace));

    if (_portfolioEnhanceReplace) {
	    let updatedTickers = []
      let enhanceUpdatedArray = _portfolioEnhanceReplace.map((item) => {
        if (item.selectedPortfolioFunds) {
          updatedTickers.push(item.ticker);
          return {
            ...item.selectedPortfolioFunds,
            return: {
              returns: item.selectedPortfolioFunds.returns, // NOTE: have to remove this logic
            },
          };
        }
      });

      let currentUpdatedArray = portfolioFunds.map((item) => {
        if (item && item.target_card && item.target_card.funds && item.target_card.funds.length > 0) {
          return {
            ...item.target_card.funds[0],
            quantity: item.target_card.funds[0].quantity || 1,
            _realTimePrice: item.target_card.funds[0].nav,
            return: {
              returns: item.target_card.funds[0].returns, // NOTE: have to remove this logic
            },
          };
        }
      })

      const currentSeries = getCurrentPfStats(currentUpdatedArray, portfolioQuery, portfolioBenchmark) //calCulateSeriesPoints(currentUpdatedArray);
      const enhancedSeries = getCalculatedEnhanceStats(enhanceUpdatedArray, portfolioQuery, portfolioBenchmark)//calCulateSeriesPoints(enhanceUpdatedArray);

      return {
        currentPerformanceImpact: currentSeries, // out.currentSeries,
        enhancedPerformanceImpact: enhancedSeries, // out.enhancedSeries,
        portfolioEnhanceReplace: _portfolioEnhanceReplace,
      };
    }

  } catch (e) {
    console.log(e);
  }

}

export async function statsForPerformanceImpact({
  portfolioEnhanceReplace,
  watchlistFundstats,
  accountFundstats,
  portfolioFunds,
  marketFunds,
  portfolioQuery,
  enhancePortfolioVisible,
  view,
  portfolioBenchmark
}) {

  try {
    if (typeof portfolioEnhanceReplace === 'undefined' ||
    typeof watchlistFundstats === 'undefined' ||
    typeof accountFundstats === 'undefined' ||
    typeof portfolioFunds === 'undefined' ||
    typeof marketFunds === 'undefined' ||
    typeof portfolioQuery === 'undefined' ||
    typeof enhancePortfolioVisible === 'undefined' ||
    typeof view === 'undefined') {
      return {}
    }

    let _portfolioEnhanceReplace = JSON.parse(JSON.stringify(portfolioEnhanceReplace));

    let currentStats = {}, header2Disable = false;
    let enhanceTickers = [], currentTicker = [];

    if (view == 'account') {
      if (_portfolioEnhanceReplace.length !== accountFundstats.funds.length && _portfolioEnhanceReplace.length < accountFundstats.funds.length) {
        console.log('---------- USE ACCOUNT CURRENT => BATCH SEARCH RESPONSE ----------');
        const currentAccountFunds = JSON.parse(JSON.stringify(accountFundstats.funds));
        currentAccountFunds.forEach((item, i) => {
          const currentTick = item.script || item.symbol || item.ticker;
          const isTickerNotInPortfolioEnhanceReplace = _portfolioEnhanceReplace.find((g) => g.ticker === currentTick);
          if (typeof isTickerNotInPortfolioEnhanceReplace === 'undefined') {
            const returns = item.returns.sort(dateSort);
            returns.forEach((h, i) => {
              h.v = h.v/100
            });
            _portfolioEnhanceReplace.push({
              query: '',
              ticker: currentTick,
              replace: currentTick,
              replaceTicker: currentTick,
              selectedPortfolioFunds: {
                ...item,
                query_var: [], // for showing correlation chart
                [`overlap_${item.ticker}`] : item[`overlap_${item.ticker}`],
                'Fee_Risk_Return': item.Fee_Risk_Return,
                category: item.category,
                ticker: item.ticker,
                dividend_yield: item.dividend_yield,
                fees: item.fees,
                feesq: item.feesq,
                name: item.name,
                nav: item.nav,
                returns: returns,
                short_desc: item.short_desc,
                short_name: item.short_name,
                sponsor: item.sponsor,
                vehicle: item.vehicle,
                asset_alloc: item.asset_alloc,
                weight_yield: item.weight_yield,
                weight_track_record: item.weight_track_record,
                'attribute/fundamental/yield': item['attribute/fundamental/yield'] || item['yield'],
                outperform : item['outperform'] || 0.0,
                'attribute/track_record': item['attribute/track_record'] || item['track_record'],
                ticker: currentTick,
              },
            });
          }
        });
      }
    } else if (view == 'watchlist') {
      if (_portfolioEnhanceReplace.length !== watchlistFundstats.positions.length && _portfolioEnhanceReplace.length < watchlistFundstats.positions.length) {
        console.log('---------- USE WATCHLIST CURRENT => BATCH SEARCH RESPONSE ----------');
        const currentWatchlistFunds = JSON.parse(JSON.stringify(watchlistFundstats.positions));
        currentWatchlistFunds.forEach((item, i) => {
          const currentTick = item.script || item.symbol || item.ticker;
          const isTickerNotInPortfolioEnhanceReplace = _portfolioEnhanceReplace.find((g) => g.ticker === currentTick);
          if (typeof isTickerNotInPortfolioEnhanceReplace === 'undefined') {
            const returns = item.returns.sort(dateSort);
            returns.forEach((h, i) => {
              h.v = h.v/100
            });
            _portfolioEnhanceReplace.push({
              query: '',
              ticker: currentTick,
              replace: currentTick,
              replaceTicker: currentTick,
              selectedPortfolioFunds: {
                ...item,
                query_var: [], // for showing correlation chart
                [`overlap_${item.ticker}`] : item[`overlap_${item.ticker}`],
                'Fee_Risk_Return': item.Fee_Risk_Return,
                category: item.category,
                ticker: item.ticker,
                dividend_yield: item.dividend_yield,
                fees: item.fees,
                feesq: item.feesq,
                name: item.name,
                nav: item.nav,
                returns: returns,
                short_desc: item.short_desc,
                short_name: item.short_name,
                sponsor: item.sponsor,
                vehicle: item.vehicle,
                asset_alloc: item.asset_alloc,
                weight_yield: item.weight_yield,
                weight_track_record: item.weight_track_record,
                'attribute/fundamental/yield': item['attribute/fundamental/yield'] || item['yield'],
                outperform : item['outperform'] || 0.0,
                'attribute/track_record': item['attribute/track_record'] || item['track_record'],
                ticker: currentTick,
              },
            });
          }
        });
      }
    } else if (view == 'mrktPl') {
      if (_portfolioEnhanceReplace.length !== marketFunds.length && _portfolioEnhanceReplace.length < marketFunds.length) {
        console.log('---------- USE MARKETFUNDS CURRENT => BATCH SEARCH RESPONSE ----------');
        const currentMarketFunds = JSON.parse(JSON.stringify(marketFunds));
        currentMarketFunds.forEach((item, i) => {
          const currentTick = item.script || item.symbol || item.ticker;
          const isTickerNotInPortfolioEnhanceReplace = _portfolioEnhanceReplace.find((g) => g.ticker === currentTick);
          if (typeof isTickerNotInPortfolioEnhanceReplace === 'undefined') {
            const returns = item.returns.sort(dateSort);
            returns.forEach((h, i) => {
              h.v = h.v/100
            });
            _portfolioEnhanceReplace.push({
              query: '',
              ticker: currentTick,
              replace: currentTick,
              replaceTicker: currentTick,
              selectedPortfolioFunds: {
                ...item,
                query_var: [], // for showing correlation chart
                [`overlap_${item.ticker}`] : item[`overlap_${item.ticker}`],
                'Fee_Risk_Return': item.Fee_Risk_Return,
                category: item.category,
                ticker: item.ticker,
                dividend_yield: item.dividend_yield,
                fees: item.fees,
                feesq: item.feesq,
                name: item.name,
                nav: item._realTimePrice || item.nav,
                returns: returns,
                short_desc: item.short_desc,
                short_name: item.short_name,
                sponsor: item.sponsor,
                vehicle: item.vehicle,
                asset_alloc: item.asset_alloc,
                weight_yield: item.weight_yield,
                weight_track_record: item.weight_track_record,
                'attribute/fundamental/yield': item['attribute/fundamental/yield'] || item['yield'],
                outperform : item['outperform'] || 0.0,
                'attribute/track_record': item['attribute/track_record'] || item['track_record'],
                ticker: currentTick,
              },
            });
          }
        });
      }
    } else {
      // console.log('_portfolioEnhanceReplace.length', _portfolioEnhanceReplace.length, 'portfolioFunds', portfolioFunds.length);
      if (_portfolioEnhanceReplace.length !== portfolioFunds.length && _portfolioEnhanceReplace.length < portfolioFunds.length) {
        console.log('---------- USE PORTFOLIO CURRENT => BATCH SEARCH RESPONSE ----------');
        const currentPortfolioFunds = JSON.parse(JSON.stringify(portfolioFunds));
        currentPortfolioFunds.forEach((item, i) => {
          if (item.type !== 'SHARE') {
            const currentTick = item.script || item.symbol || item.ticker;
            const isTickerNotInPortfolioEnhanceReplace = _portfolioEnhanceReplace.find((g) => g.ticker === currentTick);
            if (typeof isTickerNotInPortfolioEnhanceReplace === 'undefined') {
              const returns = item.returns.sort(dateSort);
              returns.forEach((h, i) => {
                h.v = h.v/100
              });
              _portfolioEnhanceReplace.push({
                query: '',
                ticker: currentTick,
                replace: currentTick,
                replaceTicker: currentTick,
                selectedPortfolioFunds: {
                  ...item,
                  query_var: [], // for showing correlation chart
                  [`overlap_${item.ticker}`] : item[`overlap_${item.ticker}`],
                  'Fee_Risk_Return': item.Fee_Risk_Return,
                  category: item.category,
                  ticker: item.ticker,
                  dividend_yield: item.dividend_yield,
                  fees: item.fees,
                  feesq: item.feesq,
                  name: item.name,
                  nav: item.nav,
                  returns: returns,
                  short_desc: item.short_desc,
                  short_name: item.short_name,
                  sponsor: item.sponsor,
                  vehicle: item.vehicle,
                  asset_alloc: item.asset_alloc,
                  weight_yield: item.weight_yield,
                  weight_track_record: item.weight_track_record,
                  'attribute/fundamental/yield': item['attribute/fundamental/yield'] || item['yield'],
                  outperform : item['outperform'] || 0.0,
                  'attribute/track_record': item['attribute/track_record'] || item['track_record'],
                  ticker: currentTick,
                },
              });
            }
          }
        });
      }
    }

    if (_portfolioEnhanceReplace && !enhancePortfolioVisible) {
	    let updatedTickers = []
      let updatedArray = _portfolioEnhanceReplace.map((item) => {

	      updatedTickers.push(item.ticker)
	      const returns = item.selectedPortfolioFunds.returns.sort(dateSort);
        let pF = {}

        if (view == 'account') {
          if (accountFundstats.tickers.length <= 0) {
            header2Disable = true;
          } else header2Disable = false;
          // currentStats = getCurrentPfStats(accountFundstats.funds, portfolioQuery);
          pF = accountFundstats.funds.filter((dd) => (dd.script || dd.symbol) === item.ticker)[0];
        } else if (view == 'watchlist') {
          if (watchlistFundstats.tickers.length <= 0) {
            header2Disable = true;
          } else header2Disable = false;
          // currentStats = getCurrentPfStats(watchlistFundstats.positions, portfolioQuery);
          pF = watchlistFundstats.positions.filter((dd) => (dd.script || dd.symbol) === item.ticker)[0];
        } else if (view == 'mrktPl') {
          header2Disable = false;
          // currentStats = getCurrentPfStats(marketFunds, portfolioQuery);
          pF = marketFunds.filter((dd) => (dd.script || dd.symbol) === item.ticker)[0];
        } else {
          header2Disable = false;
          // currentStats = getCurrentPfStats(portfolioFunds, portfolioQuery);
          pF = portfolioFunds.filter((dd) => ((dd.script || dd.symbol) === item.ticker) && dd.type !== 'SHARE')[0];
        }

        const fundData = {
          ...item.selectedPortfolioFunds,
          _pF: pF,
          return: {
            returns: returns.reverse(), // intentionally did it!!
          },
        };

        item.selectedPortfolioFunds = fundData;

        return fundData;
      });

	    const [enhanceStats] = await Promise.all([
        getCalculatedEnhanceStats(updatedArray, portfolioQuery,portfolioBenchmark),
      ]);

      return {
        // currentPerformanceImpact: currentStats,
        enhancedPerformanceImpact: enhanceStats,
        header2Disable,
        portfolioEnhanceReplace: _portfolioEnhanceReplace,
      };
    }

  } catch (e) {
    console.log(e);
  }

}

export const getDefaultEnhanceReplaceData = ({
  resultSet,
  toggleState,
}) => {

  try {
    if (typeof resultSet === 'undefined' || typeof toggleState === 'undefined') {
      return {
        enhanceReplace: [],
        _resultSet: resultSet,
      }
    }

    let _resultSet = resultSet.filter(x => x.result.funds);
    let swapTickerData = [], enhanceTickerFilter = [];

    if (_resultSet && _resultSet.length > 0) {
      _resultSet.forEach((e, index) => {
        let _funds = e.result.funds;
        enhanceTickerFilter.push(e.ticker);
        if (_funds.length > 0) {
          _funds.forEach((item, index) => {
            if (index === 0 && toggleState) {
              item._selected = true;
              const selectedFunds = {
                category: item.category,
                ticker: item.ticker,
                dividend_yield: item.dividend_yield,
                fees: item.fees,
                feesq: item.feesq,
                name: item.name,
                nav: item.nav,
                returns: item.returns,
                short_desc: item.short_desc,
                short_name: item.short_name,
                sponsor: item.sponsor,
                vehicle: item.vehicle,
                'attribute/fundamental/yield': item['attribute/fundamental/yield'] || item['yield'],
                outperform : item['outperform'] || 0.0,
                'attribute/track_record': item['attribute/track_record'] || item['track_record'],
              };
              swapTickerData.push({
                query: e.query,
                ticker: e.ticker,
                replaceTicker: item.ticker,
                replace: item.ticker,
                selectedPortfolioFunds: selectedFunds, // NOTE:: need to remove unused keys from this object to only required ones, might be reason for speed issue
              })
            } else {
              item._selected = false;
            }
          });
        }
      });

      console.log(swapTickerData);

      return {
        enhanceReplace: swapTickerData,
        _resultSet,
      };
    }
  } catch (e) {
    console.log(e);
  }
}

export const prepareHRSDataForFund = ({ holdings, card }) => {
  try {
    // card is Fund from fund list.
    if (holdings && card) {
      let _sum = 0.0;
      let sectorData = [], holdingData = [], regionData = [];
      holdingData = getNestDefault(['holdings'], holdings, []).map(
        (e, i) => {
          _sum += e.weight
          return {
            name: e.name,
            value: e.weight,
            color: SectorColors[i % SectorColors.length]
          }
        }
      )
      if (holdingData.length > 0 && _sum < 100)
        holdingData.push({ name: 'Others', value: 100 - _sum, color: '#ddd' })

      regionData = getNestDefault([CN['Region']], card, [])
        .reduce((acc, e) => acc.concat(e.sub), [])
        .map(e => ({ name: e.n, value: e.v, color: RegionColors[e.n] }))

      SectorsAttrs.forEach((e, i) => {
        let v = cn(card, e)
        if (v !== undefined && v > 0.0)
          sectorData.push({
            name: e,
            value: Math.round(cn(card, e)),
            color: SectorColors[i % SectorColors.length]
          })
      });

      return {
        holdingData,
        sectorData,
        regionData,
      };
    } else {
      return {
        holdingData: [],
        sectorData: [],
        regionData: [],
      };
      throw '-- Missing params --'
    }
  } catch (e) {
    console.log(e);
  }
}

export const prepareSFMItemFundObject = ({ returns, categories, charts, funds, selectorAttrsData }) => {
  try {
    // card is Fund from fund list.
    if (returns && categories && charts && funds) {
      let holdings = charts.topten,
          assetTypes = charts.asset_type,
          themeScore = charts.themes_new,
          diverseData = charts.diversification,
          riskAdjReturn = charts.risk_adjusted;

      let returnsData = arrayToHash(returns, CN['Ticker']);
      let chartsData = arrayToHash(charts, CN['Ticker']);

      categories["categories"] && categories["categories"].length && categories["categories"].forEach(e => {
        let r = getNest(['returns', 0], e);
        if (r){
          e._start = r['d'];
          e._end = e.returns[e.returns.length-1]['d'];
        }
        e.returns.forEach((item,index) => {
          e.returns[index].v = item.v/100;
        });
      });

      funds.forEach((e, index) => {
        funds[index].region = chartsData[cn(e, 'Ticker')].result.regions ? chartsData[cn(e, 'Ticker')].result.regions.length ? chartsData[cn(e, 'Ticker')].result.regions[0].region : [] : [];
        funds[index].returns = returnsData[e.ticker].returns;
        funds[index].returns = reverse(funds[index].returns);
        let r = getNest(['returns', 0], e);
        if (r){
          e._start = r['d'];
          e._end = e.returns[e.returns.length-1]['d'];
        }
        e.returns.forEach((item,index) => {
          e.returns[index].v = item.v/100;
        });

        if (typeof selectorAttrsData !== 'undefined' && selectorAttrsData && selectorAttrsData.length && selectorAttrsData.find(item => item[e.ticker])) {
          const currentSelectorData = selectorAttrsData.find(item => item[e.ticker]);
          e.selectorAttrsData = currentSelectorData && currentSelectorData[e.ticker] && currentSelectorData[e.ticker].length ? currentSelectorData[e.ticker][0] : {};
        }

        if (!e.series) {
          const valTraslate = v => Math.log10((v+100)/100);
          let stats = calcStats(e.returns);
          let dd = e.returns.map(e => e.d);
          e.series = dd.map(d => {
            let ival = getNest([d, 'cuml'], stats.series)
            return { x: d, y1: ival, y3: ival }
          });
        }

        // console.log(JSON.parse(JSON.stringify(e)));
        if (!e._stats || typeof e._stats === 'undefined') {
          // console.log(e._stats);
          // pass date-range param to get filter item list, it will update this.props.item
          const sfmDateRange = { // or else check this.props.sfmDateRange and use ?
            start: e._start,
            end: e._end,
          };
          if (funds.length && sfmDateRange) {
            getFilteredListByRange({ items: funds, range: sfmDateRange, catData: (categories["categories"] && categories["categories"].length) ? categories["categories"] : [] });
          }
        }

        // e.brokers = getNest([cn(e, 'Ticker'), 'brokers'], commData);
        e.brokers = BrokersComms;
        e.broker = getNest([8], e.brokers);

        //regions data manipulation -- adding others
        if(e.region.length> 0){
          e.region.forEach((reg, i) => {
            let sum = 0;
            if(reg.n == 'Others'){
              reg.sub.forEach(child => {
                sum += child.v;
              })
              e.region[i].sub = [{ n: 'Others ', v: sum}];
            }
          });
        }

        //calculate assetType chart data
        e.assetTypeData = chartsData[cn(e, 'Ticker')].result.asset_type[0] || []

        //calculate theme score chart data
        e.themeScore = chartsData[cn(e, 'Ticker')].result.themes_new[cn(e, 'Ticker')];

        //calculate risk adjusted return chart data
        e.riskAdjReturn = chartsData[cn(e, 'Ticker')].result.risk_adjusted[cn(e, 'Ticker')];

        e.diverseData = chartsData[cn(e, 'Ticker')].result.diversification[0];

        e.diverseData = chartsData[cn(e, 'Ticker')].result.diversification[0];
        // console.log(e.diverseData)
        //calculate holding chart data
        let _sum = 0.0;
        let holdingData = chartsData[cn(e, 'Ticker')].result.topten.funds.length ? chartsData[cn(e, 'Ticker')].result.topten.funds[0].holdings : []
        e.holdingData = holdingData.map((el, i) => {
          _sum += el.weight;
          return {
            name: el.name,
            value: el.weight,
            color: SectorColors[i % SectorColors.length]
          }
        });

        if (e.holdingData.length > 0 && _sum < 100){
          let rem = 100 - _sum.toFixed(1);
          rem = parseFloat(rem.toFixed(1));
          e.holdingData.push({ name: 'Others', value: rem, color: '#ddd' });
        }

        // did intentionally for HRS mapping logic
        e.sectorData = [];
        SectorsAttrs.forEach((el, i) => {
          let v = cn(e, el)
          if (v !== undefined && v > 0.0)
            e.sectorData.push({
              name: el,
              value: Math.round(cn(e, el)),
              color: SectorColors[i % SectorColors.length]
            })
        });

        e._regionData = getNestDefault([CN['Region']], e, [])
          .reduce((acc, el) => acc.concat(el.sub), [])
          .map(ek => ({ name: ek.n, value: ek.v, color: RegionColors[ek.n] }))

        e._holdingData = e.holdingData;
        e._sectorData = e.sectorData;
        // e._selected = true;
        // e._selector = true;
      });

      let filterAttrData = FilterAttrData.reduce((acc, v) => ({...acc, [v.name]: uniqValues(funds, v.col)}), {});
      filterAttrData = Object.keys(filterAttrData).reduce((acc,item) => {
        acc[item] = filterAttrData[item].filter(x => (x != 0 || x != ''))
        return acc;
      }, {});

      return { funds, categories: (categories["categories"] && categories["categories"].length) ? categories["categories"] : [], filterAttrData };
    } else {
      throw '-- Missing params --';
    }
  } catch (e) {
    console.log(e);
    return { funds: [], categories: [], filterAttrData: {} };
  }
}

export const textCapitalize = (str) => {
  if (str.length) {
    return str[0].toUpperCase() + str.slice(1);
  }
  return str;
};

export const dateRangeMonthList = range => {
  let out = [];  // List of months
  let rangeEnd = moment(range.end);
  for (let m = moment(range.start); m.isSameOrBefore(rangeEnd); m.add(1, 'months')) {
    out.push(dateToMonth(m));
  }
  return out;
}

export const dateSort = (a, b) => {
  let _a = monthToDate(a.d), _b = monthToDate(b.d);
  return _a.isBefore(_b) ? -1 : (_a.isAfter(_b) ? 1 : 0) ;
}

const valTraslate = v => v < 0 ? -1*Math.log10((100-v)/100) : Math.log10((100+v)/100);
const valTraslateX = v => v;

export const getDiscoverFundsList = ({ items, range, initialRange, ...props }) => {

  const sliderEndDate = moment(JSON.parse(JSON.stringify(window.sessionStorage.getItem('sliderEndDate'))));

  const dd = moment(range.start);
  if (!dd.isValid()) {
    range = {
      start: moment("01 "+moment(range.start, 'MMM YYYY')._i, "DD MMM YYYY"),
      end: moment("01 "+moment(range.end, 'MMM YYYY')._i, "DD MMM YYYY"),
    }
  }

  const rangeFilter = e => {
    let dt = monthToDate(e.d);
    return dt.isSameOrAfter(moment(range.start).subtract(1, 'seconds'),'date') && dt.isSameOrBefore(moment(range.end),'date');
  }

  let out = {}, ids = [];
  let x = dateRangeMonthList(range);
  let rMin = 1e6, rMax = -1e6;
  let itemsVisible = items.filter(e => !e._hidden);

  itemsVisible.forEach(e => {
    if (e.returns.length > 0) {
      let points = e.returns.filter(rangeFilter);
      if (points.length <= 0) {
        e._hidden = true;
        return;
      }

      let cat = e._categorySeries || null, categoryStats = null;
      // if (cat && cat.returns && points.length > 0) {
      if (cat && cat !== null && Object.keys(cat).length > 0) {
        let cindex = cat.returns.findIndex(e => e.d === points[0].d);
        categoryStats = calcStats(cat.returns.slice(cindex));
        // ['fees', 'attribute/technical/volume', 'aum', 'attribute/technical/volume_min', 'aum_min', 'attribute/technical/volume_max', 'aum_max', 'esgScore', 'esgScore_min', 'esgScore_max'].forEach(a => e._cstats[a] = cat[a] || 0.0 );
      }

      // console.log('=-= categoryStats =-=', categoryStats);
      let stats = calcStats(points);
      if (typeof stats.series !== 'undefined') {
        let series = x.map(d => {
          let val = getNest([d, 'cuml'], stats.series),
              ival = val !== undefined ? valTraslate(val) : val,
              cval = (ival !== undefined && categoryStats !== null && categoryStats && categoryStats.series && getNest([d, 'cuml'], categoryStats.series)) ? valTraslate(getNest([d, 'cuml'], categoryStats.series)) : 0.0,
              ival2 = ival !== undefined ? getNest([d, 'ret'], stats.series) : 'Inactive';

          if (ival < rMin) rMin = ival;
          if (cval < rMin) rMin = cval;
          if (ival > rMax) rMax = ival;
          if (cval > rMax) rMax = cval;

          return { x: d, y1: ival, y2: cval, y3: ival2, y4: val };
        });

        const stats2 = {
          start: x[0],
          end:   x[x.length-1],
        }

        e._volatility_quin  = (categoryStats && categoryStats !== null) ? getQuintile(stats.volt, categoryStats.volt) : getQuintile(stats.volt, stats.volt);
        e._return_quin      = (categoryStats && categoryStats !== null) ? getQuintile(stats.yRet, categoryStats.yRet) : getQuintile(stats.yRet, stats.yRet);
        e._return_cuml_quin = (categoryStats && categoryStats !== null) ? getQuintile(stats.cuml, categoryStats.cuml) : getQuintile(stats.cuml, stats.cuml);
        e._drawdown_quin    = (categoryStats && categoryStats !== null) ? getQuintile(stats.dMax, categoryStats.dMax) : getQuintile(stats.dMax, stats.dMax);

        e._volatility_v     = stats.volt;
        e._return_v         = stats.yRet;
        e._return_cuml_v    = stats.cuml;
        e._drawdown_v       = stats.dMax;

        if (categoryStats && categoryStats !== null) {
          e._categorySeries = {
            ...e._categorySeries,
            ...categoryStats,
            _volatility_quin : (categoryStats && categoryStats !== null) ? categoryStats.volt : 0,
            _return_quin     : (categoryStats && categoryStats !== null) ? categoryStats.yRet : 0,
            _return_cuml_quin: (categoryStats && categoryStats !== null) ? categoryStats.cuml : 0,
            _drawdown_quin   : (categoryStats && categoryStats !== null) ? categoryStats.dMax : 0,
            _return_cuml_max : (categoryStats && categoryStats !== null) ? categoryStats.cumlMax : 0,
          };
        } else {
          e._categorySeries = null;
        }

        //add api hardcoding for 1, 3, 5, 10, max years
        // console.log('sid hardcoding')
        const rend = moment(JSON.parse(JSON.stringify(range.end))).endOf('month')
        if ( sliderEndDate.month() === rend.month() &&
            moment(JSON.parse(JSON.stringify(sliderEndDate))).add(1, 'seconds').subtract(1, 'years').month() == range.start.month() &&
            moment(JSON.parse(JSON.stringify(sliderEndDate))).add(1, 'seconds').subtract(1, 'years').year() == range.start.year()
        ) {
          // console.log('sid slide1 yr')
          if(e.cumulative_returns_1y_ui != null) e._return_cuml_v = e.cumulative_returns_1y_ui *100
          if(e.drawdown_1y_ui != null) e._drawdown_v= e.drawdown_1y_ui *100
          if(e.volatility_1y_ui != null) e._volatility_v = e.volatility_1y_ui *100
          if(e.returns_1y_ui != null) e._return_v = e.returns_1y_ui *100
        } else if (sliderEndDate.month() === rend.month() &&
          moment(JSON.parse(JSON.stringify(sliderEndDate))).add(1, 'seconds').subtract(3, 'years').month() == range.start.month() &&
          moment(JSON.parse(JSON.stringify(sliderEndDate))).add(1, 'seconds').subtract(3, 'years').year() == range.start.year()
        ) {
          // console.log('sid slide3 yr')
          if(e.cumulative_returns_3y_ui != null) e._return_cuml_v = e.cumulative_returns_3y_ui *100
          if(e.drawdown_3y_ui != null) e._drawdown_v= e.drawdown_3y_ui *100
          if(e.volatility_3y_ui != null) e._volatility_v = e.volatility_3y_ui *100
          if(e.returns_3y_ui != null) e._return_v = e.returns_3y_ui *100
        } else if(sliderEndDate.month() === rend.month() &&
          moment(JSON.parse(JSON.stringify(sliderEndDate))).add(1, 'seconds').subtract(5, 'years').month() == range.start.month() &&
          moment(JSON.parse(JSON.stringify(sliderEndDate))).add(1, 'seconds').subtract(5, 'years').year() == range.start.year()
        ) {
          // console.log('sid slide5 yr')
          if(e.cumulative_returns_5y_ui != null) e._return_cuml_v = e.cumulative_returns_5y_ui *100
          if(e.drawdown_5y_ui != null) e._drawdown_v= e.drawdown_5y_ui *100
          if(e.volatility_5y_ui != null) e._volatility_v = e.volatility_5y_ui *100
          if(e.returns_5y_ui != null) e._return_v = e.returns_5y_ui *100
        } else if(sliderEndDate.month() === rend.month() &&
          moment(JSON.parse(JSON.stringify(sliderEndDate))).add(1, 'seconds').subtract(10, 'years').month() == range.start.month() &&
          moment(JSON.parse(JSON.stringify(sliderEndDate))).add(1, 'seconds').subtract(10, 'years').year() == range.start.year()
        ) {
          // console.log('sid slide10 yr')
          if(e.cumulative_returns_10y_ui != null) e._return_cuml_v = e.cumulative_returns_10y_ui *100
          if(e.drawdown_10y_ui != null) e._drawdown_v= e.drawdown_10y_ui *100
          if(e.volatility_10y_ui != null) e._volatility_v = e.volatility_10y_ui *100
          if(e.returns_10y_ui != null) e._return_v = e.returns_10y_ui *100
        } else if(sliderEndDate.month() === rend.month() &&
          moment(JSON.parse(JSON.stringify(DateRange)).start).format() == moment(JSON.parse(JSON.stringify(range)).start).format()
        ) {
          // console.log('sid slide max yr')
          if(e.cumulative_returns_ltd_ui != null) e._return_cuml_v = e.cumulative_returns_ltd_ui *100
          if(e.drawdown_ltd_ui != null) e._drawdown_v= e.drawdown_ltd_ui *100
          if(e.volatility_ltd_ui != null) e._volatility_v = e.volatility_ltd_ui *100
          if(e.returns_ltd_ui != null) e._return_v = e.returns_ltd_ui *100
        }

        e._returnsChart = {...stats2, series};

        if (lineChartStartDate(e) && lineChartStartDate(e)._tickerStartDate) {
          e._tickerStartDate = e._tickerStartDate
        }

        // uncommenting this will reflect in SFM dropdown date
        // if (e._returnsChart && typeof e._returnsChart.end !== 'undefined' && typeof e._returnsChart.start !== 'undefined' ) {
        //   e._start = e._returnsChart.start;
        //   e._end = e._returnsChart.end;
        // }
      }
      // }
    }
  });

  let risks = itemsVisible.map(e => e._volatility_v),
      returns = itemsVisible.map(e => e._return_v),
      fees = itemsVisible.map(e => e.fees),
      dividend_yields = itemsVisible.map(e => (typeof e.dividend_yield !== 'undefined' && e.dividend_yield !== 'null' && e.dividend_yield !== null) && e.dividend_yield), // .filter(e => { if(e.dividend_yield !== "null" || typeof e.dividend_yield !== 'undefined') return true;}),
      market_caps = itemsVisible.map(e => (typeof e.market_cap !== "undefined" && e.market_cap !== "null") && e.market_cap),
      // sharpeValues = itemsVisible.map(e => { let sharpee = e.riskAdjReturn.filter(e => e.name == 'Sharpe'); if(sharpee.length >0) return sharpee[0].value}).filter(e => { if(e) return e;}), sharpeMax, sharpeMin,
      // sortinoValues = itemsVisible.map(e => { let sortinoo = e.riskAdjReturn.filter(e => e.name == 'Sortino'); if(sortinoo.length >0) return sortinoo[0].value}).filter(e => {if(e) return e;}), sortinoMax, sortinoMin,
      // trackingErrorValues = itemsVisible.map(e => { let errorr = e.riskAdjReturn.filter(e => e.name == 'Tracking Error'); if(errorr.length >0) return errorr[0].value}).filter(e => {if(e) return e;}), trackingErrorMax, trackingErrorMin;

      sharpeValues = itemsVisible.map(e => { let sharpee; (e.riskAdjReturn && e.riskAdjReturn.length > 0) ? sharpee = e.riskAdjReturn.filter(e => e.name == 'Sharpe'): sharpee = []; if(sharpee.length >0) return sharpee[0].value}).filter(e => { if(e) return e;}), sharpeMax, sharpeMin,
      sortinoValues = itemsVisible.map(e => { let sortinoo; (e.riskAdjReturn && e.riskAdjReturn.length > 0) ? sortinoo = e.riskAdjReturn.filter(e => e.name == 'Sortino'): sortinoo = []; if(sortinoo.length >0) return sortinoo[0].value}).filter(e => {if(e) return e;}), sortinoMax, sortinoMin,
      trackingErrorValues = itemsVisible.map(e => { let errorr; (e.riskAdjReturn && e.riskAdjReturn.length > 0) ? errorr = e.riskAdjReturn.filter(e => e.name == 'Tracking Error') : errorr = []; if(errorr.length >0) return errorr[0].value}).filter(e => {if(e) return e;}), trackingErrorMax, trackingErrorMin;

  let [risksMin, returnsMax, feesMin, dividendMin, dividendMax, marketcapMin, marketcapMax] = [Math.min(...risks), Math.max(...returns), Math.min(...fees), Math.min(...dividend_yields), Math.max(...dividend_yields), Math.min(...market_caps), Math.max(...market_caps)];
  [sharpeMax, sharpeMin, sortinoMax, sortinoMin, trackingErrorMax, trackingErrorMin] = [Math.max(...sharpeValues), Math.min(...sharpeValues), Math.max(...sortinoValues), Math.min(...sortinoValues), Math.max(...trackingErrorValues), Math.min(...trackingErrorValues)]

  rMax = Math.max(Math.abs(rMin), rMax);
  itemsVisible.forEach(e => {
    e._flags = { risksMin: e._volatility_v === risksMin,
                 returnsMax: e._return_v === returnsMax,
                 feesMin: e.fees === feesMin };
    e._returnRange = { min: -rMax, max: rMax };
    e.sharpeMax = sharpeMax;
    e.sharpeMin = sharpeMin;
    e.sortinoMax = sortinoMax;
    e.sortinoMin = sortinoMin;
    e.trackingErrorMax = trackingErrorMax;
    e.trackingErrorMin = trackingErrorMin;
    e.dividendMin = dividendMin;
    e.dividendMax = dividendMax;
    e.marketcapMin = marketcapMin;
    e.marketcapMax = marketcapMax;
  });


  // Funds common start-end range
  let commonStartRange = moment.max(itemsVisible.map(e => monthToDate(e._start)));
  let defaultStartRange = moment(DateRange.start),
      activeStartRange = moment.max(defaultStartRange, commonStartRange);

  let commonEndRange = moment.max(itemsVisible.map(e => monthToDate(e._end)));
  let defaultEndRange = moment(DateRange.end),
      activeEndRange = moment.max(defaultEndRange, commonEndRange);

  // chart multiple render hack
  let itemsKey = items.map(e => cn(e, 'Ticker')).join('|')
  itemsKey += `|${moment(range.start).format("MMM YYYY")}|${moment(range.end).format("MMM YYYY")}`

  return {
    items: itemsVisible,
    itemsKey,
    commonActiveFundsReturnsRange: { start: activeStartRange, end: activeEndRange },
  };
};

export const createReturnSeriesData = ({ items, range, ...props }) => {
  const sliderEndDate = moment(JSON.parse(JSON.stringify(window.sessionStorage.getItem('sliderEndDate'))));
  const dd = moment(range.start);
  if (!dd.isValid()) {
    range = {
      start: moment("01 "+moment(range.start, 'MMM YYYY')._i, "DD MMM YYYY"),
      end: moment("01 "+moment(range.end, 'MMM YYYY')._i, "DD MMM YYYY"),
    }
  }

  const rangeFilter = e => {
    let dt = monthToDate(e.d);
    return dt.isAfter(moment(range.start).subtract(1, 'seconds')) && dt.isBefore(moment(range.end).add(1, 'seconds'));
  }

  let x = dateRangeMonthList(range);
  let rMin = 1e6, rMax = -1e6;
  let itemsVisible = items.filter(e => !e._hidden);

  itemsVisible.forEach(e => {
    if (e.returns.length > 0) {
      let points = e.returns.filter(rangeFilter);
      if (points.length <= 0) {
        e._hidden = true;
        return;
      }

      let cat = e._categorySeries;
      if (cat && cat.returns && points.length > 0) {
        let cindex = cat.returns.findIndex(e => e.d === points[0].d);
        let categoryStats = calcStats(cat.returns.slice(cindex));
        // ['fees', 'attribute/technical/volume', 'aum', 'attribute/technical/volume_min', 'aum_min', 'attribute/technical/volume_max', 'aum_max', 'esgScore', 'esgScore_min', 'esgScore_max'].forEach(a => e._cstats[a] = cat[a] || 0.0 );
        let stats = calcStats(points);
        let series = x.map(d => {
          let val = getNest([d, 'cuml'], stats.series),
              ival = val !== undefined ? valTraslate(val) : val,
              cval = ival !== undefined && getNest([d, 'cuml'], categoryStats.series) ? valTraslate(getNest([d, 'cuml'], categoryStats.series)) : 0.0,
              ival2 = ival !== undefined ? getNest([d, 'ret'], stats.series) : 'Inactive';

          if (ival < rMin) rMin = ival;
          if (cval < rMin) rMin = cval;
          if (ival > rMax) rMax = ival;
          if (cval > rMax) rMax = cval;

          return { x: d, y1: ival, y2: cval, y3: ival2, y4: val };
        });

        e._returnsChart = series;
      }
    }
  });

  rMax = Math.max(Math.abs(rMin), rMax);
  itemsVisible.forEach(e => {
    e._returnRange = { min: -rMax, max: rMax };
  });

  return {
    funds: itemsVisible,
  };
};

export async function executeDiscoverDataMapping(_funds, query, reducerSfmItems, reducerBuilderItems) {
  // console.log("fund: ", _funds);
  if (!_funds || _funds.funds.length === 0 || (_funds.data && _funds.data.length === 0))
    return { funds: [], stockFunds: [], categories: [], range: { start: moment(DateRange.start), end: moment(DateRange.end) }, filterAttrData: {} };

  if (uniqValues(_funds.funds, 'ticker').length <= 0)
    return { funds: [], stockFunds: [], categories: [], range: { start: moment(DateRange.start), end: moment(DateRange.end) }, filterAttrData: {} };

  let resultCount = _funds.total_funds || _funds.total_stock_funds || 0;
  let ticks = uniqValues(_funds.funds.filter(v=> !(v.type === "BASKET" || v.vehicle === "BASKET")), 'ticker').join(',');

  let didYouMean = _funds.didumean;
  let updated_at = new moment().format('YYYY/MM/D hh:mm:ss SSS');
  let relatedInsightsResponse = {
    relatedInsights: [],
    message: '',
  };

  if (
    _funds &&
    _funds['stocks_data'] &&
    _funds['stocks_data'].length > 0 &&
    Array.isArray(_funds['stocks_data'])
  ) {
    let stock_ticks = uniqValues(_funds['stocks_data'].filter(v=> !(v.type === "BASKET" || v.vehicle === "BASKET")), 'ticker');
    ticks = ticks.split(',');
    ticks = [...ticks, ...stock_ticks].join(',');
  }

  let sponsors_tickers = _funds.sponsors_tickers;
  let [fundsPrice] = await Promise.all([
    fetchRealTimePrices({ tickers: ticks }),
  ]);

  const { related, messages, log_id, date_range, query_uuid, filter_options, query_log, default_chart = 'Assets' } = _funds;
  let { corrected_query } = _funds;
  corrected_query = corrected_query || query_log.corrected_query_string;
  let itemCardView = {};
  let funds = [];
  let stockFunds = _funds['stocks_data'] || [];

  if (_funds["funds"] && _funds["funds"].length) {
    funds = _funds["funds"].slice(0, MaxResult);
  }

  if (funds.length <= 0)
  return { funds: [], stockFunds: [], categories: [], range: _funds["date_range"], filterAttrData: {} };

  if (
    _funds['funds'] &&
    _funds['stocks_data'] &&
    _funds['stocks_data'].length > 0 &&
    Array.isArray(_funds['funds']) &&
    Array.isArray(_funds['stocks_data'])
  ) {
    _funds['stocks_data'].forEach((sI, i) => {
      if (typeof sI.assetTypeData === 'undefined') {
        sI.assetTypeData = {}
      }
      if (typeof sI.categories === 'undefined') {
        sI.categories = null
      }
      if (typeof sI.diverseData === 'undefined') {
        sI.diverseData = {}
      }
      if (typeof sI.diverseData === 'undefined') {
        sI.diverseData = []
      }
      if (typeof sI.region === 'undefined') {
        sI.region = []
      }
      if (typeof sI.returns === 'undefined') {
        sI.returns = []
      }
      if (typeof sI.riskAdjReturn === 'undefined') {
        sI.riskAdjReturn = []
      }
      if (typeof sI.themeScore === 'undefined') {
        sI.themeScore = []
      }
    });

    funds = [
      ...funds,
      ..._funds['stocks_data'],
    ];
    stockFunds = _funds['stocks_data'];
  }

  if (
    _funds['funds'] &&
    _funds['stocks_data'] &&
    _funds['stocks_data'].length === 0 &&
    Array.isArray(_funds['funds']) &&
    Array.isArray(_funds['stocks_data']) &&
    _funds['funds'].filter((item) => (item.type === 'SHARE')).length > 0
  ) {
    let __stockFunds = _funds['funds'].filter((item) => (item.type === 'SHARE'));
    stockFunds = [
      ...stockFunds,
      ...__stockFunds,
    ];
  }

  const queryArr = _funds["query_var"];
  let highlightArr = [];
  let queryCategory = 'unknown';
  let dynamicQueryCategory, queryCategoryValue, viewName, secondaryViewName, chartData = [], ticker, highlightText = {}, outPerfReturnsData;

  let relevantCharts = (queryArr.filter(e => {
    if (e.active && e.view_name && e.view_name != "") {
      if (e.view_name != 'Highlight') {
        return e;
      }
      else
      highlightArr.push(e)
    }
  }) || []);

  const __DefaultViewName = (default_chart && typeof default_chart !== 'undefined' && default_chart !== 'null') ? convertToCapitalCase(default_chart.toLowerCase()) : 'Assets';

  let relevantViewNames = [], jointNameStr = '', jointNameArray = [];
  if (relevantCharts && relevantCharts.length > 0) {
    let secondaryViewArr = [];
    relevantCharts.forEach((r, i) => {
      let view = r.view_name;
      jointNameArray.push(view);
      // NOTE: pick only dynamic chart
      if (view === 'Regions') {
        let displayText = RegionChartMapping.filter(
          e => e.chart_name === r.node && r.Static === true
        )
        if (displayText.length) {
          let toBeDisplayedView = '%\u00a0' + displayText[0].region_name;
          view = toBeDisplayedView;
        }
      } else if (view == 'Sectors') {
        let find = SectorChartMapping.filter(
          e => e.chart_name === r.node && r.Static === true
        )
        if (find.length > 0) {
          let toBeDisplayedView = '%\u00a0' + find[0].sector_name
          view = toBeDisplayedView
        }
      } else if (
        view == 'Top Holdings' &&
        r.data &&
        (r.variable.includes('holdings_') ||
        r.variable.includes('neg_holdings_'))
      ) {
        let ticker = ''
        if (Object.prototype.toString.call(r.data) === '[object Array]') {
          ticker = r.data[0]
        } else if (typeof r.data === 'string') {
          ticker = r.data
        }
        if (ticker.length > 4) ticker = formatTickerForView(ticker)
        let toBeDisplayedView = '%\u00a0' + ticker
        view = toBeDisplayedView
        // return name;
      }
      // NOTE: for Holdings mapping, pick 'view' from query_var.data
      if (view && view.includes('ticker')) {
        if (Object.prototype.toString.call(r.data) === '[object Array]') {
          view = view.replace("ticker", r.data[0]);
        }
        else if (typeof r.data === 'string') {
          view = view.replace("ticker", r.data);
        }
      }
      //push the individual view name
      relevantViewNames.push({ name: view, secondaryView: r.Secondary_view_name, originalName: [r.view_name], variable: r.variable });
      relevantCharts[i].display_view_name = view;
      // console.log(r.variable);
      relevantCharts[i].variable = r.variable;
      if (view == 'Return Quality') view = view + ' (' + r.Secondary_view_name + ')';
      i == 0 ? jointNameStr += view : jointNameStr += ' && ' + view;
      // secondaryViewArr.push(r.Secondary_view_name);
    });
    if (relevantViewNames.length > 1) relevantViewNames.push({ name: jointNameStr, secondaryView: '', originalName: jointNameArray });
  } else if (default_chart && typeof default_chart !== 'undefined' && default_chart !== 'null') {
    // NOTE: check for `default_chart` key in api response if query_var is empty
    relevantViewNames.push({ name: __DefaultViewName, secondaryView: '', originalName: [`${__DefaultViewName}`] });
    relevantCharts.push({ active: true, Static: true, view_name: `${__DefaultViewName}`, Secondary_view_name: '', display_view_name: `${__DefaultViewName}` });
  } else {
    relevantViewNames.push({ name: 'Assets', secondaryView: '', originalName: ['Assets'] });
    relevantCharts.push({ active: true, Static: true, view_name: 'Assets', Secondary_view_name: '', display_view_name: 'Assets' });
  }

  if (relevantViewNames && relevantViewNames.length > 0 && relevantViewNames[relevantViewNames.length - 1] && relevantViewNames[relevantViewNames.length - 1].name.includes('&')) {
    itemCardView = {
      view: relevantViewNames[0].name,
      secondaryView: relevantViewNames[0].secondaryView,
      mappingVariable: relevantViewNames[0].variable || '',
    };
  } else {
    itemCardView = {
      view: relevantCharts[0].display_view_name,
      secondaryView: relevantCharts[0].Secondary_view_name,
      mappingVariable: relevantCharts[0].variable || '',
    };
  }

  if (relevantCharts.length > 0)
  chartData = relevantCharts;
  highlightText = {
    feesHighLightEnable: false,
    returnHighLightEnable: false,
    riskHighLightEnable: false,
  };

  if (highlightArr.length) {
    let highlightPopup = [];
    const highlightText_local = highlightArr.map((item) => {
      if (typeof item.chart_type !== 'undefined' && item.chart_type.length && item.active) {
        if (item.chart_type == 'highlight_popup')
        highlightPopup.push(item);
        return item.chart_type.split('highlight_')[1];
      }
    });
    if (highlightText_local.length) {
      highlightText_local.map((item) => {
        switch (item) {
          case 'fees':
            highlightText.feesHighLightEnable = true;
          break;
          case 'returns':
            highlightText.returnHighLightEnable = true;
          break;
          case 'risk':
            highlightText.riskHighLightEnable = true;
          break;
          case 'popup':
            highlightText.popupHighLightEnable = highlightPopup;
          break;
          default:
            highlightText = {
              feesHighLightEnable: false,
              returnHighLightEnable: false,
              riskHighLightEnable: false,
            };
        }
      });
    } else {
      highlightText = {
        feesHighLightEnable: false,
        returnHighLightEnable: false,
        riskHighLightEnable: false,
      };
    }
  } else {
    highlightText = {
      feesHighLightEnable: false,
      returnHighLightEnable: false,
      riskHighLightEnable: false,
    };
  }

  if (fundsPrice) {
    const updatedFundsPriceData = getFundsUpdatedPrice(fundsPrice, funds);
    if (updatedFundsPriceData) {
      funds = updatedFundsPriceData.funds;
      updated_at = updatedFundsPriceData.updated_at;
    }
  }

  // console.log(filter_options);
  // let filterAttrData = FilterAttrData.reduce((acc, v) => ({ ...acc, [v.name]: uniqValues(funds, v.col) }), {});
  // console.log(filterAttrData);
  // filterAttrData = Object.keys(filterAttrData).reduce((acc, item) => {
  //   acc[item] = filterAttrData[item].filter(x => (x != 0 || x != ''))
  //   return acc;
  // }, {});
  // console.log(filterAttrData);

  let filterAttrData = {};
  if (filter_options && Object.keys(filter_options).length > 0) {
    Object.keys(filter_options).map((cls, v) => {
      let kk = FilterAttrData.find((fAtr) => fAtr.col === cls);
      if (kk) {
        filterAttrData[kk.name] = filter_options[cls]; // api gives 'asset_class' not 'Asset Class', so convert back 'Asset Class' to 'asset_class' for api filter request
      }
    });
  }

  const applyDateRangeFilter = {
    start: (date_range && date_range.start) ? moment(new Date(`01 ${date_range.start}`)) : moment(DateRange.start),
    end: moment(new Date(`01 ${date_range.end}`)),
  }

  // based of API's date_range value execute `getUpdatedRangeFromYears` function.
  const updatedFundsDateRange = getUpdatedRangeFromYears(DefaultYears, applyDateRangeFilter); // returns Jun 2016, May 2019
  // apply filter attribute to filter-out funds data
  // const newUpdatedFundsData = filterApply(funds, updatedFundsDateRange, filterAttrData); // JSON.parse(JSON.stringify(funds));
  // console.log(newUpdatedFundsData);
  // let sliderEndDate = window.sessionStorage.getItem('sliderEndDate');
  // if(!sliderEndDate){
  //enable this code to save the end date in moment format
  let dd = moment(JSON.parse(JSON.stringify(updatedFundsDateRange.end))).endOf('month');
  if (!dd.isValid()) {
    dd = moment("01 " + moment(updatedFundsDateRange.end, 'MMM YYYY')._i, "DD MMM YYYY");
    dd = dd.endOf('month');
  }
  window.sessionStorage.setItem('sliderEndDate', dd.toString());
  // }

  // const NEW_DATA_FROM_FILTER = JSON.parse(JSON.stringify(updatedFundsDateRange));
  const NEW_DATA_FROM_FILTER = applyDateRangeFilter; //API's date_range value for.e.g: 2014 - 2020
  // console.log({ start: moment("01 "+NEW_DATA_FROM_FILTER.start, "DD MMM YYYY"), end: moment("01 "+NEW_DATA_FROM_FILTER.end, "DD MMM YYYY") });
  // const sponsor_ticker = _funds.sponsors_tickers;
  // const themeValue = chartData.map((item) => {
  //   if (item && item.ontology_node) {
  //     const themeData = item.ontology_node.split('/');
  //     if (themeData.length) {
  //       themeData.filter(i => i === 'theme');
  //       return themeData[themeData.length - 1];
  //     }
  //   }
  // });
  //
  // try {
  //   if (themeValue && themeValue.length && getInsightHashKey(themeValue[0]) && RelatedInsightsToggle) {
  //     // call mio insight api
  //     const idHash = getInsightHashKey(themeValue[0]);
  //     let [pluginResponse] = await Promise.all([
  //       getRelatedInsightsData({ id_hash: idHash }),
  //     ])
  //     if (pluginResponse.result.success ===  true) {
  //       let feeds = pluginResponse.result.data.feed;
  //       if (feeds) {
  //         let newArr = feeds.filter(item => (item.media_urls.length > 0 && item.text != ""));
  //         const arr = [];
  //         if (newArr.length) {
  //           if(config.sponsoredTicker && sponsor_ticker.length === 3) { newArr.length = 2; } //0
  //           else if (config.sponsoredTicker && sponsor_ticker.length === 1) { newArr.length = 2; } //2
  //           else if (config.sponsoredTicker && sponsor_ticker.length === 2) { newArr.length = 2; } //1
  //           else { newArr.length = 3; }
  //           arr.push(newArr);
  //           arr.push(pluginResponse.result.data.details);
  //           relatedInsightsResponse = {
  //             relatedInsights: arr,
  //             message: 'success',
  //           };
  //         } else {
  //           relatedInsightsResponse = {
  //             relatedInsights: [],
  //             message: 'media_urls in response not found',
  //           };
  //         }
  //       } else {
  //         relatedInsightsResponse = {
  //           relatedInsights: [],
  //           message: 'empty insight api response',
  //         };
  //       }
  //     } else if (pluginResponse.result.success === false) {
  //       console.log('No data for related insights');
  //       relatedInsightsResponse = {
  //         relatedInsights: [],
  //         message: 'No data',
  //       };
  //     }
  //   }
  // } catch (error) {
  //   console.log('error -------->>>>>>>>>>>>>>>>>>>>>>>>>>', error);
  //   relatedInsightsResponse = {
  //     relatedInsights: [],
  //     message: 'No data',
  //   };
  // }

  // MIN & MAX value for ODOMETER chart
  if (chartData.length) {
    chartData.map(chart => {
      if(chart.chart_type == 'odometer' && chart.Static == false) {
        let min, max;
        let values = funds.map(e => e[chart.variable]);
        if(chart.min == 'data_min'){
          min = Math.min(...values);
          chart.min = min;
        }
        if(chart.max == 'data_max'){
          max = Math.max(...values);
          chart.max = max;
        }
      }
    });
  }

  funds.map((e, index) => {
    funds[index].returns = (funds[index].returns) ? reverse(funds[index].returns) : [];
    let Fr = getNest(['returns', 0], e);
    if (Fr && e.returns && e.returns.length > 0) {
      e._start = Fr['d'];
      e._end = e.returns[e.returns.length - 1]['d'];
    }
    if (e.returns && e.returns.length > 0) {
      e.returns.forEach((item, index) => {
        e.returns[index].v = item.v/100;
      });
    }

    // calculate holding chart data
    let _sum = 0.0;
    e.holdingData = e.holdingData ? e.holdingData.map((el, i) => {
      _sum += el.weight;
      return {
        name: el.name,
        value: el.weight,
        color: SectorColors[i % SectorColors.length]
      }
    }) : [];
    if (e.holdingData.length > 0 && _sum < 100) {
      let rem = 100 - _sum.toFixed(1);
      rem = parseFloat(rem.toFixed(1));
      e.holdingData.push({ name: 'Others', value: rem, color: '#ddd' });
    }

    // select funds if its present in Builder array
    if (reducerBuilderItems && reducerBuilderItems.length > 0) {
      const found = reducerBuilderItems.find(d => d.ticker === e.ticker);
      if (found && typeof found !== 'undefined') {
        e._builder = true;
      }
    }

    // select funds if its present in SFM array
    if (reducerSfmItems && reducerSfmItems.length > 0) {
      const found = reducerSfmItems.find(d => d.ticker === e.ticker);
      if (found && typeof found !== 'undefined') {
        e._selected = true;
        e._selector = found._selector;
      }
    }

    // Set Risk, Return, Drawdown value for FRR block
    e._volatility_v  = e[`volatility_${DefaultYears}y_ui`] ? (e[`volatility_${DefaultYears}y_ui`]*100) : (e[`volatility_${DefaultYears}y`] ? e[`volatility_${DefaultYears}y`]*100 : null);
    e._return_v      = e[`returns_${DefaultYears}y_ui`] ? (e[`returns_${DefaultYears}y_ui`]*100) : (e[`returns_${DefaultYears}y`] ? e[`returns_${DefaultYears}y`]*100 : null);
    e._return_cuml_v = e[`cumulative_returns_${DefaultYears}y_ui`] ? (e[`cumulative_returns_${DefaultYears}y_ui`]*100) : (e[`cumulative_returns_${DefaultYears}y`] ? e[`cumulative_returns_${DefaultYears}y`]*100 : null);
    e._drawdown_v    = e[`drawdown_${DefaultYears}y_ui`] ? (e[`drawdown_${DefaultYears}y_ui`]*100) : (e[`drawdown_${DefaultYears}y`] ? e[`drawdown_${DefaultYears}y`]*100 : null);

    // Quintile value for Risk/Return ['Low', 'Avg', 'High']
    e._volatility_quin  = e[`riskq`] || null;
    e._return_quin      = e[`returnq`] || null;
    e._return_cuml_quin = e[`return_cuml_q`] || null;
    e._drawdown_quin    = e[`drawdownq`] || null;
    // e.selectorAttrsData = currentSelectorData && currentSelectorData[e.ticker] && currentSelectorData[e.ticker].length ? currentSelectorData[e.ticker][0] : {};

    e._returnsChart     = e[`returns`];
    // uncommenting this will reflect in SFM dropdown date
    // if (e._returnsChart && typeof e._returnsChart.end !== 'undefined' && typeof e._returnsChart.start !== 'undefined' ) {
    //   e._start = e._returnsChart.start;
    //   e._end = e._returnsChart.end;
    // }

    let r = getNest(['returns', 0], e[`categories`]);
    if (e[`categories`] && e[`categories`].returns && e[`categories`].returns.length) {
      if (r) {
        e[`categories`]._start = r['d'];
        e[`categories`]._end = e[`categories`].returns[e[`categories`].returns.length-1]['d'];
      }
      e[`categories`].returns.forEach((item,index) => {
        e[`categories`].returns[index].v = item.v/100;
      });
    }
    e._categorySeries = e[`categories`] || null;

    // for View dropdown
    e.chartData = chartData;

    // Brokers
    e.brokers = BrokersComms;
    e.broker = getNest([8], e.brokers);

    // query for reports
    e.query = query;
    e.correctedQuery = corrected_query;

    let st = ''
    if (
      _funds.sponsors_tickers &&
      Array.isArray(_funds.sponsors_tickers)
    ) {
      let sponsored = _funds.sponsors_tickers.filter(sponsor => sponsor.ticker === e.ticker)
      if (sponsored && sponsored.length > 0) {
        st = sponsored[0].link
      }
    }

    e._learnMoreLink = (e.type === 'BASKET' || e.vehicle === 'BASKET')
      ? e.basket_link || 'https://magnifi.com'
      : st.includes(".pdf") ? `/assets/data${st}` : st

  });

  // Funds common start-end range
  let commonStartRange = moment.max(funds.map(e => monthToDate(e._start)));
  let defaultStartRange = moment(DateRange.start),
      activeStartRange = moment.max(defaultStartRange, commonStartRange);

  let commonEndRange = moment.max(funds.map(e => monthToDate(e._end)));
  let defaultEndRange = moment(DateRange.end),
      activeEndRange = moment.max(defaultEndRange, commonEndRange);

  if (funds.filter((item) => (item.type !== 'SHARE')).length !== 0) {
    funds = funds.filter((item) => (item.type !== 'SHARE'));
  }

  // if (funds.filter((item) => (item.type === 'SHARE')).length > 0) {
  //   let __stockFunds = funds.filter((item) => (item.type === 'SHARE'));
  //   stockFunds = [
  //     ...stockFunds,
  //     ...__stockFunds,
  //   ];
  // }

  return {
    funds, //newUpdatedFundsData,
    stockFunds,
    highlightText,
    range: NEW_DATA_FROM_FILTER,
    dateRangeSelected: updatedFundsDateRange,
    filterAttrData,
    relevantViewNames,
    chartData,
    related,
    didYouMean,
    corrected_query,
    outPerfReturnsData,
    messages,
    ticks,
    itemCardView,
    relatedInsightsResponse,
    log_id,
    updated_at,
    sponsors_tickers,
    resultCount,
    query_uuid,
    query_var: queryArr,
    commonActiveFundsReturnsRange: { start: activeStartRange, end: activeEndRange },
  };
}

export const getFRRAttrData = (query, highlights) => {
  try {
    if (query && highlights) {
      const { feesHighLightEnable, returnHighLightEnable, riskHighLightEnable } = highlights;
      return [
        { attr: 'fees',                  name: 'Fees',           qcol: 'feesQ',         col: 'fees',                                              reverse: true,  icon: 'fa-tags',           multiplier: 1,   highlightClass: feesHighLightEnable ? 'search-risk-stats-highlighting' : '', suffix: '%', decimal: 2 },
        { attr: 'returns',               name: 'Returns',        qcol: 'yRetQ',         col: 'yRet',                                              reverse: false, icon: 'fa-chart-line',     multiplier: 1,   highlightClass: returnHighLightEnable  ? 'search-risk-stats-highlighting' : '', suffix: '%', decimal: 2 },
        { attr: 'risk',                  name: 'Risk',           qcol: 'voltQ',         col: 'volt',                                              reverse: true,  icon: 'fa-heart-rate-alt', multiplier: 1,   highlightClass: riskHighLightEnable ? 'search-risk-stats-highlighting' : '', suffix: '%', decimal: 2 },
        // { attr: 'outperform',            name: 'Outperform',     qcol: 'OutPerform',    col: 'outperform',                                        reverse: true,  img:  'OutPerform',        multiplier: 100, highlightClass: 'search-risk-stats-highlighting', suffix: '%', decimal: 2 },
        { attr: 'track_record',          name: 'Track Record',   qcol: 'TrackRecord',   col: 'attribute/track_record',                            reverse: true,  img:  'TrackRecord',       multiplier: 1,   highlightClass: 'search-risk-stats-highlighting', suffix: 'Yrs', decimal: 2 },
        // { attr: 'tracking_error',        name: 'Tracking Error', qcol: 'TrackingError', col: 'attribute/technical/tracking_error',                reverse: true,  icon: 'fa-heart-rate-alt', multiplier: 1,   highlightClass: 'search-risk-stats-highlighting', suffix: '%', decimal: 2 },
        { attr: 'yield',                 name: 'Yield',          qcol: 'Yield',         col: 'attribute/fundamental/yield',                       reverse: true,  img:  'Yield',             multiplier: 100, highlightClass: 'search-risk-stats-highlighting', suffix: '%', decimal: 2 },
        { attr: 'risk_adjusted_returns', name: 'Sharpe',         qcol: 'Sharpe',        col: 'attribute/technical/returns/risk_adjusted_returns', reverse: true,  img:  'Sharpe',            multiplier: 1,   highlightClass: 'search-risk-stats-highlighting', suffix: '', decimal: 2 },
        // { attr: 'aum',                   name: 'Assets',         qcol: 'Assets',        col: 'attribute/technical/aum',                           reverse: true,  img:  'Assets',            multiplier: 1,   highlightClass: 'search-risk-stats-highlighting', suffix: '', decimal: 2 },
      ]
    } else {
      return [
        { attr: 'fees',                  name: 'Fees',           qcol: 'feesQ',         col: 'fees',                                              reverse: true,  icon: 'fa-tags',           multiplier: 1,   highlightClass: '', suffix: '%', decimal: 2 },
        { attr: 'returns',               name: 'Returns',        qcol: 'yRetQ',         col: 'yRet',                                              reverse: false, icon: 'fa-chart-line',     multiplier: 1,   highlightClass: '', suffix: '%', decimal: 2 },
        { attr: 'risk',                  name: 'Risk',           qcol: 'voltQ',         col: 'volt',                                              reverse: true,  icon: 'fa-heart-rate-alt', multiplier: 1,   highlightClass: '', suffix: '%', decimal: 2 },
        // { attr: 'outperform',            name: 'Outperform',     qcol: 'OutPerform',    col: 'outperform',                                        reverse: true,  img:  'OutPerform',        multiplier: 100, highlightClass: 'search-risk-stats-highlighting', suffix: '%', decimal: 2 },
        { attr: 'track_record',          name: 'Track Record',   qcol: 'TrackRecord',   col: 'attribute/track_record',                            reverse: true,  img:  'TrackRecord',       multiplier: 1,   highlightClass: 'search-risk-stats-highlighting', suffix: 'Yrs', decimal: 2 },
        // { attr: 'tracking_error',        name: 'Tracking Error', qcol: 'TrackingError', col: 'attribute/technical/tracking_error',                reverse: true,  icon: 'fa-heart-rate-alt', multiplier: 1,   highlightClass: 'search-risk-stats-highlighting', suffix: '%', decimal: 2 },
        { attr: 'yield',                 name: 'Yield',          qcol: 'Yield',         col: 'attribute/fundamental/yield',                       reverse: true,  img:  'Yield',             multiplier: 100, highlightClass: 'search-risk-stats-highlighting', suffix: '%', decimal: 2 },
        { attr: 'risk_adjusted_returns', name: 'Sharpe',         qcol: 'Sharpe',        col: 'attribute/technical/returns/risk_adjusted_returns', reverse: true,  img:  'Sharpe',            multiplier: 1,   highlightClass: 'search-risk-stats-highlighting', suffix: '', decimal: 2 },
        // { attr: 'aum',                   name: 'Assets',         qcol: 'Assets',        col: 'attribute/technical/aum',                           reverse: true,  img:  'Assets',            multiplier: 1,   highlightClass: 'search-risk-stats-highlighting', suffix: '', decimal: 2 },
      ]
    }
  } catch (e) {
    console.log(e);
  }
};

export const getFRRAttrDataForModels = (query, highlights) => {
  try {
    if (highlights) {
      const { feesHighLightEnable, returnHighLightEnable, riskHighLightEnable } = highlights;
      return [
        { attr: 'fees',                  name: 'Fees',           qcol: 'feesQ',         col: 'fees',                                              reverse: true,  icon: 'fa-tags',           multiplier: 1,   highlightClass: feesHighLightEnable ? 'search-risk-stats-highlighting' : '', suffix: '%', decimal: 2 },
        // { attr: 'returns',               name: 'Returns',        qcol: 'yRetQ',         col: 'yRet',                                              reverse: false, icon: 'fa-chart-line',     multiplier: 100, highlightClass: returnHighLightEnable  ? 'search-risk-stats-highlighting' : '', suffix: '%', decimal: 2 },
        { attr: 'returns',               name: 'Returns',        qcol: 'yRetQ',         col: 'returns_3y',                                        reverse: false, icon: 'fa-chart-line',     multiplier: 100, highlightClass: returnHighLightEnable  ? 'search-risk-stats-highlighting' : '', suffix: '%', decimal: 2 },
        // { attr: 'risk',                  name: 'Risk',           qcol: 'voltQ',         col: 'volt',                                              reverse: true,  icon: 'fa-heart-rate-alt', multiplier: 100, highlightClass: riskHighLightEnable ? 'search-risk-stats-highlighting' : '', suffix: '%', decimal: 2 },
        { attr: 'risk',                  name: 'Risk',           qcol: 'voltQ',         col: 'volatility_3y',                                     reverse: true,  icon: 'fa-heart-rate-alt', multiplier: 100, highlightClass: riskHighLightEnable ? 'search-risk-stats-highlighting' : '', suffix: '%', decimal: 2 },
        { attr: 'outperform',            name: 'Outperform',     qcol: 'OutPerform',    col: 'outperform',                                        reverse: true,  img:  'OutPerform',        multiplier: 100, highlightClass: 'search-risk-stats-highlighting', suffix: '%', decimal: 2 },
        { attr: 'track_record',          name: 'Track Record',   qcol: 'TrackRecord',   col: 'attribute/track_record',                            reverse: true,  img:  'TrackRecord',       multiplier: 1,   highlightClass: 'search-risk-stats-highlighting', suffix: 'Yrs', decimal: 2 },
        { attr: 'tracking_error',        name: 'Tracking Error', qcol: 'TrackingError', col: 'attribute/technical/tracking_error',                reverse: true,  icon: 'fa-heart-rate-alt', multiplier: 1,   highlightClass: 'search-risk-stats-highlighting', suffix: '%', decimal: 2 },
        { attr: 'yield',                 name: 'Yield',          qcol: 'Yield',         col: 'attribute/fundamental/yield',                       reverse: true,  img:  'Yield',             multiplier: 100, highlightClass: 'search-risk-stats-highlighting', suffix: '%', decimal: 2 },
        { attr: 'risk_adjusted_returns', name: 'Sharpe',         qcol: 'Sharpe',        col: 'attribute/technical/returns/risk_adjusted_returns', reverse: true,  img:  'Sharpe',            multiplier: 1,   highlightClass: 'search-risk-stats-highlighting', suffix: '', decimal: 2 },
        { attr: 'aum',                   name: 'Assets',         qcol: 'Assets',        col: 'attribute/technical/aum',                           reverse: true,  img:  'Assets',            multiplier: 1,   highlightClass: 'search-risk-stats-highlighting', suffix: '', decimal: 2 },
      ]
    } else {
      return [
        { attr: 'fees',                  name: 'Fees',           qcol: 'feesQ',         col: 'fees',                                              reverse: true,  icon: 'fa-tags',           multiplier: 1,   highlightClass: '', suffix: '%', decimal: 2 },
        { attr: 'returns',               name: 'Returns',        qcol: 'yRetQ',         col: 'returns_3y',                                              reverse: false, icon: 'fa-chart-line',     multiplier: 100, highlightClass: '', suffix: '%', decimal: 2 },
        { attr: 'risk',                  name: 'Risk',           qcol: 'voltQ',         col: 'volatility_3y',                                              reverse: true,  icon: 'fa-heart-rate-alt', multiplier: 100, highlightClass: '', suffix: '%', decimal: 2 },
        { attr: 'outperform',            name: 'Outperform',     qcol: 'OutPerform',    col: 'outperform',                                        reverse: true,  img:  'OutPerform',        multiplier: 100, highlightClass: 'search-risk-stats-highlighting', suffix: '%', decimal: 2 },
        { attr: 'track_record',          name: 'Track Record',   qcol: 'TrackRecord',   col: 'attribute/track_record',                            reverse: true,  img:  'TrackRecord',       multiplier: 1,   highlightClass: 'search-risk-stats-highlighting', suffix: 'Yrs', decimal: 2 },
        { attr: 'tracking_error',        name: 'Tracking Error', qcol: 'TrackingError', col: 'attribute/technical/tracking_error',                reverse: true,  icon: 'fa-heart-rate-alt', multiplier: 1,   highlightClass: 'search-risk-stats-highlighting', suffix: '%', decimal: 2 },
        { attr: 'yield',                 name: 'Yield',          qcol: 'Yield',         col: 'attribute/fundamental/yield',                       reverse: true,  img:  'Yield',             multiplier: 100, highlightClass: 'search-risk-stats-highlighting', suffix: '%', decimal: 2 },
        { attr: 'risk_adjusted_returns', name: 'Sharpe',         qcol: 'Sharpe',        col: 'attribute/technical/returns/risk_adjusted_returns', reverse: true,  img:  'Sharpe',            multiplier: 1,   highlightClass: 'search-risk-stats-highlighting', suffix: '', decimal: 2 },
        { attr: 'aum',                   name: 'Assets',         qcol: 'Assets',        col: 'attribute/technical/aum',                           reverse: true,  img:  'Assets',            multiplier: 1,   highlightClass: 'search-risk-stats-highlighting', suffix: '', decimal: 2 },
      ]
    }
  } catch (e) {
    console.log(e);
  }
};

export const portfolioDataToCSVUploadPortfolioFormat = (portfolioFunds) => {
  try {
    if (portfolioFunds && portfolioFunds.length > 0) {
      const mappedData = portfolioFunds.map((item, i) => {
        if (item.ticker) {
          return {
            ticker: item.ticker,
            weight: item.weight,
            shares: item.shares,
            price : item.price,
            invalid: item.invalid,
          }
        }
      });
      return mappedData;
    } else {
      throw `-- portfolioFunds is missing -- ${portfolioFunds}`
    }
  } catch (e) {
    console.log(e);
    return [];
  }
}

export const capitalizeAll = (str)=>{
  return str.toLowerCase().replace(/\b\w/g, l => l.toUpperCase())
}

export const dropdownAndReturnSeries = ({ returns, fund = null }) => {
  const defaultSeries = JSON.parse(JSON.stringify(returns));
  let updatedFundObject = fund, tickerStartDate = null; // '28 Aug 2014';
  if (defaultSeries && defaultSeries.length > 0 && typeof defaultSeries[0].d !== 'undefined') {
    let dropdown = {
      l1y: {
        label: '1 Year',
        value: 'l1y',
        disabled: false,
      },
      l3y: {
        label: '3 Years',
        value: 'l3y',
        disabled: false,
      },
      l5y: {
        label: '5 Years',
        value: 'l5y',
        disabled: false,
      },
    };
    let outSeries = {};
    const start = moment(new Date(`01 ${defaultSeries[defaultSeries.length - 1].d}`));
    const end = moment(new Date(`01 ${defaultSeries[0].d}`));
    outSeries = {
      start: start.format("MMM YYYY"),
      end: end.format("MMM YYYY"),
    };
    let cumlSeries = outSeries;
    const PortfolioDateRangeMoment = { start, end };
    Object.keys(dropdown).forEach((item, i) => {
      if (item) {
        let stats = calcCumulativeReturns(defaultSeries, nameToDateRange(item, PortfolioDateRangeMoment), tickerStartDate);
        if (stats && stats.series) {
          cumlSeries = {
            ...cumlSeries,
            [`${item}`]: stats.series,
          }
          outSeries = {
            ...outSeries,
            [`${item}`]: stats.series.filter((k) => k.d !== 'Start'),
          };
        }
      }
    });
    let out = {
      outSeries,
      dropdown,
      cumlSeries,
    };

    if (outSeries && Object.keys(outSeries).length > 0 && (outSeries.l1y.length > 0) && typeof outSeries.l1y[0].d !== 'undefined') {
      // check if current Month has 0 value..  for 1 year, 3 years and 5 years.
      const currentMonth = moment(new Date()).format('MMM YYYY');

      if (outSeries.l1y[outSeries.l1y.length - 1].d === currentMonth && outSeries.l1y[outSeries.l1y.length - 1].v === 0) {
        // console.log(`for 1 year Returns current ${currentMonth} Month has 0 value`);
        const findIndex = _.findIndex(outSeries.l1y, ['d', currentMonth]);
        if (typeof findIndex !== 'undefined') {
          outSeries.l1y = outSeries.l1y.splice(0, findIndex);
        }
      }

      if (outSeries.l3y[outSeries.l3y.length - 1].d === currentMonth && outSeries.l3y[outSeries.l3y.length - 1].v === 0) {
        // console.log(`for 3 years Returns current ${currentMonth} Month has 0 value`);
        const findIndex = _.findIndex(outSeries.l3y, ['d', currentMonth]);
        if (typeof findIndex !== 'undefined') {
          outSeries.l3y = outSeries.l3y.splice(0, findIndex);
        }
      }

      if (outSeries.l5y[outSeries.l5y.length - 1].d === currentMonth && outSeries.l5y[outSeries.l5y.length - 1].v === 0) {
        // console.log(`for 5 years Returns current ${currentMonth} Month has 0 value`);
        const findIndex = _.findIndex(outSeries.l5y, ['d', currentMonth]);
        if (typeof findIndex !== 'undefined') {
          outSeries.l5y = outSeries.l5y.splice(0, findIndex);
        }
      }

      const ReturnActive1Year = outSeries.l1y.filter((u) => u.v !== 0);
      const ReturnActive3Years = outSeries.l3y.filter((u) => u.v !== 0);
      const ReturnActive5years = outSeries.l5y.filter((u) => u.v !== 0);

      // console.log(ReturnActive5years);

      if (ReturnActive1Year.length > 0) {
        // console.log('----- pfRet1y -----');
        // console.log('pfRet1y total months count', ReturnActive1Year.length);
        // console.log('lets updated series for 1 year ?', ReturnActive1Year.length, outSeries.l1y);
        const yearCouldBe = `${ReturnActive1Year[0].d} - ${ReturnActive1Year[ReturnActive1Year.length - 1].d}`; // ReturnActive1Year.length === 12 ? '1 year' : `${ReturnActive1Year[0].d} - ${ReturnActive1Year[ReturnActive1Year.length - 1].d}`;
        out.dropdown.l1y = {
          ...out.dropdown.l1y,
          year: yearCouldBe,
          disabled: false,
        };
        out.dropdown.l3y.disabled = true;
        out.dropdown.l5y.disabled = true;
        outSeries.l1y = ReturnActive1Year;
        // console.log(outSeries);
        // out.returnObj = outSeries;
      }

      if (ReturnActive3Years.length > 12) {
        // console.log('----- pfRet3y -----');
        // console.log('pfRet3y total months count', ReturnActive3Years.length);
        // console.log('lets update series for 3 years ?', ReturnActive3Years.length < outSeries.l3y.length,  ReturnActive3Years.length, outSeries.l3y.length);
        // console.log('lets update series for 3 years ?', ReturnActive3Years.length, outSeries.l3y.length);
        // const yearCouldBe = ReturnActive3Years.length === 36 ? '3 years' : `${ReturnActive3Years[0].d} - ${ReturnActive3Years[ReturnActive3Years.length - 1].d}`;
        // console.log(' for 3 years --> yearCouldBe -> ', yearCouldBe);
        if (ReturnActive3Years.length > 12 && ReturnActive3Years.length <= 36) {
          out.dropdown.l3y = {
            ...out.dropdown.l3y,
            disabled: false,
          };
        }
        out.dropdown.l3y = {
          ...out.dropdown.l3y,
          year: `${ReturnActive3Years[0].d} - ${ReturnActive3Years[ReturnActive3Years.length - 1].d}`,
        };
        out.dropdown.l5y.disabled = true;
        outSeries.l3y = ReturnActive3Years;
        // out.returnObj = outSeries;
      }

      if (ReturnActive5years.length > 36) {
        // console.log('----- pfRet5y -----');
        // console.log('pfRet5y total months count', ReturnActive5years.length);
        // console.log('lets update series for 5 years ?', ReturnActive5years.length < outSeries.l5y.length,  ReturnActive5years.length, outSeries.l5y.length);
        // console.log('lets update series for 5 years ?', ReturnActive5years.length, outSeries.l5y.length);
        // const yearCouldBe = ReturnActive5years.length === 60 ? '5 years' : `${ReturnActive5years[0].d} - ${ReturnActive5years[ReturnActive5years.length - 1].d}`;
        if (ReturnActive5years.length > 36 && ReturnActive5years.length <= 60) {
          // console.log(' for 5 years --> yearCouldBe -> ', yearCouldBe);
          out.dropdown.l5y = {
            ...out.dropdown.l5y,
            disabled: false,
          };
        }
        out.dropdown.l5y = {
          ...out.dropdown.l5y,
          year: `${ReturnActive5years[0].d} - ${ReturnActive5years[ReturnActive5years.length - 1].d}`,
        };
        outSeries.l5y = ReturnActive5years;
        out.returnObj = outSeries;
      }

      return out;
    }

    return out;
  } else {
    console.log('-- dropdownAndReturnSeries -> return series empty --');
    return null;
  }
}

export const dailyReturnSeries = ({ returns, fund = null, defaultYear = 'l3m' }) => {
  if (!returns || (returns && returns.length === 0) || typeof returns === 'undefined') return;
  let _returns = returns.map((u) => {
    let _obj = {
      ...u,
      m: moment(u.d).format('MM/YYYY'),
    };
    return _obj;
  });
  if (!_returns || (_returns && _returns.length === 0) || typeof _returns === 'undefined') return;
  const defaultSeries = JSON.parse(JSON.stringify(_returns));
  let updatedFundObject = fund, tickerStartDate = null; // '28 Aug 2014';
  if (defaultSeries && defaultSeries.length > 0 && typeof defaultSeries[0].d !== 'undefined') {
    let dropdown = {
      l1m: {
        label: '1 Month',
        value: 'l1m',
        disabled: false,
      },
      l3m: {
        label: '3 Months',
        value: 'l3m',
        disabled: false,
      },
      l6m: {
        label: '6 Months',
        value: 'l6m',
        disabled: false,
      },
      l1y: {
        label: '1 Year',
        value: 'l1y',
        disabled: false,
      },
      l3y: {
        label: '3 Years',
        value: 'l3y',
        disabled: false,
      },
      max: {
        label: 'Max',
        value: 'max',
        disabled: false,
      }
    };
    let outSeries = {};

    const currentDate = moment(new Date()).format('DD MMM YYYY');
    const commonDate = moment(new Date()).date(); // moment(defaultSeries[0].d).date();

    const start = moment(defaultSeries[defaultSeries.length - 1].d).date(commonDate);
    // console.log('=== start ===> ', start.format('DD MMM YYYY'));
    const end = moment(defaultSeries[0].d);
    // console.log('=== end ===> ', end.format('DD MMM YYYY'));

    outSeries = {
      start: start.format("MMM YYYY"),
      end: end.format("MMM YYYY"),
    };
    let cumlSeries = outSeries;
    const PortfolioDateRangeMoment = { start, end };

    // const calculatePoints = (series, period, endDate) => {
    //   let returns = JSON.parse(JSON.stringify(series))
    //   const range = {
    //     start: nameToDateRangeForDailyReturns(period, PortfolioDateRangeMoment).start._d,
    //     end: nameToDateRangeForDailyReturns(period, PortfolioDateRangeMoment).end._d,
    //   }
    //   return returns.filter((e) => {
    //     let dt = moment(e.d); // monthToDate(e.d)
    //     return dt.isAfter(moment(range.start)) && dt.isBefore(moment(range.end))
    //   })
    // }

    let defaultRange = nameToDateRangeForDailyReturns(defaultYear, PortfolioDateRangeMoment);

    Object.keys(dropdown).forEach((item, i) => {
      if (item) {
        defaultRange = nameToDateRangeForDailyReturns(item, PortfolioDateRangeMoment);
        let stats = calcDailyCumulativeReturns(defaultSeries, defaultRange, tickerStartDate);
        if (stats && stats.series) {
          const endDate = stats.series[stats.series.length - 1].d;
          cumlSeries = {
            ...cumlSeries,
            [`${item}`]: stats.series,
          }
          outSeries = {
            ...outSeries,
            [`${item}`]: stats.series.filter((k) => k.d !== 'Start'),
          };
        }
      }
    });

    let out = {
      outSeries,
      dropdown,
      cumlSeries,
    };

    if (outSeries &&
      Object.keys(outSeries).length > 0 &&
      (outSeries.l1y.length > 0) &&
      typeof outSeries.l1y[0].d !== 'undefined'
    ) {
      // check if current Month has 0 value... for 1m, 3m, 6m, 1 year, 3 years and 5 years.

      // console.log('=== currentDate ===> ', currentDate);
      // console.log(outSeries.l1m);
      if (outSeries.l1m && outSeries.l1m[outSeries.l1m.length - 1].d === currentDate && outSeries.l1m[outSeries.l1m.length - 1].v === 0) {
        // console.log(`for 1 month Returns current ${currentDate} Month has 0 value`);
        const findIndex = _.findIndex(outSeries.l1m, ['d', currentDate]);
        if (typeof findIndex !== 'undefined') {
          outSeries.l1m = outSeries.l1m.splice(0, findIndex);
        }
      }

      if (outSeries.l3m && outSeries.l3m[outSeries.l3m.length - 1].d === currentDate && outSeries.l3m[outSeries.l3m.length - 1].v === 0) {
        // console.log(`for 3 months Returns current ${currentDate} Month has 0 value`);
        const findIndex = _.findIndex(outSeries.l3m, ['d', currentDate]);
        if (typeof findIndex !== 'undefined') {
          outSeries.l3m = outSeries.l3m.splice(0, findIndex);
        }
      }

      if (outSeries.l6m && outSeries.l6m[outSeries.l6m.length - 1].d === currentDate && outSeries.l6m[outSeries.l6m.length - 1].v === 0) {
        // console.log(`for 6 months Returns current ${currentDate} Month has 0 value`);
        const findIndex = _.findIndex(outSeries.l6m, ['d', currentDate]);
        if (typeof findIndex !== 'undefined') {
          outSeries.l6m = outSeries.l6m.splice(0, findIndex);
        }
      }

      if (outSeries.l1y && outSeries.l1y[outSeries.l1y.length - 1].d === currentDate && outSeries.l1y[outSeries.l1y.length - 1].v === 0) {
        // console.log(`for 1 year Returns current ${currentDate} Month has 0 value`);
        const findIndex = _.findIndex(outSeries.l1y, ['d', currentDate]);
        if (typeof findIndex !== 'undefined') {
          outSeries.l1y = outSeries.l1y.splice(0, findIndex);
        }
      }

      if (outSeries.l3y && outSeries.l3y[outSeries.l3y.length - 1].d === currentDate && outSeries.l3y[outSeries.l3y.length - 1].v === 0) {
        // console.log(`for 3 years Returns current ${currentDate} Month has 0 value`);
        const findIndex = _.findIndex(outSeries.l3y, ['d', currentDate]);
        if (typeof findIndex !== 'undefined') {
          outSeries.l3y = outSeries.l3y.splice(0, findIndex);
        }
      }

      // if (outSeries.l5y && outSeries.l5y[outSeries.l5y.length - 1].d === currentDate && outSeries.l5y[outSeries.l5y.length - 1].v === 0) {
      //   // console.log(`for 5 years Returns current ${currentDate} Month has 0 value`);
      //   const findIndex = _.findIndex(outSeries.l5y, ['d', currentDate]);
      //   if (typeof findIndex !== 'undefined') {
      //     outSeries.l5y = outSeries.l5y.splice(0, findIndex);
      //   }
      // }

      const ReturnActive1Month = outSeries.l1m && outSeries.l1m.filter((u) => u.v !== 0) || [];
      const ReturnActive3Months = outSeries.l3m && outSeries.l3m.filter((u) => u.v !== 0) || [];
      const ReturnActive6Months = outSeries.l6m && outSeries.l6m.filter((u) => u.v !== 0) || [];
      const ReturnActive1Year = outSeries.l1y && outSeries.l1y.filter((u) => u.v !== 0) || [];
      const ReturnActive3Years = outSeries.l3y && outSeries.l3y.filter((u) => u.v !== 0) || [];
      // const ReturnActive5years = outSeries.l5y && outSeries.l5y.filter((u) => u.v !== 0) || [];
      let maxStats = { series: [] };
      if (ReturnActive1Month.length > 0) {
        const yearCouldBe = `${ReturnActive1Month[0].d} - ${ReturnActive1Month[ReturnActive1Month.length - 1].d}`; // ReturnActive1Month.length === 12 ? '1 year' : `${ReturnActive1Month[0].d} - ${ReturnActive1Month[ReturnActive1Month.length - 1].d}`;
        if(ReturnActive1Month.length < 22){
          out.dropdown.l1m = {
            ...out.dropdown.l1m,
            year: yearCouldBe,
            disabled: true,
          };
        } else {
          out.dropdown.l1m = {
            ...out.dropdown.l1m,
            year: yearCouldBe,
            disabled: false,
          };
        }
        out.dropdown.l3m.disabled = true;
        out.dropdown.l6m.disabled = true;
        out.dropdown.l1y.disabled = true;
        out.dropdown.l3y.disabled = true;
        // out.dropdown.l5y.disabled = true;
        outSeries.l1m = ReturnActive1Month;
        // maxStats.series = cumlSeries.l1m;
      }

      let s3months = ReturnActive3Months.map((k) => k.d && moment(k.d).format('MMM YYYY')).filter((k) => typeof k !== 'undefined');
      s3months = uniqBy(s3months);
      if (s3months.length > 2) {
        // const yearCouldBe = s3months.length === 4 ? '3 months' : `${ReturnActive3Months[0].d} - ${ReturnActive3Months[ReturnActive3Months.length - 1].d}`;
        // if (s3months.length > 2) {
        //   out.dropdown.l3m = {
        //     ...out.dropdown.l3m,
        //     disabled: false,
        //   };
        // }
        if(s3months.length <= 3){
          out.dropdown.l3m = {
            ...out.dropdown.l3m,
            disabled: true,
            year: `${ReturnActive3Months[0].d} - ${ReturnActive3Months[ReturnActive3Months.length - 1].d}`,
          };
        } else {
          out.dropdown.l3m = {
            ...out.dropdown.l3m,
            disabled: false,
            year: `${ReturnActive3Months[0].d} - ${ReturnActive3Months[ReturnActive3Months.length - 1].d}`,
          };
        }
        out.dropdown.l6m.disabled = true;
        out.dropdown.l1y.disabled = true;
        out.dropdown.l3y.disabled = true;
        // out.dropdown.l5y.disabled = true;
        outSeries.l3m = ReturnActive3Months;
        // maxStats.series = cumlSeries.l3m;
      }

      let s6months = ReturnActive6Months.map((k) => k.d && moment(k.d).format('MMM YYYY')).filter((k) => typeof k !== 'undefined');
      s6months = uniqBy(s6months);
      if (s6months.length > 4) {
        // const yearCouldBe = s6months.length === 7 ? '6 months' : `${ReturnActive6Months[0].d} - ${ReturnActive6Months[ReturnActive6Months.length - 1].d}`;
        // if (s6months.length > 4 && s6months.length <= 7) {
        //   out.dropdown.l6m = {
        //     ...out.dropdown.l6m,
        //     disabled: false,
        //   };
        // }
        if(s6months.length <= 6){
          out.dropdown.l6m = {
            ...out.dropdown.l6m,
            disabled: true,
            year: `${ReturnActive6Months[0].d} - ${ReturnActive6Months[ReturnActive6Months.length - 1].d}`,
          };
        } else {
          out.dropdown.l6m = {
            ...out.dropdown.l6m,
            disabled: false,
            year: `${ReturnActive6Months[0].d} - ${ReturnActive6Months[ReturnActive6Months.length - 1].d}`,
          };
        }
        out.dropdown.l1y.disabled = true;
        out.dropdown.l3y.disabled = true;
        // out.dropdown.l5y.disabled = true;
        outSeries.l6m = ReturnActive6Months;
        // maxStats.series = cumlSeries.l6m;
      }

      let s12months = ReturnActive1Year.map((k) => k.d && moment(k.d).format('MMM YYYY')).filter((k) => typeof k !== 'undefined');
      s12months = uniqBy(s12months);
      if (s12months.length > 7) {
        // const yearCouldBe = s12months.length === 12 ? '1 year' : `${ReturnActive1Year[0].d} - ${ReturnActive1Year[ReturnActive1Year.length - 1].d}`;
        if (s12months.length <= 12) {
          out.dropdown.l1y = {
            ...out.dropdown.l1y,
            disabled: true, // disbale 1 year
            year: `${ReturnActive1Year[0].d} - ${ReturnActive1Year[ReturnActive1Year.length - 1].d}`,
          };
        } else {
          out.dropdown.l1y = {
            ...out.dropdown.l1y,
            disabled: false,
            year: `${ReturnActive1Year[0].d} - ${ReturnActive1Year[ReturnActive1Year.length - 1].d}`,
          };
        }

        out.dropdown.l3y.disabled = true;
        // out.dropdown.l5y.disabled = true;
        outSeries.l1y = ReturnActive1Year;
        // maxStats.series = cumlSeries.l1y;
      }

      let s36months = ReturnActive3Years.map((k) => k.d && moment(k.d).format('MMM YYYY')).filter((k) => typeof k !== 'undefined');
      s36months = uniqBy(s36months);

      if (s36months.length > 13) {
        // const yearCouldBe = s36months.length === 36 ? '3 years' : `${ReturnActive3Years[0].d} - ${ReturnActive3Years[ReturnActive3Years.length - 1].d}`;
        if (s36months.length <= 36) {
          out.dropdown.l3y = {
            ...out.dropdown.l3y,
            disabled: true, // disbale 3 year
            year: `${ReturnActive3Years[0].d} - ${ReturnActive3Years[ReturnActive3Years.length - 1].d}`,
          };
        } else {
          out.dropdown.l3y = {
            ...out.dropdown.l3y,
            disabled: false,
            year: `${ReturnActive3Years[0].d} - ${ReturnActive3Years[ReturnActive3Years.length - 1].d}`,
          };
        }
        // out.dropdown.l5y.disabled = true;
        outSeries.l3y = ReturnActive3Years;
        // maxStats.series = cumlSeries.l3y;
      }

      // if (ReturnActive5years.length > 1095) {
      //   const yearCouldBe = ReturnActive5years.length === 1825 ? '5 years' : `${ReturnActive5years[0].d} - ${ReturnActive5years[ReturnActive5years.length - 1].d}`;
      //   if (ReturnActive5years.length > 1095 && ReturnActive5years.length <= 1825) {
      //     out.dropdown.l5y = {
      //       ...out.dropdown.l5y,
      //       disabled: false,
      //     };
      //   }
      //   out.dropdown.l5y = {
      //     ...out.dropdown.l5y,
      //     year: `${ReturnActive5years[0].d} - ${ReturnActive5years[ReturnActive5years.length - 1].d}`,
      //   };
      //   outSeries.l5y = ReturnActive5years;
      //   out.returnObj = outSeries;
      // }

      if (out.cumlSeries && out.cumlSeries.max && out.cumlSeries.max.length > 0 && out.outSeries && out.outSeries.max && out.outSeries.max.length > 0) {
        let _activeDropdown = {}, _inactiveDropdown = {};
        Object.keys(out.dropdown).map((l) => {
          if (!out.dropdown[l].disabled) {
            _activeDropdown[l] = out.dropdown[l];
          }
          if (out.dropdown[l].disabled) {
            _inactiveDropdown[l] = out.dropdown[l];
          }
        });
        if(out.dropdown && out.dropdown.l3y && out.dropdown.l3y.disabled === true){
          out.dropdown.max['disabled'] = false;
          out.dropdown.max['year'] = `${out.outSeries.max[0].d} - ${out.outSeries.max[out.outSeries.max.length - 1].d}`
        } else {
          out.dropdown.max['disabled'] = true;
        }


        // for Option: 3
        // out.dropdown = {
        //   ..._activeDropdown,
        //   max: {
        //     disabled: false,
        //     label: 'Max',
        //     value: 'max',
        //     year: `${maxStats.series[0].d} - ${maxStats.series[maxStats.series.length - 1].d}`,
        //   },
        //   ..._inactiveDropdown,
        // }

        out.cumlSeries.max = out.cumlSeries.max;
        out.outSeries = {
          ...out.outSeries,
          max: out.outSeries.max,
        };

      }

      console.log('========= out ==========');
      console.log(out);
      console.log('========================');

      return out;
    }

    return out;
  } else {
    console.log('-- dailyReturnSeries -> return series empty --');
    return null;
  }
}

export const lineChartStartDate = (e) => {
  if (e &&
    e.start_date &&
    e._returnsChart &&
    e.start_date !== '' &&
    e.start_date !== null &&
    typeof e.start_date !== 'undefined' &&
    typeof e._returnsChart.end !== 'undefined' &&
    typeof e._returnsChart.start !== 'undefined'
  ) {
    e._tickerStartDate = moment(e.start_date);
    // NOTE:  grisha changes
    // Show a wider popup with 2 lines:
    // Start 26 Jun 2019 - 30 Jun 2019 0.76% ("26" is the start_date and "30" is the month end date for each month)
    if (e._tickerStartDate.isValid()) {
      // NOTE: check if diff of 'e._tickerStartDate' & 'e._returnsChart.start' is less than or equal to month ==> then use 'e._tickerStartDate' DATE for month (START: e._tickerStartDate)
      // console.log(e._tickerStartDate.format('MMM YYYY'), e._returnsChart.start);
      // e._tickerStartDate.isSameOrAfter(moment(e._returnsChart.start).subtract(1, 'seconds'), 'date')
      // console.log(e._tickerStartDate.isSameOrBefore(moment(e._returnsChart.start), 'month'));
      if (e._tickerStartDate.isSameOrAfter(moment(e._returnsChart.start), 'month')) {
        // for.e.g: (e._tickerStartDate - JULY 2018 & e._returnsChart.start - AUG 2015
        // then use e._tickerStartDate - JULY 2018, exact date for { START: e._tickerStartDate } in line chart
        // console.log(`==== e._tickerStartDate -> ${e._returnsChart.start}, exact with day ====`);
        e._tickerStartDate = moment(JSON.parse(JSON.stringify(e._tickerStartDate))).format('DD MMM YYYY');
      } else if (e._tickerStartDate.isSameOrBefore(moment(e._returnsChart.start), 'month')) {
        // NOTE: check if diff of 'e._tickerStartDate' & 'e._returnsChart.start' is more than month ==> then use (START: 'e._returnsChart.start' month - 1)
        // for.e.g: (e._tickerStartDate - JULY 2014 & e._returnsChart.start - AUG 2018
        // then use { START: (e._returnsChart.start - AUG 2018) - 1 month } in line chart
        // console.log(`==== e._returnsChart.start ->  (${e._returnsChart.start} - 1 month) ====`);
        e._tickerStartDate = moment(JSON.parse(JSON.stringify(e._returnsChart.start))).add(1, 'seconds').subtract(1, 'month').format('MMM YYYY');
      }
    }
  }
  return e;
}

export const apexRedirectionWhereTo = ({ profile, push }) => {
  // console.log("---- apexRedirectionWhereTo ----");
  // if (profile && push && typeof profile !== 'undefined' && typeof push !== 'undefined') {
  if (profile && typeof profile !== 'undefined') {
    const selfRole = profile && profile.self_role;

    if (selfRole === 'Other/Curious' || selfRole === 'Individual Investor') {
      const apex_obj = profile && profile.apex;
      // console.log('is_obj', apex_obj && Object.keys(apex_obj).length === 0 && apex_obj.constructor === Object)
      const isApexEmpty = apex_obj && Object.keys(apex_obj).length === 0 && apex_obj.constructor === Object;

      if (isApexEmpty) {
        return `/accounts/apex/create`
      }

      if (!isApexEmpty && apex_obj) {
        const orders        = (profile) ? get(profile, 'apex.orders') : [];
        const tradeRequest  = (profile) ? get(profile, 'apex.trade_request') : [];
        const accoutNotActive = !profile.is_active || (profile && profile.is_active && profile.apex && profile.apex.account  && !profile.apex.account[0].is_active);
        // if (apex_obj.account[0] && apex_obj.account[0].status !== 'COMPLETE') {
        //   return `/accounts/apex/under-verification`;
        // }

        if (accoutNotActive && (orders && orders.length === 0) && (tradeRequest && tradeRequest.length === 0)) { // sid said to add profile.is_active
          return `/accounts/apex/under-verification`;
        }

        if ((orders && orders.length === 0) && (tradeRequest && tradeRequest.length === 0)) {
          return `/accounts/apex/empty-result`;
        }
      }

      let accountNo = profile && profile.apex && profile.apex.account  && profile.apex.account[0].account_no;
      if (accountNo && accountNo !== '') {
        const jObj = {
          portfolioType: 'My Portfolio',
          portfolioId: `MAGNIFI_${accountNo}`,
        }

        return routeToAccountsPage('My Portfolio', 'dashboard/summary', jObj);
      }

      return 'Not to apex route';
    } else {
      return 'Not to apex route';
    }
  } else {
    console.log('=== apexRedirectionWhereTo Empty Props ===');
    return null;
  }
}

export const routeToAccountsPage = (selectedPortfolioType, path, jObj) => {
  if (selectedPortfolioType && path && jObj) {
    let urlParam = [];
    for (var i in jObj){
      urlParam.push(encodeURI(i) + "=" + encodeURI(jObj[i]));
    }
    const newPath = `/accounts/${path}?${urlParam.join("&")}`;
    return newPath;
  }
}

export const apexPageState = ({ profile }) => {
  if (profile && typeof profile !== 'undefined') {
    const selfRole = profile && profile.self_role;

    if (selfRole === 'Other/Curious' || selfRole === 'Individual Investor') {
      const apex_obj = profile && profile.apex;
      const isApexEmpty = apex_obj && Object.keys(apex_obj).length === 0 && apex_obj.constructor === Object;

      let accountNo = (profile &&
                      profile.apex &&
                      profile.apex.account &&
                      profile.apex.account.length > 0 &&
                      profile.apex.account[0] &&
                      profile.apex.account[0].account_no &&
                      profile.apex.account[0].account_no !== '') ? profile.apex.account[0].account_no : null;

      let accountStatus = (profile &&
                          profile.apex &&
                          profile.apex.account &&
                          profile.apex.account.length > 0 &&
                          profile.apex.account[0] &&
                          profile.apex.account[0].status) ? profile.apex.account[0].status : null;

      if (isApexEmpty) {
        return {
          accountNo,
          allowToTrade: false,
          status: 'Create',
          name: 'Magnifi',
          portfolioId: null,
        };
      }

      if (!isApexEmpty && apex_obj) {
        const orders        = (profile) ? get(profile, 'apex.orders') : [];
        const tradeRequest  = (profile) ? get(profile, 'apex.trade_request') : [];

        if (!profile.is_active && accountStatus === 'REJECTED' && (orders && orders.length === 0) && (tradeRequest && tradeRequest.length === 0)) {
          return {
            accountNo,
            allowToTrade: false,
            name: 'Magnifi',
            portfolioId: null,
            status: 'Suspended',
          };
        }

        if (!profile.is_active && (orders && orders.length === 0) && (tradeRequest && tradeRequest.length === 0)) { // sid told to add profile.is_active
          return {
            accountNo,
            allowToTrade: false,
            name: 'Magnifi',
            portfolioId: null,
            status: 'Under Verification',
          };
        }

        if ((orders && orders.length === 0) && (tradeRequest && tradeRequest.length === 0)) {
          return {
            accountNo,
            allowToTrade: true,
            name: 'Magnifi',
            portfolioId: null,
            status: 'No Trade Placed',
          };
        }
      }

      if (accountNo && accountNo !== '') {
        return {
          accountNo,
          allowToTrade: true,
          name: 'Magnifi',
          portfolioId: `MAGNIFI_${accountNo}`,
          status: 'Active',
        };
      }

      return null;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export const isRetailUser = (selfRole) => (["","Other/Curious", "Individual Investor"].includes(selfRole) === true || selfRole === null);

export const domainRedirectForRetailAdvisor = ({
  selfRole,
  token,
  email,
}) => {
  // console.log('== self_role ==', selfRole);
  // check selfRole of a user
  // check url has subdomain or not
  let hostname = window.location.hostname.replace('http://', '').replace('https://', '').replace('www.', '').split(/[/?#]/)[0];
  let host = window.location.host;
  let protocol = window.location.protocol;
  let urlDomain = host.split(".");

  let subdomain = "";
  let isRetailUser = (["","Other/Curious", "Individual Investor"].includes(selfRole) === true || selfRole === null);

  // console.log(urlDomain);
  // console.log('== build ==', process.env.REACT_APP_SUB_DOMAIN);
  // console.log('== isRetailUser == ', isRetailUser);
  // console.log('== !token ==', !token);

  // // check if already loggedIn with different email then log-out
  // if (AuthUtils.getAuthToken().email && AuthUtils.getAuthToken().email !== '') {
  //   console.log('============= already LoggedIN =============');
  //   console.log('================ LOGGING OUT ===============');
  //   AuthUtils.deleteAuthToken();
  // }

  //only for localhost
  if (urlDomain.length && urlDomain.filter((ki) => ki.includes('localhost')).length > 0) {
    // show pop-up then redirect
    const domain = urlDomain.filter((ki) => ki.includes('localhost'))[0];
    if (process.env.REACT_APP_SUB_DOMAIN === 'advisor' && isRetailUser) {
      const redirectTo = `http://retail.${domain}/login?token=${token}&email=${email}`;
      // console.log('=== redirect to ===', redirectTo);
      return redirectTo;
    } else if (process.env.REACT_APP_SUB_DOMAIN === 'retail' && !isRetailUser) {
      const redirectTo = `http://advisor.${domain}/login?token=${token}&email=${email}`;
      // console.log('=== redirect to ===', redirectTo);
      return redirectTo;
    }
  } else { //only for live domains
    // show pop-up then redirect
    if (process.env.REACT_APP_SUB_DOMAIN === 'advisor' && isRetailUser) {
      const redirectTo = config.retail_redirect+`/login?token=${token}&email=${email}`;
      // console.log('=== redirect to ===', redirectTo);
      return redirectTo;
    } else if (process.env.REACT_APP_SUB_DOMAIN === 'retail' && !isRetailUser) {
      const redirectTo = config.advisor_redirect+`/login?token=${token}&email=${email}`;
      // console.log('=== redirect to ===', redirectTo);
      return redirectTo;
    }
  }
  // console.log('=== redirect to ===', null);
  return null;
}

export const numberCheck = (num, prec = 0) => {
  return ((typeof num !== 'undefined' &&
          num !== null) &&
          num !== '--' &&
          !isNaN(num) &&
          !isNaN(parseFloat(num).toFixed(prec))) ? (prec === 'all' ? Number(parseFloat(num)) : Number(parseFloat(num).toFixed(prec))) : 0;
}

export const checkTimeDiff = (requestedDate) => {
  if (requestedDate) {
    let timeDiff = momentTZ.duration(
      momentTZ(
        momentTZ.tz(
          momentTZ.utc(
            momentTZ()
          ).format(), "utc"
        ).format()
      ).diff(
        momentTZ(
          momentTZ.tz(
            requestedDate, "utc"
          ).format()
        )
      )
    ).asHours();
    // console.log('timeDiff =====> ', timeDiff);
    return timeDiff;
  }
  return false;
};

export const getClientDetailsForTotum = (advisorId, clientList, totumScore, portfolioName, isItRenderingSummaryPageContent = false) => {
  if (clientList && totumScore && portfolioName) {
    const clientDetails = clientList.find((item) => item.original_portfolio === portfolioName);
    const advisorName = clientDetails ? clientDetails.name : portfolioName;
    const advisorEmail = clientDetails ? clientDetails.email : '';
    const clientId = clientDetails ? clientDetails.id : null;
    // const clientId = (clientDetails && clientDetails.original_portfolio_id) ? clientDetails.original_portfolio_id : null;
    const { min = 0, max = 100, scoreMeter } = totumScore;
    let resendEmail = false;
    let orderedScoreMeter = _.orderBy(scoreMeter.filter((item) => item.value !== null), ['value'],['asc']);
    let allScoreMeter = orderedScoreMeter;

    // if (isItRenderingSummaryPageContent) {
    //   let currentScoreMeter = allScoreMeter.find((j) => j.key === 'current');
    //   if (currentScoreMeter && currentScoreMeter.value) {
    //     let __value = JSON.parse(JSON.stringify(currentScoreMeter.value));
    //     console.log('---- __value ----> ', __value);
    //     allScoreMeter = allScoreMeter.map((kl) => {
    //       if (kl.key === 'enhanced') {
    //         kl.value = __value;
    //       }
    //       return kl;
    //     });
    //     allScoreMeter = allScoreMeter.map((kl) => {
    //       if (kl.key === 'current') {
    //         kl.value = null;
    //       }
    //       return kl;
    //     });
    //     debugger
    //     console.log(allScoreMeter);
    //   }
    // }

    let renderScoreValues = orderedScoreMeter.map((item) => item.value); // [10, 34]
    let renderAllScoreValues = renderScoreValues;
    let showScoreMeter = (advisorId && advisorId !== null && advisorId !== '' && (clientDetails && clientDetails.totum_client_detials && clientDetails.totum_client_detials.length > 0));
    let totumClientDetails = showScoreMeter && clientDetails.totum_client_detials[clientDetails.totum_client_detials.length - 1] ? clientDetails.totum_client_detials[clientDetails.totum_client_detials.length - 1] : null;

    if (showScoreMeter &&
      totumClientDetails !== null &&
      typeof totumClientDetails.questionnaire_status !== 'undefined'
    ) {
      resendEmail = (totumClientDetails.questionnaire_status !== 'COMPLETED');
    }

    if (showScoreMeter &&
      totumClientDetails !== null &&
      totumClientDetails.risk_capacity_score &&
      totumClientDetails.risk_preference_score &&
      numberCheck(totumClientDetails.risk_capacity_score) &&
      numberCheck(totumClientDetails.risk_preference_score)
    ) {
      if (numberCheck(totumClientDetails.risk_preference_score) > numberCheck(totumClientDetails.risk_capacity_score)) {
        allScoreMeter = [{
          color: "#56a9e8",
          key: "capacity",
          label: "Risk Capacity",
          value: numberCheck(totumClientDetails.risk_capacity_score),
        }, ...allScoreMeter, {
          color: "#56a9e8",
          key: "preference",
          label: "Risk Preference",
          value: numberCheck(totumClientDetails.risk_preference_score),
        }];
        renderAllScoreValues = [
          numberCheck(totumClientDetails.risk_capacity_score),
          ...renderAllScoreValues,
          numberCheck(totumClientDetails.risk_preference_score),
        ];
      } else {
        allScoreMeter = [{
          color: "#56a9e8",
          key: "preference",
          label: "Risk Preference",
          value: numberCheck(totumClientDetails.risk_preference_score),
        }, ...allScoreMeter, {
          color: "#56a9e8",
          key: "capacity",
          label: "Risk Capacity",
          value: numberCheck(totumClientDetails.risk_capacity_score),
        }];
        renderAllScoreValues = [
          numberCheck(totumClientDetails.risk_preference_score),
          ...renderAllScoreValues,
          numberCheck(totumClientDetails.risk_capacity_score),
        ];
      }

      // NOTE: order by ascending
      allScoreMeter = _.orderBy(allScoreMeter.filter((item) => item.value !== null), ['value'],['asc'])

      // console.log(renderAllScoreValues);
      renderAllScoreValues = renderAllScoreValues.sort((a, b) => a - b);
      // console.log(renderAllScoreValues);
    }

    return {
      min,
      max,
      clientId,
      advisorName,
      resendEmail,
      advisorEmail,
      allScoreMeter,
      showScoreMeter,
      orderedScoreMeter,
      renderScoreValues,
      totumClientDetails,
      renderAllScoreValues,
    }
  }
  return {
    min: 0,
    max: 100,
    clientId: null,
    advisorName: '',
    advisorEmail: '',
    resendEmail: false,
    allScoreMeter: null,
    showScoreMeter: null,
    orderedScoreMeter: [],
    renderScoreValues: [],
    totumClientDetails: null,
  }
}

export const isElementOverlapping = (elem1, elem2) => {
  if (!elem1 || !elem2) return null;
  const div1 = elem1.getBoundingClientRect();
  const div2 = elem2.getBoundingClientRect();
  if (!div1 || !div2) return null;
  return (div1.right > div2.left &&
          div1.left < div2.right &&
          div1.bottom > div2.top &&
          div1.top < div2.bottom);
}

export const getPositionOfAnElement = ( element ) => {
  if (!element) return null;
  const rect = element.getBoundingClientRect();
  if (!rect) return null;
  return {
    x: rect.left,
    y: rect.top,
  };
}

export const prepareScoreMeterColorArray = ({ scoreMeter = [], }) => {
  let defaultColorsArray = ['#9f9f9f', '#9f9f9f', '#9f9f9f'];
  if (!scoreMeter || typeof scoreMeter === 'undefined' || scoreMeter.length < 2) return defaultColorsArray;

  let prepareColorArray = [];
  let scoreKeyOrders = scoreMeter.map((item) => item.key);

  try {
    // atleast scoreKeyOrders has =>> ['preference', 'capacity', 'current'] || ['preference', 'capacity', 'enhanced']
    if (scoreKeyOrders && scoreKeyOrders.length > 2) {

      scoreKeyOrders.forEach((item) => {
        if (item) {
          prepareColorArray.push('---');
          prepareColorArray.push(item);
        }
      });
      if (prepareColorArray && prepareColorArray.length > 1 && prepareColorArray[prepareColorArray.length - 1] !== '---') {
        prepareColorArray.push('---');
      }

      // NOTE: color array structure 'prepareColorArray', where '----' will holds color;
      // '---- |current| ---- |preference| ---- |current| ----'
      // '---- |current| ---- |capacity| ---- |preference| ----'
      // '---- |preference| ---- |current| ---- |enhanced| ---- |capacity| ----'

      // console.log('prepareColorArray->', prepareColorArray);

      let __indexOfPreference = prepareColorArray.indexOf('preference');
      let __indexOfCapacity = prepareColorArray.indexOf('capacity');

      if (__indexOfPreference !== -1 && __indexOfCapacity !== -1) {
        if (__indexOfPreference < __indexOfCapacity) { // ['preference', 'capacity']
          prepareColorArray.forEach((kl, i) => {
            if (kl === 'preference') {
              let __index = i;
              while (prepareColorArray[__index] !== 'capacity') {
                if (prepareColorArray[__index] === '---') {
                  prepareColorArray[__index] = '#56a9e8'
                }
                __index++
              }
            }
          });
        } else { // ['capacity', 'preference']
          prepareColorArray.forEach((kl, i) => {
            if (kl === 'capacity') {
              let __index = i;
              while (prepareColorArray[__index] !== 'preference') {
                if (prepareColorArray[__index] === '---') {
                  prepareColorArray[__index] = '#56a9e8'
                }
                __index++
              }
            }
          });
        }

        prepareColorArray = prepareColorArray.map((kl, i) => {
          if (kl === '---') {
            kl = '#9f9f9f';
          }
          return kl;
        }).filter((ye) => ye && ye !== 'preference' && ye !== 'capacity' && ye !== 'current' && ye !== 'enhanced');

        console.log('prepareColorArray->', prepareColorArray);
        if (prepareColorArray && prepareColorArray.length > 0) {
          return prepareColorArray;
        } else {
          throw `--- prepareColorArray is empty ---> , ${prepareColorArray}`
        }
      } else {
        throw `--- preference or capacity is missing  ---> , ${prepareColorArray}`
      }
    } else {
      throw `--- preference or capacity is missing ---> , ${prepareColorArray}`
    }
  } catch (e) {
    console.log(e);
    return defaultColorsArray;
  }
}

export const checkValidNumber = (num, prec = 0, suffix, handleReturn = '--') => {
  return ((typeof num !== 'undefined' &&
          num !== null) &&
          num !== '--' &&
          !isNaN(num) &&
          !isNaN(parseFloat(num).toFixed(prec))) ? (prec === 'all' ? Number(parseFloat(num)) : (suffix ? Number(parseFloat(num).toFixed(prec))+suffix : Number(parseFloat(num).toFixed(prec)))) : handleReturn;
}

export const openNotificationWithIcon = ({ type, message, description, ...props }) => {
  notification[type]({
    ...props,
    message,
    description,
  });
};

export const encryptPassword = (password) => {
  let _password = password || null;
  if (_password) {
    let key = CryptoJS.enc.Utf8.parse('[fGJq$4ZGC~jjZtF');
    let iv = CryptoJS.lib.WordArray.random(16);
    let ps = CryptoJS.AES.encrypt(_password, key, {
      iv: iv,
    });
    ps = iv.concat(ps.ciphertext).toString(CryptoJS.enc.Base64);
    return ps;
  }
  return _password;
}

export const isBurstChartDataValid = (chartData) => {
  if (chartData && typeof chartData !== 'undefined' && chartData.length > 0) {
    return (chartData.filter(l => l.sub && l.sub.length > 0).length > 0)
  }
  return false;
}

export const burstToPieChart = (burstChartData, fallBackData = {}) => {
  // from BURST ==>
  // [
  //   0: {n: "bonds", v: 0, sum: 99.52350823501449, sub: [{n: "AAMA Income", v: 98.1186070758802},…]}
  //   1: {n: "cash", v: 0, sum: 2.0165457094637453, sub: [{n: "AAMA Income", v: 1.3992668685980323},…]}
  //   2: {n: "commodities", v: 0, sum: 0,…}
  //   3: {n: "currencies", v: 0, sum: 3.9253401219417153e-7,…}
  //   4: {n: "derivatives", v: 0, sum: 0,…}
  //   5: {n: "equities", v: 0, sum: 0,…}
  //   6: {n: "other", v: 0, sum: -1.5400543370127235,…}
  // ]

  // to PIE chart ==>
  // {
  //   bonds: 99.52350823501449,
  //   cash: 2.0165457094637453,
  //   commodities: 0,
  //   currencies: 3.9253401219417153e-7,
  //   derivatives: 0,
  //   equities: 0,
  //   other: -1.5400543370127235,
  // }

  if (burstChartData && isBurstChartDataValid(burstChartData)) {
    let overlap = {};
    burstChartData.forEach(k => {
      overlap[`${k.n}`] = k.sum;
    });
    return overlap;
  }
  return fallBackData;
}

export default {
  mapDataFromSFMItems,
  customScroll,
  getFundsUpdatedPrice,
  accountsPageUpdatePrice,
  // executeDataMapping,
  getQueryItems,
  getCookie,
  dataMapForEnhancer,
  statsForPerformanceImpact,
  getDefaultEnhanceReplaceData,
  getFRRAttrData,
  getFRRAttrDataForModels,
  textCapitalize,
  replicaDataMapForEnhancer,
  replicaStatsForPerformanceImpact,
  prepareFundObject,
  prepareFRRAndChart,
  prepareHRSDataForFund,
  prepareSFMItemFundObject,
  portfolioDataToCSVUploadPortfolioFormat,
  getDiscoverFundsList,
  executeDiscoverDataMapping,
  capitalizeAll,
  dropdownAndReturnSeries,
  apexRedirectionWhereTo,
  domainRedirectForRetailAdvisor,
  CHECK_QUICK_TRADE,
  apexPageState,
  numberCheck,
  lineChartStartDate,
  checkTimeDiff,
  dailyReturnSeries,
  getClientDetailsForTotum,
  isElementOverlapping,
  getPositionOfAnElement,
  prepareScoreMeterColorArray,
  checkValidNumber,
  encryptPassword,
  openNotificationWithIcon,
  isBurstChartDataValid,
  burstToPieChart,
};

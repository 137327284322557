import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { Tabs, Tooltip } from 'antd';
import AccountDetails from './Elements/AccountDetails';
import ClientHoldings from './Elements/ClientHoldings';
import AssetAllocation from './Elements/AssetAllocation';
import AccountPerformance from './Elements/AccountPerformance';
import { clientDetailsRequest, pfHoldingsRequest, clearHoldingsRequest, clientChartDetailRequest } from 'actions/clientportfolioV2';
import { ShowSpinner } from '../Shared/ShowSpinner';
import { Menu, Dropdown, Button } from "antd";
import { createLoadingSelector } from 'selectors/loading-selector';
import "antd/dist/antd.css";
import { setEnhancerState, getPortfolioDataByIDRequest } from 'actions/enhancer';
import { addEventToAnalytics } from 'Utils';

const { TabPane } = Tabs;

class ClientDetailsModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      closeCDModal: true,
      selectedTabIndex: '1',
      // searchAccTickerIndex:0,
      // query_var: props.query_var || {},
    }
  }

  componentWillMount() {
    // const { selectedClientDet, selectedClientDet : { id } ,query_var} = this.props;
    // this.props.clientDetailsRequest({client_id: id,query_var:query_var})
    // this.props.clientChartDetailRequest({account_id:id});
    //this.getClientPfDetails(selectedClientDet);
    this.setPaddingOfDetailSection();
    this.setHeightOfDetailSecion();
    this.setState({
      selectedTabIndex: '1'
    })
  }

  setPaddingOfDetailSection = () => {
    let header = document.getElementsByClassName('infinite-list-header');
    if (header.length) {
      let clientDetailDiv = document.getElementsByClassName('client-detail-section')
      //let hightlightContainer=document.getElementsByClassName('highlight-ticker')
      let tableBodyheight = document.getElementsByClassName('infinite-list-wrapper')
      // let addAccountMargin= document.getElementsByClassName('imp-exp-opt')
      if (clientDetailDiv.length) {
        clientDetailDiv[0].style.paddingTop = header[0].clientHeight - 1 + 'px';
      }
      if (tableBodyheight && tableBodyheight.length) {
        tableBodyheight[0].style.height = 'calc(98vh - ' + (300 + header[0].clientHeight + 55) + 'px )';
      }
      // if(hightlightContainer.length){
      //   tableBodyheight[0].style.maxHeight= 'calc(100vh - '+(300 + header[0].clientHeight + 60 )+'px )';
      //   //addAccountMargin[0].style.marginTop=0+'px';
      // }
    }
  }

  setHeightOfDetailSecion = () => {
    let _clientHeaderHeight = document.getElementsByClassName('client-header');
    let _scrollContainer = document.getElementsByClassName('container-scroll');
    if (_clientHeaderHeight.length && _scrollContainer.length) {
      _scrollContainer[0].style.height = 'calc(100% - ' + (_clientHeaderHeight[0].clientHeight + 10) + 'px )';
    }
  }

  componentDidUpdate(prevProps) {
    // Need to add update cycle to call component on calling row selection of Table
    // const { selectedClientDet, selectedClientDet : { id },query_var,searchAccTickerIndex } = this.props;
    // const { searchAccTickerIndex, query_var } = this.props;
    this.setPaddingOfDetailSection();
    this.setHeightOfDetailSecion();
    // if (id !== prevProps['selectedClientDet'].id) {
    //     this.props.clientDetailsRequest({client_id: id,query_var:query_var});
    //     this.props.clientChartDetailRequest({account_id:id});
    //    // this.getClientPfDetails(selectedClientDet);
    //     this.setState({
    //       selectedTabIndex:'1'
    //     })
    // }
    // if(searchAccTickerIndex!==prevProps['searchAccTickerIndex']){
    //   this.setState({
    //     searchAccTickerIndex:searchAccTickerIndex
    //   })
    // }

    // if(query_var!==prevProps['query_var']){
    //   this.setState({
    //     query_var:query_var
    //   })
    // }
  }

  onChange = activeKey => {
    this.setState({ selectedTabIndex: activeKey })
  };

  getClientPfDetails = (cd) => {
    // cd => client Details
    let { model_portfolio: mp, custom_portfolio: cp } = cd;
    if (mp.ticker) {
      this.props.pfHoldingsRequest({ ticker: mp.ticker });
    } else if (cp.portfolio_name) {
      this.props.pfHoldingsRequest({ file_name: cp.portfolio_name });
    } else {
      this.props.clearHoldingsRequest();
    }
  }

  redirectPortfolioEnhancementPage = (pfObj, _row) => {
    const { history, setEnhancerState, getPortfolioDataByIDRequest } = this.props;
    const selectedPortfolio = (_row && _row.model_portfolio && Object.keys(_row.model_portfolio).length > 0) ? _row.model_portfolio : {};

    if (setEnhancerState && pfObj && pfObj.pfName && pfObj.pfName !== '' && pfObj.type && _row.original_portfolio_id) {
      getPortfolioDataByIDRequest({
        filename: pfObj.pfName,
        portfolioType: 'Client Portfolio',
        allocatedModel: (selectedPortfolio && selectedPortfolio.ticker) ? true : false, // NOTE: REQUIRED FIELD
        _for: 'REDIRECTING'
      }, (res) => {
        if (res && res.tickers) {
          const { tickers } = res;
          if (history && tickers && tickers.length) {
            // if (Object.keys(currentPerformanceImpact).length > 0) {
            setEnhancerState({
              portfolioId: _row.original_portfolio_id,
              redirectFrom: {
                module: 'go',
                portfolioId: _row.original_portfolio_id,
                portfolioName: pfObj.pfName,
                type: pfObj.type,
                payload: {
                  clientDetails: _row
                },
                link: '/advisor/accountV2',
              }
            });
            const jObj = {
              "portfolioType": 'Client Portfolio',
              "portfolioId": pfObj.pfName,
              // "universe": fundUniverse,
              // "similarity-measures": similarityMeasures,
              // "q": 'similar',
              // "tickers": tickers,
              "callbackPath": "clientV2",
            };
            let urlParam = [];
            for (var i in jObj) {
              urlParam.push(encodeURI(i) + "=" + encodeURI(jObj[i]));
            }
            // const newPath = `/enhancer/dashboard/funds?${urlParam.join("&")}`;
            // summary?portfolioType=My%20Portfolio&portfolioId=MyPortfolio_16
            const newPath = `/enhancer/dashboard/summary?${urlParam.join("&")}`;
            history.push(newPath);
            // }
          }
        } else {
          console.log('-- tickers missing --');
        }
      });
    }
  }

  getPfDetails = (obj) => {
    if (obj && obj.model_portfolio && Object.keys(obj.model_portfolio).length && obj.model_portfolio.ticker) {
      return {
        pfName: obj.model_portfolio.ticker,
        type: 'model_portfolio'
      }
    } else if (obj && obj.custom_portfolio && Object.keys(obj.custom_portfolio).length && obj.custom_portfolio.portfolio_name) {
      return {
        pfName: obj.custom_portfolio.portfolio_name,
        type: 'custom_portfolio'
      }
    } else {
      return {
        pfName: obj.original_portfolio,
        type: 'custom_portfolio'
      }
    }
  }

  redirectToEnhancer = (client) => {
    const { downloadedList } = this.props;
    if (downloadedList && downloadedList.data && downloadedList.data.length > 0) {
      // NOTE: For redirection we have use 'Client' from 'Downloaded List' not from 'selectedClientDetails' state.
      // using 'selectedClientDetails' as Client cause api to fall 400 error.
      const _foundClient = downloadedList.data.find((kl) => kl.id === client.id);
      // console.log('client --> ', _foundClient);
      if (_foundClient) {
        const pfObj = this.getPfDetails(_foundClient);
        console.log('pfObj --> ', pfObj)
        addEventToAnalytics('Client Enhance', 'Client Enhance', 'CLIENT_ENHANCE', { client_id: client.id }, false)
        this.redirectPortfolioEnhancementPage(pfObj, _foundClient);
      } else {
        console.log('--- client not found in downloadedList ---');
      }
    } else {
      console.log('--- data not found ---');
    }
  }

  render() {
    const {
      selectedClientDet,
      // closeDetailsModal,
      selectedClientDetails,
      // selectedClientHoldings: { holdings: pfHoldings },
      // searchAccTicker,
      query_var,
      clientDeleteCallBack,
      restrictedTickerCallBack,
      loadingClientDetails,
      searchAccTickerIndex,
    } = this.props;

    let client = selectedClientDet || selectedClientDetails;

    const handleMenuClick = (e) => {
      if (e && e.key === '1') {
        if (clientDeleteCallBack) {
          clientDeleteCallBack(client);
        }
        // this.props.clientDeleteCallBack(client);
      } else if (e && e.key === '2') {
        if (restrictedTickerCallBack) {
          restrictedTickerCallBack(client)
        }
        // this.props.restrictedTickerCallBack(client);
      }
    }

    const renderMenus = (
      <Menu onClick={(e) => handleMenuClick(e)}>
        <Menu.Item key="1" icon={<img src={'/assets/images/TrashIcon.svg'} />}>
          Delete Account
        </Menu.Item>
        <Menu.Item key="2" icon={<img src={'/assets/images/edit.svg'} />}>
          Restricted Ticker
        </Menu.Item>
      </Menu>
    );

    // console.log('client ---- >', client);

    // const selectedModel = getModelName(client);

    //let isPfHoldingExists = selectedModel == 'Allocation Pending' ? true : ( pfHoldings && pfHoldings.length > 0 ) ? true : false
    return (
      <div className='client-detail-section-name' style={{ height: '100%' }}>
        <div className="cl-dt-header client-header">
          <div className="client-header__left-section">
            <h3>{client.name}</h3>
            <div className="client-header-icon">
              <Tooltip placement="top" title='Take this Account to Enhancer!'>
                <img src='/assets/images/icons/Enhancer_Icon.svg' onClick={() => { this.redirectToEnhancer(client) }} />
              </Tooltip>
            </div>
          </div>
          <div className="client-header__right-section">
            <Dropdown.Button
              trigger={'click'}
              overlay={renderMenus}
              icon={<img src={'/assets/images/dropdownIcon.svg'} />}
            />
          </div>
        </div>
        {(!loadingClientDetails && typeof selectedClientDetails.holdings !== 'undefined') ? (
          <div className={'container-scroll'}>
            <Tabs activeKey={this.state.selectedTabIndex} onChange={(activeKey) => this.onChange(activeKey)}>
              <TabPane tab="Holdings" key="1">
                <ClientHoldings
                  {...this.props}
                  qv={query_var}
                  client_id={selectedClientDetails.id}
                  searchAccTickerIndex={searchAccTickerIndex}
                />
              </TabPane>
              <TabPane tab="Model Allocation" key="2">
                <AccountDetails
                  client={selectedClientDetails}
                  getModelName={this.getModelName}
                />
              </TabPane>
              <TabPane tab="Benchmark" key="3">
                <AssetAllocation />
              </TabPane>
            </Tabs>
            <AccountPerformance />
          </div>
        ) : (
          <ShowSpinner />
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ loading, clientportfolioV2: { selectedClientDetails, selectedClientHoldings, query_var, downloadedList }, enhancer }) => ({
  downloadedList,
  selectedClientDetails,
  selectedClientHoldings,
  query_var,
  loadingClientDetails: createLoadingSelector(['GET_SELECTED_CLIENT_DETAILS'])({ loading }),
  // similarityMeasures: enhancer.similarityMeasures,
  // fundUniverse: enhancer.fundUniverse,
  // currentPerformanceImpact: enhancer.performanceImpact.currentPerformanceImpact || {}
})

const mapDispatchToProps = {
  clientChartDetailRequest,
  pfHoldingsRequest,
  clearHoldingsRequest,
  clientDetailsRequest,
  setEnhancerState,
  getPortfolioDataByIDRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClientDetailsModal));

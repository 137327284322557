import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
import classnames from 'classnames'

function OSCheckbox(props) {
  const { className, ...rest } = props
  const styleList = classnames(
    {
      'os-checkbox': true
    },
    className
  )
  return <Input type="checkbox" {...rest} className={styleList} />
}

export default OSCheckbox

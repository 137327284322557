import React, { useContext } from 'react'
import { Row, Col } from 'reactstrap';
import { withHeaderFooter } from '../shared/HeaderFooter';
// import { FormatCurrency } from '../shared/Utils';
import NameSponsor from './Common';
import { PercentChartWrap } from '../shared/Charts';
import _ from 'lodash';
import ConclusionCompareOnly from './ConclusionCompareOnly';
import ActReportContext from 'components/reportsV2/ActReportContext';
import BestTickerBadge from '../shared/BestTickerBadge';

const RelFundsPerf = (props) => {
  const { cpChartData, cpItems, cpFunds, isCompareOnly, showHeaderFooter } = props;
  const reportData = useContext(ActReportContext);
  const { bestTicker, printSelection } = reportData;
  const cutOff = cpItems.length > 10 ? 12 : 10;
  const items = cpItems.slice(0, cutOff);
  const otherItems = cpItems.slice(cutOff, cpItems.length);
  const otherItemsChunk = _.chunk(otherItems, 12);
  const totalItemsChunk = otherItemsChunk.length;
  const conclusionPosition = cpItems.length > 5 ? items.length < 11 ? 1 :
    otherItemsChunk && otherItemsChunk.length && otherItemsChunk[totalItemsChunk - 1].length < 13 ? 2 : 3 : 0;

  const reqData = {
    cpChartData,
    cpItems: items,
    cpFunds,
    isCompareOnly,
    conclusionPosition
  }
  return (
    <>
      <RelFundsPerfContent
        bestTicker={bestTicker}
        showHeaderFooter={showHeaderFooter}
        printSelection={printSelection}
        pageName={(isCompareOnly && conclusionPosition === 1) ?
          `${printSelection.includes('high-rel-funds') ? 'high-rel-funds' : ''},${printSelection.includes('conclusion') ? 'conclusion' : ''}` : `${printSelection.includes('high-rel-funds') ? 'high-rel-funds' : ''}`}
        {...reqData}
      />
      {otherItemsChunk.length > 0 && (printSelection.includes('high-rel-funds') || printSelection.includes('conclusion')) &&
        otherItemsChunk.map(item => {
          return (
            <HighestScoringFunds
              cpItems={item}
              printSelection={printSelection}
              bestTicker={bestTicker}
              pageName={(isCompareOnly && conclusionPosition === 2) ?
                `${printSelection.includes('high-rel-funds') ? 'high-rel-funds' : ''},${printSelection.includes('conclusion') ? 'conclusion' : ''}` : `${printSelection.includes('high-rel-funds') ? 'high-rel-funds' : ''}`}
              {...reqData}
            />
          )
        })
      }
      {(isCompareOnly && conclusionPosition === 3) && printSelection.includes('conclusion') &&
        <ConclusionCompare
          bestTicker={bestTicker}
          pageName={'conclusion'} />
      }
    </>
  )
}

export default RelFundsPerf;

const RelFundsPerfContent = withHeaderFooter(({ printSelection, cpChartData, cpItems, cpFunds, isCompareOnly, conclusionPosition, bestTicker }) => {
  return <div className="content">
    <div className="secWrapper">
      {printSelection.includes('high-rel-funds') && <div className="pr-v2-relevant-funds">
        <div className="pr-v2-header">
          <h3>Highest Scoring Relevant Investment Options</h3>
        </div>
        <div>
          <p>Diligence score*</p>
        </div>
        <div>
          <HighestScoringFunds
            printSelection={printSelection}
            bestTicker={bestTicker}
            cpChartData={cpChartData}
            cpItems={cpItems}
            cpFunds={cpFunds}
            showHeaderFooter={false}
          />
        </div>
      </div>}
      {(isCompareOnly && conclusionPosition === 1) && printSelection.includes('conclusion') && <ConclusionCompare showHeaderFooter={false} />}
    </div>
  </div>

});

const HighestScoringFunds = withHeaderFooter((props) => {
  const { printSelection, cpChartData, cpItems, cpFunds, isCompareOnly, conclusionPosition, bestTicker } = props;
  return printSelection.includes('high-rel-funds') && (
    <div className="content">
      <div className="rel-results">
        <Col xs="12">
          {cpItems.map((item, i) => {
            const chartData = cpChartData ? cpChartData[0] : null;
            const details = chartData ? chartData.filter((it) => it.name === item.ticker_short)[0] : null;
            const fundColor = details ? (details.color ? details.color : null) : null;
            const fundColorNew = i === 0 ? '#00a700' : '#f5a20a';
            const color = fundColor || fundColorNew;
            return (
              <Row key={i} className="result-row">
                <Col xs="3" className="fund-symbol text-center">
                  <p className="mb-0">
                    {item.ticker_short}
                    {(item.ticker_short && item.ticker_short === bestTicker) && (
                      <BestTickerBadge
                        style={{
                          paddingLeft: '12px',
                          top: '2px',
                          position: 'absolute',
                        }}
                      />
                    )}
                  </p>
                  {/* <p className="mb-0">{FormatCurrency(item.nav, 2)}</p> */}
                </Col>
                <Col xs="5" className="fund-name">
                  <NameSponsor item={item} name={item.name} sponsor={item.sponsor} />
                </Col>
                <Col xs="4" className="fund-gauge text-center">
                  <PercentChartWrap width={130} value={cpFunds[i].score} size={'md'} color={color} />
                </Col>
              </Row>
            )
          })}
        </Col>
      </div>
      {/* {(isCompareOnly && conclusionPosition === 2) && printSelection.includes('conclusion') &&
        <ConclusionCompare showHeaderFooter={false} />
      } */}
    </div>
  )
});

const ConclusionCompare = withHeaderFooter((props) => {
  return (
    <div className="content">
      <div className="secWrapper" style={{ marginTop: '50px' }}>
        <ConclusionCompareOnly />
      </div>
    </div>
  )
})

import React from 'react'
import { withHeaderFooter } from './HeaderFooter'
import { SwapOutlined } from '@ant-design/icons'
import { kmb, sponsorLogo } from 'DataSet'
// import { StackedAreaChart } from '../shared/Charts';
// import { getSummaryContent } from '../shared/Utils'
// import PortfolioContext from '../PortfolioContext'
import style from '../ItemCard.module.scss'

const AllReplacement = withHeaderFooter((props) => {
  let {
    // allTicker,
    list,
    queryEnglish,
    positions,
    first,
    last,
    index,
    // replacementChosen,
    noReplacementChosen = [],
    noReplacementFound = [],
  } = props;
  // console.log({ positions });
  // console.log({ list });
  // console.log(props);
  // {queryEnglish ? `'${queryEnglish}'` : 'your selection'}
  // console.log({ noReplacementChosen })
  // console.log({ noReplacementFound })
  return (
    <React.Fragment>
      {list && <div className="content">
        {first && <div className="pr-v2-header">
          <h3>We attempted to enhance your portfolio through <b>'{queryEnglish}'</b> to your holdings </h3>
        </div>}
        <div className="pr-v2-ex-summary">
          {first && list.length > 0 && <div>
            <h5 className={style.titleText}>The current holdings and suggested replacements are presented below</h5>
          </div>}
          <div>
            {list.length > 0 && <table className={style.replacementTable}>
              <tr>
                <td className={style.index}/>
                <td className={style.current}>Current Holdings</td>
                <td className={style.swap}/>
                <td className={style.replacement}>Suggested Replacements</td>
              </tr>
              {list.map((d, i) => {
                return (d.selected && d.selected.ticker) && (
                  <tr className={style.row} key={i}>
                    <td
                      className={style.index}>{(8 * index) + i + 1 < 10 ? `0${(8 * index) + i + 1}` : (8 * index) + i + 1}</td>
                    <td>
                      <TickerCardSmall
                        name={d.target_card && d.target_card.cardInfo && d.target_card.cardInfo.short_name}
                        ticker_short={d.target_card && d.target_card.cardInfo && (d.target_card.cardInfo.ticker_short || d.target_card.cardInfo.ticker)}
                        sponsor={d.target_card && d.target_card.cardInfo && d.target_card.cardInfo.sponsor}
                        holdingPrice={d.target_card && d.target_card.cardInfo && positions[d.target_card.cardInfo.ticker_short] && positions[d.target_card.cardInfo.ticker_short].price}
                        holdingPercent={d.target_card && d.target_card.cardInfo && positions[d.target_card.cardInfo.ticker_short] && positions[d.target_card.cardInfo.ticker_short].weight}
                      />
                    </td>
                    <td><SwapOutlined className="swap-icon-wrapper"/></td>
                    <td>
                      <TickerCardSmall
                        name={d.selected && d.selected.cardInfo && d.selected.cardInfo.short_name}
                        ticker_short={d.selected && d.selected.cardInfo && d.selected.cardInfo.ticker_short || d.selected && d.selected.cardInfo && d.selected.cardInfo.ticker}
                        sponsor={d.selected && d.selected.cardInfo && d.selected.cardInfo.sponsor}
                      />
                    </td>
                  </tr>
                )
              })}
            </table>}
            {last && noReplacementChosen && noReplacementChosen.length > 0 && noReplacementFound && noReplacementFound.length > 0 && (
              <p className={style.noReplaceText}>
                {noReplacementChosen.length === 1 ? 'Fund' : 'Funds'} for which no {noReplacementChosen.length === 1 ? 'replacement' : 'replacements'} {noReplacementChosen.length === 1 ? 'was' : ' were'} selected {noReplacementChosen.length === 1 ? ' is' : 'are'} {noReplacementChosen.map((ticker, i) => `${ticker.cardInfo && ticker.cardInfo.short_name} (${ticker.cardInfo && ticker.cardInfo.ticker})${i < noReplacementChosen.length - 1 ? ', ' : ''}`)} & {noReplacementFound.length === 1 ? 'fund' : 'funds'} for which no {noReplacementFound.length === 1 ? 'replacement' : 'replacements'} {noReplacementFound.length === 1 ? 'was' : ' were'} found {noReplacementFound.length === 1 ? ' is' : ' are'} {noReplacementFound.map((ticker, i) => `${ticker.cardInfo && ticker.cardInfo.short_name} (${ticker.cardInfo && ticker.cardInfo.ticker})${i < noReplacementFound.length - 1 ? ', ' : '.'}`)}
              </p>
            )}
            {(last && noReplacementChosen && noReplacementChosen.length > 0 && noReplacementFound && noReplacementFound.length === 0) && (
              <p className={style.noReplaceText}>
                {noReplacementChosen.length === 1 ? 'Fund' : 'Funds'} for which no {
                  noReplacementChosen.length === 1 ? 'replacement' : 'replacements'} {
                  noReplacementChosen.length === 1 ? 'was' : 'were'} selected {
                  noReplacementChosen.length === 1 ? ' is' : 'are'} {
                  noReplacementChosen.map((h, i) => `${
                    h.targetCard && h.targetCard.cardInfo && h.targetCard.cardInfo.short_name
                  } (${h.targetCard.cardInfo && h.targetCard.cardInfo.ticker})${i < noReplacementChosen.length - 1 ? ', ' : '.'}`
                )}
              </p>
            )}
            {last && noReplacementFound.length > 0 && noReplacementChosen.length === 0 && (
              <p className={style.noReplaceText}>
                {noReplacementFound.length === 1 ? 'Fund' : 'Funds'} for which no {
                  noReplacementFound.length === 1 ? 'replacement' : 'replacements'} {
                  noReplacementFound.length === 1 ? 'was' : 'were'} found {
                  noReplacementFound.length === 1 ? ' is' : 'are'} {
                  noReplacementFound.map((h, i) => `${
                    h.targetCard && h.targetCard.cardInfo && h.targetCard.cardInfo.short_name
                  } (${h.targetCard && h.targetCard.cardInfo && h.targetCard.cardInfo.ticker})${i < noReplacementFound.length - 1 ? ', ' : '.'}`
                )}
              </p>
            )}
            {(last && props.inValidTicker && typeof props.inValidTicker !== 'undefined' && props.inValidTicker !== null && props.inValidTicker.length > 0) && (
              <p className={style.excludeTickerStyle}>Unrecognized Securities: {props.inValidTicker}</p>
            )}
            {(last && props.tickerNotSupported && typeof props.tickerNotSupported !== 'undefined' && props.tickerNotSupported !== null && props.tickerNotSupported.length > 0) && (
              <p className={style.excludeTickerStyle}>Unsupported Securities: {props.tickerNotSupported}</p>
            )}
          </div>
        </div>
      </div>}
    </React.Fragment>
  )
})

export default AllReplacement

function TickerCardSmall({ i, name, sponsor, ticker_short, holdingPrice, holdingPercent }) {
  return (
    <div className={style.tickerCardSmall}>
      <img className={style.sponsorLogo} src={sponsorLogo(sponsor)} alt={''}/>
      <span className={style.name}>{name}</span>
      <span className={style.ticker}>{ticker_short}
      <span>{holdingPrice ? ` | ${kmb(holdingPrice)}` : null}</span>
      <span>{holdingPercent ? ` | ${parseFloat(holdingPercent).toFixed(2)}%` : null}</span>
      </span>

    </div>
  )
}

import React, { useEffect, useState } from "react"
import { addEventToAnalytics } from 'Utils';
import './_index.scss';

const Violator = ({ profile, trendingSearches, loadingProfileData, violatorQueryHandler }) => {
    const mainObject = trendingSearches && trendingSearches.violatorSearches.data ? trendingSearches.violatorSearches.data : {};
    const violator = Object.keys(mainObject);

    const [longViolatorData, setLongViolatorData ] = useState([])
    let counter = 0;
    useEffect(() => {
        if(!counter && mainObject){
            if (longViolatorData.length < 1) {
                const tmp = []
                for ( let i = 0; i < 10; i++ ) {
                    tmp.push(...violator)
                }
                setLongViolatorData(tmp)
                counter =1;
            }
        }
    }, [violator])

    const queryHandler = (link, violator) => {
      addEventToAnalytics('Violator Click','Violator Click','VIOLATOR_CLICK', { violator }, true);
      // if (profile && profile.apex) {
      //   const apex_obj = profile.apex;
      //   const isApexEmpty = apex_obj && Object.keys(apex_obj).length === 0 && apex_obj.constructor === Object;
      //   if (isApexEmpty) {
      //     // if apex object is empty or not present then open link
      //     if(link){
      //       addEventToAnalytics('Scrolling Query Click','Scrolling Query Click','SCROLLING_QUERY_CLICK',{});
      //       window.open(
      //         link,
      //         '_blank'
      //       );
      //     }
      //   }
      // } else {
      //   // if apex key not present then open link
      //   if(link){
      //     addEventToAnalytics('Scrolling Query Click','Scrolling Query Click','SCROLLING_QUERY_CLICK',{});
      //     window.open(
      //       link,
      //       '_blank'
      //     );
      //   }
      // }
      if (violatorQueryHandler) {
        violatorQueryHandler(link);
      }
    }

    return (
        <div className="violator">
            {(longViolatorData && longViolatorData.length && !loadingProfileData)
                ? (
                    <div className="marq-container">
                        <div className="marq">
                            <div>
                                {
                                    longViolatorData.map((v, i) =>
                                        <span 
                                            onClick={() => queryHandler(mainObject[v].link, mainObject[v].violator)} 
                                            key={`${mainObject[v].violator}${i}v1`} 
                                            className={`marq-text1 ${mainObject[v].link ? '' : 'disabled'}`} 
                                        >
                                            {mainObject[v].violator}
                                        </span>
                                    )
                                }
                                {
                                    longViolatorData.map((v, i) =>
                                        <span 
                                            onClick={() => queryHandler(mainObject[v].link, mainObject[v].violator)}
                                            key={`${mainObject[v].violator}${i}v2`} 
                                            className={`marq-text1 ${mainObject[v].link ? '' : 'disabled'}`} 
                                        >
                                            {mainObject[v].violator}
                                        </span>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
                : null
            }
        </div>
    )
}

export default Violator;

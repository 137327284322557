import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
// import { Alert } from 'antd'
import { Formik } from 'formik'
import qs from 'query-string'
import * as Yup from 'yup'
import { get } from 'lodash'
import * as AuthUtils from 'common/auth-utils'

import {
  logout,
  setAuthState,
  failedGoogleAuth,
  refreshAuthState,
  auth as authLogin,
  oktaAuthLoginRequest,
  autoLoginByTokenRequest,
  oktaChangePasswordRequest,
  resendPasswordResetEmailRequest,
} from 'actions/auth'
import { registerGoogle, setRegisterState, registerProfileData } from 'actions/register'
import { createLoadingSelector } from 'selectors/loading-selector'
import { isTermsAgreed } from 'selectors/auth-selector'

import { Spinner } from 'shared-components'
import * as AuthApis from 'apis/auth'

// import { storeAuthToken } from 'common/auth-utils'
import { addEventToAnalytics, searchUrlV3 } from 'Utils'
import { REDIRECT_DOMAIN_PATH } from 'DataSet'
import TifinWealthMessage from 'components/TifinWealthMessage'

import LoginForm from './LoginForm'
import SelectDomainPopup from './SelectDomainPopup'
import OktaChangePasswordModal from '../Elements/OktaChangePasswordModal'

const magnifiLogo = '/assets/images/magnifi_by_tifin.svg';
const magnifiInvestResponsiblyLogo = '/assets/images/register-magnifi-invest-resp-logo.svg';

class Login extends Component {
  state = { modal: true }

  async componentDidMount() {
    document.getElementsByTagName('body')[0].style.paddingTop = 0
    const {
      // authLogin,
      loggedIn,
      setAuthState,
      history: { location },
      autoLoginByTokenRequest,
    } = this.props
    // console.log(location)
    if (
      location &&
      typeof location.search !== 'undefined' &&
      location.search !== ''
    ) {
      const locationSearch = location.search
      // console.log('locationSearch --> ', locationSearch)
      //
      // const __parrr = decodeURIComponent(locationSearch);
      // console.log('__parrr --> ', __parrr);

      let params = new URLSearchParams(locationSearch)
      if (params.get('response')) {
        // console.log(params)
        // console.log(params.get('token'), params.get('email'))
        // params => login?token=355c192a5ee8b78c2916e12b63847c5084025a7d&email=ankit@dev.com&query=Save%20For%20Retirement
        // let payload = {
        //   token: params.get('token'),
        //   email: params.get('email'),
        //   query: params.get('query'),
        // }
        let payload = JSON.parse(params.get('response'))
        console.log('URL payload --> ', payload);
        console.log(payload.token)

        // check if already loggedIn with different email then log-out
        if (
          payload.email &&
          AuthUtils.getAuthToken().email &&
          AuthUtils.getAuthToken().email !== ''
        ) {
          if (AuthUtils.getAuthToken().email !== payload.email) {
            // call logout api
            await Promise.all([
              AuthApis.logout(
                qs.stringify({ email: AuthUtils.getAuthToken().email })
              )
            ])
            // const resp = AuthApis.logout(qs.stringify({ email: AuthUtils.getAuthToken().email }))
          }
          console.log('============= already LoggedIN =============')
          console.log('================ LOGGING OUT ===============')
          setAuthState({
            loggedIn: false
          })
          AuthUtils.deleteAuthToken()
        }

        // if (authLogin) {
        //   authLogin(payload)
        // }

        if (
          payload &&
          payload.token &&
          autoLoginByTokenRequest
        ) {
          autoLoginByTokenRequest({
            token: payload.token
          })
        }
      }
    } else if (loggedIn && window.localStorage.getItem('token')) {
      // history.push('/securities')
      const { history } = this.props;
      let discoveryQuery = this.props.discoveryQuery || window.sessionStorage.getItem('query') || ''
      if (history) {
        // NOTE: Get query from discovery reducer's state
        // --------------------------------------------------------------------------------------------
        const securitiesPath = searchUrlV3(discoveryQuery); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
        // --------------------------------------------------------------------------------------------
        history.push(securitiesPath);
      }
    }
  }

  componentWillUnmount() {
    const { error, refreshAuthState } = this.props;
    if (error && typeof error === 'object' && error.error && refreshAuthState) {
      refreshAuthState();
    }
  }

  handleSubmit = (values, { setSubmitting }) => {
    const { authLogin } = this.props;
    try {
      console.log('values --> ', values)
      if (authLogin) {
        addEventToAnalytics(
          'Sign In',
          'Sign In',
          'SIGN_IN',
          {
            email: values.email,
            cta_clicked: 'sign_in'
          }
        )
        authLogin(values)
      }
      if (setSubmitting) {
        setSubmitting(false); // To stop submit cycle---Formik
      }
    } catch(e) {
      console.log("<----- Error in submitting Form Data ------->");
    }
  }

  handleGoogleSignInSuccess = event => {
    // NOTE: COMMENTING BELOW LINE, EVERYTHING HANDLED BY OKTA
    // ---------------------------------------------------------------
    // const profile = response.profileObj
    //
    // this.props.registerGoogle({
    //   g: 1,
    //   email: profile.email,
    //   token: response.tokenId,
    //   first_name: profile.givenName,
    //   last_name: profile.familyName,
    // },(oktaRegisterResponse) => {
    //
    //   console.log('OKTA RESPONSE GOOGLE UI --> ', oktaRegisterResponse)
    //
    //
    //   // if (oktaRegisterResponse && oktaRegisterResponse.activationToken) {
    //   //   // activationToken: "i45X7UoYfYy8zZAW5x0H"
    //   //
    //   //   const { authLogin } = this.props;
    //   //   if (authLogin) {
    //   //     authLogin({
    //   //       token: oktaRegisterResponse.activationToken,
    //   //       executOktaLoginFor: 'google',
    //   //     }, (callbackResponse) => {
    //   //
    //   //       console.log('--- OKTA RESPONSE GOOGLE UI callback response ---', callbackResponse)
    //   //
    //   //       // NOTE: old logic
    //   //       // const { history } = this.props;
    //   //       // let discoveryQuery = this.props.discoveryQuery || window.sessionStorage.getItem('query') || ''
    //   //       // if (history) {
    //   //       //   // NOTE: Get query from discovery reducer's state
    //   //       //   // --------------------------------------------------------------------------------------------
    //   //       //   const securitiesPath = searchUrlV3(discoveryQuery); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
    //   //       //   // --------------------------------------------------------------------------------------------
    //   //       //   history.push(securitiesPath);
    //   //       // }
    //   //
    //   //     })
    //   //   }
    //   //
    //   // }
    //
    //   // NOTE: old logic
    //   // const { history } = this.props;
    //   // let discoveryQuery = this.props.discoveryQuery || window.sessionStorage.getItem('query') || ''
    //   // if (history) {
    //   //   // NOTE: Get query from discovery reducer's state
    //   //   // --------------------------------------------------------------------------------------------
    //   //   const securitiesPath = searchUrlV3(discoveryQuery); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
    //   //   // --------------------------------------------------------------------------------------------
    //   //   history.push(securitiesPath);
    //   // }
    //
    //
    // })
    //
    //
    // // NOTE: old logic
    //
    // // if (window.heap) {
    // //   window.heap.identify(profile.email)
    // //   window.heap.addUserProperties({
    // //     'First Name': profile.firstName,
    // //     'Last Name': profile.lastName
    // //   })
    // //   window.heap.track('Sign In with Google', { email: profile.email })
    // // }
    // //
    // // window.addkrakenUser && typeof window.addkrakenUser === 'function' && window.addkrakenUser({
    // //   email: profile.email,
    // //   firstName: profile.firstName,
    // //   lastName: profile.lastName
    // // })
    // //
    // // // NOTE: Segment Script
    // // // ------------------------------------------------------------------------------------------
    // // if (window.analytics && window.analytics.identify && typeof window.analytics.identify === 'function') {
    // //   // console.log('---- Login Segment ----');
    // //   window.analytics.identify(profile.email, {
    // //     name: profile.firstName+' '+profile.lastName,
    // //     firstName: profile.firstName,
    // //     lastName: profile.lastName,
    // //     email: profile.email,
    // //     user_type: 'Advisor',
    // //   });
    // // }
    // // // ------------------------------------------------------------------------------------------
    // //
    // // addEventToAnalytics(
    // //   'Sign In with Google',
    // //   'Sign In with Google',
    // //   'SIGN_IN_WITH_GOOGLE',
    // //   { email: profile.email }
    // // )
    // ---------------------------------------------------------------

    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    console.log(' --- handleGoogleSignInSuccess --- ');
    const { registerGoogle } = this.props;
    if (registerGoogle) {
      registerGoogle();
    }

  }

  handleGoogleSignInError = error => {
    console.log('--- handleGoogleSignInError ---')
    // const { setRegisterState } = this.props;
    //
    // setRegisterState({
    //   registerStepStatus: '0',
    //   registerGoogleAuthToken: 'tokenID',
    // });
    //TODO: why Google sso triggered error first time
    if (error !== 'SDK not loaded') {
      this.props.failedGoogleAuth(error)
    }
  }

  authSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email')
      .required('Email is a required field'),
    password: Yup.string().required('Password is a required field')
  })

  handleToggle = () => {
    const currentPath = get(this.props, 'location.pathname', '')
    const pathname = this.props.fromPath || '/'
    if (pathname !== currentPath) {
      this.props.history.push(pathname)
    }
    if (this.props.handleClose) {
      this.props.handleClose()
    }
  }

  routeToPage = () => {
    let discoveryQuery = this.props.discoveryQuery || window.sessionStorage.getItem('query') || ''
    const currentPath = get(this.props, 'location.pathname', '')

    // NOTE: Get query from discovery reducer's state
    // --------------------------------------------------------------------------------------------
    const securitiesPath = searchUrlV3(discoveryQuery); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
    // --------------------------------------------------------------------------------------------

    const pathname = this.props.fromPath || securitiesPath || '/';
    if (pathname !== currentPath) {
      this.props.history.push(pathname)
    }
  }

  render() {
    const {
      error,
      isFetching,
      oktaChangePasswordModal,
      advisorRetailRedirectData,
      resendPasswordResetEmailRequest,
    } = this.props

    console.log('error --> ', error)

    return (
      <React.Fragment>
        <TifinWealthMessage />
        <Spinner isFetching={isFetching || this.props.isProfileCreate} />
        {advisorRetailRedirectData && (
          <SelectDomainPopup
            closable={false}
            width="auto"
            style={{ height: 'auto' }}
            footer={null}
            loading={false}
            centered={true}
            maskStyle={true}
            modalTitle={null}
            wrapClassName={null}
            advisorRetailRedirectData={advisorRetailRedirectData}
          />
        )}
        <Row
          className={`magnifi-auth-page-wrapper ${
            advisorRetailRedirectData &&
            typeof advisorRetailRedirectData !== 'undefined' &&
            Object.keys(advisorRetailRedirectData).length > 0
              ? 'blur-effect'
              : ''
          }`}
        >
          <Col md="6" lg="6" className="left-side-panel-container-login">
            <a className="logo-wrapper" href={REDIRECT_DOMAIN_PATH}>
              <img src={process.env.REACT_APP_SUB_DOMAIN === 'retail' ? magnifiInvestResponsiblyLogo : magnifiLogo} height={70} alt="magnifi" />
            </a>
          </Col>
          <Col md="6" lg="6" className="right-side-panel-container">
            <div className="close-icon" onClick={this.routeToPage}>
              <i className="fal fa-times-circle"></i>
            </div>
            <Formik
              initialValues={{ email: '', password: '' }}
              onSubmit={this.handleSubmit}
              validationSchema={this.authSchema}
              render={props => (
                <LoginForm
                  formProps={props}
                  loginError={error}
                  history={this.props.history}
                  onGoogleSignInError={this.handleGoogleSignInError}
                  resendEmailAction={resendPasswordResetEmailRequest}
                  onGoogleSignInSuccess={this.handleGoogleSignInSuccess}
                />
              )}
            />
          </Col>
        </Row>
        {oktaChangePasswordModal && (
          <OktaChangePasswordModal oktaChangePasswordModal={oktaChangePasswordModal} />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ auth, loading, router, register, discovery: { query: discoveryQuery } }) => ({
  discoveryQuery,
  user: auth.user,
  error: auth.error,
  loggedIn: auth.loggedIn,
  location: router.location,
  authFailed: auth.authFailed,
  authSuccess: auth.authSuccess,
  isTermsAgreed: isTermsAgreed(auth),
  registerStepStatus: register.registerStepStatus,
  oktaChangePasswordModal: auth.oktaChangePasswordModal,
  advisorRetailRedirectData: auth.advisorRetailRedirectData,
  isProfileCreate: createLoadingSelector(['REGISTER_PROFILE'])({ loading }),
  isFetching: false, // using this in ChokidarRouter -> createLoadingSelector(['AUTH', 'OKTA_AUTH_LOGIN'])({ loading }),
})

const mapDispatchToProps = {
  logout,
  authLogin,
  setAuthState,
  registerGoogle,
  failedGoogleAuth,
  refreshAuthState,
  setRegisterState,
  registerProfileData,
  oktaAuthLoginRequest,
  autoLoginByTokenRequest,
  oktaChangePasswordRequest,
  resendPasswordResetEmailRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))

// <Alert
//   showIcon
//   closable
//   type="error"
//   className="auth-alert-wrapper"
//   style={{ bottom: 0 }}
//   message={error.error}
//   description={(
//     <span>
//       Entered password is incorrect.
//       Please note that your account was recently upgraded with an extra layer of security.
//       If you haven't already changed your password please check your inbox or reach out to <a href="mailto:support@magnifi.com">support@magnifi.com</a> for further assistance.
//     </span>
//   )}
// />

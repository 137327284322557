import React from 'react'
import { connect } from 'react-redux'
import { Cell, Pie, PieChart, Tooltip, ResponsiveContainer } from 'recharts'
import { SectorColors } from 'data/Colors'

const WeightsPieChart = ({ dataObject, customCalculatorAttrs }) => {
  const formattedData = Object.keys(dataObject).map(key => {
    const labelMapping = customCalculatorAttrs.find(item => item.col === key);
    return({
      name: labelMapping ? labelMapping.name : '',
      value: dataObject[key]
    })}
  )
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width="100%">
        <Pie
          data={formattedData}
          dataKey="value"
          nameKey="name"
          isAnimationActive={false}
          fill="#8884d8"
          stroke="transparent"
        >
          {formattedData.map((v, i) => (
            <Cell key={i} fill={SectorColors[i]} />
          ))}
        </Pie>
        <Tooltip itemStyle={{ fontSize: '12px', padding: 0 }} />
      </PieChart>
    </ResponsiveContainer>
  )
}

const mapStateToProps = ({ weights }) => ({
  customCalculatorAttrs: weights.customCalculatorAttrs,
})

export default connect(
  mapStateToProps
)(WeightsPieChart)
import React, { Component } from 'react';
import { Steps, Button, message } from 'antd';

export default class IndexIcon extends Component {

  render() {
    const { number, className } = this.props;

    return (
      <React.Fragment>
        <div className={`indexIconWrapper ${className}`}>
          {number}
        </div>
      </React.Fragment>
    )
  }
}

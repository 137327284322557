import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, Tooltip, LabelList, ResponsiveContainer, ReferenceLine } from 'recharts';
// import { getNestDefault } from 'Utils';
import { numberCheck, checkValidNumber } from 'layouts/utils';

export const CustomBarChart = ({ data }) => {
  if (typeof data === 'undefined' || typeof data.length === 'undefined' || data.length === 0) return null;

  const domain = [ dataMin => Math.min(dataMin, 0), dataMax => Math.max(dataMax, 0) ];

  return (
    <ResponsiveContainer width="100%" height="100%" >
      <BarChart data={data}  barGap={4} margin={{top: 45, right: 0, left: 0, bottom: 30}}>
        <XAxis
          dataKey="name"
          tickLine={false}
          y={10}
          axisLine={false}
          tick={
           <CustomXAxisTick
             labelFormatter={labelPc}
           />
          }
        />
        <YAxis hide={true} domain={domain} />
        <Tooltip content={<BarCustomTooltip />} cursor={false} isAnimationActive={false} />
        <ReferenceLine y={0} stroke="#bbb" shapeRendering="crispEdges"/>
        <Bar dataKey="current" unit="%"  maxBarSize={30} isAnimationActive={false} >
          <LabelList dataKey="current" position="top" className="text" formatter={labelPc} content={renderCustomizedLabel} />
          { data.map((v, i) => <Cell key={`cell-${i}`} fill="#56a9e8"/>) }
        </Bar>

        {(data && data.length && typeof data[0].enhanced !== 'undefined')
          && (
            <Bar dataKey="enhanced" unit="%" maxBarSize={30} isAnimationActive={false} >
              <LabelList dataKey="enhanced" position="top"  className="text" formatter={labelPc} content={renderCustomizedLabel} />
              { data.map((v, i) => <Cell key={`cell-${i}`} fill="#1e5f91"/>) }
            </Bar>
          )
        }
      </BarChart>
    </ResponsiveContainer>
  );
}

const labelPc = label => label.toFixed(2)+'%';

const CustomXAxisTick = (props) => {
  const {
    x, y, width, height, fill, payload,
  } = props;

  if (payload) {
    const { coordinate, value, offset, tickCoord, isShow } = payload;
    return (
      <text x={x} y={y+20} fill={fill} textAnchor="middle" dominantBaseline="middle">
        {value}
      </text>
    );
  }
}

const renderCustomizedLabel = (props) => {
  const {
    x, y, width, height, value, fill,
  } = props;

  let yForText = y - 10;
  yForText = numberCheck(yForText);

  if (value !== undefined && typeof value !== 'undefined') {
    let _value = checkValidNumber(value, 1, null, '--');

    if (_value < 0 || _value === '--') {
      let _y = numberCheck(y);
      if (_y === 0) {
        yForText = 85;
      } else {
        yForText = _y + 10;
      }
    }

    return (
      <g>
        <text style={{fontSize:`11px`}} x={x + width / 2} y={yForText} fill={fill} textAnchor="middle" dominantBaseline="middle">
          {_value+'%'}
        </text>
      </g>
    );
  }
};

const BarCustomTooltip = (props) => {
  const { active, payload, label, vkey } = props;
  if (active && payload) {
    return (
      <div className="recharts-default-tooltip ssf-tooltip-custom">
        <div className="label">{label}</div>
        { payload.map((e, index) => (
          <div key={e.dataKey} className="d-flex" style={{color: e.payload[`${e.dataKey+'Color'}`]}}>
            <span className="mr-2">{e.dataKey}</span>
            <span className="ml-auto">{e.payload[e.dataKey]}</span>
            <span className="">{e.unit}</span>
          </div>
        )) }
      </div>
    );
  }

  return null;
}

export default {
  CustomBarChart,
}

import React from 'react';
import { Redirect } from 'react-router-dom';
import { get } from 'lodash'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'reactstrap';

import { ConfirmationPayload } from './helper';

export default class Confirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
    };
  }

  componentDidMount() {
    const elems = document.querySelectorAll('.custom-scroll');
    if (elems.length) {
      [].forEach.call(elems, function(el) {
        if (el.scrollHeight > el.clientHeight) {
          el.style.paddingRight = '.25rem';
          el.style.paddingLeft = '.40rem';
        } else el.style.paddingRight = '0rem';
      });
    }
  }

  toggle = (accountPage = false) => {
    this.setState({ modal: !this.state.modal, accountPage });
    accountPage || this.props.confirmHandler(false);
  }

  triggerInvestment = () => {
    const { triggerInvestment } = this.props;
    if (triggerInvestment) {
      triggerInvestment();
    }
    this.toggle(true);
  }

  render() {
    const { match } = this.props;
    let renderButton = (
      <div className="row no-gutters w-100">
        <div className="col">
          <Button color="link" className="text-white" block onClick={() => this.toggle(true)}>View Account</Button>{' '}
        </div>
        <div className="col">
          <Button color="link" className="text-white continue" block onClick={this.toggle}>Continue Search</Button>
        </div>
      </div>
    )
    if (match && match.path === '/investments' || window.location.pathname === '/investments') {
      renderButton = (
        <div className="row no-gutters w-100">
          <div className="col">
            <Button color="link" className="text-white" block onClick={this.triggerInvestment}>View Account</Button>{' '}
          </div>
        </div>
      )
    }
    if (this.state.accountPage === true) {
      return <Redirect to='/enhancer/onboard-screen' />
    }
    const { confirmPayload } = this.props;

    return (
      <Modal isOpen={this.state.modal} toggle={() => {}} size="md" centered modalClassName="confirm" contentClassName="bg-ssf-blue-modal-bg br-15px p-4">
        <ModalHeader className="text-white lh-100 border-0 d-flex justify-content-center pt-2" tag="h4">
          <span className="text-white fs-20">Confirmation</span>
          <i className="fal fa-times-circle text-white close-icon" onClick={() => this.setState({ modal: false })}></i>
        </ModalHeader>
        <ModalBody className="p-0 font-one no-scroll">
          { confirmPayload ? <ConfirmationPayload data={confirmPayload} /> :
          <div className="d-flex flex-column align-items-center justify-content-center">
            <h5 className="text-center text-white" style={{ marginTop: '0.5rem' }}>Order has been added to watchlist successfully.<br />Please click on View Account to proceed.</h5>
            <br />
            <i className="fal fa-fw fa-check-circle fa-7x text-white"></i>
            <br />
          </div> }
        </ModalBody>
        <ModalFooter className="pb-2">
          {renderButton}
        </ModalFooter>
      </Modal>
    )
  }
}

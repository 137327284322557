import React from 'react'
import { Cell, Pie, PieChart, Tooltip, ResponsiveContainer } from 'recharts'
import { SectorColors } from '../../data/Colors'

const labelMapping = {
  yRet: 'Return',
  risk: 'Risk',
  fees: 'Fee',
  aum: 'Assets',
  vol: 'Volume',
  trkerror: 'Tracking Error',
  weight_yield: 'Yield',
  weight_bid_ask: 'Bid-Ask Spread',
  weight_track_record: 'Track Record',
  weight_premium_to_nav: 'Premium to NAV',
  weight_trend: 'Trend',
  weight_fund_flows: 'Fund Flows',
  weight_risk_adjusted_returns: 'Price to Book',
  weight_price_to_book: 'Risk Adjusted Returns',
  manager_tenure: 'Manager Tenure',
  expense_ratio: 'Expense Ratio',
  aumAC: 'AUM',
  upside_capture_5y: 'Up Capture 5Yr',
  upside_capture_10y: 'Up Capture 10yr',
  downside_capture_5y: 'Down Capture 5Yr',
  downside_capture_10y: 'Down Capture 10yr',
  outperformance_3y: 'Outperformance 3yr',
  outperformance_5y: 'Outperformance 5yr',
  outperformance_10y: 'Outperformance 10yr',
  drawdown: 'Drawdown',
  alpha_5y: 'Alpha 10yr',
  alpha_10y: 'Alpha 10yr',
}

const WeightsPieChart = ({ dataObject }) => {
  const formattedData = Object.keys(dataObject).map(key => ({
    name: labelMapping[key],
    value: dataObject[key]
  }))
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width="100%">
        <Pie
          data={formattedData}
          dataKey="value"
          nameKey="name"
          isAnimationActive={false}
          fill="#8884d8"
          stroke="transparent"
        >
          {formattedData.map((v, i) => (
            <Cell key={i} fill={SectorColors[i]} />
          ))}
        </Pie>
        <Tooltip itemStyle={{ fontSize: '12px', padding: 0 }} />
      </PieChart>
    </ResponsiveContainer>
  )
}

export default WeightsPieChart

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Checkbox, Collapse, Icon, Select, Row, Col, Dropdown, Menu, Radio,Tooltip } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { addEventToAnalytics } from 'Utils';

import IndexIcon from '../IndexIcon'
import GenerateTradeList from '../GenerateTradeList'
import { Link } from 'react-router-dom'

import { uniqValues } from 'Utils'
import { QUERY_MAP, MODEL_PORTFOOLIO_QUERY_MAP } from 'DataSet';
import { customScroll } from '../../../../utils'

import SearchBox from '../../Elements/SearchBox'

import {
  saveEnhancedOption,
  saveAccountEnhancedOption,
  setEnhancedTransaction,
  setPortfolioState,
  disableEnhanceBtnControlState,
  resetCachingForPortfolio,
  setCachingForPortfolio,
  enhancePortfolioVisible,
  getMyCurrentPortfolio,
  getReplacePortfolios,
  resetModelEnhancePortfoliosState,
} from 'actions/portfolio'
import { getPortfolioStats, getPortfolioAccountStats, getFileName } from 'selectors/investment'
import {
  downloadPortfolioReport,
  investmentFetchSuccess,
  fetchPortfolioData,
  setInvestmentState,
  resetCachingForInvestment,
  setCachingForInvestment
} from 'actions/investment'
import {
  getInvestmentWatchlistStats,
  getInvestmentPositionStats,
  getMarketStats
} from 'selectors/investment'
import CustomAlert from 'components/CustomAlert'
import { fetchWeightsData, changeCalculator } from 'actions/weights'
import WeightChangeModal from 'components/sf/WeightChangeModal'

import {
  DownOutlined,
} from '@ant-design/icons';
const { Panel } = Collapse
const { Option } = Select

function callback(key) {
  console.log(key)
}

const customPanelStyle = {
  background: 'transparent',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden',
  margin: 0,
  wightValueChange: false
}

const ModelsPortfolios =  [
  // {
  //   label: 'Sponsor and My Models',
  //   key: 'smm',
  // },
  {
    label: 'Model Portfolios',
    key: 'mp',
  },
  {
    label: 'My Portfolio',
    key: 'up',
  }
];

const FundUniverse =  [
  {
    label: 'ETFs and MFs',
    key: 'etf,etn,mf',
  }, {
    label: 'ETFs',
    key: 'etf,etn',
  }, {
    label: 'Mutual Funds',
    key: 'mf',
  }
];

class Timeline extends Component {
  constructor(props) {
    super(props)
    // console.log(props)
    this.state = {
      generateTradelist: false,
      btnError: false,
      alert: false,
      noFile: false,
      checked: false,
      header2Disable: false,
      fundUniverse: 'etf,etn,mf',
      modelsUniverse: 'mp',
      similarityMeasures: props.similarityMeasures,
      hideForOptions: ["low_cost_alternative_models", "low_risk_alternative_models", "high_yield_alternative_models", "high_sharpe_alternative_models"]
    }
  }

  componentDidMount() {
    const { view, watchlistFundstats, accountFundstats } = this.props
    if (view == 'watchlist') {
      if (watchlistFundstats.tickers.length <= 0) {
        this.setState({ header2Disable: true })
      } else this.setState({ header2Disable: false })
    } else if (view == 'account') {
      if (accountFundstats.tickers.length <= 0) {
        this.setState({ header2Disable: true })
      } else this.setState({ header2Disable: false })
    }
    this.props.fetchWeightsData()
  }

  componentWillUnmount() {
    // this.props.setPortfolioState({ query: '' })
  }

  componentDidUpdate() {
    customScroll();
  }

  componentWillReceiveProps(nextProps) {
    const { view, watchlistFundstats, accountFundstats } = nextProps
    if (view == 'watchlist') {
      if (watchlistFundstats.tickers.length <= 0) {
        this.setState({ header2Disable: true })
      } else this.setState({ header2Disable: false })
    } else if (view == 'account') {
      if (accountFundstats.tickers.length <= 0) {
        this.setState({ header2Disable: true })
      } else this.setState({ header2Disable: false })
    } else if (view === 'uplPrt' || view === 'mrktPl') {
      this.setState({ header2Disable: false })
    }
    // hack hai!
    if (
      nextProps.enableEnhancePortfolioQueries == true &&
      nextProps.enableFirstEnhancePortfolioQueries == true
    ) {
      this.props.toggleFirstTimeEnhanceQuerySelction()
      if (this.props.triggerModelPortfolioQuery !== nextProps.triggerModelPortfolioQuery && nextProps.triggerModelPortfolioQuery == true) {
        this.props.toggleFirstTimeEnhanceQuerySelction()
        this.modelOnChange('low_cost_alternative_models', '');
      } else {
        this.onChange({ target: { value: 'similar' } })
      }
    }

    if (
      !nextProps.portfolioCheck &&
      nextProps.portfolioCheck !== this.props.portfolioCheck
    ) {
      const { activeAccount, oldViewBeforeUpload } = this.props
      // NOTE: call update-portfolio with filename for reason to use 'OLD TICKER' which we get while clicking on dropdown for batch search.
      console.log(view, oldViewBeforeUpload)

      if (oldViewBeforeUpload === '') {
        this.props.setInvestmentState({ oldViewBeforeUpload: view })
      }
    }
  }

  scoreAttrsWeightHandler = values => {
    this.setState(
      {
        wightValueChange: true
      },
      () => {
        setTimeout(() => {
          this.setState(() => ({ wightValueChange: false }))
        }, 3000)
      }
    )
  }

  weightModal = () => {
    this.props.changeCalculator('passive fund selector')
  }

  onChange = (e, currentQuery) => {
    const val = e.target?e.target.value:e
    // if (val === currentQuery) return;
    const {
      query,
      investmentAllStates,
      portfolioCheck,
      enhanceCTA,
      setPortfolioState,
      onChangeCheckbox,
      disableEnhanceBtnControlState,
      resetCachingForInvestment,
      resetCachingForPortfolio,
      setInvestmentState,
      enhancePortfolioVisible,
      resetModelEnhancePortfoliosState,
    } = this.props;
    console.log(`current query '${currentQuery}' and checked query '${val}' are same ?`, val === currentQuery, );
    if (val === currentQuery) return;
    // if (query != '' && query != val) {
    //   setPortfolioState({ query: val, downloadQuery: '', enhancedPortfolioName: '', replacePortfolioToggle: true, enhanceReplace: [], enhanceTickerFilter: [], filterResultSet: [], resultSet: [] })
    //   onChangeCheckbox();
    //   disableEnhanceBtnControlState();
    // } else if (query == '') {
    //   setPortfolioState({ query: val, downloadQuery: '', enhancedPortfolioName: '', replacePortfolioToggle: true, enhanceReplace: [], enhanceTickerFilter: [], filterResultSet: [], resultSet: [] })
    //   onChangeCheckbox();
    //   disableEnhanceBtnControlState();
    // }
    addEventToAnalytics('Enhancer Query','Enhancer Query','ENHANCER_QUERY',{ query: val },false);
    if (
      investmentAllStates.caching &&
      typeof investmentAllStates.caching.portfolioFunds !== 'undefined'
    ) {
      if (!portfolioCheck && enhanceCTA === 'Finalize') {
        const cachedData = investmentAllStates.caching
        const usedStateList = [
          'portfolioLoading',
          'reportLoader',
          'droppedFund',
          'triggerInvestment',
          'checkPurchaseValues',
          'uploadfileDuplicate',
          'uploadFileFailure',
          'uploadfileIncomplete',
          'uploadFilePartial',
          'setPfEnhancementContent'
        ]
        usedStateList.forEach((uu, i) => {
          if (typeof cachedData[uu] !== 'undefined') {
            delete cachedData[uu]
          }
        })
        setInvestmentState({
          ...cachedData
        })
        resetCachingForInvestment()
        resetCachingForPortfolio()
      }
    }

    if (val !== '' && val.includes('model')) {
      console.log(enhanceCTA);
      if (!portfolioCheck && enhanceCTA === 'Finalize') {
        this.props.investmentFetchSuccess({ setPfEnhancementContent: true })
      }
      return this.modelOnChange(e, currentQuery);
    } else {
      if (resetModelEnhancePortfoliosState) {
        resetModelEnhancePortfoliosState();
      }

      setPortfolioState({
        query: val,
        fundUniverse: this.state.fundUniverse,
        query_english: this.getQuery(val),
        downloadQuery: '',
        enhancedPortfolioName: '',
        replacePortfolioToggle: true,
        enhanceReplace: [],
        enhanceTickerFilter: [],
        filterResultSet: [],
        resultSet: []
      })
      onChangeCheckbox()
      disableEnhanceBtnControlState()
    }

  }

  modelOnChange = (e, currentQuery) => {
    const val = (e && e.target) ? e.target.value : e;
    const {
      disableEnhanceBtnControlState,
      getReplacePortfolios,
      portfolioName,
      portfolio,
      query,
      profile,
      portfolioValue,
      currentPerformanceImpact,
      view,
      onChangeCheckbox,
    } = this.props;

    console.log(`current query '${currentQuery}' and checked query '${val}' are same ?`, val === currentQuery, );
    addEventToAnalytics('Enhancer Query','Enhancer Query','ENHANCER_QUERY',{ query: val },false);

    if (getReplacePortfolios && val && portfolioName) {

      const enhancementScreenView = (this.props.oldViewBeforeUpload !== '' && this.props.oldViewBeforeUpload !== this.props.view) ? this.props.oldViewBeforeUpload : this.props.view;

      let payloadFunds = [];
      if (this.props.portfolioFunds && this.props.portfolioFunds.length > 0 && enhancementScreenView !== "mrktPl") {
        console.log('====================== PORTFOLIO FUNDS ======================');
        const portfolioFunds = JSON.parse(JSON.stringify(this.props.portfolioFunds));
        portfolioFunds.map((r) => {
          r.weight = Math.round(r.weight);
        });
        payloadFunds = portfolioFunds;

      } else if (this.props.marketFunds && this.props.marketFunds.length > 0 && enhancementScreenView === "mrktPl") {
        console.log('====================== MARKET FUNDS ======================');
        const marketFunds = JSON.parse(JSON.stringify(this.props.marketFunds));
        marketFunds.map((r) => {
          r.weight = Math.round(r.weight);
        });
        payloadFunds = marketFunds;
      } else if (this.props.watchlistFundstats && this.props.watchlistFundstats.ticker && this.props.watchlistFundstats.tickers.length > 0 && enhancementScreenView === "watchlist") {
        console.log('====================== WATCHLIST FUNDS ======================');
        // console.log(this.props.watchlistFundstats);
        // const marketFunds = JSON.parse(JSON.stringify(this.props.marketFunds));
        // marketFunds.map((r) => {
        //   r.weight = Math.round(r.weight);
        // });
        // payloadFunds = marketFunds;
      }

      if (payloadFunds.length > 0) {
        // console.log(portfolioName);
        // console.log(this.portfolioIdWillBe(portfolioName));
        if (this.portfolioIdWillBe(portfolioName)) {
          getReplacePortfolios({
            query: MODEL_PORTFOOLIO_QUERY_MAP[val],
            portfolioId: this.portfolioIdWillBe(portfolioName),
            portfolio_type: (view === 'uplPrt') ? 'custom_portfolio' : ((view === 'mrktPl') ? 'model_portfolio' : ''),
            fundUniverse: this.state.modelsUniverse,
            // similarityMeasures: this.props.similarityMeasures,
            myCurrentPortfolio: {
              portfolioDetails: {
                name: portfolioName,
                profile: {
                  firstName: window.localStorage.getItem('firstName'),
                  lastName: window.localStorage.getItem('lastName'),
                }, // do not change this
                riskFeeReturn: currentPerformanceImpact,
                chart: currentPerformanceImpact.asset_alloc,
              },
              holdings: _.orderBy(payloadFunds, ['weight'], ['desc']),
              portfolioValue,
            },
            portfolioState: {
              query_english: this.getQuery(val),
              downloadQuery: '',
              enhancedPortfolioName: '',
              replacePortfolioToggle: true,
              enhanceReplace: [],
              enhanceTickerFilter: [],
              // filterResultSet: [],
              // resultSet: [],
              enhancePortfolioVisible: true,
            },
          });
          onChangeCheckbox();
          disableEnhanceBtnControlState();
        }
      }
    }
  }

  portfolioIdWillBe = (portfolioName) => {
    // const { portfolioList, portfolioId } = this.props;
    // console.log(portfolioName, portfolioId, 'Demo portfolio ???', portfolioName.includes('Demo'));
    // if (portfolioName.includes('Demo') && portfolioId) {
    //  return portfolioId;
    // } else if (portfolioList.length > 0) {
    //   if (portfolioList.find((item) => item.portfolio_name === portfolioName)) {
    //     return portfolioList.find((item) => item.portfolio_name === portfolioName).id;
    //   } else if (portfolioName.includes('Demo')) {
    //     return portfolioId;
    //   }
    // }
    return portfolioName
  }

  onCloseTradeList = () => {
    this.setState({
      generateTradelist: !this.state.generateTradelist
    })
  }

  onDownloadTradeComplete = () => {
    const { setPortfolioState } = this.props
    this.setState(
      {
        alert: !this.state.alert
      },
      () => {
        setPortfolioState({
          enhanceReplace: [],
          enhanceTickerFilter: [],
          resultSet: [],
          filterResultSet: []
        })
      }
    )
  }

  noData = () => {
    this.setState({
      noFile: !this.state.noFile
    })
  }

  callUpdatePortfolio = () => {
    const { setPortfolioState } = this.props
    addEventToAnalytics('Enhancer Edit','Enhancer Edit','ENHANCER_EDIT',{},false);
    setPortfolioState({ portfolioCheck: false })
  }

  header = (number, title) => {
    const {
      view,
      activeAccount,
      activePortfolio,
      setPortfolioState,
      enableEnhanceBtnControl
    } = this.props
    return (
      <div className="headerWrap" onClick={event => event.stopPropagation()}>
        <div className="indexIconWrapper">{number}</div>
        <div className="indexText">
          {number === 1 && (
            <Fragment>
              {view == 'account' && (
                <span className="node-head" style={{ fontSize: '16px' }}>
                  {title}{' '}
                  <span style={{ color: '#4a4a4a', fontSize: '14px' }}>
                    {activeAccount}
                  </span>
                </span>
              )}
              {view == 'watchlist' && (
                <span className="node-head" style={{ fontSize: '16px' }}>
                  {title}{' '}
                  <span style={{ color: '#4a4a4a', fontSize: '14px' }}>
                    Watchlist
                  </span>
                </span>
              )}
              {view == 'uplPrt' && (
                <span className="node-head" style={{ fontSize: '16px' }}>
                  {title}{' '}
                  <span style={{ color: '#4a4a4a', fontSize: '14px' }}>
                    {activePortfolio}
                  </span>
                </span>
              )}
              {view == 'mrktPl' && (
                <span className="node-head" style={{ fontSize: '16px' }}>
                  {title}{' '}
                  <span style={{ color: '#4a4a4a', fontSize: '14px' }}>
                    {activeAccount}
                  </span>
                </span>
              )}
              <span
                className="edit-icon"
                onClick={event => {
                  event.stopPropagation()
                  const { tabOnClick } = this.props
                  if (tabOnClick) {
                    addEventToAnalytics('Enhancer Edit Home','Enhancer Edit Home','ENHANCER_EDIT_HOME',{},false);
                    this.props.disableEnhanceBtnControlState()
                    tabOnClick(number, title)
                  }
                }}
              >
                <i className="fal fa-pen" style={{ fontWeight: '500' }}></i>
              </span>
            </Fragment>
          )}
          {number === 2 && (
            <Fragment>
              <span style={{ fontSize: '16px' }}>{title}</span>{' '}
              {enableEnhanceBtnControl && (
                <span
                  className="edit-icon"
                  onClick={() => this.callUpdatePortfolio()}
                >
                  <i className="fal fa-pen" style={{ fontWeight: '500' }}></i>
                </span>
              )}
            </Fragment>
          )}
          {number === 3 && (
            <Fragment>
              <span style={{ fontSize: '16px' }}>{title}</span>
            </Fragment>
          )}
        </div>
      </div>
    )
  }

  saveEnhancedOption = action => {
    const {
      view,
      query,
      activeAccount,
      filename,
      enhanceReplace,
      saveEnhancedOption,
      setPortfolioState,
      saveAccountEnhancedOption,
      watchlistFundstats,
      accountFundstats,
      marketFundStats
    } = this.props
    setPortfolioState({ downloadQuery: query })
    if (view == 'uplPrt') {
      if (enhanceReplace && enhanceReplace.length > 0) {
        enhanceReplace.map(item => {
          delete item.query
          delete item.selectedPortfolioFunds
        })
        this.setState({ btnError: false, action }, () => {
          if (action == 'analytics') {
            saveEnhancedOption(
              { portfolio_name: filename, replace: enhanceReplace },
              () => this.setFilename(activeAccount)
            )
          } else {
            saveEnhancedOption(
              { portfolio_name: filename, replace: enhanceReplace },
              () => this.setFilename(activeAccount)
            )
          }
        })
      } else this.setState({ btnError: true })
    } else if (view == 'mrktPl') {
      saveAccountEnhancedOption(
        {
          portfolio_name: filename,
          replace: enhanceReplace,
          data: marketFundStats.tickers
        },
        () => this.setFilename(activeAccount)
      )
    } else if (view == 'watchlist') {
      saveAccountEnhancedOption(
        {
          portfolio_name: 'watchlist',
          replace: enhanceReplace,
          data: watchlistFundstats.tickers
        },
        () => this.setFilename(activeAccount)
      )
    } else if (view == 'account') {
      saveAccountEnhancedOption(
        {
          portfolio_name: filename,
          replace: enhanceReplace,
          data: accountFundstats.tickers
        },
        () => this.setFilename(activeAccount)
      )
    }
  }

  setFilename = filename => {
    const { investmentFetchSuccess } = this.props
    investmentFetchSuccess({ activeAccount: filename })
  }

  setEnhanceprofile = () => {
    const { action } = this.state
    if (action == 'proposal') {
      this.downloadPDF('similar')
    } else if (action == 'tradeList') {
      this.tradeList()
    }
  }

  downloadPDF = query => {
    const { fundStats, downloadQuery } = this.props
    if (fundStats && fundStats.funds && downloadQuery != '') {
      const { positions } = fundStats
      const ALL_FUNDS_TICKER = uniqValues(positions, 'symbol')
      const { downloadPortfolioReport } = this.props
      downloadPortfolioReport({
        tickers: ALL_FUNDS_TICKER.slice(0, 5).join(','),
        query: downloadQuery
      })
    }
  }

  tradeList = () => {
    this.setState({
      generateTradelist: !this.state.generateTradelist
    })
    const { filename } = this.props
    this.props.setEnhancedTransaction(filename)
  }

  modelDropDownChange = (data) => {
    if (data && data.key) {
      this.setState({
        modelsUniverse: data.key,
      }, () => {
        if (this.props.query !== '' && this.props.query.includes('model')) {
          const {
            portfolioCheck,
            enhanceCTA,
          } = this.props;
          console.log(enhanceCTA);
          if (!portfolioCheck && enhanceCTA === 'Finalize') {
            this.props.investmentFetchSuccess({ setPfEnhancementContent: true })
          }
          this.modelOnChange(this.props.query, '');
        }
      })
    }
  }

  universeDropDownChange = (data) => {
    if (data && data.key && this.state.fundUniverse !== data.key) {
      this.setState({
        fundUniverse: data.key,
      }, () => {
        const {
          query,
          investmentAllStates,
          portfolioCheck,
          enhanceCTA,
          setPortfolioState,
          onChangeCheckbox,
          disableEnhanceBtnControlState,
          resetCachingForInvestment,
          resetCachingForPortfolio,
          setInvestmentState,
          enhancePortfolioVisible,
        } = this.props;

        if (query !== '' && enhanceCTA !== 'Finalize') {

          setPortfolioState({
            query: '', //do not remove this
          });

          if (
            investmentAllStates.caching &&
            typeof investmentAllStates.caching.portfolioFunds !== 'undefined'
          ) {
            if (!portfolioCheck && enhanceCTA === 'Finalize') {
              const cachedData = investmentAllStates.caching
              const usedStateList = [
                'portfolioLoading',
                'reportLoader',
                'droppedFund',
                'triggerInvestment',
                'checkPurchaseValues',
                'uploadfileDuplicate',
                'uploadFileFailure',
                'uploadfileIncomplete',
                'uploadFilePartial',
                'setPfEnhancementContent'
              ]
              usedStateList.forEach((uu, i) => {
                if (typeof cachedData[uu] !== 'undefined') {
                  delete cachedData[uu]
                }
              })
              setInvestmentState({
                ...cachedData
              })
              resetCachingForInvestment()
              resetCachingForPortfolio()
            }
          }

          // if (enhancePortfolioVisible) {
          //   enhancePortfolioVisible({
          //     enhancePortfolioVisible: false,
          //   });
          // }

          setPortfolioState({
            query,
            fundUniverse: this.state.fundUniverse,
            query_english: this.getQuery(query),
            downloadQuery: '',
            enhancedPortfolioName: '',
            replacePortfolioToggle: true,
            enhanceReplace: [],
            enhanceTickerFilter: [],
            filterResultSet: [],
            resultSet: []
          });

          onChangeCheckbox()
          disableEnhanceBtnControlState()
        }

      });
    }
  }

  getQuery = query => {
    return QUERY_MAP[query];
    // switch (query) {
    //   case 'similar':
    //     return 'Low Cost Alternatives'
    //
    //   case 'outperform':
    //     return 'Best Performing Options In Category'
    //
    //   case 'safe_alternative':
    //     return 'Lowest Risk Options'
    //
    //   case 'high_yield_alternative':
    //     return 'Highest Yield Options'
    //
    //   case 'long_track_record_alternatives':
    //     return 'Long Track Record Alternatives'
    //
    //   case 'lowest_tracking_error_option':
    //     return 'Lowest tracking error options'
    //
    //   case 'user_criteria':
    //     return 'Best Alternatives Using Passive Fund Selector'
    //
    //   case 'low_risk_alternative_models':
    //     return 'Lower Risk Alternatives'
    //
    //   case 'high_yield_alternative_models':
    //     return 'Higher Yield Alternatives'
    //
    //   case 'high_sharpe_alternative_models':
    //     return 'Best Model Portfolio'
    //
    //   case 'user_criteria_models':
    //     return 'Best Alternative Using My Investment Selector'
    //
    //   default:
    //     return ''
    // }
  }

  similarityMeasuresOverlay = (data) => {
    if (data && data.key && data.key !== this.state.similarityMeasures) {
      this.setState({
        similarityMeasures: data.key,
      }, () => {
        const { shareClassCallback, enhanceCTA, query, setPortfolioState } = this.props;
        if (setPortfolioState) {
          setPortfolioState({
            similarityMeasures: this.state.similarityMeasures,
          });
        }
        addEventToAnalytics('Enhancer Similarity Measures','Enhancer Similarity Measures','ENHANCE_SIMILARITY_MEASURES',{measure: this.state.similarityMeasures},false);
        if (query !== '' && enhanceCTA !== 'Finalize' && !query.includes('model')) {
         this.searchAgain('', this.state.similarityMeasures);
        }
      })
    }
  }

  searchAgain = (mfClassType, similarityMeasures) => {
    const {
      query,
      investmentAllStates,
      portfolioCheck,
      enhanceCTA,
      setPortfolioState,
      onChangeCheckbox,
      disableEnhanceBtnControlState,
      resetCachingForInvestment,
      resetCachingForPortfolio,
      setInvestmentState,
      setPfEnhancement,
      enhancePortfolioVisible,
      resetModelEnhancePortfoliosState,
    } = this.props;

    if (
      investmentAllStates.caching &&
      typeof investmentAllStates.caching.portfolioFunds !== 'undefined'
    ) {
      if (!portfolioCheck && enhanceCTA === 'Finalize') {
        const cachedData = investmentAllStates.caching
        const usedStateList = [
          'portfolioLoading',
          'reportLoader',
          'droppedFund',
          'triggerInvestment',
          'checkPurchaseValues',
          'uploadfileDuplicate',
          'uploadFileFailure',
          'uploadfileIncomplete',
          'uploadFilePartial',
          'setPfEnhancementContent'
        ]
        usedStateList.forEach((uu, i) => {
          if (typeof cachedData[uu] !== 'undefined') {
            delete cachedData[uu]
          }
        })
        setInvestmentState({
          ...cachedData
        })
        resetCachingForInvestment()
        resetCachingForPortfolio()
      }
    }

    if (resetModelEnhancePortfoliosState) {
      resetModelEnhancePortfoliosState();
    }

    if (mfClassType !== '') {
      setPortfolioState({
        downloadQuery: '',
        enhancedPortfolioName: '',
        replacePortfolioToggle: true,
        enhanceReplace: [],
        enhanceTickerFilter: [],
        filterResultSet: [],
        resultSet: [],
        shareClass: mfClassType,
        // similarityMeasures,
      })
    } else {
      setPortfolioState({
        downloadQuery: '',
        enhancedPortfolioName: '',
        replacePortfolioToggle: true,
        enhanceReplace: [],
        enhanceTickerFilter: [],
        filterResultSet: [],
        resultSet: [],
        // similarityMeasures,
      })
    }

    setPfEnhancement()
    disableEnhanceBtnControlState()

  }

  render() {
    const {
      loggedIn,
      view,
      query,
      downloadQuery,
      portfolioCheck,
      enableEnhanceBtnControl,
      head,
      children,
      style,
      activePortfolio,
      setPfEnhancementContent,
      activeAccount
    } = this.props
    const { header2Disable } = this.state

    let renderModal = ''

    if (this.state.generateTradelist)
      renderModal = (
        <GenerateTradeList
          onCloseClick={this.onCloseTradeList}
          onDownload={this.onDownloadTradeComplete}
        />
      )

    const UniverseMenu = (
      <Menu onClick={this.universeDropDownChange} selectedKeys={[this.state.fundUniverse]}>
        {
          FundUniverse.filter(r => r.key !== this.state.fundUniverse).map((j) => (
            <Menu.Item key={j.key}>
              {j.label}
            </Menu.Item>
          ))
        }
      </Menu>
    );

    const ModelsPortfolioMenus = (
      <Menu onClick={this.modelDropDownChange} selectedKeys={[this.state.modelsUniverse]}>
        {
          ModelsPortfolios.map((j) => (
            <Menu.Item key={j.key}>
              {j.label}
            </Menu.Item>
          ))
        }
      </Menu>
    );

    const text = () => {
      const {  hideForOptions } = this.state;
      const SimilarityMeasures =  [
        {
          label: 'Fund Category',
          key: 'fund_category',
          tooltip: 'Find replacements for funds held from the same category they belong to'
        },        
        {
          label: 'Holdings Overlap',
          key: 'overlap',
          tooltip: 'Find replacements for funds held that have similar holdings when available, else funds with similar returns'
        },
        {
          label: 'Return Correlation',
          key: 'correlation',
          tooltip: 'Find replacements for funds held that have similar returns when available, else funds with similar holdings'
        }
      ];
      const fundsUniverseText = FundUniverse.find((item) => item.key === this.state.fundUniverse) ? FundUniverse.find((item) => item.key === this.state.fundUniverse).label : ( this.state.fundUniverse === 'mf' ? 'Mutual Funds' : this.state.fundUniverse);
      const modelsUniverseText = ModelsPortfolios.find((item) => item.key === this.state.modelsUniverse) ? ModelsPortfolios.find((item) => item.key === this.state.modelsUniverse).label : this.state.modelsUniverse;
      const SimilarityMeasuresOverlay = (
        <Menu onClick={this.similarityMeasuresOverlay} selectedKeys={[this.state.similarityMeasures]}>
          {
            SimilarityMeasures.map((j) => (
              <Menu.Item key={j.key}>
                {j.label}
                <Tooltip placement='right' title={j.tooltip}>
                  <span id={`info-icon-${j.key}`} className='info-icon'><i className="fal fa-info-circle"/></span>
                </Tooltip>
                {/*<Radio checked={this.state.similarityMeasures === j.key} />*/}
              </Menu.Item>
            ))
          }
        </Menu>
      );
      // console.log("===============>> QUERY <<===============");
      // console.log(query);
      // console.log("=========================================");
      return (
        <Radio.Group
          value={query}
          onChange={e => this.onChange(e, query)}
          style={{ flexDirection: 'column' }}
        >
          <Col className="radio-col" >
            <div className="checkbox-category-head-label">
              <span>Use </span>
              <span id="portfolio-class-dropdown-container" className="portfolio-class-dropdown-container dropdown-span">
		            <Dropdown
			            overlayClassName="portfolio-class-dropdown-wrapper similarity-measures"
                  trigger={['hover']}
			            overlay={SimilarityMeasuresOverlay}
			            getPopupContainer={() => document.getElementById('portfolio-class-dropdown-container')}
                >
                  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    Similar <CaretDownOutlined />
                  </a>
		            </Dropdown>
	            </span>
              <span id="fund-universe-dropdown" className="dropdown-span">
                <Dropdown
                  placement="bottomLeft"
                  trigger={['hover']}
                  overlay={UniverseMenu}
                  getPopupContainer={() => document.getElementById('fund-universe-dropdown')}
                >
                  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    {fundsUniverseText} <CaretDownOutlined />
                  </a>
                </Dropdown>
              </span>
              <span>To Replace Holdings With</span>
            </div>
          </Col>

          <Col
            className={
              portfolioCheck && downloadQuery === 'similar' ? 'check-bold radio-col' : 'radio-col'
            }
          >
            <Radio
              id="similar"
              value="similar"
              defaultChecked
              disabled={header2Disable || portfolioCheck}
              style={{ fontSize: '15px' }}
            >
              {this.getQuery('similar')}
            </Radio>

          </Col>

          <Col
            className={
              portfolioCheck && downloadQuery === 'outperform'
                ? 'check-bold radio-col'
                : 'radio-col'
            }
          >
            <Radio
              id="outperform"
              value="outperform"
              defaultChecked
              disabled={header2Disable || portfolioCheck}
              style={{ fontSize: '15px' }}
            >
              {this.getQuery('outperform')}
            </Radio>

          </Col>
          <Col
            className={
              portfolioCheck && downloadQuery === 'safe_alternative'
                ? 'check-bold radio-col'
                : 'radio-col'
            }
          >
            <Radio
              id="safe_alternative"
              value="safe_alternative"
              defaultChecked
              disabled={header2Disable || portfolioCheck}
              style={{ fontSize: '15px' }}
            >
              {this.getQuery('safe_alternative')}
            </Radio>
          </Col>
          <Col
            className={
              portfolioCheck && downloadQuery === 'high_yield_alternative'
                ? 'check-bold radio-col'
                : 'radio-col'
            }
          >
            <Radio
              id="high_yield_alternative"
              value="high_yield_alternative"
              defaultChecked
              disabled={header2Disable || portfolioCheck}
              style={{ fontSize: '15px' }}
            >
              {this.getQuery('high_yield_alternative')}
            </Radio>
          </Col>

          <Col
            className={
              portfolioCheck && downloadQuery === 'long_track_record_alternatives'
                ? 'check-bold radio-col'
                : 'radio-col'
            }
          >
            <Radio
              id="long_track_record_alternatives"
              value="long_track_record_alternatives"
              defaultChecked
              disabled={header2Disable || portfolioCheck}
              style={{ fontSize: '15px' }}
            >
              {this.getQuery('long_track_record_alternatives')}
            </Radio>
          </Col>

          <Col
            className={
              portfolioCheck && downloadQuery === 'high_sharpe_alternative'
                ? 'check-bold radio-col'
                : 'radio-col'
            }
          >
            <Radio
              id="high_sharpe_alternative"
              value="high_sharpe_alternative"
              defaultChecked
              disabled={header2Disable || portfolioCheck}
              style={{ fontSize: '15px' }}
            >
              {this.getQuery('high_sharpe_alternative')}
            </Radio>
          </Col>

          <Col
            className={
              portfolioCheck && downloadQuery === 'user_criteria'
                ? 'check-bold radio-col mg-b-10'
                : 'radio-col mg-b-10'
            }
          >
            <Radio
              id="user_criteria"
              value="user_criteria"
              defaultChecked
              disabled={header2Disable || portfolioCheck}
              style={{ fontSize: '15px' }}
            >
              {this.getQuery('user_criteria')}
              <span
                id="user_criteria_tooltip"
                className="uni-tooltp"
                style={{ fontSize: 11, color: '#BABABB' }}
              ></span>
            </Radio>
            <WeightChangeModal
              {...this.props}
              disabled={header2Disable || portfolioCheck}
              scoreAttrs={this.props.scoreAttrs}
              premiumUser={this.props.premiumUser}
              scoreAttrsWeightHandler={this.scoreAttrsWeightHandler}
            />
          </Col>
          <Col>
            <div
              className="element"
              style={{ paddingBottom: '0', paddingTop: '0' }}
            >
              <div className="searchInput enhance-custom-query-search-bar margin-searchBox">
                <SearchBox
                  queryHandler={this.onChange}
                  query=""
                  currentQuery={query}
                  history={this.props.history}
                  loggedIn={loggedIn}
                  disabled={header2Disable || portfolioCheck}
                  placeholder="Search other enhancement ideas"
                />
              </div>
            </div>
          </Col>
          {(view !== 'account' && view !== 'watchlist') && (
            <>
              <Col className="radio-col">
                <div className="checkbox-category-head-label">
                  <span>Use Similar </span>
                  <span id="model-dropdown" className="dropdown-span">
                    <Dropdown
                      placement="bottomLeft"
                      overlayClassName="timeline-dropdown-span"
                      getPopupContainer={() => document.getElementById('fund-universe-dropdown')}
                      overlay={ModelsPortfolioMenus}
                      trigger={['hover']}
                     >
                      <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        {modelsUniverseText} <CaretDownOutlined />
                      </a>
                    </Dropdown>
                  </span>
                  <span>To Replace Current Portfolio With</span>
                  {/*
                    <span>Use <span style={{ borderBottom: '1px solid #4a4a4a' }}>Model Portfolios</span> To Replace Current Portfolio With</span>
                  */}
                </div>
              </Col>

              <Col
                className={
                  portfolioCheck && downloadQuery === 'low_cost_alternative_models'
                    ? 'check-bold radio-col'
                    : 'radio-col'
                }
              >
                <Radio
                  id="low_cost_alternative_models"
                  value="low_cost_alternative_models"
                  defaultChecked
                  disabled={header2Disable || portfolioCheck}
                  style={{ fontSize: '15px' }}
                >
                  {this.getQuery('low_cost_alternative_models')}
                </Radio>
              </Col>

              <Col
                className={
                  portfolioCheck && downloadQuery === 'low_risk_alternative_models'
                    ? 'check-bold radio-col'
                    : 'radio-col'
                }
              >
                <Radio
                  id="low_risk_alternative_models"
                  value="low_risk_alternative_models"
                  defaultChecked
                  disabled={header2Disable || portfolioCheck}
                  style={{ fontSize: '15px' }}
                >
                  {this.getQuery('low_risk_alternative_models')}
                </Radio>
              </Col>

              <Col
                className={
                  portfolioCheck && downloadQuery === 'high_yield_alternative_models'
                    ? 'check-bold radio-col'
                    : 'radio-col'
                }
              >
                <Radio
                  id="high_yield_alternative_models"
                  value="high_yield_alternative_models"
                  defaultChecked
                  disabled={header2Disable || portfolioCheck}
                  style={{ fontSize: '15px' }}
                >
                  {this.getQuery('high_yield_alternative_models')}
                </Radio>
              </Col>

              <Col
                className={
                  portfolioCheck && downloadQuery === 'high_sharpe_alternative_models'
                    ? 'check-bold radio-col'
                    : 'radio-col'
                }
              >
                <Radio
                  id="high_sharpe_alternative_models"
                  value="high_sharpe_alternative_models"
                  defaultChecked
                  disabled={header2Disable || portfolioCheck}
                  style={{ fontSize: '15px' }}
                >
                  {this.getQuery('high_sharpe_alternative_models')}
                </Radio>
              </Col>
            </>
          )}

        </Radio.Group>
      )
    }

    return (
      <React.Fragment>
        {this.state.wightValueChange && (
          <CustomAlert className="alert-windown-wrapper" color="info">
            Settings Saved Successfully
          </CustomAlert>
        )}

        {this.state.generateTradelist && (
          <GenerateTradeList
            onCloseClick={this.onCloseTradeList}
            onDownload={this.onDownloadTradeComplete}
          />
        )}
        <div className="timelineWrapper custom-scroll" style={style}>
          <Collapse
            activeKey={['1', '2', '3']}
            defaultActiveKey={['1', '2', '3']}
            onChange={callback}
            expandIconPosition="right"
            className="collapseWrapper"
          >
            <Panel
              header={this.header(1, 'Portfolio: ')}
              key="1"
              style={customPanelStyle}
              showArrow={false}
            >
              {/* <div className="collapse-element">
              </div> */}
            </Panel>
            <Panel
              header={this.header(2, 'Popular Enhancements')}
              key="2"
              style={customPanelStyle}
              disabled={
                header2Disable || !this.props.enableEnhancePortfolioQueries
              }
              showArrow={false}
            >
              {this.props.enableEnhancePortfolioQueries && (
                <div className="collapse-element">
                  <div
                    className={
                      header2Disable || portfolioCheck
                        ? 'node node-head not-allowed'
                        : 'node node-head'
                    }
                  ></div>
                  <div className="text-body">{text()}</div>
                </div>
              )}
            </Panel>

            <Panel
              header={this.header(3, 'Enhanced Portfolio Summary')}
              className="third-collpase"
              key="3"
              style={customPanelStyle}
              disabled={
                typeof enableEnhanceBtnControl !== 'undefined' &&
                enableEnhanceBtnControl
                  ? false
                  : true
              }
              showArrow={false}
            >
              {typeof enableEnhanceBtnControl !== 'undefined' &&
                enableEnhanceBtnControl && (
                  <div className="collapse-element">
                    {/* <div> */}
                    {/* { enhancedPortfolioName != '' && <div className="node-head">{`Portfolio : ${enhancedPortfolioName}`}</div> } */}
                    {/* { downloadQuery != '' && <div className="node-head" style={{ marginTop: '10px' }}>{`Query : ${this.getQuery()}`}</div> } */}
                    {/* </div> */}
                  </div>
                )}
            </Panel>
          </Collapse>
        </div>
        {this.state.alert && (
          <CustomAlert className="alert-windown-wrapper" color="info">
            Tradelist Download Successfully
          </CustomAlert>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  investmentAllStates: state.investment,
  loggedIn: state.auth.loggedIn,
  view: state.investment.view,
  query: state.portfolio.query,
  downloadQuery: state.portfolio.downloadQuery,
  enhancedPortfolioName: state.portfolio.enhancedPortfolioName,
  portfolioCheck: state.portfolio.portfolioCheck,
  enableEnhanceBtnControl: state.portfolio.enableEnhanceBtnControl,
  activeAccount: state.investment.activeAccount,
  fundStats: getPortfolioStats(state),
  enhanceReplace: state.portfolio.enhanceReplace,
  filename: state.investment.filename,
  setPfEnhancementContent: state.investment.setPfEnhancementContent,
  watchlistFundstats: getInvestmentWatchlistStats(state),
  accountFundstats: getInvestmentPositionStats(state),
  marketFundStats: getMarketStats(state),
  oldViewBeforeUpload: state.investment.oldViewBeforeUpload,
  premiumUser: state.auth.user.premiumUser,
  scoreAttrs: state.weights.myScoreAttrs || state.weights.scoreAttrs,
  myScoreAttrs: state.weights.myScoreAttrs,
  enhanceCTA: state.portfolio.enhanceCTA,
  portfolioList: state.investment.portfolioList,
  portfolioName: getFileName(state), // state.investment.filename,
  portfolioId: state.investment.portfolioId,
  profile: state.profile,
  currentPerformanceImpact: state.portfolio.currentPerformanceImpact,
  portfolioFunds: getPortfolioAccountStats(state.investment),
  marketFunds: state.investment.marketFunds,
  portfolioValue: state.investment.portfolioValue,
  triggerModelPortfolioQuery: state.portfolio.triggerModelPortfolioQuery,
  similarityMeasures: state.portfolio.similarityMeasures,
})

const mapDispatchToProps = {
  investmentFetchSuccess,
  saveEnhancedOption,
  saveAccountEnhancedOption,
  downloadPortfolioReport,
  setEnhancedTransaction,
  setPortfolioState,
  disableEnhanceBtnControlState,
  fetchPortfolioData,
  setInvestmentState,
  fetchWeightsData,
  changeCalculator,
  setCachingForInvestment,
  resetCachingForInvestment,
  setCachingForPortfolio,
  resetCachingForPortfolio,
  enhancePortfolioVisible,
  getReplacePortfolios,
  getMyCurrentPortfolio,
  resetModelEnhancePortfoliosState,
}

export default connect(mapStateToProps, mapDispatchToProps)(Timeline)

import React from 'react';
import { Modal, ModalBody, ButtonGroup } from 'reactstrap';
import { PrimaryButton, SecondaryButton } from 'shared-components';

import './SkipModal.scss';

const SkipModal = (props) => {
    const {open, cancel, handleConfirm} = props;

    return (<Modal
                isOpen={open}
                toggle={cancel}
                centered
                keyboard={true}
                backdrop
                className="premium-msg-modal-sm-light modalFeature"
                backdropClassName="premium-feature-process-modal-backdrop"
            >
                <ModalBody className="ssf-modal__body">
                <div className="premium-feature-process-modal-container delete-confirmation-modal">
                    <div className="header-wrapper">
                    <h1 className="text-align-center pb-2">Are you sure?</h1>
                    <p className="text-align-center pb-2 h3_Title">All the information entered will be lost.</p>
                    </div>
                    <i className="fal fa-times-circle premium-feature-completed-close" onClick={cancel}></i>
                    <div className="confirmation-btn">
                        <ButtonGroup
                            className="justify-content-center w-100"
                        >
                            <SecondaryButton
                                type="button"
                                className="btn-link btn-reset"
                                onClick={cancel}
                                style={{
                                    maxWidth: '170px',
                                    cursor: 'pointer',
                                    color: '#1e5f91',
                                }}
                            >
                            Cancel
                            </SecondaryButton>
                            <PrimaryButton
                            type="button"
                            className="btn-link btn-update"
                            onClick={handleConfirm}
                            style={{
                                maxWidth: '170px',
                                cursor: 'pointer',
                            }}
                            >
                            OK
                            </PrimaryButton>
                        </ButtonGroup>
                        </div>
                </div>
                </ModalBody>
            </Modal>)
}

export default SkipModal;

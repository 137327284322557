import React from 'react'
import {
  Alert,
  Col,
  Collapse,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Button,
  Input
} from 'reactstrap'
import { PrimaryButton } from 'shared-components'
import { addEventToAnalytics } from 'Utils'

class SubscriptionReactivationRequest extends React.Component {
  constructor(props) {
    super(props)
  }

  reactivatePremSub = (isYes)=>{
    if(isYes){
      addEventToAnalytics('Reactivate Premium Sub Yes','Reactivate Premium Sub Yes','REACTIVATE_PREMIUM_SUB_YES',{},false);
      this.props.reactivateSub()
    }else{
      addEventToAnalytics('Reactivate Premium Sub No','Reactivate Premium Sub No','REACTIVATE_PREMIUM_SUB_NO',{},false);
      this.props.changeReactivatePlanModalStatus(false)
    }
  }


  render() {
    return (
      <React.Fragment>
        <div className="premium-feature-process-modal-container">
          <div className="header-wrapper">
            <h1 className="text-align-left premium-feature-main-heading">Would you like to reactivate your Premium subscription?</h1>
          </div>
          <i className="fal fa-times-circle premium-feature-completed-close close-light" onClick={() => {this.reactivatePremSub(false)}}/>
          <div className="pcm__description">
            <p>You can access all the advanced features.</p>
          </div>
          <div className="profile__subscription-button">
            <Container>
              <Row>
                <Col xs={12}>
                  <FormGroup className="pcm__button-group">
                    <Button color="tools"
                      disabled={!this.props.subscriptionID}
                      className="btn h3 text-uppercase font-weight-bold p-3 mb-0 ml-auto primary-btn"
                      onClick={()=>{this.reactivatePremSub(true)}}
                    >
                      YES
                    </Button>
                    <Button color="tools"
                      className="btn h3 text-uppercase font-weight-bold p-3 mb-0 ml-auto secondary-btn"
                      onClick={() => {this.reactivatePremSub(false)}}
                    >
                      NO
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default SubscriptionReactivationRequest

import React, { Component } from 'react';
import { Row, Col, PopoverBody } from 'reactstrap';
import { withPopover } from 'components/Popover';
import { quintileSize, quintileClass } from 'Utils';
import { cn } from 'DataSet';
import { Quintile } from '../../common';

var classNames = require('classnames');

export default class ResponsiveAnalyticsPopover extends Component {
  render() {
    const { card, qi, icon, highlight, checkPopOpen, firstPop } = this.props;
    let val = qi.name === 'Volume' ? Math.round(card[qi.col]/1000) : card[qi.col];
    if(val == null) val = '--';
    if(qi.name == "Fees" && cn(card, 'Vehicle') == "MF"){
      // val = val * 100;
      val = val != '--' ? val.toFixed(2) : '--';
    }
    const valFmt = val != '--' ? <span className="text-gray-8 font-weight-bold">{ this.props.prefix }{ val.toLocaleString('en', { minimumFractionDigits: 0 }) }{ this.props.suffix }</span> : '--'
    const catVal = qi.name === 'Fees' ? card._cstats[qi.col].toFixed(2) : Math.round(card._cstats[qi.col]);
    const catValFmt = catValFmt != null ? <span>{ this.props.prefix }{ catVal.toLocaleString('en', { minimumFractionDigits: 0 }) }%</span> : '--'

    const hikeLabel = quintileSize(card[qi.qcol], qi.reverse) === 2 ? 'Average' : (quintileSize(card[qi.qcol], qi.reverse) === 3 ? 'High' : 'Low');
    // const statusColor = quintileClass(card[qi.qcol], qi.reverse) === 'danger' ? '#ff4d4d' : (quintileClass(card[qi.qcol], qi.reverse) === 'success' ? '#416D60' : '#1b8be0');

    const e1 = () => (
        <span className="icon-Slader-Fees h2 mb-0 hover-orange">
          <span className="path1"></span><span className="path2"></span>
        </span>
    );

    const e2 = () => (
      <React.Fragment>
        <PopoverBody className="p-0">
          <Row className="no-gutters">
            {/* <Col className="col-12 p-2 pt-3 d-flex justify-content-between align-items-center">
              <h4 className="fs-17 text-ssf-blue-d2 mb-1">{qi.name}</h4>
            </Col> */}
            <Col className="col-12 p-2 text-gray-9 bg-light br-2">
              <div className="h5 d-flex justify-content-between pt-1">
                <div className="popover-drop">
                  <div className="fees-pop-tog popover-drop__toggle" onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave} >
                    <span className="pop-toggle-name">Fees</span>
                  </div>
                </div>
                <span>{valFmt}</span>
              </div>
              <Quintile date={null} item={qi} value={card[qi.qcol]} catValue={catValFmt} bg={quintileClass(card[qi.qcol], qi.reverse)} />
            </Col>
          </Row>
        </PopoverBody>
      </React.Fragment>
    );

    const highlightClassName = highlight ? 'search-fees-return-stats-padding search-risk-stats-highlighting' : (card._flags.feesMin ? 'search-fees-return-stats-padding search-risk-stats-highlighting' : 'search-fees-return-stats-padding');
    const WithPopover = withPopover(e1, e2, { id: "ResponsiveAnalyticsPopover-" + qi.name + '-' + cn(card, 'Id'), placement: 'bottom',
       checkPopOpen, firstPop
    })
    return (
      <WithPopover />
    );
  }
}

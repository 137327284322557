import React, { useContext } from 'react';
import moment from 'moment';
import { Row, Col, Table } from 'reactstrap';
import { withHeaderFooter } from '../shared/HeaderFooter';
// import { StackedAreaChart } from '../shared/Charts';
import SimplePieChart from '../shared/SimplePieChart'
import { assetAllocData, assetAllocData2 } from '../shared/Utils'
import PortfolioContext from '../PortfolioContext'
import { CustomBarChart } from '../shared/BarChart'
import NotApplicable from 'components/NotApplicable'

const AssetAllocations = withHeaderFooter((props) => {
  const reportData = useContext(PortfolioContext)
  let { investment, portfolio: { selectedBenchmark, isModelPortfolioQuery }, portfolio, portfolio: { enhancedPerformanceImpact: { asset_alloc: enAlloc, pfRet3y: Returns3Yr }, currentPerformanceImpact: { asset_alloc: crAlloc } } } = reportData;

  let crChartData = isModelPortfolioQuery ? assetAllocData2(JSON.parse(localStorage.getItem('_current_asset_data'))) : assetAllocData(crAlloc)
  let enChartData = isModelPortfolioQuery ? assetAllocData2(JSON.parse(localStorage.getItem('_enhanced_asset_data'))) : assetAllocData(enAlloc)

  let startDate = Returns3Yr && (Returns3Yr.length > 0 && Returns3Yr[0]) ? (Returns3Yr[0].d) : null;
  let endDate = Returns3Yr && (Returns3Yr.length > 0 && Returns3Yr[Returns3Yr.length - 1]) ? (Returns3Yr[Returns3Yr.length - 1].d) : null;
  let dateRange = startDate && endDate ? `${moment(startDate).format('MMMM YYYY')} - ${moment(endDate).format('MMMM YYYY')}` : null;
  let expectedBarData = [
    {
      "name": "Up Market Capture Ratio*",
      "current": reportData.portfolio.currentPerformanceImpact.up_3yr,
      "currentColor": "#56a9e8",
      "enhanced": reportData.portfolio.enhancedPerformanceImpact.up_3yr,
      "enhancedColor": "#1e5f91"
    },
    {
      "name": "Down Market Capture Ratio*",
      "current": reportData.portfolio.currentPerformanceImpact.down_3yr,
      "currentColor": "#56a9e8",
      "enhanced": reportData.portfolio.enhancedPerformanceImpact.down_3yr,
      "enhancedColor": "#1e5f91"
    }
  ];
  let colors = [
    "rgb(29,96,145)",
    "rgb(86,169,232)",
    "rgb(85, 85, 85)"
  ];

  // console.log(crChartData)

  return (
    <React.Fragment>
      <div className="content">
        <div style={{ marginBottom: `30px` }} className="pr-v2-port-performance">
          <Row className="pr-v2-pp-sections" style={{ marginTop: '30px' }}>
            <Col xs="12">
              <div className="bg-chart-block">
                <p className="mb-0" style={{ fontSize: '1.3rem', color: '#505050' }}>Up and Down Capture vs {selectedBenchmark.includes('SPY') ? 'Comparison ETF (SPY)*' : selectedBenchmark}</p>
                {dateRange && <p className="mb-0" style={{ fontSize: '1rem', color: '#505050', fontWeight: '600' }}>{dateRange}</p>}
                <div style={{ height: '320px', width: '890px' }}>
                  <CustomBarChart
                    data={expectedBarData}
                  />
                </div>
                <div className="pr-v2-chart-legends">
                  <p>Current</p>
                  <p>Enhanced</p>
                </div>
              </div>
              {/* <div>
                <p>Up and down capture shows us whether a given portfolio has gained more or lost less than a broad market benchmark during the period. </p>
              </div> */}
            </Col>
          </Row>
        </div>
        <div className="pr-v2-asset-alloc">
          <h4 className="pr-v2-aa-header">Enhanced vs Existing Portfolio Allocations</h4>
          <Row>
            <Col xs="6">
              <div>
                <div className="pr-v2-clm-alignAll">
                  <h4 className="pr-v2-light-blue">Current</h4>
                  {crChartData ? <SimplePieChart data={crChartData} colors={colors} decimal={1} width={200} height={200} /> : <NotApplicable />}
                </div>
              </div>
            </Col>
            <Col xs="6">
              <div className="pr-v2-clm-alignAll">
                <h4 className="pr-v2-dark-blue">Enhanced</h4>
                {enChartData ? <SimplePieChart data={enChartData} colors={colors} decimal={1} width={200} height={200} /> : <NotApplicable style={{ height: `300px` }} />}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
});

export default AssetAllocations;

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Dropdown, Menu, Tooltip } from 'antd';
import { CaretDownOutlined, DownOutlined } from '@ant-design/icons';
import _ from 'lodash'

import Toggle from 'components/Toggle';

import { searchQuery, setFundsState  } from 'actions/funds';
import { fundsApiCallTime, clearFundsApiLoadPercent } from 'actions/global';
import {
	setPortfolioDefault,
	portfolioQuery,
	saveEnhancedOption,
	setPortfolioReplace,
	saveEnhancedTicker,
	saveAccountEnhancedOption,
	setEnhancedTransaction,
	setPortfolioState,
	disableEnhanceBtnControlState,
	resetCachingForPortfolio,
	setCachingForPortfolio,
	enhancePortfolioVisible,
	getMyCurrentPortfolio,
	getReplacePortfolios,
	resetModelEnhancePortfoliosState,
	resetFundsPortfoliosState,
} from 'actions/portfolio';

import {
	setInvestmentDefault,
	investmentFetch,
	downloadPortfolioReport,
	investmentFetchSuccess,
	fetchPortfolioData,
	setInvestmentState,
	resetCachingForInvestment,
	setCachingForInvestment,
} from 'actions/investment';

import {
	getPortfolioStats,
	getMarketStats,
	getFileName,
	getPortfolioAccountStats,
	getInvestmentWatchlistStats,
	getInvestmentPositionStats,
} from 'selectors/investment';

import { fetchWeightsData, changeCalculator } from 'actions/weights';

import { searchUrl, uniqValues, addEventToAnalytics } from 'Utils';
import { QUERY_MAP, MODEL_PORTFOOLIO_QUERY_MAP, REVERSE_MODEL_PORTFOOLIO_QUERY_MAP, weightSelectorsOptions } from 'DataSet';

import SearchBox from './Elements/SearchBox';
import Timeline from './Elements/Timeline';
import SideNavs from './Home/SideNavs';
import TopFeeds from './Elements/TopFeeds';
import CustomAlert from 'components/CustomAlert'
import WeightChangeModal from 'components/sf/WeightChangeModal'
import { customScroll } from '../../utils'

const SimilarityMeasures =  [
	{
		label: 'Fund Category',
		key: 'fund_category',
		tooltip: 'Find replacements for funds held from the same category they belong to'
	},	
	{
		label: 'Holdings Overlap',
		key: 'overlap',
		tooltip: 'Find replacements for funds held that have similar holdings when available, else funds with similar returns'
	},
	{
		label: 'Return Correlation',
		key: 'correlation',
		tooltip: 'Find replacements for funds held that have similar returns when available, else funds with similar holdings'
	}
];
const ModelsPortfolios =  [
  {
    label: 'Model Portfolios',
    key: 'mp',
  },
  {
    label: 'My Portfolio',
    key: 'up',
  }
];

const FundUniverse =  [
  {
    label: 'ETFs and MFs',
    key: 'etf,etn,mf',
  }, {
    label: 'ETFs',
    key: 'etf,etn',
  }, {
    label: 'Mutual Funds',
    key: 'mf',
  }
];
const topQueryList = {
	'similar'                       : 'Lower Cost Alternatives',
	'safe_alternative'              : 'Lower Risk Alternatives',
	'low_cost_low_risk_replacement' : 'Lower Cost And Risk Alternatives',
	'lower_drawdown_alternatives' 	: 'Lower Drawdown Alternatives',
	'high_yield_alternative'        : 'Highest Yield Alternatives',
	'long_track_record_alternatives': 'Long Track Record Alternatives',
	'lowest_tracking_error_option'  : 'Lower Tracking Error Alternatives',
	'high_sharpe_alternative'       : 'Highest Risk Adjusted Return Alternatives',
	'outperform'                    : 'Best Performing Options In Category',
	'user_criteria'                 : 'Best Alternatives Using Passive Fund Selector',
};

const modelQuery = {
	'similar' 											: 'low_cost_alternative_models',
	'outperform' 										: 'outperform_models',
	'safe_alternative' 							: 'low_risk_alternative_models',
	'low_cost_low_risk_replacement'	:	'low_cost_low_risk_replacement_models',
	'lower_drawdown_alternatives'		:	'lower_drawdown_alternatives_models',
	'high_yield_alternative' 				: 'high_yield_alternative_models',
	'long_track_record_alternatives': 'long_track_record_alternatives_models',
	'lowest_tracking_error_option'	: 'lowest_tracking_error_option_models',
	'high_sharpe_alternative' 			: 'high_sharpe_alternative_models',
	'user_criteria' 								: 'user_criteria_models',
};

const modelReverseQuery = {
	'low_cost_alternative_models'   				: 'similar',
	'outperform_models'											: 'outperform',
	'low_risk_alternative_models'   				: 'safe_alternative',
	'low_cost_low_risk_replacement_models'	:	'low_cost_low_risk_replacement',
	'lower_drawdown_alternatives_models'		:	'lower_drawdown_alternatives',
	'high_yield_alternative_models' 				: 'high_yield_alternative',
	'long_track_record_alternatives_models' : 'long_track_record_alternatives',
	'lowest_tracking_error_option_models'		:	'lowest_tracking_error_option',
	'high_sharpe_alternative_models'				: 'high_sharpe_alternative',
	'user_criteria_models'          				: 'user_criteria',
};

class SideBar extends Component {
  constructor(props){
    super(props);
    this.state = {
      enhanceOpts: ['all'],
      btnError: false,
      enhanceTickerFilter: [],
			fundUniverse: 'etf,etn,mf',
			modelsUniverse: 'mp',
			similarityMeasures: props.similarityMeasures,
			switchFundState: true,
    }
  }

	componentDidMount() {
		const { setPortfolioState, fetchWeightsData } = this.props;
		if (setPortfolioState) {
			setPortfolioState({
				searchTypeMode: this.state.switchFundState ? 'FUNDS' : 'MODELS',
			});
		}
		if (fetchWeightsData) {
			fetchWeightsData(this.setInitialCalculator);
		}
	}

  componentWillUnmount() {
    this.setState({ btnError: false })
  }

  componentDidUpdate() {
    customScroll();
  }

	componentWillReceiveProps(nextProps) {
		const { view, watchlistFundstats, accountFundstats } = nextProps
		// hack hai!
		if (nextProps.enableEnhancePortfolioQueries == true && nextProps.enableFirstEnhancePortfolioQueries == true) {
			this.props.toggleFirstTimeEnhanceQuerySelction();
			this.enhanceSearchQuery('similar');
		}
	}

	setInitialCalculator = () => {
		this.setWeightModal(weightSelectorsOptions[0], false)
	}

  checkRoutes = () => {
    const { location, isTermsAgreed, loggedIn, match } = this.props;
    const { pathname } = location;
    const query = window.sessionStorage.getItem('query');
    if (loggedIn && isTermsAgreed === 0 && query) return;
    if (!match.params.id || match.params.id == '') this.props.history.push(searchUrl(query));
  }

  queryHandler = (query) => {
    if (query) {
      const { searchQuery, setFundsState, fundsApiCallTime, clearFundsApiLoadPercent } = this.props;
      clearFundsApiLoadPercent();
      window.sessionStorage.setItem('query', query);
      setFundsState({
        actions: true,
      });
      this.checkRoutes();
      searchQuery({
        query,
      });
    }
  }

  setPfEnhancementOption = (option) => {
    const { enhanceOpts } = this.state;
    if (enhanceOpts.includes(option)) {
      const index = enhanceOpts.findIndex(x => x === option)
      if (index >= 0 && enhanceOpts.length > 1) {
        enhanceOpts.splice(index, 1);
        this.setState({ enhanceOpts }, () => this.callPortfolioQuery())
      }
    } else {
      enhanceOpts.push(option);
      this.setState({ enhanceOpts }, () => this.callPortfolioQuery())
    }
  }

  callPortfolioQuery = () => {
    const { enhanceOpts } = this.state;
    const { fundStats } = this.props;

    const tickers = uniqValues(fundStats.funds, 'symbol').join(',');
    this.props.portfolioQuery({ tickers, prtQuery: enhanceOpts.join(',') })
  }

  saveEnhancedOption = () => {
    const { filename, enhanceReplace, saveEnhancedOption } = this.props;
    if (enhanceReplace && enhanceReplace.length > 0) {
      enhanceReplace.map((item) => delete item.query);
      this.setState({ btnError: false }, () => {
        saveEnhancedOption({ "portfolio_name": filename, "replace": enhanceReplace});
      })
    } else this.setState({ btnError: true })
  }

  setPfEnhancementTicker = (e, ticker) => {
    const { enhanceTickerFilter } = this.state;
    const { saveEnhancedTicker, resultSet } = this.props;
    const val = document.getElementById(e.target.id).checked;
    if (val) {
      enhanceTickerFilter.push(ticker)

      let filterResultSet = [];
    if (enhanceTickerFilter.length > 0) {
      filterResultSet =  resultSet.filter(x => enhanceTickerFilter.includes(x.ticker))
    } else filterResultSet = resultSet;

      saveEnhancedTicker({ enhanceTickerFilter, filterResultSet })
      this.setState({ enhanceTickerFilter })
    } else {
      const index = enhanceTickerFilter.findIndex(x => x === ticker);
      index >= 0 && enhanceTickerFilter.splice(index,1);

      let filterResultSet = [];
    if (enhanceTickerFilter.length > 0) {
      filterResultSet =  resultSet.filter(x => enhanceTickerFilter.includes(x.ticker))
    } else filterResultSet = resultSet;

      saveEnhancedTicker({ enhanceTickerFilter, filterResultSet })
      this.setState({ enhanceTickerFilter })
    }

  }

  setPfEnhancementCategory = (e, category) => {
    const val = document.getElementById(e.target.id).checked;
    if (val) {
      const { resultSet, setPortfolioReplace } = this.props;
      const resultRadio = resultSet.filter(x => x.query.includes(category));
      const enhanceReplaceCategory =  resultRadio.length > 0 && resultRadio.reduce((acc, item) => {
        const replaceTicker = item.result.funds[0].ticker;
        acc.push({ ticker: item.ticker, replace: replaceTicker, query: item.query });
        return acc;
      }, [])
      setPortfolioReplace(enhanceReplaceCategory);
    }
  }

  onChangeCheckbox = () => {
    const { view, oldViewBeforeUpload, setPfEnhancement, fundStats, marketFundStats, setPortfolioState } = this.props;

    if (view == 'watchlist' || view == 'account') {
      setPfEnhancement();
    } else if (view == 'mrktPl') {
      const tickers = uniqValues(marketFundStats.funds, 'symbol').join(',');
      // console.log(tickers);
      if (setPfEnhancement && tickers && tickers.length) {
        setPfEnhancement();
      }
    } else {
      const tickers = uniqValues(fundStats.funds, 'symbol').join(',');
      // console.log(tickers);
      if (setPfEnhancement && tickers && tickers.length) {
        setPfEnhancement();
      }
    }

  }

  tabOnClick = (tabNumber, tabTitle) => {
    if (tabNumber === 1) {
      const { setPortfolioDefault, setInvestmentDefault } = this.props;
      this.props.investmentFetch();
      setPortfolioDefault();
      setInvestmentDefault();
    }
  }

	enhanceSearchQuery = (q) => {
		if (q) {
			const { query, searchQuery, fundsApiCallTime, clearFundsApiLoadPercent, resetModelEnhancePortfoliosState, setPortfolioState } = this.props;
			if (this.state.switchFundState) {
				// call funds api
				this.onChange(q, query);
			} else {
				// ignore calling api for user_criteria query for models
				if (q === 'user_criteria' || q === 'user_criteria_active_fund_selector' || q === 'user_criteria_passive_fund_selector') {
					if (resetModelEnhancePortfoliosState) {
						resetModelEnhancePortfoliosState();
					}
					setPortfolioState({
						query: q,
						query_english: this.getQuery(q) || q,
						downloadQuery: '',
						enhancedPortfolioName: '',
						enhanceReplace: [],
						enhanceTickerFilter: [],
						filterResultSet: [],
						resultSet: []
					})
				} else {
					// call models api
					this.modelOnChange(q, query);
				}
			}
		}
	}

	switchFundStateHandle = (switchFundState) => {
		const { setPortfolioState, resetModelEnhancePortfoliosState } = this.props;
		this.setState({
			switchFundState: !this.state.switchFundState,
		}, () => {
			if (setPortfolioState) {
				setPortfolioState({
					searchTypeMode: this.state.switchFundState ? 'FUNDS' : 'MODELS',
				});
				if (this.state.switchFundState) {
					// call funds api
					console.log('for funds api');
					console.log(this.props.query.includes('model'), this.props.query, modelReverseQuery[this.props.query]);
					const val = (this.props.query.includes('model') && modelReverseQuery[this.props.query]) ? modelReverseQuery[this.props.query] : this.props.query;
					this.onChange(val, '');
				} else {
					// call models api
					console.log('for models api');
					console.log(!this.props.query.includes('model'), this.props.query, modelQuery[this.props.query]);
					const val = (!this.props.query.includes('model') && modelQuery[this.props.query]) ? modelQuery[this.props.query] : this.props.query;
					// ignore calling api for user_criteria query for models
					console.log(val);
					if (val === 'user_criteria_models' || val === 'user_criteria_active_fund_selector' || val === 'user_criteria_passive_fund_selector') {
						if (resetModelEnhancePortfoliosState) {
							resetModelEnhancePortfoliosState();
						}
						setPortfolioState({
							// query: val,
							// query_english: this.getQuery(val) || val,
							downloadQuery: '',
							enhancedPortfolioName: '',
							enhanceReplace: [],
							enhanceTickerFilter: [],
							filterResultSet: [],
							resultSet: []
						})
					} else {
						// call models api
						this.modelOnChange(val, '');
					}
				}
			}
		})
	}

	onChange = (e, currentQuery) => {
		let val = e.target?e.target.value:e
		console.log(val);

		const {
			query,
			investmentAllStates,
			portfolioCheck,
			enhanceCTA,
			setPortfolioState,
			onChangeCheckbox,
			disableEnhanceBtnControlState,
			resetCachingForInvestment,
			resetCachingForPortfolio,
			setInvestmentState,
			enhancePortfolioVisible,
			resetModelEnhancePortfoliosState,
			selectedWeightOptions,
		} = this.props;

		console.log(`current query '${currentQuery}' and checked query '${val}' are same ?`, val === currentQuery, );
		if (val === currentQuery) return;

		addEventToAnalytics('Enhancer Query','Enhancer Query','ENHANCER_QUERY',{ query: val },false);

		if (
			investmentAllStates.caching &&
			typeof investmentAllStates.caching.portfolioFunds !== 'undefined'
		) {
			if (enhanceCTA === 'Finalize') {
				const cachedData = investmentAllStates.caching
				const usedStateList = [
					'portfolioLoading',
					'reportLoader',
					'droppedFund',
					'triggerInvestment',
					'checkPurchaseValues',
					'uploadfileDuplicate',
					'uploadFileFailure',
					'uploadfileIncomplete',
					'uploadFilePartial',
					'setPfEnhancementContent'
				]
				usedStateList.forEach((uu, i) => {
					if (typeof cachedData[uu] !== 'undefined') {
						delete cachedData[uu]
					}
				})
				setInvestmentState({
					...cachedData
				})
				resetCachingForInvestment()
				resetCachingForPortfolio()
			}
		}

		if (resetModelEnhancePortfoliosState) {
			resetModelEnhancePortfoliosState();
		}

		setPortfolioState({
			query: val,
			fundUniverse: this.state.fundUniverse,
			query_english: this.getQuery(val) || val,
			downloadQuery: '',
			enhancedPortfolioName: '',
			replacePortfolioToggle: true,
			enhanceReplace: [],
			enhanceTickerFilter: [],
			filterResultSet: [],
			resultSet: []
		})
		this.onChangeCheckbox()
		disableEnhanceBtnControlState()

	}

	modelOnChange = (e, currentQuery) => {
		console.log(e, currentQuery);
		let val = (e && e.target) ? e.target.value : e;
		console.log(val);
		val = modelQuery[val] || val;
		console.log(val);

		const {
			disableEnhanceBtnControlState,
			getReplacePortfolios,
			portfolioName,
			portfolio,
			query,
			profile,
			portfolioValue,
			currentPerformanceImpact,
			view,
			onChangeCheckbox,
			resetFundsPortfoliosState,
		} = this.props;

		console.log(`current query '${currentQuery}' and checked query '${val}' are same ?`, val === currentQuery, );
		if (typeof val === 'undefined' || val === currentQuery) return;

		if (resetFundsPortfoliosState) {
			resetFundsPortfoliosState();
		}

		addEventToAnalytics('Enhancer Query','Enhancer Query','ENHANCER_QUERY',{ query: val },false);

		if (getReplacePortfolios && val && portfolioName) {

			const enhancementScreenView = (this.props.oldViewBeforeUpload !== '' && this.props.oldViewBeforeUpload !== this.props.view) ? this.props.oldViewBeforeUpload : this.props.view;

			let payloadFunds = [];
			if (this.props.portfolioFunds && this.props.portfolioFunds.length > 0 && enhancementScreenView !== "mrktPl") {
				console.log('====================== PORTFOLIO FUNDS ======================');
				const portfolioFunds = JSON.parse(JSON.stringify(this.props.portfolioFunds));
				portfolioFunds.map((r) => {
					r.weight = Math.round(r.weight);
				});
				payloadFunds = portfolioFunds;

			} else if (this.props.marketFunds && this.props.marketFunds.length > 0 && enhancementScreenView === "mrktPl") {
				console.log('====================== MARKET FUNDS ======================');
				const marketFunds = JSON.parse(JSON.stringify(this.props.marketFunds));
				marketFunds.map((r) => {
					r.weight = Math.round(r.weight);
				});
				payloadFunds = marketFunds;
			} else if (this.props.watchlistFundstats && this.props.watchlistFundstats.ticker && this.props.watchlistFundstats.tickers.length > 0 && enhancementScreenView === "watchlist") {
				console.log('====================== WATCHLIST FUNDS ======================');
			}

			if (payloadFunds.length > 0) {
				const MODEL_QUERY = MODEL_PORTFOOLIO_QUERY_MAP[val] || val;
				if (this.portfolioIdWillBe(portfolioName) && MODEL_QUERY) {
						getReplacePortfolios({
							query: MODEL_QUERY,
							portfolioId: this.portfolioIdWillBe(portfolioName),
							portfolio_type: (view === 'uplPrt') ? 'custom_portfolio' : ((view === 'mrktPl') ? 'model_portfolio' : ''),
							fundUniverse: this.state.modelsUniverse,
							// similarityMeasures: this.props.similarityMeasures,
							myCurrentPortfolio: {
								portfolioDetails: {
									name: portfolioName,
									profile: {
										firstName: window.localStorage.getItem('firstName'),
										lastName: window.localStorage.getItem('lastName'),
									}, // do not change this
									riskFeeReturn: currentPerformanceImpact,
									chart: currentPerformanceImpact.asset_alloc,
								},
								holdings: _.orderBy(payloadFunds, ['weight'], ['desc']),
								portfolioValue,
							},
							portfolioState: {
								query_english: this.getQuery(val) || val,
								downloadQuery: '',
								enhancedPortfolioName: '',
								replacePortfolioToggle: true,
								enhanceReplace: [],
								enhanceTickerFilter: [],
								// filterResultSet: [],
								// resultSet: [],
								enhancePortfolioVisible: true,
							},
						});
						this.onChangeCheckbox();
						disableEnhanceBtnControlState();
					}
			}
		}
	}

	portfolioIdWillBe = (portfolioName) => {
		return portfolioName
	}

	modelDropDownChange = (data) => {
    if (data && data.key) {
      this.setState({
        modelsUniverse: data.key,
      }, () => {
				this.triggerModalQueryForChange();
      })
    }
  }

	triggerModalQueryForChange = () => {
		if (this.props.query !== '' && !this.state.switchFundState) {
			const {
				portfolioCheck,
				enhanceCTA,
				resetModelEnhancePortfoliosState,
				setPortfolioState,
			} = this.props;
			// console.log(enhanceCTA);
			if (enhanceCTA === 'Finalize') {
				this.props.investmentFetchSuccess({ setPfEnhancementContent: true })
			}

			setPortfolioState({
				query: '', //do not remove this
			});

			// this.enhanceSearchQuery(this.props.query);
			// ignore calling api for user_criteria query for models
			if (this.props.query === 'user_criteria' || this.props.query === 'user_criteria_active_fund_selector' || this.props.query === 'user_criteria_passive_fund_selector') {
				if (resetModelEnhancePortfoliosState) {
					resetModelEnhancePortfoliosState();
				}
				setPortfolioState({
					query: this.props.query,
					query_english: this.getQuery(this.props.query) || this.props.query,
					downloadQuery: '',
					enhancedPortfolioName: '',
					enhanceReplace: [],
					enhanceTickerFilter: [],
					filterResultSet: [],
					resultSet: []
				})
			} else {
				// call models api
				this.modelOnChange(this.props.query, '');
			}
		}
	}

  universeDropDownChange = (data) => {
    if (data && data.key && this.state.fundUniverse !== data.key) {
      this.setState({
        fundUniverse: data.key,
      }, () => {
				this.triggerFundsQueryForChange();
      });
    }
  }

	triggerFundsQueryForChange = () => {
		const {
			query,
			investmentAllStates,
			portfolioCheck,
			enhanceCTA,
			setPortfolioState,
			onChangeCheckbox,
			disableEnhanceBtnControlState,
			resetCachingForInvestment,
			resetCachingForPortfolio,
			setInvestmentState,
			enhancePortfolioVisible,
		} = this.props;

		if (query !== '' && enhanceCTA !== 'Finalize') {

			setPortfolioState({
				query: '', //do not remove this
			});

			if (
				investmentAllStates.caching &&
				typeof investmentAllStates.caching.portfolioFunds !== 'undefined'
			) {
				if (enhanceCTA === 'Finalize') {
					const cachedData = investmentAllStates.caching
					const usedStateList = [
						'portfolioLoading',
						'reportLoader',
						'droppedFund',
						'triggerInvestment',
						'checkPurchaseValues',
						'uploadfileDuplicate',
						'uploadFileFailure',
						'uploadfileIncomplete',
						'uploadFilePartial',
						'setPfEnhancementContent'
					]
					usedStateList.forEach((uu, i) => {
						if (typeof cachedData[uu] !== 'undefined') {
							delete cachedData[uu]
						}
					})
					setInvestmentState({
						...cachedData
					})
					resetCachingForInvestment()
					resetCachingForPortfolio()
				}
			}

			setPortfolioState({
				query,
				fundUniverse: this.state.fundUniverse,
				query_english: this.getQuery(query) || query,
				downloadQuery: '',
				enhancedPortfolioName: '',
				replacePortfolioToggle: true,
				enhanceReplace: [],
				enhanceTickerFilter: [],
				filterResultSet: [],
				resultSet: []
			});

			this.onChangeCheckbox()
			disableEnhanceBtnControlState()
		}

	}

  getQuery = query => {
    return QUERY_MAP[query];
  }

  similarityMeasuresOverlay = (data) => {
    if (data && data.key && data.key !== this.state.similarityMeasures) {
      this.setState({
        similarityMeasures: data.key,
      }, () => {
        const { shareClassCallback, enhanceCTA, query, setPortfolioState } = this.props;
        if (setPortfolioState) {
          setPortfolioState({
            similarityMeasures: this.state.similarityMeasures,
          });
        }
        addEventToAnalytics('Enhancer Similarity Measures','Enhancer Similarity Measures','ENHANCE_SIMILARITY_MEASURES',{measure: this.state.similarityMeasures},false);
        if (query !== '' && enhanceCTA !== 'Finalize' && !query.includes('model')) {
         this.searchAgain('', this.state.similarityMeasures);
        }
      })
    }
  }

  searchAgain = (mfClassType, similarityMeasures) => {
    const {
      query,
      investmentAllStates,
      portfolioCheck,
      enhanceCTA,
      setPortfolioState,
      onChangeCheckbox,
      disableEnhanceBtnControlState,
      resetCachingForInvestment,
      resetCachingForPortfolio,
      setInvestmentState,
      setPfEnhancement,
      enhancePortfolioVisible,
      resetModelEnhancePortfoliosState,
    } = this.props;

    if (
      investmentAllStates.caching &&
      typeof investmentAllStates.caching.portfolioFunds !== 'undefined'
    ) {
      if (!portfolioCheck && enhanceCTA === 'Finalize') {
        const cachedData = investmentAllStates.caching
        const usedStateList = [
          'portfolioLoading',
          'reportLoader',
          'droppedFund',
          'triggerInvestment',
          'checkPurchaseValues',
          'uploadfileDuplicate',
          'uploadFileFailure',
          'uploadfileIncomplete',
          'uploadFilePartial',
          'setPfEnhancementContent'
        ]
        usedStateList.forEach((uu, i) => {
          if (typeof cachedData[uu] !== 'undefined') {
            delete cachedData[uu]
          }
        })
        setInvestmentState({
          ...cachedData
        })
        resetCachingForInvestment()
        resetCachingForPortfolio()
      }
    }

    if (resetModelEnhancePortfoliosState) {
      resetModelEnhancePortfoliosState();
    }

    if (mfClassType !== '') {
      setPortfolioState({
        downloadQuery: '',
        enhancedPortfolioName: '',
        replacePortfolioToggle: true,
        enhanceReplace: [],
        enhanceTickerFilter: [],
        filterResultSet: [],
        resultSet: [],
        shareClass: mfClassType,
        // similarityMeasures,
      })
    } else {
      setPortfolioState({
        downloadQuery: '',
        enhancedPortfolioName: '',
        replacePortfolioToggle: true,
        enhanceReplace: [],
        enhanceTickerFilter: [],
        filterResultSet: [],
        resultSet: [],
        // similarityMeasures,
      })
    }

    setPfEnhancement()
    disableEnhanceBtnControlState()

  }

	scoreAttrsWeightHandler = values => {
		const { setPortfolioState } = this.props;
		if (setPortfolioState) {
			setPortfolioState({
				query: '',
			});
		}
		this.setState({
			wightValueChange: true
		}, () => {
			setTimeout(() => {
				this.setState(() => ({ wightValueChange: false }))
			}, 3000);
			this.enhanceSearchQuery('user_criteria');
		});
	}

	setWeightModal = (selectedWeightOptions, changeQuery = true) => {
		const { setPortfolioState, changeCalculator } = this.props;
		if (selectedWeightOptions && selectedWeightOptions.value && setPortfolioState && changeCalculator) {
			console.log('selectedWeightOptions', selectedWeightOptions);
			setPortfolioState({
				selectedWeightOptions,
			});
			changeCalculator(selectedWeightOptions.value);
			if(changeQuery){
				this.enhanceSearchQuery('user_criteria');
			}
		}
	}

  render() {
    const {
      query,
      loggedIn,
      onBoardScreen,
      activePortfolio,
      setPfEnhancementContent,
			selectedWeightOptions,
    } = this.props;
    const  { btnError, switchFundState } = this.state;

		//FundUniverse.filter(r => r.key !== this.state.fundUniverse)
		const UniverseMenu = (
			<Menu onClick={this.universeDropDownChange} selectedKeys={[this.state.fundUniverse]}>
				{
					FundUniverse.map((j) => (
						<Menu.Item key={j.key}>
							{j.label}
						</Menu.Item>
					))
				}
			</Menu>
		);

		const ModelsPortfolioMenus = (
			<Menu onClick={this.modelDropDownChange} selectedKeys={[this.state.modelsUniverse]}>
				{
					ModelsPortfolios.map((j) => (
						<Menu.Item key={j.key}>
							{j.label}
						</Menu.Item>
					))
				}
			</Menu>
		);

		const fundsUniverseText = FundUniverse.find((item) => item.key === this.state.fundUniverse) ? FundUniverse.find((item) => item.key === this.state.fundUniverse).label : ( this.state.fundUniverse === 'mf' ? 'Mutual Funds' : this.state.fundUniverse);
		const modelsUniverseText = ModelsPortfolios.find((item) => item.key === this.state.modelsUniverse) ? ModelsPortfolios.find((item) => item.key === this.state.modelsUniverse).label : this.state.modelsUniverse;
		const SimilarityMeasuresOverlay = (
			<Menu onClick={this.similarityMeasuresOverlay} selectedKeys={[this.state.similarityMeasures]}>
				{
					SimilarityMeasures.map((j) => (
						<Menu.Item key={j.key}>
							{j.label}
							<Tooltip placement='right' title={j.tooltip}>
								<span id={`info-icon-${j.key}`} className='info-icon'><i className="fal fa-info-circle"/></span>
							</Tooltip>
							{/*<Radio checked={this.state.similarityMeasures === j.key} />*/}
						</Menu.Item>
					))
				}
			</Menu>
		);

		// {(!onBoardScreen)
		// 	&& (
		// 		<React.Fragment>
		// 			<Timeline
		// 				history={this.props.history}
		// 				activePortfolio={activePortfolio}
		// 				tabOnClick={this.tabOnClick}
		// 				onChangeCheckbox={this.onChangeCheckbox}
		// 				queryHandler={this.queryHandler}
		// 				setPfEnhancement={this.props.setPfEnhancement}
		// 				enableEnhancePortfolioQueries={this.props.enableEnhancePortfolioQueries}
		// 				enableFirstEnhancePortfolioQueries
		// 				// enhanceReplace={this.props.enhanceReplace}
		// 				toggleFirstTimeEnhanceQuerySelction= {this.props.toggleFirstTimeEnhanceQuerySelction}
		// 				enableFirstEnhancePortfolioQueries= {this.props.enableFirstEnhancePortfolioQueries}
		// 			/>
		// 		</React.Fragment>
		// 	)
		// }

		let serachBoxQueryValue = this.getQuery(query) || query;
		console.log('serachBoxQueryValue ==> ', serachBoxQueryValue, query);

		if (query === 'user_criteria' && selectedWeightOptions && selectedWeightOptions.code) {
			if (selectedWeightOptions.code === "active_fund_selector") {
				serachBoxQueryValue = QUERY_MAP['user_criteria_active_fund_selector'];
			} else if (selectedWeightOptions.code === "passive_fund_selector") {
				serachBoxQueryValue = QUERY_MAP['user_criteria_passive_fund_selector'];
			}
		}

    return (
      <React.Fragment>
        <div className="sideBarWrapper">
          <div className="element">
            <div className="label">How would you like to enhance your portfolio?</div>
            <div className="searchInput">
              <SearchBox
                queryHandler={this.enhanceSearchQuery}
                query={serachBoxQueryValue}
                history={this.props.history}
                loggedIn={loggedIn}
								placeholder='Specify replacement objectives'
              />
            </div>
          </div>
					<div className="universe-container element">
						<div className="checkbox-category-head-label d-flex align-items-center " style={{ flexWrap: 'wrap' }}>
							<div className="d-flex align-items-center" style={{ marginRight: 15, marginBottom: 0 }}>
								<span className="label" style={{ marginRight: 6, marginBottom: 0 }}>Use </span>
								<div id="portfolio-class-dropdown-container" className="portfolio-class-dropdown-container dropdown-span">
									<Dropdown
										overlayClassName="portfolio-class-dropdown-wrapper similarity-measures"
										trigger={['hover']}
										overlay={SimilarityMeasuresOverlay}
										getPopupContainer={() => document.getElementById('portfolio-class-dropdown-container')}
									>
										<a className="ant-dropdown-link label" onClick={e => e.preventDefault()}>
											Similar <CaretDownOutlined style={{ fontSize: 12 }} />
										</a>
									</Dropdown>
								</div>
							</div>
							<div className="d-flex align-items-center" style={{ marginRight: 12, marginBottom: 0 }}>
								<Toggle size="small" style={{ marginRight: 5 }} checked={switchFundState} onChange={this.switchFundStateHandle} />
								<div id="fund-universe-dropdown" className="portfolio-class-dropdown-container dropdown-span">
									<Dropdown
										placement="bottomLeft"
										overlayClassName="portfolio-class-dropdown-wrapper timeline-dropdown-span"
										trigger={['hover']}
										disabled={!switchFundState}
										overlay={UniverseMenu}
										getPopupContainer={() => document.getElementById('fund-universe-dropdown')}
									>
										<a className="ant-dropdown-link label" onClick={e => e.preventDefault()}>
											Funds <CaretDownOutlined style={{ fontSize: 12, verticalAlign: 0 }} />
										</a>
									</Dropdown>
								</div>
							</div>
							<div className="label" style={{ marginRight: 12, marginBottom: 0 }}>Or</div>
							<div className="d-flex align-items-center" style={{ marginRight: 15, marginBottom: 0 }}>
								<Toggle size="small" style={{ marginRight: 5 }} checked={!switchFundState} onChange={this.switchFundStateHandle} />
								<div id="model-dropdown" className="portfolio-class-dropdown-container dropdown-span">
									<Dropdown
										placement="bottomLeft"
										overlayClassName="portfolio-class-dropdown-wrapper timeline-dropdown-span"
										trigger={['hover']}
										disabled={switchFundState}
										overlay={ModelsPortfolioMenus}
										getPopupContainer={() => document.getElementById('fund-universe-dropdown')}
									 >
										<a className="ant-dropdown-link label" onClick={e => e.preventDefault()}>
											Models <CaretDownOutlined style={{ fontSize: 12, verticalAlign: 0 }} />
										</a>
									</Dropdown>
								</div>
							</div>
						</div>
					</div>
					<div className="element">
						<div className="label">Popular Enhancements <span className="sub-label">(click on any to see results)</span></div>
						<TopFeeds
							queryList={topQueryList}
							queryHandler={this.enhanceSearchQuery}
							isModelsToggleOn={!this.state.switchFundState}
							setWeightModal={this.setWeightModal}
							weightSetting={
								<WeightChangeModal
									{...this.props}
									disabled={false}
									scoreAttrs={this.props.scoreAttrs}
									premiumUser={this.props.premiumUser}
									scoreAttrsWeightHandler={this.scoreAttrsWeightHandler}
								/>
							}
						/>
					</div>
					{this.state.wightValueChange && (
						<CustomAlert className="alert-windown-wrapper" color="info" zIndex={9999}>
							Settings Saved Successfully
						</CustomAlert>
					)}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
	investmentAllStates: state.investment,
	loggedIn: state.auth.loggedIn,
	view: state.investment.view,
	query: state.portfolio.query,
  loading: state.investment.loading,
  location: state.router.location,
  resultSet: state.portfolio.resultSet,
  onBoardScreen: state.portfolio.onBoardScreen,
  activePortfolio: state.investment.activeAccount,
	downloadQuery: state.portfolio.downloadQuery,
	enhancedPortfolioName: state.portfolio.enhancedPortfolioName,
	portfolioCheck: state.portfolio.portfolioCheck,
	enableEnhanceBtnControl: state.portfolio.enableEnhanceBtnControl,
	activeAccount: state.investment.activeAccount,
	fundStats: getPortfolioStats(state),
	enhanceReplace: state.portfolio.enhanceReplace,
	filename: state.investment.filename,
	setPfEnhancementContent: state.investment.setPfEnhancementContent,
	watchlistFundstats: getInvestmentWatchlistStats(state),
	accountFundstats: getInvestmentPositionStats(state),
	marketFundStats: getMarketStats(state),
	oldViewBeforeUpload: state.investment.oldViewBeforeUpload,
	premiumUser: state.auth.user.premiumUser,
	scoreAttrs: state.weights.scoreAttrs,
	myScoreAttrs: state.weights.myScoreAttrs,
	enhanceCTA: state.portfolio.enhanceCTA,
	portfolioList: state.investment.portfolioList,
	portfolioName: getFileName(state), // state.investment.filename,
	portfolioId: state.investment.portfolioId,
	profile: state.profile,
	currentPerformanceImpact: state.portfolio.currentPerformanceImpact,
	portfolioFunds: getPortfolioAccountStats(state.investment),
	marketFunds: state.investment.marketFunds,
	portfolioValue: state.investment.portfolioValue,
	triggerModelPortfolioQuery: state.portfolio.triggerModelPortfolioQuery,
	similarityMeasures: state.portfolio.similarityMeasures,
	selectedWeightOptions: state.portfolio.selectedWeightOptions,
})

const mapDispatchToProps = {
  portfolioQuery,
  setPortfolioReplace,
  saveEnhancedTicker,
  investmentFetch,
  setPortfolioDefault,
  setInvestmentDefault,
	investmentFetchSuccess,
  saveEnhancedOption,
  saveAccountEnhancedOption,
  downloadPortfolioReport,
  setEnhancedTransaction,
  setPortfolioState,
  disableEnhanceBtnControlState,
  fetchPortfolioData,
  setInvestmentState,
  setCachingForInvestment,
  resetCachingForInvestment,
  setCachingForPortfolio,
  resetCachingForPortfolio,
  enhancePortfolioVisible,
  getReplacePortfolios,
  getMyCurrentPortfolio,
  resetModelEnhancePortfoliosState,
	resetFundsPortfoliosState,
	fetchWeightsData,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideBar)

import { takeEvery, fork, call, put, all } from 'redux-saga/effects'
import qs from 'query-string'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'

import ActionTypes from '../action-types'
import * as actions from '../actions/broker'
import * as BrokerApi from '../apis/broker'

import * as brokerHelper from './broker-helper'
import { BrokerNameMap, getBrokerNameForImport } from '../DataSet'

const BrokerAccounts = [
  {
    id: 1,
    user: 16,
    account_number: '30049872',
    account_description: 'MyAccount-1',
    margin_level: 'MARGIN',
    net_account_value: 9999871.82,
    registration_type: 'INDIVIDUAL'
  }
]

function* setupBroker(action) {
  // if (action.broker.name === 'etrade') {
  //   yield brokerHelper.setupETradeBroker(action)
  // } else 
  if (action.broker.name === 'tdameritrade') {
    yield brokerHelper.setupTDAmeritradeBroker(action)
  } else {
    yield brokerHelper.setupTradeItBroker(action)
  }
}

function* completeBrokerSetup(action) {
  const { payload } = action

  try {
    const response = yield call(BrokerApi.completeBrokerSetup, {
      params: {
        oauth_verifier: payload.oauth_verifier
      }
    })
    yield put(
      actions.successfulCompleteBrokerSetup({
        ...response.data,
        brokerName: payload.brokerName
      })
    )
  } catch (error) {
    yield put(actions.failedCompleteBrokerSetup(error))
  }
}

function* fetchImportedAccounts() {
  try {
    const response = yield call(BrokerApi.fetchImportedAccounts)
    const accountList = get(response, 'data', {})
    let accounts = []

    console.log({ accountList })

    if (!isEmpty(accountList)) {
      accounts = Object.keys(accountList).map(brokerName => ({
        broker: BrokerNameMap(brokerName),
        accounts: orderBy(accountList[brokerName], 'account_description')
      }))
    }
    accounts = orderBy(accounts, 'broker')
    yield put(actions.successfulFetchImportedAccounts(accounts || []))
  } catch (error) {
    console.log('error ', error)
    const message = get(
      error,
      'response.data.message',
      error && error.toString()
    )
    yield put(actions.failedFetchImportedAccounts({ message }))
  }
}

const getBrokerAccountsApi = brokerName => {
  switch (brokerName) {
    case 'tdameritrade':
      return BrokerApi.fetchTDBrokerAccounts
    default:
    case 'tradeit':
      return BrokerApi.fetchTradeItBrokerAccounts
      break
  }
}

function* fetchBrokerAccounts(action) {
  const { payload } = action
  try {
    const response = yield call(
      getBrokerAccountsApi(action.payload),
      action.payload
    )
    // yield put(actions.successfulFetchBrokerAccount(BrokerAccounts.slice()))
    if (Array.isArray(response && response.data)) {
      yield put(actions.successfulFetchBrokerAccount(response.data || []))

      // will be triggered only when setting up broker accounts for the firt time
      if (action.isBrokerSetup) {
        yield put(actions.importBrokerAccounts(response.data, payload))
      }
    } else {
      yield put(
        actions.failedFetchBrokerAccount({
          message: get(response, 'data.message', 'Unable to fetch accounts')
        })
      )
    }
  } catch (error) {
    console.log('error ', error)
    const message = get(
      error,
      'response.data.message',
      error && error.toString()
    )
    yield put(actions.failedFetchBrokerAccount({ message }))
  }
}

function* importBrokerAccounts(action) {
  const { payload } = action
  try {
    const response = yield call(
      BrokerApi.importBrokerAccounts,
      payload,
      getBrokerNameForImport(action.brokerName),
    )
    yield put(actions.successfulImportBrokerAccounts(response.data || []))
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      error && error.toString()
    )
    yield put(actions.failedImportBrokerAccounts({ message }))
  }
}

function* completeTDAmeritradeBrokerSetup(action) {
  const { payload } = action
  console.log({ payload })
  try {
    const response = yield call(BrokerApi.completeTDAmeritradeBrokerSetup, {
      params: {
        ...payload
      }
    })
    yield put(actions.successfulCompleteSetupTDAmeritrade(response.data))
  } catch (error) {
    yield put(actions.failedCompleteSetupTDAmeritrade(error))
  }
}

function* setDefaultAccount(action) {
  const { payload } = action
  try {
    const response = yield call(
      BrokerApi.setDefaultAccount,
      { account_number: payload.account_number },
      payload.brokerName
    )
    yield put(actions.successfulSetDefaultAccount(response.data))
  } catch (error) {
    yield put(actions.failedSetDefaultAccount(error))
  }
}

function* deleteImportedAccountRequest(action) {
  const { payload } = action
  console.log({ payload })
  try {
    const response = yield call(
      BrokerApi.deleteImportedAccount,
      {
        ...payload,
        brokerName: payload.brokerName
      },
      getBrokerNameForImport(payload.brokerName)
    )
    yield put(actions.successfulDeletedImportedAccount(payload))
  } catch (error) {
    yield put(actions.failedDeletedImportedAccount(error))
  }
}

function* successfulCompleteBrokerSetup(action) {
  const brokerName = get(action, 'response.brokerName', '')
  yield put(actions.fetchBrokerAccounts(brokerName, true))
}

function* brokerSaga() {
  yield all([
    fork(
      takeEvery,
      ActionTypes.FETCH_IMPORTED_ACCOUNT_REQUEST,
      fetchImportedAccounts
    ),
    fork(takeEvery, ActionTypes.SETUP_BROKER_REQUEST, setupBroker),
    fork(
      takeEvery,
      ActionTypes.COMPLETE_BROKER_SETUP_REQUEST,
      completeBrokerSetup
    ),
    fork(
      takeEvery,
      ActionTypes.FETCH_BROKER_ACCOUNT_REQUEST,
      fetchBrokerAccounts
    ),
    fork(
      takeEvery,
      ActionTypes.IMPORT_BROKER_ACCOUNT_REQUEST,
      importBrokerAccounts
    ),
    fork(
      takeEvery,
      ActionTypes.COMPLETE_TDAMERITRADE_BROKER_SETUP_REQUEST,
      completeTDAmeritradeBrokerSetup
    ),
    fork(takeEvery, ActionTypes.SET_AS_DEFAULT_REQUEST, setDefaultAccount),
    fork(
      takeEvery,
      ActionTypes.DELETE_IMPORTED_ACCOUNT_REQUEST,
      deleteImportedAccountRequest
    ),
    fork(
      takeEvery,
      ActionTypes.COMPLETE_BROKER_SETUP_SUCCESS,
      successfulCompleteBrokerSetup
    ),
    fork(
      takeEvery,
      ActionTypes.COMPLETE_TRADE_IT_BROKER_SETUP,
      brokerHelper.completeTradeItBrokerSetup
    ),
    fork(
      takeEvery,
      ActionTypes.TRADE_IT_MORE_INFO_VEIRFY_REQUEST,
      brokerHelper.tradeItVerificationRequest
    )
  ])
}

export default brokerSaga

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Form,
	ButtonGroup
} from 'reactstrap';
import {
	CloseButton,
	PrimaryButton,
	SecondaryButton
} from 'shared-components';
import { deleteCalculator } from 'actions/weights';
import { notification } from 'antd';

class DeleteSelectorModal extends Component {
	constructor(props) {
    super(props);
    this.state = {
    	deletionStatus: '',
    }
  }

  deleteSelector = (calc) => {
  	const { deleteCalculator } = this.props;
  	deleteCalculator(calc, this.deletionMessage);
  }
  deletionMessage = (deletionStatus) => {
  	this.closeModal();  	
  	const { selectorName } = this.props;
  	let deletionMessage = '', toastClass = '';
  	if(deletionStatus === 'success'){
			deletionMessage = (
				<span>
					<strong>{selectorName}</strong> deleted successfully!
				</span>
			);
			toastClass = 'delete-selector-success';
		}else{
			deletionMessage = 'Something went wrong. Try again later.';
			toastClass = 'delete-selector-failure';
		}
  	notification.open({
      message: deletionMessage,
      className: toastClass,
      duration: 3,
      top: 70,
      onClose: () => {
        this.setState({
          deletionStatus: '',
        })
      },
    });
  }
  closeModal = () => {
  	this.props.toggleDeleteModal(false);
  }

	render() {
		const {
			modalToggle,
			selectorName,
			toggleDeleteModal
		} = this.props;
		const { deletionStatus } = this.state;
		return(
			<Modal
	      isOpen={modalToggle}
	      toggle={() => this.closeModal()}
	      centered
	      keyboard={true}
	      backdrop
	      className="premium-msg-modal-sm-light"
	      backdropClassName="premium-feature-process-modal-backdrop"
	    >
	      <ModalBody className="ssf-modal__body premium-feature-process-modal-container">
	        <div
	        	className="delete-confirmation-modal"
	        >
	        	<DeleteConfirmation
		        	selectorName={selectorName}
							closeModal={this.closeModal}
							deleteSelector={this.deleteSelector}
		        />
	        </div>
	      </ModalBody>
	    </Modal>
    )
	}
}

const mapDispatchToProps = {
	deleteCalculator,
}

export default connect(
  null,
  mapDispatchToProps
)(DeleteSelectorModal);

const DeleteConfirmation = (props) => {
	const {
		selectorName,
		closeModal,
		deleteSelector,
	} = props;

	return(
		<React.Fragment>
			<div className="delete-content">
	  		Do you want to delete <strong>{selectorName}</strong>?
	  	</div>
	  	<i
				className="fal fa-times-circle premium-feature-completed-close close-light"
				onClick={() => closeModal()}
			>
			</i>
	  	<div className="confirmation-btn">
	  		<ButtonGroup
	  			className="justify-content-center w-100"
	  		>
	        <SecondaryButton
	          type="button"
	          className="btn-link btn-reset"
	          onClick={() => closeModal()}
	          style={{
	            maxWidth: '170px',
	            cursor: 'pointer',
	            color: '#1e5f91',
	          }}
	        >
	          Cancel
	        </SecondaryButton>
	        <PrimaryButton
	          type="button"
	          className="btn-link btn-update"
	          onClick={() => deleteSelector(selectorName)}
	          style={{
	          	maxWidth: '170px',
	          	cursor: 'pointer',
	          }}
	        >
	          Delete
	        </PrimaryButton>
	      </ButtonGroup>
	  	</div>
  	</React.Fragment>
	)
}
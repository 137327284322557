import React from 'react';

import { extent as d3Extent } from 'd3-array';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import { ProfitLossColors } from 'data/Colors'
import { getNestDefault } from 'Utils';

export const MultiSeriesLineChart = (props) => {
  // console.log(props);
  const { lines, xkey, ydomain, series } = props;
  const colors = ['#56a9e8', '#1e5f91'];

  if (!lines || !lines.length) return null;

  if (!series || !series.length || typeof series === 'undefined') return null;

  const labelFormatter = label => {
    return label
  }
  const tickFormatter = label => {
    return '.'
  } // wider tick label dont show all xticks

  const first = series.findIndex(e => e.y1 !== undefined)
  let data = series.slice()

  data.splice(first, 0, { x: 'Start', y1: 0, y2: 0, y3: 0, y4: 0 })
  let last = data[data.length - 1]

  const posY = () => {
    let out = { y1: 0, y2 : 0 }
    if (typeof data[data.length - 1].label !== "Enhanced") return out

    const ranges = ['y1', 'y2'].reduce(
      (acc, y) => acc.concat(d3Extent(data, e => e[y])),
      []
    )
    const [min, max] = d3Extent(ranges) // ydomain across all 3 lines
    const y1pos = last.y1 / (max - min),
      y2pos = last.y2 / (max - min)
    const diff = Math.abs(y1pos - y2pos)

    // if difference is more than 20% then no collision
    if (diff > 0.2) return out

    const adj = Math.round((0.2 - diff) * 30) // consider 12px text height, 12px = 20%
    if (y1pos > y2pos) {
      // y1 appears first
      if (y1pos > 0.9) out.y2 = 2 * adj
      // y1 almost at top, so just move y4 down
      else if (y2pos < 0.1) out.y1 = -2 * adj
      // y2 almost at bottom, so just move y1 up
      else out = { y1: -adj, y2: adj } // move y1 up, y2 down by same amt
    } else {
      // y2 appears first
      if (y2pos > 0.9) out.y1 = 2 * adj
      // y2 almost at top, so just move y1 down
      else if (y1pos < 0.1) out.y2 = -2 * adj
      // y1 almost at bottom, so just move y2 up
      else out = { y1: adj, y2: -adj } // move y2 up, y1 down by same amt
    }
    return out
  }
  const labelOff = posY()

  const segs = (color) => {
    const tick = 1 / (data.length - 1 - first)
    let out = [],
      dir

    for (let i = first + 1; i < data.length; i++) {
      let _dir = data[i].y3 < 0 ? 0 : 1
      if (dir === _dir) {
        out[out.length - 1] = (
          <stop
            key={`s${i}`}
            offset={(i-first-1)*tick}
            stopColor={color}
            stopOpacity={1}
          />
        )
      } else {
        out.push(
          <stop
            key={`s${i}`}
            offset={(i - first - 1) * tick}
            stopColor={color}
            stopOpacity={1}
          />
        )
        out.push(
          <stop
            key={`e${i}`}
            offset={(i - first) * tick}
            stopColor={color}
            stopOpacity={1}
          />
        )
      }
      dir = _dir
    }
    return out
  }

  const gradientId = `color-${props.fid}-${props.gSuffix || ''}`;

   return (
     <ResponsiveContainer id={props.id || 'linechart'} width="100%" height="100%">
       <LineChart
         data={data}
         margin={{top: 8, right: 32, left: 32, bottom: 8}}
       >
        <defs>
          { lines.map((s, index) => (
            <linearGradient id={`color-${s.name}`} x1="0" y1="0" x2="1" y2="0" key={s.name}>
              { segs(s.data, s.color) }
            </linearGradient>
          ))}
        </defs>

        <ReferenceLine
          y={0}
          stroke="#bbb"
          shapeRendering="crispEdges"
          label={<ReferenceLineCustomLabel value={'$100'} stroke='#666' />} />

        <XAxis
          dataKey={xkey}
          tick={false}
          hide={true}
          tickFormatter={tickFormatter}
          height={1}
          strokeWidth="1"
          stroke="#aaa"
          strokeDasharray="2 2"
        />

        <YAxis
          hide={true}
          domain={ydomain}
        />

        <Tooltip content={<CustomTooltip />}
          cursor={{ stroke: '#666', strokeWidth: 1, strokeDasharray: "2 2" }}/>

        <Line
          dataKey={lines[0].ykey}
          name={lines[0].name}
          key={lines[0].name}
          type="monotone"
          unit="%"
          stroke={lines[0].color}
          dot={false}
          strokeWidth={1}
          activeDot={{r: 3, strokeWidth: 1, fill: colors[1]}}
          label={<CustomizedLabel
            data={last}
            dataKey="y1"
            size={data.length}
            label={lines[0].label}
            stroke={lines[0].color}
            yoff={labelOff[lines[0].ykey]}
          />}
          isAnimationActive={false}
        />

        {(lines.length > 1) && (
          <Line
            dataKey="y2"
            name={lines[1].name}
            key={lines[1].name}
            type="monotone"
            unit="%"
            stroke={lines[1].color}
            dot={false}
            strokeWidth={1}
            activeDot={{r: 3, strokeWidth: 1, fill: colors[1]}}
            label={<CustomizedLabel
              dataKey="y2"
              data={last}
              size={data.length}
              label={lines[1].label}
              stroke={lines[1].color}
              yoff={labelOff[lines[1].ykey]}
            />}
            isAnimationActive={false}
          />
      )}

      </LineChart>
     </ResponsiveContainer>
   )
}

const CustomTooltip = (props) => {
  const { active, payload, label, vkey } = props;

  if (active && payload) {
    const key = vkey ? ['payload', vkey] : ['value'];
    payload.map(e => {
      const _y2 = payload.find(e => e.dataKey === 'y2');
      if (_y2 && typeof _y2.payload !== 'undefined') {
        const _y2Payload = _y2.payload;
        // _y2Payload.y2 = _y2Payload.y2 - 0.02; // removing padding between lines from PortfolioLineChart file line no: 74
      }
    })
    return (
      <div className="recharts-default-tooltip ssf-tooltip-custom">
        <div>
          <div className="label">{label}</div>
          { payload.map((e, index) => (
            <div key={e.name} className="d-flex" style={{color: e.color}}>
              <span className="mr-2">{e.name}</span>
              <span className="ml-auto">{(getNestDefault(['payload', `y${index+1}T`], e, 0.0)).toFixed(2)}</span>
              <span className="">{e.unit}</span>
            </div>
          )) }
        </div>
      </div>
    );
  }

  return null;
}

const ReferenceLineCustomLabel = (props) => {
  const { viewBox, stroke, value } = props;
  return <text x={0} y={viewBox.y} dx={4} dy={4} fill={stroke} fontSize={10} textAnchor="start">{value}</text>
};

const colorSegments = (data, color) => {
  const first = 0;
  const tick = 1/(data.length-1-first);
  let out = [];

  for (let i = first+1; i < data.length; i++) {
    out.push(<stop key={`s${i}`} offset={(i-first-1)*tick} stopColor={color} stopOpacity={1}/>)
    out.push(<stop key={`e${i}`} offset={(i-first)*tick} stopColor={color} stopOpacity={1}/>)
  }
  return out;
}

const CustomizedLabel = (props) => {
  const { index, size, x, y, stroke, label, dx, dy, dataKey, labelText, viewBox, data, value } = props;
  if (index === (size - 1)) {
    // console.log(props);
    return (
      <text
        id={`linechart-label-for-${dataKey}`}
        dataKey={dataKey}
        x={x}
        y={y}
        dx={4}
        dy={4}
        fill={stroke}
        fontSize={10}
        textAnchor="start"
      >
        {label}
      </text>
    )
  }
  return null;
};

export default {
  MultiSeriesLineChart,
}

import React, { useContext } from 'react'
// import { Row, Col, Table } from 'reactstrap';
//
// import ImageRoundMask from './ImageRoundMask';

const PortfolioName = props => {
  return (
    <div className="pf-name">
      {/* <ImageRoundMask
        initial={props.initial}
      /> */}
      <span
        style={{
          maxWidth: '20ch',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {props.name}
      </span>
    </div>
  )
}
export default PortfolioName

// import { OktaAuth } from '@okta/okta-auth-js';

import config from 'env';

export const OKTA_AUTHENTICATOR = 'okta_password';
export const OKTA_IDP = config.REACT_APP_OKTA_IDP;
export const SCOPES = ['openid', 'profile', 'email', 'offline_access'];
export const REDIRECT_URI = `${window.location.origin}/auth/callback`;
export const CLIENT_ID = config.REACT_APP_OKTA_CLIENT_ID;
export const ISSUER = `https://${config.REACT_APP_OKTA_ISSUER}/oauth2/default`;
export const GROUP_IDS = ['00g5gibu72o0DTErw5d7'];

export const REGISTRATION_BASE_URL = `https://${config.REACT_APP_OKTA_ISSUER}/api/v1/registration/reg5lqxcbz7y6LCE65d7/register`

export const oktaConfig = {
  oidc: {
    issuer: ISSUER,
    scopes: SCOPES,
    clientId: CLIENT_ID,
    redirectUri: REDIRECT_URI,
    postLogoutRedirectUri: `${window.location.origin}`,
  },
};

export const updateRedirectURI = (path) => {
  oktaConfig.oidc.redirectUri = path;
}

// this.oktaAuth = window.OktaAuth;
// if (window.OktaAuth) {
//   this.oktaAuth = new window.OktaAuth(oktaConfig.oidc);
// }

export const oktaAuth = window.OktaAuth ? new window.OktaAuth(oktaConfig.oidc) : null;

export default {
  SCOPES,
  OKTA_IDP,
  oktaAuth,
  CLIENT_ID,
  GROUP_IDS,
  oktaConfig,
  REDIRECT_URI,
  updateRedirectURI,
  REGISTRATION_BASE_URL,
}

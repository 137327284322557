import React from 'react';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { SearchOutlined } from '@ant-design/icons';

import {
  UploadOutlined,
} from '@ant-design/icons';

const { Option } = Select;

export default class CustomSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      value: [],
      fetching: false,
    };
    this.fetchList = debounce(this.fetchList, 800);
  }

  componentDidMount() {
    const { dataList } = this.props;
    if (dataList && dataList.length) {
      const data = dataList.slice(0,1000);
      this.setState({ data });
    }
  }

  fetchList = value => {
    // console.log('fetching list for', value);
    this.setState({ data: [], fetching: true });
    const { dataList } = this.props;
    if (dataList && dataList.length) {
      if (value.length === 0 || value === '') {
        const data = dataList.filter((d) => d.toLowerCase().indexOf(value.toLowerCase()) >= 0).slice(0,1000);
        this.setState({ data, fetching: false });
      } else {
        const data = dataList.filter((d) => d.toLowerCase().indexOf(value.toLowerCase()) >= 0);
        this.setState({ data, fetching: false });
      }
    }
  };

  handleChange = value => {
    this.setState({
      value,
      fetching: false,
    });
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };

  filterOption = (input, option) => {
    if (typeof option.children === 'undefined') {
      return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  render() {
    const { fetching, data, value } = this.state;
    return (
      <Select
        className="select-box"
        style={{ width: 200 }}
        placeholder="Search ticker"
        showSearch
        showArrow
        defaultActiveFirstOption={false}
        value={value}
        onChange={this.handleChange}
        onSearch={this.fetchList}
        filterOption={(input, option) => this.filterOption(input, option)}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        suffixIcon={<SearchOutlined />}
      >
        {data.map(d => <Option key={d} value={d}>{d}</Option>)}
      </Select>
    );
  }
}

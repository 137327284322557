import React, { useContext } from 'react';
import { Row, Col, Table } from 'reactstrap';
import { withHeaderFooter } from '../shared/HeaderFooter';
import ActReportContext from '../ActReportContext';
import { FormatCurrency } from '../shared/Utils';
import NameSponsor from './Common';
import { ViewChart } from './ViewChart';

const Appendix = withHeaderFooter((props) => {
  const reportData = useContext(ActReportContext)
  const { otherFunds } = reportData;
  const { funds, query, correctedQuery } = props.data;
  const items = otherFunds.filter(item => 
    funds.includes(item.ticker) && item.query === query)
  return (
    <React.Fragment>
      <div className="content">
        <div className="pr-v2-header">
          <h3>Appendix</h3>
        </div>
        <div className="pr-v2-ex-summary">
          <div>
            <h4 className="pr-v2-sub-header">Other Relevant Investment options for "{correctedQuery}"</h4>
            <Col xs="12" className="rel-results">
              {items.length? items.map((item, i) => (
                <Row key={i} className="result-row">
                  <Col xs="3" className="fund-symbol text-center">
                    <p className="mb-0">{item.ticker_short}</p>
                    {/* <p className="mb-0">{FormatCurrency(item.nav, 2)}</p> */}
                  </Col>
                  <Col xs="5" className="fund-name">
                    <NameSponsor item={item} name={item.name} sponsor={item.sponsor} />
                  </Col>
                  <Col xs="4" className="fund-gauge text-center">
                    {/*<p className="mb-1">Insure Tech</p>*/}
                    <ViewChart fund={item} i={i} queryVar={item.queryVar}
                      data={item._viewData} chartType={item._viewChartType} size={'sm'} />
                  </Col>
                </Row>
              )):<Row>
                <p style={{marginLeft:0}}>
                  No Other Relevant Investment options for "{correctedQuery}" were found.
                </p>
              </Row>}
            </Col>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
});

export default Appendix;
import React, { Component } from 'react';
import _ from 'lodash';
import {
  Button,
  Spin,
} from 'antd';
import {
  Modal,
  ModalHeader,
  ModalBody,
  // ModalFooter,
  // InputGroup,
  // InputGroupAddon,
  // Input,
  // Button as ReactStrapButton,
} from 'reactstrap';

import { LoadingOutlined } from '@ant-design/icons';

import { isElementOverlapping, getPositionOfAnElement, textCapitalize } from 'layouts/utils';
import { addEventToAnalytics } from 'Utils';
// import { TifinRiskLogo } from 'SvgComponent';

import { ScoreMeter, ClientDetailModal, ResendEmailModal } from './helper';

import EMailPreviewModal from './EMailPreviewModal';

let TifinRiskLogo = '/assets/images/tifin-risk-logo.svg';

// const ALLOW_ACCESS = [
//   'ankit@magnifi.com',
//   'pratik.adv@magnifi.com',
//   'bnickles@ydfinancial.com',
//   'grisha_totum2@magnifi.com',
//   'pratik.advisor@magnifi.com',
//   'rstella@magnifi.com',
//   'anne@magnifi.com',
//   'grisha.advisor@magnifi.com',
//   'pratik.adv5@magnifi.com',
//   'praerewd@aa.com',
//   'anne@magnifi.com',
//   'matt@magnifi.com',
//   'jantolik@magnifi.com',
// ];

class TotumSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        first_name: '',
        last_name: '',
        email: props.advisorEmail || '',
      },
      sendEMailMode: '',
      riskModal: false,
      emailModal: false,
      mailPreviewModal: false,
      resendEmailModal: false,
      min: props.min,
      max: props.max,
      scoreMeter: props.scoreMeter,
      scores: props.renderScoreValues,
      showScoreMeter: props.showScoreMeter,
      renderScoreValues: props.renderScoreValues,
      renderAllScoreValues: props.renderAllScoreValues,
    }
  }

  componentDidMount() {
    const { scoreMeter, loadScoreData, totumAccessEmail, clientId } = this.props;
    // console.log('-- found access ? --->', ALLOW_ACCESS.find((e) => e === totumAccessEmail));
    // if (!totumAccessEmail ||
    //   typeof totumAccessEmail === 'undefined' ||
    //   !ALLOW_ACCESS.find((e) => e === totumAccessEmail) ||
    //   !ALLOW_ACCESS.includes(totumAccessEmail)
    // ) {
    //   console.log('---- Blocked Totum Access For Email ---->', totumAccessEmail);
    //   return null;
    // }
    if (!clientId || typeof clientId === 'undefined' || clientId === null || clientId === '') {
      console.log('---- Missing client ID ---->', clientId);
      return null;
    }
    if (scoreMeter && loadScoreData) {
      let currentScore = (scoreMeter && scoreMeter.find((item) => item.key === 'current')) ? scoreMeter.find((item) => item.key === 'current').value : null;
      if (currentScore === null) {
        loadScoreData();
      }
    }
    if (typeof this.props.advisorName !== 'undefined' && this.props.advisorName !== '' && this.props.advisorName) {
      const name = this.props.advisorName.split(' ');
      const { formData } = this.state;
      let firstName = formData.first_name, lastName = formData.last_name;
      if (name && name.length > 0) {
        firstName = name[0];
        lastName = name.length > 1 ? name[1] : lastName;
        if ((firstName && firstName !== '' && formData.first_name !== firstName) ||
          (lastName && lastName !== '' && formData.last_name !== lastName)
        ) {
          this.setState({
            formData: {
              ...this.state.formData,
              first_name: firstName,
              last_name: lastName,
            },
          });
        }
      }
    }
    if (typeof this.props.advisorEmail !== 'undefined' && this.props.advisorEmail !== '') {
      const { formData } = this.state;
      if (this.props.advisorEmail !== formData.email) {
        this.setState({
          formData: {
            ...formData,
            email: this.props.advisorEmail,
          }
        })
      }
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.renderScoreValues !== this.state.scores) {
      this.setState({
        scores: newProps.renderScoreValues,
      });
    }
    if (newProps.renderAllScoreValues !== this.state.renderAllScoreValues) {
      this.setState({
        renderAllScoreValues: newProps.renderAllScoreValues,
      })
    }
    if (typeof newProps.advisorName !== 'undefined' && newProps.advisorName !== '' && newProps.advisorName) {
      const name = newProps.advisorName.split(' ');
      const { formData } = this.state;
      let firstName = formData.first_name, lastName = formData.last_name;
      if (name && name.length > 0) {
        firstName = name[0];
        lastName = name.length > 1 ? name[1] : lastName;
        if ((firstName && firstName !== '' && formData.first_name !== firstName) ||
          (lastName && lastName !== '' && formData.last_name !== lastName)
        ) {
          this.setState({
            formData: {
              ...this.state.formData,
              first_name: firstName,
              last_name: lastName,
            },
          });
        }
      }
    }
    if (typeof newProps.advisorEmail !== 'undefined' && newProps.advisorEmail !== '') {
      const { formData } = this.state;
      if (newProps.advisorEmail !== formData.email) {
        this.setState({
          formData: {
            ...formData,
            email: newProps.advisorEmail,
          }
        })
      }
    }
  }

  componentDidUpdate() {
    // console.log('--------- totum socre componentDidUpdate ---------');
    this.makeLabelForScoreMeter(this.props);
  }

  makeLabelForScoreMeter = (props) => {
    if (!props) return;
    const { allScoreMeter, clientId } = props;
    if (!clientId || clientId === null || typeof clientId === 'undefined' || isNaN(clientId)) {
      console.log('--------- clientId issue makeLabelForScoreMeter ---------', clientId);
      return null;
    } else {
      if (allScoreMeter) {
        // NOTE: for Current and Enhanced Portfolio Label Placement and Handle Label Collision
        const forCEPRLabelPlacement = allScoreMeter.filter((item) => item.key === 'current' || item.key === 'enhanced').map((kl) => kl.key);
        if (forCEPRLabelPlacement && forCEPRLabelPlacement.length > 1) {
          this.scoreLabelPlacement({
            clientId,
            allScoreMeter,
            node1: forCEPRLabelPlacement[0],
            node2: forCEPRLabelPlacement[1]
          });
          this.handleLabelCollision({
            clientId,
            allScoreMeter,
            node1: forCEPRLabelPlacement[0],
            node2: forCEPRLabelPlacement[1]
          });
        }
        // NOTE: for Risk Perference and Risk Capacity Label Placement and Handle Label Collision
        const forRCPLabelPlacement = allScoreMeter.filter((item) => item.key === 'preference' || item.key === 'capacity').map((kl) => kl.key);
        if (forRCPLabelPlacement && forRCPLabelPlacement.length > 1) {
          this.scoreLabelPlacement({
            clientId,
            allScoreMeter,
            node1: forRCPLabelPlacement[0],
            node2: forRCPLabelPlacement[1]
          });
          this.handleLabelCollision({
            clientId,
            allScoreMeter,
            node1: forRCPLabelPlacement[0],
            node2: forRCPLabelPlacement[1]
          });
        }
      }
    };
  }

  // Score Meter label placement
  scoreLabelPlacement = ({ node1, node2, allScoreMeter, clientId }) => {
    // console.log('---- Score Meter label placement ----');
    if (!clientId || clientId === null || typeof clientId === 'undefined' || isNaN(clientId)) {
      console.log('--------- clientId issue scoreLabelPlacement ---------', clientId);
      return null;
    } else {
      // NOTE: first check for Min and Max Label or Meter Value point
      let minPoint = document.getElementById(`${clientId}-score-min-label`);
      let maxPoint = document.getElementById(`${clientId}-score-max-label`);

      if (!minPoint || !maxPoint) {
        minPoint = document.getElementById(`${clientId}-score-meter-range-track-min`);
        maxPoint = document.getElementById(`${clientId}-score-meter-range-track-max`);
      }

      // NOTE: return 'null' if -> Failed to detect Min and Max label point for score meter
      if (!minPoint || !maxPoint) {
        console.log('---- Failed to detect Min and Max label point for score meter ----');
        return null;
      } else {
        // NOTE: Detect X and Y position of 'minPoint' and 'maxPoint'
        const minPos = getPositionOfAnElement(minPoint);
        // console.log('------- minPos ------->', minPos);
        const maxPos = getPositionOfAnElement(maxPoint);
        // console.log('------- maxPos ------->', maxPos);

        // NOTE: check scale value is it between 0 - 50 or 51 - 100 range ? -> to decide rendering side of element [left side or right side]
        if (node1 && node2 && allScoreMeter && allScoreMeter.length > 1) {
          let filterAllScoreMeter = allScoreMeter.filter((item) => (item.key === node1 || item.key === node2));

          let Node1Score = filterAllScoreMeter.find((item) => (item.key === node1)) ? filterAllScoreMeter.find((item) => (item.key === node1)).value : null;
          let Node2Score = filterAllScoreMeter.find((item) => (item.key === node2)) ? filterAllScoreMeter.find((item) => (item.key === node2)).value : null;

          if (Node1Score && Node1Score !== null && Node2Score && Node2Score !== null) {
            // NOTE: by default select 'minPos' for [Node1Score, Node2Score];
            let selectPosForNode1Score = minPos, selectPosForNode2Score = minPos;
            // NOTE: check which 'pos' to pick for [Node1Score, Node2Score] between 'minPos' and 'maxPos'
            let isNode1ScoreOnLeftSide = (Node1Score >= 0 && Node1Score <= 50);
            let isNode2ScoreOnLeftSide = (Node2Score >= 0 && Node2Score <= 50);

            if (isNode1ScoreOnLeftSide) {
              selectPosForNode1Score = minPos
            } else {
              selectPosForNode1Score = maxPos
            }

            if (isNode2ScoreOnLeftSide) {
              selectPosForNode2Score = minPos
            } else {
              selectPosForNode2Score = maxPos
            }

            // const checkScoreDiff = numberCheck(__scores[1] - __scores[0]);
            // console.log('--- checkScoreDiff for capacity & preference ---> ', checkScoreDiff);

            const node1LabelElement = document.getElementById(`${clientId}-score-meter-range-thumb-title-${node1}`);
            if (node1LabelElement) {
              const node1LabelElementPos = getPositionOfAnElement(node1LabelElement)
              // console.log(node1, '----- selectPosForNode1Score -----> ', selectPosForNode1Score);
              // console.log(node1, '--- node1LabelElementPos --->', node1LabelElementPos);
              if (node1LabelElementPos && selectPosForNode1Score && selectPosForNode1Score.x > node1LabelElementPos.x) {
                if (node1LabelElement && node1LabelElement.style) {
                  if (isNode1ScoreOnLeftSide) {
                    node1LabelElement.style.left = `-${selectPosForNode1Score.x - node1LabelElementPos.x}px` || 0;
                  } else {
                    node1LabelElement.style.right = 0;
                  }

                }
              }
            }
            const node2LabelElement = document.getElementById(`${clientId}-score-meter-range-thumb-title-${node2}`);
            if (node2LabelElement) {
              const node2LabelElementPos = getPositionOfAnElement(node2LabelElement)
              // console.log(node2, '----- selectPosForNode2Score -----> ', selectPosForNode2Score);
              // console.log(node2, '--- node2LabelElementPos --->', node2LabelElementPos);
              if (node2LabelElementPos && selectPosForNode2Score && selectPosForNode2Score.x > node2LabelElementPos.x) {
                if (node2LabelElement && node2LabelElement.style) {
                  if (isNode2ScoreOnLeftSide) {
                    node2LabelElement.style.left = `-${selectPosForNode2Score.x - node2LabelElementPos.x}px` || 0;
                  } else {
                    node2LabelElement.style.right = 0;
                  }
                }
              }
            }

            if (node1LabelElement && node2LabelElement) {
              const collisionDetect = isElementOverlapping(node1LabelElement, node2LabelElement);
              // console.log('--- collisionDetect for capacity & preference ---> ', collisionDetect);
              if (collisionDetect) {
                node2LabelElement.innerHTML = '';
                let labelWillBe = allScoreMeter.filter((item) => (item.key === node1 || item.key === node2)).map((item) => `${item.label}: ${item.value}`).filter((item) => item).join('<span style="padding: 0 7px;">|</span>');
                // console.log('labelWillBe----->', labelWillBe);
                node1LabelElement.innerHTML = labelWillBe;
              }
            }

          } else {
            console.log('--- Failed to find NODE deta from ScoreMeter array ---');
          }

        } else {
          console.log('--- node1, node2 or allScoreMeter -> Params missing ---');
        }

      }

    }
  }

  // Score Meter label placement when collision occured
  handleLabelCollision = ({ node1, node2, allScoreMeter, clientId }) => {
    // console.log('------ handleLabelCollision --------');
    if (!clientId || clientId === null || typeof clientId === 'undefined' || isNaN(clientId)) {
      console.log('--------- clientId issue handleLabelCollision ---------', clientId);
      return null;
    } else {
      if (node1 && node2 && allScoreMeter) {
        let __scores = allScoreMeter.filter((item) => (item.key === node1 || item.key === node2)).map((kl) => kl.value);
        // console.log('======= __scores ========>', __scores);
        if (__scores && __scores.length > 1 && allScoreMeter && allScoreMeter.length > 1) {
          // const checkScoreDiff = numberCheck(__scores[1] - __scores[0]);
          // console.log('--- checkScoreDiff for capacity & preference ---> ', checkScoreDiff);
          const node1LabelElement = document.getElementById(`${clientId}-score-meter-range-thumb-title-${node1}`);
          const node2LabelElement = document.getElementById(`${clientId}-score-meter-range-thumb-title-${node2}`);
          if (node1LabelElement && node2LabelElement) {
            const collisionDetect = isElementOverlapping(node1LabelElement, node2LabelElement);
            // console.log('--- collisionDetect for capacity & preference ---> ', collisionDetect);
            if (collisionDetect) {
              node2LabelElement.innerHTML = '';
              let labelWillBe = allScoreMeter.filter((item) => (item.key === node1 || item.key === node2)).map((item) => `${item.label}: ${item.value}`).filter((item) => item).join('<span style="padding: 0 7px;">|</span>');
              // console.log('labelWillBe----->', labelWillBe);
              node1LabelElement.innerHTML = labelWillBe;
            }
          }
        }
      }
    }
  }

  triggerScoreMeter = (event, label) => {
    console.log('triggerScoreMeter for --> ', label);
  }

  totumRenderButtonOnClick = () => {
    const { portfolioType, advisorId } = this.props;
    if (portfolioType && portfolioType === 'Client Portfolio') {
      if (advisorId === null || !advisorId || advisorId === '') {
        this.setState({
          riskModal: true,
        });
      } else {
        this.setState({
          emailModal: true,
        });
      }
      addEventToAnalytics('Totum Risk Assessment Button', 'Totum Risk Assessment Button', 'TOTUM_RISK_ASSESSMENT_BUTTON', {}, false);
    }
  }

  activateOnClick = () => {
    const { activateOnClick } = this.props;
    if (activateOnClick) {
      activateOnClick(() => {
        this.setState({
          riskModal: false,
          emailModal: true,
        });
      });
    }
    // this.setState({
    //   riskModal: false,
    //   emailModal: true,
    // });
  }

  setEmailModal = (state) => {
    this.setState({
      emailModal: state,
    });
  }

  setRiskModal = (state) => {
    this.setState({
      riskModal: state,
    });
  }

  openEMailPreviewModal = (formData) => {
    this.setEmailModal(false)
    const { clientId } = this.props;
    // console.log('formData -->', formData, clientId);
    if (clientId) {
      let data = {
        ...formData,
        client_id: clientId,
        questionnaire_type: 'long',
      };
      setTimeout(() => {
        this.setState({
          mailPreviewModal: true,
          formData: data,
        });
      }, 500)
      addEventToAnalytics('Totum Risk Assessment Preview', 'Totum Risk Assessment Preview', 'TOTUM_RISK_ASSESSMENT_PREVIEW', {}, false);
    }
  }

  sendMail = (formData) => {
    const { sendEMailMode } = this.state;
    const { sendEmailOnClick, clientId } = this.props;
    if (clientId && sendEmailOnClick && formData) {
      if (formData && formData.signature) {
        delete formData.signature;
      }
      // console.log(formData);
      if (sendEMailMode === 'RESEND') {
        this.resendLink();
      } else {
        sendEmailOnClick({
          ...formData,
          client_id: clientId,
        }, () => {
          console.log('-- callback setEmailModal --');
        });
        addEventToAnalytics('Totum Mail Send Button', 'Totum Mail Send Button', 'TOTUM_MAIL_SEND_BUTTON', {}, false);
      }
      this.closeEMailPreviewModal();
    }
  }

  closeEMailPreviewModal = () => {
    this.setEmailModal(false)
    this.setState({
      mailPreviewModal: false,
    });
  }

  resendLink = () => {
    // console.log('=-= resend totum email =-=');
    const { formData } = this.state;
    const { resendLink, clientId } = this.props;
    if (clientId && formData && resendLink) {
      if (formData.signature) {
        delete formData.signature;
      }
      resendLink({
        ...formData,
        client_id: clientId,
      });
      this.setResendEmailModalState(false);
      // after re-sending email, reset 'sendEMailMode' state
      this.setState({
        sendEMailMode: '',
      });
      addEventToAnalytics('Totum Mail ReSend Button', 'Totum Mail ReSend Button', 'TOTUM_MAIL_RESEND_BUTTON', {}, false);
    }
  }

  setResendEmailModalState = (state) => {
    // console.log('-- setResendEmailModalState --', state);
    this.setState({ resendEmailModal: state });
  }

  checkQuestionnaireStatus = (props) => {
    // console.log('-- resendEmail --', props);
    const { getQuestionnaireStatus } = this.props;
    if (getQuestionnaireStatus) {
      getQuestionnaireStatus((clientDetails) => {
        // console.log('=== callback ===', clientDetails);
        let showScoreMeter = (clientDetails && clientDetails.totum_client_detials && clientDetails.totum_client_detials.length > 0);
        let totumClientDetails = showScoreMeter && clientDetails.totum_client_detials[clientDetails.totum_client_detials.length - 1] ? clientDetails.totum_client_detials[clientDetails.totum_client_detials.length - 1] : null;
        if (totumClientDetails) {
          // console.log('--- totumClientDetails ---', totumClientDetails);
          if (totumClientDetails.questionnaire_status !== 'COMPLETED') {
            this.setResendEmailModalState(true);
          }
        }
      });
    }
    addEventToAnalytics('Get Risk Assessment Results Button', 'Get Risk Assessment Results Button', 'GET_RISK_ASSESSMENT_RESULTS_BUTTON', {}, false);
  }

  resendEmailOkClick = (props) => {
    // console.log(props);
    this.setState({
      emailModal: true,
      sendEMailMode: 'RESEND',
      resendEmailModal: false,
    });
    addEventToAnalytics('Resend Link Popup Yes Button', 'Resend Link Popup Yes Button', 'RESEND_LINK_POPUP_YES_BUTTON', {}, false);
  }

  render() {
    const {
      min,
      max,
      // width,
      // advisorId,
      // scoreMeter,
      profileData,
      // advisorName,
      resendEmail,
      // advisorEmail,
      // clientDetails,
      portfolioType,
      allScoreMeter,
      showScoreMeter,
      // totumAccessEmail,
      totumRiskLoading,
      // renderScoreValues,
      // totumClientDetails,
      resendingTotumEmail,
    } = this.props;

    const { clientId } = this.props;

    // console.log('-- found access ? --->', ALLOW_ACCESS.find((e) => e === totumAccessEmail));

    // if (!totumAccessEmail ||
    //   typeof totumAccessEmail === 'undefined' ||
    //   !ALLOW_ACCESS.find((e) => e === totumAccessEmail) ||
    //   !ALLOW_ACCESS.includes(totumAccessEmail)
    // ) {
    //   console.log('---- Blocked Totum Access For Email ---->', totumAccessEmail);
    //   return null;
    // }

    if (!clientId || typeof clientId === 'undefined' || clientId === null || clientId === '') {
      console.log('---- Missing client ID ---->', clientId);
      return null;
    }

    const {
      emailModal,
      riskModal,
      // scores,
      formData,
      mailPreviewModal,
      resendEmailModal,
      renderAllScoreValues,
    } = this.state;

    let selfName = (profileData && profileData.first_name && profileData.last_name) ? `${textCapitalize(profileData.first_name)} ${textCapitalize(profileData.last_name)}` : '';

    // let currentScore = (allScoreMeter && allScoreMeter.find((item) => item.key === 'current')) ? allScoreMeter.find((item) => item.key === 'current').value : null;
    // let enhancedScore = (allScoreMeter && allScoreMeter.find((item) => item.key === 'enhanced')) ? allScoreMeter.find((item) => item.key === 'enhanced').value : null;

    // console.log('currentScore --> ', currentScore, 'enhancedScore --> ', enhancedScore, renderAllScoreValues);

    const renderScoreMeter = (showScoreMeter && renderAllScoreValues && renderAllScoreValues.length > 0) ? (
      <div className="totum-score-meter-wrapper">
        <span id={`${clientId}-score-min-label`} className="low_high_span">Less Risk</span>
        <ScoreMeter
          // disabled
          step={0.1}
          min={min}
          max={max}
          clientId={clientId}
          scores={renderAllScoreValues}
          scoreMeter={allScoreMeter}
        />
        <span id={`${clientId}-score-max-label`} className="low_high_span pl-2">More Risk</span>
        <div className="totum-logo-area">
          {(resendEmail) && (
            <Button
              type="text"
              className="totum-resend-link pl-2"
              loading={resendingTotumEmail}
              onClick={this.checkQuestionnaireStatus}
            >
              Get Risk Assessment Results
            </Button>
          )}
          <div className="pl-2">
            {/* <span className="text">powered by</span> */}
            <span><img alt='' src='/assets/images/tifin-risk-logo.svg' /></span>
          </div>
        </div>
      </div>
    ) : null;

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (
      <div className="totum-risk-bar-container">
        {(totumRiskLoading) ? (
          <div className="totum-risk-bar-loader">
            <Spin indicator={antIcon} />
          </div>
        ) : (
          <>
            {(showScoreMeter) ? renderScoreMeter : (
              <>
                <Button
                  className={(portfolioType && portfolioType === 'Client Portfolio') ? 'primary-cta-totum' : 'primary-cta-totum disabled'}
                  onClick={this.totumRenderButtonOnClick}
                >
                  RISK ASSESSMENT <img src={TifinRiskLogo} width={'auto'} height={12} style={{ paddingLeft: 10, width: 'auto', height: 12 }} />
                </Button>
              </>
            )}
          </>
        )}

        {(resendEmailModal) && (
          <ResendEmailModal
            visible={resendEmailModal}
            title={null}
            wrapClassName={`totum-questionnaire-email-preview-modal`}
            onClickOk={(props) => this.resendEmailOkClick(props)}
            onModalClose={() => this.setResendEmailModalState(false)}
          />
        )}

        {(emailModal) && (
          <ClientDetailModal
            loading={totumRiskLoading}
            visible={emailModal}
            formData={formData}
            title={<div class="title-head">Risk Assessment</div>}
            wrapClassName={`totum-questionnaire-form-modal`}
            onClickOk={(props) => this.openEMailPreviewModal(props)}
            onModalClose={() => this.setEmailModal(false)}
          />
        )}

        {(mailPreviewModal) && (
          <EMailPreviewModal
            width={720}
            footer={null}
            centered={true}
            selfName={selfName}
            formData={formData}
            loading={totumRiskLoading}
            visible={mailPreviewModal}
            title={<div class="title-head">Preview</div>}
            wrapClassName={`totum-questionnaire-email-preview-modal`}
            onClickOk={(props) => this.sendMail(props)}
            onModalClose={this.closeEMailPreviewModal}
          />
        )}

        <Modal
          backdrop
          centered
          isOpen={riskModal}
          className="sent-invite-modal"
          backdropClassName="send-invite-backdrop"
          toggle={() => this.setRiskModal(false)}
        >
          <i className="fal fa-times-circle premium-feature-completed-close send-invite-close" onClick={() => this.setRiskModal(false)}></i>
          <ModalHeader>
            <span className="heading">
              Personal Risk Assessment <img src={TifinRiskLogo} className="icon_in_heading" width={'auto'} height={12} style={{ paddingLeft: 10, width: 'auto', height: 12 }} />
            </span>
          </ModalHeader>
          <ModalBody style={{ paddingTop: 10 }}>
            <div className="w-100 text-center">
              <p style={{ fontSize: 17 }}>
                This risk tolerance questionnaire evaluates a client's risk capacity, risk preference, and portfolio risk score.
              </p>
            </div>
            <button className="send" onClick={this.activateOnClick}>
              Activate
            </button>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default TotumSection;

export default {
    _GoogleClientID: '326538060414-5vqrgaosddu857h2siom011r796tfl4j.apps.googleusercontent.com',
    GoogleClientID: '444564269788-i2f23dh0svn4jb8ckuevjvbh9067p8kc.apps.googleusercontent.com'
};

export const Coverage_Logo = '/assets/images/MagnifiLogo/Magnifi_byTIFIN-darkgrey.svg';
export const Coverage_poweredByLogo = '/assets/images/pow-by-magnifi-blue.png';
export const ProposalCoverImage = '/assets/images/ProposalCoverIcon.svg';
export const CoverFooterIcon = '/assets/images/CoverFooterIcon.png';
export const PoweredByIcon = 'assets/images/PoweredByIcon.png';

export const Header_Footer_Logo = '/assets/images/MagnifiLogo/magnifi_discover_insvt.png';
export const Header_Footer_poweredByLogo = '/assets/images/pow-by-magnifi-black.png';

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { setEnhancedCard  } from 'actions/portfolio';
import { Menu, Dropdown, Icon, Row, Col } from 'antd';

const classNames = require('classnames');

class Autocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  };

  static defaultProps = {
    suggestions: []
  };

  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: ""
    };
  }

  onChange = e => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    });
  };

  onClick = e => {
    const { overlapTicker } = this.props;
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });

    this.props.setEnhancedCard({ticker:e.currentTarget.innerText,overlap:overlapTicker});
  };

  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion]
      });
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    const { disabled } = this.props;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul class="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div class="no-suggestions">
            <em>No suggestions, you're on your own!</em>
          </div>
        );
      }
    }

    return (
      <div style={{ position: 'relative' }}>
        <input
          id="ticker-search-box"
          value={userInput}
          onChange={onChange}
          className="bg-white input-search"
          type="text"
          placeholder="Enter ticker here"
          autocomplete="off"
          aria-label="Search"
          style={{ width:'125px', fontSize:'12px', border:'0', borderBottom:'1px solid #6c757d', paddingRight: 25 }}
          disabled={disabled || false}
        />

        {suggestionsListComponent}

         <i className="far fa-fw fa-search fa-md text-secondary search-icon search-ticker-icon"
              style={{top:'17px',right:'50px'}}
           />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
    //enhancementCardData: state.portfolio.enhancementCardData,
  })

  const mapDispatchToProps = {
    setEnhancedCard,
  }

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Autocomplete)

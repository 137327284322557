import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button , ButtonGroup } from 'reactstrap'
import { PrimaryButton } from 'shared-components'
import PaymentSuccessMessage from './PaymentSuccessMessage';
import PaymentFailureMessage from './PaymentFailureMessage';

class PaymentStatusModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      closePaymentModal: true
    }
  }

  closePaymentStatus = () => {
    this.setState({closePaymentModal: false})
    window.sessionStorage.removeItem('paymentStatus')
  }

  render() {
    let renderModal = '';
    const paymentStatus = this.props.paymentStatus || '';
    if (paymentStatus.includes('1')) {
      renderModal = <PaymentSuccessMessage planName={this.props.planName} closePaymentStatus={this.closePaymentStatus} />
    } else if (paymentStatus.includes('0')) {
      renderModal = <PaymentFailureMessage closePaymentStatus={this.closePaymentStatus} userEmail={this.props.userEmail} />
    }
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.closePaymentModal}
          toggle={() => {
            this.setState({closePaymentModal: false})
            window.sessionStorage.removeItem('paymentStatus')
          }}
          centered
          keyboard={true}
          backdrop
          className="premium-msg-modal-sm-light"
          backdropClassName="premium-feature-process-modal-backdrop"
          >
          <ModalBody className="ssf-modal__body">
            {renderModal}
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default PaymentStatusModal

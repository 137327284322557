export const demoPortfolioJSON = [
  {
    "ticker": "SPY",
    "price": 290.3703704,
    "shares": 351,
    "date": "22-06-2019"
  },
  {
    "ticker": "EEM",
    "price": 47.14516999,
    "shares": 1853,
    "date": "18-09-2019"
  },
  {
    "ticker": "ACWI",
    "price": 74.53924915,
    "shares": 1172,
    "date": "25-07-2019"
  },
  {
    "ticker": "IYW",
    "price": 264,
    "shares": 420,
    "date": "29-04-2019"
  },
  {
    "ticker": "HYG",
    "price": 85.98425197,
    "shares": 1016,
    "date": "27-07-2018"
  },
  {
    "ticker": "IAT",
    "price": 33,
    "shares": 2250,
    "date": "04-02-2020"
  },
  {
    "ticker": "IYE",
    "price": 21,
    "shares": 4000,
    "date": "26-08-2019"
  },
  {
    "ticker": "IYH",
    "price": 210,
    "shares": 600,
    "date": "03-04-2020"
  }
];

export default {
	demoPortfolioJSON,
}

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Row, Col, Button, message } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import {
	setEnhancerState,
	resetFundsPortfolioRequest,
} from 'actions/enhancer';
import { addEventToAnalytics } from 'Utils'

class ContentHead extends Component {
	constructor(props) {
		super(props);
		this.state = {

		}
	}

	routeToOnBoardScreen = () => {
		addEventToAnalytics('Enhancer Edit Home', 'Enhancer Edit Home', 'ENHANCER_EDIT_HOME', {}, false);
		const { history, resetFundsPortfolioRequest } = this.props;
		if (resetFundsPortfolioRequest && history) {
			resetFundsPortfolioRequest('', () => {
				const newPath = `/enhancer/onboard-screen`;
				history.push(newPath);
			})
		}
	}

	drawerToggle = () => {
		const { summaryDrawer, openDrawer } = this.props;
		if (openDrawer) {
			openDrawer(!summaryDrawer);
		}
	}

	finalize = () => {
		const { finalizeClick, enhanceReplace } = this.props;
		let analytics_data = enhanceReplace.map(item => {
			return {
				ticker: item.ticker,
				replace: item.replace
			}
		})
		if (finalizeClick) {
			addEventToAnalytics('Enhancer Finalize', 'Enhancer Finalize', 'ENHANCER_FINALIZE', { funds: analytics_data, category: 'advisory_PTC' }, false);
			finalizeClick();
		}
	}

	generateProposal = () => {
		const { generateProposalClick } = this.props;
		if (generateProposalClick) {
			//false for enhancer report
			generateProposalClick(false);
		}
	}

	generateRecommendationReport = () => {
		const { generateProposalClick } = this.props;
		if (generateProposalClick) {
			//true for recommendation report
			generateProposalClick(true);
		}
	}

	generateTradelist = () => {
		const { generateTradeListClick } = this.props;
		if (generateTradeListClick) {
			generateTradeListClick();
		}
	}

	savePortfolio = () => {
		addEventToAnalytics('Enhancer Save Btn', 'Enhancer Save Btn', 'ENHANCER_SAVE_BTN', { slider: this.state.label }, false)
		const { savePortfolioClick } = this.props;
		if (savePortfolioClick) {
			savePortfolioClick();
		}
	}

	render() {
		const { portfolioName, readableQuery, guidanceMessage, finalizeData, enhanceReplace, redirectToPath, portfolioType, sponsoredPortfolioName } = this.props;
		const noData = !enhanceReplace || !enhanceReplace.length || _.isEmpty(enhanceReplace[0].selectedPortfolioFunds);
		return (
			<React.Fragment>
				<div id="funds-board-head" className="funds-board-head">
					<Row id="funds-board-content-header" className="pf-content-header m-0">
						<Col span={24} className="d-flex col-wrap">
							<Col span={20} className="enhancer-portfolio-name-container">
								<span style={{ color: '#494949' }}>Enhancement Ideas For </span>
								<span>
									{(portfolioType && portfolioType === 'MarketPlace' && sponsoredPortfolioName && sponsoredPortfolioName !== '') ? sponsoredPortfolioName : portfolioName}
								</span>
							</Col>
							<Col span={4} className="d-flex align-items-center" style={{ justifyContent: 'flex-end' }}>
								<Button
									shape="round"
									className="primary-button return-home-button"
									type="primary"
									style={{ padding: 0 }}
									onClick={this.routeToOnBoardScreen}
								>
									<img width="28px" src="/assets/images/reload.svg" />
								</Button>
							</Col>
						</Col>
						{(readableQuery && readableQuery !== '') && (
							<>
								{(finalizeData &&
									finalizeData.portfolioName &&
									finalizeData.summary &&
									finalizeData.onboardData &&
									finalizeData.enhancedPortfolioId &&
									finalizeData.onboardData.selectedPortfolioType &&
									typeof finalizeData.summary !== 'undefined' &&
									typeof finalizeData.summary.retData !== 'undefined' &&
									finalizeData.summary.retData.length > 0 &&
									typeof finalizeData.summary.portfolioFunds !== 'undefined' &&
									finalizeData.summary.portfolioFunds.length > 0) ? (
									<></>
								) : (
									<Col span={24} className="d-flex pf-showing-result-label col-wrap showing-result-col">
										<Col span={18}>
											{guidanceMessage && guidanceMessage.length > 0 ? guidanceMessage[0] : `Showing results for “${readableQuery}“`}
										</Col>
										<Col span={6} className="d-flex align-items-center evaluate-col" style={{ justifyContent: 'flex-end' }}></Col>
									</Col>
								)}
							</>
						)}
					</Row>
					<Row className="pt-2 pb-2 m-0">
						<Col span={18} className="d-flex">

							{!(finalizeData &&
								finalizeData.portfolioName &&
								finalizeData.summary &&
								typeof finalizeData.summary !== 'undefined' &&
								typeof finalizeData.summary.retData !== 'undefined') && (
									<span style={{ marginRight: 10 }}>
										<Button className="primary-cta-button" onClick={this.finalize} disabled={noData}>FINALIZE</Button>
									</span>
								)}

							{(finalizeData &&
								finalizeData.portfolioName &&
								finalizeData.summary &&
								typeof finalizeData.summary !== 'undefined' &&
								typeof finalizeData.summary.retData !== 'undefined') && <span style={{ marginRight: 10 }}>
									<Button className="primary-cta-button" onClick={this.generateTradelist}>GENERATE TRADELIST</Button>
								</span>}

							{!(finalizeData &&
								finalizeData.portfolioName &&
								finalizeData.summary &&
								typeof finalizeData.summary !== 'undefined' &&
								typeof finalizeData.summary.retData !== 'undefined') ? <span style={{ marginRight: 10 }}>
								<Button className="primary-cta-button" onClick={this.generateRecommendationReport} disabled={noData}>GENERATE PROPOSAL</Button>
							</span> : <span style={{ marginRight: 10 }}>
								<Button className="primary-cta-button" onClick={this.generateProposal}>GENERATE PROPOSAL</Button>
							</span>}

							{(finalizeData &&
								finalizeData.portfolioName &&
								finalizeData.summary &&
								typeof finalizeData.summary !== 'undefined' &&
								typeof finalizeData.summary.retData !== 'undefined') && (
									<span style={{ marginRight: 10 }}>
										<Button className="primary-cta-button" onClick={this.savePortfolio}>
											{redirectToPath && redirectToPath !== '' ? "SAVE & RETURN" : "SAVE PORTFOLIO"}
										</Button>
									</span>
								)}

						</Col>
						<Col span={6} className="d-flex align-items-center" style={{ justifyContent: 'flex-end' }}>
							<Button className="primary-cta-button" onClick={this.drawerToggle} style={{ paddingRight: 32 }}>PERFORMANCE IMPACT <RightOutlined /></Button>
						</Col>
					</Row>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ router, loading, enhancer }) => ({
	location: router.location,
	portfolioName: enhancer.portfolioName,
	enhanceReplace: enhancer.enhanceReplace,
	portfolioType: enhancer.portfolioType,
	sponsoredPortfolioName: enhancer.sponsoredPortfolioName,
	enhanceTickerFilter: enhancer.enhanceTickerFilter,

});

const mapDispatchToProps = {
	setEnhancerState,
	resetFundsPortfolioRequest,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ContentHead);

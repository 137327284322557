import React, { Component, useState, useEffect, Fragment, useCallback } from 'react'
import { PlaidLink, usePlaidLink } from 'react-plaid-link'
import { Link, Redirect } from 'react-router-dom'
import { get, isEmpty, toLower } from 'lodash'
import {
  ButtonGroup,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip,
  UncontrolledTooltip,
  Modal, ModalBody
} from 'reactstrap'

import { CloseButton, CustomFormFeedback, Spinner } from 'shared-components'
import { PrimaryButton, SecondaryButton, DatePicker, DropdownDatePicker } from 'shared-components/'
import {
  employmentType,
  countryList,
  countryList1,
  accountType,
  maritalStatus,
  occupation,
  tradingExperience,
  relations,
  financialGoals,
  liquidityTime,
  uploadType,
  usStates,
  trustedContactCountry,
  investMentObjectives,
  annualIncomeRange,
  formattedRange,
  liquidNetWorth,
  totalNetWorth,
  riskTolerance,
  dividentReinvestment,
  SUPPORTED_FORMATS,
  FILE_SIZE,
  taxBracket,
  TEARM_LINKS,
  POLITICAL_TOOLTIP_CONTENT
} from '../Constant/FormData'

import {
  Carousel,
  CarouselItem,
  CarouselIndicators,
  Spinner as SpinnerR
} from 'reactstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import clx from 'classnames'
import { REDIRECT_DOMAIN_PATH } from 'DataSet'
import { IconButton } from 'shared-components'
import './TradingForm.scss'
import { Regex } from 'common/regex'
import { Checkbox, Select, Steps } from 'antd'
import { SaveOutlined, ReloadOutlined } from '@ant-design/icons';
import moment from 'moment'
import AddMoneyPopup from '../SFM/ActTab/AddMoney'
import { fetchApexBalance, saveForReview, uploadReviewImage } from 'apis/funds'
import { fetchBalance } from '../SFM/ActTab/ItemList'
import { getApexAccountStatus } from 'apis/register'
import { getCityUsingZip } from 'apis/auth'
import { uploadCustomLogo } from 'apis/profile'

import * as RegisterApi from 'apis/register'
import 'cleave.js/dist/addons/cleave-phone.i18n.js'
import Cleave from 'cleave.js/react'
import {
  SecondaryTradingFormRHS3,
  SecondaryTradingFormRHS4
} from './SecondaryTradingForm'
import { SketchField, Tools } from 'react-sketch'
import SkipModal from './SkipModal/SkipModal'
import { capitalizeAll } from '../../utils'
import PhoneNumberInput from '../../../components/PhoneNumberInput'
import CustomAlert from 'components/CustomAlert';
import { addEventToAnalytics } from 'Utils';
import { finalizeReplaceModelPortfolioSuccess } from 'actions/portfolio'
import VideoPlayer from 'components/VideoPlayer'

const { Option } = Select
const { Step } = Steps;
const apex_disclosures = '/assets/disclosures/apex_disclosures.pdf'

const magnifiLogo =
  '/assets/images/MagnifiLogo/Magnifi_Tagline_Charcoal_RGB.png'
const magnifiLogoWhite =
  '/assets/images/MagnifiLogo/Magnifi_Tagline_White_RGB@2x.png'
const lhsmuchmore = '/assets/images/misc/2-Much-More.png'
const lhsCalculator = '/assets/images/misc/1-Calculator.png'
const lhsEnhance = '/assets/images/misc/2-Enhancement.png'
const del = '/assets/images/delete.svg'
const magnifiInvestResponsiblyLogo = '/assets/images/register-magnifi-invest-resp-logo.svg';
const blueLogo = '/assets/images/Magnifi_Invest_res.svg'

const renderStepTwo = (props, canOverRide, values, step, toggleSkipModal, formProps, isSubmitStep, finishLaterCall) => {
  if (props.isBranded && canOverRide === false) {
    //client is branded
    return (
      <BrandingTradingFormRHS3
        finishLaterCall={finishLaterCall}
        formProps={formProps}
        saveData={props.saveData}
        isSubmitStep={isSubmitStep}
        merger={true}
        step={step}
        isBranded={props.isBranded}
        profile={props.profile}
        toggleSkipModal={toggleSkipModal}
      />
    )
  }
  return null;
}

const renderStepThree = (props, canOverRide, values, step, toggleSkipModal, formProps, isSubmitStep, finishLaterCall, toggleTermsModal) => {
  if (values.account_type === 'Joint') {
    return (
      <SecondaryTradingFormRHS4
        toggleTermsModal={toggleTermsModal}
        finishLaterCall={finishLaterCall}
        formProps={formProps}
        saveData={props.saveData}
        step={step}
        hideStepBar={true}
        profile={props.profile}
        isSubmitStep={isSubmitStep}
        isBranded={props.isBranded}
        toggleSkipModal={toggleSkipModal}
      />
    )
  }
  return null;
}


const RenderStep = ({ step, formProps, props, toggleSkipModal, handleCreateAccount, bankAccountDetails, bankAccountDataStatus, institution_name, linkToken, isSubmitStep, createAchRelation, setAccountTypeG, finshThisLater, mapAbbrToState, toggleTermsModal }) => {
  console.log('step----------', step, formProps)
  let canOverRide = false

  const { values } = formProps

  if (values.account_type === 'Joint') {
    // if (props.isBranded && step === 4) {
    //   step = 3
    //   canOverRide = true
    // }
    // if (props.isBranded && step === 5) {
    //   step = 4
    //   canOverRide = true
    // }
    if (props.isBranded && step === 6) {
      step = 5
      canOverRide = true
    }
    if (props.isBranded && step === 7) {
      step = 6
      canOverRide = true
    }
  } else {
    if (props.isBranded && step === 4) {
      step = 3
      canOverRide = true
    }
  }

  const finishLaterCall = () => {
    let email = localStorage.getItem('email') || window.sessionStorage.getItem('email') || "";
    addEventToAnalytics(`Step ${step} I Will Finish This Later`, `Step ${step} I Will Finish This Later`, `STEP_${step}_I_WILL_FINISH_THIS_LATER`, { email: email }, true);
    props.saveData(formProps.values, true);
    props.history.push('/securities');
  }

  useEffect(() => {
    if (finshThisLater > 1) {
      finishLaterCall();
    }
  }, [finshThisLater]);


  switch (step) {
    case 1:
      return (
        <TradingFormRHS1
          finishLaterCall={finishLaterCall}
          saveData={props.saveData}
          fromRegister={props.fromRegister}
          setAccountTypeG={setAccountTypeG}
          profile={props.profile}
          formProps={formProps}
          step={step}
          isBranded={props.isBranded}
          toggleSkipModal={toggleSkipModal}
          apexRedirect={props.apexRedirect}
          isSubmitStep={isSubmitStep}
        />
      )
    case 2:
      return (
        <>
          {renderStepTwo(props, canOverRide, values, step, toggleSkipModal, formProps, isSubmitStep, finishLaterCall)}
          <TradingFormRHS2
            toggleTermsModal={toggleTermsModal}
            formProps={formProps}
            finishLaterCall={finishLaterCall}
            hideStepBar={true}
            saveData={props.saveData}
            step={step}
            profile={props.profile}
            isBranded={props.isBranded}
            toggleSkipModal={toggleSkipModal}
            isSubmitStep={isSubmitStep}
          />
        </>
      )
    case 3:

      if (values.account_type === 'Joint') {
        return (
          <>
            <SecondaryTradingFormRHS3
              formProps={formProps}
              mapAbbrToState={mapAbbrToState}
              finishLaterCall={finishLaterCall}
              saveData={props.saveData}
              isSubmitStep={isSubmitStep}
              step={step}
              merger={true}
              profile={props.profile}
              isBranded={props.isBranded}
              toggleSkipModal={toggleSkipModal}
            />
            {renderStepThree(props, canOverRide, values, step, toggleSkipModal, formProps, isSubmitStep, finishLaterCall, toggleTermsModal)}
          </>
        )
      }

      return (
        <TradingFormRHS3
          formProps={formProps}
          finishLaterCall={finishLaterCall}
          isSubmitStep={isSubmitStep}
          bankAccountDetails={bankAccountDetails}
          bankAccountDataStatus={bankAccountDataStatus}
          institution_name={institution_name}
          linkToken={linkToken}
          saveData={props.saveData}
          step={step}
          setStep={props.setStep}
          getLinkToken={props.getLink}
          getUsersAccounts={props.getUsersAccounts}
          createAccount={props.createAccount}
          history={props.history}
          isBranded={props.isBranded}
          toggleSkipModal={toggleSkipModal}
          handleCreateAccount={handleCreateAccount}
          createAchRelation={createAchRelation}
        />
      )
    case 4:
      if (values.account_type === 'Joint') {
        return (
          <TradingFormRHS3
            formProps={formProps}
            finishLaterCall={finishLaterCall}
            isSubmitStep={isSubmitStep}
            saveData={props.saveData}
            bankAccountDetails={bankAccountDetails}
            bankAccountDataStatus={bankAccountDataStatus}
            institution_name={institution_name}
            linkToken={linkToken}
            step={step}
            setStep={props.setStep}
            getLinkToken={props.getLink}
            getUsersAccounts={props.getUsersAccounts}
            createAccount={props.createAccount}
            history={props.history}
            isBranded={props.isBranded}
            toggleSkipModal={toggleSkipModal}
            handleCreateAccount={handleCreateAccount}
            createAchRelation={createAchRelation}
          />
        )
      }
      return (
        <TradingFormRHS4
          formProps={formProps}
          finishLaterCall={finishLaterCall}
          isSubmitStep={isSubmitStep}
          saveData={props.saveData}
          step={step}
          getLinkToken={props.getLink}
          getUsersAccounts={props.getUsersAccounts}
          createAccount={props.createAccount}
          history={props.history}
          isBranded={props.isBranded}
          toggleSkipModal={toggleSkipModal}
        />
      )
    case 5:
      return (
        <TradingFormRHS5
          fetchProfile={props.fetchProfile}
          finishLaterCall={finishLaterCall}
          isSubmitStep={isSubmitStep}
          saveData={props.saveData}
          profile={props.profile}
          formProps={formProps}
          step={step}
          getLinkToken={props.getLink}
          getUsersAccounts={props.getUsersAccounts}
          createAccount={props.createAccount}
          history={props.history}
          isBranded={props.isBranded}
          toggleSkipModal={toggleSkipModal}
        />
      )
    case 6:
      if (values.account_type === 'Joint') {
        return (
          <TradingFormRHS4
            formProps={formProps}
            isSubmitStep={isSubmitStep}
            finishLaterCall={finishLaterCall}
            saveData={props.saveData}
            step={step}
            getLinkToken={props.getLink}
            getUsersAccounts={props.getUsersAccounts}
            createAccount={props.createAccount}
            history={props.history}
            isBranded={props.isBranded}
            toggleSkipModal={toggleSkipModal}
          />
        )
      }
      return (
        <TradingFormRHS1
          formProps={formProps}
          finishLaterCall={finishLaterCall}
          isSubmitStep={isSubmitStep}
          saveData={props.saveData}
          step={step}
          isBranded={props.isBranded}
          toggleSkipModal={toggleSkipModal}
        />
      )
    default:
      return (
        <TradingFormRHS1
          formProps={formProps}
          finishLaterCall={finishLaterCall}
          isSubmitStep={isSubmitStep}
          saveData={props.saveData}
          step={step}
          isBranded={props.isBranded}
          toggleSkipModal={toggleSkipModal}
        />
      )
  }
}

const mapAbbrToState = abbr =>
  usStates.filter(item => item.abbreviation === abbr).length
    ? usStates.filter(item => item.abbreviation === abbr)[0].name
    : ''

const TradingFormOpts = props => {
  let validationSchema = {}

  let { profile, savedProgress } = props
  const formInitialValues = {
    tooltipOpen: false,
    account_type: savedProgress && savedProgress.account_type || '',
    marital_status: savedProgress && savedProgress.marital_status || '',
    citizenship: savedProgress && savedProgress.citizenship || 'US',
    citizenshipInput: savedProgress && savedProgress.citizenshipInput || 'United States',
    citizenship_value: savedProgress && savedProgress.citizenship_value || 'United States',
    phone_code: savedProgress && savedProgress.phone_code ? savedProgress.phone_code : profile && profile.phone_code ? profile.phone_code : '',
    phone_number: savedProgress && savedProgress.phone_number ? savedProgress.phone_number : profile && profile.phone ? profile.phone : '',
    ssn: savedProgress && savedProgress.ssn || '',
    experience: savedProgress && savedProgress.experience || '',
    experience_value: savedProgress && savedProgress.experience_value || '',
    identity_proof: savedProgress && savedProgress.identity_proof || '',
    address_proof: savedProgress && savedProgress.address_proof || '',
    citizenship_proof: savedProgress && savedProgress.citizenship_proof || '',
    firstname: savedProgress && savedProgress.firstname ? savedProgress.firstname : profile && profile.first_name ? profile.first_name : '',
    lastname: savedProgress && savedProgress.lastname ? savedProgress.lastname : profile && profile.last_name ? profile.last_name : '',
    // primary_firstname     : "",
    // primary_lastname      : "",
    secondary_applicant_first_name: savedProgress && savedProgress.secondary_applicant_first_name || '',
    secondary_applicant_last_name: savedProgress && savedProgress.secondary_applicant_last_name || '',
    are_you_employed: savedProgress && savedProgress.are_you_employed || '',
    trusted_contact: savedProgress && savedProgress.trusted_contact && savedProgress.trusted_contact === 'true' ? true : false,
    trusted_select: savedProgress && savedProgress.trusted_select ? JSON.parse(savedProgress.trusted_select) : ['trusted_email'],
    trusted_contact_email: savedProgress && savedProgress.trusted_contact_email || '',
    trusted_contact_phone: savedProgress && savedProgress.trusted_contact_phone || '',
    trusted_contact_firstname: savedProgress && savedProgress.trusted_contact_firstname || '',
    trusted_contact_lastname: savedProgress && savedProgress.trusted_contact_lastname || '',
    trusted_contact_country_code: savedProgress && savedProgress.trusted_contact_country_code || '+1',
    trusted_contact_country: savedProgress && savedProgress.trusted_contact_country || 'USA',
    trusted_contact_state: savedProgress && savedProgress.trusted_contact_state || '',
    trusted_contact_city: savedProgress && savedProgress.trusted_contact_city || '',
    trusted_contact_street: savedProgress && savedProgress.trusted_contact_street || '',
    trusted_contact_zipcode: savedProgress && savedProgress.trusted_contact_zipcode || '',
    country_input: savedProgress && savedProgress.country_input || 'United States',
    country_value: savedProgress && savedProgress.country_value || '',
    employer_name: savedProgress && savedProgress.employer_name || '',
    occupation: savedProgress && savedProgress.occupation || '',
    are_you_family_member: savedProgress && savedProgress.are_you_family_member && savedProgress.are_you_family_member === 'true' ? true : false,
    stock_name: savedProgress && savedProgress.stock_name || '',
    are_you_authorize: savedProgress && savedProgress.are_you_authorize && savedProgress.are_you_authorize === 'true' ? true : false,
    upload_type: savedProgress && savedProgress.upload_type || '',
    upload_3210: savedProgress && savedProgress.upload_3210 || '',
    upload_3210_name: savedProgress && savedProgress.upload_3210_name || '',
    upload_407: savedProgress && savedProgress.upload_407 || '',
    upload_407_name: savedProgress && savedProgress.upload_407_name || '',
    firm_name: savedProgress && savedProgress.firm_name || '',
    reg_bi_form: savedProgress && savedProgress.reg_bi_form || '',
    // work_for_another_brokerage: false,
    are_you_exposed: savedProgress && savedProgress.are_you_exposed && savedProgress.are_you_exposed === 'true' ? true : false,
    political_organization: savedProgress && savedProgress.political_organization ? JSON.parse(savedProgress.political_organization) : [{ political_organization: '', relation: '' }],
    // relation              : [],
    terms_and_agreement: savedProgress && savedProgress.terms_and_agreement && savedProgress.terms_and_agreement === 'true' ? true : false,
    magnifi_terms_and_conditions: savedProgress && savedProgress.magnifi_terms_and_conditions && savedProgress.magnifi_terms_and_conditions === 'true' ? true : true,
    apex_terms_and_conditions: savedProgress && savedProgress.apex_terms_and_conditions && savedProgress.apex_terms_and_conditions === 'true' ? true : true,
    enroll_in_sweep_program: savedProgress && savedProgress.enroll_in_sweep_program && savedProgress.enroll_in_sweep_program === 'true' ? true : false,
    account_id: '',
    plaid_user_id: savedProgress && savedProgress.plaid_user_id || '',
    stateInput: savedProgress && savedProgress.stateInput || '',
    state_value: savedProgress && savedProgress.state_value || '',
    self_role: savedProgress && savedProgress.self_role || '',
    country: savedProgress && savedProgress.country ? savedProgress.country : profile && profile.country ? profile.country : 'United States',
    countryInputP: savedProgress && savedProgress.countryInputP ? savedProgress.countryInputP : profile && profile.country ? profile.country : 'United States',
    state: savedProgress && savedProgress.state ? savedProgress.state : profile && profile.state ? profile.state : '',
    stateInputP: savedProgress && savedProgress.stateInputP ? savedProgress.stateInputP : profile && profile.state ? mapAbbrToState(profile.state) : '',
    stateValueP: savedProgress && savedProgress.stateValueP ? savedProgress.stateValueP : profile && profile.state ? profile.state : '',
    zipcode: savedProgress && savedProgress.zipcode ? savedProgress.zipcode : profile && profile.zipcode ? profile.zipcode : '',
    date_of_birth:
      (savedProgress && savedProgress.date_of_birth && !props.fromRegister)
        ? (moment().diff(moment(savedProgress.date_of_birth), 'years') >= 18)
          ? moment(savedProgress.date_of_birth, 'YYYY-MM-DD').format('YYYY-MM-DD')
          : ''
        : (profile && profile.date_of_birth)
          ? (moment().diff(moment(profile.date_of_birth), 'years') >= 18)
            ? moment(profile.date_of_birth).utc().format('YYYY-MM-DD')
            : ''
          : '',
    address: savedProgress && savedProgress.address ? savedProgress.address : profile && profile.address ? profile.address : '',
    city: savedProgress && savedProgress.city ? savedProgress.city : profile && profile.city ? profile.city : '',
    // step 3
    secondary_account_first_name: savedProgress && savedProgress.secondary_account_first_name || '',
    secondary_account_last_name: savedProgress && savedProgress.secondary_account_last_name || '',
    secondary_account_country_code: savedProgress && savedProgress.secondary_account_country_code || '+1',
    secondary_account_phone_number: savedProgress && savedProgress.secondary_account_phone_number || '',
    secondary_account_citizenship: savedProgress && savedProgress.secondary_account_citizenship || 'US',
    secondary_account_citizenshipInput: savedProgress && savedProgress.secondary_account_citizenshipInput || 'United States',
    secondary_account_citizenship_value: savedProgress && savedProgress.secondary_account_citizenship_value || 'United States',
    secondary_account_ssn: savedProgress && savedProgress.secondary_account_ssn || '',
    secondary_account_user_email: savedProgress && savedProgress.secondary_account_user_email || '',
    secondary_dob: savedProgress && savedProgress.secondary_dob || '',
    secondary_account_country_value: savedProgress && savedProgress.secondary_account_country_value || 'USA',
    secondary_account_country_name: savedProgress && savedProgress.secondary_account_country_name || 'United States',
    secondary_account_state_value: savedProgress && savedProgress.secondary_account_state_value || '',
    secondary_account_stateInput: savedProgress && savedProgress.secondary_account_stateInput || '',
    secondary_account_street_value: savedProgress && savedProgress.secondary_account_street_value || '',
    secondary_account_city: savedProgress && savedProgress.secondary_account_city || '',
    secondary_account_zipcode: savedProgress && savedProgress.secondary_account_zipcode || '',
    // step 4
    secondary_account_timeHorizon: savedProgress && savedProgress.secondary_account_timeHorizon || '',
    secondary_account_liquidityNeeds: savedProgress && savedProgress.secondary_account_liquidityNeeds || '',
    secondary_account_are_you_family_member: savedProgress && savedProgress.secondary_account_are_you_family_member && savedProgress.secondary_account_are_you_family_member === 'true' ? true : false,
    secondary_account_stock_name: savedProgress && savedProgress.secondary_account_stock_name || '',
    secondary_account_upload_type: savedProgress && savedProgress.secondary_account_upload_type || '',
    secondary_account_are_you_authorize: savedProgress && savedProgress.secondary_account_are_you_authorize && savedProgress.secondary_account_are_you_authorize === 'true' ? true : false,
    secondary_account_are_you_exposed: savedProgress && savedProgress.secondary_account_are_you_exposed && savedProgress.secondary_account_are_you_exposed === 'true' ? true : false,
    secondary_account_upload_3210: savedProgress && savedProgress.secondary_account_upload_3210 || '',
    secondary_account_upload_3210_name: savedProgress && savedProgress.secondary_account_upload_3210_name || '',
    secondary_account_upload_407: savedProgress && savedProgress.secondary_account_upload_407 || '',
    secondary_account_upload_407_name: savedProgress && savedProgress.secondary_account_upload_407_name || '',

    secondary_account_political_organization: savedProgress && savedProgress.secondary_account_political_organization ? JSON.parse(savedProgress.secondary_account_political_organization) : [
      { political_organization: '', relation: '' }
    ],
    secondary_is_employed: savedProgress && savedProgress.secondary_is_employed || '',
    secondary_occupation: savedProgress && savedProgress.secondary_occupation || '',
    secondary_employer_name: savedProgress && savedProgress.secondary_employer_name || '',
    // secondary_years_employee_exp: "",
    secondary_account_magnifi_terms_and_conditions: savedProgress && savedProgress.secondary_account_magnifi_terms_and_conditions && savedProgress.secondary_account_magnifi_terms_and_conditions === 'true' ? true : true,
    secondary_account_apex_terms_and_conditions: savedProgress && savedProgress.secondary_account_apex_terms_and_conditions && savedProgress.secondary_account_apex_terms_and_conditions === 'true' ? true : true,
    secondary_account_enroll_in_sweep_program: savedProgress && savedProgress.secondary_account_enroll_in_sweep_program && savedProgress.secondary_account_enroll_in_sweep_program === 'true' ? true : false,
    investment_obj: savedProgress && savedProgress.investment_obj || '',
    annual_income: savedProgress && savedProgress.annual_income || '',
    // liquid_net_worth: savedProgress && savedProgress.liquid_net_worth || '',
    total_net_worth: savedProgress && savedProgress.total_net_worth || '',
    // risk_tolerance: savedProgress && savedProgress.risk_tolerance || '',
    // tax_bracket: savedProgress && savedProgress.tax_bracket || '',
    divident_reinvestment: savedProgress && savedProgress.divident_reinvestment || 'REINVEST',
    signature_snap_id: savedProgress && savedProgress.signature_snap_id || '',
    isBranded: savedProgress && savedProgress.isBranded && savedProgress.isBranded === 'true' ? true : false,
    // timeHorizon: savedProgress && savedProgress.timeHorizon || '',
    // liquidityNeeds: savedProgress && savedProgress.liquidityNeeds || '',
    secondary_firm_name: savedProgress && savedProgress.secondary_firm_name || '',
    form_crs: savedProgress && savedProgress.form_crs && savedProgress.form_crs === 'true' ? true : true,
    magnifi_part2_brochure: savedProgress && savedProgress.magnifi_part2_brochure && savedProgress.magnifi_part2_brochure === 'true' ? true : false,
    investment_advisory_agreement: savedProgress && savedProgress.investment_advisory_agreement && savedProgress.investment_advisory_agreement === 'true' ? true : false,
    secondary_account_form_crs: savedProgress && savedProgress.secondary_account_form_crs && savedProgress.secondary_account_form_crs === 'true' ? true : true,
    secondary_account_magnifi_part2_brochure: savedProgress && savedProgress.secondary_account_magnifi_part2_brochure && savedProgress.secondary_account_magnifi_part2_brochure === 'true' ? true : false,
    secondary_account_form_investment_advisory_agreement: savedProgress && savedProgress.secondary_account_form_investment_advisory_agreement && savedProgress.secondary_account_form_investment_advisory_agreement === 'true' ? true : false
  }

  formInitialValues.isBranded = props.isBranded
  const [isOpenSkipModal, setIsOpenSkipModal] = useState(false)
  const [_account_type, setAccountTypeG] = useState(formInitialValues.account_type)
  const [finshThisLater, setFinishThisLater] = useState(1)
  const [linkToken, setLinkToken] = useState('')
  const [bankAccountDetails, setBankAccountDetails] = useState([])
  const [bankAccountDataStatus, setBankAccountDataStatus] = useState(false)
  const [institution_name, setInstitution_name] = useState(false)
  const [termAndCondition, setTermAndCondition] = useState(false)


  useEffect(() => {
    if (savedProgress && savedProgress.account_type && savedProgress.account_type !== _account_type && !_account_type) {
      setAccountTypeG(savedProgress.account_type);
    }
  }, [savedProgress]);


  const handleOnSuccess = (public_token, metadata) => {
    props.closeBankAccountPopup();
    if (public_token && metadata && metadata.institution && metadata.accounts) {
      let postData = {
        public_token: public_token,
        institution_name: metadata.institution.name,
        link_token: linkToken,
        acc_numbers: metadata.accounts.map(el => el.id)
      }

      props.getUsersAccounts(postData, data => {
        if (data && data.bankAccounnts) {
          let formData = {};
          if (data.bankAccounnts.length && data.bankAccounnts.length === 1) {
            formData['plaid_account_id'] = data.bankAccounnts[0].account_id;
            formData['plaid_user_id'] = data.bankAccounnts[0].plaid_user_id;
            props.createAchRelation(formData);
          } else {
            setBankAccountDetails(data.bankAccounnts)
            setBankAccountDataStatus(true)
            setInstitution_name(metadata.institution.name)
            props.setStep(props.step);
          }
          props.setlinkAccountPopup();
        }
      })
    }
  }

  useEffect(() => {
    props.getLink(data => {
      if (data && data.linkToken) {
        setLinkToken(data.linkToken)
      }
    })
  }, []);

  let step = props.step
  let canOverRide = false

  if (props.isBranded && step === 4) {
    step = 3
    canOverRide = true
  }
  if (props.isBranded && step === 5) {
    step = 4
    canOverRide = true
  }
  if (props.isBranded && step === 6) {
    step = 5
    canOverRide = true
  }
  console.log('TradingFormOpts----', step)

  if (step === 1) {
    validationSchema = Yup.object().shape({
      firstname: Yup.string().required('First name is a required field').matches(Regex.someCharacter, 'First name is a required field'),
      lastname: Yup.string().required('Last name is a required field').matches(Regex.someCharacter, 'Last name is a required field'),
      account_type: Yup.string().required('Please select your account type'),
      marital_status: Yup.string().required(
        'Please select your marital status'
      ),
      phone_number: Yup.string()
        .required('Phone Number is a required field')
        .matches(
          Regex.phone,
          'Phone number is not valid. Sample format (515) 989-2899'
        ),
      citizenship: Yup.string().required('Please select your citizenship'),
      ssn: Yup.string()
        .required('SSN is a required field')
        .min(11, 'Must be exactly 9 digits'),
      experience: Yup.string().required('Experience is a required field'),
      are_you_employed: Yup.string().required(
        'Please select your employment type'
      ),
      country: Yup.string().required('Please select a country'),
      zipcode: Yup.string()
        .required('Please enter your zipcode')
        .matches(Regex.zipCode, 'zip code is not valid'),
      //age_group:Yup.string(),
      date_of_birth: Yup.string()
        .required('Please enter your DOB')
        .test(
          'is-valid-date',
          'Invalid Date',
          dob => moment(dob).isValid()
        )
        .test(
          'is-over-18',
          'Age must be over 18',
          dob => moment().diff(dob, 'years') >= 18
        ),
      state: Yup.string().required('Please enter your state'),
      city: Yup.string().required('Please enter your city'),
      annual_income: Yup.string().required('This is a required field'),
      total_net_worth: Yup.string().required('This is a required field'),
      investment_obj: Yup.string().required('This is a required field'),
      divident_reinvestment: Yup.string().required('This is a required field'),
      address: Yup.string()
        .required('Please enter your street')
        .matches(Regex.pobox, 'Invalid street address')
        .max(30, 'Street must be at most 30 characters'),
      employer_name: Yup.string().when('are_you_employed', {
        is: are_you_employed => are_you_employed == 'Employed',
        then: Yup.string().required('This is a required field')
      }),
      occupation: Yup.string().when('are_you_employed', {
        is: are_you_employed => are_you_employed == 'Employed',
        then: Yup.string().required('This is a required field')
      }),
      trusted_contact_firstname: Yup.string().when('trusted_contact', {
        is: trusted_contact => trusted_contact,
        then: Yup.string()
          .required('This is a required field')
          .matches(Regex.someCharacter, 'This is a required field')
        // .matches(Regex.names, 'First name is not valid')
      }),
      trusted_contact_lastname: Yup.string().when('trusted_contact', {
        is: trusted_contact => trusted_contact,
        then: Yup.string()
          .required('This is a required field')
          .matches(Regex.someCharacter, 'This is a required field')
        // .matches(Regex.names, 'Last name is not valid')
      }),
      trusted_contact_email: Yup.string().when(
        ['trusted_contact', 'trusted_select'],
        {
          is: (trusted_contact, trusted_select) =>
            trusted_contact && trusted_select.includes('trusted_email'),
          then: Yup.string()
            .email('Invalid email address')
            .required('This is a required field')
        }
      ),
      trusted_contact_phone: Yup.string().when(
        ['trusted_contact', 'trusted_select'],
        {
          is: (trusted_contact, trusted_select) =>
            trusted_contact && trusted_select.includes('trusted_phone'),
          then: Yup.string()
            .required('This is a required field')
            .matches(
              Regex.phone,
              'Phone number is not valid. Sample format (515) 989-2899'
            )
        }
      ),
      trusted_contact_country: Yup.string().when(
        ['trusted_contact', 'trusted_select'],
        {
          is: (trusted_contact, trusted_select) =>
            trusted_contact && trusted_select.includes('trusted_address'),
          then: Yup.string().required('This is a required field')
        }
      ),
      trusted_contact_state: Yup.string().when(
        ['trusted_contact', 'trusted_select'],
        {
          is: (trusted_contact, trusted_select) =>
            trusted_contact && trusted_select.includes('trusted_address'),
          then: Yup.string().required('This is a required field')
        }
      ),
      trusted_contact_city: Yup.string().when(
        ['trusted_contact', 'trusted_select'],
        {
          is: (trusted_contact, trusted_select) =>
            trusted_contact && trusted_select.includes('trusted_address'),
          then: Yup.string().required('This is a required field')
        }
      ),
      trusted_contact_street: Yup.string().when(
        ['trusted_contact', 'trusted_select'],
        {
          is: (trusted_contact, trusted_select) =>
            trusted_contact && trusted_select.includes('trusted_address'),
          then: Yup.string()
            .required('This is a required field')
            .matches(Regex.pobox, 'Invalid street address')
            .max(30, 'Street must be at most 30 characters')
        }
      ),
      trusted_contact_zipcode: Yup.string().when(
        ['trusted_contact', 'trusted_select'],
        {
          is: (trusted_contact, trusted_select) =>
            trusted_contact && trusted_select.includes('trusted_address'),
          then: Yup.string()
            .required('This is a required field')
            .matches(Regex.zipCode, 'zip code is not valid')
        }
      )

      //commenting for now, will work on this later
      // identity_proof    : Yup.string().required('Identity proof is a required field'),
      // address_proof     : Yup.string().required('Address proof is a required field'),
      // citizenship_proof : Yup.string().required('Citizenship proof is a required field'),
    })
  } else if (step === 2) {
    validationSchema = Yup.object().shape({
      are_you_family_member: Yup.boolean().oneOf(
        [true, false],
        'This is a required field'
      ),

      stock_name: Yup.string().when('are_you_family_member', {
        is: are_you_family_member => are_you_family_member === true,
        then: Yup.string().required('This is a required field')
          .matches(Regex.someCharacter, 'This is a required field')
      }),

      are_you_authorize: Yup.boolean().oneOf(
        [true, false],
        'This is a required field'
      ),

      firm_name: Yup.string().when('are_you_authorize', {
        is: are_you_authorize => are_you_authorize,
        then: Yup.string().required('This is a required field')
          .matches(Regex.someCharacter, 'This is a required field')
      }),

      upload_type: Yup.string().when('are_you_authorize', {
        is: are_you_authorize => are_you_authorize,
        then: Yup.string().required('This is a required field')
      }),

      upload_3210: Yup.mixed().when(['are_you_authorize', 'upload_type'], {
        is: (are_you_authorize, upload_type) =>
          are_you_authorize && upload_type === 'Upload 3210',
        then: Yup.mixed()
          .required('This is a required field')
      }),

      upload_407: Yup.mixed().when(['are_you_authorize', 'upload_type'], {
        is: (are_you_authorize, upload_type) =>
          are_you_authorize && upload_type === 'Upload 407',
        then: Yup.mixed()
          .required('This is a required field')
      }),

      // reg_bi_form : Yup.string().when('self_role', {
      //   is: (self_role) => (self_role=== "RIA"),
      //   then: Yup.string().required('This is a required field'),
      // }),

      // work_for_another_brokerage : Yup.boolean().oneOf([true, false], 'This is a required field'),
      are_you_exposed: Yup.boolean().oneOf(
        [true, false],
        'This is a required field'
      ),

      political_organization: Yup.array().when(['are_you_exposed'], {
        is: are_you_exposed => {
          return are_you_exposed
        },
        then: Yup.array().of(
          Yup.object().shape({
            relation: Yup.string()
              .required('This is a required field')
              .matches(Regex.someCharacter, 'This is a required field'),
            political_organization: Yup.string()
              .required('This is a required field')
              .matches(Regex.someCharacter, 'This is a required field')
          })
        )
      }),
      account_type: Yup.string().required('Please select your account type'),
      magnifi_terms_and_conditions: Yup.boolean().when('account_type', {
        is: account_type => account_type !== 'Joint',
        then: Yup.boolean().oneOf([true], 'This is a required field')
      }),
      apex_terms_and_conditions: Yup.boolean().when('account_type', {
        is: account_type => account_type !== 'Joint',
        then: Yup.boolean().oneOf([true], 'This is a required field')
      }),
      // enroll_in_sweep_program: Yup.boolean().when('account_type', {
      //   is: account_type => account_type !== 'Joint',
      //   then: Yup.boolean().oneOf([true], 'This is a required field')
      // }),
      employer_name: Yup.string().when('are_you_employed', {
        is: are_you_employed => are_you_employed === true,
        then: Yup.string().required('This is a required field')
      }),
      occupation: Yup.string().when('are_you_employed', {
        is: are_you_employed => are_you_employed === true,
        then: Yup.string().required('This is a required field')
      }),
      isBranded: Yup.boolean(),
      // timeHorizon: Yup.string().when('isBranded', {
      //   is: isBranded => isBranded === true,
      //   then: Yup.string().required('This is a required field')
      // }),
      // liquidityNeeds: Yup.string().when('isBranded', {
      //   is: isBranded => isBranded === true,
      //   then: Yup.string().required('This is a required field')
      // }),
      // reg_bi_form: Yup.mixed().required('This is a required field'),
      // upload_type: Yup.string().required('This is a required field')
      form_crs: Yup.boolean().when('account_type', {
        is: account_type => account_type !== 'Joint',
        then: Yup.boolean().oneOf([true], 'This is a required field')
      }),
      magnifi_part2_brochure: Yup.boolean().when('account_type', {
        is: account_type => account_type !== 'Joint',
        then: Yup.boolean().oneOf([true], 'This is a required field')
      }),
      investment_advisory_agreement: Yup.boolean().when('account_type', {
        is: account_type => account_type !== 'Joint',
        then: Yup.boolean().oneOf([true], 'This is a required field')
      }),
      secondary_account_magnifi_terms_and_conditions: Yup.boolean().when('account_type', {
        is: account_type => account_type === 'Joint',
        then: Yup.boolean().oneOf([true], 'This is a required field')
      }),
      secondary_account_apex_terms_and_conditions: Yup.boolean().when('account_type', {
        is: account_type => account_type === 'Joint',
        then: Yup.boolean().oneOf([true], 'This is a required field')
      }),
      // secondary_account_enroll_in_sweep_program: Yup.boolean().oneOf(
      //   [true],
      //   'This is a required field'
      // ),
      secondary_account_form_crs: Yup.boolean().when('account_type', {
        is: account_type => account_type === 'Joint',
        then: Yup.boolean().oneOf([true], 'This is a required field')
      }),
      secondary_account_magnifi_part2_brochure: Yup.boolean().when('account_type', {
        is: account_type => account_type === 'Joint',
        then: Yup.boolean().oneOf([true], 'This is a required field')
      }),
      secondary_account_form_investment_advisory_agreement: Yup.boolean().when('account_type', {
        is: account_type => account_type === 'Joint',
        then: Yup.boolean().oneOf([true], 'This is a required field')
      }),
      // liquid_net_worth: Yup.string().required('This is a required field'),
      // risk_tolerance: Yup.string().required('This is a required field'),
      // tax_bracket: Yup.number()
      //   .required('This is a required field')
      //   .min(0, 'Min value 0.')
      //   .max(100, 'Max value 100.')
      //   .test(
      //     'maxDigitsAfterDecimal',
      //     'Tax Bracket must have 2 digits or less after decimal',
      //     value => (value + '').match(/^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/)
      //   ),
      // signature_snap_id: Yup.string().required(
      //   'Signature is a required field, press Save Signature to upload'
      // )
    })
  } else if (step === 3) {
    validationSchema = Yup.object().shape({
      secondary_account_phone_number: Yup.string()
        .required('Phone Number is a required field')
        .matches(
          Regex.phone,
          'Phone number is not valid. Sample format (515) 989-2899'
        ),
      secondary_account_citizenship: Yup.string().required(
        'Please select your citizenship'
      ),
      secondary_account_ssn: Yup.string()
        .required('SSN is a required field')
        .min(11, 'Must be exactly 9 digits'),
      secondary_account_first_name: Yup.string()
        .required('This is a required field')
        .matches(Regex.someCharacter, 'This is a required field'),
      secondary_account_last_name: Yup.string()
        .required('This is a required field')
        .matches(Regex.someCharacter, 'This is a required field'),
      secondary_account_user_email: Yup.string()
        .email('Invalid email address')
        .required('This is a required field'),
      secondary_dob: Yup.string().required('This is a required field')
        .test(
          'is-valid-date',
          'Invalid Date',
          dob => moment(dob).isValid()
        )
        .test(
          'is-over-18',
          'Age must be over 18',
          dob => moment().diff(dob, 'years') >= 18
        ),
      // secondary_years_employee_exp        : Yup.string().required('Experience is a required field'),
      secondary_is_employed: Yup.string().required(
        'Please select your account type'
      ),
      secondary_occupation: Yup.string().when('secondary_is_employed', {
        is: secondary_is_employed => secondary_is_employed == 'Employed',
        then: Yup.string().required('This is a required field')
      }),
      secondary_employer_name: Yup.string().when('secondary_is_employed', {
        is: secondary_is_employed => secondary_is_employed == 'Employed',
        then: Yup.string().required('This is a required field')
      }),
      secondary_account_country_value: Yup.string().required(
        'This is a required field'
      ),
      secondary_account_state_value: Yup.string().required(
        'This is a required field'
      ),
      secondary_account_city: Yup.string().required('This is a required field'),
      secondary_account_street_value: Yup.string()
        .required('This is a required field')
        .matches(Regex.pobox, 'Invalid street address')
        .max(30, 'Street must be at most 30 characters'),
      secondary_account_zipcode: Yup.string()
        .required('This is a required field')
        .matches(Regex.zipCode, 'zip code is not valid'),
      secondary_account_are_you_family_member: Yup.boolean().oneOf(
        [true, false],
        'This is a required field'
      ),

      secondary_account_stock_name: Yup.string().when(
        'secondary_account_are_you_family_member',
        {
          is: secondary_account_are_you_family_member =>
            secondary_account_are_you_family_member === true,
          then: Yup.string().required('This is a required field')
        }
      ),

      secondary_account_are_you_authorize: Yup.boolean().oneOf(
        [true, false],
        'This is a required field'
      ),
      secondary_account_are_you_exposed: Yup.boolean().oneOf(
        [true, false],
        'This is a required field'
      ),

      secondary_firm_name: Yup.string().when(
        'secondary_account_are_you_authorize',
        {
          is: secondary_account_are_you_authorize =>
            secondary_account_are_you_authorize,
          then: Yup.string().required('This is a required field')
        }
      ),

      secondary_account_upload_type: Yup.string().when(
        'secondary_account_are_you_authorize',
        {
          is: secondary_account_are_you_authorize =>
            secondary_account_are_you_authorize,
          then: Yup.string().required('This is a required field')
        }
      ),

      secondary_account_upload_3210: Yup.mixed().when(
        [
          'secondary_account_are_you_authorize',
          'secondary_account_upload_type'
        ],
        {
          is: (
            secondary_account_are_you_authorize,
            secondary_account_upload_type
          ) =>
            secondary_account_are_you_authorize &&
            secondary_account_upload_type === 'Upload 3210',
          then: Yup.mixed()
            .required('This is a required field')
        }
      ),

      secondary_account_upload_407: Yup.mixed().when(
        [
          'secondary_account_are_you_authorize',
          'secondary_account_upload_type'
        ],
        {
          is: (
            secondary_account_are_you_authorize,
            secondary_account_upload_type
          ) =>
            secondary_account_are_you_authorize &&
            secondary_account_upload_type === 'Upload 407',
          then: Yup.mixed()
            .required('This is a required field')
        }
      ),
      secondary_account_are_you_exposed: Yup.boolean().oneOf(
        [true, false],
        'This is a required field'
      ),
      secondary_account_political_organization: Yup.array().when(
        ['secondary_account_are_you_exposed'],
        {
          is: secondary_account_are_you_exposed => {
            return secondary_account_are_you_exposed
          },
          then: Yup.array().of(
            Yup.object().shape({
              relation: Yup.string()
                .required('This is a required field')
                .matches(Regex.someCharacter, 'This is a required field'),
              political_organization: Yup.string()
                .required('This is a required field')
                .matches(Regex.someCharacter, 'This is a required field')
            })
          )
        }
      )
    })
  }

  const videoSetState = _state => {
    addEventToAnalytics('Register Video', 'Register Video', 'REGISTER_VIDEO', {}, false)
  }

  const handleCreateAccount = (dataToSubmit) => {
    const values = dataToSubmit
    let formData = {
      data: {
        first_name: values.firstname.trim(),
        last_name: values.lastname.trim(),
        customer_type: values.account_type.toUpperCase(),
        marital_status: values.marital_status.toLowerCase(),
        phone: values.phone_number,
        citizenship: 'USA', //values.citizenship_value,
        ss_number: values.ssn,
        investment_exprience: values.experience_value,
        country: values.country,
        state: values.state,
        zipcode: values.zipcode,
        date_of_birth: values.date_of_birth,
        address: values.address,
        city: values.city,
        //  "is_employed"                    : values.are_you_employed ? "YES" : "NO",
        is_employed: values.are_you_employed,
        trusted_contact: values.trusted_contact ? 'YES' : 'NO',
        trusted_contact_firstname: values.trusted_contact
          ? values.trusted_contact_firstname.trim()
          : '',
        trusted_contact_lastname: values.trusted_contact
          ? values.trusted_contact_lastname.trim()
          : '',
        trusted_contact_email:
          values.trusted_contact &&
            values.trusted_select.includes('trusted_email')
            ? values.trusted_contact_email
            : '',

        trusted_contact_mobile:
          values.trusted_contact &&
            values.trusted_select.includes('trusted_phone')
            ? values.trusted_contact_phone
            : '',
        trusted_contact_country_code:
          values.trusted_contact &&
            values.trusted_select.includes('trusted_phone')
            ? values.trusted_contact_country_code
            : '',

        trusted_contact_country:
          values.trusted_contact &&
            values.trusted_select.includes('trusted_address')
            ? values.trusted_contact_country
            : '',

        trusted_contact_state:
          values.trusted_contact &&
            values.trusted_select.includes('trusted_address')
            ? values.state_value
            : '',
        country_code: !isEmpty(values.phone_code) ? values.phone_code : '+1',
        trusted_contact_city:
          values.trusted_contact &&
            values.trusted_select.includes('trusted_address')
            ? values.trusted_contact_city
            : '',
        trusted_contact_street:
          values.trusted_contact &&
            values.trusted_select.includes('trusted_address')
            ? values.trusted_contact_street
            : '',
        trusted_contact_zipcode:
          values.trusted_contact &&
            values.trusted_select.includes('trusted_address')
            ? values.trusted_contact_zipcode
            : '',
        employer_name: values.are_you_employed ? values.employer_name : '',
        occupation: values.are_you_employed
          ? values.occupation.toUpperCase()
          : '',
        //"is_shareholder"                 : values.are_you_family_member ? "YES" : "NO",
        // time_horizon: values.timeHorizon
        //   ? values.timeHorizon.toUpperCase()
        //   : '',
        // liquidity_needs: values.liquidityNeeds
        //   ? values.liquidityNeeds
        //     .split(' ')
        //     .join('_')
        //     .toUpperCase()
        //   : '',
        is_affiliated_exchange_finra: values.are_you_authorize ? 'YES' : 'NO',
        firm_name: values.firm_name.trim(), //"NYSE",
        upload_3210: values.are_you_authorize ? values.upload_3210 : '',
        upload_407: values.are_you_authorize ? values.upload_407 : '',
        upload_type: values.upload_type ? values.upload_type : '',
        upload_ria: values.reg_bi_form,
        //  is_another_brokerage             : values.work_for_another_brokerage ? "YES" : "NO",
        is_political_exposed: values.are_you_exposed ? 'YES' : 'NO',
        //  "relation"                       : values.relation,
        political_organization: values.political_organization.map(el => {
          if (el.political_organization)
            el.political_organization = el.political_organization.trim();
          if (el.relation)
            el.relation = el.relation.trim();
          return el;
        }),
        //  terms_agreed                     : values.terms_and_agreement ? "YES" : "NO",
        terms_agreed:
          values.magnifi_terms_and_conditions &
            values.apex_terms_and_conditions && values.enroll_in_sweep_program
            ? 'YES'
            : 'NO',
        plaid_user_id: values.plaid_user_id,
        selected_bank_account_id: values.account_id,
        is_control_person: values.are_you_family_member ? 'YES' : 'NO',
        stock_name: values.are_you_family_member ? values.stock_name.trim() : '',
        esigned: 'YES',
        account_holder_type: 'A',
        is_direct: props.isBranded ? 'YES' : 'NO'
      }
    }

    if (props.isBranded) {
      formData.data = {
        ...formData.data,
        // risk_to_laerance: values.risk_tolerance,
        // feederal_tax_bracket: values.tax_bracket,
        annual_income_max: values.annual_income
          ? values.annual_income.split('-')[1]
          : '',
        annual_income_min: values.annual_income
          ? values.annual_income.split('-')[0]
          : '',
        // liquid_net_max: values.liquid_net_worth
        //   ? values.liquid_net_worth.split('-')[1]
        //   : '',
        // liquid_net_min: values.liquid_net_worth
        //   ? values.liquid_net_worth.split('-')[0]
        //   : '',
        total_net_worth_max: values.total_net_worth
          ? values.total_net_worth.split('-')[1]
          : '',
        total_net_worth_min: values.total_net_worth
          ? values.total_net_worth.split('-')[0]
          : '',
        investment_obj: values.investment_obj,
        divident_reinvestment: values.divident_reinvestment,
        // affiliatedApproval: values.signature_snap_id
      }
    }

    if (values.account_type === 'Joint') {
      const joint_is_political_exposed =
        values.secondary_account_are_you_exposed
      let joint_upload = ''
      let joint_upload_file_type = ''
      if (values.secondary_account_are_you_authorize) {
        joint_upload =
          values.secondary_account_upload_type &&
            values.secondary_account_upload_type === 'Upload 3210'
            ? values.secondary_account_upload_3210
            : values.secondary_account_upload_407
        joint_upload_file_type = values.secondary_account_upload_type
      }
      formData.data = {
        ...formData.data,
        // joint account keys

        joint_is_affiliated_exchange_finra: values.secondary_account_are_you_authorize
          ? 'YES'
          : 'NO',
        joint_is_political_exposed: joint_is_political_exposed ? 'YES' : 'NO',
        joint_is_control_person: values.secondary_account_are_you_family_member
          ? 'YES'
          : 'NO',
        joint_firm_name: values.secondary_account_are_you_authorize ? values.secondary_firm_name : '', //"NYSE",
        joint_citizenship: 'USA', // values.secondary_account_citizenship,
        joint_first_name: values.secondary_account_first_name,
        joint_last_name: values.secondary_account_last_name.trim(),
        joint_ssn: values.secondary_account_ssn,
        joint_country_code: isEmpty(values.secondary_account_country_code)
          ? '+1'
          : values.secondary_account_country_code,
        joint_phone: values.secondary_account_phone_number,
        joint_postal: values.secondary_account_zipcode,
        joint_city: values.secondary_account_city,
        joint_street: values.secondary_account_street_value,
        joint_state: values.secondary_account_state_value,
        joint_country: values.secondary_account_country_value,
        joint_email: values.secondary_account_user_email,
        // joint_esigned                    : 'YES',
        // joint_jointesigned               : 'YES',
        joint_upload: joint_upload,
        joint_upload_file_type: joint_upload_file_type,
        joint_political_organization: JSON.stringify(
          values.secondary_account_political_organization
        ),
        joint_stock_name: values.secondary_account_are_you_family_member ? values.secondary_account_stock_name : '',
        joint_dob: values.secondary_dob,
        is_joint_employed: values.secondary_is_employed,
        joint_occupation: values.secondary_occupation,
        joint_employer_name: values.secondary_employer_name
        // joint_years_employee_exp         : values.secondary_years_employee_exp
      }
    }

    props.createAccount(formData, data => {
      if (
        data &&
        data.responseData &&
        data.responseData.result &&
        data.responseData.result.user_id
      ) {
        // props.setStep(props.isBranded ? 4 : 3)

        // this.props.history.push('/securities');
      }
    })
  }

  const goBackCondition =
    props.step === 5 && window.location.pathname === '/trading/review'

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

  return (
    <Row className="magnifi-auth-page-wrapper register-magnifi-opt-2 retail_different_layout">
      <SkipModal
        open={isOpenSkipModal}
        cancel={() => setIsOpenSkipModal(false)}
        handleConfirm={() => props.history.push('/login')}
      />

      <Modal
        isOpen={termAndCondition}
        centered
        keyboard
        toggle={() => setTermAndCondition(!termAndCondition)}
        backdrop
        className="premium-msg-modal-sm-light"
        backdropClassName="premium-feature-process-modal-backdrop"
      >
        <ModalBody className="ssf-modal__body text_21_rem">
          <div className="premium-feature-process-modal-container delete-confirmation-modal">
            <div className="header-wrapper">
              <h1 className="text-align-center pb-2">Terms and Disclosures</h1>
              {/* <p className="text-align-center pb-2 h3_Title">All the information entered will be lost.</p> */}
            </div>
            <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => setTermAndCondition(false)}></i>
          </div>
          <Row>
            <Col md={12} className="text-center mb-2">
              <a href={TEARM_LINKS.form_crs} target="_blank" style={{ fontSize: 16, textDecoration: 'underline' }}>Form CRS</a>
            </Col>
            <Col md={12} className="text-center  mb-2">
              <a href="https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/privacy_policy.pdf" target="_blank" style={{ fontSize: 16, textDecoration: 'underline' }}>Privacy Policy</a>
            </Col>
            <Col md={12} className="text-center  mb-2">
              <a href={TEARM_LINKS.brochure} target="_blank" style={{ fontSize: 16, textDecoration: 'underline' }}>Magnifi Part 2 Brochure</a>
            </Col>
            <Col md={12} className="text-center">
              <a href="/assets/disclosures/apex_disclosures.pdf" target="_blank" style={{ fontSize: 16, textDecoration: 'underline' }}>Apex Terms and Conditions</a>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={props.bankAccountSuccess}
        centered
        keyboard
        unmountOnClose={false}
        backdrop
        className="premium-msg-modal-sm-light"
        backdropClassName="premium-feature-process-modal-backdrop"
      >
        <ModalBody className="ssf-modal__body text_21_rem">
          <div className="premium-feature-process-modal-container delete-confirmation-modal">
            <div className="header-wrapper">
              <h1 className="text-align-center pb-2">{props.bankAccountStatus ? 'Bank Account Linked Successfully' : 'Error in linking Bank Account'}</h1>
              {/* <p className="text-align-center pb-2 h3_Title">All the information entered will be lost.</p> */}
            </div>
            {/* <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => setIsCreateModal(null)}></i> */}
            <div className="confirmation-btn">
              <ButtonGroup
                className="justify-content-center w-100 mb-2"
              >
                {props.bankAccountStatus ? <PrimaryButton
                  type="button"
                  className="ssf-btn-primary btn-link btn_link_now btn btn-secondary"
                  onClick={() => props.history.push('/securities')}
                  style={{
                    width: '80%',
                    cursor: 'pointer',
                  }}
                >
                  START INVESTING
                </PrimaryButton> : (step && ((_account_type === 'Joint' && step === 3) || (_account_type === 'Individual' && step === 3) || step === 1) ? <PrimaryButton
                  type="button"
                  className="ssf-btn-primary btn-link btn_link_now btn btn-secondary"
                  onClick={() => props.closeBankAccountPopup()}
                  style={{
                    width: '80%',
                    cursor: 'pointer',
                  }}
                >
                  RETRY
                </PrimaryButton> : <PlaidLink
                  product={['auth']}
                  onExit={() => { }}
                  onSuccess={(public_token, metadata) => {
                    handleOnSuccess(public_token, metadata)
                  }}
                  className="ssf-btn-primary btn-link btn_link_now btn btn-secondary"
                  token={linkToken}
                >
                  RETRY
                </PlaidLink>)}
              </ButtonGroup>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={props.linkAccountPopup}
        centered
        keyboard
        unmountOnClose={false}
        backdrop
        className="premium-msg-modal-sm-light"
        backdropClassName="premium-feature-process-modal-backdrop"
      >
        <ModalBody className="ssf-modal__body text_21_rem">
          <div className="premium-feature-process-modal-container delete-confirmation-modal">
            <div className="header-wrapper">
              <h1 className="text-align-center pb-2">Link your Bank Account to Start Investing</h1>
              {/* <p className="text-align-center pb-2 h3_Title">All the information entered will be lost.</p> */}
            </div>
            {/* <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => setIsCreateModal(null)}></i> */}
            <div className="confirmation-btn">
              <ButtonGroup
                className="justify-content-center w-100 mb-2"
              >
                {/* <PrimaryButton
                  type="button"
                  className="btn-link btn_link_now"
                  onClick={() => { }}
                  style={{
                    width: '80%',
                    cursor: 'pointer',
                  }}
                >
                  LINK ACCOUNT
                </PrimaryButton> */}
                <PlaidLink
                  product={['auth']}
                  onExit={() => { }}
                  onSuccess={(public_token, metadata) => {
                    let email = localStorage.getItem('email') || window.sessionStorage.getItem('email') || "";
                    addEventToAnalytics('Link Bank Account Now', 'Link Bank Account Now', 'LINK_BANK_ACCOUNT_NOW', { email: email }, true);
                    handleOnSuccess(public_token, metadata)
                  }}
                  className="ssf-btn-primary btn-link btn_link_now btn btn-secondary"
                  token={linkToken}
                >
                  LINK ACCOUNT
                </PlaidLink>
              </ButtonGroup>
              <ButtonGroup
                className="justify-content-center w-100"
              >
                <SecondaryButton
                  type="button"
                  className="btn-link btn_later"
                  onClick={() => {
                    let email = localStorage.getItem('email') || window.sessionStorage.getItem('email') || "";
                    addEventToAnalytics('Link Bank Account Later', 'Link Bank Account Later', 'LINK_BANK_ACCOUNT_LATER', { email: email }, true);
                    props.history.push('/securities');
                  }}
                  style={{
                    width: '80%',
                    cursor: 'pointer',
                    color: '#56a9e8',
                  }}
                >
                  LINK LATER
                </SecondaryButton>
              </ButtonGroup>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Col className="left-side-panel-container">
        <Row className="parent_row custom-scroll">
          <Col sm={12} style={{ height: '88%', display: 'flex', alignItems: 'center' }}>
            <div className="w-100 text-center">
              <a className="logo-wrapper" href={REDIRECT_DOMAIN_PATH}>
                <img src={blueLogo} height={70} alt="magnifi" />
              </a>
              <h1 className="ai_heading">Become an AI-Powered Investor</h1>
              <p className="ai_desc">Combine AI powered natural language search with robust investment intelligence to find, compare, and invest.</p>
              <div className="w-100 mt-4 video_frame_container">
                  <VideoPlayer
                    key={'https://magnifi-videos.s3.us-east-2.amazonaws.com/Demo_Video_Revised_02.mp4'}
                    link={'https://magnifi-videos.s3.us-east-2.amazonaws.com/Demo_Video_Revised_02.mp4'}
                    title={'Magnifi'}
                    onPlayPauseState={videoSetState}
                    onPauseState={videoSetState}
                    onEndedState={videoSetState}
                  />
              </div>
            </div>
          </Col>
          <Col style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'flex-end' }}>
            <div className="w-100 text-center">
              <p className="any_questions">
                Questions?
              </p>
              <p className="get_support">
                <a href="mailto:support@magnifi.com">support@magnifi.com</a> | Anytime
              </p>
            </div>
          </Col>
        </Row>
        {/* {!isMobile && (
          <Row style={{ flex: 1 }}>
            <div className="contact-info">
              <p><a href="mailto:support@magnifi.com">support@magnifi.com</a> | Anytime</p>
            </div>
          </Row>
        )} */}
      </Col>

      <Col
        sm="12"
        md="12"
        lg="6"
        xl="6"
        className="right-side-panel-container register-page-height"
      >
        <div className="form_content">
          <Spinner isFetching={props.isFetching || props.isFetching1} />
          <Formik
            enableReinitialize
            initialValues={formInitialValues}
            onSubmit={(e) => {
              if (((props.step === 3 && e.account_type === 'Joint') || (props.step === 2 && e.account_type === 'Individual')) && !bankAccountDataStatus) {
                handleCreateAccount(e)
                let email = localStorage.getItem('email') || window.sessionStorage.getItem('email') || "";
                addEventToAnalytics(`Create Account`, `Create Account`, `CREATE_ACCOUNT`, { email: email }, true);
              } else {
                props.setStep(props.step)
              }
              props.saveData(e, true)
            }}
            validationSchema={validationSchema}
            render={formProps => {
              const toggleSkipModal = () => {
                if (
                  JSON.stringify(formInitialValues) ===
                  JSON.stringify(formProps.values)
                ) {
                  console.log('same')
                  props.history.push('/login')
                } else {
                  setIsOpenSkipModal(old => !old)
                }
              }
              let totalSteps = formProps.values.account_type === 'Joint' ? 3 : 2;
              totalSteps = props.isBranded ? ++totalSteps : ++totalSteps;
              let isSubmitStep = (props.step === 3 && formProps.values.account_type === 'Joint') || (props.step === 2 && formProps.values.account_type === 'Individual') ? true : false;
              return (<>
                {window.location.pathname === '/trading/bank/add' || (step && ((_account_type === 'Joint' && props.step === 4) || (_account_type === 'Individual' && step === 3))) ? <div className="mb-4 max_for_cont"></div> : null}
                {window.location.pathname === '/trading/bank/add' || (step && ((_account_type === 'Joint' && props.step === 4) || (_account_type === 'Individual' && step === 3))) ? null : <div className="align-self-stretch mb-0 px-3 pt-3 max_for_cont">
                  <BackButton
                    step={props.step}
                    fromRegister={props.fromRegister}
                    clickHandler={() => {
                      if (goBackCondition) {
                        if (isMobile) {
                          props.history.push('/securities')
                        } else {
                          props.history.goBack()
                        }
                      } else {
                        if (props.fromRegister && props.step === 1) {
                          let email = localStorage.getItem('email') || window.sessionStorage.getItem('email') || "";
                          addEventToAnalytics(`Back Button Clicked`, `Back Button Clicked`, `BACK_BUTTON_CLICKED`, { email: email }, true);
                          props.saveData(formProps.values, true);
                          props.history.push({ pathname: props.fromPathRouter, returnToEdit: true })
                        } else {
                          props.backHandler()
                        }
                      }
                    }}
                  />
                  {!goBackCondition && <StepsBar step={step} account_type={formProps.values.account_type} totalSteps={totalSteps} />}
                  {/* {!goBackCondition && props.step < 5 && !isSubmitStep ? <div className="mb-2 start_finding"><a style={{ fontSize: 14 }} onClick={() => {
                    setFinishThisLater(finshThisLater + 1)
                  }} href="javascript:void(0)">I'll Finish This Later</a></div> : null} */}
                  {step === 1 ? <div className="text-center w-100 mb-2">
                    <span className="help_note" style={{ fontSize: '0.8rem', display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}><img style={{ width: 20, height: 20, margin: '0 5px' }} src={'/assets/images/icons/US_flag.svg'} /><span style={{ position: 'initial', marginTop: -2, fontSize: '0.7rem' }}>&nbsp;&dagger;&nbsp;</span> Magnifi Investment Accounts are currently only open to U.S. Citizens</span>
                  </div> : null}
                </div>}
                <Form
                  className="trading_me magnifi-auth-design-container retail_form_container register-magnifi-auth-design"
                  id="register-trade-form"
                  noValidate={true}
                  onSubmit={formProps.handleSubmit}
                >
                  {/* <Tagline /> */}
                  <div className="body-wrapper">
                    {/* {renderStep(props.step, formProps, props, toggleSkipModal, handleCreateAccount, bankAccountDetails, bankAccountDataStatus, institution_name, linkToken, isSubmitStep, props.createAchRelation, setAccountTypeG, finshThisLater)} */}
                    <RenderStep step={props.step} props={props} formProps={formProps} toggleSkipModal={toggleSkipModal} handleCreateAccount={handleCreateAccount} bankAccountDetails={bankAccountDetails} bankAccountDataStatus={bankAccountDataStatus} institution_name={institution_name} linkToken={linkToken} isSubmitStep={isSubmitStep} createAchRelation={props.createAchRelation} setAccountTypeG={setAccountTypeG} finshThisLater={finshThisLater} mapAbbrToState={mapAbbrToState} toggleTermsModal={() => setTermAndCondition(!termAndCondition)} />
                    {isMobile && (
                      <Row style={{ flex: 1 }}>
                        <div className="contact-info w-100 text-center">
                          {/* <p>Call or Text Magnifi Support: <a href="tel:7204455530">(720) 445-5530</a> | 9am - 6pm EST</p> */}
                          <p><a href="mailto:support@magnifi.com">support@magnifi.com</a> | Anytime</p>
                        </div>
                      </Row>
                    )}
                  </div>
                </Form>
              </>)
            }}
          />
        </div>
      </Col>
    </Row>
  )
}

export default TradingFormOpts

const BackButton = ({ clickHandler, step, fromRegister }) => (
  (step && (step !== 1 || (fromRegister && step === 1))) ? (
    <IconButton
      onClick={clickHandler}
      iconClass="fa-long-arrow-left"
      style={{ fontSize: '30px' }}
    />
  ) : (
    <div style={{ height: 20 }}></div>
  )
)

class TradingFormRHS1 extends Component {
  state = {
    account_type: false,
    marital_status: false,
    citizenship: '',
    citizenshipInput: '',
    citizenship_value: '',
    phone_number: '',
    ssn: this.props.formProps.values.ssn,
    experience: '',
    experience_value: '',
    identity_proof: '',
    address_proof: '',
    citizenship_proof: '',
    firstname: '',
    lastname: '',
    // primary_firstname   : this.props.formProps.values.primary_firstname,
    // primary_lastname    : this.props.formProps.values.primary_lastname,
    secondary_applicant_first_name: '',
    secondary_applicant_last_name: '',
    are_you_employed: '',
    employer_name: '',
    occupation: '',
    trusted_contact: false,
    trusted_contact_firstname: '',
    trusted_contact_lastname: '',
    trusted_contact_email: '',
    trusted_contact_phone: '',
    trusted_select: ['trusted_email'],
    trusted_contact_country: '',
    country_input: '',
    country_value: '',
    trusted_contact_state: '',
    trusted_contact_city: '',
    trusted_contact_street: '',
    trusted_contact_zipcode: '',

    stateInput: '',
    state_value: '',
    country: '',
    countryInputP: '',
    stateInputP: '',
    stateValueP: '',
    phone_code: '',
    phone_code_input: '+1',
    phoneCodeState: false,
    zipCodeTimeOut: null,
    primaryZipNotValid: '',
    trustedZipNotValid: '',
    annualIncome: false,
    totalNetWorth: false,
    investmentObj: false,
    dividentReinvestment: false
  }

  // componentWillMount() {
  //   const { apexRedirect, profile: profileState } = this.props;
  //   console.log(' === Register/Trading page didMount ===> ', profileState);
  //   if (apexRedirect && profileState && profileState.apex) {
  //     apexRedirect(profileState)
  //   }
  //   // const { triggerProfileApiCall } = this.props;
  //   // if (triggerProfileApiCall) {
  //   //   triggerProfileApiCall();
  //   // }
  // }

  componentDidMount() {
    document.getElementsByTagName('body')[0].style.paddingTop = 0
    this.props.formProps.setTouched({})
  }

  componentWillReceiveProps(newProps) {
    const { apexRedirect, profile: profileState } = newProps;
    // console.log(' === Register/Trading page didMount ===> ', profileState);
    if (apexRedirect &&
      profileState &&
      profileState.apex &&
      this.props.profile !== profileState &&
      this.props.profile.apex !== profileState.apex
    ) {
      apexRedirect(profileState)
    }
  }

  toggleAccountType = () => {
    this.setState({
      account_type: !this.state.account_type
    }, () => {
      if (this.state.account_type) {
        setTimeout(() => {
          document.getElementById('account_type_child0').focus()
        }, 100)
      }
    })
  }

  toggleAreYouEmp = () => {
    this.setState({
      are_you_employed: !this.state.are_you_employed
    }, () => {
      if (this.state.are_you_employed) {
        setTimeout(() => {
          document.getElementById('are_you_employed_child0').focus()
        }, 100)
      }
    })
  }

  handleDropDownEmp = e => {
    this.props.formProps.setFieldValue('are_you_employed', e.target.value)
    //  this.props.formProps.setFieldValue('are_you_employed',true)
  }

  toggleMaritalStatus = () => {
    this.setState({
      marital_status: !this.state.marital_status
    }, () => {
      if (this.state.marital_status) {
        setTimeout(() => {
          document.getElementById('marital_status_child0').focus()
        }, 100)
      }
    })
  }

  toggleCitizenshipStatus = () => {
    this.setState({
      citizenshipState: !this.state.citizenshipState
    })
  }

  toggleExperienceStatus = () => {
    this.setState({
      experience: !this.state.experience
    }, () => {
      if (this.state.experience) {
        setTimeout(() => {
          document.getElementById('experience0').focus()
        }, 100)
      }
    })
  }

  triggerInputFile(e, elem) {
    e.preventDefault()
    document.getElementById(elem).click()
  }

  fileSelectHandler = (e, elem) => {
    this.props.formProps.setFieldValue(elem, e.target.files[0])
  }

  handleUpload = async data => {
    try {
      let _responseData = await RegisterApi.uploadDocs(data)
      //console.log("_responseData",_responseData)
    } catch { }
  }

  onCitizenshipChange = e => {
    const { formProps } = this.props

    if (e.target.value == '') {
      formProps.setFieldValue('citizenship', e.target.value)
    }

    if (!this.state.countryState) {
      this.setState({
        citizenshipState: true
      })
    }
    formProps.handleChange(e)
  }

  onCitizenshipSelect = item => {
    const { setFieldValue } = this.props.formProps
    setFieldValue('citizenship', item.name)
    setFieldValue('citizenship_value', item.code)
    setFieldValue('citizenshipInput', item.name)
  }

  toggleOccupationStatus = () => {
    this.setState({
      occupation: !this.state.occupation
    })
  }

  handleTrustedSelect = e => {
    const { setFieldValue, values } = this.props.formProps
    let trusted = [...values.trusted_select]

    if (!e.target.checked && values.trusted_select.includes(e.target.name)) {
      if (trusted.length > 1)
        trusted = values.trusted_select.filter(val => val !== e.target.name)
    } else {
      trusted.push(e.target.name)
    }
    setFieldValue('trusted_select', trusted)
  }

  toggleCountryStatus = () => {
    this.setState({
      countryState: !this.state.countryState
    })
  }

  onCountrySelect = value => {
    const { setFieldValue } = this.props.formProps
    setFieldValue('trusted_contact_country', value['alpha-3'])
    setFieldValue('country_input', value.name)
  }

  onCountryChange = e => {
    const { formProps } = this.props
    if (!this.state.countryState) {
      this.setState({
        countryState: true
      })
    }
    formProps.handleChange(e)
  }

  toggleStateStatus = () => {
    this.setState({
      statesState: !this.state.statesState
    })
  }

  onStateChange = e => {
    const { formProps } = this.props

    if (e.target.value == '') {
      formProps.setFieldValue('trusted_contact_state', e.target.value)
    }

    if (!this.state.statesState) {
      this.setState({
        statesState: true
      })
    }
    formProps.handleChange(e)
  }

  onStateSelect = item => {
    const { setFieldValue } = this.props.formProps
    setFieldValue('trusted_contact_state', item.name)
    setFieldValue('state_value', item.abbreviation)
    setFieldValue('stateInput', item.name)
  }


  handleZipCodeChangeTrusted = async (value) => {
    const { setFieldValue, values } = this.props.formProps
    if (values.country_input.toLowerCase() === 'united states') {
      this.setState({ trustedZipNotValid: '' })
      setFieldValue('trusted_contact_state', '')
      setFieldValue('state_value', '')
      setFieldValue('stateInput', '')
    }
    if (this.state.zipCodeTimeOut) {
      clearTimeout(this.state.zipCodeTimeOut);
    }
    this.setState({ zipCodeTimeOut: null }, () => {
      if (value && value.match(Regex.zipCode)) {
        if (values.country_input.toLowerCase() === 'united states') {
          let mtTime = setTimeout(async () => {
            try {
              let cityData = await getCityUsingZip(value.substring(0, 5));
              if (cityData.data) {
                if (cityData.data.state) {
                  setFieldValue('trusted_contact_state', mapAbbrToState(cityData.data.state))
                  setFieldValue('state_value', cityData.data.state)
                  setFieldValue('stateInput', mapAbbrToState(cityData.data.state))
                }
                if (cityData.data.city) {
                  setFieldValue('trusted_contact_city', cityData.data.city)
                }
              } else {
                this.setState({ trustedZipNotValid: 'Zip Code is not valid' })
              }
            } catch (err) {
              this.setState({ trustedZipNotValid: 'Zip Code is not valid' })
            }
          }, 700)
          this.setState({ zipCodeTimeOut: mtTime })
        }
      }
    })
  }


  phoneCodeChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  togglePhoneCode = () => {
    this.setState({
      phoneCodeState: !this.state.phoneCodeState
    })
  }

  toggleCountryP = () => {
    this.setState({
      countryStateP: !this.state.countryStateP
    })
  }

  onCountrySelectP = value => {
    const { setFieldValue } = this.props.formProps
    setFieldValue('country', value)
    setFieldValue('countryInputP', value)
  }

  onCountryChangeP = e => {
    const { formProps } = this.props
    if (!this.state.countryStateP) {
      this.setState({
        countryStateP: true
      })
    }
    formProps.handleChange(e)
  }

  toggleStateStatusP = () => {
    this.setState({
      statesStateP: !this.state.statesStateP
    })
  }

  onStateChangeP = e => {
    const { formProps } = this.props

    if (e.target.value == '') {
      formProps.setFieldValue('state', e.target.value)
    }

    if (!this.state.statesStateP) {
      this.setState({
        statesStateP: true
      })
    }
    formProps.handleChange(e)
  }

  onStateSelectP = item => {
    const { setFieldValue } = this.props.formProps
    setFieldValue('stateValueP', item.abbreviation)
    setFieldValue('stateInputP', item.name)
    setFieldValue('state', item.abbreviation)
  }

  toggleAnnualIncome = () => {
    this.setState({
      annualIncome: !this.state.annualIncome
    }, () => {
      if (this.state.annualIncome) {
        setTimeout(() => {
          document.getElementById('annual_income_child0').focus()
        }, 100)
      }
    })
  }

  getSplittedValue = (value) => {
    let formattedIndex = formattedRange.findIndex(el => Object.keys(el)[0] === value)
    if (formattedIndex >= 0) {
      return formattedRange[formattedIndex][value]
    }
    return value
  }

  toggleTNW = () => {
    this.setState({
      totalNetWorth: !this.state.totalNetWorth
    }, () => {
      if (this.state.totalNetWorth) {
        setTimeout(() => {
          document.getElementById('total_net_worth_child0').focus()
        }, 100)
      }
    })
  }

  toggleInvestObjType = () => {
    this.setState({
      investmentObj: !this.state.investmentObj
    }, () => {
      if (this.state.investmentObj) {
        setTimeout(() => {
          document.getElementById('investment_obj_child0').focus()
        }, 100)
      }
    })
  }

  toggleDividentReinvestment = () => {
    this.setState({
      dividentReinvestment: !this.state.dividentReinvestment
    }, () => {
      if (this.state.dividentReinvestment) {
        setTimeout(() => {
          document.getElementById('divident_reinvestment_child0').focus()
        }, 100)
      }
    })
  }

  handleZipCodeChangePrimary = async (value) => {
    const { setFieldValue, values } = this.props.formProps
    if (values.country.toLowerCase() === 'united states') {
      this.setState({ primaryZipNotValid: '' })
      setFieldValue('stateValueP', '')
      setFieldValue('stateInputP', '')
      setFieldValue('state', '')
    }
    if (this.state.zipCodeTimeOut) {
      clearTimeout(this.state.zipCodeTimeOut);
    }
    this.setState({ zipCodeTimeOut: null }, () => {
      if (value && value.match(Regex.zipCode)) {
        if (values.country.toLowerCase() === 'united states') {
          let mtTime = setTimeout(async () => {
            try {
              let cityData = await getCityUsingZip(value.substring(0, 5));
              if (cityData.data) {
                if (cityData.data.state) {
                  setFieldValue('stateValueP', cityData.data.state)
                  setFieldValue('stateInputP', mapAbbrToState(cityData.data.state))
                  setFieldValue('state', cityData.data.state)
                }
                if (cityData.data.city) {
                  setFieldValue('city', cityData.data.city)
                }
              } else {
                this.setState({ primaryZipNotValid: 'Zip Code is not valid' })
              }
            } catch (err) {
              this.setState({ primaryZipNotValid: 'Zip Code is not valid' })
            }
          }, 700)
          this.setState({ zipCodeTimeOut: mtTime })
        }
      }
    })
  }

  render() {
    const { formProps, step, merger, hideStepBar, setAccountTypeG, fromRegister } = this.props
    const {
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue
    } = formProps

    let totalSteps = values.account_type === 'Joint' ? 3 : 2

    totalSteps = this.props.isBranded ? ++totalSteps : ++totalSteps

    return (
      <>
        {/* <div>
          {!hideStepBar ? <StepsBar step={step} account_type={values.account_type} totalSteps={totalSteps} /> : null}
        </div> */}
        {/* {!hideStepBar ? <div className="header-area">
          <div className="header-label sub">
            Setup Your Magnifi Investment Account
          </div>
        </div> : null} */}
        {/* <div className="header-label-sub-gray pb-2">
            This Will Allow You To Open A Magnifi Account You Can Use For Investing
          </div> */}
        <div className="w-100 mt-3">
          <span className='heading_in_between'>Now, let's setup your account - first your contact info: <span style={{ marginTop: -2, fontSize: '0.8rem' }}>&nbsp;&dagger;&nbsp;</span></span>
        </div>
        <Row className="form_input_container_gray border_radius_top border_radius_bottom">
          <Col xs={6} md={6} lg={6} xl={6}>
            <FormGroup className="element-box pos-rel">
              <Label className={values.firstname ? "label new_label has_value" : "label new_label"} htmlFor="firstname">
                First Name
              </Label>
              <Input
                type="text"
                name="firstname"
                id="firstname"
                value={values.firstname}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.firstname && touched.firstname
                    ? 'is-invalid input-area'
                    : 'input-area'
                }
              />
              {errors.firstname && touched.firstname && (
                <div className="form-invalid-field">
                  {errors['firstname']}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={6} md={6} lg={6} xl={6}>
            <FormGroup className="element-box pos-rel">
              <Label className={values.lastname ? "label new_label has_value" : "label new_label"} htmlFor="lastname">
                Last Name
              </Label>
              <Input
                type="text"
                name="lastname"
                id="lastname"
                value={values.lastname}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.lastname && touched.lastname
                    ? 'is-invalid input-area'
                    : 'input-area'
                }
              />
              {errors.lastname && touched.lastname && (
                <div className="form-invalid-field">{errors['lastname']}</div>
              )}
            </FormGroup>
          </Col>
          <Col xs={6} md={6} lg={6} xl={fromRegister ? 6 : 6}>
            <FormGroup className="element-box pos-rel">
              <Label className={values.address ? "label new_label has_value" : "label new_label"} htmlFor="address">
                Street Address
              </Label>
              <Input
                onKeyDown={(e) => {
                  if (e.key === 'Tab' && fromRegister) {
                    e.preventDefault();
                    document.getElementById('account_type').click();
                    document.getElementById('account_type').focus();
                  }
                }}
                type="text"
                name="address"
                id="address"
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.address && touched.address
                    ? 'is-invalid input-area'
                    : 'input-area'
                }
              />
              {/* <CustomFormFeedback formProps={formProps} fieldName="street" /> */}
              {errors.address && touched.address && (
                <div className="form-invalid-field">{errors['address']}</div>
              )}
            </FormGroup>
          </Col>
          <Col xs={6} md={6} lg={6} xl={fromRegister ? 6 : 6}>
            {/*zip*/}
            <FormGroup className="element-box pos-rel">
              <Label className={values.zipcode ? "label new_label has_value" : "label new_label"} htmlFor="zipcode">
                Zip code
              </Label>
              <Input
                type="text"
                name="zipcode"
                id="zipcode"
                value={values.zipcode}
                onChange={(e) => {
                  this.handleZipCodeChangePrimary(e.target.value)
                  handleChange(e)
                }}
                onBlur={handleBlur}
                className={
                  errors.zipcode && touched.zipcode
                    ? 'is-invalid input-area'
                    : 'input-area'
                }
              // placeholder="Enter the zip code"
              />
              {this.state.primaryZipNotValid ?
                <div className="form-invalid-field">
                  {this.state.primaryZipNotValid}
                </div>
                : <CustomFormFeedback formProps={formProps} fieldName="zipcode" />}
            </FormGroup>
          </Col>
          <Col xs={6} md={6} lg={6} xl={6}>
            <FormGroup className="element-box dropdown-element pos-rel">
              <Label className={values.phone_number ? "label new_label has_value" : "label new_label"} htmlFor="phoneNumber">
                Phone Number
              </Label>
              <PhoneNumberInput
                hideCode={true}
                onChangePhoneCode={val => setFieldValue('phone_code', val)}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                phoneCodeName="phone_code"
                phoneName="phone_number"
              />
              {errors.phone_number && touched.phone_number && (
                <div className="form-invalid-field">
                  {errors['phone_number']}
                </div>
              )}
            </FormGroup>
          </Col>
          
          {!fromRegister ? <Col xs={6} md={6} lg={6} xl={6}>
            <FormGroup className="element-box dropdown-element pos-rel">
              <Label className={values.date_of_birth ? "label new_label has_value" : "label new_label"} htmlFor="date_of_birth">
                Date of Birth
              </Label>
              {/* <DropdownDatePicker
                shortForm
                bg={'#f7f7f7'}
                id="date_of_birth"
                value={values.date_of_birth
                  ? typeof values.date_of_birth.getMonth === 'function'
                    ? values.date_of_birth._d
                    : moment(values.date_of_birth)._d
                  : null}
                onDateChange={(dateInstance) => {
                  let dob = moment(dateInstance).format('YYYY-MM-DD')
                  if (dateInstance) setFieldValue('date_of_birth', dob)
                }}
                maxDate={new Date(
                  moment()
                    .subtract(18, 'years')
                    .format('YYYY-MM-DD')
                )}
              /> */}

              <DatePicker
                onKeyDown={(e) => {
                  if (e.key === 'Tab') {
                    e.preventDefault();
                    document.getElementById('account_type').click();
                    document.getElementById('account_type').focus();
                  }
                }}
                id="date_of_birth"
                selected={
                  values.date_of_birth
                    ? typeof values.date_of_birth.getMonth === 'function'
                      ? values.date_of_birth
                      : moment(values.date_of_birth)._d
                    : values.date_of_birth
                }
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                maxDate={
                  new Date(
                    moment()
                      .subtract(18, 'years')
                      .format('YYYY-MM-DD')
                  )
                }
                autoComplete="off"
                onChange={(dateInstance, dateString) => {
                  let dob = moment(dateInstance).format('YYYY-MM-DD')
                  if (dateInstance) setFieldValue('date_of_birth', dob)
                }}
                className={
                  errors.date_of_birth && touched.date_of_birth
                    ? 'is-invalid input-area form-control'
                    : 'input-area form-control'
                }
              />

              {errors.date_of_birth && touched.date_of_birth && (
                <div className="form-invalid-field">
                  {errors['date_of_birth']}
                </div>
              )}
            </FormGroup>
          </Col> : null}
        </Row>

        {values.account_type && values.account_type === 'Custodial' && (
          <React.Fragment>
            {/* <div className="element-box section-heading">
              <Label className="label" htmlFor="primary">Primary Applicant*</Label>
            </div>
            <Row>
              <Col>
                <FormGroup className="element-box dropdown-element pos-rel">
                  <Label className="label" htmlFor="primaryFirstName">First Name*</Label>
                  <Input
                    type="text"
                    name="primary_firstname"
                    id="primaryFirstName"
                    value={values.primary_firstname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.primary_firstname && touched.primary_firstname ? 'is-invalid input-area' : 'input-area'}
                    placeholder="Enter first name"
                    />
                  <CustomFormFeedback formProps={formProps} fieldName="primary_firstname" />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="element-box dropdown-element pos-rel">
                  <Label className="label" htmlFor="primaryLastName">Last Name*</Label>
                    <Input
                      type="text"
                      name="primary_lastname"
                      id="primaryLastName"
                      value={values.primary_lastname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={errors.primary_lastname && touched.primary_lastname ? 'is-invalid input-area' : 'input-area'}
                      placeholder="Enter last name"
                      />
                    <CustomFormFeedback formProps={formProps} fieldName="primary_lastname" />
                </FormGroup>
              </Col>
            </Row> */}

            <div className="element-box section-heading">
              <Label className="label" htmlFor="secondary">
                Secondary Applicant
              </Label>
            </div>
            <Row>
              <Col>
                <FormGroup className="element-box dropdown-element pos-rel">
                  <Label className={values.secondary_applicant_first_name ? "label new_label has_value" : "label new_label"} htmlFor="secondaryFirstName">
                    First Name
                  </Label>
                  <Input
                    type="text"
                    name="secondary_applicant_first_name"
                    id="secondaryFirstName"
                    value={values.secondary_applicant_first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.secondary_applicant_first_name &&
                        touched.secondary_applicant_first_name
                        ? 'is-invalid input-area'
                        : 'input-area'
                    }
                  />
                  <CustomFormFeedback
                    formProps={formProps}
                    fieldName="secondary_applicant_first_name"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="element-box dropdown-element pos-rel">
                  <Label className={values.secondary_applicant_last_name ? "label new_label has_value" : "label new_label"} htmlFor="secondaryLastName">
                    Last Name
                  </Label>
                  <Input
                    type="text"
                    name="secondary_applicant_last_name"
                    id="secondaryLastName"
                    value={values.secondary_applicant_last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.secondary_applicant_last_name &&
                        touched.secondary_applicant_last_name
                        ? 'is-invalid input-area'
                        : 'input-area'
                    }
                  />
                  <CustomFormFeedback
                    formProps={formProps}
                    fieldName="secondary_applicant_last_name"
                  />
                </FormGroup>
              </Col>
            </Row>
          </React.Fragment>
        )}

        {/* <Row className="form_input_container_gray">
          <Col>
            <FormGroup className="element-box dropdown-element pos-rel country-wrapper">
              <Label className={values.citizenship || values.citizenshipInput ? "label new_label has_value" : "label new_label"} htmlFor="citizenship">
                Citizenship*
              </Label>
              <Dropdown
                className={
                  errors.citizenship && touched.citizenship
                    ? 'is-invalid dropdown-wrapper'
                    : 'dropdown-wrapper'
                }
                isOpen={this.state.citizenshipState === true}
                toggle={this.toggleCitizenshipStatus}
              >
                <DropdownToggle
                  id="citizenship"
                  value={values.citizenship}
                  style={{ backgroundColor: 'transparent' }}
                  className={
                    errors.citizenship && touched.citizenship
                      ? 'is-invalid input-area'
                      : 'input-area'
                  }
                  caret
                >
                  <input
                    style={{ padding: 0, height: `20px` }}
                    type="search"
                    placeholder="Select"
                    name="citizenshipInput"
                    value={values.citizenshipInput}
                    onChange={e => this.onCitizenshipChange(e)}
                    className="typeahead-field"
                    autocomplete="off"
                  />
                </DropdownToggle>
                <DropdownMenu>
                  {countryList1.map(item => {
                    if (
                      !values.citizenshipInput ||
                      toLower(item.name).includes(
                        toLower(values.citizenshipInput)
                      )
                    ) {
                      return (
                        <DropdownItem
                          value={item.name}
                          onClick={e => this.onCitizenshipSelect(item)}
                        >
                          {item.name}
                        </DropdownItem>
                      )
                    }
                    return null
                  })}
                </DropdownMenu>
              </Dropdown>

              {errors.citizenship && touched.citizenship && (
                <div className="form-invalid-field">
                  {errors['citizenship']}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row> */}

        <div className="w-100 mt-3">
          <span className='heading_in_between'>Next, your account details: <span style={{ marginTop: -2, fontSize: '0.8rem' }}>&nbsp;&dagger;&dagger;&nbsp;</span></span>
        </div>
        {/* <Row>
          <FormGroup className="element-box dropdown-element pos-rel" check style={{paddingBottom:'1.75rem'}}>
            <div className="toggle-switch-wrapper">
              <div className="label">Are you employed?*</div>
              <Label check className="register-form__term-cond-label toggle-switch">
                <Input
                  type="checkbox"
                  name="are_you_employed"
                  id="are_you_employed"
                  className={
                    errors.are_you_employed && touched.are_you_employed
                    ? 'register-form__checkbox is-invalid input-area toggle-switch-input'
                    : 'register-form__checkbox input-area toggle-switch-input'
                  }
                  checked={values.are_you_employed}
                  onChange={handleChange}
                  />
                <span className="slider round"></span>
              </Label>

            </div>
            <CustomFormFeedback
              formProps={formProps}
              fieldName="are_you_employed"
              />
            {errors.are_you_employed && touched.are_you_employed && <div className="form-invalid-field">{errors['are_you_employed']}</div>}
          </FormGroup>
          </Row> */}
        <Row className="form_input_container_gray border_radius_top">
          <Col xs={12} md={12} lg={6} xl={4}>
            <FormGroup className="element-box dropdown-element pos-rel">
              <Label className={values.account_type ? "label new_label has_value" : "label new_label"} htmlFor="account_type">
                Account Type
              </Label>
              <Dropdown
                className={
                  errors.account_type && touched.account_type
                    ? 'is-invalid dropdown-wrapper'
                    : 'dropdown-wrapper'
                }
                isOpen={this.state.account_type === true}
                toggle={this.toggleAccountType}
                onChange={this.handleAccountTypeChange}
              >
                <DropdownToggle
                  id="account_type"
                  value={values.account_type}
                  style={{ backgroundColor: 'transparent' }}
                  className={
                    errors.account_type && touched.account_type
                      ? 'is-invalid input-area'
                      : 'input-area'
                  }
                  caret
                >
                  {values.account_type || ''}
                </DropdownToggle>
                <DropdownMenu center>
                  {accountType.map((item, i) => {
                    return (
                      <DropdownItem
                        key={`accountType${i}`}
                        id={'account_type_child' + i}
                        value={item.value}
                        disabled={item.disabled}
                        onClick={e => {
                          setAccountTypeG(e.target.value)
                          setFieldValue('account_type', e.target.value)
                        }}
                      >
                        {item.value}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </Dropdown>

              {errors.account_type && touched.account_type && (
                <div className="form-invalid-field">
                  {errors['account_type']}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} md={12} lg={6} xl={4}>
            <FormGroup className="element-box dropdown-element pos-rel">
              <Label className={values.marital_status ? "label new_label has_value" : "label new_label"} htmlFor="marital_status">
                Marital Status
              </Label>
              <Dropdown
                className={
                  errors.marital_status && touched.marital_status
                    ? 'is-invalid dropdown-wrapper'
                    : 'dropdown-wrapper'
                }
                isOpen={this.state.marital_status === true}
                toggle={this.toggleMaritalStatus}
                onChange={this.handleMaritalStatusChange}
              >
                <DropdownToggle
                  id="marital_status"
                  onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                      e.preventDefault();
                      document.getElementById('annual_income').click();
                      document.getElementById('annual_income').focus();
                    }
                  }}
                  value={values.marital_status}
                  style={{ backgroundColor: 'transparent' }}
                  className={
                    errors.marital_status && touched.marital_status
                      ? 'is-invalid input-area'
                      : 'input-area'
                  }
                  caret
                >
                  {values.marital_status || ''}
                </DropdownToggle>
                <DropdownMenu center>
                  {maritalStatus.map((item, i) => {
                    return (
                      <DropdownItem
                        id={'marital_status_child' + i}
                        key={`maritialStatus${i}`}
                        value={item}
                        onClick={e => {
                          setFieldValue('marital_status', e.target.value)
                        }}
                      >
                        {item}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </Dropdown>

              {errors.marital_status && touched.marital_status && (
                <div className="form-invalid-field">
                  {errors['marital_status']}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} md={12} lg={6} xl={4}>
            <FormGroup className="element-box dropdown-element pos-rel">
              <Label className={values.ssn ? "label new_label has_value" : "label new_label"} htmlFor="ssn">
                Social Security Number
              </Label>

              <Cleave
                onKeyDown={(e) => {
                  if (e.key === 'Tab') {
                    e.preventDefault();
                    document.getElementById('marital_status').click();
                    document.getElementById('marital_status').focus();
                  }
                }}
                // placeholder="Enter SSN"
                value={values.ssn}
                options={{
                  delimiters: ['-', '-'],
                  numericOnly: true,
                  uppercase: true,
                  blocks: [3, 2, 4]
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.ssn && touched.ssn
                    ? 'is-invalid input-area form-control'
                    : 'input-area form-control'
                }
                id="ssn"
                name="ssn"
              />

              <CustomFormFeedback formProps={formProps} fieldName="ssn" />
            </FormGroup>
          </Col>
          <Col xs={12} md={12} lg={6} xl={6}>
            <FormGroup className="element-box dropdown-element pos-rel">
              <Label className={values.are_you_employed ? "label new_label has_value" : "label new_label"} htmlFor="are_you_employed">
                Employment Type
              </Label>
              <Dropdown
                className={
                  errors.are_you_employed && touched.are_you_employed
                    ? 'is-invalid dropdown-wrapper'
                    : 'dropdown-wrapper'
                }
                isOpen={this.state.are_you_employed === true}
                toggle={this.toggleAreYouEmp}
              // onChange={this.handleAccountTypeChange}
              >
                <DropdownToggle
                  id="are_you_employed"
                  onKeyDown={(e) => {
                    if (e.key === 'Tab' && values.are_you_employed !== 'Employed') {
                      e.preventDefault();
                      document.getElementById('total_net_worth').click();
                      document.getElementById('total_net_worth').focus();
                    }
                  }}
                  value={values.are_you_employed}
                  style={{ backgroundColor: 'transparent' }}
                  className={
                    errors.are_you_employed && touched.are_you_employed
                      ? 'is-invalid input-area'
                      : 'input-area'
                  }
                  caret
                >
                  {values.are_you_employed || ''}
                </DropdownToggle>
                <DropdownMenu center>
                  {employmentType.map((item, i) => {
                    return (
                      <DropdownItem
                        id={'are_you_employed_child' + i}
                        value={item}
                        onClick={e => this.handleDropDownEmp(e)}
                      >
                        {item}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </Dropdown>
              {errors.are_you_employed && touched.are_you_employed && (
                <div className="form-invalid-field">
                  {errors['are_you_employed']}
                </div>
              )}
            </FormGroup>
          </Col>
          {values.are_you_employed == 'Employed' ? <Col xs={12} md={12} lg={6} xl={6}>
            <FormGroup className="element-box dropdown-element pos-rel">
              <Label className={values.employer_name ? "label new_label has_value" : "label new_label"} htmlFor="employer_name">
                Name of the Employer
              </Label>
              <Input
                type="text"
                name="employer_name"
                id="employer_name"
                value={values.employer_name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.employer_name && touched.employer_name
                    ? 'is-invalid input-area'
                    : 'input-area'
                }
              />
              <CustomFormFeedback
                formProps={formProps}
                fieldName="employer_name"
              />
            </FormGroup>
          </Col> : null}
          {values.are_you_employed == 'Employed' ? <Col xs={12} md={12} lg={6} xl={6}>
            <FormGroup className="element-box dropdown-element pos-rel">
              <Label className={values.occupation ? "label new_label has_value" : "label new_label"} htmlFor="occupation">
                Occupation
              </Label>
              <Input
                type="text"
                name="occupation"
                onKeyDown={(e) => {
                  if (e.key === 'Tab') {
                    e.preventDefault();
                    document.getElementById('total_net_worth').click();
                    document.getElementById('total_net_worth').focus();
                  }
                }}
                id="occupation"
                value={values.occupation}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.occupation && touched.occupation
                    ? 'is-invalid input-area'
                    : 'input-area'
                }
              />
              {errors.occupation && touched.occupation && (
                <div className="form-invalid-field">
                  {errors['occupation']}
                </div>
              )}
            </FormGroup>
          </Col> : null}
          <Col xs={12} md={12} lg={6} xl={6}>
            <FormGroup className="element-box dropdown-element pos-rel">
              <Label className={values.annual_income ? "label new_label has_value" : "label new_label"} htmlFor="annual_income">
                Annual Income
              </Label>
              <Dropdown
                className={
                  errors.annual_income && touched.annual_income
                    ? 'is-invalid dropdown-wrapper'
                    : 'dropdown-wrapper'
                }
                isOpen={this.state.annualIncome}
                toggle={this.toggleAnnualIncome}
              >
                <DropdownToggle
                  id="annual_income"
                  onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                      e.preventDefault();
                      document.getElementById('experience').click();
                      document.getElementById('experience').focus();
                    }
                  }}
                  value={values.annual_income}
                  style={{ backgroundColor: 'transparent' }}
                  className={
                    errors.annual_income && touched.annual_income
                      ? 'is-invalid input-area'
                      : 'input-area'
                  }
                  caret
                >
                  {values.annual_income ? this.getSplittedValue(values.annual_income) : ''}
                </DropdownToggle>
                <DropdownMenu>
                  {annualIncomeRange.map((item, i) => {
                    return (
                      <DropdownItem
                        key={`annual_income${i}`}
                        id={'annual_income_child' + i}
                        value={item}
                        onClick={e => {
                          setFieldValue('annual_income', e.target.value)
                        }}
                      >
                        {this.getSplittedValue(item)}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </Dropdown>

              {errors.annual_income && touched.annual_income && (
                <div className="form-invalid-field">
                  {errors['annual_income']}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} md={12} lg={6} xl={6}>
            <FormGroup className="element-box dropdown-element pos-rel">
              <Label className={values.investment_obj ? "label new_label has_value" : "label new_label"} htmlFor="investment_obj">
                Investment Goals
              </Label>
              <Dropdown
                className={
                  errors.investment_obj && touched.investment_obj
                    ? 'is-invalid dropdown-wrapper'
                    : 'dropdown-wrapper'
                }
                isOpen={this.state.investmentObj}
                toggle={this.toggleInvestObjType}
              >
                <DropdownToggle
                  id="investment_obj"
                  onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                      e.preventDefault();
                      document.getElementById('divident_reinvestment').click();
                      document.getElementById('divident_reinvestment').focus();
                    }
                  }}
                  value={values.investment_obj}
                  style={{ backgroundColor: 'transparent' }}
                  className={
                    errors.investment_obj && touched.investment_obj
                      ? 'is-invalid input-area'
                      : 'input-area'
                  }
                  caret
                >
                  {capitalizeAll(
                    values.investment_obj
                      .split('_')
                      .map(i => i.toLowerCase())
                      .join(' ')
                  ) || ''}
                </DropdownToggle>
                <DropdownMenu>
                  {investMentObjectives.map((item, i) => {
                    return (
                      <DropdownItem
                        id={"investment_obj_child" + i}
                        className={'ca'}
                        key={`investment_obj${i}`}
                        value={item}
                        onClick={e => {
                          setFieldValue('investment_obj', e.target.value)
                        }}
                      >
                        {capitalizeAll(
                          item
                            .split('_')
                            .map(i => i.toLowerCase())
                            .join(' ')
                        )}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </Dropdown>

              {errors.investment_obj && touched.investment_obj && (
                <div className="form-invalid-field">
                  {errors['investment_obj']}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} md={12} lg={6} xl={6}>
            <FormGroup className="element-box dropdown-element pos-rel">
              <Label className={values.divident_reinvestment ? "label new_label has_value" : "label new_label"} htmlFor="divident_reinvestment">
                Dividend Reinvestment
              </Label>
              <Dropdown
                className={
                  errors.divident_reinvestment &&
                    touched.divident_reinvestment
                    ? 'is-invalid dropdown-wrapper'
                    : 'dropdown-wrapper'
                }
                isOpen={this.state.dividentReinvestment}
                toggle={this.toggleDividentReinvestment}
              >
                <DropdownToggle
                  id="divident_reinvestment"
                  value={values.divident_reinvestment}
                  style={{ backgroundColor: 'transparent' }}
                  className={
                    errors.divident_reinvestment &&
                      touched.divident_reinvestment
                      ? 'is-invalid input-area'
                      : 'input-area'
                  }
                  caret
                >
                  {capitalizeAll(
                    values.divident_reinvestment
                      .split('_')
                      .map(i => i.toLowerCase())
                      .join(' ')
                  ) || ''}
                </DropdownToggle>
                <DropdownMenu>
                  {dividentReinvestment.map((item, i) => {
                    return (
                      <DropdownItem
                        id={'divident_reinvestment_child' + i}
                        key={`divident_reinvestment${i}`}
                        value={item}
                        onClick={e => {
                          setFieldValue(
                            'divident_reinvestment',
                            e.target.value
                          )
                        }}
                      >
                        {capitalizeAll(
                          item
                            .split('_')
                            .map(i => i.toLowerCase())
                            .join(' ')
                        )}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </Dropdown>

              {errors.divident_reinvestment &&
                touched.divident_reinvestment && (
                  <div className="form-invalid-field">
                    {errors['divident_reinvestment']}
                  </div>
                )}
            </FormGroup>
          </Col>
          <Col xs={12} md={12} lg={6} xl={6}>
            <FormGroup className="element-box dropdown-element pos-rel">
              <Label className={values.experience ? "label new_label has_value" : "label new_label"} htmlFor="experience">
                Investment Experience
              </Label>
              <Dropdown
                className={
                  errors.experience && touched.experience
                    ? 'is-invalid dropdown-wrapper'
                    : 'dropdown-wrapper'
                }
                isOpen={this.state.experience === true}
                toggle={this.toggleExperienceStatus}
                onChange={this.handleExperienceChange}
              >
                <DropdownToggle
                  id="experience"
                  onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                      e.preventDefault();
                      document.getElementById('are_you_employed').click();
                      document.getElementById('are_you_employed').focus();
                    }
                  }}
                  value={values.experience}
                  style={{ backgroundColor: 'transparent' }}
                  className={
                    errors.experience && touched.experience
                      ? 'is-invalid input-area'
                      : 'input-area'
                  }
                  caret
                >
                  {values.experience || ''}
                </DropdownToggle>
                <DropdownMenu>
                  {tradingExperience.map((item, i) => {
                    return (
                      <DropdownItem
                        value={item.value}
                        id={'experience' + i}
                        onClick={e => {
                          setFieldValue('experience', e.target.value)
                          setFieldValue('experience_value', item.key)
                        }}
                      >
                        {item.value}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </Dropdown>

              {errors.experience && touched.experience && (
                <div className="form-invalid-field">
                  {errors['experience']}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} md={12} lg={6} xl={6}>
            <FormGroup className="element-box dropdown-element pos-rel">
              <Label className={values.total_net_worth ? "label new_label has_value" : "label new_label"} htmlFor="total_net_worth">
                Total Net Worth
              </Label>
              <Dropdown
                className={
                  errors.total_net_worth && touched.total_net_worth
                    ? 'is-invalid dropdown-wrapper'
                    : 'dropdown-wrapper'
                }
                isOpen={this.state.totalNetWorth}
                toggle={this.toggleTNW}
              >
                <DropdownToggle
                  id="total_net_worth"
                  onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                      e.preventDefault();
                      document.getElementById('investment_obj').click();
                      document.getElementById('investment_obj').focus();
                    }
                  }}
                  value={values.total_net_worth}
                  style={{ backgroundColor: 'transparent' }}
                  className={
                    errors.total_net_worth && touched.total_net_worth
                      ? 'is-invalid input-area'
                      : 'input-area'
                  }
                  caret
                >
                  {values.total_net_worth ? this.getSplittedValue(values.total_net_worth) : ''}
                </DropdownToggle>
                <DropdownMenu>
                  {totalNetWorth.map((item, i) => {
                    return (
                      <DropdownItem
                        key={`total_net_worth${i}`}
                        id={'total_net_worth_child' + i}
                        value={item}
                        onClick={e => {
                          setFieldValue('total_net_worth', e.target.value)
                        }}
                      >
                        {this.getSplittedValue(item)}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </Dropdown>

              {errors.total_net_worth && touched.total_net_worth && (
                <div className="form-invalid-field">
                  {errors['total_net_worth']}
                </div>
              )}
            </FormGroup>
          </Col>
          {/* <Col lg={6} xl={4}>
            <FormGroup className="element-box dropdown-element pos-rel country-wrapper">
              <Label className={values.country || values.countryInputP ? "label new_label has_value" : "label new_label"} htmlFor="country">
                Country*
              </Label>
              <Dropdown
                className={
                  errors.country && touched.country
                    ? 'is-invalid dropdown-wrapper'
                    : 'dropdown-wrapper'
                }
                isOpen={this.state.countryStateP}
                toggle={this.toggleCountryP}
              >
                <DropdownToggle
                  id="country"
                  value={values.country}
                  style={{ backgroundColor: 'transparent' }}
                  className={
                    errors.country && touched.country
                      ? 'is-invalid input-area'
                      : 'input-area'
                  }
                  caret
                >
                  <input
                    style={{ padding: 0, height: `20px` }}
                    type="search"
                    name="countryInputP"
                    value={values.countryInputP}
                    onChange={e => this.onCountryChangeP(e)}
                    className="typeahead-field"
                    autocomplete="off"
                  />
                </DropdownToggle>
                <DropdownMenu>
                  {countryList.map(item => {
                    if (
                      !values.countryInputP ||
                      toLower(item.name).includes(
                        toLower(values.countryInputP)
                      )
                    ) {
                      return (
                        <DropdownItem
                          value={item.name}
                          onClick={e => this.onCountrySelectP(e.target.value)}
                        >
                          {item.name}
                        </DropdownItem>
                      )
                    }
                    return null
                  })}
                </DropdownMenu>
              </Dropdown>

              {errors.country && touched.country && (
                <div className="form-invalid-field">{errors['country']}</div>
              )}
            </FormGroup>
          </Col> */}

          {/*state*/}
          {/* <Col lg={6} xl={4}>
            <FormGroup className="element-box dropdown-element pos-rel country-wrapper">
              <Label className={values.state || values.stateInputP ? "label new_label has_value" : "label new_label"} htmlFor="state">
                State*
              </Label>
              {values.country !== 'United States' ? (
                <>
                  <Input
                    type="text"
                    name="state"
                    id="state"
                    value={values.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.state && touched.state
                        ? 'is-invalid input-area'
                        : 'input-area'
                    }
                  />
                </>
              ) : (
                <Dropdown
                  className={
                    errors.state && touched.state
                      ? 'is-invalid dropdown-wrapper'
                      : 'dropdown-wrapper'
                  }
                  isOpen={this.state.statesStateP}
                  toggle={this.toggleStateStatusP}
                >
                  <DropdownToggle
                    id="state"
                    value={values.state}
                    style={{ backgroundColor: 'transparent' }}
                    className={
                      errors.state && touched.state
                        ? 'is-invalid input-area'
                        : 'input-area'
                    }
                    caret
                  >
                    <input
                      style={{ padding: 0, height: `20px` }}
                      type="search"
                      name="stateInputP"
                      value={values.stateInputP}
                      onChange={e => this.onStateChangeP(e)}
                      className="typeahead-field"
                      autoComplete={`stateInputP`}
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    {usStates.map(item => {
                      if (
                        !values.stateInputP ||
                        toLower(item.name).includes(
                          toLower(values.stateInputP)
                        )
                      ) {
                        return (
                          <DropdownItem
                            value={item.abbreviation}
                            onClick={e => this.onStateSelectP(item)}
                          >
                            {item.name}
                          </DropdownItem>
                        )
                      }
                      return null
                    })}
                  </DropdownMenu>
                </Dropdown>
              )}
              {errors.state && touched.state && (
                <div className="form-invalid-field">{errors['state']}</div>
              )}
            </FormGroup>
          </Col> */}
          {/*city*/}
          {/* <Col lg={6} xl={4}>
            <FormGroup className="element-box pos-rel">
              <Label className={values.city ? "label new_label has_value" : "label new_label"} htmlFor="city">
                City*
              </Label>
              <Input
                type="text"
                name="city"
                id="city"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.city && touched.city
                    ? 'is-invalid input-area'
                    : 'input-area'
                }
              />
              {errors.city && touched.city && (
                <div className="form-invalid-field">{errors['city']}</div>
              )}
            </FormGroup>
          </Col> */}
          {/* </Row>
          <Row> */}

        </Row>
        <Row className={"form_input_container_gray"}>
          <Col>
            <FormGroup
              className="element-box dropdown-element pl-0 pos-rel"
              check
              style={{ padding: 0 }}
            >
              <div className="toggle-switch-wrapper" style={{ justifyContent: 'center' }}>
                <div className="label">
                  Do you want to add a trusted contact?
                </div>
                <Label
                  check
                  className="register-form__term-cond-label toggle-switch"
                >
                  <Input
                    type="checkbox"
                    name="trusted_contact"
                    id="trusted_contact"
                    className={
                      errors.trusted_contact && touched.trusted_contact
                        ? 'register-form__checkbox is-invalid input-area toggle-switch-input'
                        : 'register-form__checkbox input-area toggle-switch-input'
                    }
                    checked={values.trusted_contact}
                    onChange={handleChange}
                  />
                  <span className="slider round"></span>
                </Label>
              </div>
              <CustomFormFeedback
                formProps={formProps}
                fieldName="trusted_contact"
              />
              {errors.trusted_contact && touched.trusted_contact && (
                <div className="form-invalid-field">
                  {errors['trusted_contact']}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        {values.trusted_contact && (
          <>
            <Row className="form_input_container_gray">
              <Col>
                <FormGroup className="element-box dropdown-element pos-rel">
                  <Label
                    className={values.trusted_contact_firstname ? "label new_label has_value" : "label new_label"}
                    htmlFor="trusted_contact_firstname"
                  >
                    First Name
                  </Label>
                  <Input
                    type="text"
                    name="trusted_contact_firstname"
                    id="trusted_contact_firstname"
                    value={values.trusted_contact_firstname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.trusted_contact_firstname &&
                        touched.trusted_contact_firstname
                        ? 'is-invalid input-area'
                        : 'input-area'
                    }
                  />
                  <CustomFormFeedback
                    formProps={formProps}
                    fieldName="trusted_contact_firstname"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="element-box dropdown-element pos-rel">
                  <Label className={values.trusted_contact_lastname ? "label new_label has_value" : "label new_label"} htmlFor="trusted_contact_lastname">
                    Last Name
                  </Label>
                  <Input
                    type="text"
                    name="trusted_contact_lastname"
                    id="trusted_contact_lastname"
                    value={values.trusted_contact_lastname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.trusted_contact_lastname &&
                        touched.trusted_contact_lastname
                        ? 'is-invalid input-area'
                        : 'input-area'
                    }
                  />
                  <CustomFormFeedback
                    formProps={formProps}
                    fieldName="trusted_contact_lastname"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="form_input_container_gray">
              <Col sm={12} lg={12}>
                <FormGroup className="element-box dropdown-element pos-rel pb-0">
                  <Label className="label" htmlFor="trusted_contact_label">
                    At least one is required
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="element-box pos-rel pl-4">
                  <Input
                    type="checkbox"
                    name="trusted_email"
                    id="trusted_email"
                    checked={values.trusted_select.includes('trusted_email')}
                    onChange={this.handleTrustedSelect}
                    className={
                      errors.trusted_email && touched.trusted_email
                        ? 'register-form__checkbox is-invalid'
                        : 'register-form__checkbox'
                    }
                  />
                  <Label className="label" htmlFor="trusted_email">
                    Email
                  </Label>
                  <CustomFormFeedback
                    formProps={formProps}
                    fieldName="trusted_email"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="element-box pos-rel pl-2">
                  <Input
                    type="checkbox"
                    name="trusted_phone"
                    id="trusted_phone"
                    checked={values.trusted_select.includes('trusted_phone')}
                    onChange={this.handleTrustedSelect}
                    className={
                      errors.trusted_phone && touched.trusted_phone
                        ? 'register-form__checkbox is-invalid'
                        : 'register-form__checkbox'
                    }
                  />
                  <Label className="label" htmlFor="trusted_phone">
                    Phone
                  </Label>
                  <CustomFormFeedback
                    formProps={formProps}
                    fieldName="trusted_phone"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="element-box pos-rel pl-2">
                  <Input
                    type="checkbox"
                    name="trusted_address"
                    id="trusted_address"
                    checked={values.trusted_select.includes(
                      'trusted_address'
                    )}
                    onChange={this.handleTrustedSelect}
                    className={
                      errors.trusted_address && touched.trusted_address
                        ? 'register-form__checkbox is-invalid'
                        : 'register-form__checkbox'
                    }
                  />
                  <Label className="label" htmlFor="trusted_address">
                    Address
                  </Label>
                  <CustomFormFeedback
                    formProps={formProps}
                    fieldName="trusted_address"
                  />
                </FormGroup>
              </Col>
            </Row>

            {values.trusted_select.includes('trusted_email') && (
              <Row className="form_input_container_gray">
                <Col>
                  <FormGroup className="element-box dropdown-element pos-rel">
                    <Label className={values.trusted_contact_email ? "label new_label has_value" : "label new_label"} htmlFor="trusted_contact_email">Email</Label>
                    <Input
                      type="text"
                      name="trusted_contact_email"
                      id="trusted_contact_email"
                      value={values.trusted_contact_email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.trusted_contact_email &&
                          touched.trusted_contact_email
                          ? 'is-invalid input-area'
                          : 'input-area'
                      }
                    />
                    <CustomFormFeedback
                      formProps={formProps}
                      fieldName="trusted_contact_email"
                    />
                  </FormGroup>
                </Col>
                <Col></Col>
              </Row>
            )}

            {values.trusted_select.includes('trusted_phone') && (
              <Row className="form_input_container_gray">
                <Col>
                  <FormGroup className="element-box dropdown-element pos-rel">
                    <Label className={values.trusted_contact_phone ? "label new_label has_value" : "label new_label"} htmlFor="trusted_contact_phone">Phone</Label>
                    <PhoneNumberInput
                      hideCode={true}
                      onChangePhoneCode={val =>
                        setFieldValue('trusted_contact_country_code', val)
                      }
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      values={values}
                      phoneCodeName="trusted_contact_country_code"
                      phoneName="trusted_contact_phone"
                      phoneId="trusted_contact_phone"
                    />
                    {errors.trusted_contact_phone &&
                      touched.trusted_contact_phone && (
                        <div className="form-invalid-field">
                          {errors['trusted_contact_phone']}
                        </div>
                      )}
                    <CustomFormFeedback formProps={formProps} fieldName="trusted_contact_phone" />
                  </FormGroup>
                </Col>
                <Col></Col>
              </Row>
            )}

            {values.trusted_select.includes('trusted_address') && (
              <>
                <Row className="form_input_container_gray">
                  {/* <Col lg={{ size: 4 }}>
                    <FormGroup className="element-box dropdown-element pos-rel country-wrapper">
                      <Label
                        className={values.trusted_contact_country || values.country_input ? "label new_label has_value" : "label new_label"}
                        htmlFor="trusted_contact_country"
                      >
                        Country*
                      </Label>
                      <Dropdown
                        className={
                          errors.trusted_contact_country &&
                            touched.trusted_contact_country
                            ? 'is-invalid dropdown-wrapper'
                            : 'dropdown-wrapper'
                        }
                        isOpen={this.state.countryState === true}
                        toggle={this.toggleCountryStatus}
                      >
                        <DropdownToggle
                          id="trusted_contact_country"
                          value={values.trusted_contact_country}
                          style={{ backgroundColor: 'transparent' }}
                          className={
                            errors.trusted_contact_country &&
                              touched.trusted_contact_country
                              ? 'is-invalid input-area'
                              : 'input-area'
                          }
                          caret
                        >
                          <input
                            style={{ padding: 0, height: `20px` }}
                            type="search"
                            // placeholder="Select a country"
                            name="country_input"
                            value={values.country_input}
                            onChange={e => this.onCountryChange(e)}
                            className="typeahead-field"
                            autoComplete={`country_input`}
                          />
                        </DropdownToggle>
                        <DropdownMenu>
                          {trustedContactCountry.map(item => {
                            if (
                              !values.country_input ||
                              toLower(item.name).includes(
                                toLower(values.country_input)
                              )
                            ) {
                              return (
                                <DropdownItem
                                  value={item['alpha-3']}
                                  onClick={e => this.onCountrySelect(item)}
                                >
                                  {item.name}
                                </DropdownItem>
                              )
                            }
                            return null
                          })}
                        </DropdownMenu>
                      </Dropdown>
                      {errors.trusted_contact_country &&
                        touched.trusted_contact_country && (
                          <div className="form-invalid-field">
                            {errors['trusted_contact_country']}
                          </div>
                        )}
                    </FormGroup>
                  </Col> */}

                  <Col lg={{ size: 6 }}>
                    <FormGroup className="element-box dropdown-element pos-rel">
                      <Label
                        className={values.trusted_contact_zipcode ? "label new_label has_value" : "label new_label"}
                        htmlFor="trusted_contact_zipcode"
                      >
                        Zip code
                      </Label>
                      <Input
                        type="text"
                        name="trusted_contact_zipcode"
                        id="trusted_contact_zipcode"
                        value={values.trusted_contact_zipcode}
                        onChange={(e) => {
                          this.handleZipCodeChangeTrusted(e.target.value)
                          handleChange(e)
                        }}
                        onBlur={handleBlur}
                        className={
                          errors.trusted_contact_zipcode &&
                            touched.trusted_contact_zipcode
                            ? 'is-invalid input-area'
                            : 'input-area'
                        }
                      />
                      {this.state.trustedZipNotValid ?
                        <div className="form-invalid-field">
                          {this.state.trustedZipNotValid}
                        </div>
                        : <CustomFormFeedback
                          formProps={formProps}
                          fieldName="trusted_contact_zipcode"
                        />}

                    </FormGroup>
                  </Col>

                  <Col lg={{ size: 6 }}>
                    <FormGroup className="element-box pos-rel">
                      <Label
                        className={values.trusted_contact_street ? "label new_label has_value" : "label new_label"}
                        htmlFor="trusted_contact_street"
                      >
                        Street Address
                      </Label>
                      <Input
                        type="text"
                        name="trusted_contact_street"
                        id="trusted_contact_street"
                        value={values.trusted_contact_street}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.trusted_contact_street &&
                            touched.trusted_contact_street
                            ? 'is-invalid input-area'
                            : 'input-area'
                        }
                      />
                      <CustomFormFeedback
                        formProps={formProps}
                        fieldName="trusted_contact_street"
                      />
                    </FormGroup>
                  </Col>

                  {/* <Col lg={{ size: 4 }}>
                    <FormGroup className="element-box pos-rel dropdown-element">
                      <Label
                        className={values.trusted_contact_state || values.stateInput ? "label new_label has_value" : "label new_label"}
                        htmlFor="trusted_contact_state"
                      >
                        State*
                      </Label>
                      {values.trusted_contact_country !== 'USA' ? (
                        <>
                          <Input
                            type="text"
                            name="trusted_contact_state"
                            id="trusted_contact_state"
                            value={values.trusted_contact_state}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.trusted_contact_state &&
                                touched.trusted_contact_state
                                ? 'is-invalid input-area'
                                : 'input-area'
                            }
                          />
                        </>
                      ) : (
                        <Dropdown
                          className={
                            errors.trusted_contact_state &&
                              touched.trusted_contact_state
                              ? 'is-invalid dropdown-wrapper'
                              : 'dropdown-wrapper'
                          }
                          isOpen={this.state.statesState}
                          toggle={this.toggleStateStatus}
                        >
                          <DropdownToggle
                            id="trusted_contact_state"
                            value={values.trusted_contact_state}
                            style={{ backgroundColor: 'transparent' }}
                            className={
                              errors.trusted_contact_state &&
                                touched.trusted_contact_state
                                ? 'is-invalid input-area'
                                : 'input-area'
                            }
                            caret
                          >
                            <input
                              style={{ padding: 0, height: `20px` }}
                              type="search"
                              // placeholder="Select"
                              name="stateInput"
                              value={values.stateInput}
                              onChange={e => this.onStateChange(e)}
                              className="typeahead-field"
                              autoComplete={`stateInput`}
                            />
                          </DropdownToggle>
                          <DropdownMenu>
                            {usStates.map(item => {
                              if (
                                !values.stateInput ||
                                toLower(item.name).includes(
                                  toLower(values.stateInput)
                                )
                              ) {
                                return (
                                  <DropdownItem
                                    value={item.name}
                                    onClick={e => this.onStateSelect(item)}
                                  >
                                    {item.name}
                                  </DropdownItem>
                                )
                              }
                              return null
                            })}
                          </DropdownMenu>
                        </Dropdown>
                      )}

                      {errors.trusted_contact_state &&
                        touched.trusted_contact_state && (
                          <div className="form-invalid-field">
                            {errors['trusted_contact_state']}
                          </div>
                        )}
                    </FormGroup>
                  </Col> */}
                </Row>
                <Row className="form_input_container_gray">
                  {/* <Col lg={{ size: 4 }}>
                    <FormGroup className="element-box pos-rel">
                      <Label
                        className={values.trusted_contact_city ? "label new_label has_value" : "label new_label"}
                        htmlFor="trusted_contact_city"
                      >
                        City*
                      </Label>
                      <Input
                        type="text"
                        name="trusted_contact_city"
                        id="trusted_contact_city"
                        value={values.trusted_contact_city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.trusted_contact_city &&
                            touched.trusted_contact_city
                            ? 'is-invalid input-area'
                            : 'input-area'
                        }
                      />
                      <CustomFormFeedback
                        formProps={formProps}
                        fieldName="trusted_contact_city"
                      />
                    </FormGroup>
                  </Col> */}
                </Row>
              </>
            )}
          </>
        )}
        {/* <Row className="form_input_container_gray border_radius_bottom" style={{ fontSize: 10 }}>
          &nbsp;
        </Row> */}
        {/* <Row>
            <Col>
              <FormGroup className="element-box dropdown-element pos-rel">
                <div className={'upload_div'}>
                  <p>407 Letter*</p>
                  <div className={'d-flex align-items-center label-container justify-content-between'}>
                    <p style={{fontWeight:400}}>{  values.upload_407.name || 'Upload Document'}</p>
                    <Label className={`label holoButton`} htmlFor="upload_407">Select File</Label>
                  </div>
                  <Input
                    type="file"
                    name="upload_407"
                    id="upload_407"
                    // value={values.upload_407}
                    accept="image/*"
                    onChange={(e) => this.fileSelectHandler(e, 'upload_407')}
                    // onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.upload_407 && touched.upload_407 ? 'is-invalid input-area' : 'input-area'}
                    placeholder="Upload document"
                  />
                </div>

                <CustomFormFeedback formProps={formProps} fieldName="upload_407" />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <button className="touched.upload_407" onClick={()=>this.handleUpload(values.upload_407)}>Upload File </button>
          </Row>
            */}

        {/*<Row>
            <Col>
              <FormGroup className="element-box dropdown-element pos-rel">
                <Label className="label" htmlFor="IdentityProof">Identity Proof</Label>
                  <div className="element-upload-outer">
                    <button className="document-upload" onClick={(e) => this.triggerInputFile(e, 'IdentityProof')}>Upload</button>

                        <Input
                          type="file"
                          name="identity_proof"
                          id="IdentityProof"
                          className="file-hidden"
                          onChange={(e) => this.fileSelectHandler(e, 'identity_proof')}
                       />
                    <div className={errors.identity_proof && touched.identity_proof ? 'is-invalid input-area upload-div' : 'input-area upload-div'}>{(values.identity_proof) ? values.identity_proof : 'Upload document'}</div>

                    <CustomFormFeedback formProps={formProps} fieldName="identity_proof" />
                    <span className="doc-toltip" id="id-tooltip"><i className="fal fa-info-circle"></i></span>
                  </div>
                  <UncontrolledTooltip className="upl-tool-info" placement="right" target="id-tooltip">
                    Birth Certificate <br/> Social Security Card <br/>State-issued Driver's License/ID Card<br/> Real ID Act<br/> Passport and Passport Card<br/>Department of Defense
                  </UncontrolledTooltip>

              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="element-box dropdown-element pos-rel">
                  <Label className="label" htmlFor="addressProof">Address Proof</Label>
                    <div className="element-upload-outer">
                      <button className="document-upload" onClick={(e) => this.triggerInputFile(e, 'addressProof')}>Upload</button>
                        <Input
                          type="file"
                          name="address_proof"
                          id="addressProof"
                          className="file-hidden"
                          onChange={(e) => this.fileSelectHandler(e, 'address_proof')}
                       />
                      <div className={errors.address_proof && touched.address_proof ? 'is-invalid input-area upload-div' : 'input-area upload-div'}>{(values.address_proof) ? values.address_proof : 'Upload document'}</div>

                      <CustomFormFeedback formProps={formProps} fieldName="address_proof" />
                      <span className="doc-toltip" id="address-tooltip"><i className="fal fa-info-circle"></i></span>
                    </div>
                    <UncontrolledTooltip className="upl-tool-info" placement="right" target="address-tooltip">
                      Valid Driver's License <br/> Property Tax Receipt <br/>Posted Mail with name of Applicant<br/> Utility Bill<br/> Lease Agreement<br/>Insurance Card<br />Voter Registration Card<br />College Enrollment Papers
                    </UncontrolledTooltip>

              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup className="element-box dropdown-element pos-rel">
                <Label className="label" htmlFor="citizenshipProof">Citizenship Proof</Label>
                  <div className="element-upload-outer">
                    <button className="document-upload">Upload</button>

                      <button className="document-upload" onClick={(e) => this.triggerInputFile(e, 'citizenshipProof')}>Upload</button>
                        <Input
                          type="file"
                          name="citizenship_proof"
                          id="citizenshipProof"
                          className="file-hidden"
                          onChange={(e) => this.fileSelectHandler(e, 'citizenship_proof')}
                       />
                      <div className={errors.citizenship_proof && touched.citizenship_proof ? 'is-invalid input-area upload-div' : 'input-area upload-div'}>{(values.citizenship_proof) ? values.citizenship_proof : 'Upload document'}</div>


                    <CustomFormFeedback formProps={formProps} fieldName="citizenship_proof" />
                      <span className="doc-toltip" id="citizenship-tooltip"><i className="fal fa-info-circle"></i></span>
                  </div>
                  <UncontrolledTooltip className="upl-tool-info" placement="right" target="citizenship-tooltip">
                    Birth Certificate <br/> Social Security Card <br/>State-issued Driver's License/ID Card<br/> Real ID Act<br/> Passport and Passport Card
                  </UncontrolledTooltip>

              </FormGroup>
            </Col>

            <Col>

            </Col>
          </Row>*/}
        <div className="text-center w-100 form_input_container_gray border_radius_bottom pb-2">
          <span className="help_note" style={{ fontSize: 13, display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}><span style={{ position: 'initial', marginTop: -2, fontSize: '0.7rem' }}>&nbsp;&dagger;&dagger;&nbsp;</span> This information is required by SEC regulators.</span>
        </div>

        {!merger ? <FormGroup className="cta-wrapper" style={{ display: 'inline-block', width: '100%' }}>

          {!this.props.isSubmitStep ? <PrimaryButton
            type="button"
            onClick={() => this.props.saveData(this.props.formProps.values)}
            className="btn secondary-btn"
            style={{ width: '48%', float: 'left' }}
          >
            Save Progress
          </PrimaryButton> : null}

          <PrimaryButton
            type="submit"
            className="btn primary-btn"
            style={this.props.isSubmitStep ? { width: '100%' } : { width: '48%', float: 'right' }}
          >
            {this.props.isSubmitStep ? 'Create Account' : 'Save & Continue'}
          </PrimaryButton>
          {this.props.isSubmitStep ? <div className="start_finding">
            <a href="javascript:void(0)" onClick={() => this.props.finishLaterCall()}>Start Finding Investments...I'll Finish This Later</a>
          </div> : <div className="start_finding" style={{ marginTop: 60 }}><a style={{ color: '#56a9e8' }} onClick={() => {
            this.props.finishLaterCall()
          }} href="javascript:void(0)">I'll Finish This Later</a></div>}
        </FormGroup> : null}
        {/* {!merger ? <div className="acc-access-option-wrapper text-center">
              Create Magnifi Investment Account later?
              <span className="hyper-link" onClick={this.props.toggleSkipModal}>
                {' '}
                Skip
              </span>
            </div>: null} */}
        {/* {!merger ? <div className="or-partition text-center mb-3">
          * Mandatory
        </div> : null} */}
      </>
    )
  }
}

class TradingFormRHS2 extends Component {
  state = {
    tooltipOpen: false,
    // are_you_employed      : this.props.formProps.values.are_you_employed,
    // employer_name         : this.props.formProps.values.employer_name,
    // occupation            : this.props.formProps.values.occupation,
    timeHorizon: '',
    liquidityNeeds: '',
    are_you_family_member: false,
    stock_name: '',
    fields: [-1],
    loader: false,
    upload_type: '',
    firm_name: '',
    parentNode: null,
    isSigned: false,
    uploadError: { upload_3210: null, upload_407: null },
    politicalTooltip: false
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].style.paddingTop = 0
    let { profile } = this.props
    this.props.formProps.setFieldValue('self_role', profile.self_role)
    this.props.formProps.setTouched({})
    document.addEventListener('pointerdown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('pointerdown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    console.log(this.state.parentNode)
    if (this.state.parentNode && this.state.parentNode.contains(e.target)) {
      this.setState({ isSigned: true });
    }
  }

  clear = () => {
    this.setState({ isSigned: false });
    if (this._sketch) {
      this._sketch.clear()
      this._sketch.setBackgroundFromDataUrl('')
      this.props.formProps.setFieldValue('signature_snap_id', '')
    }
  }

  saveSignature = async () => {
    var dataURI = this._sketch.toDataURL()
    var byteString = atob(dataURI.split(',')[1])
    var ab = new ArrayBuffer(byteString.length)
    var ia = new Uint8Array(ab)

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    var image = new Blob([ab], { type: 'image/jpeg' })

    let formData = new FormData()
    formData.append('file', image)
    formData.append('tag', 'ID_DOCUMENT')
    // formData.append('account', this.props.profile.apex.account[0].id)

    try {
      this.setState({
        loading: true
      })

      let uploadRes = await uploadReviewImage(formData)

      this.setState({
        loading: false
      })

      this.props.formProps.setFieldValue(
        'signature_snap_id',
        uploadRes.record_id
      )

      console.log(uploadRes)
    } catch (e) {
      this.setState({
        loading: false
      })
    }
  }

  // toggleOccupationStatus = () => {
  //   this.setState({
  //     occupation: !this.state.occupation,
  //   })
  // }
  toggleFinancialGoals = () => {
    this.setState({
      timeHorizon: !this.state.timeHorizon
    })
  }

  toggleLiquidityTime = () => {
    this.setState({
      liquidityNeeds: !this.state.liquidityNeeds
    })
  }

  // toggleRelationStatus = () => {
  //   this.setState({
  //     relation: !this.state.relation,
  //   })
  // }
  fileSelectHandler = async (e, elem) => {
    this.props.formProps.setFieldValue(elem + '_name', '');
    try {
      let errorObj = { upload_3210: null, upload_407: null };
      if (e.target.files && e.target.files[0]) {
        const files = e.target.files;
        let formData = new FormData()
        const { user } = this.props.profile
        let fileSize = files[0].size / 1024 / 1024;
        if (fileSize > 15) {
          errorObj[elem] = `The file ${files[0].name} is too big. (files must be under 15MB in size).`;
          return this.setState({ uploadError: errorObj }, () => {
            this.props.formProps.setFieldValue(elem, '');
          });
        } else if (!SUPPORTED_FORMATS.includes(files[0].type)) {
          errorObj[elem] = "Only .png,.jpg,.jpeg,.gif, image format are allowed";
          return this.setState({ uploadError: errorObj }, () => {
            this.props.formProps.setFieldValue(elem, '');
          });
        } else {
          errorObj[elem] = null;
          this.setState({ uploadError: errorObj });
        }
        this.setState({ loader: true });
        formData.append('file', files[0])
        formData.append('tag', 'AFFILIATED_APPROVAL')
        formData.append('user', user || '')
        let res = await uploadReviewImage(formData)
        this.props.formProps.setFieldValue(elem, res.record_id)
        this.props.formProps.setFieldValue(elem + '_name', files[0].name)
      }
      this.setState({ loader: false });
    } catch (err) {
      this.setState({ loader: false });
      this.props.formProps.setFieldValue(elem, '');
    }
  }

  // handleRelationChange = (e,index) =>{
  //   let arr = this.props.formProps.values.relation
  //   let val = e.target.value
  //   arr[index] = val
  //   this.props.formProps.setFieldValue("relation", arr)
  // }
  handlePolOrgChange = (e, index) => {
    let arr = this.props.formProps.values.political_organization
    let val = e.target.value
    arr[index][e.target.name] = val
    this.props.formProps.setFieldValue('political_organization', arr)
  }
  handleAddFields = e => {
    e.preventDefault()
    let arr = this.props.formProps.values.political_organization
    arr.push({ political_organization: '', relation: '' })
    this.props.formProps.setFieldValue('political_organization', arr)
  }

  handleDelFields = index => {
    let arrPol = this.props.formProps.values.political_organization
    arrPol.splice(index, 1)
    this.props.formProps.setFieldValue('political_organization', arrPol)
  }
  toggleSelectUpload = () => {
    this.setState({
      upload_type: !this.state.upload_type
    }, () => {
      if (this.state.upload_type) {
        setTimeout(() => {
          document.getElementById('upload_type_child0').focus()
        }, 100)
      }
    })
  }

  handleRedirect = type => {
    window.open(apex_disclosures, '_blank')
  }

  render() {
    const { formProps, from, profile, step, merger, hideStepBar } = this.props
    const { fields } = this.state
    const {
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue
    } = formProps

    let totalSteps = values.account_type === 'Joint' ? 3 : 2

    totalSteps = this.props.isBranded ? ++totalSteps : ++totalSteps

    //console.log("2 state",this.state)
    console.log('2 props', this.props)
    console.log(errors.timeHorizon, touched.timeHorizon)
    return (
      <>
        <Spinner isFetching={this.state.loader || this.state.loading} />
        {/* <div>
          {!hideStepBar ? <StepsBar step={step} account_type={values.account_type} totalSteps={totalSteps} /> : null}
        </div> */}

        {/* {!hideStepBar ? <div className="header-area">
          <div className="header-label sub">
            Specify Your Investment Objectives
          </div>
        </div> : null} */}
        <div className="w-100 mt-3">
          <span className='heading_in_between'>Disclosures <span style={{ fontSize: 12, marginTop: 5 }}> &nbsp;(not common)</span></span>
        </div>
        {/* <FormGroup className="element-box pos-rel pl-0 pb-3" check style={{paddingBottom:'1.75rem'}}>
            <div className="toggle-switch-wrapper">
              <div className="toggle-content label mr-2">Are you employed? </div>
              <Label check className="register-form__term-cond-label toggle-switch">
                <Input
                  type="checkbox"
                  name="are_you_employed"
                  id="are_you_employed"
                  className={
                    errors.are_you_employed && touched.are_you_employed
                    ? 'register-form__checkbox is-invalid input-area toggle-switch-input'
                    : 'register-form__checkbox input-area toggle-switch-input'
                  }
                  checked={values.are_you_employed}
                  onChange={handleChange}
                  />
                <span className="slider round"></span>
              </Label>

            </div>
            <CustomFormFeedback
              formProps={formProps}
              fieldName="are_you_employed"
              />
            {errors.are_you_employed && touched.are_you_employed && <div className="form-invalid-field">{errors['are_you_employed']}</div>}
          </FormGroup> */}

        {/* {(values.are_you_employed) && <Row>
            <Col>
              <FormGroup className="element-box dropdown-element pos-rel">
                <Label className="label" htmlFor="employer_name">Name of the Employer*</Label>
                <Input
                  type="text"
                  name="employer_name"
                  id="employer_name"
                  value={values.employer_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.employer_name && touched.employer_name ? 'is-invalid input-area' : 'input-area'}
                  placeholder="Enter employer name"
                  />
                <CustomFormFeedback formProps={formProps} fieldName="employer_name" />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="element-box dropdown-element pos-rel">
                <Label className="label" htmlFor="occupation">Occupation*</Label>
                  <Dropdown
                    className={errors.occupation && touched.occupation ? 'is-invalid dropdown-wrapper' : 'dropdown-wrapper'}
                    isOpen={this.state.occupation}
                    toggle={this.toggleOccupationStatus}
                    onChange={this.handleOccupationChange}
                    >
                    <DropdownToggle
                      value={values.occupation}
                      style={{ backgroundColor: '#fff' }}
                      className={errors.occupation && touched.occupation ? 'is-invalid input-area' : 'input-area'}
                      caret
                      >
                      {values.occupation || 'Select'}
                    </DropdownToggle>
                    <DropdownMenu center>
                      {
                        occupation.map((item) => {
                          return <DropdownItem value={item} onClick={(e) => { setFieldValue('occupation', e.target.value)}}>{item}</DropdownItem>
                        })
                      }

                    </DropdownMenu>
                  </Dropdown>

                  {errors.occupation && touched.occupation && <div className="form-invalid-field">{errors['occupation']}</div>}
              </FormGroup>
            </Col>
          </Row>} */}

        {/* <FormGroup
          className="element-box pos-rel pl-0 pb-3"
          check
          style={{ paddingBottom: '1.75rem' }}
        >
          <div className="toggle-switch-wrapper">
            <div className="toggle-content label">
              Number of Years planned to invest to achieve particular
              financial goal{this.props.isBranded ? '*' : ''}
              <Dropdown
                className={
                  errors.timeHorizon && touched.timeHorizon
                    ? 'is-invalid dropdown-wrapper'
                    : 'dropdown-wrapper'
                }
                isOpen={this.state.timeHorizon}
                toggle={this.toggleFinancialGoals}
                onChange={this.handleFinancialGoalChange}
              >
                <DropdownToggle
                  value={values.timeHorizon}
                  style={{ backgroundColor: '#fff' }}
                  className={
                    errors.timeHorizon && touched.timeHorizon
                      ? 'is-invalid input-area'
                      : 'input-area'
                  }
                  caret
                >
                  {values.timeHorizon || 'Select'}
                </DropdownToggle>
                <DropdownMenu center>
                  {financialGoals.map((item, i) => {
                    return (
                      <DropdownItem
                        key={`financialGoals${i}`}
                        value={item.value}
                        onClick={e => {
                          setFieldValue('timeHorizon', e.target.value)
                        }}
                      >
                        {item.name}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          {errors.timeHorizon && touched.timeHorizon && (
            <div className="form-invalid-field">{errors.timeHorizon}</div>
          )}
        </FormGroup> */}

        {/* <FormGroup
          className="element-box pos-rel pl-0 pb-3"
          check
          style={{ paddingBottom: '1.75rem' }}
        >
          <div className="toggle-switch-wrapper">
            <div className="toggle-content label">
              Ability to quickly and easily convert all or a portion of the
              account assets into cash without experiencing significant loss
              {this.props.isBranded ? '*' : ''}
              <Dropdown
                className={
                  errors.liquidityNeeds && touched.liquidityNeeds
                    ? 'is-invalid dropdown-wrapper'
                    : 'dropdown-wrapper'
                }
                isOpen={this.state.liquidityNeeds}
                toggle={this.toggleLiquidityTime}
                onChange={this.handleLiquidityTimeChange}
              >
                <DropdownToggle
                  value={values.liquidityNeeds}
                  style={{ backgroundColor: '#fff' }}
                  className={
                    errors.liquidityNeeds && touched.liquidityNeeds
                      ? 'is-invalid input-area'
                      : 'input-area'
                  }
                  caret
                >
                  {values.liquidityNeeds || 'Select'}
                </DropdownToggle>
                <DropdownMenu center>
                  {liquidityTime.map((item, i) => {
                    return (
                      <DropdownItem
                        key={`financialGoals${i}`}
                        value={item}
                        onClick={e => {
                          setFieldValue('liquidityNeeds', e.target.value)
                        }}
                      >
                        {item}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          {errors.liquidityNeeds && touched.liquidityNeeds && (
            <div className="form-invalid-field">
              {errors['liquidityNeeds']}
            </div>
          )}
        </FormGroup> */}
        <Row className="form_input_container_gray border_radius_top">
          <Col xs={10}>
            <FormGroup
              className="element-box pos-rel pl-0 pb-0"
              check
              style={{ paddingBottom: '1.75rem' }}
            >
              <div className="toggle-switch-wrapper">
                <div className="toggle-content label">
                  Are you or a family member a senior executive or 10% shareholder
                  at a publicly traded company?
                </div>
              </div>
              <CustomFormFeedback
                formProps={formProps}
                fieldName="are_you_family_member"
              />
              {errors.are_you_family_member && touched.are_you_family_member && (
                <div className="form-invalid-field">
                  {errors['are_you_family_member']}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={2}>
            <FormGroup
              className="element-box pos-rel pl-0 pb-0"
              check
              style={{ paddingBottom: '1.75rem' }}
            >
              <div className="toggle-switch-wrapper" style={{ width: '100%' }}>
                <div className="toggle-content label text-right" style={{ width: '100%' }}>
                  <Label
                    check
                    className="register-form__term-cond-label toggle-switch m-0 ml-2 mt-1"
                  >
                    <Input
                      type="checkbox"
                      name="are_you_family_member"
                      id="are_you_family_member"
                      className={
                        errors.are_you_family_member &&
                          touched.are_you_family_member
                          ? 'register-form__checkbox is-invalid input-area toggle-switch-input'
                          : 'register-form__checkbox input-area toggle-switch-input'
                      }
                      checked={values.are_you_family_member}
                      onChange={handleChange}
                    />
                    <span className="slider round"></span>
                  </Label>
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>

        {values.are_you_family_member && (
          <Row className="form_input_container_gray">
            <Col>
              <FormGroup className="element-box dropdown-element pos-rel">
                <Label className={values.stock_name ? "label new_label has_value" : "label new_label"} htmlFor="stock_name">
                  Company Ticker
                </Label>
                <Input
                  type="text"
                  name="stock_name"
                  id="stock_name"
                  value={values.stock_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.stock_name && touched.stock_name
                      ? 'is-invalid input-area'
                      : 'input-area'
                  }
                // placeholder="Enter company ticker"
                />
                <CustomFormFeedback
                  formProps={formProps}
                  fieldName="stock_name"
                />
              </FormGroup>
            </Col>
            <Col></Col>
          </Row>
        )}
        <Row className="form_input_container_gray">
          <Col xs={10}>
            <FormGroup
              className="element-box pos-rel pl-0 pb-0 pt-1"
              check
              style={{ paddingBottom: '1.75rem' }}
            >
              <div className="toggle-switch-wrapper">
                <div className="toggle-content label">
                  Are you affiliated or work for a member firm of a Stock Exchange or FINRA?
                </div>
              </div>
              <CustomFormFeedback
                formProps={formProps}
                fieldName="are_you_authorize"
              />
              {errors.are_you_authorize && touched.are_you_authorize && (
                <div className="form-invalid-field">
                  {errors['are_you_authorize']}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={2}>
            <FormGroup
              className="element-box pos-rel pl-0 pb-0 pt-1"
              check
              style={{ paddingBottom: '1.75rem' }}
            >
              <div className="toggle-switch-wrapper" style={{ width: '100%' }}>
                <div className="toggle-content label text-right" style={{ width: '100%' }}>
                  <Label
                    check
                    className="register-form__term-cond-label toggle-switch m-0 ml-2 mt-1"
                  >
                    <Input
                      type="checkbox"
                      onKeyDown={(e) => {
                        if (e.key === 'Tab') {
                          if (values.are_you_authorize) {
                            e.preventDefault();
                            document.getElementById('upload_type').click();
                            document.getElementById('upload_type').focus();
                          }
                        }
                      }}
                      name="are_you_authorize"
                      id="are_you_authorize"
                      className={
                        errors.are_you_authorize && touched.are_you_authorize
                          ? 'register-form__checkbox is-invalid input-area toggle-switch-input'
                          : 'register-form__checkbox input-area toggle-switch-input'
                      }
                      checked={values.are_you_authorize}
                      onChange={handleChange}
                    />
                    <span className="slider round"></span>
                  </Label>
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>

        {values.are_you_authorize && (
          <Row className="form_input_container_gray">
            <Col sm={6}>
              <FormGroup className="element-box dropdown-element pos-rel">
                <Label className={values.upload_type ? "label new_label has_value" : "label new_label"} htmlFor="upload_type">
                  Select Upload Type
                </Label>
                <Dropdown
                  className={
                    errors.upload_type && touched.upload_type
                      ? 'is-invalid dropdown-wrapper'
                      : 'dropdown-wrapper'
                  }
                  isOpen={this.state.upload_type}
                  toggle={this.toggleSelectUpload}
                >
                  <DropdownToggle
                    id="upload_type"
                    value={values.upload_type}
                    style={{ backgroundColor: 'transparent' }}
                    className={
                      errors.upload_type && touched.upload_type
                        ? 'is-invalid input-area'
                        : 'input-area'
                    }
                    caret
                  >
                    {values.upload_type || ''}
                  </DropdownToggle>
                  <DropdownMenu center>
                    {uploadType.map((item, i) => {
                      return (
                        <DropdownItem
                          id={'upload_type_child' + i}
                          value={item}
                          onClick={e => {
                            setFieldValue('upload_type', e.target.value)
                          }}
                        >
                          {item}
                        </DropdownItem>
                      )
                    })}
                  </DropdownMenu>
                </Dropdown>

                {errors.upload_type && touched.upload_type && (
                  <div className="form-invalid-field">
                    {errors['upload_type']}
                  </div>
                )}
              </FormGroup>
            </Col>
            {values.upload_type == 'Upload 3210' && (
              <Col sm={6}>
                <FormGroup className="element-box dropdown-element pos-rel">
                  <div className={'upload_div upload_div_new'}>
                    {/* <p>3210 Letter*</p> */}
                    <div
                      className={
                        'd-flex align-items-center  label-container justify-content-between'
                      }
                    >
                      <p style={{ fontWeight: 400 }}>
                        {values.upload_3210_name || '3210 Letter*'}
                      </p>
                      <Label
                        className={`label holoButton`}
                        htmlFor="upload_3210"
                      >
                        Upload
                      </Label>
                    </div>
                    <Input
                      type="file"
                      name="upload_3210"
                      id="upload_3210"
                      // value={values.upload_3210}
                      accept="image/*"
                      onChange={e => this.fileSelectHandler(e, 'upload_3210')}
                      // onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.upload_3210 && touched.upload_3210
                          ? 'is-invalid input-area'
                          : 'input-area'
                      }
                      placeholder="3210 Letter*"
                    />
                  </div>
                  <CustomFormFeedback
                    formProps={formProps}
                    fieldName="upload_3210"
                  />
                  {(errors.upload_3210 && touched.upload_3210) || this.state.uploadError.upload_3210 ?
                    (<div className="form-invalid-field">
                      {this.state.uploadError.upload_3210 ? this.state.uploadError.upload_3210 : errors['upload_3210']}
                    </div>)
                    : null}
                </FormGroup>
              </Col>
            )}
            {values.upload_type == 'Upload 407' && (
              <Col sm={6}>
                <FormGroup className="element-box dropdown-element pos-rel">
                  <div className={'upload_div upload_div_new'}>
                    {/* <p>407 Letter*</p> */}
                    <div
                      className={
                        'd-flex align-items-center label-container justify-content-between'
                      }
                    >
                      <p style={{ fontWeight: 400 }}>
                        {values.upload_407_name || '407 Letter*'}
                      </p>
                      <Label
                        className={`label holoButton`}
                        htmlFor="upload_407"
                      >
                        Upload
                      </Label>
                    </div>
                    <Input
                      type="file"
                      name="upload_407"
                      id="upload_407"
                      // value={values.upload_407.name}
                      accept="image/*"
                      onChange={e => this.fileSelectHandler(e, 'upload_407')}
                      // onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.upload_407 && touched.upload_407
                          ? 'is-invalid input-area'
                          : 'input-area'
                      }
                      placeholder="407 Letter*"
                    />
                  </div>

                  <CustomFormFeedback
                    formProps={formProps}
                    fieldName="upload_407"
                  />
                  {(errors.upload_407 && touched.upload_407) || this.state.uploadError.upload_407 ?
                    (<div className="form-invalid-field">
                      {this.state.uploadError.upload_407 ? this.state.uploadError.upload_407 : errors['upload_407']}
                    </div>)
                    : null}
                </FormGroup>
              </Col>
            )}
          </Row>
        )}

        {values.are_you_authorize && (
          <Row className="form_input_container_gray">
            <Col sm={6}>
              <FormGroup className="element-box dropdown-element pos-rel">
                <Label className={values.firm_name ? "label new_label has_value" : "label new_label"} htmlFor="firmName">
                  Firm Name
                </Label>
                <Input
                  type="text"
                  name="firm_name"
                  id="firmName"
                  value={values.firm_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.firm_name && touched.firm_name
                      ? 'is-invalid input-area'
                      : 'input-area'
                  }
                // placeholder="Enter firm name"
                />
                <CustomFormFeedback
                  formProps={formProps}
                  fieldName="firm_name"
                />
              </FormGroup>
            </Col>
          </Row>
        )}

        {/* { profile.self_role !== "RIA" &&
          <FormGroup className="element-box pos-rel pl-0 pb-3" check style={{paddingBottom:'1.75rem'}}>
            <div className="toggle-switch-wrapper">
              <div className="toggle-content label">Do you or your family work for another brokerage?*
              <Label check className="register-form__term-cond-label toggle-switch m-0 ml-2">
                <Input
                  type="checkbox"
                  name="work_for_another_brokerage"
                  id="work_for_another_brokerage"
                  className={
                    errors.work_for_another_brokerage && touched.work_for_another_brokerage
                    ? 'register-form__checkbox is-invalid input-area toggle-switch-input'
                    : 'register-form__checkbox input-area toggle-switch-input'
                  }
                  checked={values.work_for_another_brokerage}
                  onChange={handleChange}
                  />
                <span className="slider round"></span>
              </Label>
              </div>
            </div>
            <CustomFormFeedback
              formProps={formProps}
              fieldName="work_for_another_brokerage"
              />
            {errors.work_for_another_brokerage && touched.work_for_another_brokerage && <div className="form-invalid-field">{errors['work_for_another_brokerage']}</div>}
          </FormGroup>} */}

        {/*profile && profile.self_role === "RIA" &&
            <>
            <FormGroup className="element-box pos-rel pl-0 pb-3" check style={{paddingBottom:'1.75rem'}}>
            <div className="toggle-switch-wrapper">
              <div className="toggle-content label">Since you are a RIA. Please upload Reg/BI form*</div>
            </div>
          </FormGroup>
          <Row>
          <Col sm={6}>
              <FormGroup className="element-box dropdown-element pos-rel">
                <div className={'upload_div'}>

                <div className={'d-flex align-items-center label-container justify-content-between'}>
                    <p style={{fontWeight:400}}>{  values.reg_bi_form.name || 'Upload Document'}</p>
                    <Label className={`label holoButton`} htmlFor="reg_bi_form">Upload</Label>
                  </div>
                  <Input
                    type="file"
                    name="reg_bi_form"
                    id="reg_bi_form"
                    // value={values.upload_407.name}
                    accept="image/*"
                    onChange={(e) => this.fileSelectHandler(e, 'reg_bi_form')}
                    // onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.reg_bi_form && touched.reg_bi_form ? 'is-invalid input-area' : 'input-area'}
                    placeholder="Upload document"
                  />
                </div>

                <CustomFormFeedback formProps={formProps} fieldName="reg_bi_form" />
                {errors.reg_bi_form && touched.reg_bi_form && <div className="form-invalid-field">{errors['reg_bi_form']}</div>}
              </FormGroup>
            </Col>
          </Row>
        </>*/}
        <Row className={`form_input_container_gray ${values.are_you_exposed ? '' : 'border_radius_bottom'}`}>
          <Col xs={10}>
            <FormGroup
              className="element-box pos-rel pl-0 pb-2 pt-1"
              check
              style={{ paddingBottom: '1.75rem' }}
            >
              <div className="toggle-switch-wrapper">
                <div className="toggle-content label">
                  Are you or a family member politically exposed? <span className="politicaly_exposed_span" id="politicaly_exposed_tooltip">&#9432;</span>
                  <Tooltip style={{ textAlign: 'left', fontSize: 10, width: 210, maxWidth: 220 }} placement="right-start" isOpen={this.state.politicalTooltip} target="politicaly_exposed_tooltip" toggle={() => this.setState({ politicalTooltip: !this.state.politicalTooltip })}>
                    {POLITICAL_TOOLTIP_CONTENT.map(co => {
                      return (
                        <p>{co}</p>
                      )
                    })}
                  </Tooltip>
                </div>
              </div>
              <CustomFormFeedback
                formProps={formProps}
                fieldName="are_you_exposed"
              />
              {errors.are_you_exposed && touched.are_you_exposed && (
                <div className="form-invalid-field">
                  {errors['are_you_exposed']}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={2}>
            <FormGroup
              className="element-box pos-rel pl-0 pb-2 pt-1"
              check
              style={{ paddingBottom: '1.75rem' }}
            >
              <div className="toggle-switch-wrapper" style={{ width: '100%' }}>
                <div className="toggle-content label text-right" style={{ width: '100%' }}>
                  <Label
                    check
                    className="register-form__term-cond-label toggle-switch m-0 ml-2"
                  >
                    <Input
                      type="checkbox"
                      name="are_you_exposed"
                      id="are_you_exposed"
                      className={
                        errors.are_you_exposed && touched.are_you_exposed
                          ? 'register-form__checkbox is-invalid input-area toggle-switch-input'
                          : 'register-form__checkbox input-area toggle-switch-input'
                      }
                      checked={values.are_you_exposed}
                      onChange={handleChange}
                    />
                    <span className="slider round"></span>
                  </Label>
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>

        {values.are_you_exposed &&
          values.political_organization.map((obj, i) => (
            <Row key={`relation${i}`} className="form_input_container_gray">
              <Col>
                <FormGroup className="element-box dropdown-element pos-rel">
                  <Label className={obj.relation ? "label new_label has_value" : "label new_label"} htmlFor={`relation${i}`}>
                    Name
                  </Label>
                  <Input
                    type="text"
                    name={`relation`}
                    id={`relation${i}`}
                    value={obj.relation}
                    // onChange={handleChange}
                    onChange={e => this.handlePolOrgChange(e, i)}
                    onBlur={handleBlur}
                    className={
                      errors.political_organization &&
                        touched.political_organization
                        ? 'is-invalid input-area'
                        : 'input-area'
                    }
                  // placeholder="Name"
                  />
                  {/* <CustomFormFeedback formProps={formProps} fieldName="political_organization" /> */}
                  {errors.political_organization &&
                    touched.political_organization &&
                    errors.political_organization[i] && (
                      <div className="form-invalid-field">
                        {errors.political_organization[i].relation}
                      </div>
                    )}
                </FormGroup>
              </Col>

              <Col>
                <FormGroup className="element-box dropdown-element pos-rel">
                  <Label className={obj.political_organization ? "label new_label has_value" : "label new_label"} htmlFor={`political_organization${i}`}>
                    Political Organization
                  </Label>
                  <Input
                    type="text"
                    name="political_organization"
                    id={`political_organization${i}`}
                    value={obj.political_organization}
                    // onChange={handleChange}
                    onChange={e => this.handlePolOrgChange(e, i)}
                    onBlur={handleBlur}
                    className={
                      errors.political_organization &&
                        touched.political_organization
                        ? 'is-invalid input-area'
                        : 'input-area'
                    }
                  // placeholder="Organization name"
                  />
                  {/* <CustomFormFeedback formProps={formProps} fieldName="political_organization" /> */}
                  {errors.political_organization &&
                    touched.political_organization &&
                    errors.political_organization[i] && (
                      <div className="form-invalid-field">
                        {
                          errors.political_organization[i]
                            .political_organization
                        }
                      </div>
                    )}
                </FormGroup>
                {i > 0 && (
                  <img
                    className="delbtn"
                    src={del}
                    width={20}
                    onClick={() => this.handleDelFields(i)}
                  />
                )}
              </Col>
            </Row>
          ))}
        {values.are_you_exposed && (
          <Row className="form_input_container_gray border_radius_bottom">
            <Col>
              <button
                className="btnfield"
                onClick={e => this.handleAddFields(e)}
              >
                {' '}
                add family member
              </button>
            </Col>
            <Col>
              {/* {fields.length >1 && <button className="btnfield" onClick={()=>this.handleRemoveFields()}>remove family member</button>} */}
            </Col>
          </Row>
        )}

        {values.account_type !== 'Joint' ?
          <div>
            <div className="w-100 mt-3">
              <span className='heading_in_between'>Terms and Agreement</span>
            </div>
            <div className="row form_input_container_gray border_radius_top border_radius_bottom pt-3">
              <div className="col">
                <Row className="mt-2">
                  <Col xs={6} md={6} lg={6} xl={4} className="pl-0 pr-1">
                    <FormGroup
                      className="element-box pos-rel pl-0 pb-3"
                      check
                      style={{ paddingBottom: '1.75rem' }}
                    >
                      <Label
                        check
                        className="register-form__term-cond-label terms-and-agreement-label checkmark_label"
                      >
                        <Input
                          type="checkbox"
                          name="investment_advisory_agreement"
                          id="investment_advisory_agreement"
                          className={
                            errors.investment_advisory_agreement &&
                              touched.investment_advisory_agreement
                              ? 'register-form__checkbox is-invalid input-area'
                              : 'register-form__checkbox input-area'
                          }
                          checked={values.investment_advisory_agreement}
                          onChange={handleChange}
                        />
                        <span class="checkmark"></span>
                      </Label>
                      <a
                        className="toggle-content label terms"
                        style={{ color: '#56a9e7' }}
                        href={TEARM_LINKS.investment_advisory}
                        target="_blank"
                      >
                        Magnifi Investment Advisory Agreement
                      </a>
                      {errors.investment_advisory_agreement &&
                        touched.investment_advisory_agreement && (
                          <div className="form-invalid-field">
                            {errors['investment_advisory_agreement']}
                          </div>
                        )}
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={6} lg={6} xl={4} className="pl-1 pr-1">
                    <FormGroup
                      className="element-box pos-rel pl-0 pb-3"
                      check
                      style={{ paddingBottom: '1.75rem' }}
                    >
                      <Label
                        check
                        className="register-form__term-cond-label terms-and-agreement-label checkmark_label"
                      >
                        <Input
                          type="checkbox"
                          name="enroll_in_sweep_program"
                          id="enroll_in_sweep_program"
                          className={
                            errors.enroll_in_sweep_program &&
                              touched.enroll_in_sweep_program
                              ? 'register-form__checkbox is-invalid input-area'
                              : 'register-form__checkbox input-area'
                          }
                          checked={values.enroll_in_sweep_program}
                          onChange={handleChange}
                        />
                        <span class="checkmark"></span>
                      </Label>
                      <a
                        className="toggle-content label terms"
                        style={{ color: '#56a9e7' }}
                        href="/assets/disclosures/sweep_disclosures.pdf"
                        target="_blank"
                      >
                        Enroll in the Sweep Program <span style={{ color: '#b3b3b3', fontSize: 12 }}>(optional)</span>
                      </a>
                      <CustomFormFeedback
                        formProps={formProps}
                        fieldName="enroll_in_sweep_program"
                      />
                      {errors.enroll_in_sweep_program &&
                        touched.enroll_in_sweep_program && (
                          <div className="form-invalid-field">
                            {errors['enroll_in_sweep_program']}
                          </div>
                        )}
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={6} lg={6} xl={4} className="pl-1 pr-0">
                    <FormGroup
                      className="element-box pos-rel pl-0 pb-3"
                      check
                      style={{ paddingBottom: '1.75rem' }}
                    >
                      <Label
                        check
                        className="register-form__term-cond-label terms-and-agreement-label checkmark_label"
                      >
                        <Input
                          type="checkbox"
                          name="magnifi_part2_brochure"
                          id="magnifi_part2_brochure"
                          className={
                            errors.magnifi_part2_brochure &&
                              touched.magnifi_part2_brochure
                              ? 'register-form__checkbox is-invalid input-area'
                              : 'register-form__checkbox input-area'
                          }
                          checked={values.magnifi_part2_brochure}
                          onChange={handleChange}
                        />
                        <span class="checkmark"></span>
                      </Label>
                      <a
                        className="toggle-content label terms"
                        style={{ color: '#56a9e7', letterSpacing: -1 }}
                        href={'javascript:void(0)'}
                        onClick={() => this.props.toggleTermsModal()}
                      >
                        Terms and Disclosures
                      </a>
                      {errors.magnifi_part2_brochure &&
                        touched.magnifi_part2_brochure && (
                          <div className="form-invalid-field">
                            {errors['magnifi_part2_brochure']}
                          </div>
                        )}
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          : <div>
            <div className="w-100 mt-3">
              <span className='heading_in_between'>Terms and Agreement</span>
            </div>
            <div className="row form_input_container_gray border_radius_top border_radius_bottom pt-3">
              <div className="col">
                <Row className="mt-2">
                  <Col xs={6} md={6} lg={6} xl={4} className="pl-0 pr-1">
                    <FormGroup className="element-box pos-rel pl-0 pb-3" check style={{ paddingBottom: '1.75rem' }}>
                      <Label check className="register-form__term-cond-label terms-and-agreement-label checkmark_label">
                        <Input
                          type="checkbox"
                          name="secondary_account_form_investment_advisory_agreement"
                          id="secondary_account_form_investment_advisory_agreement"
                          className={
                            errors.secondary_account_form_investment_advisory_agreement && touched.secondary_account_form_investment_advisory_agreement
                              ? 'register-form__checkbox is-invalid input-area'
                              : 'register-form__checkbox input-area'
                          }
                          checked={values.secondary_account_form_investment_advisory_agreement}
                          onChange={handleChange}
                        />
                        <span class="checkmark"></span>
                      </Label>
                      <a className="toggle-content label terms" style={{ color: "#56a9e7" }} href={TEARM_LINKS.investment_advisory} target="_blank">Magnifi Investment Advisory Agreement</a>
                      {errors.secondary_account_form_investment_advisory_agreement && touched.secondary_account_form_investment_advisory_agreement && <div className="form-invalid-field">{errors['secondary_account_form_investment_advisory_agreement']}</div>}
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={6} lg={6} xl={4} className="pl-1 pr-1">
                    <FormGroup className="element-box pos-rel pl-0 pb-3" check style={{ paddingBottom: '1.75rem' }}>
                      <Label check className="register-form__term-cond-label terms-and-agreement-label checkmark_label">
                        <Input
                          type="checkbox"
                          name="secondary_account_enroll_in_sweep_program"
                          id="secondary_account_enroll_in_sweep_program"
                          className={
                            errors.secondary_account_enroll_in_sweep_program && touched.secondary_account_enroll_in_sweep_program
                              ? 'register-form__checkbox is-invalid input-area'
                              : 'register-form__checkbox input-area'
                          }
                          checked={values.secondary_account_enroll_in_sweep_program}
                          onChange={handleChange}
                        />
                        <span class="checkmark"></span>
                      </Label>
                      <a className="toggle-content label terms" style={{ color: "#56a9e7" }} href='/assets/disclosures/sweep_disclosures.pdf' target="_blank">Enroll in the Sweep Program <span style={{ color: '#b3b3b3', fontSize: 12 }}>(optional)</span></a>
                      <CustomFormFeedback
                        formProps={formProps}
                        fieldName="secondary_account_enroll_in_sweep_program"
                      />
                      {errors.secondary_account_enroll_in_sweep_program && touched.secondary_account_enroll_in_sweep_program && <div className="form-invalid-field">{errors['secondary_account_enroll_in_sweep_program']}</div>}
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={6} lg={6} xl={4} className="pl-1 pr-0">
                    <FormGroup
                      className="element-box pos-rel pl-0 pb-3"
                      style={{ paddingBottom: '1.75rem' }}>
                      <Label check className="register-form__term-cond-label terms-and-agreement-label checkmark_label">
                        <Input
                          type="checkbox"
                          name="secondary_account_magnifi_part2_brochure"
                          id="secondary_account_magnifi_part2_brochure"
                          className={
                            errors.secondary_account_magnifi_part2_brochure && touched.secondary_account_magnifi_part2_brochure
                              ? 'register-form__checkbox is-invalid input-area'
                              : 'register-form__checkbox input-area'
                          }
                          checked={values.secondary_account_magnifi_part2_brochure}
                          onChange={handleChange}
                        />
                        <span class="checkmark"></span>
                      </Label>
                      <a className="toggle-content label terms" style={{ color: "#56a9e7", letterSpacing: -1 }} href={'javascript:void(0)'} onClick={() => this.props.toggleTermsModal()}>Terms and Disclosures</a>
                      {errors.secondary_account_magnifi_part2_brochure && touched.secondary_account_magnifi_part2_brochure && <div className="form-invalid-field">{errors['secondary_account_magnifi_part2_brochure']}</div>}
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </div>
          </div>}

        {/* <Row className="mt-4 mb-2">
        <Col xs={7} style={{ display: 'flex', alignItems: 'center' }}>
          <div className="header-area form_title no_padding" style={{ width: '100%' }}>
              <div className="header-label sub" style={{ marginTop: 0, width: '100%', textAlign: 'right' }}>
                Signature
              </div>
          </div>
        </Col>
        {this.state.isSigned ? <Col xs={5} className={'signature_buttons'}>
            <span className="text-right save_clear_span" onClick={this.saveSignature}>
              <SaveOutlined style={{ marginRight: 3 }} />
              Save
            </span>
            <span className="text-right save_clear_span" onClick={this.clear}>
              <ReloadOutlined style={{ marginRight: 3 }} />
              Clear
            </span>
        </Col>: null}
      </Row>

        <Row className="form_input_container_gray border_radius_top border_radius_bottom">
          <Col>
            <FormGroup className="element-box dropdown-element pos-rel">


              <div ref={(node)=> {
                if(!this.state.parentNode)
                this.setState({ parentNode: node })
                }} className="signature-box sig-div">
                <SketchField
                  width="400px"
                  ref={c => this._sketch = c}
                  height="200px"
                  tool={Tools.Pencil}
                  lineColor="black"
                  lineWidth={6}
                />
              </div>

              {errors.signature_snap_id && touched.signature_snap_id && (
                <div className="form-invalid-field">
                  {errors['signature_snap_id']}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row> */}

        {!merger ? <FormGroup className="cta-wrapper" style={{ display: 'inline-block', width: '100%' }}>

          {!this.props.isSubmitStep ? <PrimaryButton
            type="button"
            onClick={() => this.props.saveData(this.props.formProps.values)}
            className="btn secondary-btn"
            style={{ width: '48%', float: 'left' }}
          >
            Save Progress
          </PrimaryButton> : null}

          <PrimaryButton
            type="submit"
            className="btn primary-btn"
            style={this.props.isSubmitStep ? { width: '100%' } : { width: '48%', float: 'right' }}
          >
            {this.props.isSubmitStep ? 'Create Account' : 'Save & Continue'}
          </PrimaryButton>
          {this.props.isSubmitStep ? <div className="start_finding">
            <a href="javascript:void(0)" onClick={() => this.props.finishLaterCall()}>Start Finding Investments...I'll Finish This Later</a>
          </div> : <div className="start_finding" style={{ marginTop: 60 }}><a style={{ color: '#56a9e8' }} onClick={() => {
            this.props.finishLaterCall()
          }} href="javascript:void(0)">I'll Finish This Later</a></div>}
        </FormGroup> : null}
        {/* {!merger ? <div className="acc-access-option-wrapper text-center">
              Create Magnifi Investment Account later?
              <span className="hyper-link" onClick={this.props.toggleSkipModal}>
                {' '}
                Skip
              </span>
            </div>:null} */}
        {/* {!merger ? <div className="or-partition text-center mb-3">
          * Mandatory
        </div> : null} */}
      </>
    )
  }
}

class BrandingTradingFormRHS3 extends Component {
  state = {
    tax_bracket: ''
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].style.paddingTop = 0
    // document.addEventListener('keydown', this.handleKeyPress)
    this.props.formProps.setTouched({})
  }

  handleKeyPress = (e) => {
    if (this.props.step === 2 && e.code === 'Tab' && !e.target.id) {
      let docu = document.getElementById('investment_obj');
      if (docu) {
        e.preventDefault();
        docu.click();
        docu.focus();
      }
    }
  }

  componentWillUnmount() {
    // document.removeEventListener('keydown', this.handleKeyPress)
  }


  toggleLNW = () => {
    this.setState({
      liquidNetWorth: !this.state.liquidNetWorth
    })
  }

  toggleRiskTolerance = () => {
    this.setState({
      riskTolerance: !this.state.riskTolerance
    })
  }

  toggleTaxBracket = () => {
    this.setState({
      taxBracket: !this.state.taxBracket
    })
  }


  getSplittedValue = (value) => {
    let formattedIndex = formattedRange.findIndex(el => Object.keys(el)[0] === value)
    if (formattedIndex >= 0) {
      return formattedRange[formattedIndex][value]
    }
    return value
  }

  render() {
    const { formProps, step, merger, hideStepBar } = this.props

    const {
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue
    } = formProps

    let totalSteps = values.account_type === 'Joint' ? 3 : 2

    totalSteps = this.props.isBranded ? ++totalSteps : ++totalSteps

    return (
      <>
        {/* <div>
          {!hideStepBar ? <StepsBar step={step} account_type={values.account_type} totalSteps={totalSteps} /> : null}
        </div> */}

        {/* {!hideStepBar ? <div className="header-area">
          <div className="header-label sub">
            Details to create Magnifi Investment Account
          </div>
        </div> : null} */}
        {/* <div className="header-area form_title">
          <div className="header-label sub">
            Investment Information
          </div>
        </div>
        {this.state.loading && <Spinner isFetching={this.state.loading} />}
        <Row className="form_input_container_gray border_radius_top">
          
          
        </Row>

        <Row className="form_input_container_gray border_radius_bottom">
          
        </Row> */}

        {!merger ? <FormGroup className="cta-wrapper" style={{ display: 'inline-block', width: '100%' }}>

          {!this.props.isSubmitStep ? <PrimaryButton
            type="button"
            onClick={() => this.props.saveData(this.props.formProps.values)}
            className="btn secondary-btn"
            style={{ width: '48%', float: 'left' }}
          >
            Save Progress
          </PrimaryButton> : null}

          <PrimaryButton
            type="submit"
            className="btn primary-btn"
            style={this.props.isSubmitStep ? { width: '100%' } : { width: '48%', float: 'right' }}
          >
            {this.props.isSubmitStep ? 'Create Account' : 'Save & Continue'}
          </PrimaryButton>
          {this.props.isSubmitStep ? <div className="start_finding">
            <a href="javascript:void(0)" onClick={() => this.props.finishLaterCall()}>Start Finding Investments...I'll Finish This Later</a>
          </div> : <div className="start_finding" style={{ marginTop: 60 }}><a style={{ color: '#56a9e8' }} onClick={() => {
            this.props.finishLaterCall()
          }} href="javascript:void(0)">I'll Finish This Later</a></div>}
        </FormGroup> : null}
        {/* {!merger ? <div className="acc-access-option-wrapper text-center">
              Create Magnifi Investment Account later?
              <span className="hyper-link" onClick={this.props.toggleSkipModal}>
                {' '}
                Skip
              </span>
            </div>:null} */}
        {/* {!merger ? <div className="or-partition text-center mb-3">
          * Mandatory
        </div> : null} */}
      </>
    )
  }
}

class TradingFormRHS3 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      linkToken: this.props.linkToken,
      create_account_btn_class: 'btn primary-btn disabled',
      create_account_btn_disabled: true,
      bankAccountDetails: this.props.bankAccountDetails,
      bankAccountDataStatus: this.props.bankAccountDataStatus,
      institution_name: this.props.institution_name,
      plaid_user_id: ''
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.linkToken !== this.props.linkToken) {
      this.setState({ linkToken: nextProps.linkToken })
    }
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].style.paddingTop = 0
    // this.props.getLinkToken(data => {
    //   if (data && data.linkToken) {
    //     this.setState({ linkToken: data.linkToken })
    //   }
    // })
    this.props.formProps.setTouched({})
  }

  handleOnSuccess(public_token, metadata) {
    //  console.log(public_token,metadata)
    // send token to client server
    if (public_token && metadata && metadata.institution) {
      let postData = {
        public_token: public_token,
        institution_name: metadata.institution.name,
        link_token: this.state.linkToken,
        acc_numbers: metadata.accounts.map(el => el.id)
      }
      let formData = {};
      this.props.getUsersAccounts(postData, data => {
        if (data && data.bankAccounnts) {
          if (data.bankAccounnts.length && data.bankAccounnts.length === 1) {
            formData['plaid_account_id'] = data.bankAccounnts[0].account_id;
            formData['plaid_user_id'] = data.bankAccounnts[0].plaid_user_id;
            this.props.createAchRelation(formData);
          }
          this.setState({
            bankAccountDataStatus: true,
            bankAccountDetails: data.bankAccounnts,
            institution_name: metadata.institution.name
          })
        }
      })
    }
  }

  handleOnExit() {
    // handle the case when your user exits Link
  }

  addNewAccount = e => {
    e.preventDefault()
    this.props.getLinkToken(data => {
      if (data && data.linkToken) {
        this.setState({
          bankAccountDataStatus: false,
          bankAccountDetails: [],
          institution_name: '',
          plaid_user_id: '',
          account_id: '',
          create_account_btn_class: 'btn primary-btn disabled'
        })
      }
    })
  }

  handleClick(e) {
    e.preventDefault()
    let token = this.props.getLinkToken(data => {
      if (data && data.linkToken) {
        this.setState({ linkToken: data.linkToken })
      }
    })
  }

  handleAch = () => {
    let formData = {}
    formData['plaid_account_id'] = this.props.formProps.values.account_id;
    formData['plaid_user_id'] = this.props.formProps.values.plaid_user_id;
    this.props.createAchRelation(formData);
  }

  handleBankRadioChange = event => {
    const target = event.target
    const value = target.value
    this.state.bankAccountDetails.forEach(item => {
      if (item.account_id === value) {
        localStorage.setItem('mask', item.mask)
      }
    })
    this.props.formProps.values.account_id = value
    this.props.formProps.values.plaid_user_id = event.target.getAttribute(
      'data-user-id'
    )
    this.setState({
      [event.target.name]: value,
      plaid_user_id: event.target.getAttribute('data-user-id'),
      create_account_btn_class: 'btn primary-btn ',
      create_account_btn_disabled: false
    })
  }

  render() {
    const { formProps, from, step, hideStepBar } = this.props

    const {
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue
    } = formProps

    let totalSteps = values.account_type === 'Joint' ? 3 : 2

    totalSteps = this.props.isBranded ? ++totalSteps : ++totalSteps;

    return (
      <>
        {/* {!hideStepBar ? <StepsBar step={'last'} account_type={values.account_type} totalSteps={totalSteps} />:null} */}
        <div className="header-area">
          <div className="header-label main">
            Create A Magnifi Investment Account For Exclusive Access
          </div>
        </div>
        {!hideStepBar ? <div className="header-area">
          <div className="header-label sub">Link your account</div>
        </div> : null}
        {this.state.bankAccountDataStatus === false && (
          <Row>
            <Col>
              <FormGroup className="element-box dropdown-element pos-rel">
                <Label className="label" htmlFor="bank_account">
                  Bank Account
                </Label>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="element-box dropdown-element pos-rel">
                <div className="element-upload-outer custom-add-account">
                  <PlaidLink
                    product={['auth']}
                    onExit={this.handleOnExit}
                    onSuccess={this.handleOnSuccess.bind(this)}
                    className="document-upload"
                    token={this.state.linkToken}
                  >
                    ADD ACCOUNT
                  </PlaidLink>
                </div>
              </FormGroup>
            </Col>
          </Row>
        )}

        {this.state.bankAccountDataStatus === true && (
          <>
            <Row>
              <Col>
                <FormGroup className="element-box dropdown-element pos-rel pb-3 pt-0">
                  <Label className="label" htmlFor="select_bank">
                    Select the bank account you want to link
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup className="element-box dropdown-element pos-rel pb-2 pt-0">
                  <Label className="label" htmlFor="institution_name">
                    <b>Bank Account: </b>
                    {this.state.institution_name}
                  </Label>
                  <div
                    className="custom-add-account"
                    style={{ marginBottom: 0 }}
                  >
                    <button
                      style={{ top: 0, right: 0 }}
                      className="document-upload"
                      onClick={e => this.addNewAccount(e)}
                    >
                      CHANGE BANK
                    </button>
                  </div>
                </FormGroup>
              </Col>
            </Row>

            {this.state.bankAccountDetails &&
              this.state.bankAccountDetails.length > 0 &&
              this.state.bankAccountDetails.map((obj, idx) => {
                return (
                  <Row key={idx}>
                    <Col>
                      <div className="element-box bank-details pt-0">
                        <Input
                          type="radio"
                          name="account_id"
                          data-user-id={obj.plaid_user_id}
                          value={obj.account_id}
                          id={`account_${obj.account_id}`}
                          onChange={this.handleBankRadioChange}
                        />
                        <label
                          for={`account_${obj.account_id}`}
                          className="label"
                        >
                          {obj.name}
                        </label>
                      </div>
                    </Col>
                    <Col className="text-right">
                      <label>{`XXXX${obj.mask}`}</label>
                    </Col>
                  </Row>
                )
              })}

            {/* <Row>
            <Col>
              <FormGroup className="element-box dropdown-element pos-rel">
                <div className="element-upload-outer custom-add-account">

                  <button className="document-upload" onClick={(e) => this.addNewAccount(e)}>Change Account</button>


                </div>
              </FormGroup>
            </Col>
          </Row> */}
          </>
        )}

        <FormGroup className="cta-wrapper">
          <PrimaryButton
            disabled={this.state.create_account_btn_disabled}
            type="button"
            className={this.state.create_account_btn_class}
            style={{ marginTop: '6px', marginBottom: '6px' }}
            onClick={() => this.handleAch()}
          >
            Add Bank Account
          </PrimaryButton>
          {/*{!this.state.bankAccountDataStatus && <div className="acc-access-option-wrapper">Do you want to add bank account later?*/}
          {/*  <span className="hyper-link" onClick={this.props.toggleSkipModal}> Skip</span>*/}
          {/*</div>}*/}
        </FormGroup>
      </>
    )
  }
}

class TradingFormRHS4 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showAddMoneyPopup: false,
      balance: '...',
      mask: localStorage.getItem('mask') ? localStorage.getItem('mask') : ''
    }
    localStorage.removeItem('mask')
  }

  async componentDidMount() {
    let balance = await fetchBalance()
    //console.log(balance)
    this.setState({
      balance
    })
    // try {
    //   balance = await fetchApexBalance().then(result => result)
    //   console.log(balance.data)
    //   this.setState({
    //     avaBalance:balance.data.total
    //   })
    // } catch (e) {
    //   console.log(e)
    //   this.setState({
    //     avaBalance:0
    //   })
    // }
  }

  toggleAddMoneyPopup = () => {
    this.setState({
      showAddMoneyPopup: !this.state.showAddMoneyPopup
    })
  }

  onPopupClose = () => {
    this.toggleAddMoneyPopup()
    this.props.history.push('/securities')
  }

  render() {
    return (
      <>
        <Tagline />
        <div className="header-area">
          <div className="header-label">
            Your account has been successfully created. Add funds to your investment account
          </div>
        </div>
        <div>
          <Row>
            <Col>
              <div className="element-box">
                <label className="label">Bank Account</label>
              </div>
            </Col>
            <Col className="text-right">
              <label>{`XXXX${this.state.mask}`}</label>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="element-box ">
                <label className="label">Investment Account Balance</label>
              </div>
            </Col>
            <Col className="text-right">
              <label className="account-balance">{`$${this.state.balance}`}</label>
            </Col>
          </Row>
        </div>
        <Form>
          <FormGroup className="cta-wrapper">
            <PrimaryButton
              type="button"
              className="btn primary-btn"
              style={{ marginTop: '6px', marginBottom: '6px' }}
              onClick={this.toggleAddMoneyPopup}
            >
              Add funds
            </PrimaryButton>
          </FormGroup>
        </Form>
        {/*<div className="d-flex justify-content-center acc-access-option-wrapper">*/}
        {/*  Do you want to add bank account later?*/}
        {/*  <span className="hyper-link" onClick={this.props.toggleSkipModal}>{`${' '}`} Skip</span>*/}
        {/*</div>*/}
        {
          <AddMoneyPopup
            callBack={this.onPopupClose}
            toggleAddMoneyPopup={this.toggleAddMoneyPopup}
            showAddMoneyPopup={this.state.showAddMoneyPopup}
          />
        }
      </>
    )
  }
}

class TradingFormRHS5 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      data: {},
      doesAccountNeedsReview: false,
      apex_account_id: '', //2 digit
      apex_account_no: '', //8
      finalReviewData: [],
      apexLoading: false,
      message: null,
      isSubmitted: false
    }
    this.documentContainer = React.createRef()
  }

  componentDidMount() {
    if (!this.props.profile.apex || isEmpty(this.props.profile)) {
      // console.log('fetch')
      this.setState({
        loading: false
      })
      this.props.fetchProfile()
    }
  }

  async componentWillReceiveProps(nextProps) {
    let apex
    let doesAccountNeedsReview = false
    if (
      nextProps.profile.apex &&
      !isEmpty(nextProps.profile.apex) &&
      !this.state.apexLoading
    ) {
      apex = nextProps.profile.apex
      //  console.log(apex)
      this.setState({
        loading: true,
        apexLoading: true, //hack
        apex_account_id: apex.account[0].id, //2 digit
        apex_account_no: apex.account[0].account_no //8 digit
      })
      let apexStatus = await getApexAccountStatus(apex.account[0].id)
      apexStatus = apexStatus.result
      console.log(apexStatus);
      !isEmpty(apexStatus.investigations) &&
        apexStatus.investigations.forEach(investigation => {
          if (!doesAccountNeedsReview) {
            !isEmpty(investigation.sketchId) &&
              investigation.sketchId.cipCategories.length &&
              investigation.sketchId.cipCategories.forEach(cat => {
                if (
                  cat.categoryState === 'REJECTED' &&
                  cat.requestedDocuments.length > 0
                ) {
                  doesAccountNeedsReview = true
                }
              })
          }
        })
      if (!doesAccountNeedsReview) {
        this.props.history.push('/securities')
      } else {
        this.setState({
          data: apexStatus,
          loading: false,
          doesAccountNeedsReview
        })
      }
    }
  }

  updateFinalReviewData = obj => {
    let data = this.state.finalReviewData.length
      ? this.state.finalReviewData
      : []
    let index = data.findIndex(val => val.sketchId === obj.sketchId)
    if (index > -1) {
      data[index] = obj
    } else {
      data.push(obj)
    }

    console.log(data)
    this.setState({ finalReviewData: data })
  }

  onClickReview = async () => {
    let payload = {
      apex_account_id: this.state.apex_account_id,
      data: this.state.finalReviewData.map(val => ({
        sketchId: val.sketchId,
        snapIDs: val.snapIDs
      }))
    }
    // console.log(payload)
    try {
      this.setState({
        loading: true
      })
      let uploadRes = await saveForReview(payload);
      if (uploadRes && uploadRes.data && uploadRes.data.message) {
        // console.log('====== DOC SAVED ======> ', uploadRes.data.message);
        // console.log(uploadRes);
        this.setState({
          loading: false
        })
        this.setState(
          {
            message: {
              type: 'success',
              message: 'Documents updated successfully'
            },
            isSubmitted: true
          },
          () =>
            setTimeout(() => {
              this.setState({ message: null })
              const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
              if (isMobile) {
                this.props.history.push('/securities')
              } else {
                this.props.history.push('/settings/tradingAccount')
              }
            }, 3000)
        )
        this.props.fetchProfile()
      } else {
        console.log('===== FAILED TO SAVE DOC =====');
      }
      // this.props.history.push('/securities');
      // console.log(uploadRes)
    } catch (e) {
      this.setState({
        loading: false
      })
      //console.log(e)
    }
  }

  render() {
    const {
      loading,
      finalReviewData,
      data,
      apex_account_no,
      apex_account_id,
      isSubmitted
    } = this.state
    const documentsElement = get(this.documentContainer, 'current.children')
      ? Array.from(get(this.documentContainer, 'current.children'))
      : []
    const filterDocumentElements = documentsElement
      ? documentsElement.filter(
        val =>
          val.nodeName !== 'H5' &&
          get(val, 'attributes[attr-id].value') === 'enabled'
      )
      : []

    // const isSubmitDisabled = finalReviewData.length ? !finalReviewData.every(val => val.enabled) : true;
    const isSubmitDisabled =
      filterDocumentElements.length !==
      finalReviewData.reduce((acc, val) => (acc += val.snapIDs.length), 0)

    return (
      <>
        {this.state.message && (
          <CustomAlert
            className="alert-windown-wrapper"
            color={this.state.message.type}
          >
            {this.state.message.message}
          </CustomAlert>
        )}
        {loading && <Spinner isFetching={loading} />}
        <Tagline />
        <div className="header-area">
          <div className="header-label">
            Your account has been rejected. Please upload following documents
            for review.
          </div>
        </div>
        <div ref={this.documentContainer}>
          {!isEmpty(data) &&
            data.investigations.map((investigation, index) => {
              let heading = ''
              if (index === 0) {
                heading = (
                  <h5>
                    Primary User Documents (
                    {`${get(this.props.profile, 'first_name')}`}{' '}
                    {`${get(this.props.profile, 'last_name')}`})
                  </h5>
                )
              } else if (index === 1) {
                heading = (
                  <h5>
                    Secondary User Documents (
                    {`${get(
                      this.props.profile,
                      'apex.joint_user.joint_first_name'
                    )}`}{' '}
                    {`${get(
                      this.props.profile,
                      'apex.joint_user.joint_last_name'
                    )}`}
                    )
                  </h5>
                )
              }
              if (!isEmpty(investigation.sketchId)) {
                return (
                  <Fragment>
                    {heading}
                    <SketchContainer
                      apex={this.props.profile.apex}
                      apex_account_id={apex_account_id}
                      updateFinalReviewData={this.updateFinalReviewData}
                      account_no={apex_account_no}
                      data={investigation.sketchId}
                      mainIndex={index}
                      onClickReview={this.onClickReview}
                    />
                  </Fragment>
                )
              }
            })}
        </div>
        <Form>
          <FormGroup className="cta-wrapper">
            <PrimaryButton
              disabled={isSubmitDisabled || loading}
              type="button"
              className="btn primary-btn"
              style={{ marginTop: '36px', marginBottom: '6px' }}
              onClick={this.onClickReview}
            >
              Submit for review
            </PrimaryButton>
          </FormGroup>
        </Form>
      </>
    )
  }
}

export const Tagline = () => (
  <div className="mb-3" style={{ width: '90%' }}>
    <a className="logo-wrapper" href={REDIRECT_DOMAIN_PATH}>
      <img src={magnifiLogo} width="100%" alt="magnifi" />
    </a>
  </div>
)

export const StepsBar = (props) => {
  let stepToActive = props.step === 'last' ? props.totalSteps + 1 : props.step + 1;
  // let headingsArray = [`Great! You're on Step 2 of ${props.totalSteps}. Regulators Require This.`, 'Please Review These Disclosures.', 'Please Specify Secondary Applicant Details.']
  let headingsArray = [`Start Today`, 'Start Today', 'Start Today']
  return (
    <div className="mb-4" style={{ width: '100%', paddingLeft: '8%', paddingRight: '10%' }}>
      <div className="header-area retail_form_container">
        <h1 className="header-label main text-center mb-3">
          {/* {headingsArray[stepToActive - 2] ? headingsArray[stepToActive - 2] : 'Create A Magnifi Investment Account For Exclusive Access'} */}
          {headingsArray[stepToActive - 2] ? headingsArray[stepToActive - 2] : 'Start Today'}
        </h1>
      </div>
      <section class="step-indicator" style={{ width: '100%' }}>
        {Array(props.totalSteps).fill().map((el, i) => {
          let stepNames = ['Login', 'About You', 'Disclosures', 'Link Account'];
          let timer = ['(1 min)', '(1 min)', '(1-2 min)', '(1-2 min)']
          if (props.account_type === "Joint") {
            stepNames = ['Login', 'About You', 'Disclosures', 'Secondary Applicant', 'Link Account'];
            timer = ['(1 min)', '(1 min)', '(1-2 min)', '(1-2 min)', '(1-2 min)']
          }
          return (
            <>
              <div class={`step step${i + 1} ${i + 1 <= stepToActive ? 'active' : ''}`}>
                <div class="step-icon">{i + 1}</div>
                <span className={stepNames[i] === 'About You' ? 'set_span_text' : ''}>{stepNames[i]}</span>
                {/* <span className="timer">{timer[i]}</span> */}
              </div>
              {i < props.totalSteps - 1 ? <div class={`indicator-line ${i + 1 < stepToActive ? 'active' : ''} ${i + 1 === stepToActive ? 'nextToActive' : ''}`}></div> : null}
            </>
          )
        })}
      </section>
    </div>
  )
}

export const RegisterFormLHS = () => (
  <div className="text-center w-75 carousel-panel">
    <h1 className="header-1">Why Personalize?</h1>
    {/* <img src={lhsStep1} width="100%" alt="magnifi" /> */}
    <MessagesCarousel />
  </div>
)

// const LHSMsg = [
//   'Access calculators to compare funds',
//   'Build and Evaluate changes to your portfolio',
//   'And much more...',
// ]

const LHSMsg = [
  {
    src: lhsCalculator,
    altText: '',
    caption: 'Access calculators to compare funds'
  },
  {
    src: lhsEnhance,
    altText: '',
    caption: 'Build and evaluate changes to your portfolio'
  },
  {
    src: lhsmuchmore,
    altText: '',
    caption: 'And much more...'
  }
]

const MessagesCarousel = props => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)
  const items = LHSMsg

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = newIndex => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  // const slides = items.map((item, i) => {
  //   return (
  //     <CarouselItem key={i} className="h4"
  //       onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} >
  //       {item}
  //     </CarouselItem>
  //   );
  // });

  const slides = items.map(item => {
    return (
      <CarouselItem
        className="carouselImg"
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
        id="caraousel-id"
      >
        <img src={item.src} alt={item.altText} />
        <h4 style={{ color: '#fff', fontWeight: '400', fontSize: '20px' }}>
          {item.caption}
        </h4>
        {/* <CarouselCaption captionText={item.caption} captionHeader={item.caption} /> */}
      </CarouselItem>
    )
  })

  return (
    // <Carousel activeIndex={activeIndex} next={next} previous={previous}
    //   interval={5000} ride="carousel" >
    //   <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} className="dots" />
    //   {slides}
    // </Carousel>
    <Carousel
      interval={2000}
      ride="carousel"
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
        className="dots"
        id="caraousel-dots"
      />
      {slides}
      {/* <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} /> */}
    </Carousel>
  )
}

const SketchContainer = ({
  apex,
  updateFinalReviewData,
  data,
  account_no,
  apex_account_id,
  mainIndex,
  onClickReview
}) => {
  let snaps = apex.snaps ? apex.snaps : []
  //data.cipCategories = []
  let Rows = []

  const [snapIDs, pushSnapIds] = useState([])

  let updateSnapID = obj => {
    pushSnapIds([...snapIDs, obj])
  }
  let countEnabled = 0

  useEffect(() => {
    let enabled = countEnabled > 0 && snapIDs.length === countEnabled
    updateFinalReviewData({
      enabled,
      sketchId: data.id,
      snapIDs
    })
  }, [snapIDs, countEnabled])

  if (!isEmpty(data)) {
    let cipCategories = data.cipCategories
    {
      cipCategories.forEach((cat, index) => {
        if (
          cat.categoryState === 'REJECTED' &&
          cat.requestedDocuments.length > 0
        ) {
          let isDisabled = true
          snaps.length &&
            snaps.findIndex(snap => snap.sketch_id == data.id) > -1
            ? snaps.forEach(snap => {
              if (snap.sketch_id === data.id && snap.doc_type === cat.name) {
                isDisabled = snap.status !== 'rejected'
              }
            })
            : (isDisabled = false)
          countEnabled = isDisabled ? countEnabled : countEnabled + 1

          Rows.push(
            <RequestedDocRow
              snaps={snaps}
              index={`${mainIndex}${index}`}
              apex_account_id={apex_account_id}
              updateSnapID={updateSnapID}
              data={cat}
              request={data.request}
              sketchId={data.id}
              isDisabled={isDisabled}
            />
          )
        }
      })
    }
  }

  return (
    <Fragment>
      {Rows}
      {Rows.length > 0 ? null : (
        <div className="pb-2">No rejected document</div>
      )}
    </Fragment>
  )
}

const RequestedDocRow = ({
  snaps,
  data,
  updateSnapID,
  apex_account_id,
  request,
  index,
  sketchId,
  isDisabled
}) => {
  const FORMLABLES = {
    DOB: 'Date of Birth',
    TAX_ID: 'Social Security Number'
  }
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [ddValue, setDDValue] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [isUploadDisabled, updateButton] = useState(true)
  const [files, setFiles] = useState(null)
  //console.log("files",files)
  const onChangeHandler = async event => {
    const files = event.target.files
    if (files && files[0]) {
      setFiles(files[0])
      setError('')
      let fileSize = files[0].size / 1024 / 1024
      if (fileSize > 15) {
        setError(
          `The file ${files[0].name} is too big. (files must be under 15MB in size).`
        )
        return
      } else if (!SUPPORTED_FORMATS.includes(files[0].type)) {
        setError('Only .png,.jpg,.jpeg,.gif, image format are allowed')
        return
      }
      let formData = new FormData()
      formData.append('file', files[0])
      formData.append('tag', 'ID_DOCUMENT')
      formData.append('account', apex_account_id)
      setLoading(true)
      try {
        let upload = await uploadReviewImage(formData)
        setLoading(false)
        updateSnapID({
          id: upload.record_id,
          type: data.name,
          proof: ddValue.name,
          status: 'uploaded'
        })
      } catch (e) {
        setLoading(false)
        setError('Error')
      }
    }
  }

  const onChangeDD = doc => {
    setDDValue(doc)
    updateButton(false)
  }

  // let isDisabled = true
  // snaps.length && (snaps.findIndex(snap => snap.sketch_id == sketchId) > -1) ? snaps.forEach(snap=>{
  //   if(snap.sketch_id === sketchId && snap.doc_type === data.name){
  //     isDisabled = snap.status !== 'rejected';
  //   }
  // }) : ( isDisabled=false)

  const toggle = () => setDropdownOpen(prevState => !prevState)
  //console.log(isDisabled)
  return (
    <div className="req-doc-row" attr-id={isDisabled ? 'disabled' : 'enabled'}>
      <div className="top">
        {/* <span className={'heading'}>{data.name}</span> */}
        <span className={'error'}>{error}</span>
      </div>
      <div>
        <div className="bottom fdc">
          <p>
            {FORMLABLES[data.name]
              ? FORMLABLES[data.name]
              : data.name.replace('_', ' ')}
            *
          </p>
          {/* {!ddValue.description && <p>Select The Document Type From Dropdown*</p>} */}
          <Row>
            <Col md={12} lg={6} xl={6} style={{ padding: '15px 5px 0 5px' }}>
              <Dropdown
                disabled={isDisabled}
                className={'dropdown-wrapper'}
                isOpen={dropdownOpen}
                toggle={toggle}
              >
                <DropdownToggle disabled={isDisabled} className={'toggle'} caret>
                  {ddValue.description || 'Select'}
                </DropdownToggle>
                <DropdownMenu>
                  {data.requestedDocuments.map((doc, i) => (
                    <DropdownItem
                      key={`${i}${doc.description}`}
                      value={doc}
                      onClick={e => {
                        onChangeDD(doc)
                      }}
                    >
                      {doc.description}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </Col>
            <Col md={12} lg={6} xl={6} style={{ position: 'relative', padding: '15px 5px 0 5px' }}>
              {ddValue.description && (
                <div className="upload_div">
                  <div className="upl-file">
                    {files ? files.name : `Upload Document`}
                  </div>
                  <Input
                    disabled={isUploadDisabled || isDisabled}
                    key={`${data.name}${index}`}
                    className="hidden_file"
                    type="file"
                    name={data.name}
                    id={`${data.name}${index}`}
                    accept="image/*"
                    onChange={onChangeHandler}
                    placeholder="Upload document"
                  />
                  <Label
                    className={`label holoButton ${isUploadDisabled &&
                      'disabled'} upl`}
                    htmlFor={`${data.name}${index}`}
                  >
                    Upload
                  </Label>
                </div>
              )}
            </Col>
          </Row>
          {loading && <SpinnerR />}
        </div>
      </div>
    </div>
  )
}

const PoliticallyExposed = () => {
  return <div>this is politically exposed div</div>
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Alert,
  Col,
  Collapse,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Button,
  Input
} from 'reactstrap'
import moment from 'moment';
import isEmpty from 'lodash/isEmpty'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { FieldValue, PrimaryButton } from 'shared-components'

import ViewProfileForm from 'components/profile/ViewProfileForm'
import EditableProfileForm from 'components/profile/EditableProfileForm'
import ProfilePlanDetails from 'components/profile/ProfilePlanDetails'

import { fetchProfile, updateProfile, profileChangePasswordRequest } from 'actions/profile'
import { createLoadingSelector } from 'selectors/loading-selector'

import { Regex } from 'common/regex'
import { Debug, CustomFormFeedback, SecondaryButton } from 'shared-components/'
import ChangePassword from 'components/profile/ChangePassword'
import { Spinner } from 'shared-components/';
import { encryptPassword } from 'layouts/utils';
import { addEventToAnalytics } from 'Utils'

function ProfileForm(props) {
  if (props.mode === 'edit') {
    return <EditableProfileForm formProps={props.formProps} />
  }
  return <ViewProfileForm formProps={props.formProps} />
}

class ProfileFormContainer extends Component {
  state = {
    closeAlert: false,
    toggleChangePassword: false,
    mode: 'edit', // 'view'
    profile: {
      ...this.defaultProfile
    }
  }

  // hear_aboutus: Yup.string().required(
  //   'Where did you hear about us is a required field'
  // ),
  // self_role: Yup.string().required('Are you an is a required field'),
  getProfileSchema = () => {
    return Yup.object().shape({
      first_name: Yup.string().required('First name is a required field').matches(
        Regex.fullName,
        'First name is not valid'
      ),
      last_name: Yup.string().required('Last name is a required field').matches(
        Regex.fullName,
        'Last name is not valid'
      ),
      phone: Yup.string().matches(
        Regex.phone,
        'Phone number is not valid. Sample format 515 989 2899'
      ),
      zipcode: Yup.string().matches(Regex.zipCode, 'zip code is not valid')
    })
  }

  handleProfileUpdateSubmit = (values, { setSubmitting }) => {
    let data = values;
    delete data.terms_agreed;
    data.date_of_birth = data.date_of_birth !== '' ? moment(data.date_of_birth)._d : '';
    this.props.updateProfile(data)
    setSubmitting(false)
  }

  componentDidMount() {
    document.body.style.paddingTop = "245px";
    this.props.fetchProfile()
  }

  componentDidUpdate(prevProps) {

    document.body.style.paddingTop = "";
    document.body.classList.remove('ht0');
    if (this.props.profile !== prevProps.profile) {
      this.setState({
        profile: isEmpty(this.props.profile)
          ? this.state.profile
          : this.props.profile
      })
    }

    if (this.props.profileUpdateSuccess !== prevProps.profileUpdateSuccess) {
      this.setState({
        mode: 'edit',
        closeAlert: true
      })
      window.scrollTo(0, 0); //for viewing alert
      setTimeout(() => {
        this.setState({
          closeAlert: false
        })
      }, 5000)
    }

    if (this.props.profileUpdateFailed !== prevProps.profileUpdateFailed) {
      this.setState({
        mode: 'edit',
        closeAlert: true
      })
      window.scrollTo(0, 0);  //for viewing alert
      setTimeout(() => {
        this.setState({
          closeAlert: false
        })
      }, 5000)
    }

    if (this.props.changePasswordSuccess !== prevProps.changePasswordSuccess) {
      this.setState({
        closeAlert: true
      })
      setTimeout(() => {
        this.setState({
          closeAlert: false
        })
      }, 5000)
    }

    if (this.props.changePasswordFailed !== prevProps.changePasswordFailed) {
      this.setState({
        closeAlert: true
      })
      setTimeout(() => {
        this.setState({
          closeAlert: false
        })
      }, 5000)
    }

    if (this.props.changePasswordSuccess !== prevProps.changePasswordSuccess) {
      if (this.props.changePasswordSuccess) {
        this.setState(prevState => ({
          toggleChangePassword: !prevState.toggleChangePassword
        }))
      }
    }
  }

  handleUpdate = event => {
    event.preventDefault()
    this.setState({
      mode: 'edit'
    })
  }

  handleChangePassword = event => {
    event.preventDefault()
    this.setState(prevState => ({
      toggleChangePassword: !prevState.toggleChangePassword
    }))
  }

  handleCancelPassword = () => {
    this.setState(prevState => ({
      toggleChangePassword: !prevState.toggleChangePassword
    }))
  }

  handleChangePasswordSubmit = values => {
    const { auth: { token, user: { email } }, profileChangePasswordRequest } = this.props;
    if (profileChangePasswordRequest) {
      this.setState(prevState => ({
        toggleChangePassword: !prevState.toggleChangePassword
      }))
      addEventToAnalytics(
        'Password Change',
        'Password Change',
        'PASSWORD_CHANGE',
        {},
        false
      );
      let payloadRequest = {
        email,
        verifypwdtoken: token,
        password : encryptPassword(values.password),
        old_password: encryptPassword(values.current_password),
      }
      profileChangePasswordRequest(payloadRequest)
    }
    //this.props.profileChangePasswordRequest(values)
  }

  render() {
    const { mode, closeAlert } = this.state
    const {
      isFetching,
      user,
      isSSOUser,
      profileUpdateSuccess,
      profileUpdateFailed,
      changePasswordSuccess,
      changePasswordFailed
    } = this.props
    const isViewMode = mode === 'view'
    const isEditMode = mode === 'edit'

    return (
      <div className="profile-container">
        <Spinner isFetching={isFetching} />
        <div className="profile">
          <div className="profile__alert">
            <Row>
              <Col xs={12}>
                {profileUpdateSuccess ? (
                  <Alert isOpen={closeAlert} color="success">
                    Profile updated successfully!
                  </Alert>
                ) : null}
                {profileUpdateFailed ? (
                  <Alert isOpen={closeAlert} color="danger">
                    Unable to update the profile info
                  </Alert>
                ) : null}
                {changePasswordSuccess ? (
                  <Alert isOpen={closeAlert} color="success">
                    Change password updated successfully!
                  </Alert>
                ) : null}
                {changePasswordFailed ? (
                  <Alert isOpen={closeAlert} color="danger">
                    Unable to update the change password
                  </Alert>
                ) : null}
              </Col>
            </Row>
          </div>
          <div className="profile__section profile__user-info">
            <Container>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label>E-mail</Label>
                    <FieldValue dashIfEmpty>{user.email}</FieldValue>
                  </FormGroup>
                </Col>
              </Row>
              {!isSSOUser && (
                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <a
                        href="#"
                        className="primary-link"
                        onClick={this.handleChangePassword}
                      >
                        Change Password
                      </a>
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Collapse isOpen={this.state.toggleChangePassword}>
                <ChangePassword
                  onSubmit={this.handleChangePasswordSubmit}
                  onCancel={this.handleCancelPassword}
                />
              </Collapse>
            </Container>
          </div>
          <div className="profile__section profile__personal-info">
            <div className="profile__section-header">
              <Container>
                <Row>
                  <Col xs={12}>
                    <h2 className="profile__section-title">
                      PERSONAL INFORMATION
                    </h2>
                    <h2 className="profile__section-title-required">* - Required Fields</h2>
                    {isViewMode ? (
                      <a
                        href="#"
                        className="profile__section-link primary-link float-right"
                        onClick={this.handleUpdate}
                      >
                        Update
                      </a>
                    ) : null}
                  </Col>
                </Row>
              </Container>
            </div>
            <Container>
              <Formik
                enableReinitialize
                initialValues={this.state.profile}
                onSubmit={this.handleProfileUpdateSubmit}
                validationSchema={this.getProfileSchema()}
                render={props => (
                  <Form onSubmit={props.handleSubmit} noValidate={true}>
                    <ProfileForm mode={mode} formProps={props} />
                    {isEditMode ? (
                      <div className="profile__section-footer">
                        <Container>
                          <Row>
                            <Col offset-xs={12}>
                              <FormGroup>
                              <Button color="tools"
                                  className="btn h3 text-uppercase float-right font-weight-bold p-3 mb-0 ml-auto secondary-btn"
                                  onClick={props.onProfileUpdate}
                                >
                                  UPDATE
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    ) : null}
                  </Form>
                )}
              />
            </Container>
          </div>
          {/*<ProfilePlanDetails premiumUser={user.premiumUser} />*/}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.profile.profile,
  user: state.auth.user,
  auth: state.auth,
  isSSOUser: Boolean(state.auth.isGoogleLogin),
  profileUpdateSuccess: state.profile.profileUpdateSuccess,
  profileUpdateFailed: state.profile.profileUpdateFailed,
  isFetching:  createLoadingSelector(['PROFILE', 'CHANGE_PASSWORD', 'PROFILE_UPDATE'])(state),
  changePasswordSuccess: state.profile.changePasswordSuccess,
  changePasswordFailed: state.profile.changePasswordFailed
})

const mapDispatchToProps = {
  fetchProfile,
  updateProfile,
  profileChangePasswordRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileFormContainer)

import React, { Component } from 'react'

import { indexPageMapping } from 'components/reportsV2/shared/Utils'
import style from './PrintPreviewModal.module.scss'
import { Checkbox } from 'antd'


export const getAllPages = () => {
  let arr = []
  try {
    for (const [key, value] of Object.entries(indexPageMapping)) {
      value.showPageNum && arr.push(key)
    }
  }catch (e) {
    console.log(e)
  }
  return arr
}
export const ALL_LENGTH = 11

class PrintPreviewModal extends Component {
  state={
    master_selection:this.props.report_preference.length?this.props.report_preference:getAllPages(),
    isAllSelected:true,
    previewPage:'ex-sum'
  }

  updateSelection = (arr)=>{
    this.setState({
      master_selection:arr,
      isAllSelected:arr.length===ALL_LENGTH
    })
  }

  updatePreviewPage = (previewPage)=>{
    this.setState({
      previewPage:previewPage,
    })
  }

  toggleSelectAll=()=>{
    if(this.state.isAllSelected){
      this.setState({
        master_selection:[],
        isAllSelected:false
      })
    }else{
      let arr = getAllPages()
      this.setState({
        master_selection:arr,
        isAllSelected:true
      })
    }
  }

  render() {

    const {isAllSelected,master_selection,previewPage} = this.state
    const {toggle,allFunds,selectedFunds,activeTab} = this.props
    let selectedFundsArray = selectedFunds.map(item=>item.ticker)
    let otherFunds = []
    allFunds.forEach(e => {
      if (!selectedFundsArray.includes(e.ticker)) {
        otherFunds.push(e);
      }
    })

    return (
      <div className={style.container} >
        {/*close button*/}
        <i className={`${style.close} fal fa-times`} onClick={()=>{toggle(true)}}/>
        {/*header*/}
        <div className={style.headerBox}>
          <h2>Manager Diligence Report</h2>
          <p>
            This proposal reflects the product research conducted on Magnifi based on your diligence criteria.
            <br/>
            Here is a quick preview of report.
          </p>
        </div>
        {/*body*/}
        <div className={style.body}>
          {/*left*/}
          <div className={style.left}>
            {(activeTab==='Combine'&&previewPage==='high-rel-funds')?
              <img className={style.image} src={`/assets/images/SFMReportPreview/${previewPage}-combine.png`} alt="page" />:
              <img className={style.image} src={`/assets/images/SFMReportPreview/${previewPage}.png`} alt="page" />
            }
          </div>
          {/*right*/}
          <div className={style.right}>
            <div className={style.table}>
              <div className={style.row}>
                <div className={style.pageName}>Content</div>
                <div className={style.checkbox} onClick={this.toggleSelectAll}>Select {isAllSelected?'None':'All'}</div>
              </div>
              <GenerateTableBody
                otherFunds={otherFunds}
                previewPage={previewPage}
                updatePreviewPage={this.updatePreviewPage}
                master_selection={master_selection}
                updateSelection={this.updateSelection}/>
            </div>
          </div>
        </div>
        {/*bottom button*/}
        <div className={style.footer}>
          <button className={style.save} disabled={master_selection.length===0} onClick={()=>{this.props.savePrintSelection(master_selection)}}>Download report with selected pages</button>
        </div>
      </div>
    )
  }
}


export default PrintPreviewModal

// PrintPreviewModal.propTypes = {
//   report_preference: PropTypes.array.isRequired,
//   toggle: PropTypes.func.isRequired,
//   savePrintSelection:PropTypes.func.isRequired
// }

let GenerateTableBody=({otherFunds,updateSelection,master_selection,updatePreviewPage,previewPage})=>{


  const generateSelection = (name)=>{
    if(master_selection.length && master_selection.includes(name)){
      let temp= master_selection.filter(item=>item!==name)
      updateSelection(temp)
    }else{
      let temp = [...master_selection,name]
      updateSelection(temp)
    }
  }

  let Rows = []

  for (const [key, value] of Object.entries(indexPageMapping)) {
    if(value.showPageNum){
      let name = value.name
      name = name==='a. Other Relevant Funds'?name='Appendix: Other Relevant Funds':name
      name = name==='Fund Fact Sheets'?name='Appendix: Fund Fact Sheets':name
      if(!(name==='Appendix: Other Relevant Funds' && otherFunds.length===0)){
        Rows.push(<div className={style.row}>
          <div onClick={()=>{updatePreviewPage(key)}} title={name} className={`${previewPage===key&&style.active} ${style.pageName}`}>
            <span>{name}</span>
          </div>
          <div onClick={()=>{generateSelection(key)}} className={style.checkbox}>
            <Checkbox id={key} name={key} value={key} checked={master_selection.includes(key)}/>
          </div>
        </div>)
      }
    }
  }

  return Rows
}

import React, { useEffect, useState } from 'react'
import { REDIRECT_DOMAIN_PATH } from 'DataSet';
import { verifyEmail } from 'apis/auth'

export default function VerifyEmail (props){

  const[loading,setLoading]=useState(false)
  const[status,setStatus] = useState(null)

  useEffect(()=>{
    (async ()=>{
      document.body.classList.toggle('pt-0', true);
      document.body.classList.toggle('about_container', true);
      setLoading(true)
      let query = props.location.search.split('=')[1]
      if(query){
        try {
          let data = await verifyEmail(query)
          console.log(data)
          setLoading(false)
          setStatus('success')
        }catch (e) {
          console.log(e)
          setLoading(false)
          setStatus('error')
        }
      }else{
        props.history.push('/')
      }

    })()
    return ()=>{
      document.body.classList.remove('pt-0');
      document.body.classList.remove('about_container');
    }
  },[])

  return (
    <div className="about_container">
      <a name="aboutUsAnchor"></a>
      <div className="whatwedo_masthead text-center text-white d-flex">
        <div className="container my-auto">
          <div className="row">
            <div className="col-md-12 mx-auto text-center abt-txt">
              <div style={{ textAlign: 'center' }}>
                <a className="navbar-brand mx-auto h1" href={REDIRECT_DOMAIN_PATH}>
                  <img src="/assets/images/MagnifiLogo/Magnifi_Logo_Grey_RGB.png" width="250" alt="OpenSeSaFi" />
                </a>
              </div>
              <div className="mt-5">
                <h2>Email Verification</h2>
                <div className="btm-underline" style={{ width: '94.34px' }}></div>
                {loading ?
                  <p className="text-faded"> <br /><i className="fal fa-circle-notch fa-4x fa-spin text-gray-5"></i></p> :
                  <p className="text-faded">
                  <br />
                    {status==='success' && 'Successfully Verified'}
                    {status==='error' && 'Expired or Invalid Link'}
                  <br />
                </p>}
                <br />
              </div>
            </div>
          </div>
          {!loading && <div className="container main-container__participate">
            <a className="btn main-container__btn-participate" href="/">Visit Homepage</a>
          </div>}
        </div>
      </div>
    </div>
  )
}



import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { extent as d3Extent } from 'd3-array';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ReferenceLine ,CartesianGrid} from 'recharts';
import { getNestDefault, numFmt } from 'Utils';

export const MultiSeriesLineChart = (props) => {
	// console.log(props);
	const { lines, ydomain, series, yearsData } = props;
	let { selectedYear: { number } } = props;
	const colors = ['#56a9e8', '#1e5f91'];

	if (!lines || !lines.length) return null;

	if (!series || !series.length || typeof series === 'undefined') return null;

	const labelFormatter = label => {
		return label
	}

	const first = series.findIndex(e => e.y1 !== undefined)
	let data = series;

	// data.splice(first, 0, { x: 'Start', y1: 0, y2: 0, y3: 0, y4: 0 })
	let last = data[data.length - 1]

	const posY = () => {
		let out = { y1: 0, y2 : 0 }
		if (typeof data[data.length - 1].label !== "Enhanced") return out

		const ranges = ['y1', 'y2'].reduce(
			(acc, y) => acc.concat(d3Extent(data, e => e[y])),
			[]
		)
		const [min, max] = d3Extent(ranges) // ydomain across all 3 lines
		const y1pos = last.y1 / (max - min),
		y2pos = last.y2 / (max - min)
		const diff = Math.abs(y1pos - y2pos)

		// if difference is more than 20% then no collision
		if (diff > 0.2) return out

		const adj = Math.round((0.2 - diff) * 30) // consider 12px text height, 12px = 20%
		if (y1pos > y2pos) {
			// y1 appears first
			if (y1pos > 0.9) out.y2 = 2 * adj
			// y1 almost at top, so just move y4 down
			else if (y2pos < 0.1) out.y1 = -2 * adj
			// y2 almost at bottom, so just move y1 up
			else out = { y1: -adj, y2: adj } // move y1 up, y2 down by same amt
		} else {
			// y2 appears first
			if (y2pos > 0.9) out.y1 = 2 * adj
			// y2 almost at top, so just move y1 down
			else if (y1pos < 0.1) out.y2 = -2 * adj
			// y1 almost at bottom, so just move y2 up
			else out = { y1: adj, y2: -adj } // move y2 up, y1 down by same amt
		}
		return out
	}
	const labelOff = posY()

	const segs = (color) => {
		const tick = 1 / (data.length - 1 - first)
		let out = [],
		dir

		for (let i = first + 1; i < data.length; i++) {
			let _dir = data[i].y3 < 0 ? 0 : 1
			if (dir === _dir) {
				out[out.length - 1] = (
					<stop
						key={`s${i}`}
						offset={(i-first-1)*tick}
						stopColor={color}
						stopOpacity={1}
						/>
				)
			} else {
				out.push(
					<stop
						key={`s${i}`}
						offset={(i - first - 1) * tick}
						stopColor={color}
						stopOpacity={1}
						/>
				)
				out.push(
					<stop
						key={`e${i}`}
						offset={(i - first) * tick}
						stopColor={color}
						stopOpacity={1}
						/>
				)
			}
			dir = _dir
		}
		return out
	}

	const gradientId = `color-${props.fid}-${props.gSuffix || ''}`;

	const onlyValue = data.map((item) => item.v);
	const YAxisTicks = [Math.min(...onlyValue), Math.max(...onlyValue)];

	// console.log(data);
	let _count = (number*12);
	// console.log('_count -->', _count, number);
	let toalMonthsInNumber = [...new Array(_count)].map((e, i) => i+1).reverse();
	// console.log(toalMonthsInNumber);
	// const toalMonthsInNumber= [1, 2, 3, 4, 5, 6 ,7, 8, 9, 10, 11, 12].reverse();
	const DefaultMonthsForYear = []; // moment.monthsShort();
	toalMonthsInNumber.forEach((kl, i) => {
		DefaultMonthsForYear.push(moment().add(moment().daysInMonth()-moment().format('DD'), 'day').subtract(kl, 'months').format('DD MMM YYYY'));
	});
	// console.log(DefaultMonthsForYear);

	// const TotalEntries = data.map((j) => moment(j.d).format('DD MMM YYYY'));

	let getAllYears = DefaultMonthsForYear.map((o) => moment(o).year()); // data.map((o) => moment(o.d).year());

	if (getAllYears && getAllYears.length > 0) {
		getAllYears = _.union(getAllYears);
	}
	// console.log('getAllYears --> ', getAllYears);

	let getMonthsEntryForYear = [];

	getAllYears.forEach((year, index) => {
		let _monthArray = data.filter((o) => o.d && o.d.includes(year));
		if (_monthArray && _monthArray.length > 0) {
			let _missingMonthEntry = [];
			let dummyEntry = [];
			let __arr = [];

			DefaultMonthsForYear.forEach((month) => {
				let found = _monthArray.find((k) => (moment(month).format('MMM') === moment(k.d).format('MMM')));
				__arr=[...new Array(moment(month).format('DD'))]
				console.log('month print--->',month,moment(month).format('DD'))
				if (!found) {
					__arr.forEach(() => {
						dummyEntry.push({
							cuml: 0,
							cumlLog: 0,
							d: `${month}`,
							m: null,
							v: 'Inactive',
							xAxis: `${moment(month).format('MMM YYYY')}`,
						});
					});
				} else {
					// console.log('found month ->', found);
				}
			});

			_missingMonthEntry = [...dummyEntry, ..._missingMonthEntry];

			let finalArr = [..._missingMonthEntry, ..._monthArray].sort((a, b) => {
				// return DefaultMonthsForYear.indexOf(moment(a.d).format('MMM')) - DefaultMonthsForYear.indexOf(moment(b.d).format('MMM'));
				return new Date(a.d) - new Date(b.d);
			});

			getMonthsEntryForYear = [
				...getMonthsEntryForYear,
				...finalArr,
			];
		}
	});
    
   
	// console.log(`getMonthsEntryForYear -->`, getMonthsEntryForYear);

	// hack: only to show 1year data
	let renderYearData = (getMonthsEntryForYear && getMonthsEntryForYear.length > 0) ? getMonthsEntryForYear : data;
	renderYearData = renderYearData.sort((a, b) => {
		// return DefaultMonthsForYear.indexOf(moment(a.d).format('MMM')) - DefaultMonthsForYear.indexOf(moment(b.d).format('MMM'));
		return new Date(a.d) - new Date(b.d);
	});

	/* Changes to avoid line breaking on chart*/
	let _removeData=[];
	DefaultMonthsForYear.forEach(ele=>{
		renderYearData.forEach(ele1=>{
			if(ele1.d===ele && ele1.m){
				_removeData.push(ele);
			}
		})
	})

    renderYearData=renderYearData.filter(ele=>{
		if(!ele.m && _removeData.includes(ele.d)){
			return false;
		}else{
			return true;
		}
	})
    // No Break point in between
    let _i=-1;
	for(let j=0;j<renderYearData.length;j++){
		if(renderYearData[j].m){
			_i=j;
			break;
		}
	}
	console.log("Index of I", _i);
	renderYearData= renderYearData.filter((ele,index)=>{
		if(index > _i){
			if(ele.m){
				return true;
			}else{
				return false;
			}
		}else{
			return true;
		}
	})


	console.log('renderYearData ->', renderYearData);
	//In xAxis interval='preserveStartEnd' is use to handle starting of lable from 0 the point on xAxis
	return (
		<ResponsiveContainer id={props.id || 'linechart'} width="100%" height="100%">
			<LineChart
				data={renderYearData}
				margin={{ top: 10, right: 20, left: 0, bottom: 10 }}
				>
				{/* <defs>
					{ lines.map((s, index) => (
						<linearGradient id={`color-${s.name}`} x1="0" y1="0" x2="1" y2="0" key={s.name}>
							{ segs(s.data, s.color) }
						</linearGradient>
					))}
				</defs> */}
                <CartesianGrid strokeDasharray="3 3" />
				<ReferenceLine
					y={0}
					stroke="#bbb"
					shapeRendering="crispEdges"
					/>

				<XAxis dataKey="xAxis" interval='preserveStartEnd' />

				<YAxis
					type='number'
					domain={['dataMin', 'dataMax']}
					ticks={YAxisTicks}
					tickFormatter={tickFormatter}
					/>

				<Tooltip
					content={<CustomTooltip />}
					cursor={{ stroke: '#666', strokeWidth: 1, strokeDasharray: "2 2" }}
					/>

				<Line
					dataKey={`v`}
					type="monotone"
					dot={false}
					strokeWidth={2}
					activeDot={{r: 3, strokeWidth: 1, fill: colors[1]}}
					isAnimationActive={false}
					/>

			</LineChart>
		</ResponsiveContainer>
	)
}

const CustomTooltip = props => {
  const { active, payload, label, dataKey } = props;
	// console.log(props);
  if (active && payload) {
		// console.log(payload);
    const _payload = payload.find(e => e.dataKey === 'v');
		// console.log(_payload);
    if (_payload) {
      let xVal = getNestDefault(['payload', 'v'], _payload);
			// console.log('xVal --> ', xVal);
      if (xVal === 'Inactive') {
        return (
          <div className="recharts-default-tooltip ssf-tooltip-custom">
            <div>
              <span className="label">Inactive</span>
            </div>
          </div>
        )
      } else if (getNestDefault(['payload', 'v'], _payload)) {
				let headText = getNestDefault(['payload', 'd'], _payload) ? getNestDefault(['payload', 'd'], _payload) : label;
				headText = moment(headText).format('DD MMM YYYY');
				let val = getNestDefault(['payload', 'v'], _payload, 0.0).toFixed(2);
				let _updatedVal= '$' +numberWithCommas(val)// to format the value of amount
        // 26 Jun - 30 Jun 2019 0.76%
				let color = _payload.color;
				return (
          <div className="recharts-default-tooltip ssf-tooltip-custom">
            <div>
              <span className="label">{headText}</span>
							<div key={label} className="d-flex" >
								<span className="mr-2">Amount:</span>
								<span className="ml-auto" style={{ color }}>{_updatedVal}</span>
							</div>
            </div>
          </div>
        )
      }
    } else {
			return (
				<div className="recharts-default-tooltip ssf-tooltip-custom">
					<div>
						<span className="label">Inactive</span>
					</div>
				</div>
			)
    }
  }
  return null
}

const numberWithCommas=(x)=>{
	return numFmt(parseFloat(x));
    // return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

const ReferenceLineCustomLabel = (props) => {
	const { viewBox, stroke, value } = props;
	return <text x={0} y={viewBox.y} dx={4} dy={4} fill={stroke} fontSize={10} textAnchor="start">{value}</text>
};

const suffix = ['', 'K', 'M', 'B'];
const kmb = (val, i = 0) => ((val > -100 && val < 100) || i > 2) ? `$${val.toFixed(2)}${suffix[i]}`: kmb(val / 1000, i + 1);

const tickFormatter = (value) => {
	return kmb(value);
}

const colorSegments = (data, color) => {
	const first = 0;
	const tick = 1/(data.length-1-first);
	let out = [];

	for (let i = first+1; i < data.length; i++) {
		out.push(<stop key={`s${i}`} offset={(i-first-1)*tick} stopColor={color} stopOpacity={1}/>)
		out.push(<stop key={`e${i}`} offset={(i-first)*tick} stopColor={color} stopOpacity={1}/>)
	}
	return out;
}

const CustomizedLabel = (props) => {
	const { index, size, x, y, stroke, label, dx, dy, dataKey, labelText, viewBox, data, value } = props;
	if (index === (size - 1)) {
		// console.log(props);
		return (
			<text
				id={`linechart-label-for-${dataKey}`}
				dataKey={dataKey}
				x={x}
				y={y}
				dx={4}
				dy={4}
				fill={stroke}
				fontSize={10}
				textAnchor="start"
				>
				{label}
			</text>
		)
	}
	return null;
};

export default {
	MultiSeriesLineChart,
}

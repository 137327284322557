import React, { Component, Fragment } from 'react';

import NotApplicable from '../../NotApplicable';
import GaugeChart from './GaugeChart'

const formatNumberValue = (value, fraction) => {
  const leftPart = (value + '').split('.')[0];

  if (leftPart.length >= 4 && leftPart.length < 7) {
    return (value / 1000).toFixed(fraction) + 'K';
  } else
  if (leftPart.length >= 7 && leftPart.length < 10) {
    return (value / 1000000).toFixed(fraction) + 'M';
  } else
  if (leftPart.length >= 10) {
    return (value / 1000000000).toFixed(fraction) + 'B';
  } else {
    return value.toFixed(fraction)
  }
};

const DynamicGaugeChart = (props) => {
  // console.log(tickerData);
  const { tickerData, queryVar, width } = props;
  const { view_name, Secondary_view_name, decimal, suffix, multiplier, variable } = queryVar;
  // console.log('here card', {view_name, tickerData});

  if (!tickerData) return <div className="not-applicable-icon-wrapper">
    <NotApplicable />
  </div>;

  const mult = multiplier !== '' ? multiplier : 1;
  let name = view_name + (Secondary_view_name ? ` (${Secondary_view_name})` : '');
  name = name.replace('ticker', queryVar.data);

  if(variable && variable.includes('correlation')){
    name = name.replace('Overlap', 'Correlation');
  }

  let _min = tickerData.min * mult,
  _max = tickerData.max * mult,
  _value = tickerData.value * mult;

  const deTailsWidth = width || '100px';
  return (
    <div className="siw-meter print-wrapper">
      <div className="siw-meter-title" title={name}>{name}</div>
      <GaugeChart
        chartWidth={width}
        value={_value}
        max={_max}
        min={_min}
        />
      <div className="d-flex meter-value" style={{ width: deTailsWidth, justifyContent :"space-between" }}>
        <span className="siw-meter-min">
          {_min.toFixed(decimal)}{suffix}
        </span>
        <span className="siw-meter-value">
          {_value.toFixed(decimal)}{suffix}
        </span>
        <span className="siw-meter-max">
          {_max.toFixed(decimal)}{suffix}
        </span>
      </div>
    </div>
  );
};

export default DynamicGaugeChart;

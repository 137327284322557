import React, { Component } from 'react';
import Sort from './Sort';

const classNames = require('classnames');

export default class SortFilter extends Component {
  constructor (props) {
    super(props);
    this.state = {
      sortAttr: '',
      filterAttrs: {},
      filterItems: this.filterAttrsSelection(this.props.filterAttrData),
    };
    this.onSortBtnClick = this.onSortBtnClick.bind(this);
    this.filterChange = this.filterChange.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
  }

  componentDidMount(){
    this.setActiveFilters()
  }

  setActiveFilters = () => {
    if (Object.keys(this.props.filterAttrs).length > 0) {
      const { filterAttrs } = this.props;
      let selectedFilter = {};
      Object.keys(filterAttrs).map((c) => {
        selectedFilter[c] = filterAttrs[c];
      });
      this.setState({
        filterItems: selectedFilter,
      })
    }
  }

  selected = (c, v) => {
    if (this.state && this.state.filterItems && this.state.filterItems.length) {
      let _v = this.state.filterItems[c] && this.state.filterItems[c].find(e => e.name === v);
      return _v && _v.selected;
    }
  }

  filterAttrsSelection = (obj) => {
    return Object.keys(obj)
             .reduce((p, c) => ({...p, [c]: obj[c].map(i => ({name: i, selected: this.selected(c, i)}))}), {});
  }

  onSortBtnClick(sortAttr) {
    this.setState({ sortAttr });
    this.props.sortHandler(sortAttr);
  }

  filterChange(attr, value) {
    let filterItems = JSON.parse(JSON.stringify(this.state.filterItems));
    filterItems[attr][value].selected = !filterItems[attr][value].selected;
    this.setState({ filterItems: filterItems }, () => {
      this.props.filterHandler(this.state.filterItems);
    });
  }

  resetFilters() {
    this.props.filterHandler({});
    this.setState({ filterItems: SortFilter.filterAttrsSelection(this.props.filterAttrData), filterAttrs: {} });
  }

  render() {
    return this.props.loading ? null : (
      <React.Fragment>
        <div className="toolbarWrapper" style={{ background: '#ddd', paddingTop: 10, paddingTop: 0 }}>
          <div className="container dynamic e2" style={{ height: 'auto' }}>
            <Sort
              sortAttr={this.props.sortAttr}
              sortDesc={this.props.sortDesc}
              sortAttrSelect={this.onSortBtnClick}
              filterItems={this.state.filterItems}
              filtersActive={this.state.filterAttrs}
              filterChange={this.filterChange} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

import React from 'react';
import { connect } from 'react-redux';
import { Nav, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Modal, ModalBody, ButtonGroup } from 'reactstrap';

import PremiumNoticeModal from 'components/PremiumNoticeModal';
import CSVPreviewUpload from 'components/CSVPreviewUpload';
import CustomAlert from 'components/CustomAlert';
import { Notification } from 'shared-components'
import { uniqValues, mapCsvToJSON, addEventToAnalytics, findDelimiter, convertToUpperCase, convertToLowerCase, updateTickerData, calculatePortfolioDataFormat } from 'Utils'

import { createLoadingSelector } from 'selectors/loading-selector';

import { getPortfolioStats } from 'selectors/investment'
import { investmentOptions, fetchPortfolioData, deletePortfolio, uploadPortfolio, getRealTimePriceForTickersRequest } from 'actions/investment';
import { subscriptionPlan, setProfileState } from 'actions/profile';
import { setEnhancerState, checkTickerRequest, checkSingleTickerValidityRequest, uploadCSVRequest, verifyCSVTickerAndPriceRequest, uploadCSVFileRequest, uploadCSVFileFetch, uploadCSVFileSuccess, uploadPortfolioDataByIdRequest } from 'actions/enhancer';

import UploadFile from './UploadFile';
import { Loader } from '../../Elements/Loader';
import { demoPortfolioJSON } from './utils';

import _ from 'lodash';

class UploadSection extends React.PureComponent {
  Watchlist = { name: 'Watchlist', code: 'watchlist' }
  UploadPortfolio = { label: 'Upload Portfolio', name: 'Portfolio', code: 'uplPrt' }

  constructor(props) {
    super(props);
    this.state = {
      portfolioDropdownOpen: false,
      uplPrtModal: false,
      // premiumModal: false,
      notify: false,
      isNotifyOpen: false,
      csvVisible: false,
      csvDataList: [],
      validTickers: [],
      invalidTickers: [],
      csvFormatErrorMessage: '',
      messageModal: false,
      uploadFileFor: '',
    };
  }

  componentWillReceiveProps(newProps) {
    if (((newProps.tickerNotExist && newProps.tickerNotExist.length > 0) || (newProps.tickerNotSupported && newProps.tickerNotSupported.length > 0)) && !this.state.messageModal) {
      this.setState({ messageModal: false, csvVisible: true });
    }
  }

  uploadFileFor = () => {
    const { fileFor, portfolioID } = this.props;
    this.setState({ uploadFileFor: fileFor });
    switch (fileFor) {
      case 'Demo_portfolio':
        this.uploadFileForDemo();
      break;
      case 'MarketPlace_Portfolio':
        if (document.getElementById('sponsored-portfolio-dropdown')) {
          document.getElementById('sponsored-portfolio-dropdown').click();
        }
        this.uploadFileForMarketPlace(portfolioID);
      break;
      case 'Client_Portfolio':
        if (document.getElementById('clientSearch')) {
          document.getElementById('clientSearch').click();
        }
        this.uploadFileForClientPortfolio(portfolioID);
      break;
      case 'Custom_portfolio':
        this.openUplPrt();
      break;
      default:
    }
  }

  uploadFileForClientPortfolio = (selectedClientPortfolio) => {
    console.log('--------- uploadFileForClientPortfolio ----------');
    console.log('selectedClientPortfolio =>', selectedClientPortfolio);
    const { uploadPortfolioDataByIdRequest, uploadCSVFileSuccess, verifyCSVTickerAndPriceRequest } = this.props;
    // NOTE: Step: 1, fetch Holdings data for selected MarketPlace ID
    try {
      if (selectedClientPortfolio && selectedClientPortfolio.original_portfolio && selectedClientPortfolio.name) {
        const { name: _clientPortfolioName, original_portfolio_id: _selectedClientPortfolioId, original_portfolio: _selectedClientPortfolioTicker } = selectedClientPortfolio;
        if (uploadPortfolioDataByIdRequest) {
          const requestObj = {
            portfolioType: 'Client Portfolio',
            filename: _selectedClientPortfolioTicker,
            selectedPortfolioName: _clientPortfolioName,
            selectedPortfolioTicker: _selectedClientPortfolioTicker,
            selectedPortfolioId: _selectedClientPortfolioId,
          };
          uploadPortfolioDataByIdRequest(requestObj, (res) => {
            if (res && res.csvFormatPortfolioData && res.csvFormatPortfolioData.length > 0 && res.tickers && typeof res.realTimePrice !== 'undefined') {
              console.log(res);
              const { tickers, realTimePrice, csvFormatPortfolioData } = res;
              this.setState({
                JSONData: csvFormatPortfolioData,
              }, () => {
                // NOTE: Step: 2, Map 'verifyCSVTickerAndPriceRequest' data with 'JSONData';
                if(tickers && tickers.length > 0) {
                  this.mapRealTimeToJSONData(realTimePrice);
                } else {
                  throw `-- To verifyCSVTickerAndPriceRequest, tickers ${tickers} is not valid --`;
                }
              });
            } else {
              throw `-- To fetch data, sponsored ticker ${res.tickers} or realTimePrice ${res.realTimePrice} or portfolio funds ${res.csvFormatPortfolioData} is missing --`;
            }
          });
        } else {
          throw `-- Redux actions uploadPortfolioDataByIdRequest missing --`;
        }
      } else if (selectedClientPortfolio && selectedClientPortfolio.name && selectedClientPortfolio.name === 'Demo Account') {
        console.log('=== Upload demo portfolio for client accounts ===');
        this.onFileSuccess(selectedClientPortfolio);
      } else {
        throw `-- To fetch data, sponsored ticker ${selectedClientPortfolio} is missing --`;
      }
    } catch (e) {
      console.log(e);
    }
  }

  uploadFileForMarketPlace = (selectedMarketPlaceData) => {
    console.log('--------- uploadFileForMarketPlace ----------');
    console.log('selectedMarketPlaceData =>', selectedMarketPlaceData);
    const { uploadPortfolioDataByIdRequest, uploadCSVFileSuccess, verifyCSVTickerAndPriceRequest } = this.props;
    // NOTE: Step: 1, fetch Holdings data for selected MarketPlace ID
    try {
      if (selectedMarketPlaceData && selectedMarketPlaceData.ticker && selectedMarketPlaceData.name) {
        const { name: _sponsoredPortfolioName, ticker: _selectedMarketPlaceId } = selectedMarketPlaceData;
        if (uploadPortfolioDataByIdRequest) {
          const requestObj = {
            portfolioType: 'MarketPlace',
            filename: _selectedMarketPlaceId,
            selectedPortfolioName: _sponsoredPortfolioName,
          };
          uploadPortfolioDataByIdRequest(requestObj, (res) => {
            if (res && res.csvFormatPortfolioData && res.csvFormatPortfolioData.length > 0 && res.tickers && typeof res.realTimePrice !== 'undefined') {
              const { tickers, realTimePrice, csvFormatPortfolioData } = res;
              this.setState({
                JSONData: csvFormatPortfolioData,
              }, () => {
                // NOTE: Step: 2, Map 'verifyCSVTickerAndPriceRequest' data with 'JSONData';
                if(tickers && tickers.length > 0) {
                  this.mapRealTimeToJSONData(realTimePrice);
                } else {
                  throw `-- To verifyCSVTickerAndPriceRequest, tickers ${tickers} is not valid --`;
                }
              });
            } else {
              throw `-- To fetch data, sponsored ticker ${res.tickers} or realTimePrice ${res.realTimePrice} or portfolio funds ${res.csvFormatPortfolioData} is missing --`;
            }
          });
        } else {
          throw `-- Redux actions uploadPortfolioDataByIdRequest missing --`;
        }
      } else {
        throw `-- To fetch data, sponsored ticker ${selectedMarketPlaceData} is missing --`;
      }
    } catch (e) {
      console.log(e);
    }
  }

  uploadFileForDemo = () => {
    addEventToAnalytics('Enhancer Demo','Enhancer Demo','ENHANCER_DEMO',{},false);
    console.log('--------- uploadFileForDemo ----------');
    if (demoPortfolioJSON) {
      // NOTE: Step: 1, call 'verifyCSVTickerAndPriceRequest' api for tickers
      this.setState({
        JSONData: demoPortfolioJSON,
      }, () => {
        this.processDemoPortfolioData(this.state.JSONData);
      });
    }
  }

  processDemoPortfolioData = (JSONData) => {
    const { uploadCSVFileSuccess } = this.props;
    let tickers = demoPortfolioJSON.map((r) => r.ticker);
    // NOTE: Step: 2, Map 'verifyCSVTickerAndPriceRequest' data with 'JSONData';
    if(tickers && tickers.length > 0){
      tickers = tickers.join();
      const { verifyCSVTickerAndPriceRequest } = this.props;
      if (verifyCSVTickerAndPriceRequest) {
        verifyCSVTickerAndPriceRequest({ tickers }, this.mapRealTimeToJSONData);
      }
    }
  }

  mapRealTimeToJSONData = (realTimeTickerPrice) => {
    // NOTE:
    /*
    given input of each row:
    {
    "ticker": "ACWI",
    "price": 74.53924915,
    "shares": 1172,
    "date": "25-07-2019"
    }

    After mapping expected output of each row sample:
    {
    date: "25-07-2019"
    price: 74.53924915
    realTimePrice: 80.8562
    shares: 1172
    ticker: "ACWI"
    values: 94763.4664
    weight(%): 11.87388352446596
    }
    */
    if (realTimeTickerPrice) {
      const { funds } = realTimeTickerPrice;
      let sumOfPriceAndShares = 0;
      const { portfolioValue } = this.props;
      const mappedArray = calculatePortfolioDataFormat(this.state.JSONData, realTimeTickerPrice, portfolioValue);
      console.log(mappedArray);
      if (mappedArray && mappedArray.length > 0) {
        this.setState({ csvDataList: mappedArray, csvFormatErrorMessage: '', uplPrtModal: false, csvVisible: true }, () => {
          this.showOnlyTickersAvailable();
        });
      } else {
        console.log('-- Failed to map and classify valid/invallid CSV data --');
      }
      // if (funds && typeof funds !== 'undefined') {
      //   const mappedArray = this.state.JSONData.map((kl) => {
      //     if (kl.ticker) {
      //       const found = funds.find((hk) => hk.ticker === kl.ticker);
      //       if (found) {
      //         kl.realTimePrice = found.price;
      //         kl.values = found.price * kl.shares;
      //         sumOfPriceAndShares += kl.realTimePrice * kl.shares;
      //         return kl;
      //       }
      //     }
      //   });
      //
      //   // Weights computation
      //   mappedArray.map((y, k) => {
      //     if (typeof y.shares !== 'undefined' && y.shares !== 'NA' && y.shares !== 'shares') {
      //       if (typeof y.realTimePrice !== 'undefined' && y.realTimePrice !== 'NA') {
      //         y['weight(%)'] = ((y.realTimePrice*y.shares)/sumOfPriceAndShares)*100
      //       }else {
      //         if (typeof y.price !== 'undefined' && y.price !== 'NA') {
      //           y['weight(%)'] = ((y.price * y.shares) / sumOfPriceAndShares) * 100
      //         }
      //       }
      //     } else {
      //       console.log(`------ Failed to calculate weight, SHARES is missing for ${y.ticker} ------`);
      //     }
      //   });
      //   this.setState({ csvDataList: mappedArray, csvFormatErrorMessage: '', uplPrtModal: false, csvVisible: true }, () => {
      //     this.showOnlyTickersAvailable();
      //   });
      // }
    }
  }

  openUplPrt = () => {
    addEventToAnalytics('Enhancer Upload','Enhancer Upload','ENHANCER_UPLOAD',{},false);
    this.setState({ uplPrtModal: true })
  }

  openNotify = () => {
    this.setState({
      notify: true,
    });
  }

  notifyToggle = () => {
    this.setState({
      notify: !this.state.notify,
    });
  }

  // closePremiumModal = () => {
  //   this.setState({
  //     premiumModal: false,
  //   });
  // }
  //
  // premiumModalToggle = () => {
  //   this.setState({
  //     premiumModal: !this.state.premiumModal,
  //   });
  // }

  onFileSuccess = () => {
    console.log('--- onFileSuccess ---');
    this.setState({ uplPrtModal: false, isNotifyOpen: true }, () => {
      this.csvOnClose();
      const { onFileSuccess } = this.props;
      if (onFileSuccess) {
        onFileSuccess();
      }
      setTimeout(() => {
        this.setState({ isNotifyOpen: false })
      }, 3000);
    })
  }

  getCSVData = (data, fileList) => {
    if (data) {
      var reader = new FileReader();
      reader.onload = () => {
        this.processUploadData(reader.result)
      };
      // start reading the file. When it is done, calls the onload event defined above.
      reader.readAsBinaryString(data);
    }
  }

  processUploadData = (csvData) => {
    const { uploadCSVFileSuccess, getRealTimePriceForTickersRequest, setEnhancerState } = this.props;
    this.setState({
      csvDataList: [],
      messageModal: false,
      csvVisible: false,
    }, () => {
      setEnhancerState({ tickerNotExist: [], tickerNotSupported: [] });
    });

    // console.log(csvData);

    let lines = csvData.split("\n");
    const identified_delimiter = findDelimiter(csvData);
    let tickers = '';
    let headers = lines[0].split(identified_delimiter);
    headers = headers.map(item => convertToLowerCase(item));
    let tickerIndex = headers.indexOf('ticker');

    /* eslint-disable */
    for (let i=1; i<lines.length; i++) {
      let currentline = lines[i].split(identified_delimiter);
      if(currentline[tickerIndex]){
        tickers = i == 1 ? tickers+convertToUpperCase(currentline[tickerIndex]) : tickers+','+convertToUpperCase(currentline[tickerIndex])
      }
    }

    this.setState({
      csvData
    }, () => {
      const { uploadCSVFileSuccess } = this.props;
      if (uploadCSVFileSuccess) {
        uploadCSVFileSuccess();
      }
      if(tickers){
        const { verifyCSVTickerAndPriceRequest } = this.props;
        if (verifyCSVTickerAndPriceRequest) {
          verifyCSVTickerAndPriceRequest({ tickers }, this.buildClientList);
        }
      }else{
        this.buildClientList([], true)
      }
    });
  }

  buildClientList = (realTimeTickerPrice, noData=false) => {
    const filters = { headersArray: ['ticker','price','shares','date','lot date','weight','amount'], maxHeadersCount: 6, addHeader: ['weight(%)'] };
    const { portfolioValue } = this.props;
    const clientList = JSON.parse(mapCsvToJSON(this.state.csvData, filters, realTimeTickerPrice, noData, portfolioValue));
    if (typeof clientList.error !== 'undefined') {
      this.setState({
        csvFormatErrorMessage: clientList.error || '',
      });
    } else {
      const filteredClientList = clientList.filter((item) => (item.ticker && item.ticker !== '' && item.ticker !== 'ticker'));
      this.setState({ csvDataList: filteredClientList, csvFormatErrorMessage: '', uplPrtModal: false, csvVisible: true }, () => {
        const { checkTickerRequest } = this.props;
        const tickersList = this.state.csvDataList.map((u) => {
          if (u.ticker && u.ticker !== '' && u.ticker !== 'ticker') {
            return u.ticker;
          }
        });
        this.showOnlyTickersAvailable();
      });
    }
  }

  showOnlyTickersAvailable = () => {
    const { tickerNotExist, tickerNotSupported } = this.props;
    const { csvDataList } = this.state;
    let validTickers = [];
    let invalidTickers = [];
    csvDataList.forEach((u, i) => {
      if (typeof tickerNotExist.find((k) => k === u.ticker) === 'undefined' && typeof tickerNotSupported.find((k) => k === u.ticker) === 'undefined') {
        u.key = i;
        u.validTicker = true;
        u.invalidType = 'supported';
        validTickers.push(u);
      } else {
        if (typeof tickerNotExist.find((k) => k === u.ticker) !== 'undefined') {
          u.invalidType = 'unrecognized';
          u.message = 'Ticker not identified';
        } else if (typeof tickerNotSupported.find((k) => k === u.ticker) !== 'undefined') {
          u.invalidType = 'unsupported';
          u.message = 'Ticker not supported';
        }
        u.key = i;
        u.validTicker = false;
        u.showInMissingTicker = true;
        invalidTickers.push(u);
      }
    });
    this.setState({
      validTickers,
      invalidTickers
    })
  }

  checkTicker = (ticker, callback) => {
    const { checkTickerRequest } = this.props;
    if (checkTickerRequest && ticker && callback) {
      checkTickerRequest({ tickers: [ticker] }, callback);
    }
  }

  csvOnClose = () => {
    this.props.setEnhancerState({ tickerNotExist: [], tickerNotSupported: [] });
    this.setState({
      csvVisible: false,
      csvDataList: [],
      validTickers: [],
      invalidTickers: []
    });
  }

  handleUploadSubmit = () => {
    const updatedTickers = [...this.state.validTickers, ...this.state.invalidTickers];
    // const { portfolioValue } = this.props;
    // console.log(JSON.parse(JSON.stringify(updatedTickers)));
    // updatedTickers.forEach((item, i) => {
    //   if (item.weight && typeof item.weight_calculated_format !== 'undefined' && item.weight_calculated_format === 'weight') { delete item.values; delete item.shares; delete item.amount; };
    //   if (item.shares && typeof item.weight_calculated_format !== 'undefined' && item.weight_calculated_format === 'shares') { delete item.values; delete item.weight; delete item.amount; };
    //   if (item.amount && typeof item.weight_calculated_format !== 'undefined' && item.weight_calculated_format === 'amount') { delete item.values; delete item.shares; delete item.weight; };
    // });
    // console.log(JSON.parse(JSON.stringify(updatedTickers)));
    // const allTickers = updateTickerData(updatedTickers, portfolioValue);
    // console.log(JSON.parse(JSON.stringify(allTickers)));

    let validTickers = [];
    let invalidTickers = [];
    updatedTickers.forEach((u) => {
      if(u.showInMissingTicker){
        invalidTickers.push(u)
      }else{
        validTickers.push(u)
      }
    })
    this.setState({
      validTickers,
      invalidTickers
    }, () => this.csvOnClickOk(updatedTickers));
  }

  csvOnClickOk = (dataJson) => {
    if (dataJson) {
      const {
        onboardScreenData,
        originalCSVFormatPortfolioData,
        originalCSVFormatPortfolioTickers,
        sponsoredPortfolioName,
        uploadedPortfolioTicker,
        selectedPortfolioName,
        selectedPortfolioID,
      } = this.props;
      if (onboardScreenData && onboardScreenData.selectedPortfolioType === 'MarketPlace') {
        // upload for Sponsored Portfolio
        console.log(' ==== MarketPlace CSV data ==== ');
        const reqJSONTickers = dataJson.map((jk) => jk.ticker);
        const notMatchTickers = originalCSVFormatPortfolioTickers.filter((tick) => !reqJSONTickers.includes(tick));
        //step: if TICKER has changed (or edit operation performed)
        if (notMatchTickers.length > 0) {
          // and not matched with the ones uploaded from dropdown then call 'upload-portfolio' api
          this.props.setEnhancerState({ tickerNotExist: [], tickerNotSupported: [] });
          this.setState({ messageModal: false });
          if (this.props.fileName !== '') {
            this.props.uploadCSVRequest({ data: dataJson, "file_name": this.props.fileName }, this.onFileSuccess);
          } else {
            this.props.uploadCSVRequest({ data: dataJson }, this.onFileSuccess);
          }
        } else {
          // or else "NO" change/update/edit operation performed, then call 'model-portfolio' api
          //by passing SPONSORED_PORTFOLIO_TICKER which was selected from dropdown
          if (uploadedPortfolioTicker && sponsoredPortfolioName) {
            const sponsoredObj = {
              ticker: uploadedPortfolioTicker,
              name: sponsoredPortfolioName,
            };
            // console.log(sponsoredObj);
            this.onFileSuccess(sponsoredObj);
          } else {
            console.log(`--- uploadedPortfolioTicker ${uploadedPortfolioTicker} or sponsoredPortfolioName ${sponsoredPortfolioName} is missing ---`);
          }
        }
      } else if (onboardScreenData && onboardScreenData.selectedPortfolioType === 'Client Portfolio') {
        // upload for Client Portfolio
        console.log(' ==== Client Portfolio CSV data ==== ');
        const reqJSONTickers = dataJson.map((jk) => jk.ticker);
        const notMatchTickers = originalCSVFormatPortfolioTickers.filter((tick) => !reqJSONTickers.includes(tick));
        //step: if TICKER has changed (or edit operation performed)
        if (notMatchTickers.length > 0) {
          // and not matched with the ones uploaded from dropdown then call 'upload-portfolio' api
          this.props.setEnhancerState({ tickerNotExist: [], tickerNotSupported: [] });
          this.setState({ messageModal: false });
          if (this.props.fileName !== '') {
            this.props.uploadCSVRequest({ data: dataJson, "file_name": this.props.fileName }, this.onFileSuccess);
          } else {
            this.props.uploadCSVRequest({ data: dataJson }, this.onFileSuccess);
          }
        } else {
          // or else "NO" change/update/edit operation performed, then call 'model-portfolio' api
          //by passing CLIENT_PORTFOLIO_TICKER which was selected from dropdown
          if (uploadedPortfolioTicker &&
            selectedPortfolioName &&
            selectedPortfolioName !== '' &&
            typeof selectedPortfolioName !== 'undefined' &&
            selectedPortfolioID &&
            typeof selectedPortfolioID !== 'undefined' &&
            selectedPortfolioID !== ''
          ) {
            const clientRequestObj = {
              original_portfolio: uploadedPortfolioTicker,
              original_portfolio_id: selectedPortfolioID,
            };
            // console.log(clientRequestObj);
            this.onFileSuccess(clientRequestObj);
          } else {
            console.log(`--- uploadedPortfolioTicker ${uploadedPortfolioTicker} or selectedPortfolioName ${selectedPortfolioName} is missing ---`);
          }
        }
      } else {
        // upload for My Portfolio
        this.props.setEnhancerState({ tickerNotExist: [], tickerNotSupported: [] });
        this.setState({ messageModal: false });
        if (this.props.fileName !== '') {
          this.props.uploadCSVRequest({ data: dataJson, "file_name": this.props.fileName }, this.onFileSuccess);
        } else {
          this.props.uploadCSVRequest({ data: dataJson }, this.onFileSuccess);
        }
      }
    }
  }

  onFileSuccess = (responseOBJ) => {
    console.log('--- onFileSuccess ---');
    this.setState({ uplPrtModal: false, isNotifyOpen: true }, () => {
      const { onFileSuccess } = this.props;
      if (onFileSuccess) {
        onFileSuccess(responseOBJ);
      }
      setTimeout(() => {
        this.setState({ isNotifyOpen: false })
      }, 3000);
    })
  }

  // delete operation triggers getUpdatedData
  getUpdatedData = (data) => {
    console.log(' -- delete operation triggers getUpdatedData -- ');
    const dataJson = JSON.parse(JSON.stringify(data));
    // console.log(dataJson);
    this.setState({ validTickers: dataJson }, () => {
      this.getUpdatedPortfolioDataState();
    });
  }

  closeMessageModal = (doesClientExist) => {
    this.setState({ messageModal: false, csvVisible: doesClientExist });
  }

  getTickerList = (items) => {
    return items.reduce((acc, curr, i) =>
    i !== 0 ? acc + `${items.length - 1 === i ? ` and  ` : ', '}` + curr : curr, '');
  }

  uploadModalToggle = () => {
    this.setState({
      csvFormatErrorMessage: '', // dont change this
      uplPrtModal: false,
    });
  }

  updateTickerStatus = (invalidData) => {
    const { checkSingleTickerValidityRequest } = this.props;
    const { updatedTicker, selectedRecord } = invalidData;
    if (updatedTicker && updatedTicker !== selectedRecord.ticker) {
      const invalidTickers = this.state.invalidTickers.map(item => {
        if(item.key == selectedRecord.key){
          item.loading = true;
        }
        // item.isDisabled = true;
        return item;
      })
      this.setState({
        invalidTickers
      }, () => checkSingleTickerValidityRequest(invalidData, this.updateCSVData));
    }
  }

  // edit operation triggers updateCSVData
  updateCSVData = (oldTicker, selectedRecord, updateStatus) => {
    console.log('-- edit operation triggers updateCSVData --');
    // console.log(oldTicker, selectedRecord, updateStatus);
    let invalidTickers = this.state.invalidTickers.map(obj => {
      let item = Object.assign({}, obj);
      if(item.key == selectedRecord.key) {
        item.ticker = selectedRecord.ticker;
        item.invalid = (item.invalid === true) ? !updateStatus : item.invalid;
        item.validTicker = updateStatus;
        item.message = updateStatus ? 'Valid Ticker' : 'Ticker not identified';
        item.loading = false;
        item.showInMissingTicker = true;
        item.realTimePrice = selectedRecord.realTimePrice || 'NA';
      }
      return item;
    })
    // console.log(invalidTickers);
    this.setState({
      invalidTickers
    }, () => {
      this.getUpdatedPortfolioDataState();
    })
  }

  getUpdatedPortfolioDataState = () => {
    console.log(' ---- getUpdatedPortfolioDataState ----');
    const updatedTickers = [...this.state.validTickers, ...this.state.invalidTickers];
    const { portfolioValue } = this.props;
    // console.log(JSON.parse(JSON.stringify(updatedTickers)));
    updatedTickers.forEach((item, i) => {
      if (item.weight && typeof item.weight_calculated_format !== 'undefined' && item.weight_calculated_format === 'weight') { delete item.values; delete item.shares; delete item.amount; };
      if (item.shares && typeof item.weight_calculated_format !== 'undefined' && item.weight_calculated_format === 'shares') { delete item.values; delete item.weight; delete item.amount; };
      if (item.amount && typeof item.weight_calculated_format !== 'undefined' && item.weight_calculated_format === 'amount') { delete item.values; delete item.shares; delete item.weight; };
    });
    // console.log(JSON.parse(JSON.stringify(updatedTickers)));
    const allTickers = updateTickerData(updatedTickers, portfolioValue, true); // OVER_RIDE_DATA = true;
    // console.log(JSON.parse(JSON.stringify(allTickers)));

    let validTickers = [];
    let invalidTickers = [];
    allTickers.forEach((u) => {
      if(u.showInMissingTicker){
        invalidTickers.push(u)
      }else{
        validTickers.push(u)
      }
    })
    this.setState({
      validTickers,
      invalidTickers
    });
  }

  deleteInvalidTickers = (selectedRecord) => {
    let invalidTickers = this.state.invalidTickers.filter(item => item.key !== selectedRecord.key);
    this.setState({
      invalidTickers
    }, () => {
      this.getUpdatedPortfolioDataState();
    })
  }

  render() {
    const { portfolioDropdownOpen, uplPrtModal, premiumModal, notify, isNotifyOpen, csvVisible, csvFormatErrorMessage } = this.state;
    const { premiumUser, uploadFilePartial, uploadFilePartialMsg, setEnhancerState, children, className, childrenWrapClassName, tickerNotExist, csvValidating, fileFor, portfolioLoading, prefixTag } = this.props;

    let { csvDataList, validTickers, invalidTickers } = this.state;
    let CSVPreviewUploadTOP = 60;
    const headerElement = document.getElementById("magnifi-header-wrapper");
    if (headerElement) {
      CSVPreviewUploadTOP = `${headerElement.offsetHeight}px`;
    }

    return (
      <div className={className}>
        {prefixTag}
        <div
          className={childrenWrapClassName}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.uploadFileFor()
          }}
          >
          {children}
        </div>

        {(this.state.uploadFileFor === fileFor) && (
          <div>
            <Modal
              isOpen={uplPrtModal}
              toggle={() => this.uploadModalToggle()}
              centered
              keyboard={true}
              backdrop
              className="premium-msg-modal account-modal"
              backdropClassName="premium-feature-process-modal-backdrop top-60px"
              modalClassName="top-60px"
              >
              <ModalBody className="ssf-modal__body" style={{ minHeight: 212 }}>
                <div className="premium-feature-process-modal-container">
                  <div className="header-wrapper">
                    <h1 className="text-align-left">Upload and view analytics on portfolio holdings</h1>
                  </div>
                  <i className="fal fa-times-circle premium-feature-completed-close" onClick={() => this.uploadModalToggle()}></i>
                  <p className="content-wrapper">
                    <UploadFile
                      getCSVData={this.getCSVData}
                      csvFormatErrorMessage={csvFormatErrorMessage}
                      />
                  </p>
                </div>
              </ModalBody>
            </Modal>

            {csvVisible && !this.state.messageModal && ( validTickers.length > 0 || invalidTickers.length > 0 ) && (
              <CSVPreviewUpload
                modalClassName="csv-preview-upload"
                modalTitle={<div class="title-head">Uploaded Portfolio</div>}
                width={600}
                centered={true}
                footer={null}
                maskStyle={{ top: CSVPreviewUploadTOP }}
                visble={csvVisible}
                data={validTickers}
                invalidTickers={invalidTickers}
                loading={csvValidating}
                deleteInvalidTickers={this.deleteInvalidTickers}
                onModalClose={this.csvOnClose}
                getDataList={this.getUpdatedData}
                onClickOk={this.handleUploadSubmit}
                checkTicker={this.checkTicker}
                updateTickerStatus={this.updateTickerStatus}
                disclaimerMessage={<div className="disclaimer-text mb-0"><i class="fas fa-asterisk"></i>Deleting a position will remove the security entirely from the analysis. The security's allocation will be pro-rated to the other securities in the portfolio.</div>}
              />
            )}

            {(this.props.uploadFileFailure || this.props.uploadfileDuplicate) && (
              <CustomAlert className="alert-windown-wrapper" color='danger'>
                {this.props.uploadfileDuplicate ? 'Label already exists' : this.props.uploadFileFailureMsg}
              </CustomAlert>
            )
          }

          {isNotifyOpen
            && (
              <Notification
                isNotifyOpen={isNotifyOpen}
                notifyClassName="notify-card"
                notifyBody="File uploaded successfully"
              />
            )
          }
        </div>
      )}

    </div>
  );
}
}


export default connect(
  state => ({
    premiumUser: state.auth.user.premiumUser,
    uploadFilePartial: state.enhancer.uploadFilePartial,
    uploadFilePartialMsg: state.enhancer.uploadFilePartialMsg,
    uploadFileFailure: state.enhancer.uploadFileFailure,
    uploadFileFailureMsg: state.enhancer.uploadFileFailureMsg,
    uploadfileDuplicate: state.enhancer.uploadfileDuplicate,
    tickerNotExist: state.enhancer.tickerNotExist,
    tickerNotSupported: state.enhancer.tickerNotSupported,
    csvValidating: createLoadingSelector(['VERIFY_CSV_TICKER_AND_PRICE'])({ loading: state.loading }),
    fileName: state.enhancer.fileName,
    portfolioValue: state.profile.portfolioValue,
    onboardScreenData: state.enhancer.onboardData,
    originalCSVFormatPortfolioData: state.enhancer.originalCSVFormatPortfolioData,
    originalCSVFormatPortfolioTickers: state.enhancer.originalCSVFormatPortfolioTickers,
    uploadedPortfolioTicker: state.enhancer.uploadedPortfolioTicker,
    sponsoredPortfolioName: state.enhancer.sponsoredPortfolioName,
    selectedPortfolioName: state.enhancer.portfolioName,
    selectedPortfolioID: state.enhancer.portfolioID,
  }),
  {
    investmentOptions,
    fetchPortfolioData,
    setEnhancerState,
    checkTickerRequest,
    uploadCSVRequest,
    getRealTimePriceForTickersRequest,
    checkSingleTickerValidityRequest,
    uploadCSVFileRequest,
    uploadCSVFileFetch,
    uploadCSVFileSuccess,
    verifyCSVTickerAndPriceRequest,
    uploadPortfolioDataByIdRequest,
  }
)(UploadSection);

const Spinner = () => {
  return (
    <div className="spinner-wrapper">
      <div className="text-secondary text-center">
        <i className="fal fa-circle-notch fa-4x fa-spin text-gray-5"></i>
      </div>
    </div>
  )
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Formik } from 'formik'
import * as Yup from 'yup'

import SignUp from './SignUp'
import { widgetRegister, setWidgetToken, clearError } from 'actions/register'
import ThankYouWidget from '../ThankYouWidget';
import { REDIRECT_DOMAIN_PATH } from 'DataSet';
import { addEventToAnalytics } from 'Utils';

const magnifiLogo = "/assets/images/MagnifiLogo/Magnifi_Tagline_White_RGB@2x.png";

class WidgetLogin extends Component {
  initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    url: '',
    version: '',
    termsCondition: false
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].style.paddingTop = 0;
    addEventToAnalytics('Page Loaded','Page Loaded','PAGE_LOADED',{ page: window.location.href });
  }

  handleSubmit = (values) => {
    this.props.widgetRegister(values);
    addEventToAnalytics('Widget Register Button','Widget Register Button','WIDGET_REGISTER_BUTTON',{ email:values.email });
  }

  registerSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is a required field'),
    last_name: Yup.string().required('Last name is a required field'),
    email: Yup.string()
      .email('Email must be a valid email')
      .required('Email is a required field'),
    url: Yup.string()
      .url('Enter valid URL')
      .required('URL is a required field'),
    version: Yup.string().required('API version is a required'),
    termsCondition: Yup.boolean().oneOf(
      [true],
      'Please accept terms and conditions'
    )
  })

  render() {
    const { token, pendingWidgetToken } = this.props;

    let renderDesign = (
      <Formik
        initialValues={this.initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={this.registerSchema}
        render={props => (
          <SignUp
            formProps={props}
            error={this.props.error}
            clearError={this.props.clearError}
          />
        )}
      />
    );

    if (token !== '' || pendingWidgetToken) {
      renderDesign = (
        <div>
          <ThankYouWidget {...this.props} />
        </div>
      );
    }

    return (
      <React.Fragment>
        <Row className="magnifi-auth-page-wrapper">
          <Col md="6" lg="6" className="left-side-panel-container">
            <a className="logo-wrapper" href={REDIRECT_DOMAIN_PATH}>
              <img src={magnifiLogo} height={70} alt="magnifi" />
            </a>
          </Col>
          <Col md="6" lg="6" className="right-side-panel-container">
            {renderDesign}
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ register }) => ({
  token: register.token,
  email: register.email,
  error: register.error,
  pendingWidgetToken: register.pendingWidgetToken,
})

const mapDispatchToProps = {
  widgetRegister,
  setWidgetToken,
  clearError
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WidgetLogin))


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Formik } from 'formik'
import * as Yup from 'yup'
import { REDIRECT_DOMAIN_PATH } from 'DataSet';
import  ChangePasswordPage from './ChangePasswordPage';
import { changePassword } from 'actions/auth';
import {
  hasLowerCaseRegex,
  hasUpperCaseRegex,
  hasDigitRegex,
  hasSpecialCharactersRegex,
} from 'Utils';

const magnifiLogo = "/assets/images/magnifi_by_tifin.svg";
const magnifiInvestResponsiblyLogo = '/assets/images/register-magnifi-invest-resp-logo.svg';

class ChangePwd extends Component {

      state = {
        verifyPasswordErrMsg: ''
      }

      initialValues = {
          password:'',
          confirmPassword:''
        }

      componentDidMount() {
        document.getElementsByTagName('body')[0].style.paddingTop = 0;
      }

      handleSubmit = (values, { setSubmitting }) => {
        const {changePassword,auth}=this.props;
        // console.log(values)
        try{
          if(values.password === values.confirmPassword){
            if(auth){
              this.setState({
                verifyPasswordErrMsg:""
              })
              const _token_change_pwd=auth.user.token;
              const _email=auth.user.profile.email;
              const _oldPassword=auth.old_password;
              changePassword && changePassword({email:_email,old_password:_oldPassword,values:values,token:_token_change_pwd});
            }
          }else{
            this.setState({
              verifyPasswordErrMsg:"Your password and confirm password should match."
            })
          }
          setSubmitting && setSubmitting(false);
        }catch(e){
          console.log("<----- Error in submitting Form Data ------->");
        }
      }

      changePwdSchema = Yup.object().shape({
        password: Yup.string()
        .required('Password is a required field')
        .min(8, 'Password has to be longer than 8 characters!')
        .matches(
          hasLowerCaseRegex,
          'Password should contain at least one lower case letter',
        )
        .matches(
          hasUpperCaseRegex,
          'Password should contain at least one upper case letter',
        )
        .matches(hasDigitRegex, 'Password should contain at least one number')
        .matches(
          hasSpecialCharactersRegex,
          'Password should contain atleast one special character',
        ),
        confirmPassword : Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Password is a required field'),
      })

  render() {

    let renderDesign = (
        <Formik
        initialValues={this.initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={this.changePwdSchema}
        render={props => (
          <ChangePasswordPage
          formProps={props}
          error={this.props.error}
          verifyPasswordErrMsg={this.state.verifyPasswordErrMsg}
          />
        )}
      />
    )

    return (
    <React.Fragment>
          <Row className="magnifi-auth-page-wrapper">
          <Col md="6" lg="6" className="left-side-panel-container">
            <a className="logo-wrapper" href={REDIRECT_DOMAIN_PATH}>
              <img src={process.env.REACT_APP_SUB_DOMAIN === 'retail' ? magnifiInvestResponsiblyLogo : magnifiLogo} height={70} alt="magnifi" />
            </a>
          </Col>
          <Col md="6" lg="6" className="right-side-panel-container">
            <Link className="back-to-page" to="/login">
              <i className="fas fa-long-arrow-left"></i>
            </Link>
            {renderDesign}
          </Col>
            </Row>
        </React.Fragment>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  auth
})

const mapDispatchToProps = {
  changePassword,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChangePwd))

import React, { Component } from 'react'
import { Col, Row, Form, FormGroup, Input } from 'reactstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'

import {
  CustomFormFeedback,
  PasswordRequirement,
} from 'shared-components'

import {
  hasLowerCaseRegex,
  hasUpperCaseRegex,
  hasDigitRegex,
  hasSpecialCharactersRegex,
} from 'Utils';

import style from './Profile.module.scss'

export default class ChangePassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      open: true,
      alert: true,
      showFunDetails: false,
      selectedFunDetails: undefined
    }
    this.inRef = React.createRef()
    this.bw = document.body.clientWidth
  }

  initialValue = {
    current_password: '',
    password: '',
    confirm_password: ''
  }

  passwordSchema = Yup.object().shape({
    current_password: Yup.string()
      .min(8, 'Current password has to be longer than 8 characters!')
      .required('Current password is a required field'),
    password: Yup.string()
      .required('Password is a required field')
      .min(8, 'Password has to be longer than 8 characters!')
      .matches(
        hasLowerCaseRegex,
        'Password should contain at least one lower case letter',
      )
      .matches(
        hasUpperCaseRegex,
        'Password should contain at least one upper case letter',
      )
      .matches(hasDigitRegex, 'Password should contain at least one number')
      .matches(
        hasSpecialCharactersRegex,
        'Password should contain atleast one special character',
      ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Re-enter password is a required field')
  })

  handleCancel = resetForm => {
    resetForm(this.initialValue)
    this.props.onCancel()
  }

  render() {
    return (
      <Formik
        enableReinitialize
        initialValues={this.initialValue}
        onSubmit={this.props.handleChangePasswordSubmit}
        validationSchema={this.passwordSchema}
        render={props => {
          const {
            values,
            touched,
            errors,
            // isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            // resetForm
          } = props
          return (
            <Form onSubmit={handleSubmit} noValidate={true} className={style.changePassword}>
              <span className={style.title}>Change Password</span>
              <FormGroup className={style.formGroup}>
                <Input
                  type="password"
                  name="current_password"
                  id="current_password"
                  value={values.current_password}
                  onChange={handleChange}
                  placeHolder={'Enter Current Password'}
                  onBlur={handleBlur}
                  className={
                    errors.current_password && touched.current_password
                      ? `is-invalid ${style.inputInvalid}`
                      : style.input
                  }
                />
                <CustomFormFeedback
                  formProps={props}
                  fieldName="current_password"
                />
              </FormGroup>
              <FormGroup className={style.formGroup}>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeHolder={'Enter New Password'}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.password && touched.password ? `is-invalid ${style.inputInvalid}`
                      : style.input
                  }
                />

                <CustomFormFeedback
                  formProps={props}
                  fieldName="password"
                />
              </FormGroup>
              <FormGroup className={style.formGroup}>
                <Input
                  type="password"
                  name="confirm_password"
                  id="confirm_password"
                  placeHolder={'Confirm New Password'}
                  value={values.confirm_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.confirm_password && touched.confirm_password
                      ? `is-invalid ${style.inputInvalid}`
                      : style.input
                  }
                />

                <CustomFormFeedback
                  formProps={props}
                  fieldName="confirm_password"
                />
              </FormGroup>
              <Row style={{ width: 280, margin: '0 auto' }}>
                <Col>
                  <div className="body-wrapper">
                    <FormGroup className="element-box pos-rel">
                      <PasswordRequirement
                        label={'Password Requirements:'}
                        formProps={props}
                        fieldName={'password'}
                      />
                    </FormGroup>
                  </div>
                </Col>
              </Row>
              <FormGroup>
                <button color="tools"
                        className={style.update}>
                  UPDATE PASSWORD</button>
              </FormGroup>
            </Form>
          )
        }}
      />
    )
  }
}

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { Formik } from 'formik'
import * as Yup from 'yup'
import { get } from 'lodash'
import moment from 'moment'
import qs from 'query-string'
import {
  Row,
  Col,
  Form
} from 'reactstrap'
import * as ProfileApi from '../../../apis/profile'
import { failedAppleAuth } from 'actions/auth'
import { registerProfileData } from 'actions/register'
import { Spinner } from 'shared-components/'

import { createLoadingSelector } from 'selectors/loading-selector'
import { isTermsAgreed } from 'selectors/auth-selector'
import { REDIRECT_DOMAIN_PATH } from 'DataSet'
import { getAgeMapping, investableAssetsList } from '../Constant/FormData'
import '../Register/RegisterFormOpt2.scss'
import { createSubscriptionRequest } from 'actions/profile'
import { addEventToAnalytics, searchUrlV3 } from 'Utils'

import RegistrationRequiredDetails, {
  Tagline
} from './RegistrationRequiredDetails'
import config from '../../../env'
import { PrimaryButton } from 'shared-components/'
import { Regex } from 'common/regex'
import { updateMetaInfo } from '../utils'

const magnifiLogo = '/assets/images/MagnifiLogo/Magnifi_Tagline_White_RGB@2x.png'

const magnifiInvestResponsiblyLogo = '/assets/images/register-magnifi-invest-resp-logo.svg';

class RegisterFormRHS3 extends Component {

  componentDidMount() {
    document.getElementsByTagName('body')[0].style.paddingTop = 0
    let email = localStorage.getItem('socialmail') || window.sessionStorage.getItem('socialmail') || "";
    addEventToAnalytics('Register Apple Page', 'Register Apple Page', 'REGISTER_APPLE_PAGE', {
      clickPage: 'Register Apple Page',
      email: email
    })
  }

  createTradingAccount = () => {
    this.props.history.push('/register/trading')
  }

  upgradeToPremium = () => {
    this.props.updatePlanChange(true)
  }

  validateForm(e, plan) {
    const { subscribeNow, formProps } = this.props
    const { validateForm, handleSubmit } = formProps

    subscribeNow(plan)
    handleSubmit(e)
  }

  routeToPage = () => {
    const { discoveryQuery } = this.props;
    // NOTE: Get query from discovery reducer's state
    // --------------------------------------------------------------------------------------------
    const securitiesPath = searchUrlV3(discoveryQuery); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
    // --------------------------------------------------------------------------------------------
    this.props.history.push(securitiesPath)
  }

  render() {
    const selectorText = process.env.REACT_APP_SUB_DOMAIN === 'retail' ? 'Cart' : 'Selector'

    if (
      process.env.REACT_APP_SUB_DOMAIN === 'retail' &&
      this.props.plan_selected === 'basic'
    ) {
      return (
        <Form
          name="RegisterFormRHS3"
          className={`magnifi-auth-design-container register-magnifi-auth-design ${process
            .env.REACT_APP_SUB_DOMAIN === 'retail' &&
            'd-flex flex-column justify-content-center'}`}
          noValidate={true}
        >
          <Tagline />
          <div className="header-area">
            <div className="header-label">
              You are now a registered user at magnifi.com that allows you to
              Discover a new world of investment shopping
            </div>
          </div>

          <div className="cta-wrapper registration-btn-wrapper">
            <PrimaryButton
              type="button"
              className="btn primary-btn"
              onClick={this.createTradingAccount}
            >
              Create Magnifi investment account
            </PrimaryButton>
          </div>
          {process.env.REACT_APP_SUB_DOMAIN !== 'retail' && (
            <Fragment>
              <div className="trading-benefits">
                <h1 style={{ paddingTop: `10px` }}>Upgrade to Premium</h1>
                <h2>Benefits Of premium account</h2>
                <ul className="row">
                  <li className="col-lg-6 col-12">
                    <b>{selectorText}:</b> Personalize our patented fund
                    selector to find the best fund for you among relevant funds
                    discovered.
                  </li>
                  <li className="col-lg-6 col-12">
                    <b>Builder:</b> Start with selected funds or initial
                    portfolio to build a portfolio for proposals or trades.
                  </li>
                  <li className="col-lg-6 col-12">
                    <b>Enhancer:</b> Read in client, prospect or sponsor
                    provided portfolios and evaluate enhancements with simple
                    language based queries.
                  </li>
                  <li className="col-lg-6 col-12">
                    <b>Proposals:</b> For clients to help convert and explain;
                    and for Compliance from fund selector, builder and enhancer.{' '}
                  </li>
                  <li className="col-lg-6 col-12">
                    <b>Save:</b> Results and download to spreadsheets.
                  </li>
                </ul>
              </div>
              <div className="cta-wrapper registration-btn-wrapper">
                <PrimaryButton
                  type="button"
                  onClick={this.upgradeToPremium}
                  className="btn primary-btn"
                >
                  Upgrade to Premium
                </PrimaryButton>
              </div>
            </Fragment>
          )}
          <p
            className={'skip'}
            onClick={() => {
              this.routeToPage()
            }}
          >
            Skip and Start Discovering
          </p>
        </Form>
      )
    } else {
      return null
    }
  }
}

class Register extends Component {
  state = {
    modal: true,
    showSuccess: false,
    isStripeSubscription: false,
    planName: '',
    plan_selected: '',
    isPlanChange: false,
    showStep2: false,
    openTradingRegisterPage: null,
    registerForTrade: null,
    isFetching: false,
    isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    initialValues: {
      termsCondition: false,
      country: '',
      zipcode: '',
      state: '',
      stateInput: '',
      state_value: '',
      city: '',
      address: '',
      date_of_birth: this.props.profileData.profile && this.props.profileData.profile.date_of_birth ? this.props.profileData.profile.date_of_birth : '',
      self_role:
        process.env.REACT_APP_SUB_DOMAIN === 'retail'
          ? 'Individual Investor'
          : '',
      areYouAnOther: '',
      investable_assets: this.props.profileData.profile && this.props.profileData.profile.investable_assets ? this.props.profileData.profile.investable_assets : '',
      estimated_net_worth: '',
      hear_aboutus: '',
      freeTrial: false
    }
  }

  registerSchema = Yup.object().shape({

    self_role: Yup.string().required('Please select your profile'),

    age_group: Yup.string(),

    hear_aboutus: Yup.string(),

    country: Yup.string().when('self_role', {
        is: self_role =>
        !['', 'Individual Investor', 'Other/Curious'].includes(self_role),
        then: Yup.string().required('Please select a country'),
        otherwise: Yup.string()
    }),

    zipcode: Yup.string().when('self_role', {
        is: self_role =>
        !['', 'Individual Investor', 'Other/Curious'].includes(self_role),
        then: Yup.string()
        .required('Please enter your zipcode')
        .matches(Regex.zipCode, 'zip code is not valid'),
        otherwise: Yup.string()
    }),

    date_of_birth: Yup.string()
        .required('Please select your DOB')
        .test(
          'is-valid-date',
          'Invalid Date',
          dob => moment(dob).isValid()
        )
        .test(
        'is-over-18',
        'Age must be over 18',
        dob => moment().diff(dob, 'years') >= 18
        ),

    state: Yup.string().when('self_role', {
        is: self_role =>
        !['', 'Individual Investor', 'Other/Curious'].includes(self_role),
        then: Yup.string().required('Please enter your state'),
        otherwise: Yup.string()
    }),

    city: Yup.string().when('self_role', {
        is: self_role =>
        !['', 'Individual Investor', 'Other/Curious'].includes(self_role),
        then: Yup.string().required('Please enter your city'),
        otherwise: Yup.string()
    }),

    address: Yup.string().when('self_role', {
        is: self_role =>
        !['', 'Individual Investor', 'Other/Curious'].includes(self_role),
        then: Yup.string()
        .required('This is a required field')
        .matches(Regex.pobox, 'Invalid street address')
        .max(30, 'Street must be at most 30 characters'),
        otherwise: Yup.string()
    }),

    areYouAnOther: Yup.string().when('self_role', {
        is: self_role => self_role === '',
        then: Yup.string().required('Enter a value'),
        otherwise: Yup.string()
    }),

    investable_assets: Yup.string().when('self_role', {
        is: (self_role) => ['', 'Individual Investor', 'Other/Curious'].includes(self_role),
        then: Yup.string().required('Please Select a Investable Assets'),
        otherwise: Yup.string()
    }),

    estimated_net_worth: Yup.string()

  })

  componentDidMount() {
    console.log(this.props,'here are all props')
    updateMetaInfo({ title: 'Register With Magnifi | Innovation-Based Investing & Portfolio Management', description: 'Create your free Magnifi account and get access to customized investment portfolios for you or your client. Discover a whole new world of investing opportunities today!' });
    document.getElementsByTagName('body')[0].style.paddingTop = 0
    let { plan_selected } = this.props
    this.setState({
      plan_selected: plan_selected ? plan_selected : 'basic'
    })
  }

  componentDidUpdate(prevProps) {
    const {
      stripeSessionID,
      createSubscriptionRequest,
      registerSuccess
    } = this.props
    const { isStripeSubscription } = this.state

    if (
      registerSuccess !== prevProps.registerSuccess &&
      registerSuccess &&
      !stripeSessionID
    ) {
      createSubscriptionRequest({ plan_name: this.state.planName })
    }

    if (
      prevProps.stripeSessionID !== stripeSessionID &&
      stripeSessionID &&
      isStripeSubscription
    ) {
      this.subscribeNow()
    }
  }

  componentWillUnmount(){
    updateMetaInfo();
  }

  updateUsersProfile = async (postData, callback) => {
    try {
      this.setState({ isFetching: true })
      let _responseData = await ProfileApi.updateProfile(
        qs.stringify({ ...postData })
      )
      callback({ responseData: _responseData })
      this.setState({ isFetching: false })
    } catch (error) {
      console.log(error)
      this.setState({ isFetching: false })
      if (error && typeof error.response !== 'undefined') {
        if (typeof error.response.data !== 'undefined') {
          //TO DO
        }
      }
    }
  }

  handleSubmit = (values, { setSubmitting }) => {

    const returnedToEdit= this.props.history && this.props.history.location && this.props.history.location.returnToEdit ? true : false;

    if(returnedToEdit){
      let postData = JSON.parse(JSON.stringify(this.props.profileData.profile))
      postData['investable_assets'] = values.investable_assets;
      postData['date_of_birth'] = values.date_of_birth
      ? typeof values.date_of_birth.getMonth === 'function'
        ? moment(values.date_of_birth).format('YYYY-MM-DD')
        : moment(values.date_of_birth).format('YYYY-MM-DD')
      : values.date_of_birth;
      this.updateUsersProfile(postData, ()=>{
        this.props.history.push({ pathname: '/register/trading', fromRegister: true, fromPathRouter: this.props.location.pathname })
      })

      return
    }

    window.localStorage.removeItem('socialmail');
    window.localStorage.setItem('profile_self_role', values.self_role)
    const ageValue = getAgeMapping(values.age_group)
    let { plan_selected } = this.state
    let data = {
      ...values,
      register_step_status: '1',
      age_group: ageValue || null
    }
    let isPlanChange = localStorage.getItem('isPlanChange')
      ? JSON.parse(localStorage.getItem('isPlanChange'))
      : false

    if (!isPlanChange) {
      if (plan_selected === 'pro') {
        this.subscribePlan('$250')
      } else if (plan_selected === 'pro-plus') {
        this.subscribePlan('$500')
      }
    } else {
      this.setState({
        isPlanChange: isPlanChange
      })
    }

    if (
      values.self_role === 'Individual Investor' ||
      values.self_role === 'Other/Curious'
    ) {
      data = {
        ...data,
        estimated_net_worth: null
      }
    } else if (values.self_role === 'Other/Curious') {
      data = {
        ...data,
        self_role: values.areYouAnOther
      }
    } else {
      data = {
        ...data,
        investable_assets: null
      }
    }

    const { registerAppleAuthToken, registerProfileData } = this.props

    if (registerAppleAuthToken) {
      const payload = {
        data,
        token: registerAppleAuthToken
      }

      registerProfileData(payload, () => {

        setTimeout(() => {
          if (
            plan_selected === 'basic' &&
            process.env.REACT_APP_SUB_DOMAIN === 'retail' &&
            this.state.registerForTrade === 'OPEN_TRADING_REGISTER_PAGE'
          ) {
            this.props.history.push({ pathname: '/register/trading', fromRegister: true, fromPathRouter: this.props.location.pathname });
          } else {
            // this.props.history.push('/')
            this.routeToPage();
          }
        }, 1000)

      })

      setSubmitting(false)
    }

  }

  routeToPage = () => {
    const { discoveryQuery } = this.props;
    const currentPath = get(this.props,'location.pathname','');
    // NOTE: Get query from discovery reducer's state
    // --------------------------------------------------------------------------------------------
    const securitiesPath = searchUrlV3(discoveryQuery); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
    // --------------------------------------------------------------------------------------------
    const pathname = this.props.fromPath || securitiesPath || '/'
    if (pathname !== currentPath) {
      this.props.history.push(pathname)
    }
  }

  subscribeNow = () => {
    const sessionId = this.props.stripeSessionID
    if (sessionId) {
      const stripe = window.Stripe(config.stripePublishableKey)
      stripe
        .redirectToCheckout({
          sessionId
        })
        .then(result => {
          console.log('results status', result)
        })
    }
  }

  subscribePlan = plan => {
    switch (plan) {
      case '$250':
        this.setState({
          planName: 'Premium Lite'
        })
        break
      case '$500':
        this.setState({
          planName: 'Premium Plan'
        })
        break
      default:
        this.setState({
          planName: 'Premium Lite'
        })
    }
    this.setState({ isStripeSubscription: true })
  }

  updatePlanChange = value => {
    this.setState({
      isPlanChange: value
    })
  }

  registerForTrade = (state) => {
    console.log('registerForTrade ==> ', state);
    this.setState({ registerForTrade: state });
  }

  render() {
    const {
      registerSuccess,
      match,
      profileUpdateSuccess
    } = this.props
    const {
      plan_selected,
      isPlanChange,
      showStep2
    } = this.state

    let returnedToEdit= this.props.history && this.props.history.location && this.props.history.location.returnToEdit ? true : false;

    if ((registerSuccess || profileUpdateSuccess) && isPlanChange) {
      localStorage.removeItem('isPlanChange')
      return (
        <Redirect
          to={{
            pathname: '/join',
            state: { isPlanChange: true }
          }}
        />
      )
    }

    return (
      <React.Fragment>
        <Row className="magnifi-auth-page-wrapper register-magnifi-opt-2">
          <Col className="left-side-panel-container">
            <Row style={{ flex: 7, alignItems: "center" }}>
              <a className="logo-wrapper" href={REDIRECT_DOMAIN_PATH}>
                <img src={process.env.REACT_APP_SUB_DOMAIN === 'retail' ? magnifiInvestResponsiblyLogo : magnifiLogo} height={70} alt="magnifi" />
              </a>
            </Row>
            {!this.state.isMobile && (
              <Row style={{ flex: 1 }}>
                <div className="contact-info">
                  {/* <p>Call or Text Magnifi Support: <a href="tel:7204455530">(720) 445-5530</a> | 9am - 6pm EST</p> */}
                  <p><a href="mailto:support@magnifi.com">support@magnifi.com</a> | Anytime</p>
                </div>
              </Row>
            )}
          </Col>
          <Spinner isFetching={this.state.isFetching}/>
          {!showStep2 ? (
            <Col
              md="6"
              lg="6"
              className="right-side-panel-container register-page-height"
            >
              <Formik
                initialValues={this.state.initialValues}
                onSubmit={this.handleSubmit}
                validationSchema={this.registerSchema}
                render={prop => (
                  <>
                    <RegistrationRequiredDetails
                      {...this.props}
                      formProps={prop}
                      returnedToEdit={returnedToEdit}
                      onToggle={this.handleToggle}
                      error={this.props.error}
                      subscribeNow={plan => this.subscribePlan(plan)}
                      plan_selected={plan_selected}
                      registerForTrade={this.registerForTrade}
                    />
                    {this.state.isMobile && (
                      <Row style={{ flex: 1 }}>
                        <div className="contact-info">
                          {/* <p>Call or Text Magnifi Support: <a href="tel:7204455530">(720) 445-5530</a> | 9am - 6pm EST</p> */}
                          <p><a href="mailto:support@magnifi.com">support@magnifi.com</a> | Anytime</p>
                        </div>
                      </Row>
                    )}
                  </>
                )}
              />
            </Col>
          ) : (
            <Col
              md="6"
              lg="6"
              className={`right-side-panel-container register-page-height ${process
                .env.REACT_APP_SUB_DOMAIN === 'retail' &&
                'd-flex flex-column justify-content-center'}`}
            >
              <RegisterFormRHS3
                {...this.props}
                plan_selected={plan_selected}
                updatePlanChange={this.updatePlanChange}
              />
            </Col>
          )}
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ auth, register, loading, profile, discovery: { query: discoveryQuery } }) => ({
  discoveryQuery,
  registerAppleAuthToken: register.registerAppleAuthToken,
  registerStepStatus: register.registerStepStatus,
  registerFailed: register.registerFailed,
  registerSuccess: register.registerSuccess,
  error: register.error,
  isFetching: createLoadingSelector(['REGISTER_PROFILE'])({ loading }),
  isTermsAgreed: isTermsAgreed(auth),
  loggedIn: auth.loggedIn,
  profileUpdateSuccess: profile.profileUpdateSuccess,
  stripeSessionID: profile.stripeSessionID,
  plan_selected: register.plan_selected,
  profileData: profile
})

const mapDispatchToProps = {
  failedAppleAuth,
  registerProfileData,
  createSubscriptionRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)

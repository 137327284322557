import ActionTypes from '../action-types'
import handleActions from 'redux-actions/lib/handleActions'
import { get } from 'lodash'
import { BrokersList } from '../DataSet'

const initialState = {
  selectedBroker: null,
  failedEndPoint: false,
  receivedEndPoint: false,
  brokerSetupSuccess: false,
  brokerSetupFailed: false,
  redirectEndpoint: ''
}

export const fetchImportedAccountReducer = handleActions(
  {
    [ActionTypes.FETCH_IMPORTED_ACCOUNT_REQUEST]: (state, action) => ({
      accounts: [],
      success: false,
      failed: false,
      error: null
    }),
    [ActionTypes.FETCH_IMPORTED_ACCOUNT_SUCCESS]: (state, action) => ({
      accounts: action.payload,
      success: true
    }),
    [ActionTypes.FETCH_IMPORTED_ACCOUNT_FAILURE]: (state, action) => ({
      failed: true,
      error: action.payload
    }),
    [ActionTypes.DELETE_IMPORTED_ACCOUNT_SUCCESS]: (state, action) => {
      const deleteAccount = action.payload.account_number
      const accounts = [...state.accounts].map(acc => {
        return {
          ...acc,
          accounts: acc.accounts.filter(a => a.account_number !== deleteAccount)
        }
      })

      return {
        accounts: accounts,
        success: true
      }
    }
  },
  { accounts: [], success: false, failed: false, error: null }
)

export const fetchBrokerAccountReducer = handleActions(
  {
    [ActionTypes.FETCH_BROKER_ACCOUNT_REQUEST]: (state, action) => ({
      // accounts: [], TODO: decide on while update set as default avoid blink experience
      ...state,
      fetchSuccess: false,
      fetchFailed: false,
      error: null
    }),
    [ActionTypes.FETCH_BROKER_ACCOUNT_SUCCESS]: (state, action) => ({
      accounts: action.payload,
      fetchSuccess: true
    }),
    [ActionTypes.FETCH_BROKER_ACCOUNT_FAILURE]: (state, action) => ({
      fetchFailed: true,
      error: action.payload
    })
  },
  { accounts: [], fetchSuccess: false, fetchFailed: false, error: null }
)

// POST call
export const importBrokerAccountReducer = handleActions(
  {
    [ActionTypes.IMPORT_BROKER_ACCOUNT_REQUEST]: (state, action) => ({
      ...state,
      importAccountsSuccess: false,
      importAccountsFailed: false,
      error: undefined
    }),
    [ActionTypes.IMPORT_BROKER_ACCOUNT_SUCCESS]: (state, action) => ({
      ...state,
      importAccountsSuccess: true
    }),
    [ActionTypes.IMPORT_BROKER_ACCOUNT_FAILURE]: (state, action) => ({
      ...state,
      importAccountsFailed: true,
      error: action.payload
    })
  },
  { importAccountsSuccess: false, importAccountsFailed: false, error: undefined }
)

export const setDefaultAccountReducer = handleActions(
  {
    [ActionTypes.SET_AS_DEFAULT_REQUEST]: (state, action) => ({
      success: false,
      failed: false,
      error: undefined
    }),
    [ActionTypes.SET_AS_DEFAULT_SUCCESS]: (state, action) => ({
      success: true
    }),
    [ActionTypes.SET_AS_DEFAULT_FAILURE]: (state, action) => ({
      failed: true,
      error: action.payload
    })
  },
  {}
)

export const deleteImportedAccountReducer = handleActions(
  {
    [ActionTypes.DELETE_IMPORTED_ACCOUNT_REQUEST]: (state, action) => ({
      success: false,
      failed: false,
      error: undefined
    }),
    [ActionTypes.DELETE_IMPORTED_ACCOUNT_SUCCESS]: (state, action) => ({
      success: true
    }),
    [ActionTypes.DELETE_IMPORTED_ACCOUNT_FAILURE]: (state, action) => ({
      failed: true,
      error: action.payload
    })
  },
  {}
)

export const tdameritradeReducer = handleActions(
  {
    [ActionTypes.COMPLETE_TDAMERITRADE_BROKER_SETUP_REQUEST]: (
      state,
      action
    ) => ({
      brokerSetupSuccess: false,
      brokerSetupFailed: false
    }),
    [ActionTypes.COMPLETE_TDAMERITRADE_BROKER_SETUP_SUCCESS]: (
      state,
      action
    ) => ({
      brokerSetupSuccess: true
    }),
    [ActionTypes.COMPLETE_TDAMERITRADE_BROKER_SETUP_FAILURE]: (
      state,
      action
    ) => ({
      brokerSetupFailed: true
    })
  },
  { brokerSetupSuccess: false, brokerSetupFailed: false }
)

export function broker(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SETUP_BROKER_REQUEST:
      return {
        ...state,
        selectedBroker: {
          ...action.broker
        },
        redirectEndpoint: '',
        receivedEndPoint: false,
        failedEndPoint: false,
        error: null
      }
    case ActionTypes.SETUP_BROKER_SUCCESS:
      return {
        ...state,
        receivedEndPoint: true,
        failedEndPoint: false,
        redirectEndpoint: action.response.redirect_endpoint
      }
    case ActionTypes.SETUP_BROKER_FAILURE:
      return {
        failedEndPoint: true,
        receivedEndPoint: false,
        redirectEndpoint: '',
        error: action.error
      }

    case ActionTypes.COMPLETE_BROKER_SETUP_REQUEST:
      return {
        ...state,
        brokerSetupSuccess: false,
        brokerSetupFailed: false
      }

    case ActionTypes.COMPLETE_BROKER_SETUP_SUCCESS:
      return {
        ...state,
        brokerSetupSuccess: true,
        brokerSetupFailed: false
      }

    case ActionTypes.COMPLETE_BROKER_SETUP_FAILURE:
      return {
        ...state,
        brokerSetupSuccess: false,
        brokerSetupFailed: true
      }

    default:
      return state
  }
}


// Selectors

export function aggregateNetCashBalanceSelector(accounts = []) {
  accounts.forEach(acc => {
    var broker = BrokersList.find(b => b.name === acc.broker)
    acc.imgSrc = (broker && broker.imgSrc) ? broker.imgSrc : 'unknown/broker/img';
    acc.accounts = acc.accounts || []
    acc.sumNetValue = acc.accounts.reduce(
      (total, item) => total + parseFloat(item.net_account_value || 0),
      0
    )
    acc.cashBalance = acc.accounts.reduce(
      (total, item) => total + parseFloat(item.available_cash || 0),
      0
    )
  })
  return accounts
}

const tradeItSecurityHandlingInitialState = {
  displayVerification: false,
  isFetching:false,  
  isError:true,
  error: null,  
  isVerificationSuccess: false,
  securityQuestion: '',
  securityQuestionOptions: [],
  traditVerificationToken: '',
  brokerName: ''
};
export const tradeItSecurityHandlingReducer = handleActions({
  [ActionTypes.TRADE_IT_MORE_INFO_STATUS]: (state, action) => ({
    ...tradeItSecurityHandlingInitialState,
    displayVerification: true,
    securityQuestion: get(action,'payload.data.securityQuestion',''),
    securityQuestionOptions: get(action,'payload.data.securityQuestionOptions', []),
    traditVerificationToken: get(action,'payload.data.token', ''),
    brokerName: get(action,'payload.brokerName', '')
  }),
  [ActionTypes.TRADE_IT_MORE_INFO_VEIRFY_REQUEST]: (state, action) => ({
    ...state,
    error: null,  
    isFetching: true,
  }),  
  [ActionTypes.TRADE_IT_MORE_INFO_VEIRFY_SUCCESS]: (state, action) => ({
    ...tradeItSecurityHandlingInitialState,
    isVerificationSuccess: true,
  }), 
  [ActionTypes.TRADE_IT_MORE_INFO_VEIRFY_ERROR]: (state, action) => ({
    ...state,
    isError: true,
    isFetching: false,
    error: action.payload,
  }),
  [ActionTypes.TRADE_IT_MORE_INFO_VEIRFY_RESET]: (state, action) => ({
    ...tradeItSecurityHandlingInitialState,
  }),
}, tradeItSecurityHandlingInitialState)

import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Row, Col, PopoverBody, Popover } from 'reactstrap'
import { withPopover } from 'components/Popover';
import { quintileSize, quintileClass,addEventToAnalytics } from 'Utils';
import { cn, QuinItems } from 'DataSet';
import { Quintile, Pct } from '../common';

// var classNames = require('classnames');


const ANNUALIZED = 'Annualized Volatility'


class RiskPopover2 extends Component {

  state={
    popoverOpen: false,
  }

  onHover = () => {
    !this.state.popoverOpen && this.setState({
      popoverOpen: true,
    },()=>{
      addEventToAnalytics('Risk Popup','Risk Popup','RISK_POPUP',{ query: this.props.funds.query, logId: this.props.funds.logId, ticker: this.props.card.ticker },true);
    })
  }

  onHoverLeave = () => {
    this.state.popoverOpen &&this.setState({
      popoverOpen: false,
    })
  }

  toggle=()=> {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    })
  }

  onMouseOver = () => {
    try {
      const element = document.querySelector('.popover-drop__item');
      element.style.display = 'block';
    } catch (e) {
      console.log(e);
    }
  }

  onMouseOverItem = () => {
    try {
      const element = document.querySelector('.popover-drop__item');
      element.style.display = 'block';
    } catch (e) {
      console.log(e);
    }
  }

  onMouseLeave = () => {
    try {
      const element = document.querySelector('.popover-drop__item');
      element.style.display = 'none';
    } catch (e) {
      console.log(e);
    }
  }

  // setItem = (e) => {
  //   const { card, funds } = this.props;
  //   const val = e.target.id;
  //
  //   const element = document.querySelector('.pop-toggle-name');
  //   element.innerHTML = val;
  //   document.querySelector('.popover-drop__item').style.display = 'none';
  //
  //   if (val === 'Annualized Volatility') {
  //     const element1 = document.querySelector('.popover-drop__item__wrapper');
  //     element1.innerHTML = `<li class="popover-drop__item__wrapper__list" id="Drawdown">Drawdown</li>`
  //     const elems1 = document.querySelectorAll(".drawdown");
  //     [].forEach.call(elems1, function (el) {
  //       el.style.display = 'none';
  //     });
  //     const elems2 = document.querySelectorAll(".annualized-volatility");
  //     [].forEach.call(elems2, function (el) {
  //       el.style.display = 'block';
  //     });
  //   } else {
  //     const element1 = document.querySelector('.popover-drop__item__wrapper');
  //     element1.innerHTML = `<li class="popover-drop__item__wrapper__list" id="Annualized Volatility">Annualized Volatility</li>`
  //     const elems1 = document.querySelectorAll(".annualized-volatility");
  //     [].forEach.call(elems1, function (el) {
  //       el.style.display = 'none';
  //     });
  //     const elems2 = document.querySelectorAll(".drawdown");
  //     [].forEach.call(elems2, function (el) {
  //       el.style.display = 'block';
  //     });
  //   }
  //   addEventToAnalytics('Risk Popup -- Dropdown','Risk Popup -- Dropdown','RISK_POPUP -- DROPDOWN',{ query: funds.query, logId: funds.logId, optionSelected: val, card: card.ticker },true);
  //
  //   this.addEvent();
  // }
  //
  // addEvent = () => {
  //   const abc = document.querySelector('.popover-drop__item__wrapper__list');
  //   if (abc !== null) {
  //     abc.addEventListener('click', this.setItem)
  //   }
  // }

  render() {
    const { card, highlight, checkPopOpen, firstPop, funds ,setRiskPopover,user_risk_prefer} = this.props;
    const i = 4;
    const blueUrl = `/assets/images/icons/blue-heart-rate-${quintileSize(card._stats.voltQ, QuinItems[i].reverse)}.svg`;
    const iconUrl = `/assets/images/icons/heart-rate-${quintileSize(card._stats.voltQ, QuinItems[i].reverse)}.svg`;

    const hikeLabel = user_risk_prefer===ANNUALIZED?(quintileSize(card._stats.voltQ, QuinItems[i].reverse) === 2 ? 'Average' : (quintileSize(card._stats.voltQ, QuinItems[i].reverse) === 3 ? 'High' : 'Low')):(quintileSize(card._stats.dMaxQ, QuinItems[i].reverse) === 2 ? 'Average' : (quintileSize(card._stats.dMaxQ, QuinItems[i].reverse) === 3 ? 'High' : 'Low'));
    // const statusColor = quintileClass(card._stats.voltQ, QuinItems[i].reverse) === 'danger' ? '#ff4d4d' : (quintileClass(card._stats.voltQ, QuinItems[i].reverse) === 'success' ? '#416D60' : '#1b8be0');
    const statusText = user_risk_prefer===ANNUALIZED ? card._stats.volt.toFixed(2) + "%":card._stats.dMax.toFixed(2) + "%";

    // const e1 = () => (
    //   <div className="risk-flag-color">
    //     <img src={highlight ? blueUrl : (card._flags.risksMin ? blueUrl : iconUrl)} width="22" alt="Risk" style={{ marginBottom: '3px' }} />
    //     <p className="__val" style={{ margin: 0, fontSize: 11, lineHeight: 1, fontWeight: 'bold' }}>
    //       {statusText}
    //     </p>
    //     <p className="__state" style={{ margin: 0, fontSize: 10, lineHeight: 1 }}>
    //       {hikeLabel}
    //     </p>
    //   </div>
    // );

    // const e2 = () => (
    //   <React.Fragment>
    //     <PopoverBody className="p-0">
    //       <Row className="no-gutters">
    //         {/* <Col className="col-12 p-2 pt-3 d-flex justify-content-between align-items-center">
    //           <h4 className="fs-17 text-ssf-blue-d2 mb-1">{'Risk'}<span className="h6 font-three text-gray-7 ml-2">{card._stats.start + ' - ' + card._stats.end}</span></h4>
    //         </Col> */}
    //         <Col className="col-12 p-2 text-gray-9 bg-light br-2">
    //           <div className={classNames("d-flex justify-content-between align-items-center")} style={{ lineHeight: 1 }}>
    //
    //             <div className="popover-drop">
    //               <div className="popover-drop__toggle" onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave} >
    //                 <span className="pop-toggle-name">Annualized Volatility</span> <i className="fas fa-caret-down"></i>
    //               </div>
    //               <div className="popover-drop__item" onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}>
    //                 <ul className="popover-drop__item__wrapper">
    //                   {/* <li className="popover-drop__item__wrapper__list" onClick={() => this.setItem('Annualized Volatility')}>Annualized Volatility</li> */}
    //                   <li className="popover-drop__item__wrapper__list" id="Drawdown">Drawdown</li>
    //                 </ul>
    //               </div>
    //             </div>
    //             <span className="font-weight-bold annualized-volatility">{card._stats.volt.toFixed(2)}<Pct /></span>
    //             <span className="font-weight-bold drawdown">{card._stats.dMax.toFixed(2)}<Pct /></span>
    //           </div>
    //
    //           <div className="annualized-volatility">
    //             <Quintile cardCategory={cn(card, 'Category')} date={`${card._stats.start} - ${card._stats.end}`} item={QuinItems[i]} value={card._stats.voltQ}
    //               catValue={<span>{card._cstats.volt.toFixed(2)}<Pct /></span>}
    //               bg={quintileClass(card._stats.voltQ, QuinItems[i].reverse)} />
    //           </div>
    //           <div className="drawdown">
    //             <Quintile cardCategory={cn(card, 'Category')} date={`${card._stats.start} - ${card._stats.end}`} item={QuinItems[i]} value={card._stats.dMaxQ}
    //               catValue={<span>{card._cstats.dMax.toFixed(2)}<Pct /></span>}
    //               bg={quintileClass(card._stats.dMaxQ, QuinItems[i].reverse)} />
    //           </div>
    //         </Col>
    //       </Row>
    //     </PopoverBody>
    //     {this.addEvent()}
    //   </React.Fragment>
    // );

    const highlightClassName = highlight ? 'search-risk-stats-padding search-risk-stats-highlighting' : (card._flags.risksMin ? 'search-risk-stats-padding search-risk-stats-highlighting' : 'search-risk-stats-padding');

    return (
      <div
        className={highlightClassName}
        onMouseEnter={this.onHover}
        onMouseLeave={this.onHoverLeave}>
        <div id={"RiskPopover-" + cn(card, 'Id')} className="w-100">
         <div className="risk-flag-color">
           <img src={highlight ? blueUrl : (card._flags.risksMin ? blueUrl : iconUrl)} width="22" alt="Risk" style={{ marginBottom: '3px' }} />
           <p className="__val" style={{ margin: 0, fontSize: 11, lineHeight: 1, fontWeight: 'bold' }}>{statusText}</p>
           <p className="__state" style={{ margin: 0, fontSize: 10, lineHeight: 1 }}>{hikeLabel}</p>
         </div>
        </div>
        <Popover
          toggle={this.toggle}
          isOpen={this.state.popoverOpen}
          trigger={'hover'} placement="right"
          target={"RiskPopover-" + cn(card, 'Id')} >
          <div className="pop-wrapper">
            <PopoverBody className="p-0">
            <Row className="no-gutters">
              <Col className="col-12 p-2 text-gray-9 bg-light br-2">
                <div className={classNames("d-flex justify-content-between align-items-center")} style={{ lineHeight: 1 }}>
                  <div className="popover-drop">
                    <div className="popover-drop__toggle" onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave} >
                      <span className="pop-toggle-name">{`${user_risk_prefer===ANNUALIZED?`Annualized Volatility`:'Drawdown'}`}</span> <i className="fas fa-caret-down"></i>
                    </div>
                    <div className="popover-drop__item" onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}>
                      <ul className="popover-drop__item__wrapper">
                        {user_risk_prefer===ANNUALIZED? <li  onClick={() => setRiskPopover('Drawdown')} className="popover-drop__item__wrapper__list" id="Drawdown">Drawdown</li>:
                          <li onClick={() => setRiskPopover('Annualized Volatility')}  className="popover-drop__item__wrapper__list" id="Annualized Volatility">Annualized Volatility</li>}
                      </ul>
                    </div>
                  </div>
                  {user_risk_prefer===ANNUALIZED?<span className="font-weight-bold ">{card._stats.volt.toFixed(2)}<Pct /></span>:
                  <span className="font-weight-bold ">{card._stats.dMax.toFixed(2)}<Pct /></span>}
                </div>

                {user_risk_prefer===ANNUALIZED?<div className="">
                  <Quintile cardCategory={cn(card, 'Category')} date={`${card._stats.start} - ${card._stats.end}`} item={QuinItems[i]} value={card._stats.voltQ}
                            catValue={<span>{card._cstats.volt.toFixed(2)}<Pct /></span>}
                            bg={quintileClass(card._stats.voltQ, QuinItems[i].reverse)} />
                </div>:
                <div className="">
                  <Quintile cardCategory={cn(card, 'Category')} date={`${card._stats.start} - ${card._stats.end}`} item={QuinItems[i]} value={card._stats.dMaxQ}
                            catValue={<span>{card._cstats.dMax.toFixed(2)}<Pct /></span>}
                            bg={quintileClass(card._stats.dMaxQ, QuinItems[i].reverse)} />
                </div>}
              </Col>
            </Row>
          </PopoverBody>
          </div>
        </Popover>
      </div>
    );
  }
}


const mapStateToProps = ({ funds }) => ({
  funds
})

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiskPopover2)

import React, { Component } from 'react';

class AlertFeeds extends Component {
  constructor(props){
    super(props);
		// this.slider = React.createRef();
  }

	executeAlertClick = (alertData) => {
		const { onClickAlert } = this.props;
		if (onClickAlert) {
			onClickAlert(alertData, 'alert_click');
		}
	}

  render() {
    const { data } = this.props;

		if ((data && data.length === 0) || !data) return null;

    return (
      <React.Fragment>
				<div id="accounts-alert-feeds-container" className="accounts-alert-feeds-container">
					{
						/* we can use alert_error instead of alert_success according to condition */
						data && data.map((obj, idx) => {
							return (
								<div
									key={idx}
									className={`accounts-alert-feeds-wrapper accounts-alert-${obj.is_active ? 'success' : 'error'}`}
									onClick={() => this.executeAlertClick(obj)}
							  >
									<div className="alert-title">
										{obj.title}
									</div>
									<div className="alert-desc">
										{obj.description}
									</div>
								</div>
							)
						})
					}
				 </div>
      </React.Fragment>
    )
  }
}

export default AlertFeeds;

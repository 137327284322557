import React from 'react';

const BestTickerBadge = (props) => {
  const { style } = props;
  return (<></>);
  return(
    <img
      style={style}
      src={'/assets/images/badge.svg'}
      height="26"
      alt={'badge'}
    />
  )
};

export default BestTickerBadge;

import React, { Component } from 'react'
import moment from 'moment'
import ErrorBoundary from 'components/shared/ErrorBoundary'
import IndexPage from './Pages/IndexPage'
import ContentTable from './Pages/ContentTable'
import AllReplacement from './Pages/AllReplacement'
import SingleReplacement from './Pages/SingleReplacement'
import { GlossaryPage2 } from '../../PortfolioReportV2/sf/Glossary'
import { createAPIInstance } from 'apis/api'
// import { Disclosures } from './Pages/Disclosures';
// import { haveHTMLData } from 'Utils';

export const fetchReportData = async requestQuery => {
  return createAPIInstance()
    .get('/user/get-compare-reports', { params: { id: requestQuery } })
    .then(response => response.data)
}

const equalSplit = (array, n) => {
  let [...arr] = array
  var res = []
  while (arr.length) {
    res.push(arr.splice(0, n))
  }
  return res
}
const SPLIT_CONTENT_PAGE = 20
const SPLIT_ALL_REPLACE_PAGE = 10

class RecommendationReport extends Component {
  state = {
    list: null
  }

  async componentDidMount() {
    // let { data, logo, queryEnglish, positionsOut: positions } = JSON.parse(localStorage.getItem('rec'))
    //8 for initial page as it has heading and all
    //
    // let replacementChosen = data.filter(v => v.ticker !== v.selected.ticker)
    // let noReplacementChosen = data.filter(v => v.ticker === v.selected.ticker && v.result.funds.length !== 0)
    // let noReplacementFound = data.filter(v => v.ticker === v.selected.ticker && v.result.funds.length === 0)
    // const split = [replacementChosen.slice(0, 8), ...equalSplit(replacementChosen.slice(8), SPLIT_ALL_REPLACE_PAGE)]
    // //equal 20 split.
    // const split20 = equalSplit(replacementChosen, SPLIT_CONTENT_PAGE)
    // this.setState({
    //   list: data,
    //   replacementChosen,
    //   noReplacementChosen,
    //   noReplacementFound,
    //   logo,
    //   queryEnglish,
    //   positions,
    //   split,
    //   split20
    // }, () => {
    //   document.body.classList.add('recommendation-report')
    // })

    // const query = new URLSearchParams(this.props.location.search)
    // const rid = query.get('id')
    // if (!rid) return
    // let { data, logo, queryEnglish, positionsOut: positions } = await fetchReportData(rid).then(reportData => reportData.message !== 'No Data Present' && JSON.parse(reportData[0].json))
    let {
      data = [],
      logo,
      queryEnglish,
      positionsOut: positions,
      invalidTickerData,
      tickerNotSupported,
      replacementNotChosen: noReplacementChosen,
      replacementNotFound: noReplacementFound,
      portfolioName
    } = this.props.data;

    let textWithOnlyTicker = null, textWithOnlyTickerTNS = null;
    let replacementChosen = data.filter(v => v.ticker !== v.selected.ticker)

    //all replacement page is 8 on first page and 10 on other pages.
    const split = [
      replacementChosen.slice(0, 8),
      ...equalSplit(replacementChosen.slice(8), SPLIT_ALL_REPLACE_PAGE)
    ]
    //equal 20 split for content page
    const split20 = equalSplit(replacementChosen, SPLIT_CONTENT_PAGE)
    if (
      invalidTickerData !== undefined &&
      typeof invalidTickerData !== 'undefined' &&
      invalidTickerData !== 'undefined' &&
      invalidTickerData &&
      invalidTickerData.textWithOnlyTicker &&
      typeof invalidTickerData.textWithOnlyTicker !== 'undefined'
    ) {
      textWithOnlyTicker = invalidTickerData.textWithOnlyTicker
    }
    if (
      tickerNotSupported !== undefined &&
      typeof tickerNotSupported !== 'undefined' &&
      tickerNotSupported !== 'undefined' &&
      tickerNotSupported &&
      tickerNotSupported.textWithOnlyTicker &&
      typeof tickerNotSupported.textWithOnlyTicker !== 'undefined'
    ) {
      textWithOnlyTickerTNS = tickerNotSupported.textWithOnlyTicker
    }
    this.setState(
      {
        list: data,
        replacementChosen,
        noReplacementChosen,
        noReplacementFound,
        logo,
        queryEnglish,
        positions,
        split,
        split20,
        inValidTicker: textWithOnlyTicker,
        tickerNotSupported: textWithOnlyTickerTNS,
        portfolioName: portfolioName
      },
      () => {
        document.body.classList.add('recommendation-report')
      }
    )
  }

  componentWillUnmount() {
    document.body.classList.remove('recommendation-report')
  }

  render() {
    let {
      logo,
      list,
      queryEnglish,
      positions,
      split,
      split20,
      replacementChosen,
      noReplacementChosen,
      noReplacementFound,
      inValidTicker,
      tickerNotSupported,
      portfolioName
    } = this.state
    const { disclosures, data: _FUNDS_RECOMMENDATION_DATA } = this.props.data
    // console.log('---- RecommendationReport ----')
    // console.log(this.props.data)
    // console.log('------------------------------')
    let createdDate = moment(
      new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })
    ).format('MM/DD/YYYY')
    if (_FUNDS_RECOMMENDATION_DATA && _FUNDS_RECOMMENDATION_DATA.length) {
      const foundFeedDateFund = _FUNDS_RECOMMENDATION_DATA.find(
        item => item && item.selected && item.selected.feed_date
      )
      if (
        foundFeedDateFund &&
        foundFeedDateFund.selected &&
        foundFeedDateFund.selected.feed_date
      ) {
        createdDate = moment(foundFeedDateFund.selected.feed_date).format(
          'MM/DD/YYYY'
        )
      }
    }

    return (
      <ErrorBoundary>
        <IndexPage
          disclosures={disclosures}
          createdDate={createdDate}
          logo={logo}
          portfolioName={portfolioName}
        />
        {list && (
          <div className="container h-100">
            {split20 &&
              split20.map((list, i) => {
                return (
                  <ContentTable
                    index={i}
                    list={list}
                    logo={logo}
                    split={split}
                    split20={split20}
                    createdDate={createdDate}
                    SPLIT_CONTENT_PAGE={SPLIT_CONTENT_PAGE}
                    first={i === 0}
                    replacementChosen={replacementChosen}
                    noReplacementFound={noReplacementFound}
                    noReplacementChosen={noReplacementChosen}
                  />
                )
              })}

            {split &&
              split.map((list_8, i) => {
                return (
                  <AllReplacement
                    index={i}
                    split20={split20}
                    logo={logo}
                    allTicker={list}
                    queryEnglish={queryEnglish}
                    positions={positions}
                    last={i === split.length - 1}
                    first={i === 0}
                    createdDate={createdDate}
                    replacementChosen={replacementChosen}
                    noReplacementChosen={noReplacementChosen}
                    noReplacementFound={noReplacementFound}
                    list={list_8}
                    inValidTicker={inValidTicker}
                    tickerNotSupported={tickerNotSupported}
                  />
                )
              })}

            {replacementChosen &&
              replacementChosen.map(singleTickerData => (
                <SingleReplacement
                  logo={logo}
                  split20={split20}
                  data={singleTickerData}
                  createdDate={createdDate}
                />
              ))}
            <div className="portfolio-reports-v2">
              <GlossaryPage2 createdDate={createdDate} />
            </div>
            {/*disclosures section*/}
            {/* disclosures && disclosures.length > 0 && haveHTMLData(disclosures) && <Disclosures createdDate={createdDate} disclosure={disclosures}/> */}
            {/*<GlossaryPage2/>*/}
            {/* <GlossaryPage3/>
          <GlossaryPage4/> */}
          </div>
        )}
      </ErrorBoundary>
    )
  }
}

export default RecommendationReport

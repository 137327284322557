import React from 'react'
import { connect } from 'react-redux'
// import {
//   Alert,
//   Col,
//   Collapse,
//   Row,
//   Container,
//   Form,
//   FormGroup,
//   Label,
//   Button,
//   Input
// } from 'reactstrap'
// import { PrimaryButton } from 'shared-components'
import { Redirect } from 'react-router-dom'
import qs from 'query-string'

import UserPlanType from './UserPlanType'
import ProfilePricingPlans from './ProfilePricingPlans'
// import PremiumFeatureDetails from './PremiumFeatureDetails'

class SubscriptionPlanDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPlanDetails: true
    }
    this.changePlan = this.changePlan.bind(this);
  }

  changePlan() {
  }

  render() {
    const query = qs.parse(this.props.location.search)
    if (query.paymentStatus) { window.sessionStorage.setItem('paymentStatus', query.paymentStatus) }
    return (
      <React.Fragment>
        {(query.paymentStatus) && (
          <Redirect to='/settings/subscriptionPlan' />
        )}
        <UserPlanType changePlan={this.changePlan} userDetails={this.props.userDetails} />
        {(this.state.showPlanDetails) && (
          <div>
            <ProfilePricingPlans />
          </div>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  userDetails: auth.user
})

export default connect(
  mapStateToProps
)(SubscriptionPlanDetails);

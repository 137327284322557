import React from 'react'
import {
  Alert,
  Col,
  Collapse,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Button,
  Input
} from 'reactstrap'
import { PrimaryButton } from 'shared-components'

class SubscriptionCancellationSuccess extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <React.Fragment>
        <div className="premium-feature-process-modal-container">
          <div className="header-wrapper">
            <h1 className="text-align-left premium-feature-main-heading">Premium subscription cancelled</h1>
          </div>
          <i className="fal fa-times-circle premium-feature-completed-close close-light" onClick={() => {this.props.changeUnsubscribeModalStatus(false)}}></i>
          <div className="pcm__description">
            <p>You can update to the premium plan from my subscription tab to access the features again.</p>
          </div>
          <div className="profile__subscription-button">
            <Container>
              <Row>
                <Col xs={12}>
                  <FormGroup className="pcm__button-group">
                    <Button color="tools"
                      className="btn h3 text-uppercase font-weight-bold p-3 mb-0 ml-auto primary-btn"
                      onClick={() => {this.props.changeUnsubscribeModalStatus(false)}}
                    >
                      CONTINUE
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default SubscriptionCancellationSuccess

import ActionTypes from '../action-types'

export function dymLogs(payload) {
  console.log('dymLogs -=-----');
  return {
    type: ActionTypes.DYM_LOGS,
    payload,
  }
}

export function fetchFundsPrice(payload) {
  return {
    type: ActionTypes.UPDATE_FUNDS_DATA,
    payload,
  }
}

export function updateFundsData(payload) {
  return {
    type: ActionTypes.UPDATE_FUNDS_DATA,
    payload,
  }
}

export function refreshFundsState(payload) {
  return {
    type: ActionTypes.REFRESH_FUNDS_STATE,
    payload,
  }
}

export function setFundsState(payload) {
  return {
    type: ActionTypes.SET_FUNDS_STATE,
    payload,
  }
}

export function newQuery(query) {
  return {
    type: ActionTypes.FUNDS_QUERY,
    query,
  }
}

export function searchQuery(payload) {
  return {
    type: ActionTypes.SEARCH_QUERY,
    payload,
  }
}

export function setUniverse(payload) {
  return {
    type: ActionTypes.SET_UNIVERSE,
    payload,
  }
}

export function searchAnalyticsAPI(payload) {
  return {
    type: ActionTypes.SEARCH_ANALYTICS_API,
    payload,
  }
}

export function setThemeValue(payload){
  return{
    type:ActionTypes.SET_THEME_VALUE,
    payload,
  }
}

export function setSponsoredValue(payload){
  return{
    type:ActionTypes.SET_SPONSRED_VALUE,
    payload,
  }
}


export function getTrendingFeeds(){
  return{
    type:ActionTypes.TRENDING_QUERIES_REQUEST,
  }
}

export function fetchTrendingFeeds(){
  return{
    type:ActionTypes.TRENDING_QUERIES_FETCH,
  }
}

export function trendingFeedsResponse(payload){
  return{
    type:ActionTypes.TRENDING_QUERIES_SUCCES,
    payload,
  }
}

export function getChartDataForAllFunds(payload){
  return{
    type:ActionTypes.GET_CHART_DATA_FOR_ALL_FUNDS,
    payload,
  }
}

export function updateSelFundsInCrd(payload){
  return{
    type:ActionTypes.UPDATE_SEL_FUNDS_IN_CRD,
    payload,
  }
}

export function updateChartReportData(payload){
  return{
    type:ActionTypes.UPDATE_CHART_REPORT_DATA,
    payload,
  }
}

/* -- Download CSV of Searched Results -- */
export function downloadSearchedResultsRequest(payload, callback){
  return {
    type: ActionTypes.DOWNLOAD_SEARCHED_RESULTS_REQUEST,
    payload,
    callback,
  }
}

export function downloadSearchedResultsFetch(payload){
  return {
    type:ActionTypes.DOWNLOAD_SEARCHED_RESULTS_FETCH,
    payload,
  }
}

export function downloadSearchedResultsSuccess(payload){
  return {
    type:ActionTypes.DOWNLOAD_SEARCHED_RESULTS_SUCCESS,
    payload,
  }
}

/* SELECTOR WITH TIME FRAME */
export function selectorWithTimeFrameRequest(payload, callback){
  return {
    type: ActionTypes.SELECTOR_WITH_TIME_FRAME_REQUEST,
    payload,
    callback,
  }
}

export function selectorWithTimeFrameFetch(payload){
  return {
    type:ActionTypes.SELECTOR_WITH_TIME_FRAME_FETCH,
    payload,
  }
}

export function selectorWithTimeFrameSuccess(payload){
  return {
    type:ActionTypes.SELECTOR_WITH_TIME_FRAME_SUCCESS,
    payload,
  }
}

/* UPDATED EACH FUND SELECTOR STATE based on SFM selected ticker */
export function updatedFundsSelectorState(payload){
  return {
    type:ActionTypes.UPDATE_FUNDS_SELECTOR_STATE,
    payload,
  }
}

/* REMOVE FUND or set _selector = false for ticker */
export function removeFundsSelectorState(payload){
  return {
    type:ActionTypes.REMOVE_FUNDS_SELECTOR_STATE,
    payload,
  }
}

/* REMOVE ALL FUND or set _selector = false for ticker */
export function removeAllFundsSelectorState(payload){
  return {
    type:ActionTypes.REMOVE_ALL_FUNDS_SELECTOR_STATE,
    payload,
  }
}

/* RESET_FUNDS_UNIVERSE_STATE */
export function refreshFundsUniverseState(){
  return {
    type:ActionTypes.RESET_FUNDS_UNIVERSE_STATE,
  }
}
//
// export function executeMagnifiOrderRequest(payload){
//   return {
//     type: ActionTypes.EXECUTE_MAGNIFI_BROKER_ORDERS_REQUEST,
//     payload,
//   }
// }
//
// export function executeMagnifiOrderFetch(payload){
//   return {
//     type:ActionTypes.EXECUTE_MAGNIFI_BROKER_ORDERS_FETCH,
//     payload,
//   }
// }
//
// export function  executeMagnifiOrderSuccess(payload){
//   return {
//     type:ActionTypes.EXECUTE_MAGNIFI_BROKER_ORDERS_SUCCESS,
//     payload,
//   }
// }

/* SAVE DISCOVER POPUP  OPTIONS*/
export function savePopoverOption(payload){
  return{
    type:ActionTypes.SAVE_POPOVER_OPTION,
    payload
  }
}

import React from 'react'
import { connect } from 'react-redux'
import { reactivateSubcriptionRequest } from 'actions/profile'
import { Alert, Col, Collapse, Row, Container, Form, FormGroup, Label, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup } from 'reactstrap'
import { PrimaryButton } from 'shared-components'
import SubscriptionReactivationRequest from './SubscriptionReactivationRequest'
import SubscriptionReactivationSuccess from './SubscriptionReactivationSuccess'
import SubscriptionReactivationFailure from './SubscriptionReactivationFailure'

class ProfileReactivationModal extends React.Component {
  constructor(props) {
    super(props)
    this.reactivateSub = this.reactivateSub.bind(this);
  }

  reactivateSub(){
    const subscription_id = this.props.subscription.subscription_id;
    if(subscription_id){
      this.props.reactivateSubcriptionRequest(this.props.subscription);
    }
  }

  render() {

    let showContent = '';

    if(!this.props.reactivateSubscriptionStatus && !this.props.reactivateSubscriptionError){
      // Show the Reactivation confirmation modal
      showContent = <SubscriptionReactivationRequest changeReactivatePlanModalStatus={this.props.changeReactivatePlanModalStatus} reactivateSub={this.reactivateSub} subscriptionID={this.props.subscription&&this.props.subscription.subscription_id} />
    }else if(this.props.reactivateSubscriptionStatus && !this.props.reactivateSubscriptionError){
      // Display message on successful Reactivation
      showContent = <SubscriptionReactivationSuccess changeReactivatePlanModalStatus={this.props.changeReactivatePlanModalStatus} />
    }else{
      // Show Reactivation error message
      showContent = <SubscriptionReactivationFailure changeReactivatePlanModalStatus={this.props.changeReactivatePlanModalStatus} />
    }

    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.modalStatus}
          toggle={() => {this.props.changeReactivatePlanModalStatus(false)}}
          centered
          keyboard={true}
          backdrop
          className="premium-msg-modal-sm-light"
          backdropClassName="premium-feature-process-modal-backdrop"
          >
          <ModalBody className="ssf-modal__body">
            { showContent }
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ profile }) => ({
  subscription: profile.profile.subscription,
  reactivateSubscriptionStatus: profile.reactivateSubscriptionStatus,
  reactivateSubscriptionError: profile.reactivateSubscriptionError
})

const mapDispatchToProps = {
  reactivateSubcriptionRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileReactivationModal);

import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import Slider from "react-slick";
import PerfectScrollbar from 'perfect-scrollbar';
import { Card } from 'antd';

import ItemCard from './ItemCard';
import { arrayToHash } from 'Utils';
import { CN  } from 'DataSet';

import { RightOutlined, LeftOutlined } from '@ant-design/icons';

import { replaceCurrentModelPortfolioWith, setPortfolioState } from 'actions/portfolio';

class ItemList extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      enhanceReplace : '',
    }
  }

  componentDidMount() {
    const { replacePortfolioWith } = this.props;
    // console.log('------------------!!!!___________!!!-------------------');
    // console.log(this.state.enhanceReplace);
    if (replacePortfolioWith && typeof replacePortfolioWith.ticker !== 'ticker') {
      this.setState({
        enhanceReplace: replacePortfolioWith.ticker,
      });
    }
    // console.log(this.state.enhanceReplace);
  }

  // componentDidUpdate(prevProps) {
    // const { enhanceModelPortfolio } = this.props;
    // const { replacePortfolioWith } = enhanceModelPortfolio;
    // console.log('------------------!!!!___________!!!-------------------');
    // console.log(this.state.enhanceReplace);
    // console.log(replacePortfolioWith.ticker);
    // if (replacePortfolioWith && typeof replacePortfolioWith.ticker !== 'ticker' && this.state.enhanceReplace === '') {
      // console.log('------------------!!!!___________!!!-------------------');
      // this.setState({
      //   enhanceReplace: replacePortfolioWith.ticker,
      // });
    // }
  // }

  // componentWillReceiveProps(nextProps) {
    // const { enhanceModelPortfolio } = nextProps;
    // const { replacePortfolioWith } = enhanceModelPortfolio;
    // console.log('------------------!!!!___________!!!-------------------');
    // console.log(this.state.enhanceReplace);
    // console.log(replacePortfolioWith.ticker);
    // if (replacePortfolioWith && typeof replacePortfolioWith.ticker !== 'ticker' && this.state.enhanceReplace === '') {
    //   console.log('------------------!!!!___________!!!-------------------');
    //   // this.setState({
    //   //   enhanceReplace: replacePortfolioWith.ticker,
    //   // });
    // }
  // }

  setReplaceTicker = (e, replaceTickerData, replaceTicker, query) => {
    let ticker = replaceTicker, tickerData = replaceTickerData;
    //for unchecking checkbox
    if (this.state.enhanceReplace === ticker) {
      ticker = '';
      tickerData = {};
    }

    //emhanceReplace array obj sample:
    // [{
    //   query: "low cost funds that are similar to SPY"
    //   replace: "SPLG"
    //   selectedPortfolioFunds: {},
    //   ticker: "SPY"
    // }]

    this.setState({
      enhanceReplace: ticker,
    }, () => {
      const { replaceCurrentModelPortfolioWith, setPortfolioState, myCurrentPortfolio } = this.props;
      if (replaceCurrentModelPortfolioWith && setPortfolioState) {
        setPortfolioState({
          emhanceReplace: [{
            query,
            replace: ticker,
            selectedPortfolioFunds: tickerData,
            ticker: myCurrentPortfolio.portfolioDetails.name,
          }],
        });
        replaceCurrentModelPortfolioWith({
          replacePortfolioWith: tickerData,
        });
      }
    });
  }

  render () {
    const { data, query, ticker, staticCard, resetCheck, currentPortfolioNameInitial, enhanceModelPortfolio, portfolioQuery, universe } = this.props;
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: null, //<RightOutlined />,
      prevArrow: null, //<LeftOutlined />,
      responsive: [
        {
          breakpoint: 1900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1530,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1180,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const messgageText = (q) => {
      if (enhanceModelPortfolio.portfolioId) {
        if (q.includes('yield')) {
          return <span>There are <span className="text-bold">no portfolios</span> similar to <br/><span className="text-bold">{enhanceModelPortfolio.portfolioId}</span> with <span className="text-bold">higher yield</span>.</span>;
        } else if (q.includes('risk') || q.includes('safe_alternative')) {
          return <span>There are <span className="text-bold">no portfolios</span> similar to <br/><span className="text-bold">{enhanceModelPortfolio.portfolioId}</span> with <span className="text-bold">lower risk</span>.</span>;
        } else if (q.includes('low_cost') || q.includes('similar')) {
          return <span>There are <span className="text-bold">no portfolios</span> similar to <br/><span className="text-bold">{enhanceModelPortfolio.portfolioId}</span> with <span className="text-bold">lower cost</span>.</span>;
        } else if (q.includes('high_sharpe_alternative')) {
          return <span>There are <span className="text-bold">no portfolios</span> similar to <br/><span className="text-bold">{enhanceModelPortfolio.portfolioId}</span> with the <span className="text-bold">higher risk adjusted return</span>.</span>;
        } else {
          return <span>There are <span className="text-bold">no portfolios</span> similar to <br/><span className="text-bold">{enhanceModelPortfolio.portfolioId}</span> for <span className="text-bold">'{portfolioQuery}'</span>.</span>;
        }
      }
    }

    const renderDesign = (data && typeof data.funds !== 'undefined' && data.funds.length > 0) ? (
      <Slider {...settings}>
        { data && typeof data.funds !== 'undefined' && data.funds.length > 0 && data.funds.map((e, i) => (
          <ItemCard
            {...this.props}
            className="model-portfolio-item-card"
            data={e}
            universe={universe || ''}
            query={query}
            portfolioQuery={portfolioQuery}
            i={i}
            currentPortfolioNameInitial={currentPortfolioNameInitial}
            staticCard={staticCard}
            resetCheck={resetCheck}
            defaultFRR={e._defaultFRR}
            relevanceChartTabs={e._relevanceChartTabs}
            originalQueryVar={e._originalQueryVar}
            ticker={e.ticker}
            highlights={data.Highlights}
            setReplaceTicker={this.setReplaceTicker}
            enhanceReplace={this.state.enhanceReplace}
          />
        )) }
      </Slider>
    ) : (
      <Card className="card-background-grey empty-message-wrapper model-portfolio-item-card" style={{ margin: '0 8px' }}>
        <div className="text-box">
          {messgageText(query)}
        </div>
      </Card>
    );

    return (
      <div className="itemlist-carousel-wrap" style={{ display: 'inherit', width: '100%' }}>
        {renderDesign}
      </div>
    )
  }
}

const mapStateToProps = ({ portfolio }) => ({
  enhanceModelPortfolio: portfolio.enhancePortfolio,
  myCurrentPortfolio: portfolio.enhancePortfolio.myCurrentPortfolio,
  replacePortfolioWith: portfolio.enhancePortfolio.replacePortfolioWith,
  portfolioQuery: portfolio.query,
});

const mapDispatchToProps = {
  replaceCurrentModelPortfolioWith,
  setPortfolioState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemList)

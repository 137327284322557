import moment from 'moment';
// import { FilterAttrData, DefaultSortAttr, DateRange, ViewNames } from '../DataSet';
import ActionTypes from '../action-types'

const initialState = {
  mobileDevice: false,
  tabDevice: false,
  smallScreen: false, //only screen and (min-width: 1024px) and (max-width: 1280px)
  desktopScreen: false,
  bigScreen: false,
  fundApiCallTime: moment().format('x'),
  fundApiCallDuration: moment().format('x'),
  fundsApiCallPercent: 0,
  surveyModal: window.sessionStorage.getItem('counter') === 2 || false,
  replacePremiumWithSurvey: false,
  portfolioReport: [],
  magnifiTickers: [],
  showJoinModal: false,
  error: {
    500: 'Something went wrong',
    501: 'Something went wrong',
    502: 'Our website is currently undergoing scheduled maintenance, we will be right back in sometime.',
  }
}

export function global(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case ActionTypes.REFRESH_GLOBAL_STATE:
      return {
        mobileDevice: false,
        tabDevice: false,
        smallScreen: false,
        desktopScreen: false,
        bigScreen: false,
        fundApiCallTime: moment().format('x'),
        fundApiCallDuration: moment().format('x'),
        fundsApiCallPercent: 0,
        surveyModal: false,
        replacePremiumWithSurvey: false,
        showJoinModal: false
      }
    case ActionTypes.CLEAR_FUNDS_API_LOAD_PERCENT:
      return {
        ...state,
        fundApiCallTime: moment().format('x'),
        fundApiCallDuration: moment().format('x'),
        fundsApiCallPercent: 0,
      }
    case ActionTypes.UPDATE_LOAD_PERCENT:
      return {
        ...state,
        fundsApiCallPercent: payload.fundsApiCallPercent,
      }
    case ActionTypes.FUNDS_API_CALL_DURATION:
      return {
        ...state,
        fundApiCallDuration: payload.fundApiCallDuration,
      }
    case ActionTypes.FUNDS_API_CALL_TIME:
      // console.log(payload);
      return {
        ...state,
        fundApiCallTime: payload.fundApiCallTime,
      }
    case ActionTypes.SHOW_JOIN_MODAL:
      // console.log(payload);
      return {
        ...state,
        showJoinModal: payload.showJoinModal,
      }
    case ActionTypes.SET_GLOBAL_STATE:
      return {
        ...state,
        ...payload,
      }
    case ActionTypes.GET_ALL_TICKER_LIST_SUCCESS:
      return {
        ...state,
        ...payload,
      }
    default:
      return state
  }
}

import ActionTypes from '../action-types';

const initialState = {
  selectorReportIndexData: [],
}

export function reports(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case ActionTypes.SET_SELECTOR_REPORT_INDEX_DATA:
      return {
        ...state,
        selectorReportIndexData: payload,
      }
    default:
      return state
  }
}

import React from 'react';
import { Row, Col } from 'reactstrap';
import NotApplicable from 'components/NotApplicable';
import GaugeChart from 'components/charts/GaugeChart';

export const DynamicGuageChart = (view, card, width, chrtD) => {
  const chartData = chrtD || card.chartData;
  if (typeof chartData === 'undefined') return <NotApplicable />;
  const currentChartData = chartData.filter(e => e.view_name == view)[0];
  if (typeof currentChartData === 'undefined') return <NotApplicable />;
  const mult = typeof currentChartData !== 'undefined' ? (
    currentChartData.multiplier != "" ? currentChartData.multiplier : 1
  ) : 1;

  // console.log(currentChartData);
  // console.log(card.ticker, currentChartData.min, currentChartData.max, currentChartData.variable, card[currentChartData.variable]);

  if (isNaN(currentChartData.min) || currentChartData.min === '') return <NotApplicable />;

  if (isNaN(currentChartData.max) || currentChartData.max === '') return <NotApplicable />;

  if (isNaN(card[currentChartData.variable]) || card[currentChartData.variable] === '') return <NotApplicable />;

  // console.log('sid chart values', currentChartData.multiplier, mult, currentChartData.min, currentChartData.max, card[currentChartData.variable], _min, _max, _val);
  //not present
  if (card[currentChartData.variable] == null || typeof card[currentChartData.variable] == undefined || card[currentChartData.variable] === "") return <NotApplicable />;

  //less than min
  if (card[currentChartData.variable] < currentChartData.min) return <NotApplicable />;

  //less than max
  if (card[currentChartData.variable] > currentChartData.max) return <NotApplicable />;

  //if same
  // if (currentChartData.min == currentChartData.max) return <NotApplicable />;

  let _min = currentChartData.min * mult,
    _max = currentChartData.max * mult,
    _val = card[currentChartData.variable] * mult;
  // console.log(card.ticker, _min, _max, _val);
  let chart_color = currentChartData.chart_color;

  let renderStats;

  if (currentChartData.variable === 'attribute/portfolio_manager/manager_investment') { // for 'HCMAX manager investment' type query when dynamic chart is Assets Type chart
    const suffix = ['', 'K', 'M', 'B'];
    const kmb = (val, i = 0) => (val < 100 || i > 2) ? `${currentChartData.Prefix}${val.toFixed(currentChartData.decimal)}${suffix[i]}` : kmb(val / 1000, i + 1);
    renderStats = (
      <Row className="no-gutters h6 mb-0 w-100 guage-chart-details">
        <Col className="col-4 pr-2 text-gray-6i text-right h7 fz-9" style={{ marginTop: '-2px' }}>{kmb(_min)}</Col>
        <Col className="col-4 text-center font-weight-bold mb-0 lh-100 fz-9" style={{ marginTop: '2px' }}>{kmb(_val)}</Col>
        <Col className="col-4 pl-2 text-gray-6i text-left h7 fz-9" style={{ marginTop: '-2px' }}>{kmb(_max)}</Col>
      </Row>
    );
  } else {
    renderStats = (
      <Row className="no-gutters h6 mb-0 w-100 guage-chart-details">
        <Col className="col-4 pr-2 text-gray-6i text-right h7 fz-9" style={{ marginTop: '-2px' }}>{currentChartData.Prefix}{_min.toFixed(currentChartData.decimal)}{currentChartData.suffix}</Col>
        <Col className="col-4 text-center font-weight-bold mb-0 lh-100 fz-9" style={{ marginTop: '2px' }}>{currentChartData.Prefix}{_val.toFixed(currentChartData.decimal)}{currentChartData.suffix}</Col>
        <Col className="col-4 pl-2 text-gray-6i text-left h7 fz-9" style={{ marginTop: '-2px' }}>{currentChartData.Prefix}{_max.toFixed(currentChartData.decimal)}{currentChartData.suffix}</Col>
      </Row>
    );
  }

  return (
    <div className="w-100 p-1 guage-chart-wrapper">
      <div className="d-flex flex-column align-items-center" >
        {/* style={{ minWidth: width || 134 }} */}
        {/* <h6 className="text-center mb-0">Percentage Overlap</h6> */}
        {(_min === _max && _max === _val) ? (
          <GaugeChart chart_color={chart_color} minWidth={width} pc={0.5} />
        ) : (
          <GaugeChart chart_color={chart_color} minWidth={width} value={_val} min={_min} max={_max} />
        )}
        {renderStats}
      </div>
    </div>
  );
}

export default DynamicGuageChart;

import React from 'react';
import { BarChartS1 } from 'components/charts/Recharts';
import { cn, D3AppColors09, kmb } from 'DataSet';
import { dateToMonth, getNest, numDc } from 'Utils';
import { DateRangeSelector } from '../Common';
import { variablesWithoutDateRange } from '../utils';

export const Chart = (props) => {
  const { attr, items, dropdownScoreAttrs } = props;

  if (!items[0] || !items[0]._sfStats) return null;

  const { name, col, code, colOff, suffix, parser  } = attr;
  const parserFN = parser ? val => kmb(val || 0) : '';
  let data;

  data = items.map((e, i) => {
    let out = {
      name: cn(e, 'Ticker Short'),
      color: D3AppColors09[i%D3AppColors09.length],
    }

    return getNest(['_sfWtStats', colOff], e)
      ? { ...out, value: 0.0, label: 'Inactive' }
      : { ...out, value: numDc(getNest(['_sfWtStats', col], e)) }
  });

  // console.log(props.range[code]);
  // console.log(props.dateRange[code]);
  // 'Since '+dateToMonth(props.dateRange[code].start)
  return (
    <div style={{padding: '0 2px 2px'}}>
      <div className="p-1 bg-white rounded">
        <div className="bg-ssf-gray-l1">
          <div className="d-flex align-items-center px-2" style={{height: '20px'}}>
            <div className="h6 mb-0 font-weight-bold">&Delta; {name}</div>
            { !variablesWithoutDateRange.includes(name) &&
              ((props.dateRange[code]) && typeof props.displayYear !== 'undefined' && typeof (props.dateRange[code]).start !== undefined) &&
              (
                <div className="ml-auto">
                  <DateRangeSelector
                    noCustomDate={true}
                    dropdownScoreAttrs={dropdownScoreAttrs}
                    rangeHandler={props.rangeHandler}
                    handleDateChangeCombineScore={props.handleDateChangeCombineScore}
                    col={code}
                    range={props.range[code]}
                    accountCreatedDate={props.accountCreatedDate}
                    activeLabel={props.sinceDate}
                    displayYear={props.displayYear}
                    dateRange={props.dateRange[code]}
                    fromDatePicker={props.range.fromDatePicker}
                  />
                </div>
              )
            }
            { !variablesWithoutDateRange.includes(name) &&
              ((props.dateRange[code]) && typeof props.displayYear === 'undefined' && typeof (props.dateRange[code]).start !== undefined) &&
              (
                <div className="ml-auto">
                  <DateRangeSelector
                    noCustomDate={true}
                    dropdownScoreAttrs={dropdownScoreAttrs}
                    rangeHandler={props.rangeHandler}
                    accountCreatedDate={props.accountCreatedDate}
                    handleDateChangeCombineScore={props.handleDateChangeCombineScore}
                    col={code}
                    range={props.range[code]}
                    activeLabel={props.sinceDate}
                    dateRange={props.dateRange[code]}
                    fromDatePicker={props.range.fromDatePicker}
                  />
                </div>
              )
            }
          </div>
          <div className="px-1 text-gray-3 chart-wrap" style={{ height: 89 }}>
            <BarChartS1 data={data} suffix={suffix} parser={parserFN} showTickerOnAxis />
          </div>
        </div>
      </div>
    </div>
  )
}

export const ReturnChart = (props) => {
  const code = 'return';

  return (
    <div style={{padding: '0 2px 2px'}}>
      <div className="p-1 bg-white rounded">
        <div className="bg-ssf-gray-l1">
          <div className="d-flex align-items-center px-2" style={{height: '20px'}}>
            <div className="h6 mb-0 font-weight-bold">Cumulative Return</div>
              {(props.dateRange[code] && typeof (props.dateRange[code]).start !== undefined)
                && (
                  <div className="ml-auto">
                    <DateRangeSelector
                      rangeHandler={props.rangeHandler}
                      col={code} range={props.range[code]}
                      activeLabel={'Since '+dateToMonth(props.dateRange[code].start)} />
                  </div>
                )
              }
          </div>
          <div className="px-1 text-gray-3 chart-wrapX">
            <div className="d-flex justify-content-center align-items-center" style={{minHeight: '200px'}}>
              <h3 className="pb-3"><i className="fal fa-fw fa-chart-line fa-5x" title="Placeholder"></i></h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import GaugeChart from 'components/charts/GaugeChart';
import NotApplicable from 'components/NotApplicable';

export const MarketCapChart = ({ card, width }) => {
  if (typeof card._categorySeries == 'undefined' || !card._categorySeries) return <NotApplicable />

  const mult = 1;
  const _min = card['marketcapMin'],
    _max = card['marketcapMax'],
    _val = card['market_cap'] * mult;

  if (typeof _val == 'undefined' || _val == null) return <NotApplicable />
  if (typeof _min == 'undefined' || _min == null) return <NotApplicable />
  if (typeof _max == 'undefined' || _max == null) return <NotApplicable />

  const suffix = ['', 'K', 'M', 'B'];
  const kmb = (val, i = 0) => (val < 100 || i > 2) ? `$${val.toFixed(1)}${suffix[i]}` : kmb(val / 1000, i + 1)

  return (
    <div className="w-100 p-1 guage-chart-wrapper">
      <div className="d-flex flex-column align-items-center">
        {
          <Fragment>
            {(_min === _max && _max === _val) ? (
              <GaugeChart minWidth={width} pc={0.5} />
            ) : (
              <GaugeChart minWidth={width} value={_val} min={_min} max={_max} />
            )}
            <Row className="no-gutters h6 mb-0 w-100 guage-chart-details">
              <Col className="col-4 pr-2 text-gray-6i text-right h7 fz-9" style={{ marginTop: '-2px' }}>{kmb(_min)}</Col>
              <Col className="col-4 text-center font-weight-bold mb-0 lh-100 fz-9" style={{ marginTop: '2px' }}>{kmb(_val)}</Col>
              <Col className="col-4 pl-2 text-gray-6i text-left h7 fz-9" style={{ marginTop: '-2px' }}>{kmb(_max)}</Col>
            </Row>
          </Fragment>
        }
      </div>
    </div>
  );
}

export default MarketCapChart;
